import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    CreateBranchRequest,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { addBranch } from "api/FloreyBuilder/FloreyBuilderApi";

export const useCreateBranchMutation = createMutationHook<
    CreateBranchRequest,
    QuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["CreateBranch"],
    mutationFn: async ({ organisationId, ...args }) => {
        const response = await addBranch(organisationId, {
            organisationId,
            ...args,
        });
        return returnDataOrThrow(response);
    },
}));
