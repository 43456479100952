import isNil from "lodash/isNil";

import {
    PatientThreadSummaryDetails,
    PatientThreadUser,
    PatientThreadUserGroup,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { isInstance } from "shared/concierge/conversations/utils";
import {
    TeamId,
    TeamSummary,
    UserSummary,
    UsersAndTeamsWithMembershipSummaries,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

export const mapInitialSummaryDetailsToUsersAndTeams = (
    summaryDetails: PatientThreadSummaryDetails,
): UsersAndTeamsWithMembershipSummaries => {
    const users =
        summaryDetails.activeUsers
            ?.map(mapPatientThreadUserToUserSummary)
            .filter(isInstance) ?? [];

    const teams: TeamSummary[] = [];
    const currentUserTeamIds: TeamId[] = [];

    summaryDetails.activeUserGroups?.forEach((userGroup) => {
        const team = mapPatientThreadUserGroupToTeamSummary(userGroup);

        if (!team) {
            return;
        }

        teams.push(team);

        if (userGroup.currentUserIsMember) {
            currentUserTeamIds.push(team.id);
        }
    });

    return { users, teams, currentUserTeamIds };
};

/**
 * Maps from a Ticket User to a User Summary
 * @param dto the ticket user
 * @returns mapped version to User Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserToUserSummary = (
    dto: PatientThreadUser,
): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }
    return {
        id: dto.accuRxId,
        displayName: dto.name || dto.email || dto.accuRxId,
    };
};

/**
 * Maps from a Ticket User Group to a Team Summary
 * @param dto the ticket user group
 * @returns mapped version to Team Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserGroupToTeamSummary = (
    dto: PatientThreadUserGroup,
): TeamSummary | undefined => {
    if (isNil(dto.id) || dto.id.length === 0) {
        return undefined;
    }

    return {
        id: dto.id,
        displayName: dto.name || dto.id,
    };
};
