export const scrollChildToCenter = ({
    child,
    parent,
}: {
    child: HTMLElement;
    parent: HTMLElement;
}) => {
    const parentTop = parent.getBoundingClientRect().top;
    const parentHeight = parent.offsetHeight;
    const childTop = child.getBoundingClientRect().top;
    const childHeight = child.offsetHeight;
    const topOffset = childTop - parentTop;

    // Don't scroll if the child element is already fully visible in the parent
    if (parentHeight > topOffset + childHeight) {
        return;
    }

    // Scroll the parent so that the child is exactly in the vertical center
    const distance = topOffset + childHeight / 2 - parentHeight / 2;

    parent.scrollTo({
        top: distance,
        behavior: "smooth",
    });
};
