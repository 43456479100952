import { Dispatch } from "redux";

import ChainApiClient from "api/VaccineApiHelper";
import { CoreActions } from "shared/constants/actionTypes";

import {
    FirstVaccinationFilterChoice,
    PatientInviteSearchFilters,
    SecondVaccinationFilterChoice,
    VaccineCourse,
    VaccineDeliveryItem,
    VaccineInviteActionData,
    VaccinePatientInvited,
    VaccineTypeFilters,
} from "../models/VaccineDeliveryDTO";
import { getVaccineCourseSearchTerm } from "../utils";

export function resetState() {
    return (dispatch: Dispatch) =>
        dispatch({ type: CoreActions.PREPARE_NEW_VACCINE_INVITES });
}

export function uploadList(
    practiceId: string,
    course: VaccineCourse,
    list: File,
) {
    const formData = new FormData();
    formData.append("formFile", list);
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.UPLOAD_VACCINE_INVITE_LIST });

        const courseParam = getVaccineCourseSearchTerm(course);
        const { success, result, error } =
            await ChainApiClient.postVaccineUploadFile(
                practiceId,
                courseParam,
                formData,
            );
        if (success && result) {
            dispatch({
                type: CoreActions.UPLOAD_VACCINE_INVITE_LIST_SUCCESS,
                response: result,
            });
        } else {
            dispatch({
                type: CoreActions.UPLOAD_VACCINE_INVITE_LIST_FAILURE,
                err: error,
            });
        }
    };
}

export function getUploadStatus(practiceId: string, vaccineDeliveryId: string) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.GET_VACCINE_UPLOAD_STATUS });
        const { success, result, error } =
            await ChainApiClient.getVaccineUploadStatus(
                practiceId,
                vaccineDeliveryId,
            );
        if (success && result) {
            dispatch({
                type: CoreActions.GET_VACCINE_UPLOAD_STATUS_SUCCESS,
                response: result,
            });
            return;
        }
        dispatch({
            type: CoreActions.GET_VACCINE_UPLOAD_STATUS_FAILURE,
            err: error,
        });
    };
}

export function getVaccineDeliveryDetails(
    practiceId: string,
    vaccineDeliveryId: string,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.GET_VACCINE_UPLOAD_DETAILS });
        const { success, result, error } =
            await ChainApiClient.getVaccineDeliveryDetails(
                practiceId,
                vaccineDeliveryId,
            );
        if (success && result) {
            dispatch({
                type: CoreActions.GET_VACCINE_UPLOAD_DETAILS_SUCCESS,
                response: result,
            });
            return;
        }
        dispatch({
            type: CoreActions.GET_VACCINE_UPLOAD_DETAILS_FAILURE,
            err: error,
        });
    };
}

export function getVaccineAllPatientsInvited(
    practiceId: string,
    body: PatientInviteSearchFilters,
) {
    return async (dispatch: Dispatch) => {
        // If we have multiple requests in flight, we only care about the response from the most recent,
        // use a simple key with negligible probability of collision.
        const requestId = `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
        dispatch({
            type: CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED,
            requestId,
        });
        const { success, result, error } =
            await ChainApiClient.getVaccineAllPatientsInvited(practiceId, body);
        if (success && result) {
            dispatch({
                type: CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED_SUCCESS,
                response: result,
                requestId,
            });
            return;
        }
        dispatch({
            type: CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED_FAILURE,
            error,
            requestId,
        });
    };
}

export function sendMessage(
    practiceId: string,
    vaccineDeliveryId: string,
    messageTemplate: string,
    shouldReloadBatch: boolean,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES });

        const request = {
            MessageTemplate: messageTemplate,
        };
        const { success, result } = await ChainApiClient.postSendMessage(
            practiceId,
            vaccineDeliveryId,
            request,
        );
        if (success && result) {
            if (shouldReloadBatch) {
                const reloadRes =
                    await ChainApiClient.getVaccineDeliveryDetails(
                        practiceId,
                        vaccineDeliveryId,
                    );
                if (reloadRes.success && reloadRes.result) {
                    dispatch({
                        type: CoreActions.GET_VACCINE_UPLOAD_DETAILS_SUCCESS,
                        response: reloadRes.result,
                    });
                    dispatch({
                        type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS,
                        response: result,
                    });
                } else {
                    // message has still been sent, so carry on with stale batch details
                    dispatch({
                        type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS,
                        response: result,
                    });
                    // no actual response here, but there is stale data,
                    // so we use dispatch a special-case action to set the status to success without setting the body
                    dispatch({
                        type: CoreActions.GET_VACCINE_UPLOAD_DETAILS_SUCCESS_NO_BODY,
                        err: reloadRes.error,
                    });
                }
            } else {
                dispatch({
                    type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS,
                    response: result,
                });
            }
        } else {
            dispatch({
                type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_FAILURE,
            });
        }
    };
}

export function sendInviteForSinglePatient(
    orgId: string,
    patientToken: string,
    shouldSendSms: boolean,
    course: string,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES });
        const request = { patientToken, shouldSendSms, course };
        const { success, result } =
            await ChainApiClient.postInviteSinglePatient(orgId, request);

        if (success && result) {
            return dispatch({
                type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS,
                response: result,
            });
        }
        return dispatch({
            type: CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_FAILURE,
        });
    };
}

export function cancelBooking(
    practiceId: string,
    vaccineDeliveryId: string,
    inviteId: string,
    isAllowedRebook: boolean,
    cancelSecondBooking: boolean,
    customSmsSignature: string | null,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.CANCEL_BOOKING, inviteId });
        const request = {
            patientCanRebook: isAllowedRebook,
            cancelSecondBooking,
            customSmsSignature,
        };
        const { success } = await ChainApiClient.postVaccineCancelBooking(
            practiceId,
            vaccineDeliveryId,
            inviteId,
            request,
        );
        if (success) {
            return dispatch({ type: CoreActions.CANCEL_BOOKING_SUCCESS });
        }
        return dispatch({
            type: CoreActions.CANCEL_BOOKING_FAILURE,
            err: "Something went wrong cancelling this booking. Please try again",
        });
    };
}

export function setCancelBookingDetails(patient: VaccinePatientInvited) {
    return (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.SET_CANCEL_BOOKING_DETAILS, patient });
    };
}

export function setStatusFilters(statusFilters: string[]) {
    return (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.SET_STATUS_FILTERS, statusFilters });
    };
}

export function setMyPracticeFilter(myPractice: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.SET_MY_PRACTICE_FILTER, myPractice });
    };
}

export function setVaccineTypeFilters(vaccineTypeFilters: VaccineTypeFilters) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CoreActions.SET_VACCINE_TYPE_FILTERS,
            vaccineTypeFilters,
        });
    };
}

export function setFirstVaccinationTimeFilter(
    firstVaccinationTimeFilter: FirstVaccinationFilterChoice | null,
) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CoreActions.SET_FIRST_VACCINE_TIME_FILTER,
            firstVaccinationTimeFilter,
        });
    };
}

export function setSecondVaccinationTimeFilter(
    secondVaccinationTimeFilter: SecondVaccinationFilterChoice | null,
) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CoreActions.SET_SECOND_VACCINE_TIME_FILTER,
            secondVaccinationTimeFilter,
        });
    };
}

export function setUnclearStatusFilter(unclearStatus: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CoreActions.SET_UNCLEAR_STATUS_FILTERS,
            unclearStatus,
        });
    };
}

export function resetBooking(
    practiceId: string,
    vaccineDeliveryId: string,
    inviteId: string,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.RESET_BOOKING, inviteId });
        const { success } = await ChainApiClient.postVaccineResetBooking(
            practiceId,
            vaccineDeliveryId,
            inviteId,
        );
        if (success) {
            dispatch({ type: CoreActions.RESET_BOOKING_SUCCESS });
            return;
        }
        dispatch({ type: CoreActions.RESET_BOOKING_FAILURE });
    };
}

export function setPatientInvites(
    vaccineInviteActionData: VaccineInviteActionData,
) {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: CoreActions.SET_PATIENT_INVITES_FOR_BULK_ACTION,
            vaccineInviteActionData,
        });
    };
}

export function updateSearchTerm(searchTerm: string) {
    return (dispatch: Dispatch): void => {
        dispatch({ type: CoreActions.UPDATE_SEARCH_TERM, searchTerm });
    };
}

export function updateMyPracticeFilter(myPractice: boolean) {
    return (dispatch: Dispatch): void => {
        dispatch({ type: CoreActions.UPDATE_MY_PRACTICE_FILTER, myPractice });
    };
}

export function resetGoToCancelFlow() {
    return (dispatch: Dispatch): void => {
        dispatch({ type: CoreActions.RESET_GO_TO_CANCEL_FLOW });
    };
}

// This is setting invites for second invite, or for booster invite
export function setPatientInvitesForInviteToSecond(
    patientDeliveryItems: VaccineDeliveryItem[],
) {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: CoreActions.SET_PATIENT_INVITES_FOR_INVITE_TO_SECOND,
            patientDeliveryItems,
        });
    };
}

export function resetPatientInvitesForInviteToSecond() {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: CoreActions.RESET_PATIENT_INVITES_FOR_INVITE_TO_SECOND,
        });
    };
}

export function updateCourseType(course: VaccineCourse) {
    return (dispatch: Dispatch): void => {
        dispatch({ type: CoreActions.UPDATE_COURSE_TYPE, course });
    };
}
