import React from "react";

import { Flex, Icon, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import {
    MaxBatchSMSSize,
    MaxBatchSelfbookSMSSize,
} from "app/batchMessage/gp/BatchMessage.types";

import { MessageErrorType } from "./compose/BatchMessageCompose.types";

const StyledFlex = styled(Flex)`
    margin-top: ${Tokens.SIZES[1]};
`;

export type ComposeMsgInfoProps = {
    characterCount: number;
    fragmentCount?: number;
    messageErrorMessage?: string;
    messageErrorType?: MessageErrorType;
    isSelfBook?: boolean;
};

export const ComposeMsgInfo = ({
    characterCount,
    fragmentCount,
    messageErrorMessage,
    messageErrorType,
    isSelfBook = false,
}: ComposeMsgInfoProps): JSX.Element => {
    const maxSize = isSelfBook ? MaxBatchSelfbookSMSSize : MaxBatchSMSSize;
    return (
        <StyledFlex flexWrap="wrap" justifyContent="flex-end">
            <div>
                {messageErrorMessage && (
                    <Flex gap="1">
                        <div>
                            <Icon
                                colour={
                                    messageErrorType === MessageErrorType.Error
                                        ? "red"
                                        : "orange"
                                }
                                name={
                                    messageErrorType === MessageErrorType.Error
                                        ? "Error"
                                        : "Warning"
                                }
                                size={3}
                                theme="Fill"
                            />
                        </div>
                        <Text skinny>{messageErrorMessage}</Text>
                    </Flex>
                )}
            </div>
            <div data-testid="msginfo">
                <Text
                    variant="note"
                    colour={characterCount > maxSize ? "red" : "zinc"}
                >
                    {characterCount} / {maxSize}{" "}
                    {fragmentCount && (
                        <>
                            ({fragmentCount}{" "}
                            {fragmentCount === 1 ? "fragment" : "fragments"})
                        </>
                    )}
                </Text>
            </div>
        </StyledFlex>
    );
};
