import "proxy-polyfill";
import "intersection-observer"; // N.B. We use this in the web inbox

// Order of importing CSS is significant, so import as early as possible.
// N.B. We use this in the web inbox
import "./styles/styles.scss";

// Vite does not add a "global" object to window which some
// libraries expect. So we do that here.
window.global ||= window;
