import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation, PatientExternalId } from "domains/concierge/types";

type Variables = {
    patientExternalIds?: PatientExternalId[];
    patientToken?: string;
    conversation: Conversation;
};

export const useMatchTicketToPatientMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "MatchTicketToPatient",
        async ({ patientExternalIds, conversation, patientToken }) => {
            if (conversation.source.system !== "Ticket") {
                throw new Error(
                    `MatchTicketToPatientMutation does not support ${conversation.source.system} conversations`,
                );
            }

            if (patientToken !== undefined) {
                const { updates } = await api.matchTicketToPatientWithToken({
                    workspaceId,
                    latestToken: conversation.latestToken,
                    ticketIdentity: conversation.source.ticketIdentity,
                    patientToken,
                });

                return dispatch(
                    actions.processUpdates({
                        source: "Mutation:MatchTicketToPatient:WithToken",
                        ...updates,
                    }),
                );
            } else if (patientExternalIds !== undefined) {
                const { updates } = await api.matchTicketToPatient({
                    workspaceId,
                    latestToken: conversation.latestToken,
                    ticketIdentity: conversation.source.ticketIdentity,
                    patientExternalIds,
                });

                return dispatch(
                    actions.processUpdates({
                        source: "Mutation:MatchTicketToPatient:WithId",
                        ...updates,
                    }),
                );
            } else {
                throw new Error(
                    "Cannot match ticket to patient. No patient token or external IDs were provided.",
                );
            }
        },
        options,
    );
};
