import React, { useEffect, useState } from "react";

import {
    Card,
    Feedback,
    Icon,
    Spinner,
    StackPanel,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { DateHelpers } from "@accurx/shared";
import { toast } from "react-toastify";

import { IFileUploadRequest } from "api/FlemingDtos";
import {
    ORGANISATION_RESOURCE_TYPES,
    OrganisationResource,
} from "api/OrganisationResourceApi";
import { useDeleteWorkspaceResourceMutation } from "app/hooks/mutations";
import { useWorkspaceResourcesQuery } from "app/hooks/queries";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ReactComponent as NoResourcesIllustration } from "shared/images/CardIllustration.svg";
import { useAppSelector } from "store/hooks";

import { DocumentEntryCard } from "./DocumentEntryCard";
import {
    StyledAddResourceButton,
    StyledLabelWrapper,
    StyledNoResourcesWrapper,
} from "./OrganisationResources.styles";
import { UploadResource } from "./UploadResource";

type AddResourceButtonProps = {
    organisationId: number | null;
    onUploadFile: (request: IFileUploadRequest) => void;
};

const AddResourceButton = ({
    organisationId,
    onUploadFile,
}: AddResourceButtonProps): JSX.Element => (
    <StyledAddResourceButton
        maxFiles={1}
        maxSize={3}
        accept=".png,.jpg,.pdf"
        noDrag={true}
        organisationId={organisationId}
        onUploadFile={onUploadFile}
        uploadButtonProps={{
            icon: {
                name: "Plus",
            },
            text: "Add resource",
            theme: "primary",
            className: "align-self-center",
        }}
        displayUploadedFiles={false}
        displayErrorsAsPopover={true}
    />
);

const getDocumentType = (type: string | null): string | undefined => {
    if (
        type &&
        ORGANISATION_RESOURCE_TYPES[
            type as keyof typeof ORGANISATION_RESOURCE_TYPES
        ]
    ) {
        return ORGANISATION_RESOURCE_TYPES[
            type as keyof typeof ORGANISATION_RESOURCE_TYPES
        ];
    }

    return undefined;
};

const getResourceDescription = ({
    uploadedBy,
    uploadedByCurrentUser,
}: OrganisationResource) => {
    return `Uploaded by ${uploadedBy}${uploadedByCurrentUser ? " (you)" : ""}`;
};

const OrganisationResources = (): JSX.Element => {
    useAccurxWebTitle("Manage workspace documents");

    const organisationName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const organisationId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const [currentFileUploadRequest, setCurrentFileUploadRequest] =
        useState<IFileUploadRequest | null>(null);

    const [showUploadPage, setShowUploadPage] = useState(false);

    const handleCancelUpload = () => setCurrentFileUploadRequest(null);

    const deleteOrganisationResourceMutation =
        useDeleteWorkspaceResourceMutation({
            onError: () => {
                toast(
                    Feedback({
                        colour: "error",
                        title: "There was a problem deleting this document. Please refresh the page and try again.",
                    }),
                );
            },
        });

    const workspaceResourcesQuery = useWorkspaceResourcesQuery(
        { workspaceId: organisationId! }, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        { enabled: organisationId !== null },
    );

    useEffect(() => {
        if (currentFileUploadRequest) {
            setShowUploadPage(true);
        }

        return () => setShowUploadPage(false);
    }, [currentFileUploadRequest]);

    if (showUploadPage && currentFileUploadRequest) {
        return (
            <UploadResource
                fileUploadRequest={currentFileUploadRequest}
                handleGoBack={handleCancelUpload}
            />
        );
    }

    const renderMainContent = (): JSX.Element => {
        if (workspaceResourcesQuery.status === "loading") {
            return <Spinner />;
        }

        if (workspaceResourcesQuery.status === "error") {
            return (
                <Feedback
                    colour="error"
                    title={`There was an error loading the resources for ${organisationName}`}
                >
                    <Text skinny>Please refresh the page and try again</Text>
                </Feedback>
            );
        }

        if (workspaceResourcesQuery.data.length === 0) {
            return (
                <Card variant="dashed">
                    <StyledNoResourcesWrapper>
                        <NoResourcesIllustration />
                        <Text variant="subtitle">
                            You haven’t added any resources
                        </Text>
                        <Text variant="body">
                            Help patients that attend your organisation by
                            providing resources such as maps, something or
                            something. (File types .png, .jpg, .pdf)
                        </Text>
                        <AddResourceButton
                            organisationId={organisationId}
                            onUploadFile={setCurrentFileUploadRequest}
                        />
                    </StyledNoResourcesWrapper>
                </Card>
            );
        }

        return (
            <>
                <StyledLabelWrapper>
                    <StackPanel
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        gutter={1}
                    >
                        <Icon
                            name="Person"
                            halo={{
                                colour: "blue",
                                luminosity: "high",
                            }}
                            size={4}
                        />
                        <Text variant="label" skinny>
                            Available to patients in the NHS app
                        </Text>
                    </StackPanel>
                    <AddResourceButton
                        organisationId={organisationId}
                        onUploadFile={setCurrentFileUploadRequest}
                    />
                </StyledLabelWrapper>
                <StackPanel gutter={1}>
                    {workspaceResourcesQuery.data.map((resource, index) => {
                        return (
                            <DocumentEntryCard
                                key={`${resource.title}-${index}`}
                                title={resource.title || resource.fileName}
                                description={getResourceDescription(resource)}
                                downloadUrl={resource.downloadUrl}
                                documentType={getDocumentType(resource.type)}
                                uploadedAt={DateHelpers.formatDate(
                                    resource.uploadedDate,
                                    "HH:mm, DD MMM YYYY",
                                )}
                                handleDelete={() =>
                                    deleteOrganisationResourceMutation.mutate({
                                        resourceId: resource.id,
                                        workspaceId: organisationId,
                                    })
                                }
                            />
                        );
                    })}
                </StackPanel>
            </>
        );
    };

    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title={`Resources for ${organisationName}`}
                helperText="Upload resources and they’ll be visible to patients via the NHS App"
            />
            {renderMainContent()}
        </>
    );
};

export { OrganisationResources };
