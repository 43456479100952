import { Tokens } from "@accurx/design";

import { useMediaQuery } from "./useMediaQuery";

const { BREAKPOINTS } = Tokens;

export type ViewportSize = keyof typeof BREAKPOINTS;

/**
 * Gets the greatest breakpoint that the size of the viewport currently matches.
 *
 * Mapping devices to breakpoints:
 * - mobile: xs, sm
 * - tablet: md
 * - desktop: lg, xl
 */
export const useViewportSize = (): ViewportSize => {
    const [sm, md, lg, xl] = [
        useMediaQuery(`@media screen and (min-width: ${BREAKPOINTS.sm})`),
        useMediaQuery(`@media screen and (min-width: ${BREAKPOINTS.md})`),
        useMediaQuery(`@media screen and (min-width: ${BREAKPOINTS.lg})`),
        useMediaQuery(`@media screen and (min-width: ${BREAKPOINTS.xl})`),
    ];

    if (xl) return "xl";
    if (lg) return "lg";
    if (md) return "md";
    if (sm) return "sm";
    return "xs";
};
