import React from "react";

import { Icon, StackPanel, Text } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useAnalytics } from "app/analytics/hooks";
import { ROUTES_CHAIN } from "shared/Routes";

import { SectionCardLink } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCTAStackPanel,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledList,
} from "../../styles/Cards.styles";

export const PatientTriageProductCard = (): JSX.Element => {
    const { track } = useAnalytics();
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();

    const isNewReportingEnabled = useFeatureFlag("EmbeddedPowerBiPOC");

    const patientTriageSettingsClickHandler = () => {
        track("HomepagePatientTriageSettingButtonClick", {});
        history.push(
            generatePath(ROUTES_CHAIN.practicesPatientTriageSettings, {
                orgId,
            }),
        );
    };

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="RecordPerson"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.PATIENT_TRIAGE}
                    </Text>
                </StackPanel>
                <StyledCTAStackPanel
                    gutter={2}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <StyledCTAButton
                        theme="secondary"
                        text="Settings"
                        icon={{ name: "Cog", colour: "blue" }}
                        onClick={patientTriageSettingsClickHandler}
                    />
                </StyledCTAStackPanel>
            </StyledCardHeading>

            {!isNewReportingEnabled && (
                <StyledCardContent>
                    <StyledList>
                        <li>
                            <SectionCardLink
                                section={SECTIONS.PATIENT_TRIAGE}
                                text="Dashboard"
                                path={generatePath(
                                    ROUTES_CHAIN.practicesPatientTriageDashboards,
                                    {
                                        orgId,
                                    },
                                )}
                            />
                        </li>
                        {/* FLOW team looking into adding this deeplinking functionality.
                        Desired behaviour: clicking this link should open the accurx desktop inbox and land on the To assign folder
                     <li>
                        <SectionCardLink
                            text="View patient requests"
                            iconName="OpenWindow"
                            rotation="down"
                            href="#"
                        />
                    </li> */}
                    </StyledList>
                </StyledCardContent>
            )}
        </StyledCard>
    );
};
