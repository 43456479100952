import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Link } from "domains/inbox/components/Link/Link";
import {
    tabItemActiveStyles,
    tabItemBaseStyles,
    tabItemFocusStyles,
    tabItemHoverStyles,
} from "domains/inbox/components/Tabs/tabs.styles";
import styled from "styled-components";

export const StyledTabsItem = styled(NavigationMenu.Item)`
    position: relative;
`;

export const StyledActiveTab = styled.span`
    ${tabItemBaseStyles}
    ${tabItemActiveStyles}

    &:before {
        // Need to put the active tab border on top of the list items border
        bottom: -1px;
    }
`;

export const StyledTabsLink = styled(Link)<{ $active: boolean }>`
    ${tabItemBaseStyles}

    &:hover {
        ${tabItemHoverStyles}
        text-decoration: none;
        cursor: pointer;
    }

    &:focus,
    &:active {
        ${tabItemFocusStyles}
    }
`;
