import { ComponentProps, useEffect, useRef } from "react";

import * as UI from "@accurx/design";

import { StyledCustomRadioLabelWrapper } from "./CustomRadio.styles";

export const CustomRadio = (props: ComponentProps<typeof UI.Radio>) => {
    const selectedRadioRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        selectedRadioRef.current?.focus();
    }, []);

    return (
        <StyledCustomRadioLabelWrapper $labelFor={props.id}>
            <UI.Radio
                {...props}
                ref={
                    props.value === props.checkedValue ? selectedRadioRef : null
                }
            />
        </StyledCustomRadioLabelWrapper>
    );
};
