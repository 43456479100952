import React, { ChangeEvent } from "react";

import {
    Feedback,
    FormFieldWrapper,
    Input,
    Option,
    SearchSelect,
    Text,
    Tokens,
} from "@accurx/design";

import { SkeletonBlock } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { StyledHintBannerWrapper } from "./WorkspaceDetailsForm.styles";
import {
    WORKSPACE_DESCRIPTION_ID,
    WORKSPACE_NAME_CHAR_LIMIT,
    WORKSPACE_NAME_ID,
    WORKSPACE_SPECIALTY_ID,
} from "./constants";

type CommonInputProps = {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    error?: JSX.Element | string | null;
    value: string;
};

type InputProps = CommonInputProps & {
    description: string | JSX.Element;
    title: string;
    id: string;
    isDisabled?: boolean;
    charLimit?: number;
};

/**
 * Note: For optimum usability, character count should only be used as a visual
 * cue and not take away control from the user by restricting input.
 */
const InputField = ({
    description,
    title,
    id,
    isDisabled,
    value,
    onChange,
    error = null,
    charLimit,
}: InputProps) => {
    const charCount = value.trim().length;

    return (
        <FormFieldWrapper
            labelProps={{ htmlFor: id }}
            label={
                <Text variant="label" skinny>
                    {title}
                </Text>
            }
            subLabelProps={{ id: `${id}-hint` }}
            subLabel={description}
            errors={error ? [error] : undefined}
            metaInfo={
                charLimit ? (
                    <Text
                        variant="note"
                        as="span"
                        colour={charCount > charLimit ? "red" : "zinc"}
                    >
                        {charCount} / {charLimit}
                    </Text>
                ) : undefined
            }
        >
            <Input
                id={id}
                disabled={isDisabled}
                value={value}
                onChange={onChange}
                hasErrors={!!error}
                aria-describedby={`${id}-hint`}
            />
        </FormFieldWrapper>
    );
};

const WorkspaceNameHintBanner: JSX.Element = (
    <StyledHintBannerWrapper>
        <Feedback
            colour="information"
            title="The workspace name will appear on all messages to patients"
        />
    </StyledHintBannerWrapper>
);

export const WorkspaceNameField = ({
    onChange,
    value,
    error,
}: CommonInputProps) => {
    return (
        <InputField
            title="Name"
            description={WorkspaceNameHintBanner}
            id={WORKSPACE_NAME_ID}
            onChange={onChange}
            value={value}
            error={error}
            charLimit={WORKSPACE_NAME_CHAR_LIMIT}
        />
    );
};

export const WorkspaceDescriptionField = ({
    onChange,
    value,
    error,
}: CommonInputProps) => {
    return (
        <InputField
            title="Description (optional)"
            description="Describe your workspace e.g. type of work or patient cohort."
            id={WORKSPACE_DESCRIPTION_ID}
            onChange={onChange}
            value={value}
            error={error}
            charLimit={100}
        />
    );
};

export const WorkspaceSpecialtyField = ({
    initialSelection = undefined,
    options,
    onChange,
    noApplicableSpecialtyOption,
    isLoading = false,
    error = null,
}: {
    initialSelection?: Option | undefined;
    options: Option[];
    onChange: (selected: Option) => void;
    noApplicableSpecialtyOption: Option;
    isLoading?: boolean;
    error?: string | null;
}) => {
    return (
        <FormFieldWrapper
            labelProps={{ htmlFor: WORKSPACE_SPECIALTY_ID }}
            label={
                <Text variant="label" skinny>
                    Specialty
                </Text>
            }
            subLabelProps={{ id: `${WORKSPACE_SPECIALTY_ID}-hint` }}
            subLabel="Select the most applicable specialty for this workspace."
            errors={error ? [error] : undefined}
        >
            {isLoading ? (
                <SkeletonBlock height={Tokens.SIZES[5]} width="100%" />
            ) : (
                <SearchSelect
                    id={WORKSPACE_SPECIALTY_ID}
                    initSelectedValues={initialSelection}
                    options={[...options, noApplicableSpecialtyOption]}
                    onChangeHandler={(selected) => onChange(selected as Option)}
                    dimension="medium"
                    emptyGroup={{
                        options: [noApplicableSpecialtyOption],
                        heading: "No results",
                    }}
                    aria-describedby={`${WORKSPACE_SPECIALTY_ID}-hint`}
                    error={Boolean(error)}
                />
            )}
        </FormFieldWrapper>
    );
};

export const WorkspaceAdminField = ({ value }: CommonInputProps) => {
    return (
        <InputField
            title="Admin"
            description="An admin has additional controls to manage your workspace. You can add more admins after the workspace is created."
            id="workspace-admin"
            isDisabled={true}
            value={value}
        />
    );
};
