import { ReactNode } from "react";

import { Spinner } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import NotFound from "NotFound";
import { Switch, useLocation, useParams } from "react-router";
import { Redirect, generatePath } from "react-router-dom";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { SimpleRoute } from "app/AppRoute";
import { TEMP_ROUTES_WEBINBOX_REDIRECTS } from "shared/Routes";
import { useOptionalConversationManager } from "shared/concierge/conversations/context/ConversationManagerContext";

import { ConversationGroupPage } from "./pages/ConversationGroupPage";
import { ConversationPage } from "./pages/ConversationPage";

// Workspace id is already passed in from the parent component
type PossibleWorkspaceConversationsParams = {
    userId?: string;
    teamId?: string;
    conversationId?: string;
};

const oldConversationIdRegex = /^t:(\d+)-id:(\w+)$/;

const mapOldConversationIdToNewConversationId = (
    id: string,
): string | undefined => {
    const match = id.match(oldConversationIdRegex);
    return match ? `t-${match[1]}-${match[2]}` : undefined;
};

/**
 * While we gradually release Unified Inbox to more users
 * we want to ensure that all the old webinbox URLs get redirected
 * to exactly the right place in the Unified Inbox, folder mappings included
 *
 * If unified inbox is enabled for the workspace, it will redirect to the provided unified inbox path
 * and add the needed filters/query params;
 * otherwise, it will behave as the old webinbox:
 * - if a conversation manager exists, it will render the oldWebInboxPage
 * - if a conversation manager doesn't exist, it will render a Spinner
 */
const UnifiedInboxRedirectOrOldWebInboxPage = ({
    oldWebInboxPage,
    getUnifiedInboxPath,
}: {
    /** Returns Unified Inbox page to redirect to */
    getUnifiedInboxPath: (
        params: PossibleWorkspaceConversationsParams,
    ) => string;
    oldWebInboxPage: ReactNode;
}) => {
    const manager = useOptionalConversationManager();
    const isUnifiedInboxEnabled = useFeatureFlag(
        "AccurxWebEmbeddedUnifiedInbox",
    );

    const dynamicRouteParams =
        useParams<PossibleWorkspaceConversationsParams>();
    // This would be Open/Done filters and other such params
    const queryParams = useLocation()?.search ?? "";

    if (isUnifiedInboxEnabled) {
        const newPath = getUnifiedInboxPath(dynamicRouteParams);

        const newConversationId = dynamicRouteParams.conversationId
            ? mapOldConversationIdToNewConversationId(
                  dynamicRouteParams.conversationId,
              )
            : undefined;

        // Add conversationId to query params if present in the route
        const conversationIdParam = newConversationId
            ? `${queryParams ? "&" : "?"}conversationId=${newConversationId}`
            : "";

        return (
            <Redirect to={`${newPath}${queryParams}${conversationIdParam}`} />
        );
    }

    if (!manager) {
        // This is expected to occur while the conversation manager is loading
        // We do this so we can guarantee all children beneath here can get a
        // conversation manager
        return <Spinner />;
    }
    return <>{oldWebInboxPage}</>;
};

type WorkspaceConversationsSectionProps = {
    workspaceId: number;
};

/**
 * This component acts as a controller for all
 * routes nested under "/w/:workspaceId/conversations"
 */
export const WorkspaceConversationsSection = ({
    workspaceId,
}: WorkspaceConversationsSectionProps): JSX.Element => {
    useAccurxWebTitle("My conversations");
    const isUnifiedInboxEnabled = useFeatureFlag(
        "AccurxWebEmbeddedUnifiedInbox",
    );
    const queryParams = useLocation()?.search ?? "";

    return (
        <Switch>
            {/* MY FOLDERS */}

            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS
                        .conversationInMeAssignedFolder.oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS
                                .conversationInMeAssignedFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                .newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS.conversationInMeSentFolder
                        .oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS
                                .conversationInMeSentFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.meSentFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.meSentFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>

            {/* USER FOLDERS */}

            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS
                        .conversationInUserAssignedFolder.oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={(params) => {
                        // Ideally go to user's folder
                        if (params.userId) {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS
                                    .conversationInUserAssignedFolder.newUrl,
                                {
                                    workspaceId,
                                    colleagueId: params.userId,
                                },
                            );
                            // Fallback, redirect to my inbox
                        } else {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                    .newUrl,
                                {
                                    workspaceId,
                                },
                            );
                        }
                    }}
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.userAssignedFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={(params) => {
                        // Ideally go to the user folder
                        if (params.userId) {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS
                                    .userAssignedFolder.newUrl,
                                {
                                    workspaceId,
                                    colleagueId: params.userId,
                                },
                            );
                            // Fallback, redirect to my inbox
                        } else {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                    .newUrl,
                                {
                                    workspaceId,
                                },
                            );
                        }
                    }}
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS.conversationUserSent.oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.conversationUserSent
                                .newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.userSentFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.userSentFolder
                                .newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>

            {/* "TEAM" FOLDERS */}

            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS
                        .conversationInTeamAssignedFolder.oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={(params) => {
                        if (params.teamId) {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS
                                    .conversationInTeamAssignedFolder.newUrl,
                                {
                                    workspaceId,
                                    teamId: params.teamId,
                                },
                            );
                            // Fallback, redirect to my inbox
                        } else {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                    .newUrl,
                                {
                                    workspaceId,
                                },
                            );
                        }
                    }}
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.teamAssignedFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={(params) => {
                        if (params.teamId) {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS
                                    .teamAssignedFolder.newUrl,
                                {
                                    workspaceId,
                                    teamId: params.teamId,
                                },
                            );
                            // Fallback, redirect to my inbox
                        } else {
                            return generatePath(
                                TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                    .newUrl,
                                {
                                    workspaceId,
                                },
                            );
                        }
                    }}
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>

            {/* "ALL" FOLDERS */}

            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS
                        .conversationInAllAssignedFolder.oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS
                                .conversationInAllAssignedFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS.conversationWithoutAFolder
                        .oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS
                                .conversationWithoutAFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.allAssignedFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.allAssignedFolder
                                .newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS.conversationInAllSentFolder
                        .oldUrl
                }
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS
                                .conversationInAllSentFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationPage />}
                />
            </SimpleRoute>
            <SimpleRoute
                path={TEMP_ROUTES_WEBINBOX_REDIRECTS.allSentFolder.oldUrl}
            >
                <UnifiedInboxRedirectOrOldWebInboxPage
                    getUnifiedInboxPath={() =>
                        generatePath(
                            TEMP_ROUTES_WEBINBOX_REDIRECTS.allSentFolder.newUrl,
                            {
                                workspaceId,
                            },
                        )
                    }
                    oldWebInboxPage={<ConversationGroupPage />}
                />
            </SimpleRoute>

            {/* Catch all redirects / not found */}
            <Redirect
                exact
                from={
                    TEMP_ROUTES_WEBINBOX_REDIRECTS.mainConversationPage.oldUrl
                }
                to={`${
                    isUnifiedInboxEnabled
                        ? generatePath(
                              TEMP_ROUTES_WEBINBOX_REDIRECTS
                                  .mainConversationPage.newUrl,
                              { workspaceId },
                          )
                        : generatePath(
                              TEMP_ROUTES_WEBINBOX_REDIRECTS.meAssignedFolder
                                  .oldUrl,
                              {
                                  workspaceId,
                              },
                          )
                }${queryParams}`}
            />
            <SimpleRoute path="*">
                <NotFound />
            </SimpleRoute>
        </Switch>
    );
};
