import React, { useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    CollaborativeWorkspaceResponse,
    ExampleWorkspace,
} from "@accurx/api/account";
import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { Button, Feedback, Input, Text, usePagination } from "@accurx/design";
import { LinkIcon } from "@accurx/design/dist/components/Link/LinkIcon";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { useFuzzyFilter } from "@accurx/hooks";
import { BackButton } from "@accurx/navigation";
import {
    Link,
    generatePath,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";

import {
    StyledCardItem,
    StyledCardList,
    StyledLink,
    StyledMain,
    StyledPageWrapper,
    StyledTwoThirdsContainer,
} from "../../components/Layout.styles";
import { PaginationControls } from "../../components/Pagination/PaginationControls";
import { PaginationLabel } from "../../components/Pagination/PaginationLabel";
import { SkeletonList } from "../../components/SkeletonList/SkeletonList";
import { useGetWorkspacesQuery } from "../../hooks/queries";
import { useGetExampleWorkspacesQuery } from "../../hooks/queries/useGetExampleWorkspacesQuery";
import {
    ROOT_ROUTES,
    SUPPORT_ARTICLE_WORKSPACE_GUIDANCE,
    WORKSPACE_MANAGEMENT_ROUTES,
} from "../../routes";
import { ExampleWorkspaceItem } from "./WorkspaceItem/ExampleWorkspaceItem";
import { ExistingWorkspaceItem } from "./WorkspaceItem/ExistingWorkspaceItem";
import { UserflowIds } from "./constants";
import { getCombinedWorkspaces } from "./helpers";

const exampleWorkspaceTypeCheck = (
    workspace: ExampleWorkspace | CollaborativeWorkspaceResponse,
): workspace is ExampleWorkspace => {
    return !("id" in workspace);
};

const CreateNewWorkspaceCard = ({ workspaceId }: { workspaceId: number }) => {
    const { search } = useLocation();
    const isOnboarding = Boolean(useRouteMatch(ROOT_ROUTES.workspaceJoin));
    const workspaceCreationPath = isOnboarding
        ? { pathname: ROOT_ROUTES.workspaceCreate, search }
        : generatePath(WORKSPACE_MANAGEMENT_ROUTES.workspaceCreate, {
              workspaceId,
          });

    return (
        <StyledCardItem>
            <Text skinny>
                If you cannot find a relevant workspace you can create one with
                a custom name
            </Text>
            <Link to={workspaceCreationPath}>
                <Button
                    as="span"
                    theme="secondary"
                    text="Create a workspace"
                    icon={{
                        id: "arrow",
                        name: "ArrowTail",
                        placement: "end",
                        rotation: "right",
                    }}
                    data-userflow-id={UserflowIds.joinWorkspace.createNewButton}
                />
            </Link>
        </StyledCardItem>
    );
};

export const JoinWorkspacePage = () => {
    const history = useHistory();

    const { orgId: workspaceId, defaultWorkspaceId: defaultOrgId } =
        useCurrentWorkspace();

    const { user } = useCurrentUser();

    const isOnboarding = Boolean(useRouteMatch(ROOT_ROUTES.workspaceJoin));

    const defaultOrgName =
        user.organisations.find(({ orgId }) => orgId === defaultOrgId)
            ?.organisationName || "";

    const track = useAnalytics();

    const pageWrapperRef = useRef<HTMLElement>(null);

    const [searchTerm, setSearchTerm] = useState("");

    const { data: existingWorkspaces = [], status: workspacesQueryStatus } =
        useGetWorkspacesQuery(
            { organisationId: defaultOrgId },
            { refetchOnMount: true },
        );

    const { data: exampleWorkspacesData, status: exampleWorkspacesStatus } =
        useGetExampleWorkspacesQuery({ workspaceId }, { refetchOnMount: true });

    const isLoading = [workspacesQueryStatus, exampleWorkspacesStatus].includes(
        "loading",
    );

    // List does not include `exampleWorkspacesStatus` since if this errors, the rest of the page can still function fine
    const hasError = [workspacesQueryStatus].includes("error");

    const combinedWorkspaces = getCombinedWorkspaces({
        existingWorkspaces,
        exampleWorkspaces: exampleWorkspacesData || [],
    });

    const filteredWorkspaces = useFuzzyFilter<
        CollaborativeWorkspaceResponse | ExampleWorkspace
    >(combinedWorkspaces, searchTerm, {
        threshold: 0.3,
        keys: ["name", "specialties", "description"],
    });

    const { currentPage, totalPages, onNext, onPrevious, goToPage, paginate } =
        usePagination({
            defaultPage: 1,
            defaultPageSize: 20,
            total: filteredWorkspaces.length,
        });

    const paginatedWorkspaces = paginate(filteredWorkspaces);

    useEffect(() => {
        if (!isLoading && !hasError) {
            track("WorkspaceJoin Page View", {});
        }
    }, [isLoading, hasError, track]);

    const handleBackClick = () => {
        history.goBack();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        goToPage(1);
        setSearchTerm(e.target.value);
    };

    const showPreviousResults = () => {
        onPrevious();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    const showNextResults = () => {
        onNext();
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    return (
        <StyledPageWrapper ref={pageWrapperRef} flexDirection="column">
            {!isOnboarding && <BackButton backCallback={handleBackClick} />}
            <StyledMain gap="3" flexDirection="column">
                {hasError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <>
                        <Text as="h1" variant="subtitle" skinny>
                            {`Join or create a workspace at ${defaultOrgName}`}
                        </Text>

                        <StyledTwoThirdsContainer>
                            <Text skinny>
                                A workspace is a group of people at your
                                organisation who would like to collaborate in
                                Accurx e.g. your specialty.{" "}
                                <StyledLink
                                    href={SUPPORT_ARTICLE_WORKSPACE_GUIDANCE}
                                    openInNewTab
                                >
                                    <LinkText text="Find out more about workspaces" />
                                    <LinkIcon />
                                </StyledLink>
                            </Text>
                        </StyledTwoThirdsContainer>

                        <Input
                            isSearchInput
                            placeholder={`Search workspaces at ${defaultOrgName}`}
                            onChange={handleSearch}
                            disabled={isLoading}
                            aria-label={`Search workspaces at ${defaultOrgName}`}
                        />
                        {isLoading ? (
                            <SkeletonList quantity={3} />
                        ) : (
                            <>
                                <PaginationLabel
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    totalCount={filteredWorkspaces.length}
                                    appliedLimit={20}
                                />
                                <StyledCardList
                                    key={`${searchTerm}-${currentPage}`}
                                >
                                    {paginatedWorkspaces.map((workspace) => {
                                        const isExampleWorkspace =
                                            exampleWorkspaceTypeCheck(
                                                workspace,
                                            );

                                        return isExampleWorkspace ? (
                                            <ExampleWorkspaceItem
                                                key={`example-workspace-${workspace.name}`}
                                                name={workspace.name}
                                                specialtyId={
                                                    workspace.specialties[0].id
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                            />
                                        ) : (
                                            <ExistingWorkspaceItem
                                                id={workspace.id}
                                                key={workspace.id}
                                                name={workspace.name}
                                                description={
                                                    workspace.description
                                                }
                                                users={workspace.users}
                                                specialty={
                                                    workspace.specialties[0]
                                                }
                                                isRecommended={
                                                    workspace.isRecommended
                                                }
                                            />
                                        );
                                    })}
                                    {currentPage === totalPages && (
                                        <CreateNewWorkspaceCard
                                            workspaceId={workspaceId}
                                        />
                                    )}
                                </StyledCardList>
                                {filteredWorkspaces.length > 0 && (
                                    <PaginationControls
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPrevious={showPreviousResults}
                                        onNext={showNextResults}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};
