import { RefCallback, useRef } from "react";

import { Conversation } from "@accurx/concierge/types";
import { useInboxOptionalParams } from "domains/inbox/hooks/util/useInboxParams";
import { FixedSizeList } from "react-window";

/**
 * When the conversation feed mounts, if the currently viewed conversation
 * appears in the list we want to scroll the list down to display that
 * conversation.
 *
 * This hook returns a ref callback function that should be provided to the
 * react-window FixedSizeList. The first time the list mounts it will scroll to
 * the correct element.
 */
export const useScrollToCurrentConversation = (
    members: Conversation["id"][],
): RefCallback<FixedSizeList> => {
    const params = useInboxOptionalParams(["conversationId"]);
    const isFirstMountRef = useRef(true);

    return (ref) => {
        if (ref && isFirstMountRef.current && params.conversationId) {
            const activeIndex = members.indexOf(params.conversationId);
            ref.scrollToItem(activeIndex, "center");
        }

        isFirstMountRef.current = false;
    };
};
