import { FormEvent, useState } from "react";

import { Complexity, FloreyScoreStatus } from "@accurx/api/ticket";
import {
    Button,
    Checkbox,
    Flex,
    FormFieldWrapper,
    Grid,
    Input,
    RadioGroup,
    Text,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import {
    Filter,
    trackQuestionnaireResponsesFilterApplyClick,
} from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { FiltersSchema } from "app/questionnaireScoreboard/schemas";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import {
    COMPLEXITY_LABELS,
    FILTER_FORM_ID,
    FILTER_FORM_LABEL_ID,
    FLOREY_STATUS_LABELS,
} from "./constants";
import { DateTypeFilter, FilterOptions } from "./types";

const FiltersFormBody = ({
    onFiltersApplied,
    initialValues,
}: {
    initialValues: FilterOptions;
    onFiltersApplied: (filters: FilterOptions) => void;
}) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    const [selectedDateStart, setSelectedDateStart] = useState(
        initialValues.dateStart,
    );
    const [selectedDateType, setSelectedDateType] = useState<DateTypeFilter>(
        initialValues.dateType,
    );
    const [selectedDateEnd, setSelectedDateEnd] = useState(
        initialValues.dateEnd,
    );
    const [selectedStatuses, setSelectedStatuses] = useState(
        initialValues.status,
    );
    const [selectedComplexity, setSelectedComplexity] = useState(
        initialValues.complexity,
    );
    const [validationErrors, setValidationErrors] = useState<
        Partial<Record<keyof FilterOptions, string[]>>
    >({});

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setValidationErrors({});

        const filters = {
            dateStart: selectedDateStart,
            dateEnd: selectedDateEnd,
            dateType: selectedDateType,
            status: selectedStatuses,
            complexity: selectedComplexity,
        };

        const validatedFilters = FiltersSchema.safeParse(filters);
        if (validatedFilters.success === false) {
            setValidationErrors(validatedFilters.error.flatten().fieldErrors);
            return;
        }

        const appliedFilterNames: Filter[] = [];
        if (selectedDateStart && selectedDateType === "date-sent") {
            appliedFilterNames.push("DateStart");
        }
        if (selectedDateEnd && selectedDateType === "date-sent") {
            appliedFilterNames.push("DateEnd");
        }
        if (selectedDateStart && selectedDateType === "date-answered") {
            appliedFilterNames.push("AnsweredStart");
        }
        if (selectedDateEnd && selectedDateType === "date-answered") {
            appliedFilterNames.push("AnsweredEnd");
        }
        if (selectedStatuses.length) {
            appliedFilterNames.push("Status");
        }
        if (selectedComplexity.length) {
            appliedFilterNames.push("Complexity");
        }

        trackQuestionnaireResponsesFilterApplyClick({
            ...loggedInProps,
            conditionId: PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
            filterName: appliedFilterNames,
        });

        onFiltersApplied(filters);
    };

    const handleOnStatusCheck =
        (statusName: FloreyScoreStatus) => (checked: boolean) => {
            if (checked) {
                setSelectedStatuses((previousStatuses) => [
                    ...previousStatuses,
                    statusName,
                ]);
            } else {
                setSelectedStatuses((previousStatuses) =>
                    previousStatuses.filter((status) => status !== statusName),
                );
            }
        };

    const handleOnComplexityCheck =
        (complexityName: Complexity) => (checked: boolean) => {
            if (checked) {
                setSelectedComplexity((previousComplexity) => [
                    ...previousComplexity,
                    complexityName,
                ]);
            } else {
                setSelectedComplexity((previousComplexity) =>
                    previousComplexity.filter(
                        (complexity) => complexity !== complexityName,
                    ),
                );
            }
        };

    return (
        <form
            id={FILTER_FORM_ID}
            noValidate
            onSubmit={handleFormSubmit}
            aria-labelledby={FILTER_FORM_LABEL_ID}
        >
            <RadioGroup
                formFieldWrapperProps={{
                    label: "Filter by dates",
                }}
                name="date-type-filter"
                onChange={({ target: { value } }) =>
                    setSelectedDateType(value as DateTypeFilter)
                }
                checkedValue={selectedDateType}
                theme="bordered"
                columns={2}
                radioInputs={[
                    {
                        id: "date-sent",
                        label: "Date sent",
                        value: "date-sent",
                    },
                    {
                        id: "date-answered",
                        label: "Date answered",
                        value: "date-answered",
                    },
                ]}
            />
            <Flex flexDirection="column" gap="3">
                <Grid columns="1fr 1fr" gap="1">
                    <FormFieldWrapper
                        label="Dates from"
                        labelProps={{
                            htmlFor: "date-start-input",
                        }}
                        errors={validationErrors.dateStart}
                    >
                        <Input
                            id="date-start-input"
                            type="date"
                            max={
                                selectedDateEnd ||
                                DateHelpers.formatDate(
                                    new Date().toISOString(),
                                    DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
                                )
                            }
                            value={selectedDateStart}
                            onChange={({ target: { value: dateString } }) =>
                                setSelectedDateStart(dateString)
                            }
                            hasErrors={!!validationErrors.dateStart}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Dates to"
                        labelProps={{
                            htmlFor: "date-end-input",
                        }}
                        errors={validationErrors.dateEnd}
                    >
                        <Input
                            id="date-end-input"
                            type="date"
                            min={selectedDateStart}
                            max={DateHelpers.formatDate(
                                new Date().toISOString(),
                                DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
                            )}
                            value={selectedDateEnd}
                            onChange={({ target: { value: dateString } }) =>
                                setSelectedDateEnd(dateString)
                            }
                            hasErrors={!!validationErrors.dateEnd}
                        />
                    </FormFieldWrapper>
                </Grid>

                <Flex as="fieldset" flexDirection="column" gap="1">
                    <Text as="legend" variant="label">
                        Status
                    </Text>
                    {Object.values(FloreyScoreStatus).map((status) => (
                        <Checkbox
                            key={`${status}-filter`}
                            id={`${status}-filter`}
                            theme="bordered"
                            text={FLOREY_STATUS_LABELS[status]}
                            checked={selectedStatuses.includes(status)}
                            onCheckChange={handleOnStatusCheck(status)}
                        />
                    ))}
                </Flex>

                <Flex as="fieldset" flexDirection="column" gap="1">
                    <Text as="legend" variant="label">
                        Complexity
                    </Text>
                    {Object.values(Complexity).map((complexity) => (
                        <Checkbox
                            key={`${complexity}-filter`}
                            id={`${complexity}-filter`}
                            theme="bordered"
                            text={COMPLEXITY_LABELS[complexity]}
                            checked={selectedComplexity.includes(complexity)}
                            onCheckChange={handleOnComplexityCheck(complexity)}
                        />
                    ))}
                </Flex>
            </Flex>
        </form>
    );
};

const FiltersFormSubmitButton = () => {
    return <Button text="Apply filters" form={FILTER_FORM_ID} type="submit" />;
};

const FiltersFormClearButton = ({
    onFiltersClear,
}: {
    onFiltersClear: () => void;
}) => {
    return (
        <Button
            text="Clear filters"
            theme="secondary"
            form={FILTER_FORM_ID}
            type="reset"
            onClick={onFiltersClear}
        />
    );
};

export const FiltersForm = {
    Body: FiltersFormBody,
    SubmitButton: FiltersFormSubmitButton,
    ClearButton: FiltersFormClearButton,
};
