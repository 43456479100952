import { useState } from "react";

import {
    QuestionnaireSummary,
    StatusChange,
    StatusQuery,
} from "@accurx/api/florey-builder";
import {
    Button,
    ButtonLink,
    Ds,
    Flex,
    FormFieldFeedback,
    Switch,
    Table,
    Text,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers, Log } from "@accurx/shared";
import { useHistory, useParams } from "react-router";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    useQuestionnaireStatusMutation,
    useShareQuestionnaireMutation,
} from "app/hooks/mutations";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import {
    ConfirmDeleteModal,
    ConfirmEditModal,
    ConfirmGloballyAccessibleModal,
    ConfirmPublishModal,
} from "../components/ConfirmationModal";
import { genericSuccess, statusChangeError } from "../components/ErrorToasts";
import {
    routeExportAsJson,
    routeNameAndQuestions,
    routeShare,
} from "../constants/paths";

const mutationErrors = {
    [StatusChange.Archive]: "",
    [StatusChange.Delete]:
        "[FloreyBuilder] - handleDelete - delete did not succeed",
    [StatusChange.None]: "",
    [StatusChange.Publish]:
        "[FloreyBuilder] - handlePublish - publish did not succeed",
    [StatusChange.Review]: "",
};

const mutationSuccess = {
    [StatusChange.Archive]: "",
    [StatusChange.Delete]: "Florey deleted",
    [StatusChange.None]: "",
    [StatusChange.Publish]: "Florey published",
    [StatusChange.Review]: "",
};

const mapSummaryStatusToText = (
    status: StatusQuery | undefined,
): "Draft" | "Reviewing" | "Published" | "Deleted" | "None" | null => {
    switch (status) {
        case StatusQuery.Draft:
            return "Draft";
        case StatusQuery.Reviewing:
            return "Reviewing";
        case StatusQuery.Published:
            return "Published";
        case StatusQuery.Deleted:
            return "Deleted";
        case StatusQuery.None:
            return "None";
        default:
            return null;
    }
};

const StatusBadge = ({
    status,
}: {
    status: StatusQuery | undefined;
}): JSX.Element => {
    switch (status) {
        case StatusQuery.Reviewing:
            return <Ds.Badge color="green">Ready to test</Ds.Badge>;
        case StatusQuery.Draft:
        default:
            return <Ds.Badge color="yellow">Incomplete</Ds.Badge>;
    }
};

export const CustomFloreySummary = ({
    summary,
    isSharingEnabled,
    isGlobalCustomFloreyEnabled,
    onShareError,
}: {
    summary: QuestionnaireSummary;
    /**
     * @deprecated
     * in the future, remove this and directly query the
     * feature flag in this component. This is temporary while we
     * sort out the domain & route restructure which will give us
     * access to using the feature flag based on the workspace ID
     * from the URL.
     */
    isSharingEnabled: boolean;
    /**
     * @deprecated
     * in the future, remove this and directly query the
     * feature flag in this component. This is temporary while we
     * sort out the domain & route restructure which will give us
     * access to using the feature flag based on the workspace ID
     * from the URL.
     */
    isGlobalCustomFloreyEnabled: boolean;
    /**
     * A callback triggered when the questionnaire fails to share
     * @param summary The QuestionnaireSummary which failed to share
     */
    onShareError?: (summary: QuestionnaireSummary) => void;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();

    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isGloballyAccessibleModalOpen, setIsGloballyAccessibleModalOpen] =
        useState(false);

    const updateStatusMutation = useQuestionnaireStatusMutation({
        onError: (error, { status }) => {
            Log.error(mutationErrors[status], {
                originalException: error,
            });
            statusChangeError(error.message);
        },
        onSuccess: (_data, { status }) => {
            genericSuccess(mutationSuccess[status]);
        },
    });

    const shareQuestionnaireMutation = useShareQuestionnaireMutation({
        onSuccess: (data) => {
            history.push(routeShare(orgId, data.shareId));
        },
        onError: (error) => {
            Log.error("[FloreyBuilder] - handleShare - share did not succeed", {
                originalException: error,
            });
            onShareError?.(summary);
        },
    });

    const routeToEdit = () => {
        history.push(routeNameAndQuestions(orgId, summary.id.toString()));
    };

    function handleEdit(): void {
        if (summary.status === StatusQuery.Published) {
            setIsEditModalOpen(true);
        } else {
            FlemingAnalyticsTracker.trackQuestionnaireOverviewAction({
                ...analyticsLoggedInProps,
                questionnaireId: summary.id,
                questionnaireStatus: mapSummaryStatusToText(summary.status),
                actionSelected: "Edit",
            });
            routeToEdit();
        }
    }

    function confirmEdit(): void {
        FlemingAnalyticsTracker.trackQuestionnaireOverviewAction({
            ...analyticsLoggedInProps,
            questionnaireId: summary.id,
            questionnaireStatus: mapSummaryStatusToText(summary.status),
            actionSelected: "Edit",
        });

        routeToEdit();
    }

    function confirmPublish(): void {
        FlemingAnalyticsTracker.trackQuestionnairePublishConfirm({
            ...analyticsLoggedInProps,
            questionnaireId: summary.id ?? null,
            pageOrigin: "QuestionnaireHome",
        });

        updateStatusMutation.mutate({
            workspaceId: parseInt(orgId),
            questionnaireId: summary.id,
            status: StatusChange.Publish,
            questionnaireRowVersion: summary.questionnaireRowVersion,
            isGloballyAccessible: summary.isGloballyAccessible,
        });

        setIsPublishModalOpen(false);
    }

    function confirmDelete(): void {
        FlemingAnalyticsTracker.trackQuestionnaireDeleteConfirm({
            ...analyticsLoggedInProps,
            questionnaireId: summary.id,
        });

        updateStatusMutation.mutate({
            questionnaireId: summary.id,
            workspaceId: parseInt(orgId),
            status: StatusChange.Delete,
            questionnaireRowVersion: summary.questionnaireRowVersion,
            isGloballyAccessible: summary.isGloballyAccessible,
        });

        setIsDeleteModalOpen(false);
    }

    function handleShare(): void {
        FlemingAnalyticsTracker.trackQuestionnaireOverviewAction({
            ...analyticsLoggedInProps,
            questionnaireId: summary.id,
            questionnaireStatus: mapSummaryStatusToText(summary.status),
            actionSelected: "Share",
        });

        shareQuestionnaireMutation.mutate({
            questionnaireId: summary.id,
            workspaceId: parseInt(orgId),
            questionnaireRowVersion: summary.questionnaireRowVersion,
        });
    }

    function handleGloballyAccessibleToggle() {
        updateStatusMutation.mutate({
            questionnaireId: summary.id,
            workspaceId: parseInt(orgId),
            status: StatusChange.Publish,
            isGloballyAccessible: !summary.isGloballyAccessible,
            questionnaireRowVersion: summary.questionnaireRowVersion,
        });

        setIsGloballyAccessibleModalOpen(false);
    }

    return (
        <>
            <ConfirmPublishModal
                onConfirm={confirmPublish}
                onCancel={() => {
                    setIsPublishModalOpen(false);
                }}
                isOpen={isPublishModalOpen}
                isLoading={updateStatusMutation.isLoading}
                summary={summary}
            />

            <ConfirmDeleteModal
                onConfirm={confirmDelete}
                onCancel={() => {
                    setIsDeleteModalOpen(false);
                }}
                isOpen={isDeleteModalOpen}
                isLoading={updateStatusMutation.isLoading}
                summary={summary}
            />

            <ConfirmEditModal
                onConfirm={confirmEdit}
                onCancel={() => {
                    setIsEditModalOpen(false);
                }}
                isOpen={isEditModalOpen}
                isLoading={updateStatusMutation.isLoading}
                summary={summary}
            />

            <ConfirmGloballyAccessibleModal
                onConfirm={handleGloballyAccessibleToggle}
                onCancel={() => {
                    setIsGloballyAccessibleModalOpen(false);
                }}
                isOpen={isGloballyAccessibleModalOpen}
                isLoading={updateStatusMutation.isLoading}
                summary={summary}
            />

            <Table.Row key={summary.id}>
                <Table.DataCell>
                    {summary.lastUpdatedAt && (
                        <>
                            <Text>
                                {DateHelpers.formatTime(
                                    summary.lastUpdatedAt,
                                    DateFormatOptions.TIME,
                                )}
                            </Text>
                            <Text>
                                {DateHelpers.formatDate(
                                    summary.lastUpdatedAt,
                                    DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                )}
                            </Text>
                        </>
                    )}
                </Table.DataCell>
                <Table.DataCell>
                    <Text>{summary.name}</Text>
                </Table.DataCell>
                <Table.DataCell>
                    {isGlobalCustomFloreyEnabled && !!summary.categoryName && (
                        <Text>
                            <Text as="span" skinny variant="label">
                                Category:
                            </Text>{" "}
                            {summary.categoryName}
                        </Text>
                    )}
                    {!!summary.userGroupName && (
                        <Text>
                            <Text as="span" skinny variant="label">
                                Responses sent to:
                            </Text>{" "}
                            {summary.userGroupName === "Unknown"
                                ? summary.status === StatusQuery.Published
                                    ? "Practice Floreys"
                                    : "not chosen"
                                : summary.userGroupName}
                        </Text>
                    )}
                </Table.DataCell>
                <Table.DataCell>
                    <Text>{summary.lastUpdatedByUser}</Text>
                </Table.DataCell>
                {summary.status !== StatusQuery.Published && (
                    <Table.DataCell>
                        <StatusBadge status={summary.status} />
                    </Table.DataCell>
                )}
                <Table.DataCell>
                    <Flex flexDirection="column" gap="1">
                        <div>
                            <Button
                                theme="secondary"
                                onClick={handleEdit}
                                icon={{ name: "Pencil" }}
                                text="Edit"
                                disabled={false}
                            />
                        </div>
                        {summary.status === StatusQuery.Reviewing && (
                            <div>
                                <Button
                                    theme="secondary"
                                    onClick={() => {
                                        setIsPublishModalOpen(true);
                                    }}
                                    icon={{ name: "Upload" }}
                                    text="Publish"
                                    disabled={false}
                                />
                            </div>
                        )}
                        <div>
                            <Button
                                theme="secondary"
                                onClick={() => {
                                    setIsDeleteModalOpen(true);
                                }}
                                icon={{ name: "Bin" }}
                                text="Delete"
                                disabled={false}
                            />
                        </div>
                        {summary.status === StatusQuery.Published &&
                            isSharingEnabled && (
                                <div>
                                    <Button
                                        theme="secondary"
                                        onClick={handleShare}
                                        icon={{ name: "Send" }}
                                        text="Share"
                                        disabled={false}
                                    />
                                    {shareQuestionnaireMutation.error && (
                                        <FormFieldFeedback
                                            text="Error sharing"
                                            variant="error"
                                        />
                                    )}
                                </div>
                            )}
                        {summary.status === StatusQuery.Published &&
                            isGlobalCustomFloreyEnabled && (
                                <>
                                    <div>
                                        <ButtonLink
                                            href={routeExportAsJson(
                                                orgId,
                                                summary.id.toString(),
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            theme="secondary"
                                            icon={{ name: "Assign" }}
                                            text="Export"
                                        />
                                    </div>
                                    <div>
                                        <Switch
                                            controlled
                                            checked={
                                                !!summary.isGloballyAccessible
                                            }
                                            onChange={() => {
                                                setIsGloballyAccessibleModalOpen(
                                                    true,
                                                );
                                            }}
                                            labelText="Globally Accessible"
                                        />
                                    </div>
                                </>
                            )}
                    </Flex>
                </Table.DataCell>
            </Table.Row>
        </>
    );
};
