import { Complexity, FloreyScoreStatus } from "@accurx/api/ticket";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useHistory } from "react-router";

import { FilterOptions } from "../../responsesDashboard/types";

export const FILTERS_URL_PARAMS: Record<
    keyof FilterOptions,
    keyof FilterOptions
> = {
    dateStart: "dateStart",
    dateEnd: "dateEnd",
    dateType: "dateType",
    status: "status",
    complexity: "complexity",
} as const;

const getStatusFilterFromUrlParams = (params: string[]) => {
    return params.filter((statusParam) =>
        Object.values(FloreyScoreStatus).includes(
            statusParam as FloreyScoreStatus,
        ),
    ) as FloreyScoreStatus[];
};

const getComplexityFilterFromUrlParams = (params: string[]) => {
    return params.filter((complexityParam) =>
        Object.values(Complexity).includes(complexityParam as Complexity),
    ) as Complexity[];
};

/**
 * Takes the search params on the current URL,
 * validates each of them,
 * and returns an object with the current filters
 * as well as a method to update them
 */
export const useQuestionnaireFilters = (): {
    filters: FilterOptions;
    updateFilters: (newFilters: FilterOptions) => void;
} => {
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);

    const dateTypeFromUrl = searchParams.get("dateType");
    const dateTypeValue =
        dateTypeFromUrl === "date-sent" || dateTypeFromUrl === "date-answered"
            ? dateTypeFromUrl
            : "date-sent";

    const dateStartFromUrl = searchParams.get("dateStart") || "";
    const dateStartValue = DateHelpers.checkDateFormats(dateStartFromUrl, [
        DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
    ])
        ? dateStartFromUrl
        : "";
    const dateEndFromUrl = searchParams.get("dateEnd") || "";
    const dateEndValue = DateHelpers.checkDateFormats(dateEndFromUrl, [
        DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
    ])
        ? dateEndFromUrl
        : "";
    const statusFilterValues = getStatusFilterFromUrlParams(
        searchParams.getAll("status"),
    );
    const complexityFilterValues = getComplexityFilterFromUrlParams(
        searchParams.getAll("complexity"),
    );

    const updateFilters = (newFilters: FilterOptions) => {
        const filterKeys = Object.keys(
            FILTERS_URL_PARAMS,
        ) as (keyof FilterOptions)[];

        filterKeys.forEach((filterKey) => {
            const newFilter = newFilters[filterKey];
            if (Array.isArray(newFilter)) {
                // Clean all existing values before adding new or existing ones
                searchParams.delete(filterKey);

                newFilter.forEach((filterValue) => {
                    searchParams.append(filterKey, filterValue);
                });
            } else {
                searchParams.set(filterKey, newFilter);
            }
        });

        history.replace(
            `${history.location.pathname}?${searchParams.toString()}`,
        );
    };

    return {
        filters: {
            dateStart: dateStartValue,
            dateEnd: dateEndValue,
            dateType: dateTypeValue,
            status: statusFilterValues,
            complexity: complexityFilterValues,
        },
        updateFilters,
    };
};
