import { AppointmentReminderConfigOptions } from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type RemindersAndFilterOptionsParameters = {
    workspaceId: string;
};

const getReminderConfigOptions = (
    workspaceId: string,
): Promise<IWrappedResult<AppointmentReminderConfigOptions>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/AppointmentReminders/:workspaceId/options",
            params: { workspaceId },
        }),
    );

export const useReminderConfigOptions = ({
    workspaceId,
}: RemindersAndFilterOptionsParameters) =>
    useQuery({
        queryKey: [
            "AppointmentReminders",
            "ReminderConfigOptions",
            { workspaceId },
        ],
        queryFn: async () => {
            const response = await getReminderConfigOptions(workspaceId);
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Request failed");
        },
    });
