import { ReactNode } from "react";

import { Card, Ds, Flex, Icon, Item, Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";

import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { sentenceCase } from "shared/formatters/String";

import { isContactable } from "../../isContactable";

type AppointmentCardProps = {
    actionButton?: ReactNode;
    appointment: ClinicAppointment;
};

export default function AppointmentCard({
    actionButton,
    appointment,
}: AppointmentCardProps) {
    const {
        appointmentType,
        displayName: patientName,
        nhsNumber,
        dateOfBirth,
        startTime,
        gender,
    } = appointment;

    const formattedNhsNumber = nhsNumber
        ? NhsNumberHelpers.formatNhsNumber(nhsNumber)
        : "Unknown";
    const formattedDOB = dateOfBirth
        ? DateHelpers.formatDate(
              dateOfBirth,
              DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
          )
        : "Unknown";
    const formattedGender = gender ? sentenceCase(gender) : "Unknown";

    return (
        <Card
            spacing={1.5}
            header={
                <CardHeader
                    appointmentStartTime={startTime || ""}
                    appointmentType={appointmentType}
                />
            }
        >
            <Flex
                gap="1 3"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
            >
                <Item>
                    {patientName && (
                        <Text variant="label" skinny>
                            {patientName}
                        </Text>
                    )}
                    <Flex gap="0.25 4" flexWrap="wrap">
                        <Text skinny>NHS: {formattedNhsNumber}</Text>
                        <Text skinny>Born: {formattedDOB}</Text>
                        <Text skinny>Gender: {formattedGender}</Text>
                    </Flex>
                    {!isContactable(appointment) && (
                        <Ds.Badge>
                            Cannot contact • Unable to match patient
                        </Ds.Badge>
                    )}
                </Item>
                {actionButton}
            </Flex>
        </Card>
    );
}

function CardHeader({
    appointmentStartTime,
    appointmentType,
}: {
    appointmentStartTime: string;
    appointmentType?: string | null;
}) {
    return (
        <Flex alignItems="center" justifyContent="space-between" gap="2">
            <Flex alignItems="center" gap="1">
                <Icon
                    name="Clock"
                    size={4}
                    halo={{ colour: "blue", luminosity: "high" }}
                />
                <Text skinny variant="label">
                    {DateHelpers.formatTime(
                        appointmentStartTime,
                        DateFormatOptions.TIME,
                    )}
                </Text>
            </Flex>

            {appointmentType && (
                <Ds.Badge color="blue">{appointmentType}</Ds.Badge>
            )}
        </Flex>
    );
}
