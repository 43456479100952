import { PatientThreadTicketCommandResult } from "@accurx/api/ticket";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import {
    Conversation,
    PatientSummary,
    TeamSummary,
    UserSummary,
} from "domains/concierge/types";

import { mapPatientThreadUserToUserSummary } from "./mapPatientThreadUserToUserSummary";
import { mapTicketItemUpdateToConversation } from "./mapTicketItemUpdateToConversation";

export const mapTicketCommandResultToConciergeUpdate = (
    result: PatientThreadTicketCommandResult,
): ConciergeUpdates => {
    const conversations: Conversation[] = [];
    const patients: PatientSummary[] = [];
    const users: UserSummary[] = [];
    const teams: TeamSummary[] = [];

    result.updates?.forEach((update) => {
        const conversation = mapTicketItemUpdateToConversation(update);

        if (conversation) {
            conversations.push(conversation);
        }

        if (update.referencedPatients) {
            patients.push(
                ...update.referencedPatients
                    .map(mapPatientThreadPatientDetailsToPatientSummary)
                    .filter(isInstance),
            );
        }

        if (update.users) {
            users.push(
                ...update.users
                    .map(mapPatientThreadUserToUserSummary)
                    .filter(isInstance),
            );
        }

        if (update.userGroups) {
            teams.push(
                ...update.userGroups
                    .map(mapPatientThreadUserGroupToTeamSummary)
                    .filter(isInstance),
            );
        }
    });

    return {
        conversations,
        patients,
        users: users,
        teams: teams,
    };
};
