import { Draft } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { shouldReplacePatient } from "domains/concierge/internal/util/shouldReplacePatient";
import { mergeExternalIds } from "domains/concierge/util/mergeExternalIds";

export const processUpdates = (
    state: Draft<ConciergeState["patients"]>,
    action: ConciergeAction<ConciergeUpdates>,
) => {
    const patients = state.items;
    const updates = action.payload.patients ?? [];

    for (const update of updates) {
        // if patient doesn't exist in a store yet - we wanna just add them
        if (!patients[update.patientId]) {
            patients[update.patientId] = update;
            continue;
        }

        // if patient already exist in a store, we take them by id and check:
        // does update OR existing entry of this patient has more fresh value
        // of demographicsLastRefreshed
        const shoulBePatientReplaced = shouldReplacePatient(
            patients[update.patientId],
            update,
        );

        // we take exisitng external ids of this patient, that we already have in a store
        const { externalIds = [] } = patients[update.patientId];

        const mergedExternalIds = mergeExternalIds(
            // external ids of a patient that already exist in the store
            // for example, these could be patient ids received via a ticket API, PLUS ids from an EMR
            // EMR ids go first domains/concierge/util/mapPatientDemographicsToPatientSummary.ts
            externalIds,
            // ids which aren't directly from the local EMR go second
            update.externalIds ?? [],
        );

        if (shoulBePatientReplaced) {
            patients[update.patientId] = {
                ...update,
                externalIds: mergedExternalIds,
            };
        } else {
            // When we receive an update for a patient, we would like to merge their external ids,
            // even if an update has more fresh value for demographicsLastRefreshed field.
            // All other fields keep previous updating logic:
            // it needs to be updated only in case when update's demographicsLastRefreshed is more fresh then of an existing patient in a store
            patients[update.patientId].externalIds = mergedExternalIds;
        }
    }

    return state;
};
