import { useEffect, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useAuth } from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";
import { Link, generatePath } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { useWorkspaceUsersQuery } from "../../hooks/queries";
import { WORKSPACE_MANAGEMENT_ROUTES } from "../../routes";
import {
    isPharmacy,
    isUserCreatedWorkspace,
} from "../../util/workspaceHelpers";

export const GlobalAdminPendingRequestsModal = () => {
    const [open, setOpen] = useState(false);

    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();
    const track = useAnalytics();

    const workspace = user.isLoggedIn
        ? user.organisations.find(({ orgId }) => orgId === selectedOrgId)
        : undefined;

    const isEnabled = Boolean(
        user.isLoggedIn &&
            workspace?.settings.isAdminUser &&
            (isPharmacy(workspace) || isUserCreatedWorkspace(workspace)),
    );

    const { isSuccess, data: { unapprovedUsers = [] } = {} } =
        useWorkspaceUsersQuery(
            { workspaceId: Number(selectedOrgId) },
            { enabled: isEnabled },
        );

    const handleView = () => {
        setOpen(false);
        track("AdminRequestPendingView Button Click", {});
    };

    useEffect(() => {
        if (selectedOrgId && isEnabled && unapprovedUsers.length) {
            // `isSuccess` implies that `isEnabled` is true
            setOpen(true);
            track("AdminRequestPending Component View", {});
        }
        // Deliberately using `isSuccess` as a dependency instead of
        // `unapprovedUsers` because we don't want to show the modal every time
        // the cache gets updated (by other components).
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrgId, isEnabled, isSuccess]);

    if (!selectedOrgId) {
        return null;
    }

    return (
        <Ds.Modal open={open} onOpenChange={setOpen}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        You have requests pending approval
                    </Ds.Modal.Title>
                    <Text>
                        Review these requests to let your colleagues view all
                        conversations and collaborate with others.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            text="Close"
                            theme="secondary"
                            onClick={() => setOpen(false)}
                        />
                        <Link
                            to={{
                                pathname: generatePath(
                                    WORKSPACE_MANAGEMENT_ROUTES.userManagement,
                                    { workspaceId: selectedOrgId },
                                ),
                                search: "?tab=unapproved",
                            }}
                            onClick={handleView}
                        >
                            <Button as="span" text="View requests" />
                        </Link>
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
