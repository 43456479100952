import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const EditorButton = styled.button<{ isActive?: boolean }>`
    font-weight: bold;
    color: ${({ isActive }) =>
        isActive
            ? Tokens.COLOURS.greyscale.white
            : Tokens.COLOURS.greyscale.metal};
    background-color: ${({ isActive }) =>
        isActive
            ? Tokens.COLOURS.primary.blue["100"]
            : Tokens.COLOURS.greyscale.white};
    border: ${({ isActive }) =>
        isActive ? Tokens.BORDERS.normal.blue : Tokens.BORDERS.normal.silver};
    border-right: ${Tokens.BORDERS.normal.transparent};
    padding: ${Tokens.SIZES["0.5"]} ${Tokens.SIZES["1"]};
    transition: all 0.2s ease;

    &:first-child {
        border-top-left-radius: ${Tokens.BORDERS.radius};
        border-bottom-left-radius: ${Tokens.BORDERS.radius};
    }

    &:last-child {
        border-right: ${({ isActive }) =>
            isActive
                ? Tokens.BORDERS.normal.blue
                : Tokens.BORDERS.normal.silver};
        border-top-right-radius: ${Tokens.BORDERS.radius};
        border-bottom-right-radius: ${Tokens.BORDERS.radius};
    }

    &:not(:disabled):hover {
        color: ${Tokens.COLOURS.primary.blue["100"]};
        background-color: ${Tokens.COLOURS.primary.blue["10"]};
        border: ${Tokens.BORDERS.soft.blue};
    }

    /* Focus and z-index trick to get better focus state for buttons that are
     * next to each other without any gutter space
    */
    &:focus {
        z-index: 1;
    }

    &:not(:focus) {
        z-index: 0;
    }

    &:disabled {
        color: ${Tokens.COLOURS.greyscale.stone};
        background-color: ${Tokens.COLOURS.greyscale.cloud};
    }
`;

export const EditorPopoverTrigger = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${Tokens.COLOURS.greyscale.metal};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};

    &:hover,
    &:focus {
        color: ${Tokens.COLOURS.primary.blue[100]};
        background-color: ${Tokens.COLOURS.primary.blue[10]};
        border: ${Tokens.BORDERS.normal.blue};
    }

    & span {
        padding: ${Tokens.SIZES["1"]};
        line-height: 1;
        font-weight: 700;
    }
`;

export const StyledMenuWrapper = styled.div`
    margin-bottom: ${Tokens.SIZES["1"]};
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledEditorButtonGroup = styled.div`
    padding: ${Tokens.SIZES["0.5"]};
    display: flex;
`;

export const StyledMenuSeparator = styled.div`
    border: 0.5px solid ${Tokens.COLOURS.greyscale.silver};
    margin: ${Tokens.SIZES["1.5"]} ${Tokens.SIZES["1.5"]};
`;
