import React, { ReactNode } from "react";

import {
    EditorComponent,
    Remirror,
    useHelpers,
    useRemirror,
} from "@remirror/react";
import {
    BoldExtension,
    BulletListExtension,
    HeadingExtension,
    ItalicExtension,
    LinkExtension,
    MarkdownExtension,
    OrderedListExtension,
    PlaceholderExtension,
} from "remirror/extensions";

import { StyledEditor } from "./Editor.styles";
import { EditorMenu } from "./Menu";

export const EditorProvider = ({
    content,
    label,
    editable = true,
    children,
}: {
    content: string;
    label?: string;
    editable?: boolean;
    children: ReactNode;
}) => {
    const { manager, state } = useRemirror({
        extensions: () => [
            new BoldExtension(),
            new ItalicExtension(),
            new LinkExtension({ autoLink: true }),
            new HeadingExtension({ levels: [3], defaultLevel: 3 }),
            new PlaceholderExtension({ placeholder: "Enter content here" }),
            new BulletListExtension(),
            new OrderedListExtension(),
            new MarkdownExtension(),
        ],
        content,
        selection: "start",
        stringHandler: "markdown",
    });

    return (
        <Remirror
            manager={manager}
            editable={editable}
            label={label}
            initialContent={state}
        >
            {children}
        </Remirror>
    );
};

type EditorProps = {
    onChange?: (markdown: string) => void;
};

export const Editor = ({ onChange }: EditorProps) => {
    const { isViewEditable, getMarkdown } = useHelpers(true);
    const markdown = getMarkdown();
    React.useEffect(() => {
        onChange && onChange(markdown);
    }, [onChange, markdown]);
    return (
        <StyledEditor editable={isViewEditable()}>
            {isViewEditable() && <EditorMenu />}
            <EditorComponent />
        </StyledEditor>
    );
};
