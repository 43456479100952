import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPageWrapper = styled.div`
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
`;

export const StyledThreadContainer = styled.div`
    flex: 1;
    overflow: auto;
    background: ${Tokens.COLOURS.greyscale.cloud};

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        min-height: 45%;
    }
`;

export const StyledBackButtonWrapper = styled.div`
    background: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
    position: sticky;
    top: 0;
    z-index: 1;

    padding: ${Tokens.SIZES[1]} 0;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    }
`;

export const StyledCompose = styled(Flex).attrs({
    gap: "3",
    flexDirection: "column",
})<{ isOpen: boolean }>`
    ${({ isOpen }) => isOpen && "flex: 1;"}
    background: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[3]};
    }
`;
