/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { LoadingStatus } from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

import { PatientIdType, ReceiptItem } from "./DeliveryReceipts.types";

export type DeliveryReceiptsState = {
    loadingStatus: LoadingStatus;
    receipts: ReceiptItem[];
    receiptHeaderType: PatientIdType;
    isRestrictedToOwnReceipts: boolean;
};

export const initialState: DeliveryReceiptsState = {
    loadingStatus: LoadingStatus.Initial,
    receipts: [],
    receiptHeaderType: PatientIdType.NhsNumber,
    isRestrictedToOwnReceipts: false,
};

export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CoreActions.LOAD_DELIVERY_RECEIPTS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loading,
            };
        case CoreActions.LOAD_DELIVERY_RECEIPTS_FAIL:
            return {
                ...state,
                loadingStatus: LoadingStatus.Failed,
            };
        case CoreActions.LOAD_DELIVERY_RECEIPTS_SUCCESS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                receipts: action.receipts,
                receiptHeaderType: action.receiptHeaderType,
                isRestrictedToOwnReceipts: action.isRestrictedToOwnReceipts,
            };
        default:
            return state;
    }
};
