import { Button, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledHintBannerWrapper = styled.div`
    display: flex;
    margin: ${Tokens.SIZES[1]} 0;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
`;

export const StyledFlex = styled(Flex)`
    padding-top: ${Tokens.SIZES[2]};
`;

export const StyledSpacer = styled.div`
    padding-bottom: ${Tokens.SIZES[3]};
`;
