import React, { useEffect, useMemo } from "react";

import {
    ButtonLink,
    Flex,
    Grid,
    Item,
    Spinner,
    StackPanel,
    Table,
    Text,
    useTable,
} from "@accurx/design";

import Linkify from "app/sharedComponents/linkify/Linkify";
import { LoadingStatus } from "shared/LoadingStatus";
import { useIsUserApproved } from "store/hooks";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { AvailabilityCellContent } from "./AvailabilityCellContent";
import { CategoriesFilter } from "./CategoriesFilter";
import {
    StyledSpacedItem,
    StyledTabPanelLayout,
    StyledTableBody,
} from "./ManageTemplates.styles";
import {
    FEEDBACK_SEARCH_TEXT,
    NO_TEMPLATES_TEXT,
    getCategoryPath,
    shouldDisplayCategoryHeader,
} from "./ManageTemplates.utils";
import { TabIds } from "./ManageTemplatesOverview";
import SnomedTag from "./SnomedTag";
import { useManageTemplatesData } from "./hooks";
import {
    routeManageTemplatesCopyToPractice,
    routeManageTemplatesCreate,
    routeManageTemplatesDelete,
    routeManageTemplatesEdit,
} from "./routeHelpers";
import { CountDistinctCategories } from "./utils";

type UserTemplatesPanelProps = {
    orgId: string;
    orgName: string;
};

export const UserTemplatesPanel = ({
    orgId,
    orgName,
}: UserTemplatesPanelProps): JSX.Element => {
    const {
        messageTemplatesUser: templates,
        messageTemplatesLoadingStatus: loadingStatus,
    } = useManageTemplatesData();

    const formattedData = React.useMemo(
        () =>
            templates.map((template) => ({
                ...template,
                templateName: template.title,
                details: template.body,
                category: getCategoryPath(template.path),
            })),
        [templates],
    );

    const table = useTable({
        data: formattedData ?? [],
        columnDefs: [
            {
                header: "Template name",
                id: "templateName",
            },
            {
                header: "Details",
                id: "details",
            },
            {
                header: "Category",
                id: "category",
            },
            {
                header: "Availability",
                id: "availability",
            },
            {
                header: "Actions",
                id: "actions",
            },
        ],
    });

    const isUserApproved: boolean = useIsUserApproved();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const countDistinctCategories = useMemo(() => {
        return CountDistinctCategories(templates);
    }, [templates]);

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: countDistinctCategories,
            currentTab: TabIds.UserTemplates,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties, countDistinctCategories]);
    return (
        <>
            <StyledTabPanelLayout>
                <StackPanel gutter={3} orientation="vertical">
                    <Text skinny as="h2" variant="subtitle">
                        My Templates
                    </Text>
                    <Text skinny>
                        Here you can create your own templates and they will
                        appear in the software for you to use. Each message will
                        still have a greeting (e.g. Dear Miss Flynn) and a
                        signature (e.g. {orgName}), so you won't need to create
                        these!
                    </Text>
                    <ButtonLink
                        href={routeManageTemplatesCreate({
                            orgId,
                            isOrgTemplate: false,
                        })}
                        text="Create New Template"
                    />
                    <Table>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header colSpan={5}>
                                    <Grid
                                        columns={{ xs: "1fr", sm: "1fr 1fr" }}
                                        gap="1"
                                    >
                                        <StackPanel>
                                            <Text variant={"subtitle"}>
                                                My templates
                                            </Text>
                                            {templates.length !== 0 && (
                                                <Text>
                                                    {FEEDBACK_SEARCH_TEXT}
                                                </Text>
                                            )}
                                        </StackPanel>

                                        <CategoriesFilter
                                            templates={templates}
                                            table={table}
                                            currentTable={TabIds.UserTemplates}
                                        />
                                    </Grid>
                                </Table.Header>
                            </Table.Row>
                            <Table.Row>
                                {table.columns.map((column) => (
                                    <Table.Header
                                        scope={"col"}
                                        key={column.id}
                                        colour="greyscale"
                                    >
                                        {column.header}
                                    </Table.Header>
                                ))}
                            </Table.Row>
                        </Table.Head>
                        <StyledTableBody>
                            {table.rows.map((row, index) => (
                                <React.Fragment key={row.id}>
                                    {shouldDisplayCategoryHeader({
                                        currentRow: row,
                                        currentIndex: index,
                                        allRows: table.rows,
                                    }) && (
                                        <Table.Row>
                                            <Table.Header
                                                colSpan={5}
                                                scope="colgroup"
                                                colour="greyscale"
                                            >
                                                <Text
                                                    skinny
                                                    variant="subtitle"
                                                    as="span"
                                                >
                                                    {row.categoryHeading}
                                                </Text>
                                            </Table.Header>
                                        </Table.Row>
                                    )}
                                    <Table.Row>
                                        <Table.Header scope="row">
                                            {row.templateName}
                                        </Table.Header>
                                        <Table.DataCell>
                                            <StackPanel>
                                                <Linkify>{row.details}</Linkify>
                                                {row.snomedCodes.map(
                                                    (snomedCode, index) => (
                                                        <SnomedTag
                                                            key={`${snomedCode.conceptId}-${index}`}
                                                            text={
                                                                snomedCode.term
                                                            }
                                                            code={
                                                                snomedCode.conceptId
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </StackPanel>
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            {row.category}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <AvailabilityCellContent
                                                orgId={orgId}
                                                template={row}
                                            />
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <StackPanel
                                                orientation="vertical"
                                                horizontalContentAlignment="left"
                                                gutter={1}
                                            >
                                                {isUserApproved && (
                                                    <ButtonLink
                                                        href={routeManageTemplatesCopyToPractice(
                                                            {
                                                                orgId: orgId,
                                                                templateId:
                                                                    row.id,
                                                            },
                                                        )}
                                                        text="Copy to organisation"
                                                        theme="primary"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    />
                                                )}

                                                <ButtonLink
                                                    href={routeManageTemplatesEdit(
                                                        {
                                                            orgId,
                                                            templateId: row.id,
                                                            isOrgTemplate:
                                                                false,
                                                        },
                                                    )}
                                                    text="Edit"
                                                    theme="secondary"
                                                />
                                                <ButtonLink
                                                    href={routeManageTemplatesDelete(
                                                        {
                                                            orgId,
                                                            templateId: row.id,
                                                            isOrgTemplate:
                                                                false,
                                                        },
                                                    )}
                                                    text="Delete"
                                                    theme="secondary"
                                                />
                                            </StackPanel>
                                        </Table.DataCell>
                                    </Table.Row>
                                </React.Fragment>
                            ))}
                        </StyledTableBody>
                        {table.rows.length > 0 && (
                            <Table.Foot>
                                <Table.Row>
                                    <Table.DataCell colSpan={5}>
                                        <Flex justifyContent={"center"}>
                                            <Item>
                                                Showing {table.rows.length}
                                            </Item>
                                        </Flex>
                                    </Table.DataCell>
                                </Table.Row>
                            </Table.Foot>
                        )}
                    </Table>
                    {templates && templates.length === 0 && (
                        <StyledSpacedItem>
                            <Text>{NO_TEMPLATES_TEXT}</Text>
                        </StyledSpacedItem>
                    )}
                    {loadingStatus === LoadingStatus.Loading && <Spinner />}
                </StackPanel>
            </StyledTabPanelLayout>
        </>
    );
};
