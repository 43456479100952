import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { ProductOrigin } from ".";
import {
    FlemingLoggedInCustomProperties,
    HasErrorProps,
} from "../FlemingAnalyticsConstants";
import { FlemingAnalyticsTemplateType } from "./LegacyMessageTemplatesEvents";

export type MessageTemplatePageOrigin =
    | "WebInboxMessageTemplatePage"
    | "WebInboxCreateTemplatePage"
    | "WebInboxConversationQuickView"
    | "PatientListPage"
    | "ManageTemplatePage";

type MessageTemplateUseProps = FlemingLoggedInCustomProperties &
    HasErrorProps & {
        templateName: string;
        searchString: string;
        templateGroup: string | null;
        pageOrigin: MessageTemplatePageOrigin;
        fromQuickView: boolean;
        templateType: FlemingAnalyticsTemplateType | "email";
        countAttachment: number;
    };

type MessageTemplatePreviewProps = FlemingLoggedInCustomProperties &
    HasErrorProps &
    Pick<
        MessageTemplateUseProps,
        "templateName" | "templateType" | "searchString" | "templateGroup"
    >;

/**
 * User Click on Use button (from QuickView or from List)
 * from message message templates page (Inside new web inbox)
 *
 * Event type: Button Click
 * */
export const trackMessageTemplateUseButtonClick = (
    props: MessageTemplateUseProps,
): void => {
    GenericTrackEvent({
        object: "TemplateUse",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * User Click on Preview button
 * from message message templates page (Inside new web inbox)
 *
 * Event type: Button Click
 * */
export const trackMessageTemplatePreviewButtonClick = (
    props: MessageTemplatePreviewProps,
): void => {
    GenericTrackEvent({
        object: "TemplatePreview",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

type TemplateCreateStartButtonClickProps = FlemingLoggedInCustomProperties &
    HasErrorProps & {
        pageOrigin: "MessageTemplates" | "WebInboxConversationThread";
    };

/**
 * Conversation UI message template flow
 * - When a user clicks the create button within the template page to start creating a template
 */
export const trackTemplateCreateStartButtonClick = (
    props: TemplateCreateStartButtonClickProps,
): void => {
    GenericTrackEvent({
        object: "TemplateCreateStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

type HasErrorWithDetail =
    | { hasError: true; errorReason: string[] }
    | { hasError: false; errorReason?: undefined };

type TemplateActionProps = FlemingLoggedInCustomProperties & {
    pageOrigin: MessageTemplatePageOrigin;
    templateType: FlemingAnalyticsTemplateType | "email";
    templateName: string;
    countAttachment: number;
};

export type TemplateSaveButtonProps = HasErrorWithDetail &
    ProductOrigin &
    TemplateActionProps & {
        // Number of placeholders for patient details found in the template
        countPatientDetail: number;
        templateLevel: "Organisation" | "User";
        countSnomedCode?: number;
    };

type TemplateCreateConfirmResponseProps = HasErrorProps & TemplateActionProps;

type TemplateDeleteConfirmButtonProps = TemplateActionProps;

/**
 * Conversation UI message template flow or edit template dialog or page
 * - When a user clicks the button to save a template that they’re creating.
 */
export const trackTemplateCreateConfirmButtonClick = (
    props: TemplateSaveButtonProps,
): void => {
    GenericTrackEvent({
        object: "TemplateCreateConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 2,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Edit template dialog from manage templates page
 * - When a user clicks the button to save a template that they’re editing.
 */
export const trackTemplateEditConfirmButtonClick = (
    props: TemplateSaveButtonProps,
): void => {
    GenericTrackEvent({
        object: "TemplateEditConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 2,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Copy template dialog from manage templates page
 * - When a user clicks the button to save a template that they’re copying.
 */
export const trackTemplateCopyConfirmButtonClick = (
    props: TemplateSaveButtonProps,
): void => {
    GenericTrackEvent({
        object: "TemplateCopyConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Delete template dialog from manage templates page
 * - When a user clicks the button to save a template that they’re editing.
 */
export const trackTemplateDeleteConfirmButtonClick = (
    props: TemplateDeleteConfirmButtonProps,
): void => {
    GenericTrackEvent({
        object: "TemplateDeleteConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Web inbox conversation UI message template flow
 * - Response of the action after the user clicks the button to save a template that they’re creating.
 */
export const trackTemplateCreateConfirmButtonResponse = (
    props: TemplateCreateConfirmResponseProps,
): void => {
    GenericTrackEvent({
        object: "TemplateCreateConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};
