import { UserGroupsResponse } from "@accurx/api/florey-builder";
import {
    BatchMessageDetails,
    BatchMessageRef,
    BatchMessageStatusResponse,
    QueueBatchMessageRequest,
} from "@accurx/api/patient-messaging";
import {
    IWrappedResult,
    JsonResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";

import {
    BatchFloreySnomedCodes,
    BatchMessageId,
    BatchMessageModalDetails,
    BatchSlotTypesDto,
    BatchSnomedCode,
    CreateBeastInvitesRequest,
    FloreyTemplate,
    FloreysList,
    ListBeastSeriesDto,
    MessageTemplate,
    SendBatchMessageRequest,
    UploadPatientsRequest,
} from "app/batchMessage/gp/BatchMessage.types";

const BM_ENDPOINTS = {
    // get
    getSentBatches: "patientMessaging/batchMessaging/:practiceId",
    getFloreysList: "patientMessaging/FloreyTemplates/batch",
    getAccurxMessageTemplates:
        "patientMessaging/template/message/:practiceId/accurx/batch",
    getUserMessageTemplates:
        "patientMessaging/template/message/:practiceId/user/batch",
    getOrgMessageTemplates:
        "patientMessaging/template/message/:practiceId/organisation/batch",
    getSnomedCodes: "patientMessaging/batchMessaging/:practiceId/SnomedCodes",
    getFloreySnomedCodes: "floreybuilder/clinicalcodes/all-snomed-codes",
    getSlotTypes: "appointments/SelfBook/Availability",
    getUploadDetails: "patientMessaging/batchMessaging/:practiceId/:batchId",
    getUploadStatus:
        "patientMessaging/batchMessaging/:practiceId/:batchId/status",
    getSignupFlorey:
        "patientMessaging/FloreyTemplates/:practiceId/SignupFlorey",
    getBeastSeries: "patientMessaging/beast/:practiceId/series",
    getBatchDetails: "patientMessaging/batchMessaging/:orgId/:batchId/details",
    getPracticeUserGroups:
        "floreybuilder/organisations/:practiceId/user-groups",

    // post
    uploadBatchFile: "patientMessaging/DocumentUpload/BatchUpload/:practiceId",
    uploadPatients: "patientMessaging/batchMessaging/:practiceId/uploadjson",
    sendBatchMessage: "patientMessaging/batchMessaging/:practiceId/send",
    createBeastInvites: "patientMessaging/beast/:practiceId/Send",
    cancelBatch: "patientMessaging/batchMessaging/:orgId/Cancel",
    selfBookTriggerScrapeWork: "appointments/SelfBook/TriggerScrapeWork/:orgId",
} as const;

// Batch Messaging
export const bmUploadBatchFile = (
    practiceId: string,
    formData: FormData,
): Promise<JsonResult> =>
    httpClient.postFileSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.uploadBatchFile,
            params: { practiceId },
        }),
        formData,
    );

export const bmUploadPatients = (
    practiceId: string,
    request: UploadPatientsRequest,
): Promise<IWrappedResult<BatchMessageId>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.uploadPatients,
            params: { practiceId },
        }),
        request,
    );

export const sendBatchMessage = (
    workspaceId: string,
    request: QueueBatchMessageRequest,
): Promise<IWrappedResult<BatchMessageRef>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.sendBatchMessage,
            params: { practiceId: workspaceId },
        }),
        request,
    );

/**
 * @deprecated Please use `sendBatchMessage` instead for correct typing
 */
export const bmSendBatchMessage = (
    practiceId: string,
    request: SendBatchMessageRequest,
): Promise<IWrappedResult<BatchMessageId>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.sendBatchMessage,
            params: { practiceId },
        }),
        request,
    );

export const bmFetchFloreysList = (
    practiceId: string,
): Promise<IWrappedResult<FloreysList>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getFloreysList,
            query: `?organisationId=${practiceId}`,
        }),
    );

export const bmFetchAccurxMessageTemplates = (
    practiceId: string,
): Promise<IWrappedResult<MessageTemplate[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getAccurxMessageTemplates,
            params: { practiceId },
        }),
    );

export const bmFetchUserMessageTemplates = (
    practiceId: string,
): Promise<IWrappedResult<MessageTemplate[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getUserMessageTemplates,
            params: { practiceId },
        }),
    );

export const bmFetchOrgMessageTemplates = (
    practiceId: string,
): Promise<IWrappedResult<MessageTemplate[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getOrgMessageTemplates,
            params: { practiceId },
        }),
    );

export const bmFetchSnomedCodes = (
    practiceId: string,
): Promise<IWrappedResult<BatchSnomedCode[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getSnomedCodes,
            params: {
                practiceId,
            },
        }),
    );

// This will be used by the Florey flow within Batch Messaging
export const bmFetchFloreySnomedCodes = (
    practiceId: string,
): Promise<IWrappedResult<BatchFloreySnomedCodes>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getFloreySnomedCodes,
            query: `?organisationId=${practiceId}`,
        }),
    );

// This will be used by the Florey flow within Batch Messaging
export const bmFetchPracticeUserGroups = (
    practiceId: string,
): Promise<IWrappedResult<UserGroupsResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getPracticeUserGroups,
            params: { practiceId },
        }),
    );

export const bmFetchSlotTypes = (
    practiceId: string,
): Promise<IWrappedResult<BatchSlotTypesDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getSlotTypes,
            query: `?organisationId=${practiceId}`,
        }),
    );

export const bmGetUploadDetails = (
    practiceId: string,
    batchId: string,
): Promise<IWrappedResult<BatchMessageDetails>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getUploadDetails,
            params: { practiceId, batchId },
        }),
    );

export const bmGetUploadStatus = (
    practiceId: string,
    batchId: string,
): Promise<IWrappedResult<BatchMessageStatusResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getUploadStatus,
            params: { practiceId, batchId },
        }),
    );

export const bmCreateBeastInvites = (
    practiceId: string,
    request: CreateBeastInvitesRequest,
): Promise<IWrappedResult<BatchMessageId>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.createBeastInvites,
            params: { practiceId },
        }),
        request,
    );

export const bmFetchSignupFlorey = (
    practiceId: string,
): Promise<IWrappedResult<FloreyTemplate>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getSignupFlorey,
            params: { practiceId },
        }),
    );

export const bmFetchBeastSeries = (
    practiceId: string,
): Promise<IWrappedResult<ListBeastSeriesDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getBeastSeries,
            params: { practiceId },
        }),
    );

export const bmFetchBatchDetails = (
    orgId: string,
    batchId: string,
): Promise<IWrappedResult<BatchMessageModalDetails>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.getBatchDetails,
            params: { orgId, batchId },
        }),
    );

export const bmCancelBatch = (
    orgId: string,
    batchId: string,
): Promise<IWrappedResult<BatchMessageId>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.cancelBatch,
            params: { orgId },
        }),
        { Id: batchId },
    );

export const bmBatchSelfBookTriggerScrapeWork = (orgId: string): void => {
    httpClient.postSafeAsync(
        getApiEndpoint({
            path: BM_ENDPOINTS.selfBookTriggerScrapeWork,
            params: { orgId },
        }),
    );
};
