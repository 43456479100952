import { FormEvent, useEffect, useState } from "react";

import { WorkspaceFragmentUsage } from "@accurx/api/patient-messaging";
import { Feedback, Flex, Text } from "@accurx/design";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useFragmentUsageDashboardMutation } from "app/hooks/mutations";
import { ROUTES_ORGS } from "shared/Routes";
import { useCurrentOrgId, useIsUserApproved } from "store/hooks";

import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "../../UsageDashboardPage.consts";
import { ALL_PRODUCTS } from "./FragmentsDashboard.const";
import { StyledContainer } from "./FragmentsDashboard.styles";
import { DashboardApiProps } from "./FragmentsDashboard.types";
import * as helpers from "./FragmentsDashboard.utils";
import { FragmentsDashboardFilterForm } from "./FragmentsDashboardFilterForm";
import { Info } from "./Info";
import { Overview } from "./Overview";
import { SummaryTable } from "./SummaryTable";
import { TrendsTable } from "./TrendsTable";

const initialUsageData = {
    overview: { numberOfMessages: 0, numberOfFragments: 0 },
    summary: [],
    dailyUsage: [],
};
export const FragmentsDashboard = () => {
    const [usageData, setUsageData] =
        useState<WorkspaceFragmentUsage>(initialUsageData);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;

    const isApprovedUser = useIsUserApproved();
    const fragmentUsageDashboardMutation = useFragmentUsageDashboardMutation({
        onSuccess: (data) => {
            setUsageData(data);
        },
    });

    const handleOnSubmit = (
        e: FormEvent<HTMLFormElement>,
        apiOptions: DashboardApiProps,
    ) => {
        fragmentUsageDashboardMutation.mutate(apiOptions);
    };

    const productsList = usageData.summary.map(
        (summaryRow) => summaryRow.product,
    ) || [ALL_PRODUCTS];

    useEffect(() => {
        // on initial load, fetch dates relating to today and 90 days prior
        if (isApprovedUser)
            fragmentUsageDashboardMutation.mutate({
                workspaceId: workspaceId,
                startDate: helpers.initialStartDate(),
                endDate: helpers.initialEndDate(),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApprovedUser, workspaceId]); // Explicitly ignore the fragmentUsageDashboardMutation from dependency array to prevent infinite loop

    const isNewReportingEnabled = useFeatureFlag("EmbeddedPowerBiPOC");

    if (!isNewReportingEnabled) {
        return (
            <StyledContainer>
                <Flex gap="2" flexDirection="column">
                    {!isApprovedUser && (
                        <Feedback
                            title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                            colour="warning"
                        >
                            {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                        </Feedback>
                    )}
                    {isApprovedUser && (
                        <>
                            <Info />
                            <FragmentsDashboardFilterForm
                                serverError={
                                    fragmentUsageDashboardMutation.error
                                        ?.message || ""
                                }
                                onSubmit={handleOnSubmit}
                            />
                            <Overview
                                data={usageData.overview}
                                isLoading={
                                    fragmentUsageDashboardMutation.isLoading
                                }
                            />
                            <SummaryTable
                                data={usageData.summary}
                                isLoading={
                                    fragmentUsageDashboardMutation.isLoading
                                }
                            />
                            <TrendsTable
                                data={usageData.dailyUsage}
                                isLoading={
                                    fragmentUsageDashboardMutation.isLoading
                                }
                                productsList={productsList}
                            />
                        </>
                    )}
                </Flex>
            </StyledContainer>
        );
    }

    return (
        <Text>
            Fragment reporting has a new home, navigate to it by clicking{" "}
            <Link
                to={{
                    pathname: generatePath(ROUTES_ORGS.reporting, {
                        orgId: workspaceId,
                    }),
                    state: {
                        navigationOrigin: "Web",
                        pageOrigin: "UsageDashboard",
                    },
                }}
            >
                here
            </Link>
            !
        </Text>
    );
};

export default FragmentsDashboard;
