import { ReactNode, forwardRef } from "react";

import { Ds } from "@accurx/design";
import { FocusRingSpacing, NavLink } from "@accurx/navigation";
import styled from "styled-components";

const StyledButton = styled.button`
    background-color: inherit;
    text-align: left;
    width: 100%;
`;

type SecondaryNavToggleProps = {
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    isOpen?: boolean;
    onClick?: () => void;
    children: ReactNode;
};

export const SecondaryNavToggle = forwardRef<
    HTMLButtonElement,
    SecondaryNavToggleProps
>(
    (
        { icon, isOpen = false, onClick, children }: SecondaryNavToggleProps,
        forwardedRef,
    ) => {
        return (
            <FocusRingSpacing>
                <NavLink asChild>
                    <StyledButton ref={forwardedRef} onClick={onClick}>
                        {icon && <NavLink.Icon {...icon} />}
                        <NavLink.Text>
                            {children}{" "}
                            <Ds.Icon
                                name="Arrow"
                                rotate={isOpen ? "0" : "90"}
                                size="xsmall"
                                color="white"
                            />
                        </NavLink.Text>
                    </StyledButton>
                </NavLink>
            </FocusRingSpacing>
        );
    },
);
