import React from "react";

import { Feedback, Icon, Link, StackPanel, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { generatePath, useHistory, useParams } from "react-router";

import { useAnalytics } from "app/analytics/hooks";
import { useModules } from "app/organisations/hooks";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";

import { SectionCardLink } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCTAStackPanel,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledFeedbackContainer,
    StyledList,
} from "../../styles/Cards.styles";

export const AccuBookProductCard = (): JSX.Element => {
    const { track } = useAnalytics();
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();
    const modules = useModules();

    const isAccubookOrg = modules?.accubook.organisation;
    const isDeliverySite = modules?.accubook.deliverySite;
    const showVaccineLinks = modules?.accubook.vaccineLinks;

    const invitePatientsClickHandler = () => {
        track("HomepageVaccineStartButtonClick", {});
        history.push(
            generatePath(ROUTES_CHAIN.practicesVaccineInvitesUploadAndReview, {
                orgId,
            }),
        );
    };

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="Syringe"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.ACCUBOOK}
                    </Text>
                </StackPanel>
                {modules?.accubook.organisation &&
                    modules?.accubook.vaccineLinks && (
                        <StyledCTAStackPanel
                            gutter={2}
                            orientation="horizontal"
                            verticalContentAlignment="center"
                            horizontalContentAlignment="left"
                        >
                            <StyledCTAButton
                                theme="secondary"
                                text="Invite patients"
                                icon={{ name: "Plus", colour: "blue" }}
                                onClick={invitePatientsClickHandler}
                            />
                        </StyledCTAStackPanel>
                    )}
            </StyledCardHeading>

            <StyledCardContent>
                <StyledFeedbackContainer>
                    <Feedback
                        colour="learning"
                        title="Try using batch messaging and Self-Book to manage future clinics"
                    >
                        {
                            <>
                                <Text skinny>
                                    These features are available to everyone for
                                    managing their flu and COVID campaigns.
                                </Text>
                                <Link
                                    onClick={() =>
                                        track(
                                            "HomepageDiscoverResourceLinkClick",
                                            {
                                                homepageSection:
                                                    SECTIONS.ACCUBOOK,
                                                homepageItem:
                                                    "Try using batch messaging and Self-Book to manage future clinics",
                                            },
                                        )
                                    }
                                    href={
                                        SupportUrls.FaceToFaceAppointmentUsingBatch
                                    }
                                    text="Learn how to use batch self-book for vaccine clinics"
                                    openInNewTab
                                    iconName="OpenWindow"
                                />
                            </>
                        }
                    </Feedback>
                </StyledFeedbackContainer>
                <StyledList>
                    {showVaccineLinks && isAccubookOrg && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ACCUBOOK}
                                text="Invite and manage patients"
                                path={generatePath(ROUTES_ORGS.accubook, {
                                    orgId,
                                    tab: "manage-patients",
                                })}
                            />
                        </li>
                    )}
                    {isDeliverySite && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ACCUBOOK}
                                text="Manage clinics"
                                path={generatePath(ROUTES_ORGS.accubook, {
                                    orgId,
                                    tab: "manage-clinics",
                                })}
                            />
                        </li>
                    )}
                    {showVaccineLinks && (isDeliverySite || isAccubookOrg) && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ACCUBOOK}
                                text="Booked patients"
                                path={generatePath(ROUTES_ORGS.accubook, {
                                    orgId,
                                    tab: "booked-patients",
                                })}
                            />
                        </li>
                    )}
                    {isAccubookOrg && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ACCUBOOK}
                                text="Delivery sites"
                                path={generatePath(ROUTES_ORGS.accubook, {
                                    orgId,
                                    tab: "delivery-sites",
                                })}
                            />
                        </li>
                    )}
                    {isAccubookOrg && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ACCUBOOK}
                                text="Dashboard"
                                path={generatePath(ROUTES_ORGS.accubook, {
                                    orgId,
                                    tab: "dashboard",
                                })}
                            />
                        </li>
                    )}
                </StyledList>
            </StyledCardContent>
        </StyledCard>
    );
};
