import {
    OrganisationSetupUserViewModel,
    UserRole,
    UserStatus,
} from "@accurx/api/portal";

/**
 * Returns true if the user is allowed to change to the given status
 */
export const isStatusChangeAllowed = (
    { userStatusState }: OrganisationSetupUserViewModel,
    status: UserStatus,
) => {
    if (!userStatusState?.allowedChanges.length) {
        return false;
    }
    return userStatusState.allowedChanges.includes(status);
};

/**
 * Returns true if the user is allowed to change to the given role
 */
export const isRoleChangeAllowed = (
    { userRoleState }: OrganisationSetupUserViewModel,
    role: UserRole,
) => {
    if (!userRoleState?.allowedChanges.length) {
        return false;
    }
    return userRoleState.allowedChanges.includes(role);
};

/**
 * Returns true if the user is not the only admin
 */
export const canLeaveWorkspace = (
    user: OrganisationSetupUserViewModel,
    approvedUsers: OrganisationSetupUserViewModel[],
) => {
    const allAdmins = approvedUsers.filter(
        ({ userRoleState }) => userRoleState?.currentValue === UserRole.Admin,
    );
    if (
        user.userRoleState?.currentValue === UserRole.Admin &&
        allAdmins.length <= 1
    ) {
        return false;
    }
    return true;
};
