import React from "react";

import { Ds, Icon, Text } from "@accurx/design";

import { StyledLinkDisabled } from "./SectionCardLinkDisabled.styles";

export type SectionCardLinkProps = {
    text: string;
};

export const SectionCardLinkDisabled = ({
    text,
}: SectionCardLinkProps): JSX.Element => {
    return (
        <StyledLinkDisabled>
            <Icon
                name="LockLocked"
                theme="Fill"
                halo={{
                    colour: "greyscale",
                    luminosity: "high",
                }}
                size={3}
            />
            <Text as="span" skinny>
                {text}
            </Text>
            <Ds.Badge color="yellow" radius="round">
                Approved users only
            </Ds.Badge>
        </StyledLinkDisabled>
    );
};
