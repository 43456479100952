import { createContext } from "react";

import { Action, StoreState } from "domains/native/types";
import { ReactReduxContextValue, createSelectorHook } from "react-redux";

import { initialState, store } from "./store";

export const NativeSubscriptionsContext = createContext<
    ReactReduxContextValue<StoreState, any> // eslint-disable-line @typescript-eslint/no-explicit-any
>({
    store: store,
    storeState: initialState,
});

const useNativeSelector = createSelectorHook<StoreState, Action>(
    NativeSubscriptionsContext,
);

export const useNativeSubscription = <T extends keyof StoreState>(
    subscription: T,
): StoreState[T] => {
    return useNativeSelector((state) => state[subscription]);
};
