import { Base } from "./components/Base/Base";
import { Button } from "./components/Button/Button";
import { Dropdown } from "./components/Dropdown/Dropdown";
import { Icon } from "./components/Icon/Icon";
import { Loading } from "./components/Loading/Loading";
import { PrimaryButton } from "./components/PrimaryButton/PrimaryButton";
import { SecondaryButton } from "./components/SecondaryButton/SecondaryButton";
import { Text } from "./components/Text/Text";

export const Pill = {
    Base,
    Button,
    Loading,
    Icon,
    Text,
    Dropdown,
    PrimaryButton,
    SecondaryButton,
};
