import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";

export const useUserConversationGroupQuery: ConversationGroupHook<{
    userId: string;
    status: "Open" | "Done";
}> = ({ userId, status }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFilteredTicketView({
                workspaceId: workspaceId,
                assignee: {
                    type: "User",
                    id: userId,
                },
                isDone: status === "Done",
                ordering: {
                    urgentFirst: status === "Open",
                },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, userId, status],
    );

    return useConversationGroupQuery({
        filters: [
            { type: "Source", value: "Ticket" },
            {
                type: "AssignedTo",
                value: { type: "User", id: userId },
            },
            { type: "Status", value: status },
        ],
        sortOptions:
            status === "Open"
                ? [
                      { key: "IsUrgent", direction: "desc" },
                      { key: "LastUpdated", direction: "desc" },
                      { key: "Id", direction: "desc" },
                  ]
                : [
                      { key: "LastUpdated", direction: "desc" },
                      { key: "Id", direction: "desc" },
                  ],
        loggingInfo: {
            name: "User conversations",
            tags: {
                statusFilter: status,
                userIdFilter: userId,
            },
        },
        fetcher,
    });
};
