import React, { ChangeEvent, useMemo } from "react";

import { FeatureName } from "@accurx/auth";
import { Accordion, Button, Card, Feedback, Icon, Text } from "@accurx/design";

import { UpdatingStatus } from "shared/LoadingStatus";
import { useIsFeatureEnabled } from "store/hooks";

import { VaccineCourse } from "../models/VaccineDeliveryDTO";
import {
    renderEmisInstructions,
    renderSystmOneInstructions,
} from "../shared/UploadInstructionComponents";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import { getTotalSteps } from "./VaccineDeliveryUploadAndComposeHelpers";

export interface VaccineDeliveryUploadButtonAndInfoProps {
    csvUploadStatus: UpdatingStatus;
    inviteUploadStatus: UpdatingStatus;
    uploadFailureMessage: string;
    lastFileUploadErrors: string[];
    vaccineCourse: VaccineCourse;
    changeUploadPageToOtherCourse: () => void;
    uploadFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const VaccineDeliveryUploadButtonAndInfo = ({
    csvUploadStatus,
    inviteUploadStatus,
    uploadFailureMessage,
    lastFileUploadErrors,
    vaccineCourse,
    changeUploadPageToOtherCourse,
    uploadFile,
}: VaccineDeliveryUploadButtonAndInfoProps) => {
    const setTitle = useMemo(() => {
        switch (vaccineCourse) {
            case VaccineCourse.Primary:
                return "Invite patients to COVID-19 Primary Course";
            case VaccineCourse.Booster:
                return "Invite patients to COVID-19 Booster";
            case VaccineCourse.BoosterSpring2022:
                return "Invite patients to COVID-19 Spring 2022 Booster";
            case VaccineCourse.BoosterAutumn2022:
                return "Invite patients to COVID-19 Autumn 2022 Booster";
            case VaccineCourse.BoosterSpring2023:
            case VaccineCourse.None: // prevent text flickering for most used tab
            default:
                return "Invite patients to COVID-19 Spring 2023 Booster";
            case VaccineCourse.BoosterAutumn2023:
                return "Invite patients to COVID-19 Autumn 2023 Booster";
            case VaccineCourse.BoosterSpring2024:
                return "Invite patients to COVID-19 Spring 2024 Booster";
            case VaccineCourse.BoosterAutumn2024:
                return "Invite patients to COVID-19 Autumn 2024 Booster";
        }
    }, [vaccineCourse]);

    // Feature flag for rendering switch course
    const canUseVaccinePracticeAutumn2023BoosterTab = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2023BoosterTab,
    );

    const renderSwitchCourse = () => {
        if (vaccineCourse === VaccineCourse.Primary) {
            return (
                <>
                    <Text skinny>
                        Patients already received their primary course?
                    </Text>
                    <Button
                        type="button"
                        theme="secondary"
                        onClick={changeUploadPageToOtherCourse}
                        text="Invite patients to booster vaccinations"
                    />
                </>
            );
        }

        return (
            <>
                <Text skinny>Patients need their primary course?</Text>
                <Button
                    type="button"
                    theme="secondary"
                    onClick={changeUploadPageToOtherCourse}
                    text="Invite patients to primary vaccinations"
                />
            </>
        );
    };

    return (
        <div className="d-flex flex-column align-items-center w-50 mb-5">
            <VaccineDeliveryHeader
                stepNumber={1}
                totalSteps={getTotalSteps(vaccineCourse)}
                title={setTitle}
            />
            {!canUseVaccinePracticeAutumn2023BoosterTab && renderSwitchCourse()}
            <div className="d-flex flex-column align-items-center mt-4 w-100">
                <div className="text-center w-100">
                    <Text>
                        We save to EMIS and SystmOne that invites have been sent
                        to patients with mobile numbers - you need to be logged
                        in for this to happen
                    </Text>
                    <Text>
                        For SystmOne, this happens at a rate of around 30
                        records per minute.{" "}
                        <Text as="span" variant="label">
                            If you send to a large number of patients, you may
                            find SystmOne unresponsive for a few minutes while
                            it processes the records.
                        </Text>
                    </Text>
                </div>
                {csvUploadStatus === UpdatingStatus.Failed && (
                    <Feedback
                        colour="error"
                        title="Something went wrong while uploading your patient list"
                        props={{
                            style: { whiteSpace: "pre-line", width: "70%" },
                        }}
                        content={uploadFailureMessage}
                    />
                )}
                {inviteUploadStatus === UpdatingStatus.Failed && (
                    <Feedback
                        colour="error"
                        props={{ style: { margin: "2rem" } }}
                        title={
                            "Something went wrong while uploading your patient list"
                        }
                        content="Please check your internet connection and try again"
                    />
                )}
                <label
                    className="w-75"
                    htmlFor={"fileUpload"}
                    style={{ cursor: "pointer", margin: "2rem" }}
                >
                    <Card spacing={3}>
                        <div className="d-flex flex-column align-items-center">
                            <Icon theme="Line" name="Upload" />
                            <Text>Upload CSV file</Text>
                            <input
                                type="file"
                                accept=".csv"
                                id="fileUpload"
                                onChange={uploadFile}
                                style={{ display: "none" }}
                            />
                        </div>
                    </Card>
                </label>
                {lastFileUploadErrors.length > 0 &&
                    lastFileUploadErrors.map((errMessage) => {
                        return (
                            <React.Fragment key={errMessage + " ImageKey"}>
                                <Text colour="red">{errMessage}</Text>
                            </React.Fragment>
                        );
                    })}
                <div className="text-center w-100">
                    <Text>
                        Your upload needs to contain the following columns:
                    </Text>
                    <Text className="mb-4" variant="label">
                        Title, Given Name, Family Name, Date of Birth, NHS
                        Number, Contact number.
                    </Text>
                    <Text>
                        If you are seeing an error uploading your csv, try
                        renaming your columns to match the above. Please see the
                        dropdowns below for more information on how to extract
                        your patient info from your medical system.
                    </Text>
                </div>
                <div className="w-100">
                    <Accordion
                        header="How do I do this in EMIS?"
                        className="mb-4"
                    >
                        {renderEmisInstructions()}
                    </Accordion>
                    <Accordion
                        header="How do I do this in SystmOne?"
                        className="mb-4"
                    >
                        {renderSystmOneInstructions()}
                    </Accordion>
                </div>
            </div>
        </div>
    );
};
