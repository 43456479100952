import { useEffect, useState } from "react";

import { Button, Card, Text } from "@accurx/design";

import { TeamUsage, TeamUsageKeys } from "./TeamUsage.types";

export type TeamUsageTableProps = {
    userUsage: TeamUsage[];
};

const TABLE_HEADER: {
    key: TeamUsageKeys;
    title: string;
}[] = [
    {
        key: "fullName",
        title: "User",
    },
    {
        key: "singleMessageSent",
        title: "Single messages sent",
    },
    {
        key: "multiMessageSent",
        title: "Multiple messages sent",
    },
    {
        key: "floreysSent",
        title: "Floreys sent",
    },
    {
        key: "videoInvitesSent",
        title: "Video consults sent",
    },
    {
        key: "totalSent",
        title: "Total sent",
    },
];

const sortData = (
    data: TeamUsage[],
    sortKey: TeamUsageKeys,
    sortDirectionAsc: boolean,
): TeamUsage[] => {
    data.sort((a, b) => {
        if (sortKey === "fullName") {
            return a[sortKey].localeCompare(b[sortKey]);
        }
        return b[sortKey] - a[sortKey];
    });
    if (!sortDirectionAsc) {
        data.reverse();
    }
    return data;
};

export const TeamUsageTable = ({ userUsage }: TeamUsageTableProps) => {
    const [sortKey, setSortKey] = useState<TeamUsageKeys>(TABLE_HEADER[0].key);
    const [sortDirectionAsc, setSortDirectionAsc] = useState(true);
    const [sortedData, setSortedData] = useState(
        sortData(userUsage, sortKey, sortDirectionAsc),
    );

    useEffect(() => {
        // Sort table on click
        setSortedData(sortData(userUsage, sortKey, sortDirectionAsc));
    }, [userUsage, sortKey, sortDirectionAsc]);

    const handleSort = (selectedKey: TeamUsageKeys): void => {
        if (sortKey === selectedKey) {
            // Changing sorting order
            setSortDirectionAsc((prev) => !prev);
            return;
        }
        // Changing sorting column
        setSortKey(selectedKey);
        setSortDirectionAsc(true);
    };

    return (
        <Card
            spacing={0}
            isElevated={false}
            props={{ className: "table-responsive" }}
        >
            <table className="table table-bordered table-sm mb-0">
                <thead>
                    <tr>
                        {TABLE_HEADER.map(({ key, title }) => (
                            <Text
                                key={title}
                                as="th"
                                variant="label"
                                props={{
                                    className: `align-middle ${
                                        key === sortKey && "bg-blue-25"
                                    }`,
                                }}
                            >
                                <Button
                                    className="p-0 text-left align-items-center"
                                    theme="transparent"
                                    text={title}
                                    icon={{
                                        name: "Order",
                                        colour: "blue",
                                        placement: "end",
                                        rotation:
                                            key === sortKey && sortDirectionAsc
                                                ? "up"
                                                : "down",
                                    }}
                                    onClick={(): void => handleSort(key)}
                                />
                            </Text>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((user, index) => (
                        <tr key={index}>
                            <Text as="td" variant="label">
                                {user.fullName}
                            </Text>
                            <Text as="td">{user.singleMessageSent}</Text>
                            <Text as="td">{user.multiMessageSent}</Text>
                            <Text as="td">{user.floreysSent}</Text>
                            <Text as="td">{user.videoInvitesSent}</Text>
                            <Text as="td">{user.totalSent}</Text>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};
