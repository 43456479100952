import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledCollapsibleMenuButton = styled.button`
    display: flex;
    align-items: center;

    width: 100%;

    padding: ${Tokens.SIZES[0.5]} 0;
    padding-right: ${Tokens.SIZES[0.5]};

    background-color: ${Tokens.COLOURS.greyscale.white};
    border-style: solid;
    border-width: ${Tokens.SIZES[0.25]};
    border-color: ${Tokens.COLOURS.greyscale.white};
    border-radius: ${Tokens.BORDERS.radius};

    transition: background-color 0.2s ease-in;

    text-align: left;

    &:focus,
    &:focus-visible {
        // This is needed to remove the outline shown when a link is in focus.
        // We don't want this outline as we are applying our own focus styles.
        box-shadow: none !important;
    }

    &:focus {
        border-color: ${Tokens.COLOURS.greyscale.silver};
    }

    &:hover {
        border-color: ${Tokens.COLOURS.greyscale.silver};
        background-color: ${Tokens.COLOURS.greyscale.silver};
    }
`;

export const StyledCollapsibleTitle = styled(Text)`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledCollapsibleContent = styled.div`
    padding-left: ${Tokens.SIZES[3]};
    padding-top: ${Tokens.SIZES[0.5]};
`;
