import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useCurrentUser,
    useCurrentWorkspace,
    useFeatureFlag,
} from "@accurx/auth";
import { Ds, Popover, PopoverTrigger, Text } from "@accurx/design";
import {
    StyledDivider,
    StyledListHeader,
    StyledPopoverContent,
    StyledPopoverItem,
} from "@accurx/navigation";
import {
    MyWorkspaceWarningModal,
    SelectNhsPharmacyModal,
    isMyWorkspace,
    isNhsPharmacy,
    isPharmacy,
    isUserOnlyInDefaultWorkspaceWithinOrg,
} from "@accurx/workspace-management";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import {
    StyledArchivedBadge,
    StyledTriggerButton,
} from "./WorkspaceSwitcher.styles";

export const WorkspaceSwitcher = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const history = useHistory();
    const track = useAnalytics();

    return (
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
            <PopoverTrigger asChild>
                <StyledTriggerButton
                    theme="success"
                    icon={{
                        name: "ArrowTwoWay",
                        style: "Fill",
                        rotation: "left",
                        title: "Switch workspace",
                    }}
                    onClick={() => {
                        if (!popoverOpen) {
                            track("NavigationMenu Button Click", {
                                origin: history.location.pathname,
                                navigationOptionSelected: "Workspace",
                            });
                        }
                    }}
                />
            </PopoverTrigger>
            <WorkspaceSwitcherContent onClose={() => setPopoverOpen(false)} />
        </Popover>
    );
};

export const WorkspaceSwitcherContent = ({
    onClose,
}: {
    onClose: () => void;
}) => {
    const history = useHistory();
    const track = useAnalytics();

    const { user } = useCurrentUser();
    const currentWorkspace = useCurrentWorkspace();
    const { setSelectedOrgId } = useSelectedOrganisation();

    const allWorkspaces = [...user.organisations];
    const availableWorkspaces = allWorkspaces.filter(
        ({ orgId }) => orgId !== currentWorkspace.orgId,
    );

    const [showPharmacyModal, setShowPharmacyModal] = useState(false);
    const [selectedMyWorkspace, setSelectedMyWorkspace] =
        useState<Organisation | null>(null);

    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const navigateToJoinOrganisation = () =>
        history.push("/join-organisation", { showBackButton: true });

    const navigateToJoinWorkspace = () => {
        history.push(
            generatePath(
                "/w/:workspaceId/workspace-onboarding/select-organisation",
                {
                    workspaceId: currentWorkspace.orgId,
                },
            ),
        );
    };

    const joinWorkspace = () => {
        isFlexibleWorkspaceEnabled
            ? navigateToJoinWorkspace()
            : navigateToJoinOrganisation();
        track("NavigationOrganisation MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "JoinAnOrganisation",
        });
        onClose();
    };

    const handleWorkspaceClick = (workspace: Organisation) => {
        track("NavigationOrganisation MenuItem Click", {
            optionSelected: "SwitchPractice",
            origin: history.location.pathname,
        });
        if (
            isNhsPharmacy(workspace) &&
            user.organisations.filter(isPharmacy).length > 0
        ) {
            setShowPharmacyModal(true);
        } else if (
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            ) &&
            isMyWorkspace(workspace) &&
            !isUserOnlyInDefaultWorkspaceWithinOrg({
                user,
                organisation: workspace,
            })
        ) {
            setSelectedMyWorkspace(workspace);
        } else {
            selectWorkspace(workspace);
        }
        onClose();
    };

    const selectWorkspace = (workspace: Organisation) => {
        setSelectedOrgId(workspace.orgId);
        history.push("/");
    };

    return (
        <>
            <StyledPopoverContent side="bottom" align="end">
                <StyledPopoverItem onClick={joinWorkspace}>
                    <Ds.Icon name="Plus" size="small" color="stone" />
                    <Text skinny variant="label">
                        {isFlexibleWorkspaceEnabled
                            ? "Join a workspace"
                            : "Join an organisation"}
                    </Text>
                </StyledPopoverItem>

                <StyledDivider />

                <StyledListHeader>Switch workspace</StyledListHeader>
                {availableWorkspaces.map((workspace) => (
                    <WorkspaceItem
                        key={workspace.orgId}
                        workspace={workspace}
                        onClick={handleWorkspaceClick}
                    />
                ))}
            </StyledPopoverContent>

            {showPharmacyModal && (
                <SelectNhsPharmacyModal
                    handleClose={() => setShowPharmacyModal(false)}
                    handleSelectWorkspace={selectWorkspace}
                />
            )}

            {selectedMyWorkspace && (
                <MyWorkspaceWarningModal
                    handleClose={() => setSelectedMyWorkspace(null)}
                    handleSelectMyWorkspace={() => {
                        selectWorkspace(selectedMyWorkspace);
                        setSelectedMyWorkspace(null);
                    }}
                />
            )}
        </>
    );
};

const WorkspaceItem = ({
    workspace,
    onClick,
}: {
    workspace: Organisation;
    onClick: (workspace: Organisation) => void;
}) => {
    const showArchivedBadge =
        isNhsPharmacy(workspace) ||
        (isMyWorkspace(workspace) &&
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                workspace,
            ));

    return (
        <StyledPopoverItem
            key={workspace.orgId}
            onClick={() => onClick(workspace)}
        >
            <Ds.Icon name="Carehome" size="small" color="stone" />
            <Ds.Flex flexDirection="column" alignItems="flex-start">
                <Text variant="label" skinny>
                    {isMyWorkspace(workspace)
                        ? "My Workspace"
                        : workspace.organisationName}
                </Text>
                {isFeatureEnabledForWorkspace(
                    FeatureName.FlexibleWorkspace,
                    workspace,
                ) && <Text skinny>{workspace.organisationNodeName}</Text>}
                {showArchivedBadge && (
                    <StyledArchivedBadge color="greyscale">
                        Archived
                    </StyledArchivedBadge>
                )}
            </Ds.Flex>
        </StyledPopoverItem>
    );
};
