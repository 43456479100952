import { useEffect, useState } from "react";

import {
    AppointmentChangeDeadline,
    AppointmentChangeSupport,
    AppointmentReminder,
} from "@accurx/api/appointment";
import {
    DeleteAppointmentReminderRequest,
    DeleteReminderModal,
    FormValues,
    ReminderFormFields,
    TemplateType,
    mapFormValuesToRequest,
} from "@accurx/appointment-reminders";
import { ErrorSummaryProvider, Flex, Text, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    CreateAndAmendAppointmentReminderProps,
    ExistingAppointmentReminderProps,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import {
    createAppointmentReminder,
    deleteAppointmentReminder,
    resetAppointmentComposeState,
    updateAppointmentReminder,
} from "../AppointmentReminder.actions";

const StyledWrapper = styled(Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    gap: "3",
})`
    width: 60%;
    margin: 0 auto ${Tokens.SIZES[6]};

    @media (max-width: ${Tokens.BREAKPOINTS.lg}) {
        width: 100%;
    }
`;

type ComposeFormProps = {
    workspaceId: string;
    workspaceName?: string;
    appointmentReminders: AppointmentReminder[];
    slotTypes: string[];
    siteNames: string[];
    selectedAppointmentReminder: AppointmentReminder | null;
};

export const ComposeForm = ({
    workspaceId: practiceId,
    workspaceName: practiceName = "[Your Practice name]",
    appointmentReminders,
    slotTypes,
    siteNames,
    selectedAppointmentReminder,
}: ComposeFormProps): JSX.Element => {
    const isEdit = selectedAppointmentReminder !== null || false;
    useAccurxWebTitle(
        isEdit ? "Edit appointment reminder" : "Create an appointment reminder",
    );

    // Global properties and states
    const dispatch = useDispatch();
    const history = useHistory();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const {
        createAppointmentReminderStatus,
        deleteAppointmentReminderStatus,
        updateAppointmentReminderStatus,
        createAppointmentReminderErrorMessage,
    } = useAppSelector(
        ({ appointmentReminders }) => appointmentReminders,
        shallowEqual,
    );

    const webRequestInProgress =
        deleteAppointmentReminderStatus !== UpdatingStatus.Initial ||
        updateAppointmentReminderStatus !== UpdatingStatus.Initial ||
        (createAppointmentReminderStatus !== UpdatingStatus.Initial &&
            createAppointmentReminderStatus !== UpdatingStatus.Failed);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    // Reset previous state on component mount. Useful to clear stale state when user use the browser back button and try to compose a new reminder after
    useEffect(() => {
        // Scroll to top of the page
        setTimeout(() => {
            window.scrollTo(0, 0);
        });
        if (!isEdit) {
            dispatch(resetAppointmentComposeState());
        }
    }, [dispatch, isEdit]);

    useEffect(() => {
        if (
            createAppointmentReminderStatus === UpdatingStatus.Loaded ||
            deleteAppointmentReminderStatus === UpdatingStatus.Loaded ||
            updateAppointmentReminderStatus === UpdatingStatus.Loaded
        ) {
            dispatch(resetAppointmentComposeState());
            history.push(
                ROUTES_CHAIN.practicesAppointmentReminder.replace(
                    ":orgId",
                    practiceId,
                ),
            );
        }
    }, [
        history,
        practiceId,
        createAppointmentReminderStatus,
        deleteAppointmentReminderStatus,
        updateAppointmentReminderStatus,
        dispatch,
    ]);

    // handle button clicks
    const handleDeleteAppointmentReminder = (): void => {
        if (!selectedAppointmentReminder) {
            return;
        }
        const analyticsProps: ExistingAppointmentReminderProps = {
            ...analyticsLoggedInProps,
            appointmentReminderId: selectedAppointmentReminder.id.toString(),
        };
        ChainAnalyticsTracker.trackDeleteAppointmentReminderClicked(
            analyticsProps,
        );
        const request: DeleteAppointmentReminderRequest = {
            appointmentReminderId: selectedAppointmentReminder.id,
        };
        dispatch(deleteAppointmentReminder(practiceId, request));
        setShowDeleteModal(false);
    };

    const handleCancel = (): void => {
        if (isEdit) {
            if (!selectedAppointmentReminder) {
                return;
            }
            const analyticsProps: ExistingAppointmentReminderProps = {
                ...analyticsLoggedInProps,
                appointmentReminderId:
                    selectedAppointmentReminder.id.toString(),
            };
            ChainAnalyticsTracker.trackCancelEditAppointmentReminderClicked(
                analyticsProps,
            );
        } else {
            ChainAnalyticsTracker.trackCancelCreateAppointmentReminderClicked(
                analyticsLoggedInProps,
            );
        }
        history.goBack();
    };

    const handleCreateAppointmentReminder = (formValues: FormValues): void => {
        const analyticsProps: CreateAndAmendAppointmentReminderProps = {
            ...analyticsLoggedInProps,
            numberOfSlotTypes: formValues.selectedSlotTypes.length.toString(),
            withOptionalMessageBody: formValues.customMessage !== "",
            postAppointmentEnabled: formValues.postAppointmentMessageEnabled,
        };
        ChainAnalyticsTracker.trackSetLiveAppointmentReminderClicked(
            analyticsProps,
        );

        ChainAnalyticsTracker.trackAppointmentReminderCreateConfirm({
            ...analyticsLoggedInProps,
            countSlotType: formValues.selectedSlotTypes.length.toString(),
            withCustomMessageBody: formValues.customMessage !== "",
            isPostAppointmentEnabled: formValues.postAppointmentMessageEnabled,
            isWeekBeforeEnabled: formValues.oneWeekEnabled,
        });

        const { appointmentTypeFilters, ...request } =
            mapFormValuesToRequest(formValues);

        dispatch(createAppointmentReminder(practiceId, request));
    };

    const handleUpdateAppointmentReminder = (
        formValues: FormValues,
        reminderId: number,
    ): void => {
        const analyticsProps: CreateAndAmendAppointmentReminderProps = {
            ...analyticsLoggedInProps,
            numberOfSlotTypes: formValues.selectedSlotTypes.length.toString(),
            withOptionalMessageBody: formValues.customMessage !== "",
            postAppointmentEnabled: formValues.postAppointmentMessageEnabled,
        };
        ChainAnalyticsTracker.trackUpdateLiveAppointmentReminderClicked(
            analyticsProps,
        );

        const { appointmentTypeFilters, ...request } =
            mapFormValuesToRequest(formValues);

        dispatch(
            updateAppointmentReminder(practiceId, {
                ...request,
                appointmentReminderId: reminderId,
            }),
        );
    };

    const initialFormValues = selectedAppointmentReminder
        ? {
              selectedSiteNames: selectedAppointmentReminder.siteNames,
              selectedSlotTypes: selectedAppointmentReminder.slotTypes.map(
                  (slotType) => ({
                      label: slotType,
                      value: slotType,
                      disabled: false,
                  }),
              ),
              selectedAppointmentType:
                  selectedAppointmentReminder.appointmentTypeFilters[0],
              templateType: selectedAppointmentReminder.type as TemplateType,
              customMessage:
                  selectedAppointmentReminder.customMessageBody ?? "",
              oneWeekEnabled:
                  selectedAppointmentReminder.oneWeekReminderEnabled,
              threeDaysEnabled:
                  selectedAppointmentReminder.threeWorkingDayReminderEnabled,
              postAppointmentMessageEnabled:
                  selectedAppointmentReminder.postAppointmentMessageEnabled,
              postAppointmentMessageBody:
                  selectedAppointmentReminder.postAppointmentCustomMessage ??
                  "",
          }
        : {};

    if (siteNames.length === 1) {
        initialFormValues.selectedSiteNames = siteNames;
    }
    if (slotTypes.length === 1) {
        initialFormValues.selectedSlotTypes = slotTypes.map((slotType) => ({
            label: slotType,
            value: slotType,
            disabled: false,
        }));
    }

    return (
        <StyledWrapper>
            <DeleteReminderModal
                isOpen={showDeleteModal}
                areButtonsDisabled={webRequestInProgress}
                onCancel={() => setShowDeleteModal(false)}
                onDelete={handleDeleteAppointmentReminder}
            />
            <Text variant="title">
                {isEdit ? "Edit your reminder" : "Create a reminder"}
            </Text>

            <Flex as="form" flexDirection="column" gap="3">
                <ErrorSummaryProvider>
                    <ReminderFormFields
                        workspaceName={practiceName}
                        existingReminders={appointmentReminders}
                        siteNames={siteNames}
                        slotTypes={slotTypes}
                        appointmentTypes={[]}
                        workspaceHasMultipleSites={siteNames.length > 1}
                        initialValues={initialFormValues}
                        submitButtonText={
                            isEdit ? "Save changes" : "Set reminder live"
                        }
                        onSubmit={(formValues) => {
                            if (selectedAppointmentReminder?.id) {
                                handleUpdateAppointmentReminder(
                                    formValues,
                                    selectedAppointmentReminder.id,
                                );
                            } else {
                                handleCreateAppointmentReminder(formValues);
                            }
                        }}
                        changeDeadline={AppointmentChangeDeadline.OneHour}
                        supportedChanges={{
                            cancellation: AppointmentChangeSupport.Allowed,
                            rebooking: AppointmentChangeSupport.NotSupported,
                        }}
                        availableFilters="site-name-slot-type"
                        cancelButton={{ show: true, onCancel: handleCancel }}
                        deleteButton={{
                            show: isEdit,
                            onDelete: () => setShowDeleteModal(true),
                        }}
                        isError={createAppointmentReminderErrorMessage !== null}
                        error={
                            createAppointmentReminderErrorMessage
                                ? {
                                      message:
                                          createAppointmentReminderErrorMessage,
                                  }
                                : undefined
                        }
                        layout="one-column"
                        workspaceId={practiceId}
                    />
                </ErrorSummaryProvider>
            </Flex>
        </StyledWrapper>
    );
};

export default ComposeForm;
