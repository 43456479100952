import React from "react";

import { StackPanel } from "@accurx/design";

import { CONVERSATION_GROUP_LIST_SPACING } from "../ConversationGroup/ConversationGroup.constants";
import { ConversationListItemLoadingSkeleton } from "../ConversationGroup/ConversationListItem/ConversationListItemLoadingSkeleton";

export const ConversationGroupPageLoading = (): JSX.Element => {
    return (
        <StackPanel gutter={CONVERSATION_GROUP_LIST_SPACING} role="status">
            {Array.apply(0, Array(6)).map((_, i) => {
                return (
                    <ConversationListItemLoadingSkeleton key={`loading-${i}`} />
                );
            })}
        </StackPanel>
    );
};
