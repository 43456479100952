import { useCurrentWorkspace } from "@accurx/auth";
import { PatientSummary } from "@accurx/concierge/types";
import { NativeTransportError, useNativeTransport } from "@accurx/native";
import { Log } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";
import { patientDemographicsQuery } from "domains/inbox/native/queries";
import { PatientDemographicsDto } from "domains/inbox/schemas/PatientDemographicsDtoSchema";

export const getPatientDemographicsQueryKey = (
    externalIds?: PatientSummary["externalIds"],
) => {
    return [
        "PatientDemographicsQuery",
        {
            externalIds,
        },
    ];
};

type SelectMapper<T> = (
    data: PatientDemographicsDto["patientDemographics"],
) => T;

export const usePatientDemographicsQuery = <
    T = PatientDemographicsDto["patientDemographics"],
>({
    patientId,
    externalIds,
    enabled = true,
    select,
}: {
    patientId: PatientSummary["patientId"];
    externalIds: PatientSummary["externalIds"];
    enabled?: boolean;
    select?: SelectMapper<T>;
}) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;
    const queryKey = getPatientDemographicsQueryKey(externalIds);

    return useQuery({
        queryFn: async () => {
            if (!transport)
                throw new NativeTransportError(
                    "PatientDemographicsQuery: Called with no native transport",
                );

            const res = await patientDemographicsQuery(
                { transport, workspaceId },
                {
                    patientExternalIdentityDto: {
                        patientExternalIds: externalIds,
                    },
                },
            );

            const { patientDemographics, serverPatientId } = res;

            if (serverPatientId !== patientId) {
                const message =
                    "PatientDemographicsQuery: reveived a response with another serverPatientId";
                Log.warn(message);
                throw new Error(message);
            }
            return patientDemographics;
        },
        enabled,
        queryKey,
        select,
    });
};
