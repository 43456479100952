import React, { useEffect } from "react";

import {
    Feedback,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Text,
} from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { fetchSlotTypes } from "app/batchMessage/gp/BatchMessage.actions";
import { BatchSlotType } from "app/batchMessage/gp/BatchMessage.types";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

type ComposeSlotDropdownProps = {
    selectedSlot: BatchSlotType;
    setSelectedSlot: (slot: BatchSlotType) => void;
};

export const ComposeSlotDropdown = ({
    selectedSlot,
    setSelectedSlot,
}: ComposeSlotDropdownProps): JSX.Element => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const { gettingSlotTypesStatus, slotTypes, weeksAvailable } =
        useAppSelector(({ batchMessage }) => batchMessage, shallowEqual);

    // Load all Slot Types
    useEffect(() => {
        if (gettingSlotTypesStatus === LoadingStatus.Initial && practiceId) {
            dispatch(fetchSlotTypes(practiceId));
        }
    }, [dispatch, practiceId, gettingSlotTypesStatus]);

    // TODO: selected type should allow a single Option
    const handleOptionChange = (selected: Option[] | Option) => {
        const selectedSlotType = slotTypes.find(
            (slotType: BatchSlotType) =>
                slotType.type === (selected as Option).value,
        );
        if (selectedSlotType) {
            setSelectedSlot(selectedSlotType);
            ChainAnalyticsTracker.trackBatchComposeSlotOptionClick({
                ...analyticsLoggedInProps,
                batchSlotAvailable: selectedSlotType.availability,
            });
        }
    };

    // Create an array of options for the dropdown
    const options: Option[] = slotTypes.map((slotType: BatchSlotType) => ({
        label: slotType.type,
        labelElement: (
            <Text skinny>
                <b>{slotType.type}</b>
                {slotType.availability === 0 || slotType.availability > 1
                    ? ` - ${slotType.availability} appointments `
                    : ` - ${slotType.availability} appointment `}
                available
            </Text>
        ),
        value: slotType.type,
    }));

    // Use selected value
    const initialValue = selectedSlot.type
        ? {
              label: selectedSlot.type,
              value: selectedSlot.type,
          }
        : undefined;
    const hasNoAppointment =
        !!selectedSlot.type && selectedSlot.availability === 0;
    const isApiError = gettingSlotTypesStatus === LoadingStatus.Failed;
    const isLoaded = gettingSlotTypesStatus === LoadingStatus.Loaded;

    return (
        <>
            {isApiError && (
                <Feedback
                    colour="error"
                    title="There was a connection problem."
                    props={{ className: "mb-4" }}
                >
                    <Text skinny>
                        Refresh the page to try again. If that still doesn't
                        work, contact support.
                    </Text>
                </Feedback>
            )}
            <FormFieldWrapper
                className="mb-3"
                label="Slot type"
                labelProps={{
                    htmlFor: "slot-dropdown",
                }}
                errors={[
                    hasNoAppointment
                        ? "No appointments. Make more available in your clinical system."
                        : null,
                    isLoaded && options.length === 0
                        ? "There was a problem finding your slot types. Contact support."
                        : null,
                ]}
            >
                <Text skinny>
                    Patients will be able to book into your clinical system up
                    to {weeksAvailable} weeks in the future.{" "}
                </Text>
                <SearchSelect
                    id="slot-dropdown"
                    options={options}
                    onChangeHandler={handleOptionChange}
                    initSelectedValues={initialValue}
                    zIndex={Z_INDEX.dropdown}
                />
            </FormFieldWrapper>
        </>
    );
};
