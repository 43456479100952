import React from "react";

import { Button, Card, Icon, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

export type RecallModalProps = {
    isOpen: boolean;
    toggleRecallModal: () => void;
    fileName: string;
    handleDelete: () => void;
};

export const RecallAttachmentModal = ({
    isOpen,
    toggleRecallModal,
    fileName,
    handleDelete,
}: RecallModalProps): JSX.Element | null => {
    const patientName = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse)
                ?.displayName || "this patient",
    );

    const closeBtn = (
        <>
            <Text
                as="span"
                props={{
                    className: "sr-only",
                }}
            >
                Closing the modal
            </Text>
            <Button
                theme="transparent"
                text=""
                dimension="medium"
                icon={{
                    name: "Cross",
                    style: "Line",
                    colour: "metal",
                }}
                onClick={toggleRecallModal}
            />
        </>
    );

    return (
        <Modal
            isOpen={isOpen}
            backdrop="static"
            toggle={toggleRecallModal}
            fade={false}
            centered
        >
            <ModalHeader toggle={toggleRecallModal} close={closeBtn} tag="div">
                <Text variant="subtitle" as="h3" colour="night" skinny>
                    You are about to recall the document '{fileName}' from the
                    patient
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Card
                    spacing={1.5}
                    isElevated={false}
                    props={{ className: "mb-2" }}
                >
                    <Text as="h5" props={{ className: "mb-1" }} variant="label">
                        <Icon
                            name="Info"
                            halo={{ colour: "orange", luminosity: "high" }}
                            size={3}
                            props={{ className: "mr-2" }}
                        />
                        Make document unavailable for
                    </Text>
                </Card>
                <Card spacing={1.5} isElevated={false}>
                    <Text as="h5" variant="label">
                        {patientName}
                    </Text>
                    <Text variant="body"> From Accurx and NHS App</Text>
                </Card>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "space-between" }}>
                <Button
                    text="Back"
                    theme="secondary"
                    onClick={toggleRecallModal}
                />
                <Button
                    text="Recall"
                    theme="danger"
                    onClick={handleDelete}
                    icon={{ name: "Bin" }}
                />
            </ModalFooter>
        </Modal>
    );
};
