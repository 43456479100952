import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Button, Text, Tokens } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { generateFiltersArray, generateFiltersString } from "../Vaccine.helper";
import { VaccineTypeFilters } from "../models/VaccineDeliveryDTO";
import { NimsVaccineProductType } from "../models/VaccineSharedDTO";
import { setVaccineTypeFilters } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import {
    initialState as VaccineAllInvitesInitialState,
    initialVaccineFilters,
} from "../vaccineInvitesOldPage/vaccineDelivery.reducer";

export interface VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxesProps {
    numIndividualSelected: number;
    setCurrentPaginationPage: Dispatch<SetStateAction<number>>;
}

export const VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxes = ({
    numIndividualSelected,
    setCurrentPaginationPage,
}: VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxesProps): JSX.Element => {
    const dispatch = useDispatch();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const myPracticeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.myPractice,
        shallowEqual,
    );

    const statusFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.statusFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.statusFilters,
        shallowEqual,
    );

    const firstVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.firstVaccinationTimeFilter ||
            VaccineAllInvitesInitialState.allInvitesFilters
                .firstVaccinationTimeFilter,
        shallowEqual,
    );

    const vaccineTypeFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.vaccineTypeFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.vaccineTypeFilters,
        shallowEqual,
    );

    const unclearStatusFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.unclearStatus ||
            VaccineAllInvitesInitialState.allInvitesFilters.unclearStatus,
        shallowEqual,
    );

    const typeFilterActive =
        vaccineTypeFilters.astrazeneca ||
        vaccineTypeFilters.janssen ||
        vaccineTypeFilters.moderna ||
        vaccineTypeFilters.pfizer;

    const [filterVaccineTypeOpen, setFilterVaccineTypeOpen] =
        useState<boolean>(typeFilterActive); // default to open if one or more type filters checked

    const [localFilters, setLocalFilters] = useState<VaccineTypeFilters>(
        initialVaccineFilters,
    );

    const [reduxFiltersLoaded, setReduxFiltersLoaded] = useState(false);

    useEffect(() => {
        // On first load or load after clicking 'Apply Filter' we sync the local state with the redux state
        if (!reduxFiltersLoaded) {
            setLocalFilters(vaccineTypeFilters);
            setReduxFiltersLoaded(true);
        }
    }, [reduxFiltersLoaded, vaccineTypeFilters]);

    const localFiltersMatchRedux =
        vaccineTypeFilters.astrazeneca === localFilters.astrazeneca &&
        vaccineTypeFilters.pfizer === localFilters.pfizer &&
        vaccineTypeFilters.moderna === localFilters.moderna &&
        vaccineTypeFilters.janssen === localFilters.janssen;

    const handleFilterVaccineTypeOpenToggle = (): void => {
        if (filterVaccineTypeOpen && !localFiltersMatchRedux) {
            setLocalFilters(vaccineTypeFilters);
        }
        setFilterVaccineTypeOpen((prev) => !prev);
    };

    const handleToggleAstrazenecaFilter = (): void => {
        setLocalFilters({
            ...localFilters,
            astrazeneca: !localFilters.astrazeneca,
        });
        setCurrentPaginationPage(0);
    };

    const handleToggleJanssenFilter = (): void => {
        setLocalFilters({
            ...localFilters,
            janssen: !localFilters.janssen,
        });
        setCurrentPaginationPage(0);
    };

    const handleTogglePfizerFilter = (): void => {
        setLocalFilters({
            ...localFilters,
            pfizer: !localFilters.pfizer,
        });
        setCurrentPaginationPage(0);
    };

    const handleToggleModernaFilter = (): void => {
        setLocalFilters({
            ...localFilters,
            moderna: !localFilters.moderna,
        });
        setCurrentPaginationPage(0);
    };

    const handleApplyFilters = (): void => {
        setReduxFiltersLoaded(false);
        dispatch(setVaccineTypeFilters(localFilters));
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListFiltersAppliedProps =
            {
                ...analyticsLoggedInProps,
                applyFilterSection: "vaccineType",
                myPracticeFilter: myPracticeFilter,
                currentStatusFilters: statusFilters,
                firstVaccinationWeekFilter:
                    firstVaccinationTimeFilter?.displayText,
                vaccineTypeFilters: generateFiltersArray(vaccineTypeFilters),
                unclearStatusFilter: unclearStatusFilter,
            };
        ChainAnalyticsTracker.trackVaccinePatientListFiltersApplied(
            analyticsProps,
        );
    };

    return (
        <div
            className="mt-3 pb-3 d-flex flex-column"
            style={{
                borderBottom: `1px solid ${Tokens.COLOURS.greyscale.stone}`,
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={handleFilterVaccineTypeOpenToggle}
            >
                <Text variant="label" props={{ className: "ml-2 mt-0" }}>
                    Vaccine type
                </Text>
                <Button
                    dimension="small"
                    theme="transparent"
                    icon={{
                        name: "Arrow",
                        colour: "zinc",
                        rotation: filterVaccineTypeOpen ? "up" : "down",
                        placement: "end",
                    }}
                    text={
                        !filterVaccineTypeOpen
                            ? generateFiltersString(vaccineTypeFilters)
                            : ""
                    }
                    aria-label="open-vaccine-types-filter"
                />
            </div>
            {filterVaccineTypeOpen && (
                <>
                    <div className="d-flex m-2">
                        <input
                            type="checkbox"
                            id="astrazeneca-checkbox"
                            onChange={handleToggleAstrazenecaFilter}
                            checked={localFilters.astrazeneca}
                            disabled={numIndividualSelected > 0}
                            className="mr-2 mt-1"
                        />
                        <Text
                            as="label"
                            skinny
                            props={{ htmlFor: "astrazeneca-checkbox" }}
                        >
                            {NimsVaccineProductType.Az}
                        </Text>
                    </div>
                    <div className="d-flex m-2">
                        <input
                            type="checkbox"
                            id="moderna-checkbox"
                            onChange={handleToggleModernaFilter}
                            checked={localFilters.moderna}
                            disabled={numIndividualSelected > 0}
                            className="mr-2 mt-1"
                        />
                        <Text
                            as="label"
                            skinny
                            props={{ htmlFor: "moderna-checkbox" }}
                        >
                            {NimsVaccineProductType.Moderna}
                        </Text>
                    </div>
                    <div className="d-flex m-2">
                        <input
                            type="checkbox"
                            id="pfizer-checkbox"
                            onChange={handleTogglePfizerFilter}
                            checked={localFilters.pfizer}
                            disabled={numIndividualSelected > 0}
                            className="mr-2 mt-1"
                        />
                        <Text
                            as="label"
                            skinny
                            props={{ htmlFor: "pfizer-checkbox" }}
                        >
                            {NimsVaccineProductType.Pfizer}
                        </Text>
                    </div>
                    <div className="d-flex m-2">
                        <input
                            type="checkbox"
                            id="janssen-checkbox"
                            onChange={handleToggleJanssenFilter}
                            checked={localFilters.janssen}
                            disabled={numIndividualSelected > 0}
                            className="mr-2 mt-1"
                        />
                        <Text
                            as="label"
                            skinny
                            props={{ htmlFor: "janssen-checkbox" }}
                        >
                            {NimsVaccineProductType.Janssen}
                        </Text>
                    </div>
                    <Button
                        text="Apply filter"
                        onClick={handleApplyFilters}
                        disabled={localFiltersMatchRedux}
                        className="d-block"
                        aria-label="apply-filter"
                    />
                </>
            )}
        </div>
    );
};
