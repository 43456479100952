import { useEffect, useState } from "react";

import { OptionalHubClient } from "domains/transport/hooks/useOptionalHubClient";

import { ConnectionStateNew } from "shared/hubClient/ConnectionState";

type ConnectionState = ConnectionStateNew | undefined;

export const useOptionalHubClientConnectionState = (
    hubClient: OptionalHubClient,
): ConnectionState => {
    const [hubClientConnectionState, setHubClientConnectionState] =
        useState<ConnectionState>(
            hubClient?.connectionStateSubscriptionNew.getValue(),
        );

    useEffect(() => {
        if (hubClient) {
            const subscription =
                hubClient.connectionStateSubscriptionNew.subscribe(
                    setHubClientConnectionState,
                );
            return () => subscription.unsubscribe();
        }
    }, [hubClient]);

    return hubClientConnectionState;
};
