import { SearchForPatientResponse } from "@accurx/api/portal";
import { useDispatch } from "react-redux";

import { actionCreators as searchForPatientActions } from "app/searchForPatient/SearchForPatientActions";

/**
 * Used for some of the features we link to via the dropdown
 * as they require the patient being saved in the redux store
 * & session storage for Route Guarding.
 */
export default function useSavePatientSearchToStore() {
    const dispatch = useDispatch();

    const saveToStore = (searchResult: SearchForPatientResponse) => {
        dispatch(
            searchForPatientActions.searchForPatientFinished(searchResult),
        );
    };

    return saveToStore;
}
