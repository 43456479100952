import { Button } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { trackQuestionnaireResponsesContactOptionClick } from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import BaseContactDropdown, {
    PatientProfileOption,
    RecordViewOption,
    ViewConversationOption,
} from "app/sharedComponents/patientContactDropdown";
import { ROUTES_EXTENSION, ROUTES_PRIMARY } from "shared/Routes";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";

type ContactDropdownProps = {
    /** Unique id for identifying the dropdown */
    id: string;
    /** The patient we get provided for the table row */
    patient: PatientSummary;
    /** The id of the conversation the clinician had started */
    conversationId: string;
};

export const ContactDropdown = ({
    id,
    patient,
    conversationId,
}: ContactDropdownProps) => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const history = useHistory();
    const nhsNumber = patient.externalIds.find(
        (id) => id.type === "NhsNumber",
    )?.value;

    const loggedInProps = useFlemingLoggedInAnalytics();

    return (
        <BaseContactDropdown
            dateOfBirth={patient.dateOfBirth}
            nhsNumber={nhsNumber}
            workspaceId={workspaceId}
            triggerButton={({ status }) => (
                <Button
                    icon={{
                        name: "More",
                        title: "Contact the patient",
                        id,
                        style: "Fill",
                        colour: "blue",
                    }}
                    theme="secondary"
                    disabled={status === "loading"}
                />
            )}
            dropdownContent={({ data }) => (
                <>
                    <ViewConversationOption
                        searchResult={data}
                        onClick={() => {
                            history.push(
                                generatePath(
                                    ROUTES_PRIMARY.questionnaireScoreboard +
                                        ROUTES_EXTENSION.patientProfile +
                                        "/conversation/:conversationId",
                                    {
                                        workspaceId,
                                        conversationId,
                                    },
                                ),
                            );

                            trackQuestionnaireResponsesContactOptionClick({
                                ...loggedInProps,
                                conditionId:
                                    PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                                optionSelected: "ViewFullConversation",
                            });
                        }}
                    />
                    <RecordViewOption
                        searchResult={data}
                        onClick={() => {
                            history.push(
                                generatePath(
                                    ROUTES_PRIMARY.questionnaireScoreboard +
                                        ROUTES_EXTENSION.remoteRecordView,
                                    {
                                        workspaceId,
                                    },
                                ),
                            );

                            trackQuestionnaireResponsesContactOptionClick({
                                ...loggedInProps,
                                conditionId:
                                    PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                                optionSelected: "RequestGpRecord",
                            });
                        }}
                    />
                    <PatientProfileOption
                        searchResult={data}
                        onClick={() => {
                            history.push(
                                generatePath(
                                    ROUTES_PRIMARY.questionnaireScoreboard +
                                        ROUTES_EXTENSION.patientProfile,
                                    {
                                        workspaceId,
                                    },
                                ),
                            );

                            trackQuestionnaireResponsesContactOptionClick({
                                ...loggedInProps,
                                conditionId:
                                    PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                                optionSelected: "ViewProfile",
                            });
                        }}
                    />
                </>
            )}
        />
    );
};
