import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    CreateQuestionRequest,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { addQuestion } from "api/FloreyBuilder/FloreyBuilderApi";

export const useCreateQuestionMutation = createMutationHook<
    CreateQuestionRequest,
    QuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["CreateQuestion"],
    mutationFn: async ({ organisationId, ...args }) => {
        const response = await addQuestion(organisationId.toString(), {
            organisationId,
            ...args,
        });
        return returnDataOrThrow(response);
    },
}));
