import { Conversation, PatientSummary } from "@accurx/concierge/types";

import { ReplyState } from "../../ConversationView.types";
import { NoteActionButton } from "./MessageActionButton";
import { NoteContentsNew } from "./NoteContentsNew";

export type NoteNewProps = {
    patient?: PatientSummary;
    conversation: Conversation;
    noteState: ReplyState;
    setNoteState: (state: ReplyState) => void;
    setSentNoteStatus: (status: string | null) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
};

export const NoteNew = ({
    patient,
    conversation,
    noteState,
    setNoteState,
    setSentNoteStatus,
    isExpanded,
    onExpandClick,
}: NoteNewProps) => {
    if (noteState === "Closed") {
        return <NoteActionButton onClick={() => setNoteState("Open")} />;
    }

    return (
        <NoteContentsNew
            noteState={noteState}
            setNoteState={setNoteState}
            conversation={conversation}
            patient={patient}
            isExpanded={isExpanded}
            onExpandClick={onExpandClick}
            setSentNoteStatus={setSentNoteStatus}
        />
    );
};
