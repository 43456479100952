import { NHSChoicesLink } from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { NHSAdviceLink } from "../types";

const mapToNHSAdviceLink = (
    nhsChoicesLinkFromApi: NHSChoicesLink,
): NHSAdviceLink | null => {
    if (!nhsChoicesLinkFromApi.name) {
        Log.error("Discarding NHS choices link as it has no name.");
        return null;
    }
    if (!nhsChoicesLinkFromApi.url) {
        Log.error("Discarding NHS choices link as it has no URL.");
        return null;
    }

    return {
        name: nhsChoicesLinkFromApi.name,
        url: nhsChoicesLinkFromApi.url,
    };
};

const mapApiDataToNHSAdviceLinks = (nhsChoicesLinksFromApi: {
    links: NHSChoicesLink[];
}): NHSAdviceLink[] => {
    return nhsChoicesLinksFromApi.links
        .map(mapToNHSAdviceLink)
        .filter((link): link is NHSAdviceLink => link !== null);
};

export const useNHSAdviceLinks = (
    options?: UseQueryOptions<NHSAdviceLink[], Error, NHSAdviceLink[]>,
) => {
    return useQuery({
        queryKey: ["NHSChoicesLinks"],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/NhsChoices`,
                }),
            );

            const data = returnDataOrThrow<{ links: NHSChoicesLink[] }>(
                response,
            );

            return mapApiDataToNHSAdviceLinks(data);
        },
        ...options,
    });
};
