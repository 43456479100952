import { Button, Card, Ds, Flex, Icon, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useHistory } from "react-router";
import styled from "styled-components";

import { trackClinicViewButtonClick } from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { getLocationWithPlaceholder } from "../../../../shared/location";
import { getClinicPath } from "./getClinicPath";

const StyledText = styled(Text).attrs({
    skinny: true,
})`
    word-break: break-word;
`;

type ClinicCardProps = {
    workspaceId: string;
    clinicDate: string;
    name?: string | null;
    responsibleClinicians: string[];
    clinicCode?: string | null;
    location?: string | null;
    appointmentCount: number;
};

export const ClinicCard = ({
    workspaceId,
    clinicDate,
    name,
    responsibleClinicians,
    clinicCode,
    location,
    appointmentCount,
}: ClinicCardProps) => {
    const history = useHistory();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const clinicPath = getClinicPath({
        workspaceId,
        clinicCode,
        clinicDate,
        location,
    });

    const formattedClinicDate = DateHelpers.formatDate(
        clinicDate,
        DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
    );

    const isEmptyClinic = appointmentCount === 0;

    return (
        <Card
            spacing={1.5}
            header={
                <Flex alignItems="center" gap="1">
                    <Icon
                        name="Calendar"
                        size={4}
                        halo={{
                            colour: "blue",
                            luminosity: "high",
                        }}
                    />
                    <Text skinny variant="label">
                        {formattedClinicDate}
                    </Text>
                </Flex>
            }
        >
            <Flex justifyContent="space-between" alignItems="center" gap="1">
                <Flex flexDirection="column" alignItems="flex-start" gap="0.5">
                    <Text skinny variant="label">
                        {name || "Clinic name unknown"}
                    </Text>

                    {isEmptyClinic && (
                        <Ds.Badge color="greyscale">
                            No patients in clinic
                        </Ds.Badge>
                    )}

                    <Flex alignItems="baseline" flexWrap="wrap" gap="1.5">
                        <StyledText>
                            {responsibleClinicians[0] ||
                                "Responsible clinician unknown"}
                        </StyledText>
                        <StyledText>
                            {clinicCode || "Clinic code unknown"}
                        </StyledText>
                        <StyledText>
                            {getLocationWithPlaceholder(location)}
                        </StyledText>
                    </Flex>
                </Flex>

                {!isEmptyClinic && clinicPath && (
                    <Button
                        text="View patients"
                        theme="secondary"
                        icon={{
                            name: "ArrowTail",
                            rotation: "right",
                            placement: "end",
                        }}
                        onClick={() => {
                            trackClinicViewButtonClick(
                                flemingLoggedInProperties,
                            );
                            history.push(clinicPath);
                        }}
                    />
                )}
            </Flex>
        </Card>
    );
};
