import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";

export const useAllSentConversationGroupQuery: ConversationGroupHook<
    void
> = () => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFolderTicketView({
                workspaceId: workspaceId,
                folder: { viewType: "Sent" },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId],
    );

    return useConversationGroupQuery({
        filters: [
            { type: "Source", value: "Ticket" },
            {
                type: "ContainsItem",
                value: {
                    filters: [
                        {
                            type: "ItemType",
                            value: [
                                "PatientEmail",
                                "PatientSms",
                                "NhsAppMessage",
                            ],
                        },
                    ],
                },
            },
        ],
        // https://www.notion.so/accurx/What-ticket-items-are-returned-with-folderticketview-call-and-how-desktop-inbox-determines-the-displ-c5f1d24e64ab4b6aa1deb541e3c9ea3b
        // - Sorted by deliveryStatusUpdatedAt of the last sent sms/email/nhsapp message
        // - then sorted by the lastSent sms/email/nhsapp message id
        sortOptions: [
            { key: "MostRecentSentItem", direction: "desc" },
            { key: "MostRecentSentItemId", direction: "desc" },
        ],
        loggingInfo: {
            name: "All sent conversations",
            tags: {},
        },
        fetcher,
    });
};
