import { ComponentProps } from "react";

import * as UI from "@accurx/design";

import { StyledButtonTabWrapper } from "./ButtonTabsList.styles";

/**
 * Must be used with UI.Tabs & UI.TabsPanel in order to work
 */
export const ButtonTabsList = (props: ComponentProps<typeof UI.TabList>) => {
    return (
        <StyledButtonTabWrapper>
            <UI.TabList {...props} />
        </StyledButtonTabWrapper>
    );
};
