import { useAnalytics } from "@accurx/analytics";
import {
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";

export type SidebarFolderClickAnalyticsFields = {
    folderName: string;
    conversationParticipant: "WithHcp" | "WithPatient";
    teamName?: string;
    teamId?: string;
    isUserSubscribedToGroupNotifications?: boolean;
    unreadCount?: number;
    ownerUserId?: string;
    isPendingApproval?: boolean;
};

export const useSidebarFolderClickAnalytics = () => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const env = useBrowserEnvironment();
    const track = useAnalytics();

    const trackFolderClick = ({
        folderName,
        conversationParticipant,
        teamName,
        teamId,
        isUserSubscribedToGroupNotifications,
        unreadCount,
        ownerUserId,
        isPendingApproval,
    }: SidebarFolderClickAnalyticsFields) => {
        track("ConversationFolder MenuItem Click", {
            accessType: env === "WebView" ? "DesktopApp" : "Browser",
            folderName,
            teamName,
            teamId,
            ownerUserId,
            unreadCount,
            isUserSubscribedToGroupNotifications,
            isPendingApproval,
            medicalRecordSystem: medicalRecordConnection.system,
            conversationParticipant,
            eventVersion: 3,
        });
    };

    return trackFolderClick;
};
