import { Actions as PracticesAction } from "app/practices/Practices.actions";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    SessionSummary,
    VaccineSessionResponse,
} from "../models/VaccineRecordingDTO";
import { Actions, KnownAction } from "./VaccineRecording.actions";

//#region STATE

export interface VaccineRecordingUpdateError {
    error: string;
    id: number | null;
}

export enum RecordReactionFormStatus {
    Allowed = "ALLOWED",
    NotAllowedCriticalInfoChanged = "NOT_ALLOWED_INFO_CHANGED",
    NotAllowedMissingBatchInfo = "NOT_ALLOWED_BATCH_MISSING",
}

export interface VaccineRecordingReducerState {
    allSessions: SessionSummary[] | null;
    allSessionsStatus: LoadingStatus;
    allSessionsError: string;

    session: VaccineSessionResponse | null;
    sessionStatus: LoadingStatus;
    sessionError: string;
}

export const initialState: VaccineRecordingReducerState = {
    allSessions: null,
    allSessionsStatus: LoadingStatus.Initial,
    allSessionsError: "",

    session: null,
    sessionStatus: LoadingStatus.Initial,
    sessionError: "",
};

//#endregion STATE

export const reducer = (
    state: VaccineRecordingReducerState = initialState,
    action: KnownAction,
): VaccineRecordingReducerState => {
    let tempSession: VaccineSessionResponse | null = null;
    switch (action.type) {
        //#region ALL SESSIONS

        case Actions.GET_ALL_VACCINE_SESSIONS_STARTED:
            return {
                ...state,
                allSessionsStatus: LoadingStatus.Loading,
                allSessionsError: "",
            };
        case Actions.GET_ALL_VACCINE_SESSIONS_SUCCESS:
            return {
                ...state,
                allSessionsStatus: LoadingStatus.Loaded,
                allSessions: action.allSessions,
            };
        case Actions.GET_ALL_VACCINE_SESSIONS_FAILURE:
            return {
                ...state,
                allSessionsStatus: LoadingStatus.Failed,
                allSessionsError: action.error,
            };

        //#endregion ALL SESSIONS

        //#region SESSION
        case Actions.GET_VACCINE_SESSION_STARTED:
            return {
                ...state,
                sessionStatus: LoadingStatus.Loading,
                sessionError: "",
            };
        case Actions.GET_VACCINE_SESSION_SUCCESS:
            return {
                ...state,
                sessionStatus: LoadingStatus.Loaded,
                session: action.session,
            };
        case Actions.GET_VACCINE_SESSION_FAILURE:
            return {
                ...state,
                sessionStatus: LoadingStatus.Failed,
                sessionError: action.error,
            };

        // NOTE: we will ultimately need to
        // handle push notifications to update this list live (arrival change; booking added/removed)
        case Actions.UPDATE_VACCINE_SESSION_ARRIVAL:
            tempSession = !state.session
                ? state.session
                : {
                      ...state.session,
                      slots: state.session.slots.map((slot) => {
                          if (
                              !action.slotStartTime ||
                              slot.startTime === action.slotStartTime
                          ) {
                              return {
                                  ...slot,
                                  bookedPatients: slot.bookedPatients.map(
                                      (p) => {
                                          if (
                                              p.recallSlotBookingId ===
                                              action.recallSlotBookingId
                                          ) {
                                              return {
                                                  ...p,
                                                  hasArrived: action.hasArrived,
                                              };
                                          }
                                          return p;
                                      },
                                  ),
                              };
                          }
                          return slot;
                      }),
                  };

            return {
                ...state,
                session: tempSession,
            };

        case Actions.RESET_VACCINE_SESSION:
            return {
                ...state,
                session: null,
                sessionStatus: LoadingStatus.Initial,
                sessionError: "",
            };
        //#endregion SESSION

        case PracticesAction.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;

        default:
            return state;
    }
};
