import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { ShareQuestionnaireResponse } from "@accurx/api/florey-builder";

import { shareQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";

type ShareQuestionnaireMutationVariables = {
    workspaceId: number;
    questionnaireId: number;
    questionnaireRowVersion: string;
};

export const useShareQuestionnaireMutation = createMutationHook<
    ShareQuestionnaireMutationVariables,
    ShareQuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["ShareQuestionnaire"],
    mutationFn: async ({
        workspaceId,
        questionnaireId,
        questionnaireRowVersion,
    }) => {
        const response = await shareQuestionnaire(workspaceId.toString(), {
            organisationId: workspaceId,
            questionnaireId,
            questionnaireRowVersion,
        });
        return returnDataOrThrow(response);
    },
}));
