import React from "react";

import { Table } from "@accurx/design";

import { ContactablePatient } from "../pages/utils/contactable";
import {
    StyledTable,
    StyledTableHead,
    StyledTruncatedTableData,
    StyledWrappedTableDataCell,
} from "./PatientDetailsTable.styles";

type PatientDetailsTableProps = { patients: ContactablePatient[] };

export const PatientDetailsTable = ({ patients }: PatientDetailsTableProps) => {
    return (
        <StyledTable>
            <StyledTableHead>
                <Table.Row>
                    <Table.Header scope="column">NHS number</Table.Header>
                    <Table.Header scope="column">Patient name</Table.Header>
                    <Table.Header scope="column">Contact method</Table.Header>
                </Table.Row>
            </StyledTableHead>
            <Table.Body>
                {patients.map((patient, index) => {
                    return (
                        <Table.Row key={`${patient.nhsNumber}-${index}`}>
                            <Table.DataCell>
                                <StyledTruncatedTableData>
                                    {patient.nhsNumber}
                                </StyledTruncatedTableData>
                            </Table.DataCell>
                            <Table.DataCell>
                                <StyledTruncatedTableData>
                                    {patient.displayName}
                                </StyledTruncatedTableData>
                            </Table.DataCell>
                            <StyledWrappedTableDataCell>
                                {patient.contact.value}
                            </StyledWrappedTableDataCell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </StyledTable>
    );
};
