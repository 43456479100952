import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPageWrapper = styled.main<{ $theme: "white" | "cloud" }>`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: ${(props) => Tokens.COLOURS.greyscale[props.$theme]};
`;
export const StyledMainContentWrapper = styled.div`
    min-width: 0;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
export const StyledQuickviewWrapper = styled.div`
    box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);
    width: 100%;
    position: relative;
    @media (min-width: 1200px) {
        min-width: 414px;
        flex: 0.5;
    }
    &:empty {
        min-width: 0;
        flex: 0;
    }
`;
