import {
    GenericNoteTheme as ApiGenericNoteTheme,
    PatientThreadGenericNote,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    SelfBookConversationItem,
    SelfBookTheme,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    conversationItemMappingLog,
    mapBaseConversationItem,
} from "./conversationItem.helpers";

/**
 * Maps ticket Generic Note item to conversation Self Book item
 *
 * @param ticketSelfBookNoteItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapSelfBookNoteItem(
    ticketSelfBookNoteItem: PatientThreadGenericNote,
): SelfBookConversationItem | undefined {
    if (!ticketSelfBookNoteItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketSelfBookNoteItem,
    );

    if (!ticketSelfBookNoteItem.message.markdown) {
        throw new Error("Cannot map ticket item as prop markdown is invalid");
    }

    let theme = mapSelfBookNoteTheme(ticketSelfBookNoteItem.message.theme);
    if (!theme) {
        conversationItemMappingLog({
            message:
                "Could not map to a known theme, defaulting to Information",
            itemId: ticketSelfBookNoteItem.id,
            itemType: ticketSelfBookNoteItem.type,
        });
        theme = "Information";
    }

    return {
        ...baseConversationItem,
        contentType: "SelfBookNote",
        createdBy: { type: "System" },
        markdown: ticketSelfBookNoteItem.message.markdown,
        theme,
        displaySender: ticketSelfBookNoteItem.message.displaySender || null,
    };
}

function mapSelfBookNoteTheme(
    apiTheme?: Optional<ApiGenericNoteTheme>,
): SelfBookTheme | undefined {
    switch (apiTheme) {
        case ApiGenericNoteTheme.Alarm:
            return "Alarm";
        case ApiGenericNoteTheme.Information:
            return "Information";
        case ApiGenericNoteTheme.Warning:
            return "Warning";
        default:
            return;
    }
}
