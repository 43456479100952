import React from "react";

import { Spinner } from "@accurx/design";
import { Redirect, Switch, generatePath } from "react-router";

import { SimpleRoute } from "app/AppRoute";
import {
    useCurrentOrganisation,
    useCurrentUser,
    usePractices,
} from "app/organisations/hooks";
import { InstalledComputers } from "app/practices/computers/InstalledComputers";
import { ModuleAccess } from "app/practices/moduleAccess/moduleAccess";
import { PracticeUsers } from "app/practices/practiceUsers/PracticeUsers";
import { SenderId } from "app/practices/senderId/SenderId";
import { TeamNotifications } from "app/practices/teamNotifications/TeamNotifications";
import { SettingsCookies } from "app/practices/userSettings/SettingsCookies";
import { SettingsPassword } from "app/practices/userSettings/SettingsPassword";
import { SettingsProfile } from "app/practices/userSettings/SettingsProfile";
import { SettingsSecurity } from "app/practices/userSettings/SettingsSecurity";
import { SettingsSignature } from "app/practices/userSettings/SettingsSignature";
import { SettingsTermsAndConditions } from "app/practices/userSettings/SettingsTermsAndConditions";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_ORGS } from "shared/Routes";

import { Tab, TabLookup } from "./SettingsPage.constants";
import { PatientTriageTab } from "./components/PatientTriageTab";

export const Routes = (): JSX.Element => {
    const practices = usePractices();

    const org = useCurrentOrganisation();
    const userSettings = useCurrentUser();

    const tabPath = (tab: Tab) =>
        ROUTES_ORGS.settings.replace(":tab?", tab as string);

    const catchallUrl = generatePath(ROUTES_ORGS.settings, {
        orgId: org?.orgId,
        tab:
            org && userSettings?.isApproved
                ? TabLookup.users.tabId
                : TabLookup.profile.tabId,
    });

    if (practices.loadingStatus !== LoadingStatus.Loaded) return <Spinner />;

    return (
        <Switch>
            <SimpleRoute path={tabPath("users")}>
                <PracticeUsers noHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("available-features")}>
                <ModuleAccess noHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("installed-computers")}>
                <InstalledComputers noHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("team-notifications")}>
                <TeamNotifications noHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("patient-triage")}>
                <PatientTriageTab />
            </SimpleRoute>
            <SimpleRoute path={tabPath("sms-settings")}>
                <SenderId noHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("profile")}>
                <SettingsProfile hideHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("password")}>
                <SettingsPassword />
            </SimpleRoute>
            <SimpleRoute path={tabPath("message-signature")}>
                <SettingsSignature hideHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("cookies")}>
                <SettingsCookies hideHeader />
            </SimpleRoute>
            <SimpleRoute path={tabPath("security")}>
                <SettingsSecurity hideHeader isChain />
            </SimpleRoute>
            <SimpleRoute path={tabPath("terms-and-conditions")}>
                <SettingsTermsAndConditions hideHeader />
            </SimpleRoute>
            <Redirect from={ROUTES_ORGS.settings} to={catchallUrl} />
        </Switch>
    );
};
