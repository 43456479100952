import { Children, ReactNode } from "react";

export const Lines = ({ children }: { children: ReactNode }) => (
    <>
        {Children.map(children, (child) =>
            child ? (
                <>
                    {child}
                    <br />
                </>
            ) : null,
        )}
    </>
);
