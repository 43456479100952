/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { BASE_AXIS_CONFIG, BASE_OPTIONS } from "./Graph.constants";
import { CommonProps, GraphProps, GraphType } from "./Graph.types";

type Props = CommonProps & GraphProps;

/**This component uses highChart to generate the graph, for more on the options required for a particular graph, you can find it on the following link:
https://api.highcharts.com/highcharts/?_ga=2.202121873.1221882377.1659538249-1498261574.1655393042
*/
export const Graph = ({
    xAxisCategories,
    yAxisCategories,
    series,
    customLegend,
    ...props
}: Props): JSX.Element => {
    let opts;

    switch (props.graphType) {
        case GraphType.Column:
            opts = {
                ...BASE_OPTIONS,
                chart: { type: props.graphType },
                xAxis: {
                    ...BASE_AXIS_CONFIG,
                    categories: xAxisCategories,
                    ...props.chartOptions.xAxis,
                },
                yAxis: {
                    ...BASE_AXIS_CONFIG,
                    ...props.chartOptions.yAxis,
                },
                series,
                plotOptions: props.chartOptions.plotOptions,
                legend: props.chartOptions.legend || null,
            };
            break;
        case GraphType.Heatmap:
            opts = {
                ...BASE_OPTIONS,
                chart: { type: props.graphType },
                xAxis: {
                    ...BASE_AXIS_CONFIG,
                    ...props.chartOptions.xAxis,
                    categories: xAxisCategories,
                },
                yAxis: {
                    ...BASE_AXIS_CONFIG,
                    ...props.chartOptions.yAxis,
                    categories: yAxisCategories,
                },
                series: [
                    ...series.map((data) => ({
                        ...data,
                        ...props.chartOptions.series,
                    })),
                ],
                tooltip: props.chartOptions.tooltip || null,
                plotOptions: props.chartOptions.plotOptions || null,
                legend: props.chartOptions.legend || null,
                colorAxis: props.chartOptions.colorAxis || null,
            };
            break;
        default:
            throw new Error("Type of chart not recognised");
    }

    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={opts} />
            {customLegend}
        </>
    );
};
