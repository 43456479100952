import { MessagePatientProps } from "domains/concierge/types/messagePatient.types";

import * as PatientMessagingApiClient from "../PatientMessagingApiClient";
import { mapMessagePatientResponseItemsToConciergeUpdateConversationItems } from "../mappers/mapMessagePatientResponseToConciergeUpdateConversationItems";
import { mapMessagePatientWithExternalIdentity } from "../mappers/mapMessagePatientWithExternalIdentity";

export const messagePatientWithExternalIdentity = async (
    arg: MessagePatientProps,
) => {
    const request = mapMessagePatientWithExternalIdentity({ ...arg });

    const response =
        await PatientMessagingApiClient.messagePatientWithExternalIdentity(
            request,
        );

    const mappedItems =
        mapMessagePatientResponseItemsToConciergeUpdateConversationItems(
            response,
        );

    return {
        updates: {
            conversations: [
                {
                    ...arg.conversation,
                    items: mappedItems ?? [],
                },
            ],
            teams: [],
            users: [],
            patients: [],
        },
    };
};
