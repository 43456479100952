import {
    BaseAppointmentRemindersResponse,
    CreateAppointmentReminderRequest,
    GetAppointmentRemindersResponse,
} from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type CreateReminderParameters = {
    workspaceId: string;
};

const createReminder = (
    workspaceId: string,
    reminder: CreateAppointmentReminderRequest,
): Promise<IWrappedResult<BaseAppointmentRemindersResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/AppointmentReminders/:workspaceId/create",
            params: { workspaceId: workspaceId },
        }),
        reminder,
    );

export const useCreateReminder = ({
    workspaceId,
}: CreateReminderParameters) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [
            "AppointmentReminders",
            "CreateReminder",
            { workspaceId },
        ],
        mutationFn: async (reminder: CreateAppointmentReminderRequest) => {
            const response = await createReminder(workspaceId, reminder);
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(
                response.error ?? "Could not create appointment reminder",
            );
        },
        onSuccess(data) {
            queryClient.setQueryData<GetAppointmentRemindersResponse>(
                [
                    "AppointmentReminders",
                    "RemindersAndFilterOptions",
                    { workspaceId },
                ],
                (previousData) => {
                    if (!previousData) {
                        return;
                    }

                    return {
                        ...previousData,
                        appointmentReminders: data.appointmentReminders,
                    };
                },
            );
        },
    });
};
