import { useCurrentWorkspace } from "@accurx/auth";
import { getDay, isTomorrow } from "date-fns";

import { DAYS_OF_THE_WEEK } from "../components/Compose/components/ScheduleSend/constants/constants";
import { getDateOptions } from "../components/Compose/components/ScheduleSend/utils/getDateOptions";
import { useBankHolidays } from "./useBankHolidays";
import { useNextWorkingDay } from "./useNextWorkingDay";

export const usePresetDateOptions = () => {
    const workspace = useCurrentWorkspace();

    const { data: bankHolidays, status: bankHolidaysStatus } = useBankHolidays({
        workspaceId: workspace.orgId,
    });

    const options =
        bankHolidaysStatus !== "loading" ? getDateOptions(bankHolidays) : [];

    const shouldFetchNextWorkingDay = !!options.find(
        ({ value }) => value === "nextWorkingDay",
    );

    const { data: nextWorkingDay, status: nextWorkingDayStatus } =
        useNextWorkingDay({
            workspaceId: workspace.orgId,
            enabled: shouldFetchNextWorkingDay,
        });

    const nextWorkingDayNumber = nextWorkingDay
        ? getDay(new Date(nextWorkingDay))
        : null;

    const nextWorkingDayName = nextWorkingDayNumber
        ? DAYS_OF_THE_WEEK[nextWorkingDayNumber]
        : "";

    const isNextWorkingDayTomorrow = nextWorkingDay
        ? isTomorrow(new Date(nextWorkingDay))
        : false;

    const isLoading =
        (nextWorkingDayStatus === "loading" && shouldFetchNextWorkingDay) ||
        bankHolidaysStatus === "loading";

    const isError =
        nextWorkingDayStatus === "error" || bankHolidaysStatus === "error";

    // that behaviour could be updated
    // BECKS-1438
    return {
        options:
            isLoading || isError
                ? []
                : options.map(({ value, text }) => ({
                      value,
                      text:
                          value === "nextWorkingDay"
                              ? isNextWorkingDayTomorrow
                                  ? `Tomorrow ${text}`
                                  : `${nextWorkingDayName} ${text}`
                              : text,
                  })),
        // depending on UI needs probably we will need to return more info form that hook
        status: isLoading ? "loading" : isError ? "error" : "success",
        nextWorkingDay,
    };
};
