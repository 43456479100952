import React, { useState } from "react";

import { Button, Card, Feedback, Flex, Icon, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";

import {
    EnrolmentSchedule,
    useValidateFloreyEnrolmentMutation,
} from "app/hooks/mutations/useValidateFloreyEnrolmentMutation";
import { useQuestionnaireListQuery } from "app/hooks/queries";
import { ModalContent } from "app/sharedComponents/ModalContent/ModalContent";

import { PatientType } from "../Conversation.types";
import { QuestionnairesList } from "./QuestionnairesList";
import {
    filterGroupsWithAvailableTemplatesOnly,
    mapFloreyTemplatesResponseToDisplay,
} from "./mappers";
import { DisplayFloreyMessageTemplate } from "./types";

type SinglePatientQuestionnairesListProps = {
    onUseQuestionnaireClick: (template: DisplayFloreyMessageTemplate) => void;
    /** Needed for fetching the org questionnaires */
    workspaceId: number;
    patient: PatientType;
};

/**
 * The API succeeded, but the patient cannot be enrolled
 */
const showFailToEnrolErrorToast = (): void => {
    toast(
        <Feedback
            colour="error"
            title="Cannot enrol the patient in this questionnaire"
        >
            <Text skinny>
                The patient cannot be enrolled in this questionnaire, please
                pick a different one or cancel.
            </Text>
        </Feedback>,
    );
};

/**
 * The API failed for some reason
 */
const showEnrolRequestErrorToast = (): void => {
    toast(
        <Feedback colour="error" title="Something went wrong">
            <Text skinny>Please try again.</Text>
        </Feedback>,
    );
};

export const SinglePatientQuestionnairesList = ({
    onUseQuestionnaireClick,
    workspaceId,
    patient,
}: SinglePatientQuestionnairesListProps): JSX.Element => {
    const [twoCopiesWarningModalOpen, setTwoCopiesWarningModalOpen] =
        useState(false);
    const [previouslySentQuestionnaire, setPreviouslySentQuestionnaire] =
        useState<
            | (EnrolmentSchedule & {
                  questionnaire: DisplayFloreyMessageTemplate;
              })
            | null
        >(null);
    const { status, data } = useQuestionnaireListQuery(
        { workspaceId, forBatch: false },
        {
            select: (data) => {
                const mapped = mapFloreyTemplatesResponseToDisplay(data);
                return filterGroupsWithAvailableTemplatesOnly(mapped);
            },
        },
    );

    const validateFloreyEnrolmentMutation = useValidateFloreyEnrolmentMutation({
        onSuccess: (validated, context) => {
            if (validated.canBeEnrolled) {
                const lastSentQuestionnaire =
                    validated.enrolmentSchedules.at(-1);

                if (!lastSentQuestionnaire) {
                    setTwoCopiesWarningModalOpen(false);
                    onUseQuestionnaireClick(context.questionnaire);
                } else {
                    setTwoCopiesWarningModalOpen(true);
                    setPreviouslySentQuestionnaire({
                        startDate: lastSentQuestionnaire.startDate,
                        endDate: lastSentQuestionnaire.endDate,
                        questionnaire: context.questionnaire,
                    });
                }
            } else {
                showFailToEnrolErrorToast();
            }
        },
        onError: () => {
            showEnrolRequestErrorToast();
        },
    });

    const handleUseQuestionnaire = (template: DisplayFloreyMessageTemplate) => {
        validateFloreyEnrolmentMutation.mutate({
            workspaceId,
            patientIds: patient?.externalIds,
            questionnaire: template,
        });
    };

    if (status === "loading" || status === "error") {
        return <QuestionnairesList workspaceId={workspaceId} status={status} />;
    }

    return (
        <>
            <QuestionnairesList
                workspaceId={workspaceId}
                status={status}
                questionnaires={data.groups}
                onUseQuestionnaireClick={handleUseQuestionnaire}
                isValidating={validateFloreyEnrolmentMutation.isLoading}
            />
            <Modal
                isOpen={twoCopiesWarningModalOpen}
                toggle={() =>
                    setTwoCopiesWarningModalOpen((prevState) => !prevState)
                }
                centered
                size="md"
                fade={false}
            >
                {previouslySentQuestionnaire !== null && (
                    <ModalContent.Layout>
                        <ModalContent.Header>
                            {`Does ${
                                patient?.firstName || "the patient"
                            } need two copies of this questionnaire?`}
                        </ModalContent.Header>
                        <ModalContent.Body>
                            <Card
                                isElevated={false}
                                spacing={2}
                                header={
                                    <Flex gap="1" alignItems="center">
                                        <div>
                                            <Icon
                                                name="Calendar"
                                                size={4}
                                                halo={{
                                                    colour: "green",
                                                }}
                                            />
                                        </div>
                                        <Text variant="label" skinny>
                                            {patient?.firstName ||
                                                "the patient"}{" "}
                                            is responding to an existing copy
                                        </Text>
                                    </Flex>
                                }
                            >
                                <Flex flexDirection="column" gap="2">
                                    <div>
                                        <Text variant="label" skinny>
                                            Questionnaire
                                        </Text>
                                        <Text skinny>
                                            {
                                                previouslySentQuestionnaire
                                                    .questionnaire.title
                                            }
                                        </Text>
                                    </div>
                                    <div>
                                        <Text variant="label" skinny>
                                            Sent
                                        </Text>
                                        <Text skinny>
                                            {DateHelpers.formatDate(
                                                previouslySentQuestionnaire.startDate,
                                                DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                            )}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text variant="label" skinny>
                                            Date to reply
                                        </Text>
                                        <Text skinny>
                                            Until{" "}
                                            {DateHelpers.formatDate(
                                                previouslySentQuestionnaire.endDate,
                                                DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                            )}
                                        </Text>
                                    </div>
                                </Flex>
                            </Card>
                        </ModalContent.Body>
                        <ModalContent.Footer>
                            <Flex justifyContent="space-between">
                                <Button
                                    theme="danger"
                                    text="Cancel second copy"
                                    onClick={() =>
                                        setTwoCopiesWarningModalOpen(false)
                                    }
                                />
                                <Button
                                    theme="primary"
                                    text="Yes, they need two copies"
                                    onClick={() => {
                                        setTwoCopiesWarningModalOpen(false);
                                        onUseQuestionnaireClick(
                                            previouslySentQuestionnaire.questionnaire,
                                        );
                                    }}
                                />
                            </Flex>
                        </ModalContent.Footer>
                    </ModalContent.Layout>
                )}
            </Modal>
        </>
    );
};
