import { generatePath, useParams } from "react-router";

import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES_PRIMARY } from "shared/Routes";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

export const QuestionnaireScoreboardNavigation = ({
    onClick,
}: {
    onClick: () => void;
}) => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const routeMatchWithTwoFactor = useRouteMatchOrTwoFactorPrompt({
        path: ROUTES_PRIMARY.questionnaireScoreboard,
    });

    if (!routeMatchWithTwoFactor) {
        return null;
    }

    return (
        <StyledContainer>
            <SecondaryNavigationHeader>
                Questionnaires
            </SecondaryNavigationHeader>
            <SecondaryNavigationContent>
                {!!workspaceId && (
                    <StyledLinkList>
                        <li>
                            <NavLink
                                to={generatePath(
                                    ROUTES_PRIMARY.questionnaireScoreboard,
                                    {
                                        workspaceId,
                                    },
                                )}
                                text="Responses"
                                variant={LinkVariant.Secondary}
                                onClick={onClick}
                            />
                        </li>
                    </StyledLinkList>
                )}
            </SecondaryNavigationContent>
        </StyledContainer>
    );
};
