import { useCurrentWorkspace } from "@accurx/auth";
import { validateFile } from "@accurx/design/dist/components/Attachment/Attachment.hooks";
import { useUploadFileMutation } from "domains/compose/hooks/useUploadFileMutation";
import { AttachmentSource } from "domains/compose/reducer.types";

import { SUPPORTED_FILE_EXTENSIONS } from "./upload.constants";
import { UploadFilesOptions } from "./upload.types";

export const useUploadFromDesktop = (source: AttachmentSource) => {
    const uploadFileMutation = useUploadFileMutation(source);
    const orgId = useCurrentWorkspace().orgId;
    const workspaceId = orgId.toString();

    const onUpload = async (
        file: File,
        options: UploadFilesOptions,
    ): Promise<
        | {
              status: "success";
              result: {
                  id: string;
                  previewUrl?: string;
              };
          }
        | {
              status: "error";
              result: { errors: string[] };
          }
    > => {
        const validated = validateFile({
            file,
            maxAllowedFileSize: {
                size: options.maxFileSizeInMegabytes * 1024 * 1024,
                humanReadable: `${options.maxFileSizeInMegabytes} MB`,
            },
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });
        if (!validated.isValid) {
            return {
                status: "error",
                result: {
                    errors: validated.errors,
                },
            };
        }
        try {
            const result = await uploadFileMutation.mutateAsync({
                file,
                workspaceId,
            });

            return {
                status: "success",
                result,
            };
        } catch (error) {
            let errorMessage = "Failed to upload";
            if (error instanceof Error && error.message) {
                errorMessage = error.message;
            }
            return {
                status: "error",
                result: {
                    errors: [errorMessage],
                },
            };
        }
    };

    return {
        onUpload,
    };
};
