import React, { useEffect, useMemo, useState } from "react";

import { PatientNoteTagsWithTokenRequest } from "@accurx/api/ticket";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import { Redirect, generatePath, useHistory, useParams } from "react-router";

import {
    IdType,
    PatientExternalIdentityDto,
    PatientNoteTags,
    PatientThreadContentType,
} from "api/FlemingDtos";
import { postMarkNoteReadWithToken } from "api/MessagingApi";
import { useSelectedPatient } from "app/patients/hooks";
import { useIsTwoFactorAuthenticated } from "app/twoFactorAuth/TwoFactorReducer";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES } from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import * as TicketApiClient from "shared/concierge/conversations/tickets/TicketApiClient";
import {
    createUniqueConversationId,
    mapTicketToConversation,
    parseUniqueConversationId,
    parseUniqueNoteId,
} from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import { mapTicketPatientToPatientSummary } from "shared/concierge/conversations/tickets/mappers/PatientMapper";
import { TicketIdentityType } from "shared/concierge/conversations/tickets/types/dto.types";
import { PatientExternalIdentity } from "shared/concierge/patients/types/patient.types";
import { UsersAndTeamsManagerProvider } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";
import { useCurrentUserId } from "store/hooks";

import { Conversation } from "../components/Conversation/Conversation";
import { mapPatientThreadSmsOrEmailToConversationItems } from "../components/Conversation/Conversation.helpers";
import {
    ConversationSummary,
    OnSendMessageSuccessType,
    PatientData,
} from "../components/Conversation/Conversation.types";

/**
 * This component can be used to display the conversation UI for a user that has accessed the conversation from outside of the inbox (and therefore may not have workspace actions or 2FA enabled)
 *
 * This component relies on us having information about the current patient (which at the moment will mean a patient search has happened)
 * When redirecting to or rendering this component, a searched patient of type SearchedPatientSummary must be saved in the location state
 * We use this rather than the redux store so that the page can load quicker, and does not have to wait for the patient to be fetched
 *
 */
export const NewConversation = ({ workspaceId }: { workspaceId: number }) => {
    const [conversation, setConversation] =
        useState<ConversationSummary | null>(null);
    const currentUserId = useCurrentUserId();
    const isTwoFactorAuthenticated = useIsTwoFactorAuthenticated();
    const history = useHistory();

    const [hasError, setHasError] = useState<boolean>(false);

    const [patientData, setPatientData] = useState<PatientData>({
        data: null,
        isLoading: true,
    });

    const { conversationId } = useParams<{ conversationId: string }>();

    useAccurxWebTitle(
        conversationId
            ? "View conversation with patient"
            : "Start new conversation with patient",
    );

    const selectedPatient = useSelectedPatient();
    const searchedPatient = useMemo<PatientData>(() => {
        return selectedPatient
            ? {
                  data: {
                      patientToken: selectedPatient.patientToken || "",
                      firstName: selectedPatient.firstName as string,
                      familyName: selectedPatient.familyName as string,
                      dateOfBirth: selectedPatient.dateOfBirth as string,
                      ageYear: selectedPatient.ageYear,
                      gender: selectedPatient.gender as string,
                      prefixName: selectedPatient.prefixName as string,
                      externalIds: [
                          {
                              type: "NhsNumber",
                              value: selectedPatient.nhsNumber as string,
                          },
                      ],
                      mobileNumber: selectedPatient.mobileNumber as string,
                  },
                  isLoading: false,
              }
            : { data: null, isLoading: true };
    }, [selectedPatient]);

    const patientToken = searchedPatient?.data?.patientToken;

    useEffect(() => {
        if (conversationId && patientToken) {
            const fetchNewConversation = async () => {
                const ticketIdentity =
                    parseUniqueConversationId(conversationId);

                if (!ticketIdentity) {
                    setHasError(true);
                    Log.error(
                        "Could not fetch conversation as failed to parse conversation id ",
                        {
                            tags: { conversationId },
                        },
                    );
                    return;
                }

                let newConversation;

                try {
                    newConversation =
                        await TicketApiClient.fetchTicketWithToken(
                            workspaceId,
                            ticketIdentity,
                            patientToken,
                        );
                } catch {
                    setHasError(true);
                    return;
                }

                const mappedConversation =
                    mapTicketToConversation(newConversation);

                const mappedPatient = newConversation.patient
                    ? mapTicketPatientToPatientSummary(
                          newConversation.patient,
                      ) ?? null
                    : null;

                if (!mappedConversation) {
                    setHasError(true);
                    Log.error("Could not fetch conversation");
                    return;
                }

                const {
                    id,
                    assignee,
                    isFullyLoaded,
                    items,
                    status,
                    regardingPatientId,
                } = mappedConversation;

                const formattedConversation: ConversationSummary = {
                    id,
                    assignee,
                    isFullyLoaded,
                    items,
                    status,
                    regardingPatientId,
                };

                setConversation(formattedConversation);
                setPatientData({ isLoading: false, data: mappedPatient });
            };

            fetchNewConversation();
        } else {
            setPatientData(searchedPatient);
        }
    }, [
        conversationId,
        workspaceId,
        isTwoFactorAuthenticated,
        patientToken,
        searchedPatient,
    ]);

    // If no searched for patient is in the state, a user has come from outside the app to try to access this page.
    // Instead, direct them to the home page
    if (!PatientHelper.getPatientTokenWithStorageFallback(null) || hasError) {
        return <Redirect to={generatePath(ROUTES.home)} />;
    }

    // This should never actually happen, but just in case
    if (!currentUserId) {
        Log.error("A conversation page was accessed with no user ID");
        return <Redirect to={generatePath(ROUTES.login)} />;
    }

    const onSendMessageSuccess: OnSendMessageSuccessType = (messages) => {
        if (messages) {
            const firstMessage = messages[0];
            const newConversationId = firstMessage.id;
            const patientId = firstMessage.patientAccuRxId;
            const newConversationItems =
                mapPatientThreadSmsOrEmailToConversationItems(messages);

            if (!newConversationId) {
                Log.error(
                    "Unable to update conversation UI: no conversation ID",
                );
                return;
            }

            if (!patientId) {
                Log.error("Unable to update conversation UI: no patient ID");
                return;
            }

            if (conversation) {
                setConversation((previousConversation) => {
                    // If a message has already been sent before, add to the existing conversation
                    if (previousConversation) {
                        return {
                            ...previousConversation,
                            items: [
                                ...previousConversation.items,
                                ...newConversationItems,
                            ],
                        };
                    } else return previousConversation; // this shouldn't actually ever happen
                });
            } else {
                const ticketIdType =
                    firstMessage.type === PatientThreadContentType.PatientEmail
                        ? TicketIdentityType.PatientEmail
                        : TicketIdentityType.SMS;
                const uniqueId = createUniqueConversationId({
                    type: ticketIdType,
                    id: newConversationId,
                });

                const pathname = history.location.pathname.replace(
                    "/new",
                    `/new/${uniqueId}`,
                );

                history.replace({
                    pathname,
                });
            }
        }
    };

    const markItemAsRead = async (
        conversationItemId: string,
        patientExternalIdentity: PatientExternalIdentity | null,
    ) => {
        if (conversation?.id) {
            const itemId = parseUniqueNoteId(conversationItemId);

            const ticketPatientExternalIdentity:
                | PatientExternalIdentityDto
                | undefined = patientExternalIdentity
                ? {
                      patientExternalIds: [
                          {
                              type: IdType[patientExternalIdentity.type],
                              value: patientExternalIdentity.value,
                          },
                      ],
                  }
                : undefined;

            if (!itemId) {
                throw new Error(
                    `Unable to mark conversation item as read - cannot parse conversation item ID ${conversationItemId}.`,
                );
            }

            if (isTwoFactorAuthenticated) {
                const request: PatientNoteTags = {
                    organisationId: workspaceId,
                    patientExternalIdentity: ticketPatientExternalIdentity,
                    patientThreadItemIds: [itemId],
                };
                await TicketApiClient.markNoteRead(request);
            } else {
                if (!patientToken) {
                    Log.error(
                        "markNoteAsRead - patientToken must be defined when user is not 2FAed.",
                    );
                    return;
                }
                const request: PatientNoteTagsWithTokenRequest = {
                    organisationId: workspaceId,
                    patientThreadItemIds: [itemId],
                    patientToken: patientToken,
                };
                await postMarkNoteReadWithToken(request);
            }
        }
    };

    return (
        <Conversation
            conversation={conversation}
            patient={patientData}
            conversationActions={{
                onSendMessageSuccess,
                markItemAsRead,
            }}
            currentUserId={currentUserId}
            composeAreaDefaultOpen={conversationId ? false : true}
        />
    );
};

export const NewConversationPage = () => {
    const workspaceId = useWorkspaceId();

    return (
        <UsersAndTeamsManagerProvider workspaceId={workspaceId}>
            <NewConversation workspaceId={workspaceId} />
        </UsersAndTeamsManagerProvider>
    );
};
