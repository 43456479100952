import React from "react";

import { Icon, Text } from "@accurx/design";
import moment from "moment";

import { PatientList } from "api/FlemingDtos";

import PatientListBadge from "../PatientListTypeBadge";
import {
    StyledActionButtons,
    StyledHeader,
    StyledHeaderInfoSection,
    StyledTime,
} from "./PatientListSubMenu.styles";

type PatientListSubMenuProps = {
    patientList: PatientList | null;
    editNameButton?: JSX.Element;
    actionButtons?: JSX.Element;
    shareButtons?: JSX.Element;
};

export const PatientListSubMenu = ({
    patientList,
    editNameButton,
    actionButtons,
    shareButtons,
}: PatientListSubMenuProps): JSX.Element => {
    return (
        <>
            <StyledHeader>
                <Text
                    variant="subtitle"
                    props={{ className: "my-0 d-flex align-items-center" }}
                >
                    {patientList?.name}
                    {editNameButton}
                </Text>
                <StyledHeaderInfoSection>
                    {patientList?.lastUpdated && (
                        <StyledTime variant="note">
                            <Icon name="Clock" size={3} />
                            <span>
                                Last update ·{" "}
                                {moment(patientList.lastUpdated).fromNow()}
                            </span>
                        </StyledTime>
                    )}
                    <PatientListBadge listType={patientList?.listType} />
                    {shareButtons && (
                        <StyledActionButtons>
                            {shareButtons}
                        </StyledActionButtons>
                    )}
                    {actionButtons && (
                        <StyledActionButtons>
                            {actionButtons}
                        </StyledActionButtons>
                    )}
                </StyledHeaderInfoSection>
            </StyledHeader>
        </>
    );
};
