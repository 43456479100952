import random from "lodash/random";

/**
 * Generates a refresh interval in milliseconds with a randomised "jitter".
 * @param minMs the minimum number of milliseconds for the interval.
 * @param jitterRatio the percentage of the minMS (as a decimal) that the interval should jitter by.
 * @returns the refresh interval with jitter built in.
 */
export const generateRefreshIntervalWithJitterMs = (
    minMs: number,
    jitterRatio = 0.1,
): number => {
    const maxMs = minMs * jitterRatio + minMs;

    return random(minMs, maxMs, false);
};
