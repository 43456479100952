/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect } from "react";

import { Button, Spinner, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { UpdatingStatus } from "shared/LoadingStatus";

export interface VaccineDeliveryAddToNetworkModalProps {
    inviteId: string;
    showAddToNetworkModal: boolean;
    onToggleModal: () => void;
    onConfirm: (inviteId: string) => void;
    onFinish: () => void;
    addingToNetworkStatus: UpdatingStatus;
    addingToNetworkError: string;
}

const renderModalText = (
    addingToNetworkStatus: UpdatingStatus,
    addingToNetworkError: string,
): JSX.Element => {
    switch (addingToNetworkStatus) {
        case UpdatingStatus.Initial:
            return (
                <>
                    <Text>
                        This patient was already invited by a practice outside
                        your network. By clicking add to network, you will
                        cancel their original invite.
                    </Text>
                    <Text>
                        Please note: a patient can be added to a new network
                        only if they reached out to you asking to be invited by
                        your practice.
                    </Text>
                </>
            );
        case UpdatingStatus.Loading:
            return (
                <>
                    <Text>Adding patient to your network</Text>
                    <Spinner />
                </>
            );
        case UpdatingStatus.Loaded: // Modal should auto-close, but displaying this in case of a delay on the user's PC
            return (
                <>
                    <Text>Patient successfully added to your network</Text>
                </>
            );
        case UpdatingStatus.Failed:
            return (
                <>
                    {addingToNetworkError && (
                        <Text>{addingToNetworkError}</Text>
                    )}
                </>
            );
        default:
            return <></>;
    }
};

const renderModalButtons = (
    inviteId: string,
    addingToNetworkStatus: UpdatingStatus,
    onToggleModal: () => void,
    onConfirm: (inviteId: string) => void,
    onFinish: () => void,
): JSX.Element => {
    switch (addingToNetworkStatus) {
        case UpdatingStatus.Initial:
            return (
                <>
                    <Button
                        text="Cancel"
                        theme="secondary"
                        onClick={onToggleModal}
                        className="mx-1"
                    />
                    <Button
                        text="Invite through your practice"
                        onClick={(): void => onConfirm(inviteId)}
                    />
                </>
            );
        case UpdatingStatus.Loading:
            return (
                <>
                    <Button
                        text="Cancel"
                        theme="secondary"
                        disabled
                        className="mx-1"
                    />
                    <Button text="Invite through your practice" disabled />
                </>
            );
        case UpdatingStatus.Loaded:
        case UpdatingStatus.Failed:
            return (
                <>
                    <Button text="Finish" onClick={onFinish} />
                </>
            );
        default:
            return <></>;
    }
};

export const VaccineDeliveryAddToNetworkModal = ({
    inviteId,
    showAddToNetworkModal,
    onToggleModal,
    onConfirm,
    onFinish,
    addingToNetworkStatus,
    addingToNetworkError,
}: VaccineDeliveryAddToNetworkModalProps): JSX.Element => {
    useEffect(() => {
        // auto-close the modal if the API call is a success
        if (addingToNetworkStatus === UpdatingStatus.Loaded) {
            onFinish();
        }
    }, [addingToNetworkStatus]);

    return (
        <Modal
            isOpen={showAddToNetworkModal}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
            backdrop="static" // We show the error in here so need to wait for the API call to complete without the user clicking off the modal midway
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h1" skinny>
                    Invite through your practice
                </Text>
            </ModalHeader>
            <ModalBody className={"bg-cloud"}>
                {renderModalText(addingToNetworkStatus, addingToNetworkError)}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                {renderModalButtons(
                    inviteId,
                    addingToNetworkStatus,
                    onToggleModal,
                    onConfirm,
                    onFinish,
                )}
            </ModalFooter>
        </Modal>
    );
};
