import React from "react";

import {
    StyledInnerStackPanel,
    StyledOuterStackPanel,
} from "./LoginStackPanel.styles";

export const OuterStackPanel = ({
    backgroundImage,
    compensateForLayoutMargins,
    children,
}: {
    backgroundImage?: string;
    compensateForLayoutMargins?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <StyledOuterStackPanel
            verticalContentAlignment="center"
            gutter={2}
            horizontalContentAlignment="center"
            backgroundImage={backgroundImage || undefined}
            compensateForLayoutMargins={compensateForLayoutMargins}
        >
            {children}
        </StyledOuterStackPanel>
    );
};

export const InnerStackPanel = ({
    children,
    horizontalContentAlignment = "center",
    shadow,
}: {
    horizontalContentAlignment?: "center" | "left" | "right" | "stretch";
    shadow?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <StyledInnerStackPanel
            verticalContentAlignment="center"
            horizontalContentAlignment={horizontalContentAlignment}
            gutter={2}
            shadow={shadow}
        >
            {children}
        </StyledInnerStackPanel>
    );
};
