import React, { useRef } from "react";

import { useCurrentUser } from "@accurx/auth";
import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useIsEllipsisActive } from "domains/inbox/hooks/util/useIsEllipsisActive";
import { userflowIds } from "domains/inbox/util";
import styled from "styled-components";

import { StyledAssigneeLabel, StyledPillText } from "./AssigneeButton.styles";

export type AssigneeButtonProps = {
    conversation: Conversation;
    onClick: () => void;
};

export type Ref = HTMLButtonElement;

const Container = styled.div`
    display: inline-block;
    max-width: 100%;
    position: relative;
`;

export const AssigneeButton = React.forwardRef<Ref, AssigneeButtonProps>(
    ({ conversation, onClick, ...props }, ref) => {
        const nameRef = useRef<HTMLElement>(null);
        const isEllipsisActive = useIsEllipsisActive(nameRef.current);

        const { type } = conversation.assignee;

        const assignee = useAssignee(conversation.assignee);
        const { user: currentUser } = useCurrentUser();

        const isUnassigned =
            !assignee ||
            (type === "Team" && assignee.displayName === "Unassigned");

        const isCurrentUser =
            type === "User" && assignee?.id === currentUser.accuRxUserId;

        const assigneeName = isCurrentUser
            ? "You"
            : isUnassigned
            ? "Unassigned"
            : assignee.displayName;

        return (
            <Container>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    props={{
                        root: { $withEllipsis: true },
                    }}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {isUnassigned
                                ? assigneeName
                                : `Assigned: ${assigneeName}`}
                        </UI.Text>
                    }
                >
                    <Pill.Dropdown
                        radius="full"
                        ref={ref}
                        data-userflow-id={
                            userflowIds.conversationView.assignButton
                        }
                        {...props}
                        onClick={onClick}
                    >
                        <Pill.Icon
                            name="Assign"
                            colour="blue"
                            theme="Fill"
                            props={{
                                "aria-hidden": true,
                            }}
                        />
                        <StyledPillText ref={nameRef}>
                            {isUnassigned ? (
                                <>Unassigned</>
                            ) : (
                                <>
                                    <UI.VisuallyHidden>
                                        Assigned: {assigneeName}
                                    </UI.VisuallyHidden>
                                    <span aria-hidden>
                                        <StyledAssigneeLabel>
                                            Assigned:{" "}
                                        </StyledAssigneeLabel>
                                        <em>{assigneeName}</em>
                                    </span>
                                </>
                            )}
                        </StyledPillText>
                    </Pill.Dropdown>
                </WithTooltip>
            </Container>
        );
    },
);
