export enum PatientIdType {
    Unknown = 0,
    NhsNumber = 1,
    Emis = 2,
    SystmOne = 3,
    Vision = 4,
}

export type PatientIdStatusType =
    | PatientIdType.Unknown
    | PatientIdType.NhsNumber
    | PatientIdType.Emis;

export enum DeliveryReceiptStatus {
    Sent = "Sent",
    Failed = "Failed",
    Delivered = "Delivered",
    Unknown = "Unknown",
}

export type ReceiptItem = {
    dateSent?: string;
    sender?: string;
    status?: DeliveryReceiptStatus;
    patientIdDisplay?: string;
    patientIdDisplayType?: PatientIdStatusType;
};
