import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledOverlay = styled(Flex).attrs(() => ({
    justifyContent: "center",
    alignItems: "center",
}))`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${Tokens.COLOURS.primary.blue[10]};
`;
