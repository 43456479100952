import React, { MouseEvent, useCallback, useEffect, useState } from "react";

import { Button, Card, Feedback, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { useSafeAsync } from "api/Api.utils";
import { PatientRecordViewRequest } from "api/FlemingDtos";
import { requestRecordView } from "api/RecordViewApi";
import { ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";

export type RequestRecordGpFlowProps = Omit<
    PatientRecordViewRequest,
    "isGpOwnPatient"
>;

/**
 * Component shown to users when they can skip patient authorisation
 * when trying to view a patient's record
 */
export const RequestRecordGpFlow = ({
    patientToken,
    organisationId,
}: RequestRecordGpFlowProps): JSX.Element => {
    const safeAsync = useSafeAsync();
    const history = useHistory();

    const [requestId, setRequestId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const requestRecord = useCallback(async () => {
        setIsLoading(true);
        const res = await safeAsync(
            requestRecordView({
                patientToken,
                organisationId,
                isGpOwnPatient: true,
            }),
        );
        setIsLoading(false);

        if (typeof res.requestId === "string" && res.requestId.length > 0) {
            setRequestId(res.requestId);
        } else {
            setError(res.error ?? "Unable to load the record");
        }
    }, [organisationId, patientToken, safeAsync]);

    useEffect(() => {
        requestRecord();
    }, [requestRecord]);

    const handleTryAgain = (e: MouseEvent<HTMLLinkElement>): void => {
        e.preventDefault();
        setError("");
        requestRecord();
    };

    const handleViewRecord = () => {
        requestId &&
            history.push(
                `${findBaseRoute(history.location.pathname)}${
                    ROUTES_EXTENSION.remoteRecordViewMedicalRecord
                }?request=${requestId}`,
            );
    };

    return (
        <Card
            footer={
                <div className="d-flex">
                    {isLoading && (
                        <Text
                            colour="metal"
                            props={{ className: "mr-2" }}
                            skinny
                        >
                            Record View loading...
                        </Text>
                    )}
                    <Button
                        text="Continue"
                        onClick={handleViewRecord}
                        disabled={requestId === null}
                        className="ml-auto"
                    />
                </div>
            }
        >
            {error && (
                <Feedback
                    colour="error"
                    title="An error occured"
                    content={
                        <Text as="p">
                            {error}.{" "}
                            <Text
                                as="a"
                                variant="link"
                                props={{ href: "#", onClick: handleTryAgain }}
                            >
                                Please click here to try again
                            </Text>
                        </Text>
                    }
                    props={{ className: "mb-3" }}
                />
            )}
            <Text skinny>
                As this patient is registered at your practice, you do not need
                to request patient permission to view a summary of their GP
                record.
            </Text>
        </Card>
    );
};
