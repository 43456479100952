import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledButtonsContainer = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1]};
    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        flex-wrap: wrap;
    }
`;

const StyledTabPanels = styled.div`
    margin-bottom: ${Tokens.SIZES[8]};
`;

const StyledPatientInfoMenu = styled.div`
    margin-bottom: ${Tokens.SIZES[3]};
`;

export { StyledButtonsContainer, StyledTabPanels, StyledPatientInfoMenu };
