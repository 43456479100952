/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { Dispatch } from "redux";

import { CoreActions } from "shared/constants/actionTypes";
import { Values } from "shared/types/utils.types";

import { PATIENT_INITIATED_API_ENDPOINTS } from "./constants/ApiEndpoints";
import {
    WorkingHoursSettings,
    WorkingHoursSuspensionMessageSettings,
} from "./types";

export const getPatientTriageStatus =
    (organisationId: string) => async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.LOAD_PATIENT_TRIAGE_STATUS });
        const res = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.status,
                params: { organisationId },
            }),
        );
        if (res.success && res.result) {
            dispatch({
                type: CoreActions.LOAD_PATIENT_TRIAGE_STATUS_SUCCESS,
                status: res.result,
            });
            return;
        }
        dispatch({ type: CoreActions.LOAD_PATIENT_TRIAGE_STATUS_FAIL });
    };

export function setOutOfHoursFeature(organisationId: string, request: any) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS });
        const res = await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.updateOutOfHours,
                params: { organisationId },
            }),
            request,
        );
        if (res.success && res.result) {
            dispatch({
                type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_SUCCESS,
                status: res.result,
            });
            return;
        }
        dispatch({ type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_FAIL });
    };
}

export function setScheduledOutOfHoursFeature(
    organisationId: string,
    request: any,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS });
        const res = await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.scheduledOutOfHours,
                params: { organisationId },
            }),
            request,
        );
        if (res.success && res.result) {
            dispatch({
                type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_SUCCESS,
                status: res.result,
            });
            return;
        }
        dispatch({ type: CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_FAIL });
    };
}

export function updatePrescriptionSettings(
    organisationId: string,
    request: any,
) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updatePrescriptionSettings,
        organisationId,
        request,
    );
}

export function updateDisableUntilSettings(
    organisationId: string,
    request: DisableUntilRequest,
) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateDisableUntilSettings,
        organisationId,
        request,
    );
}

export function updateWelcomePage(organisationId: string, request: any) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateWelcomePage,
        organisationId,
        request,
    );
}

export function updateSubmissionPage(organisationId: string, request: any) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateSubmissionPage,
        organisationId,
        request,
    );
}

export function updateSubmissionSms(organisationId: string, request: any) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateSubmissionSms,
        organisationId,
        request,
    );
}

export function updateOutOfHoursMessage(organisationId: string, request: any) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateOutOfHoursMessage,
        organisationId,
        request,
    );
}

export function updatePTMessage(organisationId: string, request: any) {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updateDisabledMessage,
        organisationId,
        request,
    );
}

type SelfReferralEnabled = {
    patientSelfReferralEnabled: true;
    patientSelfReferralMessage: string;
};

type SelfReferralDisabled = {
    patientSelfReferralEnabled: false;
    patientSelfReferralMessage: string | null;
};

type PatientSelfReferralRequest = SelfReferralEnabled | SelfReferralDisabled;

export type DisableUntilRequest = {
    disableUntil: string | null;
    reason: string;
    comment: string;
    allowAdminRequests: boolean;
};

export function updatePatientSelfReferral(
    organisationId: string,
    request: PatientSelfReferralRequest,
): (dispatch: Dispatch) => Promise<void> {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.updatePatientSelfReferral,
        organisationId,
        request,
    );
}

export function updateWorkingHours(
    practiceId: string,
    request: WorkingHoursSettings,
): (dispatch: Dispatch) => Promise<void> {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.workingHours,
        practiceId,
        request,
    );
}

export function updateWorkingHoursSuspensionMessage(
    practiceId: string,
    request: WorkingHoursSuspensionMessageSettings,
): (dispatch: Dispatch) => Promise<void> {
    return postPatientInitiatedSettings(
        PATIENT_INITIATED_API_ENDPOINTS.suspensionMessage,
        practiceId,
        request,
    );
}

export function resetUpdateStatus() {
    return { type: CoreActions.RESET_UPDATING_STATUS };
}

function postPatientInitiatedSettings(
    endpoint: Values<typeof PATIENT_INITIATED_API_ENDPOINTS>,
    organisationId: string,
    request: Record<string, unknown>,
) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS });
        const res = await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: endpoint,
                params: { organisationId },
            }),
            request,
        );

        if (res.success && res.result) {
            dispatch({
                type: CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS_SUCCESS,
                status: res.result,
            });
            return;
        }
        dispatch({ type: CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS_FAIL });
    };
}
