import React from "react";

import { Tokens } from "@accurx/design";
import styled, { css, keyframes } from "styled-components";

// This file is a temporary copy of the styles we need from the SkeletonLoader in fleming-client's shared/components
// These styles should not be updated, since we want to be able to replace them with an import of a shared skeleton loader when one is ready

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

type SkeletonBlockProps = {
    height: React.CSSProperties["height"];
    width: React.CSSProperties["width"];
};

const baseSkeletonCss = css`
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;

    background-color: var(--base-color);
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    overflow: hidden;

    // z-index necessary for overflow: hidden to work correctly in Safari
    z-index: 1;

    // only enable the animation if user has not set a value for prefers-reduced-motion
    @media (prefers-reduced-motion: no-preference) {
        &::after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-image: linear-gradient(
                90deg,
                var(--base-color),
                var(--highlight-color),
                var(--base-color)
            );
            transform: translateX(-100%);

            display: block;
            animation-name: ${shimmer};
            animation-direction: var(--animation-direction);
            animation-duration: var(--animation-duration);
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
`;

export const SkeletonBlock = styled.div<SkeletonBlockProps>`
    ${baseSkeletonCss}
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;
