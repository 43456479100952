import {
    ConversationSummary,
    ConversationUpdate,
} from "@accurx/api/clinician-messaging";
import { mapConversationSummaryToConciergeUpdate } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationSummaryToConciergeUpdate";
import { mapConversationUpdateToConversationItem } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationUpdateToConversationItem";
import { ConciergeUpdates } from "domains/concierge/testing";
import { ConversationItem } from "domains/concierge/types";

export const mapConversationCommandResponseToConciergeUpdate = (dto: {
    summary: ConversationSummary;
    notificationUpdates: ConversationUpdate[];
}): ConciergeUpdates => {
    const updates = mapConversationSummaryToConciergeUpdate(dto.summary);

    const conversation = updates.conversations[0];

    if (!conversation) {
        return updates;
    }

    const items: ConversationItem[] = [];

    for (const update of dto.notificationUpdates) {
        const item = mapConversationUpdateToConversationItem(update);
        if (item) items.push(item);
    }

    return {
        ...updates,
        conversations: [
            {
                ...conversation,
                items,
            },
        ],
    };
};
