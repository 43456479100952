import { ComponentPropsWithoutRef } from "react";

import { SizeVariants } from "@accurx/design/dist/styles/types";
import {
    StyledContent,
    StyledDivider,
    StyledDividerContainer,
    StyledFooter,
    StyledSidenav,
    StyledSubtitle,
    StyledTitle,
} from "domains/navigation/components/Sidenav/Sidenav.styles";

export const SidenavDivider = ({
    spacing,
}: { spacing?: `${SizeVariants}` } & ComponentPropsWithoutRef<"div">) => {
    return (
        <StyledDividerContainer $spacing={spacing ?? "1"}>
            <StyledDivider />
        </StyledDividerContainer>
    );
};

StyledSidenav.displayName = "Sidenav";
StyledTitle.displayName = "Sidenav.Title";
StyledSubtitle.displayName = "Sidenav.Subtitle";
StyledContent.displayName = "Sidenav.Content";
StyledFooter.displayName = "Sidenav.Footer";
SidenavDivider.displayName = "Sidenav.Divider";

export const Sidenav = Object.assign(StyledSidenav, {
    Title: StyledTitle,
    Subtitle: StyledSubtitle,
    Content: StyledContent,
    Footer: StyledFooter,
    Divider: SidenavDivider,
});
