import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledQuickViewContainer = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};

    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);

    height: 100%;
`;

export const StyledQuickViewColumn = styled.section`
    height: 100%;
    width: 100%;

    position: relative;
`;
