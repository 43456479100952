import React, { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { getBatchMessageCsvDownloadUrl } from "@accurx/batch-messaging";
import {
    Button,
    ButtonLink,
    Ds,
    Feedback,
    Flex,
    Icon,
    Link,
    Text,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useHistory } from "react-router";
import { ModalHeader } from "reactstrap";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    getBatchMessageResponsesCsvDownloadUrl,
    statusLabel,
} from "app/batchMessage/gp/BatchMessage.types";
import { displayBatchType } from "app/batchMessage/gp/BatchMessage.utils";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ResponsiveModal } from "app/sharedComponents/ResponsiveModal/ResponsiveModal.styles";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES } from "shared/Routes";
import { NEXT_URL_QUERY_PARAM } from "shared/RoutesHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    BottomRowBatchDetails,
    CustomModalBody,
    FullRowBatchDetails,
    LastRowBatchDetails,
    MidRowBatchDetails,
    StyledFullWidthColumn,
    StyledPatientValidMobileColumnModel,
    TopRowBatchDetails,
} from "./BatchMessageDetailsModal.styles";

export type BatchMessageDetailsModalProps = {
    batchId: string;
    orgId: string;
    isOpen: boolean;
    onToggleModal: () => void;
};

const statusLabelColorPicker = (label: statusLabel) => {
    switch (label) {
        case statusLabel.Scheduled:
            return "blue";
        case statusLabel.Sending:
        case statusLabel.Saving:
            return "yellow";
        case statusLabel.Done:
        case statusLabel.Saved:
            return "green";
        default:
            return "greyscale";
    }
};

// Returns a modal notification dialog box when changing user state or role
export const BatchMessageDetailsModal = ({
    batchId,
    orgId,
    isOpen,
    onToggleModal,
}: BatchMessageDetailsModalProps): JSX.Element | null => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();

    // Feature flags
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );
    const excludeLikelyToFail = useIsFeatureEnabled(
        FeatureName.LikelyToFailMobilesAsInvalidInBatch,
    );

    // Store
    const fullName = useAppSelector(
        ({ batchMessage }) =>
            batchMessage.batchMessageModalDetails.sender?.fullName,
    );
    const {
        totalPatients,
        batchStatus,
        type,
        batchType,
        slotType,
        createdTime,
        delivered,
        failedToDeliver,
        bookedCount,
        staggerOverDays,
        nonTextablePatients,
        savingStatus,
        sendAt,
        failedToSave,
        messageTemplate,
        declineSettings,
        id,
        saveMessageToRecord,
        isFloreyCsvDownloadSupported,
    } = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageModalDetails,
    );
    const batchMessageDetailsLoading = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingBatchModalDetailsStatus,
    );
    const isTwoFactorAuthenticated = useAppSelector(
        ({ twoFactor }) => twoFactor.isAuthenticated,
    );

    const trackBatchCsvDownloaded = (
        csvType: "Delivered" | "FailedModal" | "MissingNumber",
    ): void => {
        ChainAnalyticsTracker.trackBatchOverviewMessageDownloadClick({
            ...analyticsLoggedInProps,
            batchOverviewDownloadType: csvType,
        });
    };

    useEffect(() => {
        if (isOpen && id === batchId) {
            const declineSettingsStatus = declineSettings?.snomedConceptId
                ? "Allowed"
                : "Not Allowed";
            ChainAnalyticsTracker.trackBatchOverviewMessageDetailClick({
                ...analyticsLoggedInProps,
                type,
                batchStatus,
                declineSettings:
                    type === "SELFBOOK" ? declineSettingsStatus : "N/A",
            });
        }
    }, [
        analyticsLoggedInProps,
        batchId,
        batchStatus,
        batchType,
        isOpen,
        declineSettings?.snomedConceptId,
        type,
        id,
    ]);

    const getTwoFactorUrl = (): string => {
        const redirectParam = new URLSearchParams();
        redirectParam.append(NEXT_URL_QUERY_PARAM, history.location.pathname);

        return ROUTES.two_factor_auth + "?" + redirectParam.toString();
    };

    const closeBtn = (
        <>
            <Text
                as="span"
                props={{
                    className: "sr-only",
                }}
            >
                Closing the modal
            </Text>
            <Button
                theme="transparent"
                text=""
                dimension="medium"
                icon={{ name: "Cross", style: "Line", colour: "metal" }}
                onClick={onToggleModal}
                tabIndex={0}
            />
        </>
    );

    // TODO: Rewrite with separated component
    const renderModalContent = () => {
        switch (batchMessageDetailsLoading) {
            case LoadingStatus.Loading:
                return (
                    <Flex justifyContent="center">
                        <Ds.Spinner />
                    </Flex>
                );
            case LoadingStatus.Failed:
                return (
                    <Text
                        variant="preview"
                        colour="red"
                        className="text-center pb-4 pt-2"
                    >
                        There was a problem loading this batch detail. Please
                        try again shortly
                    </Text>
                );
            case LoadingStatus.Loaded:
                return (
                    <CustomModalBody>
                        <TopRowBatchDetails>
                            <div>
                                <Text variant="label" skinny>
                                    Patient list
                                </Text>
                                <Text
                                    variant="subtitle"
                                    className="mt-0"
                                    skinny
                                >
                                    {totalPatients}
                                </Text>
                            </div>
                            <StyledPatientValidMobileColumnModel>
                                <Text variant="label" skinny>
                                    {excludeLikelyToFail
                                        ? "Not sent"
                                        : "Patients without a valid mobile number"}
                                </Text>
                                {nonTextablePatients ? (
                                    <Text
                                        variant="link"
                                        as="a"
                                        props={{
                                            href: getBatchMessageCsvDownloadUrl(
                                                {
                                                    workspaceId: orgId,
                                                    batchId,
                                                    include: "nonTextable",
                                                },
                                            ),
                                            "data-testid": "nonTextable",
                                            onClick: () => {
                                                trackBatchCsvDownloaded(
                                                    "MissingNumber",
                                                );
                                            },
                                        }}
                                    >
                                        <Icon
                                            colour="blue"
                                            name="Save"
                                            size={3}
                                            props={{
                                                className: "mr-1",
                                            }}
                                        />

                                        {nonTextablePatients}
                                    </Text>
                                ) : (
                                    <Text>0</Text>
                                )}
                            </StyledPatientValidMobileColumnModel>
                            <div>
                                <Text variant="label" skinny>
                                    Delivered
                                </Text>
                                {delivered ? (
                                    <Text
                                        variant="link"
                                        as="a"
                                        props={{
                                            href: getBatchMessageCsvDownloadUrl(
                                                {
                                                    workspaceId: orgId,
                                                    batchId,
                                                    include: "delivered",
                                                },
                                            ),
                                            "data-testid": "delivered",
                                            onClick: () => {
                                                trackBatchCsvDownloaded(
                                                    "Delivered",
                                                );
                                            },
                                        }}
                                    >
                                        <Icon
                                            colour="blue"
                                            name="Save"
                                            size={3}
                                            props={{
                                                className: "mr-1",
                                            }}
                                        />

                                        {delivered}
                                    </Text>
                                ) : (
                                    <Text>
                                        {batchStatus === "Scheduled"
                                            ? "N/A"
                                            : "0"}
                                    </Text>
                                )}
                            </div>
                            <div>
                                <Text variant="label" skinny>
                                    Failed to deliver
                                </Text>
                                {failedToDeliver ? (
                                    <Text
                                        variant="link"
                                        as="a"
                                        props={{
                                            href: getBatchMessageCsvDownloadUrl(
                                                {
                                                    workspaceId: orgId,
                                                    batchId,
                                                    include: "failed",
                                                },
                                            ),
                                            "data-testid": "failed",
                                            onClick: () => {
                                                trackBatchCsvDownloaded(
                                                    "FailedModal",
                                                );
                                            },
                                        }}
                                    >
                                        <Icon
                                            colour="blue"
                                            name="Save"
                                            size={3}
                                            props={{
                                                className: "mr-1",
                                            }}
                                        />

                                        {failedToDeliver}
                                    </Text>
                                ) : (
                                    <Text>
                                        {batchStatus === "Scheduled"
                                            ? "N/A"
                                            : failedToDeliver}
                                    </Text>
                                )}
                            </div>
                            <div>
                                <Text variant="label" skinny>
                                    Booked
                                </Text>
                                {bookedCount || "N/A"}
                            </div>
                            <StyledFullWidthColumn>
                                <Feedback
                                    colour="secondary"
                                    title="The delivered number doesn't count emails"
                                >
                                    <Text skinny>
                                        <Link
                                            href="https://support.accurx.com/en/articles/6867494-batch-messaging-how-to-use-guide#h_9ca721766b"
                                            openInNewTab
                                        >
                                            <Link.Text text="Read more about what delivered means" />
                                            <Link.Icon />
                                        </Link>
                                    </Text>
                                </Feedback>
                            </StyledFullWidthColumn>
                            {isFloreyCsvDownloadSupported && (
                                <div>
                                    <Text variant="label" skinny>
                                        Responses
                                    </Text>
                                    {isTwoFactorAuthenticated ? (
                                        <ButtonLink
                                            href={getBatchMessageResponsesCsvDownloadUrl(
                                                orgId,
                                                batchId,
                                            )}
                                            icon={{
                                                name: "Save",
                                                placement: "end",
                                            }}
                                            theme="secondary"
                                            dimension="medium"
                                            text="Download"
                                        />
                                    ) : (
                                        <>
                                            <Button
                                                icon={{
                                                    name: "Save",
                                                    placement: "end",
                                                }}
                                                theme="secondary"
                                                dimension="medium"
                                                disabled={true}
                                                text="Download"
                                            />
                                            <Text variant="note">
                                                To be able to download responses
                                                please confirm your identity
                                                with Two Factor Authentication.
                                            </Text>
                                            <Link
                                                href={getTwoFactorUrl()}
                                                text="Confirm identity"
                                                iconName="ArrowTail"
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </TopRowBatchDetails>
                        <MidRowBatchDetails>
                            <div>
                                <Text variant="label">Send time</Text>
                                <Text as="span">
                                    {`${DateHelpers.formatDate(
                                        sendAt,
                                        DateFormatOptions.TIME,
                                    )}`}
                                </Text>
                                <Text as="span" className="text-nowrap ml-2">
                                    {`${DateHelpers.formatDate(
                                        sendAt,
                                        DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                    )}`}
                                </Text>
                            </div>
                            <div>
                                <Text variant="label">Batch status</Text>
                                <Ds.Badge
                                    data-testid="statusLabel"
                                    color={statusLabelColorPicker(batchStatus)}
                                >
                                    {batchStatus}
                                </Ds.Badge>
                            </div>
                            <div>
                                <Text variant="label">Stagger over</Text>
                                <Text skinny>
                                    {staggerOverDays
                                        ? staggerOverDays + " days"
                                        : "N/A"}
                                </Text>
                            </div>
                            <div>
                                <Text variant="label">Sender</Text>
                                <Text skinny>{fullName}</Text>
                            </div>
                            {waitingListValidationEnabled === false && (
                                <div>
                                    <Text variant="label">Save to record</Text>
                                    {saveMessageToRecord === false && (
                                        <Text>Off</Text>
                                    )}
                                    {saveMessageToRecord !== false && (
                                        <>
                                            {savingStatus ? (
                                                <>
                                                    {batchStatus !==
                                                        "Scheduled" && (
                                                        <Ds.Badge
                                                            data-testid="statusLabel"
                                                            color={statusLabelColorPicker(
                                                                savingStatus,
                                                            )}
                                                        >
                                                            {savingStatus}
                                                        </Ds.Badge>
                                                    )}

                                                    <Text
                                                        as="span"
                                                        className="ml-1"
                                                    >
                                                        {failedToSave &&
                                                        batchStatus !==
                                                            "Scheduled"
                                                            ? `(${failedToSave}
                                                            failed to save)`
                                                            : batchStatus ===
                                                              "Scheduled"
                                                            ? "N/A"
                                                            : `(${failedToSave}
                                                            failed to save)`}
                                                    </Text>
                                                </>
                                            ) : (
                                                <Text>N/A</Text>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            <div>
                                <Text variant="label">
                                    Message created time
                                </Text>
                                <Text as="span">
                                    {`${DateHelpers.formatDate(
                                        createdTime,
                                        DateFormatOptions.TIME,
                                    )}`}
                                </Text>
                                <Text as="span" className="text-nowrap ml-2">
                                    {`${DateHelpers.formatDate(
                                        createdTime,
                                        DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                    )}`}
                                </Text>
                            </div>
                        </MidRowBatchDetails>
                        <BottomRowBatchDetails>
                            <div>
                                <Text variant="label">Batch type</Text>
                                <Text>{displayBatchType(type, batchType)}</Text>
                            </div>
                            {slotType && (
                                <div>
                                    <Text variant="label">Slot type</Text>
                                    <Text>{slotType}</Text>
                                </div>
                            )}
                        </BottomRowBatchDetails>
                        <FullRowBatchDetails>
                            <div>
                                <Text variant="label">
                                    {type === "SMS"
                                        ? "Message"
                                        : "Invite message"}
                                </Text>
                                <Text>{messageTemplate}</Text>
                            </div>
                        </FullRowBatchDetails>
                        <div>
                            {type === "SELFBOOK" && (
                                <LastRowBatchDetails>
                                    <div>
                                        <Text
                                            variant="label"
                                            props={{
                                                "data-testid": "decline-label",
                                            }}
                                        >
                                            Decline
                                        </Text>
                                        <Text
                                            props={{
                                                "data-testid":
                                                    "decline-setting-label",
                                            }}
                                        >
                                            {declineSettings !== null
                                                ? "Allowed"
                                                : "Not allowed"}
                                        </Text>
                                        {declineSettings !== null && (
                                            <div>
                                                <Text
                                                    variant="label"
                                                    props={{
                                                        "data-testid":
                                                            "decline-message-title",
                                                    }}
                                                >
                                                    Decline message
                                                </Text>
                                                <Text
                                                    props={{
                                                        "data-testid":
                                                            "decline-confirmation-message",
                                                    }}
                                                >
                                                    {
                                                        declineSettings.confirmationText
                                                    }
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                </LastRowBatchDetails>
                            )}
                        </div>
                    </CustomModalBody>
                );
        }
    };
    return (
        <ResponsiveModal
            isOpen={isOpen}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
            centered
            contentClassName="fullHeight"
            fullheight="true"
            data-testid="batch-details-modal"
        >
            <ModalHeader toggle={onToggleModal} close={closeBtn}>
                <Text variant="subtitle" skinny>
                    Details
                </Text>
            </ModalHeader>
            {renderModalContent()}
        </ResponsiveModal>
    );
};
