import React, { ReactNode } from "react";

import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { TwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer";

type AccountPageLayoutProps = {
    children: ReactNode;
    title: string;
};

const AccountPageLayout = ({ children, title }: AccountPageLayoutProps) => {
    return (
        <TwoThirdsContainer>
            <PageHeader type={PageHeaderType.ListPage} title={title} />
            {children}
        </TwoThirdsContainer>
    );
};

export { AccountPageLayout };
