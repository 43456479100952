import { createMutationHook } from "@accurx/api/QueryClient";
import { Patient, SearchForPatientResponse } from "@accurx/api/portal";

import FlemingApi from "api/FlemingApiClient";

/** The shape of data we receive when a search is successful */
export type SearchForPatientResponseSuccess = {
    searched: true;
    searchedResult: {
        matchFound: true;
        patient: Patient;
        patientToken: string;
    };
    searchedNhsNumber: string;
};

type SearchForPatientByNhsNumberQueryVariables = {
    workspaceId: string | number;
    nhsNumber: string;
    dateOfBirthYear: number;
    dateOfBirthMonth: number;
    dateOfBirthDay: number;
    isUserAction?: boolean;
};

const isSearchSuccess = (
    response: SearchForPatientResponse,
): response is SearchForPatientResponseSuccess => {
    return (
        response.searched === true && response.searchedResult?.patient !== null
    );
};

export const useSearchForPatientByNhsNumber = createMutationHook(() => ({
    mutationKey: ["SearchForPatientByNhsNumber"],
    mutationFn: async ({
        workspaceId,
        nhsNumber,
        dateOfBirthYear,
        dateOfBirthMonth,
        dateOfBirthDay,
        isUserAction,
    }: SearchForPatientByNhsNumberQueryVariables) => {
        const response = await FlemingApi.searchForPatientByNhsNumber(
            typeof workspaceId === "string"
                ? parseInt(workspaceId, 10)
                : workspaceId, // handle string vs number here
            nhsNumber,
            dateOfBirthYear,
            dateOfBirthMonth,
            dateOfBirthDay,
            isUserAction,
        );

        if (isSearchSuccess(response)) {
            return response;
        }

        throw new Error("No patient found");
    },
}));
