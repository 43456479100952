import {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    useCallback,
    useEffect,
    useState,
} from "react";

import { SearchForPatientByNhsNumberRequest } from "@accurx/api/portal";
import { FormFieldFeedback, Input } from "@accurx/design";
import { DateHelpers, NhsNumberHelpers } from "@accurx/shared";

import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { DateInput, DateInputValue } from "./DateInput";
import {
    StyledFormFieldWrapper,
    StyledFormWrapper,
    StyledSubmitButton,
} from "./SearchForPatientByNHSNumber.styles";
import { SearchForPatientFormIds, formFieldIds } from "./SearchFormConstants";

export type SearchFormData = {
    nhsNumber?: string;
    dobDay?: string;
    dobMonth?: string;
    dobYear?: string;
};

export type SubmitNhsNumberSearchForm = (
    request: SearchForPatientByNhsNumberRequest,
    resetForm?: () => void,
) => void;

export type SearchForPatientByNHSNumberProps = {
    onSubmit: SubmitNhsNumberSearchForm;
    initialFormData?: SearchFormData;
    isFormDisabled: boolean;
    serverError: string;
    resetServerError: () => void;
    /**
     * Unique identifier in case there are multiple forms on the same page
     */
    formId: SearchForPatientFormIds;
    hasCustomSubmitButton?: boolean;
    hasColumnLayout?: boolean;
    onFormValidationError?: () => void;
};

export const SearchForPatientByNHSNumber = ({
    onSubmit,
    onFormValidationError,
    initialFormData,
    serverError,
    resetServerError,
    isFormDisabled,
    formId,
    hasCustomSubmitButton = false,
    hasColumnLayout = false,
}: SearchForPatientByNHSNumberProps): JSX.Element => {
    const selectedOrganisation = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const [nhsNumber, setNhsNumber] = useState("");
    const [nhsNumberErrorMessage, setNhsNumberErrorMessage] = useState("");

    const [dobDay, setDobDay] = useState("");
    const [dobMonth, setDobMonth] = useState("");
    const [dobYear, setDobYear] = useState("");
    const [dobErrorMessage, setDobErrorMessage] = useState("");

    // Disable only form fields, not the submit button (i.e. for test patient prefill)
    const [formFieldsDisabled, setFormFieldsDisabled] = useState(false);

    const handleResetServerError = useCallback(() => {
        if (serverError) {
            resetServerError();
        }
    }, [serverError, resetServerError]);

    useEffect(() => {
        const prefilledNhsNumber = initialFormData?.nhsNumber || "";
        const prefilledDobDay = initialFormData?.dobDay || "";
        const prefilledDobMonth = initialFormData?.dobMonth || "";
        const prefilledDobYear = initialFormData?.dobYear || "";

        const isDemoPatient =
            prefilledNhsNumber !== "" &&
            PatientHelper.isDemoPatient(prefilledNhsNumber);
        const isPrefilledDateBlank =
            prefilledDobDay + prefilledDobMonth + prefilledDobYear === "";

        // Set all form fields disabled if we are prefilling form with test patient
        // But not if DOB is left blank! (i.e. when deeplinking with patientToken url string)
        setFormFieldsDisabled(isDemoPatient && !isPrefilledDateBlank);

        setNhsNumber(prefilledNhsNumber);
        setDobDay(prefilledDobDay);
        setDobMonth(prefilledDobMonth);
        setDobYear(prefilledDobYear);
        setNhsNumberErrorMessage("");
        setDobErrorMessage("");
    }, [initialFormData]);

    const resetFormState = (): void => {
        setNhsNumber("");
        setDobDay("");
        setDobMonth("");
        setDobYear("");
        setFormFieldsDisabled(false);
    };

    const handleNhsNumberChange = (e: ChangeEvent<HTMLInputElement>): void =>
        sanitiseInputStringAndSetState(e.target.value);
    const handleNhsNumberPaste = (
        e: ClipboardEvent<HTMLInputElement>,
    ): void => {
        e.preventDefault();
        const text = e.clipboardData?.getData("Text") || "";
        sanitiseInputStringAndSetState(text);
    };
    const sanitiseInputStringAndSetState = (inputString: string): void => {
        const cleanedString = inputString.replace(/[^0-9]/g, "");
        setNhsNumber(cleanedString);
        setNhsNumberErrorMessage("");
        handleResetServerError();
    };

    const handleDateChange = (value: DateInputValue) => {
        setDobDay(value.day);
        setDobMonth(value.month);
        setDobYear(value.year);
        setDobErrorMessage("");
        handleResetServerError();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const dateValidation = DateHelpers.validateDob(
            dobDay,
            dobMonth,
            dobYear,
        ); // Need to call both upfront to render feedback on both fields
        const nhsNumberValidation =
            NhsNumberHelpers.validateNhsNumber(nhsNumber);

        setDobErrorMessage(dateValidation.error || "");
        setNhsNumberErrorMessage(nhsNumberValidation.error || "");

        if (
            dateValidation.success &&
            nhsNumberValidation.success &&
            selectedOrganisation
        ) {
            const request: SearchForPatientByNhsNumberRequest = {
                nhsNumber,
                dateOfBirthYear: parseInt(dobYear, 10),
                dateOfBirthMonth: parseInt(dobMonth, 10),
                dateOfBirthDay: parseInt(dobDay, 10),
                organisationId: selectedOrganisation,
            };

            onSubmit(request, resetFormState);
        } else if (onFormValidationError) {
            onFormValidationError();
        }
    };

    const isBlankForm = nhsNumber + dobDay + dobMonth + dobYear === "";

    const nhsNumberId = `${formId}-${formFieldIds.nhsNumber}`;

    return (
        <form id={formId} onSubmit={handleSubmit} aria-label={formId}>
            <StyledFormWrapper hasColumnLayout={hasColumnLayout}>
                <StyledFormFieldWrapper
                    label="NHS number"
                    subLabel="Paste or type here • Example, 111 222 9999"
                    labelProps={{
                        htmlFor: nhsNumberId,
                        skinny: true,
                    }}
                    errors={[nhsNumberErrorMessage]}
                >
                    <Input
                        id={nhsNumberId}
                        placeholder="xxxxxxxxxx"
                        value={nhsNumber}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        minLength={10}
                        maxLength={10}
                        onChange={handleNhsNumberChange}
                        onPaste={handleNhsNumberPaste}
                        disabled={isFormDisabled || formFieldsDisabled}
                        required
                        data-userflow-id="patient-search-nhs-number-input"
                    />
                </StyledFormFieldWrapper>
                <DateInput
                    value={{
                        day: dobDay,
                        month: dobMonth,
                        year: dobYear,
                    }}
                    error={dobErrorMessage}
                    label="Date of birth"
                    subLabel="Paste or type here • Example, 24 2 1987"
                    onChange={handleDateChange}
                    required
                    disabled={isFormDisabled || formFieldsDisabled}
                />
                {!hasCustomSubmitButton && (
                    <StyledSubmitButton
                        type="submit"
                        aria-label="Search for a patient"
                        icon={{
                            name: "Search",
                            title: "Search for a patient",
                            id: "search-btn",
                        }}
                        disabled={isFormDisabled || isBlankForm}
                        data-userflow-id="search-patient-button"
                    />
                )}
            </StyledFormWrapper>
            {serverError !== "" && (
                <FormFieldFeedback variant="error" text={serverError} />
            )}
        </form>
    );
};
