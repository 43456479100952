import { DateHelpers } from "@accurx/shared";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";

import { UNSELECTED_FILTER_VALUE } from "./components/Filters";

export type FiltersValues = {
    clinicCode: string;
    responsibleClinician: string;
    location: string;
    date: string;
};

export type FilterKey = keyof FiltersValues;

export default function getFilteredClinics(
    clinics: IntegratedClinic[],
    filters: FiltersValues,
): IntegratedClinic[] {
    const hasFiltersSelected = Object.values(filters).some(
        (filterValue) => filterValue !== UNSELECTED_FILTER_VALUE,
    );

    if (!hasFiltersSelected) {
        return clinics;
    }

    return clinics.filter((clinic) => clinicMatchesAllFilters(clinic, filters));
}

function clinicMatchesAllFilters(
    clinic: IntegratedClinic,
    filters: FiltersValues,
) {
    if (
        filters.clinicCode !== UNSELECTED_FILTER_VALUE &&
        filters.clinicCode !== clinic.clinicCode
    ) {
        return false;
    }

    if (
        filters.responsibleClinician !== UNSELECTED_FILTER_VALUE &&
        Array.isArray(clinic.responsibleClinicians) &&
        !clinic.responsibleClinicians.includes(filters.responsibleClinician)
    ) {
        return false;
    }

    if (
        filters.location !== UNSELECTED_FILTER_VALUE &&
        filters.location !== clinic.location
    ) {
        return false;
    }

    if (
        filters.date !== UNSELECTED_FILTER_VALUE &&
        !DateHelpers.isSameDate(filters.date, clinic.clinicDate ?? "")
    ) {
        return false;
    }

    return true;
}
