import React from "react";

import { useParams } from "react-router-dom";

import { PatientList } from "./PatientList";

const PatientListPage = (): JSX.Element => {
    const { patientListId } = useParams<{ patientListId: string }>();

    return <PatientList key={patientListId} urlPatientListId={patientListId} />;
};

export { PatientListPage };
export default PatientListPage;
