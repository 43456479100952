import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledTruncatedText = styled(UI.Text)`
    min-width: 0%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledOneLineBadge = styled(UI.Ds.Badge)`
    display: block;
    white-space: nowrap;
`;
