import React, { ChangeEvent, useEffect, useState } from "react";

import {
    ButtonLink,
    Feedback,
    FormFieldWrapper,
    Input,
    Link,
    Spinner,
    Text,
} from "@accurx/design";
import { EmailAddressHelper } from "@accurx/shared";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { getMagicValidateTokens, postMagicResendEmail } from "api/AccountApi";
import { ROUTES } from "shared/Routes";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { StyledFullWidthButton } from "../SharedStyledComponents.styles";

enum PAGE_STATES {
    INITIAL,
    VALID,
    INVALID,
}

export const MagicLink = (): JSX.Element => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const email = encodeURIComponent(params.get("email") || ""); // Api expect an encoded token
    const token = encodeURIComponent(params.get("token") || ""); // Api expect an encoded token

    const [currentState, setCurrentState] = useState(PAGE_STATES.INITIAL);
    const [desktopUrl, setDesktopUrl] = useState("");
    const [emailInput, setEmailInput] = useState(""); // Fallback if no email params detected
    const [emailError, setEmailError] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState("");

    useEffect(() => {
        // Redirect users that are missing query params
        if (!email || !token) {
            setCurrentState(PAGE_STATES.INVALID);
            return;
        }

        // Display invalid state if the token or email are invalid
        const validateMagicLinkTokens = async () => {
            const res = await getMagicValidateTokens(email, token);
            if (
                !res.success ||
                !res.result ||
                !res.result.tokenValid ||
                !res.result.launchUrl
            ) {
                setCurrentState(PAGE_STATES.INVALID);
                return;
            }

            setCurrentState(PAGE_STATES.VALID);
            setDesktopUrl(res.result.launchUrl);
        };

        validateMagicLinkTokens();
    }, [email, token]);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value);
    };

    const handleSendMagicLink = async () => {
        setEmailError("");
        const emailParams = emailInput || decodeURIComponent(email);
        const emailErr = EmailAddressHelper.isValidEmailAddress(emailParams);
        if (!emailErr) {
            setEmailError("Please enter a valid email address and try again.");
            return;
        }

        const res = await postMagicResendEmail(emailParams);
        if (!res.success) {
            setFeedbackMsg(
                res.error || "Something went wrong. Please try again.",
            );
            return;
        }

        history.push(`${ROUTES.checkYourInbox}`, { email: emailParams });
    };

    const getContentToRender = () => {
        switch (currentState) {
            case PAGE_STATES.INITIAL:
                return <Spinner />;
            case PAGE_STATES.INVALID:
                return (
                    <>
                        <Text variant="title" as="h1" skinny>
                            Invalid magic link
                        </Text>
                        <InnerStackPanel horizontalContentAlignment="stretch">
                            <Text skinny>
                                Magic links expire after 24 hours and can only
                                be used once.
                            </Text>
                            {(!email || emailError) && (
                                <FormFieldWrapper
                                    label="Email"
                                    labelProps={{ htmlFor: "user-email" }}
                                    errors={[emailError]}
                                >
                                    <Input
                                        id="user-email"
                                        value={emailInput}
                                        type="email"
                                        placeholder={"Email address"}
                                        onChange={handleEmailChange}
                                        hasErrors={!!emailError}
                                        required
                                        autoFocus
                                    />
                                </FormFieldWrapper>
                            )}
                            <StyledFullWidthButton
                                type="button"
                                onClick={handleSendMagicLink}
                                text="Send a new magic link"
                            />
                            {feedbackMsg && (
                                <Feedback title={feedbackMsg} colour="error" />
                            )}
                        </InnerStackPanel>
                    </>
                );
            default:
                return (
                    <>
                        <Text variant="title" as="h1" skinny>
                            Email verified
                        </Text>
                        <InnerStackPanel horizontalContentAlignment="stretch">
                            <Text skinny>
                                You can now log in to the toolbar with your new
                                account.
                            </Text>
                            <StyledCenteredButtonLink
                                href={desktopUrl}
                                text="Log in to Accurx Desktop toolbar"
                            />
                            <Text variant="label">
                                {
                                    "Haven't installed the Accurx Desktop toolbar? "
                                }
                                <Link
                                    href={ROUTES.downloadDesktop}
                                    text="Install now"
                                />
                            </Text>
                        </InnerStackPanel>
                    </>
                );
        }
    };

    return <OuterStackPanel>{getContentToRender()}</OuterStackPanel>;
};

const StyledCenteredButtonLink = styled(ButtonLink)`
    > span {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    width: 100%;
`;
