import { Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
    IsCollapsed,
    transitionStyles,
    truncatedTextStyles,
} from "../NavigationMenu.styles";
import { ActiveState, LinkVariant } from "./NavLink.types";

type StyledNavLinkProps = {
    activestate: ActiveState;
    variant: LinkVariant;
};

type StyledPrimaryNavLinkProps = {
    activestate: ActiveState;
    variant: LinkVariant.Primary;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
};

type StyledSecondaryNavLinkProps = {
    activestate: ActiveState;
    variant: LinkVariant.Secondary | LinkVariant.Tertiary;
};

const COLOURS = {
    Primary: {
        Active: {
            default: {
                border: Tokens.COLOURS.greyscale.white,
                background: Tokens.COLOURS.greyscale.white,
            },
            hover: {
                border: Tokens.COLOURS.primary.green[50],
                background: Tokens.COLOURS.greyscale.white,
            },
        },
        Inactive: {
            default: {
                border: Tokens.COLOURS.primary.green[130],
                background: Tokens.COLOURS.primary.green[100],
            },
            hover: {
                border: Tokens.COLOURS.primary.green[50],
                background: Tokens.COLOURS.primary.green[75],
            },
        },
    },
    Secondary: {
        Active: {
            default: {
                border: Tokens.COLOURS.greyscale.zinc,
                background: Tokens.COLOURS.greyscale.zinc,
            },
            hover: {
                border: Tokens.COLOURS.greyscale.stone,
                background: Tokens.COLOURS.greyscale.night,
            },
        },
        Inactive: {
            default: {
                border: Tokens.COLOURS.greyscale.dishwater,
                background: Tokens.COLOURS.greyscale.dishwater,
            },
            hover: {
                border: Tokens.COLOURS.greyscale.stone,
                background: Tokens.COLOURS.greyscale.silver,
            },
        },
    },
    Tertiary: {
        Active: {
            default: {
                border: Tokens.COLOURS.greyscale.zinc,
                background: Tokens.COLOURS.greyscale.zinc,
            },
            hover: {
                border: Tokens.COLOURS.greyscale.stone,
                background: Tokens.COLOURS.greyscale.night,
            },
        },
        Inactive: {
            default: {
                border: Tokens.COLOURS.greyscale.dishwater,
                background: Tokens.COLOURS.greyscale.dishwater,
            },
            hover: {
                border: Tokens.COLOURS.greyscale.stone,
                background: Tokens.COLOURS.greyscale.silver,
            },
        },
    },
};

const StyledIconWrapper = styled.div`
    flex-shrink: 0;
`;

const NavLinkSharedStyles = css<StyledNavLinkProps>`
    color: inherit;
    margin: 0;

    display: flex;
    align-items: center;

    background-color: ${(props) =>
        COLOURS[props.variant][props.activestate].default.background};
    border-style: solid;
    border-width: ${Tokens.SIZES[0.25]};
    border-color: ${(props) =>
        COLOURS[props.variant][props.activestate].default.border};

    transition-property: background-color, border;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;

    &:hover,
    &:focus-visible {
        color: inherit;
        text-decoration: none;
        background: ${(props) =>
            COLOURS[props.variant][props.activestate].hover.background};
        border-color: ${(props) =>
            COLOURS[props.variant][props.activestate].hover.border};
    }

    &:focus,
    &:focus-visible {
        // This is needed to remove the outline shown when a link is in focus.
        // We don't want this outline as we are applying our own focus styles.
        box-shadow: none !important;
    }
`;

/**
 * These styles are also shared by the popover button that sit in the primary nav
 */
const PrimaryNavLinkSharedStyles = css`
    ${NavLinkSharedStyles}

    position: relative;
    padding: ${Tokens.SIZES[1]};
`;

const StyledPrimaryNavLink = styled(Link)<StyledPrimaryNavLinkProps>`
    ${PrimaryNavLinkSharedStyles}

    border-radius: ${Tokens.SIZES[1]};
`;

const StyledPrimaryNavLinkBadge = styled.span`
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 1;

    > span {
        display: block;
        position: initial;
    }
`;

const StyledPrimaryNavLinkText = styled.span<{
    isCollapsed?: IsCollapsed;
}>`
    ${truncatedTextStyles}
    ${transitionStyles}
`;

const StyledSecondaryNavLink = styled(Link)<StyledSecondaryNavLinkProps>`
    ${NavLinkSharedStyles}

    position: relative;

    justify-content: space-between;

    padding: ${Tokens.SIZES[0.25]} ${Tokens.SIZES[1]};
    border-radius: ${Tokens.SIZES[0.5]};

    ${StyledIconWrapper} {
        margin: ${Tokens.SIZES[0.25]};
    }
`;

const StyledSecondaryLinkContentWrapper = styled.span`
    display: flex;
    align-items: center;
    gap: ${Tokens.SIZES[0.5]};

    overflow: hidden;
`;

const StyledSecondaryNavLinkText = styled.span`
    flex: 1;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

/** Similar to nav links, but it's got more rounded corners */
const StyledButton = styled.button<StyledNavLinkProps>`
    ${PrimaryNavLinkSharedStyles}
    border-radius: 99px;
    width: 100%;
`;

/**
 * Looks exactly like the primary nav links
 * Currently in use by marketing team to manage onboarding
 * and other such campaigns
 */
const StyledPrimaryNavButton = styled.button`
    ${PrimaryNavLinkSharedStyles}
    font-weight: inherit;
    border-radius: ${Tokens.SIZES[1]};
`;

export {
    PrimaryNavLinkSharedStyles,
    StyledIconWrapper,
    StyledPrimaryNavLink,
    StyledPrimaryNavLinkText,
    StyledPrimaryNavLinkBadge,
    StyledSecondaryNavLink,
    StyledSecondaryNavLinkText,
    StyledSecondaryLinkContentWrapper,
    StyledButton,
    StyledPrimaryNavButton,
};

export type { StyledNavLinkProps };
