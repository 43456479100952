import { Button, Flex, Text } from "@accurx/design";
import { useHistory } from "react-router";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsTwoFactorAuthenticated } from "app/twoFactorAuth/TwoFactorReducer";
import { getTwoFactorRouteWithNext } from "shared/RoutesHelper";

export const TwoFactorGate = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const history = useHistory();
    const is2FAed = useIsTwoFactorAuthenticated();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleTwoFactor = () => {
        FlemingAnalyticsTracker.trackLogIntoTwoFactorForRecentSearches(
            analyticsLoggedInProps,
        );

        history.push(getTwoFactorRouteWithNext(history.location.pathname));
    };

    if (is2FAed) {
        return <>{children}</>;
    }

    return (
        <Flex flexDirection="column" alignItems="flex-start" gap="1">
            <Text skinny>
                Set-up secure access to view recently searched patients
            </Text>
            <Button text="Get started" onClick={handleTwoFactor} />
        </Flex>
    );
};
