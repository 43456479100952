import React from "react";

import * as UI from "@accurx/design";
import { Pill, Tooltip } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/compose/userflow";

import { StyledAttachDocumentButton } from "./AttachDocumentButton.styles";

export type AttachDocumentButtonProps = {
    toggle: () => void;
    iconOnly?: boolean;
};

export type Ref = HTMLButtonElement;

export const AttachDocumentButton = React.forwardRef<
    Ref,
    AttachDocumentButtonProps
>(({ toggle, iconOnly = false }, ref) => {
    return (
        <Tooltip.Root>
            <Tooltip.Trigger tabIndex={-1} asChild={true}>
                <StyledAttachDocumentButton
                    radius="full"
                    onClick={toggle}
                    ref={ref}
                    aria-label="Attach files"
                    data-userflow-id={userflowIds.attachButton}
                    showIconOnly={iconOnly}
                >
                    <Pill.Icon
                        name="Paperclip"
                        colour="blue"
                        theme="Line"
                        props={{
                            "aria-hidden": true,
                        }}
                    />
                    {!iconOnly && (
                        <UI.Text as="span" skinny variant="preview">
                            Attach
                        </UI.Text>
                    )}
                </StyledAttachDocumentButton>
            </Tooltip.Trigger>
            {iconOnly && (
                <Tooltip.Content>
                    <UI.Text colour="white" variant="note" skinny>
                        Attach
                    </UI.Text>
                </Tooltip.Content>
            )}
        </Tooltip.Root>
    );
});
