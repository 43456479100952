import { Action, Reducer } from "redux";

import { Actions } from "./AppActions";
import type { AppViewState } from "./AppState.types";

export const initialState: AppViewState = { isEmbedded: false };

export const reducer: Reducer<AppViewState> = (
    state = initialState,
    action: Action,
): AppViewState => {
    switch (action.type) {
        case Actions.SET_EMBEDDED_MODE:
            return { isEmbedded: true };
    }

    return state;
};
