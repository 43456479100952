import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledTriggerButton = styled(UI.Button)`
    // Increase the button's clickable area
    &:before {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        top: -4px;
        left: -4px;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: ${UI.Tokens.COLOURS.primary.blue[5]};
    }

    &:hover {
        box-shadow: 0px 0px 0px 1px ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    &:focus,
    &:focus:not(:focus-visible),
    &:active {
        box-shadow: 0px 0px 0px 1px ${UI.Tokens.COLOURS.greyscale.zinc};

        outline-style: solid;
        outline-width: 3px;
        outline-color: ${UI.Tokens.COLOURS.primary.blue[50]};
    }
`;
