import {
    ClinicAppointment,
    ClinicAppointmentPDSMatched,
} from "api/Appointment/IntegratedClinics";

export function isContactable(
    appointment: ClinicAppointment,
): appointment is ClinicAppointmentPDSMatched {
    return appointment.patientLookupResult === "Success";
}
