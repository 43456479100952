import React from "react";

import * as UI from "@accurx/design";
import styled from "styled-components";

const Container = styled.span`
    color: ${UI.Tokens.COLOURS.greyscale.metal};
    flex: 0 0 auto;
    overflow-wrap: break-word;
    max-width: 100%;
`;

const Detail = styled.b`
    color: ${UI.Tokens.COLOURS.greyscale.metal};
`;

export const SentToDetail = ({ children }: { children: React.ReactNode }) => {
    if (!children) {
        return null;
    }
    return (
        <Container>
            To: <Detail>{children}</Detail>
        </Container>
    );
};
