import * as UI from "@accurx/design";
import styled, { keyframes } from "styled-components";

const markAsDone = keyframes`
  0% {
    background: transparent;
    opacity: 0;
  }
  100% {
    opacity: 1;
    background: ${UI.Tokens.COLOURS.primary.green[25]};
  }
`;

export const StyledContainer = styled.div`
    position: absolute;
    z-index: 1;

    // spread out by 1px to account for the 1px outer border of the container
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
`;

export const StyledOverlay = styled(UI.Flex)`
    height: 100%;
    border: 1px solid ${UI.Tokens.COLOURS.primary.green[100]};
    border-radius: ${UI.Tokens.BORDERS.radius};
    background: ${UI.Tokens.COLOURS.primary.green[25]};

    @media (prefers-reduced-motion: no-preference) {
        animation: ${markAsDone} 0.25s ease-in forwards;
    }
`;
