import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

export const FragmentsPage = () => {
    return (
        <StyledContainer>
            <PageHeader title="Fragments" type={PageHeaderType.ListPage} />
        </StyledContainer>
    );
};
