import { shallowEqual } from "react-redux";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { useAppSelector } from "store/hooks";

// N.B. Using a shallowEqual here so that when this is used in components, it doesn't
// cause unnecessary re-renders since the returned value from the selector is an object
// so we need to do a deeper equality check
export const useFlemingLoggedInAnalytics =
    (): FlemingAnalyticsTracker.FlemingLoggedInCustomProperties =>
        useAppSelector(
            (state) =>
                AnalyticsMapper.getAnalyticsLoggedInProps(
                    state.account,
                    state.sessionAnalytics,
                ),
            shallowEqual,
        );
