import { Tokens } from "@accurx/design";
import { Modal } from "reactstrap";
import styled from "styled-components";

const MODAL_HEIGHT = 556;
export const ResponsiveModal = styled(Modal)<{
    isOpen: boolean;
    toggle: () => void;
    fade: boolean;
    backdropClassName: string;
    fullheight?: "true";
}>`
    max-width: 100vw;
    width: 100%;
    margin: 0 auto;

    .fullHeight {
        min-height: 100vh;
    }

    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: calc(${Tokens.BREAKPOINTS.md} - ${Tokens.SIZES[3]});
        .fullHeight {
            min-height: ${(p) => p.fullheight && MODAL_HEIGHT}px;
        }
    }
`;
