import { PatientThreadInitialUnreadItems } from "@accurx/api/ticket";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { isInstance } from "domains/concierge/internal/util/isInstance";

import * as TicketApiClient from "../TicketApiClient";
import { mapPatientThreadUserToUserSummary } from "../mappers/mapPatientThreadUserToUserSummary";
import { mapTicketUnreadItemUpdates } from "../mappers/mapTicketUnreadItemUpdates";

export const getInitialUnreadItems: ConciergeFetcher<
    { workspaceId: number },
    PatientThreadInitialUnreadItems
> = async (arg) => {
    const response = await TicketApiClient.fetchInitialUnreadItems(
        arg.workspaceId,
    );

    const conversations = mapTicketUnreadItemUpdates(response);

    const users = (response.referencedNoteUsers ?? [])
        .map(mapPatientThreadUserToUserSummary)
        .filter(isInstance);

    const teams = (response.referencedNoteUserGroups ?? [])
        .map(mapPatientThreadUserGroupToTeamSummary)
        .filter(isInstance);

    return {
        updates: {
            conversations,
            patients: [], // we don't receive full patients when fetching unreads so we ignore
            users,
            teams,
        },
        response,
    };
};
