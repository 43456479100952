import {
    GenericNoteTheme as ApiGenericNoteTheme,
    PatientThreadGenericNote,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { SelfBookItem } from "domains/concierge/types";

/**
 * Maps ticket Generic Note item to conversation Self Book item
 *
 * @param ticketSelfBookNoteItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapSelfBookNoteItem(
    ticketSelfBookNoteItem: PatientThreadGenericNote,
): SelfBookItem | undefined {
    if (!ticketSelfBookNoteItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketSelfBookNoteItem,
    );

    if (!ticketSelfBookNoteItem.message.markdown) {
        throw new Error("Cannot map ticket item as prop markdown is invalid");
    }

    let theme = mapSelfBookNoteTheme(ticketSelfBookNoteItem.message.theme);
    if (!theme) {
        Log.error("Could not map to a known theme, defaulting to Information", {
            tags: {
                product: "Inbox",
                itemId: ticketSelfBookNoteItem.id,
                itemType: ticketSelfBookNoteItem.type,
            },
        });
        theme = "Information";
    }

    if (!ticketSelfBookNoteItem.message.displaySender) {
        Log.warn("Sender is unknown", {
            tags: {
                product: "Inbox",
                itemId: ticketSelfBookNoteItem.id,
                itemType: ticketSelfBookNoteItem.type,
            },
        });
    }

    const senderName = mapSelfBookNoteDisplaySender(
        ticketSelfBookNoteItem.message.displaySender,
    );

    const createdBy: SelfBookItem["createdBy"] =
        senderName === "Accurx"
            ? { type: "System" }
            : {
                  type: "Patient",
                  id: ticketSelfBookNoteItem.patientAccuRxId ?? undefined,
              };

    return {
        ...baseConversationItem,
        contentType: "SelfBookNote",
        createdBy,
        markdown: ticketSelfBookNoteItem.message.markdown,
        theme,
        fallbackSenderDisplayName: senderName,
    };
}

function mapSelfBookNoteTheme(
    apiTheme?: Optional<ApiGenericNoteTheme>,
): SelfBookItem["theme"] | undefined {
    switch (apiTheme) {
        case ApiGenericNoteTheme.Alarm:
            return "Alarm";
        case ApiGenericNoteTheme.Information:
            return "Information";
        case ApiGenericNoteTheme.Warning:
            return "Warning";
        default:
            return;
    }
}

function mapSelfBookNoteDisplaySender(
    displaySender?: Optional<string>,
): string {
    if (!displaySender) return "Unknown sender";

    if (displaySender.toLowerCase() === "accurx messaging service")
        return "Accurx";

    return displaySender;
}
