import { useRef } from "react";

import { Conversation, ConversationItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useIsEllipsisActive } from "domains/inbox/hooks/util/useIsEllipsisActive";

import { StyledBadge, StyledBadgeText } from "./StatusBadge.styles";

const sharedBadgeProps = {
    radius: "round",
    className: "conversation-preview-badge",
} as const;

export type StatusBadgeProps = {
    status: Conversation["status"];
    conversationItem: ConversationItem;
};

export const StatusBadge = ({ status, conversationItem }: StatusBadgeProps) => {
    const textRef = useRef<HTMLElement>(null);
    const isEllipsisActive = useIsEllipsisActive(textRef.current);

    const contentType = conversationItem.contentType;
    const deliveryStatus =
        "deliveryStatus" in conversationItem
            ? conversationItem.deliveryStatus
            : undefined;

    if (status === "Done") {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    done
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Done"}
                        </UI.Text>
                    }
                    props={{
                        root: { $withEllipsis: true },
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="green"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <UI.Icon name="Success" theme="Fill" />
                        <StyledBadgeText ref={textRef}>
                            {"Done"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (
        contentType === "FailedDeliveryReceiptLink" ||
        deliveryStatus === "Failed"
    ) {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    failed to deliver
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Failed"}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="red"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <UI.Icon name="Warning" theme="Fill" />
                        <StyledBadgeText ref={textRef}>
                            {"Failed"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (
        contentType === "PatientSingleResponse" &&
        conversationItem.sections.length === 0
    ) {
        return (
            <>
                <UI.VisuallyHidden as="span">
                    patient did not respond
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {"Did not respond"}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color="yellow"
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <UI.Icon name="Warning" theme="Fill" />
                        <StyledBadgeText ref={textRef}>
                            {"Did not respond"}
                        </StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (contentType === "SelfBookNote") {
        const isBooked =
            conversationItem.markdown.includes("Patient has booked");
        const text = isBooked ? "Booked" : "Did not book";

        return (
            <>
                <UI.VisuallyHidden as="span">
                    {isBooked
                        ? "self-book appointment booked"
                        : "did not book self-book appointment"}
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {text}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color={isBooked ? "green" : "yellow"}
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <UI.Icon
                            name={isBooked ? "Success" : "Warning"}
                            theme="Fill"
                        />
                        <StyledBadgeText ref={textRef}>{text}</StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    if (contentType === "FloreyResponseNote") {
        const floreyResponses = conversationItem.sections[0]?.responses ?? [];
        const isSubmitted = floreyResponses.length > 0;
        const text = isSubmitted ? "Submitted" : "Did not complete";

        return (
            <>
                <UI.VisuallyHidden as="span">
                    {isSubmitted
                        ? "florey submitted"
                        : "did not complete florey"}
                    {", "}
                </UI.VisuallyHidden>
                <WithTooltip
                    isEnabled={isEllipsisActive}
                    content={
                        <UI.Text skinny variant="note" colour="white">
                            {text}
                        </UI.Text>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                        },
                    }}
                >
                    <StyledBadge
                        color={isSubmitted ? "green" : "yellow"}
                        aria-hidden="true"
                        {...sharedBadgeProps}
                    >
                        <UI.Icon
                            name={isSubmitted ? "Success" : "Warning"}
                            theme="Fill"
                        />
                        <StyledBadgeText ref={textRef}>{text}</StyledBadgeText>
                    </StyledBadge>
                </WithTooltip>
            </>
        );
    }

    return null;
};
