import { CourseSearchTerm, VaccineCourse } from "../models/VaccineDeliveryDTO";

export const getVaccineCourseSearchTerm = (
    course: string,
): CourseSearchTerm => {
    switch (course) {
        case VaccineCourse.Booster:
            return CourseSearchTerm.Booster;
        case VaccineCourse.BoosterSpring2022:
            return CourseSearchTerm.BoosterSpring2022;
        case VaccineCourse.BoosterAutumn2022:
            return CourseSearchTerm.BoosterAutumn2022;
        case VaccineCourse.BoosterSpring2023:
            return CourseSearchTerm.BoosterSpring2023;
        case VaccineCourse.BoosterAutumn2023:
            return CourseSearchTerm.BoosterAutumn2023;
        case VaccineCourse.BoosterSpring2024:
            return CourseSearchTerm.BoosterSpring2024;
        case VaccineCourse.BoosterAutumn2024:
            return CourseSearchTerm.BoosterAutumn2024;
        default:
            return CourseSearchTerm.Primary;
    }
};
