import React, { ReactElement, useState } from "react";

import { Icon, Text } from "@accurx/design";

import {
    LinkButton,
    StyledTooltip,
} from "./MoreInformationTooltipLinkComponents.style";

type MoreInformationTooltipLinkProps = {
    children: ReactElement[] | ReactElement;
    text: string;
    id: string;
};

const MoreInformationTooltipLink = ({
    id,
    text,
    children,
}: MoreInformationTooltipLinkProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const clickHandler = () => {
        if (!isOpen) {
            toggle();
        }
    };

    return (
        <>
            <div className="d-flex align-items-center ml-2 my-1">
                <LinkButton id={id} onClick={clickHandler}>
                    <Icon colour="zinc" name="Info" size={3} />
                    <Text
                        skinny
                        as="u"
                        colour="zinc"
                        props={{ style: { cursor: "default" } }}
                    >
                        {text}
                    </Text>
                </LinkButton>
            </div>
            <StyledTooltip
                autohide={false}
                delay={{ show: 0, hide: 50 }}
                className="selectable-tooltip"
                placement="bottom"
                target={id}
                isOpen={isOpen}
                toggle={toggle}
                trigger="hover"
            >
                {children}
            </StyledTooltip>
        </>
    );
};

export default MoreInformationTooltipLink;
