import { Button, Flex, FormFieldFeedback } from "@accurx/design";

import { CardWithTitle } from "app/sharedComponents/cardWithTitle/CardWithTitle";
import { CardErrorRefType } from "app/sharedComponents/saveableCard/SaveableCard";

import { ClientSideQuestion } from "../../types/context.types";
import {
    BranchId,
    BranchPosition,
    CardId,
    CardsByKey,
    QuestionLevel,
    QuestionnaireId,
    QuestionsPageError,
} from "../../types/data.types";
import { HandleCancelMeasurementTypeSelectorCard } from "../MeasurementTypeSelector.types";
import { MeasurementsSelectorCard } from "../MeasurementTypeSelectorCard";
import {
    HandleCloseQuestionSelectCard,
    HandleSaveMeasurmentType,
    HandleSelectQuestionType,
} from "../QuestionTypeSelector.types";
import { QuestionTypeSelectorCard } from "../QuestionTypeSelectorCard";

type SelectorsProps = {
    cardsByKey: CardsByKey;
    questions: ClientSideQuestion[];
    handleSelectQuestionType: ({
        branchId,
        branchPosition,
        questionType,
        questionLevel,
    }: HandleSelectQuestionType) => void;
    handleCloseQuestionSelectCard: ({
        branchPosition,
        questionLevel,
    }: HandleCloseQuestionSelectCard) => void;
    handleCancelMeasurementTypeSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSaveMeasurementTypeSelectorCard: ({
        branchId,
        branchPosition,
        event,
        questionLevel,
    }: HandleSaveMeasurmentType) => Promise<boolean>;
    handleCancelPhotoUploadSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSavePhotoUploadSelectorCard: ({
        branchId,
        branchPosition,
        questionLevel,
    }: Omit<HandleSaveMeasurmentType, "event">) => Promise<boolean>;
    activeCardErrorRef: CardErrorRefType;
    questionLevel: QuestionLevel;
    questionnaireErrors: QuestionsPageError[];
    questionnaireId: QuestionnaireId;
    branchId?: BranchId;
    branchPosition?: BranchPosition;
};
export const Selectors = ({
    activeCardErrorRef,
    handleCancelMeasurementTypeSelectorCard,
    handleCancelPhotoUploadSelectorCard,
    handleCloseQuestionSelectCard,
    handleSaveMeasurementTypeSelectorCard,
    handleSavePhotoUploadSelectorCard,
    handleSelectQuestionType,
    questionnaireErrors,
    questionnaireId,
    questions,
    cardsByKey,
    questionLevel,
    branchId,
    branchPosition,
}: SelectorsProps): JSX.Element | null => {
    const cardErrorQuestionTypeSelector: string[] = questionnaireErrors
        .filter(
            (error: QuestionsPageError) =>
                error.id === CardId.questionTypeSelector,
        )
        .map((error: QuestionsPageError) => error.message);
    const cardErrorsMeasurementSelector: string[] = questionnaireErrors
        .filter(
            (error: QuestionsPageError) =>
                error.id === CardId.measurementTypeSelector,
        )
        .map((error: QuestionsPageError) => error.message);
    const cardErrorsPhotoUploadSelector: string[] = questionnaireErrors
        .filter(
            (error: QuestionsPageError) =>
                error.id === CardId.photoUploadSelector,
        )
        .map((error: QuestionsPageError) => error.message);

    return (
        <>
            {questionnaireId && cardsByKey[CardId.questionTypeSelector] && (
                <QuestionTypeSelectorCard
                    branchId={branchId}
                    branchPosition={branchPosition}
                    cardActionErrors={cardErrorQuestionTypeSelector}
                    cardActionErrorRef={activeCardErrorRef}
                    handleSelectQuestionType={handleSelectQuestionType}
                    handleClose={handleCloseQuestionSelectCard}
                    questionLevel={questionLevel}
                />
            )}
            {questionnaireId && cardsByKey[CardId.measurementTypeSelector] && (
                <MeasurementsSelectorCard
                    branchId={branchId}
                    branchPosition={branchPosition}
                    cardTitleQuestionNumber={questions.length + 1}
                    cardActionErrors={cardErrorsMeasurementSelector}
                    cardActionErrorRef={activeCardErrorRef}
                    handleCancelChanges={
                        handleCancelMeasurementTypeSelectorCard
                    }
                    handleSaveChanges={handleSaveMeasurementTypeSelectorCard}
                    isSaving={
                        cardsByKey[CardId.measurementTypeSelector]?.isSaving ??
                        false
                    }
                    questionLevel={questionLevel}
                />
            )}
            {questionnaireId && cardsByKey[CardId.photoUploadSelector] && (
                <>
                    {cardErrorsPhotoUploadSelector.length > 0 && (
                        <div ref={activeCardErrorRef} tabIndex={0}>
                            <FormFieldFeedback
                                text={cardErrorsPhotoUploadSelector[0]}
                                variant="error"
                            />
                        </div>
                    )}
                    <CardWithTitle
                        cardTitle={`Q${
                            questions.length + 1
                        }. Add Photo Upload Question`}
                        cardFooter={
                            <Flex justifyContent="space-between">
                                <Button
                                    text="Back"
                                    theme="secondary"
                                    onClick={() =>
                                        handleCancelPhotoUploadSelectorCard({
                                            questionLevel,
                                        })
                                    }
                                />
                                <Button
                                    text="Add question"
                                    theme="primary"
                                    onClick={() =>
                                        handleSavePhotoUploadSelectorCard({
                                            branchId,
                                            branchPosition,
                                            questionLevel,
                                        })
                                    }
                                />
                            </Flex>
                        }
                    >
                        Add a question to allow patients upload up to 5 photos
                    </CardWithTitle>
                </>
            )}
        </>
    );
};
