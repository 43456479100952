import { TeamSummary, UserSummary } from "@accurx/concierge/types";

export const formatUserDisplayName = ({
    user,
    considerCurrentUser = true,
    defaultToAccurx = false,
}: {
    user: UserSummary | undefined;
    considerCurrentUser?: boolean;
    defaultToAccurx?: boolean;
}) => {
    if (!user && defaultToAccurx) {
        return "Accurx";
    }

    if (!user) {
        return "Unknown user";
    }

    return considerCurrentUser && user.isCurrentUser ? "You" : user.displayName;
};

export const formatAssigneeDisplayName = (
    assignee: UserSummary | TeamSummary | undefined,
): string => {
    if (!assignee) return "Unknown assignee";

    if ("isCurrentUser" in assignee) {
        return assignee.isCurrentUser ? "You" : assignee.displayName;
    }

    return assignee.displayName;
};
