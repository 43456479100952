import { useCurrentWorkspace } from "@accurx/auth";
import { generatePath } from "react-router";

import { useNewConversationNavigation } from "app/clinicianConversations/hooks/useNewConversationNavigation";
import { ROUTES } from "shared/Routes";

import { LinkVariant, NavLink } from "../navLink";
import { StyledButton } from "./ClinicianConversationsSecondaryNavigation.styles";
import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

export const ClinicianConversationsSecondaryNavigation = ({
    onClick,
}: {
    onClick: () => void;
}) => {
    const { navigateToNewConversation, canCreateNewConversation } =
        useNewConversationNavigation();
    const { orgId: workspaceId } = useCurrentWorkspace();

    return (
        <StyledContainer>
            <SecondaryNavigationHeader>Message GP</SecondaryNavigationHeader>
            <SecondaryNavigationContent>
                <StyledLinkList>
                    {canCreateNewConversation && (
                        <li>
                            <StyledButton
                                text="New message"
                                theme="secondary"
                                icon={{ name: "PenToSquare", colour: "blue" }}
                                onClick={() =>
                                    navigateToNewConversation({
                                        fromEmptyConversations: false,
                                    })
                                }
                            />
                        </li>
                    )}
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES.workspaceClinicianConversationsView,
                                { workspaceId, view: "me" },
                            )}
                            text="My conversations"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={generatePath(
                                ROUTES.workspaceClinicianConversationsView,
                                { workspaceId, view: "workspace" },
                            )}
                            text="All conversations"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                </StyledLinkList>
            </SecondaryNavigationContent>
        </StyledContainer>
    );
};
