import { FeatureName } from "@accurx/auth";

import { useIsFeatureEnabled } from "store/hooks";

export const useIsQuestionnaireScoreboardEnabled = () => {
    const isFeatureEnabled = useIsFeatureEnabled(
        FeatureName.PerioperativeCarePilot,
    );
    return isFeatureEnabled;
};
