import * as UI from "@accurx/design";
import styled from "styled-components";

const { SIZES, COLOURS } = UI.Tokens;

export const Indented = styled.div`
    padding: ${SIZES[0.5]} 0 ${SIZES[0.5]} ${SIZES[0.5]};
    margin-left: 1.85rem;
    border-left: 1px solid ${COLOURS.primary.green[130]};
`;
