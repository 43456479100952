import {
    AppointmentChangeRequestStatus,
    AppointmentChangeRequestTypeV2,
} from "../../api/appointment";

const successStatusForType: Record<AppointmentChangeRequestTypeV2, string> = {
    Cancel: "Cancelled",
    Rebook: "Rebooked",
};

const requestedStatusForType: Record<AppointmentChangeRequestTypeV2, string> = {
    Cancel: "Cancellation requested",
    Rebook: "Rebook requested",
};

export function getChangeRequestStatusMessage(
    type: AppointmentChangeRequestTypeV2,
    status: AppointmentChangeRequestStatus,
) {
    if (status === AppointmentChangeRequestStatus.Succeeded) {
        return successStatusForType[type];
    }
    //Both skipped and failed are displayed as 'requested'
    return requestedStatusForType[type];
}
