import {
    SearchForPatientByNhsNumberRequest,
    SearchForPatientResponse,
} from "@accurx/api/portal";
import { Dispatch } from "redux";

import FlemingApi from "api/FlemingApiClient";
import patientTokenService from "shared/storage/PatientTokenService";

// ACTION TYPES

export const SEARCH_FOR_PATIENT_STARTED = "SEARCH_FOR_PATIENT_STARTED";
export const SEARCH_FOR_PATIENT_FINISHED = "SEARCH_FOR_PATIENT_FINISHED";
export const SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE =
    "SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SearchForPatientStartedAction {
    type: typeof SEARCH_FOR_PATIENT_STARTED;
}

export interface SearchForPatientFinishedAction {
    type: typeof SEARCH_FOR_PATIENT_FINISHED;
    response: SearchForPatientResponse | null;
}

interface setEntryPatientNhsNumber {
    type: typeof SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE;
    patientNhsNumber: string | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | SearchForPatientStartedAction
    | SearchForPatientFinishedAction
    | setEntryPatientNhsNumber;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const searchForPatientStarted = (): SearchForPatientStartedAction => ({
    type: SEARCH_FOR_PATIENT_STARTED,
});

const searchForPatientFinished = (
    searchForPatientResponse: SearchForPatientResponse,
): SearchForPatientFinishedAction => {
    // clear current patient from storage as we've just searched for another.
    // prevents stale data remaining behind in the event the latest search fails
    if (patientTokenService !== null) {
        patientTokenService.clearCurrentPatientToken();

        if (searchForPatientResponse.searchedResult?.patientToken) {
            patientTokenService.setCurrentPatientToken(
                searchForPatientResponse.searchedResult.patientToken,
            );
        }
    }

    return {
        type: SEARCH_FOR_PATIENT_FINISHED,
        response: searchForPatientResponse,
    };
};

export const actionCreators = {
    searchForPatientStarted,
    searchForPatientFinished,
    searchForPatient:
        (patientSearchInfo: SearchForPatientByNhsNumberRequest) =>
        async (
            dispatch: Dispatch<
                SearchForPatientStartedAction | SearchForPatientFinishedAction
            >,
        ): Promise<void> => {
            dispatch(searchForPatientStarted());

            const searchForPatientResponse =
                await FlemingApi.searchForPatientByNhsNumber(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    patientSearchInfo.organisationId!,
                    patientSearchInfo.nhsNumber,
                    patientSearchInfo.dateOfBirthYear,
                    patientSearchInfo.dateOfBirthMonth,
                    patientSearchInfo.dateOfBirthDay,
                );
            dispatch(searchForPatientFinished(searchForPatientResponse));
        },
    setEntryPatientNhsNumber: (patientNhsNumber: string) => ({
        type: SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE,
        patientNhsNumber,
    }),
};
