import { ReactNode } from "react";

import { Spinner } from "@accurx/design";

import { GeneralError } from "./GeneralError";

export type LoadingWrapperStatus = {
    hasLoaded: boolean;
    hasLoadingFailed: boolean;
};

type LoadingWrapperProps = {
    children: ReactNode;
    loadingStatus: LoadingWrapperStatus;
};

export const LoadingWrapper = ({
    loadingStatus,
    children,
}: LoadingWrapperProps) => {
    if (
        loadingStatus.hasLoaded === true &&
        loadingStatus.hasLoadingFailed === false
    ) {
        return <>{children}</>;
    }
    if (
        loadingStatus.hasLoaded === false &&
        loadingStatus.hasLoadingFailed === false
    ) {
        return <Spinner />;
    }
    return <GeneralError />;
};
