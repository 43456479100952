import React, {
    ChangeEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";

import { Button, Input, Text } from "@accurx/design";
import {
    useActive,
    useAttrs,
    useChainedCommands,
    useCurrentSelection,
    useExtensionEvent,
    useUpdateReason,
} from "@remirror/react";
import { LinkExtension, ShortcutHandlerProps } from "remirror/extensions";

import { EditIcon, LinkIcon, UnlinkIcon } from "./Icons";
import { FloatingLinkInputWrapper, InnerFloatingWrapper } from "./Link.styles";
import { EditorButton, StyledEditorButtonGroup } from "./Menu.styles";

function useLinkShortcut() {
    const [linkShortcut, setLinkShortcut] = useState<
        ShortcutHandlerProps | undefined
    >();
    const [isEditing, setIsEditing] = useState(false);

    useExtensionEvent(
        LinkExtension,
        "onShortcut",
        useCallback(
            (props) => {
                if (!isEditing) {
                    setIsEditing(true);
                }

                return setLinkShortcut(props);
            },
            [isEditing],
        ),
    );

    return { linkShortcut, isEditing, setIsEditing };
}

function useLinkState() {
    const chain = useChainedCommands();
    const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
    const { to, empty } = useCurrentSelection();

    const url = (useAttrs().link()?.href ?? "") as string;
    const [href, setHref] = useState<string>(url);

    const onRemove = useCallback(() => {
        return chain.removeLink().focus().run();
    }, [chain]);

    const updateReason = useUpdateReason();

    useLayoutEffect(() => {
        if (!isEditing) {
            return;
        }

        if (updateReason.doc || updateReason.selection) {
            setIsEditing(false);
        }
    }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

    useEffect(() => {
        setHref(url);
    }, [url]);

    const submitHref = useCallback(() => {
        setIsEditing(false);
        const range = linkShortcut ?? undefined;

        if (href === "") {
            chain.removeLink();
        } else {
            chain.updateLink({ href, auto: false }, range);
        }

        chain.focus(range?.to ?? to).run();
    }, [setIsEditing, linkShortcut, chain, href, to]);

    const cancelHref = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const clickEdit = useCallback(() => {
        if (empty) {
            chain.selectLink();
        }
        setIsEditing(true);
    }, [chain, empty, setIsEditing]);

    return {
        href,
        setHref,
        linkShortcut,
        isEditing,
        empty,
        clickEdit,
        onRemove,
        submitHref,
        cancelHref,
    };
}

export const LinkButton = () => {
    const {
        isEditing,
        clickEdit,
        empty,
        onRemove,
        submitHref,
        href,
        setHref,
        cancelHref,
    } = useLinkState();
    const active = useActive();
    const activeLink = active.link();

    const handleClickEdit = useCallback(() => {
        clickEdit();
    }, [clickEdit]);

    const linkEditButtons = activeLink ? (
        <>
            <EditorButton onClick={handleClickEdit} title="Edit link">
                <EditIcon />
            </EditorButton>
            <EditorButton onClick={onRemove} title="Remove link">
                <UnlinkIcon />
            </EditorButton>
        </>
    ) : (
        <EditorButton
            disabled={empty}
            onClick={handleClickEdit}
            title="Insert link"
        >
            <LinkIcon />
        </EditorButton>
    );

    return (
        <div>
            <StyledEditorButtonGroup>{linkEditButtons}</StyledEditorButtonGroup>
            {isEditing && (
                <FloatingLinkInputWrapper>
                    <Text variant="note">Link</Text>
                    <InnerFloatingWrapper>
                        <Input
                            autoFocus
                            placeholder="Enter link..."
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                setHref(event.target.value)
                            }
                            value={href}
                            onKeyPress={(
                                event: KeyboardEvent<HTMLInputElement>,
                            ) => {
                                const { key } = event;

                                if (key === "Enter") {
                                    submitHref();
                                }

                                if (key === "Escape") {
                                    cancelHref();
                                }
                            }}
                        />
                        <Button
                            theme="primary"
                            onClick={submitHref}
                            text="Enter"
                        />
                    </InnerFloatingWrapper>
                </FloatingLinkInputWrapper>
            )}
        </div>
    );
};
