import { Button, Feedback, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSelfbookForm = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: 0 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[2]};
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
`;

export const StyledSelfbookModal = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    height: 100vh;
    overflow-y: hidden;
`;

export const StyledFeedback = styled(Feedback)`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[1]};
`;

export const SpacingWrapper = styled.div`
    padding: ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[3]} 0;
`;

export const StyledSearchSelect = styled.div`
    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const StyledSingleSelect = styled.div`
    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const SpinnerPositioning = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const StyledFooter = styled.div`
    bottom: 0;
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 2px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[1]};
    display: flex;
    justify-content: end;
`;

export const StyledButton = styled(Button)<{
    marginBottom?: number | string;
}>`
    // fixed height in accordance to figma design
    height: 32px;
    // added margin because a bottom margin is added in wpf
    // to avoid issue where webview corners are not rounded
    margin-bottom: ${(props) => props.marginBottom ?? 0};
    * {
        font-size: 14px;
    }
`;

export const StyledInnerWrapper = styled(Flex).attrs({
    flexDirection: "column",
})`
    height: 100%;
`;
export const StyledMiddleWrapper = styled.div`
    position: relative;
    height: 100%;
`;

export const StyledOuterWrapper = styled.div`
    overflow-y: hidden;
    // dvh is same as vh with dynamic changes with respect to change in ui such as components behind feauture flags
    height: 100dvh;
`;

export const TelephoneDetailsWrapper = styled.div`
    margin: ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};
`;

export const StyledLinkedText = styled.span`
    span {
        font-size: 14px;
    }
`;

export const StyledClinicianSelect = styled.div`
    margin-left: ${Tokens.SIZES[3]};
    // reduce the font size of list items in search select component
    margin-bottom: ${Tokens.SIZES[3]};

    // this is because Option doesn't support override styles
    * {
        font-size: 14px;
    }
`;

export const ErrorPositioningWrapper = styled.div`
    margin-top: ${Tokens.SIZES[0.5]};
`;
