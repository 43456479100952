import { useConciergeMeta } from "domains/concierge/internal/context";
import { useUnreadCountSelector } from "domains/concierge/internal/hooks/useUnreadCountSelector";
import { Conversation } from "domains/concierge/types";

export const useConversationUnreadCount = (
    conversationId: Conversation["id"],
): number => {
    const { userId } = useConciergeMeta();

    return useUnreadCountSelector((state) => {
        const { teamMembership } = state.conversations;
        const unreadSummary = state.conversations.unreadItems[conversationId];

        // if the conversation has no unread summary that means it has no unread
        // items
        if (!unreadSummary) return 0;

        // conversations are only unread if they're open
        if (unreadSummary.status !== "Open") return 0;

        const { assignee } = unreadSummary;

        // conversations assigned to other users are never unread
        if (assignee.type === "User" && assignee.id !== userId) return 0;

        // conversations assigned to teams the user is not a member of are never
        // unread
        if (assignee.type === "Team" && !teamMembership[assignee.id]) return 0;

        return unreadSummary.itemIds.length;
    });
};
