import { useConciergeSelector } from "domains/concierge/internal/context";
import { getConversation } from "domains/concierge/internal/util/getConversation";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";

export const useConversation = ({
    conversationId,
}: {
    conversationId: Conversation["id"];
}): Conversation | undefined => {
    return useConciergeSelector((state) =>
        getConversation(state.conversations, conversationId),
    );
};
