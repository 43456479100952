import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyledHeaderInfoSection = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyledTime = styled(Text)`
    margin-left: ${Tokens.SIZES[1.5]};
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyledActionButtons = styled.div`
    margin-left: ${Tokens.SIZES[1.5]};
    display: flex;
`;
