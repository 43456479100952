import {
    Conversation,
    PatientEmailItem,
    PatientSmsItem,
    UserSummary,
} from "domains/concierge/types";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";

export const getNextScheduledItem = (
    conversation: Conversation,
    senderId?: UserSummary["id"],
): PatientSmsItem | PatientEmailItem | undefined => {
    const scheduledItems = filter(
        conversation.items,
        (item): item is PatientSmsItem | PatientEmailItem => {
            if (
                item.contentType !== "PatientEmail" &&
                item.contentType !== "PatientSms"
            ) {
                return false;
            }

            if (item.deliveryStatus !== "Queued") {
                return false;
            }

            if (senderId !== undefined) {
                return (
                    item.createdBy.type === "User" &&
                    item.createdBy.id === senderId
                );
            }

            return true;
        },
    );

    if (scheduledItems.length) {
        return sortBy(scheduledItems, "deliveryScheduledAt")[0];
    }

    return undefined;
};
