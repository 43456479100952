import isNil from "lodash/isNil";
import { Observable, Subject } from "rxjs";

import { mapTicketUnreadItemUpdates } from "shared/concierge/conversations/tickets/mappers/mapTicketUnreadItemUpdates";
import { AssigneeSummary } from "shared/concierge/conversations/types/assignee.types";
import {
    ConversationActions as ConversationActionType,
    ConversationForActionRequest,
} from "shared/concierge/conversations/types/component.types";
import {
    Conversation,
    ConversationIdentity,
    ConversationUpdate,
} from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationGroupPage,
    ConversationGroupRuleset,
    ConversationGroupSortOptions,
} from "shared/concierge/conversations/types/conversationGroup.types";
import { InitialSummary } from "shared/concierge/conversations/types/initialSummary.types";
import { ConversationItem } from "shared/concierge/conversations/types/item.types";
import { isInstance } from "shared/concierge/conversations/utils";
import {
    PatientExternalIdentity,
    PatientSummary,
} from "shared/concierge/patients/types/patient.types";
import { UsersAndTeamsSummaries } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import * as TicketApiClient from "./TicketApiClient";
import { mapPaginatedTicketResponseToConversationGroup } from "./mappers/ConversationGroupMapper";
import {
    getServerSortDefinitionForFolder,
    mapRulesetForFilteredTicketViewRequest,
    mapRulesetForFolderTicketViewRequest,
} from "./mappers/ConversationGroupRulesetMapper";
import {
    getPatientThreadAssignee,
    mapTicketToConversation,
    parseUniqueConversationId,
    parseUniqueNoteId,
} from "./mappers/ConversationMapper";
import { mapTicketItemUpdateToConversationUpdate } from "./mappers/ConversationUpdateMapper";
import { mapTicketPatientToPatientSummary } from "./mappers/PatientMapper";
import { mapPatientThreadUserGroupToTeamSummary } from "./mappers/TeamMapper";
import { mapPatientThreadUserToUserSummary } from "./mappers/UserMapper";
import { mapInitialSummary } from "./mappers/mapInitialSummary";
import {
    IdType,
    PatientExternalIdentityDto,
    PatientNoteTags,
    PatientThreadFilteredTicketView,
    PatientThreadPatientDetails,
    PatientThreadTicket,
    PatientThreadTicketCommandResult,
    PatientThreadUser,
    PatientThreadUserGroup,
} from "./types/dto.types";

/**
 * ConversationActions
 *
 * These are handlers for actions the user can take that result
 * in a conversation update or set of updates.
 */
export class ConversationActions implements ConversationActionType {
    protected readonly conversationsSubject = new Subject<
        ConversationUpdate[]
    >();
    protected readonly patientsSubject = new Subject<PatientSummary[]>();
    protected readonly usersAndTeamsSubject =
        new Subject<UsersAndTeamsSummaries>();

    constructor(protected readonly workspaceId: number) {
        // actions exposed for use by UI components should be safe to use without worrying about 'this'
        this.addItemsToConversation = this.addItemsToConversation.bind(this);
        this.markAsDone = this.markAsDone.bind(this);
        this.markAsOpen = this.markAsOpen.bind(this);
        this.markAsUrgent = this.markAsUrgent.bind(this);
        this.markAsNonUrgent = this.markAsNonUrgent.bind(this);
        this.markItemAsRead = this.markItemAsRead.bind(this);
        this.assign = this.assign.bind(this);
        this.getConversationGroup = this.getConversationGroup.bind(this);
        this.getConversation = this.getConversation.bind(this);
        this.matchPatientToConversation =
            this.matchPatientToConversation.bind(this);
    }

    get conversationsFeed(): Observable<ConversationUpdate[]> {
        return this.conversationsSubject;
    }

    get patientsFeed(): Observable<PatientSummary[]> {
        return this.patientsSubject;
    }

    get usersAndTeamsFeed(): Observable<UsersAndTeamsSummaries> {
        return this.usersAndTeamsSubject;
    }

    addItemsToConversation(
        conversation: Conversation,
        items: ConversationItem[],
    ): void {
        const updatedConversation = {
            ...conversation,
            items,
        };

        this.conversationsSubject.next([updatedConversation]);
    }

    async markAsDone(
        conversation: ConversationForActionRequest,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.markTicketDone(
            this.workspaceId,
            ticketIdentity,
            conversation.latestToken,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);

        return conversation;
    }

    async markAsOpen(
        conversation: ConversationForActionRequest,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.reOpenTicket(
            this.workspaceId,
            ticketIdentity,
            conversation.latestToken,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);

        return conversation;
    }

    async markItemAsRead(
        conversationItemId: string,
        patientExternalIdentity?: PatientExternalIdentity | null,
    ): Promise<void> {
        const itemId = parseUniqueNoteId(conversationItemId);

        const ticketPatientExternalIdentity:
            | PatientExternalIdentityDto
            | undefined = patientExternalIdentity
            ? {
                  patientExternalIds: [
                      {
                          type: IdType[patientExternalIdentity.type],
                          value: patientExternalIdentity.value,
                      },
                  ],
              }
            : undefined;

        if (!itemId) {
            throw new Error(
                `Unable to mark conversation item as read - cannot parse conversation item ID ${conversationItemId}.`,
            );
        }

        const request: PatientNoteTags = {
            organisationId: this.workspaceId,
            patientExternalIdentity: ticketPatientExternalIdentity,
            patientThreadItemIds: [itemId],
        };

        const apiResponse = await TicketApiClient.markNoteRead(request);

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);
    }

    async markAsUrgent(
        conversation: ConversationForActionRequest,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.markTicketUrgent(
            this.workspaceId,
            ticketIdentity,
            conversation.latestToken,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);

        return conversation;
    }

    async markAsNonUrgent(
        conversation: ConversationForActionRequest,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.markTicketNonUrgent(
            this.workspaceId,
            ticketIdentity,
            conversation.latestToken,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);

        return conversation;
    }

    async assign(
        conversation: ConversationForActionRequest,
        assignee: AssigneeSummary,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const newAssignee = getPatientThreadAssignee(assignee);

        if (!newAssignee) {
            throw new Error("No assignee defined to a ticket identity.");
        }

        const apiResponse = await TicketApiClient.assign(
            this.workspaceId,
            ticketIdentity,
            conversation.latestToken,
            newAssignee,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);

        return conversation;
    }

    async getAllUnreadItems(): Promise<ConversationUpdate[]> {
        const apiResult = await TicketApiClient.fetchInitialUnreadItems(
            this.workspaceId,
        );

        this.mapAndPublishUserAndTeamUpdates(
            apiResult.referencedNoteUsers ?? [],
            apiResult.referencedNoteUserGroups ?? [],
        );

        const conversationUpdates = mapTicketUnreadItemUpdates(apiResult);

        if (conversationUpdates.length > 0) {
            this.conversationsSubject.next(conversationUpdates);
        }

        return conversationUpdates;
    }

    async getInitialSummary(): Promise<InitialSummary> {
        const apiResult = await TicketApiClient.fetchInitialSummaryDetails(
            this.workspaceId,
        );

        const activeUsers = apiResult.activeUsers ?? [];
        const activeUserGroups = apiResult.activeUserGroups ?? [];

        // since we have it to hand, currently we push display information to UsersAndTeamsManager
        // rather than need it to wait for its own queries (helps avoid double API call).
        this.mapAndPublishUserAndTeamUpdates(activeUsers, activeUserGroups);

        return mapInitialSummary(apiResult);
    }

    async getConversationGroup(
        ruleset: ConversationGroupRuleset,
        sortOptions: ConversationGroupSortOptions,
        continuationToken?: string,
    ): Promise<Pick<ConversationGroupPage, "apiContinuation">> {
        const mappedToFilteredRequest =
            mapRulesetForFilteredTicketViewRequest(ruleset);
        if (mappedToFilteredRequest) {
            const apiResult = await TicketApiClient.fetchMatchingTickets({
                workspaceId: this.workspaceId,
                assignee: mappedToFilteredRequest.assignee,
                isDone: mappedToFilteredRequest.isDone,
                ordering: {
                    urgentFirst:
                        sortOptions.sortOrder === "UrgentThenNewestFirst",
                },
                continuationToken,
            });

            return this.mapAndPublishTicketViewUpdates(ruleset, apiResult);
        }

        const mappedToFolderRequest =
            mapRulesetForFolderTicketViewRequest(ruleset);
        if (mappedToFolderRequest) {
            const serverDefined = getServerSortDefinitionForFolder(
                mappedToFolderRequest,
            );
            if (
                sortOptions.sortOrder !== serverDefined?.sortOrder ||
                sortOptions.sortBy !== serverDefined?.sortBy
            ) {
                throw new Error(
                    "Unable to align sort options to a known ticket API call.",
                );
            }

            const apiResult = await TicketApiClient.fetchFolderView(
                this.workspaceId,
                mappedToFolderRequest,
                continuationToken,
            );

            return this.mapAndPublishTicketViewUpdates(ruleset, apiResult);
        }

        throw new Error("Unable to convert rules to a known ticket API call");
    }

    async getConversation(
        conversationIdentity: ConversationIdentity,
    ): Promise<void> {
        const ticketIdentity = parseUniqueConversationId(
            conversationIdentity.id,
        );

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.fetchTicket(
            this.workspaceId,
            ticketIdentity,
        );

        this.mapAndPublishPatientThreadTicket(apiResponse);
    }

    async matchPatientToConversation(
        conversation: ConversationForActionRequest,
        patientToken: string,
    ): Promise<ConversationIdentity> {
        const ticketIdentity = parseUniqueConversationId(conversation.id);

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketApiClient.matchTicketToPatientWithToken(
            this.workspaceId,
            patientToken,
            ticketIdentity,
            conversation.latestToken,
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);
        return conversation;
    }

    teardown(): void {
        this.conversationsSubject.unsubscribe();
        this.patientsSubject.unsubscribe();
        this.usersAndTeamsSubject.unsubscribe();
    }

    protected mapAndPublishPatientThreadTicket(
        result: PatientThreadTicket,
    ): void {
        const mappedResponse = mapTicketToConversation(result);

        if (result.referencedPatients) {
            this.mapAndPublishPatientUpdates(result.referencedPatients);
        }

        if (isNil(mappedResponse)) {
            throw new Error("Unable to process result from fetchTicket API.");
        }

        this.conversationsSubject.next([mappedResponse]);
    }

    protected mapAndPublishTicketCommandResultUpdates(
        result: PatientThreadTicketCommandResult,
    ): void {
        const conversationUpdates: ConversationUpdate[] = [];
        const patientUpdates: PatientThreadPatientDetails[] = [];
        const userUpdates: PatientThreadUser[] = [];
        const teamUpdates: PatientThreadUserGroup[] = [];

        result.updates?.forEach((update) => {
            const conversation =
                mapTicketItemUpdateToConversationUpdate(update);

            if (update.referencedPatients) {
                patientUpdates.push(...update.referencedPatients);
            }

            if (update.users) {
                userUpdates.push(...update.users);
            }

            if (update.userGroups) {
                teamUpdates.push(...update.userGroups);
            }

            if (conversation) {
                conversationUpdates.push(conversation);
            }
        });

        this.mapAndPublishPatientUpdates(patientUpdates);
        this.mapAndPublishUserAndTeamUpdates(userUpdates, teamUpdates);

        this.conversationsSubject.next(conversationUpdates);

        if (!result.isSuccess) {
            throw new Error(
                "Unable to perform action on the conversation due to a conflict, current state has been updated",
            );
        }
    }

    protected mapAndPublishPatientUpdates(
        updates: PatientThreadPatientDetails[],
    ) {
        const mappedPatients = updates
            .map(mapTicketPatientToPatientSummary)
            .filter(isInstance);

        if (mappedPatients.length) {
            this.patientsSubject.next(mappedPatients);
        }
    }

    protected mapAndPublishUserAndTeamUpdates(
        userUpdates: PatientThreadUser[],
        teamUpdates: PatientThreadUserGroup[],
    ) {
        const userSummaries = userUpdates
            .map(mapPatientThreadUserToUserSummary)
            .filter(isInstance);

        const teamSummaries = teamUpdates
            .map(mapPatientThreadUserGroupToTeamSummary)
            .filter(isInstance);

        if (userSummaries.length || teamSummaries.length) {
            this.usersAndTeamsSubject.next({
                users: userSummaries,
                teams: teamSummaries,
            });
        }
    }

    protected mapAndPublishTicketViewUpdates(
        ruleset: ConversationGroupRuleset,
        result: PatientThreadFilteredTicketView,
    ): Pick<ConversationGroupPage, "apiContinuation"> {
        const mappedResponse = mapPaginatedTicketResponseToConversationGroup(
            ruleset,
            result,
        );

        if (!mappedResponse) {
            throw new Error("Unable to process result from ticket view API.");
        }

        if (result.referencedPatients) {
            this.mapAndPublishPatientUpdates(result.referencedPatients);
        }

        if (result.users || result.userGroups) {
            this.mapAndPublishUserAndTeamUpdates(
                result.users ?? [],
                result.userGroups ?? [],
            );
        }

        this.conversationsSubject.next(mappedResponse.conversations);
        return {
            apiContinuation: mappedResponse.apiContinuation,
        };
    }
}

/**
 * Creates a new conversation actions instance.
 *
 * We prefer to use a factory function over directly instantiating
 * classes because it's easier to mock functions than classes in tests.
 */
export const createConversationActions = (
    ...args: ConstructorParameters<typeof ConversationActions>
): ConversationActions => {
    return new ConversationActions(...args);
};
