import { i } from "images";

export const ErrorImage = ({ alt }: { alt: string }) => {
    return (
        <img
            src={i("illustrations/error-icon-80px@2x.png")}
            srcSet={`${i("illustrations/error-icon-80px.png")}, ${i(
                "illustrations/error-icon-80px@2x.png",
            )} 2x, ${i("illustrations/error-icon-80px@3x.png")} 3x`}
            alt={alt}
            height="80"
        />
    );
};
