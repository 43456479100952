import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledPillText = styled(Pill.Text)`
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;

    em {
        font-weight: bold;
        font-style: normal;
    }
`;
