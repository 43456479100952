import { FloreyDisplayCategory } from "@accurx/api/patient-messaging";
import sortBy from "lodash/sortBy";

export const upperCaseOnlyFirstLetter = (value: string): string =>
    `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`;
export const mapPascalCaseToTitleCase = (value: string): string =>
    value.replace(/[A-Z]/g, " $&").slice(1);

// Before, row in FloreyDisplayCategory enum: `LifestyleAndDemographic = 9`
// After: `{ id: 9, name: "Lifestyle and demographic" }`
export const floreyCategories = Object.values(FloreyDisplayCategory)
    .filter((key) => typeof key === "string")
    .map((name, id) => ({
        value: `${id}`,
        label: upperCaseOnlyFirstLetter(
            mapPascalCaseToTitleCase(name.toString()),
        ),
    }));

// Sorted by label
export const sortedFloreyCategories = sortBy(floreyCategories, "label");
