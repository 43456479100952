import { Conversation } from "./types/conversation.types";
import {
    ConversationContainsItemRule,
    ConversationGroupRuleset,
    ConversationItemRule,
    ConversationRule,
    ConversationStartsWithItemRule,
} from "./types/conversationGroup.types";
import { ConversationItem } from "./types/item.types";

export type ConversationFilter = (conversation: Conversation) => boolean;
export type ConversationItemFilter = (
    conversation: ConversationItem,
) => boolean;

type ConversationFilterCreator = (rule: ConversationRule) => ConversationFilter;
type ConversationItemFilterCreator = (
    rule: ConversationItemRule,
) => ConversationItemFilter;

/**
 * buildConversationFilterFromRuleset - accepts a conversation group ruleset, which
 * is a static definition of a conversation group, and converts it into a filter
 * to be applied.
 *
 * The filters operate on a list of conversations and filter out the
 * conversations that don't fall into this group.
 */
export function buildConversationFilterFromRuleset(
    ruleset: ConversationGroupRuleset,
): ConversationFilter {
    const filters = ruleset.rules.map((rule) =>
        conversationFilterCreators[rule.type](rule),
    );
    if (filters.length < 2) {
        // minor optimization to avoid indirection, given created once and persisted
        return filters.length === 0 ? (_) => true : filters[0];
    }
    return (c) => filters.every((filter) => filter(c));
}

/**
 * buildItemFiltersFromRuleset - accepts a conversation group ruleset, which
 * is a static definition of a conversation group, and converts it into a
 * set of filters for items that should be found in it.
 *
 * A ruleset may have multiple rules within a ContainsItem rule, but may also
 * have multiple ContainsItem rules. While the top level conversation must
 * match all rules, a given item only needs to match one ContainsItem rule.
 *
 * Returns undefined if there are no item filters set.
 */
export function buildItemFiltersFromRuleset(
    ruleset: ConversationGroupRuleset,
): ConversationItemFilter | undefined {
    const filters = ruleset.rules
        .filter(
            (
                rule,
            ): rule is
                | ConversationContainsItemRule
                | ConversationStartsWithItemRule =>
                rule.type === "ContainsItem" || rule.type === "StartsWithItem",
        )
        .map((rule) => buildItemFilterFromContainsRule(rule));
    if (filters.length < 2) {
        // minor optimization to avoid indirection, given created once and persisted
        return filters.length === 0 ? undefined : filters[0];
    }
    return (item) => filters.some((filter) => filter(item));
}

/**
 * Conversation filter creators accept a rule (as defined in a conversation
 * group ruleset) and return a function that tells you if a given
 * conversation is captured by that rule.
 *
 * The idea is that you can create multiple filter functions and compose them
 * to check if a conversation passes multiple rules.
 * e.g. a conversation status is Open and an assignee is user 89
 */
export const conversationFilterCreators: {
    [T in ConversationRule["type"]]: ConversationFilterCreator;
} = {
    Any: (rule) => (conversation) => {
        if (rule.type !== "Any") {
            return false;
        }
        const filters = rule.value.map((rule) => {
            return conversationFilterCreators[rule.type](rule);
        });
        return filters.some((filter) => filter(conversation));
    },
    AssignedTo: (rule) => (conversation) => {
        if (rule.type !== "AssignedTo") {
            return false;
        }

        if (
            conversation.assignee.type === "User" &&
            rule.value.type === "User"
        ) {
            return (
                rule.value.id === null ||
                conversation.assignee.id === rule.value.id
            );
        }

        if (
            conversation.assignee.type === "Team" &&
            rule.value.type === "Team"
        ) {
            return (
                rule.value.id === null ||
                conversation.assignee.id === rule.value.id
            );
        }

        return false;
    },
    Status: (rule) => (conversation) => {
        if (rule.type !== "Status") {
            return false;
        }

        return rule.value === conversation.status;
    },
    ContainsItem: (rule) => {
        if (rule.type !== "ContainsItem") {
            return () => false;
        }

        const itemFilter = buildItemFilterFromContainsRule(rule);
        return (conversation) =>
            conversation.items.some((item) => itemFilter(item));
    },
    StartsWithItem: (rule) => {
        if (rule.type !== "StartsWithItem") {
            return () => false;
        }

        const itemFilter = buildItemFilterFromContainsRule(rule);
        return (conversation) => {
            const firstItem = getStartingItem(conversation);
            return firstItem ? itemFilter(firstItem) : false;
        };
    },
};

function buildItemFilterFromContainsRule(
    containsItemRule:
        | ConversationContainsItemRule
        | ConversationStartsWithItemRule,
): ConversationItemFilter {
    const itemRules = containsItemRule.value.rules.map((rule) =>
        conversationItemFilterCreators[rule.type](rule),
    );
    if (itemRules.length < 2) {
        // minor optimization to avoid indirection, given created once and persisted
        return itemRules.length === 0 ? (_) => true : itemRules[0];
    }
    return (item) => itemRules.every((itemRule) => itemRule(item));
}

/**
 * As with the conversation equivalent, conversation item filter creator
 * accepts a rule and returns a function that tells you if a given
 * conversation item is captured by that rule.
 */
export const conversationItemFilterCreators: {
    [T in ConversationItemRule["type"]]: ConversationItemFilterCreator;
} = {
    ItemType: (rule) => (item) => {
        if (rule.type !== "ItemType") {
            return false;
        }

        return !!rule.value.find((x) => x === item.contentType);
    },
    Sender: (rule) => (item) => {
        if (rule.type !== "Sender") {
            return false;
        }

        if (rule.value.type !== "User") {
            return false;
        }

        return (
            item.createdBy.type === "User" &&
            item.createdBy.id === rule.value.id
        );
    },
    PatientTriageRequestType: (rule) => (item) => {
        if (rule.type !== "PatientTriageRequestType") {
            return false;
        }

        return (
            item.contentType === "PatientTriageRequestNote" &&
            item.requestType === rule.value
        );
    },
    DeliveryStatus: (rule) => (item) => {
        if (rule.type !== "DeliveryStatus") {
            return false;
        }

        if (
            item.contentType !== "PatientSms" &&
            item.contentType !== "PatientEmail" &&
            item.contentType !== "NhsAppMessage"
        ) {
            return false;
        }

        return !!rule.value.includes(item.deliveryStatus);
    },
};

// GPWIN-204: currently if a conversation is only known about from live updates
// this is not quite accurate as later item updates may be all we know about
// a given conversation.
//
// isFullyLoaded is too specific as we don't care about all later items being
// populated, we need to introduce an additional bool / link, which can be worked
// out in the mapping stage.
//
// For now, ignore this detail but trap the follow up work as in practice the
// only rules we have that care about the initial item are for those that can
// only start a conversation anyway (patient triage, appointment request).
const getStartingItem = (
    conversation: Conversation,
): ConversationItem | undefined => {
    return conversation.items.length > 0 ? conversation.items[0] : undefined;
};
