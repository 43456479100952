import { useState } from "react";

import { Ds, Flex, Item } from "@accurx/design";
import { FormFieldFeedback, Input, Pill } from "@accurx/inbox-design-library";

type OtherLabelModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (userInput: string) => void;
};

export const OtherLabelModal = ({
    isOpen,
    onClose,
    onConfirm,
}: OtherLabelModalProps) => {
    const [labelDescription, setLabelDescription] = useState("");
    const [inputError, setInputError] = useState<string | null>(null);

    const onClickSave = () => {
        if (labelDescription.length === 0) {
            setInputError("Enter an outcome to record it to the conversation");
            return;
        }
        if (labelDescription.length > 40) {
            setInputError("Enter an outcome using 40 characters or less");
            return;
        }

        onConfirm(labelDescription);
    };

    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Enter outcome</Ds.Modal.Title>
                    <Item>
                        <form
                            id="other-outcome"
                            onSubmit={(e) => {
                                e.preventDefault();
                                onClickSave();
                            }}
                        >
                            <Input
                                aria-label="Enter outcome"
                                aria-describedby={
                                    inputError ? "input-error" : undefined
                                }
                                onChange={(e) => {
                                    setInputError(null);
                                    setLabelDescription(e.target.value);
                                }}
                            />
                            {inputError && (
                                <FormFieldFeedback
                                    id="input-error"
                                    variant="error"
                                    text={inputError}
                                />
                            )}
                        </form>
                    </Item>
                    <Flex justifyContent="flex-end" gap="2">
                        <Pill.SecondaryButton
                            dimension="small"
                            type="button"
                            onClick={onClose}
                        >
                            <Pill.Text>Cancel</Pill.Text>
                        </Pill.SecondaryButton>
                        <Pill.PrimaryButton
                            form="other-outcome"
                            type="submit"
                            dimension="small"
                        >
                            <Pill.Text>Save</Pill.Text>
                        </Pill.PrimaryButton>
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
