import { UserGroup } from "@accurx/api/florey-builder";
import {
    BatchMessageDetails,
    BatchMessageStatusResponse,
} from "@accurx/api/patient-messaging";
import { Option } from "@accurx/design";
import { Dispatch } from "redux";

import {
    bmCancelBatch,
    bmCreateBeastInvites,
    bmFetchAccurxMessageTemplates,
    bmFetchBatchDetails,
    bmFetchBeastSeries,
    bmFetchFloreySnomedCodes,
    bmFetchFloreysList,
    bmFetchOrgMessageTemplates,
    bmFetchPracticeUserGroups,
    bmFetchSignupFlorey,
    bmFetchSlotTypes,
    bmFetchSnomedCodes,
    bmFetchUserMessageTemplates,
    bmGetUploadDetails,
    bmGetUploadStatus,
    bmSendBatchMessage,
    bmUploadBatchFile,
} from "api/BatchMessaging";
import {
    Actions as PracticesActions,
    ExtendedActions as PracticesExtendedActions,
} from "app/practices/Practices.actions";

import {
    BatchMessageModalDetails,
    BatchSlotType,
    BatchSnomedCode,
    BatchType,
    BeastSeriesDto,
    CreateBeastInvitesParameters,
    FloreyOwner,
    FloreyTemplate,
    FloreysList,
    ListBeastSeriesDto,
    MessageTemplate,
    MessageTemplateOwner,
    SendAt,
    SendBatchMessageRequest,
    SendMessageParameters,
} from "./BatchMessage.types";

export enum Actions {
    CANCEL_BATCH_MESSAGE = "CANCEL_BATCH_MESSAGE",
    CANCEL_BATCH_MESSAGE_SUCCESS = "CANCEL_BATCH_MESSAGE_SUCCESS",
    CANCEL_BATCH_MESSAGE_FAILURE = "CANCEL_BATCH_MESSAGE_FAILURE",
    FETCH_BATCH_MESSAGE_MODAL_DETAILS = "FETCH_BATCH_MESSAGE_MODAL_DETAILS",
    FETCH_BATCH_MESSAGE_MODAL_DETAILS_SUCCESS = "FETCH_BATCH_MESSAGE_MODAL_DETAILS_SUCCESS",
    FETCH_BATCH_MESSAGE_MODAL_DETAILS_FAILURE = "FETCH_BATCH_MESSAGE_MODAL_DETAILS_FAILURE",
    RESET_CANCEL_BATCH_MESSAGE_STATUS = "RESET_CANCEL_BATCH_MESSAGE_STATUS",
    SEND_BATCH_MESSAGE = "SEND_BATCH_MESSAGE",
    SEND_BATCH_MESSAGE_SUCCESS = "SEND_BATCH_MESSAGE_SUCCESS",
    SEND_BATCH_MESSAGE_FAILURE = "SEND_BATCH_MESSAGE_FAILURE",
    RESET_BATCH_MESSAGE_COLLECTION = "RESET_BATCH_MESSAGE_COLLECTION",
    FETCH_BATCH_FLOREY_LIST = "FETCH_BATCH_FLOREY_LIST",
    FETCH_BATCH_FLOREY_LIST_SUCCESS = "FETCH_BATCH_FLOREY_LIST_SUCCESS",
    FETCH_BATCH_FLOREY_LIST_FAILURE = "FETCH_BATCH_FLOREY_LIST_FAILURE",
    FETCH_ACCURX_MESSAGE_TEMPLATES = "FETCH_ACCURX_MESSAGE_TEMPLATES",
    FETCH_ACCURX_MESSAGE_TEMPLATES_SUCCESS = "FETCH_ACCURX_MESSAGE_TEMPLATES_SUCCESS",
    FETCH_ACCURX_MESSAGE_TEMPLATES_FAILURE = "FETCH_ACCURX_MESSAGE_TEMPLATES_FAILURE",
    FETCH_USER_MESSAGE_TEMPLATES = "FETCH_USER_MESSAGE_TEMPLATES",
    FETCH_USER_MESSAGE_TEMPLATES_SUCCESS = "FETCH_USER_MESSAGE_TEMPLATES_SUCCESS",
    FETCH_USER_MESSAGE_TEMPLATES_FAILURE = "FETCH_USER_MESSAGE_TEMPLATES_FAILURE",
    FETCH_ORG_MESSAGE_TEMPLATES = "FETCH_ORG_MESSAGE_TEMPLATES",
    FETCH_ORG_MESSAGE_TEMPLATES_SUCCESS = "FETCH_ORG_MESSAGE_TEMPLATES_SUCCESS",
    FETCH_ORG_MESSAGE_TEMPLATES_FAILURE = "FETCH_ORG_MESSAGE_TEMPLATES_FAILURE",
    FETCH_BATCH_SNOMED_CODES = "FETCH_BATCH_SNOMED_CODES",
    FETCH_BATCH_SNOMED_CODES_SUCCESS = "FETCH_BATCH_SNOMED_CODES_SUCCESS",
    FETCH_BATCH_SNOMED_CODES_FAILURE = "FETCH_BATCH_SNOMED_CODES_FAILURE",
    FETCH_BATCH_FLOREY_SNOMED_CODES = "FETCH_BATCH_FLOREY_SNOMED_CODES",
    FETCH_BATCH_FLOREY_SNOMED_CODES_SUCCESS = "FETCH_BATCH_FLOREY_SNOMED_CODES_SUCCESS",
    FETCH_BATCH_FLOREY_SNOMED_CODES_FAILURE = "FETCH_BATCH_FLOREY_SNOMED_CODES_FAILURE",
    GET_BATCH_UPLOAD_DETAILS = "GET_BATCH_UPLOAD_DETAILS",
    GET_BATCH_UPLOAD_DETAILS_SUCCESS = "GET_BATCH_UPLOAD_DETAILS_SUCCESS",
    GET_BATCH_UPLOAD_DETAILS_FAILURE = "GET_BATCH_UPLOAD_DETAILS_FAILURE",
    GET_BATCH_UPLOAD_STATUS = "GET_BATCH_UPLOAD_STATUS",
    GET_BATCH_UPLOAD_STATUS_SUCCESS = "GET_BATCH_UPLOAD_STATUS_SUCCESS",
    GET_BATCH_UPLOAD_STATUS_FAILURE = "GET_BATCH_UPLOAD_STATUS_FAILURE",
    REMOVE_BATCH_UPLOAD_PATIENT_LIST = "REMOVE_BATCH_UPLOAD_PATIENT_LIST",
    UPLOAD_BATCH_PATIENT_LIST = "UPLOAD_BATCH_PATIENT_LIST",
    UPLOAD_BATCH_PATIENT_LIST_SUCCESS = "UPLOAD_BATCH_PATIENT_LIST_SUCCESS",
    CREATE_BEAST_INVITES = "CREATE_BEAST_INVITES",
    CREATE_BEAST_INVITES_SUCCESS = "CREATE_BEAST_INVITES_SUCCESS",
    CREATE_BEAST_INVITES_FAILURE = "CREATE_BEAST_INVITES_FAILURE",
    FETCH_SIGN_UP_FLOREY = "FETCH_SIGN_UP_FLOREY",
    FETCH_SIGN_UP_FLOREY_SUCCESS = "FETCH_SIGN_UP_FLOREY_SUCCESS",
    FETCH_SIGN_UP_FLOREY_FAILURE = "FETCH_SIGN_UP_FLOREY_FAILURE",
    FETCH_BEAST_SERIES = "FETCH_BEAST_SERIES",
    FETCH_BEAST_SERIES_SUCCESS = "FETCH_BEAST_SERIES_SUCCESS",
    FETCH_BEAST_SERIES_FAILURE = "FETCH_BEAST_SERIES_FAILURE",
    REMOVE_BATCH_FILE = "REMOVE_BATCH_FILE",
    RESET_IS_BATCH_SCHEDULED = "RESET_IS_BATCH_SCHEDULED",
    RESET_CONFIRM_MESSAGE_SUCCESS = "RESET_CONFIRM_MESSAGE_SUCCESS",
    SEND_NEW_BATCH_MESSAGE = "SEND_NEW_BATCH_MESSAGE",
    UPDATE_BATCH_MESSAGE_BODY = "UPDATE_BATCH_MESSAGE_BODY",
    UPDATE_BATCH_SELECTED_BEAST_SERIES = "UPDATE_BATCH_SELECTED_BEAST_SERIES",
    UPDATE_BATCH_SELECTED_FLOREY_ID = "UPDATE_BATCH_SELECTED_FLOREY_ID",
    UPDATE_BATCH_SELECTED_FLOREY_NAME = "UPDATE_BATCH_SELECTED_FLOREY_NAME",
    UPDATE_BATCH_SELECTED_FLOREY_SAVE_TO_RECORD = "UPDATE_BATCH_SELECTED_FLOREY_SAVE_TO_RECORD",
    UPDATE_BATCH_SELECTED_FLOREY_SNOMED = "UPDATE_BATCH_SELECTED_FLOREY_SNOMED",
    UPDATE_BATCH_SELECTED_FLOREY_OWNER = "UPDATE_BATCH_SELECTED_FLOREY_OWNER",
    UPDATE_BATCH_SELECTED_TEMPLATE_NAME = "UPDATE_BATCH_SELECTED_TEMPLATE_NAME",
    UPDATE_BATCH_SELECTED_TEMPLATE_OWNER = "UPDATE_BATCH_SELECTED_TEMPLATE_OWNER",
    UPDATE_BATCH_SELECTED_TEMPLATE_ID = "UPDATE_BATCH_SELECTED_TEMPLATE_ID",
    UPDATE_BATCH_SELECTED_TEMPLATE_REPLY = "UPDATE_BATCH_SELECTED_TEMPLATE_REPLY",
    UPDATE_BATCH_SELECTED_TEMPLATE_SNOMED = "UPDATE_BATCH_SELECTED_TEMPLATE_SNOMED",
    UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_ID = "UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_ID",
    UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_NAME = "UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_NAME",
    UPDATE_BATCH_SELECTED_FLOREY_DEFAULT_ASSIGNEE_ID = "UPDATE_BATCH_SELECTED_FLOREY_DEFAULT_ASSIGNEE_ID",
    UPDATE_BATCH_SELECTED_OPTION_SAVE_TO_RECORD = "UPDATE_BATCH_SELECTED_OPTION_SAVE_TO_RECORD",
    UPDATE_BATCH_TYPE = "UPDATE_BATCH_TYPE",
    UPDATE_SEND_DATE_TIME = "UPDATE_SEND_DATE_TIME",
    UPDATE_STAGGER_OVER_DAYS = "UPDATE_STAGGER_OVER_DAYS",
    UPLOAD_BATCH_FILE = "UPLOAD_BATCH_FILE",
    UPLOAD_BATCH_FILE_FAILURE = "UPLOAD_BATCH_FILE_FAILURE",
    UPLOAD_BATCH_FILE_SUCCESS = "UPLOAD_BATCH_FILE_SUCCESS",
    BATCH_FILE_DATA_RECEIVED = "BATCH_FILE_DATA_RECEIVED",
    UPDATE_BATCH_SELECTED_SELFBOOK_SLOT = "UPDATE_BATCH_SELECTED_SELFBOOK_SLOT",
    GET_BATCH_SLOT_TYPES_LOADING = "GET_BATCH_SLOT_TYPES_LOADING",
    GET_BATCH_SLOT_TYPES_SUCCESS = "GET_BATCH_SLOT_TYPES_SUCCESS",
    GET_BATCH_SLOT_TYPES_FAILURE = "GET_BATCH_SLOT_TYPES_FAILURE",
    FETCH_PRACTICE_USERGROUPS = "FETCH_PRACTICE_USERGROUPS",
    FETCH_PRACTICE_USERGROUPS_SUCCESS = "FETCH_PRACTICE_USERGROUPS_SUCCESS",
    FETCH_PRACTICE_USERGROUPS_FAILURE = "FETCH_PRACTICE_USERGROUPS_FAILURE",
}

type DefaultActions = BaseActions<typeof Actions>;

export type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.CANCEL_BATCH_MESSAGE_SUCCESS]: { response: { id: string } };
        [Actions.CANCEL_BATCH_MESSAGE_FAILURE]: { err: string };
        [Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_SUCCESS]: {
            response: BatchMessageModalDetails;
        };
        [Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_FAILURE]: { err: string };
        [Actions.SEND_BATCH_MESSAGE_SUCCESS]: { response: { id: string } };
        [Actions.SEND_BATCH_MESSAGE_FAILURE]: { err: string };
        [Actions.FETCH_BATCH_FLOREY_LIST_SUCCESS]: { response: FloreysList };
        [Actions.FETCH_BATCH_FLOREY_LIST_FAILURE]: { err: string };

        [Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_SUCCESS]: {
            response: MessageTemplate[];
        };
        [Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_FAILURE]: { err: string };
        [Actions.FETCH_USER_MESSAGE_TEMPLATES_SUCCESS]: {
            response: MessageTemplate[];
        };
        [Actions.FETCH_USER_MESSAGE_TEMPLATES_FAILURE]: { err: string };
        [Actions.FETCH_ORG_MESSAGE_TEMPLATES_SUCCESS]: {
            response: MessageTemplate[];
        };
        [Actions.FETCH_ORG_MESSAGE_TEMPLATES_FAILURE]: { err: string };
        [Actions.FETCH_BATCH_SNOMED_CODES_SUCCESS]: {
            response: BatchSnomedCode[];
        };
        [Actions.FETCH_BATCH_SNOMED_CODES_FAILURE]: { err: string };
        [Actions.FETCH_PRACTICE_USERGROUPS_SUCCESS]: {
            response: UserGroup[];
        };
        [Actions.FETCH_BATCH_SNOMED_CODES_FAILURE]: { err: string };
        [Actions.FETCH_BATCH_FLOREY_SNOMED_CODES_SUCCESS]: {
            response: BatchSnomedCode[];
        };
        [Actions.FETCH_BATCH_FLOREY_SNOMED_CODES_FAILURE]: { err: string };
        [Actions.GET_BATCH_UPLOAD_DETAILS_SUCCESS]: {
            response: BatchMessageDetails;
        };
        [Actions.GET_BATCH_UPLOAD_DETAILS_FAILURE]: { err: string };
        [Actions.GET_BATCH_UPLOAD_STATUS_SUCCESS]: {
            response: BatchMessageStatusResponse;
        };
        [Actions.GET_BATCH_UPLOAD_STATUS_FAILURE]: { err: string };
        [Actions.UPLOAD_BATCH_PATIENT_LIST_SUCCESS]: {
            response: { id: string } | null;
        };
        [Actions.CREATE_BEAST_INVITES_FAILURE]: { err: string };
        [Actions.FETCH_SIGN_UP_FLOREY_SUCCESS]: { response: FloreyTemplate };
        [Actions.FETCH_BEAST_SERIES_SUCCESS]: { response: ListBeastSeriesDto };
        [Actions.UPDATE_BATCH_SELECTED_TEMPLATE_REPLY]: { canReply: boolean };
        [Actions.UPDATE_BATCH_SELECTED_BEAST_SERIES]: {
            series: BeastSeriesDto | null;
        };
        [Actions.UPDATE_BATCH_MESSAGE_BODY]: { patientMessage: string };
        [Actions.UPDATE_BATCH_TYPE]: { batchType: BatchType };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_ID]: { floreyId: string };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_NAME]: { floreyName: string };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_SNOMED]: { snomedCode: string };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_OWNER]: {
            floreyOwner: FloreyOwner | null;
        };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_SAVE_TO_RECORD]: {
            canSaveToRecord: boolean;
        };
        [Actions.UPDATE_BATCH_SELECTED_OPTION_SAVE_TO_RECORD]: {
            shouldSaveToRecord: boolean;
        };
        [Actions.UPDATE_BATCH_SELECTED_TEMPLATE_SNOMED]: { snomedCode: string };
        [Actions.UPDATE_BATCH_SELECTED_TEMPLATE_NAME]: { templateName: string };
        [Actions.UPDATE_BATCH_SELECTED_TEMPLATE_OWNER]: {
            templateOwner: MessageTemplateOwner;
        };
        [Actions.UPDATE_BATCH_SELECTED_TEMPLATE_ID]: {
            selectedTemplateId: number;
        };
        [Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_ID]: {
            assigneeUserGroupId: string;
        };
        [Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_NAME]: {
            assigneeUserGroupName: string;
        };
        [Actions.UPDATE_BATCH_SELECTED_FLOREY_DEFAULT_ASSIGNEE_ID]: {
            selectedFloreyDefaultAssigneeId: string;
        };

        [Actions.UPDATE_SEND_DATE_TIME]: {
            sendDateTime: {
                sendAt: SendAt;
                formattedDate: string;
                formattedTime: Option;
            } | null;
        };
        [Actions.UPDATE_STAGGER_OVER_DAYS]: { staggerOverDays: number | null };
        [Actions.UPLOAD_BATCH_FILE_SUCCESS]: {
            response: {
                attachmentId: string;
                fileLink: string;
                fileName: string;
            };
        };
        [Actions.BATCH_FILE_DATA_RECEIVED]: {
            fileData: {
                attachmentId: string;
                fileLink: string;
                fileName: string;
                isFileAttachmentFromTemplate: boolean;
            };
        };
        [Actions.UPDATE_BATCH_SELECTED_SELFBOOK_SLOT]: { slot: BatchSlotType };
        [Actions.GET_BATCH_SLOT_TYPES_FAILURE]: { err: string };
        [Actions.GET_BATCH_SLOT_TYPES_SUCCESS]: {
            response: {
                slotFormated: BatchSlotType[];
                weeksAvailable: number;
            };
        };
    }
>;

export type KnownAction =
    | ExtendedActions[keyof ExtendedActions]
    | PracticesExtendedActions[PracticesActions.SET_SELECTED_PRACTICE];

export const resetState =
    () =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({ type: Actions.SEND_NEW_BATCH_MESSAGE });

export const resetIsBatchScheduled =
    () =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({ type: Actions.RESET_IS_BATCH_SCHEDULED });

export const updateMessage =
    (message: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_MESSAGE_BODY,
            patientMessage: message,
        });

export const updateBatchType =
    (batchType: BatchType) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_TYPE,
            batchType: batchType,
        });

export const updateSelectedFloreyId =
    (floreyId: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_ID,
            floreyId: floreyId,
        });

export const updateSelectedFloreyName =
    (floreyName: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_NAME,
            floreyName: floreyName,
        });

export const updateSelectedFloreySnomed =
    (snomedCode: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_SNOMED,
            snomedCode: snomedCode,
        });

export const updateSelectedFloreyOwner =
    (floreyOwner: FloreyOwner | null) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_OWNER,
            floreyOwner,
        });

export const updateSelectedFloreySaveToRecord =
    (canSaveToRecord: boolean) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_SAVE_TO_RECORD,
            canSaveToRecord,
        });

export const updateSelectedTemplateName =
    (templateName: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_TEMPLATE_NAME,
            templateName: templateName,
        });

export const updateSelectedTemplateOwner =
    (templateOwner: MessageTemplateOwner) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_TEMPLATE_OWNER,
            templateOwner: templateOwner,
        });
export const updateSelectedTemplateId =
    (selectedTemplateId: number) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_TEMPLATE_ID,
            selectedTemplateId: selectedTemplateId,
        });

export const updateSelectedTemplateSnomed =
    (snomedCode: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_TEMPLATE_SNOMED,
            snomedCode: snomedCode,
        });

export const updateSelectedTemplateCanReply =
    (canReply: boolean) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_TEMPLATE_REPLY,
            canReply: canReply,
        });

export const updateSelectedBatchOptionToSaveToRecord =
    (shouldSaveToRecord: boolean) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_OPTION_SAVE_TO_RECORD,
            shouldSaveToRecord,
        });

export const updateSelectedBeastSeries =
    (series: BeastSeriesDto | null) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_BEAST_SERIES,
            series,
        });

export const updateSelectedAssigneeUserGroupId =
    (assigneeUserGroupId: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_ID,
            assigneeUserGroupId: assigneeUserGroupId,
        });

export const updateSelectedAssigneeUserGroupName =
    (assigneeUserGroupName: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_NAME,
            assigneeUserGroupName: assigneeUserGroupName,
        });

export const updateSelectedFloreyDefaultAssigneeId =
    (selectedFloreyDefaultAssigneeId: string) =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_FLOREY_DEFAULT_ASSIGNEE_ID,
            selectedFloreyDefaultAssigneeId: selectedFloreyDefaultAssigneeId,
        });

export const removeUploadList =
    () =>
    (dispatch: Dispatch<KnownAction>): KnownAction =>
        dispatch({ type: Actions.REMOVE_BATCH_UPLOAD_PATIENT_LIST });

export const getUploadStatus =
    (practiceId: string, batchId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_BATCH_UPLOAD_STATUS });
        const response = await bmGetUploadStatus(practiceId, batchId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.GET_BATCH_UPLOAD_STATUS_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.GET_BATCH_UPLOAD_STATUS_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const getUploadDetails =
    (practiceId: string, batchId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_BATCH_UPLOAD_DETAILS });
        const response = await bmGetUploadDetails(practiceId, batchId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.GET_BATCH_UPLOAD_DETAILS_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.GET_BATCH_UPLOAD_DETAILS_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const sendMessage =
    ({
        practiceId,
        batchId,
        messageTemplate,
        snomedCode,
        floreyId,
        documentId,
        canSaveToRecord,
        sendAt,
        staggerOverDays,
        declineSettings,
        slotType,
        selfBookTimeoutDays,
        assignedUserGroupId,
        shouldSaveBatchToRecord,
        messageTemplateAttachments,
    }: SendMessageParameters) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.SEND_BATCH_MESSAGE });
        let request: SendBatchMessageRequest = {
            id: batchId,
            messageTemplate: messageTemplate,
            snomedConceptId: snomedCode,
            floreyConditionId: floreyId,
            documentId: documentId,
            assignedUserGroupId: assignedUserGroupId,
            saveMessageToRecord: shouldSaveBatchToRecord,
            messageTemplateAttachments,
        };

        if (sendAt || staggerOverDays) {
            request = {
                ...request,
                sendAt: sendAt || null,
                staggerOverDays: staggerOverDays || null,
            };
        }

        if (canSaveToRecord) {
            request.autoSaveToRecord = canSaveToRecord;
        }

        if (slotType) {
            request.slotType = slotType;
            request.selfBookTimeoutDays = selfBookTimeoutDays;
        }

        if (
            declineSettings?.confirmationText &&
            declineSettings?.snomedConceptId
        ) {
            request.declineSettings = {
                snomedConceptId: declineSettings?.snomedConceptId,
                confirmationText: declineSettings?.confirmationText,
            };
        }

        const response = await bmSendBatchMessage(practiceId, request);
        if (response?.success && response?.result) {
            return dispatch({
                type: Actions.SEND_BATCH_MESSAGE_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.SEND_BATCH_MESSAGE_FAILURE,
            err:
                response?.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const createBeastInvites =
    ({
        batchMessageId,
        beastSeriesId,
        practiceId,
    }: CreateBeastInvitesParameters) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.CREATE_BEAST_INVITES });
        const response = await bmCreateBeastInvites(practiceId, {
            batchMessageId,
            beastSeriesId,
        });
        if (response.success && response.result) {
            return dispatch({
                type: Actions.CREATE_BEAST_INVITES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.CREATE_BEAST_INVITES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchFloreysList =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BATCH_FLOREY_LIST });
        const response = await bmFetchFloreysList(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_BATCH_FLOREY_LIST_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_BATCH_FLOREY_LIST_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchSignupFlorey =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_SIGN_UP_FLOREY });
        const response = await bmFetchSignupFlorey(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_SIGN_UP_FLOREY_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_SIGN_UP_FLOREY_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchBeastSeries =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BEAST_SERIES });
        const response = await bmFetchBeastSeries(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_BEAST_SERIES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_BEAST_SERIES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchAccurxMessageTemplates =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_ACCURX_MESSAGE_TEMPLATES });
        const response = await bmFetchAccurxMessageTemplates(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchUserMessageTemplates =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_USER_MESSAGE_TEMPLATES });
        const response = await bmFetchUserMessageTemplates(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_USER_MESSAGE_TEMPLATES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_USER_MESSAGE_TEMPLATES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchOrgMessageTemplates =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_ORG_MESSAGE_TEMPLATES });
        const response = await bmFetchOrgMessageTemplates(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_ORG_MESSAGE_TEMPLATES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_ORG_MESSAGE_TEMPLATES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchSnomedCodes =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BATCH_SNOMED_CODES });
        const response = await bmFetchSnomedCodes(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_BATCH_SNOMED_CODES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_BATCH_SNOMED_CODES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchFloreySnomedCodes =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BATCH_FLOREY_SNOMED_CODES });
        const response = await bmFetchFloreySnomedCodes(practiceId);
        if (response.success && response.result && response.result.codes) {
            return dispatch({
                type: Actions.FETCH_BATCH_FLOREY_SNOMED_CODES_SUCCESS,
                response: response.result.codes || [],
            });
        }
        return dispatch({
            type: Actions.FETCH_BATCH_FLOREY_SNOMED_CODES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const fetchUserGroups =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_PRACTICE_USERGROUPS });
        const response = await bmFetchPracticeUserGroups(practiceId);
        if (response.success && response.result && response.result.userGroups) {
            return dispatch({
                type: Actions.FETCH_PRACTICE_USERGROUPS_SUCCESS,
                response: response.result.userGroups || [],
            });
        }
        return dispatch({
            type: Actions.FETCH_PRACTICE_USERGROUPS_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const updateSelfBookSlot =
    (slot: BatchSlotType) =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({
            type: Actions.UPDATE_BATCH_SELECTED_SELFBOOK_SLOT,
            slot,
        });
    };

export const fetchSlotTypes =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_BATCH_SLOT_TYPES_LOADING });
        const res = await bmFetchSlotTypes(practiceId);
        if (res.success && res.result) {
            // Using the same API than desktop, so we can't change to format in the backend
            // So manually formatting it so it's easier to use
            const slotFormated: BatchSlotType[] = [];
            const slotResponse = res.result.slotTypesWithAvailabilityCount;
            if (slotResponse && Object.keys(slotResponse).length) {
                Object.keys(slotResponse).forEach((slotType) => {
                    slotFormated.push({
                        type: slotType,
                        availability: slotResponse[slotType],
                    });
                });
            }
            return dispatch({
                type: Actions.GET_BATCH_SLOT_TYPES_SUCCESS,
                response: {
                    slotFormated,
                    weeksAvailable: res.result.weeksAvailable,
                },
            });
        }
        return dispatch({
            type: Actions.GET_BATCH_SLOT_TYPES_FAILURE,
            err: res.error || "Something went wrong, please refresh the page",
        });
    };

export const uploadBatchFile = (file: File, practiceId: string) => {
    const formData = new FormData();
    formData.append("formFile", file);
    return async (dispatch: Dispatch<KnownAction>) => {
        dispatch({ type: Actions.UPLOAD_BATCH_FILE });
        const response = await bmUploadBatchFile(practiceId, formData);
        if (response.success) {
            const fileName = file?.name || "";
            const fileLink = URL.createObjectURL(file) || "";
            const attachmentId = response.result.id;
            return dispatch({
                type: Actions.UPLOAD_BATCH_FILE_SUCCESS,
                response: { fileName, fileLink, attachmentId },
            });
        }
        return dispatch({ type: Actions.UPLOAD_BATCH_FILE_FAILURE });
    };
};

export const removeUploadedBatchFile = () => {
    return (dispatch: Dispatch<KnownAction>): void => {
        dispatch({ type: Actions.REMOVE_BATCH_FILE });
    };
};

export const updateBatchFile =
    (fileData: {
        attachmentId: string;
        fileLink: string;
        fileName: string;
        isFileAttachmentFromTemplate: boolean;
    }) =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({
            type: Actions.BATCH_FILE_DATA_RECEIVED,
            fileData,
        });
    };

export const resetConfirmMessageSuccess =
    () =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({ type: Actions.RESET_CONFIRM_MESSAGE_SUCCESS });
    };

export const updateSendDateTime =
    (
        sendDateTime: {
            formattedDate: string;
            formattedTime: Option;
            sendAt: SendAt;
        } | null,
    ) =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({ type: Actions.UPDATE_SEND_DATE_TIME, sendDateTime });
    };

export const updateStaggerOverDays =
    (staggerOverDays: number | null) =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({
            type: Actions.UPDATE_STAGGER_OVER_DAYS,
            staggerOverDays,
        });
    };

export const fetchBatchModalDetails =
    (orgID: string, batchID: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS });
        const response = await bmFetchBatchDetails(orgID, batchID);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };

export const cancelBatchMessage =
    (orgID: string, batchID: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.CANCEL_BATCH_MESSAGE });
        const response = await bmCancelBatch(orgID, batchID);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.CANCEL_BATCH_MESSAGE_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.CANCEL_BATCH_MESSAGE_FAILURE,
            err:
                response.error ||
                "There was a problem cancelling this batch, please try again shortly",
        });
    };

export const resetCancelBatchStatus =
    () =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({ type: Actions.RESET_CANCEL_BATCH_MESSAGE_STATUS });
    };

export const resetBatchMessageCollection =
    () =>
    (dispatch: Dispatch<KnownAction>): void => {
        dispatch({ type: Actions.RESET_BATCH_MESSAGE_COLLECTION });
    };
