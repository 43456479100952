import { Button } from "@accurx/design";

import { AddPatientsCard } from "./AddPatientsCard";

export const BatchUploadCopyPasteCard = ({
    onClick,
    disabled = false,
    "data-userflow-id": dataUserFlowId,
}: {
    onClick: () => void;
    disabled?: boolean;
    "data-userflow-id"?: string;
}) => {
    return (
        <AddPatientsCard.CardOuter>
            <AddPatientsCard.CardInner>
                <AddPatientsCard.TextWrapper>
                    <AddPatientsCard.Title>
                        Input patient details
                    </AddPatientsCard.Title>
                    <AddPatientsCard.Body>
                        Input, or copy and paste, patient details from another
                        source such as an electronic patient record or clinical
                        system
                    </AddPatientsCard.Body>
                </AddPatientsCard.TextWrapper>

                <Button
                    text="Continue"
                    theme="secondary"
                    icon={{
                        name: "ArrowTail",
                        rotation: "right",
                        placement: "end",
                    }}
                    onClick={onClick}
                    disabled={disabled}
                    aria-label="Continue to Import Patients From Copy And Paste"
                    data-userflow-id={dataUserFlowId}
                />
            </AddPatientsCard.CardInner>
        </AddPatientsCard.CardOuter>
    );
};
