import { ChangeEvent, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import {
    useMedicalRecordConnection,
    useNativeTrackingFields,
    useUploadPatientRecordAttachmentMutation,
} from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { Log } from "@accurx/shared";
import { formatPatientName } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { AttachFileFromPatientRecordButton } from "domains/compose/components/Compose/components/Attach/components/AttachSelector/components/AttachFileFromPatientRecordButton";
import { PatientRecordAttachmentSelector } from "domains/compose/components/Compose/components/Attach/components/PatientRecordAttachmentSelector/PatientRecordAttachmentSelector";
import { COMPOSE_ERRORS, MAX_MESSAGE_LENGTH } from "domains/compose/constants";
import { useCompose } from "domains/compose/context";
import { useCharacterCount } from "domains/compose/hooks/useCharacterCount";
import { Attachment } from "domains/compose/reducer.types";
import { NHSAdviceLink } from "domains/compose/types";
import {
    getNativeUploadErrorMessage,
    getTemplateSnomedCodes,
    getValidPatientNhsNumber,
    isErrorActive,
} from "domains/compose/utils";
import { mapSelfBookFormDataToSelfBookLink } from "domains/compose/utils/mapSelfBookFormDataToSelfBookLink";
import { v4 as uuid } from "uuid";

import { SelfbookConfigurationQuickView } from "../../../self-book/components/SelfbookConfigurationQuickView/SelfbookConfigurationQuickView";
import {
    StyledContainer,
    StyledFlexGrowContainer,
    StyledNoContactErrorItem,
    StyledScrollableFixedHeightContainer,
} from "./Compose.styles";
import { ComposeActionStack } from "./ComposeActionStack";
import { AttachmentFailedMessage } from "./components/Attach/components/AttachFeedback/AttachmentFailedMessage";
import { AttachSelector } from "./components/Attach/components/AttachSelector/AttachSelector";
import { AttachFileFromDesktopButton } from "./components/Attach/components/AttachSelector/components/AttachFileFromDesktopButton";
import { AttachNHSAdviceButton } from "./components/Attach/components/AttachSelector/components/AttachNHSAdviceButton";
import {
    MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
    SUPPORTED_FILE_EXTENSIONS,
} from "./components/Attach/upload.constants";
import { useUploadFromDesktop } from "./components/Attach/useUploadFromDesktop";
import { BookingInvite } from "./components/BookingInvite/BookingInvite";
import { BrowseTemplatesButton } from "./components/BrowseTemplatesButton/BrowseTemplatesButton";
import { CharacterCount } from "./components/CharacterCount/CharacterCount";
import { ComposeErrors } from "./components/ComposeErrors/ComposeErrors";
import { ComposeTextArea } from "./components/ComposeTextArea/ComposeTextArea";
import { ContactDetailSelector } from "./components/ContactDetailSelector/ContactDetailSelector";
import { ExpandMinimiseBar } from "./components/ExpandMinimiseBar/ExpandMinimiseBar";
import { FragmentCount } from "./components/FragmentCount/FragmentCount";
import { PatientMessageTemplateSelector } from "./components/MessageTemplates/PatientMessageTemplateSelector/PatientMessageTemplateSelector";
import { NHSAdviceSelector } from "./components/NhsAdviceSelector/NhsAdviceSelector";
import { AllowReplyCheckbox } from "./components/PatientResponseCheckbox/AllowReplyCheckbox";
import { PatientResponseCheckbox } from "./components/PatientResponseCheckbox/PatientResponseCheckbox";
import { RemoveTemplateButton } from "./components/RemoveTemplateButton/RemoveTemplateButton";
import { ResponseToCurrentAssigneeLabel } from "./components/ResponseToCurrentAssigneeLabel/ResponseToCurrentAssigneeLabel";
import { SaveToRecordCheckbox } from "./components/SaveToRecordCheckbox/SaveToRecordCheckbox";
import { ScheduledSendInfo } from "./components/ScheduledSendInfo/ScheduledSendInfo";
import { SendMessage } from "./components/SendMessage/SendMessage";
import { AsyncSnomedCodesInfo } from "./components/SnomedCodesInfo/AsyncSnomedCodesInfo";
import { SnomedCodesInfo } from "./components/SnomedCodesInfo/SnomedCodesInfo";
import type { ComposeProps } from "./types";

export const Compose = ({
    contactDetails = [],
    assigneeLabel = "You",
    conversationId,
    patient,
    patientMatchesCurrentMedicalRecordPatient,
    onMessageSend,
    isMessageSending,
    onUserTyping,
    onMinimiseClick,
    isHeightRestricted,
    onExpandClick,
}: ComposeProps) => {
    const track = useAnalytics();
    const connection = useMedicalRecordConnection();
    const nativeTrackingFields = useNativeTrackingFields();
    const removeTemplateButtonRef = useRef<HTMLButtonElement>(null);
    const attachSelectorButtonRef = useRef<HTMLButtonElement>(null);

    const isBookingInviteEnabled = useFeatureFlag(
        "SelfBookFromComposeSendLink",
    );

    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord === true;

    const { state, dispatch } = useCompose();
    const uploadPatientRecordAttachment =
        useUploadPatientRecordAttachmentMutation();

    const [isAttachSelectorOpen, setIsAttachSelectorOpen] = useState(false);
    const [showMessageTemplates, setShowMessageTemplates] = useState(false);
    const [showPatientRecordAttachments, setShowPatientRecordAttachments] =
        useState(false);
    const [showSelfbookConfiguration, setShowSelfbookConfiguration] =
        useState(false);
    const { onUpload } = useUploadFromDesktop("PatientMessaging");
    const [uploadErrors, setUploadErrors] = useState<string[] | null>(null);
    const [announcements, setAnnouncements] = useState<string | null>(null);
    const [showNhsAdviceSelector, setShowNhsAdviceSelector] = useState(false);

    const hasQuestionnaire = state.template?.type === "QuestionnaireTemplate";
    const hasReachedUploadLimit =
        !hasQuestionnaire &&
        state.attachments.length === state.maxAttachmentCount;
    const selfBookLinkSelected = state.selfBookLink !== null;
    const shouldDisableAttachments =
        hasQuestionnaire ||
        hasReachedUploadLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;
    const hasReachedNHSAdviceLinkLimit = !!state.nhsAdviceLink;
    const shouldDisableNHSAdvice =
        hasQuestionnaire ||
        hasReachedNHSAdviceLinkLimit ||
        selfBookLinkSelected ||
        showSelfbookConfiguration;

    const templateSnomedCodes = state.template
        ? getTemplateSnomedCodes(state.template)
        : [];
    const isPatientResponseCheckboxEnabled =
        !hasQuestionnaire && !selfBookLinkSelected;

    const { characterCount, fragmentCount, isUnicode } = useCharacterCount();

    const patientResposeCheckboxOnChange = () => {
        track("PatientMessageResponse Option Select", {
            ...nativeTrackingFields,
            oldValue: state.isPatientResponseEnabled,
            newValue: !state.isPatientResponseEnabled,
        });
        dispatch({
            type: "UPDATE_IS_PATIENT_RESPONSE_ENABLED",
            payload: {
                isPatientResponseEnabled: !state.isPatientResponseEnabled,
            },
        });
    };

    const uploadFile = async (file: File) => {
        const fileName = file.name;
        const fileSize = file.size;
        const tempAttachment: Attachment = {
            name: fileName,
            origin: "TempUpload",
            id: uuid(),
            size: fileSize,
        };
        dispatch({
            type: "ADD_ATTACHMENT",
            payload: { attachment: tempAttachment },
        });

        const result = await onUpload(file, {
            maxFileSizeInMegabytes: MAX_ATTACHMENT_FILE_SIZE_MEGABYTES,
            allowedFileExtensions: SUPPORTED_FILE_EXTENSIONS,
        });

        if (result.status === "error") {
            dispatch({
                type: "REMOVE_ATTACHMENT",
                payload: { attachment: tempAttachment },
            });
            setUploadErrors(result.result.errors);
        } else {
            dispatch({
                type: "UPDATE_ATTACHMENT",
                payload: {
                    attachmentId: tempAttachment.id,
                    attachmentOrigin: "TempUpload",
                    attachment: {
                        origin: "Upload",
                        name: fileName,
                        size: fileSize,
                        ...result.result,
                    },
                },
            });
        }
    };

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAttachSelectorOpen(false);

        const file = e.target.files ? e.target.files[0] : null;

        if (file) {
            void uploadFile(file);
        }
    };

    const onSelectNHSAdvice = (nhsAdviceLink: NHSAdviceLink) => {
        dispatch({ type: "ADD_NHS_ADVICE_LINK", payload: { nhsAdviceLink } });
        setShowNhsAdviceSelector(false);
    };

    return (
        <StyledContainer>
            {isMessageComponentV1_1Enabled && (
                <ExpandMinimiseBar
                    onMinimiseClick={onMinimiseClick}
                    onExpandClick={onExpandClick}
                    isHeightRestricted={isHeightRestricted ?? false}
                >
                    {state.template === null ? (
                        <span>
                            template and questionnaire search goes here :)
                        </span>
                    ) : (
                        <RemoveTemplateButton
                            ref={removeTemplateButtonRef}
                            template={state.template}
                            onClick={() =>
                                dispatch({ type: "REMOVE_TEMPLATE" })
                            }
                        />
                    )}
                </ExpandMinimiseBar>
            )}
            <StyledFlexGrowContainer>
                <UI.Grid rows="2">
                    <UI.Flex
                        justifyContent={"space-between"}
                        flexWrap={"wrap"}
                        gap={"1"}
                    >
                        <UI.Item>
                            <UI.Flex alignItems={"center"} gap={"0.5"}>
                                <UI.Text variant="note" skinny>
                                    To:
                                </UI.Text>
                                <ContactDetailSelector
                                    contactDetails={contactDetails}
                                />
                            </UI.Flex>
                        </UI.Item>
                        <UI.Item>
                            {state.template === null ? (
                                <BrowseTemplatesButton
                                    onClick={() =>
                                        setShowMessageTemplates(true)
                                    }
                                />
                            ) : (
                                !isMessageComponentV1_1Enabled && (
                                    <RemoveTemplateButton
                                        ref={removeTemplateButtonRef}
                                        template={state.template}
                                        onClick={() =>
                                            dispatch({
                                                type: "REMOVE_TEMPLATE",
                                            })
                                        }
                                    />
                                )
                            )}
                        </UI.Item>
                    </UI.Flex>

                    {isErrorActive(
                        state.errors,
                        COMPOSE_ERRORS.NoContactDetails,
                    ) && (
                        <StyledNoContactErrorItem>
                            <UI.Icon
                                theme="Fill"
                                name="Error"
                                colour="red"
                                size={3}
                            />
                            <UI.Text skinny variant="preview">
                                Enter patient's mobile number or email
                            </UI.Text>
                        </StyledNoContactErrorItem>
                    )}
                    {isErrorActive(
                        state.errors,
                        COMPOSE_ERRORS.EmailWithSelfBookLink,
                    ) && (
                        <StyledNoContactErrorItem>
                            <UI.Icon
                                theme="Fill"
                                name="Error"
                                colour="red"
                                size={3}
                            />
                            <UI.Text skinny variant="preview">
                                Self-Book not available for emails
                            </UI.Text>
                        </StyledNoContactErrorItem>
                    )}
                </UI.Grid>
                {!isMessageComponentV1_1Enabled &&
                    canSaveToRecord &&
                    templateSnomedCodes.length > 0 &&
                    (hasQuestionnaire ? (
                        // The api for questionnaire templates doesn't contain SNOMED terms so we need to fetch them async
                        <AsyncSnomedCodesInfo
                            snomedCodeIds={templateSnomedCodes.map(
                                ({ id }) => id,
                            )}
                        />
                    ) : (
                        <SnomedCodesInfo snomedCodes={templateSnomedCodes} />
                    ))}
                <ComposeErrors />
                <StyledScrollableFixedHeightContainer
                    $isHeightRestricted={isHeightRestricted || false}
                >
                    <ComposeTextArea onUserTyping={onUserTyping} />
                    {isMessageComponentV1_1Enabled && (
                        <ComposeActionStack
                            assigneeLabel={assigneeLabel}
                            canSaveToRecord={canSaveToRecord}
                        />
                    )}
                </StyledScrollableFixedHeightContainer>
                <UI.Flex flexDirection="column" gap="1.5">
                    {!isMessageComponentV1_1Enabled && (
                        <UI.Flex
                            justifyContent={"space-between"}
                            alignItems="center"
                        >
                            {isPatientResponseCheckboxEnabled && (
                                <PatientResponseCheckbox
                                    checked={state.isPatientResponseEnabled}
                                    onChange={patientResposeCheckboxOnChange}
                                    assignee={assigneeLabel}
                                />
                            )}
                            {!isPatientResponseCheckboxEnabled && (
                                <ResponseToCurrentAssigneeLabel
                                    assigneeLabel={assigneeLabel}
                                />
                            )}
                            {state.contactDetails.method !== "Email" && (
                                <UI.Flex
                                    gap="0.5"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexWrap="wrap"
                                >
                                    <CharacterCount
                                        charactersUsed={characterCount}
                                        maxCharacters={MAX_MESSAGE_LENGTH}
                                    />
                                    <FragmentCount count={fragmentCount} />
                                </UI.Flex>
                            )}
                        </UI.Flex>
                    )}
                    {isMessageComponentV1_1Enabled && <ScheduledSendInfo />}
                    <UI.Flex
                        justifyContent="space-between"
                        flexWrap="wrap"
                        gap="1"
                    >
                        {uploadErrors !== null && (
                            <AttachmentFailedMessage errors={uploadErrors} />
                        )}
                        {/* The 100 flex grow here was the only way we could figure out how to get the save to record checkbox to sit nicely up against the send button. */}
                        <UI.Item flex="100">
                            <UI.Flex gap="1" justifyContent="space-between">
                                <UI.Item>
                                    <UI.Flex
                                        justifyContent="flex-start"
                                        gap="0.5"
                                    >
                                        {isMessageComponentV1_1Enabled && (
                                            <UI.Item>
                                                <AllowReplyCheckbox
                                                    checked={
                                                        state.isPatientResponseEnabled
                                                    }
                                                    onChange={
                                                        patientResposeCheckboxOnChange
                                                    }
                                                    disabled={
                                                        !isPatientResponseCheckboxEnabled
                                                    }
                                                />
                                            </UI.Item>
                                        )}
                                        <UI.Item>
                                            <AttachSelector
                                                ref={attachSelectorButtonRef}
                                                isOpen={isAttachSelectorOpen}
                                                toggle={() => {
                                                    // only track when its being opened
                                                    if (!isAttachSelectorOpen) {
                                                        track(
                                                            "Attachment Button Click",
                                                            {
                                                                ...nativeTrackingFields,
                                                                conversationParticipant:
                                                                    state.conversationParticipant,
                                                                eventVersion: 2,
                                                            },
                                                        );
                                                    }

                                                    setIsAttachSelectorOpen(
                                                        (prev) => !prev,
                                                    );
                                                }}
                                                iconOnlyButton={
                                                    isBookingInviteEnabled
                                                }
                                            >
                                                <UI.Flex
                                                    flexDirection="column"
                                                    gap="1"
                                                >
                                                    {(selfBookLinkSelected ||
                                                        showSelfbookConfiguration) && (
                                                        <UI.Feedback
                                                            colour="warning"
                                                            title="Attachment not available with Self-Book"
                                                        />
                                                    )}
                                                    {hasReachedUploadLimit &&
                                                        !hasReachedNHSAdviceLinkLimit && (
                                                            <UI.Feedback
                                                                colour="warning"
                                                                title="File attachment limit reached"
                                                            />
                                                        )}
                                                    {hasReachedUploadLimit &&
                                                        hasReachedNHSAdviceLinkLimit && (
                                                            <UI.Feedback
                                                                colour="warning"
                                                                title="Attachment limit reached"
                                                            />
                                                        )}
                                                    {hasQuestionnaire && (
                                                        <UI.Feedback
                                                            colour="warning"
                                                            title="Attachment not available with a questionnaire link"
                                                        />
                                                    )}
                                                    <AttachFileFromDesktopButton
                                                        isDisabled={
                                                            shouldDisableAttachments
                                                        }
                                                        accepts={`.${SUPPORTED_FILE_EXTENSIONS.join(
                                                            ",.",
                                                        )}`}
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    eventVersion: 2,
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "LocalFile",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setUploadErrors(
                                                                null,
                                                            );
                                                        }}
                                                        onChange={
                                                            handleFileUpload
                                                        }
                                                    />
                                                    <AttachFileFromPatientRecordButton
                                                        isDisabled={
                                                            shouldDisableAttachments
                                                        }
                                                        patientNhsNumber={getValidPatientNhsNumber(
                                                            patient.externalIds,
                                                        )}
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "MedicalRecord",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setShowPatientRecordAttachments(
                                                                true,
                                                            );
                                                            setIsAttachSelectorOpen(
                                                                false,
                                                            );
                                                        }}
                                                    />

                                                    <AttachNHSAdviceButton
                                                        onClick={() => {
                                                            track(
                                                                "Attachment MenuItem Click",
                                                                {
                                                                    conversationParticipant:
                                                                        state.conversationParticipant,
                                                                    attachmentType:
                                                                        "NHSAdvice",
                                                                    ...nativeTrackingFields,
                                                                },
                                                            );

                                                            setShowNhsAdviceSelector(
                                                                true,
                                                            );
                                                        }}
                                                        isDisabled={
                                                            shouldDisableNHSAdvice
                                                        }
                                                        showWarning={
                                                            hasReachedNHSAdviceLinkLimit &&
                                                            !hasReachedUploadLimit
                                                        }
                                                    />
                                                </UI.Flex>
                                            </AttachSelector>
                                        </UI.Item>

                                        {isBookingInviteEnabled && (
                                            <UI.Item>
                                                <BookingInvite
                                                    onClick={() =>
                                                        setShowSelfbookConfiguration(
                                                            true,
                                                        )
                                                    }
                                                />
                                            </UI.Item>
                                        )}
                                    </UI.Flex>
                                </UI.Item>

                                {canSaveToRecord && (
                                    <UI.Item>
                                        <SaveToRecordCheckbox />
                                    </UI.Item>
                                )}
                            </UI.Flex>
                        </UI.Item>

                        <UI.Item flex="1">
                            <SendMessage
                                conversationId={conversationId}
                                patient={patient}
                                patientMatchesCurrentMedicalRecordPatient={
                                    patientMatchesCurrentMedicalRecordPatient
                                }
                                characterCount={characterCount}
                                fragmentCount={fragmentCount}
                                isUnicode={isUnicode}
                                onMessageSend={onMessageSend}
                                isLoading={isMessageSending}
                            />
                        </UI.Item>
                    </UI.Flex>
                </UI.Flex>
                <QuickViewPortal
                    isOpen={showMessageTemplates}
                    onClose={() => setShowMessageTemplates(false)}
                    returnFocusRef={removeTemplateButtonRef}
                >
                    <PatientMessageTemplateSelector
                        onClose={() => setShowMessageTemplates(false)}
                        onClickTemplate={({ type, value: template }) => {
                            if (type === "QuestionnaireTemplate") {
                                dispatch({
                                    type: "ADD_QUESTIONNAIRE_TEMPLATE",
                                    payload: { template },
                                });
                                setShowMessageTemplates(false);
                                setAnnouncements(
                                    `${template.title} selected, questionnaire responses will be sent to ${assigneeLabel}`,
                                );
                                return;
                            }

                            if (
                                template.attachments &&
                                template.attachments.length >
                                    state.maxAttachmentCount
                            ) {
                                Log.warn(
                                    "Unexpected: User attempted to add a template with more than the allowed number of attachments, discarding the ones in excess",
                                    {
                                        tags: {
                                            maxAttachmentsAllowed:
                                                state.maxAttachmentCount,
                                            totalTemplateAttachments:
                                                template.attachments.length,
                                        },
                                    },
                                );
                            }

                            dispatch({
                                type: "ADD_MESSAGE_TEMPLATE",
                                payload: { template },
                            });
                            setShowMessageTemplates(false);
                            setAnnouncements(`${template.title} selected`);
                        }}
                        patientExternalIds={patient.externalIds}
                    />
                </QuickViewPortal>
                <QuickViewPortal
                    isOpen={showPatientRecordAttachments}
                    onClose={() => setShowPatientRecordAttachments(false)}
                >
                    <PatientRecordAttachmentSelector
                        patientExternalIds={patient.externalIds}
                        patientName={formatPatientName({
                            firstName: patient.firstName,
                            familyName: patient.familyName,
                            prefixName: patient.prefixName,
                        })}
                        onSelect={(attachment) => {
                            setShowPatientRecordAttachments(false);
                            setUploadErrors(null);

                            const tempAttachment: Attachment = {
                                id: attachment.documentId,
                                name: attachment.fileName,
                                origin: "TempUpload",
                            };

                            dispatch({
                                type: "ADD_ATTACHMENT",
                                payload: {
                                    attachment: tempAttachment,
                                },
                            });

                            uploadPatientRecordAttachment.mutate(
                                {
                                    documentId: attachment.documentId,
                                    patientExternalIds: patient.externalIds,
                                },
                                {
                                    onError: (error) => {
                                        dispatch({
                                            type: "REMOVE_ATTACHMENT",
                                            payload: {
                                                attachment: tempAttachment,
                                            },
                                        });
                                        setUploadErrors([
                                            getNativeUploadErrorMessage(error),
                                        ]);
                                    },
                                    onSuccess: (data) => {
                                        dispatch({
                                            type: "UPDATE_ATTACHMENT",
                                            payload: {
                                                attachmentId: tempAttachment.id,
                                                attachmentOrigin: "TempUpload",
                                                attachment: {
                                                    origin: "Upload",
                                                    id: data.serverId,
                                                    name: attachment.fileName,
                                                    previewUrl: data.previewUrl,
                                                },
                                            },
                                        });
                                    },
                                },
                            );
                        }}
                    />
                </QuickViewPortal>
                <SelfbookConfigurationQuickView
                    showSelfbookConfiguration={showSelfbookConfiguration}
                    setShowSelfbookConfiguration={(showConfig: boolean) =>
                        setShowSelfbookConfiguration(showConfig)
                    }
                    onSelfbookConfigComplete={(selfbookConfigData) => {
                        const selfBookLink =
                            mapSelfBookFormDataToSelfBookLink(
                                selfbookConfigData,
                            );

                        dispatch({
                            type: "ADD_SELF_BOOK_LINK",
                            payload: {
                                selfBookLink,
                            },
                        });
                    }}
                />
                <QuickViewPortal
                    onClose={() => setShowNhsAdviceSelector(false)}
                    isOpen={showNhsAdviceSelector}
                    returnFocusRef={attachSelectorButtonRef}
                >
                    <NHSAdviceSelector onSelectNHSAdvice={onSelectNHSAdvice} />
                </QuickViewPortal>
                {/* Aria live regions - use for annonucements */}
                <UI.VisuallyHidden>
                    <div id="compose-announcements" aria-live="polite">
                        {announcements}
                    </div>
                </UI.VisuallyHidden>
            </StyledFlexGrowContainer>
        </StyledContainer>
    );
};
