import React, { ReactNode } from "react";

import { Tokens } from "@accurx/design";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Container as OldContainer } from "reactstrap";
import styled from "styled-components";

import { ROUTES } from "shared/Routes";
import { isChainPathname } from "shared/RoutesHelper";

export const StyledContainer = styled.div`
    margin: 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[2]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        margin: 0 ${Tokens.SIZES[4]} ${Tokens.SIZES[4]} ${Tokens.SIZES[4]};
    }
`;

/**
 * @deprecated Please use StyledContainer instead directly inside a page
 */
const Container = ({ children }: { children: ReactNode }): JSX.Element => {
    const history = useHistory();
    const isChain = isChainPathname(history.location.pathname);
    const isLogin = !!useRouteMatch({
        path: [ROUTES.login],
    });
    const isRegister = !!useRouteMatch({ path: [ROUTES.register] });

    return !isChain && !isLogin && !isRegister ? (
        <StyledContainer>{children}</StyledContainer>
    ) : (
        // Accurx web is being redesigned and reactstrap is being deprecated.
        <OldContainer>{children}</OldContainer>
    );
};

export { Container };
