import React, { useEffect } from "react";

import { ButtonLink, Ds, Flex, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES } from "shared/Routes";

export const GlobalNhsPharmacyModal = () => {
    const history = useHistory();

    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLAnchorElement>();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        FlemingAnalyticsTracker.trackPharmacyModalJoinComponentView(
            analyticsLoggedInProps,
        );
        // Only track event on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Use SPA navigation to avoid full page refresh
    const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
        FlemingAnalyticsTracker.trackPharmacyModalJoinButtonClick({
            ...analyticsLoggedInProps,
        });
        e.preventDefault();
        history.push(ROUTES.joinPharmacy);
    };

    return (
        <Ds.Modal
            open={true}
            onOpenChange={() => ({})}
            onOpenAutoFocus={handleFocusRef}
        >
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Join a workspace dedicated to your pharmacy
                    </Ds.Modal.Title>
                    <Text skinny>
                        Until now pharmacy users on Accurx were members of the
                        same NHSPHARMACY workspace. Your pharmacy will now have
                        its own dedicated workspace so you can communicate more
                        personally with your patients.
                    </Text>
                    <Text skinny>
                        Your existing conversations in NHSPHARMACY will not be
                        transferred but you will still have access to them for
                        your records.
                    </Text>
                    <Flex justifyContent="flex-end">
                        <ButtonLink
                            ref={ref}
                            href={ROUTES.joinPharmacy}
                            text="Join your pharmacy's workspace"
                            onClick={handleNavigate}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
