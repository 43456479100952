/*
 * A utility for running a callback after a re-render is complete.
 * We use this for performance tracing, so we know how long
 * it takes for the page to update after performing a mutation
 * or another action taking place.
 *
 * It works by requesting an animation frame - i.e. asking for
 * a callback to run just before the browser repaints -
 * and then in that callback, using setTimeout to add the
 * callback we actually want to run to the queue to be run
 * after the current "thread" of execution (i.e. the browser repaint)
 * completes. So when we finally re-run our callback, the
 * browser should have just repainted.
 *
 * This function is based on https://github.com/andrewiggins/afterframe
 * but using the simpler method of using a `setTimeout` rather than
 * a MessageChannel, as jest won't let us mock MessageChannel.
 * This is fine for our purposes as the worst that will happen
 * is we'll measure re-renders taking slightly longer than they
 * actually did.
 */
export const afterFrame = (callback: () => void) => {
    requestAnimationFrame(() => {
        setTimeout(callback, 0);
    });
};
