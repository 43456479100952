/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { useEffect, useState } from "react";

import { Feedback, Spinner, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import {
    PatientList,
    PatientListAppointmentDay,
    PatientListPatientDetail,
    PatientListPatientDetails,
} from "api/FlemingDtos";
import { ChainAnalyticsTracker } from "app/analytics";
import { PatientListDayCard } from "app/batchMessage/trust/pages/AddPatientsFromPatientList/components/PatientListDayCard";
import { usePatientListQuery } from "app/hooks/queries/usePatientListQuery";
import { Subheader } from "app/patientLists/Subheader";
import { StackPanel } from "app/sharedComponents/StackPanel";

import { useUploadBatchPatientsMutation } from "../../../../../hooks/mutations/useUploadBatchPatientsMutation";
import { useFlemingLoggedInAnalytics } from "../../../../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { BatchMessageId } from "../../../../gp/BatchMessage.types";
import { BatchMessagePageLayout } from "../../../components/BatchMessagePageLayout";

type ChoosePatientListDaysSuccessProps = {
    patientList: PatientList;
    selectedDays: string[];
    onSelectDays: (
        selectedDays: string[],
        patients: PatientListPatientDetail[],
    ) => void;
    onUploadPatients: (patients: PatientListPatientDetail[]) => void;
};
const ChoosePatientListDaysSuccess = ({
    patientList,
    selectedDays,
    onSelectDays,
    onUploadPatients,
}: ChoosePatientListDaysSuccessProps) => {
    const getUniquePatientsFromDays = (
        patientDetails: PatientListPatientDetails,
        appointmentDays: PatientListAppointmentDay[],
    ) => {
        const patientIds = appointmentDays.flatMap((day) =>
            day.appointments.map((appointment) => appointment.patientId),
        );
        const uniquePatientIds = [...new Set(patientIds)];
        const uniquePatients = uniquePatientIds.map(
            (patientId) => patientDetails[patientId],
        );
        return uniquePatients;
    };

    // If there's only one day, select it automatically for the user
    useEffect(() => {
        if (patientList.appointmentDays.length === 1) {
            const uniquePatients = getUniquePatientsFromDays(
                patientList.patientDetails,
                patientList.appointmentDays,
            );
            onUploadPatients(uniquePatients);
        }
    }, [patientList.appointmentDays.length, patientList]);

    const updateSelectedDays = (selectedDays: string[]) => {
        const uniquePatients = getUniquePatientsFromDays(
            patientList.patientDetails,
            patientList.appointmentDays.filter((appointmentDay) =>
                selectedDays.includes(appointmentDay.date),
            ),
        );
        onSelectDays(selectedDays, uniquePatients);
    };

    const handleDaySelectChanged = (
        appointmentDay: PatientListAppointmentDay,
        currentState: boolean,
    ) => {
        if (currentState === false) {
            updateSelectedDays(
                selectedDays.filter((day) => day !== appointmentDay.date),
            );
        } else if (!selectedDays.includes(appointmentDay.date)) {
            updateSelectedDays([...selectedDays, appointmentDay.date]);
        }
    };

    return (
        <>
            {patientList.appointmentDays.length === 0 && (
                <Feedback title="This patient list contains no patients">
                    <Text skinny>
                        Try importing patients from a list which contains
                        patients
                    </Text>
                </Feedback>
            )}
            {
                // Automatically selected when only one day
                patientList.appointmentDays.length === 1 && <Spinner />
            }
            {patientList.appointmentDays.length > 1 && (
                <>
                    <Text>
                        This patient list has multiple dates. Select the dates
                        you want to import patient details from for your batch
                        message
                    </Text>
                    <Subheader
                        icon="Clock"
                        text={`Dates to choose from · ${patientList.appointmentDays.length}`}
                    />
                    <StackPanel gutter={1}>
                        {patientList.appointmentDays.map((appointmentDay) => (
                            <PatientListDayCard
                                key={appointmentDay.date}
                                patientListName={patientList.name}
                                isSelected={selectedDays.includes(
                                    appointmentDay.date,
                                )}
                                onDaySelectChanged={handleDaySelectChanged}
                                appointmentDay={appointmentDay}
                            ></PatientListDayCard>
                        ))}
                    </StackPanel>
                </>
            )}
        </>
    );
};

type ChoosePatientListDaysProps = {
    workspaceId: number | null;
    patientListId: number;
    onBatchMessageCreated: (batchMessageId: BatchMessageId) => void;
    onBack?: () => void;
};
export const ChoosePatientListDays = ({
    workspaceId,
    patientListId,
    onBatchMessageCreated,
    onBack,
}: ChoosePatientListDaysProps) => {
    const patientListQuery = usePatientListQuery({
        workspaceId,
        patientListId,
    });

    const loggedInAnalyticsProps = useFlemingLoggedInAnalytics();

    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [patients, setPatients] = useState<PatientListPatientDetail[]>([]);

    const uploadBatchPatientsMutation = useUploadBatchPatientsMutation();

    const uploadPatients = async (patients: PatientListPatientDetail[]) => {
        const patientsToUpload = patients.map((patient) => ({
            // We don't expect NHS number to be null, if this happens the API will handle the error
            nhsNumber: patient.nhsNumber ?? "",
            dateOfBirth: DateHelpers.formatDate(
                patient.dateOfBirth as string,
                DateFormatOptions.ISO_DATE_STRING_WITHOUT_SECONDS,
            ),
        }));

        // NOTE: order of events is intentional, reset patients should occur synchronously
        // Otherwise tests complain about updating the component not in an act() call

        // User should restart flow if they go back - simplest way to handle various navigation issues
        setPatients([]);

        try {
            const createdBatchMessage =
                await uploadBatchPatientsMutation.mutateAsync({
                    workspaceId: workspaceId?.toString() ?? null,
                    patients: patientsToUpload,
                });
            onBatchMessageCreated(createdBatchMessage);
        } catch {
            // Errors are handled by react query
        }
    };

    const handleContinue = async () => {
        // Should always be success - need to check for type safety
        if (patientListQuery.status === "success") {
            ChainAnalyticsTracker.trackBatchPatientListDateImportButtonClick({
                ...loggedInAnalyticsProps,
                patientListType: patientListQuery.data.listType,
                countPatient: patients.length,
                countDaySelected: selectedDays.length,
            });
        }

        await uploadPatients(patients);
    };

    const handleChoosePatients = (
        selectedDays: string[],
        patients: PatientListPatientDetail[],
    ) => {
        setSelectedDays(selectedDays);
        setPatients(patients);
    };

    return (
        <BatchMessagePageLayout
            pageTitle="Select dates from list"
            showControls
            continueDisabled={patients.length === 0}
            onContinue={handleContinue}
            onBack={onBack}
        >
            {(patientListQuery.status === "loading" ||
                uploadBatchPatientsMutation.status === "loading") && (
                <Spinner />
            )}
            {patientListQuery.status === "error" && (
                <Feedback
                    colour="error"
                    title="There was an error loading the patient list"
                >
                    <Text skinny>Please refresh the page and try again</Text>
                </Feedback>
            )}
            {uploadBatchPatientsMutation.status === "error" && (
                <Feedback
                    colour="error"
                    title="There was an error uploading patients"
                >
                    <Text skinny>
                        {uploadBatchPatientsMutation.error.message ??
                            "Please refresh the page and try again"}
                    </Text>
                </Feedback>
            )}
            {patientListQuery.status === "success" &&
                uploadBatchPatientsMutation.status === "idle" && (
                    <ChoosePatientListDaysSuccess
                        patientList={patientListQuery.data}
                        selectedDays={selectedDays}
                        onSelectDays={handleChoosePatients}
                        onUploadPatients={uploadPatients}
                    ></ChoosePatientListDaysSuccess>
                )}
        </BatchMessagePageLayout>
    );
};
