/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { ChangeEvent, useEffect, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    FormFieldWrapper,
    Icon,
    Input,
    Link,
    Spinner,
    StackPanel,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { useParams } from "react-router-dom";

import { getSharedQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";
import {
    trackQuestionnaireShareLinkCopy,
    trackQuestionnaireSharePage,
} from "app/analytics/FlemingAnalytics";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    routeHome,
    routeImport,
    routeSummary,
} from "../../floreyBuilder/constants/paths";
import {
    StyledCardLineBreak,
    StyledFlexChildInitialInline,
    StyledFlexDivNoWrap,
    StyledLi,
    StyledUl,
} from "./SharingPage.styles";
import {
    COPY_NOT_SUPPORTED_MESSAGE,
    COPY_SUCCESS_MESSAGE,
    CopyStatus,
} from "./SharingPage.types";
import { SharingPageOrgsList } from "./SharingPageOrgsList";

const handleOnChangeInput = (
    event: any,
    sharingLink: string,
    setSharingLink: React.Dispatch<React.SetStateAction<string>>,
): void => {
    if (event.target.value !== sharingLink) {
        setSharingLink(event.target.value);
    }
};

export const SharingPage = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { shareId, orgId } = useParams<{ shareId: string; orgId: string }>();
    const [questionnaireInfo, setQuestionnaireInfo] = useState({ name: "" });
    const [sharingLink, setSharingLink] = useState("");
    const [copyStatus, setCopyStatus] = useState<string>(CopyStatus.Initial);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Initial);

    const getAndSetQuestionnaireName = async () => {
        const response = await getSharedQuestionnaire(shareId);
        if (!response.result || !response.result.questionnaireName) {
            setLoadingStatus(LoadingStatus.Failed);
            return;
        }
        setQuestionnaireInfo({ name: response.result.questionnaireName });
        setLoadingStatus(LoadingStatus.Loaded);
    };
    useEffect(() => {
        trackQuestionnaireSharePage({
            ...analyticsLoggedInProps,
            shareId,
        });
        setLoadingStatus(LoadingStatus.Loading);
        getAndSetQuestionnaireName();
        setSharingLink(window.location.origin + routeImport(shareId));
    }, [shareId]);

    // TODO: understand why event.target.focus does not exist when type is FormEvent<HTMLButtonElement>
    const handleOnClick = (event: any, sharingLink: string) => {
        event.persist(); // Required for React v16: https://reactjs.org/docs/legacy-event-pooling.html
        event.preventDefault();
        trackQuestionnaireShareLinkCopy({
            ...analyticsLoggedInProps,
            shareId,
        });
        if (!navigator.clipboard || !navigator.clipboard.writeText) {
            setCopyStatus(CopyStatus.NotSupported);
            // TODO: update Log.info to send to Sentry to collate enable support analysis for this method
            Log.info(
                "[QuestionnaireSharingPage] User attempted to copy a message but their browser does not support it or the page does not permission to do so",
            );
        } else {
            navigator.clipboard.writeText(sharingLink);
            event.target.focus(); // Focus on button
            setCopyStatus(CopyStatus.Succeeded);
        }
    };

    return (
        <>
            {loadingStatus === LoadingStatus.Loading && <Spinner />}
            {loadingStatus === LoadingStatus.Failed && (
                <StackPanel gutter={2}>
                    <Feedback
                        colour={"error"}
                        title={
                            "There was a problem with that Florey questionnaire"
                        }
                        content={
                            "Try refreshing the page, or return to the homepage and try again. If the problem persists please contact support"
                        }
                    />
                    <Link
                        href={routeHome(orgId)}
                        text={"Take me back to the homepage"}
                    />
                </StackPanel>
            )}
            {loadingStatus === LoadingStatus.Loaded && (
                <>
                    <Breadcrumb title="Custom Florey questionnaires" />

                    <Card
                        header={
                            <Text variant="title" as="h1" skinny>
                                How to share the "{questionnaireInfo.name}"{" "}
                                questionnaire
                            </Text>
                        }
                        footer={
                            <StackPanel horizontalContentAlignment="right">
                                <Link
                                    text="Back to my questionnaires"
                                    href={routeSummary(orgId)}
                                />
                            </StackPanel>
                        }
                    >
                        <StackPanel gutter={2}>
                            <Text skinny>
                                Copy the link to share this Florey Questionnaire
                                with other Accurx users. If you are a user at
                                multiple organisations, you can also choose one
                                to import it to directly.
                            </Text>
                            <Text skinny>
                                This will create a separate copy of this
                                questionnaire in the new organisation. Any
                                changes made in one questionnaire will not
                                affect the other after it has been shared.
                            </Text>
                            <StackPanel orientation="horizontal" gutter={1}>
                                <div>
                                    <Icon
                                        name="Done"
                                        halo={{
                                            colour: "blue",
                                            luminosity: "high",
                                        }}
                                        size={3}
                                    />
                                </div>
                                <div>
                                    <Text variant="label" skinny>
                                        What will be shared?
                                    </Text>
                                    <StyledUl>
                                        <StyledLi>
                                            Name of the questionnaire
                                        </StyledLi>
                                        <StyledLi>Questions & answers</StyledLi>
                                        <StyledLi>Invitation message</StyledLi>
                                        <StyledLi>Confirmation page</StyledLi>
                                    </StyledUl>
                                </div>
                            </StackPanel>
                            <StyledCardLineBreak />
                            <Text as="h2" variant="subtitle" skinny>
                                Share "{questionnaireInfo.name}" questionnaire
                            </Text>
                            <form>
                                <FormFieldWrapper
                                    label="Copy link to share"
                                    labelProps={{
                                        htmlFor: "sharing-link-input",
                                    }}
                                    errors={
                                        copyStatus === CopyStatus.NotSupported
                                            ? [COPY_NOT_SUPPORTED_MESSAGE]
                                            : []
                                    }
                                    successMessage={
                                        copyStatus === CopyStatus.Succeeded
                                            ? COPY_SUCCESS_MESSAGE
                                            : undefined
                                    }
                                >
                                    {/* Input disabled to prevent accidental typos to link prior to copying */}
                                    <StyledFlexDivNoWrap>
                                        <StyledFlexChildInitialInline>
                                            <Input
                                                id="sharing-link-input"
                                                disabled
                                                value={sharingLink}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ): void =>
                                                    handleOnChangeInput(
                                                        e,
                                                        sharingLink,
                                                        setSharingLink,
                                                    )
                                                }
                                            />
                                        </StyledFlexChildInitialInline>
                                        <Button
                                            text="Copy link"
                                            onClick={(e) =>
                                                handleOnClick(e, sharingLink)
                                            }
                                        />
                                    </StyledFlexDivNoWrap>
                                </FormFieldWrapper>
                            </form>
                            <Text skinny>or</Text>
                            <SharingPageOrgsList />
                        </StackPanel>
                    </Card>
                </>
            )}
        </>
    );
};

export default SharingPage;
