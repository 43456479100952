import { User } from "@accurx/auth";

/**
 * Accepts the current user ID and a list of workspaces and returns the ID
 * of the initial selected workspace. First we check the user data
 * for the last active workspace, otherwise we return the first workspace
 * associated to the user, otherwise we return null.
 */
export const getInitialSelectedOrgId = ({
    lastActiveWorkspaceId,
    organisations,
}: User) => {
    if (
        lastActiveWorkspaceId &&
        organisations.find((o) => o.orgId === lastActiveWorkspaceId)
    ) {
        return lastActiveWorkspaceId;
    } else if (organisations.length) {
        return organisations[0].orgId;
    }
    return null;
};
