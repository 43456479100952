const SESSION_STORAGE_TOUR = "accurx-onboarding-tour";

/**
 * We can't end directly a tour because we want to display a feedback on the last step of each flow
 * We can't use queryParams because it won't handle browser back button, and we want to functionalities to work on page refresh and redirection
 * example of flow:
 * 1 - getStarted page is setting the storage to TourTestPatient.Sms
 * 2 - Displaying feedback on each step until main action (ie: sending a messsage)
 * 3 - When we send a message we set the storage to TourTestPatient.SmsEnded to be able to still display the tour on the sucess page
 * 4 - Next time pass by the search page, we clear the tour
 */
export enum TourTestPatient {
    RecordView = "record-view",
    Sms = "sms",
    Video = "video-consult",
    RecordViewEnded = "record-view-ended",
    SmsEnded = "sms-ended",
    VideoEnded = "video-consult-ended",
}

export type FeatureTileName =
    | "Message patient"
    | "Video Consult"
    | "Record View"
    | "Message GP practice"
    | "Conversations";

/**
 * All the tour modes that don't represent a tour that has ended. If the tour
 * mode is one of these that means the tour is still in progress.
 */
export type ActiveTourMode =
    | TourTestPatient.RecordView
    | TourTestPatient.Sms
    | TourTestPatient.Video;

export const getTourMode = (userId: string): TourTestPatient | "" =>
    (window.sessionStorage.getItem(
        `${SESSION_STORAGE_TOUR}-${userId}`,
    ) as TourTestPatient) || "";
export const setTourMode = (value: TourTestPatient, userId: string): void => {
    window.sessionStorage.setItem(`${SESSION_STORAGE_TOUR}-${userId}`, value);
};
export const clearTourMode = (userId: string): void => {
    window.sessionStorage.removeItem(`${SESSION_STORAGE_TOUR}-${userId}`);
};

export const isTourActive = (
    tourMode: TourTestPatient | "",
): tourMode is ActiveTourMode => {
    return (
        tourMode === TourTestPatient.RecordView ||
        tourMode === TourTestPatient.Sms ||
        tourMode === TourTestPatient.Video
    );
};

export const isTestPatientTour = (value: string): boolean =>
    Object.values<string>(TourTestPatient).includes(value);

export const isTourEnded = (userId: string): boolean => {
    const tourMode = getTourMode(userId);
    return (
        tourMode === TourTestPatient.RecordViewEnded ||
        tourMode === TourTestPatient.SmsEnded ||
        tourMode === TourTestPatient.VideoEnded
    );
};

export const getFeatureNameForTour = (
    tourMode?: string,
): FeatureTileName | null => {
    switch (tourMode) {
        case TourTestPatient.Sms:
            return "Message patient";
        case TourTestPatient.RecordView:
            return "Record View";
        case TourTestPatient.Video:
            return "Video Consult";
        default:
            return null;
    }
};
