import * as React from "react";

import { Spinner, Text } from "@accurx/design";

import { ChainAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { PracticeDetails } from "app/practices/Practices.types";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import {
    SectionAccuBook,
    SectionDesktop,
    SectionPatientTriage,
    SectionRecordView,
    SectionSelfBook,
    SectionUnapproved,
    SectionYourPractice,
} from "./sections";

export const PracticeDetail = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceLoading = useAppSelector(
        ({ practices }) => practices.loadingStatus,
    );

    const practice = useAppSelector(({ practices }) =>
        practices.items.find(
            (x: PracticeDetails) =>
                x.id.toString() === practices.selectedPractice,
        ),
    );

    React.useEffect(() => {
        if (practiceLoading !== LoadingStatus.Loaded) return;
        ChainAnalyticsTracker.trackManagePracticePageView(
            analyticsLoggedInProps,
        );
    }, [practiceLoading, analyticsLoggedInProps]);

    const approved = practice?.approved ?? false;
    const practiceFeatures = practice?.features ?? [];
    const practiceModules = practice?.modules ?? {
        batchAndAppointmentReminders: false,
        floreyPlus: false,
        patientTriage: false,
        sms: null,
        videoConsultations: false,
    };

    if (practiceLoading === LoadingStatus.Loading) {
        return <Spinner />;
    }

    return (
        <>
            <NavSubMenuComponent>
                <Breadcrumb wrapper={false} />
                <Text variant="title" as="h1" skinny className="mt-2">
                    {practiceName}
                </Text>
            </NavSubMenuComponent>
            <div className="row">
                <div className="col-12 col-lg-8">
                    {!approved ? (
                        <SectionUnapproved
                            selectedPractice={selectedPractice}
                        />
                    ) : (
                        <>
                            <SectionAccuBook practice={practice} />
                            <SectionYourPractice />
                            <SectionDesktop
                                selectedPractice={selectedPractice}
                                practiceFeatures={practiceFeatures}
                            />
                            <SectionPatientTriage
                                practiceModules={practiceModules}
                            />
                            <SectionSelfBook
                                practiceFeatures={practiceFeatures}
                            />
                            <SectionRecordView
                                practiceFeatures={practiceFeatures}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
