import { useContext } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import {
    ConversationList,
    ConversationViewPage,
} from "domains/inbox/components";
import { CrossWorkspaceSidebar } from "domains/inbox/components/Sidebar/CrossWorkspaceSidebar";
import { PatientMessagingSidebar } from "domains/inbox/components/Sidebar/PatientMessagingSidebar";
import {
    useInboxLink,
    useNavigateToConversationGroup,
    useSearchParams,
    useViewportSize,
} from "domains/inbox/hooks/util";
import { useHasClinicianMessagingInbox } from "domains/inbox/hooks/util/useHasClinicianMessagingInbox";
import { Redirect, Route, Switch } from "react-router";

import { InboxContext } from "./components/InboxContext/InboxContext";
import { InboxLayoutController } from "./components/Layout/InboxLayoutController";
import { LogoutMedicalRecordModal } from "./components/LogoutMedicalRecordModal/LogoutMedicalRecordModal";
import { useAuthHeartbeatQuery } from "./hooks/queries/useAuthHeartbeatQuery";
import { useScrollBouncePrevent } from "./hooks/util/useScrollBouncePrevent";
import { ROUTES_INBOX } from "./routes";
import { userflowIds } from "./util";

export const Inbox = ({
    navigationMenuComponent,
}: {
    navigationMenuComponent?: React.ReactNode;
}) => {
    useAuthHeartbeatQuery();
    const link = useInboxLink();

    const isUnassignedSelfReferralEnabled = useFeatureFlag(
        "PatientInitiatedSussexMsk",
    );
    const hasClinicianMessagingInbox = useHasClinicianMessagingInbox();

    /* we don't use `useInboxParams` here as the conversationId might legitimately not be set */
    const { conversationId, source } = useSearchParams();

    const env = useBrowserEnvironment();
    const viewport = useViewportSize();
    const isMobile = viewport !== "xl" && viewport !== "lg";
    const shouldDisplayNavigationMenu = env !== "WebView";

    const { feedCount } = useContext(InboxContext);

    const userflowEnvId =
        env === "WebView"
            ? userflowIds.environment.webview
            : userflowIds.environment.web;

    /**
     * When visited by native interop with a conversation, we want to disable redirects to a default route
     * so that we can navigate to a specific one in useNavigateToConversationGroup
     */
    const shouldRedirect = !conversationId || source !== "native";
    useNavigateToConversationGroup();

    useScrollBouncePrevent();

    return (
        <div data-userflow-id={userflowEnvId}>
            <InboxLayoutController
                navigationMenuComponent={
                    !isMobile && shouldDisplayNavigationMenu
                        ? navigationMenuComponent
                        : undefined
                }
                leftPane={
                    <>
                        {isMobile &&
                            shouldDisplayNavigationMenu &&
                            navigationMenuComponent}
                        {hasClinicianMessagingInbox ? (
                            <CrossWorkspaceSidebar />
                        ) : (
                            <PatientMessagingSidebar />
                        )}
                    </>
                }
                centrePane={
                    <Switch>
                        <Route path={ROUTES_INBOX.MyInboxSent}>
                            <ConversationList.MyInboxSent />
                        </Route>
                        <Route path={ROUTES_INBOX.MyInboxScheduled}>
                            <ConversationList.MyInboxScheduled />
                        </Route>
                        <Route path={ROUTES_INBOX.MyInbox}>
                            <ConversationList.MyInbox />
                        </Route>
                        <Route path={ROUTES_INBOX.Teams}>
                            <ConversationList.Teams />
                        </Route>
                        <Route path={ROUTES_INBOX.Questionnaires}>
                            <ConversationList.Questionnaires />
                        </Route>
                        <Route path={ROUTES_INBOX.UnassignedAdmin}>
                            <ConversationList.UnassignedTriage triageType="Admin" />
                        </Route>
                        {isUnassignedSelfReferralEnabled && (
                            <Route path={ROUTES_INBOX.UnassignedSelfReferral}>
                                <ConversationList.UnassignedTriage triageType="SelfReferral" />
                            </Route>
                        )}
                        <Route path={ROUTES_INBOX.UnassignedMedical}>
                            <ConversationList.UnassignedTriage triageType="Medical" />
                        </Route>
                        <Route path={ROUTES_INBOX.Unassigned} exact>
                            <ConversationList.Unassigned />
                        </Route>
                        <Route path={ROUTES_INBOX.AllSent}>
                            <ConversationList.AllSent />
                        </Route>
                        <Route path={ROUTES_INBOX.AllScheduled}>
                            <ConversationList.AllScheduled />
                        </Route>
                        <Route path={ROUTES_INBOX.AllPatientResponses}>
                            <ConversationList.AllPatientResponses />
                        </Route>
                        <Route path={ROUTES_INBOX.AllPatientTriages}>
                            <ConversationList.AllPatientTriages />
                        </Route>
                        <Route path={ROUTES_INBOX.AllFailed}>
                            <ConversationList.AllFailed />
                        </Route>
                        <Route path={ROUTES_INBOX.All}>
                            <ConversationList.All />
                        </Route>
                        <Route path={ROUTES_INBOX.Shared}>
                            <ConversationList.Shared />
                        </Route>
                        <Route path={ROUTES_INBOX.Colleagues}>
                            <ConversationList.Colleagues />
                        </Route>
                        <Route path={ROUTES_INBOX.Patient}>
                            <ConversationList.Patient />
                        </Route>
                        {hasClinicianMessagingInbox && (
                            <Route
                                path={"/inbox/w/:workspaceId/care-providers"}
                            >
                                <Switch>
                                    <Route
                                        path={ROUTES_INBOX.CareProvidersMyInbox}
                                    >
                                        <ConversationList.ClinicianMyInbox />
                                    </Route>
                                    <Route path={ROUTES_INBOX.CareProvidersAll}>
                                        <ConversationList.ClinicianAll />
                                    </Route>
                                    {shouldRedirect && (
                                        <Redirect
                                            to={link.to(
                                                "CareProvidersMyInbox",
                                                {
                                                    status: "Open",
                                                },
                                            )}
                                        />
                                    )}
                                </Switch>
                            </Route>
                        )}
                        {shouldRedirect && (
                            <Redirect
                                to={link.to("MyInbox", {
                                    status: "Open",
                                })}
                            />
                        )}
                    </Switch>
                }
                rightPane={
                    <ConversationViewPage
                        showEmptyState={feedCount.value > 0}
                    />
                }
            />
            <LogoutMedicalRecordModal />
        </div>
    );
};
