import React from "react";

import { useSimplifiedNavigation } from "store/hooks";

import { StyledNavigationMenu } from "./NavigationMenu.styles";
import { PrimaryNavigation } from "./primaryNavigation";
import { SecondaryNavigation } from "./secondaryNavigation";

const FullNavigationMenu = ({
    showToggle,
    toggleOnClick,
}: {
    showToggle?: boolean;
    toggleOnClick?: () => void;
}): JSX.Element => {
    const isSimpleNav = useSimplifiedNavigation();

    return (
        <StyledNavigationMenu>
            <PrimaryNavigation
                showToggle={showToggle}
                toggleOnClick={toggleOnClick}
            />
            {!isSimpleNav && (
                <SecondaryNavigation
                    linkOnClick={toggleOnClick}
                    onClose={toggleOnClick}
                />
            )}
        </StyledNavigationMenu>
    );
};

export { FullNavigationMenu };
