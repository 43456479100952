import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { shardedMap } from "domains/concierge/internal/util/shardedMap";
import { Conversation } from "domains/concierge/types";
import { Draft } from "immer";

export const getConversation = (
    state: Draft<Pick<ConversationsState, "items">>,
    id: string,
): Conversation | undefined => {
    return shardedMap.getValue(state.items, id);
};
