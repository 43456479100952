import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { usePoller } from "domains/concierge/internal/hooks/usePoller";
import { actions } from "domains/concierge/internal/store";

export const useInitialSummariesPoller = () => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    const fetchFn = useCallback(async () => {
        const { updates } = await api.getInitialSummaries({
            workspaceId: workspaceId,
        });

        dispatch(
            actions.processUpdates({
                source: "Poller:InitialSummaries",
                ...updates,
            }),
        );

        // Once we have team membership information we need to force refresh
        // unread counts as they are affected by team membership
        dispatch(actions.conversations.recalculateUnreadCounts());
    }, [dispatch, workspaceId]);

    return usePoller({
        name: "InitialSummariesPoller",
        onlyPollWhenOffline: true,
        refreshRate: 1_800_000, // 30 minutes
        fetchFn,
    });
};
