import { Button, Table, Tokens } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";
import styled from "styled-components";

import { PatientDetails } from "../PatientParser";
import TableEmptyState from "./TableEmptyState";

const StyledTable = styled(Table)`
    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        table-layout: fixed;
    }
`;

type PatientsTableProps = {
    patients: PatientDetails[];
    onRemovePatient(nhsNumber: string): void;
};

export default function PatientsTable({
    patients,
    onRemovePatient,
}: PatientsTableProps) {
    return (
        <StyledTable>
            <Table.Head>
                <Table.Row>
                    <Table.Header scope="column">NHS number</Table.Header>
                    <Table.Header scope="column">Date of birth</Table.Header>
                    <Table.Header scope="column">Action</Table.Header>
                </Table.Row>
            </Table.Head>
            <Table.Body>
                {patients.length ? (
                    patients.map((patient) => (
                        <Table.Row key={patient.nhsNumber}>
                            <Table.DataCell>
                                {NhsNumberHelpers.formatNhsNumber(
                                    patient.nhsNumber,
                                )}
                            </Table.DataCell>
                            <Table.DataCell>
                                {DateHelpers.formatDate(
                                    patient.dateOfBirth.toISOString(),
                                    DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
                                )}
                            </Table.DataCell>
                            <Table.DataCell>
                                <Button
                                    text="Remove patient"
                                    theme="secondary"
                                    icon={{
                                        name: "Bin",
                                        colour: "red",
                                        placement: "end",
                                    }}
                                    onClick={() => {
                                        onRemovePatient(patient.nhsNumber);
                                    }}
                                />
                            </Table.DataCell>
                        </Table.Row>
                    ))
                ) : (
                    <TableEmptyState />
                )}
            </Table.Body>
        </StyledTable>
    );
}
