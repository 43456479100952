export enum Settings {
    SELECT_DAY = "SELECT_DAY",
    SELECT_TIME = "SELECT_TIME",
    SELECT_REASON = "SELECT_REASON",
    TEXT_COMMENT = "TEXT_COMMENT",
    TEXT_WELCOME_PAGE = "TEXT_WELCOME_PAGE",
    TEXT_SUBMISSION_PAGE = "TEXT_SUBMISSION_PAGE",
    TEXT_SUBMISSION_SMS = "TEXT_SUBMISSION_SMS",
    TEXT_OOH_PAGE = "TEXT_OOH_PAGE",
    OOH_SCHEDULE = "OOH_SCHEDULE",
    TEXT_PT_PAGE = "TEXT_PT_PAGE",
    TEXT_PT_HEADING = "TEXT_PT_HEADING",
    TEXT_PT_BODY = "TEXT_PT_BODY",
    PRESCRIPTION_LINK = "LINK",
    PRESCRIPTION_TITLE = "TITLE",
    TEXT_WORKINGHOURSLIMIT_PAGE = "TEXT_WORKINGHOURSLIMIT_PAGE",
}
