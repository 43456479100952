import { Button, Text, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledItemContainer = styled.li<{
    selectedItem: boolean;
}>`
    display: flex;
    height: ${Tokens.SIZES[8]};
    align-items: center;

    padding: ${Tokens.SIZES[1.5]} 0px ${Tokens.SIZES[1.5]} ${Tokens.SIZES[1.5]};
    border-left: 4px solid transparent;
    border-radius: ${Tokens.BORDERS.radius};

    ${({ selectedItem }) =>
        selectedItem &&
        css`
            border-left: 4px solid ${Tokens.COLOURS.primary.blue["100"]};
            background-color: ${Tokens.COLOURS.primary.blue["10"]};
        `}
`;

export const StyledText = styled(Text)<{ selectedItem?: boolean }>`
    margin-left: ${Tokens.SIZES[1]};
    ${({ selectedItem }) =>
        !selectedItem &&
        css`
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        `}
`;

export const StyledDetailsSection = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
`;

export const StyledInfo = styled.div`
    min-width: 0;
    flex: 1;
    padding-right: ${Tokens.SIZES[0.5]};
`;

export const StyledButton = styled(Button)`
    margin-right: ${Tokens.SIZES[0.5]};
`;

export const StyledTeamIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    background: ${Tokens.COLOURS.greyscale.dishwater};
    border-radius: ${Tokens.BORDERS.radius};
    padding: 0 ${Tokens.SIZES[1]};
`;
