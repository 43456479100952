import { Button, FormFieldWrapper, Input, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledContainer = styled.div`
    padding: 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[2]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: 0 ${Tokens.SIZES[4]} ${Tokens.SIZES[4]} ${Tokens.SIZES[4]};
    }

    background-color: ${Tokens.COLOURS.greyscale.white};
    height: 100%;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    margin: ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[1.5]};
`;

const StyledInput = styled(Input)`
    margin-bottom: ${Tokens.SIZES[1.5]};
`;

const StyledFooter = styled.div`
    box-shadow: 0px -2px 4px rgba(68, 72, 126, 0.2);
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[4]};
    }
`;

const StyledButton = styled(Button)`
    margin-left: auto;
`;

export {
    StyledContainer,
    StyledFormFieldWrapper,
    StyledInput,
    StyledFooter,
    StyledButton,
};
