import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { bmFetchSnomedCodes } from "api/BatchMessaging";
import { getSnomedCodes } from "api/FloreyBuilder/FloreyBuilderApi";

export const GET_SNOMED_CODES_CACHE_KEY = "GetSnomedCodes";

type SnomedCode = {
    term: string;
    conceptId: string;
};

export type SnomedCodeListContext =
    | "accumail"
    | "patientMessaging"
    | "floreybuilder";

type GetSnomedCodesQueryVariables = {
    workspaceId: number;
    context: SnomedCodeListContext;
};

export const useGetSnomedCodesQuery = createQueryHook<
    GetSnomedCodesQueryVariables,
    SnomedCode[]
>(({ workspaceId, context }) => ({
    queryKey: [GET_SNOMED_CODES_CACHE_KEY, { workspaceId, context }],
    queryFn: async () => {
        /**
         * This hook fetches SNOMED codes across different contexts in the product
         * These places currently use differnt endpoints and the context determines
         * which one of the endpoints to call.
         * accumail and patient messaging both use the batch snomed code endpoint
         * while florey uses the endpoint from floreybuilder.
         * These endpoints may be converged in the future.
         */
        if (context === "floreybuilder") {
            const response = await getSnomedCodes(workspaceId.toString());
            const data = returnDataOrThrow(response);
            return data.codes;
        }
        const response = await bmFetchSnomedCodes(workspaceId.toString());
        return returnDataOrThrow(response);
    },
}));
