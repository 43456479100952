import { useConversationUnreadCount } from "@accurx/concierge/hooks/data/useConversationUnreadCount";
import { Conversation } from "@accurx/concierge/types";
import { VisuallyHiddenUnreadCount } from "domains/inbox/components/VisuallyHiddenUnreadCount/VisuallyHiddenUnreadCount";

import { StyledSpan } from "./UnreadBadge.styles";

export type UnreadBadgeProps = {
    conversation: Conversation;
};

export const UnreadBadge = ({ conversation }: UnreadBadgeProps) => {
    const unreadCount = useConversationUnreadCount(conversation.id);
    const isUnread = unreadCount > 0;
    if (!isUnread) return null;
    return (
        <StyledSpan>
            <VisuallyHiddenUnreadCount count={unreadCount} />
        </StyledSpan>
    );
};
