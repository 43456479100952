import * as UI from "@accurx/design";
import { Link } from "domains/inbox/components/Link/Link";
import { colors } from "domains/inbox/theme";
import styled from "styled-components";

const {
    SIZES,
    BORDERS,
    COLOURS: { greyscale, primary },
} = UI.Tokens;

export const StyledCurrentPatient = styled(Link)<{
    $isActive: boolean;
}>`
    display: flex;
    flex-direction: column;
    gap: ${SIZES[2]};
    border: 1px solid ${primary.green[100]};
    padding: ${SIZES[3]} ${SIZES[2]};
    text-decoration: none;
    color: ${greyscale.white};
    background: ${colors.caribbeanCurrent};
    border-radius: 6px;
    padding: ${SIZES[1]};
    cursor: pointer;

    &:hover {
        text-decoration: none;
        background: ${primary.green[130]};
        border: 1px solid ${primary.green[75]};
        color: ${greyscale.white};
    }

    &:focus,
    &:focus-visible {
        outline: none;

        border: 1px solid ${primary.green[75]};
        box-shadow: 0px 0px 0px 2px ${greyscale.white},
            0px 0px 0px 4px ${primary.blue[100]};
    }

    ${({ $isActive }) =>
        $isActive &&
        `
        background: ${primary.green[100]};
        border: 1px solid ${primary.green[50]};
    `}
`;

export const StyledDisconnectedState = styled.div`
    border-radius: 6px;
    padding: 8px;
    border: 1px solid ${colors.battleshipGrey};
    background: ${colors.feldgrau};
    display: flex;
    gap: ${SIZES[1]};
    color: ${primary.yellow[25]};
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    align-items: center;

    & svg {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const StyledName = styled.div`
    display: block;
    line-height: ${SIZES[3]};
`;

export const StyledLoadingContainer = styled(UI.Flex)`
    padding: ${SIZES[1.5]} ${SIZES[1]};
    background: ${primary.green[130]};
    border-radius: ${BORDERS.radius};
    border: 1px solid ${primary.green[100]};
    gap: ${SIZES[1.5]};
    display: flex;
    flex-direction: column;
`;

export const StyledCurrentPatientTop = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    gap: "1",
})`
    margin-bottom: ${SIZES[0.5]};
`;

export const StyledCurrentPatientActionButton = styled.button<{
    $stretch?: boolean;
}>`
    background-color: ${primary.green[100]};

    // Adding box shadow so we can avoid setting fixed height and still have a 32px button
    box-shadow: 0px 0px 0px 1px ${primary.green[75]};
    border: none;
    border-radius: ${BORDERS.radius};

    display: flex;
    align-items: center;
    gap: ${SIZES[0.5]};
    padding: 6px;

    // To accomodate for the loading icon
    min-width: ${SIZES[4]};

    ${({ $stretch }) => {
        if ($stretch) {
            return "flex: 1;";
        }
    }}

    /* Styling size of svg & children */
    & svg {
        height: 20px;
        width: 20px;
    }

    & span {
        color: ${greyscale.white};
    }

    /* States */

    &:hover&:not(:disabled) {
        background-color: ${primary.green[75]};
    }
    &:focus&:not(:disabled) {
        box-shadow: 0px 0px 0px 2px ${greyscale.white},
            0px 0px 0px 4px ${primary.blue[100]};
    }
`;

export const StyledOuterCardActionButton = styled(
    StyledCurrentPatientActionButton,
)`
    background-color: ${colors.caribbeanCurrent};
    border-color: ${primary.green[100]};

    &:hover&:not(:disabled) {
        background-color: ${primary.green[130]};
        border-color: ${primary.green[75]};
    }
`;
