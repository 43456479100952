import { useEffect, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { NativeApi, useNativeTransport } from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    convertNativeErrorMessageToError,
    sanitizeAndLogError,
} from "domains/inbox/native/errors";
import { ROUTES_INBOX } from "domains/inbox/routes";
import { WorkspaceChangeSchema } from "domains/inbox/schemas/WorkspaceChangeSchema";
import { generatePath, useHistory } from "react-router-dom";

export const useNativeWorkspaceChangeListener = () => {
    const transport = useNativeTransport();
    const workspace = useCurrentWorkspace();
    const history = useHistory();

    const workspaceIdRef = useRef(workspace.orgId);
    workspaceIdRef.current = workspace.orgId;

    useEffect(() => {
        if (!transport) return;

        const unsubscribe = NativeApi.subscribe({
            transport,
            request: {
                type: "SubscribeWorkspaceChanges",
                workspaceId: workspaceIdRef.current,
            },
            onEvent: (event) => {
                if (!event.success) {
                    sanitizeAndLogError(
                        convertNativeErrorMessageToError(event.error),
                        {
                            domain: "Native API Subscription",
                            subscription: "SubscribeWorkspaceChanges",
                        },
                    );
                    return;
                }

                const parseResult = WorkspaceChangeSchema.safeParse(event.data);

                if (!parseResult.success) {
                    sanitizeAndLogError(parseResult.error, {
                        domain: "Native API Subscription",
                        subscription: "SubscribeWorkspaceChanges",
                    });
                    return;
                }

                const { fromWorkspaceId, toWorkspaceId, changeStatus } =
                    parseResult.data;

                if (fromWorkspaceId !== workspaceIdRef.current) {
                    Log.error(
                        "Native app tried to change from a workspace ID that is not the client's current workspace ID",
                    );
                }

                if (
                    changeStatus === "Complete" &&
                    toWorkspaceId !== workspaceIdRef.current
                ) {
                    const link = generatePath(ROUTES_INBOX.MyInbox, {
                        workspaceId: toWorkspaceId,
                    });

                    // Hard redirect to the new workspace
                    window.location.assign(link);
                }
            },
        });
        return () => unsubscribe();
    }, [transport, history]);
};
