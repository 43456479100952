import {
    IWrappedResult,
    JsonResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";

export const ORGANISATION_RESOURCE_TYPES = {
    AdviceAndGuidance: "Advice & guidance",
    Instructions: "Instructions",
    Map: "Map",
    Other: "Other",
};

export type GetOrganisationResourcesRequest = {
    organisationId: number;
};

export type UploadOrganisationResourceRequest = {
    file: File;
    organisationId: number;
    type: string;
    title: string;
};

export type DeleteOrganisationResourceRequest = {
    organisationId: number;
    resourceId: number;
};

export type GetOrganisationResourcesResponse = {
    resources: OrganisationResource[];
};

export type OrganisationResource = {
    id: number;
    fileName: string;
    title: string;
    type: string | null;
    uploadedBy: string;
    uploadedDate: string;
    uploadedByCurrentUser: boolean;
    downloadUrl: string;
};

const ENDPOINTS = {
    orgResourceAll: "/api/portal/OrganisationResource/all",
    orgResourceUpload: "/api/portal/OrganisationResource/Upload",
    orgResource: "/api/portal/OrganisationResource",
} as const;

export const getOrganisationResources = async ({
    organisationId,
}: GetOrganisationResourcesRequest): Promise<
    IWrappedResult<GetOrganisationResourcesResponse>
> => {
    return await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.orgResourceAll,
            query: `?organisationId=${organisationId}`,
        }),
    );
};

export const uploadOrganisationResource = async ({
    file,
    organisationId,
    type,
    title,
}: UploadOrganisationResourceRequest): Promise<
    IWrappedResult<OrganisationResource>
> => {
    const formData = new FormData();
    formData.append("file", file);

    return await httpClient.postFileSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.orgResourceUpload,
            query: `?organisationId=${organisationId}&type=${type}&title=${title}`,
        }),
        formData,
    );
};

export const deleteOrganisationResource = async ({
    organisationId,
    resourceId,
}: DeleteOrganisationResourceRequest): Promise<JsonResult> => {
    return await httpClient.deleteJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.orgResource,
        }),
        { organisationId, resourceId },
    );
};
