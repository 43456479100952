import React from "react";

import { Card, Icon, Text, Tokens } from "@accurx/design";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { getStringQuery } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import {
    AcceptableUsePolicyValues,
    setUserAcceptableUsePolicyValue,
} from "../FloreyBuilder.utils";
import { PageContainerForStickyFooter } from "../components/PageContainer.styles";

const StyledSmallTitleHeading = styled.div`
    display: flex;
    justify-content: center;
`;
const StyledPageTitleHeading = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`;
const StyledTextLinkLayout = styled.span`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;
const StyledLayoutLink = styled.a`
    padding: 0 0 ${Tokens.SIZES[1]} ${Tokens.SIZES[1]};
    text-decoration: underline;
`;

export const FloreyBuilderAUP = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();
    const location = useLocation();
    const redirectString = getStringQuery(location.search, "next") || "";
    const termsAndConditions = "https://www.accurx.com/terms-and-conditions";
    const userId = useAppSelector(({ account }) => account.user?.accuRxUserId);

    const handleAccept = (): void => {
        FlemingAnalyticsTracker.trackFloreyBuilderAUPAccepted(
            analyticsLoggedInProps,
        );
        if (userId) {
            setUserAcceptableUsePolicyValue(
                userId,
                AcceptableUsePolicyValues.True,
            );
        }
        history.push(redirectString);
    };

    const handleGoBack = (): void => {
        FlemingAnalyticsTracker.trackFloreyBuilderAUPBack(
            analyticsLoggedInProps,
        );
        history.goBack();
    };

    return (
        <>
            <PageContainerForStickyFooter>
                <StyledSmallTitleHeading>
                    <Text colour={"zinc"} children={"Custom Floreys"} skinny />
                </StyledSmallTitleHeading>
                <StyledPageTitleHeading>
                    <Text
                        variant="title"
                        as="h1"
                        children={"Acceptable Use Policy"}
                        skinny
                    />
                </StyledPageTitleHeading>
                <Card>
                    <>
                        <StyledTextLinkLayout>
                            <Text>
                                The provision of and use of this feature are
                                governed by{" "}
                            </Text>
                            <StyledLayoutLink
                                href={termsAndConditions}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {" "}
                                Accurx terms and conditions
                                <Icon
                                    name={"OpenWindow"}
                                    colour={"blue"}
                                    size={3}
                                />
                            </StyledLayoutLink>
                        </StyledTextLinkLayout>
                        <Text>
                            The special terms of service are listed below:
                        </Text>
                        <ul>
                            <li>
                                <Text>
                                    Questionnaires should only request personal
                                    information from patients that is relevant
                                    to their care.
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    Questionnaires should not include any
                                    material that is defamatory, offensive or
                                    otherwise objectionable that could cause
                                    reputational damage to Accurx.
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    Questionnaires should not include material
                                    that infringes the intellectual property
                                    rights of Accurx or any other third party.
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    You accept that you are responsible for the
                                    content of Florey Questionnaires when using
                                    this feature.
                                </Text>
                            </li>
                            <li>
                                <Text>
                                    To ensure the data is processed correctly,
                                    please try to avoid including personal
                                    information in the questions or template
                                    names.
                                </Text>
                            </li>
                        </ul>
                        <Text>
                            We reserve the right to suspend access to this
                            feature if found that you have been using this
                            feature inappropriately.
                        </Text>
                    </>
                </Card>
            </PageContainerForStickyFooter>

            <StepsFooter
                backText={"Back"}
                forwardText={"Agree and start"}
                backClickFunction={handleGoBack}
                forwardClickFunction={handleAccept}
                disabled={false}
            />
        </>
    );
};
