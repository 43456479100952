import { Assignee } from "@accurx/api/clinician-messaging";
import { AssigneeSummary } from "domains/concierge/types";

export const mapAssignee = (assignee: Assignee): AssigneeSummary => {
    if (assignee.assignedUser?.accuRxId) {
        return { type: "User", id: assignee.assignedUser.accuRxId };
    }

    if (assignee.assignedUserGroup?.id) {
        return { type: "Team", id: assignee.assignedUserGroup.id };
    }

    return { type: "None" };
};
