import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { PatientManager } from "shared/concierge/patients/types/PatientManager.types";

import { useGlobalPatientManager } from "./GlobalPatientManagerContext";

export const PatientManagerContext = createContext<PatientManager | null>(null);

type PatientManagerProviderProps = {
    children?: ReactNode;
    workspaceId: number | null;
};

/**
 * PatientManagerProvider
 *
 * Sets up a patient manager instance for a given workspace
 * and exposes it via React Context. Child components can access
 * the patient manager through a hook:
 * `usePatientManager()`
 *
 * Any child components are guaranteed to have access to a patient
 * manager instance because this provider will not render any of its
 * children until an instance is set up.
 */
export const PatientManagerProvider = ({
    workspaceId,
    children,
}: PatientManagerProviderProps): JSX.Element => {
    const globalPatientManager = useGlobalPatientManager();
    const [manager, setManager] = useState<PatientManager | null>(null);

    useEffect(() => {
        const nextManager =
            workspaceId && Number.isSafeInteger(workspaceId)
                ? globalPatientManager?.forWorkspace(workspaceId)
                : undefined;
        setManager(nextManager?.item ?? null);
        return () => nextManager?.release();
    }, [workspaceId, globalPatientManager]);

    return (
        <PatientManagerContext.Provider value={manager}>
            {/* Always return children, this does mean children are not guaranteed to have access to the manager,
                but allows us to control the UI before the manager is initialised */}
            {children}
        </PatientManagerContext.Provider>
    );
};

/**
 * Provides a patient manager instance
 */
export const usePatientManager = (): PatientManager => {
    const manager = useOptionalPatientManager();

    if (!manager) {
        throw new Error(
            "usePatientManager called outside the <PatientManagerProvider /> or before it has been initialised",
        );
    }

    return manager;
};
/**
 * Provides a patient manager instance if it has been instantiated
 */
export const useOptionalPatientManager = (): PatientManager | null =>
    useContext(PatientManagerContext);

/**
 * Export the context provider directly so that our UI tests can easily
 * wrap a component with a fake patient manager;
 */
export const StaticPatientManagerProvider = PatientManagerContext.Provider;
