import { useEffect } from "react";

import { Log } from "@accurx/shared";
import { Redirect, Route, Switch } from "react-router";

import { ROUTES_COMPOSE } from "./Routes.constants";
import { ComposePage } from "./compose/ComposePage";
import { NativeSubscriptionsProvider } from "./compose/native/NativeSubscriptionsProvider";
import { ConversationPage } from "./conversation/ConversationPage";

const RedirectedNotFoundRoutes = () => {
    useEffect(() => {
        Log.error(
            "ComposeRoutes: attempt to visit a page that does not exist. Redirecting away",
            {
                tags: {
                    product: "Compose",
                },
            },
        );
    }, []);

    return <Redirect to="/" />;
};

export const ComposeRoutes = () => {
    return (
        <NativeSubscriptionsProvider>
            <Switch>
                <Route path={ROUTES_COMPOSE.compose} exact>
                    <ComposePage />
                </Route>
                <Route path={ROUTES_COMPOSE.conversation} exact>
                    <ConversationPage />
                </Route>
                {/* redirect away from paths that don't match the route */}
                <Route path="*">
                    <RedirectedNotFoundRoutes />
                </Route>
            </Switch>
        </NativeSubscriptionsProvider>
    );
};
