import { QuestionTemplateType } from "@accurx/api/florey-builder";

import { QuestionLevel } from "../types/data.types";

export enum MeasurementValues {
    Height = "height",
    Weight = "weight",
}

/**
 *
 * @param measurementValue string from the value field on the radio input
 * @returns correctly formatted integer enum QuestionTemplateType required by the API
 */
export const getQuestionTemplateType = (
    measurementValue: string,
): QuestionTemplateType | string => {
    switch (measurementValue) {
        case MeasurementValues.Height:
            return QuestionTemplateType.Height;
        case MeasurementValues.Weight:
            return QuestionTemplateType.Weight;
        default:
            return "";
    }
};

export type HandleCancelMeasurementTypeSelectorCard = {
    questionLevel: QuestionLevel;
};
