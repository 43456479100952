import React from "react";

export const OutcomeLoadingContext = React.createContext<{
    isLoading: boolean;
    setIsLoading: (val: boolean) => void;
} | null>(null);

/** Simple context to manage loading state of all outcome label buttons simultaneously */
export const useOutcomeLoadingContext = () => {
    const context = React.useContext(OutcomeLoadingContext);

    if (context === null) {
        throw new Error(
            "useOutcomeLoadingContext must be used inside a <OutcomeLoadingContext.Provider>",
        );
    }

    return context;
};
