import { useEffect, useState } from "react";

import * as UI from "@accurx/design";
import {
    FormFieldFeedback,
    Input,
    SelectItem,
    SingleSelect,
} from "@accurx/inbox-design-library";
import {
    addDays,
    format,
    isBefore,
    isToday,
    isValid,
    startOfDay,
    startOfToday,
} from "date-fns";
import { useAvailableHourOptions } from "domains/compose/hooks/useAvailableHourOptions";

import { DEFAULT_TIME } from "../../constants/constants";
import { ScheduleSendDate } from "../../types/ScheduleSend.types";
import { getSelectedDateTimeUK } from "../../utils/getSelectedDateTimeUK";
import {
    StyledDateInputContainer,
    StyledLabelContainer,
    StyledSelectContainer,
} from "./ScheduleSendCustomDate.styles";

type SpecificDateProps = {
    onChangeValue: (value: ScheduleSendDate | null) => void;
    dateOverDaysInAdvanceError?: string;
    daysInAdvance: number;
};

export const SpecificDate = ({
    onChangeValue,
    dateOverDaysInAdvanceError,
    daysInAdvance,
}: SpecificDateProps) => {
    const today = startOfToday();
    const daysInAdvanceAfterToday = format(
        addDays(startOfToday(), daysInAdvance),
        "yyyy-MM-dd",
    );

    const [time, setTime] = useState(DEFAULT_TIME);
    const [date, setDate] = useState(format(today, "yyyy-MM-dd"));

    const availableTimeOptions = useAvailableHourOptions({
        isToday: isToday(new Date(date)),
    });

    const isInvalidDate =
        !isValid(new Date(date)) || isBefore(startOfDay(new Date(date)), today);

    useEffect(() => {
        if (isInvalidDate) {
            onChangeValue(null);
            return;
        }

        const sendAtDateTime = getSelectedDateTimeUK({
            type: "calendar",
            specificDate: date,
            specificTime: time,
        });

        onChangeValue({
            type: "calendar",
            sendAtDateTime: sendAtDateTime ?? "",
        });
    }, [isInvalidDate, date, time, onChangeValue]);

    useEffect(() => {
        // Reset the selected time if the previous time is no longer available
        if (
            !availableTimeOptions.some(({ value }) => value === time) &&
            availableTimeOptions[0]?.value
        ) {
            setTime(availableTimeOptions[0].value);
        }
    }, [availableTimeOptions, time]);

    return (
        <UI.Flex gap="1" flexDirection="column">
            <UI.Flex flexDirection="column">
                <UI.Flex gap="1" alignItems="center">
                    <StyledLabelContainer>
                        <UI.Text
                            as="label"
                            variant="preview"
                            props={{ htmlFor: "on-specific-date" }}
                            skinny
                        >
                            On
                        </UI.Text>
                    </StyledLabelContainer>
                    <StyledDateInputContainer>
                        <Input
                            id="on-specific-date"
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value);
                            }}
                            max={daysInAdvanceAfterToday}
                            min={format(today, "yyyy-MM-dd")}
                            type="date"
                            aria-invalid={
                                !!dateOverDaysInAdvanceError || isInvalidDate
                            }
                            aria-describedby={
                                dateOverDaysInAdvanceError
                                    ? "overOneYearError"
                                    : "invalidDateError"
                            }
                        />
                    </StyledDateInputContainer>
                </UI.Flex>
                {isInvalidDate && (
                    <FormFieldFeedback
                        id="invalidDateError"
                        text="Enter a valid date to schedule this message"
                        variant="error"
                    />
                )}
                {!!dateOverDaysInAdvanceError && (
                    <FormFieldFeedback
                        id="overOneYearError"
                        text={dateOverDaysInAdvanceError}
                        variant="error"
                    />
                )}
            </UI.Flex>
            <UI.Flex gap="1" alignItems="center">
                <StyledLabelContainer>
                    <UI.Text
                        as="label"
                        variant="preview"
                        props={{ htmlFor: "specific-date-time" }}
                        skinny
                    >
                        At
                    </UI.Text>
                </StyledLabelContainer>

                <StyledSelectContainer width="95px">
                    <SingleSelect
                        id="specific-date-time"
                        onValueChange={(selectedTime: string) =>
                            setTime(selectedTime)
                        }
                        value={time}
                        name="specific-date-time"
                    >
                        {availableTimeOptions.map((option) => (
                            <SelectItem key={option.label} value={option.value}>
                                {option.label}
                            </SelectItem>
                        ))}
                    </SingleSelect>
                </StyledSelectContainer>
            </UI.Flex>
        </UI.Flex>
    );
};
