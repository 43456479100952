import { PatientThreadLabel } from "@accurx/api/ticket";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { mapWriteToRecordState } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapWriteToRecordState";
import { LabelTagItem } from "domains/concierge/types";

/**
 * Maps ticket label to conversation label tag
 *
 * @param threadItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapLabelTagItem(
    threadItem: PatientThreadLabel,
): LabelTagItem | undefined {
    if (!threadItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    if (!threadItem.message.createdByUserId) {
        throw new Error(
            "Property createdByUserId not present in LabelTag item, but it is required",
        );
    }

    if (!threadItem.message.labelText) {
        throw new Error(
            "Property labelText not present in LabelTag item, but it is required",
        );
    }

    return {
        ...mapBaseConversationItem(threadItem),
        contentType: "LabelTag",
        createdBy: { type: "User", id: threadItem.message.createdByUserId },
        label: threadItem.message.labelText,
        saveToRecordState: mapWriteToRecordState(
            threadItem.message.writeToRecordState,
        ),
    };
}
