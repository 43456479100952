import { AnyAction } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ThunkMiddleware } from "redux-thunk";

import { ConciergeMeta } from "../types/ConciergeMeta";

export const meta: (
    meta: ConciergeMeta,
) => ThunkMiddleware<ConciergeState, AnyAction> =
    (meta) => () => (next) => (action) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        action.meta = meta;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return next(action);
    };
