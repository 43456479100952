export const ROUTES_INBOX = {
    MyInbox: "/inbox/w/:workspaceId/my",
    MyInboxSent: "/inbox/w/:workspaceId/my/sent",
    MyInboxScheduled: "/inbox/w/:workspaceId/my/scheduled",
    Colleagues: "/inbox/w/:workspaceId/colleagues/:colleagueId",
    Teams: "/inbox/w/:workspaceId/teams/:teamId",
    All: "/inbox/w/:workspaceId/all",
    AllSent: "/inbox/w/:workspaceId/all/sent",
    AllScheduled: "/inbox/w/:workspaceId/all/scheduled",
    AllFailed: "/inbox/w/:workspaceId/all/failed",
    AllPatientResponses: "/inbox/w/:workspaceId/all/patient-responses",
    AllPatientTriages: "/inbox/w/:workspaceId/all/patient-triages",
    Shared: "/inbox/w/:workspaceId/shared/:teamId",
    Questionnaires: "/inbox/w/:workspaceId/questionnaires/:teamId",
    UnassignedAdmin: "/inbox/w/:workspaceId/unassigned/:teamId/admin",
    UnassignedMedical: "/inbox/w/:workspaceId/unassigned/:teamId/medical",
    UnassignedSelfReferral:
        "/inbox/w/:workspaceId/unassigned/:teamId/self-referral",
    Unassigned: "/inbox/w/:workspaceId/unassigned/:teamId",
    Patient: "/inbox/w/:workspaceId/patient",
    CareProvidersMyInbox: "/inbox/w/:workspaceId/care-providers/my",
    CareProvidersAll: "/inbox/w/:workspaceId/care-providers/all",
} as const;
