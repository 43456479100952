import { useEffect } from "react";

import {
    NativeTransport,
    NativeTransportEventListener,
    useNativeTransport,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { z } from "zod";

export const useNativeResetSelfBookConfiguration = (onEvent: () => void) => {
    const transport = useNativeTransport();

    useEffect(() => {
        if (!transport) return;

        const unsubscribe = subscribe({
            transport,
            type: "SubscribeResetSelfBookConfiguration",
            onEvent: onEvent,
        });

        return () => unsubscribe();
    }, [transport, onEvent]);
};

const NativeToWebMessageSchema = z
    .object({
        requestType: z.string(),
        requestId: z.string(),
    })
    .passthrough();

const subscribe = ({
    transport,
    type,
    onEvent,
}: {
    transport: NativeTransport;
    type: string;
    onEvent: () => void;
}) => {
    const eventHandler: NativeTransportEventListener = (message) => {
        const messageParse = NativeToWebMessageSchema.safeParse(message);
        if (!messageParse.success) {
            return;
        }

        const event = messageParse.data;
        if (event.requestType !== type) {
            return;
        }

        onEvent();
    };

    let unsubscribe: () => void = () => undefined;

    try {
        unsubscribe = transport.addEventListener(eventHandler);
    } catch (err) {
        Log.error(err as Error);
    }

    return unsubscribe;
};
