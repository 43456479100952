import React from "react";

import { Organisation } from "@accurx/auth";
import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import {
    isMyWorkspace,
    supportsMultipleWorkspaces,
} from "@accurx/workspace-management";
import { Link, generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";

import { WorkspaceCard } from "../../WorkspaceCard/WorkspaceCard";
import { StyledUnderLineText } from "./WorkspaceMemberCard.styles";

export const WorkspaceMemberCard = ({
    workspace,
}: {
    workspace: Organisation;
}) => {
    const history = useHistory();

    const { selectedOrgId, setSelectedOrgId } = useSelectedOrganisation();

    const defaultWorkspaceName = supportsMultipleWorkspaces(workspace)
        ? workspace.organisationNodeName
        : undefined;

    const userWorkspaceRole = workspace.settings.isAdminUser
        ? "Admin"
        : "Member";

    const isCurrentWorkspace = (orgId: number) => {
        return orgId === selectedOrgId;
    };

    const workspaceName = isMyWorkspace(workspace)
        ? "My Workspace"
        : workspace.organisationName;

    const handleSwitchWorkspace = (
        event: React.MouseEvent<HTMLAnchorElement>,
        workspaceId: number,
    ) => {
        event.preventDefault();
        setSelectedOrgId(workspaceId);
        history.push(ROUTES.home);
    };

    return (
        <WorkspaceCard
            workspaceName={workspaceName}
            defaultWorkspaceName={defaultWorkspaceName}
        >
            <Text skinny>{userWorkspaceRole}</Text>
            <Popover>
                <PopoverTrigger>
                    <StyledUnderLineText colour="blue" skinny>
                        Actions
                        <Icon
                            colour="blue"
                            name="Arrow"
                            rotation="down"
                            size={2}
                            theme="Line"
                        />
                    </StyledUnderLineText>
                </PopoverTrigger>
                <PopoverContent align="center" side="bottom">
                    {!isCurrentWorkspace(workspace.orgId) && (
                        <PopoverItem>
                            <Link
                                to={ROUTES.home}
                                onClick={(e) =>
                                    handleSwitchWorkspace(e, workspace.orgId)
                                }
                            >
                                <Text skinny>Switch to workspace</Text>
                            </Link>
                        </PopoverItem>
                    )}
                    <PopoverItem>
                        <Link
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceGeneralSettings,
                                {
                                    workspaceId: workspace.orgId,
                                },
                            )}
                        >
                            <Text skinny>Workspace settings</Text>
                        </Link>
                    </PopoverItem>
                </PopoverContent>
            </Popover>
        </WorkspaceCard>
    );
};
