import { Reducer } from "@reduxjs/toolkit";
import {
    NativeFetchError,
    NativeFetchUnexpectedError,
    convertNativeErrorMessageToError,
} from "domains/native/errors";
import { Action, QueryResult } from "domains/native/types";
import { ZodSchema, ZodTypeDef } from "zod";

type ReducerDefinition<TOutput, TInput> = {
    name: string;
    schema: ZodSchema<TOutput, ZodTypeDef, TInput>;
    initialState?: QueryResult<TOutput, NativeFetchError>;
};

/**
 * This utility creates a Redux reducer for a Native subscription
 * based on the subscription name and a Zod schema.
 *
 * The Zod schema defines the type of data that we expect and may also
 * include a transform step that produces the final action payloads.
 */
export const createSubscriptionReducer =
    <TOutput, TInput>({
        name,
        schema,
        initialState = { status: "loading" },
    }: ReducerDefinition<TOutput, TInput>): Reducer<
        QueryResult<TOutput, NativeFetchError>,
        Action
    > =>
    (state = initialState, action: Action) => {
        if (action.type !== name) {
            return state;
        }

        if (!action.payload.success) {
            return {
                status: "error",
                error: convertNativeErrorMessageToError(action.payload.error),
            };
        }

        const parseResult = schema.safeParse(action.payload.data);

        if (!parseResult.success) {
            return {
                status: "error",
                error: new NativeFetchUnexpectedError(
                    "ClientZodParseFailure",
                    `Invalid payload for ${name}`,
                    parseResult.error,
                ),
            };
        }

        return {
            status: "success",
            data: parseResult.data,
        };
    };
