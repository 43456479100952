import { FeatureName } from "@accurx/auth";
import { useDispatch } from "react-redux";

import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { useIsFeatureEnabled } from "store/hooks";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

export const NewConversationOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const dispatch = useDispatch();
    const saveToStore = useSavePatientSearchToStore();

    const isFeatureEnabled = useIsFeatureEnabled(FeatureName.PatientThreadSMS);

    if (!isFeatureEnabled) {
        return null;
    }

    const isDeceasedPatient = searchResult.searchedResult.patient.deceased;

    return (
        <ContactOption
            onClick={() => {
                saveToStore(searchResult);
                dispatch(actionCreators.selectSMSProductType());
                onClick();
            }}
            disabled={isDeceasedPatient}
            iconName="Send"
            text="Message patient"
        />
    );
};
