import { syncedCharLength } from "shared/MessageHelper";

export const FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE = "3MB";

export const FILE_UPLOAD_MAX_SIZE_BYTES = 3145728;

export const FILE_UPLOAD_ALLOWED_EXTENSIONS = [
    "jpg",
    "jpeg",
    "tiff",
    "tiff2",
    "tif",
    "png",
    "rtf",
    "pdf",
    "doc",
    "docx",
];
export const FILE_UPLOAD_ACCEPT_FORMATS =
    "image/jpeg,image/png,image/gif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const FILE_UPLOAD_MAX_FILES = 1;

// The max character count allowed when composing a message
export const MAX_CHAR_COUNT = syncedCharLength.maxMessageCharCount;
