import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const SubHeading = styled(Text)`
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const AlreadySharedParagraph = styled(Text)`
    margin: ${Tokens.SIZES[2]};
    background: ${Tokens.COLOURS.primary.blue[10]};
    padding: ${Tokens.SIZES[2]};
    border: 1px solid ${Tokens.COLOURS.primary.blue[25]};
    border-radius: ${Tokens.SIZES[0.5]};
`;

export const TooltipTriggerContent = styled(Text).attrs(() => ({ as: "span" }))`
    text-decoration: underline;
    color: ${Tokens.COLOURS.primary.blue[100]};
`;
