import { TextArea } from "domains/compose/components/Textarea";
import { useCompose } from "domains/compose/context";

export const MessageHeader = () => {
    const { state } = useCompose();

    if (state.contactDetails.method !== "Email") {
        return null;
    }

    return (
        <TextArea.UneditableText>
            Please DO NOT reply to this email address as it will NOT be
            delivered to your healthcare team.
        </TextArea.UneditableText>
    );
};
