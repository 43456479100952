import { ReactNode } from "react";

import { Action, Reducer } from "redux";

import { SELECT_PRODUCT } from "../selectProduct/SelectProductActions";
import {
    FILE_DATA_RECEIVED,
    FILE_REMOVED,
    FILE_UPLOAD_FINISHED,
    FILE_UPLOAD_STARTED,
    KnownAction,
    RESET_FILE_UPLOAD,
} from "./FileUploadActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type FileUploadState = {
    files: StoredFileWithId[];
    errors: string[] | ReactNode[] | null;
    isUploading: boolean;
};

/**
 * Since we shouldn't store non-serializable objects
 * onto the store, we just save the properties that we need for UI
 */
export type StoredFile = {
    name: string;
    size: number;
    previewUrl: string;
};

export type StoredFileWithId = {
    file: StoredFile;
    id: string;
    isFromTemplate?: boolean; //set to true only when attachment is added from template
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: FileUploadState = {
    files: [],
    errors: [],
    isUploading: false,
};

export const reducer: Reducer<FileUploadState> = (
    state = initialState,
    incomingAction: Action,
): FileUploadState => {
    // Make sure we reset the state on product selection change
    if (incomingAction.type === SELECT_PRODUCT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case FILE_UPLOAD_STARTED:
            return {
                ...state,
                isUploading: true,
            };

        case FILE_UPLOAD_FINISHED:
            return {
                ...state,
                isUploading: false,
                errors:
                    action.error != null && action.error.trim() !== ""
                        ? [action.error]
                        : action.file === null
                        ? ["An error has occured, please try again."]
                        : null,
                files:
                    action.file !== null
                        ? state.files.concat(action.file)
                        : state.files,
            };

        case FILE_DATA_RECEIVED:
            return {
                ...state,
                files: action.files,
            };

        case FILE_REMOVED:
            return {
                ...state,
                files: state.files.filter((f) => f.id !== action.fileId),
            };

        case RESET_FILE_UPLOAD:
            return initialState;
    }
    return state;
};
