import isNil from "lodash/isNil";

import { PatientThreadUserGroup } from "shared/concierge/conversations/tickets/types/dto.types";
import { TeamSummary } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

/**
 * Maps from a Ticket User Group to a Team Summary
 * @param dto the ticket user group
 * @returns mapped version to Team Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserGroupToTeamSummary = (
    dto: PatientThreadUserGroup,
): TeamSummary | undefined => {
    if (isNil(dto.id) || dto.id.length === 0) {
        return undefined;
    }

    return { id: dto.id, displayName: dto.name || dto.id };
};
