import { useEffect, useState } from "react";

import debounce from "lodash/debounce";

const isClient = typeof window === "object";

type WindowSize = {
    height: number | undefined;
    width: number | undefined;
};

const getSize = (): WindowSize => {
    return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
    };
};

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getSize());
        };
        const debounced = debounce(handleResize, 300);

        window.addEventListener("resize", debounced);

        return () => {
            debounced.cancel();
            window.removeEventListener("resize", debounced);
        };
    }, []);

    return windowSize;
};
