import { useState } from "react";

import { useErrorSummary } from "@accurx/design";
import { Log } from "@accurx/shared";
import { containsAbuseWord } from "@accurx/shared/dist/AbuseWordsHelper";
import { z } from "zod";

import {
    DESCRIPTION_ABUSIVE_LANGUAGE_ERROR,
    DESCRIPTION_CHAR_LIMIT_ERROR,
    DESCRIPTION_MIN_CHAR_ERROR,
    NAME_ABUSIVE_LANGUAGE_ERROR,
    NameError,
    WORKSPACE_NAME_CHAR_LIMIT_ERROR,
    WORKSPACE_NAME_MIN_CHAR_ERROR,
    WORKSPACE_SPECIALTY_ERROR,
} from "./Errors";
import {
    WORKSPACE_DESCRIPTION_CHAR_LIMIT,
    WORKSPACE_DESCRIPTION_ID,
    WORKSPACE_DESCRIPTION_MIN_CHAR,
    WORKSPACE_NAME_CHAR_LIMIT,
    WORKSPACE_NAME_ID,
    WORKSPACE_NAME_MIN_CHAR,
    WORKSPACE_SPECIALTY_ID,
} from "./constants";

const isNotAbusiveLanguage = (val: string) => {
    const isAbusive = containsAbuseWord(val);
    if (isAbusive) {
        Log.warn(
            `User attempted to create a workspace with abusive language: ${val}`,
        );
    }
    return !isAbusive;
};

const CreateWorkspaceSchema = z.object({
    [WORKSPACE_NAME_ID]: z
        .string()
        .min(WORKSPACE_NAME_MIN_CHAR, {
            message: WORKSPACE_NAME_MIN_CHAR_ERROR.summaryMessage,
        })
        .max(WORKSPACE_NAME_CHAR_LIMIT, {
            message: WORKSPACE_NAME_CHAR_LIMIT_ERROR.summaryMessage,
        })
        .refine(isNotAbusiveLanguage, {
            message: NAME_ABUSIVE_LANGUAGE_ERROR,
        }),
    [WORKSPACE_DESCRIPTION_ID]: z
        .string()
        .min(WORKSPACE_DESCRIPTION_MIN_CHAR, {
            message: DESCRIPTION_MIN_CHAR_ERROR,
        })
        .max(WORKSPACE_DESCRIPTION_CHAR_LIMIT, {
            message: DESCRIPTION_CHAR_LIMIT_ERROR,
        })
        .refine(isNotAbusiveLanguage, {
            message: DESCRIPTION_ABUSIVE_LANGUAGE_ERROR,
        })
        .optional()
        .or(z.literal("")),
    [WORKSPACE_SPECIALTY_ID]: z.number({
        required_error: WORKSPACE_SPECIALTY_ERROR,
    }),
});

export const useValidateForm = () => {
    // Handling form validation
    const { addError, clearAllErrors } = useErrorSummary();
    // Need to set the errors manually because the type of the error returned by `errors` in `useErrorSummary` doesn't match the type accepted by `FormFieldInput`
    const [nameError, setNameError] = useState<NameError | null>(null);
    const [descriptionError, setDescriptionError] = useState<string | null>(
        null,
    );
    const [specialtyError, setSpecialtyError] = useState<string | null>(null);

    const validateForm = ({
        name,
        description,
        specialty,
    }: {
        name: string;
        description: string;
        specialty?: number;
    }) => {
        clearAllErrors();
        setNameError(null);
        setDescriptionError(null);
        setSpecialtyError(null);

        const result = CreateWorkspaceSchema.safeParse({
            [WORKSPACE_NAME_ID]: name.trim(),
            [WORKSPACE_DESCRIPTION_ID]: description.trim(),
            [WORKSPACE_SPECIALTY_ID]: specialty,
        });

        if (result.success) {
            return [];
        }

        return Object.entries(result.error.flatten().fieldErrors).map(
            ([field, [error]]) => {
                switch (field) {
                    case WORKSPACE_NAME_ID:
                        setNameError({
                            detailMessage: error,
                            summaryMessage: error,
                        });
                        break;
                    case WORKSPACE_DESCRIPTION_ID:
                        setDescriptionError(error);
                        break;
                    case WORKSPACE_SPECIALTY_ID:
                        setSpecialtyError(error);
                        break;
                    default:
                        break;
                }
                addError({ id: field, body: error });
                return error;
            },
        );
    };

    return {
        validateForm,
        nameError,
        descriptionError,
        specialtyError,
        // The following should only be used if you need to manually display a form field error
        // e.g. when a field-specific error such as 'duplicate workspace name' is returned from the backend
        manuallyAddErrorSummary: addError,
        manuallySetNameError: setNameError,
    };
};
