import { Table, Tokens } from "@accurx/design";
import styled from "styled-components";

const Header = styled(Table.Header)`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

const SortableHeader = styled.div`
    padding: none;
    margin: -5px;
`;

const SortButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px ${Tokens.COLOURS.greyscale.white};
    background-color: transparent;
    width: 100%;

    // Smaller padding than non-sortable columns because it needs space for focus state

    &:focus {
        border-color: ${Tokens.COLOURS.greyscale.zinc};
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.blue[10]};
    }

    &:hover:not(:focus) {
        border-color: ${Tokens.COLOURS.primary.blue[10]};
    }
`;

export const StyledTable = {
    Header,
    SortableHeader,
    SortButton,
};
