import { IdType, PatientExternalIdDto } from "@accurx/api/ticket";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { PatientExternalId } from "domains/concierge/types";

const mapPatientExternalIdToPatientSummaryExternalId = (
    dto: PatientExternalIdDto,
): PatientExternalId | undefined => {
    if (dto.type === IdType.NhsNumber) {
        return {
            type: "NhsNumber",
            value: dto.value,
        };
    }

    if (dto.type === IdType.Emis) {
        return {
            type: "Emis",
            value: dto.value,
        };
    }

    if (dto.type === IdType.SystmOne) {
        return {
            type: "SystmOne",
            value: dto.value,
        };
    }

    if (dto.type === IdType.Vision) {
        return {
            type: "Vision",
            value: dto.value,
        };
    }

    // There are a few more external ID types that we don't currently map:
    // - None
    // - Dummy
};

export const mapTicketPatientExternalIdsToPatientSummaryExternalIds = (
    dto: PatientExternalIdDto[] | null | undefined,
): PatientExternalId[] => {
    if (!dto) {
        return [];
    }

    return dto
        .map(mapPatientExternalIdToPatientSummaryExternalId)
        .filter(isInstance);
};
