import { ComponentProps } from "react";

import * as UI from "@accurx/design";
import { NavLink } from "@accurx/navigation";
import { Link, LinkProps } from "domains/inbox/components/Link/Link";
import { UnreadCount } from "domains/inbox/components/UnreadCount/UnreadCount";

const { COLOURS } = UI.Tokens;

type FolderLinkProps = LinkProps & {
    text: string;
    icon?: ComponentProps<typeof UI.Icon>;
    unreadCount?: number;
    size?: "large" | "small";
    isActive?: boolean;
    color?: string;
};

export const FolderLink = ({
    text,
    icon,
    unreadCount,
    size,
    isActive,
    color,
    ...linkProps
}: FolderLinkProps) => {
    return (
        <NavLink
            size={size ?? "small"}
            color={color ?? COLOURS.greyscale.white}
            isActive={!!isActive}
            aria-current={isActive ? "page" : false}
            asChild
        >
            <Link {...linkProps}>
                {!!icon && (
                    <UI.Item>
                        <UI.Icon colour="white" size={3} {...icon} />
                    </UI.Item>
                )}
                <NavLink.Text>{text}</NavLink.Text>
                {!!unreadCount && (
                    <UI.Item>
                        <UnreadCount count={unreadCount} />
                    </UI.Item>
                )}
            </Link>
        </NavLink>
    );
};
