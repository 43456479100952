import { useFeatureFlag } from "@accurx/auth";

export const useCanUseQuestionnaires = () => {
    const isWebSingleSendFloreysEnabled = useFeatureFlag(
        "WebSingleSendFloreys",
    );

    const isCollaborativeWebInboxEnabled = useFeatureFlag(
        "CollaborativeWebInbox",
    );

    return isWebSingleSendFloreysEnabled && isCollaborativeWebInboxEnabled;
};
