import { FeatureName } from "@accurx/auth";

import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    AcceptableUsePolicyValues,
    hasAcceptedFloreyBuilderUsePolicy,
    setUserAcceptableUsePolicyValue,
} from "./FloreyBuilder.utils";

/**
 * Hook to fetch the Florey Builder's Acceptable Use Policy
 */
export const useHasAcceptedFloreyBuilderUsePolicy = (): boolean => {
    const userId = useAppSelector(({ account }) => account.user?.accuRxUserId);
    if (userId) {
        return hasAcceptedFloreyBuilderUsePolicy(userId);
    }
    return false;
};

/**
 * Hook to accept the Florey Builder's Acceptable Use Policy
 */
export const useAcceptFloreyBuilderUsePolicy = (): void => {
    const userId = useAppSelector(({ account }) => account.user?.accuRxUserId);
    if (userId) {
        setUserAcceptableUsePolicyValue(userId, AcceptableUsePolicyValues.True);
    }
};

type QuestionFeatures = {
    isQuestionImageEnabled: boolean;
    isRecordTextEnabled: boolean;
};
/**
 * A hook to separate out our florey builder internal question features
 * so we can release them individually in future
 * @returns object with feature names and boolean values
 */
export const useQuestionFeatures = (): QuestionFeatures => {
    const isGlobalCustomFloreyEnabled = useIsFeatureEnabled(
        FeatureName.CreateGlobalCustomFloreys,
    );
    return {
        isQuestionImageEnabled: !!isGlobalCustomFloreyEnabled,
        isRecordTextEnabled: !!isGlobalCustomFloreyEnabled,
        // In future this will have other conditions added.
    };
};
