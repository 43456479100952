import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPrimaryNavigation = styled.nav`
    background-color: ${Tokens.COLOURS.primary.green[100]};
    color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;

export const StyledNavLinkList = styled.ul`
    align-items: center;
    display: flex;
    gap: ${Tokens.SIZES[2]};
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        gap: ${Tokens.SIZES[1]};
    }
`;
