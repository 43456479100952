import { FilterKey, FiltersValues } from "../../getFilteredClinics";
import { UNSELECTED_FILTER_VALUE } from "./Filters";

export function getSelectedFilterKeys(filters: FiltersValues): FilterKey[] {
    return Object.entries(filters).reduce<FilterKey[]>(
        (filterKeys, [filterKey, filterValue]) => {
            if (filterValue !== UNSELECTED_FILTER_VALUE) {
                filterKeys.push(filterKey as FilterKey);
            }

            return filterKeys;
        },
        [],
    );
}
