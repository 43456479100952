import { Pill } from "@accurx/inbox-design-library";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES_COMPOSE } from "../Routes.constants";
import { Page } from "../components/Page";
import {
    StyledBackButtonWrapper,
    StyledDivider,
} from "./ConversationPage.styles";
import { InboxConversationView } from "./components/InboxConversationView";

export const ConversationPage = () => {
    const { conversationId, workspaceId } = useParams<{
        conversationId: string;
        workspaceId: string;
    }>();
    const history = useHistory();
    return (
        <Page theme="cloud">
            {/* Temporary button to go back to compose */}
            <StyledBackButtonWrapper>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => {
                        history.replace(
                            generatePath(ROUTES_COMPOSE.compose, {
                                workspaceId,
                            }),
                        );
                    }}
                >
                    <Pill.Icon name="Arrow" rotation="left" />
                    <Pill.Text>Go back</Pill.Text>
                </Pill.SecondaryButton>
            </StyledBackButtonWrapper>
            <StyledDivider />
            <InboxConversationView conversationId={conversationId} />
        </Page>
    );
};
