import { api as clinicianMessagingApi } from "domains/concierge/internal/api/clinicianMessaging";
import { api as ticketApi } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import {
    AssigneeSummary,
    Conversation,
    PatientExternalId,
} from "domains/concierge/types";

type Variables = {
    conversation: Conversation;
    assignee: AssigneeSummary;
    note?: {
        patientExternalIds?: PatientExternalId[];
        text: string;
    } | null;
};

export const useUpdateAssigneeMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation<Variables>(
        "UpdateAssignee",
        async ({ conversation, assignee, note }) => {
            switch (conversation.source.system) {
                case "ClinicianMessaging": {
                    if (assignee.type !== "User") {
                        throw new Error(
                            `ClinicianMessaging conversations do not support assigning to ${assignee.type}`,
                        );
                    }
                    const { updates } =
                        await clinicianMessagingApi.updateAssigneeWithNote({
                            workspaceId: workspaceId.toString(),
                            workspaceConversationId:
                                conversation.source.serverId,
                            assigneeId: assignee.id,
                            notes: note?.text,
                        });

                    dispatch(
                        actions.processUpdates({
                            source: "Mutation:UpdateAssignee",
                            ...updates,
                        }),
                    );
                    break;
                }
                case "Ticket": {
                    const { updates: assignmentUpdates } =
                        await ticketApi.updateAssignee({
                            workspaceId,
                            latestToken: conversation.latestToken,
                            ticketIdentity: conversation.source.ticketIdentity,
                            assignee,
                        });

                    dispatch(
                        actions.processUpdates({
                            source: "Mutation:UpdateAssignee",
                            ...assignmentUpdates,
                        }),
                    );
                    if (note && note.text.length > 0) {
                        try {
                            const { updates: noteUpdates } =
                                await ticketApi.addNote({
                                    workspaceId,
                                    ticketIdentity:
                                        conversation.source.ticketIdentity,
                                    patientIds: note.patientExternalIds,
                                    note: {
                                        type: "Text",
                                        text: note.text,
                                    },
                                });
                            dispatch(
                                actions.processUpdates({
                                    source: "Mutation:UpdateAssignee",
                                    ...noteUpdates,
                                }),
                            );
                        } catch (error) {
                            throw new AssignmentError(
                                "AddNoteError",
                                "Error adding note",
                            );
                        }
                    }
                    break;
                }
            }
        },
        options,
    );
};

export class AssignmentError extends Error {
    public readonly name = "AssignmentError";

    constructor(
        public readonly reason: "AssignmentError" | "AddNoteError",
        public readonly message: string,
    ) {
        super(message);
    }
}
