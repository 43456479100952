import { QuestionTemplateType, QuestionType } from "@accurx/api/florey-builder";

export const generateCardKey = (keyBase: string) => {
    return `${keyBase}~card`;
};

export const generateClientIdFromCardKey = (cardKey: string) => {
    return cardKey.split("~")[0];
};

export const mapQuestionTypeToString = (
    questionType: QuestionType,
): string | undefined => {
    switch (questionType) {
        case QuestionType.MultipleChoice:
            return "MultipleChoice";
        case QuestionType.Text:
            return "FreeText";
        case QuestionType.Numbered:
            return "Measurement";
        case QuestionType.Branching:
            return "Branching";
        case QuestionType.ImageAttachment:
            return "ImageAttachment";
        default:
            return undefined;
    }
};

export const getQuestionCardHeading = (
    questionType: QuestionType,
    questionTemplateType: Optional<QuestionTemplateType>,
    questionAllowMultipleAnswers: Optional<boolean>,
): string | undefined => {
    if (questionTemplateType !== undefined) {
        //templated question titles are more specific than the question type so take precedence
        switch (questionTemplateType) {
            case QuestionTemplateType.Weight:
                return "Weight Question";
            case QuestionTemplateType.Height:
                return "Height Question";
        }
    }
    switch (questionType) {
        case QuestionType.Information:
            return "Information";
        case QuestionType.MultipleChoice:
            switch (questionAllowMultipleAnswers) {
                case true:
                    return "Multiple choice question (many answers)";
                case false:
                default:
                    return "Multiple choice question (one answer)";
            }
        case QuestionType.Numbered:
            return "Numbered question";
        case QuestionType.Text:
            return "Free text question";
        // In future other question types will be supported and can be added here.
    }
};
