import { ChangeEvent, useState } from "react";

import {
    Button,
    Icon,
    Input,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { AppSettings } from "@accurx/shared";

const DEFAULT_PR = "web.dev";
const SEARCH_PARAM_KEY = "AccurxPrNumber";

export const updatePrNumber = (prNumber: string): void => {
    // Update search params
    const searchParams = new URLSearchParams(window.location.search);
    if (prNumber) {
        searchParams.set(SEARCH_PARAM_KEY, prNumber);
    } else {
        searchParams.delete(SEARCH_PARAM_KEY);
    }
    window.location.search = searchParams.toString();
};

export const PrNumberPopover = (): JSX.Element | null => {
    // States
    const [open, setOpen] = useState(false);
    const [prNumber] = useState(AppSettings.accurxPrNumber || DEFAULT_PR);
    const [newPrNumber, setNewPrNumber] = useState("");

    const isDevEnvironment =
        window.location.host.includes("localhost") ||
        window.location.host.includes(".dev.accurx.com");

    if (!isDevEnvironment) {
        return null;
    }

    const handleChangePr = (e: ChangeEvent<HTMLInputElement>): void => {
        setNewPrNumber(e.target.value);
    };

    const openGithub = (): void => {
        const url = prNumber !== DEFAULT_PR ? prNumber : "";
        window.open(`https://github.com/Accurx/rosemary/pull/${url}`, "_blank");
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <Button
                    theme="secondary"
                    onClick={() => void 0}
                    icon={{ name: "Hashtag" }}
                    text={prNumber === DEFAULT_PR ? "PR Deploy" : prNumber}
                    dimension="small"
                />
            </PopoverTrigger>
            <PopoverContent side="top" align="center">
                <PopoverItem onClick={openGithub}>
                    <Icon name="AccurxLogo" size={3} />
                    <Text variant="label" skinny>
                        Active PR: {prNumber}
                    </Text>
                </PopoverItem>
                <hr />
                {prNumber !== DEFAULT_PR && (
                    <PopoverItem onClick={() => updatePrNumber("")}>
                        <Icon name="Cross" size={3} />
                        <Text skinny>Delete PR number</Text>
                    </PopoverItem>
                )}
                <PopoverItem>
                    <Input
                        placeholder="eg: 12345"
                        value={newPrNumber}
                        onChange={handleChangePr}
                    />
                    <Button
                        type="button"
                        onClick={() => updatePrNumber(newPrNumber)}
                        text="Update"
                    />
                </PopoverItem>
            </PopoverContent>
        </Popover>
    );
};
