import { FeatureName, Organisation } from "@accurx/auth";

import FlemingApi from "api/FlemingApiClient";
import {
    IJoinOrganisationFormRequest,
    IOrganisation,
    ISetupOrganisationRequest,
    IUserResponse,
    PostUserProfileRequest,
} from "api/FlemingDtos";

import { SettingsUserBase } from "../practices/userSettings/Settings.types";

declare global {
    interface Window {
        accurxUserId: string;
    }
}

// Action Types

export const ACCOUNT_LOGGED_IN = "ACCOUNT_LOGGED_IN";
export const ACCOUNT_LOGGED_OUT = "ACCOUNT_LOGGED_OUT";

export const SELECT_ORG = "SELECT_ORG";
export const CLEAR_ORG = "CLEAR_ORG";
export const ADD_NEW_ORG = "ADD_NEW_ORG";

export const SETUP_ORG_STARTED = "SETUP_ORG_STARTED";
export const SETUP_ORG_FAILED = "SETUP_ORG_FAILED";
export const SETUP_ORG_SUCCESS = "SETUP_ORG_SUCCESS";

export const SUBMIT_JOIN_ORG_FORM_STARTED = "SUBMIT_JOIN_ORG_FORM_STARTED";
export const SUBMIT_JOIN_ORG_FORM_FAILED = "SUBMIT_JOIN_ORG_FORM_FAILED";
export const SUBMIT_JOIN_ORG_FORM_SUCCESS = "SUBMIT_JOIN_ORG_FORM_SUCCESS";

export const POST_USER_PROFILE_STARTED = "POST_USER_PROFILE_STARTED";
export const POST_USER_PROFILE_SUCCESS = "POST_USER_PROFILE_SUCCESS";
export const POST_USER_PROFILE_FAILURE = "POST_USER_PROFILE_FAILURE";

export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_COOKIES_UPDATE_SUCCESS = "USER_COOKIES_UPDATE_SUCCESS";
export const USER_SIGNATURE_UPDATE_SUCCESS = "USER_SIGNATURE_UPDATE_SUCCESS";

export const ORGANISATIONS_UPDATE = "ORGANISATIONS_UPDATE";

export const UPDATE_ORG_USER_FEATURE_FLAG_STATUS =
    "UPDATE_ORG_USER_FEATURE_FLAG_STATUS";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface AccountLoggedInAction {
    type: typeof ACCOUNT_LOGGED_IN;
    user: IUserResponse;
    is2FAed: boolean;
}

interface AccountLoggedOutAction {
    type: typeof ACCOUNT_LOGGED_OUT;
}

interface SetupOrganisationStartedAction {
    type: typeof SETUP_ORG_STARTED;
}

interface SetupOrganisationFailedAction {
    type: typeof SETUP_ORG_FAILED;
}

interface SetupOrganisationSuccessAction {
    type: typeof SETUP_ORG_SUCCESS;
    organisations: IOrganisation[];
    selectedOrganisationId: number | null;
}

interface SubmitJoinOrganisationFormStartedAction {
    type: typeof SUBMIT_JOIN_ORG_FORM_STARTED;
}

interface SubmitJoinOrganisationFormFailedAction {
    type: typeof SUBMIT_JOIN_ORG_FORM_FAILED;
}

interface SubmitJoinOrganisationFormSuccessAction {
    type: typeof SUBMIT_JOIN_ORG_FORM_SUCCESS;
}

export interface SelectOrganisation {
    type: typeof SELECT_ORG;
    orgId: number;
}

export interface AddOrganisation {
    type: typeof ADD_NEW_ORG;
    organisation: IOrganisation;
}

interface ClearOrganisation {
    type: typeof CLEAR_ORG;
}

type PostUserProfileStartedAction = {
    type: typeof POST_USER_PROFILE_STARTED;
};

export type PostUserProfileSuccessAction = {
    type: typeof POST_USER_PROFILE_SUCCESS;
} & PostUserProfileRequest;

type PostUserProfileFailureAction = {
    type: typeof POST_USER_PROFILE_FAILURE;
    error: string;
};

type UpdateOrgUserFeatureFlagStatusActionPayload = {
    organisationId: number;
    featureFlag: FeatureName;
    isEnabled: boolean;
};

type UpdateOrgUserFeatureFlagStatusAction = {
    type: typeof UPDATE_ORG_USER_FEATURE_FLAG_STATUS;
} & UpdateOrgUserFeatureFlagStatusActionPayload;

type UserProfileUpdateSuccessAction = {
    type: typeof USER_PROFILE_UPDATE_SUCCESS;
    userSettingsProfile: SettingsUserBase;
};

type UserCookiesUpdateSuccessAction = {
    type: typeof USER_COOKIES_UPDATE_SUCCESS;
    hasAcceptedCookies: boolean;
};

type UserSignatureUpdateSuccessAction = {
    type: typeof USER_SIGNATURE_UPDATE_SUCCESS;
    messageSignature: string;
};

type OrganisationsUpdateAction = {
    type: typeof ORGANISATIONS_UPDATE;
    organisations: Organisation[];
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | AccountLoggedInAction
    | AccountLoggedOutAction
    | SelectOrganisation
    | ClearOrganisation
    | AddOrganisation
    | SetupOrganisationStartedAction
    | SetupOrganisationSuccessAction
    | SetupOrganisationFailedAction
    | SubmitJoinOrganisationFormStartedAction
    | SubmitJoinOrganisationFormFailedAction
    | SubmitJoinOrganisationFormSuccessAction
    | PostUserProfileStartedAction
    | PostUserProfileSuccessAction
    | PostUserProfileFailureAction
    | UpdateOrgUserFeatureFlagStatusAction
    | UserSignatureUpdateSuccessAction
    | UserProfileUpdateSuccessAction
    | UserCookiesUpdateSuccessAction
    | OrganisationsUpdateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    selectOrganisation: (
        userId: string | undefined,
        organisationId: number,
    ) => ({
        type: SELECT_ORG,
        orgId: organisationId,
    }),

    clearOrganisation: () => ({
        type: CLEAR_ORG,
    }),

    addOrganisation: (organisation: IOrganisation) => ({
        type: ADD_NEW_ORG,
        organisation,
    }),

    setupOrganisation:
        (
            organisationRequestDto: ISetupOrganisationRequest,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess: () => any,
        ): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: SETUP_ORG_STARTED,
            });

            const setupOrgResponse = await FlemingApi.setupOrganisation(
                organisationRequestDto,
            );

            if (setupOrgResponse.success) {
                dispatch({
                    type: SETUP_ORG_SUCCESS,
                    organisations: setupOrgResponse.organisations,
                    selectedOrganisationId: setupOrgResponse.organisationId,
                });
                onSuccess();
            } else {
                dispatch({
                    type: SETUP_ORG_FAILED,
                });
            }
        },

    submitJoinOrganisationRequest:
        (
            organisationRequestDto: IJoinOrganisationFormRequest,
            onSubmit: (success: boolean) => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: SUBMIT_JOIN_ORG_FORM_STARTED,
            });

            const submitJoinOrgFormSuccess =
                await FlemingApi.submitJoinOrganisationRequest(
                    organisationRequestDto,
                );
            if (submitJoinOrgFormSuccess) {
                dispatch({
                    type: SUBMIT_JOIN_ORG_FORM_SUCCESS,
                });
                onSubmit(true);
            } else {
                dispatch({
                    type: SUBMIT_JOIN_ORG_FORM_FAILED,
                });
                onSubmit(false);
            }
        },
    postUserProfile:
        (
            request: PostUserProfileRequest,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess?: () => any,
        ): AppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({
                type: POST_USER_PROFILE_STARTED,
            });
            const response = await FlemingApi.postUserProfile(request);
            if (response.success) {
                const setupOrgResponse = response.result?.organisationResponse;
                if (request.nationalCode !== undefined && setupOrgResponse) {
                    dispatch({
                        type: SETUP_ORG_SUCCESS,
                        organisations: setupOrgResponse.organisations,
                        selectedOrganisationId: setupOrgResponse.organisationId,
                    });
                }
                dispatch({
                    type: POST_USER_PROFILE_SUCCESS,
                    healthcareRole: request.healthcareRole,
                    specialistArea: request.specialistArea,
                    hasAcceptedTermsService: request.hasAcceptedTermsService,
                });
                if (onSuccess) {
                    // Do this after the dispatches to ensure redux is updated before moving forward
                    onSuccess();
                }
                return;
            }

            return dispatch({
                type: POST_USER_PROFILE_FAILURE,
                error:
                    response.error ||
                    "An error occurred when updating the account.",
            });
        },

    updateOrgUserFeatureFlag: (
        payload: UpdateOrgUserFeatureFlagStatusActionPayload,
    ) => ({
        type: UPDATE_ORG_USER_FEATURE_FLAG_STATUS,
        ...payload,
    }),

    updateUserProfile: (
        userSettingsProfile: SettingsUserBase,
    ): UserProfileUpdateSuccessAction => ({
        type: USER_PROFILE_UPDATE_SUCCESS,
        userSettingsProfile,
    }),
    updateMessageSignature: (
        messageSignature: string,
    ): UserSignatureUpdateSuccessAction => ({
        type: USER_SIGNATURE_UPDATE_SUCCESS,
        messageSignature,
    }),
    updateUserCookies: (
        hasAcceptedCookies: boolean,
    ): UserCookiesUpdateSuccessAction => ({
        type: USER_COOKIES_UPDATE_SUCCESS,
        hasAcceptedCookies,
    }),
    updateOrganisations: (
        organisations: Organisation[],
    ): OrganisationsUpdateAction => ({
        type: ORGANISATIONS_UPDATE,
        organisations,
    }),
};
