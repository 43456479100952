import { useEffect } from "react";

import { Transport, useTransport } from "@accurx/transport";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";

import {
    useConciergeDispatch,
    useConciergeGetState,
    useConciergeMeta,
} from "../context";

export type Subscription = (arg: {
    transport: Transport;
    dispatch: Dispatch<AnyAction>;
    getState: () => ConciergeState;
    workspaceId: number;
    currentUserId: string;
}) => () => void;

export const useSignalRSubscription = (subscription: Subscription) => {
    const { workspaceId, userId } = useConciergeMeta();
    const { transport } = useTransport();
    const dispatch = useConciergeDispatch();
    const getState = useConciergeGetState();

    useEffect(() => {
        if (!transport) return;
        const unsubscribe = subscription({
            transport,
            dispatch,
            getState,
            workspaceId,
            currentUserId: userId,
        });
        return () => unsubscribe();
    }, [subscription, dispatch, getState, transport, workspaceId, userId]);
};
