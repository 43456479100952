import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useQuestionnairePreviewLinkQuery } from "domains/compose/hooks/useQuestionnairePreviewLinkQuery";
import { toast } from "react-toastify";

import {
    NoWrap,
    StyledPreviewLink,
    StyledPreviewLinkLoaderContainer,
} from "./QuestionnaireTemplatePreviewLink.styles";

export const QuestionnaireTemplatePreviewLink = ({
    questionnaireId,
}: {
    questionnaireId: string;
}): JSX.Element => {
    const workspace = useCurrentWorkspace();
    const previewLinkQuery = useQuestionnairePreviewLinkQuery({
        questionnaireId,
        workspaceId: workspace.orgId,
    });

    if (previewLinkQuery.status === "loading") {
        return (
            <StyledPreviewLinkLoaderContainer>
                <SkeletonLoader
                    width="150px"
                    backgroundColor={UI.Tokens.COLOURS.greyscale.silver}
                />
            </StyledPreviewLinkLoaderContainer>
        );
    }

    // If the preview URL failed to fetch lets still show the link, because most
    // users probably won't click it anyway, but show an error toast if they do
    // click on it.
    if (previewLinkQuery.status === "error") {
        return (
            <StyledPreviewLink
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    toast(
                        <UI.Feedback
                            title="Preview link for this questionnaire unavailable"
                            colour="error"
                        />,
                    );
                }}
            >
                Preview sample{" "}
                <NoWrap>
                    questionnaire{" "}
                    <UI.Icon
                        name="OpenWindow"
                        size={3}
                        colour={"blue"}
                        title={"Opens in new tab"}
                    />
                </NoWrap>
            </StyledPreviewLink>
        );
    }

    return (
        <StyledPreviewLink href={previewLinkQuery.data.link}>
            Preview sample{" "}
            <NoWrap>
                questionnaire{" "}
                <UI.Icon
                    name="OpenWindow"
                    size={3}
                    colour={"blue"}
                    title={"Opens in new tab"}
                />
            </NoWrap>
        </StyledPreviewLink>
    );
};
