import { Children, Fragment, ReactNode, isValidElement } from "react";

export const CardRowStack = ({ children }: { children: ReactNode }) => {
    const cardRows = Children.map(children, (child) => {
        if (!isValidElement(child)) {
            console.error(
                `Invalid child element: CardRowStack received an unexpected element as a child.
                It expects all direct children to be a valid ReactElement,
                but received ${typeof child}`,
            );
            return null;
        }

        return child;
    })?.filter(Boolean);

    return (
        <div>
            {cardRows &&
                cardRows.map((row, i) => {
                    const isNotLast = i < cardRows.length - 1;
                    return (
                        <Fragment key={i}>
                            {row}
                            {isNotLast && <hr className="my-3" />}
                        </Fragment>
                    );
                })}
        </div>
    );
};
