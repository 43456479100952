// Fallback inmemory option. This will used if browser doesnt support sessionStorage or access is prevented to use it.
const tempStorage = new Map<string, string | boolean>();

export const getSessionStorageItem = (key: string) => {
    try {
        return window.sessionStorage.getItem(key);
    } catch {
        return tempStorage.get(key);
    }
};

export const setSessionStorageItem = (key: string, value: string): void => {
    try {
        window.sessionStorage.setItem(key, value);
    } catch {
        tempStorage.set(key, value);
    }
};

export const removeSessionStorageItem = (key: string): void => {
    try {
        window.sessionStorage.removeItem(key);
    } catch {
        tempStorage.delete(key);
    }
};
