import { useEffect, useMemo, useState } from "react";

import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import pullAt from "lodash/pullAt";
import remove from "lodash/remove";

export type ItemState = "entering" | "present" | "leaving" | "suspended";

export type Reason = "Reassigned" | "MarkedOpen" | "MarkedDone" | "Unknown";

export type ItemStateWithReason =
    | { current: "entering" | "present" }
    | { current: "leaving" | "suspended"; reason: Reason };

export type ItemWithState = {
    id: string;
    state: ItemState;
};
export const mergeFeeds = (
    originalFeed: ItemWithState[],
    ids: string[],
    currentConversationId?: string,
) => {
    const cached = [...originalFeed];
    const result: ItemWithState[] = [];
    const cachedSet = new Set(cached.map((i) => i.id));
    const idsSet = new Set(ids);

    ids.forEach((id, index) => {
        while (cached.length && !idsSet.has(cached[0].id)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const id = cached[0].id;

            // if ticket is opened we don't want delete it from list till it's not opened
            if (currentConversationId === id) {
                result.push({ state: "suspended", id });
            } else {
                result.push({ state: "leaving", id });
            }
            pullAt(cached, 0);
        }

        if (cachedSet.has(id)) {
            result.push({ state: "present", id });
            remove(cached, (i) => i.id === id);
        } else {
            // Don't consider ids entering if they're added at the end of the existing list.
            const isEntering = index < originalFeed.length;
            result.push({ state: isEntering ? "entering" : "present", id });
        }
    });

    while (cached.length && !idsSet.has(cached[0].id)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id = cached[0].id;
        if (currentConversationId === id) {
            result.push({ state: "suspended", id });
        } else {
            result.push({ state: "leaving", id });
        }
        pullAt(cached, 0);
    }
    return result;
};

export const useUpdatingFeed = (
    ids: string[],
    options: { delay: number; currentConversationId?: string },
) => {
    const [feed, setFeed] = useState<ItemWithState[]>(
        ids.map((id) => ({ state: "present", id })),
    );

    const removeLeavingItems = useMemo(
        () =>
            debounce(() => {
                setFeed((feed) => {
                    const newFeed = feed.filter((i) => i.state !== "leaving");

                    return isEqual(newFeed, feed) ? feed : newFeed;
                });
            }, options.delay),
        [options.delay],
    );

    useEffect(() => {
        setFeed((feed) => mergeFeeds(feed, ids, options.currentConversationId));
        removeLeavingItems();
    }, [ids, removeLeavingItems, options.currentConversationId]);

    useEffect(() => {
        return () => removeLeavingItems.cancel();
    }, [removeLeavingItems]);

    return { items: feed };
};
