import { Log, httpClient } from "@accurx/shared";
import isBefore from "date-fns/isBefore";
import { Unsubscribable, from } from "rxjs";
import { distinctUntilKeyChanged, filter, first } from "rxjs/operators";

import { ConnectionStateNew } from "shared/hubClient/ConnectionState";
import { HubClient } from "shared/hubClient/HubClient";

import { PollerOptions, Teardown } from "./poller.types";

export const offlinePoller = (
    hubClient: HubClient,
    options: PollerOptions,
): Teardown => {
    let initialized = false;
    let polling = false;
    let isLive = true;
    let unauthorized = false;
    let intervalHandle: ReturnType<typeof setTimeout> | null = null;

    /**
     * This value is set to the last time SignalR connected,
     * or if it is disconnected, null. This allows us to know,
     * when our fetchFn completes, whether we have had a consistent
     * SignalR connection for the whole duration of the request.
     */
    let connectedSince: Date | null = null;

    const subscriptionHandles: Unsubscribable[] = [];

    const teardown = () => {
        isLive = false;
        if (intervalHandle) {
            clearTimeout(intervalHandle);
        }
        subscriptionHandles.forEach((handle) => handle.unsubscribe());
    };

    const syncPollingState = ({ state }: ConnectionStateNew) => {
        connectedSince = state === "Connected" ? new Date() : null;

        // Enable polling if we just entered a disconnected state
        if (state !== "Connected") {
            polling = true;
            const meta = {
                initialized,
                polling,
                connected: !!connectedSince,
                refreshRate: options.refreshRate,
                online: navigator.onLine,
                unauthorized,
            };
            Log.debug(`${options.name}: enabling polling`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onPollingStateChange && options.onPollingStateChange(meta);
        }
    };

    const setupNextRefreshTick = () => {
        if (!isLive) return;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        intervalHandle = setTimeout(onRefreshTick, options.refreshRate);
    };

    const performFetch = async () => {
        const meta = {
            initialized,
            polling,
            connected: !!connectedSince,
            refreshRate: options.refreshRate,
            online: navigator.onLine,
            unauthorized,
        };

        if (!initialized && options.skipInitialFetch) {
            Log.debug(`${options.name}: skipping initial fetch`, {
                tags: { product: "Inbox", ...meta },
            });
            initialized = true;
            return;
        }

        // Track the time the fetch started at. There's a small chance
        // SignalR could disconnect and then reconnect while the fetch is
        // happening (especially with large requests like initialunreaditems).
        // So we need to know not just that SignalR is connected when the fetch
        // completes, but that it has been connected for the whole lifetime
        // of the request.
        //
        // All of this unfortunately doesn't and can't take into account lag
        // on the backend between things happening and SignalR events being pushed.
        const fetchStartedAt = new Date();

        // Try to fetch data:
        // - If the call is successful and we are now back in a connected state then disable polling.
        // - If the call is successful and we're still disconnected then keep polling enabled.
        // - If the call was unsuccessful then keep polling enabled
        try {
            Log.debug(`${options.name}: fetch start`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchStart && options.onFetchStart(meta);

            await options.fetchFn({ isInitialFetch: !initialized });

            Log.debug(`${options.name}: fetch success`, {
                tags: { product: "Inbox", ...meta },
            });
            options.onFetchSuccess && options.onFetchSuccess(meta);

            // If we are back in a connected state,
            // and have been since before the fetchFn began,
            // then we can switch off polling.
            if (connectedSince && isBefore(connectedSince, fetchStartedAt)) {
                Log.debug(`${options.name}: disabling polling`, {
                    tags: { product: "Inbox", ...meta },
                });
                polling = false;
                options.onPollingStateChange &&
                    options.onPollingStateChange({ ...meta, polling });
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (e.statusCode === 401) {
                Log.debug(`${options.name}: 401 unauthorized received`, {
                    tags: {
                        product: "Inbox",
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                        statusCode: e.statusCode,
                        ...meta,
                    },
                    originalException: e,
                });
            } else {
                Log.error(`${options.name}: fetch failed`, {
                    tags: {
                        product: "Inbox",
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                        statusCode: e.statusCode,
                        ...meta,
                    },
                    originalException: e,
                });
            }
            polling = true;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            options.onFetchError && options.onFetchError(e, meta);
        } finally {
            initialized = true;
        }
    };

    const onRefreshTick = async () => {
        const meta = {
            initialized,
            polling,
            connected: !!connectedSince,
            refreshRate: options.refreshRate,
            online: navigator.onLine,
            unauthorized,
        };
        Log.info(`${options.name}: refresh interval`, {
            tags: { product: "Inbox", ...meta },
        });
        options.onRefreshInterval && options.onRefreshInterval(meta);

        if (polling) {
            await performFetch();
        }
        setupNextRefreshTick();
    };

    // Subscribe to connection status changes and synchronise internal state
    // so that we can keep track of wether we should be polling on the next
    // refresh interval.
    const allConnectionEvents = from(
        hubClient.connectionStateSubscriptionNew,
    ).pipe(
        distinctUntilKeyChanged("state"),
        filter((event) => event.state !== "Initialising"),
    );

    const allEventsHandle = allConnectionEvents.subscribe((event) =>
        syncPollingState(event),
    );

    // Subscribe to the first connection event and:
    // 1. Initially fetch data
    // 2. Setup the refresh interval
    // 3. Enable polling if SignalR is disconnected
    const firstEvent = allConnectionEvents.pipe(first());
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const firstEventHandle = firstEvent.subscribe(async () => {
        await performFetch();
        setupNextRefreshTick();
    });

    const unauthorizedHandle = httpClient.unauthorizedObservable.subscribe(
        () => {
            unauthorized = true;
        },
    );

    subscriptionHandles.push(
        allEventsHandle,
        firstEventHandle,
        unauthorizedHandle,
    );

    return teardown;
};
