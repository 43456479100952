import { ChangeEvent, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Button, Card, Feedback, Link, RadioGroup, Text } from "@accurx/design";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { actionCreators } from "app/account/AccountActions";
import { loadUserflow } from "app/account/Userflow";
import { loadAnalytics } from "app/analytics/AnalyticsHelper";
import { useUpdateUserCookiesMutation } from "app/hooks/mutations";
import { useHasAcceptedCookies } from "store/hooks";

import { FeedbackMsg } from "./Settings.types";

type SettingsCookiesProps = {
    hideHeader?: boolean;
};

enum CookiesOptions {
    ACCEPT = "Accept all",
    DECLINE = "Decline optional cookies",
}

const RADIO_OPTIONS = [
    {
        id: "accept",
        label: CookiesOptions.ACCEPT,
        value: CookiesOptions.ACCEPT,
    },
    {
        id: "decline",
        label: CookiesOptions.DECLINE,
        value: CookiesOptions.DECLINE,
    },
];

const EMPTY_FEEDBACK = {
    colour: null,
    msg: "",
};

export const SettingsCookies = ({ hideHeader }: SettingsCookiesProps) => {
    useAccurxWebTitle("Manage cookies");

    const { user } = useCurrentUser();
    const updateUserCookiesMutation = useUpdateUserCookiesMutation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const defaultFeedback: FeedbackMsg = params.get("declined")
        ? {
              colour: "success",
              msg: "Your preferences has been updated.",
          }
        : EMPTY_FEEDBACK;

    // Store
    const dispatch = useDispatch();
    const cookiesStatus = useHasAcceptedCookies();

    // State
    const [cookiesValue, setCookiesValue] = useState<CookiesOptions>(
        cookiesStatus ? CookiesOptions.ACCEPT : CookiesOptions.DECLINE,
    );
    const [feedbackMsg, setFeedbackMsg] = useState(defaultFeedback);

    const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFeedbackMsg(EMPTY_FEEDBACK);
        setCookiesValue(e.target.value as CookiesOptions);
    };

    const handleSave = () => {
        setFeedbackMsg(EMPTY_FEEDBACK);
        const hasAcceptedCookies = cookiesValue === CookiesOptions.ACCEPT;

        updateUserCookiesMutation.mutate(
            { hasAcceptedCookies },
            {
                onSuccess: () => {
                    if (hasAcceptedCookies) {
                        loadAnalytics();

                        if (user.analyticsUserIdentifier) {
                            loadUserflow(user.analyticsUserIdentifier);
                        }

                        dispatch(
                            actionCreators.updateUserCookies(
                                hasAcceptedCookies,
                            ),
                        );
                        setFeedbackMsg({
                            colour: "success",
                            msg: "Your preferences has been updated.",
                        });
                    } else {
                        // Reload the page so that we can deactivate all tracking
                        window.location.href = `${location.pathname}?declined=true`;
                    }
                },
                onError: (error) => {
                    setFeedbackMsg({
                        colour: "error",
                        msg:
                            error.message ||
                            "Something went wrong saving your preferences. Please try again.",
                    });
                },
            },
        );
    };

    return (
        <Card
            header={
                !hideHeader ? (
                    <Text variant="subtitle" as="h2" skinny>
                        Cookies
                    </Text>
                ) : undefined
            }
        >
            <Text>
                {`We'd like to use analytics cookies to give you the best experience possible. Our `}
                <Link href="https://www.accurx.com/cookie-policy" openInNewTab>
                    <LinkText text="Cookie Policy" />
                    <Link.Icon />
                </Link>
                {` provides more details on what cookies we collect and their purpose.`}
            </Text>
            <RadioGroup
                name="cookies-radio"
                onChange={handleCheckedChange}
                checkedValue={cookiesValue}
                radioInputs={RADIO_OPTIONS}
            />
            {feedbackMsg.colour && feedbackMsg.msg && (
                <Feedback
                    title={feedbackMsg.msg}
                    colour={feedbackMsg.colour}
                    props={{ className: "mt-2 mb-3" }}
                />
            )}
            <Button
                type="button"
                text="Save changes"
                className="mt-2"
                onClick={handleSave}
            />
        </Card>
    );
};
