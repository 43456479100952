import { FeatureName } from "@accurx/auth";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import { DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT } from "app/batchMessage/gp/BatchMessage.constant";
import { BatchMessageReducerState } from "app/batchMessage/gp/BatchMessage.reducer";
import {
    BatchType,
    MessageTemplateOwner,
} from "app/batchMessage/gp/BatchMessage.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

const getMessageProps = (batchMessage: BatchMessageReducerState) => {
    const {
        batchType,
        selectedTemplateName,
        uploadedFileAttachmentId,
        isFileAttachmentFromTemplate,
        selectedFloreySaveToRecord,
        selectedTemplateOwner,
        selectedFloreyId,
        selectedFloreyName,
        selectedTemplateCanReply,
        declineSettings,
    } = batchMessage;

    const declineSettingsSnomedCode = declineSettings.snomedConceptId;
    const declineSettingsUsingDefaultMessage =
        declineSettings.confirmationText ===
        DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT;

    const isFlorey = batchType === BatchType.FLOREY;
    const isPresetTemplate =
        selectedTemplateOwner === MessageTemplateOwner.AccuRx;

    if (isFlorey) {
        return {
            conditionId: selectedFloreyId,
            floreyName: selectedFloreyName,
            isAutoSaveEnabled: selectedFloreySaveToRecord,
            isPresetTemplate: isPresetTemplate,
            templateName: null,
            withPatientReplyLink: null,
            withAttachment: null,
            countAttachmentFromTemplate: null,
            declineSettingsSnomedCode,
            declineSettingsUsingDefaultMessage,
        };
    }

    const hasAttachment = !isNaN(parseInt(uploadedFileAttachmentId));

    return {
        conditionId: null,
        floreyName: null,
        isAutoSaveEnabled: null,
        isPresetTemplate: isPresetTemplate,
        templateName:
            isPresetTemplate && !!selectedTemplateName
                ? selectedTemplateName
                : null,
        withPatientReplyLink: selectedTemplateCanReply,
        withAttachment: hasAttachment,
        countAttachmentFromTemplate:
            hasAttachment && isFileAttachmentFromTemplate ? 1 : 0,
        declineSettingsSnomedCode,
        declineSettingsUsingDefaultMessage,
    };
};

type UseTrackBatchPatientMessageSendClickType = {
    trackBatchPatientMessageSendClick: (props: {
        snomedCode: string | null;
        countTotal: number;
        isTrustFlow: boolean;
    }) => void;
};
export const useTrackBatchPatientMessageSendClick =
    (): UseTrackBatchPatientMessageSendClickType => {
        const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
        const meaningfulActionProps = useMeaningfulActionAnalyticsProps();
        const batchMessage = useAppSelector(({ batchMessage }) => batchMessage);

        const messageProps = getMessageProps(batchMessage);

        const isPifuEnabled = useIsFeatureEnabled(
            FeatureName.WebPatientLedFollowUp,
        );
        const nationalCode = useAppSelector(
            ({ account }) =>
                OrganisationHelper.getOrganisation(account)?.nationalCode,
        );

        const messageContainsPIFULink = AnalyticsMapper.messageContainsPIFULink(
            isPifuEnabled,
            nationalCode,
            batchMessage.patientMessage,
        );

        return {
            trackBatchPatientMessageSendClick: (props) =>
                ChainAnalyticsTracker.trackBatchPatientMessageSendClick({
                    ...analyticsLoggedInProps,
                    ...meaningfulActionProps,
                    ...messageProps,
                    ...props,
                    messageSaveToRecord:
                        batchMessage.selectedBatchOptionSaveToRecord,
                    messageType: AnalyticsMapper.getBatchType(
                        batchMessage.batchType,
                        true,
                    ),
                    withPatientInitiatedFollowUpLink: messageContainsPIFULink,
                    appOrigin: null,
                }),
        };
    };
