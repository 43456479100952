import { TwoFactorContext } from "../TwoFactor";

export const getMainContentSuffix = (context: TwoFactorContext): string => {
    switch (context) {
        case TwoFactorContext.PatientList:
            return "patient lists";
        default:
            return "Accurx Web";
    }
};

export const shouldDisplaySkipButton = (context: TwoFactorContext) =>
    context === TwoFactorContext.PostLogin;
