import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Button, Text, Tokens } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { generateFiltersArray } from "../Vaccine.helper";
import { setMyPracticeFilter } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineAllPatientsInvitedFiltersStatusCheckboxes } from "./VaccineAllPatientsInvitedFiltersStatusCheckboxes";
import { VaccineAllPatientsInvitedFiltersUnclearStatusCheckbox } from "./VaccineAllPatientsInvitedFiltersUnclearStatusCheckbox";
import { VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxes } from "./VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxes";

export interface VaccineAllPatientsInvitedFiltersProps {
    numIndividualSelected: number;
    setCurrentPaginationPage: Dispatch<SetStateAction<number>>;
}

export const VaccineAllPatientsInvitedFilters = ({
    numIndividualSelected,
    setCurrentPaginationPage,
}: VaccineAllPatientsInvitedFiltersProps): JSX.Element => {
    const dispatch = useDispatch();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const myPracticeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.myPractice,
        shallowEqual,
    );

    const statusFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.statusFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.statusFilters,
        shallowEqual,
    );

    const firstVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.firstVaccinationTimeFilter ||
            VaccineAllInvitesInitialState.allInvitesFilters
                .firstVaccinationTimeFilter,
        shallowEqual,
    );

    const vaccineTypeFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.vaccineTypeFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.vaccineTypeFilters,
        shallowEqual,
    );

    const unclearStatusFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.unclearStatus ||
            VaccineAllInvitesInitialState.allInvitesFilters.unclearStatus,
        shallowEqual,
    );

    const [myPracticeFilterOpen, setMyPracticeFilterOpen] = useState(false);

    const [localMyPracticeFilter, setLocalMyPracticeFilter] = useState(true);

    const [reduxFilterLoaded, setReduxFilterLoaded] = useState(false);

    useEffect(() => {
        // On first load or load after clicking 'Apply Filter' we sync the local state with the redux state
        if (!reduxFilterLoaded) {
            setLocalMyPracticeFilter(myPracticeFilter);
            setReduxFilterLoaded(true);
        }
    }, [myPracticeFilter, reduxFilterLoaded]);

    const localFilterMatchesRedux = myPracticeFilter === localMyPracticeFilter;

    // region Practice checkbox

    const handleApplyFilter = (): void => {
        setReduxFilterLoaded(false);
        dispatch(setMyPracticeFilter(localMyPracticeFilter));
        setCurrentPaginationPage(0);
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListFiltersAppliedProps =
            {
                ...analyticsLoggedInProps,
                applyFilterSection: "invitingPractice",
                myPracticeFilter: localMyPracticeFilter,
                currentStatusFilters: statusFilters,
                firstVaccinationWeekFilter:
                    firstVaccinationTimeFilter?.displayText,
                vaccineTypeFilters: generateFiltersArray(vaccineTypeFilters),
                unclearStatusFilter: unclearStatusFilter,
            };
        ChainAnalyticsTracker.trackVaccinePatientListFiltersApplied(
            analyticsProps,
        );
    };

    const handleToggleFilter = (): void => {
        setLocalMyPracticeFilter((prevState) => !prevState);
    };

    const handleMyPracticeFilterOpenToggle = (): void => {
        if (myPracticeFilterOpen && !localFilterMatchesRedux) {
            setLocalMyPracticeFilter(myPracticeFilter);
        }
        setMyPracticeFilterOpen((prev) => !prev);
    };

    const renderPracticeCheckbox = (): JSX.Element => {
        return (
            <div
                className="mt-3 pb-3 d-flex flex-column"
                style={{
                    borderBottom: `1px solid ${Tokens.COLOURS.greyscale.stone}`,
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={handleMyPracticeFilterOpenToggle}
                >
                    <Text variant="label" props={{ className: "ml-2 mt-0" }}>
                        Inviting practice
                    </Text>
                    <Button
                        dimension="small"
                        theme="transparent"
                        icon={{
                            name: "Arrow",
                            colour: "zinc",
                            rotation: myPracticeFilterOpen ? "up" : "down",
                            placement: "end",
                        }}
                        text={
                            myPracticeFilter && !myPracticeFilterOpen
                                ? "My practice"
                                : ""
                        }
                        aria-label="open-mypractice-filter"
                    />
                </div>
                {myPracticeFilterOpen && (
                    <>
                        <div className="d-flex ml-2 mt-2 mb-2">
                            <input
                                type="checkbox"
                                id="myPracticeCheckbox"
                                data-testid="myPracticeCheckbox"
                                onChange={handleToggleFilter}
                                checked={localMyPracticeFilter}
                                disabled={numIndividualSelected > 0}
                                className="mr-2 mt-1"
                            />
                            <Text
                                as="label"
                                skinny
                                props={{ htmlFor: "myPracticeCheckbox" }}
                            >
                                My practice
                            </Text>
                        </div>
                        <Button
                            text="Apply filter"
                            onClick={handleApplyFilter}
                            disabled={localFilterMatchesRedux}
                            className="d-block"
                            aria-label="apply-my-practice-filter"
                        />
                    </>
                )}
            </div>
        );
    };

    // endregion Practice checkbox

    return (
        <>
            {renderPracticeCheckbox()}
            <VaccineAllPatientsInvitedFiltersStatusCheckboxes
                numIndividualSelected={numIndividualSelected}
                setCurrentPaginationPage={setCurrentPaginationPage}
            />
            <VaccineAllPatientsInvitedFiltersVaccineTypeCheckboxes
                numIndividualSelected={numIndividualSelected}
                setCurrentPaginationPage={setCurrentPaginationPage}
            />
            <VaccineAllPatientsInvitedFiltersUnclearStatusCheckbox
                numIndividualSelected={numIndividualSelected}
                setCurrentPaginationPage={setCurrentPaginationPage}
            />
        </>
    );
};
