import { Conversation, ConversationItem, SaveToRecordState } from "../types";

const isItemWithSaveToRecordSupport = (
    item: ConversationItem,
): item is Extract<
    ConversationItem,
    { saveToRecordState: SaveToRecordState }
> => {
    return "saveToRecordState" in item;
};

export const getAllSaveToRecordEvents = ({
    conversation,
}: {
    conversation: Conversation;
}) => {
    const itemsWithSaveToRecordSupport = conversation.items.filter(
        isItemWithSaveToRecordSupport,
    );

    return itemsWithSaveToRecordSupport.flatMap((convItem) => {
        return convItem.saveToRecordState.saveEvents;
    });
};
