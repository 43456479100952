import { ReactNode } from "react";

import { Provider } from "react-redux";

import { NativeSubscriptionsContext } from "./context";
import { store } from "./store";

export const NativeSubscriptionsProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <Provider context={NativeSubscriptionsContext} store={store}>
            {children}
        </Provider>
    );
};
