import { useAnalytics } from "@accurx/analytics";
import { DateHelpers } from "@accurx/shared";
import { getWorkspaceActivity } from "domains/inbox/components/ConversationView/components/WorkspaceActivityTooltipProvider/WorkspaceActivityTooltipProvider";

export const useWorkspaceActivityAnalytics = ({
    location,
}: {
    location: "message" | "participantPill";
}) => {
    const track = useAnalytics();
    const trackWorkspaceStaffAvailabilityHover = ({
        workspaceStatus,
    }: {
        workspaceStatus?: ReturnType<typeof getWorkspaceActivity>;
    }) => {
        if (workspaceStatus) {
            track("WorkspaceStaffAvailability Component Hover", {
                countWorkspaceStaffOnline:
                    workspaceStatus.activity?.numberOfUsers ?? 0,
                workspaceStaffOnlineDate: workspaceStatus.activity
                    ? DateHelpers.getDaysAgoText(
                          workspaceStatus.activity.daysSinceOnline,
                      )
                    : "Not online in the past 4 days",
                location,
            });
        }
    };
    return {
        trackWorkspaceStaffAvailabilityHover,
    };
};
