import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    DateFormatOptions,
    DateHelpers,
    IWrappedResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";

type DateString = string;

const useNextWorkingDayForDate = createQueryHook(
    ({
        workspaceId,
        date,
        enabled,
    }: {
        workspaceId: string;
        date: string;
        enabled: boolean;
    }) => ({
        queryKey: ["NextWorkingDay", { workspaceId, date }],
        queryFn: async () => {
            const response: IWrappedResult<DateString> =
                await httpClient.getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "patientmessaging/NextWorkingDay/:workspaceId/getnextworkingday/:date",
                        params: { workspaceId, date },
                    }),
                );
            return returnDataOrThrow(response);
        },
        enabled,
    }),
);

type UseNextWorkingDay = { workspaceId: string; enabled: boolean };

export const useNextWorkingDay = ({
    workspaceId,
    enabled,
}: UseNextWorkingDay) => {
    const today = new Date().toISOString();
    const date = DateHelpers.formatDate(
        today,
        DateFormatOptions.DATE_SHORT_NO_SPACES_REVERSE,
    );

    return useNextWorkingDayForDate({ workspaceId, date, enabled });
};
