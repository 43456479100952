import { Redirect, generatePath } from "react-router";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES, ROUTES_PRIMARY } from "shared/Routes";

export const BatchMessagingRoutesSection = () => {
    const currentWorkspace = useSelectedOrganisation();

    if (!currentWorkspace.selectedOrgId) {
        return <Redirect to={ROUTES.home} />;
    }

    const path = generatePath(ROUTES_PRIMARY.batchMessageTrust, {
        workspaceId: currentWorkspace.selectedOrgId,
    });

    return <Redirect to={path} />;
};
