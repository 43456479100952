import * as UI from "@accurx/design";
import { Tokens } from "@accurx/design";
import { ConversationActions } from "domains/inbox/components/ConversationView/components";
import styled from "styled-components";

export const StyledConversationViewGrid = styled(UI.Grid)`
    height: 100%;
`;

export const StyledGridItemConversationThread = styled(UI.Cell)`
    overflow: hidden;
    position: relative;
    background: ${UI.Tokens.COLOURS.greyscale.cloud};
`;

export const StyledConversationViewLoading = styled(UI.Flex)`
    height: 100vh;
`;

export const StyledReplyContainer = styled(UI.Cell)`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const StyledLiveUserActivityBadgeContainer = styled.div`
    position: absolute;
    z-index: 1;
    top: -${UI.Tokens.SIZES[1.5]};
    left: 50%;
    transform: translate(-50%, -100%);
    max-width: calc(100% - ${Tokens.SIZES[6]});
    width: 100%;
    display: flex;
    justify-content: center;

    [data-radix-popper-content-wrapper] {
        // This is set by default as an inline style to be 'max-content'. This is
        // problematic for when we need text to wrap based on the parent width, rather
        // then a hard-coded max-width value.
        min-width: unset !important;
        max-width: 100%;
    }
`;

export const PatientParticipantContainer = styled(
    ConversationActions.Item,
).attrs({
    $collapsible: true,
})`
    @media (max-width: 1230px) {
        visibility: hidden;
        padding: 0;
    }
`;
