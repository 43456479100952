import React from "react";

import { Button, Ds, Feedback, Spinner, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { getNbsBookingDoseText } from "../Vaccine.helper";
import {
    BookingDetails,
    InviteNote,
    InviteNoteType,
    InviteReminder,
    InviteReminderType,
    InviteStatusType,
    VaccinePatientInvite,
} from "../models/VaccineAllPatientsInvitedDTO";
import {
    NbsBooking,
    NbsData,
    VaccineCourse,
} from "../models/VaccineDeliveryDTO";
import { getPatientStatusInfo } from "../models/VaccineInviteStatus";

export interface VaccineAllPatientsInvitedDetailsBookingDetailsProps {
    updateSearchTerm: (searchInput: string) => void;
    handleToggleAddToNetworkModal: () => void;
    patientDetails: VaccinePatientInvite[];
    patientDetailsLoading: boolean;
    patientDetailsError: string;
    nbsData: NbsData | null;
    selectedCourseTab: VaccineCourse;
}

export const VaccineAllPatientsInvitedDetailsBookingDetails = ({
    updateSearchTerm,
    handleToggleAddToNetworkModal,
    patientDetails,
    patientDetailsLoading,
    patientDetailsError,
    nbsData,
    selectedCourseTab,
}: VaccineAllPatientsInvitedDetailsBookingDetailsProps): JSX.Element => {
    const renderPatientBookingStatusLabel = (
        patient: VaccinePatientInvite,
    ): JSX.Element => {
        const info = getPatientStatusInfo(patient.status, selectedCourseTab);
        return (
            <Text
                as="span"
                data-testid="statusLabel"
                props={{
                    className: `vaccine-coloured-label ${info.colourClass}`,
                }}
            >
                {info.displayText}
            </Text>
        );
    };

    const handleBatchFilterClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
        batchId: string,
    ): void => {
        event.preventDefault();
        if (batchId) updateSearchTerm(`InviteId:${batchId}`);
    };

    const getInviteNoteUserInfo = (note: InviteNote): JSX.Element => {
        return (
            <>
                {note?.createdByUser && (
                    <span> by {note.createdByUser.fullName}</span>
                )}
                {note?.createdByOrg && (
                    <span> from {note.createdByOrg.nationalCode}</span>
                )}
            </>
        );
    };

    const getInviteNoteText = (note: InviteNote): JSX.Element => {
        switch (note.type) {
            case InviteNoteType.PatientDeclined:
                return (
                    <>
                        <Text>
                            Invite declined on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                        </Text>
                        <Text props={{ className: "ml-4" }}>
                            Reason: {note.summary}
                        </Text>
                        {note.details && (
                            <Text props={{ className: "ml-4" }}>
                                Details: {note.details}
                            </Text>
                        )}
                    </>
                );
            case InviteNoteType.UserCancelled:
                return (
                    <>
                        <Text>
                            Invite cancelled on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                            {getInviteNoteUserInfo(note)}
                        </Text>
                    </>
                );
            case InviteNoteType.UserResetInvite:
                return (
                    <>
                        <Text>
                            Invite reset on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                            {getInviteNoteUserInfo(note)}
                        </Text>
                    </>
                );
            case InviteNoteType.UserInvitedToSecond:
                return (
                    <>
                        <Text>
                            Invited to second booking on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                            {getInviteNoteUserInfo(note)}
                        </Text>
                    </>
                );
            case InviteNoteType.UserInvitedToBooster:
                return (
                    <>
                        <Text>
                            Invited to booster booking on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                            {getInviteNoteUserInfo(note)}
                        </Text>
                    </>
                );
            case InviteNoteType.UserAddedPatientToNetwork:
                return (
                    <>
                        {note?.createdAt && (
                            <Text>
                                Invite added to network on{" "}
                                {DateHelpers.formatDate(
                                    note.createdAt,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </Text>
                        )}
                        {note?.details && (
                            <Text props={{ className: "ml-4" }}>
                                {note.details}
                            </Text>
                        )}
                    </>
                );
            case InviteNoteType.UserRemovedPatientFromNetwork:
                return (
                    <>
                        {note?.createdAt && (
                            <Text>
                                Invite removed from network on{" "}
                                {DateHelpers.formatDate(
                                    note.createdAt,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </Text>
                        )}
                        {note?.details && (
                            <Text props={{ className: "ml-4" }}>
                                {note.details}
                            </Text>
                        )}
                    </>
                );
            case InviteNoteType.PatientBooked:
                return (
                    <>
                        <Text>
                            Patient booked themselves in on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                        </Text>
                    </>
                );
            case InviteNoteType.PatientCancelledBooking:
                return (
                    <>
                        <Text>
                            Patient cancelled their booking on{" "}
                            {DateHelpers.formatDate(
                                note.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                        </Text>
                    </>
                );
            case InviteNoteType.UserAsPatientBooked:
                const noteDetails = note?.details?.split(","); // We expect to receive a list of 1 or 2 comma-separates dates here for the booking dates

                return (
                    <>
                        <Text>
                            Patient booked
                            {getInviteNoteUserInfo(note)}
                            <span>
                                {" "}
                                on{" "}
                                {DateHelpers.formatDate(
                                    note.createdAt,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </span>
                        </Text>
                        <Text props={{ className: "ml-4" }}>
                            {noteDetails.length === 1 &&
                                DateHelpers.isISODate(noteDetails[0]) && (
                                    <span>
                                        {" "}
                                        for{" "}
                                        {DateHelpers.formatDate(
                                            noteDetails[0],
                                            DateFormatOptions.DATE_LONG,
                                        )}
                                    </span>
                                )}
                            {noteDetails.length === 2 &&
                                DateHelpers.isISODate(noteDetails[0]) &&
                                DateHelpers.isISODate(noteDetails[1]) && (
                                    <span>
                                        {" "}
                                        for{" "}
                                        {DateHelpers.formatDate(
                                            noteDetails[0],
                                            DateFormatOptions.DATE_LONG,
                                        )}{" "}
                                        and{" "}
                                        {DateHelpers.formatDate(
                                            noteDetails[1],
                                            DateFormatOptions.DATE_LONG,
                                        )}
                                    </span>
                                )}
                        </Text>
                    </>
                );
            case InviteNoteType.UserAsPatientDeclined:
                return (
                    <>
                        <Text>
                            <span>Patient invite declined</span>
                            {getInviteNoteUserInfo(note)}
                            <span>
                                {" "}
                                on{" "}
                                {DateHelpers.formatDate(
                                    note.createdAt,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </span>
                        </Text>
                        <Text props={{ className: "ml-4" }}>
                            Reason: {note.summary}
                        </Text>
                        {note.details && (
                            <Text props={{ className: "ml-4" }}>
                                Details: {note.details}
                            </Text>
                        )}
                    </>
                );
            case InviteNoteType.UserCancelledBooking:
                return (
                    <>
                        <Text>
                            <span>Patient booking cancelled</span>
                            {getInviteNoteUserInfo(note)}
                            <span>
                                {" "}
                                on{" "}
                                {DateHelpers.formatDate(
                                    note.createdAt,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </span>
                        </Text>
                    </>
                );
            default:
                return <></>;
        }
    };

    const getInviteReminderText = (
        reminder: InviteReminder,
    ): JSX.Element | null => {
        switch (reminder.type) {
            case InviteReminderType.AutomatedReminder:
                return (
                    <Text>
                        Automated booking reminder was sent on{" "}
                        {DateHelpers.formatDate(
                            reminder?.createdAt,
                            DateFormatOptions.DATE_SHORT_WITH_SPACES,
                        )}
                    </Text>
                );
            case InviteReminderType.ManualReminder:
                return (
                    <Text>
                        Patient sent booking reminder
                        {reminder?.createdByUser && (
                            <span> by {reminder.createdByUser.fullName}</span>
                        )}
                        {reminder?.createdByOrg && (
                            <span>
                                {" "}
                                from {reminder.createdByOrg.nationalCode}
                            </span>
                        )}
                        <span>
                            {" "}
                            on{" "}
                            {DateHelpers.formatDate(
                                reminder?.createdAt,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                        </span>
                    </Text>
                );
            default:
                return null;
        }
    };

    const renderInviteTopLine = (invite: VaccinePatientInvite): JSX.Element => {
        return (
            <Text>
                {invite.invitedByUser?.fullName ||
                invite.invitedByUser?.email ? ( // Won't get invitedByUser for "not in network" invites
                    <>
                        <Text
                            as="span"
                            variant="link"
                            props={{
                                "data-testid": `batchid${invite.batchId}`,
                                style: { cursor: "pointer" },
                                onClick: (
                                    event: React.MouseEvent<HTMLAnchorElement>,
                                ): void =>
                                    handleBatchFilterClick(
                                        event,
                                        invite.batchId,
                                    ),
                            }}
                        >
                            Uploaded by{" "}
                            {invite.invitedByUser.fullName ||
                                invite.invitedByUser.email}{" "}
                            from {invite.invitedBy?.nationalCode}
                        </Text>
                        <span>
                            {" "}
                            on{" "}
                            {DateHelpers.formatDate(
                                invite.sendTime,
                                DateFormatOptions.DATE_SHORT_WITH_SPACES,
                            )}
                        </span>
                        {renderPatientBookingStatusLabel(invite)}
                    </>
                ) : (
                    <span className="d-flex justify-content-between">
                        <span>
                            <span>
                                Invite by {invite.invitedBy?.nationalCode} on{" "}
                                {DateHelpers.formatDate(
                                    invite.sendTime,
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </span>
                            {renderPatientBookingStatusLabel(invite)}
                        </span>
                        {invite.status === InviteStatusType.NotInNetwork && (
                            <Button
                                text="Invite through your practice"
                                onClick={handleToggleAddToNetworkModal}
                                theme="secondary"
                                dimension="small"
                                icon={{
                                    style: "Fill",
                                    name: "Assign",
                                    colour: "zinc",
                                }}
                            />
                        )}
                    </span>
                )}
            </Text>
        );
    };

    const renderNbsBookingInfo = (nbsBookings: NbsBooking[]): JSX.Element[] => {
        return nbsBookings.map((booking: NbsBooking) => {
            return (
                <Text
                    key={booking.vaccineDose}
                    props={{ className: "font-weight-bold" }}
                >
                    NBS {getNbsBookingDoseText(booking.vaccineDose)} for{" "}
                    {DateHelpers.formatDate(
                        booking?.doseDate,
                        DateFormatOptions.DATE_LONG,
                    )}
                </Text>
            );
        });
    };

    const renderBookingInfo = (invite: VaccinePatientInvite): JSX.Element[] => {
        return (invite.bookings || []).map((booking: BookingDetails) => {
            return (
                <li
                    key={booking.bookingId}
                    className="d-flex align-items-start listitem"
                >
                    <div className="position-relative pb-4">
                        <Text
                            as="span"
                            props={{ className: "font-weight-bold" }}
                        >
                            {DateHelpers.formatDate(
                                booking?.startTime,
                                DateFormatOptions.TIME_DATE_LONG,
                            )}
                        </Text>
                        {booking?.setupLocationFreeText && (
                            <Text
                                as="span"
                                props={{ className: "font-weight-bold" }}
                            >
                                {` at ${booking?.setupLocationFreeText}`}
                            </Text>
                        )}
                    </div>
                    <Ds.Badge color="greyscale" className="ml-2">
                        {booking?.status}
                    </Ds.Badge>
                </li>
            );
        });
    };

    // Rendering all the automatically added notes (and also manual reminders)
    const renderNonUserNotes = (
        invite: VaccinePatientInvite,
    ): (JSX.Element | null)[] => {
        const acceptedNoteTypes: InviteNoteType[] = [
            InviteNoteType.PatientDeclined,
            InviteNoteType.UserCancelled,
            InviteNoteType.UserInvitedToSecond,
            InviteNoteType.UserAddedPatientToNetwork,
            InviteNoteType.UserRemovedPatientFromNetwork,
            InviteNoteType.PatientBooked,
            InviteNoteType.PatientCancelledBooking,
            InviteNoteType.UserAsPatientBooked,
            InviteNoteType.UserAsPatientDeclined,
            InviteNoteType.UserCancelledBooking,
            InviteNoteType.UserInvitedToBooster,
            InviteNoteType.UserResetInvite,
        ];

        return (invite.notes || [])
            .filter((note) => acceptedNoteTypes.includes(note.type))
            .map((n) => n as InviteNote | InviteReminder) // about to concat reminders array so explicitly making this array a union type
            .concat(invite.inviteReminders || [])
            .sort((a, b) =>
                DateHelpers.mostRecentSorter(a.createdAt, b.createdAt),
            )
            .map((item: InviteNote | InviteReminder, index) => {
                if ((item as InviteNote).summary !== undefined) {
                    // assume InviteReminders or other items in this collection don't have a 'summary' field and for InviteNotes it will always be populated!
                    const note = item as InviteNote;
                    return (
                        <li key={index} className="listitem">
                            {getInviteNoteText(note)}
                        </li>
                    );
                }
                if (item as InviteReminder) {
                    const reminder = item as InviteReminder;
                    return (
                        <li key={index} className="listitem">
                            {getInviteReminderText(reminder)}
                        </li>
                    );
                }
                return null;
            });
    };

    const sortedPatientDetails = [...patientDetails].sort(
        (a: VaccinePatientInvite, b: VaccinePatientInvite) =>
            DateHelpers.mostRecentSorter(b.sendTime, a.sendTime),
    );

    return (
        <>
            <div className="ml-2 w-100" data-testid="patient-details">
                {patientDetailsLoading && <Spinner />}
                {!patientDetailsLoading && patientDetailsError && (
                    <Feedback colour="error" title={patientDetailsError} />
                )}
                {!!nbsData?.bookings?.length && (
                    <div className="mt-4">
                        {renderNbsBookingInfo(nbsData.bookings)}
                    </div>
                )}
                <ul className="list" data-testid="detailslist">
                    {!patientDetailsLoading &&
                        !patientDetailsError &&
                        sortedPatientDetails?.map(
                            (invite: VaccinePatientInvite) => {
                                if (!invite) return null;
                                return (
                                    <li
                                        key={invite.inviteId}
                                        className="mt-4 listitem"
                                    >
                                        {renderInviteTopLine(invite)}
                                        <ul data-testid="nesteddetails">
                                            {renderBookingInfo(invite)}
                                            {renderNonUserNotes(invite)}
                                        </ul>
                                    </li>
                                );
                            },
                        )}
                </ul>
            </div>
        </>
    );
};
