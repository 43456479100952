import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";
import map from "lodash/map";

import { Specialty } from "../../types/Specialty";

export type Specialties = {
    specialties: Specialty[];
    noApplicableSpecialty?: Specialty;
};

// Must be kept in sync with the backend https://github.com/Accurx/rosemary/blob/trunk/src/Web/accuRx.Models/Chain/Interfaces/IUserWorkspaceSpecialty.cs
export const NO_APPLICABLE_SPECIALTY_NAME = "No Applicable Specialty";

export const WORKSPACE_MANAGEMENT_SPECIALTIES_CACHE_KEY =
    "WorkspaceManagementSpecialties";

const fetchSpecialties = async (): Promise<
    IWrappedResult<Record<string, string>>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({ path: "/api/portal/specialties" }),
    );

const formatResponse = (dictionary: Record<string, string>): Specialty[] => {
    return map(dictionary, (name, id) => ({ id, name }));
};

/**
 * @param specialties a list of specialties of type Specialty[]
 * @returns a list of specialties excluding the 'no applicable specialty item', and a separate item for 'no applicable specialty'
 */
const separateSpecialties = (specialties: Specialty[]): Specialties => {
    const noApplicableSpecialty = specialties.find(
        ({ name }) => name === NO_APPLICABLE_SPECIALTY_NAME,
    );

    const allOtherSpecialties = specialties.filter(
        ({ name }) => name !== NO_APPLICABLE_SPECIALTY_NAME,
    );

    return { specialties: allOtherSpecialties, noApplicableSpecialty };
};

export const useSpecialtiesQuery = () => {
    return useQuery<Specialty[], Error, Specialties>({
        queryKey: [WORKSPACE_MANAGEMENT_SPECIALTIES_CACHE_KEY],
        queryFn: async () => {
            const response = await fetchSpecialties();

            if (!response.success || !response.result) {
                throw new Error(
                    response.error ?? "Request failed to fetch specialty data",
                );
            }

            return formatResponse(response.result);
        },
        select: separateSpecialties,
    });
};
