import React from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Card, Flex, Icon, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_BASE } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import {
    StyledBannerSection,
    StyledIconWrapper,
} from "./NextStepsBanner.styles";

type BannerProps = {
    title: string;
    body: React.ReactNode;
    ctaButton: React.ReactNode;
};

const FindPatientCTAButton = () => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();

    const handleClickAndTrack = () => {
        FlemingAnalyticsTracker.trackNavigationSubMenuClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            navigationOptionSelected: "PatientSearch",
            pageOrigin: "ClinicianConversationBanner",
        });

        history.push(ROUTES_BASE.patientsSearch);
    };

    return (
        <Button
            text="Find a patient"
            onClick={handleClickAndTrack}
            icon={{ name: "Send", colour: "white" }}
        />
    );
};

// Reusable banner that we can use anywhere that we want to display a CTA (we can easily pass in different CTA buttons)
const BannerLayout = ({ title, body, ctaButton }: BannerProps) => {
    return (
        <StyledBannerSection>
            <Card spacing={1.5}>
                <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex flexDirection="row">
                        <StyledIconWrapper>
                            <Icon
                                size={7}
                                name="Person"
                                halo={{
                                    colour: "green",
                                    luminosity: "high",
                                }}
                            />
                        </StyledIconWrapper>
                        <div>
                            <Text variant="label">{title}</Text>
                            <Text variant="body" as={"span"}>
                                {body}
                            </Text>
                        </div>
                    </Flex>
                    {ctaButton}
                </Flex>
            </Card>
        </StyledBannerSection>
    );
};

const MessagePatientBanner = () => {
    return (
        <BannerLayout
            title="Message your patients"
            body="Find your patients using their NHS number and date of birth"
            ctaButton={<FindPatientCTAButton />}
        />
    );
};

const MessagePracticeBanner = () => {
    return (
        <BannerLayout
            title="Message another patient's practice"
            body="Search for a patient and message their GP practice"
            ctaButton={<FindPatientCTAButton />}
        />
    );
};

export const NextStepsBanner = () => {
    const isMessagePracticeEnabled = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );

    return isMessagePracticeEnabled ? (
        <MessagePracticeBanner />
    ) : (
        <MessagePatientBanner />
    );
};
