import { useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Feedback, Flex, Text } from "@accurx/design";

import { FileInputButton } from "../../../components/FileInputButton";
import { ALLOWED_FILE_EXTENSIONS } from "../../../constants";
import { MAX_FILE_SIZE_IN_MB, validateCSVFile } from "./validateFileForUpload";

interface UploadFileProps {
    onUpload: (file: File) => void;
    isUploading?: boolean;
    isTrustFlow: boolean;
}

export const UploadFile = ({
    onUpload,
    isUploading = false,
    isTrustFlow,
}: UploadFileProps) => {
    const track = useAnalytics();
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const uploadInputRef = useRef<HTMLInputElement>(null);

    const handleUpload = (files: FileList | null) => {
        const filesArray = files ? Array.from(files) : [];

        if (filesArray.length === 1) {
            const file = filesArray[0];
            setValidationErrors([]);

            const validationResult = validateCSVFile(file);

            if (validationResult.isValid) {
                onUpload(file);
            } else {
                setValidationErrors(validationResult.failureReasons);
                track("BatchCsvUploadError Page View", { isTrustFlow });
            }
        }
    };

    const onUploadClick = () => {
        // Resetting input value for the case
        // when user select the same file after fixing
        // validation issues, because by default
        // onChange will not be called if the same file selected
        if (uploadInputRef.current) {
            uploadInputRef.current.value = "";
        }

        track("BatchCsvUpload Button Click", { isTrustFlow });
    };

    return (
        <>
            {!!validationErrors.length && (
                <Feedback
                    colour="error"
                    title="Your file must be in the right format and size"
                >
                    {validationErrors.map((error, index) => (
                        <Text skinny key={`validation-error-${index}`}>
                            {error}
                        </Text>
                    ))}
                </Feedback>
            )}
            <Flex gap="0.25" flexDirection="column" alignItems="flex-start">
                <FileInputButton
                    ref={uploadInputRef}
                    id="file-for-upload"
                    allowedFileExtensions={ALLOWED_FILE_EXTENSIONS}
                    onFileChange={handleUpload}
                    onClick={onUploadClick}
                    disabled={isUploading}
                    labelButton={{
                        text: isUploading ? "Uploading file..." : "Upload file",
                        icon: {
                            name: "Upload",
                        },
                    }}
                    aria-describedby="file-requirements-message"
                />
                <Text
                    variant="preview"
                    props={{ id: "file-requirements-message" }}
                >
                    We accept .csv, .tsv and .txt files. Maximum size{" "}
                    {MAX_FILE_SIZE_IN_MB}MB.
                </Text>
            </Flex>
        </>
    );
};
