/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import {
    Button,
    Card,
    Ds,
    Feedback,
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    StackPanel,
    Text,
} from "@accurx/design";
import { toast } from "react-toastify";

import FlemingApi from "api/FlemingApiClient";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { StyledCardContent } from "../account/OrganisationResources/DocumentEntryCard.styles";
import { RecallAttachmentModal } from "./RecallModel";

export type PatientProfileCardProps = {
    fileName: string;
    description: string;
    sentAt: string;
    downloadUrl: string;
    documentUrlId: string;
    deletedAt: string | null;
};

export const PatientAttachmentCard = ({
    fileName,
    description,
    sentAt,
    downloadUrl,
    documentUrlId,
    deletedAt,
}: PatientProfileCardProps): JSX.Element | null => {
    const [showRecallModal, setShowRecallModal] = useState(false);

    const [isDeleted, setIsDeleted] = useState(!!deletedAt);

    const toggleRecallModal = () => {
        setShowRecallModal(!showRecallModal);
    };

    const organisationId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const patientToken = PatientHelper.getPatientTokenWithStorageFallback(null);

    const handleDeleteClick = async (): Promise<void> => {
        if (organisationId == null) {
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem deleting this attachment. Please refresh the page and try again.",
                }),
            );
            return;
        }

        const { success } = await FlemingApi.deletePatientAttachments({
            organisationId,
            patientToken,
            documentUrlId,
        });

        if (success) {
            setIsDeleted(true);
            toggleRecallModal();
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem recalling this document. Please try again.",
                }),
            );
        }
    };

    return (
        <>
            <RecallAttachmentModal
                isOpen={showRecallModal}
                toggleRecallModal={toggleRecallModal}
                fileName={fileName}
                handleDelete={handleDeleteClick}
            />
            <Card spacing={2}>
                <StyledCardContent>
                    <StackPanel orientation="horizontal" gutter={2}>
                        <Icon
                            name="Document"
                            theme="Line"
                            colour="night"
                            size={6}
                            halo={{
                                colour: "greyscale",
                            }}
                        />
                        <div>
                            <StackPanel
                                orientation="horizontal"
                                verticalContentAlignment="center"
                                gutter={1}
                            >
                                <Text variant="label" skinny>
                                    {fileName}
                                </Text>
                                {isDeleted && (
                                    <Ds.Badge className="ml-2">
                                        Recalled from patient
                                    </Ds.Badge>
                                )}
                            </StackPanel>
                            <StackPanel orientation="horizontal" gutter={2}>
                                <Text variant="preview">{description}</Text>
                                {sentAt && (
                                    <Text variant="preview">{sentAt}</Text>
                                )}
                            </StackPanel>
                        </div>
                    </StackPanel>
                    {downloadUrl && (
                        <StackPanel
                            orientation="horizontal"
                            gutter={1.5}
                            verticalContentAlignment="center"
                        >
                            <Popover>
                                <PopoverTrigger asChild>
                                    {!isDeleted && (
                                        <Button
                                            theme="secondary"
                                            icon={{
                                                name: "More",
                                                style: "Fill",
                                                title: "More",
                                                id: "more-btn",
                                            }}
                                        />
                                    )}
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverItem onClick={toggleRecallModal}>
                                        <StackPanel
                                            gutter={0.5}
                                            orientation="horizontal"
                                            verticalContentAlignment="center"
                                        >
                                            <Button
                                                theme="transparent"
                                                icon={{
                                                    name: "Bin",
                                                    colour: "red",
                                                }}
                                                text={"Recall"}
                                                className="text-nowrap w-100"
                                            />
                                        </StackPanel>
                                    </PopoverItem>
                                </PopoverContent>
                            </Popover>
                            <a href={downloadUrl} download={fileName}>
                                <Button
                                    as="span"
                                    theme="secondary"
                                    icon={{
                                        name: "Save",
                                        style: "Line",
                                        colour: "blue",
                                    }}
                                    text="Download"
                                />
                            </a>
                        </StackPanel>
                    )}
                </StyledCardContent>
            </Card>
        </>
    );
};
