import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { Container } from "app/organisations/shared";

export const IntroductionContainer = styled.div`
    margin-top: ${Tokens.SIZES[3]};
    margin-bottom: ${Tokens.SIZES[5]};

    > span {
        margin-top ${Tokens.SIZES[3]}
    }
`;

export const StyledPageHeader = styled((props) => Text({ ...props, as: "h1" }))`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledPageDescription = styled(Text)`
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledContainer = styled(Container)`
    margin-bottom: 80px;
`;
