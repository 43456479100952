import React from "react";

import { Button, Flex } from "@accurx/design";

import { AddPatientsCard } from "./AddPatientsCard";

export type BatchUploadCardProps = {
    onClick: () => void;
    disabled?: boolean;
    "data-userflow-id"?: string;
};

export const BatchUploadCsvCard = ({
    onClick,
    disabled = false,
    "data-userflow-id": dataUserFlowId,
}: BatchUploadCardProps) => {
    return (
        <AddPatientsCard.CardOuter>
            <Flex flexDirection="column">
                <AddPatientsCard.CardInner>
                    <AddPatientsCard.TextWrapper>
                        <AddPatientsCard.Title>
                            CSV file from spreadsheet
                        </AddPatientsCard.Title>
                        <AddPatientsCard.Body>
                            Import patient info from an existing CSV file made
                            in Excel or similar
                        </AddPatientsCard.Body>
                    </AddPatientsCard.TextWrapper>

                    <Button
                        text="Continue"
                        theme="secondary"
                        icon={{
                            name: "ArrowTail",
                            rotation: "right",
                            placement: "end",
                        }}
                        onClick={onClick}
                        disabled={disabled}
                        aria-label="Continue to Import Patients From a File"
                        data-userflow-id={dataUserFlowId}
                    />
                </AddPatientsCard.CardInner>
            </Flex>
        </AddPatientsCard.CardOuter>
    );
};
