import { Card, StackPanel, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

// TEMPORARY SOLUTION
// Can't do styled(Card) as Card doesn't pass the className prop
export const ParticipantPanel = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    overflow-y: auto;
`;

export const ParticipantList = styled(StackPanel)`
    padding: 0;
    list-style: none;
`;

export const ParticipantName = styled((props) =>
    Text({ ...props, as: "span" }),
)`
    margin-top: 0;
    margin-bottom: 0;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ParticipantCard = styled(Card)<{ workspaceAvailable: boolean }>`
    background-color: ${({ workspaceAvailable }) =>
        workspaceAvailable
            ? Tokens.COLOURS.greyscale.white
            : Tokens.COLOURS.greyscale.cloud};
`;
