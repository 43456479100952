import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { SaveToRecordEvent } from "@accurx/concierge/types";
import { format } from "date-fns";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

type SaveToRecordUnmatchWarningProps = {
    senderName: string;
    creationTime: string;
    saveToRecordEvent: SaveToRecordEvent;
};

export const SaveToRecordUnmatchWarning = ({
    senderName,
    creationTime,
    saveToRecordEvent,
}: SaveToRecordUnmatchWarningProps) => {
    const savedToRecordByUser = useUser({
        userId: saveToRecordEvent.savedByUserId ?? "",
    });
    const savedAtTime = format(new Date(saveToRecordEvent.savedAt), "h:mmaaa");
    const savedToRecordByUserName = formatUserDisplayName({
        user: savedToRecordByUser,
    });

    return (
        <>
            <b>{senderName}</b> unmatched this conversation from the patient at{" "}
            <b>{creationTime}</b>. This was previously saved to a patient's
            medical record by <b>{savedToRecordByUserName}</b> at{" "}
            <b>{savedAtTime}</b>, you will need to rectify this according to
            your organisation's policies and procedures.
        </>
    );
};
