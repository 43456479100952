import { PatientThreadItemUpdate } from "shared/concierge/conversations/tickets/types/dto.types";
import type { ConversationUpdate } from "shared/concierge/conversations/types/conversation.types";

import { mapTicketToConversation } from "./ConversationMapper";

/**
 * Maps from an external ticket item update DTO to an internal
 * domain view "Conversation". the conversation returned is the
 * conversation result after the update has been applied.
 *
 * Note: This function will update the data in the entity store
 *
 * @param update
 * @returns A conversation
 */
export function mapTicketItemUpdateToConversationUpdate(
    update: PatientThreadItemUpdate,
): ConversationUpdate | undefined {
    return mapTicketToConversation({
        ticketSummary: update.ticketSummary,
        patient: update.patient,
        isFullyLoaded: false,
        items: [update],
    });
}
