import { ReactNode, createContext, useEffect, useState } from "react";

import { HubClient, createHubClient } from "./HubClient";

export const HubClientContext = createContext<HubClient | null>(null);

type UserProviderProps = {
    children?: ReactNode;
    currentUserId: string | null;
    // currently the 2fa reconnection trigger is handled by the 2FA component and not here
};

/**
 * HubClientProvider
 *
 * Provide the HubClient instance and manage its connection based on current authentication
 * context.
 */
export const HubClientProvider = ({
    currentUserId,
    children,
}: UserProviderProps) => {
    // Currently we have a single HubClient that is explicitly re-connected
    // when we have new authentication information available in cookies rather
    // than replacing it entirely.
    // See Linear task GPWIN-91.
    const [hubClient] = useState(() => createHubClient());

    useEffect(() => {
        if (currentUserId) {
            hubClient.connect();
            return () => {
                hubClient.disconnect();
            };
        }
    }, [currentUserId, hubClient]);

    return (
        <HubClientContext.Provider value={hubClient}>
            {children}
        </HubClientContext.Provider>
    );
};
