import { Log } from "@accurx/shared";
import { Draft } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";

export const processUpdates = (
    state: Draft<ConciergeState["teams"]>,
    action: ConciergeAction<ConciergeUpdates>,
) => {
    const teams = state.items;
    const updates = action.payload.teams ?? [];

    const toAssignTeams = uniqBy(
        updates.filter((t) => t.type === "ToAssign"),
        "id",
    );

    if (toAssignTeams.length > 1) {
        Log.error(
            "Received more than one team of type ToAssign. Only one team of this type is supported and additional are not displayed.",
            { tags: { product: "Inbox" } },
        );
    }

    for (const update of updates) {
        const current: TeamSummary | undefined = teams[update.id];

        // Maintain a count of the number of teams by type so we can easily
        // check if we have any Hub teams (for example). this code handles the
        // case that a team would change its type, which in reality would never
        // happen but we'll take the belt & braces approach.
        if (current && state.countByType[current.type]) {
            state.countByType[current.type] =
                (state.countByType[current.type] ?? 0) - 1;
        }
        state.countByType[update.type] =
            (state.countByType[update.type] ?? 0) + 1;

        teams[update.id] = {
            ...teams[update.id],
            ...update,
            displayName:
                // Temporary special case for the To Assign team. We are currently unable
                // to update the display name on the backend because we do not want
                // to affect the desktop inbox.
                update.displayName === "To Assign" && update.type === "ToAssign"
                    ? "Unassigned"
                    : update.displayName,
            canBeAssignedTo: isNil(update.canBeAssignedTo)
                ? teams[update.id]?.canBeAssignedTo
                : update.canBeAssignedTo,
        };
    }

    return state;
};
