import React from "react";

import { Text } from "@accurx/design";

import { MedicalRecordResult } from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type ImmunisationsProps = Pick<MedicalRecordResult, "immunisations">;

export const Immunisations = ({
    immunisations,
}: ImmunisationsProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title="Immunisations">
            {immunisations.length > 0 ? (
                <CardRowStack>
                    {immunisations.map((immunisation, i) => (
                        <CardRow
                            type={CardRowType.Default}
                            key={`${i}-${immunisation.date}-${immunisation.name}`}
                            label={formatMedicalRecordDate(immunisation.date)}
                            title={immunisation.name}
                        />
                    ))}
                </CardRowStack>
            ) : (
                <Text skinny>No recorded immunisations</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
