import React from "react";

import * as UI from "@accurx/design";
import { LinkProps } from "domains/inbox/components/Link/Link";

import {
    StyledActiveTab,
    StyledTabsItem,
    StyledTabsLink,
} from "./LinkTabItem.styles";

export type LinkTabItemProps = {
    active: boolean;
    link: LinkProps["to"];
    userflowId?: string;
    onClick?: (e: React.MouseEvent) => void;
};

export const LinkTabItem = ({
    active,
    link,
    children,
    userflowId,
    onClick,
}: React.PropsWithChildren<LinkTabItemProps>) => {
    return (
        <StyledTabsItem data-userflow-id={userflowId}>
            <UI.Text variant="note" skinny>
                {active ? (
                    <StyledActiveTab>{children}</StyledActiveTab>
                ) : (
                    <StyledTabsLink
                        $active={active}
                        to={link}
                        onClick={onClick}
                    >
                        {children}
                    </StyledTabsLink>
                )}
            </UI.Text>
        </StyledTabsItem>
    );
};
