import { NativeApi } from "@accurx/native";
import { createEndpoint } from "domains/inbox/native/createEndpoint";
import {
    PatientsSearchResult,
    PatientsSearchResultSchema,
} from "domains/inbox/schemas/PatientsSearchResultSchema";

export type SearchPatientsQueryPayload = {
    searchTerm?: string;
    nhsNumber?: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
};

export const searchPatientsQuery = createEndpoint<
    PatientsSearchResult,
    SearchPatientsQueryPayload
>("QuerySearchPatients", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QuerySearchPatients",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
    return PatientsSearchResultSchema.parse(result);
});
