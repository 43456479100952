import { useConciergeSelector } from "domains/concierge/internal/context";
import { getConversation } from "domains/concierge/internal/util/getConversation";
import { Conversation } from "domains/concierge/types";

export const useUnreadCountsQueryStatus = ({
    conversationId,
}: {
    conversationId: Conversation["id"];
}) => {
    return useConciergeSelector((state) => {
        const conversation = getConversation(
            state.conversations,
            conversationId,
        );

        if (!conversation) return "loading";

        switch (conversation.source.system) {
            case "Ticket":
                return state.conversations.unreadCounts.ticket.queryStatus;

            case "ClinicianMessaging":
                return state.conversations.unreadCounts.clinicianMessaging
                    .queryStatus;
        }
    });
};
