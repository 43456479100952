import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledWrapper = styled.div<{ $zIndex?: number }>`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 2px solid lightgray;
    padding: ${Tokens.SIZES[1]};
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${(props) => (props.$zIndex ? `z-index: ${props.$zIndex}` : "")}
`;
