import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { FlemingLoggedInCustomProperties } from "../FlemingAnalyticsConstants";

export const trackClinicViewButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "ClinicView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackClinicBackButtonClick = (
    props: {
        origin: string;
    } & FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "ClinicBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackClinicBatchSendStartButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "ClinicBatchSendStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
