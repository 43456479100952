import { useState } from "react";

import {
    Icon,
    Input,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { PopoverItemButton } from "@accurx/design/dist/components/Popover";

import { SkeletonBody } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { sanitiseMobileNumber } from "app/workspaceConversations/utils/conversation.utils";

import {
    StyledDetailsSection,
    StyledFormFieldWrapper,
    StyledIconSection,
    StyledSendViaButton,
    StyledWrapper,
} from "./SendVia.styles";
import { SendViaOption, SendViaProps } from "./SendVia.types";

export const SendVia = ({
    defaultMobileNumber,
    currentValue,
    onInputChange,
    onOptionChange,
    isLoading,
    activeOption,
    error,
    disabled,
    hasEmailEnabled,
}: SendViaProps) => {
    const [open, setOpen] = useState(false);

    const mobileManualOption: SendViaOption = {
        id: "MobileManual",
        label: "Mobile",
        optionLabel: "Message via SMS to other number",
        icon: "Phone",
    };

    const mobilePdsOption: SendViaOption = {
        id: "MobilePds",
        label: `Mobile (${defaultMobileNumber})`,
        optionLabel: `Message via SMS to ${defaultMobileNumber}`,
        icon: "Phone",
    };

    const emailManualOption: SendViaOption = {
        id: "EmailManual",
        label: "Email",
        optionLabel: "Message via email",
        icon: "Mail",
    };

    // If email is enabled display that option, if there is a default mobile display the
    // option to send to that mobile, always display the option to enter a mobile number
    // This will be simplified when we remove the feature flag for email
    const options = hasEmailEnabled
        ? defaultMobileNumber
            ? [mobilePdsOption, mobileManualOption, emailManualOption]
            : [mobileManualOption, emailManualOption]
        : defaultMobileNumber
        ? [mobilePdsOption, mobileManualOption]
        : [mobileManualOption];

    const currentActiveOption = options.find(
        (option) => option.id === activeOption,
    );
    const handleOptionOnClick = (option: SendViaOption) => {
        onOptionChange(option);
        setOpen(false);
    };

    return (
        <StyledWrapper>
            <Text variant="label" skinny>
                Via:
            </Text>{" "}
            {isLoading ? (
                <SkeletonBody charCount={20} />
            ) : (
                <>
                    {options.length === 1 && (
                        <StyledDetailsSection singleOption>
                            <Text variant="body" skinny>
                                {currentActiveOption?.label}
                            </Text>
                        </StyledDetailsSection>
                    )}
                    {options.length > 1 && (
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger>
                                <StyledSendViaButton
                                    autoFocus
                                    disabled={disabled}
                                >
                                    <StyledDetailsSection>
                                        <Text variant="body" skinny>
                                            {currentActiveOption?.label}
                                        </Text>
                                    </StyledDetailsSection>
                                    <StyledIconSection isOpen={open}>
                                        <Icon
                                            name="Pencil"
                                            size={3}
                                            theme="Line"
                                            title="Edit recipient contact details"
                                            id="edit-recipient"
                                            colour="blue"
                                        />
                                    </StyledIconSection>
                                </StyledSendViaButton>
                            </PopoverTrigger>
                            <PopoverContent
                                align="center"
                                side="bottom"
                                width="auto"
                            >
                                {options.map((option) => (
                                    <PopoverItemButton
                                        key={option.id}
                                        active={activeOption === option.id}
                                        onClick={() =>
                                            handleOptionOnClick(option)
                                        }
                                    >
                                        <Icon name={option.icon} size={3} />
                                        <Text
                                            skinny
                                            variant={
                                                activeOption === option.id
                                                    ? "label"
                                                    : "body"
                                            }
                                        >
                                            {option.optionLabel}
                                        </Text>
                                    </PopoverItemButton>
                                ))}
                            </PopoverContent>
                        </Popover>
                    )}{" "}
                    {activeOption === "MobileManual" && (
                        <StyledFormFieldWrapper
                            errors={error ? [error] : []}
                            label="Enter patient's mobile"
                            labelProps={{
                                htmlFor: "mobile-input",
                                className: "sr-only",
                            }}
                        >
                            <Input
                                autoFocus
                                id="mobile-input"
                                name="mobile-input"
                                placeholder="Enter patient's mobile"
                                type="tel"
                                value={currentValue}
                                onChange={(e) => {
                                    const stringSanitised =
                                        sanitiseMobileNumber(e.target.value);
                                    onInputChange(stringSanitised);
                                }}
                                disabled={disabled}
                                data-userflow-id="enter-patient-mobile-number-input"
                            />
                        </StyledFormFieldWrapper>
                    )}
                    {activeOption === "EmailManual" && (
                        <StyledFormFieldWrapper
                            errors={error ? [error] : []}
                            label="Enter patient's email"
                            labelProps={{
                                htmlFor: "email-input",
                                className: "sr-only",
                            }}
                        >
                            <Input
                                autoFocus
                                id="email-input"
                                name="email-input"
                                placeholder="Enter patient's email"
                                type="email"
                                value={currentValue}
                                onChange={(e) => onInputChange(e.target.value)}
                                disabled={disabled}
                            />
                        </StyledFormFieldWrapper>
                    )}
                </>
            )}
        </StyledWrapper>
    );
};
