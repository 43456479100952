import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { ConversationItem } from "shared/concierge/conversations/types/item.types";

const currentDateString = new Date().toISOString();

export const groupConversationItemsByDate = (
    items: ConversationItem[],
    currentDate: string = currentDateString,
): Map<string, ConversationItem[]> => {
    const newItems = new Map<string, ConversationItem[]>();
    const todaysDate = DateHelpers.formatTime(
        currentDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );

    items.forEach((item) => {
        const createdAt = DateHelpers.formatTime(
            item.createdAt,
            DateFormatOptions.DATE_SHORT_WITH_SPACES,
        );
        const key = createdAt === todaysDate ? "Today" : createdAt;
        const createdAtConversationItems = newItems.get(key);
        if (!createdAtConversationItems) {
            newItems.set(key, []);
        }
        newItems.set(key, [...(createdAtConversationItems || []), item]);
    });

    return newItems;
};
