import React, {
    ChangeEvent,
    Dispatch,
    Fragment,
    SetStateAction,
    useEffect,
    useState,
} from "react";

import { Feedback, Icon, Text, Tokens } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, DropdownMenu } from "reactstrap";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import { fetchFloreysList } from "app/batchMessage/gp/BatchMessage.actions";
import {
    FloreyOwner,
    FloreyTemplate,
} from "app/batchMessage/gp/BatchMessage.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import {
    BatchMessageDropDownItem,
    BatchMessageDropDownToggle,
} from "../../../components/compose/BatchMessageCompose.styles";
import {
    FloreyDropdownByGroupHeader,
    FloreyDropdownTemplate,
} from "../../../components/compose/BatchMessageCompose.types";
import { findFloreyById } from "../../../components/compose/BatchMessageCompose.utils";
import { mapFloreysForDropdown } from "./ComposeFloreyDropdown.utils";

const BatchSaveToRecordOption = styled.div<{ selected: boolean }>`
    border-radius: ${Tokens.BORDERS.radius};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
        props.selected
            ? Tokens.COLOURS.primary.blue[100]
            : Tokens.COLOURS.greyscale.silver};
    background-color: ${(props) =>
        props.selected
            ? Tokens.COLOURS.primary.blue[10]
            : Tokens.COLOURS.greyscale.white};

    &:focus-within {
        border-color: ${Tokens.COLOURS.primary.blue[100]};
    }
`;

export type ComposeFloreyDropdownProps = {
    selectedFloreyId: string;
    saveFloreyToRecord: boolean;
    selectedFloreyDisplay: string;
    selectFlorey: (florey: FloreyTemplate, floreyOwner: FloreyOwner) => void;
    setSaveFloreyToRecord: Dispatch<SetStateAction<boolean>>;
    orgId?: string;
};

export const ComposeFloreyDropdown = ({
    selectedFloreyId,
    saveFloreyToRecord,
    selectedFloreyDisplay,
    selectFlorey,
    setSaveFloreyToRecord,
    orgId,
}: ComposeFloreyDropdownProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const gettingFloreysListStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingFloreysListStatus,
        shallowEqual,
    );
    const floreysList = useAppSelector(
        ({ batchMessage }) => batchMessage.floreysList,
        shallowEqual,
    );
    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const orgFloreysCategory = `${practiceName} Florey Questionnaires`;
    const accurxFloreysCategory = "Accurx Florey Questionnaires";

    // States
    const [showFloreyDropdown, setShowFloreyDropdown] = useState(false);
    const [showFloreyCanSaveToRecord, setShowFloreyCanSaveToRecord] =
        useState(false);
    const [selectedFloreyIsCustom, setSelectedFloreyIsCustom] = useState(false);

    const getDisabledTextStyle = (isNotDisabled: boolean) => {
        return isNotDisabled ? { style: {} } : { style: { color: "grey" } };
    };

    const saveFloreyToRecordHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSaveFloreyToRecord(e.target.checked);
        if (e.target.checked) {
            ChainAnalyticsTracker.trackBatchComposeSaveToRecordSelect(
                analyticsLoggedInProps,
            );
        } else {
            ChainAnalyticsTracker.trackBatchComposeNoSaveToRecordSelect(
                analyticsLoggedInProps,
            );
        }
    };

    const handleToggle = () => {
        setShowFloreyDropdown((prevState) => !prevState);
    };

    const handleSelectFlorey = (
        florey: FloreyTemplate,
        floreyOwner: FloreyOwner,
    ) => {
        selectFlorey(florey, floreyOwner);
        setSaveFloreyToRecord(false); // reset save Florey to record
        setSelectedFloreyIsCustom(floreyOwner === FloreyOwner.Org);
        ChainAnalyticsTracker.trackBatchComposeFloreyOptionClick(
            analyticsLoggedInProps,
        );
    };

    // Load all florey for the dropdown
    useEffect(() => {
        if (gettingFloreysListStatus === UpdatingStatus.Initial && orgId) {
            dispatch(fetchFloreysList(orgId));
        }
    }, [dispatch, orgId, gettingFloreysListStatus]);

    // Show/Hide display of FloreyCanSaveToRecordOption
    useEffect(() => {
        if (floreysList && selectedFloreyId) {
            const selectedFlorey = findFloreyById(
                floreysList,
                selectedFloreyId,
            );
            if (selectedFlorey !== null) {
                setShowFloreyCanSaveToRecord(
                    selectedFlorey.canAutoSaveToRecord ?? false,
                );
            }
        }
    }, [floreysList, selectedFloreyId]);

    const renderCategoryHeadingDropdownItem = (
        categoryText: string,
    ): JSX.Element => (
        <BatchMessageDropDownItem $heading disabled>
            <Text skinny colour="green" variant="label">
                {categoryText}
            </Text>
        </BatchMessageDropDownItem>
    );

    const renderFloreyDropdownItems = (
        floreyDropdownTemplates: FloreyDropdownByGroupHeader,
        floreyOwner: FloreyOwner,
    ) => {
        return Object.keys(floreyDropdownTemplates)
            .sort()
            .map((groupHeader: string, index: number) => {
                return (
                    <Fragment key={groupHeader + " Fragment " + index}>
                        {groupHeader !== "" ? (
                            <BatchMessageDropDownItem
                                key={groupHeader}
                                disabled={true}
                            >
                                <Text skinny colour="green">
                                    {groupHeader}
                                </Text>
                            </BatchMessageDropDownItem>
                        ) : null}
                        {floreyDropdownTemplates[groupHeader].map(
                            (
                                florey: FloreyDropdownTemplate,
                                floreyIndex: number,
                            ) => {
                                return (
                                    <BatchMessageDropDownItem
                                        disabled={florey.availability !== 1}
                                        key={florey.title + floreyIndex}
                                        onClick={() =>
                                            handleSelectFlorey(
                                                florey,
                                                floreyOwner,
                                            )
                                        }
                                    >
                                        <Text
                                            skinny
                                            props={getDisabledTextStyle(
                                                florey.availability === 1,
                                            )}
                                        >
                                            {florey.title}
                                        </Text>
                                    </BatchMessageDropDownItem>
                                );
                            },
                        )}
                    </Fragment>
                );
            });
    };

    const renderFloreyCanSaveToRecordOption = () => {
        if (!selectedFloreyId || !showFloreyCanSaveToRecord) {
            return;
        }
        return (
            <BatchSaveToRecordOption
                selected={saveFloreyToRecord}
                className="px-2 py-1 my-3"
            >
                <div className="px-1 d-flex align-items-center">
                    <Text
                        as="label"
                        skinny
                        props={{
                            id: "saveFloreyToRecord",
                            htmlFor: "saveFloreyToRecordCheckbox",
                        }}
                    >
                        <input
                            id="saveFloreyToRecordCheckbox"
                            onChange={saveFloreyToRecordHandler}
                            checked={saveFloreyToRecord}
                            type="checkbox"
                            name="saveFloreyToRecord"
                            className="mr-2"
                        />
                        Save responses to the patient's record automatically
                    </Text>
                    <Text
                        skinny
                        as="a"
                        props={{
                            href: SupportUrls.BatchFloreySaveAuto,
                            target: "_blank",
                            rel: "noopener noreferrer",
                            className: "ml-auto",
                            onClick: () => {
                                ChainAnalyticsTracker.trackBatchResourceClick({
                                    ...analyticsLoggedInProps,
                                    origin: history.location.pathname,
                                    batchResourceName:
                                        SupportUrls.BatchFloreySaveAuto,
                                });
                            },
                        }}
                    >
                        <Icon size={4} name="Info" theme="Fill" />
                        <Text
                            as="span"
                            props={{
                                className: "sr-only",
                            }}
                        >
                            More information about saving responses to patient's
                            record
                        </Text>
                    </Text>
                </div>
            </BatchSaveToRecordOption>
        );
    };
    const renderSaveToRecordCustomFloreyWarning = () => {
        if (
            !selectedFloreyId ||
            !showFloreyCanSaveToRecord ||
            !saveFloreyToRecord ||
            !selectedFloreyIsCustom
        ) {
            return;
        }
        return (
            <Feedback
                title={
                    " Are you sure you want to save responses to this questionnaire without reviewing the answers?"
                }
                colour={"warning"}
            >
                <Text skinny>
                    You are sending a questionnaire to multiple patients. If
                    this might return clinical information that needs review or
                    if you’re not sure, please do not select "Save responses to
                    patient's record automatically".
                </Text>
            </Feedback>
        );
    };

    const orgFloreysByGroup = mapFloreysForDropdown(
        floreysList.groups,
        FloreyOwner.Org,
    );
    const accurxFloreysByGroup = mapFloreysForDropdown(
        floreysList.groups,
        FloreyOwner.Accurx,
    );

    return (
        <div className="w-100 mb-3">
            <div>
                <Text
                    as="label"
                    variant="label"
                    props={{ htmlFor: "florey-dropdown" }}
                >
                    Florey
                </Text>
                <Dropdown
                    isOpen={showFloreyDropdown}
                    toggle={handleToggle}
                    className="batch-dropdown"
                >
                    <BatchMessageDropDownToggle
                        id="florey-dropdown"
                        color="light"
                        data-testid="floreyDropdown"
                    >
                        <Text skinny>{selectedFloreyDisplay}</Text>
                        <span className="caret" />
                    </BatchMessageDropDownToggle>
                    <DropdownMenu
                        className="w-100"
                        style={{ overflowY: "auto", height: "20rem" }}
                    >
                        {Object.keys(orgFloreysByGroup).length > 0 &&
                            renderCategoryHeadingDropdownItem(
                                orgFloreysCategory,
                            )}
                        {renderFloreyDropdownItems(
                            orgFloreysByGroup,
                            FloreyOwner.Org,
                        )}
                        {Object.keys(accurxFloreysByGroup).length > 0 &&
                            renderCategoryHeadingDropdownItem(
                                accurxFloreysCategory,
                            )}
                        {renderFloreyDropdownItems(
                            accurxFloreysByGroup,
                            FloreyOwner.Accurx,
                        )}
                    </DropdownMenu>
                </Dropdown>
            </div>
            {renderFloreyCanSaveToRecordOption()}
            {renderSaveToRecordCustomFloreyWarning()}
            {gettingFloreysListStatus === UpdatingStatus.Failed && (
                <Feedback
                    colour="error"
                    title="There was a connection problem."
                    props={{ className: "mt-2 mb-4" }}
                >
                    <Text skinny>
                        We couldn't load the list of Florey templates. Refresh
                        the page to try again. If that still doesn't work,
                        contact support.
                    </Text>
                </Feedback>
            )}
        </div>
    );
};
