import { Feedback, Text } from "@accurx/design";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";
import { OrderedList } from "app/patientLists/integratedClinics/shared/components/OrderedList.styles";
import { ClinicTabId } from "app/patientLists/integratedClinics/types";

import { ClinicCard } from "./ClinicCard";
import { EmptyMessage } from "./EmptyMessage";

interface FilteredListProps {
    workspaceId: string;
    noClinics: boolean;
    currentTab: {
        tabId: ClinicTabId;
        hasNoClinics: boolean;
    };
    filteredClinics: IntegratedClinic[];
    startDate: string;
    endDate: string;
}

export const FilteredList = (props: FilteredListProps) => {
    if (props.noClinics) {
        return (
            <EmptyMessage
                startDate={props.startDate}
                endDate={props.endDate}
                clinicsGroup="relevant"
            />
        );
    }

    if (props.currentTab.hasNoClinics) {
        return (
            <EmptyMessage
                startDate={props.startDate}
                endDate={props.endDate}
                clinicsGroup={props.currentTab.tabId}
            />
        );
    }

    if (props.filteredClinics.length === 0) {
        return (
            <Feedback colour="information" title="No results found">
                <Text skinny>
                    There are no clinic lists matching your filters
                </Text>
            </Feedback>
        );
    }

    return (
        <OrderedList>
            {props.filteredClinics.map((clinic, i) => (
                <li key={i}>
                    <ClinicCard {...clinic} workspaceId={props.workspaceId} />
                </li>
            ))}
        </OrderedList>
    );
};
