import { createContext } from "react";

import { SignalRConnection, Transport } from "domains/transport/types";

export type TransportContextState = {
    transport: Transport | null;
    connectionState: SignalRConnection;
};

export const TransportContext = createContext<TransportContextState>({
    transport: null,
    connectionState: "Initialising",
});
