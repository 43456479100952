import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { NHSAdviceLink } from "domains/compose/types";

import { StyledPillButton } from "../Attach/components/AttachmentInfoBox.styles";
import { BodyInfoBox } from "../BodyInfoBox";
import { StyledColumn, StyledRow } from "./NHSAdviceInfoBox.styles";

export const NHSAdviceInfoBox = ({
    nhsAdviceLink,
    onRemoveNHSAdviceLink,
}: {
    nhsAdviceLink: NHSAdviceLink;
    onRemoveNHSAdviceLink: () => void;
}) => {
    return (
        <BodyInfoBox.Header gap="2" fullBorder>
            <StyledRow>
                <BodyInfoBox.Icon name="Link" />
                <StyledColumn>
                    <BodyInfoBox.Text colour="night">
                        NHS.UK Advice: {nhsAdviceLink.name}
                    </BodyInfoBox.Text>
                    <UI.Flex>
                        <BodyInfoBox.Link
                            href={`https://${nhsAdviceLink.url}`}
                            openInNewTab
                        >
                            <UI.Link.Text text={nhsAdviceLink.url} />
                            <UI.Link.Icon />
                        </BodyInfoBox.Link>
                    </UI.Flex>
                </StyledColumn>
            </StyledRow>
            <StyledPillButton
                onClick={onRemoveNHSAdviceLink}
                title="Remove NHS advice"
            >
                <Pill.Icon name="Cross" colour="red" size={3} />
            </StyledPillButton>
        </BodyInfoBox.Header>
    );
};
