import { AccordionSingleProps, Trigger } from "@radix-ui/react-accordion";

import { Content } from "./Content";
import { Header } from "./Header";
import { Indented } from "./Indented";
import { Item } from "./Item";
import { Root } from "./Root";

export const Accordion = {
    Root,
    Item,
    Header,
    Content,
    Indented,
    Trigger,
};

export type AccordionOnValueChangeHandler =
    Required<AccordionSingleProps>["onValueChange"];
