import {
    AppointmentChangeDeadline,
    AppointmentChangeSupport,
    AppointmentSupportedChanges,
} from "@accurx/api/appointment";

import { isSupported } from "./isSupported";

export const getSupportedAmendMessage = (
    supportedChanges: AppointmentSupportedChanges,
    changeDeadline: AppointmentChangeDeadline,
) => {
    if (
        supportedChanges.cancellation ===
            AppointmentChangeSupport.NotSupported &&
        supportedChanges.rebooking === AppointmentChangeSupport.NotSupported
    ) {
        return "";
    }
    const amendDeadline =
        changeDeadline === AppointmentChangeDeadline.OneHour
            ? "1 hour"
            : "1 working day";

    if (
        isSupported(supportedChanges.cancellation) &&
        supportedChanges.rebooking === AppointmentChangeSupport.NotSupported
    ) {
        return `Patients will be able to cancel the appointment up to ${amendDeadline} before the appointment is due.`;
    }

    if (
        isSupported(supportedChanges.rebooking) &&
        supportedChanges.cancellation === AppointmentChangeSupport.NotSupported
    ) {
        return `Patients will be able to rebook the appointment up to ${amendDeadline} before the appointment is due.`;
    }

    return `Patients will be able to amend the appointment up to ${amendDeadline} before the appointment is due.`;
};
