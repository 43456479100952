// -----------------
// ACTION TYPES
// -----------------
export const SET_TWO_FACTOR_IS_AUTHENTICATED =
    "SET_TWO_FACTOR_IS_AUTHENTICATED";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// -----------------

interface SetTwoFactorIsAuthenticatedAction {
    type: typeof SET_TWO_FACTOR_IS_AUTHENTICATED;
    value: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetTwoFactorIsAuthenticatedAction;

// ----------------
// ACTION CREATORS
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// ----------------
export const actionCreators = {
    setTwoFactorIsAuthenticated: (value: boolean) => ({
        type: SET_TWO_FACTOR_IS_AUTHENTICATED,
        value,
    }),
};
