import { ReactNode, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { IWrappedResult } from "@accurx/shared";

import {
    ClinicianMessage,
    IFirstMessageResponse,
    ISendMessageResponse,
} from "api/ClinicianConversationApi";
import { isTestPatient } from "app/patientProfile/isTestPatient";
import { useCurrentOrgId, useCurrentUserEmailAddress } from "store/hooks";

import { ClinicianConversationContext } from ".";
import {
    buildSubjectFromPatient,
    getPatientDetailsWithDisplayNames,
} from "../clinicianConversation.helper";
import { usePatientDetailsFromState } from "../hooks/usePatientDetailsFromState";
import { AttachmentWithPreviewUrl, SendMessageArgs } from "../types";
import { ClinicianConversationContextType } from "./types";

export const FromTestConversation = ({ children }: { children: ReactNode }) => {
    const organisationId = useCurrentOrgId();
    const userEmailAddress = useCurrentUserEmailAddress();
    const currentUser = useCurrentUser();

    const [attachedFilesMap, setAttachedFilesMap] = useState<{
        [Key: string]: AttachmentWithPreviewUrl;
    }>({});

    if (!userEmailAddress) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromNewConversation without having an userEmailAddress in the state",
        );
    }
    if (!organisationId) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromNewConversation without having an org id in the state",
        );
    }

    const { patient, status } = usePatientDetailsFromState();

    const [mockMessages, setMockMessages] = useState<ClinicianMessage[] | null>(
        null,
    );

    const gpPracticeName = "Test GP Practice";

    const participants = {
        participantWorkspaces: [
            {
                workspaceId: 0,
                workspaceName: gpPracticeName,
                participants: [],
            },
        ],
        individualParticipants: [
            {
                emailAddress: userEmailAddress,
                displayName: currentUser.user.fullName,
                isCurrentUser: true,
            },
        ],
    };

    const markEverthingAsRead = () => {
        setMockMessages((currentMessages) =>
            currentMessages === null
                ? null
                : currentMessages.map((x) => ({
                      ...x,
                      dateRead: new Date().toISOString(),
                  })),
        );
    };

    const addMessage = ({ messageBody, attachedFiles }: SendMessageArgs) => {
        attachedFiles.map((a) =>
            setAttachedFilesMap((map) => ({ ...map, [a.id]: a })),
        );

        const newMessage = {
            messageId: "",
            senderEmailAddress: userEmailAddress,
            senderDisplayName: currentUser.user.fullName,
            body: messageBody,
            dateSent: new Date().toISOString(),
            attachments: attachedFiles.map(({ id, displayName }) => ({
                id: parseInt(id),
                displayName,
            })),
        };

        return { messages: [...(mockMessages ?? []), newMessage], newMessage };
    };

    const automaticReplyMessage = `Hi ${
        currentUser.user.fullName && currentUser.user.fullName.trim()
            ? currentUser.user.fullName
            : "there"
    }! 👋
    
Thanks for trying Message GP! 🚀

This is an automated response to show you what responses look like when you use Message GP.

All you need is your patient's NHS number & date of birth to begin contacting their GP practice. Learn more about Message GP through this article: https://support.accurx.com/en/articles/5948485-accurx-web-what-is-message-gp-practice-in-web.

Thanks again from the Accurx team.`;

    const addAutomaticReply = () =>
        setMockMessages((messages) => [
            ...(messages ?? []),
            {
                messageId: "",
                senderEmailAddress: "support@accurx.com",
                senderDisplayName: "Accurx",
                body: automaticReplyMessage,
                dateSent: new Date().toISOString(),
                attachments: [],
            },
        ]);

    const onSendFn = () => {
        setTimeout(markEverthingAsRead, 1000);
        setTimeout(addAutomaticReply, 1700);
    };

    const getContext: () => ClinicianConversationContextType = () => {
        if (status === "loading") {
            return {
                state: "loading",
                urlId: null,
                userEmailAddress,
                isNewConversation: true,
            };
        }

        if (status !== "success" || !isTestPatient(patient)) {
            return {
                state: "error",
                urlId: null,
                error: "You need be using the test patient to use this page.",
                userEmailAddress,
                isNewConversation: true,
            };
        }

        const patientDetailsWithDisplayNames =
            getPatientDetailsWithDisplayNames({
                ...patient,
                prefixName: "Mr",
                practiceName: gpPracticeName,
            });

        if (mockMessages) {
            return {
                state: "fetched",
                error: null,
                conversationId: null,
                messages: mockMessages,
                getAttachmentUrl: (id) => attachedFilesMap[id].previewUrl,
                patient: patientDetailsWithDisplayNames,
                sendMessage: async ({ messageBody, attachedFiles }) =>
                    await new Promise<IWrappedResult<ISendMessageResponse>>(
                        (r) => {
                            const { messages, newMessage } = addMessage({
                                messageBody,
                                attachedFiles,
                            });
                            setTimeout(() => {
                                setMockMessages(messages);
                                r({
                                    success: true,
                                    result: { message: newMessage },
                                    error: null,
                                });
                            }, 700);
                        },
                    ),
                onSendFn,
                urlId: null,
                participants,
                userEmailAddress,
                subject: buildSubjectFromPatient(
                    patientDetailsWithDisplayNames,
                ),
                workspaceId: organisationId,
                hasPolled: false,
                isNewConversation: true,
                isTestFlow: true,
            };
        }

        return {
            state: "new",
            error: null,
            conversationId: null,
            messages: null,
            getAttachmentUrl: null,
            patient: patientDetailsWithDisplayNames,
            sendMessage: async ({ messageBody, attachedFiles }) =>
                await new Promise<
                    IWrappedResult<
                        Pick<
                            IFirstMessageResponse,
                            "messages" | "urlId" | "workspaceConversationId"
                        >
                    >
                >((r) => {
                    const { messages } = addMessage({
                        messageBody,
                        attachedFiles,
                    });
                    setTimeout(() => {
                        setMockMessages(messages);
                        r({
                            success: true,
                            result: {
                                messages,
                                urlId: null,
                                workspaceConversationId: null,
                            },
                            error: null,
                        });
                    }, 1000);
                }),
            onSendFn,
            urlId: null,
            participants,
            userEmailAddress,
            subject: buildSubjectFromPatient(patientDetailsWithDisplayNames),
            workspaceId: organisationId,
            hasPolled: false,
            isNewConversation: true,
            isTestFlow: true,
        };
    };

    return (
        <ClinicianConversationContext.Provider value={getContext()}>
            {children}
        </ClinicianConversationContext.Provider>
    );
};
