import { DateFormatOptions, DateHelpers, IWrappedResult } from "@accurx/shared";

import { IMessagePatientResponse, IUserResponse } from "api/FlemingDtos";

// These should be in sync
export const syncedDefaultText = {
    pleaseViewYourAttachmentHere:
        "To view your attachment, please follow this link:", // Should be in sync with SmsMessageBuilder.BuildDocumentLinksSignOffForPortal
    pleaseViewYourAttachmentsHere:
        "To view your attachments, please follow this link:", // Should be in sync with SmsMessageBuilder.BuildDocumentLinksSignOffForPortal
    patientResponseText: "TO RESPOND, please follow this link:", // Should be in sync with SmsMessageBuilder._patientResponseLinkTextStart
    scheduledVideoConsultText:
        "Date: ,\nTime: ,\nYou will receive a link to the Video Consult approximately 18 hours before the appointment.",
    singleLinkToPortal:
        "To RESPOND or VIEW ATTACHMENTS, please follow this link:",
    floreyResponseText: "Please complete this questionnaire:", // Should be in sync with SmsMessageBuilder._floreyLinkTextStart
};

export const syncedCharLength = {
    maxMessageCharCount: 612, // taken from C# definition - SmsConstants.DefaultMessageLengthLimit
    videoConsultationLinkTextMaxLength: 111, // i.e. "To join, please follow this link: https://florey.accurx.com/v/c/*guid*?embed=true\n"
    scheduledVideoConsultDefaultTextLength:
        syncedDefaultText.scheduledVideoConsultText.length,
    // Support multiple attachments where link text looks like: PleaseViewYourDocumentsHere + "\n" + https://accurx.nhs.uk/c/p-4pq2wfbuv4\n\n
    smsAttachmentLinkTextConstLength:
        syncedDefaultText.pleaseViewYourAttachmentHere.length + 40, // 40 is the maximum length of a link to patient portal e.g. https://accurx.nhs.uk/c/p-4pq2wfbuv4\n\n
    smsAttachmentsLinkTextConstLength:
        syncedDefaultText.pleaseViewYourAttachmentsHere.length + 40, // 40 is the maximum length of a link to patient portal e.g. https://accurx.nhs.uk/c/p-4pq2wfbuv4\n\n
    patientPortalResponseTextMaxLength:
        syncedDefaultText.patientResponseText.length + 40, // 40 is the maximum length of a link to patient portal e.g. https://accurx.nhs.uk/c/p-4pq2wfbuv4\n\n
    singleLinkToPortalTextConstLength:
        syncedDefaultText.singleLinkToPortal.length + 40,
    floreyResponseTextMaxLength:
        syncedDefaultText.floreyResponseText.length + 40, // 40 is the maximum length of a link to patient portal e.g. https://accurx.nhs.uk/c/p-4pq2wfbuv4\n\n
};
// These should be in sync -- END

export const generateSignature = (
    user: IUserResponse | undefined | null,
): string => {
    if (user?.messageSignature) {
        return user.messageSignature;
    }
    const name = (user && user.fullName) || "Unknown user";
    return `Thanks,\n` + name;
};

interface MessageSizeValidationResult {
    isSizeValid: boolean;
    messageSize: number;
}

const calcPatientResponseAndAttachmentsLength = (
    isPatientResponseEnabled: boolean,
    numberAttachments: number,
): number => {
    if (isPatientResponseEnabled && numberAttachments > 0) {
        return syncedCharLength.singleLinkToPortalTextConstLength;
    }
    let contentLength = 0;
    if (isPatientResponseEnabled) {
        contentLength += syncedCharLength.patientPortalResponseTextMaxLength;
    }
    if (numberAttachments > 0) {
        contentLength += syncedCharLength.smsAttachmentsLinkTextConstLength;
    }
    return contentLength;
};

export const validateMessageSize = (
    isVideoConsult: boolean,
    isPatientResponseEnabled: boolean,
    numberAttachments: number,
    orgName: string,
    messageBody: string,
    appointmentTime: string | null,
): MessageSizeValidationResult => {
    const videoConsultTextLength =
        isVideoConsult && !appointmentTime
            ? syncedCharLength.videoConsultationLinkTextMaxLength
            : 0;
    const scheduledVideoConsultTextLength =
        isVideoConsult && appointmentTime
            ? syncedCharLength.scheduledVideoConsultDefaultTextLength +
              DateHelpers.formatDate(
                  appointmentTime,
                  DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
              ).length +
              DateHelpers.formatTime(appointmentTime, DateFormatOptions.TIME)
                  .length
            : 0;
    const responseAndAttachmentsText = calcPatientResponseAndAttachmentsLength(
        isPatientResponseEnabled,
        numberAttachments,
    );
    //+1 is to account for new line between message and org name
    const messageSize =
        videoConsultTextLength +
        scheduledVideoConsultTextLength +
        responseAndAttachmentsText +
        messageBody.length +
        orgName.length +
        1;

    return {
        messageSize: messageSize,
        isSizeValid: messageSize <= syncedCharLength.maxMessageCharCount,
    };
};

export const isMessageSentSuccess = (
    sendMessageResponse: IWrappedResult<IMessagePatientResponse>,
    isVideoConsult: boolean,
    videoConsultTime: string | null,
): boolean => {
    if (!sendMessageResponse) return false;

    if (!sendMessageResponse.success || sendMessageResponse.result === null)
        return false;

    if (sendMessageResponse.result.messageText === null) return false;

    if (
        isVideoConsult &&
        !videoConsultTime &&
        (sendMessageResponse.result.videoConsultId === null ||
            sendMessageResponse.result.videoConsultUrl === null)
    )
        return false;

    return true;
};
