import { Tokens } from "@accurx/design";

type BreakpointsType = typeof Tokens.BREAKPOINTS;
type BreakpointSizes = keyof BreakpointsType;

export type Breakpoint = BreakpointsType[BreakpointSizes];

export const Breakpoints: BreakpointsType = Tokens.BREAKPOINTS;

export const matchesResponsiveBreakpoint = (
    width: BreakpointsType[BreakpointSizes],
): boolean => window.matchMedia(`(min-width: ${width})`).matches;
