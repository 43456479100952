import React from "react";

import { Card, Text } from "@accurx/design";

import { AttachFileRow } from "app/workspaceConversations/components/Conversation/AttachFileRow/AttachFileRow";

import { TemplateAttachmentDisplay } from "../../MessageTemplates.types";
import {
    StyledContainer,
    StyledTemplateContentCard,
    StyledText,
} from "./TemplatePreview.styles";

export type TemplatePreviewProps = {
    title: string;
    body: string;
    practiceName: string;
    attachedDocuments?: TemplateAttachmentDisplay[];
};

export const TemplatePreview = ({
    title,
    body,
    practiceName,
    attachedDocuments,
}: TemplatePreviewProps) => {
    const templateBody = `${body}\n${practiceName}`;
    return (
        <StyledContainer>
            <Text as="h1" skinny variant="subtitle">
                {title}
            </Text>
            <Card spacing={0} isElevated={false}>
                <StyledText variant="label">Template contents</StyledText>
                <StyledTemplateContentCard>
                    <Text skinny variant="body">
                        {templateBody}
                    </Text>
                </StyledTemplateContentCard>
            </Card>
            {attachedDocuments?.map((attachment) => (
                <AttachFileRow
                    attachmentId={attachment.documentId}
                    previewUrl={attachment.previewUrl}
                    fileName={attachment.fileName}
                    fileSize={attachment.fileSize}
                    handleRemoveFile={() => null}
                    key={attachment.documentId}
                    isControllable={false}
                />
            ))}
        </StyledContainer>
    );
};
