import { Log } from "@accurx/shared";

export const MAX_FILE_SIZE_IN_MB = 2;
export const ALLOWED_FILE_EXTENSIONS = ["csv", "tsv", "txt"];
export const ALLOWED_FILE_TYPES = [
    "text/csv",
    "text/tab-separated-values",
    "text/plain",
    "text/x-csv",
];

type ValidatedFileSuccess = {
    isValid: true;
    failureReasons?: never;
};

type ValidatedFileError = {
    isValid: false;
    failureReasons: string[];
};

type ValidatedFile = ValidatedFileSuccess | ValidatedFileError;

export const validateCSVFile = (file: File): ValidatedFile => {
    const failureReasons = [];
    try {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (
            fileExtension === undefined ||
            ALLOWED_FILE_EXTENSIONS.indexOf(fileExtension) === -1
        ) {
            failureReasons.push("It must be in .csv, .tsv, or .txt format.");
        }

        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            Log.info(
                `Unexpected file type found for batch upload: ${file.type}`,
            );
        }

        if (file.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024) {
            failureReasons.push(
                `It must be less than ${MAX_FILE_SIZE_IN_MB}MB in size.`,
            );
        }

        if (file.size === 0) {
            failureReasons.push("It must be a non-empty file.");
        }

        if (failureReasons.length === 0) {
            return { isValid: true };
        }

        return {
            isValid: false,
            failureReasons,
        };
    } catch (e) {
        return {
            isValid: false,
            failureReasons:
                failureReasons.length > 0
                    ? failureReasons
                    : ["This file is not valid, please try again."],
        };
    }
};
