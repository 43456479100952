import { NativeFetchError } from "domains/native/errors";
import { SubscribeSignalRLifecycleEvents } from "domains/native/schemas/SubscribeSignalRLifecycleEventsSchema";
import { useNativeSubscription } from "domains/native/subscriptions/context";

import { QueryHook } from "../../inbox/hooks/queries/types";

export type UseNativeSignalRConnectionQuery = QueryHook<
    undefined,
    SubscribeSignalRLifecycleEvents,
    NativeFetchError
>;

export const useNativeSignalRConnectionQuery: UseNativeSignalRConnectionQuery =
    () => useNativeSubscription("SubscribeSignalRLifecycleEvents");
