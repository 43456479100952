import { partitionItemIdsByReadState } from "domains/concierge/internal/util/partitionItemIdsByReadState";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import { ConciergeUpdates } from "domains/concierge/testing";
import { Conversation } from "domains/concierge/types";
import sortBy from "lodash/sortBy";

const mapConversationToUnreadsSummary = (
    conversation: Conversation,
    userId: string,
): UnreadItemsSummary | undefined => {
    const { unread } = partitionItemIdsByReadState(conversation, userId);

    if (!unread.length) {
        return undefined;
    }

    return {
        conversationId: conversation.id,
        latestToken: conversation.latestToken,
        patientId: conversation.regardingPatientId,
        assignee: conversation.assignee,
        status: conversation.status,
        system: conversation.source.system,
        // Sort the item IDs to make them more efficient to search
        itemIds: sortBy(unread),
    };
};

export const mapConciergeUpdatesToUnreadItemsSummaries = (
    updates: ConciergeUpdates,
    userId: string,
): UnreadItemsSummary[] => {
    const unreadsSummaries: UnreadItemsSummary[] = [];

    for (const conversation of updates.conversations) {
        const unreadSummary = mapConversationToUnreadsSummary(
            conversation,
            userId,
        );

        if (unreadSummary) {
            unreadsSummaries.push(unreadSummary);
        }
    }

    return unreadsSummaries;
};
