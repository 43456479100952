import React, { ReactNode, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import { getBatchMessageUncontactablePatientsCsvDownloadUrl } from "@accurx/batch-messaging";
import { Feedback, Icon, Link, Text } from "@accurx/design";
import * as Collapsible from "@radix-ui/react-collapsible";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { splitItemsByContactable } from "../pages/utils/contactable";
import { StyledReviewUploadedPatients } from "./BatchUploadPopulatedDemographicsReview.styles";
import { ContactablePatientsTable } from "./ContactablePatientsTable";
import { TextWithHalo } from "./TextWithHalo";
import { UncontactablePatientsTable } from "./UncontactablePatientsTable";

type BatchUploadReviewPatientsSectionProps = {
    hasPatients: boolean;

    /** If there's more than one patient in the array,
     * whether to show the content or not */
    showContent: boolean;
    /** If there's more than one patient in the array,
     * changes whether the content is shown or not */
    toggleShowContent: () => void;

    /** Will dictate the header title and icon colour */
    header: JSX.Element;
    children: ReactNode;
};

const BatchUploadReviewPatientsSection = ({
    hasPatients,
    header,
    showContent,
    toggleShowContent,
    children,
}: BatchUploadReviewPatientsSectionProps) => {
    if (!hasPatients) {
        return (
            <StyledReviewUploadedPatients.Item>
                {header}
            </StyledReviewUploadedPatients.Item>
        );
    }

    return (
        <Collapsible.Root open={showContent} onOpenChange={toggleShowContent}>
            <StyledReviewUploadedPatients.Item>
                {header}
                <Collapsible.Trigger asChild>
                    <StyledReviewUploadedPatients.ItemHeaderButton
                        text={showContent ? "Hide details" : "See details"}
                        icon={{
                            name: "List",
                            colour: "blue",
                        }}
                        theme="secondary"
                    />
                </Collapsible.Trigger>
            </StyledReviewUploadedPatients.Item>
            <Collapsible.Content>
                <StyledReviewUploadedPatients.ItemContent>
                    {children}
                </StyledReviewUploadedPatients.ItemContent>
            </Collapsible.Content>
        </Collapsible.Root>
    );
};

const MIN_PERCENTAGE_OVERRIDE_WARNING_THRESHOLD = 10;
const MIN_PATIENTS_OVERRIDE_WARNING_THRESHOLD = 5;

type BatchUploadPopulatedDemographicsReviewProps = {
    uploadedPatients: BatchMessageItemSummary[];
    onRemoveAllClick: () => void;
    workspaceId: string;
    batchId: string;
    isTrustFlow: boolean;

    /** Specific to self-book */
    isAppointmentAvailabilityWarningVisible?: boolean;
    /** Specific to self-book */
    numAvailableAppointmentsForSlotType?: number;
    /** Specific to self-book */
    weeksAvailable?: number;
};

export const BatchUploadPopulatedDemographicsReview = ({
    isAppointmentAvailabilityWarningVisible = false,
    numAvailableAppointmentsForSlotType = 0,
    weeksAvailable,
    uploadedPatients,
    onRemoveAllClick,
    workspaceId,
    batchId,
    isTrustFlow,
}: BatchUploadPopulatedDemographicsReviewProps) => {
    const track = useAnalytics();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { contactable, uncontactable } = splitItemsByContactable(
        uploadedPatients,
        batchId,
    );
    const totalContactable = contactable.length;
    const totalUncontactable = uncontactable.length;

    const [
        isContactablePatientsSectionExpanded,
        setIsContactablePatientsSectionExpanded,
    ] = useState(false);
    const [
        isNotContactablePatientsSectionExpanded,
        setIsNotContactablePatientsSectionExpanded,
        // Open uncontactable section by default if there are no contactable patients
    ] = useState(totalContactable === 0);

    const onDownloadNonContactable = () => {
        track("BatchDownloadList Link Click", {
            countAccepted: totalContactable,
            countNotAccepted: totalUncontactable,
        });
        window.open(
            getBatchMessageUncontactablePatientsCsvDownloadUrl({
                workspaceId,
                batchId,
            }),
        );
    };

    const numberOfContactableMobileNumbers = contactable.filter(
        (patient) => patient.contact.type === "Mobile",
    ).length;
    const numberOfOverriddenMobileNumbers = contactable.filter(
        (patient) => patient.contact.isOverride,
    ).length;

    const mobileOverridePercentage = numberOfContactableMobileNumbers
        ? (numberOfOverriddenMobileNumbers / numberOfContactableMobileNumbers) *
          100
        : 0;
    const isOverrideWarningVisible =
        mobileOverridePercentage >= MIN_PERCENTAGE_OVERRIDE_WARNING_THRESHOLD &&
        numberOfOverriddenMobileNumbers >=
            MIN_PATIENTS_OVERRIDE_WARNING_THRESHOLD;

    return (
        <>
            {isAppointmentAvailabilityWarningVisible && (
                <Feedback
                    colour="warning"
                    title={`Only ${numAvailableAppointmentsForSlotType} ${
                        numAvailableAppointmentsForSlotType === 1
                            ? "appointment"
                            : "appointments"
                    } available in the next ${weeksAvailable} weeks.`}
                >
                    <Text skinny>
                        There will be about{" "}
                        {totalContactable - numAvailableAppointmentsForSlotType}{" "}
                        patients that cannot book in. Reduce the patient list or
                        add more appointments.
                    </Text>
                </Feedback>
            )}
            {totalContactable === 0 && (
                <Feedback
                    colour="error"
                    title="None of the patients you provided can be contacted"
                >
                    You can see the reasons why we can’t contact them. Remove
                    all and try again.
                </Feedback>
            )}
            {isOverrideWarningVisible && (
                <Feedback colour="warning">
                    A significant number of the details you have provided do not
                    match those on the record system/NHS spine. Please review
                    your list of patients in case of any errors.
                </Feedback>
            )}
            <StyledReviewUploadedPatients.Group>
                <StyledReviewUploadedPatients.Item>
                    <StyledReviewUploadedPatients.ItemHeader>
                        <TextWithHalo colour="silver">
                            {uploadedPatients.length}{" "}
                        </TextWithHalo>
                        <StyledReviewUploadedPatients.ItemHeaderText>
                            Patient{uploadedPatients.length === 1 ? "" : "s"}{" "}
                            provided
                        </StyledReviewUploadedPatients.ItemHeaderText>
                    </StyledReviewUploadedPatients.ItemHeader>
                    <StyledReviewUploadedPatients.ItemHeaderButton
                        icon={{
                            name: "Bin",
                            colour: "red",
                        }}
                        text="Remove all"
                        theme="secondary"
                        onClick={() => {
                            ChainAnalyticsTracker.trackBatchRemovePatientListClick(
                                {
                                    ...analyticsLoggedInProps,
                                    batchPatientListSize: totalContactable,
                                    batchCapacityWarningSeen:
                                        isAppointmentAvailabilityWarningVisible,
                                    batchSlotAvailable:
                                        numAvailableAppointmentsForSlotType,
                                    countPatientNotContactable:
                                        totalUncontactable,
                                    isTrustFlow,
                                    mobileOverridePercentage,
                                    hasOverrideWarning:
                                        isOverrideWarningVisible,
                                },
                            );

                            onRemoveAllClick();
                        }}
                    />
                </StyledReviewUploadedPatients.Item>
                <BatchUploadReviewPatientsSection
                    header={
                        <StyledReviewUploadedPatients.ItemHeaderWrapper>
                            <StyledReviewUploadedPatients.ItemHeader>
                                <TextWithHalo colour="green">
                                    {totalContactable}{" "}
                                </TextWithHalo>
                                <StyledReviewUploadedPatients.ItemHeaderText>
                                    Can be contacted
                                </StyledReviewUploadedPatients.ItemHeaderText>
                            </StyledReviewUploadedPatients.ItemHeader>
                            {numberOfOverriddenMobileNumbers > 0 && (
                                <Text skinny variant="label" colour="metal">
                                    <Icon name="Flag" size={3} colour="metal" />
                                    {numberOfOverriddenMobileNumbers} overridden
                                    number(s)
                                </Text>
                            )}
                        </StyledReviewUploadedPatients.ItemHeaderWrapper>
                    }
                    hasPatients={totalContactable > 0}
                    showContent={isContactablePatientsSectionExpanded}
                    toggleShowContent={() => {
                        if (!isContactablePatientsSectionExpanded) {
                            ChainAnalyticsTracker.trackBatchReviewPatientDetailViewClick(
                                {
                                    ...analyticsLoggedInProps,
                                    isTrustFlow,
                                    patientDetailSection: "Can be contacted",
                                },
                            );
                        }
                        setIsContactablePatientsSectionExpanded(
                            (prev) => !prev,
                        );
                    }}
                >
                    {numberOfOverriddenMobileNumbers > 0 && (
                        <Feedback
                            colour="information"
                            title="You have overridden some patient numbers"
                            iconName="Flag"
                        >
                            <Text skinny>
                                We are using the details you provided for the
                                patient instead of those received from the
                                record system/NHS spine
                            </Text>
                        </Feedback>
                    )}
                    <ContactablePatientsTable patients={contactable} />
                </BatchUploadReviewPatientsSection>
                <BatchUploadReviewPatientsSection
                    header={
                        <StyledReviewUploadedPatients.ItemHeaderWrapper>
                            <StyledReviewUploadedPatients.ItemHeader>
                                <TextWithHalo colour="red">
                                    {totalUncontactable}{" "}
                                </TextWithHalo>
                                <StyledReviewUploadedPatients.ItemHeaderText>
                                    Cannot be contacted
                                </StyledReviewUploadedPatients.ItemHeaderText>
                            </StyledReviewUploadedPatients.ItemHeader>
                            {totalUncontactable > 0 && (
                                <StyledReviewUploadedPatients.ItemHeaderButton
                                    icon={{
                                        name: "Save",
                                        colour: "blue",
                                    }}
                                    text="Download list"
                                    theme="secondary"
                                    onClick={onDownloadNonContactable}
                                />
                            )}
                        </StyledReviewUploadedPatients.ItemHeaderWrapper>
                    }
                    hasPatients={totalUncontactable > 0}
                    showContent={isNotContactablePatientsSectionExpanded}
                    toggleShowContent={() => {
                        if (!isNotContactablePatientsSectionExpanded) {
                            ChainAnalyticsTracker.trackBatchReviewPatientDetailViewClick(
                                {
                                    ...analyticsLoggedInProps,
                                    isTrustFlow,
                                    patientDetailSection: "Cannot be contacted",
                                },
                            );
                        }
                        setIsNotContactablePatientsSectionExpanded(
                            (prev) => !prev,
                        );
                    }}
                >
                    <Feedback
                        colour="secondary"
                        title="There are different reasons why we can’t contact a patient"
                    >
                        <Link
                            href="https://support.accurx.com/en/articles/6867494-batch-messaging-how-to-use-guide#h_ad8854a792"
                            openInNewTab
                            onClick={() => {
                                track("BatchCsvUploadResource Tab Click", {
                                    resourceTitle:
                                        "Read more about these reasons in this article",
                                });
                            }}
                        >
                            Read more about these reasons in this article
                            <Icon name="OpenWindow" colour="blue" size={3} />
                        </Link>
                    </Feedback>
                    <UncontactablePatientsTable
                        batchId={batchId}
                        patients={uncontactable}
                    />
                </BatchUploadReviewPatientsSection>
            </StyledReviewUploadedPatients.Group>
        </>
    );
};
