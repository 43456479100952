import { Ds } from "@accurx/design";

type MessageStatusLabel = {
    text: string;
    badgeColor: Ds.BadgeProps["color"];
    badgeName: string;
};

const BatchMessageStatuses: { [key: string]: MessageStatusLabel } = {
    Scheduled: {
        text: "A batch message is scheduled to be sent.",
        badgeColor: "blue",
        badgeName: "Scheduled",
    },
    Sending: {
        text: "A batch message is sending or waiting for delivery confirmation.",
        badgeColor: "yellow",
        badgeName: "Sending",
    },
    Done: {
        text: "A batch message has finished delivering. We do not get delivery confirmation for an email.",
        badgeColor: "green",
        badgeName: "Done",
    },
    Cancelled: {
        text: "A batch message has been cancelled.",
        badgeColor: "greyscale",
        badgeName: "Cancelled",
    },
    Saved: {
        text: "A batch message has been saved to medical records.",
        badgeColor: "green",
        badgeName: "Saved",
    },
    Saving: {
        text: "A staggered batch message is saving to medical records.",
        badgeColor: "yellow",
        badgeName: "Saving",
    },
};

export { BatchMessageStatuses };

export const DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT =
    "If you don’t want this appointment, we’ll stop sending you invites. Please call us if you change your mind.";
