import { ReactNode, useMemo } from "react";

import { useTransport } from "@accurx/transport";
import { useClinicianMessagingUnreadsPoller } from "domains/concierge/internal/api/clinicianMessaging/pollers/useClinicianMessagingUnreadsPoller";
import {
    ConciergeContext,
    ConciergeMetaProvider,
} from "domains/concierge/internal/context";
import { useConnectionStateChangeDispatcher } from "domains/concierge/internal/hooks/useConnectionStateChangeDispatcher";
import { useConversationGroupGarbageCollection } from "domains/concierge/internal/hooks/useConversationGroupGarbageCollection";
import { useSignalRSubscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { useStateReconciler } from "domains/concierge/internal/hooks/useStateReconciler";
import { createStore } from "domains/concierge/internal/store";
import { Provider as ReduxProvider } from "react-redux";

import { useInitialSummariesPoller } from "./api/ticket/pollers/useInitialSummariesPoller";
import { useTicketUnreadItemsPoller } from "./api/ticket/pollers/useTicketUnreadItemsPoller";
import { OnPatientThreadItemChanged } from "./api/ticket/subscriptions/OnPatientThreadItemChanged";
import { OnUserGroupChanged } from "./api/ticket/subscriptions/OnUserGroupChanged";
import { ConciergeMeta } from "./types/ConciergeMeta";

/**
 * Run initialization code needed by "untrusted" concierge layer clients, trusted or
 * Trusted, as of Jan 2024, = 2fa'd or with a Desktop cookie.
 * We currently do not spin up unread items + initial summaries pollers, or
 * SignalR subscriptions, for untrusted users.
 *
 * This is modelled as a null component rather than a hook so it can
 * sit within the ConciergeReduxProvider more easily.
 */
const ConciergeUntrustedDeviceInitializer = () => {
    useStateReconciler();
    useConversationGroupGarbageCollection();
    useConnectionStateChangeDispatcher();
    return null;
};

/**
 * Run initialization code needed by "trusted" concierge layer clients.
 * Trusted, as of Jan 2024, = 2fa'd or with a Desktop cookie.
 * We currently only spin up unread items + initial summaries pollers, and
 * SignalR subscriptions, for trusted users.
 *
 * We delay this initialization until after we have a confirmed SignalR
 * connection, (or have failed to connect three times) to avoid the case
 * where the initial unread items endpoint returns before SignalR has
 * connected and is immediately marked as stale.
 *
 * This is modelled as a null component rather than a hook so it can
 * sit within the ConciergeReduxProvider more easily.
 */
const ConciergeTrustedDeviceInitializer = () => {
    useStateReconciler();
    useConversationGroupGarbageCollection();
    useConnectionStateChangeDispatcher();

    useTicketUnreadItemsPoller();
    useInitialSummariesPoller();

    useSignalRSubscription(OnPatientThreadItemChanged);
    useSignalRSubscription(OnUserGroupChanged);

    return null;
};

const ConciergeClinicianMessagingUnreadsPoller = () => {
    useClinicianMessagingUnreadsPoller();
    return null;
};

const ConciergeReduxProvider = ({
    workspaceId,
    userId,
    isTrustedDevice,
    children,
}: ConciergeMeta & { children: ReactNode }) => {
    const store = useMemo(
        () => createStore({ workspaceId, userId, isTrustedDevice: true }),
        [workspaceId, userId],
    );

    return (
        <ConciergeMetaProvider value={{ workspaceId, userId, isTrustedDevice }}>
            <ReduxProvider context={ConciergeContext} store={store}>
                {children}
            </ReduxProvider>
        </ConciergeMetaProvider>
    );
};

export const ConciergeProvider = ({
    workspaceId,
    userId,
    isTrustedDevice,
    children,
    withClinicianMessaging,
}: ConciergeMeta & {
    withClinicianMessaging?: boolean;
    children: ReactNode;
}) => {
    const { connectionState } = useTransport();

    return (
        <ConciergeReduxProvider
            workspaceId={workspaceId}
            userId={userId}
            isTrustedDevice={isTrustedDevice}
        >
            {/* Children are rendered immediately */}
            {children}

            {/* The Concierge layer is initialized immediately for untrusted devices */}
            {!isTrustedDevice && <ConciergeUntrustedDeviceInitializer />}

            {/* For trusted devices, the Concierge layer is initialized after SignalR initialization is complete */}
            {isTrustedDevice && connectionState !== "Initialising" && (
                <ConciergeTrustedDeviceInitializer />
            )}

            {withClinicianMessaging && (
                <ConciergeClinicianMessagingUnreadsPoller />
            )}
        </ConciergeReduxProvider>
    );
};
