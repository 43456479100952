import { useEffect, useMemo, useState } from "react";

import { Feedback } from "@accurx/design/dist/components/Feedback/Feedback";
import { DateHelpers, NhsNumberHelpers } from "@accurx/shared";
import { toast } from "react-toastify";

import { useSafeAsync } from "api/Api.utils";
import FlemingApiClient from "api/FlemingApiClient";
import { isConversationItemUnread } from "app/workspaceConversations/utils/conversation.utils";
import { getPatientNhsNumber } from "app/workspaceConversations/utils/patient.utils";
import { ConversationItem } from "shared/concierge/conversations/types/item.types";

import { PatientType } from "./Conversation.types";

type PatientDetailsRequiredToSendMessage = {
    mobileNumber: string | null;
    patientToken: string;
};

type useFetchPatientDataProps = {
    patient: PatientType;
    workspaceId: number | null;
};

type useFetchPatientDataResult = {
    result: PatientDetailsRequiredToSendMessage;
    success: boolean;
    isLoading: boolean;
};

export const useFetchPatientData = ({
    patient,
    workspaceId,
}: useFetchPatientDataProps): useFetchPatientDataResult => {
    const safeAsync = useSafeAsync();
    const [patientDetails, setPatientDetails] =
        useState<PatientDetailsRequiredToSendMessage>({
            mobileNumber: null,
            patientToken: "",
        });
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPatientData = async (patient: PatientType | null) => {
            if (!patient) return;

            const nhsNumber = getPatientNhsNumber(patient.externalIds);

            if (
                !nhsNumber ||
                NhsNumberHelpers.validateNhsNumber(nhsNumber).success === false
            ) {
                setIsLoading(false);
                return;
            }

            const {
                d: dateOfBirthDay,
                m: dateOfBirthMonth,
                y: dateOfBirthYear,
            } = DateHelpers.getDateParts(patient.dateOfBirth) || {
                d: NaN,
                m: NaN,
                y: NaN,
            };

            const result = await safeAsync(
                FlemingApiClient.searchForPatientByNhsNumber(
                    workspaceId,
                    nhsNumber,
                    dateOfBirthYear,
                    dateOfBirthMonth,
                    dateOfBirthDay,
                    false,
                ),
            );

            // if the Patient Token was retrieved this means the search was successful.
            // There's no need to check the mobile number, in some cases it might be null.
            if (result.searchedResult?.patientToken) {
                setSuccess(true);
                setIsLoading(false);
                setPatientDetails({
                    mobileNumber:
                        result.searchedResult?.patient?.mobileNumber || null,
                    patientToken: result.searchedResult?.patientToken,
                });
            } else {
                setSuccess(false);
                setIsLoading(false);
            }
        };

        const existingPatientToken = patient?.patientToken;

        if (existingPatientToken) {
            setSuccess(true);
            setIsLoading(false);
            setPatientDetails({
                mobileNumber: patient.mobileNumber,
                patientToken: existingPatientToken,
            });
        }

        if (patient?.patientId) {
            fetchPatientData(patient);
        }
    }, [workspaceId, patient, safeAsync]);

    return {
        result: patientDetails,
        isLoading,
        success,
    };
};

// Returns the id of the first unread conversation item in the given list of items.
// We need this id to know where to scroll the user to when opening a conversation
// with unread messages. We memoize the result and only recalculate when the number
// of conversation items changes.
export const useFirstUnreadItemId = ({
    items,
    currentUserId,
}: {
    items: ConversationItem[];
    currentUserId: string;
}): string | null => {
    const firstUnreadItemId = useMemo(
        () =>
            items.find((item) => isConversationItemUnread(item, currentUserId))
                ?.id,
        [items.length], // eslint-disable-line react-hooks/exhaustive-deps
    ); // Recalculate when the number of conversation items changes.

    return firstUnreadItemId || null;
};

export const useQuestionnairePreview = (
    workspaceId: number,
    questionnaireId: string,
) => {
    const [isLoading, setIsLoading] = useState(false);

    return {
        requestPreview: async () => {
            setIsLoading(true);
            const response = await FlemingApiClient.requestQuestionnairePreview(
                {
                    questionnaireId,
                    workspaceId,
                },
            );
            setIsLoading(false);

            if (response.success && response.result?.enrolmentUrl) {
                window.open(response.result.enrolmentUrl, "_blank");
            } else {
                toast(
                    <Feedback
                        colour="error"
                        title="Error loading questionnaire preview"
                    />,
                );
            }
        },
        isLoading,
    };
};
