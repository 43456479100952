import { SupportUrls } from "shared/constants/supportUrls";

import { InviteStatusType } from "./VaccineAllPatientsInvitedDTO";
import { StatusType, VaccineCourse } from "./VaccineDeliveryDTO";

export interface StatusLabelInfo {
    filterStatus: string; // internal value sent over the API, used for filter queries and allowed actions
    displayText: string; // display string in the UI, may diverge from the internal value over time
    colourClass: string; // CSS class defining the colour of the status label
    description: string; // description shown in the LHS filter list
    supportUrl: string | null; // where to read more information about the status in question
    statusType: StatusType; // either ToBook or Other
    vaccineCourse: VaccineCourse[]; // list of vaccine course tabs to show this filter under
}

export const AwaitingResponseFirst: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingResponseLegacy,
    displayText: InviteStatusType.AwaitingResponseFirst,
    colourClass: "vaccine-awaiting-response-yellow",
    description:
        "Patients who have received a link to book in, but have not booked",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Primary],
};

export const AwaitingResponseBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingResponseLegacy,
    displayText: "Awaiting response",
    colourClass: "vaccine-awaiting-response-yellow",
    description:
        "Patients who have received a link to book in, but have not booked",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const AwaitingResponseSecond: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingResponseSecond,
    displayText: InviteStatusType.AwaitingResponseSecond,
    colourClass: "vaccine-awaiting-response-yellow",
    description:
        "Patients who have received a link to book in, but have not booked",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Primary],
};

export const AwaitingSecondInvite: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingSecondInvite,
    displayText: "Awaiting invite - 2nd",
    colourClass: "vaccine-awaiting-second-orange",
    description:
        "Patients who have had their 1st vaccination, and need to be invited for their 2nd vaccination",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Primary],
};

export const AwaitingBoosterInvite: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingBoosterInvite,
    displayText: "Awaiting invite",
    colourClass: "vaccine-awaiting-second-orange",
    description:
        "Patients who have had their primary vaccinations, and need to be invited for their booster vaccination",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Booster],
};

export const AwaitingSeasonalBoosterInvite: StatusLabelInfo = {
    filterStatus: InviteStatusType.AwaitingBoosterInvite,
    displayText: "Awaiting invite",
    colourClass: "vaccine-awaiting-second-orange",
    description: "Patients who require a seasonal booster vaccine",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const BookedBoth: StatusLabelInfo = {
    filterStatus: InviteStatusType.BookedBoth,
    displayText: "Booked - 2nd",
    colourClass: "vaccine-booked-green",
    description: "Patients who are booked in for their 2nd vaccination",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const BookedFirstOnly: StatusLabelInfo = {
    filterStatus: InviteStatusType.BookedFirstOnly,
    displayText: "Booked - 1st",
    colourClass: "vaccine-booked-first-orange",
    description: "Patients who are booked in for their 1st vaccination",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const BookedBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.BookedBooster,
    displayText: "Booked - booster",
    colourClass: "vaccine-booked-green",
    description: "Patients who are booked in for their booster vaccination",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const Cancelled: StatusLabelInfo = {
    filterStatus: InviteStatusType.Cancelled,
    displayText: InviteStatusType.Cancelled,
    colourClass: "vaccine-cancelled-blue",
    description:
        "Patients who have had their invitation cancelled, and will need to be invited again to book",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const CancelledBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.Cancelled,
    displayText: "Cancelled",
    colourClass: "vaccine-cancelled-blue",
    description:
        "Patients who have had their booster invitation cancelled, and will need to be invited again to book",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const CompletedPrimary: StatusLabelInfo = {
    filterStatus: InviteStatusType.Completed,
    displayText: "Had primary",
    colourClass: "vaccine-completed-green",
    description: "Patients who have had all their primary course vaccinations",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const CompletedBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.Completed,
    displayText: "Had booster",
    colourClass: "vaccine-completed-green",
    description: "Patients who have had their booster vaccination",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const Declined: StatusLabelInfo = {
    filterStatus: InviteStatusType.Declined,
    displayText: InviteStatusType.Declined,
    colourClass: "vaccine-declined-blue",
    description:
        "Patients who have indicated they don't want to book their vaccinations",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const DeclinedBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.Declined,
    displayText: "Declined",
    colourClass: "vaccine-declined-blue",
    description:
        "Patients who have indicated they don't want to book their booster vaccinations",
    supportUrl: null,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const Rejected: StatusLabelInfo = {
    filterStatus: InviteStatusType.Rejected,
    displayText: "Already uploaded",
    colourClass: "vaccine-rejected-grey",
    description: "Patients who have already been invited",
    supportUrl: SupportUrls.AlreadyInvitedRecall,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Primary,
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const RejectedIneligible: StatusLabelInfo = {
    filterStatus: InviteStatusType.RejectedIneligible,
    displayText: "Not eligible",
    colourClass: "vaccine-rejected-grey",
    description: "Patients who have not had their initial vaccinations",
    supportUrl: SupportUrls.RejectedIneligible,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const NotInNetwork: StatusLabelInfo = {
    filterStatus: InviteStatusType.NotInNetwork,
    displayText: "Not in network",
    colourClass: "vaccine-rejected-grey",
    description: "Patients who have been already invited in another network",
    supportUrl: SupportUrls.NotInNetworkRecall,
    statusType: StatusType.Other,
    vaccineCourse: [VaccineCourse.Primary],
};

export const NotInNetworkBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.NotInNetwork,
    displayText: "Not in network",
    colourClass: "vaccine-rejected-grey",
    description:
        "Patients who have been already invited to their booster vaccinations by another network",
    supportUrl: SupportUrls.NotInNetworkRecall,
    statusType: StatusType.Other,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const ToManuallyBookFirst: StatusLabelInfo = {
    filterStatus: InviteStatusType.ToManuallyBookLegacy,
    displayText: InviteStatusType.ToManuallyBookFirst,
    colourClass: "vaccine-manually-book-red",
    description:
        "Patients who cannot be sent an SMS invite, and need to manually be booked in",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Primary],
};

export const ToManuallyBookBooster: StatusLabelInfo = {
    filterStatus: InviteStatusType.ToManuallyBookLegacy,
    displayText: "To manually book",
    colourClass: "vaccine-manually-book-red",
    description:
        "Patients who cannot be sent an SMS invite, and need to manually be booked in",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [
        VaccineCourse.Booster,
        VaccineCourse.BoosterSpring2022,
        VaccineCourse.BoosterAutumn2022,
        VaccineCourse.BoosterSpring2023,
        VaccineCourse.BoosterAutumn2023,
        VaccineCourse.BoosterSpring2024,
        VaccineCourse.BoosterAutumn2024,
    ],
};

export const ToManuallyBookSecond: StatusLabelInfo = {
    filterStatus: InviteStatusType.ToManuallyBookSecond,
    displayText: InviteStatusType.ToManuallyBookSecond,
    colourClass: "vaccine-manually-book-red",
    description:
        "Patients who cannot be sent an SMS invite, and need to manually be booked in",
    supportUrl: null,
    statusType: StatusType.ToBook,
    vaccineCourse: [VaccineCourse.Primary],
};

// N.B. Booster patients are also returned with AwaitingResponseLegacy and ToManuallyBookLegacy
// but we want to map these to different string just for booster view
export const getPatientStatusInfo = (
    patientInviteStatus: string,
    vaccineCourse: VaccineCourse,
): StatusLabelInfo => {
    switch (patientInviteStatus) {
        case InviteStatusType.AwaitingResponseFirst:
        case InviteStatusType.AwaitingResponseLegacy:
            if (vaccineCourse !== VaccineCourse.Primary) {
                return AwaitingResponseBooster;
            }
            return AwaitingResponseFirst;
        case InviteStatusType.AwaitingResponseSecond:
            return AwaitingResponseSecond;
        case InviteStatusType.AwaitingSecondInvite:
            return AwaitingSecondInvite;
        case InviteStatusType.AwaitingBoosterInvite:
            return AwaitingBoosterInvite;
        case InviteStatusType.BookedBoth:
            return BookedBoth;
        case InviteStatusType.BookedFirstOnly:
            return BookedFirstOnly;
        case InviteStatusType.BookedBooster:
            return BookedBooster;
        case InviteStatusType.Cancelled:
            if (vaccineCourse !== VaccineCourse.Primary) {
                return CancelledBooster;
            }
            return Cancelled;
        case InviteStatusType.Completed:
            return vaccineCourse !== VaccineCourse.Primary
                ? CompletedBooster
                : CompletedPrimary;
        case InviteStatusType.Declined:
            return vaccineCourse !== VaccineCourse.Primary
                ? DeclinedBooster
                : Declined;
        case InviteStatusType.Rejected:
            return Rejected;
        case InviteStatusType.RejectedIneligible:
            return RejectedIneligible;
        case InviteStatusType.NotInNetwork:
            if (vaccineCourse !== VaccineCourse.Primary) {
                return NotInNetworkBooster;
            }
            return NotInNetwork;
        case InviteStatusType.ToManuallyBookFirst:
        case InviteStatusType.ToManuallyBookLegacy:
            if (vaccineCourse !== VaccineCourse.Primary) {
                return ToManuallyBookBooster;
            }
            return ToManuallyBookFirst;
        case InviteStatusType.ToManuallyBookSecond:
            return ToManuallyBookSecond;
        default:
            return {
                filterStatus: patientInviteStatus,
                displayText: patientInviteStatus,
                colourClass: "vaccine-rejected-grey",
                description: `No description available for '${patientInviteStatus}'`,
                supportUrl: null,
                statusType: StatusType.All,
                vaccineCourse: [VaccineCourse.Primary, VaccineCourse.Booster],
            };
    }
};

// Statuses to show in the filters sidebar
export const allStatuses = [
    ToManuallyBookFirst,
    AwaitingResponseFirst,
    AwaitingResponseBooster,
    AwaitingSecondInvite,
    AwaitingBoosterInvite,
    AwaitingSeasonalBoosterInvite,
    ToManuallyBookSecond,
    ToManuallyBookBooster,
    AwaitingResponseSecond,
    CompletedBooster,
    CompletedPrimary,
    Cancelled,
    CancelledBooster,
    BookedBoth,
    BookedFirstOnly,
    BookedBooster,
    Declined,
    DeclinedBooster,
    NotInNetwork,
    NotInNetworkBooster,
];

export const toBookStatuses = allStatuses.filter(
    (status) => status.statusType === StatusType.ToBook,
);

export const otherStatuses = allStatuses.filter(
    (status) => status.statusType === StatusType.Other,
);

export const determineStatusType = (filters: string[]): StatusType => {
    if (filters.length === 0) {
        return StatusType.All;
    }
    const toBookFilters = toBookStatuses.map((status) => status.filterStatus);
    const otherFilters = otherStatuses.map((status) => status.filterStatus);
    if (filters.some((filter) => toBookFilters.includes(filter))) {
        return StatusType.ToBook;
    }
    if (filters.some((filter) => otherFilters.includes(filter))) {
        return StatusType.Other;
    }
    return StatusType.ToBook;
};

export type StatusFilterLabelInfo = {
    status: string;
    displayValue: string;
    description: string;
    checked: boolean;
    onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
