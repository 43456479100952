/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { ConversationSummary } from "app/workspaceConversations/components/Conversation/Conversation.types";
import {
    getAssigneeFromRuleset,
    getSentByUserIdFromRuleset,
} from "shared/concierge/conversations/ConversationGroup.utils";
import { AssigneeSummary } from "shared/concierge/conversations/types/assignee.types";
import { ConversationGroupRuleset } from "shared/concierge/conversations/types/conversationGroup.types";
import {
    ConversationItem,
    PatientMatchState,
} from "shared/concierge/conversations/types/item.types";

import { FlemingAnalyticsTracker } from ".";
import { PatientConversationMatchStatus } from "./FlemingAnalytics";

export const getAnalyticsConversationMatchType = (
    state: PatientMatchState | null,
): PatientConversationMatchStatus => {
    switch (state) {
        case "NoMatch":
            return "Unmatched";
        case "Suggested":
            return "Suggested";
        case "Verified":
            return "Matched";
        default:
            return "Unknown";
    }
};

/**
 * Returns the type of assignee from a given conversation.
 * @param assignee the assignee of a conversation.
 * @param currentUserId the ID of the current user.
 * @returns type of assignee.
 */
export const getAnalyticsConversationsAssigneeType = (
    assignee: AssigneeSummary,
    currentUserId?: string,
): FlemingAnalyticsTracker.AssigneeType => {
    switch (assignee.type) {
        case "None":
            return "None";
        case "User": {
            if (assignee.id === currentUserId) {
                return "UserSelf";
            }
            return "UserOther";
        }
        case "Team":
            return "Team";
    }
};

/**
 * From a fully loaded conversation it identifies who initiated the conversation(Patient or Clinician or Unknown).
 * The Unknown option is returned when the conversation is not fully loaded therefore it's not possible to determine the
 * initial sender of the message.
 * @param conversation a conversation that was fully loaded, cannot be used for partial conversation.
 * @returns who initiated the conversation. (Patient or Clinician or Unknown)
 */
export const getAnalyticsConversationInitiatedBy = (
    conversation: ConversationSummary | null,
): FlemingAnalyticsTracker.PatientConversationInitiatedByType => {
    if (conversation === null) {
        return "ClinicianInitiated";
    }

    if (conversation.isFullyLoaded) {
        return conversation.items.length > 0 &&
            conversation.items[0].createdBy.type === "Patient"
            ? "PatientInitiated"
            : "ClinicianInitiated";
    }

    return "Unknown";
};

/**
 * Returns the count of messages between a clinician and a patient in the given list of conversation items.
 * @param conversationItems items in a conversation.
 * @returns count of messages.
 */
export const getAnalyticsConversationMessageCount = (
    conversationItems: ConversationItem[],
): number => {
    return conversationItems.filter((item) => {
        /**
         * These are the actual messages exchanged between
         * patient and clinician
         */
        const validMessageTypes: ConversationItem["contentType"][] = [
            "FloreyResponseNote",
            "PatientEmail",
            "PatientTriageRequestNote",
            "PatientSms",
            "NhsAppMessage",
        ];

        return validMessageTypes.indexOf(item.contentType) > -1;
    }).length;
};

/**
 * Returns true if the given message contains a PIFU link.
 * @param isPifuEnabled boolean to say if PIFU feature is enabled.
 * @param nationalCode national code of the organisation.
 * @param messageInputValue message to check for a PIFU link.
 * @returns count of messages.
 */
export const getAnalyticsMessageContainsPIFULink = (
    isPifuEnabled: boolean,
    nationalCode: string | undefined,
    messageInputValue: string,
): boolean => {
    // It only checks the link in the url when the PIFU FF is turned on.
    if (isPifuEnabled && nationalCode) {
        //Need to retrieve the url from the backend instead of concatenating it in the frontend.
        //Unfortunately, PIFU can not use the PT status endpoint /api/portal/PatientTriage/134/Status
        //because the commercial team requested that we don't use the PT module which denies access to the aforementioned endpoint.
        //This linear task PIFU-669 was created with the purpose to allow PT users to fetch this information.
        const regexUrlPattern = new RegExp(`\/p\/${nationalCode}`, "gmi");
        const withPIFULink = regexUrlPattern.test(messageInputValue);
        return withPIFULink;
    } else {
        return false;
    }
};

/**
 * Maps to known conversation groups or "Other"
 * if we get to a currently non-existing conversation group
 *
 * @param ruleset the set of rules that defines what conversations get into that group
 * @param currentUserId the id of the current user
 * @returns the "conversationGroup" custom dimension
 */
export const mapConversationGroupRulesetToAnalyticsConversationGroup = (
    ruleset: ConversationGroupRuleset,
    currentUserId: string,
): FlemingAnalyticsTracker.PatientConversationListItemClickProps["conversationGroup"] => {
    const assignee = ruleset ? getAssigneeFromRuleset(ruleset) : undefined;

    if (assignee && assignee.id) {
        switch (assignee.type) {
            case "User":
                if (assignee.id === currentUserId) {
                    return "UserSelfAssignedConversations";
                } else if (assignee.id) {
                    return "UserOtherAssignedConversations";
                }
                return "Other";

            case "Team":
                if (assignee.id) {
                    return "TeamAssignedConversations";
                }
                return "Other";

            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustive: never = assignee.type;
                return "Other";
        }
    }
    const sentByUserId = ruleset
        ? getSentByUserIdFromRuleset(ruleset)
        : undefined;
    if (sentByUserId === currentUserId) {
        return "UserSelfSentConversations";
    } else if (sentByUserId) {
        return "UserOtherSentConversations";
    }

    return "Other";
};
