import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledRecipientSection = styled.div`
    display: flex;
    align-items: baseline;
`;

export const StyledRecipientStackPanel = styled(StackPanel)`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[0.5]};
    margin-left: ${Tokens.SIZES[1]};
`;

export const StyledRecipientAvatarContainer = styled.div`
    margin-right: ${Tokens.SIZES[1]};
    background: ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
`;
