import { Assignee } from "app/workspaceConversations/workspaceConversations.types";
import { AssigneeType } from "shared/concierge/conversations/types/assignee.types";
import {
    TeamSummary,
    UserSummary,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

export const getAssigneeId = (assignee: Assignee): string | undefined => {
    if (assignee.type === "None") {
        return undefined;
    }

    return assignee.value.id;
};

export const getAssigneeDisplayName = (
    assignee: Assignee,
): string | undefined => {
    if (assignee.type === "None") {
        return undefined;
    }

    return assignee.value.displayName;
};

export const createAssignee = (
    type: AssigneeType,
    entity: TeamSummary | UserSummary | undefined,
): Assignee => {
    if (type === "User" && entity) {
        return { type: "User", value: entity };
    }

    if (type === "Team" && entity) {
        return { type: "Team", value: entity };
    }

    return { type: "None" };
};
