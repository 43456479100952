import {
    MessagePatientRequestWithExternalIdentity,
    MessagePatientResponse,
    TicketIdentity,
    TicketIdentityType,
} from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { conversationIdMapper } from "@accurx/concierge";
import { Conversation } from "@accurx/concierge/types";
import { ContentTypesWithSaveToRecordSupport } from "@accurx/native";
import { Log, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import isNil from "lodash/isNil";

import { mapSendMessageVariablesToMessagePatientRequestWithExternalId } from "./mapSendMessageVariablesToMessagePatientRequestWithExternalId";
import { SendMessageVariables } from "./useSendMessageMutation.types";

/**
 * Send a message
 *
 * User must be with trusted device, in order to be able to successfully use this endpoint
 */
const messagePatientWithExternalIdentity = async (
    request: MessagePatientRequestWithExternalIdentity,
): Promise<MessagePatientResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/patientmessaging/messagepatientwithid",
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as MessagePatientResponse;
};

export type SendMessageMutationResult =
    | {
          conversationId: Conversation["id"];
          ticketIdentity: TicketIdentity;
          patientId?: string;
          contentType: Extract<
              ContentTypesWithSaveToRecordSupport,
              "PatientSms" | "PatientEmail"
          >;
      }
    | undefined;

export const useSendMessageMutation = (
    options: UseMutationOptions<
        SendMessageMutationResult,
        Error,
        SendMessageVariables
    > = {},
) => {
    const workspace = useCurrentWorkspace();
    return useMutation<SendMessageMutationResult, Error, SendMessageVariables>({
        mutationFn: async (variables): Promise<SendMessageMutationResult> => {
            const requestBody =
                mapSendMessageVariablesToMessagePatientRequestWithExternalId({
                    sendMessageVariables: variables,
                    workspaceId: workspace.orgId,
                });

            const res = await messagePatientWithExternalIdentity(requestBody);

            let ticketIdentity: TicketIdentity | undefined = undefined;
            let patientId: string | undefined = undefined;

            const sms = res.messages?.find((msg) => msg.id) ?? null;
            if (sms?.id) {
                ticketIdentity = {
                    id: sms.id,
                    type: TicketIdentityType.SMS,
                };
                patientId = sms.patientAccuRxId ?? undefined;
            }
            const email = res.emailMessages?.find((msg) => msg.id) ?? null;
            if (email?.id) {
                ticketIdentity = {
                    id: email.id,
                    type: TicketIdentityType.PatientEmail,
                };
                patientId = email.patientAccuRxId ?? undefined;
            }

            if (ticketIdentity) {
                const conversationId =
                    conversationIdMapper.ticket.fromSource(ticketIdentity);
                if (conversationId) {
                    return {
                        conversationId,
                        patientId,
                        ticketIdentity,
                        contentType: sms ? "PatientSms" : "PatientEmail",
                    };
                }
            }

            return undefined;
        },
        ...options,
    });
};
