import { ChangeEvent, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { useQuickViewContext } from "@accurx/quick-view";
import { addDays, isAfter } from "date-fns";
import { useCompose } from "domains/compose/context";
import { useAppointmentsConfigurationQuery } from "domains/compose/hooks/useAppointmentConfigurationQuery";
import { useDayDetails } from "domains/compose/hooks/useDayDetails";

import { CustomDateType, ScheduleSendAt } from "../../types/ScheduleSend.types";
import { AfterTimeFrame } from "./AfterTimeFrame";
import {
    StyledCloseButton,
    StyledCustomDateBody,
    StyledCustomDateContainer,
    StyledCustomDateFooter,
    StyledCustomDateHeader,
    StyledOptionLabel,
    StyledSubtitle,
} from "./ScheduleSendCustomDate.styles";
import { SpecificDate } from "./SpecificDate";

type ScheduleSendCustomDateProps = {
    onConfirm: (payload: ScheduleSendAt) => void;
    sendAt?: ScheduleSendAt;
};

// equivalent to one year
const DEFAULT_SCHEDULING_WINDOW_IN_DAYS = 365;

export const ScheduleSendCustomDate = ({
    onConfirm,
}: ScheduleSendCustomDateProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { state } = useCompose();
    const { data } = useAppointmentsConfigurationQuery();
    const { onClose } = useQuickViewContext();

    const [overDaysInAdvanceError, setOverDaysInAdvanceError] = useState<
        string | undefined
    >(undefined);

    const [showEmptyError, setShowEmptyError] = useState(false);

    const [selectedOption, setSelectedOption] =
        useState<CustomDateType>("frame");
    const [selectedValue, setSelectedValue] = useState<ScheduleSendAt>(null);

    const dayDetails = useDayDetails({
        selectedDate: selectedValue?.sendAtDateTime || null,
    });

    const availableScheduleTimeFrameText =
        state.selfBookLink && data?.selfBookSchedulingWeeksIntoFuture
            ? `${data.selfBookSchedulingWeeksIntoFuture} weeks`
            : "1 year";

    /*
        When a self book link has been added,
        we allow to set a scheduling window of n weeks in advance, which is set per org.
        Otherwise, the default scheduling window is 1 year in advance.
    */
    const schedulingWindowInDays =
        state.selfBookLink && data?.selfBookSchedulingWeeksIntoFuture
            ? data.selfBookSchedulingWeeksIntoFuture * 7 // n*7 equivalent to n days
            : DEFAULT_SCHEDULING_WINDOW_IN_DAYS;

    const onConfirmButtonClick = () => {
        const scheduledTimestampUtc = selectedValue?.sendAtDateTime
            ? new Date(selectedValue.sendAtDateTime).toUTCString()
            : undefined;

        track("ConversationScheduleConfirm Button Click", {
            ...nativeTrackingFields,
            indexMessage: 0,
            templateName: state.template?.value.title,
            scheduleType: selectedValue ? "Custom" : "Now",
            scheduledTimestampUtc,
        });

        if (dateOverDaysInAdvanceError) {
            setOverDaysInAdvanceError(
                `You can only schedule a message ${availableScheduleTimeFrameText} in advance`,
            );
            return;
        }

        if (!selectedValue) {
            setShowEmptyError(true);
            return;
        }

        onConfirm(selectedValue);
    };

    const onSelectOption = (e: ChangeEvent<HTMLInputElement>) => {
        setOverDaysInAdvanceError(undefined);
        setSelectedOption(e.target.value as CustomDateType);
    };

    const dateOverDaysInAdvanceError = useMemo(() => {
        setOverDaysInAdvanceError(undefined);

        if (!selectedValue?.sendAtDateTime) return;

        const daysInAdvanceErrorText = `You can only schedule ${availableScheduleTimeFrameText} in advance`;
        const daysFromToday = addDays(new Date(), schedulingWindowInDays);

        const daysOverInAdvance: boolean = isAfter(
            new Date(selectedValue.sendAtDateTime),
            daysFromToday,
        );

        return daysOverInAdvance ? daysInAdvanceErrorText : undefined;
    }, [availableScheduleTimeFrameText, schedulingWindowInDays, selectedValue]);

    const isUserBasedAbroad = () =>
        Intl.DateTimeFormat().resolvedOptions().timeZone !== "Europe/London";

    return (
        <StyledCustomDateContainer>
            <StyledCustomDateHeader>
                <StyledCloseButton onClick={onClose}>
                    <Pill.Icon name="Cross" colour="blue" />
                    <Pill.Text>Close</Pill.Text>
                </StyledCloseButton>
            </StyledCustomDateHeader>
            <StyledCustomDateBody>
                <div>
                    <UI.Text variant="label">Schedule message</UI.Text>
                    <StyledSubtitle>
                        When would you like to send this message?
                    </StyledSubtitle>
                </div>
                <UI.Flex gap="1" flexDirection="column">
                    <UI.Radio
                        value="frame"
                        id="frame"
                        label={
                            <StyledOptionLabel>
                                After a time frame
                            </StyledOptionLabel>
                        }
                        onChange={onSelectOption}
                        name="frame"
                        checkedValue={selectedOption}
                    />
                    {selectedOption === "frame" && (
                        <>
                            <AfterTimeFrame
                                dateOverDaysInAdvanceError={
                                    dateOverDaysInAdvanceError
                                }
                                onChangeValue={setSelectedValue}
                                setShowEmptyError={setShowEmptyError}
                                showEmptyError={showEmptyError}
                            />
                            {!!overDaysInAdvanceError && (
                                <UI.Feedback colour="error" iconName="Failed">
                                    <UI.Text variant="label" skinny>
                                        {overDaysInAdvanceError}
                                    </UI.Text>
                                </UI.Feedback>
                            )}

                            <UI.VisuallyHidden aria-live="assertive">
                                {!!overDaysInAdvanceError && (
                                    <>{overDaysInAdvanceError}</>
                                )}
                            </UI.VisuallyHidden>
                        </>
                    )}
                </UI.Flex>
                <UI.Flex gap="1" flexDirection="column">
                    <UI.Radio
                        value="calendar"
                        id="calendar"
                        label={
                            <StyledOptionLabel>
                                On a specific date
                            </StyledOptionLabel>
                        }
                        onChange={onSelectOption}
                        name="calendar"
                        checkedValue={selectedOption}
                    />
                    {selectedOption === "calendar" && (
                        <>
                            <SpecificDate
                                onChangeValue={setSelectedValue}
                                dateOverDaysInAdvanceError={
                                    dateOverDaysInAdvanceError
                                }
                                daysInAdvance={schedulingWindowInDays}
                            />
                            {!!overDaysInAdvanceError && (
                                <UI.Feedback colour="error" iconName="Failed">
                                    <UI.Text variant="label" skinny>
                                        {overDaysInAdvanceError}
                                    </UI.Text>
                                </UI.Feedback>
                            )}

                            <UI.VisuallyHidden aria-live="assertive">
                                {!!overDaysInAdvanceError && (
                                    <>{overDaysInAdvanceError}</>
                                )}
                            </UI.VisuallyHidden>
                        </>
                    )}
                </UI.Flex>
            </StyledCustomDateBody>

            {dayDetails && !overDaysInAdvanceError && (
                <UI.Feedback
                    colour={
                        dayDetails.type === "workingDay"
                            ? "information"
                            : "warning"
                    }
                    iconName={
                        dayDetails.type === "workingDay"
                            ? "Calendar"
                            : "Warning"
                    }
                >
                    {dayDetails.type === "bankHoliday" && (
                        <UI.Text skinny colour="night">
                            <b>This date is a bank holiday</b>
                        </UI.Text>
                    )}
                    {dayDetails.type === "weekend" && (
                        <UI.Text skinny colour="night">
                            <b>This date is a weekend</b>
                        </UI.Text>
                    )}
                    <UI.Text skinny>
                        This message will send on{" "}
                        <b>
                            {dayDetails.date}
                            {isUserBasedAbroad() && " (UK Standard Time)"}
                        </b>
                    </UI.Text>
                </UI.Feedback>
            )}

            <StyledCustomDateFooter>
                <Pill.PrimaryButton
                    onClick={onConfirmButtonClick}
                    dimension="small"
                >
                    <Pill.Text>Confirm date and time</Pill.Text>
                </Pill.PrimaryButton>
            </StyledCustomDateFooter>
        </StyledCustomDateContainer>
    );
};
