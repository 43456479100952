import React, { useEffect } from "react";

import {
    ChangeRequests,
    DeliveryReceipts,
} from "@accurx/appointment-reminders";
import { Button, Link, Tabs, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, generatePath, useHistory, useParams } from "react-router";

import { ChainAnalyticsTracker } from "app/analytics";
import { useAnalytics } from "app/analytics/hooks";
import {
    Breadcrumb,
    Breadcrumbs,
    Container,
    Header,
    Spinner,
} from "app/organisations/shared";
import {
    resetAppointmentComposeState,
    selectAppointmentReminder,
} from "app/practices/appointmentReminder/AppointmentReminder.actions";
import { AppointmentReminderHomePanel } from "app/practices/appointmentReminder/AppointmentReminderHomePanel";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";

import { useLoadAppointmentReminders } from "../hooks/useLoadAppointmentReminders";
import { TABS } from "./AppointmentRemindersPage.constants";
import { useAppointmentRemindersPage } from "./AppointmentRemindersPage.hooks";
import { MainPanel } from "./AppointmentRemindersPage.styles";

import "../../practices/appointmentReminder/AppointmentReminder.scss";

export const AppointmentRemindersPage = (): JSX.Element => {
    const loading = useLoadAppointmentReminders();
    const history = useHistory();
    const dispatch = useDispatch();
    const { orgId } = useParams<{ orgId: string }>();

    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices?.selectedPractice,
        shallowEqual,
    );

    useEffect(() => {
        dispatch(selectAppointmentReminder(null));
    }, [dispatch]);

    const { tab, onTabChange, redirect } = useAppointmentRemindersPage();

    const { track, baseProps } = useAnalytics();
    const newAppointmentReminderClickHandler = () => {
        ChainAnalyticsTracker.trackSetupAppointmentReminderClicked(baseProps);
        track("HomepageAppointmentReminderCreateStartButtonClick", {
            pageOrigin: "AppointmentReminders",
        });
        dispatch(resetAppointmentComposeState());
        history.push(
            generatePath(ROUTES_CHAIN.practicesAppointmentReminderCompose, {
                orgId,
            }),
        );
    };

    if (!tab) return <Redirect to={redirect} />;
    if (loading !== UpdatingStatus.Loaded) return <Spinner />;

    return (
        <Tabs selected={tab.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="Appointment Reminders"
                        path={ROUTES_ORGS.appointmentReminders}
                    />
                    <Breadcrumb
                        text={tab.text}
                        path={ROUTES_ORGS.appointmentReminders}
                        params={{ tab: tab?.tabId }}
                    />
                </Breadcrumbs>

                <Text variant="title" as="h1" skinny>
                    Appointment Reminders
                </Text>
                <Text variant="body" skinny className="mt-2">
                    Automated text messages that remind patients about their
                    appointment and allow them to cancel. Read{" "}
                </Text>
                <Text variant="body" skinny>
                    more about{" "}
                    <Link
                        openInNewTab
                        href={SupportUrls.AppointmentRemindersIntroduction}
                        text="how to use Appointment Reminders."
                    />
                </Text>
                <Header.Actions>
                    <Button
                        text="Create a reminder"
                        icon={{
                            name: "Arrow",
                            rotation: "right",
                            placement: "end",
                        }}
                        onClick={newAppointmentReminderClickHandler}
                    />
                </Header.Actions>
                <Header.TabList tabs={TABS} />
            </Header>
            <Container>
                <MainPanel>
                    <Tabs.Panel tabId="manage">
                        <AppointmentReminderHomePanel />
                    </Tabs.Panel>
                    <Tabs.Panel tabId="sent">
                        <DeliveryReceipts workspaceId={practiceId} />
                    </Tabs.Panel>
                    <Tabs.Panel tabId="cancelled">
                        <ChangeRequests workspaceId={practiceId} />
                    </Tabs.Panel>
                </MainPanel>
            </Container>
        </Tabs>
    );
};
