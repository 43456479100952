import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type AddLabelToTicketOptions = {
    latestToken: string;
    ticketIdentity: TicketIdentity;
    workspaceId: number;
    labelCode: string;
    userId: string;
    otherDescription?: string;
};

export const addLabelToTicket: ConciergeFetcher<
    AddLabelToTicketOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const response = await TicketApiClient.addLabelToTicket({
        workspaceId: arg.workspaceId,
        ticketIdentity: arg.ticketIdentity,
        latestToken: arg.latestToken,
        labelCode: arg.labelCode,
        userId: arg.userId,
        otherDescription: arg.otherDescription,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
