import { useConciergeSelector } from "domains/concierge/internal/context";
import { PatientSummary } from "domains/concierge/types";

export const usePatient = ({
    patientId,
}: {
    patientId: PatientSummary["patientId"] | null | undefined;
}): PatientSummary | undefined => {
    return useConciergeSelector((state) =>
        patientId ? state.patients.items[patientId] : undefined,
    );
};
