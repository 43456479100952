import { Link } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { WEB_BATCH_SUPPORT_URL } from "./BatchMessageWeb.constants";

export const BatchMessageSupportLink = () => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const trackSupportLinkClick = () => {
        ChainAnalyticsTracker.trackBatchResourceClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            batchResourceName: WEB_BATCH_SUPPORT_URL,
        });
    };

    return (
        <Link
            href={WEB_BATCH_SUPPORT_URL}
            text="How batch messages work"
            iconName="OpenWindow"
            openInNewTab
            onClick={trackSupportLinkClick}
        />
    );
};
