import {
    BaseAppointmentRemindersResponse,
    GetAppointmentRemindersResponse,
    UpdateAppointmentReminderRequest,
} from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type UpdateReminderParameters = {
    workspaceId: string;
    reminderId: string;
};

const updateReminder = (
    workspaceId: string,
    reminder: UpdateAppointmentReminderRequest,
): Promise<IWrappedResult<BaseAppointmentRemindersResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/AppointmentReminders/:workspaceId/update",
            params: { workspaceId },
        }),
        reminder,
    );

export const useUpdateReminder = ({
    workspaceId,
    reminderId,
}: UpdateReminderParameters) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [
            "AppointmentReminders",
            "UpdateReminder",
            { workspaceId, reminderId },
        ],
        mutationFn: async (reminder: UpdateAppointmentReminderRequest) => {
            const response = await updateReminder(workspaceId, reminder);
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(
                response.error ?? "Could not update appointment reminder",
            );
        },
        onSuccess(data) {
            queryClient.setQueryData<GetAppointmentRemindersResponse>(
                [
                    "AppointmentReminders",
                    "RemindersAndFilterOptions",
                    { workspaceId },
                ],
                (previousData) => {
                    if (!previousData) {
                        return;
                    }

                    return {
                        ...previousData,
                        appointmentReminders: data.appointmentReminders,
                    };
                },
            );
        },
    });
};
