import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { PatientSearchCard } from "app/searchForPatient/patientSearchCard/PatientSearchCard";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { ROUTES_PRIMARY } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import { StyledList } from "./AllPatientsPage.styles";

export const AllPatientsPage = (): JSX.Element => {
    useAccurxWebTitle("Search for a patient");

    const patientThreadSmsEnabled = useIsFeatureEnabled(
        FeatureName.PatientThreadSMS,
    );
    const videoConsultEnabled = useIsFeatureEnabled(FeatureName.VideoConsult);
    const recordAccessEnabled = useIsFeatureEnabled(
        FeatureName.RecordAccessBeta,
    );
    const caregiverInitiatedEnabled = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );

    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title="Patient search"
                helperText={
                    <StackPanel>
                        <Text variant="label">Search for a patient to:</Text>
                        <StyledList variant="label" forwardedAs="ul">
                            {patientThreadSmsEnabled && (
                                <>
                                    <li>Send them an SMS</li>
                                </>
                            )}
                            {videoConsultEnabled && <li>Video call them</li>}
                            {recordAccessEnabled && (
                                <li>Get a copy of their GP record</li>
                            )}
                            {caregiverInitiatedEnabled && (
                                <li>Contact their GP practice</li>
                            )}
                        </StyledList>
                    </StackPanel>
                }
            />
            <PatientSearchCard patientSearchOrigin={ROUTES_PRIMARY.patients} />
        </>
    );
};
