import React from "react";

import { FeatureName } from "@accurx/auth";
import { Ds, Text } from "@accurx/design";

import { FeatureFlag } from "app/practices/Practices.types";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { ROUTES } from "shared/Routes";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionRecordViewProps = {
    practiceFeatures: FeatureFlag[];
};

export const SectionRecordView = ({
    practiceFeatures,
}: SectionRecordViewProps): JSX.Element | null => {
    const isRecordViewGpOptInEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.RecordViewGpOptIn,
    );

    const firstLinkText = isRecordViewGpOptInEnabled
        ? "How do I get started with Record View?"
        : "How can I turn Record View on for my practice?";

    return (
        <>
            <div className={"d-flex align-items-center"}>
                <Text as="h2" variant="subtitle">
                    Record View
                </Text>
                <Ds.Badge color="purple" radius="round" className="ml-2">
                    New
                </Ds.Badge>
            </div>
            <div className="mb-4">
                <PracticeLink
                    url={`record-view-opt-in?referrer=webRecordViewSection`}
                    title={firstLinkText}
                    section={PracticeLinkSection.RECORD_VIEW}
                    description="Learn more about Record View and its benefits to your practice and your patients"
                />
                {isRecordViewGpOptInEnabled && (
                    <PracticeLink
                        url="record-view-requests"
                        title="How can I see record requests from other healthcare organisations?"
                        section={PracticeLinkSection.RECORD_VIEW}
                        description="View an audit trail of other healthcare workers who have requested to view your patient records"
                    />
                )}
                <PracticeLink
                    url={ROUTES.record_view_medical_record_test_patient}
                    title="Can I test record view with an example patient first?"
                    section={PracticeLinkSection.RECORD_VIEW}
                    description="Take an interactive look at Record View with our test patient"
                />
                <PracticeLink
                    url="https://support.accurx.com/en/articles/5463781-accurx-web-record-view-ig-security"
                    title="IG and Security"
                    section={PracticeLinkSection.RECORD_VIEW}
                    description="Read our support article explaining more about the IG and Security involved"
                />
            </div>
        </>
    );
};
