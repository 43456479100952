import { Table } from "@accurx/design";

import { UncontactablePatient } from "../pages/utils/contactable";
import {
    StyledTable,
    StyledTableHead,
    StyledTruncatedTableData,
    StyledWrappedTableDataCell,
} from "./PatientDetailsTable.styles";

type UncontactablePatientsTableProps = {
    batchId: string;
    patients: UncontactablePatient[];
};

export const UncontactablePatientsTable = ({
    batchId,
    patients,
}: UncontactablePatientsTableProps) => {
    return (
        <StyledTable>
            <StyledTableHead>
                <Table.Row>
                    <Table.Header scope="column">NHS number</Table.Header>
                    <Table.Header scope="column">Patient name</Table.Header>
                    <Table.Header scope="column">Reason</Table.Header>
                </Table.Row>
            </StyledTableHead>
            <Table.Body>
                {patients.map((patient, index) => (
                    <Table.Row key={`${patient.nhsNumber}-${index}`}>
                        <Table.DataCell>
                            <StyledTruncatedTableData>
                                {patient.nhsNumber}
                            </StyledTruncatedTableData>
                        </Table.DataCell>
                        <Table.DataCell>
                            <StyledTruncatedTableData>
                                {patient.displayName}
                            </StyledTruncatedTableData>
                        </Table.DataCell>
                        <StyledWrappedTableDataCell>
                            {patient.reasonUncontactable}
                        </StyledWrappedTableDataCell>
                    </Table.Row>
                ))}
            </Table.Body>
        </StyledTable>
    );
};
