import { PayloadAction } from "@reduxjs/toolkit";

import { BatchDeclineSettings } from "./BatchMessage.types";

export const UPDATE_BATCH_DECLINE_SETTINGS =
    "UPDATE_BATCH_DECLINE_SETTINGS" as const;

export type UpdateBatchDeclineSettingsAction = PayloadAction<
    BatchDeclineSettings,
    typeof UPDATE_BATCH_DECLINE_SETTINGS
>;

export const updateBatchDeclineSettings = (
    declineSettings: BatchDeclineSettings,
): UpdateBatchDeclineSettingsAction => ({
    type: UPDATE_BATCH_DECLINE_SETTINGS,
    payload: {
        confirmationText: declineSettings.confirmationText,
        snomedConceptId: declineSettings.snomedConceptId,
    },
});
