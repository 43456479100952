import {
    ItemCreatedBySystem,
    ItemCreatedByUser,
} from "domains/concierge/schemas/ConversationItemSchema";
import isNil from "lodash/isNil";

export const mapCreatedByOptionalUser = (
    userId: Optional<string>,
): ItemCreatedBySystem | ItemCreatedByUser =>
    isNil(userId) ? { type: "System" } : { type: "User", id: userId };
