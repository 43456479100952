import { Flex, Table, Tokens } from "@accurx/design";
import styled from "styled-components";

const EmptyStateMessage = styled(Flex).attrs(() => ({
    justifyContent: "center",
    alignItems: "center",
}))`
    padding: ${Tokens.SIZES[1.5]};
    text-align: center;
`;

export const emptyStateMessage =
    "Patients you add will appear here. You can add patient details multiple times.";

const TableEmptyState = () => (
    <Table.Row>
        <Table.DataCell colSpan={3}>
            <EmptyStateMessage>{emptyStateMessage}</EmptyStateMessage>
        </Table.DataCell>
    </Table.Row>
);

export default TableEmptyState;
