import React from "react";

import { NhsPharmaciesResponse } from "@accurx/api/account";
import { Flex, Pagination } from "@accurx/design";

type PharmacyResultsPaginationProps = Pick<
    NhsPharmaciesResponse,
    "currentPage" | "totalPages"
> & {
    onPrevious: () => void;
    onNext: () => void;
};

export const PharmacyResultsPagination = ({
    currentPage,
    totalPages,
    onPrevious,
    onNext,
}: PharmacyResultsPaginationProps) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
        <Flex justifyContent="center">
            <Pagination>
                <Pagination.Navigation
                    currentPage={currentPage}
                    totalPages={totalPages}
                >
                    {!isFirstPage && (
                        <Pagination.PreviousButton onPrevious={onPrevious} />
                    )}
                    {!isLastPage && <Pagination.NextButton onNext={onNext} />}
                </Pagination.Navigation>
            </Pagination>
        </Flex>
    );
};
