import React, { MouseEvent, useEffect } from "react";

import {
    Attachment,
    Button,
    Card,
    Cell,
    Checkbox,
    ErrorSummary,
    Feedback,
    Flex,
    FormFieldWrapper,
    Grid,
    Input,
    Item,
    Link,
    Spinner,
    StackPanel,
    Text,
} from "@accurx/design";
import { SharedUrls, calculateFragments } from "@accurx/shared";
import { useParams } from "react-router";

import {
    trackCopyToOrgTemplatePageView,
    trackCreateTemplatePageView,
    trackDeleteTemplatePageView,
    trackEditTemplatePageView,
} from "app/analytics/FlemingAnalytics";
import {
    useCurrentOrganisation,
    useCurrentUser,
} from "app/organisations/hooks";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";

import { SingleSnomedPicker } from "../../sharedComponents/SnomedPicker";
import { StyledGreyBox } from "../floreyBuilder/components/PageContainer.styles";
import * as helpers from "./ManageTemplatesFormPage.helpers";
import {
    useIsOrgTemplate,
    useManageTemplatesForm,
    useMessageTemplate,
} from "./ManageTemplatesFormPage.hooks";
import {
    Container,
    StyledAttachmentButton,
    StyledLayoutWithMaxSize,
    StyledPageSubHeading,
    StyledPageTitleHeading,
    StyledTextArea,
    StyledWrapper,
} from "./ManageTemplatesFormPage.styles";
import { Params } from "./ManageTemplatesFormPage.types";
import { CategoryModal } from "./components/CategoryModal";
import { CategorySelect } from "./components/CategorySelect";

export const ManageTemplatesFormPage = (): JSX.Element => {
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const user = useCurrentUser();
    const org = useCurrentOrganisation();
    const { loadingStatus, template } = useMessageTemplate();
    const form = useManageTemplatesForm(template);
    const isOrgTemplate = useIsOrgTemplate(template?.owner);
    const { action } = useParams<Params>();

    const mode = (action as Params["action"]) || "create";
    const isInDeleteMode = mode === "delete";
    const hasAttachment = form.values.attachments.length > 0;
    const hasMaxAllowedAttachments =
        form.values.attachments.length === helpers.ALLOWED_NUMBER_OF_FILES;

    const onAllowAsSmsChange = (): void => {
        if (form.values.allowAsSms && form.values.allowReplyByDefault) {
            form.onChange.disallowAsSms(
                form.values.allowAsSms,
                form.values.allowReplyByDefault,
            );
        } else {
            form.onChange.allowAsSms(form.values.allowAsSms);
        }
    };
    const onAllowAsBatchChange = (): void => {
        form.onChange.allowAsBatch(form.values.allowAsBatch);
    };

    const onAllowPatientResponseChange = (): void => {
        form.onChange.allowReplyByDefault(form.values.allowReplyByDefault);
    };

    useEffect(() => {
        if (template === null || template === undefined) {
            return;
        }
        switch (mode) {
            case "create":
                trackCreateTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    productOrigin: "PatientMessage",
                });
                break;
            case "edit":
                trackEditTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
            case "copy":
                trackCopyToOrgTemplatePageView({
                    ...flemingLoggedInProps,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
            case "delete":
                trackDeleteTemplatePageView({
                    ...flemingLoggedInProps,
                    templateLevel: template.owner,
                    templateId: template.id,
                    productOrigin: "PatientMessage",
                });
                break;
        }
    }, [flemingLoggedInProps, mode, template]);

    if (
        loadingStatus === LoadingStatus.Loading ||
        loadingStatus === LoadingStatus.Initial
    ) {
        return <Spinner />;
    }

    if (loadingStatus === LoadingStatus.Failed) {
        return (
            <Feedback
                colour="error"
                title="There was an error loading this page"
            >
                <Text skinny>Please refresh the page and try again</Text>
            </Feedback>
        );
    }

    const title: string = (() => {
        switch (mode) {
            case "create":
                return "Create a new template";
            case "edit":
                return `Edit template: "${template?.title}"`;
            case "copy":
                return `Copy template: "${template?.title}"`;
            case "delete":
                return `Delete template: "${template?.title}"?`;
        }
    })();

    const subheading: string = (() => {
        switch (mode) {
            case "create":
                return `This will be available for ${
                    isOrgTemplate
                        ? `everyone in ${org?.organisationName}`
                        : `you`
                } to use`;
            case "edit":
                return `These changes will update ${
                    isOrgTemplate
                        ? `the template for everyone in ${org?.organisationName}`
                        : `your template`
                }`;
            case "copy":
                return `From my templates for everyone in ${org?.organisationName} to use`;
            case "delete":
                return `This will delete ${
                    isOrgTemplate
                        ? `the template for everyone in ${org?.organisationName}`
                        : `your template`
                }`;
        }
    })();

    const submitText: string = (() => {
        switch (mode) {
            case "create":
            case "copy":
                return form.submitting ? "Saving" : "Save template";
            case "edit":
                return form.submitting ? "Updating" : "Update template";
            case "delete":
                return form.submitting ? "Deleting" : "Delete template";
        }
    })();

    function mapErrorsByKey(key: string) {
        if (form.errorsByKey[key] === undefined) {
            return [];
        }
        return [form.errorsByKey[key].body as JSX.Element];
    }

    const disableAllowResponseByDefault = !form.values.allowAsSms;

    const handleSelectCategory = (selectedCategory: string) => {
        if (selectedCategory === "") {
            return;
        }
        // filter by value
        const selectedOptions = form.categories.filter(
            (option) => option.value === selectedCategory,
        );
        const selectedOption = selectedOptions[0];
        form.onChange.categorySelect({
            label: selectedOption.label,
            value: selectedOption.value,
        });
    };

    /**
     * calculate fragments by passing in the body of the
     * template as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragments = calculateFragments(
        `${form.values.body}${"a".repeat(helpers.MESSAGE_LENGTH_COUNT_START)}`,
    );

    return (
        <Container>
            <CategoryModal
                isModalOpen={form.addCategoryIsModalOpen}
                handleCloseModal={form.onAddCategoryClose}
                handleAddCategory={form.onAddCategorySubmit}
                formValues={form.addCategoryFormValues}
                onChange={form.addCategoryFormOnChange}
                errors={form.addCategoryFormErrors}
            />
            <Breadcrumb title={title} />
            <StyledPageTitleHeading>
                <Text skinny variant="title" as="h1">
                    {title}
                </Text>
            </StyledPageTitleHeading>
            <StyledPageSubHeading>
                <Text skinny>{subheading}</Text>
            </StyledPageSubHeading>

            <StyledLayoutWithMaxSize>
                <form
                    onSubmit={
                        action === "delete" ? form.onDelete : form.onSubmit
                    }
                >
                    <Grid columns={{ lg: "1fr 2fr 1fr", xs: "0fr 1fr 0fr" }}>
                        <Cell />
                        <StackPanel gutter={4}>
                            <ErrorSummary
                                isShown={form.errorsCount > 0}
                                title="Review the errors in the form"
                            />
                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template details
                                    </Text>
                                }
                            >
                                <StackPanel gutter={2}>
                                    <FormFieldWrapper
                                        label="Template name"
                                        labelProps={{ htmlFor: "templateName" }}
                                        errors={mapErrorsByKey("templateName")}
                                        metaInfo={
                                            <Text
                                                variant="note"
                                                as="span"
                                                colour={
                                                    form.characterCounts
                                                        .templateName >
                                                    helpers.MAX_TEMPLATE_NAME_LENGTH
                                                        ? "red"
                                                        : "zinc"
                                                }
                                            >
                                                {
                                                    form.characterCounts
                                                        .templateName
                                                }{" "}
                                                /{" "}
                                                {
                                                    helpers.MAX_TEMPLATE_NAME_LENGTH
                                                }
                                            </Text>
                                        }
                                    >
                                        <Input
                                            id="templateName"
                                            value={form.values.templateName}
                                            onChange={
                                                form.onChange.templateName
                                            }
                                            placeholder="e.g. Opening hours"
                                            disabled={isInDeleteMode}
                                        />
                                    </FormFieldWrapper>
                                    <>
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="start"
                                        >
                                            <Item>
                                                <Text
                                                    skinny
                                                    as="label"
                                                    variant="label"
                                                    props={{
                                                        htmlFor:
                                                            "category-select",
                                                    }}
                                                >
                                                    Category (optional)
                                                </Text>
                                                <Text skinny>
                                                    This will organise templates
                                                    in the dropdown.
                                                </Text>
                                            </Item>
                                            <Item>
                                                <Button
                                                    onClick={
                                                        form.onAddCategoryOpen
                                                    }
                                                    type="button"
                                                    theme="secondary"
                                                    text="Add category"
                                                    icon={{ name: "Plus" }}
                                                    disabled={isInDeleteMode}
                                                />
                                            </Item>
                                        </Flex>
                                        {form.categoriesError && (
                                            <Feedback
                                                colour="warning"
                                                title="Unexpected error"
                                                children={
                                                    "We couldn't load the Categories. Please refresh."
                                                }
                                            />
                                        )}
                                        <CategorySelect
                                            categoriesError={
                                                form.categoriesError
                                            }
                                            categoriesStatus={
                                                form.categoriesStatus
                                            }
                                            categories={form.categories}
                                            addCategorySuccessStatus={
                                                form.addCategorySuccessStatus
                                            }
                                            formValues={form.values}
                                            onChange={handleSelectCategory}
                                            disabled={isInDeleteMode}
                                        />
                                    </>
                                </StackPanel>
                            </Card>

                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template content
                                    </Text>
                                }
                            >
                                <StackPanel gutter={2}>
                                    <FormFieldWrapper
                                        label="Message"
                                        labelProps={{ htmlFor: "message" }}
                                        errors={mapErrorsByKey("message")}
                                        metaInfo={
                                            <Text
                                                as="span"
                                                variant="note"
                                                colour={
                                                    form.characterCounts.body >
                                                    helpers.MAX_BODY_LENGTH
                                                        ? "red"
                                                        : "zinc"
                                                }
                                            >
                                                {form.characterCounts.body +
                                                    helpers.MESSAGE_LENGTH_COUNT_START}{" "}
                                                /{" "}
                                                {
                                                    helpers.MAX_MESSAGE_FRAGMENT_LENGTH
                                                }{" "}
                                                ({fragments}{" "}
                                                {fragments === 1
                                                    ? "fragment"
                                                    : "fragments"}
                                                )
                                            </Text>
                                        }
                                    >
                                        <StyledGreyBox>
                                            {helpers.MESSAGE_INTRO}
                                            <StyledTextArea
                                                id="message"
                                                value={form.values.body}
                                                onChange={form.onChange.body}
                                                rows={3}
                                                placeholder="Add the content of your template by typing here..."
                                                disabled={isInDeleteMode}
                                            />
                                            <span>
                                                {helpers.MESSAGE_OUTRO}{" "}
                                                {user?.settings?.fullName || ""}
                                            </span>
                                            <br />
                                            <span>{org?.organisationName}</span>
                                        </StyledGreyBox>
                                    </FormFieldWrapper>

                                    {form.shouldShowAttachmentsSection && (
                                        <FormFieldWrapper
                                            label="Attachments"
                                            subLabel={`You can attach ${
                                                helpers.ALLOWED_NUMBER_OF_FILES
                                            } file, max size ${
                                                helpers.ALLOWED_FILE_SIZE
                                                    .humanReadable
                                            }. Files accepted: ${helpers.ALLOWED_FILE_EXTENSIONS.join(
                                                ", ",
                                            )}.`}
                                        >
                                            <Flex
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                gap="2"
                                            >
                                                <StyledAttachmentButton
                                                    type="button"
                                                    icon={{ name: "Paperclip" }}
                                                    text="Attach file"
                                                    theme="secondary"
                                                    onClick={() => {
                                                        document
                                                            .getElementById(
                                                                "fileUpload",
                                                            )
                                                            ?.click();
                                                    }}
                                                    disabled={
                                                        isInDeleteMode ||
                                                        hasMaxAllowedAttachments
                                                    }
                                                />
                                                <input
                                                    id="fileUpload"
                                                    type="file"
                                                    accept={`.${helpers.ALLOWED_FILE_EXTENSIONS.join(
                                                        ",.",
                                                    )}`}
                                                    style={{ display: "none" }}
                                                    // Need to reset this on click in case we want to upload the same file twice
                                                    onClick={(
                                                        event: MouseEvent<HTMLInputElement>,
                                                    ) => {
                                                        event.currentTarget.value =
                                                            "";
                                                    }}
                                                    onChange={
                                                        form.onAddAttachment
                                                    }
                                                    data-testid="fileUpload"
                                                />
                                                {form.values.attachments?.map(
                                                    (attachment, i) => (
                                                        <Attachment
                                                            key={attachment.id}
                                                            index={i + 1}
                                                            status={
                                                                attachment.status
                                                            }
                                                            onRemove={() =>
                                                                form.onRemoveAttachment(
                                                                    attachment.id,
                                                                )
                                                            }
                                                            attachment={{
                                                                fileName:
                                                                    // this shouldn't ever be null, but our generated API types tell us that it can,
                                                                    // so we have to handle it by using a non-null assertion
                                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                                    attachment.fileName!,
                                                                fileSize:
                                                                    attachment.fileSize,
                                                                fileType:
                                                                    attachment.fileType,
                                                                attachmentId:
                                                                    attachment.id?.toString(),
                                                                previewUrl:
                                                                    attachment.previewUrl,
                                                                errors: attachment.errors,
                                                            }}
                                                        />
                                                    ),
                                                )}
                                                {hasAttachment && (
                                                    <Feedback
                                                        colour={"secondary"}
                                                        title={
                                                            "Check you've got the right attachment"
                                                        }
                                                    >
                                                        This message could be
                                                        sent to multiple
                                                        patients, so please do
                                                        not include information
                                                        relating to individual
                                                        patients.
                                                    </Feedback>
                                                )}
                                            </Flex>
                                        </FormFieldWrapper>
                                    )}

                                    <FormFieldWrapper
                                        label="SNOMED code (optional)"
                                        subLabel={
                                            !!form.values.snomedCode
                                                ? undefined
                                                : "Type the description or concept ID to search."
                                        }
                                        labelProps={{
                                            htmlFor: "snomedCode",
                                        }}
                                    >
                                        <SingleSnomedPicker
                                            id="snomedCode"
                                            onChange={form.onChange.snomedCode}
                                            disabled={isInDeleteMode}
                                            value={form.values.snomedCode}
                                            showMissingSnomedCodeInformation
                                            context="patientMessaging"
                                        />
                                    </FormFieldWrapper>
                                </StackPanel>
                            </Card>

                            <Card
                                header={
                                    <Text skinny variant="subtitle">
                                        Template settings
                                    </Text>
                                }
                            >
                                <StackPanel gutter={2}>
                                    <FormFieldWrapper label="Template available">
                                        <Flex
                                            gap={"2"}
                                            flexDirection={{
                                                xs: "column",
                                                sm: "row",
                                            }}
                                        >
                                            <Item flex={"1"}>
                                                <Checkbox
                                                    id="allowAsSms"
                                                    text="Individual"
                                                    theme="bordered"
                                                    checked={
                                                        form.values.allowAsSms
                                                    }
                                                    onCheckChange={
                                                        onAllowAsSmsChange
                                                    }
                                                    disabled={isInDeleteMode}
                                                />
                                            </Item>
                                            <Item flex={"1"}>
                                                <Checkbox
                                                    id="allowAsBatch"
                                                    text="Batch"
                                                    theme="bordered"
                                                    checked={
                                                        form.values.allowAsBatch
                                                    }
                                                    onCheckChange={
                                                        onAllowAsBatchChange
                                                    }
                                                    disabled={isInDeleteMode}
                                                />
                                            </Item>
                                        </Flex>
                                    </FormFieldWrapper>
                                    <FormFieldWrapper
                                        label="Default reply setting"
                                        labelProps={{
                                            htmlFor: "allowReplyByDefault",
                                        }}
                                        subLabel={
                                            "The patients will be able to respond once, with a photo or a message. Not applicable if sending in batch."
                                        }
                                    >
                                        <Checkbox
                                            id="allowReplyByDefault"
                                            text="Allow patients to respond"
                                            theme="bordered"
                                            checked={
                                                form.values.allowReplyByDefault
                                            }
                                            onCheckChange={
                                                onAllowPatientResponseChange
                                            }
                                            disabled={
                                                isInDeleteMode ||
                                                disableAllowResponseByDefault
                                            }
                                        />
                                    </FormFieldWrapper>
                                    {form.values.allowAsBatch &&
                                        !disableAllowResponseByDefault && (
                                            <Feedback
                                                colour={"secondary"}
                                                title={
                                                    "Patients can't respond when you send in batch"
                                                }
                                            >
                                                You can use Florey
                                                questionnaires to record
                                                patients' responses.{" "}
                                                <Link
                                                    href={SharedUrls.Floreys}
                                                    openInNewTab
                                                    text="Florey questionnaires"
                                                />
                                            </Feedback>
                                        )}
                                </StackPanel>
                            </Card>
                        </StackPanel>
                    </Grid>
                    <StyledWrapper>
                        <StackPanel orientation={"horizontal"} gutter={2}>
                            <Button
                                onClick={form.onCancel}
                                text="Cancel"
                                disabled={form.submitting}
                                theme="secondary"
                            />
                            <Button
                                type="submit"
                                text={submitText}
                                disabled={form.submitting}
                                theme={
                                    action === "delete" ? "danger" : "primary"
                                }
                            />
                        </StackPanel>
                    </StyledWrapper>
                </form>
            </StyledLayoutWithMaxSize>
        </Container>
    );
};
