import { useFeatureFlagOverrides } from "./hooks/useFeatureFlagOverrides";
import { Feature } from "./types/Feature";
import { useCurrentWorkspace } from "./useCurrentWorkspace";
import { isFeatureEnabledForWorkspace } from "./utils/organisation";

/** Returns true if the provided feature is enabled for the current workspace,
 * otherwise returns false.
 */
export const useFeatureFlag = (feature: Feature) => {
    const workspace = useCurrentWorkspace();
    const overrides = useFeatureFlagOverrides();
    const featureOverride = overrides[feature];

    // check if there was an override explicitly set
    if (featureOverride !== undefined) {
        return featureOverride;
    }

    return isFeatureEnabledForWorkspace(feature, workspace);
};
