/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useState } from "react";

import { GPRequestResult } from "@accurx/api/portal";
import { Button, Card, Ds, Icon, IconNameVariants, Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useSafeAsync } from "api/Api.utils";
import {
    RecordViewRequestResult,
    RecordViewResultStatus,
} from "api/FlemingDtos";
import { getLatestPatientResult } from "api/RecordViewApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    MessageTemplateCategoryName,
    MessageTemplateName,
} from "app/clinicianconversation/conversation/templates.helper";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import {
    SkeletonBody,
    SkeletonLabel,
} from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute, getMessagePracticeUrl } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { actionCreators } from "../selectProduct/SelectProductActions";
import {
    getRequestRemainingHoursTextOnly,
    isRecordViewDisabled,
} from "./PatientProfile.helper";
import {
    ProductInfoBox,
    StyledRecordViewContainer,
} from "./RecordViewCard.styles";
import {
    StyledCardColumn,
    StyledFlex,
    StyledSectionHeader,
} from "./tabPanels/OverviewPanel.styles";

export type CardContent = {
    title: string;
    desc: string | JSX.Element;
    buttonCta: string;
    badgeText: string | undefined;
    onClickHandler: () => void;
    icon: IconNameVariants;
};

const RecordViewCard = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const safeAsync = useSafeAsync();

    const [latestRecordRequest, setLatestRecordRequest] =
        useState<RecordViewRequestResult | null>(null);

    const [showGpModal, setShowGpModal] = useState(false);

    const patient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );
    const loggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        const initLoadRequest = async (): Promise<void> => {
            if (patient?.nhsNumber && organisation) {
                const { result } = await safeAsync(
                    getLatestPatientResult({
                        organisationId: organisation.orgId,
                        nhsNumber: patient.nhsNumber,
                    }),
                );

                setLatestRecordRequest(result?.record || null);
            }
        };
        initLoadRequest();
    }, [safeAsync, patient, organisation]);

    const goToRecordView = () => {
        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Overview" as const,
            optionSelected: "RecordView" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionButtonClick(
                analyticsProps,
            );

        dispatch(actionCreators.selectRecordViewType());
        const route = `${findBaseRoute(history.location.pathname)}${
            ROUTES_EXTENSION.remoteRecordView
        }`;
        history.push(route, { referredFrom: ROUTES_EXTENSION.patientProfile });
    };

    const goToMedicalRecord = (requestId: string) => {
        history.push(
            `${findBaseRoute(history.location.pathname)}${
                ROUTES_EXTENSION.remoteRecordViewMedicalRecord
            }?request=${requestId}`,
            { referredFrom: ROUTES.record_view_medical_record_requests },
        );
    };

    const goToInputCodePage = (requestId: string) => {
        history.push(
            `${findBaseRoute(history.location.pathname)}${
                ROUTES_EXTENSION.remoteRecordView
            }`,
            {
                requestId,
            },
        );
    };

    const getBadgeColor = (badgeText: string): Ds.BadgeProps["color"] => {
        switch (badgeText) {
            case "Pending":
                return "blue";
            case "Available":
                return "green";
            default:
                return "greyscale";
        }
    };

    const getCardContent = (): CardContent => {
        const disabledInfo = isRecordViewDisabled(patient);

        if (disabledInfo?.description && patient) {
            const handleRecordViewInvite = (): void => {
                const templateCategory: MessageTemplateCategoryName =
                    "record-view";
                const templateName: MessageTemplateName = "opt-in";
                dispatch(actionCreators.selectRecordViewType());
                history.push(
                    `${findBaseRoute(
                        history.location.pathname,
                    )}${getMessagePracticeUrl(
                        organisation,
                    )}?category=${templateCategory}&template=${templateName}`,
                    {
                        appOrigin: "MessagePractice",
                    },
                );
            };

            const handleShowGPContactDetails = (): void => {
                setShowGpModal(true);
            };

            const canRequestGPToOptIn =
                patient.recordViewSupported ===
                GPRequestResult.FailureGpNotOptedIn;

            const onClickHandler = canRequestGPToOptIn
                ? handleRecordViewInvite
                : handleShowGPContactDetails;

            return {
                title: "Record unavailable",
                desc: disabledInfo.description,
                badgeText: "Unavailable",
                buttonCta: "Contact practice",
                onClickHandler,
                icon: "Phone",
            };
        }

        // Record is available
        if (latestRecordRequest) {
            switch (latestRecordRequest.status) {
                case RecordViewResultStatus.RecordAvailable:
                    return {
                        title:
                            "Record available for " +
                            getRequestRemainingHoursTextOnly(
                                latestRecordRequest.accessRemainingHours,
                            ),
                        desc: "Made available by the patient",
                        buttonCta: "View GP record",
                        badgeText: "Available",
                        onClickHandler: () =>
                            goToMedicalRecord(latestRecordRequest.requestId),
                        icon: "Record",
                    };
                case RecordViewResultStatus.TwofaCodeRequired:
                    return {
                        title: "Code from patient needed",
                        desc: "Requested by you",
                        buttonCta: "Input code",
                        badgeText: "Pending",
                        onClickHandler: () =>
                            goToInputCodePage(latestRecordRequest.requestId),
                        icon: "Record",
                    };
                case RecordViewResultStatus.Requesting:
                    return {
                        title: "Record display pending",
                        desc: "Requested by you",
                        buttonCta: "View loading page",
                        badgeText: "Pending",
                        onClickHandler: () =>
                            goToMedicalRecord(latestRecordRequest.requestId),
                        icon: "Record",
                    };
            }
        }
        return {
            title: "Request a summary of the patient’s GP record",
            desc: (
                <Text as="span" variant="body" skinny>
                    <ul>
                        <li>Problems</li>
                        <li>Medications and allergies</li>
                        <li>Investigations</li>
                        <li>Immunisations</li>
                    </ul>
                </Text>
            ),
            buttonCta: "Request GP record",
            badgeText: undefined,
            onClickHandler: goToRecordView,
            icon: "Record",
        };
    };

    const renderGPContactDetailsModal = (isGpOptedIn: boolean): JSX.Element => {
        return (
            <Modal isOpen={true} toggle={() => setShowGpModal(false)}>
                <ModalHeader tag="div">
                    <Text
                        variant="subtitle"
                        as="h1"
                        skinny
                        props={{ className: "d-flex align-items-center" }}
                    >
                        GP practice contact details
                    </Text>
                    {!isGpOptedIn && (
                        <ProductInfoBox>
                            <div className="d-flex">
                                <Icon
                                    theme="Line"
                                    name="Info"
                                    colour="blue"
                                    props={{ className: "mr-2" }}
                                />
                                <Text skinny>
                                    This practice hasn't enabled Record View
                                    yet. Get in touch with them to ask for the
                                    record.
                                </Text>
                            </div>
                        </ProductInfoBox>
                    )}
                </ModalHeader>
                <ModalBody>
                    <Text variant="label">Practice name</Text>
                    <Text skinny>{patient?.practiceName ?? "Not found"}</Text>
                    <Text variant="label">Telephone number</Text>
                    <Text skinny>
                        {patient?.practicePhoneNumber ?? "Not found"}
                    </Text>
                </ModalBody>
                <ModalFooter className="d-flex align-items-end">
                    <Button
                        text="Close"
                        theme="secondary"
                        onClick={() => setShowGpModal(false)}
                    />
                </ModalFooter>
            </Modal>
        );
    };

    const { title, desc, buttonCta, badgeText, onClickHandler, icon } =
        getCardContent();

    const getHeader = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        return (
            (patient?.firstName ? patient.firstName + "'s " : "") + "GP record"
        );
    };

    const getTitle = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        return (
            <Text skinny variant="label">
                {title}
            </Text>
        );
    };

    const getDescription = () => {
        if (!patient) {
            return (
                <>
                    <SkeletonBody charCount={20} />
                    <br />
                    <SkeletonBody charCount={20} />
                </>
            );
        }

        return typeof desc === "string" ? (
            <Text variant="body" skinny>
                {desc}
            </Text>
        ) : (
            desc
        );
    };

    return (
        <StackPanel gutter={2}>
            <StyledFlex>
                <Icon name="Record" size={4} halo={{ colour: "blue" }} />
                <StyledSectionHeader forwardedAs="h5" variant="label">
                    {getHeader()}
                </StyledSectionHeader>
            </StyledFlex>
            <Card isElevated spacing={2} variant="regular">
                <StackPanel gutter={1}>
                    <StyledRecordViewContainer>
                        <StyledCardColumn>
                            {getTitle()}
                            {getDescription()}
                        </StyledCardColumn>
                        {badgeText && (
                            <Ds.Badge color={getBadgeColor(badgeText)}>
                                {badgeText}
                            </Ds.Badge>
                        )}
                    </StyledRecordViewContainer>
                    <div>
                        <Button
                            theme="secondary"
                            text={buttonCta}
                            icon={{
                                colour: "blue",
                                name: icon,
                            }}
                            onClick={onClickHandler}
                        />
                    </div>
                </StackPanel>
                {showGpModal && renderGPContactDetailsModal(true)}
            </Card>
        </StackPanel>
    );
};

export { RecordViewCard };
