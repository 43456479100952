import { useMemo, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { PatientAppointmentRequestItem } from "@accurx/concierge/types";
import { itemHasAttachments } from "@accurx/concierge/util/itemHasAttachments";
import * as UI from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { ItemAttachments } from "domains/inbox/components/ConversationItem/components/ItemAttachments/ItemAttachments";
import { PatientNameTag } from "domains/inbox/components/ConversationItem/components/PatientNameTag/PatientNameTag";
import { QuestionnaireResponse } from "domains/inbox/components/ConversationItem/components/QuestionnaireResponse/QuestionnaireResponse";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { useGetPatientToClinicianMessageInfo } from "domains/inbox/components/ConversationItem/useCopyInfo";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { useItemAttachmentAnalytics } from "domains/inbox/hooks/util/useItemAttachmentAnalytics";
import { userflowIds } from "domains/inbox/util";

export const PatientAppointmentRequestNote = ({
    item,
    conversation,
}: ConversationItemComponent<PatientAppointmentRequestItem>) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const patientId = item.createdBy.id;
    const patient = usePatient({ patientId });
    const hasAttachments = useMemo(() => itemHasAttachments(item), [item]);
    const textContentRef = useRef<HTMLDivElement>(null);
    const copyInfo = useGetPatientToClinicianMessageInfo({
        patientId,
        dateCreatedAt: item.createdAt,
        viaProxy: false,
        messageBody: textContentRef.current?.innerText ?? "",
    });

    const title =
        item.requestType === "None"
            ? "Appointment request"
            : `Appointment request - ${item.requestType}`;

    const {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    } = useItemAttachmentAnalytics({ conversation });

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={patient ? "blue" : "yellow"}
            >
                <ConversationItemHeader>
                    <PatientNameTag patientId={patientId} />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge
                            color={patient ? "blue" : "yellow"}
                            size="small"
                        >
                            Patient
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <UI.Flex flexDirection="column" gap="3">
                        <UI.Item ref={textContentRef}>
                            <QuestionnaireResponse
                                title={title}
                                sections={item.sections}
                                renderAttachments={({ attachments }) => (
                                    <ItemAttachments
                                        attachments={attachments}
                                        itemId={item.id}
                                        conversationId={conversation.id}
                                        patientId={patientId}
                                        getAttachmentUrl={({ attachment }) => {
                                            if (!patientId) {
                                                Log.warn(
                                                    `No patient ID for attachment ${attachment.id}`,
                                                );
                                                return `/api/conversation/web/patientupload/${workspaceId}/undefined/appointmentrequest/${attachment.id}`;
                                            }

                                            return `/api/conversation/web/patientupload/${workspaceId}/${patientId}/appointmentrequest/${attachment.id}`;
                                        }}
                                        onItemPreview={
                                            trackConversationAttachmentPreviewLinkClick
                                        }
                                        onItemDeleteClick={
                                            trackConversationAttachmentRemoveButtonClick
                                        }
                                        onItemDeleteConfirm={({ reason }) => {
                                            trackConversationAttachmentRemoveMenuItemClick(
                                                reason,
                                            );
                                        }}
                                    />
                                )}
                            />
                        </UI.Item>
                        <SaveItemAction
                            conversation={conversation}
                            item={item}
                            getTextToCopy={copyInfo}
                            hasAttachments={hasAttachments}
                        />
                    </UI.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
