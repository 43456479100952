import { useCurrentWorkspace } from "@accurx/auth";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    SearchForPatientSuccess,
    SearchForPatientSuccessSchema,
} from "domains/inbox/schemas/SearchForPatientSchema";

type MutationVariables = {
    firstName: string;
    lastName: string;
    dateOfBirth: { day: string; month: string; year: string };
    postcode: string;
    gender: string;
};

export const useSearchForPatientByPdsDemographicsMutation = (
    options: UseMutationOptions<
        SearchForPatientSuccess,
        Error,
        MutationVariables
    >,
) => {
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        ["searchForPatientByPdsDemographics"],
        async ({ dateOfBirth, ...otherRequestInfo }: MutationVariables) => {
            const { day, month, year } = dateOfBirth;

            const request = {
                dateOfBirthYear: year,
                dateOfBirthMonth: month,
                dateOfBirthDay: day,
                organisationId: workspaceId,
                ...otherRequestInfo,
            };

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/portal/SearchForPatient/ByDemographics",
                }),
                request,
            );

            const responseParse = SearchForPatientSuccessSchema.safeParse(
                response.result,
            );

            if (responseParse.success) {
                return responseParse.data;
            }

            throw new Error(
                "Search for patient via /api/portal/SearchForPatient/ByDemographics failed",
            );
        },
        options,
    );
};
