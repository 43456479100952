/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Flex, FormFieldWrapper, Icon, Select, Text } from "@accurx/design";

import { getValidatedComplexityBadgeText } from "../validatedComplexityBadge.utils";
import {
    StyledButtonWithCenteredContent,
    StyledTextarea,
} from "./SubmitClinicianStatusForm.styles";

type SubmitClinicianStatusFormProps = {
    onSubmit: ({
        score,
        additionalInformation,
        onSuccess,
        onError,
    }: {
        score: string;
        additionalInformation: string;
        onSuccess: () => void;
        onError: () => void;
    }) => void;
    isSubmitting: boolean;
    serverErrorMessage?: string;
};

export const SubmitClinicianStatusForm = ({
    onSubmit,
    isSubmitting,
}: SubmitClinicianStatusFormProps) => {
    // Form values
    const [additionalInformation, setAdditionalInformation] = useState("");
    const [clinicianStatus, setClinicianStatus] = useState("");

    // Errors
    const [clinicianStatusError, setClinicianStatusError] = useState("");
    const [submitError, setSubmitError] = useState("");

    const formOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!clinicianStatus) {
            setClinicianStatusError("Please select the clinician status");

            return;
        }

        onSubmit({
            score: clinicianStatus,
            additionalInformation,
            onSuccess: () => {
                setSubmitError("");
                setAdditionalInformation("");
            },
            onError: () =>
                setSubmitError("Unable to submit status. Please try again."),
        });
    };

    return (
        <form
            onSubmit={formOnSubmit}
            noValidate
            aria-describedby={submitError ? "submitError" : undefined}
        >
            <Flex flexDirection="column" gap="1">
                <FormFieldWrapper
                    label="Status - Clinician validation"
                    labelProps={{
                        htmlFor: "clinician-status",
                        skinny: true,
                    }}
                    errors={[clinicianStatusError]}
                    aria-required
                >
                    <Select
                        id="clinician-status"
                        disabled={isSubmitting}
                        onChangeHandler={(selected) => {
                            setClinicianStatus(
                                Array.isArray(selected)
                                    ? selected[0].value
                                    : selected.value,
                            );
                            setClinicianStatusError("");
                            setSubmitError("");
                        }}
                        options={[
                            {
                                value: "Low",
                                label: getValidatedComplexityBadgeText("Low"),
                            },
                            {
                                value: "MediumHigh",
                                label: getValidatedComplexityBadgeText(
                                    "MediumHigh",
                                ),
                            },
                            {
                                value: "Unvalidated",
                                label: getValidatedComplexityBadgeText(
                                    "Unvalidated",
                                ),
                            },
                        ]}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="Additional information (optional)"
                    labelProps={{
                        htmlFor: "additional-information",
                        skinny: true,
                    }}
                >
                    <StyledTextarea
                        id="additional-information"
                        disabled={isSubmitting}
                        value={additionalInformation}
                        onChange={(e) =>
                            setAdditionalInformation(e.currentTarget.value)
                        }
                    />
                </FormFieldWrapper>
                <StyledButtonWithCenteredContent
                    text={isSubmitting ? "Submitting" : "Submit"}
                    disabled={isSubmitting}
                />
                {submitError && (
                    <Flex alignItems="center" gap="0.5">
                        <Icon name="Error" colour="red" theme="Fill" size={3} />
                        <Text skinny props={{ id: "submitError" }}>
                            {submitError}
                        </Text>
                    </Flex>
                )}
            </Flex>
        </form>
    );
};
