import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledEditor = styled.div<{ editable?: boolean }>`
    background-color: ${({ editable }) =>
        editable === false
            ? Tokens.COLOURS.greyscale.cloud
            : Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES["1"]};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    margin-top: ${Tokens.SIZES["1"]};
    white-space: pre-wrap;

    & h3 {
        ${Tokens.TYPOGRAPHY.subtitle.base}
        ${Tokens.TYPOGRAPHY.subtitle.mobile}

        @media (min-width: ${Tokens.BREAKPOINTS.md}) {
            ${Tokens.TYPOGRAPHY.subtitle.desktop}
        }
    }

    & p {
        ${Tokens.TYPOGRAPHY.body.base}
        ${Tokens.TYPOGRAPHY.body.mobile}

        @media (min-width: ${Tokens.BREAKPOINTS.md}) {
            ${Tokens.TYPOGRAPHY.body.desktop}
        }
    }

    ${({ editable }) =>
        editable
            ? /* css */ `
                /* Editor textarea wrapper */
                & .remirror-editor-wrapper {
                    position: relative;
                    width: 100%;

                    /* Border alternative */
                    &::before {
                        content: " ";
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        width: 100%;
                        height: 100%;
                        padding: ${Tokens.SIZES["1"]} ${Tokens.SIZES["1.5"]};
                        border-radius: ${Tokens.BORDERS.radius};
                        border: ${Tokens.BORDERS.normal.silver};
                        box-shadow: ${Tokens.SHADOWS.lowered.shallow.silver};
                        transition: all 0.2s ease;
                    }

                    &:focus-within,
                    &:hover {
                        &::before {
                            box-shadow: ${Tokens.SHADOWS.lowered.shallow.metal};
                            border: ${Tokens.BORDERS.normal.metal};
                        }
                    }
                }

                & .remirror-editor {
                    border-radius: ${Tokens.BORDERS.radius};
                    padding: ${Tokens.SIZES["1"]} ${Tokens.SIZES["1.5"]};
                    pointer-events: auto;
                    position: relative;
                    height: 200px;
                    overflow-y: auto;
                }

                /* Placeholder */
                & .remirror-is-empty::before {
                    content: attr(data-placeholder);
                    font-style: italic;
                    color: ${Tokens.COLOURS.greyscale.stone};
                    pointer-events: none;
                    position: absolute;
                }
            `
            : /* css */ `
            & .remirror-editor-wrapper {
                max-height: 200px;
                overflow-y: auto;
            }
        `}
`;
