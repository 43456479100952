import {
    AssigneeType,
    PatientThreadAssignee,
    PatientThreadTicketStateChangeNote,
    TicketState,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { BaseConversationItem } from "shared/concierge/conversations/types/conversation.types";
import {
    StateChangeAssignedToId,
    StateChangeConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    mapBaseConversationItem,
    mapCreatedByOptionalUser,
} from "./conversationItem.helpers";

/**
 * Maps ticket State change to conversation State change
 *
 * @param ticketStateChangeItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapStateChangeItem(
    ticketStateChangeItem: PatientThreadTicketStateChangeNote,
): StateChangeConversationItem | undefined {
    if (!ticketStateChangeItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    const baseConversationItem: BaseConversationItem &
        Pick<StateChangeConversationItem, "contentType" | "createdBy"> = {
        ...mapBaseConversationItem(ticketStateChangeItem),
        contentType: "StateChange",
        // Ok if createdByUserId is not there because it could be a system event (i.e. archived user is auto-reassigned)
        createdBy: mapCreatedByOptionalUser(
            ticketStateChangeItem.message.createdByUserId,
        ),
    };

    if (ticketStateChangeItem.message.state === TicketState.Assignment) {
        const assignedToId = mapToStateChangeAssignee(
            ticketStateChangeItem.message.assignee,
        );

        if (!assignedToId) {
            throw new Error(
                "Cannot map ticket item as assignee cannot be mapped",
            );
        }

        return {
            ...baseConversationItem,
            changeType: "Assignment",
            assignedToId,
        };
    }

    const changeType = mapToStateChangeType(
        ticketStateChangeItem.message.state,
    );
    if (!changeType) {
        throw new Error(
            "Cannot map ticket item as state change type is unknown",
        );
    }

    return {
        ...baseConversationItem,
        changeType,
    };
}

/**
 * Mapper for all state change types except Assignment, as that's dealt with separately
 */
function mapToStateChangeType(
    apiStateChangeType: Exclude<TicketState, "Assignment">,
):
    | Exclude<StateChangeConversationItem["changeType"], "Assignment">
    | undefined {
    switch (apiStateChangeType) {
        case TicketState.Done:
            return "Done";
        case TicketState.ReOpened:
            return "ReOpened";
        case TicketState.PatientMatch:
            return "PatientMatch";
        case TicketState.UnmatchPatient:
            return "PatientUnmatch";
        case TicketState.Urgent:
            return "Urgent";
        case TicketState.NonUrgent:
            return "NonUrgent";

        default:
            return undefined;
    }
}

function mapToStateChangeAssignee(
    apiAssignee?: PatientThreadAssignee | null,
): StateChangeAssignedToId | undefined {
    if (apiAssignee?.type === AssigneeType.User && !!apiAssignee.userId) {
        return {
            type: "User",
            id: apiAssignee.userId,
        };
    }

    if (
        apiAssignee?.type === AssigneeType.UserGroup &&
        !!apiAssignee.userGroupId
    ) {
        return {
            type: "Team",
            id: apiAssignee.userGroupId,
        };
    }

    return undefined;
}
