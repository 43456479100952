import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type unmatchTicketFromPatientOptions = {
    ticketIdentity: TicketIdentity;
    latestToken: string;
    workspaceId: number;
};

export const unmatchTicketFromPatient: ConciergeFetcher<
    unmatchTicketFromPatientOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const response = await TicketApiClient.unmatchTicketFromPatient({
        organisationId: arg.workspaceId,
        latestToken: arg.latestToken,
        ticketIdentity: arg.ticketIdentity,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
