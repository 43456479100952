import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import {
    FlemingLoggedInCustomProperties,
    OriginProps,
} from "./FlemingAnalyticsConstants";

export type NavigationMenuAnalyticsProps = FlemingLoggedInCustomProperties &
    OriginProps & {
        navigationOptionSelected: string;
        notificationCount?: string;
        pageOrigin?: string;
    };

/**
 * Web navigation flow - when a user clicks on one of the buttons in the navigation sub menu
 * Event type: Button click
 **/
export const trackNavigationSubMenuClick = (
    props: NavigationMenuAnalyticsProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "NavigationSubMenu",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
