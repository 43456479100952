import {
    AppointmentChangeSupport,
    AppointmentSupportedChanges,
} from "@accurx/api/appointment";
import { Log } from "@accurx/shared";

import { ChangeLinkType } from "../types";
import { isSupported } from "./isSupported";

/**
 * Unlike in getSupportedAmendMessage, here we want to always return a value
 * of a type ChangeLinkType
 */
export const getSupportedChangeLinkType = (
    supportedChanges: AppointmentSupportedChanges,
): ChangeLinkType => {
    if (
        isSupported(supportedChanges.rebooking) &&
        isSupported(supportedChanges.cancellation)
    ) {
        return "amend this appointment";
    }

    if (
        isSupported(supportedChanges.cancellation) &&
        supportedChanges.rebooking === AppointmentChangeSupport.NotSupported
    ) {
        return "cancel";
    }

    // Returning cancel by default and logging error about unexpected
    // support combination
    Log.error("Unexpected combination of supported changes for appointment", {
        tags: {
            ...supportedChanges,
        },
    });
    return "cancel";
};
