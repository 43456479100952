import { useMemo } from "react";

import { useFuzzyFilter } from "@accurx/hooks";
import { NHSAdviceLink } from "domains/compose/types";

import { groupNHSAdviceByFirstLetter } from "./NHSAdviceSelector.utils";

const SEARCH_OPTIONS = { keys: ["name"], threshold: 0.2 };

type useNHSAdviceLinksFilterProps = {
    links: NHSAdviceLink[];
    searchTerm: string;
};

export const useNHSAdviceLinksFilter = ({
    links,
    searchTerm,
}: useNHSAdviceLinksFilterProps) => {
    const filteredNhsLinks = useFuzzyFilter<NHSAdviceLink>(
        links,
        searchTerm,
        SEARCH_OPTIONS,
    );

    return useMemo(
        () => ({
            groupedNhsAdviceLinks:
                groupNHSAdviceByFirstLetter(filteredNhsLinks),
            count: filteredNhsLinks.length,
        }),
        [filteredNhsLinks],
    );
};
