import * as React from "react";

import {
    GeneratedColumn,
    Icon,
    SortDirection,
    Text,
    Tooltip,
    TooltipContent,
    TooltipPortal,
    TooltipTrigger,
} from "@accurx/design";

import { trackPatientTriageDashboardSortClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { StyledIconWrapper } from "../shared/shared.styles";
import { COLUMN_SORT_DIRECTION } from "../shared/shared.utils";
import {
    StyledTableHeaderButton,
    StyledTooltipContentWrapper,
    StyledTooltipWrapper,
} from "./ColumnHeaderContent.styles";
import {
    getColumnDescription,
    getColumnFullName,
    isColumnSortReversed,
} from "./ColumnHeaderContent.utils";

type ColumnHeaderContentProps = {
    column: GeneratedColumn;
    sortDirection: SortDirection | SortDirection[];
};

export const ColumnHeaderContent = ({
    column,
    sortDirection,
}: ColumnHeaderContentProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const primaryDirection = Array.isArray(sortDirection)
        ? sortDirection[1]
        : sortDirection;

    const handleSortAnalytics = React.useCallback(
        (sortField: string, sortDirection: "Ascending" | "Descending") => {
            trackPatientTriageDashboardSortClick({
                ...analyticsLoggedInProps,
                sortField,
                sortDirection,
                dashboardName: "Patient Triage Dashboard",
                sectionName: "Requests by assignee",
            });
        },
        [analyticsLoggedInProps],
    );

    const handleSort = () => {
        if (
            !column.metadata.isSorted ||
            (column.metadata.isSorted &&
                isColumnSortReversed(column.id, primaryDirection))
        ) {
            column.sort();
        } else {
            column.sort(COLUMN_SORT_DIRECTION[column.id].reverse);
        }
        handleSortAnalytics(
            getColumnFullName(column.id),
            column.metadata.isSorted && primaryDirection === "asc"
                ? "Ascending"
                : "Descending",
        );
    };
    return (
        <>
            <StyledTableHeaderButton onClick={handleSort}>
                <Text skinny variant="label">
                    {column.header}
                </Text>
                <StyledIconWrapper>
                    <Icon
                        id="sort-indicator"
                        size={3}
                        name={column.metadata.isSorted ? "Order" : "Menu"}
                        colour={column.metadata.isSorted ? "blue" : "stone"}
                        rotation={
                            column.metadata.isSorted &&
                            primaryDirection === "asc"
                                ? "down"
                                : "up"
                        }
                    />
                </StyledIconWrapper>
            </StyledTableHeaderButton>
            <StyledTooltipWrapper>
                <Tooltip>
                    <TooltipTrigger>
                        <Icon
                            id="Info"
                            size={3}
                            name="Info"
                            colour="stone"
                            title={`Click for more information on ${getColumnFullName(
                                column.id,
                            )}`}
                        />
                    </TooltipTrigger>
                    <TooltipPortal>
                        <TooltipContent>
                            <StyledTooltipContentWrapper>
                                <Text skinny>
                                    {getColumnDescription(column.id)}
                                </Text>
                            </StyledTooltipContentWrapper>
                        </TooltipContent>
                    </TooltipPortal>
                </Tooltip>
            </StyledTooltipWrapper>
        </>
    );
};
