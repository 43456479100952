import { SelfBookLink } from "domains/compose/reducer.types";

export const getAppointmentTypeDisplay = (
    appointmentType: SelfBookLink["appointmentType"],
) => {
    switch (appointmentType) {
        case "FaceToFace":
            return "face-to-face";
        case "Telephone":
            return "telephone";

        default: {
            // Make sure build breaks if we don't render one of the attachments origins
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = appointmentType;
            return null;
        }
    }
};
