import React, { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { Text, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { Cell, Row, StickyTable } from "react-sticky-table";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

import { LoadingWrapper } from "../../sharedComponents/LoadingWrapper";
import { PracticeDetails } from "../Practices.types";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { loadTeams, updateTeamMembership } from "./TeamNotifications.actions";
import { FixedWidthCell } from "./TeamNotifications.styles";
import { FeatureFlag, NotificationUser } from "./TeamNotifications.types";

const getCheckboxStyle = (isEnabled: boolean) => ({
    background: isEnabled ? Tokens.COLOURS.primary.green[50] : undefined,
});

const shouldSkipTeam = (features: FeatureFlag[], team: PracticeDetails) =>
    IsFeatureEnabled(features, FeatureName.InboxConsistentFolders) &&
    (team.name === "Failed Delivery Receipts" ||
        team.name === "Patient Responses");

export const TeamNotifications = ({
    noHeader,
}: {
    noHeader?: boolean;
}): JSX.Element => {
    useAccurxWebTitle("Manage team notifications");

    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const isEditable = useAppSelector((state) => state.teams.isEditable);
    const practiceId = useAppSelector(
        (state) => state.practices.selectedPractice,
    );
    const loadingStatus = useAppSelector(
        (state) => state.teams.loadingStatus,
        shallowEqual,
    );
    const teams = useAppSelector((state) => state.teams.teams, shallowEqual);
    const users = useAppSelector((state) => state.teams.users, shallowEqual);
    const features = useAppSelector((state) => {
        const practice = state.practices.items.find(
            (n: PracticeDetails) =>
                n.id.toString() === state.practices.selectedPractice,
        );
        return practice?.features || [];
    }, shallowEqual);

    useEffect(() => {
        if (practiceId) {
            dispatch(loadTeams(practiceId));
        }
    }, [dispatch, practiceId]);

    useEffect(() => {
        const analyticsProps = {
            ...analyticsLoggedInProps,
            isAdmin: isEditable?.toString() || "false",
        };

        ChainAnalyticsTracker.trackManageTeamsPageLoaded(analyticsProps);
    }, [analyticsLoggedInProps, dispatch, isEditable]);

    const onCheckboxChanged = (
        user: NotificationUser,
        team: PracticeDetails,
        isMember: boolean,
    ) => {
        dispatch(
            updateTeamMembership(
                practiceId,
                user,
                { id: team.id, name: team.name },
                !isMember,
            ),
        );
        const analyticsProps = {
            ...analyticsLoggedInProps,
            teamId: team.id.toString(),
            teamName: team.name,
        };

        if (isMember) {
            ChainAnalyticsTracker.trackUserRemovedFromTeam(analyticsProps);
        } else {
            ChainAnalyticsTracker.trackUserAddedToTeam(analyticsProps);
        }
    };

    return (
        <LoadingWrapper loadingStatus={loadingStatus}>
            <div>
                {!noHeader && (
                    <>
                        <Breadcrumb title="Manage Team Notifications" />
                        <Text variant={"subtitle"}>
                            Manage Team Notifications
                        </Text>
                    </>
                )}
                <Text props={{ className: "mb-4" }}>
                    Here you can select who is notified of messages arriving in
                    the following teams.
                </Text>

                <div className="mb-4" style={{ height: "40rem" }}>
                    <StickyTable className="table" borderWidth={"1px"}>
                        <Row>
                            <Cell>
                                <Text variant={"label"}>User</Text>
                            </Cell>
                            {teams &&
                                teams.reduce((output, team) => {
                                    if (shouldSkipTeam(features, team)) {
                                        // skip iteration
                                        return output;
                                    }
                                    output.push(
                                        <Cell key={team.id}>
                                            <Text variant={"label"}>
                                                {team.name}
                                            </Text>
                                        </Cell>,
                                    );
                                    return output;
                                }, [])}
                        </Row>

                        {users &&
                            users.map((user: NotificationUser) => {
                                return (
                                    <Row key={user.id}>
                                        <FixedWidthCell title={user.name}>
                                            {user.name || user.email}
                                        </FixedWidthCell>
                                        {teams &&
                                            teams.reduce((output, team) => {
                                                const teamUser =
                                                    team.users.find(
                                                        (x: { id: number }) =>
                                                            x.id === user.id,
                                                    );
                                                const isMember =
                                                    teamUser !== undefined
                                                        ? teamUser.isMember
                                                        : null;
                                                const updateStatus =
                                                    teamUser !== undefined
                                                        ? teamUser.updateStatus
                                                        : null;
                                                if (
                                                    shouldSkipTeam(
                                                        features,
                                                        team,
                                                    )
                                                ) {
                                                    // skip iteration
                                                    return output;
                                                }
                                                output.push(
                                                    <Cell
                                                        key={team.id}
                                                        style={getCheckboxStyle(
                                                            isMember,
                                                        )}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            data-testid={`${user.name}|${team.name}`}
                                                            disabled={
                                                                isEditable ===
                                                                false
                                                            }
                                                            checked={
                                                                isMember ===
                                                                true
                                                            }
                                                            onChange={() =>
                                                                onCheckboxChanged(
                                                                    user,
                                                                    team,
                                                                    isMember,
                                                                )
                                                            }
                                                        />
                                                        {updateStatus ===
                                                            LoadingStatus.Loading && (
                                                            // Replace this with a spinner from the Accurx design library once they're in there
                                                            <div className="balls realign">
                                                                <div className="ball skipping"></div>
                                                                <div className="ball stone-one"></div>
                                                                <div className="ball stone-two"></div>
                                                                <div className="ball stone-three"></div>
                                                            </div>
                                                        )}
                                                    </Cell>,
                                                );
                                                return output;
                                            }, [])}
                                    </Row>
                                );
                            })}
                    </StickyTable>
                </div>
            </div>
        </LoadingWrapper>
    );
};
