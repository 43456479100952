import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { MessageTemplateCategoryResponse } from "@accurx/api/patient-messaging";

import { getMessageTemplateCategories } from "api/PatientMessagingServer/PatientMessagingServerApi";

export const MESSAGE_TEMPLATE_CATEGORIES_QUERY_KEY =
    "messageTemplateCategories";

type MessageTemplateCategoriesParameters = {
    organisationId: string;
    owner: "user" | "organisation";
};
export const useMessageTemplateCategoriesQuery = createQueryHook<
    MessageTemplateCategoriesParameters,
    MessageTemplateCategoryResponse
>(({ organisationId, owner }) => ({
    queryKey: [
        MESSAGE_TEMPLATE_CATEGORIES_QUERY_KEY,
        { organisationId, owner },
    ],
    queryFn: async () => {
        const response = await getMessageTemplateCategories({
            organisationId,
            owner,
        });
        const data = returnDataOrThrow(response);
        return data;
    },
}));
