import { useMemo } from "react";

import { useCurrentUser } from "@accurx/auth";
import { useParams } from "react-router-dom";

import { parseUrlId } from "./helpers/parseUrlId";

/**
 * These props should be added to all events that are considered by product to be 'meaningful actions'
 */
export type MeaningfulActionProps = {
    organisationNationalCode: string | null;
    isMultiFactorAuthEnabled: boolean;
    isMultiFactorAuthActive: boolean;
};

/**
 * Accepts an optional workspace ID. If none is provided, it will use the workspace or organisation ID from the url
 * The user must be logged in for this hook to be called, as it relies on the user object existing
 * If the user is not in a workspace, any properties relating to organisation will be returned as `null` and user properties will be returned as usual
 * @returns tracking properties that are required by meaningful action events
 */
export const useMeaningfulActionAnalyticsProps = ({
    workspaceId,
}: { workspaceId?: number } = {}): MeaningfulActionProps => {
    const { orgId: orgIdFromUrl, workspaceId: workspaceIdFromUrl } = useParams<{
        orgId?: string;
        workspaceId?: string;
    }>();

    const currentWorkspaceId =
        workspaceId || parseUrlId(workspaceIdFromUrl || orgIdFromUrl);

    const { user } = useCurrentUser();

    // Memoised so that we don't repeat this search every render unless the workspace ID or list of organisations has changed
    const organisationNationalCode = useMemo(() => {
        // Sets the organisationNationalCode to null if the user is not yet in an organisation (eg during onboarding flow)
        return currentWorkspaceId
            ? user.organisations.find((org) => org.orgId === currentWorkspaceId)
                  ?.nationalCode || null
            : null;
    }, [currentWorkspaceId, user.organisations]);

    return {
        organisationNationalCode,
        isMultiFactorAuthEnabled: user.isSetupFor2Fa || false,
        isMultiFactorAuthActive: user.is2FAed || false,
    };
};
