import { Feedback, Text } from "@accurx/design";

export const WhatPatientDetailInformation = () => (
    <Feedback
        title={"What does the 'Add patient detail' button do?"}
        colour="information"
    >
        <Text>
            When you add a 'patient detail' field, we automatically fill it with
            information from the patient's record. If the record doesn't have
            that information, we'll leave it blank for you to fill in manually.
        </Text>
    </Feedback>
);
