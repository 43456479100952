import { StackPanel, Table, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTableBody = styled(Table.Body)`
    vertical-align: top;
`;

export const CentredText = styled(Text)`
    text-align: center;
`;

export const StyledHeaderStackPanel = styled(StackPanel)`
    > div {
        max-width: 300px;
    }

    padding: ${Tokens.SIZES[0.5]} 0;

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        flex-direction: column;
        align-items: flex-start;

        > div {
            margin-top: ${Tokens.SIZES[1]};
        }
    }
`;

export const StyledTable = styled(Table)`
    * > tr {
        th:first-child,
        td:first-child {
            padding-left: ${Tokens.SIZES[3]};
        }

        th:last-child,
        td:last-child {
            padding-right: ${Tokens.SIZES[3]};
        }
    }
`;
