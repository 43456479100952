import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
    OriginProps,
} from "./FlemingAnalyticsConstants";

export type OnboardingAnalyticsFlowProps = FlemingLoggedInCustomProperties &
    OriginProps & {
        feature: string;
    };

/**
 * Triggered when someone views the Get Started page during onboarding
 *
 * Event type: Page View
 * */
export const trackOnboardingGetStartedPageView = (): void => {
    GenericTrackEvent({
        object: "OnboardingGetStarted",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
        },
    });
};

/**
 * Triggered when someone starts an onboarding tour
 *
 * Event type: Button click
 * */
export const trackOnboardingStartButtonClick = (
    props: OnboardingAnalyticsFlowProps,
): void => {
    GenericTrackEvent({
        object: "OnboardingStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * Triggered when someone skips an onboarding tour
 *
 * Event type: Button click
 * */
export const trackOnboardingSkipButtonClick = (
    props: OnboardingAnalyticsFlowProps,
): void => {
    GenericTrackEvent({
        object: "OnboardingSkip",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
