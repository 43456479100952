import { Text } from "@accurx/design";
import styled from "styled-components";

export const StyledHeader = styled((props) =>
    Text({ ...props, variant: "title", as: "h1" }),
)`
    text-align: center;
`;

export const StyledText = styled(Text)`
    text-align: center;
`;
