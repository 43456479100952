import { useCurrentWorkspace } from "@accurx/auth";
import { Log } from "@accurx/shared";
import { useSnomedCodesQuery } from "domains/compose/hooks/useSnomedCodesQuery";
import { SnomedCode } from "domains/compose/types";

import { SnomedCodesInfo } from "./SnomedCodesInfo";

export type SnomedCodesInfoProps = {
    snomedCodeIds: string[];
};

export const AsyncSnomedCodesInfo = ({
    snomedCodeIds,
}: SnomedCodesInfoProps) => {
    const workspace = useCurrentWorkspace();
    const snomedCodesQuery = useSnomedCodesQuery(
        {
            workspaceId: workspace.orgId,
        },
        {
            enabled: snomedCodeIds.length > 0,
            onSuccess: (res) => {
                snomedCodeIds.forEach((id) => {
                    const matchedSnomedCode = res.find(
                        (snomedCode) => snomedCode.id === id,
                    );

                    if (matchedSnomedCode === undefined) {
                        Log.error("Unable to find requested SNOMED code", {
                            tags: { id },
                        });
                    }
                });
            },
        },
    );

    const snomedCodes: SnomedCode[] = snomedCodeIds.map((id) => {
        const term = snomedCodesQuery.data?.find(
            (snomedCode) => snomedCode.id === id,
        )?.term;

        return {
            id,
            term,
        };
    });

    return (
        <SnomedCodesInfo
            snomedCodes={snomedCodes}
            isLoading={snomedCodesQuery.isFetching}
        />
    );
};
