import { IWrappedResult } from "@accurx/shared";
import { Action, Reducer } from "redux";

import {
    IMessagePracticeRequest,
    IMessagePracticeResponse,
} from "api/FlemingDtos";
import { StoredFileWithId } from "app/fileUpload/FileUploadReducer";

import {
    FILE_REMOVED,
    FILE_UPLOAD_FINISHED,
    FILE_UPLOAD_STARTED,
} from "../fileUpload/FileUploadActions";
import { RESET_PRODUCT } from "../selectProduct/SelectProductActions";
import {
    KnownAction,
    MESSAGE_PRACTICE_FINISHED,
    MESSAGE_PRACTICE_RESET_PRODUCT,
    MESSAGE_PRACTICE_STARTED,
} from "./MessagePracticeActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessagePracticeState {
    isMessageSending: boolean;
    messageInfoSent: IMessagePracticeRequest;
    lastMessagePracticeResponse: IWrappedResult<IMessagePracticeResponse> | null;
    lastFileUploaded: StoredFileWithId | null;
    lastFileUploadedHasErrors: boolean;
    lastFileRemoved: string | null;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: MessagePracticeState = {
    messageInfoSent: {
        patientToken: "",
        nhsNumber: "null",
        practiceCode: "null",
        messageBody: "null",
        organisationId: null,
        externalEmailAttachmentIds: new Array<string>(),
    },
    lastMessagePracticeResponse: null,
    isMessageSending: false,
    lastFileUploaded: null,
    lastFileUploadedHasErrors: false,
    lastFileRemoved: null,
};

export const reducer: Reducer<MessagePracticeState> = (
    state = initialState,
    incomingAction: Action,
): MessagePracticeState => {
    //If we re-select product then clear previous state
    if (incomingAction.type === RESET_PRODUCT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case MESSAGE_PRACTICE_STARTED:
            return {
                ...state,
                isMessageSending: true,
                lastMessagePracticeResponse: null, //Reset if not already
                messageInfoSent: action.requestData,
            } as MessagePracticeState;

        case MESSAGE_PRACTICE_FINISHED:
            return {
                ...state,
                isMessageSending: false,
                lastMessagePracticeResponse: action.response,
            };

        case MESSAGE_PRACTICE_RESET_PRODUCT:
            return initialState;

        case FILE_UPLOAD_FINISHED:
            return {
                ...state,
                lastFileUploadedHasErrors:
                    (action.error != null && action.error.trim() !== "") ||
                    action.file === null,
                lastFileUploaded: action.file,
            };

        case FILE_UPLOAD_STARTED:
            return {
                ...state,
                lastFileUploadedHasErrors: false,
            };

        case FILE_REMOVED:
            return {
                ...state,
                lastFileRemoved: action.fileId,
            };
    }

    return state;
};
