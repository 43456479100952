import {
    PatientAppointmentRequestType,
    PatientThreadPatientAppointmentRequestNote,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    AppointmentRequestConversationItem,
    PatientAppointmentRequestItemType,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    conversationItemMappingLog,
    mapBaseConversationItem,
    mapPatientResponseSections,
} from "./conversationItem.helpers";

/**
 * Maps ticket Appointment request to conversation Appointment request
 *
 * @param ticketAppointmentRequestItem Appointment request as it's sent from the API
 * @returns mapped Appointment request conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapAppointmentRequest(
    ticketAppointmentRequestItem: PatientThreadPatientAppointmentRequestNote,
): AppointmentRequestConversationItem | undefined {
    if (!ticketAppointmentRequestItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    // Do not accept an appointment request that has no responses
    if (
        !ticketAppointmentRequestItem.message.responses ||
        ticketAppointmentRequestItem.message.responses.length === 0
    ) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    const responses = ticketAppointmentRequestItem.message.responses;
    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketAppointmentRequestItem,
    );

    const requestType = mapAppointmentRequestType(
        ticketAppointmentRequestItem.message.patientRequestType,
    );

    // Log to Sentry, but don't have mapping fail completely
    if (requestType === "None") {
        conversationItemMappingLog({
            message: "Could not map to a known requestType, defaulting to None",
            itemId: ticketAppointmentRequestItem.id,
            itemType: ticketAppointmentRequestItem.type,
        });
    }

    return {
        ...baseConversationItem,
        contentType: "PatientAppointmentRequestNote",
        createdBy: { type: "Patient" },
        sections: result.sections,
        requestType,
    };
}

function mapAppointmentRequestType(
    requestType: PatientAppointmentRequestType,
): PatientAppointmentRequestItemType {
    switch (requestType) {
        case PatientAppointmentRequestType.Cancel:
            return "Cancellation";
        case PatientAppointmentRequestType.GeneralEnquiry:
            return "General enquiry";
        case PatientAppointmentRequestType.Reschedule:
            return "Reschedule";

        case PatientAppointmentRequestType.None:
        default:
            return "None";
    }
}
