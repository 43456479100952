import { NativeApi } from "@accurx/native";
import { createEndpoint } from "domains/inbox/native/createEndpoint";
import { PatientExternalIdentityDto } from "domains/inbox/schemas/PatientExternalIdentityDtoSchema";

export type SyncPatientDemographicsPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

export const syncPatientDemographics = createEndpoint<
    unknown,
    SyncPatientDemographicsPayload
>("MutateSyncPatientDemographics", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateSyncPatientDemographics",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
});
