import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { patientNhsNumber } from "@accurx/concierge/util/patientNhsNumber";
import * as UI from "@accurx/design";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { AutoFocusHeading } from "domains/inbox/components/AutoFocusHeading/AutoFocusHeading";
import { PatientName } from "domains/inbox/components/PatientName/PatientName";
import { PatientNotFound } from "domains/inbox/components/PatientNotFound/PatientNotFound";
import { useViewportSize } from "domains/inbox/hooks/util";
import { formatDateShortWithHyphens } from "domains/inbox/util/format/date";
import { formatPatientName } from "domains/inbox/util/format/patientDisplayInfo";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";

import { OpenInEmrButton } from "../OpenInEmrButton/OpenInEmrButton";
import {
    StyledContainer,
    StyledPatientDetails,
    StyledSkeletonLoader,
} from "./PatientHeader.styles";
import { PatientMatchingActions } from "./PatientMatchingActions";

type PatientHeaderProps = {
    conversation: Conversation;
    onClickSearchForPatient: () => void;
    onClickBack: (() => void) | undefined;
};

export const PatientHeader = ({
    conversation,
    onClickSearchForPatient,
    onClickBack,
}: PatientHeaderProps) => {
    const size = useViewportSize();
    const patientId = conversation.regardingPatientId;
    const patientMatchState = getPatientMatchState(conversation);
    const patient = usePatient({ patientId });

    const nhsNumber = patient?.externalIds.find(
        (externalId) => externalId.type === "NhsNumber",
    )?.value;

    const patientName = formatPatientName({
        firstName: patient?.firstName,
        familyName: patient?.familyName,
    });

    const showPatientMatchingActions =
        !!patientMatchState && conversation.status !== "Done";

    const showSuggestedMatch =
        !patient && patientMatchState?.type === "SuggestedMatch";

    return (
        <StyledContainer>
            <UI.Item>
                <UI.Flex gap="1">
                    {onClickBack !== undefined &&
                        (size === "xs" || size === "sm") && (
                            <UI.Item>
                                <nav>
                                    <UI.Button
                                        theme="transparent"
                                        dimension="large"
                                        icon={{
                                            name: "Arrow",
                                            title: "Close conversation",
                                            id: "close-convsersation",
                                            rotation: "left",
                                        }}
                                        onClick={onClickBack}
                                    />
                                </nav>
                            </UI.Item>
                        )}
                    <UI.Item flex={"1"}>
                        <UI.Flex
                            justifyContent="space-between"
                            flexWrap="wrap"
                            gap="1"
                        >
                            <UI.Item>
                                {showSuggestedMatch && (
                                    <PatientDetails
                                        nhsNumber={patientNhsNumber(
                                            patientMatchState.suggestedMatch
                                                .externalIds,
                                        )}
                                        dateOfBirth={
                                            patientMatchState.suggestedMatch
                                                .dateOfBirth
                                        }
                                        name={formatPatientName({
                                            firstName:
                                                patientMatchState.suggestedMatch
                                                    .firstName,
                                            familyName:
                                                patientMatchState.suggestedMatch
                                                    .familyName,
                                        })}
                                        ageYear={
                                            patientMatchState.suggestedMatch
                                                .ageYear
                                        }
                                        gender={
                                            patientMatchState.suggestedMatch
                                                .gender
                                        }
                                    />
                                )}
                                {!showSuggestedMatch &&
                                    (!patient ? (
                                        <PatientNotFound large />
                                    ) : (
                                        <PatientDetails
                                            nhsNumber={nhsNumber}
                                            dateOfBirth={patient.dateOfBirth}
                                            name={patientName}
                                            ageYear={patient.ageYear}
                                            gender={patient.gender}
                                        />
                                    ))}
                            </UI.Item>
                            <UI.Flex gap="1">
                                {showPatientMatchingActions && (
                                    <UI.Item>
                                        <PatientMatchingActions
                                            onClickSearchForPatient={
                                                onClickSearchForPatient
                                            }
                                            patientMatchState={
                                                patientMatchState
                                            }
                                            conversation={conversation}
                                        />
                                    </UI.Item>
                                )}
                                {!!patientId && (
                                    <OpenInEmrButton patientId={patientId} />
                                )}
                            </UI.Flex>
                        </UI.Flex>
                    </UI.Item>
                </UI.Flex>
            </UI.Item>
        </StyledContainer>
    );
};

type PatientDetailsProps = {
    nhsNumber?: string;
    dateOfBirth: string;
    name: string;
    ageYear: number;
    gender: string;
};

const PatientDetails = ({
    nhsNumber,
    dateOfBirth,
    name,
    ageYear,
    gender,
}: PatientDetailsProps) => {
    return (
        <UI.Flex flexDirection="column" gap="1">
            <UI.Item>
                <AutoFocusHeading as="h2" skinny>
                    <PatientName
                        name={name}
                        ageYear={ageYear}
                        gender={gender}
                        textProps={{
                            variant: "subtitle",
                        }}
                    />
                </AutoFocusHeading>
            </UI.Item>
            <StyledPatientDetails
                justifyContent="flex-start"
                flexDirection="row"
            >
                {nhsNumber && (
                    <UI.Item>
                        <UI.Text skinny colour="metal">
                            {`NHS: ${formatNhsNumber(nhsNumber)}`}
                        </UI.Text>
                    </UI.Item>
                )}
                {!!dateOfBirth && (
                    <UI.Item>
                        <UI.Text skinny colour="metal">
                            {`DOB: ${formatDateShortWithHyphens(dateOfBirth)}`}
                        </UI.Text>
                    </UI.Item>
                )}
            </StyledPatientDetails>
        </UI.Flex>
    );
};

export const LoadingState = () => (
    <StyledContainer>
        <UI.Flex flexDirection="column" gap="1">
            <StyledSkeletonLoader height="20px" width="64%" theme="dark" />
            <UI.Flex gap="3">
                <StyledSkeletonLoader
                    height={UI.Tokens.SIZES[2]}
                    width="22%"
                    theme="dark"
                />
                <StyledSkeletonLoader
                    height={UI.Tokens.SIZES[2]}
                    width="22%"
                    theme="dark"
                />
            </UI.Flex>
        </UI.Flex>
    </StyledContainer>
);
