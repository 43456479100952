import { Question, QuestionnaireResponse } from "@accurx/api/florey-builder";
import { v4 as uuid } from "uuid";

import {
    ClientSideBranchData,
    ClientSideQuestion,
    SelectedQuestionnaire,
} from "../types/context.types";

export const mapQuestionnaireResponseToSelectedQuestionnaire = (
    questionnaireResponse: QuestionnaireResponse,
): SelectedQuestionnaire => ({
    ...questionnaireResponse,
    questions: mapResponseToSelectedQuestionnaire(
        // Since introduction of reordering, question.order is the source of truth for question order, not the order in the array in the API response - this sorting is to ensure the frontend can still map this.
        questionnaireResponse.questions?.sort(compareQuestionOrders) ?? [],
    ),
});

const mapResponseToSelectedQuestionnaire = (
    questions: Question[],
): ClientSideQuestion[] =>
    questions.map((question) => {
        const branchQuestions = mapBranchQuestionToDisplayQuestion(
            question.branchData?.branchQuestions ?? [],
        );
        const branchData = {
            ...question.branchData,
            branchQuestions,
        } as Optional<ClientSideBranchData>;
        return { ...question, clientId: uuid(), branchData };
    });

const mapBranchQuestionToDisplayQuestion = (
    branchQuestions: Question[] | undefined,
): ClientSideQuestion[] | undefined => {
    if (branchQuestions === undefined) {
        return undefined;
    }
    return branchQuestions.map((branchQuestion) => {
        return { ...branchQuestion, clientId: uuid(), branchData: null }; //We only allow one level of nesting. If we increase this, we will need to make a recursive function here to add client Ids all the way down
    });
};

const compareQuestionOrders = (a: Question, b: Question) => {
    if (
        (a.order === null || a.order === undefined) &&
        (b.order === null || b.order === undefined)
    ) {
        return 0;
    }
    if (a.order === null || a.order === undefined) {
        return -1;
    }
    if (b.order === null || b.order === undefined) {
        return 1;
    }
    return a.order - b.order;
};
