import { HeartbeatPeriod, useCurrentUser } from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import { useQuery } from "@tanstack/react-query";
import { ApiQueryKey, helpers } from "domains/inbox/api";

export const useAuthHeartbeatQuery = () => {
    const { user } = useCurrentUser();
    const isTrustedDevice = user.isTrustedDevice;
    const isWebView = useBrowserEnvironment() === "WebView";

    const isWebViewAndTrustedDevice = isWebView && isTrustedDevice;

    const { status, data } = useQuery<
        HeartbeatPeriod,
        Error,
        HeartbeatPeriod,
        ApiQueryKey
    >({
        queryKey: [{ path: "/api/account/heartbeat/period" }],
        queryFn: helpers.getJson,
        enabled: isWebViewAndTrustedDevice,
    });

    const minimumRefetchInterval = 30 * 60_000; //default to 30 mins
    const refetchInterval = data?.cookieRefreshTimeSpan.ticks
        ? Math.max(
              minimumRefetchInterval,
              data.cookieRefreshTimeSpan.ticks / 10_000,
          )
        : minimumRefetchInterval;

    return useQuery<number | null, Error, number | null, ApiQueryKey>({
        queryKey: [{ path: "/api/account/heartbeat/authed" }],
        queryFn: helpers.getStatusCode,
        enabled: isWebViewAndTrustedDevice && status === "success",
        refetchInterval: (data) =>
            data && data >= 500 ? false : refetchInterval + 2_000, //do not retry on server failure
        refetchIntervalInBackground: true,
    });
};
