import { useEffect, useMemo, useState } from "react";

import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import { Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    getUploadDetails,
    getUploadStatus,
} from "app/batchMessage/gp/BatchMessage.actions";
import { BatchStatus } from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { getCurrentUploadStep } from "app/batchMessage/gp/components/upload/BatchMessageUpload.utils";
import { UploadInitial } from "app/batchMessage/gp/components/upload/UploadInitial";
import { UploadReview } from "app/batchMessage/gp/components/upload/UploadReview";
import { BatchUploadDemographicsProgress } from "app/batchMessage/shared/BatchUploadDemographicsProgress";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

let interval: ReturnType<typeof setInterval> | null = null;

const MAX_INTERVAL_TIME = 1000; // in sec

export const BatchMessageUpload = (): JSX.Element => {
    useAccurxWebTitle("Add a batch message patient list");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store data
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const csvUploadStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.csvUploadStatus,
        shallowEqual,
    );
    const uploadPercentage = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageProgressPercentage,
    );
    const totalNonTextable = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.totalNonTextable,
    );
    const uploadDetailsStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.uploadDetailsStatus,
        shallowEqual,
    );
    const batchMessageId = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageId,
    );
    const batchUploadStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.batchUploadStatus,
        shallowEqual,
    );
    const batchMessageStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageStatus,
        shallowEqual,
    );
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );
    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );
    const numOfTextablePatients = patientList.filter(
        (item: BatchMessageItemSummary) => item.isTextable,
    ).length;

    // States
    const [uploadRequestCount, setUploadRequestCount] = useState<number>(0);

    // Refresh the data at intervals to check on upload status
    useEffect(() => {
        const checkOnUpload = (): void => {
            // Continue to check for the status
            if (uploadRequestCount < MAX_INTERVAL_TIME) {
                if (
                    batchMessageStatus !== BatchStatus.DemographicsPopulated &&
                    batchUploadStatus !== LoadingStatus.Loading
                ) {
                    const updateCount = () => {
                        dispatch(getUploadStatus(practiceId, batchMessageId));
                        setUploadRequestCount((prevState) => prevState + 1);
                    };

                    if (uploadRequestCount === 0) {
                        updateCount();
                    }
                    if (interval !== null) {
                        clearInterval(interval);
                    }

                    // Will continue refreshing for about 3 hours before giving up (60 * 1s + 940 * 10s)
                    interval = setInterval(
                        updateCount,
                        uploadRequestCount < 60 ? 1000 : 10000,
                    );
                }
            }
            // Stop fetching the status and get the result
            if (batchMessageStatus === BatchStatus.DemographicsPopulated) {
                if (interval !== null) {
                    clearInterval(interval);
                }
                dispatch(getUploadDetails(practiceId, batchMessageId));
            }
        };

        // Start checking for the status
        if (
            csvUploadStatus === LoadingStatus.Loaded &&
            batchUploadStatus !== LoadingStatus.Failed
        ) {
            checkOnUpload();
        }

        return () => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };
    }, [
        dispatch,
        csvUploadStatus,
        batchMessageId,
        batchUploadStatus,
        batchMessageStatus,
        practiceId,
        uploadRequestCount,
    ]);

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const clickForwardFunction = (): void => {
        const analyticsProps: ChainAnalyticsTracker.BatchContinuedToWriteMessageProps =
            {
                ...analyticsLoggedInProps,
                patientsIncluded: numOfTextablePatients,
                patientsExcluded: totalNonTextable,
            };
        ChainAnalyticsTracker.trackBatchContinuedToWriteMessage(analyticsProps);
    };

    const {
        pageTitle,
        isStateInitial,
        isStateDemographics,
        isStateReviewList,
    } = useMemo(
        () =>
            getCurrentUploadStep({
                csvUploadStatus,
                batchUploadStatus,
                uploadDetailsStatus,
            }),
        [csvUploadStatus, batchUploadStatus, uploadDetailsStatus],
    );

    const routes = findBatchRoute(practiceId, BatchRoute.UPLOAD, batchType);

    return (
        <>
            <Breadcrumb title="Batch Messaging" />
            <div className="col-12 col-lg-8 offset-lg-2 text-center mb-4 pb-3 px-0">
                <BatchMessageHeader title={pageTitle} stepNumber={2} />
                {(isStateReviewList || isStateDemographics) && (
                    <Text>This is a summary of the list you provided.</Text>
                )}
            </div>
            {isStateInitial && <UploadInitial />}
            {isStateDemographics && (
                <BatchUploadDemographicsProgress
                    percentage={uploadPercentage ?? 100}
                />
            )}
            {isStateReviewList && <UploadReview />}
            <StepsFooter
                backText={"Back"}
                backLink={routes.back}
                backClickFunction={handleBack}
                forwardText={"Continue"}
                forwardLink={routes.forward}
                forwardClickFunction={clickForwardFunction}
                disabled={
                    // On a review step we don't want to continue if uploaded file has 0 contactable patients
                    (isStateReviewList && numOfTextablePatients === 0) ||
                    (csvUploadStatus === LoadingStatus.Loaded &&
                        uploadDetailsStatus === LoadingStatus.Loaded) === false
                }
            />
        </>
    );
};

export default BatchMessageUpload;
