// eslint-disable-next-line
import { Route, Switch, generatePath } from "react-router";

import { CreateReminderPage, EditReminderPage, OverviewPage } from "./pages";

const basePath = "/w/:workspaceId/settings/appointment-reminders";
const createPagePath = `${basePath}/create`;
const editPagePath = `${basePath}/:reminderId`;

export function AppointmentRemindersRoutes() {
    return (
        <Switch>
            <Route path={basePath} exact>
                <OverviewPage />
            </Route>
            <Route path={createPagePath} exact>
                <CreateReminderPage />
            </Route>
            <Route path={editPagePath} exact>
                <EditReminderPage />
            </Route>
        </Switch>
    );
}

export const getOverviewPagePath = (workspaceId: number | string) => {
    return generatePath(basePath, { workspaceId });
};

export const getCreatePagePath = (workspaceId: number | string) => {
    return generatePath(createPagePath, { workspaceId });
};

export const getEditPagePath = (
    workspaceId: number | string,
    reminderId: number,
) => {
    return generatePath(editPagePath, { workspaceId, reminderId });
};
