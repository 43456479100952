import { useCurrentWorkspace } from "@accurx/auth";
import { useMutation } from "@tanstack/react-query";

import { mutateSignalRInvoke } from "../api/endpoints/mutateSignalRInvoke";
import { NativeTransportError } from "../errors";
import { useNativeTransport } from "./useNativeTransport";

// A hook to trigger a SignalR method via the native bridge
export const useSignalRInvoke = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation({
        mutationKey: ["MutateSignalRClientInvoke"],
        mutationFn: async ({
            methodName,
            payload,
        }: {
            methodName: string;
            payload: object;
        }) => {
            if (!transport) {
                throw new NativeTransportError(
                    "useSignalRInvoke: No native transport",
                );
            }

            return await mutateSignalRInvoke(
                {
                    transport,
                    workspaceId,
                },
                { methodName, payload },
            );
        },
    });
};
