import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const Header = styled.header`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: ${Tokens.SHADOWS.raised.high.silver};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }
`;
