import FlemingApi from "api/FlemingApiClient";
import { IDeliveryReceipt, IDeliveryReceiptRequest } from "api/FlemingDtos";

// Action Types

export const REQUEST_RECEIPTS_STARTED = "REQUEST_RECEIPTS_STARTED";
export const REQUEST_RECEIPTS_SUCCESS = "REQUEST_RECEIPTS_SUCCESS";
export const REQUEST_RECEIPTS_FAILURE = "REQUEST_RECEIPTS_FAILED";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestReceiptsStartedAction {
    type: typeof REQUEST_RECEIPTS_STARTED;
}

interface RequestReceiptsSuccessAction {
    type: typeof REQUEST_RECEIPTS_SUCCESS;
    deliveryReceipts: IDeliveryReceipt[];
    lastDeliveryReceiptsOrganisation: number | null;
}

interface RequestReceiptsFailureAction {
    type: typeof REQUEST_RECEIPTS_FAILURE;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestReceiptsStartedAction
    | RequestReceiptsSuccessAction
    | RequestReceiptsFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getDeliveryReceipts:
        (request: IDeliveryReceiptRequest): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: REQUEST_RECEIPTS_STARTED,
            });

            const getDeliveryReceiptsResponse =
                await FlemingApi.getDeliveryReceipts(request);
            if (getDeliveryReceiptsResponse.success) {
                return dispatch({
                    type: REQUEST_RECEIPTS_SUCCESS,
                    deliveryReceipts:
                        getDeliveryReceiptsResponse.deliveryReceipts,
                    lastDeliveryReceiptsOrganisation:
                        getDeliveryReceiptsResponse.organisationId,
                });
            }
            return dispatch({
                type: REQUEST_RECEIPTS_FAILURE,
            });
        },
};
