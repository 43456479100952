import React from "react";

import { Text, Thread } from "@accurx/design";

import { LabelTagConversationItem } from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";

export type LabelTagItemProps =
    BaseConversationThreadItem<LabelTagConversationItem>;

// This was created to provide basic support for label tags to stop error logging
// For full support would require design input into how this looks, and handling edge cases
// like no outcome
export const LabelTagItem = ({
    item,
    handleOnItemRead,
    createdByDisplayName,
}: LabelTagItemProps): JSX.Element => {
    const messageContent = createdByDisplayName
        ? `${createdByDisplayName} edited the outcome to be ${item.outcome}`
        : `The outcome was edited to be ${item.outcome}`;

    return (
        <Thread.Message
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <Text>{messageContent}</Text>
        </Thread.Message>
    );
};
