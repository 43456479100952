/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { DeliveryReceiptStatus, PatientIdType } from "./DeliveryReceipts.types";

export const getPatientIdentifierColumnHeader = (
    patientIdType?: PatientIdType,
): string => {
    switch (patientIdType) {
        case PatientIdType.Emis:
            return "Emis Number";
        case PatientIdType.SystmOne:
            return "SystmOne PseudoNumber";
        case PatientIdType.Vision:
            return "Vision Number";
        case PatientIdType.Unknown:
        case PatientIdType.NhsNumber:
        default:
            return "NHS Number";
    }
};

export const returnTextForTable = (
    idValue: any,
    idType: PatientIdType,
    headerType: PatientIdType,
): string => {
    if (idValue === null) {
        return "Unknown";
    }
    if (headerType === idType) {
        return `${idValue}`;
    }

    switch (idType) {
        case PatientIdType.NhsNumber:
            return `${idValue}`;
        case PatientIdType.Emis:
            return `${idValue}  (EMIS No.)`;
        case PatientIdType.SystmOne:
            return `${idValue}  (SystmOne No.)`;
        case PatientIdType.Vision:
            return `${idValue}  (Vision No.)`;
        case PatientIdType.Unknown:
        default:
            return "Unknown";
    }
};

export const statusFilterOptions = {
    Sent: DeliveryReceiptStatus.Sent,
    Delivered: DeliveryReceiptStatus.Delivered,
    Failed: DeliveryReceiptStatus.Failed,
};

export const rowTextColour = (smsStatus: DeliveryReceiptStatus) => {
    if (smsStatus === DeliveryReceiptStatus.Failed) {
        return "text-danger";
    }
    return "black";
};
