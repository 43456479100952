import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import {
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";

export const useItemAttachmentAnalytics = ({
    conversation,
}: {
    conversation: Conversation;
}) => {
    const track = useAnalytics();
    const env = useBrowserEnvironment();
    const { system: medicalRecordSystem, userMedicalRecordRole } =
        useMedicalRecordConnection();

    const accessType = env === "WebView" ? "DesktopApp" : "Browser";

    const conversationParticipant =
        getConversationParticipantForAnalytics(conversation);

    function trackConversationAttachmentPreviewLinkClick() {
        track("ConversationAttachmentPreview Link Click", {
            accessType,
            medicalRecordSystem,
            conversationParticipant,
        });
    }

    function trackConversationAttachmentRemoveButtonClick() {
        track("ConversationAttachmentRemove Button Click", {
            accessType,
            medicalRecordSystem,
            userMedicalRecordRole,
            conversationParticipant,
        });
    }

    function trackConversationAttachmentRemoveMenuItemClick(reason: string) {
        track("ConversationAttachmentRemove MenuItem Click", {
            deleteAttachmentReason: reason,
            accessType,
            medicalRecordSystem,
            userMedicalRecordRole,
            conversationParticipant,
        });
    }

    return {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    };
};
