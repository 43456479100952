const pluralRules = new Intl.PluralRules("en-GB");

type PluralizeProps = {
    count: number;
    forms: {
        singular: string;
        plural: string;
    };
};

export const pluralize = ({
    count,
    forms: { singular, plural },
}: PluralizeProps) => {
    const grammaticalNumber = pluralRules.select(count);

    switch (grammaticalNumber) {
        case "one":
            return `${count} ${singular}`;
        case "other":
            return `${count} ${plural}`;
        default:
            throw new Error("Unknown: " + grammaticalNumber);
    }
};
