import { Button, Ds, Flex, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

// Fixed width wrapper to maintain left-alignment
export const StyledMembersWrapper = styled.div`
    width: 130px;
`;

export const StyledButton = styled(Button)`
    color: ${Tokens.COLOURS.primary.blue["100"]};
`;

//  Fixed width wrapper to keep button/'joined'/'pending request' aligned
export const StyledActionSection = styled(Flex)`
    justify-content: flex-start;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        width: 180px;
        justify-content: flex-end;
    }
`;

export const StyledMembersText = styled(Text)`
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]}; // Matches padding on Button for alignment
`;

export const StyledBadge = styled(Ds.Badge)`
    max-width: max-content;
    height: max-content;
`;

export const StyledLeftHandSide = styled(Flex)`
    width: 100%;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }
`;

export const StyledList = styled.ul`
    margin-bottom: 0;
`;
