import { DateFormatOptions, DateHelpers } from "@accurx/shared";

export const getDateTimeFromISOString = (
    isoDate: string | null,
): [string | null, string | null] => {
    if (!isoDate) {
        return [null, null];
    }
    const isoDateWithoutSeconds = DateHelpers.formatDate(
        isoDate,
        DateFormatOptions.ISO_DATE_STRING_WITHOUT_SECONDS,
    );

    const dateTime = isoDateWithoutSeconds.split("T");
    return [dateTime[0], dateTime[1]];
};

export const formatDateTimeAsISO = (
    date: string | null,
    time: string | null,
): string => {
    const timezoneOffset = -1 * (new Date().getTimezoneOffset() / 60);
    const isoTimezoneOffset = `${timezoneOffset >= 0 ? "+" : ""}${timezoneOffset
        .toString(10)
        .padStart(2, "0")}:00`;

    return `${date}T${time}${isoTimezoneOffset}`;
};

export const getSuspendDays = (): string[] => {
    const numberOfDaysToAdd = 4;
    const days: string[] = [];
    for (let index = 0; index <= numberOfDaysToAdd; index++) {
        const day = new Date();
        day.setDate(day.getDate() + index);
        days.push(day.toISOString());
    }
    return days;
};

export const timeIn30MinuteIncrements = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2)
        .toString()
        .padStart(2, "0");
    const minute = i % 2 === 0 ? "00" : "30";
    return `${hour}:${minute}`;
});

export const isValidSuspendTime = (isoDate: string): boolean => {
    return DateHelpers.isBefore(new Date().toISOString(), isoDate);
};

export const formatDateAsFriendlyDate = (date: string): string => {
    return DateHelpers.formatDate(date, DateFormatOptions.DATE_LONG_SHORT_DAY);
};

export const formatDateAsFriendlyTime = (date: string): string => {
    return DateHelpers.formatTime(date, DateFormatOptions.TIME_SHORT_12_HR);
};

export const formatDate = (date: string): string => {
    // if can be parsed, render as local string, else output raw text
    if (Date.parse(date) > 0) {
        const d = new Date(date);
        return d.toLocaleString("en-GB");
    }
    return date;
};
