import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalId } from "domains/native/schemas/PatientExternalIdSchema";

export type OpenComposeWindowMutationPayload = {
    patientExternalIds: PatientExternalId[];
};

export const mutateOpenComposeWindow = createEndpoint<
    void,
    OpenComposeWindowMutationPayload
>("MutateOpenComposeWindow", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateOpenComposeWindow",
            workspaceId: context.workspaceId,
            payload: { patientExternalIdentityDto: payload },
        },
    });
});
