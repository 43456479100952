import * as UI from "@accurx/design";
import styled from "styled-components";

import { DotSeparator } from "../DotSeparator/DotSeparator";

const StyledDeliveryStatusContainer = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

const IconPositioner = styled.span`
    margin-top: -2px;
    margin-right: 2px;
`;

export const DeliveredTag = () => (
    <StyledDeliveryStatusContainer>
        <IconPositioner>
            <UI.Icon size={3} name="TickDouble" colour="stone" />
        </IconPositioner>
        <UI.VisuallyHidden>Message delivered</UI.VisuallyHidden>
    </StyledDeliveryStatusContainer>
);

export const SentTag = () => (
    <StyledDeliveryStatusContainer>
        <IconPositioner>
            <UI.Icon size={3} name="Tick" colour="stone" />
        </IconPositioner>
        <UI.VisuallyHidden>Message sent</UI.VisuallyHidden>
    </StyledDeliveryStatusContainer>
);

export const CancelledTag = () => (
    <StyledDeliveryStatusContainer>
        <IconPositioner>
            <UI.Icon size={3} name="Failed" colour="stone" />
        </IconPositioner>
        <UI.VisuallyHidden>Message cancelled</UI.VisuallyHidden>
        <span aria-hidden>Cancelled</span>
        <DotSeparator />
    </StyledDeliveryStatusContainer>
);

export const FailedTag = () => (
    <StyledDeliveryStatusContainer>
        <IconPositioner>
            <UI.Icon size={3} name="Failed" colour="red" theme="Fill" />
        </IconPositioner>
        <UI.VisuallyHidden>Message failed</UI.VisuallyHidden>
        <span aria-hidden>Failed</span>
        <DotSeparator />
    </StyledDeliveryStatusContainer>
);

export const ReadTag = () => (
    <StyledDeliveryStatusContainer>
        <IconPositioner>
            <UI.Icon size={3} name="TickDouble" colour="green" />
        </IconPositioner>
        <UI.VisuallyHidden>Message read</UI.VisuallyHidden>
        <span aria-hidden>Read</span>
        <DotSeparator />
    </StyledDeliveryStatusContainer>
);
