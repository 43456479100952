import { FormEvent, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { postUserPassword } from "api/SettingsApi";

import { FeedbackMsg } from "./Settings.types";

export const SettingsPassword = () => {
    useAccurxWebTitle("Change password");

    const [passwordCurrent, setPasswordCurrent] = useState("");
    const [passwordCurrentError, setPasswordCurrentError] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [passwordNewError, setPasswordNewError] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordConfirmError, setPasswordConfirmError] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    const validateCurrentPassword = (): void =>
        setPasswordCurrentError(
            !passwordCurrent ? "This field is required." : "",
        );

    const validateNewPassword = (): void =>
        setPasswordNewError(
            passwordNew.length < 8 || passwordNew.length > 100
                ? "The new password must be at least 8 and at max 100 characters long."
                : "",
        );

    const validateConfirmPassword = (): void =>
        setPasswordConfirmError(
            passwordConfirm !== passwordNew
                ? "The new password and confirmation password do not match."
                : "",
        );

    const handleSave = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        if (
            !passwordCurrent ||
            passwordCurrentError ||
            !passwordNew ||
            passwordNewError ||
            !passwordConfirm ||
            passwordConfirmError
        ) {
            setFeedbackMsg({
                colour: "error",
                msg: "One of the required fields is invalid.",
            });
            return;
        }

        const res = await postUserPassword({
            oldPassword: passwordCurrent,
            newPassword: passwordNew,
            confirmPassword: passwordConfirm,
        });
        if (!res.success) {
            setFeedbackMsg({
                colour: "error",
                msg:
                    res.error ||
                    "Something went wrong saving your password. Please try again.",
            });
            return;
        }
        setFeedbackMsg({
            colour: "success",
            msg: "Your password has been changed. Following security best practices, you will shortly be signed out in any other places you have Chain open and will be able to sign in using your new password.",
        });
        setPasswordCurrent("");
        setPasswordNew("");
        setPasswordConfirm("");
    };

    return (
        <Card
            header={
                <Text variant="subtitle" as="h2" skinny>
                    Change password
                </Text>
            }
        >
            <form onSubmit={handleSave}>
                <FormFieldWrapper
                    label="Current password"
                    labelProps={{ htmlFor: "password-current" }}
                    className="mb-2"
                    errors={[passwordCurrentError]}
                >
                    <Input
                        id="password-current"
                        value={passwordCurrent}
                        type="password"
                        onChange={(e): void =>
                            setPasswordCurrent(e.target.value)
                        }
                        onBlur={validateCurrentPassword}
                        autoComplete="current-password"
                        hasErrors={!!passwordCurrentError}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="New password"
                    labelProps={{ htmlFor: "password-new" }}
                    className="mb-2"
                    errors={[passwordNewError]}
                >
                    <Input
                        id="password-new"
                        value={passwordNew}
                        type="password"
                        onChange={(e): void => setPasswordNew(e.target.value)}
                        onBlur={validateNewPassword}
                        autoComplete="new-password"
                        hasErrors={!!passwordNewError}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="Confirm new password"
                    labelProps={{ htmlFor: "password-confirm" }}
                    className="mb-3"
                    errors={[passwordConfirmError]}
                >
                    <Input
                        id="password-confirm"
                        value={passwordConfirm}
                        type="password"
                        onChange={(e): void =>
                            setPasswordConfirm(e.target.value)
                        }
                        onBlur={validateConfirmPassword}
                        autoComplete="new-password"
                        hasErrors={!!passwordConfirmError}
                    />
                </FormFieldWrapper>
                <Text skinny>
                    To keep your account secure use a{" "}
                    <a
                        href="https://www.ncsc.gov.uk/cyberaware/home"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        hard to guess password
                    </a>
                    , e.g. two random words and a number.
                </Text>
                <Text>
                    Your password must be at least 8 characters long. It should
                    also include a number, lower and upper case letters.
                </Text>
                {feedbackMsg.colour && feedbackMsg.msg && (
                    <Feedback
                        title={feedbackMsg.msg}
                        colour={feedbackMsg.colour}
                        props={{ className: "mb-3" }}
                    />
                )}
                <Button type="submit" text="Update password" />
            </form>
        </Card>
    );
};
