import { useState } from "react";

import { useCancelScheduledMessageMutation } from "@accurx/concierge/hooks/mutations/useCancelScheduledMessageMutation";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { Log } from "@accurx/shared";
import { format, isSameYear } from "date-fns";
import { toast } from "react-toastify";

import { DeleteConfirmModal } from "../DeleteConfirmModal/DeleteConfirmModal";
import {
    StyledCancelButton,
    StyledContainer,
} from "./ScheduledMessageFeedback.styles";

const formatDateTime = (deliveryScheduledAt: string): string => {
    try {
        const scheduledAt = new Date(deliveryScheduledAt);
        const dateFormat = isSameYear(scheduledAt, new Date())
            ? "h:mmaaa eee dd MMM"
            : "h:mmaaa eee dd MMM yyyy";
        return format(scheduledAt, dateFormat);
    } catch (e) {
        Log.warn("Invalid deliveryScheduledAt value");
        return "";
    }
};

type ScheduledMessageFeedbackProps = {
    deliveryScheduledAt: string;
    messageId: string;
    conversationId: string;
};

export const ScheduledMessageFeedback = ({
    deliveryScheduledAt,
    messageId,
    conversationId,
}: ScheduledMessageFeedbackProps) => {
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
        useState(false);

    const { mutate: cancelScheduledMessage, isLoading } =
        useCancelScheduledMessageMutation({
            onError: () => {
                toast(
                    <UI.Feedback
                        colour="error"
                        title="Sorry, we were unable to cancel sending the message. Please try again."
                    />,
                );
            },
        });

    const openDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(true);
    const closeDeleteConfirmModal = () => setIsDeleteConfirmModalOpen(false);

    return (
        <>
            <StyledContainer>
                <UI.Text
                    variant="note"
                    colour="metal"
                    skinny
                >{`Scheduled to send at ${formatDateTime(
                    deliveryScheduledAt,
                )}`}</UI.Text>
                <StyledCancelButton
                    radius="regular"
                    onClick={openDeleteConfirmModal}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <Pill.Icon isLoading={isLoading} />
                    ) : (
                        <UI.Text skinny variant="note" colour="red" as="span">
                            Cancel
                        </UI.Text>
                    )}
                </StyledCancelButton>
            </StyledContainer>
            <DeleteConfirmModal
                title="Are you sure you want to cancel sending this
                            message?"
                isOpen={isDeleteConfirmModalOpen}
                onClose={closeDeleteConfirmModal}
                onConfirm={() => {
                    cancelScheduledMessage({
                        messageId,
                        conversationId,
                    });
                    closeDeleteConfirmModal();
                }}
            />
        </>
    );
};
