import { useRouteMatch } from "react-router";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { useCurrentUser } from "./useCurrentUser";

/**
 * Get user organisation
 *
 * @returns a workspace if found or throws an error otherwise
 */
export const useCurrentWorkspace = () => {
    const params = useRouteMatch<{ workspaceId?: string; orgId?: string }>({
        path: ["*/w/:workspaceId", "*/organisations/:orgId"],
        strict: false,
        sensitive: false,
    })?.params;

    const { selectedOrgId } = useSelectedOrganisation();

    const workspaceIdFromUrl = params?.workspaceId
        ? parseInt(params.workspaceId, 10)
        : null;

    const orgIdFromUrl = params?.orgId ? parseInt(params.orgId, 10) : null;

    const workspaceId =
        workspaceIdFromUrl ?? orgIdFromUrl ?? selectedOrgId ?? null;

    const { user } = useCurrentUser();

    const workspace = workspaceId
        ? // FIXME
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          user.organisations?.find((o) => o.orgId === workspaceId)
        : null;

    if (!workspace) {
        throw new Error("No current workspace, but there should be");
    }
    return workspace;
};
