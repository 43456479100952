import React from "react";

import {
    Icon,
    SelectItem,
    SingleSelect,
    Text,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    VisuallyHidden,
} from "@accurx/design";

import { trackPatientTriageDashboardFilterByRequestButtonClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { RequestSource, RequestType } from "../shared.constants";
import {
    StyledContainer,
    StyledFilterContainer,
    StyledFilterWrapper,
    StyledSourceFilterWrapper,
    StyledSubtitleWrapper,
    StyledTitleWrapper,
    StyledTooltipContentWrapper,
    StyledTooltipInnerWrapper,
    StyledTooltipWrapper,
} from "./Header.styles";
import { DashboardCardFilter } from "./Header.types";

export type DashboardCardHeaderProps = {
    cardTitle: string;
    cardSubTitle: string;
    cardTooltip: string;
    filter: DashboardCardFilter;
};

export const DashboardCardHeader = ({
    cardTitle,
    cardSubTitle,
    cardTooltip,
    filter,
}: DashboardCardHeaderProps): JSX.Element => {
    const [isRequestTypeOpen, setIsRequestTypeOpen] = React.useState(false);
    const [isRequestSourceOpen, setIsRequestSourceOpen] = React.useState(false);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleRequestTypeOpenChange = (isOpen: boolean) => {
        setIsRequestTypeOpen(isOpen);
        if (isOpen) {
            trackPatientTriageDashboardFilterByRequestButtonClick({
                ...analyticsLoggedInProps,
                sectionName: cardTitle,
                dashboardName: "Patient Triage Dashboard",
                filterName: "Filter by Request Type",
            });
        }
    };

    const handleRequestSourceOpenChange = (isOpen: boolean) => {
        setIsRequestSourceOpen(isOpen);
        if (isOpen) {
            trackPatientTriageDashboardFilterByRequestButtonClick({
                ...analyticsLoggedInProps,
                sectionName: cardTitle,
                dashboardName: "Patient Triage Dashboard",
                filterName: "Filter by Request Source",
            });
        }
    };
    return (
        <StyledContainer>
            <Text as="span" skinny className="d-flex align-items-center">
                <StyledTitleWrapper>
                    <Text as="span" variant="subtitle" skinny>
                        {`${cardTitle} · `}&nbsp;
                    </Text>
                    <StyledSubtitleWrapper>
                        <Text as="span" skinny>
                            {cardSubTitle}
                        </Text>
                        <StyledTooltipWrapper>
                            <StyledTooltipInnerWrapper>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Icon
                                            id="Info"
                                            size={3}
                                            name="Info"
                                            colour="stone"
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <StyledTooltipContentWrapper>
                                            <Text
                                                colour={"night"}
                                                as="label"
                                                skinny
                                            >
                                                {cardTooltip}
                                            </Text>
                                        </StyledTooltipContentWrapper>
                                    </TooltipContent>
                                </Tooltip>
                            </StyledTooltipInnerWrapper>
                        </StyledTooltipWrapper>
                    </StyledSubtitleWrapper>
                </StyledTitleWrapper>
            </Text>
            <StyledFilterContainer
                flexWrap={{ xs: "wrap", md: "nowrap" }}
                gap={{ xs: "1", md: "2" }}
                justifyContent="flex-end"
            >
                {filter.requestSourceFilterId &&
                    filter.onRequestSourceFilterSelect && (
                        <StyledSourceFilterWrapper>
                            <VisuallyHidden
                                as="label"
                                htmlFor={filter.requestSourceFilterId}
                            >
                                Filter requests by source
                            </VisuallyHidden>
                            <SingleSelect
                                id={filter.requestSourceFilterId}
                                dimension="medium"
                                defaultValue={
                                    RequestSource.PatientsAndReception
                                }
                                open={isRequestSourceOpen}
                                onOpenChange={handleRequestSourceOpenChange}
                                onValueChange={
                                    filter.onRequestSourceFilterSelect
                                }
                            >
                                <SelectItem
                                    value={RequestSource.PatientsAndReception}
                                >
                                    Submitted by patients and reception
                                </SelectItem>
                                <SelectItem value={RequestSource.Patients}>
                                    Submitted by patients only
                                </SelectItem>
                                <SelectItem value={RequestSource.Reception}>
                                    Submitted by reception only
                                </SelectItem>
                            </SingleSelect>
                        </StyledSourceFilterWrapper>
                    )}
                <StyledFilterWrapper>
                    <VisuallyHidden
                        as="label"
                        htmlFor={filter.requestTypeFilterId}
                    >
                        Filter requests by type
                    </VisuallyHidden>
                    <SingleSelect
                        id={filter.requestTypeFilterId}
                        dimension="medium"
                        defaultValue={RequestType.MedicalAndAdmin}
                        open={isRequestTypeOpen}
                        onOpenChange={handleRequestTypeOpenChange}
                        onValueChange={filter.onRequestTypeFilterSelect}
                    >
                        <SelectItem value={RequestType.MedicalAndAdmin}>
                            Medical and Admin
                        </SelectItem>
                        <SelectItem value={RequestType.Medical}>
                            Medical only
                        </SelectItem>
                        <SelectItem value={RequestType.Admin}>
                            Admin only
                        </SelectItem>
                    </SingleSelect>
                </StyledFilterWrapper>
            </StyledFilterContainer>
        </StyledContainer>
    );
};
