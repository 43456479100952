import {
    IWrappedResult,
    JsonResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import isNil from "lodash/isNil";

import {
    Complexity,
    FloreyScoreResponse,
    FloreyScoreStatus,
    FloreyScoresSummaryResponse,
    GetFilteredTicketViewRequest,
    PatientNoteTags,
    PatientThreadAssignTicket,
    PatientThreadAssignee,
    PatientThreadFilteredTicketView,
    PatientThreadFolderTicketView,
    PatientThreadInitialUnreadItems,
    PatientThreadMatchTicketWithToken,
    PatientThreadSummaryDetails,
    PatientThreadTicket,
    PatientThreadTicketCommandResult,
    PatientThreadTicketFolder,
    PatientThreadTicketOrdering,
    PatientTicketMarkDone,
    PatientTicketReOpenTicket,
    TicketIdentity,
} from "./types/dto.types";

const ENDPOINTS = {
    downloadFloreyPdf:
        "conversation/web/downloadfloreypdf/:organisationId/:floreyResponseId",
    folderTicketView: "/api/conversation/web/folderticketview",
    filteredTicketView: "/api/conversation/web/filteredticketview",
    initialSummaryDetails: "/api/conversation/web/initialsummarydetails",
    initialUnreadItems: "/api/conversation/web/initialunreaditems",
    ticket: "/api/conversation/web/ticket",
    ticketWithToken: "api/conversation/web/ticketwithtoken",
    markNoteRead: "/api/conversation/web/marknoteread",
    markTicketDone: "/api/conversation/web/markticketdone",
    reopenTicket: "/api/conversation/web/reopenticket",
    markTicketUrgent: "/api/conversation/web/markticketurgent",
    markTicketNonUrgent: "/api/conversation/web/markticketnonurgent",
    changeAssignee: "/api/conversation/web/changeassignee",
    matchTicketToPatientWithToken:
        "/api/conversation/web/matchtickettopatientwithtoken",
    floreyResponseScoreSummaries: "/api/conversation/floreyscores",
    floreyResponseScore: "/api/conversation/floreyscores/:floreyEnrolmentId",
    addFloreyResponseScore: "/api/conversation/floreyscores/addscorevalidation",
} as const;

/**
 * Returns a page of ticket highlights corresponding to the folder
 * details as supplied in the request body, for example the user
 * inbox or all unassigned patient triage messages.
 */
export async function fetchFolderView(
    workspaceId: number,
    folder: PatientThreadTicketFolder,
    continuationToken?: string,
): Promise<PatientThreadFolderTicketView> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.folderTicketView,
        }),
        {
            organisationId: workspaceId,
            folder,
            continuationToken: continuationToken,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to fetch folder view for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns a page of ticket highlights corresponding to the filter
 * details as supplied in the request body, for example all open
 * tickets currently assigned to Joe Bloggs.
 */
export async function fetchMatchingTickets({
    workspaceId,
    assignee,
    isDone,
    ordering,
    continuationToken,
}: {
    workspaceId: number;
    assignee?: Optional<PatientThreadAssignee>;
    isDone?: Optional<boolean>;
    ordering: PatientThreadTicketOrdering;
    continuationToken?: string;
}): Promise<PatientThreadFilteredTicketView> {
    const request: GetFilteredTicketViewRequest = {
        organisationId: workspaceId,
        assignee,
        isDone,
        continuationToken,
        ordering,
    };
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.filteredTicketView,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to fetch folder view for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns a ticket with all history of items contained within it.
 */
export async function fetchTicket(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
): Promise<PatientThreadTicket> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.ticket,
        }),
        {
            organisationId: workspaceId,
            ticketIdentity: ticketIdentity,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `Failed to fetch conversation for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Uses a patient token to fetch a ticket
 */
export async function fetchTicketWithToken(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    patientToken: string,
): Promise<PatientThreadTicket> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.ticketWithToken,
        }),
        {
            organisationId: workspaceId,
            ticketIdentity: ticketIdentity,
            patientToken: patientToken,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `Failed to fetch conversation with patient token for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

export const markNoteRead = async (
    request: PatientNoteTags,
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteRead,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `Failed to mark note as read, with note ID ${request.patientThreadItemIds}`,
        );
    }

    return response.result;
};

/**
 * Returns users and teams that will be used to construct the navigation
 * to see tickets assigned to teams and colleagues
 */
export async function fetchInitialSummaryDetails(
    workspaceId: number,
): Promise<PatientThreadSummaryDetails> {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.initialSummaryDetails,
            query: `?organisationId=${workspaceId}`,
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to load initial summary for workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Returns users and teams that will be used to construct the navigation
 * to see tickets assigned to teams and colleagues
 */
export async function fetchInitialUnreadItems(
    workspaceId: number,
): Promise<PatientThreadInitialUnreadItems> {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.initialUnreadItems,
            query: `?organisationId=${workspaceId}`,
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to load initial unread items for workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Used specific to check valid PatientThreadTicketCommandResult response
 * @param response - response from httpClient
 * */
export const isValidResponse = (response: JsonResult): boolean => {
    return (
        response.success &&
        !isNil(response.result) &&
        !isNil(response.result.isSuccess)
    );
};

/**
 * Mark a ticket as Done.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketDone(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketDone,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Error while attempting to mark as done ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return resp.result;
}

/**
 * Mark a ticket as Urgent.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketUrgent(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketUrgent,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Error while attempting to mark as urgent ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return resp.result;
}

/**
 * Mark a ticket as non-urgent.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function markTicketNonUrgent(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketMarkDone = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markTicketNonUrgent,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Error while attempting to mark as non-urgent ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return resp.result;
}

/**
 * Re-open a ticket previously marked as Done.
 *
 * Returns the result of the action, including whether it was successful and any relevant changes
 * to the ticket, equivalent to the set of updates that all clients would be pushed.
 *
 * If the action was not possible due to a conflict, success will be false and the set of changes
 * will represent the full current state of the ticket so that the client may repair itself.
 */
export async function reOpenTicket(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientTicketReOpenTicket = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.reopenTicket,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Error while attempting to reopen ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return resp.result;
}

export async function assign(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    latestToken: string,
    assignee: PatientThreadAssignee,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientThreadAssignTicket = {
        organisationId: workspaceId,
        ticketIdentity,
        latestToken,
        assignee,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.changeAssignee,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Error while attempting to assign ticket (${ticketIdentity.type}, ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }

    return resp.result;
}

export async function matchTicketToPatientWithToken(
    workspaceId: number,
    patientToken: string,
    ticketIdentity: TicketIdentity,
    conversationToken: string,
): Promise<PatientThreadTicketCommandResult> {
    const req: PatientThreadMatchTicketWithToken = {
        patientToken: patientToken,
        latestToken: conversationToken,
        organisationId: workspaceId,
        ticketIdentity: ticketIdentity,
    };
    const resp = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.matchTicketToPatientWithToken,
        }),
        req,
    );

    if (!isValidResponse(resp)) {
        throw new Error(
            resp.error ||
                `Failed to confirm patient match for ticketIdentitytype (${ticketIdentity.type}, ticketIdentityid ${ticketIdentity.id}) in workspace ${workspaceId}`,
        );
    }
    return resp.result;
}

export const getFloreyResponsePDFDownloadUrl = (
    organisationId: number,
    floreyResponseId: string,
): string => {
    return getApiEndpoint({
        path: ENDPOINTS.downloadFloreyPdf,
        params: { organisationId: `${organisationId}`, floreyResponseId },
    }).urlFinal;
};

/**
 * GET request for fetching a questionnaire response, a status to determine
 * whether the response is low/not low complexity, and any clinician validations.
 *
 * This is part of the GSTT pilot.
 */
export const getFloreyScoreResponse = async (
    workspaceId: string,
    floreyEnrolmentId: number,
): Promise<IWrappedResult<FloreyScoreResponse>> => {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.floreyResponseScore,
            params: { floreyEnrolmentId: floreyEnrolmentId.toString() },
            query: `?organisationId=${workspaceId}`,
        }),
    );

    return response;
};

/**
 * POST request for submitted a clinician validation to a questionnaire response.
 * Returns a full, updated FloreyScoreResponse.
 *
 * This is part of the GSTT pilot.
 */
export const postFloreyScoreResponse = async ({
    workspaceId,
    floreyEnrolmentId,
    score,
    additionalInformation,
}: {
    workspaceId: string;
    floreyEnrolmentId: number;
    score: string;
    additionalInformation?: string;
}): Promise<IWrappedResult<FloreyScoreResponse>> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.addFloreyResponseScore,
        }),
        {
            organisationId: workspaceId,
            floreyEnrolmentId,
            score,
            additionalInformation,
        },
    );

    return response;
};

/**
 * GET request for fetching the list of patient responses for a given questionnaire
 * + a status to determine whether the response is low or not low complexity.
 *
 * This is part of the GSTT pilot.
 */
export const getFloreyResponseScoreSummaries = async ({
    organisationId,
    conditionId,
    filters,
}: {
    organisationId: string;
    /** The id of the Florey which we want to get the summaries of responses for */
    conditionId: string;
    filters: {
        /** ISO string format */
        dateStart?: string;
        /** ISO string format */
        dateEnd?: string;
        /** ISO string format */
        answeredAtStart?: string;
        /** ISO string format */
        answeredAtEnd?: string;
        status: FloreyScoreStatus[];
        complexity: Complexity[];
    };
}): Promise<IWrappedResult<FloreyScoresSummaryResponse>> => {
    const queryParams = new URLSearchParams();
    queryParams.append("organisationId", organisationId);
    queryParams.append("conditionId", conditionId);
    if (filters.dateStart) {
        queryParams.append("dateStart", filters.dateStart);
    }
    if (filters.dateEnd) {
        queryParams.append("dateEnd", filters.dateEnd);
    }
    if (filters.answeredAtStart) {
        queryParams.append("answeredAtStart", filters.answeredAtStart);
    }
    if (filters.answeredAtEnd) {
        queryParams.append("answeredAtEnd", filters.answeredAtEnd);
    }
    filters.status.forEach((status) => {
        queryParams.append("status", status);
    });
    filters.complexity.forEach((complexity) => {
        queryParams.append("complexity", complexity);
    });
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.floreyResponseScoreSummaries,
            query: `?${queryParams.toString()}`,
        }),
    );

    if (!!response.result) {
        return {
            success: true,
            result: response.result,
            error: null,
        };
    } else {
        return {
            success: false,
            result: null,
            error: response.error,
        };
    }
};
