import { Feedback } from "@accurx/design";
import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { cancelDelayedMessages, getDelayedMessages } from "api/PracticesApi";
import { CoreActions } from "shared/constants/actionTypes";

const getAllData = async (
    practiceId: string,
    dispatch: Dispatch,
): Promise<undefined> => {
    const res = await getDelayedMessages(practiceId);
    if (res.success && res.result) {
        dispatch({
            type: CoreActions.LOAD_DELAYED_MESSAGES_SUCCESS,
            messages: res.result,
        });
        return;
    }
    dispatch({ type: CoreActions.LOAD_DELAYED_MESSAGES_FAIL });
};

export const getIntialData = (practiceId: string) => (dispatch: Dispatch) => {
    dispatch({ type: CoreActions.LOAD_DELAYED_MESSAGES });
    getAllData(practiceId, dispatch);
};

export const cancelMessage = async (
    practiceId: string,
    messageId: string,
    dispatch: Dispatch,
): Promise<undefined> => {
    const res = await cancelDelayedMessages(practiceId, {
        messageId,
        isCancelled: true,
    });
    if (res.success) {
        getAllData(practiceId, dispatch);
        return;
    }
    toast(
        Feedback({
            colour: "error",
            content: "Could not cancel this messages. Please try again.",
        }),
    );
};
