import { useEffect, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import { usePatientUnreadCount } from "@accurx/concierge/hooks/data/usePatientUnreadCount";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    PatientDemographics,
    useBrowserEnvironment,
    useChangeMedicalRecordPatientMutation,
    useMedicalRecordConnection,
    useNativeTrackingFields,
    useOpenComposeWindowMutation,
} from "@accurx/native";
import { useGenerateTriageReceptionFlowTokenMutation } from "domains/inbox/hooks/mutations/useGenerateTriageReceptionFlowTokenMutation";
import { useCurrentPatientQuery } from "domains/inbox/hooks/queries";
import { usePatientIdentityQuery } from "domains/inbox/hooks/queries/usePatientIdentityQuery";
import { usePatientTriageUrl } from "domains/inbox/hooks/usePatientTriageUrl";
import { useInboxLink } from "domains/inbox/hooks/util";
import { InboxLocationState } from "domains/inbox/hooks/util/useInboxLink";
import { ROUTES_INBOX } from "domains/inbox/routes";
import { formatPatientDisplayInfo } from "domains/inbox/util/format";
import noop from "lodash/noop";
import { createPortal } from "react-dom";
import { matchPath, useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";

import { PATIENT_SEARCH_PANEL_ROOT_ID } from "../Layout/InboxLayout";
import {
    PATIENT_SEARCH_PANEL_ID,
    PatientSearchPanel,
} from "../PatientSearch/components/PatientSearchPanel";
import { SkeletonLoader } from "../SkeletonLoader/SkeletonLoader";
import {
    StyledLoadingContainer,
    StyledOuterCardActionButton,
} from "./CurrentPatient.styles";
import {
    CurrentPatientDisconnected,
    CurrentPatientWithPatient,
} from "./components";
import { isSamePatient, useGetCurrentPatient } from "./useGetCurrentPatient";

const ANALYTICS_APP_ORIGIN = "PatientCard" as const;

type CurrentPatientProps = {
    onClick?: () => void;
};

export const CurrentPatient = ({
    onClick = () => noop,
}: CurrentPatientProps) => {
    const history = useHistory();

    const isReceptionFlowEnabled = useFeatureFlag("PatientTriageReceptionFlow");

    const medicalRecordPatientQuery = useCurrentPatientQuery();
    const { accessType, medicalRecordSystem } = useNativeTrackingFields();

    // Get patient triage URL
    const patientTriageUrl = usePatientTriageUrl();

    const medicalRecordConnection = useMedicalRecordConnection();

    const track = useAnalytics();

    const env = useBrowserEnvironment();

    const changeMedicalRecordPatientMutation =
        useChangeMedicalRecordPatientMutation();
    const createOpenComposeWindowMutation = useOpenComposeWindowMutation({
        onError: () => {
            toast(
                <UI.Feedback
                    colour="warning"
                    title="Sorry, something went wrong. Please try again."
                />,
            );
        },
        onSettled: (_data, error) => {
            track("MessageCompose Button Response", {
                accessType,
                medicalRecordSystem,
                hasError: !!error,
                appOrigin: ANALYTICS_APP_ORIGIN,
            });
        },
    });
    const generateTriageReceptionFlowTokenMutation =
        useGenerateTriageReceptionFlowTokenMutation({
            onSuccess: (data) => {
                if (patientTriageUrl) {
                    window.open(
                        `${patientTriageUrl}?token=${data.token}`,
                        "_blank",
                    );
                }
            },
            onError: () => {
                toast(
                    <UI.Feedback
                        colour="error"
                        title="Sorry, something went wrong. Please try again."
                    />,
                );
            },
            onSettled: (_data, error) => {
                track("ReceptionFlowStart Button Response", {
                    accessType,
                    medicalRecordSystem,
                    hasError: !!error,
                    appOrigin: ANALYTICS_APP_ORIGIN,
                });
            },
        });

    const link = useInboxLink();

    const { pathname, state: locationState = {} } =
        useLocation<InboxLocationState>();

    const [isPatientSearchPanelOpen, setIsPatientSearchPanelOpen] =
        useState(false);
    const autoFocusInputRef = useRef<HTMLInputElement | null>(null);
    const patientSearchButtonRef = useRef<HTMLButtonElement>(null);
    const closePatientSearchPanelRef = useRef(setIsPatientSearchPanelOpen);

    const { type: currentPatientType, patient: currentPatient } =
        useGetCurrentPatient({
            isOpeningInSystem: changeMedicalRecordPatientMutation.isLoading,
            medicalRecordPatient: medicalRecordPatientQuery.data ?? undefined,
        });

    const isActive =
        matchPath(pathname, ROUTES_INBOX.Patient) !== null &&
        isSamePatient(locationState.patient, currentPatient ?? undefined);

    const { patientExternalIds } =
        currentPatient?.patientExternalIdentityDto ?? {};

    const { data: patientIdentity } = usePatientIdentityQuery(
        patientExternalIds ?? [],
        !!patientExternalIds,
    );
    const unreadCount = usePatientUnreadCount(patientIdentity?.accuRxId);

    /** Close the search panel if we are navigating away
     * This component would never unmount while the inbox is open
     * so we need a way to have the search panel behave similarly to
     * quickview, where it closes on location changes
     */
    useEffect(() => {
        closePatientSearchPanelRef.current(false);
    }, [pathname]);

    useEffect(() => {
        // Focus on the panel on open
        if (isPatientSearchPanelOpen) {
            // Focus on the input if we need to focus on an input within the panel
            if (autoFocusInputRef.current) {
                autoFocusInputRef.current.focus();
                // Otherwise focus on the whole panel
            } else {
                document.getElementById(PATIENT_SEARCH_PANEL_ID)?.focus();
            }
        }
    }, [isPatientSearchPanelOpen]);

    if (medicalRecordPatientQuery.status === "loading") {
        return (
            <StyledLoadingContainer>
                <SkeletonLoader
                    width="33%"
                    backgroundColor="rgba(255, 255, 255, 0.1)"
                    shimmerRGBColor={[255, 255, 255]}
                />
                <SkeletonLoader
                    width="69%"
                    backgroundColor="rgba(255, 255, 255, 0.1)"
                    shimmerRGBColor={[255, 255, 255]}
                />
            </StyledLoadingContainer>
        );
    }

    // TODO: in stage 2, show <CurrentPatientDisconnected /> when disconnected from the medical record

    const openComposeScreen = () => {
        if (!currentPatient) return;

        track("MessageCompose Button Click", {
            appOrigin: ANALYTICS_APP_ORIGIN,
            medicalRecordSystem,
            accessType,
        });

        createOpenComposeWindowMutation.mutate({
            patientExternalIds:
                currentPatient.patientExternalIdentityDto.patientExternalIds,
        });
    };

    const closeSearchPanel = () => {
        setIsPatientSearchPanelOpen(false);

        autoFocusInputRef.current = null;
        // Return focus to the patient search button
        patientSearchButtonRef.current?.focus();
    };

    const onPatientCardClick = () => {
        track("PatientCard Button Click", {
            accessType: env === "WebView" ? "DesktopApp" : "Browser",
            medicalRecordSystem: medicalRecordConnection.system,
            unreadCount,
        });
        onClick();
        // Ensure panel closes if user clicks on the card after opening the panel
        if (isPatientSearchPanelOpen) closeSearchPanel();
    };

    const handleOpenPatientInSystem = (patient: PatientDemographics) => {
        changeMedicalRecordPatientMutation.mutate(
            {
                patientExternalIds:
                    patient.patientExternalIdentityDto.patientExternalIds,
            },
            {
                onError: () => {
                    toast(
                        <UI.Feedback
                            title="We had an issue loading this patient in your medical record"
                            colour="error"
                        />,
                    );
                },
            },
        );
    };

    const shouldShowTriageButton = !!patientTriageUrl && isReceptionFlowEnabled;

    const patientSearchPanelRoot = document.getElementById(
        PATIENT_SEARCH_PANEL_ROOT_ID,
    );

    const medicalRecordSystemDisplay =
        medicalRecordConnection.system === "Unknown" ||
        !medicalRecordConnection.system
            ? "system"
            : medicalRecordConnection.system.toUpperCase();

    const shouldShowFullPatientSearchButton =
        !currentPatient && medicalRecordConnection.status === "Connected";

    return (
        <>
            <UI.Flex flexDirection="column" gap="1">
                <UI.Flex gap="1.5">
                    <StyledOuterCardActionButton
                        ref={patientSearchButtonRef}
                        onClick={() => {
                            track("PatientCardSearchSelect Button Click", {
                                accessType,
                                medicalRecordSystem,
                                medicalRecordSystemStatus:
                                    medicalRecordConnection.status ?? "None",
                            });

                            setIsPatientSearchPanelOpen(true);
                        }}
                        aria-label="Search for a patient"
                        $stretch={shouldShowFullPatientSearchButton}
                    >
                        <UI.Icon
                            name="SearchPerson"
                            colour="white"
                            size={3}
                            theme="Fill"
                        />
                        {shouldShowFullPatientSearchButton && (
                            <UI.Text skinny variant="note" as="span">
                                Search patient
                            </UI.Text>
                        )}
                    </StyledOuterCardActionButton>
                </UI.Flex>

                {medicalRecordConnection.status === "Connected" &&
                    !!currentPatient && (
                        <CurrentPatientWithPatient
                            onClick={onPatientCardClick}
                            isActive={isActive}
                            to={link.to("Patient", undefined, {
                                patient: currentPatient,
                            })}
                            isTriageButtonLoading={
                                generateTriageReceptionFlowTokenMutation.isLoading
                            }
                            isComposeButtonLoading={
                                createOpenComposeWindowMutation.isLoading
                            }
                            onComposeButtonClick={openComposeScreen}
                            onTriageButtonClick={() => {
                                track("ReceptionFlowStart Button Click", {
                                    accessType,
                                    medicalRecordSystem,
                                    appOrigin: ANALYTICS_APP_ORIGIN,
                                });
                                generateTriageReceptionFlowTokenMutation.mutate(
                                    {
                                        patient: currentPatient,
                                    },
                                );
                            }}
                            shouldShowTriageButton={shouldShowTriageButton}
                            displayName={formatPatientDisplayInfo(
                                currentPatient,
                            )}
                            patientCardAriaLabel={
                                currentPatientType === "openingInSystem"
                                    ? `Opening in ${medicalRecordSystemDisplay}...`
                                    : currentPatientType === "searched"
                                    ? "Searched patient"
                                    : `${medicalRecordSystemDisplay} patient`
                            }
                            unreadCount={unreadCount}
                        >
                            <UI.Text skinny variant="label" colour={"white"}>
                                {currentPatientType === "searched" &&
                                    "Searched patient"}
                                {currentPatientType === "system" &&
                                    `${medicalRecordSystemDisplay} patient`}
                                {currentPatientType === "openingInSystem" && (
                                    <UI.Flex gap="0.25">
                                        <Pill.Icon
                                            isLoading
                                            spinnerColour="white"
                                        />{" "}
                                        Opening in {medicalRecordSystemDisplay}
                                        ...
                                    </UI.Flex>
                                )}
                            </UI.Text>
                        </CurrentPatientWithPatient>
                    )}
                {medicalRecordConnection.status === "Disconnected" && (
                    <CurrentPatientDisconnected />
                )}
            </UI.Flex>
            {patientSearchPanelRoot &&
                createPortal(
                    isPatientSearchPanelOpen ? (
                        <PatientSearchPanel
                            onClose={closeSearchPanel}
                            onSearchConfirm={(
                                searchResult,
                                shouldOpenInSystem,
                            ) => {
                                const linkTo = link.to("Patient", undefined, {
                                    patient: searchResult,
                                });
                                history.push(
                                    `${linkTo.pathname}${linkTo.search ?? ""}`,
                                    linkTo.state,
                                );

                                if (shouldOpenInSystem) {
                                    handleOpenPatientInSystem(searchResult);
                                }

                                onClick();
                                closeSearchPanel();
                            }}
                            medicalRecordSystemDisplay={
                                medicalRecordSystemDisplay
                            }
                            suggestedMedicalRecordPatient={
                                currentPatientType === "searched"
                                    ? medicalRecordPatientQuery.data ?? null
                                    : null
                            }
                            onSelectMedicalRecordPatient={(
                                patient: PatientDemographics,
                            ) => {
                                const linkTo = link.to("Patient", undefined, {
                                    patient,
                                });
                                history.push(
                                    `${linkTo.pathname}${linkTo.search ?? ""}`,
                                    linkTo.state,
                                );

                                onClick();
                                closeSearchPanel();
                            }}
                            autoFocusInputRef={autoFocusInputRef}
                        />
                    ) : null,
                    patientSearchPanelRoot,
                )}
        </>
    );
};
