import React from "react";

import { Icon, Text, Tokens } from "@accurx/design";
import { IconNameVariants } from "@accurx/design/dist/styles/types";

// Pretend-button in case user may not use a feature because it is not available for patient (i.e. accuMail) or because they are deceased
export type UnusableButtonInDropdownProps = {
    text: string;
    iconName: IconNameVariants;
    errorText?: string | undefined;
};

export const UnusableButtonInDropdown = ({
    text,
    iconName,
    errorText,
}: UnusableButtonInDropdownProps): JSX.Element => {
    return (
        <div
            className="d-flex align-items-center"
            style={{ cursor: "not-allowed", padding: "12px" }}
        >
            <div>
                <Icon name={iconName} size={3} colour="stone" />
            </div>
            <div style={{ marginLeft: 4 }}>
                <Text
                    variant="label"
                    props={{
                        style: { color: Tokens.COLOURS.greyscale.stone },
                        className: "text-nowrap",
                    }}
                    skinny
                >
                    {text}
                </Text>
                <Text variant="note" skinny>
                    {errorText}
                </Text>
            </div>
        </div>
    );
};
