import { FeatureName } from "@accurx/auth";
import { shallowEqual } from "react-redux";
import { Switch, useLocation, useParams } from "react-router-dom";

import { AppRoute, SimpleRoute } from "app/AppRoute";
import { SwitchingOrgPage } from "app/practices/questionnaires/switchingOrg/SwitchingOrgPage";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { ImportToOrgPage } from "../questionnaires/importToOrg/ImportToOrgPage";
import { SharingPage } from "../questionnaires/sharing/SharingPage";
import { useHasAcceptedFloreyBuilderUsePolicy } from "./FloreyBuilder.hooks";
import { FloreyBuilderAUP } from "./FloreyBuilderAcceptableUsePolicy/FloreyBuilderAUP";
import { FloreyBuilderAssignAndSave } from "./FloreyBuilderAssignAndSave/FloreyBuilderAssignAndSave";
import { FloreyBuilderCreateConfirmationMessage } from "./FloreyBuilderCreateConfirmationMessage/FloreyBuilderCreateConfirmationMessage";
import { FloreyBuilderCreateInviteMessage } from "./FloreyBuilderCreateInviteMessage/FloreyBuilderCreateInviteMessage";
import { FloreyBuilderHome } from "./FloreyBuilderHome/FloreyBuilderHome";
import { FloreyBuilderExportAsJson } from "./FloreyBuilderImportExport/FloreyBuilderExportAsJson";
import { FloreyBuilderImportAsJson } from "./FloreyBuilderImportExport/FloreyBuilderImportAsJson";
import { FloreyBuilderNameAndQuestions } from "./FloreyBuilderNameAndQuestions/FloreyBuilderNameAndQuestions";
import { PATHS } from "./constants";
import { routeAcceptableUsePolicy, routeHome } from "./constants/paths";

export const FloreyBuilderContent = (): JSX.Element => {
    const location = useLocation();
    const { orgId } = useParams<{ orgId: string }>();
    const NEXT_URL_QUERY_PARAM = "?next";
    const accountState = useAppSelector(({ account }) => account, shallowEqual);
    const org = OrganisationHelper.getOrganisation(accountState);
    const hasFeatureEnabled = (feature: FeatureName): boolean =>
        OrganisationHelper.isFeatureEnabled(org, feature);

    const encodeQueryParams = (): string =>
        `${NEXT_URL_QUERY_PARAM}=${encodeURIComponent(
            location.pathname + location.search,
        )}`;
    const hasAcceptedFloreyBuilderUsePolicy =
        useHasAcceptedFloreyBuilderUsePolicy();

    const isFloreySharingEnabled = hasFeatureEnabled(FeatureName.FloreyBuilder);
    const isCreateGlobalFloreysEnabled = hasFeatureEnabled(
        FeatureName.CreateGlobalCustomFloreys,
    );

    return (
        <>
            <main>
                <Switch>
                    <SimpleRoute
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.acceptableUse
                        }
                    >
                        <FloreyBuilderAUP />
                    </SimpleRoute>
                    <AppRoute
                        exact
                        path={ROUTES_CHAIN.practicesFloreyBuilderBase}
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderHome />
                    </AppRoute>
                    <AppRoute
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.nameAndQuestionsWithId
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderNameAndQuestions />
                    </AppRoute>
                    <AppRoute
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.nameAndQuestions
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderNameAndQuestions />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.invitationMessage
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderCreateInviteMessage />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.confirmationMessage
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderCreateConfirmationMessage />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.assignAndSave
                        }
                        allowRoute={{
                            isAllowed: hasAcceptedFloreyBuilderUsePolicy,
                            redirectTo: `${routeAcceptableUsePolicy(
                                orgId,
                            )}${encodeQueryParams()}`,
                        }}
                    >
                        <FloreyBuilderAssignAndSave />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.exportJson
                        }
                        allowRoute={{
                            isAllowed: isCreateGlobalFloreysEnabled,
                            redirectTo: `${routeHome(orgId)}`,
                        }}
                    >
                        <FloreyBuilderExportAsJson />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.importJson
                        }
                        allowRoute={{
                            isAllowed: isCreateGlobalFloreysEnabled,
                            redirectTo: `${routeHome(orgId)}`,
                        }}
                    >
                        <FloreyBuilderImportAsJson />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.questionnaireShare
                        }
                        allowRoute={{
                            isAllowed: isFloreySharingEnabled,
                            redirectTo: `${routeHome(orgId)}`,
                        }}
                    >
                        <SharingPage />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.questionnaireSwitchingOrg
                        }
                        allowRoute={{
                            isAllowed: isFloreySharingEnabled,
                            redirectTo: `${routeHome(orgId)}`,
                        }}
                    >
                        <SwitchingOrgPage />
                    </AppRoute>
                    <AppRoute
                        exact
                        path={
                            ROUTES_CHAIN.practicesFloreyBuilderBase +
                            PATHS.import
                        }
                        allowRoute={{
                            isAllowed: isFloreySharingEnabled,
                            redirectTo: `${routeHome(orgId)}`,
                        }}
                    >
                        <ImportToOrgPage />
                    </AppRoute>
                </Switch>
            </main>
        </>
    );
};
