import * as React from "react";

import { Card } from "@accurx/design";

import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

export type SsoErrorCardProps = {
    header: string;
    body: string | JSX.Element;
};

export const SsoErrorCard = ({
    header,
    body,
}: SsoErrorCardProps): JSX.Element => (
    <>
        <PageHeader title={header} type={PageHeaderType.ListPage} />
        <Card spacing={2} isElevated={false} props={{ className: "mb-4" }}>
            {body}
        </Card>
    </>
);
