import { PatientListType } from "@accurx/api/patient-messaging";
import { Button, Card, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PatientListAppointment } from "api/FlemingDtos";

import {
    ListDetails,
    getConfirmDeleteCopy,
} from "./ConfirmDeleteAppointmentsCopy";

type ConfirmDeleteAppointmentsProps = {
    /**
     * A list of appointments that have video consults
     *
     * *Note* that this may be a subset of the total set of appointments
     * attempting to be deleted. The presence of appointments in this prop
     * acts as a flag to show the user a warning message
     */
    appointmentsWithVideoConsultsToDelete: PatientListAppointment[];
    /**
     * The single date that will be affected by the delete operation.
     * A null date means 'Anytime'.
     */
    affectedDates: string[] | null;
    /**
     * The patient who's appointment is being deleted. This will only be
     * supplied when deleting a single appointment. A value of `undefined` for
     * this argument is used to infer that a whole day of appointments is being
     * deleted.
     */
    patientName?: string;
    /**
     * Information about the patient list being affected
     */
    listDetails: ListDetails;
    handleConfirm: () => void;
    handleCancel: () => void;
};

export const ConfirmDeleteAppointments = ({
    appointmentsWithVideoConsultsToDelete,
    affectedDates,
    patientName,
    listDetails,
    handleConfirm,
    handleCancel,
}: ConfirmDeleteAppointmentsProps) => {
    useAccurxWebTitle("Delete patient list");

    const scheduledVideoConsultsAffected =
        affectedDates !== null &&
        affectedDates.length > 0 &&
        appointmentsWithVideoConsultsToDelete.length > 0;

    const modalCopy = getConfirmDeleteCopy(
        affectedDates,
        scheduledVideoConsultsAffected,
        listDetails,
        patientName,
    );

    const mainBodyContent =
        patientName !== undefined ? (
            <>
                <strong>{`${patientName}`}</strong>
                {`${modalCopy.body.replace("[[INSERT PATIENT NAME]]", "")}`}
            </>
        ) : (
            modalCopy.body
        );

    const anytime =
        affectedDates?.filter(
            (date): date is string => date !== null && date === "",
        ) || [];

    const dates =
        affectedDates?.filter(
            (date): date is string => date !== null && date !== "",
        ) || [];

    // dates are affected by the delete
    const showDatesCard = dates.length > 0;
    // anytime section is affected by the delete
    const showAnytimeCard = anytime.length > 0;

    const isWholeListBeingDeleted =
        listDetails !== undefined && listDetails.listName !== undefined;

    const userCanDelete =
        listDetails?.isCurrentUserListOwner === true ||
        listDetails?.listType === PatientListType.WorkspaceManaged;
    const shouldRenderDateInfoCard =
        // if the list owner is deleting a list that affects some dates
        (isWholeListBeingDeleted && userCanDelete && showDatesCard) ||
        // if the delete affects a scheduled appointment
        (isWholeListBeingDeleted === false && showDatesCard);

    const shouldRenderAnytimeInfoCard =
        // if the list owner is deleting a list that affects anytime
        isWholeListBeingDeleted && userCanDelete && showAnytimeCard;

    return (
        <Modal
            isOpen={true}
            backdrop="static"
            data-testid="delete-patient-modal"
            centered
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h3" colour="night" skinny>
                    {modalCopy.title}
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Text
                    as="p"
                    variant="body"
                    colour="zinc"
                    props={{ className: "mb-1" }}
                    skinny
                >
                    {mainBodyContent}
                </Text>
                {shouldRenderDateInfoCard && (
                    <Card
                        spacing={1.5}
                        isElevated={false}
                        props={{ className: "mb-2" }}
                    >
                        {dates.map((date) => (
                            <Text
                                as="p"
                                key={date}
                                props={{ className: "mb-1" }}
                                skinny
                            >
                                {DateHelpers.formatDate(
                                    date,
                                    DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                                )}
                            </Text>
                        ))}

                        {modalCopy.videoConsultWarning && (
                            <div className="mt-1">
                                <Feedback
                                    key="scheduled-video-consult-warning"
                                    colour="warning"
                                    title={modalCopy.videoConsultWarning.title}
                                >
                                    <Text skinny>
                                        {modalCopy.videoConsultWarning.body}
                                    </Text>
                                </Feedback>
                            </div>
                        )}
                    </Card>
                )}

                {shouldRenderAnytimeInfoCard && (
                    <Card spacing={1.5} isElevated={false}>
                        <Text
                            as="p"
                            key={"anytime"}
                            props={{ className: "mb-1" }}
                            skinny
                        >
                            Anytime
                        </Text>

                        {modalCopy.videoConsultWarning && (
                            <div className="mt-1">
                                <Feedback
                                    key="scheduled-video-consult-warning"
                                    colour="warning"
                                    title="Patients from this section WILL NOT receive cancellation messages"
                                >
                                    <Text skinny>
                                        Patients from the 'Anytime' section will
                                        have to be notified by you about any
                                        changes.
                                    </Text>
                                </Feedback>
                            </div>
                        )}
                    </Card>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    text="Cancel"
                    theme="secondary"
                    onClick={handleCancel}
                />
                <Button
                    text="Remove"
                    theme="danger"
                    onClick={handleConfirm}
                    icon={{ name: "Bin" }}
                />
            </ModalFooter>
        </Modal>
    );
};
