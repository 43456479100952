import React from "react";

import { Flex, Text } from "@accurx/design";

import { trackQuestionnaireResponsesClinicianValidation } from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { mapUiFiltersToApiFilters } from "app/questionnaireScoreboard/mappers";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { useQuestionnaireFilters } from "../filtering/hooks/useQuestionnaireFilters";
import { SubmitClinicianStatusForm } from "./SubmitClinicianStatusForm";
import { StyledValidationCard } from "./ValidationCard.styles";
import { useSubmitClinicianStatusMutation } from "./useSubmitClinicianStatusMutation";

type SubmitClinicianStatusCardProps = {
    workspaceId: string;
    floreyEnrolmentId: number;
    questionnaireId: string;
};

export const SubmitClinicianStatusCard = ({
    workspaceId,
    floreyEnrolmentId,
    questionnaireId,
}: SubmitClinicianStatusCardProps) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    const { filters: currentFilters } = useQuestionnaireFilters();
    const submitClinicianStatusMutation = useSubmitClinicianStatusMutation();

    const onSubmitClinicianStatus = ({
        score,
        additionalInformation,
        onSuccess,
        onError,
    }: {
        score: string;
        additionalInformation?: string;
        onSuccess?: () => void;
        onError?: () => void;
    }) => {
        submitClinicianStatusMutation.mutate(
            {
                workspaceId,
                floreyEnrolmentId,
                score,
                additionalInformation,
                questionnaireId,
                filters: mapUiFiltersToApiFilters(currentFilters),
            },
            {
                onSuccess,
                onError,
                onSettled: (_data, error) => {
                    trackQuestionnaireResponsesClinicianValidation({
                        ...loggedInProps,
                        conditionId:
                            PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                        floreyEnrolmentId,
                        hasAdditionalNotes: !!additionalInformation,
                        hasError: !!error,
                    });
                },
            },
        );
    };

    return (
        <StyledValidationCard>
            <Flex flexDirection="column" gap="1.5">
                <SubmitClinicianStatusForm
                    onSubmit={onSubmitClinicianStatus}
                    isSubmitting={submitClinicianStatusMutation.isLoading}
                />
                <Text skinny variant="preview" colour="metal">
                    Only visible for people in your org
                </Text>
            </Flex>
        </StyledValidationCard>
    );
};
