import { Button, Ds, Flex, Text } from "@accurx/design";

type SaveToRecordWithDifferentPatientModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onSendMessage: () => void;
    medicalRecordSystem: string;
};

export const SaveToRecordWithDifferentEmrPatientModal = ({
    isOpen,
    onCancel,
    onSendMessage,
    medicalRecordSystem,
}: SaveToRecordWithDifferentPatientModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onCancel}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Save warning</Ds.Modal.Title>
                    <Text>
                        You are about to write to the record of a different
                        patient to the one in {medicalRecordSystem}. Are you
                        sure you want to do this?
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            type="button"
                            onClick={onCancel}
                            text="No"
                            aria-label="No, go back to the message"
                        />
                        <Button
                            theme="primary"
                            type="button"
                            onClick={onSendMessage}
                            text="Yes"
                            aria-label="Yes, continue sending the message"
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
