import { Actions as PracticesAction } from "app/practices/Practices.actions";
import { UpdatingStatus } from "shared/LoadingStatus";

import { VaccinePatientInvite } from "../models/VaccineAllPatientsInvitedDTO";
import { NbsData } from "../models/VaccineDeliveryDTO";
import { NimsData } from "../models/VaccineSharedDTO";
import { Actions, KnownAction } from "./VaccineAllPatientsInvited.actions";

// -----------------
// STATE Interface - This defines the type of data maintained in the Redux store.
// -----------------
export interface VaccineAllPatientsInvitedReducerState {
    patientDetailsInviteId: string;
    patientDetails: VaccinePatientInvite[];

    nimsData: NimsData | null;
    nimsDataLoading: boolean;
    nimsDataError: string;

    nbsData: NbsData | null;

    patientDetailsLoading: boolean;
    patientDetailsError: string;

    patientNoteSaving: boolean;
    patientNoteError: string;

    addingToNetworkStatus: UpdatingStatus;
    addingToNetworkError: string;
    addingToNetworkPatientBatchInviteId: string | null; // holds inviteBatchId for patient just added to network
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: VaccineAllPatientsInvitedReducerState = {
    patientDetailsInviteId: "",
    patientDetails: [],

    nimsData: null,
    nimsDataLoading: false,
    nimsDataError: "",

    nbsData: { bookings: [] },

    patientDetailsLoading: false,
    patientDetailsError: "",

    patientNoteSaving: false,
    patientNoteError: "",

    addingToNetworkStatus: UpdatingStatus.Initial,
    addingToNetworkError: "",
    addingToNetworkPatientBatchInviteId: null,
};

export const reducer = (
    state: VaccineAllPatientsInvitedReducerState = initialState,
    action: KnownAction,
): VaccineAllPatientsInvitedReducerState => {
    switch (action.type) {
        case Actions.GET_PATIENT_INVITE_DETAILS_STARTED:
            return {
                ...state,
                patientDetailsInviteId: action.inviteId,
                patientDetailsLoading: true,
                patientDetailsError: "",
            };
        case Actions.GET_PATIENT_INVITE_DETAILS_SUCCESS:
            return {
                ...state,
                patientDetailsLoading: false,
                patientDetails: action.invites || [],
                nimsData: action.nimsData,
                nbsData: action.nbsData,
            };
        case Actions.GET_PATIENT_INVITE_DETAILS_FAILURE:
            return {
                ...state,
                patientDetailsLoading: false,
                patientDetailsError: action.error,
            };
        case Actions.POST_PATIENT_INVITE_NOTES_STARTED:
            return {
                ...state,
                patientNoteSaving: true,
                patientNoteError: "",
            };
        case Actions.POST_PATIENT_INVITE_NOTES_SUCCESS:
            return {
                ...state,
                patientNoteSaving: false,
            };
        case Actions.POST_PATIENT_INVITE_NOTES_FAILURE:
            return {
                ...state,
                patientNoteSaving: false,
                patientNoteError: action.error,
            };
        case Actions.REFRESH_NIMS_DATA_STARTED:
            return {
                ...state,
                nimsDataLoading: true,
                nimsDataError: "",
            };
        case Actions.REFRESH_NIMS_DATA_SUCCESS:
            return {
                ...state,
                nimsDataLoading: false,
            };
        case Actions.REFRESH_NIMS_DATA_FAILURE:
            return {
                ...state,
                nimsDataLoading: false,
                nimsDataError: action.error,
            };
        case Actions.ADD_TO_NETWORK_STARTED:
            return {
                ...state,
                addingToNetworkStatus: UpdatingStatus.Loading,
                addingToNetworkError: "",
            };
        case Actions.ADD_TO_NETWORK_SUCCESS:
            return {
                ...state,
                addingToNetworkStatus: UpdatingStatus.Loaded,
                addingToNetworkPatientBatchInviteId: action.inviteBatchId,
            };
        case Actions.ADD_TO_NETWORK_FAILURE:
            return {
                ...state,
                addingToNetworkStatus: UpdatingStatus.Failed,
                addingToNetworkError: action.error,
            };
        case Actions.RESET_INVITE_NOTE_ERROR:
            return {
                ...state,
                patientNoteError: "",
            };
        case Actions.RESET_NIMS_DATA_ERROR:
            return {
                ...state,
                nimsDataError: "",
            };
        case Actions.RESET_ADD_TO_NETWORK_INFO:
            return {
                ...state,
                addingToNetworkStatus: UpdatingStatus.Initial,
                addingToNetworkError: "",
                addingToNetworkPatientBatchInviteId: null,
            };
        case Actions.UPDATE_PATIENT_INVITE_STATUS: {
            if (state.patientDetailsInviteId !== action.update.inviteId) {
                return state;
            }
            return {
                ...state,
                patientDetails: state.patientDetails.map(
                    (y: VaccinePatientInvite) => {
                        if (y.inviteId !== action.update.inviteId) return y;
                        return {
                            ...y,
                            status: action.update.status,
                        };
                    },
                ),
            };
        }
        case PracticesAction.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        default:
            return state;
    }
};
