import {
    Patient,
    SearchForPatientResponse,
    SearchedResult,
} from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { DateHelpers } from "@accurx/shared/dist";

import { WebPatient } from "app/patients/Patient.types";
import { SearchPatientState } from "app/searchForPatient/SearchForPatientReducer";
import { demoPatientData } from "app/searchForPatient/searchForPatientForm/SearchFormConstants";
import patientTokenService from "shared/storage/PatientTokenService";

export class PatientHelper {
    static isFeatureEnabled(
        patient: Patient | null,
        featureName: FeatureName,
    ): boolean {
        if (patient) {
            const foundFeature = patient.featuresAvailable.find(
                (x) => x.name === featureName,
            );
            return (foundFeature && foundFeature.isEnabled) === true;
        }
        return false;
    }

    static getPatient(
        patientSearchResult: SearchForPatientResponse | null,
    ): Patient | null {
        return (
            (patientSearchResult?.searched &&
                // we have to do this horrible casting
                // because api types include nullable fields
                // that aren't actually nullable, and we want to terminate
                // treating them nullable here
                (patientSearchResult?.searchedResult?.patient as Patient)) ||
            null
        );
    }

    // Gets the patient token from the passed in search result
    static getPatientToken(
        patientSearchResult: SearchForPatientResponse | null,
    ): string {
        if (patientSearchResult?.searchedResult?.patientToken) {
            return patientSearchResult.searchedResult.patientToken;
        }

        return "";
    }

    // Gets the patient token from the passed in search result
    // If it can't find a token then, then looks in the session storage as well
    // Used in App.tsx to persist the searched patient between requests
    // and also in other components or hooks that rely on a patient token (eg. ClinicianConversation)
    static getPatientTokenWithStorageFallback(
        patientSearchResult: SearchForPatientResponse | null,
    ): string {
        const patientToken = this.getPatientToken(patientSearchResult);

        if (patientToken.length > 0) {
            return patientToken;
        }

        if (patientTokenService !== null) {
            const storedPatientToken =
                patientTokenService.getCurrentPatientToken();
            if (storedPatientToken !== null) {
                return storedPatientToken;
            }
        }

        return "";
    }

    static isDummyPatient = (patient: WebPatient | null): boolean => {
        if (patient === null) {
            return false;
        }
        return (
            patient.nhsNumber === "0000000000" ||
            PatientHelper.isDemoPatient(patient.nhsNumber as string) ||
            patient.nhsNumber === "8000000008"
        );
    };

    static isDemoPatient = (nhsNumber: string): boolean => {
        return nhsNumber === demoPatientData.nhsNumber;
    };

    static getWebPatientFromSearchResult = (
        searchResult: SearchedResult | null,
    ): WebPatient | null => {
        const searchedPatient = searchResult?.patient || null;

        if (searchedPatient !== null) {
            const dateParts = DateHelpers.getDateParts(
                searchedPatient.dateOfBirth as string,
            );
            let dateOfBirthDay = NaN;
            let dateOfBirthMonth = NaN;
            let dateOfBirthYear = NaN;
            if (dateParts !== false) {
                dateOfBirthDay = dateParts.d;
                dateOfBirthMonth = dateParts.m;
                dateOfBirthYear = dateParts.y;
            }

            const webPatient: WebPatient = {
                nhsNumber: searchedPatient.nhsNumber,
                dateOfBirth: searchedPatient.dateOfBirth,
                dateOfBirthDay,
                dateOfBirthMonth,
                dateOfBirthYear,
                ageYear: searchedPatient.ageYear,
                prefixName: searchedPatient.prefixName,
                firstName: searchedPatient.firstName,
                familyName: searchedPatient.familyName,
                displayName: searchedPatient.displayName,
                gender: searchedPatient.gender,
                mobileNumber: searchedPatient.mobileNumber,
                // Patient search results never contain a patient id
                patientId: null,
                patientToken: searchResult?.patientToken || null,
            } as WebPatient;
            return webPatient;
        }

        return null;
    };

    static getSelectedPatient = (
        patientStore: SearchPatientState,
    ): WebPatient | null => {
        const selectedPatientId = patientStore.selectedPatient;

        // No selected patient
        if (!selectedPatientId) {
            return null;
        }

        return patientStore.allByNhsNumber[selectedPatientId] || null;
    };
}
