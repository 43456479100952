import React, { useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Button,
    Card,
    ErrorSummaryProvider,
    FormFieldFeedback,
    FormFieldWrapper,
    Switch,
    Text,
    Tokens,
    useErrorSummary,
} from "@accurx/design";
import { Editor, EditorProvider } from "@accurx/markdown-editor";
import { useHelpers, useRemirrorContext } from "@remirror/react";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { EDITOR_COUNT_EXTENSION_MAXIMUM } from "app/practices/patientTriage/constants/PracticePatientTriage";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsFeatureEnabled } from "store/hooks";

import {
    FooterProps,
    HandleSubmitProps,
    PatientSelfReferralProps,
} from "./PatientSelfReferral.types";

const StyledFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${Tokens.SIZES["2"]};
`;

const FooterContent = ({
    isEditable,
    isEditing,
    setIsEditing,
    initialContent,
    isEnabled,
    onSave,
}: FooterProps) => {
    const { errors, addError, clearAllErrors } = useErrorSummary();
    const { getMarkdown } = useHelpers(true);
    const { setContent } = useRemirrorContext();
    const loggedInAnalyticsProps = useFlemingLoggedInAnalytics();

    const baseAnalyticsProps = {
        ...loggedInAnalyticsProps,
        isEnabled,
        objectType: PatientTriageSettingObjectType.BUTTON,
    };

    const markdown = getMarkdown();

    const handleEdit = () => {
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSelfReferral({
            ...baseAnalyticsProps,
            hasError: false,
            countWord: markdown.length,
            objectName: PatientTriageSettingObjectName.EDIT,
        });

        setIsEditing(true);
    };

    const handleCancel = () => {
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSelfReferral({
            ...baseAnalyticsProps,
            hasError: errors.length > 0,
            countWord: markdown.length,
            objectName: PatientTriageSettingObjectName.CANCEL,
        });

        clearAllErrors();
        setContent(initialContent);
        setIsEditing(false);
    };

    const handleSave = () => {
        clearAllErrors();
        let hasError = false;

        if (markdown.trim().length === 0 && isEnabled) {
            addError({
                id: "saveSelfReferral",
                body: "Content cannot be empty when self-referral is enabled",
            });
            hasError = true;
        }
        if (markdown.length > EDITOR_COUNT_EXTENSION_MAXIMUM) {
            addError({
                id: "saveSelfReferral",
                body: "Content cannot exceed 10,000 characters",
            });
            hasError = true;
        }
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSelfReferral({
            ...baseAnalyticsProps,
            hasError,
            countWord: markdown.length,
            objectName: PatientTriageSettingObjectName.SAVE,
        });
        if (!hasError) {
            onSave({ message: markdown, isEnabled });
            setIsEditing(false);
        }
    };

    return (
        <>
            {isEditing ? (
                <>
                    <Button
                        id="saveSelfReferral"
                        theme="primary"
                        onClick={handleSave}
                        type="button"
                        text="Save changes"
                    />
                    <Button
                        dimension="medium"
                        theme="transparent"
                        onClick={handleCancel}
                        text="Cancel"
                        type="button"
                    />
                </>
            ) : (
                <Button
                    disabled={!isEditable}
                    dimension="medium"
                    theme="primary"
                    icon={{ name: "Pencil" }}
                    onClick={handleEdit}
                    text="Edit"
                    type="button"
                />
            )}
        </>
    );
};

const DisplayErrors = () => {
    const { errors } = useErrorSummary();
    return (
        <div>
            {errors.map((error, key) => (
                <FormFieldFeedback
                    key={key}
                    text={error.body as string}
                    variant="error"
                />
            ))}
        </div>
    );
};

export const PatientSelfReferral = ({
    isEditable,
    isEnabled,
    message,
    onSubmit,
}: PatientSelfReferralProps): JSX.Element | null => {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedEnabledValue, setUpdatedEnabledValue] = useState(isEnabled);
    const handleSwitchEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setUpdatedEnabledValue(checked);
    };

    const handleSubmit = ({ message, isEnabled }: HandleSubmitProps) => {
        onSubmit({ isEnabled, message });
    };

    const isFeatureEnabled = useIsFeatureEnabled(
        FeatureName.PatientTriageSelfReferralSetting,
    );
    return isFeatureEnabled ? (
        <section>
            <Text as="h2" variant="subtitle">
                Patient self-referral
            </Text>
            <EditorProvider
                editable={isEditing}
                label="Self referral content"
                content={message}
            >
                <ErrorSummaryProvider>
                    <Card
                        props={{ className: "mb-3" }}
                        header={
                            <Text as="h3" skinny variant="subtitle">
                                Settings
                            </Text>
                        }
                        footer={
                            <StyledFooterWrapper>
                                {
                                    <FooterContent
                                        isEditable={isEditable}
                                        isEditing={isEditing}
                                        setIsEditing={setIsEditing}
                                        initialContent={message}
                                        isEnabled={updatedEnabledValue}
                                        onSave={handleSubmit}
                                    />
                                }
                            </StyledFooterWrapper>
                        }
                    >
                        <div>
                            <Text>
                                Turning on this option will show patients a
                                self-referral option on the form with the
                                message that you specify below.
                            </Text>

                            {isEditing ? (
                                <>
                                    <FormFieldWrapper label="Enable Patient Self Referral">
                                        <div className="d-flex mt-2">
                                            <Switch
                                                testId="selfReferralSwitch"
                                                onChange={handleSwitchEnabled}
                                                defaultChecked={isEnabled}
                                            />
                                            <Text skinny>
                                                {updatedEnabledValue
                                                    ? "Enabled"
                                                    : "Disabled"}
                                            </Text>
                                        </div>
                                    </FormFieldWrapper>
                                </>
                            ) : (
                                <>
                                    <Text variant="label">
                                        Enable Patient Self Referral
                                    </Text>
                                    <Text skinny>
                                        {isEnabled ? "Enabled" : "Disabled"}
                                    </Text>
                                    <Text variant="label">Current message</Text>
                                </>
                            )}
                            <Editor />
                            <DisplayErrors />
                        </div>
                    </Card>
                </ErrorSummaryProvider>
            </EditorProvider>
        </section>
    ) : null;
};
