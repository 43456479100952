import { FormEvent, useEffect, useState } from "react";

import * as UI from "@accurx/design";
import {
    FormFieldFeedback,
    Input,
    SelectItem,
    SingleSelect,
} from "@accurx/inbox-design-library";
import { useSearchForPatientByPdsDemographicsMutation } from "domains/inbox/hooks/mutations/useSearchForPatientByPdsDemographicsMutation";

import { StyledDateOfBirthWrapper, StyledFormSpacer } from "./Forms.styles";
import { NoPatientsFoundFeedback } from "./NoPatientsFoundFeedback";
import { SearchErrorFeedback } from "./SearchErrorFeedback";
import {
    FormErrorsByDemographics,
    FormPdsResult,
    FormStateByDemographics,
} from "./types";
import { validateByDemographicsForm } from "./validatePdsForms";

type FormPdsByDemographicsProps = {
    onResultsFound: ({ patient, patientToken }: FormPdsResult) => void;
    onSearchClick?: ({ hasError }: { hasError: boolean }) => void;
    onSearchSettled?: ({ hasError }: { hasError: boolean }) => void;
    setIsLoading?: (isLoading: boolean) => void;
};

export const formPdsByDemographicsId =
    "search-for-patient-by-demographics-form";

export const FormPdsByDemographics = ({
    onResultsFound,
    onSearchClick,
    onSearchSettled,
    setIsLoading,
}: FormPdsByDemographicsProps) => {
    const [formState, setFormState] = useState<FormStateByDemographics>({
        firstName: "",
        lastName: "",
        dateOfBirth: { day: "", month: "", year: "" },
        gender: "",
        postcode: "",
    });

    const [formFieldErrors, setFormFieldErrors] =
        useState<FormErrorsByDemographics>({});

    const { mutate, data, status } =
        useSearchForPatientByPdsDemographicsMutation({
            onSuccess: (data) => {
                data.searchedResult.patient &&
                    onResultsFound({
                        patient: data.searchedResult.patient,
                        patientToken: data.searchedResult.patientToken,
                    });
            },
            onSettled: (_data, error) =>
                onSearchSettled?.({ hasError: !!error }),
        });

    const onChange = (
        data: Partial<FormStateByDemographics>,
        field: keyof FormStateByDemographics,
    ) => {
        if (formFieldErrors[field]) {
            setFormFieldErrors((prev) => ({
                ...prev,
                [field]: null,
            }));
        }
        setFormState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        const validated = validateByDemographicsForm(formState);

        onSearchClick?.({ hasError: !validated.isValid });

        if (validated.isValid) {
            setFormFieldErrors({});

            // Search for the patient
            mutate(formState);
        } else {
            setFormFieldErrors(validated.errors);
        }
    };

    const showErrorMessage = status === "error";
    const showNotFoundMessage =
        status === "success" && !data.searchedResult.patient;

    useEffect(() => {
        setIsLoading?.(status === "loading");
    }, [status, setIsLoading]);

    return (
        <UI.Flex flexDirection="column" gap="0.5">
            <form
                onSubmit={onSubmit}
                id={formPdsByDemographicsId}
                aria-label="Search for patient by demographics"
                aria-describedby={
                    showErrorMessage
                        ? "search-error"
                        : showNotFoundMessage
                        ? "not-found-warning"
                        : undefined
                }
            >
                <StyledFormSpacer>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "first-name" }}
                            skinny
                        >
                            First name
                        </UI.Text>
                        <Input
                            id={"first-name"}
                            hasErrors={!!formFieldErrors.firstName}
                            value={formState.firstName}
                            onChange={(e): void =>
                                onChange(
                                    { ...formState, firstName: e.target.value },
                                    "firstName",
                                )
                            }
                            aria-describedby={
                                formFieldErrors.firstName
                                    ? "first-name-error"
                                    : undefined
                            }
                        />
                        {!!formFieldErrors.firstName && (
                            <div id="first-name-error">
                                <FormFieldFeedback
                                    text={formFieldErrors.firstName}
                                    variant="error"
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "last-name" }}
                            skinny
                        >
                            Last name
                        </UI.Text>
                        <Input
                            id={"last-name"}
                            hasErrors={!!formFieldErrors.lastName}
                            value={formState.lastName}
                            onChange={(e): void =>
                                onChange(
                                    { ...formState, lastName: e.target.value },
                                    "lastName",
                                )
                            }
                            aria-describedby={
                                formFieldErrors.lastName
                                    ? "last-name-error"
                                    : undefined
                            }
                        />
                        {!!formFieldErrors.lastName && (
                            <div id="last-name-error">
                                <FormFieldFeedback
                                    text={formFieldErrors.lastName}
                                    variant="error"
                                />
                            </div>
                        )}
                    </div>
                    <StyledDateOfBirthWrapper>
                        <UI.DateOfBirthInput
                            error={formFieldErrors.dateOfBirth ?? undefined}
                            legend={
                                <UI.Text variant="note" skinny>
                                    Date of birth
                                </UI.Text>
                            }
                            dayValue={formState.dateOfBirth.day}
                            onDayChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            day: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                            monthValue={formState.dateOfBirth.month}
                            onMonthChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            month: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                            yearValue={formState.dateOfBirth.year}
                            onYearChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            year: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                        />
                    </StyledDateOfBirthWrapper>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "gender" }}
                            skinny
                        >
                            Gender
                        </UI.Text>
                        <SingleSelect
                            id="gender"
                            onValueChange={(val) => {
                                onChange(
                                    { ...formState, gender: val },
                                    "gender",
                                );
                            }}
                            dimension="medium"
                            error={!!formFieldErrors.gender}
                            placeholder="Select"
                        >
                            {[
                                { label: "Male", value: "Male" },
                                { label: "Female", value: "Female" },
                                { label: "Not known", value: "Not Known" },
                                {
                                    label: "Not specified",
                                    value: "Not Specified",
                                },
                            ].map((option) => {
                                return (
                                    <SelectItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </SelectItem>
                                );
                            })}
                        </SingleSelect>
                        {!!formFieldErrors.gender && (
                            <div id="gender-error">
                                <FormFieldFeedback
                                    text={formFieldErrors.gender}
                                    variant="error"
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "postcode" }}
                            skinny
                        >
                            Postcode
                        </UI.Text>
                        <Input
                            id={"postcode"}
                            hasErrors={!!formFieldErrors.postcode}
                            value={formState.postcode}
                            onChange={(e): void =>
                                onChange(
                                    { ...formState, postcode: e.target.value },
                                    "postcode",
                                )
                            }
                            aria-describedby={
                                formFieldErrors.postcode
                                    ? "postcode-error"
                                    : undefined
                            }
                        />
                        {!!formFieldErrors.postcode && (
                            <div id="postcode-error">
                                <FormFieldFeedback
                                    text={formFieldErrors.postcode}
                                    variant="error"
                                />
                            </div>
                        )}
                    </div>
                    {showErrorMessage && (
                        <SearchErrorFeedback id="search-error" />
                    )}
                    {showNotFoundMessage && (
                        <NoPatientsFoundFeedback id="not-found-warning" />
                    )}
                </StyledFormSpacer>
            </form>
        </UI.Flex>
    );
};
