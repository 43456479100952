import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledUnorderedList = styled.ul`
    display: grid;
    list-style-type: none;
    margin: ${Tokens.SIZES[0.5]};
    padding: 0;
    row-gap: ${Tokens.SIZES[1]};
`;

export const StyledLi = styled.li`
    margin: 0;
    padding: 0;
`;
