import { ReactNode } from "react";

import { ButtonProps } from "../Button/Button";
import { StyledPrimaryButton } from "./PrimaryButton.styles";

export const PrimaryButton = ({
    children,
    ...otherProps
}: { children: ReactNode } & ButtonProps) => {
    return (
        <StyledPrimaryButton dimension="medium" {...otherProps}>
            {children}
        </StyledPrimaryButton>
    );
};
