import { Observable } from "rxjs";
import { scan } from "rxjs/operators";

import { mergeDeepAllConversations } from "shared/concierge/conversations/ConversationData.utils";
import {
    Conversation,
    ConversationUpdate,
} from "shared/concierge/conversations/types/conversation.types";

/**
 * Function that pipes in the feed of action updates and merges it with the
 * historical data accumulated since the beginning of the subscription
 */
export function getSearchedPatientConversationsFeed(
    currentUserId: string,
    actionsConversationsFeed: Observable<ConversationUpdate[]>,
): Observable<Conversation[]> {
    return actionsConversationsFeed.pipe(
        scan((accumulated, updates) => {
            const conversationsMap = new Map();
            accumulated.forEach((conv) => {
                conversationsMap.set(conv.id, conv);
            });

            // We don't care about the epoch version as we're not listening
            // to live updates and/or dealing with background fetching
            const epochVersion = 0;

            return mergeDeepAllConversations(
                currentUserId,
                conversationsMap,
                updates,
                epochVersion,
            );
        }, [] as Conversation[]),
    );
}
