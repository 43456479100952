import {
    MessageTemplate,
    QuestionnaireTemplate,
} from "@accurx/message-templates";
import { SelfbookConfigurationPayload } from "@accurx/self-book";
import { ScheduleSendAt } from "domains/compose/components/Compose/components/ScheduleSend/types/ScheduleSend.types";
import { NHSAdviceLink, TemplateItem } from "domains/compose/types";

import { COMPOSE_ERRORS } from "./constants";

type ActionMap<Actions = Record<string, unknown>> = {
    [Key in keyof Actions]: Actions[Key] extends undefined
        ? { type: Key }
        : { type: Key; payload: Actions[Key] };
};

export const ContactMethods = { Mobile: "Mobile", Email: "Email" } as const;

export type ContactDetail = {
    method: keyof typeof ContactMethods;
    value: string;
    origin: "PDS" | "EMR" | "Conversation" | "UserInput" | "SubmittedByPatient";
};

export type AttachmentSource = "PatientMessaging" | "ClinicianMessaging";

export type Attachment = {
    origin: "TempUpload" | "Upload" | "Template";
    id: string;
    name: string;
    previewUrl?: string;
    /** File size in bytes */
    size?: number;
};

export type ComposeErrorType =
    (typeof COMPOSE_ERRORS)[keyof typeof COMPOSE_ERRORS];

export type ComposeMessageTemplate = Omit<MessageTemplate, "attachments">;

export type SelfBookLink = {
    appointmentType: SelfbookConfigurationPayload["appointmentType"];
    slotType?: string;
    timeoutDays: number;
    specificClinician?: {
        clinicianGroupId: number;
        clinicianNames: string[];
    }[];
    // Some self book links are for different organisations than the current one
    crossOrgBooking?: {
        targetOdsName: string;
        targetOdsCode: string;
    };
};

export type ComposeStateType = {
    /**
     * Original settings set when
     * initialising compose,
     * can be referenced to determine
     * message validity or reverting to
     * original message
     */
    originalGreeting: string;
    originalSignature: string;
    originalBody: string;

    contactDetails: ContactDetail;
    messageBody: string;
    messageSignature: string;
    /** How many attachments is the user allowed to add the the message */
    maxAttachmentCount: number;
    attachments: Attachment[];
    isPatientResponseEnabled: boolean;
    isSaveToRecordEnabled: boolean;
    errors: ComposeErrorType[];
    template: TemplateItem | null;
    sendAt: ScheduleSendAt;
    selfBookLink: SelfBookLink | null;
    nhsAdviceLink: NHSAdviceLink | null;
    canUseQuestionnaires: boolean;
    conversationParticipant: "WithHcp" | "WithPatient";
};

export const ComposeActionsTypes = {
    // Contact details actions
    UpdateContactDetails: "UPDATE_CONTACT_DETAILS",

    // Textarea actions
    UpdateMessageBody: "UPDATE_MESSAGE_BODY",
    UpdateMessageSignature: "UPDATE_MESSAGE_SIGNATURE",
    UpdateIsPatientResponseEnabled: "UPDATE_IS_PATIENT_RESPONSE_ENABLED",
    AddMessageTemplate: "ADD_MESSAGE_TEMPLATE",
    AddQuestionnaireTemplate: "ADD_QUESTIONNAIRE_TEMPLATE",
    RemoveTemplate: "REMOVE_TEMPLATE",
    AddSelfBookLink: "ADD_SELF_BOOK_LINK",
    RemoveSelfBookLink: "REMOVE_SELF_BOOK_LINK",
    AddNHSAdviceLink: "ADD_NHS_ADVICE_LINK",
    RemoveNHSAdviceLink: "REMOVE_NHS_ADVICE_LINK",

    // Attachments actions
    AddAttachment: "ADD_ATTACHMENT",
    UpdateAttachment: "UPDATE_ATTACHMENT",
    RemoveAttachment: "REMOVE_ATTACHMENT",

    // Save to record settings
    UpdateIsSaveToRecordEnabled: "UPDATE_IS_SAVE_TO_RECORD_ENABLED",

    // Scheduling
    UpdateSendAt: "UPDATE_SEND_AT",

    SetErrors: "SET_ERRORS",
} as const;

export type ComposePayload = {
    // Contact details actions: switch who the message is going to/in which format (email vs. SMS)
    [ComposeActionsTypes.UpdateContactDetails]: {
        contactDetails: ContactDetail;
    };

    // Textarea actions: add / remove / replace content
    [ComposeActionsTypes.UpdateMessageBody]: {
        text: string;
    };
    [ComposeActionsTypes.UpdateMessageSignature]: {
        text: string;
    };
    [ComposeActionsTypes.UpdateIsPatientResponseEnabled]: {
        isPatientResponseEnabled: boolean;
    };
    [ComposeActionsTypes.AddMessageTemplate]: {
        template: MessageTemplate;
    };
    [ComposeActionsTypes.AddQuestionnaireTemplate]: {
        template: QuestionnaireTemplate;
    };
    [ComposeActionsTypes.RemoveTemplate]: undefined;

    [ComposeActionsTypes.AddSelfBookLink]: { selfBookLink: SelfBookLink };

    [ComposeActionsTypes.RemoveSelfBookLink]: undefined;
    [ComposeActionsTypes.AddNHSAdviceLink]: { nhsAdviceLink: NHSAdviceLink };
    [ComposeActionsTypes.RemoveNHSAdviceLink]: undefined;

    // Attachment actions: add / remove attachment
    [ComposeActionsTypes.AddAttachment]: {
        attachment: Attachment;
    };
    [ComposeActionsTypes.UpdateAttachment]: {
        attachmentId: string;
        attachmentOrigin: Attachment["origin"];
        attachment: Attachment;
    };
    [ComposeActionsTypes.RemoveAttachment]: {
        attachment: Attachment;
    };

    // Scheduling actions
    [ComposeActionsTypes.UpdateSendAt]: { sendAt: ScheduleSendAt };

    // Save to record actions
    [ComposeActionsTypes.UpdateIsSaveToRecordEnabled]: {
        isSaveToRecordEnabled: boolean;
    };

    // Setting errors
    [ComposeActionsTypes.SetErrors]: ComposeErrorType[];
};

export type ComposeActions =
    ActionMap<ComposePayload>[keyof ActionMap<ComposePayload>];
