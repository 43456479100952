import React from "react";

import { Button, Ds, Flex, Icon, Link, Text } from "@accurx/design";

import { getDisplayFileSize } from "app/workspaceConversations/utils/conversation.utils";

import type { PreviewUrl } from "../../MessageTemplates/MessageTemplates.types";
import {
    StyledAttachedFile,
    StyledAttachedFilesWrapper,
    StyledFileInformation,
} from "./AttachFileRow.styles";

type AttachFileRowProps = {
    attachmentId: string;
    previewUrl: PreviewUrl;
    fileName: string;
    fileSize: number;
    handleRemoveFile: (attachmentId: string) => void;
    isControllable: boolean;
    isFromTemplate?: boolean;
};

export const AttachFileRow = ({
    attachmentId,
    previewUrl,
    fileName,
    fileSize,
    handleRemoveFile,
    isFromTemplate,
    isControllable,
}: AttachFileRowProps) => (
    <StyledAttachedFilesWrapper data-testid="attached-file-preview">
        <StyledAttachedFile>
            <Flex alignItems="center" gap="1">
                <Icon name="Document" size={3} />
                <Flex flexDirection="column" alignItems="flex-start" gap="0.25">
                    {isFromTemplate && (
                        <Ds.Badge color="greyscale">From template</Ds.Badge>
                    )}
                    <Text skinny>{fileName}</Text>
                </Flex>
            </Flex>
            <StyledFileInformation>
                {getDisplayFileSize(fileSize)}
                <Link href={previewUrl} openInNewTab text="Preview" />
                {isControllable && (
                    <Button
                        dimension="small"
                        icon={{
                            name: "Cross",
                            colour: "red",
                            title: "Remove attachment",
                            id: "remove-attachment",
                        }}
                        theme="secondary"
                        onClick={() => handleRemoveFile(attachmentId)}
                    />
                )}
            </StyledFileInformation>
        </StyledAttachedFile>
    </StyledAttachedFilesWrapper>
);
