import {
    PatientListAppointment,
    PatientListAppointmentDay,
    PatientListPatientDetail,
    PatientListPatientDetails,
} from "api/FlemingDtos";
import { SearchFormErrors } from "app/searchForPatient/searchForPatientForm/SearchFormConstants";

import { PatientListAddPatientServerErrors } from "./PatientListConstants";

/** Gets a list of appointments grouped by day and returns the full list of appointments ungrouped
 *
 * @param appointmentDays - [optional]
 * @returns empty array if appointmentDays is undefined or the ungrouped list of appointments in each day
 */
export const getPatientListEntries = (
    appointmentDays?: PatientListAppointmentDay[],
): PatientListAppointment[] => {
    if (appointmentDays) {
        return appointmentDays.reduce<PatientListAppointment[]>(
            (result, value) => {
                return [...result, ...value.appointments];
            },
            [],
        );
    }
    return [];
};

/**
 * @param patientDetails - part of the currentList store state, if not null, it is a normalised list of patients, with patientId as key
 * @param patientId - the Id of the patient
 * @return the patient object from the patientId or null if no patient is found
 * */
export const getPatientFromPatientId = (
    patientDetails: PatientListPatientDetails | null,
    patientId: number,
): PatientListPatientDetail | null => {
    if (patientDetails && patientDetails[patientId]) {
        return patientDetails[patientId];
    }
    return null;
};

export const getAddSinglePatientServerError = (
    errorMessage: string,
): string => {
    if (errorMessage.includes(PatientListAddPatientServerErrors.NoMatchFound)) {
        return SearchFormErrors.NoMatchFound;
    }

    // Check other server errors other than no match
    if (
        errorMessage.includes(
            PatientListAddPatientServerErrors.NhsNumberNotVerified,
        )
    ) {
        return SearchFormErrors.NhsNumberNotVerified;
    }
    if (
        errorMessage.includes(PatientListAddPatientServerErrors.MultipleMatches)
    ) {
        return SearchFormErrors.MultipleMatches;
    }

    return SearchFormErrors.Other;
};
