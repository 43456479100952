import { z } from "zod";

import { PatientExternalIdSchema } from "./PatientExternalIdSchema";

export const PatientExternalIdentityDtoSchema = z.object({
    patientExternalIds: z.array(PatientExternalIdSchema),
});

export type PatientExternalIdentityDto = z.infer<
    typeof PatientExternalIdentityDtoSchema
>;
