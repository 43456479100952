import React from "react";

import { QuestionnaireSummary, StatusQuery } from "@accurx/api/florey-builder";
import { Button, StackPanel, Text, Tokens } from "@accurx/design";
import { ButtonVariants } from "@accurx/design/dist/styles/types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

import { StyledIndent, StyledSpaced } from "./PageContainer.styles";

export type ConfirmationModalProps = {
    isModalOpen: boolean;
    closeModal: () => void;
    headerText: string;
    children: React.ReactNode;
    secondaryAction: () => void;
    secondaryText: string;
    primaryAction: () => void;
    primaryText: string;
    primaryButtonTheme?: ButtonVariants;
    isActionInProgress?: boolean;
};

const CloseModalButton = styled(Button)`
    margin-left: auto;
    position: absolute;
    top: ${Tokens.SIZES[1]};
    right: ${Tokens.SIZES[1]};
`;

export const ConfirmationModal = ({
    isModalOpen,
    closeModal,
    headerText,
    children,
    secondaryAction,
    secondaryText,
    primaryAction,
    primaryText,
    primaryButtonTheme,
    isActionInProgress,
}: ConfirmationModalProps): JSX.Element => {
    return (
        <Modal isOpen={isModalOpen} centered>
            <CloseModalButton
                icon={{ name: "Cross", title: "Close", id: "close-modal" }}
                theme="transparent"
                onClick={closeModal}
            />
            <ModalHeader>
                <Text variant={"label"} children={headerText} />
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                <StackPanel orientation={"horizontal"} gutter={2}>
                    <Button
                        theme="secondary"
                        type="button"
                        data-testid="secondary-button"
                        onClick={secondaryAction}
                        text={secondaryText}
                        disabled={isActionInProgress === true}
                    />
                    <Button
                        theme={primaryButtonTheme ?? "primary"}
                        type="button"
                        data-testid="primary-button"
                        onClick={primaryAction}
                        text={primaryText}
                        disabled={isActionInProgress === true}
                    />
                </StackPanel>
            </ModalFooter>
        </Modal>
    );
};

type ConfirmModalProps = {
    onConfirm: () => void;
    onCancel: () => void;
    isOpen: boolean;
    isLoading: boolean;
    summary: QuestionnaireSummary;
};

export const ConfirmPublishModal = ({
    summary,
    isOpen,
    isLoading,
    onConfirm,
    onCancel,
}: ConfirmModalProps) => {
    return (
        <ConfirmationModal
            isModalOpen={isOpen}
            closeModal={onCancel}
            headerText={"Publishing Florey Questionnaire"}
            secondaryAction={onCancel}
            secondaryText={"Cancel"}
            primaryAction={onConfirm}
            primaryText={isLoading ? "Publishing..." : "Publish"}
            isActionInProgress={isLoading}
        >
            <Text>
                Are you sure you want to publish this Florey Questionnaire?
            </Text>
            <StyledIndent>
                <Text variant={"label"}>{summary.name}</Text>
            </StyledIndent>
            <StyledSpaced>
                <Text>
                    Once published, this Florey Questionnaire can be sent to
                    real patients
                </Text>
            </StyledSpaced>
        </ConfirmationModal>
    );
};

export const ConfirmDeleteModal = ({
    summary,
    isOpen,
    isLoading,
    onConfirm,
    onCancel,
}: ConfirmModalProps) => {
    return (
        <ConfirmationModal
            isModalOpen={isOpen}
            closeModal={onCancel}
            headerText={"Deleting Florey Questionnaire"}
            secondaryAction={onCancel}
            secondaryText={"Cancel"}
            primaryAction={onConfirm}
            primaryText={isLoading ? "Deleting..." : "Delete"}
            primaryButtonTheme={"danger"}
            isActionInProgress={isLoading}
        >
            <Text>
                Are you sure you want to delete this Florey Questionnaire?
            </Text>
            <StyledIndent>
                <Text variant={"label"}>{summary.name}</Text>
            </StyledIndent>
            <StyledSpaced>
                {summary.status === StatusQuery.Published ? (
                    <>
                        <Text children={"This cannot be undone."} />
                        <Text
                            children={
                                " Deleting this Florey Questionnaire after it has been sent to patients will not prevent them from being able to respond to it."
                            }
                        />
                    </>
                ) : (
                    <Text>This cannot be undone.</Text>
                )}
            </StyledSpaced>
        </ConfirmationModal>
    );
};

export const ConfirmEditModal = ({
    summary,
    isOpen,
    onConfirm,
    onCancel,
}: ConfirmModalProps) => {
    return (
        <ConfirmationModal
            isModalOpen={isOpen}
            closeModal={onCancel}
            headerText={"Edit Florey Questionnaire"}
            secondaryAction={onCancel}
            secondaryText={"Cancel"}
            primaryAction={onConfirm}
            primaryText={"Edit"}
        >
            <Text>
                Are you sure you want to edit this Florey Questionnaire?
            </Text>
            <StyledIndent>
                <Text variant={"label"}>{summary.name}</Text>
            </StyledIndent>
            <StyledSpaced>
                <ul>
                    <li>
                        <Text
                            children={
                                "You will need to publish this Florey Questionnaire again once you've finished editing."
                            }
                        />
                    </li>
                    <li>
                        <Text
                            children={
                                "This Florey Questionnaire will move from the 'Published' table to the 'Drafted' table."
                            }
                        />
                    </li>
                    <li>
                        <Text
                            children={
                                "Florey Questionnaires you have already sent out will still be accessible to be answered by patients."
                            }
                        />
                    </li>
                </ul>
            </StyledSpaced>
        </ConfirmationModal>
    );
};

export const ConfirmGloballyAccessibleModal = ({
    summary,
    isOpen,
    onConfirm,
    onCancel,
}: ConfirmModalProps) => {
    return (
        <ConfirmationModal
            isModalOpen={isOpen}
            closeModal={onCancel}
            headerText={"Global Accessibility"}
            secondaryAction={onCancel}
            secondaryText={"Cancel"}
            primaryAction={onConfirm}
            primaryText={summary.isGloballyAccessible ? "Turn off" : "Turn on"}
        >
            <Text>
                Globally Availability allows other organisations to use this
                Florey for their own patients.
            </Text>
            <Text>
                {summary.isGloballyAccessible ? (
                    <>You are turning off Global Availability for</>
                ) : (
                    <>You are turning on Global Availability for</>
                )}
            </Text>
            <StyledIndent>
                <Text variant={"label"}>{summary.name}</Text>
            </StyledIndent>
            <Text>
                {summary.isGloballyAccessible ? (
                    <>Select "Cancel" to keep it available for others.</>
                ) : (
                    <>
                        Select "Cancel" to keep it exclusive to your
                        organisation.
                    </>
                )}
            </Text>
        </ConfirmationModal>
    );
};
