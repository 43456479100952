import { useEffect, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Card, Feedback, Text } from "@accurx/design";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { WorkspaceInvite as WorkspaceInviteType } from "api/WorkspaceDtos";
import {
    OnboardingContainer,
    OnboardingPageWrapper,
} from "app/account/Organisation/layout-components";
import { useGetWorkspaceInvitesQuery } from "app/hooks/queries";
import {
    SkeletonBody,
    SkeletonLabel,
} from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { ROUTES } from "shared/Routes";

import { WorkspaceInvitation } from "./WorkspaceInvitation";

const WorkspaceInvitationPage = (): JSX.Element => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const workspaceId = Number(params.get("workspaceId"));
    const { setSelectedOrgId } = useSelectedOrganisation();
    const {
        user: { organisations },
    } = useCurrentUser();

    const [isLoading, setIsLoading] = useState(true);
    const [invite, setInvite] = useState<WorkspaceInviteType | null>(null);

    const {
        isError: hasError,
        isLoading: isLoadingInvites,
        data: invites = [],
    } = useGetWorkspaceInvitesQuery();

    const existingWorkspaceForInvite = organisations.find(
        ({ orgId }) => orgId === workspaceId,
    );
    const hasUserAlreadyJoinedWorkspace = !!existingWorkspaceForInvite;
    const existingWorkspaceName = existingWorkspaceForInvite?.organisationName;

    // Get the invitation for the given workspace ID and current user
    useEffect(() => {
        if (isLoadingInvites) {
            return;
        }

        if (hasError || !invites) {
            setIsLoading(false);
            return;
        }

        const inviteForCurrentWorkspace = invites.find(
            (invite: WorkspaceInviteType) =>
                invite.workspace.id === workspaceId,
        );

        inviteForCurrentWorkspace && setInvite(inviteForCurrentWorkspace);

        setIsLoading(false);
    }, [workspaceId, invites, hasError, isLoadingInvites]);

    // Redirect home and select the workspace if current user is already a member
    useEffect(() => {
        if (hasUserAlreadyJoinedWorkspace) {
            setSelectedOrgId(workspaceId);

            toast(
                Feedback({
                    colour: "success",
                    title: `You're already a member of ${existingWorkspaceName}`,
                }),
            );

            history.push(ROUTES.home);
        }
        // Only need to check on page load, as we don't want this to run after accepting an invite
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OnboardingPageWrapper>
            <Text as="h1" variant="title">
                You've been invited to join your colleagues
            </Text>
            <OnboardingContainer>
                {hasError && (
                    <Card>
                        <Feedback
                            colour="error"
                            title="There was an error getting your invitation"
                        >
                            <Text skinny>
                                Please refresh the page to try again
                            </Text>
                        </Feedback>
                    </Card>
                )}
                {isLoading && (
                    <Card>
                        <Card>
                            <SkeletonLabel charCount={40} />
                            <br />
                            <SkeletonBody charCount={20} />
                        </Card>
                    </Card>
                )}
                {!hasError && !isLoading && !invite && (
                    <Card>
                        <Feedback
                            colour="error"
                            title="We were unable to find an invitation to this workspace for you"
                        >
                            <Text skinny>
                                Please ask an admin from the workspace to send
                                another invitation
                            </Text>
                        </Feedback>
                    </Card>
                )}
                {invite && (
                    <WorkspaceInvitation
                        workspaceId={invite.workspace.id}
                        workspaceName={invite.workspace.name}
                        organisationNodeName={
                            invite.workspace.organisationNodeName
                        }
                        invitedByUser={invite.invitedByUser}
                    />
                )}
            </OnboardingContainer>
        </OnboardingPageWrapper>
    );
};

export { WorkspaceInvitationPage };
