import { Option } from "@accurx/design";
import { Specialty } from "@accurx/workspace-management";
import sortBy from "lodash/sortBy";

import { FlemingLoggedInCustomProperties } from "app/analytics/FlemingAnalytics";
import { trackAccountSpecialtyConfirm } from "app/analytics/FlemingAnalytics/AccountSpecialtyEvents";

export const trackConfirmSpecialty = ({
    hasError,
    specialtyName,
    analyticsProps,
}: {
    hasError: boolean;
    specialtyName: string;
    analyticsProps: FlemingLoggedInCustomProperties;
}) => {
    trackAccountSpecialtyConfirm({
        ...analyticsProps,
        selectedSpecialtyName: specialtyName,
        hasError,
    });
};

/**
 * @returns the value of a specialty option as a Number
 */
export const getSpecialtyOptionValue = (option?: Option) => {
    if (!option) {
        return undefined;
    }
    const parsedValue = Number(option.value);
    return isNaN(parsedValue) ? undefined : parsedValue;
};

/**
 * a really simple helper to format a specialty as an Option for a Select component
 */
export const getSpecialtyAsOption = (specialty?: Specialty): Option => {
    return {
        value: specialty?.id || "",
        label: specialty?.name || "",
    };
};

/**
 * @param specialties a list of specialties of type Specialty[]
 * @returns a list of specialties sorted A-Z, of type Option to be used by a SearchSelect component
 */
export const sortSpecialtiesAlphabetically = (
    specialties: Specialty[],
): Option[] => {
    return sortBy(specialties, ["name"]).map(getSpecialtyAsOption);
};
