import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    useMarkAsDoneMutation,
    useMarkAsOpenMutation,
} from "domains/inbox/hooks/mutations";
import { useViewportSize } from "domains/inbox/hooks/util/";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { userflowIds } from "domains/inbox/util";
import { toast } from "react-toastify";

import { StyledDoneButton } from "./ToggleStatusActionButton.styles";

export type ToggleStatusActionButtonProps = {
    conversation: Conversation;
    productOriginAnalytics: Parameters<
        typeof useConversationActionAnalytics
    >[0]["productOrigin"];
};

export const ToggleStatusActionButton = ({
    conversation,
    productOriginAnalytics,
}: ToggleStatusActionButtonProps) => {
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: productOriginAnalytics,
    });
    const size = useViewportSize();
    const markDoneText = size !== "xs" ? "Mark done" : "Done";

    const markAsDone = useMarkAsDoneMutation({
        onSuccess: () =>
            analytics.trackConversationActionButtonResponse("Done", false),
        onError: () => {
            analytics.trackConversationActionButtonResponse("Done", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't mark this conversation as done
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });
    const markAsOpen = useMarkAsOpenMutation({
        onSuccess: () =>
            analytics.trackConversationActionButtonResponse("Reopen", false),
        onError: () => {
            analytics.trackConversationActionButtonResponse("Reopen", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't re-open this conversation
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });

    const onClickMarkAsDoneButton = () => {
        analytics.trackConversationActionButtonClick("Done");
        markAsDone.mutate(conversation);
    };
    const onClickMarkAsOpenButton = () => {
        analytics.trackConversationActionButtonClick("Reopen");
        markAsOpen.mutate(conversation);
    };

    switch (conversation.status) {
        case "Open":
            return (
                <Pill.Button
                    radius="full"
                    onClick={onClickMarkAsDoneButton}
                    disabled={markAsDone.isLoading}
                    aria-label="Mark conversation as done"
                    data-userflow-id={
                        userflowIds.conversationView.markDoneButton
                    }
                >
                    <Pill.Icon
                        name="Tick"
                        colour="green"
                        isLoading={markAsDone.isLoading}
                    />
                    <Pill.Text>{markDoneText}</Pill.Text>
                </Pill.Button>
            );

        case "Done":
            return (
                <StyledDoneButton
                    radius="full"
                    color={UI.Tokens.COLOURS.primary.green[10]}
                    onClick={onClickMarkAsOpenButton}
                    disabled={markAsOpen.isLoading}
                    aria-label="Mark conversation as not done"
                >
                    <Pill.Icon
                        name="Success"
                        colour="green"
                        theme="Fill"
                        isLoading={markAsOpen.isLoading}
                    />
                    <Pill.Text>Done</Pill.Text>
                    <Pill.Icon
                        name="Cross"
                        colour={markAsOpen.isLoading ? "stone" : "zinc"}
                    />
                </StyledDoneButton>
            );
        case "None":
            return null;
    }
};
