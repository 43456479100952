import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";
import {
    PatientRecordAttachmentsDto,
    PatientRecordAttachmentsDtoSchema,
} from "domains/native/schemas/PatientRecordAttachmentsDtoSchema";
import orderBy from "lodash/orderBy";

export type PatientRecordAttachmentsQueryPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

export const patientRecordAttachmentsQuery = createEndpoint<
    PatientRecordAttachmentsDto,
    PatientRecordAttachmentsQueryPayload
>("QueryPatientRecordAttachments", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QueryPatientRecordAttachments",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });

    return {
        attachments: orderBy(
            PatientRecordAttachmentsDtoSchema.parse(result).attachments,
            ["addedAt", "fileName"],
            ["desc", "asc"],
        ),
    };
});
