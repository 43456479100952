import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const BatchMessageTableWrapper = styled.div`
    max-width: ${Tokens.BREAKPOINTS.xl};
`;

export const PaginationWrapper = styled(Flex).attrs({
    justifyContent: "flex-end",
})`
    margin-top: ${Tokens.SIZES["2"]};
`;
