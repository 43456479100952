import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { usePatientRecordAttachmentsQuery } from "@accurx/native";
import { useShouldPrefetchPatientRecordAttachments } from "domains/inbox/hooks/util/useShouldPrefetchPatientRecordAttachments";

import { ReplyState } from "../../ConversationView.types";
import { NoteNew } from "../ReplyNew/NoteNew";
import { ReplyNew } from "../ReplyNew/ReplyNew";
import { StyledExpandableContainer } from "./MessageActionsNew.styles";

export const MessageActionsNew = ({
    conversation,
    onExpandClick,
    isExpanded,
    replyState,
    setReplyState,
    noteState,
    setNoteState,
    setSentNoteStatus,
}: {
    conversation: Conversation;
    onExpandClick: () => void;
    isExpanded: boolean;
    replyState: ReplyState;
    setReplyState: (state: ReplyState) => void;
    noteState: ReplyState;
    setNoteState: (state: ReplyState) => void;
    setSentNoteStatus: (state: string | null) => void;
}) => {
    const patient = usePatient({ patientId: conversation.regardingPatientId });

    const showNote = replyState !== "Open";
    const showReply = noteState !== "Open" && !!patient;

    // When the conversation opens we want to prefetch patient record
    // attachments if the medical record supports it because that request can
    // potentially take a looooong time so doing it early increases perceived
    // performance.
    const shouldPrefetchPatientRecordAttachments =
        useShouldPrefetchPatientRecordAttachments(patient?.externalIds ?? []);
    usePatientRecordAttachmentsQuery(
        {
            patientExternalIds: patient?.externalIds ?? [],
        },
        {
            enabled: !!patient && shouldPrefetchPatientRecordAttachments,
            refetchOnMount: true,
        },
    );

    return (
        <StyledExpandableContainer
            $isExpanded={isExpanded}
            $isFloating={replyState !== "Open" && noteState !== "Open"}
        >
            {showReply && (
                <ReplyNew
                    patient={patient}
                    conversation={conversation}
                    replyState={replyState}
                    setReplyState={setReplyState}
                    isExpanded={isExpanded}
                    onExpandClick={onExpandClick}
                />
            )}
            {showNote && (
                <NoteNew
                    patient={patient}
                    conversation={conversation}
                    noteState={noteState}
                    setNoteState={setNoteState}
                    setSentNoteStatus={setSentNoteStatus}
                    isExpanded={isExpanded}
                    onExpandClick={onExpandClick}
                />
            )}
        </StyledExpandableContainer>
    );
};
