import {
    DocumentUploadResponse,
    FloreyEnrolmentCanBeEnrolledRequest,
    FloreyEnrolmentCanBeEnrolledResponse,
    FloreyTemplatesResponse,
    FragmentUsageDashboardRequest,
    MessageTemplate,
    MessageTemplateCategoryResponse,
    MessageTemplateManagementView,
    MessageTemplateManagementViewResponse,
    MessageTemplateResponse,
    MessageTemplateUpdateSendTypeRequest,
    WorkspaceFragmentUsage,
} from "@accurx/api/patient-messaging";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

const ENDPOINTS = {
    messageTemplate: {
        view: "/api/patientmessaging/template/message/:organisationId/managementview",
        updateSendType:
            "/api/patientmessaging/template/message/:organisationId/updatesendtype",
        upsert: "/api/patientmessaging/template/message/:organisationId/createupdate",
        delete: "/api/patientmessaging/template/message/:organisationId/delete/:templateId",
        webpreset:
            "/api/patientmessaging/template/message/:organisationId/accurx/webpreset",
        byUser: "/api/patientmessaging/template/message/:organisationId/user",
        byOrganisation:
            "/api/patientmessaging/template/message/:organisationId/organisation",
        categories:
            "/api/patientmessaging/template/message/:organisationId/categories/:owner",
    },
    florey: {
        getTemplates: "/api/patientmessaging/floreytemplates",
        getTemplatesForBatch: "/api/patientmessaging/floreytemplates/batch",
        validateFloreyEnrolment:
            "/api/patientmessaging/floreyenrolmentschedulevalidation",
    },
    fragmentUsage: {
        dashboard: "/api/patientmessaging/fragmentusage/dashboard",
    },
    documentUpload: "/api/patientmessaging/documentupload",
} as const;

export type TemplatesViewRequest = {
    organisationId: number;
    includeVideo: boolean;
};
export const getTemplatesManagementView = ({
    organisationId,
    includeVideo,
}: TemplatesViewRequest): Promise<
    IWrappedResult<MessageTemplateManagementViewResponse>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.view,
            params: { organisationId: organisationId.toString() },
            query: `?includeVideo=${includeVideo}`,
        }),
    );

export const getTemplatesByUser = ({
    organisationId,
    includeVideo,
}: TemplatesViewRequest): Promise<IWrappedResult<MessageTemplateResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.byUser,
            params: { organisationId: organisationId.toString() },
            query: `?includeVideo=${includeVideo}`,
        }),
    );

export const getTemplatesByOrganisation = ({
    organisationId,
    includeVideo,
}: TemplatesViewRequest): Promise<IWrappedResult<MessageTemplateResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.byOrganisation,
            params: { organisationId: organisationId.toString() },
            query: `?includeVideo=${includeVideo}`,
        }),
    );

export type TemplateUpdateSendTypeRequest = {
    organisationId: string;
    // includeVideo: boolean;
    request: MessageTemplateUpdateSendTypeRequest | null;
};

export const updateSendType = ({
    organisationId,
    request,
}: TemplateUpdateSendTypeRequest): Promise<IWrappedResult<void>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.updateSendType,
            params: { organisationId },
        }),
        request,
    );

export const createUpdateMessageTemplate = ({
    organisationId,
    messageTemplate,
}: {
    organisationId: number | null;
    messageTemplate: MessageTemplate;
}): Promise<IWrappedResult<MessageTemplateManagementView>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.upsert,
            params: { organisationId: organisationId?.toString() || "" },
        }),
        messageTemplate,
    );

export const deleteMessageTemplate = ({
    organisationId,
    templateId,
}: {
    organisationId: string;
    templateId: string;
}): Promise<IWrappedResult<void>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.delete,
            params: { organisationId, templateId },
        }),
    );

export const getMessageTemplateCategories = ({
    organisationId,
    owner,
}: {
    organisationId: string;
    owner: "user" | "organisation";
}): Promise<IWrappedResult<MessageTemplateCategoryResponse>> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.categories,
            params: { organisationId, owner },
        }),
    );
};

export const getAccurxWebPresetTemplates = ({
    organisationId,
}: {
    organisationId: number | null;
}): Promise<IWrappedResult<MessageTemplateResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messageTemplate.webpreset,
            params: { organisationId: organisationId?.toString() ?? "" },
        }),
    );

export const getFloreyTemplates = ({
    organisationId,
    forBatch,
}: {
    organisationId: number | null;
    forBatch: boolean;
}): Promise<IWrappedResult<FloreyTemplatesResponse>> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            // Batch end point doesn't include unpublished custom floreys
            path: forBatch
                ? ENDPOINTS.florey.getTemplatesForBatch
                : ENDPOINTS.florey.getTemplates,
            query: `?organisationId=${organisationId}`,
        }),
    );
};

export const validateFloreyEnrolment = (
    payload: FloreyEnrolmentCanBeEnrolledRequest,
): Promise<IWrappedResult<FloreyEnrolmentCanBeEnrolledResponse>> => {
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.florey.validateFloreyEnrolment,
        }),
        payload,
    );
};

export const fragmentUsageDashboard = (
    payload: FragmentUsageDashboardRequest,
): Promise<IWrappedResult<WorkspaceFragmentUsage>> => {
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.fragmentUsage.dashboard,
        }),
        payload,
    );
};

export const documentUpload = async ({
    file,
    organisationId,
    origin,
}: {
    file: File;
    organisationId: number | null;
    origin: string;
}): Promise<IWrappedResult<DocumentUploadResponse>> => {
    const formData = new FormData();
    formData.append("formFile", file);
    return httpClient.postFileSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.documentUpload,
            query: `?organisationId=${organisationId}&origin=${origin}`,
        }),
        formData,
    );
};
