import React, { useState } from "react";

import { Switch } from "@accurx/design";
import { useDispatch } from "react-redux";

import { useIsUserApproved } from "store/hooks";

import { trackChangeAvailability } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { updateTemplateSendType } from "./ManageTemplates.actions";
import { StyledVisibilityHidden } from "./ManageTemplates.styles";
import { OnChangeSendType, SendType, Template } from "./ManageTemplates.types";

type SendTypeSwitchesType = {
    orgId: string;
    template: Template;
};

export const SendTypeSwitches = ({
    orgId,
    template,
}: SendTypeSwitchesType): JSX.Element => {
    const dispatch = useDispatch();
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const [isAllowedAsBatch, setIsAllowedAsBatch] = useState(
        template.isAllowedAsBatch,
    );
    const [isAllowedAsSms, setIsAllowedAsSms] = useState(
        template.isAllowedAsSms,
    );
    const isUserApproved: boolean = useIsUserApproved();

    const handleOnChangeSendType = ({
        sendType,
        templateId,
        owner,
        event,
    }: OnChangeSendType): void => {
        const isEnabled = event.target.checked;
        trackChangeAvailability({
            ...flemingLoggedInProps,
            templateLevel: owner,
            templateId: templateId,
            newValue: isEnabled,
            oldValue: !isEnabled,
            availabilityType: sendType,
        });
        if (sendType === SendType.Batch) {
            setIsAllowedAsBatch(isEnabled);
        }
        if (sendType === SendType.Sms) {
            setIsAllowedAsSms(isEnabled);
        }
        if (orgId) {
            dispatch(
                updateTemplateSendType({
                    organisationId: orgId,
                    request: { sendType, id: templateId, owner, isEnabled },
                }),
            );
        }
    };

    return (
        <>
            <Switch
                id={`individual-switch-${template.id}`}
                colour="green"
                labelText={
                    <>
                        {/* To provide unique labels when there are multiple in a page - making visibility hidden for design purposes. */}
                        <StyledVisibilityHidden>{`${template.title} Availability `}</StyledVisibilityHidden>
                        Individual
                    </>
                }
                size="small"
                controlled
                checked={isAllowedAsSms}
                disabled={!isUserApproved}
                onChange={(event) =>
                    handleOnChangeSendType({
                        event,
                        owner: template.owner,
                        sendType: SendType.Sms,
                        templateId: template.id,
                    })
                }
            />
            <Switch
                id={`batch-switch-${template.id}`}
                colour="green"
                labelText={
                    <>
                        {/* To provide unique labels when there are multiple in a page - making visibility hidden for design purposes. */}
                        <StyledVisibilityHidden>{`${template.title} Availability `}</StyledVisibilityHidden>
                        Batch
                    </>
                }
                size="small"
                controlled
                checked={isAllowedAsBatch}
                disabled={!isUserApproved}
                onChange={(event) =>
                    handleOnChangeSendType({
                        event,
                        owner: template.owner,
                        sendType: SendType.Batch,
                        templateId: template.id,
                    })
                }
            />
        </>
    );
};
