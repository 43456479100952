import { useEffect, useRef } from "react";

import { actions } from "domains/concierge/internal/store";

import { useConciergeDispatch } from "../context";

const INTERVAL =
    process.env.NODE_ENV === "development"
        ? 1000 * 60 * 1 // 1 minute in development
        : 1000 * 60 * 15; // 15 minutes otherwise

/*
 * Dispatches a reconcileState action every 15 minutes, or more
 * frequently in development.
 */
export const useStateReconciler = () => {
    const dispatch = useConciergeDispatch();
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                dispatch(actions.conversations.reconcileState());
            }, INTERVAL);
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [dispatch]);
};
