import * as UI from "@accurx/design";
import styled from "styled-components";

const { TYPOGRAPHY } = UI.Tokens;

export const StyledLink = styled(UI.Link)`
    display: inline-flex;
    align-items: center;

    font-size: ${TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${TYPOGRAPHY.preview.desktop.lineHeight};

    svg {
        width: 20px;
        height: 20px;
    }
`;
