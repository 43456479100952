import { PatientThreadTicketCommandResult } from "@accurx/api/ticket";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { PatientExternalId } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkTicketLabelAsWrittenToRecordOptions = {
    workspaceId: number;
    patientExternalIds: PatientExternalId[];
    itemId: string;
    writeToRecordSuccess: boolean;
};

export const markTicketLabelAsWrittenToRecord: ConciergeFetcher<
    MarkTicketLabelAsWrittenToRecordOptions,
    PatientThreadTicketCommandResult
> = async ({
    workspaceId,
    patientExternalIds,
    itemId,
    writeToRecordSuccess,
}) => {
    const response = await TicketApiClient.markTicketLabelAsWrittenToRecord({
        organisationId: workspaceId,
        patientExternalIdentity: {
            patientExternalIds:
                mapPatientSummaryExternalIdsToTicketPatientExternalIds(
                    patientExternalIds,
                ),
        },
        patientThreadItemId: itemId,
        writeToRecordSuccess,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);
    return { updates, response };
};
