import { Action, Reducer } from "redux";

import { PatientRecordViewRequest, RecordViewRequest } from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";

import { RESET_PRODUCT } from "../selectProduct/SelectProductActions";
import { getRecordWithoutEmptyInfo } from "./RecordView.helper";
import {
    GET_MEDICAL_RECORD,
    GET_MEDICAL_RECORD_NO_AUTH_STARTED,
    KnownAction,
    RESET_RECORD_ACCESS,
    SET_VIEW_MEDICAL_RECORD_ORIGIN,
} from "./RecordViewActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface RecordViewState {
    patient: PatientRecordViewRequest;
    isRecordNoAuthLoading: boolean;
    /*
    TODO: we probably don't need/want to maintain record and records in state,
    as they ought to be fetched over the network to ensure we are showing the
    most accurate data. Managing dynamic behaviours in state makes sense, but
    there is nothing dynamic about these. We want to fetch and show every time.
    */
    record: RecordViewRequest | null;
    medicalRecordFormat: "EmisXml" | "SystmOneXml" | null;
    /** For analytics purposes */
    viewMedicalRecordOrigin: FlemingAnalyticsTracker.MedicalRecordViewOrigin;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: RecordViewState = {
    patient: {
        patientToken: "null",
        organisationId: null,
        isGpOwnPatient: false,
    },
    isRecordNoAuthLoading: false,
    record: null,
    viewMedicalRecordOrigin:
        FlemingAnalyticsTracker.MedicalRecordViewOrigin.None,
    medicalRecordFormat: null,
};

export const reducer: Reducer<RecordViewState> = (
    state = initialState,
    incomingAction: Action,
): RecordViewState => {
    //If we re-select product then clear previous state
    if (incomingAction.type === RESET_PRODUCT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case GET_MEDICAL_RECORD_NO_AUTH_STARTED:
            return {
                ...state,
                isRecordNoAuthLoading: true,
            };

        case GET_MEDICAL_RECORD:
            const recordResult = { ...action.record };
            // Remove empty items if a valid record is pulled
            if (
                recordResult !== null &&
                recordResult.result !== null &&
                recordResult.result.medicalRecord
            ) {
                recordResult.result.medicalRecord = getRecordWithoutEmptyInfo(
                    recordResult.result.medicalRecord,
                );
            }

            return {
                ...state,
                isRecordNoAuthLoading: false,
                record: recordResult,
            };

        case SET_VIEW_MEDICAL_RECORD_ORIGIN:
            return {
                ...state,
                viewMedicalRecordOrigin: action.origin,
            };

        case RESET_RECORD_ACCESS:
            return initialState;
    }

    return state;
};
