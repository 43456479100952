import React from "react";

import { Text, Tokens } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    MessageTemplate,
    MessageTemplateOwner,
} from "app/batchMessage/gp/BatchMessage.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useAppSelector } from "store/hooks";

import { BatchMessageDropDownItem } from "./BatchMessageCompose.styles";
import { TemplatesByHeader } from "./BatchMessageCompose.types";

type ComposeTemplateDropdownItemProps = {
    templateGroup: MessageTemplate[];
    templateOwner: MessageTemplateOwner;
    templateTitle: string;
    templateHeader: TemplatesByHeader;
    isSelfBook: boolean;
    selectTemplate: (
        template: MessageTemplate,
        templateOwner: MessageTemplateOwner,
    ) => void;
};

export const ComposeTemplateDropdownItem = ({
    templateGroup,
    templateOwner,
    templateTitle,
    templateHeader,
    isSelfBook,
    selectTemplate,
}: ComposeTemplateDropdownItemProps): JSX.Element => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { selectedSlotType } = useAppSelector(
        ({ batchMessage }) => batchMessage,
    );

    const mapTemplateDropdownItems = (
        templatesByHeader: TemplatesByHeader,
        templateOwner: MessageTemplateOwner,
    ) => {
        const selfBookHeader = "Self-Book  >";
        //these non-selfbook headers are all sorted alphabetically
        const nonSelfbookTemplateHeaders = Object.keys(templatesByHeader)
            .filter((headerKey) => headerKey.slice(0, 12) !== selfBookHeader)
            .sort();
        //these Accurx selfbook headers are not supposed to be sorted alphabetically in selfbook flow and have a predefined arrangement
        const accurxSelfBookTemplateHeaders = Object.keys(
            templatesByHeader,
        ).filter((headerKey) => headerKey.slice(0, 12) === selfBookHeader);
        const customSortedTemplateHeaders = [
            ...accurxSelfBookTemplateHeaders,
            ...nonSelfbookTemplateHeaders,
        ];
        const allTemplateHeaders = isSelfBook
            ? customSortedTemplateHeaders
            : Object.keys(templatesByHeader).sort();
        return allTemplateHeaders.map((headerKey: string) => {
            return (
                <React.Fragment key={headerKey}>
                    {headerKey !== "" ? (
                        <BatchMessageDropDownItem $heading disabled={true}>
                            <Text skinny colour="green">
                                {headerKey}
                            </Text>
                        </BatchMessageDropDownItem>
                    ) : null}
                    {templatesByHeader[headerKey].map(
                        (template: MessageTemplate) => {
                            return (
                                <BatchMessageDropDownItem
                                    key={template.title}
                                    onClick={() => {
                                        selectTemplate(template, templateOwner);
                                        const snomedCode =
                                            template.snomedCodes.find(
                                                (code) => code.conceptId,
                                            )?.conceptId || "";
                                        ChainAnalyticsTracker.trackBatchComposeSnomedOptionClick(
                                            {
                                                ...analyticsLoggedInProps,
                                                slotName: selectedSlotType.type
                                                    ? selectedSlotType.type
                                                    : "Not selected",
                                                snomedCode,
                                                batchSnomedSelect: isSelfBook
                                                    ? "Booking - Invite"
                                                    : "SMS - Invite",
                                                origin: history.location
                                                    .pathname,
                                            },
                                        );
                                    }}
                                >
                                    <Text skinny>{template.title}</Text>
                                </BatchMessageDropDownItem>
                            );
                        },
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <>
            {templateGroup.length !== 0 && (
                <>
                    <BatchMessageDropDownItem
                        style={{
                            borderTop: `0.1rem solid ${Tokens.COLOURS.greyscale.silver}`,
                        }}
                        $heading
                        disabled={true}
                    >
                        <Text skinny variant="label" colour="green">
                            {templateTitle}
                        </Text>
                    </BatchMessageDropDownItem>
                    {mapTemplateDropdownItems(templateHeader, templateOwner)}
                </>
            )}
        </>
    );
};
