import React from "react";

import { Text } from "@accurx/design";

import { GpConsultation } from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type GpConsultationsProps = { gpConsultations: Array<GpConsultation> };

export const GpConsultations = ({
    gpConsultations,
}: GpConsultationsProps): JSX.Element | null => {
    const consultationRows = gpConsultations.map((consultation, i) => (
        <CardRow
            type={CardRowType.DefaultWithComments}
            key={`${i}-${consultation.date}`}
            label={formatMedicalRecordDate(consultation.date)}
            title={consultation.clinicianName || "Clinician name not provided"}
            comments={consultation.consultationItems || []}
        />
    ));

    return (
        <MedicalRecordPanelCard title="GP consultations (Last 3)">
            {consultationRows.length > 0 ? (
                <CardRowStack>{consultationRows}</CardRowStack>
            ) : (
                <Text skinny>No recorded GP consultations</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
