import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import FlemingApi from "api/FlemingApiClient";
import { IDocumentUploadResponse, IFileUploadRequest } from "api/FlemingDtos";

export const useUploadSmsFileMutation = createMutationHook<
    IFileUploadRequest,
    IDocumentUploadResponse
>(() => ({
    mutationKey: ["UploadSmsFile"],
    mutationFn: async ({ file, organisationId }) => {
        const response = await FlemingApi.documentUpload({
            file,
            organisationId,
        });
        return returnDataOrThrow(response);
    },
}));
