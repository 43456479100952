import { ComponentProps, HTMLAttributes, MouseEventHandler } from "react";

import * as UI from "@accurx/design";
import { Accordion, NavLinkWithToggle } from "@accurx/navigation";
import { Link, LinkProps } from "domains/inbox/components/Link/Link";
import { UnreadCount } from "domains/inbox/components/UnreadCount/UnreadCount";

type FolderLinkWithToggleProps = HTMLAttributes<HTMLDivElement> & {
    text: string;
    to: LinkProps["to"];
    isOpen?: boolean;
    isActive?: boolean;
    icon?: ComponentProps<typeof UI.Icon>;
    unreadCount?: number;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    toggleProps?: HTMLAttributes<HTMLButtonElement>;
};

export const FolderLinkWithToggle = ({
    text,
    to,
    isOpen,
    isActive,
    icon,
    unreadCount,
    onClick,
    toggleProps,
    ...containerProps
}: FolderLinkWithToggleProps) => {
    return (
        <NavLinkWithToggle isActive={isActive} {...containerProps}>
            <NavLinkWithToggle.Link asChild isActive={isActive}>
                <Link to={to} onClick={onClick}>
                    {!!icon && (
                        <UI.Item>
                            <UI.Icon colour="white" size={3} {...icon} />
                        </UI.Item>
                    )}
                    <UI.Item flex="1">{text}</UI.Item>
                    {!!unreadCount && (
                        <UI.Item>
                            <UnreadCount count={unreadCount} />
                        </UI.Item>
                    )}
                </Link>
            </NavLinkWithToggle.Link>
            <Accordion.Trigger asChild>
                <NavLinkWithToggle.Toggle {...toggleProps}>
                    <UI.Icon
                        name="Arrow"
                        colour="white"
                        size={2}
                        rotation={isOpen ? "down" : "right"}
                    />
                </NavLinkWithToggle.Toggle>
            </Accordion.Trigger>
        </NavLinkWithToggle>
    );
};
