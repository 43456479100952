import { useMemo } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    useMessageTemplatesQuery,
    useQuestionnaireTemplatesQuery,
} from "@accurx/message-templates";
import { useCompose } from "domains/compose/context";

import {
    MessageTemplateDataProps,
    MessageTemplateSelector,
    MessageTemplateSelectorProps,
} from "../components/MessageTemplateSelector/MessageTemplateSelector";
import { combineMessageAndQuestionnaireTemplates } from "../components/MessageTemplateSelector/utils/combineMessageAndQuestionnaireTemplates";

export const PatientMessageTemplateSelector = (
    props: MessageTemplateSelectorProps,
) => {
    const workspace = useCurrentWorkspace();
    const messageTemplatesQuery = useMessageTemplatesQuery({
        workspaceId: workspace.orgId,
    });
    const { state } = useCompose();

    const canUseQuestionnaires = state.canUseQuestionnaires;
    const questionnaireTemplatesQuery = useQuestionnaireTemplatesQuery(
        { workspaceId: workspace.orgId },
        { enabled: canUseQuestionnaires },
    );

    const combinedTemplates = useMemo(
        () =>
            combineMessageAndQuestionnaireTemplates({
                messageTemplates: messageTemplatesQuery.data,
                questionnaireTemplates: questionnaireTemplatesQuery.data,
            }),
        [messageTemplatesQuery.data, questionnaireTemplatesQuery.data],
    );

    const failedToLoad =
        messageTemplatesQuery.status === "error" ||
        questionnaireTemplatesQuery.status === "error";

    // When questionnaires are supported we want to wait for all templates to
    // have been fetched
    const isFetchingTemplates =
        messageTemplatesQuery.status === "loading" ||
        (canUseQuestionnaires &&
            questionnaireTemplatesQuery.status === "loading");

    const refetch = () => {
        if (messageTemplatesQuery.status === "error") {
            void messageTemplatesQuery.refetch();
        }

        if (
            canUseQuestionnaires &&
            questionnaireTemplatesQuery.status === "error"
        ) {
            void questionnaireTemplatesQuery.refetch();
        }
    };

    const getDataState = (): MessageTemplateDataProps => {
        if (failedToLoad) {
            return { status: "failed", refetch };
        }
        if (isFetchingTemplates) {
            return { status: "loading" };
        }
        return {
            status: "loaded",
            templates: combinedTemplates,
        };
    };

    return (
        <MessageTemplateSelector
            {...props}
            {...getDataState()}
            manageTemplatesLink={
                window.chrome?.webview
                    ? `/practices/${workspace.orgId}/manage-templates?tab=UserTemplates`
                    : `/w/${workspace.orgId}/settings/personal-templates`
            }
        />
    );
};
