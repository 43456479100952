import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: grid;
    white-space: nowrap;
`;

export const StyledFlex = styled(UI.Flex)`
    overflow: hidden;
`;

export const StyledNameText = styled(UI.Text)`
    text-overflow: ellipsis;
    overflow: hidden;

    position: relative;
    &:before {
        content: "";
        position: absolute;

        width: 100%;
        height: 100%;
    }
`;

export const StyledName = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
`;
