import { Participant } from "@accurx/concierge/types";

export const getParticipantName = (participant: Participant | null): string => {
    switch (participant?.type) {
        case "Workspace":
        case "Contact":
            return participant.displayName;
        default:
            return "Unknown";
    }
};
