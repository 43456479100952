import { Conversation, ConversationItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useIsActiveConversation } from "domains/inbox/hooks/util";
import { ItemStateWithReason } from "domains/inbox/hooks/util/useUpdatingFeed";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";

import {
    CONVERSATION_PREVIEW_HEIGHT,
    StyledContainer,
} from "../../ConversationPreview.styles";
import { ConversationPreviewLink } from "../PreviewLink/ConversationPreviewLink";
import { StateChangeOverlay } from "../StateChangeOverlay/StateChangeOverlay";

export const ConversationPreviewLayout = ({
    children,
    conversation,
    item,
    state,
    height = CONVERSATION_PREVIEW_HEIGHT,
}: {
    children: React.ReactNode;
    conversation: Conversation;
    item: ConversationItem;
    state: ItemStateWithReason;
    height?: number;
}) => {
    const isActive = useIsActiveConversation(conversation.id);
    return (
        <StyledContainer
            $state={state.current}
            $isActive={isActive}
            $height={height}
            data-testid={getConversationServerId(conversation)}
        >
            <UI.Card
                props={{ className: "conversation-item" }}
                spacing={1.5}
                isElevated={false}
            >
                {(state.current === "leaving" ||
                    state.current === "suspended") && (
                    <StateChangeOverlay reason={state.reason} />
                )}
                <ConversationPreviewLink
                    conversation={conversation}
                    item={item}
                >
                    {children}
                </ConversationPreviewLink>
            </UI.Card>
        </StyledContainer>
    );
};
