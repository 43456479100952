import { PatientSummary } from "@accurx/concierge/types";

export const checkIsPatientSummaryComplete = (patient?: PatientSummary) => {
    return (
        patient &&
        !!(
            (patient.familyName || patient.firstName) &&
            patient.dateOfBirth &&
            patient.demographicsLastRefreshed
        )
    );
};
