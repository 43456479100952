import React, { useEffect, useState } from "react";

import { Tokens } from "@accurx/design";

import { Breakpoints, matchesResponsiveBreakpoint } from "shared/breakpoints";

type Spacing = "small" | "medium" | "tiny";

export type CardSpacerProps = {
    spacing: Spacing;
};

const workOutSpacerHeight = (spacing: Spacing): string => {
    if (spacing === "tiny") {
        return Tokens.SIZES[1];
    }

    if (spacing === "small") {
        if (matchesResponsiveBreakpoint(Breakpoints.lg)) {
            return Tokens.SIZES[3];
        }
        if (matchesResponsiveBreakpoint(Breakpoints.md)) {
            return Tokens.SIZES[2];
        }
        return Tokens.SIZES[1.5];
    }

    if (spacing === "medium") {
        if (matchesResponsiveBreakpoint(Breakpoints.lg)) {
            return Tokens.SIZES[4];
        }
        if (matchesResponsiveBreakpoint(Breakpoints.md)) {
            return Tokens.SIZES[3];
        }
        return Tokens.SIZES[2];
    }

    return "0px";
};

export const CardSpacer = ({ spacing }: CardSpacerProps): JSX.Element => {
    const [spacerHeight, setSpacerHeight] = useState("0px");

    useEffect(() => {
        const resizeHandler = (): void => {
            setSpacerHeight(workOutSpacerHeight(spacing));
        };

        // Set size on load
        resizeHandler();

        window.addEventListener("resize", resizeHandler);

        return (): void => window.removeEventListener("resize", resizeHandler);
    }, [spacing]);

    return (
        <>
            <span className="d-block" style={{ height: spacerHeight }}></span>
        </>
    );
};
