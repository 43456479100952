import { Log } from "@accurx/shared";

export type SharedReference<T> = { item: T; release: () => void };

export type WorkspaceSingletonProvider<T> = {
    forWorkspace: (workspaceId: number) => SharedReference<T>;
    destroy: () => void;
};

/**
 * Tracks singleton instances for for a given workspace ID. This needs to exist to marry up the mix
 * of code we have today, where some newer components use route-based context while others are picking
 * up current workspace ID from the redux store.
 */
export const createWorkspaceSingletonProvider = <T>(
    name: string,
    fnCreate: (workspaceId: number) => T,
    fnDestroy: (instance: T) => void,
): WorkspaceSingletonProvider<T> =>
    new WorkspaceSingletonProviderImpl<T>(name, fnCreate, fnDestroy);

type ReferenceCounted<T> = { item: T; refCount: number };

class WorkspaceSingletonProviderImpl<T>
    implements WorkspaceSingletonProvider<T>
{
    private readonly tracked = new Map<string, ReferenceCounted<T>>();
    private isDestroyed = false;

    constructor(
        private name: string,
        private fnCreate: (workspaceId: number) => T,
        private fnDestroy: (instance: T) => void,
    ) {}

    forWorkspace(workspaceId: number): SharedReference<T> {
        const key = workspaceId.toString();

        if (this.isDestroyed) {
            this.error("Attempted use after destruction", key);
            throw new Error("Attempted use after destruction");
        }

        const existing = this.tracked.get(key);
        if (existing) {
            this.log("Returned existing", key);
            return this.addReference(key, existing);
        }

        const created = { item: this.fnCreate(workspaceId), refCount: 0 };
        const recheck = this.tracked.get(key);
        if (recheck) {
            // would be pretty odd code to call us within create, but let's protect against it!
            this.error("Recursion during creation", key);
            this.fnDestroy(created.item);
            return this.addReference(key, recheck);
        }

        this.log("Created", key);
        this.tracked.set(key, created);
        return this.addReference(key, created);
    }

    destroy() {
        if (this.isDestroyed) {
            this.error("Already destroyed");
        }
        this.isDestroyed = true;
        this.log("Destroyed");
    }

    private addReference(
        key: string,
        value: ReferenceCounted<T>,
    ): SharedReference<T> {
        value.refCount++;
        return {
            item: value.item,
            release: () => this.release(key, value.item),
        };
    }

    private release(key: string, instance: T) {
        const existing = this.tracked.get(key);
        if (!existing || existing.item !== instance) {
            // should only occur if there's a mismatched pair of create/release in callers
            this.error("Attempt to release untracked item", key);
            return;
        }
        existing.refCount--;
        if (existing.refCount === 0) {
            this.log("Cleanup as now unused", key);
            this.tracked.delete(key);
            this.fnDestroy(existing.item);
        }
    }

    private log(message: string, key = "(global)") {
        //Uncomment to track key lifecycle events in logs
        //Log.info(`${this.name}::${key}: ${message}`);
    }

    private error(message: string, key = "(global)") {
        Log.error(`${this.name}::${key}: ${message}`);
    }
}
