import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledConnectionErrorWrapper = styled(UI.Flex).attrs({
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5",
})`
    padding: ${UI.Tokens.SIZES[2]};

    // To get the slightly off-center display
    height: 60%;

    text-align: center;
`;

export const StyledFormsDescriptionText = styled(UI.Text).attrs({
    skinny: true,
})``;
