import { useEffect, useState } from "react";

import { useConversationManager } from "shared/concierge/conversations/context/ConversationManagerContext";
import {
    ConversationGroupRuleset,
    ConversationGroupSummary,
} from "shared/concierge/conversations/types/conversationGroup.types";
import {
    LoadingStatus,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";

export type UseConversationGroupSummaryResult =
    SubscriptionResult<ConversationGroupSummary> & { workspaceId: number };

/**
 * Returns conversation group data for the given ruleset, processes the initial data and responds to update events.
 */
export const useConversationGroupSummary = (
    ruleset: ConversationGroupRuleset | null,
): UseConversationGroupSummaryResult | null => {
    const manager = useConversationManager();
    const [state, setState] =
        useState<SubscriptionResult<ConversationGroupSummary>>(
            initialEmptyState,
        );

    useEffect(() => {
        if (ruleset) {
            const sub = manager.getConversationGroupSummary(ruleset);

            const result = sub.feed.subscribe(setState);

            return () => {
                result.unsubscribe();
            };
        }
    }, [manager, ruleset]);

    if (ruleset === null) {
        return null;
    }

    return {
        ...state,
        workspaceId: manager.workspaceId,
    };
};

/**
 * Returns workspace conversations data for the current user, processes the initial data and responds to update events.
 */
export const useWorkspaceConversationsSummary =
    (): UseConversationGroupSummaryResult => {
        const manager = useConversationManager();
        const [state, setState] =
            useState<SubscriptionResult<ConversationGroupSummary>>(
                initialEmptyState,
            );

        useEffect(() => {
            const sub = manager.getConversationGroupSummary({ rules: [] });

            const result = sub.feed.subscribe(setState);

            return () => {
                result.unsubscribe();
            };
        }, [manager]);

        return {
            ...state,
            workspaceId: manager.workspaceId,
        };
    };

const initialEmptyState: LoadingStatus = {
    status: "LOADING",
    data: null,
    errorMessage: null,
};
