import {
    ConversationItem as ClinicianMessagingConversationItem,
    ItemType,
} from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { ConversationItem } from "domains/concierge/types";

type ConversationItemIdentity = Pick<
    ClinicianMessagingConversationItem,
    "id" | "type"
>;

const regex = /^cmi-(\d+)-(\w+)$/;

export const mapConversationItemIdToClinicianMessagingItemIdentity = (
    id: ConversationItem["id"],
): ConversationItemIdentity | undefined => {
    const match = id?.match(regex);
    if (match) {
        return {
            type: parseInt(match[1], 10),
            id: match[2],
        };
    }
    Log.error(
        "Unable to map Clinician Messaging Item ID from Conversation ID",
        {
            tags: {
                product: "Inbox",
                id,
            },
        },
    );
    return undefined;
};

export const partitionClinicianMessagingItemIdentitiesByType = (
    identities: ConversationItemIdentity[],
): Record<ItemType, ClinicianMessagingConversationItem["id"][]> => {
    const partitionedIdentities: Record<
        ItemType,
        ClinicianMessagingConversationItem["id"][]
    > = {
        [ItemType.None]: [],
        [ItemType.OutgoingMessage]: [],
        [ItemType.IncomingMessage]: [],
        [ItemType.AssignedEvent]: [],
    };

    identities.forEach((identity) =>
        partitionedIdentities[identity.type].push(identity.id),
    );

    return partitionedIdentities;
};
