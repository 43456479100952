import { useEffect } from "react";

import { useBrowserEnvironment } from "@accurx/native";

// this hook prevent bounce scrolling effect when it's a WebView environment,
// that allows to scroll a bit out of a page in a ny side using touchpad.
// overflow:hidden, applied to body prevent this effect.
// overscroll-behavior: none is not supported in Edge browser, that we definetely would like to suppoert
export const useScrollBouncePrevent = () => {
    const env = useBrowserEnvironment();

    useEffect(() => {
        const body = document.querySelector<HTMLBodyElement>("body");

        if (env === "WebView" && body) {
            body.style.overflow = "hidden";
        }

        return () => {
            if (env === "WebView" && body) {
                body.style.overflow = "auto";
            }
        };
    }, [env]);
};
