import React from "react";

import { Tokens } from "@accurx/design";

import { SkeletonBlock } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { StyledLoadingSkeleton } from "../ConversationGroupLayout.styles";

/**
 * A component in charge of displaying a loading skeleton
 */
export const ConversationListItemLoadingSkeleton = (): JSX.Element => {
    return (
        <StyledLoadingSkeleton>
            <SkeletonBlock height={Tokens.SIZES[5]} width="100%" />
        </StyledLoadingSkeleton>
    );
};
