import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledUserActionsContainer = styled.span`
    display: inline-flex;
    justify-content: flex-end;
    width: 110px;
`;

export const StyledUnderlinedText = styled.span`
    color: ${Tokens.COLOURS.primary.blue[100]};
    text-decoration: underline;
`;
