import { useRef } from "react";

import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    ClinicianConversation,
    ClinicianConversationApi,
} from "api/ClinicianConversationApi";

export const CLINICIAN_CONVERSATION_BY_URL_ID_CACHE_KEY =
    "ClinicianConversationByUrlId";

type ClinicianConversationByUrlIdQueryVariables = {
    workspaceId: number | null;
    urlId: string;
};

export const useClinicianConversationByUrlIdQuery = createQueryHook<
    ClinicianConversationByUrlIdQueryVariables,
    ClinicianConversation & { hasPolled: boolean }
>(({ workspaceId, urlId }) => {
    const requestCount = useRef(0);
    return {
        cacheTime: 0,
        queryKey: [
            CLINICIAN_CONVERSATION_BY_URL_ID_CACHE_KEY,
            { workspaceId, urlId },
        ],
        queryFn: async () => {
            if (!workspaceId) {
                throw new Error("WorkspaceId missing from store");
            }
            const response = await ClinicianConversationApi.getConversation(
                urlId,
                workspaceId,
            );
            const data = returnDataOrThrow(response);

            requestCount.current = requestCount.current + 1;
            const hasPolled = requestCount.current > 1;

            return { ...data, hasPolled };
        },
    };
});
