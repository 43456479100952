import { useConciergeSelector } from "domains/concierge/internal/context";
import { getAllUnreadConversationItems } from "domains/concierge/internal/util/getAllUnreadConversationItems";
import { getConversation } from "domains/concierge/internal/util/getConversation";
import { ConversationItem } from "domains/concierge/types";

export const useConversationUnreadItems = ({
    conversationId,
    userId,
}: {
    conversationId: string;
    userId: string;
}): ConversationItem[] => {
    return useConciergeSelector((state) => {
        const conversation = getConversation(
            state.conversations,
            conversationId,
        );

        if (!conversation) {
            return [];
        }

        return getAllUnreadConversationItems(conversation, userId);
    });
};
