import { useCurrentWorkspace } from "@accurx/auth";
import { PatientSummary } from "@accurx/concierge/types";
import {
    DateHelpers,
    NhsNumberHelpers,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
    SearchForPatientSuccess,
    SearchForPatientSuccessSchema,
} from "domains/inbox/schemas/SearchForPatientSchema";
import { isTestPatient } from "domains/inbox/util";

type QueryVariables = {
    patient: PatientSummary;
    isUserAction?: boolean;
};

export const useSearchForPatientQuery = <T = SearchForPatientSuccess>({
    patient,
    isUserAction = false,
    enabled = true,
    select,
}: QueryVariables &
    UseQueryOptions<SearchForPatientSuccess, Error, T, QueryKey>) => {
    const workspaceId = useCurrentWorkspace().orgId;

    return useQuery({
        queryKey: [
            "searchForPatientByPDS",
            { externalIds: patient.externalIds },
        ],
        queryFn: async () => {
            const nhsNumber = patient.externalIds.find(
                (externalId) =>
                    externalId.type === "NhsNumber" &&
                    NhsNumberHelpers.validateNhsNumber(externalId.value)
                        .success,
            )?.value;
            const dobParts = DateHelpers.getDateParts(patient.dateOfBirth);
            const requestDto = {
                nhsNumber: nhsNumber,
                dateOfBirthYear: dobParts && dobParts.y,
                dateOfBirthMonth: dobParts && dobParts.m,
                dateOfBirthDay: dobParts && dobParts.d,
                organisationId: workspaceId,
                isTestPatient: isTestPatient(nhsNumber),
                isUserAction,
            };

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/portal/SearchForPatient",
                }),
                requestDto,
            );

            const responseParse = SearchForPatientSuccessSchema.safeParse(
                response.result,
            );

            if (responseParse.success) {
                return responseParse.data;
            }

            throw new Error(
                "Search for patient via /api/portal/SearchForPatient failed",
            );
        },
        enabled,
        select,
    });
};
