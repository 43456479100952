import * as UI from "@accurx/design";
import styled from "styled-components";

import { StyledPillProps, pillStyles } from "../../styles";

export const StyledBase = styled.div<StyledPillProps>`
    ${pillStyles}
    max-width: 100%;
`;

export const StyledBaseFlex = styled(UI.Flex)`
    max-width: 100%;
`;
