import { Log } from "@accurx/shared";

import { TicketItemIdentity } from "./mapTicketItemIdentityToConversationItemId";

const regex = /^ti-(\d+)-(\w+)$/;

/**
 * Provides the original server identity for a mapped conversation,
 * used for assign etc.
 */
export const mapConversationItemIdToTicketItemIdentity = (
    itemId: string,
): TicketItemIdentity | undefined => {
    const match = itemId?.match(regex);
    if (match) {
        return {
            type: parseInt(match[1], 10),
            id: match[2],
        };
    }
    Log.error("Unable to parse unique conversation item ID", {
        tags: {
            product: "Inbox",
            itemId,
        },
    });
    return undefined;
};
