import { ConversationItem, TeamSummary } from "domains/concierge/types";

/**
 * Returns true if the conversation item is unread by the requested user. For a
 * conversation item to be unread a few things need to be considered.
 *
 * Firstly the item must be "notifiable" to the user. An item is notifiable to
 * the user if one of the following is true:
 * 1. The conversation was open and assigned to the user at the time when the
 *    item was created.
 * 2. The conversation was open and assigned to a team the user was a member of
 *    when the item was created.
 *
 * If the item is notifiable to the user their user ID will appear in the item
 * read data. If they have read the item then there will be a "readAt" date for
 * that user. If the user's ID appears in the read data but there is no "readAt"
 * date then the item is notifiable to the user AND the user has not read the
 * item. Only under those circumstances will this function return true.
 */
export const isConversationItemUnreadForUser = (
    item: ConversationItem,
    userId: string,
): boolean => {
    if (
        !item.readDataByUserId ||
        !item.readDataByUserId[userId] ||
        item.readDataByUserId[userId].readAt !== null
    ) {
        return false;
    }

    return true;
};

/**
 * Returns true if the conversation item is unread by the requested user either
 * individually or within the context of a specific team. To calculate that a few things need to be
 * considered.
 *
 * Firstly the item must be "team notifiable" to the user or "directly notifiable".
 *
 * An item is team notifiable to the user if: The conversation was open and assigned to a team
 * the user was a member of when the item was created.
 *
 * If the item is notifiable to the user their user ID will appear in the item
 * read data. If the item is notifiable via a team, the team ID is also present
 * in the read data.
 *
 * This function only returns true if:
 * 1. The given user ID appears in the read data
 * 2. There is no team ID in the read data OR the given team ID appears in the read data for that user
 * 3. The read data does not contain a "readAt" timestamp
 */
export const isConversationItemUnreadForTeam = (
    item: ConversationItem,
    userId: string,
    teamId: TeamSummary["id"],
): boolean => {
    if (
        !item.readDataByUserId ||
        !item.readDataByUserId[userId] ||
        item.readDataByUserId[userId].readAt !== null
    ) {
        return false;
    }

    const byTeamId = item.readDataByUserId[userId].byTeamId;

    if (!byTeamId) {
        // If there is no team id, and this is directly notifiable to the user
        // we show this as notifiable within a team folder
        // This is necessary for "mark as unread" where a user is directly
        // notifiable on an item assigned to a team if they weren't previously
        // "team notifiable" on that note
        return true;
    }

    // If it is only unread in the context of a different team to the team
    // currently assigned, then it is not notifiable
    return !!byTeamId[teamId];
};
