import { Tabs, Text } from "@accurx/design";
import { Redirect, generatePath, useHistory, useParams } from "react-router";

import { BatchMessageOverview } from "app/batchMessage/gp/pages/BatchMessageOverview";
import {
    Breadcrumb,
    Breadcrumbs,
    Container,
    Header,
    Spinner,
} from "app/organisations/shared";
import { DelayedMessages } from "app/practices/delayedMessages/DelayedMessages";
import { DeliveryReceiptsContainer } from "app/practices/deliveryReceipts/DeliveryReceipts.container";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";

import { useCurrentUser, usePractices } from "../hooks";
import { TABS } from "./AllMessagesPage.constants";
import { useAllMessagesPage } from "./AllMessagesPage.hooks";
import { MainPanel, StyledSendBatchButton } from "./AllMessagesPage.styles";

export const AllMessagesPage = (): JSX.Element => {
    const practices = usePractices();
    const history = useHistory();
    const user = useCurrentUser();
    const { orgId } = useParams<{ orgId: string }>();

    const { tab, redirect } = useAllMessagesPage();
    if (!tab) return <Redirect to={redirect} />;
    if (practices.loadingStatus !== LoadingStatus.Loaded) return <Spinner />;

    const sendBatchClickHandler = () => {
        history.push(
            generatePath(ROUTES_CHAIN.practicesBatchMessagingChoose, {
                orgId,
            }),
            {
                from: history.location.pathname,
            },
        );
    };

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.allMessages, {
                orgId,
                tab: tabId,
            }),
        );
    };

    // Unapproved user should only see the Sent tab
    const TabsList = () => {
        if (user?.isApproved) {
            return TABS;
        } else {
            return TABS.filter((tab) => tab.text === "Sent");
        }
    };

    return (
        <Tabs selected={tab.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="All messages"
                        path={ROUTES_ORGS.allMessages}
                    />
                    <Breadcrumb
                        text={tab.text}
                        path={ROUTES_ORGS.allMessages}
                        params={{ tab: tab?.tabId }}
                    />
                </Breadcrumbs>

                <Text variant="title" as="h1" skinny>
                    All messages
                </Text>

                {user?.isApproved && (
                    <StyledSendBatchButton
                        onClick={sendBatchClickHandler}
                        icon={{
                            name: "Arrow",
                            colour: "white",
                            placement: "end",
                            rotation: "right",
                        }}
                        text="Send a batch message"
                    />
                )}

                <Header.TabList tabs={TabsList()} />
            </Header>
            <Container>
                <MainPanel>
                    <Tabs.Panel tabId="delivery-receipts">
                        <DeliveryReceiptsContainer noHeader />
                    </Tabs.Panel>
                    {user?.isApproved && (
                        <>
                            <Tabs.Panel tabId="delayed-messages">
                                <DelayedMessages noHeader />
                            </Tabs.Panel>
                            <Tabs.Panel tabId="batch-messages">
                                <br />
                                <BatchMessageOverview noHeader />
                            </Tabs.Panel>
                        </>
                    )}
                </MainPanel>
            </Container>
        </Tabs>
    );
};

export default AllMessagesPage;
