import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

import {
    LoginBoxShadow,
    MAX_LOGIN_BOX_WIDTH,
} from "../LogIn/LoginStackPanel.styles";

export const StyledPageWrapper = styled(Flex)`
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${Tokens.SIZES[3]};
    padding: ${Tokens.SIZES[3]};
`;

export const StyledFixedContainerWithShadow = styled.div`
    max-width: ${MAX_LOGIN_BOX_WIDTH + 100}px;
    width: 100%;
    ${LoginBoxShadow}
`;
