import { configureStore } from "@reduxjs/toolkit";
import { QueryResultLoading, Store } from "domains/native/types";
import mapValues from "lodash/mapValues";

import { loggerMiddleware } from "./loggerMiddleware";
import * as reducer from "./reducers";

export const initialState = mapValues(
    reducer,
    (): QueryResultLoading => ({ status: "loading" }),
);

export const store: Store = configureStore({
    reducer: { ...reducer },
    devTools:
        process.env.NODE_ENV !== "production"
            ? { name: "Native Subscriptions" }
            : false,
    middleware: [loggerMiddleware],
});
