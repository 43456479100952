import { SubscribeCurrentPatientDtoSchema } from "domains/native/schemas/SubscribeCurrentPatientDtoSchema";

import { createSubscriptionReducer } from "./createSubscriptionReducer";

export const reducer = createSubscriptionReducer({
    name: "SubscribeCurrentPatient",
    schema: SubscribeCurrentPatientDtoSchema.transform(
        (dto) => dto.patientDemographics ?? null,
    ),
});
