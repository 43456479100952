/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { ChangeEvent, MouseEvent, useMemo, useRef } from "react";

import {
    AttachmentUpload,
    Button,
    Feedback,
    FileUploadRequest,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { toast } from "react-toastify";

import FlemingApiClient from "api/FlemingApiClient";
import { TemplateAttachment } from "api/FlemingDtos";
import {
    FILE_UPLOAD_ACCEPT_FORMATS,
    FILE_UPLOAD_MAX_FILES,
    FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE,
} from "app/workspaceConversations/components/Conversation/ComposeArea/ComposeArea.constants";
import { pluralize } from "shared/formatters/Pluralize";

import { validateTemplateAttachment } from "./MessageTemplatesHelper";

type TemplateAttachmentInputProps = {
    setAttachmentError: (error: string) => void;
    organisationId: number;
    handleFilesUpload: (uploadedFiles: AttachmentUpload[]) => void;
    isUploading: boolean;
    setIsUploading: (isUploading: boolean) => void;
    attachments: TemplateAttachment[];
};
export const TemplateAttachmentInput = ({
    setAttachmentError,
    organisationId,
    handleFilesUpload,
    attachments,
    isUploading,
    setIsUploading,
}: TemplateAttachmentInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null!);
    const userCanAttachFile = useMemo(
        () => attachments.length < FILE_UPLOAD_MAX_FILES,
        [attachments],
    );

    const buttonClickUpload = () => {
        setAttachmentError("");
        inputRef.current.click();
    };

    const uploadFiles = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files === null) return;

        const validFiles = Array.from(e.target.files).filter((file) => {
            const { success, failureReasons } =
                validateTemplateAttachment(file);

            setAttachmentError(failureReasons.join(". "));

            return success;
        });

        const uploadFileRequest = (request: FileUploadRequest) =>
            FlemingApiClient.uploadTemplateAttachment({
                file: request.file,
                organisationId: organisationId.toString(),
            });

        const uploadedFiles: AttachmentUpload[] = [];
        setIsUploading(true);
        for (const file of validFiles) {
            try {
                const { result } = await uploadFileRequest({
                    file,
                });

                if (result?.id) {
                    uploadedFiles.push({
                        attachmentId: result?.id,
                        file,
                        dataUrl: result.link,
                    });
                }
            } catch (error) {
                Log.warn("Unable to upload user file in template creation", {
                    originalException: error,
                });
                toast(
                    <Feedback title="Unable to upload file" colour="error">
                        <Text>Please try again</Text>
                    </Feedback>,
                );
            }
        }
        setIsUploading(false);
        handleFilesUpload(uploadedFiles);
    };

    return (
        <>
            {userCanAttachFile && (
                <Button
                    text={isUploading ? "Attaching a file..." : "Attach a file"}
                    theme="secondary"
                    icon={{ name: "Paperclip" }}
                    onClick={buttonClickUpload}
                    disabled={isUploading}
                />
            )}
            <input
                ref={inputRef}
                type="file"
                multiple={FILE_UPLOAD_MAX_FILES > 1}
                accept={FILE_UPLOAD_ACCEPT_FORMATS}
                id="templateAttachmentInput"
                data-testid="templateAttachmentInput"
                onChange={uploadFiles}
                // Need to reset this on click in case we want to upload the same file twice
                onClick={(event: MouseEvent<HTMLInputElement>) => {
                    event.currentTarget.value = "";
                }}
                style={{ display: "none" }}
                disabled={isUploading}
            />
            <Text>
                You can attach{" "}
                {pluralize({
                    count: FILE_UPLOAD_MAX_FILES,
                    forms: { singular: "file", plural: "files" },
                })}
                , with max size {FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE} in total.
            </Text>
        </>
    );
};
