import { Card, Spinner, Text } from "@accurx/design";

import { OverviewData } from "./FragmentsDashboard.types";

type OverviewProps = {
    data: OverviewData;
    isLoading: boolean;
};
export const Overview = ({ data, isLoading }: OverviewProps) => {
    return (
        <Card
            header={
                <Text as="h2" variant="subtitle">
                    Fragments usage overview
                </Text>
            }
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <Text skinny>
                        <Text as="span" variant="label">
                            {`Number of messages for given date range: `}
                        </Text>
                        {data.numberOfMessages}
                    </Text>
                    <Text skinny>
                        <Text as="span" variant="label">
                            {`Number of fragments for given date range: `}
                        </Text>
                        {data.numberOfFragments}
                    </Text>
                </>
            )}
        </Card>
    );
};
