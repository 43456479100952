/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useState } from "react";

import { Card, Feedback, Spinner, Text } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { LoadingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import { VaccineApprovalDropdown } from "./ApprovalDropdown";
import { VaccineApprovalModal } from "./ApprovalModal";
import {
    getPendingApprovalRequests,
    postApprovalUpdate,
} from "./ApprovalRequests.actions";
import { initialState as VaccineApprovalInitialState } from "./ApprovalRequests.reducer";
import {
    VaccineApprovalUpdate,
    VaccinePracticeLink,
} from "./ApprovalRequests.types";

export const VaccineApprovalRequests = ({
    noHeader = false,
}: {
    noHeader?: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();

    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices?.selectedPractice,
        shallowEqual,
    );

    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const {
        vaccineSiteLinks,
        vaccineSitesLoading,
        vaccineSitesFetchError,
        approvalRequestsUpdating,
        approvalRequestUpdateError,
        leaveRequestUpdating,
        leaveRequestError,
    } = useSelector(
        ({ vaccineApprovalRequests }: any) =>
            vaccineApprovalRequests || VaccineApprovalInitialState,
    );

    const [approvalModalOpen, setApprovalModalOpen] = useState<boolean>(false);
    const [vaccineApprovalUpdate, setVaccineApprovalUpdate] =
        useState<VaccineApprovalUpdate | null>(null);

    useEffect(() => {
        // Load VaccineApprovalRequests data
        if (vaccineSitesLoading === LoadingStatus.Initial && practiceId) {
            dispatch(getPendingApprovalRequests(practiceId));
        }
    }, [dispatch, practiceId, vaccineSitesLoading]);

    const handleModelPublish = () => {
        if (vaccineApprovalUpdate) {
            dispatch(postApprovalUpdate(practiceId, vaccineApprovalUpdate));
        }
        handleToggleModel(false, null);
    };

    const handleToggleModel = (
        modelOpen: boolean,
        approvalUpdate: VaccineApprovalUpdate | null,
    ): void => {
        setApprovalModalOpen(modelOpen);
        setVaccineApprovalUpdate(approvalUpdate);
    };

    // const handleLeave = (recallSetupId: number): void => {
    //     dispatch(tryLeaveDeliverySite(practiceId, { recallSetupId }));
    // };

    const ApprovalRow = ({
        name,
        nationalCode,
        siteLocation,
        recallSetupId,
    }: VaccinePracticeLink): JSX.Element => {
        return (
            <tr>
                <td>{name}</td>
                <td style={{ width: "300px" }}>{nationalCode}</td>
                <td>{siteLocation}</td>
                <td style={{ width: "50px" }} className="text-right">
                    <VaccineApprovalDropdown
                        onAction={(approved): void =>
                            handleToggleModel(true, { recallSetupId, approved })
                        }
                    />
                </td>
            </tr>
        );
    };

    const DeliverySiteRow = ({
        name,
        nationalCode,
        siteLocation,
    }: VaccinePracticeLink): JSX.Element => {
        return (
            <tr>
                <td>{name}</td>
                <td>{nationalCode}</td>
                <td>{siteLocation}</td>
                <td className="text-right">
                    {/* <VaccineApprovalDropdown
                        onActionLeave={(): void => handleLeave(recallSetupId)}
                    /> */}
                </td>
            </tr>
        );
    };

    if (
        vaccineSitesFetchError ||
        leaveRequestError ||
        approvalRequestUpdateError
    ) {
        return (
            <Feedback
                colour="error"
                title={
                    vaccineSitesFetchError ||
                    leaveRequestError ||
                    approvalRequestUpdateError
                }
                content=""
            />
        );
    }

    if (
        vaccineSitesLoading === LoadingStatus.Initial ||
        vaccineSitesLoading === LoadingStatus.Loading ||
        approvalRequestsUpdating ||
        leaveRequestUpdating
    ) {
        return <Spinner />;
    }

    return (
        <>
            {!noHeader && (
                <>
                    <NavSubMenuComponent>
                        <Breadcrumb
                            title="Linked Delivery Sites"
                            wrapper={false}
                        />
                        <Text variant="title" as="h1" skinny className="mt-2">
                            COVID-19 Vaccine
                            {practiceName && (
                                <Text
                                    variant="subtitle"
                                    as="span"
                                    props={{ className: "mx-1" }}
                                    skinny
                                >
                                    {` · ${practiceName}`}
                                </Text>
                            )}
                        </Text>
                    </NavSubMenuComponent>
                    <Text variant="subtitle" as="h2">
                        Linked delivery sites
                    </Text>
                </>
            )}
            <Text variant="subtitle" as="h3">
                Current sites
            </Text>
            <Text>
                This shows the delivery sites you are a member of. Any patients
                you invite will see these locations to choose from to book their
                appointments.
                <b>
                    {" "}
                    Your invited patients will also count towards the capacity
                    of these sites.
                </b>
            </Text>
            <Card spacing={0}>
                <table className="table mb-0" data-testid="table-approved">
                    <thead>
                        <tr>
                            <Text as="th" variant="label">
                                Name
                            </Text>
                            <Text as="th" variant="label">
                                ODS Code
                            </Text>
                            <Text as="th" variant="label">
                                Site Location
                            </Text>
                            <Text as="th" variant="label">
                                Actions
                            </Text>
                        </tr>
                    </thead>
                    <tbody>
                        {vaccineSiteLinks?.deliverySites?.map(
                            (x: VaccinePracticeLink) => (
                                <DeliverySiteRow
                                    key={x.recallSetupId}
                                    name={x.name}
                                    nationalCode={x.nationalCode}
                                    siteLocation={x.siteLocation}
                                    recallSetupId={x.recallSetupId}
                                />
                            ),
                        )}
                    </tbody>
                </table>
            </Card>
            <br />
            <Text variant="subtitle" as="h3">
                Pending approval requests
            </Text>
            <Text>
                Once you accept a request from a delivery site, your patients{" "}
                <b>
                    will see all published clinics at this location to book
                    into.
                </b>{" "}
                Please make sure you are joining the right delivery site before
                accepting, as this cannot easily be undone.
            </Text>
            <Card spacing={0} props={{ className: "mb-5" }}>
                <table className="table mb-0" data-testid="table-approval">
                    <thead>
                        <tr>
                            <Text as="th" variant="label">
                                Name
                            </Text>
                            <Text as="th" variant="label">
                                ODS Code
                            </Text>
                            <Text as="th" variant="label">
                                Site Location
                            </Text>
                            <Text as="th" variant="label">
                                Actions
                            </Text>
                        </tr>
                    </thead>
                    <tbody>
                        {vaccineSiteLinks?.approvalRequests?.map(
                            (x: VaccinePracticeLink) => (
                                <ApprovalRow
                                    key={x.recallSetupId}
                                    name={x.name}
                                    nationalCode={x.nationalCode}
                                    siteLocation={x.siteLocation}
                                    recallSetupId={x.recallSetupId}
                                />
                            ),
                        )}
                    </tbody>
                </table>
            </Card>
            <VaccineApprovalModal
                isApproving={vaccineApprovalUpdate?.approved ?? false}
                showApprovalModal={approvalModalOpen}
                onToggleModal={(): void => handleToggleModel(false, null)}
                onApprove={handleModelPublish}
            />
        </>
    );
};

export default VaccineApprovalRequests;
