import { Button, Card, Ds, Icon, Text } from "@accurx/design";
import { useDispatch } from "react-redux";

import { BulkAddErrors } from "api/FlemingDtos";
import { useAppSelector } from "store/hooks";

import { actionCreators as patientListsActionCreators } from "../PatientListsActions";

const PatientListBulkAddPatientErrorsCard = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const currentListBulkAddErrors = useAppSelector(
        ({ patientLists }) => patientLists.currentListBulkAddErrors,
    );

    const handleClick = (): void => {
        dispatch(patientListsActionCreators.resetBulkAddPatientsErrors());
    };

    const getBulkAddErrorText = (bulkAddError: BulkAddErrors): string => {
        switch (bulkAddError) {
            case BulkAddErrors.DuplicateCsvEntry:
                return "Duplicate CSV entry";
            case BulkAddErrors.InvalidDateOfBirth:
                return "Invalid date of birth";
            case BulkAddErrors.InvalidNhsNumber:
                return "Invalid NHS number";
            case BulkAddErrors.PatientNotFound:
                return "Patient not found";
            case BulkAddErrors.InvalidAppointmentTime:
                return "Invalid appointment date or time";
            default:
                return "There was an error searching for this patient";
        }
    };

    const numberBulkErrors = currentListBulkAddErrors.length;

    if (numberBulkErrors === 0) return null;
    return (
        <Card
            header={
                <Text variant="label" props={{ className: "my-0" }}>
                    <Icon
                        name="Warning"
                        theme="Fill"
                        size={3}
                        props={{ className: "mb-1" }}
                    />{" "}
                    {numberBulkErrors} patient{" "}
                    {numberBulkErrors > 1 ? "entries" : "entry"} not added or
                    updated
                </Text>
            }
            footer={<Button text="Ok, got it" onClick={handleClick} />}
        >
            <>
                <Text variant="body">
                    Check CSV file for the below errors. Re-upload the whole CSV
                    once these errors are fixed.
                </Text>
                {currentListBulkAddErrors.length > 0 &&
                    currentListBulkAddErrors.map((bulkAddError, i) => {
                        return (
                            <div key={`bulkError${i}`}>
                                <Card
                                    props={{
                                        className:
                                            i <
                                            currentListBulkAddErrors.length - 1
                                                ? "mb-3"
                                                : "",
                                    }}
                                    spacing={2}
                                >
                                    <div className="d-md-flex justify-content-between align-items-center overflow-hidden">
                                        <Text
                                            variant="body"
                                            props={{
                                                className:
                                                    "my-0 mr-md-4 d-flex flex-wrap",
                                            }}
                                        >
                                            <span className="mr-4 text-md-nowrap">
                                                NHS: {bulkAddError.nhsNumber}
                                            </span>
                                            <span className="mr-4 text-md-nowrap">
                                                Born: {bulkAddError.dateOfBirth}
                                            </span>
                                            {bulkAddError.appointmentTime && (
                                                <span className="text-md-nowrap">
                                                    Appointment:{" "}
                                                    {
                                                        bulkAddError.appointmentTime
                                                    }
                                                </span>
                                            )}
                                        </Text>
                                        <Ds.Badge className="text-nowrap">
                                            {getBulkAddErrorText(
                                                bulkAddError.errorDetails,
                                            )}
                                        </Ds.Badge>
                                    </div>
                                </Card>
                            </div>
                        );
                    })}
            </>
        </Card>
    );
};

export default PatientListBulkAddPatientErrorsCard;
