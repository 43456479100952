import React from "react";

import { AttachFileRow } from "app/workspaceConversations/components/Conversation/AttachFileRow/AttachFileRow";
import { TemplateAttachmentDisplay } from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";

type TemplateAttahcmentsListProps = {
    attachments: TemplateAttachmentDisplay[];
    handleFileRemove: (idToRemove: string) => void;
};

export const TemplateAttahcmentsList = ({
    attachments,
    handleFileRemove,
}: TemplateAttahcmentsListProps) => (
    <>
        {attachments.map(({ documentId, fileName, fileSize, previewUrl }) => (
            <AttachFileRow
                key={`${documentId}-${fileName}`}
                attachmentId={documentId}
                previewUrl={previewUrl}
                fileName={fileName}
                fileSize={fileSize}
                handleRemoveFile={() => handleFileRemove(documentId)}
                isControllable
            />
        ))}
    </>
);
