import React from "react";

import { Cell, Grid } from "@accurx/design";

import {
    StyledNumPadContainer,
    StyledNumpadButton,
    StyledNumpadButtonLetters,
    StyledNumpadButtonValue,
} from "./Voip.styles";

type NumberButton = {
    value: string;
    letters?: string;
};

const buttons: NumberButton[] = [
    { value: "1" },
    { value: "2", letters: "abc" },
    { value: "3", letters: "def" },
    { value: "4", letters: "ghi" },
    { value: "5", letters: "jkl" },
    { value: "6", letters: "mno" },
    { value: "7", letters: "pqrs" },
    { value: "8", letters: "tuv" },
    { value: "9", letters: "wxyz" },
    { value: "*" },
    { value: "0" },
    { value: "#" },
];

type NumPadProps = {
    onInput: (value: string) => void;
};

export const NumPad = (props: NumPadProps): JSX.Element => {
    return (
        <StyledNumPadContainer>
            <Grid columns="repeat(3, 1fr)" gap="1">
                {buttons.map((button) => (
                    <Cell key={button.value}>
                        <NumPadButton
                            onClick={() => props.onInput(button.value)}
                            value={button.value}
                            letters={button.letters}
                        />
                    </Cell>
                ))}
            </Grid>
        </StyledNumPadContainer>
    );
};

type NumPadButtonProps = {
    value: string;
    onClick: () => void;
    letters?: string;
};

const NumPadButton = (props: NumPadButtonProps) => {
    return (
        <StyledNumpadButton type="button" onClick={props.onClick}>
            <StyledNumpadButtonValue>{props.value}</StyledNumpadButtonValue>
            {props.letters && (
                <StyledNumpadButtonLetters>
                    {props.letters}
                </StyledNumpadButtonLetters>
            )}
        </StyledNumpadButton>
    );
};
