import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    FormPdsByDemographics,
    formPdsByDemographicsId,
} from "domains/inbox/components/PatientSearch/components/forms/FormPdsByDemographics";
import {
    FormPdsByNhsNumber,
    formPdsByNumberId,
} from "domains/inbox/components/PatientSearch/components/forms/FormPdsByNhsNumber";
import { PatientMatchLayout } from "domains/inbox/components/PatientSearch/components/forms/PatientMatchLayout";
import { FormPdsResult } from "domains/inbox/components/PatientSearch/components/forms/types";
import { Tabs } from "domains/inbox/components/Tabs";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";

import {
    StyledDivider,
    StyledFormsDescriptionText,
    StyledPatientMatchSpacer,
    StyledTabButtonContainer,
} from "./PatientMatch.styles";

type TabType = "byNhsNumber" | "byDemographics";

type PatientMatchPdsProps = {
    onResultsFound: ({ patient, patientToken }: FormPdsResult) => void;
    conversation: Conversation;
};

const tabIdToFormId: Record<TabType, string> = {
    byDemographics: formPdsByDemographicsId,
    byNhsNumber: formPdsByNumberId,
};

const DEFAULT_TAB: TabType = "byNhsNumber";

export const PatientMatchPds = ({
    onResultsFound,
    conversation,
}: PatientMatchPdsProps) => {
    const track = useAnalytics();
    const [isLoading, setIsLoading] = useState(false);
    const [formId, setFormId] = useState<string>(tabIdToFormId[DEFAULT_TAB]);

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "ConversationActions",
    });

    function assertIsTabType(type: unknown): asserts type is TabType {
        if (["byNhsNumber", "byDemographics"].some((id) => id === type)) return;
        throw new Error("Invalid Pds form type");
    }

    const handleTabChange = (tabId: string): void => {
        assertIsTabType(tabId);
        setFormId(tabIdToFormId[tabId]);
    };

    return (
        <PatientMatchLayout
            isLoading={isLoading}
            buttonProps={{ form: formId }}
        >
            <>
                <StyledPatientMatchSpacer>
                    <UI.Text as="h2" variant="label" skinny>
                        Match patient to conversation
                    </UI.Text>
                </StyledPatientMatchSpacer>
                <UI.Tabs
                    defaultSelected={DEFAULT_TAB}
                    onTabChange={handleTabChange}
                >
                    <StyledTabButtonContainer>
                        <Tabs.Button.ListAndItems
                            tabs={[
                                {
                                    tabId: "byNhsNumber",
                                    text: "Search by NHS no.",
                                },
                                {
                                    tabId: "byDemographics",
                                    text: "Search by name",
                                },
                            ]}
                        />
                    </StyledTabButtonContainer>
                    <StyledDivider />
                    <StyledPatientMatchSpacer>
                        <UI.TabPanel tabId="byNhsNumber">
                            <StyledFormsDescriptionText>
                                Search for patients by their date of birth and
                                NHS number. Both are required to find a patient
                                to match with.
                            </StyledFormsDescriptionText>
                            <FormPdsByNhsNumber
                                onResultsFound={onResultsFound}
                                onSearchClick={({ hasError }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Click",
                                        { ...trackingFields, hasError },
                                    )
                                }
                                onSearchSettled={({ hasError }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Response",
                                        {
                                            ...trackingFields,
                                            hasError,
                                            countResult: 1,
                                        },
                                    )
                                }
                                setIsLoading={setIsLoading}
                            />
                        </UI.TabPanel>
                        <UI.TabPanel tabId="byDemographics">
                            <StyledFormsDescriptionText>
                                Search for patients by their name, date of
                                birth, gender, and post code. All fields are
                                required to find a patient to match with.
                            </StyledFormsDescriptionText>
                            <FormPdsByDemographics
                                onResultsFound={onResultsFound}
                                onSearchClick={({ hasError }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Click",
                                        { ...trackingFields, hasError },
                                    )
                                }
                                onSearchSettled={({ hasError }) =>
                                    track(
                                        "ConversationPatientMatchSearch Button Response",
                                        {
                                            ...trackingFields,
                                            hasError,
                                            countResult: 1,
                                        },
                                    )
                                }
                                setIsLoading={setIsLoading}
                            />
                        </UI.TabPanel>
                    </StyledPatientMatchSpacer>
                </UI.Tabs>
            </>
        </PatientMatchLayout>
    );
};
