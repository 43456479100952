import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import moment from "moment-timezone";

export const formatDateTime = (s: string): string => {
    return DateHelpers.formatDate(
        s,
        DateFormatOptions.HUMAN_READABLE_DATE_TIME,
    );
};

const formatCalendarDateTime = (
    dateToFormat: string,
    dateDisplayFormat: "ddd D MMM" | "dddd D MMMM",
    referenceDate?: string,
) => {
    const isSameYear = moment(dateToFormat).isSame(
        referenceDate ?? new Date(),
        "year",
    );
    const overOneDayFormat = isSameYear
        ? dateDisplayFormat
        : DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT;

    // 'moment' allows us to format specific timeframes (e.g. same day, yesterday, last week, over a week, etc.)
    return moment(dateToFormat).calendar(referenceDate, {
        sameDay: DateFormatOptions.NHS_TIME_SHORT_12_HR_DOT,
        lastDay: "[Yesterday]",
        lastWeek: overOneDayFormat,
        sameElse: overOneDayFormat,
    });
};

/**
 * Formats the date/time to align with Web & NHS.uk guidelines.
 *
 * Formats to the following examples:
 * - Today: 10:00am
 * - Yesterday: Yesterday
 * - Within current year: Sun 1 Jan
 * - Previous year(s): 31 Dec 2022
 *
 * If 'referenceDate' is not provided, the current date/time is used by default.
 */
export const formatNHSDateTime = (
    dateToFormat: string,
    referenceDate?: string,
) => formatCalendarDateTime(dateToFormat, "ddd D MMM", referenceDate);

/**
 *  Formats the date/time mostly following the NHS guidelines but with a small update to within current year
 * this is only meant to be used for screen reader users
 *
 * Formats to the following examples:
 * - Today: 10:00am
 * - Yesterday: Yesterday
 * - Within current year: Sunday 1 January
 * - Previous year(s): 31 Dec 2022
 */
export const formatSRDateTime = (
    dateToFormat: string,
    referenceDate?: string,
) => formatCalendarDateTime(dateToFormat, "dddd D MMMM", referenceDate);

export const formatTwelveHourTime = (dateToFormat: string) =>
    DateHelpers.formatTime(dateToFormat, "h.mma");

export const getTimeDiffInMinutes = (startDate: string, endDate: string) => {
    return DateHelpers.getTimeDiffInMinutes(startDate, endDate);
};
