import { useCurrentWorkspace } from "@accurx/auth";
import { Feedback, Link } from "@accurx/design";
import { ROUTES_INBOX } from "@accurx/inbox";
import { generatePath } from "react-router";

export const NewInboxBanner = () => {
    const workspaceId = useCurrentWorkspace().orgId;
    const newInboxLink = generatePath(ROUTES_INBOX.CareProvidersMyInbox, {
        workspaceId,
    });

    return (
        <Feedback colour="learning">
            Your Message GP conversations are available in a new and improved
            inbox, found in the "Conversations" tab. This page will be removed
            on the 27th of August.{" "}
            <Link href={newInboxLink}>
                Click here to view the new inbox experience
            </Link>
            .
        </Feedback>
    );
};
