import { ReactNode } from "react";

import { Button, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useHistory } from "react-router-dom";

import {
    RecordViewRequestResult,
    RecordViewResultStatus,
} from "api/FlemingDtos";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";

import { getRequestRemainingHoursText } from "../RecordView.helper";
import { RecordStatusBadge } from "./MedicalRecordStatuses";
import { StyledTable } from "./MedicalRecordsTable.styles";

const ColumnHeding = ({ children }: { children: ReactNode }): JSX.Element => (
    <Text variant="label" as="th" props={{ scope: "col" }}>
        {children}
    </Text>
);
const Cell = ({ children }: { children: ReactNode }): JSX.Element => (
    <Text as="td" props={{ className: "text-nowrap" }}>
        {children}
    </Text>
);

export type MedicalRecordsTableProps = {
    records: Array<RecordViewRequestResult>;
};

export const MedicalRecordsTable = ({
    records,
}: MedicalRecordsTableProps): JSX.Element => {
    return (
        <StyledTable bordered responsive>
            <thead>
                <tr>
                    <ColumnHeding>NHS number</ColumnHeding>
                    <ColumnHeding>Date requested</ColumnHeding>
                    <ColumnHeding>Status</ColumnHeding>
                    <ColumnHeding>Action</ColumnHeding>
                </tr>
            </thead>
            <tbody>
                {records.map((record: RecordViewRequestResult, i: number) => (
                    <MedicalRecordsTableRow key={i} record={record} />
                ))}
            </tbody>
        </StyledTable>
    );
};

type MedicalRecordsTableRowProps = {
    record: RecordViewRequestResult;
};

const MedicalRecordsTableRow = ({
    record,
}: MedicalRecordsTableRowProps): JSX.Element => {
    const history = useHistory();

    const goToMedicalRecord = () => {
        history.push(
            `${history.location.pathname}${ROUTES_EXTENSION.remoteRecordViewMedicalRecord}?request=${record.requestId}`,
            { referredFrom: ROUTES.record_view_medical_record_requests },
        );
    };

    const inputCode = () => {
        history.push(
            `${history.location.pathname}${ROUTES_EXTENSION.remoteRecordView}`,
            { requestId: record.requestId },
        );
    };

    const renderActionButton = () => {
        const noActionText = "No longer available";
        const possibleactions = {
            [RecordViewResultStatus.RecordAvailable]: (
                <Button text="Go to record" onClick={goToMedicalRecord} />
            ),
            [RecordViewResultStatus.Expired]: noActionText,
            [RecordViewResultStatus.TwofaCodeRequired]: (
                <Button
                    text="Input code"
                    theme="secondary"
                    onClick={inputCode}
                />
            ),
            [RecordViewResultStatus.AuthorisationFailed]: noActionText,
            [RecordViewResultStatus.Requesting]: noActionText,
            [RecordViewResultStatus.RequestFailed]: noActionText,
        };

        return possibleactions[record.status];
    };

    return (
        <tr>
            <Text as="th" props={{ scope: "row", className: "text-nowrap" }}>
                {record.nhsNumber}
            </Text>
            <Cell>
                {record.requestedTime
                    ? DateHelpers.formatDate(
                          record.requestedTime,
                          DateFormatOptions.TIME_DATE_LONG_WITH_DAY,
                      )
                    : "Not found"}
            </Cell>
            <Cell>
                <RecordStatusBadge status={record.status} />
                {record.status === RecordViewResultStatus.RecordAvailable && (
                    <>
                        <br />
                        <Text variant="preview" as="span">
                            {getRequestRemainingHoursText(
                                record.accessRemainingHours,
                            )}
                        </Text>
                    </>
                )}
            </Cell>
            <Cell>{renderActionButton()}</Cell>
        </tr>
    );
};
