import { PatientThreadSuggestedPatientDetails } from "@accurx/api/ticket";
import { mapDateOfBirthToPatientSummaryAgeParts } from "domains/concierge/internal/api/shared/mappers/patients/mapDateOfBirthToPatientSummaryAgeParts";
import { mapTicketPatientExternalIdsToPatientSummaryExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientExternalIdsToPatientSummaryExternalIds";
import { mapPatientGenderToPatientSummaryGender } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientGenderToPatientSummaryGender";
import { mapPatientNamesToPatientSummaryParts } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientNamesToPatientSummaryParts";
import { SuggestedPatientMatch } from "domains/concierge/types";

export const mapTicketSuggestedPatientToSuggestedPatientMatch = (
    suggestedpatient: PatientThreadSuggestedPatientDetails,
): SuggestedPatientMatch => {
    const { firstName, familyName, prefixName } =
        mapPatientNamesToPatientSummaryParts(suggestedpatient.names);
    return {
        firstName,
        familyName,
        prefixName,
        ...mapDateOfBirthToPatientSummaryAgeParts(suggestedpatient.dateOfBirth),
        gender: mapPatientGenderToPatientSummaryGender(suggestedpatient.gender),
        externalIds: mapTicketPatientExternalIdsToPatientSummaryExternalIds(
            suggestedpatient.patientExternalIdentity?.patientExternalIds,
        ),
        postCode: suggestedpatient?.postCode ?? null,
    };
};
