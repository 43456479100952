import { ExampleWorkspace } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

type ExampleWorkspacesRequest = { workspaceId: number; limit?: number };

type ExampleWorkspacesQueryOptions = Pick<
    UseQueryOptions<ExampleWorkspace[], Error>,
    "refetchOnMount"
>;

const WORKSPACE_MANAGEMENT_EXAMPLE_WORKSPACES_CACHE_KEY =
    "GetExampleWorkspaces";

const fetchExampleWorkspaces = async ({
    limit,
    workspaceId,
}: ExampleWorkspacesRequest): Promise<IWrappedResult<ExampleWorkspace[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/account/ExampleWorkspaces",
            query: `?workspaceId=${workspaceId}${
                limit ? `&limit=${limit}` : ""
            }`,
        }),
    );

export const useGetExampleWorkspacesQuery = (
    { limit, workspaceId }: ExampleWorkspacesRequest,
    { refetchOnMount }: ExampleWorkspacesQueryOptions = {
        refetchOnMount: false,
    },
) => {
    return useQuery<ExampleWorkspace[], Error, ExampleWorkspace[]>({
        queryKey: [
            WORKSPACE_MANAGEMENT_EXAMPLE_WORKSPACES_CACHE_KEY,
            { workspaceId, limit },
        ],
        queryFn: async () => {
            const response = await fetchExampleWorkspaces({
                limit,
                workspaceId,
            });

            return returnDataOrThrow(response);
        },
        refetchOnMount,
    });
};
