import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSectionHeader = styled(Text)`
    margin-left: ${Tokens.SIZES[1]};
`;

export const StyledFlex = styled.div`
    display: flex;
`;

export const StyledContainer = styled.div`
    display: block;
    justify-content: space-between;
    align-items: start;
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
    }
`;

export const StyledOverviewColumnOne = styled.div`
    flex: 2 0;
    padding-bottom: ${Tokens.SIZES[3]};
    overflow: hidden;

    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        :not(:last-child) {
            padding-right: ${Tokens.SIZES[3]};
        }
    }
`;

export const StyledOverviewColumnTwo = styled.div`
    flex: 1 0;
`;
export const StyledCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    padding-right: 0;
    max-width: 100%;
    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding-right: ${Tokens.SIZES[2]};
    }
`;
