import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledGrid = styled(UI.Grid)`
    grid-template-columns: max-content auto;
    column-gap: ${UI.Tokens.SIZES[0.5]};
`;

export const StyledCellActionSummary = styled(UI.Cell)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
