import { PatientListType } from "@accurx/api/patient-messaging";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

type MainCopy = {
    title: string;
    body: string;
};

type VideoConsultWarningCopy = MainCopy;
type DeleteConfirmationCopy = MainCopy & {
    videoConsultWarning?: VideoConsultWarningCopy;
};

/**
 * Information about the patient list
 */
export type ListDetails = {
    /** The name of the list to display in modal copy */
    listName: string;
    /**
     * Is the user who is performing this delete operation the owner of the list?
     * This property is required when the whole list is attempting to be deleted
     * as a delete by a non-owner is actually an "unshare with me" operation,
     * and they will need to informed that it will not cancel appointments of
     * video consults.
     */
    isCurrentUserListOwner: boolean;
    listType: PatientListType;
};

const isAnytimeAppointment = (date: string) => {
    return date === "";
};

export const getConfirmDeleteCopy = (
    datesOfAppointmentsToDelete: string[] | null,
    areScheduledVideoConsultsAffected: boolean,
    listDetails: ListDetails,
    patientName?: string,
): DeleteConfirmationCopy => {
    const { listName, isCurrentUserListOwner, listType } = listDetails;
    const userCanDeleteList =
        isCurrentUserListOwner || listType === PatientListType.WorkspaceManaged;

    if (!userCanDeleteList) {
        return {
            title: `You are about to remove yourself from ${listName}`,
            body: "This list will no longer be available to you. In order to access this list, it will have to be shared with you again.",
        };
    }

    if (datesOfAppointmentsToDelete !== null) {
        const numberOfDatesAffected = datesOfAppointmentsToDelete.length;

        // single appointment
        if (numberOfDatesAffected === 1 && patientName !== undefined) {
            const dateOfAppointmentsToDelete = datesOfAppointmentsToDelete[0];

            // scheduled, with a video consult
            if (
                isAnytimeAppointment(dateOfAppointmentsToDelete) === false &&
                areScheduledVideoConsultsAffected
            ) {
                return {
                    title: "You are about to remove a patient who has an existing Video Consult invitation",
                    body: `[[INSERT PATIENT NAME]]'s patient details and appointment time info for the following date will be removed:`,
                    videoConsultWarning: {
                        title: "Removing this patient will cancel their Video Consult invitation",
                        body: "Removed patients will be sent cancellations messages and will have to be reinvited by you.",
                    },
                };
            }
        }

        // entire anytime
        if (numberOfDatesAffected === 1) {
            const dateOfAppointmentsToDelete = datesOfAppointmentsToDelete[0];

            if (isAnytimeAppointment(dateOfAppointmentsToDelete)) {
                return {
                    title: "You are about to remove all patient info for the 'Anytime' group",
                    body: "All patient details for this group will be removed",
                };
            }

            // entire date
            const deleteWholeDateCopy: DeleteConfirmationCopy = {
                title: `You are about to remove all patient info for ${DateHelpers.formatDate(
                    dateOfAppointmentsToDelete,
                    DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                )}`,
                body: "All patient details and appointment time info for the following date will be removed:",
            };

            // which contains scheduled video consults
            if (areScheduledVideoConsultsAffected) {
                deleteWholeDateCopy.videoConsultWarning = {
                    title: "This update will affect some active Video Consult invitations",
                    body: "Removed patients will be sent cancellations messages and will have to be reinvited by you.",
                };
            }

            return deleteWholeDateCopy;
        }
    }

    const deleteWholeListCopy: DeleteConfirmationCopy = {
        title: `You are about to delete ${listName}, removing all patient details and appointment info`,
        body: "All patient details and appointment time info for the following dates will be removed:",
    };

    if (areScheduledVideoConsultsAffected) {
        deleteWholeListCopy.videoConsultWarning = {
            title: "This update will affect some active Video Consult invitations",
            body: "Removed patients will be sent cancellations messages and will have to be reinvited by you.",
        };
    }

    return deleteWholeListCopy;
};
