/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Button, Icon, Text } from "@accurx/design";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

export interface VaccineDeliveryMoreDropdownProps {
    onCancel?: () => void;
    onDecline?: () => void;
    onBook?: () => void;
    disabled: boolean;
}

export const VaccineDeliveryMoreDropdown = ({
    onCancel,
    onDecline,
    onBook,
    disabled,
}: VaccineDeliveryMoreDropdownProps): JSX.Element => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = (): void => {
        setIsDropdownOpen((prevState) => !prevState);
    };
    return (
        <Dropdown isOpen={isDropdownOpen} toggle={handleToggleDropdown}>
            <DropdownToggle tag="div" aria-expanded={isDropdownOpen}>
                <Button
                    disabled={disabled}
                    icon={{ name: "More", style: "Fill" }}
                    theme="secondary"
                    text="More"
                />
            </DropdownToggle>
            <DropdownMenu right={true}>
                {onDecline && (
                    <DropdownItem
                        tag="div"
                        className="d-flex p-1 cursor-pointer"
                        onClick={onDecline}
                    >
                        <Icon
                            theme="Line"
                            name="Cross"
                            size={3}
                            colour="blue"
                        />
                        <Text variant="label" as="span" colour="blue" skinny>
                            Decline Invite
                        </Text>
                    </DropdownItem>
                )}
                {onCancel && (
                    <DropdownItem
                        tag="div"
                        className="d-flex p-1 cursor-pointer"
                        onClick={onCancel}
                    >
                        <Icon theme="Line" name="Cross" size={3} colour="red" />
                        <Text variant="label" as="span" colour="red" skinny>
                            Cancel Invite
                        </Text>
                    </DropdownItem>
                )}
                {onBook && (
                    <DropdownItem
                        tag="div"
                        className="d-flex p-1 cursor-pointer"
                        onClick={onBook}
                    >
                        <Icon theme="Line" name="Calendar" size={3} />
                        <Text variant="label" as="span" skinny>
                            Book
                        </Text>
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};
