import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const AvatarContainer = styled(Flex)`
    height: ${Tokens.SIZES[3]};
    width: ${Tokens.SIZES[3]};
    background-color: ${Tokens.COLOURS.greyscale.dishwater};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.SIZES[0.5]};
`;
