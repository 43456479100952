import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { UserGroupsResponse } from "@accurx/api/florey-builder";

import { getUserGroups } from "api/FloreyBuilder/FloreyBuilderApi";

export const GET_USER_GROUPS_CACHE_KEY = "GetUserGroups";

type GetUserGroupsQueryVariables = {
    workspaceId: number;
};

export const useGetUserGroupsQuery = createQueryHook<
    GetUserGroupsQueryVariables,
    UserGroupsResponse
>(({ workspaceId }) => ({
    queryKey: [GET_USER_GROUPS_CACHE_KEY, { workspaceId }],
    queryFn: async () => {
        const response = await getUserGroups(workspaceId.toString());
        return returnDataOrThrow(response);
    },
}));
