import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledPrimaryNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[1]};
    background-color: ${Tokens.COLOURS.primary.green[100]};
    overflow-y: auto;
    flex: none;
`;

const StyledNavigationContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
`;

const StyledMobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledExpandButton = styled.button`
    background: inherit;
    padding: ${Tokens.SIZES[1]};
    border: ${Tokens.SIZES[0.25]} solid ${Tokens.COLOURS.primary.green[130]};
    border-right: none;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100% + ${Tokens.SIZES[2]});

    &:hover,
    &:focus {
        background: ${Tokens.COLOURS.primary.green[75]};
        border-color: ${Tokens.COLOURS.primary.green[50]};
        transition: background-color 0.2s ease-in;
    }

    &:focus,
    &:hover,
    &:focus-visible {
        box-shadow: none !important;
    }
`;

export {
    StyledNavigationContent,
    StyledPrimaryNavigation,
    StyledMobileHeader,
    StyledExpandButton,
};
