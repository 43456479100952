import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    Complexity,
    FloreyScoreStatus,
    FloreyScoresSummaryResponse,
} from "@accurx/api/ticket";

import { getFloreyResponseScoreSummaries } from "shared/concierge/conversations/tickets/TicketApiClient";

export const FLOREY_RESPONSE_SCORE_SUMMARIES_CACHE_KEY =
    "FloreyResponseScoreSummaries";

export type FloreyResponseScoreSummariesQueryVariables = {
    workspaceId: string;
    /** The ID of the questionnaire we need responses for */
    questionnaireId: string;
    filters: {
        /** ISO string format */
        dateStart?: string;
        /** ISO string format */
        dateEnd?: string;

        status: FloreyScoreStatus[];
        complexity: Complexity[];
    };
};

export const useFloreyResponseScoreSummariesQuery = createQueryHook<
    FloreyResponseScoreSummariesQueryVariables,
    FloreyScoresSummaryResponse
>(({ workspaceId, questionnaireId, filters }) => ({
    queryKey: [
        FLOREY_RESPONSE_SCORE_SUMMARIES_CACHE_KEY,
        {
            workspaceId,
            questionnaireId,
            ...filters,
        },
    ],
    queryFn: async () => {
        const response = await getFloreyResponseScoreSummaries({
            organisationId: workspaceId,
            conditionId: questionnaireId,
            filters,
        });
        return returnDataOrThrow(response);
    },
}));
