import * as UI from "@accurx/design";
import { colors } from "domains/inbox/theme";
import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StyledSpan = styled.span`
    display: block;
    padding: 0 ${UI.Tokens.SIZES[0.5]};
    border-radius: 50%;
    color: ${colors.darkblue};
    animation: ${fadein} 100ms ease-in;

    min-width: 18px;
    height: 18px;

    background-color: ${UI.Tokens.COLOURS.primary.red["50"]};
`;
