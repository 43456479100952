import * as UI from "@accurx/design";

import { StyledNoWrap } from "./Questionnaire.styles";

export const QuestionnairePreviewLinkText = ({
    questionnaireName,
}: {
    questionnaireName: string;
}) => {
    const nameParts = questionnaireName.split(" ");

    if (nameParts.length > 0) {
        const allButLastWord = nameParts
            .slice(0, nameParts.length - 1)
            .join(" ");
        const lastWord = nameParts[nameParts.length - 1];

        return (
            <>
                {allButLastWord}{" "}
                <StyledNoWrap>
                    {lastWord}
                    <UI.Icon
                        name="OpenWindow"
                        size={3}
                        colour={"blue"}
                        title={"Opens in new tab"}
                    />
                </StyledNoWrap>
            </>
        );
    }

    return (
        <StyledNoWrap>
            {questionnaireName}
            <UI.Icon
                name="OpenWindow"
                size={3}
                colour={"blue"}
                title={"Opens in new tab"}
            />
        </StyledNoWrap>
    );
};
