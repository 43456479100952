import { z } from "zod";

export const SignalRConnectionStateSchema = z.enum([
    "Uninitialised",
    "Starting",
    "Closed",
    "Open",
]);

export const SubscribeSignalRLifecycleEventsSchema = z.object({
    state: SignalRConnectionStateSchema,
});

export type SignalRConnectionState = z.infer<
    typeof SignalRConnectionStateSchema
>;
export type SubscribeSignalRLifecycleEvents = z.infer<
    typeof SubscribeSignalRLifecycleEventsSchema
>;
