import { Tokens } from "@accurx/design";
import styled from "styled-components";

type LeftSideBorderedBlockProps = {
    background?: string;
    padding?: string;
};

export const StyledLeftSideBorderedBlock = styled.div`
    background-color: ${(props: LeftSideBorderedBlockProps) =>
        props.background ? `${props.background}` : ""};
    border-left: 3px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${(props: LeftSideBorderedBlockProps) =>
        props.padding
            ? `${props.padding}`
            : `${Tokens.SIZES[2]} ${Tokens.SIZES[3]}`};
`;
