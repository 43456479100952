import NotFound from "NotFound";

import { useCurrentWorkspace } from "../../useCurrentWorkspace";
import { hasRequiredFeatureFlags } from "./hasRequiredFeatureFlags";
import { RequiredFlags } from "./types";

export type FeatureGuardProps = RequiredFlags & { children: JSX.Element };

/**
 * Must be used only when the component sits inside a
 * Route containing a :workpaceId.
 * Please use `src/app/FeatureGuard` otherwise
 *
 * @params
 * Can pass an array of feature flags
 * - if _any_ is used, it will check whether at least 1 flag is on
 * - if _all_ is used, it will check whether all flags are on
 *
 * @returns
 * - If feature flags are enabled, it will render children
 * - If feature flags are disabled, it will render not found page
 * - If workspace is not found, it will throw an error
 */
export const WorkspaceFeatureGuard = ({
    children,
    ...flagRules
}: FeatureGuardProps) => {
    const workspace = useCurrentWorkspace();

    const featureFlagCheck = hasRequiredFeatureFlags({
        flags: flagRules,
        workspace,
    });
    return featureFlagCheck.isAllowed ? (
        children
    ) : (
        <NotFound missingFeatureFlags={featureFlagCheck.missingFeatureFlags} />
    );
};
