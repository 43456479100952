/* These render functions are shared between the page components for the old and new flows */
import React from "react";

import { Ds } from "@accurx/design";

import { PdsMatchWarningLevel } from "../VaccineCopy";
import { InviteStatusType } from "../models/VaccineAllPatientsInvitedDTO";
import {
    FirstVaccinationFilterChoice,
    VaccineCourse,
    VaccineDeliveryItem,
    VaccineExistingPatientState,
    VaccineInviteStageState,
    vaccineTypeStrings,
} from "../models/VaccineDeliveryDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    eightWeeks,
    elevenWeeks,
    fiveWeeks,
    fourWeeks,
    lessThanOneWeek,
    nineWeeks,
    oneWeek,
    sevenWeeks,
    sixWeeks,
    tenWeeks,
    threeWeeks,
    twelvePlusWeeks,
    twoWeeks,
} from "../vaccineAllPatientsInvited/VaccineAllPatientsInvited.helper";

export const timeSinceFirstDoseVaccinationWeekChoices: FirstVaccinationFilterChoice[] =
    [
        twelvePlusWeeks,
        elevenWeeks,
        tenWeeks,
        nineWeeks,
        eightWeeks,
        sevenWeeks,
        sixWeeks,
        fiveWeeks,
        fourWeeks,
        threeWeeks,
        twoWeeks,
        oneWeek,
        lessThanOneWeek,
    ];

type FeedbackColourVariants =
    | "success"
    | "secondary"
    | "information"
    | "warning"
    | "error";

export const getBadDataWarningLevel = (
    level: PdsMatchWarningLevel,
): FeedbackColourVariants => {
    switch (level) {
        case PdsMatchWarningLevel.NONE:
            return "secondary";
        case PdsMatchWarningLevel.MEDIUM:
            return "warning";
        case PdsMatchWarningLevel.HIGH:
        case PdsMatchWarningLevel.CRITICAL:
        default:
            return "error";
    }
};

export const renderJabState = (jabState: string | null): string => {
    switch (jabState) {
        case VaccineExistingPatientState.NoJabs:
            return "No recorded vaccinations";
        case VaccineExistingPatientState.HadFirstJab:
            return "1st vaccination recorded";
        case VaccineExistingPatientState.HadSecondJab:
            return "Primary vaccination(s) recorded";
        case VaccineExistingPatientState.HadBoosterJabs:
            return "Booster vaccination recorded";
        case VaccineExistingPatientState.Unknown:
        default:
            return "No data returned";
    }
};

export const getAllPatientsWhoNeedFirstJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.Unknown ||
            item.jabState === VaccineExistingPatientState.NoJabs,
    );
};

export const getTextablePatientsWhoNeedFirstJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.isTextable &&
            (item.jabState === VaccineExistingPatientState.Unknown ||
                item.jabState === VaccineExistingPatientState.NoJabs),
    );
};

export const getNonTextablePatientsWhoNeedFirstJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            !item.isTextable &&
            (item.jabState === VaccineExistingPatientState.Unknown ||
                item.jabState === VaccineExistingPatientState.NoJabs),
    );
};

export const getAllPatientsWhoNeedSecondJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) => item.jabState === VaccineExistingPatientState.HadFirstJab,
    );
};

export const getTextablePatientsWhoNeedSecondJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.sendMessages &&
            item.jabState === VaccineExistingPatientState.HadFirstJab,
    );
};

export const getNonTextablePatientsWhoNeedSecondJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            !item.sendMessages &&
            item.jabState === VaccineExistingPatientState.HadFirstJab,
    );
};

export const getPatientsWhoNeedSecondJabWhoHaveBeenInvitedForSecond = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadFirstJab &&
            item.inviteStage === VaccineInviteStageState.Second,
    );
};

export const getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecond = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadFirstJab &&
            item.inviteStage !== VaccineInviteStageState.Second,
    );
};

export const getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecondAndNotAlreadyUploaded =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState === VaccineExistingPatientState.HadFirstJab &&
                item.inviteStage !== VaccineInviteStageState.Second &&
                item.inviteStatus !== InviteStatusType.Rejected,
        );
    };

export const getAllPatientsWhoNeedSecondJabAndHaveNotBeenPreviouslyUploaded = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadFirstJab &&
            item.inviteStatus !== InviteStatusType.Rejected,
    );
};

export const getAllPatientsWhoNeedSecondJabAndHaveBeenPreviouslyUploaded = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadFirstJab &&
            item.inviteStatus === InviteStatusType.Rejected,
    );
};

export const getPatientsWhoNeedFirstOrSecondJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.Unknown ||
            item.jabState === VaccineExistingPatientState.NoJabs ||
            item.jabState === VaccineExistingPatientState.HadFirstJab,
    );
};

// i.e. people who have had both
export const getAllPatientWhoNeedBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) => item.jabState === VaccineExistingPatientState.HadSecondJab,
    );
};

export const getAllPatientWhoNeedSeasonalBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab ||
            item.jabState === VaccineExistingPatientState.HadBoosterJabs,
    );
};

export const getTextablePatientsWhoNeedBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.sendMessages &&
            item.jabState !== VaccineExistingPatientState.Unknown,
    );
};

export const getTextablePatientsWhoNeedSeasonalBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.sendMessages &&
            item.jabState !== VaccineExistingPatientState.Unknown,
    );
};

export const getNonTextablePatientsWhoNeedBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            !item.sendMessages &&
            item.jabState !== VaccineExistingPatientState.Unknown,
    );
};

export const getNonTextablePatientsWhoNeedSeasonalBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            !item.sendMessages &&
            item.jabState !== VaccineExistingPatientState.Unknown,
    );
};

export const getAllPatientsWhoHaveHadPrimaryJabs = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] =>
    patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab ||
            item.jabState === VaccineExistingPatientState.HadBoosterJabs,
    );

export const getAllPatientsWhoHaveHadBoosterJab = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) => item.jabState === VaccineExistingPatientState.HadBoosterJabs,
    );
};

export const getPatientsWhoNeedBoosterWhoHaveBeenInvitedForBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab &&
            item.inviteStage === VaccineInviteStageState.Booster,
    );
};

export const getPatientsWhoNeedSeasonalBoosterWhoHaveBeenInvitedForSeasonalBooster =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState !== VaccineExistingPatientState.Unknown &&
                item.inviteStage === VaccineInviteStageState.Booster,
        );
    };

export const getPatientsWhoNeedBoosterWhoHaveNotBeenInvitedForBooster = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab &&
            item.inviteStage !== VaccineInviteStageState.Booster,
    );
};

export const getPatientsWhoNeedBoosterWhoHaveNotBeenInvitedForBoosterAndNotAlreadyUploaded =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState === VaccineExistingPatientState.HadSecondJab &&
                item.inviteStage !== VaccineInviteStageState.Booster &&
                item.inviteStatus !== InviteStatusType.Rejected,
        );
    };

export const getPatientsWhoNeedSeasonalBoosterWhoHaveNotBeenInvitedForSeasonalBoosterAndNotAlreadyUploaded =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState !== VaccineExistingPatientState.Unknown &&
                item.inviteStage !== VaccineInviteStageState.Booster &&
                item.inviteStatus !== InviteStatusType.Rejected,
        );
    };

export const getAllPatientWhoNeedBoosterAndHaveNotBeenPreviouslyUploaded = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab &&
            item.inviteStatus !== InviteStatusType.Rejected,
    );
};

export const getAllPatientWhoNeedSeasonalBoosterAndHaveNotBeenPreviouslyUploaded =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState !== VaccineExistingPatientState.Unknown &&
                item.inviteStatus !== InviteStatusType.Rejected,
        );
    };

export const getAllPatientWhoNeedBoosterAndHaveBeenPreviouslyUploaded = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab &&
            item.inviteStatus === InviteStatusType.Rejected,
    );
};

export const getAllPatientWhoNeedSeasonalBoosterAndHaveBeenPreviouslyUploaded =
    (patientList: VaccineDeliveryItem[]): VaccineDeliveryItem[] => {
        return patientList.filter(
            (item) =>
                item.jabState !== VaccineExistingPatientState.Unknown &&
                item.inviteStatus === InviteStatusType.Rejected,
        );
    };

export const getAllPatientWithNoVaccinationData = (
    patientList: VaccineDeliveryItem[],
): VaccineDeliveryItem[] => {
    return patientList.filter(
        (item) => item.jabState === VaccineExistingPatientState.Unknown,
    );
};

export const listHasOnlyNonTextablePatientsWhoNeedFirstJab = (
    patientList: VaccineDeliveryItem[],
): boolean => {
    return patientList.every(
        (item: VaccineDeliveryItem) =>
            !item.isTextable &&
            item.jabState !== VaccineExistingPatientState.HadFirstJab &&
            item.jabState !== VaccineExistingPatientState.HadSecondJab &&
            item.jabState !== VaccineExistingPatientState.HadBoosterJabs,
    );
};

export const listHasOnlyPatientsWhoNeedSecondJab = (
    patientList: VaccineDeliveryItem[],
): boolean => {
    return patientList.every(
        (item: VaccineDeliveryItem) =>
            item.jabState === VaccineExistingPatientState.HadFirstJab &&
            item.jabState !== VaccineExistingPatientState.HadSecondJab &&
            item.jabState !== VaccineExistingPatientState.HadBoosterJabs,
    );
};

export const listHasOnlyPatientsWhoHaveHadSecondJab = (
    patientList: VaccineDeliveryItem[],
): boolean => {
    return patientList.every(
        (item: VaccineDeliveryItem) =>
            item.jabState === VaccineExistingPatientState.HadSecondJab,
    );
};

export const listHasOnlyPatientsWhoHaveHadBoosterJab = (
    patientList: VaccineDeliveryItem[],
): boolean => {
    return patientList.every(
        (item: VaccineDeliveryItem) =>
            item.jabState === VaccineExistingPatientState.HadBoosterJabs,
    );
};

export const badDataWarningLevel = (
    level: PdsMatchWarningLevel,
): FeedbackColourVariants => {
    switch (level) {
        case PdsMatchWarningLevel.NONE:
            return "secondary";
        case PdsMatchWarningLevel.MEDIUM:
            return "warning";
        case PdsMatchWarningLevel.HIGH:
        case PdsMatchWarningLevel.CRITICAL:
        default:
            return "error";
    }
};

// To be populated with stepper eventually
export const renderEmptyPanel = (): JSX.Element => {
    return <div className="d-flex flex-column" style={{ width: "22%" }} />;
};

export const renderUploadingText = (): JSX.Element => {
    return (
        <div className="align-items-center" style={{ flexFlow: "column" }}>
            <VaccineDeliveryHeader stepNumber={1} title={"Uploading..."} />
        </div>
    );
};

// N.B. This is a copy from Florey - vaccine\shared\HelperFunctions.ts
export const mapVaccineTypeToBadgeColour = (
    vaccineType: string,
): Ds.BadgeProps["color"] => {
    switch (vaccineType) {
        case vaccineTypeStrings.AstraZeneca:
            return "yellow";
        case vaccineTypeStrings.Moderna:
            return "green";
        case vaccineTypeStrings.Pfizer:
            return "blue";
        case vaccineTypeStrings.Janssen:
            return "red";
        default:
            return "greyscale";
    }
};

// Get total number of steps for the flow (6 for normal, 4 for booster)
export const getTotalSteps = (vaccineCourse: VaccineCourse): number => {
    return vaccineCourse !== VaccineCourse.Primary ? 4 : 6;
};

export const UploadMaxAllowedFileSize = 1048576; // 1MB
export const UploadMaxAllowedFileSizeHumanReadable = "1MB";
export const UploadAllowedFileExtensions = ["csv"];

export interface VaccineCapacity {
    slots: number;
    nimsProductType: string;
    bgColour: string;
}

export type VaccineDeliveryAccordionDetails = {
    list: VaccineDeliveryItem[];
    listSuffix: string;
    testId: string;
};
