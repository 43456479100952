import { Card, Icon, Text } from "@accurx/design";
import { ButtonVariants } from "@accurx/design/dist/styles/types";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";

import { FlemingAnalyticsTracker } from "app/analytics";

import CopyTextButton from "../copyText/CopyTextButtonComponent";

export interface MessageCopy {
    sentTo?: string;
    mobileNumber?: string;
    email?: string;
    sentBy?: string;
    dateTime: string;
    messageText: string;
}

export const MESSAGE_COPY_SECTION_TITLE: MessageCopy = {
    sentTo: "Sent to",
    mobileNumber: "Mobile phone number",
    email: "Email",
    sentBy: "Sent by",
    dateTime: "Time and date",
    messageText: "Message text",
};

/*
 * Component used by message sending products to provide a preview
 * */

export interface MessagePreviewProps {
    messageCopy: MessageCopy;
    copyButtonVariant?: ButtonVariants;
    copyButtonAnalyticsContext: FlemingAnalyticsTracker.CopyButtonAnalyticsContext;
}

// Structure of this should mirror structure of MessagePreview ui
export const generateMessageCopy = (messageCopy: MessageCopy): string => {
    const sentTo = messageCopy.sentTo
        ? `${MESSAGE_COPY_SECTION_TITLE.sentTo}:\n${messageCopy.sentTo}\n\n`
        : "";
    const mobileNumber = messageCopy.mobileNumber
        ? `${MESSAGE_COPY_SECTION_TITLE.mobileNumber}:\n${messageCopy.mobileNumber}\n\n`
        : "";
    const email = messageCopy.email
        ? `${MESSAGE_COPY_SECTION_TITLE.email}:\n${messageCopy.email}\n\n`
        : "";
    const sentBy = messageCopy.sentBy
        ? `${MESSAGE_COPY_SECTION_TITLE.sentBy}:\n${messageCopy.sentBy}\n\n`
        : "";
    const dateTime = messageCopy.dateTime
        ? `${MESSAGE_COPY_SECTION_TITLE.dateTime}:\n${messageCopy.dateTime}\n\n`
        : "";
    const messageText = messageCopy.messageText
        ? `${MESSAGE_COPY_SECTION_TITLE.messageText}:\n${messageCopy.messageText}`
        : "";

    return `${sentTo}${mobileNumber}${email}${sentBy}${dateTime}${messageText}`.trim();
};

const MessagePreview = ({
    messageCopy,
    copyButtonVariant = "secondary",
    copyButtonAnalyticsContext,
}: MessagePreviewProps) => {
    return (
        <Card
            spacing={0}
            header={
                <div className="d-flex align-items-center pt-3 pb-3 pl-3 pr-3">
                    <Icon
                        theme="Line"
                        name="Save"
                        size={4}
                        halo={{
                            luminosity: "high",
                            colour: "green",
                        }}
                    />
                    <Text
                        variant="label"
                        as="span"
                        props={{ className: "ml-1" }}
                    >
                        Get a copy of the message to save it to the record
                    </Text>
                </div>
            }
            footer={
                <div className="d-flex justify-content-end pl-3 pr-3 pt-3 pb-3">
                    <CopyTextButton
                        text={generateMessageCopy(messageCopy)}
                        isButtonOnly
                        buttonProps={{
                            text: "Get a copy",
                            theme: copyButtonVariant,
                        }}
                        analyticsContext={copyButtonAnalyticsContext}
                    />
                </div>
            }
        >
            <div
                className="p-3"
                style={{ height: "33vh", overflowY: "scroll" }}
            >
                {/* Structure of this should mirror structure of generateMessageCopy */}
                {messageCopy.sentTo && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.sentTo}
                        </Text>
                        <Text>{messageCopy.sentTo}</Text>
                    </>
                )}
                {messageCopy.mobileNumber && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.mobileNumber}
                        </Text>
                        <Text>{messageCopy.mobileNumber}</Text>
                    </>
                )}
                {messageCopy.email && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.email}
                        </Text>
                        <Text>{messageCopy.email}</Text>
                    </>
                )}
                {messageCopy.sentBy && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.sentBy}
                        </Text>
                        <Text>{messageCopy.sentBy}</Text>
                    </>
                )}
                {messageCopy.dateTime && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.dateTime}
                        </Text>
                        <Text>{messageCopy.dateTime}</Text>
                    </>
                )}
                {messageCopy.messageText && (
                    <>
                        <Text variant="label">
                            {MESSAGE_COPY_SECTION_TITLE.messageText}
                        </Text>
                        <TextareaAutosize
                            className="form-control col-sm-12"
                            value={messageCopy.messageText}
                            disabled
                            style={{
                                backgroundColor: "white",
                                border: "none",
                                padding: 0,
                                color: "inherit",
                            }}
                        />
                    </>
                )}
            </div>
        </Card>
    );
};

export default MessagePreview;
