import { ReactNode } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { NewInboxBanner } from "app/clinicianConversations/components/NewInboxBanner";
import { StyledHeaderContainer } from "app/workspaceConversations/pages/ConversationGroupPage.styles";

import { StyledGrid } from "./ClinicianConversationsPage.styles";

type ClinicianConversationsPageLayoutProps = {
    headerText: string;
    pagination?: JSX.Element;
    children: ReactNode;
};

const BannerContainer = styled.div`
    background: white;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[1.5]} 0;
`;

export const ClinicianConversationsPageLayout = ({
    headerText,
    pagination,
    children,
}: ClinicianConversationsPageLayoutProps) => {
    const hasNewInbox = useFeatureFlag("UnifiedInboxAccumailSandboxBuild");

    return (
        <>
            {hasNewInbox && (
                <BannerContainer>
                    <NewInboxBanner />
                </BannerContainer>
            )}
            <StyledHeaderContainer>
                <Text as="h1" variant="subtitle" skinny>
                    {headerText}
                </Text>
            </StyledHeaderContainer>
            <StyledGrid
                rows={pagination ? "min-content 1fr min-content" : "1fr"}
                gap="1.5"
            >
                {pagination}
                {children}
                {pagination}
            </StyledGrid>
        </>
    );
};
