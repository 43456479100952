import { useEffect } from "react";

import { useLocation } from "react-router";

/**
 * Resets the view to the top of the page
 */
export const useResetView = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
    }, [pathname]);

    return null;
};
