import { PatientListType } from "@accurx/api/patient-messaging";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import { Action, Reducer } from "redux";

import {
    PatientList,
    PatientListAppointment,
    PatientListAppointmentDay,
    PatientListBulkAddPatientError,
    PatientListPatientDetail,
    PatientListPatientDetails,
    PatientListSummary,
    PatientVideoProgressResponse,
} from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";

import { SELECT_ORG } from "../account/AccountActions";
import {
    getPatientFromPatientId,
    getPatientListEntries,
} from "./PatientList.helper";
import {
    ADD_CURRENT_LIST_DUPLICATE,
    CANCEL_INIT_CREATE_EDIT_LIST,
    CANCEL_PATIENT_LIST_SHARE,
    CHANGE_ORDER_METHOD,
    CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL,
    GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE,
    GET_ALL_PATIENT_LIST_SUMMARIES_STARTED,
    GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS,
    GET_PATIENT_LIST_FAILURE,
    GET_PATIENT_LIST_STARTED,
    GET_PATIENT_LIST_SUCCESS,
    INIT_CREATE_EDIT_LIST,
    INIT_PATIENT_LIST_SHARE,
    KnownAction,
    OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL,
    POST_ADD_PATIENT_TO_LIST_FAILURE,
    POST_ADD_PATIENT_TO_LIST_STARTED,
    POST_ADD_PATIENT_TO_LIST_SUCCESS,
    POST_BULK_ADD_PATIENTS_TO_LIST_STARTED,
    POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE,
    POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED,
    POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS,
    POST_CREATE_OR_EDIT_LIST_FAILURE,
    POST_CREATE_OR_EDIT_LIST_STARTED,
    POST_CREATE_OR_EDIT_LIST_SUCCESS,
    POST_DELETE_PATIENT_LIST_FAILURE,
    POST_DELETE_PATIENT_LIST_STARTED,
    POST_DELETE_PATIENT_LIST_SUCCESS,
    POST_PATIENTS_VIDEO_PROGRESS_FAILURE,
    POST_PATIENTS_VIDEO_PROGRESS_STARTED,
    POST_PATIENTS_VIDEO_PROGRESS_SUCCESS,
    POST_REMOVE_PATIENTS_FROM_LIST_FAILURE,
    POST_REMOVE_PATIENTS_FROM_LIST_STARTED,
    POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS,
    POST_SHARE_PATIENT_LIST_FAILURE,
    POST_SHARE_PATIENT_LIST_STARTED,
    POST_SHARE_PATIENT_LIST_SUCCESS,
    REMOVE_CURRENT_LIST_DUPLICATE,
    RESET_BULK_ADD_PATIENTS_ERRORS,
    RESET_CURRENT_LIST,
    RESET_CURRENT_LIST_ERRORS,
    SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED,
    SET_LATEST_PATIENT_LIST_SEARCHED,
    SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_STARTED,
    SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_SUCCESS,
} from "./PatientListsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export enum OrderMethod {
    lastDateAdded,
    firstDateAdded,
    sortByAZ,
    sortByZA,
}

type SortOrderDirection = "asc" | "desc";

export enum BulkAddLoadingState {
    None = "None",
    PrepareLoading = "PrepareLoading",
    ConfirmLoading = "ConfirmLoading",
}

export interface PatientListsState {
    allListSummariesLoading: boolean;
    allListSummaries: PatientListSummary[] | null;
    allListSummariesError: string;
    createOrEditListInitialising: boolean; // For when we click on the create a new list
    createOrEditListSubmitting: boolean;
    createOrEditListOrigin: FlemingAnalyticsTracker.PatientListActionOrigin | null;
    currentListLoading: boolean;
    currentListLastFailed: boolean;
    currentList: PatientList | null;
    currentListSharingInitialising: boolean;
    workspaceSharingModalOpen: boolean;
    currentListSharing: boolean;
    currentListWorkspaceSharingRequestStarted: boolean;
    currentListPatientAdding: boolean;
    currentListPatientAddLastFailed: boolean;
    currentListPatientDuplicates: PatientListAppointment[];
    currentListPatientsBulkAdding: BulkAddLoadingState;
    currentListPatientsBulkAddLastFailed: boolean;
    currentListBulkAddErrors: PatientListBulkAddPatientError[];
    currentListPatientsOrder: OrderMethod;
    currentListPatientRemoving: boolean;
    currentListPatientsBatchRemoving: boolean;
    currentListConsultationIds: string[];
    currentListVideoProgressLoading: boolean;
    currentListVideoProgressError: string;
    currentListVideoProgressStatus: PatientVideoProgressResponse[] | [];
    /** Used in send message component,
     * to provide the needed patient list context
     * even after the rest of patient list state gets reset
     * */
    latestSearchPatientListEntry: PatientListAppointment | null;
    /** Used throughout component,
     * to provide the needed patient list context
     * as well as to redirect the user back to a list even after the state gets reset
     * */
    latestSearchedPatientListId: number | null;
    deletingListLoading: boolean;
    /** Used in patient list component,
     * to prevent excess get list requests from happening after a list deletion
     */
    lastDeletedListId: number | null;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const initialState: PatientListsState = {
    allListSummariesLoading: false,
    allListSummaries: null,
    allListSummariesError: "",
    createOrEditListInitialising: false,
    createOrEditListSubmitting: false,
    createOrEditListOrigin: null,
    currentList: null,
    currentListLoading: false,
    currentListLastFailed: false,
    currentListSharingInitialising: false,
    workspaceSharingModalOpen: false,
    currentListSharing: false,
    currentListWorkspaceSharingRequestStarted: false,
    currentListPatientAdding: false,
    currentListPatientAddLastFailed: false,
    currentListPatientDuplicates: [],
    currentListPatientsBulkAdding: BulkAddLoadingState.None,
    currentListPatientsBulkAddLastFailed: false,
    currentListBulkAddErrors: [],
    currentListPatientsOrder: OrderMethod.lastDateAdded,
    currentListPatientRemoving: false,
    currentListPatientsBatchRemoving: false,
    currentListConsultationIds: [],
    currentListVideoProgressLoading: false,
    currentListVideoProgressError: "",
    currentListVideoProgressStatus: [],
    latestSearchPatientListEntry: null,
    latestSearchedPatientListId: null,
    deletingListLoading: false,
    lastDeletedListId: null,
};

const orderAlphabetically = (
    stringA: string | null,
    stringB: string | null,
    order: SortOrderDirection,
): number => {
    if (stringA && stringB && stringA !== stringB) {
        if (order === "desc") {
            return stringA > stringB ? -1 : 1;
        }
        return stringA > stringB ? 1 : -1;
    }
    return 0;
};

const orderPatientsAlphabetically = (
    patientA: PatientListPatientDetail | null,
    patientB: PatientListPatientDetail | null,
    order: SortOrderDirection,
): number => {
    const firstNameA = patientA?.firstName || null;
    const familyNameA = patientA?.familyName || null;
    const firstNameB = patientB?.firstName || null;
    const familyNameB = patientB?.familyName || null;

    // sort by family name first
    const lastNameOrder = orderAlphabetically(familyNameA, familyNameB, order);

    if (lastNameOrder !== 0) {
        return lastNameOrder;
    }

    // if same family name, then sort by first name
    return orderAlphabetically(firstNameA, firstNameB, order);
};

const orderAppointments = (
    appointmentDays: PatientListAppointmentDay[],
    patients: PatientListPatientDetails,
    orderMethod: OrderMethod,
): PatientListAppointmentDay[] => {
    const appointmentDaysWithOrderedAppointments = appointmentDays.map(
        (day) => {
            const appointments = [...day.appointments];
            const dateOrderingField = day.date ? "dateTimeStart" : "dateAdded";

            let orderedAppointments: PatientListAppointment[];

            switch (orderMethod) {
                case OrderMethod.firstDateAdded:
                    orderedAppointments = orderBy(
                        appointments,
                        [dateOrderingField],
                        ["asc"],
                    );
                    break;
                case OrderMethod.sortByAZ:
                case OrderMethod.sortByZA:
                    const sortOrder: SortOrderDirection =
                        orderMethod === OrderMethod.sortByAZ ? "asc" : "desc";

                    orderedAppointments = appointments.sort((a, b) => {
                        const patientDetailsA = getPatientFromPatientId(
                            patients,
                            a.patientId,
                        );
                        const patientDetailsB = getPatientFromPatientId(
                            patients,
                            b.patientId,
                        );
                        return orderPatientsAlphabetically(
                            patientDetailsA,
                            patientDetailsB,
                            sortOrder,
                        );
                    });
                    break;
                case OrderMethod.lastDateAdded:
                default:
                    orderedAppointments = orderBy(
                        appointments,
                        [dateOrderingField],
                        ["desc"],
                    );
                    break;
            }

            return { ...day, appointments: [...orderedAppointments] };
        },
    );

    // Make sure that dates are appropriately ordered too,
    // anytime patients first and dates ordered from oldest to newest
    return orderBy(appointmentDaysWithOrderedAppointments, ["date"], ["asc"]);
};

const extractConsultationIds = (
    days: PatientListAppointmentDay[],
): string[] => {
    const ids = getPatientListEntries(days)
        .map((appointment) => appointment.videoConsultId)
        .filter(Boolean);

    return uniq(ids as string[]);
};

/*
 * Resets the patient list information except the video progress status
 * Video progress status is used outside of the patient list page specifically
 * so it needs to be there at all times
 * */
const resetCurrentList = (state: PatientListsState): PatientListsState => {
    return {
        ...state,
        currentList: null,
        currentListLoading: false,
        currentListLastFailed: false,
        currentListSharingInitialising: false,
        workspaceSharingModalOpen: false,
        currentListSharing: false,
        currentListPatientsOrder: OrderMethod.lastDateAdded,
        currentListPatientAdding: false,
        currentListPatientDuplicates: [],
        currentListPatientAddLastFailed: false,
        currentListBulkAddErrors: [],
        currentListPatientsBulkAdding: BulkAddLoadingState.None,
        currentListPatientsBulkAddLastFailed: false,
        currentListPatientRemoving: false,
        currentListPatientsBatchRemoving: false,
        currentListConsultationIds: [],
    };
};

const addPatientToDuplicates = (
    patient: PatientListAppointment,
    duplicates: PatientListAppointment[],
): PatientListAppointment[] => {
    return [
        patient,
        ...duplicates.filter(
            (duplicatePatient) => duplicatePatient.id !== patient?.id,
        ),
    ];
};

const updatePatientListSummaries = (
    { allListSummaries }: PatientListsState,
    patientList: PatientList,
): PatientListSummary[] | null => {
    if (!allListSummaries) return null;

    const isNew = !allListSummaries.find(
        (summary) => summary.patientListId === patientList.patientListId,
    );

    if (isNew) {
        return [
            ...allListSummaries,
            {
                ...patientList,
                patientCount: 0,
            },
        ];
    }

    return allListSummaries.map((summary) => {
        if (summary.patientListId === patientList.patientListId) {
            return {
                ...summary,
                name: patientList.name,
            };
        }
        return summary;
    });
};

const findAppointmentInAnytimeGroup = (
    newAppointment: PatientListAppointment,
    currentAppointmentDays: PatientListAppointmentDay[],
): PatientListAppointment | null => {
    const anytimeAppointments = currentAppointmentDays.find(
        (day) => day.date === "",
    );
    if (!anytimeAppointments) return null;

    const matchingAppointment = anytimeAppointments.appointments.find(
        (app) => app.id === newAppointment.id,
    );

    return matchingAppointment || null;
};

export const reducer: Reducer<PatientListsState> = (
    state = initialState,
    incomingAction: Action,
): PatientListsState => {
    const action = incomingAction as KnownAction;
    const { currentList, currentListPatientDuplicates } = state;

    const shouldResetList = [
        // lists are specific to orgs, so reset if this has been changed
        SELECT_ORG,
    ].includes(incomingAction.type);

    if (shouldResetList) {
        return resetCurrentList(state);
    }

    let newPatientList: PatientListAppointmentDay[] = [];
    let newState: PatientListsState;

    switch (action.type) {
        case GET_ALL_PATIENT_LIST_SUMMARIES_STARTED:
            return {
                ...state,
                allListSummariesLoading: true,
            };

        case GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS:
            return {
                ...state,
                allListSummaries: action.patientListSummaries,
                allListSummariesLoading: false,
                allListSummariesError: "",
            };

        case GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE:
            return {
                ...state,
                allListSummariesError: action.error,
                allListSummariesLoading: false,
                allListSummaries: null,
            };

        case INIT_CREATE_EDIT_LIST:
            newState = action.isNewList ? resetCurrentList(state) : state;

            return {
                ...newState,
                createOrEditListOrigin: action.createOrEditOrigin,
                createOrEditListInitialising: true,
                latestSearchedPatientListId: action.saveLastSearched
                    ? state.latestSearchedPatientListId
                    : null,
            };

        case CANCEL_INIT_CREATE_EDIT_LIST:
            return {
                ...state,
                createOrEditListInitialising: false,
            };

        case POST_CREATE_OR_EDIT_LIST_STARTED:
            return {
                ...state,
                createOrEditListSubmitting: true,
            };

        case POST_CREATE_OR_EDIT_LIST_SUCCESS:
            return {
                ...state,
                allListSummaries: updatePatientListSummaries(
                    state,
                    action.patientList,
                ),
                currentList: {
                    ...action.patientList,
                    appointmentDays: orderAppointments(
                        action.patientList.appointmentDays,
                        action.patientList.patientDetails,
                        state.currentListPatientsOrder,
                    ),
                },
                currentListConsultationIds: extractConsultationIds(
                    action.patientList.appointmentDays,
                ),
                latestSearchedPatientListId: action.patientList.patientListId,
                createOrEditListSubmitting: false,
                createOrEditListInitialising: false,
            };

        case POST_CREATE_OR_EDIT_LIST_FAILURE:
            return {
                ...state,
                createOrEditListSubmitting: false,
            };

        case GET_PATIENT_LIST_STARTED:
            // Do not show loading state if it is not the first time loading the list
            if (
                state.currentList &&
                state.currentList.patientListId ===
                    state.latestSearchedPatientListId
            ) {
                return state;
            }

            return {
                ...state,
                currentListLoading: true,
            };

        case GET_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                currentListLoading: false,
                currentList: {
                    ...action.patientList,
                    appointmentDays: orderAppointments(
                        action.patientList.appointmentDays,
                        action.patientList.patientDetails,
                        state.currentListPatientsOrder,
                    ),
                },
                currentListLastFailed: false,
                currentListConsultationIds: extractConsultationIds(
                    action.patientList.appointmentDays,
                ),
            };

        case GET_PATIENT_LIST_FAILURE:
            return {
                ...state,
                currentListLoading: false,
                currentListLastFailed: true,
                currentList: null,
                currentListConsultationIds: [],
            };

        case POST_DELETE_PATIENT_LIST_STARTED:
            return {
                ...state,
                deletingListLoading: true,
            };
        case POST_DELETE_PATIENT_LIST_SUCCESS:
            return {
                ...resetCurrentList(state),
                deletingListLoading: false,
                lastDeletedListId: action.patientListId,
                allListSummaries:
                    state.allListSummaries?.filter(
                        (summary) =>
                            summary.patientListId !== action.patientListId,
                    ) || null,
                latestSearchedPatientListId: null,
            };
        case POST_DELETE_PATIENT_LIST_FAILURE:
            return {
                ...state,
                deletingListLoading: false,
            };

        case INIT_PATIENT_LIST_SHARE:
            return {
                ...state,
                currentListSharingInitialising: true,
            };

        case OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL:
            return {
                ...state,
                workspaceSharingModalOpen: true,
            };

        case CANCEL_PATIENT_LIST_SHARE:
            return {
                ...state,
                currentListSharingInitialising: false,
            };

        case CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL:
            return {
                ...state,
                workspaceSharingModalOpen: false,
            };

        case POST_SHARE_PATIENT_LIST_STARTED:
            return {
                ...state,
                currentListSharing: true,
            };

        case SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_STARTED:
            return {
                ...state,
                currentListWorkspaceSharingRequestStarted: true,
            };

        case POST_SHARE_PATIENT_LIST_SUCCESS:
            newState = { ...state, currentListSharing: false };

            // Update the list summaries
            if (newState.allListSummaries) {
                newState = {
                    ...newState,
                    allListSummaries: newState.allListSummaries.map(
                        (summary) => {
                            if (
                                summary.patientListId === action.patientListId
                            ) {
                                return {
                                    ...summary,
                                    countShare: summary.countShare + 1,
                                };
                            }
                            return summary;
                        },
                    ),
                };
            }

            // Update the current list
            if (
                newState.currentList &&
                newState.currentList.patientListId === action.patientListId
            ) {
                newState = {
                    ...newState,
                    currentList: {
                        ...newState.currentList,
                        countShare: newState.currentList.countShare + 1,
                    },
                };
            }

            return newState;

        case SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_SUCCESS:
            newState = {
                ...state,
                currentListWorkspaceSharingRequestStarted: false,
            };
            // Update the current list
            if (
                newState.currentList &&
                newState.currentList.patientListId === action.patientListId
            ) {
                newState = {
                    ...newState,
                    currentList: {
                        ...newState.currentList,
                        listType: PatientListType.WorkspaceManaged,
                    },
                };
            }

            return newState;

        case POST_SHARE_PATIENT_LIST_FAILURE:
            return {
                ...state,
                currentListSharing: false,
            };

        case POST_ADD_PATIENT_TO_LIST_STARTED:
            return {
                ...state,
                currentListPatientAdding: true,
            };

        case POST_ADD_PATIENT_TO_LIST_SUCCESS:
            // the action we've received is for a list that doesn't match the
            // current list being viewed
            if (
                currentList === null ||
                currentList.patientListId !== action.patientListId
            ) {
                return {
                    ...state,
                    currentListPatientAdding: false,
                    currentListLastFailed: false,
                };
            }

            // We only expect a single patient to have been added, so there should
            // only be one appointmentDay (its date will be anytime), and a single
            // appointment within it. We only attempt to use the first item from
            // each of these collections.
            const addedAppointment = action.appointmentDays[0]?.appointments[0];

            // NHS numbers can be updated. If an old number is searched for, the
            // search result have the updated NHS number. If you then search for
            // the same old NHS number again, the front-end check on the input
            // will not identify a duplicate. Therefore, we need to search for a
            // duplicate on the returned result. If the returned item matches one
            // already in the list, we don't want to add it again.
            const foundAppointment =
                addedAppointment !== undefined
                    ? findAppointmentInAnytimeGroup(
                          addedAppointment,
                          currentList.appointmentDays,
                      )
                    : null;

            if (foundAppointment) {
                return {
                    ...state,
                    currentListPatientDuplicates: addPatientToDuplicates(
                        foundAppointment,
                        currentListPatientDuplicates,
                    ),
                    currentListPatientAdding: false,
                    currentListLastFailed: false,
                };
            }

            // aim here is to merge the newly created appointment into the matching
            // appointmentDay, if it exists. If it doesn't, we take the one
            // from the action and use that as is.
            const patientDetails = Object.assign(
                {},
                currentList.patientDetails,
                action.patientDetails,
            );
            const datedAppointmentDays = currentList.appointmentDays.filter(
                (day) => day.date !== "",
            );
            const anytimeDay = currentList.appointmentDays.find(
                (day) => day.date === "",
            );

            let appointmentDays: PatientListAppointmentDay[];

            if (anytimeDay !== undefined) {
                const day = Object.assign({}, anytimeDay);
                day.appointments = [...day.appointments, addedAppointment];
                appointmentDays = datedAppointmentDays.concat(day);
            } else {
                appointmentDays = datedAppointmentDays.concat(
                    action.appointmentDays,
                );
            }

            return {
                ...state,
                currentList: {
                    ...currentList,
                    patientListId: action.patientListId,
                    patientDetails,
                    appointmentDays: orderAppointments(
                        appointmentDays,
                        patientDetails,
                        state.currentListPatientsOrder,
                    ),
                },
                currentListConsultationIds:
                    extractConsultationIds(appointmentDays),
                currentListPatientAdding: false,
                currentListPatientAddLastFailed: false,
            };

        case POST_ADD_PATIENT_TO_LIST_FAILURE:
            return {
                ...state,
                currentListPatientAdding: false,
                currentListPatientAddLastFailed: true,
            };

        case POST_BULK_ADD_PATIENTS_TO_LIST_STARTED:
            return {
                ...state,
                currentListPatientsBulkAdding: action.isLoading
                    ? BulkAddLoadingState.PrepareLoading
                    : BulkAddLoadingState.None,
                currentListBulkAddErrors: [],
            };

        case POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED:
            return {
                ...state,
                currentListPatientsBulkAdding:
                    BulkAddLoadingState.ConfirmLoading,
                currentListBulkAddErrors: [],
            };

        case POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS:
            // the action we've received is for a list that doesn't match the
            // current list being viewed
            if (
                currentList === null ||
                currentList.patientListId !== action.patientListId
            ) {
                return {
                    ...state,
                    currentListPatientsBulkAdding: BulkAddLoadingState.None,
                    currentListPatientsBulkAddLastFailed: false,
                };
            }

            return {
                ...state,
                currentListPatientsBulkAdding: BulkAddLoadingState.None,
                currentListPatientsBulkAddLastFailed: false,
                currentListBulkAddErrors: action.errors,
            };

        case POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE:
            return {
                ...state,
                currentListPatientsBulkAdding: BulkAddLoadingState.None,
                currentListPatientsBulkAddLastFailed: true,
                currentListBulkAddErrors: [],
            };

        case RESET_BULK_ADD_PATIENTS_ERRORS:
            return {
                ...state,
                currentListBulkAddErrors: [],
            };

        case POST_REMOVE_PATIENTS_FROM_LIST_STARTED:
            return {
                ...state,
                currentListPatientRemoving: !action.isBatch,
                currentListPatientsBatchRemoving: action.isBatch,
            };

        case POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS:
            if (
                !state.currentList ||
                state.currentList.patientListId !== action.patientListId
            ) {
                return {
                    ...state,
                    currentListPatientRemoving: false,
                    currentListPatientsBatchRemoving: false,
                };
            }

            let remainingPatientIds: number[] = [];
            newPatientList = state.currentList.appointmentDays
                .map((day) => ({
                    ...day,
                    appointments: day.appointments.filter((apt) => {
                        const isEntryDeleted =
                            action.patientListEntryIds.includes(apt.id);

                        // While we work out the new list of appointments, we also work out the
                        // ids of the patients that are still in the list
                        if (!isEntryDeleted) {
                            remainingPatientIds = [
                                ...remainingPatientIds,
                                apt.patientId,
                            ];
                        }

                        return !isEntryDeleted;
                    }),
                }))
                .filter((day) => day.appointments.length > 0);

            // Work out new patient object using the remainingPatientIds
            // it should not include patients that have been completely removed
            // i.e. if patient A was only present once, it should not appear in the patientDetails obj
            const newPatientDetailsArray = filter(
                state.currentList.patientDetails,
                (patient) => {
                    return remainingPatientIds.includes(patient.patientId);
                },
            );
            const newPatientDetails = keyBy(
                newPatientDetailsArray,
                "patientId",
            );

            // Remove duplicates message for patients that have been deleted
            const currentListDuplicates =
                state.currentListPatientDuplicates.filter((apt) => {
                    return !action.patientListEntryIds.includes(apt.id);
                });

            return {
                ...state,
                currentListPatientRemoving: false,
                currentListPatientsBatchRemoving: false,
                currentList: {
                    ...state.currentList,
                    appointmentDays: newPatientList,
                    patientDetails: newPatientDetails,
                },
                currentListPatientDuplicates: currentListDuplicates,
                currentListConsultationIds:
                    extractConsultationIds(newPatientList),
            };

        case POST_REMOVE_PATIENTS_FROM_LIST_FAILURE:
            return {
                ...state,
                currentListPatientRemoving: false,
                currentListPatientsBatchRemoving: false,
            };

        case CHANGE_ORDER_METHOD:
            return {
                ...state,
                currentListPatientsOrder: action.order,
                currentList: state.currentList
                    ? {
                          ...state.currentList,
                          appointmentDays: orderAppointments(
                              state.currentList.appointmentDays,
                              state.currentList.patientDetails,
                              action.order,
                          ),
                      }
                    : null,
            };

        case RESET_CURRENT_LIST_ERRORS:
            return {
                ...state,
                currentListPatientAddLastFailed: false,
                currentListLastFailed: false,
            };

        case ADD_CURRENT_LIST_DUPLICATE:
            return {
                ...state,
                currentListPatientDuplicates: addPatientToDuplicates(
                    action.duplicatePatient,
                    state.currentListPatientDuplicates,
                ),
            };

        case REMOVE_CURRENT_LIST_DUPLICATE:
            return {
                ...state,
                currentListPatientDuplicates:
                    state.currentListPatientDuplicates.filter(
                        (apt) => apt.id !== action.duplicatePatient.id,
                    ),
            };

        case SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED:
            return {
                ...state,
                latestSearchedPatientListId: action.patientListId,
                latestSearchPatientListEntry: action.patientListAppointment,
            };

        case SET_LATEST_PATIENT_LIST_SEARCHED:
            return {
                ...state,
                currentListLastFailed: false,
                latestSearchedPatientListId: action.patientListId,
            };

        case RESET_CURRENT_LIST:
            return resetCurrentList(state);

        case POST_PATIENTS_VIDEO_PROGRESS_STARTED:
            return {
                ...state,
                currentListVideoProgressLoading: true,
                currentListVideoProgressError: "",
            };

        case POST_PATIENTS_VIDEO_PROGRESS_SUCCESS:
            return {
                ...state,
                currentListVideoProgressLoading: false,
                currentListVideoProgressError: "",
                currentListVideoProgressStatus: action.videoProgress,
            };

        case POST_PATIENTS_VIDEO_PROGRESS_FAILURE:
            return {
                ...state,
                currentListVideoProgressLoading: false,
                currentListVideoProgressError: action.error,
            };

        default:
            return state;
    }
};
