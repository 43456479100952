import { Conversation } from "@accurx/concierge/types";

export const getConversationParticipantForAnalytics = (
    conversation: Conversation,
): "WithHcp" | "WithPatient" => {
    switch (conversation.source.system) {
        case "Ticket":
            return "WithPatient";
        case "ClinicianMessaging":
            return "WithHcp";
    }
};
