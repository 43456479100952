import isNil from "lodash/isNil";

import { PatientThreadFilteredTicketView } from "shared/concierge/conversations/tickets/types/dto.types";
import {
    ConversationGroupPage,
    ConversationGroupRuleset,
} from "shared/concierge/conversations/types/conversationGroup.types";
import { isInstance } from "shared/concierge/conversations/utils";

import { mapTicketToConversation } from "./ConversationMapper";

/**
 * Maps from an external "Ticket" DTO version of a conversation group (Folder View) to an
 * internal domain view - a ConversationGroup.
 *
 * @param ruleset - The full ruleset used to build the folder request
 * @param folderView - An external "Folder view" DTO
 * @returns A conversation group
 */
export function mapPaginatedTicketResponseToConversationGroup(
    ruleset: ConversationGroupRuleset,
    folderView: PatientThreadFilteredTicketView | null | undefined,
): ConversationGroupPage | undefined {
    if (isNil(folderView)) {
        return undefined;
    }

    const mappedConversations = folderView.tickets
        ?.map((ticket) => mapTicketToConversation(ticket))
        .filter(isInstance);

    return {
        ruleset,
        conversations: mappedConversations ?? [],
        apiContinuation: folderView.continuationToken
            ? {
                  continuationToken: folderView.continuationToken,
                  fetchNewConversationsCutoff:
                      folderView.continuationToken.split("|")[0], // Date/time from the continuation token is the first part
              }
            : undefined,
    };
}
