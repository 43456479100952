import { MeaningfulActionProps } from "@accurx/analytics";
import { PatientListType } from "@accurx/api/patient-messaging";
import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { statusLabel } from "app/batchMessage/gp/BatchMessage.types";

import {
    FlemingLoggedInCustomProperties,
    HasErrorProps,
    OriginProps,
} from "../FlemingAnalytics/FlemingAnalyticsConstants";
import { TrackBatchEvent } from "./ChainAnalyticsTracker";

/**
 * - patientsUploaded: Number of patients in the CSV
 * - timeTaken: Time taken to upload the CSV in seconds
 **/
type BatchCsvUploadedBaseProps = {
    patientsUploaded: number;
    timeTaken: string;
};

/**
 * - patientsUploaded: Number of patients in the CSV
 * - timeTaken: Time taken to upload the CSV in seconds
 **/
type BatchCsvReviewBaseProps = {
    countTotal: number;
    countNotAccepted: number;
    countAccepted: number;
    uploadTime: string;
};

/**
 * - patientsIncluded: Number of patients with demographics
 * - patientsExcluded: Number of patients without demographics
 **/
type BatchContinuedToWriteMessageBaseProps = {
    patientsIncluded: number;
    patientsExcluded: number;
};

/**
 * - lengthOfMessage: Length of the message sent
 **/
type BatchContinuedToReviewMessageBaseProps = {
    lengthOfMessage: number;
};

/**
 * - batchType: SMS or Florey
 * - snomedCode: Snomed code attachted to the batch message
 * - floreyId: Id of florey graph chosen
 * - floreyName: Name of florey condition chosen
 * - messageTemplateDescription: Who owned the template and what was it titled
 * - patientResponseOnTemplate: Can the patient normally respond to the selected template
 * - withDocument: Was the batch sent with a document
 * - patientsSentTo: Number of patients the batch was sent to
 **/
type BatchMessageSentBaseProps = {
    batchId: string;
    batchType: string;
    snomedCode: string;
    floreyId: string;
    floreyName: string;
    messageTemplateDescription: string;
    patientResponseOnTemplate: boolean;
    withDocument: boolean;
    patientsSentTo: number;
    autoSaveEnabled: boolean;
    selfBookSlotType: string;
    appointmentsAvailableWhenSent: number | undefined;
    changedDefaultAssignee: boolean;
};

/**
 * - beastSeriesId: Id of BEAST message series chosen
 * - beastSeriesName: Name of BEAST message series chosen
 * - patientsSentTo: Number of patients the batch was sent to
 **/
type BatchBeastInvitesCreatedBaseProps = {
    beastSeriesId: string;
    beastSeriesName: string;
    patientsSentTo: number;
};

/**
 * - csvType: Which type of CSV was downloaded: Failed / NonTextable / Sent / Delivered
 **/
type BatchCsvDownloadedBaseProps = {
    csvType: string;
};

/**
 * - fromPage: Where the user redirected from
 **/
type BatchClickedAccurxPlusInfoBaseProps = {
    fromPage: string;
};

type BatchClickedSelfBookExperimentModalBaseProps = {
    objectName: string;
    countClick: number;
};

/**
 * - isGskOnly: whether the user can only send GSK batch, or all
 **/
type BatchFeatureAvailability = {
    isGskOnly: boolean;
};

/**
 * - resourceTitle: indicates which resource was clicked
 **/
type BatchCsvResourceBaseProps = {
    resourceTitle: string;
};

/**
 * - batchOverviewDownloadType: Type of csv dowload
 **/
type BatchCsvDowloadType = {
    batchOverviewDownloadType:
        | "Delivered"
        | "FailedTable"
        | "FailedModal"
        | "MissingNumber";
};

/**
 * - batchType: Type of csv dowload
 **/
type BatchType = {
    batchType: string;
};

/**
 * - batchSlotAvailable: availabilitiy for a slot type
 **/
type BatchSlot = {
    batchSlotAvailable: number;
};

/**
 * - batchSlotAvailable: availabilitiy for a slot type
 **/
type BatchSlotName = {
    slotName: string;
};

/**
 * - batchCapacityWarningSeen
 */
type BatchCapacityWarning = {
    batchCapacityWarningSeen: boolean;
};

/**
 * - batchPatientListSize: number of patients in batch file with textable numbers
 */
type BatchPatientList = {
    batchPatientListSize: number;
    countPatientNotContactable: number;
};

/**
 * - batchFragmentLimitCount: character count
 * - batchFragmentLimitMax: character max
 * - batchFragmentType: Type of message feedback
 **/
type BatchFragment = {
    batchFragmentLimitCount: number;
    batchFragmentLimitMax: number;
    batchFragmentType: "Warning" | "Error";
};

/**
 * - batchResourceName: indicates which resource was clicked
 **/
type BatchResourceUrl = {
    batchResourceName: string;
};

type BatchMessageSendBaseProps = {
    messageType: string;
    snomedCode: string | null;
    templateName: string | null;
    conditionId: string | null;
    floreyName: string | null;
    withPatientReplyLink: boolean | null;
    withAttachment: boolean | null;
    countAttachmentFromTemplate: number | null;
    countTotal: number;
    isAutoSaveEnabled: boolean | null;
    isPresetTemplate: boolean;
    messageSaveToRecord: boolean;
    withPatientInitiatedFollowUpLink: boolean;
    appOrigin: string | null;
};

type BatchAddPatientListProps = {
    batchType: string;
    batchSlotAvailable: number | null;
    batchInviteSnomedCode: string | null;
    batchDeclineSnomedCode: string | null;
    templateName: string | null;
    declineSettingMessageChanged: boolean | null;
};

type IsTrustFlow = {
    isTrustFlow: boolean;
};

export type BatchCsvUploadedProps = LoggedInCustomProperties &
    BatchCsvUploadedBaseProps;
export type BatchContinuedToWriteMessageProps = LoggedInCustomProperties &
    BatchContinuedToWriteMessageBaseProps;
export type BatchContinuedToReviewMessageProps = LoggedInCustomProperties &
    BatchContinuedToReviewMessageBaseProps;
export type BatchMessageSentProps = LoggedInCustomProperties &
    BatchMessageSentBaseProps;
export type BatchCsvDownloadedProps = LoggedInCustomProperties &
    BatchCsvDownloadedBaseProps;
export type BatchClickedAccurxPlusInfoProps = LoggedInCustomProperties &
    BatchClickedAccurxPlusInfoBaseProps;
export type BatchBeastInvitesCreatedProps = LoggedInCustomProperties &
    BatchBeastInvitesCreatedBaseProps;
export type BatchClickedSelfBookExperimentModalProps =
    LoggedInCustomProperties & BatchClickedSelfBookExperimentModalBaseProps;

/**
 * Overview page - When a user lands on the first page of the batch flow, which contains the table of previously sent batch messages
 **/
const trackBatchOverviewPageView = (props: LoggedInCustomProperties): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchOverview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Overview page - When a user cancel a sending from a batch message row
 **/
const trackBatchOverviewMessageCancelClick = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchOverviewMessageCancel",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Overview page - When a user open the details from a batch message row
 **/
const trackBatchOverviewMessageDetailClick = (
    props: LoggedInCustomProperties & {
        batchStatus: statusLabel;
        type: string;
        declineSettings: "Allowed" | "Not Allowed" | "N/A";
    },
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchOverviewMessageDetail",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Overview page - When a user download a csv (in the modal or table)
 **/
const trackBatchOverviewMessageDownloadClick = (
    props: LoggedInCustomProperties & BatchCsvDowloadType,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchOverviewMessageDownload",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Overview page - User started a new batch message
 **/
const trackBatchStartClick = (
    props: LoggedInCustomProperties & BatchFeatureAvailability,
): void => {
    TrackBatchEvent("New message created", props);

    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Choose page - when a user lands on the page where they can select an upload flow
 **/
const trackBatchTypeSelectPageView = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchTypeSelect",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Choose page - when a user select one of the flow
 **/
const trackBatchTypeSelectClick = (
    props: LoggedInCustomProperties & BatchType,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchTypeSelect",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Compose page - when a user lands on the page where they can review the content of the message
 **/
const trackBatchPatientMessageReviewPageView = (
    props: LoggedInCustomProperties & OriginProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientMessageReview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};
/**
 * Compose page - Attach a file to the message
 **/
const trackBatchComposeAttachClick = (
    props: LoggedInCustomProperties & IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeAttach",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
/**
 * Compose page - Have gone over the limit of allowed character
 **/
const trackBatchComposeFragmentLimitLoad = (
    props: LoggedInCustomProperties & OriginProps & BatchFragment,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeFragmentLimit",
        objectType: EventObjectType.Component,
        action: EventAction.Load,
        properties: fullProps,
    });
};
/**
 * Compose page - Have gone over the limit of allowed character
 **/
const trackBatchComposeNoSaveToRecordSelect = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeNoSaveToRecord",
        objectType: EventObjectType.Option,
        action: EventAction.Select,
        properties: fullProps,
    });
};
/**
 * Compose page - Have gone over the limit of allowed character
 **/
const trackBatchComposeSaveToRecordSelect = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeSaveToRecord",
        objectType: EventObjectType.Option,
        action: EventAction.Select,
        properties: fullProps,
    });
};
/**
 * Compose page - when a user select a florey from the dropdown
 **/
const trackBatchComposeFloreyOptionClick = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeFloreyOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};
/**
 * Compose page - when a user select a Rct from the dropdown
 **/
const trackBatchComposeRctOptionClick = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeRctOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Compose page - when a user select a Slot from the dropdown
 **/
const trackBatchComposeSlotOptionClick = (
    props: LoggedInCustomProperties & BatchSlot,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeSlotOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Compose page - when a user select a snomed from the dropdown
 **/
type SnomedOptionClickProps = LoggedInCustomProperties &
    OriginProps &
    BatchSlotName & {
        snomedCode: string;
        batchSnomedSelect: string;
    };
const trackBatchComposeSnomedOptionClick = (
    props: SnomedOptionClickProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeSnomedOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Compose page - when a user select a template from the dropdown
 **/
const trackBatchComposeTemplateOptionClick = (
    props: LoggedInCustomProperties & OriginProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposeTemplateOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Upload page - after a user clicks button to add patient list
 **/
const trackBatchAddPatientList = (
    props: LoggedInCustomProperties & BatchAddPatientListProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchAddPatientList",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Upload page - when a user land on the page to upload CSV
 **/
const trackBatchCsvPageView = (
    props: LoggedInCustomProperties &
        BatchSlot &
        BatchSlotName &
        BatchType &
        IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCsv",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Upload page - User opened a "help" resource on the Batch CSV upload page
 **/
const trackBatchCsvUploadResourceClick = (
    props: LoggedInCustomProperties & BatchCsvResourceBaseProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCsvUploadResource",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Upload page - User clicked to upload a batch message
 **/
const trackBatchCsvUploadClick = (
    props: LoggedInCustomProperties & IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCsvUpload",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Upload page - User uploaded a batch message successfully and will see the review page
 **/
const trackBatchCsvReviewPageView = (
    props: LoggedInCustomProperties & BatchCsvReviewBaseProps & IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCsvReview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Upload page - User uploaded a batch message unsuccessfully
 **/
const trackBatchCsvUploadErrorPageView = (
    props: LoggedInCustomProperties & IsTrustFlow,
): void => {
    TrackBatchEvent("Failed to upload patient list", props);

    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCsvUploadError",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/** Upload Review Paitent List page - User has uploaded a patient list and arrives on the review patient list page */
const trackBatchPatientListReviewPageView = (
    props: LoggedInCustomProperties &
        BatchSlot &
        BatchCapacityWarning &
        BatchPatientList &
        IsTrustFlow & {
            countMobileNumberOverride: number;
        },
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientListReview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Upload Review Patient List page - User removes the patient list and can start over
 */
const trackBatchRemovePatientListClick = (
    props: LoggedInCustomProperties &
        BatchCapacityWarning &
        BatchPatientList &
        IsTrustFlow &
        BatchSlot & {
            hasOverrideWarning: boolean;
            mobileOverridePercentage: number;
        },
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientListRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Review page - when a user lands on the page where for schedule batch
 **/
const trackBatchSchedulePageView = (props: LoggedInCustomProperties): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchSchedule",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Review page - when a user lands on the page where they review everything before submitting
 **/
const trackBatchMessageReviewPageView = (
    props: LoggedInCustomProperties & BatchSlot & BatchSlotName & BatchType,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchReview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Review page - when a user clicks on the button to send their batch message
 **/
const trackBatchPatientMessageSendClick = (
    props: LoggedInCustomProperties &
        BatchMessageSendBaseProps &
        IsTrustFlow &
        MeaningfulActionProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * All pages - Going one step back in the upload flow
 **/
const trackBatchBackClick = (
    props: LoggedInCustomProperties & OriginProps & IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * All pages - when a user click on a support link to learn more about something in Batch
 **/
const trackBatchResourceClick = (
    props: LoggedInCustomProperties & OriginProps & BatchResourceUrl,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchResource",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

type BatchPatientListDateImportButtonClickProps =
    BatchPatientListImportButtonClickProps & {
        countDaySelected: number;
    };
const trackBatchPatientListDateImportButtonClick = (
    props: FlemingLoggedInCustomProperties &
        BatchPatientListDateImportButtonClickProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientListDateImport",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

type BatchPatientListImportButtonClickProps = {
    countPatient: number;
    patientListType: PatientListType;
};
const trackBatchPatientListImportButtonClick = (
    props: FlemingLoggedInCustomProperties &
        BatchPatientListImportButtonClickProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientListImport",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

type BatchCopyPasteAddPatientButtonClickProps = {
    countPatient: number | undefined;
    errorReason?: "NoInput" | "ParseError";
    countErrorNhsNumberMultiple?: number;
    countErrorNhsNumberInvalid?: number;
    countErrorDateOfBirthInvalid?: number;
    countErrorDateOfBirthFuture?: number;
    countErrorDateOfBirthTooOld?: number;
};

const trackBatchCopyPasteAddPatientButtonClick = (
    props: FlemingLoggedInCustomProperties &
        BatchCopyPasteAddPatientButtonClickProps &
        HasErrorProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCopyPasteAddPatient",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

type BatchCopyPasteImportButtonClickProps = {
    countPatient: number;
};
const trackBatchCopyPasteImportButtonClick = (
    props: FlemingLoggedInCustomProperties &
        BatchCopyPasteImportButtonClickProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCopyPasteImport",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export type BatchPatientListSource = "Csv" | "CopyPaste" | "PatientList";
type BatchPatientListSourceButtonClickProps = {
    source: BatchPatientListSource;
};
const trackBatchPatientListSourceButtonClick = (
    props: FlemingLoggedInCustomProperties &
        BatchPatientListSourceButtonClickProps &
        IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchPatientListSource",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User continued to write message
 *
 * Event type: Button click
 **/
const trackBatchContinuedToWriteMessage = (
    props: BatchContinuedToWriteMessageProps,
): void => {
    TrackBatchEvent("Continue to write message", props);
};

/**
 * User continued to review the message
 *
 * Event type: Button click
 **/
const trackBatchContinuedToReview = (
    props: BatchContinuedToReviewMessageProps,
): void => {
    TrackBatchEvent("Continue to review", props);
};

/**
 * User sent the batch message
 *
 * Event type: Button click
 **/
const trackBatchMessageSent = (props: BatchMessageSentProps): void => {
    TrackBatchEvent("Message sent", props);
};

/**
 * User downloaded delivery status CSV
 *
 * Event type: Button click
 **/
const trackBatchDownloadedCsv = (props: BatchCsvDownloadedProps): void => {
    TrackBatchEvent("Downloaded patient list", props);
};

/**
 * User clicked accuRx plus info button
 *
 * Event type: Button click
 **/
const trackBatchClickedAccuRxPlusInfo = (
    props: BatchClickedAccurxPlusInfoProps,
): void => {
    TrackBatchEvent("Clicked Info Link for Batch accuRx Plus", props);
};

/**
 * User created the BEAST invites
 *
 * Event type: Button click
 **/
const trackBatchBeastInvitesCreated = (
    props: BatchBeastInvitesCreatedProps,
): void => {
    TrackBatchEvent("Created BEAST invites", props);
};

/**
 * User uploaded a batch message successfully
 *
 * Event type: API response
 **/
const trackBatchCsvUploadSucceeded = (props: BatchCsvUploadedProps): void => {
    TrackBatchEvent("Uploaded patient list", props);
};

/**
 * User clicked CTA button on Self Book Experiment modal
 *
 * Event type: Button click
 * */
const trackBatchClickedSelfBookModalCTAButton = (
    props: LoggedInCustomProperties &
        BatchClickedSelfBookExperimentModalBaseProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchOverviewSelfBookingModal",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/** EXCLUSIVE FOR TRUST FLOW */

const trackBatchComposePageView = (
    props: LoggedInCustomProperties &
        Pick<BatchCsvReviewBaseProps, "countAccepted"> &
        IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchCompose",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

const trackBatchComposePatientDetailViewClick = (
    props: LoggedInCustomProperties &
        Pick<BatchCsvReviewBaseProps, "countAccepted"> &
        IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchComposePatientDetailView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

const trackBatchConfirmPageView = (
    props: LoggedInCustomProperties &
        Pick<BatchCsvReviewBaseProps, "countAccepted"> &
        IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchConfirm",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

const trackBatchConfirmMessageStatusViewClick = (
    props: LoggedInCustomProperties & IsTrustFlow,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchConfirmMessageStatusView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

const trackBatchReviewPatientDetailViewClick = (
    props: LoggedInCustomProperties &
        IsTrustFlow & {
            patientDetailSection: "Can be contacted" | "Cannot be contacted";
        },
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "BatchReviewPatientDetailView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export {
    // New events: Mixpanel
    trackBatchOverviewPageView, // Overview
    trackBatchOverviewMessageCancelClick, // Overview
    trackBatchOverviewMessageDetailClick, // Overview
    trackBatchOverviewMessageDownloadClick, // Overview
    trackBatchStartClick, // Overview
    trackBatchTypeSelectPageView, // Choose
    trackBatchTypeSelectClick, // Choose
    trackBatchPatientMessageReviewPageView, // Compose
    trackBatchComposeAttachClick, // Compose
    trackBatchComposeFragmentLimitLoad, // Compose
    trackBatchComposeNoSaveToRecordSelect, // Compose
    trackBatchComposeSaveToRecordSelect, // Compose
    trackBatchComposeFloreyOptionClick, // Compose
    trackBatchComposeRctOptionClick, // Compose
    trackBatchComposeSlotOptionClick, // Compose
    trackBatchComposeSnomedOptionClick, // Compose
    trackBatchComposeTemplateOptionClick, // Compose
    trackBatchAddPatientList, // Upload
    trackBatchCsvPageView, // Upload
    trackBatchCsvUploadResourceClick, // Upload
    trackBatchCsvUploadClick, // Upload
    trackBatchCsvUploadErrorPageView, // Upload
    trackBatchCsvReviewPageView, // Upload
    trackBatchPatientListReviewPageView, // Patient List Review
    trackBatchRemovePatientListClick, // Patient List Review
    trackBatchSchedulePageView, // Schedule
    trackBatchMessageReviewPageView, // Review
    trackBatchPatientMessageSendClick, // Review
    trackBatchBackClick, // All pages
    trackBatchResourceClick, // All pages
    trackBatchClickedSelfBookModalCTAButton, //Overview
    trackBatchPatientListImportButtonClick,
    trackBatchPatientListDateImportButtonClick,
    trackBatchCopyPasteAddPatientButtonClick,
    trackBatchCopyPasteImportButtonClick,
    trackBatchPatientListSourceButtonClick,
    trackBatchComposePageView,
    trackBatchComposePatientDetailViewClick,
    trackBatchConfirmPageView,
    trackBatchConfirmMessageStatusViewClick,
    trackBatchReviewPatientDetailViewClick,
    // Old events: App insights
    trackBatchContinuedToWriteMessage,
    trackBatchContinuedToReview,
    trackBatchMessageSent,
    trackBatchDownloadedCsv,
    trackBatchClickedAccuRxPlusInfo,
    trackBatchBeastInvitesCreated,
    trackBatchCsvUploadSucceeded,
};
