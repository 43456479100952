import { Log } from "@accurx/shared";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { PatientRequestType } from "domains/concierge/internal/types/PatientsState";
import { PatientSummary } from "domains/concierge/types";

export const setPatientRequestTime = (
    state: Draft<ConciergeState["patients"]>,
    action: PayloadAction<{
        patientId: PatientSummary["patientId"];
        time: string | null;
        type: PatientRequestType;
    }>,
) => {
    const requestTimes = state.requestTimes;
    const { patientId, time, type } = action.payload;

    if (time) {
        requestTimes[`${patientId}-${type}`] = time;
    } else {
        if (requestTimes?.[`${patientId}-${type}`]) {
            delete requestTimes[`${patientId}-${type}`];
        } else {
            // being here indicates that something went wrong - let's log an error
            Log.error(
                "Attempt to delete a request time for a patient that doesn't exist in a store",
                {
                    tags: {
                        product: "Inbox",
                        type,
                    },
                },
            );
        }
    }

    return state;
};
