import { ButtonLink, Flex, Text } from "@accurx/design";

import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES_API } from "shared/Routes";

import { LOGIN_LAYOUT_CONSTANTS } from "../LogIn/LogIn.constants";
import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";

export const DownloadDesktop = (): JSX.Element => (
    <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.organisations}>
        <OuterStackPanel>
            <Flex flexDirection="column" gap="3">
                <Text variant="title" as="h1">
                    Install Accurx Desktop toolbar
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    <Text skinny>Step 1 - Download Accurx Desktop</Text>
                    <ButtonLink
                        href={ROUTES_API.downloadDesktop}
                        text="Download Accurx Desktop"
                        theme="secondary"
                    />

                    <Text skinny>Step 2 - Continue set up (EMIS only)</Text>
                    <ButtonLink
                        href="https://www.accurx.com/chain-install"
                        rel="noopener noreferrer"
                        text="Continue set up"
                        theme="secondary"
                    />
                </InnerStackPanel>
            </Flex>
        </OuterStackPanel>
    </LoginPageLayout>
);
