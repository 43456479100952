import React from "react";

import { Table } from "@accurx/design";

import { Days, WorkingHours } from "app/practices/patientTriage/types";

import { WorkingHoursRow } from "./WorkingHoursRow";
import { StaticWorkingHoursRow } from "./WorkingHoursRow/WorkingHoursRow";
import {
    StyledHeaderRow,
    StyledTableHeader,
    StyledTableWrapper,
} from "./WorkingHoursTable.styles";

type WorkingHoursTableProps = {
    workingHours: WorkingHours[];
    bankHolidayWorkingHours: Omit<WorkingHours, "day">;
    updateWorkDay: (updatedWorkDay: WorkingHours) => void;
    updatebankHolidayWorkingHours: (
        updatedbankHolidayWorkingHours: Omit<WorkingHours, "day">,
    ) => void;
};

const TableHeader = () => (
    <Table.Head>
        <StyledHeaderRow>
            <StyledTableHeader>Day</StyledTableHeader>
            <StyledTableHeader>Open</StyledTableHeader>
            <StyledTableHeader>Open Hours</StyledTableHeader>
            <StyledTableHeader>Limit</StyledTableHeader>
            <StyledTableHeader>Daily limit</StyledTableHeader>
        </StyledHeaderRow>
    </Table.Head>
);

const StaticTableHeader = () => (
    <Table.Head>
        <StyledHeaderRow>
            <StyledTableHeader>Day</StyledTableHeader>
            <StyledTableHeader>Open Hours</StyledTableHeader>
            <StyledTableHeader>Daily limit</StyledTableHeader>
        </StyledHeaderRow>
    </Table.Head>
);

const ReorderWorkingHoursOrder = (workingHours: WorkingHours[]) => {
    if (
        workingHours !== null &&
        workingHours.length > 0 &&
        workingHours[0].day === Days.Sunday
    ) {
        const Sunday = workingHours.shift();
        if (Sunday) workingHours.push(Sunday);
    }
    return workingHours;
};

export const WorkingHoursTable = ({
    workingHours,
    bankHolidayWorkingHours,
    updateWorkDay,
    updatebankHolidayWorkingHours,
}: WorkingHoursTableProps): JSX.Element => {
    workingHours = ReorderWorkingHoursOrder(workingHours);

    return (
        <StyledTableWrapper>
            <Table>
                <TableHeader />
                <Table.Body>
                    {workingHours.map((workingHour) => (
                        <WorkingHoursRow
                            key={workingHour.day}
                            workDay={workingHour}
                            updateWorkDay={updateWorkDay}
                        />
                    ))}
                    <WorkingHoursRow
                        workDay={bankHolidayWorkingHours}
                        updateWorkDay={updatebankHolidayWorkingHours}
                    />
                </Table.Body>
            </Table>
        </StyledTableWrapper>
    );
};

export const StaticWorkingHoursTable = ({
    workingHours,
    bankHolidayWorkingHours,
}: Omit<
    WorkingHoursTableProps,
    "updateWorkDay" | "updatebankHolidayWorkingHours"
>): JSX.Element => {
    workingHours = ReorderWorkingHoursOrder(workingHours);

    return (
        <StyledTableWrapper>
            <Table>
                <StaticTableHeader />
                <Table.Body>
                    {workingHours.map((workingHour) => (
                        <StaticWorkingHoursRow
                            key={workingHour.day}
                            workDay={workingHour}
                        />
                    ))}
                    <StaticWorkingHoursRow workDay={bankHolidayWorkingHours} />
                </Table.Body>
            </Table>
        </StyledTableWrapper>
    );
};
