/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import {
    Button,
    Card,
    Ds,
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    StackPanel,
    Text,
} from "@accurx/design";

import {
    StyledCardContent,
    StyledDownloadLink,
} from "./DocumentEntryCard.styles";

type DocumentEntryCardPreviewProps = Omit<
    DocumentEntryCardProps,
    "documentType" | "uploadedAt" | "downloadUrl" | "handleDelete"
>;

type DocumentEntryCardProps = {
    title: string;
    description: string;
    documentType?: string;
    uploadedAt: string;
    downloadUrl: string;
    handleDelete: () => void;
};

const DocumentEntryCardPreview = ({
    title,
    description,
}: DocumentEntryCardPreviewProps) => (
    <Card spacing={2}>
        <StyledCardContent>
            <StackPanel orientation="horizontal" gutter={2}>
                <Icon
                    name="Document"
                    theme="Line"
                    colour="night"
                    size={6}
                    halo={{ colour: "greyscale" }}
                />
                <div>
                    <StackPanel
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        gutter={1}
                    >
                        <Text variant="label" skinny>
                            {title}
                        </Text>
                    </StackPanel>
                    <StackPanel orientation="horizontal" gutter={2}>
                        <Text variant="preview">{description}</Text>
                    </StackPanel>
                </div>
            </StackPanel>
        </StyledCardContent>
    </Card>
);

const DocumentEntryCard = ({
    title,
    description,
    documentType,
    uploadedAt,
    downloadUrl,
    handleDelete,
}: DocumentEntryCardProps) => (
    <Card spacing={2}>
        <StyledCardContent>
            <StackPanel orientation="horizontal" gutter={2}>
                <Icon
                    name="Document"
                    theme="Line"
                    colour="night"
                    size={6}
                    halo={{ colour: "greyscale" }}
                />
                <div>
                    <StackPanel
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        gutter={1}
                    >
                        <Text variant="label" skinny>
                            {title}
                        </Text>
                        {documentType && (
                            <Ds.Badge color="greyscale">
                                {documentType}
                            </Ds.Badge>
                        )}
                    </StackPanel>
                    <StackPanel orientation="horizontal" gutter={2}>
                        <Text variant="preview">{description}</Text>
                        <Text variant="preview">{uploadedAt}</Text>
                    </StackPanel>
                </div>
            </StackPanel>
            <StackPanel
                orientation="horizontal"
                gutter={1.5}
                verticalContentAlignment="center"
            >
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            theme="secondary"
                            icon={{
                                name: "More",
                                style: "Fill",
                                title: "More",
                                id: "more-btn",
                            }}
                        />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverItem onClick={handleDelete}>
                            <StackPanel
                                gutter={0.5}
                                orientation="horizontal"
                                verticalContentAlignment="center"
                            >
                                <Icon
                                    name="Bin"
                                    theme="Line"
                                    colour="red"
                                    size={4}
                                />
                                <Text variant="label" skinny colour="metal">
                                    Delete
                                </Text>
                            </StackPanel>
                        </PopoverItem>
                    </PopoverContent>
                </Popover>
                <StyledDownloadLink href={downloadUrl} download>
                    <Button
                        as="span"
                        theme="secondary"
                        icon={{
                            name: "Save",
                            style: "Line",
                            colour: "blue",
                        }}
                        text="Download"
                    />
                </StyledDownloadLink>
            </StackPanel>
        </StyledCardContent>
    </Card>
);

export { DocumentEntryCard, DocumentEntryCardPreview };
