import { CollaborativeWorkspaceResponse } from "@accurx/api/account";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import { IOrganisation } from "api/FlemingDtos";

import {
    InviteUserToWorkspaceRequest,
    InviteUserToWorkspaceResponse,
    UpdateWorkspaceInviteRequest,
    WorkspaceInvitesForCurrentUserResponse,
} from "./WorkspaceDtos";

const ENDPOINTS = {
    workspace: "/api/account/workspace",
    workspacesIdInvites: "/api/portal/workspaces/:workspaceId/invites",
    workspaceInvites: "/api/account/workspaceInvites",
    workspaceConnection:
        "/api/portal/registerConnectedWebClientInWorkspace/:workspaceId",
} as const;

export const getWorkspaces = (
    organisationId: number,
): Promise<IWrappedResult<CollaborativeWorkspaceResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.workspace,
            query: `?organisationId=${organisationId}`,
        }),
    );

export const inviteUserToWorkspace = (
    workspaceId: number,
    inviteeEmailAddress: string,
): Promise<IWrappedResult<InviteUserToWorkspaceResponse>> => {
    const request: InviteUserToWorkspaceRequest = { inviteeEmailAddress };
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.workspacesIdInvites,
            params: { workspaceId: workspaceId.toString() },
        }),
        request,
    );
};

export const acceptWorkspaceInvitation = async (
    workspaceId: number,
): Promise<IWrappedResult<IOrganisation>> => {
    const body: UpdateWorkspaceInviteRequest = { workspaceId };
    return await httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.workspaceInvites,
        }),
        body,
    );
};

export const getWorkspaceInvitesForCurrentUser = async (): Promise<
    IWrappedResult<WorkspaceInvitesForCurrentUserResponse>
> => {
    return await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.workspaceInvites,
        }),
    );
};

export const registerWorkspaceConnection = (
    workspaceId: number,
): Promise<IWrappedResult<undefined>> => {
    return httpClient.putJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.workspaceConnection,
            params: { workspaceId: workspaceId.toString() },
        }),
        {},
    );
};
