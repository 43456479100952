import { useEffect, useState } from "react";

import { SessionStorageHelpers } from "@accurx/shared";
import { useLocation } from "react-router-dom";

// query params can be changed when the route independently changes so we want to save it once we see it
export const STORAGE_KEY = "AccurxDesktopVersion";

// A query param `desktopVersion` is provided when opening a WebView window
export const useDesktopVersion = (): string | null => {
    const [desktopVersion, setDesktopVersion] = useState<string | null>(
        SessionStorageHelpers.getSessionStorageItem(STORAGE_KEY),
    );

    const location = useLocation();

    const updateDesktopVersion = (version: string) => {
        SessionStorageHelpers.setSessionStorageItem(STORAGE_KEY, version);
        setDesktopVersion(version);
    };

    useEffect(() => {
        new URLSearchParams(location.search)
            .getAll("desktopVersion")
            .forEach((version) => {
                updateDesktopVersion(version);
            });
    }, [location.search]);

    return desktopVersion;
};
