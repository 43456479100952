import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
} from "./FlemingAnalyticsConstants";

type NavigationOrigin = "Web" | "Desktop" | "URL";

type PageOrigin =
    | "OrganisationHomepage"
    | "ReportingHomepage"
    | "PatientTriageReport"
    | "ResponseRatesReport"
    | "FragmentsReport"
    | "DesktopToolbar";

export type ReportName = Extract<
    PageOrigin,
    "PatientTriageReport" | "ResponseRatesReport" | "FragmentsReport"
>;

export type ReportingHomepagePageViewProps = {
    navigationOrigin: NavigationOrigin;
    pageOrigin?: Exclude<PageOrigin, "ReportingHomepage">;
};

/**
 * When user navigates to the Reporting homepage
 *
 * Event type: Page view
 * */
export const trackReportingHomepagePageView = (
    props: FlemingLoggedInCustomProperties & ReportingHomepagePageViewProps,
): void => {
    GenericTrackEvent({
        object: "ReportingHomepage",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

export type ReportingPageViewProps = {
    navigationOrigin: Extract<NavigationOrigin, "Web" | "URL">;
    pageOrigin?: Exclude<PageOrigin, "DesktopToolbar">;
    currentTab: string;
    reportName: ReportName;
};

/**
 * When user navigates to a specific report page
 *
 * Event type: Page view
 * */
export const trackReportingPageView = (
    props: FlemingLoggedInCustomProperties & ReportingPageViewProps,
): void => {
    GenericTrackEvent({
        object: "Reporting",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

export type ReportingPageLoadProps = ReportingPageViewProps &
    Partial<{
        hasError: boolean;
        errorReason: string;
        uiResponseTimeMs: number;
    }>;

/**
 * Tracks when a report has finished loading
 *
 * Event type: Page view
 * */
export const trackReportingPageLoad = (
    props: FlemingLoggedInCustomProperties & ReportingPageLoadProps,
): void => {
    GenericTrackEvent({
        object: "Reporting",
        objectType: EventObjectType.Page,
        action: EventAction.Load,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
    });
};
