import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledButton = styled(Button)`
    margin-top: ${Tokens.SIZES[1]};
    justify-content: center;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${Tokens.SIZES[1]};
`;
