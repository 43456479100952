import { Flex } from "@accurx/design";

import { QuestionnaireCard } from "./QuestionnaireCard";

export const QuestionnaireListLoading = ({
    workspaceId,
}: {
    workspaceId: number;
}) => (
    <div role="status">
        <Flex gap="1" flexDirection="column">
            <QuestionnaireCard workspaceId={workspaceId} isLoading />
            <QuestionnaireCard workspaceId={workspaceId} isLoading />
            <QuestionnaireCard workspaceId={workspaceId} isLoading />
            <QuestionnaireCard workspaceId={workspaceId} isLoading />
        </Flex>
    </div>
);
