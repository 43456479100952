import { Card, Flex, Icon, Text } from "@accurx/design";

import { SnomedCode } from "../SnomedCode.types";
import { StyledButton, StyledFlex } from "./SnomedSummaryCard.styles";

type SnomedCardProps = {
    onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    value: SnomedCode;
};

export const SnomedSummaryCard = ({
    onRemove,
    disabled,
    value,
}: SnomedCardProps) => {
    const { term, conceptId } = value;

    return (
        <Card spacingBody={0} isElevated={false}>
            <StyledFlex gap="3" justifyContent="space-between">
                <Flex gap="1" alignItems="center">
                    <Icon name="Tag" size={3} />
                    <Flex flexDirection="column">
                        <Text skinny colour="night">
                            {term}
                        </Text>
                        <Text
                            variant="preview"
                            skinny
                            colour="metal"
                        >{`Concept ID: ${conceptId}`}</Text>
                    </Flex>
                </Flex>
                <Flex alignItems="center">
                    <StyledButton
                        aria-label="Remove SNOMED code"
                        theme="secondary"
                        text="Remove"
                        icon={{
                            name: "Cross",
                            colour: "red",
                            props: {
                                size: 3,
                            },
                        }}
                        disabled={disabled}
                        onClick={onRemove}
                    />
                </Flex>
            </StyledFlex>
        </Card>
    );
};
