import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledWrapper = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "2",
})`
    padding: ${UI.Tokens.SIZES[2]};
`;

export const StyledTextWithNewLines = styled(UI.Text).attrs({
    skinny: true,
    variant: "preview",
})`
    white-space: pre-line;
    word-break: break-word;
`;

export const StyledList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;
