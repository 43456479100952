import { SortDirection } from "@accurx/design";
import {
    COLUMN_SORT_DIRECTIONS,
    INITIAL_TABLE_SORT_OPTIONS,
} from "domains/appointment-reminders/constants";
import { useHistory } from "react-router";

export type SortOptions = {
    sortColumnId: string;
    sortDirection: SortDirection;
};

const getValidatedSortColumn = (param: string): string | undefined => {
    const dateOptionValues = Object.keys(COLUMN_SORT_DIRECTIONS);
    return dateOptionValues.find(
        (val) => val.toLowerCase() === param.toLowerCase(),
    );
};

const getValidatedSortDirection = (
    param: string,
): SortDirection | undefined => {
    return param === "asc" || param === "desc" ? param : undefined;
};

export const useSortingColumns = () => {
    const history = useHistory();
    const searchParams = new URLSearchParams(history.location.search);

    const urlSortColumn = getValidatedSortColumn(
        searchParams.get("sort") || "",
    );
    const urlSortDirection = getValidatedSortDirection(
        searchParams.get("sortDirection") || "",
    );

    const updateSorting = (newSorting: SortOptions) => {
        searchParams.set("sort", newSorting.sortColumnId);
        searchParams.set("sortDirection", newSorting.sortDirection);

        history.replace(
            `${history.location.pathname}?${searchParams.toString()}`,
        );
    };

    const calculatedSortColumn =
        urlSortColumn || INITIAL_TABLE_SORT_OPTIONS.sortColumnId;

    const calculatedSortDirection = urlSortDirection
        ? COLUMN_SORT_DIRECTIONS[calculatedSortColumn][urlSortDirection][0]
        : COLUMN_SORT_DIRECTIONS[calculatedSortColumn].initial;

    return {
        sortColumn: calculatedSortColumn,
        sortDirection: calculatedSortDirection,
        updateSorting,
    };
};
