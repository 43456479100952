import { Tokens } from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

const {
    COLOURS: { primary },
} = Tokens;

export const StyledDoneButton = styled(Pill.Button)`
    &:enabled:not(:focus) {
        box-shadow: inset 0 -2px 0 ${primary.green[50]},
            inset 0 0 0 1px ${primary.green[50]};
    }

    &:hover:not(:focus, :disabled) {
        box-shadow: inset 0 -2px 0 ${primary.green[100]},
            inset 0 0 0 1px ${primary.green[100]};
    }

    &:focus,
    &:active {
        background: ${Tokens.COLOURS.primary.green[10]};
    }
`;
