import { useConciergeSelector } from "domains/concierge/internal/context";

import { useConversation } from "./useConversation";

/** Determines whether for the given conversationId and userId we will
 * notify the user of unread messages in the conversation.
 */
export const useIsConversationNotifiableForUser = ({
    conversationId,
    userId,
}: {
    conversationId: string;
    userId: string;
}) => {
    const conversation = useConversation({ conversationId });
    const teamMembership = useConciergeSelector(
        (state) => state.conversations.teamMembership,
    );

    if (!conversation || conversation.status !== "Open") {
        return false;
    }

    const { assignee } = conversation;

    // The conversation is notifiable if the conversation is assigned to the user
    if (assignee.type === "User" && assignee.id === userId) {
        return true;
    }

    // The conversation is notifiable if the conversation is assigned to a team
    // the user is a member of
    if (assignee.type === "Team" && teamMembership[assignee.id]) {
        return true;
    }

    return false;
};
