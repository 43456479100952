import { FormEvent } from "react";

import { Button, Card, Flex, Text } from "@accurx/design";

import { SendConfirmCodeProps } from "../TwoFactorModels";
import { StyledFeedback } from "./SendCodeCard.styles";

export type SendCodeCardProps = SendConfirmCodeProps & {
    onSubmit: () => void;
};

export const SendCodeCard = ({
    onSubmit,
    error,
    isLoading,
    text: { title, mainText, submitButtonText },
    cancelButton,
}: SendCodeCardProps): JSX.Element => {
    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card spacing={3} header={title ?? undefined}>
                <Flex flexDirection="column" gap="5">
                    <div>
                        {error && (
                            <StyledFeedback
                                colour="error"
                                title="An error occured"
                            >
                                <Text skinny>{error}</Text>
                            </StyledFeedback>
                        )}
                        {mainText}
                    </div>
                    <Flex justifyContent="flex-end" gap="2">
                        {cancelButton && cancelButton}
                        <Button
                            text={submitButtonText}
                            type="submit"
                            disabled={isLoading}
                        />
                    </Flex>
                </Flex>
            </Card>
        </form>
    );
};
