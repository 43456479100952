import { generatePath, useHistory, useParams } from "react-router";

import { BatchMessageId } from "app/batchMessage/gp/BatchMessage.types";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { ChoosePatientsFromList } from "./components/ChoosePatientsFromList";

export const AddPatientsFromPatientListPage = () => {
    const history = useHistory();
    const { workspaceId } = useParams<{ workspaceId: string }>();

    return (
        <ChoosePatientsFromList
            onBatchMessageCreated={(batchId: BatchMessageId) => {
                const path = generatePath(
                    ROUTES_WORKSPACE.batchMessageTrustReview,
                    {
                        workspaceId,
                        batchId: batchId.id,
                    },
                );
                history.push(path);
            }}
        />
    );
};
