import { useConciergeSelector } from "domains/concierge/internal/context";

export const useWithCareProvidersWorkspaceUnreadCount = () => {
    return useConciergeSelector<{
        unreadCount: number;
        status: "success" | "loading" | "error";
    }>((state) => {
        const unreadCounts =
            state.conversations.unreadCounts.clinicianMessaging;

        if (unreadCounts.queryStatus === "success") {
            let combinedTeamUnreadCount = 0;

            for (const teamId in state.teams.items) {
                if (state.teams.items[teamId].isMember) {
                    combinedTeamUnreadCount += unreadCounts.teams[teamId] ?? 0;
                }
            }

            return {
                status: unreadCounts.queryStatus,
                unreadCount: unreadCounts.user + combinedTeamUnreadCount,
            };
        } else {
            return {
                status: unreadCounts.queryStatus,
                unreadCount: 0,
            };
        }
    });
};
