import { LoadingStatus } from "shared/LoadingStatus";

import { Actions, KnownAction } from "./ManageTemplates.actions";
import { DisplayTemplate, Template } from "./ManageTemplates.types";
import { getCategoryHeading } from "./ManageTemplates.utils";

export type ManageTemplatesState = {
    messageTemplates: DisplayTemplate[];
    messageTemplatesAccurx: DisplayTemplate[];
    messageTemplatesLoadingStatus: LoadingStatus;
    messageTemplatesOrganisation: DisplayTemplate[];
    messageTemplatesUser: DisplayTemplate[];
    errorText: string;
};

export const initialState: ManageTemplatesState = {
    messageTemplates: [],
    messageTemplatesAccurx: [],
    messageTemplatesLoadingStatus: LoadingStatus.Initial,
    messageTemplatesOrganisation: [],
    messageTemplatesUser: [],
    errorText: "",
};

export const reducer = (
    state = initialState,
    action: KnownAction,
): ManageTemplatesState => {
    switch (action.type) {
        case Actions.GET_MESSAGE_TEMPLATES:
            return {
                ...state,
                messageTemplatesLoadingStatus: LoadingStatus.Loading,
            };
        case Actions.GET_MESSAGE_TEMPLATES_SUCCESS:
            const displayMessageTemplates: DisplayTemplate[] =
                action.messageTemplates
                    .map((template: Template) => ({
                        ...template,
                        categoryHeading: getCategoryHeading(template.path),
                    }))
                    .sort((a, b) => {
                        // Sort templates by title
                        if (a.title > b.title) {
                            return 1;
                        }
                        if (a.title < b.title) {
                            return -1;
                        }
                        return 0;
                    })
                    .sort((a, b) => {
                        // Ensure templates are sorted by categoryHeading
                        if (a.categoryHeading > b.categoryHeading) {
                            return 1;
                        }
                        if (a.categoryHeading < b.categoryHeading) {
                            return -1;
                        }
                        return 0;
                    });
            const messageTemplatesUser = displayMessageTemplates.filter(
                (template) => template.owner === "User",
            );
            const messageTemplatesOrganisation = displayMessageTemplates.filter(
                (template) => template.owner === "Organisation",
            );
            const messageTemplatesAccurx = displayMessageTemplates.filter(
                (template) => template.owner === "AccuRx",
            );
            return {
                ...state,
                messageTemplates: displayMessageTemplates,
                messageTemplatesUser,
                messageTemplatesOrganisation,
                messageTemplatesAccurx,
                messageTemplatesLoadingStatus: LoadingStatus.Loaded,
            };
        case Actions.GET_MESSAGE_TEMPLATES_FAILURE:
            return {
                ...state,
                messageTemplatesLoadingStatus: LoadingStatus.Failed,
            };
        case Actions.UPDATE_MESSAGE_TEMPLATE_SENDTYPE_SUCCESS:
            return {
                ...state,
                errorText: "",
            };
        case Actions.UPDATE_MESSAGE_TEMPLATE_SENDTYPE_FAILURE:
            // If the updating of the sendtype is a failure, ensure state is updated accordingly to revert the state
            const templateIndex = state.messageTemplates.findIndex(
                (template) => template.id === action.templateId,
            );

            if (templateIndex === -1 || action.originalTemplate === undefined) {
                // if haven't found a matching template, don't update state
                return state;
            } else {
                // otherwise, replace the template item with the originalTemplate
                const initialTemplates = state.messageTemplates;
                const updatedMessageTemplates: DisplayTemplate[] = [
                    ...initialTemplates.slice(0, templateIndex),
                    action.originalTemplate,
                    ...initialTemplates.slice(
                        templateIndex + 1,
                        initialTemplates.length,
                    ),
                ];

                return {
                    ...state,
                    messageTemplates: updatedMessageTemplates,
                    errorText: action.errorText,
                };
            }
        default:
            return state;
    }
};
