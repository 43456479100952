import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { ToggleStatusActionButton } from "domains/inbox/components/ConversationView/components/ConversationActions/ToggleStatusActionButton";
import { userflowIds } from "domains/inbox/util";
import { getConversationDescription } from "domains/inbox/util/getConversationDescription";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";

import {
    StyledDoneConversationContainer,
    StyledInnerContainer,
    StyledMessageActionsButton,
    StyledOuterContainer,
} from "./MessageActionsBar.styles";

type MessageActionsTypes = {
    isComposing: boolean;
    isComposingNote: boolean;
    conversation: Conversation;
    toggleReply: () => void;
    toggleNote?: () => void;
};

const getConversationTrackingFields = (conversation: Conversation) => {
    return {
        conversationDescription: getConversationDescription(conversation),
        conversationId: getConversationServerId(conversation),
        conversationStartTimestampUtc: conversation.items[0]?.createdAt,
        conversationType: getConversationType(conversation),
        conversationRequestType: getConversationRequestType(conversation),
        conversationParticipant:
            getConversationParticipantForAnalytics(conversation),
    };
};

export const MessageActionsBar = ({
    isComposing,
    isComposingNote,
    conversation,
    toggleReply,
    toggleNote,
}: MessageActionsTypes) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    return (
        <StyledOuterContainer>
            <StyledInnerContainer
                data-userflow-id={
                    userflowIds.conversationView.messageActionsBar
                }
            >
                {conversation.status !== "Done" && (
                    <UI.Cell>
                        <StyledMessageActionsButton
                            data-userflow-id={
                                userflowIds.conversationView.replyButton
                            }
                            text={isComposing ? "Continue reply" : "Reply"}
                            theme="transparent"
                            icon={{
                                name: "Reply",
                            }}
                            onClick={() => {
                                track("ConversationReply Button Click", {
                                    ...nativeTrackingFields,
                                    ...getConversationTrackingFields(
                                        conversation,
                                    ),
                                    version: "2",
                                });

                                toggleReply();
                            }}
                        />
                    </UI.Cell>
                )}
                {conversation.status === "Done" && (
                    <StyledDoneConversationContainer>
                        <UI.Text colour="metal" as="span" variant="note">
                            Re-open conversation to reply
                        </UI.Text>
                        <ToggleStatusActionButton
                            conversation={conversation}
                            productOriginAnalytics="MessageActions"
                        />
                    </StyledDoneConversationContainer>
                )}
                {conversation.status !== "Done" && toggleNote && (
                    <UI.Cell>
                        <StyledMessageActionsButton
                            text={isComposingNote ? "Continue note" : "Note"}
                            theme="transparent"
                            icon={{
                                name: "Document",
                            }}
                            onClick={toggleNote}
                        />
                    </UI.Cell>
                )}
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
