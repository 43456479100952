import { ReactNode, useState } from "react";

import { SplitButtonGroup } from "@accurx/navigation";
import { Slot } from "@radix-ui/react-slot";
import { StyledTriggerButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";

import { PatientSearchModal } from "./PatientSearchModal";

export const PatientSearch = ({ children }: { children?: ReactNode }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <SplitButtonGroup>
                <Slot onClick={() => setModalOpen(true)}>
                    {children || (
                        <StyledTriggerButton
                            icon={{
                                name: "SearchPerson",
                                style: "Fill",
                                title: "Search patient",
                            }}
                        />
                    )}
                </Slot>
            </SplitButtonGroup>

            <PatientSearchModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
            />
        </>
    );
};
