import { MeaningfulActionProps } from "@accurx/analytics";
import {
    AnalyticsProperties,
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingAnalyticsProductType,
    FlemingLoggedInCustomProperties,
} from "./FlemingAnalyticsConstants";
import { TrackFlemingEvent } from "./FlemingEventTracker";

/** Props used by all analytics events in message practice */
export type MessagePracticeAnalyticsProps = FlemingLoggedInCustomProperties;

type HasErrorProp = {
    hasError: boolean;
};

type IsTestPatient = {
    isTestPatient: boolean;
};

/**
 * Prepends `Message Practice - ` in front of all of the exported analytics functions
 * */
const TrackMessagePracticeEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    TrackFlemingEvent(`Message Practice - ${eventName}`, props);
};

/** User opened the template dropdown */
export const trackMessagePracticeTemplateDropdownOpened = (
    props: MessagePracticeAnalyticsProps,
): void => {
    TrackMessagePracticeEvent("Template dropdown opened", props);
};

type MessagePracticeTemplateSelectedProps = MessagePracticeAnalyticsProps & {
    template: string;
};

/** User selected a template */
export const trackMessagePracticeTemplateSelected = (
    props: MessagePracticeTemplateSelectedProps,
): void => {
    TrackMessagePracticeEvent("Template selected", props);
};

/**
 * User clicked the attach button
 *
 * Event type: Button clicked
 * */
export const trackMessagePracticeClickAttachButton = (
    props: MessagePracticeAnalyticsProps,
): void => {
    TrackMessagePracticeEvent("Clicked Attach button", props);
};

type MessagePracticeUseCaseSelectedPropsLegacy =
    MessagePracticeAnalyticsProps & {
        useCase: string;
    };

/**
 * When user uses the "Compose Message" dropdown  to select a use case.
 *
 * Event type: Dropdown item clicked
 * */
export const trackMessagePracticeUseCaseSelectedLegacy = (
    props: MessagePracticeUseCaseSelectedPropsLegacy,
): void => {
    TrackMessagePracticeEvent(
        "User selected a message practice use case",
        props,
    );
};

type MessagePracticeUseCaseSelectedProps = {
    optionSelected: string;
} & MessagePracticeAnalyticsProps;

export const trackMessagePracticeUseCaseSelected = (
    props: MessagePracticeUseCaseSelectedProps,
): void => {
    GenericTrackEvent({
        object: "ProductOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps({
            ...props,
            productName: "ClinicianMessage",
        }),
    });
};

/**
 * When user opens the the "Compose Message" dropdown, regardless of whether they select a use case.
 *
 * Event type: Dropdown menu opened
 * */
export const trackMessagePracticeUseCaseDropdownOpened = (
    props: MessagePracticeAnalyticsProps,
): void => {
    TrackMessagePracticeEvent("User opened message practice dropdown", props);
};

/** Attachments */
type MessagePracticeAttachedFileProps = MessagePracticeAnalyticsProps & {
    successfullyAttached: boolean;
};

/**
 * User attached a file
 *
 * Event type: API response
 * */
export const trackMessagePracticeAttachedFile = (
    props: MessagePracticeAttachedFileProps,
): void => {
    TrackMessagePracticeEvent("Attached file", props);
};

type MessagePracticeFromOtherProductProps = MessagePracticeAnalyticsProps & {
    otherProduct: FlemingAnalyticsProductType;
};
/**
 * User accessed message practice from another product
 *
 * Event type: Button click
 * */
export const trackMessagePracticeAccessedFromAnotherProduct = (
    props: MessagePracticeFromOtherProductProps,
): void => {
    TrackMessagePracticeEvent("Accessed from another product", props);
};

export type ClinicianMessageSend = {
    templateName?: string;
    templateGroup?: string;
    withTemplate: boolean;
    withAttachment: boolean;
    countAttachment: number;
    origin: string;
    appOrigin?: string;
    clinicianConversationId?: number;
    countParticipants?: number;
    emailReferrer?: string;
    isReply: boolean;
    usedKeyboardShortcut: boolean;
} & HasErrorProp &
    MeaningfulActionProps &
    MessagePracticeAnalyticsProps &
    IsTestPatient;

/**
 * User clicked the send button on the message practice page
 *
 * Event type: Button click
 * */
export const trackMessagePracticeSendButtonClick = (
    props: ClinicianMessageSend,
): void => {
    GenericTrackEvent({
        object: "ClinicianMessageSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 5 }),
    });
};

export type MessagePracticeStaffAvailabilityProps = {
    practiceStaffOnlineCount: number | undefined;
    practiceStaffOnlineDate: string | undefined;
    practiceStaffOnlineError: string | undefined;
} & MessagePracticeAnalyticsProps;

/**
 * A call to get the number of available practices completed.
 * Event reports the availability of users at the practice
 *
 * Event type: Request completes after page load
 * */
export const trackMessagePracticeStaffAvailability = (
    props: MessagePracticeStaffAvailabilityProps,
): void => {
    GenericTrackEvent({
        object: "PracticeStaffAvailability",
        objectType: EventObjectType.Component,
        action: EventAction.Load,
        properties: AddRudderStackAnalyticProps(props),
    });
};

export type MessagePracticePreviousConversationsProps = (
    | {
          hasError: false;
          countConversation: number;
      }
    | { hasError: true }
) &
    MessagePracticeAnalyticsProps;

/**
 * The previous messages for a patient and user are displayed on the patient
 * profile.
 *
 * Event type: Request completes after page load
 * */
export const trackClinicianConversationPreviousMessageComponentLoad = (
    props: MessagePracticePreviousConversationsProps,
): void => {
    GenericTrackEvent({
        object: "ClinicianConversationRecentMessage",
        objectType: EventObjectType.Component,
        action: EventAction.Load,
        properties: AddRudderStackAnalyticProps(props),
    });
};

export type MessagePracticeStatusProps = {
    messagePracticeStatus: string;
};
