import { FormEvent, useEffect, useState } from "react";

import * as UI from "@accurx/design";
import { FormFieldFeedback, Input } from "@accurx/inbox-design-library";
import { useSearchForPatientMutation } from "domains/inbox/hooks/mutations/useSearchForPatientMutation";

import { StyledDateOfBirthWrapper, StyledFormSpacer } from "./Forms.styles";
import { NoPatientsFoundFeedback } from "./NoPatientsFoundFeedback";
import { SearchErrorFeedback } from "./SearchErrorFeedback";
import { sanitiseNhsNumber } from "./sanitiseNhsNumber";
import {
    FormErrorsByNhsNumber,
    FormPdsResult,
    FormStateByNhsNumber,
} from "./types";
import { validateByNhsNumberForm } from "./validatePdsForms";

export const formPdsByNumberId = "search-for-patient-by-number-form";

type FormPdsByNhsNumberProps = {
    onResultsFound: ({ patient, patientToken }: FormPdsResult) => void;
    onSearchClick?: ({ hasError }: { hasError: boolean }) => void;
    onSearchSettled?: ({ hasError }: { hasError: boolean }) => void;
    setIsLoading?: (isLoading: boolean) => void;
};

export const FormPdsByNhsNumber = ({
    onResultsFound,
    onSearchClick,
    onSearchSettled,
    setIsLoading,
}: FormPdsByNhsNumberProps) => {
    const [formState, setFormState] = useState<FormStateByNhsNumber>({
        dateOfBirth: { day: "", month: "", year: "" },
        nhsNumber: "",
    });

    const [formFieldErrors, setFormFieldErrors] =
        useState<FormErrorsByNhsNumber>({
            dateOfBirth: null,
            nhsNumber: null,
        });

    const { mutate, data, status } = useSearchForPatientMutation({
        onSuccess: (data) => {
            data.searchedResult.patient &&
                onResultsFound({
                    patient: data.searchedResult.patient,
                    patientToken: data.searchedResult.patientToken,
                });
        },
        onSettled: (_data, error) => onSearchSettled?.({ hasError: !!error }),
    });

    const onChange = (
        data: Partial<FormStateByNhsNumber>,
        field: keyof FormStateByNhsNumber,
    ) => {
        if (formFieldErrors[field]) {
            setFormFieldErrors((prev) => ({
                ...prev,
                [field]: null,
            }));
        }
        setFormState((prev) => ({
            ...prev,
            ...data,
        }));
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const { isValid, errors } = validateByNhsNumberForm(formState);

        onSearchClick?.({ hasError: !isValid });

        if (isValid) {
            setFormFieldErrors({ dateOfBirth: null, nhsNumber: null });

            mutate({
                nhsNumber: sanitiseNhsNumber(formState.nhsNumber),
                dateOfBirth: {
                    day: formState.dateOfBirth.day,
                    month: formState.dateOfBirth.month,
                    year: formState.dateOfBirth.year,
                },
            });
        } else {
            setFormFieldErrors(errors);
        }
    };

    useEffect(() => {
        setIsLoading?.(status === "loading");
    }, [status, setIsLoading]);

    const showErrorMessage = status === "error";
    const showNotFoundMessage =
        status === "success" && !data.searchedResult.patient;

    return (
        <UI.Flex flexDirection="column" gap="0.5">
            <form
                onSubmit={onSubmit}
                id={formPdsByNumberId}
                aria-label="Search for patient by NHS number"
                aria-describedby={
                    showErrorMessage
                        ? "search-error"
                        : showNotFoundMessage
                        ? "not-found-warning"
                        : undefined
                }
            >
                <StyledFormSpacer>
                    <div>
                        <UI.Text
                            as="label"
                            variant="note"
                            props={{ htmlFor: "nhs-number" }}
                            skinny
                        >
                            NHS number
                        </UI.Text>
                        <UI.Text skinny variant="preview">
                            e.g. 111 222 3333
                        </UI.Text>
                        <Input
                            id={"nhs-number"}
                            hasErrors={!!formFieldErrors.nhsNumber}
                            value={formState.nhsNumber}
                            onChange={(e): void =>
                                onChange(
                                    { ...formState, nhsNumber: e.target.value },
                                    "nhsNumber",
                                )
                            }
                            aria-describedby={
                                formFieldErrors.nhsNumber
                                    ? "nhs-number-error"
                                    : undefined
                            }
                        />
                        {!!formFieldErrors.nhsNumber && (
                            <div id="nhs-number-error">
                                <FormFieldFeedback
                                    text={formFieldErrors.nhsNumber}
                                    variant="error"
                                />
                            </div>
                        )}
                    </div>
                    <StyledDateOfBirthWrapper>
                        <UI.DateOfBirthInput
                            error={formFieldErrors.dateOfBirth ?? undefined}
                            legend={
                                <UI.Text variant="note" skinny>
                                    Date of birth
                                </UI.Text>
                            }
                            dayValue={formState.dateOfBirth.day}
                            onDayChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            day: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                            monthValue={formState.dateOfBirth.month}
                            onMonthChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            month: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                            yearValue={formState.dateOfBirth.year}
                            onYearChange={(value) => {
                                onChange(
                                    {
                                        ...formState,
                                        dateOfBirth: {
                                            ...formState.dateOfBirth,
                                            year: value,
                                        },
                                    },
                                    "dateOfBirth",
                                );
                            }}
                        />
                    </StyledDateOfBirthWrapper>
                    {showErrorMessage && (
                        <SearchErrorFeedback id="search-error" />
                    )}
                    {showNotFoundMessage && (
                        <NoPatientsFoundFeedback id="not-found-warning" />
                    )}
                </StyledFormSpacer>
            </form>
        </UI.Flex>
    );
};
