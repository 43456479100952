import { useEffect } from "react";

import { Button, Ds, Flex } from "@accurx/design";

import { trackDeleteTemplatePageView } from "app/analytics/FlemingAnalytics";
import { AccumailTemplate } from "app/practices/accumail/templates.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { ModalBackground } from "./DeleteTemplateModal.styles";

export const DeleteTemplateModal = ({
    templateToDelete,
    onDelete,
    onCancel,
}: {
    templateToDelete: AccumailTemplate;
    onCancel: () => void;
    onDelete: (templateId: string) => void;
}) => {
    const { title, id, templateType } = templateToDelete;

    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    useEffect(() => {
        trackDeleteTemplatePageView({
            ...flemingLoggedInProperties,
            templateId: id,
            templateLevel:
                templateType === "UserTemplate" ? "User" : "Organisation",
            productOrigin: "ClinicianMessage",
        });
    }, [flemingLoggedInProperties, templateType, id]);

    return (
        <Ds.Modal open={true} onOpenChange={onCancel} dismissible={false}>
            <Ds.Modal.Section>
                <Ds.Modal.Title>Delete template</Ds.Modal.Title>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <ModalBackground>
                <Ds.Modal.Section>
                    Are you sure you want to delete {title}?
                    {templateType === "WorkspaceTemplate"
                        ? " Once deleted, it won't be available for everyone in your organisation."
                        : ""}
                </Ds.Modal.Section>
            </ModalBackground>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Flex justifyContent="flex-end" gap="2">
                    <Button
                        onClick={() => onCancel()}
                        theme="secondary"
                        text="Cancel"
                    />
                    <Button
                        onClick={() => onDelete(id)}
                        theme="danger"
                        text="Delete template"
                    />
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
