import { Tokens } from "@accurx/design";
import styled from "styled-components";

const { BORDERS, COLOURS, SIZES } = Tokens;

export const StyledBadge = styled.div<{
    colour: "red" | "green" | "yellow" | "greyscale";
}>`
    display: inline-flex;
    align-items: center;
    background-color: ${({ colour }) =>
        colour === "greyscale"
            ? COLOURS.greyscale.cloud
            : COLOURS.primary[colour][10]};
    padding: ${SIZES[0.5]} ${SIZES[1]};
    gap: ${SIZES[0.5]};
    border-radius: ${BORDERS.radius};
`;

export const StyledIconWrapper = styled.div`
    flex-shrink: 0;
`;
