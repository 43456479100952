import { Pill } from "@accurx/inbox-design-library";

import { StyledPillButton, Theme } from "./MessageActionButton.styles";

type MessageActionButtonProps = {
    text?: string;
} & (
    | {
          onClick?: () => void;
          isLoading?: false;
      }
    | {
          onClick?: undefined;
          isLoading: true;
      }
);

const MessageActionButton = ({
    text,
    icon,
    onClick,
    theme,
    isLoading = false,
}: MessageActionButtonProps & { theme: Theme; icon: "Reply" | "Document" }) => {
    return (
        <StyledPillButton
            $theme={theme}
            radius="full"
            onClick={() => onClick?.()}
            disabled={isLoading}
            aria-label={text}
        >
            <Pill.Icon
                isLoading={isLoading}
                name={icon}
                colour={theme === "primary" ? "white" : "zinc"}
            />

            <Pill.Text
                $colour={
                    isLoading || theme === "secondary" ? undefined : "white"
                }
            >
                {text}
            </Pill.Text>
        </StyledPillButton>
    );
};

export const ReplyActionButton = ({
    isLoading,
    onClick,
    text = "Reply to patient",
}: MessageActionButtonProps) => {
    const theme = "primary";
    const icon = "Reply";

    return isLoading ? (
        <MessageActionButton theme={theme} icon={icon} text={text} isLoading />
    ) : (
        <MessageActionButton
            theme={theme}
            text={text}
            icon={icon}
            onClick={onClick}
        />
    );
};

export const NoteActionButton = ({
    isLoading,
    onClick,
    text = "Add note",
}: MessageActionButtonProps) => {
    const theme = "secondary";
    const icon = "Document";

    return isLoading ? (
        <MessageActionButton theme={theme} icon={icon} text={text} isLoading />
    ) : (
        <MessageActionButton
            theme={theme}
            text={text}
            icon={icon}
            onClick={onClick}
        />
    );
};
