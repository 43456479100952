import * as UI from "@accurx/design";
import { MessageTemplate, Template } from "@accurx/message-templates";
import { TemplateItem } from "domains/compose/types";
import isEqual from "lodash/isEqual";

import { TemplateGroup } from "../types/TemplateItem";
import { ListItem } from "./ListItem";
import { StyledTemplateListSection } from "./TemplateListSection.styles";

type TemplateListSectionProps = {
    categories: TemplateGroup[];
    owner: Template["owner"];
    onClickTemplate: (template: TemplateItem) => void;
    onClickPreview: (template: TemplateItem) => void;
    loadingTemplate?: TemplateItem;
};

export const TemplateListSection = ({
    categories,
    owner,
    onClickTemplate,
    onClickPreview,
    loadingTemplate,
}: TemplateListSectionProps) => {
    const sectionHeading = getDisplaySectionHeading({
        owner: owner,
    });

    const sectionHeadingId = `${owner}-templates-heading`;

    return (
        <>
            <StyledTemplateListSection.Heading>
                <UI.Text
                    variant="note"
                    colour={"zinc"}
                    skinny
                    as="h3"
                    props={{
                        id: sectionHeadingId,
                    }}
                >
                    {sectionHeading}
                </UI.Text>
            </StyledTemplateListSection.Heading>
            <StyledTemplateListSection.List aria-labelledby={sectionHeadingId}>
                {categories.map(({ title, items }, index) => {
                    const headingId = `${title
                        .split(" ")
                        .join("-")}-templates-heading`;

                    return (
                        <li key={`${title}-${index}`}>
                            {title && (
                                <StyledTemplateListSection.SubHeading>
                                    <UI.Text
                                        skinny
                                        variant="note"
                                        colour="zinc"
                                        as="h4"
                                        props={{
                                            id: headingId,
                                        }}
                                    >
                                        {title}
                                    </UI.Text>
                                </StyledTemplateListSection.SubHeading>
                            )}
                            <StyledTemplateListSection.List
                                aria-labelledby={headingId}
                            >
                                {items.map((item) => (
                                    <ListItem
                                        onClick={() => onClickTemplate(item)}
                                        templateType={item.type}
                                        templateTitle={item.value.title}
                                        key={item.value.id}
                                        onClickPreview={() =>
                                            onClickPreview(item)
                                        }
                                        isLoading={isEqual(
                                            loadingTemplate,
                                            item,
                                        )}
                                    />
                                ))}
                            </StyledTemplateListSection.List>
                        </li>
                    );
                })}
            </StyledTemplateListSection.List>
        </>
    );
};

const getDisplaySectionHeading = ({
    owner,
}: {
    owner: MessageTemplate["owner"];
}): string => {
    switch (owner) {
        case "User":
            return "Created by you";
        case "Organisation":
            return "Created by your workspace";
        case "Accurx":
            return "Created by Accurx";
    }
};
