/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { Actions as PracticesAction } from "app/practices/Practices.actions";
import { Actions as VaccineAllPatientsActions } from "app/practices/vaccine/vaccineAllPatientsInvited/VaccineAllPatientsInvited.actions";
import { UpdatingStatus } from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

import { PdsMatchWarningLevel, defaultInviteMessage } from "../VaccineCopy";
import {
    VaccineInviteOperationResult,
    VaccinePatientStatusUpdate,
} from "../models/VaccineAllPatientsInvitedDTO";
import {
    VaccineAllInvitesFiltersData,
    VaccineAllPatientsInvitedDetails,
    VaccineCourse,
    VaccineDeliveryCollection,
    VaccineDeliveryDetails,
    VaccineDeliveryItem,
    VaccineDeliverySummary,
    VaccineInviteAction,
    VaccineInviteActionData,
    VaccinePatientInvited,
    VaccineTypeFilters,
} from "../models/VaccineDeliveryDTO";
import {
    AwaitingResponseFirst,
    ToManuallyBookFirst,
} from "../models/VaccineInviteStatus";

const emptyVaccineDeliverySummary: VaccineDeliverySummary = {
    id: "",
    status: "",
    sendTime: "",
    statusCounts: [],
};

const emptyVaccineDeliveryDetails: VaccineDeliveryDetails = {
    ...emptyVaccineDeliverySummary,
    invitedBy: {
        nationalCode: "",
        name: "",
    },
    items: [],
};

const emptyVaccinePatientInvited: VaccinePatientInvited = {
    contactNumber: "",
    currentInviteStatus: "",
    dateOfBirth: "01/01/2000",
    inviteBatchId: "",
    inviteId: "",
    invitedBy: {
        nationalCode: "",
        name: "",
    },
    patientExternalIdentity: {
        patientExternalIds: [{ value: "", type: "NhsNumber" }],
    },
    patientName: null,
    sendMessages: false,
    hasNimsConflict: false,
    hasNbsConflict: false,
    inviteCreatedAt: "",
    vaccineName: null,
    lastVaccineDate: null,
};

export const initialVaccineFilters: VaccineTypeFilters = {
    astrazeneca: false,
    janssen: false,
    moderna: false,
    pfizer: false,
};

export type VaccineDeliveryReducerState = {
    csvUploadStatus: UpdatingStatus;
    getDetailsStatus: UpdatingStatus;
    sendingStatus: UpdatingStatus;
    vaccineDeliveryCollection: VaccineDeliveryCollection;
    vaccineDeliverySummary: VaccineDeliverySummary;
    vaccineDeliveryDetails: VaccineDeliveryDetails;

    getAllPatientsDetailsRequestId: string;
    getAllPatientsDetailsStatus: UpdatingStatus;
    vaccineAllPatientsInvitedDetails: VaccineAllPatientsInvitedDetails; // To replace vaccineDeliveryDetails

    vaccineDeliveryId: string;
    vaccineDeliveryStatus: string;
    patientMessage: string;
    inviteUploadStatus: UpdatingStatus;
    cancelPatient: VaccinePatientInvited;
    cancelInviteId: string;
    cancelBatchId: string;
    cancelStatus: UpdatingStatus;
    cancelError: string;
    resetInviteId: string;
    resetStatus: UpdatingStatus;
    bulkCancelStatus: UpdatingStatus;
    bulkCancelResponse: VaccineInviteOperationResult[];
    uploadFailureMessage: string;
    badDataWarningLevel: PdsMatchWarningLevel;
    badDataMessage: string;
    goToCancelFlow: boolean;
    allInvitesFilters: VaccineAllInvitesFiltersData;
    inviteActionData: VaccineInviteActionData;
    invitesToInviteForSecond: VaccineDeliveryItem[]; // this is used during the CSV upload flow to invite for 2nd, or for booster
    vaccineCourse: VaccineCourse;
};

export const initialState: VaccineDeliveryReducerState = {
    csvUploadStatus: UpdatingStatus.Initial,
    getDetailsStatus: UpdatingStatus.Initial,
    sendingStatus: UpdatingStatus.Initial,
    getAllPatientsDetailsRequestId: "",
    getAllPatientsDetailsStatus: UpdatingStatus.Initial,
    vaccineDeliveryCollection: {
        summaries: [],
    },
    vaccineDeliverySummary: emptyVaccineDeliverySummary,
    vaccineAllPatientsInvitedDetails: {
        summaries: [],
        isLimited: false,
    },
    vaccineDeliveryDetails: emptyVaccineDeliveryDetails,
    vaccineDeliveryId: "",
    vaccineDeliveryStatus: "",
    patientMessage: defaultInviteMessage,
    inviteUploadStatus: UpdatingStatus.Initial,
    cancelPatient: emptyVaccinePatientInvited,
    cancelInviteId: "",
    cancelBatchId: "",
    cancelStatus: UpdatingStatus.Initial,
    cancelError: "",
    resetInviteId: "",
    resetStatus: UpdatingStatus.Initial,
    bulkCancelStatus: UpdatingStatus.Initial,
    bulkCancelResponse: [],
    uploadFailureMessage: "",
    badDataWarningLevel: PdsMatchWarningLevel.NONE,
    badDataMessage: "",
    goToCancelFlow: false,
    allInvitesFilters: {
        searchTerm: "",
        statusFilters: [
            ToManuallyBookFirst.filterStatus,
            AwaitingResponseFirst.filterStatus,
        ],
        myPractice: true,
        vaccineTypeFilters: initialVaccineFilters,
        firstVaccinationTimeFilter: null,
        secondVaccinationTimeFilter: null,
        unclearStatus: false,
    },
    inviteActionData: {
        invites: [],
        action: VaccineInviteAction.None,
    },
    invitesToInviteForSecond: [],
    vaccineCourse: VaccineCourse.None,
};

export const reducer = (
    state = initialState,
    action: any,
): VaccineDeliveryReducerState => {
    switch (action.type) {
        case CoreActions.SEND_VACCINE_INVITES:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Loading,
            };
        case CoreActions.SEND_VACCINE_INVITES_FAILURE:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Failed,
            };
        case CoreActions.SEND_VACCINE_INVITES_SUCCESS:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Loaded,
            };
        case CoreActions.PREPARE_NEW_VACCINE_INVITES:
            return {
                ...initialState, // want to reset everything except...
                vaccineCourse: state.vaccineCourse, // don't want to reset this when going to upload page
            };
        case CoreActions.UPLOAD_VACCINE_INVITE_LIST:
            return {
                ...state,
                csvUploadStatus: UpdatingStatus.Loading,
                inviteUploadStatus: UpdatingStatus.Initial,
            };
        case CoreActions.UPLOAD_VACCINE_INVITE_LIST_FAILURE:
            return {
                ...state,
                csvUploadStatus: UpdatingStatus.Failed,
                uploadFailureMessage: action.err,
            };
        case CoreActions.UPLOAD_VACCINE_INVITE_LIST_SUCCESS:
            return {
                ...state,
                csvUploadStatus: UpdatingStatus.Loaded,
                vaccineDeliveryId: action.response.id,
            };
        case CoreActions.GET_VACCINE_UPLOAD_STATUS:
            return {
                ...state,
                inviteUploadStatus: UpdatingStatus.Loading,
            };
        case CoreActions.GET_VACCINE_UPLOAD_STATUS_FAILURE:
            return {
                ...state,
                inviteUploadStatus: UpdatingStatus.Failed,
            };
        case CoreActions.GET_VACCINE_UPLOAD_STATUS_SUCCESS:
            let warningLevel;
            switch (action.response.warningLevel?.toUpperCase()) {
                case "MEDIUM":
                    warningLevel = PdsMatchWarningLevel.MEDIUM;
                    break;
                case "HIGH":
                    warningLevel = PdsMatchWarningLevel.HIGH;
                    break;
                case "CRITICAL":
                    warningLevel = PdsMatchWarningLevel.CRITICAL;
                    break;
                default:
                    warningLevel = PdsMatchWarningLevel.NONE;
            }
            return {
                ...state,
                vaccineDeliveryStatus: action.response.status,
                badDataMessage: action.response.warningMessage,
                badDataWarningLevel: warningLevel,
                inviteUploadStatus: UpdatingStatus.Loaded,
            };
        case CoreActions.GET_VACCINE_UPLOAD_DETAILS:
            return {
                ...state,
                getDetailsStatus: UpdatingStatus.Loading,
                cancelStatus: UpdatingStatus.Initial, // Need to reset cancelStatus as we want to reload the data only once after a booking is cancelled
            };
        case CoreActions.GET_VACCINE_UPLOAD_DETAILS_FAILURE:
            return {
                ...state,
                getDetailsStatus: UpdatingStatus.Failed,
            };
        case CoreActions.GET_VACCINE_UPLOAD_DETAILS_SUCCESS:
            return {
                ...state,
                getDetailsStatus: UpdatingStatus.Loaded,
                vaccineDeliveryDetails: action.response,
            };
        case CoreActions.GET_VACCINE_UPLOAD_DETAILS_SUCCESS_NO_BODY: // used as a special case in the sendMessage action
            return {
                ...state,
                getDetailsStatus: UpdatingStatus.Loaded,
            };
        case CoreActions.SEND_VACCINE_DELIVERY_MESSAGES:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Loading,
            };
        case CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_FAILURE:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Failed,
            };
        case CoreActions.SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS:
            return {
                ...state,
                sendingStatus: UpdatingStatus.Loaded,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    searchTerm: `inviteid:${action.response.id}`,
                    statusFilters: [], //to make sure we can see the invites, even if not in network
                },
            };
        case CoreActions.CANCEL_BOOKING:
            return {
                ...state,
                cancelInviteId: action.inviteId,
                cancelStatus: UpdatingStatus.Loading,
            };
        case CoreActions.CANCEL_BOOKING_SUCCESS:
            return {
                ...state,
                cancelInviteId: initialState.cancelInviteId,
                cancelStatus: UpdatingStatus.Loaded,
            };
        case CoreActions.CANCEL_BOOKING_FAILURE:
            return {
                ...state,
                cancelInviteId: initialState.cancelInviteId,
                cancelStatus: UpdatingStatus.Failed,
                cancelError: action.err,
            };
        case CoreActions.RESET_BOOKING:
            return {
                ...state,
                resetInviteId: action.inviteId,
                resetStatus: UpdatingStatus.Loading,
            };
        case CoreActions.RESET_BOOKING_SUCCESS:
            return {
                ...state,
                resetInviteId: initialState.resetInviteId,
                resetStatus: UpdatingStatus.Loaded,
            };
        case CoreActions.RESET_BOOKING_FAILURE:
            return {
                ...state,
                resetInviteId: initialState.resetInviteId,
                resetStatus: UpdatingStatus.Failed,
            };
        case CoreActions.BULK_CANCEL_INVITES:
            return {
                ...state,
                bulkCancelStatus: UpdatingStatus.Loading,
                bulkCancelResponse: [],
            };
        case CoreActions.BULK_CANCEL_INVITES_SUCCESS:
            return {
                ...state,
                bulkCancelStatus: UpdatingStatus.Loaded,
                bulkCancelResponse: action.response,
            };
        case CoreActions.BULK_CANCEL_INVITES_FAILURE:
            return {
                ...state,
                bulkCancelStatus: UpdatingStatus.Failed,
            };
        case CoreActions.UPDATE_SEARCH_TERM:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    searchTerm: action.searchTerm,
                },
            };
        case CoreActions.UPDATE_MY_PRACTICE_FILTER:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    myPractice: action.myPractice,
                },
            };
        case CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED:
            return {
                ...state,
                getAllPatientsDetailsStatus: UpdatingStatus.Loading,
                getAllPatientsDetailsRequestId: action.requestId,
                cancelStatus: UpdatingStatus.Initial, // Need to reset cancelStatus as we want to reload the data only once after a booking is cancelled
                inviteActionData: {
                    // Reset list of invites to for bulk reminder/second booking
                    invites: [],
                    action: VaccineInviteAction.None,
                },
            };
        case CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED_FAILURE:
            return action.requestId === state.getAllPatientsDetailsRequestId
                ? {
                      ...state,
                      getAllPatientsDetailsStatus: UpdatingStatus.Failed,
                  }
                : state;
        case CoreActions.GET_VACCINE_ALL_PATIENTS_INVITED_SUCCESS:
            return action.requestId === state.getAllPatientsDetailsRequestId
                ? {
                      ...state,
                      getAllPatientsDetailsStatus: UpdatingStatus.Loaded,
                      vaccineAllPatientsInvitedDetails: action.response,
                      goToCancelFlow: false,
                  }
                : state;
        case CoreActions.SET_CANCEL_BOOKING_DETAILS:
            return {
                ...state,
                cancelPatient: action.patient,
                goToCancelFlow: true,
            };
        case CoreActions.SET_STATUS_FILTERS:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    statusFilters: action.statusFilters,
                },
            };
        case CoreActions.SET_MY_PRACTICE_FILTER:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    myPractice: action.myPractice,
                },
            };
        case CoreActions.SET_VACCINE_TYPE_FILTERS:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    vaccineTypeFilters: action.vaccineTypeFilters,
                },
            };
        case CoreActions.SET_FIRST_VACCINE_TIME_FILTER:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    firstVaccinationTimeFilter:
                        action.firstVaccinationTimeFilter,
                },
            };
        case CoreActions.SET_SECOND_VACCINE_TIME_FILTER:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    secondVaccinationTimeFilter:
                        action.secondVaccinationTimeFilter,
                },
            };
        case CoreActions.SET_UNCLEAR_STATUS_FILTERS:
            return {
                ...state,
                allInvitesFilters: {
                    ...state.allInvitesFilters,
                    unclearStatus: action.unclearStatus,
                },
            };
        case CoreActions.RESET_GO_TO_CANCEL_FLOW:
            return {
                ...state,
                goToCancelFlow: false,
            };
        case CoreActions.RESET_BULK_CANCEL_STATUS:
            return {
                ...state,
                bulkCancelStatus: UpdatingStatus.Initial,
            };
        case CoreActions.SET_PATIENT_INVITES_FOR_BULK_ACTION:
            return {
                ...state,
                inviteActionData: action.vaccineInviteActionData,
            };
        case CoreActions.SET_PATIENT_INVITES_FOR_INVITE_TO_SECOND:
            return {
                ...state,
                invitesToInviteForSecond: action.patientDeliveryItems,
            };
        case CoreActions.RESET_PATIENT_INVITES_FOR_INVITE_TO_SECOND:
            return {
                ...state,
                invitesToInviteForSecond: [],
            };
        case CoreActions.UPDATE_COURSE_TYPE:
            return {
                ...state,
                vaccineCourse: action.course,
                allInvitesFilters: {
                    // we want to wipe some of the selected filter values when changing tabs
                    ...state.allInvitesFilters,
                    // back to default - if you change this you might also need to update the useEffect in FiltersStatusCheckboxes.tsx to change the radio button selected
                    statusFilters: initialState.allInvitesFilters.statusFilters,
                    firstVaccinationTimeFilter: null,
                    secondVaccinationTimeFilter: null,
                },
            };
        case VaccineAllPatientsActions.UPDATE_PATIENT_INVITE_STATUS:
            return {
                ...state,
                vaccineAllPatientsInvitedDetails:
                    mapVaccineAllPatientsInvitedDetails(
                        state.vaccineAllPatientsInvitedDetails,
                        action.update,
                    ),
            };
        case PracticesAction.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        default:
            return state;
    }
};

const mapVaccineAllPatientsInvitedDetails = (
    existing: VaccineAllPatientsInvitedDetails,
    update: VaccinePatientStatusUpdate,
): VaccineAllPatientsInvitedDetails => {
    return {
        ...existing,
        summaries: existing.summaries.map((x) => {
            if (x.inviteId !== update.inviteId) {
                return x;
            }
            return {
                ...x,
                currentInviteStatus: update.status,
            };
        }),
    };
};
