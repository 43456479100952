import { User } from "@accurx/auth";
import { Action, Reducer } from "redux";
import { getInitialSelectedOrgId } from "reduxQuarantine/getInitialSelectedOrgId";

import { IOrganisation, IUserResponse } from "api/FlemingDtos";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    ACCOUNT_LOGGED_IN,
    ACCOUNT_LOGGED_OUT,
    ADD_NEW_ORG,
    CLEAR_ORG,
    KnownAction,
    ORGANISATIONS_UPDATE,
    POST_USER_PROFILE_FAILURE,
    POST_USER_PROFILE_STARTED,
    POST_USER_PROFILE_SUCCESS,
    SELECT_ORG,
    SETUP_ORG_FAILED,
    SETUP_ORG_STARTED,
    SETUP_ORG_SUCCESS,
    SUBMIT_JOIN_ORG_FORM_FAILED,
    SUBMIT_JOIN_ORG_FORM_STARTED,
    SUBMIT_JOIN_ORG_FORM_SUCCESS,
    UPDATE_ORG_USER_FEATURE_FLAG_STATUS,
    USER_COOKIES_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_SIGNATURE_UPDATE_SUCCESS,
} from "./AccountActions";
import type { AccountState, UserProfileState } from "./AccountState.types";
import { UserProfileStatus } from "./AccountState.types";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const healthcareProfileInitialState: UserProfileState = {
    userProfileStatus: UserProfileStatus.Initial,
    userProfileError: "",
    healthcareRoles: [],
    specialistAreas: [],
};

export const initialState: AccountState = {
    user: null,
    isLoggedIn: null,
    selectedOrganisation: null,
    isInOrganisation: false,
    hasOrganisationEverLoaded: false,
    isOrganisationsLoading: LoadingStatus.Initial,
    isSettingUpOrganisation: false,
    lastOrganisationSetupFailed: false,
    isSubmittingOrganisationRequestForm: false,
    ...healthcareProfileInitialState,
};

export const reducer: Reducer<AccountState> = (
    state = initialState,
    incomingAction: Action,
): AccountState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case ACCOUNT_LOGGED_IN:
            return {
                ...state,
                user: action.user,
                isLoggedIn: true,
                selectedOrganisation: action.user
                    ? getInitialSelectedOrgId(action.user as User)
                    : null,
                isInOrganisation: action.user.organisations.length !== 0,
            };

        case ACCOUNT_LOGGED_OUT:
            return {
                ...state,
                user: null,
                isLoggedIn: false,
            };

        case SETUP_ORG_STARTED:
            return {
                ...state,
                isSettingUpOrganisation: true,
            };

        case SETUP_ORG_FAILED:
            return {
                ...state,
                isSettingUpOrganisation: false,
                lastOrganisationSetupFailed: true,
            };

        case SETUP_ORG_SUCCESS:
            //Check that the org exists in the list so we can select it
            const canSelectOrg =
                action.selectedOrganisationId &&
                action.organisations.findIndex(
                    (x: IOrganisation) =>
                        x.orgId === action.selectedOrganisationId,
                ) !== -1;
            const selectedOrg = canSelectOrg
                ? action.selectedOrganisationId
                : action.organisations.length === 0
                ? null
                : action.organisations[0].orgId;
            return {
                ...state,
                user: {
                    ...(state.user as IUserResponse),
                    organisations: action.organisations,
                },
                isSettingUpOrganisation: false,
                lastOrganisationSetupFailed: false,
                isInOrganisation: action.organisations.length !== 0,
                selectedOrganisation: selectedOrg,
            };
        case SUBMIT_JOIN_ORG_FORM_STARTED:
            return { ...state, isSubmittingOrganisationRequestForm: true };
        case SUBMIT_JOIN_ORG_FORM_SUCCESS:
            return { ...state, isSubmittingOrganisationRequestForm: false };
        case SUBMIT_JOIN_ORG_FORM_FAILED:
            return { ...state, isSubmittingOrganisationRequestForm: false };
        case SELECT_ORG:
            return {
                ...state,
                selectedOrganisation: action.orgId,
            };
        case ADD_NEW_ORG:
            const isAlreadyInOrg = (
                state.user as IUserResponse
            ).organisations.some(
                (org) => org.orgId === action.organisation.orgId,
            );

            if (isAlreadyInOrg) {
                return {
                    ...state,
                    selectedOrganisation: action.organisation.orgId,
                };
            }

            return {
                ...state,
                user: {
                    ...(state.user as IUserResponse),
                    organisations: [
                        ...(state.user as IUserResponse).organisations,
                        action.organisation,
                    ],
                },
                selectedOrganisation: action.organisation.orgId,
                isInOrganisation: true,
            };
        case CLEAR_ORG:
            return {
                ...state,
                selectedOrganisation: null,
            };
        case POST_USER_PROFILE_STARTED:
            return {
                ...state,
                userProfileStatus: UserProfileStatus.Loading,
            };
        case POST_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfileStatus: UserProfileStatus.Successful,
                userProfileError: "",
                user: {
                    ...(state.user as IUserResponse),
                    healthcareRole:
                        action.healthcareRole ||
                        (state.user as IUserResponse).healthcareRole,
                    specialistArea:
                        action.specialistArea ||
                        (state.user as IUserResponse).specialistArea,
                    onboarding: state.user?.onboarding && {
                        ...state.user.onboarding,
                        // Override has accepted terms if we specifically accepted them in this update
                        hasAcceptedTermsService:
                            action.hasAcceptedTermsService ||
                            state.user.onboarding.hasAcceptedTermsService,
                    },
                },
            };
        case POST_USER_PROFILE_FAILURE:
            return {
                ...state,
                userProfileStatus: UserProfileStatus.Errored,
                userProfileError: action.error,
            };

        case UPDATE_ORG_USER_FEATURE_FLAG_STATUS:
            if (state.user === null) {
                return state;
            }

            const updatedOrgSettings = state.user.organisations.map((org) => {
                if (org.orgId === action.organisationId) {
                    // If feature flag is not already in settings array
                    // (aka it's never been turned on/off for that org/user org)
                    // add it to the array
                    // otherwise, just update it
                    let isFeatureAlreadyPresentInSettings = false;
                    let orgFeatures = org.settings.features.map((feature) => {
                        if (feature.name === action.featureFlag) {
                            isFeatureAlreadyPresentInSettings = true;
                            return { ...feature, isEnabled: action.isEnabled };
                        }
                        return feature;
                    });
                    if (!isFeatureAlreadyPresentInSettings) {
                        orgFeatures = [
                            ...org.settings.features,
                            {
                                name: action.featureFlag,
                                isEnabled: action.isEnabled,
                            },
                        ];
                    }

                    return {
                        ...org,
                        settings: {
                            ...org.settings,
                            features: orgFeatures,
                        },
                    };
                }
                return org;
            });

            return {
                ...state,
                user: {
                    ...state.user,
                    organisations: updatedOrgSettings,
                },
            };

        case USER_PROFILE_UPDATE_SUCCESS:
            if (state.user === null) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    fullName: action.userSettingsProfile.fullName,
                    email: action.userSettingsProfile.email,
                },
            };
        case USER_COOKIES_UPDATE_SUCCESS:
            if (state.user === null) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    onboarding: state.user?.onboarding && {
                        ...state.user.onboarding,
                        hasAcceptedCookies: action.hasAcceptedCookies,
                    },
                },
            };

        case USER_SIGNATURE_UPDATE_SUCCESS:
            if (state.user === null) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    messageSignature: action.messageSignature,
                },
            };

        case ORGANISATIONS_UPDATE:
            if (state.user === null) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    organisations: action.organisations,
                },
            };
    }

    return state;
};
