import { Icon, Text } from "@accurx/design";

import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";

import { SupportUrls } from "../../../shared/constants/supportUrls";
import { NavSubMenuComponent } from "../../navbar/NavSubMenuComponent";
import { MessageStatus } from "./components/messageStatus";

export const SelfBook = () => {
    return (
        <div>
            <NavSubMenuComponent>
                <div>
                    <Breadcrumb
                        title="Self-Book for Patient Triage"
                        wrapper={false}
                    />
                    <div className={"selfBookContainer"}>
                        <Text as="h1" variant="title">
                            Self-Book for Patient Triage
                        </Text>
                        <Text props={{ className: "col-12 col-lg-8 px-0" }}>
                            Send a link from the Accurx inbox to invite patients
                            to book a telephone appointment themselves. Learn
                            more about{" "}
                            <Text
                                as="a"
                                variant="link"
                                props={{
                                    href: SupportUrls.SelfBookHelp,
                                    rel: "noreferrer",
                                    target: "_blank",
                                }}
                            >
                                Self-Book: What is it and how does it work
                                <Icon
                                    name="OpenWindow"
                                    colour="blue"
                                    size={3}
                                />
                            </Text>
                        </Text>
                    </div>
                </div>
            </NavSubMenuComponent>
            <MessageStatus />
        </div>
    );
};
