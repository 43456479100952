export enum Actions {
    SET_EMBEDDED_MODE = "SET_EMBEDDED_MODE",
}

type DefaultActions = BaseActions<typeof Actions>;

export type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.SET_EMBEDDED_MODE]: {
            isEmbedded: boolean;
        };
    }
>;

export type KnownAction = ExtendedActions[keyof ExtendedActions];

export const actionCreators = {
    setEmbeddedMode: (isEmbedded: boolean) => ({
        type: Actions.SET_EMBEDDED_MODE,
        isEmbedded: isEmbedded,
    }),
};
