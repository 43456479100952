import { getEmbedMode } from "domains/native/utils/getEmbedMode";
import { useLocation } from "react-router-dom";

export type Environment = "WebView" | "SignalR" | "TestConnection";

const TEST_ENV_QUERY_PARAM = "useTestEnv";
const TEST_ENV_POSITIVE_VALUE = "true";

export const useBrowserEnvironment = (): Environment => {
    const location = useLocation();
    const { embedMode } = getEmbedMode();

    const params = new URLSearchParams(location.search);

    const isTestEnv = window.sessionStorage.getItem(TEST_ENV_QUERY_PARAM);

    if (isTestEnv === TEST_ENV_POSITIVE_VALUE) {
        return "TestConnection";
    }

    if (params.get(TEST_ENV_QUERY_PARAM) === TEST_ENV_POSITIVE_VALUE) {
        window.sessionStorage.setItem(
            TEST_ENV_QUERY_PARAM,
            TEST_ENV_POSITIVE_VALUE,
        );
        return "TestConnection";
    }

    if (embedMode === "Desktop") {
        return "WebView";
    }

    return "SignalR";
};
