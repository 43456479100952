import React from "react";

import { Icon, IconNameVariants, Text } from "@accurx/design";

import { StyledButton } from "./PanelButton.styles";

export type PanelButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    text: string;
    iconName?: IconNameVariants;
    rotation?: "right" | "left" | "down" | "up";
};

export const PanelButton = React.forwardRef(
    (
        {
            onClick,
            text,
            iconName = "ArrowTail",
            rotation = "right",
        }: PanelButtonProps,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): JSX.Element => {
        return (
            <StyledButton onClick={onClick} ref={forwardedRef}>
                <Text as="span" skinny>
                    {text}
                </Text>
                <Icon
                    name={iconName}
                    props={{
                        "aria-hidden": true,
                        focusable: false,
                    }}
                    size={3}
                    rotation={rotation}
                    halo={{
                        colour: "blue",
                        luminosity: "high",
                    }}
                />
            </StyledButton>
        );
    },
);
