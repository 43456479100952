import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import moment from "moment";

import {
    ScheduleSendFrame,
    ScheduleSendType,
} from "../types/ScheduleSend.types";

export type GetSelectedDateTimeProps = {
    type: ScheduleSendType;
    timeFrame?: ScheduleSendFrame["timeFrame"];
    time?: ScheduleSendFrame["time"];
    specificDate?: string;
    specificTime?: string;
    nextWorkingDay?: string;
};

// using moment here instead of date-fns
// as we use moment to set our website timezone to Europe/London
// see: accuRx.Fleming.Client/ClientApp/src/index.tsx#L176
const getFrameDateTimeUK = ({
    timeFrame,
    time,
}: Pick<ScheduleSendFrame, "time"> & {
    timeFrame: ScheduleSendFrame["timeFrame"];
}): string | null => {
    const { type, value } = timeFrame;

    const currentUkTime = DateHelpers.getCurrentTimeStamp();

    const withSetFrame = moment(currentUkTime).add(value, type);

    try {
        const withSetHours = moment(withSetFrame)
            .hour(parseInt(time, 10))
            .minutes(0)
            .seconds(0);

        const date = DateHelpers.formatDate(
            withSetHours.toISOString(),
            DateFormatOptions.ISO_DATE_STRING,
        );

        return date;
    } catch (e) {
        // we need to catch as date could potentially go out of range
        // if user put too big number into input (we dont restrict it)
        return null;
    }
};

const getCalendarDateTimeUK = ({
    specificDate,
    specificTime,
}: {
    specificDate: string;
    specificTime: string;
}): string | null => {
    try {
        const specificDateWithHours = moment(specificDate).hour(
            parseInt(specificTime, 10),
        );

        const date = DateHelpers.formatDate(
            specificDateWithHours.toISOString(),
            DateFormatOptions.ISO_DATE_STRING,
        );

        return date;
    } catch (e) {
        return null;
    }
};

const getTodayMorningTimeUK = (): string => {
    const currentUkTime = DateHelpers.getCurrentTimeStamp();

    const todayMorning = moment(currentUkTime).hour(8).minute(0).second(0);

    const date = DateHelpers.formatDate(
        todayMorning.toISOString(),
        DateFormatOptions.ISO_DATE_STRING,
    );

    return date;
};

const getTodayEveningTimeUK = (): string => {
    const currentUkTime = DateHelpers.getCurrentTimeStamp();

    const todayEveningUK = moment(currentUkTime).hour(19).minute(0).second(0);

    const date = DateHelpers.formatDate(
        todayEveningUK.toISOString(),
        DateFormatOptions.ISO_DATE_STRING,
    );

    return date;
};

const getNextWorkingDayMorningTimeUK = ({
    nextWorkingDay,
}: {
    nextWorkingDay?: string;
}): string | null => {
    if (!nextWorkingDay) return null;

    const nextWorkingDayMorning = moment(nextWorkingDay).hour(8);

    const date = DateHelpers.formatDate(
        nextWorkingDayMorning.toISOString(),
        DateFormatOptions.ISO_DATE_STRING,
    );

    return date;
};

export const getSelectedDateTimeUK = (
    props: GetSelectedDateTimeProps,
): string | null => {
    const { type: scheduleSendType } = props;

    if (scheduleSendType === "frame") {
        const { timeFrame, time } = props;

        if (!timeFrame || !time) return null;

        return getFrameDateTimeUK({ timeFrame, time });
    }

    if (scheduleSendType === "calendar") {
        const { specificDate, specificTime } = props;

        if (!specificDate || !specificTime) return null;

        return getCalendarDateTimeUK({ specificDate, specificTime });
    }

    if (scheduleSendType === "todayMorning") {
        return getTodayMorningTimeUK();
    }

    if (scheduleSendType === "todayEvening") {
        return getTodayEveningTimeUK();
    }

    const { nextWorkingDay } = props;

    return getNextWorkingDayMorningTimeUK({ nextWorkingDay });
};
