import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledStateChangeEventContainer = styled.div`
    border-radius: ${UI.Tokens.BORDERS.radius};
    background-color: ${UI.Tokens.COLOURS.greyscale.dishwater};
    color: ${UI.Tokens.COLOURS.greyscale.metal};
    overflow: hidden;
    font-size: 0.875rem;
`;
