import { Draft } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConnectionState } from "domains/concierge/internal/types/ConnectionState";

export const connectionStateChange = (
    state: Draft<ConciergeState["conversations"]>,
    action: ConciergeAction<ConnectionState>,
) => {
    state.connectionState = action.payload;
    return state;
};
