import { ProductSelection } from "./ProductSelection";

// ACTION TYPES

export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const SET_PATIENT_SEARCH_ORIGIN = "SET_PATIENT_SEARCH_ORIGIN";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface SelectProductAction {
    type: typeof SELECT_PRODUCT;
    productSelection: ProductSelection;
}

interface ResetProductAction {
    type: typeof RESET_PRODUCT;
}

interface SetPatientSearchOriginAction {
    type: typeof SET_PATIENT_SEARCH_ORIGIN;
    route: string;
}

export type KnownAction =
    | SelectProductAction
    | ResetProductAction
    | SetPatientSearchOriginAction;

export const actionCreators = {
    resetProductType: (): ResetProductAction => {
        return {
            type: RESET_PRODUCT,
        };
    },
    selectSMSProductType: (): SelectProductAction => {
        return {
            type: SELECT_PRODUCT,
            productSelection: ProductSelection.Sms,
        };
    },
    selectVideoProductType: (): SelectProductAction => {
        return {
            type: SELECT_PRODUCT,
            productSelection: ProductSelection.Video,
        };
    },
    selectRecordViewType: (): SelectProductAction => {
        return {
            type: SELECT_PRODUCT,
            productSelection: ProductSelection.RecordView,
        };
    },
    selectMessagePracticeProductType: (): SelectProductAction => {
        return {
            type: SELECT_PRODUCT,
            productSelection: ProductSelection.MessagePractice,
        };
    },
    setPatientSearchOrigin: (route: string): SetPatientSearchOriginAction => {
        return {
            type: SET_PATIENT_SEARCH_ORIGIN,
            route,
        };
    },
};
