import React from "react";

import { Attachment, PatientResponseSection } from "@accurx/concierge/types";
import * as UI from "@accurx/design";

import {
    StyledResponseContent,
    StyledResponseContentSubtext,
    StyledResponseItemAnswer,
    StyledResponseItemQuestion,
    StyledResponseSection,
    StyledResponsesContainer,
    StyledResponsesHeader,
} from "./QuestionnaireResponse.styles";

type QuestionnaireResponseProps = {
    title?: string;
    subtitle?: string;
    contentSubtext?: string | JSX.Element;
    sections: PatientResponseSection[];
    renderAttachments: (args: { attachments: Attachment[] }) => React.ReactNode;
};

export const QuestionnaireResponse = ({
    title,
    subtitle,
    contentSubtext,
    sections,
    renderAttachments,
}: QuestionnaireResponseProps) => {
    const fullTitle = formatFullTitle({ title, subtitle });
    return (
        <StyledResponsesContainer>
            {!!fullTitle && (
                <StyledResponsesHeader>
                    <UI.Flex alignItems="center">
                        <UI.Icon name="Questionnaire" size={3} aria-hidden />
                        <h3>{fullTitle}</h3>
                    </UI.Flex>
                </StyledResponsesHeader>
            )}
            <StyledResponseContent>
                {contentSubtext && (
                    <StyledResponseContentSubtext>
                        <span>{contentSubtext}</span>
                    </StyledResponseContentSubtext>
                )}
                {sections.map((section, i) => (
                    <StyledResponseSection
                        key={i}
                        aria-label={`Response section ${i + 1}`}
                    >
                        {section.responses.map((response, i) => (
                            <li key={i}>
                                <StyledResponseItemQuestion>
                                    {response.question}:{" "}
                                </StyledResponseItemQuestion>
                                <StyledResponseItemAnswer>
                                    {response.answer}
                                </StyledResponseItemAnswer>
                                {!!response.attachments.length &&
                                    renderAttachments({
                                        attachments: response.attachments,
                                    })}
                            </li>
                        ))}
                    </StyledResponseSection>
                ))}
            </StyledResponseContent>
        </StyledResponsesContainer>
    );
};

const formatFullTitle = ({
    title,
    subtitle,
}: Pick<QuestionnaireResponseProps, "title" | "subtitle">) => {
    if (!title) return null;

    // Format to "Title"
    if (!!title && !subtitle) {
        return <b>{title}</b>;
    }

    // Format to "Title: Subtitle"
    if (!!title && !!subtitle) {
        return (
            <>
                <b>{title}: </b>
                {subtitle}
            </>
        );
    }

    return null;
};
