import { GetConversationsResponse } from "@accurx/api/clinician-messaging";
import { ConciergeUpdates } from "domains/concierge/testing";
import {
    Conversation,
    PatientSummary,
    UserSummary,
} from "domains/concierge/types";
import uniqBy from "lodash/uniqBy";

import { mapConversationSummaryToConciergeUpdate } from "./mapConversationSummaryToConciergeUpdate";

export const mapGetConversationsResponseToConciergeUpdate = (
    dto: GetConversationsResponse,
): ConciergeUpdates => {
    /*
     Clinician Messaging server returns patients and users nested in their conversation
     rather than as a separate field on the DTO. As such, it's possible there are
     patients and users referenced multiple times. We only need one copy of each,
     so the mapper will deduplicate them.
     */

    const conversations = new Array<Conversation>();
    const patients = new Array<PatientSummary>();
    const users = new Array<UserSummary>();

    for (const summary of dto.conversations) {
        const update = mapConversationSummaryToConciergeUpdate(summary);
        conversations.push(...update.conversations);
        patients.push(...update.patients);
        users.push(...update.users);
    }

    return {
        conversations,
        patients: uniqBy(patients, "patientId"),
        users: uniqBy(users, "id"),
        teams: [],
    };
};
