import { Feedback } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

type EmptyMessageProps = {
    startDate: string;
    endDate: string;
    clinicsGroup: "relevant" | "past" | "upcoming";
};

export const EmptyMessage = ({
    startDate,
    endDate,
    clinicsGroup,
}: EmptyMessageProps) => {
    const formattedStartDate = DateHelpers.formatDate(
        startDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );
    const formattedEndDate = DateHelpers.formatDate(
        endDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );

    return (
        <Feedback
            colour="information"
            title={`No ${clinicsGroup} clinics available between ${formattedStartDate} - ${formattedEndDate}`}
        />
    );
};
