import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const ModuleAccessContainer = styled.div`
    width: 100%;

    @media (min-width: 1024px) {
        width: 60%;
    }
`;

export const UsageGrid = styled.div`
    width: 70%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: ${Tokens.SIZES[1]};
    grid-row-gap: ${Tokens.SIZES[1]};
    justify-items: stretch;
    align-items: stretch;
`;

export const UsageGridSeparators = styled.span`
    position: relative;
    &:before {
        content: "";
        width: 100%;
        display: block;
        background-color: ${Tokens.COLOURS.greyscale.silver};
        height: 2px;
        margin-top: 0.75rem;
    }
`;
