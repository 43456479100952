import { mapConversationItemIdToTicketItemIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationItemIdToTicketItemIdentity";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";

type DeleteAttachmentOptions = {
    workspaceId: number;
    itemId: string;
    attachmentId: string;
    reasonForDeletion: string;
    patientId?: string;
};

export const deleteAttachment: ConciergeFetcher<
    DeleteAttachmentOptions,
    void
> = async ({
    workspaceId,
    itemId,
    attachmentId,
    patientId,
    reasonForDeletion,
}) => {
    const itemIdentity = mapConversationItemIdToTicketItemIdentity(itemId);

    if (!itemIdentity) {
        throw new Error(
            `Unable to convert conversation item ID to a ticket item identity: ${itemId}`,
        );
    }

    await TicketApiClient.deleteAttachment({
        organisationId: workspaceId,
        noteId: itemIdentity.id,
        patientId,
        attachmentId,
        reasonForDeletion,
    });

    return {
        updates: {
            conversations: [],
            users: [],
            teams: [],
            patients: [],
        },
        response: undefined,
    };
};
