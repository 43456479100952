import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";

export const useAllScheduledConversationGroupQuery: ConversationGroupHook<
    void
> = () => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFolderTicketView({
                workspaceId: workspaceId,
                folder: { viewType: "Scheduled" },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId],
    );

    return useConversationGroupQuery({
        filters: [
            { type: "Source", value: "Ticket" },
            {
                type: "ContainsItem",
                value: {
                    filters: [
                        {
                            type: "ItemType",
                            value: ["PatientEmail", "PatientSms"],
                        },
                        {
                            type: "DeliveryStatus",
                            value: ["Queued"],
                        },
                    ],
                },
            },
        ],
        sortOptions: [
            {
                key: "NextScheduledItem",
                direction: "asc",
            },
            { key: "Id", direction: "asc" },
        ],
        loggingInfo: {
            name: "All scheduled conversations",
            tags: {},
        },
        fetcher,
    });
};
