import { useEffect, useState } from "react";

import { Tokens } from "@accurx/design";

const useIsMobile = (): boolean => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const desktopBreakpoint = parseInt(Tokens.BREAKPOINTS.lg, 10);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth < desktopBreakpoint;
};

const useIsSmallMobile = (): boolean => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const mobileBreakpoint = parseInt(Tokens.BREAKPOINTS.md, 10);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth < mobileBreakpoint;
};

export { useIsMobile, useIsSmallMobile };
