import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { Sidenav } from "@accurx/navigation";
import {
    PendingApprovalButton,
    usePendingApproval,
} from "@accurx/workspace-management";
import { CurrentPatient } from "domains/inbox/components/CurrentPatient/CurrentPatient";
import { WithPatientsSidebarSection } from "domains/inbox/components/Sidebar/WithPatientsSidebarSection";
import { useAccordionBehaviour } from "domains/inbox/components/Sidebar/utils";
import styled from "styled-components";

const PendingApprovalButtonContainer = styled.div`
    padding: ${UI.Tokens.SIZES[1]};
`;

export const PatientMessagingSidebar = () => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const currentWorkspace = useCurrentWorkspace();
    const { isPendingApproval } = usePendingApproval(currentWorkspace.orgId);
    const accordion = useAccordionBehaviour();

    const showCurrentPatient =
        medicalRecordConnection.status === "Connected" ||
        medicalRecordConnection.status === "Disconnected";

    return (
        <Sidenav id="PatientMessagingSidebar">
            <Sidenav.Content>
                <Sidenav.Title>Inbox</Sidenav.Title>
                <WithPatientsSidebarSection
                    path={accordion.path.patients}
                    onPathChange={(value) => {
                        accordion.setPath({
                            topLevel: "Patients",
                            patients: value,
                        });
                    }}
                />
            </Sidenav.Content>
            {showCurrentPatient && (
                <Sidenav.Footer>
                    <CurrentPatient />
                </Sidenav.Footer>
            )}
            {isPendingApproval && (
                <Sidenav.Footer>
                    <PendingApprovalButtonContainer>
                        <PendingApprovalButton />
                    </PendingApprovalButtonContainer>
                </Sidenav.Footer>
            )}
        </Sidenav>
    );
};
