import { useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { ClinicianCompose, useIsComposing } from "@accurx/compose";
import { useSendMutation } from "@accurx/concierge/hooks/mutations/useSendMutation";
import { Conversation, PatientSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { getMainParticipant } from "domains/inbox/util/getMainParticipant";
import { getParticipantName } from "domains/inbox/util/getParticipantName";
import { toast } from "react-toastify";

import { MessageActionsBar } from "../MessageActionsBar/MessageActionsBar";
import { StyledReplyLabel } from "./ClinicianReply.styles";
import {
    StyledInnerContainer,
    StyledOuterContainer,
    StyledTabsBar,
} from "./ReplyContents.styles";
import { ReplyFailedFeedback } from "./ReplyFailedFeedback";

export const ClinicianReplyContents = ({
    conversation,
    patient,
    onClose,
}: {
    conversation: Conversation;
    onClose: () => void;
    patient?: PatientSummary;
}) => {
    const { orgId } = useCurrentWorkspace();
    const [isMinimised, setIsMinimised] = useState(false);
    const sendMessageMutation = useSendMutation();
    const isComposing = useIsComposing();

    if (isMinimised) {
        return (
            <MessageActionsBar
                isComposing={isComposing}
                isComposingNote={false}
                conversation={conversation}
                toggleReply={() => setIsMinimised(false)}
            />
        );
    }

    const recipient = getParticipantName(
        getMainParticipant(conversation, orgId),
    );

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <StyledTabsBar>
                    <StyledReplyLabel>
                        <UI.Icon name="Reply" size={3} />
                        <UI.Text variant="note" skinny>
                            Reply
                        </UI.Text>
                    </StyledReplyLabel>
                    <UI.Button
                        theme="secondary"
                        dimension="small"
                        aria-label="Minimise"
                        icon={{ name: "Minimise", title: "Minimise" }}
                        onClick={() => {
                            setIsMinimised(true);
                        }}
                    />
                </StyledTabsBar>
                <ClinicianCompose
                    isMessageSending={sendMessageMutation.isLoading}
                    onMessageSend={(args) => {
                        if (
                            // Just for type safety
                            conversation.source.system === "ClinicianMessaging"
                        ) {
                            sendMessageMutation.mutate(
                                {
                                    workspaceId: orgId.toString(),
                                    workspaceConversationId:
                                        conversation.source.serverId,
                                    message: `${args.messageBody}${
                                        args.messageSignature
                                            ? `\n${args.messageSignature}`
                                            : ""
                                    }`,
                                    attachmentIds: args.attachments.map(
                                        (att) => att.id,
                                    ),
                                    isPresetTemplate: args.template !== null,
                                    templateGroup:
                                        args.template?.value.heading ?? null,
                                    templateName:
                                        args.template?.value.title ?? null,
                                },
                                {
                                    onSuccess: () => {
                                        onClose();
                                    },
                                    onError: () => {
                                        toast(<ReplyFailedFeedback />, {
                                            autoClose: false,
                                        });
                                    },
                                },
                            );
                        }
                    }}
                    recipient={recipient}
                    conversationId={conversation.id}
                    patient={patient}
                    countParticipant={conversation.participants.length}
                />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
