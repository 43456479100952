import { Button, Compose, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledComposeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[3]};
    flex: 1;

    background-color: ${Tokens.COLOURS.greyscale.white};
`;

export const StyledPatientResponseAndSendButton = styled(Flex).attrs({
    alignItems: "center",
    gap: "2",
})`
    margin-left: auto;
`;

export const StyledTopButtonRow = styled.div`
    gap: ${Tokens.SIZES[1.5]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    background: ${Tokens.COLOURS.greyscale.white};
`;

export const StyledReponseCopyContainer = styled(Flex).attrs({
    flexDirection: "column",
    gap: "2",
})`
    margin-top: ${Tokens.SIZES[2]};
`;

export const StyledWriteAMessageButton = styled(Button)`
    margin-top: auto;
    width: 100%;
    justify-content: center;
`;

export const StyledCompose = styled(Compose)`
    height: 100%;
`;

export const StyledCollapseButton = styled(Button)`
    margin-left: auto;
`;

export const StyledTemplatesAndQuestionnaireButton = styled(Button)`
    flex: 1;
    white-space: nowrap;
    display: block;
`;
