import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateAcknowledgeMedicalRecordUserChangePayload,
    mutateAcknowledgeMedicalRecordUserChange,
} from "domains/native/api/endpoints/mutateAcknowledgeMedicalRecordUserChange";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

export type UseAcknowledgeMedicalRecordUserChangeMutationVariables =
    MutateAcknowledgeMedicalRecordUserChangePayload;

export const useAcknowledgeMedicalRecordUserChangeMutation = (
    options?: UseMutationOptions<
        unknown,
        NativeFetchError | NativeTransportError,
        UseAcknowledgeMedicalRecordUserChangeMutationVariables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "AcknowledgeMedicalRecordUserChangeMutation: Called with no native transport",
            );
        }

        await mutateAcknowledgeMedicalRecordUserChange(
            { transport, workspaceId },
            {
                logout: variables.logout,
            },
        );
    }, options);
};
