import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFilterBarContainer = styled.div`
    margin-top: ${Tokens.SIZES[3]};
    margin-bottom: ${Tokens.SIZES[1]};
    display: flex;
    justify-content: space-between;
`;

export const StyledHeaderContainer = styled.div`
    padding: ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[3]};
        padding-bottom: ${Tokens.SIZES[2]};
    }
    border-bottom: ${Tokens.BORDERS.normal.silver};
    background: ${Tokens.COLOURS.greyscale.white};
    box-shadow: ${Tokens.SHADOWS.raisedInset.high.silver};
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledFilterBarItemContainer = styled.div`
    display: flex;
`;

export const StyledBottomNavigationContainer = styled.div`
    margin-top: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[3]};
    display: flex;
    justify-content: space-between;
`;

export const StyledBottomNavigationItem = styled.div`
    display: flex;
    margin-left: auto;
`;
