import React, { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeSubscriptionsProvider as BaseNativeSubscriptionsProvider,
    isSingleSignalRSupportedDesktopVersion,
    startNativeSubscription,
    useDesktopVersion,
    useNativeTransport,
} from "@accurx/native";
import { useNativeConversationListener } from "domains/inbox/native/subscriptions/useNativeConversationListener";
import { useNativePatientDemographicsBackgroundUpdates } from "domains/inbox/native/subscriptions/useNativePatientDemographicsBackgroundUpdates";
import { useNativeWorkspaceChangeListener } from "domains/inbox/native/subscriptions/useNativeWorkspaceChangeListener";

export const NativeSubscriptionsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const transport = useNativeTransport();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;
    const desktopVersion = useDesktopVersion();

    useNativePatientDemographicsBackgroundUpdates();
    useNativeWorkspaceChangeListener();
    useNativeConversationListener();

    useEffect(() => {
        if (
            !transport ||
            !isSingleSignalRSupportedDesktopVersion(desktopVersion)
        )
            return;

        const unsubscribeSignalRLifecycleEvents = startNativeSubscription({
            subscription: "SubscribeSignalRLifecycleEvents",
            transport,
            workspaceId,
        });

        return () => {
            unsubscribeSignalRLifecycleEvents();
        };
    }, [desktopVersion, transport, workspaceId]);

    useEffect(() => {
        if (!transport) return;

        const unsubscribeCurrentPatient = startNativeSubscription({
            subscription: "SubscribeCurrentPatient",
            transport,
            workspaceId,
        });
        const unsubscribeMedicalRecordConnection = startNativeSubscription({
            subscription: "SubscribeMedicalRecordConnection",
            transport,
            workspaceId,
        });

        return () => {
            unsubscribeCurrentPatient();
            unsubscribeMedicalRecordConnection();
        };
    }, [transport, workspaceId]);

    return (
        <BaseNativeSubscriptionsProvider>
            {children}
        </BaseNativeSubscriptionsProvider>
    );
};
