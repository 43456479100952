import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useTeamConversationGroupQuery } from "@accurx/concierge/hooks/queries/useTeamConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import {
    ConversationFeed,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import {
    StyledConversationList,
    StyledHeaderTitle,
    StyledHeaderWrap,
} from "../styles";

export const Shared = () => {
    const { teamId, status } = useInboxParams(["teamId", "status"]);
    const link = useInboxLink();
    const team = useTeam({ teamId });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        team
            ? `Assigned to ${
                  team.displayName
              } ${status.toLowerCase()} conversations`
            : "",
    );
    const groupQuery = useTeamConversationGroupQuery({
        teamId,
        status,
    });

    return team ? (
        <>
            <StyledConversationList rows="auto 1fr">
                <StyledHeaderWrap>
                    <ConversationListHeader
                        heading={
                            <StyledHeaderTitle>
                                {team.displayName}
                            </StyledHeaderTitle>
                        }
                    />

                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </StyledHeaderWrap>

                <ConversationFeed
                    key={groupQuery.data?.id}
                    isLoading={groupQuery.status === "loading"}
                    isError={groupQuery.status === "error"}
                    refetch={groupQuery.refetch}
                    fetchMore={groupQuery.fetchMore}
                    isFetchingMore={groupQuery.isFetchingMore}
                    isFullyLoaded={groupQuery.data?.isFullyLoaded}
                    members={groupQuery.data?.members}
                    filters={groupQuery.data?.filters}
                    renderItem={(conversation, state) => (
                        <ConversationPreview
                            conversation={conversation}
                            item={getMostRecentItem(conversation)}
                            state={state}
                        />
                    )}
                />
            </StyledConversationList>
        </>
    ) : null;
};
