import React, { ChangeEvent, InputHTMLAttributes } from "react";

import { ButtonProps } from "@accurx/design";

import { StyledFileInputButton } from "./FileInputButton.styles";

type FileInputButtonProps = {
    /** Unique string to identify the upload field */
    id: string;
    /** Which file extensions are allowed */
    allowedFileExtensions: string[];
    /**
     * What to do once the file(s) have been selected
     */
    onFileChange: (fileList: FileList | null) => void;
    /** Use this prop for setting up the text and look and feel of the label
     * No need to pass disabled prop, as that gets set based on the file input props
     */
    labelButton: ButtonProps & { disabled?: never };
} & Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "accept" | "onChange" | "children"
>;

/**
 * A UI-only, customisable file upload component.
 *
 * No validation done, this needs to be done by the components using it.
 *
 * For now this is sitting in batchMessage/trust product, but if found useful,
 * if can be reused for other products too
 */
export const FileInputButton = React.forwardRef<
    HTMLInputElement,
    FileInputButtonProps
>(({ id, onFileChange, allowedFileExtensions, labelButton, ...props }, ref) => {
    const handleFilePicked = (e: ChangeEvent<HTMLInputElement>) => {
        onFileChange(e.target.files);
    };

    return (
        <StyledFileInputButton.Wrapper>
            <StyledFileInputButton.FileInput
                type="file"
                accept={`.${allowedFileExtensions.join(",.")}`}
                id={id}
                data-testid={id}
                ref={ref}
                onChange={handleFilePicked}
                {...props}
            />
            <StyledFileInputButton.Label htmlFor={id}>
                <StyledFileInputButton.StyledButton
                    {...labelButton}
                    forwardedAs="span"
                    disabled={props.disabled}
                />
            </StyledFileInputButton.Label>
        </StyledFileInputButton.Wrapper>
    );
});
