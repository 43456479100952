import { NhsNumberHelpers } from "@accurx/shared";

import { sanitiseNhsNumber } from "./sanitiseNhsNumber";
import {
    FormErrorsEMRPatientDetails,
    FormErrorsEmis,
    FormStateEMRPatientDetails,
    FormStateEmis,
} from "./types";

export const validateEmisForm = (
    formState: FormStateEmis,
): {
    isValid: boolean;
    errors: FormErrorsEmis;
} => {
    const errors: FormErrorsEmis = {};

    if (!formState.searchTerm) {
        errors.searchTerm = "Enter a search term";
    }

    return {
        isValid: Object.values(errors).length === 0,
        errors,
    };
};

export const validateEMRPatientDetailsForm = (
    formState: FormStateEMRPatientDetails,
): {
    isValid: boolean;
    errors: FormErrorsEMRPatientDetails;
} => {
    const errors: FormErrorsEMRPatientDetails = {};

    // If user enters NHs number, we only care about that
    if (formState.nhsNumber) {
        const validatedNhsNumber = NhsNumberHelpers.validateNhsNumber(
            sanitiseNhsNumber(formState.nhsNumber),
        );
        if (validatedNhsNumber.error) {
            errors.nhsNumber = validatedNhsNumber.error;
        }
        // If no nhs number is entered, we check that they added both a first and last name
    } else if (!formState.firstName || !formState.lastName) {
        errors.allFieldsError =
            "Enter either an NHS Number or both a First and Last name to perform a valid search";
    }

    return {
        isValid: Object.values(errors).length === 0,
        errors,
    };
};
