import React from "react";

import { FormFieldWrapper, Switch, Text, VisuallyHidden } from "@accurx/design";

import {
    StyledFlexWrapper,
    StyledSettingsSection,
    StyledTopLevelSettingsWrapper,
} from "./WorkingHoursTopLevelSettings.styles";

export type WorkingHoursTopLevelSettingsProps = {
    updateSuspendRequestsOutOfHours: (value: boolean) => void;
    updateAllowAdminOutOfHours: (value: boolean) => void;
    updateExcludeAdminFromLimit: (value: boolean) => void;
    suspendRequestsOutOfHours: boolean;
    allowAdminOutOfHours: boolean;
    excludeAdminFromLimit: boolean;
};

export const WorkingHoursTopLevelSettings = ({
    updateSuspendRequestsOutOfHours,
    updateAllowAdminOutOfHours,
    updateExcludeAdminFromLimit,
    suspendRequestsOutOfHours,
    allowAdminOutOfHours,
    excludeAdminFromLimit,
}: WorkingHoursTopLevelSettingsProps): JSX.Element => {
    const handleSwitchSuspended = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        updateSuspendRequestsOutOfHours(checked);
        if (!checked) updateAllowAdminOutOfHours(false);
    };

    const handleAllowAdminOutOfHours = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { checked } = e.target;
        updateAllowAdminOutOfHours(checked);
    };

    const handleExcludeAdminFromLimit: React.ChangeEventHandler<
        HTMLInputElement
    > = (e) => {
        updateExcludeAdminFromLimit(e.target.checked);
    };

    return (
        <StyledTopLevelSettingsWrapper>
            <FormFieldWrapper label="Suspend patient requests while out of hours">
                <div>
                    <StyledFlexWrapper>
                        <Switch
                            labelText={
                                <VisuallyHidden as="span">
                                    Suspend patient requests while out of hours
                                </VisuallyHidden>
                            }
                            labelVariant="body"
                            onChange={handleSwitchSuspended}
                            defaultChecked={suspendRequestsOutOfHours}
                        />
                        <Text>{suspendRequestsOutOfHours ? "Yes" : "No"}</Text>
                    </StyledFlexWrapper>
                </div>
            </FormFieldWrapper>
            {suspendRequestsOutOfHours && (
                <StyledSettingsSection>
                    <Text variant="label">While out of hours</Text>
                    <StyledFlexWrapper>
                        <input
                            type="checkbox"
                            id="allowAdminOutOfHours"
                            checked={allowAdminOutOfHours}
                            onChange={handleAllowAdminOutOfHours}
                        />
                        <Text
                            skinny
                            as="label"
                            props={{
                                htmlFor: "allowAdminOutOfHours",
                            }}
                            className="ml-2"
                        >
                            Suspend medical request access only
                        </Text>
                    </StyledFlexWrapper>
                </StyledSettingsSection>
            )}
            <div>
                <Text variant="label">What is included in the daily limit</Text>
                <StyledFlexWrapper>
                    <input
                        type="checkbox"
                        id="excludeAdminFromLimit"
                        checked={excludeAdminFromLimit}
                        onChange={handleExcludeAdminFromLimit}
                    />
                    <Text
                        skinny
                        as="label"
                        props={{
                            htmlFor: "excludeAdminFromLimit",
                        }}
                        className="ml-2"
                    >
                        Exclude admin requests from the limit
                    </Text>
                </StyledFlexWrapper>
            </div>
        </StyledTopLevelSettingsWrapper>
    );
};

export const StaticWorkingHoursTopLevelSettings = ({
    suspendRequestsOutOfHours,
    allowAdminOutOfHours,
    excludeAdminFromLimit,
}: Omit<
    WorkingHoursTopLevelSettingsProps,
    | "updateSuspendRequestsOutOfHours"
    | "updateAllowAdminOutOfHours"
    | "updateExcludeAdminFromLimit"
>): JSX.Element => {
    return (
        <StyledTopLevelSettingsWrapper>
            <Text>
                <Text as="span" variant="label" skinny>
                    Suspend Patient Triage access during out of hours:
                </Text>{" "}
                {suspendRequestsOutOfHours ? "Yes" : "No"}
            </Text>
            {suspendRequestsOutOfHours && (
                <StyledSettingsSection>
                    <Text variant="label">While out of hours</Text>
                    <StyledFlexWrapper>
                        <Text skinny>
                            Suspend medical request access only:{" "}
                            {allowAdminOutOfHours ? "Yes" : "No"}
                        </Text>
                    </StyledFlexWrapper>
                </StyledSettingsSection>
            )}
            <div>
                <Text variant="label">What is included in the daily limit</Text>
                <StyledFlexWrapper>
                    <Text skinny>
                        Exclude admin requests from the limit:{" "}
                        {excludeAdminFromLimit ? "Yes" : "No"}
                    </Text>
                </StyledFlexWrapper>
            </div>
        </StyledTopLevelSettingsWrapper>
    );
};
