import { Dispatch } from "redux";

import { getPractices } from "api/PracticesApi";
import { getVaccineDetails } from "api/VaccineApi";

import { PracticeDetailsDto, VaccineApprovalDetails } from "./Practices.types";
import { UpdateVaccineSiteLinksAction } from "./vaccine/approvalRequests/ApprovalRequests.actions";

export enum Actions {
    GET_PRACTICES_FAILURE = "GET_PRACTICES_FAILURE",
    GET_PRACTICES_STARTED = "GET_PRACTICES_STARTED",
    GET_PRACTICES_SUCCESS = "GET_PRACTICES_SUCCESS",
    SET_SELECTED_PRACTICE = "SET_SELECTED_PRACTICE",
}
type DefaultActions = BaseActions<typeof Actions>;
export type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.GET_PRACTICES_SUCCESS]: {
            practices: PracticeDetailsDto[];
            vaccineApprovalDetails: VaccineApprovalDetails[];
        };
        [Actions.SET_SELECTED_PRACTICE]: { selectedPractice: string };
    }
>;
export type KnownAction =
    | ExtendedActions[keyof ExtendedActions]
    | UpdateVaccineSiteLinksAction;

export const loadPractices =
    () =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_PRACTICES_STARTED });
        const res = await getPractices();
        if (res.success && res.result) {
            const practices: PracticeDetailsDto[] = res.result;

            // Saving userId to windows object so app insights can have access to it at the earliest point
            if (practices[0]?.currentUserAnalyticsId) {
                window.accurxUserId = practices[0].currentUserAnalyticsId;
            }

            // Loading Vaccine details display flags
            const vaccineRes = await getVaccineDetails();

            return dispatch({
                type: Actions.GET_PRACTICES_SUCCESS,
                practices,
                vaccineApprovalDetails: vaccineRes.result || [],
            });
        }
        return dispatch({
            type: Actions.GET_PRACTICES_FAILURE,
            error:
                res.error ||
                "Something went wrong loading the practice information, please refresh the page.",
        });
    };

export const selectPractice = (selectedPractice: string) => ({
    type: Actions.SET_SELECTED_PRACTICE,
    selectedPractice,
});
