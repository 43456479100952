import { useEffect, useMemo, useState } from "react";

import { usePatientManager } from "shared/concierge/patients/context/PatientManagerContext";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";
import {
    ErrorStatus,
    LoadingStatus,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";

export const usePatient = (
    patientId: string | null | undefined,
): SubscriptionResult<PatientSummary> => {
    const manager = usePatientManager();

    const [state, setState] = useState<SubscriptionResult<PatientSummary>>(
        patientId ? initialLoadingState : initialMissingPatientIdState,
    );

    const subscription = useMemo(() => {
        if (patientId) {
            return manager
                .getPatient({
                    patientIdentity: { id: patientId },
                })
                .feed.subscribe(setState);
        } else {
            setState(initialMissingPatientIdState);
        }
    }, [patientId, manager]);

    useEffect(() => () => subscription?.unsubscribe(), [subscription]);

    return state;
};

const initialLoadingState: LoadingStatus = {
    status: "LOADING",
    data: null,
    errorMessage: null,
};

const initialMissingPatientIdState: ErrorStatus = {
    status: "ERROR",
    data: null,
    errorMessage: "No patient ID provided",
};
