import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTemplateListContainer = styled.div`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
`;
