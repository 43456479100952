import * as UI from "@accurx/design";
import { Reason } from "domains/inbox/hooks/util/useUpdatingFeed";

import { StyledContainer, StyledOverlay } from "./StateChangeOverlay.styles";

export type StateChangeOverlayProps = {
    reason: Reason;
};

export const StateChangeOverlay = ({ reason }: StateChangeOverlayProps) => {
    if (reason === "Unknown") return null;

    return (
        <StyledContainer>
            {reason === "MarkedDone" && (
                <StyledOverlay justifyContent="center" alignItems="center">
                    <UI.Icon name="Tick" size={3} colour="green" />
                    <UI.Text variant="note">
                        Conversation marked as done
                    </UI.Text>
                </StyledOverlay>
            )}
            {reason === "MarkedOpen" && (
                <StyledOverlay justifyContent="center" alignItems="center">
                    <UI.Icon name="Upload" size={3} colour="green" />
                    <UI.Text variant="note">Conversation re-opened</UI.Text>
                </StyledOverlay>
            )}
            {reason === "Reassigned" && (
                <StyledOverlay justifyContent="center" alignItems="center">
                    <UI.Icon name="Upload" size={3} colour="green" />
                    <UI.Text variant="note">Conversation re-assigned</UI.Text>
                </StyledOverlay>
            )}
        </StyledContainer>
    );
};
