import React, { ComponentProps, ReactNode } from "react";

import { Avatar } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";

import { SkeletonPreview } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";

import { PATIENT_NAME_MAX_CHAR_LENGTH } from "./PatientPill.constants";
import {
    StyledPatientPillAgeGenderContainer,
    StyledPatientPillAvatarContainer,
    StyledPatientPillContainer,
    StyledPatientPillInfoContent,
    StyledPatientPillInnerContainer,
    StyledPatientPillNameContainer,
    StyledPatientPillNhsNumberContainer,
} from "./PatientPill.styles";

const mapToDisplayGender = (gender: string | undefined): string | undefined => {
    switch (gender?.toLowerCase()) {
        case "male":
            return "M";

        case "female":
            return "F";

        default:
            return undefined;
    }
};

const mapToDisplayGenderVerbose = (
    gender: string | undefined,
): string | undefined => {
    switch (gender?.toLowerCase()) {
        case "male":
            return "Male";

        case "female":
            return "Female";

        default:
            return undefined;
    }
};

const mapToDisplayNhsNumber = (nhsNumber: string | undefined): string => {
    if (nhsNumber) {
        return NhsNumberHelpers.formatNhsNumber(nhsNumber);
    }
    return "Unknown";
};

const TextWithSeparator = ({ text }: { text: string }): JSX.Element => {
    return <>&nbsp;· {text}</>;
};

type PatientPillProps = {
    patient?: Pick<
        PatientSummary,
        | "ageYear"
        | "dateOfBirth"
        | "firstName"
        | "familyName"
        | "externalIds"
        | "gender"
    >;
    verbose?: boolean;
};

export const PatientPill = ({
    patient,
    verbose,
}: PatientPillProps): JSX.Element => {
    const nameForAvatar = patient
        ? `${patient.firstName} ${patient.familyName}`
        : undefined;

    const nameForLabel = patient
        ? formatPatientDisplayName({
              firstName: patient.firstName,
              familyName: patient.familyName,
          }) ?? "Unknown patient"
        : "Unknown patient";

    const nhsNumber = patient
        ? mapToDisplayNhsNumber(
              patient.externalIds.find((id) => id.type === "NhsNumber")?.value,
          )
        : undefined;
    const age = patient?.ageYear;

    const dateOfBirth = !!patient?.dateOfBirth
        ? DateHelpers.formatDate(
              patient.dateOfBirth,
              DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
          )
        : undefined;
    const gender = mapToDisplayGender(patient?.gender);
    const genderVerbose = mapToDisplayGenderVerbose(patient?.gender);

    return (
        <BasePatientPill
            nameForAvatar={nameForAvatar}
            sizeForAvatar={verbose ? "medium" : "small"}
            verbose={verbose}
        >
            <StyledPatientPillNameContainer verbose={verbose}>
                {nameForLabel}
            </StyledPatientPillNameContainer>
            {nhsNumber !== undefined && (
                <StyledPatientPillNhsNumberContainer>
                    <TextWithSeparator text={`NHS: ${nhsNumber}`} />
                </StyledPatientPillNhsNumberContainer>
            )}
            {verbose && dateOfBirth !== undefined && (
                <StyledPatientPillAgeGenderContainer>
                    <TextWithSeparator text={`Born: ${dateOfBirth}`} />
                </StyledPatientPillAgeGenderContainer>
            )}
            {!verbose && Number.isInteger(age) && (
                <StyledPatientPillAgeGenderContainer>
                    <TextWithSeparator text={`${age}y`} />
                </StyledPatientPillAgeGenderContainer>
            )}
            {gender !== undefined && (
                <StyledPatientPillAgeGenderContainer>
                    <TextWithSeparator
                        text={verbose ? `Gender: ${genderVerbose}` : gender}
                    />
                </StyledPatientPillAgeGenderContainer>
            )}
        </BasePatientPill>
    );
};

export const LoadingPatientPill = ({
    verbose,
}: {
    verbose?: boolean;
}): JSX.Element => {
    return (
        <BasePatientPill
            nameForAvatar={undefined}
            sizeForAvatar={verbose ? "medium" : "small"}
        >
            <SkeletonPreview
                // Make character count in preview be the max name length + leave space for nhs number age and gender
                charCount={PATIENT_NAME_MAX_CHAR_LENGTH + 22}
                data-testid="loading-patient-pill"
            />
        </BasePatientPill>
    );
};

type BasePatientPillProps = {
    nameForAvatar: string | undefined;
    sizeForAvatar: ComponentProps<typeof Avatar>["size"];
    children: ReactNode;
    verbose?: boolean;
};

const BasePatientPill = ({
    nameForAvatar,
    sizeForAvatar,
    children,
    verbose,
}: BasePatientPillProps): JSX.Element => {
    return (
        <StyledPatientPillContainer>
            <StyledPatientPillInnerContainer>
                <StyledPatientPillAvatarContainer>
                    <Avatar
                        name={nameForAvatar}
                        colour="grey"
                        size={sizeForAvatar}
                    />{" "}
                </StyledPatientPillAvatarContainer>
                <StyledPatientPillInfoContent verbose={verbose}>
                    {children}
                </StyledPatientPillInfoContent>
            </StyledPatientPillInnerContainer>
        </StyledPatientPillContainer>
    );
};
