import { useState } from "react";

import { Thread } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { copyToClipboard } from "shared/Browser.helper";
import {
    PatientNhsAppMessageConversationItem,
    PatientSmsConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { CustomTemplate } from "../../../CustomTemplate/CustomTemplate";
import { TurnIntoTemplateButton } from "../../Helpers/TurnIntoTemplateButton/TurnIntoTemplateButton";
import { BaseConversationThreadItem } from "../threadItem.types";

type PatientConversationItem =
    | PatientSmsConversationItem
    | PatientNhsAppMessageConversationItem;

export type PatientGenericThreadProps =
    BaseConversationThreadItem<PatientConversationItem> & {
        patientFullName: string | null;
        patientInfo: string[] | null;
        isFirstMessageByUser: boolean;
        deliveryMethod: string;
        mobileNumber?: string | null;
    };

export const MessageConversationThreadItem = ({
    item,
    patientFullName,
    isFirstMessageByUser,
    deliveryMethod,
    handleOnItemRead,
    createdByDisplayName,
    mobileNumber,
    patientInfo,
}: PatientGenericThreadProps) => {
    const dateCreated = formatConversationItemDateCreatedShort(item.createdAt);
    const [showCreateTemplateForm, setShowCreateTemplateForm] = useState(false);

    // TODO: any needs to be changed with the type Feedback once its exported from design lib
    const onCopyClick = async (): Promise<{ text: string } | undefined> => {
        const message = generateGenericMessageCopy(
            item,
            patientFullName,
            createdByDisplayName,
            mobileNumber,
        );
        if (await copyToClipboard(message)) {
            return { text: "Copied" };
        }
        return { text: "Failed to copy" };
    };

    const onTurnIntoTemplateClick = () => {
        setShowCreateTemplateForm(true);
    };

    return (
        <>
            <Thread.Message
                inboxType="sent"
                deliveryMethod={deliveryMethod}
                sent={item.deliveryStatus === "Sent"}
                delivered={item.deliveryStatus === "Delivered"}
                failed={item.deliveryStatus === "Failed"}
                senderFullName={{
                    name: createdByDisplayName
                        ? {
                              fullName: createdByDisplayName,
                              shouldDisplayFullName: true,
                          }
                        : null,
                }}
                dateCreated={dateCreated}
                actionButtons={[
                    {
                        id: `turn-into-template-${item.id}`,
                        customElement: (
                            <TurnIntoTemplateButton
                                id={item.id}
                                onClick={onTurnIntoTemplateClick}
                                isFirstMessageByUser={isFirstMessageByUser}
                            />
                        ),
                    },
                    {
                        text: "Get a copy",
                        icon: { name: "Copy" },
                        dimension: "medium",
                        providesFeedback: true,
                        onClick: onCopyClick,
                    },
                ]}
                readActionProps={{
                    id: item.id,
                    onMessageSeen: handleOnItemRead,
                }}
            >
                {item.body}
            </Thread.Message>
            <QuickViewPortal
                isOpen={showCreateTemplateForm}
                onClose={() => setShowCreateTemplateForm(false)}
            >
                <QuickViewPortal.Body>
                    <CustomTemplate
                        key={item.id}
                        onCancel={() => setShowCreateTemplateForm(false)}
                        onSuccess={() => setShowCreateTemplateForm(false)}
                        patientConversationItem={item}
                        patientInfo={patientInfo}
                    />
                </QuickViewPortal.Body>
            </QuickViewPortal>
        </>
    );
};

export const generateGenericMessageCopy = (
    item: PatientGenericThreadProps["item"],
    patientFullName: string | null,
    createdByDisplayName: string | null | undefined,
    mobileNumber?: string | null,
): string => {
    const dateCreatedLong = DateHelpers.formatDate(
        item.createdAt,
        DateFormatOptions.HUMAN_READABLE_DATE_TIME,
    );

    const renderMobileNumber = mobileNumber
        ? `Mobile phone number:\n${mobileNumber}\n\n`
        : "";

    const sentTo = patientFullName ? `Sent to:\n${patientFullName}\n\n` : "";

    const sentBy = createdByDisplayName
        ? `Sent by:\n${createdByDisplayName}\n\n`
        : "";

    const dateTime = dateCreatedLong
        ? `Date and time:\n${dateCreatedLong}\n\n`
        : "";

    const messageText = item.body ? `Message text:\n${item.body}` : "";

    return `${sentTo}${renderMobileNumber}${sentBy}${dateTime}${messageText}`.trim();
};

export const patientMessageThreadItemSummaryText = (
    item: PatientNhsAppMessageConversationItem | PatientSmsConversationItem,
): string => item.body?.replace(/\s+/, " ") || "empty message";
