import { useEffect, useState } from "react";

import { History, Location } from "history";
import { useHistory, useLocation } from "react-router";

const ATTACHMENT_ID_PARAM = "attachmentId";

const getAttachmentIdToHighlight = (
    location: Location,
    history: History,
): string | undefined => {
    const params = new URLSearchParams(location.search);

    const attachmentIdToHighlight =
        params.get(ATTACHMENT_ID_PARAM) ?? undefined;

    if (attachmentIdToHighlight !== undefined) {
        params.delete(ATTACHMENT_ID_PARAM);
        history.replace({ ...location, search: params.toString() });
    }

    return attachmentIdToHighlight;
};

export const useAttachmentIdToHighlight = () => {
    const history = useHistory();
    const location = useLocation();
    const [attachmentIdToHighlight, setAttachmentIdToHighlight] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        if (attachmentIdToHighlight === undefined) {
            const attachment = getAttachmentIdToHighlight(location, history);
            if (attachment !== undefined) {
                setAttachmentIdToHighlight(attachment);
            }
        }
    }, [
        location,
        history,
        setAttachmentIdToHighlight,
        attachmentIdToHighlight,
    ]);

    return attachmentIdToHighlight;
};
