import { useConciergeDispatch } from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { PatientRequestType } from "domains/concierge/internal/types/PatientsState";
import { useMutation } from "domains/concierge/internal/util/useMutation";
import { PatientSummary } from "domains/concierge/types";

export const useSetPatientRequestTimeMutation = () => {
    const dispatch = useConciergeDispatch();

    return useMutation(
        "SetPatientUpdateInfo",
        async ({
            patientId,
            type,
            time,
        }: {
            patientId: PatientSummary["patientId"];
            type: PatientRequestType;
            time: string | null;
            //  - this is not an async function but `useMutation` expects functions to be async
            // eslint-disable-next-line @typescript-eslint/require-await
        }) => {
            dispatch(
                actions.patients.setPatientRequestTime({
                    patientId,
                    time,
                    type,
                }),
            );
        },
    );
};
