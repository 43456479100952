import { useMemo, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    PatientResponseSection,
    PatientTriageRequestItem,
    UserSummary,
} from "@accurx/concierge/types";
import { itemHasAttachments } from "@accurx/concierge/util/itemHasAttachments";
import * as UI from "@accurx/design";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { ItemAttachments } from "domains/inbox/components/ConversationItem/components/ItemAttachments/ItemAttachments";
import { PatientNameTag } from "domains/inbox/components/ConversationItem/components/PatientNameTag/PatientNameTag";
import { QuestionnaireResponse } from "domains/inbox/components/ConversationItem/components/QuestionnaireResponse/QuestionnaireResponse";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { useGetPatientToClinicianMessageInfo } from "domains/inbox/components/ConversationItem/useCopyInfo";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { useItemAttachmentAnalytics } from "domains/inbox/hooks/util/useItemAttachmentAnalytics";
import { userflowIds } from "domains/inbox/util";

export const PatientTriageRequestNote = ({
    item,
    conversation,
}: ConversationItemComponent<PatientTriageRequestItem>) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const patientId = item.createdBy.id;
    const patient = usePatient({ patientId });
    const hasAttachments = useMemo(() => itemHasAttachments(item), [item]);
    const textContentRef = useRef<HTMLDivElement>(null);

    const copyInfo = useGetPatientToClinicianMessageInfo({
        patientId,
        dateCreatedAt: item.createdAt,
        viaProxy: !!item.patientProxy,
        messageBody: textContentRef.current?.innerText ?? "",
    });

    const proxySection = getProxySection(item.patientProxy);
    const receptionSection = getReceptionSection(
        useUser({ userId: item.reception?.userId ?? "" }),
    );

    const getSections = (): PatientResponseSection[] => {
        if (!proxySection && !receptionSection) return item.sections;
        return [
            ...(proxySection ? [proxySection] : []),
            ...(receptionSection && !proxySection ? [receptionSection] : []),
            ...item.sections,
        ];
    };

    const {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    } = useItemAttachmentAnalytics({ conversation });

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={patient ? "blue" : "yellow"}
            >
                <ConversationItemHeader>
                    <PatientNameTag patientId={patientId} />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge
                            color={patient ? "blue" : "yellow"}
                            size="small"
                        >
                            {item.patientProxy
                                ? "Via proxy"
                                : item.reception
                                ? "Via staff"
                                : "Patient"}
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <UI.Flex flexDirection="column" gap="3">
                        <UI.Item ref={textContentRef}>
                            <QuestionnaireResponse
                                title="Patient Triage"
                                subtitle={item.requestTitle ?? undefined}
                                sections={getSections()}
                                renderAttachments={({ attachments }) => (
                                    <ItemAttachments
                                        attachments={attachments}
                                        itemId={item.id}
                                        conversationId={conversation.id}
                                        patientId={patientId}
                                        getAttachmentUrl={({ attachment }) =>
                                            `/api/conversation/web/patientupload/${workspaceId}/${attachment.id}`
                                        }
                                        onItemPreview={
                                            trackConversationAttachmentPreviewLinkClick
                                        }
                                        onItemDeleteClick={
                                            trackConversationAttachmentRemoveButtonClick
                                        }
                                        onItemDeleteConfirm={({ reason }) => {
                                            trackConversationAttachmentRemoveMenuItemClick(
                                                reason,
                                            );
                                        }}
                                    />
                                )}
                            />
                        </UI.Item>
                        <SaveItemAction
                            conversation={conversation}
                            item={item}
                            getTextToCopy={copyInfo}
                            hasAttachments={hasAttachments}
                        />
                    </UI.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};

const getProxySection = (
    patientProxy: PatientTriageRequestItem["patientProxy"],
): PatientResponseSection | undefined => {
    if (!patientProxy) return;
    return {
        responses: [
            {
                answer: `${patientProxy.firstName ?? ""} ${
                    patientProxy.lastName ?? ""
                } ${
                    patientProxy.relationshipToPatient
                        ? `(${patientProxy.relationshipToPatient})`
                        : ""
                }`,
                attachments: [],
                displayQuestionBold: true,
                question:
                    "This request was submitted by a contact of the patient",
            },
        ],
    };
};

const getReceptionSection = (
    receptionUser?: UserSummary,
): PatientResponseSection | undefined => {
    if (!receptionUser) return;
    return {
        responses: [
            {
                answer: `${receptionUser.displayName} (colleague)`,
                attachments: [],
                displayQuestionBold: true,
                question: "This request was recorded by",
            },
        ],
    };
};
