import * as Portal from "@radix-ui/react-portal";

import { PrNumberPopover } from "../accurxPrNumber";
import {
    ButtonGroup,
    ToolbarContainer,
    ToolbarRoot,
} from "./DevToolbar.styles";
import { FeatureFlagPopover } from "./FeatureFlagPopover";
import { MoreOptionsPopover } from "./MoreOptionsPopover";
import { SignalRToggle } from "./SignalRToggle";
import { useDevToolbar } from "./useDevToolbar";

export const DevToolbar = () => {
    const { isOpen, toggleOpen } = useDevToolbar();

    if (!isOpen) return null;

    return (
        <Portal.Root>
            <ToolbarContainer>
                <ToolbarRoot>
                    <ButtonGroup>
                        <PrNumberPopover />
                        <FeatureFlagPopover />
                        <SignalRToggle />
                        <MoreOptionsPopover hideToolbar={toggleOpen} />
                    </ButtonGroup>
                </ToolbarRoot>
            </ToolbarContainer>
        </Portal.Root>
    );
};
