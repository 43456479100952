import { Log } from "@accurx/shared";
import { convertNativeErrorMessageToError } from "domains/native/errors";
import { NativeToWebMessageSchema } from "domains/native/schemas/NativeToWebMessageSchema";
import {
    NativeRequest,
    NativeTransport,
    NativeTransportEventListener,
    SubscriptionEvent,
    Unsubscribe,
} from "domains/native/types";
import camelCase from "lodash/camelCase";
import { v4 as uuid } from "uuid";

const request = ({
    transport,
    request,
}: {
    transport: NativeTransport;
    request: NativeRequest;
}): Promise<unknown> => {
    // Generate a unique request ID. This ID will be sent to the Native app.
    // The native app will then include the same ID in the response message.
    // This way we can connect the two.
    const requestId = uuid();

    // The response body is the same as the message type except the
    // message type is in PascalCase and the body key is in
    // camelCase.
    const payloadKey = camelCase(request.type);

    return new Promise((resolve, reject) => {
        const eventHandler: NativeTransportEventListener = (
            message,
            unsubscribe,
        ) => {
            // Parse the event to make sure that it conforms to the structure of a response message
            const messageParse = NativeToWebMessageSchema.safeParse(message);

            if (!messageParse.success) {
                return;
            }

            const event = messageParse.data;

            // Make sure that this message is in response to the request we sent
            if (event.requestId !== requestId) {
                return;
            }

            unsubscribe();

            // If the request failed reject
            if (!event.success) {
                reject(convertNativeErrorMessageToError(event.error));
                return;
            }

            // If the request was successful resolve
            resolve(event[payloadKey]);
        };

        try {
            transport.addEventListener(eventHandler);
            transport.postMessage({
                requestId,
                workspaceId: request.workspaceId,
                type: request.type,
                [payloadKey]: request.payload,
            });
        } catch (err) {
            Log.error(err as Error);
            throw err;
        }
    });
};

const subscribe = ({
    transport,
    request,
    onEvent,
}: {
    transport: NativeTransport;
    request: NativeRequest;
    onEvent: (data: SubscriptionEvent) => void;
}) => {
    // Generate a unique request ID. This ID will be sent to the Native app.
    // The native app will then include the same ID in the response message.
    // This way we can connect the two.
    const requestId = uuid();

    // The response body is the same as the message type except the
    // message type is in PascalCase and the body key is in
    // camelCase.
    const payloadKey = camelCase(request.type);

    const eventHandler: NativeTransportEventListener = (message) => {
        const messageParse = NativeToWebMessageSchema.safeParse(message);

        if (!messageParse.success) {
            return;
        }

        const event = messageParse.data;

        if (event.requestId !== requestId) {
            return;
        }

        if (!event.success) {
            onEvent({
                success: false,
                type: event.requestType,
                error: event.error,
            });
            return;
        }

        // If the request was successful resolve
        onEvent({
            success: true,
            type: request.type,
            data: event[payloadKey],
        });
    };

    let unsubscribe: Unsubscribe = () => undefined;

    try {
        unsubscribe = transport.addEventListener(eventHandler);

        transport.postMessage({
            requestId,
            workspaceId: request.workspaceId,
            type: request.type,
            [payloadKey]: request.payload,
        });
    } catch (err) {
        Log.error(err as Error);
    }

    return unsubscribe;
};

export const NativeApi = {
    request,
    subscribe,
};
