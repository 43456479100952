import React, { useEffect, useState } from "react";

import { Accordion, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { getRecordViewRequestsForGp } from "api/RecordViewApi";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useAppSelector } from "store/hooks";

import { FeedbackMsg, RecordViewRequest } from "./RequestsList.types";
import { RequestsListTable } from "./RequestsListTable";

export const RequestsList = (): JSX.Element => {
    useAccurxWebTitle("View medical record requests");

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const [requests, setRequests] = useState<RecordViewRequest[]>([]);
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    useEffect(() => {
        if (practiceId) {
            (async function loadData(): Promise<void> {
                const res = await getRecordViewRequestsForGp(practiceId);
                if (!res.success || !res.result) {
                    setFeedbackMsg({
                        colour: "error",
                        msg:
                            res.error ||
                            "Something went wrong loading your information. Please refresh the page.",
                    });
                    return;
                }
                setRequests(res.result.recordAccessRequests);
            })();
        }
    }, [practiceId]);

    return (
        <>
            <Breadcrumb title="Record View requests" />
            <div className="mb-4">
                <Text variant="title" as="h1" skinny>
                    Record View requests
                </Text>
                <Text props={{ className: "mb-2" }}>
                    Here you can see who has requested Record View for a patient
                </Text>
                <Accordion header="Table status explained">
                    <Text skinny>
                        <Text as="span" variant="label">
                            Request failed:{" "}
                        </Text>
                        {"We were unable to retrieve this patient's record."}
                    </Text>
                    <Text skinny>
                        <Text as="span" variant="label">
                            Expired:{" "}
                        </Text>
                        The record display expired after 24 hours and is no
                        longer available.
                    </Text>
                    <Text skinny>
                        <Text as="span" variant="label">
                            Patient authorisation required:{" "}
                        </Text>
                        The patient needs to share their 6-digit access code
                        with the requester to authorise record view.
                    </Text>
                    <Text skinny>
                        <Text as="span" variant="label">
                            Authorisation failed:{" "}
                        </Text>
                        {
                            "We are unable to display the record as there were 15 failed attempts to enter the patient's 6 digit access code into Accurx Web."
                        }
                    </Text>
                    <Text skinny>
                        <Text as="span" variant="label">
                            Currently requesting:{" "}
                        </Text>
                        Record View has been authorised and the display is
                        pending.
                    </Text>
                </Accordion>
            </div>

            {feedbackMsg.msg && feedbackMsg.colour && (
                <Feedback
                    title={feedbackMsg.msg}
                    colour={feedbackMsg.colour}
                    props={{ className: "mb-3" }}
                />
            )}

            <div className="mb-4">
                <RequestsListTable requests={requests} />
            </div>
        </>
    );
};
