import React from "react";

import { i } from "images";
import { generatePath } from "react-router";

import { PrNumberPopover } from "app/layout/accurxPrNumber";
import { useCurrentOrganisation } from "app/organisations/hooks";
import { ROUTES_ORGS } from "shared/Routes";

import { IconLink, Logo, Nav, NavContainer, Spacer } from "./Navbar.styles";
import { AccountDropdown, OrganisationDropdown } from "./components";

export const Navbar = (): JSX.Element => {
    const org = useCurrentOrganisation();
    return (
        <header>
            <Nav>
                <NavContainer>
                    <IconLink
                        to={
                            org
                                ? generatePath(ROUTES_ORGS.org, {
                                      orgId: org?.orgId,
                                  })
                                : ROUTES_ORGS.root
                        }
                    >
                        <Logo
                            src={i("accurx-logo-full.svg")}
                            alt="Accurx home"
                        />
                    </IconLink>
                    <Spacer />
                    <PrNumberPopover />
                    <OrganisationDropdown />
                    <AccountDropdown />
                </NavContainer>
            </Nav>
        </header>
    );
};
