import { TabProps } from "@accurx/design";
import { useParams } from "react-router";

import { useCurrentOrganisation } from "app/organisations/hooks";

import { TabLookup } from "./SettingsPage.constants";

export type UseSettingsPage = () => {
    tab?: TabProps;
};

export const useSettingsPage: UseSettingsPage = () => {
    const org = useCurrentOrganisation();
    const defaultTab = org ? "users" : "profile";
    const { tab: slug } = useParams<{ tab: string }>();
    return { tab: TabLookup[slug || defaultTab] };
};
