import { useNativeSignalRConnectionQuery } from "@accurx/native";
import { OptionalHubClient } from "domains/transport/hooks/useOptionalHubClient";
import { useOptionalHubClientConnectionState } from "domains/transport/hooks/useOptionalHubClientConnectionState";
import { SignalRConnection } from "domains/transport/types";

export const useSignalRConnectionState = (
    hubClient: OptionalHubClient,
): SignalRConnection => {
    const nativeSignalRConnection = useNativeSignalRConnectionQuery().data;
    const hubClientConnectionState =
        useOptionalHubClientConnectionState(hubClient);

    switch (nativeSignalRConnection?.state) {
        case "Uninitialised":
        case "Starting":
            return "Initialising";
        case "Open":
            return "Connected";
        case "Closed":
            return "Disconnected";
    }

    switch (hubClientConnectionState?.state) {
        case "Initialising":
            return "Initialising";
        case "Connected":
            return "Connected";
        case "Disconnected":
            return "Disconnected";
    }

    return "Initialising";
};
