import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSelectItem = styled(UI.SelectItem)`
    font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
    line-height: ${UI.Tokens.TYPOGRAPHY.note.desktop.lineHeight};
    min-height: ${UI.Tokens.SIZES[4]};
    margin: 0;
    padding: ${UI.Tokens.SIZES[0.25]} ${UI.Tokens.SIZES[1.5]};
    display: flex;
    align-items: center;
`;
