import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const NoReceptionDataWrapper = styled.div`
    text-align: center;
    position: absolute;
    // Specific values used to absolutely position the edges of the
    // modal against the edges of the rendered graph (excluding legends and axes).
    top: -5%;
    left: 4%;
    width: 96%;
    height: 86%;
    // Using custom hex here as design library sets alpha values to 0
    // where I need an alpha value of 20 here.
    background-color: #191c4d20;

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        display: none;
    }
`;

export const NoReceptionDataCard = styled.div`
    width: 60%;
    max-width: 367px;
    margin: 0 auto;
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`;
