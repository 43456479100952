import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useCurrentlyTypingUsers } from "domains/inbox/hooks/useCurrentlyTypingUsers";
import { useCurrentlyViewingUsers } from "domains/inbox/hooks/useCurrentlyViewingUsers";

import { StyledBadge, StyledText } from "./LiveUserActivityBadge.styles";

export type LiveUserActivityBadgeProps = {
    conversationId: string;
};

const getUserIsViewingText = (users: string[]): JSX.Element | null => {
    switch (users.length) {
        case 0:
            return null;
        case 1:
            return (
                <>
                    <UI.Text variant="note" as="span" skinny>
                        {users[0]}
                    </UI.Text>{" "}
                    is viewing
                </>
            );
        default:
            return (
                <>
                    <UI.Text variant="note" as="span" skinny>
                        {users.length} colleagues
                    </UI.Text>{" "}
                    are viewing
                </>
            );
    }
};

const USER_IS_TYPING_DEFAULT_TEXT = "Multiple colleagues are typing...";

const getUserIsTypingText = (
    users: string[],
    defaultText?: string,
): JSX.Element | null => {
    switch (users.length) {
        case 0:
            return null;
        case 1:
            return (
                <>
                    <UI.Text variant="note" as="span" skinny>
                        {users[0]}
                    </UI.Text>{" "}
                    is typing...
                </>
            );
        default:
            return <>{defaultText ?? USER_IS_TYPING_DEFAULT_TEXT}</>;
    }
};

export const LiveUserActivityBadge = ({
    conversationId,
}: LiveUserActivityBadgeProps): JSX.Element | null => {
    const track = useAnalytics();
    const viewingUsers = useCurrentlyViewingUsers(conversationId);
    const typingUsers = useCurrentlyTypingUsers(conversationId);
    const nativeTrackingFields = useNativeTrackingFields();

    const hasViewingUsers = viewingUsers.length > 0;
    const hasTypingUsers = typingUsers.length > 0;

    if (!hasViewingUsers && !hasTypingUsers) {
        return null;
    }

    const isViewingTextDisplayed = (): boolean => {
        // Display viewing text when there are either multiple viewing
        // users or no typing users. ie do not display when there is
        // one user viewing and typing
        return viewingUsers.length > 1 || typingUsers.length === 0;
    };

    return (
        <WithTooltip
            isEnabled={viewingUsers.length > 1}
            content={
                <StyledText skinny variant="note" colour="white">
                    {viewingUsers.length === 1 ? "Colleague" : "Colleagues"}{" "}
                    viewing: {viewingUsers.join(", ")}
                </StyledText>
            }
            props={{
                root: {
                    onOpenChange: (open) => {
                        if (!open || viewingUsers.length === 0) return;

                        track("ConversationColleagueViewing ListItem Hover", {
                            accessType: nativeTrackingFields.accessType,
                            pageOrigin: "ConversationActions",
                            countColleague: viewingUsers.length,
                        });
                    },
                },
            }}
        >
            <StyledBadge>
                <StyledText skinny variant="preview" aria-live="polite">
                    {isViewingTextDisplayed() && (
                        <>
                            {getUserIsViewingText(viewingUsers)}
                            {hasViewingUsers && hasTypingUsers && ", "}
                        </>
                    )}
                    {getUserIsTypingText(
                        typingUsers,
                        isViewingTextDisplayed()
                            ? USER_IS_TYPING_DEFAULT_TEXT.toLowerCase()
                            : USER_IS_TYPING_DEFAULT_TEXT,
                    )}
                </StyledText>
            </StyledBadge>
        </WithTooltip>
    );
};
