import { Log } from "@accurx/shared";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

import {
    BaseConversationItem,
    Conversation,
    ConversationDisplaySummary,
    ConversationSummary,
    ConversationUpdate,
} from "./types/conversation.types";
import { ConversationGroupSortOptions } from "./types/conversationGroup.types";

/**
 * Items are currently always sent over in full, so if we have an update replace the existing item.
 * Item ordering is also performed here, by order of creation date, which is immutable.
 *
 * @param currentItems The existing item data we have for a conversation
 * @param updatedItems Updated or re-sent items for a conversation
 */
const mergeConversationItems = <TItem extends BaseConversationItem>(
    currentItems: TItem[],
    updatedItems: TItem[],
): TItem[] => {
    // eliminate duplicates
    // createdAt are iso timestamps that can be compared lexographically without conversion
    // return sortConversations(uniqBy([...updatedItems, ...currentItems], (item) => item.id), "asc");
    return orderBy(
        uniqBy([...updatedItems, ...currentItems], (item) => item.id),
        ["createdAt", "id"],
        ["asc", "asc"],
    );
};

/**
 * Will merge conversation updates into the existing data we have for a conversation and return the merged result.
 *
 * @param currentUserId The ID of the current user for unread count calculations
 * @param existing The existing data we have for a conversation, if any
 * @param update Updates for a conversation, may contain one or more new items and updated summary information.
 */
export const mergeDeepConversation = (
    currentUserId: string,
    existing: Conversation | undefined,
    update: ConversationUpdate,
    epochVersion: number,
): Conversation => {
    if (!existing) {
        return {
            ...update,
            epochVersion: epochVersion,
            isFullyLoaded: update.isFullyLoaded,
            isStale: false,
            unreadCount: calculateUnreadCount(
                currentUserId,
                update,
                update.items,
            ),
        };
    }

    if (existing.id !== update.id) {
        Log.error(
            "Attempted to merge conversations with different IDs, ignoring updates",
            { tags: { existing: existing.id, updated: update.id } },
        );
        return existing;
    }

    const mergedItems = mergeConversationItems(existing.items, update.items);

    // As we can't guarantee the order we receive updates we use the latest token
    // to take the most up to date of 'updates' and 'existing' as the merged response
    // The server provides values that can be ordered lexographically
    const mostUpToDate =
        existing.latestToken > update.latestToken ? existing : update;

    return {
        ...mostUpToDate,
        items: mergedItems,
        epochVersion: epochVersion,
        isFullyLoaded: existing.isFullyLoaded || update.isFullyLoaded,
        isStale: false,
        unreadCount: calculateUnreadCount(
            currentUserId,
            mostUpToDate,
            mergedItems,
        ),
    };
};

/**
 * Will merge all conversation updates into the existing data we have for a conversation and return the merged result.
 * This also handles multiple updates for a given conversation and understand to thake the most accurate update for those.
 * @param currentUserId The ID of the current user for unread count calculations
 * @param existing The existing data we have for conversations, if any
 * @param updates Conversation updates, may contain one or more new items and updated summary information.
 */
export const mergeDeepAllConversations = (
    currentUserId: string,
    existing: Map<string, Conversation>,
    updates: ConversationUpdate[],
    epochVersion: number,
): Conversation[] => {
    // defensive: put updates in a Map so that if we have multiple updates for the same
    // conversation they collapse down to a single latest version, so that consuming code
    // doesn't need to consider duplicates in a single event.
    const allMerged = updates.reduce((acc, update) => {
        const merged = mergeDeepConversation(
            currentUserId,
            acc.get(update.id) ?? existing.get(update.id),
            update,
            epochVersion,
        );
        acc.set(update.id, merged);
        return acc;
    }, new Map<string, Conversation>());
    return Array.from(allMerged.values());
};

export const sortConversationDisplaySummaries = (
    conversations: ConversationDisplaySummary[],
    sortOptions: ConversationGroupSortOptions,
): ConversationDisplaySummary[] => {
    return orderBy(
        conversations,
        [
            sortOptions.sortOrder === "UrgentThenNewestFirst"
                ? (c) => c.isUrgent
                : (c) => false,
            (c) => sortConversationsBy(c, sortOptions),
            (c) => c.id,
        ],
        sortOrders(sortOptions),
    );
};

const sortOrders = (
    sortOptions: ConversationGroupSortOptions,
): ("asc" | "desc")[] => {
    switch (sortOptions.sortOrder) {
        case "SoonestFirst":
            return ["asc", "asc", "asc"];

        default:
            return ["desc", "desc", "desc"];
    }
};

const sortConversationsBy = (
    c: ConversationDisplaySummary,
    sortOptions: ConversationGroupSortOptions,
) => {
    switch (sortOptions.sortBy) {
        case "DisplayItemCreatedAt":
            return c.displayItem.createdAt;
        case "ScheduledSendDate":
            if (
                c.displayItem.contentType === "PatientSms" ||
                c.displayItem.contentType === "PatientEmail" ||
                c.displayItem.contentType === "NhsAppMessage"
            ) {
                return c.displayItem.deliveryScheduledAt;
            }
            break;
        default:
            return c.lastUpdated;
    }
};

/**
 * Merges in updates to a conversations list, with updates taking precedence.
 * This only does a shallow merge so updates inside the conversation items may be overwritten.
 *
 * @param existing The existing data we have for conversations, if any
 * @param updates Conversation updates, may contain one or more new conversations
 */
export const mergeShallowConversations = <T extends ConversationSummary>(
    existing: T[],
    updates: T[],
): T[] => {
    return uniqBy([...updates, ...existing], (conversation) => conversation.id);
};

/**
 * Merges in updates to a conversations list and ensures the most recent conversations are first.
 * This only does a shallow merge so updates inside the conversation items may be overwritten.
 *
 * @param existing The existing data we have for conversations, if any
 * @param updates Conversation updates, may contain one or more new conversations
 * @param sortOptions Sorting rules
 */
export const mergeShallowAndSortConversationDisplaySummaries = (
    existing: ConversationDisplaySummary[],
    updates: ConversationDisplaySummary[],
    sortOptions: ConversationGroupSortOptions,
): ConversationDisplaySummary[] => {
    return sortConversationDisplaySummaries(
        mergeShallowConversations(existing, updates),
        sortOptions,
    );
};

/**
 * Removes conversations from a list of existing conversations
 *
 * @param existing The original list of conversations
 * @param toRemove The conversations to be removed
 */
export const removeConversations = <T extends ConversationSummary>(
    existing: T[],
    toRemove: T[],
): T[] => {
    const ids = new Set(toRemove.map((conversation) => conversation.id));
    return existing.filter((conv) => !ids.has(conv.id));
};

/**
 * Recalculates the unread count for a conversation. Ignores unread for non-open conversations, but does not
 * yet take into account current group membership.
 *
 * @param currentUserId the id of the current user
 * @param conversation the latest summary state of the conversation, used to check status
 * @param items the current set of known items in the conversation
 * @return the count of notifiable unread items in the conversation
 */
export const calculateUnreadCount = (
    currentUserId: string,
    conversation: ConversationSummary,
    items: BaseConversationItem[],
): number => {
    if (conversation.status !== "Open") {
        return 0;
    }
    return items.reduce(
        (count, item) =>
            // TODO FOU-211: explicitly share with `isConversationItemUnread` while here
            item.readDataByUserId?.[currentUserId]?.readAt === null
                ? count + 1
                : count,
        0,
    );
};
