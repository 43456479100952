import { Button } from "@accurx/design";

import { StyledBackButtonWrapper } from "./BackButton.styles";

type BackButtonProps = {
    backCallback: () => void;
    backButtonDisabled?: boolean;
    /** For overriding styling if needed */
    className?: string;
};

export const BackButton = ({
    backCallback,
    backButtonDisabled,
    className,
}: BackButtonProps) => {
    return (
        <StyledBackButtonWrapper className={className}>
            <Button
                onClick={backCallback}
                text="Back"
                icon={{
                    name: "ArrowTail",
                    colour: "metal",
                    rotation: "left",
                    style: "Line",
                }}
                theme="transparent"
                disabled={backButtonDisabled}
                data-userflow-id="nav-back"
            />
        </StyledBackButtonWrapper>
    );
};
