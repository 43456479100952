import React from "react";

import { FeatureName } from "@accurx/auth";
import { useHistory } from "react-router-dom";

import { GPDetails } from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES } from "shared/Routes";
import { getMessagePracticeUrl } from "shared/RoutesHelper";
import {
    useAppSelector,
    useIsFeatureEnabledForOrganisationAndPatient,
} from "store/hooks";

import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export const GpDetails = ({
    practiceName,
    practiceAddress,
    practiceTelephone,
    usualGpName,
}: GPDetails): JSX.Element => {
    const isInEmbeddedMode = useAppSelector(({ app }) => app.isEmbedded);
    const account = useAppSelector(({ account }) => account);
    const sessionAnalytics = useAppSelector(
        ({ sessionAnalytics }) => sessionAnalytics,
    );

    const messagePracticeEnabled = useIsFeatureEnabledForOrganisationAndPatient(
        FeatureName.CaregiverInitiated,
    );
    const history = useHistory();

    const directToMessagePractice = () => {
        FlemingAnalyticsTracker.trackMessagePracticeAccessedFromAnotherProduct({
            otherProduct: "record-access",
            ...AnalyticsMapper.getMessagePracticeBaseAnalyticsProps(
                account,
                sessionAnalytics,
            ),
        });

        const selectedOrganisation =
            OrganisationHelper.getOrganisation(account);

        history.push(`${getMessagePracticeUrl(selectedOrganisation)}`, {
            referredFrom: ROUTES.record_view_medical_record,
            appOrigin: "MedicalRecords",
        });
    };

    return (
        <MedicalRecordPanelCard
            title="GP contact details"
            headerButton={
                messagePracticeEnabled && isInEmbeddedMode === false
                    ? {
                          text: "Message GP Practice",
                          onClick: directToMessagePractice,
                      }
                    : undefined
            }
        >
            <CardRowStack>
                <CardRow
                    type={CardRowType.Default}
                    label="Name"
                    title={usualGpName}
                    emphasise
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Practice name"
                    title={practiceName}
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Address"
                    title={practiceAddress}
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Phone"
                    title={practiceTelephone}
                />
            </CardRowStack>
        </MedicalRecordPanelCard>
    );
};
