import { ComponentProps, ReactNode } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { QuickViewPortal, useQuickViewContext } from "@accurx/quick-view";

type PatientMatchLayoutComponentProps = {
    isLoading?: boolean;
    buttonProps?: Omit<ComponentProps<typeof Pill.Button>, "children">;
    children?: ReactNode;
};

export const PatientMatchLayout = ({
    isLoading,
    buttonProps,
    children,
}: PatientMatchLayoutComponentProps): JSX.Element => {
    const { onClose } = useQuickViewContext();

    return (
        <>
            <QuickViewPortal.Header>
                <Pill.SecondaryButton dimension="small" onClick={onClose}>
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>{" "}
            </QuickViewPortal.Header>
            <QuickViewPortal.Body>{children}</QuickViewPortal.Body>
            <QuickViewPortal.Footer>
                <UI.Flex justifyContent={"flex-end"}>
                    <Pill.PrimaryButton
                        type="submit"
                        disabled={isLoading}
                        dimension="small"
                        {...buttonProps}
                    >
                        <Pill.Icon
                            name="Search"
                            colour="white"
                            size={3}
                            isLoading={isLoading}
                        />
                        <Pill.Text>Search</Pill.Text>
                    </Pill.PrimaryButton>
                </UI.Flex>
            </QuickViewPortal.Footer>
        </>
    );
};
