import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export const mutateSignalRClientOn = createEndpoint<
    unknown,
    { methodName: string }
>(
    "MutateSignalRClientOn",
    async (context, { methodName }) =>
        await NativeApi.request({
            transport: context.transport,
            request: {
                type: "MutateSignalRClientOn",
                workspaceId: context.workspaceId,
                payload: {
                    methodName,
                },
            },
        }),
);
