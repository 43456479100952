import React, { ChangeEvent, useLayoutEffect, useRef } from "react";

import { Input, StackPanel, Text } from "@accurx/design";
import TextareaAutosize from "react-autosize-textarea/lib";

import { MessageTemplateType } from "api/FlemingDtos";
import { useAppSelector } from "store/hooks";

import {
    StyledBodyFieldContainer,
    StyledFormFieldWrapper,
} from "./CreateMessageTemplateForm.styles";

export type CreateMessageTemplateFormProps = {
    title: string;
    titleError: string;
    onTitleChange: (value: string) => void;
    body: string;
    bodyError: string;
    onBodyChange: (value: string) => void;
    templateType: MessageTemplateType | null;
    orgName: string;
};

export const CreateMessageTemplateForm = ({
    title,
    titleError,
    onTitleChange,
    body,
    bodyError,
    onBodyChange,
    templateType,
    orgName,
}: CreateMessageTemplateFormProps) => {
    const titleInputRef = useRef<HTMLInputElement>(null);
    const userFullName = useAppSelector(
        ({ account }) => account.user?.fullName,
    );

    useLayoutEffect(() => {
        titleInputRef.current?.focus();
    }, []);

    return (
        <>
            <StyledFormFieldWrapper
                errors={[titleError]}
                label="Title"
                labelProps={{
                    htmlFor: "template-title",
                }}
            >
                <Input
                    ref={titleInputRef}
                    id="template-title"
                    type="text"
                    value={title}
                    placeholder={"e.g. Referral template"}
                    onChange={(e) => onTitleChange(e.target.value)}
                />
            </StyledFormFieldWrapper>
            <StyledFormFieldWrapper
                errors={[bodyError]}
                label="Body"
                labelProps={{
                    htmlFor: "template-body",
                }}
            >
                <StyledBodyFieldContainer>
                    <Text skinny>Dear [patient],</Text>
                    <TextareaAutosize
                        id="template-body"
                        className="form-control"
                        placeholder="Add content of your template..."
                        rows={6}
                        value={body}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            onBodyChange(e.target.value)
                        }
                    ></TextareaAutosize>
                    <StackPanel orientation="vertical" gutter={1}>
                        <Text skinny>
                            Thanks,
                            <br />
                            {userFullName}
                        </Text>
                        {templateType === MessageTemplateType.VideoConsult && (
                            <Text skinny>
                                To join, please follow this link: [link will
                                appear here]
                            </Text>
                        )}
                        <Text skinny>{orgName}</Text>
                    </StackPanel>
                </StyledBodyFieldContainer>
            </StyledFormFieldWrapper>
        </>
    );
};
