import { Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: ${Tokens.SIZES[2]};
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    > span:not(.new-badge) {
        text-decoration: underline;
        color: ${Tokens.COLOURS.primary.blue[100]};
    }

    &:hover {
        transition: background-color 0.5s;
        background-color: ${Tokens.COLOURS.primary.blue[10]};
        text-decoration: none;

        > span:not(.new-badge) {
            transition: color 0.5s;
            color: ${Tokens.COLOURS.primary.blue[100]};
        }
    }

    &:active {
        transition: background-color 0.5s;
        background-color: ${Tokens.COLOURS.primary.blue[25]};
        outline: none;
        box-shadow: none;

        > span:not(.new-badge) {
            transition: color 0.25s;
            color: ${Tokens.COLOURS.primary.blue[130]};
        }
    }
`;
