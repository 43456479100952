/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useMemo } from "react";

import { Text } from "@accurx/design";

import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useAppSelector } from "store/hooks";

export const BatchSnomedCode = () => {
    // Store
    const selectedFloreySnomedCode = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreySnomedCode,
    );
    const selectedTemplateSnomedCode = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedTemplateSnomedCode,
    );
    const floreySnomedCodes = useAppSelector(
        ({ batchMessage }) => batchMessage.floreySnomedCodes,
    );
    const smsSnomedCodes = useAppSelector(
        ({ batchMessage }) => batchMessage.snomedCodes,
    );
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );

    const selectedSnomedCode = useMemo(() => {
        if (selectedFloreySnomedCode !== "") {
            return selectedFloreySnomedCode;
        }
        if (selectedTemplateSnomedCode !== "") {
            return selectedTemplateSnomedCode;
        }
        return null;
    }, [selectedFloreySnomedCode, selectedTemplateSnomedCode]);
    const snomedCodes = useMemo(() => {
        if (floreySnomedCodes.length > 0) {
            return floreySnomedCodes;
        }

        return smsSnomedCodes;
    }, []);
    const snomedCodeMatch = useMemo(
        () => snomedCodes.find((code) => code.conceptId === selectedSnomedCode),
        [snomedCodes, selectedSnomedCode],
    );

    if (selectedSnomedCode && snomedCodes.length > 0 && snomedCodeMatch) {
        return (
            <>
                <Text skinny>{snomedCodeMatch.term}</Text>
                <Text colour="metal">
                    (This will be saved to record once the message is sent.)
                </Text>
            </>
        );
    }

    if (batchType === BatchType.FLOREY) {
        return (
            <>
                <Text>No SNOMED code for the initial Florey invite.</Text>
                <Text colour="metal">
                    (You will still get SNOMED codes for the patient's
                    responses.)
                </Text>
            </>
        );
    }

    return <Text colour="metal">No SNOMED code</Text>;
};
