import { Table, Tokens } from "@accurx/design";
import styled from "styled-components";

const Header = styled(Table.Header)`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

const SortableHeader = styled(Table.Header)`
    padding: ${Tokens.SIZES[0.5]};
`;

const Cell = styled(Table.DataCell)`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

const PatientCell = styled(Cell)`
    width: 100%;
    max-width: 350px;
`;

const NoWrapCell = styled(Cell)`
    white-space: nowrap;
`;

const SortButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px ${Tokens.COLOURS.greyscale.white};
    background-color: transparent;
    width: 100%;

    // Smaller padding than non-sortable columns because it needs space for focus state
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};

    &:focus {
        border-color: ${Tokens.COLOURS.greyscale.zinc};
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.blue[10]};
    }

    &:hover:not(:focus) {
        border-color: ${Tokens.COLOURS.primary.blue[10]};
    }
`;

export const StyledTable = {
    PatientCell,
    NoWrapCell,
    Header,
    SortableHeader,
    SortButton,
    Cell,
};
