import { ArchiveResponse } from "@accurx/api/clinician-messaging";
import { mapConversationCommandResponseToConciergeUpdate } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationCommandResponseToConciergeUpdate";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

type MarkAsDoneOptions = {
    rowVersionBase64: string;
    workspaceId: string;
    workspaceConversationId: string;
};

export const markAsDone: ConciergeFetcher<
    MarkAsDoneOptions,
    ArchiveResponse
> = async (arg) => {
    const response = await ClinicianMessagingApiClient.archive(arg);

    const updates = mapConversationCommandResponseToConciergeUpdate(response);

    return { updates, response };
};
