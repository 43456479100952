import { useState } from "react";

import { Question, QuestionnaireResponse } from "@accurx/api/florey-builder";
import { Button, Feedback, Link, Spinner, Text } from "@accurx/design";
import Dropzone from "react-dropzone";
import { useParams } from "react-router";
import styled from "styled-components";

import {
    useCreateBranchMutation,
    useCreateQuestionMutation,
    useCreateQuestionnaireMutation,
    useCreateTemplatedQuestionMutation,
    useEditQuestionnaireMutation,
} from "app/hooks/mutations";
import "app/hooks/mutations/useCreateBranchMutation";
import "app/hooks/mutations/useCreateTemplatedQuestionMutation";

import { routeNameAndQuestions } from "../constants/paths";
import { questionnaireResponseSchema } from "./schemas";

const StyledFileUpload = styled.div`
    border: dotted 2px black;
    height: 200px;
    width: 100%;
`;

export const FloreyBuilderImportAsJson = () => {
    const { orgId } = useParams<{ orgId: string }>();
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [questionnaireData, setQuestionnaireData] =
        useState<QuestionnaireResponse>();
    const [importedQuestionnaireId, setImportedQuestionnaireId] =
        useState<number>();

    const createQuestionnaire = useCreateQuestionnaireMutation();
    const editQuestionnaire = useEditQuestionnaireMutation();
    const createQuestion = useCreateQuestionMutation();
    const createTemplatedQuestion = useCreateTemplatedQuestionMutation();
    const createBranch = useCreateBranchMutation();

    const onFileUploaded = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const result = e.target?.result;
            if (typeof result === "string") {
                const data = JSON.parse(result);
                const validated = questionnaireResponseSchema.safeParse(data);
                if (!validated.success) {
                    setError(
                        "The processed file is incorrect. Please re-export the questionnaire",
                    );
                    return;
                }
                setQuestionnaireData(validated.data);
                return;
            }

            setError(
                "unable to parse file. please ensure you have uploaded a JSON file",
            );
        };
        reader.readAsText(file);
    };

    const handleImport = async () => {
        if (!questionnaireData) return;
        setIsLoading(true);
        const response = await createQuestionnaire.mutateAsync({
            organisationId: parseInt(orgId, 10),
            name: questionnaireData.name ?? "",
        });

        await editQuestionnaire.mutateAsync({
            ...questionnaireData,
            questionnaireRowVersion: response.questionnaireRowVersion,
            organisationId: parseInt(orgId, 10),
            questionnaireId: response.id,
            enrolmentSnomedCode:
                questionnaireData.enrolmentSnomedCode?.conceptId,
            completionSnomedCode:
                questionnaireData.completionSnomedCode?.conceptId,
        });

        questionnaireData.questions.sort((a, b) =>
            (a.order ?? 0) > (b.order ?? 0) ? 1 : -1,
        );
        const questionIdMap: Record<number, number> = {};

        const createQuestionFn = async (
            questionData: Question,
            branchId?: number,
        ) => {
            return typeof questionData.questionTemplateType === "number"
                ? await createTemplatedQuestion.mutateAsync({
                      organisationId: parseInt(orgId, 10),
                      questionnaireId: response.id,
                      questionTemplateType: questionData.questionTemplateType,
                      branchId: branchId,
                  })
                : await createQuestion.mutateAsync({
                      ...questionData,
                      branchId: branchId,
                      organisationId: parseInt(orgId, 10),
                      questionnaireId: response.id,
                      questionTitle: questionData.questionTitle ?? "",
                      snomedCode: questionData.snomedCode?.conceptId,
                      options: questionData.options?.map((o) => ({
                          ...o,
                          answerCode: o.answerCode?.conceptId ?? "",
                      })),
                  });
        };

        for (let i = 0; i < questionnaireData.questions.length; i++) {
            const questionData = questionnaireData.questions[i];

            if (questionData.branchData) {
                const branchResponse = await createBranch.mutateAsync({
                    organisationId: parseInt(orgId, 10),
                    questionnaireId: response.id,
                    conditionOperator:
                        questionData.branchData.condition.conditionOperator,
                    conditionQuestionId:
                        questionIdMap[
                            questionData.branchData.condition.conditionQuestion
                                .questionId
                        ],
                    conditionValue:
                        questionData.branchData.condition.conditionQuestion
                            .answerText,
                });

                const branchId =
                    branchResponse.questions[
                        branchResponse.questions.length - 1
                    ].id;

                for (
                    let j = 0;
                    j < questionData.branchData.branchQuestions.length;
                    j++
                ) {
                    const branchQuestionData =
                        questionData.branchData.branchQuestions[j];
                    await createQuestionFn(branchQuestionData, branchId);
                }
                continue;
            }
            if (!questionData.branchData) {
                const questionResponse = await createQuestionFn(questionData);
                questionIdMap[questionData.id] =
                    questionResponse.questions[
                        questionResponse.questions.length - 1
                    ].id;
                continue;
            }
        }
        setIsLoading(false);
        setImportedQuestionnaireId(response.id);
    };

    return (
        <>
            {isLoading && <Spinner />}
            {questionnaireData && !importedQuestionnaireId && !isLoading && (
                <div>
                    <Button onClick={handleImport} text="Begin Import" />
                </div>
            )}
            {!questionnaireData && !isLoading && (
                <>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            if (acceptedFiles.length > 0) {
                                onFileUploaded(acceptedFiles[0]);
                            }
                        }}
                        accept="application/json"
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <StyledFileUpload>
                                    <label
                                        htmlFor="inputFile"
                                        className="sr-only"
                                    >
                                        Input file
                                    </label>
                                    <input
                                        id="inputFile"
                                        {...getInputProps()}
                                    />
                                    <Text>
                                        Upload exported questionnaire file here
                                    </Text>
                                </StyledFileUpload>
                            </div>
                        )}
                    </Dropzone>
                    {error && (
                        <Feedback
                            colour="error"
                            title="Error importing questionnaire"
                        >
                            {error}
                            <Link href="#" onClick={window.location.reload}>
                                Click here to try again
                            </Link>
                        </Feedback>
                    )}
                </>
            )}
            {importedQuestionnaireId && !isLoading && (
                <Feedback colour="success" title="Questionnaire imported">
                    <Link
                        href={routeNameAndQuestions(
                            orgId,
                            importedQuestionnaireId.toString(),
                        )}
                    >
                        Click here to view the questionnaire
                    </Link>
                </Feedback>
            )}
        </>
    );
};
