import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    ClinicAppointment,
    IntegratedClinicWithAppointments,
    getIntegratedClinic,
} from "api/Appointment/IntegratedClinics";

import { getLocationWithPlaceholder } from "../../patientLists/integratedClinics/shared/location";

type IndividualClinicParameters = {
    workspaceId: string;
    clinicCode: string;
    clinicDay: string;
    location: string;
};

function filterAppointmentOnLocation(
    appointments: ClinicAppointment[],
    location: string,
): ClinicAppointment[] {
    return appointments.filter((x) => {
        return getLocationWithPlaceholder(x.location) === location;
    });
}

export const useIntegratedClinic = createQueryHook<
    IndividualClinicParameters,
    IntegratedClinicWithAppointments
>(
    ({
        workspaceId,
        clinicCode,
        clinicDay,
        location,
    }: IndividualClinicParameters) => ({
        queryKey: [
            "IntegratedClinic",
            { workspaceId, clinicCode, clinicDay, location },
        ],
        queryFn: async () => {
            const response = await getIntegratedClinic({
                workspaceId,
                clinicCode,
                clinicDay,
            });

            const result = returnDataOrThrow(response);

            const appointmentFilteredOnLocation = filterAppointmentOnLocation(
                result.appointments,
                location,
            );

            return {
                ...result,
                appointments: appointmentFilteredOnLocation,
            };
        },
    }),
);
