import { useTeamsByType } from "@accurx/concierge/hooks/data/useTeamsByType";
import { useFuzzyFilter } from "@accurx/hooks";
import { FocusRingSpacing } from "@accurx/navigation";
import { FlexList } from "domains/inbox/components/Sidebar/components/FlexList";
import { FolderLink } from "domains/inbox/components/Sidebar/components/FolderLink";
import { NoResults } from "domains/inbox/components/Sidebar/components/NoResults";
import { useInboxLink } from "domains/inbox/hooks/util";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import { ROUTES_INBOX } from "domains/inbox/routes";
import sortBy from "lodash/sortBy";
import { useRouteMatch } from "react-router-dom";

type TeamsListProps = {
    searchTerm?: string;
};

const useActiveTeamId = () => {
    const match = useRouteMatch<{ teamId: string }>(ROUTES_INBOX.Shared);
    return match?.params.teamId;
};

export const SharedTeamsList = (props: TeamsListProps) => {
    const teams = useTeamsByType({ type: "Hub" });
    const link = useInboxLink();
    const activeTeamId = useActiveTeamId();
    const trackFolderClick = useSidebarFolderClickAnalytics();

    const searchResults = useFuzzyFilter(
        sortBy(teams, (t) => t.displayName.toLowerCase()),
        props.searchTerm ?? "",
        {
            keys: ["displayName"],
            threshold: 0.4,
        },
    );

    return (
        <div>
            {!!searchResults.length && (
                <FocusRingSpacing>
                    <FlexList gap="0.5" aria-label="Shared teams">
                        {searchResults.map((team) => (
                            <li
                                key={team.id}
                                data-autoscroll={
                                    team.id === activeTeamId
                                        ? "true"
                                        : undefined
                                }
                            >
                                <FolderLink
                                    text={team.displayName}
                                    to={link.to("Shared", {
                                        teamId: team.id,
                                    })}
                                    isActive={team.id === activeTeamId}
                                    onClick={() =>
                                        trackFolderClick({
                                            folderName: "Shared",
                                            teamId: team.id,
                                            teamName: team.displayName,
                                            conversationParticipant:
                                                "WithPatient",
                                        })
                                    }
                                />
                            </li>
                        ))}
                    </FlexList>
                </FocusRingSpacing>
            )}
            {!searchResults.length && (
                <FocusRingSpacing>
                    <NoResults aria-live="assertive">No teams found</NoResults>
                </FocusRingSpacing>
            )}
        </div>
    );
};
