import React, { ReactNode } from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledFooter = styled.footer`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

export const Footer = ({ children }: { children: ReactNode }) => {
    return <StyledFooter>{children}</StyledFooter>;
};
