import { useCurrentUser } from "@accurx/auth";
import { Button, Feedback, Flex, Text } from "@accurx/design";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import {
    ACCOUNT_LOGGED_IN,
    ACCOUNT_LOGGED_OUT,
} from "app/account/AccountActions";
import { useIsClinicianConversationOnboarding } from "app/account/Organisation/hooks";
import { OnboardingCard } from "app/account/Organisation/layout-components";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    useAcceptWorkspaceInvitationMutation,
    useRemoveCurrentInvite,
} from "app/hooks/mutations/useAcceptWorkspaceInvitationMutation";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES } from "shared/Routes";
import patientTokenService from "shared/storage/PatientTokenService";

type WorkspaceInvitationProps = {
    workspaceId: number;
    workspaceName: string;
    organisationNodeName: string;
    invitedByUser: string;
    dismissible?: boolean;
};

const WorkspaceInvitation = ({
    workspaceId,
    workspaceName,
    organisationNodeName,
    invitedByUser,
    dismissible = false,
}: WorkspaceInvitationProps): JSX.Element => {
    const history = useHistory();
    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const dispatch = useDispatch();
    const { refetch } = useCurrentUser();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();
    const acceptWorkspaceInvitation = useAcceptWorkspaceInvitationMutation();
    const { setSelectedOrgId } = useSelectedOrganisation();

    const removeCurrentInvite = useRemoveCurrentInvite();

    const acceptWorkspaceInvitationButtonClick = () => {
        acceptWorkspaceInvitation.mutate(
            { workspaceId },
            {
                onSuccess: async (data) => {
                    // Refetch the entire user object to ensure the default
                    // organisation is available if they have been invited to a
                    // non-default workspace
                    const currentUser = await refetch();

                    if (!currentUser.data?.isLoggedIn) {
                        // clear patient from storage as we're not logged in
                        if (patientTokenService !== null) {
                            patientTokenService.clearCurrentPatientToken();
                        }
                        dispatch({
                            type: ACCOUNT_LOGGED_OUT,
                        });

                        return;
                    }
                    removeCurrentInvite(workspaceId);

                    dispatch({
                        type: ACCOUNT_LOGGED_IN,
                        user: currentUser.data,
                        is2FAed: currentUser.data.is2FAed,
                    });

                    setSelectedOrgId(data.orgId);

                    toast(
                        Feedback({
                            colour: "success",
                            title: `You have been added to ${workspaceName}`,
                        }),
                    );

                    history.push(ROUTES.home);
                },
                onError: () => {
                    toast(
                        Feedback({
                            colour: "error",
                            title: "There was a problem trying to join the workspace. Please try again.",
                        }),
                    );
                },
                onSettled: (_data, error) => {
                    FlemingAnalyticsTracker.trackWorkspaceInviteAccept({
                        ...analyticsLoggedInProps,
                        ...meaningfulActionProps,
                        workspaceOrganisationId: workspaceId,
                        hasError: error !== null,
                    });
                },
            },
        );
    };

    const onDismissButtonClick = () => {
        FlemingAnalyticsTracker.trackWorkspaceInviteDismiss({
            ...analyticsLoggedInProps,
            workspaceOrganisationId: workspaceId,
        });
        removeCurrentInvite(workspaceId);
    };

    return (
        <OnboardingCard
            header={
                isBlurryChatLayout
                    ? "You've been invited to join your colleagues"
                    : undefined
            }
        >
            <Flex flexDirection="column" gap="3">
                <Flex flexDirection="column" gap="2">
                    <Text skinny>
                        <strong>{workspaceName}</strong>
                        {` at ${organisationNodeName}`}
                    </Text>
                    <Text
                        skinny
                    >{`You were invited by ${invitedByUser}.`}</Text>
                </Flex>
                <Flex justifyContent="flex-end" gap="2">
                    {dismissible && (
                        <Button
                            text="Dismiss"
                            theme="secondary"
                            onClick={onDismissButtonClick}
                            disabled={
                                acceptWorkspaceInvitation.isLoading ||
                                acceptWorkspaceInvitation.isSuccess
                            }
                        />
                    )}
                    <Button
                        text="Join"
                        theme="primary"
                        onClick={acceptWorkspaceInvitationButtonClick}
                        disabled={
                            acceptWorkspaceInvitation.isLoading ||
                            acceptWorkspaceInvitation.isSuccess
                        }
                    />
                </Flex>
            </Flex>
        </OnboardingCard>
    );
};

export { WorkspaceInvitation };
