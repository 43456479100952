import React, { useCallback, useMemo } from "react";

import { FeatureName } from "@accurx/auth";
import { Accordion, Icon, Spinner, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import NotFound from "NotFound";

import { FlemingAnalyticsTracker } from "app/analytics";
import { mapConversationGroupRulesetToAnalyticsConversationGroup } from "app/analytics/ConversationsAnalyticsMapper";
import { StyledContainer } from "app/layout/Container";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    getAssigneeFromRuleset,
    getSentByUserIdFromRuleset,
    getStatusFromRuleset,
    isContainsItemRule,
} from "shared/concierge/conversations/ConversationGroup.utils";
import { useConversationManager } from "shared/concierge/conversations/context/ConversationManagerContext";
import { useCurrentUserId } from "shared/concierge/conversations/hooks";
import { useConversationGroup } from "shared/concierge/conversations/hooks/useConversationGroup";
import { useConversationGroupRuleset } from "shared/concierge/conversations/hooks/useConversationGroupRuleset";
import {
    ConversationGroupPaginationOptions,
    ConversationGroupRuleset,
} from "shared/concierge/conversations/types/conversationGroup.types";
import {
    useSingleTeamQuery,
    useSingleUserQuery,
} from "shared/concierge/usersAndTeams/hooks";
import {
    TeamSummary,
    UserSummary,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import { useIsFeatureEnabled } from "store/hooks";

import { ConversationGroup } from "../components/ConversationGroup";
import { ConversationListItemAnalyticsClickCallback } from "../components/ConversationGroup/ConversationListItem";
import { PaginationNavigation } from "../components/ConversationGroupPage";
import { ConversationGroupPageError } from "../components/ConversationGroupPage/ConversationGroupPageError";
import { ConversationGroupPageLoading } from "../components/ConversationGroupPage/ConversationGroupPageLoading";
import { ConversationGroupStatusSwitcher } from "../components/ConversationGroupStatusSwitcher";
import {
    StyledBottomNavigationContainer,
    StyledBottomNavigationItem,
    StyledFilterBarContainer,
    StyledFilterBarItemContainer,
    StyledHeaderContainer,
} from "./ConversationGroupPage.styles";

const pageSize = 24;

type ConversationGroupDisplayOptions = {
    /* Switch between open and done */
    showStatusSwitcher: boolean;

    /*
     * If this an assigned ruleset we use this to only show the "from patient" etc indicator.
     * Specifically this includes your personal and other users open/done views, but does
     * not include the sent views or the all view - where the assignee indicator will be used
     * in preference.
     */
    showLastItemIndicator: boolean;

    /*
     * For the sent view we're wanting to order by and show the item time rather than the
     * last modification time of the containing conversation.
     */
    showDisplayItemDate: boolean;

    paginationOptions: ConversationGroupPaginationOptions;
    /* The start of the text that will be in the page header - it will be combined with the user/team display name */
    headerTextIntro: string;
    /* If the ruleset filters on a user, then the id of that user */
    userId: string | undefined;
    /* If the ruleset filters on a team, then the id of that team */
    teamId: string | undefined;
};

/**
 * Choose slightly different display options based on what we're showing - if we have
 * a group that's showing data based on specific item inclusion (right now "sent")
 * then we don't currently want to filter based on status and (very soon) will also
 * want to summarize a conversation based on the matched item, not its most recent.
 *
 * While this feels like it could be split out into a different display component
 * (ConversationItemGroup vs ConversationGroup), it's not yet clear that it is worth
 * doing vs inferring a couple of settings from the rules.
 *
 * @param ruleset The inclusion rules for matching conversations into the group.
 */
const mapRulesetToDisplayOptions = (
    ruleset: ConversationGroupRuleset | null,
): ConversationGroupDisplayOptions => {
    const hasContainsItemRule =
        !!ruleset && ruleset.rules.some(isContainsItemRule);

    const assignee = ruleset ? getAssigneeFromRuleset(ruleset) : undefined;
    const hasAssignmentRule = assignee !== undefined;

    let userId: string | undefined = undefined;
    let teamId: string | undefined = undefined;
    let headerTextIntro = "Conversations";
    if (assignee && assignee.id) {
        switch (assignee.type) {
            case "User":
                userId = assignee.id;
                break;
            case "Team":
                teamId = assignee.id;
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustive: never = assignee.type;
        }
        headerTextIntro = "Assigned to ";
    } else {
        const sentByUserId = ruleset
            ? getSentByUserIdFromRuleset(ruleset)
            : undefined;
        if (sentByUserId) {
            headerTextIntro = "Sent by ";
            userId = sentByUserId;
        }
    }
    return {
        showStatusSwitcher: !!ruleset && !hasContainsItemRule,
        // If this an assigned ruleset we use this to only show the "from patient" etc indicator.
        // Specifically this includes your personal and other users open/done views, but does
        // not include the sent views or the all view - where the assignee indicator will be used
        // in preference
        showLastItemIndicator: hasAssignmentRule,
        showDisplayItemDate: hasContainsItemRule,
        paginationOptions: {
            pageSize,
            sortBy: hasContainsItemRule
                ? "DisplayItemCreatedAt"
                : "ConversationLastUpdated",
            sortOrder: "NewestFirst",
        },
        headerTextIntro,
        userId,
        teamId,
    };
};

export const ConversationGroupPage = (): JSX.Element | null => {
    const ruleset = useConversationGroupRuleset();
    const currentUserId = useCurrentUserId();

    const {
        showStatusSwitcher,
        showLastItemIndicator,
        showDisplayItemDate,
        paginationOptions,
        headerTextIntro,
        userId,
        teamId,
    } = useMemo(() => mapRulesetToDisplayOptions(ruleset), [ruleset]);

    // Get valid user and teams that the current user can access to check
    // against any users/teams from the URL so that we don't make invalid
    // calls to the API
    const userQuery = useSingleUserQuery(userId);
    const teamQuery = useSingleTeamQuery(teamId);

    const isValidUserId =
        userId !== undefined &&
        userQuery.status === "success" &&
        userQuery.data !== undefined;
    const isValidTeamId =
        teamId !== undefined &&
        teamQuery.status === "success" &&
        teamQuery.data !== undefined;

    // If there is a user or team, we are only going to get the conversation group
    // and load the page if they are valid.
    const areUsersAndTeamsValid =
        (userId === undefined || isValidUserId) &&
        (teamId === undefined || isValidTeamId);
    const validatedRuleset = areUsersAndTeamsValid ? ruleset : null;

    const manager = useConversationManager();
    const {
        data,
        status,
        backgroundStatus,
        isRulesetValid,
        paginationActions,
        retryFetch,
    } = useConversationGroup(manager, validatedRuleset, paginationOptions);

    const isAutoMarkAsDoneEnabled = useIsFeatureEnabled(
        FeatureName.AutoMarkAsDone,
    );

    /* Analytics */
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const sendConversationListItemAnalytics =
        useCallback<ConversationListItemAnalyticsClickCallback>(
            (eventProps) => {
                if (ruleset) {
                    const conversationGroup =
                        mapConversationGroupRulesetToAnalyticsConversationGroup(
                            ruleset,
                            currentUserId,
                        );

                    FlemingAnalyticsTracker.trackPatientConversationListitemClicked(
                        {
                            ...analyticsLoggedInProps,
                            conversationGroup,
                            ...eventProps,
                        },
                    );
                }
            },
            [ruleset, currentUserId, analyticsLoggedInProps],
        );

    /* Analytics */

    // To check if a user or team from the ruleset is valid we need to wait
    // for the user/team to be loaded, display a spinner until that point
    if (
        (userId && userQuery.status === "loading") ||
        (teamId && teamQuery.status === "loading")
    ) {
        return <Spinner />;
    }

    if (!areUsersAndTeamsValid) {
        // We have loaded users and teams, but
        // We want to return not found if user or team id from the URL is not a user or team id
        // the current user is allowed to see.
        return <NotFound />;
    }

    /**
     * This page component should only ever get rendered on a URL that
     * links to a valid conversation group ruleset so if we have no ruleset
     * something has gone wrong. In that case we'll log an error and display
     * nothing.
     */
    if (!isRulesetValid) {
        Log.error("A conversation group page was displayed at an invalid URL");
        return null;
    }

    const assigneeName = getAssigneeName(
        userQuery.data,
        teamQuery.data,
        currentUserId,
    );

    const accordionName = getAccordionName(
        userQuery.data,
        teamQuery.data,
        currentUserId,
    );

    const fullHeaderText = headerTextIntro + assigneeName;

    // Deliberate design decision not to display data if any form (main or background) loading or errors are happening
    // even if we have data to display
    const displayData =
        data &&
        status === "SUCCESS" &&
        (backgroundStatus === "SUCCESS" || backgroundStatus === undefined);

    // We won't allow users to go to the next page if the current page hasn't loaded
    // all the data it needs
    const hasLoadedAllDataRequired =
        status === "SUCCESS" &&
        (backgroundStatus === "SUCCESS" || backgroundStatus === undefined);

    return (
        <>
            <StyledHeaderContainer>
                <Text as="h1" variant="subtitle" skinny>
                    {fullHeaderText}
                </Text>
            </StyledHeaderContainer>
            <StyledContainer>
                <Accordion
                    header={
                        <Text variant="label" skinny>
                            <Icon
                                name="Info"
                                size={3}
                                halo={{ colour: "orange" }}
                            />
                            What will appear here?
                        </Text>
                    }
                >
                    {getAccordionContent({
                        ruleset,
                        displayName: accordionName,
                        isAutoMarkAsDoneEnabled,
                    })}
                </Accordion>
                <StyledFilterBarContainer>
                    <StyledFilterBarItemContainer>
                        {showStatusSwitcher && (
                            <ConversationGroupStatusSwitcher />
                        )}
                    </StyledFilterBarItemContainer>
                    <StyledFilterBarItemContainer>
                        <PaginationNavigation
                            paginationActions={paginationActions}
                            hasLoadedAllDataRequired={hasLoadedAllDataRequired}
                            uniqueId="top"
                        />
                    </StyledFilterBarItemContainer>
                </StyledFilterBarContainer>
                {displayData && (
                    <ConversationGroup
                        conversations={data}
                        showDisplayItemDate={showDisplayItemDate}
                        showLastItemIndicator={showLastItemIndicator}
                        conversationListItemAnalytics={
                            sendConversationListItemAnalytics
                        }
                    />
                )}
                {/* Show an error if initial status is error, or we have a background fetch error and the page is stale */}
                {(status === "ERROR" || backgroundStatus === "ERROR") && (
                    <ConversationGroupPageError
                        errorMessage="There was a problem loading your conversations"
                        retryFetch={retryFetch}
                    />
                )}
                {/* Show a loading state if initial status is loading, or we have background loading occurring and the page is stale */}
                {(status === "LOADING" || backgroundStatus === "LOADING") && (
                    <ConversationGroupPageLoading />
                )}
                <StyledBottomNavigationContainer>
                    <StyledBottomNavigationItem>
                        <PaginationNavigation
                            paginationActions={paginationActions}
                            hasLoadedAllDataRequired={hasLoadedAllDataRequired}
                            uniqueId="bottom"
                        />
                    </StyledBottomNavigationItem>
                </StyledBottomNavigationContainer>
            </StyledContainer>
        </>
    );
};

const getAssigneeName = (
    user: UserSummary | undefined,
    team: TeamSummary | undefined,
    currentUserId: string,
): string => {
    if (user && user.id === currentUserId) {
        return `${user.displayName} (you)`;
    }

    if (user) {
        return user.displayName;
    }

    if (team) {
        return team.displayName;
    }

    return "";
};

const getAccordionName = (
    user: UserSummary | undefined,
    team: TeamSummary | undefined,
    currentUserId: string,
): string => {
    if (user && user.id === currentUserId) {
        return "you";
    }

    if (user) {
        return user.displayName;
    }

    if (team) {
        return team.displayName;
    }

    return "";
};

const getAccordionContent = ({
    ruleset,
    displayName,
    isAutoMarkAsDoneEnabled,
}: {
    ruleset: ConversationGroupRuleset | null;
    displayName: string;
    isAutoMarkAsDoneEnabled: boolean;
}) => {
    const assignee = ruleset ? getAssigneeFromRuleset(ruleset) : undefined;
    const status = ruleset ? getStatusFromRuleset(ruleset) : undefined;

    if (assignee && status) {
        return (
            <>
                <ul>
                    <li>
                        <Text skinny>
                            {status} conversations with patients that are
                            assigned to {displayName}
                        </Text>
                        {isAutoMarkAsDoneEnabled && (
                            <ul>
                                <li>
                                    <Text skinny>
                                        Please note: conversations that have no
                                        new messages for 30 days are
                                        automatically moved to Done
                                    </Text>
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
                <Text skinny variant="label">
                    This view will NOT include:{" "}
                </Text>
                <ul>
                    <li>
                        <Text skinny>
                            {status === "Done" ? "Open" : "Done"} conversations
                            with patients that are assigned to {displayName}
                        </Text>
                        <ul>
                            <li>
                                <Text skinny>
                                    These messages can be found by selecting the
                                    '{status === "Done" ? "Open" : "Done"}'
                                    filter
                                </Text>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Text skinny>
                            Video consult invitations sent by you
                        </Text>
                        <ul>
                            <li>
                                <Text skinny>
                                    These messages can be found in 'Sent by'
                                </Text>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Text skinny>Messages you've sent to GP Practices</Text>
                        <ul>
                            <li>
                                <Text skinny>
                                    These messages can be found on the patient
                                    profile of the patient subject
                                </Text>
                            </li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }

    return (
        <ul>
            <li>
                <Text skinny>Messages sent to patients by {displayName}</Text>
            </li>
            <li>
                <Text skinny>
                    Video consult invitations sent by {displayName}
                </Text>
            </li>
        </ul>
    );
};
