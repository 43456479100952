import { trimStart } from "./templates.format";

const outOfStock = trimStart(
    `We have received prescription(s) with item(s) that are currently out of stock and unavailable. We do not expect them to be available before they are needed by the patient, so please could you prescribe alternative(s).
    
    Out of stock item: 
    Number of days supply patient has left: 
    Prescription date: 
    Electronic transfer of prescription (ETP) barcode: 
    Available alternative(s): 
    
    I have marked item as not dispensed / returned script to spine for item cancellation *****DELETE AS APPROPRIATE*****
    
    Pharmacy name & location: 
    Contact number: 
    `,
);

const bpCheck = trimStart(
    `I have just consulted with your patient at our NHS Community Pharmacy Blood Pressure Check Service.
    
    Consultation summary: NHS Hypertension Case-Finding Service
    Attached: copy of NHS Hypertension Case-Finding Service Consultation Form
    
    Pharmacy name & location: 
    Actions required: 
    `,
);

const dosetteBoxQuery = trimStart(
    `We have received prescription(s) for this patient which are normally packed into dossette boxes. 

    However our records show that regular repeat medication(s) are missing:
     *****MEDICATION, DOSE*****
    
    Actions required by practice:
    *****COMPLETE OR DELETE AS APPROPRIATE*****
    Please can you confirm if the above medication(s) have been stopped?
    Please can you issue a prescription with the missing medication(s) so they can be included in the dossette box.
    
    Pharmacy name & location: 
    Contact number: 
    `,
);

const controlledDrugQuery = trimStart(
    `
    We have received prescription(s) with the following controlled drug(s):
    *****MEDICATION, DOSE, DURATION*****
    
    However the prescription does not comply with controlled drug regulations because *****INSERT REASON*****
    
    Actions required by practice:
    *****REQUESTED ACTION*****
    
    Pharmacy name & location: 
    Contact number: 
    `,
);

const unlicensedMedicationDoseQuery = trimStart(
    `
    We have received prescription(s) with the following items:
    *****MEDICATION, DOSE, DURATION*****

    However the prescribed *****USE/DOSE***** is outside of its product’s licence.

    Actions required by practice:
    Please can you confirm that the *****USE/DOSE***** is correct or reissue a new prescription with the correct details.

    Pharmacy name & location: 
    Contact number: 
    `,
);

const medicationDoseQuery = trimStart(
    `
    We have received prescription(s) with the following item(s):
    *****MEDICATION, DOSE, DURATION*****

    *****INSERT MEDICATION QUERY***** 

    Actions required by practice:
    *****REQUESTED ACTION*****

    Pharmacy name & location: 
    Contact number: 
    `,
);

const incorrectQuantity = trimStart(
    `
    We have received prescription(s) with the following item(s):
    *****MEDICATION, DOSE, DURATION*****

    However the quantity prescribed appears to be inappropriate.

    Actions required by practice:
    Please can you confirm that the quantity prescribed is correct or reissue a new prescription with the correct quantity.

    Pharmacy name & location: 
    Contact number: 
    `,
);

const drugInteraction = trimStart(
    `
    We have received prescription(s) with the following item(s):
    *****MEDICATION, DOSE, DURATION*****
    
    However these prescribed medications have a clinically significant drug interaction. 
    *****DESCRIBE DRUG INTERACTION*****
    
    Actions required by practice:
    Please can the prescriber confirm that they are aware of the drug interaction and would like the original prescription to be issued or can a new prescription be issued.
    
    Pharmacy name & location: 
    Contact number: 
    `,
);

const medicationQueryFollowUp = trimStart(
    `
    We sent a medication query for the above patient on *****INSERT DATE*****

    However, we are yet to receive a response or a new prescription.
    
    Please can this enquiry be actioned as soon as possible to avoid further delays dispensing this patient's prescription.

    Pharmacy name & location: 
    Contact number: 
    `,
);

export const pharmacyTemplates = {
    outOfStock,
    bpCheck,
    dosetteBoxQuery,
    controlledDrugQuery,
    unlicensedMedicationDoseQuery,
    medicationDoseQuery,
    incorrectQuantity,
    drugInteraction,
    medicationQueryFollowUp,
};
