import React from "react";

import { Button, Text } from "@accurx/design";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";

export const VaccineAllSessionsSubMenu = (): JSX.Element => {
    const history = useHistory();

    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );

    const handleOverviewClick = (): void => {
        history.push(`/practices/${practiceId}/details`);
    };

    return (
        <NavSubMenuComponent>
            <div className="d-flex justify-content-between align-items-start">
                <span>
                    <Breadcrumb title="Booked Clinic" wrapper={false} />
                    <Text variant="title" as="h1" skinny className="mt-2">
                        COVID-19 Vaccination - Booked Clinic
                    </Text>
                </span>
                <Button
                    theme="secondary"
                    type="button"
                    onClick={handleOverviewClick}
                    text="Manage practice"
                    className="text-nowrap m-1"
                    icon={{
                        name: "Arrow",
                        style: "Line",
                        rotation: "left",
                    }}
                />
            </div>
        </NavSubMenuComponent>
    );
};
