import { Button } from "@accurx/design";

export const BackButton = ({ onClick }: { onClick: () => void }) => (
    <Button
        theme="secondary"
        text="Back"
        icon={{
            name: "Arrow",
            style: "Line",
            rotation: "left",
        }}
        type="button"
        onClick={onClick}
    />
);

export const SkipButton = ({ onClick }: { onClick: () => void }) => (
    <Button
        theme="secondary"
        text="Skip for now"
        type="button"
        onClick={onClick}
    />
);
