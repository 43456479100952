import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const NumberedListItem = styled.li`
    display: flex;

    &::before {
        content: counter(bubble-counter);
        display: inline-block;
        flex-shrink: 0;
        margin-right: ${Tokens.SIZES[2]};

        background: ${Tokens.COLOURS.primary.blue[25]};
        border-radius: 50%;
        width: ${Tokens.SIZES[3]};
        height: ${Tokens.SIZES[3]};

        color: ${Tokens.COLOURS.primary.blue[130]};
        font-weight: 700;
        text-align: center;
    }

    & + & {
        margin-top: ${Tokens.SIZES[2]};
    }
`;

export const NumberedList = styled.ol`
    padding: 0;
    margin: 0;
    list-style: none;

    counter-reset: bubble-counter;

    ${NumberedListItem} {
        counter-increment: bubble-counter;
    }
`;
