import { Button, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledButton = styled(Button)`
    ${({ disabled }) =>
        !disabled && `color: ${Tokens.COLOURS.primary.red[100]};`}
`;

export const StyledFlex = styled(Flex)`
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
`;
