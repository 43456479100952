import React, {
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Avatar, Card, StackPanel, Text } from "@accurx/design";

import { useSafeAsync } from "api/Api.utils";
import { useCurrentOrgId } from "store/hooks";

import {
    PracticeOnlineStatus,
    PracticeUserAvailabilityBadgeProps,
} from "../practiceAvailabilityBadge/PracticeOnlineStatusComponent";
import { PracticeUserAvailabilityService } from "../practiceAvailabilityBadge/PracticeUserAvailabilityService";
import { ClinicianConversationContext } from "../providers";
import { ClinicianConversationSidePanelSection } from "../sidePanelSection/conversationSidePanelSection";
import {
    ParticipantCard,
    ParticipantList,
    ParticipantName,
    ParticipantPanel,
} from "./ParticipantsPanel.styles";

export type Participant = {
    displayName?: string;
    emailAddress: string;
    isCurrentUser: boolean;
};

export type ParticipantWorkspace = {
    workspaceName: string;
    participants: Participant[];
} & ({ workspaceId: number } | { practiceCode: string });

export type ConversationParticipants = {
    participantWorkspaces: ParticipantWorkspace[];
    individualParticipants: Participant[];
};

type ParticipantsPanelProps = {
    participants: ConversationParticipants;
};

type AvailabilityRequest =
    | {
          workspaceIdToQuery: number;
      }
    | {
          practiceCodeToQuery: string;
      };

const currentUserAtEndOfArray = (a: Participant, _: Participant): 1 | -1 =>
    a.isCurrentUser ? 1 : -1;

const countParticipants = ({
    individualParticipants,
    participantWorkspaces,
}: ConversationParticipants) =>
    new Set(
        [
            ...individualParticipants,
            ...participantWorkspaces
                .map(({ participants }) => participants)
                .reduce((a, b) => a.concat(b), []),
        ].map(({ emailAddress }) => emailAddress),
    ).size;

const ParticipantRow = ({
    participant,
    bold,
}: {
    participant: Participant;
    bold?: boolean;
}) => {
    const { isCurrentUser, displayName, emailAddress } = participant;
    const participantName = `${displayName ?? emailAddress}${
        isCurrentUser ? " (You)" : ""
    }`;
    return (
        <StackPanel
            key={`participant-${emailAddress}`}
            orientation="horizontal"
            verticalContentAlignment="center"
            gutter={1}
        >
            <Avatar
                colour={isCurrentUser ? "blue" : "grey"}
                name={displayName}
            />
            <ParticipantName
                variant={bold ? "label" : "body"}
                colour="zinc"
                props={{ title: participantName }}
            >
                {participantName}
            </ParticipantName>
        </StackPanel>
    );
};

const hasEqualParticipantsCount = (
    prev: ParticipantsPanelProps,
    next: ParticipantsPanelProps,
) =>
    countParticipants(prev.participants) ===
    countParticipants(next.participants);

const ParticipantWorkspacePanel = (workspace: ParticipantWorkspace) => {
    const orgId = useCurrentOrgId();
    const safeAsync = useSafeAsync();
    const [onlineStatusProps, setOnlineStatusProps] =
        useState<PracticeUserAvailabilityBadgeProps>({ loading: true });
    const [workspaceAvailable, setWorkspaceAvailable] = useState(true);
    const context = useContext(ClinicianConversationContext);

    const workspaceHasIndividualParticipants =
        workspace.participants.length > 0;

    const getWorkspaceAvailability = useCallback(
        async (request: AvailabilityRequest) => {
            if (!orgId) {
                throw new Error(
                    "ParticipantWorkspacePanel must have a valid organisation id",
                );
            }

            if (context === null) {
                throw new Error(
                    "ParticipantWorkspacePanel must be used inside an appropriate conversation provider",
                );
            }

            if (context.isTestFlow) {
                setTimeout(() => {
                    setOnlineStatusProps({
                        loading: false,
                        result: {
                            success: true,
                            result: {
                                isAvailable: true,
                                activity: {
                                    lastOnlineDaysAgo: 1,
                                    numberOfUsers: 3,
                                },
                            },
                            error: null,
                        },
                    });
                }, 700);
                return;
            }

            const result = await safeAsync(
                PracticeUserAvailabilityService.getPracticeUserAvailability({
                    organisationId: orgId,
                    ...request,
                }),
            );

            setOnlineStatusProps({ result, loading: false });
            setWorkspaceAvailable(result.result?.isAvailable ?? true);
        },
        [context, orgId, safeAsync],
    );

    useEffect(() => {
        getWorkspaceAvailability(
            "workspaceId" in workspace
                ? { workspaceIdToQuery: workspace.workspaceId }
                : { practiceCodeToQuery: workspace.practiceCode },
        );
    }, [getWorkspaceAvailability, workspace]);

    const workspaceParticipantInformation = (
        <StackPanel>
            <Text variant="label">{workspace.workspaceName}</Text>
            <PracticeOnlineStatus {...onlineStatusProps} />
        </StackPanel>
    );

    return (
        <li key={`workspace-${workspace.workspaceName}`}>
            <ParticipantCard
                spacing={2}
                header={
                    workspaceHasIndividualParticipants
                        ? workspaceParticipantInformation
                        : undefined
                }
                workspaceAvailable={workspaceAvailable}
            >
                {workspaceHasIndividualParticipants ? (
                    <ParticipantList gutter={2} as="ul">
                        {workspace.participants
                            .sort(currentUserAtEndOfArray)
                            .map((p) => (
                                <li
                                    key={`${workspace.workspaceName}-${p.emailAddress}`}
                                >
                                    <ParticipantRow
                                        participant={p}
                                    ></ParticipantRow>
                                </li>
                            ))}
                    </ParticipantList>
                ) : (
                    workspaceParticipantInformation
                )}
            </ParticipantCard>
        </li>
    );
};

const ParticipantsPanel = ({
    participants,
}: ParticipantsPanelProps): ReactElement => (
    <ClinicianConversationSidePanelSection
        headerText={`Participants · ${countParticipants(participants)}`}
        headerIconProps={{ name: "Team" }}
        hideOverflow
    >
        <ParticipantPanel>
            <Card spacing={2}>
                <ParticipantList gutter={1.5} as="ul">
                    {participants.participantWorkspaces.map(
                        ParticipantWorkspacePanel,
                    )}
                    {participants.individualParticipants
                        .sort(currentUserAtEndOfArray)
                        .map((p) => (
                            <li key={p.emailAddress}>
                                <Card spacing={2}>
                                    <ParticipantRow
                                        bold
                                        participant={p}
                                    ></ParticipantRow>
                                </Card>
                            </li>
                        ))}
                </ParticipantList>
            </Card>
        </ParticipantPanel>
    </ClinicianConversationSidePanelSection>
);

export default React.memo(ParticipantsPanel, hasEqualParticipantsCount);
