import { BatchPatientIdentifier } from "@accurx/api/patient-messaging";

export const BATCH_OVERVIEW_PAGE_SIZE = 100;

export const ALLOWED_FILE_EXTENSIONS = ["csv", "tsv", "txt"];

export const IdentifiersMap: Record<BatchPatientIdentifier, string> = {
    NhsNumber: "NHS Number",
    HcnNumber: "HCN Number",
};
