import { forwardRef } from "react";

import * as UI from "@accurx/design";
import {
    Level1AccordionHeaderHeight,
    applyFocusStyle,
    applyHoverStyle,
} from "@accurx/navigation";
import { Link } from "domains/inbox/components";
import { LinkProps } from "domains/inbox/components/Link/Link";
import { UnreadCount } from "domains/inbox/components/UnreadCount/UnreadCount";
import styled from "styled-components";

type CareSettingSectionHeaderProps = {
    title: string;
    to: LinkProps["to"];
    isOpen?: boolean;
    unreadCount?: number;
};

const CareSettingLink = styled(Link)`
    appearance: none;
    background: none;
    display: block;
    font-size: 1rem;
    padding: ${UI.Tokens.SIZES[1]};
    text-align: left;
    border-radius: 8px;
    width: 100%;
    border: 1px solid transparent;

    // For the accordion behaviour we need to set a specific height for the
    // accordion header elements. It's very important that this header item fits
    // exactly within the fixed level 1 accordion header height. We take 8px
    // from that value to account for the 4px focus ring above and below.
    height: calc(${Level1AccordionHeaderHeight} - 8px);
    max-height: calc(${Level1AccordionHeaderHeight} - 8px);
    overflow: hidden;

    &:hover {
        ${applyHoverStyle};
    }

    &:focus {
        ${applyFocusStyle};
    }
`;

const Label = styled(UI.Text).attrs({
    colour: "white",
})`
    margin: 0;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    opacity: 0.6;
    line-height: 1.8;
`;

const Title = styled(UI.Text).attrs({
    colour: "white",
    variant: "subtitle",
})`
    margin: 0;
    font-size: 1.25rem;
`;

export const CareSettingSectionHeader = forwardRef<
    HTMLAnchorElement,
    CareSettingSectionHeaderProps
>(({ title, isOpen, unreadCount, ...linkProps }, forwardedRef) => {
    return (
        <CareSettingLink {...linkProps} ref={forwardedRef}>
            <Label>Conversations with</Label>
            <UI.Flex alignItems="center" gap="0.5">
                <UI.Item>
                    <Title>{title}</Title>
                </UI.Item>
                <UI.Item flex="1">
                    <UI.Icon
                        name="Arrow"
                        colour="white"
                        size={3}
                        rotation={isOpen ? "down" : "right"}
                    />
                </UI.Item>
                {!!unreadCount && !isOpen && (
                    <UI.Item>
                        <UnreadCount count={unreadCount} />
                    </UI.Item>
                )}
            </UI.Flex>
        </CareSettingLink>
    );
});
