import { DateHelpers } from "@accurx/shared";
import isNil from "lodash/isNil";

import {
    IdType,
    PatientExternalIdDto,
    PatientName,
    PatientNameUse,
    PatientThreadPatientDetails,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { isInstance } from "shared/concierge/conversations/utils";
import {
    PatientExternalIdentity,
    PatientSummary,
} from "shared/concierge/patients/types/patient.types";

// pulled from existing mapping code in webinbox's conversations.ts in order to move forward,
// fully expect the definition of PatientSummary to evolve later once we have the update models.

export function mapTicketDateOfBirthInfoToPatientSummaryAgeParts(
    dateOfBirth?: string | null,
): Pick<PatientSummary, "ageYear" | "dateOfBirth"> {
    const dob = dateOfBirth ?? "";
    const patientAge = dob ? DateHelpers.getAgeFromDate(dob) : NaN;

    return {
        dateOfBirth: dob ?? "Unspecified",
        ageYear: patientAge,
    };
}

export function mapTicketPatientGenderToPatientSummaryGender(
    gender?: string | null,
): string {
    return gender ?? "Unknown";
}

export const mapTicketPatientToPatientSummary = (
    dto: PatientThreadPatientDetails,
): PatientSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }

    return {
        ...mapTicketPatientNamesToPatientSummaryParts(dto.names),
        ...mapTicketDateOfBirthInfoToPatientSummaryAgeParts(dto.dateOfBirth),
        gender: mapTicketPatientGenderToPatientSummaryGender(dto.gender),
        patientId: dto.accuRxId,
        externalIds: mapTicketPatientExternalIdsToPatientSummaryExternalIds(
            dto.patientExternalIdentity?.patientExternalIds,
        ),
    };
};

export function mapTicketPatientNamesToPatientSummaryParts(
    names?: PatientName[] | null,
): Pick<PatientSummary, "familyName" | "firstName" | "prefixName"> {
    const nameTypeUsual = names?.find(
        (patientNames) => patientNames.use === PatientNameUse.Usual,
    );
    const prefixName = nameTypeUsual?.prefix?.join(" ") || null;
    const firstName = nameTypeUsual?.given?.join(" ") || null;
    const familyName = nameTypeUsual?.family?.join(" ") || null;

    return {
        prefixName,
        firstName,
        familyName,
    };
}
export const mapTicketPatientExternalIdsToPatientSummaryExternalIds = (
    dto: PatientExternalIdDto[] | null | undefined,
): PatientExternalIdentity[] => {
    if (!dto) {
        return [];
    }

    return dto
        .map(mapTicketPatientExternalIdToPatientSummaryExternalId)
        .filter(isInstance);
};

const mapTicketPatientExternalIdToPatientSummaryExternalId = (
    dto: PatientExternalIdDto,
): PatientExternalIdentity | undefined => {
    if (dto.type === IdType.NhsNumber) {
        return {
            type: "NhsNumber",
            value: dto.value,
        };
    }

    if (dto.type === IdType.Emis) {
        return {
            type: "Emis",
            value: dto.value,
        };
    }

    // There are a few more external ID types that we don't currently map:
    // - None
    // - Dummy
    // - SystmOne
    // - Vision
};

export const mapPatientSummaryExternalIdsToTicketPatientExternalIds = (
    ids: PatientExternalIdentity[] | null | undefined,
): PatientExternalIdDto[] => {
    if (!ids) {
        return [];
    }

    return ids
        .map(mapPatientSummaryExternalIdToTicketPatientExternalId)
        .filter(isInstance);
};

const mapPatientSummaryExternalIdToTicketPatientExternalId = (
    ids: PatientExternalIdentity,
): PatientExternalIdDto | undefined => {
    if (ids.type === "NhsNumber") {
        return {
            type: IdType.NhsNumber,
            value: ids.value,
        };
    }

    if (ids.type === "Emis") {
        return {
            type: IdType.Emis,
            value: ids.value,
        };
    }

    // There are a few more external ID types that we don't currently map:
    // - None
    // - Dummy
    // - SystmOne
    // - Vision
};
