export const supportUrls = {
    GET_STARTED:
        "https://support.accurx.com/en/articles/4415312-patient-triage-how-to-get-started",
    PATIENT_REQUESTS_INBOX:
        "https://support.accurx.com/en/articles/4410732-patient-triage-how-to-manage-patient-requests-in-the-accurx-desktop-inbox",
    NEW_REQUESTS:
        "https://support.accurx.com/en/articles/6867607-patient-triage-who-gets-notified-about-new-requests",
    PATIENT_INDENTIFICATION:
        "https://support.accurx.com/en/articles/4247693-patient-triage-how-does-patient-identification-work",
    PATIENT_DATA_SUBMIT:
        "https://support.accurx.com/en/articles/4246197-patient-triage-what-information-do-patients-submit",
};
