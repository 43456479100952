import { Draft } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import isNil from "lodash/isNil";

export const processUpdates = (
    state: Draft<ConciergeState["users"]>,
    action: ConciergeAction<ConciergeUpdates>,
) => {
    const userId = action.meta.userId;
    const users = state.items;
    const updates = action.payload.users ?? [];

    for (const update of updates) {
        users[update.id] = {
            ...users[update.id],
            ...update,
            // let's not waste space by including
            // this flag for all the users where it's not
            // relevant (it also makes testing easier)
            ...(update.id === userId ? { isCurrentUser: true } : {}),
            canBeAssignedTo: isNil(update.canBeAssignedTo)
                ? users[update.id]?.canBeAssignedTo
                : update.canBeAssignedTo,
        };
    }

    return state;
};
