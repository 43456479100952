import { FeedbackColourVariants } from "@accurx/design/dist/styles/types";

export type FeedbackMsg = {
    colour: FeedbackColourVariants | null;
    msg: string;
};

export enum SettingsTabs {
    Cookies = "cookies",
    Profile = "profile",
    Password = "password",
    Signature = "signature",
    Security = "security",
    TermsAndConditions = "terms-and-conditions",
}

export type SettingsUserBase = {
    email: string;
    fullName: string;
};

export type SettingsUserProfile = SettingsUserBase & {
    isEmailConfirmed: boolean;
    statusMessage: string;
};

export type SettingsUserPassword = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export type SettingsUserSignature = {
    signature: string | null;
};

export type SettingsCookies = {
    hasAcceptedCookies: boolean;
};
