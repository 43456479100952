import { Dispatch } from "redux";

import { bFetchBeastSeries } from "../../../api/BeastApi";
import { Actions } from "../../batchMessage/gp/BatchMessage.actions";
import { ListBeastSeriesDto } from "./Beast.types";

interface fetchBeastSeriesAction {
    type: typeof Actions.FETCH_BEAST_SERIES;
}

interface fetchBeastSeriesSuccessAction {
    type: typeof Actions.FETCH_BEAST_SERIES_SUCCESS;
    response: ListBeastSeriesDto;
}

interface fetchBeastSeriesFailureAction {
    type: typeof Actions.FETCH_BEAST_SERIES_FAILURE;
}

export type KnownAction =
    | fetchBeastSeriesAction
    | fetchBeastSeriesSuccessAction
    | fetchBeastSeriesFailureAction;

export const fetchBeastSeries =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.FETCH_BEAST_SERIES });
        const response = await bFetchBeastSeries(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.FETCH_BEAST_SERIES_SUCCESS,
                response: response.result,
            });
        }
        return dispatch({
            type: Actions.FETCH_BEAST_SERIES_FAILURE,
            err:
                response.error ||
                "Something went wrong, please refresh the page",
        });
    };
