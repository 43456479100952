import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[2]};
    flex: 1 1 0;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[3]};
    }
`;

export const StyledFooter = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1.5]};
    justify-content: flex-end;
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }
`;
