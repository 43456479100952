import { FormEvent, useEffect, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useDispatch } from "react-redux";

import { getUserProfile } from "api/SettingsApi";
import { actionCreators } from "app/account/AccountActions";
import { useUpdateUserProfileMutation } from "app/hooks/mutations";

import { FeedbackMsg } from "./Settings.types";

type SettingsProfileProps = {
    hideHeader?: boolean;
};

export const SettingsProfile = ({ hideHeader }: SettingsProfileProps) => {
    useAccurxWebTitle("Manage profile");

    const updateUserProfileMutation = useUpdateUserProfileMutation();
    const [fullName, setFullName] = useState("");
    const dispatch = useDispatch();
    const [fullNameError, setFullNameError] = useState("");
    const [email, setEmail] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    useEffect(() => {
        (async function loadData(): Promise<void> {
            const res = await getUserProfile();
            if (!res.success || !res.result) {
                setFeedbackMsg({
                    colour: "error",
                    msg:
                        res.error ||
                        "Something went wrong fetching your details. Please refresh the page.",
                });
                return;
            }
            setEmail(res.result.email);
            setFullName(res.result.fullName);
        })();
    }, []);

    const handleSave = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setFullNameError("");
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        if (!fullName) {
            setFullNameError("This field is required.");
            return;
        }

        updateUserProfileMutation.mutate(
            { email, fullName },
            {
                onSuccess: () => {
                    dispatch(
                        actionCreators.updateUserProfile({
                            email,
                            fullName,
                        }),
                    );
                    setFeedbackMsg({
                        colour: "success",
                        msg: "Your profile has been updated.",
                    });
                },
                onError: (error) => {
                    setFeedbackMsg({
                        colour: "error",
                        msg:
                            error.message ||
                            "Something went wrong saving your details. Please try again.",
                    });
                },
            },
        );
    };

    return (
        <Card
            header={
                !hideHeader ? (
                    <Text variant="subtitle" as="h2" skinny>
                        Profile
                    </Text>
                ) : undefined
            }
        >
            <form onSubmit={handleSave}>
                <Flex flexDirection="column" gap="2">
                    <FormFieldWrapper
                        label="Full name"
                        labelProps={{ htmlFor: "fullname" }}
                        errors={[fullNameError]}
                    >
                        <Input
                            id="fullname"
                            value={fullName}
                            type="text"
                            onChange={(e): void => setFullName(e.target.value)}
                            hasErrors={!!fullNameError}
                        />
                    </FormFieldWrapper>
                    <div>
                        <Text skinny variant="label">
                            Email
                        </Text>
                        <Text variant="body" skinny>
                            {email}
                        </Text>
                    </div>
                    {feedbackMsg.colour && feedbackMsg.msg && (
                        <Feedback
                            title={feedbackMsg.msg}
                            colour={feedbackMsg.colour}
                        />
                    )}
                    <Flex>
                        <Button type="submit" text="Save" />
                    </Flex>
                </Flex>
            </form>
        </Card>
    );
};
