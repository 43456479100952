import {
    Flex,
    IconNameVariants,
    Item,
    PopoverItem,
    PopoverItemButton,
} from "@accurx/design";

import { StyledContactOption } from "./ContactOption.styles";

type NotContactableItemProps = {
    disabled: true;
};
type ContactableItemProps = {
    disabled: false;
    onClick: () => void;
};
type ContactItemProps = {
    iconName: IconNameVariants;
    text: string;
} & (ContactableItemProps | NotContactableItemProps);

export const ContactOption = ({
    iconName,
    text,
    ...props
}: ContactItemProps) => {
    if (props.disabled) {
        return (
            <PopoverItem>
                <StyledContactOption.OptionIcon name={iconName} />
                <Flex flexDirection="column" alignItems="flex-start">
                    <Item>
                        <StyledContactOption.OptionText $disabled>
                            {text}
                        </StyledContactOption.OptionText>
                    </Item>
                    <StyledContactOption.UnavailableMessage>
                        Unavailable for this patient
                    </StyledContactOption.UnavailableMessage>
                </Flex>
            </PopoverItem>
        );
    }

    return (
        <PopoverItemButton onClick={props.onClick}>
            <StyledContactOption.OptionIcon name={iconName} />
            <StyledContactOption.OptionText>
                {text}
            </StyledContactOption.OptionText>
        </PopoverItemButton>
    );
};
