import React from "react";

import { Accordion, Flex, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { ConversationGroup } from "app/workspaceConversations/components/ConversationGroup";
import {
    ConversationGroupPageError,
    ConversationGroupPageLoading,
    PaginationNavigation,
} from "app/workspaceConversations/components/ConversationGroupPage";
import { SearchedPatientConversationGroupActionScope } from "shared/concierge/conversations/tickets/SearchedPatientConversationActions";
import { useCurrentOrgName } from "store/hooks";

import { useSearchedPatientConversationGroup } from "../conversations/useSearchedPatientConversationGroup";

type PatientConversationsPanelProps = {
    conversationGroupType: SearchedPatientConversationGroupActionScope["type"];
};

export const PatientConversationsPanel = ({
    conversationGroupType,
}: PatientConversationsPanelProps): JSX.Element => {
    const conversationScope =
        conversationGroupType === "workspace"
            ? "all"
            : conversationGroupType === "user"
            ? "my"
            : "";

    useAccurxWebTitle(`View ${conversationScope} conversations with patient`);

    const groupResult = useSearchedPatientConversationGroup(
        conversationGroupType,
    );

    const workspaceName = useCurrentOrgName();

    // Deliberate design decision not to display data if any form (main or background) loading or errors are happening
    // even if we have data to display
    const displayData =
        groupResult?.data &&
        groupResult.status === "SUCCESS" &&
        (groupResult.backgroundStatus === "SUCCESS" ||
            groupResult.backgroundStatus === undefined);

    // We won't allow users to go to the next page if the current page hasn't loaded
    // all the data it needs
    const hasLoadedAllDataRequired =
        groupResult?.status === "SUCCESS" &&
        (groupResult.backgroundStatus === "SUCCESS" ||
            groupResult.backgroundStatus === undefined);

    return (
        <Flex gap="0.5" flexDirection="column">
            <Accordion
                header={
                    <Text variant="label" skinny>
                        <Icon
                            name="Info"
                            size={3}
                            halo={{ colour: "orange" }}
                        />
                        What will appear here?
                    </Text>
                }
            >
                <ul>
                    <li>
                        {conversationGroupType === "workspace" ? (
                            <Text>
                                All conversations with this specific patient
                                from colleagues at{" "}
                                {workspaceName === "Unknown"
                                    ? "your workspace"
                                    : workspaceName}
                            </Text>
                        ) : (
                            <Text>
                                All conversations shared between me and this
                                specific patient
                            </Text>
                        )}
                    </li>
                </ul>
            </Accordion>
            <Flex justifyContent="flex-end">
                <PaginationNavigation
                    paginationActions={groupResult.paginationActions}
                    hasLoadedAllDataRequired={hasLoadedAllDataRequired}
                    uniqueId="top"
                />
            </Flex>
            {displayData && (
                <ConversationGroup
                    conversations={groupResult.data}
                    showDisplayItemDate={false}
                    showLastItemIndicator
                    conversationListItemAnalytics={() => undefined}
                />
            )}
            {/* Show an error if initial status is error, or we have a background fetch error and the page is stale */}
            {(groupResult?.status === "ERROR" ||
                groupResult?.backgroundStatus === "ERROR") && (
                <ConversationGroupPageError
                    errorMessage="There was a problem loading your patient conversations"
                    retryFetch={groupResult.retryFetch}
                />
            )}
            {/* Show a loading state if initial status is loading, or we have background loading occurring and the page is stale */}
            {(groupResult?.status === "LOADING" ||
                groupResult?.backgroundStatus === "LOADING") && (
                <ConversationGroupPageLoading />
            )}
            <Flex justifyContent="flex-end">
                <PaginationNavigation
                    paginationActions={groupResult.paginationActions}
                    hasLoadedAllDataRequired={hasLoadedAllDataRequired}
                    uniqueId="bottom"
                />
            </Flex>
        </Flex>
    );
};
