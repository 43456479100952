import React, { useEffect } from "react";

import {
    Feedback,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Text,
} from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { fetchSnomedCodes } from "app/batchMessage/gp/BatchMessage.actions";
import {
    BatchSnomedCode,
    MessageTemplate,
} from "app/batchMessage/gp/BatchMessage.types";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

import { SnomedCodeFeedback } from "./SnomedCodeFeedback";

export type SnomedCodeSelectProps = {
    id?: "snomed-dropdown" | "decline-settings-snomed-dropdown";
    selectedTemplateDisplay: string;
    selectedSnomedCode: string;
    setSelectedTemplateSnomedConceptId: (snomedId: string) => void;
    label?: string;
    sublabel?: string;
    batchSnomedSelect: string;
};

export const SnomedCodeSelect = ({
    selectedTemplateDisplay,
    selectedSnomedCode,
    batchSnomedSelect,
    setSelectedTemplateSnomedConceptId,
    label,
    sublabel,
    id = "snomed-dropdown",
}: SnomedCodeSelectProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const {
        gettingSnomedCodesStatus,
        snomedCodes,
        accurxTemplatesList,
        orgTemplatesList,
        userTemplatesList,
        selectedSlotType,
    } = useAppSelector(({ batchMessage }) => batchMessage, shallowEqual);

    useEffect(() => {
        if (gettingSnomedCodesStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(fetchSnomedCodes(practiceId));
        }
    }, [dispatch, practiceId, gettingSnomedCodesStatus]);

    const handleOptionChange = (selected: Option[] | Option) => {
        const selectedValue = (selected as Option).value;

        setSelectedTemplateSnomedConceptId(
            selectedValue === "RESET" ? "" : selectedValue,
        );

        const slotName = selectedSlotType.type || "Not selected";
        const snomedCode =
            snomedCodes.find((code) => code.conceptId === selectedValue)
                ?.conceptId || "";
        ChainAnalyticsTracker.trackBatchComposeSnomedOptionClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            snomedCode,
            slotName,
            batchSnomedSelect,
        });
    };

    // Create options for the dropdown
    const options: Option[] = [
        {
            label: "No SNOMED code",
            value: "RESET",
        },
        ...snomedCodes.map((snomed: BatchSnomedCode) => ({
            label: snomed.term,
            value: snomed.conceptId,
        })),
    ];

    // Search for previously selected SNOMED
    const selectedSnomed = snomedCodes.find(
        (snomed: BatchSnomedCode) => selectedSnomedCode === snomed.conceptId,
    );
    const initialValue =
        selectedSnomed !== undefined
            ? { label: selectedSnomed.term, value: selectedSnomed.conceptId }
            : undefined;

    // Templates with default SNOMED are non editable
    const allTemplates: MessageTemplate[] = [
        ...accurxTemplatesList,
        ...orgTemplatesList,
        ...userTemplatesList,
    ];
    if (selectedTemplateDisplay && allTemplates.length) {
        const selectedTemplate = allTemplates.find(
            (template: MessageTemplate) =>
                template.title === selectedTemplateDisplay,
        );

        if (selectedTemplate?.snomedCodes.length) {
            return (
                <SnomedCodeFeedback
                    selectedSnomedCode={selectedSnomedCode}
                    snomedCodes={snomedCodes}
                />
            );
        }
    }

    if (gettingSnomedCodesStatus === UpdatingStatus.Failed) {
        return (
            <Feedback
                colour="warning"
                title="There was a connection problem."
                props={{ className: "mb-4" }}
            >
                <Text skinny>
                    We couldn't load the list of SNOMED code. Refresh the page
                    to try again. If that still doesn't work, contact support.
                </Text>
            </Feedback>
        );
    }

    if (snomedCodes.length !== 0) {
        return (
            // N.B: this div is used for targeting specific <select>, because htmlFor and <label> is not accessible via testing-library
            <div data-testid={`${id}-select-wrapper`}>
                <FormFieldWrapper
                    className="mb-4"
                    labelProps={{ htmlFor: id }}
                    label={
                        label || (
                            <Text skinny>
                                <Text variant="label" as="span" skinny>
                                    SNOMED code
                                </Text>{" "}
                                (optional)
                            </Text>
                        )
                    }
                >
                    <Text skinny>
                        {sublabel ||
                            "This will be saved to record once the message is sent."}
                    </Text>
                    <SearchSelect
                        id={id}
                        options={options}
                        onChangeHandler={handleOptionChange}
                        initSelectedValues={initialValue}
                        zIndex={Z_INDEX.dropdown}
                    />
                </FormFieldWrapper>
            </div>
        );
    }

    return null;
};
