import { useCallback, useEffect, useState } from "react";

import { Button, Card, Icon, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { useSafeAsync } from "api/Api.utils";
import { FlemingAnalyticsTracker } from "app/analytics";
import {
    PracticeOnlineStatus,
    PracticeUserAvailabilityBadgeProps,
} from "app/clinicianconversation/practiceAvailabilityBadge/PracticeOnlineStatusComponent";
import { PracticeUserAvailabilityService } from "app/clinicianconversation/practiceAvailabilityBadge/PracticeUserAvailabilityService";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { findBaseRoute, getMessagePracticeUrl } from "shared/RoutesHelper";
import { useAppSelector, useCurrentOrgId } from "store/hooks";

import { StyledButtonWrapper } from "./GpCard.styles";
import { isTestPatient } from "./isTestPatient";
import {
    StyledCardColumn,
    StyledContainer,
    StyledFlex,
    StyledSectionHeader,
} from "./tabPanels/OverviewPanel.styles";
import { useMessagePracticeAvailabilityWithPatientData } from "./useMessagePracticeAvailabilityWithPatientData";

const GpCard = (): JSX.Element => {
    const history = useHistory();

    const { canMessageGp, patient } =
        useMessagePracticeAvailabilityWithPatientData();

    const loggedInProps = useFlemingLoggedInAnalytics();
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );

    const account = useAppSelector((state) => state.account);
    const testPatient = isTestPatient(patient);

    const orgId = useCurrentOrgId();
    const safeAsync = useSafeAsync();

    const [onlineStatusProps, setOnlineStatusProps] =
        useState<PracticeUserAvailabilityBadgeProps>({ loading: true });

    const redirectToMessageGp = () => {
        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Overview" as const,
            optionSelected: "MessagePractice" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionButtonClick(
                analyticsProps,
            );

        const selectedOrganisation =
            OrganisationHelper.getOrganisation(account);

        history.push(
            `${findBaseRoute(history.location.pathname)}${getMessagePracticeUrl(
                selectedOrganisation,
            )}${testPatient ? "-test" : ""}`,
            {
                appOrigin: "MessagePractice",
            },
        );
    };

    const getHeader = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        return (
            (patient?.firstName ? patient.firstName + "'s " : "") +
            "GP practice"
        );
    };

    const getPracticeName = () => {
        if (!patient) {
            return <SkeletonLabel charCount={20} />;
        }

        if (patient?.practiceName) {
            return patient.practiceName;
        }

        if (patient?.practiceCode) {
            return `Practice with ODS code ${patient.practiceCode}`;
        }

        if (testPatient) {
            return "Test GP Practice";
        }

        return "Practice name unknown";
    };

    const getWorkspaceAvailability = useCallback(async () => {
        if (!canMessageGp && !testPatient) {
            return;
        }

        if (!orgId) {
            throw new Error("GPCard must have a valid organisation id");
        }

        if (testPatient) {
            setTimeout(() => {
                console.log("Ahh!");
                setOnlineStatusProps({
                    loading: false,
                    result: {
                        success: true,
                        result: {
                            isAvailable: true,
                            activity: {
                                lastOnlineDaysAgo: 1,
                                numberOfUsers: 3,
                            },
                        },
                        error: null,
                    },
                });
                console.log("Bahh!");
            }, 700);
            return;
        }

        if (patient?.practiceCode) {
            const result = await safeAsync(
                PracticeUserAvailabilityService.getPracticeUserAvailability({
                    organisationId: orgId,
                    practiceCodeToQuery: patient?.practiceCode,
                }),
            );
            setOnlineStatusProps({ result, loading: false });
            return;
        }
    }, [canMessageGp, orgId, patient?.practiceCode, testPatient, safeAsync]);

    useEffect(() => {
        getWorkspaceAvailability();
    }, [getWorkspaceAvailability]);

    return (
        <StackPanel gutter={2}>
            <StyledFlex>
                <Icon name="Doctor" size={4} halo={{ colour: "blue" }} />
                <StyledSectionHeader forwardedAs="h5" variant="label">
                    {getHeader()}
                </StyledSectionHeader>
            </StyledFlex>
            <Card isElevated spacing={2} variant="regular">
                <StackPanel>
                    <StyledContainer>
                        <StyledCardColumn>
                            <Text variant="label" skinny>
                                {getPracticeName()}
                            </Text>
                            {patient?.practiceAddress && (
                                <Text variant="body">
                                    {patient?.practiceAddress}
                                </Text>
                            )}
                            {patient?.practicePhoneNumber && (
                                <Text variant="body">
                                    {patient?.practicePhoneNumber}
                                </Text>
                            )}
                        </StyledCardColumn>
                        {(canMessageGp || testPatient) && (
                            <PracticeOnlineStatus {...onlineStatusProps} />
                        )}
                    </StyledContainer>
                    <StyledButtonWrapper>
                        {(canMessageGp || testPatient) && (
                            <Button
                                data-userflow-id="message-gp-patient-profile-gp-card"
                                theme="secondary"
                                text="Message GP practice"
                                icon={{
                                    colour: "blue",
                                    name: "Mention",
                                }}
                                onClick={redirectToMessageGp}
                            />
                        )}
                    </StyledButtonWrapper>
                </StackPanel>
            </Card>
        </StackPanel>
    );
};

export { GpCard };
