import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { NoteItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { FreeText } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { userflowIds } from "domains/inbox/util";
import { formatUserDisplayName } from "domains/inbox/util/format/names";
import styled from "styled-components";

import { StylesNotesIconWrapper } from "./NotesMessages.styles";

const StyledContainer = styled(ConversationItemContainer)`
    background-color: ${UI.Tokens.COLOURS.primary.orange[5]};
`;

export const NoteMessage = ({
    item,
    isReadReceiptShown = true,
}: ConversationItemComponent<NoteItem> & { isReadReceiptShown?: boolean }) => {
    const senderId = item.createdBy.type === "User" ? item.createdBy.id : "";
    const sender = useUser({ userId: senderId });

    const senderName = formatUserDisplayName({
        user: sender,
        considerCurrentUser: false,
    });

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <StyledContainer
                data-testid={`conversation-item-${item.id}`}
                $color="orange"
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={
                            <UI.Avatar
                                name={senderName}
                                size="small"
                                colour="grey"
                            />
                        }
                        name={senderName}
                    />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color="orange" size="small">
                            Note
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <UI.Flex flexDirection="column" gap="3">
                        <UI.Item>
                            <FreeText>
                                {item.segments.map((s) =>
                                    s.type === "Text" ? s.text : null,
                                )}
                            </FreeText>
                        </UI.Item>
                        <UI.Item>
                            <UI.Flex alignItems="center">
                                <StylesNotesIconWrapper>
                                    <UI.Icon
                                        name="LockLocked"
                                        colour="metal"
                                        theme="Fill"
                                    />
                                </StylesNotesIconWrapper>
                                <UI.Text
                                    skinny
                                    variant="preview"
                                    colour="metal"
                                >
                                    Only visible to colleagues in your workspace
                                </UI.Text>
                            </UI.Flex>
                        </UI.Item>
                    </UI.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <div>
                        {isReadReceiptShown && (
                            <ReadByTooltip
                                readDataByUserId={item.readDataByUserId}
                            />
                        )}
                    </div>
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </StyledContainer>
        </ConversationThreadListItem>
    );
};
