import React from "react";

import { Feedback, Text } from "@accurx/design";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionUnapprovedProps = {
    selectedPractice: string;
};

export const SectionUnapproved = ({
    selectedPractice,
}: SectionUnapprovedProps): JSX.Element => {
    return (
        <>
            <Feedback
                colour="warning"
                title="You are currently an unapproved user."
            >
                <Text skinny>
                    This means you will not have access to certain Accurx
                    features. Please ask your Admin to approve you.
                </Text>
            </Feedback>
            <Text as="h2" variant="subtitle">
                Accurx Desktop
            </Text>
            <div className="mb-4">
                <PracticeLink
                    url="delivery-receipts"
                    title="Delivery Receipts"
                    section={PracticeLinkSection.UNAPPROVED}
                    description="Shows the delivery status for SMS messages sent by your team"
                />
                <PracticeLink
                    url={`/practices/${selectedPractice}/manage-templates`}
                    title="Manage Templates"
                    section={PracticeLinkSection.UNAPPROVED}
                    description="Allows you to create and edit SMS templates and create Florey Questionnaires for yourself and your team"
                />
            </div>
        </>
    );
};
