import { useEffect, useState } from "react";

import { PatientDemographics } from "@accurx/native";
import { Log } from "@accurx/shared";
import { InboxLocationState } from "domains/inbox/hooks/util/useInboxLink";
import { ROUTES_INBOX } from "domains/inbox/routes";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { matchPath, useLocation } from "react-router";

export const isSamePatient = (
    patientA?: PatientDemographics,
    patientB?: PatientDemographics,
): boolean => {
    if (!patientA || !patientB) return false;

    /**
     * We compare external ids arrays here because:
     * 1. we don't have internal ids in PatientDemographics,
     *    we'd have to fetch them via an API call if we needed those
     * 2. both patients come from the medical record integration, so data should
     *    be consistent/the same
     */
    return isEqual(
        sortBy(patientA.patientExternalIdentityDto.patientExternalIds, [
            "type",
            "value",
        ]),
        sortBy(patientB.patientExternalIdentityDto.patientExternalIds, [
            "type",
            "value",
        ]),
    );
};

export const useGetCurrentPatient = ({
    isOpeningInSystem,
    medicalRecordPatient,
}: {
    isOpeningInSystem: boolean;
    medicalRecordPatient?: PatientDemographics;
}):
    | {
          type: "none";
          patient: null;
      }
    | {
          type: "searched" | "system" | "openingInSystem";
          patient: PatientDemographics;
      } => {
    const { pathname, state = {} } = useLocation<InboxLocationState>();
    const locationPatient = state.patient;

    const [isInPatientConversationPage, setIsInPatientConversationPage] =
        useState(false);

    useEffect(() => {
        // When the medical record patient changes, show the system patient
        if (medicalRecordPatient) {
            setIsInPatientConversationPage(false);
        }
    }, [medicalRecordPatient]);

    useEffect(() => {
        setIsInPatientConversationPage(
            matchPath(pathname, ROUTES_INBOX.Patient) !== null &&
                !!locationPatient,
        );
    }, [pathname, locationPatient]);

    if (isOpeningInSystem) {
        if (locationPatient) {
            return {
                type: "openingInSystem",
                patient: locationPatient,
            };
        } else {
            Log.error(
                "Expected patient to be in browser location state while opening in medical record system, but none were found",
            );
        }
    }

    // Show the searched patient when user navigates to a patient conversation page has a selected patient in state
    // and if we're on a patient that is different from the current medical record patient
    if (
        locationPatient &&
        isInPatientConversationPage &&
        !isSamePatient(locationPatient, medicalRecordPatient)
    ) {
        return {
            type: "searched",
            patient: locationPatient,
        };
    } else if (medicalRecordPatient) {
        return {
            type: "system",
            patient: medicalRecordPatient,
        };
    } else {
        return { type: "none", patient: null };
    }
};
