import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

export const MainPanel = styled.div`
    margin: ${Tokens.SIZES[2]} 0;
`;

export const StyledSendBatchButton = styled(Button)`
    margin: ${Tokens.SIZES[3]} 0;
`;
