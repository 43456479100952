import { ConversationSummary } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { UserSummary } from "domains/concierge/types";

import { mapPatientThreadUserToUserSummary } from "./mapPatientThreadUserToUserSummary";

export const mapConversationSummaryToUserSummaries = (
    dto: ConversationSummary,
): Record<UserSummary["id"], UserSummary> => {
    const users: Record<UserSummary["id"], UserSummary> = {};

    if (dto.assignee) {
        const assignee = mapPatientThreadUserToUserSummary(dto.assignee);

        if (assignee) {
            users[assignee.id] = assignee;
        }

        if (dto.assignedUserId && !assignee) {
            Log.warn(
                "A conversation summary with an assignedUserId did not contain valid user details",
                {
                    tags: {
                        product: "Inbox",
                        conversationId: dto.id,
                        assigneeId: dto.assignedUserId,
                    },
                },
            );
        }
    }

    for (const item of dto.items) {
        const creator = item.createdBy;
        if (creator.discriminator === "user") {
            const member = mapPatientThreadUserToUserSummary({
                accuRxId: creator.userId,
                name: creator.displayName,
                email: creator.emailAddress,
            });

            if (member) {
                users[member.id] = member;
            } else {
                Log.warn(
                    "A conversation item sender did not contain valid user details",
                    {
                        tags: {
                            product: "Inbox",
                            conversationId: dto.id,
                            itemId: item.id,
                            userId: creator.userId,
                        },
                    },
                );
            }
        }
    }

    return users;
};
