import { useEffect, useMemo, useState } from "react";

import { MessageTemplateResponse } from "@accurx/api/patient-messaging";
import { FeatureName } from "@accurx/auth";
import { useDispatch } from "react-redux";

import { getAccurxWebPresetTemplates } from "api/PatientMessagingServer/PatientMessagingServerApi";
import { actionCreators } from "app/messageTemplates/MessageTemplatesActions";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { mapToPresetTemplateItemArray } from "./MessageTemplate.helper";

export const useMessageTemplates = (workspaceId: number | null) => {
    const dispatch = useDispatch();

    const isApprovedUser = useAppSelector(({ account }) => {
        return OrganisationHelper.getIsApprovedOrgUser(account);
    });

    const smsTemplates = useAppSelector(
        ({ messageTemplates }) => messageTemplates.smsTemplates,
    );

    const lastGetUserMessageTemplatesFailed = useAppSelector(
        ({ messageTemplates }) =>
            messageTemplates.lastGetMessageTemplatesFailed,
    );

    const isUserTemplatesLoading = useAppSelector(
        ({ messageTemplates }) => messageTemplates.isMessageTemplatesLoading,
    );

    const hasUserTemplatesFailed =
        !isUserTemplatesLoading && lastGetUserMessageTemplatesFailed;

    const [hasPresetTemplatesFailed, setHasPresetTemplatesFailed] =
        useState(false);

    const [isPresetTemplatesLoading, setIsPresetTemplatesLoading] =
        useState(true);

    const [presetTemplates, setPresetTemplates] = useState<
        MessageTemplateResponse[]
    >([]);

    const hidePresetMessageTemplatesEnabled = useIsFeatureEnabled(
        FeatureName.HidePresetMessageTemplates,
    );

    useEffect(() => {
        const fetchPresetTemplates = async () => {
            if (hidePresetMessageTemplatesEnabled) {
                setIsPresetTemplatesLoading(false);
                setPresetTemplates([]);
                return;
            }

            const { result, success } = await getAccurxWebPresetTemplates({
                organisationId: workspaceId,
            });

            if (!result || !success) {
                setHasPresetTemplatesFailed(true);
                setIsPresetTemplatesLoading(false);
                return;
            }
            setPresetTemplates(result);
            setIsPresetTemplatesLoading(false);
        };

        fetchPresetTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceId]);

    const mappedPresetTemplates = useMemo(() => {
        return mapToPresetTemplateItemArray(presetTemplates);
    }, [presetTemplates]);

    useEffect(() => {
        if (!!workspaceId) {
            dispatch(
                actionCreators.getUserMessageTemplates(
                    {
                        organisationId: workspaceId,
                        isApprovedUser,
                    },
                    false,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceId]);

    return {
        presetTemplates: mappedPresetTemplates,
        userTemplates: smsTemplates,
        isPresetTemplatesLoading,
        isUserTemplatesLoading,
        hasPresetTemplatesError: hasPresetTemplatesFailed,
        hasUserTemplatesError: hasUserTemplatesFailed,
    };
};
