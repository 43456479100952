import { ComponentProps, useMemo } from "react";

import * as UI from "@accurx/design";
import {
    formatNHSDateTime,
    formatSRDateTime,
} from "domains/inbox/util/format/dateTime";

type TextProps = Omit<ComponentProps<typeof UI.Text>, "children">;

export type LastUpdateTimeProps = {
    time: string;
    textProps?: TextProps;
};

export const LastUpdateTime = ({ time, textProps }: LastUpdateTimeProps) => {
    const formattedTime = useMemo(() => formatNHSDateTime(time), [time]);
    const formattedSRTime = useMemo(() => formatSRDateTime(time), [time]);

    return (
        <>
            <UI.VisuallyHidden as="span">
                updated {formattedSRTime},{" "}
            </UI.VisuallyHidden>
            <UI.Text
                variant="preview"
                props={{ "aria-hidden": true }}
                {...textProps}
            >
                {formattedTime}
            </UI.Text>
        </>
    );
};
