import { MutableRefObject, useEffect, useRef } from "react";

import {
    Button,
    Card,
    Flex,
    FormFieldFeedback,
    Item,
    StackPanel,
    Text,
} from "@accurx/design";

import { useIsFeatureEnabled } from "store/hooks";

import { BranchId, BranchPosition, QuestionLevel } from "../types/data.types";
import { PanelButton } from "./PanelButton";
import { StyledLi, StyledUnorderedList } from "./QuestionTypeSelector.styles";
import {
    HandleCloseQuestionSelectCard,
    HandleSelectQuestionType,
    SupportedQuestionType,
} from "./QuestionTypeSelector.types";

export type QuestionTypeSelectorCardProps = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    handleSelectQuestionType: ({
        branchId,
        branchPosition,
        questionLevel,
        questionType,
    }: HandleSelectQuestionType) => void;
    handleClose: ({
        branchPosition,
        questionLevel,
    }: HandleCloseQuestionSelectCard) => void;
    cardActionErrors: string[];
    cardActionErrorRef: MutableRefObject<HTMLDivElement | null>;
    questionLevel: QuestionLevel;
};

export const QuestionTypeSelectorCard = ({
    branchId,
    branchPosition,
    handleSelectQuestionType,
    handleClose,
    cardActionErrors,
    cardActionErrorRef,
    questionLevel,
}: QuestionTypeSelectorCardProps): JSX.Element => {
    const firstButtonRef = useRef<HTMLButtonElement>(null);
    // TODO: once on the new workspace URL structure, can replace useIsFeatureEnabled with
    // import { useFeatureFlag } from "@accurx/auth";
    const isGlobalCustomFloreyEnabled = useIsFeatureEnabled(
        "CreateGlobalCustomFloreys",
    );

    useEffect(() => {
        // Only on first mount of the component, focus on the first button
        if (firstButtonRef.current) {
            firstButtonRef.current.focus();
        }
    }, []);

    const orgSupportedQuestionTypes = [
        {
            type: SupportedQuestionType.MultipleChoice,
            text: "Multiple choice (one or many answers)",
        },
        {
            type: SupportedQuestionType.Measurement,
            text: "Measurement (height or weight)",
        },
        {
            type: SupportedQuestionType.FreeText,
            text: "Free text",
        },
    ];

    /**
     * Should only display this if the Global Florey FF is enabled
     */
    if (isGlobalCustomFloreyEnabled) {
        orgSupportedQuestionTypes.push({
            type: SupportedQuestionType.PhotoUpload,
            text: "Photo upload",
        });
        orgSupportedQuestionTypes.push({
            type: SupportedQuestionType.Information,
            text: "Information",
        });
    }

    /** Should only display branch prop when we're are rendering at the Questionnaire level */
    if (isGlobalCustomFloreyEnabled && questionLevel === "Questionnaire") {
        orgSupportedQuestionTypes.push({
            type: SupportedQuestionType.Branch,
            text: "Branch",
        });
    }

    return (
        <Card
            variant={"regular"}
            spacing={3}
            header={
                <Flex justifyContent="space-between">
                    <Item>
                        <Text
                            variant={"label"}
                            as={"h2"}
                            children={"Choose a question type"}
                        />
                    </Item>
                    <Item>
                        <Button
                            theme="secondary"
                            icon={{
                                name: "Cross",
                                colour: "metal",
                                title: "Close question type selector",
                                id: "close-question-selector",
                            }}
                            onClick={() =>
                                handleClose({
                                    branchPosition,
                                    questionLevel,
                                })
                            }
                        />
                    </Item>
                </Flex>
            }
            children={
                <StackPanel gutter={2}>
                    {cardActionErrors.length > 0 && (
                        <div ref={cardActionErrorRef} tabIndex={0}>
                            <FormFieldFeedback
                                text={cardActionErrors[0]}
                                variant="error"
                            />
                        </div>
                    )}
                    <StyledUnorderedList>
                        {orgSupportedQuestionTypes.map(
                            ({ type, text }, index) => {
                                return (
                                    <StyledLi key={`${type}`}>
                                        <PanelButton
                                            ref={
                                                index === 0
                                                    ? firstButtonRef
                                                    : null
                                            }
                                            text={`${text}`}
                                            onClick={() =>
                                                handleSelectQuestionType({
                                                    branchId,
                                                    branchPosition,
                                                    questionType: type,
                                                    questionLevel,
                                                })
                                            }
                                        />
                                    </StyledLi>
                                );
                            },
                        )}
                    </StyledUnorderedList>
                </StackPanel>
            }
        />
    );
};
