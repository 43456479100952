import { useEffect } from "react";

import { Prompt } from "react-router-dom";

// Extends the funtionality of react router's Prompt, to display messages also on page unload.
export const UnsavedChangesPrompt = ({ blockExit }: { blockExit: boolean }) => {
    const message =
        "Are you sure you want to leave this page? Your message may not be saved.";

    useEffect(() => {
        const beforeunload = (event: {
            preventDefault: () => void;
            returnValue: string | null;
        }) => {
            if (blockExit) {
                event.preventDefault();
                // Probably won't actually be displayed, but have to return a string so might as well try
                event.returnValue = message;
            }
        };
        window.addEventListener("beforeunload", beforeunload);
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, [blockExit]);

    return <Prompt when={blockExit} message={message} />;
};
