import * as UI from "@accurx/design";
import { SIDENAV_WIDTH } from "@accurx/navigation";
import styled from "styled-components";

import { StyledSearchFormSubmitButtonContainer } from "./forms/Forms.styles";

const StyledPanelContainer = styled.section`
    // Absolutely positioned withing the outer
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - ${SIDENAV_WIDTH});

    // Important - some sticky elements will stay on top otherwise
    z-index: 1;

    @media screen and (max-width: ${UI.Tokens.BREAKPOINTS.lg}) {
        width: 100%;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${UI.Tokens.COLOURS.greyscale.white};
    box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);
`;

const StyledPanelHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: ${UI.Tokens.SIZES[1]};

    padding: ${UI.Tokens.SIZES[1.5]} ${UI.Tokens.SIZES[3]};
`;

const StyledPanelFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: ${UI.Tokens.SIZES[1]};

    padding: ${UI.Tokens.SIZES[1.5]} ${UI.Tokens.SIZES[3]};
    border-top: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
`;

const StyledPanelBody = styled.main`
    overflow: auto;
    height: 100%;
    padding: ${UI.Tokens.SIZES[2]} ${UI.Tokens.SIZES[3]};
`;

export const StyledPanel = {
    Container: StyledPanelContainer,
    Header: StyledPanelHeader,
    Body: StyledPanelBody,
    Footer: StyledPanelFooter,
};

export const StyledPatientSearchFormOverrides = styled.div`
    /** Search button should be aligned to the left on the search panel */
    ${StyledSearchFormSubmitButtonContainer} {
        justify-content: flex-start;
    }
`;
