import { Dispatch, SetStateAction, useState } from "react";

import {
    Button,
    Feedback,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import isValid from "date-fns/isValid";
import { toast } from "react-toastify";

import { ScheduleMessageModal } from "./ScheduleMessageModal";
import { useNextWorkingDay } from "./useNextWorkingDay";

export type ScheduleOptions = "now" | "next-working-day-8am" | "custom-date";

type ScheduleButtonProps = {
    activeOption: ScheduleOptions;
    setActiveOption: Dispatch<SetStateAction<ScheduleOptions>>;
    setScheduleDateAndTime(date: null, hour: null): void;
    setScheduleDateAndTime(date: Date, hour: number): void;
    workspaceId: string;
};

export function ScheduleButton({
    activeOption,
    setActiveOption,
    setScheduleDateAndTime,
    workspaceId,
}: ScheduleButtonProps) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const { status, data } = useNextWorkingDay({
        workspaceId,
        enabled: true,
    });

    return (
        <>
            <ScheduleMessageModal
                isOpen={isModalOpen}
                setIsOpen={setModalOpen}
                onCancel={() => setModalOpen(false)}
                onSchedule={(date, hour) => {
                    setScheduleDateAndTime(date, hour);
                    setModalOpen(false);
                }}
            />
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
                <PopoverTrigger asChild>
                    <Button
                        theme="secondary"
                        text="Schedule message"
                        icon={{
                            name: "Arrow",
                            colour: "blue",
                            placement: "end",
                        }}
                        disabled={status === "loading"}
                    />
                </PopoverTrigger>
                <PopoverContent align="end" side="top" width="100%">
                    <PopoverItemButton
                        active={activeOption === "now"}
                        onClick={() => {
                            setActiveOption("now");
                            // clear the selected date and time
                            setScheduleDateAndTime(null, null);
                            setIsPopoverOpen(false);
                        }}
                    >
                        Now
                    </PopoverItemButton>
                    <PopoverItemButton
                        active={activeOption === "next-working-day-8am"}
                        onClick={() => {
                            setActiveOption("next-working-day-8am");

                            if (status === "success") {
                                const nextWorkingDate = new Date(data);

                                if (isValid(nextWorkingDate)) {
                                    setScheduleDateAndTime(nextWorkingDate, 8);
                                } else {
                                    Log.error(
                                        `Invalid date received for next working day: ${data}`,
                                    );

                                    setScheduleDateAndTime(null, null);
                                    showScheduleErrorToast();
                                }
                            } else {
                                setScheduleDateAndTime(null, null);
                                showScheduleErrorToast();
                            }
                            setIsPopoverOpen(false);
                        }}
                    >
                        Next working day at 8am
                    </PopoverItemButton>
                    <PopoverItemButton
                        active={activeOption === "custom-date"}
                        onClick={() => {
                            setActiveOption("custom-date");
                            setModalOpen(true);
                        }}
                    >
                        Custom date and time
                    </PopoverItemButton>
                </PopoverContent>
            </Popover>
        </>
    );
}

function showScheduleErrorToast() {
    toast(
        <Feedback
            title="Unable to schedule batch message for next working day"
            colour="error"
        >
            <Text skinny>
                Try again later or schedule the message to send now
            </Text>
        </Feedback>,
        { autoClose: false },
    );
}
