import { Option } from "@accurx/design";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";

export function getResponsibleClinicianFilterOptions(
    clinics: IntegratedClinic[],
): Option[] {
    const clinicians = new Set<string>();

    clinics.forEach(({ responsibleClinicians }) => {
        if (responsibleClinicians && responsibleClinicians.length) {
            responsibleClinicians.forEach((clinician) => {
                if (clinician) {
                    clinicians.add(clinician);
                }
            });
        }
    });

    const sortedValues = [...clinicians].sort();
    const options = sortedValues.map((value) => ({ value, label: value }));

    return options;
}
