import { UnreadSummary } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { mapConversationItemIdentityToConversationItemId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationItemIdentityToConversationItemId";
import { mapConversationServerIdToConversationId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationServerIdToConversationId";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

const mapUnreadSummary = (
    summary: UnreadSummary,
): UnreadItemsSummary | undefined => {
    let assignee: UnreadItemsSummary["assignee"];

    if (summary.assignedUserId) {
        assignee = { type: "User", id: summary.assignedUserId };
    } else if (summary.assignedGroupId) {
        assignee = { type: "Team", id: summary.assignedGroupId };
    } else {
        Log.error("Received an unread summary with no assignee", {
            tags: { product: "Inbox", conversationId: summary.conversationId },
        });
        return undefined;
    }

    return {
        conversationId: mapConversationServerIdToConversationId(
            summary.conversationId,
        ),
        assignee,
        status: "Open",
        system: "ClinicianMessaging",
        latestToken: summary.conversationLastUpdated,
        patientId: summary.patientId ?? null,
        itemIds: summary.itemIds.map(
            mapConversationItemIdentityToConversationItemId,
        ),
    };
};

export const getUnreadSummaries = async (arg: {
    workspaceId: number;
}): Promise<UnreadItemsSummary[]> => {
    const response = await ClinicianMessagingApiClient.fetchUnreadSummary(arg);

    const result: UnreadItemsSummary[] = [];

    for (const summary of response.unreadSummaries) {
        const mapped = mapUnreadSummary(summary);

        if (mapped) {
            result.push(mapped);
        }
    }

    return result;
};
