import { useCurrentWorkspace } from "@accurx/auth";
import { SubscriptionEvent } from "domains/native/types";

import { NativeApi } from "../api/NativeApi";
import { NativeTransportError } from "../errors";
import { useNativeTransport } from "./useNativeTransport";

// A hook to subscribe to a SignalR method via the native bridge
export const useSubscribeSignalRInvocations = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return ({
        methodName,
        onEvent,
    }: {
        methodName: string;
        onEvent: (payload: SubscriptionEvent) => void;
    }) => {
        if (!transport) {
            throw new NativeTransportError(
                "useSubscribeSignalRInvocations: No native transport",
            );
        }

        return NativeApi.subscribe({
            transport,
            request: {
                type: "SubscribeSignalRInvocations",
                workspaceId,
                payload: {
                    methodName,
                },
            },
            onEvent,
        });
    };
};
