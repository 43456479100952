import { useLocation } from "react-router";

import { ConversationStatus } from "shared/concierge/conversations/types/conversation.types";
import { sentenceCase } from "shared/formatters/String";

export const useConversationGroupStatus = (): ConversationStatus => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    return getStatusFromQueryParam(query.get("status"));
};

export const getStatusFromQueryParam = (
    statusQueryParam: string | null,
): ConversationStatus => {
    const status = sentenceCase(statusQueryParam ?? "");
    return isValidStatusFilter(status) ? status : "Open";
};

const isValidStatusFilter = (status: string): status is ConversationStatus => {
    return status === "Open" || status === "Done";
};
