import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { PatientSummary } from "domains/concierge/types";

export const demographicsUpdates = (
    state: Draft<ConciergeState["patients"]>,
    action: PayloadAction<{ patient: PatientSummary; time?: string }>,
) => {
    const patients = state.items;
    const { patient } = action.payload;

    patients[patient.patientId] = {
        ...patient,
    };

    return state;
};
