import { Text, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

type IsCollapsed = boolean;

const StyledNavigationMenu = styled.div`
    display: flex;
    max-width: 100vw;
    overflow-x: auto;
    height: 100%;
    max-height: 100vh;
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: inset -1px 0 0 ${Tokens.COLOURS.greyscale.silver};
`;

const StyledLinkList = styled.ul`
    width: 100%;
    display: grid;
    list-style-type: none;
    padding: 0;
    margin: ${Tokens.SIZES[1]} 0 0 0;
    // need the direct child of the list item to be full width, but because
    // the direct child of the item might be a 3rd party thing, e.g. Tooltip
    // we need to set it from "above"
    > li > span {
        width: 100%;
    }
    > *:not(:last-child) {
        margin-bottom: ${Tokens.SIZES[1]};
    }
`;

const transitionStyles = css`
    max-width: ${({ isCollapsed = false }: { isCollapsed?: boolean }) =>
        isCollapsed ? "0px" : "120px"};
    transition: all 0.3s ease-in-out;
    transition-property: margin, max-width;
`;

const truncatedTextStyles = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledCollapsibleText = styled(Text)<{
    isCollapsed?: IsCollapsed;
}>`
    margin: ${({ isCollapsed }) =>
        `${Tokens.SIZES[0.5]} ${isCollapsed ? 0 : ""}`};

    ${truncatedTextStyles} ${transitionStyles};
`;

export {
    StyledNavigationMenu,
    StyledLinkList,
    StyledCollapsibleText,
    truncatedTextStyles,
    transitionStyles,
};

export type { IsCollapsed };
