import { Participant } from "domains/concierge/types";
import uniqBy from "lodash/uniqBy";

/**
 * Merges in updates to a list of conversation participants. For
 * duplicates we favour the newer participants over the older participant.
 * It is up to the caller to determine which is "newer" and which is "older".
 *
 * @param older
 * @param newer
 * @returns a deduped list of conversation participants
 */
export const mergeConversationParticipants = (
    older: Participant[] | undefined,
    newer: Participant[],
): Participant[] => {
    const allItems = older ? [...newer, ...older] : newer;
    return uniqBy(
        allItems,
        (p) =>
            `${p.type}-${p.type === "Workspace" ? p.workspaceId : ""}-${
                p.type === "User" ? p.userId : ""
            }-${p.type === "Contact" ? p.contactId : ""}`,
    );
};
