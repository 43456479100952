import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { PracticeDetails } from "../Practices.types";
import { PatientTriage } from "./PatientTriage";
import * as actions from "./PatientTriage.actions";

const mapStateToProps = (state: ApplicationState) => {
    const practice = state.practices.items.find(
        (n: PracticeDetails) =>
            n.id.toString() === state.practices.selectedPractice,
    );
    return {
        accountState: state.account,
        analyticsState: state.sessionAnalytics,
        practices: state.practices,
        practiceId: state.practices.selectedPractice,
        practiceName: practice?.name || "",
        loadingStatus: state.patientTriage.loadingStatus,
        updatingStatus: state.patientTriage.updatingStatus,
        triageSettingsUpdatingStatus:
            state.patientTriage.triageSettingsUpdatingStatus,
        status: state.patientTriage.status,
        features: practice?.features || [],
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export const PatientTriageContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientTriage);

export default PatientTriageContainer;
