import { FormEvent } from "react";

import { BranchId, BranchPosition, QuestionLevel } from "../types/data.types";
import { MeasurementFormElement } from "./MeasurementTypeSelector";

export enum SupportedQuestionType {
    MultipleChoice = "MultipleChoice",
    Measurement = "Measurement",
    FreeText = "FreeText",
    Branch = "Branch",
    PhotoUpload = "PhotoUpload",
    Information = "Information",
}

export type HandleSelectQuestionType = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    questionLevel: QuestionLevel;
    questionType: SupportedQuestionType;
};

export type HandleSaveMeasurmentType = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    event: FormEvent<MeasurementFormElement>;
    questionLevel: QuestionLevel;
};

export type HandleCloseQuestionSelectCard = {
    branchPosition?: BranchPosition;
    questionLevel: QuestionLevel;
};
