import React, { useState } from "react";

import {
    Button,
    Flex,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@accurx/design";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    getSessionStorageItem,
    setSessionStorageItem,
} from "shared/storage/sessionStorageHelper";
import { useAppSelector, useCurrentUserId } from "store/hooks";

import { StyledWrapper } from "./TurnIntoTemplateButton.styles";

const TURN_INTO_TEMPLATE_COOKIE = "turn_to_template";

export const TURN_INTO_TEMPLATE = "Turn into template";

export type TurnIntoTemplateButtonProps = {
    onClick: () => void;
    id: string;
    isFirstMessageByUser: boolean;
};

export const TurnIntoTemplateButton = ({
    onClick: showTemplateForm,
    id,
    isFirstMessageByUser,
}: TurnIntoTemplateButtonProps) => {
    const isFirstTimeUser =
        useAppSelector(
            ({ account }) => account.user?.onboarding?.isFirstTimeUser,
        ) || false;

    const loggedInProps = useFlemingLoggedInAnalytics();
    const currentUserId = useCurrentUserId();
    const [open, setOpen] = useState(true);

    const hasUserClickedPopoverActions =
        getSessionStorageItem(
            `${TURN_INTO_TEMPLATE_COOKIE}-${currentUserId}`,
        ) || false;

    // is only displayed for a first time user who has not dismissed the tooltip
    // and only on the first message they've sent in a new conversation (so it doesn't appear multiple times,
    // or in inbox conversations they haven't participated in
    // as we want to target users with this just after they send their first message
    const showPopover =
        isFirstTimeUser &&
        !hasUserClickedPopoverActions &&
        isFirstMessageByUser;

    const setCookieValue = () => {
        setSessionStorageItem(
            `${TURN_INTO_TEMPLATE_COOKIE}-${currentUserId}`,
            "0",
        );
    };

    const handleOnPopoverTriggerClick = () => {
        setOpen((value) => !value);
    };

    const handleTurnIntoTemplateClick = () => {
        FlemingAnalyticsTracker.trackTemplateCreateStartButtonClick({
            ...loggedInProps,
            hasError: false,
            pageOrigin: "WebInboxConversationThread",
        });
        showTemplateForm();
        setCookieValue();
        setOpen(false);
    };

    const handleNotNowClick = () => {
        setCookieValue();
        setOpen(false);
    };

    if (showPopover) {
        return (
            <Popover open={open}>
                <PopoverTrigger asChild>
                    <Button
                        id={`btn-popover-trigger-template-${id}`}
                        theme="secondary"
                        dimension="medium"
                        onClick={handleOnPopoverTriggerClick}
                        text={TURN_INTO_TEMPLATE}
                    />
                </PopoverTrigger>
                <PopoverContent align="center" side="left" width="280px">
                    <StyledWrapper>
                        <Flex flexDirection={"column"}>
                            <Text variant="label">Want to save time?</Text>
                            <Text variant="body">
                                Turn a message into a template to send the same
                                content to any patient.
                            </Text>
                            <Flex justifyContent={"space-between"}>
                                <Button
                                    id={`btn-popover-content-primary-${id}`}
                                    theme="primary"
                                    onClick={handleTurnIntoTemplateClick}
                                    text={TURN_INTO_TEMPLATE}
                                    dimension="medium"
                                />
                                <Button
                                    btn-popover-content-primary
                                    id={`btn-popover-content-secondary-${id}`}
                                    theme="secondary"
                                    onClick={handleNotNowClick}
                                    text={"Not now"}
                                    dimension="medium"
                                />
                            </Flex>
                        </Flex>
                    </StyledWrapper>
                </PopoverContent>
            </Popover>
        );
    }

    return (
        <Button
            id={`btn-turn-into-template-${id}`}
            theme="secondary"
            onClick={handleTurnIntoTemplateClick}
            text={TURN_INTO_TEMPLATE}
            dimension="medium"
        />
    );
};
