import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";
import { userflowIds } from "domains/compose/userflow";

type BrowseTemplatesButtonProps = {
    onClick: () => void;
};

export const BrowseTemplatesButton = ({
    onClick,
}: BrowseTemplatesButtonProps) => {
    const { state } = useCompose();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const canUseQuestionnaires = state.canUseQuestionnaires;

    return (
        <Pill.Button
            radius="full"
            onClick={() => {
                onClick();
                track("TemplateBrowse Button Click", {
                    ...nativeTrackingFields,
                    eventVersion: 2,
                    conversationParticipant: state.conversationParticipant,
                });
            }}
            aria-label={
                canUseQuestionnaires
                    ? "Select a message template or questionnaire"
                    : "Select a message template"
            }
            data-userflow-id={userflowIds.templatesButton}
        >
            <Pill.Icon
                name="Search"
                colour="blue"
                theme="Line"
                props={{
                    "aria-hidden": true,
                }}
            />
            <UI.Text skinny variant="preview">
                {canUseQuestionnaires
                    ? "Templates and questionnaires"
                    : "Templates"}
            </UI.Text>
        </Pill.Button>
    );
};
