import { Action, Reducer } from "redux";

import { useAppSelector } from "../../store/hooks";
import {
    ACCOUNT_LOGGED_IN,
    ACCOUNT_LOGGED_OUT,
    KnownAction as LoginKnownAction,
} from "../account/AccountActions";
import {
    KnownAction,
    SET_TWO_FACTOR_IS_AUTHENTICATED,
} from "./TwoFactorActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// -----------------
export interface TwoFactorReducerState {
    isAuthenticated: boolean;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: TwoFactorReducerState = {
    isAuthenticated: false,
};

export const reducer: Reducer<TwoFactorReducerState> = (
    state = initialState,
    incomingAction: Action,
) => {
    const loginAction = incomingAction as LoginKnownAction;
    if (loginAction.type === ACCOUNT_LOGGED_IN) {
        return {
            ...state,
            isAuthenticated: loginAction.is2FAed,
        };
    }
    if (loginAction.type === ACCOUNT_LOGGED_OUT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SET_TWO_FACTOR_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.value,
            };
    }

    return state;
};

export const useIsTwoFactorAuthenticated = (): boolean =>
    useAppSelector((state) => !!state.twoFactor?.isAuthenticated);
