import { DateHelpers } from "@accurx/shared";
import { PatientSummary } from "domains/concierge/types";

export function mapDateOfBirthToPatientSummaryAgeParts(
    dateOfBirth?: string | null,
): Pick<PatientSummary, "ageYear" | "dateOfBirth"> {
    const dob = dateOfBirth ?? "";
    const patientAge = dob ? DateHelpers.getAgeFromDate(dob) : NaN;

    return {
        dateOfBirth: dob ?? "Unspecified",
        ageYear: patientAge,
    };
}
