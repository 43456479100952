import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const Outer = styled.div`
    border: solid 1px ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: ${Tokens.SHADOWS.raised.medium.silver};
`;

export const Header = styled.div`
    padding: ${Tokens.SIZES["2"]};
`;

export const Body = styled.div`
    overflow-x: auto;

    // Ensure borders of the table don't add up to the outer borders of the DASHBOARD
    tr th:first-child,
    tr td:first-child {
        border-left: 0;
    }

    tr th:last-child,
    tr td:last-child {
        border-right: 0;
    }
`;

export const Footer = styled.div`
    padding: ${Tokens.SIZES["2"]};
`;

export const EmptyBody = styled.div`
    padding: ${Tokens.SIZES["2"]};
    padding-top: 0;
`;

export const StyledDashboard = {
    Outer,
    Header,
    Body,
    EmptyBody,
    Footer,
};
