import { useMarkAsUnreadMutation } from "@accurx/concierge/hooks/mutations/useMarkAsUnreadMutation";
import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useViewportSize } from "domains/inbox/hooks/util/";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { toast } from "react-toastify";

import { StyledUnreadLabelText } from "./MarkAsUnreadActionButton.styles";

export type MarkAsUnreadActionButtonProps = {
    conversation: Conversation;
    onMarkAsUnreadSuccess: (() => void) | undefined;
};

export const MarkAsUnreadActionButton = ({
    conversation,
    onMarkAsUnreadSuccess,
}: MarkAsUnreadActionButtonProps) => {
    const size = useViewportSize();
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: "ConversationActions",
    });
    const shouldShowLabels = size === "sm" || size === "md";
    const shouldShowTooltip = size !== "xs" && !shouldShowLabels;

    const markAsUnreadMutation = useMarkAsUnreadMutation(
        {
            patientId: conversation.regardingPatientId,
        },
        {
            onSuccess: () => {
                analytics.trackConversationActionButtonResponse(
                    "Unread",
                    false,
                );
                toast(
                    <UI.Feedback
                        colour="success"
                        title="Conversation marked unread for you"
                    />,
                );
                onMarkAsUnreadSuccess?.();
            },
            onError: () => {
                analytics.trackConversationActionButtonResponse("Unread", true);
                toast(
                    <UI.Feedback
                        title="Sorry, we were unable to mark this conversation
            as unread. Please try again."
                        colour="error"
                    />,
                );
            },
        },
    );

    const onClick = () => {
        analytics.trackConversationActionButtonClick("Unread");
        markAsUnreadMutation.mutate({ conversation });
    };

    return (
        <WithTooltip
            isEnabled={shouldShowTooltip}
            content={
                <UI.Text skinny variant="note" colour="white">
                    Mark unread
                </UI.Text>
            }
        >
            <Pill.Button
                aria-label="Mark conversation as unread"
                radius="full"
                onClick={onClick}
                disabled={markAsUnreadMutation.isLoading}
            >
                {markAsUnreadMutation.isLoading && <Pill.Icon isLoading />}
                {!markAsUnreadMutation.isLoading && (
                    <>
                        <Pill.Icon name="Unread" theme="Line" colour="blue" />
                        {shouldShowLabels && (
                            <StyledUnreadLabelText>
                                Mark unread
                            </StyledUnreadLabelText>
                        )}
                    </>
                )}
            </Pill.Button>
        </WithTooltip>
    );
};
