import { ReactNode } from "react";

import { Text } from "@accurx/design";
import { DropdownItem } from "reactstrap";

export interface DropdownMenuOptionProps {
    children?: ReactNode;
    /** [Optional] Whether the item in the dropdown is active
     * Defaults to `false`
     * */
    isActive?: boolean;
    /** Click handler */
    onClick: () => void;
}

const DropdownMenuOption = ({
    isActive = false,
    onClick,
    children,
}: DropdownMenuOptionProps): JSX.Element | null => {
    if (!children) {
        console.error(
            "You must provide at least one child for DropdownMenuOption component",
        );
        return null;
    }

    return (
        <DropdownItem
            active={!!isActive}
            className="select-dropdown-item text-truncate px-2"
            onClick={onClick}
        >
            <Text skinny as="span" variant={isActive ? "label" : "body"}>
                {children}
            </Text>
        </DropdownItem>
    );
};

export default DropdownMenuOption;
