import React from "react";

import { Card, Ds, Icon, Text } from "@accurx/design";
import { Link } from "react-router-dom";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

export type PracticeLinkCardProps = {
    title: string;
    section: PracticeLinkSection;
    description: string;
    badgeCopy?: string; // ie: "Pending Requests", "New"
};

export type PracticeLinkProps = {
    url: string;
    onClickEvent?: () => void; //ie: for analytics
} & PracticeLinkCardProps;

export enum PracticeLinkSection {
    DESKTOP = "Desktop",
    PATIENT_TRIAGE = "PatientTriage",
    RECORD_VIEW = "RecordView",
    SELF_BOOK = "SelfBook",
    UNAPPROVED = "Unapproved",
    VACCINE = "Vaccine",
    YOUR_ORG = "YourOrg",
}

const PracticeLinkCard = ({
    title,
    description,
    badgeCopy,
}: PracticeLinkCardProps) => (
    <Card props={{ className: "mb-3" }}>
        <div className="d-flex justify-content-between">
            <div>
                <Text as="span" variant="label" skinny>
                    {title}
                </Text>
                {badgeCopy && (
                    <Ds.Badge color="purple" radius="round" className="ml-2">
                        {badgeCopy}
                    </Ds.Badge>
                )}
                <Text skinny>{description}</Text>
            </div>
            <div className="ml-2">
                <Icon
                    name="Arrow"
                    size={3}
                    rotation="right"
                    halo={{ colour: "blue", luminosity: "high" }}
                />
            </div>
        </div>
    </Card>
);

export const PracticeLink = ({
    url,
    title,
    section,
    description,
    badgeCopy,
    onClickEvent,
}: PracticeLinkProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const trackClickEvent = () => {
        const analyticsProps: ChainAnalyticsTracker.OrganisationManagementItemProps =
            {
                ...analyticsLoggedInProps,
                objectName: AnalyticsMapper.mapToPascalCase(section, title),
            };

        ChainAnalyticsTracker.trackOrganisationManagementItemLinkClick(
            analyticsProps,
        );
    };

    const handleClick = () => {
        trackClickEvent();
        return onClickEvent ?? undefined;
    };

    // MERGE_CHAIN_FLEMING
    // This is just temporary while we are waiting to migrate MessageTemplates page
    // ie: "practices/3/MessageTemplates"
    if (url.includes("/MessageTemplates")) {
        return (
            <Text
                as="a"
                props={{
                    href: `${url}`,
                    className: "d-block",
                    onClick: handleClick,
                }}
            >
                <PracticeLinkCard
                    title={title}
                    section={section}
                    description={description}
                    badgeCopy={badgeCopy}
                />
            </Text>
        );
    }

    // Handle external link (ie: support article)
    if (url.includes("https://")) {
        return (
            <Text
                as="a"
                props={{
                    href: url,
                    target: "_blank",
                    rel: "noopener noreferrer",
                    className: "d-block",
                    onClick: handleClick,
                }}
            >
                <PracticeLinkCard
                    title={title}
                    section={section}
                    description={description}
                    badgeCopy={badgeCopy}
                />
            </Text>
        );
    }

    return (
        <Link to={url} onClick={handleClick} className="d-block">
            <PracticeLinkCard
                title={title}
                section={section}
                description={description}
                badgeCopy={badgeCopy}
            />
        </Link>
    );
};
