import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[3]};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
`;

export const StyledText = styled(Text)`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
`;

export const StyledTemplateContentCard = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    padding: ${Tokens.SIZES[2]};
    white-space: pre-wrap;
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;
