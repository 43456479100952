import { shallowEqual } from "react-redux";

import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { PatientDetails } from "../types";

type PatientFromStateStatus =
    | {
          status: "loading" | "unavailable";
          patient?: undefined;
          token?: undefined;
      }
    | { status: "success"; patient: PatientDetails; token: string };

const toPatientDetails = (
    patient: NonNullable<ReturnType<typeof PatientHelper.getPatient>>,
): PatientDetails => ({
    ...patient,
    firstName: patient.firstName || undefined,
    lastName: patient.familyName || undefined,
    practiceName: patient.practiceName || undefined,
    practiceCode: patient.practiceCode || undefined,
    prefixName: patient.prefixName || undefined,
    nhsNumber: patient.nhsNumber || undefined,
    dateOfBirth: patient.dateOfBirth || undefined,
    gender: patient.gender || undefined,
});

export const usePatientDetailsFromState = () =>
    useAppSelector(({ searchForPatient }): PatientFromStateStatus => {
        // We need to check session storage for a patient token, as the redux store will not immediately contain the patient search information (it fetches it from session storage when we visit a new page)
        // If there is no patient token in session storage, this means no patient search has happened, so we want to redirect the user to the patient search page
        const token = PatientHelper.getPatientTokenWithStorageFallback(null);
        if (!token) {
            return { status: "unavailable" };
        }

        const patient = PatientHelper.getPatient(searchForPatient.lastResponse);

        // If we have a patient token in session storage, but no patient in the redux store, wait for it to load
        if (!patient) {
            return { status: "loading" };
        }

        return {
            status: "success",
            patient: toPatientDetails(patient),
            token,
        };
    }, shallowEqual);
