import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPopoverContent = styled(UI.PopoverContent)`
    width: calc(100vw - calc(${UI.Tokens.SIZES[2]} * 2));
    height: 100%;
    // Account for border size too
    max-height: calc(71vh + 2px);

    transition: max-height 1.15s ease-out;

    // override PopoverContent padding
    padding: 0px;
    border: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};

    > ul {
        height: 100%;
        max-height: 71vh;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        width: 405px;
    }
`;

export const StyledErrorContainer = styled.div`
    padding: ${UI.Tokens.SIZES[2]} 0 ${UI.Tokens.SIZES[5]} 0;
`;
