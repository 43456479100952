import { BasicUserResponse } from "@accurx/api/account";
import { Avatar, Flex, Icon, Text } from "@accurx/design";

import {
    StyledBody,
    StyledContainer,
    StyledHeader,
    StyledLegend,
    StyledMember,
} from "./WorkspaceQuickView.styles";

export const WorkspaceQuickView = ({
    name,
    description,
    users,
}: {
    name: string;
    description?: Optional<string>;
    users: BasicUserResponse[];
}) => {
    return (
        <StyledContainer>
            <StyledHeader>
                <Text as="h2" variant="subtitle" skinny>
                    {name}
                </Text>
                {description && <Text variant="preview">{description}</Text>}
            </StyledHeader>
            <StyledBody>
                <StyledLegend>
                    <Flex gap="1">
                        <Icon
                            name="Person"
                            halo={{ colour: "blue" }}
                            size={4}
                        />
                        <Flex flexDirection="column">
                            <Text as="h3" variant="label">
                                Approved Members · {users.length}
                            </Text>
                        </Flex>
                    </Flex>
                </StyledLegend>
                <Flex flexDirection="column" gap="1">
                    {users.map(({ fullName, email }) => (
                        <StyledMember key={email}>
                            <Avatar
                                name={fullName ?? undefined}
                                colour="grey"
                            />
                            <div>
                                {fullName && (
                                    <Text variant="label" skinny>
                                        {fullName}
                                    </Text>
                                )}
                                <Text variant="preview" skinny>
                                    {email}
                                </Text>
                            </div>
                        </StyledMember>
                    ))}
                </Flex>
            </StyledBody>
        </StyledContainer>
    );
};
