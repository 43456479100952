import React from "react";

import { Icon, Text } from "@accurx/design";

import { PatientDetails } from "../types";
import {
    StyledRecipientAvatarContainer,
    StyledRecipientSection,
    StyledRecipientStackPanel,
} from "./RecipientSection.styles";

type RecipientSectionProps = {
    patient: PatientDetails;
};

export const RecipientSection = ({
    patient,
}: RecipientSectionProps): JSX.Element => {
    const { practiceName, practiceCode } = patient;
    return (
        <StyledRecipientSection>
            <Text skinny>To: </Text>
            <StyledRecipientStackPanel
                orientation="horizontal"
                verticalContentAlignment="center"
            >
                <StyledRecipientAvatarContainer>
                    <Icon
                        size={3}
                        name="Team"
                        halo={{
                            colour: "greyscale",
                            luminosity: "high",
                        }}
                    />
                </StyledRecipientAvatarContainer>
                <Text variant="note">
                    {practiceName ||
                        `Organisation with ODS code ${practiceCode}`}
                </Text>
            </StyledRecipientStackPanel>
        </StyledRecipientSection>
    );
};
