import { useContext, useEffect, useState } from "react";

import { shallowEqual, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { ClinicianMessage } from "api/ClinicianConversationApi";
import { FileUploadState } from "app/fileUpload/FileUploadReducer";
import { findBaseRoute, getStringQuery } from "shared/RoutesHelper";

import { usePreviousState } from "../../../shared/usePrevious";
import { useAppSelector } from "../../../store/hooks";
import { actionCreators as fileUploadActionCreators } from "../../fileUpload/FileUploadActions";
import {
    addMessageToMessages,
    addPendingMessage,
    createPendingMessage,
    getParticipantsCountForTracking,
    groupByDate,
    hasNewMessageHelper,
    updatePendingMessages,
} from "../clinicianConversation.helper";
import {
    CopyMessageFunction,
    copyMessageWithContext,
} from "../copyMessageWithContext";
import { ClinicianConversationContext } from "../providers";
import {
    PendingMessage,
    SendMessageArgs,
    Template,
    UseClinicianConversationReturnOptions,
} from "../types";
import {
    Origin,
    useClinicianConversationAnalytics,
} from "./useClinicianConversationAnalytics";

export type LocationState = { appOrigin?: Origin; patientOrigin: string };

export const useClinicianConversation =
    (): UseClinicianConversationReturnOptions => {
        const history = useHistory();
        const pathname = history.location.pathname;
        const { state, search } = useLocation<{
            appOrigin?: Origin;
            patientOrigin?: string;
        }>();

        const patientOrigin =
            state?.patientOrigin || findBaseRoute(pathname) || "";

        const emailReferrer = getStringQuery(search, "emailReferrer");

        const origin: Origin | undefined = emailReferrer
            ? "Email"
            : state?.appOrigin || undefined;

        // show compose button and message patient banner after send message(for first time user)
        const user = useAppSelector(
            ({ account }) => account.user,
            shallowEqual,
        );
        const isFirstTimeUser = user?.onboarding?.isFirstTimeUser || false;
        const [showComposeArea, setShowComposeArea] = useState(true);
        const context = useContext(ClinicianConversationContext);

        if (context === null) {
            throw new Error(
                "useClinicianConversation must be used inside an appropriate conversation provider",
            );
        }

        const {
            urlId,
            state: conversationState,
            messages: contextMessages,
            conversationId,
            participants,
            userEmailAddress,
            sendMessage,
            patient,
            subject,
            workspaceId,
            hasPolled,
            isNewConversation,
        } = context;

        const {
            trackPageViewSuccess,
            trackPageViewFailure,
            trackSendMessageClick,
            trackMessageCopy,
            trackBackButtonClick,
            trackIntercomReplyFromWebSent,
        } = useClinicianConversationAnalytics({
            appOrigin: origin,
            patientOrigin,
            search,
            urlId: urlId,
            emailReferrer: emailReferrer || "",
        });

        const userDisplayName =
            useAppSelector(({ account }) => account.user, shallowEqual)
                ?.fullName || "";
        const dispatch = useDispatch();

        const [prevMessages, messages, setMessages] = usePreviousState<
            ClinicianMessage[] | null
        >(null);
        const [prevPendingMessages, pendingMessages, setPendingMessages] =
            usePreviousState<PendingMessage[]>([]);

        const hasNewMessage = hasNewMessageHelper(
            prevMessages ?? [],
            messages ?? [],
            prevPendingMessages ?? [],
            pendingMessages,
        );

        useEffect(() => {
            if (conversationState === "fetched" && messages === null) {
                trackPageViewSuccess({
                    clinicianConversationId: conversationId ?? 0,
                    countParticipants: getParticipantsCountForTracking({
                        individualParticipants:
                            participants.individualParticipants,
                        workspaces: participants.participantWorkspaces,
                    }),
                });
            }
        }, [
            conversationState,
            participants,
            messages,
            conversationId,
            trackPageViewSuccess,
            prevMessages,
        ]);

        useEffect(() => {
            if (contextMessages) {
                setMessages(contextMessages);
            }
        }, [contextMessages, setMessages]);

        if (conversationState === "loading") {
            return { state: "loading", isNewConversation };
        }

        if (conversationState === "error") {
            trackPageViewFailure();
            return {
                errorText: context.error,
                state: "error",
                isNewConversation,
            };
        }

        if (conversationState === "redirect") {
            return {
                state: "redirect",
                redirectTo: context.redirectTo,
                isNewConversation,
            };
        }

        const handleCopyMessage: CopyMessageFunction = async (message) => {
            trackMessageCopy(message.sentByCurrentUser);
            return await copyMessageWithContext(message, {
                subject: subject,
                participantWorkspaces: participants.participantWorkspaces,
                individualParticipants: participants.individualParticipants,
            });
        };

        const allMessagesByDate = groupByDate([
            ...(messages ?? []),
            ...pendingMessages,
        ]);

        const onSendMessage = async ({
            messageDetails,
            tempMessageId,
            trackingProperties,
        }: {
            tempMessageId: string;
            messageDetails: SendMessageArgs;
            trackingProperties: {
                numberOfAttachments: number;
                template?: Template;
                usedKeyboardShortcut: boolean;
            };
        }) => {
            const { success, result } = await sendMessage({
                ...messageDetails,
                metadata: {
                    isPresetTemplate: trackingProperties.template !== undefined,
                    templateLevel:
                        trackingProperties.template !== undefined
                            ? "Accurx"
                            : null,
                    templateGroup: trackingProperties.template?.heading ?? null,
                    templateName: trackingProperties.template?.title ?? null,
                },
            });
            let hasError = false;

            if (success && result !== null) {
                setPendingMessages(
                    updatePendingMessages(tempMessageId, "sent"),
                );

                setMessages((currentMessages) =>
                    "messages" in result
                        ? result.messages
                        : addMessageToMessages({
                              prevMessages: currentMessages ?? [],
                              message: result.message,
                          }),
                );

                // TODO: can tidy this up when we add support for RfW
                const workspaceConversationId =
                    "workspaceConversationId" in result
                        ? result.workspaceConversationId
                        : null;
                const urlId = "urlId" in result ? result.urlId : null;

                context.onSendFn(
                    { workspaceConversationId, urlId },
                    history.location.pathname,
                );

                if (isFirstTimeUser) {
                    setShowComposeArea(false);
                }

                if (emailReferrer === "InitialNotification") {
                    trackIntercomReplyFromWebSent();
                }
            } else {
                setPendingMessages(
                    updatePendingMessages(tempMessageId, "failed"),
                );
                hasError = true;
            }

            trackSendMessageClick &&
                trackSendMessageClick({
                    clinicianConversationId: conversationId ?? 0,
                    countParticipants: getParticipantsCountForTracking({
                        individualParticipants:
                            participants.individualParticipants,
                        workspaces: participants.participantWorkspaces,
                        messageSender: userEmailAddress,
                    }),
                    hasError: hasError,
                    countAttachments: trackingProperties.numberOfAttachments,
                    template: trackingProperties.template,
                    numberOfPreviousMessages: messages?.length ?? 0,
                    usedKeyboardShortcut:
                        trackingProperties.usedKeyboardShortcut,
                });
        };

        const onClickRetry = async (messageId: string): Promise<void> => {
            // creating an empty file upload state for now, since we don't retry messages with attachments

            const messageToResend = pendingMessages.find(
                ({ tempMessageId }) => {
                    return tempMessageId === messageId;
                },
            );

            // This should never happen, but in the case that the message we're trying to resent isn't present, we can't retry
            if (!messageToResend) {
                return;
            }
            // set to pending to show loading status while retrying
            setPendingMessages(updatePendingMessages(messageId, "sending"));

            const attachedFiles = messageToResend.attachments;

            await onSendMessage({
                tempMessageId: messageId,
                messageDetails: {
                    messageBody: messageToResend.body,
                    attachedFiles: attachedFiles,
                },
                trackingProperties: {
                    numberOfAttachments: attachedFiles.length,
                    template: messageToResend.template,
                    usedKeyboardShortcut: false,
                },
            });
        };

        const onClickSend = async (
            messageBody: string,
            fileUploadState: FileUploadState,
            usedKeyboardShortcut: boolean,
            template: Template | undefined = undefined,
        ): Promise<void> => {
            const message = createPendingMessage({
                body: messageBody,
                attachments: fileUploadState.files.map((f) => ({
                    id: f.id,
                    displayName: f.file.name,
                    previewUrl: f.file.previewUrl,
                })),
                senderDisplayName: userDisplayName,
                senderEmailAddress: userEmailAddress,
                template,
            });
            setPendingMessages(addPendingMessage(message));

            dispatch(fileUploadActionCreators.resetFileUpload());

            await onSendMessage({
                messageDetails: {
                    messageBody,
                    attachedFiles: fileUploadState.files.map(
                        ({ id, file }) => ({
                            id,
                            displayName: file.name,
                            previewUrl: file.previewUrl,
                        }),
                    ),
                },
                tempMessageId: message.tempMessageId,
                trackingProperties: {
                    numberOfAttachments: fileUploadState.files.length,
                    template,
                    usedKeyboardShortcut: usedKeyboardShortcut,
                },
            });
        };

        return {
            state: "success",
            conversation: {
                userEmailAddress,
                patient,
                subject,
                hasNewMessage,
                participants,
                messages: allMessagesByDate,
                onClickSend: onClickSend,
                onCopyMessage: handleCopyMessage,
                onClickRetry: onClickRetry,
            },
            workspaceId,
            hasPolled,
            isNewConversation: isNewConversation,
            trackBackButtonClick,
            showComposeArea: showComposeArea,
            setShowComposeArea: setShowComposeArea,
        };
    };
