import { CSSObject } from "highcharts";

import { BarchartProps } from "./Graph.barchart.types";
import { HeatmapProps } from "./Graph.heatmap.types";

export enum GraphType {
    Column = "column",
    Heatmap = "heatmap",
}

export type GraphOptions = {
    /**Options for the tooltip that appears when the user hovers over a series or point. */
    tooltip: {
        /**Enable or disable the tooltip. */
        enabled: boolean;
    };
    /**The X axis or category axis. Normally this is the horizontal axis,
     * though if the chart is inverted this is the vertical axis. In case of multiple axes, the xAxis node is an array of configuration objects */
    xAxis?: {
        /**Whether to display the axis on the opposite side of the normal. The normal is on the left side for vertical axes and bottom for horizontal,
         *  so the opposite sides will be right and top respectively. This is typically used with dual or multiple axes. */
        opposite?: boolean;
        /**The width of the grid lines extending the ticks across the plot area */
        gridLineWidth?: number;
        /**The width of the line marking the axis itself. */
        lineWidth?: number;
        /**
         * Whether to allow decimals in this axis' ticks.
         * When counting integers, like persons or hits on a web page,
         * decimals should be avoided in the labels.
         * By default, decimals are allowed on small scale axes.
         * */
        allowDecimals?: boolean;
        min?: number;
        startOnTick?: boolean;
        /**
         * The angle determines the position of the axis line and the labels,
         * though the coordinate system is unaffected
         */
        labels?: {
            align?: "left" | "center" | "right";
            rotation?: number;
            style?: CSSObject;
        };
    };
    yAxis?: {
        /**The width of the grid lines extending the ticks across the plot area */
        gridLineWidth?: number;
        /**The width of the line marking the axis itself. */
        lineWidth?: number;
        /**
         * Whether to allow decimals in this axis' ticks.
         * When counting integers, like persons or hits on a web page,
         * decimals should be avoided in the labels.
         * By default, decimals are allowed on small scale axes.
         * */
        allowDecimals?: boolean;
    };
    colorAxis: {
        /**The minimum value of the axis in terms of map point values.  */
        min: number;
        /**The color to represent the minimum of the color axis. */
        minColor: string;
        /**The color to represent the maximum of the color axis. */
        maxColor: string;
    };
    legend: {
        /**Enable or disable the legend.  */
        enabled: boolean;
    };
    series: {
        borderWidth: number;
        dataLabels: {
            /**Enable or disable the data labels. */
            enabled: boolean;
            /**The text color for the data labels. Defaults to undefined. For certain series types,
             *  like column or map, the data labels can be drawn inside the points. */
            color: string;
            /**Styles for the label. The default color setting is "contrast" */
            style: {
                textOutline: string;
                fontSize: string;
            };
            /**The vertical alignment of a data label. Can be one of top, middle or bottom. */
            verticalAlign: string;
        };
    };
};

export type CommonProps = {
    /**The default series type for the chart. In TypeScript this option has no effect in sense of typing and instead the type option must always be set in the series */
    graphType: GraphType;
    /** [Optional] ** This is to provide a legend other than the one from highcharts */
    customLegend?: JSX.Element | undefined;
    /**If categories are present for the xAxis, names are used instead of numbers for that axis.
     * However, if you have multiple series, best practice remains defining the categories array.
     */
    xAxisCategories: string[];
    /**If categories are present for the xAxis, names are used instead of numbers for that axis.
     * However, if you have multiple series, best practice remains defining the categories array.
     */
    yAxisCategories?: string[];
};

/**
 * Series options for specific data and the data itself. In TypeScript you have to cast the series options to specific series types, to get all possible options for a series.
 */
export type SeriesColumn = {
    /**The name of the series as shown in the legend, tooltip etc.*/
    name?: string;
    /**A pixel value specifying a fixed width for each column or bar point. */
    pointWidth?: number;
    /**The main color of the series. In line type series it applies to the line and the point markers unless otherwise specified*/
    color?: string;
    borderColor?: string;
    borderWidth?: number;
    /**An array of data points for the series. */
    data: number[] | number[][];
    enableMouseTracking?: boolean;
    showInLegend?: boolean;
    opacity?: number;
};

export type GraphProps = HeatmapProps | BarchartProps;
