import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    EnrolmentResultReason,
    FloreyEnrolmentCanBeEnrolledResponse,
} from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";

import * as PatientMessagingApi from "api/PatientMessagingServer/PatientMessagingServerApi";
import { DisplayFloreyMessageTemplate } from "app/workspaceConversations/components/Conversation/FloreyQuestionnaire/types";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "shared/concierge/conversations/tickets/mappers/PatientMapper";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";

// Request
type ValidateFloreyEnrolmentMutationVariables = {
    patientIds: PatientSummary["externalIds"] | undefined;
    workspaceId: number | null;
    questionnaire: DisplayFloreyMessageTemplate;
};

// Mapped response
export type EnrolmentSchedule = {
    startDate: string;
    endDate: string;
};

type ValidateFloreyEnrolmentMutationSuccess = {
    canBeEnrolled: true;
    enrolmentDuration: string | null;
    enrolmentSchedules: EnrolmentSchedule[];
};

type ValidateFloreyEnrolmentMutationError = {
    canBeEnrolled: false;
};

type ValidateFloreyEnrolmentMutationResponse =
    | ValidateFloreyEnrolmentMutationSuccess
    | ValidateFloreyEnrolmentMutationError;

export const mapFloreyCanBeEnrolledResponseToMutationResult = (
    response: FloreyEnrolmentCanBeEnrolledResponse,
): ValidateFloreyEnrolmentMutationResponse => {
    if (!response.canBeEnrolled) {
        if (response.enrolmentResultReason === EnrolmentResultReason.Success) {
            Log.warn(
                "enrolmentResultReason and canBeEnrolled values are incompatible",
                {
                    tags: {
                        canBeEnrolled: response.canBeEnrolled,
                        enrolmentResultReason: response.enrolmentResultReason,
                    },
                },
            );
        }
        if (
            response.enrolmentResultReason ===
            EnrolmentResultReason.FailAlreadyEnrolled
        ) {
            Log.warn("enrolmentResultReason should not be in use", {
                tags: {
                    canBeEnrolled: response.canBeEnrolled,
                    enrolmentResultReason: response.enrolmentResultReason,
                },
            });
        }
        return {
            canBeEnrolled: false,
        };
    }

    if (response.enrolmentResultReason !== EnrolmentResultReason.Success) {
        Log.warn(
            "enrolmentResultReason and canBeEnrolled values are incompatible",
            {
                tags: {
                    canBeEnrolled: response.canBeEnrolled,
                    enrolmentResultReason: response.enrolmentResultReason,
                },
            },
        );
    }

    // Only take into consideration enrolments which have a valid start and end date
    const previousValidEnrolments =
        response.enrolmentSchedules?.filter(
            (prevFloreySent): prevFloreySent is EnrolmentSchedule =>
                !!prevFloreySent.startDate && !!prevFloreySent.endDate,
        ) || [];

    return {
        canBeEnrolled: true,
        enrolmentDuration: response.enrolmentDuration || null,
        enrolmentSchedules: previousValidEnrolments,
    };
};

export const useValidateFloreyEnrolmentMutation = createMutationHook<
    ValidateFloreyEnrolmentMutationVariables,
    ValidateFloreyEnrolmentMutationResponse
>(({ queryClient }) => ({
    mutationKey: ["ValidateFloreyEnrolment"],
    mutationFn: async ({ workspaceId, questionnaire, patientIds }) => {
        if (workspaceId === null) {
            throw new Error("workspaceId not supplied to mutation");
        }

        if (!patientIds || patientIds.length === 0) {
            throw new Error("patientIds not supplied to mutation");
        }

        const response = await PatientMessagingApi.validateFloreyEnrolment({
            accuRxOrgId: workspaceId,
            patientExternalIds:
                mapPatientSummaryExternalIdsToTicketPatientExternalIds(
                    patientIds,
                ),
            conditionId: questionnaire.id,
        });

        const rawData = returnDataOrThrow(response);

        return mapFloreyCanBeEnrolledResponseToMutationResult(rawData);
    },
}));
