import { SingleSelect } from "@accurx/design";
import styled from "styled-components";

import { Container } from "app/organisations/shared";

export const StyledSingleSelect = styled(SingleSelect)`
    width: 30rem;
`;

// Reset spacing to 0 & add bottom spacing so intercom banner doesn't overlap the table pagination
export const StyledContainer = styled(Container)`
    padding: 0 0 5rem 0;
`;
