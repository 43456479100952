import { NativeApi } from "@accurx/native";
import { createEndpoint } from "domains/inbox/native/createEndpoint";
import {
    MaterialisedPatientDemographics,
    MaterialisedPatientDemographicsSchema,
} from "domains/inbox/schemas/MaterialisedPatientDemographics";
import { PatientExternalIdentityDto } from "domains/inbox/schemas/PatientExternalIdentityDtoSchema";

export type PatientDemographicsQueryPayload = {
    patientExternalIdentityDto: PatientExternalIdentityDto;
};

/**
 *  Clients should load demographics from the medical record for patients when either:
 *  1. a patient is actively selected in a Conversation
 *  2. a patient's Conversation Group is selected
 *  3. a Conversation is pushed via SignalR which is relevant to the current user (either they are involved, the
 *    the Conversation is relevant to an already loaded group or the Conversation is in view).
 *
 * We avoid calling more often as the clinical system audit logs can fill up with these queries.
 * SystmOne practices have complained about this in the past. For example, we should not be calling this when loading
 * Conversation Group summaries.
 *
 * A side effect of this request is that demographics may get pushed to our servers
 * @param {PatientThreadPatientDetails.demographicsLastRefreshed} - see implementation behind this for more details
 */
export const patientDemographicsQuery = createEndpoint<
    MaterialisedPatientDemographics,
    PatientDemographicsQueryPayload
>("QueryPatientDemographics", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "QueryPatientDemographics",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });

    return MaterialisedPatientDemographicsSchema.parse(result);
});
