import { COMPOSE_ERRORS, MAX_MESSAGE_LENGTH } from "../constants";
import { Attachment, ComposeErrorType } from "../reducer.types";
import { NHSAdviceLink, TemplateItem } from "../types";
import { calculateCharacterCount } from "./calculateCharacterCount";

export type ValidateMessageProps = {
    messageBody: string;
    messageSignature: string;
    attachments: Attachment[];
    mobileNumber: string | null;
    emailAddress: string | null;
    workspaceName: string;
    originalGreeting: string;
    template: TemplateItem | null;
    nhsAdviceLink: NHSAdviceLink | null;
    withSelfBookLink: boolean;
    withPatientResponseEnabled: boolean;
};

const placeholderRegex = /\*{5}(\w|\s)*\*{5}/;

export const validateMessage = (
    arg: ValidateMessageProps,
): ComposeErrorType[] => {
    const errors: ComposeErrorType[] = [];
    const { characterCount } = calculateCharacterCount({
        body: arg.messageBody,
        footer: arg.messageSignature,
        workspaceName: arg.workspaceName,
        patientResponseEnabled: !!arg.withPatientResponseEnabled,
        hasAttachments: arg.attachments.length > 0,
        hasQuestionnaire: arg.template?.type === "QuestionnaireTemplate",
        nhsAdviceLink: arg.nhsAdviceLink,
        hasSelfbookLink: arg.withSelfBookLink,
    });

    // Do not allow sending if there are attachments in progress
    if (arg.attachments.find((a) => a.origin === "TempUpload")) {
        errors.push(COMPOSE_ERRORS.AttachmentStillLoading);
    }

    if (arg.mobileNumber && characterCount > MAX_MESSAGE_LENGTH) {
        errors.push(COMPOSE_ERRORS.SmsMessageTooLong);
    }

    if (placeholderRegex.test(arg.messageBody) && !!arg.template) {
        errors.push(COMPOSE_ERRORS.TemplatePlaceholderRemains);
    }

    const textNoWhiteSpaces = arg.messageBody.trim();
    const isBodyEmpty = textNoWhiteSpaces === "";
    const isBodyTextEdited = textNoWhiteSpaces !== arg.originalGreeting;
    /**
     * A message is considered edited if the user
     * - somehow changed the body area to be different that the plain greeting
     * - enabled patient response
     * - attached an NHS advice link
     * - attached a document
     */
    const hasBeenEdited =
        isBodyTextEdited ||
        arg.withPatientResponseEnabled ||
        !!arg.nhsAdviceLink ||
        arg.attachments.length > 0;
    if (isBodyEmpty || !hasBeenEdited) {
        errors.push(COMPOSE_ERRORS.EmptyMessage);
    }

    if (!arg.emailAddress && !arg.mobileNumber) {
        errors.push(COMPOSE_ERRORS.NoContactDetails);
    }

    if (arg.emailAddress && arg.withSelfBookLink) {
        errors.push(COMPOSE_ERRORS.EmailWithSelfBookLink);
    }

    return errors;
};
