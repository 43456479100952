import { AppointmentReminderAuditResponse } from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type DeliveryReceiptsParameters = {
    workspaceId: string;
};

const getDeliveryReceipts = (
    workspaceId: string,
): Promise<IWrappedResult<AppointmentReminderAuditResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/appointmentReminderDeliveryReceipts/:workspaceId",
            params: { workspaceId },
        }),
    );

export const useDeliveryReceipts = ({
    workspaceId,
}: DeliveryReceiptsParameters) =>
    useQuery({
        queryKey: ["AppointmentReminders", "DeliveryReceipts", { workspaceId }],
        queryFn: async () => {
            const response = await getDeliveryReceipts(workspaceId);
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Request failed");
        },
    });
