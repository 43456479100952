import { useConciergeSelector } from "domains/concierge/internal/context";
import { TeamSummary } from "domains/concierge/types";

export const useCountOfTeamsByType = ({
    type,
}: {
    type: TeamSummary["type"];
}): number => {
    return useConciergeSelector((state) => state.teams.countByType[type] ?? 0);
};
