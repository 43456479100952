import { BulkAddLoadingState, PatientListsState } from "../PatientListsReducer";

/** Returns whether a CSV is uploading to a list
 *
 * used by useSelector hooks
 * */
export const getPatientListIsBulkAdding = (
    patientLists: PatientListsState,
): boolean => {
    return (
        patientLists.currentListPatientsBulkAdding !== BulkAddLoadingState.None
    );
};
