import { Fields, useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import { useNativeTrackingFields } from "@accurx/native";
import { getTimeDiffInMinutes } from "domains/inbox/util/format/dateTime";
import { getConversationDescription } from "domains/inbox/util/getConversationDescription";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";
import { getCountConversationItems } from "domains/inbox/util/getCountConversationItems";

type ActionSelected = Extract<
    Fields,
    {
        key: "actionSelected";
    }
>["value"];

type AppOrigin =
    | "ConversationListPreview"
    | "ConversationActions"
    | "MessageActions"
    | "ComposeNote";

export const useConversationActionTrackingFields = (
    conversation: Conversation,
) => {
    const nativeTrackingFields = useNativeTrackingFields();

    const conversationType = getConversationType(conversation);
    const conversationParticipant =
        getConversationParticipantForAnalytics(conversation);
    const conversationDescription = getConversationDescription(conversation);
    const { countPatientMessageInbound, countPatientMessageOutbound } =
        getCountConversationItems(conversation.items);
    const conversationRequestType = getConversationRequestType(conversation);
    const conversationStartTimestampUtc = conversation.items[0]?.createdAt;

    return ({
        type,
        appOrigin,
    }: {
        type: ActionSelected;
        appOrigin: AppOrigin;
    }) => {
        const timeSinceConversationStarted = conversationStartTimestampUtc
            ? getTimeDiffInMinutes(
                  conversationStartTimestampUtc,
                  new Date().toUTCString(),
              )
            : null;

        return {
            ...nativeTrackingFields,
            actionSelected: type,
            conversationDescription,
            conversationId: getConversationServerId(conversation),
            conversationStartTimestampUtc,
            timeSinceConversationStartedMins: timeSinceConversationStarted,
            conversationType,
            conversationParticipant,
            countItemsInConversation:
                countPatientMessageInbound + countPatientMessageOutbound,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            conversationRequestType,
            appOrigin,
            eventVersion: 3,
        };
    };
};

export const useConversationActionAnalytics = ({
    conversation,
    productOrigin,
}: {
    conversation: Conversation;
    productOrigin: AppOrigin;
}) => {
    const track = useAnalytics();
    const getTrackingFields = useConversationActionTrackingFields(conversation);

    function trackConversationActionButtonClick(type: ActionSelected) {
        track("ConversationAction Button Click", {
            ...getTrackingFields({ type, appOrigin: productOrigin }),
        });
    }

    function trackConversationActionButtonResponse(
        type: ActionSelected,
        hasError: boolean,
    ) {
        track("ConversationAction Button Response", {
            ...getTrackingFields({ type, appOrigin: productOrigin }),
            hasError: hasError,
        });
    }

    return {
        trackConversationActionButtonClick,
        trackConversationActionButtonResponse,
    };
};
