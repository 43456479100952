import { ReactNode } from "react";

import { useAnalytics } from "@accurx/analytics";
import { BatchCommunicationMethod } from "@accurx/api/patient-messaging";
import { Accordion, Link, Text } from "@accurx/design";
import { useHistory } from "react-router";

import { AccordionContent } from "./AccordionContent";
import { TickList, TickListItem } from "./TickList";

const BoldText = ({ children }: { children: ReactNode }) => (
    <Text as="span" variant="label">
        {children}
    </Text>
);

const EMAIL_SUPPORT_URL =
    "https://support.accurx.com/en/articles/9305388-batch-messaging-sending-via-email";
const NHS_APP_SUPPORT_URL =
    "https://support.accurx.com/en/articles/8078648-batch-messaging-how-to-use-batch-messaging-with-the-nhs-app";

export function HowDoWeSendMessagesInfo({
    availableCommunicationMethods,
}: {
    availableCommunicationMethods: BatchCommunicationMethod[];
}) {
    const track = useAnalytics();
    const history = useHistory();

    if (!availableCommunicationMethods.length) {
        return null;
    }

    return (
        <Accordion
            header={
                <Text skinny variant="label">
                    How do we send messages to patients?
                </Text>
            }
            openOnly
            buttonProps={{
                onClick() {
                    track("BatchCsvUploadResource Tab Click", {
                        resourceTitle: "How do we send messages to patients?",
                    });
                },
            }}
        >
            <AccordionContent>
                <Text skinny>We offer different methods of communication:</Text>
                <TickList>
                    {availableCommunicationMethods.includes(
                        BatchCommunicationMethod.Email,
                    ) && (
                        <TickListItem>
                            <Text skinny>
                                <BoldText>Email</BoldText> will be used
                                exclusively if you provide an email address, but
                                be aware that we cannot confirm delivery of the
                                message.
                            </Text>
                            <Text skinny>
                                <Link
                                    openInNewTab
                                    href={EMAIL_SUPPORT_URL}
                                    onClick={() => {
                                        track("BatchResource Link Click", {
                                            batchResourceName:
                                                EMAIL_SUPPORT_URL,
                                            origin: history.location.pathname,
                                        });
                                    }}
                                >
                                    Read the help article for more information
                                    <Link.Icon />
                                </Link>
                            </Text>
                        </TickListItem>
                    )}
                    {availableCommunicationMethods.includes(
                        BatchCommunicationMethod.NhsApp,
                    ) && (
                        <TickListItem>
                            <Text skinny>
                                <BoldText>NHS App</BoldText> notifications will
                                be sent to the patient, and if the message isn't
                                opened within 24 hours, we will follow up with a
                                SMS message.
                            </Text>
                            <Text skinny>
                                <Link
                                    openInNewTab
                                    href={NHS_APP_SUPPORT_URL}
                                    onClick={() => {
                                        track("BatchResource Link Click", {
                                            batchResourceName:
                                                NHS_APP_SUPPORT_URL,
                                            origin: history.location.pathname,
                                        });
                                    }}
                                >
                                    Read the help article for more information
                                    <Link.Icon />
                                </Link>
                            </Text>
                        </TickListItem>
                    )}
                    {availableCommunicationMethods.includes(
                        BatchCommunicationMethod.Sms,
                    ) && (
                        <TickListItem>
                            <Text skinny>
                                <BoldText>SMS message</BoldText> delivery is
                                available for UK mobile numbers only.
                            </Text>
                        </TickListItem>
                    )}
                </TickList>
            </AccordionContent>
        </Accordion>
    );
}
