import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSwitchWrapper = styled.div`
    div {
        padding-left: ${UI.Tokens.SIZES[1]};
    }
    span {
        margin-top: 0;
    }
`;

export const StyledNotificationPopoverItem = styled(UI.PopoverItem)`
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]}
        ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[0.5]};
    margin: 0;
    display: block;

    * {
        margin-left: 0;
    }
`;
