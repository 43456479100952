import React from "react";

import { Text } from "@accurx/design";

import {
    MedicalRecordResult,
    Problem,
    ProblemListSeverity,
} from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { CardSpacer } from "./CardSpacer";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type ProblemsProps = Pick<
    MedicalRecordResult,
    "activeProblems" | "pastProblems" | "potentialProblems" | "otherProblems"
>;

enum ProblemsTitles {
    Active = "Active problems",
    Past = "Past problems",
    Potential = "Potential problems",
    Other = "Other problems",
}

export const Problems = ({
    activeProblems,
    pastProblems,
    potentialProblems,
    otherProblems,
}: ProblemsProps): JSX.Element => {
    return (
        <>
            <ProblemSection
                title={ProblemsTitles.Active}
                problems={activeProblems}
            />
            <CardSpacer spacing="medium" />
            <ProblemSection
                title={ProblemsTitles.Past}
                problems={pastProblems}
            />
            {potentialProblems.length > 0 && (
                <>
                    <CardSpacer spacing="medium" />
                    <ProblemSection
                        title={ProblemsTitles.Potential}
                        problems={potentialProblems}
                    />
                </>
            )}
            {otherProblems.length > 0 && (
                <>
                    <CardSpacer spacing="medium" />
                    <ProblemSection
                        title={ProblemsTitles.Other}
                        problems={otherProblems}
                    />
                </>
            )}
        </>
    );
};

type ProblemSectionProps = {
    title: ProblemsTitles;
    problems: Array<Problem>;
};

const ProblemSection = ({
    title,
    problems,
}: ProblemSectionProps): JSX.Element => {
    const significantProblems = problems.filter(
        (p) => p.severity === ProblemListSeverity.Significant,
    );
    const minorProblems = problems.filter(
        (p) => p.severity !== ProblemListSeverity.Significant,
    );

    const isCollapsible = title !== ProblemsTitles.Active;

    return (
        <MedicalRecordPanelCard
            title={title}
            count={problems.length}
            collapsible={isCollapsible}
        >
            <ProblemList
                severity={ProblemListSeverity.Significant}
                problems={significantProblems}
                title={title}
            />
            <CardSpacer spacing="small" />
            <ProblemList
                severity={ProblemListSeverity.Minor}
                problems={minorProblems}
                title={title}
            />
        </MedicalRecordPanelCard>
    );
};

type ProblemListProps = {
    severity: ProblemListSeverity;
    problems: Array<Problem>;
} & Pick<ProblemSectionProps, "title">;

export const ProblemList = ({
    severity,
    problems,
    title,
}: ProblemListProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title={severity} nested>
            {problems.length > 0 ? (
                <CardRowStack>
                    {problems.map((problem, i) => (
                        <CardRow
                            type={CardRowType.DefaultWithComments}
                            key={`${i}-${problem.date}-${problem.description}`}
                            label={formatMedicalRecordDate(problem.date)}
                            title={problem.description}
                            comments={problem.freeText ?? ""}
                        />
                    ))}
                </CardRowStack>
            ) : (
                <Text
                    skinny
                >{`No recorded ${severity.toLowerCase()} ${title.toLowerCase()}`}</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
