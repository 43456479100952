import React, { ReactNode } from "react";

import { Card, Icon } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { PatientListSummary as Summary } from "api/FlemingDtos";
import {
    StyledButtonsDiv,
    StyledFlexDiv,
    StyledListCountText,
    StyledListDateText,
    StyledListNameText,
    StyledOuterDiv,
    StyledSummaryDetailsDiv,
    StyledSummaryDiv,
} from "app/patientLists/PatientListSummary.styles";

import PatientListBadge from "./PatientListTypeBadge";

export interface PatientListSummaryCardProps {
    summary: Summary;
    actionButtons: ReactNode;
}

const PatientListSummaryCard = ({
    summary,
    actionButtons,
}: PatientListSummaryCardProps): JSX.Element => {
    return (
        <Card spacing={2}>
            <StyledOuterDiv data-testid={`patient-list-summary-item`}>
                <StyledFlexDiv>
                    <Icon
                        name="List"
                        halo={{ colour: "blue", luminosity: "high" }}
                        size={6}
                    />
                    <StyledSummaryDiv>
                        <StyledListNameText variant="label">
                            {summary.name}
                            <PatientListBadge listType={summary.listType} />
                        </StyledListNameText>
                        <StyledSummaryDetailsDiv>
                            <StyledListCountText variant="preview">
                                {summary.patientCount} patient{" "}
                                {summary.patientCount === 1
                                    ? "entry"
                                    : "entries"}
                            </StyledListCountText>
                            <StyledListDateText variant="preview">
                                Created{" "}
                                {DateHelpers.formatDate(
                                    summary.dateCreated.toString(),
                                    DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                )}
                            </StyledListDateText>
                        </StyledSummaryDetailsDiv>
                    </StyledSummaryDiv>
                </StyledFlexDiv>
                {actionButtons && (
                    <StyledButtonsDiv>{actionButtons}</StyledButtonsDiv>
                )}
            </StyledOuterDiv>
        </Card>
    );
};

export default PatientListSummaryCard;
