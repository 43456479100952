import React from "react";

import {
    Feedback,
    FormFieldFeedback,
    SelectItem,
    SingleSelect,
} from "@accurx/design";

import { ManageTemplatesFormHook } from "../../ManageTemplatesFormPage.types";

export type CategorySelectProps = {
    categoriesError: ManageTemplatesFormHook["categoriesError"];
    categoriesStatus: ManageTemplatesFormHook["categoriesStatus"];
    categories: ManageTemplatesFormHook["categories"];
    addCategorySuccessStatus: ManageTemplatesFormHook["addCategorySuccessStatus"];
    formValues: ManageTemplatesFormHook["values"];
    onChange: (value: string) => void;
    disabled?: boolean;
};

export const CategorySelect = ({
    categoriesError,
    categoriesStatus,
    categories,
    addCategorySuccessStatus,
    formValues,
    onChange,
    disabled,
}: CategorySelectProps): JSX.Element => {
    return (
        <>
            {categoriesError && (
                <Feedback
                    colour="warning"
                    title="Unexpected error"
                    children={
                        "We couldn't load the Categories. Please refresh."
                    }
                />
            )}
            {categoriesStatus === "success" && (
                <SingleSelect
                    value={formValues.categorySelect.value}
                    onValueChange={onChange}
                    data-testid={"category-select"}
                    id={"category-select"}
                    disabled={disabled}
                >
                    {categories.map((option) => (
                        <SelectItem key={option.label} value={option.value}>
                            {option.label}
                        </SelectItem>
                    ))}
                </SingleSelect>
            )}
            {addCategorySuccessStatus && (
                <FormFieldFeedback
                    variant="success"
                    text={addCategorySuccessStatus}
                />
            )}
        </>
    );
};
