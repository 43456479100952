import { MessagePatientRequestWithToken } from "@accurx/api/patient-messaging";
import { mapConversationIdToTicketIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToTicketIdentity";
import { MessagePatientWithToken } from "domains/concierge/types/messagePatient.types";
import { patientNhsNumber } from "domains/concierge/util/patientNhsNumber";

import { mapAttachmentsToAttachDocumentIds } from "./mapAttachmentsToAttachDocumentIds";
import { mapAttachmentsToTemplateAttachmentIds } from "./mapAttachmentsToTemplateAttachmentIds";
import { mapSelfBookToSelfBookRequestType } from "./mapSelfBookToSelfBookRequestType";

export const mapMessagePatientWithTokenRequests = ({
    workspaceId,
    conversation,
    patient,
    mobileNumber,
    emailAddress,
    messageBody,
    messageSignature,
    patientToken,
    attachments,
    enablePatientResponse,
    useMobileNumberFromPds,
    template,
    sendTime,
    nhsChoicesLink,
    selfBook,
}: MessagePatientWithToken): MessagePatientRequestWithToken => {
    const ticketIdentity = mapConversationIdToTicketIdentity(conversation.id);

    const nhsNumber = patientNhsNumber(patient.externalIds);

    const attachedDocumentIds = mapAttachmentsToAttachDocumentIds(attachments);
    const attachmentIds = mapAttachmentsToTemplateAttachmentIds(attachments);
    const selfBookRequest = mapSelfBookToSelfBookRequestType(selfBook);

    const messageTemplate =
        template?.type === "MessageTemplate"
            ? {
                  id: parseInt(template.value.id),
                  attachmentIds,
                  isPreset: template.value.owner === "Accurx",
                  name: template.value.title,
                  group: template.value.heading,
                  level: template.value.owner,
              }
            : undefined;

    const questionnaireParams =
        template?.type === "QuestionnaireTemplate"
            ? {
                  conditionId: template.value.id,
                  conditionName: template.value.title,
              }
            : {};

    return {
        organisationId: workspaceId,
        ticketIdentity,
        mobileNumber,
        emailAddress,
        isVideoConsult: false,
        patientListId: null,
        attachedDocumentIds,
        enablePatientResponse,
        videoConsultTime: null,
        patientListEntryId: null,
        messageBody,
        messageFooter: messageSignature,
        patientToken,
        nhsNumber,
        useMobileNumberFromPds,
        messageTemplate,
        ...questionnaireParams,
        // Send null in case sendTime is empty string
        sendTime: sendTime || null,
        nhsChoicesLink,
        selfBook: selfBookRequest,
    };
};
