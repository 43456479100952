import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const QuickViewBodyWrapper = styled(Flex).attrs({
    gap: "2",
    flexDirection: "column",
})`
    padding: ${Tokens.SIZES[2]};
`;

export const StyledFilters = {
    QuickViewBodyWrapper,
};
