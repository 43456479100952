import React from "react";

import { shallowEqual } from "react-redux";

import { PatientListSubMenu } from "app/patientLists/submenu/PatientListSubMenu";
import { useAppSelector } from "store/hooks";

export const PatientListReadonlySubMenu = (): JSX.Element => {
    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );

    return <PatientListSubMenu patientList={currentList} />;
};
