import { Table, Tokens } from "@accurx/design";
import styled, { css, keyframes } from "styled-components";

const pulseAnimation = keyframes` {
    0% {
        box-shadow: 0 0 0 0 ${Tokens.COLOURS.primary.green[50]};
    }

    80% {
        box-shadow: 0 0 0 ${Tokens.SIZES[2]} rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}`;

export const StyledTable = styled(Table)<{ shouldAnimate: boolean }>`
    table-layout: fixed;

    animation: ${(props) =>
        props.shouldAnimate
            ? css`
                  ${pulseAnimation} 2s;
              `
            : "none"};
`;

export const StyledTableHead = styled(Table.Head)`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
`;

export const PageWrapper = styled.div`
    padding: ${Tokens.SIZES[3]};
`;
