import { AppointmentReminder } from "@accurx/api/appointment";

import { UpdatingStatus } from "shared/LoadingStatus";

import {
    CREATE_APPOINTMENT_REMINDER_FAILURE,
    CREATE_APPOINTMENT_REMINDER_STARTED,
    CREATE_APPOINTMENT_REMINDER_SUCCESS,
    DELETE_APPOINTMENT_REMINDER_FAILURE,
    DELETE_APPOINTMENT_REMINDER_STARTED,
    DELETE_APPOINTMENT_REMINDER_SUCCESS,
    GET_APPOINTMENT_REMINDERS_FAILURE,
    GET_APPOINTMENT_REMINDERS_STARTED,
    GET_APPOINTMENT_REMINDERS_SUCCESS,
    KnownAction,
    RESET_COMPOSE_ACTIONS,
    RESET_REMINDER_STATE,
    SELECT_APPOINTMENT_REMINDER,
    UPDATE_APPOINTMENT_REMINDER_FAILURE,
    UPDATE_APPOINTMENT_REMINDER_STARTED,
    UPDATE_APPOINTMENT_REMINDER_SUCCESS,
} from "./AppointmentReminder.actions";

// -----------------
// STATE Interface - This defines the type of data maintained in the Redux store.
// -----------------
export interface AppointmentReminderReducerState {
    appointmentReminders: Array<AppointmentReminder>;
    slotTypes: Array<string>;
    siteNames: Array<string>;
    getAppointmentRemindersStatus: UpdatingStatus;

    selectedAppointmentReminder: AppointmentReminder | null;
    createAppointmentReminderStatus: UpdatingStatus;
    updateAppointmentReminderStatus: UpdatingStatus;
    deleteAppointmentReminderStatus: UpdatingStatus;

    createAppointmentReminderErrorMessage: string | null;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: AppointmentReminderReducerState = {
    appointmentReminders: [],
    slotTypes: [],
    siteNames: [],
    getAppointmentRemindersStatus: UpdatingStatus.Initial,

    selectedAppointmentReminder: null,
    createAppointmentReminderStatus: UpdatingStatus.Initial,
    updateAppointmentReminderStatus: UpdatingStatus.Initial,
    deleteAppointmentReminderStatus: UpdatingStatus.Initial,

    createAppointmentReminderErrorMessage: null,
};

export const reducer = (
    state: AppointmentReminderReducerState = initialState,
    action: KnownAction,
): AppointmentReminderReducerState => {
    switch (action.type) {
        case GET_APPOINTMENT_REMINDERS_STARTED:
            return {
                ...state,
                getAppointmentRemindersStatus: UpdatingStatus.Loading,
            };
        case GET_APPOINTMENT_REMINDERS_SUCCESS:
            return {
                ...state,
                getAppointmentRemindersStatus: UpdatingStatus.Loaded,
                appointmentReminders: action.appointmentReminders,
                slotTypes: action.slotTypes,
                siteNames: action.siteNames,
            };
        case GET_APPOINTMENT_REMINDERS_FAILURE:
            return {
                ...state,
                getAppointmentRemindersStatus: UpdatingStatus.Failed,
            };
        case SELECT_APPOINTMENT_REMINDER:
            return {
                ...state,
                selectedAppointmentReminder: action.selectedAppointmentReminder,
            };
        case CREATE_APPOINTMENT_REMINDER_STARTED:
            return {
                ...state,
                createAppointmentReminderStatus: UpdatingStatus.Loading,
            };
        case CREATE_APPOINTMENT_REMINDER_SUCCESS:
            return {
                ...state,
                createAppointmentReminderStatus: UpdatingStatus.Loaded,
                appointmentReminders: action.appointmentReminders,
            };
        case CREATE_APPOINTMENT_REMINDER_FAILURE:
            return {
                ...state,
                createAppointmentReminderStatus: UpdatingStatus.Failed,
                createAppointmentReminderErrorMessage: action.errorMessage,
            };
        case UPDATE_APPOINTMENT_REMINDER_STARTED:
            return {
                ...state,
                updateAppointmentReminderStatus: UpdatingStatus.Loading,
            };
        case UPDATE_APPOINTMENT_REMINDER_SUCCESS:
            return {
                ...state,
                updateAppointmentReminderStatus: UpdatingStatus.Loaded,
                appointmentReminders: action.appointmentReminders,
            };
        case UPDATE_APPOINTMENT_REMINDER_FAILURE:
            return {
                ...state,
                updateAppointmentReminderStatus: UpdatingStatus.Failed,
            };
        case DELETE_APPOINTMENT_REMINDER_STARTED:
            return {
                ...state,
                deleteAppointmentReminderStatus: UpdatingStatus.Failed,
            };
        case DELETE_APPOINTMENT_REMINDER_SUCCESS:
            return {
                ...state,
                deleteAppointmentReminderStatus: UpdatingStatus.Loaded,
                appointmentReminders: action.appointmentReminders,
            };
        case DELETE_APPOINTMENT_REMINDER_FAILURE:
            return {
                ...state,
                deleteAppointmentReminderStatus: UpdatingStatus.Failed,
            };
        case RESET_COMPOSE_ACTIONS:
            return {
                ...initialState,
                getAppointmentRemindersStatus:
                    state.getAppointmentRemindersStatus,
                appointmentReminders: state.appointmentReminders,
                slotTypes: state.slotTypes,
                siteNames: state.siteNames,
            };
        case RESET_REMINDER_STATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
