import { PatientName, PatientNameUse } from "@accurx/api/ticket";
import { getPatientNameFromArray } from "domains/concierge/internal/util/getPatientNameFromArray";
import { PatientSummary } from "domains/concierge/types";

export function mapPatientNamesToPatientSummaryParts(
    names?: PatientName[] | null,
): Pick<
    PatientSummary,
    "familyName" | "firstName" | "prefixName" | "nickname"
> {
    const nameTypeUsual = names?.find(
        (patientNames) => patientNames.use === PatientNameUse.Usual,
    );
    const nameTypeNickname = names?.find(
        (patientNames) => patientNames.use === PatientNameUse.Nickname,
    );

    const prefixName = getPatientNameFromArray(nameTypeUsual?.prefix);
    const firstName = getPatientNameFromArray(nameTypeUsual?.given);
    const familyName = getPatientNameFromArray(nameTypeUsual?.family);
    const nickname = getPatientNameFromArray(nameTypeNickname?.given);

    return {
        prefixName,
        firstName,
        familyName,
        nickname,
    };
}
