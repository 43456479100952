import { FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledBodyFieldContainer = styled.div`
    background: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.SIZES[0.5]};
    margin: ${Tokens.SIZES[0.5]} 0 ${Tokens.SIZES[2]} 0;
    padding: ${Tokens.SIZES[1.5]};
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1.5]};
`;
