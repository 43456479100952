import { MouseEventHandler } from "react";

import { Button } from "@accurx/design";

export function CreateReminderButton({
    onClick,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <Button
            text="Create appointment reminder"
            icon={{ name: "Plus" }}
            onClick={onClick}
        />
    );
}
