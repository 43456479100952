import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";

/**
 * TODO: Remove import from ILLEGAL_IMPORTS_DO_NOT_USE
 */
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";

import { BodyInfoBox } from "../../BodyInfoBox";
import { VIEW_ATTACHMENT_TEXT } from "./AttachmentInfoBox.constants";
import { StyledPillButton } from "./AttachmentInfoBox.styles";

export const AttachmentInfoBox = ({
    name,
    previewUrl,
    onAttachmentRemove,
}: {
    name: string;
    previewUrl?: string;
    onAttachmentRemove?: () => void;
}) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header gap="2">
                <UI.Flex gap="1">
                    <BodyInfoBox.Icon name={"Document"} />
                    <BodyInfoBox.Text colour="night">
                        {`${VIEW_ATTACHMENT_TEXT}[link will appear here]`}
                    </BodyInfoBox.Text>
                </UI.Flex>
                {onAttachmentRemove && (
                    <StyledPillButton
                        onClick={onAttachmentRemove}
                        title="Remove attachment"
                    >
                        <Pill.Icon name="Cross" colour="red" size={3} />
                    </StyledPillButton>
                )}
            </BodyInfoBox.Header>
            <BodyInfoBox.Footer>
                {previewUrl ? (
                    <BodyInfoBox.Link
                        href={previewUrl}
                        openInNewTab={true}
                        onClick={() => {
                            track("AttachmentPreview Link Click", {
                                ...nativeTrackingFields,
                            });
                        }}
                    >
                        <UI.Link.Text text={name} />
                        <UI.Link.Icon />
                    </BodyInfoBox.Link>
                ) : (
                    <BodyInfoBox.Text colour="metal">{name}</BodyInfoBox.Text>
                )}
            </BodyInfoBox.Footer>
        </BodyInfoBox.Wrapper>
    );
};
