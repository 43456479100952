import React, { useEffect, useRef, useState } from "react";

import { Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { shallowEqual } from "react-redux";

import { PatientListAppointmentDay } from "api/FlemingDtos";
import DropdownMenuOption from "app/sharedComponents/dropdown/DropdownMenuOption.component";
import SelectDropdown from "app/sharedComponents/dropdown/SelectDropdown.component";
import { useAppSelector } from "store/hooks";

type DateSelectionRef = {
    date: string;
    index: number;
};

type ClinicDayFilterProps = {
    selectedDateIndex: number;
    onDaySelected: (selectedDateIndex: number) => void;
};

export const ClinicDayFilter = ({
    selectedDateIndex,
    onDaySelected,
}: ClinicDayFilterProps): JSX.Element | null => {
    const [open, setOpen] = useState(false);

    const toggleOpen = (): void => {
        setOpen((prevState) => !prevState);
    };

    const currentListDays = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.appointmentDays || null,
        shallowEqual,
    );

    const lastSelectedDateRef = useRef<DateSelectionRef | null>(null);
    const currentListDates = useAppSelector(
        ({ patientLists }) =>
            patientLists.currentList?.appointmentDays
                .map((day) => (day.date === "" ? "anytime" : day.date))
                .join("|") || "",
    );

    /*
        This useEffect only gets executed when a days get added or removed
    */
    useEffect(() => {
        const datesArr = currentListDates.split("|").filter(Boolean);

        // No patients in the list, we shouldn't have anything selected
        if (datesArr.length === 0) {
            lastSelectedDateRef.current = null;
            return;
        }

        // The data has loaded, but the date dropdown hasn't been selected yet
        // We can initialise the selection to be the first item in the array
        if (lastSelectedDateRef.current === null) {
            onDaySelected(0);
            lastSelectedDateRef.current = { date: datesArr[0], index: 0 };
            return;
        }

        // Data has loaded and something had already been selected before
        const index = datesArr.indexOf(lastSelectedDateRef.current.date);

        // The date was removed
        if (index === -1) {
            // Work out if the date removed was in the middle or last - move the selection up a position
            // Or if it was the very first - leave the position to 0 but update the date in the ref
            const newIndexSelected =
                lastSelectedDateRef.current.index > 0
                    ? lastSelectedDateRef.current.index - 1
                    : 0;

            onDaySelected(newIndexSelected);
            lastSelectedDateRef.current = {
                date: datesArr[newIndexSelected],
                index: newIndexSelected,
            };

            return;
        }

        // Or a new date was added, so we need to readjust the index
        onDaySelected(index);
        lastSelectedDateRef.current = { date: datesArr[index], index };
    }, [currentListDates, onDaySelected]);

    const getDayDropdownText = (day: PatientListAppointmentDay): string => {
        const dateOrAnytime = day.date
            ? DateHelpers.formatDate(
                  day.date,
                  DateFormatOptions.DATE_DROPDOWN_OPTION_FORMAT,
              )
            : "Anytime";
        return `${dateOrAnytime} (Patient entries ${day.appointments.length})`;
    };

    const renderDaySelectionOption = (
        day: PatientListAppointmentDay,
        index: number,
    ): JSX.Element => {
        const handleClick = (): void => {
            onDaySelected(index);
            lastSelectedDateRef.current = { index, date: day.date };
        };
        return (
            <DropdownMenuOption
                key={day.date || `anytime-${index}`}
                isActive={selectedDateIndex === index}
                onClick={handleClick}
            >
                {getDayDropdownText(day)}
            </DropdownMenuOption>
        );
    };

    return (
        <>
            <Text variant="label" skinny>
                Clinic day
            </Text>
            <SelectDropdown
                isOpen={open}
                onToggle={toggleOpen}
                dropdownText={
                    currentListDays
                        ? getDayDropdownText(currentListDays[selectedDateIndex])
                        : "Select"
                }
            >
                {currentListDays?.map(renderDaySelectionOption)}
            </SelectDropdown>
        </>
    );
};
