import { ContactDetail } from "@accurx/compose";
import {
    Conversation,
    PatientEmailItem,
    PatientSmsItem,
    PatientTriageRequestItem,
} from "@accurx/concierge/types";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";

type ConversationItemWithContactDetails =
    | PatientEmailItem
    | PatientSmsItem
    | PatientTriageRequestItem;

const findPatientSubmittedMobileNumber = (item: PatientTriageRequestItem) => {
    const foundPhoneNumberResponse = flatten(
        item.sections.map(({ responses }) => responses),
    ).find((response) => {
        return response.question === "Phone number";
    });

    return foundPhoneNumberResponse?.answer ?? null;
};

export const getConversationItemsWithValidContactDetails = (
    conversation: Conversation,
): ConversationItemWithContactDetails[] => {
    const items = conversation.items.filter(
        (item): item is ConversationItemWithContactDetails =>
            item.contentType === "PatientEmail" ||
            item.contentType === "PatientSms" ||
            item.contentType === "PatientTriageRequestNote",
    );

    return items.filter((item) => {
        switch (item.contentType) {
            case "PatientEmail":
                return EmailAddressHelper.isValidEmailAddress(
                    item.emailAddress,
                );
            case "PatientSms":
                return MobileNumberHelper.isValidMobileNumber(
                    item.mobileNumber,
                );
            case "PatientTriageRequestNote": {
                const foundNumber = findPatientSubmittedMobileNumber(item);
                return foundNumber
                    ? MobileNumberHelper.isValidMobileNumber(foundNumber)
                    : false;
            }
            default:
                return false;
        }
    });
};

const getContactDetailsInDateOrder = (
    conversationItems: ConversationItemWithContactDetails[],
): ContactDetail[] => {
    const itemsOrderedByCreatedAt = orderBy(
        conversationItems,
        "createdAt",
        "desc",
    );

    return itemsOrderedByCreatedAt
        .map((item) => {
            switch (item.contentType) {
                case "PatientEmail":
                    return {
                        method: "Email",
                        value: item.emailAddress,
                        origin: "Conversation",
                    };
                case "PatientSms":
                    return {
                        method: "Mobile",
                        value: item.mobileNumber,
                        origin: "Conversation",
                    };
                case "PatientTriageRequestNote": {
                    const foundNumber = findPatientSubmittedMobileNumber(item);

                    return foundNumber
                        ? {
                              method: "Mobile",
                              value: foundNumber,
                              origin: "SubmittedByPatient",
                          }
                        : null;
                }
                default:
                    return null;
            }
        })
        .filter((c): c is ContactDetail => !!c);
};

export const getContactDetailsFromConversation = (
    conversation: Conversation,
): ContactDetail[] => {
    const itemsWithValidContactDetails =
        getConversationItemsWithValidContactDetails(conversation);

    const contactDetails = getContactDetailsInDateOrder(
        itemsWithValidContactDetails,
    );

    return contactDetails;
};
