import { Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

const SIDEBAR_WIDTH = "25rem";

export const stickyTableStyles = css`
    position: sticky;
    right: 0;
    z-index: 50;
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: 0px ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]}
            ${Tokens.COLOURS.greyscale.metal},
        inset 1px 0px 0px ${Tokens.COLOURS.greyscale.silver};
    background-clip: padding-box; //fixes border bug on firefox browser
`;

export const StickyTableLastColumn = styled.tbody`
    @media screen and (max-width: calc(${Tokens.BREAKPOINTS
            .xl} + ${SIDEBAR_WIDTH})) {
        tr > td:last-of-type {
            ${stickyTableStyles}
        }
    }
`;

export const StickyTableLastHeader = styled.thead`
    @media screen and (max-width: calc(${Tokens.BREAKPOINTS
            .xl} + ${SIDEBAR_WIDTH})) {
        th:last-of-type {
            ${stickyTableStyles}
        }
    }
`;
