import { format, getHours, getMinutes, isWeekend } from "date-fns";

import { PresetDateType } from "../types/ScheduleSend.types";

type PresetDateOption = {
    value: PresetDateType;
    text: string;
}[];

export const getDateOptions = (
    bankHolidays: string[] = [],
): PresetDateOption => {
    const currentHours = getHours(Date.now());
    const currentMinutes = getMinutes(Date.now());

    // bankHolidays api returns date of format "yyyy-MM-dd"
    const todayDateString = format(Date.now(), "yyyy-MM-dd");

    const isBankHoliday = bankHolidays.includes(todayDateString);

    if (isWeekend(Date.now()) || isBankHoliday) {
        return [{ value: "nextWorkingDay", text: "at 8am" }];
    }

    const isBeforeSevenThirty = currentHours === 7 && currentMinutes < 30;

    if (currentHours >= 0 && (currentHours < 7 || isBeforeSevenThirty)) {
        return [
            { value: "todayMorning", text: "Today at 8am" },
            { value: "todayEvening", text: "Today at 7pm" },
        ];
    }

    const isBeforeSixteenThirty = currentHours === 18 && currentMinutes <= 30;
    const isAfterSevenThirty = currentHours === 7 && currentMinutes >= 30;

    if (
        (currentHours >= 8 || isAfterSevenThirty) &&
        (currentHours < 18 || isBeforeSixteenThirty)
    ) {
        return [
            { value: "todayEvening", text: "Today at 7pm" },
            { value: "nextWorkingDay", text: "at 8am" },
        ];
    }

    return [{ value: "nextWorkingDay", text: "at 8am" }];
};
