import React, { useEffect, useRef, useState } from "react";

import {
    Button,
    ErrorSummary,
    ErrorSummaryProvider,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Link,
    Text,
    useErrorSummary,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { z } from "zod";

import { FlemingAnalyticsTracker } from "app/analytics";
import { FlemingLoggedInCustomProperties } from "app/analytics/FlemingAnalytics";
import { useSearchPharmaciesQuery } from "app/hooks/queries/useSearchPharmaciesQuery";
import { TwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SkeletonList } from "app/sharedComponents/loadingSkeleton/SkeletonList";
import { SkeletonBlock } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { ROUTES } from "shared/Routes";

import {
    StyledCardItem,
    StyledCardList,
    StyledMain,
    StyledPageWrapper,
} from "../shared/Layout/Layout.styles";
import { PharmacyResult } from "./PharmacyResult";
import { PharmacyResultsPagination } from "./PharmacyResultsPagination";
import { PAGE_PARAM_NAME, SEARCH_TERM_PARAM_NAME } from "./constants";
import { getQueryString, getResultsLabel } from "./utils";

const SearchTips = ({
    analyticsProps,
}: {
    analyticsProps: FlemingLoggedInCustomProperties;
}) => (
    <ul>
        <li>
            <Text skinny>
                ODS (Organisation Data Service) code. You can{" "}
                <Link
                    href="https://odsportal.digital.nhs.uk/Organisation/Search"
                    openInNewTab={true}
                    onClick={() => {
                        FlemingAnalyticsTracker.trackOdsLookupInfoLinkClick(
                            analyticsProps,
                        );
                    }}
                >
                    find your code on the ODS portal
                    <Link.Icon />
                </Link>
            </Text>
        </li>
        <li>
            <Text skinny>address and/or postcode</Text>
        </li>
    </ul>
);

const AddOrganisationCardItem = () => (
    <StyledCardItem as="li">
        <Text skinny>
            If your organisation is not listed you can add it here.
        </Text>
        <RouterLink
            to={{
                pathname: ROUTES.addOrganisation,
                state: { showBackButton: true },
            }}
        >
            <Button
                as="span"
                theme="secondary"
                text="Add an organisation"
                icon={{
                    name: "Plus",
                    colour: "blue",
                }}
            />
        </RouterLink>
    </StyledCardItem>
);

const SearchSchema = z.object({
    searchTerm: z.string().min(3, {
        message: "Enter 3 characters or more",
    }),
});

const JoinPharmacyPageContent = () => {
    useAccurxWebTitle("Join a pharmacy");

    const history = useHistory();
    const location = useLocation();
    const { addError, clearAllErrors } = useErrorSummary();

    const params = new URLSearchParams(location.search);
    const searchTermParam = params.get(SEARCH_TERM_PARAM_NAME) || "";
    const pageParam = Number(params.get(PAGE_PARAM_NAME)) || 1;

    const pageWrapperRef = useRef<HTMLElement>(null);

    const [searchTerm, setSearchTerm] = useState(searchTermParam);
    const [inputError, setInputError] = useState("");

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { data, refetch, isError, isFetching } = useSearchPharmaciesQuery({
        searchTerm: searchTermParam,
        page: pageParam,
    });

    useEffect(() => {
        FlemingAnalyticsTracker.trackPharmacySearchPageView(
            analyticsLoggedInProps,
        );
        // Only track event on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateSearch = (): boolean => {
        const result = SearchSchema.safeParse({
            searchTerm: searchTerm.trim(),
        });

        if (result.success) {
            setInputError("");
            clearAllErrors();
            return true;
        }

        const { message } = result.error.issues[0];
        setInputError(message);
        addError({ id: "search-input", body: message });
        return false;
    };

    const onSubmitSearch = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!validateSearch()) {
            return;
        }

        // Allow users to re-search for a more natural UX
        if (searchTermParam === searchTerm.trim()) {
            await refetch();
        }

        history.push({
            search: getQueryString({ searchTerm: searchTerm.trim() }),
        });
    };

    const showPreviousResults = () => {
        history.push({
            search: getQueryString({
                searchTerm: searchTermParam,
                page: pageParam - 1,
            }),
        });
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    const showNextResults = () => {
        history.push({
            search: getQueryString({
                searchTerm: searchTermParam,
                page: pageParam + 1,
            }),
        });
        pageWrapperRef.current?.scrollTo(0, 0);
    };

    return (
        <StyledPageWrapper ref={pageWrapperRef} flexDirection="column">
            <StyledMain flexDirection="column">
                <ErrorSummary isShown={Boolean(inputError)} />
                <Text variant="subtitle" as="h1">
                    Join a pharmacy
                </Text>
                <Text>
                    Search for a pharmacy below. For better results you can
                    include:
                </Text>
                <SearchTips analyticsProps={analyticsLoggedInProps} />
                <Flex gap="3" flexDirection="column">
                    <form onSubmit={onSubmitSearch}>
                        <FormFieldWrapper errors={[inputError]}>
                            <Flex flexDirection="row" gap="2">
                                <Input
                                    id="search-input"
                                    isSearchInput
                                    value={searchTerm}
                                    placeholder="Search pharmacies"
                                    aria-label="Search pharmacies"
                                    hasErrors={Boolean(inputError)}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                                <Button
                                    icon={{
                                        name: "Search",
                                        colour: "white",
                                    }}
                                    text="Search"
                                    theme="primary"
                                />
                            </Flex>
                        </FormFieldWrapper>
                    </form>
                    {isFetching ? (
                        <>
                            <SkeletonBlock height="20px" width="300px" />
                            <SkeletonList quantity={10} />
                        </>
                    ) : isError ? (
                        <TwoThirdsContainer>
                            <Feedback colour="error">
                                Something went wrong. Please try again.
                            </Feedback>
                        </TwoThirdsContainer>
                    ) : (
                        data && (
                            <Flex
                                flexDirection="column"
                                gap="3"
                                key={`${searchTerm}-${pageParam}`}
                            >
                                {data.totalCount >= 100 && (
                                    <Feedback colour="information">
                                        This search returned more than 100
                                        results. Try adding more details to
                                        narrow down the search.
                                    </Feedback>
                                )}
                                <Text variant="label" skinny>
                                    {getResultsLabel(data)}
                                </Text>
                                <StyledCardList data-testid="pharmacy-list">
                                    {data.nhsPharmacies.map((pharmacy) => (
                                        <PharmacyResult
                                            key={pharmacy.id}
                                            name={pharmacy.name}
                                            address={pharmacy.address}
                                            odsCode={pharmacy.odsCode}
                                            hasMembers={!!pharmacy.hasMembers}
                                        />
                                    ))}
                                    {data.currentPage === data.totalPages && (
                                        <AddOrganisationCardItem />
                                    )}
                                </StyledCardList>
                                {data.totalPages > 1 && (
                                    <>
                                        <Text variant="label" skinny>
                                            {getResultsLabel(data)}
                                        </Text>
                                        <PharmacyResultsPagination
                                            totalPages={data.totalPages}
                                            currentPage={data.currentPage}
                                            onPrevious={showPreviousResults}
                                            onNext={showNextResults}
                                        />
                                    </>
                                )}
                            </Flex>
                        )
                    )}
                </Flex>
            </StyledMain>
        </StyledPageWrapper>
    );
};

export const JoinPharmacyPage = () => (
    <ErrorSummaryProvider>
        <JoinPharmacyPageContent />
    </ErrorSummaryProvider>
);
