import { useConciergeSelector } from "domains/concierge/internal/context";
import { TeamSummary } from "domains/concierge/types";

export const useTeam = ({
    teamId,
}: {
    teamId: TeamSummary["id"];
}): TeamSummary | undefined => {
    return useConciergeSelector((state) => state.teams.items[teamId]);
};
