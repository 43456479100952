import { useAuth } from "@accurx/auth";

/** For a logged in user, returns true if they belong to at least one organisation */
export const useIsInOrganisation = (): boolean | null => {
    const { user } = useAuth();

    if (!user.isLoggedIn) {
        return null;
    }

    return user.organisations.length > 0;
};
