import { Button, StackPanel } from "@accurx/design";

import { ButtonContainer } from "./TemplateForm.styles";

type FormButtonsProps = {
    goBack: () => void;
    saveButtonText: string;
};

export const FormButtons = ({ goBack, saveButtonText }: FormButtonsProps) => (
    <ButtonContainer>
        <StackPanel
            orientation="horizontal"
            horizontalContentAlignment="center"
            gutter={2}
        >
            <Button
                type="button"
                onClick={goBack}
                theme="secondary"
                text="Cancel"
            />
            <Button type="submit" text={saveButtonText} />
        </StackPanel>
    </ButtonContainer>
);
