import {
    Feedback,
    Flex,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Spinner,
} from "@accurx/design";
import { FormFieldWrapperProps } from "@accurx/design/dist/components/Forms/FormFieldWrapper/FormFieldWrapper";
import {
    SearchSelectMode,
    SearchSelectProps,
} from "@accurx/design/dist/components/Forms/Select/models";

const ID = "question-select";

type QuestionSelectFormFieldProps = {
    isLoading?: boolean;
    label: string | JSX.Element;
    subLabel?: string | JSX.Element | undefined;
    selectedOption?: Option;
    options: Option[];
    infoText?: string;
    onChange: (option: Option) => void;
    formFieldProps?: Partial<FormFieldWrapperProps>;
    selectProps?: Partial<SearchSelectProps>;
    id?: string;
};

/*
 * Similar to the SnomedSelectFormField
 * QuestionSelectFormField should have a loading state and helpful text
 */
export const QuestionSelectFormField = ({
    isLoading,
    selectedOption,
    label,
    subLabel,
    options,
    infoText,
    onChange,
    formFieldProps,
    selectProps,
    id = ID,
}: QuestionSelectFormFieldProps) => {
    return (
        <Flex flexDirection="column" gap={"1"}>
            <FormFieldWrapper
                {...formFieldProps}
                label={label}
                subLabel={subLabel}
                labelProps={{ ...formFieldProps?.labelProps, id: id }}
            >
                {isLoading && (
                    <span id={`${id}-loading`}>
                        <Spinner />
                    </span>
                )}
                {!isLoading && (
                    <>
                        <SearchSelect
                            zIndex={1}
                            id={id} // used for aria-labelledby internally
                            options={options}
                            onChangeHandler={(selected): void => {
                                onChange(selected as Option);
                            }}
                            initSelectedValues={selectedOption}
                            searchMode={SearchSelectMode.AnyWord}
                            {...selectProps}
                        />
                    </>
                )}
            </FormFieldWrapper>
            {!!infoText && <Feedback colour="secondary" title={infoText} />}
        </Flex>
    );
};
