import { Log } from "@accurx/shared";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { isConversationInGroup } from "domains/concierge/internal/util/isConversationInGroup";
import { shardedMap } from "domains/concierge/internal/util/shardedMap";
import { sortConversationsForGroup } from "domains/concierge/internal/util/sortConversationsForGroup";
import { updateUnreadState } from "domains/concierge/internal/util/updateUnreadState";
import { Conversation } from "domains/concierge/types";
import { Draft, createDraft } from "immer";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import take from "lodash/take";
import uniq from "lodash/uniq";

const reconcileUnreadCounts = (
    state: Draft<ConversationsState>,
    userId: string,
) => {
    // create a clean version of the state where unread counts have been reset
    // so we can build up new unread counts and compare to the real state.
    const cleanState = createDraft(state);
    cleanState.unreadCounts = {
        ticket: {
            queryStatus: state.unreadCounts.ticket.queryStatus,
            user: 0,
            teams: {},
            patients: {},
        },
        clinicianMessaging: {
            queryStatus: state.unreadCounts.clinicianMessaging.queryStatus,
            user: 0,
            teams: {},
        },
    };

    for (const id in state.unreadItems) {
        updateUnreadState({
            state: cleanState,
            current: undefined,
            next: state.unreadItems[id],
            userId,
        });
    }

    // now we've completely recalculated the unread counts, we can compare them
    // to what we already have an log an error if they're different.
    if (!isEqual(cleanState.unreadCounts, state.unreadCounts)) {
        Log.error(
            "Reconciled unread counts don't match unread counts in state",
            {
                tags: {
                    product: "Inbox",
                    unreadCounts: JSON.stringify(state.unreadCounts),
                    reconciledCounts: JSON.stringify(cleanState.unreadCounts),
                },
            },
        );

        // Update unread counts in case there were any discrepencies
        state.unreadCounts = cleanState.unreadCounts;
    }
};

/*
 * A periodic safety check triggered by the `useStateReconciler` hook.
 *
 * Recalculates from the ground up the two key pieces of calculated state
 * the Concierge layer tracks on a running basis - unread counts,
 * and conversation groups - and compares the recalculated values to
 * what we currently have in the state. If there's a mismatch, something
 * has gone wrong, and we need to know about it ASAP, so we log an error.
 *
 * At the moment the `useStateReconciler` hook triggers this reducer
 * for every user every 15 minutes. Because it will potentially be quite
 * expensive/slow to traverse the entire state so frequently, once we have
 * confidence things are working well we may decide to reduce the frequency
 * or introduce sampling. Will always be nice to keep now and again though
 * as a safety check.
 */
export const reconcileState: CaseReducer<ConversationsState, PayloadAction> = (
    state,
    action,
) => {
    const userId = (action as ConciergeAction<undefined>).meta.userId;

    reconcileUnreadCounts(state, userId);

    // initialise an empty object to keep track of which
    // conversations fall into a conversation group
    // and create an entry for each group currently in the state.
    // we'll keep track of full conversations in this object rather
    // that just ids, because we'll need the full conversations
    // to sort the list later
    const reconciledGroups: Record<string, Conversation[]> = {};
    for (const id in state.groups.items) {
        reconciledGroups[id] = [];
    }

    // count the number of conversations in the state
    // while we're iterating through them
    let countConversations = 0;

    shardedMap.forEach(state.items, (conversation: Conversation) => {
        countConversations++;

        // check for each group if the conversation is in
        // that group, and if so add it to the list
        for (const id in state.groups.items) {
            const group = state.groups.items[id];
            const isInGroup = isConversationInGroup(
                conversation,
                group.filters,
            );
            if (isInGroup) {
                reconciledGroups[id].push(conversation);
            }
        }
    });

    // make sure our running total of conversations is still correct
    if (countConversations !== state.total) {
        Log.error(
            "Reconciled count of conversations doesn't match count in state",
            {
                tags: {
                    domain: "Concierge",
                    stateTotal: state.total,
                    reconciledTotal: countConversations,
                },
            },
        );
        state.total = countConversations;
    }

    // similarly, for each group, we can check that the conversations
    // we've found by iterating through all conversations in the state
    // match the ones in the group's `members` array.
    for (const id in state.groups.items) {
        const group = state.groups.items[id];

        const reconciledMembers = sortConversationsForGroup(
            reconciledGroups[id],
            group.sortOptions,
        ).map((c) => c.id);

        const reconciledDenseSet = take(reconciledMembers, group.ceiling);
        const groupDenseSet = take(group.members, group.ceiling);

        if (
            // we only check up to the `ceiling` of the group, as we don't
            // guarantee that all the conversations in the state that fall into the group
            // will be in the `members` array - only the ones up to the ceiling.
            !isEqual(reconciledDenseSet, groupDenseSet)
        ) {
            Log.warn(
                "Reconciled conversation group doesn't match conversation group in state",
                {
                    tags: {
                        product: "Inbox",
                        ceiling: group.ceiling,
                        groupMembersCount: group.members.length,
                        stateMembersCount: reconciledMembers.length,
                        sortOptions: JSON.stringify(group.sortOptions),
                        groupName: group.loggingInfo.name,
                        ...group.loggingInfo.tags,
                        denseSetExtra: JSON.stringify(
                            difference(reconciledDenseSet, groupDenseSet),
                        ),
                        denseSetMissing: JSON.stringify(
                            difference(groupDenseSet, reconciledDenseSet),
                        ),
                        countGroupMemberDuplicates:
                            groupDenseSet.length - uniq(groupDenseSet).length,
                    },
                },
            );
        }
    }

    return state;
};
