import {
    AppointmentReminder,
    AppointmentReminderFilterAttribute,
    AppointmentTypeFilter,
    AppointmentTypeFilterOption,
} from "@accurx/api/appointment";
import { Log } from "@accurx/shared";

import { getValidSiteNamesLength } from "../../utils";

export function getFilterSummaryForReminder({
    reminder,
    availableFilters,
    appointmentTypes,
    allSiteNames,
}: {
    reminder: AppointmentReminder;
    availableFilters: AppointmentReminderFilterAttribute[];
    appointmentTypes: AppointmentTypeFilterOption[];
    allSiteNames: string[];
}) {
    const badgeContent: string[] = [];

    if (
        availableFilters.includes(
            AppointmentReminderFilterAttribute.AppointmentTypes,
        )
    ) {
        const appointmentTypesSummaryText = getAppointmentTypesText(
            reminder.appointmentTypeFilters,
            appointmentTypes,
        );

        if (appointmentTypesSummaryText) {
            badgeContent.push(appointmentTypesSummaryText);
        }
    }

    if (
        availableFilters.includes(AppointmentReminderFilterAttribute.SiteNames)
    ) {
        const sitenamesFilterSummaryText = getNumberOfSitesText(
            reminder.siteNames,
            allSiteNames,
        );

        if (sitenamesFilterSummaryText) {
            badgeContent.push(sitenamesFilterSummaryText);
        }
    }

    return badgeContent.join(", ");
}

export function getNumberOfSitesText(
    reminderSiteNames: string[],
    allSiteNames: string[],
) {
    const validSiteNamesLength = getValidSiteNamesLength(
        reminderSiteNames,
        allSiteNames,
    );

    if (allSiteNames.length > 1) {
        if (validSiteNamesLength === allSiteNames.length) {
            return "Sites: All";
        }

        return `Sites: ${validSiteNamesLength} of ${allSiteNames.length}`;
    }

    return "";
}

export function getAppointmentTypesText(
    reminderAppointmentTypes: AppointmentTypeFilter[],
    appointmentTypes: AppointmentTypeFilterOption[],
) {
    if (reminderAppointmentTypes.length) {
        const listOfAppointmentTypes = reminderAppointmentTypes
            .map((type) => {
                const appointmentType = appointmentTypes.find(
                    ({ value }) => value === type,
                );

                if (appointmentType) {
                    return appointmentType.description;
                }

                Log.error(
                    new Error(
                        `Couldn't find appointment description of type: '${type}'`,
                    ),
                );

                return undefined;
            })
            .join(", ");

        return `Appointment type: ${listOfAppointmentTypes}`;
    }

    return "";
}
