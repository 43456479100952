import React from "react";

import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { useAnalytics } from "app/analytics/hooks";
import { useModules } from "app/organisations/hooks";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import { SectionCardLink } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledList,
} from "../../styles/Cards.styles";

export const OrganisationCard = (): JSX.Element => {
    const { track } = useAnalytics();
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();
    const modules = useModules();

    const recordViewEnabled = useIsFeatureEnabled(
        FeatureName.RecordViewGpOptIn,
    );

    const settingsClickHandler = () => {
        track("HomepageOrganisationSettingButtonClick", {
            homepageSection: "Homepage",
        });
        history.push(
            generatePath(ROUTES_ORGS.settings, {
                orgId,
            }),
        );
    };

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={2}
            >
                <Text as="h2" skinny variant="subtitle">
                    {SECTIONS.ORGANISATION}
                </Text>
                <StyledCTAButton
                    theme="secondary"
                    text="Settings"
                    icon={{ name: "Cog", colour: "blue" }}
                    onClick={settingsClickHandler}
                />
            </StyledCardHeading>

            <StyledCardContent>
                <StyledList>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.ORGANISATION}
                            text="Usage dashboard"
                            path={generatePath(ROUTES_ORGS.usageDashboard, {
                                orgId,
                            })}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.ORGANISATION}
                            text="Users"
                            path={generatePath(ROUTES_ORGS.settings, {
                                orgId,
                                tab: "users",
                            })}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.ORGANISATION}
                            text="Team notifications"
                            path={generatePath(ROUTES_ORGS.settings, {
                                orgId,
                                tab: "team-notifications",
                            })}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.ORGANISATION}
                            text="Available features"
                            path={generatePath(ROUTES_ORGS.settings, {
                                orgId,
                                tab: "available-features",
                            })}
                        />
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.ORGANISATION}
                            text="Record View"
                            path={generatePath(
                                recordViewEnabled
                                    ? ROUTES_CHAIN.practicesRecordViewRequests
                                    : ROUTES_CHAIN.practicesRecordViewOptIn,
                                {
                                    orgId,
                                },
                            )}
                        />
                    </li>
                    {modules?.inhalerRct && (
                        <li>
                            <SectionCardLink
                                section={SECTIONS.ORGANISATION}
                                text="Inhaler Adherence Study Patients"
                                path={generatePath(
                                    ROUTES_CHAIN.practicesBeastPatients,
                                    {
                                        orgId,
                                    },
                                )}
                            />
                        </li>
                    )}
                </StyledList>
            </StyledCardContent>
        </StyledCard>
    );
};
