import { Button, Feedback } from "@accurx/design";

import { isFilteredByInviteId } from "./VaccineAllPatientsInvited.helper";

export interface VaccineAllPatientsInvitedFilterFeedbackProps {
    searchTerm: string;
    serverLimitedResults: boolean;
    onClearFilter: () => void;
}

export const VaccineAllPatientsInvitedFilterFeedback = ({
    searchTerm,
    serverLimitedResults,
    onClearFilter,
}: VaccineAllPatientsInvitedFilterFeedbackProps): JSX.Element | null => {
    if (isFilteredByInviteId(searchTerm)) {
        return (
            <Feedback
                colour="information"
                content={
                    <>
                        The active filter may hide some patients in your list.
                        Clear the filter and then search for a patient to see
                        their current status.
                        <Button
                            theme="secondary"
                            text="Clear filter"
                            icon={{ name: "Cross", colour: "blue" }}
                            className="mt-2 float-right"
                            onClick={onClearFilter}
                        />
                    </>
                }
                props={{ className: "mb-2 w-100" }}
            />
        );
    }

    if (serverLimitedResults) {
        return (
            <Feedback
                colour="information"
                content={
                    <>
                        The active filter may only have returned some patient
                        matches for your search terms. Either clear the filter
                        to view the full list, or try to narrow down your search
                        further.
                        <Button
                            theme="secondary"
                            text="Clear filter"
                            icon={{ name: "Cross", colour: "blue" }}
                            className="mt-2 float-right"
                            onClick={onClearFilter}
                        />
                    </>
                }
                props={{ className: "mb-2 w-100" }}
            />
        );
    }

    return null;
};
