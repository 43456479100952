/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { Reducer } from "redux";

import { LoadingStatus } from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

export type TeamNotificationsReducerState = {
    loadingStatus: typeof LoadingStatus.Initial;
    teams: any[];
    users: any[];
    isEditable: boolean;
};

export const initialState: TeamNotificationsReducerState = {
    loadingStatus: LoadingStatus.Initial,
    teams: [],
    users: [],
    isEditable: false,
};

function updateTeamState(
    state: any,
    loadingStatus: any,
    teamId: any,
    userId: any,
    isMember?: any,
) {
    return state.teams.map((team: any) => {
        if (team.id === teamId) {
            if (team.users.find((x: any) => x.id === userId) !== undefined) {
                // User has been added to the team at least once before
                return {
                    ...team,
                    users: team.users.map((teamUser: any) => {
                        if (teamUser.id === userId) {
                            if (isMember !== undefined) {
                                // Don't update isMember unless we're processing a SUCCESS
                                return {
                                    ...teamUser,
                                    isMember: isMember,
                                    updateStatus: loadingStatus,
                                };
                            } else {
                                return {
                                    ...teamUser,
                                    updateStatus: loadingStatus,
                                };
                            }
                        }
                        return teamUser;
                    }),
                };
            } else if (isMember !== undefined) {
                // User has never been in this team
                return {
                    ...team,
                    users: [
                        ...team.users,
                        {
                            id: userId,
                            isMember: isMember,
                            updateStatus: loadingStatus,
                        },
                    ],
                };
            } else {
                return {
                    ...team,
                    users: [
                        ...team.users,
                        {
                            id: userId,
                            isMember: false, //If user not in group's list, user can't already be a member
                            updateStatus: loadingStatus,
                        },
                    ],
                };
            }
        } else {
            return team;
        }
    });
}

export const reducer: Reducer<TeamNotificationsReducerState> = (
    state = initialState,
    action: any,
): TeamNotificationsReducerState => {
    switch (action.type) {
        case CoreActions.LOAD_PRACTICE_TEAMS:
            return { ...state, loadingStatus: LoadingStatus.Loading };
        case CoreActions.LOAD_PRACTICE_TEAMS_FAIL:
            return { ...state, loadingStatus: LoadingStatus.Failed };
        case CoreActions.LOAD_PRACTICE_TEAMS_SUCCESS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                teams: action.response.teams.map((team: any) => {
                    return {
                        ...team,
                        users: team.users.map((user: any) => {
                            return {
                                ...user,
                                updateStatus: LoadingStatus.Initial,
                            };
                        }),
                    };
                }),
                users: action.response.practiceUsers,
                isEditable: action.response.isEditable,
            };
        case CoreActions.UPDATE_TEAM_MEMBERSHIP:
            return {
                ...state,
                teams: updateTeamState(
                    state,
                    LoadingStatus.Loading,
                    action.teamId,
                    action.userId,
                ),
            };
        case CoreActions.UPDATE_TEAM_MEMBERSHIP_FAIL:
            return {
                ...state,
                teams: updateTeamState(
                    state,
                    LoadingStatus.Failed,
                    action.teamId,
                    action.userId,
                ),
            };
        case CoreActions.UPDATE_TEAM_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                teams: updateTeamState(
                    state,
                    LoadingStatus.Loaded,
                    action.teamId,
                    action.userId,
                    action.isMember,
                ),
            };
        default:
            return state;
    }
};
