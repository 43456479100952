import React from "react";

import { Feedback, Link, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export type BatchMessageNhsAppAnouncementProps = {
    title: string;
    messageCopy: string;
};

const StyledPaddedText = styled(Text)`
    padding-bottom: ${Tokens.SIZES[2]};
`;

export const NhsAppAnouncementCard = ({
    title,
    messageCopy,
}: BatchMessageNhsAppAnouncementProps): JSX.Element => {
    return (
        <Feedback colour="learning" title={title}>
            <StyledPaddedText skinny>
                {`Patients who have the NHS App with notifications turned on will receive ${messageCopy} via the NHS App.
                    If patients don't read the message in the NHS App within 3 hours (or if the message fails to send), we will send them the same message via SMS.`}
            </StyledPaddedText>

            <Text as="span">
                {"See more about "}
                <Link
                    href="https://support.accurx.com/en/articles/8078648-how-to-use-batch-messaging-with-the-nhs-app"
                    openInNewTab
                >
                    <Link.Text text="sending batch messages via the NHS App" />
                    <Link.Icon />
                </Link>
            </Text>
        </Feedback>
    );
};
