import React from "react";

import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface VaccineDeliveryResetModalProps {
    showResetModal: boolean;
    onToggleModal: () => void;
    onSubmit: () => void;
}

export const VaccineDeliveryResetModal = ({
    showResetModal,
    onToggleModal,
    onSubmit,
}: VaccineDeliveryResetModalProps): JSX.Element => {
    return (
        <Modal
            isOpen={showResetModal}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h1" skinny>
                    Reset Invite
                </Text>
            </ModalHeader>
            <ModalBody className={"bg-cloud"}>
                <Text>
                    The patient has declined their vaccinations. Please only
                    reactivate this if you believe this was done in error or you
                    have confirmed this with the patient.
                </Text>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button
                    text="Back"
                    theme="secondary"
                    onClick={onToggleModal}
                    className="mx-1"
                />
                <Button text="Reset booking" onClick={onSubmit} />
            </ModalFooter>
        </Modal>
    );
};
