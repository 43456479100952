import { useConciergeSelector } from "domains/concierge/internal/context";
import { PatientRequestType } from "domains/concierge/internal/types/PatientsState";
import { PatientSummary } from "domains/concierge/types";

export const usePatientRequestTime = ({
    patientId,
    type,
}: {
    patientId: PatientSummary["patientId"] | null;
    type: PatientRequestType;
}): string | undefined => {
    return useConciergeSelector((state) =>
        patientId
            ? state.patients.requestTimes[`${patientId}-${type}`]
            : undefined,
    );
};
