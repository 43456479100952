import { z } from "zod";

import { PatientExternalIdSchema } from "./PatientExternalIdSchema";

export const PatientSummarySchema = z.object({
    ageYear: z.number(),
    dateOfBirth: z.string(),
    prefixName: z.string().nullable(),
    familyName: z.string().nullable(),
    firstName: z.string().nullable(),
    nickname: z.string().nullable(),
    gender: z.string(),
    patientId: z.string(),
    externalIds: z.array(PatientExternalIdSchema),
    demographicsLastRefreshed: z.string().nullable(),
});

export type PatientSummary = z.infer<typeof PatientSummarySchema>;
