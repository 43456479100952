import * as UI from "@accurx/design";
import { MAX_MESSAGE_LENGTH } from "domains/compose/constants";

type CharacterCountProps = {
    charactersUsed: number;
    maxCharacters: number;
};

export const CharacterCount = ({
    charactersUsed,
    maxCharacters,
}: CharacterCountProps) => {
    const isOverCharacterLimit = charactersUsed > MAX_MESSAGE_LENGTH;

    const colour: UI.Types.TextColour = isOverCharacterLimit ? "red" : "metal";

    return (
        <UI.Text
            skinny
            variant="preview"
            colour={colour}
            props={{
                "aria-label":
                    "Count of characters typed and the maximum allowed.",
            }}
        >
            {charactersUsed}/{maxCharacters}
        </UI.Text>
    );
};
