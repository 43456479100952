import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";

import { BodyInfoBox } from "../../../BodyInfoBox";
import { StyledPillButton } from "../AttachmentInfoBox.styles";
import { AttachmentSize } from "../AttachmentSize";

export const ClinicianAttachmentInfoBox = ({
    name,
    previewUrl,
    onAttachmentRemove,
    fileSize,
}: {
    name: string;
    previewUrl?: string;
    onAttachmentRemove?: () => void;
    fileSize?: number;
}) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header fullBorder={true} gap="2">
                <UI.Flex gap="1">
                    <BodyInfoBox.Icon name={"Paperclip"} />
                    {previewUrl ? (
                        <BodyInfoBox.Link
                            href={previewUrl}
                            openInNewTab={true}
                            onClick={() => {
                                track("AttachmentPreview Link Click", {
                                    ...nativeTrackingFields,
                                });
                            }}
                        >
                            <UI.Link.Text text={name} />
                            <UI.Link.Icon />
                        </BodyInfoBox.Link>
                    ) : (
                        <BodyInfoBox.Text colour="metal">
                            {name}
                        </BodyInfoBox.Text>
                    )}
                </UI.Flex>
                <UI.Flex gap="1" justifyContent="flex-end" alignItems="center">
                    {fileSize && <AttachmentSize size={fileSize} />}

                    {onAttachmentRemove && (
                        <StyledPillButton
                            onClick={onAttachmentRemove}
                            title="Remove attachment"
                        >
                            <Pill.Icon name="Cross" colour="red" size={3} />
                        </StyledPillButton>
                    )}
                </UI.Flex>
            </BodyInfoBox.Header>
        </BodyInfoBox.Wrapper>
    );
};
