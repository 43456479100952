import { Conversation, SuggestedPatientMatch } from "@accurx/concierge/types";

export type PatientMatchState =
    | { type: "Match" }
    | { type: "NoMatch" }
    | { type: "SuggestedMatch"; suggestedMatch: SuggestedPatientMatch }
    | null;

/** If a conversation was started by a patient triage request, return the state of the patient match:
 *
 * - `Match`: the conversation has been matched to a patient
 * - `NoMatch`: the conversation has not been matched to a patient
 * - `SuggestedMatch`: the conversation has not been matched to a patient, but there is a suggested patient
 *
 * If the conversation was not started by a patient triage request, return `null`.
 */
export const getPatientMatchState = (
    conversation: Conversation,
): PatientMatchState => {
    const firstConversationItem = conversation.items[0];

    if (firstConversationItem.contentType !== "PatientTriageRequestNote") {
        return null;
    }

    if (conversation.regardingPatientId) {
        return { type: "Match" };
    }

    if (firstConversationItem.suggestedMatch) {
        return {
            type: "SuggestedMatch",
            suggestedMatch: firstConversationItem.suggestedMatch,
        };
    }

    return { type: "NoMatch" };
};
