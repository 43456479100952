import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledInvisibleFileInput = styled.input`
    display: none;
`;

export const StyledButton = styled(UI.Button)`
    width: 100%;
`;
