import { Button, Icon, Text } from "@accurx/design";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";

import { ASSIGN_REFERRED_KEY } from "../../Conversation.constants";
import { StyledContainer } from "./NoUserFound.styles";

export const NoUserFound = (): JSX.Element => {
    const history = useHistory();
    const workspaceId = useWorkspaceId();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleInvite = () => {
        FlemingAnalyticsTracker.trackWorkspaceInviteStart({
            ...analyticsLoggedInProps,
            appOrigin: "AssignModal",
        });
        history.push(
            generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                workspaceId,
            }),
            { referredFrom: ASSIGN_REFERRED_KEY },
        );
    };

    return (
        <StyledContainer>
            <Icon
                id="edit_assignee"
                name="ReviewPerson"
                halo={{
                    colour: "red",
                    luminosity: "high",
                }}
                size={5}
                theme="Fill"
                title="Edit Assignee"
            />
            <Text variant="body">
                We can’t find any colleagues with that name
            </Text>
            <Text variant="body">
                Invite a colleague to join Accurx and view this conversation
            </Text>
            <Button
                icon={{ name: "Assign", style: "Fill", colour: "blue" }}
                text="Invite"
                theme="secondary"
                onClick={handleInvite}
            />
        </StyledContainer>
    );
};
