import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { copyToClipboard } from "domains/inbox/util/copyToClipboard";
import { toast } from "react-toastify";

type CopyToClipboardButtonProps = {
    getText: () => string;
};

export const CopyToClipboardButton = ({
    getText,
}: CopyToClipboardButtonProps) => {
    const onClickCopy = async () => {
        const { success } = await copyToClipboard(getText());
        if (success) {
            toast(
                <UI.Feedback
                    title="Copied to the clipboard"
                    colour="success"
                />,
            );
        } else {
            toast(
                <UI.Feedback
                    title="Couldn't copy to the clipboard"
                    colour="error"
                />,
            );
        }
    };

    return (
        <Pill.SecondaryButton
            dimension="small"
            onClick={() => {
                void (async () => {
                    await onClickCopy();
                })();
            }}
        >
            <Pill.Icon name="Copy" colour="blue" />
            <Pill.Text>Get a copy</Pill.Text>
        </Pill.SecondaryButton>
    );
};
