import { createMutationHook } from "@accurx/api/QueryClient";
import { Organisation, useCurrentUser } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";

import {
    IAllowedOrganisationResponse,
    PostUserProfileOrganisationResponse,
    PostUserProfileRequest,
} from "api/FlemingDtos";

import { ALLOWED_ORGANISATIONS_QUERY_KEY } from "../queries/useGetAllowedOrganisationsQuery";

export const useUpdateUserHealthcareProfileMutation = createMutationHook<
    PostUserProfileRequest,
    { organisationResponse: PostUserProfileOrganisationResponse }
>(({ queryClient }) => {
    const { update, user } = useCurrentUser();
    return {
        mutationKey: ["updateUserHealthcareProfile"],
        mutationFn: async ({
            healthcareRole,
            specialistArea,
            hasAcceptedTermsService,
            nationalCode,
        }) => {
            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/account/userprofile",
                }),
                {
                    healthcareRole,
                    specialistArea,
                    hasAcceptedTermsService,
                    nationalCode,
                },
            );

            return returnDataOrThrow(response);
        },
        onSuccess: (data, variables) => {
            const onboarding = user.onboarding && {
                ...user.onboarding,
                hasAcceptedTermsService:
                    variables.hasAcceptedTermsService ||
                    user.onboarding.hasAcceptedTermsService,
            };

            let organisations = user.organisations;

            // If there was a nationalCode in the request, then a request
            // to join an organisation was made and we should update
            // the organisations.
            if (variables.nationalCode && data?.organisationResponse) {
                organisations = data.organisationResponse
                    .organisations as Organisation[];

                queryClient.setQueryData(
                    [ALLOWED_ORGANISATIONS_QUERY_KEY, {}],
                    (
                        allowedOrganisationsCache: IAllowedOrganisationResponse[] = [],
                    ) => {
                        return allowedOrganisationsCache.filter(
                            ({ nationalCode }) =>
                                nationalCode !== variables.nationalCode,
                        );
                    },
                );
            }

            update({ ...user, onboarding, organisations });
        },
    };
});
