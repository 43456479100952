import { TabProps } from "@accurx/design";
import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";
import keyBy from "lodash/keyBy";

export const PowerBIReportPages = {
    booking: "booking",
    questionnaire: "questionnaire",
};

type PowerBIReportPageViewProps = {
    currentTab: string;
    navigationOrigin: string;
};

export const TABS: TabProps[] = [
    { text: "Questionnaire", tabId: "questionnaire" },
    { text: "Booking", tabId: "booking" },
];

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;

/**
 * When user navigates to the PowerBI Embedded Report page
 *
 * Event type: Page view
 * */
export const trackPowerBIReportPageView = (
    props: LoggedInCustomProperties & PowerBIReportPageViewProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "Reporting",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};
