import {
    FloreyOwner,
    FloreyTemplate,
    MessageTemplate,
} from "app/batchMessage/gp/BatchMessage.types";

export type TemplatesByHeader = {
    [group: string]: MessageTemplate[];
};

export enum MessageErrorType {
    None = "None",
    Warning = "Warning",
    Error = "Error",
}

export type FloreyDropdownTemplate = FloreyTemplate & {
    path: string;
    source: FloreyOwner;
};

export type FloreyDropdownByGroupHeader = {
    [group: string]: FloreyDropdownTemplate[];
};
