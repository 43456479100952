import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateSaveConversationItemToRecordPayload,
    mutateSaveConversationItemToRecord,
} from "domains/native/api/endpoints/mutateSaveConversationItemToRecord";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

export const useSaveConversationItemToRecordMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        MutateSaveConversationItemToRecordPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "SaveConversationItemToRecordMutation: Called with no native transport",
            );
        }

        await mutateSaveConversationItemToRecord(
            {
                transport,
                workspaceId,
            },
            variables,
        );
    }, options);
};
