import React, { ReactNode } from "react";

import styled from "styled-components";

const StyledBody = styled.main`
    overflow: auto;
    height: 100%;
`;

export const Body = ({ children }: { children: ReactNode }) => {
    return <StyledBody>{children}</StyledBody>;
};

Body.displayName = "QuickViewBody";
