import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

const {
    COLOURS: { primary, greyscale },
} = UI.Tokens;

export const StyledUrgentButton = styled(Pill.Button)<{
    $isSelected?: boolean;
}>`
    ${({ $isSelected }) =>
        $isSelected &&
        `
            background: ${primary.red[10]};

            &:enabled:not(:focus) {
                box-shadow: inset 0 -2px 0 ${primary.red[50]},
                    inset 0 0 0 1px ${primary.red[50]};
            }

            &:focus,
            &:active {
                background: ${primary.red[10]};
            }
    `}

    &:hover:not(:focus, :disabled) {
        box-shadow: inset 0 -2px 0 ${primary.red[100]},
            inset 0 0 0 1px ${primary.red[100]};
    }

    ${({ $isSelected }) =>
        !$isSelected &&
        `
            &:hover,
            &:active,
            &:focus {
                svg path {
                    fill: ${primary.red[100]};
                }

                span {
                    color: ${primary.red[100]};
                }
            }

            &:focus,
            &:active {
                background: ${primary.red[5]};
            }
    `}
`;

export const StyledUrgentLabelText = styled(Pill.Text)<{
    $isSelected?: boolean;
}>`
    ${({ $isSelected }) =>
        `
            color: ${$isSelected ? primary.red[130] : greyscale.zinc};
    `}
`;
