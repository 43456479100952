import * as UI from "@accurx/design";

import { StyledIconButton } from "./ExpandMinimiseBar.styles";

export const ExpandMinimiseBar = ({
    children,
    onExpandClick,
    isHeightRestricted,
    onMinimiseClick,
}: {
    children: JSX.Element;
    onExpandClick?: () => void;
    onMinimiseClick?: () => void;
    isHeightRestricted: boolean;
}) => (
    <UI.Flex justifyContent="space-between" alignItems="center" gap="1">
        {children}
        <UI.Flex gap="1">
            {onExpandClick && (
                <StyledIconButton
                    aria-label={isHeightRestricted ? "Expand" : "Collapse"}
                    icon={{
                        name: isHeightRestricted ? "Expand" : "Collapse",
                        title: isHeightRestricted ? "Expand" : "Collapse",
                    }}
                    onClick={onExpandClick}
                />
            )}
            {onMinimiseClick && (
                <StyledIconButton
                    aria-label="Minimise"
                    icon={{ name: "Minimise", title: "Minimise" }}
                    onClick={onMinimiseClick}
                />
            )}
        </UI.Flex>
    </UI.Flex>
);
