import * as UI from "@accurx/design";

import { BodyInfoBox } from "../../../BodyInfoBox";
import { SpinnerContainer } from "./ClinicianAttachmentInProgressInfoBox.styles";

export const ClinicianAttachmentInProgressInfoBox = ({
    name,
}: {
    name: string;
}) => (
    <BodyInfoBox.Wrapper>
        <BodyInfoBox.Header fullBorder={true}>
            <UI.Flex gap="1" alignItems="center">
                <SpinnerContainer alignItems="center" justifyContent="center">
                    <UI.Spinner
                        hiddenLabel="Uploading attachment"
                        dimension="extra-small"
                    />
                </SpinnerContainer>
                <BodyInfoBox.Text colour="metal">{name}</BodyInfoBox.Text>
            </UI.Flex>
        </BodyInfoBox.Header>
    </BodyInfoBox.Wrapper>
);
