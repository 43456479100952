import { ReactNode } from "react";

import { useAuth, useFeatureFlag } from "@accurx/auth";
import {
    NativeTransportProvider,
    isSingleSignalRSupportedDesktopVersion,
    useBrowserEnvironment,
    useDesktopVersion,
} from "@accurx/native";
import { useHubClientTransport } from "domains/transport/hooks/useHubClientTransport";
import { useNativeSignalRTransport } from "domains/transport/hooks/useNativeSignalRTransport";
import { useOptionalHubClient } from "domains/transport/hooks/useOptionalHubClient";
import { useSignalRConnectionState } from "domains/transport/hooks/useSignalRConnectionState";

import { TransportContext } from "./TransportContext";

const TransportContextProvider = ({ children }: { children: ReactNode }) => {
    const env = useBrowserEnvironment();
    const { user } = useAuth();
    const isFeatureFlagEnabled = useFeatureFlag(
        "UnifiedInboxSingleSignalRConnection",
    );
    const desktopVersion = useDesktopVersion();

    const hubClient = useOptionalHubClient({
        currentUserId: user.isLoggedIn ? user.accuRxUserId : null,
    });
    const connectionState = useSignalRConnectionState(hubClient);

    const hubClientTransport = useHubClientTransport(hubClient);
    const nativeTransport = useNativeSignalRTransport();

    const shouldUseNativeTransport =
        isFeatureFlagEnabled &&
        env === "WebView" &&
        isSingleSignalRSupportedDesktopVersion(desktopVersion);
    const transport = shouldUseNativeTransport
        ? nativeTransport
        : hubClientTransport;

    return (
        <TransportContext.Provider value={{ transport, connectionState }}>
            {children}
        </TransportContext.Provider>
    );
};

export const TransportProvider = ({ children }: { children: ReactNode }) => {
    return (
        <NativeTransportProvider>
            <TransportContextProvider>{children}</TransportContextProvider>
        </NativeTransportProvider>
    );
};
