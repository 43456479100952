/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Button, Icon, Text } from "@accurx/design";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

export interface VaccineApprovalDropdownProps {
    onAction?: (isApproved: boolean) => void;
    onActionLeave?: () => void;
}

export const VaccineApprovalDropdown = ({
    onAction,
    onActionLeave,
}: VaccineApprovalDropdownProps): JSX.Element => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = (): void => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    return (
        <Dropdown isOpen={isDropdownOpen} toggle={handleToggleDropdown}>
            <DropdownToggle tag="div" aria-expanded={isDropdownOpen}>
                <Button
                    theme="secondary"
                    icon={{
                        name: "More",
                        style: "Fill",
                        title: "Toggle dropdown",
                        id: "toggle-dropdown",
                    }}
                />
            </DropdownToggle>
            <DropdownMenu right={true}>
                {onAction && (
                    <>
                        <DropdownItem
                            tag="div"
                            className="d-flex p-1 cursor-pointer"
                            onClick={(): void => onAction(true)}
                        >
                            <Icon theme="Line" name="Tick" size={3} />
                            <Text variant="label" as="span" skinny>
                                Approve
                            </Text>
                        </DropdownItem>
                        <DropdownItem
                            tag="div"
                            className="d-flex p-1 cursor-pointer"
                            onClick={(): void => onAction(false)}
                        >
                            <Icon
                                theme="Line"
                                name="Cross"
                                size={3}
                                colour="red"
                            />
                            <Text variant="label" as="span" colour="red" skinny>
                                Decline
                            </Text>
                        </DropdownItem>
                    </>
                )}
                {onActionLeave && (
                    <DropdownItem
                        tag="div"
                        className="d-flex p-1 cursor-pointer"
                        onClick={onActionLeave}
                    >
                        <Icon theme="Line" name="Cross" size={3} colour="red" />
                        <Text variant="label" as="span" colour="red" skinny>
                            Leave
                        </Text>
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};
