import { ReactNode } from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFooterActionsWrapper = styled.div<{
    additionalActions?: ReactNode;
}>`
    display: flex;
    justify-content: ${(props) =>
        props.additionalActions ? "space-between" : "flex-end"};
`;

export const StyledFooterGroupedActions = styled.div`
    display: inherit;
`;

export const StyledFooterErrorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: ${Tokens.SIZES[0.5]};
`;
