import { Observable, Subject, Unsubscribable } from "rxjs";
import { map } from "rxjs/operators";

import { createPatientSubscription } from "./PatientSubscription";
import {
    PatientManager as PatientManagerType,
    PatientSubscription,
    PatientSubscriptionRequest,
} from "./types/PatientManager.types";
import { PatientSummary } from "./types/patient.types";

/**
 * PatientManager
 *
 * This class acts as a data store for patients inside a single workspace.
 * Because patients come from several places like API calls and realtime
 * updates, PatientManager allows you to connect multiple feeds for source
 * data.
 *
 * Clients can retreive a patient by asking for a patient subscription and
 * when any connected feed contains an update to that patient the update
 * will flow through to that subscription.
 */
class PatientManager implements PatientManagerType {
    /**
     * The patient store contains all patients that are sent through
     * any connected feed. We use the patient store to hydrate patient
     * subscriptions with their initial value.
     */
    private readonly patientStore = new Map<string, PatientSummary>();

    /**
     * The outbound feed is a feed of all patient updates that gets
     * multicasted to all subscriptions this manager creates. Each
     * subscription chooses which events it cares about.
     */
    private readonly outboundFeed = new Subject<PatientSummary[]>();

    /**
     * It's important we unsubscribe from RxJS handles when the manager
     * is torn down. We store them all here so that we can unsubscribe
     * from them later.
     */
    private readonly subscriptionHandles: Unsubscribable[] = [];

    constructor(public readonly workspaceId: number) {
        this.processUpdates = this.processUpdates.bind(this);
    }

    /**
     * getPatient - returns a subscription for a single patient summary
     *
     * @param request - an identity object that contains the patient's internal ID
     * @returns the patient subscription
     */
    public getPatient(
        request: PatientSubscriptionRequest,
    ): PatientSubscription {
        const patient = this.patientStore.get(request.patientIdentity.id);

        return createPatientSubscription(
            request.patientIdentity,
            patient,
            this.outboundFeed,
        );
    }

    public unsubscribe(): void {
        this.outboundFeed.unsubscribe();
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
    }

    /**
     * Allows you to connect a feed of patient updates from an external source.
     * We, for example, want to feed in updates from API responses that contain
     * referenced patient objects.
     */
    public connectFeed(feed: Observable<PatientSummary[]>): Unsubscribable {
        const handle = feed
            .pipe(map(this.processUpdates))
            .subscribe(this.outboundFeed);

        this.subscriptionHandles.push(handle);

        return handle;
    }

    private processUpdates(updates: PatientSummary[]): PatientSummary[] {
        updates.forEach((patient) =>
            this.patientStore.set(patient.patientId, patient),
        );

        return updates;
    }
}

export const createPatientManager = (
    ...args: ConstructorParameters<typeof PatientManager>
): PatientManagerType => {
    return new PatientManager(...args);
};
