import { ReactNode } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useHistory } from "react-router";

import { getOverviewPagePath } from "../../Routes";
import { NavigationBar, PageContainer, PageTitle } from "../../components";

export const Page = ({ children }: { children: ReactNode }) => {
    const { orgId } = useCurrentWorkspace();
    const history = useHistory();
    const path = getOverviewPagePath(orgId);

    return (
        <>
            <NavigationBar onBackButtonClick={() => history.push(path)} />
            <PageContainer justifyContent="center">
                <PageTitle title="Edit your reminder" />
                {children}
            </PageContainer>
        </>
    );
};
