import { MessagePatientWithToken } from "domains/concierge/types/messagePatient.types";

import * as PatientMessagingApiClient from "../PatientMessagingApiClient";
import { mapMessagePatientResponseItemsToConciergeUpdateConversationItems } from "../mappers/mapMessagePatientResponseToConciergeUpdateConversationItems";
import { mapMessagePatientWithTokenRequests } from "../mappers/mapMessagePatientWithTokenRequests";

export const messagePatientWithToken = async (arg: MessagePatientWithToken) => {
    const request = mapMessagePatientWithTokenRequests(arg);

    const response = await PatientMessagingApiClient.messagePatientWithToken(
        request,
    );

    const mappedItems =
        mapMessagePatientResponseItemsToConciergeUpdateConversationItems(
            response,
        );

    return {
        updates: {
            conversations: [
                {
                    ...arg.conversation,
                    items: mappedItems ?? [],
                },
            ],
            teams: [],
            users: [],
            patients: [],
        },
    };
};
