import { ReactNode, useEffect, useRef, useState } from "react";

import { Button } from "@accurx/design";
import { BackButton } from "@accurx/navigation";
import throttle from "lodash/throttle";
import { createPortal } from "react-dom";

import { Container } from "app/layout/Container";

import {
    StyledBackButtonWrapper,
    StyledWrapper,
} from "./NavSubMenuComponent.styles";

export const PORTAL_ID_NAV_SUBMENU = "sub-nav-menu-root";

export type NavSubMenuBackButtonProps = {
    backCallback: () => void;
    backButtonDisabled?: boolean;
    /** For overriding styling if needed */
    className?: string;
};

/**
 * @deprecated - use BackButton from @accurx/navigation instead
 */
export const NavSubMenuBackButton = ({
    backCallback,
    backButtonDisabled,
    className,
}: NavSubMenuBackButtonProps) => {
    return (
        <StyledBackButtonWrapper className={className}>
            <Button
                onClick={backCallback}
                text="Back"
                icon={{
                    name: "ArrowTail",
                    colour: "metal",
                    rotation: "left",
                    style: "Line",
                }}
                theme="transparent"
                disabled={backButtonDisabled}
                data-userflow-id="nav-back"
            />
        </StyledBackButtonWrapper>
    );
};

type NavSubMenuComponentProps = {
    /**
     * [optional] Whether the content inside the submenu should extend right
     * to the bottom of the container, as opposed to there being some padding
     * at the bottom? e.g. if you're using a tab menu in the sub-nav then you
     * probably want to set this to true.
     */
    contentExtendsToBottomEdge?: boolean;
    sticky?: boolean;
    backCallback?: () => void;
    backButtonDisabled?: boolean;
    children?: ReactNode;
};

export const NavSubMenuComponent = ({
    children,
    contentExtendsToBottomEdge = false,
    sticky = false,
    backCallback,
    backButtonDisabled = false,
}: NavSubMenuComponentProps): JSX.Element | null => {
    const el = useRef(document.createElement("div"));
    const [isStuck, setIsStuck] = useState<boolean>(false);
    const subMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const current = el.current;

        const navSubMenuRoot = document.querySelector<HTMLDivElement>(
            `#${PORTAL_ID_NAV_SUBMENU}`,
        );

        if (navSubMenuRoot === null) return;

        navSubMenuRoot.appendChild(current);
        return (): void => void navSubMenuRoot.removeChild(current);
    }, []);

    useEffect(() => {
        if (sticky === false) {
            return;
        }

        const handleScroll = throttle((): void => {
            if (subMenuRef.current !== null) {
                const subMenuTop = subMenuRef.current.offsetTop;
                const scrollPosition = document.documentElement.scrollTop;
                setIsStuck(scrollPosition > subMenuTop);
            }
        }, 100);

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [sticky]);

    const yPadding = contentExtendsToBottomEdge
        ? "pt-3 pt-md-4" // pad top only
        : "py-3 py-md-4"; // pad top and bottom

    const stuckSubMenu = (
        <div className="sub-menu-border mb-4 fixed-top bg-white">
            <div className={`${yPadding} container`}>{children}</div>
        </div>
    );

    const backButton = backCallback ? (
        <BackButton
            backCallback={backCallback}
            backButtonDisabled={backButtonDisabled}
        />
    ) : null;

    const childrenWithSubmenuWrapper = (
        <>
            {sticky && isStuck && stuckSubMenu}
            <div className="bg-white sub-menu-border" ref={subMenuRef}>
                {backButton}
                <Container>
                    <StyledWrapper
                        contentExtendsToBottomEdge={contentExtendsToBottomEdge}
                    >
                        {children}
                    </StyledWrapper>
                </Container>
            </div>
        </>
    );

    const contentToRender = children ? childrenWithSubmenuWrapper : backButton;

    return createPortal(contentToRender, el.current) || null;
};
