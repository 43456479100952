export interface VaccineSiteLink {
    nationalCode: string;
    name: string;
    isApproved: boolean;
    expiryTime: string | null;
}

export interface VaccineSiteSetup {
    id: string;
    locationText: string;
    links: VaccineSiteLink[];
}

export interface VaccineSiteSession {
    id: string;
    startTime: string;
    endTime: string;
    patientsPerHour: number;
    type: number;
    locationText: string;
    isPopulated: boolean;
    isPublished: boolean;
    totalBooked: number;
    reservedForClinicianBooking: boolean;
    vaccineDoseRestriction: VaccineSiteSessionDoseRestrictionStrings;
}

// See RecallType in backend's RecallSetup.cs
export enum VaccineSiteSessionRecallType {
    None,
    CovidPfizer,
    CovidAstraZeneca,
    CovidModerna,
    CovidJanssen,
    CovidPfizerPaediatric,
    CovidMedicago,
    CovidNovavax,
    CovidValneva,
    CovidSinopharm,
    CovidCovishield,
    CovidCoronaVac,
    CovidCovaxin,
    CovidSanofi,
}

// See VaccineDoseRestriction in backend's RecallSetup.cs
export enum VaccineSiteSessionDoseRestriction {
    None = "None",
    Both = "Both",
    FirstOnly = "FirstOnly",
    SecondOnly = "SecondOnly",
    Booster = "Booster",
    All = "All",
}
export type VaccineSiteSessionDoseRestrictionStrings =
    keyof typeof VaccineSiteSessionDoseRestriction;

// NOTE: not actually a DTO, used to store state for rendering the table
export interface VaccineSiteSessionDetails {
    id: string;
    date: string;
    startTime: string;
    endTime: string;
    patientsPerHour: number;
    capacity: number;
    type: number;
    locationText: string;
    isPopulated: boolean;
    isPublished: boolean;
    totalBooked: number;
    reservedForClinicianBooking: boolean;
    vaccineDoseRestriction: VaccineSiteSessionDoseRestrictionStrings;
}

interface VaccineOrgBasic {
    name: string;
    nationalCode: string;
}

export type VaccineSiteSessionDetailsType = keyof VaccineSiteSessionDetails;

export interface VaccineSiteSessionErrorResponse {
    message: string;
}

export interface VaccineODSSearchResponse {
    results: VaccineOrgBasic[];
}

// Requests
export interface VaccineSiteSetupResponse {
    setups: VaccineSiteSetup[];
}

export interface VaccineSiteSetupLocationRequest {
    locationText: string;
}

export interface VaccineSiteSetupLinksRequest {
    nationalCode: string;
}

export interface VaccineSiteSetupSessionResponse {
    sessions: VaccineSiteSession[];
}
