import { Button, Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTriggerButton = styled(Button).attrs({
    theme: "success",
    icon: {
        name: "ArrowTwoWay",
        style: "Fill",
        rotation: "left",
        title: "Switch workspace",
    },
})`
    &[data-state="open"] {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
    }
`;

export const StyledArchivedBadge = styled(Ds.Badge)`
    margin-top: ${Tokens.SIZES[0.5]};
`;
