import { Tokens } from "@accurx/design";
import styled from "styled-components";

/* The container needs some additional top margin if there is a div 
   (in this case a SnomedCard) before it on the page. When there is no
   div before it, it should have no additional spacing so it appears directly
   under the field header. */
export const StyledSelectContainer = styled.div`
    div ~ & {
        margin-top: ${Tokens.SIZES[2]};
    }
`;
