import { Ds } from "@accurx/design";
import styled from "styled-components";

import { TokenOverrides } from "./tokenOverrides";

export const SplitButtonGroup = styled(Ds.Flex)`
    & > button {
        border-radius: 0;

        &:first-child {
            border-radius: ${TokenOverrides.BORDERS.radius} 0 0
                ${TokenOverrides.BORDERS.radius};
        }
        &:last-child {
            border-radius: 0 ${TokenOverrides.BORDERS.radius}
                ${TokenOverrides.BORDERS.radius} 0;
        }
        &:not(:last-child) {
            border-right: 0;
        }

        // When it is the only button in the group we need border radius on all corners
        &:first-child:last-child {
            border-radius: ${TokenOverrides.BORDERS.radius};
        }

        &:focus,
        &:focus-within {
            z-index: 9999;
        }
    }
`;
