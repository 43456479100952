import { useEffect, useRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";

import { HubClient } from "shared/hubClient/HubClient";
import { useHubClient } from "shared/hubClient/useHubClient";

export type OptionalHubClient = HubClient | null;

type Props = {
    currentUserId: string | null;
};

export const useOptionalHubClient = ({
    currentUserId,
}: Props): OptionalHubClient => {
    const isHubClientOptional = useFeatureFlag(
        "UnifiedInboxSingleSignalRConnection",
    );

    // `env` should realistically never change
    const env = useBrowserEnvironment();

    /**
     * We are using useHubClient here so that we don't create an extra connection to SignalR.
     * Ideally, we will want to call createHubClient() in here ourselves only when we want to.
     *
     * TODO: Remove useHubClient() and call createHubClient() in the useRef below when migrating away from using HubClientProvider
     */
    const legacyHubClient = useHubClient();

    // if `isHubClientOptional` is false, we always want to create a HubClient connection
    const hubClient = useRef<OptionalHubClient>(
        !isHubClientOptional || env === "SignalR" ? legacyHubClient : null,
    );

    useEffect(() => {
        const hubClientInstance = hubClient.current;

        // Reconnect to HubClient when the user changes
        if (currentUserId && hubClientInstance) {
            void hubClientInstance.connect();

            return () => {
                void hubClientInstance.disconnect();
            };
        }
    }, [currentUserId]);

    return hubClient.current;
};
