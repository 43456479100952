import React, { ReactNode } from "react";

import { Text } from "@accurx/design";

import {
    StyledBody,
    StyledFooter,
    StyledHeading,
    StyledModalContent,
} from "./ModalContent.styles";

// This composable component is being developed to be eventually moved into the design library to work with the `Modal` component
// For now, it's only being used in fleming and the design is under construction

const Header = ({ children }: { children: ReactNode | string }) => {
    return (
        <StyledHeading>
            {typeof children === "string" ? (
                <Text skinny variant="subtitle" as="h2">
                    {children}
                </Text>
            ) : (
                children
            )}
        </StyledHeading>
    );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
    return <StyledFooter>{children}</StyledFooter>;
};

const Body = ({ children }: { children: React.ReactNode }) => {
    return <StyledBody>{children}</StyledBody>;
};

const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <StyledModalContent
            justifyContent="space-evenly"
            flexDirection={"column"}
            gap="3"
        >
            {children}
        </StyledModalContent>
    );
};

export const ModalContent = { Header, Footer, Body, Layout };
