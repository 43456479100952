import { Tokens, focusBorderStyles } from "@accurx/design";
import { TokenOverrides } from "@accurx/navigation";
import { css } from "styled-components";

export const mobileNavItemStyles = css`
    align-items: center;
    border: 1px solid transparent;
    border-radius: ${TokenOverrides.BORDERS.radius};
    display: flex;
    padding: ${Tokens.SIZES[1.5]};
    transition-property: background-color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;

    &[data-active="true"],
    &[data-state="open"] {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
        text-decoration: none;
    }

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        ${focusBorderStyles};
    }
`;
