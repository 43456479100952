import React, { FormEvent, useState } from "react";

import { PatientListType } from "@accurx/api/patient-messaging";
import {
    Button,
    Checkbox,
    Text,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { truncateLongWord } from "shared/formatters/String";
import { useAppSelector } from "store/hooks";

import {
    AlreadySharedParagraph,
    SubHeading,
    TooltipTriggerContent,
} from "./PatientListWorkspaceSharingModalComponent.styles";
import { actionCreators } from "./PatientListsActions";

const ListWorkspaceSharingModal = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);

    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );

    const currentListSharingRequestStarted = useAppSelector(
        ({ patientLists }) =>
            patientLists.currentListWorkspaceSharingRequestStarted,
    );

    const workspaceSharingModalOpen = useAppSelector(
        ({ patientLists }) => patientLists.workspaceSharingModalOpen,
    );

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    }, shallowEqual);
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    useAccurxWebTitle(
        workspaceSharingModalOpen
            ? "Share patient list with workspace"
            : "View patient list",
    );

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();

        analyticsPatientListProps &&
            FlemingAnalyticsTracker.trackPatientListShareButtonClick({
                ...analyticsPatientListProps,
                ...meaningfulActionProps,
                shareType: "WorkspaceShare",
            });

        if (!organisationId || !currentList?.patientListId) {
            return;
        }

        dispatch(
            actionCreators.sharePatientListWithWorkspace(
                {
                    organisationId,
                    patientListId: currentList.patientListId,
                },
                handleCloseModal,
            ),
        );
    };

    const handleCheck = () => setChecked(!checked);

    const handleCloseModal = (): void => {
        setChecked(false);
        dispatch(actionCreators.closePatientListWorkspaceSharingModal());
    };

    const isAlreadyShared =
        currentList?.listType === PatientListType.WorkspaceManaged;

    return (
        <Modal
            isOpen={workspaceSharingModalOpen}
            backdrop="static"
            style={{ marginBottom: "6rem" }}
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h3" colour="zinc" skinny>
                    Share {truncateLongWord(currentList?.name ?? "", 24)}
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Text variant="body">
                    Invite Accurx users from{" "}
                    <Text variant="label" as="span">
                        {orgName}
                    </Text>{" "}
                    to have access to this list.{" "}
                    <Tooltip>
                        <TooltipTrigger>
                            <TooltipTriggerContent>
                                What can they do?
                            </TooltipTriggerContent>
                        </TooltipTrigger>
                        <TooltipContent>
                            Colleagues you share with can contact patients from
                            the list.
                        </TooltipContent>
                    </Tooltip>
                </Text>
                <SubHeading variant="label">
                    Share with everyone in {orgName}
                </SubHeading>
                {isAlreadyShared ? (
                    <AlreadySharedParagraph>
                        This list was already shared with everyone at{" "}
                        <Text variant="label" as="span">
                            {orgName}
                        </Text>
                    </AlreadySharedParagraph>
                ) : (
                    <Checkbox
                        id="shareWithEveryone"
                        text="Share with everyone"
                        theme="bordered"
                        checked={checked}
                        onCheckChange={handleCheck}
                    />
                )}
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button
                    onClick={handleCloseModal}
                    text="Cancel"
                    theme="secondary"
                />
                <Button
                    onClick={handleSubmit}
                    text="Share list"
                    disabled={!checked || currentListSharingRequestStarted}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ListWorkspaceSharingModal;
