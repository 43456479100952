import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { User } from "@accurx/auth";
import { ConversationItem } from "@accurx/concierge/types";
import { useDeepMemo } from "@accurx/hooks";
import { Log } from "@accurx/shared";
import { startOfDay } from "date-fns";
import { Alignment } from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import sortBy from "lodash/sortBy";

/**
 * Takes a flat list of conversation items and groups them into day buckets.
 * Each bucket is a tuple containing the ISO 8601 date string and the list of
 * items.
 *
 * This is useful for displaying items in the conversation thread grouped by
 * date.
 */
export const groupConversationItemsByDate = (
    items: ConversationItem[],
): [string, ConversationItem[]][] => {
    const bucketsMap = new Map<string, ConversationItem[]>();

    items.forEach((item) => {
        const bucketDate = startOfDay(new Date(item.createdAt));
        const bucketKey = bucketDate.toISOString();

        const bucket = bucketsMap.get(bucketKey);

        if (bucket) {
            bucket.push(item);
        } else {
            bucketsMap.set(bucketKey, [item]);
        }
    });

    return sortBy(Array.from(bucketsMap), [0]);
};

/**
 * The conversation thread doesn't render all conversation items equally.
 * Specifically we hide link notes in some cases:
 * - Video consult links are always hidden because they are instead rendered as
 *   part of the item they link to
 * - Failed delivery receipts are hidden if they come immedietly after the item
 *   they link to. If any other activity happens on the conversation between the
 *   failed item and its delivery receipt we do show the delivery receipt
 *   separately.
 */
export const filterOutLinkNotes = (
    items: ConversationItem[],
): ConversationItem[] => {
    return items.filter((item, index) => {
        // We never want to show video consult links on their own. They will
        // always be displayed as part of the linked item.
        if (item.contentType === "VideoConsultLink") {
            return false;
        }

        // If the failed delivery receipt for an item comes immedietly after the
        // item that failed to send we do not need to display the failed
        // delivery receipt.
        if (
            item.contentType === "FailedDeliveryReceiptLink" &&
            items[index - 1] &&
            items[index - 1].id === item.linkedItemId
        ) {
            return false;
        }

        return true;
    });
};

export const getNewMessagesMarkerInfo = (
    visibleItems: ConversationItem[],
    unreadItems: ConversationItem[],
) => {
    const visibleUnreadItems = visibleItems.filter(
        (item) => unreadItems.indexOf(item) >= 0,
    );

    if (visibleUnreadItems.length === 0) {
        return null;
    }

    return {
        message: visibleUnreadItems[0],
    };
};

const logUnusedRef = (name: string) => {
    Log.error(`
        useThreadScrollBehaviour used but ${name} not passed to a DOM element.
        This could result in strange behaviour in the conversation thread.
    `);
};

const scrollToBottom = (el: Element): void => {
    el.scrollTop = el.scrollHeight;
};

/**
 * useThreadScrollBehaviour - The conversation thread has some interesting
 * scroll behaviour that this hook provides.
 *
 * Accepts:
 * - a list of conversation item IDs - it uses this to know when new items
 *    appear in the thread
 *
 * It returns 3 refs that you should pass to DOM nodes:
 * 1. containerRef - pass this to the scrolling container element
 * 2. bottomAnchorRef - pass this to an element that sits at the bottom of the
 *    thread
 * 3. newMessagesMarkerRef - pass this to the "New marker" message element
 *
 * The conversation thread has some interesting scroll behaviour that this hook
 * provides:
 * 1. When the conversation first loads it scrolls to the bottom to display the
 *    most recent items UNLESS there are any unread items, in which case it
 *    scrolls up to show the "New messages" marker.
 * 2. When scrolled to the bottom it stays scrolled to the bottom when any new
 *    messages are displayed, when the "reply" UI opens or when the window
 *    resizes.
 * 3. If you scroll up to view previous messages it never automatically scrolls
 *    back down to the bottom in any of the cases mentioned in point 2.
 */
export const useThreadScrollBehaviour = ({
    itemIds,
}: {
    itemIds: string[];
}) => {
    // Keep track of three DOM nodes
    // 1. The scrolling container element
    // 2. The anchor at the bottom of the scrollable container
    // 3. The "New messages" marker element
    const containerRef = useRef<HTMLElement>(null);
    const bottomAnchorRef = useRef<HTMLDivElement>(null);
    const newMessagesMarkerRef = useRef<HTMLLIElement>(null);

    // Declare an internal flag that stores wether the container element is
    // currently scrolled to the bottom or not. Keep it in state to allow
    // consumers to rerender based on a change but also keep a ref so we can use
    // it inside effects without it rerunning the effect.
    const [isAtBottom, setIsAtBottom] = useState(false);
    const isAtBottomRef = useRef<boolean>(false);
    isAtBottomRef.current = isAtBottom;

    // When mounting we do a few things:
    // 1. if there is a new messages marker scroll up to it, otherwise scroll
    //    the thread down to the bottom.
    // 2. set up an intersection observer that updates our internal "isAtBottom"
    //    flag when the anchor element at the bottom of the thread comes into
    //    view (i.e. when the thread is scrolled to the bottom).
    // 3. set up a resize observer that ensures the thread stays scrolled to the
    //    bottom when the window resizes or when the reply bar is opened.
    useLayoutEffect(() => {
        if (!containerRef.current) {
            logUnusedRef("containerRef");
            return;
        }

        if (!bottomAnchorRef.current) {
            logUnusedRef("bottomAnchorRef");
            return;
        }

        // Step 1: if there is a new messages marker scroll up to it, otherwise
        // scroll the thread down to the bottom.
        if (newMessagesMarkerRef.current) {
            newMessagesMarkerRef.current.scrollIntoView();
        } else {
            scrollToBottom(containerRef.current);
        }

        // Step 2: set up an intersection observer that updates our internal
        // "isAtBottom" flag when the anchor element at the bottom of the thread
        // comes into view (i.e. when the thread is scrolled to the bottom).
        const intersectionObserver = new IntersectionObserver(
            (event) => {
                setIsAtBottom(event[0]?.isIntersecting);
            },
            {
                root: containerRef.current,
                threshold: 1,
            },
        );

        // Step 3: set up a resize observer that ensures the thread stays
        // scrolled to the bottom when the window resizes or when the reply bar
        // is opened.
        const resizeObserver = new ResizeObserver(() => {
            if (!containerRef.current) {
                logUnusedRef("containerRef");
                return;
            }

            if (isAtBottomRef.current) {
                scrollToBottom(containerRef.current);
            }
        });

        intersectionObserver.observe(bottomAnchorRef.current);
        resizeObserver.observe(containerRef.current);

        return () => {
            intersectionObserver.disconnect();
            resizeObserver.disconnect();
        };
    }, []);

    // When new items appear in the conversation we'll want to scroll the
    // container element down to show the new items
    const itemIdsMemo = useDeepMemo(itemIds);
    useEffect(() => {
        if (!containerRef.current) {
            logUnusedRef("containerRef");
            return;
        }

        if (isAtBottomRef.current) {
            scrollToBottom(containerRef.current);
        }
    }, [itemIdsMemo]);

    return {
        containerRef,
        bottomAnchorRef,
        newMessagesMarkerRef,
        isAtBottom,
    };
};

export const getItemAlignment = ({
    item,
    currentUser,
    currentWorkspaceId,
}: {
    item: ConversationItem;
    currentUser?: User;
    currentWorkspaceId?: number;
}): Alignment => {
    switch (item.contentType) {
        case "LabelTag":
            return "FullWidth";

        case "StateChange":
            return "Center";

        case "ClinicianMessage":
            if (
                item.createdBy.type === "WorkspaceUser" &&
                item.createdBy.userId === currentUser?.accuRxUserId &&
                item.createdBy.workspaceId === currentWorkspaceId?.toString()
            ) {
                // If a clinician message is sent by the current user and from
                // the current workspace it's right aligned
                return "Right";
            } else if (
                item.createdBy.type === "Contact" &&
                item.createdBy.emailAddress.toLowerCase() ===
                    currentUser?.email.toLowerCase()
            ) {
                // If a clinician message is sent by an email contact and the
                // email address matches the current user's it's right aligned.
                // Also account for the fact that email addresses are
                // case-insensitive.
                return "Right";
            } else {
                // For the remaining cases it's left alighted:
                // - Sent by a different user
                // - Sent by the current user but from a different workspace
                // - Sent by some email contact
                return "Left";
            }

        default: {
            if (item.createdBy.type === "Patient") {
                return "Left";
            } else {
                return "Right";
            }
        }
    }
};
