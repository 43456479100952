import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledInlineContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${Tokens.SIZES[1]};
    width: fit-content;
`;

export const StyledTimeInputWrapper = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        & {
            max-width: 100px;
        }
    }
`;

export const StyledText = styled(Text)`
    display: inline;
`;

export const StyledInputWrapper = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        & {
            max-width: 100px;
        }
    }
`;
