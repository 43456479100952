import { Fields, useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";

type FilterName = Extract<Fields, { key: "filterName" }>["value"];

type FilterButtonClickFields = {
    appOrigin: "ConversationList" | "TemplateList";
    filterName: FilterName;
};

type FilteMenuItemClickFields = {
    appOrigin: "Sidebar" | "ConversationList" | "TemplateList";
    filterName: FilterName;
};

export const useConversationFilterAnalytics = () => {
    const { accessType } = useNativeTrackingFields();
    const track = useAnalytics();

    const trackFilterButtonClick = ({
        appOrigin,
        filterName,
    }: FilterButtonClickFields) => {
        track("ConversationFilter Button Click", {
            accessType,
            appOrigin,
            filterName,
            eventVersion: 2,
        });
    };

    const trackFilterMenuItemClick = ({
        appOrigin,
        filterName,
    }: FilteMenuItemClickFields) => {
        track("ConversationFilter MenuItem Click", {
            accessType,
            appOrigin,
            filterName,
            eventVersion: 2,
        });
    };

    const trackFilterMenuItemHover = ({
        appOrigin,
        filterName,
    }: FilteMenuItemClickFields) => {
        track("ConversationFilter MenuItem Hover", {
            accessType,
            appOrigin,
            filterName,
            eventVersion: 1,
        });
    };

    return {
        trackFilterButtonClick,
        trackFilterMenuItemClick,
        trackFilterMenuItemHover,
    };
};
