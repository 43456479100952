import { Flex, Text, Tokens } from "@accurx/design";

const Indicator = ({
    borderColour,
    backgroundColour,
}: {
    borderColour: string;
    backgroundColour: string;
}) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1"
                width="18"
                height="18"
                rx="3"
                fill={backgroundColour}
                stroke={borderColour}
                stroke-width="2"
            />
        </svg>
    );
};

export const FortnightlyBarGraphLegend = () => {
    return (
        <Flex justifyContent="center" gap="2">
            <Flex gap="1" alignItems="center">
                <Indicator
                    backgroundColour={Tokens.COLOURS.primary.blue[10]}
                    borderColour={Tokens.COLOURS.primary.blue[75]}
                />
                <Text variant="note">Last week</Text>
            </Flex>
            <Flex gap="1" alignItems="center">
                <Indicator
                    backgroundColour={Tokens.COLOURS.primary.blue[75]}
                    borderColour={Tokens.COLOURS.primary.blue[75]}
                />
                <Text variant="note">Current Week</Text>
            </Flex>
        </Flex>
    );
};
