import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { actionCreators as accountActions } from "app/account/AccountActions";
import { useAppSelector, useCurrentUserId } from "store/hooks";

/** The job of this component is to keep the global Redux store in sync with the current selected organisation from the SelectedOrganisationProvider. */
export const SelectedOrganisationController = () => {
    const dispatch = useDispatch();
    const { selectedOrgId } = useSelectedOrganisation();
    const reduxSelectedOrgId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const userId = useCurrentUserId();

    useEffect(() => {
        if (selectedOrgId !== null && selectedOrgId !== reduxSelectedOrgId) {
            dispatch(accountActions.selectOrganisation(userId, selectedOrgId));
        }
        // Note: Avoid adding 'reduxSelectedOrgId' as a dependency because it
        // will get overwritten with the current 'selectedOrgId' value anyway by
        // the Redux action
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedOrgId, userId]);

    return null;
};
