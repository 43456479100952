import React, { ReactNode } from "react";

import { useToggle } from "@accurx/navigation";
import {
    QUICK_VIEW_CONTAINER_ID,
    useQuickViewContext,
} from "@accurx/quick-view";

import { MAIN_CONTENT_ID } from "app/layout/Layout.constants";
import { StyledNavigationMenu } from "app/layout/navigationMenu/NavigationMenu.styles";
import { SkipToContentLink } from "app/layout/skipToContentLink";
import { PORTAL_ID_NAV_SUBMENU } from "app/navbar/NavSubMenuComponent";
import { useSimplifiedNavigation } from "store/hooks";

import { MobileLayoutContext } from "./Mobile/MobileLayoutContext";
import { MobileNavigationModal } from "./Mobile/MobileNavigationModal/MobileNavigationModal";
import { MobileHeader } from "./MobileHeader/MobileHeader";
import { UnifiedMobilePrimaryNavigation } from "./PrimaryNavigation/UnifiedMobilePrimaryNavigation";
import {
    UnifiedPrimaryNavigation,
    UnifiedSimplePrimaryNavigation,
} from "./PrimaryNavigation/UnifiedPrimaryNavigation";
import { UnifiedSecondaryNavigation } from "./SecondaryNavigation/UnifiedSecondaryNavigation";
import {
    PageContainer,
    QuickViewContainer,
    StyledLayout,
    StyledMainContent,
    StyledQuickViewWrapper,
    StyledSidebar,
    StyledWrapper,
} from "./UnifiedLayout.styles";

export const UnifiedLayout = ({ children }: { children?: ReactNode }) => {
    const quickView = useQuickViewContext();
    const isSimpleNav = useSimplifiedNavigation();

    return (
        <StyledLayout data-testid="unified-layout">
            <SkipToContentLink />
            {isSimpleNav ? (
                <UnifiedSimplePrimaryNavigation />
            ) : (
                <UnifiedPrimaryNavigation />
            )}
            <StyledWrapper>
                <StyledSidebar>
                    <StyledNavigationMenu>
                        {!isSimpleNav && <UnifiedSecondaryNavigation />}
                    </StyledNavigationMenu>
                </StyledSidebar>

                <StyledMainContent id={MAIN_CONTENT_ID}>
                    <StyledQuickViewWrapper $quickViewIsOpen={quickView.isOpen}>
                        <PageContainer>
                            <div id={PORTAL_ID_NAV_SUBMENU}></div>
                            {children}
                        </PageContainer>
                        <QuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
                    </StyledQuickViewWrapper>
                </StyledMainContent>
            </StyledWrapper>
        </StyledLayout>
    );
};

export const UnifiedMobileLayout = ({ children }: { children?: ReactNode }) => {
    const quickView = useQuickViewContext();
    const isSimpleNav = useSimplifiedNavigation();
    const modalNav = useToggle();

    return (
        <MobileLayoutContext.Provider value={{ modalNav }}>
            <StyledLayout data-testid="unified-mobile-layout">
                <SkipToContentLink />
                <MobileHeader />
                <StyledWrapper>
                    <StyledMainContent id={MAIN_CONTENT_ID}>
                        <StyledQuickViewWrapper
                            $quickViewIsOpen={quickView.isOpen}
                        >
                            <PageContainer>
                                <div id={PORTAL_ID_NAV_SUBMENU}></div>
                                {children}
                            </PageContainer>
                            <QuickViewContainer id={QUICK_VIEW_CONTAINER_ID} />
                        </StyledQuickViewWrapper>
                    </StyledMainContent>
                </StyledWrapper>
                {!isSimpleNav && <UnifiedMobilePrimaryNavigation />}
            </StyledLayout>
            <MobileNavigationModal
                open={modalNav.isOpen}
                onOpenChange={modalNav.setIsOpen}
            >
                <UnifiedSecondaryNavigation />
            </MobileNavigationModal>
        </MobileLayoutContext.Provider>
    );
};
