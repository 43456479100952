import { useState } from "react";

import { NhsPharmacy } from "@accurx/api/account";
import { useCurrentUser } from "@accurx/auth";
import { Button, Ds, Feedback, Flex, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES } from "shared/Routes";

import { useJoinOrganisation } from "../../hooks/useJoinOrganisation";
import { StyledCardItem } from "../shared/Layout/Layout.styles";
import { StyledList } from "./PharmacyResult.styles";
import { capitalise } from "./utils";

type PharmacyResultProps = Pick<
    NhsPharmacy,
    "name" | "address" | "odsCode" | "hasMembers"
>;

export const PharmacyResult = ({
    name,
    address,
    odsCode,
    hasMembers,
}: PharmacyResultProps) => {
    const history = useHistory();
    const { setSelectedOrgId } = useSelectedOrganisation();
    const { user } = useCurrentUser();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [showModal, setShowModal] = useState(false);

    const addressLines = [address.line1, address.line2, address.county]
        .filter(Boolean)
        .join(", ");
    const formattedAddress = `${capitalise(addressLines)}, ${address.postCode}`;

    const joinedOrganisation = user.organisations.find(
        ({ nationalCode }) => nationalCode === odsCode,
    );

    const handleSwitch = (workspaceId: number) => {
        setSelectedOrgId(workspaceId);
        history.push(ROUTES.home);
    };

    const handleJoinClick = () => {
        FlemingAnalyticsTracker.trackPharmacyJoinButtonClick(
            analyticsLoggedInProps,
        );
        setShowModal(true);
    };

    return (
        <StyledCardItem as="li">
            <div>
                <Text variant="label" skinny>
                    {capitalise(name)}
                </Text>
                <Text skinny>
                    {formattedAddress} · ODS: {odsCode}
                </Text>
            </div>
            {joinedOrganisation ? (
                <Button
                    type="button"
                    text="Switch to workspace"
                    aria-label={`Switch to ${capitalise(name)}`}
                    theme="secondary"
                    onClick={() => handleSwitch(joinedOrganisation.orgId)}
                />
            ) : (
                <Button
                    type="button"
                    text="Join"
                    aria-label={`Join ${capitalise(name)}`}
                    theme="secondary"
                    onClick={handleJoinClick}
                />
            )}
            <JoinPharmacyModal
                open={showModal}
                onOpenChange={setShowModal}
                name={name}
                odsCode={odsCode}
                isAdminModal={!hasMembers}
            />
        </StyledCardItem>
    );
};

const JoinPharmacyModal = ({
    open,
    onOpenChange,
    name,
    odsCode,
    isAdminModal,
}: Ds.ModalProps & {
    name: string;
    odsCode: string;
    isAdminModal: boolean;
}) => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { isLoading, mutate } = useJoinOrganisation();
    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLButtonElement>();

    const handleJoin = () => {
        mutate(
            { nationalCode: odsCode },
            {
                onSuccess: () => {
                    FlemingAnalyticsTracker.trackPharmacyJoinConfirmButtonClick(
                        {
                            ...analyticsLoggedInProps,
                            isAdminModal,
                            hasError: false,
                        },
                    );
                    history.push(ROUTES.home);
                },
                onError: () => {
                    FlemingAnalyticsTracker.trackPharmacyJoinConfirmButtonClick(
                        {
                            ...analyticsLoggedInProps,
                            isAdminModal,
                            hasError: true,
                        },
                    );
                    toast(
                        <Feedback
                            colour="error"
                            title="Failed to update account"
                        >
                            Please refresh page and try again
                        </Feedback>,
                    );
                },
            },
        );
    };

    const handleCancel = () => {
        FlemingAnalyticsTracker.trackPharmacyJoinCancelButtonClick({
            ...analyticsLoggedInProps,
            isAdminModal,
        });
        onOpenChange(false);
    };

    return (
        <Ds.Modal
            open={open}
            onOpenChange={onOpenChange}
            onOpenAutoFocus={handleFocusRef}
            dismissible={false}
        >
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>Join {name}</Ds.Modal.Title>
                    {isAdminModal ? (
                        <Text skinny>
                            As the first member of the workspace you will join
                            as an admin. This means you can manage other
                            colleagues’ access to the workspace.
                        </Text>
                    ) : (
                        <>
                            <Text skinny>
                                Your membership will be pending approval from an
                                admin. Until then you can:
                            </Text>
                            <StyledList>
                                <Text as="li" skinny>
                                    start sending messages from the workspace
                                </Text>
                                <Text as="li" skinny>
                                    create message templates and patient lists
                                </Text>
                            </StyledList>
                            <Text skinny>
                                Approved members will be able to see the
                                messages you send.
                            </Text>
                        </>
                    )}
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            text="Cancel"
                            disabled={isLoading}
                            onClick={handleCancel}
                        />
                        <Button
                            ref={ref}
                            text="Join"
                            aria-label={`Join ${name}`}
                            disabled={isLoading}
                            onClick={handleJoin}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
