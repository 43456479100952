import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledResponsesContainer = styled.section`
    width: 100%;
    border: ${UI.Tokens.BORDERS.normal.silver};
    border-radius: ${UI.Tokens.BORDERS.radius};
`;

export const StyledResponsesHeader = styled.div`
    padding: ${UI.Tokens.SIZES[1]};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};
    display: flex;
    align-items: center;

    h3 {
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
    }
`;

export const StyledResponseContent = styled.div`
    padding: 0 ${UI.Tokens.SIZES[1]};
`;

export const StyledResponseContentSubtext = styled.p`
    padding: ${UI.Tokens.SIZES[1.5]} 0;
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};

    margin-bottom: 0px;
`;

export const StyledResponseSection = styled.ul`
    list-style: none;
    padding: ${UI.Tokens.SIZES[1.5]} 0;
    margin-bottom: 0px;

    &:not(:last-of-type) {
        border-bottom: ${UI.Tokens.BORDERS.normal.silver};
    }
`;

export const StyledResponseItemQuestion = styled.span`
    font-weight: 700;
`;

export const StyledResponseItemAnswer = styled.span``;
