import { useState } from "react";

import { Conversation, PatientSummary } from "@accurx/concierge/types";

import { ReplyState } from "../../ConversationView.types";
import { ComposeNote } from "../ComposeNote/ComposeNote";
import {
    StyledInnerContainer,
    StyledOuterContainer,
} from "./ContentsNew.styles";
import { NoteActionButton } from "./MessageActionButton";

type NoteContentsProps = {
    conversation: Conversation;
    patient?: PatientSummary;
    noteState: Omit<ReplyState, "Closed">; // NoteContents is only be displayed when Reply is open
    setNoteState: (state: ReplyState) => void;
    isExpanded: boolean;
    onExpandClick: () => void;
    setSentNoteStatus: (status: string | null) => void;
};

export const NoteContentsNew = ({
    patient,
    conversation,
    noteState,
    setNoteState,
    isExpanded,
    onExpandClick,
    setSentNoteStatus,
}: NoteContentsProps) => {
    const [noteText, setNoteText] = useState<string>("");

    const isComposingNote = noteText.length > 0;

    if (noteState === "Minimised") {
        return (
            <NoteActionButton
                onClick={() => setNoteState("Open")}
                text={isComposingNote ? "Continue note" : undefined}
            />
        );
    }

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <ComposeNote
                    noteText={noteText}
                    setNoteText={setNoteText}
                    conversation={conversation}
                    patient={patient}
                    closeNotePanel={() => setNoteState("Closed")}
                    setSentNoteStatus={setSentNoteStatus}
                    isHeightRestricted={!isExpanded}
                    onMinimiseClick={() => setNoteState("Minimised")}
                    onExpandClick={onExpandClick}
                />
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};
