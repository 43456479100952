import { ReactNode } from "react";

import { Card } from "@accurx/design";

export type BreadcrumbWrapperProps = {
    wrapperContent?: boolean;
    children: ReactNode;
};

export const BreadcrumbWrapper = ({
    wrapperContent,
    children,
}: BreadcrumbWrapperProps) =>
    wrapperContent ? (
        <Card
            spacing={2}
            props={{
                className: "mb-4",
                "data-testid": "breadcrumbWrapper",
            }}
        >
            {children}
        </Card>
    ) : (
        <>{children}</>
    );
