import { AssignResponse } from "@accurx/api/clinician-messaging";
import { mapConversationCommandResponseToConciergeUpdate } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationCommandResponseToConciergeUpdate";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";

type UpdateAssigneeWithNoteOptions = {
    assigneeId: string;
    notes?: string | null;
    workspaceId: string;
    workspaceConversationId: string;
};

export const updateAssigneeWithNote: ConciergeFetcher<
    UpdateAssigneeWithNoteOptions,
    AssignResponse
> = async (arg) => {
    const response = await ClinicianMessagingApiClient.assignWithNote({
        newAssigneeUserId: arg.assigneeId,
        notes: arg.notes,
        workspaceConversationId: arg.workspaceConversationId,
        workspaceId: arg.workspaceId,
    });

    const updates = mapConversationCommandResponseToConciergeUpdate(response);

    return { updates, response };
};
