import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES_BASE } from "shared/Routes";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

const AccountNavigation = ({
    onClick,
}: {
    onClick: () => void;
}): JSX.Element => {
    return (
        <StyledContainer>
            <SecondaryNavigationHeader>Account</SecondaryNavigationHeader>
            <SecondaryNavigationContent>
                <StyledLinkList>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserNameAndEmail}
                            text="Name and email"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserWorkspaceAccess}
                            text="Workspace Access"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserManageSignature}
                            text="Message signature"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserCookies}
                            text="Cookies"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserSecurity}
                            text="Security"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.accountUserTermsAndConditions}
                            text="Terms and Conditions"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                </StyledLinkList>
            </SecondaryNavigationContent>
        </StyledContainer>
    );
};

export { AccountNavigation };
