import { ReactNode } from "react";

import { FeatureName } from "@accurx/auth";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { Layout } from "app/layout/Layout";
import { useIsMobile } from "shared/useIsMobile";

import {
    UnifiedLayout,
    UnifiedMobileLayout,
} from "./UnifiedLayout/UnifiedLayout";

export const LayoutController = ({ children }: { children?: ReactNode }) => {
    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );
    const isMobile = useIsMobile();

    if (isUnifiedNavigationEnabled) {
        return isMobile ? (
            <UnifiedMobileLayout>{children}</UnifiedMobileLayout>
        ) : (
            <UnifiedLayout>{children}</UnifiedLayout>
        );
    }

    return <Layout>{children}</Layout>;
};
