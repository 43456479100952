import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPreviewLink = styled.a.attrs({
    target: "_blank",
    rel: "noreferrer noopener",
})`
    // We're extending a standard <a> element here with the link styles instead
    // of directly using <UI.Link> because <UI.Link> does not support calling
    // e.preventDefault() inside the onClick handler. We need to do this in the
    // case that the fail to fetch the preview link.
    ${UI.LinkStyles}
    // In UI.Link we force the container to span the full width of
    // the content, but this means that reply will overflow if window
    // is smaller that the link text.
    // Do not force the width of the link to be the max content
    // otherwise it doesn't go on multiple lines
    width: initial;
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};
    // Also unfortunately the <UI.Icon> component does not support being set to
    // the correct size for 14px text. It's either too big or too small and it
    // cannot be directly extended with styled(UI.Icon). So we have to manually
    // override its size here.
    svg {
        width: 20px;
        height: 20px;
    }
`;

// The quickview panel does not have much horizontal space and the link text
// changes depending on wether you're web or desktop embedded. To prevent weird
// whitespace breakages in all cases we can use this nowrap span to be very
// specific about which parts of the link we don't want to break onto a new
// line.
export const StyledNoWrap = styled.span`
    white-space: nowrap;
`;
