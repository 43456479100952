import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledModalContent = styled(Flex)`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    border: ${Tokens.BORDERS.normal.silver};
    filter: drop-shadow(
        0px ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]}
            ${Tokens.COLOURS.greyscale.metal}
    );
    overflow: hidden;
`;

export const StyledHeading = styled.div`
    padding: ${Tokens.SIZES[3]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: solid 1px ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledBody = styled.div`
    padding: 0 ${Tokens.SIZES[3]};
`;

export const StyledFooter = styled.div`
    border-top: solid 1px ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    background-color: ${Tokens.COLOURS.greyscale.white};
`;
