import { useMemo } from "react";

import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    ItemCreatedByUser,
    NoteItem,
} from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type NoteProps = {
    conversationItem: NoteItem;
};

export const Note = ({ conversationItem }: NoteProps) => {
    const userId = (conversationItem.createdBy as ItemCreatedByUser).id;
    const participant = useUser({ userId });

    const summary = useMemo((): string => {
        const n = conversationItem.segments.find((segment) => {
            return segment.type === "Text";
        });

        if (n?.type !== "Text") return "";
        return `'${n.text}'`;
    }, [conversationItem.segments]);

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {participant?.isCurrentUser
                        ? "You"
                        : participant?.displayName}
                    :
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    Note {summary}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
