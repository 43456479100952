/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Icon, RadioGroup, Text, Tokens } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { PracticeDetails } from "app/practices/Practices.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { useAppSelector } from "store/hooks";

import { generateFiltersArray } from "../Vaccine.helper";
import {
    FirstVaccinationFilterChoice,
    SecondVaccinationFilterChoice,
    StatusType,
    VaccineCourse,
} from "../models/VaccineDeliveryDTO";
import {
    AwaitingBoosterInvite,
    AwaitingSeasonalBoosterInvite,
    AwaitingSecondInvite,
    StatusFilterLabelInfo,
    StatusLabelInfo,
    determineStatusType,
    otherStatuses,
    toBookStatuses,
} from "../models/VaccineInviteStatus";
import {
    setFirstVaccinationTimeFilter,
    setSecondVaccinationTimeFilter,
    setStatusFilters,
} from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import {
    eightPlusWeeks,
    firstVaccinationWeeksFilterChoices,
    thirteenPlusWeeks,
    timeSinceLastVaccination,
    timeSincePrimaryVaccinationWeekChoicesFull,
    timeSincePrimaryVaccinationWeekChoicesReduced,
    twentySixPlusWeeks,
} from "./VaccineAllPatientsInvited.helper";

export interface VaccineAllPatientsInvitedFiltersStatusCheckboxesProps {
    numIndividualSelected: number;
    setCurrentPaginationPage: Dispatch<SetStateAction<number>>;
}

export const VaccineAllPatientsInvitedFiltersStatusCheckboxes = ({
    numIndividualSelected,
    setCurrentPaginationPage,
}: VaccineAllPatientsInvitedFiltersStatusCheckboxesProps): JSX.Element => {
    const dispatch = useDispatch();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const myPracticeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.myPractice,
        shallowEqual,
    );

    const statusFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.statusFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.statusFilters,
        shallowEqual,
    );

    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );

    const firstVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.firstVaccinationTimeFilter ||
            VaccineAllInvitesInitialState.allInvitesFilters
                .firstVaccinationTimeFilter,
        shallowEqual,
    );

    const secondVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.secondVaccinationTimeFilter ||
            VaccineAllInvitesInitialState.allInvitesFilters
                .secondVaccinationTimeFilter,
        shallowEqual,
    );

    const vaccineTypeFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.vaccineTypeFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.vaccineTypeFilters,
        shallowEqual,
    );

    const unclearStatusFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.unclearStatus ||
            VaccineAllInvitesInitialState.allInvitesFilters.unclearStatus,
        shallowEqual,
    );

    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccineAllInvitesInitialState.vaccineCourse,
    );

    const [localFilters, setLocalFilters] = useState<string[]>([]);

    const [
        localFirstVaccinationTimeFilter,
        setLocalFirstVaccinationTimeFilter,
    ] = useState<FirstVaccinationFilterChoice | null>(null);
    const [
        localSecondVaccinationTimeFilter,
        setLocalSecondVaccinationTimeFilter,
    ] = useState<SecondVaccinationFilterChoice | null>(null);

    const [reduxFiltersLoaded, setReduxFiltersLoaded] = useState(false);

    const [hideToBookFilters, setHideToBookFilters] = useState(false);
    const [hideOtherFilters, setHideOtherFilters] = useState(false);

    const statusType = determineStatusType(statusFilters);
    const [statusTypeSelected, setStatusTypeSelected] = useState(statusType);

    const reducedBoosterDoseSeparation: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeReducedBoosterDoseSeparation,
    );

    const extendedBoosterTimeRangeFilter: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeExtendedBoosterTimeRangeFilter,
    );

    const useReducedBoosterGap =
        reducedBoosterDoseSeparation && !extendedBoosterTimeRangeFilter;
    const timeSincePrimaryVaccinationWeekChoices = useReducedBoosterGap
        ? timeSincePrimaryVaccinationWeekChoicesReduced
        : timeSincePrimaryVaccinationWeekChoicesFull;
    const defaultTimeSincePrimaryVaccinationWeekChoice = useReducedBoosterGap
        ? thirteenPlusWeeks
        : twentySixPlusWeeks;

    useEffect(() => {
        // On first load or load after clicking 'Apply Filter' we sync the local state with the redux state
        if (!reduxFiltersLoaded) {
            setLocalFilters(statusFilters);
            setLocalFirstVaccinationTimeFilter(firstVaccinationTimeFilter);
            setLocalSecondVaccinationTimeFilter(secondVaccinationTimeFilter);
            setReduxFiltersLoaded(true);
        }
    }, [
        firstVaccinationTimeFilter,
        secondVaccinationTimeFilter,
        reduxFiltersLoaded,
        statusFilters,
    ]);

    // Sync the local state with the redux state when changing tabs (to apply the new set of statuses)
    useEffect(() => {
        setLocalFilters(statusFilters);
        setLocalFirstVaccinationTimeFilter(firstVaccinationTimeFilter);
        setLocalSecondVaccinationTimeFilter(secondVaccinationTimeFilter);

        // Change the radio button to "to book" to show the default set statuses again
        if (reduxFiltersLoaded) {
            // Don't do this on the first load!
            setStatusTypeSelected(StatusType.ToBook);
            setHideToBookFilters(false);
            setHideOtherFilters(true);
        }
    }, [vaccineCourse]);

    const localFiltersMatchRedux =
        [...statusFilters].sort().join("") ===
            [...localFilters].sort().join("") &&
        firstVaccinationTimeFilter === localFirstVaccinationTimeFilter &&
        secondVaccinationTimeFilter === localSecondVaccinationTimeFilter;

    const handleToggleToBookStatusFilter = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setCurrentPaginationPage(0);
        setLocalFirstVaccinationTimeFilter(null);
        setLocalSecondVaccinationTimeFilter(null);
        const newFilter = event.target.value;

        const toBookFilters = localFilters.filter((x) =>
            toBookStatuses.map((status) => status.filterStatus).includes(x),
        );

        const found = toBookFilters.findIndex((x) => x === newFilter);
        if (found === -1) {
            setLocalFilters([...toBookFilters, newFilter]);
            if (event.target.value === AwaitingSecondInvite.filterStatus) {
                // set 8+ weeks as default filtering when selecting awaiting 2nd invite filter
                handleFirstVaccinationTimeFilter(eightPlusWeeks);
            }
            if (event.target.value === AwaitingBoosterInvite.filterStatus) {
                // set 26 + weeks as default filtering when selecting awaiting booster invite filter
                handleSecondVaccinationTimeFilter(
                    defaultTimeSincePrimaryVaccinationWeekChoice,
                );
            }
        } else {
            setLocalFilters(toBookFilters.filter((x) => x !== newFilter));
        }
    };

    const handleToggleOtherStatusFilter = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setCurrentPaginationPage(0);
        setLocalFirstVaccinationTimeFilter(null);
        setLocalSecondVaccinationTimeFilter(null);
        const newFilter = event.target.value;

        const otherFilters = localFilters.filter((x) =>
            otherStatuses.map((status) => status.filterStatus).includes(x),
        );

        const found = otherFilters.findIndex((x) => x === newFilter);
        if (found === -1) {
            setLocalFilters([...otherFilters, newFilter]);
        } else {
            setLocalFilters(otherFilters.filter((x) => x !== newFilter));
        }
    };

    const handleFirstVaccinationTimeFilter = (
        value: FirstVaccinationFilterChoice,
    ): void => {
        setCurrentPaginationPage(0);
        setLocalFilters([AwaitingSecondInvite.filterStatus]);
        setLocalFirstVaccinationTimeFilter(value);
    };

    const handleSecondVaccinationTimeFilter = (
        value: SecondVaccinationFilterChoice,
    ): void => {
        setCurrentPaginationPage(0);
        setLocalFilters([AwaitingBoosterInvite.filterStatus]);
        setLocalSecondVaccinationTimeFilter(value);
    };

    const handleBoosterTimeFilter = (
        value: SecondVaccinationFilterChoice,
    ): void => {
        setCurrentPaginationPage(0);
        setLocalFilters([AwaitingSeasonalBoosterInvite.filterStatus]);
        setLocalSecondVaccinationTimeFilter(value);
    };

    const getListOfRelevantStatusFilters = (
        statuses: StatusLabelInfo[],
        onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void,
    ): StatusFilterLabelInfo[] => {
        return statuses
            .filter((x) => x.vaccineCourse.includes(vaccineCourse)) // Check that the filter should show up for the current selected vaccine course matches
            .map((x: StatusLabelInfo) => {
                return {
                    status: x.filterStatus,
                    displayValue: x.displayText,
                    description: x.description,
                    checked: localFilters.indexOf(x.filterStatus) !== -1,
                    onChangeFunction: onChangeFunction,
                };
            });
    };

    // Get a list of copy + handlers for each of the filters in each grouping of "toBook" or "other"
    const toBookCheckBoxCopy = getListOfRelevantStatusFilters(
        toBookStatuses,
        handleToggleToBookStatusFilter,
    );
    const otherCheckBoxCopy = getListOfRelevantStatusFilters(
        otherStatuses,
        handleToggleOtherStatusFilter,
    );

    const handleStatusTypeChange = (
        e: React.FormEvent<HTMLInputElement>,
    ): void => {
        const statusTypeNum = parseInt(e.currentTarget.value, 10);
        setStatusTypeSelected(statusTypeNum);
        switch (statusTypeNum) {
            case StatusType.ToBook:
                setHideToBookFilters(false);
                setHideOtherFilters(true);
                break;
            case StatusType.Other:
                setHideToBookFilters(true);
                setHideOtherFilters(false);
                break;
            case StatusType.All:
            default:
                setHideToBookFilters(true);
                setHideOtherFilters(true);
                setLocalFilters([]);
                setLocalFirstVaccinationTimeFilter(null);
                setLocalSecondVaccinationTimeFilter(null);
        }
    };

    const handleApplyFilters = (): void => {
        setReduxFiltersLoaded(true);
        dispatch(setStatusFilters(localFilters));
        dispatch(
            setFirstVaccinationTimeFilter(localFirstVaccinationTimeFilter),
        );
        dispatch(
            setSecondVaccinationTimeFilter(localSecondVaccinationTimeFilter),
        );
        setCurrentPaginationPage(0);
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListFiltersAppliedProps =
            {
                ...analyticsLoggedInProps,
                applyFilterSection: "status",
                myPracticeFilter: myPracticeFilter,
                currentStatusFilters: localFilters,
                firstVaccinationWeekFilter:
                    localFirstVaccinationTimeFilter?.displayText,
                vaccineTypeFilters: generateFiltersArray(vaccineTypeFilters),
                unclearStatusFilter: unclearStatusFilter,
            };
        ChainAnalyticsTracker.trackVaccinePatientListFiltersApplied(
            analyticsProps,
        );
    };

    const renderWeeksFilterChoice = (): JSX.Element[] => {
        return firstVaccinationWeeksFilterChoices.map((choice) => (
            <div key={choice.id}>
                <input
                    type="radio"
                    id={`${choice.id}`}
                    name={"firstVaccinationWeek"}
                    onChange={(): void =>
                        handleFirstVaccinationTimeFilter(choice)
                    }
                    checked={localFirstVaccinationTimeFilter?.id === choice.id}
                    disabled={numIndividualSelected > 0}
                    className="mr-1"
                />
                <Text
                    as="label"
                    skinny
                    variant="preview"
                    props={{ htmlFor: `${choice.id}` }}
                >
                    {choice.displayText}
                </Text>
            </div>
        ));
    };

    const renderBoosterWeeksFilterChoice = (): JSX.Element[] => {
        return timeSincePrimaryVaccinationWeekChoices.map((choice) => (
            <div key={choice.key}>
                <input
                    type="radio"
                    id={`${choice.key}`}
                    name={"secondVaccinationWeek"}
                    onChange={(): void =>
                        handleSecondVaccinationTimeFilter(choice)
                    }
                    checked={
                        localSecondVaccinationTimeFilter?.key === choice.key
                    }
                    disabled={numIndividualSelected > 0}
                    className="mr-1"
                />
                <Text
                    as="label"
                    skinny
                    variant="preview"
                    props={{ htmlFor: `${choice.key}` }}
                >
                    {choice.displayText}
                </Text>
            </div>
        ));
    };

    const renderSeasonalBoosterFilterChoice = (): JSX.Element => {
        const choiceLookup = new Map(
            timeSinceLastVaccination.map((choice) => [choice.key, choice]),
        );
        return (
            <RadioGroup
                name="choosing"
                onChange={(e) =>
                    handleBoosterTimeFilter(choiceLookup.get(e.target.value)!)
                }
                checkedValue={localSecondVaccinationTimeFilter?.key}
                radioInputs={timeSinceLastVaccination.map((choice) => ({
                    id: `sinceLastVaccine_${choice.key}`,
                    label: (
                        <Text skinny variant={"preview"}>
                            {choice.displayText + (choice.addendumText ?? "")}
                        </Text>
                    ),
                    value: choice.key,
                }))}
            />
        );
    };

    const renderStatusCheckbox = (
        status: string,
        display: string,
        description: string,
        checkedParam: boolean,
        onChangeFunction: (event: React.ChangeEvent<HTMLInputElement>) => void,
        index?: number,
    ): JSX.Element => {
        const showWeeksFilters =
            status === AwaitingSecondInvite.filterStatus &&
            localFilters.indexOf(AwaitingSecondInvite.filterStatus) !== -1;

        const showBoosterWeeksFilters =
            status === AwaitingBoosterInvite.filterStatus &&
            localFilters.indexOf(AwaitingBoosterInvite.filterStatus) !== -1 &&
            vaccineCourse === VaccineCourse.Booster;

        const showSeasonalBoosterWeeksFilters =
            status === AwaitingSeasonalBoosterInvite.filterStatus &&
            localFilters.indexOf(AwaitingSeasonalBoosterInvite.filterStatus) !==
                -1 &&
            (vaccineCourse === VaccineCourse.BoosterAutumn2022 ||
                vaccineCourse === VaccineCourse.BoosterSpring2022 ||
                vaccineCourse === VaccineCourse.BoosterSpring2023 ||
                vaccineCourse === VaccineCourse.BoosterAutumn2023 ||
                vaccineCourse === VaccineCourse.BoosterSpring2024 ||
                vaccineCourse === VaccineCourse.BoosterAutumn2024);

        return (
            <div className="d-flex m-2" key={index}>
                <input
                    type="checkbox"
                    id={status}
                    value={status}
                    onChange={onChangeFunction}
                    checked={checkedParam}
                    disabled={numIndividualSelected > 0}
                    className="mr-2 mt-1"
                />
                <div>
                    <Text as="label" skinny props={{ htmlFor: status }}>
                        {display}
                    </Text>
                    <Text colour="metal" variant="preview">
                        {description}
                    </Text>
                    {showWeeksFilters && renderWeeksFilterChoice()}
                    {showBoosterWeeksFilters &&
                        renderBoosterWeeksFilterChoice()}
                    {showSeasonalBoosterWeeksFilters &&
                        renderSeasonalBoosterFilterChoice()}
                </div>
            </div>
        );
    };

    const toggleHideFilters = (): void => {
        setHideToBookFilters((x) => !x);
        setHideOtherFilters((x) => !x);
    };

    const renderStatusCheckboxes = (): JSX.Element => {
        return (
            <div
                className="mt-3 pb-3 d-flex flex-column"
                style={{
                    borderBottom: `1px solid ${Tokens.COLOURS.greyscale.stone}`,
                }}
            >
                <Text variant="label" props={{ className: "ml-2 mt-2" }}>
                    Status
                </Text>
                <div className="d-flex m-2">
                    <input
                        type="radio"
                        id="toBook"
                        name="statusType"
                        value={StatusType.ToBook}
                        onChange={handleStatusTypeChange}
                        checked={statusTypeSelected === StatusType.ToBook}
                        disabled={numIndividualSelected > 0}
                        className="mt-1"
                    />
                    <label htmlFor="toBook" className="w-100">
                        <div
                            className="d-flex align-items-center cursor-pointer"
                            onClick={toggleHideFilters}
                        >
                            To Book
                            <Icon
                                colour="stone"
                                name="Arrow"
                                size={2}
                                theme="Line"
                                rotation={
                                    statusTypeSelected === StatusType.ToBook &&
                                    !hideToBookFilters
                                        ? "up"
                                        : "down"
                                }
                                props={{ className: "ml-1" }}
                            />
                        </div>
                        {statusTypeSelected === StatusType.ToBook &&
                            !hideToBookFilters &&
                            toBookCheckBoxCopy.map((copy, index) =>
                                renderStatusCheckbox(
                                    copy.status,
                                    copy.displayValue,
                                    copy.description,
                                    copy.checked,
                                    copy.onChangeFunction,
                                    index,
                                ),
                            )}
                    </label>
                </div>
                <div className="d-flex m-2">
                    <input
                        type="radio"
                        id="other"
                        name="statusType"
                        value={StatusType.Other}
                        onChange={handleStatusTypeChange}
                        checked={statusTypeSelected === StatusType.Other}
                        disabled={numIndividualSelected > 0}
                        className="mt-1"
                    />
                    <label htmlFor="other" className="w-100">
                        <div
                            className="d-flex align-items-center cursor-pointer"
                            onClick={toggleHideFilters}
                        >
                            Other
                            <Icon
                                colour="stone"
                                name="Arrow"
                                size={2}
                                theme="Line"
                                rotation={
                                    statusTypeSelected === StatusType.Other &&
                                    !hideOtherFilters
                                        ? "up"
                                        : "down"
                                }
                                props={{ className: "ml-1" }}
                            />
                        </div>
                        {statusTypeSelected === StatusType.Other &&
                            !hideOtherFilters &&
                            otherCheckBoxCopy.map((copy, index) =>
                                renderStatusCheckbox(
                                    copy.status,
                                    copy.displayValue,
                                    copy.description,
                                    copy.checked,
                                    copy.onChangeFunction,
                                    index,
                                ),
                            )}
                    </label>
                </div>
                <div className="d-flex m-2">
                    <input
                        type="radio"
                        id="all"
                        name="statusType"
                        value={StatusType.All}
                        onChange={handleStatusTypeChange}
                        checked={statusTypeSelected === StatusType.All}
                        disabled={numIndividualSelected > 0}
                        className="mt-1"
                    />
                    <label htmlFor="all">All</label>
                </div>
                <Button
                    text="Apply filter"
                    onClick={handleApplyFilters}
                    disabled={localFiltersMatchRedux}
                    className="d-block"
                    aria-label="apply-filter"
                />
            </div>
        );
    };

    return <>{renderStatusCheckboxes()}</>;
};
