import React from "react";

import { Feedback, Spinner } from "@accurx/design";
import { Log } from "@accurx/shared";
import { Redirect, generatePath, useParams } from "react-router";

import { Conversation } from "app/workspaceConversations/components/Conversation/Conversation";
import { mapPatientThreadSmsOrEmailToConversationItems } from "app/workspaceConversations/components/Conversation/Conversation.helpers";
import {
    OnSendMessageSuccessType,
    PatientData,
} from "app/workspaceConversations/components/Conversation/Conversation.types";
import { ROUTES } from "shared/Routes";
import { usePatient } from "shared/concierge/patients/hooks/usePatient";
import { PatientExternalIdentity } from "shared/concierge/patients/types/patient.types";
import { useCurrentUserId } from "store/hooks";

import { useSearchedPatientConversation } from "./conversations/useSearchedPatientConversation";

export const SearchedPatientConversationPage = (): JSX.Element | null => {
    const { conversationId } = useParams<{ conversationId: string }>();
    const result = useSearchedPatientConversation(conversationId);
    const patientQuery = usePatient(result?.data?.regardingPatientId);
    const currentUserId = useCurrentUserId();

    const patient: PatientData = {
        data: patientQuery.data,
        isLoading: patientQuery.status === "LOADING",
    };

    // This should never actually happen, but just in case
    if (!currentUserId) {
        Log.error("A conversation page was accessed with no user ID");
        return <Redirect to={generatePath(ROUTES.login)} />;
    }

    /**
     * This page component should only ever get rendered on a URL that
     * links to a valid conversation so if we have no conversationId
     * something has gone wrong. In that case we'll log an error and display
     * nothing.
     */
    if (!conversationId) {
        Log.error("A conversation page was displayed at an invalid URL");
        return null;
    }

    if (result.status === "ERROR") {
        return (
            <Feedback
                title="An error occurred while trying to load the conversation"
                colour="error"
            >
                {result.errorMessage}
            </Feedback>
        );
    }

    if (result.status === "LOADING") {
        return <Spinner />;
    }

    const onSendMessageSuccess: OnSendMessageSuccessType = (messages) => {
        const conversationItems =
            mapPatientThreadSmsOrEmailToConversationItems(messages);

        result.actions?.addItemsToConversation(result.data, conversationItems);
    };

    const markAsDone = async () => {
        await result.actions?.markAsDone(result.data);
    };

    const markAsOpen = async () => {
        await result.actions?.markAsOpen(result.data);
    };

    const markItemAsRead = async (
        itemId: string,
        patientExternalId: PatientExternalIdentity | null,
    ) => {
        await result.actions?.markItemAsRead(itemId, patientExternalId);
    };

    return (
        <Conversation
            conversation={result.data}
            patient={patient}
            conversationActions={{
                markAsDone,
                markAsOpen,
                markItemAsRead,
                onSendMessageSuccess,
            }}
            currentUserId={currentUserId}
        />
    );
};
