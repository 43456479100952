import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";

export const NhsAdvice = ({
    name,
    url,
    onRemoveClick,
}: {
    name: string;
    url: string;
    onRemoveClick?: () => void;
}) => {
    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="1"
        >
            <UI.Flex gap="1" alignItems="center">
                <BodyInfoBox.Icon name="Link" />
                <BodyInfoBox.Text colour="night">
                    NHS.UK Advice:{" "}
                    <BodyInfoBox.Link href={`https://${url}`} openInNewTab>
                        <UI.Link.Text text={name} />
                        <UI.Link.Icon />
                    </BodyInfoBox.Link>
                </BodyInfoBox.Text>
            </UI.Flex>
            {onRemoveClick !== undefined && (
                <StyledActionStack.SquareIconButton
                    onClick={onRemoveClick}
                    title="Remove NHS advice"
                >
                    <Pill.Icon name="Cross" colour="red" size={3} />
                </StyledActionStack.SquareIconButton>
            )}
        </StyledActionStack.Row>
    );
};
