import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import {
    WORKSPACE_MANAGEMENT_ROUTES,
    isDefaultWorkspace,
} from "@accurx/workspace-management";
import { generatePath, useHistory } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES } from "shared/Routes";

export const useLeaveWorkspaceRedirect = (): { handleRedirect: () => void } => {
    const history = useHistory();
    const { user } = useCurrentUser();
    const {
        orgId: workspaceId,
        defaultWorkspaceId,
        nationalCode,
    } = useCurrentWorkspace();
    const { setSelectedOrgId } = useSelectedOrganisation();

    const handleRedirect = () => {
        const updatedWorkspaces = user.organisations.filter(
            ({ orgId }) => orgId !== workspaceId,
        );

        // Redirect to onboarding if user isn't in any other workspace

        if (updatedWorkspaces.length === 0) {
            history.push(ROUTES.joinOrganisation);
            return;
        }

        const workspaceInSameOrg = updatedWorkspaces.find(
            (workspace) =>
                workspace.nationalCode === nationalCode &&
                !isDefaultWorkspace(workspace),
        );

        // Redirect to homepage if user is in another user-created workspace within the same organisation

        if (workspaceInSameOrg) {
            setSelectedOrgId(workspaceInSameOrg.orgId);
            history.push(ROUTES.home);
            return;
        }

        const firstWorkspaceInAnyOrg = updatedWorkspaces.find(
            (workspace) => !isDefaultWorkspace(workspace),
        );

        // Redirect to homepage if user is in a user-created workspace within any other organisation

        if (firstWorkspaceInAnyOrg) {
            setSelectedOrgId(firstWorkspaceInAnyOrg.orgId);
            history.push(ROUTES.home);
            return;
        }

        const currentDefaultOrg = updatedWorkspaces.find(
            (workspace) => workspace.orgId === defaultWorkspaceId,
        );

        // Redirect to /join-workspace if user is in the default organisation of the workspace they're leaving

        if (currentDefaultOrg) {
            history.push(
                generatePath(WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin, {
                    workspaceId: currentDefaultOrg.orgId,
                }),
            );
            return;
        }

        const firstDefaultOrg = updatedWorkspaces[0];

        // Otherwise redirect to /select-organisation
        if (firstDefaultOrg) {
            history.push(
                generatePath(WORKSPACE_MANAGEMENT_ROUTES.selectOrganisation, {
                    workspaceId: firstDefaultOrg.orgId,
                }),
            );
            return;
        }
    };

    return { handleRedirect };
};
