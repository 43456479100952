import { Log } from "@accurx/shared";
import isNil from "lodash/isNil";

import { createUniqueNoteId } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import {
    BasePatientThreadItem,
    FloreyResponse,
    NoteUserGroupState,
    NoteUserState,
    PatientThreadTicketItem,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    BaseConversationItem,
    IncludedByTeamId,
    ReadDataByUserId,
} from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationItemCreatedBy,
    PatientResponseItem,
    PatientResponseSection,
} from "shared/concierge/conversations/types/item.types";

type ConversationItemMappingLog = {
    /** The message of the error being sent */
    message: string;
    /** The type of the item we're logging about as it's sent by the API */
    itemType?: BasePatientThreadItem["type"];
    /** The id of the item we're logging about */
    itemId?: BasePatientThreadItem["id"];
};

/**
 * Will log an issue to Sentry
 *
 * @param info {ConversationItemMappingLog} object
 */
export function conversationItemMappingLog(
    info: ConversationItemMappingLog,
): void {
    Log.error(info.message, {
        tags: {
            product: "Foundational Inbox",
            domain: "Conversation item mapping",
            itemType: info.itemType,
            itemId: info.itemId,
        },
    });
}

export function mapBaseConversationItem(
    ticketItem: PatientThreadTicketItem,
): BaseConversationItem {
    const itemId = createUniqueNoteId(ticketItem.type, ticketItem.id || "");

    const readDataResult =
        !!ticketItem?.message && "userStates" in ticketItem.message
            ? mapUserReadData(
                  ticketItem.message.userStates || [],
                  ticketItem.message.userGroupStates || [],
              )
            : undefined;

    if (readDataResult && readDataResult.failedToMapCount > 0) {
        conversationItemMappingLog({
            message:
                "Could not map all read states for ticket item - missing or inconsistent data",
            itemId: ticketItem.id,
            itemType: ticketItem.type,
        });
    }

    return {
        id: itemId,
        createdAt: ticketItem.createdAt,
        readDataByUserId: readDataResult?.readDataByUserId,
    };
}

type UserReadDatesResult = {
    readDataByUserId: ReadDataByUserId;
    failedToMapCount: number;
};

function mapUserReadData(
    userStates: NoteUserState[],
    userGroupStates: NoteUserGroupState[],
): UserReadDatesResult {
    const userIdsToGroups = new Map<string, IncludedByTeamId>();
    const dict: ReadDataByUserId = {};
    let failedToMapCount = 0;

    userGroupStates.forEach((groupState) => {
        if (groupState.isArchived) {
            return;
        }

        if (!groupState.userGroupId || !groupState.userIdsInGroupAtNoteCreate) {
            failedToMapCount++;
            return;
        }

        const userGroupId = groupState.userGroupId;
        groupState.userIdsInGroupAtNoteCreate.forEach((userId) => {
            const existing = userIdsToGroups.get(userId);
            if (existing) {
                existing[userGroupId] = true;
            } else {
                userIdsToGroups.set(userId, { [userGroupId]: true });
            }
        });
    });

    userStates.forEach((userState) => {
        if (userState.isArchived) {
            return;
        }
        if (!userState.userId) {
            failedToMapCount++;
            return;
        }

        if (!!userState.readAt !== !!userState.hasRead) {
            failedToMapCount++;
            return;
        }

        // FOU-212: We need an explicit flag from the server to help differentiate when a user
        // is included both directly as an individual and indirectly via team membership. Until
        // then, a user that is included via both paths will be calculated via team logic alone.
        // The desktop client partly masks this by looking for tags in UserNote content, but
        // this isn't complete and we should fix both at the same time.
        const shouldIgnoreTeamMembership = false;

        dict[userState.userId] = {
            readAt: userState.readAt ?? null,
            byTeamId: shouldIgnoreTeamMembership
                ? null
                : userIdsToGroups.get(userState.userId) ?? null,
        };
    });

    return {
        readDataByUserId: dict,
        failedToMapCount,
    };
}

// Patient inbound response mappings - shared between item types

function mapPatientResponse(
    response: FloreyResponse,
): PatientResponseItem | undefined {
    const attachmentIds =
        response.answer?.floreyPatientAttachments
            ?.map((id) => id.attachmentId)
            .filter((id): id is string => id !== undefined) ?? [];

    // A valid respose must contain at least a question or attachments or an answer
    if (
        !response.question &&
        !response.answer?.displayAnswer &&
        attachmentIds.length === 0
    ) {
        return;
    }

    return {
        // as per DTO the default is bold, expect it to be explicitly false
        displayQuestionBold: response.displayQuestionBold ?? true,
        question: response.question || null,
        answer: response.answer?.displayAnswer || null,
        attachmentIds: attachmentIds.length > 0 ? attachmentIds : null,
    };
}

type PatientResponseSectionResult = {
    sections: PatientResponseSection[];
    failedToMapCount: number;
};

export function mapPatientResponseSections(
    responses: FloreyResponse[],
): PatientResponseSectionResult {
    let failedToMapCount = 0;
    const sections: PatientResponseSection[] = [];

    responses.forEach((resp) => {
        const displaySection = resp.displaySection ?? 0;
        if (sections[displaySection] === undefined) {
            sections[displaySection] = { responses: [] };
        }
        const mapped = mapPatientResponse(resp);
        if (!mapped) {
            failedToMapCount++;
        } else {
            sections[displaySection].responses.push(mapped);
        }
    });

    // There could be gaps in source data
    // (i.e. displaySection 0, 1, 4, etc)
    // so drop any empty sections
    return {
        sections: sections.filter((grp) => grp !== undefined),
        failedToMapCount,
    };
}

export const mapCreatedByOptionalUser = (
    userId: Optional<string>,
): ConversationItemCreatedBy =>
    isNil(userId) ? { type: "System" } : { type: "User", id: userId };

export const mapDeliveryScheduledAt = (
    deliveryScheduledAt: Optional<string>,
): string | undefined =>
    isNil(deliveryScheduledAt) ? undefined : deliveryScheduledAt;
