/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useMemo, useState } from "react";

import {
    Accordion,
    Card,
    Ds,
    Feedback,
    Icon,
    Spinner,
    Text,
    Tokens,
} from "@accurx/design";
import { IWrappedResult } from "@accurx/shared";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { MaxBatchSMSSize, MaxSingleSMSSize } from "../Vaccine.type";
import {
    apptLinkSignaturePreviewCopy,
    boosterCopy,
    boosterInviteMessage,
    calculateMessageLength,
    characterCountWarning,
    patientGreetingCopy,
    practiceSignature,
    seasonalBoosterInviteMessage,
    smsPreviewCopy,
} from "../VaccineCopy";
import {
    RecallCapacityRequest,
    RecallInviteId,
    VaccineCourse,
    VaccineDeliveryItem,
    VaccineExistingPatientState,
    VaccinePatientInvited,
} from "../models/VaccineDeliveryDTO";
import { RecallBoosterSmsRequest } from "../models/VaccineRecordingBatchesDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    BulkActionContext,
    VaccineBulkActions,
} from "../vaccineBulkActions/VaccineBulkActions.component";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineDeliveryComposePatientTable } from "./VaccineDeliveryComposePatientTable";
import {
    getNonTextablePatientsWhoNeedBooster,
    getNonTextablePatientsWhoNeedSeasonalBooster,
    getNonTextablePatientsWhoNeedSecondJab,
    getTextablePatientsWhoNeedBooster,
    getTextablePatientsWhoNeedSeasonalBooster,
    getTextablePatientsWhoNeedSecondJab,
    getTotalSteps,
} from "./VaccineDeliveryUploadAndComposeHelpers";

const primaryMessageFixedBody = smsPreviewCopy(true) + "\n";
const boosterMessageFixedBody = apptLinkSignaturePreviewCopy + "\n";

/* This page is used for sending patients an invite for their second vaccines, or for their booster vaccines */
export const VaccineDeliveryComposeSecond = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const practices = useAppSelector(({ practices }) => practices);
    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices.selectedPractice,
            )?.name,
        shallowEqual,
    );

    const vaccineDeliveryId = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryId ||
            VaccineAllInvitesInitialState.vaccineDeliveryId,
        shallowEqual,
    );
    const invitesToInviteForSecond = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.invitesToInviteForSecond ||
            VaccineAllInvitesInitialState.invitesToInviteForSecond,
        shallowEqual,
    );

    const vaccineCourse = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineCourse ||
            VaccineAllInvitesInitialState.vaccineCourse,
        shallowEqual,
    );

    const isBooster = vaccineCourse !== VaccineCourse.Primary;
    const boosterLabel = boosterCopy(vaccineCourse);

    const history = useHistory();

    const [messageSignature, setMessageSignature] = useState(
        practiceSignature(practiceName || ""),
    ); // signature (end of SMS)

    const [boosterMessageTemplate, setBoosterMessageTemplate] = useState(
        vaccineCourse === VaccineCourse.Booster
            ? boosterInviteMessage
            : seasonalBoosterInviteMessage(boosterLabel),
    ); // just for boosters, you can also edit the middle of the SMS

    const [validMessageBody, setValidMessageBody] = useState(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState(
        characterCountWarning,
    );
    const [characterCount, setCharacterCount] = useState(0);
    const [sendingMessages, setSendingMessages] = useState(false);
    const [finishedSendingMessages, setFinishedSendingMessages] =
        useState(false);

    const [capacityCheckPassed, setCapacityCheckPassed] =
        useState<boolean>(false);
    const [capacityCheckStatus, setCapacityCheckStatus] =
        useState<UpdatingStatus>(UpdatingStatus.Initial);
    const [capacityMessage, setCapacityMessage] = useState<string | null>(null); // error message for capacity check

    // Check whether there is capacity to send the selected invites
    useEffect(() => {
        const checkCapacity = async (
            invites: VaccineDeliveryItem[],
        ): Promise<any> => {
            const request: RecallCapacityRequest = {
                isReminder: false,
                isSecondBooking: isBooster ? false : true,
                isBoosterBooking: isBooster ? true : false,
                invites: invites.map(
                    (i): RecallInviteId => ({ id: i.inviteId }),
                ),
            };

            setCapacityCheckStatus(UpdatingStatus.Loading);
            const result = await ChainApiClient.checkCapacity(
                `${selectedPractice}`,
                request,
            );

            if (result.result && result.result.canSendMessages) {
                setCapacityCheckPassed(true);
                setCapacityMessage(null);
            } else {
                setCapacityCheckPassed(false);
                setCapacityMessage(result.result?.error ?? null);
            }

            setCapacityCheckStatus(UpdatingStatus.Loaded);
        };
        if (capacityCheckStatus === UpdatingStatus.Initial) {
            checkCapacity(invitesToInviteForSecond);
        }
    }, [capacityCheckStatus, invitesToInviteForSecond, selectedPractice]);

    // Need to run this on the first render to set the character count and any relevant warnings
    useEffect(() => {
        checkMessageTextAndSetState(messageSignature, true);
    }, []);

    const textableSecondJabPatients = getTextablePatientsWhoNeedSecondJab(
        invitesToInviteForSecond,
    );
    const nonTextableSecondJabPatients = getNonTextablePatientsWhoNeedSecondJab(
        invitesToInviteForSecond,
    );
    const textableBoosterPatients = getTextablePatientsWhoNeedBooster(
        invitesToInviteForSecond,
    );
    const nonTextableBoosterPatients = getNonTextablePatientsWhoNeedBooster(
        invitesToInviteForSecond,
    );
    const textableSeasonalBoosterPatients =
        getTextablePatientsWhoNeedSeasonalBooster(invitesToInviteForSecond);
    const nonTextableSeasonalBoosterPatients =
        getNonTextablePatientsWhoNeedSeasonalBooster(invitesToInviteForSecond);

    const relevantTextableList = isBooster
        ? vaccineCourse === VaccineCourse.Booster
            ? textableBoosterPatients
            : textableSeasonalBoosterPatients
        : textableSecondJabPatients;
    const relevantNonTextableList = isBooster
        ? vaccineCourse === VaccineCourse.Booster
            ? nonTextableBoosterPatients
            : nonTextableSeasonalBoosterPatients
        : nonTextableSecondJabPatients;
    const relevantTextableListIsNonEmpty = relevantTextableList.length !== 0;
    const relevantNonTextableListIsNonEmpty =
        relevantNonTextableList.length !== 0;

    // Should send invites if there's any relevant patients, regardless of whether they're textable or not
    // This is not necessarily sending an SMS (e.g. if all are nontextable), just putting invites into a state where they're bookable
    const shouldSendInvites =
        capacityCheckPassed &&
        (relevantTextableListIsNonEmpty || relevantNonTextableListIsNonEmpty);
    const actionInProgress =
        capacityCheckStatus === UpdatingStatus.Initial ||
        capacityCheckStatus === UpdatingStatus.Loading;

    //combine both lists and transform into VaccinePatientInvited objects to use in the bulk actions component
    // N.B. lots left blank here as we only need the bits that will be displayed in the bulk actions component or used in promiseForInvitingForSecond
    const listOfPatientsForPromise = relevantTextableList
        .concat(relevantNonTextableList)
        .map((patient) => {
            const newPatient: VaccinePatientInvited = {
                patientExternalIdentity: patient.patientExternalIdentity,
                contactNumber: patient.contactNumber,
                patientName: patient.patientName,
                dateOfBirth: patient.dateOfBirth,
                currentInviteStatus: patient.inviteStatus,
                inviteId: patient.inviteId,
                inviteBatchId: vaccineDeliveryId,
                invitedBy: { nationalCode: "", name: "" }, // won't need this in the bulk action promise
                inviteCreatedAt: "", // won't need this in the bulk action promise
                sendMessages: false, // won't need this in the bulk action promise
                hasNimsConflict: false, // won't need this in the bulk action promise
                hasNbsConflict: false, // won't need this in the bulk action promise
                vaccineName: null, // won't need this in the bulk action promise
                lastVaccineDate: null,
            };
            return newPatient;
        });

    const handleSendMessages = (): void => {
        if (isBooster) {
            ChainAnalyticsTracker.trackVaccineInviteToBoosterBooking(
                analyticsLoggedInProps,
            );
        } else {
            ChainAnalyticsTracker.trackVaccineInviteToSecondBooking(
                analyticsLoggedInProps,
            );
        }
        setSendingMessages(true);
    };

    const handleContinue = (): void => {
        history.push(
            isBooster
                ? ROUTES_CHAIN.practicesVaccineInvitesReviewInvites.replace(
                      ":orgId",
                      selectedPractice,
                  )
                : ROUTES_CHAIN.practicesVaccineInvitesUploadSecond.replace(
                      ":orgId",
                      selectedPractice,
                  ),
        );
    };

    const handleForwardClick = (): void => {
        // If not going to send invites, or in the process (or finished) of sending messages
        if (!shouldSendInvites || sendingMessages) {
            handleContinue(); // click through to next page
        }
        handleSendMessages(); // else - start sending messages
    };

    const handleBackToInviteList = (): void => {
        history.push(
            ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                ":orgId",
                selectedPractice,
            ),
        );
    };

    const promiseForInviting = (
        patient: VaccinePatientInvited,
    ): Promise<IWrappedResult<any>> => {
        setFinishedSendingMessages(true);

        if (isBooster) {
            const request: RecallBoosterSmsRequest = {
                messageTemplate: boosterMessageTemplate,
                customSmsSignature: messageSignature,
            };
            return ChainApiClient.sendInviteToBoosterBooking(
                selectedPractice,
                patient.inviteBatchId,
                patient.inviteId,
                request,
            );
        }
        const request = {
            customSmsSignature: messageSignature,
        };
        return ChainApiClient.sendInviteToSecondBooking(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            request,
        );
    };

    const assembleSMS = (textToCheck: string, isSignature: boolean): string => {
        if (!isBooster) {
            return `${primaryMessageFixedBody}\n${textToCheck}`;
        }

        // Two editable bits for the booster SMS so take this into account when assembling the SMS

        if (isSignature) {
            // Only editing the signature, so assemble the text with the already edited template
            return `${patientGreetingCopy}\n${boosterMessageTemplate}\n${boosterMessageFixedBody}\n${textToCheck}`;
        } else {
            // Only editing the template, so assemble the text with the already edited signature
            return `${patientGreetingCopy}\n${textToCheck}\n${boosterMessageFixedBody}\n${messageSignature}`;
        }
    };

    // For boosters there are two editable bits, so we need to know if the text being edited is the template or signature
    // so that we can assemble the SMS properly
    const checkMessageTextAndSetState = (
        textToCheck: string,
        isSignature: boolean,
    ): boolean => {
        const assembled = assembleSMS(textToCheck, isSignature);
        const totalCharacterCount = calculateMessageLength(assembled, 0);
        setCharacterCount(totalCharacterCount);

        if (textToCheck.length <= 2) {
            // Need some body
            setValidMessageBody(false);
            return false;
        }

        if (totalCharacterCount > MaxBatchSMSSize) {
            setValidMessageBody(false);
            setMessageErrorMessage(
                `Sorry, your message must be equal to or less than 600 characters. ${characterCountWarning}`,
            );
            return false;
        }

        if (totalCharacterCount >= MaxSingleSMSSize) {
            setValidMessageBody(true);
            setMessageErrorMessage(
                `Reduce the number of characters to send a single text message. ${characterCountWarning}`,
            );
            return true;
        }

        setValidMessageBody(true);
        setMessageErrorMessage(characterCountWarning);
        return true;
    };

    const updateMessageSignature = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageSignature = event.target.value;
        checkMessageTextAndSetState(messageSignature, true);
        setMessageSignature(messageSignature);
    };

    const updateBoosterMessageTemplate = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageTemplate = event.target.value;
        checkMessageTextAndSetState(messageTemplate, false);
        setBoosterMessageTemplate(messageTemplate);
    };

    const renderInfoCopy = (isBooster: boolean): JSX.Element => {
        let headingCopy;
        let explanationCopy;

        if (isBooster) {
            if (relevantNonTextableList.length) {
                headingCopy = `To manually book (${boosterLabel} invites):`;
                explanationCopy = `You have not uploaded anyone for their ${boosterLabel} jab with a mobile number that will be sent a text invite to their ${boosterLabel} vaccination.`;
            }
        } else {
            if (nonTextableSecondJabPatients.length) {
                headingCopy = "To manually book (2nd invites):";
                explanationCopy =
                    "You have not uploaded anyone for their second jab with a mobile number that will be sent a text invite to their second vaccination.";
            } else {
                headingCopy = "No patients for 2nd invites";
                explanationCopy =
                    "None of the patients you've selected have had their first vaccinations (according to national vaccination data).";
            }
        }

        return (
            <Feedback
                colour="information"
                title={headingCopy}
                content={explanationCopy}
            />
        );
    };

    const renderMessageCompose = (isBooster: boolean): JSX.Element => (
        <Card spacing={2} props={{ className: "mb-2" }}>
            <>
                <div className="d-flex align-items-center">
                    <Icon theme="Line" size={3} name="Mail" />
                    <Text variant="label" skinny>
                        Invite text
                    </Text>
                </div>
                <div className="d-flex flex-column mb-3">
                    {isBooster && ( // Need to show extra textareas for the booster view as we allow editing part of the middle of the SMS as well
                        <>
                            <TextareaAutosize
                                autoFocus
                                style={{
                                    width: "100%",
                                    padding: "1rem",
                                    backgroundColor:
                                        Tokens.COLOURS.greyscale.white,
                                    color: Tokens.COLOURS.greyscale.stone,
                                    borderStyle: "solid",
                                    borderColor:
                                        Tokens.COLOURS.greyscale.silver,
                                    borderWidth: "1px 1px 0px 1px",
                                    resize: "vertical",
                                    outline: "none",
                                    paddingBottom: "0",
                                    paddingTop: "0",
                                    lineHeight: "2rem",
                                }}
                                disabled
                                value={patientGreetingCopy}
                                data-testid="booster-message-greeting"
                                rows={1}
                            />
                            <TextareaAutosize
                                autoFocus
                                style={{
                                    width: "100%",
                                    padding: "1rem",
                                    backgroundColor:
                                        Tokens.COLOURS.greyscale.white,
                                    borderStyle: "solid",
                                    borderColor:
                                        Tokens.COLOURS.greyscale.silver,
                                    borderWidth: "0px 1px 0px 1px",
                                    resize: "vertical",
                                    outline: "none",
                                    paddingBottom: "0",
                                    paddingTop: "0",
                                    lineHeight: "2rem",
                                }}
                                value={boosterMessageTemplate}
                                onChange={updateBoosterMessageTemplate}
                                data-testid="booster-message-template"
                                rows={1}
                            />
                        </>
                    )}
                    <TextareaAutosize
                        autoFocus
                        style={{
                            width: "100%",
                            padding: "1rem",
                            backgroundColor: Tokens.COLOURS.greyscale.white,
                            color: Tokens.COLOURS.greyscale.stone,
                            borderStyle: "solid",
                            borderColor: Tokens.COLOURS.greyscale.silver,
                            // will be first textarea on primary so need the top border
                            borderWidth: isBooster
                                ? "0px 1px 0px 1px"
                                : "1px 1px 0px 1px",
                            resize: "vertical",
                            outline: "none",
                            paddingBottom: "0",
                            paddingTop: "0",
                            lineHeight: "2rem",
                        }}
                        disabled
                        value={
                            isBooster
                                ? boosterMessageFixedBody
                                : primaryMessageFixedBody
                        }
                        data-testid="message-body"
                        rows={1}
                    />
                    <TextareaAutosize
                        style={{
                            width: "100%",
                            padding: "1rem",
                            borderStyle: "solid",
                            borderColor: Tokens.COLOURS.greyscale.silver,
                            borderWidth: "0px 1px 1px 1px",
                            resize: "vertical",
                            paddingTop: "0",
                            lineHeight: "2rem",
                        }}
                        value={messageSignature}
                        onChange={updateMessageSignature}
                        data-testid="message-footer"
                    />
                </div>

                <div className="d-flex justify-content-between">
                    <div>
                        {messageErrorMessage && (
                            <div className="d-flex pr-4">
                                <div>
                                    <Icon
                                        theme="Fill"
                                        name="Warning"
                                        size={2}
                                        colour="orange"
                                    />
                                </div>
                                <Text skinny>{messageErrorMessage}</Text>
                            </div>
                        )}
                    </div>
                    <Text
                        colour={
                            characterCount > MaxBatchSMSSize ? "red" : "night"
                        }
                        props={{ style: { minWidth: "4.5rem" } }}
                        skinny
                    >
                        {characterCount} / {MaxBatchSMSSize}
                    </Text>
                </div>
            </>
        </Card>
    );

    const renderSmsPreview = (isBooster: boolean): JSX.Element => {
        return (
            <div className="row mb-5">
                <div className="col-12 mb-3">
                    <Card
                        spacing={2}
                        header={
                            <>
                                <Text variant="label" as="span" skinny>
                                    {relevantTextableList.length +
                                        relevantNonTextableList.length}{" "}
                                    patients{" "}
                                </Text>
                                <Text as="span" skinny>
                                    to be invited today to{" "}
                                    {isBooster
                                        ? `${boosterLabel} vaccination`
                                        : "2nd vaccination"}
                                </Text>
                            </>
                        }
                    >
                        <>
                            <Text>
                                These patients will be sent SMS invites with
                                unique booking links
                            </Text>
                            <Accordion
                                header={`${relevantTextableList.length} patients`}
                                extra={
                                    <Ds.Badge color="yellow">
                                        Will be sent SMS invite
                                    </Ds.Badge>
                                }
                                icon={{ name: "Team" }}
                                className="mb-2"
                            >
                                <VaccineDeliveryComposePatientTable
                                    filteredList={relevantTextableList}
                                    testIdPrefix="textable"
                                    showNims={showNims}
                                />
                            </Accordion>

                            {renderMessageCompose(isBooster)}

                            <Text>
                                These patients do not have valid UK mobile
                                numbers, and need to be manually booked in
                            </Text>
                            <Accordion
                                header={`${relevantNonTextableList.length} patients`}
                                extra={
                                    <Ds.Badge>
                                        Will need to be manually booked
                                    </Ds.Badge>
                                }
                                icon={{ name: "Team" }}
                                className="mb-2"
                            >
                                <VaccineDeliveryComposePatientTable
                                    filteredList={relevantNonTextableList}
                                    testIdPrefix="nontextable"
                                    showNims={showNims}
                                />
                            </Accordion>
                        </>
                    </Card>
                </div>
            </div>
        );
    };

    const renderPage = (): JSX.Element => {
        if (!sendingMessages) {
            // Not doing the bulk action
            if (actionInProgress) {
                // Checking capacity
                return (
                    <div
                        className="align-items-center"
                        style={{ flexFlow: "column" }}
                    >
                        <Spinner />
                    </div>
                );
            }

            if (!capacityCheckPassed) {
                // Capacity check tried and failed
                return (
                    <Feedback
                        colour="error"
                        title={capacityMessage ?? "Unable to check capacities"}
                        content={
                            "Please continue and invite these patients later from the patient list"
                        }
                    />
                );
            }

            if (relevantTextableListIsNonEmpty) {
                // There are invites we can send texts for - render the compose screen
                return renderSmsPreview(isBooster);
            }

            return renderInfoCopy(isBooster); // There are no invites we can send texts for - render the info text
        } else {
            // Doing the bulk action
            return (
                <VaccineBulkActions
                    practices={practices}
                    practiceId={selectedPractice.toString()}
                    invites={listOfPatientsForPromise}
                    processFn={promiseForInviting}
                    onBackToInviteList={handleBackToInviteList}
                    successListTitle={"Invites sent"}
                    successListSubTitle={`These patients have been sent an invite for their ${
                        isBooster ? boosterLabel : "2nd"
                    } vaccination`}
                    failedListTitle={"Invited failed"}
                    failedListSubTitle={
                        isBooster
                            ? `These patients were unable to be invited for their ${boosterLabel} vaccination`
                            : "These patients were not invited for their 2nd vaccination as part of this upload as they have already been invited through accuBook. " +
                              "You can find them in the patient list under their relevant status"
                    }
                    context={
                        isBooster
                            ? BulkActionContext.InviteToBooster
                            : BulkActionContext.InviteToSecond
                    }
                    showStatusBadges={false}
                />
            );
        }
    };

    const setTitle = useMemo(() => {
        switch (vaccineCourse) {
            case VaccineCourse.Booster:
                return "Invite to booster vaccination";
            case VaccineCourse.BoosterSpring2022:
                return "Invite to spring 2022 booster vaccination";
            case VaccineCourse.BoosterAutumn2022:
                return "Invite to autumn 2022 booster vaccination";
            case VaccineCourse.BoosterSpring2023:
                return "Invite to spring 2023 booster vaccination";
            case VaccineCourse.BoosterAutumn2023:
                return "Invite to autumn 2023 booster vaccination";
            case VaccineCourse.BoosterSpring2024:
                return "Invite to spring 2024 booster vaccination";
            case VaccineCourse.BoosterAutumn2024:
                return "Invite to autumn 2024 booster vaccination";
            default:
                return "Invite to 2nd vaccination";
        }
    }, []);

    // only show jab state data if we have any (so that if NIMS display is off on the backend, we skip it)
    const showNims =
        invitesToInviteForSecond.filter(
            (item: VaccineDeliveryItem) =>
                item.jabState &&
                item.jabState !== VaccineExistingPatientState.Unknown,
        ).length > 0;
    const forwardButtonText = ((): string => {
        if (
            shouldSendInvites &&
            relevantTextableListIsNonEmpty &&
            !sendingMessages
        ) {
            // Will send texts, and haven't started sending texts yet
            return setTitle;
        }
        // Won't invite patients, or all patients are nontextable, or sending message is inflight
        return "Continue";
    })();

    return (
        <div>
            <div className="text-center">
                <VaccineDeliveryHeader
                    stepNumber={vaccineCourse !== VaccineCourse.Primary ? 3 : 4}
                    totalSteps={getTotalSteps(vaccineCourse)}
                    title={setTitle}
                />
            </div>
            <div className="row mb-5">
                <div className="col-8 offset-md-2">{renderPage()}</div>
            </div>

            <StepsFooter
                backText="Back"
                backLink={ROUTES_CHAIN.practicesVaccineInvitesSelectSecond.replace(
                    ":orgId",
                    selectedPractice,
                )}
                forwardText={forwardButtonText}
                forwardClickFunction={handleForwardClick}
                disabled={
                    actionInProgress ||
                    (relevantTextableListIsNonEmpty && !validMessageBody) ||
                    (sendingMessages && !finishedSendingMessages)
                }
            />
        </div>
    );
};

export default VaccineDeliveryComposeSecond;
