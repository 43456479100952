import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { deleteAccumailTemplate } from "api/Accumail/AccumailTemplatesApi";
import {
    AccumailTemplate,
    AccumailTemplateType,
} from "app/practices/accumail/templates.types";

import { ACCUMAIL_TEMPLATES_CACHE_KEY } from "../queries/useAccumailTemplatesQuery";

type DeleteAccumailTemplateMutationVariables = {
    workspaceId: number;
    templateType: AccumailTemplateType;
    templateId: string;
};

export const useDeleteAccumailTemplateMutation =
    createMutationHook<DeleteAccumailTemplateMutationVariables>(
        ({ queryClient }) => ({
            mutationKey: ["DeleteAccumailTemplate"],
            mutationFn: async ({ workspaceId, templateId }) => {
                const response = await deleteAccumailTemplate(
                    workspaceId,
                    templateId,
                );

                returnDataOrThrow(response);
            },
            onMutate: async ({ templateId, templateType, workspaceId }) => {
                const queryKey = [
                    ACCUMAIL_TEMPLATES_CACHE_KEY,
                    { workspaceId, templateType },
                ];

                await queryClient.cancelQueries({
                    queryKey,
                });

                const previousTemplates =
                    queryClient.getQueryData<AccumailTemplate[]>(queryKey);

                queryClient.setQueryData<AccumailTemplate[]>(queryKey, (old) =>
                    old === undefined
                        ? undefined
                        : old.filter(
                              (t) =>
                                  !(
                                      t.id === templateId &&
                                      t.templateType === templateType
                                  ),
                          ),
                );

                return { previousTemplates };
            },
        }),
    );
