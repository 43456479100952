import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";

export const TABS: TabProps[] = [
    { text: "Manage patients", tabId: "manage-patients" },
    { text: "Manage clinics", tabId: "manage-clinics" },
    { text: "Booked patients", tabId: "booked-patients" },
    { text: "Delivery sites", tabId: "delivery-sites" },
    { text: "Dashboard", tabId: "dashboard" },
];

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;
