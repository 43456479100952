import React, { useEffect, useState } from "react";

import {
    Input,
    Pagination,
    SelectItem,
    SingleSelect,
    Table,
    Text,
    usePagination,
    useTable,
} from "@accurx/design";
import { useFuzzyFilter } from "@accurx/hooks";
import { useAccurxWebTitle } from "@accurx/navigation";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { LoadingWrapper } from "app/sharedComponents/LoadingWrapper";
import { SupportUrls } from "shared/constants/supportUrls";

import {
    StyledPaginationContainer,
    StyledSelectorContainer,
} from "./DeliveryReceipts.styles";
import { PatientIdStatusType, ReceiptItem } from "./DeliveryReceipts.types";
import {
    getPatientIdentifierColumnHeader,
    returnTextForTable,
    rowTextColour,
} from "./DeliveryReceipts.utils";

const DeliveryStatus = {
    delivered: "Delivered",
    sent: "Sent",
    failed: "Failed",
    all: "Select status...",
};

// Format the date to be DD/MM/YY HH:MM:SS from string
const dateFormat = (date: string | undefined) => {
    // if can be parsed, render as local string, else output raw text
    // Server returns "Hidden" for receipts the User does not have permission to see, so must handle this
    if (date && Date.parse(date) > 0) {
        return DateHelpers.formatDate(
            date,
            DateFormatOptions.DATE_TIME_WITH_SECONDS,
        );
    }
    return date;
};

// Render rows with failed send status with red text
const renderFailedRowTextRed = (row: ReceiptItem) => {
    if (row.status) return rowTextColour(row.status);

    return "black";
};

const setPatientIdentifierHeader = (headerType?: PatientIdStatusType) => {
    return getPatientIdentifierColumnHeader(headerType);
};

interface DeliveryReceiptsProps {
    noHeader?: boolean;
    practiceId: number;
    loadingStatus: {
        hasLoaded: boolean;
        hasLoadingFailed: boolean;
    };
    receipts?: ReceiptItem[];
    receiptHeaderType?: PatientIdStatusType;
    isRestrictedToOwnReceipts: boolean;
    actions: {
        getInitialData: (practiceId: number) => void;
    };
}

const DeliveryReceipts = (props: DeliveryReceiptsProps): JSX.Element => {
    useAccurxWebTitle("View sent messages");

    useEffect(() => {
        props.practiceId && props.actions.getInitialData(props.practiceId);
    }, [props.actions, props.practiceId]);

    const {
        loadingStatus,
        receipts = [],
        receiptHeaderType,
        isRestrictedToOwnReceipts,
    } = props;
    const summary = isRestrictedToOwnReceipts
        ? "Here you can see the delivery status for your SMS messages. Those sent by your team are hidden - please get Approved by your Admin to see these."
        : "Here you can see the delivery status for SMS messages sent by your team.";

    const [searchId, setSearchId] = useState("");
    const [searchName, setSearchName] = useState("");

    const tableRows = useFuzzyFilter(
        receipts,
        {
            patientIdDisplay: searchId,
            sender: searchName,
        },
        {
            keys: ["patientIdDisplay", "sender"],
        },
    );

    const table = useTable({
        data: tableRows,
        columnDefs: [
            {
                id: "patientIdDisplay",
                header: setPatientIdentifierHeader(receiptHeaderType),
            },
            {
                id: "sender",
                header: "Sender Name",
            },
            {
                id: "dataSent",
                header: "Date Sent",
            },
            {
                id: "status",
                header: "Status",
            },
        ],
    });

    const {
        currentPage,
        totalPages,
        currentPageSize,
        onNext,
        onPrevious,
        onPageSizeChange,
        paginate,
        goToPage,
    } = usePagination({
        defaultPage: 1,
        defaultPageSize: 50,
        total: table.rows.length,
        isCycleEnabled: true,
    });

    const handleStatusFilter: (filter: string) => void = (filter: string) => {
        table.actions.filter(
            (data) => filter === DeliveryStatus.all || data.status === filter,
        );
        goToPage(1);
    };

    return (
        <LoadingWrapper loadingStatus={loadingStatus}>
            {!props.noHeader && (
                <>
                    <Breadcrumb title="Delivery Receipts" />
                    <Text variant="title" as="h1">
                        Delivery Receipts
                    </Text>
                </>
            )}
            <Text>{summary}</Text>
            <Text skinny>
                <Text variant="label" as="span">
                    Sent:
                </Text>{" "}
                SMS sent but not yet delivered to recipient's phone.
            </Text>
            <Text skinny>
                <Text variant="label" as="span">
                    Delivered:
                </Text>{" "}
                SMS has been successfully delivered to recipient's phone.
            </Text>
            <Text skinny>
                <Text variant="label" as="span">
                    Failed:
                </Text>{" "}
                We were unable to deliver this SMS to the recipient's phone.
            </Text>
            <Text props={{ className: "my-2" }}>
                See{" "}
                <a
                    href={SupportUrls.DeliveryStateMeaning}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    this article
                </a>{" "}
                for more details.
            </Text>
            <div className="mt-4">
                <Table>
                    <Table.Head>
                        <Table.Row>
                            {table.columns.map((column) => (
                                <Table.Header key={column.id} scope="col">
                                    {column.header}

                                    {column.id === "patientIdDisplay" && (
                                        <Input
                                            type="search"
                                            placeholder="Enter NHS Number..."
                                            value={searchId}
                                            onChange={(e) => {
                                                setSearchId(e.target.value);
                                                goToPage(1);
                                            }}
                                            className="mt-2"
                                        />
                                    )}

                                    {column.id === "sender" && (
                                        <Input
                                            type="search"
                                            placeholder="Enter Sender Name..."
                                            value={searchName}
                                            onChange={(e) => {
                                                setSearchName(e.target.value);
                                                goToPage(1);
                                            }}
                                            className="mt-2"
                                        />
                                    )}

                                    {column.id === "status" && (
                                        <StyledSelectorContainer>
                                            <SingleSelect
                                                id="delivery-status"
                                                defaultValue={
                                                    DeliveryStatus.all
                                                }
                                                onValueChange={
                                                    handleStatusFilter
                                                }
                                                dimension="medium"
                                            >
                                                <SelectItem
                                                    value={DeliveryStatus.all}
                                                >
                                                    {DeliveryStatus.all}
                                                </SelectItem>
                                                <SelectItem
                                                    value={
                                                        DeliveryStatus.delivered
                                                    }
                                                >
                                                    {DeliveryStatus.delivered}
                                                </SelectItem>
                                                <SelectItem
                                                    value={DeliveryStatus.sent}
                                                >
                                                    {DeliveryStatus.sent}
                                                </SelectItem>
                                                <SelectItem
                                                    value={
                                                        DeliveryStatus.failed
                                                    }
                                                >
                                                    {DeliveryStatus.failed}
                                                </SelectItem>
                                            </SingleSelect>
                                        </StyledSelectorContainer>
                                    )}
                                </Table.Header>
                            ))}
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {paginate(table.rows).map((row, index) => (
                            <Table.Row
                                key={`${index}__${row.patientIdDisplay}`}
                                className={renderFailedRowTextRed(row)}
                            >
                                <Table.DataCell>
                                    {row.patientIdDisplayType &&
                                    receiptHeaderType
                                        ? returnTextForTable(
                                              row.patientIdDisplay,
                                              row.patientIdDisplayType,
                                              receiptHeaderType,
                                          )
                                        : row.patientIdDisplayType}
                                </Table.DataCell>
                                <Table.DataCell>{row.sender}</Table.DataCell>
                                <Table.DataCell>
                                    {dateFormat(row.dateSent)}
                                </Table.DataCell>
                                <Table.DataCell>{row.status}</Table.DataCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>

            <StyledPaginationContainer>
                <Pagination.SizeSelect
                    id="test-pagination-size"
                    onPageSizeChange={onPageSizeChange}
                    pageSize={currentPageSize}
                >
                    <Pagination.SizeOption value={50} />
                    <Pagination.SizeOption value={receipts.length}>
                        All
                    </Pagination.SizeOption>
                </Pagination.SizeSelect>

                <Pagination>
                    <Pagination.Navigation
                        currentPage={currentPage}
                        totalPages={totalPages}
                    >
                        {currentPage > 1 && (
                            <>
                                <Pagination.PreviousButton
                                    onPrevious={onPrevious}
                                />
                            </>
                        )}

                        {currentPage < totalPages && (
                            <>
                                <Pagination.NextButton onNext={onNext} />
                            </>
                        )}
                    </Pagination.Navigation>
                </Pagination>
            </StyledPaginationContainer>
        </LoadingWrapper>
    );
};

export default DeliveryReceipts;
