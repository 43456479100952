import { PatientThreadLabel } from "shared/concierge/conversations/tickets/types/dto.types";
import { LabelTagConversationItem } from "shared/concierge/conversations/types/item.types";

import {
    mapBaseConversationItem,
    mapCreatedByOptionalUser,
} from "./conversationItem.helpers";

/**
 * Maps ticket label tag to conversation item. Not yet fully supported, just enough to avoid error logging
 * @param labelTagItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapLabelTagItem(
    labelTagItem: PatientThreadLabel,
): LabelTagConversationItem | undefined {
    const baseConversationItem = mapBaseConversationItem(labelTagItem);

    return {
        ...baseConversationItem,
        contentType: "LabelTag",
        createdBy: mapCreatedByOptionalUser(
            labelTagItem.message?.createdByUserId,
        ),
        outcome: labelTagItem.message?.labelText,
    };
}
