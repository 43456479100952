import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledEntryCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        flex-wrap: nowrap;
    }
`;

const StyledContent = styled.div`
    margin-right: ${Tokens.SIZES[2]};

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        margin-bottom: ${Tokens.SIZES[1]};
    }
`;

const StyledPatientDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    white-space: nowrap;
    margin-top: ${Tokens.SIZES[0.5]};

    > *:not(:last-child) {
        margin-right: 0;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        flex-direction: row;

        > *:not(:last-child) {
            margin-right: ${Tokens.SIZES[3]};
        }
    }
`;

export { StyledEntryCardWrapper, StyledContent, StyledPatientDetails };
