/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import * as React from "react";
import { useEffect, useState } from "react";

import { Icon, Text } from "@accurx/design";

import { StyledBadge } from "../shared/shared.styles";
import {
    getBadgeColour,
    getBadgeText,
    getIconColour,
} from "./LiveStatusBadge.util";

type LiveStatusBadgeProps = {
    lastFetched: number | undefined;
};

const ONE_MINUTE = 60 * 1000;

export const LiveStatusBadge = ({
    lastFetched,
}: LiveStatusBadgeProps): JSX.Element => {
    const [diff, setDiff] = useState(lastFetched ? 0 : undefined);

    useEffect(() => {
        if (!lastFetched) return;
        setDiff(0);
        const interval = setInterval(() => {
            setDiff((diff) => (diff ?? 0) + 1);
        }, ONE_MINUTE);
        return () => {
            clearInterval(interval);
        };
    }, [lastFetched]);
    return (
        <StyledBadge colour={getBadgeColour(diff)}>
            <Icon
                name="Dot"
                theme="Fill"
                colour={getIconColour(diff)}
                size={2}
            />
            <Text skinny variant="body" as="div">
                <Text skinny variant="label" as="span">
                    Last updated:
                </Text>{" "}
                {getBadgeText(diff)}
            </Text>
        </StyledBadge>
    );
};
