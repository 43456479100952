import { QuestionType } from "@accurx/api/florey-builder";

import { ClientSideQuestion } from "../types/context.types";
import {
    BranchErrors,
    ErrorIds,
    InformationQuestionErrors,
    MultipleChoiceQuestionErrors,
    QuestionConsts,
    TextQuestionErrors,
    ValidateReturn,
} from "../types/data.types";

export const validateQuestion = (
    question: ClientSideQuestion,
): ValidateReturn => {
    switch (question.questionType) {
        case QuestionType.MultipleChoice:
            return validateMultipleChoiceQuestion(question);
        case QuestionType.Text:
            return validateTextQuestion(question);
        case QuestionType.Information:
            return validateInformationQuestion(question);
        case QuestionType.Branching:
            return validateBranchingQuestion(question);
    }

    return {
        isValid: true,
        errors: {},
    };
};

const validateMultipleChoiceQuestion = (
    question: ClientSideQuestion,
): ValidateReturn => {
    const {
        questionTitle,
        helperText,
        options,
        imageDocumentUrl,
        imageAltText,
    } = question;
    const multipleChoiceQuestionErrors: MultipleChoiceQuestionErrors = {};

    if (
        questionTitle === null ||
        questionTitle === undefined ||
        questionTitle === "" ||
        questionTitle.trim().length === 0
    ) {
        multipleChoiceQuestionErrors.questionTitle = {
            errorMessage: "Enter a question title",
            errorId: ErrorIds.questionTitleError,
        };
    }
    if (questionTitle && questionTitle.length > QuestionConsts.TitleMaxLength) {
        multipleChoiceQuestionErrors.questionTitle = {
            errorMessage: `Enter a question title shorter than ${QuestionConsts.TitleMaxLength} characters.`,
            errorId: ErrorIds.questionTitleError,
        };
    }

    if (helperText && helperText.length > QuestionConsts.DescriptionMaxLength) {
        multipleChoiceQuestionErrors.helperText = {
            errorMessage: `Enter a question description shorter than ${QuestionConsts.DescriptionMaxLength} characters.`,
            errorId: ErrorIds.questionDescriptionError,
        };
    }

    if (options?.length === 0) {
        multipleChoiceQuestionErrors.optionsFirst = {
            errorMessage:
                "Multiple choice question must have at least one answer",
            errorId: ErrorIds.questionMultipleChoiceOptionRequired,
        };
    }

    if (options && options.length > QuestionConsts.MultipleChoiceMaxOptions) {
        multipleChoiceQuestionErrors.optionsLast = {
            errorMessage: `A maximum of ${QuestionConsts.MultipleChoiceMaxOptions} answers allowed per question`,
            errorId: ErrorIds.questionMultipleChoiceOptionMaximum,
        };
    }

    options?.forEach((option, index) => {
        if (!option.text || option.text.trim().length === 0) {
            const newError = {
                errorMessage: "Enter answer text",
                errorId: ErrorIds.questionMultipleChoiceOptionAnswer + index,
            };
            if (multipleChoiceQuestionErrors.options) {
                multipleChoiceQuestionErrors.options[index] = newError;
            } else {
                multipleChoiceQuestionErrors.options = [newError];
            }
        }
        if (
            option &&
            option.text &&
            option.text.length > QuestionConsts.MultipleChoiceOptionsMaxLength
        ) {
            const newError = {
                errorMessage: `A maximum of ${QuestionConsts.MultipleChoiceOptionsMaxLength} characters is allowed per answer for multiple choice question`,
                errorId: ErrorIds.questionMultipleChoiceOptionAnswer + index,
            };
            if (multipleChoiceQuestionErrors.options) {
                multipleChoiceQuestionErrors.options[index] = newError;
            } else {
                multipleChoiceQuestionErrors.options = [newError];
            }
        }
    });

    if (imageDocumentUrl && !imageAltText) {
        multipleChoiceQuestionErrors.imageAltText = {
            errorMessage: "A descriptive text for the image is required",
            errorId: ErrorIds.questionImageDescriptionError,
        };
    }

    if (Object.keys(multipleChoiceQuestionErrors).length === 0) {
        return {
            isValid: true,
            errors: {},
        };
    } else {
        return {
            isValid: false,
            errors: multipleChoiceQuestionErrors,
        };
    }
};

const validateTextQuestion = (question: ClientSideQuestion): ValidateReturn => {
    const { questionTitle, helperText, imageDocumentUrl, imageAltText } =
        question;
    const questionErrors: TextQuestionErrors = {};
    if (
        questionTitle === null ||
        questionTitle === undefined ||
        questionTitle === "" ||
        questionTitle.trim().length === 0
    ) {
        questionErrors.questionTitle = {
            errorMessage: "Enter a question title",
            errorId: ErrorIds.questionTitleError,
        };
    }
    if (questionTitle && questionTitle.length > QuestionConsts.TitleMaxLength) {
        questionErrors.questionTitle = {
            errorMessage: `Enter a question title shorter than ${QuestionConsts.TitleMaxLength} characters.`,
            errorId: ErrorIds.questionTitleError,
        };
    }

    if (helperText && helperText.length > QuestionConsts.DescriptionMaxLength) {
        questionErrors.helperText = {
            errorMessage: `Enter a question description shorter than ${QuestionConsts.DescriptionMaxLength} characters.`,
            errorId: ErrorIds.questionDescriptionError,
        };
    }

    if (imageDocumentUrl && !imageAltText) {
        questionErrors.imageAltText = {
            errorMessage: "A descriptive text for the image is required",
            errorId: ErrorIds.questionImageDescriptionError,
        };
    }

    if (Object.keys(questionErrors).length === 0) {
        return {
            isValid: true,
            errors: {},
        };
    } else {
        return {
            isValid: false,
            errors: questionErrors,
        };
    }
};

const validateInformationQuestion = (
    question: ClientSideQuestion,
): ValidateReturn => {
    const { questionTitle, helperText, imageDocumentUrl, imageAltText } =
        question;
    const errors: InformationQuestionErrors = {};

    if (
        questionTitle === null ||
        questionTitle === undefined ||
        questionTitle === "" ||
        questionTitle.trim().length === 0
    ) {
        errors.questionTitle = {
            errorMessage: "Enter a heading",
            errorId: ErrorIds.questionTitleError,
        };
    }
    if (questionTitle && questionTitle.length > QuestionConsts.TitleMaxLength) {
        errors.questionTitle = {
            errorMessage: `Enter a heading shorter than ${QuestionConsts.TitleMaxLength} characters.`,
            errorId: ErrorIds.questionTitleError,
        };
    }

    if (helperText && helperText.length > QuestionConsts.DescriptionMaxLength) {
        errors.helperText = {
            errorMessage: `Enter content shorter than ${QuestionConsts.DescriptionMaxLength} characters.`,
            errorId: ErrorIds.questionDescriptionError,
        };
    }

    if (imageDocumentUrl && !imageAltText) {
        errors.imageAltText = {
            errorMessage: "A descriptive text for the image is required",
            errorId: ErrorIds.questionImageDescriptionError,
        };
    }

    if (Object.keys(errors).length === 0) {
        return {
            isValid: true,
            errors: {},
        };
    } else {
        return {
            isValid: false,
            errors: errors,
        };
    }
};

const validateBranchingQuestion = (
    question: ClientSideQuestion,
): ValidateReturn => {
    const branchErrors: BranchErrors = {};

    const { branchData } = question;

    const conditionAnswerText =
        branchData?.condition.conditionQuestion.answerText;
    const conditionQuestionId =
        branchData?.condition.conditionQuestion.questionId;
    if (!conditionQuestionId) {
        branchErrors.conditionQuestion = {
            errorMessage: "Choose a question",
            errorId: ErrorIds.branchConditionQuestion,
        };
    }
    if (!conditionAnswerText) {
        branchErrors.conditionAnswer = {
            errorMessage: "Choose a matching answer",
            errorId: ErrorIds.branchConditionAnswer,
        };
    }

    if (Object.keys(branchErrors).length === 0) {
        return {
            isValid: true,
            errors: {},
        };
    } else {
        return {
            isValid: false,
            errors: branchErrors,
        };
    }
};
