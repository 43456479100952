import { ReactNode, useEffect } from "react";

import { LoggedInOrOutUser, useAuth } from "@accurx/auth";
import { ComposeRoutes } from "@accurx/compose-toolbar";
import { ConciergeProvider } from "@accurx/concierge";
import { TooltipProvider } from "@accurx/design";
import { App as Inbox } from "@accurx/inbox";
import { getEmbedMode } from "@accurx/native";
import { QuickViewProvider } from "@accurx/quick-view";
import { SelfbookRoutes } from "@accurx/self-book";
import { Log } from "@accurx/shared";
import { TransportProvider } from "@accurx/transport";
import { GlobalAdminPendingRequestsModal } from "@accurx/workspace-management";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import AccurxWeb from "app/App";
import { SimpleRoute } from "app/AppRoute";
import { TrustWebInboxProviders } from "app/TrustWebInboxProviders";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import { StyledMainContent, StyledWrapper } from "app/layout/Layout.styles";
import { DevToolbar } from "app/layout/devToolbar/DevToolbar";
import { PrimaryNavigation } from "app/layout/navigationMenu/primaryNavigation";
import { withLoginCheck } from "app/withLoginCheck";
import { GlobalMyWorkspaceModal } from "app/workspace/components/GlobalMyWorkspaceModal/GlobalMyWorkspaceModal";
import { HubClientProvider } from "shared/hubClient/HubClientContext";

import { ROUTES, ROUTES_CHAIN, ROUTES_ORGS } from "./shared/Routes";
import { useWorkspaceValidator } from "./shared/useWorkspaceValidator";

/**
 * Horrible, but until we pull logged out pages out of the tree
 * this feels like the best compromise
 */
const OptionalConciergeProvider = ({
    children,
    workspaceId,
    user,
    isTrustedDevice,
}: {
    user: LoggedInOrOutUser;
    workspaceId?: number | null;
    isTrustedDevice: boolean;
    children: ReactNode;
}) => {
    const enabled = useFeatureFlag("AccurxWebEmbeddedUnifiedInbox");
    const { embedMode } = getEmbedMode();
    const hasClinicianMessagingInboxFlag = useFeatureFlag(
        "UnifiedInboxAccumailSandboxBuild",
    );

    if (!user.isLoggedIn || !workspaceId) return <>{children}</>;
    return (
        <ConciergeProvider
            userId={user.accuRxUserId}
            workspaceId={workspaceId}
            isTrustedDevice={
                isTrustedDevice &&
                /* TODO remove this once the inbox is fully live
                 * this is a temporary hack to avoid us
                 * spinning up the full concierge layer for
                 * workspaces where the new inbox hasn't been enabled yet
                 * */
                enabled
            }
            withClinicianMessaging={
                hasClinicianMessagingInboxFlag && embedMode === "Web"
            }
        >
            {children}
        </ConciergeProvider>
    );
};

const WebApp = ({
    user,
    selectedOrgId,
}: {
    user: LoggedInOrOutUser;
    selectedOrgId: number | null;
}) => {
    const isValidWorkspace = useWorkspaceValidator();
    const isManageOrg = useRouteMatch({
        path: [ROUTES_ORGS.root, ROUTES_CHAIN.practices],
    });

    if (!isValidWorkspace) {
        return (
            <Redirect to={isManageOrg ? ROUTES_CHAIN.practices : ROUTES.home} />
        );
    }

    return (
        <TrustWebInboxProviders
            workspaceId={selectedOrgId}
            is2FAed={user.isLoggedIn ? user.is2FAed : false}
            currentUserId={user.isLoggedIn ? user.accuRxUserId : undefined}
        >
            <GlobalAdminPendingRequestsModal />
            <GlobalMyWorkspaceModal />
            <Switch>
                <GuardedRoute
                    path={["/inbox/*", "/inbox"]}
                    requires={{
                        authenticationStatus: "LoggedInWith2FA",
                        onboarded: true,
                    }}
                >
                    <StyledWrapper>
                        {/* The inbox handles its own navigation menu at different breakpoints. Therefore we do not render the primary navigation here,
                                            but instead pass it in for the inbox to render.*/}
                        <StyledMainContent>
                            <Inbox
                                navigationMenuComponent={<PrimaryNavigation />}
                            />
                        </StyledMainContent>
                    </StyledWrapper>
                </GuardedRoute>
                <SimpleRoute
                    path={[
                        "/w/:workspaceId/self-book/*",
                        "/w/:workspaceId/self-book",
                    ]}
                >
                    <SelfbookRoutes />
                </SimpleRoute>
                <SimpleRoute path="*">
                    <AccurxWeb />
                </SimpleRoute>
            </Switch>
        </TrustWebInboxProviders>
    );
};

const OptionalTransportProvider = ({
    workspaceId,
    children,
}: {
    workspaceId: number | null;
    children: ReactNode;
}): JSX.Element => {
    const isValidWorkspace = useWorkspaceValidator();

    if (!workspaceId || !isValidWorkspace) return <>{children}</>;

    return <TransportProvider>{children}</TransportProvider>;
};

export const BaseApp = withLoginCheck(() => {
    const history = useHistory();
    const { user } = useAuth();
    const { selectedOrgId } = useSelectedOrganisation();
    const { embedMode } = getEmbedMode();

    const isDevEnvironment =
        window.location.host.includes("localhost") ||
        window.location.host.includes(".dev.accurx.com");

    useEffect(() => {
        // track usage of Ctrl/Cmd+F to see if and where people use it
        const ctrlF = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
                const tags = {
                    path: history.location.pathname,
                    querystring: history.location.search,
                    organisation: selectedOrgId,
                    user: "accuRxUserId" in user ? user.accuRxUserId : "",
                    isInWebView: embedMode === "Desktop",
                };
                Log.info("User triggered browsers Find (Ctrl/Cmd+F)", { tags });
            }
        };

        window.addEventListener("keydown", ctrlF);
        return () => window.removeEventListener("keydown", ctrlF);
    }, [history, user, selectedOrgId, embedMode]);

    return (
        <HubClientProvider
            currentUserId={user.isLoggedIn ? user.accuRxUserId : null}
        >
            <OptionalTransportProvider workspaceId={selectedOrgId}>
                <ToastContainer
                    position="top-center"
                    hideProgressBar
                    draggable
                    closeButton={false}
                />
                <OptionalConciergeProvider
                    user={user}
                    workspaceId={selectedOrgId}
                    isTrustedDevice={user.isTrustedDevice}
                >
                    <TooltipProvider>
                        <QuickViewProvider>
                            {/* If the user is coming from WebView2, we only allow them to visit the inbox, and not the rest of Accurx Web. In these cases, guarding against
             login and 2FA statuses are handled by the inbox itself. Otherwise, they are coming from a browser and they can use the whole app. */}
                            {embedMode === "Desktop" ? (
                                <Switch>
                                    <SimpleRoute path={["/inbox/*", "/inbox"]}>
                                        <Inbox />
                                    </SimpleRoute>
                                    <SimpleRoute
                                        path={[
                                            "/w/:workspaceId/self-book/*",
                                            "/w/:workspaceId/self-book",
                                        ]}
                                    >
                                        <SelfbookRoutes />
                                    </SimpleRoute>
                                    <SimpleRoute path="/compose">
                                        <ComposeRoutes />
                                    </SimpleRoute>
                                </Switch>
                            ) : (
                                <WebApp
                                    user={user}
                                    selectedOrgId={selectedOrgId}
                                />
                            )}
                            {isDevEnvironment && <DevToolbar />}
                        </QuickViewProvider>
                    </TooltipProvider>
                </OptionalConciergeProvider>
            </OptionalTransportProvider>
        </HubClientProvider>
    );
});
