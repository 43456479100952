import React from "react";

import { FormFieldFeedback, Icon, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledClickableAreaButton = styled.button`
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.dishwater};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
    &:active,
    &:hover,
    &:focus {
        background-color: ${Tokens.COLOURS.primary.blue[10]};
        border-color: ${Tokens.COLOURS.primary.blue[100]};
    }
    & * {
        justify-content: center;
    }
`;

type AddQuestionButtonPropTypes = {
    onClick: () => void;
    maxQuestionLimit?: number;
    limitError?: boolean;
};
const AddQuestionButton = ({
    onClick,
    maxQuestionLimit = 15,
    limitError,
}: AddQuestionButtonPropTypes): JSX.Element => {
    return (
        <StyledClickableAreaButton onClick={onClick}>
            <Icon name="Plus" halo={{ colour: "blue", luminosity: "high" }} />
            <Text variant="label">Add question</Text>
            <Text>You can add maximum {maxQuestionLimit} questions</Text>
            {limitError && (
                <FormFieldFeedback
                    variant="error"
                    text={`You've reached the limit of ${maxQuestionLimit} questions`}
                />
            )}
        </StyledClickableAreaButton>
    );
};

export default AddQuestionButton;
