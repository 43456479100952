import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { WorkspaceFragmentUsage } from "@accurx/api/patient-messaging";

import { fragmentUsageDashboard } from "api/PatientMessagingServer/PatientMessagingServerApi";

export type FragmentUsageDashboardMutationVariables = {
    workspaceId: number;
    startDate: string;
    endDate: string;
};
type FragmentUsageDashboardMutationResponse = WorkspaceFragmentUsage;

export const useFragmentUsageDashboardMutation = createMutationHook<
    FragmentUsageDashboardMutationVariables,
    FragmentUsageDashboardMutationResponse
>(() => ({
    mutationKey: ["FragmentUsageDashboard"],
    mutationFn: async ({ workspaceId, startDate, endDate }) => {
        const response = await fragmentUsageDashboard({
            organisationId: workspaceId,
            startDate,
            endDate,
        });
        return returnDataOrThrow(response);
    },
}));
