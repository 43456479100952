import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import { StyledPillText } from "./PatientNotFound.styles";

export type PatientNotFoundProps = {
    large?: boolean;
};

export const PatientNotFound = ({ large = false }: PatientNotFoundProps) => {
    return (
        <>
            <UI.VisuallyHidden as="span">Patient not found, </UI.VisuallyHidden>
            {large ? (
                <Pill.Base
                    color={UI.Tokens.COLOURS.primary.yellow[25]}
                    border={false}
                >
                    <StyledPillText>Patient not found</StyledPillText>
                </Pill.Base>
            ) : (
                <UI.Ds.Badge color="yellow" aria-hidden="true">
                    Patient not found
                </UI.Ds.Badge>
            )}
        </>
    );
};
