import { EventUserType, LoggedInCustomProperties } from "@accurx/shared";

export type Referrer = { referrer: string };

export type ChainLoggedInCustomProperties = LoggedInCustomProperties & Referrer;

export type SharedRudderStackAnalyticProps = {
    eventVersion: number;
    eventUserType: EventUserType;
    userIsLoggedIn: boolean;
};

export type SharedLoggedInRudderStackAnalyticProps = LoggedInCustomProperties &
    SharedRudderStackAnalyticProps;

export function AddRudderStackAnalyticProps<T>(
    props: T & LoggedInCustomProperties,
    sharedRudderstackProps: Partial<SharedRudderStackAnalyticProps> = {},
): T & SharedLoggedInRudderStackAnalyticProps {
    const {
        eventVersion = 1,
        eventUserType = EventUserType.HealthcareProfessional,
        userIsLoggedIn = true,
    } = sharedRudderstackProps;

    return {
        ...props,
        eventVersion,
        eventUserType,
        userIsLoggedIn,
    };
}
