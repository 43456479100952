import { Link, Text } from "@accurx/design";

export const MissingSnomedCodeInformation = () => (
    <Text>
        If you can't find the code you're looking for,{" "}
        <Link
            href="https://forms.gle/WhLWuczWsniGqAUUA"
            openInNewTab
            text="let us know"
        />
        .
    </Text>
);
