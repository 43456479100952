import { UserRole } from "@accurx/api/portal";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

const updateUserRole = ({
    workspaceId,
    userId,
    role,
}: {
    workspaceId: string;
    userId: number;
    role: UserRole;
}): Promise<IWrappedResult<undefined>> =>
    httpClient.putJsonReturnSafeAsync(
        getApiEndpoint({
            path: "/api/portal/OrganisationSetup/:workspaceId/UpdateRole",
            params: { workspaceId },
        }),
        { userId, role },
    );

type UpdateUserRoleMutationOptions = {
    workspaceId: number;
    userId: number;
    role: UserRole;
};

export const useUpdateUserRoleMutation = (
    options: UseMutationOptions<
        void,
        Error,
        UpdateUserRoleMutationOptions
    > = {},
) =>
    useMutation({
        mutationKey: ["UpdateUserRole"],
        ...options,
        mutationFn: async ({ workspaceId, userId, role }) => {
            const response = await updateUserRole({
                workspaceId: workspaceId.toString(),
                userId,
                role,
            });

            if (!response.success) {
                throw new Error(response.error ?? "Failed to update user role");
            }
        },
    });
