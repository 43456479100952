import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { getApiEndpoint, httpClient } from "@accurx/shared";

import { PATIENT_INITIATED_API_ENDPOINTS } from "app/practices/patientTriage/constants/ApiEndpoints";
import { DashboardData } from "app/practices/patientTriage/types";

export const PATIENT_TRIAGE_DASHBOARD_FORTNIGHTLY_DATA_CACHE_KEY =
    "PatientTriageDashboardFortnightlyData";

type PatientTriageDashboardFortnightlyDataQueryVariables = {
    workspaceId: number;
};

export const usePatientTriageDashboardFortnightlyDataQuery = createQueryHook<
    PatientTriageDashboardFortnightlyDataQueryVariables,
    { usage: Array<DashboardData> }
>(({ workspaceId }) => ({
    queryKey: [
        PATIENT_TRIAGE_DASHBOARD_FORTNIGHTLY_DATA_CACHE_KEY,
        { workspaceId },
    ],
    queryFn: async () => {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.fornightlyData,
                params: { organisationId: workspaceId.toString() },
            }),
        );
        return returnDataOrThrow(response);
    },
}));
