import { createMutationHook } from "@accurx/api/QueryClient";
import { useCurrentUser } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";

type SettingsUserSignature = { signature: string };

export const useUpdateMessageSignatureMutation = createMutationHook<
    { signature: string },
    SettingsUserSignature | null
>(() => {
    const { user, update } = useCurrentUser();

    return {
        mutationKey: ["updateMessageSignature"],
        mutationFn: async ({ signature }) => {
            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/account/Manage/MessageSignature",
                }),
                { signature },
            );

            return returnDataOrThrow(response);
        },
        onSuccess: (data) => {
            const messageSignature = data?.signature ?? user.messageSignature;

            update({
                ...user,
                messageSignature,
            });
        },
    };
});
