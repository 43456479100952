import { Button, Ds, Flex } from "@accurx/design";

type UnmatchPatientModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirmUnmatch: () => void;
    isLoading?: boolean;
};

export const UnmatchPatientModal = ({
    isOpen,
    onClose,
    onConfirmUnmatch,
    isLoading,
}: UnmatchPatientModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Would you like to unmatch the current patient from this
                        conversation?
                    </Ds.Modal.Title>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            onClick={onClose}
                            type="button"
                            text="Cancel"
                            theme="secondary"
                        />
                        <Button
                            onClick={onConfirmUnmatch}
                            type="button"
                            text="Confirm"
                            disabled={isLoading}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
