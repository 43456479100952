import { ChangeEvent, FormEvent, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";

import { SendConfirmCodeProps } from "../TwoFactorModels";

export type ConfirmCodeCardProps = SendConfirmCodeProps & {
    onSubmit: (code: string, remember: boolean) => void;
    showRememberMe?: boolean;
};

const CODE_LENGTH = 6;

export const ConfirmCodeCard = ({
    onSubmit,
    error,
    isLoading,
    text: { title, mainText, submitButtonText },
    cancelButton,
    showRememberMe,
}: ConfirmCodeCardProps): JSX.Element => {
    const [isEdit, setIsEdit] = useState(false);
    const [accessCode, setAccessCode] = useState("");
    const [rememberTwoFactor, setRememberTwoFactor] = useState(
        !!showRememberMe,
    );

    const isNumeric = (value: string) => {
        return /^\d+$/.test(value);
    };

    const handleTwoFactorInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setIsEdit(true);

        // Allows only numbers (0...9) to be typed
        if (isNumeric(e.target.value) || e.target.value === "") {
            setAccessCode(e.target.value);
        }
    };

    const handleSendConfirmation = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        // Validate access code
        if (
            accessCode === "" ||
            accessCode.length !== CODE_LENGTH ||
            !isNumeric(accessCode)
        ) {
            return;
        }
        onSubmit(accessCode, rememberTwoFactor);
        setIsEdit(false);
    };

    // Only display the error if there is one and if we are not editing the field
    const displayErrors = error !== "" && !isEdit;

    return (
        <form onSubmit={handleSendConfirmation} noValidate>
            <Card spacing={3} header={title ?? undefined}>
                <Flex flexDirection="column" gap="5">
                    <Flex flexDirection="column" gap="2">
                        {mainText}
                        <FormFieldWrapper
                            label="6-digit code"
                            labelProps={{
                                htmlFor: "accessCode",
                                className: "col-form-label mt-0",
                            }}
                            errors={displayErrors ? [error] : undefined}
                        >
                            {/* Reduce width of input to be length of code + allow for some room for potential autocomplete extensions */}
                            <div style={{ width: "7.5rem" }}>
                                <Input
                                    id="accessCode"
                                    type="text"
                                    inputMode="numeric"
                                    className="form-control"
                                    onChange={handleTwoFactorInput}
                                    value={accessCode}
                                    pattern="[0-9]*"
                                    minLength={CODE_LENGTH}
                                    maxLength={CODE_LENGTH}
                                    autoComplete="off"
                                    placeholder="XXXXXX"
                                    readOnly={isLoading}
                                    hasErrors={displayErrors}
                                    autoFocus
                                />
                            </div>
                        </FormFieldWrapper>
                        {showRememberMe && (
                            <>
                                <div className="d-flex mt-1 align-items-center">
                                    <input
                                        type="checkbox"
                                        id="rememberTwoFactor"
                                        checked={rememberTwoFactor}
                                        onChange={(e): void =>
                                            setRememberTwoFactor(
                                                e.target.checked,
                                            )
                                        }
                                        aria-label="remember me"
                                        className="mr-2"
                                    />
                                    <Text
                                        as="label"
                                        variant="label"
                                        skinny
                                        props={{
                                            htmlFor: "rememberTwoFactor",
                                        }}
                                    >
                                        Remember me for 30 days
                                    </Text>
                                </div>
                                <Feedback colour="information">
                                    <Text variant="label" skinny>
                                        Do not use ‘Remember me for 30 days’
                                        when sharing logins on the same device
                                    </Text>
                                </Feedback>
                            </>
                        )}
                    </Flex>
                    <Flex justifyContent="flex-end" gap="2">
                        {cancelButton && cancelButton}
                        <Button
                            text={submitButtonText}
                            type="submit"
                            disabled={
                                accessCode.length !== CODE_LENGTH || isLoading
                            }
                        />
                    </Flex>
                </Flex>
            </Card>
        </form>
    );
};
