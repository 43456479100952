import { forwardRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill, Tooltip } from "@accurx/inbox-design-library";

import { StyledBookingInviteButton } from "./BookingInviteButton.styles";

export const BookingInviteButton = forwardRef<
    HTMLButtonElement,
    {
        userflowId?: string;
        onClick?: () => void;
    }
>(({ onClick, userflowId }, ref) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const isIconOnly = isMessageComponentV1_1Enabled;

    return (
        <Tooltip.Root>
            <Tooltip.Trigger tabIndex={-1} asChild={true}>
                <StyledBookingInviteButton
                    radius="full"
                    onClick={onClick}
                    aria-label={"Add a Self-Book invite"}
                    data-userflow-id={userflowId}
                    $showIconOnly={isIconOnly}
                    ref={ref}
                >
                    <Pill.Icon
                        name="Calendar"
                        colour="blue"
                        theme="Line"
                        props={{
                            "aria-hidden": true,
                        }}
                    />
                    {!isMessageComponentV1_1Enabled && (
                        <UI.Text as="span" skinny variant="preview">
                            Booking invite
                        </UI.Text>
                    )}
                </StyledBookingInviteButton>
            </Tooltip.Trigger>
            {isIconOnly && (
                <Tooltip.Content>
                    <UI.Text colour="white" variant="note" skinny>
                        Booking invite
                    </UI.Text>
                </Tooltip.Content>
            )}
        </Tooltip.Root>
    );
});
