import { Ds } from "@accurx/design";
import styled from "styled-components";

export const StyledBadge = styled(Ds.Badge)`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledBadgeText = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
`;
