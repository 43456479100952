import { useEffect, useState } from "react";

import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useScrollIntoView } from "domains/inbox/hooks/util/useScrollIntoView";

import { StyledOutcomeRecording } from "./OutcomeRecording.styles";
import { Header } from "./components/Header";
import { OutcomeLabels } from "./components/OutcomeLabels";

type OutcomeRecordingProps = {
    isMinimisedByDefault?: boolean;
    conversation: Conversation;
};

export const OutcomeRecording = ({
    isMinimisedByDefault,
    conversation,
}: OutcomeRecordingProps) => {
    const [isMinimised, setIsMinimised] = useState(isMinimisedByDefault);
    const { ref, scrollIntoView } = useScrollIntoView();

    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView, isMinimised]);

    if (isMinimised) {
        return (
            <Header text="Would you like to record an outcome?" isMinimised>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => setIsMinimised(false)}
                >
                    <Pill.Text>Add outcome</Pill.Text>
                </Pill.SecondaryButton>
            </Header>
        );
    }

    return (
        <StyledOutcomeRecording.Wrapper aria-live="polite" ref={ref}>
            <Header text="Select an outcome">
                <UI.Button
                    theme="secondary"
                    dimension="small"
                    aria-label="Minimise"
                    icon={{ name: "Minimise", title: "Minimise" }}
                    onClick={() => setIsMinimised(true)}
                />
            </Header>
            <StyledOutcomeRecording.Body>
                <OutcomeLabels conversation={conversation} />
            </StyledOutcomeRecording.Body>
        </StyledOutcomeRecording.Wrapper>
    );
};

type OutcomeRecordingEditProps = {
    conversation: Conversation;
    onCancelEdit: () => void;
};

export const OutcomeRecordingEdit = ({
    onCancelEdit,
    conversation,
}: OutcomeRecordingEditProps) => {
    const { ref, scrollIntoView } = useScrollIntoView();

    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <StyledOutcomeRecording.Wrapper aria-live="polite" ref={ref}>
            <Header text="Change outcome">
                <UI.Button
                    theme="secondary"
                    dimension="small"
                    aria-label="Cancel edit outcome"
                    icon={{ name: "Cross", title: "Cancel" }}
                    onClick={onCancelEdit}
                />
            </Header>
            <StyledOutcomeRecording.Body>
                <OutcomeLabels conversation={conversation} />
            </StyledOutcomeRecording.Body>
        </StyledOutcomeRecording.Wrapper>
    );
};
