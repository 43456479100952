import { Conversation } from "domains/concierge/schemas/ConversationSchema";
import { itemHasAttachments } from "domains/concierge/util/itemHasAttachments";

export const conversationHasAttachments = (
    conversation: Conversation,
): boolean => {
    for (const item of conversation.items) {
        if (itemHasAttachments(item)) {
            return true;
        }
    }

    return false;
};
