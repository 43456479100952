import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { PowerBIReportResponse, getPowerBIReport } from "api/PowerBIApi";

export const GET_POWERBI_REPORT_CACHE_KEY = "GetPowerBIReport";

type GetPowerBiReportQueryVariables = {
    organisationId: string;
    reportName?: string;
};

export const useGetPowerBiReportQuery = createQueryHook<
    GetPowerBiReportQueryVariables,
    PowerBIReportResponse
>(({ organisationId, reportName }) => ({
    queryKey: [GET_POWERBI_REPORT_CACHE_KEY, { organisationId, reportName }],
    queryFn: async () => {
        const response = await getPowerBIReport({ organisationId, reportName });
        return returnDataOrThrow(response);
    },
    refetchInterval: 15 * 60 * 1000, // Refetch every 15 mins (refresh before 1 hour token expiry of powerbi)
    refetchOnMount: true,
}));
