import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledValidationCard = styled.div`
    border-radius: ${Tokens.BORDERS.radius};
    box-sizing: border-box;
    padding: ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.primary.orange[10]};
    border: ${Tokens.BORDERS.soft.orange};
    overflow-wrap: anywhere;
`;
