import React, {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    useEffect,
    useRef,
    useState,
} from "react";

import { Button, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { EmailAddressHelper } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
} from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { truncateLongWord } from "shared/formatters/String";
import { useAppSelector } from "store/hooks";

import { actionCreators as patientListActionCreators } from "./PatientListsActions";

interface EmailAddressErrors {
    required: string;
    invalid: string;
}

export const emailAddressErrors: EmailAddressErrors = {
    required: "Please specify an email address",
    invalid: "Please enter a valid email address",
};

const ListSharingModal = (): JSX.Element | null => {
    const blurTimeout = useRef<undefined | number>(undefined);
    const dispatch = useDispatch();
    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );
    const currentListSharingInitialising = useAppSelector(
        ({ patientLists }) => patientLists.currentListSharingInitialising,
    );
    const currentListSharing = useAppSelector(
        ({ patientLists }) => patientLists.currentListSharing,
    );
    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    }, shallowEqual);
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    useAccurxWebTitle(
        currentListSharingInitialising
            ? "Share patient list with colleague"
            : "View patient list",
    );

    const [emailValue, setEmailValue] = useState("");
    const [emailError, setEmailError] = useState("");
    const [emailValid, setEmailValid] = useState(false);

    // Actions to take on unmount
    useEffect(() => {
        return (): void => {
            clearBlurTimeout();
        };
    }, []);

    const clearBlurTimeout = (): void => {
        if (blurTimeout.current) {
            clearTimeout(blurTimeout.current);
        }
    };

    const resetEverything = (): void => {
        clearBlurTimeout();
        setEmailValue("");
        setEmailValid(false);
        setEmailError("");
    };

    const handleToggle = (): void => {
        if (currentListSharingInitialising) {
            dispatch(patientListActionCreators.cancelPatientListSharing());
        } else {
            dispatch(patientListActionCreators.initPatientListSharing());
        }
    };

    const handleSubmit = (e: FormEvent): void => {
        e.preventDefault();

        analyticsPatientListProps &&
            FlemingAnalyticsTracker.trackPatientListShareButtonClick({
                ...analyticsPatientListProps,
                ...meaningfulActionProps,
                shareType: "UserShare",
            });

        const isValid = validateEmail(emailValue, true, true);

        if (!isValid || !organisationId || !currentList?.patientListId) return;

        dispatch(
            patientListActionCreators.sharePatientList(
                {
                    organisationId,
                    patientListId: currentList?.patientListId,
                    emailAddress: emailValue,
                },
                afterSubmitSuccess,
            ),
        );
    };

    const afterSubmitSuccess = (): void => {
        dispatch(patientListActionCreators.cancelPatientListSharing());
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setEmailValue(value);
        // Validate on change to decide disabled state of submit button
        validateEmail(value, true, false);
    };

    const handlePaste = (e: ClipboardEvent): void => {
        e.preventDefault();
        const text = e.clipboardData.getData("Text") || "";
        const textWithoutWhitespaces = text.replace(/\s/g, "");

        setEmailValue(textWithoutWhitespaces);
        validateEmail(textWithoutWhitespaces, true, false);
    };

    const handleBlur = (): void => {
        if (!emailValue) return;

        clearBlurTimeout();
        blurTimeout.current = window.setTimeout(() => {
            validateEmail(emailValue, false, true);
        }, 100);
    };

    const validateEmail = (
        value: string,
        validateEmpty: boolean,
        showErrors: boolean,
    ): boolean => {
        // Reset the errors first
        setEmailError("");
        if (validateEmpty && !value) {
            setEmailValid(false);
            showErrors && setEmailError(emailAddressErrors.required);
            return false;
        }
        if (value && !EmailAddressHelper.isValidEmailAddress(value)) {
            setEmailValid(false);
            showErrors && setEmailError(emailAddressErrors.invalid);
            return false;
        }
        setEmailValid(true);
        return true;
    };

    if (!currentList) return null;
    return (
        <Modal
            isOpen={currentListSharingInitialising}
            toggle={handleToggle}
            onClosed={resetEverything}
            backdrop="static"
            style={{ marginBottom: "6rem" }}
        >
            <ModalHeader tag="div">
                <Text
                    variant="subtitle"
                    as="h3"
                    colour="zinc"
                    props={{ className: "m-0" }}
                >
                    Share {truncateLongWord(currentList?.name, 24)}
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Text variant="body">
                    Invite Accurx users from{" "}
                    <Text variant="label" as="span">
                        {orgName}
                    </Text>{" "}
                    to have access to this list.
                </Text>
                <div className="d-flex justify-content-between flex-wrap">
                    <Text variant="label">Share with colleague</Text>
                    <Text
                        variant="link"
                        colour="zinc"
                        props={{ id: "ShareInfo", className: "mb-0" }}
                    >
                        <Icon name="Info" size={3} />
                        What can they do?
                    </Text>
                    <UncontrolledTooltip target="ShareInfo">
                        Colleagues you share with can edit the list, contact
                        patients from the list and invite others to do the same.
                    </UncontrolledTooltip>
                </div>
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    className="mt-2"
                    data-testid="patientListShareForm"
                >
                    <div className="form-group">
                        <Text
                            variant="label"
                            as="label"
                            props={{
                                className: "col-form-label mt-0 d-inline-flex",
                                htmlFor: "emailAddress",
                                id: "EmailAddressInfo",
                            }}
                        >
                            Colleague's email address
                            <Icon name="Info" size={3} />
                        </Text>
                        <input
                            autoComplete="off"
                            autoFocus
                            required
                            id="emailAddress"
                            className="form-control"
                            type="text"
                            inputMode="email"
                            value={emailValue}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onPaste={handlePaste}
                            placeholder="Email"
                            readOnly={currentListSharing}
                        />
                        {!!emailError && (
                            <div className="d-flex align-items-center mt-2">
                                <div style={{ lineHeight: "normal" }}>
                                    <Icon
                                        colour="red"
                                        name="Error"
                                        size={3}
                                        theme="Fill"
                                    />
                                </div>
                                <div>
                                    <Text props={{ className: "m-0" }}>
                                        {emailError}
                                    </Text>
                                </div>
                            </div>
                        )}
                        <UncontrolledTooltip target="EmailAddressInfo">
                            This must be the email address they use to log-in to
                            their Accurx account.
                        </UncontrolledTooltip>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleToggle}
                    text="Cancel"
                    theme="secondary"
                />
                <Button
                    onClick={handleSubmit}
                    text="Share"
                    disabled={!emailValid || currentListSharing}
                />
            </ModalFooter>
        </Modal>
    );
};

export default ListSharingModal;
