import * as UI from "@accurx/design";
import { Checkbox } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/compose/userflow";

import { PATIENT_RESPONSE_CHECKBOX_ID } from "./PatientResponseCheckbox.constants";

type PatientResponseCheckboxProps = {
    onChange: () => void;
    checked: boolean;
    assignee: string;
};

export const PatientResponseCheckbox = ({
    onChange,
    checked,
    assignee,
}: PatientResponseCheckboxProps) => {
    /**
     * Keep in sync with the visual contents of the checkbox
     * this is just an accessible readable label
     */
    const checkboxAriaLabel = `Allow response to: ${assignee}`;
    return (
        <Checkbox
            id={PATIENT_RESPONSE_CHECKBOX_ID}
            checked={checked}
            onCheckChange={onChange}
            text={checkboxAriaLabel}
            data-userflow-id={userflowIds.patientResponseCheckbox}
        >
            <UI.Text skinny as="span" variant="preview">
                Allow response to: <strong>{assignee}</strong>
            </UI.Text>
        </Checkbox>
    );
};
