export enum FilterTimeTeamUsage {
    ALL_TIME = "ALL_TIME",
    LAST_7_DAYS = "LAST_7_DAYS",
}

export type TeamUsage = {
    fullName: string;
    singleMessageSent: number;
    multiMessageSent: number;
    floreysSent: number;
    videoInvitesSent: number;
    totalSent: number;
};

export type TeamUsageKeys = keyof TeamUsage;

export type TeamUsageDto = {
    firstInstall: string;
    numberOfUsers: number;
    messagesSinceFirstInstall: number;
    userUsageLast7Days: TeamUsage[];
    userUsageSinceInstall: TeamUsage[];
};
