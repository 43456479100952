import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export type ContentTypesWithSaveToRecordSupport =
    | "PatientSms"
    | "PatientEmail"
    | "NhsAppMessage"
    | "FloreyResponseNote"
    | "PatientSingleResponse"
    | "PatientTriageRequestNote"
    | "PatientAppointmentRequestNote"
    | "LabelTag";

export type MutateSaveConversationItemToRecordPayload = {
    ticketIdentity: {
        type: string;
        id: string;
    };
    itemId: string;
    patientId: string;
    contentType: ContentTypesWithSaveToRecordSupport;
    createAttachments: boolean;
    snomedCodes?: string[];
};

export const mutateSaveConversationItemToRecord = createEndpoint<
    void,
    MutateSaveConversationItemToRecordPayload
>("MutateSaveConversationItemToRecord", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateSaveConversationItemToRecord",
            workspaceId: context.workspaceId,
            payload,
        },
    });
});
