import { Attachment } from "domains/concierge/types/messagePatient.types";

export const mapAttachmentsToAttachDocumentIds = (
    attachments: Attachment[],
): string[] | [] => {
    if (attachments && attachments.length === 0) {
        return [];
    }

    return attachments
        .filter((attachment) => attachment.origin === "Upload")
        .map((attachment) => attachment.id);
};
