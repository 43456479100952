import { FeatureName } from "@accurx/auth";
import { Card, Feedback, Icon, Text } from "@accurx/design";

import { PracticeDetails } from "app/practices/Practices.types";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

import { PdsMatchWarningLevel } from "../VaccineCopy";
import { VaccineCapacityTypeStats } from "../models/VaccineCapacityDashboardDTO";
import { VaccineCourse } from "../models/VaccineDeliveryDTO";
import { NimsVaccineProductType } from "../models/VaccineSharedDTO";
import {
    VaccineCapacity,
    badDataWarningLevel,
} from "./VaccineDeliveryUploadAndComposeHelpers";

export interface VaccineDeliveryCapacityPanelProps {
    vaccineCourse: VaccineCourse;
    capacityStatsStatus: UpdatingStatus;
    capacityStatsError: string;
    warningLevel: PdsMatchWarningLevel;
    pfizerCapacities?: VaccineCapacityTypeStats;
    pfizerPaediatricCapacities?: VaccineCapacityTypeStats;
    azCapacities?: VaccineCapacityTypeStats;
    modernaCapacities?: VaccineCapacityTypeStats;
    janssenCapacities?: VaccineCapacityTypeStats; // N.B. only show this for both as Janssen clinics can only be both
    sanofiCapacities?: VaccineCapacityTypeStats;
}

const renderCapacityStats = (
    vaccineCourse: VaccineCourse,
    seeAllFutureClinics: boolean,
    canHaveSanofiClinics: boolean,
    pfizerCapacities?: VaccineCapacityTypeStats,
    pfizerPaediatricCapacities?: VaccineCapacityTypeStats,
    azCapacities?: VaccineCapacityTypeStats,
    modernaCapacities?: VaccineCapacityTypeStats,
    janssenCapacities?: VaccineCapacityTypeStats,
    sanofiCapacities?: VaccineCapacityTypeStats,
): JSX.Element => {
    const firstCapacities = [
        {
            slots: pfizerCapacities?.availableFirst ?? 0,
            nimsProductType: NimsVaccineProductType.Pfizer,
            bgColour: "bg-blue-25",
        },
        {
            slots: pfizerPaediatricCapacities?.availableFirst ?? 0,
            nimsProductType: "Paeds5-11Pfizer",
            bgColour: "bg-purple-25",
        },
        {
            slots: modernaCapacities?.availableFirst ?? 0,
            nimsProductType: NimsVaccineProductType.Moderna,
            bgColour: "bg-green-25",
        },
        {
            slots: azCapacities?.availableFirst ?? 0,
            nimsProductType: NimsVaccineProductType.Az,
            bgColour: "bg-yellow-25",
        },
    ];

    const secondCapacities = [
        {
            slots: pfizerCapacities?.availableSecond ?? 0,
            nimsProductType: NimsVaccineProductType.Pfizer,
            bgColour: "bg-blue-25",
        },
        {
            slots: modernaCapacities?.availableSecond ?? 0,
            nimsProductType: NimsVaccineProductType.Moderna,
            bgColour: "bg-green-25",
        },
        {
            slots: azCapacities?.availableSecond ?? 0,
            nimsProductType: NimsVaccineProductType.Az,
            bgColour: "bg-yellow-25",
        },
    ];

    const bothCapacities = [
        {
            slots: pfizerCapacities?.availableBoth ?? 0,
            nimsProductType: NimsVaccineProductType.Pfizer,
            bgColour: "bg-blue-25",
        },
        {
            slots: modernaCapacities?.availableBoth ?? 0,
            nimsProductType: NimsVaccineProductType.Moderna,
            bgColour: "bg-green-25",
        },
        {
            slots: azCapacities?.availableBoth ?? 0,
            nimsProductType: NimsVaccineProductType.Az,
            bgColour: "bg-yellow-25",
        },
        {
            slots: janssenCapacities?.availableBoth ?? 0,
            nimsProductType: NimsVaccineProductType.Janssen,
            bgColour: "bg-red-25",
        },
    ];

    const boosterCapacities = [
        {
            slots: pfizerCapacities?.availableBooster ?? 0,
            nimsProductType: NimsVaccineProductType.Pfizer,
            bgColour: "bg-blue-25",
        },
        {
            slots: modernaCapacities?.availableBooster ?? 0,
            nimsProductType: NimsVaccineProductType.Moderna,
            bgColour: "bg-green-25",
        },
        {
            slots: azCapacities?.availableBooster ?? 0,
            nimsProductType: NimsVaccineProductType.Az,
            bgColour: "bg-yellow-25",
        },
    ];

    if (canHaveSanofiClinics) {
        boosterCapacities.push({
            slots: sanofiCapacities?.availableBooster ?? 0,
            nimsProductType: NimsVaccineProductType.Sanofi,
            bgColour: "bg-red-25",
        });
    }

    const allCapacities = [
        // "all" is first, second and booster
        {
            slots: pfizerCapacities?.availableAll ?? 0,
            nimsProductType: NimsVaccineProductType.Pfizer,
            bgColour: "bg-blue-25",
        },
        {
            slots: modernaCapacities?.availableAll ?? 0,
            nimsProductType: NimsVaccineProductType.Moderna,
            bgColour: "bg-green-25",
        },
        {
            slots: azCapacities?.availableAll ?? 0,
            nimsProductType: NimsVaccineProductType.Az,
            bgColour: "bg-yellow-25",
        },
    ];

    return (
        <div className="p-3 bg-silver mb-5" style={{ borderRadius: "0.25rem" }}>
            {seeAllFutureClinics ? (
                <Text variant="label" props={{ className: "mb-1" }}>
                    Future patient facing capacity
                </Text>
            ) : (
                <Text variant="label" props={{ className: "mb-1" }}>
                    Next 3 week patient facing capacity
                </Text>
            )}
            <Text variant="body" props={{ className: "mb-4" }}>
                This shows slots available for your patients to book in. Hidden
                clinics do not appear here.
            </Text>
            {vaccineCourse !== VaccineCourse.Primary ? (
                <>
                    {renderCapacities(allCapacities, "Any clinics", "all")}
                    {renderCapacities(
                        boosterCapacities,
                        "Booster clinics",
                        "booster",
                    )}
                </>
            ) : (
                <>
                    {renderCapacities(allCapacities, "Any clinics", "all")}
                    {renderCapacities(
                        bothCapacities,
                        "1st+2nd clinics",
                        "1stAnd2nd",
                    )}
                    {renderCapacities(
                        firstCapacities,
                        "1st clinics only",
                        "1st",
                    )}
                    {renderCapacities(
                        secondCapacities,
                        "2nd clinics only",
                        "2nd",
                    )}
                </>
            )}
        </div>
    );
};

const renderCapacities = (
    capacities: VaccineCapacity[],
    title: string,
    dosePrefix: string,
): JSX.Element => {
    return (
        <div className="mb-4">
            <Text variant="label" props={{ className: "mb-1" }}>
                {title}
            </Text>
            {capacities.map((capacity) => {
                return (
                    <Card
                        key={`${title}-${capacity.nimsProductType}`}
                        spacing={0}
                        props={{ className: "mb-1 p-1" }}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <Icon
                                    name="Medication"
                                    size={3}
                                    props={{
                                        className: `mr-1 rounded-circle ${capacity.bgColour}`,
                                    }}
                                />
                                <Text skinny>{capacity.nimsProductType}</Text>
                            </div>
                            <Text
                                skinny
                                props={{
                                    "data-testid": `${dosePrefix}-${capacity.nimsProductType}`,
                                }}
                            >{`${capacity.slots} slots`}</Text>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export const VaccineDeliveryCapacityPanel = ({
    vaccineCourse,
    capacityStatsStatus,
    capacityStatsError,
    warningLevel,
    pfizerCapacities,
    pfizerPaediatricCapacities,
    azCapacities,
    modernaCapacities,
    janssenCapacities,
    sanofiCapacities,
}: VaccineDeliveryCapacityPanelProps) => {
    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );

    const seeAllFutureClinics = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeSeeAllFutureClinics,
    );

    const canHaveSanofiClinics = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeSanofi,
    );

    return (
        <div className="d-flex flex-column" style={{ width: "22%" }}>
            {capacityStatsStatus === UpdatingStatus.Loaded &&
                azCapacities &&
                pfizerCapacities &&
                modernaCapacities &&
                renderCapacityStats(
                    vaccineCourse,
                    seeAllFutureClinics,
                    canHaveSanofiClinics,
                    pfizerCapacities,
                    pfizerPaediatricCapacities,
                    azCapacities,
                    modernaCapacities,
                    janssenCapacities,
                    sanofiCapacities,
                )}
            {capacityStatsStatus === UpdatingStatus.Failed && (
                <Feedback
                    colour={badDataWarningLevel(warningLevel)}
                    title={"Cannot load capacity details"}
                    content={capacityStatsError}
                />
            )}
        </div>
    );
};
