import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSwitchGroup = styled.div`
    margin-left: ${Tokens.SIZES[2]};
    display: inline-flex;
    vertical-align: middle;
`;

const StyledSwitchLabel = styled.label<{ active: boolean }>`
    background-color: ${({ active }) =>
        active
            ? Tokens.COLOURS.primary.green[100]
            : Tokens.COLOURS.greyscale.white};
    ${({ active }) =>
        active ? `color: ${Tokens.COLOURS.greyscale.white};` : ""}
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    border-radius: ${Tokens.BORDERS.radius};
    margin-bottom: 0px;

    & > input {
        display: none;
    }
`;

export const StyledSwitchLabelLeft = styled(StyledSwitchLabel)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

export const StyledSwitchLabelRight = styled(StyledSwitchLabel)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
`;
