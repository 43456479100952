import { FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledContent = styled.div`
    padding-top: ${Tokens.SIZES[1.5]};
`;

const StyledDocumentCardWrapper = styled.div`
    margin: ${Tokens.SIZES[3]} 0;
`;

const StyledButtonsWrapper = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1.5]};
    justify-content: flex-end;
    margin-top: ${Tokens.SIZES[3]};
`;

const StyledLabel = styled.div`
    margin-bottom: ${Tokens.SIZES[1]};
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    margin-bottom: ${Tokens.SIZES[3]};
`;

const StyledCollapsibleInfo = styled.div`
    padding: ${Tokens.SIZES[1]} 0;
`;

export {
    StyledContent,
    StyledDocumentCardWrapper,
    StyledButtonsWrapper,
    StyledLabel,
    StyledFormFieldWrapper,
    StyledCollapsibleInfo,
};
