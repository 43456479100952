import { FeatureName } from "@accurx/auth";
import { Button, Feedback, Flex, Spinner, Text } from "@accurx/design";
import differenceInDays from "date-fns/differenceInDays";
import { useHistory, useParams } from "react-router";

import {
    trackClinicBatchSendStartButtonClick,
    useTrackLoggedInPageView,
} from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";
import { useIntegratedClinic } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsFeatureEnabled } from "store/hooks";

import {
    getClinicListsPath,
    getClinicSelectBatchPatientsPath,
} from "../../routes";
import AppointmentCard from "../../shared/components/AppointmentCard";
import { Container } from "../../shared/components/Container.styles";
import { OrderedList } from "../../shared/components/OrderedList.styles";
import { TopNavigation } from "../../shared/components/TopNavigation";
import { isContactable } from "../../shared/isContactable";
import ContactDropdown from "./components/ContactDropdown";
import IntegratedClinicHeader from "./components/Header";
import HeaderSkeleton from "./components/HeaderSkeleton";

type IntegratedClinicURLParams = {
    clinicCode: string;
    clinicDay: string;
    location: string;
    workspaceId: string;
};

export default function IntegratedClinic() {
    const { clinicCode, clinicDay, workspaceId, location } =
        useParams<IntegratedClinicURLParams>();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    useTrackLoggedInPageView("Clinic");
    const history = useHistory();
    const { data, status } = useIntegratedClinic({
        clinicCode,
        clinicDay,
        location,
        workspaceId,
    });
    const webBatchEnabled = useIsFeatureEnabled(FeatureName.WebBatchMessaging);

    const previousPageUrl = getClinicListsPath({ workspaceId });

    if (status === "loading") {
        return (
            <>
                <TopNavigation previousPageUrl={previousPageUrl} />
                <HeaderSkeleton />
                <Container>
                    <Spinner />
                </Container>
            </>
        );
    }

    if (status === "error") {
        return (
            <>
                <TopNavigation previousPageUrl={previousPageUrl} />
                <IntegratedClinicHeader
                    name={data?.name}
                    clinicCode={clinicCode}
                    clinicDate={data?.clinicDate}
                    location={location}
                    responsibleClinicianName={data?.responsibleClinicians?.[0]}
                    lastUpdated={data?.lastUpdated}
                />
                <Container>
                    <Feedback
                        colour="error"
                        iconName="Failed"
                        title="There was an issue retrieving clinic information"
                    >
                        <Text skinny>No information found</Text>
                    </Feedback>
                </Container>
            </>
        );
    }

    const clinicInfoOutOfdate = data.lastUpdated
        ? differenceInDays(new Date(), new Date(data.lastUpdated)) >= 1
        : true;

    return (
        <>
            <TopNavigation previousPageUrl={previousPageUrl} />
            <IntegratedClinicHeader
                name={data.name}
                clinicCode={data.clinicCode}
                clinicDate={data.clinicDate}
                location={location}
                responsibleClinicianName={data.responsibleClinicians[0]}
                lastUpdated={data.lastUpdated}
            />
            <Container>
                {clinicInfoOutOfdate && (
                    <Feedback
                        colour="error"
                        iconName="Failed"
                        title="There was an issue retrieving clinic information"
                    >
                        <Text skinny>
                            The clinic information has not been updated in over
                            24 hours
                        </Text>
                    </Feedback>
                )}
                {data.appointments?.length ? (
                    <>
                        {webBatchEnabled && (
                            <Flex justifyContent="flex-end">
                                <Button
                                    theme="primary"
                                    icon={{
                                        name: "Send",
                                        placement: "end",
                                        colour: "blue",
                                    }}
                                    text="Send batch message"
                                    onClick={() => {
                                        trackClinicBatchSendStartButtonClick(
                                            flemingLoggedInProperties,
                                        );
                                        history.push(
                                            getClinicSelectBatchPatientsPath({
                                                workspaceId,
                                                clinicCode,
                                                clinicDay,
                                                location,
                                            }),
                                        );
                                    }}
                                />
                            </Flex>
                        )}
                        <OrderedList>
                            {data.appointments.map((appointment) => (
                                <li key={appointment.id}>
                                    <AppointmentCard
                                        actionButton={
                                            isContactable(appointment) && (
                                                <ContactDropdown
                                                    nhsNumber={
                                                        appointment.nhsNumber
                                                    }
                                                    dateOfBirth={
                                                        appointment.dateOfBirth
                                                    }
                                                />
                                            )
                                        }
                                        appointment={appointment}
                                    />
                                </li>
                            ))}
                        </OrderedList>
                    </>
                ) : (
                    <Feedback
                        colour="information"
                        title="There are no appointments for this clinic"
                    />
                )}
            </Container>
        </>
    );
}
