import { Tokens } from "@accurx/design";
import styled from "styled-components";

const { SIZES, COLOURS } = Tokens;

export const StyledTableCellButton = styled.button`
    display: flex;
    align-items: center;
    gap: ${SIZES[1]};
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: inherit;

    & {
        color: ${COLOURS.greyscale.stone};
    }

    &:hover,
    &:focus {
        color: ${COLOURS.greyscale.metal};
    }
`;
