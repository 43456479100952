import { ContactDetail } from "@accurx/compose";

const isFromPatientSearch = (contactDetailOrigin: ContactDetail["origin"]) => {
    return contactDetailOrigin === "PDS" || contactDetailOrigin === "EMR";
};

/** Given contact details from both a patient demographics search and
 * a conversation, returns the contact detail to be used as a default.  */
export const getDefaultContactDetail = (
    contactDetails: ContactDetail[],
): ContactDetail | null => {
    // Top priority is to return most recent contact detail previously used in conversation
    const fromConversation = contactDetails.find(
        (deet) => deet.origin === "Conversation",
    );
    if (fromConversation) {
        return fromConversation;
    }

    // If there are none, return from the patient search. Prefer Mobile over Email
    const mobile = contactDetails.find(
        ({ origin, method }) =>
            isFromPatientSearch(origin) && method === "Mobile",
    );
    if (mobile) {
        return mobile;
    }

    const email = contactDetails.find(
        ({ origin, method }) =>
            isFromPatientSearch(origin) && method === "Email",
    );

    if (email) {
        return email;
    }

    // Return details submitted in a triage request as last option
    const submittedByPatient = contactDetails.find(
        ({ origin }) => origin === "SubmittedByPatient",
    );

    return submittedByPatient ?? null;
};
