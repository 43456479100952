import { useCallback, useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useNativeTransport } from "@accurx/native";

import { useSelfBookSettings } from "../hooks/useSelfBookSettings";
import { useChangeSelfBookConfigurationMutation } from "../native/hooks/useChangeSelfBookConfigurationMutation";
import { useSelfBookConfigurationComplete } from "../native/hooks/useSelfbookConfigurationComplete";
import { useSupportLinkClickMutation } from "../native/hooks/useSupportLinkClickMutation";
import { useNativeResetSelfBookConfiguration } from "../native/subscriptions/useNativeResetSelfBookConfiguration";
import { SelfbookConfigurationPayload } from "../utils/types";
import { SelfbookConfigurationForm } from "./SelfbookConfigurationForm";
import { StyledSelfbookModal } from "./SelfbookConfigurationForm.styles";

export const SelfbookConfigurationModal = () => {
    const [state, setState] = useState(0);
    const native = useNativeTransport();
    const currentWorkspace = useCurrentWorkspace();
    const { showCrossOrg } = useSelfBookSettings();
    const { mutate: supportLinkClickMutation } = useSupportLinkClickMutation();
    const { mutate: selfbookConfigurationComplete } =
        useSelfBookConfigurationComplete();
    const { mutate: changeSelfBookConfigurationMutation } =
        useChangeSelfBookConfigurationMutation();

    // Fully re-mount form when reset message received
    useNativeResetSelfBookConfiguration(() => {
        const newSafeKeyValue = (state + 1) % 10;
        setState(newSafeKeyValue);
    });

    const onSupportLinkClick = useCallback(
        (supportLinkUri: string) =>
            supportLinkClickMutation({
                uri: supportLinkUri,
            }),
        [supportLinkClickMutation],
    );
    const onSelfbookConfigComplete = useCallback(
        (selfbookConfigData: SelfbookConfigurationPayload) => {
            selfbookConfigurationComplete({
                validationSuccess: selfbookConfigData.validationSuccess,
            });
        },
        [selfbookConfigurationComplete],
    );
    const onSelfbookConfigChange = useCallback(
        (selfbookConfigData: SelfbookConfigurationPayload) =>
            changeSelfBookConfigurationMutation(selfbookConfigData),
        [changeSelfBookConfigurationMutation],
    );

    return (
        <StyledSelfbookModal>
            {native && (
                <SelfbookConfigurationForm
                    key={state}
                    orgId={currentWorkspace.orgId}
                    organisationName={currentWorkspace.organisationName}
                    showCrossOrg={showCrossOrg}
                    appOrigin="Compose"
                    marginBottom="-5px" // added negative margin because a 5px bottom margin is added in wpf to avoid issue where webview corners are not rounded
                    onSupportLinkClick={onSupportLinkClick}
                    onSelfbookConfigComplete={onSelfbookConfigComplete}
                    onSelfbookConfigChange={onSelfbookConfigChange}
                />
            )}
        </StyledSelfbookModal>
    );
};
