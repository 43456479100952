import {
    Button,
    Flex,
    Table,
    Text,
    VisuallyHidden,
    useTable,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import {
    mapDisplaySortDirectionToAnalyticsSortDirection,
    mapDisplaySortFieldToAnalyticsSortField,
    trackQuestionnaireResponsesColumnSortClick,
} from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { FloreyScoreSummary } from "app/questionnaireScoreboard/schemas";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { CalculatedComplexityBadge } from "./CalculatedComplexityBadge";
import { ContactDropdown } from "./ContactDropdown";
import { PatientInfo } from "./PatientInfo";
import { QuestionnaireStatusBadge } from "./QuestionnaireStatusBadge";
import { StyledTable } from "./ResponsesTable.styles";
import { SortableTableHeading } from "./SortableTableHeading";
import { COLUMN_SORT_DIRECTIONS } from "./constants";
import { useQuestionnairesSorting } from "./useQuestionnairesSorting";

export const ResponsesTable = ({
    data,
    onViewResponseClick,
}: {
    data: FloreyScoreSummary[];
    onViewResponseClick: (floreyEnrolmentId: number) => void;
}) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    const sorting = useQuestionnairesSorting();

    const table = useTable({
        data,
        columnDefs: [
            {
                id: "sentAt",
                header: "Date sent",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.sentAt[
                            COLUMN_SORT_DIRECTIONS.sentAt.initial
                        ],
                    selector: "sentAt",
                },
            },
            {
                id: "patient",
                header: "Patient",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.patient[
                            COLUMN_SORT_DIRECTIONS.patient.initial
                        ],
                    selector: (row) =>
                        (
                            (row.patient.familyName || "") +
                            (row.patient.firstName || "")
                        ).toLowerCase(),
                },
            },
            {
                id: "status",
                header: "Status",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.status[
                            COLUMN_SORT_DIRECTIONS.status.initial
                        ],
                    selector: "status",
                },
            },
            {
                id: "complexity",
                header: "Complexity",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.complexity[
                            COLUMN_SORT_DIRECTIONS.complexity.initial
                        ],
                    selector: "complexity",
                },
            },
            {
                id: "answeredAt",
                header: "Date answered",
                sort: {
                    direction:
                        COLUMN_SORT_DIRECTIONS.answeredAt[
                            COLUMN_SORT_DIRECTIONS.answeredAt.initial
                        ],
                    selector: "answeredAt",
                },
            },
            {
                id: "conversationId",
                header: "Questionnaire",
            },
            {
                id: "contact",
                header: "Contact",
            },
        ],
        defaultOptions: {
            sortColumnId: sorting.sortColumn,
            sortDirection: sorting.sortDirection,
        },
    });

    return (
        <Table>
            <VisuallyHidden as="caption">
                Column headers with buttons are sortable
            </VisuallyHidden>
            <Table.Head>
                <Table.Row>
                    {table.columns.map((column) =>
                        column.metadata.isSortable ? (
                            <SortableTableHeading
                                key={column.id}
                                currentSortDetails={{
                                    sortColumnId: sorting.sortColumn,
                                    sortDirection: sorting.sortDirection,
                                }}
                                onSortApplied={(newSorting) => {
                                    trackQuestionnaireResponsesColumnSortClick({
                                        ...loggedInProps,
                                        conditionId:
                                            PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                                        sortField:
                                            mapDisplaySortFieldToAnalyticsSortField(
                                                sorting.sortColumn,
                                            ),
                                        sortDirection:
                                            mapDisplaySortDirectionToAnalyticsSortDirection(
                                                sorting.sortDirection,
                                            ),
                                    });

                                    sorting.updateSorting(newSorting);
                                }}
                                tableColumn={column}
                            />
                        ) : (
                            <StyledTable.Header key={column.id} scope="col">
                                {column.header}
                            </StyledTable.Header>
                        ),
                    )}
                </Table.Row>
            </Table.Head>
            <Table.Body>
                {table.rows.map((row) => (
                    <Table.Row key={row.floreyEnrolmentId}>
                        <StyledTable.NoWrapCell>
                            {DateHelpers.formatDate(
                                row.sentAt,
                                DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                            )}
                        </StyledTable.NoWrapCell>
                        <StyledTable.PatientCell>
                            <PatientInfo patient={row.patient} />
                        </StyledTable.PatientCell>
                        <StyledTable.Cell>
                            <QuestionnaireStatusBadge status={row.status} />
                        </StyledTable.Cell>
                        <StyledTable.NoWrapCell>
                            <CalculatedComplexityBadge
                                complexity={row.complexity}
                            />
                        </StyledTable.NoWrapCell>
                        <StyledTable.NoWrapCell>
                            {row.answeredAt
                                ? DateHelpers.formatDate(
                                      row.answeredAt,
                                      DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                                  )
                                : "N/A"}
                        </StyledTable.NoWrapCell>
                        <StyledTable.NoWrapCell>
                            {row.isQuestionnaireSubmitted ? (
                                <Button
                                    text="View"
                                    icon={{
                                        name: "Questionnaire",
                                        colour: "blue",
                                    }}
                                    theme="secondary"
                                    onClick={() =>
                                        onViewResponseClick(
                                            row.floreyEnrolmentId,
                                        )
                                    }
                                />
                            ) : (
                                <Text skinny>No response</Text>
                            )}
                        </StyledTable.NoWrapCell>
                        <StyledTable.Cell>
                            <Flex justifyContent="center">
                                <ContactDropdown
                                    id={`reply-${row.floreyEnrolmentId}`}
                                    patient={row.patient}
                                    conversationId={row.conversationId}
                                />
                            </Flex>
                        </StyledTable.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
