import {
    AnalyticsProperties,
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
    IsTestPatientProps,
} from "./FlemingAnalyticsConstants";
import { TrackFlemingEvent } from "./FlemingEventTracker";

export type PatientSearchMethodType = "SearchByName" | "SearchByNHSNumber";

type PatientSearchMethodTypeProps = {
    patientSearchMethodType: PatientSearchMethodType;
};

/**
 * Prepends `Search For Patient - ` in front of all of the exported analytics functions
 * */
const TrackSearchForPatientEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    TrackFlemingEvent(`Search For Patient - ${eventName}`, props);
};

export type SearchForPatientEmailLinkType = FlemingLoggedInCustomProperties;

export type SearchForPatientTabClickType = FlemingLoggedInCustomProperties &
    PatientSearchMethodTypeProps;

export type SearchForPatientFormSubmitType = FlemingLoggedInCustomProperties &
    IsTestPatientProps &
    PatientSearchMethodTypeProps & {
        hasError: boolean;
        patientSearchSuccess: boolean;
    };

/**
 * User clicked emailed link with a token
 *
 * Event type: Page load
 * */
export const trackSearchForPatientEmailLink = (
    props: SearchForPatientEmailLinkType,
): void => {
    TrackSearchForPatientEvent("Email Link Clicked", props);
};

/**
 * User clicked on test patient toggle in search for patient form
 *
 * Event type: Button click
 */
export const trackTestPatientToggle = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackFlemingEvent("Clicked Autofill test patient details", props);
};

/**
 * User clicked on one of the tabs to switch search form
 *
 * Event type: Tab click
 */
export const trackSearchForPatientFormTabClick = (
    props: SearchForPatientTabClickType,
): void => {
    GenericTrackEvent({
        object: "PatientSearchMethod",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User submitted one of the search for patient forms
 *
 * Event type: Button click
 */
export const trackSearchForPatientFormSubmit = (
    props: SearchForPatientFormSubmitType,
): void => {
    GenericTrackEvent({
        object: "PatientSearch",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/** RECENT SEARCHES */

/**
 * User clicked on button to log into 2FA in order
 * to view their recent search history
 *
 * Event type: Button click
 */
export const trackLogIntoTwoFactorForRecentSearches = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackFlemingEvent("Clicked On Log Into 2FA To View Recent Searches", props);
};

/**
 * User clicked on recent search
 *
 * Event type: Button click
 */
export const trackRecentSearchClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackFlemingEvent("Clicked On A Recent Search", props);
};
