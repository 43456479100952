import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";

type CancelScheduledMessageOptions = {
    workspaceId: number;
    messageId: string;
};

export const cancelScheduledMessage: ConciergeFetcher<
    CancelScheduledMessageOptions,
    void
> = async (options) => {
    await TicketApiClient.cancelScheduledMessage(options);

    return {
        updates: {
            conversations: [],
            users: [],
            teams: [],
            patients: [],
        },
        response: undefined,
    };
};
