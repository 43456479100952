import { Button, Card, Feedback, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { PatientListAppointment } from "api/FlemingDtos";

export interface ConfirmBulkAddPatientsModalComponentProps {
    /**
     * List of appointments that will be replaced with the upload
     * and that have scheduled Video Consults
     * which will be sent cancellation messages
     *
     * The presence of appointments in this prop
     * acts as a flag to show the user a warning message
     * */
    appointmentsWithScheduledVideoConsults: PatientListAppointment[];
    /**
     * The list of dates in ISO DateTime format
     * to display for the user except
     *
     * Should have a minimum length of 1
     * */
    affectedDates: string[];
    /**
     * The total number of dates that are contained in the list
     * EXCLUDING Anytime group
     * */
    totalDatesCount: number;
    handleConfirm: () => void;
    handleCancel: () => void;
}

export const ConfirmBulkAddPatientsModalComponent = ({
    appointmentsWithScheduledVideoConsults,
    affectedDates,
    totalDatesCount,
    handleConfirm,
    handleCancel,
}: ConfirmBulkAddPatientsModalComponentProps): JSX.Element | null => {
    const areMultipleDatesReplaced = affectedDates.length > 1;
    const showVideoWarning = appointmentsWithScheduledVideoConsults.length > 0;
    const showSomeDatesUnaffectedMessage =
        affectedDates.length < totalDatesCount;

    // Work out the title
    let title = `You're about to replace all patient info for ${DateHelpers.formatDate(
        affectedDates[0],
        DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
    )}`;
    if (areMultipleDatesReplaced) {
        const otherDates = affectedDates.length - 1;
        title += ` and ${otherDates} other date${otherDates > 1 ? "s" : ""}`;
    }

    // Work out the main body
    const mainBodyContent = `All patient details and appointment time info for the following date${
        areMultipleDatesReplaced ? "s" : ""
    } will be replaced:`;

    const onConfirmClick = (): void => {
        handleConfirm();
    };

    const onCancelClick = (): void => {
        handleCancel();
    };

    return (
        <Modal
            isOpen
            backdrop="static"
            centered
            data-testid="bulk-add-patients-modal"
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h3" colour="night" skinny>
                    {title}
                </Text>
            </ModalHeader>
            <ModalBody
                className="bg-light"
                data-testid="bulk-add-patients-modal-content"
            >
                <Text props={{ className: "mb-1" }} skinny>
                    {mainBodyContent}
                </Text>
                <Card
                    spacing={1.5}
                    isElevated={false}
                    props={{ className: "mb-1" }}
                >
                    {affectedDates.map((date) => (
                        <Text key={date} skinny>
                            {DateHelpers.formatDate(
                                date,
                                DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                            )}
                        </Text>
                    ))}
                    {showVideoWarning && (
                        <div className="mt-1">
                            <Feedback
                                props={{ className: "mb-2" }}
                                colour="warning"
                                title="This update will affect some active Video Consult invitations"
                            >
                                <Text skinny>
                                    Any removed or edited patients will be sent
                                    cancellation messages and will have to be
                                    re-invited by you.
                                </Text>
                            </Feedback>
                            <Feedback
                                colour="success"
                                title="Patients who’s info has not been changed will maintain their previous Video Consult invitations"
                            />
                        </div>
                    )}
                </Card>
                {showSomeDatesUnaffectedMessage && (
                    <Card spacing={1.5} isElevated={false}>
                        <Text skinny>
                            Other existing dates in this patient list will not
                            be affected.
                        </Text>
                    </Card>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    text="Stop and revert"
                    theme="secondary"
                    onClick={onCancelClick}
                />
                <Button text="Replace patient info" onClick={onConfirmClick} />
            </ModalFooter>
        </Modal>
    );
};
