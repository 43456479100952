import { IdType, PatientExternalIdDto } from "@accurx/api/ticket";
import { PatientExternalId } from "@accurx/concierge/schemas/PatientExternalIdSchema";

import { isInstance } from "./isInstance";

export const convertNumberExternalIdItemToEnumExternalIdItem = (
    dto: PatientExternalIdDto,
): PatientExternalId | undefined => {
    if (dto.type === IdType.NhsNumber) {
        return {
            type: "NhsNumber",
            value: dto.value,
        };
    }

    if (dto.type === IdType.Emis) {
        return {
            type: "Emis",
            value: dto.value,
        };
    }

    if (dto.type === IdType.SystmOne) {
        return {
            type: "SystmOne",
            value: dto.value,
        };
    }

    if (dto.type === IdType.Vision) {
        return {
            type: "Vision",
            value: dto.value,
        };
    }
};

export const convertNumberExternalIdsToEnumExternalIds = (
    patientExternalIds: PatientExternalIdDto[] = [],
): PatientExternalId[] => {
    const externalIds = patientExternalIds.map((item) =>
        convertNumberExternalIdItemToEnumExternalIdItem(item),
    );

    return externalIds.filter(isInstance<PatientExternalId>);
};

export const convertEnumExternalIdsToNumberExternalIds = (
    patientExternalIds: PatientExternalId[] = [],
): PatientExternalIdDto[] => {
    return patientExternalIds.map(({ type, value }) => ({
        value,
        type: IdType[type],
    }));
};
