import { PatientThreadUser } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { UserSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

/**
 * Maps from a Ticket User to a User Summary
 * @param dto the ticket user
 * @returns mapped version to User Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserToUserSummary = (
    dto: PatientThreadUser,
): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }

    if (!dto.name && !dto.email) {
        Log.warn("Encountered User with no display name or email address", {
            tags: {
                product: "Inbox",
                userId: dto.accuRxId,
            },
        });
    }

    return {
        id: dto.accuRxId,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        displayName: dto.name || dto.email || "Unknown user",
    };
};
