export const SECTIONS = {
    BATCH_AND_APPOINTMENT_REMINDERS: "Batch & Appointment Reminders",
    PATIENT_TRIAGE: "Patient Triage",
    ORGANISATION: "Organisation",
    MESSAGING: "Messaging",
    HCP_MESSAGING: "Healthcare Professional Messaging (Accumail)",
    ACCUBOOK: "AccuBook",
    APPOINTMENT_REMINDERS: "Appointment Reminders",
    FLOREY_PLUS: "Florey Plus",
    REPORTING: "Reporting",
};
