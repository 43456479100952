import { UserGroupType } from "@accurx/api/ticket";
import { TeamSummary } from "domains/concierge/types";

export const mapUserGroupTypeToTeamType = (
    type: UserGroupType | null | undefined,
): TeamSummary["type"] => {
    switch (type) {
        case UserGroupType.Florey:
            return "Florey";
        case UserGroupType.ToAssign:
            return "ToAssign";
        case UserGroupType.PracticeGroup:
            return "Hub";
        case UserGroupType.FailedDeliveryReceipts:
            return "FailedDeliveryReceipts";
        case UserGroupType.PatientSingleResponse:
            return "PatientSingleResponse";
        default:
            return "Default";
    }
};
