import { Children, KeyboardEvent, ReactNode, isValidElement } from "react";

import { Button, OptionalButtonIconProps } from "@accurx/design";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import DropdownMenuOption from "./DropdownMenuOption.component";
import { SelectDropdownProps } from "./SelectDropdown.component";

export interface ButtonDropdownProps extends SelectDropdownProps {
    children?: ReactNode;
    /** [Optional] Icon for the dropdown button */
    customIcon?: OptionalButtonIconProps;
    /** [Optional] Whether the dropdown menu should open to the right of the main dropdown button */
    opensRight?: boolean;
    // [Optional] Whether or not to force the width of the dropdown menu items, may add an ellipsis on long item texts */
    fixedWidth?: boolean;
    // [Optional] Classes to apply to the button */
    className?: string;
}

/**
 * Outputs a dropdown styled like a button, with a bottom shade
 * */
const ButtonDropdown = ({
    isOpen,
    onToggle,
    dropdownText,
    customIcon,
    opensRight = false,
    children,
    fixedWidth = true,
    className = "",
}: ButtonDropdownProps): JSX.Element | null => {
    const dropdownOptions = Children.map(children, (child) => {
        if (!isValidElement(child) || child.type !== DropdownMenuOption) {
            console.error(
                "You should only use DropdownMenuOption component as children of this component",
            );
            return null;
        }

        return child;
    })?.filter(Boolean);

    if (!dropdownOptions?.length) {
        console.error(
            "You must provide at least one valid `DropdownMenuOption`",
        );
        return null;
    }

    const handleDropdownKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") onToggle();
    };

    return (
        <Dropdown isOpen={isOpen} toggle={onToggle}>
            <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={isOpen}
            >
                <Button
                    theme="secondary"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    icon={customIcon as any}
                    text={dropdownText}
                    className={`w-100 align-items-center ${className}`}
                    onKeyUp={handleDropdownKeyUp}
                />
            </DropdownToggle>
            <DropdownMenu
                className={`p-1 text-truncate ${fixedWidth ? "w-100" : ""}`}
                right={opensRight}
            >
                {dropdownOptions}
            </DropdownMenu>
        </Dropdown>
    );
};

export default ButtonDropdown;
