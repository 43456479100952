import React from "react";

import { ButtonLink, Card, Link, StackPanel, Text } from "@accurx/design";

import { importToOrg, routeShare } from "../../floreyBuilder/constants/paths";
import { useSwitchingOrgPage } from "./SwitchingOrgPage.hooks";

export const SwitchingOrgPage = (): JSX.Element => {
    const { orgId, shareId, switchingOrgId, organisationName } =
        useSwitchingOrgPage();

    return (
        <Card
            header={
                <Text variant="title" as="h1" skinny>
                    Switching organisation
                </Text>
            }
            footer={
                <StackPanel
                    gutter={2}
                    orientation="horizontal"
                    verticalContentAlignment={"center"}
                    horizontalContentAlignment={"right"}
                >
                    <Link
                        text={"Back"}
                        href={routeShare(orgId ?? "", shareId ?? "")}
                    />
                    <ButtonLink
                        text={"Continue"}
                        href={`${importToOrg(
                            switchingOrgId ?? "",
                            shareId ?? "",
                        )}?returnOrg=${orgId}`}
                    />
                </StackPanel>
            }
        >
            <Text>
                To finish importing this questionnaire, we will log you into
                this organisation:{" "}
                <Text as="span" variant="label" skinny>
                    {organisationName}
                </Text>
                .
            </Text>
            <Text>
                If you want to change your organisation after importing this
                questionnaire, use the dropdown at the top right of the screen
                to select the organisation you want to log into.
            </Text>
        </Card>
    );
};

export default SwitchingOrgPage;
