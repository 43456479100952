import {
    DateFormatOptions,
    DateHelpers,
    IWrappedResult,
    MobileNumberHelper,
    NhsNumberHelpers,
} from "@accurx/shared";
import isNil from "lodash/isNil";

import FlemingApiClient from "api/FlemingApiClient";
import {
    IMessagePatientResponse,
    MessagePatientAllEndpointsRequest,
} from "api/FlemingDtos";
import { getPatientNhsNumber } from "app/workspaceConversations/utils/patient.utils";
import { syncedCharLength } from "shared/MessageHelper";
import { parseUniqueConversationId } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "shared/concierge/conversations/tickets/mappers/PatientMapper";
import {
    mapPatientEmailItem,
    mapPatientSmsItem,
} from "shared/concierge/conversations/tickets/mappers/conversationItemMappers";
import {
    PatientThreadContentType,
    PatientThreadEmail,
    PatientThreadSMS,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    ConversationItem,
    PatientEmailConversationItem,
    PatientMatchState,
    PatientSmsConversationItem,
    PatientTriageSuggestedPatient,
} from "shared/concierge/conversations/types/item.types";
import {
    PatientExternalIdentity,
    PatientSummary,
} from "shared/concierge/patients/types/patient.types";

import { ConversationSummary, PatientType } from "./Conversation.types";
import { DisplayFloreyMessageTemplate } from "./FloreyQuestionnaire/types";

export type SendMessageOptions = {
    questionnaire: DisplayFloreyMessageTemplate | null;
    confirmationEmail: { subjectLine: string; body: string };
    message: string;
    patientToken?: string;
    patientExternalIds: PatientExternalIdentity[];
    mobileNumber: string | null;
    emailAddress: string | null;
    workspaceId: number | null;
    useMobileNumberFromPDS: boolean;
    enablePatientResponse: boolean;
    attachedDocumentIds: string[];
    messageTemplate: MessagePatientAllEndpointsRequest["messageTemplate"];
};

export type MessageType = "Sms" | "Email";

type GenerateEmailConfirmation = {
    patientName?: string | null;
    sentByUserName?: string;
    nhsNumber?: string;
    mobileNumber?: string | null;
};

type EmailConfirmation = {
    body: string;
    subjectLine: string;
};

export const generateGreeting = (
    patient: Pick<
        PatientSummary,
        "ageYear" | "prefixName" | "familyName" | "firstName"
    > | null,
): string => {
    if (!patient) {
        return "";
    }
    if (patient.ageYear > 30) {
        return patient.prefixName != null
            ? `Dear ${patient.prefixName} ${patient.familyName},`
            : `Dear ${patient.firstName},`;
    }
    return `Hi ${patient.firstName},`;
};

export const generateMessage = ({
    greeting,
    body,
    signature,
}: {
    greeting?: string;
    body?: string;
    signature: string;
}) => {
    if (!greeting) {
        return `${body || ""}\n\n${signature}`;
    }

    return `${greeting}\n\n${body || ""}\n\n${signature}`;
};

export const sendMessage = async ({
    conversationId,
    messageOptions,
}: {
    conversationId: string | null;
    messageOptions: SendMessageOptions;
}): Promise<IWrappedResult<IMessagePatientResponse>> => {
    const {
        patientToken,
        patientExternalIds,
        mobileNumber,
        emailAddress,
        message,
        workspaceId,
        confirmationEmail,
        useMobileNumberFromPDS,
        enablePatientResponse,
        attachedDocumentIds,
        messageTemplate,
        questionnaire,
    } = messageOptions;
    const canSendMessageWithToken = !!messageOptions.patientToken;

    const nhsNumber = getPatientNhsNumber(patientExternalIds);

    if (!nhsNumber) {
        return {
            error: "Patient has no NHS number",
            success: false,
            result: null,
        };
    }

    const request: MessagePatientAllEndpointsRequest = {
        conditionId: questionnaire?.id,
        conditionName: questionnaire?.title,
        mobileNumber,
        emailAddress,
        isVideoConsult: false,
        patientListId: null,
        emailSubjectLine: confirmationEmail.subjectLine,
        attachedDocumentIds,
        messageTemplate,
        enablePatientResponse,
        videoConsultTime: null,
        patientListEntryId: null,
        useMobileNumberFromPDS,
        emailBody: confirmationEmail.body,
        messageBody: message,
        patientToken: patientToken ?? "",
        nhsNumber,
        organisationId: workspaceId,
        ticketIdentity: conversationId
            ? parseUniqueConversationId(conversationId)
            : undefined,
        patientExternalIdentity: {
            patientExternalIds:
                mapPatientSummaryExternalIdsToTicketPatientExternalIds(
                    patientExternalIds,
                ),
        },
    };

    let apiResponse;

    if (canSendMessageWithToken) {
        // A PDS search has happened and we have the patient token
        apiResponse = await FlemingApiClient.messagePatientWithToken(request);
    } else {
        // A PDS search has either not happened or has failed, and we don't have the patient token
        apiResponse = await FlemingApiClient.messagePatientWithExternalIdentity(
            request,
        );
    }

    return apiResponse;
};

/**
 * Used to map the response from the SendMessage API to conversation items for display
 **/
export const mapPatientThreadSmsOrEmailToConversationItems = (
    items: (PatientThreadSMS | PatientThreadEmail)[] | null | undefined,
): ConversationItem[] => {
    const mappedResponse: ConversationItem[] =
        items
            ?.map((item) => {
                if (isPatientThreadSMS(item)) {
                    return mapPatientSmsItem(item);
                } else if (isPatientThreadEmail(item)) {
                    return mapPatientEmailItem(item);
                }
                return undefined;
            })
            .filter(
                (
                    item,
                ): item is
                    | PatientEmailConversationItem
                    | PatientSmsConversationItem => !isNil(item),
            ) ?? [];

    return mappedResponse;
};

const isPatientThreadSMS = (
    x: PatientThreadSMS | PatientThreadEmail,
): x is PatientThreadSMS => x.type === PatientThreadContentType.SMS;
const isPatientThreadEmail = (
    x: PatientThreadSMS | PatientThreadEmail,
): x is PatientThreadEmail => x.type === PatientThreadContentType.PatientEmail;

export const generateEmailConfirmation = ({
    patientName,
    sentByUserName,
    nhsNumber,
    mobileNumber,
}: GenerateEmailConfirmation): EmailConfirmation => {
    const timeStamp = DateHelpers.formatTime(
        DateHelpers.getCurrentTimeStamp(),
        DateFormatOptions.TIME_DATE_SHORT,
    );

    const subjectLine = `Copy of your message to patient: ${
        nhsNumber
            ? NhsNumberHelpers.formatNhsNumber(nhsNumber)
            : "Unknown NHS number"
    }`;

    const messageSentTo = `mobile number ${MobileNumberHelper.obfuscateDefined(
        mobileNumber ?? "",
    )}`;

    const body = `Message sent to ${
        patientName ?? "patient"
    } on ${messageSentTo} by ${
        sentByUserName ?? "Accurx user"
    } at ${timeStamp}\n`;

    return { body, subjectLine };
};

export const getPatientMatchingInformation = (
    conversation: ConversationSummary | null,
): {
    showPatientMatch: boolean;
    matchType: PatientMatchState | null;
    suggestedMatch: PatientTriageSuggestedPatient | null;
} => {
    const hasPatientId = conversation?.regardingPatientId;
    if (
        (conversation?.items[0]?.contentType === "PatientTriageRequestNote" &&
            conversation.items[0].initialRequestMatchState === "Verified") ||
        hasPatientId
    ) {
        return {
            showPatientMatch: false,
            matchType: "Verified",
            suggestedMatch: null,
        };
    }
    if (
        conversation?.items[0]?.contentType === "PatientTriageRequestNote" &&
        conversation.items[0].initialRequestMatchState === "NoMatch"
    ) {
        return {
            showPatientMatch: true,
            matchType: "NoMatch",
            suggestedMatch: null,
        };
    }
    if (
        conversation?.items[0]?.contentType === "PatientTriageRequestNote" &&
        conversation.items[0].initialRequestMatchState === "Suggested"
    ) {
        return {
            showPatientMatch: true,
            matchType: "Suggested",
            suggestedMatch: conversation.items[0].suggestedMatch,
        };
    }

    return {
        showPatientMatch: false,
        matchType: null,
        suggestedMatch: null,
    };
};

export type GetPatientDataResponseType = {
    firstName: string | null;
    familyName: string | null;
    externalIds: PatientExternalIdentity[];
    dateOfBirth: string;
    gender: string;
} | null;

export const getPatientData = (
    patient: PatientType,
    suggestedMatch: PatientTriageSuggestedPatient | null,
): GetPatientDataResponseType => {
    if (patient) {
        return {
            firstName: patient.firstName,
            familyName: patient.familyName,
            externalIds: patient.externalIds,
            dateOfBirth: patient.dateOfBirth,
            gender: patient.gender,
        };
    }
    if (suggestedMatch) {
        return {
            firstName: suggestedMatch.firstName,
            familyName: suggestedMatch.familyName,
            externalIds: suggestedMatch.externalIds,
            dateOfBirth: suggestedMatch.dateOfBirth,
            gender: suggestedMatch.gender,
        };
    }
    return null;
};

export const getPatientNotFoundBadgeInfo = (
    matchType: PatientMatchState | null,
): {
    showBadge: boolean;
    badgeText: string;
} => {
    if (matchType === "NoMatch") {
        return {
            showBadge: true,
            badgeText: "Patient not found",
        };
    }
    if (matchType === "Suggested") {
        return {
            showBadge: true,
            badgeText: "Mobile number not verified",
        };
    }
    return {
        showBadge: false,
        badgeText: "",
    };
};

const getPatientResponseAndAttachmentsTextLength = ({
    isPatientResponseEnabled,
    numberOfAttachments,
    includesQuestionnaire,
}: {
    isPatientResponseEnabled: boolean;
    numberOfAttachments: number;
    includesQuestionnaire: boolean;
}): number => {
    if (includesQuestionnaire) {
        return syncedCharLength.floreyResponseTextMaxLength;
    }

    if (isPatientResponseEnabled && numberOfAttachments > 0) {
        return syncedCharLength.singleLinkToPortalTextConstLength;
    }

    if (isPatientResponseEnabled) {
        return syncedCharLength.patientPortalResponseTextMaxLength;
    }

    if (numberOfAttachments > 0) {
        return syncedCharLength.smsAttachmentsLinkTextConstLength;
    }

    return 0;
};

export const getMessageSize = ({
    message,
    header,
    workspaceName,
    isPatientResponseEnabled,
    numberOfAttachments,
    includesQuestionnaire,
}: {
    message: string;
    header?: string;
    workspaceName: string;
    isPatientResponseEnabled: boolean;
    numberOfAttachments: number;
    includesQuestionnaire: boolean;
}) => {
    const patientResponseAndAttachmentsTextLength =
        getPatientResponseAndAttachmentsTextLength({
            isPatientResponseEnabled,
            numberOfAttachments,
            includesQuestionnaire,
        });

    const messageLengthWithGsmSanitisation =
        stringLengthWithGsmSantisation(message);

    const messageSize =
        patientResponseAndAttachmentsTextLength +
        messageLengthWithGsmSanitisation +
        workspaceName.length +
        (header?.length || 0);

    return {
        isSizeValid: messageSize <= syncedCharLength.maxMessageCharCount,
        messageSize: messageSize,
    };
};

/** Accepts a string and returns the character count and considers any GSM characters that take 2 characters of an SMS.
 *  The following characters from the GSM Character Set are counted as 2 characters by Firetext when sending an SMS:
 * \ ^ { } [ ~ ] | €
 * https://www.firetext.co.uk/docs/sms-marketing-glossary/gsm-character-set */
export const stringLengthWithGsmSantisation = (string: string) => {
    const gsmCharRegex = /[\\^{}[~\]|€]/g;

    const gsmCharCount = string.match(gsmCharRegex)?.length ?? 0;

    return string.length + gsmCharCount;
};
