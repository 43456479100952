import { useEffect } from "react";

import { trackAccountLoadPageView } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useAppSelector } from "store/hooks";

/**
 * Data team wants to have a single event on App load to track UtmParams
 * Possible query parameters are defined in UTM_MAPPING (ie: "utm_source", "utm_medium"...)
 * Any "utm_${ANY_VALUES}" query parameters are passed to the payload of all track events
 * This is done automatically by rudderstack under "context.campaign"
 */
export const AccountPageView = (): null => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);
    const isCookiesAccepted = useAppSelector(
        ({ account }) => account.user?.onboarding?.hasAcceptedCookies,
    );

    useEffect(() => {
        if (isLoggedIn && isCookiesAccepted) {
            trackAccountLoadPageView(analyticsLoggedInProps);
        }
        // N.B: We don't want to add "analyticsLoggedInProps" to not retrigger tracking on org change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isCookiesAccepted]);

    return null;
};
