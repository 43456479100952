import * as UI from "@accurx/design";

/**
 * TODO: Remove import from ILLEGAL_IMPORTS_DO_NOT_USE
 */
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledPillButton = styled(Pill.Button)`
    padding: ${UI.Tokens.SIZES[0.5]};
`;
