import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Hooks, Popover, PopoverTrigger } from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { COMPOSE_ERRORS } from "domains/compose/constants";
import { useCompose } from "domains/compose/context";
import { isErrorActive } from "domains/compose/utils";

import { ContactDetailButton } from "./ContactDetailButton";
import { StyledPopoverContent } from "./ContactDetailSelector.styles";
import { ContactDetailSelectorProps } from "./ContactDetailSelector.types";
import {
    ContactDetailSelectorForm,
    ContactDetailSelectorFormProps,
} from "./ContactDetailSelectorForm";

export const ContactDetailSelector = ({
    contactDetails,
}: ContactDetailSelectorProps) => {
    const { state, dispatch } = useCompose();

    const { size } = Hooks.useViewportSize();
    const enablePopoverAsModal = size === "xs" || size === "sm";

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const [isOpen, setIsOpen] = useState(false);
    const [emailInputValue, setEmailInputValue] = useState(
        state.contactDetails.method === "Email" &&
            state.contactDetails.origin === "UserInput"
            ? state.contactDetails.value
            : "",
    );
    const [mobileInputValue, setMobileInputValue] = useState(
        state.contactDetails.method === "Mobile" &&
            state.contactDetails.origin === "UserInput"
            ? state.contactDetails.value
            : "",
    );

    const onSubmit: ContactDetailSelectorFormProps["onSubmit"] = (
        selectedContactDetail,
    ) => {
        dispatch({
            type: "UPDATE_CONTACT_DETAILS",
            payload: { contactDetails: selectedContactDetail },
        });

        track("PatientMessageContactOptionConfirm Button Click", {
            ...nativeTrackingFields,
            contactType: selectedContactDetail.method,
            contactOrigin: selectedContactDetail.origin,
            // there is no such event yet, so no need to rise a version
        });

        setIsOpen(false);
    };

    const contactDetailButtonText =
        state.contactDetails.value === ""
            ? "Enter patient details"
            : state.contactDetails.value;

    const handleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <Popover
            open={isOpen}
            onOpenChange={handleOpen}
            modal={enablePopoverAsModal}
        >
            <PopoverTrigger asChild={true}>
                <ContactDetailButton
                    text={contactDetailButtonText}
                    toggle={() => setIsOpen((prev) => !prev)}
                    isError={isErrorActive(
                        state.errors,
                        COMPOSE_ERRORS.NoContactDetails,
                    )}
                />
            </PopoverTrigger>
            <StyledPopoverContent align="start">
                <ContactDetailSelectorForm
                    contactDetails={contactDetails}
                    onSubmit={onSubmit}
                    onCancel={() => setIsOpen(false)}
                    emailValue={emailInputValue}
                    emailOnChange={setEmailInputValue}
                    mobileValue={mobileInputValue}
                    mobileOnChange={setMobileInputValue}
                />
            </StyledPopoverContent>
        </Popover>
    );
};
