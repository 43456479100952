import React from "react";

import { Thread } from "@accurx/design";

import {
    StyledThreadFooterContainer,
    ThreadWrapper,
} from "./Conversation.styles";

type ConversationLayoutProps = {
    header: React.ReactNode;
    secondaryHeader?: React.ReactNode;
    messages: React.ReactNode;
    compose: React.ReactNode;
    scrollToLatestMessage: boolean;
    banner?: React.ReactNode;
};

export const ConversationLayout = ({
    header,
    secondaryHeader,
    messages,
    compose,
    scrollToLatestMessage,
    banner,
}: ConversationLayoutProps) => {
    return (
        <ThreadWrapper>
            <Thread>
                <Thread.Header>{header}</Thread.Header>
                {!!secondaryHeader && (
                    <Thread.Header>{secondaryHeader}</Thread.Header>
                )}
                <Thread.Body>
                    {messages}
                    {scrollToLatestMessage && <Thread.ScrollAnchor />}
                </Thread.Body>
                <StyledThreadFooterContainer>
                    <Thread.Footer>{compose}</Thread.Footer>
                </StyledThreadFooterContainer>
            </Thread>
            {banner && <>{banner}</>}
        </ThreadWrapper>
    );
};
