import { Draft } from "@reduxjs/toolkit";
import { ConciergeAction } from "domains/concierge/internal/types/ConciergeAction";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { setConversationFreshAt } from "domains/concierge/internal/util/conversationFreshAt";
import { conversationGroupIndexBy } from "domains/concierge/internal/util/conversationGroupIndexBy";
import { conversationGroupIndexOf } from "domains/concierge/internal/util/conversationGroupIndexOf";
import { createUnreadItemsSummary } from "domains/concierge/internal/util/createUnreadItemsSummary";
import { getConversation } from "domains/concierge/internal/util/getConversation";
import { isConversationInGroup } from "domains/concierge/internal/util/isConversationInGroup";
import { isUpdateBelowCeiling } from "domains/concierge/internal/util/isUpdateBelowCeiling";
import { mergeConversation } from "domains/concierge/internal/util/mergeConversation";
import { setConversation } from "domains/concierge/internal/util/setConversation";
import { updateUnreadState } from "domains/concierge/internal/util/updateUnreadState";

/**
 * Dear future explorers of the inbox,
 *
 * This reducer contains a lot of the more complex behaviour of the concierge
 * layer. We believe that there is a certain amount of fundamental complexity in
 * the inbox and we have done our best to contain that complexity inside this
 * function. We hope you find comfort in the amount of tests that surround this
 * reducer.
 *
 * This update accepts a set of conversations. Those conversations may be
 * complete conversations or partial conversations, i.e. it only contains a
 * subset of its full list of messages. It then:
 * - merges those updates in with any existing copies of the same conversations.
 *   It's important that this update behaviour is resiliant to both duplicate
 *   and out-of-order updates
 * - updates unread counts for the current user and all teams
 * - figures out if any of those updates fall into any tracked conversation
 *   groups and, if so, in which position in the group. Each group has a set of
 *   filters and sort options that we use to place a conversation within that
 *   group. As conversation groups are sorted lists we use binary search to
 *   performantly place a conversation in the list.
 * - if an update does fit into a group it figures out if the update falls
 *   within the know "dense set" of conversations for that group, and if so
 *   adjusts the group ceiling accordingly.
 */
export const processUpdates = (
    state: Draft<ConciergeState["conversations"]>,
    action: ConciergeAction<ConciergeUpdates>,
) => {
    const updates = action.payload.conversations;

    // Keep track of which teams the user is a member of because it's important
    // information for calculating unread counts
    //
    // NOTE: It's important to check specifically for true|false values here
    // because `undefined` is falsy and yet represents the fact that the team
    // update does not contain any information about current team membership
    for (const teamUpdate of action.payload.teams) {
        if (teamUpdate.isMember === true) {
            state.teamMembership[teamUpdate.id] = true;
        } else if (teamUpdate.isMember === false) {
            delete state.teamMembership[teamUpdate.id];
        }
    }

    for (const update of updates) {
        // Merge our existing record of the conversation (if any)
        // with the update we've received and set the result in the store.
        const current = getConversation(state, update.id);
        const next = mergeConversation(current, update);

        // if we don't already have this conversation
        // in the store, we increment the total count of
        // conversations
        if (!current) {
            state.total++;
        }

        if (
            update.isFullyLoaded &&
            // ClinicianMessaging conversations don't recieve signalR updates
            // currently so we should never treat them as fresh
            update.source.system !== "ClinicianMessaging" &&
            state.connectionState.state === "Connected"
        ) {
            setConversationFreshAt(
                state,
                update.id,
                state.connectionState.connectionId,
            );
        }

        const unreadItemsSummary = createUnreadItemsSummary(
            state.unreadItems[update.id],
            update,
            action.meta.userId,
        );
        updateUnreadState({
            state,
            current: state.unreadItems[update.id],
            next: unreadItemsSummary,
            userId: action.meta.userId,
        });

        for (const id in state.groups.items) {
            const group = state.groups.items[id];
            const isInGroup = isConversationInGroup(next, group.filters);

            const oldIndex = current
                ? conversationGroupIndexOf(state, group, current)
                : -1;

            // Because we mutate the group ceiling throughout the rest of this
            // function we first capture whether the conversation was initially
            // below the ceiling so we can reason about it a bit more easily.
            const wasBelowCeiling = oldIndex > -1 && oldIndex < group.ceiling;

            // if it was previously in the group, remove it (for now)
            // this HAS to happen before we calculate the newIndex
            // or we'll calculate the wrong index, as the list will be one
            // longer than it should be
            if (oldIndex > -1) {
                group.members.splice(oldIndex, 1);
                if (wasBelowCeiling) group.ceiling--;
            }

            const newIndex = isInGroup
                ? conversationGroupIndexBy(state, group, next)
                : -1;

            // if it is now in the group, add it
            // (this will add it back if it was removed in previous step)
            if (newIndex > -1) {
                group.members.splice(newIndex, 0, next.id);
                if (newIndex < group.ceiling) {
                    group.ceiling++;
                } else if (
                    // If the final conversation in the group updates and is
                    // still in the same position after the update we need to do
                    // an extra check to see if it still falls within the group
                    // ceiling. We compare the update to the current version to
                    // see if it's moved any further back in the sort order.
                    wasBelowCeiling &&
                    newIndex === oldIndex &&
                    oldIndex === group.ceiling &&
                    isUpdateBelowCeiling(
                        update,
                        current!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                        group.sortOptions,
                    )
                ) {
                    group.ceiling++;
                }
            }

            if (group.isFullyLoaded) {
                group.ceiling = group.members.length;
            }
        }

        // this has to happen at the end, after we calculate group membership
        // as we rely on having the original state of the conversation to do so
        setConversation(state, next);
    }

    return state;
};
