/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { actionCreators as VideoCompatibilityActions } from "app/selectProduct/VideoCompatibilityActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES, ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { FeatureButton } from "../FeatureButton";
import { isVideoConsultDisabled } from "../PatientProfile.helper";

const VideoConsultButton = ({
    userAgent,
}: {
    userAgent: string;
}): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();
    const videoCompatibility = useAppSelector(
        ({ videoCompatibility }) => videoCompatibility,
    );
    const patient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );
    const appointment = useAppSelector(({ patientLists }) => {
        if (searchPatientOrigin === ROUTES.patient_lists) {
            return patientLists.latestSearchPatientListEntry;
        }

        return null;
    });
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
    const loggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        if (videoCompatibility.hasChecked === false) {
            dispatch(
                VideoCompatibilityActions.checkVideoCompatibility(userAgent),
            );
        }
    }, []);

    const goToSearchVideo = () => {
        const route = `${findBaseRoute(history.location.pathname)}${
            ROUTES_EXTENSION.sendMessage
        }`;

        dispatch(actionCreators.selectVideoProductType());

        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Header" as const,
            optionSelected: "VideoInvite" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        FlemingAnalyticsTracker.trackProductOptionButtonClick(analyticsProps);

        history.push(route, {
            referredFrom: ROUTES_EXTENSION.patientProfile,
        });
    };

    const isVideoConsultEnabledForOrg = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName.VideoConsultWeb,
    );
    if (!isVideoConsultEnabledForOrg) return null;

    const disabledInfo = isVideoConsultDisabled(
        patient,
        appointment,
        videoCompatibility,
    );

    return (
        <FeatureButton
            title="Video Consult"
            icon={{ name: "Video", colour: "blue" }}
            action={goToSearchVideo}
            disabledInfo={disabledInfo}
        />
    );
};

export { VideoConsultButton };
