import React from "react";

import { Tabs, Text } from "@accurx/design";
import { Redirect, generatePath, useHistory, useParams } from "react-router";

import {
    Breadcrumb,
    Breadcrumbs,
    Container,
    Header,
} from "app/organisations/shared";
import { ROUTES_ORGS } from "shared/Routes";

import { PAGE_HEADING, TABS } from "./UsageDashboardPage.consts";
import { useUsageDashboardPage } from "./UsageDashboardPage.hooks";
import { FragmentsDashboard } from "./components/FragmentsUsage/FragmentsDashboard";
import { TeamUsage } from "./components/TeamUsage";

export const UsageDashboardPage = (): JSX.Element => {
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();

    const { tab, redirect } = useUsageDashboardPage();
    if (!tab) return <Redirect to={redirect} />;

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.usageDashboard, {
                orgId,
                tab: tabId,
            }),
        );
    };

    return (
        <Tabs selected={tab.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text={PAGE_HEADING}
                        path={ROUTES_ORGS.usageDashboard}
                    />
                    <Breadcrumb
                        text={tab.text}
                        path={ROUTES_ORGS.usageDashboard}
                        params={{ tab: tab?.tabId }}
                    />
                </Breadcrumbs>
                <Text variant="title" as="h1" skinny>
                    {PAGE_HEADING}
                </Text>
                <Header.TabList tabs={TABS} />
            </Header>
            <Container>
                <Tabs.Panel tabId="team">
                    <TeamUsage />
                </Tabs.Panel>
                <Tabs.Panel tabId="fragments">
                    <FragmentsDashboard />
                </Tabs.Panel>
            </Container>
        </Tabs>
    );
};

export default UsageDashboardPage;
