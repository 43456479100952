import { useCallback } from "react";

import { useMountedState } from "shared/useMountedState";

export const getServerUrl = (): string => window.location.origin;

/**
 * To be used when calling an API directly from within a component
 * if the returned value results in a state updated.
 *
 *
 * It prevents the warning
 * `Warning: Can't perform a React state update on an unmounted component.`
 * from occurring
 *
 * _If used inside a useEffect_ `safeAsync` **must** be in the list of dependencies
 *
 * ---
 *
 * **Example usage:**
 *
 * ```
 * const safeAsync = useSafeAsync();
 *
 * useEffect(() => {
 *     const response = await safeAsync(ApiHelper.testApiCall());
 *
 *     // ...continue updating state here - if mounted the promise will not resolve
 *     // and this code won't run
 * }, [safeAsync]);
 *
 * ```
 * If you want to catch any promise rejections, make sure you wrap your await block inside a try catch
 *
 * ```
 * useEffect(() => {
 *     try {
 *         const response = await safeAsync(ApiHelper.testApiCall());
 *
 *         // ...continue updating state here - if mounted the promise will not resolve
 *         // and this code won't run
 *     } catch (e) {
 *         // do something with the error
 *
 *         // ...continue updating state here - if mounted the promise will not resolve
 *         // and this code won't run
 *     }
 * }, [safeAsync]);
 * ```
 * ---
 *
 * @returns a resolved promise if the component is still mounted
 */
export const useSafeAsync = (): (<T>(promise: Promise<T>) => Promise<T>) => {
    const isMounted = useMountedState();

    const safeAsync: <T>(promise: Promise<T>) => Promise<T> = useCallback(
        (promise) => {
            return new Promise((resolve, reject) => {
                promise
                    .then((value) => {
                        if (isMounted()) {
                            resolve(value);
                        }
                    })
                    .catch((error) => {
                        if (isMounted()) {
                            reject(error);
                        }
                    });
            });
        },
        [isMounted],
    );

    return safeAsync;
};
