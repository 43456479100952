import * as UI from "@accurx/design";

import { StyledLink } from "./FragmentCount.styles";

const SUPPORT_ARTICLE_URL =
    "https://support.accurx.com/en/articles/6922338-sms-costs-what-is-a-fragment";

type FragmentCountProps = {
    count: number;
};

export const FragmentCount = ({ count }: FragmentCountProps) => {
    return (
        <UI.Text
            skinny
            variant="preview"
            colour="metal"
            props={{
                "aria-label": "Count of fragments used.",
            }}
        >
            {`(${count} `}
            <StyledLink href={SUPPORT_ARTICLE_URL} openInNewTab>
                <UI.Link.Text text={count !== 1 ? "fragments" : "fragment"} />
                <UI.Icon
                    name="OpenWindow"
                    size={3}
                    colour={"blue"}
                    title={"Opens in new tab"}
                />
            </StyledLink>
            {")"}
        </UI.Text>
    );
};
