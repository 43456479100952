import { ReactNode } from "react";

import * as UI from "@accurx/design";
import styled from "styled-components";

type AttachmentsContainerWithLabelProps = {
    children: ReactNode;
};

export const StyledContainer = styled.figure`
    margin: 0;
`;

export const AttachmentsContainerWithLabel = (
    props: AttachmentsContainerWithLabelProps,
) => {
    return (
        <StyledContainer>
            <figcaption>
                <UI.Text skinny variant="note">
                    Attachments
                </UI.Text>
            </figcaption>
            {props.children}
        </StyledContainer>
    );
};
