import { Action, Reducer } from "redux";

import { IDeliveryReceipt } from "api/FlemingDtos";

import {
    KnownAction,
    REQUEST_RECEIPTS_FAILURE,
    REQUEST_RECEIPTS_STARTED,
    REQUEST_RECEIPTS_SUCCESS,
} from "./DeliveryReceiptsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DeliveryReceiptsState {
    hasDeliveryReceiptsEverLoaded: boolean;
    isDeliveryReceiptsLoading: boolean;
    lastGetDeliveryReceiptsFailed: boolean;
    deliveryReceipts: IDeliveryReceipt[];
    lastDeliveryReceiptsOrganisation: number | null;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: DeliveryReceiptsState = {
    hasDeliveryReceiptsEverLoaded: false,
    isDeliveryReceiptsLoading: false,
    lastGetDeliveryReceiptsFailed: false,
    deliveryReceipts: [],
    lastDeliveryReceiptsOrganisation: null,
};

export const reducer: Reducer<DeliveryReceiptsState> = (
    state = initialState,
    incomingAction: Action,
): DeliveryReceiptsState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case REQUEST_RECEIPTS_STARTED:
            return {
                ...state,
                isDeliveryReceiptsLoading: true,
            };
        case REQUEST_RECEIPTS_SUCCESS:
            return {
                ...state,
                hasDeliveryReceiptsEverLoaded: true,
                isDeliveryReceiptsLoading: false,
                lastGetDeliveryReceiptsFailed: false,
                deliveryReceipts: action.deliveryReceipts,
                lastDeliveryReceiptsOrganisation:
                    action.lastDeliveryReceiptsOrganisation,
            };
        case REQUEST_RECEIPTS_FAILURE:
            return {
                ...state,
                hasDeliveryReceiptsEverLoaded: false,
                isDeliveryReceiptsLoading: false,
                lastGetDeliveryReceiptsFailed: true,
            };
    }

    return state;
};
