import {
    AssigneeType,
    PatientThreadAssignee,
    PatientThreadTicketStateChangeNote,
    TicketState,
} from "@accurx/api/ticket";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import {
    StateChangeItem,
    TeamAssigneeSummary,
    UserAssigneeSummary,
} from "domains/concierge/types";

import { mapCreatedByOptionalUser } from "./shared/mapCreatedByOptionalUser";

/**
 * Maps ticket State change to conversation State change
 *
 * @param ticketStateChangeItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapStateChangeItem(
    ticketStateChangeItem: PatientThreadTicketStateChangeNote,
): StateChangeItem | undefined {
    if (!ticketStateChangeItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    if (ticketStateChangeItem.message.state === TicketState.Assignment) {
        const assignedTo = mapToStateChangeAssignee(
            ticketStateChangeItem.message.assignee,
        );

        if (!assignedTo) {
            throw new Error(
                "Cannot map ticket item as assignee cannot be mapped",
            );
        }

        return {
            ...mapBaseConversationItem(ticketStateChangeItem),
            contentType: "StateChange",
            changeType: "Assignment",
            assignedTo,
            createdBy: mapCreatedByOptionalUser(
                ticketStateChangeItem.message.createdByUserId,
            ),
        };
    }

    const changeType = mapToStateChangeType(
        ticketStateChangeItem.message.state,
    );
    if (!changeType) {
        throw new Error(
            "Cannot map ticket item as state change type is unknown",
        );
    }

    return {
        ...mapBaseConversationItem(ticketStateChangeItem),
        contentType: "StateChange",
        createdBy: mapCreatedByOptionalUser(
            ticketStateChangeItem.message.createdByUserId,
        ),
        changeType,
    };
}

/**
 * Mapper for all state change types except Assignment, as that's dealt with separately
 */
function mapToStateChangeType(
    apiStateChangeType: Exclude<TicketState, "Assignment">,
): Exclude<StateChangeItem["changeType"], "Assignment"> | undefined {
    switch (apiStateChangeType) {
        case TicketState.Done:
            return "Done";
        case TicketState.ReOpened:
            return "ReOpened";
        case TicketState.PatientMatch:
            return "PatientMatch";
        case TicketState.UnmatchPatient:
            return "PatientUnmatch";
        case TicketState.Urgent:
            return "Urgent";
        case TicketState.NonUrgent:
            return "NonUrgent";

        default:
            return undefined;
    }
}

function mapToStateChangeAssignee(
    apiAssignee?: PatientThreadAssignee | null,
): UserAssigneeSummary | TeamAssigneeSummary | undefined {
    if (apiAssignee?.type === AssigneeType.User && !!apiAssignee.userId) {
        return {
            type: "User",
            id: apiAssignee.userId,
        };
    }

    if (
        apiAssignee?.type === AssigneeType.UserGroup &&
        !!apiAssignee.userGroupId
    ) {
        return {
            type: "Team",
            id: apiAssignee.userGroupId,
        };
    }

    return undefined;
}
