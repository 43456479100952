import { useConciergeSelector } from "domains/concierge/internal/context";
import { UserSummary } from "domains/concierge/types";

export const useUsersById = ({
    userIds,
}: {
    userIds: UserSummary["id"][];
}): (UserSummary | undefined)[] => {
    return useConciergeSelector((state) => {
        return userIds.map((id) => state.users.items[id]);
    });
};
