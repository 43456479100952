import { useEffect, useState } from "react";

import { useSelectedPatientToken } from "app/patients/hooks";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { ConversationUserActions } from "shared/concierge/conversations/types/component.types";
import { Conversation } from "shared/concierge/conversations/types/conversation.types";
import { usePatientManager } from "shared/concierge/patients/hooks";
import { SubscriptionResult } from "shared/concierge/types/subscription.types";
import { useUsersAndTeamsManager } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";
import { useCurrentUserId } from "store/hooks";

import { setupSearchedPatientConversationSubscription } from "./searchedPatientConversationSubscription";

type SupportedConversationActions = Pick<
    ConversationUserActions,
    "addItemsToConversation" | "markAsDone" | "markAsOpen" | "markItemAsRead"
>;

export const useSearchedPatientConversation = (
    conversationId: string | null,
): SubscriptionResult<Conversation> & {
    actions: SupportedConversationActions | null;
} => {
    const currentUserId = useCurrentUserId();
    const currentWorkspaceId = useWorkspaceId();
    const currentPatientToken = useSelectedPatientToken();

    const patientManager = usePatientManager();
    const usersAndTeamsManager = useUsersAndTeamsManager();

    const [state, setState] = useState<SubscriptionResult<Conversation>>({
        status: "LOADING",
        data: null,
        errorMessage: null,
    });

    const [actions, setActions] = useState<SupportedConversationActions | null>(
        null,
    );

    useEffect(() => {
        const sanitisedWorkspaceId = Number.isSafeInteger(currentWorkspaceId)
            ? currentWorkspaceId
            : null;

        if (
            !conversationId ||
            !sanitisedWorkspaceId ||
            !currentPatientToken ||
            !currentUserId
        ) {
            return;
        }

        const { subscription, actions } =
            setupSearchedPatientConversationSubscription({
                currentPatientToken,
                currentUserId,
                currentWorkspaceId,
                conversationIdentity: { id: conversationId },
            });

        subscription.start();

        const patientsSubscriptionHandle = patientManager.connectFeed(
            actions.patientsFeed,
        );
        const usersAndTeamsSubscriptionHandle =
            usersAndTeamsManager.connectFeed(actions.usersAndTeamsFeed);

        setActions(actions);

        const feed = subscription.feed.subscribe((update) => {
            setState(update);
        });

        return () => {
            feed.unsubscribe();
            subscription.teardown();
            actions.teardown();
            patientsSubscriptionHandle.unsubscribe();
            usersAndTeamsSubscriptionHandle.unsubscribe();
        };
    }, [
        currentPatientToken,
        currentUserId,
        currentWorkspaceId,
        conversationId,
        usersAndTeamsManager,
        patientManager,
    ]);

    return {
        ...state,
        actions,
    };
};
