import { createQueryHook } from "@accurx/api/QueryClient";
import { returnDataOrThrow } from "@accurx/shared";

import { getWorkspaceInvitesForCurrentUser } from "api/WorkspaceApi";
import { WorkspaceInvite } from "api/WorkspaceDtos";

export const WORKSPACE_INVITES_QUERY_KEY = "getWorkspaceInvites";

export const useGetWorkspaceInvitesQuery = createQueryHook<
    void,
    WorkspaceInvite[]
>(() => ({
    queryKey: [WORKSPACE_INVITES_QUERY_KEY, {}],
    queryFn: async () => {
        const response = await getWorkspaceInvitesForCurrentUser();
        return returnDataOrThrow(response).invites;
    },
}));
