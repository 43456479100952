import { useParams } from "react-router-dom";

import { ConversationSummaries, PaginationRow } from "../components";
import { ConversationsError } from "../components/ConversationsError";
import { NoConversations } from "../components/NoConversations";
import { useClinicianConversations } from "../hooks/useClinicianConversations";
import { isView } from "../types";
import { ClinicianConversationsPageLayout } from "./ClinicianConversationsPageLayout";
import { LoadingClinicianConversationPage } from "./LoadingClinicianConversationPage";

export const ClinicianConversationsPage: () => JSX.Element = () => {
    const { view } = useParams<{ view: string }>();

    if (!isView(view)) {
        throw new Error("Invalid view");
    }

    const headerText = `${view === "me" ? "My" : "All"} conversations`;

    const { page, setPage, conversationSummaries, state, pageSize } =
        useClinicianConversations({
            view,
        });

    if (state === "loading") {
        return (
            <LoadingClinicianConversationPage
                headerText={headerText}
                pageSize={pageSize}
            />
        );
    }

    if (state !== "success") {
        return (
            <ClinicianConversationsPageLayout headerText={headerText}>
                <ConversationsError tryAgain={() => window.location.reload()} />
            </ClinicianConversationsPageLayout>
        );
    }

    if (conversationSummaries.length === 0) {
        return (
            <ClinicianConversationsPageLayout headerText={headerText}>
                <NoConversations />
            </ClinicianConversationsPageLayout>
        );
    }

    const hasPages = page > 1 || conversationSummaries.length === pageSize;

    const pagination = hasPages ? (
        <PaginationRow
            currentPage={page}
            isACompletePage={conversationSummaries.length === pageSize}
            changePage={setPage}
        />
    ) : undefined;

    return (
        <ClinicianConversationsPageLayout
            pagination={pagination}
            headerText={headerText}
        >
            <ConversationSummaries
                conversationSummaries={conversationSummaries}
            />
        </ClinicianConversationsPageLayout>
    );
};
