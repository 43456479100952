import { User, useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateUploadPatientRecordAttachmentResponse,
    mutateUploadPatientRecordAttachment,
} from "domains/native/api/endpoints/mutateUploadPatientRecordAttachment";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";
import { PatientExternalId } from "domains/native/schemas/PatientExternalIdSchema";

export type UseUploadPatientRecordAttachmentMutationVariables = {
    documentId: string;
    patientExternalIds: PatientExternalId[];
};

export type UseUploadPatientRecordAttachmentMutationResponse =
    MutateUploadPatientRecordAttachmentResponse & {
        previewUrl?: string;
    };

export const useUploadPatientRecordAttachmentMutation = (
    options?: UseMutationOptions<
        UseUploadPatientRecordAttachmentMutationResponse,
        NativeFetchError | NativeTransportError,
        UseUploadPatientRecordAttachmentMutationVariables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;
    const { user } = useCurrentUser();

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "UploadPatientRecordAttachmentMutation: Called with no native transport",
            );
        }

        const response = await mutateUploadPatientRecordAttachment(
            {
                transport,
                workspaceId,
            },
            {
                documentId: variables.documentId,
                patientIdentity: {
                    patientExternalIds: variables.patientExternalIds,
                },
            },
        );

        const previewUrl = getPreviewUrl(user, response, workspaceId);

        return {
            ...response,
            previewUrl,
        };
    }, options);
};

function getPreviewUrl(
    user: User,
    response: MutateUploadPatientRecordAttachmentResponse,
    workspaceId: number,
) {
    if (response.serverUrlId)
        return `/api/patientmessaging/DocumentUpload/previewdocument?documentUrlId=${response.serverUrlId}&organisationId=${workspaceId}`;

    if (user.isTrustedDevice)
        return `/api/patientmessaging/DocumentUpload/preview?documentId=${response.serverId}&organisationId=${workspaceId}`;

    return undefined;
}
