import { shallowEqual } from "react-redux";

import { WebPatient } from "app/patients/Patient.types";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

// If patient is selected then will return the selected patient
// If no patient is selected then will return null
export const useSelectedPatient = (): WebPatient | null => {
    const selectedPatient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getSelectedPatient(searchForPatient),
        shallowEqual,
    );

    return selectedPatient;
};

// If patient is selected then returns the patient token (can be null)
// If no patient is selected then will return null
export const useSelectedPatientToken = (): string | null => {
    const selectedPatient = useSelectedPatient();

    // If no selected patient, then no patientId to return
    if (selectedPatient === null) {
        return null;
    }

    return selectedPatient.patientToken;
};
