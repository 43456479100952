import { Ds } from "@accurx/design";

import { FloreyScoreSummary } from "app/questionnaireScoreboard/schemas";

import {
    getComplexityBadgeColour,
    getComplexityBadgeText,
} from "../complexityBadge.utils";

type CalculatedStatusBadgeProps = {
    verbose?: boolean;
    dataTestId?: string;
} & Pick<FloreyScoreSummary, "complexity">;

/**
 * Renders a badge for the calculated complexity of a questionnaire response.
 */
export const CalculatedComplexityBadge = ({
    complexity,
    verbose,
    dataTestId,
}: CalculatedStatusBadgeProps) => {
    if (complexity === "NotApplicable") {
        return <>N/A</>;
    }

    return (
        <Ds.Badge
            data-testid={dataTestId}
            color={getComplexityBadgeColour(complexity)}
        >
            {getComplexityBadgeText({ complexity, verbose: !!verbose })}
        </Ds.Badge>
    );
};
