import { TabProps, Tabs } from "@accurx/design";

import { TabListContainer } from "./TabList.styles";

/*
 * Add negative margin so TabList bottom border overlays header border
 */
export const TabList = ({ tabs }: { tabs: TabProps[] }) => {
    return (
        <TabListContainer>
            <Tabs.TabList tabs={tabs} />
        </TabListContainer>
    );
};
