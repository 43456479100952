import React from "react";

import { Tabs } from "domains/inbox/components/Tabs";

export type ConversationTabsProps = {
    children: React.ReactNode;
};

export const ConversationTabs = ({ children }: ConversationTabsProps) => {
    return (
        <Tabs.Link.List ariaLabel="Conversation subfolder options">
            {children}
        </Tabs.Link.List>
    );
};
