import React, { Fragment } from "react";

import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";

import { PracticeDetails } from "app/practices/Practices.types";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionAccuBookProps = {
    practice?: PracticeDetails;
};

export const SectionAccuBook = ({
    practice,
}: SectionAccuBookProps): JSX.Element | null => {
    const practiceFeatures = practice?.features || [];
    const pendingVaccineApprovalRequests =
        practice?.pendingVaccineApprovalRequests || false;
    const showVaccineLinks = practice?.showVaccineLinks || false;

    const isVaccinePractice = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePractice,
    );
    const isVaccineDeliverySite = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccineDeliverySite,
    );

    const vaccineLinks: JSX.Element[] = [];
    if (isVaccineDeliverySite) {
        vaccineLinks.push(
            <PracticeLink
                url="vaccine-site-setup"
                title="Manage clinics"
                section={PracticeLinkSection.VACCINE}
                description="For the designated coordinating practice to create and manage vaccination clinics"
            />,
        );
    }
    if (isVaccinePractice) {
        vaccineLinks.push(
            <PracticeLink
                url="vaccine-approval-requests"
                title="Linked delivery sites"
                section={PracticeLinkSection.VACCINE}
                description="Accept an invite to be part of a delivery site, and view configured sites"
                badgeCopy={
                    pendingVaccineApprovalRequests ? "Pending Requests" : ""
                }
            />,
        );
    }
    if (showVaccineLinks && isVaccinePractice) {
        vaccineLinks.push(
            <PracticeLink
                url="vaccine-all-patients-invited"
                title="Invite patients and manage bookings"
                section={PracticeLinkSection.VACCINE}
                description="Upload a list of patients and send SMS invitations to book, call and manually book patients in for vaccination"
            />,
        );
    }
    if (showVaccineLinks && (isVaccineDeliverySite || isVaccinePractice)) {
        vaccineLinks.push(
            <PracticeLink
                url="vaccine-booked-clinics"
                title="Booked list"
                section={PracticeLinkSection.VACCINE}
                description="View and manage patients booked into a vaccination session"
            />,
        );
    }
    if (isVaccinePractice) {
        vaccineLinks.push(
            <PracticeLink
                url="vaccine-capacity-dashboard"
                title="Dashboard"
                section={PracticeLinkSection.VACCINE}
                description="View the capacity stats across each delivery site you're a member of"
            />,
        );
    }

    if (vaccineLinks.length === 0) {
        return null;
    }

    return (
        <>
            <Text as="h2" variant="subtitle">
                COVID-19 Vaccine
            </Text>
            <div className="mb-4">
                {vaccineLinks.map((vaccineLink, index) => (
                    <Fragment key={index}>{vaccineLink}</Fragment>
                ))}
            </div>
        </>
    );
};
