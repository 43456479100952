import { useMemo } from "react";

import { useFuzzyFilter } from "@accurx/hooks";
import { TemplateItem } from "domains/compose/types";

import { groupTemplatesByOwnerAndHeading } from "./utils/groupTemplatesByOwnerAndHeading";

const SEARCH_OPTIONS = {
    keys: ["value.title", "value.heading"],
    threshold: 0.2,
};

type UseMessageTemplatesFilterProps = {
    templates: TemplateItem[];
    searchTerm: string;
};

export const useMessageTemplatesFilter = ({
    templates,
    searchTerm,
}: UseMessageTemplatesFilterProps) => {
    const filteredTemplates = useFuzzyFilter<TemplateItem>(
        templates,
        searchTerm,
        SEARCH_OPTIONS,
    );

    return useMemo(
        () => ({
            templateGroups: groupTemplatesByOwnerAndHeading(filteredTemplates),
            count: filteredTemplates.length,
        }),
        [filteredTemplates],
    );
};
