import { Flex, Icon, Item, Table } from "@accurx/design";

import { ContactablePatient } from "../pages/utils/contactable";
import {
    ContactDetails,
    StyledTable,
    StyledTableHead,
    StyledTruncatedTableData,
} from "./PatientDetailsTable.styles";

type ContactablePatientsTableProps = {
    patients: ContactablePatient[];
};

export const ContactablePatientsTable = ({
    patients,
}: ContactablePatientsTableProps) => {
    return (
        <StyledTable>
            <StyledTableHead>
                <Table.Row>
                    <Table.Header scope="column">NHS number</Table.Header>
                    <Table.Header scope="column">Patient name</Table.Header>
                    <Table.Header scope="column">Contact method</Table.Header>
                </Table.Row>
            </StyledTableHead>
            <Table.Body>
                {patients.map((patient, index) => (
                    <Table.Row key={`${patient.nhsNumber}-${index}`}>
                        <Table.DataCell>
                            <StyledTruncatedTableData>
                                {patient.nhsNumber}
                            </StyledTruncatedTableData>
                        </Table.DataCell>
                        <Table.DataCell>
                            <StyledTruncatedTableData>
                                {patient.displayName}
                            </StyledTruncatedTableData>
                        </Table.DataCell>
                        <Table.DataCell>
                            <Flex
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <ContactDetails>
                                    {patient.contact.value}
                                </ContactDetails>
                                {patient.contact.type === "Mobile" &&
                                    patient.contact.isOverride && (
                                        <Item flex="0">
                                            <Icon
                                                name="Flag"
                                                size={3}
                                                colour="zinc"
                                                title="Mobile number overridden"
                                            />
                                        </Item>
                                    )}
                            </Flex>
                        </Table.DataCell>
                    </Table.Row>
                ))}
            </Table.Body>
        </StyledTable>
    );
};
