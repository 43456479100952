import { useConciergeSelector } from "domains/concierge/internal/context";
import { getConversationFreshAt } from "domains/concierge/internal/util/conversationFreshAt";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";

export const useIsConversationFresh = ({
    conversationId,
}: {
    conversationId: Conversation["id"];
}): boolean => {
    return useConciergeSelector((state) => {
        const connectionState = state.conversations.connectionState;

        if (connectionState.state !== "Connected") {
            return false;
        }

        const freshAt = getConversationFreshAt(
            state.conversations,
            conversationId,
        );

        return freshAt === connectionState.connectionId;
    });
};
