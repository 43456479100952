import { splitRoute } from "@accurx/navigation";
import * as Sentry from "@sentry/react";
// eslint-disable-next-line no-restricted-imports
import { Redirect, Route, RouteProps, matchPath } from "react-router-dom";

import { ROUTES } from "shared/Routes";
import { categoriseRouteByFeature } from "shared/RoutesByFeature";

export type AppRouteProps = RouteProps | GuardedAppRouteProps;

/**
 * @deprecated please use {@link SimpleRoute}  or /guardedRoutes/GuardedRoute instead
 */
export const AppRoute = (props: AppRouteProps) => {
    if (isRouteGuarded(props)) {
        // update Sentry context to set route related tags
        if (props.allowRoute.isAllowed) {
            if (props.location && props.path) {
                setSentryRouteTags(props);
            }
        }

        return <GuardedRoute {...props} />;
    } else {
        return <SimpleRoute {...props} />;
    }
};

/**
 * Type of props for Route but with non-children rendering methods removed.
 * This will cause a type error if someone tries to use component= or render=
 * on GuardedRoute.
 *
 * See https://www.typescriptlang.org/docs/handbook/utility-types.html#omittk.
 *
 * By excluding `component` and `render`, we ensure those render methods don't
 * bypass the `allowRoute` check, which would result in components being
 * rendered when not allowed.
 */
type StrictRouteProps = Omit<RouteProps, "component" | "render">;

interface GuardedAppRouteProps extends StrictRouteProps {
    allowRoute: GuardedAppRouteRedirectProps;
}

export interface GuardedAppRouteRedirectProps {
    isAllowed: boolean;
    redirectTo?: string;
}

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * A route that renders children if `allowRoute` condition true, otherwise
 * redirects to home. Inspiration taken from
 *
 * https://reacttraining.com/react-router/web/example/auth-workflow.
 *
 * React Router recommends using children rather than component or render
 * method. See

 * https://reacttraining.com/react-router/web/api/Route/route-render-methods.
 *
 * Using children rather than render ensures that the component isn't
 * recreated/remounted every render.
 */
function GuardedRoute({
    allowRoute,
    children,
    ...routeProps
}: GuardedAppRouteProps) {
    if (allowRoute.isAllowed) {
        return (
            <SentryRoute
                {...routeProps}
                render={() => {
                    return children;
                }}
            />
        );
    }

    return <BlockedRoute {...routeProps} redirectTo={allowRoute.redirectTo} />;
}

// Renders a route with no guarding
export const SimpleRoute = (props: RouteProps) => {
    setSentryRouteTags(props);

    return <SentryRoute {...props} />;
};

// Redirects away from a blocked route
export const BlockedRoute = ({
    redirectTo,
    ...routeProps
}: StrictRouteProps & { redirectTo?: string }) => {
    return (
        <SentryRoute
            {...routeProps}
            render={({ location }) => {
                const { pathname, search } = redirectTo
                    ? splitRoute(redirectTo)
                    : { pathname: ROUTES.home, search: undefined }; // Default to home page if no redirect route

                return (
                    <Redirect
                        to={{
                            ...location,
                            pathname: pathname,
                            search: search || location.search, // preserve query string, unless there was a query string in the redirectTo route
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

function isRouteGuarded(
    props: RouteProps | GuardedAppRouteProps,
): props is GuardedAppRouteProps {
    return "allowRoute" in props;
}

type RouteInfo = Pick<AppRouteProps, "location" | "path">;
type RouteParams = Record<string, string>;

/**
 * Uses react-routers location and path RouteProps to find the route name from
 * the current URL and updates the current Sentry context. This means any issues
 * that are sent to Sentry downstream of here will have the relevant route tags.
 * The parameterised route (url.path) also makes it possible to group issues by
 * route, and assign by route 😍
 */
function setSentryRouteTags({ location, path }: RouteInfo): void {
    if (location === undefined) return;

    // wrap in try to be extra sure that we don't break anything. errors
    // setting this Sentry context will be caught and logged, but the app
    // can continue running as normal
    try {
        const matchedPath = matchPath<RouteParams>(
            location.pathname,
            typeof path === "string" ? path : path ? [...path] : [],
        );

        if (matchedPath && matchedPath.isExact === true) {
            const feature = categoriseRouteByFeature(
                matchedPath.path,
                matchedPath.url,
            );

            Sentry.configureScope(function (scope) {
                scope.setTag("product", feature);
                scope.setTag("route.path", matchedPath.path);

                for (const p in matchedPath.params) {
                    scope.setTag(`route.params.${p}`, matchedPath.params[p]);
                }
            });
        }
    } catch (error) {
        if (error instanceof Error) {
            // in the event we can't set route tags
            Sentry.captureException(error, {
                level: "info",
                contexts: {
                    "Router details": {
                        ...location,
                        paths: path,
                    },
                },
                tags: {
                    path: location.pathname,
                },
            });
        }
    }
}
