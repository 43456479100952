import {
    PatientThreadFilteredTicketView,
    PatientThreadTicketFolder,
    View,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapPatientThreadFilteredTicketViewToConciergeUpdate } from "../mappers/mapPatientThreadFilteredTicketViewToConciergeUpdate";

type Folder = Omit<PatientThreadTicketFolder, "viewType"> & {
    viewType: keyof typeof View;
};

export type GetFolderTicketViewOptions = {
    workspaceId: number;
    folder: Folder;
    continuationToken?: string;
};

export const getFolderTicketView: ConciergeFetcher<
    GetFolderTicketViewOptions,
    PatientThreadFilteredTicketView
> = async (arg) => {
    const response = await TicketApiClient.fetchFolderView(
        arg.workspaceId,
        {
            ...arg.folder,
            viewType: View[arg.folder.viewType],
        },
        arg.continuationToken,
    );

    return {
        updates: mapPatientThreadFilteredTicketViewToConciergeUpdate(response),
        response,
    };
};
