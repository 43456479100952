import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const ResponsiveContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${Tokens.SIZES[3]};

    @media screen and (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: grid;
        grid-template-columns: 80% max-content;
        justify-content: space-between;
        align-items: end;
    }

    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: flex;
        flex-direction: column;
        margin-bottom: ${Tokens.SIZES[3]};
        gap: 10px;
    }

    @media screen and (min-width: ${Tokens.BREAKPOINTS.xl}) {
        display: grid;
        grid-template-columns: 80% max-content;
        justify-content: space-between;
        align-items: end;
    }
`;

export const AccordionWrapper = styled.div`
    width: 100%;
`;

export const RefreshButtonWrapper = styled.div`
    @media screen and (max-width: ${Tokens.BREAKPOINTS.md}) {
        width: max-content;
        align-self: end;
        margin-top: ${Tokens.SIZES[2]};
    }
`;

export const ActionTableColumn = styled.td`
    @media screen and (min-width: ${Tokens.BREAKPOINTS.md}) {
        min-width: 11rem;
    }
`;
