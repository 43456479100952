import { createContext, useContext } from "react";

import { Toggle } from "@accurx/navigation";

const initialState = {
    leftPane: { isOpen: false, toggle: () => null, setIsOpen: () => null },
    rightPane: { isOpen: false, toggle: () => null, setIsOpen: () => null },
};

export const LayoutContext = createContext<{
    leftPane: Toggle;
    rightPane: Toggle;
}>(initialState);

export const useLayout = () => useContext(LayoutContext);
