import React, { ReactNode, useEffect, useRef } from "react";

import { useIsOnScreen } from "shared/useIsOnScreen";

type AutoScrollProps = {
    /**
     * - when true, it scrolls the element automatically into view if not in view already
     * - when false it does nothing
     */
    active: boolean;
    children: ReactNode;
};

/**
 * A wrapper component that allows the contents to be scrolled into view
 */
export const AutoScroll = ({
    children,
    active,
}: AutoScrollProps): JSX.Element => {
    // Hold a ref of the DOM element.
    const nodeRef = useRef<HTMLDivElement>(null);

    // Setup an observer to know if the element is visible on screen and store
    // it in a ref because we'll need to know the current state inside effects
    // without retriggering the effect when the component moves on and off
    // screen.
    const isOnScreen = useIsOnScreen(nodeRef);
    const isOnScreenRef = useRef(isOnScreen);
    isOnScreenRef.current = isOnScreen;

    // This flag is used in cases where we're active but need to wait for the
    // layout to be calculated.
    const isWaitingForLayoutRef = useRef(false);

    // EFFECT A: Observes the active state. If the component becomes active and
    // is not visible in the DOM we scroll it into view. A subtlety here is that
    // on first mount we may be active but won't yet know if the DOM node is
    // visible on the screen. In that case we set isWaitingForLayoutRef to true
    // and EFFECT B will take over once the layout has been calculated.
    useEffect(() => {
        if (!active) {
            return;
        }

        // If the layout hasn't been calculated set a flag and EFFECT B will
        // take over.
        if (isOnScreenRef.current === null) {
            isWaitingForLayoutRef.current = true;
            return;
        }

        // If we're already mounted, have just become active and the node is not
        // visible on screen then scroll it into view.
        if (isOnScreenRef.current === false) {
            nodeRef.current?.scrollIntoView();
        }
    }, [active]);

    // EFFECT B: If we mounted in an active state EFFECT A will have set the
    // isWaitingForNodeRef flag. This effect will run when the layout has been
    // calculated and scroll the element into view if necessary.
    useEffect(() => {
        if (isWaitingForLayoutRef.current && isOnScreen === false) {
            nodeRef.current?.scrollIntoView();
            isWaitingForLayoutRef.current = false;
        }
    }, [isOnScreen]);

    return <div ref={nodeRef}>{children}</div>;
};
