import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import {
    FIRST_DATE_WITH_DATA_DDMMYYYY,
    MAX_NUMBER_OF_DAYS,
} from "./FragmentsDashboard.const";
import { ErrorKey, FormState } from "./FragmentsDashboard.types";

export const todaysDateMinus90DaysDDMMYYYY = () => {
    return DateHelpers.getAndFormatDateForDaysBeforeToday(
        90,
        DateFormatOptions.DATE_SHORT_WITH_SLASH,
    );
};

export const initialDateDDMMYYYY = () => {
    const firstDateWithDataIsoString = getISOStringFromDDMMYYYY(
        FIRST_DATE_WITH_DATA_DDMMYYYY,
    );
    const maxRangePriorToTodayIsoString =
        DateHelpers.getAndFormatDateForDaysBeforeToday(
            MAX_NUMBER_OF_DAYS,
            DateFormatOptions.ISO_DATE_STRING,
        );
    /* if "Today - 90 days" is before the First date with data, return the first date with data) */
    if (
        DateHelpers.isBefore(
            maxRangePriorToTodayIsoString,
            firstDateWithDataIsoString,
        )
    ) {
        return FIRST_DATE_WITH_DATA_DDMMYYYY;
    } else {
        return DateHelpers.getAndFormatDateForDaysBeforeToday(
            MAX_NUMBER_OF_DAYS,
            DateFormatOptions.DATE_SHORT_WITH_SLASH,
        );
    }
};

export const todaysDateISOString = () => new Date(Date.now()).toISOString();
export const todaysDateDDMMYYYY = () =>
    DateHelpers.formatDate(
        todaysDateISOString(),
        DateFormatOptions.DATE_SHORT_WITH_SLASH,
    );

export const initialStartDate = initialDateDDMMYYYY;
export const initialEndDate = todaysDateDDMMYYYY;

// Input format: DD/MM/YYYY output format: ISO date
export const getISOStringFromDDMMYYYY = (ddmmyyyyString: string) => {
    const doNotConvertUtc =
        true; /* Do not convert UTC so that we always get returned the midnight value rather than 23:00 from day before */
    return DateHelpers.getDateISOStringFromAnotherStringFormat(
        ddmmyyyyString,
        [DateFormatOptions.DATE_SHORT_WITH_SLASH],
        doNotConvertUtc,
    );
};

// allows DD from 01 to 31, allows MM from 1 to 12, YYYY allows any year
export const dateRegexDDMMYYYY =
    /^(3[01]|[12][0-9]|0?[1-9])(\/)(1[0-2]|0?[1-9])(\/)[0-9]{4}$/;

export const validate = (
    formState: FormState,
): { isValid: boolean; errorMessage: string; errorKey: ErrorKey | null } => {
    const [startDate, endDate] = [
        formState.startDate.trim(),
        formState.endDate.trim(),
    ];

    if (
        !dateRegexDDMMYYYY.test(startDate) ||
        !dateRegexDDMMYYYY.test(endDate)
    ) {
        return {
            isValid: false,
            errorMessage: "Please enter dates in format 23/08/2023",
            errorKey: "generic",
        };
    }
    if (
        DateHelpers.isAfter(
            getISOStringFromDDMMYYYY(startDate),
            getISOStringFromDDMMYYYY(endDate),
        )
    ) {
        return {
            isValid: false,
            errorMessage: "Start date needs to be before end date",
            errorKey: "generic",
        };
    }
    if (
        DateHelpers.isBefore(
            getISOStringFromDDMMYYYY(startDate),
            getISOStringFromDDMMYYYY(FIRST_DATE_WITH_DATA_DDMMYYYY),
        )
    ) {
        return {
            isValid: false,
            errorMessage: `Start date needs to be on or after the first date we hold data: ${FIRST_DATE_WITH_DATA_DDMMYYYY}`,
            errorKey: "startDate",
        };
    }
    if (
        DateHelpers.isAfter(
            getISOStringFromDDMMYYYY(startDate),
            todaysDateISOString(),
        )
    ) {
        return {
            isValid: false,
            errorMessage: `Start date is in the future - latest date possible is today, ${todaysDateDDMMYYYY()}`,
            errorKey: "startDate",
        };
    }
    if (DateHelpers.isAfterToday(getISOStringFromDDMMYYYY(endDate))) {
        return {
            isValid: false,
            errorMessage: `End date is in the future - latest date possible is today, ${todaysDateDDMMYYYY()}`,
            errorKey: "endDate",
        };
    }
    if (
        DateHelpers.getNumberOfDaysBetweenTwoDates(
            getISOStringFromDDMMYYYY(startDate),
            getISOStringFromDDMMYYYY(endDate),
        ) > MAX_NUMBER_OF_DAYS
    ) {
        return {
            isValid: false,
            errorMessage: "Maximum date range is 90 days",
            errorKey: "generic",
        };
    }
    return { isValid: true, errorMessage: "", errorKey: null };
};
