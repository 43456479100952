import { ReactNode } from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

import { Container } from "app/layout/Container";
import { Navbar } from "app/organisations/shared/Navbar";

import { PORTAL_ID_NAV_SUBMENU } from "./NavSubMenuComponent";

const Spacer = styled.div`
    height: ${Tokens.SIZES[3]};
`;

export const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <Navbar />
            <div id={PORTAL_ID_NAV_SUBMENU}></div>
            <Spacer />
            <Container>{children}</Container>
        </>
    );
};
