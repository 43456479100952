import { AppointmentReminder } from "@accurx/api/appointment";
import { RemindersList } from "@accurx/appointment-reminders";
import { Feedback, Flex } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { ExistingAppointmentReminderProps } from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_CHAIN } from "shared/Routes";

import { selectAppointmentReminder } from "./AppointmentReminder.actions";

export const AppointmentReminderHomePanel = () => {
    useAccurxWebTitle("Manage appointment reminders");

    const dispatch = useDispatch();
    const history = useHistory();

    const principalSystemsLast7Days = useSelector(
        ({ practices }: ApplicationState) =>
            practices.principalSystemsLast7Days,
        shallowEqual,
    );

    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices?.selectedPractice,
        shallowEqual,
    );

    const practiceName = useSelector(
        ({ practices }: ApplicationState) =>
            practices.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name,
        shallowEqual,
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const handleCreateClicked = (): void => {
        ChainAnalyticsTracker.trackSetupAppointmentReminderClicked(
            analyticsLoggedInProps,
        );
        history.push(
            generatePath(ROUTES_CHAIN.practicesAppointmentReminderCompose, {
                orgId: practiceId,
            }),
        );
    };

    const isSystmOnePractice = principalSystemsLast7Days.includes("SystmOne");

    const handleSelectEdit = (reminder: AppointmentReminder): void => {
        const analyticsProps: ExistingAppointmentReminderProps = {
            ...analyticsLoggedInProps,
            appointmentReminderId: reminder.id.toString(),
        };
        ChainAnalyticsTracker.trackEditAppointmentReminderClicked(
            analyticsProps,
        );
        dispatch(selectAppointmentReminder(reminder));
        history.push(
            generatePath(ROUTES_CHAIN.practicesAppointmentReminderCompose, {
                orgId: practiceId,
            }),
        );
    };

    return (
        <Flex flexDirection="column" gap="2">
            {isSystmOnePractice && (
                <Feedback
                    props={{ "data-testid": "systmOneBanner" }}
                    colour="warning"
                    title="We've detected appointments belonging to another practice in your appointment book"
                >
                    <div>
                        There are a few things you need to be aware of when
                        setting up appointment reminders,{" "}
                        <a
                            href="http://support.accurx.com/en/articles/5179382-accurx-appointment-reminders-for-systmone-practices-with-shared-admin"
                            rel="noopener noreferrer"
                        >
                            see our guide
                        </a>
                    </div>
                </Feedback>
            )}
            <RemindersList
                onCreate={handleCreateClicked}
                onEdit={handleSelectEdit}
                workspaceId={parseInt(practiceId, 10)}
                workspaceName={practiceName}
            />
        </Flex>
    );
};
