import { Tokens } from "@accurx/design";
import TextareaAutosize from "react-autosize-textarea";
import styled from "styled-components";

export const TextArea = styled(TextareaAutosize)<{ $hasErrors?: boolean }>`
    outline: none;
    color: ${Tokens.COLOURS.greyscale.night};
    padding: ${Tokens.BUTTON_FORM_FIELDS_PADDING.text.medium};
    background-color: ${(props) =>
        props.$hasErrors
            ? Tokens.COLOURS.primary.red[5]
            : Tokens.COLOURS.greyscale.white};
    border: ${Tokens.SIZES[0.25]} solid
        ${(props) =>
            props.$hasErrors
                ? Tokens.COLOURS.primary.red[75]
                : Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.BORDERS.radius};
    resize: vertical;
    font-size: 1rem;
`;
