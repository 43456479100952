import { useEffect, useRef, useState } from "react";

export function useSticky<T extends HTMLElement>() {
    const ref = useRef<T>(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(
            ([e]) => {
                const isAtTopOfContainer =
                    e.boundingClientRect.top < e.intersectionRect.top;

                setIsSticky(e.intersectionRatio < 1 && isAtTopOfContainer);
            },
            { threshold: [0, 1] },
        );
        observer.observe(ref.current);

        return () => observer.disconnect();
    }, []);

    return { isSticky, ref };
}
