import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalId } from "domains/native/schemas/PatientExternalIdSchema";

export type MutateChangeMedicalRecordPatientPayload = {
    patientExternalIds: PatientExternalId[];
};

export const mutateChangeMedicalRecordPatient = createEndpoint<
    unknown,
    MutateChangeMedicalRecordPatientPayload
>("MutateChangeMedicalRecordPatient", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateChangeMedicalRecordPatient",
            workspaceId: context.workspaceId,
            payload: {
                patientExternalIdentityDto: payload,
            },
        },
    });

    return result;
});
