import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useMatchTicketToPatientMutation } from "@accurx/concierge/hooks/mutations/useMatchTicketToPatientMutation";
import { useUnunmatchTicketFromPatientMutation } from "@accurx/concierge/hooks/mutations/useUnmatchTicketFromPatientMutation";
import { Conversation, PatientExternalId } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";
import { PatientMatchState } from "domains/inbox/util/getPatientMatchState";
import { toast } from "react-toastify";

import { UnmatchPatientModal } from "./UnmatchPatientModal";

type PatientMatchingActionsProps = {
    patientMatchState: NonNullable<PatientMatchState>;
    conversation: Conversation;
    onClickSearchForPatient: () => void;
};

export const PatientMatchingActions = ({
    patientMatchState,
    conversation,
    onClickSearchForPatient,
}: PatientMatchingActionsProps) => {
    const track = useAnalytics();
    const [isUnmatchPatientModalOpen, setIsUnmatchPatientModalOpen] =
        useState(false);
    const matchTicketToPatientMutation = useMatchTicketToPatientMutation();
    const unmatchTicketFromPatientMutation =
        useUnunmatchTicketFromPatientMutation();

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "ConversationActions",
    });

    const handleMatchTicketToPatient = (
        patientExternalIds: PatientExternalId[],
    ) => {
        matchTicketToPatientMutation.mutate(
            {
                conversation,
                patientExternalIds,
            },
            {
                onError: () =>
                    toast(
                        <UI.Feedback
                            title="Failed to match conversation to patient. Please try again."
                            colour="error"
                        />,
                    ),
                onSettled: (_data, error) =>
                    track("ConversationSuggestedMatchConfirm Button Response", {
                        ...trackingFields,
                        hasError: !!error,
                    }),
            },
        );
    };

    const handleUnmatchTicketFromPatient = () => {
        track("ConversationPatientUnmatchConfirm Button Click", trackingFields);

        unmatchTicketFromPatientMutation.mutate(
            { conversation },
            {
                onError: () =>
                    toast(
                        <UI.Feedback
                            title="Failed to unmatch conversation from patient. Please try again."
                            colour="error"
                        />,
                    ),
                onSuccess: () => setIsUnmatchPatientModalOpen(false),
                onSettled: (_data, error) =>
                    track("ConversationPatientUnmatchConfirm Button Response", {
                        ...trackingFields,
                        hasError: !!error,
                    }),
            },
        );
    };

    return (
        <>
            {patientMatchState.type === "NoMatch" && (
                <Pill.PrimaryButton
                    dimension="small"
                    onClick={() => {
                        track(
                            "ConversationPatientMatchSelect Button Click",
                            trackingFields,
                        );

                        onClickSearchForPatient();
                    }}
                >
                    <Pill.Text>Search for patient</Pill.Text>
                </Pill.PrimaryButton>
            )}
            {patientMatchState.type === "Match" && (
                <Pill.SecondaryButton
                    isLoading={unmatchTicketFromPatientMutation.isLoading}
                    aria-label="Unmatch patient from conversation"
                    dimension="small"
                    onClick={() => {
                        track(
                            "ConversationPatientUnmatchSelect Button Click",
                            trackingFields,
                        );

                        setIsUnmatchPatientModalOpen(true);
                    }}
                >
                    <Pill.Text>Unmatch patient</Pill.Text>
                </Pill.SecondaryButton>
            )}
            {patientMatchState.type === "SuggestedMatch" && (
                <UI.Flex justifyContent="space-between" gap="1">
                    <Pill.PrimaryButton
                        isLoading={matchTicketToPatientMutation.isLoading}
                        dimension="small"
                        aria-label="Confirm patient and match to conversation"
                        onClick={() => {
                            track(
                                "ConversationSuggestedMatchConfirm Button Click",
                                trackingFields,
                            );
                            handleMatchTicketToPatient(
                                patientMatchState.suggestedMatch.externalIds,
                            );
                        }}
                    >
                        <Pill.Text>Confirm patient</Pill.Text>
                    </Pill.PrimaryButton>
                    <WithTooltip
                        content={
                            <UI.Text skinny variant="note" colour="white">
                                Match to another patient
                            </UI.Text>
                        }
                    >
                        <Pill.SecondaryButton
                            dimension="small"
                            aria-label="Match to another patient"
                            onClick={() => {
                                track(
                                    "ConversationSuggestedPatientChangeSelect Button Click",
                                    trackingFields,
                                );

                                onClickSearchForPatient();
                            }}
                        >
                            <Pill.Text>Change</Pill.Text>
                        </Pill.SecondaryButton>
                    </WithTooltip>
                </UI.Flex>
            )}
            <UnmatchPatientModal
                isOpen={isUnmatchPatientModalOpen}
                onClose={() => setIsUnmatchPatientModalOpen(false)}
                onConfirmUnmatch={handleUnmatchTicketFromPatient}
                isLoading={unmatchTicketFromPatientMutation.isLoading}
            />
        </>
    );
};
