import { Tokens } from "@accurx/design";
import * as UI from "@accurx/design";
import styled from "styled-components";

import { StyledPillProps, getButtonBorder, pillStyles } from "../../styles";

const {
    COLOURS: { greyscale, primary },
} = Tokens;

export const StyledDropdown = styled.button<StyledPillProps>`
    ${pillStyles}
    max-width: 100%;

    box-shadow: ${getButtonBorder(greyscale.silver)};

    &:hover:not(:focus) {
        box-shadow: ${getButtonBorder(primary.blue[100])};

        svg path {
            fill: ${Tokens.COLOURS.primary.blue[100]};
        }
    }

    border: 1px solid transparent;

    &:focus,
    &:active {
        ${UI.focusBorderStyles};
        background: ${Tokens.COLOURS.primary.blue[5]};
    }
`;

export const StyledDropdownContent = styled(UI.Flex)`
    max-width: 100%;
`;
