import React from "react";

import * as UI from "@accurx/design";
import { useSticky } from "domains/inbox/hooks/util/useSticky";

import { StyledBadge, StyledDate, StyledList } from "./ThreadList.styles";

export type ThreadListProps = React.AriaAttributes & {
    date: string;
    children: React.ReactNode;
};

/**
 * This is a copy of < UI.Thread.List /> to use our own sticky header
 */
export const ThreadList = ({
    date,
    children,
    ...ariaProps
}: ThreadListProps) => {
    const { ref, isSticky } = useSticky<HTMLDivElement>();

    return (
        <div>
            <StyledDate ref={ref} isSticky={isSticky}>
                <StyledBadge isSticky={isSticky}>
                    <UI.Text skinny variant="note" as="span" colour="night">
                        {date}
                    </UI.Text>
                </StyledBadge>
            </StyledDate>
            <StyledList role="list" tabIndex={0} {...ariaProps}>
                {children}
            </StyledList>
        </div>
    );
};
