import { Button, Ds, Flex, Text } from "@accurx/design";
import { format } from "date-fns";

type ScheduledTimeHasPassedModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSendMessage: () => void;
    date: Date;
};

export const ScheduledTimeHasPassedModal = ({
    isOpen,
    onClose,
    onSendMessage,
    date,
}: ScheduledTimeHasPassedModalProps) => {
    const time = format(date, "h:mmaaa");

    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        Do you want to send this message now?
                    </Ds.Modal.Title>
                    <Text>{`The time this message was scheduled for (${time}) has passed.`}</Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            onClick={onClose}
                            text="Cancel"
                            theme="secondary"
                        />
                        <Button onClick={onSendMessage} text="Yes, send now" />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
