import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

import { NavSubMenuBackButton } from "app/navbar/NavSubMenuComponent";

import { StyledBatchMessageTrustPageLayout } from "./PageLayout.styles";

export const Wrapper = styled(Flex).attrs({
    flexDirection: "column",
})`
    flex: 1;
    height: 100%;
`;

export const BackButton = styled(NavSubMenuBackButton)`
    padding-left: ${Tokens.SIZES[3]};
    padding-right: ${Tokens.SIZES[3]};
`;

export const Main = styled(StyledBatchMessageTrustPageLayout)`
    flex: 1;
    overflow-y: scroll;
`;

export const Footer = styled(Flex).attrs({
    justifyContent: "flex-end",
})`
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: 0px -2px 4px rgba(68, 72, 126, 0.2);
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};

    // For Safari, otherwise doesn't show the shadow
    position: relative;
`;

export const StyledAddPatientsStatusPage = {
    Footer,
    Wrapper,
    Main,
    BackButton,
};
