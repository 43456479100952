import { ComponentProps } from "react";

import * as UI from "@accurx/design";

import { StyledContent, StyledUITooltip } from "./Tooltip.styles";

type RootProps = ComponentProps<typeof UI.Tooltip> & {
    $withEllipsis?: boolean;
};
const Root = ({
    children,
    delayDuration = 0,
    open,
    $withEllipsis = false,
    ...props
}: RootProps) => {
    return (
        <UI.Tooltip delayDuration={delayDuration} open={open} {...props}>
            <StyledUITooltip $withEllipsis={$withEllipsis}>
                {children}
            </StyledUITooltip>
        </UI.Tooltip>
    );
};

type TriggerProps = ComponentProps<typeof UI.TooltipTrigger>;
const Trigger = ({ children, ...props }: TriggerProps) => {
    return (
        <UI.TooltipTrigger asChild {...props}>
            {children}
        </UI.TooltipTrigger>
    );
};

type ContentProps = ComponentProps<typeof UI.TooltipContent> & {
    $colour?: "night" | "white";
};
const Content = ({ children, $colour = "night", ...props }: ContentProps) => {
    return (
        <StyledContent
            sideOffset={4}
            onPointerDownOutside={(e) => e.preventDefault()}
            hideWhenDetached
            $colour={$colour}
            {...props}
        >
            {children}
        </StyledContent>
    );
};

export const Tooltip = {
    Root,
    Trigger,
    Content,
};
