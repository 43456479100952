import { useState } from "react";

import * as UI from "@accurx/design";
import { Checkbox, Pill } from "@accurx/inbox-design-library";

export type SaveToRecordButtonProps = {
    canIncludeAttachments?: boolean;
    onClick: (arg: { includeAttachments: boolean }) => void;
    isLoading: boolean;
    hasFailed: boolean;
};

export const SaveToRecordButton = ({
    canIncludeAttachments = false,
    onClick,
    isLoading,
    hasFailed,
}: SaveToRecordButtonProps) => {
    const [includeAttachments, setIncludeAttachments] = useState(true);

    return (
        <UI.Flex flexDirection="column" alignItems="flex-start" gap="1.5">
            {canIncludeAttachments && (
                <UI.Item>
                    <Checkbox
                        id="includeAttachments"
                        text="Include attachments"
                        checked={includeAttachments}
                        onCheckChange={(value) => {
                            setIncludeAttachments(value === true);
                        }}
                    >
                        <UI.Text skinny variant="preview">
                            Include attachments
                        </UI.Text>
                    </Checkbox>
                </UI.Item>
            )}
            <UI.Item>
                <UI.Flex flexDirection="column" gap="0.5">
                    <UI.Item>
                        <Pill.SecondaryButton
                            dimension="small"
                            onClick={() =>
                                onClick({
                                    includeAttachments:
                                        includeAttachments &&
                                        canIncludeAttachments,
                                })
                            }
                            disabled={isLoading}
                        >
                            <Pill.Icon
                                isLoading={isLoading}
                                name="Save"
                                colour="blue"
                                size={3}
                            />
                            <Pill.Text>Save to record</Pill.Text>
                        </Pill.SecondaryButton>
                    </UI.Item>
                    {hasFailed && !isLoading && (
                        <UI.Flex alignItems="center" gap="0.5">
                            <UI.Icon
                                name="Error"
                                theme="Fill"
                                size={3}
                                colour="red"
                            />
                            <UI.Text skinny colour="zinc">
                                Failed to save to record
                            </UI.Text>
                        </UI.Flex>
                    )}
                </UI.Flex>
            </UI.Item>
        </UI.Flex>
    );
};
