import { PatientDemographics } from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    useConciergeDispatch,
    useConciergeSelector,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { useMutation } from "domains/concierge/internal/util/useMutation";
import { mapPatientDemographicsToPatientSummary } from "domains/concierge/util/mapPatientDemographicsToPatientSummary";

export const useBackgroundUpdatePatientDemographicsMutation = () => {
    const dispatch = useConciergeDispatch();

    const patients = useConciergeSelector((state) => state.patients.items);

    return useMutation(
        "BackgroundUpdatePatientDemographics",
        async ({
            patientDemographics,
            patientInternalId,
        }: {
            patientDemographics: PatientDemographics;
            patientInternalId: string | null;
            //  - this is not an async function but `useMutation` expects functions to be async
            // eslint-disable-next-line @typescript-eslint/require-await
        }) => {
            if (!patientInternalId) {
                Log.error(
                    "Received a patient demographics background update that doesn't match a known patient",
                    { tags: { product: "Inbox" } },
                );
                return;
            }

            // we take a patient from a store by internal ID, received via SubscribePatientDemographics subscription
            const patientSummary = patients[patientInternalId];

            // just in case we don't have that patient - lets log a warning
            if (!patientSummary) {
                Log.warn(
                    "Attempt to update patient demographics for a patient that doesn't exist in the patient summary store",
                    { tags: { product: "Inbox" } },
                );
                return;
            }

            const updatedPatient = mapPatientDemographicsToPatientSummary({
                patientDemographics,
                patientSummary,
            });

            dispatch(
                actions.patients.demographicsUpdates({
                    patient: {
                        ...updatedPatient,
                        demographicsLastRefreshed: new Date().toISOString(),
                    },
                }),
            );
        },
    );
};
