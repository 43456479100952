import { ConversationGroupSortOptions } from "domains/concierge/internal/types/ConversationGroupSortOptions";
import { conversationOrdering } from "domains/concierge/internal/util/conversationOrdering";
import { Conversation } from "domains/concierge/types";
import orderBy from "lodash/orderBy";

export const sortConversationsForGroup = (
    conversations: Conversation[],
    sortOptions: ConversationGroupSortOptions,
): Conversation[] => {
    const [comparables, orders] = conversationOrdering(sortOptions);
    return orderBy(conversations, comparables, orders);
};
