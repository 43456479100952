import { GeneratedColumn, Icon, Text } from "@accurx/design";

import { StyledTable } from "./ResponsesTable.styles";
import { COLUMN_SORT_DIRECTIONS } from "./constants";
import { SortOptions } from "./types";

const getIconSortDirection = (
    sortDirection: SortOptions["sortDirection"],
): "up" | "down" => {
    return sortDirection === "asc" ? "up" : "down";
};

/**
 * Returns the value for the aria-sort attribute to be added on the heading
 */
const getAriaSortValue = ({
    isSorted,
    sortDirection,
}: {
    isSorted: boolean;
    sortDirection: SortOptions["sortDirection"];
}): "ascending" | "descending" | undefined => {
    if (isSorted) {
        return sortDirection === "asc" ? "ascending" : "descending";
    }
    return undefined;
};

export const SortableTableHeading = ({
    tableColumn,
    currentSortDetails,
    onSortApplied,
}: {
    /** Column definition & sort functionality */
    tableColumn: GeneratedColumn;
    /** Prop to update the tracking of what's being sorted in what direction */
    onSortApplied: (value: SortOptions) => void;
    /** Prop to check current sorting in order to update it on click */
    currentSortDetails: SortOptions;
}) => {
    const isSorted = currentSortDetails.sortColumnId === tableColumn.id;

    const handleSortButtonClick = () => {
        // User changing direction of the column sorting
        if (isSorted) {
            const nextSortDirection =
                currentSortDetails.sortDirection === "asc" ? "desc" : "asc";

            const columnNextSortDirection =
                COLUMN_SORT_DIRECTIONS[tableColumn.id][nextSortDirection];

            onSortApplied({
                sortColumnId: currentSortDetails.sortColumnId,
                sortDirection: nextSortDirection,
            });
            tableColumn.sort(columnNextSortDirection);

            // User sorting by a different column that the currently sorted one
        } else {
            const columnSortSettings = COLUMN_SORT_DIRECTIONS[tableColumn.id];
            const columnInitialSortDirection =
                columnSortSettings[columnSortSettings.initial];
            onSortApplied({
                sortColumnId: tableColumn.id,
                sortDirection: columnSortSettings.initial,
            });
            tableColumn.sort(columnInitialSortDirection);
        }
    };

    return (
        <StyledTable.SortableHeader
            scope="col"
            aria-sort={getAriaSortValue({
                isSorted,
                sortDirection: currentSortDetails.sortDirection,
            })}
        >
            <StyledTable.SortButton onClick={handleSortButtonClick}>
                <Text as="span" variant="label" skinny>
                    {tableColumn.header}
                </Text>
                <Icon
                    name={"ArrowTail"}
                    size={2}
                    colour={isSorted ? "blue" : "zinc"}
                    rotation={getIconSortDirection(
                        isSorted
                            ? currentSortDetails.sortDirection
                            : COLUMN_SORT_DIRECTIONS[tableColumn.id].initial,
                    )}
                />
            </StyledTable.SortButton>
        </StyledTable.SortableHeader>
    );
};
