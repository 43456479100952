import { Tokens } from "@accurx/design";
import { DropdownToggle } from "reactstrap";
import styled from "styled-components";

export const StyledDropdownToggle = styled(DropdownToggle)`
    border: ${Tokens.BORDERS.normal.silver};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.white};
`;
