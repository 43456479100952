import { getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

import { PatientThreadSummaryDetails } from "shared/concierge/conversations/tickets/types/dto.types";

const ENDPOINTS = {
    initialSummaryDetails: "/api/conversation/web/initialsummarydetails",
} as const;

/**
 * currently the active users and teams for a workspace are fetched
 * from the Tickets API at /conversations/web/initialsummarydetails
 *
 * Hopefully soon we will have a specific API for users and teams.
 */
export async function fetchInitialSummaryDetails(
    workspaceId: number,
): Promise<PatientThreadSummaryDetails> {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.initialSummaryDetails,
            query: `?organisationId=${workspaceId}`,
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to load initial summary for workspace ${workspaceId}`,
        );
    }

    return response.result;
}
