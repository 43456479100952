import {
    SelfBookForMessagePatientRequest,
    SelfBookRequestType,
} from "@accurx/api/patient-messaging";
import { SelfBook } from "domains/concierge/types/messagePatient.types";

export const mapSelfBookToSelfBookRequestType = (
    selfBook: SelfBook | null,
): SelfBookForMessagePatientRequest | null => {
    return selfBook
        ? { ...selfBook, type: SelfBookRequestType[selfBook.type] }
        : null;
};
