import { useCurrentUser } from "@accurx/auth";
import { ComposeProvider } from "@accurx/compose";
import { ReplyContents } from "domains/inbox/components/ConversationView/components/Reply/ReplyContents";
import { usePatientContactDetails } from "domains/inbox/hooks/util";
import { useCanUseQuestionnaires } from "domains/inbox/hooks/util/useCanUseQuestionnaires";
import { getDefaultContactDetail } from "domains/inbox/hooks/util/useSetDefaultContactDetail/getDefaultContactDetail";
import { generatePatientGreeting } from "domains/inbox/util/greeting";
import { generateSignature } from "domains/inbox/util/signature";

import { MessageActionsBarLoading } from "../MessageActionsBar/MessageActionsBarLoading";
import { ReplyProps } from "./Reply.types";

/**
 * This is determined and validated by backend
 * and needs to be kept in sync with
 * MessagePatientDocumentAttachConstants.cs
 */
const MAX_ATTACHMENT_COUNT = 1;

export const Reply = ({
    patient,
    conversation,
    toggleReply,
    selectedMessageAction,
    setSentNoteStatus,
}: ReplyProps) => {
    const { user } = useCurrentUser();
    const canUseQuestionnaires = useCanUseQuestionnaires();

    const patientGreeting = generatePatientGreeting(patient);
    const userSignature = generateSignature(user);
    const { status, data: patientContactDetails } = usePatientContactDetails({
        patient,
        conversation,
    });

    if (status === "loading") {
        return <MessageActionsBarLoading />;
    }

    return (
        <ComposeProvider
            settings={{
                // In case there's an error when fetching demographics we start up with no default details
                contactDetails:
                    getDefaultContactDetail(patientContactDetails) ?? undefined,
                greeting: patientGreeting,
                editableBody: "",
                editableSignature: userSignature,
                maxAttachmentCount: MAX_ATTACHMENT_COUNT,
                canUseQuestionnaires,
                conversationParticipant: "WithPatient",
            }}
        >
            <ReplyContents
                patient={patient}
                conversation={conversation}
                contactDetails={patientContactDetails}
                close={toggleReply}
                selectedMessageAction={selectedMessageAction}
                setSentNoteStatus={setSentNoteStatus}
            />
        </ComposeProvider>
    );
};
