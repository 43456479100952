import React, { ChangeEvent } from "react";

import { Icon, Text } from "@accurx/design";

import { useAppSelector } from "store/hooks";

import {
    StyledTextAreaGroup,
    StyledTextareaAutosize,
} from "./MessageInput.styles";

export interface MessageInputProps {
    editableMessageBody: string;
    error: string;
    validText: string;
    handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    nonEditableMessageContent?: string;
}

export const MessageInput = ({
    editableMessageBody,
    error,
    validText,
    handleChange,
    nonEditableMessageContent = "",
}: MessageInputProps) => {
    const isSending = useAppSelector(
        ({ messagePatient }) => messagePatient.isMessageSending,
    );

    return (
        <div className="form-group mb-0 font-weight-bold clearfix">
            <Text
                variant="label"
                as="label"
                props={{ className: "col-form-label" }}
            >
                Message text
            </Text>
            <StyledTextAreaGroup>
                <StyledTextareaAutosize
                    required
                    autoFocus
                    disabled={isSending}
                    value={editableMessageBody}
                    onChange={handleChange}
                    placeholder="Please enter a message"
                    data-testid="message-body"
                />
                <StyledTextareaAutosize
                    disabled
                    value={nonEditableMessageContent}
                />
            </StyledTextAreaGroup>
            {!!error && (
                <div className="d-flex align-items-center mt-2 float-right">
                    <div style={{ lineHeight: "normal" }}>
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                    </div>
                    <div>
                        <Text props={{ className: "m-0" }}>{error}</Text>
                    </div>
                </div>
            )}
            {!error && validText && (
                <Text
                    variant="note"
                    colour="zinc"
                    props={{ className: "mt-2 float-right" }}
                >
                    {validText}
                </Text>
            )}
        </div>
    );
};
