import { useMemo } from "react";

import { PatientSingleResponseItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type PatientResponseProps = {
    conversationItem: PatientSingleResponseItem;
};

export const PatientResponse = ({ conversationItem }: PatientResponseProps) => {
    const hasPatientResponded = conversationItem.sections.length > 0;

    const summary = useMemo(() => {
        if (hasPatientResponded) {
            const c = conversationItem.sections.find((section) => {
                return section.responses.some((response) => {
                    return response.answer && response.answer;
                });
            });

            const answer =
                c && c.responses[0].answer ? c.responses[0].answer : null;

            if (answer === null) {
                Log.error(
                    "Patient responded, but no answers were found in patient response object",
                );
                return "No response found";
            }

            return answer;
        }
        return null;
    }, [conversationItem.sections, hasPatientResponded]);

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {hasPatientResponded ? "Patient" : "Accurx"}:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {hasPatientResponded ? summary : "Patient did not respond"}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
