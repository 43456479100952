import moment from "moment";

import {
    BatchDeclineSettings,
    BatchType,
    SendAt,
    SendMessageParameters,
} from "app/batchMessage/gp/BatchMessage.types";

const setOrdinal = (date: number) => {
    const rules = new Intl.PluralRules("en-GB", { type: "ordinal" });
    const ordinals = {
        one: "st",
        two: "nd",
        few: "rd",
        many: "th",
        zero: "th",
        other: "th",
    };

    return ordinals[rules.select(+date)];
};

export const formatSendDate = (sendAt: SendAt) => {
    if (sendAt) {
        const { day, month, year, hour } = sendAt;
        const d = moment(`${year}-${month}-${day} ${hour}`, [
            "YYYY-MM-DD h",
            "YYYY-M-D h",
        ]);
        return d.format(`ha, D[${setOrdinal(parseInt(day))}] MMM YYYY`);
    }
    return "";
};

export const prepareSendRequest = ({
    orgId,
    batchId,
    messageTemplate,
    snomedCode,
    batchType,
    selectedFloreyId,
    uploadedFileAttachmentId,
    sendAt,
    staggerOverDays,
    assignedUserGroupId,
    selectedFloreySaveToRecord,
    shouldSaveBatchToRecord,
    batchSelfBookEnabled,
    selectedSlotType,
    isDeclineSettingsAvailable,
    declineSettings,
    selfBookTimeoutDays,
    selectedTemplateId,
    isFileAttachmentFromTemplate,
}: {
    orgId: string;
    batchId: string;
    messageTemplate: string;
    snomedCode: string | null;
    batchType: BatchType;
    selectedFloreyId: string;
    uploadedFileAttachmentId?: string;
    sendAt: SendAt;
    staggerOverDays: number;
    assignedUserGroupId: string | null;
    selectedFloreySaveToRecord?: boolean;
    shouldSaveBatchToRecord?: boolean;
    batchSelfBookEnabled?: boolean;
    selectedSlotType: {
        type: string;
    };
    isDeclineSettingsAvailable?: boolean;
    declineSettings?: BatchDeclineSettings;
    selfBookTimeoutDays?: number;
    selectedTemplateId?: number;
    isFileAttachmentFromTemplate?: boolean;
}) => {
    let floreyId = null;
    if (
        (batchType === BatchType.FLOREY || batchType === BatchType.COPD) &&
        selectedFloreyId !== ""
    ) {
        floreyId = selectedFloreyId;
    }

    const getMessageTemplateAttachments = () => {
        return selectedTemplateId
            ? {
                  templateId: selectedTemplateId,
                  attachmentIds:
                      isFileAttachmentFromTemplate && uploadedFileAttachmentId
                          ? [parseInt(uploadedFileAttachmentId, 10)]
                          : [],
              }
            : undefined;
    };
    const getDocumentId = () => {
        if (isFileAttachmentFromTemplate) return null;
        return uploadedFileAttachmentId && uploadedFileAttachmentId !== ""
            ? uploadedFileAttachmentId
            : null;
    };
    const request: SendMessageParameters = {
        practiceId: orgId,
        batchId,
        messageTemplate,
        snomedCode,
        floreyId,
        sendAt,
        staggerOverDays,
        assignedUserGroupId,
        shouldSaveBatchToRecord,
        messageTemplateAttachments: getMessageTemplateAttachments(),
        documentId: getDocumentId(),
    };

    const isFloreyWithAutomaticSaveToRecord =
        batchType === BatchType.FLOREY &&
        selectedFloreyId !== "" &&
        selectedFloreySaveToRecord;
    if (isFloreyWithAutomaticSaveToRecord) {
        request.canSaveToRecord = selectedFloreySaveToRecord;
    }

    const isSelfBookWithSelectedSlot =
        batchSelfBookEnabled &&
        batchType === BatchType.SELFBOOK &&
        selectedSlotType.type;
    if (isSelfBookWithSelectedSlot) {
        request.slotType = selectedSlotType.type;
        request.selfBookTimeoutDays = selfBookTimeoutDays;
    }

    if (isDeclineSettingsAvailable) {
        request.declineSettings = declineSettings;
    }

    return request;
};
