import { ReactElement, ReactNode } from "react";

import { Button, Card, Flex, Text } from "@accurx/design";
import { CardProps } from "@accurx/design/dist/components/Card/Card";
import { useHistory } from "react-router-dom";

import { TwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer";

import { ClinicianConversationLoginPageWrapper } from "../LogIn/ClinicianConversationLoginPage";
import { useIsClinicianConversationOnboarding } from "./hooks";
import {
    StyledFixedContainerWithShadow,
    StyledPageWrapper,
} from "./layout-components.styles";

export const OnboardingPageWrapper = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const isBlurryChatVariant = useIsClinicianConversationOnboarding();

    return isBlurryChatVariant ? (
        <ClinicianConversationLoginPageWrapper compensateForLayoutMargins>
            {children}
        </ClinicianConversationLoginPageWrapper>
    ) : (
        <StyledPageWrapper>{children}</StyledPageWrapper>
    );
};

export const OnboardingContainer = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const isBlurryChatVariant = useIsClinicianConversationOnboarding();

    return isBlurryChatVariant ? (
        <StyledFixedContainerWithShadow>
            {children}
        </StyledFixedContainerWithShadow>
    ) : (
        <TwoThirdsContainer>{children}</TwoThirdsContainer>
    );
};

export const OnboardingCard = ({
    header,
    children,
    ...props
}: CardProps): ReactElement => {
    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    return (
        <Card {...props}>
            <Flex flexDirection="column" gap="2">
                {header ? (
                    <Text
                        // Card title serves as H1 due to lack of a page header
                        // in the blurry chat layout
                        as={isBlurryChatLayout ? "h1" : "h2"}
                        variant="subtitle"
                        skinny
                    >
                        {header}
                    </Text>
                ) : undefined}
                {children}
            </Flex>
        </Card>
    );
};

export const CardFooter = ({
    isLoading,
}: {
    isLoading?: boolean;
}): ReactElement => {
    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const history = useHistory<{ showBackButton: boolean }>();
    const showBackButton = history.location.state?.showBackButton ?? false;

    const buttonText = isBlurryChatLayout
        ? "Continue to conversation"
        : "Submit";

    return (
        <Flex justifyContent="flex-end" gap="2">
            {showBackButton && (
                <Button
                    text="Back"
                    type="button"
                    theme="secondary"
                    onClick={history.goBack}
                />
            )}
            {isLoading ? (
                <Button type="button" text="Submitting..." disabled />
            ) : (
                <Button text={buttonText} type="submit" />
            )}
        </Flex>
    );
};
