import { FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[0.5]};
    padding: ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[3]} 0;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    margin-bottom: ${Tokens.SIZES[2]};
`;

const StyledButtonsWrapper = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1.5]};
    justify-content: end;
`;

const StyledMainContent = styled.div`
    padding: 0 ${Tokens.SIZES[3]} ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};

    background-color: ${Tokens.COLOURS.greyscale.white};
    flex: 1;
    overflow: auto;
`;

const StyledFooter = styled.div`
    border-top: ${Tokens.SIZES[0.25]} solid ${Tokens.COLOURS.greyscale.silver};
    width: 100%;
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }
`;

const StyledPageWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export {
    StyledHeader,
    StyledFormFieldWrapper,
    StyledButtonsWrapper,
    StyledMainContent,
    StyledFooter,
    StyledPageWrapper,
};
