import {
    CollapsibleInfo,
    FormFieldWrapper,
    Option,
    Select,
    Text,
} from "@accurx/design";

type ClinicCodeFilterProps = {
    options: Option[];
    initialOptions: Option[];
    onChange(selected: Option[]): void;
    errors: string[] | undefined;
};

export function ClinicCodeFilter({
    options,
    initialOptions,
    onChange,
    errors,
}: ClinicCodeFilterProps) {
    const label = "Clinic";
    const id = "slot_types";

    return (
        <>
            <FormFieldWrapper
                label={label}
                labelProps={{ htmlFor: id }}
                subLabel="Select all the clinic(s) you’d like to send this reminder for."
                errors={errors}
            >
                <Select
                    id={id}
                    label={label}
                    options={options}
                    initSelectedValues={initialOptions}
                    // Types here are inaccurate, returns array for MULTISELECTALL
                    onChangeHandler={(selected) =>
                        onChange(selected as Option[])
                    }
                    theme="MULTISELECTALL"
                    defaultCopy={{
                        placeholder: "Please select",
                        valueSelectAll: `Select all clinics`,
                    }}
                    error={errors && errors.length > 0}
                    zIndex={2}
                />
            </FormFieldWrapper>
            <CollapsibleInfo title="Why is a clinic greyed out?">
                <Text>
                    If you can’t select a clinic, it's because it has either
                    been used with all appointment types or with one of your
                    currently selected appointment types.
                    {/* TODO: Add a link to the new support article */}
                </Text>
            </CollapsibleInfo>
        </>
    );
}
