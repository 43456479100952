import { useRef, useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { LabelTagItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationItemContent,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { OutcomeRecordingEdit } from "domains/inbox/components/ConversationView/components/OutcomeRecording/OutcomeRecording";
import { getLastConversationLabelTagItem } from "domains/inbox/util/getLastConversationLabelTag";

import { StyledLabelTagNoteContainer } from "./LabelTagNote.styles";
import { LabelTagNoteContent } from "./LabelTagNoteContent";

export const LabelTagNote = ({
    item,
    conversation,
}: ConversationItemComponent<LabelTagItem>) => {
    const textContentRef = useRef<HTMLDivElement>(null);

    const isOutcomeReportingEnabled = useFeatureFlag("OutcomeRecording");

    // If the item is the last in the conversation, it is editable
    const lastTagNoteItemInConversation =
        getLastConversationLabelTagItem(conversation);
    const isTagNoteItemEditable = lastTagNoteItemInConversation?.id === item.id;

    const [isEditing, setIsEditing] = useState(false);

    // Important to check that the tag note is editable,
    // to automatically hide the form to edit the outcome
    // once a new one come in
    if (isEditing && isTagNoteItemEditable) {
        return (
            <ConversationThreadListItem
                $align={getItemAlignment({
                    item,
                })}
            >
                <div data-testid={`conversation-item-${item.id}`}>
                    <UI.Flex flexDirection="column" gap="2">
                        <StyledLabelTagNoteContainer>
                            <ConversationItemContent>
                                <UI.Flex flexDirection="column" gap="3">
                                    <UI.Item>
                                        <LabelTagNoteContent
                                            item={item}
                                            ref={textContentRef}
                                        />
                                    </UI.Item>
                                </UI.Flex>
                            </ConversationItemContent>
                        </StyledLabelTagNoteContainer>
                        <OutcomeRecordingEdit
                            conversation={conversation}
                            onCancelEdit={() => {
                                setIsEditing(false);
                            }}
                        />
                    </UI.Flex>
                </div>
            </ConversationThreadListItem>
        );
    } else {
        return (
            <ConversationThreadListItem
                $align={getItemAlignment({
                    item,
                })}
            >
                <StyledLabelTagNoteContainer
                    data-testid={`conversation-item-${item.id}`}
                >
                    <ConversationItemContent>
                        <UI.Flex flexDirection="column" gap="3">
                            <UI.Item>
                                <LabelTagNoteContent
                                    item={item}
                                    ref={textContentRef}
                                />
                            </UI.Item>
                            <UI.Item>
                                <UI.Flex gap="1">
                                    <SaveItemAction
                                        conversation={conversation}
                                        item={item}
                                        getTextToCopy={() =>
                                            textContentRef.current?.innerText ??
                                            ""
                                        }
                                    />
                                    {isOutcomeReportingEnabled &&
                                        isTagNoteItemEditable && (
                                            <Pill.SecondaryButton
                                                dimension="small"
                                                onClick={() => {
                                                    setIsEditing(true);
                                                }}
                                            >
                                                <Pill.Text>
                                                    Change outcome
                                                </Pill.Text>
                                            </Pill.SecondaryButton>
                                        )}
                                </UI.Flex>
                            </UI.Item>
                        </UI.Flex>
                    </ConversationItemContent>
                </StyledLabelTagNoteContainer>
            </ConversationThreadListItem>
        );
    }
};
