import { Dispatch, SetStateAction } from "react";

import { Checkbox } from "@accurx/design";

import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { isContactable } from "app/patientLists/integratedClinics/shared/isContactable";

import { SelectedAppointments } from "../SelectClinicPatients";
import { isEverySelectableAppointmentSelected } from "./checkboxHelpers";

type SelectAllCheckboxProps = {
    appointments: ClinicAppointment[];
    selectedAppointments: SelectedAppointments;
    setSelectedAppointments: Dispatch<SetStateAction<SelectedAppointments>>;
};

export default function SelectAllCheckbox({
    appointments,
    selectedAppointments,
    setSelectedAppointments,
}: SelectAllCheckboxProps) {
    const isChecked = isEverySelectableAppointmentSelected(
        appointments,
        selectedAppointments,
    );

    return (
        <Checkbox
            id="select-all-patients"
            text="Select all patients"
            theme="bordered"
            checked={isChecked}
            onCheckChange={(isChecked) => {
                if (isChecked) {
                    setSelectedAppointments((previousMap) => {
                        appointments.forEach((appointment) => {
                            if (isContactable(appointment)) {
                                previousMap.set(appointment.id, appointment);
                            }
                        });

                        return new Map(previousMap);
                    });
                } else {
                    setSelectedAppointments(new Map());
                }
            }}
        />
    );
}
