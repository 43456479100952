import isNil from "lodash/isNil";

import { PatientThreadUser } from "shared/concierge/conversations/tickets/types/dto.types";
import { UserSummary } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

/**
 * Maps from a Ticket User to a User Summary
 * @param dto the ticket user
 * @returns mapped version to User Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserToUserSummary = (
    dto: PatientThreadUser,
): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }
    return {
        id: dto.accuRxId,
        displayName: dto.name || dto.email || dto.accuRxId,
    };
};
