import React, { useEffect, useState } from "react";

import {
    SearchForPatientByNhsNumberRequest,
    SearchForPatientResponse,
} from "@accurx/api/portal";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";

import FlemingApi from "api/FlemingApiClient";
import { SearchFormData } from "app/searchForPatient/searchForPatientForm/SearchForPatientByNHSNumber";
import { SearchFormErrors } from "app/searchForPatient/searchForPatientForm/SearchFormConstants";
import { getPatientNhsNumber } from "app/workspaceConversations/utils/patient.utils";
import { PatientHelper } from "shared/PatientHelper";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { PatientTriageSuggestedPatient } from "shared/concierge/conversations/types/item.types";

import {
    ConfirmPatient,
    PatientMatchConfirm,
    PatientMatchConfirmProps,
} from "../PatientMatchConfirm/PatientMatchConfirm";
import {
    PatientMatchSearch,
    PatientMatchSearchProps,
} from "../PatientMatchSearch/PatientMatchSearch";

const ERROR_MESSAGE = "Sorry, something went wrong. Please try again.";

export type FormType = "search-patient" | "confirm-patient";

export type SearchAndConfirmProps = {
    formType: FormType;
    closeQuickView: () => void;
    suggestedMatch: PatientTriageSuggestedPatient | null;
    matchPatientToConversation: (patientToken: string) => Promise<boolean>;
};

export const SearchAndConfirm = ({
    formType: initialFormType,
    closeQuickView,
    suggestedMatch,
    matchPatientToConversation,
}: SearchAndConfirmProps) => {
    const organisationId = useWorkspaceId();
    const [renderFormType, setRenderFormType] =
        useState<FormType>(initialFormType);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [searchServerError, setSearchServerError] = useState<string>("");
    const [confirmError, setConfirmError] = useState<string>("");
    const [searchFormData, setSearchFormData] = useState<SearchFormData>();
    const [confirmPatient, setConfirmPatient] = useState<ConfirmPatient>();

    const onPatientSearch = async (
        patientSearchInfo: SearchForPatientByNhsNumberRequest,
    ) => {
        setIsSearching(true);
        try {
            const res: SearchForPatientResponse =
                await FlemingApi.searchForPatientByNhsNumber(
                    organisationId,
                    patientSearchInfo.nhsNumber,
                    patientSearchInfo.dateOfBirthYear,
                    patientSearchInfo.dateOfBirthMonth,
                    patientSearchInfo.dateOfBirthDay,
                    true,
                );
            if (!PatientHelper.getPatient(res)) {
                if (res.searchedResult?.matchFound === false) {
                    setSearchServerError(SearchFormErrors.NoMatchFound);
                } else {
                    setSearchServerError(SearchFormErrors.Other);
                }
            } else {
                setConfirmPatient({
                    firstName: res.searchedResult?.patient?.firstName || null,
                    familyName: res.searchedResult?.patient?.familyName || null,
                    dateOfBirth:
                        (res.searchedResult?.patient?.dateOfBirth &&
                            DateHelpers.formatDate(
                                res.searchedResult.patient.dateOfBirth,
                                DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
                            )) ||
                        "Unknown",
                    gender: res.searchedResult?.patient?.gender || "Unknown",
                    nhsNumber:
                        res.searchedResult?.patient?.nhsNumber || "Unknown",
                    prefixName: res.searchedResult?.patient?.prefixName || "",
                    patientToken: res.searchedResult?.patientToken,
                });
                setSearchFormData({
                    dobDay: String(patientSearchInfo.dateOfBirthDay),
                    dobMonth: String(patientSearchInfo.dateOfBirthMonth),
                    dobYear: String(patientSearchInfo.dateOfBirthYear),
                    nhsNumber: String(patientSearchInfo.nhsNumber),
                });
                setRenderFormType("confirm-patient");
            }
        } catch {
            setSearchServerError(SearchFormErrors.Other);
        }
        setIsSearching(false);
    };

    const onPatientConfirm = async () => {
        setIsConfirming(true);
        setConfirmError("");
        let patientToken = confirmPatient?.patientToken;
        if (!patientToken && suggestedMatch) {
            const nhsNumber = getPatientNhsNumber(suggestedMatch.externalIds);
            if (
                !nhsNumber ||
                !NhsNumberHelpers.validateNhsNumber(nhsNumber).success
            ) {
                setConfirmError("Invalid NHS number.");
                setIsConfirming(false);
                return;
            }

            const dobDateParts = DateHelpers.getDateParts(
                suggestedMatch.dateOfBirth,
            );
            if (!dobDateParts) {
                setConfirmError("Invalid date of birth.");
                setIsConfirming(false);
                return;
            }
            const {
                d: dateOfBirthDay,
                m: dateOfBirthMonth,
                y: dateOfBirthYear,
            } = dobDateParts;

            try {
                const res = await FlemingApi.searchForPatientByNhsNumber(
                    organisationId,
                    nhsNumber,
                    dateOfBirthYear,
                    dateOfBirthMonth,
                    dateOfBirthDay,
                    false,
                );
                patientToken = res?.searchedResult?.patientToken;
            } catch {
                setConfirmError(ERROR_MESSAGE);
            }
        }
        if (patientToken) {
            const isSuccess = await matchPatientToConversation(patientToken);
            if (!isSuccess) {
                setConfirmError(ERROR_MESSAGE);
                setIsConfirming(false);
                return;
            }
            closeQuickView();
        }
        setIsConfirming(false);
    };

    const resetServerError = () => {
        setSearchServerError("");
    };

    const handleOnCancelOfConfirmPatientType = () => {
        if (initialFormType === "confirm-patient") {
            closeQuickView();
            return;
        }
        setRenderFormType("search-patient");
    };

    const updateConfirmPatient = (
        newSuggestedMatch: PatientTriageSuggestedPatient | null,
    ) => {
        if (!newSuggestedMatch) {
            setConfirmPatient(undefined);
            return;
        }
        setConfirmPatient({
            firstName: newSuggestedMatch.firstName,
            familyName: newSuggestedMatch.familyName || null,
            dateOfBirth: DateHelpers.formatDate(
                newSuggestedMatch.dateOfBirth,
                DateFormatOptions.DATE_SHORT_WITH_HYPHENS,
            ),
            gender: newSuggestedMatch.gender,
            nhsNumber:
                getPatientNhsNumber(newSuggestedMatch.externalIds) || "Unknown",
            prefixName: newSuggestedMatch.prefixName || "",
            patientToken: undefined,
        });
    };

    useEffect(() => {
        updateConfirmPatient(suggestedMatch);
    }, [suggestedMatch]);

    if (renderFormType === "search-patient") {
        const patientSearchProps: PatientMatchSearchProps = {
            onSubmit: onPatientSearch,
            isSearching: isSearching,
            initialValue: searchFormData,
            resetServerError: resetServerError,
            serverError: searchServerError,
            onCancel: closeQuickView,
        };
        return <PatientMatchSearch {...patientSearchProps} />;
    }

    if (renderFormType === "confirm-patient" && confirmPatient) {
        const patientMatchConfirmProps: PatientMatchConfirmProps = {
            isLoading: isConfirming,
            onPatientConfirm: onPatientConfirm,
            onCancel: handleOnCancelOfConfirmPatientType,
            error: confirmError,
            patient: confirmPatient,
            cancelButtonText:
                initialFormType === "search-patient" ? "Back" : "Cancel",
        };
        return <PatientMatchConfirm {...patientMatchConfirmProps} />;
    }

    return null;
};
