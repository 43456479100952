import xor from "lodash/xor";

import { ConversationStatus } from "./types/conversation.types";
import {
    ConversationAnyRule,
    ConversationAssignedToRule,
    ConversationAssignedToValue,
    ConversationAssigneeType,
    ConversationContainsItemRule,
    ConversationGroupRuleset,
    ConversationItemRule,
    ConversationItemSenderRule,
    ConversationRule,
    ConversationStartsWithItemRule,
    ConversationStatusRule,
} from "./types/conversationGroup.types";
import { ConversationItemType } from "./types/item.types";

/**
 * Converts a status string into a conversation status rule.
 */
export const createStatusRule = (
    status: ConversationStatus,
): ConversationStatusRule => {
    return {
        type: "Status",
        value: status,
    };
};

/**
 * Converts an assignee type and ID into a conversation assigned to rule.
 */
export const createAssignedToRule = (
    type: ConversationAssigneeType,
    id: string | null,
): ConversationAssignedToRule => {
    return {
        type: "AssignedTo",
        value: { type, id },
    };
};

/**
 * Converts a list of rules to an Any rule
 */
export const createAnyRule = (
    rules: ConversationRule[],
): ConversationAnyRule => ({
    type: "Any",
    value: rules,
});

/**
 * Converts a sender id to a conversation item inclusion rule.
 */
export const createSentPatientMessageRule = (
    userId: string | null,
): ConversationContainsItemRule => {
    const itemRules: ConversationItemRule[] = [
        {
            type: "ItemType",
            value: ["PatientEmail", "PatientSms", "NhsAppMessage"],
        },
    ];
    if (userId != null) {
        itemRules.push({
            type: "Sender",
            value: { type: "User", id: userId },
        });
    }
    return {
        type: "ContainsItem",
        value: { rules: itemRules },
    };
};

/**
 * Returns true if the ruleset contains an assigned rule
 */
export const isAssignedRuleset = (rule: ConversationGroupRuleset): boolean =>
    rule.rules.some(isAssignedToRule);

/**
 * Returns true if the rule is for conversation status
 */
export const isStatusRule = (
    rule: ConversationRule,
): rule is ConversationStatusRule => rule.type === "Status";

/**
 * Returns true if the rule is for conversation assignee
 */
export const isAssignedToRule = (
    rule: ConversationRule,
): rule is ConversationAssignedToRule =>
    getAssigneeFromRule(rule) !== undefined;

/**
 * Looks at all the rules in a ruleset, if there is an assignee rule amongst them
 * return the user/team id from the first assignee rule found (should only ever be one)
 */
export const getAssigneeFromRuleset = (
    ruleset: ConversationGroupRuleset,
): ConversationAssignedToValue | undefined => {
    for (const rule of ruleset.rules) {
        const assignee = getAssigneeFromRule(rule);
        if (assignee !== undefined) {
            return assignee;
        }
    }
    return undefined;
};

/**
 * Looks at all the rules in a ruleset, if there is a status rule amongst them
 * return 'Open'/'Done' from the first status rule found (should only ever be one)
 */
export const getStatusFromRuleset = (
    ruleset: ConversationGroupRuleset,
): ConversationStatusRule["value"] | undefined => {
    for (const rule of ruleset.rules) {
        const status = getStatusFromRule(rule);
        if (status !== undefined) {
            return status;
        }
    }
    return undefined;
};

/**
 * Returns user/team id from the rule if it is for assigned conversations
 */
export const getAssigneeFromRule = (
    rule: ConversationRule,
): ConversationAssignedToValue | undefined => {
    if (rule.type === "AssignedTo") {
        return rule.value;
    }
    return undefined;
};

/**
 * Returns 'Open'/'Done' from the rule if it is a status rule
 */
const getStatusFromRule = (
    rule: ConversationRule,
): ConversationStatusRule["value"] | undefined => {
    if (rule.type === "Status") {
        return rule.value;
    }
    return undefined;
};

/**
 * Looks at all the rules in a ruleset, if there is a a sent by rule amongst them
 * return the user id from the first rule found (should only ever be one)
 */
export const getSentByUserIdFromRuleset = (
    ruleset: ConversationGroupRuleset,
): string | undefined => {
    for (const rule of ruleset.rules) {
        const sentByUserId = getSentByUserIdFromRule(rule);
        if (sentByUserId !== undefined) {
            return sentByUserId;
        }
    }
    return undefined;
};

/**
 * Returns user id from the rule if it is for sent conversations
 */
export const getSentByUserIdFromRule = (
    rule: ConversationRule,
): string | undefined => {
    if (rule.type !== "ContainsItem") {
        return undefined;
    }
    return rule.value.rules.find(isSentByItemRule)?.value.id;
};

const isSentByItemRule = (
    rule: ConversationItemRule,
): rule is ConversationItemSenderRule => rule.type === "Sender";

/** Returns true if the rule is for conversation item inclusion */
export const isContainsItemRule = (
    rule: ConversationRule,
): rule is ConversationContainsItemRule => rule.type === "ContainsItem";

/** Returns true if the rule is for conversation item inclusion */
export const isStartsWithItemRule = (
    rule: ConversationRule,
): rule is ConversationStartsWithItemRule => rule.type === "StartsWithItem";

/** Returns an equivalence check for an item type rule */
export const isMatchItemTypeRule =
    (types: ConversationItemType[]) =>
    (rule: ConversationItemRule): boolean =>
        rule.type === "ItemType" &&
        rule.value.length === types.length &&
        xor(rule.value, types).length === 0;

/**
 * Returns true if the rule is an "Any" rule
 */
export const isAnyRule = (
    rule: ConversationRule,
): rule is ConversationAnyRule => rule.type === "Any";
