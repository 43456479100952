import { z } from "zod";

export const PRINCIPAL_SYSTEMS = [
    "Unknown",
    "Emis",
    "SystmOne",
    "Vision",
    "Server",
] as const;

export const PrincipalSystemSchema = z.enum(PRINCIPAL_SYSTEMS);

export const LoggedInUserDetailsSchema = z.object({
    accuRxUserName: z.string(),
    medicalRecordUserName: z.string(),
});

export const ConnectionTypeSchema = z.enum(["Connected", "Disconnected"]);

export const MedicalRecordConnectionDtoSchema = z.object({
    connectionType: ConnectionTypeSchema,
    principalSystemType: PrincipalSystemSchema,
    userMedicalRecordRole: z.string().nullable(),
    hasMedicalRecordUserChanged: z.boolean(),
    loggedInUsersDetails: LoggedInUserDetailsSchema,
    capabilities: z.object({
        attachDocumentsFromRecord: z.enum([
            "Supported",
            "NotSupported",
            "SupportedWithNhsNumber",
        ]),
        saveAttachmentsToRecord: z.boolean(),
        shouldPrefetchAttachments: z.boolean(),
    }),
});

export type PrincipalSystem = z.infer<typeof PrincipalSystemSchema>;
export type LoggedInUserDetails = z.infer<typeof LoggedInUserDetailsSchema>;
export type ConnectionType = z.infer<typeof ConnectionTypeSchema>;
export type MedicalRecordConnectionDto = z.infer<
    typeof MedicalRecordConnectionDtoSchema
>;
