import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

const sendApprovalReminder = (
    workspaceId: number,
): Promise<IWrappedResult<undefined>> =>
    httpClient.putJsonReturnSafeAsync(
        getApiEndpoint({
            path: `api/account/workspace/${workspaceId}/SendApprovalReminder`,
        }),
        {},
    );

export const useSendApprovalReminderMutation = (
    options: UseMutationOptions<void, Error, { workspaceId: number }> = {},
) =>
    useMutation({
        mutationKey: ["SendApprovalReminder"],
        ...options,
        mutationFn: async ({ workspaceId }) => {
            const response = await sendApprovalReminder(workspaceId);
            if (!response.success) {
                throw new Error(
                    response.error ?? "Failed to send approval reminder",
                );
            }
        },
    });
