import { Subject } from "rxjs";

export class PaginationHandler<T> {
    /**
     * This is the item that all items on the page being viewed currently are after,
     * If undefined, then the current page is the latest items i.e. the first page
     */
    private currentPageCutoff?: T;

    /**
     * This is the last item in the page, so that the next page should all be items after this this one
     * If undefined, then there is no next page of items in the subscription
     */
    private nextPageCutoff?: T;

    constructor(
        private readonly pageSize: number,
        private readonly itemUpdateFeed: Subject<T[]>,
        private readonly sort: (existing: T[]) => T[],
        private readonly checkAndFetchNextApiPage: (upcomingItems: T[]) => void,
    ) {
        this.onNextPage = this.onNextPage.bind(this);
        this.onPreviousPage = this.onPreviousPage.bind(this);
    }

    get hasNextPage(): boolean {
        // There is only a next option if we have another page of data in the UI, which occurs if nextPageCutoff is not null
        return this.nextPageCutoff !== undefined;
    }

    get hasPreviousPage(): boolean {
        // This is only a previous option if this page isn't the first page, only the first page has currentPageCutoff set to null
        return this.currentPageCutoff !== undefined;
    }

    onNextPage(allItems: T[]): void {
        const nextPageCutoff = this.nextPageCutoff;
        if (nextPageCutoff !== undefined) {
            // As the nextPageCutoff is the last item on the page the next button was clicked,
            // setting this to be the currentPageCutoff and recalculating the current page
            // will result in a page of items directly after the last item on the page where
            // the next button was clicked
            this.currentPageCutoff = nextPageCutoff;

            const currentPage = this.updateAndGetCurrentPage(allItems);

            this.itemUpdateFeed.next(currentPage);
        }
    }

    onPreviousPage(allItems: T[]): void {
        const currentPageCutoff = this.currentPageCutoff;
        // If currentPageCutoff is undefined we are on the first page, so previous is not an option
        if (currentPageCutoff !== undefined) {
            // As the currentPageCutoff is the item that all items on the current page
            // are after, we want to find all items up to and including that item.
            // We calculate what items would be on single page before this cutoff,
            // and set the new currentPageCutoff be the item before the first one on this page.
            const indexOfCutoff = this.getCutoffIndexOrClosest(
                currentPageCutoff,
                allItems,
            );

            // Find all items up to and including the previous cutoff item
            const filteredConvos = allItems.slice(0, indexOfCutoff + 1);

            // If N is the page size, we want the page displayed to be that last N from
            // filteredConvos, as currentPageCutoff represents the item before
            // the first one on the page, we find the item N + 1 away from the end
            // of filteredConvos, then do a fresh calculation of the current page using that as the cutoff
            this.currentPageCutoff =
                filteredConvos[filteredConvos.length - this.pageSize - 1];

            const currentPage = this.updateAndGetCurrentPage(allItems);

            this.itemUpdateFeed.next(currentPage);
        }
    }

    updateAndGetCurrentPage(allItems: T[]): T[] {
        const currentPageCutoff = this.currentPageCutoff;
        // On the first page - where currentPageCutoff is null - we just want the first page
        // of all items, so we don't apply filtering;
        let filteredItems = allItems;
        if (currentPageCutoff !== undefined) {
            // If this is not the first page, we don't want to consider any items up to
            // and including the currentPageCutoff item so we are going to remove these
            // in filteredItems
            const indexOfCutoff = this.getCutoffIndexOrClosest(
                currentPageCutoff,
                allItems,
            );

            filteredItems = allItems.slice(indexOfCutoff + 1, allItems.length);
        }

        // Once we have all items that would be on current page, or any of the next
        // pages we call back into the subscription to check whether the subscription
        // needs to call the API to get more data
        this.checkAndFetchNextApiPage(filteredItems);

        // From the filtered items, take the first N for the correct page size
        const pagedConvos = filteredItems.slice(0, this.pageSize);
        // If there are more items in filteredItems than the page size, then there should be
        // a next option so the user can reach them, in this case set nextPageCutoff to be the last item
        // on the current page. If there are no more items set nextPageCutoff to be undefined
        const nextPageStart =
            pagedConvos.length < filteredItems.length
                ? pagedConvos[pagedConvos.length - 1]
                : undefined;
        this.nextPageCutoff = nextPageStart;

        return pagedConvos;
    }

    private getCutoffIndexOrClosest(
        currentPageCutoff: T,
        allItems: T[],
    ): number {
        // if the cutoff already exists in the collection - then we know where the index is.
        const indexOfCutoff = allItems.indexOf(currentPageCutoff);
        if (indexOfCutoff >= 0) {
            return indexOfCutoff;
        }

        // If the current page cutoff has been updated in any way, then either it won't be in
        // the array of all items or there will be a new object representing it in the list.
        // In this case add the original cutoff item back in and sort the collection to calculate where
        // it would be and so find the correct index on the array.
        const allItemsWithCutoff = this.sort([...allItems, currentPageCutoff]);
        return allItemsWithCutoff.indexOf(currentPageCutoff) - 1; // Take away one to account for the fact the cutoff won't be in the array
    }
}

// Haven't used the more common pattern of ...args: ConstructorParameters<typeof PaginationHandler<T>>
// as current version of prettier incorrectly doesn't like it when using generics.
// Prettier version 2.8.3 is known to support this pattern
export const createPaginationHandler = <T>(
    pageSize: number,
    itemUpdateFeed: Subject<T[]>,
    sort: (existing: T[]) => T[],
    checkAndFetchNextApiPage: (upcomingItems: T[]) => void,
): PaginationHandler<T> => {
    return new PaginationHandler<T>(
        pageSize,
        itemUpdateFeed,
        sort,
        checkAndFetchNextApiPage,
    );
};
