import { useState } from "react";

import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
    Types,
} from "@accurx/design";

import {
    StyledButton,
    StyledUnavailablePopoverItem,
} from "./FeatureButton.styles";

export type IconType = {
    name: Types.IconNameVariants;
    colour: Types.IconColourVariants;
};

export type Title = "Message patient" | "Video Consult" | "GP services";

export type DisabledInfo = {
    title: string;
    description?: JSX.Element | string | null;
};

export type Option = {
    title: string;
    icon: IconType;
    action: () => void;
    userflowId?: string;
    disabledInfo?: DisabledInfo | null;
};

export type FeatureButtonProps = {
    title: Title;
    icon: IconType;
    action?: () => void;
    disabledInfo?: DisabledInfo | null;
    options?: Option[];
};

const FeatureButton = ({
    title,
    icon,
    action,
    disabledInfo,
    options = [],
    ...props
}: FeatureButtonProps): JSX.Element => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const allOptionsDisabled = options.every((option) => option.disabledInfo);

    if (disabledInfo) {
        return (
            <Popover
                open={isPopoverOpen}
                onOpenChange={setIsPopoverOpen}
                {...props}
            >
                <PopoverTrigger asChild>
                    <StyledButton
                        text={title}
                        theme="secondary"
                        dimension="large"
                        icon={{
                            name: "Warning",
                            style: "Line",
                            colour: "orange",
                        }}
                    />
                </PopoverTrigger>
                <PopoverContent align="start">
                    <StyledUnavailablePopoverItem>
                        <Text variant="label" colour="night">
                            {disabledInfo.title}
                        </Text>
                        {disabledInfo.description}
                    </StyledUnavailablePopoverItem>
                </PopoverContent>
            </Popover>
        );
    }

    if (options.length === 0) {
        return (
            <StyledButton
                text={title}
                theme="secondary"
                dimension="large"
                icon={{ name: icon.name, style: "Line", colour: icon.colour }}
                onClick={action}
                {...props}
            />
        );
    }

    return (
        <Popover
            open={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
            {...props}
        >
            <PopoverTrigger asChild>
                <StyledButton
                    text={title}
                    theme="secondary"
                    dimension="large"
                    icon={{
                        name: allOptionsDisabled ? "Warning" : icon.name,
                        style: "Line",
                        colour: allOptionsDisabled ? "orange" : icon.colour,
                    }}
                />
            </PopoverTrigger>
            <PopoverContent align="end">
                {options.map(
                    (
                        { title, icon, action, userflowId, disabledInfo },
                        index,
                    ) => {
                        if (disabledInfo) {
                            return (
                                <StyledUnavailablePopoverItem
                                    key={`${index}-${title}`}
                                >
                                    <Text variant="label" colour="night">
                                        {disabledInfo.title}
                                    </Text>
                                    {disabledInfo.description && (
                                        <Text variant="body" colour="night">
                                            {disabledInfo.description}
                                        </Text>
                                    )}
                                </StyledUnavailablePopoverItem>
                            );
                        } else {
                            return (
                                <PopoverItem
                                    data-userflow-id={userflowId}
                                    onClick={action}
                                    key={`${index}-${title}`}
                                >
                                    <Text variant="label" colour="night">
                                        <Icon
                                            name={icon.name}
                                            colour={icon.colour}
                                            size={3}
                                        />{" "}
                                        {title}
                                    </Text>
                                </PopoverItem>
                            );
                        }
                    },
                )}
            </PopoverContent>
        </Popover>
    );
};

export { FeatureButton };
