import { Tokens } from "@accurx/design";
import * as UI from "@accurx/design";
import styled from "styled-components";

import { StyledPillProps, getButtonBorder, pillStyles } from "../../styles";

const {
    COLOURS: { greyscale, primary },
    SIZES,
} = Tokens;

export const StyledButton = styled.button<StyledPillProps>`
    ${pillStyles}
    position: relative;
    box-shadow: ${getButtonBorder(greyscale.silver)};
    border: 1px solid transparent;

    &:hover:not(:focus, :disabled) {
        box-shadow: ${getButtonBorder(primary.blue[100])};
    }
    font-size: ${(props) => (props.$dimension === "small" ? "14px" : "16px")};

    &:focus,
    &:active {
        ${UI.focusBorderStyles};
        background: ${primary.blue[5]};
    }
`;

export const StyledWrapper = styled.span<{ $isLoading?: boolean }>`
    display: flex;
    align-items: center;
    gap: ${SIZES[0.5]};

    // Two items next together (ie: icon + text)
    * + * {
        padding-right: ${SIZES[0.5]};
    }

    ${(props) =>
        props.$isLoading &&
        `
    // Visually hidden but present for assistive tech
    clip-path: rect(0 0 0 0);
    `}
`;

export const StyledLoadingIcon = styled(UI.Spinner).attrs({
    dimension: "extra-small",
})`
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
`;
