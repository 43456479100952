import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import {
    useMarkAsNonUrgentMutation,
    useMarkAsUrgentMutation,
} from "domains/inbox/hooks/mutations";
import { useViewportSize } from "domains/inbox/hooks/util/";
import { useConversationActionAnalytics } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { toast } from "react-toastify";

import {
    StyledUrgentButton,
    StyledUrgentLabelText,
} from "./ToggleUrgentActionButton.styles";

export type ToggleUrgentActionButtonProps = {
    conversation: Conversation;
};

export const ToggleUrgentActionButton = ({
    conversation,
}: ToggleUrgentActionButtonProps) => {
    const analytics = useConversationActionAnalytics({
        conversation,
        productOrigin: "ConversationActions",
    });
    const size = useViewportSize();
    const shouldShowLabels = size === "sm" || size === "md";
    const shouldShowTooltip = size !== "xs" && !shouldShowLabels;

    const markAsUrgent = useMarkAsUrgentMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("Urgent", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("Urgent", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't mark this conversation as urgent
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });
    const markAsNonUrgent = useMarkAsNonUrgentMutation({
        onSuccess: () => {
            analytics.trackConversationActionButtonResponse("NonUrgent", false);
        },
        onError: () => {
            analytics.trackConversationActionButtonResponse("NonUrgent", true);
            toast(
                <UI.Feedback title="Action failed" colour="error">
                    <UI.Text as="span">
                        Sorry, we couldn't mark this conversation as non-urgent
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });

    const handleMarkAsUrgentClick = () => {
        analytics.trackConversationActionButtonClick("Urgent");
        markAsUrgent.mutate(conversation);
    };

    const handleMarkAsNonUrgentClick = () => {
        analytics.trackConversationActionButtonClick("NonUrgent");
        markAsNonUrgent.mutate(conversation);
    };

    return conversation.status === "Open" ? (
        <WithTooltip
            isEnabled={shouldShowTooltip}
            content={
                <UI.Text skinny variant="note" colour="white">
                    {conversation.isUrgent ? "Mark not urgent" : "Mark urgent"}
                </UI.Text>
            }
        >
            {conversation.isUrgent ? (
                <StyledUrgentButton
                    aria-label="Mark conversation as not urgent"
                    radius="full"
                    onClick={handleMarkAsNonUrgentClick}
                    $isSelected
                    disabled={markAsNonUrgent.isLoading}
                >
                    {markAsNonUrgent.isLoading && <Pill.Icon isLoading />}
                    {!markAsNonUrgent.isLoading && (
                        <>
                            <Pill.Icon name="Flag" colour="red" theme="Fill" />
                            {shouldShowLabels && (
                                <StyledUrgentLabelText $isSelected>
                                    Urgent
                                </StyledUrgentLabelText>
                            )}
                            <Pill.Icon name="Cross" colour="zinc" />
                        </>
                    )}
                </StyledUrgentButton>
            ) : (
                <StyledUrgentButton
                    aria-label="Mark conversation as urgent"
                    radius="full"
                    disabled={markAsUrgent.isLoading}
                    onClick={handleMarkAsUrgentClick}
                >
                    {markAsUrgent.isLoading && <Pill.Icon isLoading />}
                    {!markAsUrgent.isLoading && (
                        <>
                            <Pill.Icon name="Flag" colour="blue" />
                            {shouldShowLabels && (
                                <StyledUrgentLabelText>
                                    Urgent
                                </StyledUrgentLabelText>
                            )}
                        </>
                    )}
                </StyledUrgentButton>
            )}
        </WithTooltip>
    ) : null;
};
