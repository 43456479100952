import { ChangeEvent, InputHTMLAttributes, MouseEvent, useRef } from "react";

import * as UI from "@accurx/design";
import { applyFocusStyle } from "@accurx/navigation";
import { colors } from "domains/inbox/theme";
import styled from "styled-components";

type SearchInputProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "value" | "onChange"
> & {
    value: string;
    onChange: (
        value: string,
        event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
    ) => void;
};

const Container = styled.div`
    position: relative;

    color: ${UI.Tokens.COLOURS.primary.blue[100]};

    &:has(input:not(:focus)[value=""]) {
        color: ${colors.zinc.tint70};
    }
`;

const Input = styled.input`
    appearance: none;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid transparent;
    background: white;
    padding: 0 ${UI.Tokens.SIZES[4]};
    height: 36px;
    box-sizing: border-box;
    width: 100%;

    &::-webkit-search-cancel-button {
        display: none;
    }

    &[value=""]:not(:focus) {
        background: #003535;

        &:hover {
            border: 1px solid ${UI.Tokens.COLOURS.primary.green[130]};
        }
    }
`;

const SearchIconPositioner = styled.div`
    position: absolute;
    left: 6px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
`;

const DeleteButtonPositioner = styled.div`
    position: absolute;
    right: 8px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
`;

const DeleteButton = styled.button`
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 4px;

    &:focus {
        ${applyFocusStyle}
    }

    &:hover,
    &:focus {
        background: ${UI.Tokens.COLOURS.primary.red[25]};
    }
`;

export const SearchInput = ({
    value,
    onChange,
    ...props
}: SearchInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Container>
            <SearchIconPositioner>
                <UI.Icon name="Search" size={3} colour="currentColor" />
            </SearchIconPositioner>
            <Input
                ref={inputRef}
                type="search"
                autoFocus
                value={value}
                onChange={(e) => onChange(e.target.value, e)}
                {...props}
            />
            {!!value && (
                <DeleteButtonPositioner>
                    <DeleteButton
                        aria-label="Clear search term"
                        onClick={(e) => {
                            onChange("", e);
                            inputRef.current?.focus();
                        }}
                    >
                        <UI.Icon name="Cross" size={3} colour="red" />
                    </DeleteButton>
                </DeleteButtonPositioner>
            )}
        </Container>
    );
};
