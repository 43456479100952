import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";
import { PatientExternalIdentityDto } from "domains/native/schemas/PatientExternalIdentityDtoSchema";
import { z } from "zod";

export const MutateUploadPatientRecordAttachmentResponseSchema = z.object({
    documentId: z.string(),
    serverId: z.string(),
    serverUrlId: z.string().nullable().optional(),
    sizeBytes: z.number(),
});

export type MutateUploadPatientRecordAttachmentResponse = z.infer<
    typeof MutateUploadPatientRecordAttachmentResponseSchema
>;

export type MutateUploadPatientRecordAttachmentPayload = {
    patientIdentity: PatientExternalIdentityDto;
    documentId: string;
};

export const mutateUploadPatientRecordAttachment = createEndpoint<
    MutateUploadPatientRecordAttachmentResponse,
    MutateUploadPatientRecordAttachmentPayload
>("MutateUploadPatientRecordAttachment", async (context, payload) => {
    const result = await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateUploadPatientRecordAttachment",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
    return MutateUploadPatientRecordAttachmentResponseSchema.parse(result);
});
