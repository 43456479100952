import React, { useEffect } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { Flex, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { generatePath, useHistory, useParams } from "react-router";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { BatchUploadCopyPasteCard } from "../components/BatchUploadCopyPasteCard";
import { BatchUploadCsvCard } from "../components/BatchUploadCsvCard";
import { BatchUploadImportFromListCard } from "../components/BatchUploadImportFromListCard";
import { StyledBatchMessageTrustPageLayout } from "./PageLayout.styles";

export const AddPatientsPage = () => {
    useAccurxWebTitle("Send new batch message");
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const history = useHistory();
    const isWebBatchDisabledDueToFreemium = useFeatureFlag(
        "WebBatchDisableSendingNewBatchesDueToFreemium",
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchCsvPageView({
            ...analyticsLoggedInProps,
            batchSlotAvailable: 0,
            slotName: "Not selected",
            // We don't know the batch type until we get to compose screen
            batchType: "",
            isTrustFlow: true,
        });
    }, [analyticsLoggedInProps]);

    const userFlowTag = (tag: string) =>
        `${tag}${
            isWebBatchDisabledDueToFreemium ? "-disabled-by-freemium" : ""
        }`;

    return (
        <StyledBatchMessageTrustPageLayout>
            <Flex flexDirection="column" gap="2">
                <Flex flexDirection="column" gap="0.5">
                    <Text as="h1" variant="subtitle" skinny>
                        Upload a list of patients to contact
                    </Text>
                    <Text skinny>
                        Import the NHS number and date of birth for any patients
                        you want to contact.
                    </Text>
                </Flex>
                <BatchUploadCsvCard
                    onClick={() => {
                        ChainAnalyticsTracker.trackBatchPatientListSourceButtonClick(
                            {
                                ...analyticsLoggedInProps,
                                source: "Csv",
                                isTrustFlow: true,
                            },
                        );
                        const route = generatePath(
                            ROUTES_WORKSPACE.batchMessageTrustUploadViaFile,
                            {
                                workspaceId,
                            },
                        );
                        history.push(route);
                    }}
                    disabled={isWebBatchDisabledDueToFreemium}
                    data-userflow-id={userFlowTag("batch-import-csv")}
                />
                <BatchUploadImportFromListCard
                    onClick={() => {
                        ChainAnalyticsTracker.trackBatchPatientListSourceButtonClick(
                            {
                                ...analyticsLoggedInProps,
                                source: "PatientList",
                                isTrustFlow: true,
                            },
                        );

                        const route = generatePath(
                            ROUTES_WORKSPACE.batchMessageTrustUploadImportPatientList,
                            {
                                workspaceId,
                            },
                        );
                        history.push(route);
                    }}
                    disabled={isWebBatchDisabledDueToFreemium}
                    data-userflow-id={userFlowTag(
                        "batch-continue-to-import-patient-list",
                    )}
                />
                <BatchUploadCopyPasteCard
                    onClick={() => {
                        ChainAnalyticsTracker.trackBatchPatientListSourceButtonClick(
                            {
                                ...analyticsLoggedInProps,
                                source: "CopyPaste",
                                isTrustFlow: true,
                            },
                        );

                        const path = generatePath(
                            ROUTES_WORKSPACE.batchMessageTrustUploadViaCopyPaste,
                            { workspaceId },
                        );
                        history.push(path);
                    }}
                    disabled={isWebBatchDisabledDueToFreemium}
                    data-userflow-id={userFlowTag(
                        "batch-continue-to-copy-paste-patients",
                    )}
                />
            </Flex>
        </StyledBatchMessageTrustPageLayout>
    );
};
