import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledText = styled(Pill.Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
`;

export const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    overflow: hidden;
`;
