import { Flex } from "@accurx/design";

import { StyledCard } from "./ConversationSummary.styles";
import { StyledSkeletonBlock } from "./LoadingConversationSummary.styles";

export const LoadingConversationSummary = (): JSX.Element => {
    return (
        <StyledCard isElevated={false}>
            <Flex flexDirection="column" gap="1">
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex gap="1" alignItems="center">
                        <StyledSkeletonBlock
                            height="16px"
                            width="150px"
                            verticalMargin="8px"
                        />
                    </Flex>
                    <StyledSkeletonBlock
                        height="16px"
                        width="120px"
                        verticalMargin="8px"
                    />
                </Flex>
                <Flex justifyContent="space-between" gap="1">
                    <StyledSkeletonBlock
                        height="12px"
                        width="300px"
                        verticalMargin="6px"
                    />
                    <StyledSkeletonBlock
                        height="12px"
                        width="60px"
                        verticalMargin="6px"
                    />
                </Flex>
            </Flex>
        </StyledCard>
    );
};
