import { ReactNode } from "react";

import * as UI from "@accurx/design";
import { ConversationCountData } from "domains/inbox/components/Sidebar/components/ConversationCounts/ConversationCountProvider";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";

type ConversationCountTooltipProps = {
    children: ReactNode;
    onOpenChange?: (isOpen: boolean) => void;
    countData: ConversationCountData;
};

export const ConversationCountTooltip = ({
    countData,
    children,
    onOpenChange,
}: ConversationCountTooltipProps) => {
    let tooltipText = "";

    if (countData.status === "Success" && countData.isFullyLoaded) {
        tooltipText = `${countData.count} total`;
    } else if (countData.status === "Success" && !countData.isFullyLoaded) {
        tooltipText = `${countData.count}+ total`;
    }

    return (
        <WithTooltip
            isEnabled={countData.status === "Success"}
            content={
                <UI.Text variant="note" colour="white" skinny>
                    {tooltipText}
                </UI.Text>
            }
            props={{ root: { onOpenChange } }}
        >
            {children}
        </WithTooltip>
    );
};
