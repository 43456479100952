import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledBookingInviteButton = styled(Pill.Button)<{
    $showIconOnly: boolean;
}>`
    padding: ${({ $showIconOnly }) =>
        $showIconOnly ? "0 5px" : `0px ${UI.Tokens.SIZES[1]}`};
`;
