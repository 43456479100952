import { createQueryHook } from "@accurx/api/QueryClient";

import FlemingApi from "api/FlemingApiClient";
import { IAllowedOrganisationResponse } from "api/FlemingDtos";

export const ALLOWED_ORGANISATIONS_QUERY_KEY = "getAllowedOrganisations";

export const useGetAllowedOrganisationsQuery = createQueryHook<
    void,
    IAllowedOrganisationResponse[]
>(() => ({
    queryKey: [ALLOWED_ORGANISATIONS_QUERY_KEY, {}],
    queryFn: async () => {
        const response = await FlemingApi.getOrganisations();
        return response;
    },
}));
