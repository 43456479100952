import React from "react";

import { Thread } from "@accurx/design";

import { formatConversationItemDateCreatedLong } from "app/workspaceConversations/utils/conversation.utils";
import { StateChangeConversationItem } from "shared/concierge/conversations/types/item.types";
import { useSingleUserOrTeamQuery } from "shared/concierge/usersAndTeams/hooks";

import { BaseConversationThreadItem } from "../threadItem.types";

export type StateChangeThreadItemProps =
    BaseConversationThreadItem<StateChangeConversationItem>;

const getMessageContent = (
    item: StateChangeThreadItemProps["item"],
    includeIdentities: boolean,
    assignedToDisplayName?: string,
): string => {
    switch (item.changeType) {
        case "Done": {
            return "marked this conversation as done for everyone";
        }
        case "ReOpened": {
            return "re-opened this conversation for everyone";
        }
        case "Urgent": {
            return "marked this conversation as urgent";
        }
        case "NonUrgent": {
            return "marked this conversation as not urgent";
        }
        case "Assignment": {
            return includeIdentities && assignedToDisplayName
                ? `assigned this conversation to ${assignedToDisplayName}`
                : "assigned this conversation";
        }
        case "PatientMatch": {
            return "matched this conversation to a patient";
        }
        case "PatientUnmatch": {
            return "unmatched this conversation from the patient";
        }
    }
};

export const StateChangeThreadItem = ({
    item,
    handleOnItemRead,
    createdByDisplayName,
}: StateChangeThreadItemProps) => {
    const assignedToId =
        item.changeType === "Assignment" ? item.assignedToId : undefined;

    const assignedToUserQuery = useSingleUserOrTeamQuery(assignedToId);
    return (
        <Thread.Message
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            {createdByDisplayName ? createdByDisplayName : "Accurx"}{" "}
            {getMessageContent(
                item,
                true,
                assignedToUserQuery.data?.displayName,
            )}{" "}
            &middot; {formatConversationItemDateCreatedLong(item.createdAt)}
        </Thread.Message>
    );
};

export const stateChangeThreadItemSummaryText = (
    item: StateChangeThreadItemProps["item"],
): string => getMessageContent(item, false);
