import { FeatureName } from "@accurx/auth";
import { useDispatch } from "react-redux";

import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { useIsFeatureEnabled } from "store/hooks";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

export const InviteToVideoConsultOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const dispatch = useDispatch();
    const saveToStore = useSavePatientSearchToStore();
    const isVideoConsultEnabled = useIsFeatureEnabled(
        FeatureName.VideoConsultWeb,
    );
    const isMessagingEnabled = useIsFeatureEnabled(
        FeatureName.PatientThreadSMS,
    );

    if (!isVideoConsultEnabled || !isMessagingEnabled) {
        return null;
    }

    const isDeceasedPatient = searchResult.searchedResult.patient.deceased;

    return (
        <ContactOption
            onClick={() => {
                saveToStore(searchResult);
                dispatch(actionCreators.selectVideoProductType());
                onClick();
            }}
            disabled={isDeceasedPatient}
            iconName="Video"
            text="Invite to Video Consult"
        />
    );
};
