import React from "react";

import { useSendUserConnectedStatus } from "domains/inbox/hooks/useSendUserConnectedStatus";
import { useSendUserOnline } from "domains/inbox/hooks/useSendUserOnline";
import { useUserOnline } from "domains/inbox/hooks/useUserOnline";

import { OnlineUsersContext } from "./OnlineUsersContext";

type OnlineUsersContextProviderProps = {
    children: React.ReactNode;
};

const OnlineUsersContextProviderInner = ({
    children,
}: OnlineUsersContextProviderProps) => {
    const usersOnline = useUserOnline();

    useSendUserOnline();
    useSendUserConnectedStatus();

    return (
        <OnlineUsersContext.Provider
            value={{
                usersOnline,
            }}
        >
            {children}
        </OnlineUsersContext.Provider>
    );
};

export const OnlineUsersContextProvider = ({
    children,
}: OnlineUsersContextProviderProps) => {
    return (
        <OnlineUsersContextProviderInner>
            {children}
        </OnlineUsersContextProviderInner>
    );
};
