import { NativeFetchUnexpectedError } from "domains/native/errors";
import { NativeTransport } from "domains/native/types";

import { WebView2EventHandler } from "./types";

export const WebView2Transport: NativeTransport = {
    postMessage(message) {
        if (!window.chrome?.webview) {
            throw new NativeFetchUnexpectedError(
                "ClientBadTransport",
                "WebView2Transport.postMessage() called when webview global is not available",
            );
        }
        window.chrome.webview.postMessage(message);
    },
    addEventListener(handler) {
        if (!window.chrome?.webview) {
            throw new NativeFetchUnexpectedError(
                "ClientBadTransport",
                "WebView2Transport.addEventListener() called when webview global is not available",
            );
        }

        const unsubscribe = () => {
            window.chrome?.webview?.removeEventListener(
                "message",
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                webViewHandler,
            );
        };

        const webViewHandler: WebView2EventHandler = (message) =>
            handler(message.data, unsubscribe);

        window.chrome.webview.addEventListener("message", webViewHandler);

        return unsubscribe;
    },
};
