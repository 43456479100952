import * as React from "react";

import { Text } from "@accurx/design";

import { PracticeModules } from "app/practices/Practices.types";
import { patientTriageUrls } from "app/practices/patientTriage/constants/PracticePatientTriage";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionPatientTriageProps = {
    practiceModules: PracticeModules;
};

export const SectionPatientTriage = ({
    practiceModules,
}: SectionPatientTriageProps): JSX.Element | null => {
    // Don't render if user shouldn't have PT components
    if (!practiceModules.patientTriage) {
        return null;
    }

    return (
        <>
            <div className={"d-flex align-items-center"}>
                <Text as="h2" variant="subtitle">
                    Patient Triage
                </Text>
            </div>
            <div className="mb-4">
                <PracticeLink
                    url={patientTriageUrls.patientTriageDashboard}
                    title="Dashboard"
                    section={PracticeLinkSection.PATIENT_TRIAGE}
                    badgeCopy="New"
                    description="See a history of your patient requests by day and hour in the last two weeks, or download an excel report with data for the last 90 days usage"
                />
                <PracticeLink
                    url={patientTriageUrls.patientTriage}
                    title="What is Patient Triage?"
                    section={PracticeLinkSection.PATIENT_TRIAGE}
                    description="Find out how Patient Triage can improve your online consultation experience and try it for yourself"
                />
                <>
                    <PracticeLink
                        url={patientTriageUrls.patientTriageSetUp}
                        title="Set up Patient Triage"
                        section={PracticeLinkSection.PATIENT_TRIAGE}
                        description="Get your practice set up, and find useful resources about managing Patient Triage"
                    />
                    <PracticeLink
                        url={patientTriageUrls.patientTriageSettings}
                        title="Settings"
                        section={PracticeLinkSection.PATIENT_TRIAGE}
                        description="Customise Patient Triage"
                    />
                </>
            </div>
        </>
    );
};
