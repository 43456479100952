import { z } from "zod";

export const PatientRecordAttachmentSchema = z.object({
    documentId: z.string(),
    fileName: z.string(),
    descriptiveText: z.string().nullable(),
    addedAt: z.string().nullable(),
});

export type PatientRecordAttachment = z.infer<
    typeof PatientRecordAttachmentSchema
>;
