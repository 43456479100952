import { Button, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledHeaderContainer = styled.div`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }

    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
    background: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${Tokens.SIZES[1]};
`;

export const StyledSubHeaderContainer = styled.div<{
    hasUnknownPatient: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${Tokens.SIZES[1]};
    flex-wrap: wrap;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
    background: ${Tokens.COLOURS.greyscale.white};

    ${({ hasUnknownPatient }) =>
        hasUnknownPatient
            ? css`
                  background-color: ${Tokens.COLOURS.primary.yellow[10]};
              `
            : css`
                  box-shadow: inset 0px -2px 0px ${Tokens.COLOURS.greyscale.silver};
              `}
`;

export const StyledMarkAsDoneButton = styled(Button)`
    white-space: nowrap;
`;

export const StyledConversationHeader = styled.div`
    display: inline-flex;
    align-items: center;
    gap: ${Tokens.SIZES[1]};
    flex-wrap: wrap;
`;
