import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import { BatchCommunicationMethod } from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import {
    Card,
    Feedback,
    Flex,
    Icon,
    Item,
    Spinner,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { usePatientsFileUploadMutation } from "domains/batch-messaging/hooks/usePatientsFileUploadMutation";

import { useBatchConfiguration } from "../../hooks/useBatchConfiguration";
import { FileProcessingErrors } from "./components/FileProcessingErrors";
import { HowDoWeSendMessagesInfo } from "./components/HowDoWeSendMessagesInfo";
import { HowToExportListOfPatientsInfo } from "./components/HowToExportListOfPatientsInfo";
import { RequiredColumnsList } from "./components/RequiredColumnsList";
import { UploadFile } from "./components/UploadFile";

type AddPatientsViaFileUploadProps = {
    /** May be false for certain batch types */
    isEmailEnabled: boolean;
    /** May be false for certain batch types */
    isNHSAppEnabled: boolean;
    isTrustFlow: boolean;
    onUploadStart?(): void;
    onUploadSuccess(batchMessageId: string): void;
};

export const AddPatientsViaFileUpload = ({
    isEmailEnabled,
    isNHSAppEnabled,
    isTrustFlow,
    onUploadStart,
    onUploadSuccess,
}: AddPatientsViaFileUploadProps) => {
    const track = useAnalytics();
    const workspaceId = useCurrentWorkspace().orgId;
    const {
        status,
        data: batchConfig,
        error: configError,
    } = useBatchConfiguration({
        workspaceId,
        batchEmailFlow: isEmailEnabled,
    });
    const {
        mutateAsync: uploadFile,
        status: uploadStatus,
        error,
        data: uploadData,
    } = usePatientsFileUploadMutation();

    useEffect(() => {
        if (status === "success") {
            track("BatchPatientFileUpload Page View", {});
        } else if (status === "error") {
            Log.warn("Failed to fetch batch configuration", {
                originalException: configError,
            });
        }
    }, [track, status, configError]);

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "error") {
        return (
            <Feedback
                colour="error"
                title="Something went wrong fetching batch configuration"
            />
        );
    }

    const hasEmailAvailable = batchConfig.batchCommunicationMethods.includes(
        BatchCommunicationMethod.Email,
    );

    const availableCommunicationMethods = isNHSAppEnabled
        ? batchConfig.batchCommunicationMethods
        : batchConfig.batchCommunicationMethods.filter(
              (method) => method !== BatchCommunicationMethod.NhsApp,
          );

    return (
        <Flex flexDirection="column" gap="2">
            {uploadStatus === "error" && (
                <Feedback colour="error" title="We couldn't upload the file.">
                    {error.message}
                </Feedback>
            )}
            {uploadStatus === "success" && (
                <FileProcessingErrors
                    uploadData={uploadData}
                    identifier={
                        batchConfig.batchPatientDataRequirements.identifier
                    }
                />
            )}

            <Card spacing={2}>
                <Flex flexDirection="column" gap="3">
                    <RequiredColumnsList
                        requirements={batchConfig.batchPatientDataRequirements}
                        integratedSystem={batchConfig.integratedSystem}
                    />

                    <Flex gap="2">
                        <Item>
                            <Icon name="Warning" colour="orange" size={3} />
                        </Item>
                        <Text skinny variant="label">
                            Do not include patients who have opted out of SMS{" "}
                            {hasEmailAvailable ? "or email" : ""} notifications
                        </Text>
                    </Flex>

                    <UploadFile
                        isTrustFlow={isTrustFlow}
                        onUpload={(file) => {
                            onUploadStart && onUploadStart();
                            uploadFile({
                                file: file,
                                workspaceId: workspaceId,
                                isEmailEnabled,
                            })
                                .then((response) => {
                                    if (response.isUploadSuccess) {
                                        onUploadSuccess(
                                            response.batchMessageId,
                                        );
                                    } else {
                                        track("BatchCsvUploadError Page View", {
                                            isTrustFlow,
                                        });
                                    }
                                })
                                .catch(() => {
                                    track("BatchCsvUploadError Page View", {
                                        isTrustFlow,
                                    });
                                });
                        }}
                        isUploading={uploadStatus === "loading"}
                    />
                </Flex>
            </Card>
            <Text skinny as="h2" variant="subtitle">
                Helpful tips
            </Text>
            <HowToExportListOfPatientsInfo isEmailEnabled={hasEmailAvailable} />
            <HowDoWeSendMessagesInfo
                availableCommunicationMethods={availableCommunicationMethods}
            />
        </Flex>
    );
};
