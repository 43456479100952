import { Button, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTemplateManagementCardContainer = styled.div`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledTruncatedText = styled(Text)<{ seletedItem?: boolean }>`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    margin-top: ${Tokens.SIZES[0.25]};
`;

export const StyledTemplateDetails = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
    flex-wrap: wrap;
    gap: 8px;
`;

export const StyledTemplateContent = styled.div`
    min-width: 0;
    flex-basis: calc(50% - ${Tokens.SIZES[0.5]});
    flex-grow: 1;

    display: flex;
    gap: ${Tokens.SIZES[1]};

    padding-right: ${Tokens.SIZES[0.5]};
`;

export const StyledButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: calc(50% - ${Tokens.SIZES[0.5]});

    gap: ${Tokens.SIZES[1.5]};
`;

export const StyledUseTemplateButton = styled(Button)`
    white-space: nowrap;
`;
