import {
    IntegratedClinicListResponse,
    IntegratedClinicNoPiiResponse,
    IntegratedClinicResponse,
} from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

const ENDPOINTS = {
    integratedClinicLists: "/api/appointment/:workspaceId/integratedclinics",
    integratedClinic:
        "/api/appointment/:workspaceId/integratedclinics/:clinicCode/:clinicDay",
} as const;

type IntegratedClinicLists = IntegratedClinicListResponse;

export type IntegratedClinic = IntegratedClinicNoPiiResponse;

export type ClinicAppointment =
    | ClinicAppointmentPDSFailed
    | ClinicAppointmentPDSMatched;

export type IntegratedClinicWithAppointments = Omit<
    IntegratedClinicResponse,
    "appointments"
> & { appointments: ClinicAppointment[] };

type ClinicAppointmentPDSFailed = {
    id: number;
} & Untrusted<{
    appointmentType: string;
    startTime: string;
    dateOfBirth: string;
    nhsNumber: string;
    displayName: string;
    gender: string;
    location?: string | null;
    patientLookupResult:
        | "PdsLookupFailed"
        | "MissingNhsNumber"
        | "MissingDateOfBirth"
        | "FailedOther";
}>;

export type ClinicAppointmentPDSMatched = {
    id: number;
    nhsNumber: string;
    dateOfBirth: string;
    displayName: string;
    location?: string | null;
    patientLookupResult: "Success";
} & Untrusted<{
    appointmentType: string;
    startTime: string;
    gender: string;
}>;

export type IntegratedClinicListsParams = {
    workspaceId: string;
};

type IntegratedClinicParams = {
    workspaceId: string;
    clinicCode: string;
    clinicDay: string;
};

export const getIntegratedClinicLists = ({
    workspaceId,
}: IntegratedClinicListsParams): Promise<
    IWrappedResult<IntegratedClinicLists>
> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.integratedClinicLists,
            params: { workspaceId },
        }),
    );
};

export const getIntegratedClinic = ({
    workspaceId,
    clinicCode,
    clinicDay,
}: IntegratedClinicParams): Promise<
    IWrappedResult<IntegratedClinicWithAppointments>
> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.integratedClinic,
            params: { workspaceId, clinicCode, clinicDay },
        }),
    );
};
