import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import { Hooks, Popover, PopoverTrigger } from "@accurx/design";
import { useToggle } from "@accurx/navigation";
import { ErrorBoundary } from "@accurx/shared";
import { useConversationActionTrackingFields } from "domains/inbox/hooks/util/useConversatonActionAnalytics";
import { getNumberOfAssignments } from "domains/inbox/util/getNumberOfAssignments";

import { GenericErrorState } from "../GenericErrorState/GenericErrorState";
import {
    StyledErrorContainer,
    StyledPopoverContent,
} from "./AssigneeSelector.styles";
import { AssigneeSelectorContent } from "./components";
import { AssigneeButton } from "./components/AssigneeButton/AssigneeButton";

export type AssigneeSelectorProps = {
    conversation: Conversation;
    allowTeamAssignment?: boolean;
};

export const AssigneeSelector = ({
    conversation,
    allowTeamAssignment,
}: AssigneeSelectorProps): JSX.Element => {
    const { toggle, isOpen } = useToggle();
    const track = useAnalytics();

    const getConversationActionTrackingFields =
        useConversationActionTrackingFields(conversation);
    const { size } = Hooks.useViewportSize();
    const enablePopoverAsModal = size === "xs" || size === "sm";

    return (
        <Popover
            open={isOpen}
            onOpenChange={toggle}
            modal={enablePopoverAsModal}
        >
            <PopoverTrigger asChild={true}>
                <AssigneeButton
                    conversation={conversation}
                    onClick={() => {
                        const {
                            accessType,
                            conversationDescription,
                            conversationId,
                            conversationStartTimestampUtc,
                            conversationType,
                            conversationParticipant,
                            countItemsInConversation,
                            countPatientMessageInbound,
                            countPatientMessageOutbound,
                            medicalRecordSystem,
                            userMedicalRecordRole,
                            conversationRequestType,
                        } = getConversationActionTrackingFields({
                            type: "Assign",
                            appOrigin: "ConversationActions",
                        });
                        track("ConversationAssignSelect Button Click", {
                            accessType,
                            conversationDescription,
                            conversationId,
                            conversationStartTimestampUtc,
                            conversationType,
                            conversationParticipant,
                            countItemsInConversation,
                            countPatientMessageInbound,
                            countPatientMessageOutbound,
                            medicalRecordSystem,
                            userMedicalRecordRole,
                            conversationRequestType,
                            isOpening: !isOpen,
                            numberOfAssignments:
                                getNumberOfAssignments(conversation),
                        });
                    }}
                />
            </PopoverTrigger>
            <StyledPopoverContent
                aria-label="assign this conversation modal"
                align="start"
            >
                <ErrorBoundary
                    fallback={
                        <StyledErrorContainer>
                            <GenericErrorState>
                                <>
                                    Sorry, something went wrong.
                                    <br />
                                    Refresh the page to try again.
                                </>
                            </GenericErrorState>
                        </StyledErrorContainer>
                    }
                >
                    <AssigneeSelectorContent
                        conversation={conversation}
                        isAssigneeSelectorOpen={isOpen}
                        toggle={toggle}
                        allowTeamAssignment={allowTeamAssignment}
                    />
                </ErrorBoundary>
            </StyledPopoverContent>
        </Popover>
    );
};
