import React from "react";

import {
    Icon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { PopoverItemButton } from "@accurx/design/dist/components/Popover";
import { useActive, useCommands, useHelpers } from "@remirror/react";

import {
    BoldIcon,
    BulletListIcon,
    FontSizeIcon,
    ItalicIcon,
    NumberedListIcon,
    RedoIcon,
    UndoIcon,
} from "./Icons";
import { LinkButton } from "./Link";
import {
    EditorButton,
    EditorPopoverTrigger,
    EditorSelectedFontSize,
    StyledEditorButtonGroup,
    StyledMenuSeparator,
    StyledMenuWrapper,
} from "./Menu.styles";

export const EditorMenu = () => {
    const {
        toggleBold,
        toggleItalic,
        toggleHeading,
        toggleBulletList,
        toggleOrderedList,
        undo,
        redo,
        focus,
    } = useCommands();
    const active = useActive();
    const { undoDepth, redoDepth } = useHelpers();

    const handleHeading = () => {
        toggleHeading({ level: 3 });
    };

    const handleCommandAndFocus = (command: () => void) => {
        command();
        focus();
    };

    return (
        <StyledMenuWrapper>
            <StyledEditorButtonGroup>
                <EditorButton
                    disabled={undoDepth() === 0}
                    onClick={() => handleCommandAndFocus(undo)}
                    title="Undo"
                >
                    <UndoIcon />
                </EditorButton>
                <EditorButton
                    disabled={redoDepth() === 0}
                    onClick={() => handleCommandAndFocus(redo)}
                    title="Redo"
                >
                    <RedoIcon />
                </EditorButton>
            </StyledEditorButtonGroup>
            <StyledMenuSeparator />
            <StyledEditorButtonGroup>
                <Popover>
                    <PopoverTrigger>
                        <EditorPopoverTrigger title="Text size">
                            <FontSizeIcon />
                            <EditorSelectedFontSize>
                                {active.heading() ? "Heading" : "Normal Text"}
                            </EditorSelectedFontSize>
                            <Icon theme="Line" name="Arrow" size={3} />
                        </EditorPopoverTrigger>
                    </PopoverTrigger>
                    <PopoverContent width="160px">
                        <PopoverItemButton
                            onClick={() => handleCommandAndFocus(handleHeading)}
                            active={!active.heading()}
                        >
                            <Text
                                variant={!active.heading() ? "label" : "body"}
                                skinny
                            >
                                Normal Text
                            </Text>
                        </PopoverItemButton>
                        <PopoverItemButton
                            onClick={() => handleCommandAndFocus(handleHeading)}
                            active={active.heading()}
                        >
                            <Text
                                variant={active.heading() ? "label" : "body"}
                                skinny
                            >
                                Heading
                            </Text>
                        </PopoverItemButton>
                    </PopoverContent>
                </Popover>
            </StyledEditorButtonGroup>
            <StyledMenuSeparator />
            <StyledEditorButtonGroup>
                <EditorButton
                    disabled={!toggleBold.enabled()}
                    isActive={active.bold()}
                    onClick={() => handleCommandAndFocus(toggleBold)}
                    title="Bold"
                >
                    <BoldIcon />
                </EditorButton>
                <EditorButton
                    disabled={!toggleItalic.enabled()}
                    isActive={active.italic()}
                    onClick={() => handleCommandAndFocus(toggleItalic)}
                    title="Italic"
                >
                    <ItalicIcon />
                </EditorButton>
            </StyledEditorButtonGroup>
            <StyledMenuSeparator />
            <LinkButton />
            <StyledMenuSeparator />
            <StyledEditorButtonGroup>
                <EditorButton
                    disabled={!toggleBulletList.enabled()}
                    isActive={active.bulletList()}
                    onClick={() => handleCommandAndFocus(toggleBulletList)}
                    title="Bullet list"
                >
                    <BulletListIcon />
                </EditorButton>
                <EditorButton
                    disabled={!toggleOrderedList.enabled()}
                    isActive={active.orderedList()}
                    onClick={() => handleCommandAndFocus(toggleOrderedList)}
                    title="Numbered list"
                >
                    <NumberedListIcon />
                </EditorButton>
            </StyledEditorButtonGroup>
        </StyledMenuWrapper>
    );
};
