import { useEffect, useRef } from "react";

import subMilliseconds from "date-fns/subMilliseconds";
import { actions } from "domains/concierge/internal/store";

import { useConciergeDispatch } from "../context";

const INTERVAL = 300_000; // 5 minutes

export const useConversationGroupGarbageCollection = () => {
    const dispatch = useConciergeDispatch();
    const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

    useEffect(() => {
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                const now = new Date();
                const since = subMilliseconds(now, INTERVAL).toISOString();
                dispatch(actions.conversations.garbageCollect({ since }));
            }, INTERVAL);
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [dispatch]);
};
