import { useMemo } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { formatISO, isWeekend as isWeekendDateFns } from "date-fns";
import moment from "moment";

import { useBankHolidays } from "./useBankHolidays";

/**
 *
 * @param selectedDate : accepts a string in ISO date format i.e. 2024-05-09T08:00:00.000Z
 * @description This hook take a ISO date string and and returns whether the selected date is a working day, a weekend or bank holiday. Also returns a formatted date i.e. Thu 09 May 2024 at 9am.
 */

export const useDayDetails = ({
    selectedDate,
}: {
    selectedDate: string | null;
}):
    | { type: "workingDay" | "weekend" | "bankHoliday"; date: string }
    | undefined => {
    const workspace = useCurrentWorkspace();

    const { data: bankHolidays } = useBankHolidays({
        workspaceId: workspace.orgId,
    });

    return useMemo(() => {
        if (!selectedDate) return;

        const d = new Date(selectedDate);

        // Check if date falls on a weekend
        const isWeekend = isWeekendDateFns(new Date(d));

        // Check if date falls on a bank holiday
        const isBankHoliday = bankHolidays?.includes(
            formatISO(d, {
                representation: "date",
            }),
        );

        const ukDate = moment(selectedDate);

        const date = ukDate.format("ddd D MMM yyyy");
        const time = ukDate.format("ha");

        return {
            type: isWeekend
                ? "weekend"
                : isBankHoliday
                ? "bankHoliday"
                : "workingDay",
            date: `${date} at ${time}`,
        };
    }, [selectedDate, bankHolidays]);
};
