import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { UsersAndTeamsManager } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import { useGlobalUsersAndTeamsManager } from "./GlobalUsersAndTeamsManagerContext";

export const UsersAndTeamsManagerContext =
    createContext<UsersAndTeamsManager | null>(null);

type UsersAndTeamsManagerProviderProps = {
    children?: ReactNode;
    workspaceId: number | null;
};

/**
 * UsersAndTeamsManagerProvider
 *
 * Acts as a factory for UsersAndTeamsManager and makes the current instance
 * available via a `useOptionalUsersAndTeamsManager()` hook.
 *
 * When the workspace ID changes it tears down the current manager instance
 * and creates a new one.
 */
export const UsersAndTeamsManagerProvider = ({
    workspaceId,
    children,
}: UsersAndTeamsManagerProviderProps): JSX.Element => {
    const globalUsersAndTeamsManager = useGlobalUsersAndTeamsManager();
    const [manager, setManager] = useState<UsersAndTeamsManager | null>(null);

    useEffect(() => {
        const nextManager =
            workspaceId && Number.isSafeInteger(workspaceId)
                ? globalUsersAndTeamsManager?.forWorkspace(workspaceId)
                : undefined;
        setManager(nextManager?.item ?? null);
        return () => nextManager?.release();
    }, [workspaceId, globalUsersAndTeamsManager]);

    return (
        <UsersAndTeamsManagerContext.Provider value={manager}>
            {/* Always return children, this does mean children are not guaranteed to have access to the manager,
                but allows us to control the UI before the manager is initialised */}
            {children}
        </UsersAndTeamsManagerContext.Provider>
    );
};

/**
 * Export the context provider directly so that our UI tests can easily
 * wrap a component with a fake UsersAndTeamsManager;
 */
export const StaticUsersAndTeamsManagerProvider =
    UsersAndTeamsManagerContext.Provider;

/**
 * Provides a users and teams manager instance if it has been instantiated
 */
export const useOptionalUsersAndTeamsManager =
    (): UsersAndTeamsManager | null => {
        return useContext(UsersAndTeamsManagerContext);
    };

/**
 * Provides a users and teams manager manager instance
 */
export const useUsersAndTeamsManager = (): UsersAndTeamsManager => {
    const manager = useOptionalUsersAndTeamsManager();

    if (!manager) {
        throw new Error(
            "useUsersAndTeamsManager called outside the <UsersAndTeamsManagerProvider /> or before it has been initialised",
        );
    }

    return manager;
};
