import React from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledBulletWrapper = styled.div`
    display: flex;
    margin: ${Tokens.SIZES[1.5]} 0;
`;
export const StyledBulletIconPreview = styled.div``;
export const StyledBulletIconEdit = styled.div`
    margin-top: 60px;
`;
export const StyledBulletText = styled.div`
    flex-grow: 1;
    padding-left: ${Tokens.SIZES[1]};
`;
export const StyledSpacing = styled.div`
    margin-top: ${Tokens.SIZES[1]};
`;

const StyledIconSvg = styled.svg`
    height: ${Tokens.SIZES[2]};
    width: ${Tokens.SIZES[2]};
`;

// Presentational icon only, so not adding title element
export const RadioButtonSVG = (): JSX.Element => (
    <StyledIconSvg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="10"
            cy="10"
            r="8"
            strokeWidth="2"
            stroke={Tokens.COLOURS.greyscale.silver}
            fill="none"
        ></circle>
    </StyledIconSvg>
);
export const CheckboxSVG = (): JSX.Element => (
    <StyledIconSvg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="2"
            y="2"
            height="16"
            width="16"
            rx="4"
            strokeWidth="2"
            stroke={Tokens.COLOURS.greyscale.silver}
            fill="none"
        ></rect>
    </StyledIconSvg>
);
