import { AssigneeType, PatientThreadAssignee } from "@accurx/api/ticket";
import { AssigneeSummary } from "domains/concierge/types";

export const mapConversationAssigneeToTicketAssignee = (
    arg: AssigneeSummary,
): PatientThreadAssignee => {
    return arg.type === "Team"
        ? {
              type: AssigneeType.UserGroup,
              userGroupId: arg.id,
          }
        : arg.type === "User"
        ? {
              type: AssigneeType.User,
              userId: arg.id,
          }
        : {
              type: AssigneeType.None,
          };
};
