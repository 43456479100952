import React from "react";

import { WORKSPACE_MANAGEMENT_PRIMARY_ROUTES } from "@accurx/workspace-management";
import { useLocation, useRouteMatch } from "react-router-dom";

import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES, ROUTES_PRIMARY, Route } from "shared/Routes";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import { StyledContainer, StyledLinkList } from "./SecondaryNavigation.styles";

const SimplifiedNavigation = (): JSX.Element => {
    let navText = "";
    let header: string | undefined = undefined;
    let navLink: Route | undefined = undefined;

    const joinOrgMatch = useRouteMatch({ path: [ROUTES.joinOrganisation] });
    const addOrgMatch = useRouteMatch({ path: [ROUTES.addOrganisation] });
    const joinPharmacyMatch = useRouteMatch({ path: [ROUTES.joinPharmacy] });
    const getStartedMatch = useRouteMatch({ path: [ROUTES.get_started] });
    const workspaceInvitationMatch = useRouteMatch({
        path: [ROUTES.workspaceInvitation],
    });
    const matchWorkspaceConversations = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.workspaceConversations],
    });
    const matchFlexibleWorkspace = useRouteMatchOrTwoFactorPrompt({
        path: [WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding],
    });

    const location = useLocation();
    if (joinOrgMatch) {
        navText = "Confirm your details";
        header = "Onboarding";
        navLink = ROUTES.joinOrganisation;
    } else if (addOrgMatch) {
        navText = "Confirm your details";
        header = "Onboarding";
        navLink = ROUTES.addOrganisation;
    } else if (joinPharmacyMatch) {
        navText = "Confirm your details";
        header = "Onboarding";
        navLink = ROUTES.joinPharmacy;
    } else if (getStartedMatch) {
        navText = "Let's get started";
        header = "Onboarding";
        navLink = ROUTES.get_started;
    } else if (workspaceInvitationMatch) {
        header = "Invitation";
    } else if (matchWorkspaceConversations) {
        header = "Conversations";
    } else if (matchFlexibleWorkspace) {
        header = "Workspaces";
    }

    // If there is a defined section header and nav link then use that otherwise we
    // use a blank secondary nav
    return (
        <StyledContainer>
            {header !== undefined && (
                <SecondaryNavigationHeader>{header}</SecondaryNavigationHeader>
            )}
            {navLink !== undefined && (
                <SecondaryNavigationContent>
                    <StyledLinkList>
                        <li>
                            <NavLink
                                to={navLink}
                                text={navText}
                                variant={LinkVariant.Secondary}
                                toQueryParams={location.search}
                            />
                        </li>
                    </StyledLinkList>
                </SecondaryNavigationContent>
            )}
        </StyledContainer>
    );
};

export { SimplifiedNavigation };
