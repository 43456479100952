import { Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const RouterLink = styled(Link)`
    text-decoration: underline;
    margin: 0;
    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        box-shadow: none;
    }
    &:focus,
    &:active {
        box-shadow: none !important;
        border: none;
    }
    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${Tokens.COLOURS.primary.blue[50]} !important;
    }
`;
