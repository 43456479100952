import { ReactNode } from "react";

import { Ds } from "@accurx/design";
import { FocusRingSpacing, NavLink } from "@accurx/navigation";
import { Link } from "react-router-dom";

import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

export const SecondaryNavLink = ({
    to,
    icon,
    children,
}: {
    to: string;
    icon?: Pick<Ds.IconProps, "name" | "appearance" | "rotate">;
    children: ReactNode;
}) => {
    const isActive = Boolean(useRouteMatchOrTwoFactorPrompt({ path: to }));

    return (
        <FocusRingSpacing>
            <NavLink isActive={isActive} asChild>
                <Link to={to}>
                    {icon && <NavLink.Icon {...icon} />}
                    <NavLink.Text>{children}</NavLink.Text>
                </Link>
            </NavLink>
        </FocusRingSpacing>
    );
};
