import { Feedback } from "@accurx/design";

import { Template } from "../../MessageTemplates.types";
import { MessageTemplateCard } from "../MessageTemplateCard";
import {
    StyledList,
    StyledTemplateListContainer,
} from "./MessageTemplateList.styles";

type MessageTemplateListProps = {
    templates: Template[];
    isLoading?: boolean;
    onClickUseTemplate: (template: Template) => void;
    onClickPreviewTemplate: (template: Template) => void;
};

export const MessageTemplateList = ({
    templates,
    isLoading,
    onClickUseTemplate,
    onClickPreviewTemplate,
}: MessageTemplateListProps): JSX.Element => {
    if (isLoading) {
        return (
            <StyledList>
                <li>
                    <MessageTemplateCard isLoading />
                </li>
                <li>
                    <MessageTemplateCard isLoading />
                </li>
                <li>
                    <MessageTemplateCard isLoading />
                </li>
                <li>
                    <MessageTemplateCard isLoading />
                </li>
            </StyledList>
        );
    }

    return (
        <StyledTemplateListContainer>
            {templates.length === 0 && (
                <Feedback
                    colour="information"
                    content="Your list is empty"
                    iconName="Info"
                />
            )}
            {templates.length > 0 && (
                <StyledList>
                    {templates.map((template, index) => (
                        <li key={`${template.title}-${index}`}>
                            <MessageTemplateCard
                                template={template}
                                onClickUseTemplate={onClickUseTemplate}
                                onClickPreviewTemplate={onClickPreviewTemplate}
                            />
                        </li>
                    ))}
                </StyledList>
            )}
        </StyledTemplateListContainer>
    );
};
