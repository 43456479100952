import * as UI from "@accurx/design";
import { i } from "images";

import { StyledContainer } from "./ConversationViewEmptyState.styles";

export const ConversationViewEmptyState = () => {
    return (
        <StyledContainer
            flexDirection="column"
            gap="1.5"
            alignItems="center"
            justifyContent="center"
        >
            <img
                src={i("illustrations/message-bubbles-80px@2x.png")}
                srcSet={`${i("illustrations/message-bubbles-80px.png")}, ${i(
                    "illustrations/message-bubbles-80px@2x.png",
                )} 2x, ${i("illustrations/message-bubbles-80px@3x.png")} 3x`}
                alt="Select a conversation"
                height="80"
            />
            <UI.Text colour="metal">
                Select a conversation from the
                <br /> list to see it here
            </UI.Text>
        </StyledContainer>
    );
};
