import { useCurrentWorkspace } from "@accurx/auth";
import { PatientDemographics } from "@accurx/native";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { mapDemographicsToPatientDataForRequestPayload } from "./useGenerateTriageReceptionFlowTokenMutation.utils";

type MutationVariables = {
    patient: PatientDemographics;
};

type Response = { token: string };

export const useGenerateTriageReceptionFlowTokenMutation = (
    options?: UseMutationOptions<Response, Error, MutationVariables>,
) => {
    const workspace = useCurrentWorkspace();

    return useMutation(
        ["generateTriageReceptionFlowToken"],
        async (variables: MutationVariables) => {
            const request = {
                ...mapDemographicsToPatientDataForRequestPayload(
                    variables.patient,
                ),
                organisationId: workspace.orgId,
                orgCode: workspace.nationalCode,
            };

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/patienttriage/patientdetails/generatetoken",
                }),
                request,
            );

            return returnDataOrThrow<Response>(response);
        },
        options,
    );
};
