import { NativeApi } from "../../api/NativeApi";
import { createEndpoint } from "../../api/api.utils";

export const mutateSignalRInvoke = createEndpoint<
    void,
    { methodName: string; payload?: object }
>("MutateSignalRClientInvoke", async (context, { methodName, payload }) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateSignalRClientInvoke",
            workspaceId: context.workspaceId,
            payload: {
                methodName,
                parameter: payload,
            },
        },
    });
});
