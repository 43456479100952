import React, { useEffect, useState } from "react";

import { Card, Feedback, Spinner, Text } from "@accurx/design";

import { useSafeAsync } from "api/Api.utils";
import { getVaccineCapacity } from "api/VaccineApi";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { UpdatingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import {
    VaccineCapacityDetails,
    VaccineDeliverySiteStats,
    WeekView,
} from "./CapacityDashboard.type";
import { CapacityFilter } from "./CapacityFilter";

export const VaccineCapacityDashboard = ({
    noHeader = false,
}: {
    noHeader: boolean;
}): JSX.Element => {
    const safeAsync = useSafeAsync();

    const [sites, setSites] = useState<VaccineDeliverySiteStats[]>([]);
    const [error, setError] = useState<string>("");
    const [weekViewSelected, setWeekViewSelected] = useState<WeekView>(
        WeekView.upTo3Weeks,
    );
    const [fetchingDashboardStatsStatus, setFetchingDashboardStatsStatus] =
        useState<UpdatingStatus>(UpdatingStatus.Initial);
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    useEffect(() => {
        const getDashboardStats = async (): Promise<void> => {
            if (
                fetchingDashboardStatsStatus === UpdatingStatus.Initial &&
                practiceId
            ) {
                setFetchingDashboardStatsStatus(UpdatingStatus.Loading);

                const { success, result, error } = await safeAsync(
                    getVaccineCapacity(practiceId),
                );
                if (success && result) {
                    setFetchingDashboardStatsStatus(UpdatingStatus.Loaded);
                    setSites(result.sites);
                } else {
                    setFetchingDashboardStatsStatus(UpdatingStatus.Failed);
                    setError(
                        error ||
                            "There was a problem fetching your capacity details. Please refresh the page.",
                    );
                }
            }
        };
        getDashboardStats();
    }, [safeAsync, fetchingDashboardStatsStatus, practiceId]);

    const renderTableRow = (
        site: VaccineDeliverySiteStats,
        index: number,
    ): JSX.Element => {
        let row: VaccineCapacityDetails;
        switch (weekViewSelected) {
            case WeekView.upTo3Weeks:
                row = site.upTo3Weeks;
                break;
            case WeekView.pastSlots:
                row = site.pastSlots;
                break;
            case WeekView.moreThan3Weeks:
                row = site.moreThan3Weeks;
                break;
            default:
                row = site.upTo3Weeks;
        }
        return (
            <tr key={index}>
                <Text as="td" variant="label">
                    {site.locationText}
                </Text>
                <Text as="td">{row.total - row.booked}</Text>
                <Text as="td">{row.booked}</Text>
                <Text as="td">{row.total}</Text>
            </tr>
        );
    };

    const renderDashboard = (): JSX.Element => {
        switch (fetchingDashboardStatsStatus) {
            case UpdatingStatus.Initial:
            case UpdatingStatus.Loading:
                return <Spinner />;
            case UpdatingStatus.Failed:
                return (
                    <Feedback
                        colour="error"
                        title="Something went wrong"
                        content={error}
                    />
                );
            default:
            case UpdatingStatus.Loaded:
                if (sites.length === 0) {
                    return (
                        <Feedback
                            colour="error"
                            title="Something went wrong"
                            content="You are currently not linked to any Vaccine Delivery Sites"
                        />
                    );
                }
                return (
                    <Card spacing={0}>
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <Text
                                        as="th"
                                        variant="label"
                                        className="border-top-0"
                                    >
                                        Site
                                    </Text>
                                    <Text
                                        as="th"
                                        variant="label"
                                        className="border-top-0"
                                    >
                                        Unbooked
                                    </Text>
                                    <Text
                                        as="th"
                                        variant="label"
                                        className="border-top-0"
                                    >
                                        Booked
                                    </Text>
                                    <Text
                                        as="th"
                                        variant="label"
                                        className="border-top-0"
                                    >
                                        Total
                                    </Text>
                                </tr>
                            </thead>
                            <tbody>
                                {sites.length > 0 &&
                                    sites.map((site, index) =>
                                        renderTableRow(site, index),
                                    )}
                            </tbody>
                        </table>
                    </Card>
                );
        }
    };

    return (
        <>
            {!noHeader && (
                <>
                    <NavSubMenuComponent>
                        <Breadcrumb title="Dashboard" wrapper={false} />
                        <Text variant="title" as="h1" skinny className="mt-2">
                            COVID-19 Vaccine
                            {practiceName && (
                                <Text
                                    variant="subtitle"
                                    as="span"
                                    props={{ className: "mx-1" }}
                                    skinny
                                >
                                    {` · ${practiceName}`}
                                </Text>
                            )}
                        </Text>
                    </NavSubMenuComponent>
                    <Text variant="subtitle" as="h2">
                        Dashboard
                    </Text>
                </>
            )}
            <Text variant="subtitle" as="h3">
                Overview of capacity
            </Text>
            <Text>
                This shows the total of Unbooked and Booked slots for patients
                for all published clinics
            </Text>
            <CapacityFilter
                weekViewSelected={weekViewSelected}
                setWeekViewSelected={setWeekViewSelected}
            />
            {renderDashboard()}
        </>
    );
};

export default VaccineCapacityDashboard;
