import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled(UI.Flex)`
    text-align: center;
    height: 100%;
`;

export const StyledErrorMessage = styled(UI.Text).attrs({
    skinny: true,
    colour: "metal",
})`
    width: 234px;
`;
