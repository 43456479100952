import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export type UploadFailureInfoModalProps = {
    handleClose: () => void;
    errorMessage: string;
};

export const UploadFailureInfoModal = ({
    handleClose,
    errorMessage,
}: UploadFailureInfoModalProps): JSX.Element => {
    const onCloseClick = (): void => {
        handleClose();
    };

    const renderInfoCard = (text: string, link: string): JSX.Element => {
        return (
            <Card spacing={1.5} props={{ className: "mb-1" }}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <Icon name="Document" colour="night" size={4} />
                        <Text variant="label" colour="night" skinny>
                            {text}
                        </Text>
                    </div>
                    <Text
                        variant="link"
                        as="a"
                        skinny
                        props={{
                            href: link,
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        View
                    </Text>
                </div>
            </Card>
        );
    };

    return (
        <Modal
            isOpen
            backdrop="static"
            centered
            data-testid="bulk-add-info-modal"
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h3" colour="night" skinny>
                    It looks like this CSV file isn't set-up in the right way
                    for us
                </Text>
            </ModalHeader>
            <ModalBody
                className="bg-light"
                data-testid="bulk-add-patients-modal-content"
            >
                {errorMessage && (
                    <Feedback
                        colour="error"
                        title={errorMessage}
                        props={{ className: "mb-3" }}
                    />
                )}
                <Text props={{ className: "mb-2" }} skinny>
                    To get the most out of patient lists, check out these
                    articles on how to upload multiple patients:
                </Text>
                {renderInfoCard(
                    "How to upload multiple patients with appointments",
                    SupportUrls.PatientListBulkAddPatientsWithAppointments,
                )}
                {renderInfoCard(
                    "How to upload multiple patients to 'Anytime'",
                    SupportUrls.PatientListGuide,
                )}
            </ModalBody>
            <ModalFooter>
                <Button text="Close" theme="secondary" onClick={onCloseClick} />
            </ModalFooter>
        </Modal>
    );
};
