export const getBatchMessageCsvDownloadUrl = ({
    workspaceId,
    batchId,
    include,
}: {
    workspaceId: string;
    batchId: string;
    include: string;
}): string =>
    `/api/patientMessaging/BatchMessaging/${workspaceId}/${batchId}/csv?include=${include}`;

export const getBatchMessageUncontactablePatientsCsvDownloadUrl = ({
    workspaceId,
    batchId,
}: {
    workspaceId: string;
    batchId: string;
}): string =>
    `/api/patientMessaging/BatchMessaging/${workspaceId}/${batchId}/csv/uncontactablepatients`;
