import { PatientThreadPatientDetails } from "@accurx/api/ticket";
import { mapDateOfBirthToPatientSummaryAgeParts } from "domains/concierge/internal/api/shared/mappers/patients/mapDateOfBirthToPatientSummaryAgeParts";
import { mapTicketPatientExternalIdsToPatientSummaryExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientExternalIdsToPatientSummaryExternalIds";
import { mapPatientGenderToPatientSummaryGender } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientGenderToPatientSummaryGender";
import { mapPatientNamesToPatientSummaryParts } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientNamesToPatientSummaryParts";
import { PatientSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

export const mapPatientThreadPatientDetailsToPatientSummary = (
    dto: PatientThreadPatientDetails,
): PatientSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }

    return {
        ...mapPatientNamesToPatientSummaryParts(dto.names),
        ...mapDateOfBirthToPatientSummaryAgeParts(dto.dateOfBirth),
        gender: mapPatientGenderToPatientSummaryGender(dto.gender),
        patientId: dto.accuRxId,
        externalIds: mapTicketPatientExternalIdsToPatientSummaryExternalIds(
            dto.patientExternalIdentity?.patientExternalIds,
        ),
        demographicsLastRefreshed: dto.demographicsLastRefreshed
            ? new Date(dto.demographicsLastRefreshed).toISOString()
            : null,
    };
};
