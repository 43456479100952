import { useCallback, useEffect, useRef, useState } from "react";

import { useDeepMemo } from "@accurx/hooks";
import { useConversationGroup } from "domains/concierge/hooks/data/useConversationGroup";
import { useConciergeDispatch } from "domains/concierge/internal/context";
import { useIsConversationGroupFresh } from "domains/concierge/internal/hooks/useIsConversationGroupFresh";
import { actions } from "domains/concierge/internal/store";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";
import { conversationGroupId } from "domains/concierge/internal/util/conversationGroupId";
import { useMutation } from "domains/concierge/internal/util/useMutation";
import slice from "lodash/slice";

export type ConversationGroupFetcher = (continuationToken?: string) => Promise<{
    continuationToken?: string;
    updates: ConciergeUpdates;
}>;

export type UseConversationGroupQueryProps = {
    filters: ConversationGroup["filters"];
    sortOptions: ConversationGroup["sortOptions"];
    loggingInfo: ConversationGroup["loggingInfo"];
    fetcher: ConversationGroupFetcher;
    useCache?: boolean;
};

export type ConversationGroupQueryResult =
    | {
          status: "loading";
          data: null;
          error: null;
          refetch: undefined;
          fetchMore: undefined;
          isFetchingMore: undefined;
      }
    | {
          status: "success" | "error";
          data: ConversationGroup;
          error: Error | null;
          refetch: () => void;
          fetchMore: () => void;
          isFetchingMore: boolean;
      };

export type ConversationGroupQueryOptions = { enabled?: boolean };

export type ConversationGroupQueryHook = (
    params: UseConversationGroupQueryProps,
    options?: ConversationGroupQueryOptions,
) => ConversationGroupQueryResult;

export const useConversationGroupQuery: ConversationGroupQueryHook = (
    { filters, sortOptions, loggingInfo, fetcher, useCache },
    { enabled } = { enabled: true },
) => {
    const dispatch = useConciergeDispatch();
    const filtersMemo = useDeepMemo(filters);
    const sortOptionsMemo = useDeepMemo(sortOptions);
    const loggingInfoMemo = useDeepMemo(loggingInfo);
    const id = conversationGroupId(filtersMemo, sortOptionsMemo);
    const group = useConversationGroup({ id });

    // hasLoaded is used to differentiate between the initial loading state and
    // the background fetch more loading state.
    const [hasLoaded, setHasLoaded] = useState<boolean>(!!group);

    // store a flag for if the group is fresh on initial mount. we use
    // this to know if we need to perform the initial fetch.
    const isFresh = useIsConversationGroupFresh({ groupId: id });
    const isFreshRef = useRef(isFresh);
    isFreshRef.current = isFresh;

    const conversationGroupName = loggingInfoMemo.name.replaceAll(" ", "-");

    const {
        mutate: fetchGroup,
        error,
        status,
        reset,
    } = useMutation(
        `ConversationGroup-${conversationGroupName}-${id}`,
        async (continuationToken?: string) => {
            const response = await fetcher(continuationToken);

            dispatch(
                actions.processUpdates({
                    source: "Query:ConversationGroup",
                    ...response.updates,
                }),
            );
            dispatch(
                actions.conversations.updateGroupCeiling({
                    id,
                    conversations: response.updates.conversations,
                    continuationToken: response.continuationToken,
                }),
            );
        },
        {
            onSettled: () => {
                setHasLoaded(true);
            },
        },
    );

    const fetchMore = useCallback(
        () => fetchGroup(group?.continuationToken),
        [fetchGroup, group?.continuationToken],
    );

    const refetch = useCallback(() => {
        setHasLoaded(false);
        fetchGroup(group?.continuationToken);
    }, [fetchGroup, group?.continuationToken]);

    /**
     * Subscribe to the group in the store on mount
     * and unsubscribe on unmount
     */
    useEffect(() => {
        if (!enabled) return;
        setHasLoaded(isFreshRef.current);
        reset();
        dispatch(
            actions.conversations.subscribeToGroup({
                filters: filtersMemo,
                sortOptions: sortOptionsMemo,
                loggingInfo: loggingInfoMemo,
                useCache,
            }),
        );

        if (!isFreshRef.current) {
            fetchGroup(undefined);
        }

        return () => {
            dispatch(
                actions.conversations.unsubscribeFromGroup({
                    filters: filtersMemo,
                    sortOptions: sortOptionsMemo,
                }),
            );
        };
    }, [
        enabled,
        dispatch,
        useCache,
        filtersMemo,
        sortOptionsMemo,
        loggingInfoMemo,
        fetchGroup,
        reset,
    ]);

    // Memoize the members list to prevent unnecessary rerenders
    const membersDenseSet = slice(group?.members ?? [], 0, group?.ceiling);
    const members = useDeepMemo(membersDenseSet);

    if (!group || !hasLoaded) {
        return {
            status: "loading",
            data: null,
            error: null,
            refetch: undefined,
            fetchMore: undefined,
            isFetchingMore: undefined,
        };
    }

    return {
        status: status === "error" ? "error" : "success",
        data: {
            ...group,
            members,
        },
        error,
        refetch,
        fetchMore,
        isFetchingMore: status === "loading",
    };
};
