import { Button, Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        flex-wrap: nowrap;
        flex-direction: row;
    }
`;

export const StyledSidebar = styled.div`
    flex-shrink: 0;
    width: 100%;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        width: auto;
    }
`;

export const StyledMainContent = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100vw;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
`;

export const QuickViewContainer = styled.div``;
export const PageContainer = styled.div``;

export const StyledQuickViewWrapper = styled(Ds.Grid)<{
    $quickViewIsOpen: boolean;
}>`
    display: flex;
    width: 100%;
    height: 100%;

    ${QuickViewContainer} {
        box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);
        width: 100%;
        position: relative;
        @media (min-width: 1200px) {
            min-width: 414px;
            flex: 0.5;
        }
        &:empty {
            min-width: 0;
            flex: 0;
        }
    }
    ${PageContainer} {
        min-width: 0;
        flex: 1;
        overflow: auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const StyledTriggerButton = styled(Button).attrs({
    theme: "success",
})`
    & span {
        max-width: 20ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &[data-state="open"] {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
    }
`;

export const StyledMessageButton = styled(Button).attrs({
    theme: "secondary",
})`
    box-shadow: none;
    border-bottom-width: 3px;
    border-color: ${Tokens.COLOURS.primary.green["130"]};
    color: ${Tokens.COLOURS.primary.green["130"]};

    & path {
        fill: currentColor;
    }

    &[data-state="open"] {
        background-color: ${Tokens.COLOURS.primary.green["25"]};
    }
`;
