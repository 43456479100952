import { useState } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { ComposeProvider } from "@accurx/compose";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/types";
import { generateClinicianGreeting } from "domains/inbox/util/greeting";
import { generateSignature } from "domains/inbox/util/signature";

import { MessageActionsBar } from "../MessageActionsBar/MessageActionsBar";
import { ClinicianReplyContents } from "./ClinicianReplyContents";

type ClinicianReplyProps = { conversation: Conversation };

export const ClinicianReply = ({ conversation }: ClinicianReplyProps) => {
    const { user } = useCurrentUser();
    const workspace = useCurrentWorkspace();

    const [isClosed, setIsClosed] = useState(true);

    const patient = usePatient({ patientId: conversation.regardingPatientId });
    const userSignature = generateSignature(user);
    const greeting = generateClinicianGreeting(patient);
    const signature = `${userSignature}\n${workspace.organisationName}`;

    if (isClosed) {
        return (
            <MessageActionsBar
                isComposing={false}
                isComposingNote={false}
                toggleReply={() => setIsClosed(false)}
                conversation={conversation}
            />
        );
    }

    return (
        <ComposeProvider
            settings={{
                greeting,
                editableBody: "\n", // Start with an extra empty space
                editableSignature: signature,
                maxAttachmentCount: 4,
                canUseQuestionnaires: false,
                conversationParticipant: "WithHcp",
            }}
        >
            <ClinicianReplyContents
                conversation={conversation}
                patient={patient}
                onClose={() => setIsClosed(true)}
            />
        </ComposeProvider>
    );
};
