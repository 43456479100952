import { useRouteMatch } from "react-router";

export const useWorkspaceId = (): number => {
    const params = useRouteMatch<{ workspaceId: string }>({
        path: ["*/w/:workspaceId"],
        strict: false,
        sensitive: false,
    })?.params;

    if (!params?.workspaceId) {
        throw new Error("Path is missing workspace ID");
    }

    return parseInt(params.workspaceId, 10);
};
