import { FeatureName } from "@accurx/auth";
import { useParams } from "react-router-dom";

import {
    OrgFeatureFlagStatus,
    useIsFeatureEnabledForUserOrgs,
} from "store/hooks";

export const useChooseOrgPage: () => {
    shareId: string;
    orgsWithFeatureFlagEnabled: OrgFeatureFlagStatus[];
    orgsWithFeatureFlagDisabled: OrgFeatureFlagStatus[];
} = () => {
    const { shareId } = useParams<{ shareId: string }>();
    const { orgsWithFeatureFlagEnabled, orgsWithFeatureFlagDisabled } =
        useIsFeatureEnabledForUserOrgs(FeatureName.FloreyBuilder);
    return {
        shareId,
        orgsWithFeatureFlagEnabled,
        orgsWithFeatureFlagDisabled,
    };
};
