import { createConversationSubscription } from "shared/concierge/conversations/subscriptions/ConversationSubscription";
import { createSearchedPatientConversationActions } from "shared/concierge/conversations/tickets/SearchedPatientConversationActions";
import { ConversationActions } from "shared/concierge/conversations/types/component.types";
import {
    Conversation,
    ConversationIdentity,
} from "shared/concierge/conversations/types/conversation.types";
import { Subscription } from "shared/concierge/types/subscription.types";

import { getSearchedPatientConversationsFeed } from "./patientConversationUpdatesFeed";

type SearchedPatientSubscriptionOptions = {
    conversationIdentity: ConversationIdentity;
    currentPatientToken: string;
    currentUserId: string;
    currentWorkspaceId: number;
};

export const setupSearchedPatientConversationSubscription = ({
    conversationIdentity,
    currentPatientToken,
    currentWorkspaceId,
    currentUserId,
}: SearchedPatientSubscriptionOptions): {
    subscription: Subscription<Conversation>;
    actions: ConversationActions;
} => {
    const actions = createSearchedPatientConversationActions(
        currentWorkspaceId,
        currentPatientToken,
    );

    const conversationsUpdateFeed = getSearchedPatientConversationsFeed(
        currentUserId,
        actions.conversationsFeed,
    );

    const subscription = createConversationSubscription({
        conversationIdentity,
        fetchData: () => actions.getConversation(conversationIdentity),
        conversationsFeed: conversationsUpdateFeed,
    });

    return { subscription, actions };
};
