import { useCurrentUserQuery } from "./hooks/queries/useCurrentUserQuery";
import { User } from "./types/User";
import { useUpdateCurrentUser } from "./useUpdateCurrentUser";

export const useCurrentUser = (): {
    user: User;
    refetch: ReturnType<typeof useCurrentUserQuery>["refetch"];
    update: (updates: User) => void;
} => {
    const { data: user, refetch } = useCurrentUserQuery({
        enabled: false,
    });

    if (!user) {
        window.location.href = "/login";
        throw new Error(
            "[useCurrentUser] No current user, but there should be",
        );
    }

    if (!user.isLoggedIn) {
        window.location.href = "/login";
        throw new Error("User is not logged in");
    }

    const update = useUpdateCurrentUser();

    return { user, refetch, update };
};
