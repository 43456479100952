import { Feedback } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import NotFound from "NotFound";
import { Redirect, Switch, generatePath, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AppRoute, SimpleRoute } from "app/AppRoute";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import { useBatchDetailsQuery } from "app/hooks/queries/useBatchDetailsQuery";
import {
    ROUTES,
    ROUTES_BASE,
    ROUTES_PRIMARY,
    ROUTES_WORKSPACE,
} from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { useCurrentUserId } from "store/hooks";

import { BatchMessageOverviewPage } from "../shared/BatchMessageOverviewPage";
import { AddPatientsFromPatientListPage } from "./pages/AddPatientsFromPatientList";
import { AddPatientsPage } from "./pages/AddPatientsPage";
import { AddPatientsStatusPage } from "./pages/AddPatientsStatusPage";
import { AddPatientsViaCopyPastePage } from "./pages/AddPatientsViaCopyPaste";
import { AddPatientsViaFileUploadPage } from "./pages/AddPatientsViaFileUpload";
import { ComposePage } from "./pages/ComposePage";
import { ConfirmPage } from "./pages/ConfirmPage";

/**
 * Includes the routes to send a batch after it has been uploaded
 *
 * For each of these routes we are doing some extra guarding i.e.
 * prevent user from accessing data from another user's uploaded list
 */
const BatchMessageSendWithBatchIdSection = () => {
    const { batchId, workspaceId } = useParams<{
        batchId: string;
        workspaceId: string;
    }>();

    const currentUserId = useCurrentUserId();
    const batchDetailsQuery = useBatchDetailsQuery({
        workspaceId,
        batchId: batchId || "",
        enabled: !!batchId,
    });

    // If a user is trying to view a batch upload made by another user,
    // redirect them away.
    if (
        batchDetailsQuery.status === "success" &&
        batchDetailsQuery.data.sender.id !== currentUserId
    ) {
        toast(<Feedback title="Unable to view batch upload" colour="error" />);

        return <Redirect to={ROUTES.home} />;
    }

    return (
        <Switch>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustReview} exact>
                <AddPatientsStatusPage />
            </AppRoute>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustCompose} exact>
                <ComposePage />
            </AppRoute>
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustConfirm} exact>
                <ConfirmPage />
            </AppRoute>
            <AppRoute path="*">
                <NotFound />
            </AppRoute>
        </Switch>
    );
};

/**
 * Routes that are under the /w/:workspaceId/batch-messages umbrella
 */
export const BatchMessageTrustSection = (): JSX.Element => {
    useAccurxWebTitle("Batch messaging");
    const workspaceId = useWorkspaceId();

    return (
        <Switch>
            {/* Redirects */}
            <AppRoute
                path={[
                    ROUTES_PRIMARY.batchMessageTrust,
                    ROUTES_BASE.batchMessageTrustSend,
                ]}
                exact
            >
                <Redirect
                    to={generatePath(ROUTES_WORKSPACE.batchMessageTrustUpload, {
                        workspaceId,
                    })}
                />
            </AppRoute>

            <SimpleRoute
                path={ROUTES_WORKSPACE.batchMessageTrustSentMessages}
                exact
            >
                <BatchMessageOverviewPage />
            </SimpleRoute>

            {/* Upload list routes */}
            <AppRoute path={ROUTES_WORKSPACE.batchMessageTrustUpload} exact>
                <AddPatientsPage />
            </AppRoute>
            <SimpleRoute
                path={ROUTES_WORKSPACE.batchMessageTrustUploadViaFile}
                exact
            >
                <AddPatientsViaFileUploadPage />
            </SimpleRoute>
            <GuardedRoute
                path={ROUTES_WORKSPACE.batchMessageTrustUploadImportPatientList}
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
                exact
            >
                <AddPatientsFromPatientListPage />
            </GuardedRoute>
            <SimpleRoute
                path={ROUTES_WORKSPACE.batchMessageTrustUploadViaCopyPaste}
                exact
            >
                <AddPatientsViaCopyPastePage />
            </SimpleRoute>

            {/* Send routes */}
            <AppRoute
                path={[
                    ROUTES_WORKSPACE.batchMessageTrustReview,
                    ROUTES_WORKSPACE.batchMessageTrustCompose,
                    ROUTES_WORKSPACE.batchMessageTrustConfirm,
                ]}
            >
                <BatchMessageSendWithBatchIdSection />
            </AppRoute>

            <AppRoute path="*">
                <NotFound />
            </AppRoute>
        </Switch>
    );
};
