import { MouseEventHandler } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { useMedicalRecordConnection } from "@accurx/native";
import { DropdownItem } from "domains/compose/components/DropdownItem";

type AttachFileFromPatientRecordButtonProps = {
    patientNhsNumber: string | null | undefined;
    isDisabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const AttachFileFromPatientRecordButton = ({
    isDisabled,
    patientNhsNumber,
    onClick,
}: AttachFileFromPatientRecordButtonProps) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );
    const { status, system, capabilities } = useMedicalRecordConnection();

    if (status !== "Connected") {
        return null;
    }

    // Some medical records don't support attachments at all
    if (capabilities.attachDocumentsFromRecord === "NotSupported") {
        return null;
    }

    // Some medical records only support attachments for patients with an NHS
    // Number
    if (
        capabilities.attachDocumentsFromRecord === "SupportedWithNhsNumber" &&
        !patientNhsNumber
    ) {
        return null;
    }

    return (
        <DropdownItem.Button
            aria-label={
                isMessageComponentV1_1Enabled
                    ? `Upload ${system} file`
                    : `Upload file from ${system}`
            }
            text={
                isMessageComponentV1_1Enabled
                    ? `${system} file`
                    : `File from ${system}`
            }
            onClick={onClick}
            disabled={isDisabled}
            theme={"transparent"}
            icon={{
                name: isMessageComponentV1_1Enabled ? "Paperclip" : "Upload",
                colour: isDisabled ? "stone" : "zinc",
            }}
        />
    );
};
