import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledOuterContainer = styled.div`
    padding: 0 ${UI.Tokens.SIZES[3]} ${UI.Tokens.SIZES[3]};
    // create a new stacking context so that the top box-shadow remains visible
    position: relative;
`;

export const StyledInnerContainer = styled.section`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-radius: ${UI.Tokens.BORDERS.radius};
    border: ${UI.Tokens.BORDERS.normal.silver};
    box-shadow: 0px ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[2]}
        rgba(67.83, 72.3, 125.97, 0.32);
    overflow: hidden;
`;

export const StyledTabsBar = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
})`
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    padding-right: ${UI.Tokens.SIZES[2]};
`;

export const StyledSearchForPatientFeedbackContainer = styled.div`
    padding: ${UI.Tokens.SIZES[1.5]};
`;

export const StyledSearchForPatientFeedback = styled(UI.Flex)`
    padding: ${UI.Tokens.SIZES["2"]};

    background-color: ${UI.Tokens.COLOURS.primary.yellow["10"]};
    border-radius: ${UI.Tokens.BORDERS.radius};
    border: ${UI.Tokens.BORDERS.soft.yellow};
`;
