import { LoadingStatus, LoadingStatusType } from "shared/LoadingStatus";

const UploadSteps = {
    Initial: {
        title: "Upload a list of patients",
    },
    Demographics: {
        title: "Preview list of patients",
    },
    Review: {
        title: "Preview list of patients",
    },
};

type getCurrentUploadStepProps = {
    csvUploadStatus: LoadingStatusType;
    batchUploadStatus: LoadingStatusType;
    uploadDetailsStatus: LoadingStatusType;
};

export const getCurrentUploadStep = ({
    csvUploadStatus,
    batchUploadStatus,
    uploadDetailsStatus,
}: getCurrentUploadStepProps) => {
    let pageTitle = UploadSteps.Initial.title;

    const isStateInitial =
        csvUploadStatus === LoadingStatus.Initial ||
        batchUploadStatus === LoadingStatus.Failed;
    const isStateDemographics =
        csvUploadStatus === LoadingStatus.Loaded &&
        uploadDetailsStatus !== LoadingStatus.Loaded &&
        batchUploadStatus !== LoadingStatus.Failed;
    const isStateReviewList =
        csvUploadStatus === LoadingStatus.Loaded &&
        uploadDetailsStatus === LoadingStatus.Loaded;

    if (isStateDemographics) {
        pageTitle = UploadSteps.Demographics.title;
    } else if (isStateReviewList) {
        pageTitle = UploadSteps.Review.title;
    }

    return {
        pageTitle,
        isStateInitial,
        isStateDemographics,
        isStateReviewList,
    };
};
