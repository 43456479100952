import { Middleware } from "@reduxjs/toolkit";
import { sanitizeAndLogError } from "domains/native/errors";

import { Action, NativeSubscription, StoreState } from "../types";

const isKnownSubscription = (
    subscription: string,
    state: StoreState,
): subscription is NativeSubscription => {
    return subscription in state;
};

/**
 * This logger middleware is specifically for Native app subscriptions. When
 * subscription data is received it checks if the resulting state after the
 * update is an error state. If so it logs any unexpected errors.
 */
export const loggerMiddleware: Middleware<unknown, StoreState> =
    (store) => (next) => (action: Action) => {
        const subscription = action.type;

        next(action);
        const state = store.getState();

        if (!isKnownSubscription(subscription, state)) {
            return;
        }

        const subscriptionState = state[subscription];

        if (subscriptionState.status === "error") {
            sanitizeAndLogError(subscriptionState.error, {
                domain: "Native API Subscription",
                subscription: subscription,
            });
        }
    };
