import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

import { Text } from "@accurx/design";

import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useAppSelector } from "store/hooks";

export const useSetSaveBatchToRecord = () => {
    const initialOptionSaveBatchToRecord = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedBatchOptionSaveToRecord,
    );
    const [shouldSaveBatchToRecord, setShouldSaveBatchToRecord] = useState(
        initialOptionSaveBatchToRecord,
    );

    return {
        shouldSaveBatchToRecord: shouldSaveBatchToRecord,
        setShouldSaveBatchToRecord,
    };
};

export type ComposeOptionToSaveToRecordProps = {
    shouldSaveBatchToRecord: boolean;
    setShouldSaveBatchToRecord: Dispatch<SetStateAction<boolean>>;
    batchType: string;
};

export const ComposeOptionToSaveToRecord = ({
    shouldSaveBatchToRecord,
    setShouldSaveBatchToRecord,
    batchType,
}: ComposeOptionToSaveToRecordProps): JSX.Element => {
    const saveBatchToRecordHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setShouldSaveBatchToRecord(e.target.checked);
    };

    return (
        <Text
            as="label"
            skinny
            className="d-flex"
            props={{
                id: "optionToSaveBatchToRecord",
                htmlFor: "optionToSaveBatchToRecordCheckbox",
            }}
        >
            <input
                id={"optionToSaveBatchToRecordCheckbox"}
                onChange={saveBatchToRecordHandler}
                checked={shouldSaveBatchToRecord}
                type="checkbox"
                name={"optionToSaveBatchToRecord"}
                className="mr-2"
            />
            {batchType === BatchType.MESSAGE
                ? "Save message to record"
                : "Save invite message to record"}
        </Text>
    );
};
