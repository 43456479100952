import { Flex } from "@accurx/design";

import { ConversationSummaryProps } from "../types";
import { StyledCell } from "./ConversationSummaries.styles";
import { ConversationSummary } from "./ConversationSummary";

type ConversationSummariesProps = {
    conversationSummaries: ConversationSummaryProps[];
};

export const ConversationSummaries: (
    props: ConversationSummariesProps,
) => JSX.Element = ({ conversationSummaries }) => (
    <StyledCell>
        <Flex flexDirection="column">
            {conversationSummaries.map((messageSummary) => (
                <ConversationSummary
                    key={messageSummary.caseReference}
                    {...messageSummary}
                />
            ))}
        </Flex>
    </StyledCell>
);
