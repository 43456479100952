import { PatientDemographics } from "domains/inbox/schemas/PatientDemographicsSchema";

export type PatientNamesMapped = {
    familyName: string;
    firstName: string;
    prefixName: string;
};

export const mapPatientsNames = (
    names: PatientDemographics["patientName"],
): PatientNamesMapped => {
    const { given, family, prefix } = names.usual;

    // for now we are going to keep consistency with current desktop logic
    const firstName = given.join(" ");
    const familyName = family[0] || "";
    const prefixName = prefix[0] || "";

    return {
        prefixName,
        firstName,
        familyName,
    };
};

export const formatPatientName = ({
    firstName,
    familyName,
    prefixName,
}: {
    firstName?: string | null;
    familyName?: string | null;
    prefixName?: string | null;
}): string => {
    // Possible formatting of first and family names:
    // - LASTNAME
    // - Firstname
    // - LASTNAME, Firstname
    let name = [familyName?.toUpperCase(), firstName]
        .filter(Boolean)
        .join(", ");

    if (name === "") {
        return "Patient name missing";
    }

    // Any Prefix is appended:
    // - LASTNAME, Firstname (Ms)
    if (prefixName) {
        name += ` (${prefixName})`;
    }

    return name;
};

export const formatPatientDisplayGender = (gender: string) => {
    switch (gender) {
        case "Male":
            return "M";
        case "Female":
            return "F";
        default:
            return null;
    }
};

export const formatAdditionalPatientDemographics = ({
    ageYear,
    gender,
}: {
    ageYear: number;
    gender: string;
}) => {
    const displayAge = ageYear > 0 ? `${ageYear}y` : null;
    const displayGender = formatPatientDisplayGender(gender);

    return displayAge || displayGender
        ? `(${[displayAge, displayGender].filter(Boolean).join(", ")})`
        : null;
};

export const calculateAge = (date: string) => {
    const difference = new Date().getTime() - new Date(date).getTime();
    const currentAge = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
    return currentAge;
};

export const formatPatientDisplayInfo = (
    patient: PatientDemographics,
): string => {
    const { patientName, gender, dateOfBirth } = patient;

    const names = mapPatientsNames(patientName);
    const { familyName, firstName } = names;
    const name = formatPatientName({ firstName, familyName });

    const ageYear = dateOfBirth ? calculateAge(dateOfBirth) : 0;
    const additionalDemographics = formatAdditionalPatientDemographics({
        ageYear,
        gender,
    });
    const info = additionalDemographics ? ` ${additionalDemographics}` : "";

    return `${name}${info}`;
};
