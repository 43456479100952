import React from "react";

import { Flex, Tokens } from "@accurx/design";

import { StyledBorderedFlex } from "./SkeletonList.styles";
import { SkeletonBlock } from "./SkeletonText";

export const SkeletonList = ({
    quantity = 5,
    height = Tokens.SIZES[8],
}: {
    quantity?: number;
    height?: string;
}) => (
    <div role="status" title="Loading...">
        <Flex flexDirection="column" gap="1">
            {[...Array(quantity)].map((_, i) => (
                <StyledBorderedFlex
                    $height={height}
                    justifyContent="center"
                    alignItems="center"
                    key={`loading-item-${i}`}
                >
                    <SkeletonBlock height="100%" width="100%" />
                </StyledBorderedFlex>
            ))}
        </Flex>
    </div>
);
