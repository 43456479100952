import { useState } from "react";

import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Spinner,
} from "@accurx/design";
import { generatePath, useHistory } from "react-router";

import { useCurrentUser } from "app/organisations/hooks";
import { TabLookup } from "app/organisations/settings/SettingsPage.constants";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_ORGS } from "shared/Routes";
import { useIsMobile, useIsSmallMobile } from "shared/useIsMobile";

import {
    NavButton,
    NavButtonIcon,
    NavButtonText,
    NavMenuDivider,
} from "../../Navbar.styles";
import { useOrganisationDropdown } from "./OrganisationDropdown.hooks";

export const OrganisationDropdown = (): JSX.Element => {
    const userSettings = useCurrentUser();
    const { loading, currentOrganisation, organisations } =
        useOrganisationDropdown();
    const [open, setOpen] = useState(false);
    const isSmallMobile = useIsSmallMobile();
    const isMobile = useIsMobile();

    const history = useHistory();

    const navigateToHome = (orgId: number) => {
        history.push(generatePath(ROUTES_ORGS.org, { orgId }));
        setOpen(false);
    };

    const navigateToSettings = (orgId: number) => {
        history.push(
            generatePath(ROUTES_ORGS.settings, {
                orgId,
                tab: TabLookup.users.tabId,
            }),
        );
        setOpen(false);
    };

    const switchOrganisation = (orgId: number) => {
        // NB. We use a page reload here as an easy way to clear the Redux state
        const url = generatePath(ROUTES_ORGS.org, { orgId });
        window.location.href = url;
    };

    if (loading === LoadingStatus.Loading) return <Spinner />;

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <NavButton>
                    <NavButtonText>
                        {isMobile ? (
                            <Icon colour="white" name="Carehome" size={3} />
                        ) : (
                            currentOrganisation?.organisationName ??
                            "Choose organisation"
                        )}
                        {!isSmallMobile && (
                            <NavButtonIcon>
                                <Icon
                                    colour="white"
                                    name="Arrow"
                                    rotation="down"
                                    size={3}
                                />
                            </NavButtonIcon>
                        )}
                    </NavButtonText>
                </NavButton>
            </PopoverTrigger>
            <PopoverContent align="end">
                {currentOrganisation && (
                    <>
                        <PopoverItem
                            onClick={() =>
                                navigateToHome(currentOrganisation.orgId)
                            }
                            active
                        >
                            {currentOrganisation.organisationName}
                        </PopoverItem>
                        {userSettings?.isApproved && (
                            <PopoverItem
                                onClick={() =>
                                    navigateToSettings(
                                        currentOrganisation.orgId,
                                    )
                                }
                            >
                                Organisation settings
                            </PopoverItem>
                        )}
                    </>
                )}
                {organisations.length > 1 && <NavMenuDivider aria-hidden />}
                {organisations.map(
                    (org) =>
                        org.orgId !== currentOrganisation?.orgId && (
                            <PopoverItem
                                key={org.orgId}
                                onClick={() => switchOrganisation(org.orgId)}
                            >
                                {org.organisationName}
                            </PopoverItem>
                        ),
                )}
            </PopoverContent>
        </Popover>
    );
};
