import { useCurrentWorkspace } from "@accurx/auth";
import { useTitle } from "@accurx/hooks";

const titleRoot = "Accurx";

export const useInboxTitle = (title: string): void => {
    const { organisationName } = useCurrentWorkspace();

    useTitle(`${title ? title + " -" : ""} ${organisationName} - ${titleRoot}`);
};
