import { SortDirection } from "@accurx/design";

import { SortOptions } from "./components/SortableTable/SortableTable";

/**
 * Keep messages under 2 fragments to reduce costs for the user
 */
export const MAX_SMS_CHAR_LIMIT = 306;

export const COLUMN_SORT_DIRECTIONS: Record<
    string,
    {
        asc: SortDirection[];
        desc: SortDirection[];
        initial: SortDirection;
    }
> = {
    appointmentStartTime: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "asc",
    },
    appointmentChangedAt: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "desc",
    },
};

export const INITIAL_TABLE_SORT_OPTIONS: SortOptions = {
    sortColumnId: "appointmentChangedAt",
    sortDirection: COLUMN_SORT_DIRECTIONS.appointmentChangedAt.initial,
} as const;
