import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeFetchError,
    NativeTransportError,
    useNativeTransport,
} from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateSelfBookConfigurationPayload,
    mutateSelfBookConfiguration,
} from "domains/self-book/native/api/mutateSelfBookConfiguration";

export const useChangeSelfBookConfigurationMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        MutateSelfBookConfigurationPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "ChangeSelfBookConfigurationMutation: Called with no native transport",
            );
        }

        await mutateSelfBookConfiguration(
            {
                transport,
                workspaceId,
            },
            variables,
        );
    }, options);
};
