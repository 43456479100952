import {
    ConversationEvent,
    ConversationUpdate,
    EventType,
    ExternalEmailAttachment,
    ExternalEmailContact,
    ExternalEmailIncomingMessage,
    ExternalEmailMessageType,
    ExternalEmailOutgoingMessage,
    ExternalEmailUserState,
    ItemType,
    MessageBody,
    PatientThreadUser,
} from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { mapStateChangeAssignee } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapClinicianMessageItemToConversationItem";
import { mapConversationItemIdentityToConversationItemId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationItemIdentityToConversationItemId";
import {
    Attachment,
    ClinicianMessageItem,
    ConversationItem,
} from "domains/concierge/types";
import isNil from "lodash/isNil";

const mapAssignedEventToConversationItem = (
    event: ConversationEvent,
): ConversationItem => {
    const readDataByUserId: ConversationItem["readDataByUserId"] = {};

    for (const notification of event.eventNotifications) {
        if (notification.userId) {
            readDataByUserId[notification.userId] = {
                readAt: notification.readAt ?? null,
            };
        }
    }

    const assignedTo = mapStateChangeAssignee(event.assignee);

    if (!assignedTo) {
        Log.warn("Invalid assignee in AssignedEvent", {
            tags: {
                product: "Inbox",
                eventId: event.id,
            },
        });
    }

    return {
        contentType: "StateChange",
        changeType: "Assignment",
        id: mapConversationItemIdentityToConversationItemId({
            type: ItemType.AssignedEvent,
            id: event.id,
        }),
        serverId: event.id,
        createdAt: event.raisedAt,
        createdBy: event.raisedByUser?.accuRxId
            ? { type: "User", id: event.raisedByUser.accuRxId }
            : { type: "System" },
        readDataByUserId,
        assignedTo,
        note: event.notes ?? undefined,
    };
};

const mapCreatedByContact = (
    dto: ExternalEmailContact | null | undefined,
): ClinicianMessageItem["createdBy"] => {
    if (!dto) {
        throw new Error("ConversationUpdate has no sender");
    }

    if (!dto.email) {
        throw new Error(
            "ConversationUpdate sender contact has no email address",
        );
    }

    return {
        type: "Contact",
        displayName: dto.displayName || dto.email,
        emailAddress: dto.email,
    };
};

const mapCreatedByUser = (
    dto: PatientThreadUser | null | undefined,
    organisationId: number | null | undefined,
): ClinicianMessageItem["createdBy"] => {
    if (!dto) {
        throw new Error("ConversationUpdate has no sender");
    }

    if (!dto.accuRxId) {
        throw new Error("ConversationUpdate sender has no user ID");
    }

    if (isNil(organisationId)) {
        throw new Error("ConversationUpdate has no organisation ID");
    }

    return {
        type: "WorkspaceUser",
        userId: dto.accuRxId,
        workspaceId: organisationId.toString(),
    };
};

const mapMessageBody = (dto: MessageBody | null | undefined): string => {
    const body = dto?.mainMessage;

    if (isNil(body)) {
        throw new Error("ConversationUpdate has no message body");
    }

    return body;
};

const mapUserReadData = (
    dto: ExternalEmailUserState[] | null | undefined,
): ConversationItem["readDataByUserId"] => {
    const result: ConversationItem["readDataByUserId"] = {};

    if (!dto) return result;

    for (const entry of dto) {
        if (entry.userId) {
            result[entry.userId] = {
                readAt: entry.readAt ?? null,
            };
        }
    }

    return result;
};

const mapAttachments = (
    dto: ExternalEmailAttachment[] | null | undefined,
): Attachment[] => {
    if (!dto) return [];

    const result: Attachment[] = [];

    for (const entry of dto) {
        if (isNil(entry.id) || isNil(entry.displayName)) continue;

        result.push({
            id: entry.id.toString(),
            displayName: entry.displayName,
        });
    }

    return result;
};

const mapExternalEmailIncomingMessageToConversationItem = (
    dto: ExternalEmailIncomingMessage | null | undefined,
): ConversationItem => {
    if (!dto) {
        throw new Error("Empty ConversationUpdate found");
    }

    if (!dto.id) {
        throw new Error("ConversationUpdate has no ID");
    }

    if (!dto.receivedTime) {
        throw new Error("ConversationUpdate has no receivedTime");
    }

    const id = mapConversationItemIdentityToConversationItemId({
        type: ItemType.IncomingMessage,
        id: dto.id,
    });

    return {
        id,
        serverId: dto.id,
        contentType: "ClinicianMessage",
        body: mapMessageBody(dto.messageBody),
        trimmedContent: dto.messageBody?.previousMessages ?? undefined,
        createdAt: dto.receivedTime,
        createdBy: mapCreatedByContact(dto.sender),
        readDataByUserId: mapUserReadData(dto.userStates),
        attachments: mapAttachments(dto.attachments),
        readStatus: dto.dateReadByGp ? "Read" : "Delivered",
    };
};

const mapExternalEmailOutgoingMessageToConversationItem = (
    dto: ExternalEmailOutgoingMessage | null | undefined,
): ConversationItem => {
    if (!dto) {
        throw new Error("Empty ConversationUpdate found");
    }

    if (!dto.id) {
        throw new Error("ConversationUpdate has no ID");
    }

    if (!dto.sentTime) {
        throw new Error("ConversationUpdate has no sentTime");
    }

    const id = mapConversationItemIdentityToConversationItemId({
        type: ItemType.OutgoingMessage,
        id: dto.id,
    });

    return {
        id,
        serverId: dto.id,
        contentType: "ClinicianMessage",
        body: mapMessageBody(dto.messageBody),
        trimmedContent: dto.messageBody?.previousMessages ?? undefined,
        createdAt: dto.sentTime,
        createdBy: mapCreatedByUser(dto.sender, dto.organisationId),
        readDataByUserId: mapUserReadData(dto.userStates),
        attachments: mapAttachments(dto.attachments),
        readStatus: "None",
    };
};

const safeMapUpdate = <T>(
    mapper: (dto: T) => ConversationItem,
    dto: T,
    getLoggingInfo: (dto: T) => { id?: string | null; type: ItemType },
): ConversationItem | undefined => {
    try {
        return mapper(dto);
    } catch (e) {
        const errorMessage =
            e instanceof Error
                ? e.message
                : "Failed to map Clinician Message item";

        const { id, type } = getLoggingInfo(dto);

        Log.error(errorMessage, {
            tags: {
                product: "Inbox",
                itemType: type,
                itemId: id,
            },
        });
    }
};

export const mapConversationUpdateToConversationItem = (
    update: ConversationUpdate,
): ConversationItem | undefined => {
    if (update.event) {
        switch (update.event.type) {
            case EventType.Assigned:
                return safeMapUpdate(
                    mapAssignedEventToConversationItem,
                    update.event,
                    (dto) => ({ id: dto.id, type: ItemType.AssignedEvent }),
                );
            case EventType.Archived:
            case EventType.Unarchived:
                // We currently don't support archive/unarchive events
                return undefined;
        }
    }

    if (update.message) {
        switch (update.message.type) {
            case ExternalEmailMessageType.Incoming:
                return safeMapUpdate(
                    mapExternalEmailIncomingMessageToConversationItem,
                    update.message.incoming,
                    (dto) => ({ id: dto?.id, type: ItemType.IncomingMessage }),
                );

            case ExternalEmailMessageType.Outgoing:
                return safeMapUpdate(
                    mapExternalEmailOutgoingMessageToConversationItem,
                    update.message.outgoing,
                    (dto) => ({ id: dto?.id, type: ItemType.OutgoingMessage }),
                );
        }
    }

    Log.error("ConversationUpdate found with no message or event", {
        tags: {
            product: "Inbox",
            workspaceId: update.organisationId,
            conversationId: update.conversationId,
        },
    });
};
