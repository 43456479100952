import {
    AppSettings,
    Log,
    initialiseAppInsightService,
    initialiseRudderStackService,
    isAppInsightsInitialized,
    isRudderStackInitialized,
} from "@accurx/shared";
import * as Sentry from "@sentry/browser";
import userflow from "userflow.js";

import { User } from "./types/User";

const loadUserflow = (userId: string) => {
    const USERFLOW_TOKEN =
        AppSettings.accurxEnvironment === "production"
            ? process.env.REACT_APP_WEB_CLIENT_PRODUCTION_USERFLOW_TOKEN
            : process.env.REACT_APP_WEB_CLIENT_DEVELOPMENT_USERFLOW_TOKEN;

    if (USERFLOW_TOKEN === undefined) {
        Log.error("No Userflow token found");
        return;
    }

    try {
        if (!userflow.isIdentified()) {
            userflow.init(USERFLOW_TOKEN);

            void userflow.identify(userId, { userId });
        }
    } catch (e) {
        Log.error("Error while initialising Userflow", {
            originalException: e,
        });
    }
};

/**
 * Helper function to load all analytics
 * Currently we are loading Rudderstack and AppInsight
 */
const loadAnalytics = () => {
    // Load Rudderstack analytics
    if (!isRudderStackInitialized()) {
        initialiseRudderStackService();
    }

    // Load AppInsight analytics
    if (!isAppInsightsInitialized()) {
        initialiseAppInsightService();
    }
};

export const identify = ({
    user,
    isDesktopUser = false,
}: {
    user: User;
    isDesktopUser?: boolean;
}) => {
    if (user.analyticsUserIdentifier) {
        window.accurxUserId = user.analyticsUserIdentifier;
        Sentry.setUser({ id: user.analyticsUserIdentifier });
    }

    // If the user is on a desktop device, they have already consented to desktop cookies and
    // they do not need to also accept cookies in Accurx Web.
    if (isDesktopUser || user.onboarding?.hasAcceptedCookies === true) {
        loadAnalytics();

        user.analyticsUserIdentifier &&
            loadUserflow(user.analyticsUserIdentifier);
    }

    if (window.Intercom && user.intercom !== undefined) {
        // Relevant Intercom docs
        // Identity verification - https://developers.intercom.com/installing-intercom/docs/enable-identity-verification-on-your-web-product
        // Logged in users - https://www.intercom.com/help/en/articles/168-install-intercom-in-your-product-for-logged-in-users
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.Intercom("update", {
            name: user.fullName,
            email: user.email,
            // Set user_id because there are duplicate users for some email addresses in intercom and this causes issues.
            // user_id identifies the user, user_hash authenticates the user. Both are required by intercom.
            user_id: user.analyticsUserIdentifier,
            // user_hash is a HMAC (https://en.wikipedia.org/wiki/HMAC) which has been generated in Accurx's Server,
            // using a secret from intercom. This allows intercom to verify the user was logged into Accurx and haven't
            // faked the value of user_id
            user_hash: user.intercom.userIdHash,
            created_at: user.intercom.createdAtUnixTimeSeconds,
        });
    }
};
