import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFullButton = styled(Button)`
    display: block;
    width: 100%;
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledCenter = styled.div`
    text-align: center;
    margin-bottom: ${Tokens.SIZES[1]};
`;
