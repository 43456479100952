import { Ds } from "@accurx/design";

import { FloreyScoreSummary } from "app/questionnaireScoreboard/schemas";

import { getStatusBadgeColour, getStatusBadgeText } from "../statusBadge.utils";

type ValidationStatusBadgeProps = {
    verbose?: boolean;
    dataTestId?: string;
} & Pick<FloreyScoreSummary, "status">;

/**
 * Renders a badge based on whether the questionnaire response
 * has been validated by a clinician
 */
export const QuestionnaireStatusBadge = ({
    status,
    verbose,
    dataTestId,
}: ValidationStatusBadgeProps) => {
    if (status === "Validated") {
        return <>Validated</>;
    }

    return (
        <Ds.Badge data-testid={dataTestId} color={getStatusBadgeColour(status)}>
            {getStatusBadgeText({ status, verbose: !!verbose })}
        </Ds.Badge>
    );
};
