import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

const getButtonBorder = (colour: string) => `
    inset 0 -2px 0 ${colour},
    inset 0 0 0 1px ${colour}
`;

export const StyledSplitButtonGroup = styled.div`
    box-shadow: ${getButtonBorder(UI.Tokens.COLOURS.primary.blue[130])};
    height: 32px;
    border-radius: ${UI.Tokens.SIZES[0.5]};
    background-color: ${UI.Tokens.COLOURS.primary.blue[100]};
    display: flex;
`;

export const StyledSplitButton = styled.button<{
    borderDirection?: "left" | "right";
}>`
    height: 32px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;
    background-color: transparent;
    padding: ${UI.Tokens.SIZES[1]};

    ${UI.Tokens.TYPOGRAPHY.body.base}

    font-weight: 700;
    font-size: 14px;
    inline-height: 20px;

    ${({ borderDirection }) =>
        borderDirection === "left"
            ? `
            border-right: 1px solid ${UI.Tokens.COLOURS.primary.blue[130]};
            border-top-left-radius: ${UI.Tokens.SIZES[0.5]};
            border-bottom-left-radius: ${UI.Tokens.SIZES[0.5]};
        `
            : borderDirection === "right"
            ? `            
            border-top-right-radius: ${UI.Tokens.SIZES[0.5]};
            border-bottom-right-radius: ${UI.Tokens.SIZES[0.5]};
        `
            : `
        border-radius: ${UI.Tokens.SIZES[0.5]};
        `}

    span {
        color: ${UI.Tokens.COLOURS.greyscale.white};
    }

    &:hover:not(:focus, :disabled) {
        background-color: ${UI.Tokens.COLOURS.primary.blue[130]};
    }

    &:focus {
        border: 1px solid ${UI.Tokens.COLOURS.primary.blue[130]};
        background-color: ${UI.Tokens.COLOURS.primary.blue[130]};
        z-index: 1;

        /* State/Default focus (pirmary) */
        box-shadow: 0px 0px 0px 2px ${UI.Tokens.COLOURS.greyscale.white},
            0px 0px 0px 4px ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    &:disabled {
        background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
        span {
            color: ${UI.Tokens.COLOURS.greyscale.zinc};
        }
    }
`;

export const StyledSplitButtonDropdown = styled(StyledSplitButton)<{
    isOpen: boolean;
}>`
    padding: ${UI.Tokens.SIZES[0.5]};
    background-color: ${({ isOpen }) =>
        isOpen ? UI.Tokens.COLOURS.primary.blue[130] : "transparent"};
`;

export const StyledSplitButtonDropdownLoading = styled(StyledSplitButton)`
    padding: ${UI.Tokens.SIZES[1]};
    background-color: ${UI.Tokens.COLOURS.primary.blue[130]};

    &:disabled {
        background-color: ${UI.Tokens.COLOURS.primary.blue[130]};
    }

    div[role="status"] {
        width: 16px;
        height: 16px;
    }
`;

export const StyledDisabledLoadingButton = styled(Pill.PrimaryButton)<{
    width: number | undefined;
}>`
    width: ${({ width }) => (width ? `${width}px` : "auto")};
    justify-content: center;
    min-width: 108px;
    height: 32px;
`;
