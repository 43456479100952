import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    MutateChangeMedicalRecordPatientPayload,
    mutateChangeMedicalRecordPatient,
} from "domains/native/api/endpoints/mutateChangeMedicatRecordPatient";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

export const useChangeMedicalRecordPatientMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        MutateChangeMedicalRecordPatientPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        async (variables) => {
            if (!transport) {
                throw new NativeTransportError(
                    "ChangeMedicalRecordPatientMutation: Called with no native transport",
                );
            }

            await mutateChangeMedicalRecordPatient(
                {
                    transport,
                    workspaceId,
                },
                variables,
            );
        },
        {
            mutationKey: ["changeMedicalRecord"],
            ...options,
        },
    );
};
