/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Button } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { StyledWrapper } from "./StepsFooter.styles";

export type StepsFooterProps = {
    backText?: string;
    backLink?: string;
    backClickFunction?: any;
    forwardText?: string;
    forwardLink?: string;
    forwardClickFunction?: any;
    forwardButtonIsCancel?: any;
    disabled: boolean;
    zIndex?: number;
};

export const StepsFooter = ({
    backText,
    backLink,
    backClickFunction,
    forwardText,
    forwardLink,
    forwardClickFunction,
    forwardButtonIsCancel,
    disabled,
    zIndex,
}: StepsFooterProps): JSX.Element => {
    const history = useHistory();

    const handleBackClick = () => {
        backClickFunction && backClickFunction();
        backLink && history.push(backLink);
    };

    const handleforwardClick = () => {
        forwardClickFunction && forwardClickFunction();
        forwardLink && history.push(forwardLink);
    };

    return (
        <StyledWrapper $zIndex={zIndex}>
            {(backLink || backClickFunction) && backText && (
                <Button
                    theme="secondary"
                    style={{ marginRight: "1rem" }}
                    type="button"
                    data-testid="back-button"
                    onClick={handleBackClick}
                    text={backText}
                />
            )}
            {(forwardLink || forwardClickFunction) && forwardText && (
                <Button
                    theme={forwardButtonIsCancel ? "danger" : "primary"}
                    style={{ marginRight: "1rem" }}
                    type="button"
                    data-testid="forward-button"
                    onClick={handleforwardClick}
                    disabled={disabled}
                    text={forwardText}
                />
            )}
        </StyledWrapper>
    );
};
