import { useCallback, useState } from "react";

export type Toggle = {
    toggle: () => void;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
};

type UseToggleProps = { isOpen?: boolean };

export const useToggle = (props: UseToggleProps = {}): Toggle => {
    const [isOpen, setIsOpen] = useState(!!props.isOpen);

    const toggle = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
    }, []);

    return {
        toggle,
        isOpen,
        setIsOpen,
    };
};
