import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { getApiEndpoint, httpClient } from "@accurx/shared";

import {
    RequestWithOutcome,
    TicketLabel,
} from "app/practices/patientTriage/components/Dashboards/RequestsByOutcome/RequestsByOutcome.types";
import { PATIENT_INITIATED_API_ENDPOINTS } from "app/practices/patientTriage/constants/ApiEndpoints";

export const PATIENT_TRIAGE_DASHBOARD_OUTCOME_DATA_CACHE_KEY =
    "PatientTriageDashboardOutcomeData";

const NOT_RECORDED = "Not recorded";

type ResponseType = {
    labels: Array<TicketLabel>;
    outcomes: Array<RequestWithOutcome>;
};

type PatientTriageDashboardOutcomeDataQueryVariables = {
    workspaceId: number;
    startDate: string;
    endDate: string;
};

export const usePatientTriageDashboardOutcomeDataQuery = createQueryHook<
    PatientTriageDashboardOutcomeDataQueryVariables,
    { labels: string[]; outcomes: Array<RequestWithOutcome> }
>(({ workspaceId, startDate, endDate }) => ({
    queryKey: [
        PATIENT_TRIAGE_DASHBOARD_OUTCOME_DATA_CACHE_KEY,
        { workspaceId, startDate, endDate },
    ],
    queryFn: async () => {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: PATIENT_INITIATED_API_ENDPOINTS.outcomeData,
                params: { organisationId: workspaceId.toString() },
                query: `?startDate=${startDate}&endDate=${endDate}`,
            }),
        );
        const data = returnDataOrThrow<ResponseType>(response);
        const labels = data.labels.map((label) => label.name);
        labels.push(NOT_RECORDED);
        return {
            labels,
            outcomes: data.outcomes,
        };
    },
}));
