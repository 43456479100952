import { Feedback, Tabs } from "@accurx/design";
import { Redirect } from "react-router";

import { Breadcrumb, Breadcrumbs, Header } from "app/organisations/shared";
import { ROUTES_ORGS } from "shared/Routes";
import { useCurrentOrgId } from "store/hooks";

import {
    StyledContainer,
    StyledPageDescription,
    StyledPageHeader,
} from "./ManageAccumailTemplates.styles";
import { getTabs } from "./ManageAccumailTemplates.tabs";
import { ManageAccurxTemplates } from "./tabs/ManageAccurxTemplates";
import { ManageUserTemplates } from "./tabs/ManageUserTemplates";
import { ManageWorkspaceTemplates } from "./tabs/ManageWorkspaceTemplates";
import { useManageAccumailTemplatesPage } from "./useManageAccumailTemplatesPage";

export const ManageAccumailTemplates = () => {
    const orgId = useCurrentOrgId();
    const { tab, onTabChange, orgName, redirect } =
        useManageAccumailTemplatesPage();

    if (!tab) return <Redirect to={redirect} />;
    if (orgId === null)
        return (
            <Feedback title="Error" colour="error">
                Please refresh the page to try again.
            </Feedback>
        );

    return (
        <Tabs selected={tab.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        asLink={false}
                        path={ROUTES_ORGS.accumailManageTemplates}
                        text="Manage Accumail templates"
                    />
                </Breadcrumbs>

                <StyledPageHeader variant="title">
                    Manage Accumail templates
                </StyledPageHeader>
                <StyledPageDescription variant="body">
                    Create and manage templates to communicate with a service or
                    healthcare professional.
                </StyledPageDescription>

                <Header.TabList tabs={getTabs(orgName)} />
            </Header>
            <StyledContainer>
                <Tabs.Panel tabId="my-templates">
                    <ManageUserTemplates />
                </Tabs.Panel>
                <Tabs.Panel tabId="workspace-templates">
                    <ManageWorkspaceTemplates />
                </Tabs.Panel>
                <Tabs.Panel tabId="accurx-templates">
                    <ManageAccurxTemplates />
                </Tabs.Panel>
            </StyledContainer>
        </Tabs>
    );
};
