/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useState } from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { IWrappedResult } from "@accurx/shared";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { PracticesReducerState } from "../../Practices.reducer";
import { VaccinePatientInvited } from "../models/VaccineDeliveryDTO";
import { VaccineReviewPatientTable } from "../shared/VaccineReviewPatientTable.component";

export type VaccineBulkActionsProps = {
    practiceId: string;
    practices: PracticesReducerState;
    invites: VaccinePatientInvited[];
    processFn: (invite: VaccinePatientInvited) => Promise<IWrappedResult<any>>;
    onBackToInviteList: () => void;
    successListTitle: string;
    successListSubTitle: string;
    failedListTitle: string;
    failedListSubTitle: string;
    // A third option - can be used to filter the successful results (in the case of Invites to Second Booking is used for
    // separating out the patients without valid mobile numbers to manually book in)
    thirdOptionListTitle?: string;
    thirdOptionListSubTitle?: string;
    thirdOptionSuccessResultMapFn?: (result: IWrappedResult<any>) => boolean;
    showStatusBadges?: boolean; // show status badges on the header of each list. Set to false if the list will be a mix of statuses
    context: BulkActionContext; // used for analytics
};

export enum BulkActionContext {
    CancelInvites = "Cancel Invites",
    CancelAppointments = "Cancel appointments",
    InviteToSecond = "Invite to 2nd",
    SendAReminder = "Send a Reminder",
    InviteToBooster = "Invite to booster",
}

interface BulkActionResult {
    invite: VaccinePatientInvited;
    success: boolean;
}

export const VaccineBulkActions = ({
    showStatusBadges = true,
    ...props
}: VaccineBulkActionsProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    if (props.thirdOptionSuccessResultMapFn && !props.thirdOptionListTitle) {
        throw new Error(
            "Third option filter function provided without third option list title",
        );
    } else if (
        !props.thirdOptionSuccessResultMapFn &&
        props.thirdOptionListTitle
    ) {
        throw new Error(
            "Third option list title provided without third option list function",
        );
    }

    const [results, setResults] = useState<BulkActionResult[]>([]);
    const [thirdOptionResults, setThirdOptionResults] = useState<
        BulkActionResult[]
    >([]);

    // Loop through and apply the supplied function to each invite in the list
    useEffect(() => {
        async function executeAction(): Promise<void> {
            // Should also be able to do a bunch of requests in parallel rather than looping in series using Promise.all
            // Need to check performance implications first though
            for (const invite of props.invites) {
                try {
                    const result = await props.processFn(invite);
                    if (
                        props.thirdOptionSuccessResultMapFn &&
                        props.thirdOptionSuccessResultMapFn(result)
                    ) {
                        setThirdOptionResults((existing) => [
                            ...existing,
                            { invite, success: false },
                        ]);
                    } else {
                        setResults((existing) => [
                            ...existing,
                            { invite, success: result.success },
                        ]);
                    }
                } catch (err) {
                    setResults((existing) => [
                        ...existing,
                        { invite, success: false },
                    ]);
                }
            }

            const successCount = results.filter((x) => x.success).length;
            const failCount = results.filter((x) => !x.success).length;
            const analyticsProps: ChainAnalyticsTracker.VaccineCompletedBulkActionProps =
                {
                    ...analyticsLoggedInProps,
                    successCount: successCount,
                    failCount: failCount,
                };
            ChainAnalyticsTracker.trackVaccineCompletedBulkAction(
                props.context,
                analyticsProps,
            );
        }
        executeAction();
    }, []); // Empty dep array - only run on mount

    const renderSuccess = (): JSX.Element => {
        const successResults = results
            .filter((x) => x.success)
            .map((x) => x.invite);
        return (
            <VaccineReviewPatientTable
                invites={successResults}
                fullListLength={props.invites.length}
                title={props.successListTitle}
                subtitle={props.successListSubTitle}
                testId="success"
                badgeText={
                    showStatusBadges
                        ? successResults[0]?.currentInviteStatus
                        : undefined
                }
                badgeColour="greyscale"
            />
        );
    };

    const renderFailed = (): JSX.Element => {
        const failedResults = results
            .filter((x) => !x.success)
            .map((x) => x.invite);
        return (
            <VaccineReviewPatientTable
                invites={failedResults}
                fullListLength={props.invites.length}
                title={props.failedListTitle}
                subtitle={props.failedListSubTitle}
                testId="failed"
                badgeText={
                    showStatusBadges
                        ? failedResults[0]?.currentInviteStatus
                        : undefined
                }
                badgeColour="greyscale"
            />
        );
    };

    const renderThirdOption = (
        thirdOptionListTitle: string,
        thirdOptionListSubTitle: string,
    ): JSX.Element => {
        return (
            <VaccineReviewPatientTable
                invites={thirdOptionResults.map((x) => x.invite)}
                fullListLength={props.invites.length}
                title={thirdOptionListTitle}
                subtitle={thirdOptionListSubTitle}
                testId="third"
                badgeText={
                    showStatusBadges
                        ? thirdOptionResults[0]?.invite?.currentInviteStatus
                        : undefined
                }
                badgeColour="greyscale"
            />
        );
    };

    return (
        <>
            {results.length + thirdOptionResults.length !==
            props.invites.length ? (
                <>
                    <Text
                        as="p"
                        variant="title"
                        colour="zinc"
                        props={{ htmlFor: "invitesDone" }}
                    >
                        {results.length} / {props.invites.length}
                    </Text>
                    <progress
                        id="invitesDone"
                        value={results.length}
                        max={props.invites.length}
                    />
                    <Feedback
                        props={{
                            className: "mb-4 mt-4",
                            style: { whiteSpace: "pre-line", width: "70%" },
                        }}
                        colour="information"
                        title="Please be aware that this could take several minutes, during which you will not be able to leave the page. If you leave the page, some of your actions may not be completed."
                    />
                </>
            ) : (
                <>
                    <Button
                        onClick={props.onBackToInviteList}
                        text="Back to all invites list"
                        className="mb-4 mt-4"
                    />
                    <div className="w-100">
                        {renderFailed()}
                        {renderSuccess()}
                        {!!thirdOptionResults.length &&
                            props.thirdOptionListTitle &&
                            props.thirdOptionListSubTitle &&
                            renderThirdOption(
                                props.thirdOptionListTitle,
                                props.thirdOptionListSubTitle,
                            )}
                    </div>
                </>
            )}
        </>
    );
};
