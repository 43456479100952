import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const PageContainer = styled(Flex).attrs({
    flexDirection: "column",
    gap: "3",
})`
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[3]};
    }
`;
