import React, { MouseEvent, useEffect, useRef, useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Accordion,
    Button,
    ButtonLink,
    Card,
    Ds,
    Feedback,
    Icon,
    Text,
    Tokens,
} from "@accurx/design";
import { IconNameVariants } from "@accurx/design/dist/styles/types";
import {
    HttpClientUrl,
    SharedUrls,
    SupportUrls,
    httpClient,
} from "@accurx/shared";
import { i } from "images";
import { parse } from "query-string";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { PracticeDetails } from "app/practices/Practices.types";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { Sticky } from "app/sharedComponents/navigation/sticky/Sticky";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { ROUTES } from "shared/Routes";
import { Breakpoints, matchesResponsiveBreakpoint } from "shared/breakpoints";
import { useAppSelector } from "store/hooks";

export const NO_RECORD_VIEW_REFERRER = "none";

type TrackedAccordionProps = {
    title: string;
    children: JSX.Element;
};

type VSpacerProps = {
    // these are equivalent to matching values in Token.SIZES
    spacing: 0 | 1 | 2 | 3 | 4 | 5;
    className?: string;
};

const VSpacer = styled.div<VSpacerProps>`
    > * + * {
        margin-top: ${({ spacing = 0 }): string | number =>
            spacing === 0 ? 0 : Tokens.SIZES[spacing]};
    }
`;

const UList = styled.ul`
    padding: 0;
`;

const openBlankLinkProps = {
    target: "_blank",
    rel: "noreferrer noopener",
};

const optIn = (practiceId: string): Promise<{ success: boolean }> => {
    const url: HttpClientUrl = {
        url: `/api/RecordView/OptIn?organisationId=${practiceId}`,
        urlLogDescription: "/api/RecordView/OptIn",
    };
    return httpClient.postSafeAsync(url);
};

const isValidReferrer = (referrer: string | null): referrer is string => {
    return referrer !== null;
};

export const RecordViewOptIn = (): JSX.Element => {
    const history = useHistory();
    const mobileLegalText = useRef<HTMLDivElement | null>(null);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );
    const isOptedIn = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.RecordViewGpOptIn,
    );
    const recordViewFeatureEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.RecordAccessBeta,
    );

    const [referrer, setReferrer] = useState<string | null>(null);
    const [optInStatus, setOptInStatus] = useState(isOptedIn);
    const [showOptInSuccess, setShowOptInSuccess] = useState(false);
    const [showLegalText, setShowLegalText] = useState(
        matchesResponsiveBreakpoint(Breakpoints.md),
    );

    useEffect(() => {
        if (!isValidReferrer(referrer)) {
            setReferrer(
                (parse(history.location.search).referrer as string) ||
                    NO_RECORD_VIEW_REFERRER,
            );
        }
    }, [history, referrer]);

    useEffect(() => {
        if (isValidReferrer(referrer) && referrer !== NO_RECORD_VIEW_REFERRER) {
            const locationWithoutQueryParams = {
                ...history.location,
                search: "",
            };
            history.replace(locationWithoutQueryParams); // Referrer doesn't remain in browser history
        }
    }, [history, referrer]);

    useEffect(
        () => {
            isValidReferrer(referrer) &&
                ChainAnalyticsTracker.trackRecordViewOptInPageView({
                    ...analyticsLoggedInProps,
                    referrer,
                    isOptedIn,
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [referrer],
    );

    const handleOptInClick = async (): Promise<void> => {
        // set legal text visible on click for mobile and bail
        if (showLegalText === false) {
            setShowLegalText(true);
            return;
        }

        isValidReferrer(referrer) &&
            ChainAnalyticsTracker.trackRecordViewOptInButtonClicked({
                ...analyticsLoggedInProps,
                referrer,
                isOptedIn,
            });

        if (selectedPractice && showLegalText) {
            const result = await optIn(selectedPractice);

            if (result.success) {
                setOptInStatus(true);
                setShowOptInSuccess(true);
                return;
            }
        }
    };

    const handleCloseModalClick = (): void => {
        isValidReferrer(referrer) &&
            ChainAnalyticsTracker.trackRecordViewOptInConfirmationScreenClosed({
                ...analyticsLoggedInProps,
                referrer,
                isOptedIn: true,
            });

        setShowOptInSuccess(false);
    };

    const trackPageClick = (analyticsIdentifier: string): void => {
        isValidReferrer(referrer) &&
            ChainAnalyticsTracker.trackRecordViewInteractiveItemClicked({
                ...analyticsLoggedInProps,
                referrer,
                isOptedIn: optInStatus,
                itemClicked: analyticsIdentifier,
            });
    };

    const handleLinkClick = (e: MouseEvent<HTMLElement>): void => {
        // This prevents accordions from toggling
        // when clicking on links inside of them
        e.stopPropagation();

        const element = e.currentTarget;

        if (element instanceof HTMLAnchorElement) {
            const itemIdentifier = `link: ${
                element.innerText || element.textContent
            }`;

            // Send analytics event
            trackPageClick(itemIdentifier);
        }
    };

    const optInButton = (withConfirmation = false) => (
        <Button
            theme="primary"
            text={`${
                withConfirmation === false
                    ? "Switch on for your Practice"
                    : "Confirm switching on for your Practice"
            }`}
            onClick={handleOptInClick}
        />
    );

    const textWithLinkIcon = (text: string, linkText: string, url: string) => (
        <Text skinny variant="preview" props={{ className: "mt-1" }}>
            {text}&nbsp;
            <Text
                as="a"
                variant="link"
                props={{
                    style: { font: "inherit" },
                    onClick: handleLinkClick,
                    href: url,
                    ...openBlankLinkProps,
                }}
            >
                {linkText}
                <Icon
                    name="OpenWindow"
                    size={3}
                    props={{ className: "text-nowrap" }}
                    colour={"blue"}
                />
            </Text>
        </Text>
    );

    const optInLegalCopy = (
        <div className="text-left">
            <Text skinny variant="preview">
                By switching on:
            </Text>
            <ul className="pl-4 m-0">
                <li>
                    <Text skinny variant="preview">
                        You confirm you are authorised to switch on Record View
                        on behalf of your practice
                    </Text>
                </li>
                <li>
                    <Text skinny variant="preview">
                        You instruct Accurx to &nbsp;
                        <Text
                            as="a"
                            variant="link"
                            props={{
                                style: { font: "inherit" },
                                onClick: handleLinkClick,
                                href: SharedUrls.RecordViewIGInfo,
                                ...openBlankLinkProps,
                            }}
                        >
                            process data in this way
                            <Icon
                                name="OpenWindow"
                                size={3}
                                props={{ className: "text-nowrap" }}
                                colour={"blue"}
                            />
                        </Text>
                    </Text>
                </li>
            </ul>
        </div>
    );

    const contactUsButton = (
        <ButtonLink
            theme="secondary"
            dimension="small"
            text="Contact us"
            icon={{ name: "Mail", style: "Line" }}
            href={`mailto:${SupportUrls.GenericSupportEmail}`}
            onClick={handleLinkClick}
        />
    );

    const supportMailLink = (
        <Text
            as="a"
            variant="link"
            props={{
                href: `mailto:${SupportUrls.GenericSupportEmail}`,
                onClick: handleLinkClick,
            }}
        >
            {SupportUrls.GenericSupportEmail}
        </Text>
    );

    const enabledBadge = (
        <div className="w-100">
            <Feedback
                colour="success"
                title="Your practice has switched on Record View. Start using it today!"
            />
        </div>
    );

    const notEnabledBadge = <Ds.Badge>Not switched on yet</Ds.Badge>;

    const TrackedAccordion = ({
        title,
        children,
    }: TrackedAccordionProps): JSX.Element => {
        const handleAccordionToggle = (): void => {
            trackPageClick(`accordion: ${title}`);
        };

        return (
            <Accordion
                header={title}
                icon={{
                    name: "Success",
                    style: "Fill",
                    colour: "green",
                }}
                buttonProps={{ onClick: handleAccordionToggle }}
            >
                {children}
            </Accordion>
        );
    };

    const iconListItem = (
        iconName: IconNameVariants,
        title: string,
        description: string,
    ): JSX.Element => {
        return (
            <li>
                <div className="mt-2 d-flex flex-row">
                    <div>
                        <Icon
                            name={iconName}
                            halo={{ colour: "yellow", luminosity: "high" }}
                            size={3}
                        />
                    </div>
                    <div className="ml-2">
                        <Text variant="label" skinny>
                            {title}
                        </Text>
                        <Text skinny>{description}</Text>
                    </div>
                </div>
            </li>
        );
    };

    return (
        <>
            {showOptInSuccess && (
                <Modal
                    toggle={handleCloseModalClick}
                    backdropClassName="bg-zinc"
                    isOpen
                    fade
                    centered
                >
                    <ModalHeader tag="div">
                        <Text
                            variant="subtitle"
                            as="h1"
                            props={{ className: "d-flex align-items-center" }}
                            skinny
                        >
                            <Icon
                                name="Celebrate"
                                halo={{ colour: "blue", luminosity: "high" }}
                            />
                            &nbsp;Record View enabled
                        </Text>
                    </ModalHeader>
                    <ModalBody className={"bg-cloud"}>
                        <Text>
                            Record View has been enabled for your practice!
                        </Text>
                        <Text>
                            You can change your mind at anytime at{" "}
                            {supportMailLink}
                        </Text>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-end">
                        <Button text="OK" onClick={handleCloseModalClick} />
                    </ModalFooter>
                </Modal>
            )}

            <NavSubMenuComponent>
                <Breadcrumb title="Record View" wrapper={false} />
                <Text variant="title" className="mt-2" skinny>
                    Get started with Record View
                </Text>
                <Text skinny>
                    Learn more about Record View and its benefits to your
                    practice and your patients
                </Text>
            </NavSubMenuComponent>

            <div className="row">
                <div className="col-md-4 d-none d-md-block">
                    <Sticky stickyStart={30}>
                        <Card
                            spacing={3}
                            props={{
                                className: "m-auto overflow-auto",
                                style: {
                                    maxHeight: `calc(100vh - 60px)`, // on medium screens legal copy overflows, so we need to allow a scroll or the enable button will be hidden
                                },
                            }}
                            isElevated={false}
                        >
                            <VSpacer spacing={2}>
                                <VSpacer
                                    spacing={1}
                                    className="d-flex flex-column align-items-center"
                                >
                                    {optInStatus
                                        ? enabledBadge
                                        : notEnabledBadge}
                                </VSpacer>

                                {optInStatus ? (
                                    <VSpacer spacing={2}>
                                        {recordViewFeatureEnabled ? (
                                            <div className="text-left">
                                                <Text skinny variant="body">
                                                    <strong>
                                                        How to use Record View
                                                        as a GP
                                                    </strong>
                                                </Text>
                                                <ol className="pl-3">
                                                    <li>
                                                        Log into{" "}
                                                        <a href={ROUTES.login}>
                                                            Accurx web
                                                            <Icon
                                                                name="OpenWindow"
                                                                size={3}
                                                                props={{
                                                                    className:
                                                                        "text-nowrap",
                                                                }}
                                                                colour={"blue"}
                                                            />
                                                        </a>{" "}
                                                        on a web browser with
                                                        your NHS login
                                                    </li>
                                                    <li>
                                                        Search for patient from
                                                        your practice or first,
                                                        try it with a test
                                                        patient
                                                    </li>
                                                    <li>View their record</li>
                                                </ol>
                                            </div>
                                        ) : (
                                            <Text skinny variant="body">
                                                Your practice is all ready to go
                                                with Record View. We'll let you
                                                know once patient records have
                                                been requested.
                                            </Text>
                                        )}
                                    </VSpacer>
                                ) : (
                                    <VSpacer spacing={2}>
                                        <Text skinny variant="body">
                                            Join the &gt;4,000 practices already
                                            supporting Record View.
                                        </Text>
                                        <Text skinny variant="body">
                                            Switch on to let clinicians across
                                            the NHS request a 24h view of any
                                            patient record from your practice.
                                        </Text>
                                        {optInLegalCopy}
                                        <span className="d-flex justify-content-center">
                                            {optInButton()}
                                        </span>
                                    </VSpacer>
                                )}
                            </VSpacer>
                        </Card>
                        {optInStatus ? (
                            <div className="mt-3 text-center">
                                <Text
                                    skinny
                                    variant="preview"
                                    props={{ className: "mb-2 ml-4 mr-4" }}
                                >
                                    If you change your mind and would like
                                    Record View switched off please contact us.
                                </Text>
                                <span className="d-flex justify-content-center">
                                    {contactUsButton}
                                </span>
                            </div>
                        ) : (
                            <div className="mt-2">
                                <Card>
                                    <VSpacer spacing={2}>
                                        <Text variant="preview">
                                            <strong>
                                                Not ready to switch on Record
                                                View yet?
                                            </strong>
                                        </Text>
                                        {textWithLinkIcon(
                                            "Want to see what it looks like?",
                                            "Try with a test patient",
                                            ROUTES.record_view_medical_record_test_patient,
                                        )}
                                        {textWithLinkIcon(
                                            "Have more questions?",
                                            "Schedule a call with us",
                                            "https://calendly.com/d/vrg7-s3xp/accurx-record-view-call",
                                        )}
                                    </VSpacer>
                                </Card>
                            </div>
                        )}
                    </Sticky>
                </div>

                <VSpacer spacing={5} className="col-md-8">
                    <Card
                        header={
                            <div className="d-flex flex-row align-items-center">
                                <Text variant="subtitle" skinny>
                                    Quick and secure record sharing made easy
                                </Text>
                            </div>
                        }
                        isElevated={false}
                    >
                        <Text>
                            With Record View, any NHS professional may securely
                            view a read-only summary of your patients' GP
                            records.
                        </Text>

                        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                            {iconListItem(
                                "Done",
                                "Free to NHS",
                                "Record View is free to the NHS, to help ensure everyone involved in a patient's care has the right information when they need it.",
                            )}
                            {iconListItem(
                                "Record",
                                "Simple, fast and secure",
                                "NHS professionals receive an immediate view of a patient's GP record summary with assured patient permission.",
                            )}
                            {iconListItem(
                                "Chat",
                                "Informing care decisions",
                                "Record summaries show up-to-date and comprehensive patient information to enhance quality and speed of care.",
                            )}
                        </ul>
                    </Card>

                    <div>
                        <img
                            src={i("recordView/record-problems.png")}
                            alt="Send request to view summary of patients medical record"
                            className="img-fluid"
                            width="727"
                            height="425"
                        />

                        {textWithLinkIcon(
                            "Want to see what Record View looks like?",
                            "Try Record View with a test patient",
                            ROUTES.record_view_medical_record_test_patient,
                        )}
                    </div>

                    <Card
                        header={
                            <div className="d-flex flex-row justify-content-start align-items-center">
                                <Text variant="subtitle" skinny>
                                    Record View for GPs
                                </Text>
                            </div>
                        }
                        isElevated={false}
                    >
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Text variant="label">
                                    View records on any device
                                </Text>
                                <Text>
                                    GPs can use Record View for their own
                                    patient records while working out of hours,
                                    remotely or at a home visit with secure
                                    login on any device.
                                </Text>
                            </li>
                            <li>
                                <Text variant="label">
                                    Reduce administrative burden
                                </Text>
                                <Text>
                                    Save hours of responding to patient
                                    information requests from other NHS
                                    professionals each week with Record View, so
                                    those involved in your patients' care can
                                    view the right information at the right time
                                    without burdening your practice.
                                </Text>
                            </li>
                            <li>
                                <Text variant="label">Live audit trail</Text>
                                <Text>
                                    Stay in control and informed of your
                                    patients' care with a live audit trail of
                                    who has requested and viewed a summary
                                    medical record.
                                </Text>
                            </li>
                        </ul>
                        <div className="mt-2 ml-2 row">
                            <Text variant="label">
                                How to use Record View as a GP
                            </Text>
                            <div className="col-sm-8 d-flex flex-column pl-0">
                                <ul
                                    style={{
                                        listStyle: "none",
                                        paddingLeft: 0,
                                    }}
                                >
                                    <li>
                                        <div className="mt-2 d-flex align-items-center">
                                            <Ds.Badge
                                                color="greyscale"
                                                luminosity="low"
                                                radius="round"
                                            >
                                                1
                                            </Ds.Badge>
                                            <div className="ml-2">
                                                <Text skinny>
                                                    Log into Accurx web in a
                                                    browser with your NHS login
                                                </Text>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="mt-2 d-flex align-items-center">
                                            <Ds.Badge
                                                color="greyscale"
                                                luminosity="low"
                                                radius="round"
                                            >
                                                2
                                            </Ds.Badge>

                                            <div className="ml-2">
                                                <Text skinny>
                                                    Search for a patient from
                                                    your practice
                                                </Text>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="mt-2 d-flex align-items-center">
                                            <Ds.Badge
                                                color="greyscale"
                                                luminosity="low"
                                                radius="round"
                                            >
                                                3
                                            </Ds.Badge>
                                            <div className="ml-2">
                                                <Text skinny>
                                                    View their record, no need
                                                    for patient permission
                                                </Text>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4 d-flex flex-column">
                                <img
                                    src={i("recordView/login.svg")}
                                    alt="Send request to view summary of patients medical record"
                                    className="align-self-center mw-100 ml-2 align-self-md-start ml-md-0"
                                />
                            </div>
                        </div>
                    </Card>

                    <Card
                        header={
                            <div className="d-flex flex-row justify-content-start align-items-center">
                                <Text variant="subtitle" skinny>
                                    Record View for Trusts
                                </Text>
                            </div>
                        }
                        isElevated={false}
                    >
                        <ul style={{ paddingLeft: "20px" }}>
                            <li>
                                <Text variant="label">
                                    Powered by patient permission
                                </Text>
                                <Text>
                                    Secure record sharing with NHS login and
                                    verified patient permission required for
                                    each 24h read-only view.
                                </Text>
                            </li>
                            <li>
                                <Text variant="label">
                                    National record sharing solution
                                </Text>
                                <Text>
                                    Unlike local record sharing solutions,
                                    Record View is not regionally locked.
                                </Text>
                            </li>
                        </ul>

                        <Text variant="label">
                            How to use Record View as a Trust user
                        </Text>

                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-4">
                                <img
                                    src={i("recordView/arrow.svg")}
                                    alt="Send request to view summary of patients medical record"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                            <div className="col-4">
                                <img
                                    src={i("recordView/patient-code.svg")}
                                    alt="Unique 6-digit code received via SMS"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                            <div className="col-4">
                                <img
                                    src={i("recordView/view-record.svg")}
                                    alt="The patients record is viewable for 24 hours"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between align-items-center mt-3">
                            <div className="col-sm-4 d-flex">
                                <div className="d-flex flex-column">
                                    <Ds.Badge
                                        color="greyscale"
                                        luminosity="low"
                                        radius="round"
                                    >
                                        1
                                    </Ds.Badge>
                                </div>
                                <div className="d-flex flex-column ml-1">
                                    <Text variant="label" as="span" skinny>
                                        Request record
                                    </Text>
                                    <Text skinny>
                                        Generates an SMS to the patient with a
                                        permission code
                                    </Text>
                                </div>
                            </div>
                            <div className="col-sm-4 d-flex">
                                <div className="d-flex flex-column">
                                    <Ds.Badge
                                        color="greyscale"
                                        luminosity="low"
                                        radius="round"
                                    >
                                        2
                                    </Ds.Badge>
                                </div>
                                <div className="d-flex flex-column ml-1">
                                    <Text variant="label" as="span" skinny>
                                        Patient permission
                                    </Text>
                                    <Text skinny>
                                        Patient shares the code with their
                                        clinician to confirm permission
                                    </Text>
                                </div>
                            </div>
                            <div className="col-sm-4 d-flex">
                                <div className="d-flex flex-column">
                                    <Ds.Badge
                                        color="greyscale"
                                        luminosity="low"
                                        radius="round"
                                    >
                                        3
                                    </Ds.Badge>
                                </div>
                                <div className="d-flex flex-column ml-1">
                                    <Text variant="label" as="span" skinny>
                                        Unlock record
                                    </Text>
                                    <Text skinny>
                                        With a correct code, clinician obtains
                                        the record for 24h
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card
                        header={
                            <div className="d-flex flex-row justify-content-start align-items-center">
                                <Text variant="subtitle" skinny>
                                    How is Record View safe & secure
                                </Text>
                            </div>
                        }
                        isElevated={false}
                    >
                        <Text>
                            We deliver trust for GPs enabling Record View in 3
                            main ways:
                        </Text>

                        <VSpacer spacing={1}>
                            <TrackedAccordion title="Record View meets the IG standards of national bodies">
                                <UList>
                                    <li>
                                        <Text>
                                            Aligns with guidance from&nbsp;
                                            <Text
                                                as="a"
                                                variant="link"
                                                props={{
                                                    href: SharedUrls.NHSIGQuestions,
                                                    onClick: handleLinkClick,
                                                    ...openBlankLinkProps,
                                                }}
                                            >
                                                The NHSX Health and Care IG
                                                Panel
                                            </Text>
                                            &nbsp;(including representatives
                                            from NHS Digital, the National Data
                                            Guardian, NHS X and the ICO).
                                        </Text>
                                        <img
                                            src={i(
                                                "recordView/record-view-guidance.png",
                                            )}
                                            width="100%"
                                            alt=""
                                        />
                                    </li>
                                    <li>
                                        <Text>
                                            Developed in consultation with
                                            former National Data Guardian, Dame
                                            Fiona Caldicott. Please read&nbsp;
                                            <Text
                                                as="a"
                                                variant="link"
                                                props={{
                                                    href: SharedUrls.FionaCaldicottCorrespondence,
                                                    onClick: handleLinkClick,
                                                    ...openBlankLinkProps,
                                                }}
                                            >
                                                her correspondence
                                            </Text>
                                            &nbsp;in full.
                                        </Text>
                                    </li>
                                </UList>
                            </TrackedAccordion>
                            <TrackedAccordion title="You stay in control">
                                <UList>
                                    <li>
                                        <Text>
                                            You will see a live audit trail of
                                            who has requested to view your
                                            patients' records and the status of
                                            those requests.
                                        </Text>
                                    </li>
                                </UList>
                            </TrackedAccordion>
                            <TrackedAccordion title="Secure method of patient permission">
                                <UList>
                                    <li>
                                        <Text>
                                            Your patients provide explicit
                                            permission each time a health and
                                            care professional requests to view
                                            their record via multi-factor
                                            authentication.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            Your patient's contact number for
                                            the SMS is retrieved from the PDS
                                            and is non-editable.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            The SMS sent to your patients
                                            notifying them of the request
                                            includes the name of the health and
                                            care professional requesting the
                                            record and a link to an Accurx
                                            webpage informing them how their
                                            record will be viewed and what to do
                                            if they were not expecting the
                                            request.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            The record can only be viewed for 24
                                            hours.
                                        </Text>
                                    </li>
                                </UList>
                            </TrackedAccordion>
                        </VSpacer>
                    </Card>

                    <VSpacer spacing={1}>
                        <Text>For more information, check out:</Text>
                        <Card spacing={1.5}>
                            <Text
                                as="a"
                                variant="link"
                                skinny
                                props={{
                                    className:
                                        "d-flex flex-row align-items-center",
                                    href: "https://support.accurx.com/en/articles/5665673-accurx-web-record-view-for-primary-care",
                                    onClick: handleLinkClick,
                                    ...openBlankLinkProps,
                                }}
                            >
                                <Icon name="Document" />
                                <Text
                                    variant="label"
                                    as="span"
                                    props={{ className: "flex-grow-1" }}
                                    skinny
                                >
                                    Record View FAQs
                                </Text>
                            </Text>
                        </Card>
                        <Card spacing={1.5}>
                            <Text
                                as="a"
                                variant="link"
                                skinny
                                props={{
                                    className:
                                        "d-flex flex-row align-items-center",
                                    href: SharedUrls.RecordViewIGInfo,
                                    onClick: handleLinkClick,
                                    ...openBlankLinkProps,
                                }}
                            >
                                <Icon name="Document" />
                                <Text
                                    variant="label"
                                    as="span"
                                    props={{ className: "flex-grow-1" }}
                                    skinny
                                >
                                    More info about Record View information
                                    governance
                                </Text>
                            </Text>
                        </Card>
                        <Text props={{ className: "pb-5" }}>
                            If you have any other questions or want to chat
                            about Record View for your practice, please
                            email&nbsp;
                            {supportMailLink}.
                        </Text>
                    </VSpacer>
                </VSpacer>
            </div>

            {/*
                empty containers to pad bottom of page so as to prevent
                the fixed bottom sheet from overlapping it
            */}
            {optInStatus === false && showLegalText ? (
                <>
                    <div className="d-md-none mb-5 pb-5"></div>
                    <div className="d-md-none mb-5 pb-5"></div>
                </>
            ) : (
                <div className="d-md-none mb-4 pb-4"></div>
            )}

            <Card
                spacing={2}
                isElevated={false}
                props={{
                    className: "d-md-none",
                    style: {
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        margin: "auto",
                        border: "none",
                        borderRadius: 0,
                        boxShadow: "0px 0px 16px rgba(0,0,0,0.2)",
                        transition: "transform .3s ease-out",
                        transformOrigin: "bottom",
                        transform: mobileLegalText.current
                            ? showLegalText
                                ? "translateY(0)" // ref attached, legal text visible
                                : `translateY(${mobileLegalText.current?.clientHeight}px)` // ref attached, legal text not visible
                            : optInStatus === false
                            ? "translateY(300px)" // ref not yet attached, not opted-in (initial render)
                            : "translateY(0)", // ref not yet attached, opted-in
                    },
                }}
            >
                <>
                    {optInStatus ? (
                        <VSpacer spacing={1}>
                            <div className="d-flex justify-content-center">
                                {enabledBadge}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <Text skinny variant="preview">
                                    If you change your mind and would like
                                    Record View switched off please contact us.
                                </Text>
                                {contactUsButton}
                            </div>
                        </VSpacer>
                    ) : (
                        <VSpacer spacing={1}>
                            <div className="d-flex justify-content-center">
                                {notEnabledBadge}
                            </div>
                            <div className="d-flex justify-content-center">
                                {optInButton(showLegalText)}
                            </div>
                            <div
                                ref={mobileLegalText}
                                className={`${
                                    showLegalText
                                        ? "d-flex justify-content-center"
                                        : "invisible"
                                }`}
                            >
                                {optInLegalCopy}
                            </div>
                        </VSpacer>
                    )}
                </>
            </Card>
        </>
    );
};

export default RecordViewOptIn;
