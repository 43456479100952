import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledButton = styled.button`
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-radius: ${Tokens.BORDERS.radius};
    border: ${Tokens.BORDERS.normal.silver};
    display: flex;
    gap: ${Tokens.SIZES[2]};
    justify-content: space-between;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    width: 100%;

    > span {
        color: ${Tokens.COLOURS.greyscale.night};
    }

    &:hover,
    &:focus {
        background-color: ${Tokens.COLOURS.primary.blue[10]};
        transition: background-color 0.5s;
        > svg > circle {
            fill: ${Tokens.COLOURS.primary.blue[100]};
        }
        > svg > g > path {
            fill: ${Tokens.COLOURS.greyscale.white};
        }
    }

    &:active {
        background-color: ${Tokens.COLOURS.primary.blue[25]};
        transition: background-color 0.5s;
    }
`;
