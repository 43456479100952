import { SearchForPatientResponse } from "@accurx/api/portal";
import { Reducer } from "redux";

import { WebPatient } from "app/patients/Patient.types";
import { PatientHelper } from "shared/PatientHelper";
import PatientTokenService from "shared/storage/PatientTokenService";

import {
    KnownAction,
    SEARCH_FOR_PATIENT_FINISHED,
    SEARCH_FOR_PATIENT_STARTED,
    SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE,
} from "./SearchForPatientActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

type PatientNhsNumber = WebPatient["nhsNumber"];
type SelectedPatient = PatientNhsNumber | null;

export interface SearchPatientState {
    isSearching: boolean;
    lastResponse: SearchForPatientResponse | null;
    entryPatientNhsNumber: string | null;

    selectedPatient: SelectedPatient;
    allByNhsNumber: Record<NonNullable<PatientNhsNumber>, WebPatient>;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: SearchPatientState = {
    isSearching: false,
    lastResponse: null,
    entryPatientNhsNumber: null,

    selectedPatient: null,
    allByNhsNumber: {},
};

export const reducer: Reducer<SearchPatientState> = (
    state = initialState,
    action: KnownAction,
): SearchPatientState => {
    switch (action.type) {
        case SEARCH_FOR_PATIENT_STARTED:
            return {
                ...state,
                isSearching: true,
                selectedPatient: null,
                lastResponse: null,
            };
        case SEARCH_FOR_PATIENT_FINISHED:
            return addAndSelectSearchedPatient(action.response, state);
        case SET_ENTRY_NHSNUMBER_VIA_PATIENT_RESPONSE:
            return {
                ...state,
                entryPatientNhsNumber: action.patientNhsNumber,
            };
    }

    return state;
};

/*
 * Functions that map state
 */

const unsetSelectedPatient = (
    state: SearchPatientState,
    lastResponse: SearchForPatientResponse | null,
): SearchPatientState => {
    // Set token in session storage to null as well
    if (PatientTokenService !== null) {
        PatientTokenService.setCurrentPatientToken(null);
    }
    return {
        ...state,
        isSearching: false,
        lastResponse,
        selectedPatient: null,
    };
};

// Same as addAndSelectPatient but we need to turn the search result
// into a web patient first
const addAndSelectSearchedPatient = (
    apiResponse: SearchForPatientResponse | null,
    state: SearchPatientState,
): SearchPatientState => {
    if (!apiResponse?.searchedResult) {
        return unsetSelectedPatient(state, apiResponse);
    }

    const webPatient = PatientHelper.getWebPatientFromSearchResult(
        apiResponse.searchedResult,
    );

    // Don't expect this to happen often
    // But we should unset the selected patient if it does happen
    if (webPatient === null) {
        return unsetSelectedPatient(state, apiResponse);
    }

    return {
        ...state,
        isSearching: false,
        lastResponse: apiResponse,
        selectedPatient: webPatient.nhsNumber,
        allByNhsNumber: {
            ...state.allByNhsNumber,
            [webPatient.nhsNumber as string]: webPatient,
        },
    };
};
