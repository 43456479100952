import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTableRow = styled.tr<{
    $topBorder?: boolean;
}>`
    ${(props) =>
        props.$topBorder ? `border-top: ${Tokens.BORDERS.normal.silver}` : ""}
    display: flex;
    flex-direction: column;
`;

export const StyledFlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.grid-layout {
        display: grid;
        align-items: start;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.25rem;
        align-self: stretch;
    }

    &.two-headings-layout {
        justify-content: flex-start;
        gap: 4rem;
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        &.grid-layout {
      grid-template-columns: 2, 1fr;
    }
`;

export const HeadingPadding = styled.div`
    padding-top: 0.5rem;
`;

export const FullWidthOnMobile = styled.div`
    @media (max-width: 768px) {
        flex-basis: 100%;
    }
`;

export const GrowOnMobile = styled.div`
    @media (max-width: 768px) {
        flex: 1;
    }
`;

export const StyledTableHeader = styled.th`
    display: flex;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    padding-left: 1rem;
    padding-right: 1rem;
`;

export const StyledTableData = styled.td`
    word-break: break-word;
    hyphens: auto;
    margin-left: ${Tokens.SIZES[2]};
    margin-right: ${Tokens.SIZES[2]};
`;

export const StyledText = styled.div`
    margin-bottom: 0.5rem;
`;

export const StyledWrapper = styled.div`
    padding-bottom: ${Tokens.SIZES[1]};
`;
