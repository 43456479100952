import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import * as actions from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { VaccineAllPatientsInvited } from "./VaccineAllPatientsInvited";

const mapStateToProps = (state: ApplicationState) => ({
    practices: state.practices,
    practiceId: state.practices.selectedPractice,
    patientList:
        state.vaccineDelivery.vaccineAllPatientsInvitedDetails.summaries,
    patientListIsLimited:
        state.vaccineDelivery.vaccineAllPatientsInvitedDetails.isLimited,
    getAllPatientsDetailsStatus:
        state.vaccineDelivery.getAllPatientsDetailsStatus,
    cancelInviteId: state.vaccineDelivery.cancelInviteId,
    cancelStatus: state.vaccineDelivery.cancelStatus,
    resetInviteId: state.vaccineDelivery.resetInviteId,
    resetStatus: state.vaccineDelivery.resetStatus,
    bulkCancelStatus: state.vaccineDelivery.bulkCancelStatus,
    bulkCancelResponse: state.vaccineDelivery.bulkCancelResponse,
    goToCancelFlow: state.vaccineDelivery.goToCancelFlow,
    vaccineCourse: state.vaccineDelivery.vaccineCourse,
    allInvitesFilters: state.vaccineDelivery.allInvitesFilters,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export const VaccineAllPatientsInvitedContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VaccineAllPatientsInvited);

export default VaccineAllPatientsInvitedContainer;
