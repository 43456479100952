import React from "react";

import { Text } from "@accurx/design";
import styled from "styled-components";

const StyledPageNumberHeading = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: -0.75rem;
`;

const StyledPageTitleHeading = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: -1rem;
`;

export const PageTitle = ({
    pageNumber,
    pageTitle,
}: {
    pageNumber: string;
    pageTitle: string;
}): JSX.Element => (
    <>
        <StyledPageNumberHeading>
            <Text colour={"zinc"} children={`Step ${pageNumber} of 4`} />
        </StyledPageNumberHeading>
        <StyledPageTitleHeading>
            <Text variant="title" as="h1" children={pageTitle} />
        </StyledPageTitleHeading>
    </>
);
