import { useState } from "react";

import { BatchMessageId } from "app/batchMessage/gp/BatchMessage.types";
import { ChoosePatientList } from "app/batchMessage/trust/pages/AddPatientsFromPatientList/components/ChoosePatientList";
import { ChoosePatientListDays } from "app/batchMessage/trust/pages/AddPatientsFromPatientList/components/ChoosePatientListDays";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

type ChoosePatientsFromListProps = {
    onBatchMessageCreated: (batchMessageId: BatchMessageId) => void;
};
export const ChoosePatientsFromList = ({
    onBatchMessageCreated,
}: ChoosePatientsFromListProps) => {
    const [patientListId, setPatientListId] = useState<number>();

    const orgId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const handleBack = () => {
        setPatientListId(undefined);
    };

    return (
        <>
            {!patientListId ? (
                <ChoosePatientList
                    workspaceId={orgId}
                    onChoosePatientList={setPatientListId}
                />
            ) : (
                <ChoosePatientListDays
                    workspaceId={orgId}
                    patientListId={patientListId}
                    onBatchMessageCreated={onBatchMessageCreated}
                    onBack={handleBack}
                />
            )}
        </>
    );
};
