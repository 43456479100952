import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import {
    FlemingLoggedInCustomProperties,
    SharedLoggedInRudderStackAnalyticProps,
} from "./FlemingAnalyticsConstants";

/**
 * When a user clicks log out link
 *
 * Event type: Button click
 * */
export const trackLogoutButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedLoggedInRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "AccountLogOut",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Triggered when someone load the website and is logged in
 *
 * Event type: Page View
 * */
export const trackAccountLoadPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "AccountUser",
        objectType: EventObjectType.Page,
        action: EventAction.Load,
        properties: fullProps,
    });
};
