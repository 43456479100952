import * as React from "react";

import { Icon, Text } from "@accurx/design";

import { StyledBadge } from "../shared/shared.styles";
import { StyledFlexWrapper } from "./RequestsCellContent.styles";

type RequestsCellContentProps = {
    totalRequests: number;
    urgentRequests: number;
};

export const RequestsCellContent = ({
    totalRequests,
    urgentRequests,
}: RequestsCellContentProps): JSX.Element => {
    return (
        <StyledFlexWrapper gap="12px">
            <Text as="span" skinny>
                {totalRequests}
            </Text>
            {urgentRequests > 0 && (
                <StyledBadge colour="red">
                    <Icon name="Flag" colour="red" size={2} />
                    <Text skinny variant="note">
                        {urgentRequests}
                    </Text>
                </StyledBadge>
            )}
        </StyledFlexWrapper>
    );
};
