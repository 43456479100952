import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import FlemingApi from "api/FlemingApiClient";
import { PatientList } from "api/FlemingDtos/PatientListDtos";

type PatientListQueryVariables = {
    workspaceId: number | null;
    patientListId: number;
};

export const usePatientListQuery = ({
    workspaceId,
    patientListId,
}: PatientListQueryVariables): UseQueryResult<PatientList, Error> =>
    useQuery({
        queryKey: ["PatientList", workspaceId, patientListId],
        queryFn: async () => {
            const response = await FlemingApi.getUserPatientList({
                organisationId: workspaceId,
                patientListId,
            });
            return returnDataOrThrow(response);
        },
        enabled: workspaceId !== null,
        // Patient list modifications don't currently update react query state, because they are built on redux, so it's safer to always refetch on mount for now
        refetchOnMount: "always",
    });
