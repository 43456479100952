export const ROOT_ROUTES = {
    workspaceJoin: "/join-workspace",
    workspaceCreate: "/create-workspace",
};

export const WORKSPACE_MANAGEMENT_PRIMARY_ROUTES = {
    workspaceOnboarding: "/w/:workspaceId/workspace-onboarding",
    settings: "/w/:workspaceId/settings",
};

export const WORKSPACE_MANAGEMENT_ROUTES = {
    // Workspace onboarding
    workspaceJoin: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding}/join-workspace`,
    workspaceCreate: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding}/create-workspace`,
    selectOrganisation: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding}/select-organisation`,
    /** @deprecated redirects to support workspace guidance article*/
    workspaceGuidance: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding}/guidance`,

    // Workspace settings
    userManagement: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.settings}/users`,
    inviteUsers: `${WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.settings}/users/invite`,
};

export const SUPPORT_ARTICLE_WORKSPACE_GUIDANCE =
    "https://support.accurx.com/en/articles/8586989-accurx-web-how-to-create-or-join-a-workspace";
