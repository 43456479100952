import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

type PatientTriageDashboardBaseProps = LoggedInCustomProperties & {
    sectionName: string;
    dashboardName: string;
};

type PatientTriageDashboardBaseFilterProps = PatientTriageDashboardBaseProps & {
    filterName: string;
};

type PatientTriageDashboardFilterByRequestProps =
    PatientTriageDashboardBaseFilterProps & {
        optionSelected: string;
        activeFilterList: Array<string>;
    };

type PatientTriageDashboardFilterByNameProps =
    PatientTriageDashboardBaseFilterProps & {
        inputText: string;
    };

type PatientTriageDashboardSortProps = PatientTriageDashboardBaseProps & {
    sortField: string;
    sortDirection: string;
};

type PatientTriageDashboardPinProps = PatientTriageDashboardBaseProps & {
    itemId: string;
    oldValue: boolean;
    newValue: boolean;
    countPinnedRow: number;
    countTotalRow: number;
};

type PatientTriageDashboardPageChangeProps = PatientTriageDashboardBaseProps & {
    newPageNumber: number;
    currentPageNumber: number;
};

type PatientTriageDashboardPageSizeProps = PatientTriageDashboardBaseProps & {
    pageSize: number;
};

/**
 * User views the Patient Triage dashboard page
 * */
export const trackPatientTriageDashboardPageView = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboard",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * User opens the filter by request type dropdown on a graph
 * */
export const trackPatientTriageDashboardFilterByRequestButtonClick = (
    props: PatientTriageDashboardBaseFilterProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardFilter",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User selects an option from the filter by request type on a graph
 * */
export const trackPatientTriageDashboardFilterByRequestMenuItemClick = (
    props: PatientTriageDashboardFilterByRequestProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardFilter",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User types into the filter by name textbox on a graph
 */
export const trackPatientTriageDashboardFilterByNameInput = (
    props: PatientTriageDashboardFilterByNameProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardFilter",
        objectType: EventObjectType.TextBox,
        action: EventAction.Input,
        properties: fullProps,
    });
};

/**
 * User clicks the sort button on a graph
 */
export const trackPatientTriageDashboardSortClick = (
    props: PatientTriageDashboardSortProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardSort",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks the pin button on a graph
 */
export const trackPatientTriageDashboardPinClick = (
    props: PatientTriageDashboardPinProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardPin",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks the page change button on a graph
 */
export const trackPatientTriageDashboardPageChangeClick = (
    props: PatientTriageDashboardPageChangeProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardPageChange",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User opens the page size dropdowns button on a graph
 */
export const trackPatientTriageDashboardPageSizeButtonClick = (
    props: PatientTriageDashboardBaseProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardPageSize",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User selects an option from the page size dropdowns on a graph
 */
export const trackPatientTriageDashboardPageSizeMenuItemClick = (
    props: PatientTriageDashboardPageSizeProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardPageSize",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * user filters a dashboard and gets an empty state and we show a popup.
 */
export const trackPatientTriageDashboardEmptyComponentLoad = (
    props: PatientTriageDashboardBaseProps & {
        activeFilterList: Array<string>;
    },
) => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardEmpty",
        objectType: EventObjectType.Component,
        action: EventAction.Load,
        properties: fullProps,
    });
};

/**
 * when a user clicks on a link/prompt shown when there is no data
 */
export const trackPatientTriageDashboardResourceClick = (
    props: PatientTriageDashboardBaseProps & {
        resourceTitle: string;
        resourceLink: string;
    },
) => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientTriageDashboardResource",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};
