import { ReactNode } from "react";

import { Button, Card, Flex, Icon } from "@accurx/design";

import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { useQuestionnairePreview } from "../Conversation.hooks";
import { StyledQuestionnaires } from "./QuestionnairesList.styles";
import { DisplayFloreyMessageTemplate } from "./types";

const QuestionnaireIcon = (): JSX.Element => (
    <div>
        <Icon
            name="Document"
            theme="Line"
            halo={{
                colour: "blue",
            }}
            size={4}
        />
    </div>
);

const OuterWrapper = ({ children }: { children: ReactNode }) => (
    <Card isElevated={false} spacing={1.5}>
        <Flex flexDirection="column" alignItems="start" gap="1">
            {children}
        </Flex>
    </Card>
);

const IconTextWrapper = ({ children }: { children: ReactNode }) => (
    <Flex alignItems="center" gap="1">
        <QuestionnaireIcon />
        {children}
    </Flex>
);

type QuestionnaireCardLoadingProps = {
    isLoading: true;
};

type QuestionnaireCardDisplayProps = {
    workspaceId: number;
    isLoading: false;
    template: DisplayFloreyMessageTemplate;
    onUseQuestionnaireClick: (
        questionnaire: DisplayFloreyMessageTemplate,
    ) => void;

    // We pass this callback so that all other card in a list can be set to disabled while a request is in flight
    isValidating: boolean;
};

type QuestionnaireCardProps = { workspaceId: number } & (
    | QuestionnaireCardLoadingProps
    | QuestionnaireCardDisplayProps
);

/**
 * Renders a card containing a Florey questionnaire template
 * with a button to use the questionnaire.
 *
 * Does not take into account availability value - a change to
 * this component is needed if we want to treat the rendering
 * of the availability statuses differently from one another.
 */
export const QuestionnaireCard = (
    props: QuestionnaireCardProps,
): JSX.Element => {
    if (props.isLoading) {
        return (
            <OuterWrapper>
                <IconTextWrapper>
                    <SkeletonLabel charCount={20} />
                </IconTextWrapper>
                <Button
                    theme="secondary"
                    icon={{
                        name: "Success",
                        style: "Fill",
                        colour: "blue",
                    }}
                    text="Use questionnaire"
                    onClick={() => {
                        /* Do nothing */
                    }}
                    disabled
                />
            </OuterWrapper>
        );
    }

    return <QuestionnaireCardDisplay {...props} />;
};

const QuestionnaireCardDisplay = ({
    workspaceId,
    template,
    onUseQuestionnaireClick: onClick,
    isValidating,
}: QuestionnaireCardDisplayProps) => {
    const { requestPreview, isLoading } = useQuestionnairePreview(
        workspaceId,
        template.id,
    );

    return (
        <OuterWrapper>
            <IconTextWrapper>
                <StyledQuestionnaires.QuestionnaireNames variant="label">
                    {template.title}
                </StyledQuestionnaires.QuestionnaireNames>
            </IconTextWrapper>

            <Flex gap="1.5">
                <Button
                    theme="secondary"
                    text="Preview"
                    onClick={requestPreview}
                    disabled={isLoading || isValidating}
                />
                <Button
                    theme="secondary"
                    icon={{
                        name: "Success",
                        style: "Fill",
                        colour: "blue",
                    }}
                    text={"Use questionnaire"}
                    onClick={() => onClick(template)}
                    disabled={isValidating}
                />
            </Flex>
        </OuterWrapper>
    );
};
