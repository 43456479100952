import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";

import {
    Organisation,
    useCurrentUser,
    useCurrentWorkspace,
} from "@accurx/auth";
import { ErrorSummaryProvider, Feedback, Flex, Option } from "@accurx/design";
import {
    isDefaultWorkspace,
    useSpecialtiesQuery,
} from "@accurx/workspace-management";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { actionCreators } from "app/account/AccountActions";
import {
    trackWorkspaceEditConfirm,
    trackWorkspaceEditPageView,
} from "app/analytics/FlemingAnalytics";
import { useUpdateCollaborativeWorkspace } from "app/hooks/mutations/useUpdateCollaborativeWorkspace";
import { NavSubMenuBackButton } from "app/navbar/NavSubMenuComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { StyledMain, StyledPageWrapper } from "../shared/Layout/Layout.styles";
import { getDuplicateWorkspaceError } from "../shared/WorkspaceDetailsForm/Errors";
import {
    FormWithErrorSummary,
    Legend,
} from "../shared/WorkspaceDetailsForm/FormLayout";
import {
    StyledButton,
    StyledFlex,
} from "../shared/WorkspaceDetailsForm/WorkspaceDetailsForm.styles";
import {
    WorkspaceDescriptionField,
    WorkspaceNameField,
    WorkspaceSpecialtyField,
} from "../shared/WorkspaceDetailsForm/WorkspaceDetailsFormFields";
import { WORKSPACE_NAME_ID } from "../shared/WorkspaceDetailsForm/constants";
import { useValidateForm } from "../shared/WorkspaceDetailsForm/useValidateForm";
import {
    getSpecialtyAsOption,
    getSpecialtyOptionValue,
    sortSpecialtiesAlphabetically,
} from "../shared/specialtyHelpers";

const EditWorkspacePageContent = () => {
    const history = useHistory();
    const { update, user } = useCurrentUser();

    const workspace = useCurrentWorkspace();

    const {
        orgId: workspaceId,
        organisationName: initialWorkspaceName,
        description: initialWorkspaceDescription,
        settings: { isAdminUser },
        workspaceSpecialties,
    } = workspace;

    const {
        data: { specialties = [], noApplicableSpecialty } = {},
        isLoading: isLoadingSpecialties,
        isError: hasSpecialtiesError,
    } = useSpecialtiesQuery();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const {
        validateForm,
        nameError,
        descriptionError,
        specialtyError,
        manuallyAddErrorSummary,
        manuallySetNameError,
    } = useValidateForm();
    const dispatch = useDispatch();

    const initialSpecialtySelection = useMemo(() => {
        const matchingSpecialty = [
            ...specialties,
            ...(noApplicableSpecialty ? [noApplicableSpecialty] : []),
        ].find(({ name }) => name === workspaceSpecialties[0]);

        return matchingSpecialty
            ? getSpecialtyAsOption(matchingSpecialty)
            : undefined;
    }, [workspaceSpecialties, noApplicableSpecialty, specialties]);

    const [description, setDescription] = useState<string>(
        initialWorkspaceDescription || "",
    );
    const [name, setName] = useState<string>(initialWorkspaceName);
    // because we're not using the specialty yet but we will be!
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedSpecialtyOption, setSelectedSpecialtyOption] = useState<
        Option | undefined
    >(initialSpecialtySelection);

    useEffect(() => {
        if (initialSpecialtySelection) {
            setSelectedSpecialtyOption(initialSpecialtySelection);
        }
    }, [initialSpecialtySelection]);

    useEffect(() => {
        trackWorkspaceEditPageView(analyticsLoggedInProps);
    }, [analyticsLoggedInProps]);

    const navigateToGeneralSettings = () => {
        history.push(
            generatePath(ROUTES_WORKSPACE.workspaceGeneralSettings, {
                workspaceId,
            }),
        );
    };

    const onSuccess = (data: Organisation) => {
        toast(<Feedback colour="success" title="Workspace details saved" />);

        const updatedOrganisations = [...user.organisations];

        const indexOfCurrentWorkspace = updatedOrganisations.findIndex(
            ({ orgId }) => orgId === data.orgId,
        );

        updatedOrganisations[indexOfCurrentWorkspace] = data;

        // update auth provider
        update({ ...user, organisations: updatedOrganisations });
        // update redux store
        dispatch(actionCreators.updateOrganisations(updatedOrganisations));

        navigateToGeneralSettings();
    };

    const onError = (error: Error) => {
        // We get this error message from the backend if the user has tried to use a workspace name that alrady exists
        // Sadly we don't get a specific error code which is why we need to do a string match on the message
        // So that we can display form field errors only if the backend error matches this specific error
        // Any other error messages will just display a generic error (as handled by using the `isError` boolean)
        if (
            error.message ===
            `Another workspace with name ${name} already exists`
        ) {
            const duplicateWorkspaceError =
                getDuplicateWorkspaceError(workspaceId);
            manuallyAddErrorSummary({
                id: WORKSPACE_NAME_ID,
                body: duplicateWorkspaceError.summaryMessage,
            });
            manuallySetNameError(duplicateWorkspaceError);
        }
    };

    const { mutate, isError, isLoading } = useUpdateCollaborativeWorkspace();

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleSpecialtyChange = (selectedOption: Option) => {
        setSelectedSpecialtyOption(selectedOption);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const specialty = getSpecialtyOptionValue(selectedSpecialtyOption);
        const errors = validateForm({ name, description, specialty });
        const isValid = errors.length === 0;

        trackWorkspaceEditConfirm({
            ...analyticsLoggedInProps,
            hasError: !isValid,
            withDescription: !!description,
            errorReason: errors,
        });

        if (!isValid) {
            return;
        }

        mutate(
            {
                name,
                description,
                workspaceId,
                // Validation function has verified this is a number
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                specialties: [specialty!],
            },
            { onSuccess, onError },
        );
    };

    const specialtyOptions = sortSpecialtiesAlphabetically(specialties);

    const hasValidationErrors =
        !!nameError || !!descriptionError || !!specialtyError;
    const isDefaultOrg = isDefaultWorkspace(workspace);

    // Only display generic error message when there's a network error that is not covered by the form validation errors
    const hasNetworkError = isError && !hasValidationErrors;

    return (
        <StyledPageWrapper flexDirection="column" justifyContent="flex-start">
            <NavSubMenuBackButton backCallback={navigateToGeneralSettings} />
            <StyledMain flexDirection="column">
                {isDefaultOrg ? (
                    <Feedback colour="error">
                        The details for this workspace cannot be edited.
                    </Feedback>
                ) : !isAdminUser ? (
                    <Feedback colour="error">
                        You need to be an admin to access this page.
                    </Feedback>
                ) : hasSpecialtiesError ? (
                    <Feedback
                        colour="error"
                        title="Sorry, there was a problem. Please refresh the page."
                    />
                ) : (
                    <FormWithErrorSummary
                        hasFormValidationErrors={hasValidationErrors}
                        hasNetworkError={hasNetworkError}
                        onSubmit={handleSubmit}
                    >
                        <Legend>Edit workspace details</Legend>
                        <StyledFlex flexDirection="column" gap="3">
                            <WorkspaceNameField
                                onChange={handleNameChange}
                                value={name}
                                error={nameError?.detailMessage}
                            />
                            <WorkspaceDescriptionField
                                onChange={handleDescriptionChange}
                                value={description}
                                error={descriptionError}
                            />
                            <Flex>
                                <WorkspaceSpecialtyField
                                    initialSelection={initialSpecialtySelection}
                                    options={specialtyOptions}
                                    onChange={handleSpecialtyChange}
                                    noApplicableSpecialtyOption={getSpecialtyAsOption(
                                        noApplicableSpecialty,
                                    )}
                                    isLoading={isLoadingSpecialties}
                                    error={specialtyError}
                                />
                            </Flex>
                            <StyledButton
                                type="submit"
                                text="Save"
                                disabled={isLoading}
                            />
                        </StyledFlex>
                    </FormWithErrorSummary>
                )}
            </StyledMain>
        </StyledPageWrapper>
    );
};

export const EditWorkspacePage = () => {
    return (
        <ErrorSummaryProvider>
            <EditWorkspacePageContent />
        </ErrorSummaryProvider>
    );
};
