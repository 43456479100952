import { DateFormatOptions, DateHelpers, Log } from "@accurx/shared";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { mapPatientResponseSections } from "shared/concierge/conversations/tickets/mappers/conversationItemMappers/conversationItem.helpers";
import {
    FloreyScoreResponse,
    FloreyScoreValidation,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { FloreyResponseConversationItem } from "shared/concierge/conversations/types/item.types";

type ValidationDisplay = {
    heading: string;
    validatedAt: string;
    complexity: string;
    additionalInfo?: Optional<string>;
};

const groupValidationsByDate = (
    validations: FloreyScoreValidation[],
): Map<string, FloreyScoreValidation[]> => {
    const currentDate = new Date().toISOString();

    const newItems = new Map<string, FloreyScoreValidation[]>();
    const todaysDate = DateHelpers.formatTime(
        currentDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );

    validations.forEach((validation) => {
        const createdAt = DateHelpers.formatTime(
            validation.validatedAt,
            DateFormatOptions.DATE_SHORT_WITH_SPACES,
        );
        const key = createdAt === todaysDate ? "Today" : createdAt;
        const createdAtValidations = newItems.get(key);
        if (!createdAtValidations) {
            newItems.set(key, []);
        }
        newItems.set(key, [...(createdAtValidations || []), validation]);
    });

    return newItems;
};

/**
 *
 * @param floreyScoreValidations
 * @returns Mapped validation data used to display clinician validations of a questionnaire response.
 */
export const mapFloreyScoreValidationsToValidationDisplayByDate = (
    floreyScoreValidations: FloreyScoreValidation[],
): [string, ValidationDisplay[]][] => {
    const validationsByDate = Array.from(
        groupValidationsByDate(floreyScoreValidations),
    );

    return validationsByDate.map(([date, validations]) => {
        return [
            date,
            validations.map(
                (validation): ValidationDisplay => ({
                    heading: `Status - Clinician validation · ${validation.userDisplayName}`,
                    validatedAt: formatConversationItemDateCreatedShort(
                        validation.validatedAt,
                    ),
                    complexity: validation.score,
                    additionalInfo: validation.additionalInformation,
                }),
            ),
        ];
    });
};

/**
 *
 * @param floreyScoreResponse
 * @returns A conversation item with content type FloreyResponseNote. Used to display the
 * florey response linked to a florey score response.
 */
export const mapFloreyScoreResponseToFloreyConversationItem = (
    floreyScoreResponse: FloreyScoreResponse,
): FloreyResponseConversationItem | null => {
    const mappedResponseSectionsResult = mapPatientResponseSections(
        floreyScoreResponse.responses,
    );

    if (mappedResponseSectionsResult.failedToMapCount > 0) {
        Log.error(
            "Cannot map Florey score response as one or more responses have failed to be mapped",
        );

        return null;
    }

    return {
        floreyName: floreyScoreResponse.floreyName,
        id: floreyScoreResponse.ticketIdentity.id || "",
        createdAt: floreyScoreResponse.answeredAt,
        contentType: "FloreyResponseNote",
        createdBy: { type: "Patient" },
        sections: mappedResponseSectionsResult.sections,
    };
};
