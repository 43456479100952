import { Card, Tokens } from "@accurx/design";
import styled from "styled-components";

export const ButtonContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 2px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[1]};
    z-index: 1;
`;

export const StyledCard = styled(({ className, ...props }) => (
    <div className={className}>
        <Card {...props}></Card>
    </div>
))`
    max-width: 550px;
    margin: auto;
    margin-top: ${Tokens.SIZES[5]};
    margin-bottom: 350px;
`;

export const StyledErrorContainer = styled.div`
    max-width: 550px;
    margin: ${Tokens.SIZES[4]} auto;
`;
