import { RegisterFailureType } from "@accurx/api/account";

const getRegisterError = (
    failureType: Optional<RegisterFailureType>,
): string => {
    switch (failureType) {
        case RegisterFailureType.IdentityPasswordRequiresDigit: {
            return "The password must contain at least one number.";
        }

        case RegisterFailureType.IdentityPasswordTooShort: {
            return "The password must contain at least 8 and at max 100 characters long.";
        }

        case RegisterFailureType.IdentityPasswordRequiresUniqueChars: {
            return "The password requires unique characters.";
        }

        case RegisterFailureType.IdentityPasswordRequiresLower: {
            return "The password must contain at least one lower case letter.";
        }

        case RegisterFailureType.IdentityPasswordRequiresUpper: {
            return "The password must contain at least one upper case letter.";
        }

        case RegisterFailureType.PasswordDoesNotMatch: {
            return "Sorry, your passwords don't match";
        }

        case RegisterFailureType.EmailDoesNotMatch: {
            return "Email is not matching";
        }

        case RegisterFailureType.EmailNotAllowed: {
            return "A valid NHS email address is required";
        }

        case RegisterFailureType.EmailWithOrganisationIntegration: {
            return "You can already use this email address to access Accurx without creating an account. Please log in here: web.accurx.com/login with your email address and any password, and you will be redirected to your healthcare organisation's login page. Alternatively, contact your IT support for further help.";
        }

        case RegisterFailureType.NoFeedback:
        default: {
            return "";
        }
    }
};

export { getRegisterError };
