import React from "react";

import { QueryClientProvider as BaseQueryClientProvider } from "@tanstack/react-query";
import { getOrCreateQueryClient } from "domains/api/QueryClient";

type QueryClientProviderProps = {
    children: React.ReactNode;
};

export const QueryClientProvider = (
    props: QueryClientProviderProps,
): JSX.Element => {
    // Our Query Client is used for querying, caching and managing API data
    const queryClient = getOrCreateQueryClient();

    return (
        <BaseQueryClientProvider client={queryClient}>
            {props.children}
        </BaseQueryClientProvider>
    );
};
