import { COMPOSE_ERRORS } from "../constants";
import { Attachment, ComposeErrorType } from "../reducer.types";

export type ValidateClinicianMessageProps = {
    messageBody: string;
    attachments: Attachment[];
    originalGreeting: string;
    withTemplate: boolean;
};

export type ClinicianComposeErrorMessage = Extract<
    ComposeErrorType,
    | "EMPTY_MESSAGE"
    | "ATTACHMENT_STILL_LOADING"
    | "TEMPLATE_PLACEHOLDER_REMAINS"
>;

const placeholderRegex = /\*{5}(\w|\s)*\*{5}/;
export const validateClinicianMessage = (
    arg: ValidateClinicianMessageProps,
): ClinicianComposeErrorMessage[] => {
    const errors: ClinicianComposeErrorMessage[] = [];

    // Do not allow sending if there are attachments in progress
    if (arg.attachments.find((a) => a.origin === "TempUpload")) {
        errors.push(COMPOSE_ERRORS.AttachmentStillLoading);
    }

    if (placeholderRegex.test(arg.messageBody) && arg.withTemplate) {
        errors.push(COMPOSE_ERRORS.TemplatePlaceholderRemains);
    }

    const textNoWhiteSpaces = arg.messageBody.trim();
    const isBodyEmpty = textNoWhiteSpaces === "";
    const isBodyTextEdited = textNoWhiteSpaces !== arg.originalGreeting;
    /**
     * A message is considered edited if the user
     * - somehow changed the body area to be different that the plain greeting
     * - attached a document
     */
    const hasBeenEdited = isBodyTextEdited || arg.attachments.length > 0;
    if (isBodyEmpty || !hasBeenEdited) {
        errors.push(COMPOSE_ERRORS.EmptyMessage);
    }

    return errors;
};
