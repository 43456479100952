import React, { Fragment } from "react";

import { Feedback, Text } from "@accurx/design";

import {
    MedicalRecordResult,
    Medication,
    MedicationType,
} from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { CardSpacer } from "./CardSpacer";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type MedicationsProps = Pick<
    MedicalRecordResult,
    "currentMedications" | "pastMedications"
>;

enum MedicationTitles {
    Current = "Current medications",
    Past = "Past medications (Last 6 months)",
}

export const Medications = ({
    currentMedications,
    pastMedications,
}: MedicationsProps): JSX.Element => {
    return (
        <>
            <MedicationsSection
                title={MedicationTitles.Current}
                medications={currentMedications}
            />
            <CardSpacer spacing="medium" />
            <MedicationsSection
                title={MedicationTitles.Past}
                medications={pastMedications}
            />
        </>
    );
};

type MedicationsSectionProps = {
    title: MedicationTitles;
    medications: Array<Medication>;
};

const MedicationsSection = ({
    title,
    medications,
}: MedicationsSectionProps): JSX.Element => {
    const repeatMedications = medications.filter(
        (medication) => medication.type === MedicationType.Repeat,
    );
    const acuteMedications = medications.filter(
        (medication) => medication.type !== MedicationType.Repeat,
    );

    const isCollapsible = title !== MedicationTitles.Current;

    return (
        <MedicalRecordPanelCard
            title={title}
            count={medications.length}
            collapsible={isCollapsible}
        >
            <MedicationsList
                type={MedicationType.Acute}
                medications={acuteMedications}
                title={title}
            />
            <CardSpacer spacing="small" />
            <MedicationsList
                type={MedicationType.Repeat}
                medications={repeatMedications}
                title={title}
            />
        </MedicalRecordPanelCard>
    );
};

const MedicationWarning = (props: { warningText: string }) => (
    <>
        <Feedback colour="warning" content={props.warningText} />
        <CardSpacer spacing="tiny" />
    </>
);

type MedicationCardProps = { medication: Medication };

const MedicationCard = ({ medication }: MedicationCardProps) => {
    // guidance from clinical team - in GP land 1 month = 28 days, so six months = 168 days
    const SIX_MONTHS_IN_DAYS = 168;
    let warningText: string | null = null;

    // only show one warning, duration takes precendence over quantity
    if (medication.durationDays === null) {
        warningText =
            "This medication is missing duration. Please confirm with patient";
    } else if (medication.durationDays > SIX_MONTHS_IN_DAYS) {
        warningText =
            "This medication duration is more than 6 months. Please confirm with patient";
    } else if (medication.quantity === null) {
        warningText =
            "This medication is missing quantity. Please confirm with patient";
    }

    return (
        <>
            {warningText && <MedicationWarning warningText={warningText} />}
            <Text variant="label" as="h6" skinny>
                {medication.name || "Medication name not provided"}
            </Text>
            <CardSpacer spacing="tiny" />
            <CardRow
                data-testid="dose-instruction"
                type={CardRowType.Default}
                label="Dose instruction"
                title={medication.dosage}
            />
            <CardRow
                data-testid="quantity"
                type={CardRowType.Default}
                label="Quantity"
                title={medication.quantity}
            />
            <CardRow
                data-testid="duration"
                type={CardRowType.Default}
                label="Duration (days)"
                title={
                    medication.durationDays !== null
                        ? `${medication.durationDays}`
                        : null
                }
            />
            <CardRow
                data-testid="date"
                type={CardRowType.Default}
                label="Prescription date"
                title={formatMedicalRecordDate(medication.date, false)}
            />
            <CardRow
                data-testid="last-issue-date"
                type={CardRowType.Default}
                label="Last issue date"
                title={formatMedicalRecordDate(medication.lastIssueDate, false)}
            />
            <CardRow
                data-testid="expiry-date"
                type={CardRowType.Default}
                label="Expiry date"
                title={formatMedicalRecordDate(medication.expiryDate, false)}
            />
            <CardRow
                data-testid="issue-number"
                type={CardRowType.Default}
                label="Issue number"
                title={medication.issueNumber}
            />
        </>
    );
};

type MedicationsListProps = {
    type: MedicationType;
    medications: Medication[];
} & Pick<MedicationsSectionProps, "title">;

export const MedicationsList = ({
    type,
    medications,
    title,
}: MedicationsListProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title={type} nested>
            {medications.length > 0 ? (
                <CardRowStack>
                    {medications.map((medication, i) => {
                        return (
                            <Fragment
                                key={`${medication.name}-${medication.type}-${i}`}
                            >
                                <MedicationCard medication={medication} />
                            </Fragment>
                        );
                    })}
                </CardRowStack>
            ) : (
                <Text
                    skinny
                >{`No recorded ${type.toLowerCase()} ${title.toLowerCase()}`}</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
