import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { getAccumailTemplates } from "api/Accumail/AccumailTemplatesApi";
import {
    AccumailTemplate,
    AccumailTemplateType,
} from "app/practices/accumail/templates.types";

export const ACCUMAIL_TEMPLATES_CACHE_KEY = "AccumailTemplates";

type AccumailTemplatesQueryVariables = {
    workspaceId: number;
    templateType: AccumailTemplateType;
};

export const useAccumailTemplatesQuery = createQueryHook<
    AccumailTemplatesQueryVariables,
    AccumailTemplate[]
>(({ workspaceId, templateType }) => ({
    queryKey: [ACCUMAIL_TEMPLATES_CACHE_KEY, { workspaceId, templateType }],
    queryFn: async () => {
        const response = await getAccumailTemplates(workspaceId, templateType);
        return returnDataOrThrow(response).templates;
    },
}));
