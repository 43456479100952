import { ConversationItem, DeliveryStatus } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Tooltip } from "@accurx/inbox-design-library";
import { format } from "date-fns";

import {
    CancelledTag,
    DeliveredTag,
    FailedTag,
    ReadTag,
    SentTag,
} from "../DeliveryStatusDetail/DeliveryStatusDetail";
import { StyledTimeContainer } from "./DeliveryDetails.styles";

const getGreatedAtTime = (createdAt: string) => {
    try {
        return format(new Date(createdAt), "h:mmaaa");
    } catch (e) {
        return null;
    }
};

const getCreatedAtDate = (createdAt: string) => {
    try {
        return format(new Date(createdAt), "eee dd MMM");
    } catch (e) {
        return null;
    }
};

const getDeliveredAtDateTime = (deliveryStatusUpdatedAt?: string | null) => {
    if (!deliveryStatusUpdatedAt) return null;

    try {
        const date = format(new Date(deliveryStatusUpdatedAt), "eee dd MMM");
        const time = format(new Date(deliveryStatusUpdatedAt), "h:mmaaa");
        return `${time}, ${date}`;
    } catch (e) {
        return null;
    }
};

type DeliveryDetailsWithTooltipProps = {
    createdAt: ConversationItem["createdAt"];
    deliveryStatusUpdatedAt?: string | null;
    deliveryStatus: DeliveryStatus;
};

export const DeliveryDetailsWithTooltip = ({
    createdAt,
    deliveryStatusUpdatedAt,
    deliveryStatus,
}: DeliveryDetailsWithTooltipProps) => {
    const createdAtTime = getGreatedAtTime(createdAt);
    const createdAtDate = getCreatedAtDate(createdAt);
    const deliveredAtDateTime = getDeliveredAtDateTime(deliveryStatusUpdatedAt);

    if (!createdAtTime) {
        return null;
    }

    if (deliveryStatus === "Delivered" || deliveryStatus === "Sent") {
        return (
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    <DeliveryDetails
                        createdAt={createdAt}
                        deliveryStatus={deliveryStatus}
                        isTooltip
                    />
                </Tooltip.Trigger>
                <Tooltip.Content align="end" side="top" $colour="white">
                    <UI.Flex
                        flexDirection="column"
                        gap="0.5"
                        alignItems="flex-end"
                    >
                        <div>
                            <b>Sent</b> {createdAtTime}, {createdAtDate}
                        </div>
                        {deliveryStatus === "Delivered" &&
                            deliveredAtDateTime && (
                                <div>
                                    <b>Delivered</b> {deliveredAtDateTime}
                                </div>
                            )}
                    </UI.Flex>
                </Tooltip.Content>
            </Tooltip.Root>
        );
    }

    return (
        <DeliveryDetails
            createdAt={createdAt}
            deliveryStatus={deliveryStatus}
        />
    );
};

type DeliveryDetailsProps = {
    createdAt: ConversationItem["createdAt"];
    deliveryStatus?: DeliveryStatus | "Read";
    isTooltip?: boolean;
};

export const DeliveryDetails = (props: DeliveryDetailsProps) => {
    const createdAtTime = getGreatedAtTime(props.createdAt);

    return (
        <UI.Flex gap="0.25">
            {props.deliveryStatus === "Delivered" && <DeliveredTag />}
            {props.deliveryStatus === "Sent" && <SentTag />}
            {props.deliveryStatus === "Failed" && <FailedTag />}
            {props.deliveryStatus === "Cancelled" && <CancelledTag />}
            {props.deliveryStatus === "Read" && <ReadTag />}
            <StyledTimeContainer isTooltip={props.isTooltip}>
                {createdAtTime}
            </StyledTimeContainer>
        </UI.Flex>
    );
};
