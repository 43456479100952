import { Tokens } from "@accurx/design";
import { COLOURS } from "@accurx/design/dist/styles/tokens";
import styled from "styled-components";

const grey = COLOURS.greyscale.silver;

export const Border = styled.div`
    margin-top: ${Tokens.SIZES[1]};
    padding-top: ${Tokens.SIZES[1]};
    border-top: 1px solid;
    border-color: ${grey};
`;
