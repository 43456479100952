import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { AssigneeSummary } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapConversationAssigneeToTicketAssignee } from "../mappers/mapConversationAssigneeToTicketAssignee";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type UpdateAssigneeOptions = {
    ticketIdentity: TicketIdentity;
    latestToken: string;
    workspaceId: number;
    assignee: AssigneeSummary;
};

export const updateAssignee: ConciergeFetcher<
    UpdateAssigneeOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const assignee = mapConversationAssigneeToTicketAssignee(arg.assignee);

    const response = await TicketApiClient.assign(
        arg.workspaceId,
        arg.ticketIdentity,
        arg.latestToken,
        assignee,
    );

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
