import { Button } from "@accurx/design";

import { AddPatientsCard } from "./AddPatientsCard";

export const BatchUploadImportFromListCard = ({
    onClick,
    disabled,
    "data-userflow-id": dataUserFlowId,
}: {
    onClick: () => void;
    disabled?: boolean;
    "data-userflow-id"?: string;
}) => {
    return (
        <AddPatientsCard.CardOuter>
            <AddPatientsCard.CardInner>
                <AddPatientsCard.TextWrapper>
                    <AddPatientsCard.Title>
                        Import from Accurx patient list
                    </AddPatientsCard.Title>
                    <AddPatientsCard.Body>
                        Import patient details from a list you have saved in
                        Accurx
                    </AddPatientsCard.Body>
                </AddPatientsCard.TextWrapper>

                <Button
                    text="Continue"
                    theme="secondary"
                    icon={{
                        name: "ArrowTail",
                        rotation: "right",
                        placement: "end",
                    }}
                    onClick={onClick}
                    disabled={disabled}
                    aria-label="Continue to Import Patients From Patient List"
                    data-userflow-id={dataUserFlowId}
                />
            </AddPatientsCard.CardInner>
        </AddPatientsCard.CardOuter>
    );
};
