import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledBadge = styled.span`
    display: block;
    padding: ${UI.Tokens.SIZES[0.25]} ${UI.Tokens.SIZES[1]};
    border-radius: ${UI.Tokens.SIZES[1.5]};
    max-width: 100%;

    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    box-shadow: 0 0 0 1px ${UI.Tokens.COLOURS.greyscale.silver},
        0px 4px 8px 0px rgba(68, 72, 126, 0.15);
`;

export const StyledText = styled(UI.Text)`
    text-overflow: ellipsis;
    overflow: hidden;
`;
