import { useState } from "react";

import { Feedback, Flex, Spinner, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import { generatePath, useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { useUploadBatchPatientsMutation } from "app/hooks/mutations/useUploadBatchPatientsMutation";
import { useIntegratedClinic } from "app/hooks/queries";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { getIndividualClinicPath } from "../../routes";
import { ClinicsAppOrigin } from "../../shared/clinicsAppOrigin";
import { Container } from "../../shared/components/Container.styles";
import { OrderedList } from "../../shared/components/OrderedList.styles";
import { TopNavigation } from "../../shared/components/TopNavigation";
import { BottomNavigation } from "./BottomNavigaton";
import SelectAllCheckbox from "./components/SelectAllCheckbox";
import SelectableAppointmentCard from "./components/SelectableAppointmentCard";
import { mapAppointmentsToBatchPatients } from "./mapAppointmentsToBatchPatients";

const Page = styled.div`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    height: 100%;
`;

const ContentWrapper = styled(Container)`
    overflow-y: scroll;
`;

const Header = styled(Flex).attrs({
    alignItems: "center",
    justifyContent: "space-between",
})``;

export type SelectedAppointments = Map<
    ClinicAppointment["id"],
    ClinicAppointment
>;

type IntegratedClinicURLParams = {
    clinicCode: string;
    clinicDay: string;
    workspaceId: string;
    location: string;
};

export default function SelectClinicPatients() {
    const { clinicCode, clinicDay, workspaceId, location } =
        useParams<IntegratedClinicURLParams>();
    const [selectedAppointments, setSelectedAppointments] =
        useState<SelectedAppointments>(new Map());
    const history = useHistory();

    const { data, status } = useIntegratedClinic({
        clinicCode,
        clinicDay,
        workspaceId,
        location,
    });
    const uploadBatchPatientsMutation = useUploadBatchPatientsMutation();

    const previousPageUrl = getIndividualClinicPath({
        clinicCode,
        clinicDay,
        workspaceId,
        location,
    });

    if (status === "loading") {
        return (
            <Page>
                <TopNavigation previousPageUrl={previousPageUrl} />
                <ContentWrapper>
                    <Text as="h1" variant="subtitle" skinny>
                        Select Patients
                    </Text>
                    <Spinner />
                </ContentWrapper>
                <BottomNavigation continueDisabled />
            </Page>
        );
    }

    if (status === "error") {
        return (
            <Page>
                <TopNavigation previousPageUrl={previousPageUrl} />
                <ContentWrapper>
                    <Text as="h1" variant="subtitle" skinny>
                        Select Patients
                    </Text>
                    <Feedback
                        colour="error"
                        iconName="Failed"
                        title="There was an issue retrieving clinic information"
                    >
                        <Text skinny>No information found</Text>
                    </Feedback>
                </ContentWrapper>
                <BottomNavigation continueDisabled />
            </Page>
        );
    }

    if (!data.appointments?.length) {
        return (
            <Page>
                <TopNavigation previousPageUrl={previousPageUrl} />
                <ContentWrapper>
                    <Text as="h1" variant="subtitle" skinny>
                        Select Patients
                    </Text>
                    <Feedback
                        colour="information"
                        title="There are no appointments for this clinic"
                    />
                </ContentWrapper>
                <BottomNavigation continueDisabled />
            </Page>
        );
    }

    const createNewBatch = async () => {
        try {
            const patients = mapAppointmentsToBatchPatients([
                ...selectedAppointments.values(),
            ]);

            const { id: batchId } =
                await uploadBatchPatientsMutation.mutateAsync({
                    workspaceId,
                    patients,
                });

            const uploadStatusPageUrl = generatePath(
                ROUTES_WORKSPACE.batchMessageTrustReview,
                {
                    workspaceId,
                    batchId,
                },
            );
            history.push(uploadStatusPageUrl, ClinicsAppOrigin);
        } catch (e) {
            Log.error("Error uploading batch patients from clinic list", {
                originalException: e,
            });

            toast(
                <Feedback colour="error" title="Failed to upload patients">
                    <Text skinny>Please refresh page and try again</Text>
                </Feedback>,
            );
        }
    };

    return (
        <Page>
            <TopNavigation previousPageUrl={previousPageUrl} />
            <ContentWrapper>
                <Header>
                    <Text as="h1" variant="subtitle" skinny>
                        Select Patients
                    </Text>
                    <SelectAllCheckbox
                        appointments={data.appointments}
                        selectedAppointments={selectedAppointments}
                        setSelectedAppointments={setSelectedAppointments}
                    />
                </Header>
                <OrderedList>
                    {data.appointments.map((appointment) => (
                        <li key={appointment.id}>
                            <SelectableAppointmentCard
                                appointment={appointment}
                                isSelected={selectedAppointments.has(
                                    appointment.id,
                                )}
                                setSelected={setSelectedAppointments}
                            />
                        </li>
                    ))}
                </OrderedList>
            </ContentWrapper>
            <BottomNavigation
                onContinue={createNewBatch}
                continueDisabled={
                    selectedAppointments.size === 0 ||
                    uploadBatchPatientsMutation.isLoading
                }
            />
        </Page>
    );
}
