import { Tag } from "@accurx/api/florey-builder";
import {
    Button,
    Flex,
    FormFieldWrapper,
    Icon,
    Option,
    SearchSelect,
    Text,
} from "@accurx/design";
import cloneDeep from "lodash/cloneDeep";

import { StyledClickableAreaButton } from "../components/AddQuestionButton";

type ContentTagSelectProps = {
    index: number;
    tags: Tag[];
    selectedTags: number[];
    onChange: (tags: number[]) => void;
    onRemove: () => void;
};

type ContentTagsProps = {
    tags: Tag[];
    tagGroups: number[][];
    onChange: (groups: number[][]) => void;
};

export const ContentTags = ({
    tags,
    tagGroups,
    onChange,
}: ContentTagsProps) => {
    return (
        <div>
            <Text variant="label">Select tags:</Text>
            <Text skinny>
                Tags are used in groups to determine which workspaces see the
                content.
            </Text>
            <Text skinny>
                Select multiple tags in one group to show it to workspaces with
                all selected.
            </Text>
            {tagGroups.map((tagsInGroup, i) => (
                <ContentTagSelect
                    key={`${i}${tagsInGroup.join(",")}`}
                    index={i}
                    tags={tags}
                    selectedTags={tagsInGroup}
                    onRemove={() => {
                        const clonedGroups = cloneDeep(tagGroups);
                        clonedGroups.splice(i, 1);
                        onChange(clonedGroups);
                    }}
                    onChange={(tags) => {
                        const clonedGroups = cloneDeep(tagGroups);
                        clonedGroups[i] = tags;
                        onChange(clonedGroups);
                    }}
                />
            ))}
            <StyledClickableAreaButton
                onClick={() => {
                    const clonedGroups = cloneDeep(tagGroups);
                    clonedGroups.push([]);
                    onChange(clonedGroups);
                }}
            >
                <Icon
                    name="Plus"
                    halo={{ colour: "blue", luminosity: "high" }}
                />
                <Text variant="label">Add tag group</Text>
            </StyledClickableAreaButton>
        </div>
    );
};

const ContentTagSelect = ({
    index,
    tags,
    selectedTags,
    onChange,
    onRemove,
}: ContentTagSelectProps) => {
    const contentTagsAsOptions: Option[] = tags.map((tag) => ({
        label: tag.name,
        value: tag.id.toString(),
        grouping: tag.type,
    }));

    const selectedTagsAsOptions: Option[] = selectedTags.map(
        (selectedTagId) => ({
            label: tags.find((tag) => tag.id === selectedTagId)?.name,
            value: selectedTagId.toString(),
        }),
    );

    const handleChange: (selected: Option | Option[]) => void = (selected) => {
        const newlySelectedTags = (
            Array.isArray(selected) ? selected : [selected]
        ).map((option) => parseInt(option.value, 10));
        onChange(newlySelectedTags);
    };

    return (
        <Flex gap="0.5" flexDirection="column">
            <FormFieldWrapper
                label={`Tag Group #${index + 1}`}
                labelProps={{
                    htmlFor: `select-tag-${index + 1}`,
                }}
                subLabel={
                    "Workspaces need to have all tags in the list in order to match this group."
                }
            >
                <SearchSelect
                    options={contentTagsAsOptions}
                    id={`select-tag-${index + 1}`}
                    onChangeHandler={handleChange}
                    theme="MULTISELECT"
                    initSelectedValues={selectedTagsAsOptions}
                    defaultCopy={{
                        placeholder: "Select tags here",
                    }}
                    // minor hack to fix z index issues with having multiple selects from
                    // @accurx/design on the same page.
                    zIndex={800 - index}
                />
            </FormFieldWrapper>
            <Flex justifyContent="flex-end">
                <Button
                    text="Remove Group"
                    dimension="small"
                    theme="danger"
                    icon={{ name: "Bin" }}
                    onClick={onRemove}
                />
            </Flex>
            <hr />
        </Flex>
    );
};
