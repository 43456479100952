import { Tokens } from "@accurx/design";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledSwitcherContainer = styled("div")`
    display: flex;
    align-items: center;
`;

export const StyledSwitchButton = styled(NavLink)`
    display: block;
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[2]};
    margin-left: ${Tokens.SIZES[1]};
    margin-top: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[1]};
    border-radius: 999px;
    border: 1px solid ${Tokens.COLOURS.primary.blue[100]};

    &:hover,
    &:focus {
        background: ${Tokens.COLOURS.greyscale.silver};
        text-decoration: none;
    }

    &.active {
        background: ${Tokens.COLOURS.primary.blue[100]};
        color: white;
    }
`;
