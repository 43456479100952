import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { ConversationManager } from "shared/concierge/conversations/types/component.types";

import { useGlobalConversationManager } from "./GlobalConversationManagerContext";

export const ConversationManagerContext =
    createContext<ConversationManager | null>(null);

type ConversationManagerProviderProps = {
    workspaceId: number | null;
    is2FAed: boolean;
    children?: ReactNode;
};

/**
 * ConversationManagerProvider
 *
 * Sets up a conversation manager instance for a given workspace
 * and exposes it via React Context. Child components can access
 * the conversation manager through a hook:
 * `useConversationManager()`
 *
 * Any child components are guaranteed to have access to a conversation
 * manager instance because this provider will not render any of its
 * children until an instance is set up.
 */
export const ConversationManagerProvider = ({
    workspaceId,
    is2FAed,
    children,
}: ConversationManagerProviderProps): JSX.Element => {
    const newInboxEnabled = useFeatureFlag("AccurxWebEmbeddedUnifiedInbox");
    const globalConversationManager = useGlobalConversationManager();
    const [manager, setManager] = useState<ConversationManager | null>(null);

    useEffect(() => {
        if (is2FAed && !newInboxEnabled) {
            // If not 2FAed, we cannot start the conversation manager
            // we also don't want to start it if the new inbox is enabled
            const nextManager =
                workspaceId && Number.isSafeInteger(workspaceId)
                    ? globalConversationManager?.forWorkspace(workspaceId)
                    : undefined;
            setManager(nextManager?.item ?? null);
            return () => nextManager?.release();
        } else {
            // We should never move from 2FA to non-2FA in reality, but if we do ensure we
            // clear the manager
            setManager(null);
        }
    }, [workspaceId, newInboxEnabled, is2FAed, globalConversationManager]);

    return (
        <ConversationManagerContext.Provider value={manager}>
            {/* Always return children, this does mean children are not guaranteed to have access to the manager,
                but allows us to control the UI before the manager exists i.e.. before 2FA or while it is initialising */}
            {children}
        </ConversationManagerContext.Provider>
    );
};

/**
 * Provides a conversation manager instance for the current workspace. If you cannot
 * guarantee that the conversation manager is loaded, then use useOptionalConversationManager
 *
 * @returns The conversation manager
 */
export const useConversationManager = (): ConversationManager => {
    const manager = useOptionalConversationManager();

    if (!manager) {
        throw new Error(
            "You tried to access the conversation manager from a component that hasnt been wrapped in a <ConversationManagerProvider> or before the manager has been initialised.",
        );
    }

    return manager;
};

/**
 * Provides a conversation manager instance for the current workspace, if one is available
 * If this returns a value, we can also guarantee there is a UserTeamManager and PatientManager
 * available because the conversation manager requires them
 *
 * @returns The conversation manager or null
 */
export const useOptionalConversationManager = (): ConversationManager | null =>
    useContext(ConversationManagerContext);

/**
 * Export the context provider directly so that our UI tests can easily
 * wrap a component with a fake conversation manager;
 */
export const StaticConversationManagerProvider =
    ConversationManagerContext.Provider;
