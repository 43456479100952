import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { PatientSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { PatientNotFound } from "domains/inbox/components/PatientNotFound/PatientNotFound";
import { formatPatientName } from "domains/inbox/util/format/patientDisplayInfo";

type PatientNameTagProps = {
    patientId: PatientSummary["patientId"] | undefined;
};

export const PatientNameTag = ({ patientId }: PatientNameTagProps) => {
    const patient = usePatient({ patientId });

    if (!patientId || !patient) {
        return <PatientNotFound />;
    }

    return (
        <MessageNameTag
            avatar={<UI.Avatar size="small" colour="blue" />}
            name={formatPatientName({
                firstName: patient.firstName,
                familyName: patient.familyName,
                prefixName: patient.prefixName,
            })}
        />
    );
};
