import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledMobileHeader = styled.nav`
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
    display: flex;
    justify-content: space-between;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;
