import { SnomedCodeFull } from "@accurx/api/patient-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

export const useSnomedCodesQuery = <T = SnomedCodeFull[]>({
    select,
    enabled,
}: {
    enabled: boolean;
    select: (data: SnomedCodeFull[]) => T;
}) => {
    const workspaceId = useCurrentWorkspace().orgId;

    return useQuery({
        queryKey: ["SnomedCodes", { workspaceId }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/BatchMessaging/${workspaceId}/SnomedCodes`,
                }),
            );

            return returnDataOrThrow<SnomedCodeFull[]>(response);
        },
        select,
        enabled,
    });
};
