export const pink = "#fcd1cf";
export const lightblue = "#727492";
export const darkblue = "#191C4D";
export const grey = "#7c7780";
export const lightgrey = "#686577";
export const ashGray = "#C2D1CF";
export const caribbeanCurrent = "#075959";
export const feldgrau = "#506262";
export const battleshipGrey = "#798585";
export const doveGray = "#6C7097";

// Night with opacity set to 10%
export const night10 = "rgba(25, 28, 77, 0.10)";

export const metal32 = "rgba(68, 72, 126, 0.32)";

export const zinc = {
    tint10: "#4A4C73",
    tint20: "#5E6082",
    tint30: "#727492",
    tint45: "#9092A9",
    tint55: "#A5A5B9",
    tint70: "#C3C3D0",
};
