import {
    ArchiveRequest2,
    ArchiveResponse,
    AssignConversationRequest,
    AssignResponse,
    ClinicianConversationWithWorkspace,
    GetConversationsRequest,
    GetConversationsResponse,
    MarkAsReadRequest,
    MarkResponse,
    SendRequest,
    SendResponse,
    UnreadSummaryResponse,
} from "@accurx/api/clinician-messaging";
import { Log, getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

const ENDPOINTS = {
    conversation:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversation/:conversationId",
    conversations:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations",
    send: "/api/clinicianmessaging/workspaces/:workspaceId/conversation/:workspaceConversationId/send",
    archive:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations/:workspaceConversationId/archive",
    unarchive:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations/:workspaceConversationId/unarchive",
    unreadSummary:
        "/api/clinicianmessaging/workspaces/:workspaceId/unread-summary",
    assignWithNote:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations/:workspaceConversationId/assign",
    markItemsAsRead:
        "/api/clinicianmessaging/workspaces/:workspaceId/conversations/:workspaceConversationId/mark",
} as const;

type WorkspaceConversationParams = {
    workspaceId: string;
    workspaceConversationId: string;
};

export class ClinicianMessagingFetchError extends Error {
    public readonly name = "ClinicianMessagingFetchError";

    constructor(
        public readonly statusCode: number | null,
        public readonly message: string,
    ) {
        super(message);
    }
}

export async function fetchConversation(arg: {
    workspaceId: number;
    conversationId: string;
}) {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.conversation,
            params: {
                workspaceId: arg.workspaceId.toString(),
                conversationId: arg.conversationId,
            },
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new ClinicianMessagingFetchError(
            response.statusCode,
            response.error ??
                `failed to conversation in workspace ${arg.workspaceId}`,
        );
    }

    return response.result as ClinicianConversationWithWorkspace;
}

export async function fetchConversations(
    arg: GetConversationsRequest & { workspaceId: number },
) {
    const { workspaceId, ...requestBody } = arg;
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.conversations,
            params: { workspaceId: workspaceId.toString() },
        }),
        requestBody,
    );

    if (!response.success || isNil(response.result)) {
        throw new ClinicianMessagingFetchError(
            response.statusCode,
            response.error ??
                `failed to conversation group in workspace ${workspaceId}`,
        );
    }

    return response.result as GetConversationsResponse;
}

export async function fetchUnreadSummary(arg: { workspaceId: number }) {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.unreadSummary,
            params: { workspaceId: arg.workspaceId.toString() },
        }),
    );

    if (!response.success || isNil(response.result)) {
        throw new ClinicianMessagingFetchError(
            response.statusCode,
            response.error ??
                `failed to conversation group in workspace ${arg.workspaceId}`,
        );
    }

    return response.result as UnreadSummaryResponse;
}

/**
 * Send a message as part of an existing conversation
 * or create a new conversation
 */
export const send = async ({
    workspaceId,
    workspaceConversationId,
    ...request
}: SendRequest & WorkspaceConversationParams): Promise<SendResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.send,
            params: {
                workspaceId,
                workspaceConversationId,
            },
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as SendResponse;
};

export const archive = async ({
    workspaceId,
    workspaceConversationId,
    rowVersionBase64,
}: ArchiveRequest2 & WorkspaceConversationParams): Promise<ArchiveResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.archive,
            params: {
                workspaceId,
                workspaceConversationId,
            },
        }),
        { rowVersionBase64 },
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.

        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as ArchiveResponse;
};

export const unarchive = async ({
    workspaceId,
    workspaceConversationId,
    rowVersionBase64,
}: ArchiveRequest2 & WorkspaceConversationParams): Promise<ArchiveResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.unarchive,
            params: {
                workspaceId,
                workspaceConversationId,
            },
        }),
        { rowVersionBase64 },
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as ArchiveResponse;
};

export const assignWithNote = async ({
    workspaceId,
    workspaceConversationId,
    newAssigneeUserId,
    notes,
}: AssignConversationRequest &
    WorkspaceConversationParams): Promise<AssignResponse> => {
    const response = await httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.assignWithNote,
            params: {
                workspaceId,
                workspaceConversationId,
            },
        }),
        { newAssigneeUserId, notes },
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as AssignResponse;
};

export const markItemsAsRead = async ({
    workspaceId,
    workspaceConversationId,
    incomingMessageIds,
    outgoingMessageIds,
    eventIds,
}: MarkAsReadRequest & WorkspaceConversationParams): Promise<MarkResponse> => {
    const response = await httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markItemsAsRead,
            params: {
                workspaceId,
                workspaceConversationId,
            },
        }),
        { incomingMessageIds, outgoingMessageIds, eventIds },
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as AssignResponse;
};
