import { PatientSummary } from "domains/concierge/types";

export const shouldReplacePatient = (
    existing: PatientSummary,
    update: PatientSummary,
) => {
    // If we don't currently have patient demographics  - always update
    if (!existing.demographicsLastRefreshed) return true;

    // if the update doesn't have patient demographics, but existing patient has - never update
    if (!update.demographicsLastRefreshed) return false;

    // Only update the patient if the update is more fresh than the existing
    return (
        new Date(update.demographicsLastRefreshed) >
        new Date(existing.demographicsLastRefreshed)
    );
};
