import { useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { addDays, isAfter, parseISO } from "date-fns";
import { useCompose } from "domains/compose/context";
import { userflowIds } from "domains/compose/userflow";

import { useAppointmentsConfigurationQuery } from "../../../../hooks/useAppointmentConfigurationQuery";
import {
    StyledBookingInvitePopoverContent,
    StyledBookingInvitePopoverHeader,
} from "./BookingInvite.styles";
import { BookingInviteButton } from "./BookingInviteButton";
import { BookingLinkWarning } from "./BookingLinkWarning";

type BookingInviteProps = {
    onClick: () => void;
};

type BookingInviteBlockers = {
    warning:
        | "SendingAsEmail"
        | "HasResponseLink"
        | "HasAttachmentLink"
        | "HasNHSAdviceLink"
        | "HasQuestionnaireLink"
        | "MessageScheduledToFarInAdvance"
        | "SelfBookLinkAlreadyPresent"
        | "HasNHSAdvice";
    text: string;
};

export const BookingInvite = ({ onClick }: BookingInviteProps) => {
    const { state } = useCompose();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { data, status } = useAppointmentsConfigurationQuery();

    const [isBookingInviteOpen, setIsBookingInviteOpen] = useState(false);

    const toggleBookingInvitePopover = () => {
        setIsBookingInviteOpen((prev) => !prev);
    };

    const isCustomDateAndTime =
        state.sendAt?.type === "calendar" || state.sendAt?.type === "frame";

    const selectedSendDateAndTime =
        state.sendAt?.sendAtDateTime && parseISO(state.sendAt.sendAtDateTime);

    const maxDateForScheduling =
        status === "success" &&
        data.selfBookSchedulingWeeksIntoFuture &&
        addDays(new Date(), data.selfBookSchedulingWeeksIntoFuture * 7); // n*7 equivalent to n days
    const selectedTimeIsAfterAllowedSchedulingTimeFrame =
        selectedSendDateAndTime &&
        maxDateForScheduling &&
        isAfter(selectedSendDateAndTime, maxDateForScheduling);

    const bookingInviteBlockers = useMemo(():
        | BookingInviteBlockers
        | undefined => {
        if (state.contactDetails.method === "Email") {
            return {
                warning: "SendingAsEmail",
                text: "Self-Book not available for emails",
            };
        }

        if (state.isPatientResponseEnabled) {
            return {
                warning: "HasResponseLink",
                text: "Self-Book not available with a response link",
            };
        }

        if (state.attachments.length > 0) {
            return {
                warning: "HasAttachmentLink",
                text: "Self-Book not available with an attachment link",
            };
        }

        if (state.selfBookLink !== null) {
            return {
                warning: "SelfBookLinkAlreadyPresent",
                text: "Only 1 Self-Book link per message",
            };
        }

        if (
            state.template !== null &&
            state.template.type === "QuestionnaireTemplate"
        ) {
            return {
                warning: "HasQuestionnaireLink",
                text: "Self-Book not available with a questionnaire link",
            };
        }

        if (
            isCustomDateAndTime &&
            selectedTimeIsAfterAllowedSchedulingTimeFrame
        ) {
            return {
                warning: "MessageScheduledToFarInAdvance",
                text: "Self-Book not available with this scheduled time",
            };
        }

        if (state.nhsAdviceLink !== null) {
            return {
                warning: "HasNHSAdvice",
                text: "Self-Book not available with a NHS.UK advice link",
            };
        }
    }, [
        state.contactDetails.method,
        state.isPatientResponseEnabled,
        state.attachments.length,
        state.selfBookLink,
        state.template,
        state.nhsAdviceLink,
        isCustomDateAndTime,
        selectedTimeIsAfterAllowedSchedulingTimeFrame,
    ]);

    return (
        <>
            {bookingInviteBlockers ? (
                <UI.Popover
                    open={isBookingInviteOpen}
                    onOpenChange={toggleBookingInvitePopover}
                >
                    <UI.PopoverTrigger asChild={true}>
                        <BookingInviteButton />
                    </UI.PopoverTrigger>
                    <StyledBookingInvitePopoverContent
                        align="start"
                        aria-labelledby="bookingLinkButtonWarningTitle"
                        aria-describedby="bookingLinkButtonWarningContent"
                    >
                        <StyledBookingInvitePopoverHeader>
                            <UI.Text
                                skinny
                                variant="label"
                                colour="night"
                                props={{ id: "bookingLinkButtonWarningTitle" }}
                            >
                                Self-Book
                            </UI.Text>
                        </StyledBookingInvitePopoverHeader>
                        <UI.PopoverItem>
                            <BookingLinkWarning
                                id="bookingLinkButtonWarningContent"
                                text={bookingInviteBlockers.text}
                            />
                        </UI.PopoverItem>
                    </StyledBookingInvitePopoverContent>
                </UI.Popover>
            ) : (
                <BookingInviteButton
                    onClick={() => {
                        onClick();
                        track("ConversationBookingAddStart Button Click", {
                            accessType: nativeTrackingFields.accessType,
                            medicalRecordSystem:
                                nativeTrackingFields.medicalRecordSystem,
                            appOrigin: "QuickReply",
                        });
                    }}
                    userflowId={userflowIds.bookingLinkButton}
                />
            )}
        </>
    );
};
