/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, {
    ChangeEvent,
    ElementType,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Avatar,
    FormFieldWrapper,
    Icon,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@accurx/design";
import Fuse from "fuse.js";
import debounce from "lodash/debounce";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SkeletonSubtitle } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { Assignee } from "app/workspaceConversations/workspaceConversations.types";

import {
    StyledBadge,
    StyledContainer,
    StyledCurrentAssigneeContainer,
    StyledDetailsSection,
    StyledIconSection,
    StyledPopoverContentWrapper,
    StyledSearchInput,
    StyledTeamIconWrapper,
    StyledText,
} from "./Assign.styles";
import { getAssigneeDisplayName, getAssigneeId } from "./AssignHelper";
import { AssignItem } from "./AssignItem";
import { NoUserFound } from "./NoUserFound";

export interface AssignProps {
    isLoaded: boolean;
    error: boolean;
    assignmentCollection: Assignee[];
    assignee: Assignee;
    loggedInUserId?: string;
    handleOnAssigneeChange: (
        newAssignee: Assignee,
        hasSearched: boolean,
    ) => void;
    isDisabled: boolean;
    conversationId: string;
}

const options = {
    isCaseSensitive: false,
    includeScore: false,
    includeMatches: false,
    threshold: 0.4,
    keys: [
        {
            name: "displayName",
            getFn: (item: Assignee) => {
                return getAssigneeDisplayName(item) || "";
            },
        },
    ],
};

export const Assign = ({
    isLoaded,
    error,
    assignmentCollection = [],
    assignee,
    loggedInUserId,
    isDisabled,
    handleOnAssigneeChange,
    conversationId,
}: AssignProps): JSX.Element => {
    const [open, setOpen] = useState(false);
    const [assignmentItemCollection, setAssignmentItemCollection] =
        useState<Assignee[]>(assignmentCollection);
    const [searchText, setSearchText] = useState("");
    const [hasSearched, setHasSearched] = useState(false);
    const assigneeId = getAssigneeId(assignee);

    const selectedAssignee = useMemo(() => {
        return assignmentCollection.find(
            (item) =>
                getAssigneeId(item) === assigneeId &&
                item.type === assignee.type,
        );
    }, [assigneeId, assignee.type, assignmentCollection]);

    const isLoggedInUser = loggedInUserId === assigneeId;

    const fuseSearch = useMemo(() => {
        return new Fuse(assignmentCollection, options);
    }, [assignmentCollection]);

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const debounceUserSearch = useMemo(
        () =>
            debounce((searchValue) => {
                if (searchValue === "") {
                    setAssignmentItemCollection(assignmentCollection);
                    return;
                }

                const searchResult = fuseSearch.search(searchValue);
                const matchingUsers = searchResult.map(({ item }) => item);
                setAssignmentItemCollection(matchingUsers);
            }, 500),
        [fuseSearch],
    );

    const handleOnUserSearch = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearchText(e.target.value);
        setHasSearched(true);
        debounceUserSearch(e.target.value);
    };

    const handleOnAssigneeItemChange = (newAssignee: Assignee) => {
        setOpen(false);
        handleOnAssigneeChange(newAssignee, hasSearched);
    };

    useEffect(() => {
        if (!open) {
            setSearchText("");
            setAssignmentItemCollection(assignmentCollection);
            setHasSearched(false);
        }
    }, [open]);

    useEffect(() => {
        setAssignmentItemCollection(assignmentCollection);
    }, [isLoaded]);

    if (!isLoaded) {
        return (
            <StyledCurrentAssigneeContainer>
                <SkeletonSubtitle charCount={20} />
            </StyledCurrentAssigneeContainer>
        );
    }
    // to change later
    if (error) {
        return <></>;
    }

    const getCurrentAssigneeView = () => {
        const StyledContainer: ElementType = isDisabled
            ? React.Fragment
            : StyledCurrentAssigneeContainer;
        return (
            <StyledContainer>
                <StyledDetailsSection isDisabled={isDisabled}>
                    {selectedAssignee?.type === "Team" && (
                        <StyledTeamIconWrapper>
                            <Icon
                                theme="Line"
                                name="Team"
                                size={3}
                                colour="night"
                            />
                        </StyledTeamIconWrapper>
                    )}
                    {selectedAssignee?.type === "User" && (
                        <Avatar
                            name={getAssigneeDisplayName(selectedAssignee)}
                            size="medium"
                            colour={isLoggedInUser ? "blue" : "grey"}
                        />
                    )}
                    {selectedAssignee ? (
                        <>
                            <StyledText skinny variant="note">
                                {getAssigneeDisplayName(selectedAssignee)}
                            </StyledText>
                            <StyledBadge
                                colour="green"
                                dimension="medium"
                                luminosity="high"
                                radius="regular"
                                text="Assignee"
                                theme="solid"
                            />
                        </>
                    ) : (
                        <StyledText skinny variant="note">
                            No assignee
                        </StyledText>
                    )}
                </StyledDetailsSection>
                {!isDisabled && (
                    <StyledIconSection isOpen={open}>
                        <Icon
                            id="edit_assignee"
                            name="Pencil"
                            size={3}
                            theme="Line"
                            title="Edit Assignee"
                            colour="blue"
                        />
                    </StyledIconSection>
                )}
            </StyledContainer>
        );
    };

    if (isDisabled) {
        return getCurrentAssigneeView();
    }

    const handleOnOpenChange = (open: boolean) => {
        setOpen(open);
        if (open) {
            FlemingAnalyticsTracker.trackPatientConversationAssignButtonClick({
                ...analyticsLoggedInProps,
                conversationId,
                hasError: false,
            });
        }
    };

    return (
        <Popover open={open} onOpenChange={handleOnOpenChange}>
            <PopoverTrigger>{getCurrentAssigneeView()}</PopoverTrigger>
            <PopoverContent align="center" side="bottom" width="auto">
                <StyledPopoverContentWrapper>
                    <FormFieldWrapper
                        label="Search for colleague"
                        labelProps={{
                            htmlFor: "search-colleague",
                            className: "sr-only",
                        }}
                    >
                        <StyledSearchInput
                            id="search-colleague"
                            isSearchInput={true}
                            placeholder="Search for colleague"
                            value={searchText}
                            onChange={handleOnUserSearch}
                        />
                    </FormFieldWrapper>
                    <StyledContainer>
                        {assignmentItemCollection.length === 0 && (
                            <NoUserFound />
                        )}
                        {assignmentItemCollection.length > 0 &&
                            assignmentItemCollection.map((item) => (
                                <AssignItem
                                    key={`${item.type}-${getAssigneeId(item)}`}
                                    handleAssigneeItemClick={
                                        handleOnAssigneeItemChange
                                    }
                                    assignee={item}
                                    isLoggedInUser={
                                        loggedInUserId === getAssigneeId(item)
                                    }
                                    selectedItem={
                                        assigneeId === getAssigneeId(item) &&
                                        assignee.type === item.type
                                    }
                                />
                            ))}
                    </StyledContainer>
                </StyledPopoverContentWrapper>
            </PopoverContent>
        </Popover>
    );
};
