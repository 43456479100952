import { z } from "zod";

export const TeamAssigneeSummarySchema = z.object({
    type: z.literal("Team"),
    id: z.string(),
});

export const UserAssigneeSummarySchema = z.object({
    type: z.literal("User"),
    id: z.string(),
});

export const NoAssigneeSummarySchema = z.object({
    type: z.literal("None"),
});

export const AssigneeSummarySchema = z.discriminatedUnion("type", [
    TeamAssigneeSummarySchema,
    UserAssigneeSummarySchema,
    NoAssigneeSummarySchema,
]);

export type UserAssigneeSummary = z.infer<typeof UserAssigneeSummarySchema>;
export type TeamAssigneeSummary = z.infer<typeof TeamAssigneeSummarySchema>;
export type NoAssigneeSummary = z.infer<typeof NoAssigneeSummarySchema>;
export type AssigneeSummary = z.infer<typeof AssigneeSummarySchema>;
