import {
    BatchType,
    FloreyListGroup,
    FloreyTemplate,
    FloreysList,
    MaxBatchSMSSize,
    MaxBatchSelfbookSMSSize,
    MaxSingleSMSSize,
} from "app/batchMessage/gp/BatchMessage.types";

import {
    AVG_PATIENT_LENGTH,
    BATCH_MESSAGE_TEMPLATE_INSERT_TOKEN,
    FOOTER_DOCUMENT,
    FOOTER_FLOREY,
    PLACEHOLDER_LINK_PATIENT_PORTAL,
} from "./BatchMessageCompose.constants";
import { MessageErrorType } from "./BatchMessageCompose.types";

const calculateMessageLength = (
    type: BatchType,
    maxGreetingLength: number,
    bodyLength: number,
    orgNameLength: number,
    hasDocument = false,
    { linkExpiryLength } = { linkExpiryLength: 0 },
): number => {
    const sizeGreeting = maxGreetingLength || AVG_PATIENT_LENGTH;
    const sizeGreetingAndBody = sizeGreeting + bodyLength + (orgNameLength + 1); // There is a new line character before the org name
    switch (type) {
        case BatchType.MESSAGE:
            if (!!hasDocument) {
                const sizeFooter =
                    FOOTER_DOCUMENT.length +
                    PLACEHOLDER_LINK_PATIENT_PORTAL.length;
                return sizeGreetingAndBody + sizeFooter;
            }
            return sizeGreetingAndBody;
        case BatchType.FLOREY:
            const sizeFooter =
                FOOTER_FLOREY.length + PLACEHOLDER_LINK_PATIENT_PORTAL.length;
            return sizeGreetingAndBody + sizeFooter;
        case BatchType.INHALER:
        default:
            return sizeGreetingAndBody;
    }
};

type ErrorValidationType = {
    validMessageBody: boolean;
    messageErrorMessage: string;
    messageErrorType: MessageErrorType;
};

const isValidMessage = (
    textToCheck: string,
    totalCharacterCount: number,
    isSelfbook?: boolean,
): ErrorValidationType => {
    const maxSize = isSelfbook ? MaxBatchSelfbookSMSSize : MaxBatchSMSSize;
    const selfbookMessageError = `Reduce the character numbers. Self-Book messages can only be sent in 2 fragments (below ${
        MaxBatchSelfbookSMSSize + 1
    } characters).`;
    const generalMessageError = `Sorry, your message must be equal to or less than ${MaxBatchSMSSize} characters.`;
    // Body can't be empty
    if (textToCheck.length <= 2) {
        return {
            validMessageBody: false,
            messageErrorMessage: "",
            messageErrorType: MessageErrorType.None,
        };
    }

    if (totalCharacterCount > maxSize) {
        return {
            validMessageBody: false,
            messageErrorMessage: isSelfbook
                ? selfbookMessageError
                : generalMessageError,
            messageErrorType: MessageErrorType.Error,
        };
    }

    if (textToCheck.includes(BATCH_MESSAGE_TEMPLATE_INSERT_TOKEN)) {
        return {
            validMessageBody: false,
            messageErrorMessage: "Please complete your message first.",
            messageErrorType: MessageErrorType.Error,
        };
    }

    if (!isSelfbook && totalCharacterCount > MaxSingleSMSSize) {
        return {
            validMessageBody: true,
            messageErrorMessage:
                "Your message will be split into multiple segments, usually displayed as one text message. Reduce the number of characters to send a single text message.",
            messageErrorType: MessageErrorType.Warning,
        };
    }

    return {
        validMessageBody: true,
        messageErrorMessage: "",
        messageErrorType: MessageErrorType.None,
    };
};

const findFloreyCopd = (floreysList: FloreysList): FloreyTemplate | null => {
    let selectedFlorey: FloreyTemplate | null = null;
    floreysList.groups.some((group: FloreyListGroup) =>
        group.messageTemplates.some((template: FloreyTemplate) => {
            if (template.availableInBatchForFree) {
                selectedFlorey = template;
                return true;
            }
            return false;
        }),
    );
    return selectedFlorey;
};

const findFloreyById = (
    floreysList: FloreysList,
    initialSelectedFloreyId: string,
): FloreyTemplate | null => {
    let selectedFlorey: FloreyTemplate | null = null;
    floreysList.groups.some((group: FloreyListGroup) =>
        group.messageTemplates.some((template: FloreyTemplate) => {
            if (template.id === initialSelectedFloreyId) {
                selectedFlorey = template;
                return true;
            }
            return false;
        }),
    );
    return selectedFlorey;
};

export {
    calculateMessageLength,
    isValidMessage,
    findFloreyCopd,
    findFloreyById,
};
