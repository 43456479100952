import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import FlemingApiClient from "api/FlemingApiClient";
import { GetRecentSearchesResponse } from "api/FlemingDtos";

export const SEARCH_WITHIN_RECENT_SEARCHES_CACHE_KEY =
    "SearchWithinRecentSearches";

type SearchWithinRecentSearchesQueryVariables = {
    workspaceId: number | null;
    queryText: string;
};

export const useSearchWithinRecentSearchesQuery = createQueryHook<
    SearchWithinRecentSearchesQueryVariables,
    GetRecentSearchesResponse
>(({ workspaceId, queryText }) => ({
    queryKey: [
        SEARCH_WITHIN_RECENT_SEARCHES_CACHE_KEY,
        { workspaceId, queryText },
    ],
    queryFn: async () => {
        const response = await FlemingApiClient.queryRecentPatientSearches({
            organisationId: workspaceId,
            queryText,
        });
        return returnDataOrThrow(response);
    },
    refetchOnMount: true,
}));
