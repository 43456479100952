import { PatientThreadFloreyResponseNote } from "@accurx/api/ticket";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { mapWriteToRecordState } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapWriteToRecordState";
import {
    FloreyResponseItem,
    PatientSingleResponseItem,
} from "domains/concierge/types";

import { mapPatientResponseSections } from "./shared/mapPatientResponseSections";

/**
 * Maps ticket Florey response to conversation Florey responses
 *
 * This can be both patient single response or Florey responses
 *
 * @param ticketFloreyItem Florey response as it's sent from the API
 * @returns mapped Florey response conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapFloreyResponseItem(
    ticketFloreyItem: PatientThreadFloreyResponseNote,
): FloreyResponseItem | PatientSingleResponseItem | undefined {
    if (!ticketFloreyItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketFloreyItem.message.responses) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    // If this is empty it means that the patient didn't respond in time
    const responses = ticketFloreyItem.message.responses;
    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const isPatientSingleResponse =
        ticketFloreyItem.message.isPatientSingleResponse;

    const baseConversationItem = mapBaseConversationItem(ticketFloreyItem);
    const patientId = ticketFloreyItem.patientAccuRxId ?? undefined;

    const saveToRecordState = mapWriteToRecordState(
        ticketFloreyItem.message.writeToRecordState,
    );

    if (isPatientSingleResponse) {
        return {
            ...baseConversationItem,
            contentType: "PatientSingleResponse",
            sections: result.sections,
            createdBy: {
                type: "Patient",
                id: patientId,
            },
            saveToRecordState,
        };
    }

    return {
        ...baseConversationItem,
        contentType: "FloreyResponseNote",
        createdBy: {
            type: "Patient",
            id: patientId,
        },
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        floreyName: ticketFloreyItem.message.floreyName || null,
        sections: result.sections,
        saveToRecordState,
        reference: ticketFloreyItem.message.reference
            ? {
                  text: ticketFloreyItem.message.reference.text ?? null,
                  link: ticketFloreyItem.message.reference.link ?? null,
              }
            : null,
        markdown: ticketFloreyItem.message.patientThreadMarkdown ?? null,
    };
}
