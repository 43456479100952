import { MessagePatientRequestWithExternalIdentity } from "@accurx/api/patient-messaging";
import { mapConversationIdToTicketIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToTicketIdentity";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { MessagePatientProps } from "domains/concierge/types/messagePatient.types";

import { mapAttachmentsToAttachDocumentIds } from "./mapAttachmentsToAttachDocumentIds";
import { mapAttachmentsToTemplateAttachmentIds } from "./mapAttachmentsToTemplateAttachmentIds";
import { mapSelfBookToSelfBookRequestType } from "./mapSelfBookToSelfBookRequestType";

export const mapMessagePatientWithExternalIdentity = ({
    workspaceId,
    conversation,
    patient,
    mobileNumber,
    demographicsMobileNumber,
    emailAddress,
    messageBody,
    messageSignature,
    attachments,
    enablePatientResponse,
    template,
    sendTime,
    nhsChoicesLink,
    selfBook,
}: MessagePatientProps): MessagePatientRequestWithExternalIdentity => {
    const ticketIdentity = mapConversationIdToTicketIdentity(conversation.id);

    const externalIds = mapPatientSummaryExternalIdsToTicketPatientExternalIds(
        patient.externalIds,
    );

    const attachedDocumentIds = mapAttachmentsToAttachDocumentIds(attachments);
    const attachmentIds = mapAttachmentsToTemplateAttachmentIds(attachments);
    const selfBookRequest = mapSelfBookToSelfBookRequestType(selfBook);

    const messageTemplate =
        template?.type === "MessageTemplate"
            ? {
                  id: parseInt(template.value.id),
                  attachmentIds,
                  isPreset: template.value.owner === "Accurx",
                  name: template.value.title,
                  group: template.value.heading,
                  level: template.value.owner,
              }
            : undefined;

    const questionnaireParams =
        template?.type === "QuestionnaireTemplate"
            ? {
                  conditionId: template.value.id,
                  conditionName: template.value.title,
              }
            : {};

    return {
        organisationId: workspaceId,
        ticketIdentity,
        mobileNumber,
        emailAddress,
        isVideoConsult: false,
        patientListId: null,
        attachedDocumentIds,
        enablePatientResponse,
        videoConsultTime: null,
        patientListEntryId: null,
        messageBody,
        messageFooter: messageSignature,
        patientExternalIdentity: {
            patientExternalIds: externalIds,
        },
        messageTemplate,
        ...questionnaireParams,
        // Send null in case sendTime is empty string
        sendTime: sendTime || null,
        demographicsMobileNumber,
        nhsChoicesLink,
        selfBook: selfBookRequest,
    };
};
