import * as helpers from "../components/Question/Question.helpers";
import { ClientSideQuestion } from "../types/context.types";
import { QuestionsPageError } from "../types/data.types";

export const isQuestionOrderValid = (
    questions: ClientSideQuestion[],
): boolean => {
    const allQuestions = questions.map((question, index) => {
        return { question: question, order: index };
    });

    const branchQuestions = allQuestions.filter(
        //filter for populated branch data => branch question
        (questionItem) =>
            questionItem.question.branchData?.condition !== null &&
            questionItem.question.branchData?.condition !== undefined,
    );

    const failures = branchQuestions.filter((branchQuestion) => {
        const conditionQuestionId =
            branchQuestion.question.branchData?.condition.conditionQuestion
                ?.questionId;
        const branchOrder = branchQuestion.order;
        const conditionQuestionOrder = allQuestions
            .filter((question) => question.question.id === conditionQuestionId)
            .map((conditionQuestion) => conditionQuestion.order);

        return branchOrder < conditionQuestionOrder[0];
    });

    return failures.length === 0;
};
enum BranchValidationErrors {
    MissingAnswer = "Missing branch matching answer",
    InvalidQuestion = "Condition question is missing from questionnaire",
    InvalidOption = "Matching answer does not exist in list of options",
}

export const checkBranchConditionValidity = (
    questions: ClientSideQuestion[],
): QuestionsPageError[] => {
    const branchQuestions = questions.filter(
        //filter for populated branch data => branch question
        (question) =>
            question.branchData?.condition !== null &&
            question.branchData?.condition !== undefined,
    );
    const failures: QuestionsPageError[] = [];
    branchQuestions.forEach((branchQuestion) => {
        const conditionQuestionId =
            branchQuestion.branchData?.condition.conditionQuestion?.questionId;
        const conditionAnswer =
            branchQuestion.branchData?.condition.conditionQuestion.answerText;

        if (conditionAnswer === undefined) {
            failures.push({
                id: helpers.generateCardKey(branchQuestion.clientId) ?? "",
                message: BranchValidationErrors.MissingAnswer,
            });
            return;
        }

        const conditionQuestion = questions.find(
            (question) => question.id === conditionQuestionId,
        );
        if (!conditionQuestion) {
            const errorId = helpers.generateCardKey(branchQuestion.clientId);
            failures.push({
                id: `${errorId}-edit` ?? "",
                message: BranchValidationErrors.InvalidQuestion,
            });
            return;
        }

        const isValidConditionAnswer = conditionQuestion.options
            ?.map((option) => option.text)
            .includes(conditionAnswer);

        if (!isValidConditionAnswer) {
            const errorId = helpers.generateCardKey(branchQuestion.clientId);
            failures.push({
                id: `${errorId}-edit` ?? "",
                message: BranchValidationErrors.InvalidOption,
            });
        }
    });
    return failures;
};
