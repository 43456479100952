import { useContext } from "react";

import { StyledIndicator } from "domains/inbox/components/AssigneeSelector/components/AssigneeOnlineIndicator/AssigneeOnlineIndicator.styles";
import { OnlineUsersContext } from "domains/inbox/components/OnlineUsersContext/OnlineUsersContext";

type AssigneeOnlineIndicatorProps = {
    userId: string;
    displayName: string;
};

export const AssigneeOnlineIndicator = ({
    userId,
    displayName,
    ...props
}: AssigneeOnlineIndicatorProps): JSX.Element => {
    const { usersOnline } = useContext(OnlineUsersContext);
    const isUserOnline = usersOnline.has(userId);

    return (
        <span aria-live="polite" {...props}>
            <StyledIndicator
                aria-label={`${displayName} is ${
                    isUserOnline ? "online" : "offline"
                }`}
                $color={isUserOnline ? "green" : "white"}
                role="status"
            />
        </span>
    );
};
