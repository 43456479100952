import { Log } from "@accurx/shared";
import groupBy from "lodash/groupBy";
import moment from "moment";

import { DashboardData } from "../../../types";
import { RequestSource, RequestType } from "../shared/shared.constants";
import { IRequestSource, IRequestType } from "../shared/shared.types";
import { GridCell } from "./RequestsByHour.types";

type GetGridCellDataArgs = {
    dashboardData: DashboardData[];
    requestType: IRequestType;
    requestSource: IRequestSource;
    xAxisLength: number;
    yAxisLength: number;
    startHour?: number;
    endHour?: number;
};

/**
 * This function validates that the data input to the Heatmap is well formed.
 * If there are fewer data points specified than the size of the heatmap grid, all other cells
 * will be initialised to zero.
 *
 * If there are more data points than the heatmap has space for, or any data points are
 * out of bounds, an error will be logged and the graph will not render.
 */
function parseInput(
    data: GridCell[],
    xAxisLength: number,
    yAxisLength: number,
): GridCell[] | null {
    if (data.length >= xAxisLength * yAxisLength) {
        Log.error(`Invalid data for heat map: too many data points`);
        return null;
    }

    const graphData = createGridInitialisedToZero(xAxisLength, yAxisLength);
    data.forEach((cell) => (graphData[cell.xLoc][cell.yLoc] = cell.value));
    return flattenGridToCoordinates(graphData);
}

/**
 * Creates a grid for the heatmap with every cell initialised to zero.
 */
function createGridInitialisedToZero(xAxisLength: number, yAxisLength: number) {
    const graphData: Array<Array<number>> = [];
    for (let idx = 1; idx <= xAxisLength; idx++) {
        graphData.push(new Array(yAxisLength).fill(0));
    }
    return graphData;
}

/**
 * Transforms an array of arrays into the GridCell format, using the indexes of the arrays
 * to specify the x/y coordinates of the data point
 */
function flattenGridToCoordinates(data: number[][]) {
    return data.reduce<GridCell[]>((accumulator, value, xIdx) => {
        const yCol = value.map((value, yIdx) => ({
            xLoc: xIdx,
            yLoc: yIdx,
            value: value,
        }));
        return accumulator.concat(yCol);
    }, []);
}

const aggregateDataForHeatmap = (
    dashboardData: DashboardData[],
    requestType: IRequestType,
    requestSource: IRequestSource,
    startHour = 7,
    endHour = 19,
) => {
    const lastWeek = moment(moment().utc().subtract(1, "weeks")).week();

    const data: GridCell[] = [];

    const filteredData = dashboardData
        .filter(
            (patientInitiatedRequest) =>
                (requestType === RequestType.MedicalAndAdmin ||
                    patientInitiatedRequest.type === requestType) &&
                (requestSource === RequestSource.PatientsAndReception ||
                    (requestSource === RequestSource.Reception
                        ? patientInitiatedRequest.isReception
                        : !patientInitiatedRequest.isReception)),
        )
        .map((dt) => moment(dt.date))
        .filter(
            (m) =>
                m.week() === lastWeek &&
                m.hour() >= startHour &&
                m.hour() <= endHour,
        );

    const groupedByDay = groupBy(filteredData, (m) => m.isoWeekday() - 1);

    for (const dayKey in groupedByDay) {
        const groupedByHour = groupBy(
            groupedByDay[dayKey],
            (m) => 19 - m.hour(),
        );
        for (const hourKey in groupedByHour) {
            data.push({
                xLoc: Number(dayKey),
                yLoc: Number(hourKey),
                value: groupedByHour[hourKey].length,
            });
        }
    }

    return data;
};

/**
 * formats the dashboard requests data into grid cell data, filtering by the request typeand source
 * as well as the hour of the day and validating that the resulting grid fits on the heatmap.
 * @returns an object containing the grid cell data as well as the min and max values across the grid
 */
export const getGridCellData = ({
    dashboardData,
    requestType,
    requestSource,
    xAxisLength,
    yAxisLength,
    startHour,
    endHour,
}: GetGridCellDataArgs): {
    data: GridCell[] | null;
    minCellValue: number;
    maxCellValue: number;
} => {
    const data = parseInput(
        aggregateDataForHeatmap(
            dashboardData,
            requestType,
            requestSource,
            startHour,
            endHour,
        ),
        xAxisLength,
        yAxisLength,
    );
    const cellValues = data?.map((gridCell) => gridCell.value);
    const minCellValue = cellValues ? Math.min(...cellValues) : 0;
    const maxCellValue = cellValues ? Math.max(...cellValues) : 0;

    return { data, minCellValue, maxCellValue };
};

export const getYAxisCategories = (startHour: number, endHour: number) =>
    Array.from({ length: endHour - startHour + 1 }, (_, i) => `${endHour - i}`);
