import concat from "lodash/concat";

import { Days } from "app/practices/patientTriage/types";
import { timeIn30MinuteIncrements } from "app/practices/patientTriage/utils";

const DayNames = Object.keys(Days);

export const getCurrentDay = (day: number | undefined): string =>
    day === undefined ? "Bank holidays" : DayNames[day];

export const getCurrentDaySlug = (day: number | undefined): string =>
    getCurrentDay(day).toLowerCase().replace(" ", "-");

export const times = concat(timeIn30MinuteIncrements, "23:59");

/**
 * Check that timeA is greater than timeB
 * @param timeA
 * @param timeB
 * @returns
 */
export const timeIsAfter = (timeA: string, timeB: string): boolean => {
    return parseInt(timeA.replace(":", "")) > parseInt(timeB.replace(":", ""));
};

export const getDefaultClosingTime = ({
    closingTime,
    preferredDefaultClosingTime,
    validClosingTimes,
}: {
    closingTime: string | null;
    preferredDefaultClosingTime: string;
    validClosingTimes: string[];
}): string => {
    if (!!closingTime && validClosingTimes.includes(closingTime)) {
        return closingTime;
    } else if (validClosingTimes.includes(preferredDefaultClosingTime)) {
        return preferredDefaultClosingTime;
    } else {
        return validClosingTimes[0];
    }
};

export const getValidClosingTimes = (openingTime: string): string[] => {
    return times.filter((time) => timeIsAfter(time, openingTime));
};
