/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import findLast from "lodash/findLast";
import last from "lodash/last";
import {
    BehaviorSubject,
    Observable,
    Unsubscribable,
    combineLatest,
} from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import {
    mergeShallowConversations,
    removeConversations,
    sortConversationDisplaySummaries,
} from "shared/concierge/conversations/ConversationData.utils";
import {
    ConversationFilter,
    ConversationItemFilter,
    buildConversationFilterFromRuleset,
    buildItemFiltersFromRuleset,
} from "shared/concierge/conversations/ConversationFilters";
import {
    Conversation,
    ConversationDisplaySummary,
} from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationGroupApiContinuation,
    ConversationGroupPage,
    ConversationGroupPaginationOptions,
    ConversationGroupRuleset,
} from "shared/concierge/conversations/types/conversationGroup.types";
import {
    PaginatedSubscription,
    Pagination,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";

import { Mutex } from "./Mutex";
import {
    PaginationHandler,
    createPaginationHandler,
} from "./PaginationHandler";
import { safeNext } from "./safeNext";

type LoadingStatus = SubscriptionResult<ConversationDisplaySummary[]>["status"];
type BackgroundLoadingStatus = SubscriptionResult<
    ConversationDisplaySummary[]
>["backgroundStatus"];

/**
 * ConversationGroupSubscription
 *
 * This class is responsible for receiving all conversation changes from the ConversationManager,
 * checking whether the updated conversation state is accepted by the rules that define the group,
 * and then passing the changes for relevant conversation add/remove/updates to the UI.
 */
class ConversationGroupSubscription
    implements PaginatedSubscription<ConversationDisplaySummary>
{
    /**
     * Store the last known version of the conversation list for this
     * conversation group.
     */
    private conversations: ConversationDisplaySummary[] = [];

    /**
     * We keep a list of only the IDs for conversations currently in
     * the group. This helps us performantly check if we already know
     * about a given conversation.
     */
    private readonly trackedConversations = new Set<Conversation["id"]>();

    /**
     * A set of filter functions that get created based on the group ruleset.
     * If a conversation satisfies every filter we know that it falls within
     * this group.
     */
    private readonly accepts: ConversationFilter;

    /**
     * If the provided ruleset is for showing conversations that contain specific
     * items, we want to pick one of those items for display in the summary. Otherwise
     * we're happy with falling back to default behaviour (latest item that's useful
     * for display).
     */
    private readonly selectDisplayItem: ConversationItemFilter | undefined;

    private readonly sortConversations: (
        _: ConversationDisplaySummary[],
    ) => ConversationDisplaySummary[];

    /**
     * Setup the RxJS subjects that consumers will receive updates
     * from. We don't expose them directly because we don't want consumers
     * to be able to push updates into the subjects, and we want consumers
     * to only receive a combination of this data.
     * We use BehaviourSubjects as these emit on subscription with the initial
     * value passed here. This is important as we use combineLatest to combine them,
     * and combineLatest will only tick once all combined observables have ticked
     * (which makes sense as otherwise there is no value to combine).
     */
    /**
     * Feed of conversations that will flow to front end
     */
    private conversationUpdateFeed = new BehaviorSubject<
        ConversationDisplaySummary[]
    >([]);

    /**
     * Loading status of the initial load of conversations
     */
    private initialLoadingStatus = new BehaviorSubject<LoadingStatus>(
        "LOADING",
    );

    /**
     * Loading status of fetch for further API pages of conversations
     * We return this to the UI only if we are viewing the last page of the existing data
     */
    private nextApiPageLoadingStatus =
        new BehaviorSubject<BackgroundLoadingStatus>(undefined);

    /**
     * It's important that we unsubscribe to any observables when
     * the subscription has been torn down. Create a store for any
     * observables we need so that we can unsubscribe to them in
     * bulk.
     */
    private readonly subscriptionHandles: Unsubscribable[] = [];

    /**
     * Stores the continuation token used to tell the API which conversations
     * to fetch when using pagination, and the date/time of the last conversation
     * in the group already loaded so we that we know when to call the API again
     * If null, then there we have already fetched all the conversations in the group
     */
    private apiContinuation?: ConversationGroupApiContinuation;

    /**
     * Used so we only have ony call to fetch more data from the API in flight at once
     */
    private apiLock: Mutex = new Mutex();

    /**
     * Contains all logic to handle UI pagination
     */
    private paginationHandler: PaginationHandler<ConversationDisplaySummary>;

    /**
     * When initialised we start listening for updates to the conversation
     * in question but we don't start broadcasting events until the parent
     * has called `subscription.start()` and the conversation has been
     * fetched.
     *
     * @param ruleset - Defines which conversations fit within the group
     * @param onUnsubscribe - A callback that will get called when this subscription is finished
     * @param fetchData - A callback that should perform the initial conversations list fetch
     * @param conversationsFeed - An observable of updates to all conversations
     * @param paginationOptions - Options that impact pagination, including page size and sort order
     */
    constructor(
        private readonly ruleset: ConversationGroupRuleset,
        private onUnsubscribe: () => void,
        private readonly fetchData: (
            apiContinuation: string | undefined,
        ) => Promise<Pick<ConversationGroupPage, "apiContinuation">>,
        conversationsFeed: Observable<Conversation[]>,
        private readonly paginationOptions: ConversationGroupPaginationOptions,
    ) {
        this.retryFetch = this.retryFetch.bind(this);
        this.accepts = buildConversationFilterFromRuleset(ruleset);
        this.selectDisplayItem = buildItemFiltersFromRuleset(ruleset);
        this.listenToConversationFeed(conversationsFeed);
        this.sortConversations = (c) =>
            sortConversationDisplaySummaries(c, this.paginationOptions);
        this.paginationHandler = createPaginationHandler(
            this.paginationOptions.pageSize,
            this.conversationUpdateFeed,
            this.sortConversations,
            this.checkAndFetchNextApiPage.bind(this),
        );
    }

    /**
     * Feed of subscription results that allows the consumer to subscribe to changes.
     */
    get feed(): Observable<SubscriptionResult<ConversationDisplaySummary[]>> {
        return combineLatest([
            this.conversationUpdateFeed,
            this.initialLoadingStatus.pipe(distinctUntilChanged()),
            this.nextApiPageLoadingStatus.pipe(distinctUntilChanged()),
        ]).pipe(
            // merge in api loading status
            map(([latestConversations, status, nextApiPageLoadingStatus]) =>
                this.mergeLoadingStatus(
                    latestConversations,
                    status,
                    nextApiPageLoadingStatus,
                ),
            ),
        );
    }

    /**
     * Starts up the subscription. This requests the initial set of data and then begins the broadcasting process.
     */
    public async start(): Promise<void> {
        await this.fetchDataWithLock(true, undefined);
    }

    /**
     * Unsubscribe
     * - teardown all observables we've set up internally,
     * - stop broadcasting events
     * - alert parents so they can dispose of this subscription
     */
    public teardown(): void {
        this.onUnsubscribe();
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
        this.conversationUpdateFeed.unsubscribe();
        this.initialLoadingStatus.unsubscribe();
        this.nextApiPageLoadingStatus.unsubscribe();
    }

    public get pagination(): Pagination {
        return {
            next: this.paginationHandler.hasNextPage
                ? () => this.paginationHandler.onNextPage(this.conversations)
                : undefined,
            previous: this.paginationHandler.hasPreviousPage
                ? () =>
                      this.paginationHandler.onPreviousPage(this.conversations)
                : undefined,
        };
    }

    // Gives the ability for the UI to manually request to fetch more data if automatic data fetching failed
    public retryFetch(): Promise<void> {
        // If initial load was an error, we are retrying the initial load
        const isInitialLoad = this.initialLoadingStatus.value === "ERROR";
        // this.apiContinuation should be undefined if initial load is in error, but be explicit about this for safety
        const continuationToken = isInitialLoad
            ? undefined
            : this.apiContinuation?.continuationToken;
        return this.fetchDataWithLock(isInitialLoad, continuationToken);
    }

    /**
     * Starts listening to updates to the conversation updates feed.
     * The pipe operates like this:
     * - Receive an event containing a bulk list of updates
     * - Calculate how those updates effect this group
     * - Convert the update into a success state object
     * - Only broadcast the event if we're ready
     */
    private listenToConversationFeed(
        conversationsFeed: Observable<Conversation[]>,
    ): void {
        const handle = conversationsFeed
            .pipe(
                map((conversations) => this.processUpdate(conversations)),
                map((conversations) =>
                    this.paginationHandler.updateAndGetCurrentPage(
                        conversations,
                    ),
                ),
            )
            .subscribe(this.conversationUpdateFeed);

        this.subscriptionHandles.push(handle);
    }

    /**
     * Given a set of changes to the list of all conversations this is where
     * we figure out how those updates affect this group.
     * - Adds newly created conversations that fall within this group
     * - Adds conversations that have changed so that they only now fall within this group
     * - Updates any conversations that have changed while still falling within this group
     * - Removes any conversations that have changed so that they no longer fall within this group
     */
    private processUpdate(
        updates: Iterable<Conversation>,
    ): ConversationDisplaySummary[] {
        const addedOrUpdated = new Array<ConversationDisplaySummary>();
        const removed = new Array<ConversationDisplaySummary>();

        for (const conversation of updates) {
            const accepted = this.accepts(conversation);
            if (accepted) {
                addedOrUpdated.push(this.mapToDisplaySummary(conversation));
                this.trackedConversations.add(conversation.id);
            } else if (this.trackedConversations.has(conversation.id)) {
                removed.push(this.mapToDisplaySummary(conversation));
                this.trackedConversations.delete(conversation.id);
            }
        }

        if (addedOrUpdated.length > 0) {
            this.conversations = this.sortConversations(
                mergeShallowConversations(this.conversations, addedOrUpdated),
            );
        }

        if (removed.length > 0) {
            this.conversations = removeConversations(
                this.conversations,
                removed,
            );
        }

        return this.conversations;
    }

    private checkAndFetchNextApiPage(
        futureConversations: ConversationDisplaySummary[],
    ): void {
        const apiContinuation = this.apiContinuation;
        if (!apiContinuation) {
            // No API continuation token so there is no more data, and we can return
            return;
        }
        // We do an optimistic fetch of the next page so the user doesn't
        // have to wait (or wait less) if they click next
        const twoPagesSize =
            optimisticPageAmount * this.paginationOptions.pageSize;
        if (
            areConversationsStale(
                futureConversations,
                twoPagesSize,
                apiContinuation,
            )
        ) {
            // We know we don't have data for two pages, so we will do a fetch
            this.fetchNextApiPage();
        }
    }

    private fetchNextApiPage(): void {
        this.fetchDataWithLock(false, this.apiContinuation?.continuationToken);
    }

    private async fetchDataWithLock(
        isInitialLoad: boolean,
        apiContinuationToken?: string,
    ): Promise<void> {
        const updateStatusFeeds = (status: "SUCCESS" | "LOADING" | "ERROR") => {
            isInitialLoad
                ? safeNext(this.initialLoadingStatus, status)
                : safeNext(this.nextApiPageLoadingStatus, status);
        };

        // Lock so we only have one request in flight at a time, otherwise
        // we risk updating the continuation token with an older token if a
        // later requests "overtakes" an earlier one.
        if (!this.apiLock.isLocked()) {
            updateStatusFeeds("LOADING");

            // Value of string is not meaningful, other than something to re-use between the lock and release
            const lock = "fetch-conversation-group-next-api-31414";
            this.apiLock.lock(lock);
            return this.fetchData(apiContinuationToken)
                .then((cg) => {
                    this.apiContinuation = cg.apiContinuation;

                    // Note: there is a window condition here where this may be emitted
                    // before the conversations are emitted from the conversation manager
                    // by the fetch. In which case we will return a success event with no
                    // data to the UI, but that should happen only milliseconds before the
                    // success event with data is emitted
                    updateStatusFeeds("SUCCESS");
                })
                .catch((e: Error) => {
                    updateStatusFeeds("ERROR");
                })
                .finally(() => {
                    this.apiLock.release(lock);
                });
        }
    }

    private mergeLoadingStatus = (
        latestConversations: ConversationDisplaySummary[],
        status: LoadingStatus,
        nextApiPageLoadingStatus: BackgroundLoadingStatus,
    ): SubscriptionResult<ConversationDisplaySummary[]> => {
        switch (status) {
            case "LOADING":
                return {
                    status: status,
                    data: null,
                    errorMessage: null,
                };
            case "SUCCESS":
                // If there are conversations to show, and there are potentially more to fetch
                // via the API (i.e. we have an api continuation token), we check to see if the
                // conversations to be shown are stale
                const isCurrentPageStale =
                    latestConversations.length > 0 &&
                    this.apiContinuation &&
                    areConversationsStale(
                        latestConversations,
                        this.paginationOptions.pageSize,
                        this.apiContinuation,
                    );
                return {
                    status: status,
                    data: latestConversations,
                    errorMessage: null,
                    // We only care about background loading and errors if data for current page is stale
                    backgroundStatus: isCurrentPageStale
                        ? nextApiPageLoadingStatus
                        : undefined,
                };
            case "ERROR":
            default:
                return {
                    status: "ERROR",
                    data: null,
                    errorMessage: "No conversations have been loaded",
                };
        }
    };

    // default mapping for viewing assigned conversations
    private mapToDisplaySummary(
        conversation: Conversation,
    ): ConversationDisplaySummary {
        // If the subscription is tracking specific items, ensure we pick that one.
        // Otherwise fallback below.
        const latestMatchedItem = this.selectDisplayItem
            ? findLast(conversation.items, this.selectDisplayItem)
            : undefined;

        // Ignore any state change items, as these shouldn't be the display
        // Fallback to using them as the display if it is the only item.
        // This may happen if a live update comes in for a conversation not
        // yet loaded, on general load this shouldn't happen as server doesn't return
        // state change events as the latest item (and can't be the first item)
        const displayItem =
            latestMatchedItem ??
            findLast(
                conversation.items,
                (item) => item.contentType !== "StateChange",
            ) ??
            last(conversation.items)!;
        return {
            assignee: conversation.assignee,
            id: conversation.id,
            isUrgent: conversation.isUrgent,
            lastUpdated: conversation.lastUpdated,
            latestToken: conversation.latestToken,
            regardingPatientId: conversation.regardingPatientId,
            status: conversation.status,
            unreadCount: conversation.unreadCount,
            epochVersion: conversation.epochVersion,
            isStale: conversation.isStale,
            displayItem,
        };
    }
}

/**
 * Creates a new conversation group subscription.
 *
 * We prefer to use a factory function to create subscription instances over
 * directly using the class because it's easier to mock functions than
 * classes in tests.
 */
export const createConversationGroupSubscription = (
    ...args: ConstructorParameters<typeof ConversationGroupSubscription>
): PaginatedSubscription<ConversationDisplaySummary> => {
    return new ConversationGroupSubscription(...args);
};

/**
 * The number of pages to ensure are loaded ahead of time, when paginating through conversation groups
 * Increasing this number will lead to more API calls potentially unnecessary but increase the odds
 * of a user never seeing a loading state when going through pages.
 */
const optimisticPageAmount = 2;

/**
 * Calculates whether the conversations are stale, in that there is not enough valid conversations
 * to provide the next N conversations to the UI.
 * @nextConversations ordered list of all upcoming conversations to check
 * @numberConversationsToCheck the number of valid conversations we want within the next conversations
 * @apiContinuation token used to determine whether. Note this assumes that we always want to order
 * conversations in reverse chronological order. FOU-114 covers making this more generic
 */
const areConversationsStale = (
    nextConversations: ConversationDisplaySummary[],
    numberConversationsToCheck: number,
    apiContinuation: ConversationGroupApiContinuation,
): boolean => {
    if (nextConversations.length < numberConversationsToCheck) {
        // We definitely don't have enough conversations, so the data must be stale
        return true;
    }

    const conversationsToCheck = nextConversations.slice(
        0,
        numberConversationsToCheck,
    );

    const latestConversation =
        conversationsToCheck[numberConversationsToCheck - 1];
    if (
        latestConversation &&
        latestConversation.lastUpdated <
            apiContinuation.fetchNewConversationsCutoff
    ) {
        // Whatever is in the store of next conversations goes back further in time
        // than the data from the latest API fetch. So there may be missing conversations
        // unless we trigger a new api fetch, so the data is stale
        return true;
    }
    return false;
};
