import {
    MessagePatientRequestWithExternalIdentity,
    MessagePatientRequestWithToken,
    MessagePatientResponse,
} from "@accurx/api/patient-messaging";
import { Log, getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

const ENDPOINTS = {
    messagePatientWithId: "/api/patientmessaging/messagepatientwithid",
    messagePatientWithToken: "/api/patientmessaging/messagepatientwithtoken",
} as const;

/**
 * Send a message as part of an existing conversation
 * or create a new conversation
 * when we have a patient token as a result of a patient search.
 *
 * User must have previously searched for the patient in order to be able to use this endpoint
 *
 * i.e. we use this endpoint when we message a patient from the patient
 * conversations, after having searched for a patient
 */
export const messagePatientWithToken = async (
    request: MessagePatientRequestWithToken,
): Promise<MessagePatientResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messagePatientWithToken,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as MessagePatientResponse;
};

/**
 * Send a message as part of an existing conversation
 * or create a new conversation
 * when we don't have a patient token as a result of a patient search.
 *
 * User must be 2FAed in, in order to be able to successfully use this endpoint
 *
 * i.e. we use this endpoint when we message a patient from the shared inbox
 * (where patient search doesn't happen)
 */
export const messagePatientWithExternalIdentity = async (
    request: MessagePatientRequestWithExternalIdentity,
): Promise<MessagePatientResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.messagePatientWithId,
        }),
        request,
    );

    if (!response.success || isNil(response.result)) {
        // A response.error would alway be present if a error was returned from the API. However if there is any sort of network connection error, response.error would be null.
        if (response.error) {
            throw new Error(response.error);
        }

        Log.warn("Network connection error!");
    }

    return response.result as MessagePatientResponse;
};
