import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledBannerSection = styled.div`
    padding: ${Tokens.SIZES[2]} 0;
`;

export const StyledIconWrapper = styled.span`
    margin-right: ${Tokens.SIZES[2]};
`;
