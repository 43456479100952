import { Button } from "@accurx/design";

import { useFloreyBuilder } from "../../FloreyBuilder.context";
import { Actions } from "../../constants";
import { BranchPosition } from "../../types/data.types";

type BranchTypes = {
    branchPosition: BranchPosition;
};

export const AddQuestionToBranchButton = ({
    branchPosition,
}: BranchTypes): JSX.Element => {
    const { dispatch } = useFloreyBuilder();
    const handleAddQuestionToBranch = (): void => {
        dispatch({
            type: Actions.ADD_QUESTION_SELECTOR,
            payload: {
                questionLevel: "Branch",
                branchPosition,
            },
        });
    };
    return (
        <Button
            text="Add question to branch"
            onClick={handleAddQuestionToBranch}
            theme="secondary"
            icon={{ name: "Plus" }}
        />
    );
};
