import { ChangeEvent, ClipboardEvent } from "react";

import { Input } from "@accurx/design";

import { sanitiseNhsNumber } from "../Vaccine.helper";

export interface VaccineAllPatientsInvitedSearchProps {
    searchTerm: string;
    updateSearchTerm: (
        e?: ChangeEvent<HTMLInputElement>,
        text?: string,
    ) => void;
}

export const VaccineAllPatientsInvitedSearch = ({
    searchTerm,
    updateSearchTerm,
}: VaccineAllPatientsInvitedSearchProps) => {
    const handleOnPaste = (e: ClipboardEvent): void => {
        e.preventDefault();
        const text = e.clipboardData?.getData("Text");
        updateSearchTerm(undefined, sanitiseNhsNumber(text));
    };

    return (
        <div className="mb-2">
            <Input
                type="text"
                placeholder="Search patients by name, NHS number, or mobile"
                className="search-input text-truncate"
                value={searchTerm}
                onChange={updateSearchTerm}
                onPaste={handleOnPaste}
                data-testid="searchInput"
                isSearchInput
                autoFocus
            />
        </div>
    );
};
