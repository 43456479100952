export type PatientRecordViewRequest = {
    patientToken: string;
    organisationId: number | null;
    isGpOwnPatient: boolean;
};

export type PatientRecordViewResponse = {
    success: boolean;
    requestId: string | null;
    error: string | null;
};

export type PatientRecordViewAuthenticationRequest = {
    AccessCode: string | null;
    RequestId: string | null;
    OrganisationId: number | null;
};

export type PatientRecordViewGetRecordRequest = {
    RequestId: string | null;
    OrganisationId: number | null;
};

export type GetAllRecordsRequest = {
    OrganisationId: number | null;
};

export type AllRecordViewRequestsRequest = {
    recordAccessRequests: Array<RecordViewRequestResult>;
};

export type LatestPatientRecordRequest = {
    organisationId: number | null;
    nhsNumber: string;
};

export type LatestPatientRecordResponse = {
    record: RecordViewRequestResult | null;
};

export enum RecordViewResultStatus {
    RecordAvailable = "RecordAvailable",
    TwofaCodeRequired = "TwofaCodeRequired",
    AuthorisationFailed = "AuthorisationFailed",
    Requesting = "Requesting",
    Expired = "Expired",
    RequestFailed = "RequestFailed",
}

export type RecordViewRequestResult = {
    nhsNumber: string;
    requestId: string;
    requestedTime: string | null;
    status: RecordViewResultStatus;
    accessRemainingHours: number;
};

export type RecordViewRequest = {
    result: RecordViewResult;
};

export type RecordViewResult = {
    medicalRecord: MedicalRecordResult | null;
    medicalRecordFormat: "EmisXml" | "SystmOneXml" | null;
    status: string;
    isPatientAuthorised: boolean | null;
    maxAccessAttemptsExceeded: boolean | null;
    requestedTime: string | null;
};

export type MedicalRecordResult = {
    patient: PatientDetails;
    gp: GPDetails;
    activeProblems: Array<Problem>;
    pastProblems: Array<Problem>;
    healthAdminProblems: Array<Problem>;
    potentialProblems: Array<Problem>;
    otherProblems: Array<Problem>;
    allergies: Array<Allergy>;
    currentMedications: Array<Medication>;
    pastMedications: Array<Medication>;
    immunisations: Array<Immunisation>;
    investigations: Array<Investigation> | null;
    healthStatus: Array<HealthStatus>;
    //Don't show consultations tab if null returned from the server for consultations
    gpConsultations: Array<GpConsultation> | null;
};

export type PatientDetails = {
    name: string | null;
    address: string | null;
    homeTelephone: string | null;
    workTelephone: string | null;
    mobileTelephone: string | null;
    gender: string | null;
    nhsNumber: string | null;
    dateOfBirth: string | null;
};

export type GPDetails = {
    usualGpName: string;
    practiceName: string;
    practiceAddress: string;
    practiceTelephone: string;
};

export enum ProblemListSeverity {
    Significant = "Significant",
    Minor = "Minor",
}

export type Problem = {
    date: string | null; // ISO date time
    description: string | null;
    severity: ProblemListSeverity | null;
    freeText: string | null;
};

export type Allergy = {
    date: string | null;
    description: string | null;
    freeText: string | null;
    isNoKnownAllergy: boolean | null;
    endDate: string | null;
};

export enum MedicationType {
    Acute = "Acute",
    Repeat = "Repeat",
}

export type Medication = {
    date: string | null;
    name: string | null;
    dosage: string | null;
    durationDays: number | null;
    quantity: string | null;
    issueNumber: string | null;
    lastIssueDate: string | null;
    expiryDate: string | null;
    type: MedicationType | null;
};

export type Immunisation = {
    date: string | null;
    name: string | null;
};

export type Investigation = {
    name: string | null;
    date: string | null;
    resultValue: number | null;
    resultUnits: string | null;
    stringValue: string | null;
    subInvestigations: Array<Omit<Investigation, "subInvestigations">> | null;
    minimumResultValue: number | null;
    maximumResultValue: number | null;
    comments: string | null;
};

export type HealthStatus = {
    name: string | null;
    value: string | null;
    lastUpdated: string | null;
};

export type GpConsultation = {
    date: string | null;
    clinicianName: string | null;
    consultationItems: Array<string> | null;
};

export type Event = {
    date: string;
    result: string;
};
