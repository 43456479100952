import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${Tokens.SIZES[4]};
    height: ${Tokens.SIZES[4]};
    min-width: ${Tokens.SIZES[4]};
    min-height: ${Tokens.SIZES[4]};

    background-color: ${Tokens.COLOURS.greyscale.dishwater};
    border-radius: ${Tokens.BORDERS.radius};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledPatientPill = styled(Flex).attrs({
    gap: "1",
    alignItems: "center",
})`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.SIZES[0.5]};
    width: fit-content;
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]};

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        flex: 1;
    }
`;

export const StyledDetailsSection = styled.div`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.SIZES[0.5]};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    flex: 1;
`;

export const StyledPageWrapper = styled(Flex).attrs({
    flexDirection: "column",
    gap: "3",
})`
    flex: 1;
    padding: ${Tokens.SIZES[3]};
    background: white;
`;

export const StyledRecipientDetails = styled(Flex).attrs({
    flexDirection: "column",
})`
    gap: ${Tokens.SIZES[3]};
    align-items: center;
`;

export const StyledRecipientDetailsItem = styled(Flex).attrs({
    alignItems: "center",
})`
    width: 100%;
`;

export const StyledSeeDetailsButton = styled.button`
    ${Tokens.TYPOGRAPHY["body"].base}
    ${Tokens.TYPOGRAPHY["body"].desktop}
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    color: ${Tokens.COLOURS.primary.blue[130]};
    &:hover {
        color: ${Tokens.COLOURS.greyscale.night};
    }
`;

export const StyledPatientDetailsWrapper = styled(Flex).attrs({
    flexDirection: "column",
    gap: "2",
})`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    overflow: auto;
`;
