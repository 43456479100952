import * as UI from "@accurx/design";
import styled from "styled-components";

const { COLOURS } = UI.Tokens;

export const StyledUneditableText = styled(UI.Text).attrs({
    variant: "preview",
    skinny: true,
})`
    color: ${COLOURS.greyscale.stone};
`;
