import { useAuth } from "@accurx/auth";
import { useRouteMatch } from "react-router";

/**
 * Because we need to access current workspace data outside routes (e.g. navigation),
 * this hook can be used near the top of the component tree to checks whether the
 * URL's workspace ID is valid, so we can then safely use the `useCurrentWorkspace`
 * hook, as well as other hooks dependent on it.
 *
 * For routes that don't include a workspace ID param, we return `true` and let
 * the rest of the application handle any other route guarding or redirects.
 */
export const useWorkspaceValidator = (): boolean => {
    const { user } = useAuth();

    const params = useRouteMatch<{ workspaceId?: string; orgId?: string }>({
        path: [
            "*/w/:workspaceId",
            "*/organisations/:orgId",
            "*/practices/:orgId",
        ],
        strict: false,
        sensitive: false,
    })?.params;

    // Feels weird to return `true` when logged out, but until we decouple
    // logged out routes, we need to load the rest of the component tree to
    // run auth checks and perform any redirects
    if (!user.isLoggedIn) {
        return true;
    }

    // Treat generic routes without a workspace ID param as valid
    if (!params?.workspaceId && !params?.orgId) {
        return true;
    }

    return user.organisations.some(
        ({ orgId }) => orgId === Number(params.workspaceId || params.orgId),
    );
};
