import * as UI from "@accurx/design";
import {
    Level2AccordionHeaderHeight,
    applyFocusStyle,
    applyHoverStyle,
} from "@accurx/navigation";
import styled from "styled-components";

const { COLOURS, SIZES } = UI.Tokens;

export const SearchButton = styled.button`
    appearance: none;
    padding: ${SIZES[1]};
    margin: 0;
    width: 100%;
    background: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    height: calc(${Level2AccordionHeaderHeight} - 8px);

    font-size: 1rem;
    text-decoration: none !important;
    color: ${COLOURS.greyscale.white} !important;

    &:hover {
        ${applyHoverStyle}
    }

    &:focus {
        ${applyFocusStyle}
    }
`;
