import { Log } from "@accurx/shared/dist";
import { Subscription, timer } from "rxjs";

import FlemingApi from "api/FlemingApiClient";
import { PatientVideoProgressResponse } from "api/FlemingDtos";

// ACTION TYPES
export const CHECK_PROGRESS_STARTED = "CHECK_PROGRESS_STARTED";
export const CHECK_PROGRESS_RESPONSE = "CHECK_PROGRESS_RESPONSE";
export const CHECK_PROGRESS_FINISHED = "CHECK_PROGRESS_FINISHED";
export const RESET_SUBSCRIPTIONS = "RESET_SUBSCRIPTIONS";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface CheckProgressStartedAction {
    type: typeof CHECK_PROGRESS_STARTED;
}

interface CheckProgressResponseAction {
    type: typeof CHECK_PROGRESS_RESPONSE;
    consultationId: string;
    response: PatientVideoProgressResponse | null;
}

interface CheckProgressFinishedAction {
    type: typeof CHECK_PROGRESS_FINISHED;
}

interface ResetSubscriptionsAction {
    type: typeof RESET_SUBSCRIPTIONS;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | CheckProgressStartedAction
    | CheckProgressResponseAction
    | CheckProgressFinishedAction
    | ResetSubscriptionsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

interface SubscriptionPair {
    consultationId: string;
    subscription: Subscription;
}

let patientProgressSubscriptions: SubscriptionPair[] = [];

const cancelCheckPatientProgress = (consultationId: string | null): void => {
    if (consultationId === null) {
        return;
    }

    const index = patientProgressSubscriptions.findIndex(
        (x) => x.consultationId === consultationId,
    );
    if (index === -1) {
        return;
    }
    const subscription = patientProgressSubscriptions[index].subscription;
    subscription.unsubscribe();
    patientProgressSubscriptions.splice(index, 1);
};

export const actionCreators = {
    checkPatientProgress:
        (consultationId: string | null): AppThunkAction<KnownAction> =>
        (dispatch) => {
            if (consultationId === null) {
                Log.info(
                    "[checkPatientProgress] Could not check progress, consultationId is null.",
                );
                return;
            }

            const doesNotExist = patientProgressSubscriptions.findIndex(
                (x) => x.consultationId === consultationId,
            );
            if (doesNotExist !== -1) {
                Log.info(
                    "[checkPatientProgress] Subscription already exists, will not create a new one.",
                );
                return;
            }

            dispatch({
                type: CHECK_PROGRESS_STARTED,
            });

            const periodMs = 10000;
            const throttlePeriodMs = 10 * 1000;
            const allowedFailures = 3;
            let failureCount = 0;
            let delayMs = 0;

            const subscription = timer(0, periodMs).subscribe(async () => {
                if (delayMs > 0) {
                    Log.info("Throttling.");
                    delayMs -= periodMs;
                    return;
                }

                if (failureCount > allowedFailures) {
                    Log.info(
                        `Checking patient progress failed more than ${allowedFailures} times, cancelling check.`,
                    );

                    cancelCheckPatientProgress(consultationId);

                    dispatch({
                        type: CHECK_PROGRESS_FINISHED,
                    });

                    return;
                }

                const result = await FlemingApi.checkPatientProgress(
                    consultationId,
                );
                if (result === null) {
                    failureCount += 1;
                    delayMs += throttlePeriodMs;
                }

                dispatch({
                    type: CHECK_PROGRESS_RESPONSE,
                    consultationId: consultationId,
                    response: result,
                });
            });

            patientProgressSubscriptions.push({
                consultationId: consultationId,
                subscription: subscription,
            });
        },

    cancelCheckPatientProgress: (consultationId: string | null) => {
        cancelCheckPatientProgress(consultationId);

        return {
            type: CHECK_PROGRESS_FINISHED,
        };
    },

    resetSubscriptions: () => {
        patientProgressSubscriptions.forEach((x) =>
            x.subscription.unsubscribe(),
        );
        patientProgressSubscriptions = [];

        return {
            type: RESET_SUBSCRIPTIONS,
        };
    },
};
