import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useSnomedCodesQuery } from "domains/compose/hooks/useSnomedCodesQueryNew";

import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";

export const SnomedCode = ({
    term,
    id,
}: {
    term: string | undefined;
    id: string;
}) => {
    // If we do not have the term at hand, we try to fetch
    // it and backfill it asynchronously
    const { data: backfilledTerm, isFetching } = useSnomedCodesQuery<
        string | undefined
    >({
        select: (data) => {
            const code = data.find((code) => code.conceptId === id);
            return code?.term ?? undefined;
        },
        enabled: term === undefined,
    });

    // In case we don't have the backfilledTerm after fetching
    // at least we can display a fallback name
    const fallbackName = !isFetching ? id : null;

    const snomedCodeDescription = term ?? backfilledTerm;

    const snomedCodeDisplay = snomedCodeDescription
        ? `${snomedCodeDescription} - ${id}`
        : fallbackName;

    return (
        <StyledActionStack.Row>
            <UI.Flex gap="1" alignItems="center">
                <BodyInfoBox.Icon name="Tag" />
                <UI.Flex gap="1" alignItems="center">
                    <BodyInfoBox.Text colour="night">
                        SNOMED code: {snomedCodeDisplay}
                    </BodyInfoBox.Text>
                    {!term && isFetching && (
                        <SkeletonLoader
                            width="150px"
                            backgroundColor={UI.Tokens.COLOURS.greyscale.silver}
                        />
                    )}
                </UI.Flex>
            </UI.Flex>
        </StyledActionStack.Row>
    );
};
