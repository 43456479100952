import { useConciergeDispatch } from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { useMutation } from "domains/concierge/internal/util/useMutation";
import { PatientSummary } from "domains/concierge/types";

export const useUpdatePatientDemographicsMutation = () => {
    const dispatch = useConciergeDispatch();

    return useMutation(
        "UpdatePatientDemographics",
        //  - this is not an async function but `useMutation` expects functions to be async
        // eslint-disable-next-line @typescript-eslint/require-await
        async (patient: PatientSummary) => {
            const time = new Date().toISOString();
            dispatch(
                actions.patients.demographicsUpdates({
                    patient: {
                        ...patient,
                        demographicsLastRefreshed: time,
                    },
                    time,
                }),
            );
        },
    );
};
