import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { SettingsSecurity } from "../../practices/userSettings/SettingsSecurity";
import { AccountPageLayout } from "./AccountPageLayout";

const Security = () => {
    useAccurxWebTitle("Update security settings");
    return (
        <AccountPageLayout title={"Security"}>
            <SettingsSecurity hideHeader isChain={false} />
        </AccountPageLayout>
    );
};
export { Security };
