export const getClinicianNamesDisplay = (clinicianNames: string[]) => {
    if (clinicianNames.length === 1) {
        return clinicianNames[0];
    }

    if (clinicianNames.length === 2) {
        return `${clinicianNames[0]} or ${clinicianNames[1]}`;
    }

    if (clinicianNames.length > 2) {
        return `${clinicianNames[0]} or others to select from`;
    }

    return "any clinician";
};
