import { FeatureName } from "@accurx/auth";
import { getNextUrlOrDefault } from "@accurx/navigation";
import { ParseOptions, ParsedQuery, parse } from "query-string";
import { generatePath, matchPath } from "react-router";

import { IOrganisation } from "api/FlemingDtos";

import { OrganisationHelper } from "./OrganisationHelper";
import { ROUTES, ROUTES_BASE, ROUTES_EXTENSION } from "./Routes";

type BaseRoute = (typeof ROUTES_BASE)[keyof typeof ROUTES_BASE];

export const NEXT_URL_QUERY_PARAM = "next";

// Check if the URL is part of legacy chain MERGE_CHAIN_FLEMING
// TODO when migration from /practices to /organisations is complete
// we can remove the second check from this function
export const isChainPathname = (pathname: string): boolean =>
    pathname.toLowerCase().includes("/practices") ||
    pathname.toLowerCase().includes("/organisations");

export const getTwoFactorRouteWithNext = (next: string): string => {
    return `${
        ROUTES.two_factor_auth
    }?${NEXT_URL_QUERY_PARAM}=${encodeURIComponent(next)}`;
};

export const getSinglePatientListRoute = (patientListId: string): string => {
    return ROUTES_BASE.patientsListsId.replace(":patientListId", patientListId);
};

export const findBaseRoute = (pathname: string): BaseRoute => {
    const allRoutes = Object.values(ROUTES_BASE);

    const patientsListRegex = new RegExp(/\/patients\/lists\/\d+/gm);

    const patientsListRoute = pathname.match(
        patientsListRegex,
    )?.[0] as BaseRoute;

    if (patientsListRoute) return patientsListRoute;

    const foundRoute = allRoutes.find((route) => pathname.startsWith(route));

    if (!foundRoute) return ROUTES_BASE.patientsSearch; // Default to /patients/search

    return foundRoute;
};

type AllParseOptions = {
    parseBooleans: true;
    parseNumbers: true;
} & ParseOptions;

/**
 * Copy of query-string parse method
 * with options we established for the structure of our urls
 *
 * @param query search params
 * @param options options that query-string offers
 * @returns the parsed query
 */
export const appParse = (
    query: string,
    options?: AllParseOptions,
): ParsedQuery<string | number | boolean> => {
    return parse(query, {
        ...options,
        // we use comma syntax for arrays in query strings, hence why we're only
        // checking for the presence of arrays in that format
        arrayFormat: "comma",
    });
};

/**
 * Parses a url or search params and tries to find the
 * string query param we are looking for
 *
 * @param query search params
 * @param queryParam the param we are trying to extract from the query
 * @param options options that query-string offers
 * @returns the query in string format or null if the string query is not found
 */
export const getStringQuery = (
    query: string,
    queryParam: string,
    options?: AllParseOptions,
): string | null => {
    const parsed = appParse(query, options)[queryParam];

    if (typeof parsed === "string" || typeof parsed === "number") {
        return parsed.toString();
    }

    return null;
};

export const getNextUrlOrHomepage = (
    search: string,
    keepExistingSearchParams = true,
): { pathname: string; search: string } =>
    getNextUrlOrDefault(search, ROUTES.home, keepExistingSearchParams); // Default to the home page

export const nextUrlMatchesRoute = (
    search: string,
    route: (typeof ROUTES)[keyof typeof ROUTES],
): boolean =>
    matchPath(getNextUrlOrDefault(search, "/", false).pathname, {
        path: route,
        exact: true,
        strict: true,
    }) !== null;

export const getMessagePracticeUrl = (
    selectedOrganisation: IOrganisation | null,
) => {
    const navigateToMessageGPForm = OrganisationHelper.isFeatureEnabled(
        selectedOrganisation ?? null,
        FeatureName.AccuMailDisableMessageGPConversation,
    );
    return navigateToMessageGPForm
        ? ROUTES_EXTENSION.messagePractice
        : generatePath(ROUTES.message_gp);
};
