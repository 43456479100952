import {
    useAnalytics,
    useMeaningfulActionAnalyticsProps,
} from "@accurx/analytics";
import { Pill } from "@accurx/inbox-design-library";
import { useCompose } from "domains/compose/context";
import { isTestPatient } from "domains/compose/utils/isTestPatient";
import { validateClinicianMessage } from "domains/compose/utils/validateClinicianMessage";

import { ComposeProps } from "../../ClinicianCompose";
import { OnMessageSendFn } from "../../types/ClinicianCompose.types";

export type ClinicianSendMessageProps = Pick<
    ComposeProps,
    "conversationId" | "patient" | "countParticipant"
> & {
    onMessageSend: OnMessageSendFn;
    isLoading: boolean;
};

export const ClinicianSendMessage = ({
    conversationId,
    patient,
    onMessageSend,
    isLoading,
    countParticipant,
}: ClinicianSendMessageProps) => {
    const { state, dispatch } = useCompose();
    const track = useAnalytics();
    const { isMultiFactorAuthActive, isMultiFactorAuthEnabled } =
        useMeaningfulActionAnalyticsProps();

    const onSendHandler = () => {
        const errors = validateClinicianMessage({
            originalGreeting: state.originalGreeting,
            messageBody: state.messageBody,
            attachments: state.attachments,
            withTemplate: !!state.template,
        });

        track("ClinicianMessageSend Button Click", {
            conversationId,
            withTemplate: !!state.template,
            withAttachment: state.attachments.length > 0,
            countAttachment: state.attachments.length,
            origin: "/inbox",
            isReply: true, // always true for now as we have only reply form
            isTestPatient: patient ? isTestPatient(patient) : false,
            hasError: errors.length > 0,
            isMultiFactorAuthEnabled,
            isMultiFactorAuthActive,
            templateName: state.template?.value.title,
            templateGroup: state.template?.value.heading,
            countParticipant,
        });

        // Either add new or clear the errors
        dispatch({
            type: "SET_ERRORS",
            payload: errors,
        });

        if (errors.length > 0) {
            return;
        }

        onMessageSend({
            attachments: state.attachments,
            messageBody: state.messageBody,
            messageSignature: state.messageSignature,
            template: state.template,
        });
    };

    return (
        <Pill.PrimaryButton
            onClick={onSendHandler}
            disabled={isLoading}
            dimension="small"
        >
            <Pill.Icon
                name="Send"
                colour="white"
                size={3}
                theme="Fill"
                isLoading={isLoading}
            />
            <Pill.Text>Send</Pill.Text>
        </Pill.PrimaryButton>
    );
};
