import {
    MessageTemplateResponse,
    MessageTemplatesResponse,
    SnomedCodeFull2,
} from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";
import { MessageTemplate } from "domains/message-templates/types/MessageTemplate";
import { Owner } from "domains/message-templates/types/Owner";

import { SnomedCode } from "../types/SnomedCode";
import { mapTemplatePathToHeading } from "./mapTemplatePathToHeading";

const mapApiOwnerToOwner = (
    owner: MessageTemplateResponse["owner"],
): Owner | null => {
    if (owner === "AccuRx") {
        return "Accurx";
    }
    if (owner === "User") {
        return "User";
    }
    if (owner === "Organisation") {
        return "Organisation";
    }

    return null;
};

const mapSnomedCode = ({
    snomedCode,
    templateId,
}: {
    snomedCode: SnomedCodeFull2;
    templateId: string;
}): SnomedCode | null => {
    if (snomedCode.conceptId === undefined || snomedCode.conceptId === null) {
        Log.error(
            "Unable to map SNOMED code with no ID attached to message template. Discarding SNOMED code.",
            { tags: { templateId, snomedTerm: snomedCode.term } },
        );
        return null;
    }

    return { id: snomedCode.conceptId, term: snomedCode.term };
};

export const mapMessageTemplateResponseToMessageTemplate = (
    response: MessageTemplatesResponse,
    workspaceId: number,
): MessageTemplate[] => {
    const mapped = response.templates
        .map((template) => {
            const mappedOwner = mapApiOwnerToOwner(template.owner);

            if (mappedOwner === null) {
                Log.error(
                    "Unable to map message template with no owner. Discarding template.",
                    { tags: { templateId: template.id } },
                );
                return null;
            }

            if (template.id === undefined || template.id === null) {
                Log.error(
                    "Unable to map message template with no ID. Discarding template.",
                );
                return null;
            }

            const templateId = template.id;

            const mappedTemplate: MessageTemplate = {
                id: template.id,
                title: template.title,
                body: template.body,
                allowReplyByDefault: template.allowReplyByDefault,
                attachments: template.attachments?.map((attachment) => ({
                    id: attachment.id.toString(),
                    name: attachment.fileName,
                    size: attachment.fileSize,
                    previewUrl: `/api/patientMessaging/template/message/${workspaceId}/${templateId}/attachment/${attachment.id}`,
                })),
                owner: mappedOwner,
                snomedCodes: template.snomedCodes
                    ?.map((snomedCode) =>
                        mapSnomedCode({ snomedCode, templateId: templateId }),
                    )
                    .filter((s): s is SnomedCode => s !== null),
                heading: mapTemplatePathToHeading(template.path) ?? undefined,
            };

            return mappedTemplate;
        })
        .filter(
            (template: MessageTemplate | null): template is MessageTemplate => {
                return template !== null;
            },
        );

    return mapped;
};
