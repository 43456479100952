import React from "react";

import { Button, Text } from "@accurx/design";

import {
    SearchForPatientByNHSNumber,
    SearchFormData,
    SubmitNhsNumberSearchForm,
} from "app/searchForPatient/searchForPatientForm/SearchForPatientByNHSNumber";

import {
    StyledBody,
    StyledContainer,
    StyledFooter,
} from "./PatientMatchSearch.styles";

const FORM_ID = "pifu-patient-matching";

export type PatientMatchSearchProps = {
    onSubmit: SubmitNhsNumberSearchForm;
    isSearching: boolean;
    initialValue: SearchFormData | undefined;
    resetServerError: () => void;
    serverError: string;
    onCancel: () => void;
};

export const PatientMatchSearch = ({
    onSubmit,
    isSearching,
    initialValue,
    resetServerError,
    serverError,
    onCancel,
}: PatientMatchSearchProps) => {
    return (
        <StyledContainer>
            <StyledBody>
                <Text as="h2" skinny variant="subtitle">
                    Match a patient to this conversation
                </Text>
                <Text skinny variant="body">
                    Search for patients by their date of birth and NHS number.
                    Both are required to find a patient to match with.
                </Text>
                <SearchForPatientByNHSNumber
                    isFormDisabled={isSearching}
                    onSubmit={onSubmit}
                    serverError={serverError}
                    resetServerError={resetServerError}
                    initialFormData={initialValue}
                    formId={FORM_ID}
                    hasColumnLayout={true}
                    hasCustomSubmitButton={true}
                />
            </StyledBody>
            <StyledFooter>
                <Button onClick={onCancel} text="Cancel" theme="secondary" />
                <Button
                    disabled={isSearching}
                    form={FORM_ID}
                    type="submit"
                    icon={{ name: "Search" }}
                    text="Search"
                    theme="primary"
                />
            </StyledFooter>
        </StyledContainer>
    );
};
