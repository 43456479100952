import {
    useCurrentOrganisation,
    useCurrentUser,
} from "app/organisations/hooks";

import { SidebarLink } from "../SidebarLink";
import {
    StyledAccordion,
    StyledSidebar,
    StyledSidebarToggle,
    StyledStackPanel,
} from "./Sidebar.styles";

export type SidebarProps = {
    isOpen: boolean;
    close: () => void;
};

export const Sidebar = ({ isOpen, close }: SidebarProps) => {
    const org = useCurrentOrganisation();
    const userSettings = useCurrentUser();

    return (
        <StyledSidebar isOpen={isOpen}>
            <StyledSidebarToggle
                onClick={close}
                icon={{
                    colour: "blue",
                    name: "Arrow",
                    title: "Close menu",
                    id: "close-menu",
                    rotation: "left",
                }}
                text="Close menu"
                theme="transparent"
            />
            <nav aria-label="Settings tabs">
                {/*
                Bit of a weird corner case, it's possible
                to get to the settings page without having an org
                selected, in which case we want to show the user settings only.
            */}
                {org && userSettings?.isApproved && (
                    <StyledAccordion header="Organisation" defaultOpen>
                        <StyledStackPanel
                            as="ul"
                            orientation="vertical"
                            gutter={4}
                            role="tablist"
                        >
                            <SidebarLink tab="users" close={close} />
                            <SidebarLink
                                tab="available-features"
                                close={close}
                            />
                            <SidebarLink
                                tab="installed-computers"
                                close={close}
                            />
                            <SidebarLink
                                tab="team-notifications"
                                close={close}
                            />
                            <SidebarLink tab="patient-triage" close={close} />
                            <SidebarLink tab="sms-settings" close={close} />
                        </StyledStackPanel>
                    </StyledAccordion>
                )}
                <StyledAccordion header="Your account" defaultOpen>
                    <StyledStackPanel
                        as="ul"
                        orientation="vertical"
                        gutter={4}
                        role="tablist"
                    >
                        <SidebarLink tab="profile" close={close} />
                        <SidebarLink tab="password" close={close} />
                        <SidebarLink tab="message-signature" close={close} />
                        <SidebarLink tab="cookies" close={close} />
                        <SidebarLink tab="security" close={close} />
                        <SidebarLink tab="terms-and-conditions" close={close} />
                    </StyledStackPanel>
                </StyledAccordion>
            </nav>
        </StyledSidebar>
    );
};
