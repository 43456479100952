/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { useEffect, useState } from "react";

import { useErrorSummary } from "@accurx/design";
import { useHistory } from "react-router";
import { RemirrorJSON } from "remirror";

import {
    TemplateSaveButtonProps,
    trackTemplateCopyConfirmButtonClick,
    trackTemplateCreateConfirmButtonClick,
    trackTemplateEditConfirmButtonClick,
} from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SnomedCode } from "app/sharedComponents/SnomedPicker";
import { useCurrentOrgId } from "store/hooks";

import { jsonToText } from "../../accumailTemplateEditor";
import {
    getInvalidPlaceholders,
    getPlaceholders,
} from "../../accumailTemplateEditor/helpers/editorJsonToString";
import { getManageTemplatesUrl } from "../../manageTemplates/ManageAccumailTemplates.tabs";
import { AccumailTemplateType, SaveMode } from "../../templates.types";

type useTemplateFormStateProps = {
    doSave: (
        textContent: string,
        title: string,
        snomedCodes?: SnomedCode[],
    ) => void;
    type: AccumailTemplateType | undefined;
    saveMode: SaveMode;
};

export const useTemplateFormState = ({
    doSave,
    type,
    saveMode,
}: useTemplateFormStateProps) => {
    const history = useHistory();
    const workspaceId = useCurrentOrgId()!;
    const [editorContent, setEditorContent] = useState<
        RemirrorJSON | undefined
    >(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);

    const [manageTemplatePage, setManageTemplatePage] = useState<string>();
    const { addError, clearAllErrors } = useErrorSummary();

    const [submitted, setSubmitted] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [submittedContentIsEmpty, setSubmittedContentIsEmpty] =
        useState(false);
    const [invalidPlaceholder, setInvalidPlaceholder] = useState<string | null>(
        null,
    );

    const [snomedCodes, setSnomedCodes] = useState<SnomedCode[] | undefined>();
    const flemingLoggedInCustomProperties = useFlemingLoggedInAnalytics();

    const templateLevel = type === "UserTemplate" ? "User" : "Organisation";

    const save = () => {
        setSubmitted(true);
        setInvalidPlaceholder(null);
        setSubmittedContentIsEmpty(false);
        clearAllErrors();
        setHasErrors(false);

        const errors: { id: string; body: string }[] = [];

        let error = false;
        if (title === undefined || title.trim().length === 0) {
            errors.push({
                id: "title",
                body: "Template name can't be empty",
            });
            error = true;
        }
        if (title !== undefined && title.trim().length > 50) {
            errors.push({
                id: "title",
                body: "Template name can't be more than 50 characters long",
            });
            error = true;
        }

        const text =
            editorContent === undefined ? "" : jsonToText(editorContent);
        if (text.trim() === "") {
            setSubmittedContentIsEmpty(true);
            errors.push({
                id: "content",
                body: "Message can't be empty",
            });
            error = true;
        }

        const invalid = getInvalidPlaceholders(text);
        if (invalid.length > 0) {
            setInvalidPlaceholder(invalid[0]);
            errors.push({
                id: "content",
                body: `You've included the following in your template: %${invalid[0]}%. We use this word format to automatically fill information from the medical record in Accumail. Please write your placeholder as ***${invalid[0]}*** instead.`,
            });
            error = true;
        }

        errors.forEach((error) => addError(error));

        setHasErrors(error);
        if (!error && title) {
            doSave(text.trim(), title.trim(), snomedCodes);
        }

        const analyticsProps: TemplateSaveButtonProps = {
            ...flemingLoggedInCustomProperties,
            ...(error
                ? {
                      hasError: true,
                      errorReason: errors.map((e) => e.body),
                  }
                : { hasError: false }),
            productOrigin: "ClinicianMessage",
            templateLevel,
            pageOrigin: "ManageTemplatePage",
            templateType: "email",
            templateName: title ?? "",
            countAttachment: 0,
            countSnomedCode: snomedCodes?.length ?? 0,
            countPatientDetail: getPlaceholders(text).length,
        };

        const trackClicks = {
            create: trackTemplateCreateConfirmButtonClick,
            copy: trackTemplateCopyConfirmButtonClick,
            edit: trackTemplateEditConfirmButtonClick,
        };
        trackClicks[saveMode](analyticsProps);
    };

    useEffect(
        () => setManageTemplatePage(getManageTemplatesUrl(workspaceId, type)),
        [workspaceId, type],
    );

    const goBack = () =>
        manageTemplatePage === undefined
            ? undefined
            : history.push(manageTemplatePage);

    return {
        title,
        setTitle,
        editorContent,
        setEditorContent,
        snomedCodes,
        setSnomedCodes,
        save,
        goBack,
        manageTemplatePage,
        validationDetails: {
            submittedContentIsEmpty,
            submitted,
            hasErrors,
            invalidPlaceholder,
        },
    };
};
