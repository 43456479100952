import { PatientThreadContentType as TicketPatientThreadContentType } from "@accurx/api/ticket";
import { PatientThreadContentType as PatientMessagingPatientThreadContentType } from "domains/concierge/internal/api/patientMessaging/types/dto.types";
import { ConversationItem } from "domains/concierge/types";

export type TicketItemIdentity = {
    type:
        | TicketPatientThreadContentType
        | PatientMessagingPatientThreadContentType;
    id: string;
};

export const mapTicketItemIdentityToConversationItemId = (
    identity: TicketItemIdentity,
): ConversationItem["id"] => {
    return `ti-${identity.type}-${identity.id}`;
};
