// eslint-disable-next-line
import { GlobalStyle } from "@accurx/design";
import { SelfbookConfigurationModal } from "domains/self-book";
import { Route, Switch } from "react-router";

export function SelfbookRoutes() {
    if ("hideStatusEmbed" in window) {
        window.hideStatusEmbed();
    }

    return (
        <>
            <GlobalStyle />
            <Switch>
                <Route path="/w/:workspaceId/self-book/compose" exact>
                    <SelfbookConfigurationModal />
                </Route>
            </Switch>
        </>
    );
}
