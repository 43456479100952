import { useState } from "react";

import { SplitButtonGroup } from "@accurx/navigation";
import { StyledMessageButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";

import { PatientSearchModal } from "../PatientSearch/PatientSearchModal";
import { NewMessageDropdown } from "./NewMessageDropdown";

export const NewMessage = ({ hideText = false }: { hideText?: boolean }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);

    return (
        <>
            <SplitButtonGroup>
                <StyledMessageButton
                    onClick={openModal}
                    icon={{
                        name: "PenToSquare",
                        title: "New message",
                        colour: "green",
                    }}
                    text={hideText ? undefined : "New"}
                />
                <NewMessageDropdown openModal={openModal} />
            </SplitButtonGroup>

            <PatientSearchModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
            />
        </>
    );
};
