import { MedicalRecordWriteStatus } from "@accurx/api/ticket";
import { SaveToRecordState } from "domains/concierge/types";

export const mapMedicalRecordWriteState = (
    state: MedicalRecordWriteStatus | null | undefined,
): SaveToRecordState => {
    if (state === undefined || state === null) {
        return { status: "NotSaved", saveEvents: [] };
    }

    switch (state) {
        case MedicalRecordWriteStatus.Unknown:
            return { status: "NotSaved", saveEvents: [] };
        case MedicalRecordWriteStatus.Success:
            return { status: "Saved", saveEvents: [] };
        case MedicalRecordWriteStatus.Failed:
            return { status: "Failed", saveEvents: [] };
    }
};
