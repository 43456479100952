import { useCurrentWorkspace } from "@accurx/auth";

import { useCompose } from "../context";
import { calculateCharacterCount } from "../utils";

export const useCharacterCount = () => {
    const { state } = useCompose();
    const workspace = useCurrentWorkspace();

    return calculateCharacterCount({
        body: state.messageBody,
        footer: state.messageSignature,
        workspaceName: workspace.organisationName,
        patientResponseEnabled: state.isPatientResponseEnabled,
        hasAttachments: state.attachments.length > 0,
        hasQuestionnaire: state.template?.type === "QuestionnaireTemplate",
        nhsAdviceLink: state.nhsAdviceLink,
        hasSelfbookLink: state.selfBookLink !== null,
    });
};
