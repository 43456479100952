import { mapUserGroupTypeToTeamType } from "domains/concierge/internal/api/shared/mappers/mapUserGroupTypeToTeamType";
import { Subscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { actions } from "domains/concierge/internal/store";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import { Event, SocketEvents } from "shared/hubClient/HubClient";

const mapToTeamSummary = (
    update: Event<"OnUserGroupChanged">,
    currentUserId: string,
): TeamSummary | undefined => {
    const id = update.payload.userGroup?.id;
    const displayName = update.payload.userGroup?.name;

    if (isNil(update.payload.organisationId)) {
        return undefined;
    }

    if (isNil(id) || id.length === 0) {
        return undefined;
    }

    if (isNil(displayName)) {
        return undefined;
    }

    if (isNil(update.payload.userMembership)) {
        return undefined;
    }

    const isMember = !!update.payload.userMembership.find(
        (membership) => membership.userId === currentUserId,
    )?.userHasConfirmedMemberShip;

    return {
        id: id,
        displayName: displayName,
        type: mapUserGroupTypeToTeamType(update.payload.userGroup?.groupType),
        isMember,
    };
};

export const OnUserGroupChanged: Subscription = ({
    transport,
    dispatch,
    getState,
    workspaceId,
    currentUserId,
}) => {
    const subscription = transport.subscribe({
        methodName: SocketEvents.OnUserGroupChanged,
        eventFilter: (event) => event.payload.organisationId === workspaceId,
        onEvent: (event) => {
            const teamUpdate = mapToTeamSummary(event, currentUserId);
            if (!teamUpdate) return;
            const beforeState = getState();

            dispatch(
                actions.processUpdates({
                    source: "SignalR:OnUserGroupChanged",
                    teams: [teamUpdate],
                    users: [],
                    patients: [],
                    conversations: [],
                }),
            );
            const afterState = getState();
            const currentUserMembershipChanged =
                beforeState.conversations.teamMembership[teamUpdate.id] !==
                afterState.conversations.teamMembership[teamUpdate.id];
            // When team membership changes we also want to force a
            // recalculation of unread counts
            if (currentUserMembershipChanged) {
                dispatch(actions.conversations.recalculateUnreadCounts());
            }
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
