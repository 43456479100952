import { DateHelpers } from "@accurx/shared";

import { UpdatingStatus } from "shared/LoadingStatus";

import { VaccineGetAppointmentsResponse } from "../models/VaccineCancelDTO";
import {
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    KnownAction,
} from "./VaccineCancel.actions";

// -----------------
// STATE Interface - This defines the type of data maintained in the Redux store.
// -----------------
export interface VaccineCancelReducerState {
    appointmentsLoading: UpdatingStatus;
    appointmentsLoadingError: string;
    vaccineAppointmentsDetails: VaccineGetAppointmentsResponse;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: VaccineCancelReducerState = {
    appointmentsLoading: UpdatingStatus.Initial,
    appointmentsLoadingError: "",
    vaccineAppointmentsDetails: {
        bookings: [],
    },
};

export const reducer = (
    state: VaccineCancelReducerState = initialState,
    action: KnownAction,
): VaccineCancelReducerState => {
    switch (action.type) {
        case GET_APPOINTMENTS_STARTED:
            return {
                ...state,
                appointmentsLoading: UpdatingStatus.Loading,
                appointmentsLoadingError: "",
            };
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointmentsLoading: UpdatingStatus.Loaded,
                appointmentsLoadingError: "",
                vaccineAppointmentsDetails: {
                    bookings: action.vaccineAppointmentsDetails.bookings.sort(
                        (a, b) =>
                            DateHelpers.mostRecentSorter(
                                a.startTime,
                                b.startTime,
                            ),
                    ),
                },
            };
        case GET_APPOINTMENTS_FAILURE:
            return {
                ...state,
                appointmentsLoading: UpdatingStatus.Failed,
                appointmentsLoadingError: action.error,
            };
        default:
            return state;
    }
};
