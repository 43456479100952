import { useEffect } from "react";

import { useDeepMemo } from "@accurx/hooks";
import { AppSettings, Log } from "@accurx/shared";
import isEqual from "lodash/isEqual";
import { useLocation } from "react-router";

type FeatureFlagOverrideValues = Record<string, boolean | undefined>;

export const STORAGE_KEY = "useFeatureFlagOverrides";

/**
 * Ability to override feature flags via query params in the URL.
 * The overrides will be persisted through the entire session via `sessionStorage` (i.e. it will be reset after closing the browser tab)
 *
 * To override a feature, use the `featuresOn` or `featuresOff` params followed by a comma separated list
 * of feature names to override them to be On or Off respectively.
 *
 * Example:
 * `accurx.com?featuresOn=MyFeature,MyOtherFeature&featuresOff=FeatureIDontWant,OtherFeatureIDontWant`
 *
 * `MyFeature` & `MyOtherFeature` will be `On` and `FeatureIDontWant` & `OtherFeatureIDontWant` will be `Off`.
 *
 * @returns A `string`->`boolean` map where the key is the name of the feature and the value is what is should be overridden to be.
 */
export const useFeatureFlagOverrides = (): FeatureFlagOverrideValues => {
    const isProduction = AppSettings.accurxEnvironment === "production";

    const location = useLocation();

    const oldOverrides = isProduction
        ? {}
        : (JSON.parse(
              sessionStorage.getItem(STORAGE_KEY) ?? "{}",
          ) as FeatureFlagOverrideValues);

    const oldOverridesMemo = useDeepMemo(oldOverrides);

    const overrides = { ...oldOverridesMemo };

    const params = new URLSearchParams(location.search);

    params.getAll("featuresOn").forEach((v) => {
        const flags = v.split(",");
        flags.forEach((flag) => {
            overrides[flag] = true;
        });
    });

    params.getAll("featuresOff").forEach((v) => {
        const flags = v.split(",");
        flags.forEach((flag) => {
            overrides[flag] = false;
        });
    });

    const overridesMemo = useDeepMemo(overrides);

    useEffect(() => {
        if (!isProduction && !isEqual(overridesMemo, oldOverridesMemo)) {
            const overridesString = JSON.stringify(overridesMemo);

            sessionStorage.setItem(STORAGE_KEY, overridesString);
            Log.info("Feature flag overrides used", {
                tags: {
                    overrides: overridesString,
                },
            });
        }
    }, [overridesMemo, oldOverridesMemo, isProduction]);

    return isProduction ? {} : overridesMemo;
};
