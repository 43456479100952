import { Button, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    background: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    align-items: center;
    box-shadow: inset 0px -2px 0px ${Tokens.COLOURS.greyscale.silver};
    background-color: ${Tokens.COLOURS.primary.yellow[10]};
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }
`;

export const StyledSearchInfo = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${Tokens.SIZES[2]};
    margin-top: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledText = styled(Text)`
    margin-left: ${Tokens.SIZES[1]};
`;

export const StyledIconBlock = styled.span`
    height: auto;
    width: auto;
}
`;

export const StyledSearchButton = styled(Button)`
    white-space: nowrap;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1.5]};
`;
