import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { NhsAppMessageItem } from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type MessageNhsAppProps = {
    conversationItem: NhsAppMessageItem;
};

export const MessageNhsApp = ({ conversationItem }: MessageNhsAppProps) => {
    const userId = conversationItem.createdBy.id;
    const user = useUser({ userId });

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by{" "}
                    </UI.VisuallyHidden>
                    {user?.isCurrentUser ? "You" : user?.displayName}:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {conversationItem.body}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
