import { FormEvent, ReactNode, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Flex,
    FormFieldWrapper,
    Link,
    Text,
} from "@accurx/design";
import { Log, SharedUrls } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { POST_USER_PROFILE_SUCCESS } from "app/account/AccountActions";
import { FlemingAnalyticsTracker } from "app/analytics";
import { useUpdateUserHealthcareProfileMutation } from "app/hooks/mutations";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { TermsAndConditionsCheckbox } from "app/sharedComponents/termsAndConditionsCheckbox/TermsAndConditionsCheckbox";
import { getTermsAndConditionsError } from "shared/Form.helper";
import { getNextUrlOrHomepage } from "shared/RoutesHelper";
import UserSettingsService from "shared/storage/UserSettingsService";
import { useAppSelector } from "store/hooks";

const CardFooterWrapper = ({ children }: { children: ReactNode }) => (
    <Flex justifyContent={"flex-end"}>{children}</Flex>
);

/**
 * For users who are considered first time users,
 * we want them to explicitly check a checkbox
 * to accept terms and conditions
 */
const TermsAndConditionsWithRequiredCheckbox = ({
    onSubmitSuccess,
    isLoading,
}: {
    onSubmitSuccess: () => void;
    isLoading: boolean;
}) => {
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsAcceptedErrorMessage, setTermsAcceptedErrorMessage] =
        useState("");

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const errorMessage = getTermsAndConditionsError(termsAccepted);
        if (errorMessage) {
            setTermsAcceptedErrorMessage(errorMessage);
            return;
        } else {
            onSubmitSuccess();
        }
    };

    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title={"Accurx Terms and Conditions"}
            />
            <div>
                <form onSubmit={handleSubmit}>
                    <Card
                        footer={
                            <CardFooterWrapper>
                                {isLoading ? (
                                    <Button
                                        type="button"
                                        text="Submitting..."
                                        disabled
                                    />
                                ) : (
                                    <Button text="Accept" type="submit" />
                                )}
                            </CardFooterWrapper>
                        }
                    >
                        <FormFieldWrapper
                            errors={
                                termsAcceptedErrorMessage
                                    ? [termsAcceptedErrorMessage]
                                    : []
                            }
                        >
                            <TermsAndConditionsCheckbox
                                checked={termsAccepted}
                                onCheckChange={setTermsAccepted}
                            />
                        </FormFieldWrapper>
                    </Card>
                </form>
            </div>
        </>
    );
};

/**
 * For existing users we want them to
 * just implicitly acknowledge we've updated the terms,
 * they don't need to explicitly accept with checkbox
 */
export const TermsAndConditionsWithImplicitAccept = ({
    onSubmitSuccess,
    isLoading,
}: {
    onSubmitSuccess: () => void;
    isLoading: boolean;
}) => {
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmitSuccess();
    };
    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title={"We've updated our Terms and Conditions"}
            />
            <div>
                <form onSubmit={handleSubmit}>
                    <Card
                        footer={
                            <CardFooterWrapper>
                                <Button
                                    text="Ok, got it"
                                    type="submit"
                                    disabled={isLoading}
                                />
                            </CardFooterWrapper>
                        }
                    >
                        <Text skinny>
                            We've updated our{" "}
                            <Link
                                href={SharedUrls.TermsAndConditions}
                                openInNewTab
                            >
                                Terms and Conditions
                                <Link.Icon />
                            </Link>
                            . Please read them and make sure you understand
                            them.
                        </Text>
                    </Card>
                </form>
            </div>
        </>
    );
};

export const TermsAndConditions = (): JSX.Element => {
    const updateUserHealthcareProfileMutation =
        useUpdateUserHealthcareProfileMutation();
    const user = useAppSelector((state) => state.account.user, shallowEqual);

    const analytics = useFlemingLoggedInAnalytics();
    const location = useLocation();
    const dispatch = useDispatch();

    if (updateUserHealthcareProfileMutation.status === "error") {
        toast(
            Feedback({
                colour: "error",
                title: "Failed to update account",
                content: "Please refresh page and try again",
            }),
        );
    }

    if (user === null) {
        Log.error("TermsAndConditions expects non-null user prop.");
        return <Redirect to="/" />;
    }

    if (user?.onboarding?.hasAcceptedTermsService === undefined) {
        Log.error("TermsAndConditions expects defined has accepted terms.");
    }

    if (user?.onboarding?.hasAcceptedTermsService === true) {
        return <Redirect to={getNextUrlOrHomepage(location.search)} />;
    }

    const isFirstTimeUser = UserSettingsService.getIsFirstTimeUser(user);

    const handleUpdateUserProfile = () => {
        window.Intercom("trackEvent", "use-policy-accept");
        FlemingAnalyticsTracker.trackUsePolicyAcceptButtonClick(analytics);

        updateUserHealthcareProfileMutation.mutate(
            {
                hasAcceptedTermsService: true,
            },
            {
                onSuccess: () => {
                    dispatch({
                        type: POST_USER_PROFILE_SUCCESS,
                        hasAcceptedTermsService: true,
                    });
                },
            },
        );
    };

    // Force users to explicitly accept Terms by checking a checkbox
    if (isFirstTimeUser) {
        return (
            <TermsAndConditionsWithRequiredCheckbox
                onSubmitSuccess={handleUpdateUserProfile}
                isLoading={
                    updateUserHealthcareProfileMutation.status === "loading"
                }
            />
        );
        // Just force users to acknowledge we've updated Terms
    } else {
        return (
            <TermsAndConditionsWithImplicitAccept
                onSubmitSuccess={handleUpdateUserProfile}
                isLoading={
                    updateUserHealthcareProfileMutation.status === "loading"
                }
            />
        );
    }
};
