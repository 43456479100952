import { ClinicianConversationWithWorkspace } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { ConciergeUpdates } from "domains/concierge/testing";
import { TeamSummary, UserSummary } from "domains/concierge/types";

import { mapClinicianConversationToConversation } from "./mapClinicianConversationToConversation";
import { mapPatientInfoToPatientSummary } from "./mapPatientInfoToPatientSummary";
import { mapPatientThreadUserToUserSummary } from "./mapPatientThreadUserToUserSummary";

const mapToTeamSummaries = ({
    assignee,
}: ClinicianConversationWithWorkspace): TeamSummary[] => {
    if (assignee.assignedUserGroup) {
        const team = mapPatientThreadUserGroupToTeamSummary(
            assignee.assignedUserGroup,
        );
        return team ? [team] : [];
    }
    return [];
};

const mapToUserSummaries = ({
    assignee,
    items,
}: ClinicianConversationWithWorkspace): UserSummary[] => {
    const users: Record<string, UserSummary> = {};

    if (assignee.assignedUser) {
        const user = mapPatientThreadUserToUserSummary(assignee.assignedUser);

        if (user) {
            users[user.id] = user;
        }
    }

    items.forEach((item) => {
        const creator = item.createdBy;

        if (creator.discriminator === "user") {
            if (!creator.userId) {
                Log.error("Encountered User member with no user ID", {
                    tags: { product: "Inbox" },
                });
                return;
            }

            if (!creator.displayName && !creator.emailAddress) {
                Log.warn(
                    "Encountered User member with no display name or email address",
                    {
                        tags: {
                            product: "Inbox",
                            userId: creator.userId,
                        },
                    },
                );
            }

            users[creator.userId] = {
                id: creator.userId,
                displayName:
                    creator.displayName ||
                    creator.emailAddress ||
                    "Unknown user",
            };
        }
    });

    return Object.values(users);
};

export const mapClinicianConversationToConciergeUpdate = (
    dto: ClinicianConversationWithWorkspace,
): ConciergeUpdates => {
    const conversation = mapClinicianConversationToConversation(dto);

    if (!conversation) {
        return {
            conversations: [],
            users: [],
            teams: [],
            patients: [],
        };
    }

    return {
        conversations: [conversation],
        users: mapToUserSummaries(dto),
        teams: mapToTeamSummaries(dto),
        patients: dto.patient
            ? [mapPatientInfoToPatientSummary(dto.patient)]
            : [],
    };
};
