import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeFetchError,
    NativeTransportError,
    useNativeTransport,
} from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import {
    MutateSelfBookConfigurationCompletePayload,
    mutateSelfBookConfigurationComplete,
} from "../api/mutateSelfbookConfigurationComplete";

export const useSelfBookConfigurationComplete = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        MutateSelfBookConfigurationCompletePayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "MutateSelfBookConfigurationComplete: Called with no native transport",
            );
        }

        await mutateSelfBookConfigurationComplete(
            {
                transport,
                workspaceId,
            },
            variables,
        );
    }, options);
};
