import { useMemo } from "react";

import { PatientTriageRequestItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type PatientTriageRequestProps = {
    conversationItem: PatientTriageRequestItem;
};

export const PatientTriageRequest = ({
    conversationItem,
}: PatientTriageRequestProps) => {
    const summary = useMemo(() => {
        if (conversationItem.requestType === "Admin") {
            return conversationItem.requestTitle?.replace("Admin - ", "");
        }

        if (conversationItem.sections.length === 0) {
            Log.error(
                "No sections were found in this conversationItem (PatientTriageRequestItem) object",
            );
            return "No summary found";
        }

        const c = conversationItem.sections.find((section) => {
            return section.responses.some((response) => {
                return response.answer && response.answer;
            });
        });

        const answer =
            c && c.responses[0].answer ? c.responses[0].answer : null;

        if (answer === null) {
            Log.error("No answers found in patient response object");
            return "No response found";
        }

        return answer;
    }, [
        conversationItem.requestTitle,
        conversationItem.requestType,
        conversationItem.sections,
    ]);

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    {conversationItem.requestType} request:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {summary}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
