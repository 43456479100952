import { QueryClient } from "@tanstack/react-query";

let queryClient: QueryClient | undefined = undefined;

/**
 * React Query manages API calls
 *
 * This function is idempotent - meaning only one QueryClient will ever be created
 *
 * The QueryClient is the main controller for React Query that acts as a data cache
 * If you're making API calls or interacting with the cache directly from React
 * components you should first check to see if you can use one of the React Hooks
 * that React Query provides.
 *
 * Outside of React components the cache instance can be used to fetch API data
 * and to interact with the cache.
 *
 * More info here: https://tanstack.com/query/v4/docs/react/reference/QueryClient
 *
 * @returns An instance of React Queries Query Client
 */
export const getOrCreateQueryClient = (): QueryClient => {
    if (queryClient) {
        return queryClient;
    }

    queryClient = new QueryClient(queryClientOptions);

    return queryClient;
};

export const queryClientOptions = {
    defaultOptions: {
        queries: {
            // Our own httpClient already retries every request 3 times so prevent
            // ReactQuery also retrying.
            retry: false,

            // By default ReactQuery refreshes the cache if the window loses and
            // regains focus. Turn this off.
            refetchOnWindowFocus: false,

            // By default ReactQuery refreshes the cache of any query performed
            // on the first render of a newly mounted component. Turn this off.
            refetchOnMount: false,

            // ---
            // Interesting but agressive defaults:
            // The options below match the built-in defaults of React Query but
            // are helpful to highlight here so we're aware they're happening.
            // ---

            // Refresh any active queries if the users network connection drops
            // and reconnects.
            refetchOnReconnect: true,

            // Interestingly, queries are considered stale instantly by default
            // meaning that the second+ time a query fires cached data will be
            // displayed but a will refetch in the background. Because of the
            // setting overrides above, this will only happen when the users
            // networkk drops and reconnects.
            staleTime: 0,

            // Unused cached data will be deleted after 5 minutes
            cacheTime: 1000 * 60 * 5,
        },
    },
};
