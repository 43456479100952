import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    PatientDemographics,
    useMedicalRecordConnection,
} from "@accurx/native";
import { ErrorImage } from "domains/inbox/components/ErrorImage/ErrorImage";
import {
    FormEMRPatientDetails,
    formEMRPatientDetailsId,
} from "domains/inbox/components/PatientSearch/components/forms/FormEMRPatientDetails";
import {
    FormEmis,
    formEmisId,
} from "domains/inbox/components/PatientSearch/components/forms/FormEmis";
import { PatientMatchLayout } from "domains/inbox/components/PatientSearch/components/forms/PatientMatchLayout";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";

import {
    StyledFormsDescriptionText,
    StyledPatientMatchSpacer,
} from "./PatientMatch.styles";
import {
    StyledConnectionErrorWrapper,
    StyledPatientMatchSpacerWithBorder,
} from "./PatientMatchEmr.styles";

/**
 * Flow to match patients via emr
 */
export const PatientMatchEmr = ({
    onResultsFound,
    conversation,
}: {
    onResultsFound: (data: PatientDemographics[]) => void;
    conversation: Conversation;
}) => {
    const medicalRecordConnection = useMedicalRecordConnection();

    const track = useAnalytics();

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "ConversationActions",
    });

    const [isLoading, setIsLoading] = useState(false);

    if (medicalRecordConnection.status === "Disconnected") {
        return (
            <>
                <StyledPatientMatchSpacerWithBorder>
                    <UI.Text as="h2" variant="label" skinny>
                        Match patient to conversation
                    </UI.Text>
                </StyledPatientMatchSpacerWithBorder>
                <StyledConnectionErrorWrapper>
                    <ErrorImage alt="Medical record disconnected error" />
                    <UI.Text variant="preview" skinny>
                        Sorry, it seems like you're disconnected from the
                        medical records system. Try again after connecting to
                        the system.
                    </UI.Text>
                </StyledConnectionErrorWrapper>
            </>
        );
    }

    if (medicalRecordConnection.system === "Emis") {
        return (
            <PatientMatchLayout
                isLoading={isLoading}
                buttonProps={{
                    form: formEmisId,
                }}
            >
                <>
                    <StyledPatientMatchSpacer>
                        <UI.Text as="h2" variant="label" skinny>
                            Match patient to conversation
                        </UI.Text>
                    </StyledPatientMatchSpacer>
                    <StyledPatientMatchSpacer>
                        <FormEmis
                            onResultsFound={onResultsFound}
                            onSearchClick={({ hasError }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Click",
                                    {
                                        ...trackingFields,
                                        hasError,
                                    },
                                )
                            }
                            onSearchSettled={({ hasError, countResult }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Response",
                                    {
                                        ...trackingFields,
                                        hasError,
                                        countResult,
                                    },
                                )
                            }
                            setIsLoading={setIsLoading}
                            submitButton={<></>}
                        />
                    </StyledPatientMatchSpacer>
                </>
            </PatientMatchLayout>
        );
    }

    if (
        medicalRecordConnection.system === "SystmOne" ||
        medicalRecordConnection.system === "Vision"
    ) {
        return (
            <PatientMatchLayout
                isLoading={isLoading}
                buttonProps={{
                    form: formEMRPatientDetailsId,
                }}
            >
                <>
                    <StyledPatientMatchSpacer>
                        <UI.Flex flexDirection="column" gap="1">
                            <UI.Text as="h2" variant="label" skinny>
                                Match patient to conversation
                            </UI.Text>
                            <StyledFormsDescriptionText>
                                Search for patients by their name or NHS number.
                            </StyledFormsDescriptionText>
                        </UI.Flex>

                        <FormEMRPatientDetails
                            onResultsFound={onResultsFound}
                            onSearchClick={({ hasError }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Click",
                                    {
                                        ...trackingFields,
                                        hasError,
                                    },
                                )
                            }
                            onSearchSettled={({ hasError, countResult }) =>
                                track(
                                    "ConversationPatientMatchSearch Button Response",
                                    {
                                        ...trackingFields,
                                        hasError,
                                        countResult,
                                    },
                                )
                            }
                            setIsLoading={setIsLoading}
                            submitButton={<></>}
                        />
                    </StyledPatientMatchSpacer>
                </>
            </PatientMatchLayout>
        );
    }

    return (
        <>
            <StyledPatientMatchSpacerWithBorder>
                <UI.Text as="h2" variant="label" skinny>
                    Match patient to conversation
                </UI.Text>
            </StyledPatientMatchSpacerWithBorder>
            <StyledConnectionErrorWrapper>
                <ErrorImage alt="Unsupported medical record system error" />
                <UI.Text variant="preview" skinny>
                    Sorry, it seems like we don't support patient search on{" "}
                    {medicalRecordConnection.system ??
                        "the medical records system"}
                    . Please contact support
                </UI.Text>
            </StyledConnectionErrorWrapper>
        </>
    );
};
