import { generatePath } from "react-router";

import { ROUTES, ROUTES_CHAIN } from "shared/Routes";

export const PATHS = {
    acceptableUse: "/acceptable-use-policy",
    nameAndQuestions: "/name-and-questions/",
    nameAndQuestionsWithId: "/name-and-questions/:questionnaireId",
    invitationMessage: "/invitation-message/:questionnaireId",
    confirmationMessage: "/confirmation-message/:questionnaireId",
    assignAndSave: "/assign-and-save/:questionnaireId",
    questionnaireShare: "/questionnaires/share/:shareId",
    questionnaireSwitchingOrg: "/questionnaires/import/:shareId/switching-org",
    import: "/import/:shareId",
    exportJson: "/export/json/:questionnaireId",
    importJson: "/import/json",
};

export const routeHome = (orgId: string) =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId);

export const routeNameAndQuestionsBase = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.nameAndQuestions;

export const routeAcceptableUsePolicy = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId || "") +
    PATHS.acceptableUse;

export const routeNameAndQuestions = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.nameAndQuestionsWithId.replace(":questionnaireId", questionnaireId);

export const routeInvitationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.invitationMessage.replace(":questionnaireId", questionnaireId);

export const routeConfirmationMessage = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.confirmationMessage.replace(":questionnaireId", questionnaireId);

export const routeAssignAndSave = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.assignAndSave.replace(":questionnaireId", questionnaireId);

export const routeSummary = (orgId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId);

export const routeShare = (orgId: string, shareId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.questionnaireShare.replace(":shareId", shareId);

export const routeSwitchingOrg = ({
    orgId,
    shareId,
    switchingOrgId,
}: {
    orgId: string;
    shareId: string;
    switchingOrgId: string;
}): string =>
    generatePath(
        ROUTES_CHAIN.practicesFloreyBuilderBase +
            PATHS.questionnaireSwitchingOrg +
            "?switchingOrgId=:switchingOrgId",
        { orgId, shareId, switchingOrgId },
    );

export const importToOrg = (orgId: string, shareId: string): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.import.replace(":shareId", shareId);

export const routeImport = (shareId: string): string =>
    ROUTES.importChooseOrg.replace(":shareId", shareId);

export const routeExportAsJson = (
    orgId: string,
    questionnaireId: string,
): string =>
    ROUTES_CHAIN.practicesFloreyBuilderBase.replace(":orgId", orgId) +
    PATHS.exportJson.replace(":questionnaireId", questionnaireId);
