import { useEffect } from "react";

import { Ds } from "@accurx/design";
import { useLocation } from "react-router";

import { PatientSearchCard } from "app/searchForPatient/patientSearchCard/PatientSearchCard";
import { ROUTES_PRIMARY } from "shared/Routes";

type PatientSearchModalProps = {
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
};

export const PatientSearchModal = ({
    modalOpen,
    setModalOpen,
}: PatientSearchModalProps) => {
    const location = useLocation();

    useEffect(() => {
        setModalOpen(false);
    }, [location, setModalOpen]);

    useEffect(() => {
        // TODO Remove this temp fix when new patient search will be implemented
        // We need this to revert radix ui behaviour of "modal" prop on
        // the dropdown in the recent searches, because it blocks all interactive elements
        if (modalOpen) {
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = "";
            }, 0);
            return () => clearTimeout(timer);
        } else {
            document.body.style.pointerEvents = "auto";
        }
    }, [modalOpen]);

    return (
        <>
            <Ds.Modal size="large" open={modalOpen} onOpenChange={setModalOpen}>
                <Ds.Modal.Section>
                    <Ds.Modal.CloseButton />
                </Ds.Modal.Section>
                <Ds.Modal.Divider />
                <Ds.Modal.Section>
                    <PatientSearchCard
                        patientSearchOrigin={ROUTES_PRIMARY.patients}
                    />
                </Ds.Modal.Section>
            </Ds.Modal>
        </>
    );
};
