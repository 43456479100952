import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { ROUTES_WORKSPACE } from "shared/Routes";

import { AllReportsPage } from "./pages/AllReportsPage";
import { FragmentsPage } from "./pages/FragmentsPage";
import { PatientTriagePage } from "./pages/PatientTriagePage";
import { ResponseRatesPage } from "./pages/ResponseRatesPage";

export const ReportingRoutes = () => {
    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.reporting} exact>
                <AllReportsPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.reportingPatientTriage}>
                <PatientTriagePage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.reportingResponseRates}>
                <ResponseRatesPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.reportingFragments}>
                <FragmentsPage />
            </SimpleRoute>
        </Switch>
    );
};
