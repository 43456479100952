import { useConversation } from "domains/concierge/hooks/data/useConversation";
import { useConversationPoller } from "domains/concierge/internal/api/shared/pollers/useConversationPoller";
import { PollerStatus } from "domains/concierge/internal/util/poller.types";
import { Conversation } from "domains/concierge/types";

type LoadingResult = {
    status: "loading";
    data: null;
    error: null;
    refresh: PollerStatus["refresh"];
};

type SuccessResult = {
    status: "success";
    data: Conversation;
    error: null;
    refresh: PollerStatus["refresh"];
};

type ErrorResult = {
    status: "error";
    data: null;
    error: Error;
    refresh: PollerStatus["refresh"];
};

type UseConversationResult = LoadingResult | SuccessResult | ErrorResult;

export const useConversationQuery = ({
    conversationId,
}: {
    conversationId: Conversation["id"];
}): UseConversationResult => {
    const data = useConversation({ conversationId });

    const { status, error, refresh } = useConversationPoller({
        conversationId,
    });

    if (status === "error") {
        return {
            status: "error",
            error: error ?? new Error("Unknown error"),
            data: null,
            refresh,
        };
    }

    if (!data) {
        return {
            status: "loading",
            data: null,
            error: null,
            refresh,
        };
    }

    return {
        status: "success",
        data,
        error: null,
        refresh,
    };
};
