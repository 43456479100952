import React from "react";

import * as UI from "@accurx/design";

import { StyledContainer } from "./ConversationFilter.styles";

export type ConversationFilterProps = {
    folderGroup?: "MyInbox" | "All";
    filter?: "Sent" | "Scheduled";
    teamId?: string;
    children: React.ReactNode;
};

export const ConversationFilter = ({ children }: ConversationFilterProps) => {
    return (
        <StyledContainer alignItems="center" gap="1">
            <UI.Item>{children}</UI.Item>
        </StyledContainer>
    );
};
