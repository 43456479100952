import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { BatchMessageDetails } from "@accurx/api/patient-messaging";

import { bmGetUploadDetails } from "api/BatchMessaging";

type BatchUploadDetailsQueryVariables = {
    workspaceId: string;
    batchId: string;
};

export const useBatchUploadDetailsQuery = createQueryHook<
    BatchUploadDetailsQueryVariables,
    BatchMessageDetails
>(({ workspaceId, batchId }) => ({
    queryKey: ["batch-upload-details", { workspaceId, batchId }],
    queryFn: async () => {
        const response = await bmGetUploadDetails(workspaceId, batchId);
        const data = returnDataOrThrow(response);
        return data;
    },
}));
