import {
    ConversationGroupFilters,
    TriageType,
} from "domains/concierge/internal/types/ConversationGroupFilters";

const toAssignTriage = ({
    teamId,
    status,
    triageType,
}: {
    teamId: string;
    triageType: TriageType;
    status: "Open" | "Done";
}): ConversationGroupFilters => [
    { type: "Source", value: "Ticket" },
    {
        type: "AssignedTo",
        value: { type: "Team", id: teamId },
    },
    { type: "Status", value: status },
    {
        type: "StartsWithItem",
        value: {
            filters: [
                {
                    type: "ItemType",
                    value: ["PatientTriageRequestNote"],
                },
                {
                    type: "PatientTriageRequestType",
                    value: triageType,
                },
            ],
        },
    },
];

export const filters = {
    toAssignTriage,
};
