import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { SnomedCode } from "domains/compose/types";

import {
    StyledSnomedCodeInfoList,
    StyledSnomedCodeInfoListItem,
} from "./SnomedCodesInfo.styles";

export type SnomedCodesInfoProps = {
    snomedCodes: SnomedCode[];
    isLoading?: boolean;
};

export const SnomedCodesInfo = ({
    snomedCodes,
    isLoading = false,
}: SnomedCodesInfoProps) => {
    return (
        <StyledSnomedCodeInfoList>
            {snomedCodes.map((s, index) => {
                const description = s.term ? `${s.term} - ${s.id}` : s.id;
                return (
                    <StyledSnomedCodeInfoListItem key={`${s.id}-${index}`}>
                        <UI.Icon name="Tag" size={3} colour="metal" />
                        {isLoading ? (
                            <UI.Flex flexDirection="column">
                                <UI.Text
                                    skinny
                                    colour="night"
                                    variant="preview"
                                >
                                    SNOMED code:{" "}
                                </UI.Text>
                                <SkeletonLoader
                                    width="150px"
                                    backgroundColor={
                                        UI.Tokens.COLOURS.greyscale.silver
                                    }
                                />
                            </UI.Flex>
                        ) : (
                            <UI.Text skinny colour="night" variant="preview">
                                SNOMED code: {description}
                            </UI.Text>
                        )}
                    </StyledSnomedCodeInfoListItem>
                );
            })}
        </StyledSnomedCodeInfoList>
    );
};
