import { Observable } from "rxjs";

import { createConversationGroupSubscription } from "shared/concierge/conversations/subscriptions/ConversationGroupSubscription";
import {
    SearchedPatientConversationGroupActionScope,
    createSearchedPatientConversationActions,
} from "shared/concierge/conversations/tickets/SearchedPatientConversationActions";
import { ConversationDisplaySummary } from "shared/concierge/conversations/types/conversation.types";
import { ConversationGroupPaginationOptions } from "shared/concierge/conversations/types/conversationGroup.types";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";
import { PaginatedSubscription } from "shared/concierge/types/subscription.types";
import { UsersAndTeamsSummaries } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import { getSearchedPatientConversationsFeed } from "./patientConversationUpdatesFeed";

// We have an empty ruleset as we don't want any of the client filtering logic in the subscription
const RULESET = { rules: [] };

const PAGINATION_OPTIONS: ConversationGroupPaginationOptions = {
    pageSize: 10,
    sortBy: "ConversationLastUpdated",
    sortOrder: "UrgentThenNewestFirst",
};

type PatientConversationGroupSubscriptionOptions = {
    /** The scope that the created actions needs to have */
    conversationGroupType: SearchedPatientConversationGroupActionScope["type"];
    /** The token returned from a patient search */
    currentPatientToken: string;
    /** The id of the currently logged in user */
    currentUserId: string;
    /** The id of the currently selected workspace */
    currentWorkspaceId: number;
};

/**
 * Creates a subscription for the conversationGroupType specified
 * using SearchedPatientActions scoped to that conversationGroupType.
 * It can be set up to fetch and manage a searched patient's inbox.
 *
 * To note:
 * Although this is reusing the existing subscription functionality implemented
 * and used by the manager, it does not feed into the ConversationManager.
 *
 * @returns a paginated subscription of conversations
 */
export const setupSearchedPatientConversationGroupSubscription = ({
    conversationGroupType,
    currentPatientToken,
    currentUserId,
    currentWorkspaceId,
}: PatientConversationGroupSubscriptionOptions): {
    subscription: PaginatedSubscription<ConversationDisplaySummary>;
    /* Feeds that we need to hook into to update the global patient and user managers */
    actionFeeds: {
        patients: Observable<PatientSummary[]>;
        usersAndTeams: Observable<UsersAndTeamsSummaries>;
    };
} => {
    // Creates actions scoped to a specific conversation group
    const conversationGroupActionScope: SearchedPatientConversationGroupActionScope =
        conversationGroupType === "user"
            ? { type: "user", userId: currentUserId }
            : { type: conversationGroupType };

    // Creates actions scoped to a specific conversation group
    const actions = createSearchedPatientConversationActions(
        currentWorkspaceId,
        currentPatientToken,
        conversationGroupActionScope,
    );

    const conversationsUpdateFeed = getSearchedPatientConversationsFeed(
        currentUserId,
        actions.conversationsFeed,
    );

    const subscription = createConversationGroupSubscription(
        RULESET,
        () => {
            actions.teardown();
        },
        (continuationToken) =>
            actions.getConversationGroup(
                RULESET,
                {
                    sortOrder: PAGINATION_OPTIONS.sortOrder,
                    sortBy: PAGINATION_OPTIONS.sortBy,
                },
                continuationToken,
            ),
        conversationsUpdateFeed,
        PAGINATION_OPTIONS,
    );

    return {
        subscription,
        actionFeeds: {
            patients: actions.patientsFeed,
            usersAndTeams: actions.usersAndTeamsFeed,
        },
    };
};
