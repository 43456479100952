import { Card, Link, Text } from "@accurx/design";

export const Info = (): JSX.Element => {
    return (
        <Card>
            <Text>
                Here you can see the number of fragments and SMS messages sent
                by your organisation from the date 23/08/2023, the first date
                where we store fragment data.
            </Text>
            <Text>
                You can break it down by the products your organisation is
                using.
            </Text>
            <Text>
                <Link
                    href={
                        "https://support.accurx.com/en/articles/8354858-accurx-desktop-usage-dashboard-for-fragments"
                    }
                    openInNewTab
                >
                    Find more information about this Usage Dashboard for
                    Fragments.
                    <Link.Icon />
                </Link>
            </Text>
        </Card>
    );
};
