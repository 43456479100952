import {
    AppointmentReminder,
    CreateAppointmentReminderRequest,
    UpdateAppointmentReminderRequest,
} from "@accurx/api/appointment";
import { DeleteAppointmentReminderRequest } from "@accurx/appointment-reminders";
import { Feedback } from "@accurx/design";
import { toast } from "react-toastify";
import { Dispatch } from "redux";

import {
    getApptReminder,
    postApptReminderCreate,
    postApptReminderDelete,
    postApptReminderUpdate,
} from "../../../api/AppointmentReminder";

// -----------------
// ACTION TYPES
// -----------------
export const CREATE_APPOINTMENT_REMINDER_STARTED =
    "CREATE_APPOINTMENT_REMINDER_STARTED";
export const CREATE_APPOINTMENT_REMINDER_SUCCESS =
    "CREATE_APPOINTMENT_REMINDER_SUCCESS";
export const CREATE_APPOINTMENT_REMINDER_FAILURE =
    "CREATE_APPOINTMENT_REMINDER_FAILURE";

export const UPDATE_APPOINTMENT_REMINDER_STARTED =
    "UPDATE_APPOINTMENT_REMINDER_STARTED";
export const UPDATE_APPOINTMENT_REMINDER_SUCCESS =
    "UPDATE_APPOINTMENT_REMINDER_SUCCESS";
export const UPDATE_APPOINTMENT_REMINDER_FAILURE =
    "UPDATE_APPOINTMENT_REMINDER_FAILURE";

export const DELETE_APPOINTMENT_REMINDER_STARTED =
    "DELETE_APPOINTMENT_REMINDER_STARTED";
export const DELETE_APPOINTMENT_REMINDER_SUCCESS =
    "DELETE_APPOINTMENT_REMINDER_SUCCESS";
export const DELETE_APPOINTMENT_REMINDER_FAILURE =
    "DELETE_APPOINTMENT_REMINDER_FAILURE";

export const GET_APPOINTMENT_REMINDERS_STARTED =
    "GET_APPOINTMENT_REMINDERS_STARTED";
export const GET_APPOINTMENT_REMINDERS_SUCCESS =
    "GET_APPOINTMENT_REMINDERS_SUCCESS";
export const GET_APPOINTMENT_REMINDERS_FAILURE =
    "GET_APPOINTMENT_REMINDERS_FAILURE";
export const RESET_COMPOSE_ACTIONS = "RESET_COMPOSE_ACTIONS";
export const SELECT_APPOINTMENT_REMINDER = "SELECT_APPOINTMENT_REMINDER";
export const RESET_REMINDER_STATE = "RESET_REMINDER_STATE";

// -----------------
// ACTIONS INTERFACES
// -----------------
interface ResetComposeActions {
    type: typeof RESET_COMPOSE_ACTIONS;
}
interface ResetReminderState {
    type: typeof RESET_REMINDER_STATE;
}

interface CreateAppointmentReminderStartedAction {
    type: typeof CREATE_APPOINTMENT_REMINDER_STARTED;
}
interface CreateAppointmentReminderSuccessAction {
    type: typeof CREATE_APPOINTMENT_REMINDER_SUCCESS;
    appointmentReminders: Array<AppointmentReminder>;
}
interface CreateAppointmentReminderFailureAction {
    type: typeof CREATE_APPOINTMENT_REMINDER_FAILURE;
    errorMessage: string | null;
}

interface UpdateAppointmentReminderStartedAction {
    type: typeof UPDATE_APPOINTMENT_REMINDER_STARTED;
}
interface UpdateAppointmentReminderSuccessAction {
    type: typeof UPDATE_APPOINTMENT_REMINDER_SUCCESS;
    appointmentReminders: Array<AppointmentReminder>;
}
interface UpdateAppointmentReminderFailureAction {
    type: typeof UPDATE_APPOINTMENT_REMINDER_FAILURE;
}

interface DeleteAppointmentReminderStartedAction {
    type: typeof DELETE_APPOINTMENT_REMINDER_STARTED;
}
interface DeleteAppointmentReminderSuccessAction {
    type: typeof DELETE_APPOINTMENT_REMINDER_SUCCESS;
    appointmentReminders: Array<AppointmentReminder>;
}
interface DeleteAppointmentReminderFailureAction {
    type: typeof DELETE_APPOINTMENT_REMINDER_FAILURE;
}

interface GetAppointmentRemindersStartedAction {
    type: typeof GET_APPOINTMENT_REMINDERS_STARTED;
}
interface GetAppointmentRemindersSuccessAction {
    type: typeof GET_APPOINTMENT_REMINDERS_SUCCESS;
    appointmentReminders: Array<AppointmentReminder>;
    slotTypes: Array<string>;
    siteNames: Array<string>;
}
interface GetAppointmentRemindersFailureAction {
    type: typeof GET_APPOINTMENT_REMINDERS_FAILURE;
}

interface SelectAppointmentReminderAction {
    type: typeof SELECT_APPOINTMENT_REMINDER;
    selectedAppointmentReminder: AppointmentReminder | null;
}

export type KnownAction =
    | CreateAppointmentReminderStartedAction
    | CreateAppointmentReminderSuccessAction
    | CreateAppointmentReminderFailureAction
    | UpdateAppointmentReminderStartedAction
    | UpdateAppointmentReminderSuccessAction
    | UpdateAppointmentReminderFailureAction
    | DeleteAppointmentReminderStartedAction
    | DeleteAppointmentReminderSuccessAction
    | DeleteAppointmentReminderFailureAction
    | GetAppointmentRemindersStartedAction
    | GetAppointmentRemindersSuccessAction
    | GetAppointmentRemindersFailureAction
    | SelectAppointmentReminderAction
    | ResetComposeActions
    | ResetReminderState;

export const createAppointmentReminder =
    (practiceId: string, request: CreateAppointmentReminderRequest) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: CREATE_APPOINTMENT_REMINDER_STARTED });
        const response = await postApptReminderCreate(practiceId, request);
        if (response.success && response.result) {
            const successToast = Feedback({
                colour: "success",
                title: `Reminder added!`,
            });
            toast(successToast);
            return dispatch({
                type: CREATE_APPOINTMENT_REMINDER_SUCCESS,
                ...response.result,
            });
        }
        return dispatch({
            type: CREATE_APPOINTMENT_REMINDER_FAILURE,
            errorMessage: response.error,
        });
    };

export const updateAppointmentReminder =
    (practiceId: string, request: UpdateAppointmentReminderRequest) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: UPDATE_APPOINTMENT_REMINDER_STARTED });
        const response = await postApptReminderUpdate(practiceId, request);
        if (response.success && response.result) {
            const successToast = Feedback({
                colour: "success",
                title: `Updated!`,
            });
            toast(successToast);
            return dispatch({
                type: UPDATE_APPOINTMENT_REMINDER_SUCCESS,
                ...response.result,
            });
        }
        return dispatch({
            type: UPDATE_APPOINTMENT_REMINDER_FAILURE,
        });
    };

export const deleteAppointmentReminder =
    (practiceId: string, request: DeleteAppointmentReminderRequest) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: DELETE_APPOINTMENT_REMINDER_STARTED });
        const response = await postApptReminderDelete(practiceId, request);
        if (response.success && response.result) {
            return dispatch({
                type: DELETE_APPOINTMENT_REMINDER_SUCCESS,
                ...response.result,
            });
        }
        return dispatch({
            type: DELETE_APPOINTMENT_REMINDER_FAILURE,
        });
    };

export const getAppointmentReminders =
    (practiceId: string) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: GET_APPOINTMENT_REMINDERS_STARTED });
        const response = await getApptReminder(practiceId);
        if (response.success && response.result) {
            return dispatch({
                type: GET_APPOINTMENT_REMINDERS_SUCCESS,
                ...response.result,
            });
        }
        return dispatch({
            type: GET_APPOINTMENT_REMINDERS_FAILURE,
        });
    };

export const selectAppointmentReminder = (
    reminder: AppointmentReminder | null,
) => ({
    type: SELECT_APPOINTMENT_REMINDER,
    selectedAppointmentReminder: reminder,
});

export const resetAppointmentComposeState = () => ({
    type: RESET_COMPOSE_ACTIONS,
});
