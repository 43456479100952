import { PatientListType } from "@accurx/api/patient-messaging";
import { Feedback } from "@accurx/design";
import { DateFormatOptions, DateHelpers, Log } from "@accurx/shared";
import { QueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import FlemingApi from "api/FlemingApiClient";
import {
    AllPatientListSummariesRequest,
    CreateOrEditPatientListRequest,
    PatientList,
    PatientListAddPatientResponse,
    PatientListAppointment,
    PatientListConfirmBulkAddPatientRequest,
    PatientListConfirmBulkAddPatientResponse,
    PatientListRemovePatientEntriesActionRequest,
    PatientListRequest,
    PatientListSummary,
    PatientVideoProgressResponse,
    SharePatientListRequest,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { PATIENT_LIST_SUMMARY_KEY } from "app/hooks/queries/usePatientListSummariesQuery";
import { PatientHelper } from "shared/PatientHelper";

import {
    getPatientFromPatientId,
    getPatientListEntries,
} from "./PatientList.helper";
import { OrderMethod } from "./PatientListsReducer";

type PatientListActionOrigin = FlemingAnalyticsTracker.PatientListActionOrigin;

// ACTION TYPES
export const GET_ALL_PATIENT_LIST_SUMMARIES_STARTED =
    "GET_ALL_PATIENT_LIST_SUMMARIES_STARTED";
export const GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS =
    "GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS";
export const GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE =
    "GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE";
export const INIT_CREATE_EDIT_LIST = "INIT_CREATE_EDIT_LIST";
export const CANCEL_INIT_CREATE_EDIT_LIST = "CANCEL_INIT_CREATE_EDIT_LIST";
export const POST_CREATE_OR_EDIT_LIST_STARTED =
    "POST_CREATE_OR_EDIT_LIST_STARTED";
export const POST_CREATE_OR_EDIT_LIST_SUCCESS =
    "POST_CREATE_OR_EDIT_LIST_SUCCESS";
export const POST_CREATE_OR_EDIT_LIST_FAILURE =
    "POST_CREATE_OR_EDIT_LIST_FAILURE";
export const GET_PATIENT_LIST_STARTED = "GET_PATIENT_LIST_STARTED";
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS";
export const GET_PATIENT_LIST_FAILURE = "GET_PATIENT_LIST_FAILURE";
export const INIT_PATIENT_LIST_SHARE = "INIT_PATIENT_LIST_SHARE";
export const OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL =
    "OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL";
export const CANCEL_PATIENT_LIST_SHARE = "CANCEL_PATIENT_LIST_SHARE";
export const CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL =
    "CANCEL_PATIENT_LIST_WORKSPACE_SHARE";
export const POST_SHARE_PATIENT_LIST_STARTED =
    "POST_SHARE_PATIENT_LIST_STARTED";
export const SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_STARTED =
    "POST_SHARE_PATIENT_WITH_WORKSPACE_STARTED";
export const POST_SHARE_PATIENT_LIST_SUCCESS =
    "POST_SHARE_PATIENT_LIST_SUCCESS";
export const SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_SUCCESS =
    "POST_SHARE_PATIENT_LIST_WITH_WORKSPACE_SUCCESS";
export const POST_SHARE_PATIENT_LIST_FAILURE =
    "POST_SHARE_PATIENT_LIST_FAILURE";
export const POST_DELETE_PATIENT_LIST_STARTED =
    "POST_DELETE_PATIENT_LIST_STARTED";
export const POST_DELETE_PATIENT_LIST_SUCCESS =
    "POST_DELETE_PATIENT_LIST_SUCCESS";
export const POST_DELETE_PATIENT_LIST_FAILURE =
    "POST_DELETE_PATIENT_LIST_FAILURE";
export const POST_ADD_PATIENT_TO_LIST_STARTED =
    "POST_ADD_PATIENT_TO_LIST_STARTED";
export const POST_ADD_PATIENT_TO_LIST_SUCCESS =
    "POST_ADD_PATIENT_TO_LIST_SUCCESS";
export const POST_ADD_PATIENT_TO_LIST_FAILURE =
    "POST_ADD_PATIENT_TO_LIST_FAILURE";
// Only need the loading action because the api call is made directly from the component
export const POST_BULK_ADD_PATIENTS_TO_LIST_STARTED =
    "POST_BULK_ADD_PATIENTS_TO_LIST_STARTED";
export const POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED =
    "POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED";
export const POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS =
    "POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS";
export const POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE =
    "POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE";
export const POST_REMOVE_PATIENTS_FROM_LIST_STARTED =
    "POST_REMOVE_PATIENTS_FROM_LIST_STARTED";
export const POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS =
    "POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS";
export const POST_REMOVE_PATIENTS_FROM_LIST_FAILURE =
    "POST_REMOVE_PATIENTS_FROM_LIST_FAILURE";
export const CHANGE_ORDER_METHOD = "CHANGE_ORDER_METHOD";
export const RESET_CURRENT_LIST = "RESET_CURRENT_LIST";
export const RESET_CURRENT_LIST_ERRORS = "RESET_CURRENT_LIST_ERRORS";
export const RESET_BULK_ADD_PATIENTS_ERRORS = "RESET_BULK_ADD_PATIENTS_ERRORS";
export const REMOVE_CURRENT_LIST_DUPLICATE = "REMOVE_CURRENT_LIST_DUPLICATE";
export const ADD_CURRENT_LIST_DUPLICATE = "ADD_CURRENT_LIST_DUPLICATE";
export const SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED =
    "SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED";
export const SET_LATEST_PATIENT_LIST_SEARCHED =
    "SET_LATEST_PATIENT_LIST_SEARCHED";
export const POST_PATIENTS_VIDEO_PROGRESS_STARTED =
    "POST_PATIENTS_VIDEO_PROGRESS_STARTED";
export const POST_PATIENTS_VIDEO_PROGRESS_SUCCESS =
    "POST_PATIENTS_VIDEO_PROGRESS_SUCCESS";
export const POST_PATIENTS_VIDEO_PROGRESS_FAILURE =
    "POST_PATIENTS_VIDEO_PROGRESS_FAILURE";

// Get all lists summary
interface RequestAllPatientListSummariesStartedAction {
    type: typeof GET_ALL_PATIENT_LIST_SUMMARIES_STARTED;
}

interface RequestAllPatientListSummariesSuccessAction {
    type: typeof GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS;
    patientListSummaries: PatientListSummary[];
}

interface RequestAllPatientListSummariesFailureAction {
    type: typeof GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE;
    error: string;
}

// Initialise process of creating a new list
interface InitCreateEditList {
    type: typeof INIT_CREATE_EDIT_LIST;
    isNewList: boolean;
    saveLastSearched: boolean;
    createOrEditOrigin: PatientListActionOrigin;
}

interface CancelCreateEditList {
    type: typeof CANCEL_INIT_CREATE_EDIT_LIST;
}

// add / edit a patient list
interface PostCreateOrEditListStartedAction {
    type: typeof POST_CREATE_OR_EDIT_LIST_STARTED;
}

interface PostCreateOrEditListSuccessAction {
    type: typeof POST_CREATE_OR_EDIT_LIST_SUCCESS;
    patientList: PatientList;
}

interface PostCreateOrEditListFailureAction {
    type: typeof POST_CREATE_OR_EDIT_LIST_FAILURE;
    error: string;
}

// Get one list
interface RequestPatientListStartedAction {
    type: typeof GET_PATIENT_LIST_STARTED;
}

interface RequestPatientListSuccessAction {
    type: typeof GET_PATIENT_LIST_SUCCESS;
    patientList: PatientList;
}

interface RequestPatientListFailureAction {
    type: typeof GET_PATIENT_LIST_FAILURE;
    error: string;
}

// Share list
interface InitPatientListShareAction {
    type: typeof INIT_PATIENT_LIST_SHARE;
}

interface OpenPatientListWorkspaceShareModalAction {
    type: typeof OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL;
}

interface CancelPatientListShareAction {
    type: typeof CANCEL_PATIENT_LIST_SHARE;
}

interface ClosePatientListWorkspaceShareModalAction {
    type: typeof CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL;
}

interface PostSharePatientListStartedAction {
    type: typeof POST_SHARE_PATIENT_LIST_STARTED;
}

interface SharePatientListWithWorkspaceRequestStartedAction {
    type: typeof SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_STARTED;
}

interface PostSharePatientListSuccessAction {
    type: typeof POST_SHARE_PATIENT_LIST_SUCCESS;
    patientListId: number;
}

interface SharePatientListWithWorkspaceRequestSuccessAction {
    type: typeof SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_SUCCESS;
    patientListId: number;
}

interface PostSharePatientListFailureAction {
    type: typeof POST_SHARE_PATIENT_LIST_FAILURE;
    error: string;
}

// Delete Patient List
interface PostDeletePatientListStartedAction {
    type: typeof POST_DELETE_PATIENT_LIST_STARTED;
}

interface PostDeletePatientListSuccessAction {
    type: typeof POST_DELETE_PATIENT_LIST_SUCCESS;
    patientListId: number;
}

interface PostDeletePatientListFailureAction {
    type: typeof POST_DELETE_PATIENT_LIST_FAILURE;
}

// Add a patient to a list
interface PostAddPatientToListStartedAction {
    type: typeof POST_ADD_PATIENT_TO_LIST_STARTED;
}

interface PostAddPatientToListSuccessAction
    extends PatientListAddPatientResponse {
    type: typeof POST_ADD_PATIENT_TO_LIST_SUCCESS;
}

interface PostAddPatientToListFailureAction {
    type: typeof POST_ADD_PATIENT_TO_LIST_FAILURE;
}

// Add multiple patients to a list
// Step 1
interface PostBulkAddPatientsToListStartedAction {
    type: typeof POST_BULK_ADD_PATIENTS_TO_LIST_STARTED;
    isLoading: boolean;
}

// Step 2
interface PostConfirmBulkAddPatientsToListStartedAction {
    type: typeof POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED;
}

interface PostConfirmBulkAddPatientsToListSuccessAction
    extends PatientListConfirmBulkAddPatientResponse {
    type: typeof POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS;
}

interface PostConfirmBulkAddPatientsToListFailureAction {
    type: typeof POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE;
}

// Remove patients from a list
interface PostRemovePatientsFromListStartedAction {
    type: typeof POST_REMOVE_PATIENTS_FROM_LIST_STARTED;
    isBatch: boolean;
}

interface PostRemovePatientsFromListSuccessAction {
    type: typeof POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS;
    patientListId: number;
    patientListEntryIds: number[];
}

interface PostRemovePatientsFromListFailureAction {
    type: typeof POST_REMOVE_PATIENTS_FROM_LIST_FAILURE;
    error: string;
}

// Change order method
interface ChangeOrderMethodAction {
    type: typeof CHANGE_ORDER_METHOD;
    order: OrderMethod;
}

interface ResetCurrentListAction {
    type: typeof RESET_CURRENT_LIST;
}

interface ResetBulkAddPatientsErrorsAction {
    type: typeof RESET_BULK_ADD_PATIENTS_ERRORS;
}

interface ResetCurrentListErrorsAction {
    type: typeof RESET_CURRENT_LIST_ERRORS;
}

interface AddCurrentListDuplicateAction {
    type: typeof ADD_CURRENT_LIST_DUPLICATE;
    duplicatePatient: PatientListAppointment;
}

interface RemoveCurrentListDuplicateAction {
    type: typeof REMOVE_CURRENT_LIST_DUPLICATE;
    duplicatePatient: PatientListAppointment;
}

interface SetPatientListEntrySearchedAction {
    type: typeof SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED;
    patientListId: number;
    patientListAppointment: PatientListAppointment;
}

interface SetPatientListSearchedAction {
    type: typeof SET_LATEST_PATIENT_LIST_SEARCHED;
    patientListId: number;
}

// Patient video progress
interface RequestPatientsVideoProgressStartedAction {
    type: typeof POST_PATIENTS_VIDEO_PROGRESS_STARTED;
}

interface RequestPatientsVideoProgressSuccessAction {
    type: typeof POST_PATIENTS_VIDEO_PROGRESS_SUCCESS;
    videoProgress: PatientVideoProgressResponse[];
}

interface RequestPatientsVideoProgressFailureAction {
    type: typeof POST_PATIENTS_VIDEO_PROGRESS_FAILURE;
    error: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | InitCreateEditList
    | CancelCreateEditList
    | PostCreateOrEditListStartedAction
    | PostCreateOrEditListSuccessAction
    | PostCreateOrEditListFailureAction
    | RequestAllPatientListSummariesStartedAction
    | RequestAllPatientListSummariesSuccessAction
    | RequestAllPatientListSummariesFailureAction
    | RequestPatientListStartedAction
    | RequestPatientListSuccessAction
    | RequestPatientListFailureAction
    | InitPatientListShareAction
    | OpenPatientListWorkspaceShareModalAction
    | CancelPatientListShareAction
    | ClosePatientListWorkspaceShareModalAction
    | PostSharePatientListStartedAction
    | SharePatientListWithWorkspaceRequestStartedAction
    | SharePatientListWithWorkspaceRequestSuccessAction
    | PostSharePatientListSuccessAction
    | PostSharePatientListFailureAction
    | PostAddPatientToListStartedAction
    | PostAddPatientToListSuccessAction
    | PostAddPatientToListFailureAction
    | PostBulkAddPatientsToListStartedAction
    | PostConfirmBulkAddPatientsToListStartedAction
    | PostConfirmBulkAddPatientsToListSuccessAction
    | PostConfirmBulkAddPatientsToListFailureAction
    | ResetBulkAddPatientsErrorsAction
    | PostRemovePatientsFromListStartedAction
    | PostRemovePatientsFromListSuccessAction
    | PostRemovePatientsFromListFailureAction
    | ChangeOrderMethodAction
    | ResetCurrentListAction
    | ResetCurrentListErrorsAction
    | RemoveCurrentListDuplicateAction
    | AddCurrentListDuplicateAction
    | SetPatientListEntrySearchedAction
    | SetPatientListSearchedAction
    | RequestPatientsVideoProgressStartedAction
    | RequestPatientsVideoProgressSuccessAction
    | RequestPatientsVideoProgressFailureAction
    | PostDeletePatientListStartedAction
    | PostDeletePatientListSuccessAction
    | PostDeletePatientListFailureAction;

interface PatientListDetails {
    name: string;
    isOwner: boolean;
    type?: PatientListType;
}

const getPatientListDetails = (
    state: ApplicationState,
    listId: number,
): PatientListDetails => {
    let list: PatientList | PatientListSummary | null =
        state.patientLists.currentList || null;

    if (!list || list?.patientListId !== listId) {
        list =
            state.patientLists.allListSummaries?.find(
                (l) => l.patientListId === listId,
            ) || null;
    }

    // Add log error to make sure that analytics is reliable
    if (!list) {
        Log.error(
            `List not found in store when fetching patient list details`,
            {
                tags: {
                    logger: "Patient List - Actions",
                    listId,
                },
            },
        );
        return {
            name: "Unknown",
            isOwner: true,
        };
    }

    return {
        name: list.name,
        isOwner: list.isCurrentUserListOwner,
        type: list.listType,
    };
};

const isNewPatientList = (listId: number | null): listId is null => {
    return listId === null;
};

export const getUploadTypeFromApiResult = (
    apiResult: PatientListConfirmBulkAddPatientResponse,
) => {
    // User can only upload either a dated or anytime list,
    // So we check either for dated upload in success array, or in errors array,
    // there will never be a mix

    if (apiResult.appointmentDays && apiResult.appointmentDays.length > 0) {
        if (apiResult.appointmentDays[0]?.date === "") {
            return "Anytime";
        } else {
            return "Dated";
        }
    }
    if (apiResult.errors && apiResult.errors.length > 0) {
        if (apiResult.errors[0]?.appointmentTime === null) {
            return "Anytime";
        } else {
            // Even if appointmentTime value is ""
            // it means the user tried to upload a dated list
            return "Dated";
        }
    }

    // Right now we fall back to Anytime, even if the user
    // uploaded a list with Appointment date and time column
    // but without any rows
    return "Anytime";
};

const addPatientToListStarted = (): PostAddPatientToListStartedAction => ({
    type: POST_ADD_PATIENT_TO_LIST_STARTED,
});

const addPatientToListSuccess = (
    response: PatientListAddPatientResponse,
): PostAddPatientToListSuccessAction => ({
    type: POST_ADD_PATIENT_TO_LIST_SUCCESS,
    ...response,
});

const addPatientToListFailure = (): PostAddPatientToListFailureAction => ({
    type: POST_ADD_PATIENT_TO_LIST_FAILURE,
});

// -----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    // Get all the list summaries
    getAllUserPatientListSummaries:
        (
            request: AllPatientListSummariesRequest,
        ): AppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({
                type: GET_ALL_PATIENT_LIST_SUMMARIES_STARTED,
            });

            const response = await FlemingApi.getAllUserPatientListSummaries(
                request,
            );

            if (response.success && response.result) {
                return dispatch({
                    type: GET_ALL_PATIENT_LIST_SUMMARIES_SUCCESS,
                    patientListSummaries: response.result,
                });
            }

            return dispatch({
                type: GET_ALL_PATIENT_LIST_SUMMARIES_FAILURE,
                error:
                    response.error ||
                    "An error occurred while getting the lists",
            });
        },

    // Init a new list or cancel the init of a new list
    initCreateOrEditList: (
        isNewList: boolean,
        saveLastSearched: boolean,
        createOrEditOrigin: PatientListActionOrigin,
    ) => ({
        type: INIT_CREATE_EDIT_LIST,
        isNewList,
        saveLastSearched,
        createOrEditOrigin,
    }),

    cancelCreateOrEditList: () => ({
        type: CANCEL_INIT_CREATE_EDIT_LIST,
    }),

    createOrEditPatientList:
        (
            request: CreateOrEditPatientListRequest,
            onCreateSuccess: (patientListId: number) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
            queryClient?: QueryClient,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            dispatch({
                type: POST_CREATE_OR_EDIT_LIST_STARTED,
            });

            // Get store data for analytics
            const state = getState();
            const analyticsProps = !isNewPatientList(request.patientListId)
                ? AnalyticsMapper.getPatientListAnalyticsPropsWithActionOrigin(
                      state,
                      request.patientListId,
                  )
                : AnalyticsMapper.getPatientListAnalyticsPropsForNewlyCreatedList(
                      state,
                  );

            const response = await FlemingApi.createOrEditPatientList(request);

            // Send analytics
            if (analyticsProps) {
                const isSuccess = response.success && response.result != null;
                if (isNewPatientList(request.patientListId)) {
                    if (isSuccess) {
                        analyticsProps.countUserList =
                            analyticsProps.countUserList + 1;
                        FlemingAnalyticsTracker.trackPatientListCreateSuccess(
                            analyticsProps,
                        );
                    } else {
                        FlemingAnalyticsTracker.trackPatientListCreateFailure(
                            analyticsProps,
                        );
                    }
                } else {
                    isSuccess
                        ? FlemingAnalyticsTracker.trackPatientListEditSuccess(
                              analyticsProps,
                          )
                        : FlemingAnalyticsTracker.trackPatientListEditFailure(
                              analyticsProps,
                          );
                }
            }

            if (response.success && response.result) {
                const successToast = Feedback({
                    colour: "success",
                    title: `The list ${request.name} has been ${
                        request.patientListId ? "updated" : "created"
                    }`,
                    content: `You can now ${
                        request.patientListId ? "continue to " : ""
                    }add patients to it`,
                });
                toast(successToast);

                dispatch({
                    type: POST_CREATE_OR_EDIT_LIST_SUCCESS,
                    patientList: response.result,
                });
                queryClient?.invalidateQueries([
                    PATIENT_LIST_SUMMARY_KEY,
                    request.organisationId,
                ]);
                if (request.patientListId === null) {
                    onCreateSuccess(response.result.patientListId);
                }
                return;
            }

            const error =
                response.error ||
                `We were unable to ${
                    request.patientListId ? "edit" : "create"
                } this list`;
            const failureToast = Feedback({
                colour: "error",
                title: error,
                content: "Please try again",
            });
            toast(failureToast);

            return dispatch({
                type: POST_CREATE_OR_EDIT_LIST_FAILURE,
                error,
            });
        },

    // Get a single list
    getUserPatientList:
        (request: PatientListRequest): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: GET_PATIENT_LIST_STARTED,
            });

            const response = await FlemingApi.getUserPatientList(request);
            if (response.success && response.result) {
                return dispatch({
                    type: GET_PATIENT_LIST_SUCCESS,
                    patientList: response.result,
                });
            }
            return dispatch({
                type: GET_PATIENT_LIST_FAILURE,
                error: response.error || "",
            });
        },

    initPatientListSharing: () => ({
        type: INIT_PATIENT_LIST_SHARE,
    }),

    openPatientListWorkspaceSharingModal: () => ({
        type: OPEN_PATIENT_LIST_WORKSPACE_SHARE_MODAL,
    }),

    cancelPatientListSharing: () => ({
        type: CANCEL_PATIENT_LIST_SHARE,
    }),

    closePatientListWorkspaceSharingModal: () => ({
        type: CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL,
    }),

    sharePatientList:
        (
            request: SharePatientListRequest,
            successCallback?: () => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            dispatch({
                type: POST_SHARE_PATIENT_LIST_STARTED,
            });

            // Get store details for analytics
            const state = getState();
            const analyticsProps = AnalyticsMapper.getPatientListAnalyticsProps(
                state,
                request.patientListId,
            );

            const response = await FlemingApi.sharePatientList(request);

            if (response.success) {
                dispatch({
                    type: POST_SHARE_PATIENT_LIST_SUCCESS,
                    patientListId: request.patientListId,
                });

                toast(
                    Feedback({
                        colour: "success",
                        title: "Successfully shared",
                        content: `${request.emailAddress} now has access`,
                    }),
                );

                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListShareSuccess({
                        ...analyticsProps,
                        countShare: analyticsProps.countShare + 1,
                        shareType: "UserShare",
                    });

                successCallback && successCallback();

                return;
            }

            const error =
                response.error || "There was an error while sharing this list.";

            toast(
                Feedback({
                    colour: "error",
                    title: "Failed to share this list",
                    content: error,
                }),
            );

            analyticsProps &&
                FlemingAnalyticsTracker.trackPatientListShareFailure({
                    ...analyticsProps,
                    shareType: "UserShare",
                });

            return dispatch({
                type: POST_SHARE_PATIENT_LIST_FAILURE,
                error,
            });
        },

    sharePatientListWithWorkspace:
        (
            request: PatientListRequest,
            successCallback?: () => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            dispatch({
                type: SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_STARTED,
            });

            // Get store details for analytics
            const state = getState();
            const analyticsProps = AnalyticsMapper.getPatientListAnalyticsProps(
                state,
                request.patientListId,
            );
            const response = await FlemingApi.sharePatientListWithWorkspace(
                request,
            );

            if (response.success) {
                dispatch({
                    type: SHARE_PATIENT_LIST_WITH_WORKSPACE_REQUEST_SUCCESS,
                    patientListId: request.patientListId,
                });

                toast(
                    Feedback({
                        colour: "success",
                        title: "Successfully shared",
                    }),
                );

                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListShareSuccess({
                        ...analyticsProps,
                        shareType: "WorkspaceShare",
                    });
                successCallback && successCallback();
                return;
            }

            const error =
                response.error || "There was an error while sharing this list.";

            toast(
                Feedback({
                    colour: "error",
                    title: "Failed to share this list",
                    content: error,
                }),
            );

            analyticsProps &&
                FlemingAnalyticsTracker.trackPatientListShareFailure({
                    ...analyticsProps,
                    shareType: "WorkspaceShare",
                });

            return dispatch({
                type: CLOSE_PATIENT_LIST_WORKSPACE_SHARE_MODAL,
            });
        },

    // Delete a patient list
    deletePatientList:
        (
            request: PatientListRequest,
            deleteActionOrigin: PatientListActionOrigin,
            successCallback?: () => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            dispatch({ type: POST_DELETE_PATIENT_LIST_STARTED });

            // Get store data for analytics
            const state = getState();
            const analyticsProps =
                AnalyticsMapper.getPatientListAnalyticsPropsWithActionOrigin(
                    state,
                    request.patientListId,
                );

            const { name, isOwner, type } = getPatientListDetails(
                state,
                request.patientListId,
            );

            const response = await FlemingApi.deletePatientList(request);

            const deleteAttempted =
                isOwner || type === PatientListType.WorkspaceManaged;
            // Send analytics
            if (analyticsProps) {
                analyticsProps.pageOrigin = deleteActionOrigin;
                // Update the new countShare if the user is not the owner
                if (!analyticsProps.isOwner) {
                    analyticsProps.countShare = analyticsProps.countShare - 1;
                }
                if (response.success) {
                    analyticsProps.countUserList =
                        analyticsProps.countUserList - 1;
                }

                response.success
                    ? deleteAttempted
                        ? FlemingAnalyticsTracker.trackPatientListDeleteSuccess(
                              analyticsProps,
                          )
                        : FlemingAnalyticsTracker.trackPatientListRemoveSelfSuccess(
                              analyticsProps,
                          )
                    : deleteAttempted
                    ? FlemingAnalyticsTracker.trackPatientListDeleteFailure(
                          analyticsProps,
                      )
                    : FlemingAnalyticsTracker.trackPatientListRemoveSelfFailure(
                          analyticsProps,
                      );
            }

            if (response.success) {
                dispatch({
                    type: POST_DELETE_PATIENT_LIST_SUCCESS,
                    patientListId: request.patientListId,
                });

                toast(
                    Feedback({
                        colour: "success",
                        title: deleteAttempted
                            ? "List deleted"
                            : "You've removed yourself",
                        content: `${name} is no longer available${
                            deleteAttempted ? "" : " to you"
                        }.`,
                    }),
                );

                successCallback && successCallback();
                return;
            }

            const errorMessage =
                response.error ||
                `We were unable to ${
                    deleteAttempted ? "delete" : "remove you from"
                } this list, please try again.`; // Fallback error string for all uncaught errors (i.e. 500 server error)
            toast(
                Feedback({
                    colour: "error",
                    title: "Something went wrong",
                    content: errorMessage,
                }),
            );
            return dispatch({ type: POST_DELETE_PATIENT_LIST_FAILURE });
        },

    addPatientToListStarted,
    addPatientToListSuccess,
    addPatientToListFailure,

    setBulkAddPatientsToListLoadingState: (
        isLoading: boolean,
    ): PostBulkAddPatientsToListStartedAction => {
        return {
            type: POST_BULK_ADD_PATIENTS_TO_LIST_STARTED,
            isLoading,
        };
    },

    confirmBulkAddPatientsToList:
        (
            request: PatientListConfirmBulkAddPatientRequest,
            requestDurationCheckMs: number,
            successCallback?: () => void,
            failureCallback?: (error: string | null) => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            dispatch({
                type: POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_STARTED,
            });

            const state = getState();
            const analyticsProps = AnalyticsMapper.getPatientListAnalyticsProps(
                state,
                request.patientListId,
            );

            const requestStartTime = new Date().getTime();
            const response = await FlemingApi.confirmBulkAddPatientsToList(
                request,
            );
            const requestDurationConfirmMs =
                new Date().getTime() - requestStartTime;

            if (!response.success || response.result === null) {
                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListBulkAddPatientFailure(
                        {
                            ...analyticsProps,
                            requestDurationCheckMs,
                            requestDurationConfirmMs,
                            fileId: request.fileId,
                            failureReason:
                                response.error ||
                                "Unknown reason for upload failure",
                        },
                    );

                failureCallback && failureCallback(response.error);

                return dispatch({
                    type: POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_FAILURE,
                });
            }

            if (response.result !== null) {
                const { appointmentDays, countAdded, errors } = response.result;

                const countSuccessRow =
                    getPatientListEntries(appointmentDays).length;
                const countErrorRow = errors.length || 0;
                const countTotalRow = countSuccessRow + countErrorRow;

                // check the main response body first or if nothing is there check
                // the upload errors returned by the server to find out upload type
                const uploadType = getUploadTypeFromApiResult(response.result);

                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListBulkAddPatientSuccess(
                        {
                            ...analyticsProps,
                            listSize: analyticsProps.listSize + countAdded,
                            requestDurationCheckMs,
                            requestDurationConfirmMs,
                            countTotalRow,
                            countSuccessRow,
                            countErrorRow,
                            fileId: request.fileId,
                            uploadType,
                        },
                    );

                toast(
                    Feedback({
                        colour: "success",
                        title: "Upload successful",
                        content: `${countAdded} patient ${
                            countAdded === 1 ? "entry" : "entries"
                        } added to list`,
                    }),
                );

                dispatch({
                    type: POST_CONFIRM_BULK_ADD_PATIENTS_TO_LIST_SUCCESS,
                    ...response.result,
                });

                if (successCallback !== undefined) {
                    successCallback();
                }
            }
        },

    resetBulkAddPatientsErrors: () => ({
        type: RESET_BULK_ADD_PATIENTS_ERRORS,
    }),

    removePatientsFromList:
        (
            request: PatientListRemovePatientEntriesActionRequest,
            isBatch = false,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState): Promise<void> => {
            dispatch({
                type: POST_REMOVE_PATIENTS_FROM_LIST_STARTED,
                isBatch,
            });

            const state = getState();
            const analyticsProps = AnalyticsMapper.getPatientListAnalyticsProps(
                state,
                request.patientListId,
            );

            const apiRequest = {
                patientListId: request.patientListId,
                organisationId: request.organisationId,
                patientListEntryIds: request.patientListEntries.map(
                    (entry) => entry.id,
                ),
            };
            const response = await FlemingApi.removePatientEntriesFromList(
                apiRequest,
            );

            if (analyticsProps) {
                const patientIds = request.patientListEntries.map(
                    (entry) => entry.patientId,
                );

                const patients =
                    state.patientLists.currentList?.patientDetails || null;

                // are we operating on a test patient?
                let isTestPatient = false;
                if (patientIds.length > 0) {
                    const nhsNumber = getPatientFromPatientId(
                        patients,
                        patientIds[0],
                    )?.nhsNumber;

                    if (!!nhsNumber) {
                        isTestPatient = PatientHelper.isDemoPatient(nhsNumber);
                    }
                }

                if (response.success) {
                    const successProps = {
                        ...analyticsProps,
                        listSize:
                            analyticsProps.listSize -
                            request.patientListEntries.length,
                    };

                    // TODO - this ternary, and the similar one below, could prob
                    // be refactored into an encapsulating function
                    isBatch
                        ? FlemingAnalyticsTracker.trackPatientListBatchRemovePatientSuccess(
                              successProps,
                          )
                        : FlemingAnalyticsTracker.trackPatientListRemovePatientSuccess(
                              {
                                  ...successProps,
                                  isTestPatient: isTestPatient,
                              },
                          );
                } else {
                    isBatch
                        ? FlemingAnalyticsTracker.trackPatientListBatchRemovePatientFailure(
                              analyticsProps,
                          )
                        : FlemingAnalyticsTracker.trackPatientListRemovePatientFailure(
                              {
                                  ...analyticsProps,
                                  isTestPatient: isTestPatient,
                              },
                          );
                }
            }

            if (response.success) {
                let toastTitle;
                if (isBatch) {
                    toastTitle = `You successfully removed ${
                        request.patientListEntries?.length
                    } patient ${
                        request.patientListEntries?.length === 1
                            ? "entry"
                            : "entries"
                    }`;
                } else {
                    const patients =
                        state.patientLists.currentList?.patientDetails || null;
                    const removedPatient = request.patientListEntries[0];
                    const patientName =
                        getPatientFromPatientId(
                            patients,
                            removedPatient.patientId,
                        )?.displayName || "This patient entry";
                    const appointmentDateDisplay = removedPatient.dateTimeStart
                        ? ` for ${DateHelpers.formatTime(
                              removedPatient.dateTimeStart,
                              DateFormatOptions.TIME,
                          )} on ${DateHelpers.formatDate(
                              removedPatient.dateTimeStart,
                              DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                          )}`
                        : "";
                    toastTitle = `${patientName} has been removed${appointmentDateDisplay}`;
                }

                const successToast = Feedback({
                    colour: "success",
                    title: toastTitle,
                    content: "You can add them again at any time",
                });
                toast(successToast);

                return dispatch({
                    type: POST_REMOVE_PATIENTS_FROM_LIST_SUCCESS,
                    patientListId: apiRequest.patientListId,
                    patientListEntryIds: apiRequest.patientListEntryIds,
                });
            }

            const errorMessage =
                response.error ||
                `There was an error removing ${
                    isBatch ? "patients" : "the patient"
                } from the list`; // Fallback error string for all uncaught errors (i.e. 500 server error)
            const failureToast = Feedback({
                colour: "error",
                title: "Something went wrong",
                content: errorMessage,
            });
            toast(failureToast);

            return dispatch({
                type: POST_REMOVE_PATIENTS_FROM_LIST_FAILURE,
                error: errorMessage,
            });
        },

    changeOrderMethod: (order: OrderMethod) => ({
        type: CHANGE_ORDER_METHOD,
        order,
    }),

    resetCurrentList: () => ({
        type: RESET_CURRENT_LIST,
    }),

    resetCurrentListErrors: () => ({
        type: RESET_CURRENT_LIST_ERRORS,
    }),

    addCurrentListDuplicate: (duplicatePatient: PatientListAppointment) => ({
        type: ADD_CURRENT_LIST_DUPLICATE,
        duplicatePatient,
    }),

    removeCurrentListDuplicate: (duplicatePatient: PatientListAppointment) => ({
        type: REMOVE_CURRENT_LIST_DUPLICATE,
        duplicatePatient,
    }),

    setPatientListSearchedAction: (
        patientListId: number,
    ): SetPatientListSearchedAction => {
        return {
            type: SET_LATEST_PATIENT_LIST_SEARCHED,
            patientListId,
        };
    },

    setPatientListEntrySearchedAction: (
        patientListId: number,
        patientListAppointment: PatientListAppointment,
    ): SetPatientListEntrySearchedAction => {
        return {
            type: SET_LATEST_PATIENT_LIST_ENTRY_SEARCHED,
            patientListId: patientListId,
            patientListAppointment,
        };
    },

    checkPatientsVideoProgress:
        (consultationIds: string[]): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: POST_PATIENTS_VIDEO_PROGRESS_STARTED,
            });

            const response = await FlemingApi.checkPatientsVideoProgress({
                consultationIds,
            });
            if (response.success && response.result) {
                return dispatch({
                    type: POST_PATIENTS_VIDEO_PROGRESS_SUCCESS,
                    videoProgress: response.result.videoTrackingResponses,
                });
            }
            return dispatch({
                type: POST_PATIENTS_VIDEO_PROGRESS_FAILURE,
                error:
                    response.error ||
                    "Something went wrong while getting an update on the Video Consult status.",
            });
        },
};
