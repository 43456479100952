import { ConversationSummary } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { mapClinicianMessageItemToConversationItem } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapClinicianMessageItemToConversationItem";
import { mapConversationServerIdToConversationId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationServerIdToConversationId";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { Conversation, ConversationItem } from "domains/concierge/types";

const mapAssignee = (dto: ConversationSummary): Conversation["assignee"] => {
    if (dto.assignedUserId) {
        return { type: "User", id: dto.assignedUserId };
    }

    if (dto.assignedGroupId) {
        return { type: "Team", id: dto.assignedGroupId };
    }

    return { type: "None" };
};

const mapItems = (dto: ConversationSummary): ConversationItem[] => {
    return dto.items
        .map(mapClinicianMessageItemToConversationItem)
        .filter(isInstance);
};

export const mapConversationSummaryToConversation = (
    dto: ConversationSummary,
): Conversation | undefined => {
    const conversationId = mapConversationServerIdToConversationId(dto.id);

    if (dto.items.length === 0) {
        Log.error("Missing conversation items", {
            tags: { product: "Inbox", conversationId },
        });
        return undefined;
    }

    const items = mapItems(dto);

    if (items.length === 0) {
        Log.error("No valid conversation items", {
            tags: { product: "Inbox", conversationId },
        });
        return undefined;
    }

    return {
        id: conversationId,
        source: {
            system: "ClinicianMessaging",
            serverId: dto.id,
            rowVersion: dto.rowVersion,
        },
        assignee: mapAssignee(dto),
        status: dto.isArchived ? "Done" : "Open",
        isUrgent: false,
        regardingPatientId: dto.patientId ?? null,
        lastUpdated: dto.lastUpdated,
        latestToken: dto.lastUpdated,
        isFullyLoaded: false,
        items,
        participants: [],
    };
};
