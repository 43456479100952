import { useCallback, useEffect, useRef } from "react";

/**
 * Use this hook to check whether the component is still mounted
 *
 * _If used inside a useEffect_ `isMounted` **must** be in the list of dependencies
 *
 * ---
 * **Example usage**
 *
 * ```
 * const isMounted = useMountedState();
 *
 * useEffect(() => {
 *     if (isMounted()) {
 *         // do your set state in here
 *     }
 * }, [isMounted]);
 *
 * ```
 *
 *
 * ---
 *
 * @returns a function that when called will
 * return `true` if the component is mounted
 */
export const useMountedState = (): (() => boolean) => {
    const mountedRef = useRef(false);
    const isMounted = useCallback(() => mountedRef.current, []);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return isMounted;
};
