import { Button, Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

const Wrapper = styled(Flex).attrs(() => ({
    justifyContent: "flex-end",
    alignItems: "center",
}))`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[3]};
    }

    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

type BottomNavigationProps = {
    onContinue?: () => void;
    continueDisabled: boolean;
};

export const BottomNavigation = ({
    continueDisabled,
    onContinue,
}: BottomNavigationProps) => {
    return (
        <Wrapper>
            <Button
                theme="secondary"
                text="Continue"
                disabled={continueDisabled}
                icon={{
                    name: "ArrowTail",
                    rotation: "right",
                    placement: "end",
                    colour: "blue",
                }}
                onClick={onContinue}
            />
        </Wrapper>
    );
};
