import React, { Fragment } from "react";

import { Thread } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import Linkify from "linkify-react";

import { ClinicianMessage } from "../../../api/ClinicianConversationApi";
import {
    isPendingMessage,
    isSentByCurrentUser,
    mapAttachments,
} from "../clinicianConversation.helper";
import { CopyMessageFunction } from "../copyMessageWithContext";
import { MessagesByDate, PendingMessage, onRetryFunction } from "../types";
import { RelativeContainer } from "./Conversation.styles";

export type UserMessageProps = {
    userEmailAddress: string;
};

type MessageProps = UserMessageProps & {
    message: ClinicianMessage | PendingMessage;
    onCopyMessage: CopyMessageFunction;
    onClickRetry: onRetryFunction;
    attachmentIdToHighlight?: string;
    hasPolled: boolean;
};

export const Message = ({
    message,
    userEmailAddress,
    onCopyMessage,
    onClickRetry,
    attachmentIdToHighlight,
    hasPolled,
}: MessageProps) => {
    // TODO use the button - add a button, which on click calls `onClickRetry` with  message.tempMessageId
    const isPending = isPendingMessage(message);
    const sentByCurrentUser = isSentByCurrentUser(
        message.senderEmailAddress,
        userEmailAddress,
    );

    const isFailed = isPending && message.state === "failed";

    return (
        <RelativeContainer>
            <Thread.Message
                actionButtons={[
                    {
                        text: "Copy",
                        icon: { name: "Copy" },
                        providesFeedback: true,
                        onClick: async () =>
                            (await onCopyMessage({
                                ...message,
                                sentByCurrentUser,
                            }))
                                ? { text: "Copied" }
                                : { text: "Failed to copy" },
                    },
                ]}
                outerActionButtons={
                    isFailed
                        ? [
                              {
                                  text: "Retry",
                                  icon: { name: "Refresh" },
                                  onClick: () =>
                                      onClickRetry(message.tempMessageId),
                              },
                          ]
                        : []
                }
                inboxType={sentByCurrentUser ? "sent" : "received"}
                inProgressMessage={isPending ? "Sending" : undefined}
                delivered={!isPending}
                failed={isFailed}
                dateRead={
                    message.dateRead
                        ? DateHelpers.formatTime(message.dateRead, "hh:mm A")
                        : undefined
                }
                key={isPending ? message.tempMessageId : message.messageId}
                attachments={mapAttachments(
                    message.attachments,
                    hasPolled,
                    attachmentIdToHighlight,
                )}
                hiddenMessagePart={message.previousMessages}
                senderFullName={{
                    name: message.senderDisplayName
                        ? {
                              shouldDisplayFullName: true,
                              fullName: message.senderDisplayName,
                          }
                        : null,
                }}
                dateCreated={DateHelpers.formatTime(
                    message.dateSent,
                    DateFormatOptions.TIME_SHORT_12_HR,
                )}
            >
                <Linkify
                    options={{ target: "_blank", rel: "noopener noreferer" }}
                >
                    {message.body}
                </Linkify>
            </Thread.Message>
        </RelativeContainer>
    );
};

type MessageGroupByDateProps = UserMessageProps & {
    dateGroup: MessagesByDate;
    onCopyMessage: CopyMessageFunction;
    onClickRetry: onRetryFunction;
    attachmentIdToHighlight?: string;
    hasPolled: boolean;
};

export const MessageGroupByDate = ({
    dateGroup,
    userEmailAddress,
    onCopyMessage,
    onClickRetry,
    attachmentIdToHighlight,
    hasPolled,
}: MessageGroupByDateProps) => {
    return (
        <Fragment key={`date-group-${dateGroup.date}`}>
            <Thread.DateDivider date={dateGroup.date} />
            {dateGroup.messages.map((message) => (
                <Message
                    key={message.dateSent}
                    message={message}
                    userEmailAddress={userEmailAddress}
                    onCopyMessage={onCopyMessage}
                    onClickRetry={onClickRetry}
                    attachmentIdToHighlight={attachmentIdToHighlight}
                    hasPolled={hasPolled}
                />
            ))}
        </Fragment>
    );
};
