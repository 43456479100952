import { Dispatch, SetStateAction, useState } from "react";

import {
    Button,
    Ds,
    Flex,
    FormFieldWrapper,
    Input,
    Option,
    Select,
    Text,
} from "@accurx/design";
import {
    add,
    endOfToday,
    format,
    getHours,
    isAfter,
    isBefore,
    isToday,
    isValid,
    startOfToday,
} from "date-fns";

import { getWorkingHoursOptions } from "app/batchMessage/shared/getWorkingHoursOptions";

type ScheduleMessageModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onCancel(): void;
    onSchedule(date: Date, hour: number): void;
};

export const INVALID_DATE_MESSAGE = "Date must be up to 1 year in the future";
export const MISSING_TIME_MESSAGE = "Please choose a time";

export function ScheduleMessageModal({
    isOpen,
    setIsOpen,
    onCancel,
    onSchedule,
}: ScheduleMessageModalProps) {
    const dateInputId = "schedule-date";
    const timeInputId = "schedule-time";

    const today = startOfToday();
    const todayDateString = format(today, "yyyy-MM-dd");
    const oneYearFromToday = add(endOfToday(), { years: 1 });
    const [scheduleDateString, setScheduleDateString] =
        useState<string>(todayDateString);
    const [scheduleTime, setScheduleTime] = useState<Option | undefined>();
    const [isInvalidTime, setIsInvalidTime] = useState(false);
    const scheduleDate = new Date(scheduleDateString);
    const isInvalidDate =
        !isValid(scheduleDate) ||
        isBefore(scheduleDate, today) ||
        isAfter(scheduleDate, oneYearFromToday);

    const timeOptions = isToday(scheduleDate)
        ? getWorkingHoursOptions(getHours(Date.now()))
        : getWorkingHoursOptions();

    return (
        <Ds.Modal open={isOpen} onOpenChange={setIsOpen}>
            <Ds.Modal.Section>
                <Flex alignItems="center" justifyContent="space-between">
                    <Ds.Modal.Title>Schedule message</Ds.Modal.Title>
                    <Ds.Modal.CloseButton />
                </Flex>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Text>Select date and time to send the message</Text>
                <Ds.Grid columns="1fr 1fr" gap="3">
                    <FormFieldWrapper
                        label="Date"
                        labelProps={{ htmlFor: dateInputId }}
                        errors={isInvalidDate ? [INVALID_DATE_MESSAGE] : []}
                    >
                        <Input
                            id={dateInputId}
                            type="date"
                            value={scheduleDateString}
                            min={todayDateString}
                            max={format(oneYearFromToday, "yyyy-MM-dd")}
                            onChange={(event) => {
                                setScheduleDateString(event.target.value);
                            }}
                            hasErrors={isInvalidDate}
                            dimension="large"
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Time"
                        labelProps={{ htmlFor: timeInputId }}
                        errors={isInvalidTime ? [MISSING_TIME_MESSAGE] : []}
                    >
                        <Select
                            id={timeInputId}
                            options={timeOptions}
                            initSelectedValues={scheduleTime}
                            onChangeHandler={(selected) => {
                                setScheduleTime(selected as Option);
                                setIsInvalidTime(false);
                            }}
                            error={isInvalidTime}
                        />
                    </FormFieldWrapper>
                </Ds.Grid>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Flex alignItems="center" justifyContent="space-between">
                    <Button
                        text="Cancel"
                        theme="secondary"
                        onClick={onCancel}
                    />
                    <Button
                        text="Schedule message"
                        theme="primary"
                        onClick={() => {
                            if (!scheduleTime) {
                                setIsInvalidTime(true);
                                return;
                            }

                            if (!isInvalidDate) {
                                onSchedule(
                                    scheduleDate,
                                    parseInt(scheduleTime.value, 10),
                                );
                            }
                        }}
                    />
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
}
