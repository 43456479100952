import { FeatureName, useAuth } from "@accurx/auth";
import { TypedUseSelectorHook, shallowEqual, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { ONBOARDING_ROUTES } from "app/guardedRoutes/routes/OnboardingRoutesSection";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES } from "shared/Routes";

import { IOrganisation } from "../api/FlemingDtos";

// Use throughout your app instead of plain `useSelector`
export const useAppSelector: TypedUseSelectorHook<ApplicationState> =
    useSelector;

type featureFlagType = keyof typeof FeatureName;

/**
 * @deprecated This hook has been deprecated. If you have a workspaceId in the URL, please use useFeatureFlag hook from @accurx/auth in src/domains/auth/. Otherwise, use useFeatureFlag hook in src/reduxQuarantine/ which will get the workspaceId from state.
 */
export const useIsFeatureEnabled = (
    featureFlagName: featureFlagType,
): boolean => {
    const accountState = useAppSelector(({ account }) => account);
    const organisation = OrganisationHelper.getOrganisation(accountState);
    const isFeatureEnabled = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName[featureFlagName],
    );
    return isFeatureEnabled;
};

export type OrgFeatureFlagStatus = {
    name: string;
    id: number;
    featureFlagEnabled: boolean;
};
export type OrgsWithFeatureFlagStatus = {
    orgsWithFeatureFlagEnabled: OrgFeatureFlagStatus[];
    orgsWithFeatureFlagDisabled: OrgFeatureFlagStatus[];
};
/**
 * @param featureFlagName
 * @returns an object with two arrays, one with user's orgs that have the feature flag enabled, one with orgs that have it disabled
 */
export const useIsFeatureEnabledForUserOrgs = (
    featureFlagName: featureFlagType,
): OrgsWithFeatureFlagStatus => {
    const organisations = useAppSelector(
        ({ account }) => account?.user?.organisations,
    );
    if (!organisations || organisations.length === 0) {
        return {
            orgsWithFeatureFlagEnabled: [],
            orgsWithFeatureFlagDisabled: [],
        };
    }

    const orgList = organisations.map(
        (org) =>
            org && {
                featureFlagEnabled: OrganisationHelper.isFeatureEnabled(
                    org,
                    FeatureName[featureFlagName],
                ),
                name: org.organisationName,
                id: org.orgId,
            },
    );
    return {
        orgsWithFeatureFlagEnabled: orgList.filter(
            (org) => org.featureFlagEnabled === true,
        ),
        orgsWithFeatureFlagDisabled: orgList.filter(
            (org) => org.featureFlagEnabled === false,
        ),
    };
};

export const useIsFeatureEnabledForPatient = (
    featureFlagName: FeatureName,
): boolean => {
    const patient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    return PatientHelper.isFeatureEnabled(patient, featureFlagName);
};

export const useIsFeatureEnabledForOrganisationAndPatient = (
    featureFlagName: FeatureName,
): boolean => {
    const enabledForOrg = useIsFeatureEnabled(featureFlagName);
    const enabledForPatient = useIsFeatureEnabledForPatient(featureFlagName);

    return enabledForOrg && enabledForPatient;
};

/* A hook to fetch the currently selected workspace  */
export const useCurrentWorkspace = (): IOrganisation | null => {
    return useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );
};

/* A hook to fetch the current organisationId from state (this will be the workspace ID if you're in a workspace) */
export const useCurrentOrgId = (): number | null => {
    const orgId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );
    return orgId;
};

/** A hook to fetch the current Organisation name */
export const useCurrentOrgName = (): string => {
    return useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
};

/* A hook to fetch the current user's email address from state */
export const useCurrentUserEmailAddress = (): string | undefined => {
    const account = useAppSelector(({ account }) => account, shallowEqual);
    return account.user?.email;
};

/* A hook to fetch the current user's name from state */
export const useCurrentUserFullName = (): string | undefined => {
    const account = useAppSelector(({ account }) => account, shallowEqual);
    return account.user?.fullName;
};

/* A hook to fetch the current userId from state
 * Note: the userId is not expected to change during the lifecycle
 * of the application. This is just a shortcut helper function */
export const useCurrentUserId = (): string | undefined => {
    const currentUserId = useAppSelector(
        ({ account }) => account.user?.accuRxUserId,
    );
    return currentUserId;
};

/*
 * A hook to fetch the current approval status of the logged in user
 * Note: the approval status of the user is not expected to
 * change during the lifecycle
 * of the application. This is just a shortcut helper function
 * */
export const useIsUserApproved = (): boolean => {
    const isUserApproved = useAppSelector(({ account }) =>
        OrganisationHelper.getIsApprovedOrgUser(account),
    );
    return isUserApproved;
};

/*
 * Whether to use the simplified version of the navigation. We do this if:
 *   - The user is not logged in
 *   - OR it is a known route where we want simplified navigation
 */

export const useSimplifiedNavigation = (): boolean => {
    const { user } = useAuth();
    const isLoggedIn = user.isLoggedIn;
    const isRouteWithSimpleNav =
        useRouteMatch({
            path: [
                ...ONBOARDING_ROUTES,
                ROUTES.get_started,
                ROUTES.sso_failed,
                ROUTES.noOdsCode,
                ROUTES.unableToLookupOds,
                ROUTES.confirmEmailVerify,
                ROUTES.userSignoutConfirm,
            ],
        })?.isExact ?? false;

    return !isLoggedIn || isRouteWithSimpleNav;
};

export const useHasAcceptedCookies = (): boolean => {
    const { user } = useAuth();

    if (user.isLoggedIn) {
        return !!user.onboarding?.hasAcceptedCookies;
    }

    return false;
};
