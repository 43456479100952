import { Tokens } from "@accurx/design";
import { Dictionary } from "highcharts";
import groupBy from "lodash/groupBy";
import moment from "moment";

import { SeriesColumn } from "app/sharedComponents/graph/Graph.types";

import { DashboardData } from "../../../types";
import { RequestSource, RequestType } from "../shared/shared.constants";
import {
    getStartAndEndOfCurrentWeek,
    getStartAndEndOfPreviousWeek,
} from "../shared/shared.utils";

/**
 * Take in an array of the requests for a week
 * count the number of requests for each day and
 * return an array with the number of requests
 * for each day.
 * @param lastWeekData
 * @param filterRequestType
 * @param filterRequestSource
 * @returns number[]
 */
const aggregateToWeekDay = (
    lastWeekData: Dictionary<DashboardData[]>,
    filterRequestType: string,
    filterRequestSource: string,
): Array<number> => {
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    return days.map((day) => {
        if (lastWeekData[day]) {
            return lastWeekData[day].filter(
                (request) =>
                    (filterRequestType === RequestType.MedicalAndAdmin ||
                        request.type === filterRequestType) &&
                    (filterRequestSource ===
                        RequestSource.PatientsAndReception ||
                        (filterRequestSource === RequestSource.Reception
                            ? request.isReception
                            : !request.isReception)),
            ).length;
        } else {
            return 0;
        }
    });
};

/**
 * This function checks the dashboard data. Splits the data by week number objects
 * returning an array of two obejcts, one for the last week and the second for the current week
 * It also returns a boolean to show if the data sets are all zero values
 */
export const aggregateDataForRequestsByDayGraph = (
    triageRequests: DashboardData[],
    filterRequestType: string,
    filterRequestSource: string,
): {
    aggregatedData: SeriesColumn[];
    isEmptyDataSet: boolean;
} => {
    const aggregatedData: SeriesColumn[] = [];

    const [previousWeekStart, previousWeekEnd] = getStartAndEndOfPreviousWeek();
    const [currentWeekStart, currentWeekEnd] = getStartAndEndOfCurrentWeek();
    const lastWeekRequests = triageRequests.filter((request) =>
        moment(request.date).isBetween(
            previousWeekStart,
            previousWeekEnd,
            "days",
            "[]",
        ),
    );
    const currentWeekRequests = triageRequests.filter((request) =>
        moment(request.date).isBetween(
            currentWeekStart,
            currentWeekEnd,
            "days",
            "[]",
        ),
    );

    const lastWeekData = groupBy(lastWeekRequests, (dt) =>
        moment(dt.date).format("dddd"),
    );

    const lastWeekDayDataArray = aggregateToWeekDay(
        lastWeekData,
        filterRequestType,
        filterRequestSource,
    );
    aggregatedData.push({
        name: "Last week",
        pointWidth: 32,
        borderWidth: 2,
        color: Tokens.COLOURS.primary.blue[10],
        borderColor: Tokens.COLOURS.primary.blue[75],
        data: lastWeekDayDataArray,
    });

    const currentWeekData = groupBy(currentWeekRequests, (dt) =>
        moment(dt.date).format("dddd"),
    );

    const currentWeekDayDataArray = aggregateToWeekDay(
        currentWeekData,
        filterRequestType,
        filterRequestSource,
    );
    aggregatedData.push({
        name: "Current week",
        pointWidth: 32,
        borderWidth: 2,
        color: Tokens.COLOURS.primary.blue[75],
        borderColor: Tokens.COLOURS.primary.blue[75],
        data: currentWeekDayDataArray,
    });
    const isEmptyDataSet =
        Math.max(...currentWeekDayDataArray) === 0 &&
        Math.max(...lastWeekDayDataArray) === 0;

    return {
        aggregatedData,
        isEmptyDataSet,
    };
};
