import React, { ReactNode } from "react";

const imageFiles = import.meta.glob("./**/*.{jpg,png,svg}", {
    eager: true,
    as: "url",
});

const accurxWebImages = createImageDictionary(imageFiles);

/**
 * The Accurx Web image function
 *
 * Use this for retrieving an image URL, to supply to <img>. The function will
 * only accept references to *.{jpg,png,svg} images that exist in the src/images
 * directory.
 */
export const i = (image: ImageFiles) => {
    const foundImage = accurxWebImages[image] ?? "";

    if (!foundImage) {
        throw new Error(
            `Image not found: Attempted to render the image ${image} but it could not be found. Did you forget to add an image file in "src/images"? The images available in the app are:

${Object.keys(accurxWebImages).join("\n")}`,
        );
    }

    return foundImage;
};

// private

type Module = {
    __esModule: true;
    default: string;
    ReactComponent: { children: ReactNode } & React.ComponentProps<"svg">;
};

// despite the glob funciton saying it's returning a Record<string, string>,
// *i think* that .svg images are being transformed by SVGR, so they are coming
// through as the shape described by the Module type above. Forcing the argument
// type here ensures that we can produce the string values correctly
function createImageDictionary(images: Record<string, string | Module>) {
    const imageDictionary: Partial<Record<ImageFiles, string>> = {};

    for (const path in images) {
        const normalisedPath = path.replace("./", "") as ImageFiles;
        const img = images[path];

        imageDictionary[normalisedPath] = isSVG(img) ? img.default : img;
    }

    return imageDictionary;
}

function isSVG(img: string | Module): img is Module {
    return typeof img !== "string";
}
