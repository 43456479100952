import { Feedback, Text } from "@accurx/design";

import { useCurrentOrgName, useIsUserApproved } from "store/hooks";

export default function EmptyState({ searchQuery }: { searchQuery: string }) {
    const orgName = useCurrentOrgName();
    const isUserApproved = useIsUserApproved();

    if (!searchQuery) {
        return (
            <Feedback colour="information" iconName="None">
                <Text skinny>
                    {isUserApproved
                        ? `Recently searched patients for ${orgName} will appear here`
                        : "Your recently searched patients will appear here"}
                </Text>
            </Feedback>
        );
    }

    return (
        <Feedback
            colour="information"
            iconName="None"
            title={`We can’t find ‘${searchQuery}’`}
        >
            <Text skinny>
                {isUserApproved
                    ? `We searched for all patients who have been searched by members of ${orgName} in the last 12 months`
                    : "We searched all your recent patients in the last 12 months"}
            </Text>
        </Feedback>
    );
}
