import { Patient } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { shallowEqual } from "react-redux";

import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { isMessageGpDisabled } from "./PatientProfile.helper";

type MessagePracticeAvailabilityWithPatientData =
    | {
          canMessageGp: true;
          patient: Patient;
      }
    | {
          canMessageGp: false;
          patient?: Patient;
      };

export const useMessagePracticeAvailabilityWithPatientData =
    (): MessagePracticeAvailabilityWithPatientData => {
        const patient = useAppSelector(
            ({ searchForPatient }) =>
                PatientHelper.getPatient(searchForPatient.lastResponse),
            shallowEqual,
        );
        const enabledForUser = useIsFeatureEnabled(
            FeatureName.CaregiverInitiated,
        );

        if (patient === null) {
            return {
                canMessageGp: false,
            };
        }

        const enabledForPatient = isMessageGpDisabled(patient) === null;

        const canMessageGp = enabledForUser && enabledForPatient;

        return { canMessageGp, patient };
    };
