import React from "react";

import { Stepper, Text } from "@accurx/design";

export interface HeaderProps {
    stepNumber?: number;
    totalSteps?: number;
    title: string;
}

export const VaccineDeliveryHeader = ({
    stepNumber,
    totalSteps = 2,
    title,
}: HeaderProps): JSX.Element => (
    <>
        {stepNumber && (
            <Stepper currentStep={stepNumber} totalSteps={totalSteps} />
        )}
        <Text as="h1" variant="title" props={{ className: "text-center" }}>
            {title}
        </Text>
    </>
);
