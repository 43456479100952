/* eslint-disable -- ~linting bankruptcy~
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * Leaving this eslint-disable comment in place as this code will go
 * away soon once we have completely moved to the new concierge layer.
 */
import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useGlobalConversationManager } from "shared/concierge/conversations/context/GlobalConversationManagerContext";
import { ConversationManager } from "shared/concierge/conversations/types/component.types";

const SelectedGlobalOrganisationConversationManagerContext =
    createContext<ConversationManager | null>(null);

type ProviderProps = {
    workspaceId: number | null;
    is2FAed: boolean;
    children: ReactNode;
};

/**
 * SelectedGlobalOrganisationConversationManagerProvider
 *
 * Unlike `ConversationManagerProvider`, children are not guaranteed to have access as
 * the global state must be set to a workspace with the relevant feature flag
 * enabled, plus requires 2FA at this time.
 */
export const SelectedGlobalOrganisationConversationManagerProvider = ({
    workspaceId,
    is2FAed,
    children,
}: ProviderProps): JSX.Element => {
    const newInboxEnabled = useFeatureFlag("AccurxWebEmbeddedUnifiedInbox");
    const globalConversationManager = useGlobalConversationManager();
    const [manager, setManager] = useState<ConversationManager | null>(null);

    useEffect(() => {
        if (
            newInboxEnabled ||
            !Number.isSafeInteger(workspaceId) ||
            !is2FAed ||
            !globalConversationManager
        ) {
            setManager(null);
            return;
        }

        const nextManager = globalConversationManager.forWorkspace(
            workspaceId!,
        );
        setManager(nextManager.item);
        return () => nextManager.release();
    }, [is2FAed, newInboxEnabled, workspaceId, globalConversationManager]);

    return (
        <SelectedGlobalOrganisationConversationManagerContext.Provider
            value={manager}
        >
            {children}
        </SelectedGlobalOrganisationConversationManagerContext.Provider>
    );
};

/**
 * Provides a conversation manager instance for the currently selected workspace defined at a global level,
 * as stored in redux, if supporting features are enabled.
 *
 * Please prefer explicit routes and `useConversationManager` to avoid the need to deal with
 * dynamic changes here.
 *
 * @returns The conversation manager or null if the currently selected organisation (if any) does not
 * have the necessary features to support it.
 */
export const useSelectedGlobalOrganisationConversationManager =
    (): ConversationManager | null =>
        useContext(SelectedGlobalOrganisationConversationManagerContext);

/**
 * Export the context provider directly so that our UI tests can easily
 * wrap a component with a fake conversation manager;
 */
export const StaticSelectedGlobalOrganisationConversationManagerProvider =
    SelectedGlobalOrganisationConversationManagerContext.Provider;
