import { useState } from "react";

import * as UI from "@accurx/design";
import { PendingApprovalModal } from "@accurx/workspace-management";
import { useSidebarFolderClickAnalytics } from "domains/inbox/hooks/util/useSidebarFolderClickAnalytics";
import styled from "styled-components";

import { LockedInboxFeature } from "./LockedInboxFeature";

const SmallVerticalSpacer = styled.div`
    height: ${UI.Tokens.SIZES[0.5]};
    min-height: ${UI.Tokens.SIZES[0.5]};
`;

export const LockedInboxFeaturesSection = () => {
    const trackFolderClick = useSidebarFolderClickAnalytics();

    const [showPendingApprovalModal, setShowPendingApprovalModal] =
        useState(false);

    const handleFolderClick = (folderName: string) => {
        setShowPendingApprovalModal(true);
        trackFolderClick({
            folderName,
            isPendingApproval: true,
            conversationParticipant: "WithPatient",
        });
    };

    return (
        <UI.Flex flexDirection="column" gap="1">
            <LockedInboxFeature
                onClick={() => handleFolderClick("All conversations")}
                isAccordion
                aria-label="All conversations - locked"
                text="All conversations"
                icon={{ name: "Messages" }}
            />
            <SmallVerticalSpacer />
            <LockedInboxFeature
                onClick={() => handleFolderClick("Teams")}
                aria-label="Teams - locked"
                text="Teams"
            />
            <LockedInboxFeature
                onClick={() => handleFolderClick("Colleagues")}
                aria-label="Colleagues - locked"
                text="Colleagues"
            />

            <PendingApprovalModal
                open={showPendingApprovalModal}
                onOpenChange={setShowPendingApprovalModal}
                appOrigin="LockedInboxFeature"
            />
        </UI.Flex>
    );
};
