import { Log } from "@accurx/shared";
import { generatePath, useHistory } from "react-router-dom";

import { ROUTES, ROUTES_PRIMARY } from "shared/Routes";
import { useCurrentOrgId } from "store/hooks";

/**
 * Handles the possibility of the orgId in the global Redux store being null.
 * Returns a function that when called with a path will navigate to
 * /w/:orgId/:path. Redirects to ROUTES.home if orgId is null.
 */
export const useNavigateToWorkspaceRoute = () => {
    const history = useHistory();
    const currentOrgId = useCurrentOrgId();

    const navigateToWorkspaceRoute = (path: string) => {
        if (!currentOrgId) {
            Log.warn("Org ID from Redux store is null. Redirecting to '/'");

            history.push(ROUTES.home);
            return;
        }

        const workspaceRouteBase = generatePath(ROUTES_PRIMARY.workspaceBase, {
            workspaceId: currentOrgId,
        });

        history.push(workspaceRouteBase + path);
    };

    return { navigateToWorkspaceRoute };
};
