import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useTeamConversationGroupQuery } from "@accurx/concierge/hooks/queries/useTeamConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import {
    ConversationFeed,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { NotificationsDropdown } from "domains/inbox/components/NotificationsDropdown/NotificationsDropdown";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import {
    StyledAccessibleHeader,
    StyledConversationList,
    StyledHeaderTitle,
    StyledHeaderWrap,
} from "../styles";

/**
 * Notes on existing desktop behaviour:
 *
 * Currently the questionnaire/florey views are identical to the team assignment-based views - the
 * only difference is the section grouping, and the source for the group as it's
 * automatically created on first use of the relevant florey. By default when
 * a patient Florey response comes in, it will land in the default group according
 * to its configuration.
 */
export const Questionnaires = () => {
    const { teamId, status } = useInboxParams(["teamId", "status"]);
    const link = useInboxLink();
    const team = useTeam({ teamId });

    const groupQuery = useTeamConversationGroupQuery({
        teamId,
        status,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        team
            ? `${team.displayName.toLowerCase()} ${status.toLowerCase()} questionnaire conversations`
            : "",
    );

    if (!team) return null;

    return (
        <StyledConversationList rows="auto 1fr">
            <StyledHeaderWrap>
                <ConversationListHeader
                    heading={
                        <StyledHeaderTitle>
                            {team.displayName}
                            <StyledAccessibleHeader>
                                showing open messages
                            </StyledAccessibleHeader>
                        </StyledHeaderTitle>
                    }
                    notifications={<NotificationsDropdown team={team} />}
                />

                <ConversationTabs>
                    <ConversationTabItem
                        active={status === "Open"}
                        link={link.update({
                            status: "Open",
                            conversationId: null,
                        })}
                        userflowId={userflowIds.conversationList.openTab}
                        onClick={() =>
                            trackTabClick({
                                currentTab: "Done",
                                newTab: "Open",
                                conversationParticipant: "WithPatient",
                            })
                        }
                    >
                        Open
                    </ConversationTabItem>

                    <ConversationTabItem
                        active={status === "Done"}
                        link={link.update({
                            status: "Done",
                            conversationId: null,
                        })}
                        userflowId={userflowIds.conversationList.doneTab}
                        onClick={() =>
                            trackTabClick({
                                currentTab: "Open",
                                newTab: "Done",
                                conversationParticipant: "WithPatient",
                            })
                        }
                    >
                        Done
                    </ConversationTabItem>
                </ConversationTabs>
            </StyledHeaderWrap>

            <ConversationFeed
                key={groupQuery.data?.id}
                isLoading={groupQuery.status === "loading"}
                isError={groupQuery.status === "error"}
                refetch={groupQuery.refetch}
                fetchMore={groupQuery.fetchMore}
                isFetchingMore={groupQuery.isFetchingMore}
                isFullyLoaded={groupQuery.data?.isFullyLoaded}
                members={groupQuery.data?.members}
                filters={groupQuery.data?.filters}
                renderItem={(conversation, state) => (
                    <ConversationPreview
                        conversation={conversation}
                        item={getMostRecentItem(conversation)}
                        state={state}
                    />
                )}
            />
        </StyledConversationList>
    );
};
