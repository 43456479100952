import { useCurrentWorkspace } from "@accurx/auth";
import {
    AddPatientsViaFileUpload,
    BATCH_MESSAGING_ROUTES,
} from "@accurx/batch-messaging";
import { generatePath, useHistory } from "react-router";

import { BatchMessagePageLayout } from "../../components/BatchMessagePageLayout";

export const AddPatientsViaFileUploadPage = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const history = useHistory();

    return (
        <BatchMessagePageLayout pageTitle="Upload a list of patients">
            <AddPatientsViaFileUpload
                isNHSAppEnabled={false}
                isEmailEnabled
                isTrustFlow
                onUploadSuccess={(batchMessageId) => {
                    history.push(
                        generatePath(
                            BATCH_MESSAGING_ROUTES.demographicsReview,
                            {
                                workspaceId,
                                batchId: batchMessageId,
                            },
                        ),
                    );
                }}
            />
        </BatchMessagePageLayout>
    );
};
