import { ReactNode } from "react";

import { ConversationManagerProvider } from "shared/concierge/conversations/context/ConversationManagerContext";
import { PatientManagerProvider } from "shared/concierge/patients/context/PatientManagerContext";
import { UsersAndTeamsManagerProvider } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";

export const AccurxWebProviders = ({
    workspaceId,
    is2FAed,
    children,
}: {
    workspaceId: number | null;
    is2FAed: boolean;
    children: ReactNode;
}) => {
    return (
        <PatientManagerProvider workspaceId={workspaceId}>
            <UsersAndTeamsManagerProvider workspaceId={workspaceId}>
                <ConversationManagerProvider
                    workspaceId={workspaceId}
                    is2FAed={is2FAed}
                >
                    {children}
                </ConversationManagerProvider>
            </UsersAndTeamsManagerProvider>
        </PatientManagerProvider>
    );
};
