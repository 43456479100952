import { IRetryPolicy } from "@microsoft/signalr";

/**
 * This policy determines how regularly the HubClient should try to re-establish
 * its SignalR connection when it becomes disconnected.
 *
 * We start by trying again immediately and then with an exponential backoff
 * until a maximum gap of 30 minutes.
 *
 * We will then keep trying at a regular 30 minute interval.
 */
export const RECONNECT_ATTEMPTS = [
    0, // immediately
    2_000, // 2 seconds
    10_000, // 10 seconds
    30_000, // 30 seconds
    60_000, // 1 minutes
    120_000, // 2 minutes
    300_000, // 5 minutes
    600_000, // 10 minutes
    1_200_000, // 20 minutes
    1_800_000, // 30 minutes
];

export const hubClientRetryPolicy: IRetryPolicy = {
    nextRetryDelayInMilliseconds: (context): number => {
        if (context.previousRetryCount >= RECONNECT_ATTEMPTS.length) {
            return RECONNECT_ATTEMPTS[RECONNECT_ATTEMPTS.length - 1];
        }

        return RECONNECT_ATTEMPTS[context.previousRetryCount];
    },
};
