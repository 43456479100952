import React, { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";

import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { actionCreators } from "app/patientLists/PatientListsActions";
import { ROUTES_BASE } from "shared/Routes";
import { getSinglePatientListRoute } from "shared/RoutesHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    SecondaryNavigationContent,
    SecondaryNavigationHeader,
} from "./SecondaryNavigation";
import {
    StyledContainer,
    StyledLinkList,
    StyledPatientLinkList,
} from "./SecondaryNavigation.styles";

const PatientsNavigation = ({
    onClick,
}: {
    onClick: () => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const integratedClinicsEnabled = useIsFeatureEnabled(
        FeatureName.IntegratedClinicLists,
    );

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const allListSummaries = useAppSelector(
        ({ patientLists }) => patientLists.allListSummaries || [],
    );

    useEffect(() => {
        if (organisationId) {
            dispatch(
                actionCreators.getAllUserPatientListSummaries({
                    organisationId,
                }),
            );
        }
    }, [dispatch, organisationId]);

    const handlePatientListLinkClick = (patientListId: number): void => {
        dispatch(actionCreators.setPatientListSearchedAction(patientListId));
    };

    const patientListLinks = allListSummaries.map((summary) => (
        <li key={summary.patientListId}>
            <NavLink
                to={getSinglePatientListRoute(summary.patientListId.toString())}
                text={summary.name}
                variant={LinkVariant.Tertiary}
                onClick={() => {
                    onClick();
                    handlePatientListLinkClick(summary.patientListId);
                }}
                icon="List"
                customAnalyticsProps={{
                    navigationOptionSelected: "CustomPatientList",
                }}
            />
        </li>
    ));

    return (
        <StyledContainer>
            <SecondaryNavigationHeader>Patients</SecondaryNavigationHeader>
            <SecondaryNavigationContent>
                <StyledLinkList>
                    <li>
                        <NavLink
                            to={ROUTES_BASE.patientsSearch}
                            text="Patient search"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                        />
                    </li>
                    {organisationId && integratedClinicsEnabled && (
                        <li>
                            <NavLink
                                to={generatePath(
                                    ROUTES_BASE.integratedClinicListsAll,
                                    { workspaceId: organisationId },
                                )}
                                text="Clinics"
                                variant={LinkVariant.Secondary}
                                onClick={onClick}
                                userflowId="integrated-clinic-lists"
                            />
                        </li>
                    )}
                    <li>
                        <NavLink
                            to={ROUTES_BASE.patientsListsAll}
                            text="Patient lists"
                            variant={LinkVariant.Secondary}
                            onClick={onClick}
                            userflowId="patient-lists"
                        />
                    </li>
                </StyledLinkList>
                <StyledPatientLinkList>
                    {patientListLinks}
                </StyledPatientLinkList>
            </SecondaryNavigationContent>
        </StyledContainer>
    );
};

export { PatientsNavigation };
