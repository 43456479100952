import React from "react";

import { StyledTwoThirdsContainer } from "./TwoThirdsContainer.styles";

const TwoThirdsContainer = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}): JSX.Element => {
    return (
        <StyledTwoThirdsContainer className={className}>
            {children}
        </StyledTwoThirdsContainer>
    );
};

export { TwoThirdsContainer };
