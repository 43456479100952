import type { IUserResponse } from "api/FlemingDtos";
import { LoadingStatus } from "shared/LoadingStatus";

export type AccountState = {
    user: IUserResponse | null;
    isLoggedIn: boolean | null;
    selectedOrganisation: number | null;
    isInOrganisation: boolean;
    hasOrganisationEverLoaded: boolean;
    isOrganisationsLoading: LoadingStatus;
    isSettingUpOrganisation: boolean;
    lastOrganisationSetupFailed: boolean;
    isSubmittingOrganisationRequestForm: boolean;
} & UserProfileState;

export enum UserProfileStatus {
    Initial = "Initial",
    Loading = "Loading",
    Successful = "Successful",
    Errored = "Errored",
}

export type UserProfileState = {
    userProfileStatus: UserProfileStatus;
    userProfileError: string;
    healthcareRoles: string[] | null;
    specialistAreas: string[] | null;
};
