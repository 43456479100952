import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { mutatePersistAttachmentUsage } from "domains/native/api/endpoints/mutatePersistAttachmentUsage";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

export const usePersistAttachmentUsageMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        void
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async () => {
        if (!transport) {
            throw new NativeTransportError(
                "PersistAttachmentUsageMutation: Called with no native transport",
            );
        }

        await mutatePersistAttachmentUsage({
            transport,
            workspaceId,
        });
    }, options);
};
