import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { AnalyticsHelpers, Event, EventProperties } from "./useAnalytics.types";

const track = <E extends Event>(event: E, args: EventProperties<E>): void => {
    /*eslint import/namespace: ['error', { allowComputed: true }]*/
    const fn = FlemingAnalyticsTracker[`track${event}`] as (
        args: EventProperties<E>,
    ) => void;
    fn(args);
};

export const useAnalytics = (): AnalyticsHelpers => {
    const baseProps = useFlemingLoggedInAnalytics();
    return {
        baseProps,
        track: (event, props) => track(event, { ...baseProps, ...props }),
    };
};
