import { ChangeEvent, ClipboardEvent } from "react";

import { Input } from "@accurx/design";

/* eslint-disable import/no-duplicates */
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

/* eslint-enable import/no-duplicates */
import { StyledFormFieldWrapper } from "./SearchForPatientByNHSNumber.styles";

export type DateInputValue = {
    day: string;
    month: string;
    year: string;
};

const maxLengths = {
    day: 2,
    month: 2,
    year: 4,
};

const dateOfBirthFormats = [
    "d M yyyy",
    "d.M.yyyy",
    "d-M-yyyy",
    "d/M/yyyy",
    "d-MMM-yyyy",
    "d-MMMM-yyyy",
    "d MMM yyyy",
    "d MMMM yyyy",
];

const parseDate = (text: string): Date | null => {
    for (const format of dateOfBirthFormats) {
        const date = parse(text, format, new Date());

        if (isValid(date)) {
            return date;
        }
    }

    return null;
};

export type DateInputProps = {
    onChange: (value: DateInputValue) => void;
    value: DateInputValue;
    label?: string;
    subLabel?: string;
    error?: string;
    disabled?: boolean;
    required?: boolean;
};

export const DateInput = (props: DateInputProps): JSX.Element => {
    const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const text = event.clipboardData.getData("text");
        const validDate = parseDate(text);

        if (!validDate) {
            return;
        }

        // Prevent full date being pasted in to current input
        // Careful! This behaviour cannot be tested until we upgrade to @testing-library/user-event v14
        event.preventDefault();

        props.onChange({
            day: validDate.getDate().toString(),
            month: (validDate.getMonth() + 1).toString(),
            year: validDate.getFullYear().toString(),
        });
    };

    const handleInput =
        (name: keyof DateInputValue) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length <= maxLengths[name]) {
                props.onChange({
                    ...props.value,
                    [name]: event.target.value,
                });
            }
        };

    const a11yLabel = props.label || "Date";

    return (
        <StyledFormFieldWrapper
            label={props.label}
            subLabel={props.subLabel}
            grouped
            errors={props.error ? [props.error] : undefined}
            labelProps={{
                htmlFor: `${props.label}-date-input`,
                skinny: true,
            }}
        >
            <Input
                id={`${props.label}-date-input`}
                placeholder="DD"
                value={props.value.day}
                type="number"
                pattern="[0-9]*"
                min={1}
                max={31}
                onChange={handleInput("day")}
                onPaste={onPaste}
                aria-label={`${a11yLabel} day`}
                disabled={props.disabled}
                required={props.required}
            />
            <Input
                placeholder="MM"
                value={props.value.month}
                type="number"
                pattern="[0-9]*"
                min={1}
                max={12}
                onChange={handleInput("month")}
                aria-label={`${a11yLabel} month`}
                disabled={props.disabled}
                required={props.required}
            />
            <Input
                placeholder="YYYY"
                value={props.value.year}
                type="number"
                pattern="[0-9]*"
                min={1900}
                max={new Date().getFullYear()}
                onChange={handleInput("year")}
                aria-label={`${a11yLabel} year`}
                disabled={props.disabled}
                required={props.required}
            />
        </StyledFormFieldWrapper>
    );
};
