import { MultiSelectOption } from "../types";

export const formatSelectedInputObjects = (
    selectedArr: string[],
    allArr: string[],
): MultiSelectOption[] => {
    return selectedArr.map((item) => {
        const originalIndex = allArr.findIndex((i) => i === item);
        return {
            value: `${item.toLowerCase().replace(/ /g, "-")}-${originalIndex}`,
            label: item,
            disabled: false,
        };
    });
};
