/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { Dispatch } from "redux";

import {
    deleteVaccineSiteSession,
    getVaccineSiteSetup,
    getVaccineSiteSetupOds,
    getVaccineSiteSetupSessions,
    postVaccineSiteSetupLink,
    postVaccineSiteSetupLocation,
    postVaccineSiteSetupPublish,
    postVaccineSiteSetupSession,
    postVaccineSiteSetupUnpublish,
} from "api/VaccineSiteSetupApi";
import { Actions, ExtendedActions } from "app/practices/Practices.actions";

import {
    VaccineSiteLink,
    VaccineSiteSession,
    VaccineSiteSetup,
    VaccineSiteSetupLinksRequest,
    VaccineSiteSetupLocationRequest,
} from "./SiteSetup.types";

// -----------------
// ACTION TYPES
// -----------------
export const GET_SITE_SETUP_STARTED = "GET_SITE_SETUP_STARTED";
export const GET_SITE_SETUP_SUCCESS = "GET_SITE_SETUP_SUCCESS";
export const GET_SITE_SETUP_FAILURE = "GET_SITE_SETUP_FAILURE";

export const POST_SITE_SETUP_LOCATION_STARTED =
    "POST_SITE_SETUP_LOCATION_STARTED";
export const POST_SITE_SETUP_LOCATION_SUCCESS =
    "POST_SITE_SETUP_LOCATION_SUCCESS";
export const POST_SITE_SETUP_LOCATION_FAILURE =
    "POST_SITE_SETUP_LOCATION_FAILURE";

export const POST_SITE_SETUP_LINKS_STARTED = "POST_SITE_SETUP_LINKS_STARTED";
export const POST_SITE_SETUP_LINKS_SUCCESS = "POST_SITE_SETUP_LINKS_SUCCESS";
export const POST_SITE_SETUP_LINKS_FAILURE = "POST_SITE_SETUP_LINKS_FAILURE";

export const GET_SITE_SETUP_SESSIONS_STARTED =
    "GET_SITE_SETUP_SESSIONS_STARTED";
export const GET_SITE_SETUP_SESSIONS_SUCCESS =
    "GET_SITE_SETUP_SESSIONS_SUCCESS";
export const GET_SITE_SETUP_SESSIONS_FAILURE =
    "GET_SITE_SETUP_SESSIONS_FAILURE";

export const POST_SITE_SETUP_SESSION_STARTED =
    "POST_SITE_SETUP_SESSION_STARTED";
export const POST_SITE_SETUP_SESSION_SUCCESS =
    "POST_SITE_SETUP_SESSION_SUCCESS";
export const POST_SITE_SETUP_SESSION_FAILURE =
    "POST_SITE_SETUP_SESSION_FAILURE";

export const POST_SITE_SETUP_SESSION_PUBLISH_STARTED =
    "POST_SITE_SETUP_SESSION_PUBLISH_STARTED";
export const POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS =
    "POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS";
export const POST_SITE_SETUP_SESSION_PUBLISH_FAILURE =
    "POST_SITE_SETUP_SESSION_PUBLISH_FAILURE";

export const POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED =
    "POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED";
export const POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS =
    "POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS";
export const POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE =
    "POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE";

export const DELETE_SITE_SETUP_SESSION_STARTED =
    "DELETE_SITE_SETUP_SESSION_STARTED";
export const DELETE_SITE_SETUP_SESSION_SUCCESS =
    "DELETE_SITE_SETUP_SESSION_SUCCESS";
export const DELETE_SITE_SETUP_SESSION_FAILED =
    "DELETE_SITE_SETUP_SESSION_FAILED";

export const SEARCH_ODS_CODE_STARTED = "SEARCH_ODS_CODE_STARTED";
export const SEARCH_ODS_CODE_SUCCESS = "SEARCH_ODS_CODE_SUCCESS";
export const SEARCH_ODS_CODE_FAILED = "SEARCH_ODS_CODE_FAILED";

// -----------------
// ACTIONS INTERFACES
// -----------------
interface GetSiteSetupStartedAction {
    type: typeof GET_SITE_SETUP_STARTED;
}
interface GetSiteSetupSuccessAction {
    type: typeof GET_SITE_SETUP_SUCCESS;
    setups: VaccineSiteSetup[];
    setupId: string | null;
}
interface GetSiteSetupFailureAction {
    type: typeof GET_SITE_SETUP_FAILURE;
    error: string;
}

interface PostSiteSetupLocationStartedAction {
    type: typeof POST_SITE_SETUP_LOCATION_STARTED;
}
interface PostSiteSetupLocationSuccessAction {
    type: typeof POST_SITE_SETUP_LOCATION_SUCCESS;
    setup: VaccineSiteSetup;
}
interface PostSiteSetupLocationFailureAction {
    type: typeof POST_SITE_SETUP_LOCATION_FAILURE;
    error: string;
}

interface PostSiteSetupLinksStartedAction {
    type: typeof POST_SITE_SETUP_LINKS_STARTED;
}
interface PostSiteSetupLinksSuccessAction {
    type: typeof POST_SITE_SETUP_LINKS_SUCCESS;
    links: VaccineSiteLink[];
}
interface PostSiteSetupLinksFailureAction {
    type: typeof POST_SITE_SETUP_LINKS_FAILURE;
    error: string;
}

interface GetSiteSetupSessionsStartedAction {
    type: typeof GET_SITE_SETUP_SESSIONS_STARTED;
}
interface GetSiteSetupSessionsSuccessAction {
    type: typeof GET_SITE_SETUP_SESSIONS_SUCCESS;
    sessions: VaccineSiteSession[];
}
interface GetSiteSetupSessionsFailureAction {
    type: typeof GET_SITE_SETUP_SESSIONS_FAILURE;
    error: string;
}

interface PostSiteSetupSessionStartedAction {
    type: typeof POST_SITE_SETUP_SESSION_STARTED;
}
interface PostSiteSetupSessionSuccessAction {
    type: typeof POST_SITE_SETUP_SESSION_SUCCESS;
    session: VaccineSiteSession;
}
interface PostSiteSetupSessionFailureAction {
    type: typeof POST_SITE_SETUP_SESSION_FAILURE;
    error: string;
    sessionId: string;
}

interface PostSiteSetupSessionPublishStartedAction {
    type: typeof POST_SITE_SETUP_SESSION_PUBLISH_STARTED;
}
interface PostSiteSetupSessionPublishSuccessAction {
    type: typeof POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS;
    session: VaccineSiteSession;
}
interface PostSiteSetupSessionPublishFailureAction {
    type: typeof POST_SITE_SETUP_SESSION_PUBLISH_FAILURE;
    error: string;
    sessionId: string;
}
interface PostSiteSetupSessionUnpublishStartedAction {
    type: typeof POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED;
}
interface PostSiteSetupSessionUnpublishSuccessAction {
    type: typeof POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS;
    session: VaccineSiteSession;
}
interface PostSiteSetupSessionUnpublishFailureAction {
    type: typeof POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE;
    error: string;
    sessionId: string;
}

interface DeleteSiteSetupSessionStartedAction {
    type: typeof DELETE_SITE_SETUP_SESSION_STARTED;
}

interface DeleteSiteSetupSessionSuccessAction {
    type: typeof DELETE_SITE_SETUP_SESSION_SUCCESS;
}

interface DeleteSiteSetupSessionFailedAction {
    type: typeof DELETE_SITE_SETUP_SESSION_FAILED;
    error: string;
    sessionId: string;
}

interface SearchOdsCodeStartedAction {
    type: typeof SEARCH_ODS_CODE_STARTED;
}

interface SearchOdsCodeSuccessAction {
    type: typeof SEARCH_ODS_CODE_SUCCESS;
    practiceName: string;
}

interface SearchOdsCodeFailedAction {
    type: typeof SEARCH_ODS_CODE_FAILED;
    error: string;
}

export type KnownAction =
    | GetSiteSetupStartedAction
    | GetSiteSetupSuccessAction
    | GetSiteSetupFailureAction
    | PostSiteSetupLocationStartedAction
    | PostSiteSetupLocationSuccessAction
    | PostSiteSetupLocationFailureAction
    | PostSiteSetupLinksStartedAction
    | PostSiteSetupLinksSuccessAction
    | PostSiteSetupLinksFailureAction
    | GetSiteSetupSessionsStartedAction
    | GetSiteSetupSessionsSuccessAction
    | GetSiteSetupSessionsFailureAction
    | PostSiteSetupSessionStartedAction
    | PostSiteSetupSessionSuccessAction
    | PostSiteSetupSessionFailureAction
    | PostSiteSetupSessionPublishStartedAction
    | PostSiteSetupSessionPublishSuccessAction
    | PostSiteSetupSessionPublishFailureAction
    | PostSiteSetupSessionUnpublishStartedAction
    | PostSiteSetupSessionUnpublishSuccessAction
    | PostSiteSetupSessionUnpublishFailureAction
    | DeleteSiteSetupSessionStartedAction
    | DeleteSiteSetupSessionSuccessAction
    | DeleteSiteSetupSessionFailedAction
    | SearchOdsCodeStartedAction
    | SearchOdsCodeSuccessAction
    | SearchOdsCodeFailedAction
    | ExtendedActions[Actions.SET_SELECTED_PRACTICE];

interface UrlParamsSetup {
    organisationId: string;
    siteSetupId: string;
}

interface UrlParamsSession extends UrlParamsSetup {
    sessionId: string;
}

interface UrlParamsOdsSearch extends UrlParamsSetup {
    odsCode: string;
}

// ----------------
// ACTION CREATORS
// ----------------

export const getSiteSetups = (
    organisationId: string,
    chosenSetupId: string | null,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: GET_SITE_SETUP_STARTED });

        const { success, result } = await getVaccineSiteSetup(organisationId);
        if (success && result) {
            return dispatch({
                type: GET_SITE_SETUP_SUCCESS,
                setups: result.setups,
                setupId: chosenSetupId,
            });
        }
        return dispatch({
            type: GET_SITE_SETUP_FAILURE,
            error: "Something went wrong loading your data, please refresh the page",
        });
    };
};

export const postSiteSetupLocation = (
    { organisationId, siteSetupId }: UrlParamsSetup,
    request: VaccineSiteSetupLocationRequest,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_SITE_SETUP_LOCATION_STARTED });

        const { success, result } = await postVaccineSiteSetupLocation(
            organisationId,
            siteSetupId,
            request,
        );
        if (success && result?.locationText) {
            return dispatch({
                type: POST_SITE_SETUP_LOCATION_SUCCESS,
                setup: result,
            });
        }
        return dispatch({
            type: POST_SITE_SETUP_LOCATION_FAILURE,
            error: "Something went wrong saving your address, please make sure that the length is between 10 and 200 characters and that you do not already have patients booked in for upcoming sessions.",
        });
    };
};

export const postSiteSetupLinks = (
    { organisationId, siteSetupId }: UrlParamsSetup,
    request: VaccineSiteSetupLinksRequest,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_SITE_SETUP_LINKS_STARTED });

        const { success, result } = await postVaccineSiteSetupLink(
            organisationId,
            siteSetupId,
            request,
        );
        if (success && result?.links) {
            return dispatch({
                type: POST_SITE_SETUP_LINKS_SUCCESS,
                links: result.links,
            });
        }

        return dispatch({
            type: POST_SITE_SETUP_LINKS_FAILURE,
            error: "Something went wrong adding this practice, please check the code and try again",
        });
    };
};

export const getSiteSetupSessions = ({
    organisationId,
    siteSetupId,
}: UrlParamsSetup) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: GET_SITE_SETUP_SESSIONS_STARTED });

        const { success, result } = await getVaccineSiteSetupSessions(
            organisationId,
            siteSetupId,
        );
        if (success && result?.sessions) {
            return dispatch({
                type: GET_SITE_SETUP_SESSIONS_SUCCESS,
                sessions: result.sessions,
            });
        }
        return dispatch({
            type: GET_SITE_SETUP_SESSIONS_FAILURE,
            error: "Something went wrong loading your data, please refresh the page",
        });
    };
};

export const postSiteSetupSession = (
    { organisationId, siteSetupId, sessionId }: UrlParamsSession,
    request: any,
    successCallback: () => void,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_SITE_SETUP_SESSION_STARTED });

        const sessionIdUrl = sessionId || "";
        const { success, result, error } = await postVaccineSiteSetupSession(
            organisationId,
            siteSetupId,
            sessionIdUrl,
            request,
        );

        if (success && result?.id) {
            successCallback && successCallback();
            return dispatch({
                type: POST_SITE_SETUP_SESSION_SUCCESS,
                session: result,
            });
        }
        return dispatch({
            type: POST_SITE_SETUP_SESSION_FAILURE,
            error:
                error ||
                "We haven't been able to save your clinic, please check all fields are correct and try again",
            sessionId,
        });
    };
};

export const postSiteSetupSessionPublish = ({
    organisationId,
    siteSetupId,
    sessionId,
}: UrlParamsSession) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_SITE_SETUP_SESSION_PUBLISH_STARTED });

        const { success, result } = await postVaccineSiteSetupPublish(
            organisationId,
            siteSetupId,
            sessionId,
        );
        if (success && result?.id) {
            return dispatch({
                type: POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS,
                session: result,
            });
        }
        return dispatch({
            type: POST_SITE_SETUP_SESSION_PUBLISH_FAILURE,
            error: "We haven't been able to set your clinic live, please check all fields are correct with no overlapping times and try again",
            sessionId,
        });
    };
};

export const postSiteSetupSessionUnpublish = ({
    organisationId,
    siteSetupId,
    sessionId,
}: UrlParamsSession) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED });

        const { success, result } = await postVaccineSiteSetupUnpublish(
            organisationId,
            siteSetupId,
            sessionId,
        );
        if (success && result?.id) {
            return dispatch({
                type: POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS,
                session: result,
            });
        }
        return dispatch({
            type: POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE,
            error: "We haven't been able to unpublish your clinic, please try again",
            sessionId,
        });
    };
};

export const deleteSession = ({
    organisationId,
    siteSetupId,
    sessionId,
}: UrlParamsSession) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: DELETE_SITE_SETUP_SESSION_STARTED });

        const { success } = await deleteVaccineSiteSession(
            organisationId,
            siteSetupId,
            sessionId,
        );
        if (success) {
            return dispatch({
                type: DELETE_SITE_SETUP_SESSION_SUCCESS,
            });
        }

        return dispatch({
            type: DELETE_SITE_SETUP_SESSION_FAILED,
            error: "We can't delete this clinic, as there are booked in patients. Each practice needs to cancel these booked in patients in order for you to delete it",
            sessionId,
        });
    };
};

export const searchOdsCode = ({
    organisationId,
    siteSetupId,
    odsCode,
}: UrlParamsOdsSearch) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: SEARCH_ODS_CODE_STARTED });

        const { success, result } = await getVaccineSiteSetupOds(
            organisationId,
            siteSetupId,
            odsCode,
        );
        if (success && result?.results?.length) {
            return dispatch({
                type: SEARCH_ODS_CODE_SUCCESS,
                practiceName: result.results[0].name,
            });
        }

        return dispatch({
            type: SEARCH_ODS_CODE_FAILED,
            error: "Could not find a practice with that ODS code",
        });
    };
};
