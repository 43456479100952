import { Log } from "@accurx/shared";
import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";

import { conversationOrdering } from "./conversationOrdering";
import { getConversation } from "./getConversation";

/**
 * Returns the index at which the target conversation
 * should be inserted into the sorted conversation group.
 * If it is already present in the group, it will return
 * its current index.
 * Algorithm adapted from lodash's sortedIndexBy.
 */
export const conversationGroupIndexBy = (
    state: Pick<ConversationsState, "items">,
    group: ConversationGroup,
    target: Conversation | undefined,
) => {
    if (target === undefined) return -1;

    const [comparables, orders] = conversationOrdering(group.sortOptions);
    const targetComparables = comparables.map((f) => f(target));
    let low = 0;
    let high = group.members.length;

    while (low < high) {
        const mid = Math.floor((low + high) / 2);
        const other = getConversation(state, group.members[mid]);

        if (other === undefined) {
            Log.error(
                "Expected a member to be in a conversation group but it wasn't",
                {
                    tags: {
                        product: "Inbox",
                        low,
                        high,
                        mid,
                        id: group.members[mid],
                        groupName: group.loggingInfo.name,
                        ...group.loggingInfo.tags,
                    },
                },
            );
            high = mid;
            continue;
        }

        const otherComparables = comparables.map((f) => f(other));

        if (isEqual(targetComparables, otherComparables)) {
            high = mid;
            continue;
        }

        const ordered = orderBy(
            [targetComparables, otherComparables],
            targetComparables.map((_, index) => index),
            orders,
        );

        if (ordered[0] === targetComparables) {
            high = mid;
        } else {
            low = mid + 1;
        }
    }

    return high;
};
