import { partitionItemIdsByReadState } from "domains/concierge/internal/util/partitionItemIdsByReadState";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import sortedIndex from "lodash/sortedIndex";
import sortedIndexOf from "lodash/sortedIndexOf";

/**
 * Takes an unread summary and a conversation update and calculates a new
 * version of the update summary. It adds any new unread items and removes any
 * unread items that have been read. If it returns an unread items summary it
 * means the conversation is still unread, if it returns undefined it means the
 * conversation is no longer unread.
 */
export const createUnreadItemsSummary = (
    current: UnreadItemsSummary | undefined,
    conversation: Conversation,
    userId: string,
): UnreadItemsSummary => {
    // Check if the conversation update is stale
    const isUpdateStale =
        !!current && current.latestToken > conversation.latestToken;

    // If stale, prefer the details from the current summary. Otherwise take
    // details from the conversation update.
    const result = isUpdateStale
        ? current
        : {
              conversationId: conversation.id,
              patientId: conversation.regardingPatientId,
              assignee: conversation.assignee,
              status: conversation.status,
              system: conversation.source.system,
              latestToken: conversation.latestToken,
              itemIds: [...(current?.itemIds ?? [])],
          };

    const partitioned = partitionItemIdsByReadState(conversation, userId);

    // Remove any items that are now read
    for (const itemId of partitioned.read) {
        const index = sortedIndexOf(result.itemIds, itemId);
        if (index > -1) {
            result.itemIds.splice(index, 1);
        }
    }

    // Insert any new unread items
    for (const itemId of partitioned.unread) {
        const index = sortedIndex(result.itemIds, itemId);
        if (result.itemIds[index] !== itemId) {
            result.itemIds.splice(index, 0, itemId);
        }
    }

    return result;
};
