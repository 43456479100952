import { Flex, Text } from "@accurx/design";
import { NhsNumberHelpers } from "@accurx/shared";
import styled from "styled-components";

import { PatientSummary } from "shared/concierge/patients/types/patient.types";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";

const StyledWrapper = styled(Flex).attrs({
    flexDirection: "column",
})`
    white-space: nowrap;
`;

interface PatientInfoProps {
    patient?: Pick<
        PatientSummary,
        | "ageYear"
        | "dateOfBirth"
        | "firstName"
        | "familyName"
        | "externalIds"
        | "gender"
    >;
}

const mapToDisplayGender = (gender: string | undefined): string | undefined => {
    switch (gender?.toLowerCase()) {
        case "male":
            return "M";

        case "female":
            return "F";

        default:
            return undefined;
    }
};

export const PatientInfo = ({ patient }: PatientInfoProps) => {
    const patientName = patient
        ? formatPatientDisplayName({
              firstName: patient.firstName,
              familyName: patient.familyName,
          }) ?? "Unknown patient"
        : "Unknown patient";

    const nhsNumber = patient
        ? patient.externalIds.find((id) => id.type === "NhsNumber")?.value
        : undefined;
    const formattedNhsNumber = nhsNumber
        ? NhsNumberHelpers.formatNhsNumber(nhsNumber)
        : "Unknown";
    const age = patient?.ageYear;

    const gender = mapToDisplayGender(patient?.gender);

    return (
        <StyledWrapper>
            <Text variant="label">{patientName} </Text>

            <Flex>
                <Text skinny variant="body">
                    NHS: {formattedNhsNumber}
                </Text>
                {age && (
                    <Text skinny variant="body">
                        &nbsp;· {age}y
                    </Text>
                )}
                {gender && (
                    <Text skinny variant="body">
                        &nbsp;· {gender}
                    </Text>
                )}
            </Flex>
        </StyledWrapper>
    );
};
