import { TemplateItem } from "domains/compose/types";

export const getTemplateType = (value: TemplateItem) => {
    if (value.type === "QuestionnaireTemplate") {
        switch (value.value.owner) {
            case "Accurx":
                return "Florey";
            case "Organisation":
                return "CustomOrganisationFlorey";
        }
    } else {
        switch (value.value.owner) {
            case "Accurx":
                return "AccurxTemplate";
            case "Organisation":
                return "OrganisationTemplate";
            case "User":
                return "UserTemplate";
        }
    }
};
