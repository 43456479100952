import { useState } from "react";

import { Button, Flex, FormFieldWrapper, Item, Text } from "@accurx/design";
import format from "date-fns/format";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import { BatchMessagePageLayout } from "app/batchMessage/trust/components/BatchMessagePageLayout";
import { useUploadBatchPatientsMutation } from "app/hooks/mutations/useUploadBatchPatientsMutation";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledTextareaAutosize } from "app/sharedComponents/textAreaAutosize/TextAreaAutosize.styles";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import {
    PatientDetails,
    createFormattedListOfErrors,
    errorStrings,
    parsePatients,
} from "./PatientParser";
import HowDoesItWork from "./components/HowDoesItWork";
import PatientsTable from "./components/PatientsTable";
import dedupePatientsByNhsNumber from "./dedupePatientsByNhsNumber";

const Form = styled(Item).attrs(() => ({ as: "form" }))`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    width: 100%;
`;

type AddPatientsViaCopyPasteProps = {
    onUploadPatientSuccess?(batchMessageId: string): void;
};

const AddPatientsViaCopyPaste = ({
    onUploadPatientSuccess,
}: AddPatientsViaCopyPasteProps) => {
    const [patientDetailsInputValue, setPatientDetailsInputValue] =
        useState("");
    const [parsedPatientDetails, setParsedPatientDetails] = useState<
        PatientDetails[]
    >([]);
    const [errors, setErrors] = useState<string[]>([]);

    const loggedInAnalyticsProps = useFlemingLoggedInAnalytics();

    const removePatient = (nhsNumber: string) => {
        setParsedPatientDetails(
            parsedPatientDetails.filter(
                (patient) => patient.nhsNumber !== nhsNumber,
            ),
        );
    };

    const handlePatientInput = () => {
        const parseResult = parsePatients(patientDetailsInputValue);

        if (parseResult.success) {
            setErrors([]);
            setPatientDetailsInputValue("");
            setParsedPatientDetails((prevValue) =>
                dedupePatientsByNhsNumber(prevValue, parseResult.patients),
            );

            ChainAnalyticsTracker.trackBatchCopyPasteAddPatientButtonClick({
                ...loggedInAnalyticsProps,
                countPatient: parseResult.patients.length,
                hasError: false,
            });
        } else if (parseResult.errorReason === "NoInput") {
            setErrors([errorStrings.noInputDetected]);

            ChainAnalyticsTracker.trackBatchCopyPasteAddPatientButtonClick({
                ...loggedInAnalyticsProps,
                countPatient: undefined,
                hasError: true,
                errorReason: parseResult.errorReason,
            });
        } else {
            setErrors(createFormattedListOfErrors(parseResult.errorsByLine));

            ChainAnalyticsTracker.trackBatchCopyPasteAddPatientButtonClick({
                ...loggedInAnalyticsProps,
                countPatient: undefined,
                hasError: true,
                errorReason: parseResult.errorReason,
                countErrorNhsNumberMultiple:
                    parseResult.errorsByLine.multipleNhsNumbers.length,
                countErrorNhsNumberInvalid:
                    parseResult.errorsByLine.invalidNhsNumber.length,
                countErrorDateOfBirthInvalid:
                    parseResult.errorsByLine.invalidDateOfBirth.length,
                countErrorDateOfBirthFuture:
                    parseResult.errorsByLine.futureDateOfBirth.length,
                countErrorDateOfBirthTooOld:
                    parseResult.errorsByLine.patientTooOld.length,
            });
        }
    };

    const workspaceId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const { mutate: uploadPatients, status } = useUploadBatchPatientsMutation({
        onSuccess: ({ id }) =>
            onUploadPatientSuccess && onUploadPatientSuccess(id),
    });

    return (
        <BatchMessagePageLayout
            pageTitle="Input patient details"
            showControls
            continueDisabled={
                !parsedPatientDetails.length || status === "loading"
            }
            onContinue={() => {
                if (!workspaceId) {
                    // This is just for type safety as our route guards would prevent this page loading if workspaceId was missing
                    return;
                }

                uploadPatients({
                    workspaceId: workspaceId.toString(),
                    patients: parsedPatientDetails.map((p) => ({
                        nhsNumber: p.nhsNumber,
                        // Not using toIsoString because that causes issues for DST
                        // 24/06/1933 would be printed as 1933-06-23T23:00Z
                        dateOfBirth: format(p.dateOfBirth, "yyyy-MM-dd"),
                    })),
                });

                ChainAnalyticsTracker.trackBatchCopyPasteImportButtonClick({
                    ...loggedInAnalyticsProps,
                    countPatient: parsedPatientDetails.length,
                });
            }}
        >
            <Flex flexDirection="column" gap="3">
                <Item>
                    <Text>
                        Input patient details from another source such as an
                        electronic patient record or clinical system
                    </Text>
                    <HowDoesItWork />
                </Item>
                <Form>
                    <StyledFormFieldWrapper
                        label="Patient details"
                        labelProps={{
                            htmlFor: "patient-details-textarea",
                        }}
                        subLabel="Add patients’ NHS number and Date of birth below with one patient on each line"
                        errors={errors}
                    >
                        <StyledTextareaAutosize
                            id="patient-details-textarea"
                            rows={4}
                            disabled={false}
                            placeholder="Paste patient details here"
                            value={patientDetailsInputValue}
                            onChange={(e) => {
                                setPatientDetailsInputValue(
                                    e.currentTarget.value,
                                );
                            }}
                            aria-invalid={errors.length > 0}
                        />
                    </StyledFormFieldWrapper>
                    <Button
                        text="Add patients"
                        icon={{
                            name: "SearchPerson",
                            style: "Fill",
                            placement: "end",
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handlePatientInput();
                        }}
                    />
                </Form>

                <PatientsTable
                    patients={parsedPatientDetails}
                    onRemovePatient={removePatient}
                />
            </Flex>
        </BatchMessagePageLayout>
    );
};

export default AddPatientsViaCopyPaste;
