import { useCurrentWorkspace } from "@accurx/auth";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    OpenComposeWindowMutationPayload,
    mutateOpenComposeWindow,
} from "domains/native/api/endpoints/mutateOpenComposeWindow";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";

export const useOpenComposeWindowMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        OpenComposeWindowMutationPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "OpenComposeWindowMutation: Called with no native transport",
            );
        }

        await mutateOpenComposeWindow(
            {
                transport,
                workspaceId,
            },
            variables,
        );
    }, options);
};
