import {
    CONVERSATION_ITEM_MAX_WIDTH,
    CONVERSATION_ITEM_WIDTH,
} from "domains/inbox/components/ConversationView/components/ConversationThread/ConversationThread.styles";
import styled from "styled-components";

import { StyledStateChangeEventContainer } from "../StateChangeEvent/StateChangeEvent.styles";

/**
 * Custom width for label tag note is because
 * when we Change the outcome, we want to display
 * the OutcomeRecordingEdit form in full width, while still
 * keeping
 */
export const StyledLabelTagNoteContainer = styled(
    StyledStateChangeEventContainer,
)`
    max-width: ${CONVERSATION_ITEM_MAX_WIDTH};
    width: ${CONVERSATION_ITEM_WIDTH};
    margin-left: auto;
    margin-right: auto;
`;
