import { MessageTemplate } from "app/batchMessage/gp/BatchMessage.types";

export const DEFAULT_FLOREY_HEADER = "Select a Florey";

export const DEFAULT_TEMPLATE_HEADER = "Select a template";
export const emptyTemplate: MessageTemplate = {
    allowReplyByDefault: false,
    shouldSaveBatchToRecord: true,
    canSchedule: false,
    id: "",
    body: "",
    path: { folders: [] },
    snomedCodes: [],
    title: DEFAULT_TEMPLATE_HEADER,
};

export const AVG_PATIENT_LENGTH = `[Patient Name],`.length; // We don't have maximumPatientGreetingLength because upload now is done after compose
export const COPY_PATIENT_GREETING = `Dear [Patient Name],`;
export const FOOTER_DOCUMENT =
    "To view your attachment, please follow this link: ";
export const FOOTER_FLOREY = "Please complete this questionnaire: ";
export const FOOTER_SELFBOOK_LINK_EXPIRY_REPLACE = "{link_expiry_time}";
export const FOOTER_SELFBOOK_ACTION_TYPE_REPLACE = "{action_type}";
export const FOOTER_SELFBOOK = `To ${FOOTER_SELFBOOK_ACTION_TYPE_REPLACE}, please follow this link within ${FOOTER_SELFBOOK_LINK_EXPIRY_REPLACE}: `;
export const PLACEHOLDER_LINK_AUTO = "[link will autogenerate here]";
export const PLACEHOLDER_LINK_PATIENT_PORTAL =
    "https://accurx.nhs.uk/c/p-xxxxxxxxxx";
export const PLACEHOLDER_LINK_SELFBOOK = "https://accurx.nhs.uk/b/xxxxxxxxxx";
export const DECLINE_SETTINGS_SUPPORT_URL =
    "https://support.accurx.com/en/articles/6820076-how-decline-works-for-patients-when-using-batch-self-book";

export const BATCH_MESSAGE_TEMPLATE_INSERT_TOKEN = "*****";
