import styled from "styled-components";

import { Breadcrumbs } from "app/organisations/shared";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin-bottom: 0;
    ol {
        margin-bottom: 0;
    }
`;
