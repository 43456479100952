import React, { useRef } from "react";

import { StyledTextarea } from "./Textarea.styles";

type MessageParts = {
    header?: string;
    body: string;
    footer?: string;
};

type TextareaProps = {
    messageParts: MessageParts;
    disabled?: boolean;
    onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = ({
    onChange,
    messageParts,
    disabled,
    ...props
}: TextareaProps) => {
    const { header, body, footer } = messageParts;

    const bodyRef = useRef<HTMLTextAreaElement>(null);

    return (
        <StyledTextarea.Wrapper {...props}>
            {header && (
                <StyledTextarea.Header
                    onClick={() => {
                        if (bodyRef.current) {
                            // Move focus to the start of the body
                            bodyRef.current.setSelectionRange(0, 0);
                            bodyRef.current.focus();
                        }
                    }}
                    value={header}
                    aria-label="message header"
                    readOnly
                    tabIndex={-1}
                    disabled={disabled}
                />
            )}
            <StyledTextarea.Body
                onChange={onChange}
                value={body}
                aria-label="message body"
                disabled={disabled}
                ref={bodyRef}
            />
            {footer && (
                <StyledTextarea.Footer
                    onClick={() => {
                        if (bodyRef.current) {
                            // Move focus to the end of the body
                            const end = bodyRef.current.value.length;
                            bodyRef.current.setSelectionRange(end, end);
                            bodyRef.current.focus();
                        }
                    }}
                    value={footer}
                    aria-label="message footer"
                    readOnly
                    tabIndex={-1}
                    disabled={disabled}
                />
            )}
        </StyledTextarea.Wrapper>
    );
};
