import { createMutationHook } from "@accurx/api/QueryClient";
import { CollaborativeWorkspaceUpdateRequest } from "@accurx/api/account";
import { Organisation } from "@accurx/auth";
import { returnDataOrThrow } from "@accurx/shared";

import { updateCollaborativeWorkspace } from "api/AccountApi";

type MutationFnArgs = CollaborativeWorkspaceUpdateRequest & {
    workspaceId: number;
};

export const useUpdateCollaborativeWorkspace = createMutationHook<
    MutationFnArgs,
    Organisation,
    Error
>(() => ({
    mutationKey: ["UpdateWorkspace"],
    mutationFn: async ({ name, description, workspaceId, specialties }) => {
        const response = await updateCollaborativeWorkspace(
            workspaceId.toString(),
            {
                name,
                description,
                specialties,
            },
        );

        return returnDataOrThrow(response);
    },
}));
