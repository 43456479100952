import { useState } from "react";

import { Feedback, Flex, Spinner, Text } from "@accurx/design";

import { MessageGpPatientResult } from "app/clinicianConversations/components/MessageGpPatientResult";
import { isMessageGpDisabled } from "app/patientProfile/PatientProfile.helper";
import { isTestPatient } from "app/patientProfile/isTestPatient";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { MessageGpPatientSearchCard } from "../../components/MessageGpPatientSearchCard";
import { ClinicianConversationsPageLayout } from "../ClinicianConversationsPageLayout";

export const MessageGpPatientSearchPage = () => {
    const [hasDoneSearch, setHasDoneSearch] = useState(false);
    const { patient, isSearching } = useAppSelector(({ searchForPatient }) => {
        // Ensure the user has done at least one search in this context to prevent
        // showing a patient from a previous search.
        if (!hasDoneSearch) {
            return { patient: null, isSearching: searchForPatient.isSearching };
        }

        if (searchForPatient.isSearching) {
            return { isSearching: true, patient: null };
        }

        return {
            patient: PatientHelper.getPatient(searchForPatient.lastResponse),
            isSearching: false,
        };
    });

    const isEnabledForPatient = isMessageGpDisabled(patient) === null;
    const testPatient = !!patient && isTestPatient(patient);

    return (
        <ClinicianConversationsPageLayout headerText="New message to GP practice">
            <Flex gap="3" flexDirection="column">
                <Flex gap="1" flexDirection="column">
                    <div>
                        <Text variant="subtitle" skinny>
                            Which patient is this about?
                        </Text>
                        <Text skinny>
                            Search a patient below, and we will find their GP
                            practice for you.
                        </Text>
                    </div>
                    <MessageGpPatientSearchCard
                        onCompleteSearch={() => setHasDoneSearch(true)}
                    />
                </Flex>
                {(isSearching || patient) && (
                    <Flex gap="1" flexDirection="column">
                        <Text variant="label">Search Result</Text>
                        {isSearching && (
                            <Flex>
                                <Spinner dimension="small" />
                            </Flex>
                        )}
                        {patient && (
                            <MessageGpPatientResult
                                patient={patient}
                                showButton={isEnabledForPatient || testPatient}
                                testPatient={testPatient ?? false}
                            />
                        )}
                        {patient && !isEnabledForPatient && !testPatient && (
                            <Feedback
                                colour="information"
                                title={`We are unable to reach this patient's GP practice${
                                    patient.practiceName
                                        ? ` (${patient.practiceName})`
                                        : ""
                                }. Please contact them via email or phone call instead.`}
                            ></Feedback>
                        )}
                    </Flex>
                )}
            </Flex>
        </ClinicianConversationsPageLayout>
    );
};
