import React, { useState } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import noop from "lodash/noop";

import { StyledPopoverContent } from "./Dropdown.styles";
import { DropdownItem } from "./DropdownItem";

export type DropdownProps = {
    icon: JSX.Element;
    dropDownDisplayName: string;
    contentHeading: string;
    onClick?: (e: React.MouseEvent, isOpen: boolean) => void;
    children: React.ReactNode;
};

export const Dropdown = ({
    icon,
    dropDownDisplayName,
    contentHeading,
    children,
    onClick = noop,
}: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClick = (e: React.MouseEvent) => {
        onClick(e, isOpen);
    };

    return (
        <UI.Popover open={isOpen} onOpenChange={setIsOpen}>
            <UI.PopoverTrigger asChild>
                <Pill.Dropdown
                    radius="full"
                    onClick={handleOnClick}
                    aria-haspopup="menu"
                >
                    {icon}
                    <Pill.Text>{dropDownDisplayName}</Pill.Text>
                </Pill.Dropdown>
            </UI.PopoverTrigger>
            <StyledPopoverContent align="start">
                <DropdownItem.ListItem>
                    <DropdownItem.TitleBar $mb={1}>
                        <UI.Text variant="label">{contentHeading}</UI.Text>
                    </DropdownItem.TitleBar>
                </DropdownItem.ListItem>
                {children}
            </StyledPopoverContent>
        </UI.Popover>
    );
};
