import React, {
    CSSProperties,
    ComponentType,
    ReactNode,
    forwardRef,
    useContext,
    useEffect,
} from "react";

import { useConversation } from "@accurx/concierge/hooks/data/useConversation";
import {
    Conversation,
    ConversationGroupFilters,
} from "@accurx/concierge/types";
import { useScrollToCurrentConversation } from "domains/inbox/components/ConversationList/components/ConversationFeed/useScrollToCurrentConversation";
import { LoadMoreButton } from "domains/inbox/components/ConversationList/components/LoadMoreButton/LoadMoreButton";
import { LoadingCard } from "domains/inbox/components/ConversationList/components/LoadingCard/LoadingCard";
import { CONVERSATION_PREVIEW_HEIGHT } from "domains/inbox/components/ConversationPreview/ConversationPreview.styles";
import { InboxContext } from "domains/inbox/components/InboxContext/InboxContext";
import { useInboxOptionalParams } from "domains/inbox/hooks/util/useInboxParams";
import {
    ItemState,
    ItemStateWithReason,
    ItemWithState,
    Reason,
    useUpdatingFeed,
} from "domains/inbox/hooks/util/useUpdatingFeed";
import { userflowIds } from "domains/inbox/util";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
    FixedSizeList,
    ListChildComponentProps,
    ReactElementType,
} from "react-window";

import { EmptyState } from "../EmptyState/EmptyState";
import {
    GUTTER_SIZE,
    PADDING_SIZE,
    StyledContainer,
    StyledList,
    StyledListContainer,
    StyledListItem,
    StyledLoadMoreButtonContainer,
} from "./ConversationFeed.styles";
import { ConversationFeedErrorState } from "./ConversationFeedErrorState";
import { ConversationFeedLoadingSkeleton } from "./ConversationFeedLoadingSkeleton";
import { PaginationContext } from "./PaginationContext";

type RenderItem = (
    conversation: Conversation,
    state: ItemStateWithReason,
) => ReactNode;
type MembersList = Conversation["id"][];
type FetchMore = () => void;
type Refetch = () => void;
type ItemData = {
    renderItem: RenderItem;
    feed: ItemWithState[];
    filters: ConversationGroupFilters;
};

export type ConversationFeedProps = {
    fetchMore?: FetchMore;
    isFetchingMore?: boolean;
    members?: MembersList;
    isFullyLoaded?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    refetch?: Refetch;
    renderItem: RenderItem;
    filters?: ConversationGroupFilters;
    itemHeight?: number;
};

type ConversationFeedInnerProps = Required<
    Pick<
        ConversationFeedProps,
        | "members"
        | "renderItem"
        | "filters"
        | "isFullyLoaded"
        | "fetchMore"
        | "isFetchingMore"
        | "itemHeight"
    >
>;

const getReasonForLeaving = (
    conversation: Conversation,
    groupFilters: ConversationGroupFilters,
): Reason => {
    for (const filter of groupFilters) {
        switch (filter.type) {
            case "AssignedTo":
                if (!isEqual(conversation.assignee, filter.value)) {
                    return "Reassigned";
                }
                break;
            case "Status":
                if (conversation.status !== filter.value) {
                    return filter.value === "Done"
                        ? "MarkedOpen"
                        : "MarkedDone";
                }
                break;
        }
    }
    return "Unknown";
};

const ConversationFeedItem = ({
    conversationId,
    filters,
    state,
    renderItem,
}: {
    conversationId: Conversation["id"];
    filters: ConversationGroupFilters;
    renderItem: RenderItem;
    state: ItemState;
}) => {
    const conversation = useConversation({ conversationId });
    if (!conversation) return null;
    const stateWithReason: ItemStateWithReason = (() => {
        switch (state) {
            case "leaving":
            case "suspended":
                return {
                    current: state,
                    reason: getReasonForLeaving(conversation, filters),
                };
            case "entering":
            case "present":
                return {
                    current: state,
                };
        }
    })();
    return <>{renderItem(conversation, stateWithReason)}</>;
};

const ListElement: ReactElementType = forwardRef<
    HTMLOListElement,
    { style: CSSProperties & { height: string } }
>(({ style, ...rest }, ref) => {
    const { fetchMore, isFullyLoaded, isFetchingMore } =
        useContext(PaginationContext);

    return (
        <StyledListContainer>
            <StyledList
                ref={ref}
                style={{
                    ...style,
                    height: `${
                        parseFloat(style.height) +
                        PADDING_SIZE * 2 -
                        GUTTER_SIZE
                    }px`,
                }}
                {...rest}
                aria-label={"Conversations"}
            />
            {!isFullyLoaded && (
                <StyledLoadMoreButtonContainer>
                    <LoadMoreButton
                        onClick={fetchMore}
                        isLoading={!!isFetchingMore}
                    />
                </StyledLoadMoreButtonContainer>
            )}
        </StyledListContainer>
    );
});

/**
 * This is what gets rendered for each item in the list. This should be passed
 * as a child to the virtualized list component.
 *
 * NOTE: It's important that this is defined up here and not inline because
 * otherwise the renderer gets re-initialized when the data changes and each
 * list item will keep being unmounted and remounted anytime any data changes.
 */
const ListItemElement: ComponentType<
    React.PropsWithChildren<ListChildComponentProps<ItemData>>
> = ({ index, style, data }) => {
    const { feed, renderItem, filters } = data;
    const userflowId =
        index === 0
            ? userflowIds.conversationList.firstConversation
            : undefined;

    return (
        <StyledListItem
            $top={style.top as number}
            data-userflow-id={userflowId}
        >
            {index < feed.length ? (
                <ConversationFeedItem
                    state={feed[index].state}
                    conversationId={feed[index].id}
                    renderItem={renderItem}
                    filters={filters}
                />
            ) : (
                // If the feed is rendering more items than there are
                // conversations it's because we're in a loading state and the
                // extra items represent a skeleton loading state.
                <LoadingCard />
            )}
        </StyledListItem>
    );
};

const ConversationFeedInner = ({
    members,
    itemHeight,
    renderItem,
    filters,
    isFullyLoaded,
    fetchMore,
    isFetchingMore,
}: ConversationFeedInnerProps) => {
    const { conversationId } = useInboxOptionalParams(["conversationId"]);
    const feed = useUpdatingFeed(members, {
        delay: 800,
        currentConversationId: conversationId,
    });
    const isItemLoaded = (index: number) => index < members.length;
    const listRef = useScrollToCurrentConversation(members);

    const { feedCount } = useContext(InboxContext);

    useEffect(() => {
        feedCount.setValue(members.length);

        return () => {
            // When we switch conversation groups, we want to reset the feed count to 0
            feedCount.setValue(0);
        };
    }, [feedCount, members.length]);

    if (members.length === 0) {
        return <EmptyState />;
    }

    return (
        <div style={{ flex: 1 }}>
            <AutoSizer>
                {({ height, width }: Size) => (
                    <PaginationContext.Provider
                        value={{
                            fetchMore,
                            isFetchingMore,
                            isFullyLoaded,
                        }}
                    >
                        <FixedSizeList
                            ref={listRef}
                            height={height}
                            width={width}
                            itemCount={feed.items.length}
                            itemSize={itemHeight + GUTTER_SIZE}
                            itemData={{
                                feed: feed.items,
                                renderItem,
                                filters,
                            }}
                            itemKey={(index) =>
                                isItemLoaded(index)
                                    ? members[index]
                                    : `loading-state-${index}`
                            }
                            innerElementType={ListElement}
                            style={{ overflowX: "hidden" }}
                        >
                            {ListItemElement}
                        </FixedSizeList>
                    </PaginationContext.Provider>
                )}
            </AutoSizer>
        </div>
    );
};

/**
 * When using this component please remember to pass a unique "key" to make sure
 * that it gets unmounted and remounted when the conversation group changes.
 * Internally we memoize the current list of conversations so that we can nicely
 * animate items as they enter and leave the group. If the entire group changes
 * without also proving a new key, every item will be animated in and out of the
 * list rather than the new list rendering immediately.
 */
export const ConversationFeed = ({
    members = [],
    refetch = noop,
    fetchMore = noop,
    isFullyLoaded = true,
    isLoading = false,
    isFetchingMore = false,
    isError = false,
    renderItem,
    filters = [],
    itemHeight = CONVERSATION_PREVIEW_HEIGHT,
}: ConversationFeedProps) => {
    if (isError) {
        return <ConversationFeedErrorState onClick={refetch} />;
    }

    if (isLoading) {
        return <ConversationFeedLoadingSkeleton />;
    }

    return (
        <StyledContainer>
            <ConversationFeedInner
                members={members}
                renderItem={renderItem}
                filters={filters}
                itemHeight={itemHeight}
                isFullyLoaded={isFullyLoaded}
                fetchMore={fetchMore}
                isFetchingMore={isFetchingMore}
            />
        </StyledContainer>
    );
};
