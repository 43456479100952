import { PatientThreadUserGroup } from "@accurx/api/ticket";
import { TeamSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import { mapUserGroupTypeToTeamType } from "./mapUserGroupTypeToTeamType";

/**
 * Maps from a Ticket User Group to a Team Summary
 * @param dto the ticket user group
 * @returns mapped version to Team Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserGroupToTeamSummary = (
    dto: PatientThreadUserGroup,
): TeamSummary | undefined => {
    if (isNil(dto.id) || dto.id.length === 0) {
        return undefined;
    }

    return {
        id: dto.id,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        displayName: dto.name || dto.id,
        type: mapUserGroupTypeToTeamType(dto.groupType),
    };
};
