import React from "react";

import { Stepper, Text } from "@accurx/design";

import { getTotalStep } from "app/batchMessage/gp/BatchMessage.utils";
import { useAppSelector } from "store/hooks";

export type HeaderProps = {
    stepNumber?: number;
    title: string;
};

export const BatchMessageHeader = ({
    stepNumber,
    title,
}: HeaderProps): JSX.Element => {
    // Store
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
    );

    const totalStep = getTotalStep(batchType);
    const currentStep = stepNumber || totalStep;
    return (
        <>
            <Stepper currentStep={currentStep} totalSteps={totalStep} />
            <Text as="h1" variant="title" className="text-center">
                {title}
            </Text>
        </>
    );
};
