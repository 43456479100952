import { Button, Flex, Icon, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { StoredFileWithId } from "./FileUploadReducer";

const StyledFileRow = styled(Flex).attrs(() => ({
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1",
}))`
    margin-top: ${Tokens.SIZES[0.5]};
    padding: ${Tokens.SIZES[1]};

    background-color: ${Tokens.COLOURS.greyscale.white};
`;

const StyledFileName = styled(Text).attrs(() => ({
    skinny: true,
}))`
    overflow: hidden;
    width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

type FileUploadRowProps = {
    uploadedFile: StoredFileWithId;
    removeFile: (fileId: string) => void;
};

export const FileUploadRow = ({
    uploadedFile,
    removeFile,
}: FileUploadRowProps) => {
    return (
        <StyledFileRow>
            <Icon name="Done" colour="green" />
            <StyledFileName>{uploadedFile.file.name}</StyledFileName>
            <Text skinny>
                {(uploadedFile.file.size / (1024 * 1024)).toPrecision(2)}MB
            </Text>
            <Text
                skinny
                as="a"
                variant="link"
                props={{
                    target: "_blank",
                    rel: "noreferrer noopener",
                    href: uploadedFile.file.previewUrl,
                }}
            >
                Preview
            </Text>
            <Button
                type="button"
                theme="secondary"
                icon={{
                    name: "Cross",
                    colour: "red",
                    id: `removefile-${uploadedFile.id}`,
                    title: `Remove file ${uploadedFile.file.name}`,
                }}
                dimension="small"
                onClick={() => removeFile(uploadedFile.id)}
                data-testid={`removefile-${uploadedFile.id}`}
            />
        </StyledFileRow>
    );
};
