import React from "react";

import { Feedback, Text } from "@accurx/design";
import { toast } from "react-toastify";

export const connectionProblemsError = () => {
    toast(
        <Feedback
            data-testid="error-feedback"
            colour="error"
            title="Progress was not saved"
        >
            <Text skinny>
                There was a connection problem. Try again or contact support
            </Text>
        </Feedback>,
        { toastId: "connection-problems-toast" },
    );
};

export const rowVersionError = () => {
    toast(
        <Feedback
            data-testid="error-feedback"
            colour="error"
            title="Progress was not saved"
        >
            <Text skinny>
                Someone else is also editing this questionnaire. Refresh the
                page and try again.
            </Text>
        </Feedback>,
        { toastId: "row-version-error-toast" },
    );
};

export const genericError = (title: string, content: string): void => {
    toast(
        <Feedback data-testid="error-feedback" colour="error" title={title}>
            <Text skinny>{content}</Text>
        </Feedback>,
        { toastId: "generic-error-toast" },
    );
};

export const genericSuccess = (title: string, content?: string): void => {
    toast(
        <Feedback data-testid="error-feedback" colour="success" title={title}>
            <Text skinny>{content}</Text>
        </Feedback>,
        { toastId: "generic-success-toast" },
    );
};

export const statusChangeError = (customError?: string | null): void => {
    toast(
        <Feedback
            data-testid="error-feedback"
            colour="error"
            title="Progress was not saved"
        >
            <Text skinny>
                {customError ??
                    "There was a problem with updating this questionnaire status. Try again or contact support."}
            </Text>
        </Feedback>,
        { toastId: "status-change-error-toast" },
    );
};

export const savingError = (customError: string | null): void => {
    toast(
        <Feedback
            data-testid="error-feedback"
            colour="error"
            title="Progress was not saved"
        >
            <Text skinny>
                {customError ??
                    "There was a problem with saving this questionnaire status. Try again or contact support."}
            </Text>
        </Feedback>,
        { toastId: "saving-error-toast" },
    );
};
