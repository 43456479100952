import React from "react";

import { Feedback } from "@accurx/design";

import { ConversationDisplaySummary } from "shared/concierge/conversations/types/conversation.types";

import { CONVERSATION_GROUP_LIST_SPACING } from "./ConversationGroup.constants";
import { StyledConversationList } from "./ConversationGroupLayout.styles";
import {
    ConversationListItem,
    ConversationListItemAnalyticsClickCallback,
} from "./ConversationListItem";

export type ConversationGroupProps = {
    conversations: ConversationDisplaySummary[];
    /**
     * As with ConversationGroupPage - it is not yet clear splitting out dedicated display components
     * for conversation summary vs item views are necessary.
     * */
    showDisplayItemDate: boolean;
    showLastItemIndicator: boolean;
    conversationListItemAnalytics: ConversationListItemAnalyticsClickCallback;
};

export const ConversationGroup = (
    props: ConversationGroupProps,
): JSX.Element => {
    if (props.conversations.length === 0) {
        return (
            <Feedback colour="information" iconName="None">
                There are no conversations in this section right now
            </Feedback>
        );
    }
    return (
        <>
            <StyledConversationList
                aria-label="Conversation group"
                gutter={CONVERSATION_GROUP_LIST_SPACING}
            >
                {props.conversations.map((conversation) => (
                    <ConversationListItem
                        key={conversation.id}
                        conversation={conversation}
                        showDisplayItemDate={props.showDisplayItemDate}
                        showLastItemIndicator={props.showLastItemIndicator}
                        conversationListItemAnalytics={
                            props.conversationListItemAnalytics
                        }
                    />
                ))}
            </StyledConversationList>
        </>
    );
};
