import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { getAccumailTemplate } from "api/Accumail/AccumailTemplatesApi";
import { AccumailTemplate } from "app/practices/accumail/templates.types";

import { ACCUMAIL_TEMPLATES_CACHE_KEY } from "./useAccumailTemplatesQuery";

export const ACCUMAIL_TEMPLATE_CACHE_KEY = "AccumailTemplate";

type AccumailTemplateQueryVariables = {
    workspaceId: number;
    templateId: string;
};

export const useAccumailTemplateQuery = createQueryHook<
    AccumailTemplateQueryVariables,
    AccumailTemplate
>(({ workspaceId, templateId: id }) => ({
    cacheTime: 0,
    queryKey: [ACCUMAIL_TEMPLATES_CACHE_KEY, { workspaceId, id }],
    queryFn: async () => {
        const response = await getAccumailTemplate(workspaceId, id);
        return returnDataOrThrow(response);
    },
}));
