import { Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Container } from "../Container";

export const Nav = styled.nav`
    color: ${Tokens.COLOURS.greyscale.white};
    background-color: ${Tokens.COLOURS.primary.green[100]};
    padding: ${Tokens.SIZES[2]} 0;
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const Logo = styled.img`
    width: 120px;
    height: 16px;
`;

export const NavContainer = styled(Container)`
    display: flex;
    align-items: center;
`;

const SharedButtonStyles = css`
    background-color: ${Tokens.COLOURS.primary.green["100"]};
    border: none;
    border-radius: ${Tokens.BORDERS.radius};
    color: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: ${Tokens.SIZES["1"]} ${Tokens.SIZES["1.5"]};
    transition: background-color 0.2s ease;
    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        background-color: ${Tokens.COLOURS.primary.green["130"]};
        box-shadow: none;
    }
    &:focus,
    &:active {
        box-shadow: none !important;
        border: 1px solid ${Tokens.COLOURS.greyscale.night};
    }
    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${Tokens.COLOURS.primary.blue[50]} !important;
    }
`;

export const IconLink = styled(Link)`
    ${SharedButtonStyles}
    border: 1px solid transparent;
    // extra -1px to account for border
    margin-left: calc(-${Tokens.SIZES["2"]} - 1px);
    padding: ${Tokens.SIZES["1.5"]} ${Tokens.SIZES["2"]};
`;

export const NavButton = styled.button`
    ${SharedButtonStyles}
    border: 1px solid ${Tokens.COLOURS.primary.green["130"]};
    margin-left: ${Tokens.SIZES["2"]};
    max-width: 280px;
`;

export const NavButtonIcon = styled.span`
    display: inline-block;
`;

export const NavButtonText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const NavMenuDivider = styled.hr`
    margin: 0 ${Tokens.SIZES["1.5"]};
`;
