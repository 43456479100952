import React, { ChangeEvent, useState } from "react";

import { RadioGroup } from "@accurx/design";

import { MeasurementValues } from "./MeasurementTypeSelector.types";

// Typing for Form ref: https://epicreact.dev/how-to-type-a-react-form-on-submit-handler/
type FormElements = HTMLFormControlsCollection & {
    measurementRadioGroup: HTMLInputElement;
};
export type MeasurementFormElement = HTMLFormElement & {
    readonly elements: FormElements;
};

export const MeasurementsSelector = (): JSX.Element => {
    const [checkedValue, setCheckedValue] = useState<MeasurementValues>();

    const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckedValue(e.target.value as MeasurementValues);
    };

    return (
        <RadioGroup
            formFieldWrapperProps={{ label: "Select measurement question" }}
            name="measurementRadioGroup"
            onChange={handleCheckedChange}
            checkedValue={checkedValue}
            theme="bordered"
            radioInputs={[
                {
                    id: MeasurementValues.Height,
                    label: "Height (m)",
                    value: MeasurementValues.Height,
                },
                {
                    id: MeasurementValues.Weight,
                    label: "Weight (kg)",
                    value: MeasurementValues.Weight,
                },
            ]}
        />
    );
};
