import {
    TeamAssigneeSummarySchema,
    UserAssigneeSummarySchema,
} from "domains/concierge/schemas/AssigneeSummarySchema";
import { PatientSummarySchema } from "domains/concierge/schemas/PatientSummarySchema";
import { z } from "zod";

const UserReadDataSchema = z.object({
    readAt: z
        .string()
        .datetime({
            offset: true,
        })
        .nullable(),
    byTeamId: z.record(z.literal(true)).nullable().optional(),
});

const ItemCreatedByUserSchema = z.object({
    type: z.literal("User"),
    id: z.string(),
});

const ItemCreatedByWorkspaceUserSchema = z.object({
    type: z.literal("WorkspaceUser"),
    userId: z.string(),
    workspaceId: z.string(),
});

const ItemCreatedByContactSchema = z.object({
    type: z.literal("Contact"),
    displayName: z.string(),
    emailAddress: z.string(),
});

const ItemCreatedByPatientSchema = z.object({
    type: z.literal("Patient"),
    id: z.string().optional(),
});

const ItemCreatedBySystemSchema = z.object({
    type: z.literal("System"),
});

const TextSegmentSchema = z.object({
    type: z.literal("Text"),
    text: z.string(),
});

const UserTagSegmentSchema = z.object({
    type: z.literal("UserTag"),
    userId: z.string(),
});

const TeamTagSegmentSchema = z.object({
    type: z.literal("TeamTag"),
    teamId: z.string(),
});

export const NoteSegmentSchema = z.discriminatedUnion("type", [
    TextSegmentSchema,
    UserTagSegmentSchema,
    TeamTagSegmentSchema,
]);

export const AttachmentEventSchema = z.object({
    eventTime: z.string().datetime({
        offset: true,
    }),
    userId: z.string().nullable(),
    isSoftDelete: z.boolean(),
    description: z.string().nullable(),
});

export const AttachmentSchema = z.object({
    id: z.string(),
    displayName: z.string().optional(),
    event: AttachmentEventSchema.optional(),
});

export const PatientResponseSchema = z.object({
    displayQuestionBold: z.boolean(),
    question: z.string().nullable(),
    answer: z.string().nullable(),
    attachments: z.array(AttachmentSchema),
});

export const PatientResponseSectionSchema = z.object({
    responses: z.array(PatientResponseSchema),
});

export const FloreyResponseReferenceSchema = z.object({
    text: z.string().nullable(),
    link: z.string().nullable(),
});

export const SuggestedPatientMatchSchema = PatientSummarySchema.pick({
    firstName: true,
    familyName: true,
    prefixName: true,
    gender: true,
    dateOfBirth: true,
    externalIds: true,
    ageYear: true,
}).extend({
    postCode: z.string().nullable(),
});

export const PatientProxySchema = z.object({
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    relationshipToPatient: z.string().nullable(),
    patientPreferredPhoneNumber: z.string().nullable(),
});

export const BaseItemSchema = z.object({
    id: z.string(),
    serverId: z.string(),
    readDataByUserId: z.record(UserReadDataSchema),
    createdAt: z.string().datetime({
        offset: true,
    }),
});

export const DeliveryStatusSchema = z.enum([
    "Unknown",
    "Queued",
    "Cancelled",
    "Sent",
    "Delivered",
    "Failed",
]);

const SaveToRecordStatusSchema = z.enum(["NotSaved", "Saved", "Failed"]);

const SaveToRecordEventSchema = z.object({
    savedAt: z.string().datetime({
        offset: true,
    }),
    savedByUserId: z.string().nullable(),
    saveSuccess: z.boolean(),
});

const SaveToRecordStateSchema = z.object({
    status: SaveToRecordStatusSchema,
    saveEvents: z.array(SaveToRecordEventSchema),
});

export const ReceptionSchema = z.object({
    userId: z.string().nullable(),
});

const DeliveryInfoSchema = z.object({
    deliveryScheduledAt: z.string().optional().nullable(),
    deliveryStatus: DeliveryStatusSchema,
    deliveryStatusUpdatedAt: z.string().optional().nullable(),
});

export const PatientSmsItemSchema = BaseItemSchema.extend({
    contentType: z.literal("PatientSms"),
    mobileNumber: z.string(),
    body: z.string(),
    createdBy: ItemCreatedByUserSchema,
    saveToRecordState: SaveToRecordStateSchema,
    messageId: z.string(),
}).merge(DeliveryInfoSchema);

export const PatientEmailItemSchema = BaseItemSchema.extend({
    contentType: z.literal("PatientEmail"),
    emailAddress: z.string(),
    body: z.string(),
    createdBy: ItemCreatedByUserSchema,
    saveToRecordState: SaveToRecordStateSchema,
    messageId: z.string(),
}).merge(DeliveryInfoSchema);

export const NhsAppMessageItemSchema = BaseItemSchema.extend({
    contentType: z.literal("NhsAppMessage"),
    body: z.string(),
    createdBy: ItemCreatedByUserSchema,
    saveToRecordState: SaveToRecordStateSchema,
    messageId: z.string(),
}).merge(DeliveryInfoSchema);

export const NoteItemSchema = BaseItemSchema.extend({
    contentType: z.literal("Note"),
    segments: z.array(NoteSegmentSchema),
    createdBy: z.discriminatedUnion("type", [
        ItemCreatedByUserSchema,
        ItemCreatedBySystemSchema,
    ]),
});

export const ClinicianMessageItemSchema = BaseItemSchema.extend({
    contentType: z.literal("ClinicianMessage"),
    body: z.string(),
    trimmedContent: z.string().optional(),
    readStatus: z.enum(["None", "Delivered", "Read"]),
    attachments: z.array(AttachmentSchema),
    createdBy: z.discriminatedUnion("type", [
        ItemCreatedByContactSchema,
        ItemCreatedByWorkspaceUserSchema,
    ]),
});

export const PatientSingleResponseItemSchema = BaseItemSchema.extend({
    contentType: z.literal("PatientSingleResponse"),
    createdBy: ItemCreatedByPatientSchema,
    sections: z.array(PatientResponseSectionSchema),
    saveToRecordState: SaveToRecordStateSchema,
});

export const FloreyResponseItemSchema = BaseItemSchema.extend({
    contentType: z.literal("FloreyResponseNote"),
    createdBy: ItemCreatedByPatientSchema,
    sections: z.array(PatientResponseSectionSchema),
    floreyName: z.string().nullable(),
    reference: FloreyResponseReferenceSchema.nullable(),
    saveToRecordState: SaveToRecordStateSchema,
    markdown: z.string().nullable(),
});

export const PatientTriageRequestItemSchema = BaseItemSchema.extend({
    contentType: z.literal("PatientTriageRequestNote"),
    createdBy: ItemCreatedByPatientSchema,
    sections: z.array(PatientResponseSectionSchema),
    requestType: z.enum(["Admin", "Medical", "SelfReferral"]),
    requestTitle: z.string().nullable(),
    initialRequestMatchState: z.enum(["NoMatch", "Suggested", "Verified"]),
    suggestedMatch: SuggestedPatientMatchSchema.nullable(),
    patientProxy: PatientProxySchema.nullable(),
    saveToRecordState: SaveToRecordStateSchema,
    reception: ReceptionSchema.nullable(),
});

export const PatientAppointmentRequestItemSchema = BaseItemSchema.extend({
    contentType: z.literal("PatientAppointmentRequestNote"),
    createdBy: ItemCreatedByPatientSchema,
    sections: z.array(PatientResponseSectionSchema),
    requestType: z.enum([
        "None",
        "Reschedule",
        "Cancellation",
        "General enquiry",
    ]),
    saveToRecordState: SaveToRecordStateSchema,
});

export const StateChangeItemSchema = BaseItemSchema.extend({
    contentType: z.literal("StateChange"),
    changeType: z.enum([
        "Done",
        "ReOpened",
        "PatientMatch",
        "PatientUnmatch",
        "Urgent",
        "NonUrgent",
        "Assignment",
    ]),
    createdBy: z.discriminatedUnion("type", [
        ItemCreatedByUserSchema,
        ItemCreatedBySystemSchema,
    ]),
    assignedTo: z
        .union([UserAssigneeSummarySchema, TeamAssigneeSummarySchema])
        .optional(),
    note: z.string().optional(),
});

export const LabelTagItemSchema = BaseItemSchema.extend({
    contentType: z.literal("LabelTag"),
    createdBy: ItemCreatedByUserSchema,
    label: z.string(),
    saveToRecordState: SaveToRecordStateSchema,
});

export const FailedDeliveryReceiptLinkItemSchema = BaseItemSchema.extend({
    contentType: z.literal("FailedDeliveryReceiptLink"),
    linkedItemId: z.string(),
    createdBy: ItemCreatedBySystemSchema,
});

export const VideoConsultLinkItemSchema = BaseItemSchema.extend({
    contentType: z.literal("VideoConsultLink"),
    linkedItemId: z.string(),
    createdBy: ItemCreatedBySystemSchema,
    clinicianUrl: z.string(),
});

export const SelfBookItemSchema = BaseItemSchema.extend({
    contentType: z.literal("SelfBookNote"),
    createdBy: z.discriminatedUnion("type", [
        ItemCreatedByPatientSchema,
        ItemCreatedBySystemSchema,
    ]),
    markdown: z.string(),
    fallbackSenderDisplayName: z.string(),
    theme: z.enum(["Information", "Warning", "Alarm"]),
});

export const ConversationItemSchema = z.discriminatedUnion("contentType", [
    PatientSmsItemSchema,
    PatientEmailItemSchema,
    NhsAppMessageItemSchema,
    NoteItemSchema,
    PatientSingleResponseItemSchema,
    FloreyResponseItemSchema,
    PatientTriageRequestItemSchema,
    PatientAppointmentRequestItemSchema,
    StateChangeItemSchema,
    FailedDeliveryReceiptLinkItemSchema,
    VideoConsultLinkItemSchema,
    SelfBookItemSchema,
    LabelTagItemSchema,
    ClinicianMessageItemSchema,
]);

export type SaveToRecordState = z.infer<typeof SaveToRecordStateSchema>;
export type SaveToRecordEvent = z.infer<typeof SaveToRecordEventSchema>;
export type DeliveryStatus = z.infer<typeof DeliveryStatusSchema>;
export type NoteSegment = z.infer<typeof NoteSegmentSchema>;
export type SuggestedPatientMatch = z.infer<typeof SuggestedPatientMatchSchema>;
export type PatientProxy = z.infer<typeof PatientProxySchema>;
export type Reception = z.infer<typeof ReceptionSchema>;
export type AttachmentEvent = z.infer<typeof AttachmentEventSchema>;
export type Attachment = z.infer<typeof AttachmentSchema>;
export type PatientResponse = z.infer<typeof PatientResponseSchema>;
export type PatientResponseSection = z.infer<
    typeof PatientResponseSectionSchema
>;
export type FloreyResponseReference = z.infer<
    typeof FloreyResponseReferenceSchema
>;
export type BaseItem = z.infer<typeof BaseItemSchema>;
export type PatientSmsItem = z.infer<typeof PatientSmsItemSchema>;
export type PatientEmailItem = z.infer<typeof PatientEmailItemSchema>;
export type NhsAppMessageItem = z.infer<typeof NhsAppMessageItemSchema>;
export type NoteItem = z.infer<typeof NoteItemSchema>;
export type ClinicianMessageItem = z.infer<typeof ClinicianMessageItemSchema>;
export type PatientSingleResponseItem = z.infer<
    typeof PatientSingleResponseItemSchema
>;
export type FloreyResponseItem = z.infer<typeof FloreyResponseItemSchema>;
export type PatientTriageRequestItem = z.infer<
    typeof PatientTriageRequestItemSchema
>;
export type PatientAppointmentRequestItem = z.infer<
    typeof PatientAppointmentRequestItemSchema
>;
export type StateChangeItem = z.infer<typeof StateChangeItemSchema>;
export type LabelTagItem = z.infer<typeof LabelTagItemSchema>;
export type FailedDeliveryReceiptLinkItem = z.infer<
    typeof FailedDeliveryReceiptLinkItemSchema
>;
export type VideoConsultLinkItem = z.infer<typeof VideoConsultLinkItemSchema>;
export type SelfBookItem = z.infer<typeof SelfBookItemSchema>;
export type ConversationItem = z.infer<typeof ConversationItemSchema>;

export type ItemCreatedByUser = z.infer<typeof ItemCreatedByUserSchema>;
export type ItemCreatedByEmailContact = z.infer<
    typeof ItemCreatedByContactSchema
>;
export type ItemCreatedBySystem = z.infer<typeof ItemCreatedBySystemSchema>;
export type ItemCreatedByPatient = z.infer<typeof ItemCreatedByPatientSchema>;

export type TeamTagSegment = z.infer<typeof TeamTagSegmentSchema>;
export type UserTagSegment = z.infer<typeof UserTagSegmentSchema>;
export type TextSegment = z.infer<typeof TextSegmentSchema>;
