import { BatchSettings } from "@accurx/api/patient-messaging";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

interface BatchConfigurationParameters {
    workspaceId: number;
    batchEmailFlow: boolean;
}

export const getBatchConfig = ({
    workspaceId,
    batchEmailFlow,
}: BatchConfigurationParameters): Promise<IWrappedResult<BatchSettings>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "api/patientMessaging/batchMessaging/:workspaceId/settings",
            params: { workspaceId: workspaceId.toString() },
            query: `?${new URLSearchParams({
                batchEmailFlow: batchEmailFlow.toString(),
            }).toString()}`,
        }),
    );

export const useBatchConfiguration = ({
    workspaceId,
    batchEmailFlow,
}: BatchConfigurationParameters) =>
    useQuery({
        queryKey: [
            "BatchMessaging",
            "Configuration",
            { workspaceId, batchEmailFlow },
        ],
        queryFn: async () => {
            const response = await getBatchConfig({
                workspaceId,
                batchEmailFlow,
            });
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(
                response.error ?? "Failed to retrieve batch configuration",
            );
        },
        refetchOnMount: true,
    });
