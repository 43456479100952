import VideoCompatabilityService from "./VideoCompatibilityService";

// Action Types

export const VIDEO_COMPATIBILITY_CHECK_STARTED =
    "VIDEO_COMPATIBILITY_CHECK_STARTED";
export const VIDEO_COMPATIBILITY_CHECK_FINISHED =
    "VIDEO_COMPATIBILITY_CHECK_FINISHED";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface VideoCompatibilityCheckStartedAction {
    type: typeof VIDEO_COMPATIBILITY_CHECK_STARTED;
}

interface VideoCompatibilityCheckFinishedAction {
    type: typeof VIDEO_COMPATIBILITY_CHECK_FINISHED;
    browserCheck: [boolean, string | null];
    networkCheck: [boolean, string | null];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | VideoCompatibilityCheckStartedAction
    | VideoCompatibilityCheckFinishedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    checkVideoCompatibility:
        (userAgent: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: VIDEO_COMPATIBILITY_CHECK_STARTED,
            });

            const browserCheck =
                await VideoCompatabilityService.isBrowserSupportedByWhereby(
                    userAgent,
                );
            const networkCheck = await VideoCompatabilityService.isNetworkOk();

            dispatch({
                type: VIDEO_COMPATIBILITY_CHECK_FINISHED,
                browserCheck,
                networkCheck,
            });
        },
};
