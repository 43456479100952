import { ReactNode, createContext, useCallback } from "react";

import { useAuth } from "@accurx/auth";
import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    Log,
} from "@accurx/shared";
import { useLocation } from "react-router";

import { Events } from "./types/events";

type TrackFunction = <E extends Events>(...e: E) => void;

export const AnalyticsContext = createContext<
    (workspaceId?: number | null) => TrackFunction
>(() => () => null);

/*
 * Bit hacky but we allow overriding the `_track`
 * function to make testing easier.
 */
export const AnalyticsProvider = ({
    _track = GenericTrackEvent,
    children,
}: {
    _track?: typeof GenericTrackEvent;
    children: ReactNode;
}) => {
    const location = useLocation();
    const { user } = useAuth();
    const searchParams = new URLSearchParams(location.search);
    const externalReferrer = searchParams.get("externalreferrer");

    const track = useCallback(
        (contextWorkspaceId?: number | null) =>
            <E extends Events>(...[event, properties]: E) => {
                if (!user.isLoggedIn) {
                    return null;
                }

                const workspace = user.organisations.find(
                    (o) => contextWorkspaceId && o.orgId === contextWorkspaceId,
                );

                const [object, objectType, action] = event.split(" ") as [
                    string,
                    EventObjectType,
                    EventAction,
                ];
                const userId = user.analyticsUserIdentifier;
                const userOrganisationId = workspace?.orgId.toString() ?? null;
                const userHealthcareRole = user.healthcareRole;
                const isUserApproved = workspace
                    ? workspace.settings.isApprovedUser
                    : null;
                const userOrganisationRole = workspace
                    ? workspace.settings.isAdminUser
                        ? "Admin"
                        : "Unknown"
                    : null;
                const workspaceSpecialtyList = workspace?.workspaceSpecialties
                    .length
                    ? workspace.workspaceSpecialties
                    : null;

                _track({
                    object,
                    objectType,
                    action,
                    properties: {
                        userId,
                        userOrganisationId,
                        userHealthcareRole,
                        isUserApproved,
                        userOrganisationRole,
                        userIsLoggedIn: true,
                        workspaceSpecialtyList,
                        externalReferrer,
                        eventUserType: EventUserType.HealthcareProfessional,
                        eventVersion: 1, // default, can be overriden
                        ...properties,
                    },
                });
            },
        [_track, user, externalReferrer],
    );

    if (!user.isLoggedIn) {
        return (
            <AnalyticsContext.Provider
                value={() => () => {
                    Log.warn(
                        "AnalyticsProvider is not supported for logged out pages",
                    );
                    return null;
                }}
            >
                {children}
            </AnalyticsContext.Provider>
        );
    }

    return (
        <AnalyticsContext.Provider value={track}>
            {children}
        </AnalyticsContext.Provider>
    );
};
