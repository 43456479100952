import { useMemo, useRef } from "react";

import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { FloreyResponseItem } from "@accurx/concierge/types";
import { getFloreyResponsePDFDownloadUrl } from "@accurx/concierge/util/getFloreyResponsePDFDownloadUrl";
import { itemHasAttachments } from "@accurx/concierge/util/itemHasAttachments";
import * as UI from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { AccurxLogoAvatar } from "domains/inbox/components/ConversationItem/components/AccurxLogoAvatar/AccurxLogoAvatar";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { DownloadAsPDFButton } from "domains/inbox/components/ConversationItem/components/DownloadAsPDFButton/DownloadAsPDFButton";
import { FreeText } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText";
import { ItemAttachments } from "domains/inbox/components/ConversationItem/components/ItemAttachments/ItemAttachments";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { PatientNameTag } from "domains/inbox/components/ConversationItem/components/PatientNameTag/PatientNameTag";
import { QuestionnaireResponse } from "domains/inbox/components/ConversationItem/components/QuestionnaireResponse/QuestionnaireResponse";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { useGetPatientToClinicianMessageInfo } from "domains/inbox/components/ConversationItem/useCopyInfo";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { useItemAttachmentAnalytics } from "domains/inbox/hooks/util/useItemAttachmentAnalytics";
import { userflowIds } from "domains/inbox/util";
import ReactMarkdown from "react-markdown";

import { SaveFloreyPdfToRecordButton } from "../../components/SaveItemAction/SaveFloreyPdfToRecordButton";
import {
    StyledReferenceContainer,
    StyledReferenceLink,
    StyledReferenceTitle,
} from "./FloreyResponseNote.styles";

const DidNotRespond = ({
    item,
    conversation,
}: ConversationItemComponent<FloreyResponseItem>) => {
    const messageBody = `Patient has not completed their ${
        item.floreyName ?? "Questionnaire"
    }.`;
    const copyInfo = useGetPatientToClinicianMessageInfo({
        messageBody,
        dateCreatedAt: item.createdAt,
    });

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={"yellow"}
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={<AccurxLogoAvatar />}
                        name="Accurx"
                    />
                    <div
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color="yellow" size="small">
                            System
                        </UI.Ds.Badge>
                    </div>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <p>{messageBody}</p>
                    <SaveItemAction
                        conversation={conversation}
                        item={item}
                        getTextToCopy={copyInfo}
                    />
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};

export const FloreyResponseNote = ({
    item,
    conversation,
}: ConversationItemComponent<FloreyResponseItem>) => {
    const workspaceId = useCurrentWorkspace().orgId;
    const patientId = item.createdBy.id;
    const patient = usePatient({ patientId });

    const hasAttachments = useMemo(() => itemHasAttachments(item), [item]);
    const textContentRef = useRef<HTMLDivElement>(null);
    const medicalRecordConnection = useMedicalRecordConnection();
    const canSaveToRecord =
        medicalRecordConnection.capabilities?.saveToRecord === true;
    const hasBeenSavedAlready = item.saveToRecordState.status === "Saved";

    /**
     * UHL integration
     * When this feature flag is enabled, the user
     * might be able to save a PDF version of the Florey
     * to their medical system.
     * When enabled, the user also needs
     * - to not have a native integration available
     * - the item must not have been previously saved to a
     * medical record
     */
    const isSavePdfToRecordEnabled = useFeatureFlag(
        "CitoDocumentStoreIntegration",
    );
    const downloadAsPdfUrl = getFloreyResponsePDFDownloadUrl({
        floreyResponseNote: item,
        workspaceId,
    });

    const copyInfo = useGetPatientToClinicianMessageInfo({
        patientId: conversation.regardingPatientId,
        messageBody: textContentRef.current?.innerText ?? "",
        dateCreatedAt: item.createdAt,
    });

    const {
        trackConversationAttachmentPreviewLinkClick,
        trackConversationAttachmentRemoveButtonClick,
        trackConversationAttachmentRemoveMenuItemClick,
    } = useItemAttachmentAnalytics({ conversation });

    if (item.sections.length === 0) {
        return <DidNotRespond item={item} conversation={conversation} />;
    }

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={patient ? "blue" : "yellow"}
            >
                <ConversationItemHeader>
                    <PatientNameTag patientId={patientId} />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge
                            color={patient ? "blue" : "yellow"}
                            size="small"
                        >
                            Patient
                        </UI.Ds.Badge>
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <UI.Flex flexDirection="column" gap="3">
                        <UI.Flex
                            flexDirection="column"
                            gap="1.5"
                            ref={textContentRef}
                        >
                            {item.markdown ? (
                                <ReactMarkdown linkTarget="_blank">
                                    {item.markdown}
                                </ReactMarkdown>
                            ) : (
                                <>
                                    <QuestionnaireResponse
                                        title={item.floreyName ?? undefined}
                                        sections={item.sections}
                                        renderAttachments={({
                                            attachments,
                                        }) => (
                                            <ItemAttachments
                                                attachments={attachments}
                                                conversationId={conversation.id}
                                                itemId={item.id}
                                                patientId={patientId}
                                                getAttachmentUrl={({
                                                    attachment,
                                                }) => {
                                                    if (!patientId) {
                                                        Log.warn(
                                                            `No patient ID for attachment ${attachment.id}`,
                                                        );
                                                        return `/api/conversation/web/patientupload/${workspaceId}/undefined/${attachment.id}`;
                                                    }
                                                    return `/api/conversation/web/patientupload/${workspaceId}/${patientId}/${attachment.id}`;
                                                }}
                                                onItemPreview={
                                                    trackConversationAttachmentPreviewLinkClick
                                                }
                                                onItemDeleteClick={
                                                    trackConversationAttachmentRemoveButtonClick
                                                }
                                                onItemDeleteConfirm={({
                                                    reason,
                                                }) => {
                                                    trackConversationAttachmentRemoveMenuItemClick(
                                                        reason,
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                    {!!item.reference && (
                                        <StyledReferenceContainer>
                                            <StyledReferenceTitle>
                                                Reference:
                                            </StyledReferenceTitle>
                                            {!!item.reference.text && (
                                                <FreeText>
                                                    {item.reference.text}
                                                </FreeText>
                                            )}
                                            {!!item.reference.link && (
                                                <StyledReferenceLink
                                                    href={item.reference.link}
                                                    openInNewTab
                                                >
                                                    <UI.Link.Text
                                                        text={
                                                            item.reference.link
                                                        }
                                                    />
                                                    <span aria-hidden="true">
                                                        <UI.Icon
                                                            name={"OpenWindow"}
                                                            size={2}
                                                            colour={"blue"}
                                                            title={
                                                                "Opens in new tab"
                                                            }
                                                        />
                                                    </span>
                                                </StyledReferenceLink>
                                            )}
                                        </StyledReferenceContainer>
                                    )}
                                </>
                            )}
                        </UI.Flex>
                        <UI.Item>
                            <UI.Flex gap="1" flexDirection="column">
                                <SaveItemAction
                                    conversation={conversation}
                                    item={item}
                                    getTextToCopy={copyInfo}
                                    hasAttachments={hasAttachments}
                                />
                                {!canSaveToRecord &&
                                    (isSavePdfToRecordEnabled ? (
                                        !hasBeenSavedAlready && (
                                            <SaveFloreyPdfToRecordButton
                                                item={item}
                                            />
                                        )
                                    ) : (
                                        <DownloadAsPDFButton
                                            downloadUrl={downloadAsPdfUrl}
                                        />
                                    ))}
                            </UI.Flex>
                        </UI.Item>
                    </UI.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
