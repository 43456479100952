import { useContext, useMemo } from "react";

import { useParams } from "react-router-dom";

import { AnalyticsContext } from "../context";
import { parseUrlId } from "./helpers/parseUrlId";

/** Provides a track function to send analytics events with default from the current
 * user and workspace.
 *
 * Note: Accepts an optional workspaceId - this is to support pages that are not currently
 * on a route with a workspaceId. This should only be used if there is not workspaceId
 * or orgId in the URL.
 */
export const useAnalytics = ({
    workspaceId,
}: { workspaceId?: number | null } = {}) => {
    const context = useContext(AnalyticsContext);
    const { orgId: orgIdFromUrl } = useParams<{
        orgId?: string;
    }>();
    const { workspaceId: workspaceIdFromUrl } = useParams<{
        workspaceId?: string;
    }>();

    const workspaceIdForContext =
        workspaceId ||
        parseUrlId(workspaceIdFromUrl) ||
        parseUrlId(orgIdFromUrl);

    return useMemo(
        () => context(workspaceIdForContext),
        [workspaceIdForContext, context],
    );
};
