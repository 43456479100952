export type View = "me" | "team";

export function isView(view: string | null): view is View {
    if (view === null) {
        return false;
    }
    return ["me", "workspace"].includes(view);
}

export type ConversationSummaryProps = {
    onClickSummary: () => void;
    caseReference: string;
    workspaceName: string;
    patientName?: string;
    patientAge?: number;
    patientGender?: string;
    assigneeName?: string;
    senderName: string;
    messageText: string;
    messageDate: string;
    workspaceConversationId: string;
    workspaceId: number;
};
