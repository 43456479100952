import { ReactNode } from "react";

import { Container } from "app/organisations/shared/Container";

import { StyledHeader } from "./Header.styles";
import { Actions, TabList } from "./components";

export const Header = ({ children }: { children: ReactNode }) => {
    return (
        <StyledHeader>
            <Container>{children}</Container>
        </StyledHeader>
    );
};

Header.TabList = TabList;
Header.Actions = Actions;
