import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledEmptyListDiv = styled.div`
    text-align: center;
`;

interface StyledButtonWrapperProps {
    isVisible: boolean;
}

export const StyledButtonWrapper = styled.div<StyledButtonWrapperProps>`
    align-self: flex-end;
    visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;

export const StyledColumns = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        flex-grow: 1;
    }
`;

export const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${Tokens.SIZES[2]};

    & > :not(:last-child) {
        margin-right: 0px;
    }

    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        gap: ${Tokens.SIZES[1]};
    }
`;

export const StyledSplitRow = styled(StyledRow)`
    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        display: block;
    }
    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        & > :not(:last-child) {
            margin-bottom: ${Tokens.SIZES[1]};
        }
    }
`;
