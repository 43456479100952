import React from "react";

import { ButtonLink, Text } from "@accurx/design";
import { i } from "images";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import {
    WhatIsPatientTriageContainer,
    WhatIsPatientTriageTableContainer,
} from "./WhatIsPatientTriage.styles";
import { WhatIsPatientTriageProps } from "./WhatIsPatientTriage.types";

export const WhatIsPatientTriage = ({
    practiceLink,
}: WhatIsPatientTriageProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    function track() {
        ChainAnalyticsTracker.trackPatientTriageCreateRequestButtonClick({
            ...analyticsLoggedInProps,
            objectName: PatientTriageSettingObjectName.CREATE_REQUEST,
            objectType: PatientTriageSettingObjectType.BUTTON,
        });
    }

    return (
        <WhatIsPatientTriageContainer>
            <Text props={{ className: "mb-4" }}>
                Patient Triage frees up your phone lines and saves you time with
                simple online access for your patients
            </Text>
            <ButtonLink
                onClick={track}
                href={practiceLink}
                target="_blank"
                rel="noopener noreferrer"
                dimension="large"
                theme="primary"
                text="Create a patient request to see how it works"
            />

            <WhatIsPatientTriageTableContainer>
                <div className="row align-items-start">
                    <div className="col">
                        <img
                            src={i("patientTriage/accessible.png")}
                            alt="Send request"
                        />
                        <Text variant="subtitle" as="h3">
                            Accessible
                        </Text>
                        <Text>
                            Easy to complete free-text forms, with no need to
                            register or download an app, enables all your
                            patients to request medical guidance online.
                        </Text>
                    </div>
                    <div className="col">
                        <img
                            src={i("patientTriage/simple.png")}
                            alt="Simple inbox"
                        />
                        <Text variant="subtitle" as="h3">
                            Simple
                        </Text>
                        <Text>
                            Short and simple patient requests can be quickly
                            reviewed and triaged, with the ability to assign to
                            your teams all within the Accurx toolbar.
                        </Text>
                    </div>
                    <div className="col">
                        <img
                            src={i("patientTriage/integrated.png")}
                            alt="Save to record"
                        />
                        <Text variant="subtitle" as="h3">
                            Integrated
                        </Text>
                        <Text>
                            Match and open the patient in the patient record
                            system, as well as save requests to the record with
                            the correct SNOMED code, in one-click, from the
                            inbox.
                        </Text>
                    </div>
                </div>
            </WhatIsPatientTriageTableContainer>
        </WhatIsPatientTriageContainer>
    );
};
