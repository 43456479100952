import { FeatureName, useFeatureFlag } from "@accurx/auth";

import { InboxLayout, LayoutProps } from "./InboxLayout";
import { UnifiedInboxLayout } from "./UnifiedInboxLayout";

export const InboxLayoutController = (props: LayoutProps) => {
    const isUnifiedNavigationEnabled = useFeatureFlag(
        FeatureName.UnifiedNavigation,
    );

    if (isUnifiedNavigationEnabled) {
        return (
            <UnifiedInboxLayout {...props}>
                {props.centrePane}
            </UnifiedInboxLayout>
        );
    }

    return <InboxLayout {...props} />;
};
