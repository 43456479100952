import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { SettingsSignature } from "../../practices/userSettings/SettingsSignature";
import { AccountPageLayout } from "./AccountPageLayout";

const MessageSignature = () => {
    useAccurxWebTitle("Update message signature");
    return (
        <AccountPageLayout title={"Message signature"}>
            <SettingsSignature hideHeader />
        </AccountPageLayout>
    );
};
export { MessageSignature };
