import { useEffect, useState } from "react";

import { SearchForPatientByNhsNumberRequest } from "@accurx/api/portal";
import { Card, Ds, Flex, Icon, Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { useSafeAsync } from "api/Api.utils";
import FlemingApiClient from "api/FlemingApiClient";
import {
    PatientListAddPatientRequest,
    PatientListAppointment,
} from "api/FlemingDtos/PatientListDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import {
    SearchForPatientByNHSNumber,
    SearchFormData,
} from "app/searchForPatient/searchForPatientForm/SearchForPatientByNHSNumber";
import {
    demoPatientData,
    searchForPatientFormIds,
} from "app/searchForPatient/searchForPatientForm/SearchFormConstants";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { StyledOverlay } from "./AddPatientsToList.styles";
import BulkUploadPatients from "./BulkUpload/PatientListBulkUploadPatientsComponent";
import {
    getAddSinglePatientServerError,
    getPatientFromPatientId,
} from "./PatientList.helper";
import { actionCreators } from "./PatientListsActions";
import { BulkAddLoadingState } from "./PatientListsReducer";

const AddPatientsToList = (): JSX.Element => {
    const dispatch = useDispatch();
    const safeAsync = useSafeAsync();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const isSearching = useAppSelector(
        ({ searchForPatient }) => searchForPatient.isSearching,
    );
    const currentListPatientAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAdding,
    );
    const organisationId = useAppSelector(
        (state) => state.account.selectedOrganisation,
    );
    const currentListId = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.patientListId,
    );
    const currentListDays = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.appointmentDays || null,
        shallowEqual,
    );
    const currentListPatientDetails = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.patientDetails || null,
        shallowEqual,
    );
    const [addSinglePatientServerError, setAddSinglePatientServerError] =
        useState("");

    const currentListPatientsBulkAddingState = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBulkAdding,
    );
    const [isTestPatientSelected, setIsTestPatientSelected] = useState(false);
    const [initialFormData, setInitialFormData] = useState<SearchFormData>();
    const [isTestPatientSwitchDisabled, setTestPatientSwitchDisabled] =
        useState(false);

    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    });
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const isFormPending = isSearching || currentListPatientAdding;

    const handleTestPatientToggle = (checked: boolean): void => {
        setAddSinglePatientServerError("");
        setIsTestPatientSelected(checked);

        if (checked) {
            FlemingAnalyticsTracker.trackTestPatientToggle(
                analyticsLoggedInProps,
            );
        }
    };

    // Check if the patient list contains the test user & set test switch disabled
    useEffect(() => {
        if (currentListPatientDetails && currentListDays) {
            const anytimePatients = currentListDays.find((day) => !day.date);
            let listContainsTestPatient = false;
            if (anytimePatients) {
                listContainsTestPatient = !!anytimePatients.appointments.find(
                    (apt) => {
                        const patient = getPatientFromPatientId(
                            currentListPatientDetails,
                            apt.patientId,
                        );
                        return patient?.nhsNumber === demoPatientData.nhsNumber;
                    },
                );
            }
            setTestPatientSwitchDisabled(listContainsTestPatient);
        }
    }, [currentListDays, currentListPatientDetails]);

    useEffect(() => {
        if (!isTestPatientSwitchDisabled) {
            if (isTestPatientSelected) {
                setInitialFormData({
                    nhsNumber: demoPatientData.nhsNumber,
                    dobDay: demoPatientData.dobDay,
                    dobMonth: demoPatientData.dobMonth,
                    dobYear: demoPatientData.dobYear,
                });
            } else if (
                initialFormData?.nhsNumber === demoPatientData.nhsNumber
            ) {
                // Clear the form if it was previously filled with test patient data
                setInitialFormData(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTestPatientSwitchDisabled, isTestPatientSelected]);

    const getDuplicatePatient = (
        nhsNumber: string,
    ): PatientListAppointment | undefined => {
        // If we already have anytime patients in the list,
        // check that the patient being added doesn't have the same nhs number
        return currentListDays
            ?.find((day) => !day.date)
            ?.appointments.find((apt) => {
                const patient = getPatientFromPatientId(
                    currentListPatientDetails,
                    apt.patientId,
                );
                return patient?.nhsNumber === nhsNumber;
            });
    };

    const searchAndAddPatient = async (
        request: SearchForPatientByNhsNumberRequest,
        resetForm?: () => void,
    ): Promise<void> => {
        const { dateOfBirthYear, dateOfBirthMonth, dateOfBirthDay, nhsNumber } =
            request;

        analyticsPatientListProps &&
            FlemingAnalyticsTracker.trackPatientListItemAddConfirmButtonClick({
                ...analyticsPatientListProps,
                ...meaningfulActionProps,
                isTestPatient: isTestPatientSelected,
            });

        if (!!organisationId && !!currentListId) {
            const duplicatePatient = getDuplicatePatient(nhsNumber);

            if (!!duplicatePatient) {
                resetForm && resetForm();

                analyticsLoggedInProps &&
                    FlemingAnalyticsTracker.trackPatientListDuplicatePatientFound(
                        analyticsLoggedInProps,
                    );

                dispatch(
                    actionCreators.addCurrentListDuplicate(duplicatePatient),
                );
            } else {
                dispatch(actionCreators.addPatientToListStarted());

                const addPatientToListRequest: PatientListAddPatientRequest = {
                    organisationId,
                    patientListId: currentListId,
                    dateOfBirthDay,
                    dateOfBirthMonth,
                    dateOfBirthYear,
                    nhsNumber,
                };

                const { error, result, success } = await safeAsync(
                    FlemingApiClient.addPatientToList(addPatientToListRequest),
                );

                if (success && result !== null) {
                    analyticsPatientListProps &&
                        FlemingAnalyticsTracker.trackPatientListFindAndAddPatientSuccess(
                            {
                                ...analyticsPatientListProps,
                                isTestPatient:
                                    PatientHelper.isDemoPatient(nhsNumber),
                                listSize:
                                    analyticsPatientListProps.listSize + 1,
                            },
                        );

                    dispatch(actionCreators.addPatientToListSuccess(result));

                    resetForm && resetForm();
                } else {
                    analyticsPatientListProps &&
                        FlemingAnalyticsTracker.trackPatientListFindAndAddPatientFailure(
                            {
                                ...analyticsPatientListProps,
                                isTestPatient:
                                    PatientHelper.isDemoPatient(nhsNumber),
                            },
                        );

                    dispatch(actionCreators.addPatientToListFailure());

                    setAddSinglePatientServerError(
                        getAddSinglePatientServerError(error || ""),
                    );
                }
            }
        }
    };

    return (
        <Card
            variant="dashed"
            props={{
                className: "position-relative overflow-hidden",
            }}
            spacing={1.5}
        >
            <>
                {currentListPatientsBulkAddingState !==
                    BulkAddLoadingState.None && (
                    <StyledOverlay>
                        <Icon name="Clock" />
                        <Text
                            variant="label"
                            props={{
                                "data-testid":
                                    "patient-list-patient-form-loading-overlay",
                            }}
                        >
                            Your list is being{" "}
                            {currentListPatientsBulkAddingState ===
                            BulkAddLoadingState.PrepareLoading
                                ? "checked"
                                : "uploaded"}{" "}
                            - This may take a moment
                        </Text>
                    </StyledOverlay>
                )}
                <div>
                    <div className="mb-5">
                        <BulkUploadPatients />
                    </div>
                    <Flex justifyContent="space-between" flexWrap="wrap">
                        <Text
                            variant="label"
                            props={{ className: "mt-0 mr-2" }}
                        >
                            Add individual patient
                        </Text>
                        {isTestPatientSwitchDisabled ? (
                            <Text variant="body" skinny>
                                Test patient already in list
                            </Text>
                        ) : (
                            <Ds.Switch
                                checked={isTestPatientSelected}
                                disabled={isFormPending}
                                onCheckedChange={handleTestPatientToggle}
                                data-userflow-id="test-patient-toggle"
                            >
                                <Ds.Switch.Label>
                                    Use with a test patient
                                </Ds.Switch.Label>
                                <Ds.Switch.Toggle />
                            </Ds.Switch>
                        )}
                    </Flex>
                </div>
                <SearchForPatientByNHSNumber
                    isFormDisabled={isFormPending}
                    onSubmit={searchAndAddPatient}
                    serverError={addSinglePatientServerError}
                    resetServerError={() => setAddSinglePatientServerError("")}
                    initialFormData={initialFormData}
                    formId={searchForPatientFormIds.patientList}
                />
            </>
        </Card>
    );
};

export { AddPatientsToList };
