import { useMemo } from "react";

import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    ConversationItem,
    NhsAppMessageItem,
} from "@accurx/concierge/schemas/ConversationItemSchema";
import {
    FailedDeliveryReceiptLinkItem,
    PatientEmailItem,
    PatientSmsItem,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemHeader,
    ConversationThreadListItem,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { AccurxLogoAvatar } from "domains/inbox/components/ConversationItem/components/AccurxLogoAvatar/AccurxLogoAvatar";
import { CreatedAtTime } from "domains/inbox/components/ConversationItem/components/CreatedAtTime/CreatedAtTime";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { ReadByTooltip } from "domains/inbox/components/ConversationItem/components/ReadByTooltip/ReadByTooltip";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { userflowIds } from "domains/inbox/util";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

import { StyledMessage } from "./FailedDeliveryReceiptLinkNote.styles";

type SupportedItemTypes = PatientSmsItem | PatientEmailItem | NhsAppMessageItem;

const isSupported = (item?: ConversationItem): item is SupportedItemTypes => {
    return (
        item?.contentType === "PatientEmail" ||
        item?.contentType === "PatientSms" ||
        item?.contentType === "NhsAppMessage"
    );
};

const MainBodyMessage = ({ item }: { item: SupportedItemTypes }) => {
    const sender = useUser({ userId: item.createdBy.id });
    const senderName = formatUserDisplayName({
        user: sender,
        considerCurrentUser: false,
    });

    switch (item.contentType) {
        case "PatientSms":
            return (
                <StyledMessage>
                    Could not deliver SMS from <b>{senderName}</b> to{" "}
                    <b>{item.mobileNumber}</b>
                </StyledMessage>
            );

        case "PatientEmail":
            return (
                <StyledMessage>
                    Could not deliver Email from <b>{senderName}</b> to{" "}
                    <b>{item.emailAddress}</b>
                </StyledMessage>
            );

        case "NhsAppMessage":
            return (
                <StyledMessage>
                    Could not deliver NHS App message to patient from{" "}
                    <b>{senderName}</b>
                </StyledMessage>
            );
    }
};

export const FailedDeliveryReceiptLinkNote = ({
    item,
    conversation,
}: ConversationItemComponent<FailedDeliveryReceiptLinkItem>) => {
    const linkedItem = useMemo(
        () => conversation.items.find((i) => i.id === item.linkedItemId),
        [item, conversation],
    );

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color="red"
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={<AccurxLogoAvatar />}
                        name="Accurx"
                    />
                    <div
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        <UI.Ds.Badge color="red" size="small">
                            System
                        </UI.Ds.Badge>
                    </div>
                </ConversationItemHeader>
                <ConversationItemContent>
                    {isSupported(linkedItem) ? (
                        <MainBodyMessage item={linkedItem} />
                    ) : (
                        <StyledMessage>Could not deliver message</StyledMessage>
                    )}
                </ConversationItemContent>
                <ConversationItemFooter>
                    <ReadByTooltip readDataByUserId={item.readDataByUserId} />
                    <CreatedAtTime time={item.createdAt} />
                </ConversationItemFooter>
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
