import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const TabsWrapper = styled.div`
    position: relative;

    background-color: ${Tokens.COLOURS.greyscale.white};

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;

        width: 100%;
        height: 4px;
        background-color: ${Tokens.COLOURS.greyscale.silver};
    }
`;
