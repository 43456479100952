import { Tokens } from "@accurx/design";
import { css } from "styled-components";

const {
    COLOURS: { greyscale },
    SIZES,
} = Tokens;

export type StyledPillProps = {
    $radius: "regular" | "full";
    $color: string;
    $border: boolean;
    $dimension?: "small" | "medium";
};

export const pillStyles = css<StyledPillProps>`
    height: ${(props) => (props.$dimension === "medium" ? SIZES[5] : SIZES[4])};
    width: fit-content;

    white-space: nowrap;
    display: inline-flex;
    align-items: center;

    border: none;
    border-radius: ${(props) =>
        props.$radius === "full" ? "50px" : SIZES[0.5]};
    background-color: ${(props) => props.$color};
    font-size: 0.875rem;

    padding-left: ${SIZES[1]};
    padding-right: ${SIZES[1]};
    box-shadow: ${(props) =>
        props.$border ? `0 0 0 1px ${greyscale.silver}` : "none"};

    &:disabled {
        background-color: ${greyscale.cloud};
    }
`;

export const getButtonBorder = (colour: string) => `
    inset 0 -2px 0 ${colour},
    inset 0 0 0 1px ${colour}
`;
