import React, { useEffect, useMemo } from "react";

import {
    Ds,
    Feedback,
    Flex,
    Item,
    Table,
    Text,
    useTable,
} from "@accurx/design";

import Linkify from "app/sharedComponents/linkify/Linkify";
import { LoadingStatus } from "shared/LoadingStatus";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { AvailabilityCellContent } from "./AvailabilityCellContent";
import { CategoriesFilter } from "./CategoriesFilter";
import {
    StyledTabPanelLayout,
    StyledTableBody,
} from "./ManageTemplates.styles";
import {
    FEEDBACK_SEARCH_TEXT,
    getCategoryPath,
    shouldDisplayCategoryHeader,
} from "./ManageTemplates.utils";
import { TabIds } from "./ManageTemplatesOverview";
import SnomedTag from "./SnomedTag";
import { useManageTemplatesData } from "./hooks";
import { CountDistinctCategories } from "./utils";

type AccurxTemplatesPanelProps = {
    orgId: string;
};

export const AccurxTemplatesPanel = ({
    orgId,
}: AccurxTemplatesPanelProps): JSX.Element => {
    const {
        messageTemplatesAccurx: templates,
        messageTemplatesLoadingStatus: loadingStatus,
        errorText,
    } = useManageTemplatesData();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const formattedData = React.useMemo(
        () =>
            templates.map((template) => ({
                ...template,
                templateName: template.title,
                details: template.body,
                category: getCategoryPath(template.path),
            })),
        [templates],
    );

    const table = useTable({
        data: formattedData ?? [],
        columnDefs: [
            {
                header: "Template name",
                id: "templateName",
            },
            {
                header: "Details",
                id: "details",
            },
            {
                header: "Category",
                id: "category",
            },
            {
                header: "Availability",
                id: "availability",
            },
        ],
    });

    const countDistinctCategories = useMemo(() => {
        return CountDistinctCategories(templates);
    }, [templates]);

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: countDistinctCategories,
            currentTab: TabIds.AccurxTemplates,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties, countDistinctCategories]);
    return (
        <>
            <StyledTabPanelLayout>
                <Ds.Flex gap="3" flexDirection="column">
                    <Text skinny as="h2" variant="subtitle">
                        Accurx Templates
                    </Text>
                    <Text skinny>
                        These templates are provided by Accurx. They cannot be
                        deleted, but they can be "hidden" so that they do not
                        appear for your team. If you would like to edit a
                        template, you can copy the text, create your own, and
                        then hide the Accurx template.
                    </Text>
                    {errorText && (
                        <Feedback colour="error">{errorText}</Feedback>
                    )}
                    <Table>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header colSpan={4}>
                                    <Ds.Grid
                                        columns={{ xs: "1fr", sm: "1fr 1fr" }}
                                        gap="1"
                                    >
                                        <div>
                                            <Text variant="subtitle">
                                                My templates
                                            </Text>
                                            {templates.length !== 0 && (
                                                <Text>
                                                    {FEEDBACK_SEARCH_TEXT}
                                                </Text>
                                            )}
                                        </div>
                                        <CategoriesFilter
                                            templates={templates}
                                            table={table}
                                            currentTable={
                                                TabIds.AccurxTemplates
                                            }
                                        />
                                    </Ds.Grid>
                                </Table.Header>
                            </Table.Row>
                            <Table.Row>
                                {table.columns.map((column) => (
                                    <Table.Header
                                        scope={"col"}
                                        key={column.id}
                                        colour="greyscale"
                                    >
                                        {column.header}
                                    </Table.Header>
                                ))}
                            </Table.Row>
                        </Table.Head>
                        <StyledTableBody>
                            {table.rows.map((row, index) => (
                                <React.Fragment key={row.id}>
                                    {shouldDisplayCategoryHeader({
                                        currentRow: row,
                                        currentIndex: index,
                                        allRows: table.rows,
                                    }) && (
                                        <Table.Row>
                                            <Table.Header
                                                colSpan={5}
                                                scope="colgroup"
                                                colour="greyscale"
                                            >
                                                <Text
                                                    skinny
                                                    variant="subtitle"
                                                    as="span"
                                                >
                                                    {row.categoryHeading}
                                                </Text>
                                            </Table.Header>
                                        </Table.Row>
                                    )}
                                    <Table.Row>
                                        <Table.Header scope="row">
                                            {row.templateName}
                                        </Table.Header>
                                        <Table.DataCell>
                                            <div>
                                                <Linkify>{row.details}</Linkify>
                                                {row.snomedCodes.map(
                                                    (snomedCode, index) => (
                                                        <SnomedTag
                                                            key={`${snomedCode.conceptId}-${index}`}
                                                            text={
                                                                snomedCode.term
                                                            }
                                                            code={
                                                                snomedCode.conceptId
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            {row.category}
                                        </Table.DataCell>
                                        <Table.DataCell>
                                            <AvailabilityCellContent
                                                orgId={orgId}
                                                template={row}
                                            />
                                        </Table.DataCell>
                                    </Table.Row>
                                </React.Fragment>
                            ))}
                        </StyledTableBody>
                        {table.rows.length > 0 && (
                            <Table.Foot>
                                <Table.Row>
                                    <Table.DataCell colSpan={4}>
                                        <Flex justifyContent={"center"}>
                                            <Item>
                                                Showing {table.rows.length}
                                            </Item>
                                        </Flex>
                                    </Table.DataCell>
                                </Table.Row>
                            </Table.Foot>
                        )}
                    </Table>

                    {loadingStatus === LoadingStatus.Loading && (
                        <Ds.Flex justifyContent="center">
                            <Ds.Spinner />
                        </Ds.Flex>
                    )}
                </Ds.Flex>
            </StyledTabPanelLayout>
        </>
    );
};
