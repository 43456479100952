import { useAccurxWebTitle } from "@accurx/navigation";

import { AccountPageLayout } from "../AccountPageLayout";
import { JoinedWorkspaceSection } from "./JoinedWorkspaceSection/JoinedWorkspaceSection";

const WorkspaceAccessPage = () => {
    useAccurxWebTitle("Manage workspace access");

    return (
        <AccountPageLayout title="Workspace Access">
            <JoinedWorkspaceSection />
        </AccountPageLayout>
    );
};

export { WorkspaceAccessPage };
