import {
    QueryObserverLoadingErrorResult,
    QueryObserverRefetchErrorResult,
    QueryObserverResult,
    QueryObserverSuccessResult,
} from "@tanstack/react-query";

import {
    ErrorStatus,
    LoadingStatus,
    SubscriptionResult,
    SuccessStatus,
} from "shared/concierge/types/subscription.types";

export const mapQueryObserverResultToSubscriptionResult = <T>(
    result: QueryObserverResult<T>,
): SubscriptionResult<T> => {
    switch (result.status) {
        case "success":
            return mapSuccessResult(result);
        case "error":
            return mapErrorResult(result);
        case "loading":
            return mapLoadingResult();
    }
};

const mapSuccessResult = <T>(
    result: QueryObserverSuccessResult<T>,
): SuccessStatus<T> => {
    return {
        status: "SUCCESS",
        data: result.data,
        errorMessage: null,
    };
};

const mapErrorResult = (
    result: QueryObserverLoadingErrorResult | QueryObserverRefetchErrorResult,
): ErrorStatus => {
    return {
        status: "ERROR",
        errorMessage: getError(result.error).message,
        data: null,
    };
};

const mapLoadingResult = (): LoadingStatus => {
    return {
        status: "LOADING",
        data: null,
        errorMessage: null,
    };
};

const getError = (err: unknown): Error => {
    if (err instanceof Error) {
        return err;
    }

    if (typeof err === "string") {
        return new Error(err);
    }

    return new Error("Unknown error");
};
