import { FeatureName } from "@accurx/auth";
import { Icon, Text } from "@accurx/design";
import { generatePath } from "react-router";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import {
    ActiveState,
    LinkVariant,
    NavLink,
} from "app/layout/navigationMenu/navLink";
import { ROUTES_PRIMARY } from "shared/Routes";
import { useHasAcceptedCookies } from "store/hooks";

import {
    StyledIconWrapper,
    StyledPrimaryNavButton,
    StyledPrimaryNavLinkText,
} from "../navLink/NavLink.styles";
import { COLOURS, TEXT_VARIANTS } from "../navLink/NavLink.types";

const ITEM_TEXT = "Batch message";
const ITEM_ICON = "Messages";

export const BatchMessagePrimaryLinkListItem = ({
    isCollapsed,
    toggleOnClick,
    workspaceId,
}: {
    isCollapsed: boolean;
    toggleOnClick?: () => void;
    workspaceId: number | null;
}) => {
    const isGenericTrustBatchFlowEnabled = useFeatureFlag(
        FeatureName.WebBatchMessaging,
    );
    const isBatchMessageInterestSignupEnabled = useFeatureFlag(
        FeatureName.WebBatchMessagingHint,
    );

    const hasAcceptedCookies = useHasAcceptedCookies();

    if (isGenericTrustBatchFlowEnabled && workspaceId) {
        return (
            <li>
                <NavLink
                    variant={LinkVariant.Primary}
                    to={generatePath(ROUTES_PRIMARY.batchMessageTrust, {
                        workspaceId,
                    })}
                    text={ITEM_TEXT}
                    onClick={toggleOnClick}
                    icon={ITEM_ICON}
                    isCollapsed={isCollapsed}
                    userflowId="batch-message-trust-primary"
                />
            </li>
        );
    }

    /**
     * Marketing team uses userflow to capture these
     * clicks and get users to sign up to a list.
     * We can only display this if cookies are accepted
     * as userflow requires cookies */
    if (isBatchMessageInterestSignupEnabled && hasAcceptedCookies) {
        return (
            <Text
                as="li"
                props={{ title: ITEM_TEXT }}
                variant={TEXT_VARIANTS.Primary.Inactive}
                colour={COLOURS.Primary.Inactive}
                skinny
            >
                <StyledPrimaryNavButton
                    activestate={ActiveState.Inactive}
                    variant={LinkVariant.Primary}
                    id="batch-message-register-interest"
                    onClick={toggleOnClick}
                >
                    <StyledIconWrapper>
                        <Icon
                            name={ITEM_ICON}
                            theme="Line"
                            colour={COLOURS.Primary.Inactive}
                            size={4}
                        />
                    </StyledIconWrapper>
                    <StyledPrimaryNavLinkText isCollapsed={isCollapsed}>
                        {ITEM_TEXT}
                    </StyledPrimaryNavLinkText>
                </StyledPrimaryNavButton>
            </Text>
        );
    }

    return null;
};
