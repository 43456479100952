import { TabProps } from "@accurx/design";
import { generatePath, useParams } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { DEFAULT_TAB_ID, TabLookup } from "./UsageDashboardPage.consts";

export type UseUsageDashboardPage = () => {
    tab?: TabProps;
    redirect: string;
};

export const useUsageDashboardPage: UseUsageDashboardPage = () => {
    const { orgId, tab: tabId } = useParams<{ orgId: string; tab: string }>();

    const tab = TabLookup[tabId || DEFAULT_TAB_ID];

    // generate a redirect url in case the route
    // we're on points to an invalid tab
    const redirect = generatePath(ROUTES_ORGS.usageDashboard, {
        orgId,
    });

    return { tab, redirect };
};
