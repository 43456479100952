import { useUnreadCountSelector } from "domains/concierge/internal/hooks/useUnreadCountSelector";
import { TeamSummary } from "domains/concierge/types";

export const useTeamTypeUnreadCount = ({
    type,
}: {
    type: TeamSummary["type"];
}): number => {
    return useUnreadCountSelector((state) => {
        const unreadCounts = state.conversations.unreadCounts.ticket.teams;
        const teams = Object.values(state.teams.items).filter(
            (team) => team.type === type && team.isMember,
        );

        return teams.reduce((count, team) => {
            return count + (unreadCounts[team.id] ?? 0);
        }, 0);
    });
};
