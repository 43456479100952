import {
    PatientAppointmentRequestType,
    PatientThreadPatientAppointmentRequestNote,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { mapWriteToRecordState } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapWriteToRecordState";
import { PatientAppointmentRequestItem } from "domains/concierge/types";

import { mapPatientResponseSections } from "./shared/mapPatientResponseSections";

/**
 * Maps ticket Appointment request to conversation Appointment request
 *
 * @param ticketAppointmentRequestItem Appointment request as it's sent from the API
 * @returns mapped Appointment request conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapAppointmentRequest(
    ticketAppointmentRequestItem: PatientThreadPatientAppointmentRequestNote,
): PatientAppointmentRequestItem | undefined {
    if (!ticketAppointmentRequestItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    // Do not accept an appointment request that has no responses
    if (
        !ticketAppointmentRequestItem.message.responses ||
        ticketAppointmentRequestItem.message.responses.length === 0
    ) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    const responses = ticketAppointmentRequestItem.message.responses;
    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketAppointmentRequestItem,
    );

    const requestType = mapAppointmentRequestType(
        ticketAppointmentRequestItem.message.patientRequestType,
    );

    // Log to Sentry, but don't have mapping fail completely
    if (requestType === "None") {
        Log.error("Could not map to a known requestType, defaulting to None", {
            tags: {
                product: "Inbox",
                itemId: ticketAppointmentRequestItem.id,
                itemType: ticketAppointmentRequestItem.type,
            },
        });
    }

    const saveToRecordState = mapWriteToRecordState(
        ticketAppointmentRequestItem.message.writeToRecordState,
    );

    return {
        ...baseConversationItem,
        contentType: "PatientAppointmentRequestNote",
        createdBy: {
            type: "Patient",
            id: ticketAppointmentRequestItem.patientAccuRxId ?? undefined,
        },
        sections: result.sections,
        requestType,
        saveToRecordState,
    };
}

function mapAppointmentRequestType(
    requestType: PatientAppointmentRequestType,
): PatientAppointmentRequestItem["requestType"] {
    switch (requestType) {
        case PatientAppointmentRequestType.Cancel:
            return "Cancellation";
        case PatientAppointmentRequestType.GeneralEnquiry:
            return "General enquiry";
        case PatientAppointmentRequestType.Reschedule:
            return "Reschedule";

        case PatientAppointmentRequestType.None:
        default:
            return "None";
    }
}
