import React from "react";

import { RegisterAccountProductType } from "@accurx/api/account";

import { AppRoute } from "app/AppRoute";
import { Register } from "app/account/Register/Register";
import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { ConfirmEmailVerify } from "./ConfirmEmailVerify";
import { LOGIN_LAYOUT_CONSTANTS } from "./LogIn/LogIn.constants";
import { useGetProductTypeFromUrl } from "./LogIn/hooks";
import { MagicLink } from "./MagicLink/MagicLink";
import { CheckYourInbox } from "./Password/CheckYourInbox";
import { ForgotPassword } from "./Password/ForgotPassword";
import { ResetPassword } from "./Password/ResetPassword";
import { ResetPasswordConfirm } from "./Password/ResetPasswordConfirm";

/** Routes used throughout login flows. Routes in this section use the product search param to determine their layout */
export const ProductLoginRoutesSection = () => {
    const product = useGetProductTypeFromUrl();
    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    const layoutProps =
        product === RegisterAccountProductType.Chain
            ? LOGIN_LAYOUT_CONSTANTS.organisations
            : LOGIN_LAYOUT_CONSTANTS.defaultTrust;

    return (
        <LoginPageLayout {...layoutProps}>
            <AppRoute exact path={ROUTES.checkYourInbox}>
                <CheckYourInbox />
            </AppRoute>
            <AppRoute exact path={ROUTES.forgotPassword}>
                <ForgotPassword />
            </AppRoute>
            <AppRoute exact path={ROUTES.resetPassword}>
                <ResetPassword />
            </AppRoute>
            <AppRoute exact path={ROUTES.resetPasswordConfirm}>
                <ResetPasswordConfirm />
            </AppRoute>
            <AppRoute
                path={ROUTES.register}
                allowRoute={{
                    isAllowed: !isLoggedIn,
                }}
            >
                <Register />
            </AppRoute>
            <AppRoute
                exact
                path={ROUTES.confirmEmailVerify}
                allowRoute={{
                    isAllowed: !isLoggedIn,
                }}
            >
                <ConfirmEmailVerify />
            </AppRoute>
            <AppRoute exact path={ROUTES.magicLink}>
                <MagicLink />
            </AppRoute>
        </LoginPageLayout>
    );
};
