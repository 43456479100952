import React from "react";

import { StyledTemplatesAndQuestionnaireButton } from "../ComposeArea/ComposeArea.styles";

type QuestionnaireButtonProps = {
    hasQuestionnaireSelected: boolean;
    onRemoveQuestionnaireClick: () => void;
    onSeeQuestionnairesClick: () => void;
};

export const QuestionnaireButton = ({
    hasQuestionnaireSelected,
    onRemoveQuestionnaireClick,
    onSeeQuestionnairesClick,
}: QuestionnaireButtonProps) => {
    if (!hasQuestionnaireSelected) {
        return (
            <StyledTemplatesAndQuestionnaireButton
                theme="secondary"
                text="See questionnaires"
                icon={{
                    name: "Questionnaire",
                    colour: "blue",
                }}
                onClick={onSeeQuestionnairesClick}
                data-userflow-id="compose-see-questionnaires"
            />
        );
    }

    return (
        <StyledTemplatesAndQuestionnaireButton
            theme="secondary"
            text={"Remove questionnaire"}
            icon={{ name: "Bin", colour: "red" }}
            onClick={onRemoveQuestionnaireClick}
        />
    );
};
