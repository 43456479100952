import React, { FormEvent, useEffect, useState } from "react";

import { Button, Feedback, StackPanel, Text } from "@accurx/design";
import { FeedbackProps } from "@accurx/design/dist/components/Feedback/Feedback";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";

import { inviteUserToWorkspace } from "api/WorkspaceApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ASSIGN_REFERRED_KEY } from "app/workspaceConversations/components/Conversation/Conversation.constants";
import { isAllowedToRegisterForChain } from "shared/LoginHelper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { useAppSelector } from "store/hooks";

import {
    StyledButton,
    StyledContainer,
    StyledFooter,
    StyledFormFieldWrapper,
    StyledInput,
} from "./InviteUserPage.styles";

const InviteUserPage = (): JSX.Element => {
    useAccurxWebTitle("Invite user to workspace");

    const history = useHistory<{ referredFrom?: string }>();
    const workspaceId = useWorkspaceId();

    const workspaceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [inviteeEmailAddress, setInviteeEmailAddress] = useState("");

    const [emailValidationError, setEmailValidationError] = useState("");

    const [disableSendInviteInputs, setDisableSendInviteInputs] =
        useState(false);

    useEffect(() => {
        FlemingAnalyticsTracker.trackWorkspaceInviteSendPageView(
            analyticsLoggedInProps,
        );
    }, [analyticsLoggedInProps]);

    const isFromAssign =
        history.location.state?.referredFrom === ASSIGN_REFERRED_KEY;

    const handleSendInvite = async (
        workspaceId: number,
        inviteeEmailAddress: string,
    ) => {
        setDisableSendInviteInputs(true);
        const { success, error } = await inviteUserToWorkspace(
            workspaceId,
            inviteeEmailAddress,
        );

        if (success) {
            setInviteeEmailAddress("");

            const feedbackProps: FeedbackProps = {
                colour: "success",
                title: `Invite sent to ${inviteeEmailAddress}`,
            };
            if (isFromAssign) {
                feedbackProps.children = (
                    <Text skinny>
                        You’ll be able to assign them to a conversation once
                        they create an account
                    </Text>
                );
            }

            toast(Feedback(feedbackProps));
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title:
                        error ||
                        `There was a problem inviting ${inviteeEmailAddress} by email`,
                }),
            );
        }
        setDisableSendInviteInputs(false);

        FlemingAnalyticsTracker.trackWorkspaceInviteSend({
            ...analyticsLoggedInProps,
            hasError: !success,
        });
    };

    const formOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!workspaceId) {
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem sending the invite. Please refresh the page and try again.",
                }),
            );

            return;
        }

        const isEmailAllowed = isAllowedToRegisterForChain(inviteeEmailAddress);

        if (!isEmailAllowed) {
            setEmailValidationError("A valid NHS email address is required.");

            return;
        }

        setEmailValidationError("");

        await handleSendInvite(workspaceId, inviteeEmailAddress);
    };

    const navigateToWorkspaceUserManagementPage = () => {
        history.push(
            generatePath(ROUTES_WORKSPACE.workspaceUserManagement, {
                workspaceId,
            }),
        );
    };

    return (
        <>
            <NavSubMenuComponent backCallback={history.goBack} />
            <StyledContainer>
                <StackPanel
                    orientation="vertical"
                    verticalContentAlignment="space-between"
                >
                    <PageHeader
                        title="Invite colleagues"
                        type={PageHeaderType.ListPage}
                    />
                    <Text variant="body" colour="metal">
                        {/* TODO: Update this copy and link once we have it */}
                        {`Invite your colleagues to join ${workspaceName}
                     so you can work on communication tasks together.`}
                    </Text>
                    <form onSubmit={formOnSubmit}>
                        <StyledFormFieldWrapper
                            label="NHS email"
                            labelProps={{
                                htmlFor: "nhs-email",
                                skinny: true,
                            }}
                            errors={[emailValidationError]}
                        >
                            <StyledInput
                                id="nhs-email"
                                type="email"
                                placeholder={"joebloggs@nhs.net"}
                                required
                                disabled={disableSendInviteInputs}
                                value={inviteeEmailAddress}
                                onChange={(e) =>
                                    setInviteeEmailAddress(e.target.value)
                                }
                                data-userflow-id="invite-colleagues-email-input"
                            />
                        </StyledFormFieldWrapper>
                        <StackPanel
                            orientation="horizontal"
                            horizontalContentAlignment="space-between"
                        >
                            <Button
                                type="submit"
                                text="Send invite"
                                data-userflow-id="invite-colleagues-send-button"
                            />
                        </StackPanel>
                    </form>
                </StackPanel>
            </StyledContainer>
            <StyledFooter>
                <StyledButton
                    type="button"
                    text="Done"
                    theme="secondary"
                    onClick={navigateToWorkspaceUserManagementPage}
                />
            </StyledFooter>
        </>
    );
};

export { InviteUserPage };
