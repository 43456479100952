import {
    IdType,
    PatientExternalIdDto as TicketPatientExternalDto,
} from "@accurx/api/ticket";
import { PatientExternalIdDto as PatientMessagingPatientExternalIdDto } from "domains/concierge/internal/api/patientMessaging/types/dto.types";
import { PatientExternalId } from "domains/concierge/types";

export const mapPatientSummaryExternalIdsToTicketPatientExternalIds = (
    patientExternalIds: PatientExternalId[],
): (TicketPatientExternalDto | PatientMessagingPatientExternalIdDto)[] => {
    return patientExternalIds.map(({ type, value }) => ({
        value,
        type: IdType[type],
    }));
};
