/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Button, Text } from "@accurx/design";
import { IWrappedResult } from "@accurx/shared";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import ChainApiClient from "api/VaccineApiHelper";

import { PracticesReducerState } from "../../Practices.reducer";
import { VaccinePatientInvited } from "../models/VaccineDeliveryDTO";
import { VaccineCancelRequest } from "../models/VaccineRecordingDTO";
import {
    BulkActionContext,
    VaccineBulkActions,
} from "../vaccineBulkActions/VaccineBulkActions.component";

export interface VaccineDeliveryCancelModalProps {
    selectedPractice: string;
    practices: PracticesReducerState;
    showCancelModal: boolean;
    onToggleModal: () => void;
    onSubmit: (isAllowedRebook: boolean, cancelSecondBooking: boolean) => void;
    isCancelInvite: boolean; // Cancel invite or booking, same flow two copies
    isCancelSecondBooking: boolean; // Cancel second booking only
    isBulkFlow: boolean;
    invites: VaccinePatientInvited[] | null;
    onBulkFinish: () => void;
    isToManuallyBook: boolean;
}

export const VaccineDeliveryCancelModal = ({
    selectedPractice,
    practices,
    showCancelModal,
    onToggleModal,
    onSubmit,
    isCancelInvite,
    isCancelSecondBooking,
    isBulkFlow,
    invites,
    onBulkFinish,
    isToManuallyBook = false,
    ...props
}: VaccineDeliveryCancelModalProps): JSX.Element => {
    const [isAllowedRebook, setIsAllowedRebook] = useState(true);
    const [showBulkAction, setShowBulkActions] = useState(false);

    const handleToggle = (): void => {
        setIsAllowedRebook((prevState) => !prevState);
    };

    const handleSubmit = (): void => {
        if (isCancelInvite) {
            onSubmit(false, isCancelSecondBooking); // isCancelSecondBooking
            return;
        }

        onSubmit(isAllowedRebook, isCancelSecondBooking); // isAllowedRebook will be true here if isCancelSecondBooking is false as there is no way to toggle!
        setIsAllowedRebook(true); // reset to default for future use
    };

    const handleShowBulkActions = (): void => {
        setShowBulkActions(true);
    };

    const handleBulkFinish = (): void => {
        setShowBulkActions(false);
        onBulkFinish();
    };

    const promiseForCancelInvite = (
        patient: VaccinePatientInvited,
    ): Promise<IWrappedResult<any>> => {
        // For use in the bulk action
        const request: VaccineCancelRequest = {
            patientCanRebook: false,
            cancelSecondBooking: false,
            customSmsSignature: null,
        };
        return ChainApiClient.cancelBooking(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            request,
        );
    };

    const renderModalText = (): JSX.Element => {
        if (isBulkFlow) {
            if (isToManuallyBook) {
                return (
                    <Text>
                        You will need to contact these patients manually to let
                        them know their invites have been cancelled, as no
                        automatic SMS will be sent out. Do you wish to continue?
                    </Text>
                );
            }
            return (
                <Text>
                    Are you sure you want to cancel these patient's invites?
                    They will not be able to book appointments from their link
                    and you will need to re-invite them.
                </Text>
            );
        } else {
            if (isCancelInvite) {
                return (
                    <Text>
                        Are you sure you want to cancel this patient's invite?
                        They will not be able to book appointments from their
                        link and you will need to re-invite them.
                    </Text>
                );
            } else {
                if (isCancelSecondBooking) {
                    return (
                        <Text>
                            Are you sure you want to cancel this patient's
                            second booking? You will then be able to cancel the
                            patient's first booking if you wish to.
                        </Text>
                    );
                } else {
                    return (
                        <Text>
                            Are you sure you want to cancel this patient's first
                            booking?
                        </Text>
                    );
                }
            }
        }
    };

    return (
        <Modal
            isOpen={showCancelModal}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
            backdrop="static"
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h1" skinny>
                    {isBulkFlow ? (
                        <>Cancel {invites?.length} invites</>
                    ) : (
                        <>Cancel {isCancelInvite ? "invite" : "appointment"}</>
                    )}
                </Text>
            </ModalHeader>
            <ModalBody className={"bg-cloud"}>
                {renderModalText()}
                {!isBulkFlow && !isCancelInvite && !isCancelSecondBooking && (
                    <>
                        <Text>Do you wish to continue?</Text>
                        <input
                            id="allowed-rebook"
                            type="checkbox"
                            checked={isAllowedRebook}
                            onChange={handleToggle}
                        />
                        <Text
                            as="label"
                            props={{
                                htmlFor: "allowed-rebook",
                                className: "ml-1",
                            }}
                        >
                            Allow patient to rebook using their original invite
                        </Text>
                    </>
                )}
                {isBulkFlow && !!invites && showBulkAction && (
                    <VaccineBulkActions
                        practices={practices}
                        practiceId={selectedPractice}
                        invites={invites}
                        context={BulkActionContext.CancelInvites}
                        processFn={promiseForCancelInvite}
                        onBackToInviteList={handleBulkFinish}
                        successListTitle={"Invites cancelled"}
                        successListSubTitle={
                            "These invites were successfully cancelled"
                        }
                        failedListTitle={"Invites cancellation failed"}
                        failedListSubTitle={
                            "These invites weren't successfully cancelled, please try again"
                        }
                    />
                )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                {!(
                    isBulkFlow && showBulkAction
                ) /* Don't show once the bulk action is being done */ && (
                    <Button
                        text="Back"
                        theme="secondary"
                        onClick={onToggleModal}
                        className="mx-1"
                    />
                )}
                {isBulkFlow ? (
                    !showBulkAction && (
                        <Button
                            text="Bulk Cancel Invites"
                            onClick={handleShowBulkActions}
                        />
                    )
                ) : (
                    <Button
                        text={
                            isCancelInvite
                                ? "Cancel invite"
                                : isCancelSecondBooking
                                ? "Cancel second appointment"
                                : "Cancel first appointment"
                        }
                        onClick={handleSubmit}
                    />
                )}
            </ModalFooter>
        </Modal>
    );
};
