import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { FloreyTemplatesResponse } from "@accurx/api/patient-messaging";

import * as PatientMessagingApi from "api/PatientMessagingServer/PatientMessagingServerApi";

export const QUESTIONNAIRE_LIST_CACHE_KEY = "QuestionnaireList";

type QuestionnaireListQueryVariables = {
    workspaceId: number;
    forBatch: boolean;
};

export const useQuestionnaireListQuery = createQueryHook<
    QuestionnaireListQueryVariables,
    FloreyTemplatesResponse
>(({ workspaceId, forBatch }) => ({
    queryKey: [QUESTIONNAIRE_LIST_CACHE_KEY, { workspaceId, forBatch }],
    queryFn: async () => {
        const response = await PatientMessagingApi.getFloreyTemplates({
            organisationId: workspaceId,
            forBatch,
        });
        return returnDataOrThrow(response);
    },
}));
