import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    OrganisationResource,
    getOrganisationResources,
} from "api/OrganisationResourceApi";

export const WORKSPACE_RESOURCES_CACHE_KEY = "WorkspaceResources";

type WorkspaceResourcesQueryVariables = {
    workspaceId: number;
};

export const useWorkspaceResourcesQuery = createQueryHook<
    WorkspaceResourcesQueryVariables,
    OrganisationResource[]
>(({ workspaceId }) => ({
    queryKey: [WORKSPACE_RESOURCES_CACHE_KEY, { workspaceId }],
    queryFn: async () => {
        const response = await getOrganisationResources({
            organisationId: workspaceId,
        });
        const data = returnDataOrThrow(response);
        return data.resources;
    },
}));
