import React from "react";

import { useAccurxWebTitle } from "@accurx/navigation";

import { SettingsProfile } from "../../practices/userSettings/SettingsProfile";
import { AccountPageLayout } from "./AccountPageLayout";

const UserProfile = () => {
    useAccurxWebTitle("Update name and email address");
    return (
        <AccountPageLayout title="Name and email">
            <SettingsProfile hideHeader />
        </AccountPageLayout>
    );
};
export { UserProfile };
