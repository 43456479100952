import { FormFieldWrapper } from "@accurx/design";
import { RemirrorJSON } from "remirror";

import { AccumailTemplateEditor } from "../../accumailTemplateEditor";
import { TemplateEditorWrapper } from "./TemplateEditorWrapper";
import { ValidationDetails } from "./TemplateForm";

type TemplateContentProps = {
    initialContent: RemirrorJSON | undefined;
    setEditorContent: (content: RemirrorJSON) => void;
    validationDetails: ValidationDetails;
};

export const TemplateContent = ({
    initialContent,
    setEditorContent,
    validationDetails,
}: TemplateContentProps) => (
    <FormFieldWrapper
        id="content"
        label="Message"
        errors={getErrors(validationDetails)}
    >
        <TemplateEditorWrapper>
            <AccumailTemplateEditor
                onChange={(content) => {
                    setEditorContent(content);
                }}
                initialContent={initialContent}
                hasErrors={hasErrors(validationDetails)}
            />
        </TemplateEditorWrapper>
    </FormFieldWrapper>
);

const getErrors: (props: ValidationDetails) => string[] = ({
    submitted,
    submittedContentIsEmpty,
    invalidPlaceholder,
}) => {
    if (!submitted) {
        return [];
    }

    if (submittedContentIsEmpty) {
        return ["Message can't be empty"];
    }

    if (invalidPlaceholder !== null) {
        return [`Message can't contain %${invalidPlaceholder}%`];
    }

    return [];
};

const hasErrors: (props: ValidationDetails) => boolean = ({
    submitted,
    submittedContentIsEmpty,
    invalidPlaceholder,
}) => {
    return (
        (submittedContentIsEmpty || invalidPlaceholder !== null) && submitted
    );
};
