import * as Accordion from "@radix-ui/react-accordion";
import styled, { keyframes } from "styled-components";

const expand = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const collapse = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
`;

export const Content = styled(Accordion.Content)`
    overflow-y: hidden;

    &[data-state="open"] {
        animation: ${expand} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    &[data-state="closed"] {
        animation: ${collapse} 300ms cubic-bezier(0.87, 0, 0.13, 1);
    }
`;
