import { Ds, Flex, Icon, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { generatePath } from "react-router-dom";

import { ConversationSummaryProps } from "app/clinicianConversations/types";
import { ROUTES } from "shared/Routes";

import {
    StyledCard,
    StyledDateText,
    StyledLink,
    StyledMessageText,
    StyledPracticeCard,
    StyledSpan,
} from "./ConversationSummary.styles";
import { ConversationSummaryAvatar } from "./ConversationSummaryAvatar";

const formatPatientDetails = ({
    patientAge,
    patientGender,
}: Pick<ConversationSummaryProps, "patientAge" | "patientGender">) => {
    if (!patientAge && !patientGender) {
        return null;
    }

    if (patientAge && patientGender) {
        return `(${patientAge}y, ${patientGender})`;
    }
    if (patientAge) {
        return `(${patientAge}y)`;
    }

    return `(${patientGender})`;
};

export const ConversationSummary = ({
    onClickSummary,
    workspaceName,
    patientName,
    patientAge,
    patientGender,
    assigneeName,
    senderName,
    messageText,
    messageDate,
    workspaceConversationId,
    workspaceId,
}: ConversationSummaryProps): JSX.Element => {
    const patientDetails = formatPatientDetails({ patientAge, patientGender });
    const senderAndMessage = `${senderName}: ${messageText}`;
    const formattedDate = DateHelpers.formatDateConditionally(messageDate, {
        formatToday: DateFormatOptions.TIME_SHORT_12_HR,
        formatThisYear: DateFormatOptions.DATE_SHORT_WITH_SPACES_NO_YEAR,
        formatFallback: DateFormatOptions.DATE_SHORT_WITH_SPACES,
    });

    return (
        <StyledLink
            to={{
                state: { appOrigin: "ClinicianConversations" },
                pathname: generatePath(ROUTES.view_clinician_conversation, {
                    workspaceConversationId,
                    workspaceId,
                }),
            }}
            onClick={() => onClickSummary()}
        >
            <StyledCard isElevated={false}>
                <Flex flexDirection="column" gap="1">
                    <Flex justifyContent="space-between" alignItems="center">
                        <Flex gap="1" alignItems="center">
                            <StyledPracticeCard isElevated={false}>
                                <Flex alignItems="center" gap="1">
                                    <ConversationSummaryAvatar />
                                    <Text variant="label" skinny>
                                        {workspaceName}
                                    </Text>
                                </Flex>
                            </StyledPracticeCard>
                            <Text skinny>
                                -{" "}
                                {patientName && (
                                    <StyledSpan>{patientName}</StyledSpan>
                                )}{" "}
                                {patientDetails && <>{patientDetails}</>}
                            </Text>
                        </Flex>
                        {assigneeName && (
                            <Ds.Badge color="greyscale" radius="round">
                                <Icon name="Assign" />
                                {assigneeName}
                            </Ds.Badge>
                        )}
                    </Flex>
                    <Flex justifyContent="space-between" gap="1">
                        <StyledMessageText colour="metal" skinny>
                            {senderAndMessage}
                        </StyledMessageText>
                        <StyledDateText skinny>{formattedDate}</StyledDateText>
                    </Flex>
                </Flex>
            </StyledCard>
        </StyledLink>
    );
};
