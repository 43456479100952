import { useEffect } from "react";

import { useAnalytics } from "@accurx/analytics";
import {
    Organisation,
    useCurrentUser,
    useCurrentWorkspace,
} from "@accurx/auth";
import { Button, Ds, Flex, Text } from "@accurx/design";

import {
    findFirstPharmacy,
    findNhsPharmacy,
    isPharmacy,
} from "../../util/workspaceHelpers";

export const SelectNhsPharmacyModal = ({
    handleClose,
    handleSelectWorkspace,
}: {
    handleClose: () => void;
    handleSelectWorkspace: (workspace: Organisation) => void;
}) => {
    const { ref, handleFocusRef } = Ds.useModalAutoFocus<HTMLButtonElement>();
    const { user } = useCurrentUser();
    const currentWorkspace = useCurrentWorkspace();
    const track = useAnalytics();

    useEffect(() => {
        track("PharmacyModalSelect Component View", {});
        // Only track event on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const firstPharmacy = findFirstPharmacy(user.organisations);

    const nhsPharmacy = findNhsPharmacy(user.organisations);

    const handleGoToPharmacy = () => {
        firstPharmacy && handleSelectWorkspace(firstPharmacy);
        track("PharmacyModalSelect Button Click", {
            actionSelected: "GoToWorkspace",
        });
        handleClose();
    };

    const handleContinueToNhsPharmacy = () => {
        nhsPharmacy && handleSelectWorkspace(nhsPharmacy);
        track("PharmacyModalSelect Button Click", {
            actionSelected: "ContinueNHSPharmacy",
        });
        handleClose();
    };

    const handleStayInPharmacy = () => {
        track("PharmacyModalSelect Button Click", {
            actionSelected: "StayInPharmacy",
        });
        handleClose();
    };

    return (
        <Ds.Modal
            open={true}
            onOpenChange={handleClose}
            onOpenAutoFocus={handleFocusRef}
            dismissible={false}
        >
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        The NHSPHARMACY workspace is only for your records and
                        finishing any open conversations
                    </Ds.Modal.Title>
                    <Text skinny>
                        This workspace is being archived. You should use your
                        pharmacy’s workspace for any new communication.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        {isPharmacy(currentWorkspace) ? (
                            <Button
                                theme="secondary"
                                text={`Stay in ${currentWorkspace.organisationName}`}
                                onClick={handleStayInPharmacy}
                            />
                        ) : (
                            <Button
                                theme="secondary"
                                text={`Go to ${
                                    firstPharmacy?.organisationName || ""
                                }`}
                                onClick={handleGoToPharmacy}
                            />
                        )}
                        <Button
                            ref={ref}
                            text="Continue to NHSPHARMACY"
                            onClick={handleContinueToNhsPharmacy}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
