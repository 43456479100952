import React, { useState } from "react";

import { Button, Ds, Text, Tokens } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { Table } from "reactstrap";

import { VaccinePatientInvited } from "../models/VaccineDeliveryDTO";

const paginationSize = 3;

export type BadgeColourVariants =
    | "green"
    | "greyscale"
    | "yellow"
    | "red"
    | "blue";

export interface VaccineReviewPatientTableProps {
    invites: VaccinePatientInvited[];
    fullListLength: number;
    title: string;
    subtitle: string;
    testId: string;
    badgeText?: string;
    badgeColour?: BadgeColourVariants;
}

export const VaccineReviewPatientTable = (
    props: VaccineReviewPatientTableProps,
): JSX.Element => {
    const [currentPaginationPage, setCurrentPaginationPage] = useState(0);

    if (props.invites.length === 0) {
        return <></>;
    }

    const handlePageChange = (pageNumber: number): void => {
        setCurrentPaginationPage(pageNumber);
    };

    const renderPaginationButtons = (totalItems: number): JSX.Element => {
        return (
            <div className="mb-2 text-center">
                <Button
                    onClick={(): void =>
                        handlePageChange(currentPaginationPage - 1)
                    }
                    text={"<"}
                    disabled={currentPaginationPage === 0}
                    style={{ display: "inline" }}
                />
                <Text
                    props={{ style: { display: "inline", margin: "0 2rem" } }}
                >
                    Page {currentPaginationPage + 1} of{" "}
                    {Math.ceil(totalItems / paginationSize)}
                </Text>
                <Button
                    onClick={(): void =>
                        handlePageChange(currentPaginationPage + 1)
                    }
                    text={">"}
                    disabled={
                        currentPaginationPage + 1 >= totalItems / paginationSize
                    }
                    style={{ display: "inline" }}
                />
            </div>
        );
    };

    const paginated = props.invites.slice(
        currentPaginationPage * paginationSize,
        currentPaginationPage * paginationSize + paginationSize,
    );

    return (
        <div className="mb-4 mt-4">
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ borderBottom: "2px solid lightgray" }}
            >
                <Text as="h3" variant="label" skinny>
                    {props.invites.length} of {props.fullListLength} -{" "}
                    {props.title}
                </Text>
                {props.badgeText && props.badgeColour && (
                    <Ds.Badge color={props.badgeColour}>
                        {props.badgeText}
                    </Ds.Badge>
                )}
            </div>
            <Text>{props.subtitle}</Text>

            <Table style={{ backgroundColor: Tokens.COLOURS.greyscale.white }}>
                <thead>
                    <tr>
                        <th className="text-center">
                            <Text variant="label">NHS Number</Text>
                        </th>
                        <th className="text-center">
                            <Text variant="label">Patient name</Text>
                        </th>
                        <th className="text-center">
                            <Text variant="label">Date of Birth</Text>
                        </th>
                        <th className="text-center">
                            <Text variant="label">Contact Number</Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {paginated.map((invite) => {
                        return (
                            <tr key={invite.inviteId}>
                                <td>
                                    <Text
                                        skinny
                                        props={{
                                            className: "text-center",
                                            "data-testid": `${props.testId}-nhs-${invite.inviteId}`,
                                        }}
                                    >
                                        {
                                            invite.patientExternalIdentity
                                                .patientExternalIds[0].value
                                        }
                                    </Text>
                                </td>
                                <td>
                                    <Text
                                        skinny
                                        props={{
                                            className: "text-center",
                                            "data-testid": `${props.testId}-name-${invite.inviteId}`,
                                        }}
                                    >
                                        {invite.patientName}
                                    </Text>
                                </td>
                                <td>
                                    <Text
                                        skinny
                                        props={{
                                            className: "text-center",
                                            "data-testid": `${props.testId}-dob-${invite.inviteId}`,
                                        }}
                                    >
                                        {DateHelpers.formatDate(
                                            invite.dateOfBirth,
                                            "DD/MM/YYYY",
                                        )}
                                    </Text>
                                </td>
                                <td>
                                    <Text
                                        skinny
                                        props={{
                                            className: "text-center",
                                            "data-testid": `${props.testId}-phone-${invite.inviteId}`,
                                        }}
                                    >
                                        {invite.contactNumber}
                                    </Text>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            {renderPaginationButtons(props.invites.length)}
        </div>
    );
};
