import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

const { silver, dishwater, white } = Tokens.COLOURS.greyscale;

export const StyledHeader = styled.div`
    color: ${white};
    background-color: ${Tokens.COLOURS.primary.green[100]};
    padding: ${Tokens.SIZES[3]} 0 ${Tokens.BASE.grid * 15}px 0;
`;

export const StyledContent = styled.main`
    margin-top: -${Tokens.BASE.grid * 12}px;
`;

export const StyledCard = styled.section<{
    cardType?: "marketing" | "product";
}>`
    background-color: ${(props) =>
        props.cardType === "marketing" ? dishwater : white};
    border-radius: ${Tokens.BORDERS.radius};
    border: 1px solid ${silver};
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledCardHeading = styled(StackPanel)`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    flex-wrap: wrap;
    width: 100%;
`;

export const StyledCardContent = styled.div`
    padding: 0 ${Tokens.SIZES[1]};
`;

export const StyledOrganisationGrid = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-auto-flow: dense;
        gap: ${Tokens.SIZES[3]};
    }
`;

export const StyledOrganisationProducts = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        grid-row: 1;
    }
`;

export const StyledUnapprovedFeedback = styled.div`
    margin-bottom: ${Tokens.SIZES[3]};
`;
