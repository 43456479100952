import { Tokens } from "@accurx/design";
import styled from "styled-components";

// Duplicated from accurx-design/dist/components/Card/Card.styles
// To be available for the custom Card implementation with multiple line breaks

export const StyledCardLineBreak = styled.hr`
    border: none;
    border-top: solid 1px ${Tokens.COLOURS.greyscale.silver};
    margin: ${Tokens.SIZES[2]} 0 ${Tokens.SIZES[3]} 0;
`;
