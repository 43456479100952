import { Modal } from "reactstrap";
import styled from "styled-components";

export const StyledImage = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
`;

// For over-riding reactstrap's Modal's outer border
export const StyledModal = styled(Modal)`
    .modal-content {
        border: none;
        margin-top: 6rem;
    }
`;
