import { AppointmentChangeRequestResponse } from "@accurx/api/appointment";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type ChangeRequestsParameters = {
    workspaceId: string;
};

const getChangeRequests = (
    workspaceId: string,
): Promise<IWrappedResult<AppointmentChangeRequestResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/appointments/appointmentChangeRequests/:workspaceId",
            params: { workspaceId },
        }),
    );

export const useChangeRequests = ({ workspaceId }: ChangeRequestsParameters) =>
    useQuery({
        queryKey: [
            "AppointmentReminders",
            "AppointmentCancellations",
            { workspaceId },
        ],
        queryFn: async () => {
            const response = await getChangeRequests(workspaceId);
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Request failed");
        },
    });
