import { ConversationItem } from "domains/concierge/types";

export const itemHasAttachments = (item: ConversationItem): boolean => {
    if ("sections" in item) {
        for (const section of item.sections) {
            for (const response of section.responses) {
                if (response.attachments.length > 0) {
                    return true;
                }
            }
        }
    }

    return false;
};
