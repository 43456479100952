import { ConversationSummary } from "@accurx/api/clinician-messaging";
import { mapConversationSummaryToConversation } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationSummaryToConversation";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { ConciergeUpdates } from "domains/concierge/testing";
import {
    Conversation,
    PatientSummary,
    UserSummary,
} from "domains/concierge/types";

import { mapConversationSummaryToUserSummaries } from "./mapConversationSummaryToUserSummaries";

export const mapConversationSummaryToConciergeUpdate = (
    summary: ConversationSummary,
): ConciergeUpdates => {
    /*
     Clinician Messaging server returns patients and users nested in their conversation
     rather than as a separate field on the DTO. As such, it's possible there are
     patients and users referenced multiple times. We only need one copy of each,
     so the mapper will deduplicate them.
     */

    const conversations = new Array<Conversation>();
    const patients: Record<string, PatientSummary> = {};
    const users: Record<string, UserSummary> = {};

    const conversation = mapConversationSummaryToConversation(summary);

    if (conversation) {
        conversations.push(conversation);
    }

    if (summary.patient) {
        const patient = mapPatientThreadPatientDetailsToPatientSummary(
            summary.patient,
        );

        if (patient) {
            patients[patient.patientId] = patient;
        }
    }
    // merge any users in the summary
    Object.assign(users, mapConversationSummaryToUserSummaries(summary));

    return {
        conversations,
        patients: Object.values(patients),
        users: Object.values(users),
        teams: [],
    };
};
