import { useEffect, useRef, useState } from "react";

import { SnomedCode } from "@accurx/api/florey-builder";
import {
    Card,
    Flex,
    FormFieldWrapper,
    Item,
    Option,
    Text,
} from "@accurx/design";
import { calculateFragments } from "@accurx/shared";
import debounce from "lodash/debounce";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
    editQuestionnaire,
    getQuestionnaire,
    getSnomedCodes,
} from "api/FloreyBuilder/FloreyBuilderApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { StyledTextareaAutosize } from "app/sharedComponents/textAreaAutosize/TextAreaAutosize.styles";
import { LoadingStatus } from "shared/LoadingStatus";

import { useFloreyBuilder } from "../FloreyBuilder.context";
import {
    NO_SNOMED_CODE_OPTION,
    mapSnomedCodeToConcept,
    withSnomedCode,
} from "../FloreyBuilder.utils";
import {
    connectionProblemsError,
    rowVersionError,
    savingError,
} from "../components/ErrorToasts";
import {
    PageContainerForStickyFooter,
    StyledGreyBox,
    StyledIndent,
} from "../components/PageContainer.styles";
import { PageTitle } from "../components/PageTitles";
import { SnomedSelectFormField } from "../components/SnomedSelectFormField";
import { Actions } from "../constants";
import {
    routeConfirmationMessage,
    routeNameAndQuestions,
} from "../constants/paths";
import { PageActionText } from "../types/data.types";

export const FloreyBuilderCreateInviteMessage = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { orgId } = useParams<{ orgId: string }>();
    const { questionnaireId } = useParams<{ questionnaireId: string }>();
    const [enrolmentMessageErrors, setEnrolmentMessageErrors] = useState<
        string[]
    >([]);
    const [characterCount, setCharacterCount] = useState<number>();
    const [localMessage, setLocalMessage] = useState("");
    const { state, dispatch } = useFloreyBuilder();
    const { selectedQuestionnaire, snomedCodes, isSavingPage } = state;
    const history = useHistory();

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
        LoadingStatus.Initial,
    );
    const isLoading = loadingStatus !== LoadingStatus.Loaded;

    const invitationMessageRef = useRef<HTMLTextAreaElement | null>(null);

    const MAX_CHAR_COUNT = 612;
    const DEFAULT_CHARACTER_COUNT = 61;

    const practiceName = useSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name,
        shallowEqual,
    );

    const selectedSnomedOption = !!selectedQuestionnaire?.enrolmentSnomedCode
        ? ({
              label: selectedQuestionnaire.enrolmentSnomedCode.term,
              value: selectedQuestionnaire.enrolmentSnomedCode.conceptId,
          } as Option)
        : NO_SNOMED_CODE_OPTION;

    const loadQuestionnaire = async () => {
        if (questionnaireId !== null) {
            setLoadingStatus(LoadingStatus.Loading);
            const data = await getQuestionnaire(
                orgId,
                questionnaireId.toString(),
            );
            const codes = await getSnomedCodes(orgId);
            //we only load the page if questionnaire AND codes are successfully fetched
            if (
                !data.success ||
                !codes.success ||
                !data.result ||
                !codes.result?.codes
            ) {
                setLoadingStatus(LoadingStatus.Failed);
                connectionProblemsError();
            } else {
                setLocalMessage(data.result.enrolmentMessage ?? "");
                dispatch({
                    type: Actions.SET_QUESTIONNAIRE,
                    payload: data.result,
                });
                dispatch({
                    type: Actions.SET_CODES,
                    payload: codes.result,
                });
                setLoadingStatus(LoadingStatus.Loaded);
            }
        }
    };

    const calculateCharacterCount = (messageLength: number): number => {
        return (
            messageLength +
            (practiceName?.length ?? 0) +
            DEFAULT_CHARACTER_COUNT
        );
    };

    // This function is required to be within the React component
    // due to using the practice name from state to calculate max length of invite message
    const isValidInvitationMessage = (
        messageText: string | null | undefined,
        maxCharCount: number,
    ): { isMessageValid: boolean; messageErrorText: string } => {
        if (
            messageText === undefined ||
            messageText === null ||
            messageText?.length === 0
        ) {
            return {
                isMessageValid: false,
                messageErrorText:
                    "Add an invite message for your questionnaire",
            };
        }
        if (messageText?.trim().length === 0) {
            return {
                isMessageValid: false,
                messageErrorText:
                    "Add a non-whitespace invite message for your questionnaire",
            };
        }
        if (calculateCharacterCount(messageText?.length) > maxCharCount) {
            return {
                isMessageValid: false,
                messageErrorText: "Your invite message is too long.",
            };
        }
        return { isMessageValid: true, messageErrorText: "" };
    };

    // On load
    useEffect(() => {
        loadQuestionnaire();
        setCharacterCount(
            calculateCharacterCount(
                selectedQuestionnaire?.enrolmentMessage?.length ?? 0,
            ),
        );
        // This effect should only run when the questionnaire ID changes
        // Ideally, this would be a React Query call, but that will be done
        // as a follow up instead :)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaireId]);

    useEffect(() => {
        FlemingAnalyticsTracker.trackQuestionnaireInviteMessage({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
        });
    }, [analyticsLoggedInProps, questionnaireId]);

    const questionnaireNameHeading = (): JSX.Element => (
        <>
            <Text
                data-testid="questionnaire-title"
                children={`Write a message to invite patients for:`}
            />
            <StyledIndent>
                <Text variant={"label"}>{selectedQuestionnaire?.name}</Text>
            </StyledIndent>
        </>
    );

    const handleSaveInvite = async () => {
        FlemingAnalyticsTracker.trackQuestionnairePageContinue({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireInviteMessage",
            withSnomedCode: withSnomedCode(
                selectedQuestionnaire?.enrolmentSnomedCode,
            ),
        });

        const { isMessageValid, messageErrorText } = isValidInvitationMessage(
            selectedQuestionnaire?.enrolmentMessage,
            MAX_CHAR_COUNT,
        );
        if (!isMessageValid) {
            setEnrolmentMessageErrors([messageErrorText]);
            invitationMessageRef.current?.focus();
            return;
        }
        if (!selectedQuestionnaire?.questionnaireRowVersion) {
            rowVersionError();
            return;
        } else {
            dispatch({
                type: Actions.UPDATE_PAGE_IS_SAVING,
                payload: { isSavingPage: true },
            });
            const result = await editQuestionnaire(orgId, {
                questionnaireId: parseInt(questionnaireId),
                organisationId: parseInt(orgId),
                enrolmentMessage: selectedQuestionnaire?.enrolmentMessage,
                enrolmentSnomedCode: mapSnomedCodeToConcept(
                    selectedQuestionnaire.enrolmentSnomedCode,
                ),
                questionnaireRowVersion:
                    selectedQuestionnaire?.questionnaireRowVersion,
            });
            dispatch({
                type: Actions.UPDATE_PAGE_IS_SAVING,
                payload: { isSavingPage: false },
            });
            if (!result.success) {
                savingError(result.error);
                return;
            }
        }
        history.push(routeConfirmationMessage(orgId, questionnaireId));
    };

    const handleBackLink = (): void => {
        FlemingAnalyticsTracker.trackQuestionnairePageBack({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireInviteMessage",
        });
        history.push(routeNameAndQuestions(orgId, questionnaireId));
        return;
    };

    const debouncedEnrolmentMessageUpdates = useRef(
        debounce((message, dispatch) => {
            dispatch({
                type: Actions.SET_ENROLMENT_MESSAGE,
                payload: message,
            });
        }, 100),
    );

    const handleSetEnrolmentMessage: React.FormEventHandler<
        HTMLTextAreaElement
    > = (event) => {
        const message = event.currentTarget.value;
        const { isMessageValid, messageErrorText } = isValidInvitationMessage(
            message,
            MAX_CHAR_COUNT,
        );
        if (!isMessageValid) {
            setEnrolmentMessageErrors([messageErrorText]);
        } else {
            setEnrolmentMessageErrors([]);
        }
        setLocalMessage(message);

        setCharacterCount(calculateCharacterCount(message.length));
        debouncedEnrolmentMessageUpdates.current(message, dispatch);
    };

    const handleSelectSnomedCode = (selected: Option) => {
        dispatch({
            type: Actions.SET_ENROLMENT_CODE,
            payload: {
                conceptId: selected.value,
                term: selected.label,
            } as SnomedCode,
        });
    };

    /**
     * calculate fragments by passing in the body of the
     * template as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragments = calculateFragments(
        `${localMessage} ${"a".repeat(
            DEFAULT_CHARACTER_COUNT + (practiceName?.length ?? 0),
        )}`,
    );

    return (
        <>
            <PageContainerForStickyFooter>
                <StackPanel>
                    <PageTitle
                        pageNumber="2"
                        pageTitle="Create invite message"
                    />
                    <Card
                        variant={"regular"}
                        spacing={3}
                        header={questionnaireNameHeading()}
                    >
                        <>
                            <Text
                                as="label"
                                variant={"label"}
                                children={"Message"}
                                props={{ htmlFor: "invite-message" }}
                            />
                            <Text>
                                This will be sent as a message to the patient.
                            </Text>
                            <StyledGreyBox>
                                <Text children={"Dear [patient]"} />
                                <FormFieldWrapper
                                    errors={enrolmentMessageErrors}
                                >
                                    <StyledTextareaAutosize
                                        id="invite-message"
                                        autoFocus={true}
                                        disabled={false}
                                        value={localMessage}
                                        onChange={handleSetEnrolmentMessage}
                                        ref={invitationMessageRef}
                                    />
                                </FormFieldWrapper>

                                <Text
                                    as="div"
                                    children={
                                        <>
                                            <p>
                                                Please complete this
                                                questionnaire: (link will
                                                autogenerate here)
                                            </p>
                                            <br />
                                            <p>Thanks,</p>
                                            <p>{practiceName}</p>
                                        </>
                                    }
                                />
                            </StyledGreyBox>
                            <Flex justifyContent="flex-end">
                                <Item>
                                    <Text variant="note">
                                        {characterCount + "/" + MAX_CHAR_COUNT}{" "}
                                        ({fragments}{" "}
                                        {fragments === 1
                                            ? "fragment"
                                            : "fragments"}
                                        )
                                    </Text>
                                </Item>
                            </Flex>
                            <SnomedSelectFormField
                                isLoading={isLoading}
                                selectedOption={selectedSnomedOption}
                                options={snomedCodes}
                                onChange={handleSelectSnomedCode}
                                infoText="This SNOMED code will be saved to the patient's record when the Florey is sent."
                                formFieldProps={{
                                    labelProps: { htmlFor: "snomed-search" },
                                }}
                            />
                        </>
                    </Card>
                </StackPanel>
            </PageContainerForStickyFooter>

            <StepsFooter
                backText={PageActionText.Back}
                forwardText={
                    isSavingPage
                        ? PageActionText.Saving
                        : PageActionText.SaveAndNext
                }
                disabled={isSavingPage}
                backClickFunction={handleBackLink}
                forwardClickFunction={handleSaveInvite}
            />
        </>
    );
};
