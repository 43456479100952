import { NativeFetchError, useNativeSubscription } from "@accurx/native";
import { PatientDemographics } from "domains/inbox/schemas/PatientDemographicsSchema";

import { QueryHook } from "./types";

export type UseCurrentPatientQuery = QueryHook<
    undefined,
    PatientDemographics | null,
    NativeFetchError
>;

export const useCurrentPatientQuery: UseCurrentPatientQuery = () => {
    return useNativeSubscription("SubscribeCurrentPatient");
};
