import { Log } from "@accurx/shared";
import { BehaviorSubject, Observable, Unsubscribable } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import {
    DataFetcher,
    createDataFetcher,
} from "shared/concierge/conversations/DataFetcher";
import {
    Conversation,
    ConversationIdentity,
} from "shared/concierge/conversations/types/conversation.types";
import {
    Subscription,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";
import { ConnectionStateNew } from "shared/hubClient/ConnectionState";
import { BehaviorSubscribable } from "shared/types/rxjs.types";

import { safeNext } from "./safeNext";

type ConstructorArgs = {
    conversationIdentity: ConversationIdentity;
    initialState?: Conversation;
    fetchData: (identity: ConversationIdentity) => Promise<void>;
    conversationsFeed: Observable<Conversation[]>;
    connectionStateFeed?: BehaviorSubscribable<ConnectionStateNew>;
};

export const REFRESH_RATE = 120_000;

/**
 * ConversationSubscription
 *
 * This class is responsible for receiving all conversation changes
 * from the ConversationManager, and passing the changes to a single
 * conversation to the UI.
 */
class ConversationSubscription implements Subscription<Conversation> {
    private conversationIdentity: ConversationIdentity;

    private readonly fetchData: (
        identity: ConversationIdentity,
    ) => Promise<void>;

    /**
     * Store the last known version of the conversation we're tracking.
     * This will be updated when we receive update events.
     */
    private conversation: Conversation | undefined = undefined;

    /**
     * Setup the RxJS subject that consumers will receive updates
     * from. We don't expose it directly because we don't want consumers
     * to be able to push updates into the subject. Instead we expose it
     * as an observable so consumers can only receive data.
     */
    private subscriptionUpdateFeed: BehaviorSubject<
        SubscriptionResult<Conversation>
    >;

    private dataFetcher: DataFetcher;

    /**
     * We want to be able to control when we emit the state out of the subscription.
     * When we are getting the "initial" image of the data - we want to be accepting
     * any delta updates that arrive (so that we dont miss anything) but we dont want to
     * start broadcasting until we get that initial image.
     * This controls if we should begin emiting data or not out of the subscription.
     */
    private shouldEmit = false;

    /**
     * It's important that we unsubscribe to any observables when
     * the subscription has been torn down. Create a store for any
     * observables we need so that we can unsubscribe to them in
     * bulk.
     */
    private readonly subscriptionHandles: Unsubscribable[] = [];

    private connectionStateFeed?: BehaviorSubscribable<ConnectionStateNew>;

    /**
     * When initialised we start listening for updates to the conversation
     * in question but we don't start broadcasting events until the parent
     * has called `subscription.start()` and the conversation has been
     * fetched.
     *
     * @param conversationIdentity - The ID of the conversation
     * @param fetchData - A callback that should perform the initial conversation fetch
     * @param conversationsFeed - An observable of updates to all conversations
     */
    constructor(args: ConstructorArgs) {
        this.conversationIdentity = args.conversationIdentity;
        this.conversation = args.initialState;
        this.fetchData = args.fetchData;
        this.connectionStateFeed = args.connectionStateFeed;

        this.subscriptionUpdateFeed = new BehaviorSubject<
            SubscriptionResult<Conversation>
        >(
            args.initialState
                ? {
                      status: "SUCCESS",
                      data: args.initialState,
                      errorMessage: null,
                  }
                : {
                      status: "LOADING",
                      data: null,
                      errorMessage: null,
                  },
        );

        this.listenToConversationsFeed(args.conversationsFeed);
        this.dataFetcher = this.createDataFetcher();
    }

    /**
     * Feed of subscription results that allows the consumer
     * to subscribe to changes.
     */
    get feed(): Observable<SubscriptionResult<Conversation>> {
        return this.subscriptionUpdateFeed;
    }

    /**
     * Starts up the subscription. This requests the initial set
     * of data and then begins the broadcasting process.
     */
    public async start(): Promise<void> {
        try {
            await this.dataFetcher.start();

            // Now that we are complete and we know our dataset has our initial load,
            // we can start emitting events to subscribers
            this.shouldEmit = true;

            // If any updates came in from the background while fetching make
            // double sure that subscribers receive the initial payload
            if (
                this.conversation &&
                this.subscriptionUpdateFeed.getValue().data !==
                    this.conversation
            ) {
                safeNext(this.subscriptionUpdateFeed, {
                    status: "SUCCESS",
                    data: this.conversation,
                    errorMessage: null,
                });
            }
        } catch (e) {
            safeNext(this.subscriptionUpdateFeed, {
                status: "ERROR",
                errorMessage: e.message,
                data: null,
            });
        }
    }

    /**
     * Tear down
     * - teardown all observables we've set up internally,
     * - stop broadcasting events
     * - alert parents so they can dispose of this subscription
     */
    public teardown(): void {
        this.dataFetcher.teardown();

        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
        this.subscriptionUpdateFeed.unsubscribe();
    }

    private createDataFetcher() {
        return createDataFetcher({
            fetchFn: async ({ isInitialFetch, connected }) => {
                // If we already have a fully loaded, non-stale conversation
                // then don't bother fetching as we know we already have the
                // full conversation.
                if (
                    isInitialFetch &&
                    connected &&
                    !this.conversation?.isStale &&
                    this.conversation?.isFullyLoaded
                ) {
                    return;
                }

                await this.fetchData(this.conversationIdentity);
            },
            onFetchStart: (tags) => {
                Log.debug("ConversationSubscription fetch start", {
                    tags,
                });
            },
            onFetchSuccess: (tags) => {
                Log.debug("ConversationSubscription fetch success", {
                    tags,
                });
            },
            onFetchError: (e, { isInitialFetch }) => {
                Log.info("ConversationSubscription fetch failed", {
                    originalException: e,
                    tags: {
                        isInitialFetch,
                    },
                });
            },
            offlinePolling: this.connectionStateFeed
                ? {
                      connectionStateFeed: this.connectionStateFeed,
                      refreshRate: REFRESH_RATE,
                      onRefreshInterval: (details) => {
                          Log.debug(
                              `ConversationSubscription refresh interval`,
                              { tags: details },
                          );
                      },
                      onPollingStateChange: (details) => {
                          if (details.polling) {
                              Log.debug(
                                  `ConversationSubscription: enabling polling`,
                                  { tags: details },
                              );
                          } else {
                              Log.debug(
                                  `ConversationSubscription: disabling polling`,
                                  { tags: details },
                              );
                          }
                      },
                  }
                : undefined,
        });
    }

    /**
     * Starts listening to updates to the conversation updates feed.
     * The pipe operates like this:
     * - Receive an event containing a bulk list of updates
     * - Split the event into an event for each conversation that was updated
     * - Ignore any updates for conversations that aren't the one we care about
     * - Convert the update into a success state object
     * - Only broadcast the event if we're ready
     */
    private listenToConversationsFeed(
        conversationsFeed: Observable<Conversation[]>,
    ): void {
        const handle = conversationsFeed
            .pipe(
                mergeMap((conversations) => conversations), // split each conversation in the updates list into a single event
                filter((conversation) => this.accepts(conversation)),
                map((conversation) => this.processUpdate(conversation)),
                filter(() => this.shouldEmit),
            )
            .subscribe(this.subscriptionUpdateFeed);

        this.subscriptionHandles.push(handle);
    }

    /**
     * Converts the payload of a conversation update to a success state
     * that consumers would be expecting.
     */
    private processUpdate(
        conversation: Conversation,
    ): SubscriptionResult<Conversation> {
        this.conversation = conversation;
        return {
            status: "SUCCESS",
            data: conversation,
            errorMessage: null,
        };
    }

    /**
     * Checks that the given conversation is the one we care about.
     */
    private accepts(conversation: Conversation): boolean {
        return this.conversationIdentity.id === conversation.id;
    }
}

/**
 * Creates a new conversation subscription.
 *
 * We prefer to use a factory function to create subscription instances over
 * directly using the class because it's easier to mock functions than
 * classes in tests.
 */
export const createConversationSubscription = (
    arg: ConstructorArgs,
): Subscription<Conversation> => {
    return new ConversationSubscription(arg);
};
