import { generatePath } from "react-router";

import { ROUTES_CHAIN } from "shared/Routes";

type CreateProps = {
    orgId: string;
    isOrgTemplate: boolean;
};
type EditProps = {
    orgId: string;
    templateId: string;
    isOrgTemplate: boolean;
};
type DeleteProps = {
    orgId: string;
    templateId: string;
    isOrgTemplate: boolean;
};
type CopyToPracticeProps = {
    orgId: string;
    templateId: string;
};
export const routeManageTemplatesCreate = ({
    orgId,
    isOrgTemplate,
}: CreateProps): string =>
    ROUTES_CHAIN.practicesManageTemplatesCreate.replace(":orgId", orgId) +
    `?isOrganisationTemplate=${isOrgTemplate}`;

export const routeManageTemplatesEdit = ({
    orgId,
    templateId,
    isOrgTemplate,
}: EditProps): string =>
    generatePath(ROUTES_CHAIN.practicesManageTemplatesForm, {
        orgId,
        templateId,
        action: "edit",
    }) + `?isOrganisationTemplate=${isOrgTemplate}`;

export const routeManageTemplatesDelete = ({
    orgId,
    templateId,
    isOrgTemplate,
}: DeleteProps): string =>
    generatePath(ROUTES_CHAIN.practicesManageTemplatesForm, {
        orgId,
        templateId,
        action: "delete",
    }) + `?isOrganisationTemplate=${isOrgTemplate}`;

export const routeManageTemplatesCopyToPractice = ({
    orgId,
    templateId,
}: CopyToPracticeProps): string =>
    generatePath(ROUTES_CHAIN.practicesManageTemplatesForm, {
        orgId,
        templateId,
        action: "copy",
    }) + `?isOrganisationTemplate=true`;
