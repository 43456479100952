import { useConciergeSelector } from "domains/concierge/internal/context";
import { UserSummary } from "domains/concierge/types";

type UseAllUsersOptions = {
    excludeCurrentUser?: boolean;
};

export const useAllUsers = (options?: UseAllUsersOptions): UserSummary[] => {
    return useConciergeSelector((state) =>
        options?.excludeCurrentUser
            ? Object.values(state.users.items).filter(
                  (user) => !user.isCurrentUser,
              )
            : Object.values(state.users.items),
    );
};
