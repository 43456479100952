import { Log } from "@accurx/shared";
import { Primitive } from "@sentry/types";
import { NativeToWebError } from "domains/native/types";
import isNil from "lodash/isNil";
import { ZodError } from "zod";

const expectedErrorReasons = [
    "FileTooBig",
    "FileTypeInvalid",
    "MedicalRecordError",
    "MedicalRecordDisconnected",
    "MedicalRecordRequestNotSupported",
    "RequestFailed",
    "SubscriptionEnded",
] as const;

export type ExpectedErrorReason = (typeof expectedErrorReasons)[number];

export class NativeTransportError extends Error {
    public readonly name = "NativeTransportError";
}

/**
 * NativeFetchExpectedError - Thrown when the Native app sends us an error
 * reason that's in our expected list. We trust that if we receive one of these
 * expected errors that the Native app has already logged the issue so we don't
 * need to do any further logging on the client.
 *
 * Other currently known reasons which should be logged by the client include:
 *   "InvalidInput" and "TooManyParallelRequests"
 */
export class NativeFetchExpectedError extends Error {
    public readonly name = "NativeFetchExpectedError";

    constructor(
        public readonly reason: ExpectedErrorReason,
        message?: string | null,
    ) {
        const fullMessage = [reason, message]
            .filter((value) => !isNil(value))
            .join(": ");
        super(fullMessage);
    }
}

/**
 * NativeFetchUnexpectedError - Thrown for errors that we don't know how to
 * handle gracefully. Possible reasons include:
 * - The Native app sends us an error type that we're not expecting
 * - The Native app sends a payload that doesn't match our Zod schema
 *
 * Optionally accepts a "cause" error so we can log the underlying Error.
 */
export class NativeFetchUnexpectedError extends Error {
    public readonly name = "NativeFetchUnexpectedError";

    constructor(
        public readonly reason: string,
        message?: string | null,
        public readonly cause?: Error,
    ) {
        const fullMessage = [reason, message]
            .filter((value) => !isNil(value))
            .join(": ");
        super(fullMessage);
    }
}

export type NativeFetchError =
    | NativeFetchExpectedError
    | NativeFetchUnexpectedError;

export const isExpectedError = (
    reason: string,
): reason is ExpectedErrorReason => {
    return expectedErrorReasons.includes(reason as ExpectedErrorReason);
};

export const convertNativeErrorMessageToError = (
    error: NativeToWebError,
): NativeFetchError => {
    return isExpectedError(error.type)
        ? new NativeFetchExpectedError(error.type, error.message)
        : new NativeFetchUnexpectedError(error.type, error.message);
};

export const sanitizeAndLogError = (
    error: unknown,
    tags: Record<string, Primitive>,
): NativeFetchError => {
    // Expected errors do not need to be logged
    if (error instanceof NativeFetchExpectedError) {
        return error;
    }

    // Unexpected errors should be logged and may possibly have an underlying
    // cause error
    if (error instanceof NativeFetchUnexpectedError) {
        Log.error(error.cause ?? error, { tags });
        return error;
    }

    if (error instanceof ZodError) {
        Log.error(error, { tags });
        return new NativeFetchUnexpectedError(
            "ClientZodParseFailure",
            null,
            error,
        );
    }

    // We should never get here but if, for some reason, we receive an error
    // that isn't a NativeFetchError then convert it to one
    if (error instanceof Error) {
        Log.error(error, { tags });
        return new NativeFetchUnexpectedError("ClientUncaughtError");
    }

    // you can never be too careful with errors. If something that isn't even an
    // Error instance is thrown we've gone very wrong but again, lets convert it
    // to a NativeFetchError
    const err = new NativeFetchUnexpectedError("ClientUncaughtError");
    Log.error(err, { tags });
    return err;
};
