import { useEffect, useRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { MessageTemplate } from "@accurx/message-templates";
import { ActionStack } from "domains/compose/components/Compose/components/ActionStack/ActionStack";
import { AttachmentInfoBox } from "domains/compose/components/Compose/components/Attach/components/AttachmentInfoBox";
import { MessageContentPlaceholder } from "domains/compose/components/Compose/components/MessageContentPlaceholder/MessageContentPlaceholder";
import { PatientResponseEnabledInfo } from "domains/compose/components/Compose/components/PatientResponseEnabledInfo/PatientResponseEnabledInfo";
import { SnomedCodesInfo } from "domains/compose/components/Compose/components/SnomedCodesInfo/SnomedCodesInfo";

import {
    StyledList,
    StyledTextWithNewLines,
    StyledWrapper,
} from "./TemplatePreview.styles";

type TemplatePreviewProps = {
    greeting: string;
    signature: string;
} & Pick<
    MessageTemplate,
    "snomedCodes" | "attachments" | "title" | "body" | "allowReplyByDefault"
>;

export const TemplatePreview = ({
    title,
    body,
    greeting,
    signature,
    snomedCodes = [],
    attachments,
    allowReplyByDefault,
}: TemplatePreviewProps) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    /**
     * Screenreader lose focus and revert back to the top of window when navigating to preview.
     * This ref moves focus back to the component and reads out the title.
     */
    const sectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.focus();
        }
    }, []);

    return (
        <section aria-label={`${title} preview`} tabIndex={-1} ref={sectionRef}>
            <StyledWrapper>
                <UI.Text skinny variant="label" as="h2">
                    {title}
                </UI.Text>
                {!isMessageComponentV1_1Enabled && (
                    <>
                        {snomedCodes.length > 0 && (
                            <SnomedCodesInfo snomedCodes={snomedCodes} />
                        )}
                        <StyledTextWithNewLines>
                            {greeting ? `${greeting}\n${body}` : body}
                        </StyledTextWithNewLines>
                        {allowReplyByDefault === true && (
                            <PatientResponseEnabledInfo />
                        )}
                        {attachments !== undefined &&
                            attachments.length > 0 && (
                                <StyledList aria-label="Template attachments list">
                                    {attachments.map((attachment) => (
                                        <li key={attachment.id}>
                                            <AttachmentInfoBox
                                                name={
                                                    attachment.name ||
                                                    "Attachment name missing"
                                                }
                                                previewUrl={
                                                    attachment.previewUrl
                                                }
                                            />
                                        </li>
                                    ))}
                                </StyledList>
                            )}
                        {signature && (
                            <StyledTextWithNewLines>
                                {signature}
                            </StyledTextWithNewLines>
                        )}
                    </>
                )}
                {isMessageComponentV1_1Enabled && (
                    <>
                        <UI.Ds.Flex flexDirection="column" gap="3">
                            <div>
                                <StyledTextWithNewLines>
                                    {greeting}
                                </StyledTextWithNewLines>
                                <StyledTextWithNewLines>
                                    {body}
                                </StyledTextWithNewLines>
                            </div>
                            <MessageContentPlaceholder
                                isPatientResponseEnabled={
                                    allowReplyByDefault === true
                                }
                                hasAttachments={
                                    attachments !== undefined &&
                                    attachments.length > 0
                                }
                                isQuestionnaire={false}
                                nhsAdviceLink={null}
                                hasSelfBookLink={false}
                            />
                            <StyledTextWithNewLines>
                                {signature}
                            </StyledTextWithNewLines>
                        </UI.Ds.Flex>
                        <ActionStack
                            isPatientResponseEnabled={
                                allowReplyByDefault === true
                            }
                            attachments={
                                attachments?.map((att) => ({
                                    id: att.id,
                                    name: att.name ?? "file name not found",
                                    origin: "Template",
                                    previewUrl: att.previewUrl,
                                })) ?? []
                            }
                            snomedCodes={snomedCodes}
                            nhsAdviceLink={null}
                            selfBookLink={null}
                            questionnaire={null}
                        />
                    </>
                )}
            </StyledWrapper>
        </section>
    );
};
