import { ComponentProps } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";

/**
 * TODO: Remove import from ILLEGAL_IMPORTS_DO_NOT_USE
 */
import { Pill } from "@accurx/inbox-design-library";
import { TemplateItem } from "domains/compose/types";

import {
    Chevron,
    StyledButton,
    StyledListItem,
    StyledLoadingSpinner,
    StyledPreviewButton,
    StyledTitleText,
} from "./ListItem.styles";

type ListItemProps = ComponentProps<typeof StyledListItem> & {
    templateType: TemplateItem["type"];
    templateTitle: string;
    onClick: () => void;
    onClickPreview: () => void;
    isLoading?: boolean;
};

export const ListItem = ({
    templateType,
    templateTitle,
    onClick,
    onClickPreview,
    isLoading = false,
    ...listItemProps
}: ListItemProps) => {
    const version1_1 = useFeatureFlag("MessageComponentV1_1");
    return (
        <StyledListItem {...listItemProps}>
            <StyledButton onClick={version1_1 ? onClickPreview : onClick}>
                <UI.Flex gap={"1"} alignItems="center" as={"span"}>
                    {templateType === "MessageTemplate" && (
                        <UI.Icon
                            name="MessageWrite"
                            halo={version1_1 ? undefined : { colour: "yellow" }}
                            size={3}
                            props={{ style: { flexShrink: "0" } }}
                        />
                    )}
                    {templateType === "QuestionnaireTemplate" &&
                        (isLoading ? (
                            <StyledLoadingSpinner
                                dimension="extra-small"
                                colour="blue"
                            />
                        ) : (
                            <UI.Icon
                                name="Questionnaire"
                                halo={
                                    version1_1
                                        ? undefined
                                        : { colour: "purple" }
                                }
                                size={3}
                                props={{ style: { flexShrink: "0" } }}
                            />
                        ))}
                    <StyledTitleText variant="preview">
                        {templateTitle}
                    </StyledTitleText>
                    {version1_1 && <Chevron />}
                </UI.Flex>
            </StyledButton>
            {!version1_1 && (
                <StyledPreviewButton onClick={onClickPreview}>
                    <Pill.Text>Preview</Pill.Text>
                </StyledPreviewButton>
            )}
        </StyledListItem>
    );
};
