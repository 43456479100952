import { UseMutationResult } from "@tanstack/react-query";
import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { ConversationItem, PatientExternalId } from "domains/concierge/types";

export type ContentTypesWithSaveToRecordSupport =
    | "PatientSms"
    | "PatientEmail"
    | "NhsAppMessage"
    | "FloreyResponseNote"
    | "PatientSingleResponse"
    | "PatientTriageRequestNote"
    | "PatientAppointmentRequestNote"
    | "LabelTag";

export type MarkItemAsWrittenToRecordMutationVariables = {
    patientExternalIds: PatientExternalId[];
    contentType: ContentTypesWithSaveToRecordSupport;
    itemServerId: ConversationItem["serverId"];
    writeToRecordSuccess: boolean;
};

const markItemAsWrittenToRecord = async (
    args: MarkItemAsWrittenToRecordMutationVariables & { workspaceId: number },
) => {
    const {
        contentType,
        patientExternalIds,
        writeToRecordSuccess,
        itemServerId,
        workspaceId,
    } = args;

    switch (contentType) {
        case "PatientSms":
            return await api.markCommunicationAsWrittenToRecord({
                workspaceId,
                patientExternalIds,
                itemIds: [itemServerId],
                writeToRecordSuccess,
                communicationType: "SMS",
            });
        case "PatientEmail":
            return await api.markCommunicationAsWrittenToRecord({
                workspaceId,
                patientExternalIds,
                itemIds: [itemServerId],
                writeToRecordSuccess,
                communicationType: "Email",
            });
        case "NhsAppMessage":
            return await api.markCommunicationAsWrittenToRecord({
                workspaceId,
                patientExternalIds,
                itemIds: [itemServerId],
                writeToRecordSuccess,
                communicationType: "NhsAppMessage",
            });
        case "FloreyResponseNote":
        case "PatientSingleResponse":
        case "PatientTriageRequestNote":
        case "PatientAppointmentRequestNote":
            return await api.markNoteAsWrittenToRecord({
                workspaceId,
                patientExternalIds,
                itemId: itemServerId,
                writeToRecordSuccess,
            });
        case "LabelTag":
            return await api.markTicketLabelAsWrittenToRecord({
                workspaceId,
                patientExternalIds,
                itemId: itemServerId,
                writeToRecordSuccess,
            });
    }
};

export const useMarkItemAsWrittenToRecordMutation = (
    options?: UseMutationOptions<MarkItemAsWrittenToRecordMutationVariables>,
): UseMutationResult<
    void,
    Error,
    MarkItemAsWrittenToRecordMutationVariables
> => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    const mutation = useMutation(
        "MarkitemAsWrittenToRecord",
        async (payload: MarkItemAsWrittenToRecordMutationVariables) => {
            const { updates } = await markItemAsWrittenToRecord({
                workspaceId,
                ...payload,
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:MarkitemAsWrittenToRecord",
                    ...updates,
                }),
            );
        },
        options,
    );

    return mutation;
};
