import { Accordion, Text } from "@accurx/design";
import styled from "styled-components";

const OrderedList = styled.ol`
    padding: 0;
    margin: 0;
`;

const HowDoesItWork = () => (
    <Accordion header="How does this work?" icon={{ name: "Info" }}>
        <OrderedList>
            <li>
                <Text skinny>
                    Select patient details from your electronic patient record
                    or clinical system
                </Text>
            </li>
            <li>
                <Text skinny>
                    Write, or copy and paste, these details into the text box
                    below ‘Patient details’
                </Text>
                <Text skinny>
                    You can paste multiple times, with each patient on a new
                    line
                </Text>
            </li>
            <li>
                <Text skinny>Select ‘Add Patients’</Text>
                <Text skinny>
                    Patients you add will appear in the table below
                </Text>
            </li>
            <li>
                <Text skinny>
                    Click the ‘Continue’ button once you’ve added all the
                    patients you want to message
                </Text>
            </li>
        </OrderedList>
    </Accordion>
);

export default HowDoesItWork;
