import { Ds, Tokens } from "@accurx/design";
import { Colours } from "@accurx/navigation";
import styled from "styled-components";

export const StyledModal = styled(Ds.Modal)`
    background-color: ${Colours.midnightGreen};
`;

export const StyledHeader = styled(Ds.Flex)`
    align-items: center;
    justify-content: space-between;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;
