import { createSlice } from "@reduxjs/toolkit";
import { TeamsState } from "domains/concierge/internal/types/TeamsState";

import * as extraReducers from "./extraReducers";

export const initialState: TeamsState = {
    items: {},
    countByType: {},
};

export const teams = createSlice({
    name: "teams",
    initialState,
    reducers: {},
    extraReducers,
});
