import { Ds } from "@accurx/design";

const BADGES_COLOURS_LABELS: Record<
    string,
    {
        colour: Ds.BadgeProps["color"];
        label: string;
    }
> = {
    Low: {
        colour: "green",
        label: "Low complexity",
    },
    MediumHigh: {
        colour: "red",
        label: "Medium/High complexity",
    },
    Unvalidated: {
        colour: "yellow",
        label: "Pending validation",
    },
} as const;

/**
 *
 * @param complexity
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'complexity' string and returns the colour of the badge for the validation card.
 */
export const getValidatedComplexityBadgeColour = (
    complexity: string,
): Ds.BadgeProps["color"] => {
    return BADGES_COLOURS_LABELS[complexity]?.colour ?? "greyscale";
};

/**
 *
 * @param complexity
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'complexity' string and returns the string to display in the UI.
 */
export const getValidatedComplexityBadgeText = (complexity: string): string => {
    return BADGES_COLOURS_LABELS[complexity]?.label ?? complexity;
};
