import * as UI from "@accurx/design";

export const TelephoneBookingDetails = () => {
    const titleText =
        "Patients will be able to select a time window for their telephone appointment.";
    const subTitle = "The time windows will be displayed as:";
    const firstTimeWindow = "8:30am - 1:00pm";
    const secondTimeWindow = "2:00pm - 6:00pm ";

    return (
        <UI.Feedback
            colour="information"
            title={<UI.Text variant="note">{titleText}</UI.Text>}
            iconName="Phone"
        >
            <UI.Text variant="preview" skinny>
                {subTitle}
            </UI.Text>
            <UI.Text variant="preview" skinny>
                <li>{firstTimeWindow}</li>
            </UI.Text>
            <UI.Text variant="preview" skinny>
                <li>{secondTimeWindow}</li>
            </UI.Text>
        </UI.Feedback>
    );
};
