import { createContext, useContext } from "react";

import { Toggle } from "@accurx/navigation";

const initialState = {
    modalNav: { isOpen: false, toggle: () => null, setIsOpen: () => null },
};

export const MobileLayoutContext = createContext<{
    modalNav: Toggle;
}>(initialState);

export const useMobileLayout = () => useContext(MobileLayoutContext);
