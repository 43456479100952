import {
    MatchState,
    PatientThreadSuggestedPatientDetails,
    PatientThreadTriageProxyInformation,
    PatientThreadTriageRequestNote,
    RequestReceptionData,
} from "@accurx/api/ticket";
import { NO_ITEM_CONTENT_ERROR } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/conversationItem.constants";
import { mapBaseConversationItem } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapBaseConversationItem";
import { mapWriteToRecordState } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapWriteToRecordState";
import { mapTicketSuggestedPatientToSuggestedPatientMatch } from "domains/concierge/internal/api/ticket/mappers/mapTicketSuggestedPatientToSuggestedPatientMatch";
import {
    PatientTriageRequestItem,
    Reception,
} from "domains/concierge/schemas/ConversationItemSchema";
import { PatientProxy, SuggestedPatientMatch } from "domains/concierge/types";
import isNil from "lodash/isNil";

import { mapPatientResponseSections } from "./shared/mapPatientResponseSections";

const mapTicketItemRequestTypeToRequestType = (
    ticketItemRequestType?: string | null,
) => {
    if (ticketItemRequestType?.startsWith("Admin")) {
        return "Admin";
    }
    if (ticketItemRequestType?.startsWith("Medical")) {
        return "Medical";
    }
    if (ticketItemRequestType?.startsWith("Self Referral")) {
        return "SelfReferral";
    }

    // Default value
    return "Medical";
};

/**
 * Maps ticket Patient Triage incoming request to
 * conversation Patient Triage incoming request
 *
 * @param ticketPatientTriageItem Patient Triage incoming request
 * as it's sent from the API
 * @returns mapped Patient Triage incoming request conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientTriageItem(
    ticketPatientTriageItem: PatientThreadTriageRequestNote,
): PatientTriageRequestItem | undefined {
    if (!ticketPatientTriageItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    // Do not accept a request that has no responses
    if (
        isNil(ticketPatientTriageItem.message.responses) ||
        ticketPatientTriageItem.message.responses.length === 0
    ) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    const responses = ticketPatientTriageItem.message.responses;

    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketPatientTriageItem,
    );

    const matchStatus = mapMatchState(
        ticketPatientTriageItem.message.initialRequestMatchState,
    );
    if (!matchStatus) {
        throw new Error("Cannot map ticket item as match status is unknown");
    }

    const suggestedMatch = mapSuggestedMatchInfo(
        ticketPatientTriageItem.message.suggestedMatch,
    );
    if (matchStatus === "Suggested" && !suggestedMatch) {
        throw new Error(
            "Cannot map ticket item as match status is Suggested, and there is no suggestedMatch",
        );
    }

    const patientProxy = mapProxyInformation(
        ticketPatientTriageItem.message.patientTriageProxyInformation,
    );

    // expecting it to be set, but ok if not
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const title = ticketPatientTriageItem.message.patientRequestType || null;

    return {
        ...baseConversationItem,
        contentType: "PatientTriageRequestNote",
        createdBy: {
            type: "Patient",
            id: ticketPatientTriageItem.patientAccuRxId ?? undefined,
        },
        sections: result.sections,
        requestTitle: title,
        requestType: mapTicketItemRequestTypeToRequestType(
            ticketPatientTriageItem.message.patientRequestType,
        ),
        initialRequestMatchState: matchStatus,
        suggestedMatch,
        patientProxy,
        saveToRecordState: mapWriteToRecordState(
            ticketPatientTriageItem.message.writeToRecordState,
        ),
        reception: mapReceptionInformation(
            ticketPatientTriageItem.message.requestReceptionData,
        ),
    };
}

function mapProxyInformation(
    proxyInfo?: PatientThreadTriageProxyInformation | null,
): PatientProxy | null {
    if (!proxyInfo) {
        return null;
    }

    return {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        firstName: proxyInfo.proxyFirstName || null,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        lastName: proxyInfo.proxyLastName || null,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        phoneNumber: proxyInfo.proxyPhoneNumber || null,
        patientPreferredPhoneNumber:
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            proxyInfo.patientPreferredPhoneNumber || null,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        relationshipToPatient: proxyInfo.proxyRelationshipToPatient || null,
    };
}

function mapReceptionInformation(
    receptionInfo?: RequestReceptionData | null,
): Reception | null {
    if (!receptionInfo) return null;
    return {
        userId: receptionInfo.userId ?? null,
    };
}

function mapSuggestedMatchInfo(
    suggestedMatch?: PatientThreadSuggestedPatientDetails | null,
): SuggestedPatientMatch | null {
    if (!suggestedMatch) {
        return null;
    }

    return mapTicketSuggestedPatientToSuggestedPatientMatch(suggestedMatch);
}

function mapMatchState(
    matchState: MatchState,
): PatientTriageRequestItem["initialRequestMatchState"] | undefined {
    switch (matchState) {
        case MatchState.NoMatch:
            return "NoMatch";
        case MatchState.Suggested:
            return "Suggested";
        case MatchState.Verified:
            return "Verified";

        case MatchState.None:
        default:
            return;
    }
}
