import { useConversationGroup } from "domains/concierge/hooks/data/useConversationGroup";
import { TriageType } from "domains/concierge/internal/types/ConversationGroupFilters";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { conversationGroupId } from "domains/concierge/internal/util/conversationGroupId";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

export type Variables = {
    triageType: TriageType;
    teamId: string;
    status: "Open" | "Done";
};
export type ToAssignTriageConversationCount =
    | {
          count: number;
          isFullyLoaded: boolean;
      }
    | undefined;

export const useToAssignTriageConversationCount = ({
    teamId,
    status,
    triageType,
}: Variables): ToAssignTriageConversationCount => {
    const id = conversationGroupId(
        filters.toAssignTriage({ teamId, status, triageType }),
        sortOptions.toAssignTriage({ status }),
    );
    const conversationGroup = useConversationGroup({ id });

    if (!conversationGroup) return;

    // Return undefined when conversation group is still loading
    if (
        conversationGroup.members.length === 0 &&
        !conversationGroup.isFullyLoaded
    )
        return;

    return {
        count: conversationGroup.members.length,
        isFullyLoaded: conversationGroup.isFullyLoaded,
    };
};
