import { createSlice } from "@reduxjs/toolkit";
import { PatientsState } from "domains/concierge/internal/types/PatientsState";

import * as extraReducers from "./extraReducers";
import * as reducers from "./reducers";

export const initialState: PatientsState = {
    items: {},
    requestTimes: {},
};

export const patients = createSlice({
    name: "patients",
    initialState,
    reducers,
    extraReducers,
});
