import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledNavigationToggle = styled.button`
    background: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: 50%;
    border: none;
    padding: ${Tokens.SIZES[0.5]};
    display: flex;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    &:focus,
    &:hover {
        // This is needed to remove the outline shown when a link is in focus.
        // We don't want this outline as we are applying our own focus styles.
        box-shadow: none !important;

        transition: background-color 0.2s ease-in;
        background: ${Tokens.COLOURS.greyscale.silver};
    }
`;

const StyledWorkspaceConversationsTotalUnreadCountBadge = styled.span`
    position: absolute;
    top: -6px;
    right: -8px;
    z-index: 1;

    > span {
        display: block;
        position: initial;
    }
`;

export {
    StyledNavigationToggle,
    StyledWorkspaceConversationsTotalUnreadCountBadge,
};
