// Must be kept in sync with the backend https://github.com/Accurx/rosemary/blob/trunk/src/Web/accuRx.Models/Chain/Interfaces/IUserWorkspaceSpecialty.cs
export const NO_APPLICABLE_SPECIALTY_NAME = "No Applicable Specialty";

export const UserflowIds = {
    selectOrganisation: {
        selectButton: "select-organisation-select-button",
    },
    createWorkspace: {
        createButton: "create-workspace-create-button",
    },
};
