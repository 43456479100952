import { Conversation, ConversationItem } from "@accurx/concierge/types";

const INBOUND_ITEMS: ConversationItem["contentType"][] = [
    "PatientSingleResponse",
    "FloreyResponseNote",
    "PatientTriageRequestNote",
];
const OUTBOUND_ITEMS: ConversationItem["contentType"][] = [
    "PatientSms",
    "PatientEmail",
    "NhsAppMessage",
    "VideoConsultLink",
];

export const getCountConversationItems = (
    items: Conversation["items"],
): {
    countPatientMessageInbound: number;
    countPatientMessageOutbound: number;
} => {
    const count: { inbound: number; outbound: number } = items.reduce(
        (acc, item) => {
            if (INBOUND_ITEMS.includes(item.contentType)) {
                acc.inbound++;
            } else if (OUTBOUND_ITEMS.includes(item.contentType)) {
                acc.outbound++;
            } else if (item.contentType === "SelfBookNote") {
                const isInbound = item.createdBy.type === "Patient";

                if (isInbound) {
                    acc.inbound++;
                } else {
                    acc.outbound++;
                }
            }
            return acc;
        },
        {
            inbound: 0,
            outbound: 0,
        },
    );

    return {
        countPatientMessageInbound: count.inbound,
        countPatientMessageOutbound: count.outbound,
    };
};
