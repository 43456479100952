import { JsonResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { QueryFunctionContext } from "@tanstack/react-query";

import { ApiQueryKey } from "./types";

/*
 *  We inject the httpClient to make testing easier
 *  what can I say, mocking modules in jest is hard ¯\_(ツ)_/¯
 */
export const getJson = async <T>(
    { queryKey }: QueryFunctionContext<ApiQueryKey>,
    client = httpClient,
): Promise<T> => {
    const response = await client.getReturnJsonSafeAsync(
        getApiEndpoint(queryKey[0]),
    );
    if (response.success) {
        return response.result as T;
    } else {
        throw new Error(response.error ?? "Request failed");
    }
};

/*
 *  We inject the httpClient to make testing easier
 *  what can I say, mocking modules in jest is hard ¯\_(ツ)_/¯
 */
export const getStatusCode = async (
    { queryKey }: QueryFunctionContext<ApiQueryKey>,
    client = httpClient,
): Promise<number | null> => {
    const response = (await client.getReturnSafeAsync(
        getApiEndpoint(queryKey[0]),
    )) as JsonResult;
    return response.statusCode;
};
