import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { QuestionTemplateDataType } from "../../practices/floreyBuilder/types/data.types";
import { FlemingLoggedInCustomProperties } from "./FlemingAnalyticsConstants";

type QuestionnaireInfo = {
    questionnaireId: number | null;
    questionnaireName: string | null;
};

type QuestionnaireId = {
    questionnaireId: number | null;
};

type QuestionnaireStatus = {
    questionnaireStatus:
        | "Draft"
        | "Reviewing"
        | "Published"
        | "Deleted"
        | "None"
        | null;
};

type QuestionnaireNameLength = {
    questionnaireNameLength: number;
};

type PageOrigin = {
    pageOrigin:
        | "QuestionnaireHome"
        | "QuestionnaireNameAndQuestions"
        | "QuestionnaireInviteMessage"
        | "QuestionnaireConfirmationMessage"
        | "QuestionnaireAssign";
};

type WithSnomedCode = {
    withSnomedCode: boolean;
};

type HomePageAction = {
    actionSelected: "Edit" | "Delete" | "Publish" | "Share";
};

//This is the question number as the user would see - so one indexed, rather than question number as in the index
type QuestionNumber = {
    questionNumber: number;
};

type QuestionType = {
    questionType: string | null;
};

type TemplateType = {
    templateType: QuestionTemplateDataType;
};
type OptionNumber = {
    optionNumber: number | null;
};

type QuestionInfo = {
    questionId: number | null;
    questionType: string | null;
    questionNumber: number;
};

type OptionCount = {
    optionCount: number | null;
};

type UserGroupInfo = {
    userGroupType: string | null;
};

type UserGroupId = {
    userGroupId: number | null;
};
type QuestionnaireShareId = {
    shareId: string;
};

type NewOrganisationId = {
    newOrganisationId: string;
};

type ImportOrgSelectProps = {
    shareId: string | null;
    countActivatedOrgs: number;
    countUnactivatedOrgs: number;
};

type QuestionnaireInfoProps = FlemingLoggedInCustomProperties &
    QuestionnaireInfo;
type QuestionnaireNameLengthProps = FlemingLoggedInCustomProperties &
    QuestionnaireNameLength;
type QuestionNumberAndTypeProps = FlemingLoggedInCustomProperties &
    QuestionNumber &
    QuestionType;
type QuestionInfoAndOptionNumberProps = FlemingLoggedInCustomProperties &
    QuestionInfo &
    OptionNumber;
type QuestionInfoProps = FlemingLoggedInCustomProperties & QuestionInfo;
type UserGroupIdProps = FlemingLoggedInCustomProperties & UserGroupId;
type QuestionnairePublishProps = FlemingLoggedInCustomProperties &
    QuestionnaireId &
    PageOrigin;
type QuestionnaireIdProps = FlemingLoggedInCustomProperties & QuestionnaireId;
type QuestionnaireNameProps = QuestionnaireIdProps & QuestionnaireNameLength;
type QuestionSaveProps = QuestionnaireIdProps & QuestionInfo & OptionCount;
type QuestionnaireOverviewActionProps = QuestionnaireIdProps &
    QuestionnaireStatus &
    HomePageAction;
type PageOriginProps = QuestionnaireIdProps & PageOrigin;
type PageContinueProps = PageOriginProps & WithSnomedCode;
type QuestionnaireAnswerProps = QuestionnaireIdProps & QuestionType;
type AssigneeSelectedProps = QuestionnaireIdProps & UserGroupInfo;
type QuestionnaireShareProps = FlemingLoggedInCustomProperties &
    QuestionnaireShareId;
type QuestionnaireChangeOrgProps = QuestionnaireShareProps & NewOrganisationId;
type QuestionnaireTemplateTypeProps = QuestionnaireIdProps & TemplateType;
type ImportProps = QuestionnaireShareProps & QuestionnaireId;
export const trackFloreyBuilderAUPAccepted = (
    props: LoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAUPAccepted",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderAUPBack = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAUPBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderQuestionnaireNameCancel = (
    props: QuestionnaireNameLengthProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireNameCancel",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderQuestionTypeSelect = (
    props: QuestionNumberAndTypeProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionTypeSelect",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderQuestionEdit = (
    props: QuestionInfoProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionEdit",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderQuestionOptionDelete = (
    props: QuestionInfoAndOptionNumberProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionOptionDelete",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackFloreyBuilderUserGroupSave = (
    props: UserGroupIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireUserGroupSave",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnairePublishConfirm = (
    props: QuestionnairePublishProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnairePublishConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireShare = (
    props: QuestionnaireInfoProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireShare",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireCreateStart = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireCreateStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireOverview = (
    props: FlemingLoggedInCustomProperties,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireOverview",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnaireOverviewAction = (
    props: QuestionnaireOverviewActionProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireOverviewAction",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireDeleteConfirm = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireDeleteConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireQuestionCreate = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionCreate",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnairePageContinue = (
    props: PageContinueProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnairePageContinue",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnairePageBack = (props: PageOriginProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnairePageBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireNameSave = (
    props: QuestionnaireNameProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireNameSave",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireNameEdit = (
    props: QuestionnaireNameProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireNameEdit",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireQuestionAdd = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionAdd",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireQuestionRemove = (
    props: QuestionnaireAnswerProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireQuestionSave = (
    props: QuestionSaveProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionSave",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireQuestionCancel = (
    props: QuestionnaireAnswerProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionCancel",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireAnswerAdd = (
    props: QuestionnaireAnswerProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAnswerAdd",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireAnswerRemove = (
    props: QuestionnaireAnswerProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAnswerRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireSnomedSearch = (
    props: PageOriginProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireSnomedSearch",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireConfirmMessage = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireConfirmMessage",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnaireAssign = (props: QuestionnaireIdProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAssign",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnaireAssigneeSelect = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAssigneeSelect",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireAssigneeSelectItem = (
    props: AssigneeSelectedProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireAssigneeSelect",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireSaveForTest = (props: PageOriginProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireSaveForTest",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireSnomedOption = (
    props: PageOriginProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireSnomedOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackQuestionnaireInviteMessage = (
    props: QuestionnaireIdProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireInviteMessage",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnaireSharePage = (
    props: QuestionnaireShareProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireShare",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackQuestionnaireShareLinkCopy = (
    props: QuestionnaireShareProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireShareLinkCopy",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackImportOrgSelectPage = (props: ImportOrgSelectProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireImportOrganisationSelect",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackImportToOrgPage = (props: QuestionnaireShareProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireImportToOrg",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackImportToOrg = (props: ImportProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireImport",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackSwitchingOrgPage = (
    props: QuestionnaireChangeOrgProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireChangeOrganisation",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackSelectQuestionType = (
    props: QuestionnaireAnswerProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionType",
        objectType: EventObjectType.ListItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackSelectQuestionTemplate = (
    props: QuestionnaireTemplateTypeProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireQuestionTemplateSave",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
