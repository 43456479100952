export const RequestType = {
    MedicalAndAdmin: "MedicalAndAdmin",
    Medical: "Medical",
    Admin: "Admin",
} as const;

export const RequestSource = {
    PatientsAndReception: "PatientsAndReception",
    Patients: "Patients",
    Reception: "Reception",
} as const;
