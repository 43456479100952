import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledChildContainer = styled.div`
    margin: ${Tokens.SIZES[2]} 0;
`;
