import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { createGlobalConversationManager } from "shared/concierge/conversations/GlobalConversationManager";
import { createConversationActions } from "shared/concierge/conversations/tickets/ConversationActions";
import {
    subscribeToPatientUpdates,
    subscribeToTicketUpdates,
} from "shared/concierge/conversations/tickets/TicketSignalRClient";
import {
    ConversationManager,
    GlobalConversationManager,
} from "shared/concierge/conversations/types/component.types";
import { useGlobalPatientManager } from "shared/concierge/patients/context/GlobalPatientManagerContext";
import { PatientManager } from "shared/concierge/patients/types/PatientManager.types";
import { useGlobalUsersAndTeamsManager } from "shared/concierge/usersAndTeams/context/GlobalUsersAndTeamsManagerContext";
import { UsersAndTeamsManager } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import { useHubClient } from "shared/hubClient/useHubClient";
import {
    SharedReference,
    WorkspaceSingletonProvider,
    createWorkspaceSingletonProvider,
} from "shared/workspace/WorkspaceSingletonProvider";

const GlobalConversationManagerContext =
    createContext<WorkspaceSingletonProvider<ConversationManager> | null>(null);

type GlobalConversationManagerProviderProps = {
    children: ReactNode;
    currentUserId?: string;
};

/**
 * GlobalConversationManagerProvider
 *
 * Sets up a Global Conversation Manager instance for a user and
 * exposes it via React Context. The Global Conversation Manager
 * provides a factory for spinning up Conversation Managers scoped
 * to a specific workspace.
 *
 * It requires a PatientManager in order to push relevant patient
 * identity and display information to it that came from server
 * API calls, and UserAndTeamsManager to receive team membership
 * changes that are relevant for unread notification counts.
 */
export const GlobalConversationManagerProvider = (
    props: GlobalConversationManagerProviderProps,
): JSX.Element => {
    const globalPatientManager = useGlobalPatientManager();
    const globalUsersAndTeamsManager = useGlobalUsersAndTeamsManager();
    const hubClient = useHubClient();

    const [tracker, setTracker] =
        useState<WorkspaceSingletonProvider<ConversationManager> | null>(null);

    useEffect(() => {
        if (
            !props.currentUserId ||
            !globalPatientManager ||
            !globalUsersAndTeamsManager
        ) {
            setTracker(null);
            return;
        }

        const globalConversationManager = createGlobalConversationManager(
            props.currentUserId,
            hubClient,
        );

        const result = createConversationManagerTrackerWithDependencies(
            globalConversationManager,
            globalPatientManager,
            globalUsersAndTeamsManager,
        );
        setTracker(result);
        return () => result.destroy();
    }, [
        props.currentUserId,
        globalPatientManager,
        globalUsersAndTeamsManager,
        hubClient,
    ]);

    return (
        <GlobalConversationManagerContext.Provider value={tracker}>
            {props.children}
        </GlobalConversationManagerContext.Provider>
    );
};

/**
 * Provides a global conversation manager instance for the
 * currently logged in user.
 *
 * @returns The global conversation manager
 */
export const useGlobalConversationManager = () =>
    useContext(GlobalConversationManagerContext);

/**
 * Export the context provider directly so that our UI tests can easily
 * wrap a component with a fake global conversation manager;
 */
export const StaticGlobalConversationManagerProvider =
    GlobalConversationManagerContext.Provider;

type ConversationManagerWithDeps = {
    conversationManager: ConversationManager;
    patientManager: SharedReference<PatientManager>;
    usersAndTeamsManager: SharedReference<UsersAndTeamsManager>;
};

const createConversationManagerTrackerWithDependencies = (
    globalConversationManager: GlobalConversationManager,
    patients: WorkspaceSingletonProvider<PatientManager>,
    usersAndTeams: WorkspaceSingletonProvider<UsersAndTeamsManager>,
): WorkspaceSingletonProvider<ConversationManager> => {
    const trackerWithDeps =
        createWorkspaceSingletonProvider<ConversationManagerWithDeps>(
            "ConversationManager",
            (workspaceId) => {
                const patientManager = patients.forWorkspace(workspaceId);
                const usersAndTeamsManager =
                    usersAndTeams.forWorkspace(workspaceId);
                const conversationManager =
                    globalConversationManager.forWorkspace(
                        workspaceId,
                        createConversationActions,
                        subscribeToTicketUpdates,
                        subscribeToPatientUpdates,
                        patientManager.item,
                        usersAndTeamsManager.item,
                    );
                return {
                    conversationManager,
                    patientManager,
                    usersAndTeamsManager,
                };
            },
            (x) => {
                x.conversationManager.unsubscribe();
                x.patientManager.release();
                x.usersAndTeamsManager.release();
            },
        );
    return {
        forWorkspace: (workspaceId: number) => {
            const wrapped = trackerWithDeps.forWorkspace(workspaceId);
            return {
                item: wrapped.item.conversationManager,
                release: () => wrapped.release(),
            };
        },
        destroy: () => trackerWithDeps.destroy(),
    };
};
