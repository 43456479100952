import React from "react";

import { useIsMobile } from "shared/useIsMobile";
import { useAppSelector } from "store/hooks";

import { FullNavigationMenu } from "./FullNavigationMenu";
import { MobileNavigationMenu } from "./MobileNavigationMenu";

const NavigationMenu = (): JSX.Element | null => {
    const isMobile = useIsMobile();
    const isInEmbeddedMode = useAppSelector(({ app }) => app.isEmbedded);

    if (isInEmbeddedMode) {
        return null;
    }

    if (isMobile) {
        return <MobileNavigationMenu />;
    }

    return <FullNavigationMenu />;
};

export { NavigationMenu };
