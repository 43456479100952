import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";

export const useUserScheduledConversationGroupQuery: ConversationGroupHook<{
    userId: string;
}> = ({ userId }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFolderTicketView({
                workspaceId: workspaceId,
                folder: {
                    viewType: "UserScheduled",
                    userId,
                },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, userId],
    );

    return useConversationGroupQuery({
        filters: [
            { type: "Source", value: "Ticket" },
            {
                type: "ContainsItem",
                value: {
                    filters: [
                        {
                            type: "Sender",
                            value: {
                                type: "User",
                                id: userId,
                            },
                        },
                        {
                            type: "ItemType",
                            value: ["PatientEmail", "PatientSms"],
                        },
                        {
                            type: "DeliveryStatus",
                            value: ["Queued"],
                        },
                    ],
                },
            },
        ],
        sortOptions: [
            {
                key: "NextScheduledItem",
                direction: "asc",
                senderId: userId,
            },
            { key: "Id", direction: "asc" },
        ],
        loggingInfo: {
            name: "User scheduled conversations",
            tags: {
                userIdFilter: userId,
            },
        },
        fetcher,
    });
};
