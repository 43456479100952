import { FeatureName } from "@accurx/auth";
import { Redirect, Switch } from "react-router";
import { generatePath } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
// TODO remove non-lazy import after code-splitting rollout
import MedicalRecord from "app/recordView/MedicalRecordComponent";
import { RequestMedicalRecord } from "app/recordView/RequestMedicalRecord";
import { OrganisationHelper } from "shared/OrganisationHelper";
import {
    PATIENT_LISTS_ID_ROUTE,
    ROUTES,
    ROUTES_BASE,
    ROUTES_EXTENSION,
    ROUTES_WORKSPACE,
} from "shared/Routes";
import { useAppSelector } from "store/hooks";

// Note: some record view pages are also accessible via workspace routes.

const REQUEST_RECORD_ROUTES = [
    ROUTES.record_view_request_medical_record,
    `${ROUTES_BASE.patientsSearch}${ROUTES_EXTENSION.remoteRecordView}`,
    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.remoteRecordView}`,
];

const VIEW_RECORD_ROUTES = [
    ROUTES.record_view_medical_record,
    `${ROUTES_BASE.patientsSearch}${ROUTES_EXTENSION.remoteRecordViewMedicalRecord}`,
    `${PATIENT_LISTS_ID_ROUTE}${ROUTES_EXTENSION.remoteRecordViewMedicalRecord}`,
];

export const RECORD_VIEW_ROUTES = [
    ROUTES.record_view_medical_record_requests,
    ...REQUEST_RECORD_ROUTES,
    ...VIEW_RECORD_ROUTES,
];

export const RecordViewRoutesSection = () => {
    const workspaceId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    return (
        <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
            <Switch>
                {workspaceId && (
                    <SimpleRoute
                        path={ROUTES.record_view_medical_record_requests}
                        exact
                    >
                        <Redirect
                            to={generatePath(
                                ROUTES_WORKSPACE.workspaceMedicalRecords,
                                { workspaceId },
                            )}
                        />
                    </SimpleRoute>
                )}
                <SimpleRoute path={REQUEST_RECORD_ROUTES} exact>
                    <RequestMedicalRecord />
                </SimpleRoute>
                <SimpleRoute path={VIEW_RECORD_ROUTES} exact>
                    <MedicalRecord />
                </SimpleRoute>
            </Switch>
        </FeatureGuard>
    );
};
