import { mapTicketToConversation } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import { PatientThreadInitialUnreadItems } from "shared/concierge/conversations/tickets/types/dto.types";
import { ConversationUpdate } from "shared/concierge/conversations/types/conversation.types";
import { isInstance } from "shared/concierge/conversations/utils";

export const mapTicketUnreadItemUpdates = (
    result: PatientThreadInitialUnreadItems,
): ConversationUpdate[] => {
    return (
        result.unreadItems
            ?.map((item) => {
                return mapTicketToConversation({
                    ticketSummary: item.ticketSummary,
                    isFullyLoaded: false,
                    items: [item],
                });
            })
            .filter(isInstance) ?? []
    );
};
