import { Days, WorkingHoursSettings } from "app/practices/patientTriage/types";

/**
 * This default data is a fallback for practices that have not yet set up their working hours.
 * We have a script to prepopulate them, but this gives us an extra layer of safety.
 * This data is also useful for mocks in tests.
 */
export const defaultWorkingHours: {
    workingHoursSettings: WorkingHoursSettings;
} = {
    workingHoursSettings: {
        suspendRequestsOutOfHours: true,
        allowAdminOutOfHours: true,
        excludeAdminFromLimit: true,
        workingHours: [
            {
                day: Days.Sunday,
                limit: null,
                from: null,
                to: null,
            },
            {
                day: Days.Monday,
                limit: null,
                from: "09:00",
                to: "17:00",
            },
            {
                day: Days.Tuesday,
                limit: null,
                from: "09:00",
                to: "17:00",
            },
            {
                day: Days.Wednesday,
                limit: null,
                from: "09:00",
                to: "17:00",
            },
            {
                day: Days.Thursday,
                limit: null,
                from: "09:00",
                to: "17:00",
            },
            {
                day: Days.Friday,
                limit: null,
                from: "09:00",
                to: "17:00",
            },
            {
                day: Days.Saturday,
                limit: null,
                from: null,
                to: null,
            },
        ],
        bankHolidayWorkingHours: {
            limit: null,
            from: null,
            to: null,
        },
        suspensionMessageHeader:
            "We have paused access to contact us online as we have reached capacity for the day. Please select one of the available options.",
    },
};
