import { SendRequest } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";
import { mapConversationServerIdToConversationId } from "../mappers/mapConversationServerIdToConversationId";
import { mapConversationSummaryToConversation } from "../mappers/mapConversationSummaryToConversation";

export const send = async (
    request: SendRequest & {
        workspaceId: string;
        workspaceConversationId: string;
    },
): Promise<{ updates: ConciergeUpdates }> => {
    const response = await ClinicianMessagingApiClient.send(request);

    const mappedConversation = mapConversationSummaryToConversation(
        response.summary,
    );

    // Log an error
    if (!mappedConversation) {
        Log.error(
            "messageGp - could not map the response to inbox conversation, no updates will be made",
            {
                tags: {
                    product: "Inbox",
                    conversationId: mapConversationServerIdToConversationId(
                        request.workspaceConversationId,
                    ),
                },
            },
        );
    }

    return {
        updates: {
            conversations: mappedConversation ? [mappedConversation] : [],
            teams: [],
            users: [],
            patients: [],
        },
    };
};
