import { placeholder, trimStart } from "./templates.format";

const paramedicReview = trimStart(
    `I attended the above patient today.
     My impression is ${placeholder("enter details")}. I have ${placeholder(
        "enter details",
    )}.
     I would be grateful if you could ${placeholder("enter details")}.
     Please click this link and use the details below to access my full consultation:
     ${placeholder("insert link")}
     ePCR Ref - ${placeholder("reference")}
     ePCR Pin - ${placeholder("pin")}`,
);

export const ambulanceTemplates = {
    paramedicReview,
};
