/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Feedback, Spinner, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ChainApiClient from "api/VaccineApiHelper";
import { PracticeDetails } from "app/practices/Practices.types";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { InviteStatusType } from "../models/VaccineAllPatientsInvitedDTO";
import {
    RecallCapacityRequest,
    RecallCapacityResponse,
    RecallInviteId,
    VaccineInviteAction,
    VaccinePatientInvited,
} from "../models/VaccineDeliveryDTO";
import { NimsVaccineProductType } from "../models/VaccineSharedDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import { VaccineReviewPatientTable } from "../shared/VaccineReviewPatientTable.component";
import { initialState as VaccinesDeliveryInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";

const DATE_FORMAT = "DD/MM/YYYY";

export const VaccineReviewPatients = (): JSX.Element => {
    const history = useHistory();

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );

    const strictBoosterDoseSeparation = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeStrictBoosterDoseSeparation,
    );

    const reducedBoosterDoseSeparation = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeReducedBoosterDoseSeparation,
    );

    const { inviteActionData } = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery || VaccinesDeliveryInitialState,
    );
    const vaccineTypeFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.vaccineTypeFilters ||
            VaccinesDeliveryInitialState.allInvitesFilters.vaccineTypeFilters,
        shallowEqual,
    );
    const firstVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.firstVaccinationTimeFilter ||
            VaccinesDeliveryInitialState.allInvitesFilters
                .firstVaccinationTimeFilter,
        shallowEqual,
    );
    const secondVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.secondVaccinationTimeFilter ||
            VaccinesDeliveryInitialState.allInvitesFilters
                .secondVaccinationTimeFilter,
        shallowEqual,
    );

    const isBooster =
        inviteActionData.action === VaccineInviteAction.InviteToBoosterBooking;

    const [capacityCheckPassed, setCapacityCheckPassed] =
        useState<boolean>(false);
    const [capacityCheckMessages, setCapacityCheckMessages] = useState<
        string[]
    >([]);
    const [capacityCheckStatus, setCapacityCheckStatus] =
        useState<UpdatingStatus>(UpdatingStatus.Initial);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const checkCapacity = async (
            invites: VaccinePatientInvited[],
        ): Promise<any> => {
            const request: RecallCapacityRequest = {
                isReminder: false,
                isSecondBooking: isBooster ? false : true,
                isBoosterBooking: isBooster ? true : false,
                invites: invites.map((i): RecallInviteId => {
                    return { id: i.inviteId };
                }),
            };
            setCapacityCheckStatus(UpdatingStatus.Loading);
            const result = await ChainApiClient.checkCapacity(
                selectedPractice,
                request,
            );
            if (result.result && result.result.canSendMessages) {
                setCapacityCheckPassed(true);
                setCapacityCheckMessages(
                    generateAvailabilityMessage(result.result),
                );
            } else {
                setErrorMessage(
                    (result.result && result.result.error) ??
                        "Please go back and try again",
                );
            }
            setCapacityCheckStatus(UpdatingStatus.Loaded);
        };
        if (capacityCheckStatus === UpdatingStatus.Initial) {
            checkCapacity(inviteActionData.invites);
        }
    }, [
        isBooster,
        capacityCheckStatus,
        inviteActionData.invites,
        selectedPractice,
    ]);

    const renderReviewPatientsTables = (): JSX.Element => {
        const textableInvites = inviteActionData.invites.filter(
            (i) => i.sendMessages,
        );
        const nonTextableInvites = inviteActionData.invites.filter(
            (i) => !i.sendMessages,
        );

        return (
            <div className="w-100">
                <VaccineReviewPatientTable
                    invites={textableInvites}
                    fullListLength={inviteActionData.invites.length}
                    title={`Will be automatically invited to ${
                        isBooster ? "booster" : "2nd"
                    } vaccination`}
                    subtitle="These patients will be sent SMS invites with unique booking links."
                    testId="textable"
                    badgeText={
                        isBooster
                            ? InviteStatusType.AwaitingResponseLegacy
                            : InviteStatusType.AwaitingResponseSecond
                    }
                    badgeColour="yellow"
                />
                <VaccineReviewPatientTable
                    invites={nonTextableInvites}
                    fullListLength={inviteActionData.invites.length}
                    title={`To manually book to ${
                        isBooster ? "booster" : "2nd"
                    } vaccination`}
                    subtitle="These patients do not have valid UK mobile numbers, and need to be manually booked in."
                    testId="non-textable"
                    badgeText={
                        isBooster
                            ? InviteStatusType.ToManuallyBookLegacy
                            : InviteStatusType.ToManuallyBookSecond
                    }
                    badgeColour="red"
                />
            </div>
        );
    };

    const handleBackToInviteList = (): void => {
        history.push(
            ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                ":orgId",
                selectedPractice,
            ),
        );
    };

    const handleContinueToSmsPreview = (): void => {
        history.push(
            ROUTES_CHAIN.practicesVaccineSmsPreview.replace(
                ":orgId",
                selectedPractice,
            ),
        );
    };

    const getVaccineFiltersText = (
        maxTime: number | undefined,
        minTime: number | undefined,
    ): string => {
        const fromDate =
            maxTime &&
            DateHelpers.getAndFormatDateForDaysBeforeToday(
                maxTime,
                DATE_FORMAT,
            );
        const toDate =
            minTime &&
            DateHelpers.getAndFormatDateForDaysBeforeToday(
                minTime,
                DATE_FORMAT,
            );

        if (fromDate && toDate) {
            return `between ${fromDate} and ${toDate}`;
        } else if (fromDate) {
            return `after ${fromDate}`;
        } else if (toDate) {
            return `before ${toDate}`;
        }

        return "";
    };

    const generateFilteredMessage = (): string => {
        const maxDays = firstVaccinationTimeFilter?.maxDays;
        const minDays = firstVaccinationTimeFilter?.minDays;
        const primaryMaxDaysExclusive =
            secondVaccinationTimeFilter?.maxDaysExclusive;
        const primaryMinDaysInclusive =
            secondVaccinationTimeFilter?.minDaysInclusive;
        const az = vaccineTypeFilters.astrazeneca;
        const janssen = vaccineTypeFilters.janssen;
        const moderna = vaccineTypeFilters.moderna;
        const pfizer = vaccineTypeFilters.pfizer;

        let vaccineFiltersText = `You are inviting patients who had their ${
            isBooster ? "2nd" : "1st"
        } vaccination `;

        vaccineFiltersText += isBooster
            ? getVaccineFiltersText(
                  primaryMaxDaysExclusive,
                  primaryMinDaysInclusive,
              )
            : getVaccineFiltersText(maxDays, minDays);

        const filteredByVaccineNames = [
            { displayedName: NimsVaccineProductType.Az, isFiltered: az },
            {
                displayedName: NimsVaccineProductType.Janssen,
                isFiltered: janssen,
            },
            {
                displayedName: NimsVaccineProductType.Moderna,
                isFiltered: moderna,
            },
            {
                displayedName: NimsVaccineProductType.Pfizer,
                isFiltered: pfizer,
            },
        ]
            .filter((item) => item.isFiltered)
            .map((item) => item.displayedName);

        if (filteredByVaccineNames.length === 1) {
            return `${vaccineFiltersText} - filtered by ${filteredByVaccineNames[0]}`;
        }

        return vaccineFiltersText;
    };

    const generateAvailabilityMessage = (
        response: RecallCapacityResponse,
    ): string[] => {
        if (response.skippedChecks) {
            return [
                `They will be able to see upcoming available clinics${
                    !isBooster
                        ? " that match the vaccine type of their 1st."
                        : "."
                }`,
                "No capacity check was performed, either because all invites are to be manually booked or because capacity checks are disabled.",
            ];
        }

        const start =
            response.capacityStart &&
            DateHelpers.formatWithServerTimezone(
                response.capacityStart,
                DATE_FORMAT,
            );
        const end =
            response.capacityEnd &&
            DateHelpers.formatWithServerTimezone(
                response.capacityEnd,
                DATE_FORMAT,
            );
        const boosterGap = reducedBoosterDoseSeparation
            ? "13 weeks"
            : "26 weeks";
        const boosterImmediate = `Patients will be able to book appointments straight away, even if it is less than ${boosterGap} since they completed their primary course.`;
        const boosterWait = `Patients will not be able to see appointments that are less than ${boosterGap} since they completed their primary course. If patients need to be booked in earlier you must do this on their behalf.`;

        if (start && end) {
            // show specific date range based on newer logic
            const displayDateRange = `The earliest date that will show for this patient group is ${start}, the latest is ${end}.`;
            if (isBooster) {
                return [
                    strictBoosterDoseSeparation
                        ? boosterWait
                        : boosterImmediate,
                    displayDateRange,
                ];
            } else {
                return [
                    "They will be able to see any available clinics which are 11-12 weeks after the date of their 1st " +
                        "vaccine, that also match the vaccine type of their 1st.",
                    displayDateRange,
                ];
            }
        } else if (end) {
            const latestRolling = `The latest date that will show for this patient group rolls forward with time, and is currently ${end}.`;
            if (isBooster) {
                return [
                    strictBoosterDoseSeparation
                        ? boosterWait
                        : boosterImmediate,
                    latestRolling,
                ];
            } else {
                return [
                    "They will be able to see upcoming available clinics that match the vaccine type of their 1st.",
                    latestRolling,
                ];
            }
        } else {
            if (isBooster) {
                return [
                    strictBoosterDoseSeparation
                        ? boosterWait
                        : boosterImmediate,
                ];
            } else {
                return [
                    "They will be able to see upcoming available clinics that match the vaccine type of their 1st.",
                ];
            }
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "6rem",
            }}
        >
            <div
                style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <VaccineDeliveryHeader
                    stepNumber={1}
                    title="Review patient list"
                />
                <Text
                    as="h2"
                    variant="subtitle"
                    props={{
                        className: "w-100",
                        style: { borderBottom: "2px solid lightgray" },
                    }}
                >
                    {inviteActionData.invites.length} patients selected
                </Text>
                <Text
                    props={{
                        className: "w-100",
                        "data-testid": "filters-message",
                    }}
                >
                    {generateFilteredMessage()}
                </Text>
                {capacityCheckMessages.map((msg: string) => (
                    <Text
                        key={msg}
                        props={{
                            className: "w-100",
                            "data-testid": "availability-message",
                        }}
                    >
                        {msg}
                    </Text>
                ))}
                {errorMessage && (
                    <Feedback
                        props={{
                            className: "mb-4",
                            style: { whiteSpace: "pre-line", width: "70%" },
                        }}
                        colour="error"
                        title="Something went wrong"
                        content={errorMessage}
                    />
                )}

                {capacityCheckStatus === UpdatingStatus.Loaded &&
                    capacityCheckPassed &&
                    renderReviewPatientsTables()}
                {capacityCheckStatus === UpdatingStatus.Loading && <Spinner />}
                <StepsFooter
                    backText="Back"
                    backClickFunction={handleBackToInviteList}
                    forwardText="Continue"
                    forwardClickFunction={handleContinueToSmsPreview}
                    disabled={!capacityCheckPassed}
                />
            </div>
        </div>
    );
};

export default VaccineReviewPatients;
