import { useAuth } from "@accurx/auth";
import { useWorkspaceUnreadCountsQuery } from "@accurx/concierge/hooks/queries/useWorkspaceUnreadCountsQuery";
import { generatePath } from "react-router-dom";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useUnreadTicketsQuery } from "api/MessagingApi.hooks";
import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { ROUTES, ROUTES_PRIMARY } from "shared/Routes";

import { useWorkspaceUnreadCount } from "../hooks/useCurrentWorkspaceUnreadCounts";
import {
    WorkspaceConversationUnreadCountBadge,
    getUnreadCountBadgeDisplayValue,
} from "../secondaryNavigation/WorkspaceConversationUnreadCountBadge";

type ConversationsNavLinkProps = {
    onClick?: () => void;
    isCollapsed?: boolean;
    workspaceId: number;
};

/**
 * @returns either the link to the Unified Inbox
 * or the link to the Workspace Conversations
 * depending on a feature flag
 */
const useConversationsLink = (
    workspaceId: ConversationsNavLinkProps["workspaceId"],
) => {
    const isUnifiedInboxEnabled = useFeatureFlag(
        "AccurxWebEmbeddedUnifiedInbox",
    );

    const link = isUnifiedInboxEnabled
        ? ROUTES.inbox
        : ROUTES_PRIMARY.workspaceConversations;

    return generatePath(link, {
        workspaceId: workspaceId,
    });
};

export const ConversationsNavLink = (
    props: ConversationsNavLinkProps,
): JSX.Element => {
    const { user } = useAuth();
    const has2FA = user.isLoggedIn ? user.is2FAed : false;

    const isUnifiedInboxEnabled = useFeatureFlag(
        "AccurxWebEmbeddedUnifiedInbox",
    );

    if (isUnifiedInboxEnabled) {
        return (
            <UnifiedInboxNavLink
                onClick={props.onClick}
                isCollapsed={props.isCollapsed}
                workspaceId={props.workspaceId}
            />
        );
    }

    if (!has2FA) {
        return (
            <NewInboxNavLinkNon2FA
                onClick={props.onClick}
                isCollapsed={props.isCollapsed}
                workspaceId={props.workspaceId}
            />
        );
    }

    return (
        <NewInboxNavLink
            onClick={props.onClick}
            isCollapsed={props.isCollapsed}
            workspaceId={props.workspaceId}
        />
    );
};

export const NewInboxNavLink = (
    props: ConversationsNavLinkProps,
): JSX.Element => {
    const unreadCount = useWorkspaceUnreadCount();
    const link = useConversationsLink(props.workspaceId);

    const displayConversationsUnreadBadge = () =>
        unreadCount ? (
            <WorkspaceConversationUnreadCountBadge unreadCount={unreadCount} />
        ) : null;

    return (
        <NavLink
            variant={LinkVariant.Primary}
            to={link}
            text="Conversations"
            onClick={props.onClick}
            icon="Mailbox"
            renderBadge={displayConversationsUnreadBadge}
            isCollapsed={!!props.isCollapsed}
            customAnalyticsProps={{
                notificationCount: unreadCount
                    ? getUnreadCountBadgeDisplayValue(unreadCount)
                    : undefined,
            }}
        />
    );
};

export const NewInboxNavLinkNon2FA = (
    props: ConversationsNavLinkProps,
): JSX.Element => {
    const unreadCountQuery = useUnreadTicketsQuery(props.workspaceId, {
        refetchInterval: 15 * 60 * 1000, // Refetch data every 15 minutes
        select: (data) => data.totalUnreadTicketCount,
    });

    const link = useConversationsLink(props.workspaceId);

    const unreadCount = unreadCountQuery.data;

    const displayConversationsUnreadBadge = () =>
        unreadCount ? (
            <WorkspaceConversationUnreadCountBadge unreadCount={unreadCount} />
        ) : null;

    return (
        <NavLink
            variant={LinkVariant.Primary}
            to={link}
            text="Conversations"
            onClick={props.onClick}
            icon="Mailbox"
            renderBadge={displayConversationsUnreadBadge}
            isCollapsed={!!props.isCollapsed}
            customAnalyticsProps={{
                notificationCount: unreadCount
                    ? getUnreadCountBadgeDisplayValue(unreadCount)
                    : undefined,
            }}
        />
    );
};

export const UnifiedInboxNavLink = (
    props: ConversationsNavLinkProps,
): JSX.Element => {
    const hasClinicianMessagingInbox = useFeatureFlag(
        "UnifiedInboxAccumailSandboxBuild",
    );

    const { unreadCount } = useWorkspaceUnreadCountsQuery({
        hasClinicianMessagingInbox,
    });

    return (
        <NavLink
            variant={LinkVariant.Primary}
            to={generatePath(ROUTES.inbox, {
                workspaceId: props.workspaceId,
            })}
            text="Conversations"
            onClick={props.onClick}
            icon="Mailbox"
            renderBadge={() =>
                unreadCount ? (
                    <WorkspaceConversationUnreadCountBadge
                        unreadCount={unreadCount}
                    />
                ) : null
            }
            isCollapsed={!!props.isCollapsed}
            customAnalyticsProps={{
                notificationCount: unreadCount
                    ? getUnreadCountBadgeDisplayValue(unreadCount)
                    : undefined,
            }}
        />
    );
};
