import React from "react";

import { Icon, Text } from "@accurx/design";

export type NeedAssistanceProps = {
    onCallback?: () => void; // used to add an extra action on click (ie: closing a modal, scrolling...)
};

export const NeedAssistance = ({
    onCallback,
}: NeedAssistanceProps): JSX.Element => {
    const handleContactUs = () => {
        window.Intercom && window.Intercom("show");
        onCallback && onCallback();
    };
    return (
        <Text skinny>
            Need assistance?{" "}
            <Text variant="link" as="a" props={{ onClick: handleContactUs }}>
                Contact support
                <Icon name="Chat" size={3} colour={"blue"} />
            </Text>
        </Text>
    );
};
