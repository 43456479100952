import React from "react";

import { Accordion, Card, Text } from "@accurx/design";

import { RecordViewResultStatus } from "api/FlemingDtos";

import { RecordStatusBadge } from "./MedicalRecordStatuses";

export const Legend = (): JSX.Element => {
    const statuses = [
        {
            status: RecordViewResultStatus.TwofaCodeRequired,
            description:
                "Patient needs to share their 6-digit access code with you to authorise Record View.",
        },
        {
            status: RecordViewResultStatus.AuthorisationFailed,
            description:
                "Unable to display the record as there were 15 failed attempts to enter the patient's 6 digit access code.",
        },
        {
            status: RecordViewResultStatus.Requesting,
            description:
                "Record View has been authorised and the display is pending.",
        },
        {
            status: RecordViewResultStatus.RequestFailed,
            description:
                "Failed to connect with GP medical record system. This can occur if the record was requested outside of GP working hours.",
        },
        {
            status: RecordViewResultStatus.RecordAvailable,
            description: "Record View has been authorised and can be viewed.",
        },
        {
            status: RecordViewResultStatus.Expired,
            description:
                "The record display has expired after 24 hours and is no longer available.",
        },
    ];

    return (
        <Card>
            <Text colour="night">
                This is a list of all your past Record View requests. To request
                a new record, search for a patient or choose the 'Request GP
                record' within a patient list.
            </Text>
            <Accordion header="What each status means">
                <ul className="list-unstyled d-md-table mb-0">
                    {statuses.map((status, i) => (
                        <li
                            key={i}
                            className="d-md-table-row pb-2 pb-md-0"
                            data-testid={status.status}
                        >
                            <div className="pr-md-2 pb-0 pb-md-1 d-md-table-cell">
                                <RecordStatusBadge status={status.status} />
                            </div>
                            <div className="pb-0 pb-md-1 d-md-table-cell">
                                <Text colour="night" skinny>
                                    {status.description}
                                </Text>
                            </div>
                        </li>
                    ))}
                </ul>
            </Accordion>
        </Card>
    );
};
