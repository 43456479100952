import { useAllClinicianConversationGroupQuery } from "@accurx/concierge/hooks/queries/useAllClinicianConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import {
    ConversationFeed,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ClinicianConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { EXTENDED_CONVERSATION_PREVIEW_HEIGHT } from "domains/inbox/components/ConversationPreview/ConversationPreview.styles";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";

import { StyledAccessibleHeader, StyledConversationList } from "../styles";

export const ClinicianAll = () => {
    const { status } = useInboxParams(["status"]);
    const link = useInboxLink();
    const groupQuery = useAllClinicianConversationGroupQuery({
        status,
    });

    const { trackTabClick } = useConversationListAnalytics();

    useInboxTitle(
        `All ${status.toLowerCase()} conversations with healthcare providers`,
    );

    return (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                All conversations
                                <StyledAccessibleHeader>
                                    showing {status.toLowerCase()} messages
                                </StyledAccessibleHeader>
                            </>
                        }
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithHcp",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>

                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithHcp",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <ConversationFeed
                    key={groupQuery.data?.id}
                    isLoading={groupQuery.status === "loading"}
                    isError={groupQuery.status === "error"}
                    refetch={groupQuery.refetch}
                    fetchMore={groupQuery.fetchMore}
                    isFetchingMore={groupQuery.isFetchingMore}
                    isFullyLoaded={groupQuery.data?.isFullyLoaded}
                    members={groupQuery.data?.members}
                    filters={groupQuery.data?.filters}
                    renderItem={(conversation, state) => (
                        <ClinicianConversationPreview
                            conversation={conversation}
                            item={getMostRecentItem(conversation)}
                            state={state}
                            displayAssigneeBadge
                        />
                    )}
                    itemHeight={EXTENDED_CONVERSATION_PREVIEW_HEIGHT}
                />
            </StyledConversationList>
        </>
    );
};
