import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledBackButtonWrapper = styled.div`
    background: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};

    padding: ${Tokens.SIZES[1]} 0;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    }
`;
