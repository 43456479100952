import { Dispatch } from "redux";

import ChainApiClient from "api/VaccineApiHelper";

import { VaccineGetAppointmentsResponse } from "../models/VaccineCancelDTO";

// -----------------
// ACTION TYPES
// -----------------
export const GET_APPOINTMENTS_STARTED = "GET_APPOINTMENTS_STARTED";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE";

// -----------------
// ACTIONS INTERFACES
// -----------------
interface GetAppointmentsStartedAction {
    type: typeof GET_APPOINTMENTS_STARTED;
}
interface GetAppointmentsSuccessAction {
    type: typeof GET_APPOINTMENTS_SUCCESS;
    vaccineAppointmentsDetails: VaccineGetAppointmentsResponse;
}
interface GetAppointmentsFailureAction {
    type: typeof GET_APPOINTMENTS_FAILURE;
    error: string;
}

export type KnownAction =
    | GetAppointmentsStartedAction
    | GetAppointmentsSuccessAction
    | GetAppointmentsFailureAction;

// ----------------
// ACTION CREATORS
// ----------------
export const getAppointments = (
    organisationId: string,
    batchId: string,
    inviteId: string,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: GET_APPOINTMENTS_STARTED });

        const { success, result } = await ChainApiClient.getVaccineAppointment(
            organisationId,
            batchId,
            inviteId,
        );
        if (success && result) {
            return dispatch({
                type: GET_APPOINTMENTS_SUCCESS,
                vaccineAppointmentsDetails: result,
            });
        }
        return dispatch({
            type: GET_APPOINTMENTS_FAILURE,
            error: "Something went wrong loading your data, please refresh the page",
        });
    };
};
