import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { Button, Feedback, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useQueryClient } from "@tanstack/react-query";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import { UserflowEvent, trackUserflowEvent } from "app/account/Userflow";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { getSinglePatientListRoute } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { actionCreators } from "./PatientListsActions";

interface ListNameErrors {
    required: string;
    duplicate: string;
}

export const listNameErrors: ListNameErrors = {
    required: "Please specify a name for the list",
    duplicate: "A patient list with this name already exists",
};

const CreateOrEditPatientListModalComponent = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryClient = useQueryClient();
    const createOrEditListInitialising = useAppSelector(
        ({ patientLists }) => patientLists.createOrEditListInitialising,
    );
    const allListSummaries = useAppSelector(
        ({ patientLists }) => patientLists.allListSummaries,
        shallowEqual,
    );
    const createOrEditListSubmitting = useAppSelector(
        ({ patientLists }) => patientLists.createOrEditListSubmitting,
    );
    const currentList = useAppSelector(
        ({ patientLists }) => patientLists.currentList,
        shallowEqual,
    );

    useAccurxWebTitle(
        createOrEditListInitialising
            ? currentList === null
                ? "Create a new patient list"
                : "Edit patient list"
            : "View patient list",
    );

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const [newListName, setNewListName] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setNewListName(currentList?.name || "");
    }, [currentList]);

    const handleCancel = (): void => {
        // basically with the cancel we don't really wanna change the state of things
        dispatch(actionCreators.cancelCreateOrEditList());
        // Reset the list name
        setNewListName(currentList?.name || "");
    };

    const analyticsPatientListProps = useAppSelector((state) => {
        const listId = state.patientLists.currentList?.patientListId;
        if (!listId) return undefined;
        return AnalyticsMapper.getPatientListAnalyticsProps(state, listId);
    }, shallowEqual);

    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const analyticsPatientListPropsNewList = useAppSelector((state) => {
        return AnalyticsMapper.getPatientListAnalyticsPropsForNewlyCreatedList(
            state,
        );
    }, shallowEqual);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!!currentList?.patientListId) {
            analyticsPatientListProps &&
                FlemingAnalyticsTracker.trackPatientListEditButtonClick({
                    ...analyticsPatientListProps,
                    ...meaningfulActionProps,
                });
        } else {
            analyticsPatientListPropsNewList &&
                FlemingAnalyticsTracker.trackPatientListCreateButtonClick({
                    ...analyticsPatientListPropsNewList,
                    ...meaningfulActionProps,
                });
        }

        if (!isFormValid() || !organisationId) {
            return;
        }

        dispatch(
            actionCreators.createOrEditPatientList(
                {
                    organisationId,
                    patientListId: currentList?.patientListId || null,
                    name: newListName,
                },
                (patientListId: number) => {
                    history.push(
                        getSinglePatientListRoute(patientListId.toString()),
                    );
                },
                queryClient,
            ),
        );

        trackUserflowEvent(UserflowEvent.PATIENT_LIST_CREATED);
    };

    const isFormValid = (): boolean => {
        if (!newListName) {
            setError(listNameErrors.required);
            return false;
        }

        const foundDuplicateList = allListSummaries?.find(
            (summary) => summary.name === newListName,
        );
        // Check this in case we are editing the list and we don't change the name of the list we are editing
        const isReallyDuplicate =
            !currentList ||
            currentList.patientListId !== foundDuplicateList?.patientListId;
        if (foundDuplicateList && isReallyDuplicate) {
            setError(listNameErrors.duplicate);
            return false;
        }

        return true;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;

        // Remove errors on change
        if (value && error) {
            setError("");
        }

        setNewListName(value);
    };

    return (
        <Modal
            isOpen={createOrEditListInitialising}
            backdrop="static"
            style={{ marginBottom: "6rem" }}
            data-testid="create-edit-modal"
        >
            <ModalHeader tag="div">
                <Text
                    variant="subtitle"
                    as="h3"
                    colour="night"
                    props={{ className: "m-0" }}
                >
                    {currentList === null
                        ? "Create a new list"
                        : "Edit the list"}
                </Text>
                {currentList && currentList.countShare > 0 && (
                    <Feedback
                        colour="information"
                        title="This is a shared list"
                        props={{ className: "mt-2" }}
                    >
                        <Text skinny>
                            Changes are visible to everyone this list is shared
                            with
                        </Text>
                    </Feedback>
                )}
            </ModalHeader>
            <ModalBody className="bg-light">
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    data-testid="create-edit-form"
                >
                    <Text
                        variant="label"
                        as="label"
                        props={{
                            className: "col-form-label mt-0",
                            htmlFor: "listName",
                        }}
                    >
                        List name
                    </Text>
                    <input
                        required
                        className="form-control"
                        type="text"
                        value={newListName}
                        onChange={handleChange}
                        placeholder="Name"
                        id="listName"
                        autoFocus
                    />
                    <div className={!error ? "pb-2" : ""}>
                        {!!error && (
                            <div className="d-flex align-items-center mt-2">
                                <div style={{ lineHeight: "normal" }}>
                                    <Icon
                                        colour="red"
                                        name="Error"
                                        size={3}
                                        theme="Fill"
                                    />
                                </div>
                                <div>
                                    <Text props={{ className: "m-0" }}>
                                        {error}
                                    </Text>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button
                    onClick={handleCancel}
                    text="Cancel"
                    theme="secondary"
                    disabled={createOrEditListSubmitting}
                />
                <Button
                    onClick={handleSubmit}
                    text={createOrEditListSubmitting ? "Saving..." : "Save"}
                    disabled={
                        !!error || !newListName || createOrEditListSubmitting
                    }
                />
            </ModalFooter>
        </Modal>
    );
};

export default CreateOrEditPatientListModalComponent;
