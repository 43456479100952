import React, { useState } from "react";

import { InboxContext } from "./InboxContext";

export const InboxContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [feedCount, setFeedCount] = useState(0);

    return (
        <InboxContext.Provider
            value={{
                feedCount: {
                    value: feedCount,
                    setValue: setFeedCount,
                },
            }}
        >
            {children}
        </InboxContext.Provider>
    );
};
