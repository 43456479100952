import React, { ChangeEvent, FormEvent, useState } from "react";

import {
    Button,
    CollapsibleInfo,
    Feedback,
    Input,
    Option,
    Select,
    Text,
} from "@accurx/design";
import { shallowEqual } from "react-redux";
import { toast } from "react-toastify";

import { IFileUploadRequest } from "api/FlemingDtos";
import { ORGANISATION_RESOURCE_TYPES } from "api/OrganisationResourceApi";
import { useCreateWorkspaceResourceMutation } from "app/hooks/mutations/useCreateWorkspaceResourceMutation";
import { TwoThirdsContainer } from "app/layout/TwoThirdsContainer/TwoThirdsContainer";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { useAppSelector } from "store/hooks";

import { ConfirmUploadModal } from "./ConfirmUploadModal";
import { DocumentEntryCardPreview } from "./DocumentEntryCard";
import {
    StyledButtonsWrapper,
    StyledCollapsibleInfo,
    StyledContent,
    StyledDocumentCardWrapper,
    StyledFormFieldWrapper,
    StyledLabel,
} from "./UploadResource.styles";

type UploadResourceProps = {
    fileUploadRequest: IFileUploadRequest;
    handleGoBack: () => void;
};

const UploadResource = ({
    fileUploadRequest,
    handleGoBack,
}: UploadResourceProps) => {
    const { file, organisationId } = fileUploadRequest;

    const [fileType, setFileType] = useState<string | null>(null);
    const [title, setTitle] = useState("");
    const [selectFileTypeError, setSelectFileTypeError] = useState("");
    const [inputFileTypeError, setInputFileTitleError] = useState("");
    const [isConfirmUploadModalOpen, setIsConfirmUploadModalOpen] =
        useState(false);

    const handleFileTypeOnChange = (selected: Option) => {
        setFileType(selected.value);
        setSelectFileTypeError("");
    };

    const handleTitleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        setInputFileTitleError("");
    };

    const titleIsValid = title.length > 5;

    const addOrganisationResourceMutation = useCreateWorkspaceResourceMutation({
        onError: () => {
            setIsConfirmUploadModalOpen(false);
            toast(
                Feedback({
                    colour: "error",
                    title: "There was a problem uploading this document. Please refresh the page and try again.",
                }),
            );
        },
        onSuccess: () => {
            handleGoBack();
            toast(
                Feedback({
                    colour: "success",
                    title: "This document is now visible to patients in the NHS App.",
                }),
            );
        },
    });

    const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!fileType) {
            setSelectFileTypeError("Please select a type");
        }
        if (!titleIsValid) {
            setInputFileTitleError(
                "Please enter a name with at least 6 characters",
            );
        }

        if (fileType && titleIsValid && organisationId) {
            setIsConfirmUploadModalOpen(true);
        }

        return;
    };

    const username =
        useAppSelector(({ account }) => account.user, shallowEqual)?.fullName ||
        "";

    return (
        <TwoThirdsContainer>
            <NavSubMenuComponent backCallback={handleGoBack} />
            <StyledContent>
                <Text variant="subtitle" as="h1">
                    Describe this resource
                </Text>
                <Text variant="body" colour="metal">
                    What you upload will be visible to you and your colleagues
                    on Accurx as well as patients who use the NHS App.
                </Text>
                <StyledDocumentCardWrapper>
                    <DocumentEntryCardPreview
                        title={file.name}
                        description={`Being uploaded by ${
                            username ? `${username} (you)` : "you"
                        }`}
                    />
                </StyledDocumentCardWrapper>
                <form onSubmit={handleOnSubmit}>
                    <StyledFormFieldWrapper
                        errors={
                            selectFileTypeError
                                ? [selectFileTypeError]
                                : undefined
                        }
                        label="Resource type"
                        labelProps={{
                            htmlFor: "resource-type",
                            skinny: true,
                        }}
                    >
                        <StyledCollapsibleInfo data-testid="resource-type-tip">
                            <CollapsibleInfo animated title="Helpful tip">
                                <Text variant="body" colour="metal" skinny>
                                    The type will be added as a label to help
                                    identify the resource
                                </Text>
                            </CollapsibleInfo>
                        </StyledCollapsibleInfo>
                        <Select
                            id="resource-type"
                            options={(
                                Object.keys(
                                    ORGANISATION_RESOURCE_TYPES,
                                ) as Array<
                                    keyof typeof ORGANISATION_RESOURCE_TYPES
                                >
                            ).map((key) => ({
                                label: ORGANISATION_RESOURCE_TYPES[key],
                                value: key,
                            }))}
                            onChangeHandler={(selected) =>
                                handleFileTypeOnChange(selected as Option)
                            }
                            defaultCopy={{ placeholder: "Select a type" }}
                            dimension="medium"
                        />
                    </StyledFormFieldWrapper>
                    <StyledFormFieldWrapper
                        errors={
                            inputFileTypeError
                                ? [inputFileTypeError]
                                : undefined
                        }
                        label="Resource name"
                        labelProps={{
                            htmlFor: "resource-name",
                            skinny: true,
                        }}
                    >
                        <StyledLabel>
                            <StyledCollapsibleInfo data-testid="resource-name-tip">
                                <CollapsibleInfo animated title="Helpful tip">
                                    <Text variant="body" colour="metal" skinny>
                                        Choose a name that makes sense for your
                                        colleagues and for patients
                                    </Text>
                                </CollapsibleInfo>
                            </StyledCollapsibleInfo>
                            <Input
                                id="resource-name"
                                type="text"
                                placeholder={"Type your resource name"}
                                onChange={handleTitleOnChange}
                            />
                        </StyledLabel>
                    </StyledFormFieldWrapper>
                    <StyledButtonsWrapper>
                        <Button
                            type="button"
                            theme="danger"
                            text="Cancel"
                            onClick={handleGoBack}
                        />
                        <Button
                            type="submit"
                            icon={{
                                name: "Upload",
                            }}
                            text="Save and upload"
                        />
                    </StyledButtonsWrapper>
                </form>
            </StyledContent>
            <ConfirmUploadModal
                isOpen={isConfirmUploadModalOpen}
                handleConfirm={() =>
                    addOrganisationResourceMutation.mutate({
                        file,
                        type: fileType,
                        title,
                        workspaceId: organisationId,
                    })
                }
                handleGoBack={() => setIsConfirmUploadModalOpen(false)}
            />
        </TwoThirdsContainer>
    );
};

export { UploadResource };
