import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    IntegratedClinicListsParams,
    getIntegratedClinicLists,
} from "api/Appointment/IntegratedClinics";

export const useIntegratedClinicLists = createQueryHook(
    ({ workspaceId }: IntegratedClinicListsParams) => ({
        queryKey: ["IntegratedClinicLists", { workspaceId }],
        queryFn: async () => {
            const response = await getIntegratedClinicLists({
                workspaceId,
            });
            return returnDataOrThrow(response);
        },
    }),
);
