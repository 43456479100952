import { ButtonHTMLAttributes, forwardRef } from "react";

import * as UI from "@accurx/design";

import { BaseProps } from "../Base/Base";
import { Icon } from "../Icon/Icon";
import { StyledDropdown, StyledDropdownContent } from "./Dropdown.styles";

export type Ref = HTMLButtonElement;

export type DropdownProps = BaseProps & ButtonHTMLAttributes<HTMLButtonElement>;

export const Dropdown = forwardRef<Ref, DropdownProps>(
    (
        {
            radius = "regular",
            color = UI.Tokens.COLOURS.greyscale.white,
            children,
            ...otherProps
        },
        ref,
    ) => {
        return (
            <StyledDropdown
                $radius={radius}
                $color={color}
                $border={true}
                {...otherProps}
                ref={ref}
            >
                <StyledDropdownContent alignItems="center" gap="0.5">
                    {children}
                    <Icon name="Arrow" colour="stone" />
                </StyledDropdownContent>
            </StyledDropdown>
        );
    },
);
