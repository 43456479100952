import { Tokens } from "@accurx/design";
import { DropdownItem, DropdownToggle } from "reactstrap";
import styled from "styled-components";

export const Z_INDEX = {
    dropdown: 3,
    footer: 4,
};

export const BatchMessageDropDownToggle = styled(DropdownToggle)`
    width: 100%;
    padding: ${Tokens.SIZES[1]};
    font-size: 1rem;
    background-color: ${Tokens.COLOURS.greyscale.white};
    text-align: left;
    border-color: ${Tokens.COLOURS.greyscale.silver};
    color: ${Tokens.COLOURS.greyscale.night};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const getPadding = (heading?: boolean) =>
    Tokens.SIZES[0.5] + " " + Tokens.SIZES[heading ? 2 : 3];

export const BatchMessageDropDownItem = styled(DropdownItem)<{
    $heading?: boolean;
    disabled?: boolean;
}>`
    padding: ${(props) => getPadding(props.$heading)};
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;

    ${(props) =>
        !(props.$heading || props.disabled)
            ? `
                &:hover, &:focus {
                    background-color: ${Tokens.COLOURS.greyscale.silver};
                }
                `
            : ""}
`;
