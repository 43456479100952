import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const FloatingLinkInputWrapper = styled.div`
    position: absolute;
    top: 0;
    z-index: 20;
    padding: ${Tokens.SIZES["1"]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
`;

export const InnerFloatingWrapper = styled.div`
    display: flex;
    column-gap: 8px;
`;
