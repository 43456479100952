import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { AttachmentEvent } from "@accurx/concierge/types";
import { format } from "date-fns";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

import { StyledListItem } from "./ItemAttachment.styles";

type DeletedAttachmentProps = { deleteEvent: AttachmentEvent };

export const DeletedAttachment = ({ deleteEvent }: DeletedAttachmentProps) => {
    const user = useUser({ userId: deleteEvent.userId ?? "" });

    const deletedAtTime = format(
        new Date(deleteEvent.eventTime),
        "h:mmaaa EEE dd MMM YYY",
    );

    return (
        <StyledListItem>
            <div>
                <span>
                    <b>{formatUserDisplayName({ user })}</b> deleted the
                    attachment at {deletedAtTime}.
                </span>
                {deleteEvent.description && (
                    <span>
                        <br />
                        Reason: {deleteEvent.description}
                    </span>
                )}
            </div>
        </StyledListItem>
    );
};
