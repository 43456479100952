import React, { ChangeEvent, FormEvent, useState } from "react";

import {
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    StackPanel,
    Text,
} from "@accurx/design";
import { EmailAddressHelper } from "@accurx/shared";
import { Link, useHistory } from "react-router-dom";

import { postForgottenPassword } from "api/AccountApi";
import { formatSearch } from "app/account/Account.helper";
import { StyledFullWidthButton } from "app/account/SharedStyledComponents.styles";
import { ROUTES } from "shared/Routes";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { useGetProductTypeFromUrl } from "../LogIn/hooks";
import { useGetUtmParams } from "../hooks";
import { AuthForgottenDto, FeedbackMsg } from "./ForgotPassword.types";

export const ForgotPassword = (): JSX.Element => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const product = useGetProductTypeFromUrl();
    const utmParams = useGetUtmParams();
    const paramsEmail = params.get("Email");
    const searchPreLogin = formatSearch({
        search: history.location.search,
        paramsToRemove: ["Email"],
    });

    const [email, setEmail] = useState(paramsEmail || "");
    const [emailError, setEmailError] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });
    const [isWaiting, setIsWaiting] = useState(false);

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        const emailErr = EmailAddressHelper.isValidEmailAddress(email);
        if (!emailErr) {
            setEmailError("Please enter a valid email address");
            return;
        }
        setEmailError("");

        setIsWaiting(true);
        const request: AuthForgottenDto = {
            email,
            product,
        };
        if (utmParams) {
            request.utmParameters = utmParams;
        }

        const res = await postForgottenPassword(request);
        setIsWaiting(false);
        if (!res.success || !res.result) {
            setFeedbackMsg({
                colour: "error",
                msg: res.error || "Something went wrong. Please try again.",
            });
            return;
        }

        history.push(`${ROUTES.checkYourInbox}${searchPreLogin}`, { email });
    };

    return (
        <OuterStackPanel>
            <Flex flexDirection="column" alignItems="center" gap="3">
                <Text variant="title" as="h1" skinny>
                    Forgot password
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    <form noValidate onSubmit={handleSubmit}>
                        <StackPanel orientation="vertical" gutter={2}>
                            <Text skinny>
                                Enter your email below and we'll send you
                                instructions on how to reset your password.
                            </Text>
                            <FormFieldWrapper
                                label="Email"
                                labelProps={{ htmlFor: "user-email" }}
                                errors={[emailError]}
                            >
                                <Input
                                    id="user-email"
                                    value={email}
                                    type="email"
                                    placeholder={"Email address"}
                                    onChange={handleEmailChange}
                                    hasErrors={!!emailError}
                                    required
                                    autoFocus
                                />
                            </FormFieldWrapper>
                            {feedbackMsg.colour && feedbackMsg.msg && (
                                <Feedback
                                    title={feedbackMsg.msg}
                                    colour={feedbackMsg.colour}
                                />
                            )}
                            <StyledFullWidthButton
                                type="submit"
                                theme="primary"
                                text={"Email me reset instructions"}
                                disabled={isWaiting}
                            />
                        </StackPanel>
                    </form>
                </InnerStackPanel>{" "}
                <Text variant="label" skinny>
                    New to Accurx?{" "}
                    <Link to={`${ROUTES.register}${searchPreLogin}`}>
                        Create an account
                    </Link>
                </Text>
            </Flex>
        </OuterStackPanel>
    );
};
