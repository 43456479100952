import { Conversation } from "@accurx/concierge/types";

export const getConversationServerId = (conversation: Conversation): string => {
    switch (conversation.source.system) {
        case "Ticket":
            return conversation.source.ticketIdentity.id;

        case "ClinicianMessaging":
            return conversation.source.serverId;
    }
};
