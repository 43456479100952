import { Text } from "@accurx/design";

export const SignOutEverywhere = () => {
    return (
        <>
            <Text variant="title" as="h1" skinny>
                You have signed out
            </Text>
            <Text variant="subtitle">
                You will shortly be signed out in any other places you have
                Accurx Desktop & Web open.
            </Text>
        </>
    );
};
