import { Text, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

import { CONVERSATION_GROUP_BREAKPOINT } from "../../workspaceConversations/components/ConversationGroup/ConversationGroup.constants";
import { PATIENT_NAME_MAX_CHAR_LENGTH } from "./PatientPill.constants";

export const StyledPatientPillContainer = styled.div`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.SIZES[1]};
    display: inline-block;
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]} ${Tokens.SIZES[0.5]}
        ${Tokens.SIZES[0.5]};
    max-width: 100%;
`;

export const StyledPatientPillInnerContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
`;

export const StyledPatientPillAvatarContainer = styled.div`
    margin-right: ${Tokens.SIZES[0.5]};
`;

const truncationStyles = css`
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledPatientPillNameContainer = styled(Text).attrs(() => ({
    forwardedAs: "span",
    variant: "note",
    skinny: true,
}))<{ verbose?: boolean }>`
    ${({ verbose }) => !verbose && truncationStyles}

    @media all and (min-width: ${CONVERSATION_GROUP_BREAKPOINT}) {
        max-width: ${PATIENT_NAME_MAX_CHAR_LENGTH}ch;
    }
`;

export const StyledPatientPillNhsNumberContainer = styled.span`
    ${truncationStyles}
`;

export const StyledPatientPillAgeGenderContainer = styled.span`
    white-space: nowrap;
`;

export const StyledPatientPillInfoContent = styled(Text).attrs(() => ({
    variant: "preview",
    skinny: true,
}))<{ verbose?: boolean }>`
    display: flex;
    align-items: center;
    min-width: 0;
    ${({ verbose }) => verbose && "flex-wrap: wrap;"}
`;
