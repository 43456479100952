import { PatientThreadTicket, TicketIdentity } from "@accurx/api/ticket";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { isInstance } from "domains/concierge/internal/util/isInstance";

import * as TicketApiClient from "../TicketApiClient";
import { mapPatientThreadUserToUserSummary } from "../mappers/mapPatientThreadUserToUserSummary";
import { mapTicketToConversation } from "../mappers/mapTicketToConversation";

type FetchConversationOptions = {
    ticketIdentity: TicketIdentity;
    workspaceId: number;
};

export const getConversation: ConciergeFetcher<
    FetchConversationOptions,
    PatientThreadTicket
> = async (arg) => {
    const response = await TicketApiClient.fetchTicket(
        arg.workspaceId,
        arg.ticketIdentity,
    );

    const patients = (response.referencedPatients ?? [])
        .map(mapPatientThreadPatientDetailsToPatientSummary)
        .filter(isInstance);

    const users = (response.users ?? [])
        .map(mapPatientThreadUserToUserSummary)
        .filter(isInstance);

    const teams = (response.userGroups ?? [])
        .map(mapPatientThreadUserGroupToTeamSummary)
        .filter(isInstance);

    const conversation = mapTicketToConversation(response);

    return {
        updates: {
            conversations: conversation ? [conversation] : [],
            patients,
            users,
            teams,
        },
        response,
    };
};
