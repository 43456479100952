import { useAnalytics } from "@accurx/analytics";
import {
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";

export type SidebarFolderClickAnalyticsFields = {
    folderName: string;
    unreadCount?: number;
};

export const useSidebarFolderSearchClickAnalytics = () => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const env = useBrowserEnvironment();
    const track = useAnalytics();

    const trackFolderClick = ({
        folderName,
        unreadCount,
    }: SidebarFolderClickAnalyticsFields) => {
        track("ConversationFolderSearch Button Click", {
            accessType: env === "WebView" ? "DesktopApp" : "Browser",
            folderName,
            unreadCount,
            medicalRecordConnection: medicalRecordConnection.system,
        });
    };

    return trackFolderClick;
};
