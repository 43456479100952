import { DateHelpers } from "@accurx/shared";

import {
    PatientName,
    PatientNameUse,
    PatientThreadPatientDetails,
} from "api/FlemingDtos";
import { ConversationSummaryProps } from "app/clinicianConversations/types";

export const getPatientNameFromArray = (namePart?: string[] | null) => {
    return !!namePart?.length ? namePart.join(" ") : null;
};

const mapPatientName = (names: PatientName[]) => {
    const nameTypeUsual = names?.find(
        (patientNames) => patientNames.use === PatientNameUse.Usual,
    );

    const firstName = getPatientNameFromArray(nameTypeUsual?.given);
    const familyName = getPatientNameFromArray(nameTypeUsual?.family);

    return `${familyName?.toUpperCase()}, ${firstName}`;
};

const mapGender = (gender: string) => {
    if (gender === "Male") {
        return "M";
    }
    if (gender === "Female") {
        return "F";
    }
    return gender;
};

export const mapPatient = (
    patientDto?: PatientThreadPatientDetails,
): Pick<
    ConversationSummaryProps,
    "patientName" | "patientGender" | "patientAge"
> => {
    if (!patientDto) {
        return {
            patientName: undefined,
            patientGender: undefined,
            patientAge: undefined,
        };
    }

    const { names, dateOfBirth, gender } = patientDto;
    return {
        patientAge: dateOfBirth
            ? DateHelpers.getAgeFromDate(dateOfBirth)
            : undefined,
        patientGender: gender ? mapGender(gender) : undefined,
        patientName: names ? mapPatientName(names) : undefined,
    };
};
