import { PatientExternalIdentity } from "shared/concierge/patients/types/patient.types";

type PatientName = {
    firstName: string | null;
    familyName: string | null;
};

export const getPatientNhsNumber = (
    patientExternalIds: PatientExternalIdentity[],
): string | undefined => {
    return patientExternalIds.find((id) => id.type === "NhsNumber")?.value;
};

/**
 * Used to format patient name when in conversation header and conversation items.
 */
export const formatPatientFullName = ({
    firstName,
    familyName,
}: PatientName) => {
    if (firstName && familyName) {
        return `${firstName} ${familyName}`;
    }
    if (firstName) {
        return firstName;
    }

    return null;
};
