import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { SelfBookLink } from "domains/compose/reducer.types";

import { StyledPillButton } from "../Attach/components/AttachmentInfoBox.styles";
import { BodyInfoBox } from "../BodyInfoBox";
import { getClinicianNamesDisplay } from "./getClinicianNamesDisplay";

type SelfBookLinkInfoBoxProps = {
    selfBookLink: SelfBookLink;
    onSelfBookLinkRemove: () => void;
};

export const SelfBookLinkInfoBox = ({
    selfBookLink,
    onSelfBookLinkRemove,
}: SelfBookLinkInfoBoxProps) => {
    const clinicianNames = selfBookLink.specificClinician
        ? selfBookLink.specificClinician.flatMap(
              ({ clinicianNames }) => clinicianNames,
          )
        : [];
    const clinicianNamesDisplay = getClinicianNamesDisplay(clinicianNames);

    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header gap="1">
                <UI.Flex gap={"1"}>
                    <BodyInfoBox.Icon name="Calendar" />
                    <BodyInfoBox.Text colour="night">
                        To book: [link will appear here] (Expires in{" "}
                        {selfBookLink.timeoutDays}{" "}
                        {selfBookLink.timeoutDays === 1 ? "day" : "days"})
                    </BodyInfoBox.Text>
                </UI.Flex>
                <StyledPillButton
                    onClick={onSelfBookLinkRemove}
                    title="Remove self book link"
                >
                    <Pill.Icon name="Cross" colour="red" size={3} />
                </StyledPillButton>
            </BodyInfoBox.Header>
            <BodyInfoBox.Footer>
                <BodyInfoBox.Text colour="metal">
                    Patients will book into:{" "}
                    {selfBookLink.appointmentType === "FaceToFace" &&
                        !selfBookLink.crossOrgBooking && (
                            <>
                                <strong>{selfBookLink.slotType}</strong>{" "}
                                (face-to-face)
                            </>
                        )}
                    {selfBookLink.appointmentType === "Telephone" &&
                        !selfBookLink.crossOrgBooking && (
                            <>
                                <strong>{selfBookLink.slotType}</strong>{" "}
                                (telephone)
                            </>
                        )}
                    {selfBookLink.crossOrgBooking && (
                        <>
                            <strong>
                                {selfBookLink.crossOrgBooking.targetOdsName} for{" "}
                                {selfBookLink.slotType} (
                                {selfBookLink.appointmentType === "Telephone"
                                    ? "telephone"
                                    : "face-to-face"}
                                )
                            </strong>
                        </>
                    )}
                    {clinicianNamesDisplay && ` with ${clinicianNamesDisplay}.`}
                </BodyInfoBox.Text>
            </BodyInfoBox.Footer>
        </BodyInfoBox.Wrapper>
    );
};
