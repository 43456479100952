import { CollaborativeWorkspaceResponse } from "@accurx/api/account";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const getWorkspaces = (
    organisationId: number,
): Promise<IWrappedResult<CollaborativeWorkspaceResponse[]>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/account/workspace",
            query: `?organisationId=${organisationId}`,
        }),
    );

export const GET_WORKSPACES_CACHE_KEY = "GetWorkspaces";

type GetWorkspacesQueryVariables = {
    organisationId: number;
};

type GetWorkspacesQueryOptions = Pick<
    UseQueryOptions<CollaborativeWorkspaceResponse[], Error>,
    "refetchOnMount"
>;

export const useGetWorkspacesQuery = (
    { organisationId }: GetWorkspacesQueryVariables,
    { refetchOnMount }: GetWorkspacesQueryOptions = { refetchOnMount: false },
) => {
    return useQuery<CollaborativeWorkspaceResponse[], Error>({
        queryKey: [GET_WORKSPACES_CACHE_KEY, { organisationId }],
        queryFn: async () => {
            const response = await getWorkspaces(organisationId);
            return returnDataOrThrow(response);
        },
        refetchOnMount,
    });
};
