import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { UnreadItemsSummary } from "domains/concierge/schemas/UnreadItemsSummarySchema";
import { Draft } from "immer";

const isUnread = (summary: UnreadItemsSummary) => {
    return summary.status === "Open" && summary.itemIds.length > 0;
};

/**
 * Given the current and the updated version of an unread items summary it
 * updates the ticket unread counts.
 */
const updateTicketUnreadCounts = ({
    state,
    current,
    next,
    userId,
}: {
    state: Draft<ConversationsState>;
    current: UnreadItemsSummary | undefined;
    next: UnreadItemsSummary;
    userId: string;
}) => {
    const unreadCounts = state.unreadCounts.ticket;
    const teamMembership = state.teamMembership;

    // If the conversation was assigned to the current user and
    // had unread items, decrement the unread count for that user.
    if (
        current &&
        isUnread(current) &&
        current.assignee.type === "User" &&
        current.assignee.id === userId
    ) {
        unreadCounts.user--;

        // If it had a patient also decrement the patient unread count
        if (current.patientId) {
            const patientId = current.patientId;
            unreadCounts.patients[patientId] =
                unreadCounts.patients[patientId] ?? 1;
            unreadCounts.patients[patientId]--;

            // we only keep an entry for patients that have an unread count > 0
            // so if it's now reached zero lets delete it
            if (unreadCounts.patients[patientId] === 0) {
                delete unreadCounts.patients[patientId];
            }
        }
    }

    // If the conversation is now assigned to the current user
    // increment the unread count for that user.
    if (
        next &&
        isUnread(next) &&
        next.assignee.type === "User" &&
        next.assignee.id === userId
    ) {
        unreadCounts.user++;

        // If it has a patient also increment the patient unread count
        if (next.patientId) {
            const patientId = next.patientId;
            unreadCounts.patients[patientId] =
                unreadCounts.patients[patientId] ?? 0;
            unreadCounts.patients[patientId]++;
        }
    }

    // If the conversation was assigned to a team and had unread items,
    // decrement the unread count for that team.
    if (current && isUnread(current) && current.assignee.type === "Team") {
        const teamId = current.assignee.id;
        const patientId = current.patientId;
        unreadCounts.teams[teamId] = unreadCounts.teams[teamId] ?? 1;
        unreadCounts.teams[teamId]--;

        // we only keep an entry for teams that have an unread count > 0 so if
        // it's now reached zero lets delete it
        if (unreadCounts.teams[teamId] === 0) {
            delete unreadCounts.teams[teamId];
        }

        // if it had a patient and the user is a member of the team also
        // decrement the patient unread count
        if (patientId && teamMembership[teamId]) {
            unreadCounts.patients[patientId] =
                unreadCounts.patients[patientId] ?? 1;
            unreadCounts.patients[patientId]--;

            // we only keep an entry for patients that have an unread count > 0
            // so if it's now reached zero lets delete it
            if (unreadCounts.patients[patientId] === 0) {
                delete unreadCounts.patients[patientId];
            }
        }
    }

    // If the conversation is now assigned to a team and has unread items,
    // increment the unread count for that team.
    if (next && isUnread(next) && next.assignee.type === "Team") {
        const teamId = next.assignee.id;
        const patientId = next.patientId;
        unreadCounts.teams[teamId] = unreadCounts.teams[teamId] ?? 0;
        unreadCounts.teams[teamId]++;

        // if it has a patient and the user is a member of the team also
        // increment the patient unread count
        if (patientId && teamMembership[teamId]) {
            unreadCounts.patients[patientId] =
                unreadCounts.patients[patientId] ?? 0;
            unreadCounts.patients[patientId]++;
        }
    }
};

/**
 * Given the current and the updated version of an unread items summary it
 * updates the clinician messaging unread counts.
 */
const updateClinicianMessagingUnreadCounts = ({
    state,
    current,
    next,
    userId,
}: {
    state: Draft<ConversationsState>;
    current: UnreadItemsSummary | undefined;
    next: UnreadItemsSummary | undefined;
    userId: string;
}) => {
    const unreadCounts = state.unreadCounts.clinicianMessaging;

    // If the conversation was assigned to the current user and
    // had unread items, decrement the unread count for that user.
    if (
        current &&
        isUnread(current) &&
        current.assignee.type === "User" &&
        current.assignee.id === userId
    ) {
        unreadCounts.user--;
    }

    // If the conversation is now assigned to the current user
    // increment the unread count for that user.
    if (
        next &&
        isUnread(next) &&
        next.assignee.type === "User" &&
        next.assignee.id === userId
    ) {
        unreadCounts.user++;
    }

    // If the conversation was assigned to a team and had unread items,
    // decrement the unread count for that team.
    if (current && isUnread(current) && current.assignee.type === "Team") {
        const teamId = current.assignee.id;
        unreadCounts.teams[teamId] = unreadCounts.teams[teamId] ?? 1;
        unreadCounts.teams[teamId]--;

        // we only keep an entry for teams that have an unread count > 0 so if
        // it's now reached zero lets delete it
        if (unreadCounts.teams[teamId] === 0) {
            delete unreadCounts.teams[teamId];
        }
    }

    // If the conversation is now assigned to a team and has unread items,
    // increment the unread count for that team.
    if (next && isUnread(next) && next.assignee.type === "Team") {
        const teamId = next.assignee.id;
        unreadCounts.teams[teamId] = unreadCounts.teams[teamId] ?? 0;
        unreadCounts.teams[teamId]++;
    }
};

export const updateUnreadState = ({
    state,
    current,
    next,
    userId,
}: {
    state: Draft<ConversationsState>;
    current: UnreadItemsSummary | undefined;
    next: UnreadItemsSummary;
    userId: string;
}) => {
    const { system, conversationId } = next;

    switch (system) {
        case "Ticket":
            updateTicketUnreadCounts({ state, current, next, userId });
            break;

        case "ClinicianMessaging":
            updateClinicianMessagingUnreadCounts({
                state,
                current,
                next,
                userId,
            });
            break;
    }

    // If there are any unread IDs store the summary, otherwise delete it to
    // free up space
    if (next.itemIds.length > 0) {
        state.unreadItems[conversationId] = next;
    } else {
        delete state.unreadItems[conversationId];
    }

    return state;
};
