import { Tabs } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { Breadcrumb, Breadcrumbs, Header } from "../shared";
import { PowerBIReporting } from "./PowerBIReporting";
import { StyledText } from "./PowerBIReporting.styles";
import { TABS, TabLookup } from "./PowerBIReportingUtils";

export const PowerBIReportingOverview = (): JSX.Element => {
    const { orgId, tab: tabId } = useParams<{ orgId: string; tab: string }>();

    const currentTab = TabLookup[tabId || "questionnaire"];
    const history = useHistory();

    const onTabChange = (tabId: string) => {
        history.push({
            pathname: generatePath(ROUTES_ORGS.powerBIReporting, {
                orgId,
                tab: tabId,
            }),
            state: { navigationOrigin: "Web" },
        });
    };
    return (
        <Tabs selected={currentTab?.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="Reporting"
                        path={ROUTES_ORGS.powerBIReporting}
                    />
                    <Breadcrumb
                        text="Response rates"
                        path={ROUTES_ORGS.powerBIReporting}
                    />
                </Breadcrumbs>
                <StyledText variant="title" skinny>
                    Response rates
                </StyledText>
                <Header.TabList tabs={TABS} />
            </Header>

            <Tabs.Panel tabId="questionnaire">
                <PowerBIReporting />
            </Tabs.Panel>
            <Tabs.Panel tabId="booking">
                <PowerBIReporting />
            </Tabs.Panel>
        </Tabs>
    );
};
