/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import DeliveryReceipts from "./DeliveryReceipts";
import * as actions from "./DeliveryReceipts.actions";

const mapStateToProps = (state: any) => ({
    practiceId: state.practices.selectedPractice,
    loadingStatus: state.deliveryReceiptsChain.loadingStatus,
    receipts: state.deliveryReceiptsChain.receipts,
    receiptHeaderType: state.deliveryReceiptsChain.receiptHeaderType,
    isRestrictedToOwnReceipts:
        state.deliveryReceiptsChain.isRestrictedToOwnReceipts,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export const DeliveryReceiptsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryReceipts);

export default DeliveryReceiptsContainer;
