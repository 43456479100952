import { Flex, Tokens } from "@accurx/design";

import { SkeletonBlock } from "./SkeletonBlock";
import { StyledBorderedFlex } from "./SkeletonList.styles";

// This component is a copy of the SkeletonList in fleming-client's shared components
// It should not be changed, as we want to eventually replace it with an import of a shared component
export const SkeletonList = ({
    quantity = 5,
    height = Tokens.SIZES[8],
}: {
    quantity?: number;
    height?: string;
}) => (
    <div role="status" title="Loading...">
        <Flex flexDirection="column" gap="1">
            {/* This line differs from the original SkeletonList to add a type to the array due to stricter linting rules */}
            {[...Array<undefined>(quantity)].map((_, i) => (
                <StyledBorderedFlex
                    $height={height}
                    justifyContent="center"
                    alignItems="center"
                    key={`loading-item-${i}`}
                >
                    <SkeletonBlock height="100%" width="100%" />
                </StyledBorderedFlex>
            ))}
        </Flex>
    </div>
);
