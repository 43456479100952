import * as UI from "@accurx/design";

import { StyledCustomRadioLabelWrapper } from "./PatientSearchConfirmRadioButton.styles";

export type PatientSearchConfirmRadioButtonProps = {
    label: JSX.Element;
    value: string;
    checkedValue: string;
    onChange: () => void;
};

export const PatientSearchConfirmRadioButton = ({
    label,
    value,
    checkedValue,
    onChange,
}: PatientSearchConfirmRadioButtonProps) => {
    return (
        <StyledCustomRadioLabelWrapper>
            <UI.Radio
                checkPlacement="top-left"
                name={"confirm-patient"}
                theme="bordered"
                id={value}
                label={label}
                value={value}
                checkedValue={checkedValue}
                onChange={onChange}
            />
        </StyledCustomRadioLabelWrapper>
    );
};
