import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import { ListBeastSeriesDto } from "app/practices/beast/Beast.types";

const ENDPOINTS = {
    beastSeries: "/api/patientMessaging/beast/:practiceId/series",
    beastPatientsCsv:
        "/api/patientMessaging/beast/:practiceId/series/:seriesId/patientsCsv",
} as const;

export const bFetchBeastSeries = (
    practiceId: string,
): Promise<IWrappedResult<ListBeastSeriesDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.beastSeries,
            params: { practiceId },
        }),
    );

export const bGetBeastPatientCsvDownloadUrl = (
    practiceId: string,
    seriesId: string,
): string => {
    return getApiEndpoint({
        path: ENDPOINTS.beastPatientsCsv,
        params: { practiceId, seriesId },
    }).urlFinal;
};
