import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
} from "./FlemingAnalyticsConstants";

/**
 * User views add organisation form page to request a new org to be added
 *
 * Event type: Page view
 * */
export const trackOrganisationSetupPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "AccountOrganisationSetUp",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * When the user submits a request to add a new organisation
 *
 * Event type: Button click
 * */
export const trackOrganisationSetUpButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "AccountOrganisationSetUp",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * After user filled the form to link an organisation to their account and click on the "Take Me Back" button
 *
 * Event type: Link click
 * */
export const trackTakeMeBackLinkClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "AccountOrganisationSetUpTakeMeBack",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User filled in the form to accept terms and conditions
 *
 * Event type: Button click
 * */
export const trackUsePolicyAcceptButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "AccountUsePolicyAccept",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
