import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Text } from "@accurx/design";
import { SplitButtonGroup } from "@accurx/navigation";
import { generatePath } from "react-router-dom";

import { ROUTES, ROUTES_BASE, ROUTES_PRIMARY } from "shared/Routes";

import { HelpPopover } from "./HelpPopover/HelpPopover";
import { PrimaryNavLink } from "./NavLink/PrimaryNavLink";
import { NewMessage } from "./NewMessage/NewMessage";
import { PatientSearch } from "./PatientSearch/PatientSearch";
import {
    StyledNavLinkList,
    StyledPrimaryNavigation,
} from "./UnifiedPrimaryNavigation.styles";
import { WorkspaceMenu } from "./WorkspaceMenu/WorkspaceMenu";
import { WorkspaceSwitcher } from "./WorkspaceSwitcher/WorkspaceSwitcher";

export const UnifiedSimplePrimaryNavigation = () => (
    <StyledPrimaryNavigation data-testid="simple-primary-nav">
        <Ds.Flex justifyContent="flex-end">
            <HelpPopover />
        </Ds.Flex>
    </StyledPrimaryNavigation>
);

export const UnifiedPrimaryNavigation = () => {
    const { orgId: workspaceId } = useCurrentWorkspace();

    return (
        <StyledPrimaryNavigation data-testid="primary-nav">
            <Ds.Grid columns="1fr 1fr 1fr" gap="1">
                <Ds.Flex justifyContent="flex-start" gap="1">
                    <NewMessage />
                    <PatientSearch />
                </Ds.Flex>
                <Ds.Flex justifyContent="center" gap="1">
                    <StyledNavLinkList>
                        <PrimaryNavLink
                            activePaths={[ROUTES.inbox]}
                            to={generatePath(ROUTES.inbox, {
                                workspaceId,
                            })}
                        >
                            <Ds.Icon
                                name="Mailbox"
                                color="white"
                                size="small"
                            />
                            <Text variant="label" colour="white" skinny>
                                Inbox
                            </Text>
                        </PrimaryNavLink>
                        <PrimaryNavLink
                            activePaths={[
                                ROUTES_PRIMARY.patients,
                                ROUTES_PRIMARY.batchMessageTrust,
                                ROUTES_PRIMARY.questionnaireScoreboard,
                            ]}
                            to={ROUTES_BASE.patientsListsAll}
                        >
                            <Ds.Icon name="Team" color="white" size="small" />
                            <Text variant="label" colour="white" skinny>
                                Cohorts
                            </Text>
                        </PrimaryNavLink>
                    </StyledNavLinkList>
                </Ds.Flex>
                <Ds.Flex gap="1" justifyContent="flex-end">
                    <SplitButtonGroup>
                        <WorkspaceMenu />
                        <WorkspaceSwitcher />
                    </SplitButtonGroup>
                    <HelpPopover />
                </Ds.Flex>
            </Ds.Grid>
        </StyledPrimaryNavigation>
    );
};
