import { useEffect } from "react";

import { Flex } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { BatchUploadPopulatedDemographicsReview } from "app/batchMessage/trust/components/BatchUploadPopulatedDemographicsReview";
import { splitItemsByContactable } from "app/batchMessage/trust/pages/utils/contactable";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useAppSelector, useCurrentOrgId } from "store/hooks";

import { removeUploadList } from "../../BatchMessage.actions";

const Wrapper = styled(Flex).attrs(() => ({
    flexDirection: "column",
    gap: "2",
}))`
    max-width: 46rem;
    margin: 0 auto;
`;

type UploadReviewProps = {
    /** Used for analytics purposes - default to false */
    isTrustFlow?: boolean;
};

export const UploadReview = ({
    isTrustFlow = false,
}: UploadReviewProps): JSX.Element => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const {
        batchMessageId,
        weeksAvailable,
        selectedSlotType,
        batchType: batchMessageType,
        batchMessageDetails: { totalNonTextable },
    } = useAppSelector(({ batchMessage }) => batchMessage);

    const practiceId = useCurrentOrgId()?.toString() ?? "";
    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );

    const { contactable } = splitItemsByContactable(
        patientList,
        batchMessageId,
    );
    const numOfTextablePatients = contactable.length;

    const numAvailableAppointmentsForSlotType = selectedSlotType.availability;
    const appointmentAvailabilityWarning =
        batchMessageType === BatchType.SELFBOOK &&
        numOfTextablePatients - numAvailableAppointmentsForSlotType >
            numAvailableAppointmentsForSlotType;

    useEffect(() => {
        const countMobileNumberOverride = contactable.filter(
            (patient) => patient.contact.isOverride,
        ).length;

        ChainAnalyticsTracker.trackBatchPatientListReviewPageView({
            ...analyticsLoggedInProps,
            batchSlotAvailable: numAvailableAppointmentsForSlotType,
            batchCapacityWarningSeen: appointmentAvailabilityWarning,
            batchPatientListSize: numOfTextablePatients,
            countPatientNotContactable: totalNonTextable,
            isTrustFlow,
            countMobileNumberOverride,
        });
    }, [
        analyticsLoggedInProps,
        contactable,
        numAvailableAppointmentsForSlotType,
        numOfTextablePatients,
        totalNonTextable,
        isTrustFlow,
        appointmentAvailabilityWarning,
    ]);

    const removeCsv = () => {
        dispatch(removeUploadList());
    };

    return (
        <StyledLayoutWithFooter>
            <Wrapper>
                <BatchUploadPopulatedDemographicsReview
                    uploadedPatients={patientList}
                    onRemoveAllClick={removeCsv}
                    workspaceId={practiceId}
                    batchId={batchMessageId}
                    isTrustFlow={false}
                    // Self-book specific props
                    isAppointmentAvailabilityWarningVisible={
                        appointmentAvailabilityWarning
                    }
                    numAvailableAppointmentsForSlotType={
                        numAvailableAppointmentsForSlotType
                    }
                    weeksAvailable={weeksAvailable}
                />
            </Wrapper>
        </StyledLayoutWithFooter>
    );
};
