import { ReactNode, useState } from "react";

import {
    Feedback,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { toast } from "react-toastify";

import { useSearchForPatientByNhsNumber } from "app/hooks/mutations";
import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";

type ContactDropdownProps = {
    dateOfBirth: string;
    nhsNumber?: Optional<string>;
    workspaceId: string;
    triggerButton({
        isDropdownOpen,
        status,
    }: {
        isDropdownOpen: boolean;
        status: "idle" | "loading" | "error" | "success";
    }): ReactNode;
    dropdownContent({
        data,
    }: {
        data: SearchForPatientResponseSuccess;
    }): ReactNode;
    isUserAction?: boolean;
};

export default function ContactDropdown({
    dateOfBirth,
    nhsNumber,
    workspaceId,
    triggerButton,
    dropdownContent,
    isUserAction = false,
}: ContactDropdownProps) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { data, status, mutate } = useSearchForPatientByNhsNumber();

    const showErrorMessage = () =>
        toast(
            <Feedback
                colour="error"
                title="Something went wrong while fetching patient demographics"
            />,
        );

    /**
     * Perform a patient search before opening the dropdown
     * so we can get full patient details and display the options correctly
     */
    const handleOpenDropdown = () => {
        const dobParts = DateHelpers.getDateParts(dateOfBirth);

        if (!dobParts || !nhsNumber || !workspaceId) {
            showErrorMessage();
        } else {
            mutate(
                {
                    workspaceId,
                    nhsNumber,
                    dateOfBirthYear: dobParts.y,
                    dateOfBirthMonth: dobParts.m,
                    dateOfBirthDay: dobParts.d,
                    isUserAction,
                },
                {
                    onSuccess: () => {
                        setIsDropdownOpen(true);
                    },
                    onError: showErrorMessage,
                },
            );
        }
    };

    return (
        <Popover
            open={isDropdownOpen}
            onOpenChange={() => {
                if (isDropdownOpen) {
                    setIsDropdownOpen(false);
                } else {
                    handleOpenDropdown();
                }
            }}
        >
            <PopoverTrigger asChild>
                {triggerButton({ isDropdownOpen, status })}
            </PopoverTrigger>
            <PopoverContent align="end" side="bottom" width="100%">
                {status === "success" && dropdownContent({ data })}
            </PopoverContent>
        </Popover>
    );
}
