import { useState } from "react";

import { Button, Flex, Icon, Text } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";

import { trackQuestionnaireResponsesViewResponse } from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { FloreyScoreSummary } from "app/questionnaireScoreboard/schemas";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { QuestionnaireResponseStatus } from "../QuestionnaireResponseStatus";
import { ResponsesTable } from "../responsesTable/ResponsesTable";
import { CurrentFiltersText } from "./CurrentFiltersText";
import { StyledFilters } from "./Filters.styles";
import { FiltersForm } from "./FiltersForm";
import { StyledDashboard } from "./ResponsesDashboard.styles";
import { FILTER_FORM_LABEL_ID } from "./constants";
import { FilterOptions } from "./types";

export const ResponsesDashboard = ({
    workspaceId,
    tableData,
    questionnaireId,
    currentFilters,
    onFiltersApplied,
}: {
    workspaceId: string;
    questionnaireId: string;
    tableData: FloreyScoreSummary[];
    currentFilters: FilterOptions;
    onFiltersApplied: (filters: FilterOptions) => void;
}) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    const [activeEnrolmentId, setActiveEnrolmentId] = useState<number | null>(
        null,
    );

    const [isFiltersQuickViewShowing, setIsFiltersQuickViewShowing] =
        useState(false);

    const onViewResponseClick = (floreyEnrolmentId: number) => {
        trackQuestionnaireResponsesViewResponse({
            ...loggedInProps,
            conditionId: PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
            floreyEnrolmentId,
        });
        setActiveEnrolmentId(floreyEnrolmentId);
    };

    const handleFiltersQuickViewClose = () => {
        setIsFiltersQuickViewShowing(false);
    };

    const onFilterClick = () => {
        if (isFiltersQuickViewShowing) {
            handleFiltersQuickViewClose();
        } else {
            setIsFiltersQuickViewShowing(true);
        }
    };

    return (
        <>
            <StyledDashboard.Outer>
                <StyledDashboard.Header>
                    <Flex
                        gap="1.5"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Flex alignItems="center" gap="1">
                            <Icon
                                name="Questionnaire"
                                size={4}
                                halo={{
                                    colour: "blue",
                                }}
                            />
                            <Text variant="label" skinny as="h2">
                                Questionnaires sent
                            </Text>
                        </Flex>
                        <Button
                            theme="secondary"
                            text="Filter"
                            icon={{
                                name: "Filter",
                                colour: "blue",
                            }}
                            onClick={onFilterClick}
                        />
                    </Flex>
                    <CurrentFiltersText currentFilters={currentFilters} />
                </StyledDashboard.Header>
                {tableData.length > 0 ? (
                    <>
                        <StyledDashboard.Body>
                            <ResponsesTable
                                data={tableData}
                                onViewResponseClick={onViewResponseClick}
                            />
                        </StyledDashboard.Body>
                        {/* TODO: add pagination */}
                        <StyledDashboard.Footer></StyledDashboard.Footer>
                    </>
                ) : (
                    <StyledDashboard.EmptyBody>
                        <Text skinny>No questionnaires sent yet</Text>
                    </StyledDashboard.EmptyBody>
                )}
            </StyledDashboard.Outer>
            <QuickViewPortal
                isOpen={activeEnrolmentId !== null}
                onClose={() => setActiveEnrolmentId(null)}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={() => setActiveEnrolmentId(null)}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    {activeEnrolmentId && (
                        <QuestionnaireResponseStatus
                            workspaceId={workspaceId}
                            floreyEnrolmentId={activeEnrolmentId}
                            questionnaireId={questionnaireId}
                        />
                    )}
                </QuickViewPortal.Body>
            </QuickViewPortal>
            <QuickViewPortal
                isOpen={isFiltersQuickViewShowing}
                onClose={handleFiltersQuickViewClose}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={handleFiltersQuickViewClose}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    <StyledFilters.QuickViewBodyWrapper>
                        <Text
                            as="h2"
                            variant="subtitle"
                            props={{ id: FILTER_FORM_LABEL_ID }}
                            skinny
                        >
                            Filter questionnaires
                        </Text>
                        <FiltersForm.Body
                            initialValues={currentFilters}
                            onFiltersApplied={(filters) => {
                                handleFiltersQuickViewClose();
                                onFiltersApplied(filters);
                            }}
                        />
                    </StyledFilters.QuickViewBodyWrapper>
                </QuickViewPortal.Body>
                <QuickViewPortal.Footer>
                    <Flex justifyContent="space-between">
                        <FiltersForm.ClearButton
                            onFiltersClear={() => {
                                handleFiltersQuickViewClose();
                                onFiltersApplied({
                                    dateStart: "",
                                    dateEnd: "",
                                    dateType: "date-sent",
                                    complexity: [],
                                    status: [],
                                });
                            }}
                        />
                        <FiltersForm.SubmitButton />
                    </Flex>
                </QuickViewPortal.Footer>
            </QuickViewPortal>
        </>
    );
};
