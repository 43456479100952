import styled from "styled-components";

/** Do not use directly, use the InfoBoxIcon instead */
export const StyledInfoBoxIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    // 20px is not a size available to set to UI.Icon
    svg {
        height: 20px;
        width: 20px;
    }
`;
