import React from "react";

import { Text } from "@accurx/design";
import partition from "lodash/partition";

import { Allergy, MedicalRecordResult } from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { CardSpacer } from "./CardSpacer";
import {
    type AllergiesBadge,
    MedicalRecordPanelCard,
} from "./MedicalRecordPanelCard";

export type AllergiesProps = Pick<MedicalRecordResult, "allergies">;

type GroupedAllergies = {
    all: Allergy[];
    current: Allergy[];
    past: Allergy[];
};

const getHasAllergies = (allergies: Allergy[]): boolean => {
    return allergies.filter((x) => !x.isNoKnownAllergy).length > 0;
};

const groupAllergies = (allergies: Allergy[]): GroupedAllergies => {
    const [current, past] = partition(allergies, (x) => x.endDate === null);
    return {
        all: allergies,
        current,
        past,
    };
};

export const getAllergiesBadgeProps = (
    allergies: Allergy[],
): AllergiesBadge => {
    const groupedAllergies = groupAllergies(allergies);
    return getAllergiesBadgePropsFromGrouped(groupedAllergies);
};

// (From Satya) We want 3 label states:
// 1. Known allergies
// 2. No known allergies (if the record includes the ‘no known allergies’ codes or only an entry in past allergies)
// 3. No allergies recorded (when there is nothing in the allergy status in current or past)
const getAllergiesBadgePropsFromGrouped = (
    groupedAllergies: GroupedAllergies,
): AllergiesBadge => {
    const hasCurrentAllergies = getHasAllergies(groupedAllergies.current);
    return {
        text: hasCurrentAllergies
            ? "Known allergies"
            : groupedAllergies.all.length > 0
            ? "No known allergies"
            : "No allergies recorded",
        color: hasCurrentAllergies ? "red" : "greyscale",
    };
};

export const Allergies = ({ allergies }: AllergiesProps): JSX.Element => {
    const groupedAllergies = groupAllergies(allergies);
    return (
        <>
            <AllergiesDisplay
                allergies={groupedAllergies.current}
                title="Allergies & sensitivities"
                badge={getAllergiesBadgePropsFromGrouped(groupedAllergies)}
            />
            {groupedAllergies.past && groupedAllergies.past.length > 0 && (
                <>
                    <CardSpacer spacing="medium" />
                    <AllergiesDisplay
                        allergies={groupedAllergies.past}
                        title="Past allergies & sensitivities"
                        isCollapsible={true}
                    />
                </>
            )}
        </>
    );
};

type AllergiesDisplayProps = AllergiesProps & {
    title: string;
    badge?: AllergiesBadge;
    isCollapsible?: boolean;
};

const AllergiesDisplay = ({
    allergies,
    title,
    badge,
    isCollapsible,
}: AllergiesDisplayProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard
            title={title}
            badge={badge}
            collapsible={isCollapsible}
        >
            {allergies.length > 0 ? ( // Show all allergies in the list including 'No known allergy' records
                <CardRowStack>
                    {allergies.map((allergy, i) => (
                        <CardRow
                            type={CardRowType.DefaultWithComments}
                            key={i.toString()}
                            label={formatMedicalRecordDate(allergy.date)}
                            title={allergy.description}
                            comments={[
                                allergy.freeText
                                    ? `Comment: ${allergy.freeText}`
                                    : "",
                                allergy.endDate
                                    ? `Ended: ${formatMedicalRecordDate(
                                          allergy.endDate,
                                      )}`
                                    : "",
                            ]}
                            captionComments
                        />
                    ))}
                </CardRowStack>
            ) : (
                <Text skinny>No allergies recorded</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
