import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledAttachedFilesWrapper = styled.div`
    margin-top: ${Tokens.SIZES[1.5]};
    display: flex;
`;

export const StyledAttachedFile = styled.div`
    flex-basis: 476px;
    padding: ${Tokens.SIZES[1.5]};
    border: 1px solid #e0e1eb;
    border-radius: ${Tokens.SIZES[0.5]};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledFileInformation = styled.div`
    display: flex;
    align-items: center;
    gap: ${Tokens.SIZES[2]};
`;
