import React from "react";

import { useQuestionnaireListQuery } from "app/hooks/queries";

import { QuestionnairesList } from "./QuestionnairesList";
import { mapFloreyTemplatesResponseToDisplay } from "./mappers";
import { DisplayFloreyMessageTemplate } from "./types";

type QuestionnairesListProps = {
    onUseQuestionnaireClick: (template: DisplayFloreyMessageTemplate) => void;
    /** Needed for fetching the org questionnaires */
    workspaceId: number;
};

export const BatchQuestionnaireList = ({
    onUseQuestionnaireClick,
    workspaceId,
}: QuestionnairesListProps): JSX.Element => {
    const { status, data } = useQuestionnaireListQuery(
        { workspaceId, forBatch: true },
        {
            select: (data) => mapFloreyTemplatesResponseToDisplay(data),
        },
    );

    if (status === "loading" || status === "error") {
        return <QuestionnairesList workspaceId={workspaceId} status={status} />;
    }

    return (
        <QuestionnairesList
            workspaceId={workspaceId}
            status={status}
            questionnaires={data.groups}
            onUseQuestionnaireClick={onUseQuestionnaireClick}
        />
    );
};
