import { PatientDemographics } from "@accurx/native";
import { DateHelpers } from "@accurx/shared";
import { getPatientNameFromArray } from "domains/concierge/internal/util/getPatientNameFromArray";
import { PatientExternalId, PatientSummary } from "domains/concierge/types";

import { mergeExternalIds } from "./mergeExternalIds";

export const mapPatientDemographicsNamesToPatientSummaryParts = (
    names?: PatientDemographics["patientName"],
): Pick<
    PatientSummary,
    "familyName" | "firstName" | "prefixName" | "nickname"
> => {
    const prefixName = getPatientNameFromArray(names?.usual?.prefix);
    const firstName = getPatientNameFromArray(names?.usual?.given);
    const familyName = getPatientNameFromArray(names?.usual?.family);
    const nickname = getPatientNameFromArray(names?.nickname?.given);

    return {
        prefixName,
        firstName,
        familyName,
        nickname,
    };
};

export const mapPatientDemographicsDateOfBirthInfoToPatientSummaryAgeParts = (
    dateOfBirth?: string | null,
): Pick<PatientSummary, "ageYear" | "dateOfBirth"> => {
    const dob = dateOfBirth ?? "";
    const patientAge = dob ? DateHelpers.getAgeFromDate(dob) : NaN;

    return {
        dateOfBirth: dob,
        ageYear: patientAge,
    };
};

export const mapPatientDemographicsToPatientSummary = ({
    patientDemographics, // patient data received via native bridge from EMR
    patientSummary, // patient data that already exist in a store
}: {
    patientDemographics?: PatientDemographics;
    patientSummary: Omit<PatientSummary, "externalIds"> & {
        externalIds?: PatientSummary["externalIds"];
    };
    patientExternalIds?: PatientExternalId[];
}): PatientSummary => {
    const { patientName, dateOfBirth, gender, patientExternalIdentityDto } =
        patientDemographics ?? {};

    // We would like to merge all known at that moment patient external ids
    // external ids from EMR should go first
    const externalIds = mergeExternalIds(
        patientExternalIdentityDto?.patientExternalIds ?? [], // from EMR
        patientSummary.externalIds ?? [],
    );

    const updatedPatient: PatientSummary = {
        patientId: patientSummary.patientId,
        demographicsLastRefreshed: patientSummary.demographicsLastRefreshed,
        ...mapPatientDemographicsNamesToPatientSummaryParts(patientName),
        ...mapPatientDemographicsDateOfBirthInfoToPatientSummaryAgeParts(
            dateOfBirth,
        ),
        externalIds,
        gender: gender ?? "Unknown",
    };

    return updatedPatient;
};
