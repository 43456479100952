import React from "react";

import { FloreyBuilderProvider } from "./FloreyBuilder.context";
import { FloreyBuilderContent } from "./FloreyBuilderContent";

export const FloreyBuilder = (): JSX.Element => {
    return (
        <FloreyBuilderProvider>
            <FloreyBuilderContent />
        </FloreyBuilderProvider>
    );
};

export default FloreyBuilder;
