import { Flex, Item } from "@accurx/design";

import {
    SkeletonBlock,
    SkeletonBody,
    SkeletonNote,
    SkeletonSubtitle,
} from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { Header } from "./Header.styles";

export default function HeaderSkeleton() {
    return (
        <Header>
            <Flex gap="1.5" justifyContent="space-between" flexWrap="wrap">
                <Item>
                    <SkeletonSubtitle charCount={28} />
                    <Flex gap="0.25 1.5" flexWrap="wrap">
                        <SkeletonBody charCount={10} />
                        <SkeletonBody charCount={8} />
                        <SkeletonBody charCount={20} />
                    </Flex>
                </Item>
                <Flex gap="1.5" flexWrap="wrap-reverse" alignItems="center">
                    <Flex alignItems="center">
                        <SkeletonNote charCount={24} />
                    </Flex>

                    <SkeletonBlock height="24px" width="152px" />
                </Flex>
            </Flex>
        </Header>
    );
}
