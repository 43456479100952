import { ButtonHTMLAttributes, forwardRef } from "react";

import * as UI from "@accurx/design";

import { BaseProps } from "../Base/Base";
import {
    StyledButton,
    StyledLoadingIcon,
    StyledWrapper,
} from "./Button.styles";

export type Ref = HTMLButtonElement;

export type ButtonProps = BaseProps &
    ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean };

/** A note on loading states:
 *
 * Passing isLoading=true will disable the button, maintain its width, and display a loading spinner in place of the children.
 *
 * An alternative option when using an Icon and text as children is to manage the loading state yourself, making use of <Pill.Icon>.
 *
 * Example:
 *
 * <Pill.Button>
 *     <Pill.Icon isLoading={true} />
 *     <Text>Click me</Text>
 * </Pill.Button>
 */
export const Button = forwardRef<Ref, ButtonProps>(
    (
        {
            radius = "regular",
            color = UI.Tokens.COLOURS.greyscale.white,
            dimension = "small",
            children,
            isLoading,
            ...otherProps
        },
        ref,
    ) => {
        return (
            <StyledButton
                $radius={radius}
                $color={color}
                $border={true}
                $dimension={dimension}
                ref={ref}
                disabled={isLoading}
                {...otherProps}
            >
                <StyledWrapper $isLoading={isLoading}>{children}</StyledWrapper>
                {isLoading && <StyledLoadingIcon />}
            </StyledButton>
        );
    },
);
