import { ReactNode, useEffect, useMemo, useState } from "react";

import * as UI from "@accurx/design";
import { Tabs } from "domains/inbox/components/Tabs";
import { useConversationAddNoteSelectAnalytics } from "domains/inbox/hooks/util";

import { MessageActionsBar } from "../MessageActionsBar/MessageActionsBar";
import { ReplyProps } from "./Reply.types";
import {
    StyledInnerContainer,
    StyledOuterContainer,
    StyledTabsBar,
} from "./ReplyContents.styles";

type ReplyTabsProps = Pick<
    ReplyProps,
    "selectedMessageAction" | "conversation"
> & {
    isComposing: boolean;
    isComposingNote: boolean;
    children: ReactNode;
};

const ReplyTabs = ({
    selectedMessageAction,
    isComposing,
    isComposingNote,
    conversation,
    children,
}: ReplyTabsProps) => {
    const addNoteSelectAnalytics =
        useConversationAddNoteSelectAnalytics(conversation);

    const [firstVisibleTab, setFirstVisibleTab] = useState<"note" | "reply">(
        selectedMessageAction,
    );
    const [isMinimised, setIsMinimised] = useState(false);
    const [selectedTab, setSelectedTab] = useState<"note" | "reply">(
        selectedMessageAction,
    );

    const getTabs = useMemo(() => {
        const tabs = [
            {
                tabId: "reply",
                text:
                    isComposing && selectedTab !== "reply"
                        ? "Continue reply"
                        : "Reply",
                icon: { name: "Reply" as UI.IconNameVariants },
            },
            {
                tabId: "note",
                text:
                    isComposingNote && selectedTab !== "note"
                        ? "Continue note"
                        : "Note",
                icon: { name: "Document" as UI.IconNameVariants },
            },
        ];

        return tabs;
    }, [selectedTab, isComposingNote, isComposing]);

    useEffect(() => {
        if (conversation.status === "Done") {
            // If a user is typing a reply, and another user marks the conversation
            // as done, we deliberately choose to close the reply in-progress and
            // force the user to re-open if they want to continue to send the message
            // Minimising means we keep any in-progress typing if the user chooses to reopen
            setIsMinimised(true);
        }
    }, [conversation.status]);

    if (isMinimised) {
        return (
            <MessageActionsBar
                isComposing={isComposing}
                isComposingNote={isComposingNote}
                conversation={conversation}
                toggleReply={() => {
                    setFirstVisibleTab("reply");
                    setSelectedTab("reply");
                    setIsMinimised(false);
                }}
                toggleNote={() => {
                    setSelectedTab("note");
                    setFirstVisibleTab("note");
                    setIsMinimised(false);

                    addNoteSelectAnalytics();
                }}
            />
        );
    }

    return (
        <StyledOuterContainer>
            <StyledInnerContainer>
                <UI.Tabs
                    defaultSelected={firstVisibleTab}
                    onTabChange={(tabId) => {
                        setSelectedTab(tabId as "note" | "reply");

                        if (tabId === "note") {
                            addNoteSelectAnalytics();
                        }
                    }}
                >
                    <StyledTabsBar>
                        <Tabs.Button.ListAndItems tabs={getTabs} />
                        <UI.Button
                            theme="secondary"
                            dimension="small"
                            aria-label="Minimise"
                            icon={{ name: "Minimise", title: "Minimise" }}
                            onClick={() => {
                                setIsMinimised(true);
                            }}
                        />
                    </StyledTabsBar>
                    {children}
                </UI.Tabs>
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};

const ReplyPanel = ({ children }: { children: ReactNode }) => {
    return <UI.TabPanel tabId="reply">{children}</UI.TabPanel>;
};

const NotesPanel = ({ children }: { children: ReactNode }) => {
    return <UI.TabPanel tabId="note">{children}</UI.TabPanel>;
};

ReplyTabs.ReplyPanel = ReplyPanel;
ReplyTabs.NotesPanel = NotesPanel;

export { ReplyTabs };
