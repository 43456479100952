import React from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { Feedback, Flex, Link, Text } from "@accurx/design";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { formatSearch } from "app/account/Account.helper";
import { ROUTES } from "shared/Routes";

import { SHOW_LOGIN_FORM_SEARCH_PARAM } from "../LogIn/LogIn.constants";
import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { useGetProductTypeFromUrl } from "../LogIn/hooks";

export const ResetPasswordConfirm = (): JSX.Element => {
    const history = useHistory();

    // Set default product in case none was present
    const product = useGetProductTypeFromUrl();
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("product", `${product}`);

    const searchPreLogin = formatSearch({
        search: searchParams.toString(),
    });

    if (product === RegisterAccountProductType.Fleming) {
        toast(
            <Feedback colour="success" title="Password updated">
                You can now log in with your password
            </Feedback>,
        );

        return (
            <Redirect
                to={`${ROUTES.login}${searchPreLogin}&${SHOW_LOGIN_FORM_SEARCH_PARAM}=true`}
            />
        );
    }

    return (
        <OuterStackPanel>
            <Flex flexDirection="column" alignItems="center" gap="3">
                <Text variant="title" as="h1" skinny>
                    You have reset your password
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    <>
                        <Text skinny>
                            You can now close this page, return to your desktop
                            and sign in to the Accurx Desktop toolbar.
                        </Text>
                        <Text>
                            {"Haven't installed the Accurx Desktop toolbar? "}
                            <Link href={ROUTES.downloadDesktop}>
                                <Link.Text text="Install now" />
                            </Link>
                        </Text>
                    </>
                </InnerStackPanel>
            </Flex>
        </OuterStackPanel>
    );
};
