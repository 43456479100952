import * as UI from "@accurx/design";

export const SearchErrorFeedback = ({ id }: { id: string }) => {
    return (
        <UI.Feedback colour="error" props={{ id, "aria-live": "polite" }}>
            <UI.Text skinny variant="label">
                Sorry, something went wrong while searching. Please try again.
            </UI.Text>
        </UI.Feedback>
    );
};
