import { useCallback } from "react";

export const useClinicianConversationsAnalytics = () => {
    const trackIntercomConversationsViewed = useCallback(() => {
        window.Intercom("trackEvent", "view-clinician-conversations-inbox");
    }, []);

    const trackIntercomConversationClicked = useCallback(() => {
        window.Intercom("trackEvent", "view-clinician-conversation");
    }, []);

    return {
        trackIntercomConversationsViewed,
        trackIntercomConversationClicked,
    };
};
