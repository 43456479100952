import {
    ConversationItem,
    VideoConsultLinkItem,
} from "@accurx/concierge/types";

export const getVideoConsultLinkNote = (
    conversationItems: ConversationItem[],
    itemId: ConversationItem["id"],
): VideoConsultLinkItem | undefined => {
    return conversationItems.find((item): item is VideoConsultLinkItem => {
        return (
            item.contentType === "VideoConsultLink" &&
            item.linkedItemId === itemId
        );
    });
};
