import { PracticeActivityResponse } from "@accurx/api/clinician-messaging";
import { useCurrentWorkspace } from "@accurx/auth";
import { Participant } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useWorkspaceActivityQuery } from "domains/inbox/hooks/queries";
import { useIsEllipsisActive } from "domains/inbox/hooks/util/useIsEllipsisActive";
import { getParticipantName } from "domains/inbox/util/getParticipantName";
import styled from "styled-components";

export const getWorkspaceActivity = (
    data: PracticeActivityResponse,
    within = 4,
) => {
    if (!data.isOpenToReplies) {
        return {
            isAvailable: false,
            activity: null,
        };
    }
    const days = data.practiceUserOnlineStatus
        .map(({ lastOnlineAt }) =>
            DateHelpers.getDaysFromEndOfToday(new Date(lastOnlineAt)),
        )
        .filter((daysAgo) => daysAgo <= within);

    if (!days.length) {
        return {
            isAvailable: true,
            activity: null,
        };
    }

    const mostRecentDay = Math.min(...days);
    return {
        isAvailable: true,
        activity: {
            daysSinceOnline: mostRecentDay,
            numberOfUsers: days.filter((day) => day === mostRecentDay).length,
        },
    };
};

export const getActivityText = (
    workspaceStatus?: ReturnType<typeof getWorkspaceActivity>,
) => {
    return workspaceStatus?.activity
        ? `${
              workspaceStatus.activity.numberOfUsers
          } staff online ${DateHelpers.getDaysAgoText(
              workspaceStatus.activity.daysSinceOnline,
          )}`
        : "Not online in the past 4 days";
};

export const TooltipContent = ({
    displayName,
    activity,
}: {
    displayName?: string;
    activity?: string;
}) => {
    return (
        <UI.Text skinny variant="note" colour="white">
            {displayName && `${displayName}`}
            {displayName && activity && <>&nbsp;&middot;&nbsp;</>}
            {activity}
        </UI.Text>
    );
};

export const TextOverflowWrapper = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const StyledActivityIndicator = styled.div<{
    $isActive: boolean;
}>`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: solid 1px #fff;
    background: ${({ $isActive }) =>
        $isActive
            ? UI.Tokens.COLOURS.primary.green[100]
            : UI.Tokens.COLOURS.greyscale.stone};
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const ActivityIndicator = ({ isActive }: { isActive: boolean }) => (
    <StyledActivityIndicator
        data-testid="activity-indicator"
        $isActive={isActive}
    />
);

type WorkspaceActivityTooltipProviderProps = {
    nameRef?: React.RefObject<HTMLElement>;
    participant: Participant | null;
    onTooltipOpen: (args: {
        workspaceStatus?: ReturnType<typeof getWorkspaceActivity>;
    }) => void;
    children: (arg: {
        workspaceStatus?: ReturnType<typeof getWorkspaceActivity>;
    }) => React.ReactNode;
};

export const WorkspaceActivityTooltipProvider = ({
    nameRef,
    participant,
    onTooltipOpen,
    children,
}: WorkspaceActivityTooltipProviderProps) => {
    const isEllipsisActive = useIsEllipsisActive(nameRef?.current ?? null);

    const currentWorkspace = useCurrentWorkspace();
    const { data } = useWorkspaceActivityQuery({
        currentWorkspaceId: currentWorkspace.orgId,
        workspaceIdToQuery:
            participant?.type === "Workspace"
                ? participant.workspaceId
                : undefined,
    });
    const workspaceStatus = data ? getWorkspaceActivity(data) : undefined;

    const displayName = getParticipantName(participant);
    const activityText = getActivityText(workspaceStatus);

    const onOpenChange = (isOpen: boolean) => {
        if (isOpen) {
            onTooltipOpen({ workspaceStatus });
        }
    };

    return (
        <WithTooltip
            isEnabled={!!workspaceStatus || isEllipsisActive}
            props={{
                root: { $withEllipsis: true, onOpenChange },
            }}
            content={
                <TooltipContent
                    displayName={isEllipsisActive ? displayName : undefined}
                    activity={workspaceStatus ? activityText : undefined}
                />
            }
        >
            {children({ workspaceStatus })}
        </WithTooltip>
    );
};
