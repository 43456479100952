import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useConversationUnreadCount } from "@accurx/concierge/hooks/data/useConversationUnreadCount";
import { Conversation, ConversationItem } from "@accurx/concierge/types";
import { useLayout } from "domains/inbox/components/Layout/context";
import {
    useCurrentRoute,
    useInboxLink,
    useViewportSize,
} from "domains/inbox/hooks/util";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";

import { StyledLink } from "../../ConversationPreview.styles";

export const ConversationPreviewLink = ({
    conversation,
    item,
    children,
}: {
    conversation: Conversation;
    item: ConversationItem;
    children: React.ReactNode;
}) => {
    const link = useInboxLink();

    const assignee = useAssignee(conversation.assignee);
    const currentRoute = useCurrentRoute();
    const size = useViewportSize();
    const { rightPane } = useLayout();

    const unreadCount = useConversationUnreadCount(conversation.id);
    const isUnread = unreadCount > 0;

    const { trackListItemClick } = useConversationListAnalytics();

    const handleOnClick = () => {
        trackListItemClick({
            assignee,
            isConversationUnread: isUnread,
            conversationServerId: getConversationServerId(conversation),
            conversationItem: item,
            conversationGroup: currentRoute?.name,
            conversationParticipant:
                getConversationParticipantForAnalytics(conversation),
        });

        if (size === "xs" || size === "sm") {
            rightPane.toggle();
        }
    };

    return (
        <StyledLink
            to={link.update({ conversationId: conversation.id })}
            onClick={handleOnClick}
        >
            {children}
        </StyledLink>
    );
};
