import { SnomedCodeFull as SnomedCodeDto } from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { SnomedCode } from "domains/compose/types";

const mapDtoToSnomedCode = (dto: SnomedCodeDto[]): SnomedCode[] => {
    const snomedCodes: SnomedCode[] = [];

    dto.forEach(({ conceptId, term }) => {
        if (!conceptId) {
            Log.error("Snomed Code received with no concept ID", {
                tags: { term },
            });
            return;
        }

        snomedCodes.push({
            id: conceptId,
            term,
        });
    });

    return snomedCodes;
};

export const useSnomedCodesQuery = (
    { workspaceId }: { workspaceId: number },
    options?: UseQueryOptions<SnomedCodeDto[], Error, SnomedCode[]>,
) => {
    return useQuery({
        queryKey: ["SnomedCodes", { workspaceId }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/BatchMessaging/${workspaceId}/SnomedCodes`,
                }),
            );

            return returnDataOrThrow<SnomedCodeDto[]>(response);
        },
        select: mapDtoToSnomedCode,
        ...options,
    });
};
