import { useCallback, useEffect } from "react";

import { TicketIdentity } from "@accurx/api/ticket";
import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { Log } from "@accurx/shared";
import { useTransport } from "@accurx/transport";

import {
    ThreadActiveInternalMs,
    ThreadActiveSend,
} from "shared/hubClient/payload.types";

const regex = /^t-(\d+)-(\w+)$/;
export const extractTicketIdentityFromConversationId = (
    conversationId: string,
): Required<TicketIdentity> | undefined => {
    const match = conversationId.match(regex);

    if (!match) {
        Log.warn(
            "Failed to send user currently viewing to signalr as unhandled conversation id",
            {
                tags: {
                    conversationId,
                },
            },
        );
        return;
    }

    return {
        type: parseInt(match[1], 10),
        id: match[2],
    };
};

export const useSendUserIsViewing = ({
    conversationId,
    patientId,
}: {
    conversationId: string;
    patientId: string | null;
}) => {
    const isCurrentlyViewingEnabled = useFeatureFlag(
        "UnifiedInboxCurrentlyViewing",
    );

    const workspace = useCurrentWorkspace();
    const { transport, connectionState } = useTransport();

    const sendThreadActive = useCallback(
        (payload: ThreadActiveSend) => {
            if (connectionState === "Connected") {
                void transport?.send({
                    methodName: "SendThreadActive",
                    payload,
                });
            }
        },
        [transport, connectionState],
    );

    const sendIsViewing = useCallback((): void => {
        const ticketIdentity =
            extractTicketIdentityFromConversationId(conversationId);

        if (ticketIdentity) {
            sendThreadActive({
                organisationId: workspace.orgId,
                ticketIdentity: ticketIdentity,
                patient: {
                    accuRxId: patientId,
                },
            });
        }
    }, [conversationId, sendThreadActive, patientId, workspace.orgId]);

    const sendNotViewing = useCallback((): void => {
        sendThreadActive({
            organisationId: workspace.orgId,
            ticketIdentity: null, // <- Only different between the sendNotViewing & sendUserIsViewing objects
            patient: {
                accuRxId: patientId,
            },
        });
    }, [sendThreadActive, patientId, workspace.orgId]);

    /**
     * Using a similar pattern to the userIsTyping functionality.
     * When a conversation is being viewed we push the SendThreadActive event every X secs (ViewingSendInternalMs).
     * On the receiving end we check for this event if it is still polling we know the user is still viewing.
     */
    useEffect(() => {
        if (!isCurrentlyViewingEnabled) return;
        // First viewing event is sent on load
        sendIsViewing();

        // We set up a interval for ping the server letting other users know this user is still actively looking at a conversation
        const sendingUserViewingInternal = setInterval(() => {
            // We only send this ping if the document is visible
            if (document.visibilityState === "visible") {
                sendIsViewing();
            }
        }, ThreadActiveInternalMs);

        return () => {
            // Clean up the interval and send one final event letting other users know this user is no longer viewing a conversation
            clearInterval(sendingUserViewingInternal);
            sendNotViewing();
        };
    }, [sendIsViewing, sendNotViewing, isCurrentlyViewingEnabled]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === "hidden") {
            sendNotViewing();
        } else {
            sendIsViewing();
        }
    }, [sendIsViewing, sendNotViewing]);

    useEffect(() => {
        if (!isCurrentlyViewingEnabled) return;

        // Here we push the viewing/not viewing events as soon as a viewer leaves/returns to the current page. This improves the experience if we solely relied on the interval the event would not fire for up to X (ThreadActiveInternalMs) seconds.
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [handleVisibilityChange, isCurrentlyViewingEnabled]);
};
