import { FormEvent, useEffect, useMemo, useState } from "react";

import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
} from "@accurx/auth";
import {
    Feedback,
    Flex,
    FormFieldWrapper,
    GroupedOption,
    Icon,
    Option,
    SearchSelect,
    Spinner,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { IAllowedOrganisationResponse } from "api/FlemingDtos";
import { useGetAllowedOrganisationsQuery } from "app/hooks/queries";
import { TermsAndConditionsCheckbox } from "app/sharedComponents/termsAndConditionsCheckbox/TermsAndConditionsCheckbox";
import { useJoinOrganisation } from "app/workspace/hooks/useJoinOrganisation";
import { getTermsAndConditionsError } from "shared/Form.helper";
import { ROUTES } from "shared/Routes";
import { getNextUrlOrHomepage } from "shared/RoutesHelper";
import { useAppSelector } from "store/hooks";

import { useIsClinicianConversationOnboarding } from "./hooks";
import { CardFooter, OnboardingCard } from "./layout-components";

// Order options so that Suggested group is before Other in dropdown
const formatSelectOptions = (
    allFilteredOrgs: IAllowedOrganisationResponse[],
): Option[] => {
    const formattedOptions = allFilteredOrgs.map((org) => ({
        label: org.organisationName,
        value: org.nationalCode,
        grouping: org.isSuggested ? "Suggested" : "Other",
    }));
    const suggestedOption = formattedOptions.find(
        (x) => x.grouping === "Suggested",
    );
    if (suggestedOption) {
        // Grouping names will be ordered by the order they are in the array, so we want to put a "Suggested" org at the top
        formattedOptions.splice(formattedOptions.indexOf(suggestedOption), 1);
        formattedOptions.unshift(suggestedOption);
    }
    return formattedOptions;
};

const formatInitSelectOption = (
    allFilteredOrgs: IAllowedOrganisationResponse[],
): Option | undefined => {
    // If there is only 1 org, return it as the default selected in dropdown, otherwise return the suggested org
    const selectedOrg =
        allFilteredOrgs.length > 1
            ? allFilteredOrgs.find((x) => x.isSuggested)
            : allFilteredOrgs[0];
    return selectedOrg
        ? {
              label: selectedOrg.organisationName,
              value: selectedOrg.nationalCode,
          }
        : undefined;
};

export const JoinOrganisation = () => {
    const history = useHistory();
    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const { isLoading: isLoadingJoinOrganisation, mutate } =
        useJoinOrganisation();

    const track = useAnalytics();

    // Store
    const user = useAppSelector(({ account }) => account.user, shallowEqual);

    const isOnboarding = Boolean(user?.organisations.length);

    const needsToAcceptTermsOfService =
        !user?.onboarding?.hasAcceptedTermsService;

    // State
    const [selectedOption, setSelectedOption] = useState<Option>({
        label: "",
        value: "",
    });
    const [selectErrors, setSelectErrors] = useState("");

    const {
        data: filteredOrganisations = [],
        isLoading: isLoadingAllowedOrganisations,
    } = useGetAllowedOrganisationsQuery();

    const isLoading =
        isLoadingAllowedOrganisations || isLoadingJoinOrganisation;

    const filteredOrganisationOptions = useMemo(
        () => formatSelectOptions(filteredOrganisations),
        [filteredOrganisations],
    );

    const initialOrganisationSelection = useMemo(
        () => formatInitSelectOption(filteredOrganisations),
        [filteredOrganisations],
    );

    useEffect(() => {
        if (initialOrganisationSelection) {
            setSelectedOption(initialOrganisationSelection);
        }
    }, [initialOrganisationSelection]);

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsAcceptedErrorMessage, setTermsAcceptedErrorMessage] =
        useState("");

    const handlePostUserProfile = (
        selectedOrganisation: IAllowedOrganisationResponse,
    ) => {
        const hasUsedPreselectedOrg = initialOrganisationSelection
            ? initialOrganisationSelection.value === selectedOption.value
            : undefined;

        mutate(
            {
                // Only set to true when terms of service text is shown, otherwise undefined
                hasAcceptedTermsService: needsToAcceptTermsOfService
                    ? true
                    : undefined,
                nationalCode: selectedOrganisation.nationalCode,
            },
            {
                onSuccess: (data) => {
                    track("AccountOrganisationSelect Button Click", {
                        onboarding: isOnboarding,
                        hasUsedPreselectedOrg,
                        pageOrigin: "Organisation",
                        organisationActionSelected: "Join",
                    });

                    const isMultipleWorkspacesSupported =
                        isFeatureEnabledForWorkspace(
                            FeatureName.FlexibleWorkspace,
                            data.organisations.find(
                                ({ orgId }) => orgId === data.organisationId,
                            ) as Organisation,
                        );

                    if (isMultipleWorkspacesSupported) {
                        history.replace({
                            pathname: ROUTES.joinWorkspace,
                            search: history.location.search,
                        });
                    } else {
                        toast(
                            <Feedback
                                colour="success"
                                title={`Joined ${data.organisationName}`}
                            />,
                        );
                        history.replace(
                            getNextUrlOrHomepage(history.location.search),
                        );
                    }
                },
                onError: () => {
                    toast(
                        <Feedback
                            colour="error"
                            title="Failed to update account"
                        >
                            Please refresh page and try again
                        </Feedback>,
                    );
                },
            },
        );
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        setSelectErrors("");
        setTermsAcceptedErrorMessage("");
        let hasErrors = false;

        const selectedOrganisation = filteredOrganisations.find(
            ({ nationalCode }) => nationalCode === selectedOption.value,
        );

        if (!selectedOption.value) {
            setSelectErrors("Please select an organisation");
            hasErrors = true;
        } else if (!selectedOrganisation) {
            // Should realistically never happen
            Log.error(
                "[JoinOrganisation] The selected option doesn't match any of the allowed organisations",
            );
            setSelectErrors(
                "The selected organisation is invalid, please refresh and try again",
            );
            hasErrors = true;
        }

        if (needsToAcceptTermsOfService) {
            const errorMessage = getTermsAndConditionsError(termsAccepted);
            if (!!errorMessage) {
                setTermsAcceptedErrorMessage(errorMessage);
                hasErrors = true;
            }
        }

        if (!hasErrors && selectedOrganisation) {
            handlePostUserProfile(selectedOrganisation);
        }
    };

    const handleSelectOrg = (selected: Option | Option[]) => {
        setSelectedOption(selected as Option);
    };

    const handleAddOrganisation = () => {
        history.push({
            pathname: ROUTES.addOrganisation,
            search: history.location.search,
            state: { showBackButton: true },
        });
    };

    const EmptyItem: JSX.Element = (
        <div
            style={{ display: "flex" }}
            onClick={handleAddOrganisation}
            role="option"
            aria-selected={false}
        >
            <Icon name="Plus" size={3} colour="blue" />
            <Text colour="blue" skinny>
                Add your organisation
            </Text>
        </div>
    );

    const EmptyGroup: GroupedOption = {
        heading: "Not found",
        options: [
            {
                labelElement: EmptyItem,
                value: "", // This should be "" to make not selected on click
            },
        ],
    };

    return (
        <form onSubmit={handleSubmit}>
            <OnboardingCard
                header={isBlurryChatLayout ? "Join an organisation" : undefined}
            >
                <Flex flexDirection="column" gap="2">
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <FormFieldWrapper
                                labelProps={{ htmlFor: "select-org" }}
                                errors={[selectErrors]}
                            >
                                <SearchSelect
                                    id="select-org"
                                    options={filteredOrganisationOptions}
                                    initSelectedValues={
                                        initialOrganisationSelection
                                    }
                                    defaultCopy={{
                                        placeholder: "-- Select --",
                                    }}
                                    onChangeHandler={handleSelectOrg}
                                    emptyGroup={EmptyGroup}
                                    zIndex={3}
                                    disabled={isLoadingAllowedOrganisations}
                                />
                            </FormFieldWrapper>
                            {needsToAcceptTermsOfService && (
                                <FormFieldWrapper
                                    errors={
                                        termsAcceptedErrorMessage
                                            ? [termsAcceptedErrorMessage]
                                            : []
                                    }
                                >
                                    <TermsAndConditionsCheckbox
                                        checked={termsAccepted}
                                        onCheckChange={setTermsAccepted}
                                    />
                                </FormFieldWrapper>
                            )}
                            <CardFooter isLoading={isLoadingJoinOrganisation} />
                        </>
                    )}
                </Flex>
            </OnboardingCard>
        </form>
    );
};
