const REGEX_ALPHANUMERIC = /[^A-Za-z0-9]/;

export const validateSenderId = (senderId: string): string => {
    if (!senderId) {
        return "This field is required.";
    }
    if (senderId.length < 3 || senderId.length > 11) {
        return "This field must be between 3 and 11 characters.";
    }
    if (REGEX_ALPHANUMERIC.test(senderId)) {
        return "These characters should be letters or numbers with no spaces.";
    }
    return "";
};
