import { VaccineProductType } from "../Vaccine.type";

export enum WeekView {
    upTo3Weeks,
    pastSlots,
    moreThan3Weeks,
}

export interface VaccineDeliverySiteStats {
    locationText: string;
    upTo3Weeks: VaccineCapacityDetails;
    pastSlots: VaccineCapacityDetails;
    moreThan3Weeks: VaccineCapacityDetails;
}

export interface VaccineCapacityDetails {
    booked: number;
    total: number;
}

export interface VaccineDeliverySites {
    sites: VaccineDeliverySiteStats[];
}

export interface VaccineCapacityTypes {
    capacities: VaccineCapacityTypeStats[];
}

export interface VaccineCapacityTypeStats {
    availableFirst: number;
    availableSecond: number;
    availableBoth: number;
    availableBooster: number;
    availableAll: number;
    vaccineType: VaccineProductType;
}
