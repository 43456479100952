import { format, isThisYear, isToday, isTomorrow } from "date-fns";

import { ScheduleSendAt } from "../types/ScheduleSend.types";

export const formatScheduledButtonText = (
    sendAt: ScheduleSendAt | null,
): string => {
    if (!sendAt) {
        return `Send`;
    }

    const scheduledDate = new Date(sendAt.sendAtDateTime);

    // If date is today it returns `Send today at [Time]` i.e. Send today at 1pm
    // There is a edge case whereby a user can select today at 9am and leave their computer come back after that time and click send. isToday uses Date.now under the hood so doesn't work for past dates hence using type prop is used to checked the date is today
    if (
        isToday(scheduledDate) ||
        sendAt.type === "todayEvening" ||
        sendAt.type === "todayMorning"
    ) {
        return `Send today at ${format(scheduledDate, "haaa")}`;
    }

    // If date is tomorrow it returns `Send tomorrow at [Time]` i.e. Send tomorrow at 1pm
    if (isTomorrow(scheduledDate)) {
        return `Send tomorrow at ${format(scheduledDate, "haaa")}`;
    }

    // If date is within this year it returns `Send [Weekday] [Day of the Month] [Month] at [Time]` i.e. Send Mon 6 May at 1pm
    if (isThisYear(scheduledDate)) {
        return `Send ${format(scheduledDate, "E d MMM")} at ${format(
            scheduledDate,
            "haaa",
        )}`;
    }

    // If date is next year it returns `Send [Weekday] [Day of the Month] [Month] [Year] at [Time]` i.e. Send Mon 6 May 2025 at 1pm
    return `Send ${format(scheduledDate, "E d MMM y")} at ${format(
        scheduledDate,
        "haaa",
    )}`;
};
