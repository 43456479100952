import * as UI from "@accurx/design";
import { Input } from "@accurx/inbox-design-library";

/** The input component from the design with some defaults to
 * make live search accessible.
 */
export const LiveSearchInput = (
    props: React.ComponentProps<typeof UI.Input>,
) => {
    return (
        <>
            {/* This is to let screen reader users know this is a live search */}
            <UI.VisuallyHidden id="search-description">
                Results will update as you type.
            </UI.VisuallyHidden>
            <Input
                isSearchInput
                aria-describedby="search-description"
                {...props}
            />
        </>
    );
};
