import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPatientCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3px;
`;

export const StyledPatientTitle = styled.span`
    font-weight: bold;
    font-size: ${UI.Tokens.SIZES[2]};
    margin-bottom: ${UI.Tokens.SIZES[0.25]};
    line-height: ${UI.Tokens.SIZES[3]};
    color: ${UI.Tokens.COLOURS.greyscale.night};
`;

export const StyledPatientInfo = styled.span`
    color: ${UI.Tokens.COLOURS.greyscale.zinc};
    font-size: ${UI.Tokens.SIZES[2]};
    line-height: ${UI.Tokens.SIZES[3]};
`;
