import * as UI from "@accurx/design";
import { CONVERSATION_PREVIEW_HEIGHT } from "domains/inbox/components/ConversationPreview/ConversationPreview.styles";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import styled from "styled-components";

const StyledFlexContainer = styled(UI.Flex)`
    height: ${CONVERSATION_PREVIEW_HEIGHT - 2}px;
    padding: ${UI.Tokens.SIZES[1.5]};
`;

export const LoadingCard = () => {
    return (
        <UI.Card spacing={0} isElevated={false}>
            <StyledFlexContainer
                gap="1.5"
                flexDirection="column"
                justifyContent="space-between"
            >
                <UI.Item>
                    <UI.Flex gap="2">
                        <SkeletonLoader height="12px" width="100%" />
                        <SkeletonLoader height="12px" width="20%" />
                    </UI.Flex>
                </UI.Item>
                <UI.Item flex="1">
                    <UI.Flex gap="4" flexDirection="column">
                        <SkeletonLoader height="12px" width="63%" />
                        <SkeletonLoader height="12px" width="30%" />
                    </UI.Flex>
                </UI.Item>
            </StyledFlexContainer>
        </UI.Card>
    );
};
