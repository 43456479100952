import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import {
    isPharmacy,
    isUserCreatedWorkspace,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";

export const UnapprovedUsersBadge = () => {
    const currentWorkspace = useCurrentWorkspace();
    const isPendingRequestsBadgeEnabled = Boolean(
        currentWorkspace.settings.isAdminUser &&
            (isPharmacy(currentWorkspace) ||
                isUserCreatedWorkspace(currentWorkspace)),
    );

    const { data: { unapprovedUsers = [] } = {} } = useWorkspaceUsersQuery(
        { workspaceId: Number(currentWorkspace.orgId) },
        {
            enabled: isPendingRequestsBadgeEnabled,
        },
    );

    const showPendingRequestsBadge =
        isPendingRequestsBadgeEnabled && unapprovedUsers.length > 0;

    if (showPendingRequestsBadge === false) return null;

    return (
        <Ds.Badge radius="round" color="greyscale">
            {unapprovedUsers.length} to approve
        </Ds.Badge>
    );
};
