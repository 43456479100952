import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useDeleteAttachmentMutation } from "@accurx/concierge/hooks/mutations/useDeleteAttachmentMutation";
import {
    Attachment,
    AttachmentEvent,
    Conversation,
    ConversationItem,
    PatientSummary,
} from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";
import { toast } from "react-toastify";

import { DeleteConfirmModal } from "../DeleteConfirmModal/DeleteConfirmModal";
import { ItemAttachmentsList } from "./ItemAttachments.styles";
import { DeletedAttachment } from "./components/DeletedAttachment";
import { ItemAttachment } from "./components/ItemAttachment";
import { SkeletonAttachment } from "./components/SkeletonAttachment";

type ItemAttachmentsProps = {
    attachments: Attachment[];
    itemId: ConversationItem["id"];
    conversationId: Conversation["id"];
    patientId: PatientSummary["patientId"] | undefined;
    getAttachmentUrl: (args: { attachment: Attachment }) => string;
    onItemPreview?: () => void;
    onItemDeleteClick?: () => void;
    onItemDeleteConfirm?: (args: { reason: string }) => void;
};

const getDeleteEvent = (
    attachment: Attachment,
): AttachmentEvent | undefined => {
    return attachment.event?.isSoftDelete ? attachment.event : undefined;
};

export const ItemAttachments = ({
    attachments,
    itemId,
    conversationId,
    patientId,
    getAttachmentUrl,
    onItemPreview,
    onItemDeleteClick,
    onItemDeleteConfirm,
}: ItemAttachmentsProps) => {
    const track = useAnalytics();
    const env = useBrowserEnvironment();
    const { system: medicalRecordSystem, userMedicalRecordRole } =
        useMedicalRecordConnection();
    const accessType = env === "WebView" ? "DesktopApp" : "Browser";

    const [confirmationState, setConfirmationState] = useState<{
        attachmentId: string;
        reason: string;
    }>();
    const [currentlyDeleting, setCurrentlyDeleting] = useState<
        Record<string, boolean>
    >({});

    const { mutate: deleteAttachment } = useDeleteAttachmentMutation({
        onMutate: (variables) => {
            setCurrentlyDeleting((state) => ({
                ...state,
                [variables.attachmentId]: true,
            }));
        },
        onSettled: (_data, _error, variables) => {
            setCurrentlyDeleting((state) => ({
                ...state,
                [variables.attachmentId]: false,
            }));
        },
        onError: () => {
            toast(
                <UI.Feedback colour="error" title="Action failed">
                    <UI.Text as="span">
                        Sorry, we couldn't delete the attachment
                    </UI.Text>
                </UI.Feedback>,
            );
        },
    });

    return (
        <>
            <ItemAttachmentsList aria-label="Attachments">
                {attachments.map((attachment, index) => {
                    const deleteEvent = getDeleteEvent(attachment);

                    if (currentlyDeleting[attachment.id]) {
                        return <SkeletonAttachment key={attachment.id} />;
                    }

                    if (deleteEvent) {
                        return (
                            <DeletedAttachment
                                key={attachment.id}
                                deleteEvent={deleteEvent}
                            />
                        );
                    }

                    return (
                        <ItemAttachment
                            key={attachment.id}
                            fileName={
                                attachment.displayName ??
                                `Attachment ${index + 1}`
                            }
                            href={getAttachmentUrl({ attachment })}
                            allowDelete
                            onPreview={onItemPreview}
                            onDeleteClick={onItemDeleteClick}
                            onDeleteConfirm={({ reason }) => {
                                onItemDeleteConfirm?.({ reason });
                                setConfirmationState({
                                    reason,
                                    attachmentId: attachment.id,
                                });
                            }}
                        />
                    );
                })}
            </ItemAttachmentsList>
            <DeleteConfirmModal
                title="Are you sure you want to delete this attachment?"
                isOpen={!!confirmationState}
                onConfirm={() => {
                    // If the modal is confirming then we should definitely
                    // already have an attachment ID and reason but TypeScript
                    // can't know that the modal is only open if that's the
                    // case. So we have to have a safety check here to make
                    // TypeScript happy but this code path is unreachable.
                    if (!confirmationState) return;

                    track("ConversationAttachmentRemoveConfirm Button Click", {
                        accessType,
                        medicalRecordSystem,
                        userMedicalRecordRole,
                    });
                    deleteAttachment({
                        attachmentId: confirmationState.attachmentId,
                        reasonForDeletion: confirmationState.reason,
                        itemId,
                        conversationId,
                        patientId,
                    });
                    setConfirmationState(undefined);
                }}
                onClose={() => setConfirmationState(undefined)}
            />
        </>
    );
};
