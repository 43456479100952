import { useConciergeSelector } from "domains/concierge/internal/context";

export const useWithCareProvidersUserUnreadCount = (): number => {
    return useConciergeSelector((state) => {
        const counts = state.conversations.unreadCounts.clinicianMessaging;

        if (counts.queryStatus !== "success") return 0;

        return counts.user;
    });
};
