import * as UI from "@accurx/design";
import { Link } from "domains/inbox/components/Link/Link";
import { ItemState } from "domains/inbox/hooks/util/useUpdatingFeed";
import styled, { css, keyframes } from "styled-components";

export const CONVERSATION_PREVIEW_HEIGHT = 112;
export const EXTENDED_CONVERSATION_PREVIEW_HEIGHT = 126;

const entering = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const leaving = keyframes`
    20% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-20%);
    }
`;

export const StyledActionsContainer = styled(UI.Item)<{
    $isOpen: boolean;
}>`
    opacity: ${({ $isOpen }) => ($isOpen ? "100%" : "0")};
    width: ${({ $isOpen }) => ($isOpen ? "24px" : "0")};
    height: 24px;

    z-index: 1;

    // Always display on touch devices
    @media (hover: none) {
        opacity: 100%;
        width: 24px;
    }
`;

export const StyledContainer = styled.div<{
    $state: ItemState;
    $isActive: boolean;
    $height: number;
}>`
    width: 100%;

    @media (prefers-reduced-motion: no-preference) {
        ${({ $state }) =>
            $state === "entering" &&
            css`
                animation: ${entering} 0.3s ease forwards;
            `}

        ${({ $state }) =>
            $state === "leaving" &&
            css`
                animation: ${leaving} 0.3s ease-out forwards;
                animation-delay: 0.3s;
            `}
    }

    .conversation-item {
        position: relative;
        height: ${({ $height }) => $height}px;

        &:hover {
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};

            .text-item,
            .preview-summary * {
                color: ${UI.Tokens.COLOURS.primary.blue[100]};
            }
        }

        &:focus-within:not(:has(button:focus)) {
            outline: solid 3px ${UI.Tokens.COLOURS.primary.blue[50]};
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }
        }

        @supports not selector(:has(*)) {
            /* Fallback for when :has() is unsupported */

            &:focus-within {
                outline: solid 3px ${UI.Tokens.COLOURS.primary.blue[50]};
                border-radius: ${UI.Tokens.SIZES[0.5]};
                border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
                background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

                .conversation-preview-badge {
                    background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
                }
            }
        }

        &:focus:not(:hover),
        &:active {
            .text-item,
            .preview-summary * {
                color: ${UI.Tokens.COLOURS.primary.blue[130]};
            }
        }

        &:active {
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }
        }

        ${({ $isActive }) =>
            $isActive &&
            `
            border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
            background-color: ${UI.Tokens.COLOURS.primary.blue[10]};


            .conversation-preview-badge {
                background-color: ${UI.Tokens.COLOURS.primary.blue[25]};
            }

            .text-item,
            .preview-summary *  {
                color: ${UI.Tokens.COLOURS.primary.blue[130]};
            }
        `}
    }
`;

export const StyledLink = styled(Link)`
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &:hover
        ${StyledActionsContainer},
        &:focus-within
        ${StyledActionsContainer} {
        opacity: 100%;
        width: 24px;
    }
`;

export const StyledHeader = styled(UI.Flex)`
    white-space: nowrap;
`;

export const StyledFooter = styled(UI.Flex)`
    white-space: nowrap;

    margin-top: ${UI.Tokens.SIZES[1]};
`;

export const StyledBadgesGroup = styled(UI.Flex)`
    overflow: hidden;
`;
