import * as UI from "@accurx/design";
// eslint-disable-next-line no-restricted-imports
import { SkeletonLoaderProps } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";

import { BaseProps } from "../Base/Base";
import { StyledContainer, StyledSkeletonLoader } from "./Loading.styles";

export type LoadingProps = {
    width?: string;
    theme?: SkeletonLoaderProps;
} & Pick<BaseProps, "radius">;

export const Loading = ({
    width = "64px",
    radius = "regular",
}: LoadingProps) => {
    return (
        <StyledContainer
            $radius={radius}
            $color=""
            $border={true}
            $width={width}
        >
            <StyledSkeletonLoader height={UI.Tokens.SIZES[1.5]} width="100%" />
        </StyledContainer>
    );
};
