import { FirstVaccinationWeekFilters } from "../vaccineAllPatientsInvited/VaccineAllPatientsInvited.helper";

export interface VaccineDeliveryCollection {
    summaries: VaccineDeliverySummary[];
}

export interface VaccineDeliverySummary {
    id: string;
    status: string;
    sendTime: string;
    statusCounts: VaccineDeliveryStatusCount[];
}

export interface VaccineDeliveryStatusCount {
    inviteStatus: string;
    count: number;
}

export interface VaccineDeliveryUser {
    id: string;
    fullName: string;
    email: string;
}

export enum VaccineCourse {
    None = "None", // initial value
    Primary = "Primary",
    Booster = "Booster",
    BoosterSpring2022 = "BoosterSpring2022",
    BoosterAutumn2022 = "BoosterAutumn2022",
    BoosterSpring2023 = "BoosterSpring2023",
    BoosterAutumn2023 = "BoosterAutumn2023",
    BoosterSpring2024 = "BoosterSpring2024",
    BoosterAutumn2024 = "BoosterAutumn2024",
}

export interface VaccineInviteActionData {
    invites: VaccinePatientInvited[];
    action: VaccineInviteAction;
}

export enum VaccineInviteAction {
    None,
    Reminder,
    InviteToSecondBooking,
    InviteToBoosterBooking,
    CancelAppointment,
}

export enum StatusType {
    ToBook,
    Other,
    All,
}

export interface FirstVaccinationFilterChoice {
    id: FirstVaccinationWeekFilters;
    displayText: string;
    minDays: number | undefined; // inclusive in the backend
    maxDays: number | undefined; // exclusive in the backend
}

export interface SecondVaccinationFilterChoice {
    key: string;
    displayText: string;
    minDaysInclusive: number | undefined;
    maxDaysExclusive: number | undefined;
}

export interface VaccineAllInvitesFiltersData {
    searchTerm: string;
    statusFilters: string[];
    myPractice: boolean;
    vaccineTypeFilters: VaccineTypeFilters;
    firstVaccinationTimeFilter: FirstVaccinationFilterChoice | null;
    secondVaccinationTimeFilter: SecondVaccinationFilterChoice | null;
    unclearStatus: boolean;
}

export interface VaccineDeliveryDetails extends VaccineDeliverySummary {
    invitedBy: InvitedByPractice;
    items: VaccineDeliveryItem[];
}

export interface VaccineAllPatientsInvitedDetails {
    summaries: VaccinePatientInvited[];
    isLimited: boolean;
}

export interface PatientExternalId {
    type: string;
    value: string;
}

export enum FilterTermType {
    NhsNumber = "NhsNumber",
    PhoneNumber = "PhoneNumber",
    Name = "Name",
}

export interface FilterTerm {
    type: FilterTermType;
    value: string;
}

export enum CourseSearchTerm {
    Primary = "covidprimary",
    Booster = "covidbooster",
    BoosterSpring2022 = "covidboosterspring2022",
    BoosterAutumn2022 = "covidboosterautumn2022",
    BoosterSpring2023 = "covidboosterspring2023",
    BoosterAutumn2023 = "covidboosterautumn2023",
    BoosterSpring2024 = "covidboosterspring2024",
    BoosterAutumn2024 = "covidboosterautumn2024",
}

// See PatientInviteSearchFilters in backend Recall DTOs
export interface PatientInviteSearchFilters {
    course: CourseSearchTerm;
    showAllLinkedOrgs: boolean;
    searchTerm: string | null;
    statusFilters: string[] | null;
    daysSinceLastVaccination: DaysSinceLastVaccination | null; // supersedes daysSinceFirstVaccination and monthsSincePrimaryCourseCompleted
    // If making the call from older clients, vaccineTypeFilters would be null and we should assume there is no filtering based on vaccine type
    vaccineTypeFilters: VaccineTypeFilters;
    withConflicts: boolean | null; // will be null from older clients (i.e. no filtering on conflicts)
    order: string;
    limit: number | null;
    filterTerms: FilterTerm[] | null;
}

export enum vaccineTypeStrings {
    Pfizer = "Pfizer",
    AstraZeneca = "AstraZeneca",
    Moderna = "Moderna",
    Janssen = "Janssen",
    Medicago = "Medicago",
    Novavax = "Novavax",
    Valneva = "Valneva",
}

export interface DaysSinceFirstVaccination {
    min: number | undefined;
    max: number | undefined;
}

export interface DaysSinceLastVaccination {
    min: number | undefined; // inclusive
    max: number | undefined; // exclusive
}

export interface TimeSinceVaccination {
    days: number;
    months: number;
}

export interface VaccineTypeFilters {
    astrazeneca: boolean;
    janssen: boolean;
    moderna: boolean;
    pfizer: boolean;
}

export interface Patient {
    patientExternalIdentity: {
        patientExternalIds: PatientExternalId[];
    };
}

export interface VaccineDeliveryItem extends Patient {
    isTextable: boolean;
    contactNumber: string | null;
    patientName: string | null;
    dateOfBirth: string;
    inviteId: string;
    inviteStatus: string;
    jabState: string | null; // Will be of type VaccineExistingPatientState
    inviteStage: string; // Will be of type VaccineInviteStageState
    sendMessages: boolean; // isTextable was just for 1st jabs so this is a more accurate "is there a mobile no?" field
    daysSinceFirstVaccination: number | null; // null if not had first jab
    timeSinceLastVaccination: TimeSinceVaccination | null; // null if no jabs, otherwise will have info from the last jab, regardless of whether 1st, 2nd, booster
    vaccineProduct: string | null; // null if not had first jab
}

// See JabStates in backend Recall DTOs
export const VaccineExistingPatientState = {
    Unknown: "UNKNOWN",
    NoJabs: "NO_JABS",
    HadFirstJab: "HAD_FIRST_JAB",
    HadSecondJab: "HAD_SECOND_JAB",
    HadBoosterJabs: "HAD_BOOSTER_JAB",
};

// See InviteStageState in backend Recall DTOs
export const VaccineInviteStageState = {
    None: "NONE",
    First: "FIRST",
    Second: "SECOND",
    Booster: "BOOSTER",
};

export type VaccineDoseType = "First" | "Second" | "Booster";

export type VaccineProductType =
    | "Pfizer"
    | "Janssen"
    | "AstraZeneca"
    | "Moderna"
    | "PfizerPaediatric"
    | "Sanofi";

export interface VaccinePatientInvited extends Patient {
    contactNumber: string | null;
    patientName: string | null;
    dateOfBirth: string;
    currentInviteStatus: string;
    inviteId: string;
    inviteBatchId: string;
    invitedBy: VaccineOrg;
    inviteCreatedAt: string;
    sendMessages: boolean;
    hasNimsConflict: boolean;
    hasNbsConflict: boolean;
    vaccineName: string | null;
    lastVaccineDate: string | null;
}

export interface VaccineOrg {
    nationalCode: string;
    name: string;
}

export const VaccineDeliveryStatus = {
    Created: "Created",
    Sent: "Sent",
};

export interface InvitedByPractice {
    nationalCode: string;
    name: string;
}

export interface NbsBooking {
    doseDate: string;
    vaccineDose: VaccineDoseType;
    vaccineProduct: VaccineProductType;
    lastSuccessfulFetchTime: string;
}

export interface NbsData {
    bookings: NbsBooking[];
}

export interface RecallCapacityRequest {
    isSecondBooking: boolean;
    isBoosterBooking: boolean;
    isReminder: boolean;
    invites: RecallInviteId[];
}

export interface RecallInviteId {
    id: string;
}

/**
 * Provides details on the capacity check done so that the UI can show the results.
 * - If both start and end are null, there was no capacity check needed (disabled / invites not set to send messages)
 * - If only end is set, represents a check for upcoming capacity
 * - If both start and end are set, represents a check for a capacity check in a strict range from first doses
 * @see RecallCanSendMessagesResponse in backend Recall DTOs
 */
export interface RecallCapacityResponse {
    canSendMessages: boolean;
    skippedChecks: boolean;
    capacityStart: string | null;
    capacityEnd: string | null;
    error: string;
}
