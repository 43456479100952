import { Tokens } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES } = Tokens;

export const StyledTableHeaderButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: ${SIZES[3]};
    background-color: transparent;
    border: none;

    & {
        color: ${COLOURS.greyscale.stone};
    }

    &:hover,
    &:focus {
        color: ${COLOURS.greyscale.metal};
    }
`;

export const StyledTooltipWrapper = styled.div`
    position: absolute;
    top: ${SIZES[3]};
    left: ${SIZES[1]};

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        top: ${SIZES[1.5]};
    }
`;

export const StyledTooltipContentWrapper = styled.div`
    padding: ${SIZES[2]};
    max-width: 15rem;
    position: relative;
`;
