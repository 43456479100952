import { FormEvent, useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Button, Feedback, Flex, FormFieldWrapper, Text } from "@accurx/design";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
    POST_USER_PROFILE_FAILURE,
    POST_USER_PROFILE_STARTED,
    POST_USER_PROFILE_SUCCESS,
} from "app/account/AccountActions";
import { FlemingAnalyticsTracker } from "app/analytics";
import { useUpdateUserHealthcareProfileMutation } from "app/hooks/mutations";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { TermsAndConditionsCheckbox } from "app/sharedComponents/termsAndConditionsCheckbox/TermsAndConditionsCheckbox";
import { getTermsAndConditionsError } from "shared/Form.helper";
import { ROUTES } from "shared/Routes";

import { CardFooter, OnboardingCard } from "../layout-components";

export const RequestAddOrgSuccess = () => {
    const history = useHistory();
    const loggedInProps = useFlemingLoggedInAnalytics();
    const updateUserProfileMutation = useUpdateUserHealthcareProfileMutation();
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsAcceptedErrorMessage, setTermsAcceptedErrorMessage] =
        useState("");
    const dispatch = useDispatch();

    const {
        user: { onboarding, organisations },
    } = useCurrentUser();

    const needsToAcceptTermsOfService = !onboarding?.hasAcceptedTermsService;
    const hasExistingOrgs = organisations.length > 0;

    const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (needsToAcceptTermsOfService) {
            const errorMessage = getTermsAndConditionsError(termsAccepted);
            // Prevent form submission if the user didn't check the checkbox
            if (errorMessage) {
                setTermsAcceptedErrorMessage(errorMessage);
                return;
            }
        }

        dispatch({
            type: POST_USER_PROFILE_STARTED,
        });

        FlemingAnalyticsTracker.trackUsePolicyAcceptButtonClick(loggedInProps);

        updateUserProfileMutation.mutate(
            {
                hasAcceptedTermsService: needsToAcceptTermsOfService
                    ? true
                    : undefined,
            },
            {
                onSuccess: () => {
                    dispatch({
                        type: POST_USER_PROFILE_SUCCESS,
                        hasAcceptedTermsService: needsToAcceptTermsOfService
                            ? true
                            : undefined,
                    });
                },
                onError: (error) => {
                    dispatch({
                        type: POST_USER_PROFILE_FAILURE,
                        error:
                            error.message ||
                            "An error occurred when updating the account.",
                    });
                },
            },
        );
    };

    return needsToAcceptTermsOfService ? (
        <form onSubmit={onSubmit}>
            <OnboardingCard
                header="Accept terms and conditions"
                footer={
                    <CardFooter
                        isLoading={
                            updateUserProfileMutation.status === "loading"
                        }
                    />
                }
            >
                <Flex flexDirection="column" gap="3">
                    <Feedback colour="success">
                        <Text skinny>
                            You submitted a new organisation. We’ll process this
                            and add it to your account info.
                        </Text>
                    </Feedback>
                    <FormFieldWrapper
                        errors={
                            termsAcceptedErrorMessage
                                ? [termsAcceptedErrorMessage]
                                : []
                        }
                    >
                        <TermsAndConditionsCheckbox
                            checked={termsAccepted}
                            onCheckChange={setTermsAccepted}
                        />
                    </FormFieldWrapper>
                </Flex>
            </OnboardingCard>
        </form>
    ) : (
        <OnboardingCard header="We're verifying your organisation request">
            <Text>
                We're checking the details of the organisation that you added to
                your account. This may take up to 24hrs.
            </Text>
            <Feedback
                iconName="None"
                colour="information"
                props={{ className: "mb-3" }}
            >
                <Text skinny>
                    We'll send you an email once the verification is done.
                </Text>
            </Feedback>
            {hasExistingOrgs && (
                <Button
                    onClick={() => {
                        FlemingAnalyticsTracker.trackTakeMeBackLinkClick(
                            loggedInProps,
                        );
                        history.push(ROUTES.home);
                    }}
                    text="Back home"
                />
            )}
        </OnboardingCard>
    );
};
