import { useEffect } from "react";

import { Feedback } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
    resetConfirmMessageSuccess,
    resetIsBatchScheduled,
} from "app/batchMessage/gp/BatchMessage.actions";
import { BatchMessageStatuses } from "app/batchMessage/gp/components/overview/BatchMessageStatuses";
import { StatusesLabelsContext } from "app/batchMessage/gp/components/overview/BatchMessageStatusesContext";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

const statusLabels = ["Scheduled", "Sending", "Done", "Cancelled"];

export const BatchMessageOverviewPage = (): JSX.Element => {
    useAccurxWebTitle("View sent batch messages");

    const dispatch = useDispatch();

    // Store
    const orgId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );
    const confirmMessageSuccess = useAppSelector(
        ({ batchMessage }) => batchMessage.confirmMessageSuccess,
    );
    const isBatchScheduled = useAppSelector(
        ({ batchMessage }) => batchMessage.isBatchScheduled,
    );

    useEffect(() => {
        if (confirmMessageSuccess) {
            toast(
                Feedback({
                    colour: "success",
                    title: isBatchScheduled ? "Message saved" : "Message sent",
                }),
            );
            dispatch(resetConfirmMessageSuccess());
            dispatch(resetIsBatchScheduled());
        }
    }, [dispatch, confirmMessageSuccess, isBatchScheduled]);

    return (
        <StyledContainer>
            <PageHeader
                type={PageHeaderType.ListPage}
                title="Sent messages overview"
            />
            <StatusesLabelsContext.Provider value={statusLabels}>
                <BatchMessageStatuses
                    workspaceId={(orgId as number).toString()}
                    pageOrigin="AccurxWeb"
                />
            </StatusesLabelsContext.Provider>
        </StyledContainer>
    );
};
