/**
 *
 * @description this hook returns the current route's name and path values from ROUTE_INBOX
 */
import { useMemo } from "react";

import { ROUTES_INBOX } from "domains/inbox/routes";
import { matchPath, useHistory } from "react-router";

export type InboxRouteName = keyof typeof ROUTES_INBOX;
export type InboxPath = (typeof ROUTES_INBOX)[keyof typeof ROUTES_INBOX];
export type InboxRoute = {
    name: InboxRouteName;
    path: InboxPath;
};

export const useCurrentRoute = (): InboxRoute | undefined => {
    const history = useHistory();

    const route = useMemo(() => {
        for (const [key, value] of Object.entries(ROUTES_INBOX)) {
            const isMatch = matchPath(history.location.pathname, {
                path: value,
                exact: true,
                strict: true,
            });

            if (isMatch) {
                return {
                    name: key as InboxRouteName,
                    path: value,
                };
            }
        }
    }, [history.location.pathname]);

    return route;
};
