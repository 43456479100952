import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

import { BATCH_OVERVIEW_PAGE_SIZE } from "../constants";
import { BatchMessageSummaries } from "../types";

interface BatchStatusesParameters {
    workspaceId: string;
    continuationToken: string;
}

export const getSentBatchMessages = (
    workspaceId: string,
    continuationToken: string,
): Promise<IWrappedResult<BatchMessageSummaries>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "api/patientMessaging/batchMessaging/:workspaceId",
            params: { workspaceId },
            query: `?${new URLSearchParams({
                pageSize: BATCH_OVERVIEW_PAGE_SIZE.toString(),
                continuationToken,
            }).toString()}`,
        }),
    );

export const useSentBatchMessages = ({
    workspaceId,
    continuationToken,
}: BatchStatusesParameters) =>
    useQuery({
        queryKey: [
            "BatchMessaging",
            "SentBatchMessages",
            { workspaceId },
            continuationToken,
        ],
        queryFn: async () => {
            const response = await getSentBatchMessages(
                workspaceId,
                continuationToken,
            );
            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Request failed");
        },
        refetchOnMount: true,
        enabled: !!workspaceId,
    });
