import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Button, Text } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { generateFiltersArray } from "../Vaccine.helper";
import { setUnclearStatusFilter } from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";

export interface VaccineAllPatientsInvitedFiltersUnclearStatusCheckboxProps {
    numIndividualSelected: number;
    setCurrentPaginationPage: Dispatch<SetStateAction<number>>;
}

export const VaccineAllPatientsInvitedFiltersUnclearStatusCheckbox = ({
    numIndividualSelected,
    setCurrentPaginationPage,
}: VaccineAllPatientsInvitedFiltersUnclearStatusCheckboxProps): JSX.Element => {
    const dispatch = useDispatch();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const myPracticeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.myPractice,
        shallowEqual,
    );
    const statusFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.statusFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.statusFilters,
        shallowEqual,
    );
    const firstVaccinationTimeFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.firstVaccinationTimeFilter ||
            VaccineAllInvitesInitialState.allInvitesFilters
                .firstVaccinationTimeFilter,
        shallowEqual,
    );
    const vaccineTypeFilters = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.vaccineTypeFilters ||
            VaccineAllInvitesInitialState.allInvitesFilters.vaccineTypeFilters,
        shallowEqual,
    );
    const unclearStatusFilter = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.allInvitesFilters?.unclearStatus,
        shallowEqual,
    );

    const [reduxFilterLoaded, setReduxFilterLoaded] = useState(false);
    const [unclearStatusFilterOpen, setUnclearStatusFilterOpen] =
        useState(false);
    const [localUnclearStatusFilter, setLocalUnclearStatusFilter] =
        useState(true);

    useEffect(() => {
        // On first load or load after clicking 'Apply Filter' we sync the local state with the redux state
        if (!reduxFilterLoaded) {
            setLocalUnclearStatusFilter(unclearStatusFilter);
            setReduxFilterLoaded(true);
        }
    }, [unclearStatusFilter, reduxFilterLoaded]);

    const localFilterMatchesRedux =
        unclearStatusFilter === localUnclearStatusFilter;

    const handleApplyFilter = (): void => {
        setReduxFilterLoaded(false);
        dispatch(setUnclearStatusFilter(localUnclearStatusFilter));
        setCurrentPaginationPage(0);
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListFiltersAppliedProps =
            {
                ...analyticsLoggedInProps,
                applyFilterSection: "unclearStatus",
                myPracticeFilter: myPracticeFilter,
                currentStatusFilters: statusFilters,
                firstVaccinationWeekFilter:
                    firstVaccinationTimeFilter?.displayText,
                vaccineTypeFilters: generateFiltersArray(vaccineTypeFilters),
                unclearStatusFilter: localUnclearStatusFilter,
            };
        ChainAnalyticsTracker.trackVaccinePatientListFiltersApplied(
            analyticsProps,
        );
    };

    const handleToggleFilter = (): void => {
        setLocalUnclearStatusFilter((prevState) => !prevState);
    };

    const handleUnclearStatusFilterOpenToggle = (): void => {
        if (unclearStatusFilterOpen && !localFilterMatchesRedux) {
            setLocalUnclearStatusFilter(unclearStatusFilter);
        }
        setUnclearStatusFilterOpen((prev) => !prev);
    };

    return (
        <div className="mt-3 pb-3 d-flex flex-column">
            <div
                className="d-flex justify-content-between align-items-center cursor-pointer"
                onClick={handleUnclearStatusFilterOpenToggle}
            >
                <Text variant="label" props={{ className: "ml-2 mt-0" }}>
                    Unclear status
                </Text>
                <Button
                    dimension="small"
                    theme="transparent"
                    icon={{
                        name: "Arrow",
                        colour: "zinc",
                        rotation: unclearStatusFilterOpen ? "up" : "down",
                        placement: "end",
                    }}
                    text={
                        unclearStatusFilter && !unclearStatusFilterOpen
                            ? "Unclear status"
                            : ""
                    }
                    aria-label="open unclear status filter"
                />
            </div>
            {unclearStatusFilterOpen && (
                <>
                    <div className="d-flex ml-2 mt-2 mb-2">
                        <input
                            type="checkbox"
                            id="unclearStatusCheckbox"
                            data-testid="unclearStatusCheckbox"
                            onChange={handleToggleFilter}
                            checked={localUnclearStatusFilter}
                            disabled={numIndividualSelected > 0}
                            className="mr-2 mt-1"
                        />
                        <Text
                            as="label"
                            skinny
                            props={{ htmlFor: "unclearStatusCheckbox" }}
                        >
                            Unclear status
                        </Text>
                    </div>
                    <Button
                        text="Apply filter"
                        onClick={handleApplyFilter}
                        disabled={localFilterMatchesRedux}
                        className="d-block"
                        aria-label="apply unclear status filter"
                    />
                </>
            )}
        </div>
    );
};
