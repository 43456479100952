import React, { ChangeEvent } from "react";

import { Switch, Text } from "@accurx/design";
import { UncontrolledTooltip } from "reactstrap";

import { useAppSelector } from "store/hooks";

import { checkIsVideoConsult } from "./SendMessageFormComponent";

export interface PatientResponseToggleProps {
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PatientResponseToggle = ({
    handleChange,
}: PatientResponseToggleProps) => {
    const isSecureEmail = useAppSelector(
        ({ account }) => account.user?.isSecureEmail || false,
    );
    const isVideoConsult = useAppSelector((state) =>
        checkIsVideoConsult(state),
    );
    const userEmail = useAppSelector(
        ({ account }) => account.user?.email || "",
    );

    if (isVideoConsult) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-sm-12 mb-4">
                <Switch
                    testId="patient-response-toggle"
                    onChange={handleChange}
                    id="PatientResponseToggleTooltip"
                    labelText={
                        <>
                            <Text as="span" skinny>
                                Allow a patient to reply once with text or up to
                                5 photos
                            </Text>
                            <br />
                            <Text as="span" skinny>
                                The patient's response will come back to{" "}
                                {userEmail}
                            </Text>
                        </>
                    }
                    disabled={!isSecureEmail}
                />
                {!isSecureEmail && (
                    <UncontrolledTooltip
                        target="PatientResponseToggleTooltip"
                        placement="right"
                    >
                        This feature is unavailable as you have logged in with
                        an insecure email address. Please log in with either
                        nhs.net or a secure email address.
                    </UncontrolledTooltip>
                )}
            </div>
        </div>
    );
};

export default PatientResponseToggle;
