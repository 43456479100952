import { ReactNode } from "react";

import { useAnalytics } from "@accurx/analytics";
import { Accordion, Link, Text } from "@accurx/design";
import { useHistory } from "react-router";

import { AccordionContent } from "./AccordionContent";
import { NumberedList, NumberedListItem } from "./NumberedList";

const BoldText = ({ children }: { children: ReactNode }) => (
    <Text as="span" variant="label">
        {children}
    </Text>
);

const SUPPORT_URL =
    "https://support.accurx.com/en/articles/9267756-batch-messaging-a-complete-guide-to-exporting-a-csv-from-your-electronic-patient-record-epr";

export function HowToExportListOfPatientsInfo({
    isEmailEnabled,
}: {
    isEmailEnabled: boolean;
}) {
    const track = useAnalytics();
    const history = useHistory();

    return (
        <Accordion
            header={
                <Text skinny variant="label">
                    How to create a list of patients
                </Text>
            }
            openOnly
            buttonProps={{
                onClick() {
                    track("BatchCsvUploadResource Tab Click", {
                        resourceTitle: "How to create a list of patients",
                    });
                },
            }}
        >
            <AccordionContent>
                <Text skinny>
                    We support files in .csv, .tsv or .txt formats, which are
                    spreadsheet formats used for organising data into columns.
                    These columns should include the required patient
                    information with correct headers so that we can read the
                    file.
                </Text>
                <Text skinny>
                    <Link
                        openInNewTab
                        href={SUPPORT_URL}
                        onClick={() => {
                            track("BatchResource Link Click", {
                                batchResourceName: SUPPORT_URL,
                                origin: history.location.pathname,
                            });
                        }}
                    >
                        Read more about how to create this file depending on
                        your patient record system
                        <Link.Icon />
                    </Link>
                </Text>
                <Text skinny variant="label">
                    Important things to remember when you create the patient
                    list file:
                </Text>
                <NumberedList>
                    <NumberedListItem>
                        <Text skinny>
                            <BoldText>
                                Remove any patients who have declined or opted
                                out of receiving SMS{" "}
                                {isEmailEnabled ? "or email" : ""} notifications
                            </BoldText>{" "}
                            if you don't want to contact them.
                        </Text>
                    </NumberedListItem>
                    <NumberedListItem>
                        <Text skinny>
                            <BoldText>
                                Include columns with the correct patient
                                information
                            </BoldText>{" "}
                            so we can send the message to them. See the
                            information we need above.
                        </Text>
                    </NumberedListItem>
                    <NumberedListItem>
                        <Text skinny>
                            <BoldText>
                                Make sure each column has a clear header
                            </BoldText>{" "}
                            such as 'NHS number'.
                        </Text>
                    </NumberedListItem>
                    <NumberedListItem>
                        <Text skinny>
                            <BoldText>
                                Export the file from your record system or
                                spreadsheet
                            </BoldText>{" "}
                            in .csv, .tsv or .txt format.
                        </Text>
                    </NumberedListItem>
                </NumberedList>
            </AccordionContent>
        </Accordion>
    );
}
