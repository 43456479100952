import React from "react";

import { Text } from "@accurx/design";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export const SectionYourPractice = (): JSX.Element | null => {
    return (
        <>
            <Text as="h2" variant="subtitle">
                Your Organisation
            </Text>
            <div className="mb-4">
                <PracticeLink
                    url={`record-view-opt-in?referrer=webMenu`}
                    title="Record View"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Learn more about our new feature Record View which allows healthcare professionals to view a read only summary of a patient's medical record when the patient's explicit permission is provided"
                    badgeCopy="New"
                />
                <PracticeLink
                    url="modules"
                    title="Accurx Plus Module Access"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Find out what features you have access to, how to use them and what you can upgrade to"
                />
                <PracticeLink
                    url="installed-computers"
                    title="Installed Computers"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Shows which computers have Accurx Desktop installed"
                />
                <PracticeLink
                    url="practice-users"
                    title="Manage Users"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Allows you to see and manage users"
                />
                <PracticeLink
                    url="team-notifications"
                    title="Manage Team Notifications"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Allows you to see and manage team notifications"
                />
                <PracticeLink
                    url="team-usage"
                    title="Team Usage"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Shows you how many SMS messages each team member has sent"
                />
                <PracticeLink
                    url="sender-id"
                    title="Sender ID"
                    section={PracticeLinkSection.YOUR_ORG}
                    description="Allows you to edit the SMS sender name that appears for patients"
                />
            </div>
        </>
    );
};
