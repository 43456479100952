import React from "react";

import { Ds } from "@accurx/design";

import { MaxUnreadConversationDisplayCount } from "shared/constants/conversationCounts";

export const getUnreadCountBadgeDisplayValue = (
    unreadCount: number,
    max = MaxUnreadConversationDisplayCount,
): string => {
    if (max === undefined) {
        return unreadCount.toString();
    }

    return unreadCount > max ? `${max}+` : unreadCount.toString();
};

type WorkspaceConversationUnreadCountBadgeProps = {
    /**
     * The number that should appear inside the badge.
     * If value is undefined or 0, it will not render anything.
     */
    unreadCount?: number;
    max?: number;
    luminosity?: Ds.BadgeProps["luminosity"];
    color?: Ds.BadgeProps["color"];
};

export const WorkspaceConversationUnreadCountBadge = ({
    unreadCount,
    max = MaxUnreadConversationDisplayCount,
    luminosity = "low",
    color = "red",
}: WorkspaceConversationUnreadCountBadgeProps): JSX.Element | null => {
    if (unreadCount === undefined || unreadCount === 0) {
        return null;
    }

    const displayCount = getUnreadCountBadgeDisplayValue(unreadCount, max);

    return (
        <Ds.Badge
            radius="round"
            data-count={unreadCount}
            data-testid={"unread-count"}
            luminosity={luminosity}
            color={color}
        >
            {displayCount}
        </Ds.Badge>
    );
};
