import {
    IWrappedResult,
    JsonResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";

import { RecordViewRequestDto } from "../app/practices/recordViewRequests/RequestsList.types";
import {
    AllRecordViewRequestsRequest,
    GetAllRecordsRequest,
    LatestPatientRecordRequest,
    LatestPatientRecordResponse,
    PatientRecordViewAuthenticationRequest,
    PatientRecordViewGetRecordRequest,
    PatientRecordViewRequest,
    PatientRecordViewResponse,
    RecordViewRequest,
} from "./FlemingDtos";

const ENDPOINTS = {
    recordViewRequest: "/api/recordview/request",
    recordViewValidate: "/api/recordview/validate",
    recordViewResult: "/api/recordview/result",
    recordViewExampleResult: "/api/recordview/exampleresult",
    recordViewResultDirect: "/api/recordview/internaltesting/result-direct",
    recordViewGpAudit: "/api/recordview/GpAudit",
    recordViewResultXml: "/api/recordview/internaltesting/result-xml",
    recordViewRecentResults: "/api/recordview/result/recentresults",
    recordViewResultLatest: "/api/recordview/result/latest",
} as const;

export const requestRecordView = async (
    patientRecordViewRequestDto: PatientRecordViewRequest,
): Promise<PatientRecordViewResponse> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewRequest,
        }),
        patientRecordViewRequestDto,
    );
    if (response.success === false || response.result === null) {
        return {
            success: false,
            requestId: null,
            error: response.error,
        };
    }
    return {
        success: true,
        requestId: response.result.requestId,
        error: null,
    };
};

export const authenticateRecordView = async (
    patientRecordViewAuthenticationDto: PatientRecordViewAuthenticationRequest,
): Promise<boolean> => {
    const response = await httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewValidate,
        }),
        patientRecordViewAuthenticationDto,
    );
    return response.success;
};

const getRecordResult = (response: JsonResult): RecordViewRequest => {
    if (
        !response.success ||
        response.result === null ||
        !response.result.hasOwnProperty("medicalRecord")
    ) {
        return {
            result: {
                isPatientAuthorised: null,
                maxAccessAttemptsExceeded: null,
                medicalRecord: null,
                medicalRecordFormat: null,
                requestedTime: null,
                status: "Error fetching record",
            },
        };
    }

    return response;
};

export const getRecord = async ({
    RequestId,
    OrganisationId,
}: PatientRecordViewGetRecordRequest): Promise<RecordViewRequest> => {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewResult,
            query: `?requestId=${RequestId}&organisationId=${OrganisationId}`,
        }),
    );
    return getRecordResult(response);
};

export const getExampleRecord = async (
    organisationId: number | null,
): Promise<RecordViewRequest> => {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewExampleResult,
            query: `?organisationId=${organisationId}`,
        }),
    );
    return getRecordResult(response);
};

export const getRecordNoAuth = async ({
    patientToken,
    organisationId,
}: PatientRecordViewRequest): Promise<RecordViewRequest> => {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewResultDirect,
            query: `?patientToken=${patientToken}&organisationId=${organisationId}`,
        }),
    );

    return getRecordResult(response);
};

export const getRecordViewRequestsForGp = (
    practiceId: string,
): Promise<IWrappedResult<RecordViewRequestDto>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewGpAudit,
            query: `?organisationId=${practiceId}`,
        }),
    );

// Will return a download link for download
export const getDownloadUrlRecordNoAuthXML = ({
    patientToken,
    organisationId,
}: Omit<PatientRecordViewRequest, "isGpOwnPatient">): string => {
    return getApiEndpoint({
        path: ENDPOINTS.recordViewResultXml,
        query: `?patientToken=${patientToken}&organisationId=${organisationId}`,
    }).urlFinal;
};

export const getAllRecordRequests = async ({
    OrganisationId,
}: GetAllRecordsRequest): Promise<
    IWrappedResult<AllRecordViewRequestsRequest>
> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewRecentResults,
            query: `?organisationId=${OrganisationId}`,
        }),
    );
};

export const getLatestPatientResult = async ({
    organisationId,
    nhsNumber,
}: LatestPatientRecordRequest): Promise<
    IWrappedResult<LatestPatientRecordResponse>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recordViewResultLatest,
            query: `?organisationId=${organisationId}&nhsNumber=${nhsNumber}`,
        }),
    );
