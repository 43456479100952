import { useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Participant } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useWorkspaceActivityAnalytics } from "domains/inbox/hooks/util/useWorkspaceActivityAnalytics";
import { getMainParticipant } from "domains/inbox/util/getMainParticipant";
import { getParticipantName } from "domains/inbox/util/getParticipantName";
import styled from "styled-components";

import {
    ActivityIndicator,
    TextOverflowWrapper,
    WorkspaceActivityTooltipProvider,
} from "../WorkspaceActivityTooltipProvider/WorkspaceActivityTooltipProvider";

const Container = styled.div`
    display: inline-block;
    max-width: 100%;
    position: relative;
`;

export const Participants = ({
    participants,
}: {
    participants: Participant[];
}) => {
    const nameRef = useRef<HTMLDivElement>(null);
    const { trackWorkspaceStaffAvailabilityHover } =
        useWorkspaceActivityAnalytics({ location: "participantPill" });
    const currentWorkspace = useCurrentWorkspace();

    const mainParticipant = getMainParticipant(
        { participants },
        currentWorkspace.orgId,
    );
    const displayName = getParticipantName(mainParticipant);
    return (
        <Container>
            <WorkspaceActivityTooltipProvider
                participant={mainParticipant}
                onTooltipOpen={({ workspaceStatus }) => {
                    trackWorkspaceStaffAvailabilityHover({ workspaceStatus });
                }}
                nameRef={nameRef}
            >
                {({ workspaceStatus }) => (
                    <>
                        <Pill.Base
                            color={UI.Tokens.COLOURS.greyscale.cloud}
                            radius="full"
                            border={false}
                        >
                            <TextOverflowWrapper ref={nameRef}>
                                <UI.Text variant="preview" as="span" skinny>
                                    With:
                                </UI.Text>{" "}
                                <UI.Text variant="note" as="span" skinny>
                                    {displayName}
                                </UI.Text>
                            </TextOverflowWrapper>
                        </Pill.Base>
                        {workspaceStatus && (
                            <ActivityIndicator
                                isActive={
                                    !!workspaceStatus.activity &&
                                    workspaceStatus.activity.daysSinceOnline < 1
                                }
                            />
                        )}
                    </>
                )}
            </WorkspaceActivityTooltipProvider>
        </Container>
    );
};

export const PatientParticipant = () => {
    return (
        <Container>
            <Pill.Base
                color={UI.Tokens.COLOURS.greyscale.cloud}
                radius="full"
                border={false}
            >
                <TextOverflowWrapper>
                    <UI.Text variant="preview" as="span" skinny>
                        With:
                    </UI.Text>{" "}
                    <UI.Text variant="note" as="span" skinny>
                        Patient
                    </UI.Text>
                </TextOverflowWrapper>
            </Pill.Base>
        </Container>
    );
};
