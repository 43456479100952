import { useState } from "react";

import { Button, Flex, Text } from "@accurx/design";

import { SnomedCodeListContext } from "app/hooks/queries/useGetSnomedCodesQuery";

import { SnomedCode } from ".";
import { MissingSnomedCodeInformation } from "./MissingSnomedCodeInformation";
import { StyledSelectContainer } from "./MultiSnomedPicker.styles";
import { SnomedSelect } from "./SnomedSelect";
import { SnomedSummaryCard } from "./SnomedSummaryCard";

type MultiSnomedPickerProps = {
    onChange: (snomedCode: SnomedCode[]) => void;
    values: Optional<SnomedCode[]>;
    disabled: boolean;
    showMissingSnomedCodeInformation: boolean;
    id: string;
    context: SnomedCodeListContext;
};

export const MultiSnomedPicker = ({
    onChange,
    disabled,
    values,
    showMissingSnomedCodeInformation,
    id,
    context,
}: MultiSnomedPickerProps) => {
    const [addAdditionalSnomedCode, setAddAdditionalSnomedCode] =
        useState<boolean>(false);
    return (
        <Flex flexDirection="column" gap="1.5">
            {values &&
                values.map((snomedCode, index) => (
                    <SnomedSummaryCard
                        key={`snomed-${snomedCode.conceptId}`}
                        onRemove={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();

                            onChange(values.filter((_, i) => i !== index));
                        }}
                        value={snomedCode}
                        disabled={disabled}
                    />
                ))}

            {(!values || values.length === 0 || addAdditionalSnomedCode) && (
                <StyledSelectContainer>
                    <Text skinny>
                        Type the description or concept ID to search
                    </Text>
                    <SnomedSelect
                        id={`${id}-select`}
                        value={undefined}
                        filter={values ?? []}
                        onChange={(newSnomedCode) => {
                            if (newSnomedCode) {
                                if (values) {
                                    onChange([...values, newSnomedCode]);
                                } else {
                                    onChange([newSnomedCode]);
                                }
                                setAddAdditionalSnomedCode(false);
                            }
                        }}
                        disabled={disabled}
                        context={context}
                    />
                    {showMissingSnomedCodeInformation && (
                        <MissingSnomedCodeInformation />
                    )}
                </StyledSelectContainer>
            )}
            {values && values.length > 0 && !addAdditionalSnomedCode && (
                <Button
                    theme="secondary"
                    icon={{ name: "Plus", colour: "blue" }}
                    text="Add another code"
                    onClick={() => setAddAdditionalSnomedCode(true)}
                    type="button"
                ></Button>
            )}
        </Flex>
    );
};
