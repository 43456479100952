import { useEffect, useState } from "react";

import { models } from "powerbi-client";
import { EventHandler, PowerBIEmbed } from "powerbi-client-react";
import { useLocation } from "react-router";

import {
    ReportName,
    ReportingPageLoadProps,
    trackReportingPageLoad,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { useGetPowerBiReportQuery } from "app/hooks/queries/useGetPowerBIReportQuery";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { useCurrentOrganisation } from "../../hooks";
import { StyledContainer, StyledError } from "./PowerBIReport.styles";

type LocationState = Optional<
    Pick<ReportingPageLoadProps, "navigationOrigin" | "pageOrigin">
>;

export const PowerBIReport = ({
    pageName,
    reportName,
    pageOrigin,
}: {
    pageName: string;
    reportName: string;
    pageOrigin: ReportName;
}): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const org = useCurrentOrganisation();
    const { state } = useLocation<LocationState>();

    const [mountingTimestamp] = useState(Date.now());
    const [isReportLoaded, setIsReportLoaded] = useState(false);

    // Deliberately running analytics tracking as a side effect rather than the
    // event handler because the PowerBI client fires multiple `rendered` events
    // at the same time.
    useEffect(() => {
        if (isReportLoaded) {
            trackReportingPageLoad({
                ...analyticsLoggedInProps,
                navigationOrigin: state?.navigationOrigin ?? "URL",
                pageOrigin: state?.pageOrigin,
                currentTab: pageName,
                uiResponseTimeMs: Date.now() - mountingTimestamp,
                reportName: pageOrigin,
            });
        }
        // Only run tracking once when report has loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportLoaded]);

    const handleError: EventHandler = (e) => {
        trackReportingPageLoad({
            ...analyticsLoggedInProps,
            hasError: true,
            errorReason: e?.detail ?? "Failed to fetch report",
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: pageName,
            reportName: pageOrigin,
        });
    };

    const { data = null, status } = useGetPowerBiReportQuery(
        { organisationId: String(org?.orgId), reportName },
        { onError: () => handleError() },
    );

    if (status === "error") {
        return (
            <StyledError>
                Oops! We're sorry something went wrong. Please refresh the page,
                if the issue persists contact our support team.
            </StyledError>
        );
    }

    // see https://github.com/microsoft/powerbi-client-react/blob/master/React/demo/src/DemoApp.tsx
    const reportConfig = {
        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: data?.id,
        embedUrl: data?.embedUrl,
        accessToken: data?.token,
        tokenType: models.TokenType.Embed,
        viewMode: models.ViewMode.View,
        pageName: data?.pages[pageName],
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToPage,
            },
            panes: {
                filters: {
                    expanded: false,
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
            },
            background: models.BackgroundType.Transparent,
        },
    };

    return (
        <StyledContainer>
            <PowerBIEmbed
                embedConfig={reportConfig}
                cssClassName="powerBiEmbeddedContainer"
                eventHandlers={
                    new Map([
                        ["rendered", () => setIsReportLoaded(true)],
                        ["error", handleError],
                    ])
                }
            />
        </StyledContainer>
    );
};
