import React, { useMemo } from "react";

import { useUsersById } from "@accurx/concierge/hooks/data/useUsersById";
import { ConversationItem, UserSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Tooltip } from "@accurx/inbox-design-library";
import { format } from "date-fns";
import reduce from "lodash/reduce";

import {
    StyledUserList,
    StyledUserListItem,
    StyledUserReadCountLabel,
} from "./ReadByTooltip.styles";

type ReadByTooltipProps = {
    readDataByUserId: ConversationItem["readDataByUserId"];
};

type ReadReceipt = { userId: UserSummary["id"]; readAt: string };

const formatReadAt = (readReceipt: ReadReceipt): string => {
    try {
        return format(new Date(readReceipt.readAt), "h:mmaaa, E d MMM");
    } catch (e) {
        return "";
    }
};

export const ReadByTooltip = ({ readDataByUserId }: ReadByTooltipProps) => {
    const readReceipts = useMemo(() => {
        return reduce<ConversationItem["readDataByUserId"], ReadReceipt[]>(
            readDataByUserId,
            (result, item, userId) => {
                if (item.readAt !== null) {
                    result.push({ userId, readAt: item.readAt });
                }
                return result;
            },
            [],
        );
    }, [readDataByUserId]);
    const userIds = useMemo(
        () => readReceipts.map((item) => item.userId),
        [readReceipts],
    );
    const users = useUsersById({ userIds });

    return (
        <span>
            <Tooltip.Root>
                <Tooltip.Trigger asChild>
                    {users.length > 0 ? (
                        <UI.Text variant="preview" skinny colour="metal">
                            Read by:{" "}
                            <StyledUserReadCountLabel
                                forwardedAs="span"
                                skinny
                                variant="preview"
                                colour="metal"
                            >
                                {users.length} colleague
                                {users.length > 1 ? "s" : ""}
                            </StyledUserReadCountLabel>
                        </UI.Text>
                    ) : (
                        <StyledUserReadCountLabel
                            variant="preview"
                            skinny
                            colour="metal"
                        >
                            Unread
                        </StyledUserReadCountLabel>
                    )}
                </Tooltip.Trigger>
                <Tooltip.Content align="start" $colour="white">
                    {users.length > 0 ? (
                        <StyledUserList>
                            {users.map((user, i) => (
                                <StyledUserListItem
                                    key={user?.id ?? `unknown-user-${i}`}
                                >
                                    <b>{user?.displayName ?? "Unknown user"}</b>{" "}
                                    <span>
                                        ({formatReadAt(readReceipts[i])})
                                    </span>
                                </StyledUserListItem>
                            ))}
                        </StyledUserList>
                    ) : (
                        <span>
                            Colleagues will appear here when
                            <br /> they read this message
                        </span>
                    )}
                </Tooltip.Content>
            </Tooltip.Root>
        </span>
    );
};
