import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

// Some pages (patientConversationSingle/Page, ClinicianConversation)
// need to have a dynamic height so we need these values.
// This is temporary and will be refactored in the navigation revamp next cycle.
export const NAV_MENU_HEIGHT_XL = "93px";
export const NAV_MENU_HEIGHT = "103px";

export const ViewportStackPanel = styled(StackPanel)`
    height: calc(100vh - ${NAV_MENU_HEIGHT});
    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        height: calc(100vh - ${NAV_MENU_HEIGHT_XL});
    }
    padding-top: ${Tokens.SIZES[3]};
    padding-bottom: ${Tokens.SIZES[3]};
    margin-left: -15px;
    margin-right: -15px;
    min-height: 28rem;
`;

export const ErrorPadding = styled.div`
    padding-top: ${Tokens.SIZES[2]};
`;
