import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";

import {
    StyledDropdownItemButton,
    StyledDropdownItemButtonV1Pt1,
} from "./DropdownItemButton.styles";

export const DropdownItemButton = (props: UI.AllButtonProps) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    if (isMessageComponentV1_1Enabled) {
        return <StyledDropdownItemButtonV1Pt1 {...props} />;
    }

    return <StyledDropdownItemButton {...props} />;
};
