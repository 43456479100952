import { FormEvent, MutableRefObject, ReactNode } from "react";

import { Button, FormFieldFeedback, StackPanel } from "@accurx/design";

import { CardWithTitle } from "app/sharedComponents/cardWithTitle/CardWithTitle";
import {
    StyledFooterActionsWrapper,
    StyledFooterGroupedActions,
} from "app/sharedComponents/saveableCard/SaveableCard.styles";

import { BranchId, BranchPosition, QuestionLevel } from "../types/data.types";
import {
    MeasurementFormElement,
    MeasurementsSelector,
} from "./MeasurementTypeSelector";
import { HandleCancelMeasurementTypeSelectorCard } from "./MeasurementTypeSelector.types";
import { HandleSaveMeasurmentType } from "./QuestionTypeSelector.types";

export type MeasurmentsSelectorCardProps = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    cardTitleQuestionNumber: number;
    cardActionErrors: string[];
    cardActionErrorRef: MutableRefObject<HTMLDivElement | null>;
    additionalActions?: ReactNode /* Optional: Additional actions in the footer. Only used when needed.  */;
    handleCancelChanges: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSaveChanges: ({
        event,
        branchPosition,
        questionLevel,
        branchId,
    }: HandleSaveMeasurmentType) => Promise<boolean>;
    isSaving: boolean;
    questionLevel: QuestionLevel;
};

enum SaveButtonModes {
    Save = "Add question",
    Saving = "Adding question...",
}

export const MeasurementsSelectorCard = ({
    branchId,
    branchPosition,
    cardTitleQuestionNumber,
    cardActionErrors,
    cardActionErrorRef,
    additionalActions,
    handleCancelChanges,
    handleSaveChanges,
    isSaving,
    questionLevel,
}: MeasurmentsSelectorCardProps): JSX.Element => {
    return (
        <form
            onSubmit={(event) =>
                handleSaveChanges({
                    branchId,
                    branchPosition,
                    event: event as FormEvent<MeasurementFormElement>,
                    questionLevel,
                })
            }
        >
            <CardWithTitle
                children={<MeasurementsSelector />}
                cardTitle={`Q${cardTitleQuestionNumber}. Measurements`}
                cardFooter={
                    <StackPanel gutter={2}>
                        {cardActionErrors.length > 0 && (
                            <div ref={cardActionErrorRef} tabIndex={0}>
                                <FormFieldFeedback
                                    text={cardActionErrors[0]}
                                    variant="error"
                                />
                            </div>
                        )}
                        <StyledFooterActionsWrapper
                            additionalActions={additionalActions}
                        >
                            {additionalActions}
                            <StyledFooterGroupedActions>
                                <Button
                                    className="mr-2"
                                    text={"Back"}
                                    theme={"secondary"}
                                    onClick={() =>
                                        handleCancelChanges({ questionLevel })
                                    }
                                />
                                <Button
                                    type="submit"
                                    text={
                                        isSaving
                                            ? SaveButtonModes.Saving
                                            : SaveButtonModes.Save
                                    }
                                    theme={"primary"}
                                    disabled={isSaving === true}
                                />
                            </StyledFooterGroupedActions>
                        </StyledFooterActionsWrapper>
                    </StackPanel>
                }
            />
        </form>
    );
};
