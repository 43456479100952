import { NbsData } from "./VaccineDeliveryDTO";
import { NimsData } from "./VaccineSharedDTO";

export interface BookingDetails {
    bookingId: string;
    status: string;
    startTime: string;
    endTime: string;
    setupLocationFreeText: string;
    hasLinkedRecording: boolean;
}

export class InviteNoteType {
    static None = "None";
    static PatientDeclined = "PatientDeclined";
    static UserNote = "UserNote";
    static UserAddedPatientToNetwork = "UserAddedPatientToNetwork";
    static UserRemovedPatientFromNetwork = "UserRemovedPatientFromNetwork";
    static UserCancelled = "UserCancelled";
    static UserInvitedToSecond = "UserInvitedToSecond";
    static PatientBooked = "PatientBooked";
    static PatientCancelledBooking = "PatientCancelledBooking";
    static UserAsPatientBooked = "UserAsPatientBooked";
    static UserAsPatientDeclined = "UserAsPatientDeclined";
    static UserCancelledBooking = "UserCancelledBooking";
    static UserInvitedToBooster = "UserInvitedToBooster";
    static UserResetInvite = "UserResetInvite";
}

// Used with 'VaccinePatientInvited.currentInviteStatus' and filter strings, see RecallInvite's InviteStatusDisplay
export class InviteStatusType {
    static ToManuallyBookFirst = "To manually book - 1st";
    static ToManuallyBookSecond = "To manually book - 2nd";
    static AwaitingResponseFirst = "Awaiting response - 1st";
    static AwaitingResponseSecond = "Awaiting response - 2nd";
    static BookedFirstOnly = "Booked first only";
    static BookedBooster = "Booked booster";
    static BookedBoth = "Booked both";
    static AwaitingSecondInvite = "Awaiting 2nd invite";
    static AwaitingBoosterInvite = "Awaiting booster invite";
    static Completed = "Completed";
    static Cancelled = "Cancelled";
    static Declined = "Declined";
    static Rejected = "Already invited";
    static RejectedIneligible = "Rejected ineligible";
    static NotInNetwork = "Not in network";
    // no longer sent from client but left in to support migration
    static ToManuallyBookLegacy = "To manually book";
    static AwaitingResponseLegacy = "Awaiting response";
    static AwaitingSpring2022BoosterInvite =
        "Awaiting invite - spring 2022 booster";
    static BookedSpring2022Booster = "Booked spring 2022 booster";
    static AwaitingAutumn2022BoosterInvite =
        "Awaiting invite - autumn 2022 booster";
    static BookedAutumn2022Booster = "Booked autumn 2022 booster";
}

interface OrgInformation {
    nationalCode: string;
    name: string;
}

interface UserInformation {
    id: string;
    fullName: string;
    email: string;
}

export interface InviteNote {
    recallInviteId: string;
    createdAt: string;
    type: InviteNoteType;
    createdBy: number;
    createdByOrg?: OrgInformation;
    createdByUser?: UserInformation;
    summary: string;
    details: string;
}

export class InviteReminderType {
    static AutomatedReminder = "InviteReminder";
    static ManualReminder = "InviteReminderManual";
}

export interface InviteReminder {
    createdAt: string;
    type: InviteReminderType;
    createdByOrg?: OrgInformation;
    createdByUser?: UserInformation;
}

export interface VaccinePatientStatusUpdate {
    inviteId: string;
    status: string;
}

export interface VaccineInviteOperationResult {
    inviteId: number;
    success: boolean;
    error?: string;
}

export interface VaccineBulkCancelInviteResponse {
    results: VaccineInviteOperationResult[];
}

export interface VaccinePatientInvite {
    inviteId: string;
    batchId: string;
    sendTime: string;
    status: string;
    bookings: BookingDetails[];
    invitedBy: OrgInformation;
    invitedByUser: UserInformation | null; // would be null for out of network patients
    notes: InviteNote[];
    inviteReminders: InviteReminder[];
}

export interface VaccinePatientInviteDetailsResponse {
    invites: VaccinePatientInvite[];
    nimsData: NimsData;
    nbsData: NbsData;
}

export interface VaccinePatientInvitePostNotesResponse {
    ok: boolean;
}

export interface VaccinePatientRefreshNimsResponse {
    ok: boolean;
}

export interface VaccineAddToNetworkRequest {
    inviteId: string;
}

export interface VaccinePatientAddToNetworkResponse {
    id?: string; // present on successful calls
}

export interface VaccinePatientNotesRequest {
    details: string;
    summary?: string;
}
