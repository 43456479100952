import { format, isToday } from "date-fns";

const dateFormat = "d MMMM YYY";
const timeFormat = "h.mmaaa";

export const formatUpdatedDateTime = (updated?: string | null): string => {
    let formattedDateTime = "unknown";

    if (updated) {
        const updatedDate = new Date(updated);
        const formattedDate = isToday(updatedDate)
            ? "Today"
            : format(updatedDate, dateFormat);
        let formattedTime = format(updatedDate, timeFormat);
        if (formattedTime === "12.00am") {
            formattedTime = "midnight";
        }
        if (formattedTime === "12.00pm") {
            formattedTime = "midday";
        }

        formattedDateTime = `${formattedDate} at ${formattedTime}`;
    }

    return formattedDateTime;
};
