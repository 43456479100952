import { useEffect, useState } from "react";

import { useMeaningfulActionAnalyticsProps } from "@accurx/analytics";
import { FeatureName } from "@accurx/auth";
import { Button, Feedback, Icon, Text } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";
import format from "date-fns/format";
import { useHistory, useParams } from "react-router";
import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";

import { useSafeAsync } from "api/Api.utils";
import { sendBatchMessage } from "api/BatchMessaging";
import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import { BatchStatus, BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { getHourDisplayValue } from "app/batchMessage/shared/getWorkingHoursOptions";
import { useBatchUploadDetailsQuery } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { ComposeArea } from "app/workspaceConversations/components/Conversation/ComposeArea/ComposeArea";
import { HandleSendMessageParams } from "app/workspaceConversations/components/Conversation/ComposeArea/ComposeArea.types";
import { BatchQuestionnaireList } from "app/workspaceConversations/components/Conversation/FloreyQuestionnaire/BatchQuestionnaireList";
import { QuestionnaireButton } from "app/workspaceConversations/components/Conversation/FloreyQuestionnaire/QuestionnaireButton";
import { DisplayFloreyMessageTemplate } from "app/workspaceConversations/components/Conversation/FloreyQuestionnaire/types";
import { generateSignature } from "shared/MessageHelper";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { PatientDetailsTable } from "../../components/PatientDetailsTable";
import { getContactablePatient } from "../utils/contactable";
import {
    StyledDetailsSection,
    StyledIcon,
    StyledPageWrapper,
    StyledPatientDetailsWrapper,
    StyledPatientPill,
    StyledRecipientDetails,
    StyledRecipientDetailsItem,
    StyledSeeDetailsButton,
} from "./ComposePage.styles";
import { ScheduleButton, ScheduleOptions } from "./ScheduleButton";

const MESSAGE_HEADER = "Dear [Patient name appears here]";

const getRecipientCopy = (numberOfPatients: number | null) => {
    if (!numberOfPatients) {
        return "";
    }

    return numberOfPatients === 1
        ? "1 patient"
        : `${numberOfPatients} patients`;
};

const isBatchAlreadySent = (batchStatus?: string) => {
    if (!batchStatus) return false;

    return ![
        BatchStatus.FetchingDemographics,
        BatchStatus.DemographicsPopulated,
    ].includes(batchStatus);
};

export const ComposePage = () => {
    const safeAsync = useSafeAsync();
    const history = useHistory<{ appOrigin?: string }>();
    const { batchId, workspaceId } = useParams<{
        batchId: string;
        workspaceId: string;
    }>();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const [isShowingPatientDetails, setIsShowingPatientDetails] =
        useState(false);
    const signature = useAppSelector((state) =>
        generateSignature(state.account.user),
    );
    const [messageParts, setMessageParts] = useState({
        header: MESSAGE_HEADER,
        body: "",
        signature,
    });

    const batchUploadDetailsQuery = useBatchUploadDetailsQuery({
        workspaceId,
        batchId,
    });

    const [isShowingQuestionnaireList, setIsShowingQuestionnaireList] =
        useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] =
        useState<DisplayFloreyMessageTemplate | null>(null);

    const isSingleSendFloreysEnabled = useIsFeatureEnabled(
        FeatureName.WebSingleSendFloreys,
    );
    const isCollaborativeWebInboxEnabled = useIsFeatureEnabled(
        FeatureName.CollaborativeWebInbox,
    );
    const analyticsIsPifuEnabled = useIsFeatureEnabled(
        FeatureName.WebPatientLedFollowUp,
    );
    const analyticsWorkspaceNationalCode = useAppSelector(
        ({ account }) =>
            OrganisationHelper.getOrganisation(account)?.nationalCode,
    );
    const canUseQuestionnaires =
        isSingleSendFloreysEnabled && isCollaborativeWebInboxEnabled;

    const [scheduleOption, setScheduleOption] =
        useState<ScheduleOptions>("now");
    const [scheduleDate, setScheduleDate] = useState<Date | null>(null);
    const [scheduleHour, setScheduleHour] = useState<number | null>(null);

    useEffect(() => {
        if (isBatchAlreadySent(batchUploadDetailsQuery?.data?.status)) {
            toast(
                <Feedback title="Batch message already sent" colour="error" />,
            );

            // Redirect home if the batch corresponding to the ID has already been sent
            history.push(ROUTES.home);
        }
    }, [batchUploadDetailsQuery?.data?.status, history]);

    const contactablePatients = batchUploadDetailsQuery.data?.items
        ? batchUploadDetailsQuery.data.items.filter((x) => x.isTextable)
        : [];

    const numberOfContactablePatients = contactablePatients.length;

    useEffect(() => {
        if (
            batchUploadDetailsQuery.data?.status ===
            BatchStatus.DemographicsPopulated
        ) {
            ChainAnalyticsTracker.trackBatchComposePageView({
                ...analyticsLoggedInProps,
                countAccepted: numberOfContactablePatients,
                isTrustFlow: true,
            });
        }
    }, [
        batchUploadDetailsQuery?.data?.status,
        numberOfContactablePatients,
        analyticsLoggedInProps,
    ]);

    const handleSendFailure = () => ({ success: false });

    const handleSendMessage = async (params: HandleSendMessageParams) => {
        if (!params.isValidMessage) {
            return handleSendFailure();
        }

        const { message, questionnaire, template, uploadedFileIds } = params;

        // Preset Accurx templates do not have an ID. If we don't have an ID, we cannot link it to the message.
        const messageTemplateAttachments = template?.id
            ? {
                  templateId: template.id,
                  attachmentIds: (template.attachedDocuments || []).map(
                      (attachment) => parseInt(attachment.documentId, 10),
                  ),
              }
            : undefined;

        const attachedDocument = uploadedFileIds
            ? uploadedFileIds[0]
            : undefined;

        let sendAt;

        if (scheduleOption !== "now") {
            if (scheduleDate && scheduleHour) {
                sendAt = {
                    day: scheduleDate.getDate(),
                    month: scheduleDate.getMonth() + 1,
                    year: scheduleDate.getFullYear(),
                    hour: scheduleHour,
                };
            } else {
                return handleSendFailure();
            }
        }

        const templateProperties = template
            ? {
                  group: template.group,
                  name: template.title,
                  isPreset: template.isPreset,
              }
            : undefined;

        const { success, error } = await safeAsync(
            sendBatchMessage(workspaceId, {
                id: batchId,
                messageTemplate: message,
                documentId: attachedDocument,
                floreyConditionId: questionnaire?.id,
                messageTemplateAttachments,
                template: templateProperties,
                snomedConceptId: null, // Always null for Trust flow, as we never want to save to record
                autoSaveToRecord: false, // Always false for Trust flow, as we never want to save to record
                saveMessageToRecord: false, // Always false for Trust flow, as we never want to save to record
                sendAt,
            }),
        );

        ChainAnalyticsTracker.trackBatchPatientMessageSendClick({
            ...analyticsLoggedInProps,
            ...meaningfulActionProps,
            messageType: AnalyticsMapper.getBatchType(
                questionnaire?.id ? BatchType.FLOREY : BatchType.MESSAGE,
                true,
            ),
            messageSaveToRecord: false,
            snomedCode: null,
            templateName: template?.title || null,
            conditionId: questionnaire?.id || null,
            floreyName: questionnaire?.title || null,
            withPatientReplyLink: params.enablePatientResponse,
            withPatientInitiatedFollowUpLink:
                AnalyticsMapper.messageContainsPIFULink(
                    analyticsIsPifuEnabled,
                    analyticsWorkspaceNationalCode,
                    message,
                ),
            withAttachment: messageTemplateAttachments
                ? messageTemplateAttachments.attachmentIds.length > 0
                : attachedDocument !== undefined,
            countAttachmentFromTemplate:
                messageTemplateAttachments?.attachmentIds.length || 0,
            isAutoSaveEnabled: false,
            isPresetTemplate: template?.isPreset || false,
            countTotal: numberOfContactablePatients,
            isTrustFlow: true,
            appOrigin: history.location.state?.appOrigin || null,
        });

        if (!success) {
            toast(
                <Feedback title="Unable to send batch message" colour="error">
                    {error && <Text>{error}</Text>}
                </Feedback>,
            );
            return handleSendFailure();
        }

        history.push(
            generatePath(ROUTES_WORKSPACE.batchMessageTrustConfirm, {
                workspaceId,
                batchId,
            }),
        );

        return { success: true };
    };

    if (batchUploadDetailsQuery.status === "error") {
        return (
            <Feedback
                title="An error occurred while trying to load batch details"
                colour="error"
            >
                {batchUploadDetailsQuery.error.message}
            </Feedback>
        );
    }

    const patientDetails = contactablePatients.map((patientSummary) =>
        getContactablePatient(patientSummary, batchId),
    );

    const onClickSeePatientDetails = () => {
        if (isShowingPatientDetails) {
            setIsShowingPatientDetails(false);
            return;
        }

        ChainAnalyticsTracker.trackBatchComposePatientDetailViewClick({
            ...analyticsLoggedInProps,
            countAccepted: numberOfContactablePatients,
            isTrustFlow: true,
        });

        setIsShowingPatientDetails(true);
    };

    const handleUseQuestionnaireClick = (
        template: DisplayFloreyMessageTemplate,
    ) => {
        setSelectedQuestionnaire(template);
        setMessageParts({
            header: MESSAGE_HEADER,
            body: template.body,
            signature,
        });
        setIsShowingQuestionnaireList(false);

        toast(
            <Feedback colour="success" title="Questionnaire selected">
                <Text skinny>{template.title}</Text>
            </Feedback>,
        );
    };

    const handleRemoveBatchFloreyQuestionnaireClick = () => {
        setSelectedQuestionnaire(null);
        setMessageParts({
            header: MESSAGE_HEADER,
            body: "",
            signature,
        });
        toast(
            <Feedback colour="success" title="Questionnaire removed">
                <Text skinny>{selectedQuestionnaire?.title}</Text>
            </Feedback>,
        );
    };

    let sendButtonText = `Send to ${numberOfContactablePatients} patient${
        numberOfContactablePatients === 1 ? "" : "s"
    }`;

    if (scheduleDate && scheduleHour) {
        const date = format(scheduleDate, "dd/MM/yyyy");
        const time = getHourDisplayValue(scheduleHour);
        sendButtonText = `Send at ${time} on ${date}`;
    }

    const disableSendButton =
        batchUploadDetailsQuery.status === "loading" ||
        (scheduleOption !== "now" && (!scheduleDate || !scheduleHour));

    return (
        <StyledPageWrapper>
            <StyledRecipientDetails>
                <StyledRecipientDetailsItem gap="1.5">
                    <Text variant="label" skinny>
                        To:
                    </Text>{" "}
                    <StyledPatientPill>
                        <StyledIcon>
                            <Icon name="Team" size={3} />
                        </StyledIcon>
                        {batchUploadDetailsQuery.status === "loading" ? (
                            <SkeletonLabel charCount={10} />
                        ) : (
                            <>
                                <Text variant="label" skinny>
                                    {getRecipientCopy(
                                        numberOfContactablePatients,
                                    )}
                                </Text>
                                {/* m-dot */}
                                &#183;
                                <StyledSeeDetailsButton
                                    onClick={onClickSeePatientDetails}
                                >
                                    {isShowingPatientDetails
                                        ? "Hide details"
                                        : "See details"}
                                </StyledSeeDetailsButton>
                            </>
                        )}
                    </StyledPatientPill>
                </StyledRecipientDetailsItem>
                <StyledRecipientDetailsItem gap="1">
                    <Text variant="label" skinny>
                        Via:
                    </Text>{" "}
                    <StyledDetailsSection>
                        <Text variant="body" skinny>
                            Mobile
                        </Text>
                    </StyledDetailsSection>
                </StyledRecipientDetailsItem>
            </StyledRecipientDetails>
            <ComposeArea
                isOpen
                canUseAttachFiles={selectedQuestionnaire === null}
                onAttachFileClick={() => {
                    ChainAnalyticsTracker.trackBatchComposeAttachClick({
                        ...analyticsLoggedInProps,
                        isTrustFlow: true,
                    });
                }}
                workspaceId={parseInt(workspaceId, 10)}
                messageParts={messageParts}
                handleSendMessage={handleSendMessage}
                isLoading={disableSendButton}
                scheduleButton={
                    <ScheduleButton
                        workspaceId={workspaceId}
                        activeOption={scheduleOption}
                        setActiveOption={(option) => {
                            setScheduleOption(option);
                        }}
                        setScheduleDateAndTime={(date, hour) => {
                            setScheduleDate(date);
                            setScheduleHour(hour);
                        }}
                    />
                }
                sendButtonText={sendButtonText}
                {...(canUseQuestionnaires
                    ? {
                          canUseQuestionnaires: true,
                          displayQuestionnaireResponseBanner: true, // Always display who the questionnaire will be assigned to on response in batch
                          selectedQuestionnaire,
                          unSelectQuestionnaire: () =>
                              setSelectedQuestionnaire(null),
                          questionnaireButton: (
                              <QuestionnaireButton
                                  hasQuestionnaireSelected={
                                      !!selectedQuestionnaire
                                  }
                                  onRemoveQuestionnaireClick={
                                      handleRemoveBatchFloreyQuestionnaireClick
                                  }
                                  onSeeQuestionnairesClick={() =>
                                      setIsShowingQuestionnaireList(true)
                                  }
                              />
                          ),
                      }
                    : { canUseQuestionnaires: false })}
            />
            <QuickViewPortal
                isOpen={isShowingPatientDetails}
                onClose={() => setIsShowingPatientDetails(false)}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={() => setIsShowingPatientDetails(false)}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    <StyledPatientDetailsWrapper flexDirection="column" gap="2">
                        {numberOfContactablePatients && (
                            <Text variant="subtitle" skinny>
                                {getRecipientCopy(numberOfContactablePatients)}{" "}
                                being contacted
                            </Text>
                        )}
                        <PatientDetailsTable patients={patientDetails} />
                    </StyledPatientDetailsWrapper>
                </QuickViewPortal.Body>
            </QuickViewPortal>
            <QuickViewPortal
                isOpen={isShowingQuestionnaireList}
                onClose={() => setIsShowingQuestionnaireList(false)}
            >
                <QuickViewPortal.Header />
                <QuickViewPortal.Body>
                    <BatchQuestionnaireList
                        workspaceId={parseInt(workspaceId)}
                        onUseQuestionnaireClick={handleUseQuestionnaireClick}
                    />
                </QuickViewPortal.Body>
            </QuickViewPortal>
        </StyledPageWrapper>
    );
};
