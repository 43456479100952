/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { LoadingStatus } from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

import { UserType } from "./PracticeUsers.types";

export type PracticeUsersState = {
    loadingStatus: typeof LoadingStatus.Initial;
    approvedUsers: UserType[];
    unapprovedUsers: UserType[];
    archivedUsers: UserType[];
    hasApprovalRights: boolean;
};

export const initialState: PracticeUsersState = {
    loadingStatus: LoadingStatus.Initial,
    approvedUsers: [],
    unapprovedUsers: [],
    archivedUsers: [],
    hasApprovalRights: false,
};

export const reducer = (
    state = initialState,
    action: any,
): PracticeUsersState => {
    switch (action.type) {
        case CoreActions.LOAD_PRACTICE_SETUP:
            return { ...state, loadingStatus: LoadingStatus.Loading };
        case CoreActions.LOAD_PRACTICE_SETUP_FAIL:
            return { ...state, loadingStatus: LoadingStatus.Failed };
        case CoreActions.LOAD_PRACTICE_SETUP_SUCCESS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                approvedUsers: action.approvedUsers,
                unapprovedUsers: action.unapprovedUsers,
                archivedUsers: action.archivedUsers,
                hasApprovalRights: action.hasApprovalRights,
            };
        default:
            return state;
    }
};
