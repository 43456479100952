import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

import {
    SkeletonBody,
    SkeletonSubtitle,
} from "app/sharedComponents/loadingSkeleton/SkeletonText";

const StyledWrapper = styled(Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1",
})`
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const HeaderSkeleton = () => (
    <StyledWrapper>
        <Flex alignItems="center" gap="1">
            <SkeletonBody charCount={16} />
            <SkeletonBody charCount={32} />
        </Flex>
        <Flex alignItems="center" gap="1.5">
            <SkeletonBody charCount={32} />
            <SkeletonSubtitle charCount={16} />
        </Flex>
    </StyledWrapper>
);
