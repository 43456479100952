import { Text } from "@accurx/design";

type PaginationLabelProps = {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    appliedLimit: number;
};

export const PaginationLabel = ({
    totalCount,
    totalPages,
    currentPage,
    appliedLimit,
}: PaginationLabelProps) => {
    const getPaginationResultsLabel = () => {
        if (totalCount === 0) {
            return "No results found";
        } else if (totalCount === 1) {
            return "1 result";
        } else if (totalPages === 1) {
            return `${totalCount} results`;
        } else {
            const currentResultsStart = (currentPage - 1) * appliedLimit + 1;
            const currentResultsEnd = Math.min(
                currentPage * appliedLimit,
                totalCount,
            );
            return `Showing ${currentResultsStart} to ${currentResultsEnd} of ${totalCount} results`;
        }
    };

    return (
        <Text variant="label" skinny>
            {getPaginationResultsLabel()}
        </Text>
    );
};
