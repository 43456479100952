import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledSearchHeaderDiv = styled.div`
    display: block;
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[2]} 0;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[2]} 0;
    }
`;

const StyledTestPatientSwitchContainer = styled.div`
    margin: ${Tokens.SIZES[1.5]} 0 ${Tokens.SIZES[0.5]} 0;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        margin: ${Tokens.SIZES[1.5]} 0;
    }
`;

const StyledSearchBodySpacing = styled.div`
    padding: ${Tokens.SIZES[2]};
`;

export {
    StyledSearchBodySpacing,
    StyledTestPatientSwitchContainer,
    StyledSearchHeaderDiv,
};
