// Action Types
export const SET_EXTERNAL_REFERRER = "SET_EXTERNAL_REFERRER";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SetExternalReferrerAction {
    type: typeof SET_EXTERNAL_REFERRER;
    referrer: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetExternalReferrerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setExternalReferrer: (referrer: string) => ({
        type: SET_EXTERNAL_REFERRER,
        referrer: referrer,
    }),
};
