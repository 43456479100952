import { Text } from "@accurx/design";

import { StyledMessageComposeWrapper } from "app/batchMessage/gp/BatchMessage.styles";
import { COPY_PATIENT_GREETING } from "app/batchMessage/gp/components/compose/BatchMessageCompose.constants";
import { useAppSelector } from "store/hooks";

type MessageComposeWrapperProps = {
    children: React.ReactNode;
};

export const MessageComposeWrapper = (props: MessageComposeWrapperProps) => {
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );

    return (
        <StyledMessageComposeWrapper>
            <Text skinny variant="body" aria-label="message-greeting">
                {COPY_PATIENT_GREETING}
            </Text>

            {props.children}
            <Text
                skinny
                variant="body"
                className="mt-2"
                props={{ "data-testid": "message-practice-name" }}
                aria-label="Practice name signature"
            >
                {practiceName}
            </Text>
        </StyledMessageComposeWrapper>
    );
};
