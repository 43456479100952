import * as UI from "@accurx/design";
import styled from "styled-components";

const { SIZES } = UI.Tokens;

/**
 * The Sidebar has a lot of elements with overflow:hidden to make sure they
 * don't extend outside the vertical height available to them in the Accordion
 * structure. We also have focussable elements that display a 4px focus ring
 * outside of their content-box. When focussable elements are rendered directly
 * inside an element with overflow:hidden their focus ring gets cut off.
 *
 * This element provides a 4px buffer around these elements to leave room for
 * the focus ring.
 */
export const FocusRingSpacing = styled.div`
    padding: ${SIZES[0.5]};
`;
