import { useState } from "react";

import {
    AppointmentChangeDeadline,
    AppointmentReminder,
    AppointmentSupportedChanges,
    AppointmentTypeFilterOption,
} from "@accurx/api/appointment";
import {
    Button,
    Card,
    Cell,
    ErrorSummary,
    Feedback,
    Flex,
    Grid,
    Option,
    Text,
} from "@accurx/design";

import {
    ComposeField,
    PostAppointment,
    SendTimeInput,
    SiteLocationAndSlotType,
    TemplateTypeField,
} from "../../components";
import { useMessageParts } from "../../hooks/useMessageParts";
import { TemplateType } from "../../types";
import { getSupportedChangeLinkType, useFormValidation } from "../../utils";
import { AppointmentTypeFilter, ClinicCodeFilter } from "./components";
import {
    getAppointmentTypeOptions,
    getClinicCodeOptions,
    getExistingCombinations,
    getExistingSiteNamesAndLocationTypes,
    getSiteLocationAndSlotTypeData,
} from "./getFilterOptions";

export type FormValues = {
    selectedSiteNames: string[];
    selectedSlotTypes: Option[];
    selectedAppointmentType: string;
    templateType: TemplateType;
    customMessage: string;
    oneWeekEnabled: boolean;
    threeDaysEnabled: boolean;
    postAppointmentMessageEnabled: boolean;
    postAppointmentMessageBody: string;
};

const defaultInitialValues: FormValues = {
    selectedSiteNames: [],
    selectedSlotTypes: [],
    selectedAppointmentType: "",
    templateType: "FaceToFace",
    customMessage: "",
    oneWeekEnabled: false,
    threeDaysEnabled: false,
    postAppointmentMessageEnabled: false,
    postAppointmentMessageBody: "",
};

type ReminderFormFieldsProps = {
    workspaceName: string;
    existingReminders: AppointmentReminder[];
    siteNames: string[];
    slotTypes: string[];
    appointmentTypes: AppointmentTypeFilterOption[];
    workspaceHasMultipleSites: boolean;
    initialValues?: Partial<FormValues>;
    submitButtonText: string;
    onSubmit(formValues: FormValues): void;
    changeDeadline: AppointmentChangeDeadline;
    supportedChanges: AppointmentSupportedChanges;
    workspaceId: string;
    // Temporary flag to determine which inputs to render
    availableFilters: "site-name-slot-type" | "clinic-appointment-type";

    cancelButton:
        | {
              show: false;
          }
        | {
              show: true;
              onCancel: () => void;
          };
    deleteButton:
        | {
              show: false;
          }
        | {
              show: true;
              onDelete: () => void;
          };

    isError?: boolean;
    error?: { message: string };

    layout: "one-column" | "two-columns";
};

export const ReminderFormFields = ({
    workspaceName,
    existingReminders,
    siteNames,
    slotTypes,
    appointmentTypes,
    workspaceHasMultipleSites,
    initialValues,
    submitButtonText,
    onSubmit,
    changeDeadline,
    supportedChanges,
    availableFilters,
    cancelButton,
    deleteButton,
    isError,
    error,
    layout,
    workspaceId,
}: ReminderFormFieldsProps) => {
    const safeInitialValues = {
        ...defaultInitialValues,
        ...initialValues,
    };

    const { hasValidationErrors, validationErrors, validate, validatePartial } =
        useFormValidation(availableFilters);
    const existingCombinations = getExistingCombinations(existingReminders);
    const existingSiteNamesAndLocationTypes =
        getExistingSiteNamesAndLocationTypes(existingReminders);

    const [selectedAppointmentType, setSelectedAppointmentType] = useState(
        safeInitialValues.selectedAppointmentType,
    );
    const slotTypeOptions = getClinicCodeOptions(
        slotTypes,
        appointmentTypes,
        existingCombinations,
        selectedAppointmentType,
    );
    const [selectedSlotTypes, setSelectedSlotTypes] = useState<Option[]>(
        safeInitialValues.selectedSlotTypes,
    );
    const [selectedSiteNames, setSelectedSiteNames] = useState<string[]>(
        safeInitialValues.selectedSiteNames,
    );

    const appointmentTypeOptions = getAppointmentTypeOptions(
        slotTypes,
        appointmentTypes,
        existingCombinations,
        selectedSlotTypes,
    );

    const [templateType, setTemplateType] = useState(
        safeInitialValues.templateType,
    );
    const [customMessage, setCustomMessage] = useState(
        safeInitialValues.customMessage,
    );
    const [oneWeekEnabled, setOneWeekEnabled] = useState(
        safeInitialValues.oneWeekEnabled,
    );
    const [threeDaysEnabled, setThreeDaysEnabled] = useState(
        safeInitialValues.threeDaysEnabled,
    );
    const [postAppointmentMessageEnabled, setPostAppointmentMessageEnabled] =
        useState(safeInitialValues.postAppointmentMessageEnabled);
    const [postAppointmentMessageBody, setPostAppointmentMessageBody] =
        useState(safeInitialValues.postAppointmentMessageBody);

    const { messageParts, reminderMessage, postAppointment } = useMessageParts({
        templateType,
        customMessage,
        postAppointmentMessageBody,
        workspaceHasMultipleSites,
        workspaceName,
        changeLinkType: getSupportedChangeLinkType(supportedChanges),
        workspaceId: workspaceId.toString(),
    });

    return (
        <>
            {hasValidationErrors && (
                <ErrorSummary
                    isShown // With this prop set to false an empty div is created in elements tree and flex gap is applied to it
                    title="Please review the errors in the form"
                />
            )}
            <Card
                spacing={3}
                header={
                    <Text as="h2" skinny variant="subtitle">
                        Reminder details
                    </Text>
                }
            >
                <Grid
                    gap="1.5"
                    columns={
                        layout === "two-columns"
                            ? {
                                  xs: "1fr",
                                  md: "1fr 1fr",
                              }
                            : "1fr"
                    }
                >
                    {availableFilters === "site-name-slot-type" && (
                        <Cell
                            column={layout === "two-columns" ? "1" : "span 2"}
                        >
                            <SiteLocationAndSlotType
                                allSiteNames={siteNames}
                                allSlotTypes={slotTypes}
                                siteLocationAndSlotTypeData={getSiteLocationAndSlotTypeData(
                                    {
                                        selectedSiteNames,
                                        selectedSlotTypes,
                                        siteNames,
                                        slotTypes,
                                        existingSiteNamesAndLocationTypes,
                                    },
                                )}
                                onUpdateSiteLocationOrSlotTypes={(data) => {
                                    if (data.selectedSiteNames) {
                                        setSelectedSiteNames(
                                            data.selectedSiteNames,
                                        );
                                    }
                                    if (data.selectedSlotTypes) {
                                        setSelectedSlotTypes(
                                            data.selectedSlotTypes.map(
                                                (slotType) => ({
                                                    value: slotType,
                                                    label: slotType,
                                                }),
                                            ),
                                        );
                                    }
                                }}
                                canSiteBeSelected={() => true}
                                siteLocationErrors={
                                    validationErrors.selectedSiteNames ?? []
                                }
                                slotTypeErrors={
                                    validationErrors.selectedSlotTypes ?? []
                                }
                            />
                        </Cell>
                    )}
                    {availableFilters === "clinic-appointment-type" && (
                        <>
                            <Cell
                                column={
                                    layout === "two-columns" ? "1" : "span 2"
                                }
                            >
                                <ClinicCodeFilter
                                    initialOptions={
                                        safeInitialValues.selectedSlotTypes
                                    }
                                    options={slotTypeOptions}
                                    onChange={setSelectedSlotTypes}
                                    errors={validationErrors.selectedSlotTypes}
                                />
                            </Cell>
                            <Cell column="1">
                                <AppointmentTypeFilter
                                    initialOption={appointmentTypeOptions.find(
                                        (option) =>
                                            option.value ===
                                            safeInitialValues.selectedAppointmentType,
                                    )}
                                    options={appointmentTypeOptions}
                                    onChange={(selected) => {
                                        setSelectedAppointmentType(
                                            selected.value,
                                        );
                                    }}
                                    errors={
                                        validationErrors.selectedAppointmentType
                                    }
                                />
                            </Cell>
                        </>
                    )}
                    <Cell column="span 2">
                        <TemplateTypeField
                            value={templateType}
                            onChange={(e) => {
                                setTemplateType(e.target.value as TemplateType);
                            }}
                        />
                    </Cell>
                    <Cell column="span 2">
                        <ComposeField
                            messageParts={messageParts}
                            charAndFragmentInfo={
                                reminderMessage.charAndFragmentInfo
                            }
                            customMessage={customMessage}
                            onCustomMessageChange={(e) => {
                                const newMessage = e.target.value;
                                setCustomMessage(newMessage);
                                validatePartial({
                                    reminderMessageCharacterCount:
                                        reminderMessage.getFullMessageLength(
                                            newMessage,
                                        ),
                                });
                            }}
                            validationErrors={
                                validationErrors.reminderMessageCharacterCount
                            }
                        />
                    </Cell>
                </Grid>
            </Card>
            <SendTimeInput
                oneWeekReminderEnabled={oneWeekEnabled}
                toggleOneWeekReminderEnabled={() =>
                    setOneWeekEnabled((prevValue) => !prevValue)
                }
                threeWorkingDayReminderEnabled={threeDaysEnabled}
                toggleThreeDaysReminderEnabled={() =>
                    setThreeDaysEnabled((prevValue) => !prevValue)
                }
                changeDeadline={changeDeadline}
                supportedChanges={supportedChanges}
                limitOptionsWidth={layout === "two-columns"}
                spacing={3}
            />
            <PostAppointment
                formData={{
                    postAppointmentCharCount: postAppointment.characterCount,
                    postAppointmentMessageBody,
                    postAppointmentMessageEnabled,
                }}
                validationErrors={[
                    // When we validate post-appointment message we check that
                    // the message body (excluding greeting and signature) is not an empty string
                    // and at the same time combined length of the post-appointment message
                    // should be less than two SMS fragments
                    ...(validationErrors.postAppointmentMessageBody || []),
                    ...(validationErrors.postAppointmentMessageCharacterCount ||
                        []),
                ]}
                onToggle={() =>
                    setPostAppointmentMessageEnabled((prevValue) => !prevValue)
                }
                onMessageInput={({ target: { value } }) => {
                    setPostAppointmentMessageBody(value);
                    validatePartial({
                        postAppointmentMessageCharacterCount:
                            postAppointment.getFullMessageLength(value),
                        postAppointmentMessageBody: value,
                    });
                }}
                messageParts={messageParts}
                charAndFragmentInfo={postAppointment.charAndFragmentInfo}
                spacing={3}
            />

            {isError && error && (
                <Feedback colour="error" title={error.message} />
            )}

            <Flex
                justifyContent={
                    deleteButton.show ? "space-between" : "flex-end"
                }
                gap="2"
            >
                {deleteButton.show && (
                    <Button
                        type="button"
                        theme="secondary"
                        text="Delete reminder"
                        style={{ color: "red", whiteSpace: "nowrap" }}
                        onClick={deleteButton.onDelete}
                    />
                )}

                <Flex justifyContent="flex-end" gap="2">
                    {cancelButton.show && (
                        <Button
                            type="button"
                            theme="secondary"
                            text="Cancel"
                            onClick={cancelButton.onCancel}
                        />
                    )}

                    <Button
                        type="submit"
                        text={submitButtonText}
                        onClick={(e) => {
                            // prevent default form behaviour
                            e.preventDefault();

                            const formValues = {
                                selectedSiteNames,
                                selectedSlotTypes,
                                selectedAppointmentType,
                                templateType,
                                customMessage,
                                reminderMessageCharacterCount:
                                    reminderMessage.characterCount,
                                threeDaysEnabled,
                                oneWeekEnabled,
                                postAppointmentMessageEnabled,
                                postAppointmentMessageBody,
                                postAppointmentMessageCharacterCount:
                                    postAppointment.characterCount,
                            };

                            const isValid = validate(formValues).success;

                            if (isValid) {
                                onSubmit(formValues);
                            }
                        }}
                    />
                </Flex>
            </Flex>
        </>
    );
};
