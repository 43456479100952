import React, { useEffect } from "react";

import { Link, StackPanel, Text } from "@accurx/design";

import { routeSummary } from "app/practices/floreyBuilder/constants/paths";

import { trackManageTemplatePageView } from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledTabPanelLayout } from "./ManageTemplates.styles";
import { TabIds } from "./ManageTemplatesOverview";

type OrganisationFloreysPanelProps = {
    orgId: string;
};
export const OrganisationFloreysPanel = ({
    orgId,
}: OrganisationFloreysPanelProps): JSX.Element => {
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: 0, //there are no custom florey categories
            currentTab: TabIds.OrganisationFloreys,
            productOrigin: "PatientMessage",
        });
    }, [flemingLoggedInProperties]);
    return (
        <>
            <StyledTabPanelLayout>
                <StackPanel gutter={3} orientation="vertical">
                    <Text skinny>
                        Florey questionnaires for your organisation that can
                        send via Batch Messaging or individual text message.
                    </Text>
                    <Link
                        href={routeSummary(orgId)}
                        text="Go to Custom Florey Questionnaires"
                    />
                </StackPanel>
            </StyledTabPanelLayout>
        </>
    );
};
