import React from "react";

import { Button, Feedback, Text } from "@accurx/design";

export const ConversationGroupPageError = ({
    errorMessage,
    retryFetch,
}: {
    errorMessage: string;
    retryFetch?: () => Promise<void>;
}): JSX.Element => {
    return (
        <Feedback colour="error" title="Failed to load" iconName="Failed">
            <Text>{errorMessage}</Text>
            {retryFetch && (
                <Text skinny>
                    <Button text="Try again" onClick={retryFetch} />
                </Text>
            )}
        </Feedback>
    );
};
