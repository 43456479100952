import { PatientThreadWriteToRecordState } from "@accurx/api/ticket";
import { SaveToRecordState } from "domains/concierge/types";

const getSaveToRecordStatus = (
    writeToRecordAttemptEvents: NonNullable<
        PatientThreadWriteToRecordState["writeToRecordAttemptEvents"]
    >,
): SaveToRecordState["status"] => {
    // start in a not saved state
    let status: SaveToRecordState["status"] = "NotSaved";

    for (const attempt of writeToRecordAttemptEvents) {
        // If there are any successful save attempts then we consider the item
        // saved and we can stop looking
        if (attempt.writeSuccess) {
            status = "Saved";
            break;
        } else {
            // We know that there has been at least one failed attempt so set it
            // to failed. If there are any successful attempts that appear later
            // in the list this will be overwritten.
            status = "Failed";
        }
    }

    return status;
};

export const mapWriteToRecordState = (
    state: PatientThreadWriteToRecordState | null | undefined,
): SaveToRecordState => {
    if (!state?.writeToRecordAttemptEvents) {
        return { status: "NotSaved", saveEvents: [] };
    }

    const status = getSaveToRecordStatus(state.writeToRecordAttemptEvents);

    const saveEvents: SaveToRecordState["saveEvents"] =
        state.writeToRecordAttemptEvents.map((wtrEvent) => {
            return {
                savedAt: wtrEvent.writeTime,
                savedByUserId: wtrEvent.userId ?? null,
                saveSuccess: wtrEvent.writeSuccess,
            };
        });

    return { status, saveEvents };
};
