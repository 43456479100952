import {
    ConversationIdentity,
    ConversationUpdate,
} from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationGroupPage,
    ConversationGroupRuleset,
    ConversationGroupSortOptions,
} from "shared/concierge/conversations/types/conversationGroup.types";
import { InitialSummary } from "shared/concierge/conversations/types/initialSummary.types";

import { ConversationActions } from "./ConversationActions";
import * as TicketWithTokenApiClient from "./TicketWithTokenApiClient";
import {
    parseUniqueConversationId,
    parseUniqueNoteId,
} from "./mappers/ConversationMapper";
import { PatientThreadTicketFolder, View } from "./types/dto.types";

/**
 * Determines which of the two patient profile conversation groups
 * should be fetched by the getConversationGroup action
 *
 * - [user]: will require a userId to be passed,
 *      fetching a conversation group will result
 *      in getting the conversations where the user and patient
 *      were involved
 * - [workspace]: fetching a conversation group will result in
 *      getting all the conversations where the patient was involved
 *      for the given workspace
 * - [none]: no conversation group fetches will be allowed
 */
export type SearchedPatientConversationGroupActionScope =
    | {
          type: "workspace";
      }
    | {
          type: "user";
          userId: string;
      };

class SearchedPatientConversationActions extends ConversationActions {
    constructor(
        protected readonly workspaceId: number,
        private readonly patientToken: string,
        private readonly groupScope?: SearchedPatientConversationGroupActionScope,
    ) {
        super(workspaceId);
    }

    async markItemAsRead(conversationItemId: string): Promise<void> {
        const itemId = parseUniqueNoteId(conversationItemId);

        if (!itemId) {
            throw new Error(
                `Unable to mark conversation item as read - cannot parse conversation item ID ${conversationItemId}.`,
            );
        }

        const apiResponse = await TicketWithTokenApiClient.markNoteRead(
            this.workspaceId,
            this.patientToken,
            [itemId],
        );

        this.mapAndPublishTicketCommandResultUpdates(apiResponse);
    }

    assign(): Promise<ConversationIdentity> {
        return Promise.reject("Method not implemented");
    }

    matchPatientToConversation(): Promise<ConversationIdentity> {
        return Promise.reject("Method not implemented");
    }

    getInitialSummary(): Promise<InitialSummary> {
        return Promise.reject("Method not implemented");
    }

    getAllUnreadItems(): Promise<ConversationUpdate[]> {
        return Promise.reject("Method not implemented");
    }

    async getConversation(
        conversationIdentity: ConversationIdentity,
    ): Promise<void> {
        const ticketIdentity = parseUniqueConversationId(
            conversationIdentity.id,
        );

        if (!ticketIdentity) {
            throw new Error(
                "Unable to convert conversation ID to a ticket identity.",
            );
        }

        const apiResponse = await TicketWithTokenApiClient.fetchTicket(
            this.workspaceId,
            ticketIdentity,
            this.patientToken,
        );

        this.mapAndPublishPatientThreadTicket(apiResponse);
    }

    async getConversationGroup(
        ruleset: ConversationGroupRuleset,
        sortOptions: ConversationGroupSortOptions,
        continuationToken?: string,
    ): Promise<Pick<ConversationGroupPage, "apiContinuation">> {
        if (this.groupScope === undefined) {
            throw new Error(
                "Cannot fetch conversation groups without a scope set",
            );
        }

        let folder: PatientThreadTicketFolder;

        switch (this.groupScope.type) {
            case "user":
                folder = {
                    viewType: View.UserInboxMentionsAndSent,
                    userId: this.groupScope.userId,
                };
                break;

            case "workspace":
                folder = {
                    viewType: View.PracticeInboxAndSent,
                };
                break;

            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustive: never = this.groupScope;

                throw new Error(
                    "Cannot fetch conversation groups as groupScope cannot be mapped to folder view",
                );
        }

        const apiResult =
            await TicketWithTokenApiClient.fetchPatientFolderViewWithToken(
                this.workspaceId,
                folder,
                this.patientToken,
                continuationToken,
            );

        return this.mapAndPublishTicketViewUpdates(ruleset, apiResult);
    }
}

/**
 * Creates a new conversation actions instance.
 *
 * We prefer to use a factory function over directly instantiating
 * classes because it's easier to mock functions than classes in tests.
 */
export const createSearchedPatientConversationActions = (
    ...args: ConstructorParameters<typeof SearchedPatientConversationActions>
): ConversationActions => {
    return new SearchedPatientConversationActions(...args);
};
