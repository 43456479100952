import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledBookingInvitePopoverContent = styled(UI.PopoverContent)`
    padding: 0;

    div:has(> p) {
        margin-left: 0;
    }
`;

export const StyledBookingInvitePopoverHeader = styled.li`
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    border-bottom: ${UI.Tokens.BORDERS.normal.silver};
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};
`;
