import { Tokens } from "@accurx/design";
import styled from "styled-components";
import { SIZES } from "styles/shared/sizes";

const StyledSecondaryNavigation = styled.nav`
    width: ${SIZES.NAVIGATION_MAIN_CONTENT_WIDTH};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 ${Tokens.SIZES[2]};
`;

const StyledLinkList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: ${Tokens.SIZES[1.5]} 0 0 0;
    > *:not(:last-child) {
        margin-bottom: ${Tokens.SIZES[1.5]};
    }
`;

const StyledPatientLinkList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: 100%;
    > * {
        margin: ${Tokens.SIZES[0.5]} 0 0 ${Tokens.SIZES[1.5]};
    }
`;

// temporary styling for conversation group secondary nav
const StyledConversationGroupLinkList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0 0 ${Tokens.SIZES[1.5]} 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    > * {
        margin: ${Tokens.SIZES[0.5]} 0 0 ${Tokens.SIZES[1.5]};
    }
`;

const StyledContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const StyledFooterUserDetailsContainer = styled.div`
    padding-top: ${Tokens.SIZES[1.5]};
    padding-bottom: ${Tokens.SIZES[1]};
    padding-left: ${Tokens.SIZES[1.5]};
    padding-right: ${Tokens.SIZES[1.5]};
`;

const StyledPendingApprovalContainer = styled.div`
    padding: 0 ${Tokens.SIZES[1.5]} ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
`;

export {
    StyledSecondaryNavigation,
    StyledPatientLinkList,
    StyledConversationGroupLinkList,
    StyledLinkList,
    StyledContainer,
    StyledFooterUserDetailsContainer,
    StyledPendingApprovalContainer,
};
