import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { QuestionnaireResponse } from "@accurx/api/florey-builder";

import { getQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";

export const GET_QUESTIONNAIRE_CACHE_KEY = "GetQuestionnaire";

type GetQuestionnaireQueryVariables = {
    workspaceId: number;
    questionnaireId: string;
};

export const useGetQuestionnaireQuery = createQueryHook<
    GetQuestionnaireQueryVariables,
    QuestionnaireResponse
>(({ workspaceId, questionnaireId }) => ({
    queryKey: [GET_QUESTIONNAIRE_CACHE_KEY, { workspaceId, questionnaireId }],
    queryFn: async () => {
        const response = await getQuestionnaire(
            workspaceId.toString(),
            questionnaireId,
        );
        return returnDataOrThrow(response);
    },
    refetchOnMount: "always",
}));
