import { useToAssignTriageConversationCount } from "@accurx/concierge/hooks/data/conversationGroupConversationCount/useToAssignTriageConversationCount";

export type ConversationCountData =
    | { status: "Loading" }
    | { status: "Success"; count: number; isFullyLoaded: boolean };

type Props = {
    children: (arg: ConversationCountData) => JSX.Element;
};

const UnassignedAdmin = (props: Props & { teamId: string }) => {
    const data = useToAssignTriageConversationCount({
        triageType: "Admin",
        teamId: props.teamId,
        status: "Open",
    });

    if (!data) {
        return props.children({ status: "Loading" });
    }

    return props.children({
        status: "Success",
        count: data.count,
        isFullyLoaded: data.isFullyLoaded,
    });
};

const UnassignedMedical = (props: Props & { teamId: string }) => {
    const data = useToAssignTriageConversationCount({
        triageType: "Medical",
        teamId: props.teamId,
        status: "Open",
    });

    if (!data) {
        return props.children({ status: "Loading" });
    }

    return props.children({
        status: "Success",
        count: data.count,
        isFullyLoaded: data.isFullyLoaded,
    });
};

export const ConversationCountProvider = {
    UnassignedAdmin,
    UnassignedMedical,
};
