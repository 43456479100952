import * as UI from "@accurx/design";
import { colors } from "domains/inbox/theme";
import styled from "styled-components";

const { SIZES } = UI.Tokens;

export const FolderListLabel = styled(UI.Text)`
    padding: ${SIZES[1.5]} 0 ${SIZES[0.5]} ${SIZES[1]};
    color: ${colors.zinc.tint70};
    text-transform: uppercase;
    font-size: 0.6875rem;
    font-weight: 600;
    margin: 0;
`;
