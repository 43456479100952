import { bulletedList, placeholder, trimStart } from "./templates.format";

const ipDeclaration = trimStart(
    `
     
     Community Pharmacist Independent Prescriber Declaration:
     Where prescribing has occurred a patient centred consultation has taken place to discuss treatment options in line with current guidelines including discussion of the risks and benefits and taking into consideration the following:
     ${bulletedList(
         "Licensed indications & therapeutic class",
         "Dose, route of administration and duration of treatment",
         "Adverse effects and management of adverse effects",
         "Allergy check",
         "Cautions and contra-indications",
         "Monitoring requirements",
         "Clinically important drug interactions and their management",
     )}
     
     Community Pharmacist IP name and GPhC number:
     Pharmacy name & location:`,
);

const extendedMinorIllness = trimStart(
    `I have just consulted with your patient as part of our NHS Community Pharmacy Independent Prescribing (IP) Pathfinder Programme for Extended Minor Illness.
     
     Actions required by practice:
     ${placeholder("delete as appropriate")}
     ${bulletedList(
         "Update patient clinical record",
         "Add prescribing details to patients record (NB not to be added to active list of medicines, just a record of supply by community pharmacist)",
     )}
     
     Consultation summary:
     Presenting complaint:
     ${placeholder("complete or delete as appropriate")}
     ${bulletedList(
         "Advice only",
         "Advice plus OTC sale",
         `Advice plus prescription. Medication supplied: ${placeholder(
             "medication name and dose",
         )}`,
         "Additional action taken:",
     )}
     
     Attached: copy of Community Pharmacy IP Pathfinder Programme Extended Minor Illness record. Please contact us if you would like a full copy of the PharmOutcomes clinical consultation record.${ipDeclaration}`,
);

const hyptertension = trimStart(
    `I have just consulted with your patient as part of our NHS Community Pharmacy Independent Prescribing (IP) Pathfinder Programme for Hypertension Management.
     
     Actions required by practice:
     ${placeholder("delete as appropriate")}
     ${bulletedList(
         "Update the patient's clinical record",
         "Add prescribing details to patients record (as a record of supply by community pharmacist, do not add to active list of medicines)",
         "Add hypertensive medication to repeat prescriptions and resume usual care for hypertension management",
     )}
     
     Consultation summary: 
     ${placeholder("complete or delete as appropriate")}
     ${bulletedList(
         "Advice only, no medication changes",
         `Medication initiated ${placeholder("medication name and dose")}`,
         `Medication changes ${placeholder("medication name and dose")}`,
     )}
     Additional actions taken:
     ${bulletedList(
         "Patient BP controlled and being handed back to usual care by GP with antihypertensives added to repeat prescriptions",
         "Patient's BP remains uncontrolled/complex patient handed over to care of GP (following discussion with GP/clinical supervisor)",
     )}
     
     Attached: copy of Community Pharmacy IP Pathfinder Programme Hypertension consultation record. Please contact us if you would like a full copy of the PharmOutcomes clinical consultation record.${ipDeclaration}`,
);

const contraception = trimStart(
    `I have just consulted with your patient as part of our NHS Community Pharmacy Independent Prescribing (IP) Pathfinder Programme for Contraception.
     
     Actions required by practice:
     ${placeholder("delete as appropriate")}
     ${bulletedList(
         "Update the patient's clinical record with this information",
         "Add prescribing details to patients record (as a record of supply by community pharmacist, do not add to active list of medicines)",
         "Add contraceptive medication to repeat prescriptions and resume usual care",
     )}
     
     Consultation summary: 
     ${placeholder("complete or delete as appropriate")}
     ${bulletedList(
         "Advice only, no medication changes",
         `Medication initiated ${placeholder("medication name and dose")}`,
         `Medication changes ${placeholder("medication name and dose")}`,
         "Additional actions taken:",
         "BP: ",
         "BMI: ",
     )}
     
     Attached: copy of Community Pharmacy IP Pathfinder Programme Contraception consultation record. Please contact us if you would like a full copy of the PharmOutcomes clinical consultation record.${ipDeclaration}`,
);

export const ipPathfinderTemplates = {
    extendedMinorIllness,
    hyptertension,
    contraception,
};
