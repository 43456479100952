import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual } from "react-redux";

import { useAppSelector } from "store/hooks";

import { ComposeForm } from "./ComposeForm";

export const AppointmentReminderComposePage = (): JSX.Element => {
    useAccurxWebTitle("Create an appointment reminder");

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
        shallowEqual,
    );
    const practiceName = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x) => x.id.toString() === practices.selectedPractice,
            )?.name,
        shallowEqual,
    );

    const appointmentReminders = useAppSelector(
        ({ appointmentReminders }) => appointmentReminders.appointmentReminders,
        shallowEqual,
    );

    const slotTypes = useAppSelector(
        ({ appointmentReminders }) => appointmentReminders.slotTypes,
        shallowEqual,
    );

    const siteNames = useAppSelector(
        ({ appointmentReminders }) => appointmentReminders.siteNames,
        shallowEqual,
    );

    const selectedAppointmentReminder = useAppSelector(
        ({ appointmentReminders }) =>
            appointmentReminders.selectedAppointmentReminder,
        shallowEqual,
    );

    return (
        <ComposeForm
            workspaceId={practiceId}
            workspaceName={practiceName}
            appointmentReminders={appointmentReminders}
            slotTypes={slotTypes}
            siteNames={siteNames}
            selectedAppointmentReminder={selectedAppointmentReminder}
        />
    );
};

export default AppointmentReminderComposePage;
