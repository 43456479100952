import { useEffect, useRef } from "react";

import { Log } from "@accurx/shared";

const suffix = "Accurx" as const;
type Title = `${string} | ${typeof suffix}` | typeof suffix;

export const useAccurxWebTitle = (title: string): void => {
    const defaultTitle = useRef<Title>(suffix);

    let accurxWebTitle: Title = defaultTitle.current;

    if (isEmptyString(title)) {
        const message = `useAccurxWebTitle(): Unexpected empty doc title supplied.

Web pages having unique titles is an accessibility requirement, so please supply
a title that succinctly describes what the page is for, or what the user can do
from it.

For more details, refer to the WCAG guidelines

    https://www.w3.org/WAI/WCAG21/Understanding/page-titled.html`;

        Log.warn(message);
    } else {
        accurxWebTitle = `${title} | ${suffix}`;
    }

    useEffect(() => {
        const defaultValue = defaultTitle.current;
        if (document.title !== accurxWebTitle) {
            document.title = accurxWebTitle;
        }

        return () => {
            if (document.title !== defaultValue) {
                document.title = defaultValue;
            }
        };
    }, [accurxWebTitle]);
};

type EmptyString = never;
function isEmptyString(str: string): str is EmptyString {
    return Boolean(str) === false || str.length < 1;
}
