import { Flex, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPatientDetailsHeader = styled(Flex)`
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1; // Needed due to the transform styles being set on our Icon component. More info here: https://stackoverflow.com/questions/20851452/z-index-is-canceled-by-setting-transformrotate
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    border-bottom: 1px ${Tokens.COLOURS.greyscale.silver} solid;
`;

export const StyledWrapper = styled.div`
    margin: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
`;

export const StyledFloreyResponse = styled.div`
    border-radius: ${Tokens.BORDERS.radius};
    box-sizing: border-box;
    padding: ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.primary.blue[10]};
    border: ${Tokens.BORDERS.soft.blue};
    white-space: pre-line;
`;

export const StyledAlignedText = styled(Text)`
    align-self: end;
`;

export const StyledListItem = styled.li`
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
`;
