import { useEffect, useState } from "react";

import { useWindowSize } from "domains/inbox/hooks/util/useWindowSize";

export const useIsEllipsisActive = (element: HTMLElement | null) => {
    const size = useWindowSize();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (element) {
            // We use getBoundingClientRect to get a more accurate calculation
            const clientWidth = Math.round(
                element.getBoundingClientRect().width,
            );
            const scrollWidth = element.scrollWidth;
            const active = clientWidth < scrollWidth;

            setIsActive(active);
        }
    }, [element, size.width]);

    return isActive;
};
