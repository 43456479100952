import { Tokens } from "@accurx/design";
import styled from "styled-components";

const sharedMobileHeaderStyles = `
box-shadow: inset 0px -${Tokens.SIZES[0.25]} 0px ${Tokens.COLOURS.greyscale.silver};
padding: ${Tokens.SIZES[2]};
display: flex;
max-width: 100vw;
overflow-x: auto;
background-color: ${Tokens.COLOURS.greyscale.white};
`;

const StyledMobileNavigationMenu = styled.nav`
    ${sharedMobileHeaderStyles}
`;
const StyledMobileHeaderWithoutNavigation = styled.h1`
    ${sharedMobileHeaderStyles}
`;

const StyledFullAccurxLogo = styled.img`
    margin: ${Tokens.SIZES[1.5]};
`;

export {
    StyledMobileNavigationMenu,
    StyledFullAccurxLogo,
    StyledMobileHeaderWithoutNavigation,
};
