import { QuestionType } from "@accurx/api/florey-builder";
import { Button } from "@accurx/design";

import { CardWithTitle } from "app/sharedComponents/cardWithTitle/CardWithTitle";
import {
    CardErrorRefType,
    SaveableCard,
    ViewStatus,
} from "app/sharedComponents/saveableCard/SaveableCard";

import { ClientSideQuestion } from "../../types/context.types";
import {
    BranchId,
    BranchPosition,
    CancelQuestionProps,
    CardsByKey,
    Errors,
    QuestionEditProps,
    QuestionLevel,
    QuestionnaireId,
    QuestionsPageError,
    SaveQuestionProps,
    SelectedQuestionProps,
} from "../../types/data.types";
import { AddQuestionToBranchButton } from "../AddQuestionToBranch/AddQuestionToBranch";
import { HandleCancelMeasurementTypeSelectorCard } from "../MeasurementTypeSelector.types";
import { StyledQuestionIndent } from "../PageContainer.styles";
import QuestionEdit from "../QuestionEdit";
import QuestionPreview from "../QuestionPreview";
import {
    HandleCloseQuestionSelectCard,
    HandleSaveMeasurmentType,
    HandleSelectQuestionType,
} from "../QuestionTypeSelector.types";
import { Questions } from "../Questions/Questions";
import * as helpers from "./Question.helpers";
import { generateCardKey } from "./Question.helpers";

export type QuestionPropsFromHook = {
    activeCardErrorRef: CardErrorRefType;
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    cardsByKey: CardsByKey;
    handleCancelQuestion: ({
        analyticsQuestionType,
        branchPosition,
        questionLevel,
    }: CancelQuestionProps) => void;
    handleQuestionEditChanges: ({
        branchId,
        branchPosition,
        cardKey,
        questionLevel,
    }: QuestionEditProps) => boolean;
    handleRemoveQuestionModal: ({
        branchId,
        branchPosition,
        cardKey,
        questionId,
        questionRowVersion,
        questionType,
    }: SelectedQuestionProps) => void;
    handleSaveQuestion: ({
        branchId,
        branchPosition,
        question,
        questionLevel,
    }: SaveQuestionProps) => Promise<boolean>;
    questionLevel: QuestionLevel;
    questionnaireErrors: QuestionsPageError[];
    questionnaireId: QuestionnaireId;
    validationErrorsByKey: Errors;
    handleSelectQuestionType: ({
        branchPosition,
        questionLevel,
        questionType,
    }: HandleSelectQuestionType) => void;

    handleCloseQuestionSelectCard: ({
        branchPosition,
        questionLevel,
    }: HandleCloseQuestionSelectCard) => void;
    handleCancelMeasurementTypeSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSaveMeasurementTypeSelectorCard: ({
        event,
        branchPosition,
        questionLevel,
        branchId,
    }: HandleSaveMeasurmentType) => Promise<boolean>;
    handleCancelPhotoUploadSelectorCard: ({
        questionLevel,
    }: HandleCancelMeasurementTypeSelectorCard) => void;
    handleSavePhotoUploadSelectorCard: ({
        branchPosition,
        questionLevel,
        branchId,
    }: Omit<HandleSaveMeasurmentType, "event">) => Promise<boolean>;
};
type QuestionProps = {
    question: ClientSideQuestion;
    questionIndex: number;
};
export const Question = ({
    activeCardErrorRef,
    branchId,
    branchPosition,
    cardsByKey,
    handleCancelMeasurementTypeSelectorCard,
    handleCancelPhotoUploadSelectorCard,
    handleCancelQuestion,
    handleCloseQuestionSelectCard,
    handleQuestionEditChanges,
    handleRemoveQuestionModal,
    handleSaveMeasurementTypeSelectorCard,
    handleSavePhotoUploadSelectorCard,
    handleSaveQuestion,
    handleSelectQuestionType,
    question,
    questionIndex,
    questionLevel,
    questionnaireErrors,
    questionnaireId,
    validationErrorsByKey,
}: QuestionPropsFromHook & QuestionProps): JSX.Element | null => {
    const card = cardsByKey[generateCardKey(question.clientId)];
    const questionId = question.id;
    const questionRowVersion = question.questionRowVersion;
    const cardKey = helpers.generateCardKey(question.clientId);

    const cardActionErrors: string[] = questionnaireErrors
        .filter((error: QuestionsPageError) => error.id.includes(cardKey))
        .map((error: QuestionsPageError) => error.message);

    switch (question.questionType) {
        case QuestionType.Numbered:
            return (
                <CardWithTitle
                    key={cardKey}
                    cardTitle={
                        `Q${
                            questionIndex + 1
                        }. ${helpers.getQuestionCardHeading(
                            question.questionType,
                            question.questionTemplateType,
                            question.allowMultipleAnswers,
                        )}` || ""
                    }
                    children={
                        <QuestionPreview
                            keyId={`${question.clientId}-preview-item`}
                            question={question}
                        />
                    }
                    cardFooter={
                        <Button
                            text="Remove question"
                            theme="secondary"
                            onClick={() =>
                                handleRemoveQuestionModal({
                                    branchId,
                                    branchPosition,
                                    cardKey,
                                    questionId,
                                    questionLevel,
                                    questionRowVersion,
                                    questionType: QuestionType.Numbered,
                                })
                            }
                        />
                    }
                />
            );
        // Cases where we add a single question (not using a template)
        case QuestionType.MultipleChoice:
        case QuestionType.Text:
        case QuestionType.Information:
            return (
                <SaveableCard
                    key={cardKey}
                    cardTitle={
                        `Q${
                            questionIndex + 1
                        }. ${helpers.getQuestionCardHeading(
                            question.questionType,
                            question.questionTemplateType,
                            question.allowMultipleAnswers,
                        )}` || ""
                    }
                    editChildren={
                        <QuestionEdit
                            key={`${question.clientId}-edit`}
                            question={question}
                            questionIndex={questionIndex}
                            questionnaireId={questionnaireId}
                            errors={validationErrorsByKey}
                            questionLevel={questionLevel}
                            branchPosition={branchPosition}
                        />
                    }
                    initialChildren={
                        <QuestionEdit
                            key={`${question.clientId}-init`}
                            question={question}
                            questionIndex={questionIndex}
                            questionnaireId={questionnaireId}
                            errors={validationErrorsByKey}
                            questionLevel={questionLevel}
                            branchPosition={branchPosition}
                        />
                    }
                    previewChildren={
                        <QuestionPreview
                            keyId={`${question.clientId}-preview-item`}
                            question={question}
                        />
                    }
                    additionalActions={
                        <Button
                            text="Remove question"
                            theme="secondary"
                            onClick={() =>
                                handleRemoveQuestionModal({
                                    branchId,
                                    branchPosition,
                                    cardKey,
                                    questionId,
                                    questionLevel,
                                    questionRowVersion,
                                    questionType: question.questionType,
                                })
                            }
                        />
                    }
                    handleCancelChanges={() =>
                        handleCancelQuestion({
                            analyticsQuestionType:
                                helpers.mapQuestionTypeToString(
                                    question.questionType,
                                ),
                            branchPosition,
                            questionLevel,
                            clientId: question.clientId,
                        })
                    }
                    handleSaveChanges={() =>
                        handleSaveQuestion({
                            branchId,
                            branchPosition,
                            question,
                            questionIndex,
                            questionLevel,
                        })
                    }
                    isSaving={card.isSaving}
                    handleEditChanges={() =>
                        handleQuestionEditChanges({
                            cardKey,
                            branchPosition,
                            questionLevel,
                        })
                    }
                    viewStatus={question.viewStatus || ViewStatus.Preview}
                    cardActionErrorRef={activeCardErrorRef}
                    cardActionErrors={cardActionErrors}
                    cardKey={cardKey}
                />
            );
        case QuestionType.Branching:
            // Should define branchId passed down as question.id
            // Should define branchPosition passed down as the questionIndex
            return (
                <>
                    <SaveableCard
                        key={cardKey}
                        cardTitle="Branch"
                        editChildren={
                            <QuestionEdit
                                key={`${question.clientId}-edit`}
                                question={question}
                                questionIndex={questionIndex}
                                questionnaireId={questionnaireId}
                                errors={validationErrorsByKey}
                                questionLevel={questionLevel}
                                branchPosition={questionIndex} // Should define branchPosition passed down as the questionIndex
                            />
                        }
                        initialChildren={
                            <QuestionEdit
                                key={`${question.clientId}-init`}
                                question={question}
                                questionIndex={questionIndex}
                                questionnaireId={questionnaireId}
                                errors={validationErrorsByKey}
                                questionLevel={questionLevel}
                                branchPosition={questionIndex} // Should define branchPosition passed down as the questionIndex
                            />
                        }
                        previewChildren={
                            <QuestionPreview
                                keyId={`${question.clientId}-preview-item`}
                                question={question}
                            />
                        }
                        handleCancelChanges={() =>
                            handleCancelQuestion({
                                analyticsQuestionType:
                                    helpers.mapQuestionTypeToString(
                                        QuestionType.Branching,
                                    ),
                                branchPosition,
                                questionLevel,
                                clientId: question.clientId,
                            })
                        }
                        handleSaveChanges={() =>
                            handleSaveQuestion({
                                branchId,
                                questionIndex,
                                branchPosition,
                                question,
                                questionLevel,
                            })
                        }
                        isSaving={card.isSaving}
                        handleEditChanges={() =>
                            handleQuestionEditChanges({
                                cardKey,
                                questionLevel,
                                branchPosition,
                            })
                        }
                        viewStatus={question.viewStatus || ViewStatus.Preview}
                        cardActionErrorRef={activeCardErrorRef}
                        cardActionErrors={cardActionErrors}
                        additionalActions={
                            <Button
                                text="Remove branch"
                                theme="secondary"
                                onClick={() =>
                                    handleRemoveQuestionModal({
                                        branchId,
                                        branchPosition,
                                        cardKey,
                                        questionId,
                                        questionLevel,
                                        questionRowVersion,
                                        questionType: QuestionType.Branching,
                                    })
                                }
                            />
                        }
                        cardKey={cardKey}
                    />
                    <StyledQuestionIndent gap="2">
                        <Questions
                            activeCardErrorRef={activeCardErrorRef}
                            branchId={question.id}
                            branchPosition={questionIndex} // Should define branchPosition passed down as the questionIndex
                            cardsByKey={
                                question.questionsData?.cardsByKey ?? {}
                            }
                            handleCancelMeasurementTypeSelectorCard={
                                handleCancelMeasurementTypeSelectorCard
                            }
                            handleCancelPhotoUploadSelectorCard={
                                handleCancelPhotoUploadSelectorCard
                            }
                            handleCancelQuestion={(props) =>
                                handleCancelQuestion({
                                    ...props,
                                    // pass all props but override branchPosition
                                    branchPosition: questionIndex,
                                })
                            }
                            handleCloseQuestionSelectCard={(props) =>
                                handleCloseQuestionSelectCard({
                                    ...props,
                                    // pass all props but override branchPosition
                                    branchPosition: questionIndex,
                                })
                            }
                            handleQuestionEditChanges={(props) =>
                                handleQuestionEditChanges({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                })
                            }
                            handleRemoveQuestionModal={(props) => {
                                handleRemoveQuestionModal({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                });
                            }}
                            handleSaveMeasurementTypeSelectorCard={(props) =>
                                handleSaveMeasurementTypeSelectorCard({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                })
                            }
                            handleSavePhotoUploadSelectorCard={(props) =>
                                handleSavePhotoUploadSelectorCard({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                })
                            }
                            handleSaveQuestion={(props) =>
                                handleSaveQuestion({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                })
                            }
                            handleSelectQuestionType={(props) =>
                                handleSelectQuestionType({
                                    ...props,
                                    // pass all props but override branchId and branchPosition
                                    branchId: question.id,
                                    branchPosition: questionIndex,
                                })
                            }
                            questionLevel="Branch" /* TODO: add test to ensure this branch variant is being used */
                            questionnaireErrors={questionnaireErrors}
                            questionnaireId={questionnaireId}
                            questions={
                                question.branchData?.branchQuestions ?? []
                            }
                            validationErrorsByKey={validationErrorsByKey}
                            cardsCanBeReordered={false}
                        />
                        {question.questionsData?.showAddQuestionButton && (
                            <AddQuestionToBranchButton
                                branchPosition={questionIndex} // Should define branchPosition passed down as the questionIndex
                            />
                        )}
                    </StyledQuestionIndent>
                </>
            );
        case QuestionType.ImageAttachment:
            return (
                <>
                    <CardWithTitle
                        key={cardKey}
                        cardTitle={`Q${
                            questionIndex + 1
                        }. Photo upload question`}
                        children={
                            <QuestionPreview
                                keyId={`${question.clientId}-preview-item`}
                                question={question}
                            />
                        }
                        cardFooter={
                            <Button
                                text="Remove question"
                                theme="secondary"
                                onClick={() =>
                                    handleRemoveQuestionModal({
                                        branchId,
                                        branchPosition,
                                        cardKey,
                                        questionId,
                                        questionLevel,
                                        questionRowVersion,
                                        questionType: QuestionType.Numbered,
                                    })
                                }
                            />
                        }
                    />
                </>
            );
        default:
            return null; // Other QuestionTypes not yet supported.
    }
};
