import { useEffect } from "react";

import { shallowEqual, useDispatch } from "react-redux";

import { getAppointmentReminders } from "app/practices/appointmentReminder/AppointmentReminder.actions";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

export const useLoadAppointmentReminders = (): Optional<UpdatingStatus> => {
    const dispatch = useDispatch();

    const practiceId = useAppSelector(
        ({ practices }) => practices?.selectedPractice,
        shallowEqual,
    );

    const updatingStatus = useAppSelector(
        ({ appointmentReminders }) =>
            appointmentReminders?.getAppointmentRemindersStatus,
        shallowEqual,
    );

    useEffect(() => {
        if (updatingStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(getAppointmentReminders(practiceId));
        }
    }, [dispatch, updatingStatus, practiceId]);

    return updatingStatus;
};
