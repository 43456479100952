import React from "react";

import { Button } from "@accurx/design";
import styled from "styled-components";

import { PatientListSummary } from "api/FlemingDtos";
import PatientListSummaryCard from "app/patientLists/PatientListSummaryCard";

const StyledButton = styled(Button)`
    white-space: nowrap;
`;

type PatientListImportCardProps = {
    summary: PatientListSummary;
    onChoosePatientList: (patientList: PatientListSummary) => void;
};

export const PatientListImportCard = ({
    summary,
    onChoosePatientList,
}: PatientListImportCardProps) => {
    const handleImportPatientDetailsClicked = (): void => {
        onChoosePatientList(summary);
    };

    const importButton = (
        <StyledButton
            theme="secondary"
            text="Import patient details"
            icon={{ name: "Link", colour: "blue" }}
            data-userflow-id="batch-import-patient-details"
            onClick={handleImportPatientDetailsClicked}
        />
    );

    return (
        <PatientListSummaryCard
            summary={summary}
            actionButtons={importButton}
        />
    );
};
