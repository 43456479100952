import { api } from "domains/concierge/internal/api/clinicianMessaging";
import { useConciergeDispatch } from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type SendMessageVariables = {
    workspaceId: string;
    workspaceConversationId: string;
    message: string;
    attachmentIds: string[];
    isPresetTemplate: boolean;
    templateGroup: string | null;
    templateName: string | null;
};

/**
 * Clinician messaging - send message
 */
export const useSendMutation = (
    options?: UseMutationOptions<SendMessageVariables>,
) => {
    const dispatch = useConciergeDispatch();

    return useMutation<SendMessageVariables>(
        "SendMessageGpMutation",
        async (params) => {
            const { updates } = await api.send({
                workspaceId: params.workspaceId,
                workspaceConversationId: params.workspaceConversationId,
                textContent: params.message,
                externalEmailAttachmentIds: params.attachmentIds,
                metadata: {
                    isPresetTemplate: params.isPresetTemplate,
                    templateLevel: params.isPresetTemplate ? "Accurx" : null,
                    templateGroup: params.templateGroup,
                    templateName: params.templateName,
                },
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:Send",
                    ...updates,
                }),
            );
        },
        {
            ...options,
            // Figured it better to allow the user to handle the connection errors themselves. React query default to online meaning it waits for a connection to come back before attempting to send the message. I think it's safer for the user to know the message hasn't been sent so they can check their PCs network connection rather then thinking it has been sent.
            networkMode: "always",
        },
    );
};
