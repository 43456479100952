import { useEffect } from "react";

import { Button, Feedback, Flex, Text } from "@accurx/design";
import { Redirect, generatePath, useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import { ChainAnalyticsTracker } from "app/analytics";
import { BatchUploadDemographicsProgress } from "app/batchMessage/shared/BatchUploadDemographicsProgress";
import { useBatchMessageDemographicsStatusQuery } from "app/hooks/queries/useBatchMessageDemographicsStatusQuery";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES, ROUTES_WORKSPACE } from "shared/Routes";

import { BatchUploadPopulatedDemographicsReview } from "../components/BatchUploadPopulatedDemographicsReview";
import { StyledAddPatientsStatusPage } from "./AddPatientsStatusPage.styles";
import { splitItemsByContactable } from "./utils/contactable";

export const getContinueButtonText = (contactablePatientsCount: number) => {
    if (contactablePatientsCount === 0) {
        return null;
    }
    return `Continue with ${contactablePatientsCount} patient${
        contactablePatientsCount === 1 ? "" : "s"
    }`;
};

export const AddPatientsStatusPage = () => {
    const history = useHistory();

    const { workspaceId, batchId } = useParams<{
        workspaceId: string;
        batchId: string;
    }>();

    const { data, status, error } = useBatchMessageDemographicsStatusQuery({
        workspaceId,
        batchId,
    });

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        if (data?.demographicsState === "Populated") {
            const { contactable } = splitItemsByContactable(
                data.details.items,
                batchId,
            );

            const countMobileNumberOverride = contactable.filter(
                (patient) => patient.contact.isOverride,
            ).length;

            ChainAnalyticsTracker.trackBatchPatientListReviewPageView({
                ...analyticsLoggedInProps,
                batchPatientListSize: data.details.totalItems,
                countPatientNotContactable: data.details.totalNonTextable,
                isTrustFlow: true,
                // Self book not available for trusts
                batchSlotAvailable: 0,
                batchCapacityWarningSeen: false,
                countMobileNumberOverride,
            });
        }
    }, [analyticsLoggedInProps, batchId, data, history]);

    if (status === "success" && data.demographicsState === "Other") {
        toast(
            <Feedback
                title="Batch message already sent or cannot be found"
                colour="error"
            />,
        );

        // Redirect home if the batch status is neither Fetching or Populating
        return <Redirect to={ROUTES.home} />;
    }

    const handleRemoveAll = () => {
        const uploadBatchUrl = generatePath(
            ROUTES_WORKSPACE.batchMessageTrustUpload,
            {
                workspaceId,
            },
        );
        // Don't want to replace history, as user may have already
        // gone to compose screen, then clicked on back browser button
        history.push(uploadBatchUrl);
    };

    const handleContinue = () => {
        const composeBatchUrl = generatePath(
            ROUTES_WORKSPACE.batchMessageTrustCompose,
            {
                workspaceId,
                batchId,
            },
        );
        history.push(composeBatchUrl, history.location.state);
    };

    const contactablePatientsCount =
        status === "success" && data.demographicsState === "Populated"
            ? data.details.totalItems - data.details.totalNonTextable
            : 0;
    const continueButtonText = getContinueButtonText(contactablePatientsCount);

    return (
        <StyledAddPatientsStatusPage.Wrapper>
            <StyledAddPatientsStatusPage.BackButton
                backCallback={() => {
                    ChainAnalyticsTracker.trackBatchBackClick({
                        ...analyticsLoggedInProps,
                        origin: history.location.pathname,
                        isTrustFlow: true,
                    });
                    history.goBack();
                }}
            />
            <StyledAddPatientsStatusPage.Main>
                <Flex flexDirection="column" gap="3">
                    <Flex flexDirection="column">
                        <Text as="h1" variant="subtitle" skinny>
                            Preview list of patients
                        </Text>
                        <Text as="h2" skinny>
                            This is a summary of the list you provided.
                        </Text>
                    </Flex>
                    {status === "error" && (
                        <Feedback colour="error">{error.message}</Feedback>
                    )}

                    {/* On first load, show 0% progress */}
                    {status === "loading" && (
                        <BatchUploadDemographicsProgress percentage={0} />
                    )}

                    {status === "success" && (
                        <>
                            {data.demographicsState === "Fetching" && (
                                <BatchUploadDemographicsProgress
                                    percentage={data.progressPercentage ?? 100}
                                />
                            )}
                            {data.demographicsState === "Populated" && (
                                <Flex gap="3" flexDirection="column">
                                    <BatchUploadPopulatedDemographicsReview
                                        uploadedPatients={data.details.items}
                                        onRemoveAllClick={handleRemoveAll}
                                        workspaceId={workspaceId}
                                        batchId={batchId}
                                        isTrustFlow
                                    />
                                </Flex>
                            )}
                        </>
                    )}
                </Flex>
            </StyledAddPatientsStatusPage.Main>
            {continueButtonText && (
                <StyledAddPatientsStatusPage.Footer>
                    <Button
                        text={continueButtonText}
                        onClick={handleContinue}
                        icon={{
                            name: "ArrowTail",
                            rotation: "right",
                            placement: "end",
                        }}
                        data-userflow-id="batch-continue-with-patients-from-review"
                    />
                </StyledAddPatientsStatusPage.Footer>
            )}
        </StyledAddPatientsStatusPage.Wrapper>
    );
};
