import * as UI from "@accurx/design";
import styled from "styled-components";

const { SIZES, BORDERS, COLOURS } = UI.Tokens;

export const StyledContent = styled(UI.TooltipContent)<{
    $colour: "night" | "white";
}>`
    padding: ${SIZES[0.25]} 6px;
    border-radius: ${BORDERS.radius};
    z-index: 1000;
    background-color: ${(props) => COLOURS.greyscale[props.$colour]};
`;

export const StyledUITooltip = styled.span<{ $withEllipsis?: boolean }>`
    ${(props) => (props.$withEllipsis ? "overflow: hidden;" : null)}

    // This fixes the
    // "show tooltip on truncated words"
    > span[tabindex="0"] {
        display: flex;
    }
`;
