import { Action, Reducer } from "redux";

import {
    KnownAction,
    VIDEO_COMPATIBILITY_CHECK_FINISHED,
    VIDEO_COMPATIBILITY_CHECK_STARTED,
} from "./VideoCompatibilityActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface VideoCompatibilityState {
    hasChecked: boolean;
    isChecking: boolean;
    browserCheck: Check;
    networkCheck: Check;
}

interface Check {
    ok: boolean;
    solution: string | null;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: VideoCompatibilityState = {
    hasChecked: false,
    isChecking: false,
    browserCheck: { ok: false, solution: null },
    networkCheck: { ok: false, solution: null },
};

export const reducer: Reducer<VideoCompatibilityState> = (
    state = initialState,
    incomingAction: Action,
): VideoCompatibilityState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case VIDEO_COMPATIBILITY_CHECK_STARTED:
            return {
                ...state,
                isChecking: true,
            };

        case VIDEO_COMPATIBILITY_CHECK_FINISHED:
            const [browserOk, browserSolution] = action.browserCheck;
            const [networkOk, networkSolution] = action.networkCheck;
            return {
                ...state,
                hasChecked: true,
                isChecking: false,
                browserCheck: { ok: browserOk, solution: browserSolution },
                networkCheck: { ok: networkOk, solution: networkSolution },
            };
    }

    return state;
};
