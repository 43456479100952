import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledTimeContainer = styled.span<{
    isTooltip?: boolean;
}>`
    white-space: nowrap;
    color: ${UI.Tokens.COLOURS.greyscale.metal};
    font-size: 14px;
    text-decoration: ${(props) => (props.isTooltip ? "underline" : "none")};
`;
