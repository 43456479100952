import { ipPathfinderTemplates } from "./templates.IpPathfinder";
import { ambulanceTemplates } from "./templates.ambulance";
import { pharmacyTemplates } from "./templates.pharmacy";

/**
 * Record that maps template categories to a union of templates
 *
 * new templates for a given category should be added as members of a union
 * e.g.
 *     "record-view": "opt-in" | "new-template" | "another-one"
 *
 * categories and template values should only contain unreserved URI characters
 * as they are intended to be used in query string params for auto-selecting
 * https://datatracker.ietf.org/doc/html/rfc3986#section-2.3
 */
export type MessageTemplateCategories = {
    "record-view": "opt-in";
    "community-visit": "review-request";
    gp:
        | "add-to-record"
        | "appointment"
        | "bp"
        | "referral-required"
        | "prescription";
    hospital: "medication-change" | "admission" | "discharge";
    pharmacy:
        | "bp-check"
        | "out-of-stock"
        | "ip-extended-minor-illness"
        | "ip-hypertension"
        | "ip-contraception"
        | "dosette-box-query"
        | "controlled-drug-query"
        | "unlicensed-medication-dose-query"
        | "medication-dose-query"
        | "incorrect-quantity"
        | "drug-interaction"
        | "medication-query-follow-up";
    ambulance: "paramedic-review";
};

export type MessageTemplateName = MessageTemplateCategories[Exclude<
    MessageTemplateGroup["Category"],
    undefined
>];

export type MessageTemplateCategoryName = keyof MessageTemplateCategories;

export type MessageTemplate = {
    Title: string;
    Body: string;
    SelectedForUseCase?: MessagePracticeUseCase;
    Name: MessageTemplateName;
};

export type MessageTemplateGroup = {
    SectionHeading: string;
    TemplateList: MessageTemplate[];
    Category: MessageTemplateCategoryName;
};

export enum MessagePracticeUseCase {
    GeneralMessage = "GeneralMessage",
    DischargeSummary = "DischargeSummary",
    MedicationChange = "MedicationChange",
}

export const GENERAL_TEMPLATES: MessageTemplateGroup[] = [
    {
        SectionHeading: "Community visit",
        Category: "community-visit",
        TemplateList: [
            {
                Name: "review-request",
                Title: "Patient requiring review",
                Body: "I have just visited this patient at home\nSituation:\nBackground:\nAssessment:\nRecommendation:",
            },
        ],
    },
    {
        SectionHeading: "GP",
        Category: "gp",
        TemplateList: [
            {
                Name: "add-to-record",
                Title: "Add to record",
                Body: "I have just consulted with this patient. Consultation notes as follows:\n\nConsultation summary:\n\nActions required:",
            },
            {
                Name: "appointment",
                Title: "Appointment required",
                Body: "This patient requires a *****APPOINTMENT TYPE***** appointment, *****URGENCY/TIMEFRAME*****. Please contact them directly to arrange.",
            },
            {
                Name: "bp",
                Title: "Blood pressure and atrial fibrillation",
                Body: "Blood pressure reading:\n\nAtrial Fibrillation:\n\n1064971000000100 |Making Every Contact Count (regime/therapy)|",
            },
            {
                Name: "referral-required",
                Title: "Referral required",
                Body: "This patient requires a *****REFERRAL TYPE***** referral, *****URGENCY/TIMEFRAME*****. Please submit this, attaching summary of problems, medication and investigations.\n\nReason for referral:\n\nHistory and presentation:\n\nOther relevant information:",
            },
            {
                Name: "prescription",
                Title: "Prescription required",
                Body: "This patient requires a prescription for the following medication, *****URGENCY/TIMEFRAME*****\n\n*****MEDICATION, DOSE, DURATION*****\n\nI have given advice on taking this medication, and informed them that the prescription will be sent to their nominated pharmacy.",
            },
        ],
    },
    {
        SectionHeading: "Hospital",
        Category: "hospital",
        TemplateList: [
            {
                Name: "medication-change",
                Title: "Medication change/request",
                SelectedForUseCase: MessagePracticeUseCase.MedicationChange,
                Body: "I have just seen this patient in my clinic. The clinic letter will follow, but in the meantime they require a prescription of:\n\n*****MEDICATION, DOSE, DURATION*****",
            },
            {
                Name: "admission",
                Title: "Notification of admission",
                Body: "I'm contacting you to inform you that this patient of yours has just been admitted to *****SPECIALTY AND HOSPITAL*****. We have access to a summary of their GP record, however please inform us if there is a care plan in place or other information that we should know.",
            },
            {
                Name: "discharge",
                Title: "Send discharge summary",
                Body: "Please find attached a discharge summary for the patient above who was recently seen in hospital. Please contact me by replying to this message if you have any questions.",
                SelectedForUseCase: MessagePracticeUseCase.DischargeSummary,
            },
        ],
    },
    {
        SectionHeading: "Pharmacy",
        Category: "pharmacy",
        TemplateList: [
            {
                Name: "bp-check",
                Title: "Blood Pressure Check Service",
                Body: pharmacyTemplates.bpCheck,
            },
            {
                Name: "out-of-stock",
                Title: "Out of Stock",
                Body: pharmacyTemplates.outOfStock,
            },
            {
                Name: "dosette-box-query",
                Title: "Dosette box query",
                Body: pharmacyTemplates.dosetteBoxQuery,
            },
            {
                Name: "controlled-drug-query",
                Title: "Controlled drug query",
                Body: pharmacyTemplates.controlledDrugQuery,
            },
            {
                Name: "unlicensed-medication-dose-query",
                Title: "Unlicensed medication/dose query",
                Body: pharmacyTemplates.unlicensedMedicationDoseQuery,
            },
            {
                Name: "medication-dose-query",
                Title: "Medication/dose query",
                Body: pharmacyTemplates.medicationDoseQuery,
            },
            {
                Name: "incorrect-quantity",
                Title: "Incorrect quantity",
                Body: pharmacyTemplates.incorrectQuantity,
            },
            {
                Name: "drug-interaction",
                Title: "Drug interaction",
                Body: pharmacyTemplates.drugInteraction,
            },
            {
                Name: "medication-query-follow-up",
                Title: "Medication query follow up",
                Body: pharmacyTemplates.medicationQueryFollowUp,
            },
            {
                Name: "ip-extended-minor-illness",
                Title: "IP Pathfinder - Extended Minor Illness",
                Body: ipPathfinderTemplates.extendedMinorIllness,
            },
            {
                Name: "ip-hypertension",
                Title: "IP Pathfinder - Hypertension Management",
                Body: ipPathfinderTemplates.hyptertension,
            },
            {
                Name: "ip-contraception",
                Title: "IP Pathfinder - Contraception",
                Body: ipPathfinderTemplates.contraception,
            },
        ],
    },
    {
        SectionHeading: "Ambulance Services",
        Category: "ambulance",
        TemplateList: [
            {
                Name: "paramedic-review",
                Title: "Paramedic Review",
                Body: ambulanceTemplates.paramedicReview,
            },
        ],
    },
];

export const RECORD_VIEW_TEMPLATES: MessageTemplateGroup[] = [
    {
        SectionHeading: "Record View",
        Category: "record-view",
        TemplateList: [
            {
                Name: "opt-in",
                Title: "Request patient's GP opt-in",
                Body: "I tried to view this patient’s medical record summary today but was unable to, because your practice unfortunately hasn’t yet opted in to Accurx Record View.\n\nTo prevent this happening in the future, I’d like to ask you to consider enabling Record View. Record View is a free tool that allows NHS professionals to view a clear and comprehensive summary of the GP medical record, with permission directly from the patient.\n\nGPs retain a full audit trail to keep track of which record summaries are viewed and when, while saving time responding to information requests. If possible, please do take a moment to enable Accurx Record View to benefit NHS professionals both within and outside of general practice.",
            },
        ],
    },
];

export const NO_TEMPLATE = {
    label: "No template",
    value: "No template",
    body: "",
} as const;
