import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

type StyledFlexProps = { $height: string };

export const StyledBorderedFlex = styled(Flex)<StyledFlexProps>`
    padding: ${Tokens.SIZES[1.5]};
    border-radius: ${Tokens.BORDERS.radius};
    border-color: ${Tokens.COLOURS.greyscale.silver};
    border-width: 1px;
    border-style: solid;
    height: ${({ $height }) => $height};
    padding: ${Tokens.SIZES[2]};
`;
