import { z } from "zod";

import { PatientRecordAttachmentSchema } from "./PatientRecordAttachmentsSchema";

export const PatientRecordAttachmentsDtoSchema = z.object({
    attachments: z.array(PatientRecordAttachmentSchema),
});

export type PatientRecordAttachmentsDto = z.infer<
    typeof PatientRecordAttachmentsDtoSchema
>;
