import { PrincipalSystem } from "domains/native/schemas/MedicalRecordConnectionDtoSchema";
import { MedicalRecordConnection } from "domains/native/schemas/MedicalRecordConnectionSchema";

import { useMedicalRecordConnectionQuery } from "./useMedicalRecordConnectionQuery";

const openInEMRSupport: Record<PrincipalSystem, boolean> = {
    Emis: true,
    SystmOne: true,
    Vision: true,
    Server: false,
    Unknown: false,
};

const saveToRecordSupport: Record<PrincipalSystem, boolean> = {
    Emis: true,
    SystmOne: true,
    Vision: true,
    Server: true,
    Unknown: false,
};

export const useMedicalRecordConnection = (): MedicalRecordConnection => {
    const { data: medicalRecordConnection } = useMedicalRecordConnectionQuery();

    if (!medicalRecordConnection) {
        // Not connected to the toolbar/native api
        return {
            status: null,
            system: null,
            userMedicalRecordRole: null,
            hasMedicalRecordUserChanged: null,
            loggedInUsersDetails: null,
            capabilities: null,
        };
    }

    const {
        principalSystemType: system,
        connectionType: status,
        userMedicalRecordRole,
        capabilities,
        hasMedicalRecordUserChanged,
        loggedInUsersDetails,
    } = medicalRecordConnection;

    return {
        status,
        system,
        userMedicalRecordRole,
        hasMedicalRecordUserChanged,
        loggedInUsersDetails,
        capabilities: {
            openInEMR: status === "Connected" && openInEMRSupport[system],
            saveToRecord: status === "Connected" && saveToRecordSupport[system],
            attachDocumentsFromRecord: capabilities.attachDocumentsFromRecord,
            saveAttachmentsToRecord: capabilities.saveAttachmentsToRecord,
            prefetchAttachments: capabilities.shouldPrefetchAttachments,
        },
    };
};
