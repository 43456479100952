/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { loadPractices, selectPractice } from "app/practices/Practices.actions";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

/*
 * We may be able to ditch this hook at some point,
 * but for now, some of the pages/tabs from the /practices
 * routes that we are embedding in /organisations pages
 * depend on the getPractices action having been dispatched
 * and the practices loaded, so this provides us with an
 * easy way to make sure that's happened where needed
 */
export const useLoadPractices = (): Optional<LoadingStatus> => {
    const dispatch = useDispatch();

    const org = useCurrentOrganisation();

    const loadingStatus = useAppSelector(
        ({ practices }) => practices.loadingStatus,
    );

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const hasEverLoaded = useAppSelector(
        ({ practices }) => practices.hasEverLoaded,
    );

    // call the api endpoint to load practices
    useEffect(() => {
        if (!hasEverLoaded && loadingStatus === LoadingStatus.Initial) {
            dispatch(loadPractices());
        }
    }, [dispatch, hasEverLoaded]);

    // this is v annoying, but we currently have
    // both a `selectedOrganisation` _and_ a `selectedPractice`
    // in the redux state, each populated at different times,
    // by different actions, containing different data.
    // this ensures we have updated our selected practice to
    // match `selectedOrganisation` for pages that rely on it
    useEffect(() => {
        if (
            hasEverLoaded &&
            org?.orgId &&
            org?.orgId !== parseInt(selectedPractice, 10)
        ) {
            dispatch(selectPractice(org.orgId.toString()));
        }
    }, [dispatch, hasEverLoaded, org?.orgId, selectedPractice]);

    return loadingStatus;
};
