import { forwardRef } from "react";

import { Log } from "@accurx/shared";
import { LocationDescriptorObject } from "history";
import omit from "lodash/omit";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkProps = Omit<RouterLinkProps, "to"> & {
    to: LocationDescriptorObject;
};

const getLocation = ({ to: { search, pathname } }: LinkProps): string => {
    const searchParam = search
        ? `${!search.startsWith("?") ? "?" : ""}{search}`
        : "";
    return `${pathname ?? ""}${searchParam}`;
};

/*
 * An unstyled Link component that supports client-side
 * react-router links as well as native anchor tags.
 * Ensures we don't accidentally link users out of the inbox
 * context by setting target=_blank for any links that
 * don't start with /inbox.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ children, ...props }, ref) => {
        const external = !props.to.pathname?.startsWith("/inbox");

        if (external && props.target !== "_blank") {
            Log.error('Link to outside the inbox without target="_blank"');
        }

        if ("to" in props && !external) {
            return (
                <RouterLink innerRef={ref} {...props}>
                    {children}
                </RouterLink>
            );
        } else {
            const location = getLocation(props);
            return (
                <a
                    ref={ref}
                    {...omit(props, ["to"])}
                    {...(external ? { target: "_blank" } : {})}
                    href={location}
                    rel="noreferrer noopener"
                >
                    {children}
                </a>
            );
        }
    },
);
