import {
    BatchMessageDemographicValidationState,
    BatchMessageItemSummary,
    IdType,
} from "@accurx/api/patient-messaging";
import { Log, NhsNumberHelpers } from "@accurx/shared";

function getPatientNhsNumber(
    batchMessageItem: BatchMessageItemSummary,
    batchId: string,
) {
    const nhsNumber =
        batchMessageItem.patientExternalIdentity.patientExternalIds?.find(
            (id) => id.type === IdType.NhsNumber,
        )?.value;

    if (!nhsNumber) {
        Log.error(
            new Error("Missing NHS number when reviewing batch patients"),
            {
                tags: { batchId },
            },
        );

        return "Not found";
    }

    return NhsNumberHelpers.formatNhsNumber(nhsNumber);
}

function getDisplayName(batchMessageItem: BatchMessageItemSummary) {
    return batchMessageItem.patientName || "Not found";
}

export type ContactablePatient = {
    nhsNumber: string;
    displayName: string;
    contact: {
        type: "Mobile" | "Email" | "Unknown";
        isOverride: boolean;
        value: string;
    };
};

export function getContactablePatient(
    batchMessageItem: BatchMessageItemSummary,
    batchId: string,
): ContactablePatient {
    if (batchMessageItem.emailAddress) {
        return {
            nhsNumber: getPatientNhsNumber(batchMessageItem, batchId),
            displayName: getDisplayName(batchMessageItem),
            contact: {
                type: "Email",
                isOverride: false,
                value: batchMessageItem.emailAddress,
            },
        };
    }

    if (batchMessageItem.mobileNumber) {
        return {
            nhsNumber: getPatientNhsNumber(batchMessageItem, batchId),
            displayName: getDisplayName(batchMessageItem),
            contact: {
                type: "Mobile",
                isOverride:
                    batchMessageItem.userProvidedMobileValidation ===
                    BatchMessageDemographicValidationState.Mismatch,
                value: batchMessageItem.mobileNumber,
            },
        };
    }

    Log.error(new Error("Missing contact details for contactable patient"), {
        tags: { batchId },
    });

    return {
        nhsNumber: getPatientNhsNumber(batchMessageItem, batchId),
        displayName: getDisplayName(batchMessageItem),
        contact: {
            type: "Unknown",
            isOverride: false,
            value: "Unknown",
        },
    };
}

export type UncontactablePatient = {
    nhsNumber: string;
    displayName: string;
    reasonUncontactable: string;
};

function getUncontactablePatient(
    batchMessageItem: BatchMessageItemSummary,
    batchId: string,
): UncontactablePatient {
    return {
        nhsNumber: getPatientNhsNumber(batchMessageItem, batchId),
        displayName: getDisplayName(batchMessageItem),
        reasonUncontactable:
            batchMessageItem.reasonUncontactable?.description || "Unknown",
    };
}

export function splitItemsByContactable(
    batchMessageItems: BatchMessageItemSummary[],
    batchId: string,
) {
    const contactable: ContactablePatient[] = [];
    const uncontactable: UncontactablePatient[] = [];

    batchMessageItems.forEach((item) => {
        if (item.isTextable) {
            contactable.push(getContactablePatient(item, batchId));
        } else {
            uncontactable.push(getUncontactablePatient(item, batchId));
        }
    });

    return { contactable, uncontactable };
}
