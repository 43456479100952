import { PatientListType } from "@accurx/api/patient-messaging";
import {
    PatientSearchResultBase,
    SearchForPatientByNhsNumberRequest,
} from "@accurx/api/portal";

import { IDocumentUploadRequest } from "./FlemingDtos";

/*
 * HOW TO NAVIGATE THIS FILE
 *
 * Interfaces are grouped by the API endpoints that they relate to. A Ctrl+F
 * using whichever /api/* path you are interested in should take you to the
 * relevant definitions.
 *
 * To find out more about the JSON that is passed to/from these endpoints,
 * you can refer to the documentation in src/app/patientLists/README.md.
 *
 */

/**
 * The base type of a patient list. Operation at the list level will return
 * a response that extends this interface. i.e.
 *
 * /api/patientlistsummary
 * /api/patientlist/addupdatelist
 * /api/patientlist/getlist
 */
export interface PatientListBase {
    patientListId: number;
    name: string;
    isCurrentUserListOwner: boolean;
    dateCreated: string;
    countShare: number;
    isReadonly: boolean;
    listType: PatientListType;
    lastUpdated?: string;
}

//#region Video Consult cancellations

/*
 * /api/patientlist/scheduledvideoconsults
 * */
export interface GetScheduledVideoConsultsRequest extends PatientListRequest {
    /* When this is undefined, it means that the request refers to the entire patient list */
    patientListEntryIds?: number[];
}

/*
 * Used as the response for
 * /api/patientlist/scheduledvideoconsults
 * */
export interface GetScheduledVideoConsultsResponse {
    scheduledVideoConsults: PatientListAppointment[];
}

/*
 * Used in the request body for
 * /api/patientlist/removepatients
 * /api/patientlist/bulkaddpatient
 * /api/patientlist/deleteList
 *
 * For these requests which cause the cancellation of VC invites
 * we are passing back the list of VCs that are going to be cancelled.
 * Backend will then use this list to allow or prevent the action from being completed
 * in case there is a mismatch between the `GetScheduledVideoConsultsResponse.scheduledVideoConsults`
 * received and at the point of one of those endpoints being hit.
 *
 * Reason behind this is to prevent the user from mistakenly i.e. delete a list with active invites
 * created since the latest data fetch
 * (i.e. shared list, user1 opens the website, then user2 sends a VC invite -
 * user1 will not know about this until they re-fetch the information with a manual refresh)
 * */
export interface ScheduledVideoConsultCancellations {
    /* Appointments with scheduled Video Consults that are going to be cancelled as a result of the action being taken
     * This is going to be the same as the response we get for /api/patientlist/scheduledvideoconsults
     * */
    // TODO: remove ? once the rest of the code is updated, as this will be a mandatory param
    cancelScheduledVideoConsults?: PatientListAppointment[];
}

//#endregion Video Consult cancellations

// #region /api/patientlistsummary
export interface PatientListSummary extends PatientListBase {
    patientCount: number;
}
export interface PatientListRequest {
    organisationId: number | null;
    patientListId: number;
}
export interface AllPatientListSummariesRequest {
    organisationId: number | null;
}
// #endregion /api/patientlistsummary

/**
 * The response for any API requests that return patient/appointment
 * information. i.e.
 *
 * /api/patientlist/getlist
 * /api/patientlist/addupdatelist
 * /api/patientlist/addpatient
 * /api/patientlist/bulkaddpatient
 */
export interface PatientList extends PatientListBase {
    patientDetails: PatientListPatientDetails;
    appointmentDays: PatientListAppointmentDay[];
}

/**A video consultation*/
export interface PatientListVideoInviteDetails {
    /**A unique id for the video consultation*/
    videoConsultId: string | null;
    /**videoConsultUrl: string (url) The url for the video consult room*/
    videoConsultUrl: string;
    hasSentVideoConsultInvite: boolean;
}

// #region PatientList.patientDetails
/**
 * A dictionary of patients in the current list, keyed by patientId
 */
export interface PatientListPatientDetails {
    [patientId: number]: PatientListPatientDetail;
}

export interface PatientListPatientDetail
    extends Omit<PatientSearchResultBase, "nhsNumber"> {
    patientId: number;
    nhsNumber?: string | null;
}
// #endregion PatientList.patientDetails

// #region PatientList.appointmentDays
/**
 * The days within the list that contain some appointments. Where a date is an
 * <empty string>, the appointments are treated as "anytime".
 */
export interface PatientListAppointmentDay {
    date: string;
    appointments: PatientListAppointment[];
}

/**An appointment*/
export interface PatientListAppointment extends PatientListVideoInviteDetails {
    /**This is the PatientListEntry.id*/
    id: number; // Patient list entry Id
    patientId: number;
    /**dateAdded: An ISO date*/
    dateAdded: string;
    /**dateTimeStart: An ISO date*/
    dateTimeStart: string | null;
}
// #endregion PatientList.appointmentDays

// #region /api/patientlist/addpatient
export interface PatientListAddPatientRequest
    extends SearchForPatientByNhsNumberRequest {
    patientListId: number;
}

export interface PatientListAddPatientResponse {
    patientListId: number;
    patientDetails: PatientListPatientDetails;
    appointmentDays: PatientListAppointmentDay[];
}
// #endregion /api/patientlist/addpatient

// #region /api/patientlist/bulkaddpatient
export interface PatientListBulkAddPatientRequest
    extends IDocumentUploadRequest {
    patientListId: number;
}

export interface PatientListBulkAddPatientResponse
    extends GetScheduledVideoConsultsResponse {
    patientListId: number;
    fileId: string;
    versionToken: string;
    // Array of ISO dates
    replacedDates: string[];
}
// #endregion /api/patientlist/bulkaddpatient

// #region /api/patientlist/confirmbulkaddpatient
export interface PatientListConfirmBulkAddPatientRequest
    extends PatientListRequest {
    fileId: string;
    versionToken: string;
}

/**
 * The errors that can arise when bulk adding patients via csv upload
 *
 * Needs to be kept in sync with accuRx.FlemingServer/DTO/PatientList.cs
 */
export enum BulkAddErrors {
    PatientNotFound,
    InvalidNhsNumber,
    InvalidDateOfBirth,
    DuplicateCsvEntry,
    InvalidAppointmentTime,
}

export interface PatientListBulkAddPatientError {
    nhsNumber: string;
    dateOfBirth: string;
    appointmentTime: string | null;
    errorDetails: BulkAddErrors;
}

export interface PatientListConfirmBulkAddPatientResponse
    extends PatientListAddPatientResponse {
    countAdded: number;
    errors: PatientListBulkAddPatientError[];
}
// #endregion /api/patientlist/confirmbulkaddpatient

// #region /api/patientlist/removepatients
// We are handling error & success feedback from the action component, therefore we need extra data when sending in the action vs. performing the API request
export interface PatientListRemovePatientEntriesActionRequest
    extends PatientListRequest,
        ScheduledVideoConsultCancellations {
    patientListEntries: PatientListAppointment[];
}

export interface PatientListRemovePatientEntriesApiRequest
    extends PatientListRequest {
    patientListEntryIds: number[];
}
// #endregion /api/patientlist/removepatients

// #region /api/patientlist/addupdatelist
export interface CreateOrEditPatientListRequest {
    organisationId: number | null;
    patientListId: number | null;
    name: string;
}
// #endregion /api/patientlist/addupdatelist

// #region /api/patientlist/sharelist
export interface SharePatientListRequest extends PatientListRequest {
    emailAddress: string;
}
// #endregion /api/patientlist/sharelist

// #region /api/patientlist/deleteList

export type DeletePatientListRequest = PatientListRequest &
    ScheduledVideoConsultCancellations;

// #endregion
