export class NimsVaccineProductType {
    static Pfizer = "Pfizer";
    static Az = "AstraZeneca";
    static Moderna = "Moderna";
    static Janssen = "Janssen";
    static PfizerPaediatric = "PfizerPaediatric";
    static Sanofi = "Sanofi";
}

export interface NimsData {
    fluDate: string | null;
    covidFirstDoseDate: string | null;
    covidSecondDoseDate: string | null;
    lastSuccessfulFetchTime: string | null;
    vaccineProduct: NimsVaccineProductType | null;
    isSingleDoseVaccine: boolean;
    covidLatestBoosterDate: string | null;
    covidLatestBoosterProduct: NimsVaccineProductType | null;
}
