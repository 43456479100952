import { DateFormatOptions, DateHelpers, Log } from "@accurx/shared";

import { ClinicianMessage, Participant } from "api/ClinicianConversationApi";

import {
    ConversationParticipants,
    ParticipantWorkspace,
} from "./participantsPanel/ParticipantsPanel";

type Separator = ", " | "\n" | "\n\n";

/* Will return a reducer function which will combine two strings using the
 * specified separator. If either or both strings are empty, the separator will
 * not be added.
 */
const combineWith =
    (separator: Separator) => (accumulator: string, currentValue: string) =>
        `${accumulator}${
            accumulator.length > 0 && currentValue.length > 0 ? separator : ""
        }${currentValue}`;

const mapIndividualParticipant = ({ displayName, emailAddress }: Participant) =>
    `${displayName ?? "Unknown user"} (${emailAddress})`;

const reduceIndividualParticipants = (
    participants: Participant[],
    separator: Separator,
) =>
    participants
        .map(mapIndividualParticipant)
        .reduce(combineWith(separator), "");

const reduceParticipantWorkspaces = (
    participantWorkspaces: ParticipantWorkspace[],
) =>
    participantWorkspaces
        .map(
            ({ workspaceName, participants }) =>
                `${workspaceName}${
                    participants.length > 0 ? ": " : ""
                }${reduceIndividualParticipants(participants, ", ")}`,
        )
        .reduce(combineWith("\n"), "");

const buildParticipantsString = ({
    individualParticipants,
    participantWorkspaces,
}: Pick<
    ConversationContext,
    "individualParticipants" | "participantWorkspaces"
>): string =>
    [
        reduceParticipantWorkspaces(participantWorkspaces),
        reduceIndividualParticipants(individualParticipants, "\n"),
    ].reduce(combineWith("\n"), "");

const formatDate = (isoDate: string) =>
    DateHelpers.formatTime(isoDate, DateFormatOptions.TIME_DATE_SHORT);

const formatSender = ({
    senderEmailAddress,
    senderDisplayName,
}: Pick<MessageContentToCopy, "senderDisplayName" | "senderEmailAddress">) =>
    mapIndividualParticipant({
        emailAddress: senderEmailAddress,
        displayName: senderDisplayName,
    });

const generateMessageCopy = (
    { body, dateSent, ...sender }: MessageContentToCopy,
    { subject, ...participants }: ConversationContext,
): string =>
    [
        ["Subject:", subject],
        ["Participants:", buildParticipantsString(participants)],
        ["Sender:", formatSender(sender)],
        ["Time and date:", formatDate(dateSent)],
        ["Message text:", body.trim()],
    ]
        .map((element) => element.reduce(combineWith("\n"), ""))
        .reduce(combineWith("\n\n"), "");

type MessageContentToCopy = Pick<
    ClinicianMessage,
    "body" | "dateSent" | "senderDisplayName" | "senderEmailAddress"
> & {
    sentByCurrentUser: boolean;
};
export type CopyMessageFunction = (
    message: MessageContentToCopy,
) => Promise<boolean>;

export type ConversationContext = ConversationParticipants & {
    subject: string;
};

type CopyMessageFunctionWithConversationContext = (
    ...args: [...Parameters<CopyMessageFunction>, ConversationContext]
) => ReturnType<CopyMessageFunction>;

export const copyMessageWithContext: CopyMessageFunctionWithConversationContext =
    async (message, conversationContext) => {
        try {
            const copy = generateMessageCopy(message, conversationContext);
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(copy);
                return true;
            } else {
                throw new Error(
                    "User didn't grant permission to the clipboard",
                );
            }
        } catch (_error) {
            Log.error(
                "[ClinicianConversation] User attempted to copy a message but their browser does not support it or the page does not permission to do so",
            );
            return false;
        }
    };
