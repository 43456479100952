import React, { useCallback, useEffect, useState } from "react";

import { Button } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";

import {
    PatientMatchState,
    PatientTriageSuggestedPatient,
} from "shared/concierge/conversations/types/item.types";

import { PatientMatchHeader } from "./PatientMatchHeader/PatientMatchHeader";
import { SearchAndConfirm } from "./SearchAndConfirm/SearchAndConfirm";

export type PatientMatchProps = {
    conversationIsDone: boolean;
    suggestedMatch: PatientTriageSuggestedPatient | null;
    matchType: PatientMatchState | null;
    children?: React.ReactNode;
    matchPatientToConversation: (patientToken: string) => Promise<boolean>;
};

export const PatientMatch = ({
    conversationIsDone,
    suggestedMatch,
    children = null,
    matchType,
    matchPatientToConversation,
}: PatientMatchProps) => {
    const [showConfirmPatient, setShowConfirmPatient] = useState(false);
    const [showSearchPatient, setShowSearchPatient] = useState(false);
    const [showPatientMatchActionButtons, setShowPatientMatchActionButtons] =
        useState(true);
    const showPatientMatchingHeader =
        matchType === "NoMatch" || matchType === "Suggested";

    const showActionButtons = () => {
        setShowPatientMatchActionButtons(true);
    };

    const hideActionButtons = () => {
        setShowPatientMatchActionButtons(false);
    };

    const closeQuickView = useCallback(() => {
        setShowConfirmPatient(false);
        setShowSearchPatient(false);
        showActionButtons();
    }, []);

    const handleSearchForPatient = () => {
        hideActionButtons();
        setShowSearchPatient(true);
    };

    const handleConfirmPatient = () => {
        hideActionButtons();
        setShowConfirmPatient(true);
    };

    useEffect(() => {
        // hide quickview if user marks the conversation done
        if (conversationIsDone) {
            closeQuickView();
        }
    }, [conversationIsDone, closeQuickView]);

    return (
        <>
            {children}
            {showPatientMatchingHeader && (
                <PatientMatchHeader
                    matchType={matchType}
                    showActionButtons={showPatientMatchActionButtons}
                    conversationIsDone={conversationIsDone}
                    onSearchForPatient={handleSearchForPatient}
                    onConfirmPatientIdentity={handleConfirmPatient}
                />
            )}
            <QuickViewPortal
                isOpen={showConfirmPatient}
                onClose={() => setShowConfirmPatient(false)}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={() => setShowConfirmPatient(false)}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    <SearchAndConfirm
                        suggestedMatch={suggestedMatch}
                        formType="confirm-patient"
                        closeQuickView={closeQuickView}
                        matchPatientToConversation={matchPatientToConversation}
                    />
                </QuickViewPortal.Body>
            </QuickViewPortal>
            <QuickViewPortal
                isOpen={showSearchPatient}
                onClose={() => setShowSearchPatient(false)}
            >
                <QuickViewPortal.Header>
                    <Button
                        onClick={() => setShowSearchPatient(false)}
                        text="Close"
                        icon={{
                            name: "Cross",
                            colour: "metal",
                            style: "Line",
                        }}
                        theme="transparent"
                    />
                </QuickViewPortal.Header>
                <QuickViewPortal.Body>
                    <SearchAndConfirm
                        suggestedMatch={suggestedMatch}
                        formType="search-patient"
                        closeQuickView={closeQuickView}
                        matchPatientToConversation={matchPatientToConversation}
                    />
                </QuickViewPortal.Body>
            </QuickViewPortal>
        </>
    );
};
