import React, { useEffect, useState } from "react";

import { Accordion, Ds, Icon, Spinner, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { getMessageStatusList } from "api/PracticesApi";
import { useAppSelector } from "store/hooks";

import { SupportUrls } from "../../../../../shared/constants/supportUrls";
import { BookingStatus, SelfBookAuditDto } from "../../selfBook.types";
import {
    MessageStatusList,
    MessageStatusLoader,
    MessageStatusTable,
    MessageStatusTableEmpty,
    MessageStatusTableWrapper,
} from "./messageStatus.styles";

export const MessageStatus = (): JSX.Element => {
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const [statusList, setStatusList] = useState<SelfBookAuditDto | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStatusList = async () => {
            const response = await getMessageStatusList(practiceId);

            if (response.result) {
                setStatusList(response.result);
                setIsLoading(false);
            }
        };

        if (!statusList && practiceId) {
            fetchStatusList();
        }
    }, [practiceId, statusList]);

    const getBookingStatus = (status: string): JSX.Element | null => {
        switch (status) {
            case BookingStatus.Awaiting:
                return <Ds.Badge color="yellow">Awaiting</Ds.Badge>;

            case BookingStatus.Booked:
                return <Ds.Badge color="green">Booked</Ds.Badge>;

            case BookingStatus.Failed_To_Book:
                return <Ds.Badge>Failed to book</Ds.Badge>;

            case BookingStatus.Did_Not_Book:
                return <Ds.Badge color="greyscale">Did not book</Ds.Badge>;

            default:
                return null;
        }
    };

    return (
        <div className="container py-4 px-0">
            <Text as="h2" variant="subtitle" skinny>
                Message status
            </Text>
            <Text>
                See the status and details of all the sent Self-Book messages.
            </Text>

            <div className="col-12 col-lg-8 px-0">
                <Accordion
                    className="w-100"
                    header="What does each status mean?"
                    icon={{ name: "Info" }}
                >
                    <Text variant="label" props={{ className: "mb-3" }}>
                        Booking status:
                    </Text>
                    <MessageStatusList>
                        <li>
                            <Ds.Badge color="yellow" className="mr-2">
                                Awaiting
                            </Ds.Badge>{" "}
                            Waiting for patient to book an appointment.
                        </li>
                        <li>
                            <Ds.Badge color="green" className="mr-2">
                                Booked
                            </Ds.Badge>{" "}
                            Patient has booked an appointment.
                        </li>
                        <li>
                            <Ds.Badge className="mr-2">Failed to book</Ds.Badge>{" "}
                            Patient tried to book but something went wrong.
                            Contact them to re-book.
                        </li>
                        <li>
                            <Ds.Badge color="greyscale" className="mr-2">
                                Did not book
                            </Ds.Badge>{" "}
                            Patient did not book an appointment before the link
                            expired (48 hours).
                        </li>
                    </MessageStatusList>
                </Accordion>
            </div>

            {isLoading ? (
                <MessageStatusLoader>
                    <Spinner />
                </MessageStatusLoader>
            ) : (
                <MessageStatusTableWrapper>
                    <MessageStatusTable>
                        <thead>
                            <tr>
                                <th>NHS number</th>
                                <th>Patient name</th>
                                <th>Booking status</th>
                                <th>Sent time</th>
                                <th>Sender name</th>
                            </tr>
                        </thead>

                        {statusList?.auditList && (
                            <tbody>
                                {statusList?.auditList
                                    .reverse()
                                    .map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.nhsNumber}</td>
                                                <td>{item.patientName}</td>
                                                <td>
                                                    {getBookingStatus(
                                                        item.bookingStatus,
                                                    )}
                                                </td>
                                                <td>
                                                    {DateHelpers.formatTime(
                                                        item.sentTime,
                                                        DateFormatOptions.TIME_DATE,
                                                    )}
                                                </td>
                                                <td>{item.senderName}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        )}
                    </MessageStatusTable>
                </MessageStatusTableWrapper>
            )}

            {statusList?.auditList?.length === 0 && (
                <MessageStatusTableEmpty>
                    <Text skinny>No message has been sent yet.</Text>
                    <Text>
                        Learn more about{" "}
                        <Text
                            as="a"
                            variant="link"
                            props={{
                                href: SupportUrls.SelfBookHelp,
                                rel: "noreferrer",
                                target: "_blank",
                            }}
                        >
                            Self-Book: What is it and how does it work
                            <Icon name="OpenWindow" colour="blue" size={3} />
                        </Text>
                    </Text>
                </MessageStatusTableEmpty>
            )}
        </div>
    );
};
