import {
    PatientThreadSummaryDetails,
    PatientThreadUser,
    PatientThreadUserGroupUserMembership,
} from "@accurx/api/ticket";
import { mapUserGroupTypeToTeamType } from "domains/concierge/internal/api/shared/mappers/mapUserGroupTypeToTeamType";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { TeamSummary, UserSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

import * as TicketApiClient from "../TicketApiClient";

const mapUser = (dto: PatientThreadUser): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }
    return {
        id: dto.accuRxId,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        displayName: dto.name || dto.email || dto.accuRxId,
        // any users that come back in the initialsummary response can be assigned to
        // any users that don't, can't
        canBeAssignedTo: true,
    };
};

const mapTeam = (
    dto: PatientThreadUserGroupUserMembership,
): TeamSummary | undefined => {
    if (isNil(dto.id) || dto.id.length === 0) {
        return undefined;
    }

    const type = mapUserGroupTypeToTeamType(dto.groupType);
    // teams of type PatientSingleResponse and FailedDeliveryReceipts cannot be assigned to
    const canBeAssignedTo =
        type !== "PatientSingleResponse" && type !== "FailedDeliveryReceipts";

    return {
        id: dto.id,
        displayName: dto.name ?? dto.id,
        isMember: dto.currentUserIsMember,
        type,
        canBeAssignedTo,
    };
};

export const getInitialSummaries: ConciergeFetcher<
    { workspaceId: number },
    PatientThreadSummaryDetails
> = async (arg) => {
    const response = await TicketApiClient.fetchInitialSummaryDetails(
        arg.workspaceId,
    );

    const users = (response.activeUsers ?? []).map(mapUser).filter(isInstance);

    const teams = (response.activeUserGroups ?? [])
        .map(mapTeam)
        .filter(isInstance);

    return {
        updates: {
            conversations: [],
            patients: [],
            users,
            teams,
        },
        response,
    };
};
