import { ComponentProps, ComponentPropsWithRef } from "react";

import * as UI from "@accurx/design";
import {
    Level2AccordionHeaderHeight,
    applyFocusStyle,
    applyHoverStyle,
} from "@accurx/navigation";
import styled from "styled-components";

const { SIZES } = UI.Tokens;

const LockIconContainer = styled.div`
    width: 20px;
    height: 20px;

    & svg {
        width: 100%;
        height: 100%;

        & path {
            opacity: 0.5;
        }
    }
`;

const Button = styled.button`
    appearance: none;
    display: flex;
    align-items: center;
    background: transparent;
    width: 100%;
    height: calc(${Level2AccordionHeaderHeight} - 8px);
    padding: 0 ${SIZES[0.5]};
    border: 1px solid transparent;
    border-radius: ${SIZES[1]};
    text-align: left;

    &:focus {
        ${applyFocusStyle}
    }

    &:hover {
        ${applyHoverStyle}
    }
`;

const TextContainer = styled(UI.Item)`
    flex: 1;
    margin-left: ${SIZES[0.5]};
`;

const IconContainer = styled(UI.Item)`
    padding: 0 ${SIZES[0.5]} 0 ${SIZES[1]};
`;

export type LockedInboxFeatureProps = ComponentPropsWithRef<"button"> & {
    isAccordion?: boolean;
    text: string;
    icon?: ComponentProps<typeof UI.Icon>;
};

export const LockedInboxFeature = ({
    isAccordion = false,
    text,
    icon,
    ...buttonProps
}: LockedInboxFeatureProps) => {
    return (
        <Button {...buttonProps}>
            {isAccordion && (
                <UI.Icon
                    name="Arrow"
                    size={2}
                    colour="white"
                    rotation="right"
                />
            )}
            {!!icon && (
                <IconContainer>
                    <UI.Icon colour="white" size={3} {...icon} />
                </IconContainer>
            )}
            <TextContainer>
                <UI.Text as="span" colour="white">
                    {text}
                </UI.Text>
            </TextContainer>
            <LockIconContainer>
                <UI.Icon
                    name="LockLocked"
                    size={3}
                    colour="white"
                    theme="Fill"
                />
            </LockIconContainer>
        </Button>
    );
};
