import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { bmUploadPatients } from "api/BatchMessaging";
import {
    BatchMessageId,
    PatientToUpload,
} from "app/batchMessage/gp/BatchMessage.types";

type UploadBatchPatientsMutationVariables = {
    workspaceId: string | null;
    patients: PatientToUpload[];
};
export const useUploadBatchPatientsMutation = createMutationHook<
    UploadBatchPatientsMutationVariables,
    BatchMessageId
>(() => {
    return {
        mutationKey: ["UploadBatchPatients"],
        mutationFn: async ({ workspaceId, patients }) => {
            if (workspaceId === null) {
                throw new Error(
                    "workspaceId not supplied to useUploadBatchPatientsMutation",
                );
            }

            const response = await bmUploadPatients(workspaceId, {
                batchMessageItems: patients,
            });
            return returnDataOrThrow(response);
        },
    };
});
