import React from "react";

import { Button, Ds, Flex, Text } from "@accurx/design";

export const ConfirmationModal = ({
    open,
    onOpenChange,
    onConfirm,
    headerText,
    confirmText,
    children,
}: Pick<Ds.ModalProps, "open" | "onOpenChange"> & {
    onConfirm: () => void;
    headerText: string;
    confirmText?: string;
    children: React.ReactNode;
}) => {
    return (
        <Ds.Modal open={open} onOpenChange={onOpenChange}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>{headerText}</Ds.Modal.Title>
                    <Text variant="label">Are you sure?</Text>
                    <Text>{children}</Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            onClick={() => onOpenChange(false)}
                            text="Cancel"
                        />
                        <Button
                            onClick={onConfirm}
                            text={confirmText ?? "Confirm"}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
