import { MaxBatchSMSSize } from "./Vaccine.type";
import { VaccineCourse } from "./models/VaccineDeliveryDTO";

// Link/placeholder
export const clickLinkCopy = "Please click on the link to book:";
export const linkAutogenerateCopy = "(link will autogenerate here)";
export const patientGreetingCopy = "[Patient Greeting],";
export const appointmentTimeCopy = "[Appointment time(s)]";
export const apptLinkSignaturePreviewCopy = `${clickLinkCopy} ${linkAutogenerateCopy}`;

// Invite/reminder message
export const defaultInviteMessage =
    "You have been invited to book your COVID-19 vaccinations.";
export const secondInviteMessage =
    "You have been invited to book your second COVID-19 vaccination.";
export const boosterInviteMessage =
    "You have been invited to book your COVID-19 booster vaccination.";
export const seasonalBoosterInviteMessage = (course: string) =>
    `You have been invited to book your COVID-19 ${course} vaccination.`;
export const reminderMessage =
    "This is a reminder to book your COVID-19 vaccinations.";

// Longer invite/reminder message
export const secondInviteCopy =
    "You have been invited to book your second COVID-19 vaccination.\nPlease click on the link to book:";
const inviteReminderCopy =
    "This is a reminder to book your COVID-19 vaccinations.\nPlease click on the link to book:";

// Cancel appts/invite
const appointmentsCancelled =
    "Your COVID-19 vaccinations on these dates have been CANCELLED by your practice:";
const allowRebookCopy = `To rebook, please follow this link: ${linkAutogenerateCopy}`;
const disallowRebookCopy = `You will receive another text with a new invite to book your appointments in.`;

// Placeholder of correct length
const avgPatientGreetingPlaceholder = "Hi Len16CharAvgPtnt,"; // Assume name is generally within 16 characters
const avgAppointmentTimePlaceholder = "01-Jan-2001 at 08:00";
const avgDoubleAptmtTimePlaceholder =
    "1: 01-Jan-2001 at 08:00\n2: 01-Jan-2001 at 08:00";
export const avgBookingLinkPlaceholder = "https://accurx.nhs.uk/r/xxxxxxxxxx";

// Character count
export const characterCountWarning =
    "Please note that character count is indicative - it will depend on the patient's name.";
export const characterLimitBreachedError = `Sorry, your message must be equal to or less than ${MaxBatchSMSSize} characters.`;

export const smsCancellationCopy = (allowRebook: boolean): string => {
    return `${patientGreetingCopy}\n${appointmentsCancelled}\n${appointmentTimeCopy}\n${
        allowRebook ? allowRebookCopy : disallowRebookCopy
    }`;
};

export const practiceSignature = (practiceName: string): string =>
    `Thanks,\n${practiceName}`;

export const smsPreviewCopy = (secondBookingInvite: boolean): string => {
    return `${patientGreetingCopy}\n\n${
        secondBookingInvite ? secondInviteCopy : inviteReminderCopy
    } ${linkAutogenerateCopy}`;
};

export const calculateMessageLength = (
    smsContent: string,
    appointmentCount: number,
): number => {
    const subGreeting = smsContent.indexOf(patientGreetingCopy) !== -1;
    const subLink = smsContent.indexOf(linkAutogenerateCopy) !== -1;
    const subAppointments = smsContent.indexOf(appointmentTimeCopy) !== -1;
    const appointmentReplacement =
        appointmentCount === 0
            ? ""
            : appointmentCount === 1
            ? avgAppointmentTimePlaceholder
            : avgDoubleAptmtTimePlaceholder;
    return (
        smsContent.length +
        (subGreeting
            ? avgPatientGreetingPlaceholder.length - patientGreetingCopy.length
            : 0) +
        (subLink
            ? avgBookingLinkPlaceholder.length - linkAutogenerateCopy.length
            : 0) +
        (subAppointments
            ? appointmentReplacement.length - appointmentTimeCopy.length
            : 0)
    );
};

export enum PdsMatchWarningLevel {
    NONE,
    MEDIUM,
    HIGH,
    CRITICAL,
}

export const boosterCopy = (course: VaccineCourse) => {
    switch (course) {
        case VaccineCourse.BoosterSpring2022:
            return "spring 2022 booster";
        case VaccineCourse.BoosterAutumn2022:
            return "autumn 2022 booster";
        case VaccineCourse.BoosterSpring2023:
            return "spring 2023 booster";
        case VaccineCourse.BoosterAutumn2023:
            return "autumn 2023 booster";
        case VaccineCourse.BoosterSpring2024:
            return "spring 2024 booster";
        case VaccineCourse.BoosterAutumn2024:
            return "autumn 2024 booster";
        default:
            return "booster";
    }
};
