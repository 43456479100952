import { Patient } from "@accurx/api/portal";
import { Button, RadioGroup, Text } from "@accurx/design";

import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";

import {
    StyledBody,
    StyledContainer,
    StyledFooter,
    StyledRadioContent,
} from "./PatientMatchConfirm.styles";

export type ConfirmPatient = Pick<
    Patient,
    | "firstName"
    | "familyName"
    | "dateOfBirth"
    | "nhsNumber"
    | "gender"
    | "prefixName"
> & { patientToken: Optional<string> };

export type PatientMatchConfirmProps = {
    onCancel: () => void;
    onPatientConfirm: () => void;
    isLoading: boolean;
    patient: ConfirmPatient;
    cancelButtonText: string;
    error: string;
};

export const PatientMatchConfirm = ({
    onCancel,
    onPatientConfirm,
    isLoading,
    patient,
    cancelButtonText = "Cancel",
    error,
}: PatientMatchConfirmProps) => {
    const prefixName = patient?.prefixName ? `(${patient?.prefixName})` : "";
    const patientFullNameWithPrefix = `${formatPatientDisplayName({
        familyName: patient.familyName ?? null,
        firstName: patient.firstName ?? null,
    })} ${prefixName}`;
    const patientDetails = `NHS: ${patient.nhsNumber} · Born: ${patient.dateOfBirth} · Gender: ${patient.gender}`;
    const checkedValue = `${patientFullNameWithPrefix} ${patientDetails}`;
    return (
        <StyledContainer>
            <StyledBody>
                <Text as="h2" skinny variant="subtitle">
                    Confirm patient match
                </Text>
                <Text skinny variant="body">
                    Please double check this is the patient you searched to
                    match this conversation.
                </Text>
                <RadioGroup
                    formFieldWrapperProps={{
                        label: "selected patient",
                        labelProps: { className: "sr-only" },
                        errors: [error],
                    }}
                    name="confirm-patient-name"
                    checkedValue={checkedValue}
                    theme="bordered"
                    checkPlacement="top-right"
                    onChange={() => {
                        /* not clear why this is here but adding comment to avoid empty arrow fn linting error */
                    }}
                    columns={1}
                    radioInputs={[
                        {
                            id: "patient-match-id",
                            label: renderRadioLabel(
                                patientFullNameWithPrefix,
                                patientDetails,
                            ),
                            value: checkedValue,
                        },
                    ]}
                />
            </StyledBody>
            <StyledFooter>
                <Button
                    onClick={onCancel}
                    text={cancelButtonText}
                    theme="secondary"
                />
                <Button
                    onClick={onPatientConfirm}
                    disabled={isLoading}
                    icon={{ name: "Done" }}
                    text="Confirm"
                    theme="primary"
                />
            </StyledFooter>
        </StyledContainer>
    );
};

const renderRadioLabel = (
    patientFullNameWithPrefix: string,
    patientDetails: string,
): JSX.Element => {
    return (
        <StyledRadioContent>
            <Text as="span" skinny variant="label">
                {patientFullNameWithPrefix}
            </Text>
            <Text as="span" variant="body" skinny>
                {patientDetails}
            </Text>
        </StyledRadioContent>
    );
};
