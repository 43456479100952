import { TicketIdentityType } from "@accurx/api/ticket";
import { Conversation } from "@accurx/concierge/types";

export const getConversationType = (conversation: Conversation): string => {
    switch (conversation.source.system) {
        case "Ticket":
            return TicketIdentityType[conversation.source.ticketIdentity.type];

        case "ClinicianMessaging":
            return "ClinicianMessaging";
    }
};
