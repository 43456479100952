import { Patient } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { IconNameVariants } from "@accurx/design/dist/styles/types";
import { DateHelpers } from "@accurx/shared/dist";

import { PatientListAppointment } from "api/FlemingDtos";
import { isTestPatient } from "app/patientProfile/isTestPatient";
import { ProductSelection } from "app/selectProduct/ProductSelection";
import { coreProductsDisabledForDeceasedPatients } from "shared/FeatureNameHelper";
import { PatientHelper } from "shared/PatientHelper";

type ScheduledAppointment = PatientListAppointment & {
    dateTimeStart: string;
};

export const isScheduledAppointment = (
    appointment: PatientListAppointment,
): appointment is ScheduledAppointment => {
    return appointment.dateTimeStart !== null;
};

export const isAnytimeAppointment = (
    appointment: PatientListAppointment,
): boolean => {
    return isScheduledAppointment(appointment) === false;
};

export const isScheduledAppointmentInPast = (
    appointment: PatientListAppointment,
): boolean => {
    if (isScheduledAppointment(appointment)) {
        return DateHelpers.isBeforeToday(appointment.dateTimeStart);
    }

    return false;
};

export const patientFeatureDisabledMessage = (
    patient: Patient | null,
    featureName: FeatureName,
    appointment?: PatientListAppointment,
): string | null => {
    if (patient === null) {
        return null;
    }

    if (
        featureName === FeatureName.CaregiverInitiated &&
        isTestPatient(patient)
    ) {
        return null;
    }

    const enabledForPatient = PatientHelper.isFeatureEnabled(
        patient,
        featureName,
    );
    const disabledForDeceasedPatient =
        patient.deceased &&
        coreProductsDisabledForDeceasedPatients.includes(featureName);

    if (enabledForPatient === false || disabledForDeceasedPatient === true) {
        return "Unavailable for this patient";
    }

    if (featureName === FeatureName.VideoConsultWeb) {
        if (
            appointment &&
            appointment.hasSentVideoConsultInvite !== null &&
            appointment.hasSentVideoConsultInvite === true
        ) {
            return "Video Consult already scheduled";
        }

        const isVideoConsultBeforeToday =
            appointment &&
            appointment.dateTimeStart !== null &&
            DateHelpers.isBeforeToday(appointment.dateTimeStart);
        if (isVideoConsultBeforeToday) {
            return "Scheduled time in past";
        }
    }

    return null;
};

export const DROPDOWN_ITEMS = {
    goToVideoConsult: "Go to Video Consult",
    goToScheduledVideoConsult: "Go to scheduled Video Consult",
    videoConsultInvite: "Invite to Video Consult",
    scheduledVideoConsultInvite: "Invite to scheduled Video Consult",
    messagePatient: "Message patient",
    requestRecord: "Request GP record",
    messageGpPractice: "Message GP practice",
    allOptions: "View profile",
} as const;

export type FeatureDropdownItem = {
    feature: FeatureName | null;
    icon: IconNameVariants;
    product: ProductSelection;
    text: string;
};

export const DROPDOWN_DEFAULT_OPTIONS: FeatureDropdownItem[] = [
    {
        feature: FeatureName.PatientThreadSMS,
        icon: "Send",
        product: ProductSelection.Sms,
        text: DROPDOWN_ITEMS.messagePatient,
    },
    {
        feature: FeatureName.RecordAccessBeta,
        icon: "Record",
        product: ProductSelection.RecordView,
        text: DROPDOWN_ITEMS.requestRecord,
    },
    {
        feature: FeatureName.CaregiverInitiated,
        icon: "Mention",
        product: ProductSelection.MessagePractice,
        text: DROPDOWN_ITEMS.messageGpPractice,
    },
    {
        feature: null,
        icon: "List",
        product: ProductSelection.None,
        text: DROPDOWN_ITEMS.allOptions,
    },
];
