import { useContext, useEffect, useMemo, useState } from "react";

import { BatchMessageTableSummaryWithLegacyProperties } from "@accurx/api/patient-messaging";
import {
    BatchMessageSummary,
    getBatchMessageCsvDownloadUrl,
} from "@accurx/batch-messaging";
import {
    Accordion,
    Button,
    Card,
    Ds,
    Feedback,
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
    Tokens,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import { Table, UncontrolledTooltip } from "reactstrap";

import { ChainAnalyticsTracker } from "app/analytics";
import { fetchBatchModalDetails } from "app/batchMessage/gp/BatchMessage.actions";
import { BatchMessageStatuses } from "app/batchMessage/gp/BatchMessage.constant";
import { statusLabel } from "app/batchMessage/gp/BatchMessage.types";
import { displayBatchType } from "app/batchMessage/gp/BatchMessage.utils";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    StickyTableLastColumn,
    StickyTableLastHeader,
} from "app/sharedComponents/table/StickyTable.styles";

import { BatchMessageCancelModal } from "./BatchMessageCancelModal";
import { BatchMessageDetailsModal } from "./BatchMessageDetailsModal";
import { StatusesLabelsContext } from "./BatchMessageStatusesContext";
import {
    AccordionWrapper,
    ActionTableColumn,
    RefreshButtonWrapper,
    ResponsiveContentHeader,
} from "./BatchMessageTable.styles";

type BatchMessageTableHeaderProps = {
    text: string;
    toolTipText?: string;
};

const statusLabelColorPicker = (label: string | null) => {
    switch (label) {
        case statusLabel.Scheduled:
            return "blue";
        case statusLabel.Sending:
        case statusLabel.Saving:
            return "yellow";
        case statusLabel.Done:
        case statusLabel.Saved:
            return "green";
        default:
            return "greyscale";
    }
};

export const BatchMessageTableHeader = ({
    text,
    toolTipText,
}: BatchMessageTableHeaderProps): JSX.Element => {
    const id = "tooltip-target-" + text.replace(/ /g, "-").toLowerCase();
    return (
        <th className="pt-2 pt-2">
            {toolTipText && (
                <>
                    <Icon theme="Line" name="Info" size={3} props={{ id }} />
                    <UncontrolledTooltip target={id}>
                        {toolTipText}
                    </UncontrolledTooltip>
                </>
            )}
            <Text variant="label" props={{ className: "text-nowrap" }}>
                {text}
            </Text>
        </th>
    );
};

type BatchMessageTableProps = {
    noHeader?: boolean;
    practiceId: string;
    summaries: BatchMessageSummary[];
    showSavingToRecordWarning: boolean;
    refetch: (withReset: boolean) => void;
};

export const BatchMessageTable = ({
    practiceId,
    summaries,
    showSavingToRecordWarning,
    refetch,
}: BatchMessageTableProps): JSX.Element => {
    const statusesLabels = useContext(StatusesLabelsContext);

    const [showBatchDetailsModal, setShowBatchDetailsModal] = useState(false);
    const [showBatchCancelModal, setShowBatchCancelModal] = useState(false);
    const [currentBatchId, setCurrentBatchId] = useState("");
    const [currentBatch, setCurrentBatch] =
        useState<BatchMessageTableSummaryWithLegacyProperties>();
    const [width, setWidth] = useState(window.innerWidth);
    const numericBreakpoint = Number(Tokens.BREAKPOINTS.md.slice(0, -2));

    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const getBatchMessageModalDetails = (orgId: string, batchId: string) => {
        dispatch(fetchBatchModalDetails(orgId, batchId));
    };

    const updateDetailsModal = () => {
        if (currentBatch !== null) {
            const currentItem = summaries.find(
                (summary) => summary.id === currentBatch?.id,
            );
            setCurrentBatch(currentItem);
            // check if there is change in data using lodash and only then update the modal
            !isEqual(currentItem, currentBatch) &&
                getBatchMessageModalDetails(practiceId, currentBatchId);
        }
    };

    const sortedSummaries = useMemo(() => {
        updateDetailsModal();
        return summaries.sort((a, b) =>
            DateHelpers.mostRecentSorter(b.sendTime || "", a.sendTime || ""),
        );
    }, [summaries]); // eslint-disable-line react-hooks/exhaustive-deps

    const trackBatchCsvDownloaded = (): void => {
        ChainAnalyticsTracker.trackBatchOverviewMessageDownloadClick({
            ...analyticsLoggedInProps,
            batchOverviewDownloadType: "FailedTable",
        });
    };

    const toggleBatchDetailsModal = () => {
        setShowBatchDetailsModal((prev: boolean) => !prev);
    };

    const toggleBatchCancelModal = () => {
        setShowBatchCancelModal((prev: boolean) => {
            // when prev state is true that means we have clicked to close the dialogue
            if (prev === true) {
                setCurrentBatch(undefined);
            }
            return !prev;
        });
    };

    const handleCancelRow = (batch: BatchMessageSummary) => {
        setCurrentBatchId(batch.id);
        toggleBatchCancelModal();
    };

    const handleViewDetails = (batch: BatchMessageSummary) => {
        getBatchMessageModalDetails(practiceId, batch.id);
        setCurrentBatchId(batch.id);
        setCurrentBatch(batch);
        toggleBatchDetailsModal();
    };

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, [width]);

    return (
        <>
            <ResponsiveContentHeader>
                <AccordionWrapper>
                    <Text>
                        See the status and details of all the sent batch
                        messages.
                    </Text>
                    <BatchMessageDetailsModal
                        batchId={currentBatchId}
                        orgId={practiceId}
                        isOpen={showBatchDetailsModal}
                        onToggleModal={toggleBatchDetailsModal}
                    />
                    <BatchMessageCancelModal
                        batchId={currentBatchId}
                        orgId={practiceId}
                        isOpen={showBatchCancelModal}
                        onToggleModal={toggleBatchCancelModal}
                        onCancel={() => refetch(false)}
                    />

                    <Accordion
                        header="What does each status mean?"
                        icon={{
                            name: "Info",
                        }}
                    >
                        {statusesLabels.map((statusLabel) => (
                            <div
                                key={
                                    BatchMessageStatuses[statusLabel].badgeName
                                }
                            >
                                <Ds.Badge
                                    color={
                                        BatchMessageStatuses[statusLabel]
                                            .badgeColor
                                    }
                                >
                                    {
                                        BatchMessageStatuses[statusLabel]
                                            .badgeName
                                    }
                                </Ds.Badge>
                                <Text colour="night">
                                    {BatchMessageStatuses[statusLabel].text}
                                </Text>
                            </div>
                        ))}
                    </Accordion>
                    {showSavingToRecordWarning && (
                        <div className="p-0 mt-3">
                            <Feedback
                                colour="warning"
                                title="Unable to save the message to your clinical system."
                            >
                                <Text skinny>
                                    Make sure the clinical system and Accurx
                                    toolbar are open to allow saving to record.
                                </Text>
                            </Feedback>
                        </div>
                    )}
                </AccordionWrapper>
                {summaries.length > 0 && (
                    <RefreshButtonWrapper>
                        <Button
                            theme="secondary"
                            type="button"
                            onClick={() => refetch(true)}
                            text="Refresh table"
                        />
                    </RefreshButtonWrapper>
                )}
            </ResponsiveContentHeader>
            {summaries.length === 0 ? (
                <Text>You haven't sent any batches yet</Text>
            ) : (
                <Card spacing={0}>
                    <Table
                        responsive
                        className="mb-0 text-left position-relative"
                    >
                        <StickyTableLastHeader>
                            <tr>
                                <BatchMessageTableHeader text="Send time" />
                                <BatchMessageTableHeader text="Sender" />
                                <BatchMessageTableHeader text="Batch status" />
                                <BatchMessageTableHeader text="Failed to deliver" />
                                <BatchMessageTableHeader text="Batch type" />
                                <BatchMessageTableHeader text="Booked" />
                                <BatchMessageTableHeader text="Action" />
                            </tr>
                        </StickyTableLastHeader>
                        <StickyTableLastColumn>
                            {/* Message summaries sorted date descending (newest first) */}
                            {/* The useEffect hook to setSummaries filters out states that won't have a sendTime value (only Queued/Dispatched have this */}
                            {sortedSummaries.map((x: BatchMessageSummary) => {
                                return (
                                    <tr key={x.id}>
                                        <td className="pr-4">
                                            <Text skinny>
                                                {`${DateHelpers.formatDate(
                                                    x.sendTime,
                                                    DateFormatOptions.TIME,
                                                )}`}
                                            </Text>
                                            <Text className="text-nowrap">
                                                {`${DateHelpers.formatDate(
                                                    x.sendTime,
                                                    DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
                                                )}`}
                                            </Text>
                                        </td>
                                        <td className="pr-4">
                                            <Text skinny>
                                                {x.sender?.fullName}
                                            </Text>
                                        </td>
                                        <td>
                                            <Ds.Badge
                                                data-testid="statusLabel"
                                                color={statusLabelColorPicker(
                                                    x.batchStatus,
                                                )}
                                            >
                                                {x.batchStatus}
                                            </Ds.Badge>
                                        </td>
                                        <td>
                                            {x.totalFailed &&
                                            x.batchStatus !== "Scheduled" ? (
                                                <Text
                                                    skinny
                                                    as="a"
                                                    variant="link"
                                                    props={{
                                                        href: getBatchMessageCsvDownloadUrl(
                                                            {
                                                                workspaceId:
                                                                    practiceId,
                                                                batchId: x.id,
                                                                include:
                                                                    "failed",
                                                            },
                                                        ),
                                                        "data-testid": "failed",
                                                        onClick:
                                                            trackBatchCsvDownloaded,
                                                    }}
                                                >
                                                    <Icon
                                                        theme="Line"
                                                        colour="blue"
                                                        name="Save"
                                                        size={3}
                                                        props={{
                                                            className: "mr-1",
                                                        }}
                                                    />
                                                    {x.totalFailed}
                                                </Text>
                                            ) : (
                                                <Text skinny>
                                                    {x.batchStatus ===
                                                    "Scheduled"
                                                        ? "N/A"
                                                        : 0}
                                                </Text>
                                            )}
                                        </td>
                                        <td>
                                            <Text skinny>
                                                {displayBatchType(
                                                    x.type,
                                                    x.batchType,
                                                )}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text
                                                skinny
                                                props={{
                                                    "data-testid":
                                                        "bookedCount",
                                                }}
                                            >
                                                {x.bookedCount || "N/A"}
                                            </Text>
                                        </td>

                                        <ActionTableColumn>
                                            {!x.isCancellable ? (
                                                <Button
                                                    theme="secondary"
                                                    dimension="medium"
                                                    icon={{
                                                        name: "Document",
                                                    }}
                                                    text={
                                                        width >
                                                        numericBreakpoint
                                                            ? "View details"
                                                            : "View"
                                                    }
                                                    className="mb-3"
                                                    onClick={() =>
                                                        handleViewDetails(x)
                                                    }
                                                />
                                            ) : (
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button
                                                            theme="secondary"
                                                            dimension="medium"
                                                            icon={{
                                                                name: "More",
                                                                style: "Fill",
                                                                rotation:
                                                                    "left",
                                                            }}
                                                            text="More"
                                                            as="span"
                                                        />
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverItem
                                                            onClick={() =>
                                                                handleViewDetails(
                                                                    x,
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                size={3}
                                                                name="Document"
                                                            ></Icon>
                                                            <Text skinny>
                                                                View details
                                                            </Text>
                                                        </PopoverItem>
                                                        <PopoverItem
                                                            onClick={() =>
                                                                handleCancelRow(
                                                                    x,
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                size={3}
                                                                name="Cross"
                                                            ></Icon>
                                                            <Text skinny>
                                                                Cancel sending
                                                            </Text>
                                                        </PopoverItem>
                                                    </PopoverContent>
                                                </Popover>
                                            )}
                                        </ActionTableColumn>
                                    </tr>
                                );
                            })}
                        </StickyTableLastColumn>
                    </Table>
                </Card>
            )}
        </>
    );
};
