import { Flex } from "@accurx/design";
import { useParams } from "react-router";

import { useTrackLoggedInPageView } from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";
import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

import { DataStatusResolver } from "./components/DataStatusResolver";

type IntegratedClinicListURLParams = { workspaceId: string };

export default function IntegratedClinicLists() {
    const { workspaceId } = useParams<IntegratedClinicListURLParams>();

    useTrackLoggedInPageView("ClinicList");

    return (
        <StyledContainer>
            <Flex justifyContent="space-between" alignItems="center">
                <PageHeader
                    title="Clinic lists"
                    type={PageHeaderType.ListPage}
                />
            </Flex>

            <DataStatusResolver workspaceId={workspaceId} />
        </StyledContainer>
    );
}
