import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES } = Tokens;

export const StyledPendingApprovalButton = styled(Button)`
    background: ${COLOURS.primary.green[100]};
    padding: ${SIZES[1]};
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: none;
    border: 1px solid ${COLOURS.primary.green[100]};

    span {
        font-size: 14px;
        line-height: 20px;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover,
    &:active,
    &:focus {
        background: ${COLOURS.primary.green[100]};
        border: 1px solid ${COLOURS.primary.green[50]};
    }
`;
