import {
    FloreyAvailabilityState,
    FloreyMessageTemplate,
    FloreyProductType,
    FloreyTemplatesResponse,
    Scheme,
} from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { QuestionnaireTemplate } from "domains/message-templates/types/QuestionnaireTemplate";

export type UseFloreyTemplatesQueryResponse = {
    groups: {
        heading?: string;
        templates: QuestionnaireTemplate[];
    }[];
};

const mapFloreyMessageTemplateToQuestionnaireTemplate = (
    dto: FloreyMessageTemplate,
    heading: string | null | undefined,
): QuestionnaireTemplate | undefined => {
    if (!dto.title) {
        Log.error("Florey message template received with no title", {
            tags: { templateId: dto.id },
        });
        return undefined;
    }

    const type = FloreyProductType[dto.type] as keyof typeof FloreyProductType;

    // We're assuming the medicalIdentity if anything is going
    // to be a SNOMED code, log something if that's not the case
    // https://accurx.slack.com/archives/C05UA0LEN78/p1717591688204559?thread_ts=1711365176.343499&cid=C05UA0LEN78
    if (
        dto.medicalCodeIdentity?.code &&
        dto.medicalCodeIdentity.codeScheme !== Scheme.SNOMED
    ) {
        Log.info(
            `Expected medicalIdentity to be a snomed code, scheme ${dto.medicalCodeIdentity.codeScheme} received instead`,
        );
    }

    return {
        id: dto.id,
        title: dto.title,
        body: dto.templateBody ?? "",
        canSchedule: !!dto.canSchedule,
        availableInBatchForFree: !!dto.availableInBatchForFree,
        canAutoSaveToRecord: !!dto.canAutoSaveToRecord,
        supportArticleUrl: dto.supportArticleUrl ?? undefined,
        availability: FloreyAvailabilityState[
            dto.availability
        ] as keyof typeof FloreyAvailabilityState,
        type,
        heading: heading ?? undefined,
        owner: type === "OrganisationCustom" ? "Organisation" : "Accurx",
        defaultUserGroupId: dto.defaultUserGroupId ?? undefined,
        medicalCodeIdentity: dto.medicalCodeIdentity
            ? {
                  debugDisplay: dto.medicalCodeIdentity.debugDisplay,
                  code: dto.medicalCodeIdentity.code ?? undefined,
                  codeScheme: Scheme[
                      dto.medicalCodeIdentity.codeScheme
                  ] as keyof typeof Scheme,
              }
            : undefined,
        snomedCodes: dto.medicalCodeIdentity?.code
            ? [{ id: dto.medicalCodeIdentity.code }]
            : undefined,
    };
};

const mapFloreyTemplatesResponseToQuestionnaireTemplates = (
    dto: FloreyTemplatesResponse,
): QuestionnaireTemplate[] => {
    const templates: QuestionnaireTemplate[] = [];

    dto.groups?.forEach((group) => {
        group.messageTemplates?.forEach((dto) => {
            const template = mapFloreyMessageTemplateToQuestionnaireTemplate(
                dto,
                group.heading,
            );
            if (!template) return;

            templates.push(template);
        });
    });

    return templates;
};

export const useQuestionnaireTemplatesQuery = (
    { workspaceId }: { workspaceId: number },
    options?: UseQueryOptions<
        FloreyTemplatesResponse,
        Error,
        QuestionnaireTemplate[]
    >,
) => {
    return useQuery({
        queryKey: ["FloreyTemplates", { workspaceId }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/floreytemplates`,
                    query: `?organisationId=${workspaceId}`,
                }),
            );

            return returnDataOrThrow<FloreyTemplatesResponse>(response);
        },
        select: mapFloreyTemplatesResponseToQuestionnaireTemplates,
        refetchOnMount: "always",
        ...options,
    });
};
