import * as UI from "@accurx/design";

import { BodyInfoBox } from "../../../BodyInfoBox";

export const AttachmentInProgressInfoBox = ({ names }: { names: string[] }) => {
    const namesList = names.join(", ");
    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header>
                <UI.Flex gap="1" alignItems="center">
                    <UI.Ds.Spinner aria-hidden="true" size="small" />
                    <BodyInfoBox.Text colour="night">
                        File{names.length > 1 ? "s" : ""} uploading
                    </BodyInfoBox.Text>
                </UI.Flex>
            </BodyInfoBox.Header>
            <BodyInfoBox.Footer>
                <BodyInfoBox.Text colour="metal">{namesList}</BodyInfoBox.Text>
            </BodyInfoBox.Footer>
        </BodyInfoBox.Wrapper>
    );
};
