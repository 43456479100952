import { LoadingStatus } from "shared/LoadingStatus";

import { Actions, KnownAction } from "./Practices.actions";
import {
    PracticeDetails,
    PracticeDetailsDto,
    PracticeModules,
    VaccineApprovalDetails,
} from "./Practices.types";
import { UPDATE_VACCINE_SITE_LINKS } from "./vaccine/approvalRequests/ApprovalRequests.actions";

const mapPractice = (
    practice: PracticeDetailsDto,
    vaccineDetail?: VaccineApprovalDetails,
): PracticeDetails => {
    const practiceDetails: PracticeDetails = {
        id: practice.id,
        name: practice.name,
        approved: practice.approved,
        admin: practice.admin,
        isResident: practice.isResident,
        features: practice.features,
        modules: practice.modules,
    };

    if (vaccineDetail) {
        return {
            ...practiceDetails,
            showVaccineLinks: vaccineDetail.showInvitePage,
            pendingVaccineApprovalRequests:
                vaccineDetail.approvalRequestsPending,
        };
    }

    return practiceDetails;
};

const updatePendingApproval = (
    existingPractice: PracticeDetails,
    practiceId: number,
    showVaccineLinks: boolean,
    pendingVaccineApprovalRequests: boolean,
): PracticeDetails => {
    if (existingPractice.id !== practiceId) {
        return existingPractice;
    }
    return {
        ...existingPractice,
        showVaccineLinks,
        pendingVaccineApprovalRequests,
    };
};

export type PracticesReducerState = {
    currentUserAnalyticsId: string;
    hasEverLoaded: boolean;
    items: PracticeDetails[];
    loadingStatus: typeof LoadingStatus.Initial;
    modules: PracticeModules;
    principalSystemsLast7Days: string[];
    selectedPractice: string;
};

export const initialState: PracticesReducerState = {
    currentUserAnalyticsId: "",
    hasEverLoaded: false,
    items: [],
    loadingStatus: LoadingStatus.Initial,
    modules: {
        batchAndAppointmentReminders: false,
        floreyPlus: false,
        patientTriage: false,
        sms: null,
        videoConsultations: false,
    },
    principalSystemsLast7Days: [],
    selectedPractice: "",
};

export const reducer = (
    state = initialState,
    action: KnownAction,
): PracticesReducerState => {
    switch (action.type) {
        case Actions.GET_PRACTICES_STARTED:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loading,
            };
        case Actions.GET_PRACTICES_FAILURE:
            return {
                ...state,
                loadingStatus: LoadingStatus.Failed,
            };
        case Actions.GET_PRACTICES_SUCCESS:
            if (!action.practices.length) {
                return {
                    ...state,
                    hasEverLoaded: true,
                    loadingStatus: LoadingStatus.Loaded,
                };
            }

            // Loading extra vaccine display flags "api/recall/VaccineApprovalDetails"
            let items = [];
            if (
                action.vaccineApprovalDetails &&
                action.vaccineApprovalDetails.length
            ) {
                items = action.practices.map((x: PracticeDetailsDto) => {
                    const currentVaccineDetails =
                        action.vaccineApprovalDetails.find(
                            (detail: VaccineApprovalDetails) =>
                                detail.id === x.id,
                        );
                    return mapPractice(x, currentVaccineDetails);
                });
            } else {
                items = action.practices.map((x: PracticeDetailsDto) =>
                    mapPractice(x),
                );
            }

            const firstPractice = action.practices[0];
            return {
                ...state,
                currentUserAnalyticsId: firstPractice.currentUserAnalyticsId,
                hasEverLoaded: true,
                items,
                loadingStatus: LoadingStatus.Loaded,
                principalSystemsLast7Days:
                    firstPractice.principalSystemsLast7Days,
            };
        case Actions.SET_SELECTED_PRACTICE:
            return {
                ...state,
                selectedPractice: action.selectedPractice,
            };
        case UPDATE_VACCINE_SITE_LINKS:
            // ApprovalRequests.actions: On update of a vaccine site approval we want to update the links we display
            return {
                ...state,
                items: state.items.map((practice: PracticeDetails) =>
                    updatePendingApproval(
                        practice,
                        action.practiceId,
                        action.showVaccineLinks,
                        action.pendingVaccineApprovalRequests,
                    ),
                ),
            };
        default:
            return state;
    }
};
