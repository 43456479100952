import { useState } from "react";

import { useCurrentUser } from "@accurx/auth";
import { Button, Feedback, Flex, Link, Text } from "@accurx/design";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import { i } from "images";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

import { actionCreators } from "app/account/AccountActions";
import { loadUserflow } from "app/account/Userflow";
import { loadAnalytics } from "app/analytics/AnalyticsHelper";
import { useUpdateUserCookiesMutation } from "app/hooks/mutations";

import { StyledCenter, StyledFullButton } from "./CookiesModal.styles";

export const CookiesModal = (): JSX.Element => {
    const updateUserCookiesMutation = useUpdateUserCookiesMutation();
    const dispatch = useDispatch();
    const { user } = useCurrentUser();

    const [errorMsg, setErrorMsg] = useState("");

    const saveSetting = (hasAcceptedCookies: boolean) => {
        setErrorMsg("");

        updateUserCookiesMutation.mutate(
            { hasAcceptedCookies },
            {
                onSuccess: () => {
                    if (hasAcceptedCookies) {
                        loadAnalytics();

                        if (user.analyticsUserIdentifier) {
                            loadUserflow(user.analyticsUserIdentifier);
                        }
                    }

                    dispatch(
                        actionCreators.updateUserCookies(hasAcceptedCookies),
                    );
                },
                onError: (error) => {
                    setErrorMsg(
                        error.message ||
                            "Something went wrong saving your preferences. Please try again.",
                    );
                },
            },
        );
    };

    return (
        <>
            <Modal fade={false} isOpen centered backdropClassName="bg-zinc">
                <ModalBody>
                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            src={i("tick-illustration.png")}
                            alt="Green tick illustration"
                            width="115"
                            height="115"
                        />
                        <StyledCenter>
                            <Text as="h1" variant="subtitle">
                                Contribute to an improved user experience
                            </Text>
                        </StyledCenter>
                        <Text>
                            {`We'd like to use analytics cookies to give you the best experience possible. Our `}
                            <Link
                                href="https://www.accurx.com/cookie-policy"
                                openInNewTab
                            >
                                <LinkText text="Cookie Policy" />
                                <Link.Icon />
                            </Link>
                            {` provides more details on what cookies we collect and their purpose.`}
                        </Text>

                        {errorMsg && (
                            <Feedback
                                title={errorMsg}
                                colour="error"
                                props={{ className: "mt-2 mb-3" }}
                            />
                        )}

                        <StyledFullButton
                            type="button"
                            text="Accept all"
                            onClick={() => saveSetting(true)}
                        />

                        <Button
                            type="button"
                            theme="transparent"
                            text="Decline optional cookies"
                            onClick={() => saveSetting(false)}
                        />
                    </Flex>
                </ModalBody>
            </Modal>
        </>
    );
};
