import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { useAuth } from "@accurx/auth";
import { useLocation } from "react-router";

import { registerWorkspaceConnection } from "../api/WorkspaceApi";
import { getInitialSelectedOrgId } from "./getInitialSelectedOrgId";

type SelectedOrgContext = {
    selectedOrgId: number | null;
    setSelectedOrgId: (id: number) => void;
    clearSelectedOrgId: () => void;
};

export const SelectedOrganisationContext =
    createContext<SelectedOrgContext | null>(null);

/**
 * The job of this provider is to set the initial selected organisation
 * when a user logs in and provide the value to its children.
 * It also provides a function to update the selected organisation.
 *
 * The need for this is temporary. The end goal is to have the selected organisation
 * ID provided by the URL.
 */
export const SelectedOrganisationProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { user } = useAuth();
    const location = useLocation();

    const [orgId, setOrgId] = useState<number | null>(
        user.isLoggedIn ? getInitialSelectedOrgId(user) : null,
    );

    /**
     * Sync up the URL with the selected org
     * from redux, so that if we load the page on an url
     * that contains the :workspaceId, and we find that id in
     * the array of orgs, override the selected org id.
     *
     * Mainly needed for when a user **loads** the inbox, otherwise we risk
     * having a different org selected in the primary nav than we
     * have in the inbox.
     */
    const workspaceRouteMatch = location.pathname.match(/\/w\/([0-9]*)/);
    const workspaceUrlId =
        workspaceRouteMatch !== null ? workspaceRouteMatch[1] : undefined;

    useEffect(() => {
        if (workspaceUrlId !== undefined && user.isLoggedIn) {
            const found = user.organisations.find(
                (o) => `${o.orgId}` === workspaceUrlId,
            );
            if (found) {
                setOrgId(found.orgId);
            }
        }
        // Only trigger effect when the workspace ID in the URL changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceUrlId]);

    /**
     * Tracks whenever the user changes accesses or changes workspaces
     */
    useEffect(() => {
        if (orgId && (!workspaceUrlId || workspaceUrlId === orgId.toString())) {
            registerWorkspaceConnection(orgId);
        }
        // Only trigger effect when `orgId` changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    return (
        <SelectedOrganisationContext.Provider
            value={{
                selectedOrgId: orgId,
                setSelectedOrgId: (id: number) => setOrgId(id),
                clearSelectedOrgId: () => setOrgId(null),
            }}
        >
            {children}
        </SelectedOrganisationContext.Provider>
    );
};

export const useSelectedOrganisation = () => {
    const context = useContext(SelectedOrganisationContext);

    if (context === null) {
        throw new Error(
            "useSelectedOrgId must be used within a SelectedOrganisationProvider",
        );
    }

    return context;
};
