import React from "react";

import { Feedback, Flex, Text } from "@accurx/design";

import { QuestionnaireCard } from "./QuestionnaireCard";
import { QuestionnaireListLoading } from "./QuestionnaireListLoading";
import { StyledQuestionnaires } from "./QuestionnairesList.styles";
import {
    DisplayFloreyMessageTemplate,
    DisplayFloreyQuestionnaireGroup,
} from "./types";

const HEADING = "Questionnaires";
const DESCRIPTION =
    "A set of clinical questions with an associated pre-written message you can edit";

/**
 * When heading is not defined and we're dealing with
 * a custom templates group, we want to display a custom heading
 */
const getGroupTitle = (
    heading: string | null,
    isCustomFloreyGroup: boolean,
): string | null => {
    if (heading) {
        return heading;
    }
    return isCustomFloreyGroup ? "Custom questionnaires" : null;
};

type QuestionnairesListProps = { workspaceId: number } & (
    | {
          onUseQuestionnaireClick: (
              template: DisplayFloreyMessageTemplate,
          ) => void;
          // Some uses of questionnaires require validation when a questionnaire is selected. In these cases we set isValidate to handle disabled state of other questionnaires
          isValidating?: boolean;
          questionnaires: DisplayFloreyQuestionnaireGroup[];
          status: "success";
      }
    | { status: "loading" }
    | { status: "error" }
);

export const QuestionnairesList = (
    props: QuestionnairesListProps,
): JSX.Element => {
    if (props.status === "loading") {
        return (
            <StyledQuestionnaires.OuterWrapper>
                <Text
                    as="h2"
                    variant="subtitle"
                    colour="night"
                    props={{ id: "questionnaires-title" }}
                    skinny
                >
                    {HEADING}
                </Text>
                <Text props={{ id: "questionnaires-description" }}>
                    {DESCRIPTION}
                </Text>
                <QuestionnaireListLoading workspaceId={props.workspaceId} />
            </StyledQuestionnaires.OuterWrapper>
        );
    }

    if (props.status === "error") {
        return (
            <Feedback colour="error">
                We're having trouble fetching the questionnaires - please
                refresh the page to try again.
            </Feedback>
        );
    }

    const { questionnaires, onUseQuestionnaireClick, isValidating } = props;

    return (
        <StyledQuestionnaires.OuterWrapper>
            <Text
                as="h2"
                variant="subtitle"
                colour="night"
                props={{ id: "questionnaires-title" }}
                skinny
            >
                {HEADING}
            </Text>
            <Text props={{ id: "questionnaires-description" }}>
                {DESCRIPTION}
            </Text>
            <>
                {props.questionnaires.length === 0 && (
                    <Feedback colour="information">
                        There are no questionnaires available for your
                        workspace.
                    </Feedback>
                )}
                {questionnaires.length > 0 && (
                    <StyledQuestionnaires.List
                        aria-labelledby="questionnaires-title"
                        aria-describedby="questionnaires-description"
                        gap="2"
                    >
                        {questionnaires.map((group, i) => {
                            const groupId = `${(
                                group.heading || "questionnaire-group"
                            )
                                .replaceAll(" ", "-")
                                .toLowerCase()}-${i}`;

                            const groupTitle = getGroupTitle(
                                group.heading,
                                group.isCustomFloreyGroup,
                            );
                            return (
                                <Flex
                                    as="li"
                                    flexDirection="column"
                                    gap="1"
                                    key={groupId}
                                >
                                    {groupTitle !== null && (
                                        <StyledQuestionnaires.QuestionnaireNames
                                            variant="label"
                                            colour="night"
                                            props={{
                                                id: groupId,
                                            }}
                                        >
                                            {groupTitle}
                                        </StyledQuestionnaires.QuestionnaireNames>
                                    )}
                                    <StyledQuestionnaires.List
                                        // Only label the list if there is a label
                                        aria-labelledby={
                                            groupTitle ? groupId : undefined
                                        }
                                        gap="1"
                                    >
                                        {group.messageTemplates.map(
                                            (template) => {
                                                return (
                                                    <li key={template.id}>
                                                        <QuestionnaireCard
                                                            workspaceId={
                                                                props.workspaceId
                                                            }
                                                            isLoading={false}
                                                            isValidating={
                                                                !!isValidating
                                                            }
                                                            template={template}
                                                            onUseQuestionnaireClick={
                                                                onUseQuestionnaireClick
                                                            }
                                                        />
                                                    </li>
                                                );
                                            },
                                        )}
                                    </StyledQuestionnaires.List>
                                </Flex>
                            );
                        })}
                    </StyledQuestionnaires.List>
                )}
            </>
        </StyledQuestionnaires.OuterWrapper>
    );
};
