import { AnalyticsProperties, LoggedInCustomProperties } from "@accurx/shared";

import { TrackVaccinesDeliveryEvent } from "./ChainAnalyticsTracker";

//#region INTERFACES

/**
 * - patientsUploaded: Number of patients in the CSV
 * - timeTaken: Time taken to upload the CSV in seconds
 * - awaitingResponse1stCount: Patients awaiting response on 1st dose
 * - toManuallyBook1stCount: Patients to manually book 1st dose
 * - awaitingInvite2ndCount: Patients awaiting response on 2nd dose
 * - hadBothCount: Patients with both doses
 * */
type VaccineCsvUploadedBaseProps = {
    patientsUploaded: number;
    timeTaken: string;
    awaitingResponse1stCount: string;
    toManuallyBook1stCount: string;
    awaitingInvite2ndCount: string;
    hadBothCount: string;
};

/**
 * - patientsUploaded: Number of patients in the CSV
 * - timeTaken: Time taken to upload the CSV in seconds
 * - needinitialCount: Patients who need their 1st of 2nd dose
 * - needBoosterCount: Patients who need their booster dose
 * - hadAllCount: Patients who have had all doses
 * */
type VaccineCsvUploadedBoosterBaseProps = {
    patientsUploaded: number;
    timeTaken: string;
    needinitialCount: string;
    needBoosterCount: string;
    hadAllCount: string;
    course: string;
};

/**
 * - courseName: The vaccine course selected (primary or booster)
 * */
type VaccinePatientListCourseTabChangedBaseProps = {
    courseName: string;
};

/**
 * - containsNhsNumber: Is the search an NHS number
 * - containsNumber: Does the search contain a number
 * - searchInputLength: Length of search value
 * */
type VaccinePatientListSearchFilterChangedBaseProps = {
    containsNhsNumber: boolean;
    containsNumber: boolean;
    searchInputLength: number;
};

/**
 * - applyFilterSection: section where apply filter button was hit
 * - myPracticeFilter: selected filter for only my practice
 * - currentStatusFilters: selected status filter(s)
 * - firstVaccinationWeekFilter: selected filter(s) for weeks since vaccination
 * - vaccineTypeFilters: selected vaccine type filter(s)
 * - unclearStatusFilter: selected filter for only unclear statuses
 * */
type VaccinePatientListFiltersAppliedBaseProps = {
    applyFilterSection: string;
    myPracticeFilter: boolean;
    currentStatusFilters: string[];
    firstVaccinationWeekFilter: string | undefined;
    vaccineTypeFilters: string[];
    unclearStatusFilter: boolean;
};

/**
 * - previousOrder: How the list was previously sorted
 * - newOrder: How the list is now sorted
 * */
type VaccinePatientListSortOrderChangedBaseProps = {
    previousOrder: AnalyticsProperties;
    newOrder: string;
};

/**
 * - patientsIncluded: Number of patients with demographics
 * - patientsExcluded: Number of patients without demographics
 * - patientsWithFirstJab: Number of patients with one vaccine dose
 * - patientsWithBothJabs: Number of patients with two vaccine doses
 * - badDataWarninglevel: Enum of PdsMatchWarningLevel
 * */
type VaccineContinuedToWriteMessageBaseProps = {
    patientsIncluded: number;
    patientsExcluded: number;
    patientsWithFirstJab: number;
    patientsWithBothJabs: number;
    badDataWarninglevel: number;
};

/**
 * - hasEditedMessage: Has the text message been edited
 * */
type VaccineSentMessageBaseProps = {
    hasEditedMessage: string;
    isIndividualInvite: boolean;
};

/**
 * - csvType: Which type of CSV was downloaded: Failed / NonTextable / Sent / Delivered
 * */
type VaccineCsvDownloadedBaseProps = {
    csvType: string;
};

/**
 * - filterStatus: Current filter state
 * - numberOfInvites: Number of invites
 * */
type VaccineBulkActionBaseProps = {
    filterStatus: string;
    numberOfInvites: string;
};

/**
 * - filterStatus: Current filter state
 * - numberOfInvites: Number of invites
 * */
type VaccineCompletedBulkActionBaseProps = {
    successCount: number;
    failCount: number;
};

/**
 * - patientStatus: Current patient invite status
 * */
type VaccineCancelInviteBaseProps = {
    patientStatus: string;
};

/**
 * - lookupMethod: search method
 * - foundPatient: was the patient found
 * */
type VaccineIndividualPatientLookupBaseProps = {
    lookupMethod: string;
    foundPatient: boolean;
};

/**
 * - lookupMethod: search method
 * */
type VaccineIndividualPatientInviteBaseProps = {
    lookupMethod: string;
};

/**
 * - allowRebook: can patient rebook appointment
 * */
type VaccineCancelAppointmentBaseProps = {
    allowRebook: boolean;
};

/**
 * - patientLimit: number of patients
 * - loadTime: how long to load the component
 * */
type VaccineAllPatientLoadingBaseProps = {
    patientLimit: number | null;
    loadTime: number;
};

//#endregion INTERFACES

export type VaccineCsvUploadedProps = LoggedInCustomProperties &
    VaccineCsvUploadedBaseProps;
export type VaccineCsvUploadedBoosterProps = LoggedInCustomProperties &
    VaccineCsvUploadedBoosterBaseProps;
export type VaccinePatientListCourseTabsChangedProps =
    LoggedInCustomProperties & VaccinePatientListCourseTabChangedBaseProps;
export type VaccinePatientListSearchFilterChangedProps =
    LoggedInCustomProperties & VaccinePatientListSearchFilterChangedBaseProps;
export type VaccinePatientListFiltersAppliedProps = LoggedInCustomProperties &
    VaccinePatientListFiltersAppliedBaseProps;
export type VaccinePatientListSortOrderChangedProps = LoggedInCustomProperties &
    VaccinePatientListSortOrderChangedBaseProps;
export type VaccineContinuedToWriteMessageProps = LoggedInCustomProperties &
    VaccineContinuedToWriteMessageBaseProps;
export type VaccineAllPatientLoadingProps = LoggedInCustomProperties &
    VaccineAllPatientLoadingBaseProps;
export type VaccineCancelInviteProps = LoggedInCustomProperties &
    VaccineCancelInviteBaseProps;
export type VaccineIndividualPatientLookupProps = LoggedInCustomProperties &
    VaccineIndividualPatientLookupBaseProps;
export type VaccineIndividualPatientInviteProps = LoggedInCustomProperties &
    VaccineIndividualPatientInviteBaseProps;
export type VaccineCancelAppointmentProps = LoggedInCustomProperties &
    VaccineCancelAppointmentBaseProps;
export type VaccineSentMessageProps = LoggedInCustomProperties &
    VaccineSentMessageBaseProps;
export type VaccineBulkActionProps = LoggedInCustomProperties &
    VaccineBulkActionBaseProps;
export type VaccineCompletedBulkActionProps = LoggedInCustomProperties &
    VaccineCompletedBulkActionBaseProps;
export type VaccineCsvDownloadedProps = LoggedInCustomProperties &
    VaccineCsvDownloadedBaseProps;

//#region VACCINE_DELIVERY

/**
 * User started a new vaccine invite
 *
 * Event type: Button click
 * */
export const trackNewVaccineInviteSent = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("New vaccines invite created", props);
};

/**
 * Vaccine patient list successfully uploaded
 *
 * Event type: API Response
 * */
export const trackVaccineCsvUploadSucceeded = (
    props: VaccineCsvUploadedProps,
): void => {
    TrackVaccinesDeliveryEvent("Uploaded patient list", props);
};

/**
 * Vaccine patient list successfully uploaded
 *
 * Event type: API Response
 * */
export const trackVaccineCsvUploadBoosterSucceeded = (
    props: VaccineCsvUploadedBoosterProps,
): void => {
    TrackVaccinesDeliveryEvent("Uploaded patient list booster", props);
};

/**
 * Vaccine patient list failed to upload
 *
 * Event type: API Response
 * */
export const trackVaccineCsvUploadFailed = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Failed to upload patient list", props);
};

/**
 * User changes course tab (primary or booster)
 *
 * Event type: Button click
 * */
export const trackVaccinePatientListCourseTabsChanged = (
    props: VaccinePatientListCourseTabsChangedProps,
): void => {
    TrackVaccinesDeliveryEvent("Changed course tab", props);
};

/**
 * User entered a term in the table filter
 *
 * Event type: Keyboard event
 * */
export const trackVaccinePatientListSearchFilterChanged = (
    props: VaccinePatientListSearchFilterChangedProps,
): void => {
    TrackVaccinesDeliveryEvent("Patient list search filter changed", props);
};

/**
 * User clicked to apply filter to patient list
 *
 * Event type: Button click
 * */
export const trackVaccinePatientListFiltersApplied = (
    props: VaccinePatientListFiltersAppliedProps,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Apply Filter on Patient List", props);
};

/**
 * User changed the list sort order
 *
 * Event type: Button click
 * */
export const trackVaccinePatientListSortOrderChanged = (
    props: VaccinePatientListSortOrderChangedProps,
): void => {
    TrackVaccinesDeliveryEvent("Patient list sort order changed", props);
};

/**
 * User clicked to display all results on one page
 *
 * Event type: Button click
 * */
export const trackVaccinePatientListRemoveDisplayLimit = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Patient list remove display limit", props);
};

/**
 * User clicked to invite a list of patients
 *
 * Event type: Button click
 * */
export const trackClickedInviteListOfPatients = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Invite List of Patients", props);
};

/**
 * User clicked vaccine book
 *
 * Event type: Button click
 * */
export const trackVaccineClickedBook = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Book", props);
};

/**
 * User clicked decline invite
 *
 * Event type: Button click
 * */
export const trackVaccineClickedDeclineInvite = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Decline Invite", props);
};

/**
 * User clicked reset invite
 *
 * Event type: Button click
 * */
export const trackVaccineResetInvite = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Reset Invite", props);
};

/**
 * User clicked cancel invite
 *
 * Event type: Button click
 * */
export const trackVaccineCancelInvite = (
    props: VaccineCancelInviteProps,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Cancel Invite", props);
};

/**
 * User clicked look up patient
 *
 * Event type: Button click
 * */
export const trackVaccineIndividualPatientLookup = (
    props: VaccineIndividualPatientLookupProps,
): void => {
    TrackVaccinesDeliveryEvent("Individual Invite - Looked Up Patient", props);
};

/**
 * User clicked to invite to second booking
 *
 * Event type: Button click
 * */
export const trackVaccineInviteToSecondBooking = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Send Invite to 2nd", props);
};

/**
 * User clicked to invite to second booking
 *
 * Event type: Button click
 * */
export const trackVaccineInviteToBoosterBooking = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Send Invite to Booster", props);
};

/**
 * User clicked to send reminder
 *
 * Event type: Button click
 * */
export const trackVaccineSendInviteReminder = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Send Reminder", props);
};

/**
 * User clicked invite through your practice
 *
 * Event type: Button click
 * */
export const trackVaccineClickedInviteThroughYourPractice = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Invite Through Your Practice", props);
};

/**
 * User clicked to cancel appointment
 *
 * Event type: Button click
 * */
export const trackVaccineCancelAppointment = (
    props: VaccineCancelAppointmentProps,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Cancel Appointment", props);
};

/**
 * User clicked to manage booking
 *
 * Event type: Button click
 * */
export const trackVaccineManageBooking = (
    props: LoggedInCustomProperties,
): void => {
    TrackVaccinesDeliveryEvent("Clicked Manage bookings", props);
};

/**
 * User continued to write message page
 *
 * Event type: Button click
 * */
export const trackVaccineContinuedToWriteMessage = (
    props: VaccineContinuedToWriteMessageProps,
): void => {
    TrackVaccinesDeliveryEvent("Continue to write message", props);
};

/**
 * User sent a vaccine invite
 *
 * Event type: Button click
 * */
export const trackVaccineSentMessage = (
    props: VaccineSentMessageProps,
): void => {
    TrackVaccinesDeliveryEvent("Sent message", props);
};

/**
 * Component loaded to show all patients
 *
 * Event type: useEffect
 * */
export const trackVaccineAllPatientLoading = (
    props: VaccineAllPatientLoadingProps,
): void => {
    TrackVaccinesDeliveryEvent("Loaded the all patients component", props);
};

/**
 * User downloaded delivery status CSV
 *
 * Event type: Button click
 * */
export const trackVaccineDeliveryDownloadedCSV = (
    props: VaccineCsvDownloadedProps,
): void => {
    TrackVaccinesDeliveryEvent("Downloaded vaccine invite list", props);
};

/**
 * User triggered a bulk action
 *
 * Event type: Button click
 * */
export const trackVaccineBulkAction = (
    action: string,
    props: VaccineBulkActionProps,
): void => {
    TrackVaccinesDeliveryEvent(`Clicked ${action} bulk action`, props);
};

/**
 * Callback on completion of bulk action
 *
 * Event type: useEffect
 * */
export const trackVaccineCompletedBulkAction = (
    action: string,
    props: VaccineCompletedBulkActionProps,
): void => {
    TrackVaccinesDeliveryEvent(`Completed ${action} bulk action`, props);
};

//#endregion VACCINE_DELIVERY
