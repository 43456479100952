import { ConversationItem } from "domains/concierge/schemas/ConversationItemSchema";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

/**
 * Merges in updates to a list of conversation items and orders them by
 * createdAt date. Conversation items is a permenant record so existing items
 * are never removed, new items are appended and then organized in the sort. For
 * duplicates we favour the newer items over the older item. It is up to the caller
 * to determine which is "newer" and which is "older".
 *
 * @param older
 * @param newer
 * @returns a sorted and deduped list of conversation items
 */
export const mergeConversationItems = <TItem extends ConversationItem>(
    older: TItem[] | undefined,
    newer: TItem[],
): TItem[] => {
    const allItems = older ? [...newer, ...older] : newer;
    return orderBy(uniqBy(allItems, "id"), ["createdAt", "id"], ["asc", "asc"]);
};
