import { useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { Ds, Popover, PopoverTrigger, Text } from "@accurx/design";
import { StyledPopoverContent, StyledPopoverItem } from "@accurx/navigation";
import { StyledMessageButton } from "layouts/UnifiedLayout/UnifiedLayout.styles";
import { generatePath, useHistory } from "react-router-dom";

import { useNewConversationNavigation } from "app/clinicianConversations/hooks/useNewConversationNavigation";

type NewMessageDropdownProps = {
    openModal: () => void;
};

export const NewMessageDropdown = ({ openModal }: NewMessageDropdownProps) => {
    const { orgId: workspaceId } = useCurrentWorkspace();
    const history = useHistory();
    const { navigateToNewConversation, canCreateNewConversation } =
        useNewConversationNavigation();

    const [popoverOpen, setPopoverOpen] = useState(false);

    const goToMessageCareProvider = () => {
        navigateToNewConversation({ fromEmptyConversations: false });
        setPopoverOpen(false);
    };

    const goToBatchMessage = () => {
        history.push(
            generatePath("/w/:workspaceId/batch-messages/send/upload", {
                workspaceId,
            }),
        );
        setPopoverOpen(false);
    };

    return (
        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild>
                <StyledMessageButton
                    icon={{
                        name: "Arrow",
                        title: "More message options",
                        colour: "green",
                    }}
                />
            </PopoverTrigger>
            <StyledPopoverContent side="bottom" align="start">
                <StyledPopoverItem onClick={openModal}>
                    <Ds.Icon name="PenToSquare" size="small" color="stone" />
                    <Text skinny variant="label">
                        New message
                    </Text>
                </StyledPopoverItem>
                {canCreateNewConversation && (
                    <StyledPopoverItem onClick={goToMessageCareProvider}>
                        <Ds.Icon name="Mention" size="small" color="stone" />
                        <Text skinny variant="label">
                            Message care provider
                        </Text>
                    </StyledPopoverItem>
                )}
                <StyledPopoverItem onClick={goToBatchMessage}>
                    <Ds.Icon name="Messages" size="small" color="stone" />
                    <Text skinny variant="label">
                        New batch message
                    </Text>
                </StyledPopoverItem>
            </StyledPopoverContent>
        </Popover>
    );
};
