import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useCompose } from "domains/compose/context";

import { StyledPillButton } from "../Attach/components/AttachmentInfoBox.styles";
import { StyledScheduledSendInfo } from "./ScheduleSendInfo.styles";
import { ScheduledSendText } from "./ScheduledSendText";

export const ScheduledSendInfo = () => {
    const { dispatch, state } = useCompose();

    if (!state.sendAt) return null;

    return (
        <StyledScheduledSendInfo>
            <UI.Flex
                gap="1"
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <UI.Icon
                    name="Clock"
                    colour="metal"
                    size={3}
                    theme="Line"
                    aria-hidden={true}
                />
                <ScheduledSendText sendAt={state.sendAt} />
            </UI.Flex>
            <StyledPillButton
                onClick={() => {
                    dispatch({
                        type: "UPDATE_SEND_AT",
                        payload: { sendAt: null },
                    });
                }}
                title="Remove scheduled send time"
            >
                <Pill.Icon name="Cross" colour="red" size={3} />
            </StyledPillButton>
        </StyledScheduledSendInfo>
    );
};
