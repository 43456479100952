import { Text } from "@accurx/design";
import { RemirrorJSON } from "@remirror/core";
import { MarkMap, RemirrorRenderer } from "@remirror/react-renderer";

import { SyledReadOnlyMentionAtom } from "./AccumailTemplateViewer.styles";

type AccumailTemplateViewerProps = { content: RemirrorJSON };

export const AccumailTemplateViewer = ({
    content,
}: AccumailTemplateViewerProps) => {
    const s: MarkMap = {
        doc: ({ children }) => <div>{children}</div>,
        paragraph: ({ children }) => (
            <Text>{children === undefined ? <>&nbsp;</> : children}</Text>
        ),
        text: ({ text }) => text,
        mentionAtom: (obj) => (
            <SyledReadOnlyMentionAtom role="placeholder">
                {obj.node.attrs.label}
            </SyledReadOnlyMentionAtom>
        ),
    };

    return <RemirrorRenderer json={content} typeMap={s} />;
};
