import { Feedback, Text } from "@accurx/design";

import { useCurrentOrgName, useIsUserApproved } from "store/hooks";

export default function ErrorState() {
    const orgName = useCurrentOrgName();
    const isUserApproved = useIsUserApproved();

    return (
        <Feedback colour="error" title="Something went wrong">
            <Text skinny>
                {isUserApproved
                    ? `We can’t load recently searched patients for ${orgName}`
                    : "We can’t load your recently searched patients"}
            </Text>
        </Feedback>
    );
}
