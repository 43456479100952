import { createUniqueNoteId } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import {
    NoteLinkType,
    PatientThreadContentEmailLinkNote,
    PatientThreadContentSmsLinkNote,
    PatientThreadContentType,
    PatientThreadEmailLinkNote,
    PatientThreadSmsLinkNote,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    FailedDeliveryReceiptLinkItem,
    VideoConsultLinkItem,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import { mapBaseConversationItem } from "./conversationItem.helpers";

/**
 * Maps ticket Link item to conversation Link item
 *
 * @param ticketLinkItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapLinkItem(
    ticketLinkItem: PatientThreadSmsLinkNote | PatientThreadEmailLinkNote,
): FailedDeliveryReceiptLinkItem | VideoConsultLinkItem | undefined {
    if (!ticketLinkItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    const baseConversationItem = mapBaseConversationItem(ticketLinkItem);

    const linkedItemUniqueNoteId = mapLinkedUniqueItemId(
        ticketLinkItem.message,
    );
    if (!linkedItemUniqueNoteId) {
        throw new Error(
            "Cannot map ticket item as a link note item does not have the item it is linked to",
        );
    }

    switch (ticketLinkItem.message.linkType) {
        case NoteLinkType.FailedDeliveryReceipt:
            return {
                ...baseConversationItem,
                contentType: "FailedDeliveryReceiptLink",
                createdBy: { type: "System" },
                linkedItemId: linkedItemUniqueNoteId,
            };

        case NoteLinkType.VideoConsult:
            if (!ticketLinkItem.message.clinicianUrl) {
                throw new Error(
                    "Cannot map ticket item as link item is of type Video Consult, but does not contain a valid clinician url",
                );
            }

            return {
                ...baseConversationItem,
                contentType: "VideoConsultLink",
                createdBy: { type: "System" },
                clinicianUrl: ticketLinkItem.message.clinicianUrl,
                linkedItemId: linkedItemUniqueNoteId,
            };

        case NoteLinkType.None:
        default:
            throw new Error(
                "Cannot map ticket item as the link type is unknown",
            );
    }
}

function mapLinkedUniqueItemId(
    itemContent:
        | PatientThreadContentEmailLinkNote
        | PatientThreadContentSmsLinkNote,
): string | undefined {
    if (
        itemContent.type === PatientThreadContentType.SmsLinkNote &&
        "linkedSmsId" in itemContent &&
        itemContent.linkedSmsId
    ) {
        const linkedSmsUniqueNoteId = createUniqueNoteId(
            PatientThreadContentType.SMS,
            itemContent.linkedSmsId,
        );
        return linkedSmsUniqueNoteId;
    }

    if (
        itemContent.type === PatientThreadContentType.PatientEmailLinkNote &&
        "linkedEmailId" in itemContent &&
        itemContent.linkedEmailId
    ) {
        const linkedEmailUniqueNoteId = createUniqueNoteId(
            PatientThreadContentType.PatientEmail,
            itemContent.linkedEmailId,
        );
        return linkedEmailUniqueNoteId;
    }

    return undefined;
}
