import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { ErrorImage } from "domains/inbox/components/ErrorImage/ErrorImage";

import { StyledErrorStateContainer } from "./ConversationFeed.styles";

export type ConversationFeedErrorStateProps = {
    onClick: () => void;
};

export const ConversationFeedErrorState = ({
    onClick,
}: ConversationFeedErrorStateProps) => {
    return (
        <StyledErrorStateContainer
            flexDirection="column"
            alignItems="center"
            gap="1.5"
        >
            <ErrorImage alt="Conversation feed loading error" />
            <UI.Text colour="metal" skinny>
                Sorry, something went wrong. Refresh the page to try again.
            </UI.Text>
            <Pill.SecondaryButton onClick={onClick}>
                <Pill.Icon name="Refresh" colour="blue" size={3} />
                <Pill.Text>Refresh</Pill.Text>
            </Pill.SecondaryButton>
        </StyledErrorStateContainer>
    );
};
