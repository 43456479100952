import {
    IdType,
    MessagePatientRequestWithExternalIdentity,
    PatientExternalIdentityDto,
    SelfBookRequestType,
} from "@accurx/api/patient-messaging";

import { SendMessageVariables } from "./useSendMessageMutation.types";

export const mapSendMessageVariablesToMessagePatientRequestWithExternalId = ({
    sendMessageVariables,
    workspaceId,
}: {
    sendMessageVariables: SendMessageVariables;
    workspaceId: number;
}): Required<MessagePatientRequestWithExternalIdentity> => {
    const args = sendMessageVariables;
    const attachmentIds = args.attachments
        .filter((attachment) => attachment.origin === "Upload")
        .map((attachment) => attachment.id);

    const patientExternalIdentity: PatientExternalIdentityDto = {
        patientExternalIds: args.patientExternalIds.map((externalId) => ({
            value: externalId.value,
            type: IdType[externalId.type],
        })),
    };

    const messageTemplate =
        args.template?.type === "MessageTemplate"
            ? {
                  id: parseInt(args.template.value.id, 10),
                  attachmentIds: args.attachments
                      .filter((att) => att.origin === "Template")
                      .map((att) => parseInt(att.id, 10)),
              }
            : null;

    const questionnaire =
        args.template?.type === "QuestionnaireTemplate"
            ? {
                  conditionId: args.template.value.id,
                  conditionName: args.template.value.title,
              }
            : null;

    const mobileNumber =
        args.contactDetails.method === "Mobile"
            ? args.contactDetails.value
            : null;
    const emailAddress =
        args.contactDetails.method === "Email"
            ? args.contactDetails.value
            : null;
    /**
     * We only need to send this value if the number we want to send the message to is from the EMR.
     * This is so the server knows to do a fresh EMR query at the time of sending the message,
     * in the case of scheduled messages. If this value is not set, or is not equal to the mobileNumber
     * we send, then the number from the EMR will not be used.
     */
    const demographicsMobileNumber =
        args.contactDetails.method === "Mobile" &&
        args.contactDetails.origin === "EMR"
            ? args.contactDetails.value
            : null;

    const selfBook = args.selfBookLink
        ? {
              slotType: args.selfBookLink.slotType,
              clinicianGroupIds: args.selfBookLink.specificClinician?.map(
                  (clinician) => clinician.clinicianGroupId,
              ),
              targetOdsCode: args.selfBookLink.crossOrgBooking?.targetOdsCode,
              timeoutDays: args.selfBookLink.timeoutDays,
              type: SelfBookRequestType[args.selfBookLink.appointmentType],
          }
        : null;

    const sendTime =
        args.sendAt && args.sendAt.sendAtDateTime !== ""
            ? args.sendAt.sendAtDateTime
            : null;

    return {
        organisationId: workspaceId,
        mobileNumber,
        demographicsMobileNumber,
        emailAddress,
        attachedDocumentIds: attachmentIds,
        enablePatientResponse: args.isPatientResponseEnabled,
        messageBody: args.messageBody,
        messageFooter: args.messageSignature,
        patientExternalIdentity,
        sendTime,
        nhsChoicesLink: args.nhsAdviceLink,
        selfBook,
        messageTemplate,
        conditionId: questionnaire?.conditionId ?? null,
        conditionName: questionnaire?.conditionName ?? null,
        // Irrelevant params
        isVideoConsult: false,
        patientListId: null,
        videoConsultTime: null,
        patientListEntryId: null,
        ticketIdentity: null,
        clientMessageId: null,
        nhsNumber: null, // not needed for message with identity
        assignee: null, // TODO: When we implement the FE part of assign on send, we'll update this to be the assignee data
        trySendViaNhsApp: null,
    };
};
