import { Tokens } from "@accurx/design";
import { Table } from "reactstrap";
import styled from "styled-components";

export const StyledTable = styled(Table)`
    background-color: ${Tokens.COLOURS.greyscale.white};

    thead th {
        border-bottom-width: 1px;
    }
`;
