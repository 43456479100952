import React from "react";

import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface VaccineSiteSetupPublishModalProps {
    isPublishing: boolean;
    showPublishModal: boolean;
    onToggleModal: () => void;
    onPublish: () => void;
    siteSetupSessionLoading: boolean;
}

export const VaccineSiteSetupPublishModal = ({
    isPublishing,
    showPublishModal,
    onToggleModal,
    onPublish,
    siteSetupSessionLoading,
}: VaccineSiteSetupPublishModalProps): JSX.Element => (
    <Modal
        isOpen={showPublishModal}
        toggle={onToggleModal}
        fade={false}
        backdropClassName="bg-zinc"
    >
        <ModalHeader tag="div">
            <Text variant="subtitle" as="h1" skinny>
                {isPublishing ? "Set clinic live" : "Unpublish this clinic?"}
            </Text>
        </ModalHeader>
        <ModalBody className={"bg-cloud"}>
            {isPublishing ? (
                <>
                    <Text>
                        By setting a clinic live, it will become available for
                        your patients to book into.
                    </Text>
                    <Text>
                        Unless you have ticked that it's for 2nd bookings only,
                        then all patients will be able to book into this clinic.
                    </Text>
                    <Text>
                        If patients are booking in for their 2nd , they will
                        only be able to see and book into clinics with the{" "}
                        <b>correct vaccine type</b> (if they had Pfizer for
                        their 1st, then any Pfizer clinic).
                    </Text>
                </>
            ) : (
                <>
                    <Text>
                        You are about to unpublish this clinic, which means
                        patients can no longer book into it. This will not
                        cancel patients who are already booked in, the practices
                        who invited them will need to do this individually for
                        patients from their sent invites page. Once all bookings
                        are removed, you can delete this clinic and and create a
                        replacement.
                    </Text>
                </>
            )}
            <Text>Do you wish to continue?</Text>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
            <Button
                text="Cancel"
                theme="secondary"
                onClick={onToggleModal}
                className="mx-1"
            />
            <Button
                text={isPublishing ? "Set Live" : "Unpublish"}
                onClick={onPublish}
                disabled={siteSetupSessionLoading}
            />
        </ModalFooter>
    </Modal>
);
