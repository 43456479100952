import { Icon, IconNameVariants } from "@accurx/design";

import {
    StyledFlexDiv,
    StyledSubHeaderDiv,
    StyledText,
} from "app/patientLists/AllPatientLists.styles";

type PatientListSubheaderProps = {
    icon: IconNameVariants;
    text: string;
    button?: JSX.Element;
};

export const Subheader = ({
    icon,
    text,
    button,
}: PatientListSubheaderProps) => (
    <StyledSubHeaderDiv>
        <StyledFlexDiv>
            <Icon
                name={icon}
                halo={{
                    colour: "blue",
                    luminosity: "high",
                }}
                size={4}
            />
            <StyledText variant="label">{text}</StyledText>
        </StyledFlexDiv>
        {button}
    </StyledSubHeaderDiv>
);
