import { useState } from "react";

import {
    FeatureName,
    Organisation,
    isFeatureEnabledForWorkspace,
    useAuth,
} from "@accurx/auth";
import {
    Ds,
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import {
    MyWorkspaceWarningModal,
    SelectNhsPharmacyModal,
    WORKSPACE_MANAGEMENT_PRIMARY_ROUTES,
    WORKSPACE_MANAGEMENT_ROUTES,
    isMyWorkspace,
    isPharmacy,
    isUserCreatedWorkspace,
    isUserOnlyInDefaultWorkspaceWithinOrg,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import { useAnalytics } from "reduxQuarantine/useAnalytics";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import {
    IsCollapsed,
    StyledCollapsibleText,
} from "app/layout/navigationMenu/NavigationMenu.styles";
import { useIsInOrganisation } from "app/organisations/hooks/useIsInOrganisation";
import { OrganisationHelper } from "shared/OrganisationHelper";
import {
    ROUTES,
    ROUTES_CHAIN,
    ROUTES_PRIMARY,
    ROUTES_WORKSPACE,
} from "shared/Routes";
import { isChainPathname } from "shared/RoutesHelper";
import { useIsMobile } from "shared/useIsMobile";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import { ActiveState, COLOURS, LinkVariant } from "../navLink";
import { StyledButton } from "../navLink/NavLink.styles";
import {
    StyledBadge,
    StyledDivider,
    StyledText,
} from "./SelectOrganisationPopover.styles";
import { WorkspacePopoverItem } from "./WorkspacePopoverItem";

const SelectOrganisationPopover = ({
    onClick,
    isCollapsed,
}: {
    onClick?: () => void;
    isCollapsed?: IsCollapsed;
}): JSX.Element => {
    const history = useHistory();
    const { selectedOrgId, setSelectedOrgId } = useSelectedOrganisation();
    const { user } = useAuth();

    const isMobile = useIsMobile();
    const track = useAnalytics();

    const isActive = !!useRouteMatchOrTwoFactorPrompt({
        path: [
            ROUTES_PRIMARY.workspaceSettings,
            WORKSPACE_MANAGEMENT_PRIMARY_ROUTES.workspaceOnboarding,
        ],
    });
    const activeState = isActive ? ActiveState.Active : ActiveState.Inactive;
    const textColour = COLOURS.Primary[activeState];

    const [open, setOpen] = useState(false);
    const [showPharmacyModal, setShowPharmacyModal] = useState(false);
    const [selectedMyWorkspace, setSelectedMyWorkspace] =
        useState<Organisation | null>(null);
    const isInOrganisation = useIsInOrganisation();

    if (isInOrganisation === false) {
        Log.error("SelectOrganisation expects user to be in an organisation");
    }

    const allWorkspaces = user.isLoggedIn ? [...user.organisations] : [];

    const isCurrentWorkspace = (orgId: number | null) =>
        orgId === selectedOrgId;

    // Gets the current workspace
    const currentWorkspace = allWorkspaces.find(({ orgId }) =>
        isCurrentWorkspace(orgId),
    );

    // Remove selected workspace from list of workspaces
    const otherWorkspaces = allWorkspaces.filter(
        ({ orgId }) => !isCurrentWorkspace(orgId),
    );

    const sortedWorkspacesExcludingCurrent = otherWorkspaces.sort((a, b) =>
        a.organisationName.toLowerCase() > b.organisationName.toLowerCase()
            ? 1
            : -1,
    );

    const isPendingRequestsBadgeEnabled = Boolean(
        currentWorkspace?.settings.isAdminUser &&
            (isPharmacy(currentWorkspace) ||
                isUserCreatedWorkspace(currentWorkspace)),
    );

    // Poll every 5 mins to keep approval requests count up-to-date
    const { data: { unapprovedUsers = [] } = {} } = useWorkspaceUsersQuery(
        { workspaceId: Number(selectedOrgId) },
        {
            enabled: isPendingRequestsBadgeEnabled,
            refetchIntervalInBackground: true,
            refetchInterval: 1000 * 60 * 5,
        },
    );

    const showPendingRequestsBadge =
        isPendingRequestsBadgeEnabled && unapprovedUsers.length > 0;

    const selectWorkspace = (workspace: Organisation) => {
        const workspaceId = workspace.orgId;
        setSelectedOrgId(workspaceId);

        if (isChainPathname(history.location.pathname)) {
            history.push(
                ROUTES_CHAIN.practicesOrgId.replace(
                    ":orgId",
                    workspaceId.toString(),
                ),
            );
        } else {
            history.push(ROUTES.home);
        }
    };

    const navigateToJoinOrganisation = () =>
        history.push(ROUTES.joinOrganisation, { showBackButton: true });

    const navigateToJoinWorkspace = () => {
        // Should never occur, since we only call this function if the current workspace has a feature flag
        // So if there is no current workspace, we wouldn't end up in this callback
        // But this is here just in case as a failsafe & for type safety
        if (selectedOrgId === null) {
            Log.error(
                "[Workspaces] user attempted to click `Join a workspace` while not in a workspace",
            );
            toast(
                "Something went wrong. Please refresh the page and try again.",
            );
            return;
        }

        history.push(
            generatePath(WORKSPACE_MANAGEMENT_ROUTES.selectOrganisation, {
                workspaceId: selectedOrgId,
            }),
        );
    };

    const onClickWorkspace = (organisation: Organisation) => {
        track("NavigationOrganisation MenuItem Click", {
            optionSelected: "SwitchPractice",
            origin: history.location.pathname,
        });
        onClick?.();

        if (
            OrganisationHelper.isNhsPharmacy(organisation) &&
            user.isLoggedIn &&
            user.organisations.filter(OrganisationHelper.isPharmacy).length > 0
        ) {
            setShowPharmacyModal(true);
        } else if (
            user.isLoggedIn &&
            !isCurrentWorkspace(organisation.orgId) &&
            isFeatureEnabledForWorkspace(
                FeatureName.DissuadeFromUsingMyWorkspace,
                organisation,
            ) &&
            isMyWorkspace(organisation) &&
            !isUserOnlyInDefaultWorkspaceWithinOrg({ user, organisation })
        ) {
            setSelectedMyWorkspace(organisation);
        } else {
            selectWorkspace(organisation);
        }

        setOpen(false);
    };

    const onClickSettings = (currentOrg: Organisation) => {
        history.push(
            generatePath(ROUTES_WORKSPACE.workspaceGeneralSettings, {
                workspaceId: currentOrg.orgId,
            }),
        );
        setOpen(false);
    };

    const handleTriggerClick = () => {
        if (!open) {
            track("NavigationMenu Button Click", {
                origin: history.location.pathname,
                navigationOptionSelected: "Workspace",
            });
        }
    };

    const isFlexibleWorkspaceEnabled = useFeatureFlag(
        FeatureName.FlexibleWorkspace,
    );

    const onClickJoin = () => {
        track("NavigationOrganisation MenuItem Click", {
            origin: history.location.pathname,
            optionSelected: "JoinAnOrganisation",
        });
        onClick?.();
        isFlexibleWorkspaceEnabled
            ? navigateToJoinWorkspace()
            : navigateToJoinOrganisation();
        setOpen(false);
    };

    const isUserWorkspaceOnboarded = user.isLoggedIn
        ? user.organisations.some(isUserCreatedWorkspace)
        : false;

    const userflowClasses =
        "workspace-switcher" +
        (isUserWorkspaceOnboarded ? " workspace-onboarded" : "") +
        (isFlexibleWorkspaceEnabled ? " flexible-workspace-enabled" : "");

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <StyledButton
                    className={userflowClasses}
                    onClick={handleTriggerClick}
                    variant={LinkVariant.Primary}
                    activestate={activeState}
                    data-userflow-id="workspace-switcher"
                    data-testid="primary-nav-workspace-switcher-button"
                >
                    <Icon size={4} name="Carehome" colour={textColour} />
                    <StyledCollapsibleText
                        isCollapsed={isCollapsed}
                        forwardedAs="span"
                        variant="label"
                        colour={textColour}
                    >
                        {isFlexibleWorkspaceEnabled
                            ? "Workspace"
                            : "Organisation"}
                    </StyledCollapsibleText>
                    {showPendingRequestsBadge && (
                        <StyledBadge
                            radius="round"
                            color="red"
                            luminosity="low"
                            data-testid="approval-requests-badge"
                        >
                            {unapprovedUsers.length}
                        </StyledBadge>
                    )}
                </StyledButton>
            </PopoverTrigger>
            <PopoverContent
                side={isMobile ? "top" : "right"}
                align={isMobile ? "start" : "end"}
                data-testid="primary-nav-workspace-switcher-content"
            >
                {currentWorkspace && (
                    <>
                        <WorkspacePopoverItem
                            isCurrentWorkspace={true}
                            defaultOrgName={
                                currentWorkspace.organisationNodeName
                            }
                            workspace={currentWorkspace}
                            onSelectWorkspace={onClickWorkspace}
                        />
                        <PopoverItem
                            onClick={() => onClickSettings(currentWorkspace)}
                            data-userflow-id="workspace-switcher-settings"
                        >
                            <Icon name="Cog" size={3} />
                            <Text skinny>
                                {isFlexibleWorkspaceEnabled
                                    ? "Workspace"
                                    : "Organisation"}{" "}
                                settings
                            </Text>
                            {showPendingRequestsBadge && (
                                <Ds.Badge
                                    radius="round"
                                    color="red"
                                    luminosity="low"
                                    data-testid="approval-requests-badge"
                                >
                                    {unapprovedUsers.length}
                                </Ds.Badge>
                            )}
                        </PopoverItem>
                    </>
                )}
                <StyledDivider />

                {sortedWorkspacesExcludingCurrent.map((workspace) => {
                    return (
                        <WorkspacePopoverItem
                            key={workspace.orgId}
                            isCurrentWorkspace={false}
                            defaultOrgName={workspace.organisationNodeName}
                            workspace={workspace}
                            onSelectWorkspace={onClickWorkspace}
                        />
                    );
                })}
                <PopoverItem
                    onClick={onClickJoin}
                    data-userflow-id={
                        isFlexibleWorkspaceEnabled
                            ? "workspace-switcher-join-workspace"
                            : ""
                    }
                >
                    <StyledText variant="body" colour="blue">
                        <Icon name="Plus" colour="blue" size={3} />
                        {isFlexibleWorkspaceEnabled
                            ? "Join a workspace"
                            : "Join an organisation"}
                    </StyledText>
                </PopoverItem>
            </PopoverContent>

            {showPharmacyModal && (
                <SelectNhsPharmacyModal
                    handleClose={() => setShowPharmacyModal(false)}
                    handleSelectWorkspace={selectWorkspace}
                />
            )}
            {Boolean(selectedMyWorkspace) && (
                <MyWorkspaceWarningModal
                    handleClose={() => setSelectedMyWorkspace(null)}
                    handleSelectMyWorkspace={() => {
                        selectedMyWorkspace &&
                            selectWorkspace(selectedMyWorkspace);
                        setSelectedMyWorkspace(null);
                    }}
                />
            )}
        </Popover>
    );
};

export { SelectOrganisationPopover };
