import { useMemo } from "react";

import { FloreyResponseItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type FloreyResponseProps = {
    conversationItem: FloreyResponseItem;
};

export const FloreyResponse = ({ conversationItem }: FloreyResponseProps) => {
    const hasPatientResponded = useMemo(
        () => conversationItem.sections.length > 0,
        [conversationItem.sections.length],
    );

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {hasPatientResponded ? "Patient" : "Accurx"}:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                {hasPatientResponded ? (
                    <UI.Text as="span" variant="preview">
                        <UI.Text as="span" className="sr-only">
                            Message summary:
                        </UI.Text>
                        {conversationItem.floreyName} has been submitted
                    </UI.Text>
                ) : (
                    <UI.Text as="span" variant="preview">
                        <UI.VisuallyHidden as="span">
                            Message summary:
                        </UI.VisuallyHidden>
                        Patient did not respond
                    </UI.Text>
                )}
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
