import React, { useState } from "react";

import { Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";

import { PatientListAppointmentDay } from "api/FlemingDtos";
import DropdownMenuOption from "app/sharedComponents/dropdown/DropdownMenuOption.component";
import SelectDropdown from "app/sharedComponents/dropdown/SelectDropdown.component";
import { useAppSelector } from "store/hooks";

import { actionCreators } from "./PatientListsActions";
import { OrderMethod } from "./PatientListsReducer";

const sortOptions: OrderMethod[] = [
    OrderMethod.firstDateAdded,
    OrderMethod.lastDateAdded,
    OrderMethod.sortByAZ,
    OrderMethod.sortByZA,
];

type SortByFilterProps = {
    currentDaySelected: PatientListAppointmentDay;
};

export const SortByFilter = ({
    currentDaySelected: { date },
}: SortByFilterProps): JSX.Element => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const onToggle = (): void => {
        setOpen((prevState) => !prevState);
    };
    const currentListPatientsOrder = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsOrder,
        shallowEqual,
    );

    const changeOrder = (order: OrderMethod): void => {
        dispatch(actionCreators.changeOrderMethod(order));
    };

    const getSortDropdownText = (orderMethod: OrderMethod): string => {
        switch (orderMethod) {
            case OrderMethod.firstDateAdded:
                return !date
                    ? "First added"
                    : "Appointment time: first to last";
            case OrderMethod.lastDateAdded:
                return !date ? "Last added" : "Appointment time: last to first";
            case OrderMethod.sortByAZ:
                return "Surname A-Z";
            case OrderMethod.sortByZA:
                return "Surname Z-A";
        }
    };

    const renderSortSelectionOption = (
        orderMethod: OrderMethod,
    ): JSX.Element => {
        const handleClick = (): void => {
            changeOrder(orderMethod);
        };
        return (
            <DropdownMenuOption
                key={`${orderMethod}`}
                isActive={currentListPatientsOrder === orderMethod}
                onClick={handleClick}
            >
                {getSortDropdownText(orderMethod)}
            </DropdownMenuOption>
        );
    };

    return (
        <>
            <Text variant="label" skinny>
                Sorted by
            </Text>
            <SelectDropdown
                isOpen={open}
                onToggle={onToggle}
                dropdownText={getSortDropdownText(currentListPatientsOrder)}
            >
                {sortOptions.map(renderSortSelectionOption)}
            </SelectDropdown>
        </>
    );
};
