import { useMemo } from "react";

import { FeatureName } from "@accurx/auth";

import { PracticeDetails } from "app/practices/Practices.types";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    BatchAndAppointmentRemindersFeatureFlags,
    FloreyPlusFeatureFlags,
    PatientTriageFeatureFlags,
} from "../utils";

export const useModules = (): Optional<{
    batchAndAppointmentReminders: boolean;
    floreyPlus: boolean;
    patientTriage: boolean;
    inhalerRct: boolean;
    floreyBuilder: boolean;
    accumail: boolean;
    accubook: {
        organisation: boolean;
        deliverySite: boolean;
        vaccineLinks: boolean;
    };
    fragmentsDashboard: boolean;
    reporting: boolean;
}> => {
    const user = useAppSelector(({ account }) => account);

    const practice = useAppSelector(({ practices }) =>
        practices.items.find(
            (x: PracticeDetails) =>
                x.id.toString() === practices.selectedPractice,
        ),
    );

    const currentOrg = OrganisationHelper.getOrganisation(user);

    const isBatchAndAppointmentRemindersEnabled = useMemo(
        () =>
            BatchAndAppointmentRemindersFeatureFlags.every((ff) =>
                OrganisationHelper.isFeatureEnabled(currentOrg, ff),
            ),
        [currentOrg],
    );

    const isFloreyPlusEnabled = useMemo(
        () =>
            Object.values(FloreyPlusFeatureFlags).every((ff) =>
                OrganisationHelper.isFeatureEnabled(currentOrg, ff),
            ),
        [currentOrg],
    );

    const isPatientTriageEnabled = useMemo(
        () =>
            Object.values(PatientTriageFeatureFlags).every((ff) =>
                OrganisationHelper.isFeatureEnabled(currentOrg, ff),
            ),
        [currentOrg],
    );

    const isInhalerEnabled = useMemo(
        () =>
            OrganisationHelper.isFeatureEnabled(
                currentOrg,
                FeatureName.BehaviourChangeInhalerRct,
            ),
        [currentOrg],
    );

    const isFloreyBuilderEnabled = useMemo(
        () =>
            OrganisationHelper.isFeatureEnabled(
                currentOrg,
                FeatureName.FloreyBuilder,
            ),
        [currentOrg],
    );

    const isAccubookOrganisation = useMemo(
        () =>
            OrganisationHelper.isFeatureEnabled(
                currentOrg,
                FeatureName.VaccinePractice,
            ),
        [currentOrg],
    );

    const isAccumailEnabled = useMemo(
        () =>
            OrganisationHelper.isFeatureEnabled(
                currentOrg,
                FeatureName.ExternalEmail,
            ),
        [currentOrg],
    );

    const isAccubookDeliverySite = useMemo(
        () =>
            OrganisationHelper.isFeatureEnabled(
                currentOrg,
                FeatureName.VaccineDeliverySite,
            ),
        [currentOrg],
    );

    const isFragmentsDashboardEnabled =
        useIsFeatureEnabled("FragmentsDashboard");

    const isReportingEnabled = useIsFeatureEnabled(
        FeatureName.EmbeddedPowerBiPOC,
    );

    return {
        batchAndAppointmentReminders: isBatchAndAppointmentRemindersEnabled,
        floreyPlus: isFloreyPlusEnabled,
        patientTriage: isPatientTriageEnabled,
        inhalerRct: isInhalerEnabled,
        floreyBuilder: isFloreyBuilderEnabled,
        accumail: isAccumailEnabled,
        accubook: {
            organisation: isAccubookOrganisation,
            deliverySite: isAccubookDeliverySite,
            vaccineLinks: practice?.showVaccineLinks || false,
        },
        fragmentsDashboard: isFragmentsDashboardEnabled,
        reporting: isReportingEnabled,
    };
};
