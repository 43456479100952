import { Button, Flex } from "@accurx/design";
import styled from "styled-components";

export const LogInOptionsButton = styled(Button)`
    width: max-content;
`;

export const FullWidthFlex = styled(Flex).attrs({
    flexDirection: "column",
})`
    width: 100%;
`;
