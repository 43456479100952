import moment from "moment";

import { UsagePeriod } from "../moduleAccess.types";

export const getDay = ({ from, to }: UsagePeriod): string => {
    if (moment(from).isSame(to, "month")) {
        return moment(from).date().toString();
    }
    return getDayMonth(from);
};

export const getDayMonth = (isoDate: string): string => {
    return moment(isoDate).format("Do MMMM");
};
