/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import {
    VaccineODSSearchResponse,
    VaccineSiteSession,
    VaccineSiteSetup,
    VaccineSiteSetupLinksRequest,
    VaccineSiteSetupLocationRequest,
    VaccineSiteSetupResponse,
    VaccineSiteSetupSessionResponse,
} from "app/practices/vaccine/siteSetup/SiteSetup.types";

const ENDPOINTS = {
    recallSetups: "/api/recall/Practices/:practiceId/RecallSetups",
    recallSetupsSiteId:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId",
    recallSetupsLink:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Link",
    recallSetupsSessions:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Sessions",
    recallSetupsSessionsUrl:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Sessions/:sessionIdUrl",
    recallSetupsSessionsPublish:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Sessions/:sessionId/Publish",
    recallSetupsSessionsUnpublish:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Sessions/:sessionId/Unpublish",
    recallSetupsSessionsId:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/Sessions/:sessionId",
    recallSetupsFindOrg:
        "/api/recall/Practices/:practiceId/RecallSetups/:siteSetupId/FindOrganisations",
} as const;

export const getVaccineSiteSetup = (
    practiceId: string,
): Promise<IWrappedResult<VaccineSiteSetupResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetups,
            params: { practiceId },
        }),
    );

export const postVaccineSiteSetupLocation = (
    practiceId: string,
    siteSetupId: string,
    request: VaccineSiteSetupLocationRequest,
): Promise<IWrappedResult<VaccineSiteSetup>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSiteId,
            params: { practiceId, siteSetupId },
        }),
        request,
    );

export const postVaccineSiteSetupLink = (
    practiceId: string,
    siteSetupId: string,
    request: VaccineSiteSetupLinksRequest,
): Promise<IWrappedResult<VaccineSiteSetup>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsLink,
            params: { practiceId, siteSetupId },
        }),
        request,
    );

export const getVaccineSiteSetupSessions = (
    practiceId: string,
    siteSetupId: string,
): Promise<IWrappedResult<VaccineSiteSetupSessionResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSessions,
            params: { practiceId, siteSetupId },
        }),
    );

export const postVaccineSiteSetupSession = (
    practiceId: string,
    siteSetupId: string,
    sessionIdUrl: string,
    request: any,
): Promise<IWrappedResult<VaccineSiteSession>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSessionsUrl,
            params: { practiceId, siteSetupId, sessionIdUrl },
        }),
        request,
    );

export const postVaccineSiteSetupPublish = (
    practiceId: string,
    siteSetupId: string,
    sessionId: string,
): Promise<IWrappedResult<VaccineSiteSession>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSessionsPublish,
            params: { practiceId, siteSetupId, sessionId },
        }),
        {},
    );

export const postVaccineSiteSetupUnpublish = (
    practiceId: string,
    siteSetupId: string,
    sessionId: string,
): Promise<IWrappedResult<VaccineSiteSession>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSessionsUnpublish,
            params: { practiceId, siteSetupId, sessionId },
        }),
        {},
    );

export const deleteVaccineSiteSession = (
    practiceId: string,
    siteSetupId: string,
    sessionId: string,
): Promise<IWrappedResult<undefined>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsSessionsId,
            params: { practiceId, siteSetupId, sessionId },
        }),
    );

export const getVaccineSiteSetupOds = (
    practiceId: string,
    siteSetupId: string,
    odsCode: string,
): Promise<IWrappedResult<VaccineODSSearchResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.recallSetupsFindOrg,
            params: { practiceId, siteSetupId },
            query: `?odsCode=${odsCode}`,
        }),
    );
