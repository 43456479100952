import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MatchTicketToPatientOptions = {
    ticketIdentity: TicketIdentity;
    latestToken: string;
    workspaceId: number;
    patientToken: string;
};

export const matchTicketToPatientWithToken: ConciergeFetcher<
    MatchTicketToPatientOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const response = await TicketApiClient.matchTicketToPatientWithToken({
        organisationId: arg.workspaceId,
        patientToken: arg.patientToken,
        latestToken: arg.latestToken,
        ticketIdentity: arg.ticketIdentity,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
