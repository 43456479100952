import { PatientSummary } from "@accurx/concierge/types";
import { useNativeSubscription } from "@accurx/native";
import { usePatientIdentityQuery } from "domains/inbox/hooks/queries/usePatientIdentityQuery";

/**
 * If there is a medical record connection, it returns
 * true or false, depending on whether the internal ids of the
 * medical record patient and the one being replied to match.
 *
 * In case
 * - there is no medical record connection
 * - no patient selected
 * - query to fetch medical record connection patient is loading
 * it returns null
 */
export const useIsReplyPatientSameAsEmrPatient = ({
    replyPatient,
}: {
    replyPatient: PatientSummary;
}) => {
    const medicalRecordCurrentPatientResult = useNativeSubscription(
        "SubscribeCurrentPatient",
    );
    const medicalRecordPatientExternalIds =
        medicalRecordCurrentPatientResult.data?.patientExternalIdentityDto
            .patientExternalIds ?? [];

    // Only fetch the internal id if we have a valid payload to provide
    const shouldFetchMedicalRecordInternalId =
        medicalRecordPatientExternalIds.length > 0;

    const {
        data: medicalRecordCurrentPatientIds,
        status: internalIdFetchingStatus,
    } = usePatientIdentityQuery(
        medicalRecordPatientExternalIds,
        shouldFetchMedicalRecordInternalId,
    );

    return {
        status: shouldFetchMedicalRecordInternalId
            ? internalIdFetchingStatus
            : medicalRecordCurrentPatientResult.status,
        isSamePatient:
            internalIdFetchingStatus === "success"
                ? medicalRecordCurrentPatientIds.accuRxId ===
                  replyPatient.patientId
                : null,
    };
};
