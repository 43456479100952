import { returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    BatchMessageDetails,
    BatchMessageStatusResponse,
} from "@accurx/api/patient-messaging";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import {
    bmGetUploadDetails as getBatchDetails,
    bmGetUploadStatus as getDemographicsStatus,
} from "api/BatchMessaging";
import { BatchStatus } from "app/batchMessage/gp/BatchMessage.types";

type BatchMessageUploadStatusQueryVariables = {
    workspaceId: string | null;
    batchId: string;
    onDemographicsPopulated?: (details: BatchMessageDetails) => void;
};

const BATCH_UPLOAD_CSV_GET_DEMOGRAPHICS_CACHE_KEY =
    "BatchUploadCsvGetDemographics";

type BatchMessageDemographicsStatusQueryResult =
    | { demographicsState: "Populated"; details: BatchMessageDetails }
    | {
          demographicsState: "Fetching";
          progressPercentage: BatchMessageStatusResponse["progressPercentage"];
      }
    | { demographicsState: "Other" };

/**
 * Query used when demographics are being fetched serverside. This is a slow process,
 * so we poll the upload status from the server every 1 second, until all the demographics
 * are populated (signified by status going to DemographicsPopulated). At this point, the hook
 * calls the upload details endpoint, returns this and stops polling.
 */
export const useBatchMessageDemographicsStatusQuery = ({
    workspaceId,
    batchId,
    onDemographicsPopulated,
}: BatchMessageUploadStatusQueryVariables) => {
    const result: UseQueryResult<
        BatchMessageDemographicsStatusQueryResult,
        Error
    > = useQuery({
        queryKey: [
            BATCH_UPLOAD_CSV_GET_DEMOGRAPHICS_CACHE_KEY,
            { workspaceId, batchId },
        ],
        queryFn:
            async (): Promise<BatchMessageDemographicsStatusQueryResult> => {
                if (workspaceId === null) {
                    throw new Error(
                        "workspaceId not supplied to BatchMessageDemographicsStatusQuery",
                    );
                }

                const demographicsStatusResponse = await getDemographicsStatus(
                    workspaceId,
                    batchId,
                );
                const demographicsStatus = returnDataOrThrow(
                    demographicsStatusResponse,
                );

                if (
                    demographicsStatus.status ===
                    BatchStatus.DemographicsPopulated
                ) {
                    const batchDetailsResponse = await getBatchDetails(
                        workspaceId,
                        batchId,
                    );
                    const details = returnDataOrThrow(batchDetailsResponse);
                    onDemographicsPopulated && onDemographicsPopulated(details);
                    return {
                        demographicsState: "Populated",
                        details,
                    };
                }

                if (
                    demographicsStatus.status ===
                    BatchStatus.FetchingDemographics
                ) {
                    return {
                        demographicsState: "Fetching",
                        progressPercentage:
                            demographicsStatus.progressPercentage,
                    };
                }

                return {
                    demographicsState: "Other",
                };
            },
        refetchInterval: (data) =>
            data?.demographicsState === "Fetching" ? 1000 : false,
    });

    return result;
};
