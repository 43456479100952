import { AppSettings, Log } from "@accurx/shared";
import userflow from "userflow.js";

const USERFLOW_TOKEN =
    AppSettings.accurxEnvironment === "production"
        ? process.env.REACT_APP_WEB_CLIENT_PRODUCTION_USERFLOW_TOKEN
        : process.env.REACT_APP_WEB_CLIENT_DEVELOPMENT_USERFLOW_TOKEN;

export const loadUserflow = (userId: string) => {
    if (USERFLOW_TOKEN === undefined) {
        Log.error("No Userflow token found");
        return;
    }

    try {
        if (!userflow.isIdentified()) {
            userflow.init(USERFLOW_TOKEN);
            userflow.identify(userId);
            userflow.setInferenceAttributeNames(["id", "data-userflow-id"]);
        }
    } catch (e) {
        Log.error(`Error while initialising Userflow: ${e}`);
    }
};

// Deliberately ignoring Userflow promises to prevent failed API calls from
// blocking the main thread
export const trackUserflowEvent = (event: UserflowEvent) => {
    try {
        if (!userflow.isIdentified()) {
            return;
        }
        userflow.track(event);
    } catch (e) {
        Log.warn(`Failed to track Userflow event '${event}'`);
    }
};

export enum UserflowEvent {
    MESSAGE_TEMPLATE_CREATED = "message_template_created",
    PATIENT_LIST_CREATED = "patient_list_created",
    PATIENT_MESSAGE_SENT = "patient_message_sent",
}
