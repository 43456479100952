import { TextColour, TextVariants } from "@accurx/design/dist/styles/types";

export const COLOURS: LinkVariantMap<TextColour> = {
    Primary: { Active: "night", Inactive: "white" },
    Secondary: { Active: "white", Inactive: "night" },
    Tertiary: { Active: "white", Inactive: "night" },
};

export const TEXT_VARIANTS: LinkVariantMap<TextVariants> = {
    Primary: { Active: "label", Inactive: "label" },
    Secondary: { Active: "label", Inactive: "body" },
    Tertiary: { Active: "note", Inactive: "preview" },
};

export enum LinkVariant {
    Primary = "Primary",
    Secondary = "Secondary",
    Tertiary = "Tertiary",
}

export enum ActiveState {
    Active = "Active",
    Inactive = "Inactive",
}

type LinkVariantMap<T> = {
    [key in LinkVariant]: { [key in ActiveState]: T };
};
