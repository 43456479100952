import React from "react";

import { Ds, Icon, Link, StackPanel, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { i } from "images";

import { useAnalytics } from "app/analytics/hooks";
import { useCurrentUser } from "app/organisations/hooks";

import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
} from "../../HomePage.styles";
import {
    StyledMarketingCardImage,
    StyledMarketingCardStackPanel,
    StyledMarketingCardSummary,
} from "../../styles/Cards.styles";

export const FloreyPlusMarketingCard = (): JSX.Element => {
    const { track } = useAnalytics();
    const userSettings = useCurrentUser();

    return (
        <StyledCard cardType="marketing" data-testid="florey-marketing">
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StyledMarketingCardStackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="LockLocked"
                        colour="stone"
                        theme="Fill"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.FLOREY_PLUS}
                    </Text>
                    <Ds.Badge color="yellow" radius="round">
                        {userSettings?.isApproved
                            ? "Premium feature"
                            : "Approved users only"}
                    </Ds.Badge>
                </StyledMarketingCardStackPanel>
            </StyledCardHeading>
            {userSettings?.isApproved && (
                <StyledCardContent>
                    <StackPanel
                        gutter={1.5}
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        horizontalContentAlignment="space-between"
                    >
                        <StyledMarketingCardSummary>
                            <Text>
                                Create and send mobile-friendly questionnaires
                                to your patients via SMS, or choose from a wider
                                range of questionnaires than previously
                                available.
                            </Text>
                            <Link
                                href={SupportUrls.FloreyFreeAndPaid}
                                text="Learn about Floreys"
                                iconName="OpenWindow"
                                openInNewTab={true}
                                onClick={() =>
                                    track("HomepageDiscoverResourceLinkClick", {
                                        homepageSection: SECTIONS.FLOREY_PLUS,
                                        homepageItem:
                                            "Learn about Custom Floreys",
                                    })
                                }
                            />
                        </StyledMarketingCardSummary>
                        <StyledMarketingCardImage
                            src={i(
                                "organisations/illustration-custom-floreys.svg",
                            )}
                            alt="Custom florey illustration"
                            aria-hidden="true"
                        />
                    </StackPanel>
                </StyledCardContent>
            )}
        </StyledCard>
    );
};
