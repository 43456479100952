export const PATIENT_INITIATED_API_ENDPOINTS = {
    workingHours:
        "/api/portal/:organisationId/settings/patientInitiated/workingHours",
    suspensionMessage:
        "/api/portal/:organisationId/settings/patientInitiated/suspensionMessage",
    assignments:
        "/api/conversation/statistics/:organisationId/settings/patientInitiated/assignments",
    fornightlyData:
        "/api/portal/:organisationId/settings/patientInitiated/dashboard/data/fortnightly",
    outcomeData:
        "/api/conversation/statistics/:organisationId/settings/patientInitiated/dashboard/outcomes",
    status: "/api/portal/:organisationId/settings/patientInitiated/Status",
    updateOutOfHours:
        "/api/portal/:organisationId/settings/patientInitiated/updateOutOfHours",
    scheduledOutOfHours:
        "/api/portal/:organisationId/settings/patientInitiated/updateScheduleOutHoursSettings",
    updatePrescriptionSettings:
        "/api/portal/:organisationId/settings/patientInitiated/updatePrescriptionSettings",
    updateDisableUntilSettings:
        "/api/portal/:organisationId/settings/patientInitiated/updateDisableUntilSettings",
    updateWelcomePage:
        "/api/portal/:organisationId/settings/patientInitiated/updateWelcomePage",
    updateSubmissionPage:
        "/api/portal/:organisationId/settings/patientInitiated/updateSubmissionPage",
    updateSubmissionSms:
        "/api/portal/:organisationId/settings/patientInitiated/updateSubmissionSms",
    updateOutOfHoursMessage:
        "/api/portal/:organisationId/settings/patientInitiated/updateOutOfHoursMessage",
    updateDisabledMessage:
        "/api/portal/:organisationId/settings/patientInitiated/UpdateDisabledMessage",
    updatePatientSelfReferral:
        "/api/portal/:organisationId/settings/patientInitiated/UpdatePatientSelfReferral",
} as const;
