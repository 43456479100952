import { ReactNode } from "react";

import * as UI from "@accurx/design";
import { AccurxLogoAvatar } from "domains/inbox/components/ConversationItem/components/AccurxLogoAvatar/AccurxLogoAvatar";

import { StyledOutcomeRecording } from "../OutcomeRecording.styles";

type HeaderProps = { children: ReactNode; text: string; isMinimised?: boolean };

export const Header = ({ text, isMinimised, children }: HeaderProps) => {
    return (
        <StyledOutcomeRecording.Header $showFullBorder={isMinimised}>
            <UI.Flex
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap="1"
            >
                <UI.Flex gap="1" alignItems="center">
                    <AccurxLogoAvatar />
                    <UI.Text skinny variant="note" colour="night">
                        {text}
                    </UI.Text>
                </UI.Flex>
                {children}
            </UI.Flex>
        </StyledOutcomeRecording.Header>
    );
};
