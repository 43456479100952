import { Tokens } from "@accurx/design";
import styled from "styled-components";

const { white, silver } = Tokens.COLOURS.greyscale;

export const MessageStatusList = styled.ul`
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 1rem;
    }
`;

export const MessageStatusTableWrapper = styled.div`
    margin-top: 3rem;
    border: solid 1px ${silver};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${white};
`;

export const MessageStatusTable = styled.table`
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    tr {
        box-shadow: inset 0px -1px 0px ${silver};
    }

    th {
        padding: 1rem;
        box-shadow: inset 0px -3px 0px ${silver};
    }

    td {
        padding: 1rem;
    }

    tr:last-child {
        box-shadow: none;
    }

    @media screen and (min-width: ${Tokens.BREAKPOINTS.lg}) {
        display: table;
    }
`;

export const MessageStatusTableEmpty = styled.div`
    text-align: center;
    display: block;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: ${silver};
    border-bottom-left-radius: ${Tokens.BORDERS.radius};
    border-bottom-right-radius: ${Tokens.BORDERS.radius};
    padding: 5rem;
`;

export const MessageStatusLoader = styled.div`
    margin-top: 5rem;
`;
