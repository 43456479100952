import { Log } from "@accurx/shared";

/*
 * TODO remove this when we have the answer we're looking for
 * TEMPORARY CHECK TO SEE IF AZURE CDN IS ACCESSIBLE
 *
 * We want to put fleming-client behind a CDN - but the CDN doesn't
 * allow us to specify a static IP, and some customers - e.g. prisons -
 * require a static IP as they lock down their network to only a certain
 * few allowed sites.
 *
 * However it may be that they already allow the Azure CDN as it's quite
 * likely many of them already use Microsoft products or other services
 * which use Microsoft products - so we want to get a sense of how
 * big a problem this might be before we try to come up with a complex
 * brittle fix for it.
 *
 * So for now, when we first load the page with an organisation id set,
 * we try to make a HEAD request to a url behind a CDN and see if it
 * succeeeds, sending an event to RudderStack in either case.
 */
export const checkCdnAccessibility = async (
    {
        organisationId,
        userId,
    }: {
        userId: Optional<string>;
        organisationId: Optional<number>;
    },
    retryTimes = 3,
): Promise<void> => {
    if (window.location.hostname !== "web.accurx.com") return;
    if (!organisationId || isNaN(organisationId)) return;

    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 3000);
    try {
        const response = await fetch(
            `https://cdn.sandbox.accurx.com/api/portal/iscdnreachable?organisationId=${organisationId}`,
            {
                signal: controller.signal,
            },
        );
        const text = await response.text();
        console.log(text);
        if (text === "Accurx") {
            await fetch(
                `/api/portal/iscdnreachable?organisationId=${organisationId}&success=true`,
                {
                    method: "post",
                },
            );
        } else {
            // this will get caught and logged below
            throw new Error(
                "CDNAccessibilityCheck: Got back unexpected response",
            );
        }
    } catch (ex) {
        if (retryTimes > 1) {
            checkCdnAccessibility({ organisationId, userId }, retryTimes - 1);
        } else {
            try {
                await fetch(
                    `/api/portal/iscdnreachable?organisationId=${organisationId}&success=false`,
                    {
                        method: "post",
                    },
                );
            } catch (e) {
                Log.error(e, {
                    tags: { domain: "CDN Accessibility check" },
                });
            }
        }
    } finally {
        clearTimeout(timeout);
    }
};
