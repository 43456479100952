import React from "react";

import { Icon } from "@accurx/design";

import { useCurrentWorkspaceUnreadCounts } from "../hooks/useCurrentWorkspaceUnreadCounts";
import { WorkspaceConversationUnreadCountBadge } from "../secondaryNavigation/WorkspaceConversationUnreadCountBadge";
import {
    StyledNavigationToggle,
    StyledWorkspaceConversationsTotalUnreadCountBadge,
} from "./NavigationToggle.styles";

type NavigationToggleProps = {
    onClick: () => void;
    action?: "open" | "close";
    id?: string;
};

const NavigationToggle = ({
    onClick,
    action = "open",
    id,
}: NavigationToggleProps): JSX.Element => {
    const unreadData = useCurrentWorkspaceUnreadCounts();

    return (
        <StyledNavigationToggle
            id={id}
            onClick={onClick}
            theme="transparent"
            data-testid="nav toggle"
            aria-label="Menu"
        >
            {action === "open" && unreadData && unreadData.unreadCount > 0 && (
                <StyledWorkspaceConversationsTotalUnreadCountBadge>
                    <WorkspaceConversationUnreadCountBadge
                        unreadCount={unreadData.unreadCount}
                    />
                </StyledWorkspaceConversationsTotalUnreadCountBadge>
            )}
            <Icon name={action === "open" ? "Menu" : "Cross"} />
        </StyledNavigationToggle>
    );
};

export { NavigationToggle };
