import { useMemo } from "react";

import { match, matchPath, useLocation } from "react-router";

import { ROUTES_WORKSPACE } from "shared/Routes";
import {
    createAssignedToRule,
    createSentPatientMessageRule,
    createStatusRule,
} from "shared/concierge/conversations/ConversationGroup.utils";
import { ConversationGroupRuleset } from "shared/concierge/conversations/types/conversationGroup.types";

import { getStatusFromQueryParam } from "./useConversationGroupStatus";
import { useCurrentUserId } from "./useCurrentUserId";

type UrlToConversationGroupOptions = {
    currentUserId: string;
};

type RelationshipParamValues = "assigned" | "sent";

/**
 * Returns a conversation group ruleset calculated from the current URL.
 *
 * Examples:
 *   /conversations/users/me - all conversations assigned to me with Open status
 *   /conversations/users/me?status=done - all conversations assigned to me with Done status
 *   /conversations/users/123 - all conversation assigned to user with id 123 with Open status
 *   /conversations/teams/456?status=done - all conversation assigned to team with id 456 with Done status
 *
 * Returning null means that the URL does not represent a valid ruleset
 */
export const useConversationGroupRuleset = (
    inboxType?: "GP" | "Trust",
): ConversationGroupRuleset | null => {
    const location = useLocation();

    const pathname =
        inboxType === "GP"
            ? location.pathname.replace("/inbox", "")
            : location.pathname;

    const url = `${pathname}${location.search}`;
    const currentUserId = useCurrentUserId();

    return useMemo(() => {
        return mapUrlToConversationGroupRuleset(url, {
            currentUserId,
        });
    }, [url, currentUserId]);
};

/**
 * Takes a URL and constructs a conversation group ruleset from it.
 *
 * We allow a shorthand of "/me" to refer to the current user. This method
 * replaces "me" with the current user so it can be treated like any other
 * user group.
 *
 * Examples:
 *   /conversations/users/me/assigned - all conversations assigned to the current user with Open status
 *   /conversations/users/me/assigned?status=done - all conversations assigned to the current user with Done status
 *   /conversations/users/123/assigned - all conversation assigned to user with id 123 with Open status
 *   /conversations/teams/456/assigned?status=done - all conversation assigned to team with id 456 with Done status
 */
export const mapUrlToConversationGroupRuleset = (
    url: string,
    options: UrlToConversationGroupOptions,
): ConversationGroupRuleset | null => {
    const userMatch = getMatchAndSearchParamsWithUserSubstitution(
        url,
        options.currentUserId,
    );

    if (userMatch) {
        switch (userMatch.match.params.relationship) {
            case "assigned":
                return {
                    rules: [
                        createAssignedToRule(
                            "User",
                            userMatch.match.params.userId,
                        ),
                        createStatusRule(
                            getStatusFromQueryParam(
                                userMatch.search.get("status"),
                            ),
                        ),
                    ],
                };
            case "sent":
                return {
                    rules: [
                        createSentPatientMessageRule(
                            userMatch.match.params.userId,
                        ),
                    ],
                };
        }
    }

    const teamMatch = getMatchAndSearchParams<{ teamId: string }>(
        url,
        ROUTES_WORKSPACE.conversationsTeam,
    );
    if (teamMatch) {
        return {
            rules: [
                createAssignedToRule("Team", teamMatch.match.params.teamId),
                createStatusRule(
                    getStatusFromQueryParam(teamMatch.search.get("status")),
                ),
            ],
        };
    }

    const allMatch = getMatchAndSearchParams<{
        relationship: RelationshipParamValues;
    }>(url, ROUTES_WORKSPACE.conversationsAll);
    if (allMatch) {
        switch (allMatch.match.params.relationship) {
            case "assigned":
                return {
                    rules: [
                        createStatusRule(
                            getStatusFromQueryParam(
                                allMatch.search.get("status"),
                            ),
                        ),
                    ],
                };
            case "sent":
                return {
                    rules: [createSentPatientMessageRule(null)],
                };
        }
    }

    return null;
};

/**
 * Takes a URL and parses it into a structure that allows you to easily
 * access the constituent parts of a user conv group. (user ID, status etc.)
 *
 * We allow a shorthand of "/me" to refer to the current user. This method
 * replaces "me" with the current user so it can be treated like any other
 * user group.
 *
 * If the URL does not match the format of a user group it returns null.
 */
const getMatchAndSearchParamsWithUserSubstitution = (
    url: string,
    currentUserId: string,
): {
    match: match<{ userId: string; relationship: RelationshipParamValues }>;
    search: URLSearchParams;
} | null => {
    const meUrl = getMatchAndSearchParams<{
        userId: string;
        relationship: RelationshipParamValues;
    }>(url, ROUTES_WORKSPACE.conversationsMe, (match) =>
        setCurrentUser(match, currentUserId),
    );

    if (meUrl) {
        return meUrl;
    }

    const userUrl = getMatchAndSearchParams<{
        userId: string;
        relationship: RelationshipParamValues;
    }>(url, ROUTES_WORKSPACE.conversationsUser, (match) =>
        replaceCurrentUser(match, currentUserId),
    );
    if (userUrl) {
        return userUrl;
    }

    return null;
};

const replaceCurrentUser = <T extends { userId: string }>(
    urlMatch: match<T>,
    currentUserId: string,
): match<T> => {
    return urlMatch.params.userId === "me"
        ? setCurrentUser(urlMatch, currentUserId)
        : urlMatch;
};

const setCurrentUser = <T extends { userId: string }>(
    urlMatch: match<T>,
    currentUserId: string,
): match<T> => {
    return {
        ...urlMatch,
        params: {
            ...urlMatch.params,
            userId: currentUserId,
        },
    };
};

/**
 * Takes a URL and parses it into a structure that allows you to easily
 * access the URL params and search params.
 *
 * If the URL does not match the path given it returns null.
 */
const getMatchAndSearchParams = <T extends Record<string, string>>(
    url: string,
    path: string,
    fnMatchUpdate?: (m: match<T>) => match<T>,
): {
    match: match<T>;
    search: URLSearchParams;
} | null => {
    const [pathname, search] = url.split("?");

    const match = matchPath<T>(pathname, { path });

    if (!match) {
        return null;
    }

    const query = new URLSearchParams(search);

    return {
        match: fnMatchUpdate ? fnMatchUpdate(match) : match,
        search: query,
    };
};
