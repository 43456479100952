import { DateHelpers, IWrappedResult } from "@accurx/shared";

import FlemingApiClient from "api/FlemingApiClient";
import { IPracticeActivityResponse } from "api/FlemingDtos";

export type PracticeUserAvailability =
    | {
          activity: { lastOnlineDaysAgo: number; numberOfUsers: number } | null;
          isAvailable: true;
      }
    | {
          isAvailable: false;
      };

type PracticeUserAvailabilityRequestBase = {
    organisationId: number;
};

type PracticeUserAvailabilityRequestPracticeCode = {
    practiceCodeToQuery: string;
} & PracticeUserAvailabilityRequestBase;
type PracticeUserAvailabilityRequestWorkspaceId = {
    workspaceIdToQuery: number;
} & PracticeUserAvailabilityRequestBase;

type PracticeUserAvailabilityRequest =
    | PracticeUserAvailabilityRequestPracticeCode
    | PracticeUserAvailabilityRequestWorkspaceId;

export class PracticeUserAvailabilityService {
    private static _daysCutOff = 4;

    private static async getPracticeUserAvailabilityFromServer(
        request: PracticeUserAvailabilityRequest,
    ): Promise<IWrappedResult<IPracticeActivityResponse>> {
        if ("workspaceIdToQuery" in request) {
            const { workspaceIdToQuery, organisationId } = request;
            return await FlemingApiClient.getWorkspaceActivityByWorkspaceId(
                workspaceIdToQuery,
                organisationId,
            );
        } else {
            const { practiceCodeToQuery, organisationId } = request;
            return await FlemingApiClient.getWorkspaceActivityByPracticeCode(
                practiceCodeToQuery,
                organisationId,
            );
        }
    }

    static async getPracticeUserAvailability(
        request: PracticeUserAvailabilityRequest,
    ): Promise<IWrappedResult<PracticeUserAvailability>> {
        const { success, result, error } =
            await this.getPracticeUserAvailabilityFromServer(request);
        if (!success || !result) {
            return { success, result: null, error };
        }

        if (!result.isOpenToReplies) {
            return {
                success: true,
                error: null,
                result: {
                    isAvailable: false,
                },
            };
        }

        const days = result.practiceUserOnlineStatus
            .map(({ lastOnlineAt }) =>
                DateHelpers.getDaysFromEndOfToday(lastOnlineAt),
            )
            .filter((daysAgo) => daysAgo <= this._daysCutOff);

        if (!days.length) {
            // No one was online before the cut off
            return {
                success: true,
                result: { isAvailable: true, activity: null },
                error: null,
            };
        }

        const mostRecentDay = Math.min(...days);
        return {
            success: true,
            result: {
                isAvailable: true,
                activity: {
                    lastOnlineDaysAgo: mostRecentDay,
                    numberOfUsers: days.filter((day) => day === mostRecentDay)
                        .length,
                },
            },
            error: null,
        };
    }
}
