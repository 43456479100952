import { Icon, Text, Thread } from "@accurx/design";
import { Log, MobileNumberHelper } from "@accurx/shared";

import { formatConversationItemDateCreatedLong } from "app/workspaceConversations/utils/conversation.utils";
import {
    FailedDeliveryReceiptLinkItem,
    PatientEmailConversationItem,
    PatientSmsConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";

export type LinkedItem =
    | PatientSmsConversationItem
    | PatientEmailConversationItem;

export type FailedDeliveryReceiptThreadItemProps =
    BaseConversationThreadItem<FailedDeliveryReceiptLinkItem> & {
        linkedItem: LinkedItem | null;
    };

export const FailedDeliveryReceiptThreadItem = ({
    item,
    linkedItem,
    handleOnItemRead,
    createdByDisplayName,
}: FailedDeliveryReceiptThreadItemProps) => {
    const isSMS = linkedItem?.contentType === "PatientSms";

    // This is unlikely to happen, but if there is no
    // linkedItem, display a generic message.
    if (!linkedItem) {
        Log.warn(
            "FailedDeliveryReceiptThreadItem - Cannot find linked conversation item for failed delivery receipt.",
            {
                tags: { linkedItemId: item.linkedItemId },
            },
        );

        return (
            <Thread.Message key={item.id} inboxType="information">
                <span>
                    Could not deliver message sent at{" "}
                    {formatConversationItemDateCreatedLong(item.createdAt)}
                </span>
            </Thread.Message>
        );
    }

    return (
        <Thread.Message
            key={item.id}
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <Text skinny>
                <Icon theme="Fill" name="Warning" colour="red" size={3} />
                <span>
                    Could not deliver {isSMS ? "SMS" : "email"}{" "}
                    {createdByDisplayName ? `from ${createdByDisplayName}` : ""}{" "}
                    to '
                    {isSMS
                        ? MobileNumberHelper.obfuscate(linkedItem?.mobileNumber)
                        : linkedItem?.emailAddress}
                    '.
                </span>
            </Text>
        </Thread.Message>
    );
};

export const failedDeliveryReceiptThreadItemSummaryText = (
    item: FailedDeliveryReceiptLinkItem,
) => "Failed delivery receipt";
