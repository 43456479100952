import { Text } from "@accurx/design";
import { generatePath } from "react-router";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { RouterLink } from "app/organisations/shared/RouterLink";

import { StyledText } from "./Breadcrumb.styles";

export type BreadcrumbProps = {
    text: string;
    path: string;
    params?: Record<string, number | string | undefined>;
    state?: Record<string, unknown>;
    asLink?: boolean;
};

export const Breadcrumb = ({
    text,
    path,
    params = {},
    state,
    asLink = true,
}: BreadcrumbProps) => {
    const org = useCurrentOrganisation();
    /*
     * using the deprecated form of link till we can get a
     * change in accurx-design to allow react router links
     */
    return asLink && path ? (
        <Text
            as={RouterLink}
            colour="blue"
            props={{
                to: {
                    pathname: generatePath(path, {
                        orgId: org?.orgId,
                        ...params,
                    }),
                    state,
                },
            }}
            skinny
        >
            {text}
        </Text>
    ) : (
        <StyledText as="div">{text}</StyledText>
    );
};
