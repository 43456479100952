import { useCallback, useEffect, useState } from "react";

import { getApiEndpoint, httpClient } from "@accurx/shared";

type UseFetchReturns<T> = {
    data: T | undefined;
    lastSuccessfulFetch: number | undefined;
    error: string | undefined;
};

export const useFetchAtIntervals = <T>(
    endpoint: ReturnType<typeof getApiEndpoint>,
    interval: number,
): UseFetchReturns<T> => {
    const [data, setData] = useState<T>();
    const [lastSuccessfulFetch, setLastSuccessfulFetch] = useState<number>();
    const [error, setError] = useState<string>();

    const fetcher = useCallback(async () => {
        const res = await httpClient.getReturnJsonSafeAsync(endpoint);

        if (res.success && res.result) {
            setData(res.result);
            setLastSuccessfulFetch(Date.now());
            setError(undefined);
        } else {
            setError(
                res.error ??
                    "There was a problem fetching data. Please try again",
            );
        }
    }, [endpoint]);

    useEffect(() => {
        fetcher();
        const intervalId = setInterval(fetcher, interval);
        return () => clearInterval(intervalId);
    }, [interval, fetcher]);

    return { data, lastSuccessfulFetch, error };
};
