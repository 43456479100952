import React, { cloneElement } from "react";

import initial from "lodash/initial";
import last from "lodash/last";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { ROUTES_ORGS } from "shared/Routes";
import { useIsSmallMobile } from "shared/useIsMobile";

import { Breadcrumb } from "../Breadcrumb";
import { StyledList, StyledListItem, StyledNav } from "./Breadcrumbs.styles";

export type BreadcrumbsProps = {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
};

export const Breadcrumbs = ({
    children,
    className,
}: BreadcrumbsProps): JSX.Element => {
    const org = useCurrentOrganisation();
    const isSmallMobile = useIsSmallMobile();

    const home = org ? (
        <Breadcrumb
            text={isSmallMobile ? "Home" : org.organisationName}
            path={ROUTES_ORGS.org}
            params={{ orgId: org.orgId }}
        />
    ) : (
        <Breadcrumb text="Home" path={ROUTES_ORGS.root} />
    );

    const items = [home, ...(Array.isArray(children) ? children : [children])];

    const currentPage = last(items);

    return (
        <StyledNav className={className}>
            <StyledList>
                {initial(items).map((item, index) => (
                    <StyledListItem key={index}>{item}</StyledListItem>
                ))}
                {currentPage && (
                    <StyledListItem>
                        {cloneElement(currentPage, { asLink: false })}
                    </StyledListItem>
                )}
            </StyledList>
        </StyledNav>
    );
};
