import { useEffect } from "react";

import { TabProps, Tabs, Text } from "@accurx/design";
import { AppSettings } from "@accurx/shared";
import { useHistory, useParams } from "react-router";
import { generatePath, useLocation } from "react-router-dom";

import {
    ReportingPageViewProps,
    trackReportingPageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_ORGS } from "shared/Routes";

import { Breadcrumb, Breadcrumbs, Header } from "../../shared";
import { PowerBIReport } from "../components/PowerBIReport";
import { getTabName } from "../utils";

const TABS: TabProps[] = [
    { text: "Overview", tabId: "overview" },
    { text: "Message Usage", tabId: "message-usage" },
    { text: "Fragment Usage", tabId: "fragment-usage" },
    { text: "NHS App", tabId: "nhs-app" },
];

const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "FragmentsReport",
} as const;

const REPORT_NAME =
    AppSettings.accurxEnvironment === "production"
        ? "Fragment Report"
        : "Fragment Report [DEV]";

export const FragmentsReportPage = () => {
    const { orgId, tab: tabId = "overview" } = useParams<{
        orgId: string;
        tab: string;
    }>();
    const { state } = useLocation<
        Partial<ReportingPageViewProps> | undefined
    >();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const history = useHistory();

    useEffect(() => {
        trackReportingPageView({
            ...analyticsLoggedInProps,
            navigationOrigin: state?.navigationOrigin ?? "URL",
            pageOrigin: state?.pageOrigin,
            currentTab: getTabName(TABS, tabId),
            reportName: ORIGIN_PROPS.pageOrigin,
        });
        // Only track page view on mount and when tab changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabId]);

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.reportFragments, {
                orgId,
                tab: tabId,
            }),
            ORIGIN_PROPS,
        );
    };

    return (
        <Tabs selected={tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="Reporting"
                        path={ROUTES_ORGS.reporting}
                        state={ORIGIN_PROPS}
                    />
                    <Breadcrumb
                        text="Fragments"
                        path={ROUTES_ORGS.reportFragments}
                    />
                </Breadcrumbs>
                <Text as="h1" variant="title">
                    Fragments
                </Text>
                <Header.TabList tabs={TABS} />
            </Header>

            <Tabs.Panel tabId="overview">
                <PowerBIReport
                    pageName="Overview"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="message-usage">
                <PowerBIReport
                    pageName="Message Usage"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="fragment-usage">
                <PowerBIReport
                    pageName="Fragment Usage"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
            <Tabs.Panel tabId="nhs-app">
                <PowerBIReport
                    pageName="NHS App"
                    reportName={REPORT_NAME}
                    pageOrigin={ORIGIN_PROPS.pageOrigin}
                />
            </Tabs.Panel>
        </Tabs>
    );
};
