import { FeatureName, Organisation } from "../..";

type HasFeatureArgs = {
    feature: FeatureName;
    workspace: Organisation;
};

export const hasFeature = ({ feature, workspace }: HasFeatureArgs) => {
    const foundFeature = workspace.settings.features.find(
        (orgFeatureFlag) => orgFeatureFlag.name === feature,
    );
    return foundFeature?.isEnabled === true;
};
