import React from "react";

import { Feedback } from "@accurx/design";

import { OutstandingInvite } from "api/WorkspaceDtos";
import { SkeletonPreview } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { OutstandingInviteListCard } from "./OutstandingInviteListCard";
import {
    StyledList,
    StyledUserListContainer,
} from "./UserManagementPage.styles";

type OutstandingInviteListProps = {
    outstandingInvites: OutstandingInvite[];
    isLoadingInvites: boolean;
};

export const OutstandingInviteList = (
    props: OutstandingInviteListProps,
): JSX.Element => {
    return (
        <StyledUserListContainer>
            {props.isLoadingInvites ? (
                <SkeletonPreview charCount={100} />
            ) : (
                <>
                    {props.outstandingInvites.length === 0 && (
                        <Feedback
                            colour="information"
                            content="Not currently waiting on any users to accept a personal invitation"
                        />
                    )}
                    {props.outstandingInvites.length > 0 && (
                        <StyledList>
                            {props.outstandingInvites.map(
                                (outstandingInvite, index) => (
                                    <OutstandingInviteListCard
                                        key={`${outstandingInvite.id}-${index}`}
                                        outstandingInvite={outstandingInvite}
                                    />
                                ),
                            )}
                        </StyledList>
                    )}
                </>
            )}
        </StyledUserListContainer>
    );
};
