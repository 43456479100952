import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";

type Payload = Partial<
    Record<
        keyof ConversationsState["unreadCounts"],
        "loading" | "success" | "error"
    >
>;

export const setUnreadCountsQueryStatus = (
    state: Draft<ConversationsState>,
    action: PayloadAction<Payload>,
) => {
    for (const key in action.payload) {
        const section = key as keyof ConversationsState["unreadCounts"];
        const status = action.payload[section];

        if (status) {
            state.unreadCounts[section].queryStatus = status;
        }
    }

    return state;
};
