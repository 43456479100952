import React from "react";

import { ButtonLink, Flex, Text } from "@accurx/design";
import { AppSettings } from "@accurx/shared";
import { useParams } from "react-router-dom";

import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";

import { LOGIN_LAYOUT_CONSTANTS } from "../LogIn.constants";
import { InnerStackPanel, OuterStackPanel } from "../LoginStackPanel";
import { StyledStackPanel } from "../OrganisationsLogin/OrgLogin.styles";
import { externalOrgIds } from "./constants";

const trusts = {
    uhl: {
        name: "University Hospitals of Leicester NHS Trust",
        loginButton: "Log in with email (uhl-tr.nhs.uk)",
    },
};

function assertTrustId(
    trustId: string,
): asserts trustId is keyof typeof trusts {
    if (!(trustId in trusts)) throw new Error("Trust param is not valid");
}

export const ActiveDirectoryLogin = () => {
    const { trustId } = useParams<{ trustId: string }>();
    assertTrustId(trustId);

    const orgExternalId =
        externalOrgIds[trustId][AppSettings.accurxEnvironment] ??
        "accuRxSecondarySso";

    return (
        <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.defaultTrust}>
            <OuterStackPanel>
                <StyledStackPanel
                    orientation="vertical"
                    gutter={3}
                    horizontalContentAlignment="center"
                >
                    <Flex flexDirection="column" alignItems="center" gap="1">
                        <Text variant="title" skinny as="h1">
                            Welcome
                        </Text>
                        <Text variant="subtitle" skinny as="h2">
                            {trusts[trustId].name}
                        </Text>
                    </Flex>
                    <InnerStackPanel horizontalContentAlignment="center">
                        <ButtonLink
                            href={`/oauth/${orgExternalId}/authenticate?returnUrl=/postlogin`}
                            text={trusts[trustId].loginButton}
                            dimension="large"
                        />
                        <Text skinny>
                            You will be directed to log in securely.
                        </Text>
                    </InnerStackPanel>
                </StyledStackPanel>
            </OuterStackPanel>
        </LoginPageLayout>
    );
};
