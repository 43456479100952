/** Keep in sync with MessagePatientDocumentAttachConstants.cs */

/** Limit size (per file) that can be uploaded */
export const MAX_ATTACHMENT_FILE_SIZE_MEGABYTES = 3;

/** List of supported content types */
export const SUPPORTED_FILE_EXTENSIONS = [
    // Images
    "jpeg",
    "jpg",
    "png",
    "tiff",
    "tiff2",
    "tif",
    // Documents
    "pdf",
    "docx",
    "doc",
    "rtf",
];
