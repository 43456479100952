import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";

export const sentenceCase = (text: string): string => {
    return upperFirst(text.toLowerCase());
};

export const pascalCase = (text: string): string => {
    return upperFirst(camelCase(text));
};

export const truncateLongWord = (word: string, maxLength: number): string => {
    if (word && word.length > maxLength) {
        return `${word.substring(0, maxLength)}...`;
    }
    return word;
};
