import { useHistory } from "react-router";

import { ROUTES } from "shared/Routes";
import { nextUrlMatchesRoute } from "shared/RoutesHelper";

export const useIsClinicianConversationOnboarding = () => {
    const history = useHistory();

    return nextUrlMatchesRoute(history.location.search, ROUTES.reply_from_web);
};
