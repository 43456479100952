import { MessageTemplateResponse } from "@accurx/api/patient-messaging";

import { PresetTemplate, PresetTemplateSection } from "shared/Templates";

export type PresetTemplateItem = PresetTemplate & { sectionHeading: string };
export const mapToPresetTemplateItemArray = (
    templates: MessageTemplateResponse[],
): PresetTemplateItem[] => {
    const mapPresetTemplateItem = (
        template: MessageTemplateResponse,
    ): PresetTemplateItem => ({
        body: template.body,
        title: template.title,
        isPatientResponseRequired: template.allowReplyByDefault ? true : false,
        sectionHeading:
            template.path?.folders?.map((c) => c.name).join(" > ") || "",
    });
    return templates.map((item) => mapPresetTemplateItem(item));
};

export const mapToTemplateSection = (
    collection: PresetTemplateItem[],
): PresetTemplateSection[] => {
    if (collection.length === 0) {
        return [];
    }
    const mapTemplates = new Map<string, PresetTemplateSection>();
    collection.forEach((item) => {
        if (!mapTemplates.has(item.sectionHeading)) {
            mapTemplates.set(item.sectionHeading, {
                sectionHeading: item.sectionHeading,
                templateList: [],
            });
        }
        const mapTemplateItem = mapTemplates.get(item.sectionHeading);
        mapTemplateItem?.templateList.push({
            body: item.body,
            title: item.title,
            isPatientResponseRequired: item.isPatientResponseRequired,
        });
    });
    return Array.from(mapTemplates, ([, value]) => {
        return value;
    });
};
