import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";

export const TABS: TabProps[] = [
    { text: "Sent", tabId: "delivery-receipts" },
    { text: "Scheduled", tabId: "delayed-messages" },
    { text: "Batch Messages", tabId: "batch-messages" },
];

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;
