import React, { useState } from "react";

import { Card, Feedback, Spinner, Text, Tokens } from "@accurx/design";
import { COLOURS } from "@accurx/design/dist/styles/tokens";
import Highcharts from "highcharts";
import Heatmap from "highcharts/modules/heatmap";

import { trackPatientTriageDashboardFilterByRequestMenuItemClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { Graph } from "app/sharedComponents/graph/Graph";
import { GraphType } from "app/sharedComponents/graph/Graph.types";

import { xAxisCategories } from "../Dashboards.constants";
import { NoReceptionDataCard } from "../RequestsByDay/RequestsByDay.styles";
import { DashboardCardHeader } from "../shared/Header/Header";
import { NoReceptionDataPrompt } from "../shared/NoReceptionDataPrompt/NoReceptionDataPrompt";
import { RequestSource, RequestType } from "../shared/shared.constants";
import { IRequestSource, IRequestType } from "../shared/shared.types";
import { heatmapMaxColour, heatmapMinColour } from "./RequestsByHour.constants";
import { NoReceptionDataWrapper } from "./RequestsByHour.styles";
import { GridCell, HeatmapProps } from "./RequestsByHour.types";
import { getGridCellData, getYAxisCategories } from "./RequestsByHour.utils";

Heatmap(Highcharts);

const renderLegend = (): JSX.Element => {
    return (
        <div className="d-flex justify-content-center align-items-baseline">
            <Text variant="label" colour={"metal"}>
                Min
            </Text>
            <svg
                className="ml-1"
                width="80"
                height="10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <linearGradient id="gradient">
                    <stop stopColor={heatmapMinColour} offset="0%" />
                    <stop stopColor={heatmapMaxColour} offset="100%" />
                </linearGradient>
                <rect width="80" height="10" fill="url(#gradient)" rx={4} />
            </svg>
            <Text variant="label" className="ml-1" colour={"metal"}>
                Max
            </Text>
        </div>
    );
};

export const RequestsByHourGraph = ({
    isLoading,
    dashboardData,
    startHour = 7,
    endHour = 19,
}: HeatmapProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [filterRequestType, setFilterRequestType] = useState<IRequestType>(
        RequestType.MedicalAndAdmin,
    );
    const [filterRequestSource, setFilterRequestSource] =
        useState<IRequestSource>(RequestSource.PatientsAndReception);

    const handleFilterByRequestType = (requestType: IRequestType): void => {
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            dashboardName: "Patient Triage Dashboard",
            sectionName: "Requests by hour",
            filterName: "Filter by Request Type",
            activeFilterList: [
                `Request Type - ${requestType}`,
                `Request Source - ${filterRequestSource}`,
            ],
            optionSelected: requestType,
        });
        setFilterRequestType(requestType);
    };

    const handleFilterByRequestSource = (requestSource: IRequestSource) => {
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            dashboardName: "Patient Triage Dashboard",
            sectionName: "Requests by hour",
            filterName: "Filter by Request Source",
            activeFilterList: [
                `Request Source - ${requestSource}`,
                `Request Type - ${filterRequestType}`,
            ],
            optionSelected: requestSource,
        });
        setFilterRequestSource(requestSource);
    };

    // Generate the y-Axis.
    // Using the start hour and end hour,
    // generate consecutive numbers until the
    // end hour is reached.
    const yAxisCategories = getYAxisCategories(startHour, endHour);

    const xAxisLength = xAxisCategories.length;
    const yAxisLength = yAxisCategories.length;
    const {
        data: gridCellData,
        minCellValue,
        maxCellValue,
    } = getGridCellData({
        dashboardData,
        requestType: filterRequestType,
        requestSource: filterRequestSource,
        xAxisLength,
        yAxisLength,
        startHour,
        endHour,
    });
    const isEmptyDataSet = maxCellValue === 0;

    const options = {
        tooltip: {
            enabled: false,
        },
        xAxis: {
            opposite: true,
            gridLineWidth: 0,
            lineWidth: 0,
        },
        yAxis: {
            gridLineWidth: 0,
            lineWidth: 0,
        },
        plotOptions: {
            heatmap: {
                borderRadius: 10,
                colsize: 0.95,
                rowsize: 0.95,
            },
        },
        colorAxis: {
            min: minCellValue,
            minColor: heatmapMinColour,
            maxColor:
                /**
                 * Set max color to the same as
                 * min colour when data is only
                 * zero values
                 */
                isEmptyDataSet ? heatmapMinColour : heatmapMaxColour,
            stops:
                /**
                 * Set stops to `undefined` when data is only
                 * zero values
                 */
                isEmptyDataSet
                    ? undefined
                    : [
                          [0, Tokens.COLOURS.primary.blue[5]],
                          [0.2, Tokens.COLOURS.primary.blue[10]],
                          [0.5, Tokens.COLOURS.primary.blue[25]],
                          [0.8, Tokens.COLOURS.primary.blue[50]],
                          [1, Tokens.COLOURS.primary.blue[75]],
                      ],
        },
        legend: {
            enabled: false,
        },
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                color: COLOURS.greyscale.metal,
                style: {
                    textOutline: "0px",
                    fontSize: "12",
                },
                verticalAlign: "middle",
            },
        },
    };

    return (
        <>
            <Card
                spacing={3}
                header={
                    <DashboardCardHeader
                        cardTitle={"Requests by hour"}
                        cardSubTitle={"Last week"}
                        cardTooltip={
                            "The number of Patient Triage requests received by each hour of the week"
                        }
                        filter={{
                            requestTypeFilterId: "filter-hour-by-request-type",
                            requestSourceFilterId:
                                "filter-hour-by-request-source",
                            onRequestTypeFilterSelect:
                                handleFilterByRequestType,
                            onRequestSourceFilterSelect:
                                handleFilterByRequestSource,
                        }}
                    />
                }
            >
                <div style={{ position: "relative" }}>
                    {isLoading && <Spinner />}
                    {!isLoading && !!gridCellData && (
                        <Graph
                            graphType={GraphType.Heatmap}
                            xAxisCategories={xAxisCategories}
                            yAxisCategories={yAxisCategories}
                            series={[
                                {
                                    data:
                                        gridCellData?.map(
                                            (gridCell: GridCell) => [
                                                gridCell.xLoc,
                                                gridCell.yLoc,
                                                gridCell.value,
                                            ],
                                        ) ?? [],
                                },
                            ]}
                            chartOptions={options}
                            customLegend={renderLegend()}
                        />
                    )}
                    {!isLoading && !gridCellData && (
                        <Feedback
                            colour="error"
                            title="Something went wrong, please refresh the page."
                        />
                    )}
                    {filterRequestSource === RequestSource.Reception &&
                        isEmptyDataSet && (
                            <NoReceptionDataWrapper>
                                <NoReceptionDataCard>
                                    <NoReceptionDataPrompt
                                        sectionName="Requests by hour"
                                        requestSource={filterRequestSource}
                                        requestType={filterRequestType}
                                    />
                                </NoReceptionDataCard>
                            </NoReceptionDataWrapper>
                        )}
                </div>
            </Card>
        </>
    );
};
