import React from "react";

import { Feedback, Text } from "@accurx/design";

import { BatchSnomedCode } from "app/batchMessage/gp/BatchMessage.types";

export type SnomedCodeFeedbackProps = {
    selectedSnomedCode: string;
    snomedCodes: BatchSnomedCode[];
};

export const SnomedCodeFeedback = ({
    selectedSnomedCode,
    snomedCodes,
}: SnomedCodeFeedbackProps): JSX.Element | null => {
    if (!snomedCodes.length) {
        return null;
    }

    if (!selectedSnomedCode) {
        return (
            <Feedback
                colour="secondary"
                title="SNOMED code: No code for the initial Florey invite"
            >
                <Text skinny>
                    You will still get SNOMED codes for the patient's responses.
                </Text>
            </Feedback>
        );
    }

    const selectedSnomed = snomedCodes.find(
        (snomed: BatchSnomedCode) => snomed.conceptId === selectedSnomedCode,
    );
    return (
        <Feedback
            colour="secondary"
            title={`SNOMED code: ${selectedSnomed?.term}`}
        >
            <Text skinny>
                This will be saved to record once the message is sent.
            </Text>
        </Feedback>
    );
};
