import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSingleSelectOverrides = styled.div`
    & button {
        box-shadow: none;
        padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};
        height: ${UI.Tokens.SIZES[4]};
        font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
        line-height: ${UI.Tokens.TYPOGRAPHY.note.desktop.lineHeight};

        &::before {
            border-color: ${UI.Tokens.COLOURS.greyscale.stone};
        }

        &:focus {
            // This is to implement same focus styling as other input elements
            // as design library has not been updated yet
            ${UI.focusBorderStyles}

            &::before {
                border: none;
            }

            &:after {
                outline: none;

                // The design library applies the box shadow here
                // But if we do, the component will be bigger
                // than other inputs (34px instead of 32)
                // so we apply it on the button directly
                box-shadow: none;
            }
        }

        &:hover,
        &:focus,
        &[aria-expanded="true"] {
            &::before {
                box-shadow: none;
            }
        }
    }
`;
