import debounce from "lodash/debounce";

type TimerKeys = "ALL_PATIENTS"; // List of all active timer keys
type TimerData = {
    startTime: number;
    elapsedTime: number;
    stopCallback: (elapsedTime: number) => void;
};
type TimerKeysData = { [T in TimerKeys]: TimerData };

const StoredData = {} as TimerKeysData;

const debouncedLastRendering = debounce((cb: () => void) => cb(), 2000);

export const clearTimer = (key: TimerKeys): void => {
    StoredData[key].startTime = 0;
};

export const startTimer = (
    key: TimerKeys,
    callback: (elapsedTime: number) => void,
): void => {
    StoredData[key] = {
        startTime: Date.now(),
        elapsedTime: 0,
        stopCallback: callback,
    };
};

export const stopTimer = (key: TimerKeys): void => {
    // If timeout is cleared don't udpate the time anymore
    if (!StoredData[key]?.startTime) {
        return;
    }

    // Each component will add time to the overall loading time
    StoredData[key].elapsedTime = Date.now() - StoredData[key].startTime;

    // Debounce after 2sec without new rendering to make sure to fire the callback after the last rendered component,
    // The debounce start only after the first stopTimer is called
    debouncedLastRendering(() => {
        StoredData[key].stopCallback(StoredData[key].elapsedTime);
        clearTimer(key);
    });
};
