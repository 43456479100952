import { useQueryClient } from "@tanstack/react-query";

import { AUTH_API_QUERY_KEY } from "./hooks/queries/useCurrentUserQuery";
import { User } from "./types/User";

/** Returns an update function that accepts a partial User object and handles updating the query cache. */
export const useUpdateCurrentUser = () => {
    const queryClient = useQueryClient();

    const update = (data: User) => {
        queryClient.setQueryData([AUTH_API_QUERY_KEY], data);
    };

    return update;
};
