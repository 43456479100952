import { useCurrentWorkspace } from "@accurx/auth";
import * as UI from "@accurx/design";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useQuestionnairePreviewLinkQuery } from "domains/compose/hooks/useQuestionnairePreviewLinkQuery";
import { toast } from "react-toastify";

import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";
import { StyledPreviewLink } from "./Questionnaire.styles";
import { QuestionnairePreviewLinkText } from "./QuestionnairePreviewLinkText";

export const Questionnaire = ({
    name,
    id,
    withPreviewLink,
}: {
    name: string;
    id: string;
    withPreviewLink: boolean;
}) => {
    const workspaceId = useCurrentWorkspace().orgId;

    const { status, data } = useQuestionnairePreviewLinkQuery(
        {
            workspaceId,
            questionnaireId: id,
        },
        {
            enabled: withPreviewLink === true,
        },
    );

    const questionnaireName = name || "Questionnaire";

    return (
        <StyledActionStack.Row>
            <UI.Ds.Flex gap="1" alignItems="center">
                <BodyInfoBox.Icon name="Questionnaire" />
                {!withPreviewLink && (
                    <BodyInfoBox.Text colour="night">
                        {questionnaireName}
                    </BodyInfoBox.Text>
                )}
                {withPreviewLink && (
                    <>
                        {status === "loading" && (
                            <SkeletonLoader
                                width="150px"
                                backgroundColor={
                                    UI.Tokens.COLOURS.greyscale.silver
                                }
                            />
                        )}
                        {status === "success" && (
                            <BodyInfoBox.Link href={data.link} openInNewTab>
                                <QuestionnairePreviewLinkText
                                    questionnaireName={questionnaireName}
                                />
                            </BodyInfoBox.Link>
                        )}
                        {status === "error" && (
                            // If the preview URL failed to fetch lets still show the link, because most
                            // users probably won't click it anyway, but show an error toast if they do
                            // click on it.
                            <StyledPreviewLink
                                href={"#"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    toast(
                                        <UI.Feedback
                                            title="Preview link for this questionnaire unavailable"
                                            colour="error"
                                        />,
                                    );
                                }}
                            >
                                <QuestionnairePreviewLinkText
                                    questionnaireName={name}
                                />
                            </StyledPreviewLink>
                        )}
                    </>
                )}
            </UI.Ds.Flex>
        </StyledActionStack.Row>
    );
};
