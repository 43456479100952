import * as UI from "@accurx/design";
// eslint-disable-next-line no-restricted-imports
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import styled from "styled-components";

import { StyledPillProps, pillStyles } from "../../styles";

export const StyledContainer = styled.div<StyledPillProps & { $width: string }>`
    ${pillStyles}

    width: ${(props) => props.$width};
    padding: 0 ${UI.Tokens.SIZES[1.5]};
`;

export const StyledSkeletonLoader = styled(SkeletonLoader)`
    height: 12px;
`;
