import { ErrorSummary, Feedback } from "@accurx/design";

import { ValidationDetails } from "./TemplateForm";
import { StyledErrorContainer } from "./TemplateForm.styles";

type TemplateErrorSummaryProps = {
    validationDetails: ValidationDetails;
    isMutationError: boolean;
};

export const TemplateErrorSummary = ({
    validationDetails,
    isMutationError,
}: TemplateErrorSummaryProps) => {
    const { submitted, hasErrors } = validationDetails;

    if (!(submitted && (hasErrors || isMutationError))) {
        return null;
    }

    return (
        <StyledErrorContainer>
            <ErrorSummary
                title="Review the errors in the form"
                isShown={submitted && hasErrors}
            />
            {isMutationError && (
                <Feedback title={"Unable to save your template"} colour="error">
                    Please try again or try refreshing the page.
                </Feedback>
            )}
        </StyledErrorContainer>
    );
};
