import React from "react";

import * as UI from "@accurx/design";
import { StyledFreeTextContainer } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText.styles";
import Linkify from "react-linkify";
import styled from "styled-components";

const StyledLink = styled(UI.Link)`
    font-size: 0.875rem;
`;

type FreeTextProps = {
    children: React.ReactNode;
    className?: string;
};

export const FreeText = ({ children, className }: FreeTextProps) => {
    return (
        <StyledFreeTextContainer className={className}>
            <p>
                <Linkify
                    componentDecorator={(href, text, key) => (
                        <StyledLink key={key} href={href} openInNewTab>
                            {text}
                        </StyledLink>
                    )}
                >
                    {children}
                </Linkify>
            </p>
        </StyledFreeTextContainer>
    );
};
