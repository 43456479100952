export const VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT =
    "\n\nTo RESPOND or VIEW ATTACHMENTS, please follow this link: ";

export const PATIENT_RESPONSE_TEXT =
    "\n\nTO RESPOND, please follow this link: ";

export const VIEW_ATTACHMENT_TEXT =
    "\n\nTo view your attachment, please follow this link: ";

export const QUESTIONNAIRE_TEXT = "\n\nPlease complete this questionnaire: ";
export const NHS_ADVICE_TEXT = "\nNHS.UK Advice: ";
export const SELFBOOK_TEXT = {
    prepend: "To book: ",
    append: " (Expires in 7 days)",
};

export const PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES =
    "https://accurx.nhs.uk/c/xxxxxxxxxxxx\n\n"; // 40 characters is the maximum length of a link to patient portal (including two new lines at the end)

export const PATIENT_RESPONSE_TEXT_MAX =
    PATIENT_RESPONSE_TEXT + PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES;

export const VIEW_ATTACHMENT_TEXT_MAX =
    VIEW_ATTACHMENT_TEXT + PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES;

export const VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT_MAX =
    VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT +
    PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES;

export const QUESTIONNAIRE_TEXT_MAX =
    QUESTIONNAIRE_TEXT + PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES;

export const SELFBOOK_TEXT_MAX =
    SELFBOOK_TEXT.prepend +
    PATIENT_PORTAL_EXAMPLE_LINK_TEXT_WITH_NEW_LINES +
    SELFBOOK_TEXT.append;
