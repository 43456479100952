import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { ButtonLink, Card, Text, Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import { shallowEqual } from "react-redux";

import { getModuleAccessLimits } from "api/PracticesApi";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useAppSelector } from "store/hooks";

import { PracticeModules } from "../Practices.types";
import { AccessAwareMessage } from "./components/AccessAwareMessage";
import { AccessBadge } from "./components/AccessBadge";
import { LINKS } from "./moduleAccess.contants";
import {
    ModuleAccessContainer,
    UsageGrid,
    UsageGridSeparators,
} from "./moduleAccess.styles";
import { SmsUsageApiResponse } from "./moduleAccess.types";
import { getDay, getDayMonth } from "./utils";

export const ModuleAccess = ({ noHeader }: { noHeader?: boolean }) => {
    useAccurxWebTitle("View available features");

    const practices = useAppSelector(
        ({ practices }) => practices,
        shallowEqual,
    );

    const smsBundleSection = useRef<null | HTMLElement>(null);
    const videoConsaltationSection = useRef<null | HTMLElement>(null);
    const patientTriageSection = useRef<null | HTMLElement>(null);
    const [smsUsage, setSmsUsage] = useState<SmsUsageApiResponse | null>(null);
    const [practiceId, setPracticeId] = useState<string>("");
    const [modules, setModules] = useState<PracticeModules>();

    useEffect(() => {
        const getModuleLimits = async () => {
            try {
                const response = await getModuleAccessLimits(practiceId);

                if (response.success && response.result) {
                    setSmsUsage(response.result as SmsUsageApiResponse);
                }
            } catch (e) {
                Log.error(e);
            }
        };
        if (!smsUsage && practiceId) {
            getModuleLimits();
        }
    }, [practiceId, smsUsage]);

    useEffect(() => {
        if (practices && practiceId === "") {
            setPracticeId(practices.selectedPractice);

            const practice = practices.items.find(
                (practice) =>
                    practice.id === parseInt(practices.selectedPractice),
            );

            setModules(practice?.modules);
        }
    }, [practices, practiceId]);

    /*
     * TODO remove this after completing migration to the new
     * style of manage organisation pages.
     * We currently use this hacky approach to prevent the content of this
     * page being too narrow when embedded into the new manage org settings
     * page layout.
     */
    const Container = useMemo(
        () =>
            noHeader
                ? ({ children }: { children: ReactNode }) => <>{children}</>
                : ({ children }: { children: ReactNode }) => (
                      <ModuleAccessContainer className="mx-auto">
                          {children}
                      </ModuleAccessContainer>
                  ),
        [noHeader],
    );

    return (
        <div>
            {!noHeader && <Breadcrumb title="Accurx Plus Module Access" />}
            <div
                style={{ display: "flex", flexFlow: "row wrap" }}
                className={"patientTriagePageContainer"}
            >
                {!noHeader && (
                    <h1 style={{ width: "100%", marginBottom: "2rem" }}>
                        Accurx Plus Module Access
                    </h1>
                )}

                <Container>
                    <section ref={smsBundleSection} className="mb-5">
                        <Card
                            spacing={0}
                            header={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: `0 ${Tokens.SIZES[3]}`,
                                    }}
                                >
                                    <Text variant="subtitle" as="h2" skinny>
                                        SMS Bundle{" "}
                                    </Text>
                                    {(modules?.sms !== "SMS Plus" ||
                                        !modules?.floreyPlus ||
                                        !modules?.batchAndAppointmentReminders) && (
                                        <ButtonLink
                                            role="button"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={LINKS.UPGRADE}
                                            text="Upgrade"
                                        />
                                    )}
                                </div>
                            }
                        >
                            <div>
                                <div
                                    style={{ padding: `0 ${Tokens.SIZES[3]}` }}
                                >
                                    <Text variant="label" as="h3">
                                        SMS Plus{" "}
                                        {modules && (
                                            <AccessBadge
                                                status={
                                                    modules.sms === "SMS Plus"
                                                        ? "available"
                                                        : modules.sms ===
                                                          "SMS Lite"
                                                        ? "limited"
                                                        : "unavailable"
                                                }
                                            />
                                        )}
                                    </Text>
                                    {smsUsage &&
                                        modules?.sms === "SMS Lite" && (
                                            <>
                                                <Text>
                                                    Your organisation has limits
                                                    in place for attachments and
                                                    patient responses.
                                                </Text>

                                                <Text variant="label">
                                                    Limits and usage for week{" "}
                                                    {smsUsage &&
                                                        getDay(
                                                            smsUsage.usagePeriod,
                                                        )}
                                                    -
                                                    {smsUsage &&
                                                        getDayMonth(
                                                            smsUsage.usagePeriod
                                                                .to,
                                                        )}
                                                </Text>

                                                <UsageGrid className="mt-3">
                                                    <Text
                                                        variant="note"
                                                        props={{
                                                            style: {
                                                                color: Tokens
                                                                    .COLOURS
                                                                    .greyscale
                                                                    .stone,
                                                            },
                                                        }}
                                                    >
                                                        Feature
                                                    </Text>
                                                    <div></div>
                                                    <Text
                                                        variant="note"
                                                        props={{
                                                            style: {
                                                                color: Tokens
                                                                    .COLOURS
                                                                    .greyscale
                                                                    .stone,
                                                            },
                                                        }}
                                                    >
                                                        Used
                                                    </Text>
                                                </UsageGrid>
                                                <UsageGrid className="mt-1">
                                                    <div>Attachments</div>
                                                    <UsageGridSeparators />
                                                    <div data-testid="smsUsageAttachments">
                                                        {
                                                            smsUsage?.counts[0]
                                                                ?.remainingUses
                                                        }{" "}
                                                        of 20
                                                    </div>
                                                </UsageGrid>
                                                <UsageGrid className="mt-2">
                                                    <div>Patient response</div>
                                                    <UsageGridSeparators />
                                                    <div data-testid="smsUsageResponses">
                                                        {
                                                            smsUsage?.counts[1]
                                                                ?.remainingUses
                                                        }{" "}
                                                        of 20
                                                    </div>
                                                </UsageGrid>
                                            </>
                                        )}
                                </div>
                                <hr />
                                <div
                                    style={{
                                        padding: `0 ${Tokens.SIZES[3]} ${Tokens.SIZES[3]} ${Tokens.SIZES[3]}`,
                                    }}
                                >
                                    <Text variant="label" as="h3">
                                        Florey Plus{" "}
                                        {modules && (
                                            <AccessBadge
                                                status={
                                                    modules.floreyPlus
                                                        ? "available"
                                                        : "unavailable"
                                                }
                                            />
                                        )}
                                    </Text>
                                    {modules && (
                                        <AccessAwareMessage
                                            canAccess={modules.floreyPlus}
                                            moduleName="Florey Plus questionnaires"
                                        />
                                    )}

                                    <ButtonLink
                                        role="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        theme="secondary"
                                        text="Learn more"
                                        aria-label="Learn more about Florey Plus"
                                        href={LINKS.FLOREY_PLUS}
                                    />
                                </div>
                                <hr />
                                <div
                                    style={{
                                        padding: `0 ${Tokens.SIZES[3]} ${Tokens.SIZES[3]} ${Tokens.SIZES[3]}`,
                                    }}
                                >
                                    <Text variant="label" as="h3">
                                        Batch and Appointment Reminders{" "}
                                        {modules && (
                                            <AccessBadge
                                                status={
                                                    modules.batchAndAppointmentReminders
                                                        ? "available"
                                                        : "unavailable"
                                                }
                                            />
                                        )}
                                    </Text>
                                    {modules && (
                                        <AccessAwareMessage
                                            canAccess={
                                                modules.batchAndAppointmentReminders
                                            }
                                        />
                                    )}

                                    <ButtonLink
                                        role="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        theme="secondary"
                                        text="Learn more"
                                        aria-label="Learn more about Batch and Appointment Reminders"
                                        href={
                                            LINKS.BATCH_AND_APPOINTMENT_REMINDERS
                                        }
                                    />
                                </div>
                            </div>
                        </Card>
                    </section>

                    <section ref={videoConsaltationSection} className="mb-5">
                        <Card
                            spacing={3}
                            header={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text variant="subtitle" as="h1" skinny>
                                        Video Consultations{" "}
                                        {modules && (
                                            <AccessBadge
                                                status={
                                                    modules.videoConsultations
                                                        ? "available"
                                                        : "unavailable"
                                                }
                                            />
                                        )}
                                    </Text>
                                </div>
                            }
                        >
                            {modules && (
                                <AccessAwareMessage
                                    canAccess={modules.videoConsultations}
                                    moduleName="Video Consultation module"
                                />
                            )}

                            <ButtonLink
                                role="button"
                                target="_blank"
                                rel="noopener noreferrer"
                                theme="secondary"
                                text="Learn more"
                                aria-label="Learn more about Video Consultation"
                                href={LINKS.VIDEO_CONSULTATIONS}
                            />
                        </Card>
                    </section>

                    <section ref={patientTriageSection} className="mb-5">
                        <Card
                            spacing={3}
                            header={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text variant="subtitle" as="h1" skinny>
                                        Patient Triage{" "}
                                        {modules && (
                                            <AccessBadge
                                                status={
                                                    modules.patientTriage
                                                        ? "available"
                                                        : "unavailable"
                                                }
                                            />
                                        )}
                                    </Text>
                                </div>
                            }
                        >
                            {modules && (
                                <AccessAwareMessage
                                    canAccess={modules.patientTriage}
                                    moduleName="Patient Triage module"
                                />
                            )}

                            <ButtonLink
                                role="button"
                                target="_blank"
                                rel="noopener noreferrer"
                                theme="secondary"
                                text="Learn more"
                                aria-label="Learn more about Patient Triage"
                                href={LINKS.PATIENT_TRIAGE}
                            />
                        </Card>
                    </section>
                </Container>
            </div>
        </div>
    );
};
