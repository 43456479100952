import {
    AssigneeType,
    PatientInternalIdentity,
    PatientThreadAssignee,
    PatientThreadFilteredTicketView,
    PatientThreadTicketOrdering,
    StartsWithType,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import {
    TeamAssigneeSummary,
    UserAssigneeSummary,
} from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapPatientThreadFilteredTicketViewToConciergeUpdate } from "../mappers/mapPatientThreadFilteredTicketViewToConciergeUpdate";

export type StartsWithItem = keyof typeof StartsWithType;

export type GetFilteredTicketViewOptions = {
    workspaceId: number;
    assignee?: TeamAssigneeSummary | UserAssigneeSummary;
    isDone?: boolean;
    ordering: PatientThreadTicketOrdering;
    continuationToken?: string;
    patientId?: string;
    startsWithItem?: StartsWithItem;
};

const mapAssignee = (
    assignee?: TeamAssigneeSummary | UserAssigneeSummary,
): PatientThreadAssignee | undefined => {
    switch (assignee?.type) {
        case "User":
            return { type: AssigneeType.User, userId: assignee.id };
        case "Team":
            return { type: AssigneeType.UserGroup, userGroupId: assignee.id };
        default:
            return undefined;
    }
};

const mapPatientId = (
    patientId?: string,
): PatientInternalIdentity | undefined => {
    if (!patientId) {
        return undefined;
    }

    return {
        id: Number(patientId),
    };
};

const mapStartsWithItem = (
    startsWithItem?: StartsWithItem,
): StartsWithType | undefined => {
    switch (startsWithItem) {
        case "PatientTriageRequestAdmin":
            return StartsWithType.PatientTriageRequestAdmin;
        case "PatientTriageRequestMedical":
            return StartsWithType.PatientTriageRequestMedical;
        case "PatientTriageRequestSelfReferral":
            return StartsWithType.PatientTriageRequestSelfReferral;
        default:
            return undefined;
    }
};

export const getFilteredTicketView: ConciergeFetcher<
    GetFilteredTicketViewOptions,
    PatientThreadFilteredTicketView
> = async ({ patientId, ...arg }) => {
    const response = await TicketApiClient.fetchMatchingTickets({
        ...arg,
        startsWithItem: mapStartsWithItem(arg.startsWithItem),
        patientId: mapPatientId(patientId),
        assignee: mapAssignee(arg.assignee),
    });

    return {
        updates: mapPatientThreadFilteredTicketViewToConciergeUpdate(response),
        response,
    };
};
