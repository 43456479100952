import { UserRole } from "@accurx/api/portal";

export enum OrgUserRole {
    Member, // 0
    Admin, // 1
    Visitor, // 2
}

export const OrgUserRoleLabel = (newRole?: OrgUserRole | UserRole): string => {
    switch (newRole) {
        case OrgUserRole.Member:
            return "Member";
        case OrgUserRole.Admin:
            return "Admin";
        case OrgUserRole.Visitor:
            return "Visitor";
        default:
            return "";
    }
};

export const OrgUserRoleConsequence = (
    newRole?: OrgUserRole,
    userName?: string,
): string => {
    switch (newRole) {
        case OrgUserRole.Member:
            return `This will make ${userName} a regular member of your team. They will no longer have the ability to approve new users, make other users admins or archive users.`;
        case OrgUserRole.Admin:
            return `This will grant ${userName} admin privileges. They will have the ability to approve new users, make other users admins and archive users.`;
        case OrgUserRole.Visitor:
            return `This will make ${userName} a visitor to your team. They will have reduced access to your practice.`;
        default:
            return "";
    }
};

export enum OrgUserApprovalStatus {
    Unapproved, // 0
    Approved, // 1
    Archived, // 2
    Deleted, // 3
}

export const OrgUserApprovalStatusLabel = (
    status?: OrgUserApprovalStatus,
): string => {
    switch (status) {
        case OrgUserApprovalStatus.Unapproved:
            return "Unapproved";
        case OrgUserApprovalStatus.Approved:
            return "Approved";
        case OrgUserApprovalStatus.Archived:
            return "Archived";
        case OrgUserApprovalStatus.Deleted:
            return "Deleted";
        default:
            return "";
    }
};

export const OrgUserApprovalStatusConsequence = (
    newStatus: OrgUserApprovalStatus,
    oldStatus?: OrgUserApprovalStatus,
    userName?: string,
): string => {
    switch (newStatus) {
        case OrgUserApprovalStatus.Unapproved:
            return "Unapprove";
        case OrgUserApprovalStatus.Approved:
            if (oldStatus === OrgUserApprovalStatus.Archived) {
                if (userName) {
                    return `This will restore ${userName} as a member of your organisation, allowing them to use the full functionality of Accurx.`;
                }
                return "Unarchive";
            }
            return "Approve";
        case OrgUserApprovalStatus.Archived:
            if (userName) {
                return `Archiving ${userName} means they will no longer be able to use Accurx at this organisation.`;
            }
            return "Archive";
        case OrgUserApprovalStatus.Deleted:
            return "Delete";
        default:
            return "";
    }
};

type UserRoleStateType = {
    currentValue: OrgUserRole;
    allowedChanges: OrgUserRole[];
};

type UserStatusStateType = {
    currentValue: OrgUserApprovalStatus;
    allowedChanges: OrgUserApprovalStatus[];
};

export type UserType = {
    confirmedEmail: boolean;
    email: string;
    name: string | null;
    role?: string;
    userId: number;
    userRoleState?: UserRoleStateType;
    userStatusState?: UserStatusStateType;
};

export type UserTransitions = {
    title: string;
    role?: OrgUserRole;
    status?: OrgUserApprovalStatus;
    user: UserType;
    action: () => void;
};
