import { useConciergeSelector } from "domains/concierge/internal/context";
import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";

export const useIsConversationGroupFresh = ({
    groupId,
}: {
    groupId: ConversationGroup["id"];
}): boolean => {
    return useConciergeSelector((state) => {
        const connectionState = state.conversations.connectionState;

        if (connectionState.state !== "Connected") {
            return false;
        }

        const cacheEntry = state.conversations.groups.cache[groupId];

        if (!cacheEntry) {
            return false;
        }

        return cacheEntry.connectionId === connectionState.connectionId;
    });
};
