// MERGE_CHAIN_FLEMING: This is a Partial import of VaccineDeliveryDTO. Future migration will import it all
export type VaccineProductType =
    | "Pfizer"
    | "Janssen"
    | "AstraZeneca"
    | "Moderna"
    | "PfizerPaediatric"
    | "Sanofi";

export const MaxSingleSMSSize = 160;
export const MaxBatchSMSSize = 612;
