import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    AnalyticsSearchPatientOrigin,
    FlemingLoggedInCustomProperties,
    OriginProps,
    ProductSelection,
} from "./FlemingAnalyticsConstants";
import {
    TrackFlemingEvent,
    reduceToFlemingLoggedInCustomProperties,
} from "./FlemingEventTracker";
import { MessagePracticeStatusProps } from "./MessagePracticeEvents";
import { RecordViewEnabledForPatientProps } from "./RecordViewEvents";

export type PatientProfileTabName =
    | "MyConversations"
    | "AllConversations"
    | "Overview"
    | "Documents"
    | "Undefined";

export enum CopyButtonAnalyticsContext {
    sms = "sms",
    smsModal = "sms-modal",
    videoConsult = "video-consult",
    accuMail = "message-practice",
}

type IsTestPatient = {
    isTestPatient?: boolean;
};

/**
 * Clicked on copy message button after
 * using Message Patient, accuMail or Video Consult
 *
 * Event type: Button click
 * */
export const trackCopyMessage = (
    props: FlemingLoggedInCustomProperties & {
        context: CopyButtonAnalyticsContext;
        isCurrentUserMessage?: boolean;
    } & IsTestPatient,
): void => {
    const mapContext = (context: CopyButtonAnalyticsContext) => {
        switch (context) {
            case CopyButtonAnalyticsContext.sms:
            case CopyButtonAnalyticsContext.smsModal:
                return "PatientMessage";
            case CopyButtonAnalyticsContext.videoConsult:
                return "PatientVideoInvite";
            case CopyButtonAnalyticsContext.accuMail:
                return "ClinicianMessage";
        }
    };

    TrackFlemingEvent("Copy message button clicked", props);
    GenericTrackEvent({
        object: "MessageCopy",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(
            {
                ...reduceToFlemingLoggedInCustomProperties(props),
                productOrigin: mapContext(props.context),
                isModal: props.context === CopyButtonAnalyticsContext.smsModal,
                isCurrentUserMessage: props.isCurrentUserMessage,
            },
            { eventVersion: 2 },
        ),
    });
};

export enum ProductSelectLocation {
    Page = "select-product-page",
    PatientListDropdown = "patient-list-dropdown",
    RecentSearchesDropDown = "recent-searches-dropdown",
}

export type SelectProductAnalyticsProps = FlemingLoggedInCustomProperties &
    ProductSelection &
    AnalyticsSearchPatientOrigin & {
        context: ProductSelectLocation;
    };

/**
 * Clicked to select a product
 * either from select product page or from patient list
 *
 * Event type: Button click
 * */
export const trackProductSelected = (
    props: SelectProductAnalyticsProps,
): void => {
    TrackFlemingEvent("Product Selected", props);
};

/**
 * Hovered over or clicked on the Message GP practice link to display the tooltip
 * containing the practice name and address
 *
 * Event type: Button click
 * */
export const trackGpPracticeTooltipViewed = (
    props: FlemingLoggedInCustomProperties,
): void => {
    TrackFlemingEvent("GP practice tooltip viewed", props);
};

export type PatientProfilePageViewProps = MessagePracticeStatusProps &
    RecordViewEnabledForPatientProps &
    FlemingLoggedInCustomProperties;

/**
 * An event that fires when the patient profile page is rendered
 *
 * Event type: Page View
 * */
export const trackPatientProfilePageView = (
    props: PatientProfilePageViewProps,
): void => {
    GenericTrackEvent({
        object: "PatientProfile",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks a button to 'View patient profile'
 *
 * Event type: Button click
 * */
export const trackPatientProfileButtonClick = (
    props: FlemingLoggedInCustomProperties & OriginProps,
): void =>
    GenericTrackEvent({
        object: "PatientProfileView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });

/**
 * User clicks a tab to on the patient profile
 *
 * Event type: Tab click
 * */
export const trackPatientProfileTabClick = (
    props: FlemingLoggedInCustomProperties & {
        patientProfileTabName: PatientProfileTabName;
    },
): void =>
    GenericTrackEvent({
        object: "PatientProfile",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });

export type ProductOptionAnalyticsProps = {
    navigationLocation:
        | "Header"
        | "Overview"
        | "ClinicianConversation"
        | "ClinicianConversationBanner";
    optionSelected:
        | "MessagePatient"
        | "VideoInvite"
        | "MessagePractice"
        | "RecordView";
    productAvailableForPatient: boolean;
} & AnalyticsSearchPatientOrigin &
    FlemingLoggedInCustomProperties;

/**
 * A user clicks on a button that initiates a product
 */
export const trackProductOptionButtonClick = (
    props: ProductOptionAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "ProductOption",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * A user clicks on a menu item that initiates a product
 */
export const trackProductOptionMenuItemClick = (
    props: ProductOptionAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "ProductOption",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
