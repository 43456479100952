import { Tokens, focusBorderStyles } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledNavLink = styled(Link)`
    align-items: center;
    border: 1px solid ${Tokens.COLOURS.primary.green["100"]};
    border-radius: ${Tokens.SIZES[1]};
    color: ${Tokens.COLOURS.greyscale.white};
    display: flex;
    gap: ${Tokens.SIZES[1]};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    transition-property: background-color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;

    &[data-active="true"] {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.green["130"]};
        text-decoration: none;
    }

    // Higher specificity to override GlobalStyle
    &:focus&:focus,
    &:focus-visible {
        ${focusBorderStyles};
    }
`;
