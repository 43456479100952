import * as UI from "@accurx/design";
import styled from "styled-components";

const StyledWrapper = styled(UI.Flex).attrs({
    flexDirection: "column",
    alignItems: "stretch",
})``;

const StyledHeader = styled(UI.Flex).attrs({
    alignItems: "center",
    justifyContent: "space-between",
})<{ fullBorder?: boolean }>`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    padding: ${UI.Tokens.SIZES[1.5]};

    border: ${UI.Tokens.BORDERS.normal.silver};

    ${({ fullBorder }) =>
        fullBorder &&
        `
        border-radius: ${UI.Tokens.SIZES[0.5]};
    `}

    ${({ fullBorder }) =>
        !fullBorder &&
        `
        border-radius: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]} 0 0;
        border-bottom: none;
    `}
`;

const StyledFooter = styled(UI.Flex).attrs({
    alignItems: "center",
    justifyContent: "space-between",
})`
    border-radius: 0 0 ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]};
    border: ${UI.Tokens.BORDERS.normal.silver};
    background: ${UI.Tokens.COLOURS.greyscale.cloud};
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1]}
        ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[5]};

    a {
        // in order to align Link text with text in the Header, we need to account for some required side-padding Link includes
        margin-left: -${UI.Tokens.SIZES[0.25]};
    }
`;

export const StyledInfoBox = {
    Wrapper: StyledWrapper,
    Header: StyledHeader,
    Footer: StyledFooter,
};
