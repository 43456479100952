import { Table, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

const cellBorderStyles = css`
    border-left: none;
    border-right: none;
    white-space: nowrap;
    width: 1%;
`;

export const StyledTableDataCell = styled(Table.DataCell)`
    ${cellBorderStyles}
`;

export const StyledHeaderRow = styled(Table.Row)`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
`;

export const StyledTableRow = styled(Table.Row)`
    &:last-child {
        ${StyledTableDataCell} {
            border-bottom: none;
        }
    }
`;

export const StyledTableHeader = styled(Table.Header)`
    ${cellBorderStyles}
    border-top: none;
`;

export const StyledTableWrapper = styled.div`
    margin-top: ${Tokens.SIZES[3]};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    overflow-x: auto;
`;
