import React, { useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    AttachmentUpload,
    Button,
    Checkbox,
    Feedback,
    StackPanel,
    Text,
} from "@accurx/design";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { MessageTemplate, MessageTemplateType } from "api/FlemingDtos";
import * as PatientMessagingServerApi from "api/PatientMessagingServer/PatientMessagingServerApi";
import { UserflowEvent, trackUserflowEvent } from "app/account/Userflow";
import { FlemingAnalyticsTracker } from "app/analytics";
import { MessageTemplatePageOrigin } from "app/analytics/FlemingAnalytics";
import { actionCreators } from "app/messageTemplates/MessageTemplatesActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import {
    ObjectUrl,
    TemplateAttachmentDisplay,
    TemplateInList,
} from "app/workspaceConversations/components/MessageTemplates/MessageTemplates.types";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    StyledButtonsWrapper,
    StyledFooter,
    StyledFormFieldWrapper,
    StyledHeader,
    StyledMainContent,
    StyledPageWrapper,
} from "./CreateMessageTemplate.styles";
import { CreateMessageTemplateForm } from "./CreateMessageTemplateForm";
import {
    mapMessageTemplateManagementViewToTemplateInList,
    mapMessageTemplateTypeToString,
} from "./MessageTemplatesHelper";
import { TemplateAttachmentInput } from "./TemplateAttachmentInput";
import { TemplateAttahcmentsList } from "./TemplateAttachmentsList";
import {
    BODY_MAX_LENGTH,
    TITLE_MAX_LENGTH,
} from "./messageTemplatesEditCreate/ManageMessageTemplateConstants";

type AnalyticsProps = {
    pageOrigin: MessageTemplatePageOrigin;
};

export type CreateMessageTemplateProps = {
    template: MessageTemplate;
    onSuccess: (template: MessageTemplate) => void;
    analyticsProps: AnalyticsProps;
};

type ValidationResult =
    | { isValid: true; errors?: undefined }
    | { isValid: false; errors: string[] };

const CreateMessageTemplate = ({
    template,
    onSuccess,
    analyticsProps,
}: CreateMessageTemplateProps): JSX.Element => {
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [bodyError, setBodyError] = useState("");
    const [attachmentError, setAttachmentError] = useState("");
    const [body, setBody] = useState("");
    const [attachments, setAttachments] = useState<TemplateAttachmentDisplay[]>(
        [],
    );
    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const dispatch = useDispatch();

    const [isDefault, setIsDefault] = useState(false);
    const [isAvailableForOrg, setIsAvailableForOrg] = useState(false);

    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const organisationId = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );
    const loggedInProps = useFlemingLoggedInAnalytics();

    const webOrgWideTemplatesEnabled = useIsFeatureEnabled(
        FeatureName.WebOrgWideTemplates,
    );

    const userIsApproved = useAppSelector(({ account }) => {
        return OrganisationHelper.getIsApprovedOrgUser(account);
    });

    const isValidTitle = (): ValidationResult => {
        setTitleError("");
        if (title.length === 0) {
            const error = "Please add a title";
            setTitleError(error);
            return { isValid: false, errors: [error] };
        }

        if (title.length > TITLE_MAX_LENGTH) {
            const error = `Title must be less than or equal to ${TITLE_MAX_LENGTH} characters.`;
            setTitleError(error);
            return { isValid: false, errors: [error] };
        }

        return { isValid: true };
    };

    const isValidBody = (): ValidationResult => {
        setBodyError("");
        if (body.length === 0) {
            const error = "Please add a body";
            setBodyError(error);
            return { isValid: false, errors: [error] };
        }

        if (body.length > BODY_MAX_LENGTH) {
            const error = `Body must be less than or equal to ${BODY_MAX_LENGTH} characters.`;
            setBodyError(error);
            return { isValid: false, errors: [error] };
        }

        return { isValid: true };
    };

    const isValidTemplate = (): ValidationResult => {
        const validTitle = isValidTitle();
        const validBody = isValidBody();

        if (validTitle.isValid && validBody.isValid) {
            return { isValid: true };
        }

        return {
            isValid: false,
            errors: [...(validTitle.errors ?? []), ...(validBody.errors ?? [])],
        };
    };

    const handleOnMakeTemplateAvailableForOrg = () => {
        const newValue = !isAvailableForOrg;
        setIsAvailableForOrg(newValue);
        if (newValue) {
            setIsDefault(false);
        }
    };

    const handleOnSetDefault = () => {
        setIsDefault((isDefault) => !isDefault);
    };

    const handleCreateTemplate = async () => {
        const templateCreateConfirmButtonClickProps = {
            ...loggedInProps,
            pageOrigin: analyticsProps.pageOrigin,
            templateName: title,
            countAttachment: attachments.length,
        };

        const validationResult = isValidTemplate();
        const hasError = !validationResult.isValid;

        const errorInformation = hasError
            ? {
                  hasError,
                  errorReason: validationResult.errors,
              }
            : { hasError };

        const templateLevel = isAvailableForOrg ? "Organisation" : "User";

        FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonClick({
            ...templateCreateConfirmButtonClickProps,
            ...errorInformation,
            templateType: "sms",
            productOrigin: "PatientMessage",
            templateLevel,
            countPatientDetail: 0,
        });

        if (hasError) {
            return;
        }
        setIsSaveDisabled(true);

        const messageTemplate = {
            id: template.id?.toString(),
            type: mapMessageTemplateTypeToString(template.type),
            title,
            body,
            owner: templateLevel,
            isDefault,
            attachedDocumentIds: attachments.map(({ documentId }) =>
                parseInt(documentId, 10),
            ),
            allowReplyByDefault: false,
        };

        const { result, success } =
            await PatientMessagingServerApi.createUpdateMessageTemplate({
                organisationId,
                messageTemplate,
            });
        setIsSaveDisabled(false);

        const hasSucceeded = result && success;
        FlemingAnalyticsTracker.trackTemplateCreateConfirmButtonResponse({
            ...templateCreateConfirmButtonClickProps,
            templateType: "sms",
            hasError: hasSucceeded !== true,
        });

        if (hasSucceeded) {
            const updatedTemplate: TemplateInList =
                mapMessageTemplateManagementViewToTemplateInList(
                    result,
                    organisationId,
                );
            dispatch(
                actionCreators.editOrCreateMessageTemplateSuccess(
                    updatedTemplate,
                ),
            );
            onSuccess(updatedTemplate);

            trackUserflowEvent(UserflowEvent.MESSAGE_TEMPLATE_CREATED);
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "Something went wrong saving your template. Please try again.",
                    iconName: "Failed",
                }),
            );
        }
    };

    const handleFilesUpload = (uploadedFiles: AttachmentUpload[]) => {
        setAttachments([
            ...attachments,
            ...uploadedFiles.map(({ attachmentId, file }) => ({
                documentId: attachmentId,
                fileSize: file.size,
                fileName: file.name,
                previewUrl: window.URL.createObjectURL(file) as ObjectUrl,
            })),
        ]);
    };

    const handleFileRemove = (idToRemove: string) => {
        setAttachments(
            attachments.filter(({ documentId }) => documentId !== idToRemove),
        );
    };

    return (
        <>
            <StyledPageWrapper>
                <StyledMainContent>
                    <StackPanel
                        orientation="vertical"
                        verticalContentAlignment="space-between"
                    >
                        <StyledHeader>
                            <Text variant="subtitle" as="h1" skinny>
                                Create a new template
                            </Text>
                            <Text variant="body" colour="metal" skinny>
                                Templates that you create here will be added to
                                the template list.
                            </Text>
                        </StyledHeader>
                        <Text variant="label" skinny>
                            Category
                        </Text>
                        <Text>
                            {template.type === MessageTemplateType.VideoConsult
                                ? "Video consult"
                                : "Message patient"}
                        </Text>
                        <CreateMessageTemplateForm
                            title={title}
                            titleError={titleError}
                            onTitleChange={setTitle}
                            body={body}
                            bodyError={bodyError}
                            onBodyChange={setBody}
                            templateType={template.type}
                            orgName={orgName}
                        />
                        {organisationId !== null && (
                            <StyledFormFieldWrapper
                                errors={[attachmentError]}
                                label="Attachment"
                                labelProps={{
                                    htmlFor: "template-attachment",
                                }}
                            >
                                <TemplateAttahcmentsList
                                    attachments={attachments}
                                    handleFileRemove={handleFileRemove}
                                />
                                <TemplateAttachmentInput
                                    attachments={attachments}
                                    setAttachmentError={setAttachmentError}
                                    organisationId={organisationId}
                                    handleFilesUpload={handleFilesUpload}
                                    isUploading={isUploadingAttachments}
                                    setIsUploading={setIsUploadingAttachments}
                                />
                                {attachments.length > 0 && (
                                    <Feedback
                                        colour="information"
                                        title="Check you've selected the right attachment"
                                    >
                                        <Text skinny>
                                            This message template could be used
                                            with multiple patients. Please do
                                            not include attachments which could
                                            contain sensitive patient
                                            information.
                                        </Text>
                                    </Feedback>
                                )}
                            </StyledFormFieldWrapper>
                        )}
                        <StackPanel orientation="vertical" gutter={1}>
                            {userIsApproved && webOrgWideTemplatesEnabled && (
                                <Checkbox
                                    id="chkAvailableForOrg"
                                    text={`Make template available for colleagues at ${orgName}`}
                                    theme="bordered"
                                    checked={isAvailableForOrg}
                                    onCheckChange={
                                        handleOnMakeTemplateAvailableForOrg
                                    }
                                />
                            )}
                            {template.type ===
                                MessageTemplateType.VideoConsult && (
                                <Checkbox
                                    id="chkIsDefualt"
                                    text="Set as default template"
                                    theme="bordered"
                                    disabled={isAvailableForOrg}
                                    checked={isDefault}
                                    onCheckChange={handleOnSetDefault}
                                />
                            )}
                        </StackPanel>
                    </StackPanel>
                </StyledMainContent>
                <StyledFooter>
                    <StyledButtonsWrapper>
                        <Button
                            theme="primary"
                            text="Save and use"
                            onClick={handleCreateTemplate}
                            disabled={isSaveDisabled || isUploadingAttachments}
                        />
                    </StyledButtonsWrapper>
                </StyledFooter>
            </StyledPageWrapper>
        </>
    );
};

export { CreateMessageTemplate };
