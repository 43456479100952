import * as UI from "@accurx/design";

export type VisuallyHiddenUnreadCountProps = {
    count: number;
};

export const VisuallyHiddenUnreadCount = ({
    count,
}: VisuallyHiddenUnreadCountProps) => {
    return (
        <UI.VisuallyHidden as="span">
            {" "}
            with {count} unread item{count > 1 && "s"},{" "}
        </UI.VisuallyHidden>
    );
};
