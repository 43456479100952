import * as React from "react";

import { Card, Spinner } from "@accurx/design";

import { trackPatientTriageDashboardFilterByRequestMenuItemClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { usePatientTriageDashboardOutcomeDataQuery } from "app/hooks/queries/usePatientTriageDashboardOutcomeDataQuery";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { Graph } from "app/sharedComponents/graph/Graph";
import { Barchart } from "app/sharedComponents/graph/Graph.barchart.types";
import { GraphType } from "app/sharedComponents/graph/Graph.types";

import { FortnightlyBarGraphLegend } from "../shared/FortnightlyBarGraphLegend/FortnightlyBarGraphLegend";
import { DashboardCardHeader } from "../shared/Header";
import { RequestType } from "../shared/shared.constants";
import { IRequestType } from "../shared/shared.types";
import { getStartAndEndOfFortnight } from "../shared/shared.utils";
import { RequestWithOutcome } from "./RequestsByOutcome.types";
import { aggregateDataForRequestsByOutcomeGraph } from "./RequestsByOutcome.utils";

export type BarchartProps = {
    status: "error" | "success" | "loading";
    outcomes: RequestWithOutcome[];
    labels: string[];
};

export const RequestsByOutcome = ({ practiceId }: { practiceId: string }) => {
    const [startDate, endDate] = getStartAndEndOfFortnight();
    const outcomesQuery = usePatientTriageDashboardOutcomeDataQuery({
        workspaceId: parseInt(practiceId, 10),
        startDate: startDate.toDate().toISOString(),
        endDate: endDate.toDate().toISOString(),
    });

    return (
        <RequestsByOutcomeGraph
            status={outcomesQuery.status}
            outcomes={outcomesQuery.data?.outcomes ?? []}
            labels={outcomesQuery.data?.labels ?? []}
        />
    );
};

const RequestsByOutcomeGraph = ({
    status,
    labels,
    outcomes,
}: BarchartProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const [filterRequestType, setFilterRequestType] =
        React.useState<IRequestType>(RequestType.MedicalAndAdmin);

    const { aggregatedData: seriesObject } =
        aggregateDataForRequestsByOutcomeGraph(
            outcomes,
            labels,
            filterRequestType,
        );

    const handleFilterByRequestType = (requestType: IRequestType): void => {
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            dashboardName: "Patient Triage Dashboard",
            sectionName: "Requests by outcome",
            filterName: "Filter by Request Type",
            activeFilterList: [`Request Type - ${requestType}`],
            optionSelected: requestType,
        });
        setFilterRequestType(requestType);
    };

    const options: Barchart = {
        plotOptions: {
            series: {
                borderRadius: 3,
                pointPadding: 0,
                groupPadding: 0.08,
            },
        },
        yAxis: {
            allowDecimals: false,
        },
        legend: {
            enabled: false,
        },
    };

    return (
        <>
            <Card
                spacing={3}
                header={
                    <DashboardCardHeader
                        cardTitle={"Requests by outcome"}
                        cardSubTitle={"Last and current week"}
                        cardTooltip={
                            "The number of Patient Triage requests by outcome recorded in the previous and current week"
                        }
                        filter={{
                            requestTypeFilterId: "filter-day-by-request-type",
                            onRequestTypeFilterSelect:
                                handleFilterByRequestType,
                        }}
                    />
                }
            >
                {status === "loading" && <Spinner />}
                {status === "success" && (
                    <div style={{ position: "relative" }}>
                        <Graph
                            graphType={GraphType.Column}
                            xAxisCategories={labels}
                            chartOptions={options}
                            customLegend={<FortnightlyBarGraphLegend />}
                            series={[
                                ...seriesObject,
                                {
                                    /**
                                     * Force graph to maintain a non-zero
                                     * series even with no data
                                     * https://www.highcharts.com/forum/viewtopic.php?t=45068
                                     */
                                    color: "transparent",
                                    enableMouseTracking: false,
                                    showInLegend: false,
                                    opacity: 0,
                                    data: [1.2],
                                },
                            ]}
                        />
                    </div>
                )}
            </Card>
        </>
    );
};
