import { useEffect, useState } from "react";

import { AppSettings, Log } from "@accurx/shared";
import debounce from "lodash/debounce";

type UseDevToolbarResponse = {
    isOpen: boolean;
    toggleOpen: () => void;
};

export const useDevToolbar = (): UseDevToolbarResponse => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle is triggered via CMD+. or CTRL+.
    const debouncedToggle = debounce((e: KeyboardEvent) => {
        if (e.key === "." && (e.ctrlKey || e.metaKey)) {
            setIsOpen((o) => !o);
        }
    }, 200);

    useEffect(() => {
        window.addEventListener("keydown", debouncedToggle);

        return () => {
            window.removeEventListener("keydown", debouncedToggle);
        };
    }, [debouncedToggle]);

    useEffect(() => {
        if (isOpen) {
            Log.info("Dev Toolbar has been enabled.", {
                tags: {
                    prNumber: AppSettings.accurxPrNumber,
                },
            });
        }
    }, [isOpen]);

    return { isOpen, toggleOpen: () => setIsOpen((o) => !o) };
};
