import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { getServerUrl } from "api/Api.utils";
import { BaseConversationItem } from "shared/concierge/conversations/types/conversation.types";

const isToday = (date: string) => {
    return DateHelpers.getDaysFromEndOfToday(new Date(date)) === 0;
};

/**
 * @param dateString date string in ISO format
 * @returns time and date in format 16:31pm, Today (or 2 Feb 2022 if not today)
 */
export const formatConversationItemDateCreatedLong = (dateString: string) => {
    const dateIsToday = isToday(dateString);

    return dateIsToday
        ? `${DateHelpers.formatTime(
              dateString,
              DateFormatOptions.TIME_SHORT_12_HR,
          )}, Today`
        : `${DateHelpers.formatTime(
              dateString,
              DateFormatOptions.TIME_SHORT_12_HR,
          )}, ${DateHelpers.formatTime(
              dateString,
              DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
          )}`;
};

/**
 * @param dateString date string in ISO format
 * @returns 12h time format, aka 16:31
 */
export const formatConversationItemDateCreatedShort = (dateString: string) => {
    return DateHelpers.formatTime(dateString, DateFormatOptions.TIME_12_HR);
};

/**
 * @param attachmentId
 * @param organisationId
 * @param patientId
 * @returns url in format server-url/api/conversation/web/patientupload/:organisationId/:patientId/:attachmentId
 */
export const getAttachmentUrl = ({
    attachmentId,
    organisationId,
    patientId,
}: {
    attachmentId: string;
    organisationId: number;
    patientId: string | undefined | null;
}): string | null => {
    if (!patientId) {
        return null;
    }

    return `${getServerUrl()}/api/conversation/web/patientupload/${organisationId}/${patientId}/${attachmentId}`;
};

/**
 * @param attachmentId
 * @param organisationId
 * @param patientId
 * @returns url in format server-url/api/conversation/web/patientupload/:organisationId/:attachmentId
 */
export const getPatientTriageAttachmentUrl = ({
    attachmentId,
    organisationId,
}: {
    attachmentId: string;
    organisationId: number;
}): string | null => {
    return `${getServerUrl()}/api/conversation/web/patientupload/${organisationId}/${attachmentId}`;
};

/**
 * @param attachmentId
 * @param organisationId
 * @param patientId
 * @returns url in format server-url/api/conversation/web/patientupload/:organisationId/:patientId/appointmentrequest/:attachmentId
 */
export const getAppointmentRequestAttachmentUrl = ({
    attachmentId,
    organisationId,
    patientId,
}: {
    attachmentId: string;
    organisationId: number;
    patientId: string | undefined | null;
}): string | null => {
    if (!patientId) {
        return null;
    }

    return `${getServerUrl()}/api/conversation/web/patientupload/${organisationId}/${patientId}/appointmentrequest/${attachmentId}`;
};

/**
 * Returns whether the item is considered unread by the system.
 * Note that this may be true even if the item is not considered notifiable.
 *
 * @param conversationItem the item in question
 * @param currentUserId the current user
 * @returns boolean representing whether or not the conversation item is unread
 */
export const isConversationItemUnread = (
    conversationItem: BaseConversationItem,
    currentUserId: string,
) => conversationItem.readDataByUserId?.[currentUserId]?.readAt === null;

/**
 * @param sizeInBytes
 * @returns string in format 100MB
 */
export const getDisplayFileSize = (sizeInBytes: number): string => {
    const BYTES_PER_KB = 1024;
    const BYTES_PER_MB = BYTES_PER_KB * BYTES_PER_KB;

    const sizeInKB = sizeInBytes / BYTES_PER_KB;
    const sizeInMB = sizeInBytes / BYTES_PER_MB;

    if (sizeInBytes < BYTES_PER_MB) {
        return `${Math.round(sizeInKB)}KB`;
    }

    return `${Math.round(sizeInMB)}MB`;
};

/**
 * Sanitises the mobile number, keeping only digits from 0 to 9.
 * @param inputString unsanitised mobile number.
 * @returns mobile number sanitised.
 */
export const sanitiseMobileNumber = (inputString: string): string =>
    inputString.replace(/[^0-9]/g, "");
