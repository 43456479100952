import { PatientThreadTicketCommandResult } from "@accurx/api/ticket";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { TeamSummary } from "domains/concierge/types";

type ToggleNotificationsOptions = {
    teamId: TeamSummary["id"];
    notify: boolean;
    workspaceId: number;
};

export const toggleNotifications: ConciergeFetcher<
    ToggleNotificationsOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const response = await httpClient
        .postJsonReturnSafeAsync(
            getApiEndpoint({
                path: "/api/conversation/usergroupmembership",
            }),
            {
                organisationId: arg.workspaceId,
                userGroupId: arg.teamId,
                updatedMembership: arg.notify,
            },
        )
        .then(returnDataOrThrow);

    return {
        // Right now there's no response data so we have no updates
        // TODO: once this endpoint returns the updated team object
        // we can emit an update
        updates: {
            conversations: [],
            patients: [],
            users: [],
            teams: [],
        },
        response,
    };
};
