import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledCustomRadioLabelWrapper = styled.div<{ $labelFor: string }>`
    // Remove default margin between radio blocks
    > span {
        margin-bottom: 0;
    }

    ${({ $labelFor }) => {
        return `
            label[for="${$labelFor}"] {
                position: relative;

                // This is the radio checkbox
                > span:first-child {
                    position: absolute;
                    top: 2px;
                    right: 0;
                    margin-top: 0;
                }
            }
        `;
    }}
`;

export const StyledCustomRadioLabel = styled(UI.Flex)`
    width: calc(100% - ${UI.Tokens.SIZES[4]});
`;
