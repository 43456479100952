export const copyToClipboard = async (
    text: string,
): Promise<{ success: boolean }> => {
    try {
        await navigator.clipboard.writeText(text);
        return { success: true };
    } catch (e) {
        return { success: false };
    }
};
