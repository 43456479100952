import isArray from "lodash/isArray";
import { matchPath, useHistory, useRouteMatch } from "react-router-dom";

import { ROUTES, ROUTES_PRIMARY } from "./Routes";
import { getStringQuery } from "./RoutesHelper";

export function useRouteMatchOrTwoFactorPrompt({
    path,
}: {
    path: string | string[];
}) {
    const pathAsArray = isArray(path) ? path : [path];

    // The same page could live under /some-url and /w/:workspaceId/some-url
    // while we migrate pages to use /w/:workspaceId
    const routeMatch = useRouteMatch({
        path: [
            ...pathAsArray,
            ...pathAsArray.map(
                (path) => `${ROUTES_PRIMARY.workspaceBase}${path}`,
            ),
        ],
    });
    const matchTwoFactorAuth = useRouteMatch({
        path: [ROUTES.two_factor_auth],
    });
    const history = useHistory();
    if (routeMatch) {
        return routeMatch;
    }
    if (matchTwoFactorAuth) {
        const search = history.location.search;
        const nextParam = getStringQuery(search, "next") ?? "";
        const nextMatchPath = matchPath(nextParam, { path });
        if (nextMatchPath) {
            return nextMatchPath;
        }
    }
    return null;
}
