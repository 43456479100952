import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { bmFetchBatchDetails } from "api/BatchMessaging";
import { BatchMessageModalDetails } from "app/batchMessage/gp/BatchMessage.types";

type BatchUploadDetailsQueryVariables = {
    workspaceId: string;
    batchId: string;
    refetchInterval?: (data?: BatchMessageModalDetails) => number | false;
    enabled?: boolean;
};

export const useBatchDetailsQuery = ({
    workspaceId,
    batchId,
    refetchInterval,
    enabled = true,
}: BatchUploadDetailsQueryVariables) => {
    const result: UseQueryResult<BatchMessageModalDetails, Error> = useQuery({
        queryKey: ["batch-details", { workspaceId, batchId }],
        queryFn: async () => {
            const response = await bmFetchBatchDetails(workspaceId, batchId);

            const data = returnDataOrThrow(response);
            return data;
        },
        refetchInterval: (data) =>
            refetchInterval ? refetchInterval(data) : false,
        enabled,
        refetchOnMount: true,
    });

    return result;
};
