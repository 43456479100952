import { Button, StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

const { white, dishwater, silver } = Tokens.COLOURS.greyscale;

export const StyledCard = styled.section<{
    cardType?: "marketing" | "product";
}>`
    background-color: ${(props) =>
        props.cardType === "marketing" ? dishwater : white};
    border-radius: ${Tokens.BORDERS.radius};
    border: 1px solid ${silver};
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledCardHeading = styled(StackPanel)`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    flex-wrap: wrap;
    width: 100%;
    svg {
        margin-left: -${Tokens.SIZES[0.5]};
    }
`;

export const StyledCardContent = styled.div`
    padding: 0 ${Tokens.SIZES[1]};
`;

export const StyledCTAStackPanel = styled(StackPanel)`
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        margin-left: 0;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        width: auto;
    }
`;

export const StyledCTAButton = styled(Button)`
    width: 100%;
    margin-top: ${Tokens.SIZES[2]};

    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        margin-left: 0;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        width: auto;
        margin-top: 0;
    }
`;

export const StyledList = styled.ul`
    padding: 0;

    li {
        list-style: none;
    }
`;

export const StyledFeedbackContainer = styled.div`
    margin: 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[2]} ${Tokens.SIZES[2]};
`;

export const StyledMarketingCardStackPanel = styled(StackPanel)`
    flex-wrap: wrap;
    row-gap: ${Tokens.SIZES[2]};
`;

export const StyledMarketingCardImage = styled.img`
    display: none;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        align-self: flex-end;
        display: block;
    }
`;

export const StyledMarketingCardSummary = styled.div`
    padding: 0 ${Tokens.SIZES[1]} ${Tokens.SIZES[3]} ${Tokens.SIZES[3]};
`;

export const StyledProductMarketingContent = styled.div`
    background: ${white};
    background: linear-gradient(
        180deg,
        ${white} 20%,
        ${Tokens.COLOURS.primary.purple[5]} 100%
    );
`;

export const StyledProductCardSummary = styled.div`
    padding: 0 ${Tokens.SIZES[1]} ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};
`;
