import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledVideoConsultLinkNoteContainer = styled.div`
    border-radius: ${UI.Tokens.BORDERS.radius};
    border: 1px solid ${UI.Tokens.COLOURS.primary.blue[25]};
    background: ${UI.Tokens.COLOURS.primary.blue[5]};
    padding: ${UI.Tokens.SIZES[1.5]};
`;

export const StyledSubheader = styled.p`
    margin-bottom: ${UI.Tokens.SIZES[0.5]};
`;

export const StyledActionsContainer = styled(UI.Flex).attrs({
    flexDirection: "row",
    gap: "1.5",
    alignItems: "center",
})`
    margin-bottom: ${UI.Tokens.SIZES[2]};
`;
