import {
    isSubscriptionSuccessEvent,
    useSignalRClientOn,
    useSignalRInvoke,
    useSubscribeSignalRInvocations,
} from "@accurx/native";
import { Log } from "@accurx/shared";

import { Events, SocketEventPayloads } from "shared/hubClient/HubClient";

import { Transport } from "../types";

// A hook to encapsulate the logic for sending and subscribing to SignalR events
// via the native bridge. The hook is composed of being able to send SignalR events and
// setting up and subscribing events, which is abstracted under a single subscribe function

export const useNativeSignalRTransport = (): Transport => {
    const nativeSignalRInvoke = useSignalRInvoke();
    const nativeSignalRClientOn = useSignalRClientOn();
    const nativeSignalRSubscribe = useSubscribeSignalRInvocations();

    return {
        send: nativeSignalRInvoke.mutate,
        subscribe: ({ methodName, onEvent }) => {
            nativeSignalRClientOn.mutate({ methodName });
            const unsubscribe = nativeSignalRSubscribe({
                methodName,
                onEvent: (event) => {
                    if (
                        !isSubscriptionSuccessEvent(event) ||
                        !hasSuccessPayload<typeof methodName>(event.data)
                    ) {
                        Log.error(
                            "Unsuccessfully received SignalR subscription data via native bridge",
                            {
                                tags: { methodName: methodName },
                            },
                        );

                        return;
                    }

                    return onEvent({
                        event: methodName,
                        payload: event.data.parameter,
                    });
                },
            });
            return { unsubscribe };
        },
    };
};

export function hasSuccessPayload<M extends Events>(
    data: unknown,
): data is { parameter: SocketEventPayloads[M] } {
    if (!data) return false;
    return typeof data === "object" && "parameter" in data;
}
