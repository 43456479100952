import { useState } from "react";

import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@accurx/design";

type MoreOptionsPopoverProps = {
    hideToolbar: () => void;
};

export const MoreOptionsPopover = (props: MoreOptionsPopoverProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                <Button
                    theme="secondary"
                    icon={{ name: "Menu" }}
                    text=""
                    dimension="small"
                />
            </PopoverTrigger>
            <PopoverContent side="top" align="center" width="max-content">
                <Button
                    theme="secondary"
                    text="Hide Toolbar"
                    dimension="small"
                    onClick={props.hideToolbar}
                />
            </PopoverContent>
        </Popover>
    );
};
