import { QuestionType } from "@accurx/api/florey-builder";

import {
    ClientSideQuestion,
    ValidDraftBranchQuestion,
    ValidExistingBranchQuestion,
    ValidExistingNonBranchQuestion,
    ValidNonBranchDraftQuestion,
} from "../types/context.types";

export const isValidNonBranchQuestion = (
    questionToValidate: ClientSideQuestion,
): questionToValidate is ValidExistingNonBranchQuestion => {
    return (
        !!questionToValidate.questionTitle &&
        !!questionToValidate.id &&
        !!questionToValidate.questionRowVersion &&
        questionToValidate.questionType !== QuestionType.Branching
    );
};
export const isValidNonBranchDraftQuestion = (
    questionToValidate: ClientSideQuestion,
): questionToValidate is ValidNonBranchDraftQuestion => {
    return (
        !!questionToValidate.questionTitle &&
        questionToValidate.questionType !== QuestionType.Branching
    );
};
export const isValidBranchQuestion = (
    questionToValidate: ClientSideQuestion,
): questionToValidate is ValidExistingBranchQuestion => {
    return (
        !!questionToValidate.id &&
        !!questionToValidate.branchData &&
        questionToValidate.questionType === QuestionType.Branching
    );
};
export const isValidDraftBranchQuestion = (
    questionToValidate: ClientSideQuestion,
): questionToValidate is ValidDraftBranchQuestion => {
    return (
        !!questionToValidate.branchData &&
        questionToValidate.questionType === QuestionType.Branching
    );
};
