import { ReactNode } from "react";

import * as UI from "@accurx/design";

import { StyledBase, StyledBaseFlex } from "./Base.styles";

export type BaseProps = {
    children: ReactNode;
    radius?: "regular" | "full";
    color?: string;
    border?: boolean;
    dimension?: "small" | "medium";
};

export const Base = ({
    children,
    radius = "regular",
    color = UI.Tokens.COLOURS.greyscale.white,
    border = true,
    dimension = "small",
}: BaseProps) => {
    return (
        <StyledBase
            $radius={radius}
            $color={color}
            $border={border}
            $dimension={dimension}
        >
            <StyledBaseFlex alignItems="center" gap="0.5">
                {children}
            </StyledBaseFlex>
        </StyledBase>
    );
};
