import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";

import { SearchForPatientByDemographicsRequest } from "@accurx/api/portal";
import {
    FormFieldFeedback,
    FormFieldWrapper,
    Input,
    SelectItem,
    SingleSelect,
} from "@accurx/design";
import { DateHelpers } from "@accurx/shared";

import { useAppSelector } from "store/hooks";

import { DateInput, DateInputValue } from "./DateInput";
import {
    StyledDateOfBirth,
    StyledFirstName,
    StyledFormFieldsWrapper,
    StyledFormWrapper,
    StyledGenderSelect,
    StyledLastName,
    StyledPostcodeInput,
    StyledSubmitButton,
} from "./SearchForPatientByName.styles";
import { SearchForPatientFormIds } from "./SearchFormConstants";

export type SearchFormData = {
    nhsNumber?: string;
    gender?: string;
    dobDay?: string;
    dobMonth?: string;
    dobYear?: string;
};

export type SubmitNameSearchForm = (
    request: SearchForPatientByDemographicsRequest,
    resetForm?: () => void,
) => void;

export type SearchForPatientByNameProps = {
    onSubmit: SubmitNameSearchForm;
    isFormDisabled: boolean;
    serverError: string;
    resetServerError: () => void;
    /**
     * Unique identifier in case there are multiple forms on the same page
     */
    formId: SearchForPatientFormIds;
    hasCustomSubmitButton?: boolean;
};

export const SearchForPatientByName = ({
    onSubmit,
    serverError,
    resetServerError,
    isFormDisabled,
    formId,
    hasCustomSubmitButton = false,
}: SearchForPatientByNameProps): JSX.Element => {
    const selectedOrganisation = useAppSelector(
        ({ account }) => account.selectedOrganisation,
    );

    const [postcode, setPostcode] = useState("");

    const [gender, setGender] = useState("");
    const [genderErrorMessage, setGenderErrorMessage] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [dobDay, setDobDay] = useState("");
    const [dobMonth, setDobMonth] = useState("");
    const [dobYear, setDobYear] = useState("");
    const [dobErrorMessage, setDobErrorMessage] = useState("");

    const handleResetServerError = useCallback(() => {
        if (serverError) {
            resetServerError();
        }
    }, [serverError, resetServerError]);

    const resetFormState = (): void => {
        setDobDay("");
        setDobMonth("");
        setDobYear("");
        setFirstName("");
        setLastName("");
        setPostcode("");
        setGender("");
    };

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setLastName(e.target.value);
    };

    const handleDateChange = (value: DateInputValue) => {
        setDobDay(value.day);
        setDobMonth(value.month);
        setDobYear(value.year);
        setDobErrorMessage("");
        handleResetServerError();
    };

    const handleGenderChange = (option: string) => {
        setGender(option);
        setGenderErrorMessage("");
    };

    const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPostcode(e.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const dateValidation = DateHelpers.validateDob(
            dobDay,
            dobMonth,
            dobYear,
        );

        setDobErrorMessage(dateValidation.error || "");

        if (!gender) {
            setGenderErrorMessage("Please select a gender");
            return;
        }

        if (dateValidation.success && selectedOrganisation) {
            const request: SearchForPatientByDemographicsRequest = {
                firstName,
                lastName,
                gender,
                postcode,
                dateOfBirthYear: parseInt(dobYear, 10),
                dateOfBirthMonth: parseInt(dobMonth, 10),
                dateOfBirthDay: parseInt(dobDay, 10),
                organisationId: selectedOrganisation,
            };
            onSubmit(request, resetFormState);
        }
    };

    const isBlankForm =
        firstName + lastName + dobDay + dobMonth + dobYear + postcode === "";

    return (
        <form
            id={formId}
            onSubmit={handleSubmit}
            data-testid={formId}
            aria-label={formId}
        >
            <StyledFormWrapper>
                <StyledFormFieldsWrapper>
                    <StyledFirstName>
                        <FormFieldWrapper
                            label="First name"
                            labelProps={{
                                htmlFor: "first-name-input",
                                className: "mt-0",
                            }}
                        >
                            <Input
                                id={"first-name-input"}
                                placeholder="xxxxxxxxxx"
                                type="text"
                                inputMode="text"
                                minLength={1}
                                onChange={handleFirstNameChange}
                                disabled={isFormDisabled}
                                required
                            />
                        </FormFieldWrapper>
                    </StyledFirstName>
                    <StyledLastName>
                        <FormFieldWrapper
                            label="Last name"
                            labelProps={{
                                htmlFor: "last-name-input",
                                className: "mt-0",
                            }}
                        >
                            <Input
                                id={"last-name-input"}
                                placeholder="xxxxxxxxxx"
                                type="text"
                                inputMode="text"
                                minLength={1}
                                onChange={handleLastNameChange}
                                disabled={isFormDisabled}
                                required
                            />
                        </FormFieldWrapper>
                    </StyledLastName>
                    <StyledDateOfBirth>
                        <DateInput
                            value={{
                                day: dobDay,
                                month: dobMonth,
                                year: dobYear,
                            }}
                            error={dobErrorMessage}
                            label="Date of birth"
                            onChange={handleDateChange}
                            required
                            disabled={isFormDisabled}
                        />
                    </StyledDateOfBirth>
                    <StyledGenderSelect>
                        <FormFieldWrapper
                            label="Gender"
                            labelProps={{
                                htmlFor: "gender-select",
                                skinny: true,
                            }}
                            errors={[genderErrorMessage]}
                        >
                            <SingleSelect
                                id="gender-select"
                                onValueChange={handleGenderChange}
                                dimension="medium"
                                disabled={isFormDisabled}
                            >
                                {[
                                    { value: "Female", label: "Female" },
                                    { value: "Male", label: "Male" },
                                    { value: "Not Known", label: "Not known" },
                                    {
                                        value: "Not Specified",
                                        label: "Not specified",
                                    },
                                ].map(({ value, label }) => (
                                    <SelectItem key={value} value={value}>
                                        {label}
                                    </SelectItem>
                                ))}
                            </SingleSelect>
                        </FormFieldWrapper>
                    </StyledGenderSelect>
                    <StyledPostcodeInput>
                        <FormFieldWrapper
                            label="Postcode"
                            labelProps={{
                                htmlFor: "postcode-input",
                                skinny: true,
                            }}
                        >
                            <Input
                                id={"postcode-input"}
                                placeholder="xxx xxx"
                                type="text"
                                inputMode="text"
                                minLength={5}
                                maxLength={8}
                                onChange={handlePostcodeChange}
                                disabled={isFormDisabled}
                                required
                            />
                        </FormFieldWrapper>
                    </StyledPostcodeInput>
                    {!hasCustomSubmitButton && (
                        <StyledSubmitButton
                            type="submit"
                            aria-label="Search for a patient"
                            icon={{
                                name: "Search",
                                title: "Search for a patient",
                                id: "search-btn",
                            }}
                            disabled={isFormDisabled || isBlankForm}
                        />
                    )}
                </StyledFormFieldsWrapper>
            </StyledFormWrapper>
            {serverError !== "" && (
                <FormFieldFeedback variant="error" text={serverError} />
            )}
        </form>
    );
};
