import React, { FormEvent } from "react";

import { Button, Card, Text } from "@accurx/design";
import { COLOURS } from "@accurx/design/dist/styles/tokens";
import { i } from "images";
import { Col, Row } from "reactstrap";

import { ChainAnalyticsTracker } from "app/analytics";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { NavSubMenuComponent } from "../../navbar/NavSubMenuComponent";
import { Border } from "./InhalerRemindersInfo.styles";

const registerInterestFormUrl = "https://forms.gle/NigSgWVQiPBc9ZMh8";
const green = COLOURS.primary.green[100];

export const InhalerRemindersInfo = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const onRegisterInterestClick = (): void => {
        ChainAnalyticsTracker.trackInhalerRemindersRegisterInterestClicked(
            analyticsLoggedInProps,
        );
        setTimeout(() => {
            window.open(registerInterestFormUrl);
        }, 150);
    };

    return (
        <>
            <NavSubMenuComponent>
                <Breadcrumb wrapper={false} title="Inhaler Reminders" />
                <Text as="h2" variant="title">
                    Inhaler Reminders
                </Text>
                <Text
                    as="h3"
                    variant="subtitle"
                    props={{ style: { color: green } }}
                >
                    Launching Summer 2022
                </Text>
                <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        onRegisterInterestClick();
                    }}
                >
                    <Button
                        className="mt-4"
                        theme="primary"
                        dimension="medium"
                        text="Register for early access"
                        type="submit"
                    />
                </form>
                <Text>* Registering takes 10 seconds</Text>
            </NavSubMenuComponent>
            <Row>
                <Col xs="6">
                    <Text
                        as="h3"
                        variant="subtitle"
                        props={{ className: "mb-3" }}
                    >
                        Introducing Inhaler Reminders
                    </Text>
                    <Text props={{ className: "mb-3" }}>
                        We're launching a free text message reminder feature for
                        patients with asthma and COPD on preventer inhalers.
                        Your patients will receive regular reminders about
                        taking their inhaler as prescribed.
                    </Text>
                    <Text props={{ className: "mb-3" }}>
                        Inhaler Reminders are fully automated so you can set
                        them up and forget them.
                    </Text>
                    <Text props={{ className: "mb-3" }}>
                        By registering for Inhaler Reminders, you're empowering
                        your patients to manage their asthma and COPD more
                        effectively.
                    </Text>
                    <Text>
                        <Text
                            as="a"
                            variant="link"
                            props={{
                                role: "button",
                                tabindex: "0",
                                onClick: onRegisterInterestClick,
                            }}
                        >
                            Register your interest
                        </Text>{" "}
                        to get access when the feature launches.
                    </Text>
                    <Border>
                        <Text>
                            If you have any questions or want to chat about
                            Inhaler Reminders for your practice, please don't
                            hesitate to email alex@accurx.com.
                        </Text>
                    </Border>
                </Col>
                <Col xs="6">
                    <Card spacing={3} variant="regular">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="mb-2">
                                <img
                                    src={i("InhalerReminderSample.png")}
                                    alt="Reminder Sample"
                                    width="184"
                                    height="376"
                                />
                            </div>
                            <Text variant="note" props={{ className: "mb-1" }}>
                                Example reminder message
                            </Text>
                            <Text>
                                "Have you taken your inhaler today? Remember
                                that taking your inhaler every day will reduce
                                your risk of experiencing asthma symptoms."
                            </Text>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
