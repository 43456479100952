import { AttachmentUpload } from "@accurx/design";

import { PatientThreadEmail, PatientThreadSMS } from "api/FlemingDtos";
import { AssigneeSummary } from "shared/concierge/conversations/types/assignee.types";
import { Conversation as ConversationType } from "shared/concierge/conversations/types/conversation.types";
import {
    PatientExternalIdentity,
    PatientSummary,
} from "shared/concierge/patients/types/patient.types";

export const PageViewTypes = {
    MessageTemplates: "messageTemplates",
    CreateTemplate: "createTemplate",
} as const;

export type OnSendMessageSuccessType = (
    messages?: (PatientThreadSMS | PatientThreadEmail)[] | null,
) => void;

export type MarkAsDone = () => Promise<void>;
export type MarkAsOpen = () => Promise<void>;
export type Assign = (assignee: AssigneeSummary) => Promise<void>;

export type ConversationActions = {
    onSendMessageSuccess: OnSendMessageSuccessType;
    markItemAsRead: (
        itemId: string,
        patientExternalId: PatientExternalIdentity | null,
    ) => Promise<void>;
    assign?: Assign;
    markAsDone?: MarkAsDone;
    markAsOpen?: MarkAsOpen;
    matchPatientToConversation?: (patientToken: string) => Promise<boolean>;
};

export type SearchedPatientSummary = Omit<PatientSummary, "patientId"> & {
    patientToken: string;
    patientId?: null;
    mobileNumber: string | null;
};

export type PatientType =
    | SearchedPatientSummary
    | (PatientSummary & { patientToken?: null })
    | null;

export type PatientData = {
    data: PatientType;
    isLoading?: boolean;
};

export type ConversationSummary = Pick<
    ConversationType,
    | "assignee"
    | "id"
    | "items"
    | "status"
    | "isFullyLoaded"
    | "regardingPatientId"
>;

export type ConversationProps = {
    conversation: ConversationSummary | null;
    conversationActions: ConversationActions;
    patient: PatientData;
    currentUserId: string;
    composeAreaDefaultOpen?: boolean;
};

export type FileUpload = {
    attachment: AttachmentUpload;
    previewUrl: string;
};
