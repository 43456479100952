import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { FlemingLoggedInCustomProperties } from "./FlemingAnalyticsConstants";

export type PageLoadFromMedicalRecordSystemProperties =
    FlemingLoggedInCustomProperties & { userIsLoggedIn: boolean };

/**
 * When a user lands in accuRx Web after clicking on the button in their Trusts medical records system
 *
 * Event type: Page View
 * */
export const trackPageLoadFromMedicalRecordSystem = (
    props: PageLoadFromMedicalRecordSystemProperties,
): void => {
    GenericTrackEvent({
        object: "WebAccessFromMedicalRecordSystem",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: {
            ...props,
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
        },
    });
};
