import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation, PatientExternalId } from "domains/concierge/types";

type Variables = {
    conversation: Conversation;
    patientIds?: PatientExternalId[];
    note: string;
};

export const useAddNoteMutation = (options?: UseMutationOptions<Variables>) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "addNote",
        async (variables: Variables) => {
            if (variables.conversation.source.system !== "Ticket") {
                throw new Error(
                    `AddNoteMutation does not support ${variables.conversation.source.system} conversations`,
                );
            }

            const { updates } = await api.addNote({
                workspaceId,
                ticketIdentity: variables.conversation.source.ticketIdentity,
                patientIds: variables?.patientIds,
                note: {
                    type: "Text",
                    text: variables.note,
                },
            });

            dispatch(
                actions.processUpdates({
                    source: "Mutation:AddNote",
                    ...updates,
                }),
            );
        },
        options,
    );
};
