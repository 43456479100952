import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { PatientSmsItem } from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type MessagePatientSmsProps = {
    conversationItem: PatientSmsItem;
};

export const MessagePatientSms = ({
    conversationItem,
}: MessagePatientSmsProps) => {
    const userId = conversationItem.createdBy.id;
    const participant = useUser({ userId });

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {participant?.isCurrentUser
                        ? "You"
                        : participant?.displayName}
                    :
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {conversationItem.body}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
