import { Text, Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";

export const StyledLink = styled(Link)`
    display: flex;
    padding: ${Tokens.SIZES[2]};
    gap: ${Tokens.SIZES[2]};

    :hover {
        text-decoration: none;
        background-color: ${Tokens.COLOURS.greyscale.silver};
    }
`;

export const StyledLinkPlaceholder = styled(StyledLink.withComponent("div"))`
    :hover {
        background-color: unset;
    }
`;

export const StyledMainMessage = styled(Text)`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledMainMessagePlaceholder =
    StyledMainMessage.withComponent(SkeletonLabel);

export const StyledConversationList = styled.ul`
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;

    & > li {
        border: ${Tokens.BORDERS.soft.silver};
        border-bottom: none;
    }

    & > li:last-of-type {
        border-bottom: ${Tokens.BORDERS.soft.silver};
    }
`;
