import { useContext } from "react";

import { Thread } from "@accurx/design";
import { DateHelpers, NhsNumberHelpers } from "@accurx/shared";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { v4 as uuid } from "uuid";

import {
    Attachment,
    ClinicianMessage,
} from "../../api/ClinicianConversationApi";
import {
    HighlightableAttachment,
    RaisedAbsoluteContainer,
} from "./conversation/Conversation.styles";
import {
    Participant,
    ParticipantWorkspace,
} from "./participantsPanel/ParticipantsPanel";
import { ClinicianConversationContext } from "./providers";
import {
    MessagesByDate,
    PatientDetails,
    PatientDetailsWithDisplayNames,
    PendingMessage,
    PendingMessageInitial,
    PendingMessageState,
} from "./types";

/**
 * Groups messages by date sent
 * @param messages a list of messages with dates in ISO format
 * @returns {date: string, messages: ClinicianMessage | PendingMessage} where date is the date sent in the format DD MM YYYY
 * and the messages are the messages that were sent on that date, in order of time sent, but otherwise unaltered
 */
export const groupByDate = (
    messages: (ClinicianMessage | PendingMessage)[],
): MessagesByDate[] => {
    const byDateString = groupBy(messages, (m) =>
        DateHelpers.formatDate(m.dateSent, "DD MMM YYYY"),
    );

    return Object.keys(byDateString).map<MessagesByDate>((k) => {
        return {
            date: k,
            messages: orderBy(byDateString[k], (m) =>
                DateHelpers.formatDate(m.dateSent, "DD MMM YYYY"),
            ),
        };
    });
};

export const isSentByCurrentUser = (
    senderEmailAddress: string,
    userEmailAddress?: string,
): boolean => {
    return userEmailAddress?.toLowerCase() === senderEmailAddress.toLowerCase();
};

// Mirrors the Attachments type in Thread.Message
type MapAttachmentsReturn = {
    id: string;
    customElement: JSX.Element;
};

type AttachmentArg = Pick<Attachment, "displayName"> & {
    id: string | number;
    previewUrl?: string;
};

// Adds a download url if no previewUrl is given, and converts ID to string
export const mapAttachments = (
    attachments: AttachmentArg[],
    hasPolled: boolean,
    attachmentIdToHighlight?: string,
): MapAttachmentsReturn[] => {
    return attachments
        .map((attachment) => {
            const { previewUrl, id, displayName } = attachment;
            const context = useContext(ClinicianConversationContext);

            const idAsString = String(id);

            const highlight = attachmentIdToHighlight === idAsString;

            const attachmentProps = {
                highlight,
                scroll: highlight && !hasPolled,
                id: idAsString,
                displayName,
            };

            if (previewUrl) {
                return {
                    id: `attachment-${idAsString}`,
                    customElement: (
                        <ScrollableHighlightableAttachment
                            {...attachmentProps}
                            previewUrl={previewUrl}
                        />
                    ),
                };
            }

            if (context?.getAttachmentUrl) {
                return {
                    id: `attachment-${idAsString}`,
                    customElement: (
                        <ScrollableHighlightableAttachment
                            {...attachmentProps}
                            downloadUrl={context.getAttachmentUrl(
                                idAsString,
                                displayName,
                            )}
                        />
                    ),
                };
            }
            return null;
        })
        .filter((x) => x !== null) as MapAttachmentsReturn[];
};

const ScrollableHighlightableAttachment = (
    props: React.ComponentProps<typeof HighlightableAttachment> & {
        scroll: boolean;
    },
) => {
    const attachment = <HighlightableAttachment {...props} />;

    return props.scroll ? (
        <>
            <RaisedAbsoluteContainer>
                <Thread.ScrollAnchor />
            </RaisedAbsoluteContainer>
            {attachment}
        </>
    ) : (
        attachment
    );
};

export const isPendingMessage = (
    message: ClinicianMessage | PendingMessage,
): message is PendingMessage => "tempMessageId" in message;

export const createPendingMessage = (
    initialValues: PendingMessageInitial,
): PendingMessage => ({
    ...initialValues,
    tempMessageId: uuid(),
    dateSent: new Date().toISOString(),
    state: "sending",
    dateRead: undefined,
    previousMessages: undefined,
});

export const updatePendingMessages =
    (tempMessageId: string, state: PendingMessageState | "sent") =>
    (pendingMessages: PendingMessage[]): PendingMessage[] =>
        state === "sent"
            ? pendingMessages.filter(
                  ({ tempMessageId: id }) => id !== tempMessageId,
              )
            : pendingMessages.map((message) => ({
                  ...message,
                  state:
                      message.tempMessageId === tempMessageId
                          ? state
                          : message.state,
              }));

export const addPendingMessage =
    (pendingMessage: PendingMessage) =>
    (pendingMessages: PendingMessage[]): PendingMessage[] =>
        [...pendingMessages, pendingMessage];

const getEmailAddresses = (participants: Participant[]) => {
    return participants.map((participant) => {
        return participant.emailAddress.toLowerCase();
    });
};

// Returns all participants from within a workspace
const getWorkspaceParticipants = (
    workspaces: ParticipantWorkspace[],
): string[] => {
    const emailAddresses: string[] = [];
    workspaces.forEach((currentWorkspace) =>
        emailAddresses.push(
            ...getEmailAddresses(currentWorkspace.participants),
        ),
    );

    return emailAddresses;
};

export const getParticipantsCountForTracking = ({
    individualParticipants,
    workspaces,
    messageSender,
}: {
    individualParticipants: Participant[];
    workspaces: ParticipantWorkspace[];
    /** messageSender: string - use this if tracking a message being sent, as if they have not previously been included in the participants list, we need to add them */
    messageSender?: string;
}): number => {
    // If we're tracking a message being sent, add the sender to the participants list, and remove duplicates before getting count
    return new Set([
        ...getEmailAddresses(individualParticipants),
        ...getWorkspaceParticipants(workspaces),
        ...(messageSender ? [messageSender.toLowerCase()] : []),
    ]).size;
};

/*
/ Returns `true` if the list of saved messages has changed length, or the list of pending messages has been updated at all (eg. if the status of a message has been udpated)
*/
export const hasNewMessageHelper = (
    prevMessages: ClinicianMessage[],
    currentMessages: ClinicianMessage[],
    prevPendingMessages: PendingMessage[],
    pendingMessages: PendingMessage[],
): boolean =>
    prevPendingMessages !== pendingMessages ||
    prevMessages.length !== currentMessages.length;

// Returns an updated conversation containing the message to be added if it hasn't already been added. Otherwise returns the existing conversation.
export const addMessageToMessages = ({
    prevMessages,
    message,
}: {
    prevMessages: ClinicianMessage[];
    message: ClinicianMessage;
}) => {
    const hasMessageBeenAddedToConversation = prevMessages.some(
        (x) => x.messageId === message.messageId,
    );

    if (hasMessageBeenAddedToConversation) {
        return prevMessages;
    }

    return [...prevMessages, message];
};

export const getPatientDetailsWithDisplayNames = (
    patient: PatientDetails,
): PatientDetailsWithDisplayNames => {
    return {
        ...patient,
        displayName: getDisplayName(patient),
        displayNameWithNHSNo: getDisplayNameWithNHSNo(patient),
    };
};

const getDisplayName = (patient: PatientDetails): string => {
    let displayName = "Unknown";
    const { firstName, lastName, prefixName } = patient;

    if (firstName && lastName) {
        displayName = `${lastName.toUpperCase()}, ${firstName}`;
    } else if (lastName) {
        displayName = `${lastName}`;
    } else if (firstName) {
        displayName = `${firstName}`;
    }
    return `${displayName} (${prefixName})`;
};

const getDisplayNameWithNHSNo = (patient: PatientDetails): string => {
    if (patient?.nhsNumber) {
        return `${getDisplayName(
            patient,
        )}, NHS no. ${NhsNumberHelpers.formatNhsNumber(patient?.nhsNumber)}`;
    }
    return getDisplayName(patient);
};

export const buildSubjectFromPatient = (
    patient: PatientDetailsWithDisplayNames,
) => `Regarding ${patient.displayNameWithNHSNo}`;
