import moment from "moment";

/**
 * Default week is Sunday to Saturday
 * This function instead does Monday to Sunday
 */
export const getStartAndEndOfPreviousWeek = (): [
    moment.Moment,
    moment.Moment,
] => {
    const monday = moment().subtract(1, "weeks").isoWeekday(1).startOf("day");
    const sunday = moment(monday).add(6, "days").endOf("day");

    return [monday, sunday];
};

/**
 * Default week is Sunday to Saturday
 * This function instead does Monday to Sunday
 */
export const getStartAndEndOfCurrentWeek = (): [
    moment.Moment,
    moment.Moment,
] => {
    const monday = moment().isoWeekday(1).startOf("day");
    const sunday = moment(monday).add(6, "days").endOf("day");

    return [monday, sunday];
};

export const getStartAndEndOfFortnight = (): [moment.Moment, moment.Moment] => {
    const [startDate] = getStartAndEndOfPreviousWeek();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, endDate] = getStartAndEndOfCurrentWeek();
    return [startDate, endDate];
};
