import { ReactNode } from "react";

import { useRouteMatch } from "react-router-dom";

import { StyledNavLink } from "./NavLink.styles";

export const PrimaryNavLink = ({
    activePaths,
    to,
    children,
}: {
    activePaths: string[];
    to: string;
    children: ReactNode;
}) => {
    const routeMatch = useRouteMatch(activePaths);
    const isActiveRoute = Boolean(routeMatch);

    return (
        <li>
            <StyledNavLink to={to} data-active={isActiveRoute}>
                {children}
            </StyledNavLink>
        </li>
    );
};
