import React from "react";

import { UserStatus } from "@accurx/api/portal";
import { useCurrentWorkspace } from "@accurx/auth";
import {
    useUpdateUserStatusMutation,
    useWorkspaceUsersQuery,
} from "@accurx/workspace-management";

import { trackUserManagementAction } from "app/analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { showErrorToast, showSuccessToast } from "../utils/toasts";
import { ConfirmationModal } from "./ConfirmationModal";

export const ArchiveModal = ({
    name,
    userId,
    onCloseModal,
}: {
    name: string;
    userId: number;
    onCloseModal: () => void;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const { orgId: workspaceId, organisationName: workspaceName } =
        useCurrentWorkspace();

    const { refetch: refetchUsers } = useWorkspaceUsersQuery({ workspaceId });

    const { mutate: mutateStatus } = useUpdateUserStatusMutation({
        onSuccess: async () => {
            onCloseModal();
            showSuccessToast(`${name} is now archived from ${workspaceName}`);
            trackUserManagementAction({
                ...analyticsLoggedInProps,
                targetUserId: userId,
                actionSelected: "Archive",
            });
            await refetchUsers();
        },
        onError: () => showErrorToast,
    });

    return (
        <ConfirmationModal
            open
            onOpenChange={onCloseModal}
            onConfirm={() =>
                mutateStatus({
                    workspaceId,
                    userId,
                    status: UserStatus.Archived,
                })
            }
            headerText="Archive user from workspace"
            confirmText="Archive"
        >
            Archiving {name} means they will no longer have access to this
            workspace. They can still rejoin or be unarchived by an admin.
        </ConfirmationModal>
    );
};
