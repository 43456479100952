import { useMemo } from "react";

import { useLocation } from "react-router";

export const useSearchParams = (): Partial<Record<string, string>> => {
    const location = useLocation();
    return useMemo(() => {
        const params: Record<string, string> = {};
        new URLSearchParams(location.search).forEach((v, k) => {
            params[k] = v;
        });
        return params;
    }, [location.search]);
};
