import { FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSendViaButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;

    border-radius: ${Tokens.SIZES[1]};

    &:hover {
        > * {
            border-color: ${Tokens.COLOURS.primary.blue[100]};
            text-decoration: none;
            background: ${Tokens.COLOURS.primary.blue[5]};
            transition: background-color 0.2s ease-in;
        }
    }
`;

export const StyledDetailsSection = styled.div<{
    singleOption?: boolean;
}>`
    display: flex;
    align-items: center;
    border: ${Tokens.BORDERS.normal.silver};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};

    ${({ singleOption }) => {
        if (!singleOption) {
            return `
                border-right: 0;
                border-radius: ${Tokens.SIZES[0.5]} 0px 0px ${Tokens.SIZES[0.5]};
            `;
        }

        return `
            border-radius: ${Tokens.SIZES[0.5]};
        `;
    }}
`;

export const StyledIconSection = styled.div<{
    isOpen: boolean;
}>`
    background: ${({ isOpen }) =>
        isOpen ? Tokens.COLOURS.greyscale.silver : Tokens.COLOURS.transparent};
    border: 1px solid
        ${({ isOpen }) =>
            isOpen
                ? Tokens.COLOURS.primary.blue["100"]
                : Tokens.COLOURS.greyscale.silver};
    border-radius: 0px ${Tokens.SIZES[0.5]} ${Tokens.SIZES[0.5]} 0px;
    padding: ${Tokens.SIZES[1]};
`;

export const StyledWrapper = styled.div`
    flex: 1;

    display: flex;
    gap: ${Tokens.SIZES[1]};
    align-items: baseline;
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    width: 100%;
    margin-left: ${Tokens.SIZES[0.5]};
`;
