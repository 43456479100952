import { Card, Link, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { SharedUrls } from "@accurx/shared";

import { AccountPageLayout } from "./AccountPageLayout";

export const TermsAndConditionsPage = () => {
    useAccurxWebTitle("Review terms and conditions");
    return (
        <AccountPageLayout title={"Terms and Conditions"}>
            <Card spacing={2}>
                <Text skinny>
                    {"Your use of Accurx is subject to the "}
                    <Link href={SharedUrls.TermsAndConditions} openInNewTab>
                        Terms and Conditions
                        <Link.Icon />
                    </Link>
                    {" on our website. Please read these carefully."}
                </Text>
            </Card>
        </AccountPageLayout>
    );
};
