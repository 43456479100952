import { StackPanel } from "@accurx/design";
import styled from "styled-components";

export const OverflowStackPanel = styled(StackPanel)<{
    $hideOverflow?: boolean;
}>`
    overflow-y: ${({ $hideOverflow }) =>
        $hideOverflow ? "hidden" : "initial"};
    width: 100%;
`;
