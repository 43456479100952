import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";

import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { BreadcrumbWrapper } from "./BreadcrumbWrapper";

export type BreadcrumbProps = {
    title?: string;
    wrapper?: boolean;
};

export const Breadcrumb = ({ title, wrapper = true }: BreadcrumbProps) => {
    const orgId = useAppSelector(({ practices }) => practices.selectedPractice);
    const orgName = useAppSelector(({ account, practices }) => {
        const organisations = account.user?.organisations || [];
        const organisation = organisations.find(
            (org) => org.orgId.toString() === practices.selectedPractice,
        );
        return organisation?.organisationName || "";
    });

    const newManageOrgPage = useIsFeatureEnabled(
        FeatureName.NewManageOrganisationPage,
    );

    /*
     * This is a less-than-ideal temporary fix
     * while we move pages over to the new /organisations layout.
     * It won't exactly match the breadcrumbs in the new pages
     * as the layout of the page (colours etc) are different,
     * and we don't have all the info we need to display
     * all the breadcrumbs at this point in the page, but
     * it will at least be a bit less confusing as it won't
     * say "Practices".
     */
    if (newManageOrgPage && title && orgId && orgName) {
        return (
            <BreadcrumbWrapper wrapperContent={wrapper}>
                <>
                    <Text
                        as="a"
                        variant="link"
                        props={{
                            href: `/practices/${orgId}/details`,
                        }}
                        skinny
                    >
                        {orgName}
                    </Text>
                    <Text as="span" props={{ className: "mx-2" }} skinny>
                        /
                    </Text>
                    <Text as="span" skinny>
                        {title}
                    </Text>
                </>
            </BreadcrumbWrapper>
        );
    }

    return (
        <BreadcrumbWrapper wrapperContent={wrapper}>
            <>
                <Text skinny>
                    <Text
                        as="a"
                        variant="link"
                        props={{ href: "/practices" }}
                        skinny
                    >
                        Practices
                    </Text>
                    {orgId && orgName && (
                        <>
                            <Text
                                as="span"
                                props={{ className: "mx-2" }}
                                skinny
                            >
                                /
                            </Text>
                            {title ? (
                                <Text
                                    as="a"
                                    variant="link"
                                    props={{
                                        href: `/practices/${orgId}/details`,
                                    }}
                                    skinny
                                >
                                    {orgName}
                                </Text>
                            ) : (
                                <Text as="span" skinny>
                                    {orgName}
                                </Text>
                            )}
                        </>
                    )}
                    {title && (
                        <>
                            <Text
                                as="span"
                                props={{ className: "mx-2" }}
                                skinny
                            >
                                /
                            </Text>
                            <Text as="span" skinny>
                                {title}
                            </Text>
                        </>
                    )}
                </Text>
            </>
        </BreadcrumbWrapper>
    );
};
