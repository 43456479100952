import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledPageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[2]} ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: 0 ${Tokens.SIZES[4]} ${Tokens.SIZES[4]} ${Tokens.SIZES[4]};
    }
`;

export const StyledContainer = styled.div`
    max-width: 100%;
    margin-top: ${Tokens.SIZES[4]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: 67%;
    }
`;

export const StyledFooterLink = styled.div`
    margin-top: ${Tokens.SIZES[4]};
    text-align: center;
`;
