import { z } from "zod";

import { PatientDemographicsSchema } from "./PatientDemographicsSchema";

export const SubscribeCurrentPatientDtoSchema = z.object({
    patientDemographics: PatientDemographicsSchema.nullable(),
});

export type SubscribeCurrentPatientDto = z.infer<
    typeof SubscribeCurrentPatientDtoSchema
>;
