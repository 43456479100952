import { useCurrentUser } from "@accurx/auth";
import { useConversationUnreadCount } from "@accurx/concierge/hooks/data/useConversationUnreadCount";
import { useIsConversationNotifiableForUser } from "@accurx/concierge/hooks/data/useIsConversationNotifiableForUser";
import { Conversation } from "@accurx/concierge/types";

export const useCanMarkConversationUnread = (conversation: Conversation) => {
    const { user } = useCurrentUser();
    const isConversationNotifiableForUser = useIsConversationNotifiableForUser({
        conversationId: conversation.id,
        userId: user.accuRxUserId,
    });
    const unreadCount = useConversationUnreadCount(conversation.id);
    const isDone = conversation.status === "Done";
    const isRead = unreadCount === 0;
    const canMarkUnread = !isDone && isRead && isConversationNotifiableForUser;
    return canMarkUnread;
};
