import { ReactNode, useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { Feedback, Flex, Text } from "@accurx/design";
import { i } from "images";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { postConfirmEmail, postResendEmail } from "api/AccountApi";
import { ROUTES } from "shared/Routes";
import { getStringQuery } from "shared/RoutesHelper";

import { formatSearch } from "./Account.helper";
import { StyledConfirmEmailVerify } from "./ConfirmEmailVerify.styles";
import { SHOW_LOGIN_FORM_SEARCH_PARAM } from "./LogIn/LogIn.constants";
import { InnerStackPanel, OuterStackPanel } from "./LogIn/LoginStackPanel";
import { useGetProductTypeFromUrl } from "./LogIn/hooks";

const ConfirmVerifyInnerStackPanel = ({
    children,
}: {
    children: ReactNode;
}) => (
    <InnerStackPanel horizontalContentAlignment="stretch">
        {children}
    </InnerStackPanel>
);

const ConfirmVerifyTitle = () => (
    <Text variant="title" as="h1" skinny>
        Please verify your email
    </Text>
);

const ConfirmVerifyVerify = ({
    onVerify,
    isVerifying,
}: {
    onVerify: () => void;
    isVerifying: boolean;
}) => (
    <Flex flexDirection="column" gap="2">
        <Text skinny>
            Click the button below to verify your email and starting your Accurx
            account.
        </Text>
        <StyledConfirmEmailVerify.Button
            onClick={onVerify}
            text="Verify my email"
            disabled={isVerifying}
        />
    </Flex>
);

/**
 * Verify request has not been successful, so in this case we show a bit of UI to resend
 * the verify link
 */
const ConfirmVerifyResend = ({
    userId,
    product,
}: {
    userId: string | null;
    product: RegisterAccountProductType;
}) => {
    const history = useHistory();
    const [requestStatus, setRequestStatus] = useState<
        "idle" | "loading" | "error"
    >("idle");
    const [requestErrorMessage, setRequestErrorMessage] = useState("");

    const handleResend = async () => {
        if (!userId) {
            setRequestErrorMessage(
                "We couldn't find your email address. Please contact user support for help.",
            );
            return;
        }

        setRequestErrorMessage("");
        setRequestStatus("loading");
        const res = await postResendEmail({
            userId,
            product,
        });

        if (res.success) {
            history.push(ROUTES.checkYourInbox);
        } else {
            setRequestStatus("error");
            setRequestErrorMessage(
                res.error || "Oops! Looks like something has gone wrong.",
            );
        }
    };

    return (
        <>
            <div>
                <Text skinny variant="label">
                    Oops! Looks like something has gone wrong
                </Text>
                <Text skinny>
                    Please click below to request a new confirmation email.
                </Text>
            </div>
            <StyledConfirmEmailVerify.Button
                onClick={handleResend}
                text="Send new verification email"
                disabled={requestStatus === "loading"}
            />
            {requestErrorMessage && (
                <Feedback colour="error" title={requestErrorMessage} />
            )}
        </>
    );
};

/**
 * After we verify, we redirect to login page, with the email param if we can
 */
const ConfirmVerifyTrust = ({
    code,
    userId,
}: {
    code: string;
    userId: string;
}) => {
    const history = useHistory();
    const searchPreLogin = formatSearch({
        search: history.location.search,
        paramsToRemove: ["userId", "code"],
    });

    // Whether there is an API request pending - used to disable buttons
    const [verifyRequestStatus, setRequestStatus] = useState<
        "idle" | "loading" | "error"
    >("idle");

    const handleVerify = async () => {
        setRequestStatus("loading");
        const res = await postConfirmEmail({
            code,
            userId,
            product: RegisterAccountProductType.Fleming,
        });

        if (res.success) {
            setRequestStatus("idle");

            toast(
                <Feedback colour="success" title="Email verified">
                    You can now log in with your new account
                </Feedback>,
            );
            history.push(
                `${ROUTES.login}${searchPreLogin}&${SHOW_LOGIN_FORM_SEARCH_PARAM}=true`,
                {
                    email: res?.result?.email,
                },
            );
        } else {
            setRequestStatus("error");
        }
    };

    return (
        <>
            <ConfirmVerifyTitle />
            <ConfirmVerifyInnerStackPanel>
                <Flex flexDirection="column" gap="2">
                    {verifyRequestStatus !== "error" && (
                        <ConfirmVerifyVerify
                            onVerify={handleVerify}
                            isVerifying={verifyRequestStatus === "loading"}
                        />
                    )}
                    {verifyRequestStatus === "error" && (
                        <ConfirmVerifyResend
                            userId={userId}
                            product={RegisterAccountProductType.Fleming}
                        />
                    )}
                </Flex>
            </ConfirmVerifyInnerStackPanel>
        </>
    );
};

/**
 * After verify succeeds, we try to redirect to a magic link page
 * Otherwise we show some UI with a link to download desktop
 */
const ConfirmVerifyGp = ({
    code,
    userId,
}: {
    code: string;
    userId: string;
}) => {
    const history = useHistory();

    // Whether there is an API request pending - used to disable buttons
    const [verifyRequestStatus, setRequestStatus] = useState<
        "idle" | "loading" | "error" | "success"
    >("idle");

    const handleVerify = async () => {
        setRequestStatus("loading");
        const res = await postConfirmEmail({
            code,
            userId,
            product: RegisterAccountProductType.Chain,
        });

        if (res.success) {
            const emailParam = res?.result?.email
                ? `&email=${encodeURIComponent(res.result.email)}`
                : "";
            const tokenParam = res?.result?.passwordlessToken
                ? `&token=${encodeURIComponent(res.result.passwordlessToken)}`
                : "";

            if (tokenParam && emailParam) {
                history.push(
                    `${ROUTES.magicLink}?productType=${RegisterAccountProductType.Chain}${emailParam}${tokenParam}`,
                );
            } else {
                setRequestStatus("success");
            }
        } else {
            setRequestStatus("error");
        }
    };

    return (
        <>
            {verifyRequestStatus !== "success" && (
                <>
                    <ConfirmVerifyTitle />
                    <ConfirmVerifyInnerStackPanel>
                        <Flex flexDirection="column" gap="2">
                            {(verifyRequestStatus === "idle" ||
                                verifyRequestStatus === "loading") && (
                                <ConfirmVerifyVerify
                                    onVerify={handleVerify}
                                    isVerifying={
                                        verifyRequestStatus === "loading"
                                    }
                                />
                            )}
                            {verifyRequestStatus === "error" && (
                                <ConfirmVerifyResend
                                    userId={userId}
                                    product={RegisterAccountProductType.Fleming}
                                />
                            )}
                        </Flex>
                    </ConfirmVerifyInnerStackPanel>
                </>
            )}
            {verifyRequestStatus === "success" && (
                <>
                    <Text variant="title" as="h1" skinny>
                        Email verified
                    </Text>
                    <ConfirmVerifyInnerStackPanel>
                        <Text skinny>
                            You can now close this page, return to your desktop
                            and sign in to the Accurx Desktop toolbar.
                        </Text>
                        <Text skinny>
                            Haven't installed the Accurx Desktop toolbar?
                            <Text
                                as="a"
                                variant="link"
                                props={{
                                    href: ROUTES.downloadDesktop,
                                }}
                                skinny
                            >
                                Install now
                            </Text>
                        </Text>
                    </ConfirmVerifyInnerStackPanel>
                </>
            )}
        </>
    );
};

export const ConfirmEmailVerify = (): JSX.Element => {
    const location = useLocation();
    const code = getStringQuery(location.search, "code") || null;
    const userId = getStringQuery(location.search, "userId") || null;
    const product = useGetProductTypeFromUrl();

    return (
        <OuterStackPanel>
            {!code || !userId ? (
                <ConfirmVerifyInnerStackPanel>
                    <ConfirmVerifyResend product={product} userId={userId} />
                </ConfirmVerifyInnerStackPanel>
            ) : (
                <>
                    <StyledConfirmEmailVerify.Img
                        src={i("tick-illustration.png")}
                        alt="Verify email tick illustration"
                    />
                    {product === RegisterAccountProductType.Fleming && (
                        <ConfirmVerifyTrust userId={userId} code={code} />
                    )}
                    {product !== RegisterAccountProductType.Fleming && (
                        <ConfirmVerifyGp userId={userId} code={code} />
                    )}
                </>
            )}
        </OuterStackPanel>
    );
};
