import { Pagination } from "shared/concierge/types/subscription.types";

import { StyledNextPreviousButton } from "./PaginationNavigation.styles";

type PaginationNavigationProps = {
    paginationActions?: Pagination;
    hasLoadedAllDataRequired: boolean;
    uniqueId: string;
};

export const PaginationNavigation = ({
    paginationActions,
    hasLoadedAllDataRequired,
    uniqueId,
}: PaginationNavigationProps): JSX.Element => {
    return (
        <>
            {paginationActions?.previous && (
                <StyledNextPreviousButton
                    theme="secondary"
                    icon={{
                        name: "ArrowTail",
                        rotation: "left",
                        title: "Goto previous page",
                        id: `previous-page-${uniqueId}`,
                    }}
                    onClick={paginationActions.previous}
                />
            )}
            {
                // Don't allow next if we haven't loaded all data required for this page
                hasLoadedAllDataRequired && paginationActions?.next && (
                    <StyledNextPreviousButton
                        theme="secondary"
                        aria-label="Goto next page"
                        icon={{
                            name: "ArrowTail",
                            rotation: "right",
                            title: "Goto next page",
                            id: `next-page-${uniqueId}`,
                        }}
                        onClick={paginationActions.next}
                    />
                )
            }
        </>
    );
};
