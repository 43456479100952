import { FormFieldWrapper, Input, Text } from "@accurx/design";

import { ValidationDetails } from "./TemplateForm";

type TemplateTitleProps = {
    title: string | undefined;
    setTitle: (title: string) => void;
    validationDetails: ValidationDetails;
};

export const TemplateTitle = ({
    validationDetails,
    title,
    setTitle,
}: TemplateTitleProps) => (
    <FormFieldWrapper
        label="Template title"
        errors={
            validationDetails.submitted &&
            (title === undefined || title.length === 0)
                ? ["Template name can't be empty"]
                : title !== undefined && title.length > 50
                ? ["Template name can't be more than 50 characters long"]
                : []
        }
        metaInfo={
            <>
                <Text variant="note" as="span">
                    <Text
                        variant="note"
                        as="span"
                        colour={(title?.length ?? 0) > 50 ? "red" : undefined}
                    >
                        {title?.length ?? 0}
                    </Text>
                    /50
                </Text>
            </>
        }
    >
        <Input
            id="title"
            title="template-title"
            hasErrors={
                (validationDetails.submitted &&
                    (title === undefined || title.length === 0)) ||
                (title !== undefined && title.length > 50)
            }
            value={title}
            type="text"
            placeholder="e.g. Blood pressure enquiry"
            onChange={({ target }) => setTitle(target.value)}
        ></Input>
    </FormFieldWrapper>
);
