import { useEffect, useRef, useState } from "react";

import { poller } from "domains/concierge/internal/util/poller";
import {
    PollerOptions,
    PollerStatus,
} from "domains/concierge/internal/util/poller.types";

import { useHubClient } from "shared/hubClient/useHubClient";

import { offlinePoller } from "../util/offlinePoller";

type UsePollerOptions = {
    name: PollerOptions["name"];
    fetchFn: PollerOptions["fetchFn"];
    refreshRate: PollerOptions["refreshRate"];
    skipInitialFetch?: PollerOptions["skipInitialFetch"];
    onlyPollWhenOffline?: boolean;
};

/*
 * WARNING: the fetchFn passed to this
 * hook MUST BE MEMOIZED using useCallback. We've tried to do this
 * memoization internal to the hook but it
 * makes it very hard to figure out when it should change
 * - so you as a consumer of this hook are responsible
 * for knowing this and using a useCallback hook and dependency
 * array appropriately.
 */
export const usePoller = (options: UsePollerOptions): PollerStatus => {
    const hubClient = useHubClient();

    const {
        name,
        fetchFn,
        refreshRate,
        skipInitialFetch,
        onlyPollWhenOffline,
    } = options;

    // Keep a live ref of skipInitialFetch - we only
    // need this value once each time we spin up an offlinePoller
    // - we don't want to trigger the teardown and creation of a
    // poller when it changes - so we want to use it in the
    // useEffect without including it in the dependency array
    const skipInitialFetchRef = useRef(skipInitialFetch);
    useEffect(() => {
        skipInitialFetchRef.current = skipInitialFetch;
    }, [skipInitialFetch]);

    const [state, setState] = useState<PollerStatus>({
        status: skipInitialFetch ? "success" : "loading",
        refresh: { status: "idle" },
    });

    useEffect(() => {
        setState({
            status: skipInitialFetchRef.current ? "success" : "loading",
            refresh: { status: "idle" },
        });

        const pollerOptions: PollerOptions = {
            name,
            fetchFn,
            refreshRate,
            skipInitialFetch: skipInitialFetchRef.current,
            onFetchStart: (meta) => {
                if (meta.initialized) {
                    setState((s) => ({
                        ...s,
                        refresh: { status: "loading" },
                    }));
                }
            },
            onFetchSuccess: () => {
                setState(() => ({
                    status: "success",
                    refresh: { status: "idle" },
                }));
            },
            onFetchError: (e, meta) => {
                if (meta.initialized) {
                    setState((s) => ({
                        ...s,
                        refresh: { status: "error", error: e },
                    }));
                } else {
                    setState(() => ({
                        status: "error",
                        error: e,
                        refresh: { status: "idle" },
                    }));
                }
            },
        };

        if (onlyPollWhenOffline) {
            return offlinePoller(hubClient, pollerOptions);
        } else {
            return poller(pollerOptions);
        }
    }, [name, fetchFn, refreshRate, hubClient, onlyPollWhenOffline]);

    return state;
};
