import React from "react";

import { Text } from "@accurx/design";

const renderInstructions = (instructions: string[][]): JSX.Element => {
    return (
        <ol>
            {instructions.map((instruction, index) => {
                if (instruction.length === 1) {
                    return (
                        <li key={index}>
                            <Text as="p" variant="body">
                                {instruction}
                            </Text>
                        </li>
                    );
                } else {
                    const topLevelInstruction = instruction.shift();
                    return (
                        <li key={index}>
                            <Text as="p" variant="body">
                                {topLevelInstruction}
                            </Text>
                            <ul>
                                {instruction.map((subInstruction, indexSub) => (
                                    <li key={indexSub}>
                                        <Text as="p" variant="body">
                                            {subInstruction}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    );
                }
            })}
        </ol>
    );
};

export const renderEmisInstructions = (): JSX.Element => {
    const instructions = [
        [
            "Create patient search, containing the search terms for the patients you want to invite",
        ],
        ["Under 'Reporting' select 'Population reporting'"],
        ["Under the 'Add' button select 'Patient' -> 'List Report'"],
        [
            "Name your report, and add in the patient search in the 'Results from' box",
        ],
        ["Under the 'Add' button select 'Patient Details'"],
        [
            "Select 'Title, Given Name, Family Name, Date of Birth, NHS Number'. Then expand the Contact Details section and select 'Mobile telephone'",
        ],
        ["Select 'Save and run' in the top bar"],
        ["Right click on the report, and select 'View results'"],
        ["Select 'Export' in the top bar"],
        ["Select 'CSV' and 'OK', and save your CSV."],
    ];
    return renderInstructions(instructions);
};

export const renderEmisGpsocInstructions = (): JSX.Element => {
    const instructions = [
        ["Under 'Reporting' select 'Population reporting'"],
        ["Select the list of patients you want to batch message"],
        [
            "If you would like to remove those who have declined consent to be contacted by SMS, add this as a 'new rule'",
        ],
        ["Select 'Population included''"],
        ["Select 'Export results' → 'CSV'"],
        ["Save the list with a suitable name"],
    ];
    return renderInstructions(instructions);
};

export const renderSystmOneInstructions = (): JSX.Element => {
    const instructions = [
        [
            "Create the list of patients you would like to send a text to in SystmOne",
        ],
        [
            "If the list does not include mobile numbers, you will need to add this as follows:",
            "Select 'Breakdown results' and click 'Demographics' on the left hand side",
            'Make sure that "Telecom number" is ticked',
            "Select 'Refresh' at the top of the list.",
        ],
        [
            "Right click on the list, and select 'Save as CSV'. Save the list with a suitable name.",
        ],
    ];
    return renderInstructions(instructions);
};
