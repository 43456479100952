import { EmailAddressHelper } from "@accurx/shared";

/**
 * Mirrors accuRx.Shared.Classes.LoginHelper.IsAllowedToRegisterForChain.
 * @summary Returns true if a user with the email address supplied is allowed to register for the Chain product.
 */
export const isAllowedToRegisterForChain = (email: string | null): boolean => {
    if (email === null) {
        return false;
    }

    if (EmailAddressHelper.isValidEmailAddress(email) === false) {
        return false;
    }

    const domain = email.substring(email.indexOf("@") + 1).toLowerCase();
    return _allowedDomains.some((allowedDomain) =>
        allowedDomain.isMatch(domain),
    );
};

class AllowlistedDomain {
    readonly domain: string;
    readonly allowSubdomains: boolean;

    constructor(domain: string, allowSubdomains: boolean) {
        this.domain = domain;
        this.allowSubdomains = allowSubdomains;
    }

    isMatch(lowercaseDomain: string): boolean {
        return (
            lowercaseDomain === this.domain ||
            (this.allowSubdomains &&
                lowercaseDomain.endsWith(`.${this.domain}`))
        );
    }
}

const _allowedDomains = [
    new AllowlistedDomain("accurx.com", true),
    new AllowlistedDomain("accurxdevelopment.onmicrosoft.com", false),
    new AllowlistedDomain("nhs.net", true),
    new AllowlistedDomain("nhs.uk", true),
    new AllowlistedDomain("gpnet.je", true),
    new AllowlistedDomain("circlehealthgroup.co.uk", false),
    new AllowlistedDomain("hscni.net", false),
    new AllowlistedDomain("gp.hscni.net", true),
    new AllowlistedDomain("setrust.hscni.net", true),
    new AllowlistedDomain("southerntrust.hscni.net", true),
    new AllowlistedDomain("northerntrust.hscni.net", true),
    new AllowlistedDomain("yourhealthcare.org", false),
    new AllowlistedDomain("mod.gov.uk", false),
    new AllowlistedDomain("gov.im", false),
    new AllowlistedDomain("nhs.scot", false),
];
