export const TITLE_MAX_LENGTH = 50;
export const BODY_MAX_LENGTH = 450;

export const MESSAGE_TEMPLATE_FORM_TITLE_ID = "createEditModalTitle";

export enum MessageTemplateCategorySelectValues {
    Unselected = "",
    Sms = "sms",
    VideoConsult = "video-consult",
}

export const MAX_FILES_CAN_BE_ATTACHED = 1;
export const MAX_FILES_SIZE_IN_MB = 3;
export const ATTACHMENT_ACCEPTS =
    ".pdf, .docx, .doc, .jpeg, .jpg, .png, .tiff, .tiff2, .tif, .rtf";
