import * as UI from "@accurx/design";

export const NoPatientsFoundFeedback = ({ id }: { id: string }) => {
    return (
        <UI.Feedback
            colour="warning"
            title="We haven't found a patient matching these details. Please try again."
            props={{ "aria-live": "polite", id }}
        />
    );
};
