import React from "react";

import { Spinner } from "@accurx/design";
import NotFound from "NotFound";
import { Switch } from "react-router-dom";

import { AppRoute } from "app/AppRoute";
import { Container } from "app/layout/Container";
import { PatientProfile } from "app/patientProfile/PatientProfile";
import { SearchedPatientConversationPage } from "app/patientProfile/SearchedPatientConversationPage";
import { NewConversationPage } from "app/workspaceConversations/pages/NewConversationPage";
import { ROUTES_WORKSPACE } from "shared/Routes";
import { useOptionalPatientManager } from "shared/concierge/patients/hooks";

export const SearchedPatientSection = (): JSX.Element => {
    const manager = useOptionalPatientManager();

    if (!manager) {
        // This is expected to occur while the patient manager is loading
        // We do this so we can guarantee all children beneath here can get a
        // patient manager
        return <Spinner />;
    }

    return (
        <Switch>
            <AppRoute path={ROUTES_WORKSPACE.patientProfile} exact>
                <Container>
                    <PatientProfile userAgent={window.navigator.userAgent} />
                </Container>
            </AppRoute>
            <AppRoute
                path={[
                    ROUTES_WORKSPACE.searchedPatientConversationNew,
                    ROUTES_WORKSPACE.searchedPatientConversationNewWithId,
                ]}
                exact
            >
                <NewConversationPage />
            </AppRoute>
            <AppRoute path={ROUTES_WORKSPACE.searchedPatientConversation} exact>
                <SearchedPatientConversationPage />
            </AppRoute>
            <AppRoute path="*">
                <NotFound />
            </AppRoute>
        </Switch>
    );
};
