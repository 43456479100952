import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

const { SIZES } = Tokens;

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export const StyledFilterContainer = styled(Flex)`
    flex-grow: 1;
`;

export const StyledSourceFilterWrapper = styled.div`
    width: 100%;
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: 18.5rem;
    }
`;

export const StyledFilterWrapper = styled.div`
    width: 100%;
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: 12.5rem;
    }
`;

export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${SIZES[1]};
`;

export const StyledSubtitleWrapper = styled.div`
    display: flex;
`;

export const StyledTooltipWrapper = styled.div`
    position: relative;
`;

export const StyledTooltipInnerWrapper = styled.div`
    position: absolute;
    // z-index here allows tooltip popper to render
    // above the char even when it has a fixed
    // element with transformed elements.
    z-index: 999;
`;

export const StyledTooltipContentWrapper = styled.div`
    padding: ${SIZES[2]};
    max-width: 15rem;
    position: relative;
`;
