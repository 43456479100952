import { z } from "zod";

/**
 * Successful response messages contain some fixed values:
 * - success
 * - type
 * - requestId
 *
 * They will also contain a response body who's key depends on the message type.
 *
 * For example a response to the "SubscribeCurrentPatient" message will look like
 * this:
 * ```
 * {
 *   success: true,
 *   type: "SubscribeCurrentPatient",
 *   requestId: "1234-5678-9012-3456",
 *   getCurrentPatient: { ... }
 * }
 * ```
 *
 * Because the body key can change we use schema.passthrough() to ensure that
 * the body does not get removed by Zod when parsed.
 */
export const NativeToWebSuccessMessageSchema = z
    .object({
        success: z.literal(true),
        requestType: z.string(),
        requestId: z.string(),
    })
    .passthrough();

export const NativeToWebErrorMessageSchema = z.object({
    success: z.literal(false),
    requestType: z.string(),
    requestId: z.string(),
    error: z.object({
        type: z.string(),
        message: z.string().nullable(),
    }),
});

export const NativeToWebMessageSchema = z.discriminatedUnion("success", [
    NativeToWebSuccessMessageSchema,
    NativeToWebErrorMessageSchema,
]);

export type NativeToWebErrorMessage = z.infer<
    typeof NativeToWebErrorMessageSchema
>;

export type NativeToWebSuccessMessage = z.infer<
    typeof NativeToWebSuccessMessageSchema
>;

export type NativeToWebMessage = z.infer<typeof NativeToWebMessageSchema>;
