import { api as clinicianMessagingApi } from "domains/concierge/internal/api/clinicianMessaging";
import { api as ticketApi } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation } from "domains/concierge/types";

type Variables = Pick<Conversation, "source" | "latestToken">;

export const useMarkAsDoneMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation<Variables>(
        "MarkAsDone",
        async (conversation) => {
            if (conversation.source.system === "Ticket") {
                const { updates } = await ticketApi.markAsDone({
                    workspaceId,
                    ticketIdentity: conversation.source.ticketIdentity,
                    latestToken: conversation.latestToken,
                });
                dispatch(
                    actions.processUpdates({
                        source: "Mutation:MarkAsDone",
                        ...updates,
                    }),
                );
            } else if (conversation.source.system === "ClinicianMessaging") {
                const { updates } = await clinicianMessagingApi.markAsDone({
                    workspaceId: workspaceId.toString(),
                    workspaceConversationId: conversation.source.serverId,
                    rowVersionBase64: conversation.source.rowVersion ?? "",
                });
                dispatch(
                    actions.processUpdates({
                        source: "Mutation:MarkAsDone",
                        ...updates,
                    }),
                );
            }
        },
        options,
    );
};
