import { useAnalytics } from "@accurx/analytics";
import { Link, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { useHistory } from "react-router-dom";

import { MAX_SMS_CHAR_LIMIT } from "../constants";

export function CharLimitErrorMessage() {
    const history = useHistory();
    const track = useAnalytics();

    return (
        <Text as="span">
            Reduce the message below {MAX_SMS_CHAR_LIMIT + 1} characters (2
            fragments) to reduce the cost for your organisation.
            {` `}
            <Link
                href={SupportUrls.IntroToFragments}
                openInNewTab
                onClick={() => {
                    track("BatchResource Link Click", {
                        origin: history.location.pathname,
                        batchResourceName: SupportUrls.IntroToFragments,
                    });
                }}
            >
                <Link.Text text="Learn about fragments" />
                <Link.Icon />
            </Link>
        </Text>
    );
}
