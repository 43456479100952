import { Patient } from "@accurx/api/portal";
import { Button, Card, Flex, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES } from "shared/Routes";

export const MessageGpPatientResult = ({
    patient,
    testPatient,
    showButton = true,
}: {
    patient: Patient;
    testPatient: boolean;
    showButton?: boolean;
}) => {
    const { displayName, nhsNumber, dateOfBirth, ageDisplay, gender } = patient;
    const params = useParams<{ workspaceId: string }>();
    const history = useHistory<{ appOrigin?: string; backTo?: string }>();

    const directToCompose = () =>
        history.push(
            generatePath(
                testPatient
                    ? ROUTES.workspaceClinicianConversationComposeTest
                    : ROUTES.workspaceClinicianConversationCompose,
                params,
            ),
            {
                // Ensure that the backTo url is retained
                ...history.location.state,
                appOrigin: "ClinicianConversations",
            },
        );
    return (
        <Card spacing={2}>
            <Flex justifyContent="space-between" alignItems="center">
                <Flex flexDirection="column" gap="0.5">
                    <Text skinny variant="label">
                        {displayName}
                    </Text>
                    <Flex gap="2">
                        {nhsNumber && <Text skinny>NHS No: {nhsNumber}</Text>}
                        {dateOfBirth && (
                            <Text skinny>
                                Born:{" "}
                                {DateHelpers.formatDate(
                                    dateOfBirth,
                                    "DD MMM YYYY",
                                )}{" "}
                                {ageDisplay}
                            </Text>
                        )}
                        {gender && <Text skinny>Gender: {gender}</Text>}
                    </Flex>
                </Flex>
                {showButton && (
                    <Button onClick={directToCompose} text="Select patient" />
                )}
            </Flex>
        </Card>
    );
};
