import { Ds } from "@accurx/design";

import { VaccineProductTypeLabel } from "../../models/VaccineRecordingDTO";

export interface BookedPatientStatusBadgesProps {
    vaccineProduct?: string | null;
    vaccineDose?: string | null;
    vaccineDoseGiven?: string | null;
}

export const BookedPatientStatusBadges = ({
    vaccineProduct,
    vaccineDose,
    vaccineDoseGiven,
}: BookedPatientStatusBadgesProps) => {
    const renderVaccineProduct = (): JSX.Element | null => {
        if (!vaccineProduct) return null;

        const mappings = [
            //Order matters when adding new items to this list
            {
                key: "astrazeneca",
                value: VaccineProductTypeLabel.AstraZeneca,
            },
            {
                key: "moderna",
                value: VaccineProductTypeLabel.Moderna,
            },
            {
                key: "pfizer paediatric",
                value: VaccineProductTypeLabel.PfizerPaediatric,
            },
            {
                key: "pfizer",
                value: VaccineProductTypeLabel.Pfizer,
            },
            {
                key: "janssen",
                value: VaccineProductTypeLabel.Janssen,
            },
            {
                key: "sanofi",
                value: VaccineProductTypeLabel.Sanofi,
            },
        ];

        let badgeText = "";
        for (const keyValuePair of mappings) {
            if (vaccineProduct.toLowerCase().includes(keyValuePair.key)) {
                badgeText = keyValuePair.value;
                break;
            }
        }
        return (
            <Ds.Badge color="greyscale" className="mr-1">
                {badgeText}
            </Ds.Badge>
        );
    };

    const renderVaccineDose = (): JSX.Element | null => {
        if (!vaccineDose && !vaccineDoseGiven) return null;

        const badgeText = vaccineDoseGiven
            ? `${vaccineDoseGiven} dose given`
            : `${vaccineDose} dose booked`;

        return (
            <Ds.Badge color="greyscale" className="mr-1">
                {badgeText}
            </Ds.Badge>
        );
    };

    return (
        <>
            {renderVaccineProduct()}
            {renderVaccineDose()}
        </>
    );
};
