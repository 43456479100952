import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
} from "./FlemingAnalyticsConstants";

/**
 * User views the pharmacy search page
 *
 * Event type: Page View
 */
export const trackPharmacySearchPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PharmacySearch",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks link to lookup info on how to find ODS code
 *
 * Event type: Link click
 */
export const trackOdsLookupInfoLinkClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "OdsLookupInfo",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks button to join a pharmacy
 *
 * Event type: Button click
 */
export const trackPharmacyJoinButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PharmacyJoin",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks 'Join' button in pharmacy join modal
 *
 * Event type: Button click
 */
export const trackPharmacyJoinConfirmButtonClick = (
    props: FlemingLoggedInCustomProperties & {
        isAdminModal: boolean;
        hasError: boolean;
    },
): void => {
    GenericTrackEvent({
        object: "PharmacyJoinConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks 'Cancel' button in pharmacy join modal
 *
 * Event type: Button click
 */
export const trackPharmacyJoinCancelButtonClick = (
    props: FlemingLoggedInCustomProperties & { isAdminModal: boolean },
): void => {
    GenericTrackEvent({
        object: "PharmacyJoinCancel",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * Modal shown to NHSPHARMACY users that are not in any pharmacy orgs
 *
 * Event type: Component View
 */
export const trackPharmacyModalJoinComponentView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PharmacyModalJoin",
        objectType: EventObjectType.Component,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks 'Join your pharmacy's workspace' button in pharmacy join modal
 *
 * Event type: Button click
 */
export const trackPharmacyModalJoinButtonClick = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PharmacyModalJoin",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
