import React, { KeyboardEvent, useEffect, useRef, useState } from "react";

import { Button, Select, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";

import { bGetBeastPatientCsvDownloadUrl } from "../../../api/BeastApi";
import { UpdatingStatus } from "../../../shared/LoadingStatus";
import { useAppSelector } from "../../../store/hooks";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { fetchBeastSeries } from "./Beast.actions";
import { SelectOption } from "./Beast.types";

export const BeastDownloadCsv = (): JSX.Element => {
    useAccurxWebTitle("Manage inhaler adherence study");

    const dispatch = useDispatch();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const beastSeries = useAppSelector(
        ({ beast }) => beast.beastSeries,
        shallowEqual,
    );
    const fetchingSeriesStatus = useAppSelector(
        ({ beast }) => beast.fetchingBeastSeriesStatus,
        shallowEqual,
    );

    const downloadCsvRef = useRef<HTMLAnchorElement>(null);

    const handleDownloadCsvKeyUp = (
        e: KeyboardEvent<HTMLAnchorElement>,
    ): void => {
        if (e.key === "Enter") {
            if (!!downloadCsvRef?.current) {
                downloadCsvRef.current.click();
            }
        }
    };

    const [seriesSelectOptions, setSeriesSelectOptions] = useState<
        SelectOption[] | undefined
    >(undefined);
    const [selectedBeastSeriesId, setSelectedBeastSeriesId] = useState<
        string | null
    >(null);

    useEffect(() => {
        if (fetchingSeriesStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(fetchBeastSeries(practiceId));
        }
        if (fetchingSeriesStatus === UpdatingStatus.Loaded) {
            setSeriesSelectOptions(
                beastSeries?.series.map((x) => ({
                    value: x.id,
                    label: x.name,
                })),
            );
        }
    }, [dispatch, fetchingSeriesStatus, practiceId, beastSeries?.series]);

    const renderBeastSeriesDropdown = () => (
        <div className="w-100 mb-3">
            <div>
                <Select
                    options={seriesSelectOptions || []}
                    defaultCopy={{ placeholder: "Please select..." }}
                    onChangeHandler={(selectedOption) => {
                        if ("value" in selectedOption) {
                            setSelectedBeastSeriesId(selectedOption.value);
                        }
                    }}
                    id="series"
                />
            </div>
        </div>
    );

    return (
        <>
            <Breadcrumb title="Inhaler Adherence Study Patients" />
            <Text variant="title" as="h1">
                Inhaler Adherence Study Patients
            </Text>
            <Text props={{ className: "mb-4" }}>
                This page will allow you to download a list of your patients
                that are enrolled in the Accurx Inhaler Adherence Study.
            </Text>
            <Text props={{ className: "mb-4" }}>
                Please select asthma or COPD below to export the list of
                enrolled patients with that condition as a CSV.
            </Text>
            {seriesSelectOptions && renderBeastSeriesDropdown()}
            {selectedBeastSeriesId && (
                <a
                    href={bGetBeastPatientCsvDownloadUrl(
                        practiceId || "",
                        selectedBeastSeriesId,
                    )}
                    download
                    target="_blank"
                    rel="noreferrer"
                    ref={downloadCsvRef}
                    onKeyUp={handleDownloadCsvKeyUp}
                    className="mr-2"
                >
                    <Button
                        icon={{ name: "Save", colour: "blue" }}
                        text="Export CSV"
                        theme="secondary"
                        // className="text-nowrap w-100"
                    />
                </a>
            )}
        </>
    );
};
