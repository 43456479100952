import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    useChangeMedicalRecordPatientMutation,
    useMedicalRecordConnection,
} from "@accurx/native";
import { useIsMutating } from "@tanstack/react-query";
import { toast } from "react-toastify";

export type OpenInEmrButtonProps = {
    patientId: string;
};

export const OpenInEmrButton = ({ patientId }: OpenInEmrButtonProps) => {
    const patient = usePatient({ patientId });
    const medicalRecordConnection = useMedicalRecordConnection();
    const { mutate: changeMedicalRecordPatient, isLoading } =
        useChangeMedicalRecordPatientMutation();

    const isMutating = useIsMutating({ mutationKey: ["changeMedicalRecord"] });

    const medicalRecordSystem = medicalRecordConnection.system;
    const shouldShowButton =
        !!patient &&
        medicalRecordConnection.capabilities?.openInEMR &&
        medicalRecordSystem;

    if (!shouldShowButton) return null;
    return (
        <Pill.Button
            aria-label={`Open this patient in ${medicalRecordSystem}.`}
            title={`Open this patient in ${medicalRecordSystem}`}
            onClick={() => {
                changeMedicalRecordPatient(
                    {
                        patientExternalIds: patient.externalIds,
                    },
                    {
                        onError: () => {
                            toast(
                                <UI.Feedback
                                    title="We had an issue loading this patient in your medical record. Please try again."
                                    colour="error"
                                />,
                            );
                        },
                    },
                );
            }}
            disabled={isLoading || isMutating > 0}
        >
            <Pill.Icon name="OpenWindow" colour="blue" isLoading={isLoading} />
            <Pill.Text>{medicalRecordSystem}</Pill.Text>
        </Pill.Button>
    );
};
