import { RadioGroup } from "@accurx/design";

import { TemplateType } from "../types";

type TemplateTypeOption = { id: string; label: string; value: TemplateType };

const radioInputs: TemplateTypeOption[] = [
    { id: "faceToFace", label: "Face-to-face", value: "FaceToFace" },
    { id: "telephone", label: "Telephone", value: "Telephone" },
];

type TemplateTypeFieldProps = {
    value: TemplateType;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export function TemplateTypeField({ value, onChange }: TemplateTypeFieldProps) {
    return (
        <RadioGroup
            formFieldWrapperProps={{ label: "Template type" }}
            name="template-type"
            onChange={onChange}
            checkedValue={value}
            theme="bordered"
            columns={2}
            radioInputs={radioInputs}
            dimension="large"
        />
    );
}
