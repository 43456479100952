import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
} from "./FlemingAnalyticsConstants";

type ClinicianConversationProps = {
    clinicianConversationId: number;
    countParticipants: number;
};

type ClinicianConversationViewSuccess = {
    conversationUrlId: string | null;
    hasError: false;
} & ClinicianConversationProps;

type ClinicianConversationViewFailure = {
    conversationUrlId: string | null;
    hasError: true;
};

type IsTestPatient = {
    isTestPatient: boolean;
};

export type ClinicianConversationViewProps = {
    btnSource?: string;
    templateSource?: string;
    appOrigin?: string;
    emailReferrer?: string;
    isMessageGpEnabled: boolean;
} & (ClinicianConversationViewSuccess | ClinicianConversationViewFailure) &
    FlemingLoggedInCustomProperties &
    IsTestPatient;

export type ClinicianConversationReplyProps = {
    hasError: boolean;
    countAttachments: number;
} & ClinicianConversationProps &
    FlemingLoggedInCustomProperties &
    IsTestPatient;

export type ClinicianConversationsViewProps = {
    folderName: string;
    pageNumber: number;
    countConversations: number;
} & FlemingLoggedInCustomProperties;

export type ClinicianConversationSummaryClickProps = {
    folderName: string;
    clinicianWorkspaceConversationId: string;
} & FlemingLoggedInCustomProperties;

export const ClinicianConversations = {
    NavigationOption: "ClinicianConversations",
};

/**
 * User viewed the clinician conversation page
 *
 * Event type: Page view
 * */
export const trackClinicianConversationPageView = (
    props: ClinicianConversationViewProps,
): void =>
    GenericTrackEvent({
        object: "ClinicianMessage",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 3 }),
    });

/**
 * User clicked the reply button on the clinician conversation page
 *
 * Event type: Button click
 * */
export const trackClinicianConversationClickReply = (
    props: ClinicianConversationReplyProps,
): void =>
    GenericTrackEvent({
        object: "ClinicianMessageReply",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });

/**
 * User clicked the back button on the message practice page
 *
 * Event type: Button click
 * */
export const trackClinicianMessageBackButtonClick = (
    props: FlemingLoggedInCustomProperties & IsTestPatient,
): void => {
    GenericTrackEvent({
        object: "ClinicianMessageBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
    });
};

/**
 * User clicked the new message button on the inbox
 *
 * Event type: Button click
 * */
export const trackClinicianConversationsNewMessageButtonClick = (
    props: FlemingLoggedInCustomProperties & {
        fromEmptyConversations: boolean;
    },
): void => {
    GenericTrackEvent({
        object: "ClinicianMessageNew",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User viewed the clinician conversations page
 *
 * Event type: Page view
 * */
export const trackClinicianConversationsPageView = (
    props: ClinicianConversationsViewProps,
): void =>
    GenericTrackEvent({
        object: "ClinicianConversations",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });

/**
 * User clicked a conversation summary on the conversation summaries list page
 *
 * Event type: List Item click
 * */
export const trackClinicianConversationSummaryClick = (
    props: ClinicianConversationSummaryClickProps,
): void => {
    GenericTrackEvent({
        object: "ClinicianConversationSummary",
        objectType: EventObjectType.ListItem,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
