import { Conversation } from "@accurx/concierge/types";
import { useInboxOptionalParams } from "domains/inbox/hooks/util/useInboxParams";

/** Returns true if the given conversation ID matches the conversationId param in the URL.
 * Otherwise, returns false.
 */
export const useIsActiveConversation = (conversationId: Conversation["id"]) => {
    const { conversationId: activeConversationId } = useInboxOptionalParams([
        "conversationId",
    ]);

    return !!activeConversationId && conversationId === activeConversationId;
};
