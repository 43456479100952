/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Thread } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { copyToClipboard } from "shared/Browser.helper";
import { PatientEmailConversationItem } from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";

export type PatientEmailThreadItemProps =
    BaseConversationThreadItem<PatientEmailConversationItem> & {
        patientFullName: string | null;
    };

export const PatientEmailThreadItem = ({
    item,
    patientFullName,
    handleOnItemRead,
    createdByDisplayName,
}: PatientEmailThreadItemProps) => {
    const dateCreated = formatConversationItemDateCreatedShort(item.createdAt);

    // TODO: any needs to be changed with the type Feedback once its exported from design lib
    const onCopyClick = async (): Promise<any | undefined> => {
        const message = generatePatientEmailMessageCopy(
            item,
            patientFullName,
            createdByDisplayName,
        );
        if (await copyToClipboard(message)) {
            return { text: "Copied" };
        }
        return { text: "Failed to copy" };
    };

    return (
        <Thread.Message
            inboxType="sent"
            deliveryMethod="Email"
            sent={item.deliveryStatus === "Sent"}
            delivered={item.deliveryStatus === "Delivered"}
            failed={item.deliveryStatus === "Failed"}
            senderFullName={{
                name: createdByDisplayName
                    ? {
                          fullName: createdByDisplayName,
                          shouldDisplayFullName: true,
                      }
                    : null,
            }}
            dateCreated={dateCreated}
            actionButtons={[
                {
                    text: "Get a copy",
                    icon: { name: "Save" },
                    providesFeedback: true,
                    onClick: onCopyClick,
                },
            ]}
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            {item.body}
        </Thread.Message>
    );
};

export const generatePatientEmailMessageCopy = (
    item: PatientEmailThreadItemProps["item"],
    patientFullName: string | null,
    createdByDisplayName: string | null | undefined,
): string => {
    const dateCreatedLong = DateHelpers.formatDate(
        item.createdAt,
        DateFormatOptions.HUMAN_READABLE_DATE_TIME,
    );

    const sentTo = patientFullName ? `Sent to:\n${patientFullName}\n\n` : "";
    const email = item.emailAddress ? `Email:\n${item.emailAddress}\n\n` : "";
    const sentBy = createdByDisplayName
        ? `Sent by:\n${createdByDisplayName}\n\n`
        : "";
    const dateTime = dateCreatedLong
        ? `Date and time:\n${dateCreatedLong}\n\n`
        : "";
    const messageText = item.body ? `Message text:\n${item.body}` : "";
    return `${sentTo}${email}${sentBy}${dateTime}${messageText}`.trim();
};

export const patientEmailThreadItemSummaryText = (
    item: PatientEmailConversationItem,
): string => item.body?.replace(/\s+/, " ") || "empty message";
