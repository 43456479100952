import { ComponentProps, useRef } from "react";

import * as UI from "@accurx/design";

export const CustomRadio = (props: ComponentProps<typeof UI.Radio>) => {
    const selectedRadioRef = useRef<HTMLInputElement>(null);

    return (
        <UI.Radio
            {...props}
            ref={props.value === props.checkedValue ? selectedRadioRef : null}
        />
    );
};
