import { RemirrorJSON } from "@remirror/core";

import { mentionAtomName, placeholderOptions } from "./constants";

const newLine = "\n";
const placeholderRegex = /%[A-Z_]*%/g;

export const textToJson: (text: string) => RemirrorJSON = (text) => ({
    type: "doc",
    content:
        text === ""
            ? [{ type: "paragraph" }]
            : text.split(newLine).map(textToParagraphJson),
});

const textToParagraphJson: (text: string) => RemirrorJSON = (text) => {
    const placeholders = [...text.matchAll(placeholderRegex)].map(([match]) =>
        createMentionAtom(match),
    );
    const otherText = text.split(placeholderRegex).map(creatTextJson);

    return {
        type: "paragraph",
        content: interleaveParagraphComponents(otherText, placeholders),
    };
};

const createMentionAtom: (text: string) => RemirrorJSON = (text) => ({
    type: "mentionAtom",
    attrs: getPlaceholderAttr(text),
});

const getPlaceholderAttr: (placeholderToken: string) => {
    id: string;
    label: string;
    name: typeof mentionAtomName;
} = (placeholderToken) => {
    const id = placeholderToken.replace(/%/g, "");

    const label = placeholderOptions.find((p) => p.id === id)?.label;

    if (label === undefined)
        throw new Error(`Unable to find placeholder ${id}`);

    return { id, label: label, name: mentionAtomName };
};

const interleaveParagraphComponents: (
    otherText: (RemirrorJSON | null)[],
    placeholders: RemirrorJSON[],
) => RemirrorJSON[] | undefined = (otherText, placeholders) => {
    const elements = otherText.flatMap((text) => {
        const nextPlaceholder = placeholders.shift();
        if (nextPlaceholder === undefined) {
            return text === null ? [] : [text];
        }
        return text === null ? [nextPlaceholder] : [text, nextPlaceholder];
    });
    return elements.length === 0 ? undefined : elements;
};

const creatTextJson: (text: string) => RemirrorJSON | null = (text) =>
    text === "" ? null : { type: "text", text };

export const jsonToText: (json: RemirrorJSON) => string = (json) => {
    switch (json.type) {
        case "doc":
        case "paragraph":
            return (
                json.content
                    ?.map((c) => jsonToText(c))
                    .join(json.type === "doc" ? newLine : " ") ?? ""
            );
        case "text":
            if (json.text) {
                return json.text.trim();
            }
            throw new Error(`No text found on ${json.type}`);
        case "mentionAtom":
            if (json.attrs) {
                return `%${json.attrs.id}%`;
            }
            throw new Error(`No attributes found on ${json.type}`);
        default:
            throw new Error(`Unable to convert type ${json.type}`);
    }
};

export const getPlaceholders: (content: string) => string[] = (content) =>
    [...content.matchAll(placeholderRegex)].map(([match]) =>
        match.replace(/%/g, ""),
    );

export const getInvalidPlaceholders: (content: string) => string[] = (
    content,
) =>
    getPlaceholders(content).filter(
        (match) => placeholderOptions.find((p) => p.id === match) === undefined,
    );
