import React, { useState } from "react";

import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { StyledModalFooter } from "./ConfirmUploadModal.styles";

type ConfirmUploadModalProps = {
    isOpen: boolean;
    handleConfirm: () => void;
    handleGoBack: () => void;
};

const ConfirmUploadModal = ({
    isOpen = false,
    handleConfirm,
    handleGoBack,
}: ConfirmUploadModalProps): JSX.Element => {
    const [disableButtons, setDisableButtons] = useState(false);

    const confirmOnClick = () => {
        setDisableButtons(true);
        handleConfirm();
    };

    return (
        <Modal
            isOpen={isOpen}
            backdrop="static"
            data-testid="upload-resource-modal"
            centered
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h3" colour="night" skinny>
                    Confirm that this is a resource for patients
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Text as="p" variant="body" colour="zinc">
                    By pressing ‘Confirm’ you will make this resource available
                    to patients in the NHS App.
                </Text>
            </ModalBody>
            <StyledModalFooter>
                <Button
                    text="Back"
                    theme="secondary"
                    onClick={handleGoBack}
                    disabled={disableButtons}
                />
                <Button
                    text="Confirm"
                    theme="primary"
                    onClick={confirmOnClick}
                    disabled={disableButtons}
                />
            </StyledModalFooter>
        </Modal>
    );
};

export { ConfirmUploadModal };
