import { FloreyResponseItem } from "../types";

export const getFloreyResponsePDFDownloadUrl = ({
    floreyResponseNote,
    workspaceId,
}: {
    floreyResponseNote: FloreyResponseItem;
    workspaceId: number;
}) => {
    return `/api/conversation/web/downloadfloreypdf/${workspaceId}/${floreyResponseNote.serverId}`;
};
