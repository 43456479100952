import { useEffect, useState } from "react";

import {
    ConversationManager,
    ConversationUserActions,
} from "shared/concierge/conversations/types/component.types";
import { Conversation } from "shared/concierge/conversations/types/conversation.types";
import {
    ErrorStatus,
    LoadingStatus,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";

export type UseConversationResult = SubscriptionResult<Conversation> & {
    actions: ConversationUserActions;
};

/**
 * Returns conversation data for the given conversatio, processes the initial data and responds to update events.
 */
export const useConversation = (
    manager: ConversationManager,
    conversationId: string | null,
): UseConversationResult => {
    const [state, setState] = useState<SubscriptionResult<Conversation>>(
        conversationId
            ? initialLoadingState
            : initialMissingConversationIdState,
    );

    useEffect(() => {
        if (conversationId) {
            const conversationSubscription = manager.getConversation({
                conversationIdentity: { id: conversationId },
            });

            conversationSubscription.feed.subscribe(setState);

            return () => {
                conversationSubscription.teardown();
            };
        }
    }, [manager, conversationId]);

    return {
        ...state,
        actions: manager.actions,
    };
};

const initialLoadingState: LoadingStatus = {
    status: "LOADING",
    data: null,
    errorMessage: null,
};

const initialMissingConversationIdState: ErrorStatus = {
    status: "ERROR",
    data: null,
    errorMessage: "Could not load the conversation you're looking for.",
};
