import * as UI from "@accurx/design";
import styled from "styled-components";

const List = styled.ul`
    display: grid;
    grid-template-rows: repeat(5, min-content);
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: ${UI.Tokens.SIZES[1]};

    list-style: none;

    margin: 0;
    padding: 0;
`;

const Wrapper = styled.div`
    border: 1px solid ${UI.Tokens.COLOURS.primary.green[100]};
    border-radius: ${UI.Tokens.SIZES[0.5]};

    background: ${UI.Tokens.COLOURS.greyscale.white};
`;

const Body = styled.div`
    padding: ${UI.Tokens.SIZES[1.5]};
`;

const Header = styled.div<{ $showFullBorder?: boolean }>`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};

    ${({ $showFullBorder }) => {
        if ($showFullBorder) {
            return `
            border-radius: ${UI.Tokens.SIZES[0.5]};
            border: 1px solid ${UI.Tokens.COLOURS.primary.green[100]};
            `;
        } else {
            return `
            border-radius: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]} 0 0;
            border-bottom: 1px solid ${UI.Tokens.COLOURS.primary.green[100]};
            `;
        }
    }}

    background: ${UI.Tokens.COLOURS.primary.green[5]};
`;

const Button = styled.button`
    display: flex;
    align-items: center;

    border: 1px solid transparent;
    border-radius: ${UI.Tokens.BORDERS.radius};
    background-color: ${UI.Tokens.COLOURS.greyscale.white};

    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};

    // Adding box shadow so we can avoid setting fixed height
    box-shadow: inset 0 -2px 0 ${UI.Tokens.COLOURS.greyscale.silver},
        inset 0 0 0 1px ${UI.Tokens.COLOURS.greyscale.silver};

    width: 100%;
    &:hover {
        background: ${UI.Tokens.COLOURS.primary.blue[5]};
        border: 1px solid ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    &:not(:disabled):focus,
    &:not(:disabled):active {
        ${UI.focusBorderStyles};
        background: ${UI.Tokens.COLOURS.primary.blue[5]};
    }

    &:disabled {
        color: ${UI.Tokens.COLOURS.greyscale.stone};
        background: ${UI.Tokens.COLOURS.greyscale.cloud};
        border-color: transparent;
    }
`;

const Link = styled(UI.Link)`
    font-size: ${UI.Tokens.TYPOGRAPHY.note.desktop.fontSize};
`;

export const StyledOutcomeRecording = {
    Wrapper,
    Body,
    Header,
    Button,
    List,
    Link,
};
