import React from "react";

import { Link, Text } from "@accurx/design";

export const AttachmentLink = ({
    url,
}: {
    url: string | null;
}): JSX.Element => {
    if (!url) {
        return <Text skinny>Attachment URL not found</Text>;
    }

    return <Link href={url} openInNewTab text={url} />;
};
