import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledReferenceContainer = styled.section`
    width: 100%;
    border: ${UI.Tokens.BORDERS.normal.silver};
    border-radius: ${UI.Tokens.BORDERS.radius};
    padding: ${UI.Tokens.SIZES[1]};
`;

export const StyledReferenceTitle = styled.h3`
    font-weight: 700;
    font-size: 0.875rem;
    margin-bottom: ${UI.Tokens.SIZES[1]};
    padding: 0;
`;

export const StyledReferenceLink = styled(UI.Link)`
    font-size: 0.875rem;
`;
