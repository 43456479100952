import React, { useState } from "react";

import {
    FormFieldWrapper,
    Input,
    Option as SelectOption,
    StackPanel,
    Text,
} from "@accurx/design";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { NO_SNOMED_CODE_OPTION } from "../FloreyBuilder.utils";
import { BranchPosition, ErrorIds, QuestionLevel } from "../types/data.types";
import {
    CheckboxSVG,
    RadioButtonSVG,
    StyledBulletIconEdit,
    StyledBulletText,
    StyledBulletWrapper,
} from "./Question.styles";
import { SnomedSelectFormField } from "./SnomedSelectFormField";

type QuestionOptionEditProps = {
    handleOptionFieldUpdate: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        e: any, //React.ChangeEvent<HTMLInputElement>,
        questionIndex: number,
        optionIndex: number,
        fieldKey: "text", // allows support for extending to additional field keys
        fieldSetter: React.Dispatch<string | undefined>,
        questionLevel: QuestionLevel,
        branchPosition?: BranchPosition,
    ) => void;
    handleSelectSnomed: (selected: SelectOption | SelectOption[]) => void;
    key?: string;
    optionIndex: number;
    optionText: string | undefined;
    optionTextError: string | null;
    questionIndex: number;
    selectedSnomed: SelectOption | undefined;
    snomedOptions: SelectOption[];
    questionnaireId: number | undefined;
    allowMultipleAnswers: boolean;
    questionLevel: QuestionLevel;
    branchPosition?: BranchPosition;
};

const QuestionOptionEdit = ({
    handleOptionFieldUpdate,
    handleSelectSnomed,
    optionIndex,
    optionText,
    optionTextError = null,
    questionIndex,
    selectedSnomed,
    snomedOptions,
    questionnaireId,
    allowMultipleAnswers,
    questionLevel,
    branchPosition,
}: QuestionOptionEditProps): JSX.Element => {
    const [answer, setAnswer] = useState(optionText);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    return (
        <>
            <Text variant="note" skinny as="h3">{`OPTION ${
                optionIndex + 1
            }`}</Text>
            <StyledBulletWrapper>
                <StyledBulletIconEdit>
                    {allowMultipleAnswers ? (
                        <CheckboxSVG />
                    ) : (
                        <RadioButtonSVG />
                    )}
                </StyledBulletIconEdit>
                <StyledBulletText>
                    <StackPanel gutter={1.5}>
                        <FormFieldWrapper
                            label="Answer"
                            labelProps={{
                                htmlFor:
                                    ErrorIds.questionMultipleChoiceOptionAnswer +
                                    optionIndex,
                            }}
                            errors={[optionTextError]}
                        >
                            <Text skinny>
                                This will be one of the options that the patient
                                can select.
                            </Text>
                            <Input
                                id={
                                    ErrorIds.questionMultipleChoiceOptionAnswer +
                                    optionIndex
                                }
                                value={answer}
                                onChange={(e) =>
                                    handleOptionFieldUpdate(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "text",
                                        setAnswer,
                                        questionLevel,
                                        branchPosition,
                                    )
                                }
                            />
                        </FormFieldWrapper>
                        <SnomedSelectFormField
                            selectedOption={
                                selectedSnomed || NO_SNOMED_CODE_OPTION
                            }
                            options={snomedOptions}
                            onChange={handleSelectSnomed}
                            formFieldProps={{
                                labelProps: { htmlFor: "fb-select-snomed" },
                            }}
                            selectProps={{
                                id: "fb-select-snomed",
                                zIndex: 100 - optionIndex, // to ensure that first rendered one has a higher z-index than the next one so there won't be overlapping
                                expandCallback: () => {
                                    FlemingAnalyticsTracker.trackQuestionnaireSnomedSearch(
                                        {
                                            ...analyticsLoggedInProps,
                                            questionnaireId:
                                                questionnaireId ?? null,
                                            pageOrigin:
                                                "QuestionnaireNameAndQuestions",
                                        },
                                    );
                                },
                            }}
                        />
                    </StackPanel>
                </StyledBulletText>
            </StyledBulletWrapper>
        </>
    );
};

export default QuestionOptionEdit;
