import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledListItem = styled.li`
    padding: ${UI.Tokens.SIZES[1]};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: ${UI.Tokens.SIZES[1]};
    border: ${UI.Tokens.BORDERS.normal.silver};
    border-radius: ${UI.Tokens.BORDERS.radius};
`;

export const StyledDeleteButton = styled(UI.Button).attrs({
    theme: "secondary",
    dimension: "medium",
    icon: {
        name: "Bin",
        colour: "red",
        title: "Delete",
        id: "secondary-delete",
    },
})`
    color: ${UI.Tokens.COLOURS.primary.red[100]};
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1.5]};
`;

export const StyledPopoverContent = styled(UI.PopoverContent)`
    padding: 0px;
    font-size: 14px;
`;

export const StyledPopoverItemButton = styled(UI.PopoverItemButton)`
    text-align: left;
    margin: 0;
`;

export const StyledPopoverHeader = styled(UI.PopoverItem)`
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px;
`;
