import { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { generatePath } from "react-router-dom";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { actionCreators as accountActions } from "app/account/AccountActions";
import { ROUTES, ROUTES_PRIMARY } from "shared/Routes";
import { useAppSelector, useCurrentOrgId, useCurrentUserId } from "store/hooks";

export type useCheckUrlWithReduxUpdateOrRedirectResult = {
    redirect: string | undefined;
};

/**
 * Checks the workspace id URL against the current id according to redux, and all valid ids
 * If the workspace id does not match the current id from Redux, but the workspace id is valid
 * then update redux to switch to that workspace.
 * If the workspace id is not valid returns a location to redirect the page to.
 */
export const useCheckUrlWithReduxUpdateOrRedirect = (
    workspaceId: number,
): useCheckUrlWithReduxUpdateOrRedirectResult => {
    // Get the workspace/org id from Redux, and check redux to see whether the workspace from the URL
    // is one that the user is a member of
    const organisationId = useCurrentOrgId();
    const userId = useCurrentUserId();
    const workspaceFromUrl = useAppSelector(({ account }) =>
        account.user?.organisations.find((x) => x.orgId === workspaceId),
    );
    const { setSelectedOrgId } = useSelectedOrganisation();
    // State that will be used to determine if the URL is a valid one, and if not to redirect away
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string | undefined>(undefined);

    const { pathname } = useLocation();
    const pathRef = useRef(pathname);

    useEffect(() => {
        if (!workspaceId) {
            return setRedirect(ROUTES.home);
        }
        if (workspaceId === organisationId) {
            // Redux and URL are in sync, no redirect required
            return setRedirect(undefined);
        }
        if (!workspaceFromUrl) {
            // The workspace from the URL isn't a valid workspace for the user, so we
            // have to redirect away
            if (organisationId) {
                // If we have a workspace from Redux, go to the given path for this workspace
                const newPath = pathRef.current.replace(
                    generatePath(ROUTES_PRIMARY.workspaceBase, { workspaceId }),
                    generatePath(ROUTES_PRIMARY.workspaceBase, {
                        workspaceId: organisationId,
                    }),
                );
                return setRedirect(newPath);
            }
            // Otherwise nowhere else to go so redirect away
            return setRedirect(ROUTES.home);
        }
        // The workspace from the URL was valid, we treat the URL as the source of truth
        // so we'll switch the context of the app to this workspace
        dispatch(accountActions.selectOrganisation(userId, workspaceId));
        setSelectedOrgId(workspaceId);
        // Note: Avoid adding 'organisationId' as a dependency because it will
        // get overwritten with the current 'workspaceId' value anyway by the
        // Redux action
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspaceFromUrl, workspaceId, userId]);

    return { redirect };
};
