import { Actions } from "app/practices/Practices.actions";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    KnownAction,
    POST_APPROVAL_REQUEST_UPDATE,
    POST_APPROVAL_REQUEST_UPDATE_FAILURE,
    POST_APPROVAL_REQUEST_UPDATE_SUCCESS,
    POST_LEAVE_SITE_REQUEST,
    POST_LEAVE_SITE_REQUEST_FAILURE,
    POST_LEAVE_SITE_REQUEST_SUCCESS,
    VACCINE_SITE_LINKS,
    VACCINE_SITE_LINKS_FAILURE,
    VACCINE_SITE_LINKS_SUCCESS,
} from "./ApprovalRequests.actions";
import { VaccinePracticeLinks } from "./ApprovalRequests.types";

// -----------------
// STATE Interface - This defines the type of data maintained in the Redux store.
// -----------------
export interface VaccineApprovalRequestReducerState {
    vaccineSiteLinks: VaccinePracticeLinks | null;

    vaccineSitesLoading: LoadingStatus;
    vaccineSitesFetchError: string;

    approvalRequestsUpdating: boolean;
    approvalRequestUpdateError: string;

    leaveRequestUpdating: boolean;
    leaveRequestError: string;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: VaccineApprovalRequestReducerState = {
    vaccineSiteLinks: null,

    vaccineSitesLoading: LoadingStatus.Initial,
    vaccineSitesFetchError: "",

    approvalRequestsUpdating: false,
    approvalRequestUpdateError: "",

    leaveRequestUpdating: false,
    leaveRequestError: "",
};

export const reducer = (
    state: VaccineApprovalRequestReducerState = initialState,
    action: KnownAction,
): VaccineApprovalRequestReducerState => {
    switch (action.type) {
        case VACCINE_SITE_LINKS:
            return {
                ...state,
                vaccineSitesLoading: LoadingStatus.Loading,
                vaccineSitesFetchError: "",
            };
        case VACCINE_SITE_LINKS_SUCCESS:
            return {
                ...state,
                vaccineSitesLoading: LoadingStatus.Loaded,
                vaccineSiteLinks: action.vaccineSiteLinks,
            };
        case VACCINE_SITE_LINKS_FAILURE:
            return {
                ...state,
                vaccineSitesLoading: LoadingStatus.Failed,
                vaccineSitesFetchError: action.error,
            };
        case POST_APPROVAL_REQUEST_UPDATE:
            return {
                ...state,
                approvalRequestsUpdating: true,
                approvalRequestUpdateError: "",
            };
        case POST_APPROVAL_REQUEST_UPDATE_SUCCESS:
            return {
                ...state,
                approvalRequestsUpdating: false,
                approvalRequestUpdateError: "",
                vaccineSiteLinks: action.vaccineSiteLinks,
            };
        case POST_APPROVAL_REQUEST_UPDATE_FAILURE:
            return {
                ...state,
                approvalRequestsUpdating: false,
                approvalRequestUpdateError: action.error,
            };
        case POST_LEAVE_SITE_REQUEST:
            return {
                ...state,
                leaveRequestUpdating: true,
                leaveRequestError: "",
            };
        case POST_LEAVE_SITE_REQUEST_SUCCESS:
            return {
                ...state,
                leaveRequestUpdating: false,
                leaveRequestError: "",
                vaccineSiteLinks: action.vaccineSiteLinks,
            };
        case POST_LEAVE_SITE_REQUEST_FAILURE:
            return {
                ...state,
                leaveRequestUpdating: false,
                leaveRequestError: action.error,
            };
        case Actions.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        default:
            return state;
    }
};
