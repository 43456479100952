import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledOuterContainer = styled.div`
    padding: 0 ${UI.Tokens.SIZES[3]} ${UI.Tokens.SIZES[3]};
    position: relative; // create a new stacking context so that the top box-shadow remains visible
`;

export const StyledInnerContainer = styled.div`
    display: flex;
    gap: ${UI.Tokens.SIZES[1]};

    background: ${UI.Tokens.COLOURS.greyscale.white};
    border-radius: ${UI.Tokens.BORDERS.radius};
    padding: ${UI.Tokens.SIZES[0.5]};
    border: ${UI.Tokens.BORDERS.normal.silver};
    box-shadow: 0px ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[2]}
        rgba(67.83, 72.3, 125.97, 0.32);
`;

export const StyledLoadingInnerContainer = styled(StyledInnerContainer)`
    align-items: center;
`;

export const StyledMessageActionsButton = styled(UI.Button)`
    width: 100%;

    span {
        font-size: 0.875rem;
    }

    :hover {
        color: ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    // Slightly annoyingly, the UI.Button component uses :before to add a dark
    // outline to the focus/active state of the button. It's not nice having to
    // do this but lets hide the :before element so we can apply our own focus
    // colour.
    :before {
        display: none;
    }

    :focus,
    :active {
        box-shadow: 0px 0px 0px 3px ${UI.Tokens.COLOURS.primary.blue[50]},
            inset 0 0 0 1px ${UI.Tokens.COLOURS.primary.blue[130]};
    }
`;

export const StyledPaddedSkeleton = styled.div`
    width: 100%;
    padding: ${UI.Tokens.SIZES[1]} 0;
`;

export const StyledDoneConversationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[0.5]}
        ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1.5]};
`;
