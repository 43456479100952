import { ItemType } from "@accurx/api/clinician-messaging";
import { usePatient } from "domains/concierge/hooks/data/usePatient";
import { api as clinicianMessagingApi } from "domains/concierge/internal/api/clinicianMessaging";
import {
    mapConversationItemIdToClinicianMessagingItemIdentity,
    partitionClinicianMessagingItemIdentitiesByType,
} from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationItemIdToClinicianMessagingItemIdentity";
import { api as ticketApi } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import {
    Conversation,
    ConversationItem,
    PatientSummary,
} from "domains/concierge/types";

type HookVariables = {
    patientId: PatientSummary["patientId"] | null | undefined;
    conversation: Conversation;
};

type MutationVariables = {
    itemIds: ConversationItem["id"][];
};

export const useMarkItemsAsReadMutation = (
    { patientId, conversation }: HookVariables,
    options?: UseMutationOptions<MutationVariables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();
    const patient = usePatient({ patientId });

    return useMutation<MutationVariables>(
        "MarkItemsAsRead",
        async ({ itemIds }) => {
            switch (conversation.source.system) {
                case "Ticket": {
                    const { updates } = await ticketApi.markNotesAsRead({
                        workspaceId,
                        itemIds,
                        patientExternalIds: patient?.externalIds,
                    });

                    dispatch(
                        actions.processUpdates({
                            source: "Mutation:MarkItemsAsRead",
                            ...updates,
                        }),
                    );
                    break;
                }
                case "ClinicianMessaging": {
                    const itemIdentities =
                        partitionClinicianMessagingItemIdentitiesByType(
                            itemIds
                                .map(
                                    mapConversationItemIdToClinicianMessagingItemIdentity,
                                )
                                .filter(isInstance),
                        );

                    const { updates } =
                        await clinicianMessagingApi.markItemsAsRead({
                            workspaceId: workspaceId.toString(),
                            workspaceConversationId:
                                conversation.source.serverId,
                            outgoingMessageIds:
                                itemIdentities[ItemType.OutgoingMessage],
                            incomingMessageIds:
                                itemIdentities[ItemType.IncomingMessage],
                            eventIds: itemIdentities[ItemType.AssignedEvent],
                        });

                    dispatch(
                        actions.processUpdates({
                            source: "Mutation:MarkItemsAsRead",
                            ...updates,
                        }),
                    );
                }
            }
        },
        options,
    );
};
