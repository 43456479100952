import {
    Conversation,
    ConversationItem as ConversationItemType,
} from "@accurx/concierge/types";
import { ClinicianMessage } from "domains/inbox/components/ConversationItem/itemTypes/ClinicianMessage/ClinicianMessage";
import { FailedDeliveryReceiptLinkNote } from "domains/inbox/components/ConversationItem/itemTypes/FailedDeliveryReceiptLinkNote/FailedDeliveryReceiptLinkNote";
import { FloreyResponseNote } from "domains/inbox/components/ConversationItem/itemTypes/FloreyResponseNote/FloreyResponseNote";
import { LabelTagNote } from "domains/inbox/components/ConversationItem/itemTypes/LabelTagNote/LabelTagNote";
import { NhsAppMessage } from "domains/inbox/components/ConversationItem/itemTypes/NhsAppMessage/NhsAppMessage";
import { NoteMessage } from "domains/inbox/components/ConversationItem/itemTypes/NoteMessage/NoteMessage";
import { PatientAppointmentRequestNote } from "domains/inbox/components/ConversationItem/itemTypes/PatientAppointmentRequestNote/PatientAppointmentRequestNote";
import { PatientEmailMessage } from "domains/inbox/components/ConversationItem/itemTypes/PatientEmailMessage/PatientEmailMessage";
import { PatientSingleResponse } from "domains/inbox/components/ConversationItem/itemTypes/PatientSingleResponse/PatientSingleResponse";
import { PatientSmsMessage } from "domains/inbox/components/ConversationItem/itemTypes/PatientSmsMessage/PatientSmsMessage";
import { PatientTriageRequestNote } from "domains/inbox/components/ConversationItem/itemTypes/PatientTriageRequestNote/PatientTriageRequestNote";
import { SelfBookNoteMessage } from "domains/inbox/components/ConversationItem/itemTypes/SelfBookNoteMessage/SelfBookNoteMessage";
import { StateChangeEvent } from "domains/inbox/components/ConversationItem/itemTypes/StateChangeEvent/StateChangeEvent";

type MessageProps = {
    item: ConversationItemType;
    conversation: Conversation;
};

export const ConversationItem = ({ item, conversation }: MessageProps) => {
    switch (item.contentType) {
        case "FailedDeliveryReceiptLink":
            return (
                <FailedDeliveryReceiptLinkNote
                    item={item}
                    conversation={conversation}
                />
            );

        case "FloreyResponseNote":
            return (
                <FloreyResponseNote item={item} conversation={conversation} />
            );

        case "Note":
            return <NoteMessage item={item} conversation={conversation} />;

        case "PatientEmail":
            return (
                <PatientEmailMessage item={item} conversation={conversation} />
            );

        case "PatientSms":
            return (
                <PatientSmsMessage item={item} conversation={conversation} />
            );

        case "NhsAppMessage":
            return <NhsAppMessage item={item} conversation={conversation} />;

        case "PatientSingleResponse":
            return (
                <PatientSingleResponse
                    item={item}
                    conversation={conversation}
                />
            );

        case "PatientTriageRequestNote":
            return (
                <PatientTriageRequestNote
                    item={item}
                    conversation={conversation}
                />
            );

        case "SelfBookNote":
            return (
                <SelfBookNoteMessage item={item} conversation={conversation} />
            );

        case "StateChange":
            return <StateChangeEvent item={item} conversation={conversation} />;

        case "LabelTag":
            return <LabelTagNote item={item} conversation={conversation} />;

        case "PatientAppointmentRequestNote":
            return (
                <PatientAppointmentRequestNote
                    item={item}
                    conversation={conversation}
                />
            );

        case "ClinicianMessage":
            return <ClinicianMessage item={item} conversation={conversation} />;

        case "VideoConsultLink":
            // Video consult links are rendered as part of the message item that
            // they link to
            return <></>;
    }
};
