import React from "react";

import { Card, Feedback, Text } from "@accurx/design";

import { UserType } from "./PracticeUsers.types";
import { UserRow } from "./UserRow";

type UsersTableProps = {
    tableTitle: string;
    introduction: string;
    users: UserType[];
};

export const UsersTable = ({
    tableTitle,
    introduction,
    users,
}: UsersTableProps): JSX.Element => {
    return (
        <div className="mb-5">
            <Text variant="subtitle" as="h2">
                {tableTitle}
            </Text>
            <Text>{introduction}</Text>
            {users.length === 0 ? (
                <Feedback title={`No ${tableTitle}`} />
            ) : (
                <Card spacing={0}>
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <Text as="th" variant="label">
                                    User
                                </Text>
                                <Text as="th" variant="label">
                                    Role
                                </Text>
                                <Text as="th" variant="label">
                                    Status
                                </Text>
                                <Text as="th" variant="label">
                                    Actions
                                </Text>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((u: UserType) => (
                                <UserRow
                                    key={`${tableTitle}${u.userId}`}
                                    user={u}
                                />
                            ))}
                        </tbody>
                    </table>
                </Card>
            )}
        </div>
    );
};
