import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { StatusChange } from "@accurx/api/florey-builder";

import { editQuestionnaireStatus } from "api/FloreyBuilder/FloreyBuilderApi";

import { QUESTIONNAIRE_SUMMARIES_CACHE_KEY } from "../queries/useQuestionnaireSummariesQuery";

type QuestionnaireStatusMutationVariables = {
    workspaceId: number;
    questionnaireId: number;
    status: StatusChange;
    questionnaireRowVersion: string;
    isGloballyAccessible: Optional<boolean>;
};

export const useQuestionnaireStatusMutation =
    createMutationHook<QuestionnaireStatusMutationVariables>(
        ({ queryClient }) => ({
            mutationKey: ["QuestionnaireStatus"],
            mutationFn: async ({
                workspaceId,
                questionnaireId,
                status,
                questionnaireRowVersion,
                isGloballyAccessible,
            }) => {
                const response = await editQuestionnaireStatus(
                    workspaceId.toString(),
                    {
                        questionnaireId,
                        organisationId: workspaceId,
                        newStatus: status,
                        questionnaireRowVersion,
                        isGloballyAccessible,
                    },
                );
                return returnDataOrThrow(response);
            },
            onSuccess(data, { workspaceId }) {
                queryClient.setQueryData(
                    [QUESTIONNAIRE_SUMMARIES_CACHE_KEY, { workspaceId }],
                    data,
                );
            },
        }),
    );
