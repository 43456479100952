import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import {
    Card,
    Feedback,
    FormFieldWrapper,
    Input,
    Option,
    RadioGroup,
    Select,
    Switch,
    Text,
} from "@accurx/design";
import moment from "moment";
import { shallowEqual, useDispatch } from "react-redux";

import {
    updateSendDateTime,
    updateStaggerOverDays,
} from "app/batchMessage/gp/BatchMessage.actions";
import { DateInput } from "app/sharedComponents/dateInput";
import { useAppSelector } from "store/hooks";

import { getWorkingHoursOptions } from "../../../shared/getWorkingHoursOptions";
import { ScheduleDaysWrapper } from "./BatchMessageSchedule.styles";
import { DefaultStaggeredDays, SendOptions } from "./scheduleBatch.constants";
import {
    ScheduleSendFormValidation,
    StaggeredDaysValidation,
} from "./scheduleBatch.validation";

type BatchMessageScheduleFormProps = {
    setScheduleMessageValid: (value: boolean) => void;
};

export const BatchMessageScheduleForm = ({
    setScheduleMessageValid,
}: BatchMessageScheduleFormProps) => {
    const dispatch = useDispatch();

    // Store
    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );
    const scheduleSendFormDataDefaults = useAppSelector(
        ({ batchMessage }) => batchMessage.scheduleSendFormDataDefaults,
        shallowEqual,
    );
    const staggerOverDays = useAppSelector(
        ({ batchMessage }) => batchMessage.staggerOverDays,
    );
    const sendAt = useAppSelector(
        ({ batchMessage }) => batchMessage.sendAt,
        shallowEqual,
    );

    // State
    const [sendOptionsValue, setSendOptionsValue] = useState<SendOptions>(
        SendOptions.SEND_NOW,
    );
    const [selectedSendDate, setSelectedSendDate] = useState<
        string | undefined
    >(scheduleSendFormDataDefaults?.date || moment().format("YYYY-MM-DD"));
    const [selectedSendTime, setSelectedSendTime] = useState<
        Option | undefined
    >(scheduleSendFormDataDefaults?.time);
    const [staggeredSending, setStaggeredSending] = useState(
        staggerOverDays || false,
    );
    const [staggeredDays, setStaggeredDays] = useState(
        staggerOverDays || DefaultStaggeredDays,
    );
    const [staggeredDaysError, setStaggeredDaysError] = useState<string[]>([]);

    // Date fallback properties
    // These properties are used for browsers that do not support input type date
    // Check if Browser supports date inputs
    // TODO: remove eslint-disabled
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateFallbackError, setDateFallbackError] = useState(false);
    // TODO: remove eslint-disabled
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isSelectedDayWeekend, setIsSelectedDayWeekend] = useState(false);

    const numOfTextablePatients = patientList.filter(
        (item: BatchMessageItemSummary) => item.isTextable,
    ).length;

    useEffect(() => {
        if (sendAt) {
            setSendOptionsValue(SendOptions.SEND_LATER);
        }
        // FIXME!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Form Validation
    useEffect(() => {
        setScheduleMessageValid(
            ScheduleSendFormValidation({
                errors: staggeredDaysError,
                sendOption: sendOptionsValue,
                selectedDate: selectedSendDate as string,
                selectedTime: selectedSendTime?.value as string,
                selectedDateFallBackError: dateFallbackError,
            }),
        );
        // FIXME!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sendOptionsValue,
        staggeredDaysError,
        staggeredSending,
        staggeredDays,
        selectedSendDate,
        selectedSendTime,
        dateFallbackError,
    ]);

    const sendTimeOptions = useMemo((): Option[] => {
        const today = moment().format("YYYY-MM-DD");
        const isToday = moment(selectedSendDate).isSame(today);

        if (isToday) {
            const currentHour = moment().hour();
            return getWorkingHoursOptions(currentHour);
        } else {
            return getWorkingHoursOptions();
        }
    }, [selectedSendDate]);

    const handleCheckedChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSendOptionsValue(e.target.value as SendOptions);

        if (e.target.value === SendOptions.SEND_NOW) {
            dispatch(updateSendDateTime(null));
        }
    };

    const handleSendTime = (selected: Option) => {
        setSelectedSendTime(selected);

        if (selectedSendDate !== null || selected.value !== null) {
            const m = moment(selectedSendDate, ["YYYY-M-D", "YYYY-MM-DD"]);
            const d = m.toObject();

            dispatch(
                updateSendDateTime({
                    formattedDate: m.format("YYYY-MM-DD"),
                    formattedTime: selected,
                    sendAt: {
                        day: d.date.toString(),
                        month: (d.months + 1).toString(),
                        year: d.years.toString(),
                        hour: selected.value,
                    },
                }),
            );
        } else {
            dispatch(updateSendDateTime(null));
        }
    };

    const handleSendDate = (selectedDate: string) => {
        setSelectedSendDate(selectedDate);
    };

    const handleStaggeredSending = (e: ChangeEvent<HTMLInputElement>) => {
        setStaggeredDays(DefaultStaggeredDays);
        setStaggeredDaysError([]);
        setStaggeredSending(e.target.checked);
        dispatch(
            updateStaggerOverDays(
                e.target.checked ? DefaultStaggeredDays : null,
            ),
        );
    };

    const handleStaggeredDays = (e: ChangeEvent<HTMLInputElement>) => {
        setStaggeredDaysError([]);
        setStaggeredDays(e.target.value ? parseInt(e.target.value, 10) : 0);

        dispatch(
            updateStaggerOverDays(
                e.target.value ? parseInt(e.target.value, 10) : null,
            ),
        );
    };

    const handleBlurStaggeredDays = (e: ChangeEvent<HTMLInputElement>) => {
        const validate = StaggeredDaysValidation({
            staggeredDays: parseInt(e.target.value, 10),
            patients: numOfTextablePatients,
        });
        setStaggeredDaysError(validate);
    };

    const calculateMessagesPerDay = useMemo(() => {
        return Math.floor(numOfTextablePatients / staggeredDays);
        // FIXME!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staggeredDays, staggeredSending]);

    return (
        <form
            className="col-12 col-lg-12 px-0"
            onSubmit={(e) => e.preventDefault()}
        >
            <Card
                isElevated={false}
                spacing={1.5}
                header={
                    <>
                        <Text as="h2" variant="subtitle" skinny>
                            Send time
                        </Text>
                        <Text>
                            Choose to send the message now or schedule for a
                            later time.
                        </Text>
                    </>
                }
            >
                <div className="row">
                    <div className="col-12">
                        <RadioGroup
                            formFieldWrapperProps={{
                                label: "Send time",
                                labelProps: { className: "sr-only" },
                            }}
                            name="bordered-columned-radio"
                            onChange={handleCheckedChange}
                            checkedValue={sendOptionsValue}
                            theme="bordered"
                            columns={2}
                            radioInputs={[
                                {
                                    id: "now",
                                    label: SendOptions.SEND_NOW,
                                    value: SendOptions.SEND_NOW,
                                },
                                {
                                    id: "later",
                                    label: SendOptions.SEND_LATER,
                                    value: SendOptions.SEND_LATER,
                                },
                            ]}
                        />
                    </div>
                </div>

                {sendOptionsValue === SendOptions.SEND_LATER && (
                    <div className="row mt-4">
                        <div className="col-lg-6 pr-lg-2">
                            <Text as="label" variant="label" className="mb-2">
                                Date
                            </Text>

                            <DateInput
                                handleSendDate={handleSendDate}
                                defaultDate={selectedSendDate as string}
                            />
                        </div>
                        <div className="col-lg-6 pl-lg-1 mt-3 mt-lg-0">
                            <Text as="label" variant="label" className="mb-2">
                                Time
                            </Text>
                            <Select
                                options={sendTimeOptions}
                                id="send_times"
                                onChangeHandler={(selected) =>
                                    handleSendTime(selected as Option)
                                }
                                initSelectedValues={selectedSendTime}
                            />
                        </div>
                    </div>
                )}

                {isSelectedDayWeekend && (
                    <Feedback
                        colour="information"
                        props={{ className: "mt-4" }}
                    >
                        <Text skinny>
                            Avoid sending on a non-working day in case patients
                            try to contact.
                        </Text>
                    </Feedback>
                )}
            </Card>
            <div className="mt-4">
                <Card
                    isElevated={false}
                    spacing={1.5}
                    header={
                        <>
                            <Text as="h2" variant="subtitle" skinny>
                                Staggered sending
                            </Text>
                            <Text className="mb-1 mt-1">
                                Send a message in smaller groups with a time
                                delay rather than to all your patients at once.
                            </Text>
                        </>
                    }
                >
                    {numOfTextablePatients > 1 && (
                        <div className="row">
                            <div className="col-12">
                                <Switch
                                    colour="green"
                                    labelText="Staggered sending"
                                    labelVariant="body"
                                    size="small"
                                    switchPosition="left"
                                    onChange={handleStaggeredSending}
                                    defaultChecked={staggeredSending as boolean}
                                />
                            </div>
                        </div>
                    )}
                    {staggeredSending && (
                        <div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <Text variant="label">
                                        Number of working days
                                    </Text>
                                    <Text>
                                        The number of working days to stagger
                                        the sending over. The sending will start
                                        from your send date and time.
                                    </Text>

                                    <FormFieldWrapper
                                        as="div"
                                        errors={staggeredDaysError}
                                        label="Number of working days"
                                        labelProps={{
                                            htmlFor: "schedule-days-input",
                                            className: "sr-only",
                                        }}
                                    >
                                        <ScheduleDaysWrapper className="d-flex align-items-center">
                                            <Input
                                                dimension="medium"
                                                onChange={handleStaggeredDays}
                                                onBlur={handleBlurStaggeredDays}
                                                defaultValue={staggeredDays}
                                                className="d-inline"
                                                outerClassName="schedule-days-input"
                                                id="schedule-days-input"
                                                type="number"
                                                max={numOfTextablePatients}
                                                min="2"
                                            />
                                            <Text
                                                as="span"
                                                skinny
                                                className="ml-2"
                                            >
                                                days&nbsp;
                                            </Text>
                                            {staggeredDays >= 2 &&
                                                staggeredDays <= 365 && (
                                                    <Text
                                                        skinny
                                                        colour="metal"
                                                        as="span"
                                                    >
                                                        (about{" "}
                                                        <b>
                                                            {
                                                                calculateMessagesPerDay
                                                            }
                                                        </b>{" "}
                                                        patients a day)
                                                    </Text>
                                                )}
                                        </ScheduleDaysWrapper>
                                    </FormFieldWrapper>
                                </div>
                            </div>
                        </div>
                    )}
                </Card>
            </div>
        </form>
    );
};
