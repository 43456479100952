import React, { ReactNode, useMemo, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Feedback, StackPanel, Text } from "@accurx/design";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import {
    generatePath,
    useHistory,
    useParams,
    useRouteMatch,
} from "react-router";
import { useAnalytics } from "reduxQuarantine/useAnalytics";

import { FlemingAnalyticsTracker } from "app/analytics";
import { LinkVariant, NavLink } from "app/layout/navigationMenu/navLink";
import { AutoScroll } from "app/sharedComponents/AutoScroll";
import { SkeletonBody } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { ROUTES_PRIMARY, ROUTES_WORKSPACE } from "shared/Routes";
import {
    useConversationManager,
    useOptionalConversationManager,
} from "shared/concierge/conversations/context/ConversationManagerContext";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { useConversationGroupSummary } from "shared/concierge/conversations/hooks/useConversationGroupSummary";
import { ConversationGroupRuleset } from "shared/concierge/conversations/types/conversationGroup.types";
import {
    useAllColleaguesQuery,
    useSingleUserQuery,
} from "shared/concierge/usersAndTeams/hooks";
import {
    TeamSummary,
    UserSummary,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";
import UserSettingsService from "shared/storage/UserSettingsService";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { OrganisationHelper } from "../../../../shared/OrganisationHelper";
import { RenderBadgeNavLinkArgs } from "../navLink/NavLink";
import { CollapsibleMenu } from "./CollapsibleMenu/CollapsibleMenu";
import { SecondaryNavigationHeader } from "./SecondaryNavigation";
import { StyledContainer } from "./SecondaryNavigation.styles";
import { SimplifiedNavigation } from "./SimplifiedNavigation";
import {
    StyledScrollableUnstyledList,
    StyledUnstyledList,
} from "./WorkspaceConversationNavigation.styles";
import {
    WorkspaceConversationUnreadCountBadge,
    getUnreadCountBadgeDisplayValue,
} from "./WorkspaceConversationUnreadCountBadge";

const USERS_TEAMS_SORT_FN = (
    a: UserSummary | TeamSummary,
    b: UserSummary | TeamSummary,
) => a.displayName.localeCompare(b.displayName);

type WorkspaceConversationsNavigationProps = {
    onClick: () => void;
};

export const WorkspaceConversationsNavigation = (
    props: WorkspaceConversationsNavigationProps,
): JSX.Element | null => {
    const matchWithTwoFactor = useRouteMatchOrTwoFactorPrompt({
        path: ROUTES_PRIMARY.workspaceConversations,
    });

    const manager = useOptionalConversationManager();

    if (!matchWithTwoFactor) {
        return null;
    }
    if (!manager) {
        // Before the conversation manager is initialised (including before 2FA) we return
        // a simplified navigation. Doing this gives the simple navigation on 2FA screen,
        // and guarantees that when we use WorkspaceConversationSidebarContent below
        // then it will always have access to a conversation manager
        return <SimplifiedNavigation />;
    }

    return <WorkspaceConversationSidebarContent onClick={props.onClick} />;
};

type WorkspaceConversationSidebarContentProps =
    WorkspaceConversationsNavigationProps;

const WorkspaceConversationSidebarContent = ({
    onClick,
}: WorkspaceConversationSidebarContentProps) => {
    const workspaceId = useWorkspaceId();
    const manager = useConversationManager();
    const collaborativeInboxEnabled = useIsFeatureEnabled(
        FeatureName.CollaborativeWebInbox,
    );
    const workspaceUserManagementEnabled = useIsFeatureEnabled(
        FeatureName.WorkspaceUserManagement,
    );

    const history = useHistory();
    const params = useParams();
    const track = useAnalytics();

    const handleInviteButton = (): void => {
        const analyticsProps = {
            origin: history.location.pathname,
            navigationOptionSelected: "InviteNewColleague",
        };

        track("NavigationSubMenu Button Click", analyticsProps);

        history.push(
            generatePath(WORKSPACE_MANAGEMENT_ROUTES.inviteUsers, {
                workspaceId,
            }),
            params,
        );
    };

    const isApprovedUser = useAppSelector(({ account }) => {
        return OrganisationHelper.getIsApprovedOrgUser(account);
    });

    const currentUserQuery = useSingleUserQuery(manager.currentUserId);
    const currentUserTotalUnreadCount = useAssignedToCurrentUserUnreadCount(
        manager.currentUserId,
    );

    const colleaguesQuery = useAllColleaguesQuery(manager.currentUserId);
    const assignedToSectionUnreadCount =
        useAssignedToAllColleaguesAndTeamsUnreadCount(manager.currentUserId);

    // Open/close dropdown based on what user has manually opened/collapsed, save across sessions
    const isMyConversationsCollapsed =
        UserSettingsService.getIsMyConversationsSectionCollapsed(
            manager.currentUserId,
        );
    const isTeamsColleaguesConversationsCollapsed =
        UserSettingsService.getIsTeamsColleaguesConversationsSectionCollapsed(
            manager.currentUserId,
        );
    const [myConversationsMenuCollapsed, setMyConversationsMenuCollapsed] =
        useState(isMyConversationsCollapsed);
    const [teamsColleaguesMenuCollapsed, setTeamsColleaguesMenuCollapsed] =
        useState(isTeamsColleaguesConversationsCollapsed);
    const collapseExpandMyConversationsClick = () => {
        setMyConversationsMenuCollapsed(!myConversationsMenuCollapsed);
        UserSettingsService.setIsMyConversationsSectionCollapsed(
            manager.currentUserId,
            !myConversationsMenuCollapsed,
        );
    };
    const collapseExpandTeamsColleaguesConversationsClick = () => {
        setTeamsColleaguesMenuCollapsed(!teamsColleaguesMenuCollapsed);
        UserSettingsService.setIsTeamsColleaguesConversationsSectionCollapsed(
            manager.currentUserId,
            !teamsColleaguesMenuCollapsed,
        );
    };

    const sortedResult = useMemo(() => {
        if (colleaguesQuery.data) {
            const sorted = {
                teams: [...colleaguesQuery.data.teams].sort(
                    USERS_TEAMS_SORT_FN,
                ),
                users: [...colleaguesQuery.data.users].sort(
                    USERS_TEAMS_SORT_FN,
                ),
            };
            return sorted;
        }
    }, [colleaguesQuery.data]);

    return (
        <StyledContainer>
            <SecondaryNavigationHeader
                props={{ id: "conversations-navigation" }}
            >
                Conversations
            </SecondaryNavigationHeader>
            <StyledScrollableUnstyledList
                aria-label="Conversations navigation"
                gutter={2}
            >
                <CollapsibleMenu
                    title={
                        currentUserQuery.data?.displayName ||
                        (currentUserQuery.status === "loading"
                            ? ""
                            : "My conversations") // Don't flash up "my conversations" while loading
                    }
                    id="my-conversations"
                    open={!myConversationsMenuCollapsed}
                    onOpenChange={collapseExpandMyConversationsClick}
                    renderBadge={({ open }) => (
                        <ConversationGroupCollapsibleUnreadBadge
                            open={open}
                            unreadCount={currentUserTotalUnreadCount}
                        />
                    )}
                >
                    <SubsectionLinkList labelledBy="my-conversations">
                        <li>
                            <ConversationGroupCurrentUserAssignedToNavlink
                                user={currentUserQuery.data}
                                workspaceId={manager.workspaceId}
                                onClick={onClick}
                            />
                        </li>
                        <li>
                            <ConversationGroupCurrentUserSentNavlink
                                workspaceId={manager.workspaceId}
                                onClick={onClick}
                            />
                        </li>
                    </SubsectionLinkList>
                </CollapsibleMenu>
                {collaborativeInboxEnabled && (
                    <CollapsibleMenu
                        title="Assigned to"
                        id="teams-and-colleagues-conversations"
                        open={!teamsColleaguesMenuCollapsed}
                        onOpenChange={
                            collapseExpandTeamsColleaguesConversationsClick
                        }
                        renderBadge={({ open }) => (
                            <ConversationGroupCollapsibleUnreadBadge
                                open={open}
                                unreadCount={assignedToSectionUnreadCount}
                            />
                        )}
                    >
                        {colleaguesQuery.isLoading && (
                            <LoadingSkeleton repeat={3} />
                        )}
                        {colleaguesQuery.error && (
                            <SectionFailedToLoadFeedback />
                        )}
                        {sortedResult &&
                            (sortedResult.teams.length === 0 &&
                            sortedResult.users.length === 0 ? (
                                <Feedback colour="information">
                                    There are no colleagues in your workspace
                                </Feedback>
                            ) : (
                                <StackPanel
                                    data-testid="teams-and-colleagues-conversations"
                                    gutter={1.5}
                                >
                                    {sortedResult.teams.length > 0 && (
                                        <StackPanel
                                            gutter={0.5}
                                            data-testid="teams-section"
                                        >
                                            <Text
                                                as="h4"
                                                props={{ id: "teams-section" }}
                                                skinny
                                                variant="preview"
                                            >
                                                Teams
                                            </Text>
                                            <SubsectionLinkList labelledBy="teams-section">
                                                {sortedResult.teams.map(
                                                    (team) => {
                                                        return (
                                                            <li key={team.id}>
                                                                <ConversationGroupTeamNavlink
                                                                    team={team}
                                                                    onClick={
                                                                        onClick
                                                                    }
                                                                />
                                                            </li>
                                                        );
                                                    },
                                                )}
                                            </SubsectionLinkList>
                                        </StackPanel>
                                    )}
                                    <StackPanel
                                        gutter={0.5}
                                        data-testid="colleagues-section"
                                    >
                                        <Text
                                            as="h4"
                                            props={{
                                                id: "colleagues-section",
                                            }}
                                            skinny
                                            variant="preview"
                                        >
                                            Colleagues
                                        </Text>
                                        {workspaceUserManagementEnabled &&
                                            isApprovedUser && (
                                                <Button
                                                    onClick={handleInviteButton}
                                                    text="Invite new"
                                                    theme="secondary"
                                                    icon={{
                                                        name: "Plus",
                                                        colour: "blue",
                                                    }}
                                                />
                                            )}
                                        <SubsectionLinkList labelledBy="colleagues-section">
                                            {sortedResult.users.map((user) => {
                                                return (
                                                    <li key={user.id}>
                                                        <ConversationGroupColleagueNavlink
                                                            user={user}
                                                            onClick={onClick}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </SubsectionLinkList>
                                    </StackPanel>
                                </StackPanel>
                            ))}
                    </CollapsibleMenu>
                )}
            </StyledScrollableUnstyledList>
        </StyledContainer>
    );
};

const SectionFailedToLoadFeedback = () => (
    <Feedback iconName="None" colour="error" title="Failed to load">
        Try refreshing the page to see this section.
    </Feedback>
);

type WorkspaceConversationSubsectionLinkList = {
    /** Should be the id of the header which should label the list */
    labelledBy: string;

    children: ReactNode;
};

/**
 * Unordered list with some custom styling.
 */
const SubsectionLinkList = ({
    children,
    labelledBy,
}: WorkspaceConversationSubsectionLinkList): JSX.Element => {
    return (
        <StyledUnstyledList as="ul" gutter={0.5} title={labelledBy}>
            {children}
        </StyledUnstyledList>
    );
};

type LoadingSkeletonProps = {
    repeat?: number;
};

/** Loading skeleton for navigation items */
const LoadingSkeleton = ({ repeat = 1 }: LoadingSkeletonProps) => {
    return (
        <div role="status">
            {Array(repeat || 1) // minimum one skeleton
                .fill(true)
                .map((_, i) => {
                    return <SkeletonBody key={i} charCount={100} />;
                })}
        </div>
    );
};

type ConversationGroupCollapsibleUnreadBadgeProps = {
    /** Whether the collapsible is open */
    open: boolean;
    /** Number of unread conversations in the group */
    unreadCount?: number;
};

/**
 * Utility component to render the badge next to the navlink.
 * It is styled conditionally based on active navlink state
 */
const ConversationGroupCollapsibleUnreadBadge = ({
    open,
    unreadCount,
}: ConversationGroupCollapsibleUnreadBadgeProps) => {
    if (open) {
        return null;
    }

    return (
        <WorkspaceConversationUnreadCountBadge
            unreadCount={unreadCount}
            color="greyscale"
        />
    );
};

type ConversationGroupNavLinkUnreadBadgeProps = {
    /** Whether the link is active */
    isActive: boolean;
    /** Number of unread conversations in the group */
    unreadCount?: number;
};

/**
 * Utility component to render the badge next to the navlink.
 * It is styled conditionally based on active navlink state
 */
const ConversationGroupNavLinkUnreadBadge = ({
    isActive,
    unreadCount,
}: ConversationGroupNavLinkUnreadBadgeProps) => {
    return (
        <WorkspaceConversationUnreadCountBadge
            unreadCount={unreadCount}
            color="greyscale"
            luminosity={isActive ? "high" : "low"}
        />
    );
};

/**
 * Link item of the current user's assigned conversations
 */
const ConversationGroupCurrentUserAssignedToNavlink = (props: {
    user?: UserSummary;
    workspaceId: number;
    onClick: () => void;
}): JSX.Element => {
    const path = generatePath(ROUTES_WORKSPACE.conversationsMe, {
        workspaceId: props.workspaceId,
        relationship: "assigned",
    });
    const isActiveRoute = useRouteMatch({ path });

    const ruleset = useUserAssignedRuleset(props.user?.id || null);
    const result = useConversationGroupSummary(props.user?.id ? ruleset : null);

    const displayUnreadCountBadge = ({
        isActive,
    }: RenderBadgeNavLinkArgs): JSX.Element | null => {
        if (result === null) {
            return null;
        }

        return (
            <ConversationGroupNavLinkUnreadBadge
                unreadCount={result.data?.unreadCount}
                isActive={isActive}
            />
        );
    };

    return (
        <AutoScroll active={!!isActiveRoute}>
            <NavLink
                to={path}
                text={"Assigned to"}
                renderBadge={displayUnreadCountBadge}
                variant={LinkVariant.Secondary}
                onClick={props.onClick}
                customAnalyticsProps={{
                    navigationOptionSelected:
                        FlemingAnalyticsTracker.ConversationGroup
                            .UserSelfAssignedConversations,
                    notificationCount: result?.data?.unreadCount
                        ? getUnreadCountBadgeDisplayValue(
                              result?.data?.unreadCount,
                          )
                        : undefined,
                }}
            />
        </AutoScroll>
    );
};

/**
 * Link item of the current user's conversations which include sent items by said user
 */
const ConversationGroupCurrentUserSentNavlink = (props: {
    workspaceId: number;
    onClick: () => void;
}): JSX.Element => {
    const path = generatePath(ROUTES_WORKSPACE.conversationsMe, {
        workspaceId: props.workspaceId,
        relationship: "sent",
    });
    const isActiveRoute = useRouteMatch({ path });

    return (
        <AutoScroll active={!!isActiveRoute}>
            <NavLink
                to={path}
                text={"Sent by"}
                variant={LinkVariant.Secondary}
                onClick={props.onClick}
                customAnalyticsProps={{
                    navigationOptionSelected:
                        FlemingAnalyticsTracker.ConversationGroup
                            .UserSelfSentConversations,
                }}
            />
        </AutoScroll>
    );
};

/**
 * Link item of a specific colleague (that is not me)
 */
const ConversationGroupColleagueNavlink = (props: {
    user: UserSummary;
    onClick: () => void;
}): JSX.Element | null => {
    const ruleset = useUserAssignedRuleset(props.user.id);

    const result = useConversationGroupSummary(ruleset);
    const path = result?.workspaceId
        ? generatePath(ROUTES_WORKSPACE.conversationsUser, {
              workspaceId: result.workspaceId,
              userId: props.user.id,
              relationship: "assigned",
          })
        : undefined;
    const isActiveRoute = useRouteMatch({ path });

    const displayUnreadCountBadge = ({
        isActive,
    }: RenderBadgeNavLinkArgs): JSX.Element | null => {
        if (!result) {
            return null;
        }

        return (
            <ConversationGroupNavLinkUnreadBadge
                unreadCount={result?.data?.unreadCount}
                isActive={isActive}
            />
        );
    };

    if (!path) {
        return null;
    }

    return (
        <AutoScroll active={!!isActiveRoute}>
            <NavLink
                to={path}
                text={props.user.displayName}
                renderBadge={displayUnreadCountBadge}
                variant={LinkVariant.Secondary}
                onClick={props.onClick}
                customAnalyticsProps={{
                    navigationOptionSelected:
                        FlemingAnalyticsTracker.ConversationGroup
                            .UserOtherAssignedConversations,
                    notificationCount: result?.data?.unreadCount
                        ? getUnreadCountBadgeDisplayValue(
                              result.data.unreadCount,
                          )
                        : undefined,
                }}
            />
        </AutoScroll>
    );
};

/**
 * Link item of a specific team
 */
const ConversationGroupTeamNavlink = (props: {
    team: TeamSummary;
    onClick: () => void;
}): JSX.Element | null => {
    const ruleset = useTeamAssignedRuleset(props.team.id);

    const result = useConversationGroupSummary(ruleset);
    const path = result
        ? generatePath(ROUTES_WORKSPACE.conversationsTeam, {
              workspaceId: result?.workspaceId,
              teamId: props.team.id,
          })
        : undefined;
    const isActiveRoute = useRouteMatch({ path });

    const displayUnreadCountBadge = ({
        isActive,
    }: RenderBadgeNavLinkArgs): JSX.Element | null => {
        return (
            <ConversationGroupNavLinkUnreadBadge
                unreadCount={result?.data?.unreadCount}
                isActive={isActive}
            />
        );
    };

    if (!path) {
        return null;
    }

    return (
        <AutoScroll active={!!isActiveRoute}>
            <NavLink
                to={path}
                text={props.team.displayName}
                renderBadge={displayUnreadCountBadge}
                variant={LinkVariant.Secondary}
                onClick={props.onClick}
                customAnalyticsProps={{
                    navigationOptionSelected:
                        FlemingAnalyticsTracker.ConversationGroup
                            .TeamAssignedConversations,
                    notificationCount: result?.data?.unreadCount
                        ? getUnreadCountBadgeDisplayValue(
                              result.data.unreadCount,
                          )
                        : undefined,
                }}
            />
        </AutoScroll>
    );
};

/*
 * LOCAL CUSTOM HOOKS
 */

/**
 * @param userId the id of the user
 * @returns the ruleset related to a specific user's conversation group
 */
const useUserAssignedRuleset = (
    userId: UserSummary["id"] | null,
): ConversationGroupRuleset =>
    useMemo<ConversationGroupRuleset>(
        () => ({
            rules: [
                {
                    type: "AssignedTo",
                    value: { type: "User", id: userId },
                },
            ],
        }),
        [userId],
    );

/**
 * @param teamId the id of the team
 * @returns the ruleset related to a specific team's conversation group
 */
const useTeamAssignedRuleset = (
    teamId: TeamSummary["id"] | null,
): ConversationGroupRuleset =>
    useMemo<ConversationGroupRuleset>(
        () => ({
            rules: [
                {
                    type: "AssignedTo",
                    value: { type: "Team", id: teamId },
                },
            ],
        }),
        [teamId],
    );

/**
 * @param currentUserId the user id of the currently logged in user
 * @returns the overall unread count for the current user's conversation groups if possible
 */
const useAssignedToCurrentUserUnreadCount = (
    currentUserId: string,
): number | undefined => {
    const ruleset = useUserAssignedRuleset(currentUserId);

    const result = useConversationGroupSummary(ruleset);

    return result?.data?.unreadCount;
};

/**
 * @param currentUserId the user id of the currently logged in user
 * @returns the overall unread count for all colleagues and teams conversation groups if possible
 */
const useAssignedToAllColleaguesAndTeamsUnreadCount = (
    currentUserId: string,
): number | undefined => {
    const allUsersRuleset = useUserAssignedRuleset(null);
    const currentUserRuleset = useUserAssignedRuleset(currentUserId);

    const allUsersSummary = useConversationGroupSummary(allUsersRuleset);
    const currentUserSummary = useConversationGroupSummary(currentUserRuleset);

    const allTeamsRuleset = useTeamAssignedRuleset(null);
    const allTeamsSummary = useConversationGroupSummary(allTeamsRuleset);

    // It's not possible to build rulesets currently which exclude specific users - for now we
    // can take the result of all users and subtract the current user once the data is loaded.
    const colleaguesUnreadCount =
        allUsersSummary?.data && currentUserSummary?.data
            ? allUsersSummary?.data.unreadCount -
              currentUserSummary?.data.unreadCount
            : undefined;

    const totalUnreadCount =
        colleaguesUnreadCount !== undefined && allTeamsSummary?.data
            ? colleaguesUnreadCount + allTeamsSummary?.data.unreadCount
            : undefined;

    return totalUnreadCount;
};
