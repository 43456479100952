import { Tokens } from "@accurx/design";
import { ModalBody } from "reactstrap";
import styled, { css } from "styled-components";

const CommonStyle = css`
    display: grid;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]} ${Tokens.SIZES[0.5]}
        ${Tokens.SIZES[2]};
`;

const CommonResponsiveGridStyle = css`
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: ${Tokens.BREAKPOINTS.lg}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const CommonSeperatorStyle = css`
    box-shadow: inset 0px -1px 0px ${Tokens.COLOURS.greyscale.silver};
`;

export const CustomModalBody = styled(ModalBody)`
    padding: 0;
`;

export const TopRowBatchDetails = styled.div`
    ${CommonStyle}
    grid-template-columns: 15.4rem 30.8rem;
    ${CommonSeperatorStyle}
    gap: ${Tokens.SIZES[2]};
    ${CommonResponsiveGridStyle};
`;

export const MidRowBatchDetails = styled.div`
    ${CommonStyle}
    grid-template-columns: repeat(3, 15.4rem);
    ${CommonSeperatorStyle}
    gap: ${Tokens.SIZES[2]};
    padding-bottom: ${Tokens.SIZES[2]};
    ${CommonResponsiveGridStyle};
`;

export const BottomRowBatchDetails = styled.div`
    ${CommonStyle}
    grid-template-columns: repeat(3, 15.4rem);
    gap: ${Tokens.SIZES[2]};
    ${CommonResponsiveGridStyle}
`;

export const FullRowBatchDetails = styled.div`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]} ${Tokens.SIZES[0.5]}
        ${Tokens.SIZES[2]};
`;

export const LastRowBatchDetails = styled.div`
    box-shadow: inset 0px 1px 0px ${Tokens.COLOURS.greyscale.silver};
    ${CommonStyle}
    gap: ${Tokens.SIZES[2]};
    padding-bottom: ${Tokens.SIZES[2]};
`;

export const StyledPatientValidMobileColumnModel = styled.div`
    @media screen and (min-width: ${Tokens.BREAKPOINTS.sm}) {
        grid-column: 2 / 4;
        grid-row: 1;
    }
`;

export const StyledFullWidthColumn = styled.div`
    grid-column: 1 / 4;
    margin-bottom: ${Tokens.SIZES[1]};
`;
