import React from "react";

import { Ds, Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";

import { PatientDetails } from "api/FlemingDtos";
import { sentenceCase } from "shared/formatters/String";

import { MISSING_DATA_TEXT } from "../RecordView.constant";
import { AllergiesBadge } from "./MedicalRecordPanelCard";

export interface PatientDetailsProps {
    patient: PatientDetails;
    allergyBadge: AllergiesBadge;
    withNavigation?: boolean;
}

export const PatientDetailsSummary = ({
    patient,
    allergyBadge,
}: PatientDetailsProps): JSX.Element => {
    return (
        <div className="d-flex justify-content-between align-items-start flex-wrap flex-md-nowrap">
            <div className="mr-3 mr-sm-0 mb-2">
                <Text
                    variant="subtitle"
                    colour="night"
                    skinny
                    props={{ className: "d-flex flex-wrap align-items-center" }}
                >
                    <span className="d-inline-block mr-3">
                        {patient.name || "Unknown name"}
                    </span>
                    <Ds.Badge color={allergyBadge.color}>
                        {allergyBadge.text}
                    </Ds.Badge>
                </Text>
                <Text
                    variant="preview"
                    colour="night"
                    props={{ className: "d-flex flex-wrap align-items-center" }}
                >
                    <span className="text-nowrap mr-3">
                        NHS:{" "}
                        {patient.nhsNumber
                            ? NhsNumberHelpers.formatNhsNumber(
                                  patient.nhsNumber,
                              )
                            : MISSING_DATA_TEXT}
                    </span>
                    <span className="text-nowrap mr-3">
                        Born:{" "}
                        {patient.dateOfBirth
                            ? DateHelpers.formatDate(
                                  patient.dateOfBirth,
                                  DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                              )
                            : MISSING_DATA_TEXT}
                    </span>
                    <span className="text-nowrap mr-3">
                        Gender:{" "}
                        {patient.gender
                            ? sentenceCase(patient.gender)
                            : MISSING_DATA_TEXT}
                    </span>
                    <span className="text-nowrap mr-3">
                        Mobile: {patient.mobileTelephone || MISSING_DATA_TEXT}
                    </span>
                </Text>
            </div>
        </div>
    );
};
