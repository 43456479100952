import { useEffect } from "react";

export const useCustomIntercomLauncher = ({
    resetDefaultLauncherOnUnmount = true,
}: {
    resetDefaultLauncherOnUnmount: boolean;
}) => {
    const showIntercom = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-call
        window?.Intercom?.("show");
    };

    // For logged in users (those who can see this button),
    // we hide the default intercom launcher and replace it with this.
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-call
        window?.Intercom?.("update", { hide_default_launcher: true });

        if (resetDefaultLauncherOnUnmount) {
            return () => {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-call
                window?.Intercom?.("update", { hide_default_launcher: false });
            };
        }
    }, [resetDefaultLauncherOnUnmount]);

    return {
        showIntercom,
    };
};
