import {
    CreateBranchRequest,
    CreateQuestionRequest,
    CreateQuestionnaireRequest,
    CreateTemplatedQuestionRequest,
    DeleteBranchRequest,
    DeleteQuestionRequest,
    EditBranchRequest,
    EditQuestionRequest,
    EditQuestionnaireRequest,
    ImportQuestionnaireRequest,
    ImportQuestionnaireResponse,
    QuestionnaireCountResponse,
    QuestionnaireResponse,
    QuestionnaireSummariesResponse,
    ShareQuestionnaireRequest,
    ShareQuestionnaireResponse,
    SharedQuestionnaireInfoResponse,
    SnomedCodesResponse,
    StatusChangeRequest,
    TagsResponse,
    UpdateQuestionOrderRequest,
    UserGroupsResponse,
} from "@accurx/api/florey-builder";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

const ENDPOINTS = {
    branches: "/api/floreybuilder/questionnaires/:questionnaireId/branches",
    branch: "/api/floreybuilder/questionnaires/:questionnaireId/branches/:branchId",

    questionnaireSummaries:
        "/api/floreybuilder/organisations/:practiceId/questionnaire-summaries",
    questionnaireCount:
        "/api/floreybuilder/organisations/:practiceId/questionnaire-count",

    questionnaires: "/api/floreybuilder/questionnaires",
    questionnaire: "/api/floreybuilder/questionnaires/:questionnaireId",
    questions: "/api/floreybuilder/questionnaires/:questionnaireId/questions",
    question:
        "/api/floreybuilder/questionnaires/:questionnaireId/questions/:questionId",

    questionFromTemplate:
        "/api/floreybuilder/questionnaires/:questionnaireId/questions/from-template",

    questionnaireStatus:
        "/api/floreybuilder/questionnaires/:questionnaireId/status",

    shareQuestionnaire:
        "/api/floreybuilder/questionnaires/:questionnaireId/share",
    sharedQuestionnaire: "/api/floreybuilder/sharing/:shareId",

    snomedCodes: "/api/floreybuilder/clinicalcodes/all-snomed-codes",

    userGroups: "/api/floreybuilder/organisations/:practiceId/user-groups",

    reorderQuestions:
        "/api/floreybuilder/questionnaires/:questionnaireId/update-question-order",
    getTags: "/api/floreybuilder/tags",
} as const;

export const getQuestionnaireSummaries = (
    practiceId: string,
): Promise<IWrappedResult<QuestionnaireSummariesResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaireSummaries,
            params: { practiceId },
        }),
    );

export const getQuestionnaireCount = (
    practiceId: string,
): Promise<IWrappedResult<QuestionnaireCountResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaireCount,
            params: { practiceId },
        }),
    );

export const getUserGroups = (
    practiceId: string,
): Promise<IWrappedResult<UserGroupsResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.userGroups,
            params: { practiceId },
        }),
    );

export const getQuestionnaire = (
    practiceId: string,
    questionnaireId: string,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaire,
            params: { questionnaireId },
            query: `?organisationId=${practiceId}`,
        }),
    );

export const createQuestionnaire = (
    practiceId: string,
    request: CreateQuestionnaireRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaires,
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const editQuestionnaire = (
    practiceId: string,
    request: EditQuestionnaireRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.patchJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaire,
            params: { questionnaireId: request.questionnaireId.toString() },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const addQuestion = (
    practiceId: string,
    request: CreateQuestionRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questions,
            params: { questionnaireId: request.questionnaireId.toString() },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const addQuestionFromTemplate = (
    practiceId: string,
    request: CreateTemplatedQuestionRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionFromTemplate,
            params: { questionnaireId: request.questionnaireId.toString() },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const editQuestion = (
    practiceId: string,
    questionnaireId: number,
    request: EditQuestionRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> => {
    const endpoint = getApiEndpoint({
        path: ENDPOINTS.question,
        params: {
            questionnaireId: questionnaireId.toString(),
            questionId: request.questionId.toString(),
        },
        query: `?organisationId=${practiceId}`,
    });

    return httpClient.putJsonReturnJsonSafeAsync(endpoint, request);
};

export const deleteQuestion = (
    practiceId: string,
    request: DeleteQuestionRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.deleteJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.question,
            params: {
                questionnaireId: request.questionnaireId.toString(),
                questionId: request.questionId.toString(),
            },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const editQuestionnaireStatus = (
    practiceId: string,
    request: StatusChangeRequest,
): Promise<IWrappedResult<QuestionnaireSummariesResponse>> =>
    httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.questionnaireStatus,
            params: {
                questionnaireId: request.questionnaireId.toString(),
            },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const addBranch = (
    workspaceId: number,
    request: CreateBranchRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.branches,
            params: { questionnaireId: request.questionnaireId.toString() },
            query: `?organisationId=${workspaceId}`,
        }),
        request,
    );

export const editBranch = (
    workspaceId: number,
    request: EditBranchRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.branch,
            params: {
                questionnaireId: request.questionnaireId.toString(),
                branchId: request.branchId.toString(),
            },
            query: `?organisationId=${workspaceId}`,
        }),
        request,
    );

export const deleteBranch = (
    workspaceId: number,
    request: DeleteBranchRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.deleteJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.branch,
            params: {
                questionnaireId: request.questionnaireId.toString(),
                branchId: request.branchId.toString(),
            },
            query: `?organisationId=${workspaceId}`,
        }),
        request,
    );

export const shareQuestionnaire = (
    practiceId: string,
    request: ShareQuestionnaireRequest,
): Promise<IWrappedResult<ShareQuestionnaireResponse>> =>
    httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.shareQuestionnaire,
            params: {
                questionnaireId: request.questionnaireId.toString(),
            },
            query: `?organisationId=${practiceId}`,
        }),
        request,
    );

export const getSharedQuestionnaire = (
    shareId: string,
): Promise<IWrappedResult<SharedQuestionnaireInfoResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.sharedQuestionnaire,
            params: { shareId },
        }),
    );

export const importSharedQuestionnaire = (
    organisationId: number,
    shareId: string,
    request: ImportQuestionnaireRequest,
): Promise<IWrappedResult<ImportQuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: `floreybuilder/organisations/${organisationId}/import/${shareId}`,
        }),
        request,
    );

export const getSnomedCodes = (
    practiceId: string,
): Promise<IWrappedResult<SnomedCodesResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.snomedCodes,
            query: `?organisationId=${practiceId}`,
        }),
    );

export const reorderQuestions = (
    organisationId: number,
    questionnaireId: string,
    request: UpdateQuestionOrderRequest,
): Promise<IWrappedResult<QuestionnaireResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.reorderQuestions,
            params: { questionnaireId },
            query: `?organisationId=${organisationId}`,
        }),
        request,
    );

export const getContentTags = (): Promise<IWrappedResult<TagsResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.getTags,
        }),
    );
