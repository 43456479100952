/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Button,
    ButtonLink,
    Card,
    Feedback,
    Icon,
    Spinner,
    Text,
    Tokens,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import {
    formatNhsNumber,
    validateNhsNumber,
} from "@accurx/shared/dist/NhsNumberHelper";
import debounce from "lodash/debounce";
import { parse, stringifyUrl } from "query-string";
import { unstable_batchedUpdates } from "react-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";
import { clearTimer, startTimer } from "shared/Timer.helper";
import { useIsFeatureEnabled } from "store/hooks";

import { PracticesReducerState } from "../../Practices.reducer";
import { getVaccineInviteListCsvDownloadUrl } from "../Vaccine.helper";
import { VaccineInviteOperationResult } from "../models/VaccineAllPatientsInvitedDTO";
import {
    FilterTerm,
    FilterTermType,
    PatientInviteSearchFilters,
    VaccineAllInvitesFiltersData,
    VaccineCourse,
    VaccineInviteAction,
    VaccineInviteActionData,
    VaccinePatientInvited,
} from "../models/VaccineDeliveryDTO";
import {
    AwaitingBoosterInvite,
    AwaitingResponseBooster,
    AwaitingResponseFirst,
    AwaitingResponseSecond,
    AwaitingSecondInvite,
    BookedBooster,
    BookedBoth,
    BookedFirstOnly,
    Cancelled,
    CancelledBooster,
    CompletedBooster,
    CompletedPrimary,
    Declined,
    DeclinedBooster,
    NotInNetwork,
    Rejected,
    RejectedIneligible,
    StatusLabelInfo,
    ToManuallyBookBooster,
    ToManuallyBookFirst,
    ToManuallyBookSecond,
    getPatientStatusInfo,
} from "../models/VaccineInviteStatus";
import { getVaccineCourseSearchTerm } from "../utils";
import { BulkActionContext } from "../vaccineBulkActions/VaccineBulkActions.component";
import { resetAddToNetworkInfo } from "./VaccineAllPatientsInvited.actions";
import { initialState as AllPatientsInvitedInitialState } from "./VaccineAllPatientsInvited.reducer";
import { VaccineAllPatientsInvitedDetails } from "./VaccineAllPatientsInvitedDetails";
import { VaccineAllPatientsInvitedFilterFeedback } from "./VaccineAllPatientsInvitedFilterFeedback";
import { VaccineAllPatientsInvitedFilters } from "./VaccineAllPatientsInvitedFilters";
import { VaccineAllPatientsInvitedSearch } from "./VaccineAllPatientsInvitedSearch";
import { VaccineDeliveryCancelModal } from "./VaccineDeliveryCancelModal";
import { VaccineDeliveryMoreDropdown } from "./VaccineDeliveryMoreDropdown";
import { VaccineDeliveryResetModal } from "./VaccineDeliveryResetModal";

import "./VaccineDelivery.scss";

export const LIMIT_PATIENTS = 1000;

export interface VaccineAllPatientsInvitedProps {
    noHeader?: boolean;
    practices: PracticesReducerState;
    practiceId: string;
    getAllPatientsDetailsStatus: UpdatingStatus;
    patientList: Array<VaccinePatientInvited>;
    patientListIsLimited: boolean;
    cancelInviteId: string;
    cancelStatus: UpdatingStatus;
    resetInviteId: string;
    resetStatus: UpdatingStatus;
    bulkCancelStatus: UpdatingStatus;
    bulkCancelResponse: VaccineInviteOperationResult[];
    goToCancelFlow: boolean;
    allInvitesFilters: VaccineAllInvitesFiltersData;
    vaccineCourse: VaccineCourse;
    actions: {
        resetState: () => void;
        getVaccineAllPatientsInvited: (
            practiceId: string,
            body: PatientInviteSearchFilters,
        ) => void;
        cancelBooking: (
            practiceId: string,
            vaccineDeliveryId: string,
            inviteId: string,
            isAllowedRebook: boolean,
            cancelSecondBooking: boolean,
            messageSignature: string | null,
        ) => void;
        updateSearchTerm: (searchTerm: string) => void;
        setStatusFilters: (statusFilters: string[]) => void;
        resetBooking: (
            practiceId: string,
            vaccineDeliveryId: string,
            inviteId: string,
        ) => void;
        setCancelBookingDetails: (patient: VaccinePatientInvited) => void;
        setPatientInvites: (
            vaccineInviteActionData: VaccineInviteActionData,
        ) => void;
        updateCourseType: (course: VaccineCourse) => void;
    };
}

export interface PatientDetails {
    nhsNumber: string;
    displayName: string;
    gender: string;
    dateOfBirthDisplay: string;
    ageDisplay: string;
}

interface SearchTokenData {
    server: string[];
    client: string[];
}

enum SortOrder {
    NameAscending,
    NameDescending,
    AgeAscending,
    AgeDescending,
    InviteCreatedAscending,
    InviteCreatedDescending,
    LastVaccineDateAscending,
    LastVaccineDateDescending,
}

type SortOption = {
    id: SortOrder;
    displayName: string;
    apiKey: string;
};

// keep apiKey values in sync with src/Web/accuRx.Web.Chain/DTO/Recall.cs:PatientListSortOrder
export const sortOptions: SortOption[] = [
    {
        id: SortOrder.NameAscending,
        displayName: "Name - A-Z",
        apiKey: "nameAsc",
    },
    {
        id: SortOrder.NameDescending,
        displayName: "Name - Z-A",
        apiKey: "nameDesc",
    },
    {
        id: SortOrder.AgeDescending,
        displayName: "Age - Oldest first",
        apiKey: "patientAgeDesc",
    },
    {
        id: SortOrder.AgeAscending,
        displayName: "Age - Youngest first",
        apiKey: "patientAgeAsc",
    },
    {
        id: SortOrder.InviteCreatedDescending,
        displayName: "Invite - Most recent",
        apiKey: "createdDesc",
    },
    {
        id: SortOrder.InviteCreatedAscending,
        displayName: "Invite - Least recent",
        apiKey: "createdAsc",
    },
    {
        id: SortOrder.LastVaccineDateDescending,
        displayName: "Latest vaccine - Most recent",
        apiKey: "lastVaccineDateDesc",
    },
    {
        id: SortOrder.LastVaccineDateAscending,
        displayName: "Latest vaccine - Least recent",
        apiKey: "lastVaccineDateAsc",
    },
];

const defaultSortOption = sortOptions[5]; // default: "Invite - Least recent"

export enum SelectMultipleOptions {
    Select50 = "Select first 50 patients",
    Select100 = "Select first 100 patients",
    SelectAll = "Select all",
}

// Known eslint issue for enums
// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum SupportedBulkAction {
    CancelAppointment,
    CancelInvite,
    InviteToSecond,
    InviteToBooster,
    SendReminder,
}

const serverFilterTermRegex = /^(?:clinicid|secondclinicid|inviteid):\d+$/i;
const filteredToClinicRegex = /^clinicId:\d+\s*/i;
const paginationSize = 50; // On changing this, consider bulk actions filters (select 50/100) which are hard-coded currently

const debouncedSearchTerm = debounce((callback: () => void) => callback(), 800);

const tokenizeSearchTerm = (input: string): SearchTokenData => {
    const result: SearchTokenData = {
        server: [],
        client: [],
    };
    // at the moment we have a hybrid model where parts of the query go to the server and parts can be done in the client
    (input ?? "").split(" ").forEach((term) => {
        if (term.match(serverFilterTermRegex)) {
            result.server.push(term);
        } else {
            result.client.push(term.toLowerCase());
        }
    });
    return result;
};

const parseSearchTerm = (input: string): FilterTerm[] => {
    return (input ?? "")
        .split(/\s+/)
        .map((tok) => {
            const isNhsNumber = validateNhsNumber(tok).success;
            const isNumber = !isNhsNumber && !!tok.match(/^\+?\d+$/);

            return {
                type: isNhsNumber
                    ? FilterTermType.NhsNumber
                    : isNumber
                    ? FilterTermType.PhoneNumber
                    : FilterTermType.Name,
                value: tok,
            };
        })
        .filter((t) => t.value); // filter out empty
};

const searchTermFromQueryOrProps = (
    propsSearchTerm: string,
    locationSearch: string,
): string => {
    const queryString = parse(locationSearch);
    const qParam = queryString.q as string;
    return qParam ?? propsSearchTerm;
};

export const VaccineAllPatientsInvited = (
    props: VaccineAllPatientsInvitedProps,
): JSX.Element => {
    // #region Variables - general
    const history = useHistory();
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const isAutumn2023Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2023BoosterTab,
    );
    const isSpring2024Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeSpring2024BoosterTab,
    );
    const isAutumn2024Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2024BoosterTab,
    );

    const isInviteDisabled =
        (props.vaccineCourse === VaccineCourse.BoosterAutumn2024 &&
            isAutumn2024Enabled === false) || // disabled if feature flag is disabled
        (props.vaccineCourse === VaccineCourse.BoosterSpring2024 &&
            isAutumn2024Enabled) || // disabled if next season is active
        (props.vaccineCourse === VaccineCourse.BoosterAutumn2023 &&
            (isSpring2024Enabled || isAutumn2024Enabled)) || // disabled if next season is active
        (props.vaccineCourse === VaccineCourse.BoosterSpring2023 &&
            (isSpring2024Enabled ||
                isAutumn2024Enabled ||
                isAutumn2023Enabled)) || // disabled if next season is active
        props.vaccineCourse === VaccineCourse.BoosterAutumn2022 || // always disabled
        props.vaccineCourse === VaccineCourse.BoosterSpring2022 || // always disabled
        props.vaccineCourse === VaccineCourse.Booster || // always disabled
        (props.vaccineCourse === VaccineCourse.Primary && isAutumn2023Enabled); // disabled if feature flag is enabled

    // need this for "adding to network" toast
    const addingToNetworkPatientBatchInviteId = useSelector(
        ({ vaccineAllPatientsInvited }: ApplicationState) =>
            vaccineAllPatientsInvited?.addingToNetworkPatientBatchInviteId ||
            AllPatientsInvitedInitialState.addingToNetworkPatientBatchInviteId,
        shallowEqual,
    );

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [isCancelInvite, setIsCancelInvite] = useState(false);
    const [isCancelSecondBooking, setIsCancelSecondBooking] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState("");
    const [selectedBatchInvite, setSelectedBatchInvite] = useState("");
    const [showResetModal, setShowResetModal] = useState(false);
    const [currentPaginationPage, setCurrentPaginationPage] = useState(0);
    const [sortingOrder, setSortingOrder] =
        useState<SortOption>(defaultSortOption);
    const [openLinkInFlight, setOpenLinkInFlight] = useState<{
        [inviteId: string]: boolean;
    }>({});

    // #endregion

    // #region Variables - Patient limit
    const [noPatientLimit, setNoPatientLimit] = useState(false);
    const displayRemoveClientLimit =
        props.patientListIsLimited &&
        !noPatientLimit &&
        props.patientList.length >= LIMIT_PATIENTS;
    const displayServerLimitedResults =
        props.patientListIsLimited && !displayRemoveClientLimit;
    // #endregion Variables - Patient limit

    // #region Variables for search filters
    const [searchTerm, setSearchTerm] = useState<string>(
        searchTermFromQueryOrProps(
            props.allInvitesFilters.searchTerm,
            useLocation().search,
        ),
    );
    const [serverSearchTerms, setServerSearchTerms] = useState(
        tokenizeSearchTerm(searchTerm).server.join(" "),
    );
    const [serverFilterTerms, setServerFilterTerms] = useState(
        parseSearchTerm(tokenizeSearchTerm(searchTerm).client.join(" ")),
    ); // supersedes serverSearchTerms based on feature flag

    // #endregion

    // #region Variables and calculations for bulk actions

    const [selectedPatients, setSelectedPatients] = useState(new Set<string>());
    const [isBulkCancelInvite, setIsBulkCancelInvite] =
        useState<boolean>(false);

    const numIndividualSelected = selectedPatients.size;

    // #endregion
    useEffect(() => {
        if (props.vaccineCourse === VaccineCourse.None) {
            props.actions.updateCourseType(
                isAutumn2024Enabled
                    ? VaccineCourse.BoosterAutumn2024
                    : isSpring2024Enabled
                    ? VaccineCourse.BoosterSpring2024
                    : VaccineCourse.BoosterAutumn2023,
            );
        }
    }, [
        dispatch,
        props.vaccineCourse,
        isSpring2024Enabled,
        isAutumn2024Enabled,
    ]);

    // PERFORMANCE_CHECK: we are looking to get some data on the load of this component
    useEffect(() => {
        const patientLimit = noPatientLimit ? null : LIMIT_PATIENTS;
        startTimer("ALL_PATIENTS", (elapsedTime: number) => {
            const analyticsProps: ChainAnalyticsTracker.VaccineAllPatientLoadingProps =
                {
                    ...analyticsLoggedInProps,
                    patientLimit: patientLimit,
                    loadTime: elapsedTime,
                };
            ChainAnalyticsTracker.trackVaccineAllPatientLoading(analyticsProps);
        });
    }, [noPatientLimit, props.practiceId]);

    // #region useEffect hooks

    // redirect to cancel flow if in cancel flow mode
    useEffect(() => {
        if (props.goToCancelFlow) {
            history.push(
                ROUTES_CHAIN.practicesVaccineCancelBookings.replace(
                    ":orgId",
                    props.practiceId,
                ),
            );
        }
    }, [history, props.goToCancelFlow, props.practiceId]);

    // initial load or return on completion of an action's flow
    useEffect(() => {
        const isInitialLoad =
            props.getAllPatientsDetailsStatus === UpdatingStatus.Initial;
        const isLoaded =
            props.getAllPatientsDetailsStatus === UpdatingStatus.Loaded;
        const isCancelPatientSuccessful =
            props.cancelStatus === UpdatingStatus.Loaded;
        const isResetPatientSuccessful =
            props.resetStatus === UpdatingStatus.Loaded;
        if (
            props.practiceId &&
            (isInitialLoad ||
                (isLoaded &&
                    (isCancelPatientSuccessful || isResetPatientSuccessful)))
        ) {
            props.actions.getVaccineAllPatientsInvited(
                props.practiceId,
                getSearchFilter(),
            );
        }
    }, [
        props.actions,
        props.practiceId,
        props.allInvitesFilters.statusFilters,
        props.getAllPatientsDetailsStatus,
        props.cancelStatus,
        props.allInvitesFilters.myPractice,
        serverSearchTerms,
        sortingOrder,
        serverFilterTerms,
    ]);

    // when parameters change that impact returned patients
    useEffect(() => {
        const isInitialLoad =
            props.getAllPatientsDetailsStatus === UpdatingStatus.Initial;
        const isCancelPatientSuccessful =
            props.cancelStatus === UpdatingStatus.Loaded;
        const isResetPatientSuccessful =
            props.resetStatus === UpdatingStatus.Loaded;
        if (
            props.practiceId &&
            !isInitialLoad &&
            !isCancelPatientSuccessful &&
            !isResetPatientSuccessful
        ) {
            props.actions.getVaccineAllPatientsInvited(
                props.practiceId,
                getSearchFilter(),
            );
            setNoPatientLimit(false);
            clearTimer("ALL_PATIENTS"); // PERFORMANCE_CHECK: don't account for the rendering of a second request
        }
    }, [
        props.actions,
        props.practiceId,
        props.vaccineCourse,
        props.allInvitesFilters.statusFilters,
        props.allInvitesFilters.myPractice,
        serverSearchTerms,
        props.allInvitesFilters.firstVaccinationTimeFilter,
        props.allInvitesFilters.vaccineTypeFilters,
        props.allInvitesFilters.unclearStatus,
        sortingOrder,
        serverFilterTerms,
    ]);

    // need to update search term when updating from one of the modal on the page (e.g. in the adding to network flow)
    useEffect(() => {
        if (searchTerm !== props.allInvitesFilters.searchTerm) {
            setSearchTerm(props.allInvitesFilters.searchTerm);
            debouncedUpdateSearchTerm(props.allInvitesFilters.searchTerm);
        }
    }, [props.allInvitesFilters.searchTerm]);

    // show adding to network toast if info to show
    useEffect(() => {
        if (addingToNetworkPatientBatchInviteId) {
            // don't open toast if coming from failed add (where there won't be an id set)
            toast.success(
                <span data-testid="add-to-network-toast">
                    Patient successfully added
                    <Button
                        theme="transparent"
                        text="Go to patient"
                        onClick={handleFilterToAddedInvite}
                        style={{ color: "white" }}
                    />
                </span>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },
            );
        }
    }, [addingToNetworkPatientBatchInviteId]);

    // #endregion

    // #region Single cancel invite

    const handleToggleModal = (): void => {
        setShowCancelModal((prevState) => !prevState);
    };

    const handleCancelButton = (
        patient: VaccinePatientInvited,
        isCancelInvite: boolean,
        isCancelSecondBooking: boolean,
    ): void => {
        setIsBulkCancelInvite(false); // Set to false in case previously set to true
        setSelectedPatient(patient.inviteId);
        setIsCancelInvite(isCancelInvite);
        setIsCancelSecondBooking(isCancelSecondBooking);
        setSelectedBatchInvite(patient.inviteBatchId);
        handleToggleModal();
        const analyticsProps: ChainAnalyticsTracker.VaccineCancelInviteProps = {
            ...analyticsLoggedInProps,
            patientStatus: patient.currentInviteStatus,
        };
        ChainAnalyticsTracker.trackVaccineCancelInvite(analyticsProps);
    };

    const handleCancelSubmit = (
        isAllowedRebook: boolean,
        cancelSecondBooking: boolean,
    ): void => {
        setShowCancelModal(false);
        props.actions.cancelBooking(
            props.practiceId,
            selectedBatchInvite,
            selectedPatient,
            isAllowedRebook,
            cancelSecondBooking,
            null,
        );
    };

    // #endregion

    // #region Single reset invite

    const handleToggleResetModal = (): void => {
        setShowResetModal((prevState) => !prevState);
    };

    const handleResetButton = (patient: VaccinePatientInvited): void => {
        setSelectedPatient(patient.inviteId);
        setSelectedBatchInvite(patient.inviteBatchId);
        handleToggleResetModal();
    };

    const handleResetSubmit = (): void => {
        setShowResetModal(false);
        ChainAnalyticsTracker.trackVaccineResetInvite(analyticsLoggedInProps);
        props.actions.resetBooking(
            props.practiceId,
            selectedBatchInvite,
            selectedPatient,
        );
    };

    // #endregion

    // #region Single invite reminder

    const handleInviteReminderButton = (
        patient: VaccinePatientInvited,
    ): void => {
        props.actions.setPatientInvites({
            invites: [patient],
            action: VaccineInviteAction.Reminder,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineSmsPreview.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    // #endregion

    // #region Single invite to second booking

    const handleInviteToSecondBooking = (patient: VaccinePatientInvited) => {
        props.actions.setPatientInvites({
            invites: [patient],
            action: VaccineInviteAction.InviteToSecondBooking,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineSmsPreview.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    const handleInviteToBoosterBooking = (
        patient: VaccinePatientInvited,
    ): void => {
        props.actions.setPatientInvites({
            invites: [patient],
            action: VaccineInviteAction.InviteToBoosterBooking,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineSmsPreview.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    // #endregion

    // #region Single invite handle appointments

    const handleManageAppointmentsButton = (
        patient: VaccinePatientInvited,
    ): void => {
        props.actions.setCancelBookingDetails(patient);
    };

    // #endregion

    // #region Searching

    // This is just used for calculating an object to pass into calls
    const getSearchFilter = (
        noPatientLimit?: boolean,
    ): PatientInviteSearchFilters => ({
        course: getVaccineCourseSearchTerm(props.vaccineCourse),
        showAllLinkedOrgs: !props.allInvitesFilters.myPractice,
        searchTerm: serverSearchTerms,
        statusFilters: props.allInvitesFilters.statusFilters,
        daysSinceLastVaccination: {
            min:
                props.vaccineCourse !== VaccineCourse.Primary
                    ? props.allInvitesFilters.secondVaccinationTimeFilter
                          ?.minDaysInclusive
                    : props.allInvitesFilters.firstVaccinationTimeFilter
                          ?.minDays,
            max:
                props.vaccineCourse !== VaccineCourse.Primary
                    ? props.allInvitesFilters.secondVaccinationTimeFilter
                          ?.maxDaysExclusive
                    : props.allInvitesFilters.firstVaccinationTimeFilter
                          ?.maxDays,
        },
        vaccineTypeFilters: props.allInvitesFilters.vaccineTypeFilters,
        withConflicts: props.allInvitesFilters.unclearStatus,
        order: sortingOrder.apiKey,
        limit: noPatientLimit ? null : LIMIT_PATIENTS,
        filterTerms: serverFilterTerms,
    });

    // To be passed down to Details
    const debouncedUpdateSearchTerm = (searchInput: string): void => {
        // unstable_batchedUpdates should no longer be needed once we've upgraded to React 17+
        // it's needed here because this method not called within the context of a react change handler, instead it's called asynchronously after a setTimeout
        unstable_batchedUpdates(() => {
            const searchTokenData = tokenizeSearchTerm(searchInput);

            // persist search term for switching between old and new components
            props.actions.updateSearchTerm(searchInput);

            const parsedSearchTerms = parseSearchTerm(
                searchTokenData.client.join(" "),
            );
            setServerFilterTerms(parsedSearchTerms);

            // update local state and trigger server search if needed
            setServerSearchTerms(searchTokenData.server.join(" "));

            setCurrentPaginationPage(0);
        });
    };

    const updateSearchTerm = (
        event?: React.ChangeEvent<HTMLInputElement>,
        text?: string,
    ): void => {
        const searchInput = event?.target?.value || text || "";
        setSearchTerm(searchInput);
        debouncedSearchTerm(() => debouncedUpdateSearchTerm(searchInput));
    };

    const updateSearchTermForDetails = (searchInput: string): void => {
        setSearchTerm(searchInput);
        debouncedUpdateSearchTerm(searchInput);
    };

    // #endregion

    // #region Sorting
    const handleSortingOrderChange = (
        e: ChangeEvent<HTMLSelectElement>,
    ): void => {
        const value = sortOptions.find(
            (x) => x.apiKey === e.target.value,
        ) as SortOption;
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListSortOrderChangedProps =
            {
                ...analyticsLoggedInProps,
                previousOrder: sortingOrder,
                newOrder: value.displayName,
            };
        ChainAnalyticsTracker.trackVaccinePatientListSortOrderChanged(
            analyticsProps,
        );
        setSortingOrder(value);
    };

    const renderSortingOrderDropdownOptions = (): JSX.Element[] => {
        return sortOptions.map((value) => (
            <option key={value.apiKey} value={value.apiKey}>
                {value.displayName}
            </option>
        ));
    };

    const renderSortingOrderDropdown = (): JSX.Element => {
        return (
            <Card spacing={1.5}>
                <div className="d-flex">
                    <Icon size={3} name={"Order"} colour="blue" />
                    <select
                        value={sortingOrder.apiKey}
                        onChange={handleSortingOrderChange}
                        data-testid="sortingDropdown"
                        className="ml-2"
                        style={{ border: "none" }}
                    >
                        {renderSortingOrderDropdownOptions()}
                    </select>
                </div>
            </Card>
        );
    };

    // #endregion

    // #region Pagination

    const handlePageChange = (pageNumber: number): void => {
        setCurrentPaginationPage(pageNumber);
    };

    const renderPaginationButtons = (totalItems: number): JSX.Element => {
        return (
            <div className="mb-2">
                <Button
                    onClick={(): void =>
                        handlePageChange(currentPaginationPage - 1)
                    }
                    text={"<"}
                    disabled={currentPaginationPage === 0}
                    style={{ display: "inline" }}
                />
                <Text
                    props={{ style: { display: "inline", margin: "0 2rem" } }}
                >
                    Page {currentPaginationPage + 1} of{" "}
                    {Math.ceil(totalItems / paginationSize)}
                </Text>
                <Button
                    onClick={(): void =>
                        handlePageChange(currentPaginationPage + 1)
                    }
                    text={">"}
                    disabled={
                        currentPaginationPage + 1 >= totalItems / paginationSize
                    }
                    style={{ display: "inline" }}
                />
            </div>
        );
    };

    // #endregion

    // #region General bulk actions

    const supportedBulkActions = useMemo(() => {
        // avoid recalculating on every state change
        const filters = props.allInvitesFilters.statusFilters;
        const singleStatusFilter = filters.length === 1 ? filters[0] : null;
        const noStatusFilter = filters.length === 0;
        const isClinic = !!searchTerm.match(filteredToClinicRegex);
        const canDoBulkAction = (action: SupportedBulkAction): boolean => {
            switch (action) {
                case SupportedBulkAction.CancelAppointment:
                    return (
                        isClinic &&
                        (noStatusFilter ||
                            singleStatusFilter ===
                                BookedFirstOnly.filterStatus ||
                            singleStatusFilter === BookedBoth.filterStatus ||
                            singleStatusFilter === BookedBooster.filterStatus)
                    );
                case SupportedBulkAction.CancelInvite:
                    return (
                        singleStatusFilter ===
                            AwaitingSecondInvite.filterStatus ||
                        singleStatusFilter ===
                            AwaitingBoosterInvite.filterStatus ||
                        singleStatusFilter ===
                            AwaitingResponseFirst.filterStatus ||
                        singleStatusFilter ===
                            AwaitingResponseSecond.filterStatus ||
                        singleStatusFilter ===
                            AwaitingResponseBooster.filterStatus ||
                        singleStatusFilter ===
                            ToManuallyBookFirst.filterStatus ||
                        singleStatusFilter ===
                            ToManuallyBookSecond.filterStatus ||
                        singleStatusFilter ===
                            ToManuallyBookBooster.filterStatus
                    );
                case SupportedBulkAction.InviteToSecond:
                    return (
                        singleStatusFilter ===
                            AwaitingSecondInvite.filterStatus &&
                        props.allInvitesFilters.firstVaccinationTimeFilter !==
                            null
                    );
                case SupportedBulkAction.InviteToBooster:
                    return (
                        singleStatusFilter ===
                            AwaitingBoosterInvite.filterStatus &&
                        props.allInvitesFilters.secondVaccinationTimeFilter !==
                            null
                    );
                case SupportedBulkAction.SendReminder:
                    return (
                        singleStatusFilter ===
                            AwaitingResponseFirst.filterStatus ||
                        singleStatusFilter ===
                            AwaitingResponseSecond.filterStatus ||
                        singleStatusFilter ===
                            AwaitingResponseBooster.filterStatus
                    );
                default:
                    return false;
            }
        };

        return [
            SupportedBulkAction.CancelAppointment,
            SupportedBulkAction.CancelInvite,
            SupportedBulkAction.InviteToSecond,
            SupportedBulkAction.InviteToBooster,
            SupportedBulkAction.SendReminder,
        ].filter((x) => canDoBulkAction(x));
    }, [
        props.allInvitesFilters.statusFilters,
        searchTerm,
        props.allInvitesFilters.firstVaccinationTimeFilter,
    ]);

    const handleSelectMultipleChange = (
        e: ChangeEvent<HTMLSelectElement>,
    ): void => {
        const value = e.target.value as SelectMultipleOptions;
        let patients: VaccinePatientInvited[] = [];
        switch (value) {
            case SelectMultipleOptions.Select50:
                patients = props.patientList.slice(0, 50);
                break;
            case SelectMultipleOptions.Select100:
                patients = props.patientList.slice(0, 100);
                break;
            case SelectMultipleOptions.SelectAll:
                patients = props.patientList;
                break;
            default:
                break;
        }
        const newSet = new Set(patients.map((patient) => patient.inviteId));
        setSelectedPatients(newSet);
    };

    const renderSelectMultipleOptions = (): JSX.Element[] => {
        let options: SelectMultipleOptions[];
        if (props.patientList.length > 100) {
            options = Object.values(SelectMultipleOptions);
        } else if (props.patientList.length > 50) {
            options = [
                SelectMultipleOptions.Select50,
                SelectMultipleOptions.SelectAll,
            ];
        } else {
            options = [SelectMultipleOptions.SelectAll];
        }
        const dropdown = options.map((value) => (
            <option key={value} value={value}>
                {value}
            </option>
        ));
        dropdown.unshift(
            <option
                key="Select multiple patients"
                value="Select multiple patients"
                disabled
            >
                Select multiple patients
            </option>,
        );

        return dropdown;
    };

    const renderSelectMultipleDropdown = (): JSX.Element => {
        return (
            <Card spacing={1.5} props={{ className: "mb-2" }}>
                <div className="d-flex">
                    <select
                        onChange={handleSelectMultipleChange}
                        data-testid="selectMultipleDropdown"
                        className="ml-2"
                        style={{ border: "none" }}
                        defaultValue="Select multiple patients"
                    >
                        {renderSelectMultipleOptions()}
                    </select>
                </div>
            </Card>
        );
    };

    const deselectFromBulkList = (): void => {
        const newSet = new Set(selectedPatients);
        newSet.clear();
        setSelectedPatients(newSet);
    };

    const addPatientToBulkList = (
        event: React.ChangeEvent<HTMLInputElement>,
        patientId: string,
    ): void => {
        const newSet = new Set(selectedPatients);
        if (event.target.checked) {
            newSet.add(patientId);
        } else {
            newSet.delete(patientId);
        }
        setSelectedPatients(newSet);
    };

    const renderBulkActionButtons = (): JSX.Element => {
        // preserve expected render order along the top
        return (
            <>
                {renderBulkActionButton(SupportedBulkAction.CancelInvite)}
                {renderBulkActionButton(SupportedBulkAction.SendReminder)}
                {renderBulkActionButton(SupportedBulkAction.InviteToSecond)}
                {renderBulkActionButton(SupportedBulkAction.InviteToBooster)}
                {renderBulkActionButton(SupportedBulkAction.CancelAppointment)}
            </>
        );
    };

    const renderBulkActionButton = (
        action: SupportedBulkAction,
    ): JSX.Element | null => {
        if (supportedBulkActions.findIndex((x) => x === action) === -1) {
            return null;
        }

        switch (action) {
            case SupportedBulkAction.CancelAppointment:
                return (
                    <Button
                        theme="secondary"
                        icon={{ name: "Cross", colour: "red" }}
                        className="mr-2"
                        text="Cancel appointments"
                        onClick={handleBulkCancelAppointmentsButton}
                    />
                );
            case SupportedBulkAction.CancelInvite:
                return (
                    <Button
                        theme="secondary"
                        icon={{ name: "Cross" }}
                        className="mr-2"
                        text="Cancel Invites"
                        onClick={handleBulkCancelButton}
                    />
                );
            case SupportedBulkAction.InviteToSecond:
                return (
                    <Button
                        theme="secondary"
                        icon={{ name: "Send" }}
                        className="mr-2"
                        text="Invite to 2nd"
                        onClick={handleBulkSecondInviteButton}
                    />
                );
            case SupportedBulkAction.InviteToBooster:
                return (
                    <Button
                        theme="secondary"
                        icon={{ name: "Send" }}
                        className="mr-2"
                        text="Invite to booster"
                        onClick={handleBulkBoosterInviteButton}
                    />
                );
            case SupportedBulkAction.SendReminder:
                return (
                    <Button
                        theme="secondary"
                        icon={{ name: "Send" }}
                        className="mr-2"
                        text="Send a Reminder"
                        onClick={handleBulkReminderButton}
                    />
                );
            default:
                return null;
        }
    };

    const renderBulkListButtons = (): JSX.Element | null => {
        return supportedBulkActions.length ? (
            <div className="d-flex justify-content-end mb-2">
                {hasSelectedUsersForBulkAction && renderBulkActionButtons()}
                {hasSelectedUsersForBulkAction && (
                    <Card spacing={1.5}>
                        <>
                            <input
                                type="checkbox"
                                data-testid="deselectCheckbox"
                                aria-label="deselect from bulk list"
                                onChange={deselectFromBulkList}
                                style={{ marginRight: "1rem" }}
                                checked
                            />
                            <Text as="span">
                                Selected · {numIndividualSelected}
                            </Text>
                        </>
                    </Card>
                )}
                {numIndividualSelected === 0 && renderSelectMultipleDropdown()}
            </div>
        ) : null;
    };

    const renderAutumnFeedback = (): JSX.Element | null => {
        // Display a warning only if the user is on an oudated booster tab
        if (!isInviteDisabled) {
            return null;
        }

        const activeTab = isAutumn2024Enabled
            ? "Autumn Booster 2024"
            : isSpring2024Enabled
            ? "Spring Booster 2024"
            : "Autumn Booster 2023";

        return (
            <Feedback colour="warning" props={{ className: "mb-4" }}>
                {`All patients should now be invited for their Primary course or Booster using the '${activeTab}' tab.`}
            </Feedback>
        );
    };

    const hasSelectedUsersForBulkAction = numIndividualSelected > 0; // Will use for conditional display and disabling bits

    // #endregion

    // #region Bulk action - text messages

    const handleBulkReminderButton = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineBulkActionProps = {
            ...analyticsLoggedInProps,
            filterStatus: props.allInvitesFilters.statusFilters.toString(),
            numberOfInvites: selectedPatients.size.toString(),
        };
        ChainAnalyticsTracker.trackVaccineBulkAction(
            BulkActionContext.SendAReminder,
            analyticsProps,
        );
        const bulkInviteList: VaccinePatientInvited[] =
            props.patientList.filter((x) => selectedPatients.has(x.inviteId));
        props.actions.setPatientInvites({
            invites: bulkInviteList,
            action: VaccineInviteAction.Reminder,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineSmsPreview.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    const handleBulkSecondInviteButton = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineBulkActionProps = {
            ...analyticsLoggedInProps,
            filterStatus: props.allInvitesFilters.statusFilters.toString(),
            numberOfInvites: selectedPatients.size.toString(),
        };
        ChainAnalyticsTracker.trackVaccineBulkAction(
            BulkActionContext.InviteToSecond,
            analyticsProps,
        );
        const bulkInviteList: VaccinePatientInvited[] =
            props.patientList.filter((x) => selectedPatients.has(x.inviteId));
        props.actions.setPatientInvites({
            invites: bulkInviteList,
            action: VaccineInviteAction.InviteToSecondBooking,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineReviewPatients.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    const handleBulkBoosterInviteButton = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineBulkActionProps = {
            ...analyticsLoggedInProps,
            filterStatus: props.allInvitesFilters.statusFilters.toString(),
            numberOfInvites: selectedPatients.size.toString(),
        };
        ChainAnalyticsTracker.trackVaccineBulkAction(
            BulkActionContext.InviteToBooster,
            analyticsProps,
        );
        const bulkInviteList: VaccinePatientInvited[] =
            props.patientList.filter((x) => selectedPatients.has(x.inviteId));
        props.actions.setPatientInvites({
            invites: bulkInviteList,
            action: VaccineInviteAction.InviteToBoosterBooking,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineReviewPatients.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    // #endregion

    // #region Bulk action - cancel invites

    const handleBulkCancelButton = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineBulkActionProps = {
            ...analyticsLoggedInProps,
            filterStatus: props.allInvitesFilters.statusFilters.toString(),
            numberOfInvites: selectedPatients.size.toString(),
        };
        ChainAnalyticsTracker.trackVaccineBulkAction(
            BulkActionContext.CancelInvites,
            analyticsProps,
        );
        handleToggleModal();
        setIsBulkCancelInvite(true);
    };

    const handleBulkCancelInviteFinish = (): void => {
        handleToggleModal();
        setIsBulkCancelInvite(false);

        // load new and reset selected
        props.actions.getVaccineAllPatientsInvited(
            props.practiceId,
            getSearchFilter(),
        );
        deselectFromBulkList();
    };

    // #endregion

    // #region Bulk action - cancel appointments

    const handleBulkCancelAppointmentsButton = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineBulkActionProps = {
            ...analyticsLoggedInProps,
            filterStatus: props.allInvitesFilters.statusFilters.toString(),
            numberOfInvites: selectedPatients.size.toString(),
        };
        ChainAnalyticsTracker.trackVaccineBulkAction(
            BulkActionContext.CancelAppointments,
            analyticsProps,
        );
        const bulkInviteList: VaccinePatientInvited[] =
            props.patientList.filter((x) => selectedPatients.has(x.inviteId));
        props.actions.updateSearchTerm(searchTerm); // Used in csv export on the next page
        props.actions.setPatientInvites({
            invites: bulkInviteList,
            action: VaccineInviteAction.CancelAppointment,
        });
        history.push(
            ROUTES_CHAIN.practicesVaccineCancelBookings.replace(
                ":orgId",
                props.practiceId,
            ),
        );
    };

    // #endregion

    // #region Render and handle invite action buttons

    const handleBookButton = async (
        patient: VaccinePatientInvited,
        isDeclineFlow: boolean,
    ): Promise<void> => {
        //Apple blocks opening a window in an async way
        //So we open the window, then set the location after the fact
        const win = window.open("about:blank");
        setOpenLinkInFlight((prevState) => {
            const newState = { ...prevState };
            newState[patient.inviteId] = true;
            return newState;
        });
        if (isDeclineFlow) {
            ChainAnalyticsTracker.trackVaccineClickedDeclineInvite(
                analyticsLoggedInProps,
            );
        } else {
            ChainAnalyticsTracker.trackVaccineClickedBook(
                analyticsLoggedInProps,
            );
        }
        const result = await ChainApiClient.getClinicianBookingLink(
            props.practiceId,
            patient.inviteId,
            isDeclineFlow,
            patient.inviteBatchId,
        );
        if (win) {
            if (result?.result?.url) {
                win.location.assign(result.result.url);
            } else {
                win.close();
            }
        }
        setOpenLinkInFlight((prevState) => {
            const newState = { ...prevState };
            newState[patient.inviteId] = false;
            return newState;
        });
    };

    const renderManageAppointmentsButton = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <Button
                theme="secondary"
                text="Manage appointments"
                icon={{ name: "Cross" }}
                data-testid={`cancelButton${patient.inviteId}`}
                disabled={props.cancelInviteId === patient.inviteId}
                onClick={(): void => handleManageAppointmentsButton(patient)}
            />
        );
    };

    const renderManuallyBookButtons = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <>
                <Button
                    theme="secondary"
                    text="Book"
                    icon={{ name: "Calendar" }}
                    data-testid={`bookButton${patient.inviteId}`}
                    onClick={async (): Promise<void> =>
                        await handleBookButton(patient, false)
                    }
                    disabled={openLinkInFlight[patient.inviteId]}
                />
                <VaccineDeliveryMoreDropdown
                    disabled={openLinkInFlight[patient.inviteId]}
                    onDecline={async (): Promise<void> =>
                        await handleBookButton(patient, true)
                    }
                    onCancel={(): void =>
                        handleCancelButton(patient, true, false)
                    }
                />
            </>
        );
    };

    const renderAwaitingResponseButtons = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <>
                <Button
                    theme="secondary"
                    text="Send reminder"
                    icon={{ name: "Send" }}
                    data-testid={`remindButton${patient.inviteId}`}
                    onClick={(): void => handleInviteReminderButton(patient)}
                    style={{ whiteSpace: "nowrap" }}
                />
                <VaccineDeliveryMoreDropdown
                    disabled={openLinkInFlight[patient.inviteId]}
                    onDecline={async (): Promise<void> =>
                        await handleBookButton(patient, true)
                    }
                    onCancel={(): void =>
                        handleCancelButton(patient, true, false)
                    }
                    onBook={async (): Promise<void> =>
                        await handleBookButton(patient, false)
                    }
                />
            </>
        );
    };

    const renderAwaitingSecondInviteButtons = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <>
                <Button
                    theme="secondary"
                    text="Invite to 2nd"
                    icon={{ name: "Send" }}
                    data-testid={`inviteToSecondButton${patient.inviteId}`}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => handleInviteToSecondBooking(patient)}
                />
                <VaccineDeliveryMoreDropdown
                    disabled={openLinkInFlight[patient.inviteId]}
                    onCancel={(): void =>
                        handleCancelButton(patient, true, false)
                    }
                />
            </>
        );
    };

    const renderAwaitingBoosterInviteButtons = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        return (
            <>
                <Button
                    theme="secondary"
                    text="Invite to booster"
                    icon={{ name: "Send" }}
                    data-testid={`inviteToBoosterButton${patient.inviteId}`}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => handleInviteToBoosterBooking(patient)}
                />
                <VaccineDeliveryMoreDropdown
                    disabled={openLinkInFlight[patient.inviteId]}
                    onCancel={(): void =>
                        handleCancelButton(patient, true, false)
                    }
                />
            </>
        );
    };

    const renderHadBothInviteButtons = (
        patient: VaccinePatientInvited,
    ): JSX.Element => {
        // currently this is where we have an active invite and 2x jabs from NIMS, we should allow
        // users to cancel the invite as the jabs may have been done elsewhere
        return (
            <VaccineDeliveryMoreDropdown
                disabled={openLinkInFlight[patient.inviteId]}
                onCancel={(): void => handleCancelButton(patient, true, false)}
            />
        );
    };

    const renderRowButtons = (
        patientStatus: StatusLabelInfo,
        patient: VaccinePatientInvited,
    ): JSX.Element | null => {
        switch (patientStatus) {
            case Declined:
            case DeclinedBooster:
                return (
                    <Button
                        theme="secondary"
                        text="Reset"
                        icon={{ name: "Send" }}
                        data-testid={`resetButton${patient.inviteId}`}
                        disabled={props.resetStatus === UpdatingStatus.Loading}
                        onClick={(): void => handleResetButton(patient)}
                    />
                );
            case BookedBoth:
            case BookedFirstOnly:
            case BookedBooster:
                return renderManageAppointmentsButton(patient);
            case ToManuallyBookFirst:
            case ToManuallyBookSecond:
            case ToManuallyBookBooster:
                return renderManuallyBookButtons(patient);
            case AwaitingResponseFirst:
            case AwaitingResponseSecond:
            case AwaitingResponseBooster:
                return renderAwaitingResponseButtons(patient);
            case AwaitingSecondInvite:
                return renderAwaitingSecondInviteButtons(patient);
            case AwaitingBoosterInvite:
                return renderAwaitingBoosterInviteButtons(patient);
            case CompletedPrimary:
            case CompletedBooster:
                return renderHadBothInviteButtons(patient);
            case Cancelled:
            case CancelledBooster:
            case Rejected:
            case RejectedIneligible:
            default:
                return null;
        }
    };

    // #endregion

    // #region Render patient list and top bar

    const getCsvDownloadUrl = (): string => {
        const filter = getSearchFilter();
        return stringifyUrl({
            url: getVaccineInviteListCsvDownloadUrl(props.practiceId),
            query: {
                showLinkedOrgs: filter.showAllLinkedOrgs?.toString(),
                searchTerm: filter.searchTerm,
                statusFilters: filter.statusFilters?.join(","),
                daysSinceLastVaccinationMin:
                    filter.daysSinceLastVaccination?.min?.toString(),
                daysSinceLastVaccinationMax:
                    filter.daysSinceLastVaccination?.max?.toString(),
                astrazenecaFilter:
                    props.allInvitesFilters.vaccineTypeFilters.astrazeneca.toString(),
                janssenFilter:
                    props.allInvitesFilters.vaccineTypeFilters.janssen.toString(),
                modernaFilter:
                    props.allInvitesFilters.vaccineTypeFilters.moderna.toString(),
                pfizerFilter:
                    props.allInvitesFilters.vaccineTypeFilters.pfizer.toString(),
                withConflicts:
                    props.allInvitesFilters.unclearStatus?.toString(),
                course: getVaccineCourseSearchTerm(props.vaccineCourse),
            },
        });
    };

    const handleInviteClick = (nextLocation: string): void => {
        props.actions.resetState();
        history.push(nextLocation);
    };

    const renderInviteButtons = (): JSX.Element => {
        return (
            <span className="d-flex align-items-start mb-2">
                <Button
                    type="button"
                    onClick={(): void => {
                        handleInviteClick(
                            ROUTES_CHAIN.practicesVaccineInvitesUploadAndReview.replace(
                                ":orgId",
                                props.practiceId,
                            ),
                        );
                        ChainAnalyticsTracker.trackClickedInviteListOfPatients(
                            analyticsLoggedInProps,
                        );
                    }}
                    text="Invite list of patients"
                    icon={{ name: "Team" }}
                    className={"mr-2"}
                    disabled={isInviteDisabled}
                />
                <Button
                    type="button"
                    onClick={(): void => {
                        handleInviteClick(
                            ROUTES_CHAIN.practicesVaccineInvitesSingle.replace(
                                ":orgId",
                                props.practiceId,
                            ),
                        );
                    }}
                    text="Invite an individual"
                    icon={{ name: "Person" }}
                    disabled={isInviteDisabled}
                />
            </span>
        );
    };

    const handleShowNoLimit = (): void => {
        setNoPatientLimit(true);
        props.actions.getVaccineAllPatientsInvited(
            props.practiceId,
            getSearchFilter(true),
        );
        ChainAnalyticsTracker.trackVaccinePatientListRemoveDisplayLimit(
            analyticsLoggedInProps,
        );
    };

    const renderTopLine = (
        isLoading: boolean,
        showCsv: boolean,
    ): JSX.Element => {
        return (
            <div
                className="d-flex align-items-center justify-content-between mb-2 pb-2"
                style={{
                    borderBottom: `2px solid ${Tokens.COLOURS.greyscale.stone}`,
                }}
            >
                <span>
                    <Text variant="subtitle" as="span" skinny>
                        Patients ·{" "}
                    </Text>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <Text variant="subtitle" as="span" skinny>
                                {props.patientList.length}
                            </Text>
                            {displayRemoveClientLimit && (
                                <>
                                    <Text
                                        as="span"
                                        props={{ className: "ml-2 mr-2" }}
                                        skinny
                                    >{` | Show up to ${LIMIT_PATIENTS} | `}</Text>
                                    <Text
                                        as="a"
                                        variant="link"
                                        skinny
                                        props={{ onClick: handleShowNoLimit }}
                                    >
                                        Show all
                                    </Text>
                                </>
                            )}
                        </>
                    )}
                </span>
                <span className="d-flex flex-wrap align-items-center">
                    {showCsv && (
                        <ButtonLink
                            theme="secondary"
                            text="Export current view"
                            href={getCsvDownloadUrl()}
                            icon={{
                                style: "Line",
                                name: "Save",
                                colour: "blue",
                            }}
                            className="mr-2"
                        />
                    )}
                    {renderSortingOrderDropdown()}
                </span>
            </div>
        );
    };

    const renderPatientList = (): JSX.Element => {
        switch (props.getAllPatientsDetailsStatus) {
            case UpdatingStatus.Loaded:
                return (
                    <>
                        {renderTopLine(false, true)}
                        <div className="d-flex flex-wrap justify-content-between">
                            {renderInviteButtons()}
                            {renderBulkListButtons()}
                            <VaccineAllPatientsInvitedFilterFeedback
                                searchTerm={searchTerm}
                                serverLimitedResults={
                                    displayServerLimitedResults
                                }
                                onClearFilter={updateSearchTerm}
                            />
                        </div>
                        {renderAutumnFeedback()}
                        <div className="mb-2">
                            {paginated.map((p) =>
                                renderPatientRow(
                                    p,
                                    supportedBulkActions.length
                                        ? selectedPatients.has(p.inviteId)
                                        : null,
                                ),
                            )}
                        </div>
                        {renderPaginationButtons(props.patientList.length)}
                    </>
                );
            case UpdatingStatus.Loading:
            case UpdatingStatus.Initial:
                return (
                    <>
                        {renderTopLine(true, false)}
                        <Spinner />
                    </>
                );
            case UpdatingStatus.Failed:
            default:
                return (
                    <>
                        {renderTopLine(false, false)}
                        <Feedback
                            title="Something went wrong while fetching your invited patients"
                            colour="error"
                        >
                            Please refresh or contact our support team via the
                            chat in the bottom right hand corner
                        </Feedback>
                    </>
                );
        }
    };

    // #endregion

    // #region Render patient row

    const getPatientStatus = (
        patient: VaccinePatientInvited,
    ): StatusLabelInfo =>
        getPatientStatusInfo(patient.currentInviteStatus, props.vaccineCourse);

    const renderPatientBookingStatusLabel = (
        status: StatusLabelInfo,
    ): JSX.Element => {
        return (
            <span
                data-testid="statusLabel"
                className={`vaccine-coloured-label ${status.colourClass}`}
            >
                {status.supportUrl ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={status.supportUrl}
                    >
                        {status.displayText}
                    </a>
                ) : (
                    status.displayText
                )}
            </span>
        );
    };

    const renderPatientRow = (
        patient: VaccinePatientInvited,
        isSelected: boolean | null,
    ): JSX.Element => {
        const patientStatus = getPatientStatus(patient);
        const WarningMarker = () => (
            <Icon
                colour="orange"
                name="Warning"
                size={3}
                theme="Fill"
                props={{ "data-testid": `conflictMarker${patient.inviteId}` }}
            />
        );
        return (
            <Card
                key={patient.inviteId}
                footer={
                    <VaccineAllPatientsInvitedDetails
                        inviteId={patient.inviteId}
                        inviteBatchId={patient.inviteBatchId}
                        updateSearchTerm={updateSearchTermForDetails}
                        notInNetwork={patientStatus === NotInNetwork}
                        practiceId={props.practiceId}
                        searchFilters={getSearchFilter()}
                        selectedCourseTab={props.vaccineCourse}
                    />
                }
                props={{ className: "mb-2" }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-11 col-md-6 col-lg-7">
                            <Text
                                variant="label"
                                as="span"
                                props={{
                                    className: "mb-1",
                                    "data-testid": "nameColumn",
                                }}
                            >
                                {patient.patientName}
                            </Text>
                            {renderPatientBookingStatusLabel(patientStatus)}
                            {(patient.hasNimsConflict ||
                                patient.hasNbsConflict) && <WarningMarker />}
                            <div className="mt-2">
                                <Text
                                    variant="body"
                                    as="span"
                                    skinny
                                    props={{
                                        style: { display: "inline-block" },
                                        className: "pr-2 text-nowrap",
                                        "data-testid": "nhsColumn",
                                    }}
                                >
                                    NHS:{" "}
                                    {formatNhsNumber(
                                        patient.patientExternalIdentity
                                            .patientExternalIds[0].value,
                                    )}
                                </Text>
                                <Text
                                    variant="body"
                                    as="span"
                                    skinny
                                    props={{
                                        style: { display: "inline-block" },
                                        className: "pr-2 text-nowrap",
                                        "data-testid": "dobColumn",
                                    }}
                                >
                                    Born:{" "}
                                    {DateHelpers.formatDate(
                                        patient.dateOfBirth,
                                        DateFormatOptions.DATE_SHORT_WITH_SLASH,
                                    )}
                                </Text>
                                <Text
                                    variant="body"
                                    as="span"
                                    skinny
                                    props={{
                                        style: { display: "inline-block" },
                                        className: "pr-2 text-nowrap",
                                        "data-testid": "phoneColumn",
                                    }}
                                >
                                    {patient.contactNumber}
                                </Text>
                                <Text
                                    variant="body"
                                    as="span"
                                    skinny
                                    props={{
                                        style: { display: "inline-block" },
                                        className: "pr-2 text-nowrap",
                                        "data-testid": "invitedByColumn",
                                    }}
                                >
                                    Invited by: {patient.invitedBy.nationalCode}
                                </Text>
                                <Text
                                    variant="body"
                                    as="span"
                                    skinny
                                    props={{
                                        style: { display: "inline-block" },
                                        className: "pr-2 text-nowrap",
                                        "data-testid": "invitedOnColumn",
                                    }}
                                >
                                    Invited on:{" "}
                                    {DateHelpers.formatDate(
                                        patient.inviteCreatedAt,
                                        DateFormatOptions.DATE_SHORT_WITH_SLASH,
                                    )}
                                </Text>
                            </div>
                        </div>
                        <div className="col-sm-1 col-md-1 col-md-push-5 col-lg-1 col-lg-push-4">
                            {isSelected !== null && (
                                <input
                                    type="checkbox"
                                    data-testid={`selectPatient${patient.inviteId}`}
                                    onChange={(e): void =>
                                        addPatientToBulkList(
                                            e,
                                            patient.inviteId,
                                        )
                                    }
                                    checked={isSelected}
                                />
                            )}
                        </div>
                        <div className="col-sm-12 col-md-5 col-md-pull-1 col-lg-4 col-lg-pull-1">
                            <div className="d-flex flex-wrap justify-content-end">
                                {renderRowButtons(patientStatus, patient)}
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        );
    };

    // #endregion

    // #region Filtering list (before render)

    // must sort before paging for consistent ordering, cheaper to do once anyway
    const paginated = props.patientList.slice(
        currentPaginationPage * paginationSize,
        currentPaginationPage * paginationSize + paginationSize,
    );

    // #endregion

    // #region "Add to network" toast

    const handleFilterToAddedInvite = (): void => {
        dispatch(resetAddToNetworkInfo());
        props.actions.updateSearchTerm(
            `inviteId:${addingToNetworkPatientBatchInviteId}`,
        );
        props.actions.setStatusFilters([]);
    };

    // #endregion

    // #region Change vaccine course

    const handleChangeCourse = (course: VaccineCourse): void => {
        props.actions.updateCourseType(course);
        const analyticsProps: ChainAnalyticsTracker.VaccinePatientListCourseTabsChangedProps =
            {
                ...analyticsLoggedInProps,
                courseName: course,
            };
        ChainAnalyticsTracker.trackVaccinePatientListCourseTabsChanged(
            analyticsProps,
        );
    };

    const renderCourseTabs = (): JSX.Element => {
        return (
            <ul
                role="tablist"
                className="m-0 mb-2 list-unstyled d-flex"
                style={{
                    backgroundColor: `${Tokens.COLOURS.greyscale.white}`,
                    boxShadow: `Inset 0px -4px 0px 0px ${Tokens.COLOURS.greyscale.silver}`,
                }}
            >
                {isAutumn2024Enabled && (
                    <li
                        role="presentation"
                        className="p-1 px-2 position-relative d-flex align-items-center"
                        style={{
                            borderBottom: `solid 4px ${
                                props.vaccineCourse ===
                                VaccineCourse.BoosterAutumn2024
                                    ? Tokens.COLOURS.primary.blue["130"]
                                    : Tokens.COLOURS.greyscale.silver
                            }`,
                        }}
                    >
                        <Button
                            aria-selected={
                                props.vaccineCourse ===
                                VaccineCourse.BoosterAutumn2024
                            }
                            onClick={(): void =>
                                handleChangeCourse(
                                    VaccineCourse.BoosterAutumn2024,
                                )
                            }
                            role="tab"
                            text="Autumn Booster 2024"
                            theme="transparent"
                            disabled={hasSelectedUsersForBulkAction}
                        />
                    </li>
                )}
                {isSpring2024Enabled && (
                    <li
                        role="presentation"
                        className="p-1 px-2 position-relative d-flex align-items-center"
                        style={{
                            borderBottom: `solid 4px ${
                                props.vaccineCourse ===
                                VaccineCourse.BoosterSpring2024
                                    ? Tokens.COLOURS.primary.blue["130"]
                                    : Tokens.COLOURS.greyscale.silver
                            }`,
                        }}
                    >
                        <Button
                            aria-selected={
                                props.vaccineCourse ===
                                VaccineCourse.BoosterSpring2024
                            }
                            onClick={(): void =>
                                handleChangeCourse(
                                    VaccineCourse.BoosterSpring2024,
                                )
                            }
                            role="tab"
                            text="Spring Booster 2024"
                            theme="transparent"
                            disabled={hasSelectedUsersForBulkAction}
                        />
                    </li>
                )}
                {isAutumn2023Enabled && (
                    <li
                        role="presentation"
                        className="p-1 px-2 position-relative d-flex align-items-center"
                        style={{
                            borderBottom: `solid 4px ${
                                props.vaccineCourse ===
                                VaccineCourse.BoosterAutumn2023
                                    ? Tokens.COLOURS.primary.blue["130"]
                                    : Tokens.COLOURS.greyscale.silver
                            }`,
                        }}
                    >
                        <Button
                            aria-selected={
                                props.vaccineCourse ===
                                VaccineCourse.BoosterAutumn2023
                            }
                            onClick={(): void =>
                                handleChangeCourse(
                                    VaccineCourse.BoosterAutumn2023,
                                )
                            }
                            role="tab"
                            text="Autumn Booster 2023"
                            theme="transparent"
                            disabled={hasSelectedUsersForBulkAction}
                        />
                    </li>
                )}
                <li
                    role="presentation"
                    className="p-1 px-2 position-relative d-flex align-items-center"
                    style={{
                        borderBottom: `solid 4px ${
                            props.vaccineCourse ===
                            VaccineCourse.BoosterSpring2023
                                ? Tokens.COLOURS.primary.blue["130"]
                                : Tokens.COLOURS.greyscale.silver
                        }`,
                    }}
                >
                    <Button
                        aria-selected={
                            props.vaccineCourse ===
                            VaccineCourse.BoosterSpring2023
                        }
                        onClick={(): void =>
                            handleChangeCourse(VaccineCourse.BoosterSpring2023)
                        }
                        role="tab"
                        text="Spring Booster 2023"
                        theme="transparent"
                        disabled={hasSelectedUsersForBulkAction}
                    />
                </li>
                <li
                    role="presentation"
                    className="p-1 px-2 position-relative d-flex align-items-center"
                    style={{
                        borderBottom: `solid 4px ${
                            props.vaccineCourse === VaccineCourse.Booster
                                ? Tokens.COLOURS.primary.blue["130"]
                                : Tokens.COLOURS.greyscale.silver
                        }`,
                    }}
                >
                    <Button
                        aria-selected={
                            props.vaccineCourse === VaccineCourse.Booster
                        }
                        onClick={(): void =>
                            handleChangeCourse(VaccineCourse.Booster)
                        }
                        role="tab"
                        text="Boosters"
                        theme="transparent"
                        disabled={hasSelectedUsersForBulkAction}
                    />
                </li>
                <li
                    role="presentation"
                    className="p-1 px-2 position-relative"
                    style={{
                        borderBottom: `solid 4px ${
                            props.vaccineCourse === VaccineCourse.Primary
                                ? Tokens.COLOURS.primary.blue["130"]
                                : Tokens.COLOURS.greyscale.silver
                        }`,
                    }}
                >
                    <Button
                        aria-selected={
                            props.vaccineCourse === VaccineCourse.Primary
                        }
                        onClick={(): void =>
                            handleChangeCourse(VaccineCourse.Primary)
                        }
                        role="tab"
                        text="Primary course"
                        theme="transparent"
                        disabled={hasSelectedUsersForBulkAction}
                    />
                </li>
            </ul>
        );
    };

    // #endregion

    const filters = props.allInvitesFilters?.statusFilters;
    const singleStatusFilter = filters?.length === 1 ? filters[0] : null;

    const pageTitle = useMemo((): string => {
        switch (props.vaccineCourse) {
            case VaccineCourse.Booster:
                return "Boosters";
            case VaccineCourse.BoosterSpring2022:
                return "Spring Booster 2022";
            case VaccineCourse.BoosterAutumn2022:
                return "Autumn Booster 2022";
            case VaccineCourse.BoosterSpring2023:
                return "Spring Booster 2023";
            case VaccineCourse.BoosterAutumn2023:
                return "Autumn Booster 2023";
            case VaccineCourse.BoosterSpring2024:
                return "Spring Booster 2024";
            case VaccineCourse.BoosterAutumn2024:
                return "Autumn Booster 2024";
            default:
                return "Primary course";
        }
    }, [props.vaccineCourse]);

    return (
        <>
            {!props.noHeader && (
                <NavSubMenuComponent>
                    <Breadcrumb title="Invite patients" wrapper={false} />
                    <Text variant="title" as="h1" skinny className="mt-2">
                        {`accuBook - ${pageTitle}`}
                    </Text>
                </NavSubMenuComponent>
            )}
            <VaccineDeliveryCancelModal
                selectedPractice={props.practiceId.toString()}
                practices={props.practices}
                showCancelModal={showCancelModal}
                onToggleModal={handleToggleModal}
                onSubmit={handleCancelSubmit}
                isCancelInvite={isCancelInvite}
                isCancelSecondBooking={isCancelSecondBooking}
                isToManuallyBook={
                    singleStatusFilter === ToManuallyBookFirst.filterStatus ||
                    singleStatusFilter === ToManuallyBookSecond.filterStatus
                }
                isBulkFlow={isBulkCancelInvite}
                invites={
                    isBulkCancelInvite
                        ? props.patientList.filter((x) =>
                              selectedPatients.has(x.inviteId),
                          )
                        : null
                }
                onBulkFinish={handleBulkCancelInviteFinish}
            />
            <VaccineDeliveryResetModal
                showResetModal={showResetModal}
                onToggleModal={handleToggleResetModal}
                onSubmit={handleResetSubmit}
            />
            <div className="row">
                <div className="col-4 d-flex flex-column align-self-start">
                    <VaccineAllPatientsInvitedSearch
                        searchTerm={searchTerm}
                        updateSearchTerm={updateSearchTerm}
                    />
                    <VaccineAllPatientsInvitedFilters
                        numIndividualSelected={numIndividualSelected}
                        setCurrentPaginationPage={setCurrentPaginationPage}
                    />
                </div>
                <div className="col-8">
                    {renderCourseTabs()}
                    {renderPatientList()}
                </div>
            </div>
        </>
    );
};
