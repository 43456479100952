import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    CreateTemplatedQuestionRequest,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { addQuestionFromTemplate } from "api/FloreyBuilder/FloreyBuilderApi";

export const useCreateTemplatedQuestionMutation = createMutationHook<
    CreateTemplatedQuestionRequest,
    QuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["CreateTemplatedQuestion"],
    mutationFn: async ({ organisationId, ...args }) => {
        const response = await addQuestionFromTemplate(
            organisationId.toString(),
            {
                organisationId,
                ...args,
            },
        );
        return returnDataOrThrow(response);
    },
}));
