import * as UI from "@accurx/design";

export const SaveToRecordFailedFeedback = () => {
    return (
        <UI.Feedback title="Could not save message to record" colour="error">
            <UI.Text skinny>
                Please try saving to record from the sent message
            </UI.Text>
        </UI.Feedback>
    );
};
