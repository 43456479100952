import React from "react";

import { Flex } from "@accurx/design";

import { StyledButton } from "./ClinicianConversationComposeButton.styles";

type ClinicianConversationComposeButtonProps = {
    children: React.ReactNode;
    showMessageCompose: boolean;
    onWriteAnotherMessageClick: () => void;
};

export const ClinicianConversationComposeButton = ({
    children,
    showMessageCompose,
    onWriteAnotherMessageClick,
}: ClinicianConversationComposeButtonProps) => {
    const handleWriteAnotherMessageClick = () => {
        onWriteAnotherMessageClick();
    };

    if (showMessageCompose) {
        return <>{children}</>;
    }

    return (
        <Flex flexDirection={"column"}>
            <StyledButton
                text="Write another message"
                theme="secondary"
                onClick={handleWriteAnotherMessageClick}
                icon={{ name: "Pencil", colour: "blue" }}
                dimension="large"
            />
        </Flex>
    );
};
