import {
    PatientThreadEmail,
    PatientThreadNhsAppMessage,
    PatientThreadSMS,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    DeliveryStatus,
    DeliveryStatusTypes,
    PatientEmailConversationItem,
    PatientNhsAppMessageConversationItem,
    PatientSmsConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    mapBaseConversationItem,
    mapCreatedByOptionalUser,
    mapDeliveryScheduledAt,
} from "./conversationItem.helpers";

/**
 * Maps ticket SMS to conversation SMS
 *
 * @param ticketItemSMS SMS as it's sent from the API
 * @returns mapped SMS conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientSmsItem(
    ticketItemSMS: PatientThreadSMS,
): PatientSmsConversationItem | undefined {
    if (!ticketItemSMS.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItemSMS.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }

    const deliveryStatus: DeliveryStatus = mapDeliveryStatus(
        ticketItemSMS.message.deliveryStatus,
        "PatientSms",
    );

    return {
        ...mapBaseConversationItem(ticketItemSMS),
        body: ticketItemSMS.message.messageBody,
        createdBy: mapCreatedByOptionalUser(ticketItemSMS.message.sentByUserId),
        // It would be unexpected if mobileNumber wasn't there, but absence of it acceptable
        mobileNumber: ticketItemSMS.message.mobileNumber ?? "",
        contentType: "PatientSms",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItemSMS.message.deliveryScheduledAt,
        ),
    };
}

/**
 * Maps ticket email to conversation email
 *
 * @param ticketItemEmail email as it's sent from the API
 * @returns mapped email conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientEmailItem(
    ticketItemEmail: PatientThreadEmail,
): PatientEmailConversationItem | undefined {
    if (!ticketItemEmail.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItemEmail.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }

    const deliveryStatus = mapDeliveryStatus(
        ticketItemEmail.message.deliveryStatus,
        "PatientEmail",
    );

    return {
        ...mapBaseConversationItem(ticketItemEmail),
        body: ticketItemEmail.message.messageBody,
        createdBy: mapCreatedByOptionalUser(
            ticketItemEmail.message.sentByUserId,
        ),
        // It would be unexpected if emailAddress wasn't there, but absence of it acceptable
        emailAddress: ticketItemEmail.message.emailAddress ?? "",
        contentType: "PatientEmail",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItemEmail.message.deliveryScheduledAt,
        ),
    };
}

/**
 * Maps ticket nhs app messaging to conversation nhs app messaging
 *
 * @param ticketItemNHSAppMessaging NHS app messaging as it's sent from the API
 * @returns mapped email conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientNHSAppMessageItem(
    ticketItemNHSAppMessaging: PatientThreadNhsAppMessage,
): PatientNhsAppMessageConversationItem | undefined {
    if (!ticketItemNHSAppMessaging.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItemNHSAppMessaging.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }

    const deliveryStatus = mapDeliveryStatus(
        ticketItemNHSAppMessaging.message.deliveryStatus,
        "NhsAppMessage",
    );

    return {
        ...mapBaseConversationItem(ticketItemNHSAppMessaging),
        body: ticketItemNHSAppMessaging.message.messageBody,
        createdBy: mapCreatedByOptionalUser(
            ticketItemNHSAppMessaging.message.sentByUserId,
        ),
        // It would be unexpected if emailAddress wasn't there, but absence of it acceptable
        contentType: "NhsAppMessage",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItemNHSAppMessaging.message.deliveryScheduledAt,
        ),
    };
}

const mapDeliveryStatus = (
    value: string | undefined | null,
    contentType: "PatientSms" | "PatientEmail" | "NhsAppMessage",
): DeliveryStatus => {
    // extra check BE value is null or undefined.
    if (!value) {
        throw new Error(
            `Property deliveryStatus  null or undefined in ticket item, but it is required for contentType - ${contentType}`,
        );
    }
    const mappedValue = Array.from(DeliveryStatusTypes).find(
        (item: string) => item === value,
    );

    // check if the value is present in mapping type
    if (mappedValue === undefined) {
        throw new Error(
            `Property deliveryStatus value in ticket item is not present in the fe mapping types. contentType - ${contentType}`,
        );
    }

    return mappedValue;
};
