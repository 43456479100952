import { generatePath } from "react-router";

import { ROUTES_BASE } from "shared/Routes";

import {
    IntegratedClinic,
    IntegratedClinicLists,
    SelectClinicPatients,
} from "./pages";

const routes = [
    {
        path: ROUTES_BASE.integratedClinicListsAll,
        Component: IntegratedClinicLists,
        exact: true,
    },
    {
        path: ROUTES_BASE.integratedClinic,
        Component: IntegratedClinic,
        exact: true,
        isTwoFactorRequired: true,
    },
    {
        path: ROUTES_BASE.integratedClinicSelectPatients,
        Component: SelectClinicPatients,
        exact: true,
        isTwoFactorRequired: true,
    },
];

type WorkspaceId = string | number;
type ClinicCode = string;
type ClinicDay = string;

export const getClinicListsPath = ({
    workspaceId,
}: {
    workspaceId: WorkspaceId;
}) => generatePath(ROUTES_BASE.integratedClinicListsAll, { workspaceId });
export const getIndividualClinicPath = ({
    workspaceId,
    clinicCode,
    clinicDay,
    location,
}: {
    workspaceId: WorkspaceId;
    clinicCode: ClinicCode;
    clinicDay: ClinicDay;
    location: string;
}) =>
    generatePath(ROUTES_BASE.integratedClinic, {
        workspaceId,
        clinicCode,
        clinicDay,
        location,
    });

export const getClinicSelectBatchPatientsPath = ({
    workspaceId,
    clinicCode,
    clinicDay,
    location,
}: {
    workspaceId: WorkspaceId;
    clinicCode: ClinicCode;
    clinicDay: ClinicDay;
    location: string;
}) =>
    generatePath(ROUTES_BASE.integratedClinicSelectPatients, {
        workspaceId,
        clinicCode,
        clinicDay,
        location,
    });

export default routes;
