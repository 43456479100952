import { PatientSearchResultBase } from "@accurx/api/portal";
import { Flex } from "@accurx/design";

import { RecentSearch } from "./RecentSearch";

type RecentSearchesListProps = {
    recentSearches: PatientSearchResultBase[];
};

export default function RecentSearchesList({
    recentSearches,
}: RecentSearchesListProps) {
    return (
        <Flex gap="1" flexDirection="column">
            {recentSearches.map((recentSearch) => (
                <RecentSearch
                    key={recentSearch.nhsNumber}
                    patient={recentSearch}
                />
            ))}
        </Flex>
    );
}
