import { useContext } from "react";

import {
    TransportContext,
    TransportContextState,
} from "domains/transport/components/TransportContext";

export const useTransport = (): TransportContextState => {
    return useContext(TransportContext);
};
