import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPopoverContent = styled(UI.PopoverContent)`
    width: calc(100vw - calc(${UI.Tokens.SIZES[2]} * 2));
    max-height: 100%;
    transition: max-height 1.15s ease-out;

    padding: 0;

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        width: 405px;
    }
`;

const StyledHorizontalBorder = styled.hr`
    margin: 0;
`;

const StyledContactDetailsFormWrapper = styled.form`
    max-height: 300px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: ${UI.Tokens.SIZES[1]};
`;

export const StyledRadioWrapper = styled.div`
    padding: ${UI.Tokens.SIZES[1]};
    display: flex;
    flex-direction: column;
    gap: ${UI.Tokens.SIZES[1]};
`;

const StyledFormButtonsWrapper = styled(UI.Flex).attrs({
    justifyContent: "space-between",
})`
    padding: ${UI.Tokens.SIZES[1.5]};
`;

const StyledLegend = styled.legend`
    padding: ${UI.Tokens.SIZES[1]} ${UI.Tokens.SIZES[1.5]};
    margin: 0;
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    background: ${UI.Tokens.COLOURS.greyscale.cloud};
`;

export const StyledContactDetailsForm = {
    Form: StyledContactDetailsFormWrapper,
    RadioWrapper: StyledRadioWrapper,
    Legend: StyledLegend,
    HorizontalBorder: StyledHorizontalBorder,
    ButtonsWrapper: StyledFormButtonsWrapper,
};
