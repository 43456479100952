import { ChangeEvent } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useToggleNotificationsMutation } from "@accurx/concierge/hooks/mutations/useToggleNotificationsMutation";
import { TeamSummary } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { userflowIds } from "domains/inbox/util";

import {
    StyledNotificationPopoverItem,
    StyledSwitchWrapper,
} from "./NotificationsDropdown.styles";

export type NotificationsDropdownProps = {
    team: TeamSummary | undefined;
    subTitle?: string;
};

export const NotificationsDropdown = ({
    team,
    subTitle,
}: NotificationsDropdownProps) => {
    const track = useAnalytics();
    const { accessType } = useNativeTrackingFields();

    const toggleNotificationMutation = useToggleNotificationsMutation();

    // Edge case if user is a visitor they won't be able to get notifications for a team.
    if (team === undefined) {
        return null;
    }

    const isMember = !!team.isMember;

    const toggleNotificationSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        track("ConversationListNotification Button Click", {
            accessType,
            oldValue: isMember,
            newValue: !isMember,
        });

        if (!toggleNotificationMutation.isLoading) {
            toggleNotificationMutation.mutate({
                teamId: team.id,
                notify: event.target.checked,
            });
        }
    };

    const notificationAccessibleName = `${team.displayName}${
        subTitle ? ` ${subTitle}` : ""
    } notifications `;

    return (
        <UI.Popover>
            <UI.PopoverTrigger asChild>
                <Pill.Dropdown
                    radius="full"
                    data-userflow-id={
                        userflowIds.conversationList.notificationsToggle
                    }
                >
                    <Pill.Icon
                        colour={isMember ? "green" : "red"}
                        name={isMember ? "Bell" : "BellMute"}
                    />
                    <Pill.Text>
                        <UI.VisuallyHidden as="span">
                            {notificationAccessibleName}
                        </UI.VisuallyHidden>
                        {isMember ? "On" : "Off"}
                    </Pill.Text>
                </Pill.Dropdown>
            </UI.PopoverTrigger>
            <UI.PopoverContent align="start">
                <DropdownItem.ListItem>
                    <DropdownItem.TitleBar>
                        <UI.Text
                            variant="label"
                            props={{ id: "notification_popup" }}
                        >
                            <UI.VisuallyHidden as="span">
                                {team.displayName} {subTitle ? subTitle : ""}
                            </UI.VisuallyHidden>{" "}
                            Notifications
                        </UI.Text>
                    </DropdownItem.TitleBar>
                </DropdownItem.ListItem>

                <StyledNotificationPopoverItem>
                    <UI.Text>
                        Receive notifications for activity in this folder.
                    </UI.Text>
                    <StyledSwitchWrapper>
                        <UI.Switch
                            labelText={isMember ? "On" : "Off"}
                            onChange={toggleNotificationSwitch}
                            controlled
                            checked={isMember}
                            size="medium"
                            ariaLabelledby="notification_popup"
                        />
                    </StyledSwitchWrapper>
                </StyledNotificationPopoverItem>
            </UI.PopoverContent>
        </UI.Popover>
    );
};
