import { FormEvent, useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { useAuth } from "@accurx/auth";
import {
    Feedback,
    FormFieldWrapper,
    Input,
    Link,
    StackPanel,
    Text,
} from "@accurx/design";
import { Redirect, useLocation } from "react-router";

import { formatSearch } from "app/account/Account.helper";
import { LOGIN_LAYOUT_CONSTANTS } from "app/account/LogIn/LogIn.constants";
import {
    InnerStackPanel,
    OuterStackPanel,
} from "app/account/LogIn/LoginStackPanel";
import { StyledStackPanel } from "app/account/LogIn/OrganisationsLogin/OrgLogin.styles";
import { StyledFullWidthButton } from "app/account/SharedStyledComponents.styles";
import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { getEmailError } from "shared/Form.helper";
import { ROUTES, ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

// Example of link: https://web.dev.accurx.com/login/record-view?referrer=implementation
const LoginBox = (): JSX.Element => {
    const location = useLocation();

    // Set default product in case none was present
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("product", `${RegisterAccountProductType.Chain}`);

    const searchPreLogin = formatSearch({
        search: searchParams.toString(),
    });

    const { logIn, isLoading, error } = useAuth();

    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const userNameErr = getEmailError(userName);
        setUserNameError(userNameErr);

        const passwordErr = !password ? "The password field is required." : "";
        setPasswordError(passwordErr);

        if (userNameErr || passwordErr) {
            return;
        }

        logIn(
            {
                username: userName,
                password,
                isLoginOrg: true,
            },
            {
                onSuccess: () => {
                    // Let the page reload
                    // There's a bit of code in the page component
                    // That redirects to the right place with the right params
                    // When the user is logged in
                    window.location.reload();
                },
            },
        );
    };

    return (
        <InnerStackPanel horizontalContentAlignment="stretch">
            <form noValidate onSubmit={handleSubmit}>
                <StackPanel orientation="vertical" gutter={2}>
                    <Text skinny>
                        Use your Accurx Desktop details to log in.
                    </Text>
                    <FormFieldWrapper
                        label="Email address"
                        labelProps={{ htmlFor: "user-email" }}
                        errors={[userNameError]}
                    >
                        <Input
                            id="user-email"
                            value={userName}
                            type="email"
                            onChange={(e): void => setUserName(e.target.value)}
                            autoComplete="email"
                            autoFocus
                            hasErrors={!!userNameError}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Password"
                        labelProps={{ htmlFor: "user-password" }}
                        errors={[passwordError]}
                    >
                        <Input
                            id="user-password"
                            value={password}
                            type="password"
                            onChange={(e): void => setPassword(e.target.value)}
                            autoComplete="current-password"
                            hasErrors={!!passwordError}
                        />
                    </FormFieldWrapper>
                    <Link
                        href={`${ROUTES.forgotPassword}${searchPreLogin}`}
                        openInNewTab
                    >
                        <Link.Text text="Forgot password?" />
                        <Link.Icon />
                    </Link>
                    {!!error && (
                        <Feedback colour="error">
                            <Text skinny>
                                Oops! We're sorry but we could not log you in.
                            </Text>
                        </Feedback>
                    )}
                    <StyledFullWidthButton
                        type="submit"
                        theme="primary"
                        text={isLoading ? "Logging in..." : "Log in"}
                        disabled={isLoading}
                    />
                </StackPanel>
            </form>
        </InnerStackPanel>
    );
};

export const RecordViewLogin = (): JSX.Element => {
    const location = useLocation();
    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    if (isLoggedIn) {
        const params = new URLSearchParams(location.search);
        params.append("next", ROUTES_CHAIN.practices);
        // Needed so that when the user selects the practice, they go to the RV opt in page
        params.append("dest", "record-view-opt-in");

        // Redirect logged in users to
        // https://web.dev.accurx.com/postlogin?referrer=implementation&next=practices&dest=record-view-opt-in
        return (
            <Redirect
                to={{
                    ...location,
                    pathname: ROUTES.postlogin,
                    search: params.toString(),
                }}
            />
        );
    }

    return (
        <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.recordView}>
            <OuterStackPanel>
                <StyledStackPanel
                    orientation="vertical"
                    gutter={2}
                    horizontalContentAlignment="center"
                >
                    <Text variant="title" as="h1" skinny>
                        Get started with Record View
                    </Text>
                    <LoginBox />
                </StyledStackPanel>
            </OuterStackPanel>
        </LoginPageLayout>
    );
};
