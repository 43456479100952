import { SingleSelect, Table, Text, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledTabPanelLayout = styled.div`
    margin: ${Tokens.SIZES[4]} 0;
`;

export const StyledSelect = styled(SingleSelect)`
    width: 30rem;
`;

export const StyledTextFormattingNewlines = styled(Text)`
    white-space: pre-wrap;
`;

const sharedCellStyles = css`
    max-width: 40rem;
    padding: ${Tokens.SIZES[2]};
`;

export const StyledTHead = styled.thead`
    background-color: ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledTBody = styled.tbody``;
const borderStyle = `1px solid ${Tokens.COLOURS.greyscale.silver}`;

export const StyledTR = styled.tr`
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-top: ${borderStyle};
    border-left: ${borderStyle};
    border-right: ${borderStyle};
    :last-of-type {
        border-bottom: ${borderStyle};
    }
`;

export const StyledTH = styled.th`
    ${sharedCellStyles}
`;
export const StyledTD = styled.td`
    ${sharedCellStyles}
`;

// To be used by text below the table, to have correct spacing styles matching the table cell.
export const StyledSpacedItem = styled.span`
    ${sharedCellStyles}
`;

// Standard Visibility Hidden styles
// TODO: to move out to accurx-design as shared styling.
export const StyledVisibilityHidden = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
`;

export const StyledTableBody = styled(Table.Body)`
    vertical-align: top;
`;
