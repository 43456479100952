import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const SetupPatientTriageContainer = styled.div`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const SetupPatientTriageCopyButtonWrapper = styled.div`
    margin-top: 2rem;

    button {
        width: 100%;
        justify-content: space-between;
        background: ${Tokens.COLOURS.greyscale.dishwater};
        box-shadow: none;

        &:before {
            border: none;
        }
        span {
            font-weight: normal;
        }
    }
`;

export const SetupPatientTriageArticleWrapper = styled.div`
    margin: 3rem 0;

    button {
        width: 100%;
        margin-bottom: 0.25rem;
        box-shadow: inset 0px -2px 0px ${Tokens.COLOURS.greyscale.silver};
    }
`;
