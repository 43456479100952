import { Patient } from "@accurx/api/portal";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useAppSelector } from "store/hooks";

import { PageHeader, PageHeaderType } from "../../layout/PageHeader";
import ConfirmMessageLayout from "../../sharedComponents/confirmMessageLayout/ConfirmMessageLayoutComponent";
import MessagePreview from "../../sharedComponents/messagePreview/MessagePreviewComponent";

const CopyButtonAnalyticsContext =
    FlemingAnalyticsTracker.CopyButtonAnalyticsContext;

export interface ConfirmMessageProps {
    patient: Patient;
    resetProductChoice: () => void;
}

const ConfirmMessage = ({
    patient,
    resetProductChoice,
}: ConfirmMessageProps) => {
    const messageBody = useAppSelector(
        ({ messagePractice }) => messagePractice.messageInfoSent.messageBody,
    );
    const userName = useAppSelector(
        ({ account }) => account.user?.fullName || undefined,
    );

    return (
        <>
            <PageHeader type={PageHeaderType.PatientPage} title={""} />
            <ConfirmMessageLayout
                title={`Your message to ${
                    patient.practiceName || patient.practiceCode
                } about ${patient.displayName} (${
                    patient.nhsNumber
                }) has been successfully sent!`}
                handleGoBack={resetProductChoice}
            >
                <MessagePreview
                    messageCopy={{
                        sentTo: patient.practiceName || undefined,
                        messageText: messageBody,
                        sentBy: userName,
                        dateTime: DateHelpers.formatTime(
                            DateHelpers.getCurrentTimeStamp(),
                            DateFormatOptions.TIME_DATE_SHORT,
                        ),
                    }}
                    copyButtonAnalyticsContext={
                        CopyButtonAnalyticsContext.accuMail
                    }
                />
            </ConfirmMessageLayout>
        </>
    );
};

export default ConfirmMessage;
