import { returnDataOrThrow } from "@accurx/api/QueryClient";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import FlemingApi from "api/FlemingApiClient";

import { PatientListSummary } from "../../../api/FlemingDtos";

type PatientListSummariesQueryVariables = {
    workspaceId: number | null;
};

export const PATIENT_LIST_SUMMARY_KEY = "PatientListSummaries";

export const usePatientListSummariesQuery = ({
    workspaceId,
}: PatientListSummariesQueryVariables): UseQueryResult<
    PatientListSummary[],
    Error
> =>
    useQuery({
        queryKey: [PATIENT_LIST_SUMMARY_KEY, workspaceId],
        queryFn: async () => {
            const response = await FlemingApi.getAllUserPatientListSummaries({
                organisationId: workspaceId,
            });
            return returnDataOrThrow(response);
        },
        enabled: workspaceId !== null,
        // Patient list modifications don't currently update react query state, because they are built on redux, so it's safer to always refetch on mount for now
        refetchOnMount: "always",
    });
