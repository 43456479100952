import { TwoFactorContext } from "./TwoFactor";

export enum TwoFactorMethod {
    Email = "email",
    Mobile = "mobile",
}

export type TwoFactorStepProps = {
    prevStep: (() => void) | null;
    nextStep: (() => void) | null;
};

export type TwoFactorRegisterSubmitStepProps = {
    prevStep: (() => void) | null;
    nextStep: ((value: string) => void) | null;
};

export type TwoFactorVerifySubmitStepProps = {
    prevStep: (() => void) | null;
    nextStep: ((code: string, remember: boolean) => void) | null;
};

export type TwoFactorRequestStepProps = {
    twoFactorMethod: {
        chosenMethod: TwoFactorMethod;
        obfuscatedMethod: string;
    };
    error: string;
    requestLoading: boolean;
};

export type TwoFactorContextProps = {
    context: TwoFactorContext;
};

// Generic interface used by user two factor as well as Record View patient two factor
export type SendConfirmCodeProps = {
    isLoading: boolean;
    error: string;
    text: {
        title?: JSX.Element;
        mainText?: JSX.Element;
        submitButtonText: string;
    };
    cancelButton?: JSX.Element;
};
