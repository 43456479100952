import {
    PatientRecordViewGetRecordRequest,
    PatientRecordViewRequest,
    RecordViewRequest,
} from "api/FlemingDtos";
import {
    getExampleRecord,
    getRecord,
    getRecordNoAuth,
} from "api/RecordViewApi";
import { FlemingAnalyticsTracker } from "app/analytics";

// ACTION TYPES

export const GET_MEDICAL_RECORD_NO_AUTH_STARTED =
    "GET_MEDICAL_RECORD_NO_AUTH_STARTED";
export const GET_MEDICAL_RECORD = "GET_MEDICAL_RECORD";
export const GET_EXAMPLE_MEDICAL_RECORD = "GET_EXAMPLE_MEDICAL_RECORD";
export const RESET_RECORD_ACCESS = "RESET_RECORD_ACCESS";
export const SET_VIEW_MEDICAL_RECORD_ORIGIN = "SET_VIEW_MEDICAL_RECORD_ORIGIN";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface GetMedicalRecordNoAuthStartedAction {
    type: typeof GET_MEDICAL_RECORD_NO_AUTH_STARTED;
}

interface GetMedicalRecordAction {
    type: typeof GET_MEDICAL_RECORD;
    record: RecordViewRequest;
}

interface ResetRecordView {
    type: typeof RESET_RECORD_ACCESS;
}

interface SetViewMedicalRecordOrigin {
    type: typeof SET_VIEW_MEDICAL_RECORD_ORIGIN;
    origin: FlemingAnalyticsTracker.MedicalRecordViewOrigin;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | GetMedicalRecordNoAuthStartedAction
    | GetMedicalRecordAction
    | ResetRecordView
    | SetViewMedicalRecordOrigin;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getRecord:
        (
            request: PatientRecordViewGetRecordRequest,
        ): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            const response = await getRecord(request);

            dispatch({
                type: GET_MEDICAL_RECORD,
                record: response,
            });
        },
    getExampleRecord:
        (request: number | null): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            const response = await getExampleRecord(request);
            dispatch({
                type: GET_MEDICAL_RECORD,
                record: response,
            });
        },
    getRecordNoAuth:
        (request: PatientRecordViewRequest): AppThunkAction<KnownAction> =>
        async (dispatch): Promise<void> => {
            dispatch({ type: GET_MEDICAL_RECORD_NO_AUTH_STARTED });

            const response = await getRecordNoAuth(request);

            return dispatch({
                type: GET_MEDICAL_RECORD,
                record: response,
            });
        },
    resetRecordView: () => ({
        type: RESET_RECORD_ACCESS,
    }),
    setViewMedicalRecordOrigin: (
        origin: FlemingAnalyticsTracker.MedicalRecordViewOrigin,
    ) => ({
        type: SET_VIEW_MEDICAL_RECORD_ORIGIN,
        origin,
    }),
};
