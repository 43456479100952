import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { shardedMap } from "domains/concierge/internal/util/shardedMap";
import { Conversation } from "domains/concierge/types";
import { Draft } from "immer";

export const getConversationFreshAt = (
    state: Draft<Pick<ConversationsState, "conversationFreshAt">>,
    id: string,
): string | undefined => {
    return shardedMap.getValue(state.conversationFreshAt, id);
};

/**
 * Warning - this mutates the state argument. Only use it with immer
 */
export const setConversationFreshAt = (
    state: Draft<Pick<ConversationsState, "conversationFreshAt">>,
    conversationId: Conversation["id"],
    freshAt: string,
) => {
    shardedMap.setValue(state.conversationFreshAt, conversationId, freshAt);
};

/**
 * Warning - this mutates the state argument. Only use it with immer
 */
export const removeConversationFreshAt = (
    state: Draft<Pick<ConversationsState, "conversationFreshAt">>,
    conversationId: Conversation["id"],
) => {
    shardedMap.removeValue(state.conversationFreshAt, conversationId);
};
