import { Tokens } from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES } = Tokens;

export const StyledIndicator = styled.span<{
    $color: "green" | "white";
}>`
    display: block;
    width: ${SIZES[1]};
    height: ${SIZES[1]};
    border-radius: 50%;

    ${(props) => {
        if (props.$color === "green") {
            return `
                background-color: ${COLOURS.primary.green[100]};
                box-shadow: 0px 0px 0px 1px ${COLOURS.greyscale.white};
            `;
        } else {
            return `
                background-color: ${COLOURS.greyscale.white};
                box-shadow: 0px 0px 0px 1px ${COLOURS.greyscale.white}, inset 0px 0px 0px 1px ${COLOURS.greyscale.metal};
            `;
        }
    }}
`;
