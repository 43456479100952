import { Dispatch } from "redux";

import ChainApiClient from "api/VaccineApiHelper";
import {
    Actions as PracticesActions,
    ExtendedActions as PracticesExtendedActions,
} from "app/practices/Practices.actions";

import {
    VaccineAddToNetworkRequest,
    VaccinePatientInvite,
    VaccinePatientNotesRequest,
    VaccinePatientStatusUpdate,
} from "../models/VaccineAllPatientsInvitedDTO";
import { NbsData } from "../models/VaccineDeliveryDTO";
import { NimsData } from "../models/VaccineSharedDTO";

// -----------------
// ACTION TYPES
// -----------------
export enum Actions {
    GET_PATIENT_INVITE_DETAILS_STARTED = "GET_PATIENT_INVITE_DETAILS_STARTED",
    GET_PATIENT_INVITE_DETAILS_SUCCESS = "GET_PATIENT_INVITE_DETAILS_SUCCESS",
    GET_PATIENT_INVITE_DETAILS_FAILURE = "GET_PATIENT_INVITE_DETAILS_FAILURE",
    POST_PATIENT_INVITE_NOTES_STARTED = "POST_PATIENT_INVITE_NOTES_STARTED",
    POST_PATIENT_INVITE_NOTES_SUCCESS = "POST_PATIENT_INVITE_NOTES_SUCCESS",
    POST_PATIENT_INVITE_NOTES_FAILURE = "POST_PATIENT_INVITE_NOTES_FAILURE",
    REFRESH_NIMS_DATA_STARTED = "REFRESH_NIMS_DATA_STARTED",
    REFRESH_NIMS_DATA_SUCCESS = "REFRESH_NIMS_DATA_SUCCESS",
    REFRESH_NIMS_DATA_FAILURE = "REFRESH_NIMS_DATA_FAILURE",
    ADD_TO_NETWORK_STARTED = "ADD_TO_NETWORK_STARTED",
    ADD_TO_NETWORK_SUCCESS = "ADD_TO_NETWORK_SUCCESS",
    ADD_TO_NETWORK_FAILURE = "ADD_TO_NETWORK_FAILURE",
    RESET_INVITE_NOTE_ERROR = "RESET_INVITE_NOTE_ERROR",
    RESET_NIMS_DATA_ERROR = "RESET_NIMS_DATA_ERROR",
    RESET_ADD_TO_NETWORK_INFO = "RESET_ADD_TO_NETWORK_INFO",
    UPDATE_PATIENT_INVITE_STATUS = "UPDATE_PATIENT_INVITE_STATUS",
}

type ActionFailure = { error: string };

type DefaultActions = BaseActions<typeof Actions>;
type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.GET_PATIENT_INVITE_DETAILS_SUCCESS]: {
            invites: VaccinePatientInvite[];
            nimsData: NimsData;
            nbsData: NbsData;
        };
        [Actions.GET_PATIENT_INVITE_DETAILS_STARTED]: { inviteId: string };
        [Actions.GET_PATIENT_INVITE_DETAILS_FAILURE]: ActionFailure;
        [Actions.POST_PATIENT_INVITE_NOTES_STARTED]: { inviteId: string };
        [Actions.POST_PATIENT_INVITE_NOTES_FAILURE]: ActionFailure;
        [Actions.REFRESH_NIMS_DATA_FAILURE]: ActionFailure;
        [Actions.ADD_TO_NETWORK_SUCCESS]: { inviteBatchId: string };
        [Actions.ADD_TO_NETWORK_FAILURE]: ActionFailure;
        [Actions.UPDATE_PATIENT_INVITE_STATUS]: {
            update: VaccinePatientStatusUpdate;
        };
    }
>;

export type KnownAction =
    | ExtendedActions[keyof ExtendedActions]
    | PracticesExtendedActions[PracticesActions.SET_SELECTED_PRACTICE];

interface UrlParamsPatientDetails {
    organisationId: string;
    inviteId: string;
}

interface UrlParamsPatientNotes {
    organisationId: string;
    inviteBatchId: string;
    inviteId: string;
}

interface UrlParamsRefreshNims {
    organisationId: string;
    inviteBatchId: string;
    inviteId: string;
}

export interface UrlParamsAddToNetwork {
    organisationId: string;
}

export const getPatientDetails = ({
    organisationId,
    inviteId,
}: UrlParamsPatientDetails) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({
            type: Actions.GET_PATIENT_INVITE_DETAILS_STARTED,
            inviteId,
        });

        const { success, result } = await ChainApiClient.getPatientDetails(
            organisationId,
            inviteId,
        );
        if (success && result?.invites) {
            return dispatch({
                type: Actions.GET_PATIENT_INVITE_DETAILS_SUCCESS,
                invites: result?.invites,
                nimsData: result?.nimsData,
                nbsData: result?.nbsData,
            });
        }
        return dispatch({
            type: Actions.GET_PATIENT_INVITE_DETAILS_FAILURE,
            error: 'Something went wrong getting this patient’s details. To retry, please close the "Details and notes" section and try again',
        });
    };
};

export const postPatientNotes = (
    { organisationId, inviteBatchId, inviteId }: UrlParamsPatientNotes,
    request: VaccinePatientNotesRequest,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.POST_PATIENT_INVITE_NOTES_STARTED, inviteId });

        const { success } = await ChainApiClient.postVaccinePatientNotes(
            organisationId,
            inviteBatchId,
            inviteId,
            request,
        );
        if (success) {
            return dispatch({
                type: Actions.POST_PATIENT_INVITE_NOTES_SUCCESS,
            });
        }
        return dispatch({
            type: Actions.POST_PATIENT_INVITE_NOTES_FAILURE,
            error: "Something went wrong saving this patient’s notes. Please try again",
        });
    };
};

export const refreshNimsData = ({
    organisationId,
    inviteBatchId,
    inviteId,
}: UrlParamsRefreshNims) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.REFRESH_NIMS_DATA_STARTED });

        const { success } = await ChainApiClient.postRefreshNimsData(
            organisationId,
            inviteBatchId,
            inviteId,
        );
        if (success) {
            return dispatch({
                type: Actions.REFRESH_NIMS_DATA_SUCCESS,
            });
        }
        return dispatch({
            type: Actions.REFRESH_NIMS_DATA_FAILURE,
            error: "Unable to refresh Vaccination data, please try again later",
        });
    };
};

export const addPatientToNetwork = (
    params: UrlParamsAddToNetwork,
    request: VaccineAddToNetworkRequest,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.ADD_TO_NETWORK_STARTED });
        const response = await ChainApiClient.addPatientToNetwork(
            params.organisationId,
            request,
        );
        if (response.result?.id && response.success) {
            return dispatch({
                type: Actions.ADD_TO_NETWORK_SUCCESS,
                inviteBatchId: response.result.id,
            });
        }
        return dispatch({
            type: Actions.ADD_TO_NETWORK_FAILURE,
            error:
                response.error ||
                "Unable to add to network, please refresh and try again",
        });
    };
};

export const resetInviteNoteError = () => ({
    type: Actions.RESET_INVITE_NOTE_ERROR,
});

export const resetNimsDataError = () => ({
    type: Actions.RESET_NIMS_DATA_ERROR,
});

export const resetAddToNetworkInfo = () => ({
    type: Actions.RESET_ADD_TO_NETWORK_INFO,
});
