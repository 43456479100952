import {
    Conversation,
    ConversationItem,
    FailedDeliveryReceiptLinkItem,
    UserSummary,
} from "domains/concierge/types";
import findLast from "lodash/findLast";
import keyBy from "lodash/keyBy";

export const getMostRecentFailedItem = (
    conversation: Conversation,
    senderId?: UserSummary["id"],
): ConversationItem | undefined => {
    const itemMap = keyBy(conversation.items, "id");

    const deliveryReceipt = findLast(
        conversation.items,
        (item): item is FailedDeliveryReceiptLinkItem => {
            if (item.contentType !== "FailedDeliveryReceiptLink") {
                return false;
            }

            if (senderId !== undefined) {
                const linkedItem = itemMap[item.linkedItemId];
                return (
                    linkedItem &&
                    linkedItem.createdBy.type === "User" &&
                    linkedItem.createdBy.id === senderId
                );
            }

            return true;
        },
    );

    if (deliveryReceipt) {
        const failedItem = itemMap[deliveryReceipt.linkedItemId];
        return failedItem ?? deliveryReceipt;
    }

    return undefined;
};
