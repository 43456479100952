import { UnreadTickets } from "@accurx/api/ticket";
import { getApiEndpoint, httpClient } from "@accurx/shared";

export const getUnreadTickets = async (
    workspaceId: number,
): Promise<UnreadTickets> => {
    const response = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/conversation/web/unreadtickets",
            query: `?organisationId=${workspaceId}`,
        }),
    );
    if (!response.success) {
        throw new Error(response.error ?? "Error fetching unread tickets");
    }
    return response.result;
};
