import styled from "styled-components";

const StyledImg = styled.img`
    width: 6.5rem;
    height: auto;
`;

export const StyledCheckYourInbox = {
    Img: StyledImg,
};
