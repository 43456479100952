import { Tokens } from "@accurx/design";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";
import styled from "styled-components";

const { BORDERS, BORDER_ALTERNATIVE, COLOURS, SHADOWS, SIZES, TYPOGRAPHY } =
    Tokens;

type TextAreaAutosizeProps = {
    disabled: boolean;
    hasErrors?: boolean;
};

export const StyledTextareaAutosize = styled(
    TextareaAutosize,
)<TextAreaAutosizeProps>`
    ::placeholder {
        ${TYPOGRAPHY.body.base}
        color: ${COLOURS.greyscale.stone};
    }
    border: ${BORDERS.normal.silver};
    border-radius: ${BORDERS.radius};
    padding: ${SIZES[2]};
    background-color: ${COLOURS.greyscale.white};
    // Reset the box shadow implemented in the GlobalStyles - it will be replaced by the StyledInputBorder styling
    &:focus {
        box-shadow: none;
    }
    &:before {
        ${BORDER_ALTERNATIVE}
        z-index: 0;
        border-radius: ${BORDERS.radius};
        transition: all 0.2s ease;
    }
    &:hover, &:focus {
        &:before {
            border: ${BORDERS.normal.silver};
            boxShadow: ${SHADOWS.lowered.deep.silver};
        }
    }
    &:disabled {
        background-color: ${COLOURS.greyscale.cloud};
        &:before {
            border: ${BORDERS.normal.metal},
            boxShadow: ${SHADOWS.lowered.deep.metal};
        }
    }
    width: 100%;
    color: ${(props: TextAreaAutosizeProps) =>
        props.disabled ? Tokens.COLOURS.greyscale.stone : "inherit"};
`;
