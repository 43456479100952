import {
    Conversation,
    NhsAppMessageItem,
    PatientEmailItem,
    PatientSmsItem,
    UserSummary,
} from "domains/concierge/types";
import findLast from "lodash/findLast";

type SentItemType = PatientEmailItem | PatientSmsItem | NhsAppMessageItem;

// we would like to select the latest sms, email or nhsapp message;
// delivery status of the last sent item doesn't matter.
export const getMostRecentSentItem = (
    conversation: Conversation,
    senderId?: UserSummary["id"],
): SentItemType | undefined => {
    const sentItem = findLast(
        conversation.items,
        (item): item is SentItemType => {
            if (
                item.contentType !== "PatientEmail" &&
                item.contentType !== "PatientSms" &&
                item.contentType !== "NhsAppMessage"
            ) {
                return false;
            }

            if (senderId !== undefined) {
                return (
                    item.createdBy.type === "User" &&
                    item.createdBy.id === senderId
                );
            }

            return true;
        },
    );

    return sentItem;
};
