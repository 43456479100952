import * as UI from "@accurx/design";
import styled from "styled-components";

export const PADDING_SIZE = 12;
export const GUTTER_SIZE = 8;

export const StyledContainer = styled(UI.Cell)`
    padding: 0px;
    background-color: ${UI.Tokens.COLOURS.greyscale.cloud};
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StyledLoadingStateContainer = styled(StyledContainer)`
    padding: ${PADDING_SIZE}px;
`;

export const StyledErrorStateContainer = styled(UI.Flex)`
    padding: ${PADDING_SIZE}px;
    text-align: center;
`;

export const StyledConversationFeed = styled.div`
    list-style: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: ${UI.Tokens.SIZES[1]};
`;

export const StyledListContainer = styled.div`
    position: relative;
`;

export const StyledList = styled.ol`
    margin: 0;
`;

export const StyledListItem = styled.li<{ $top: number }>`
    list-style: none;
    padding-left: ${PADDING_SIZE}px;
    padding-right: ${PADDING_SIZE}px;

    position: absolute;
    top: ${(props) => `${props.$top + PADDING_SIZE}px`};
    left: 0px;
    width: 100%;
`;

export const StyledLoadMoreButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: ${PADDING_SIZE * 3}px;
    padding-top: ${PADDING_SIZE}px;
`;

export const StyledLoadMoreButton = styled.button``;
