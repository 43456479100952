import { useEffect, useState } from "react";

import { Button, Ds, Flex, Text } from "@accurx/design";
import {
    useAcknowledgeMedicalRecordUserChangeMutation,
    useMedicalRecordConnection,
} from "@accurx/native";

export const LogoutMedicalRecordModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { system, loggedInUsersDetails, hasMedicalRecordUserChanged } =
        useMedicalRecordConnection();

    const { mutate: acknowledgeMedicalRecordUserChange } =
        useAcknowledgeMedicalRecordUserChangeMutation();

    useEffect(() => {
        setIsOpen(!!hasMedicalRecordUserChanged);
    }, [hasMedicalRecordUserChanged]);

    const onChangeLogin = () => {
        acknowledgeMedicalRecordUserChange({ logout: true });
        setIsOpen(false);
    };

    const onContinue = () => {
        acknowledgeMedicalRecordUserChange({ logout: false });
        setIsOpen(false);
    };

    if (!system) {
        return null;
    }

    const loggedInUserName = loggedInUsersDetails.accuRxUserName;
    return (
        <Ds.Modal open={isOpen} onOpenChange={setIsOpen}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        {`Continue as '${loggedInUserName}'?`}
                    </Ds.Modal.Title>
                    <Text>
                        {`The account logged into ${system} has changed. You are logged into Accurx as '${loggedInUserName}'. Do you want to continue as '${loggedInUserName}'?`}
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            type="button"
                            data-testid="secondary-button"
                            onClick={onChangeLogin}
                            text="No, change login"
                        />
                        <Button
                            theme="primary"
                            type="button"
                            data-testid="primary-button"
                            onClick={onContinue}
                            text="Yes, continue"
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
