import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { SelfBookLink as SelfBookLinkType } from "domains/compose/reducer.types";

import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";
import { getAppointmentTypeDisplay } from "./selfBookLinkGetAppointmentTypeDisplay";
import { getClinicianNamesDisplay } from "./selfBookLinkGetClinicianNamesDisplay";

export const SelfBookLink = ({
    selfBookLink,
    onRemoveClick,
}: {
    selfBookLink: SelfBookLinkType;
    onRemoveClick?: () => void;
}) => {
    const clinicianNames = selfBookLink.specificClinician
        ? selfBookLink.specificClinician.flatMap(
              ({ clinicianNames }) => clinicianNames,
          )
        : [];
    const clinicianNamesDisplay = getClinicianNamesDisplay(clinicianNames);
    const appointmentTypeDisplay = getAppointmentTypeDisplay(
        selfBookLink.appointmentType,
    );

    return (
        <StyledActionStack.Row
            justifyContent={"space-between"}
            alignItems={"center"}
            gap="1"
        >
            <UI.Flex gap="1" alignItems="center">
                <BodyInfoBox.Icon name="Calendar" />
                <BodyInfoBox.Text colour="night">
                    Self-Book:{" "}
                    {selfBookLink.crossOrgBooking ? (
                        <strong>
                            {selfBookLink.crossOrgBooking.targetOdsName} for{" "}
                            {selfBookLink.slotType} ({appointmentTypeDisplay})
                        </strong>
                    ) : (
                        <>
                            <strong>{selfBookLink.slotType}</strong> (
                            {appointmentTypeDisplay})
                        </>
                    )}{" "}
                    with {clinicianNamesDisplay}.
                </BodyInfoBox.Text>
            </UI.Flex>
            {onRemoveClick !== undefined && (
                <StyledActionStack.SquareIconButton
                    onClick={onRemoveClick}
                    title={"Remove Self-Book link"}
                >
                    <Pill.Icon name="Cross" colour="red" size={3} />
                </StyledActionStack.SquareIconButton>
            )}
        </StyledActionStack.Row>
    );
};
