import { useRef } from "react";

import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    ClinicianConversation,
    ClinicianConversationApi,
} from "api/ClinicianConversationApi";

export const CLINICIAN_CONVERSATION_BY_WORKSPACE_CONVERSATION_ID_CACHE_KEY =
    "ClinicianConversationByWorkspaceConversationId";

type ClinicianConversationByWorkspaceConversationIdQueryVariables = {
    workspaceId: string;
    workspaceConversationId: string;
};

export const useClinicianConversationByWorkspaceConversationIdQuery =
    createQueryHook<
        ClinicianConversationByWorkspaceConversationIdQueryVariables,
        ClinicianConversation & { hasPolled: boolean }
    >(({ workspaceId, workspaceConversationId }) => {
        const requestCount = useRef(0);
        return {
            cacheTime: 0,
            queryKey: [
                CLINICIAN_CONVERSATION_BY_WORKSPACE_CONVERSATION_ID_CACHE_KEY,
                { workspaceId, workspaceConversationId },
            ],
            queryFn: async () => {
                const response =
                    await ClinicianConversationApi.getConversationByWorkspaceConversationId(
                        workspaceConversationId,
                        workspaceId,
                    );
                const data = returnDataOrThrow(response);

                requestCount.current = requestCount.current + 1;
                const hasPolled = requestCount.current > 1;

                return { ...data, hasPolled };
            },
        };
    });
