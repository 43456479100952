import { ChangeEvent } from "react";

import {
    MessageTemplateAttachment,
    SnomedCodeFull2,
} from "@accurx/api/patient-messaging";

type SnomedCode = SnomedCodeFull2;
type TemplateFolder = { name: string };
export type TemplatePath = {
    folders: TemplateFolder[];
};
export type Template = {
    allowReplyByDefault: boolean;
    body: string; // formatted string
    id: string; // stringified number
    isAllowedAsBatch: boolean;
    isAllowedAsSms: boolean;
    isDefault: boolean;
    owner: string;
    path: TemplatePath;
    snomedCodes: SnomedCode[];
    title: string;
    type: string;
    attachments: MessageTemplateAttachment[];
};

export type DisplayTemplate = Template & {
    categoryHeading: string; // constructed from the template path
};

export enum SendType {
    Batch = "Batch",
    Sms = "Sms",
}
export type OnChangeSendType = {
    sendType: SendType;
    templateId: string;
    owner: string;
    event: ChangeEvent<HTMLInputElement>;
};

export enum ManageTemplatesActionType {
    Edit = "edit",
    Create = "create",
    Delete = "delete",
}

export type PageUrlParams = {
    isOrganisationTemplate?: string; // stringified boolean
    userTemplateId?: string; // stringified number
};
