import { useState } from "react";

import { Button, Card, Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import FlemingApiClient from "api/FlemingApiClient";
import { signoutEverywhere } from "api/SettingsApi";

import { restartApp } from "../../../appLifecycle";

type SettingsSecurityProps = {
    hideHeader?: boolean;
    isChain: boolean;
};

const defaultErrorMsg =
    "Something went wrong signing you out. Please try again.";

export const SettingsSecurity = ({
    hideHeader,
    isChain,
}: SettingsSecurityProps) => {
    useAccurxWebTitle("Manage security settings");

    const [errorMsg, setErrorMsg] = useState("");

    const handleSignOut = async (): Promise<void> => {
        // Signout out any other devices, will also trigger webhook to logout other users
        const res = await signoutEverywhere();
        if (!res.success || res.error) {
            setErrorMsg(res.error || defaultErrorMsg);
            return;
        }

        window.Intercom && window.Intercom("shutdown");
        // Signout current browser from web/fleming
        const resFleming = await FlemingApiClient.logout();
        if (!resFleming.success) {
            setErrorMsg(defaultErrorMsg);
            return;
        }
        restartApp(isChain);
    };

    return (
        <Card
            header={
                !hideHeader ? (
                    <Text variant="subtitle" as="h2" skinny>
                        Security
                    </Text>
                ) : undefined
            }
        >
            <>
                <Text>
                    Sign out wherever you have Accurx open, including the web,
                    desktop or any other computers.
                </Text>
                {errorMsg && (
                    <Feedback
                        title={errorMsg}
                        colour="error"
                        props={{ className: "mb-3" }}
                    />
                )}
                <Button text="Sign out everywhere" onClick={handleSignOut} />
            </>
        </Card>
    );
};
