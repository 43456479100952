import {
    PopoverContent,
    PopoverItem,
    PopoverItemButton,
    Tokens,
} from "@accurx/design";
import { TokenOverrides } from "domains/navigation/styles/tokenOverrides";
import styled from "styled-components";

export const StyledPopoverContent = styled(PopoverContent)`
    max-height: 500px;
    width: 248px;
    border-radius: ${TokenOverrides.BORDERS.radius};
`;

export const StyledPopoverItem = styled(PopoverItemButton)`
    gap: ${Tokens.SIZES[1]};
    margin: 0;
    text-align: left;

    & p {
        word-break: break-word;
    }

    & svg {
        flex-shrink: 0;
    }

    // Cancelling PopoverItemButton style for this selector because it applies to nested elements
    & * + * {
        margin-left: 0;
    }
`;

export const StyledPopoverItemSwitch = styled(PopoverItem)`
    gap: ${Tokens.SIZES[1]};
    margin: 0;
    text-align: left;

    & > button {
        flex-grow: 1;
        width: 100%;
        justify-content: space-between;
    }

    & svg {
        flex-shrink: 0;
    }
    // Cancelling PopoverItemButton style for this selector because it applies to nested elements
    & * + * {
        margin-left: 0;
    }

    &:hover,
    &:focus-within {
        background-color: ${Tokens.COLOURS.primary.blue["10"]};
    }
`;

export const StyledPopoverItemWithSpaceBetween = styled(StyledPopoverItem)`
    justify-content: space-between;
`;

export const StyledDivider = styled.hr`
    margin: ${Tokens.SIZES[1]} -${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;

export const StyledListHeader = styled.h2`
    margin: ${Tokens.SIZES[1]};
    font-size: 1rem;
`;
