import React from "react";

import { Text } from "@accurx/design";
import { shallowEqual } from "react-redux";

import { StyledLeftBarBox } from "app/batchMessage/gp/BatchMessage.styles";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useAppSelector } from "store/hooks";

export const UploadSelfbookInfo = (): JSX.Element | null => {
    // Store data
    const batchType = useAppSelector(
        ({ batchMessage }) => batchMessage.batchType,
        shallowEqual,
    );
    const selectedSlotType = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedSlotType,
        shallowEqual,
    );
    const weeksAvailable = useAppSelector(
        ({ batchMessage }) => batchMessage.weeksAvailable,
        shallowEqual,
    );

    if (batchType !== BatchType.SELFBOOK || !selectedSlotType) {
        return null;
    }

    const aptCopy =
        selectedSlotType.availability > 1
            ? "appointments are"
            : "appointment is";
    return (
        <StyledLeftBarBox>
            <Text variant="label" skinny>
                {selectedSlotType.type}
                <br />
                {`About ${selectedSlotType.availability} ${aptCopy} available in the next ${weeksAvailable} weeks`}
            </Text>
        </StyledLeftBarBox>
    );
};
