import { Log } from "@accurx/shared";
import { mapConversationIdToClinicianMessagingIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToClinicianMessagingIdentity";
import { mapConversationIdToTicketIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToTicketIdentity";
import { ConversationSource } from "domains/concierge/schemas/ConversationSourceSchema";

export const mapConversationIdToConversationSource = (
    conversationId: string,
): ConversationSource | undefined => {
    const ticketIdentity = mapConversationIdToTicketIdentity(
        conversationId,
        true,
    );

    if (ticketIdentity) {
        return {
            system: "Ticket",
            ticketIdentity,
        };
    }

    const clinicianMessagingIdentity =
        mapConversationIdToClinicianMessagingIdentity(conversationId, true);

    if (clinicianMessagingIdentity) {
        return {
            system: "ClinicianMessaging",
            serverId: clinicianMessagingIdentity.id,
        };
    }

    Log.error("Invalid conversation Id", {
        tags: { product: "Inbox", conversationId },
    });
};
