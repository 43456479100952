import { useConciergeSelector } from "domains/concierge/internal/context";
import {
    AssigneeSummary,
    TeamSummary,
    UserSummary,
} from "domains/concierge/types";

export const useAssignee = (
    assignee: AssigneeSummary,
): TeamSummary | UserSummary | undefined => {
    return useConciergeSelector((state) => {
        switch (assignee.type) {
            case "None":
                return undefined;
            case "Team":
                return state.teams.items[assignee.id];
            case "User":
                return state.users.items[assignee.id];
        }
    });
};
