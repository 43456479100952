import { Cell, Flex, Pagination } from "@accurx/design";

export const PaginationRow: (props: {
    currentPage: number;
    isACompletePage: boolean;
    changePage: (page: number) => void;
}) => JSX.Element = ({ currentPage, isACompletePage, changePage }) => (
    <Cell>
        <Flex justifyContent="flex-end" gap="1">
            {currentPage > 1 && (
                <Pagination.PreviousButton
                    onPrevious={() => changePage(currentPage - 1)}
                />
            )}
            {isACompletePage && (
                <Pagination.NextButton
                    onNext={() => changePage(currentPage + 1)}
                />
            )}
        </Flex>
    </Cell>
);
