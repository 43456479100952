import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";

export const TABS: TabProps[] = [
    { text: "Manage reminders", tabId: "manage" },
    { text: "Sent reminders", tabId: "sent" },
    { text: "Cancelled", tabId: "cancelled" },
];

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;
