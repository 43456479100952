import { User } from "@accurx/auth";

export const generateSignature = (user: User): string => {
    if (user.messageSignature) {
        return user.messageSignature;
    }
    if (user.fullName) {
        return `Thanks, ${user.fullName}`;
    }
    return "Thanks";
};
