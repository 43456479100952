import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentUser, useFeatureFlag } from "@accurx/auth";
import { useConversationUnreadItems } from "@accurx/concierge/hooks/data/useConversationUnreadItems";
import { useUnreadCountsQueryStatus } from "@accurx/concierge/hooks/data/useUnreadCountsQueryStatus";
import { useMarkItemsAsReadMutation } from "@accurx/concierge/hooks/mutations/useMarkItemsAsReadMutation";
import { Conversation } from "@accurx/concierge/types";
import { getIsConversationTriageRequest } from "@accurx/concierge/util/getIsConversationTriageRequest";
import * as UI from "@accurx/design";
import { useDeepMemo } from "@accurx/hooks";
import {
    useBrowserEnvironment,
    useMedicalRecordConnection,
} from "@accurx/native";
import { DateHelpers } from "@accurx/shared";
import { ConversationItem } from "domains/inbox/components/ConversationItem/ConversationItem";
import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";
import { getConversationDescription } from "domains/inbox/util/getConversationDescription";
import { getConversationParticipantForAnalytics } from "domains/inbox/util/getConversationParticipantForAnalytics";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";
import { getLastConversationLabelTagItem } from "domains/inbox/util/getLastConversationLabelTag";

import { OutcomeRecording } from "../OutcomeRecording/OutcomeRecording";
import { ThreadList } from "../ThreadList/ThreadList";
import {
    StyledBottomThreadSpacing,
    StyledLoadingStateContainer,
    StyledNewMessageMarker,
    StyledThread,
} from "./ConversationThread.styles";
import {
    filterOutLinkNotes,
    getNewMessagesMarkerInfo,
    groupConversationItemsByDate,
    useThreadScrollBehaviour,
} from "./conversationThread.utils";

export type ConversationThreadProps = {
    conversation: Conversation;
    className?: string; // support extending with Styled Components
    allowOutcomeRecording?: true;
    hasFloatingBottomElements?: boolean;
};

export const ConversationThread = ({
    conversation,
    className,
    allowOutcomeRecording,
    hasFloatingBottomElements = false,
}: ConversationThreadProps) => {
    const { user } = useCurrentUser();
    const userId = user.accuRxUserId;
    const { mutate: markItemsAsRead } = useMarkItemsAsReadMutation({
        patientId: conversation.regardingPatientId,
        conversation,
    });
    const unreadItems = useConversationUnreadItems({
        conversationId: conversation.id,
        userId,
    });
    const unreadItemIds = useDeepMemo(unreadItems.map((item) => item.id));
    const unreadItemIdsRef = useRef(unreadItemIds);
    const env = useBrowserEnvironment();
    const hasOutcomeRecordingFlag = useFeatureFlag("OutcomeRecording");

    // If you land immediately on the conversation we don't want to mark the
    // conversation as read until unread info has been loaded. Otherwise we
    // receive the unread info too late and the client will then think the
    // conversation is unread again.
    const unreadCountsQueryStatus = useUnreadCountsQueryStatus({
        conversationId: conversation.id,
    });
    const isUnreadStateReady = unreadCountsQueryStatus !== "loading";

    const isPatientTriageConversation =
        getIsConversationTriageRequest(conversation);

    const lastRecordedOutcome = getLastConversationLabelTagItem(conversation);

    const shouldShowInitialOutcomeRecording =
        allowOutcomeRecording &&
        hasOutcomeRecordingFlag &&
        conversation.status === "Done" &&
        !lastRecordedOutcome;

    const { system: medicalRecordSystem } = useMedicalRecordConnection();

    const track = useAnalytics();

    const visibleItems = useMemo(() => {
        return filterOutLinkNotes(conversation.items);
    }, [conversation.items]);

    // Store the first unread message in state to remember the initial value
    // when the thread first mounts. We do this because we want the "New
    // message" marker to remain in place even when the unread items get marked
    // as read.
    const [newMessagesMarkerInfo] = useState(() => {
        return getNewMessagesMarkerInfo(visibleItems, unreadItems);
    });

    const { containerRef, newMessagesMarkerRef, bottomAnchorRef, isAtBottom } =
        useThreadScrollBehaviour({
            itemIds: visibleItems.map((item) => item.id),
        });

    const dateGroups = useMemo(() => {
        return groupConversationItemsByDate(visibleItems);
    }, [visibleItems]);

    useEffect(() => {
        unreadItemIdsRef.current = unreadItemIds;
    }, [unreadItemIds]);

    useEffect(() => {
        if (
            isUnreadStateReady &&
            unreadItemIdsRef.current.length > 0 &&
            isAtBottom
        ) {
            markItemsAsRead({
                itemIds: unreadItemIdsRef.current,
            });
        }
        // We want this effect to run when the total number of conversation items changes.
        // Doing it only when the unread count changes means we instantly mark a conversation
        // as read that has just been marked as unread by the user.
    }, [
        conversation.items.length,
        isAtBottom,
        markItemsAsRead,
        isUnreadStateReady,
    ]);

    useEffect(() => {
        track("Conversation Page Load", {
            eventVersion: 2,
            accessType: env === "WebView" ? "DesktopApp" : "Browser",
            isDone: conversation.status === "Done",
            conversationDescription: getConversationDescription(conversation),
            conversationId: getConversationServerId(conversation),
            conversationStartTimestampUtc: conversation.items[0]?.createdAt,
            conversationType: getConversationType(conversation),
            conversationParticipant:
                getConversationParticipantForAnalytics(conversation),
            conversationRequestType: getConversationRequestType(conversation),
            medicalRecordSystem,
        });
        // this useEffect should only run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledThread
            className={className}
            ref={containerRef}
            aria-label="Message thread"
        >
            {dateGroups.map(([date, items]) => {
                const formatDate = DateHelpers.formatDateRelativeToToday(date);

                return (
                    <ThreadList
                        key={date}
                        date={formatDate}
                        aria-label={`Messages from ${formatDate}`}
                    >
                        {items.map((item) => {
                            const hasNewMessageMarker =
                                newMessagesMarkerInfo?.message.id === item.id;

                            return (
                                <Fragment key={item.id}>
                                    {hasNewMessageMarker && (
                                        <StyledNewMessageMarker
                                            data-testid="new-message-marker"
                                            aria-hidden={true}
                                            ref={newMessagesMarkerRef}
                                        >
                                            <UI.Thread.UnreadBadge text="New" />
                                        </StyledNewMessageMarker>
                                    )}
                                    <ConversationItem
                                        key={item.id}
                                        item={item}
                                        conversation={conversation}
                                    />
                                </Fragment>
                            );
                        })}
                    </ThreadList>
                );
            })}
            {shouldShowInitialOutcomeRecording && (
                <OutcomeRecording
                    isMinimisedByDefault={!isPatientTriageConversation}
                    conversation={conversation}
                />
            )}
            <div ref={bottomAnchorRef} />
            <StyledBottomThreadSpacing
                $padding={hasFloatingBottomElements ? 3 : 2}
            />
        </StyledThread>
    );
};

export const LoadingState = ({ className }: { className?: string }) => (
    <StyledLoadingStateContainer className={className}>
        <UI.Flex gap="1.5" flexDirection="column" alignItems="center">
            <SkeletonLoader
                width="226px"
                height={UI.Tokens.SIZES[2]}
                backgroundColor={UI.Tokens.COLOURS.greyscale.dishwater}
            />
            <SkeletonLoader
                width="164px"
                height={UI.Tokens.SIZES[2]}
                backgroundColor={UI.Tokens.COLOURS.greyscale.dishwater}
            />
        </UI.Flex>
    </StyledLoadingStateContainer>
);
