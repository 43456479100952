import { Tokens } from "@accurx/design";
import styled from "styled-components";

import { getButtonBorder } from "../../styles";
import { Button } from "../Button/Button";

export const StyledPrimaryButton = styled(Button)`
    span {
        color: ${Tokens.COLOURS.greyscale.white};
    }
    background-color: ${Tokens.COLOURS.primary.blue[100]};
    padding: ${Tokens.SIZES[1]};
    line-height: 24px;

    &:enabled {
        box-shadow: ${getButtonBorder(Tokens.COLOURS.primary.blue[130])};
    }

    &:hover:not(:focus, :disabled) {
        background-color: ${Tokens.COLOURS.primary.blue[130]};
    }

    &:focus {
        background-color: ${Tokens.COLOURS.primary.blue[130]};
        box-shadow: 0px 0px 0px 3px ${Tokens.COLOURS.primary.blue[50]};
    }

    &:disabled {
        span {
            color: ${Tokens.COLOURS.greyscale.zinc};
        }
    }
`;
