import React from "react";

import { Text, Thread } from "@accurx/design";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import {
    NoteConversationItem,
    NoteSegment as NoteSegmentType,
} from "shared/concierge/conversations/types/item.types";
import {
    UserOrTeamRequest,
    useSingleUserOrTeamQuery,
} from "shared/concierge/usersAndTeams/hooks/usersAndTeamsQueryHooks";

import { BaseConversationThreadItem } from "../threadItem.types";

export type NoteThreadItemProps =
    BaseConversationThreadItem<NoteConversationItem>;

type NoteSegmentProps = {
    noteSegment: NoteSegmentType;
    itemId: string;
    noteIndex: number;
};

const getUserOrTeamRequestFromNoteSegment = (
    noteSegment: NoteSegmentType,
): UserOrTeamRequest | undefined => {
    switch (noteSegment.type) {
        case "UserTag":
            return { type: "User", id: noteSegment.userId };
        case "TeamTag":
            return { type: "Team", id: noteSegment.teamId };
        case "Text":
            return undefined;
    }
};

const getFormattedSegment = (
    noteSegment: NoteSegmentType,
    userOrTeamTagName?: string,
) => {
    switch (noteSegment.type) {
        case "Text":
            return noteSegment.text;
        case "UserTag":
            return <b>{userOrTeamTagName || "Unknown user"}</b>;
        case "TeamTag":
            return <b>{userOrTeamTagName || "Unknown team"}</b>;
    }
};

const NoteSegment = ({ noteSegment }: NoteSegmentProps): JSX.Element => {
    const userOrTeamRequest = getUserOrTeamRequestFromNoteSegment(noteSegment);
    const userOrTeamQuery = useSingleUserOrTeamQuery(userOrTeamRequest);
    const userOrTeamTagName = userOrTeamQuery.data?.displayName;

    return <Text>{getFormattedSegment(noteSegment, userOrTeamTagName)}</Text>;
};

export const NoteThreadItem = ({
    item,
    handleOnItemRead,
    createdByDisplayName,
}: NoteThreadItemProps): JSX.Element => {
    return (
        <Thread.Message
            inboxType="sent"
            dateCreated={formatConversationItemDateCreatedShort(item.createdAt)}
            senderFullName={
                createdByDisplayName
                    ? {
                          name: createdByDisplayName
                              ? {
                                    fullName: createdByDisplayName,
                                    shouldDisplayFullName: true,
                                }
                              : null,
                      }
                    : null
            }
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            {item.segments.map((noteSegment, noteIndex) => (
                <NoteSegment
                    key={`noteSegment-${item.id}-${noteIndex}`}
                    noteSegment={noteSegment}
                    noteIndex={noteIndex}
                    itemId={item.id}
                />
            ))}
        </Thread.Message>
    );
};
