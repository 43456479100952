import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { NhsPharmaciesResponse } from "@accurx/api/account";

import { searchPharmacies } from "api/PharmaciesApi";

export const SEARCH_PHARMACIES_CACHE_KEY = "GetPharmacies";

type SearchPharmaciesQueryVariables = {
    searchTerm: string;
    limit?: number;
    page?: number;
};

export const useSearchPharmaciesQuery = createQueryHook<
    SearchPharmaciesQueryVariables,
    NhsPharmaciesResponse
>(({ searchTerm, page = 1, limit = 20 }) => ({
    queryKey: [SEARCH_PHARMACIES_CACHE_KEY, { searchTerm, page, limit }],
    queryFn: async () => {
        const response = await searchPharmacies({ searchTerm, page, limit });
        return returnDataOrThrow(response);
    },
    enabled: Boolean(searchTerm),
    staleTime: Infinity,
}));
