import { Button, Ds, Flex, Text } from "@accurx/design";

type DeleteConfirmModalProps = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const DeleteConfirmModal = ({
    title,
    isOpen,
    onClose,
    onConfirm,
}: DeleteConfirmModalProps) => {
    return (
        <Ds.Modal open={isOpen} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>{title}</Ds.Modal.Title>
                    <Text>This cannot be undone.</Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            type="button"
                            data-testid="secondary-button"
                            onClick={onClose}
                            text="Cancel"
                        />
                        <Button
                            theme="primary"
                            type="button"
                            data-testid="primary-button"
                            onClick={onConfirm}
                            text="Confirm"
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
