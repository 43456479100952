export enum Actions {
    RESET_STATE = "RESET_STATE",
    SET_QUESTIONNAIRE = "SET_QUESTIONNAIRE",
    SET_QUESTIONS = "SET_QUESTIONS",
    UPDATE_CARD_IS_SAVING = "UPDATE_CARD_IS_SAVING",
    UPDATE_QUESTION_IS_REMOVING = "UPDATE_QUESTION_IS_REMOVING",
    UPDATE_PAGE_IS_SAVING = "UPDATE_PAGE_IS_SAVING",
    UPDATE_QUESTIONNAIRE_NAME = "UPDATE_QUESTIONNAIRE_NAME",
    UPDATE_QUESTIONNAIRE_NAME_VIEW_STATUS = "UPDATE_QUESTIONNAIRE_NAME_VIEW_STATUS",
    ADD_QUESTION = "ADD_QUESTION",
    UPDATE_QUESTION_FIELD = "UPDATE_QUESTION_FIELD",
    UPDATE_QUESTION_VIEW_STATUS = "UPDATE_QUESTION_VIEW_STATUS",
    UPDATE_QUESTION_BRANCH_CONDITION_QUESTION = "UPDATE_QUESTION_BRANCH_CONDITION_QUESTION",
    ADD_QUESTION_MULTIPLE_CHOICE_OPTION = "ADD_QUESTION_MULTIPLE_CHOICE_OPTION",
    UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_FIELD = "UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_FIELD",
    UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_CODE = "UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_CODE",
    REMOVE_QUESTION_MULTIPLE_CHOICE_OPTION = "REMOVE_QUESTION_MULTIPLE_CHOICE_OPTION",
    UPDATE_QUESTION_MULTIPLE_CHOICE_NUM_OF_ANSWERS = "UPDATE_QUESTION_MULTIPLE_CHOICE_NUM_OF_ANSWERS",
    REMOVE_LAST_QUESTION = "REMOVE_LAST_QUESTION",
    ADD_QUESTION_SELECTOR = "ADD_QUESTION_SELECTOR",
    REMOVE_QUESTION_SELECTOR = "REMOVE_QUESTION_SELECTOR",
    ADD_MEASUREMENT_SELECTOR = "ADD_MEASUREMENT_SELECTOR",
    REMOVE_MEASUREMENT_SELECTOR = "REMOVE_MEASUREMENT_SELECTOR",
    ADD_PHOTO_UPLOAD_SELECTOR = "ADD_PHOTO_UPLOAD_SELECTOR",
    REMOVE_PHOTO_UPLOAD_SELECTOR = "REMOVE_PHOTO_UPLOAD_SELECTOR",
    SET_ENROLMENT_MESSAGE = "SET_ENROLMENT_MESSAGE",
    SET_ENROLMENT_CODE = "SET_ENROLMENT_CODE",
    SET_COMPLETION_TEXT = "SET_COMPLETION_TEXT",
    SET_COMPLETION_CODE = "SET_COMPLETION_CODE",
    SET_ASSIGNED_USER_GROUP = "SET_ASSIGNED_USER_GROUP",
    SET_CODES = "SET_CODES",
    SET_USER_GROUPS = "SET_USER_GROUPS",
}
