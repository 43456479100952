import { useState } from "react";

import { i } from "images";

import { SlideOut } from "app/sharedComponents/SlideOut";

import { FullNavigationMenu } from "./FullNavigationMenu";
import {
    StyledFullAccurxLogo,
    StyledMobileHeaderWithoutNavigation,
    StyledMobileNavigationMenu,
} from "./MobileNavigationMenu.styles";
import { NavigationToggle } from "./navigationToggle";
import { PRIMARY_NAV_TOGGLE_ID } from "./primaryNavigation";

export const MOBILE_NAV_TOGGLE_ID = "Navigation-toggle";

type MobileHeaderProps = {
    onToggleNavigation?: (id: string) => void;
};

const MobileHeader = ({ onToggleNavigation }: MobileHeaderProps) => {
    const MobileHeaderWrapper = onToggleNavigation
        ? StyledMobileNavigationMenu
        : StyledMobileHeaderWithoutNavigation;

    return (
        <MobileHeaderWrapper>
            {onToggleNavigation && (
                <NavigationToggle
                    onClick={() => onToggleNavigation(PRIMARY_NAV_TOGGLE_ID)}
                    id={MOBILE_NAV_TOGGLE_ID}
                />
            )}
            <StyledFullAccurxLogo
                src={i("accurx-logo-full-green.svg")}
                alt="Accurx logo"
                width="120px"
                height="16px"
            />
        </MobileHeaderWrapper>
    );
};

const MobileNavigationMenu = (): JSX.Element => {
    const [isFullNavigationOpen, setIsFullNavigationOpen] = useState(false);

    const toggleOnClick = (toggleId: string) => {
        setIsFullNavigationOpen(!isFullNavigationOpen);

        // We want to move the user's focus to the close toggle of the navigation menu.
        const toggleElement = document.getElementById(toggleId);

        toggleElement?.focus();
    };

    return (
        <>
            <MobileHeader onToggleNavigation={toggleOnClick} />
            <SlideOut
                isOpen={isFullNavigationOpen}
                toggleOpen={() =>
                    setIsFullNavigationOpen(!isFullNavigationOpen)
                }
                showTab={false}
                placement="left"
                useFixedWidth
            >
                <FullNavigationMenu
                    showToggle
                    toggleOnClick={() => toggleOnClick(MOBILE_NAV_TOGGLE_ID)}
                />
            </SlideOut>
        </>
    );
};

export { MobileNavigationMenu, MobileHeader };
