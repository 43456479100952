import { Action, Reducer } from "redux";

import {
    KnownAction,
    SET_EXTERNAL_REFERRER,
} from "app/sessionAnalytics/sessionAnalyticsActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type AnalyticsState = {
    externalReferrer: string;
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: AnalyticsState = {
    externalReferrer: "",
};

export const reducer: Reducer<AnalyticsState> = (
    state = initialState,
    incomingAction: Action,
): AnalyticsState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case SET_EXTERNAL_REFERRER:
            return {
                ...state,
                externalReferrer: action.referrer,
            };
    }

    return state;
};
