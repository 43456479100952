import { Log } from "@accurx/shared";

import { ROUTES_CHAIN } from "shared/Routes";

import { BatchType } from "./BatchMessage.types";

const ALLOWED_STAGGER = [BatchType.MESSAGE, BatchType.FLOREY];

enum BatchRoute {
    CHOOSE = "Choose",
    COMPOSE = "Compose",
    UPLOAD = "Upload",
    SCHEDULE = "Schedule",
    REVIEW = "Review",
}

const findBatchRoute = (
    practiceId: string,
    currentRoute: BatchRoute,
    batchType?: BatchType,
): { back: string; forward: string } => {
    let backRoute = "";
    let fowardRoute = "";

    switch (currentRoute) {
        case BatchRoute.CHOOSE:
            backRoute = ROUTES_CHAIN.practicesBatchMessaging;
            // forward - Different possible routes handle by the page
            break;
        case BatchRoute.COMPOSE:
            backRoute = ROUTES_CHAIN.practicesBatchMessagingChoose;
            fowardRoute = ROUTES_CHAIN.practicesBatchMessagingUpload;
            break;
        case BatchRoute.UPLOAD:
            if (!batchType) {
                Log.error("You forgot to pass batchType or isFeatureStagger");
                break;
            }
            switch (batchType) {
                case BatchType.COPD:
                    backRoute = ROUTES_CHAIN.practicesBatchMessagingComposeCopd;
                    break;
                case BatchType.INHALER:
                    backRoute =
                        ROUTES_CHAIN.practicesBatchMessagingComposeInhaler;
                    break;
                case BatchType.MESSAGE:
                    backRoute = ROUTES_CHAIN.practicesBatchMessagingComposeSms;
                    break;
                case BatchType.SELFBOOK:
                    backRoute =
                        ROUTES_CHAIN.practicesBatchMessagingComposeSelfbook;
                    break;
                case BatchType.FLOREY:
                    backRoute =
                        ROUTES_CHAIN.practicesBatchMessagingComposeFlorey;
                    break;
                case BatchType.NONE:
                default:
                    backRoute = ROUTES_CHAIN.practicesBatchMessagingChoose;
                    break;
            }
            fowardRoute = ALLOWED_STAGGER.includes(batchType)
                ? ROUTES_CHAIN.practicesBatchMessagingSchedule
                : ROUTES_CHAIN.practicesBatchMessagingReview;
            break;
        case BatchRoute.SCHEDULE:
            backRoute = ROUTES_CHAIN.practicesBatchMessagingUpload;
            fowardRoute = ROUTES_CHAIN.practicesBatchMessagingReview;
            break;
        case BatchRoute.REVIEW:
            if (!batchType) {
                Log.error("You forgot to pass batchType or isFeatureStagger");
                break;
            }
            backRoute = ALLOWED_STAGGER.includes(batchType)
                ? ROUTES_CHAIN.practicesBatchMessagingSchedule
                : ROUTES_CHAIN.practicesBatchMessagingUpload;
            fowardRoute = ROUTES_CHAIN.practicesBatchMessaging;
            break;
        default:
            break;
    }

    return {
        back: backRoute.replace(":orgId", practiceId),
        forward: fowardRoute.replace(":orgId", practiceId),
    };
};

/**
 * Only Florey and SMS flows have the stagger feature at the moment
 */
const TOTAL_STEPS_WITHOUT_STAGGER = 3;
const getTotalStep = (batchType: BatchType): number => {
    let totalStep = TOTAL_STEPS_WITHOUT_STAGGER;
    if (ALLOWED_STAGGER.includes(batchType)) {
        totalStep += 1;
    }
    return totalStep;
};

const displayBatchType = (type: string, description: string): string => {
    if (type === "FLOREY") {
        return `${BatchType.FLOREY} - ${description}`;
    }
    if (type === "SELFBOOK") {
        return BatchType.SELFBOOK;
    }
    if (type === "SMS") {
        return BatchType.MESSAGE;
    }
    return description;
};

const getSelfBookLinkExpiryDays = (
    selfBook7DaysExpiryLinksEnabled: boolean,
): number => {
    const selfbookExtendedLinkExpiryTo7Days = 7;
    const selfbookOriginalLinkExpiryDays = 2;
    return selfBook7DaysExpiryLinksEnabled
        ? selfbookExtendedLinkExpiryTo7Days
        : selfbookOriginalLinkExpiryDays;
};

export {
    BatchRoute,
    findBatchRoute,
    getTotalStep,
    displayBatchType,
    getSelfBookLinkExpiryDays,
};

const SELF_BOOK_PROMOTION_HOW_IT_WORKS =
    "accurx-self-book-promotion-how-it-works";
const SELF_BOOK_PROMOTION_I_AM_NOT_INTERESTED =
    "accurx-self-book-promotion-i-am-not-interested";

export const updateShowMeHowItWorksCount = (userId: string, counter: string) =>
    window.localStorage.setItem(
        `${SELF_BOOK_PROMOTION_HOW_IT_WORKS}-${userId}`,
        counter,
    );

export const getShowMeHowItWorksCount = (userId: string) =>
    window.localStorage.getItem(
        `${SELF_BOOK_PROMOTION_HOW_IT_WORKS}-${userId}`,
    ) ?? "0";

export const updateIamNotInterestedCount = (userId: string, counter: string) =>
    window.localStorage.setItem(
        `${SELF_BOOK_PROMOTION_I_AM_NOT_INTERESTED}-${userId}`,
        counter,
    );

export const getIamNotInterestedCount = (userId: string) =>
    window.localStorage.getItem(
        `${SELF_BOOK_PROMOTION_I_AM_NOT_INTERESTED}-${userId}`,
    ) ?? "0";
