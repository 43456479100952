import { Pill } from "@accurx/inbox-design-library";

type DownloadAsPDFButtonProps = {
    downloadUrl: string;
};

export const DownloadAsPDFButton = ({
    downloadUrl,
}: DownloadAsPDFButtonProps) => {
    return (
        <Pill.SecondaryButton
            dimension="small"
            onClick={() => window.open(downloadUrl)}
        >
            <Pill.Icon name="Save" colour="blue" />
            <Pill.Text>Download as PDF</Pill.Text>
        </Pill.SecondaryButton>
    );
};
