import { Button, Text } from "@accurx/design";
import { i } from "images";

import { useNewConversationNavigation } from "../hooks/useNewConversationNavigation";
import { StyledFlex } from "./NoConversations.styles";

export const NoConversations = () => {
    const { navigateToNewConversation, canCreateNewConversation } =
        useNewConversationNavigation();

    return (
        <StyledFlex flexDirection="column" alignItems={"center"}>
            <img
                src={i("illustrations/message-bubbles-80px@3x.png")}
                alt="No conversations yet"
                height="120"
            />
            <Text variant="label">No conversations yet</Text>
            <Text>
                Any conversation between you and a patient's GP practice will
                appear here.
            </Text>
            {canCreateNewConversation && (
                <Button
                    text="Send a new message"
                    theme="primary"
                    onClick={() =>
                        navigateToNewConversation({
                            fromEmptyConversations: true,
                        })
                    }
                />
            )}
        </StyledFlex>
    );
};
