import {
    MeaningfulActionProps,
    useMeaningfulActionAnalyticsProps as useGetProps,
} from "@accurx/analytics";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

/** Returns tracking properties that are required for meaningful action events, scoped to the current selectedOrgId.
 * This is necessary while not all of fleming client pages live on routes with a workspaceId or organisationId.
 */
export const useMeaningfulActionAnalyticsProps = (): MeaningfulActionProps => {
    const { selectedOrgId } = useSelectedOrganisation();
    const analyticsProps = useGetProps({
        workspaceId: selectedOrgId || undefined,
    });

    return analyticsProps;
};
