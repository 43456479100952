import { Subject } from "rxjs";

/**
 * Will publish an update to the feed unless that feed is closed.
 *
 * @param feed a subscription (stream of data) which we want to publish updates to
 * @param data the data that we want to publish to the feed
 */
export const safeNext = <T>(feed: Subject<T>, data: T): void => {
    if (!feed.closed) {
        feed.next(data);
    }
};
