import React, { ChangeEvent, useState } from "react";

import { Button, FormFieldFeedback, Icon, Text } from "@accurx/design";
import { Log, SharedConstants } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    removeUploadedBatchFile,
    uploadBatchFile,
} from "app/batchMessage/gp/BatchMessage.actions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

const MaxAllowedFileSize = 3145728; // 3mB - Match MessagePatientDocumentAttachConstants.MaximumAttachmentFileSizeBytes in C#
const MaxAllowedFileSizeHumanReadable = "3MB";

export const BatchMessageFileUpload = ({
    isTrustFlow = false,
}: {
    /** Used for analytics purposes */
    isTrustFlow?: boolean;
}): JSX.Element => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const orgId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );
    const uploadedFileLink = useAppSelector(
        ({ batchMessage }) => batchMessage.uploadedFileLink,
        shallowEqual,
    );
    const uploadedFileName = useAppSelector(
        ({ batchMessage }) => batchMessage.uploadedFileName,
        shallowEqual,
    );
    const fileRemovingStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.fileRemovingStatus,
        shallowEqual,
    );
    const fileUploadingStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.fileUploadingStatus,
        shallowEqual,
    );
    const isUploading = fileUploadingStatus === UpdatingStatus.Loading;

    // State
    const [addingFileError, setAddingFileError] = useState(false);
    const [fileAddingErrorMessages, setFileAddingErrorMessage] = useState<
        Array<string>
    >([]);

    const buttonClickUpload = () =>
        document.getElementById("fileUpload")?.click();

    const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setFileAddingErrorMessage([]);
        setAddingFileError(false);
        if (e.target.files !== null) {
            const files = Array.from(e.target.files);
            for (const file of files) {
                const isValid = validateFile(file);
                if (isValid) {
                    dispatch(
                        uploadBatchFile(file, orgId?.toString() || practiceId),
                    );
                    ChainAnalyticsTracker.trackBatchComposeAttachClick({
                        ...analyticsLoggedInProps,
                        isTrustFlow,
                    });
                } else {
                    setAddingFileError(true);
                }
            }
        }
    };

    const validateFile = (file: File) => {
        const failureReasons = [];
        try {
            if (file.size > MaxAllowedFileSize) {
                failureReasons.push(
                    `File is too big, max file size: ${MaxAllowedFileSizeHumanReadable}`,
                );
            }

            const fileExtension: string =
                file.name.split(".").pop()?.toLowerCase() || "";
            if (
                SharedConstants.SPREADSHEET_FILE_EXTENSIONS.indexOf(
                    fileExtension,
                ) !== -1
            ) {
                failureReasons.push(
                    `${fileExtension.toUpperCase()} files are not supported here. You can upload your patient list on the next page.`,
                );
            } else if (
                SharedConstants.FileUploadTypesArray.indexOf(fileExtension) ===
                -1
            ) {
                failureReasons.push(
                    `${
                        fileExtension.toUpperCase() || "Those"
                    } files are not supported here.`,
                );
            }
        } catch (e) {
            failureReasons.push(
                "Something went wrong while validating your file, please try again.",
            );
            Log.error("Error encountered validating batch file upload", e);
        }

        setFileAddingErrorMessage(failureReasons);

        return failureReasons.length === 0;
    };

    const removeFile = () => {
        dispatch(removeUploadedBatchFile());
        setAddingFileError(false);
        setFileAddingErrorMessage([]);
    };

    return (
        <div className="mb-4">
            <div className="w-100 d-flex align-items-start mt-2">
                {!uploadedFileName ? (
                    <>
                        <label
                            data-testid="fileUploadLabel"
                            className="mb-0"
                            htmlFor="fileUpload"
                        >
                            <Button
                                text={
                                    isUploading
                                        ? "Please wait..."
                                        : "Add attachment"
                                }
                                type="button"
                                onClick={buttonClickUpload}
                                disabled={isUploading}
                                theme="secondary"
                                icon={{ name: "Paperclip" }}
                            />
                            <input
                                type="file"
                                accept={SharedConstants.FileUploadTypes}
                                id="fileUpload"
                                data-testid="fileUpload"
                                onChange={uploadFile}
                                className="d-none"
                            />
                        </label>
                    </>
                ) : (
                    <div
                        className="w-100 d-flex align-items-center justify-content-between p-1"
                        style={{
                            borderTop: "0.1rem solid lightgray",
                            borderBottom: "0.1rem solid lightgray",
                        }}
                    >
                        <div className="w-100 d-flex align-items-center">
                            <Icon
                                name="Paperclip"
                                size={3}
                                props={{ style: { minWidth: "1.5rem" } }}
                                theme="Line"
                            />
                            <Text
                                skinny
                                as="a"
                                variant="link"
                                props={{
                                    "data-testid": "batch-info-url",
                                    target: "_blank",
                                    rel: "noreferrer noopener",
                                    href: uploadedFileLink,
                                }}
                            >
                                {uploadedFileName}
                            </Text>
                        </div>
                        <Button
                            dimension="small"
                            theme="danger"
                            onClick={removeFile}
                            data-testid="bin-icon"
                            type="button"
                            icon={{
                                name: "Bin",
                                style: "Line",
                                title: "Remove file",
                                id: "remove-file-btn",
                            }}
                            disabled={
                                fileRemovingStatus === UpdatingStatus.Failed
                            }
                        />
                    </div>
                )}
            </div>
            <div className="w-100" style={{ textAlign: "start" }}>
                {(addingFileError ||
                    fileUploadingStatus === UpdatingStatus.Failed) &&
                    (fileAddingErrorMessages.length > 0 ? (
                        fileAddingErrorMessages.map((errMessage) => (
                            <FormFieldFeedback
                                key={errMessage}
                                variant="error"
                                text={errMessage}
                                className="mt-2"
                            />
                        ))
                    ) : (
                        <FormFieldFeedback
                            variant="error"
                            text="Sorry, something went wrong uploading this file"
                            className="mt-2"
                        />
                    ))}
            </div>
        </div>
    );
};
