import { useEffect, useLayoutEffect, useState } from "react";

type Size = {
    width: number | undefined;
    height: number | undefined;
};

/**
 * Given a ref object to a DOM element this hook returns the width and height of
 * that element. The initial size is calculated before first paint to avoid a
 * jittery initial render. The size is kept up-to-date via a resize observer.
 */
export const useElementSize = <T extends HTMLElement = HTMLElement>(
    ref: React.RefObject<T>,
) => {
    const [size, setSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });

    useLayoutEffect(() => {
        const rect = ref.current?.getBoundingClientRect();
        setSize({ width: rect?.width, height: rect?.height });
    }, [ref]);

    useEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver(([entry]) => {
            const height = entry.borderBoxSize[0].blockSize;
            const width = entry.borderBoxSize[0].inlineSize;
            setSize({ width, height });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return size;
};
