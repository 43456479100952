import { FeatureName } from "@accurx/auth";
import { shallowEqual, useDispatch } from "react-redux";

import { FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators } from "app/selectProduct/SelectProductActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { FeatureButton } from "../FeatureButton";
import { isMessagePatientDisabled } from "../PatientProfile.helper";

const MessagePatientButton = ({
    actionCallback,
}: {
    actionCallback: () => void;
}): JSX.Element | null => {
    const patient = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatient(searchForPatient.lastResponse),
        shallowEqual,
    );
    const loggedInProps = useFlemingLoggedInAnalytics();
    const dispatch = useDispatch();
    const organisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );

    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );

    const isPatientThreadSmsEnabledForOrg = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName.PatientThreadSMS,
    );
    if (!isPatientThreadSmsEnabledForOrg) return null;

    const disabledInfo = isMessagePatientDisabled(patient);
    const onClickHandler = () => {
        dispatch(actionCreators.selectSMSProductType());

        const analyticsProps = {
            ...loggedInProps,
            navigationLocation: "Header" as const,
            optionSelected: "MessagePatient" as const,
            productAvailableForPatient: true, // If this is enabled the product is available
            searchPatientOrigin,
        };

        analyticsProps &&
            FlemingAnalyticsTracker.trackProductOptionButtonClick(
                analyticsProps,
            );
        actionCallback();
    };

    return (
        <FeatureButton
            title="Message patient"
            icon={{ name: "Send", colour: "blue" }}
            action={onClickHandler}
            disabledInfo={disabledInfo}
            data-userflow-id="message-patient-button"
        />
    );
};

export { MessagePatientButton };
