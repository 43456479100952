/**
 * A helpful utility to filter out falsy values from
 * a list. Making sure that TypeScript can follow
 * the thread.
 */
export const isInstance = <T>(item: T | undefined): item is T => {
    return !!item;
};

export const noop = async () => {
    /* do nothing */
};
