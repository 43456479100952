import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const WebBatchPageLayout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
`;

export const WebBatchPageHeaderNav = styled.header`
    display: flex;
    align-items: center;
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

export const WebBatchPageContent = styled.main`
    padding: 0 ${Tokens.SIZES[3]} ${Tokens.SIZES[3]};
    overflow-y: auto;
`;

export const WebBatchPageFooterNav = styled.footer`
    display: flex;
    align-items: center;
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: 0px -2px 4px rgba(68, 72, 126, 0.2);
`;

export const WebBatchWidthWrapper = styled.div`
    flex-grow: 1;
`;

export const WebBatchControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;
