import { useEffect } from "react";

import { Button, Card, Ds, Link, Text } from "@accurx/design";
import { generatePath, useLocation, useParams } from "react-router-dom";

import {
    ReportingHomepagePageViewProps,
    trackReportingHomepagePageView,
} from "app/analytics/FlemingAnalytics/ReportingEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_ORGS } from "shared/Routes";

import { useModules } from "../hooks";
import { MainPanel } from "../settings/SettingsPage.styles";
import { Breadcrumb, Breadcrumbs, Container, Header } from "../shared";
import { StyledCardLink } from "./ReportingPage.styles";

const REPORTS = {
    PatientTriage: {
        name: "Patient Triage",
        description:
            "Use this report to better understand and plan for your organisation's inbound demand.",
        path: ROUTES_ORGS.reportPatientTriage,
    },
    ResponseRates: {
        name: "Response rates: Questionnaires & Self-Book",
        description:
            "Use this report to better understand the performance of the questionnaires you send, and the appointments that you offer.",
        path: ROUTES_ORGS.reportResponseRates,
    },
    Fragments: {
        name: "Fragments",
        description:
            "Use this report to better understand the number of fragments and SMS messages sent by your organisation, as well as insights about other messaging channels (e.g. the NHS App).",
        path: ROUTES_ORGS.reportFragments,
    },
} as const;

const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "ReportingHomepage",
};

export const ReportCard = ({
    name,
    description,
    path,
}: {
    name: string;
    description: string;
    path: string;
}) => {
    return (
        <Card key={name}>
            <Ds.Flex
                flexWrap={{ xs: "wrap", md: "nowrap" }}
                justifyContent="space-between"
                alignItems="center"
                gap="3"
            >
                <div>
                    <Text variant="subtitle" skinny>
                        {name}
                    </Text>
                    <Text skinny>{description}</Text>
                </div>
                <StyledCardLink
                    to={{ pathname: path, state: ORIGIN_PROPS }}
                    aria-label={`View ${name} report`}
                >
                    <Button
                        as="span"
                        text="View report"
                        theme="secondary"
                        icon={{ name: "Chart" }}
                    />
                </StyledCardLink>
            </Ds.Flex>
        </Card>
    );
};

export const ReportingPage = () => {
    const { state, search } = useLocation<
        Partial<ReportingHomepagePageViewProps> | undefined
    >();
    const { orgId } = useParams<{ orgId: string }>();
    const queryParams = new URLSearchParams(search);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const modules = useModules();

    const reports = [
        ...(modules?.patientTriage ? [REPORTS.PatientTriage] : []),
        REPORTS.ResponseRates,
        REPORTS.Fragments,
    ];

    useEffect(() => {
        if (state && state.navigationOrigin && state.pageOrigin) {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: state.navigationOrigin,
                pageOrigin: state.pageOrigin,
            });
        } else if (
            queryParams.get("navigationOrigin")?.toLowerCase() === "desktop"
        ) {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: "Desktop",
                pageOrigin: "DesktopToolbar",
            });
        } else {
            trackReportingHomepagePageView({
                ...analyticsLoggedInProps,
                navigationOrigin: "URL",
            });
        }
        // Only track page view once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb text="Reporting" path={ROUTES_ORGS.reporting} />
                </Breadcrumbs>
                <Text as="h1" variant="title">
                    Reporting
                </Text>
                <Text>
                    We are taking a new approach to reporting, please let us
                    know what you think by providing feedback{" "}
                    <Link
                        href="https://forms.gle/h2P81jGx9Xp4GFtq6"
                        openInNewTab
                    >
                        here
                        <Link.Icon />
                    </Link>
                    .
                </Text>
            </Header>
            <Container>
                <MainPanel>
                    <Ds.Flex flexDirection="column" gap="2">
                        {reports.map(({ name, description, path }) => (
                            <ReportCard
                                key={name}
                                name={name}
                                description={description}
                                path={generatePath(path, { orgId })}
                            />
                        ))}
                    </Ds.Flex>
                </MainPanel>
            </Container>
        </>
    );
};
