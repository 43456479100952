import React from "react";

import { Feedback } from "@accurx/design";
import { toast } from "react-toastify";

export const showSuccessToast = (message: string) => {
    toast(<Feedback colour="success" title={message} />);
};

export const showErrorToast = () => {
    toast(
        <Feedback colour="error" title="There was a network issue">
            Refresh the page and try again.
        </Feedback>,
    );
};
