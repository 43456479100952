import { ComponentProps, ReactNode } from "react";

import * as UI from "@accurx/design";

import { StyledInfoBoxLink } from "./InfoBoxLink.styles";

export const InfoBoxLink = ({
    children,
    ...props
}: { children: ReactNode } & ComponentProps<typeof UI.Link>) => {
    return <StyledInfoBoxLink {...props}>{children}</StyledInfoBoxLink>;
};
