import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";

import {
    Breakpoint,
    Breakpoints,
    matchesResponsiveBreakpoint,
} from "shared/breakpoints";

import { getShouldBeSticky } from "./Sticky.utils";

export type StickyProps = {
    children: ReactNode;
    /** What distance from the top of the screen should the sticky behaviour start
     * i.e. if this is 0, element will get sticky once it reaches the very top of the page
     * */
    stickyStart: number;
    /** [optional] Which breakpoint should the sticky behaviour start from
     * default is Breakpoints.sm (aka desktop) */
    stickyBreakpoint?: Breakpoint;
};

/**
 * Component that makes elements inside of it sticky at the point specified by the stickyStart prop
 * */
export const Sticky = ({
    stickyStart,
    stickyBreakpoint = Breakpoints.sm,
    children,
}: StickyProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [cardWidth, setCardWidth] = useState<number>(0);
    const [isStickyBreakpoint, setIsStickyBreakpoint] = useState<boolean>(
        matchesResponsiveBreakpoint(Breakpoints.sm),
    );
    const [shouldBeSticky, setShouldBeSticky] = useState<boolean>(false);

    // determine desired card width from the wrappers ref
    useEffect(() => {
        if (wrapperRef.current !== null) {
            setCardWidth(wrapperRef.current.clientWidth);
        }
    }, [wrapperRef]);

    useEffect(() => {
        // set card width and determine if it should be sticky
        const handleResize = (): void => {
            const checkIsInStickyBreakpoint =
                matchesResponsiveBreakpoint(stickyBreakpoint);
            setIsStickyBreakpoint(checkIsInStickyBreakpoint);
            if (wrapperRef.current !== null) {
                // Check whether card should be sticky on resize
                // (i.e. if I am resizing from the bottom of the page,
                // when I get to desktop view I'd want the card to be sticky,
                // but if I am at the top, I'd want it to be in its initial position
                setShouldBeSticky(
                    getShouldBeSticky(
                        wrapperRef.current,
                        checkIsInStickyBreakpoint,
                        stickyStart,
                    ),
                );
                setCardWidth(wrapperRef.current.clientWidth);
            }
        };

        window.addEventListener("resize", handleResize);
        return (): void => window.removeEventListener("resize", handleResize);
    }, [stickyBreakpoint, stickyStart]);

    useEffect(() => {
        if (isStickyBreakpoint === false) {
            return;
        }

        // not using lo-dash/throttle on this scroll listener as we want
        // the position changes to be as smooth as possible, and any
        // throttling makes it jittery
        const handleScroll = (): void => {
            if (wrapperRef.current !== null) {
                setShouldBeSticky(
                    getShouldBeSticky(
                        wrapperRef.current,
                        isStickyBreakpoint,
                        stickyStart,
                    ),
                );
            }
        };

        window.addEventListener("scroll", handleScroll);
        return (): void => window.removeEventListener("scroll", handleScroll);
    }, [isStickyBreakpoint, stickyStart]);

    const stickyStyles: CSSProperties =
        isStickyBreakpoint && shouldBeSticky
            ? {
                  position: "fixed",
                  width: `${cardWidth}px`,
                  top: stickyStart,
              }
            : {};

    return (
        <div ref={wrapperRef} style={{ position: "relative", width: "100%" }}>
            <div style={stickyStyles}>{children}</div>
        </div>
    );
};
