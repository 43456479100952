/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { ChangeEvent, useState } from "react";

import { Button, Flex, Spinner, Text } from "@accurx/design";

type AttachFileProps = {
    disabled?: boolean;
    allowedFileExtensions: string[];
    maxAllowedFileSize: { size: number; humanReadable: string };
    uploadFileFunction: (
        file: File,
    ) => Promise<{ success: boolean; attachmentId: string }>;
    onFileUploadSuccess: ({
        file,
        attachmentId,
    }: {
        file: File;
        attachmentId: string;
    }) => void;
    infoText?: string;
};

export const AttachFileButton = (props: AttachFileProps) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const buttonClickUpload = () => {
        setErrors([]);
        document.getElementById("fileUpload")?.click();
    };

    const validateFile = (file: any) => {
        const failureReasons: string[] = [];
        let success = false;
        try {
            if (file.size === 0) {
                failureReasons.push("Cannot upload an empty file");
            }

            if (file.size > props.maxAllowedFileSize.size) {
                failureReasons.push(
                    "File too big, max file size: " +
                        props.maxAllowedFileSize.humanReadable,
                );
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (props.allowedFileExtensions.indexOf(fileExtension) === -1) {
                failureReasons.push(
                    `Unsupported file extension: File with the extension .${fileExtension} was encountered, but files can only be one of the supported extensions (${props.allowedFileExtensions.join(
                        ", ",
                    )})`,
                );
            }

            success = failureReasons.length === 0;
        } catch (e) {
            failureReasons.push("Error validating file");
            success = false;
        }

        setErrors([...errors, ...failureReasons]);

        return success;
    };

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsUploadingFile(true);
        e.preventDefault();

        if (e.target.files !== null) {
            const files = Array.from(e.target.files);
            for (const file of files) {
                const isValid = validateFile(file);
                if (isValid) {
                    setErrors([]);
                    const response = await props.uploadFileFunction(file);

                    if (response.success) {
                        props.onFileUploadSuccess({
                            file,
                            attachmentId: response.attachmentId,
                        });
                    } else {
                        setErrors([
                            ...errors,
                            "Sorry something went wrong uploading this file",
                        ]);
                    }
                }
            }
        }

        setIsUploadingFile(false);
    };

    return (
        <Flex flexDirection="column" gap="1">
            {errors.length > 0 &&
                errors.map((error: string, index) => (
                    <Text colour="red" key={`${error}-${index}`}>
                        {error}
                    </Text>
                ))}
            <Flex alignItems="center" gap="1">
                <Button
                    text="Attach a file"
                    theme="secondary"
                    icon={{ name: "Paperclip" }}
                    onClick={buttonClickUpload}
                    disabled={props.disabled || isUploadingFile}
                />
                <input
                    type="file"
                    accept={`.${props.allowedFileExtensions.join(",.")}`}
                    id="fileUpload"
                    data-testid="fileUpload"
                    onChange={uploadFile}
                    style={{ display: "none" }}
                    disabled={props.disabled}
                />
                {isUploadingFile && <Spinner dimension="small" />}
                {props.infoText && <Text skinny>{props.infoText}</Text>}
            </Flex>
        </Flex>
    );
};
