import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";

import { conversationGroupIndexBy } from "./conversationGroupIndexBy";

/**
 * Returns the current index of the target conversation in the
 * conversation group. If it is not already present in the group,
 * returns -1.
 */
export const conversationGroupIndexOf = (
    state: Pick<ConversationsState, "items">,
    group: ConversationGroup,
    target: Conversation | undefined,
) => {
    if (target === undefined) return -1;
    const index = conversationGroupIndexBy(state, group, target);
    return group.members[index] === target.id ? index : -1;
};
