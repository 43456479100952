import { ReactNode } from "react";

import { Text } from "@accurx/design";

import { StyledTextWithHalo, TextWithHaloColours } from "./TextWithHalo.styles";

type TextWithHaloProps = {
    colour: TextWithHaloColours;
    children: ReactNode;
};

export const TextWithHalo = ({ colour, children }: TextWithHaloProps) => {
    return (
        <StyledTextWithHalo $colour={colour}>
            <Text variant="subtitle" skinny as="span">
                {children}
            </Text>
        </StyledTextWithHalo>
    );
};
