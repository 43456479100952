import { TabProps } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { useModules } from "app/organisations/hooks";
import { ROUTES_ORGS } from "shared/Routes";

import { TabLookup } from "./AccubookPage.constants";

export type UseAccubookPage = () => {
    tab?: TabProps;
    onTabChange: (tab: string) => void;
    redirect: string;
};

export const useAccubookPage: UseAccubookPage = () => {
    const modules = useModules();
    const isAccubookOrg = modules?.accubook.organisation;
    const isDeliverySite = modules?.accubook.deliverySite;
    const history = useHistory();
    const { orgId, tab: tabId } = useParams<{ orgId: string; tab: string }>();

    const defaultTab =
        isDeliverySite && !isAccubookOrg ? "manage-clinics" : "dashboard";

    const tab = TabLookup[tabId || defaultTab];

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.accubook, {
                orgId,
                tab: tabId,
            }),
        );
    };

    // generate a redirect url in case the route
    // we're on points to an invalid tab
    const redirect = generatePath(ROUTES_ORGS.accubook, {
        orgId,
        tab: defaultTab,
    });

    return { tab, onTabChange, redirect };
};
