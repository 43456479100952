import {
    CollaborativeWorkspaceCreationRequest,
    CollaborativeWorkspaceCreationResponse,
} from "@accurx/api/account";
import { Organisation, useCurrentUser } from "@accurx/auth";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

const ENDPOINT = "api/account/Workspace";

const createCollaborativeWorkspace = (
    request: CollaborativeWorkspaceCreationRequest,
): Promise<IWrappedResult<CollaborativeWorkspaceCreationResponse>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({ path: ENDPOINT }),
        request,
    );

export const useCreateWorkspaceMutation = (
    options?: UseMutationOptions<
        CollaborativeWorkspaceCreationResponse,
        Error,
        CollaborativeWorkspaceCreationRequest
    >,
) => {
    const dispatch = useDispatch();
    const { update, user } = useCurrentUser();
    const { setSelectedOrgId } = useSelectedOrganisation();

    return useMutation<
        CollaborativeWorkspaceCreationResponse,
        Error,
        CollaborativeWorkspaceCreationRequest
    >({
        mutationKey: ["CreateWorkspace"],
        mutationFn: async ({
            organisationId,
            workspaceName,
            description,
            specialties,
            isExample,
            isRecommended,
        }) => {
            const response = await createCollaborativeWorkspace({
                organisationId,
                workspaceName,
                description,
                specialties,
                isExample,
                isRecommended,
            });

            return returnDataOrThrow(response);
        },
        ...options,
        onSuccess: (...args) => {
            const { workspaceId, workspace } = args[0];
            // Lack of `workspaceId` means creation has failed despite returning 200
            if (workspaceId) {
                const organisations = [
                    ...user.organisations,
                    workspace as Organisation,
                ];
                update({ ...user, organisations });
                // Whilst we should avoid Redux dependencies in the domain
                // architecture, this is an exception because a lot of components
                // still rely on the list of workspaces in Redux being up-to-date
                dispatch({ type: "ORGANISATIONS_UPDATE", organisations });
                setSelectedOrgId(workspaceId);
            }
            // Workaround for dealing with `onSuccess` callback in `mutate` not
            // firing due to premature unmounting in E2E tests
            // https://tkdodo.eu/blog/mastering-mutations-in-react-query#some-callbacks-might-not-fire
            options?.onSuccess && options.onSuccess(...args);
        },
    });
};
