import * as UI from "@accurx/design";
import { i } from "images";
import styled from "styled-components";

const StyledContainer = styled.div`
    background: ${UI.Tokens.COLOURS.primary.green[100]};
    border-radius: ${UI.Tokens.BORDERS.radius};
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.3125rem;
`;

const StyledImage = styled.img`
    display: block;
    width: 0.875rem;
    height: 0.875rem;
`;

export const AccurxLogoAvatar = () => {
    return (
        <StyledContainer>
            <StyledImage src={i("accurx-logo-small.svg")} alt="Accurx" />
        </StyledContainer>
    );
};
