import { MeaningfulActionProps } from "@accurx/analytics";
import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    FlemingLoggedInCustomProperties,
    HasErrorProps,
} from "./FlemingAnalyticsConstants";

type HasErrorWithDetail = HasErrorProps & { errorReason: string[] };

type LoggedInPropsWithError = FlemingLoggedInCustomProperties & HasErrorProps;

type WorkspaceDetailsFormProps = FlemingLoggedInCustomProperties &
    HasErrorWithDetail & { withDescription: boolean };

type WorkspaceInviteProps = FlemingLoggedInCustomProperties & {
    workspaceOrganisationId: number;
};

type WorkspaceInviteAcceptProps = WorkspaceInviteProps &
    MeaningfulActionProps &
    HasErrorProps;

type WorkspaceInviteStartOrigin = "UserManagementPage" | "AssignModal";
type WorkspaceInviteStartProps = FlemingLoggedInCustomProperties & {
    appOrigin: WorkspaceInviteStartOrigin;
};

type UserManagementActionProps = FlemingLoggedInCustomProperties & {
    targetUserId: number;
    actionSelected:
        | "LeaveWorkspace"
        | "MakeAdmin"
        | "RemoveAdmin"
        | "Approve"
        | "Archive"
        | "Unarchive";
};

/**
 * User views the create workspace page
 *
 * Event type: Page View
 */
export const trackWorkspaceCreatePageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "WorkspaceCreate",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User views the invite user to a workspace page
 *
 * Event type: Page View
 */
export const trackWorkspaceInviteSendPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInviteSend",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicked button to invite user to a workspace
 *
 * Event type: Button click
 */
export const trackWorkspaceInviteSend = (
    props: LoggedInPropsWithError,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInviteSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicked button to accept an invitation to join a workspace
 *
 * Event type: Button click
 */
export const trackWorkspaceInviteAccept = (
    props: WorkspaceInviteAcceptProps,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInviteAccept",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
    });
};

/**
 * User clicked button to dismiss an invitation to join a workspace
 *
 * Event type: Button click
 */
export const trackWorkspaceInviteDismiss = (
    props: WorkspaceInviteProps,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInviteDismiss",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props, { eventVersion: 2 }),
    });
};

/* User clicked button to navigate to the invite user page
 *
 * Event type: Button click
 */
export const trackWorkspaceInviteStart = (
    props: WorkspaceInviteStartProps,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInviteStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User views the user management page
 *
 * Event type: Page View
 */
export const trackUserManagementPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "UserManagement",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User performs a user management action
 *
 * Event type: Button Click
 */
export const trackUserManagementAction = (
    props: UserManagementActionProps,
): void => {
    GenericTrackEvent({
        object: "UserManagementAction",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User views the workspace guidance page
 *
 * Event type: Page View
 */
export const trackWorkspaceInfoPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInfo",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks on the frequently asked questions dropdown
 *
 * Event type: Button Click
 */
export const trackWorkspaceInfoQuestionToggle = (
    props: LoggedInPropsWithError,
): void => {
    GenericTrackEvent({
        object: "WorkspaceInfoQuestion",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User views page for editing workspace details
 *
 * Event type: Page View
 */
export const trackWorkspaceEditPageView = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "WorkspaceEdit",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User saves changes to a workspace's details (name and description)
 *
 * Event type: Button Click
 */
export const trackWorkspaceEditConfirm = (
    props: WorkspaceDetailsFormProps,
): void => {
    GenericTrackEvent({
        object: "WorkspaceEditConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};
