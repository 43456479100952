import { ChangeEvent } from "react";

import {
    Question,
    QuestionType,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { ViewStatus } from "app/sharedComponents/saveableCard/SaveableCard";

import { ClientSideQuestion } from "./context.types";

// The following types are used for the questionnaire state

export type SnomedCode = {
    term: string;
    conceptId: string;
};

export type ClientSideQuestionAdditionalProps = {
    /* Adding a viewStatus for the Question type for displaying locally between Initial Edit and Preview views */
    viewStatus?: ViewStatus;
    clientId: string;
};

export type QuestionExtended = Question & ClientSideQuestionAdditionalProps;

/* Using the QuestionExtended type in this extended type for the Questionnaire */
export type QuestionnaireResponseExtended = Omit<
    QuestionnaireResponse,
    "questions"
> & {
    questions?: QuestionExtended[];
};

export enum AdditionalCardTypes {
    QuestionnaireName = "QuestionnaireName",
    QuestionTypeSelector = "QuestionTypeSelector",
    MeasurementTypeSelector = "MeasurementTypeSelector",
    PhotoUploadSelector = "PhotoUploadSelector",
}
export type CardType = QuestionType | AdditionalCardTypes;

export type CardStatus = {
    cardType: CardType;
    isSaving: boolean;
};
export type CardsByKey = Record<string, CardStatus>;

export enum QuestionTemplateDataType {
    Height = "Height",
    Weight = "Weight",
    ImageAttachment = "ImageAttachment",
    None = "None",
}

export enum PageActionText {
    Saving = "Saving...",
    SaveAndNext = "Save and next",
    SaveAndDone = "Save and done",
    Back = "Back",
}

export const QuestionConsts = {
    TitleMaxLength: 250,
    DescriptionMaxLength: 1000,
    MultipleChoiceMaxOptions: 15,
    MultipleChoiceOptionsMaxLength: 250,
} as const;

// The following are id attributes applied to the error fields of forms.
export const ErrorIds = {
    questionTitleError: "questionTitleError",
    questionDescriptionError: "questionDescriptionError",
    questionMultipleChoiceOptionAnswer: "questionMultipleChoiceOptionAnswer",
    questionMultipleChoiceOptionRequired:
        "questionMultipleChoiceOptionRequired",
    questionMultipleChoiceOptionMaximum: "questionMultipleChoiceOptionMaximum",
    questionImageDescriptionError: "questionImageDescriptionError",
    branchConditionQuestion: "branchConditionQuestion",
    branchConditionAnswer: "branchConditionAnswer",
} as const;

type InternalErrorObject = { errorMessage: string; errorId: string | null };

type AllErrors<T extends QuestionType> = {
    questionType?: T;
    questionTitle?: InternalErrorObject;
    helperText?: InternalErrorObject;
    optionsFirst?: InternalErrorObject;
    optionsLast?: InternalErrorObject;
    options?: InternalErrorObject[];
    conditionQuestion?: InternalErrorObject;
    conditionAnswer?: InternalErrorObject;
    imageAltText?: InternalErrorObject;
};

export type MultipleChoiceQuestionErrors =
    AllErrors<QuestionType.MultipleChoice> & {
        questionTitle?: InternalErrorObject;
        helperText?: InternalErrorObject;
        optionsFirst?: InternalErrorObject;
        optionsLast?: InternalErrorObject;
        options?: InternalErrorObject[];
        imageAltText?: InternalErrorObject;
    };

export type TextQuestionErrors = AllErrors<QuestionType.Text> & {
    questionTitle?: InternalErrorObject;
    helperText?: InternalErrorObject;
    imageAltText?: InternalErrorObject;
};

export type InformationQuestionErrors = AllErrors<QuestionType.Information> & {
    questionTitle?: InternalErrorObject;
    helperText?: InternalErrorObject;
    imageAltText?: InternalErrorObject;
};

export type BranchErrors = AllErrors<QuestionType.Branching> & {
    conditionQuestion?: InternalErrorObject;
    conditionAnswer?: InternalErrorObject;
};

// In future extend to include other error types
export type Errors =
    | MultipleChoiceQuestionErrors
    | TextQuestionErrors
    | InformationQuestionErrors
    | BranchErrors;

export type ValidateReturn = { isValid: boolean; errors: Errors };

export type QuestionsPageError = {
    id: string;
    message: string;
};

export enum QuestionnaireFlow {
    Create,
    Edit,
    Import,
}

export type QuestionnaireId = number | undefined;

/**
 * CardId to be used to uniquely refer to cards and assign errors
 */
export const CardId = {
    questionnaireName: "questionnaireName",
    measurementTypeSelector: "measurementTypeSelector",
    photoUploadSelector: "photoUploadSelector",
    questionTypeSelector: "questionTypeSelector",
} as const;

export type QuestionId = number | null | undefined;
export type RowVersion = string | null | undefined;
export type BranchId = number | null | undefined;

/* BranchPosition is index in the questions array where the relevant branch is positioned. */
export type BranchPosition = number;

/* QuestionLevel is whether the question is at the Questionnaire level or the Branch level */
export type QuestionLevel = "Questionnaire" | "Branch";

/** function handler types */

// Used for the remove question modal
export type SelectedQuestionProps = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    cardKey: string;
    questionId: QuestionId;
    questionLevel: QuestionLevel;
    questionRowVersion: RowVersion;
    questionType: QuestionType;
};

export type CloseQuestionProps = {
    branchPosition?: BranchPosition; // needed for card saving state
    questionLevel: QuestionLevel; // needed for card saving state
    clientId: string;
};
export type CancelQuestionProps = {
    analyticsQuestionType: string | undefined; // needed for analytics
    branchPosition?: BranchPosition; // needed for card saving state
    questionLevel: QuestionLevel; // needed for card saving state
    clientId: string;
};

export type SaveQuestionProps = {
    branchId?: BranchId;
    branchPosition?: BranchPosition; // needed for card saving state
    question: ClientSideQuestion;
    questionIndex: number;
    questionLevel: QuestionLevel; // needed for card saving state
};

export type QuestionEditProps = {
    branchId?: BranchId;
    branchPosition?: BranchPosition;
    cardKey: string;
    questionLevel: QuestionLevel;
};

export type CheckedChangeProps = {
    event: ChangeEvent<HTMLInputElement>;
    questionLevel: QuestionLevel;
    branchPosition?: BranchPosition;
};

export type QuestionMultipleChoiceSwitchProps = {
    allowMultipleAnswers: boolean;
    questionIndex: number;
    questionLevel: QuestionLevel;
    branchPosition?: BranchPosition;
};
