import { MessageTemplate } from "@accurx/message-templates";
import { TemplateItem } from "domains/compose/types";

import {
    MessageTemplateSelector,
    MessageTemplateSelectorProps,
} from "../components/MessageTemplateSelector/MessageTemplateSelector";
import {
    ClinicianMessageTemplateGroup,
    clinicianMessageTemplates,
} from "./clinicianMessagingTemplates";

const mapTemplates = ({
    templates,
    heading,
}: ClinicianMessageTemplateGroup): TemplateItem[] =>
    templates.map((template) => {
        return {
            type: "MessageTemplate",
            value: {
                id: template.name,
                title: template.title,
                body: template.body + "\n", // Add a new line so there's space between the body and signature
                owner: "Accurx",
                heading: heading,
                allowReplyByDefault: false,
            },
        };
    });

export const ClinicianMessageTemplateSelector = (
    props: Omit<
        MessageTemplateSelectorProps,
        "patientExternalIds" | "onClickTemplate"
    > & { onClickTemplate: (template: MessageTemplate) => void },
) => (
    <MessageTemplateSelector
        {...props}
        onClickTemplate={({ type, value }) => {
            switch (type) {
                case "MessageTemplate":
                    return props.onClickTemplate(value);
                default:
                    throw new Error(
                        "Expected only MessageTemplates in ClinicianMessaging context",
                    );
            }
        }}
        patientExternalIds={[]}
        status="loaded"
        templates={clinicianMessageTemplates.map(mapTemplates).flat()}
    />
);
