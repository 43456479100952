import React, { useMemo } from "react";

import { Feedback, Option, SearchSelect, Spinner } from "@accurx/design";
import {
    SearchSelectMode,
    SearchSelectProps,
} from "@accurx/design/dist/components/Forms/Select/models";

import { useGetSnomedCodesQuery } from "app/hooks/queries";
import { SnomedCodeListContext } from "app/hooks/queries/useGetSnomedCodesQuery";
import { useCurrentOrgId } from "store/hooks";

import { SnomedCode } from "../SnomedCode.types";
import * as helpers from "./SnomedSelect.helpers";

type SnomedSelectProps = {
    value: Optional<SnomedCode>;
    onChange: (snomedCode: Optional<SnomedCode>) => void;
    disabled?: boolean;
    context: SnomedCodeListContext;
    filter?: SnomedCode[];
} & Partial<SearchSelectProps>;

export const SnomedSelect = ({
    onChange: callerOnChange,
    value,
    context,
    filter,
    ...props
}: SnomedSelectProps) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const {
        data: snomedCodes,
        isLoading,
        isError,
    } = useGetSnomedCodesQuery({ workspaceId, context });

    const options = useMemo(() => {
        const filteredSnomedCodes = !filter
            ? snomedCodes
            : snomedCodes?.filter(
                  ({ conceptId }) =>
                      !filter
                          .map(
                              (filteredOutSnomedCode) =>
                                  filteredOutSnomedCode.conceptId,
                          )
                          .includes(conceptId),
              );

        return helpers.toOptions(filteredSnomedCodes ?? []);
    }, [filter, snomedCodes]);

    if (isError) {
        return (
            <Feedback
                colour="warning"
                title="There was a connection problem."
                children={
                    "We couldn't load the list of SNOMED codes. Refresh the page to try again. If that still doesn't work, contact support."
                }
            />
        );
    }

    if (isLoading) {
        return <Spinner dimension="small" />;
    }

    const onChange = (selected: Option | Option[]) => {
        const option = Array.isArray(selected) ? selected[0] : selected;
        callerOnChange(helpers.findCode(snomedCodes, option));
    };

    const selectedOption = options.find(
        (option) => option.value === value?.conceptId,
    );

    return (
        <>
            {value && !selectedOption && (
                <Feedback
                    colour="warning"
                    title="Unexpected error."
                    children={
                        "We couldn't load the SNOMED code used by this message template. Please contact support."
                    }
                />
            )}

            <SearchSelect
                {...props}
                initSelectedValues={selectedOption || helpers.EMPTY_OPTION}
                onChangeHandler={onChange}
                id={props.id || "snomed-select"}
                options={options}
                shouldDisplayValues={true}
                searchMode={SearchSelectMode.IncludeValue}
                valueDescription="Concept ID"
            />
        </>
    );
};
