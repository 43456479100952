import { useMemo } from "react";

import { Tokens } from "@accurx/design";
import { Sidenav } from "@accurx/navigation";
import {
    PendingApprovalButton,
    usePendingApproval,
} from "@accurx/workspace-management";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";
import styled from "styled-components";

import { ROUTES_PRIMARY } from "shared/Routes";
import { useRouteMatchOrTwoFactorPrompt } from "shared/useRouteMatchOrTwoFactorPrompt";

import { CohortsNavigation } from "./CohortsNavigation/CohortsNavigation";
import { ManageNavigation } from "./ManageNavigation/ManageNavigation";

const PendingApprovalContainer = styled.div`
    padding: ${Tokens.SIZES[1]};
`;

export const UnifiedSecondaryNavigation = () => {
    const { selectedOrgId } = useSelectedOrganisation();
    const { isPendingApproval } = usePendingApproval(selectedOrgId);

    const matchCohortsPath = useRouteMatchOrTwoFactorPrompt({
        path: [
            ROUTES_PRIMARY.patients,
            ROUTES_PRIMARY.batchMessageTrust,
            ROUTES_PRIMARY.questionnaireScoreboard,
        ],
    });
    const matchManagePath = useRouteMatchOrTwoFactorPrompt({
        path: [ROUTES_PRIMARY.workspaceSettings, ROUTES_PRIMARY.account],
    });

    const navigationMenu = useMemo(() => {
        if (matchCohortsPath) {
            return <CohortsNavigation />;
        } else if (matchManagePath) {
            return <ManageNavigation />;
        } else {
            return null;
        }
    }, [matchCohortsPath, matchManagePath]);

    if (!navigationMenu) {
        return null;
    }

    return (
        <Sidenav data-testid="secondary-nav">
            {navigationMenu}
            {isPendingApproval && (
                <Sidenav.Footer>
                    <PendingApprovalContainer>
                        <PendingApprovalButton />
                    </PendingApprovalContainer>
                </Sidenav.Footer>
            )}
        </Sidenav>
    );
};
