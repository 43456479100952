import * as UI from "@accurx/design";
import styled from "styled-components";

const StyledDot = styled.div`
    padding: 0 ${UI.Tokens.SIZES[1]};
    line-height: 0;
    display: flex;
    align-items: center;
    color: ${UI.Tokens.COLOURS.greyscale.stone};
`;

export const DotSeparator = () => <StyledDot>{" • "}</StyledDot>;
