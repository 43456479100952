import { Children, KeyboardEvent, ReactNode, isValidElement } from "react";

import { Button } from "@accurx/design";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import DropdownMenuOption from "./DropdownMenuOption.component";

export interface SelectDropdownProps {
    children?: ReactNode;
    /** Whether the dropdown menu is open or not */
    isOpen: boolean;
    /** Dropdown toggle handler */
    onToggle: () => void;
    /** Dropdown button text */
    dropdownText?: string;
}

/**
 * Outputs a dropdown styled like a select field, with a top shade and an arrow icon at the end
 * */
const SelectDropdown = ({
    isOpen,
    onToggle,
    dropdownText,
    children,
}: SelectDropdownProps): JSX.Element | null => {
    const dropdownOptions = Children.map(children, (child) => {
        if (!isValidElement(child) || child.type !== DropdownMenuOption) {
            console.error(
                "You should only use DropdownMenuOption component as children of this component",
            );
            return null;
        }

        return child;
    })?.filter(Boolean);

    if (!dropdownOptions?.length) {
        console.error(
            "You must provide at least one valid `DropdownMenuOption`",
        );
        return null;
    }

    const handleDropdownKeyUp = (e: KeyboardEvent<HTMLButtonElement>): void => {
        if (e.key === "Enter") onToggle();
    };

    return (
        <Dropdown isOpen={isOpen} toggle={onToggle}>
            <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                aria-expanded={isOpen}
            >
                <Button
                    theme="secondary"
                    icon={{
                        name: "Arrow",
                        colour: "zinc",
                        rotation: isOpen ? "up" : "down",
                        placement: "end",
                        title: "Toggle dropdown",
                        id: "toggle-dropdown-btn",
                    }}
                    text={dropdownText}
                    className="select-dropdown-toggle text-truncate w-100"
                    onKeyUp={handleDropdownKeyUp}
                />
            </DropdownToggle>
            <DropdownMenu className="p-1 w-100 text-truncate">
                {dropdownOptions}
            </DropdownMenu>
        </Dropdown>
    );
};

export default SelectDropdown;
