export const getBadgeColour = (
    diff: number | undefined,
): "greyscale" | "red" | "yellow" | "green" => {
    if (diff === undefined) return "greyscale";
    if (diff >= 60) return "red";
    if (diff >= 10) return "yellow";
    return "green";
};

export const getIconColour = (
    diff: number | undefined,
): "stone" | "red" | "orange" | "green" => {
    if (diff === undefined) return "stone";
    if (diff >= 60) return "red";
    if (diff >= 10) return "orange";
    return "green";
};

export const getBadgeText = (diff: number | undefined): string => {
    if (diff === undefined) return "Never";
    if (diff === 0) return "Now";
    if (diff >= 60) {
        const numberOfHours = Math.floor(diff / 60);
        return `${numberOfHours} hour${numberOfHours > 1 ? "s" : ""} ago`;
    }
    return `${diff} minute${diff > 1 ? "s" : ""} ago`;
};
