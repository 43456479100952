import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

const SHORT_SCREEN_BREAKPOINT = 710;

export const StyledResponsiveText = styled.span<{ $hideOnMobile: boolean }>`
    display: ${({ $hideOnMobile }) => ($hideOnMobile ? "none" : "inline")};
    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        display: ${({ $hideOnMobile }) => ($hideOnMobile ? "inline" : "none")};
    }
`;

export const StyledWhiteBackground = styled(StackPanel)`
    background-color: ${Tokens.COLOURS.greyscale.white};
    height: 90vh;
    width: 100%;
    padding: ${Tokens.SIZES[5]};
    max-height: 800px;

    @media (max-height: ${SHORT_SCREEN_BREAKPOINT}px) {
        background-color: transparent;
    }
`;

export const StyledMessageContainer = styled(StackPanel)`
    width: 100%;
    height: 100%;
    padding-bottom: ${Tokens.SIZES[3]};

    @media (max-height: ${SHORT_SCREEN_BREAKPOINT}px) {
        display: none;
    }
`;

export const StyledLoginFormWrapper = styled.div`
    width: 100%;
    text-align: start;
`;
