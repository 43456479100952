import { useCallback, useEffect, useState } from "react";

import { useCurrentUser, useCurrentWorkspace } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";
import { Log } from "@accurx/shared";
import { useTransport } from "@accurx/transport";

import { SocketEvents } from "shared/hubClient/HubClient";
import {
    ConnectedStatus,
    ConnectedStatusStatus,
    MachineChangeStatusReceiveStatus as UserStatus,
} from "shared/hubClient/payload.types";

type UserIdsOnlineSet = Set<string>;

export const useUserOnline = (): Set<string> => {
    const { orgId } = useCurrentWorkspace();
    const { user } = useCurrentUser();
    const { embedMode } = getEmbedMode();
    const isWeb = embedMode === "Web";

    const { transport, connectionState } = useTransport();

    const [usersOnlineSet, setUsersOnlineSet] = useState<UserIdsOnlineSet>(
        new Set([]),
    );

    const addUser = (userId: string) => {
        setUsersOnlineSet((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.add(userId);
            return newSet;
        });
    };

    const removeUser = (userId: string) => {
        setUsersOnlineSet((prevSet) => {
            const newSet = new Set(prevSet);
            newSet.delete(userId);
            return newSet;
        });
    };

    const broadcastStatus = useCallback(
        (
            statusType: UserStatus.OnConnected | UserStatus.OnDisconnected,
            userId: string,
        ) => {
            const isCurrentUserDisconnected =
                statusType === UserStatus.OnDisconnected;

            const connectedStatusRequest: ConnectedStatus = {
                organisationId: orgId,
                status: isCurrentUserDisconnected
                    ? ConnectedStatusStatus.StatusUpdateIdle
                    : ConnectedStatusStatus.StatusUpdateActive,
                notifySingleUserId: isCurrentUserDisconnected ? null : userId,
                machineIdentifier: null,
            };

            void transport?.send({
                methodName: "ConnectedStatus",
                payload: connectedStatusRequest,
            });
        },
        [transport, orgId],
    );

    const updateUserStatus = useCallback(
        (statusType: UserStatus, userId: string) => {
            switch (statusType) {
                case UserStatus.OnConnected: {
                    addUser(userId);

                    if (isWeb && userId !== user.accuRxUserId) {
                        broadcastStatus(statusType, userId);
                    }
                    break;
                }

                case UserStatus.OnDisconnected: {
                    removeUser(userId);

                    if (isWeb && userId === user.accuRxUserId) {
                        broadcastStatus(statusType, userId);
                    }
                    break;
                }

                case UserStatus.StatusUpdateActive: {
                    addUser(userId);
                    break;
                }

                case UserStatus.StatusUpdateIdle: {
                    removeUser(userId);
                    break;
                }

                case UserStatus.None:
                    break;
                default:
                    Log.warn(
                        "Unknown OnMachineChangeStatus status type",
                        statusType,
                    );
            }
        },
        [broadcastStatus, isWeb, user.accuRxUserId],
    );

    useEffect(() => {
        if (connectionState !== "Connected" || !transport) return;

        const subscription = transport.subscribe({
            methodName: SocketEvents.OnMachineChangeStatus,
            eventFilter: (event) => event.payload.organisationId === orgId,
            onEvent: (event) => {
                const { status, fromUser } = event.payload;
                if (!fromUser) return;
                updateUserStatus(status, fromUser.accuRxId);
            },
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [transport, updateUserStatus, orgId, connectionState]);

    return usersOnlineSet;
};
