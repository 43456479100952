/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { createContext, useContext } from "react";

type MedicalRecordColumnContext = {
    // Which side column are we in - if undefined, we assume no columns are present
    column?: "left" | "right";
};

const MedicalRecordColumnContext = createContext<MedicalRecordColumnContext>(
    {},
);
MedicalRecordColumnContext.displayName = "MedicalRecordColumnContext";

const MedicalRecordColumnProvider = MedicalRecordColumnContext.Provider;

const useMedicalRecordColumns = (): MedicalRecordColumnContext => {
    const context = useContext(MedicalRecordColumnContext);

    if (context === undefined) {
        throw new Error(
            "This component is a MedicalRecordColumnConsumer, it must be used within a MedicalRecordColumnProvider",
        );
    }

    return context;
};

export { MedicalRecordColumnProvider, useMedicalRecordColumns };
