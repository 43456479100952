import React from "react";

import { Text } from "@accurx/design";
import { useLocation } from "react-router-dom";

import { useConversationGroupStatus } from "shared/concierge/conversations/hooks/useConversationGroupStatus";

import {
    StyledSwitchButton,
    StyledSwitcherContainer,
} from "./ConversationGroupStatusSwitcher.styles";

export const ConversationGroupStatusSwitcher = (): JSX.Element => {
    const location = useLocation();
    const status = useConversationGroupStatus();

    return (
        <StyledSwitcherContainer role="group" aria-label="Conversation status">
            <Text variant="label" skinny>
                Show:
            </Text>
            <StyledSwitchButton
                role="button"
                to={{ pathname: location.pathname }}
                isActive={() => status === "Open"}
                aria-pressed={status === "Open"}
            >
                Open
            </StyledSwitchButton>
            <StyledSwitchButton
                role="button"
                to={{
                    pathname: location.pathname,
                    search: "?status=done",
                }}
                isActive={() => status === "Done"}
                aria-pressed={status === "Done"}
            >
                Done
            </StyledSwitchButton>
        </StyledSwitcherContainer>
    );
};
