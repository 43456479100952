import { createContext } from "react";

import { FromNewConversation } from "./FromNewConversation";
import { FromTestConversation } from "./FromTestConversation";
import { FromUrlId } from "./FromUrlId";
import { FromWorkspaceConversationId } from "./FromWorkspaceConversationId";
import { ClinicianConversationContextType } from "./types";

export const ClinicianConversationContext =
    createContext<ClinicianConversationContextType>(null);

export const ClinicianConversationProvider = {
    FromNewConversation,
    FromUrlId,
    FromWorkspaceConversationId,
    FromTestConversation,
};
