import { NoteTextSegment } from "@accurx/api/ticket";
import { NoteSegment } from "domains/concierge/types";

export const mapNoteSegmentToConversationNoteSegment = (
    note: Extract<NoteSegment, { type: "Text" }>,
): NoteTextSegment => {
    return {
        type: 1,
        text: note.text,
        order: 0,
    };
};
