import React from "react";

import {
    Card,
    Feedback,
    Flex,
    Icon,
    Spinner,
    Text,
    Thread,
} from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";

import { mapTicketPatientToPatientSummaryNoPatientIdRequired } from "app/questionnaireScoreboard/mappers";
import { renderPatientResponseSections } from "app/workspaceConversations/components/Conversation/ConversationThread/ConversationThread.helper";
import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { getPatientNhsNumber } from "app/workspaceConversations/utils/patient.utils";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";

import { CalculatedComplexityBadge } from "../responsesTable/CalculatedComplexityBadge";
import { QuestionnaireStatusBadge } from "../responsesTable/QuestionnaireStatusBadge";
import { ValidatedComplexityBadge } from "../responsesTable/ValidatedComplexityBadge";
import {
    StyledAlignedText,
    StyledFloreyResponse,
    StyledList,
    StyledListItem,
    StyledPatientDetailsHeader,
    StyledWrapper,
} from "./QuestionnaireResponseStatus.styles";
import { SubmitClinicianStatusCard } from "./SubmitClinicianStatusCard";
import { ValidationCard } from "./ValidationCard";
import {
    mapFloreyScoreResponseToFloreyConversationItem,
    mapFloreyScoreValidationsToValidationDisplayByDate,
} from "./mappers";
import { useGetFloreyScoreResponseQuery } from "./useGetFloreyScoreResponseQuery";

type QuestionnaireResponseStatusProps = {
    floreyEnrolmentId: number;
    workspaceId: string;
    questionnaireId: string;
};

export const QuestionnaireResponseStatus = ({
    workspaceId,
    floreyEnrolmentId,
    questionnaireId,
}: QuestionnaireResponseStatusProps) => {
    const { status, data } = useGetFloreyScoreResponseQuery({
        workspaceId,
        floreyEnrolmentId,
    });

    if (status === "loading") {
        return (
            <StyledWrapper>
                <Spinner />
            </StyledWrapper>
        );
    }

    if (status === "error") {
        return (
            <StyledWrapper>
                <Feedback
                    colour="error"
                    title="Something went wrong fetching questionnaire response"
                />
            </StyledWrapper>
        );
    }

    const conversationItem =
        mapFloreyScoreResponseToFloreyConversationItem(data);

    // Conversation item can be null if we are unable to map any of the questions or answers in the response.
    if (conversationItem === null) {
        return (
            <StyledWrapper>
                <Feedback
                    colour="error"
                    title="Something went wrong fetching questionnaire response"
                />
            </StyledWrapper>
        );
    }

    const validationsByDate =
        mapFloreyScoreValidationsToValidationDisplayByDate(data.validations);

    const patientSummary = mapTicketPatientToPatientSummaryNoPatientIdRequired(
        data.patient,
    );

    const nhsNumber =
        patientSummary?.externalIds &&
        getPatientNhsNumber(patientSummary.externalIds);

    const displayNhsNumber = nhsNumber ? formatNhsNumber(nhsNumber) : "Unknown";

    const patientDisplayName =
        formatPatientDisplayName({
            firstName: patientSummary?.firstName || null,
            familyName: patientSummary?.familyName || null,
        }) || "Unknown";

    const displayDateOfBirth = patientSummary
        ? DateHelpers.formatDate(
              patientSummary.dateOfBirth,
              DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
          )
        : "Unknown";

    return (
        <>
            <StyledPatientDetailsHeader flexDirection="column" gap="1">
                <Flex
                    justifyContent="space-between"
                    alignItems="start"
                    flexDirection="column"
                    gap="1"
                >
                    <Flex gap="1">
                        <CalculatedComplexityBadge
                            dataTestId="questionnaire-complexity"
                            complexity={data.complexity}
                            verbose
                        />
                        {!data.isValidated && (
                            <QuestionnaireStatusBadge
                                dataTestId="questionnaire-unvalidated-status"
                                status="Pending"
                                verbose
                            />
                        )}
                    </Flex>
                    <Text skinny variant="subtitle">
                        {patientDisplayName}
                    </Text>
                    <Text skinny variant="preview">
                        NHS: {displayNhsNumber} &nbsp;· Born:{" "}
                        {displayDateOfBirth} &nbsp;· Gender:{" "}
                        {patientSummary?.gender || "Unknown"}
                    </Text>
                </Flex>
            </StyledPatientDetailsHeader>
            <StyledWrapper>
                <StyledList>
                    <Thread.DateDivider
                        date={DateHelpers.formatTime(
                            conversationItem.createdAt,
                            DateFormatOptions.DATE_SHORT_WITH_SPACES,
                        )}
                    />
                    <StyledListItem>
                        <Card spacing={1.5}>
                            <Flex flexDirection={"column"} gap="1.5">
                                <Flex gap="0.5" alignItems="center">
                                    <Icon name="Questionnaire" size={3} />
                                    <Text variant="label" skinny>
                                        {conversationItem.floreyName ||
                                            "Questionnaire response"}
                                    </Text>
                                </Flex>
                                <StyledFloreyResponse>
                                    {renderPatientResponseSections({
                                        item: conversationItem,
                                        patientId: patientSummary?.patientId,
                                        organisationId: parseInt(
                                            workspaceId,
                                            10,
                                        ),
                                    })}
                                </StyledFloreyResponse>
                                <StyledAlignedText skinny variant="preview">
                                    {formatConversationItemDateCreatedShort(
                                        conversationItem.createdAt,
                                    )}
                                </StyledAlignedText>
                            </Flex>
                        </Card>
                    </StyledListItem>
                    <Thread.DateDivider
                        date={DateHelpers.formatTime(
                            conversationItem.createdAt,
                            DateFormatOptions.DATE_SHORT_WITH_SPACES,
                        )}
                    />
                    <StyledListItem>
                        <ValidationCard
                            heading="Status - Unvalidated"
                            complexityBadge={
                                <CalculatedComplexityBadge
                                    complexity={data.unvalidatedScore}
                                    verbose
                                />
                            }
                            validatedAt={formatConversationItemDateCreatedShort(
                                conversationItem.createdAt,
                            )}
                        />
                    </StyledListItem>
                    {validationsByDate.map(([date, validations], index) => (
                        <React.Fragment key={`${date}-${index}`}>
                            <Thread.DateDivider date={date} />
                            {validations.map((validation, index) => (
                                <StyledListItem
                                    key={`${validation.validatedAt}-${index}`}
                                >
                                    <ValidationCard
                                        {...validation}
                                        complexityBadge={
                                            <ValidatedComplexityBadge
                                                complexity={
                                                    validation.complexity
                                                }
                                            />
                                        }
                                        key={`${validation.validatedAt}-${index}`}
                                    />
                                </StyledListItem>
                            ))}
                        </React.Fragment>
                    ))}
                </StyledList>
                <SubmitClinicianStatusCard
                    workspaceId={workspaceId}
                    floreyEnrolmentId={floreyEnrolmentId}
                    questionnaireId={questionnaireId}
                />
            </StyledWrapper>
        </>
    );
};
