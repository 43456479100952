import { LoadingCard } from "domains/inbox/components/ConversationList/components/LoadingCard/LoadingCard";

import {
    StyledConversationFeed,
    StyledLoadingStateContainer,
} from "./ConversationFeed.styles";

export const ConversationFeedLoadingSkeleton = () => {
    return (
        <StyledLoadingStateContainer>
            <StyledConversationFeed aria-label="Loading conversation items">
                <>
                    {new Array(3).fill(null).map((_, index) => (
                        <LoadingCard key={`loading-${index}`} />
                    ))}
                </>
            </StyledConversationFeed>
        </StyledLoadingStateContainer>
    );
};
