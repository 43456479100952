import { StyledHeader, StyledText } from "./EditTemplatePageHeader.styles";

type EditTemplatePageHeaderProps = { title: string; subtitle: string };

export const EditTemplatePageHeader = ({
    title,
    subtitle,
}: EditTemplatePageHeaderProps) => {
    return (
        <>
            <StyledHeader>{title}</StyledHeader>
            <StyledText>{subtitle}</StyledText>
        </>
    );
};
