import React from "react";

import { PatientListType } from "@accurx/api/patient-messaging";

import { StyledListBadge } from "./PatientList.styles";

type PatientListBadgeProps = {
    listType?: PatientListType;
};

const PatientListBadge = ({ listType }: PatientListBadgeProps) => {
    switch (listType) {
        case PatientListType.PAS:
            return (
                <StyledListBadge color="blue">
                    PAS integrated list
                </StyledListBadge>
            );
        case PatientListType.WorkspaceManaged: {
            return <StyledListBadge color="blue">Shared list</StyledListBadge>;
        }
        default:
            return null;
    }
};

export default PatientListBadge;
