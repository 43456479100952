import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { useAppSelector, useCurrentOrgId } from "store/hooks";

import { getMessageTemplates } from "./ManageTemplates.actions";

export const useManageTemplatesData =
    (): ApplicationState["manageTemplates"] => {
        const dispatch = useDispatch();
        const orgId = useCurrentOrgId();

        useEffect(() => {
            if (orgId) {
                dispatch(
                    getMessageTemplates({
                        organisationId: orgId,
                        includeVideo: false,
                    }),
                );
            }
        }, [dispatch, orgId]);

        return useAppSelector(({ manageTemplates }) => manageTemplates);
    };
