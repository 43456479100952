import React, { useState } from "react";

import { Card, Text } from "@accurx/design";
import { shallowEqual } from "react-redux";

import { StackPanel } from "app/sharedComponents/StackPanel";
import { ReactComponent as PatientListEmptyIllustration } from "shared/images/CardIllustration.svg";
import { useAppSelector } from "store/hooks";

import { ClinicDayFilter } from "./ClinicDayFilter";
import { PatientListAppointments } from "./PatientListAppointments";
import {
    StyledColumns,
    StyledEmptyListDiv,
    StyledSplitRow,
} from "./PatientListPage.styles";
import { SortByFilter } from "./SortByFilter";

export const PatientListReadonly = (): JSX.Element => {
    const currentListPatientDetails = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.patientDetails || null,
        shallowEqual,
    );
    const currentListDays = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.appointmentDays || null,
        shallowEqual,
    );

    const [selectedDateIndex, setSelectedDateIndex] = useState(0);
    const currentDaySelected =
        currentListDays && currentListDays.length > selectedDateIndex
            ? currentListDays[selectedDateIndex]
            : null;

    const handleDaySelected = setSelectedDateIndex;

    const hasEntries = currentListPatientDetails
        ? Object.keys(currentListPatientDetails).length > 0
        : false;

    return (
        <>
            {!hasEntries && ( // We have no entries and we are not currently adding
                <Card variant="dashed">
                    <StyledEmptyListDiv>
                        <PatientListEmptyIllustration className="mw-100" />
                        <Text
                            variant="subtitle"
                            as="h2"
                            props={{
                                className: "mb-3",
                            }}
                        >
                            Your patient list is empty
                        </Text>
                    </StyledEmptyListDiv>
                </Card>
            )}
            {currentListDays && currentListDays?.length > 0 && (
                <>
                    <StyledSplitRow>
                        <StyledColumns>
                            <ClinicDayFilter
                                selectedDateIndex={selectedDateIndex}
                                onDaySelected={handleDaySelected}
                            />
                        </StyledColumns>
                        {currentDaySelected && (
                            <StyledColumns>
                                <StackPanel orientation="horizontal" gutter={2}>
                                    <div className="flex-grow-1">
                                        <SortByFilter
                                            currentDaySelected={
                                                currentDaySelected
                                            }
                                        />
                                    </div>
                                </StackPanel>
                            </StyledColumns>
                        )}
                    </StyledSplitRow>
                    {currentDaySelected?.appointments &&
                        currentListPatientDetails && (
                            <PatientListAppointments
                                isReadonly={true}
                                appointments={currentDaySelected?.appointments}
                                patientDetails={currentListPatientDetails}
                            />
                        )}
                </>
            )}
        </>
    );
};
