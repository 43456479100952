import {
    Participant as ParticipantDto,
    ParticipantWorkspace,
} from "api/ClinicianConversationApi";

import { ConversationParticipants, Participant } from "./ParticipantsPanel";

const mapParticipant = (
    { displayName, emailAddress }: ParticipantDto,
    userEmailAddress?: string,
): Participant => ({
    displayName: displayName && displayName.trim() ? displayName : undefined,
    emailAddress: emailAddress.toLocaleLowerCase(),
    isCurrentUser:
        !!userEmailAddress &&
        emailAddress.toLowerCase() === userEmailAddress.toLowerCase(),
});

export const getParticipantsFromConversation = ({
    individualParticipants,
    participantWorkspaces,
    userEmailAddress,
}: {
    individualParticipants: ParticipantDto[];
    participantWorkspaces: ParticipantWorkspace[];
    userEmailAddress?: string;
}): ConversationParticipants => ({
    participantWorkspaces: (participantWorkspaces ?? []).map(
        (participantWorkspace) => ({
            workspaceId: participantWorkspace.workspaceId,
            workspaceName: participantWorkspace.workspaceName,
            participants: participantWorkspace.participants.map((p) =>
                mapParticipant(p, userEmailAddress),
            ),
        }),
    ),
    individualParticipants: individualParticipants.map((p) =>
        mapParticipant(p, userEmailAddress),
    ),
});
