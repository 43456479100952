import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export const VideoConsult = () => {
    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text
                    as="span"
                    variant="note"
                    props={{
                        "aria-label": `Responded by: Accurx.`,
                    }}
                >
                    Accurx:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text
                    as="span"
                    variant="preview"
                    props={{
                        "aria-label": `Message summary: Video consultation.`,
                    }}
                >
                    Video consultation
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
