import { MouseEventHandler } from "react";

import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledNav = styled.nav`
    padding: ${Tokens.SIZES[0.5]} 0;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]};
    }
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

type NavigationBarProps = {
    onBackButtonClick: MouseEventHandler<HTMLButtonElement>;
};

export const NavigationBar = ({ onBackButtonClick }: NavigationBarProps) => {
    return (
        <StyledNav>
            <Button
                text="Back"
                theme="transparent"
                dimension="medium"
                icon={{
                    name: "ArrowTail",
                    rotation: "left",
                    placement: "start",
                }}
                onClick={onBackButtonClick}
            />
        </StyledNav>
    );
};
