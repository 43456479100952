import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledUnderLineText = styled(Text)`
    text-decoration: underline;
    display: flex;
    align-items: center;
`;

export const StyledComponent = styled.div`
    margin-bottom: ${Tokens.SIZES[3]};
`;
