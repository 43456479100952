import React from "react";

import { ActionCard, Thread } from "@accurx/design";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { AppointmentRequestConversationItem } from "shared/concierge/conversations/types/item.types";

import { renderPatientResponseSections } from "../../ConversationThread.helper";
import { BaseConversationThreadItem } from "../threadItem.types";
import { StyledActionCardWrapper } from "./AppointmentRequestConversationThreadItem.styles";

type AppointmentRequestConversationThreadItemProps =
    BaseConversationThreadItem<AppointmentRequestConversationItem> & {
        organisationId: number;
        patientId: string | null | undefined;
    };

const getTopic = (item: AppointmentRequestConversationItem): string => {
    if (item.requestType === "None") {
        return "Appointment request";
    }

    return `Appointment request - ${item.requestType}`;
};

export const AppointmentRequestConversationThreadItem = ({
    item,
    patientId,
    organisationId,
    handleOnItemRead,
}: AppointmentRequestConversationThreadItemProps) => {
    const renderRequestSections = (): JSX.Element[] => {
        return renderPatientResponseSections({
            item,
            patientId,
            organisationId,
            hideAttachmentsLabel: true,
            noResponseSection: "No information supplied",
        });
    };

    return (
        <Thread.Message
            inboxType="received"
            dateCreated={formatConversationItemDateCreatedShort(item.createdAt)}
            senderFullName={null}
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <StyledActionCardWrapper>
                <ActionCard>
                    <ActionCard.Header
                        iconName="Message"
                        contentType={getTopic(item)}
                    />
                    <ActionCard.Body colour={"blue"}>
                        {renderRequestSections()}
                    </ActionCard.Body>
                </ActionCard>
            </StyledActionCardWrapper>
        </Thread.Message>
    );
};

export const appointmentRequestThreadItemSummaryText = (
    item: AppointmentRequestConversationItem,
) => getTopic(item);
