import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

/**
 *  Used exactly like useState but returns the previous state as well so you can compare:
 *  [previousState, state, setState]
 */
export const usePreviousState: {
    <S>(initialState: (() => S) | S): [S, S, Dispatch<SetStateAction<S>>];
    <S = undefined>(): [S, S, Dispatch<SetStateAction<S>>];
} = <S>(initialState?: () => S) => {
    const [state, setState] = useState(initialState);
    const previousState = usePrevious(state);

    return [previousState, state, setState] as [
        S,
        S,
        Dispatch<SetStateAction<S>>,
    ];
};

/**
 * Returns the previous value
 */
export function usePrevious<T>(value?: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
