import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { SelfbookConfigurationPayload } from "domains/self-book/utils/types";

import { SelfbookConfigurationForm } from "../SelfbookConfigurationForm";

type SelfbookConfiguratioQuickViewProps = {
    showSelfbookConfiguration: boolean;
    setShowSelfbookConfiguration: (showConfig: boolean) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
};

export const SelfbookConfigurationQuickView = ({
    showSelfbookConfiguration,
    setShowSelfbookConfiguration,
    onSelfbookConfigComplete,
}: SelfbookConfiguratioQuickViewProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const workspace = useCurrentWorkspace();

    const isCrossOrgEnabled = useFeatureFlag("HasCrossOrgBooking");

    const [isTelephoneFlow, setIsTelephoneFlow] = useState<boolean>(false);

    const resetFormState = () => {
        setIsTelephoneFlow(false);
        setShowSelfbookConfiguration(false);
    };

    return (
        <QuickViewPortal
            isOpen={showSelfbookConfiguration}
            onClose={resetFormState}
        >
            <QuickViewPortal.Header>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => {
                        resetFormState();
                        track(
                            "BookingInviteClose Button Click",
                            nativeTrackingFields,
                        );
                    }}
                >
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>
                {isTelephoneFlow && (
                    <Pill.SecondaryButton
                        dimension="small"
                        onClick={() => {
                            setIsTelephoneFlow(false);
                        }}
                    >
                        <Pill.Icon
                            name="ArrowTail"
                            colour="blue"
                            size={3}
                            rotation="left"
                        />
                        <Pill.Text>Back</Pill.Text>
                    </Pill.SecondaryButton>
                )}
            </QuickViewPortal.Header>

            <SelfbookConfigurationForm
                orgId={workspace.orgId}
                organisationName={workspace.organisationName}
                showCrossOrg={isCrossOrgEnabled}
                isTelephoneFlow={isTelephoneFlow}
                appOrigin="UnifiedInbox"
                header={
                    <UI.Text variant="label" skinny>
                        Add a Self-Book link
                    </UI.Text>
                }
                onSelfbookConfigComplete={(selfbookConfigData) => {
                    if (
                        selfbookConfigData.appointmentType === "FaceToFace" ||
                        isTelephoneFlow
                    ) {
                        onSelfbookConfigComplete(selfbookConfigData);
                        resetFormState();
                    } else {
                        setIsTelephoneFlow(true);
                    }
                }}
            />
        </QuickViewPortal>
    );
};
