import { useEffect } from "react";

import sortBy from "lodash/sortBy";
import { useHistory } from "react-router-dom";

import { IOrganisation } from "api/FlemingDtos";
import { LoadingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

export type UseOrganisationDropdown = () => {
    loading: LoadingStatus;
    currentOrganisation: Optional<IOrganisation>;
    organisations: IOrganisation[];
};

export const useOrganisationDropdown: UseOrganisationDropdown = () => {
    const loading = useAppSelector(
        ({ account }) => account.isOrganisationsLoading,
    );

    const currentOrganisation = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisation(account),
    );

    const organisations = useAppSelector(({ account: { user } }) =>
        user?.organisations ? [...user.organisations] : [],
    );

    const history = useHistory();

    // if we don't have a current organisation,
    // redirect to the choose organisation page
    useEffect(() => {
        if (loading === LoadingStatus.Loaded && !!currentOrganisation) {
            history.push(ROUTES_CHAIN.practices);
        }
    }, [history, loading, currentOrganisation]);

    return {
        loading,
        currentOrganisation,
        organisations: sortBy(organisations, (o) =>
            o.organisationName.toLocaleLowerCase(),
        ),
    };
};
