import { PatientAppointmentRequestItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type PatientAppointmentRequestProps = {
    conversationItem: PatientAppointmentRequestItem;
};

export const PatientAppointmentRequest = ({
    conversationItem,
}: PatientAppointmentRequestProps) => {
    const summary =
        conversationItem.requestType === "None"
            ? "Appointment"
            : `Appointment ${conversationItem.requestType.toLowerCase()}`;
    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    Patient request:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {summary}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
