import { Flex, Icon, IconNameVariants, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledContainer = styled(Flex).attrs(() => ({
    alignItems: "center",
    gap: "1",
}))`
    margin-top: ${Tokens.SIZES[1.5]};
    margin-bottom: ${Tokens.SIZES[1]};
`;

type RecentSearchesHeaderProps = {
    iconName: IconNameVariants;
    title: string;
    counter: number;
};

export const RecentSearchesHeader = ({
    iconName,
    title,
    counter,
}: RecentSearchesHeaderProps): JSX.Element => {
    return (
        <StyledContainer>
            <Icon
                name={iconName}
                size={4}
                halo={{
                    colour: "blue",
                    luminosity: "high",
                }}
            />
            <Text skinny variant="label" as="h3">
                {title}
            </Text>
            {counter > 0 && <Text skinny>· {counter}</Text>}
        </StyledContainer>
    );
};
