import { Log } from "@accurx/shared";
import { DEFAULT_PARAMS, InboxParams } from "domains/inbox/routes";
import isNil from "lodash/isNil";
import upperFirst from "lodash/upperFirst";
import { useParams } from "react-router";

import { useSearchParams } from "./useSearchParams";

const parseStatus = (status: string): "Open" | "Done" => {
    const parsed = upperFirst(status.toLowerCase());
    if (parsed === "Open" || parsed === "Done") {
        return parsed;
    } else {
        Log.error(`Unexpected status param: ${status}`, {
            tags: { status },
        });
        return "Open";
    }
};

const parseNumber =
    (field: string) =>
    (value: string): number => {
        try {
            return parseInt(value);
        } catch (e) {
            Log.error(`Could not parse ${field} param: ${value}`, {
                tags: {
                    field,
                    value,
                },
            });
            throw e;
        }
    };

const parsers: Partial<{
    [K in keyof InboxParams]: (param: string) => InboxParams[K];
}> = {
    status: parseStatus,
    workspaceId: parseNumber("workspaceId"),
    conversationId: decodeURIComponent,
};

/*
 */
export const useInboxParams = <RequiredParams extends keyof InboxParams>(
    params: RequiredParams[],
): { [K in RequiredParams]: InboxParams[K] } => {
    const pathParams = useParams<{ [K in keyof InboxParams]: string }>();
    const searchParams = useSearchParams();

    return params.reduce((acc, k) => {
        if (isNil(pathParams[k]) && !searchParams[k] && !DEFAULT_PARAMS[k]) {
            Log.error(`Expected a param but it wasn't set: ${k}`);
            // rather than blow up here, we return the incorrectly typed
            // null value. really not great but there's at least a chance
            // that things will still work for the user
            return { ...acc, [k]: null };
        } else if (isNil(pathParams[k]) && !searchParams[k]) {
            return { ...acc, [k]: DEFAULT_PARAMS[k] };
        } else {
            const raw = pathParams[k] || searchParams[k];
            const parser = parsers[k];
            const parsed = parser && raw ? parser(raw) : raw;

            return { ...acc, [k]: parsed };
        }
    }, {} as { [K in RequiredParams]: InboxParams[K] });
};

export const useInboxOptionalParams = <Param extends keyof InboxParams>(
    params: Param[],
): Partial<{ [K in Param]: InboxParams[K] }> => {
    const pathParams = useParams<{ [K in keyof InboxParams]: string }>();
    const searchParams = useSearchParams();

    return params.reduce((acc, k) => {
        if (isNil(pathParams[k]) && !searchParams[k] && !DEFAULT_PARAMS[k]) {
            return acc;
        } else if (isNil(pathParams[k]) && !searchParams[k]) {
            return { ...acc, [k]: DEFAULT_PARAMS[k] };
        } else {
            const raw = pathParams[k] || searchParams[k];
            const parser = parsers[k];
            const parsed = parser && raw ? parser(raw) : raw;

            return { ...acc, [k]: parsed };
        }
    }, {} as { [K in Param]: InboxParams[K] });
};
