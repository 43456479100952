import { ChangeEvent, useState } from "react";

import {
    Card,
    Feedback,
    Grid,
    RadioGroup,
    Spinner,
    Text,
} from "@accurx/design";

import { useTeamUsageQuery } from "app/hooks/queries";
import { useCurrentOrgId, useIsUserApproved } from "store/hooks";

import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "../../UsageDashboardPage.consts";
import { StyledContainer } from "./TeamUsage.styles";
import { FilterTimeTeamUsage } from "./TeamUsage.types";
import { TeamUsageTable } from "./TeamUsageTable";

export const TeamUsage = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const [filter, setFilter] = useState(FilterTimeTeamUsage.ALL_TIME);
    const isApprovedUser = useIsUserApproved();

    const {
        data: teamUsage,
        isLoading,
        isError,
        isSuccess,
        error,
    } = useTeamUsageQuery({ workspaceId }, { enabled: isApprovedUser });

    const handleFilter = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilter(e.target.value as FilterTimeTeamUsage);
    };

    const renderFilters = (): JSX.Element => (
        <div>
            <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                <RadioGroup
                    name="time-filter"
                    onChange={handleFilter}
                    checkedValue={filter}
                    columns={2}
                    radioInputs={[
                        {
                            id: "all-time",
                            label: "All time",
                            value: FilterTimeTeamUsage.ALL_TIME,
                        },
                        {
                            id: "seven-days",
                            label: "Last 7 days",
                            value: FilterTimeTeamUsage.LAST_7_DAYS,
                        },
                    ]}
                />
            </div>
        </div>
    );

    return (
        <StyledContainer>
            <Grid gap="2">
                {!isApprovedUser && (
                    <Feedback
                        title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                        colour="warning"
                    >
                        {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                    </Feedback>
                )}
                {isApprovedUser && (
                    <>
                        {isError && (
                            <Feedback
                                title={
                                    error.message ??
                                    "Something went wrong loading your team information. Please refresh the page."
                                }
                                colour="error"
                            />
                        )}

                        {isLoading && <Spinner />}

                        {isSuccess && teamUsage.firstInstall && (
                            <Grid gap={"2"}>
                                <Card
                                    header={
                                        <Text variant="subtitle" as="h2" skinny>
                                            Team usage overview
                                        </Text>
                                    }
                                >
                                    <Text variant="label">Number of users</Text>
                                    <Text props={{ "data-testid": "nb-users" }}>
                                        {teamUsage.numberOfUsers}
                                    </Text>
                                    <Text variant="label">
                                        Number of messages sent since first
                                        install{" "}
                                        <Text
                                            as="span"
                                            props={{
                                                "data-testid":
                                                    "date-first-install",
                                            }}
                                        >
                                            ({teamUsage.firstInstall})
                                        </Text>
                                    </Text>
                                    <Text
                                        props={{
                                            "data-testid": "nb-first-install",
                                        }}
                                    >
                                        {teamUsage.messagesSinceFirstInstall}
                                    </Text>
                                </Card>

                                <Card
                                    header={
                                        <Text variant="subtitle" as="h2" skinny>
                                            Usage breakdown
                                        </Text>
                                    }
                                >
                                    <Text>
                                        You can see how many texts have been
                                        sent by your practice and each team
                                        member.
                                    </Text>
                                    {renderFilters()}
                                    <TeamUsageTable
                                        userUsage={
                                            filter ===
                                            FilterTimeTeamUsage.ALL_TIME
                                                ? teamUsage.userUsageSinceInstall
                                                : teamUsage.userUsageLast7Days
                                        }
                                    />
                                </Card>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </StyledContainer>
    );
};
