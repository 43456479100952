import { ComponentProps, ReactNode } from "react";

import * as UI from "@accurx/design";

import { StyledSingleSelectOverrides } from "./SingleSelect.styles";

export const SingleSelect = ({
    children,
    ...props
}: {
    children: ReactNode;
} & ComponentProps<typeof UI.SingleSelect>) => {
    return (
        <StyledSingleSelectOverrides>
            <UI.SingleSelect {...props}>{children}</UI.SingleSelect>
        </StyledSingleSelectOverrides>
    );
};
