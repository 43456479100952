import { useEffect, useRef, useState } from "react";

import { SnomedCode } from "@accurx/api/florey-builder";
import { FeatureName } from "@accurx/auth";
import {
    Card,
    FormFieldWrapper,
    Option,
    Spinner,
    Text,
    Tokens,
} from "@accurx/design";
import { Editor, EditorProvider } from "@accurx/markdown-editor";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useEditQuestionnaireMutation } from "app/hooks/mutations";
import {
    useGetQuestionnaireQuery,
    useGetSnomedCodesQuery,
} from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { StyledTextareaAutosize } from "app/sharedComponents/textAreaAutosize/TextAreaAutosize.styles";
import { useIsFeatureEnabled } from "store/hooks";

import { mapSnomedListToSelectOptions } from "../FloreyBuilder.reducer";
import {
    NO_SNOMED_CODE_OPTION,
    mapSnomedCodeToConcept,
    withSnomedCode,
} from "../FloreyBuilder.utils";
import {
    connectionProblemsError,
    rowVersionError,
    savingError,
} from "../components/ErrorToasts";
import {
    PageContainerForStickyFooter,
    StyledIndent,
} from "../components/PageContainer.styles";
import { PageTitle } from "../components/PageTitles";
import { SnomedSelectFormField } from "../components/SnomedSelectFormField";
import { routeAssignAndSave, routeInvitationMessage } from "../constants/paths";
import { PageActionText } from "../types/data.types";

const MAX_MESSAGE_CHAR_COUNT = 1000;
const RICH_TEXT_MAX_MESSAGE_CHAR_COUNT = 10_000;

const StyledPaddingTextArea = styled(StyledTextareaAutosize)`
    margin: ${Tokens.SIZES[1]} 0;
`;

const isValidConfirmationMessage = (
    messageText: string | null | undefined,
    maxCharCount: number,
): { isMessageValid: boolean; messageErrorText: string } => {
    if (
        messageText === undefined ||
        messageText === null ||
        messageText?.length === 0
    ) {
        return {
            isMessageValid: false,
            messageErrorText:
                "Write a message to patient when they complete the Florey Questionnaire",
        };
    }
    if (messageText?.trim().length === 0) {
        return {
            isMessageValid: false,
            messageErrorText:
                "Write a non-whitespace message to patient when they complete the Florey Questionnaire",
        };
    }
    if (messageText?.length > maxCharCount) {
        return {
            isMessageValid: false,
            messageErrorText: `Message should be ${maxCharCount} characters or fewer. You entered ${messageText?.length} characters.`,
        };
    }
    return { isMessageValid: true, messageErrorText: "" };
};

export const FloreyBuilderCreateConfirmationMessage = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { orgId } = useParams<{ orgId: string }>();
    const { questionnaireId } = useParams<{ questionnaireId: string }>();
    const [completionMessageErrors, setCompletionMessageErrors] = useState<
        string[]
    >([]);
    const [selectedSnomedOption, setSelectedSnomedOption] = useState<Option>(
        NO_SNOMED_CODE_OPTION,
    );
    const [localMessage, setLocalMessage] = useState("");
    const history = useHistory();

    const isGlobalCustomFloreyEnabled = useIsFeatureEnabled(
        FeatureName.CreateGlobalCustomFloreys,
    );

    const completionMessageRef = useRef<HTMLTextAreaElement | null>(null);

    const { data: questionnaireData, status: questionnaireStatus } =
        useGetQuestionnaireQuery(
            {
                workspaceId: parseInt(orgId, 10),
                questionnaireId,
            },
            {
                onSuccess: (data) => {
                    setLocalMessage(data.completionText ?? "");
                    data.completionSnomedCode &&
                        setSelectedSnomedOption({
                            label: data.completionSnomedCode.term,
                            value: data.completionSnomedCode.conceptId,
                        } as Option);
                },
                onError: () => connectionProblemsError(),
            },
        );

    const { data: snomedData, status: snomedStatus } = useGetSnomedCodesQuery(
        {
            workspaceId: parseInt(orgId, 10),
            context: "floreybuilder",
        },
        {
            onError: () => connectionProblemsError(),
        },
    );

    const editQuestionnaireMutation = useEditQuestionnaireMutation({
        onError: (error) => {
            savingError(error.message);
        },
        onSuccess: () => {
            history.push(routeAssignAndSave(orgId, questionnaireId));
        },
    });

    const snomedOptions: Option[] = mapSnomedListToSelectOptions(
        snomedData ?? [],
    );

    useEffect(() => {
        FlemingAnalyticsTracker.trackQuestionnaireConfirmMessage({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
        });
    }, [analyticsLoggedInProps, questionnaireId]);

    const handleSaveConfirmationPage = () => {
        FlemingAnalyticsTracker.trackQuestionnairePageContinue({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireConfirmationMessage",
            withSnomedCode: withSnomedCode(
                questionnaireData?.enrolmentSnomedCode,
            ),
        });
        const { isMessageValid, messageErrorText } = isValidConfirmationMessage(
            localMessage,
            isGlobalCustomFloreyEnabled
                ? RICH_TEXT_MAX_MESSAGE_CHAR_COUNT
                : MAX_MESSAGE_CHAR_COUNT,
        );
        if (!isMessageValid) {
            setCompletionMessageErrors([messageErrorText]);
            completionMessageRef.current?.focus();
            return;
        }
        if (!questionnaireData?.questionnaireRowVersion) {
            rowVersionError();
            return;
        } else {
            editQuestionnaireMutation.mutate({
                questionnaireId: parseInt(questionnaireId),
                organisationId: parseInt(orgId),
                completionText: localMessage,
                completionSnomedCode: mapSnomedCodeToConcept({
                    conceptId: selectedSnomedOption.value,
                    term: selectedSnomedOption.label,
                } as SnomedCode),
                questionnaireRowVersion:
                    questionnaireData?.questionnaireRowVersion,
            });
        }
    };

    const handleBackClick = (): void => {
        FlemingAnalyticsTracker.trackQuestionnairePageBack({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireConfirmationMessage",
        });
        history.push(routeInvitationMessage(orgId, questionnaireId));
    };

    const handleSetCompletionText: React.FormEventHandler<
        HTMLTextAreaElement
    > = (event) => {
        const message = event.currentTarget.value;
        if (message !== undefined && message.length !== 0) {
            setCompletionMessageErrors([]);
        }
        setLocalMessage(message);
    };

    const handleSelectSnomedCode = (selected: Option) => {
        FlemingAnalyticsTracker.trackQuestionnaireSnomedOption({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireConfirmationMessage",
        });
        setSelectedSnomedOption(selected);
    };

    return (
        <>
            <PageContainerForStickyFooter>
                <StackPanel>
                    <PageTitle
                        pageTitle="Create confirmation page"
                        pageNumber="3"
                    />
                    {questionnaireStatus === "loading" && <Spinner />}
                    {questionnaireStatus === "success" && (
                        <Card
                            variant={"regular"}
                            spacing={3}
                            header={
                                <>
                                    <Text
                                        data-testid="questionnaire-title"
                                        children={`Write a message to patients once they complete:`}
                                    />
                                    <StyledIndent>
                                        <Text variant={"label"}>
                                            {questionnaireData?.name}
                                        </Text>
                                    </StyledIndent>
                                </>
                            }
                        >
                            <FormFieldWrapper
                                errors={completionMessageErrors}
                                label={"Message"}
                                labelProps={{ htmlFor: "confirm-message" }}
                                subLabel={
                                    "This will appear on the final page of the Florey Questionnaire."
                                }
                                style={{ marginBottom: "16px" }}
                            >
                                {isGlobalCustomFloreyEnabled ? (
                                    <EditorProvider
                                        label="Message"
                                        content={
                                            questionnaireData?.completionText ??
                                            ""
                                        }
                                    >
                                        <Editor
                                            onChange={(markdown) =>
                                                setLocalMessage(markdown)
                                            }
                                        />
                                    </EditorProvider>
                                ) : (
                                    <StyledPaddingTextArea
                                        id="confirm-message"
                                        autoFocus={true}
                                        disabled={false}
                                        value={localMessage}
                                        onChange={handleSetCompletionText}
                                        ref={completionMessageRef}
                                    />
                                )}
                            </FormFieldWrapper>

                            <SnomedSelectFormField
                                isLoading={snomedStatus === "loading"}
                                selectedOption={selectedSnomedOption}
                                options={snomedOptions}
                                onChange={handleSelectSnomedCode}
                                infoText="The SNOMED code will be saved to the patient's record if they complete the Florey and their response is saved."
                                formFieldProps={{
                                    labelProps: { htmlFor: "snomed-search" },
                                }}
                                selectProps={{
                                    expandCallback: () => {
                                        FlemingAnalyticsTracker.trackQuestionnaireSnomedSearch(
                                            {
                                                ...analyticsLoggedInProps,
                                                questionnaireId:
                                                    parseInt(questionnaireId) ??
                                                    null,
                                                pageOrigin:
                                                    "QuestionnaireConfirmationMessage",
                                            },
                                        );
                                    },
                                }}
                            />
                        </Card>
                    )}
                </StackPanel>
            </PageContainerForStickyFooter>

            <StepsFooter
                backText={PageActionText.Back}
                forwardText={
                    editQuestionnaireMutation.status === "loading"
                        ? PageActionText.Saving
                        : PageActionText.SaveAndNext
                }
                disabled={editQuestionnaireMutation.status === "loading"}
                backClickFunction={handleBackClick}
                forwardClickFunction={handleSaveConfirmationPage}
            />
        </>
    );
};
