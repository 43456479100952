import sortBy from "lodash/sortBy";

import { Actions } from "app/practices/Practices.actions";
import { LoadingStatus } from "shared/LoadingStatus";

import {
    DELETE_SITE_SETUP_SESSION_FAILED,
    DELETE_SITE_SETUP_SESSION_STARTED,
    DELETE_SITE_SETUP_SESSION_SUCCESS,
    GET_SITE_SETUP_FAILURE,
    GET_SITE_SETUP_SESSIONS_FAILURE,
    GET_SITE_SETUP_SESSIONS_STARTED,
    GET_SITE_SETUP_SESSIONS_SUCCESS,
    GET_SITE_SETUP_STARTED,
    GET_SITE_SETUP_SUCCESS,
    KnownAction,
    POST_SITE_SETUP_LINKS_FAILURE,
    POST_SITE_SETUP_LINKS_STARTED,
    POST_SITE_SETUP_LINKS_SUCCESS,
    POST_SITE_SETUP_LOCATION_FAILURE,
    POST_SITE_SETUP_LOCATION_STARTED,
    POST_SITE_SETUP_LOCATION_SUCCESS,
    POST_SITE_SETUP_SESSION_FAILURE,
    POST_SITE_SETUP_SESSION_PUBLISH_FAILURE,
    POST_SITE_SETUP_SESSION_PUBLISH_STARTED,
    POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS,
    POST_SITE_SETUP_SESSION_STARTED,
    POST_SITE_SETUP_SESSION_SUCCESS,
    POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE,
    POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED,
    POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS,
    SEARCH_ODS_CODE_FAILED,
    SEARCH_ODS_CODE_STARTED,
    SEARCH_ODS_CODE_SUCCESS,
} from "./SiteSetup.actions";
import {
    VaccineSiteLink,
    VaccineSiteSession,
    VaccineSiteSetup,
} from "./SiteSetup.types";

// -----------------
// STATE Interface - This defines the type of data maintained in the Redux store.
// -----------------
export interface VaccineSiteSetupReducerState {
    siteSetups: VaccineSiteSetup[] | null;
    siteSetupId: string;
    siteSetupLocation: string;
    siteSetupLinks: VaccineSiteLink[];
    siteSetupSessions: VaccineSiteSession[];

    siteSetupLoading: LoadingStatus;
    siteSetupError: string;

    siteSetupLocationUpdating: boolean;
    siteSetupLocationError: string;

    siteSetupLinksUpdating: boolean;
    siteSetupLinksError: string;

    siteSetupSessionsLoading: boolean;
    siteSetupSessionsError: string;

    siteSetupSessionLoading: boolean;
    siteSetupSessionError: {
        id: string;
        message: string;
    };

    siteSetupSessionDeleting: boolean;
    siteSetupSessionDeletingError: {
        id: string;
        message: string;
    };

    siteSetupOdsCodeSearching: boolean;
    siteSetupOdsCodeSearchingError: string;
    siteSetupOdsCodeSearchingPracticeName: string;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// -----------------
export const initialState: VaccineSiteSetupReducerState = {
    siteSetups: null,
    siteSetupId: "",
    siteSetupLocation: "",
    siteSetupLinks: [],
    siteSetupSessions: [],

    siteSetupLoading: LoadingStatus.Initial,
    siteSetupError: "",

    siteSetupLocationUpdating: false,
    siteSetupLocationError: "",

    siteSetupLinksUpdating: false,
    siteSetupLinksError: "",

    siteSetupSessionsLoading: false,
    siteSetupSessionsError: "",

    siteSetupSessionLoading: false,
    siteSetupSessionError: {
        id: "",
        message: "",
    },

    siteSetupSessionDeleting: false,
    siteSetupSessionDeletingError: {
        id: "",
        message: "",
    },

    siteSetupOdsCodeSearching: false,
    siteSetupOdsCodeSearchingError: "",
    siteSetupOdsCodeSearchingPracticeName: "",
};

export const reducer = (
    state: VaccineSiteSetupReducerState = initialState,
    action: KnownAction,
): VaccineSiteSetupReducerState => {
    switch (action.type) {
        case GET_SITE_SETUP_STARTED:
            return {
                ...state,
                siteSetupLoading: LoadingStatus.Loading,
                siteSetups: null,
                siteSetupError: "",
            };
        case GET_SITE_SETUP_SUCCESS: {
            const setupDetails = action.setupId
                ? action.setups.find((s) => s.id === action.setupId)
                : action.setups.length >= 1
                ? action.setups[0]
                : null;
            return {
                ...state,
                siteSetupLoading: LoadingStatus.Loaded,
                siteSetups: sortBy(action.setups, ["id"]),
                siteSetupId: setupDetails?.id || "",
                siteSetupLocation: setupDetails?.locationText || "",
                siteSetupLinks: setupDetails?.links || [],
            };
        }
        case GET_SITE_SETUP_FAILURE:
            return {
                ...state,
                siteSetupLoading: LoadingStatus.Failed,
                siteSetupError: action.error,
            };
        case POST_SITE_SETUP_LOCATION_STARTED:
            return {
                ...state,
                siteSetupLocationUpdating: true,
                siteSetupLocationError: "",
            };
        case POST_SITE_SETUP_LOCATION_SUCCESS: {
            const locationFilteredSetups =
                state.siteSetups?.filter(
                    (setup) => setup.id !== action.setup?.id,
                ) ?? [];
            const locationUpdatedSetups = sortBy(
                [...locationFilteredSetups, action.setup],
                ["id"],
            );
            return {
                ...state,
                siteSetupLocationUpdating: false,
                siteSetups: locationUpdatedSetups,
                siteSetupId: action.setup?.id || "",
                siteSetupLocation: action.setup?.locationText || "",
                siteSetupLinks: action.setup?.links || [],
            };
        }
        case POST_SITE_SETUP_LOCATION_FAILURE:
            return {
                ...state,
                siteSetupLocationUpdating: false,
                siteSetupLocationError: action.error,
            };
        case POST_SITE_SETUP_LINKS_STARTED:
            return {
                ...state,
                siteSetupLinksUpdating: true,
                siteSetupLinksError: "",
                siteSetupOdsCodeSearching: false,
                siteSetupOdsCodeSearchingPracticeName: "",
                siteSetupOdsCodeSearchingError: "",
            };
        case POST_SITE_SETUP_LINKS_SUCCESS:
            return {
                ...state,
                siteSetupLinksUpdating: false,
                siteSetupLinks: action.links || [],
            };
        case POST_SITE_SETUP_LINKS_FAILURE:
            return {
                ...state,
                siteSetupLinksUpdating: false,
                siteSetupLinksError: action.error,
            };
        case GET_SITE_SETUP_SESSIONS_STARTED:
            return {
                ...state,
                siteSetupSessionsLoading: true,
                siteSetupSessionsError: "",
            };
        case GET_SITE_SETUP_SESSIONS_SUCCESS:
            return {
                ...state,
                siteSetupSessionsLoading: false,
                siteSetupSessions: sortBy(action.sessions || [], [
                    "startTime",
                    "endTime",
                ]),
            };
        case GET_SITE_SETUP_SESSIONS_FAILURE:
            return {
                ...state,
                siteSetupSessionsLoading: false,
                siteSetupSessionsError: action.error,
            };
        case POST_SITE_SETUP_SESSION_STARTED:
        case POST_SITE_SETUP_SESSION_PUBLISH_STARTED:
        case POST_SITE_SETUP_SESSION_UNPUBLISH_STARTED:
            return {
                ...state,
                siteSetupSessionLoading: true,
                siteSetupSessionError: {
                    id: "",
                    message: "",
                },
            };
        case POST_SITE_SETUP_SESSION_SUCCESS:
        case POST_SITE_SETUP_SESSION_PUBLISH_SUCCESS:
        case POST_SITE_SETUP_SESSION_UNPUBLISH_SUCCESS:
            const newSessions = state.siteSetupSessions.filter(
                (session) => session.id !== action.session.id,
            );
            return {
                ...state,
                siteSetupSessionLoading: false,
                siteSetupSessions: sortBy(
                    [...newSessions, action.session],
                    ["startTime", "endTime"],
                ),
            };
        case POST_SITE_SETUP_SESSION_FAILURE:
        case POST_SITE_SETUP_SESSION_PUBLISH_FAILURE:
        case POST_SITE_SETUP_SESSION_UNPUBLISH_FAILURE:
            return {
                ...state,
                siteSetupSessionLoading: false,
                siteSetupSessionError: {
                    id: action.sessionId,
                    message: action.error,
                },
            };
        case DELETE_SITE_SETUP_SESSION_STARTED:
            return {
                ...state,
                siteSetupSessionDeleting: true,
                siteSetupSessionDeletingError: {
                    id: "",
                    message: "",
                },
            };
        case DELETE_SITE_SETUP_SESSION_SUCCESS:
            return {
                ...state,
                siteSetupSessionDeleting: false,
            };
        case DELETE_SITE_SETUP_SESSION_FAILED:
            return {
                ...state,
                siteSetupSessionDeleting: false,
                siteSetupSessionDeletingError: {
                    id: action.sessionId,
                    message: action.error,
                },
            };
        case SEARCH_ODS_CODE_STARTED:
            return {
                ...state,
                siteSetupOdsCodeSearching: true,
                siteSetupOdsCodeSearchingError: "",
            };
        case SEARCH_ODS_CODE_SUCCESS:
            return {
                ...state,
                siteSetupOdsCodeSearching: false,
                siteSetupOdsCodeSearchingPracticeName: action.practiceName,
            };
        case SEARCH_ODS_CODE_FAILED:
            return {
                ...state,
                siteSetupOdsCodeSearching: false,
                siteSetupOdsCodeSearchingError: action.error,
            };
        case Actions.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        default:
            return state;
    }
};
