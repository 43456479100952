import {
    CommunicationMethod,
    PatientThreadTicketCommandResult,
} from "@accurx/api/ticket";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { PatientExternalId } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkCommunicationAsWrittenToRecordOptions = {
    workspaceId: number;
    patientExternalIds: PatientExternalId[];
    itemIds: string[];
    writeToRecordSuccess: boolean;
    communicationType: "SMS" | "Email" | "NhsAppMessage";
};

const getCommunicationMethod = (
    communicationType: MarkCommunicationAsWrittenToRecordOptions["communicationType"],
): number => {
    switch (communicationType) {
        case "SMS":
            return CommunicationMethod.SMS;
        case "Email":
            return CommunicationMethod.Email;
        case "NhsAppMessage":
            return 2; // we currently don't have a DTO type for this communication method
    }
};

export const markCommunicationAsWrittenToRecord: ConciergeFetcher<
    MarkCommunicationAsWrittenToRecordOptions,
    PatientThreadTicketCommandResult
> = async ({
    workspaceId,
    patientExternalIds,
    itemIds,
    writeToRecordSuccess,
    communicationType,
}) => {
    const response = await TicketApiClient.markCommunicationAsWrittenToRecord({
        organisationId: workspaceId,
        patientExternalIdentity: {
            patientExternalIds:
                mapPatientSummaryExternalIdsToTicketPatientExternalIds(
                    patientExternalIds,
                ),
        },
        communicationItemServerIds: itemIds,
        writeToRecordSuccess,
        communicationType: getCommunicationMethod(communicationType),
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);
    return { updates, response };
};
