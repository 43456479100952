import { useAnalytics } from "@accurx/analytics";
import { Conversation } from "@accurx/concierge/types";
import { useNativeTrackingFields } from "@accurx/native";
import { getConversationDescription } from "domains/inbox/util/getConversationDescription";
import { getConversationRequestType } from "domains/inbox/util/getConversationRequestType";
import { getConversationServerId } from "domains/inbox/util/getConversationServerId";
import { getConversationType } from "domains/inbox/util/getConversationType";
import { getCountConversationItems } from "domains/inbox/util/getCountConversationItems";

export const useConversationAddNoteSelectAnalytics = (
    conversation: Conversation,
) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const conversationType = getConversationType(conversation);
    const conversationDescription = getConversationDescription(conversation);
    const { countPatientMessageInbound, countPatientMessageOutbound } =
        getCountConversationItems(conversation.items);
    const conversationRequestType = getConversationRequestType(conversation);
    const conversationStartTimestampUtc = conversation.items[0]?.createdAt;

    return () => {
        track("ConversationAddNoteSelect Button Click", {
            ...nativeTrackingFields,
            conversationDescription,
            conversationId: getConversationServerId(conversation),
            conversationStartTimestampUtc,
            conversationType,
            countItemsInConversation:
                countPatientMessageInbound + countPatientMessageOutbound,
            countPatientMessageInbound,
            countPatientMessageOutbound,
            conversationRequestType,
        });
    };
};
