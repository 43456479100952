import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { StartsWithItem } from "domains/concierge/internal/api/ticket/queries/getFilteredTicketView";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { TriageType } from "domains/concierge/internal/types/ConversationGroupFilters";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { filters } from "domains/concierge/internal/util/conversationGroupFilters";
import { sortOptions } from "domains/concierge/internal/util/conversationGroupSortOptions";

const getStartsWithItem = (triageType: TriageType): StartsWithItem => {
    switch (triageType) {
        case "Admin":
            return "PatientTriageRequestAdmin";
        case "Medical":
            return "PatientTriageRequestMedical";
        case "SelfReferral":
            return "PatientTriageRequestSelfReferral";
    }
};

export const useToAssignTriageConversationGroupQuery: ConversationGroupHook<{
    teamId: string;
    triageType: TriageType;
    status: "Open" | "Done";
}> = ({ teamId, triageType, status }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFilteredTicketView({
                workspaceId: workspaceId,
                assignee: {
                    type: "Team",
                    id: teamId,
                },
                isDone: status === "Done",
                ordering: {
                    urgentFirst: status === "Open",
                },
                startsWithItem: getStartsWithItem(triageType),
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, teamId, status, triageType],
    );

    return useConversationGroupQuery({
        filters: filters.toAssignTriage({ teamId, status, triageType }),
        sortOptions: sortOptions.toAssignTriage({ status }),
        loggingInfo: {
            name: "To assign triage conversations",
            tags: {
                teamIdFilter: teamId,
                triageTypeFilter: triageType,
            },
        },
        fetcher,
    });
};
