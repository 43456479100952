import { NhsPharmaciesResponse } from "@accurx/api/account";

import { PAGE_PARAM_NAME, SEARCH_TERM_PARAM_NAME } from "./constants";

export const getResultsLabel = ({
    totalCount,
    totalPages,
    currentPage,
    appliedLimit,
}: Pick<
    NhsPharmaciesResponse,
    "totalCount" | "totalPages" | "currentPage" | "appliedLimit"
>) => {
    if (totalCount === 0) {
        return "No results found";
    } else if (totalCount === 1) {
        return "1 result";
    } else if (totalPages === 1) {
        return `${totalCount} results`;
    } else {
        const currentResultsStart = (currentPage - 1) * appliedLimit + 1;
        const currentResultsEnd = Math.min(
            currentPage * appliedLimit,
            totalCount,
        );
        return `Showing ${currentResultsStart} to ${currentResultsEnd} of ${totalCount} results`;
    }
};

export const getQueryString = ({
    searchTerm,
    page,
}: {
    searchTerm?: string;
    page?: number;
}) => {
    return new URLSearchParams({
        ...(searchTerm && { [SEARCH_TERM_PARAM_NAME]: searchTerm }),
        ...(page && page > 1 && { [PAGE_PARAM_NAME]: String(page) }),
    }).toString();
};

export const capitalise = (details: string) => {
    return details
        .split(" ")
        .map(
            (detail) =>
                detail.charAt(0).toUpperCase() + detail.slice(1).toLowerCase(),
        )
        .join(" ");
};
