import { calculateSMSFragments } from "@accurx/shared";

import {
    NHS_ADVICE_TEXT,
    PATIENT_RESPONSE_TEXT_MAX,
    QUESTIONNAIRE_TEXT_MAX,
    SELFBOOK_TEXT_MAX,
    VIEW_ATTACHMENT_TEXT_MAX,
    VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT_MAX,
} from "../components/Compose/Compose.constants";
import { NHSAdviceLink } from "../types";

/**
 * Accepts a string and returns the character count
 * and considers any GSM characters that take 2 characters of an SMS.
 * The following characters from the GSM Character Set
 * are counted as 2 characters by Firetext when sending an SMS:
 *
 * \ ^ { } [ ~ ] | €
 *
 * https://www.firetext.co.uk/docs/sms-marketing-glossary/gsm-character-set
 * */
export const calculateStringLengthWithSpecialCharacters = (string: string) => {
    const gsmCharRegex = /[\\^{}[~\]|€]/g;

    const gsmCharCount = string.match(gsmCharRegex)?.length ?? 0;

    return string.length + gsmCharCount;
};

export type CalculateCharacterCountParams = {
    /**
     * Formatted string composed of
     *
     * greeting
     * body
     */
    body: string;
    /**
     * Contains message signature
     */
    footer: string;
    workspaceName: string;
    patientResponseEnabled: boolean;
    hasAttachments: boolean;
    hasQuestionnaire: boolean;
    nhsAdviceLink: NHSAdviceLink | null;
    hasSelfbookLink: boolean;
};

export const calculateCharacterCount = ({
    body,
    footer,
    workspaceName,
    patientResponseEnabled,
    hasAttachments,
    hasQuestionnaire,
    nhsAdviceLink,
    hasSelfbookLink,
}: CalculateCharacterCountParams): {
    characterCount: number;
    fragmentCount: number;
    isUnicode: boolean;
} => {
    let formatted = `${body}\n${footer}\n${workspaceName}`;

    if (hasQuestionnaire) {
        formatted += QUESTIONNAIRE_TEXT_MAX;
    } else if (patientResponseEnabled && hasAttachments) {
        formatted += VIEW_PATIENT_RESPONSE_OR_ATTACHMENT_TEXT_MAX;
    } else if (patientResponseEnabled) {
        formatted += PATIENT_RESPONSE_TEXT_MAX;
    } else if (hasAttachments) {
        formatted += VIEW_ATTACHMENT_TEXT_MAX;
    } else if (hasSelfbookLink) {
        formatted += SELFBOOK_TEXT_MAX;
    }

    if (nhsAdviceLink !== null) {
        formatted += `${NHS_ADVICE_TEXT}${nhsAdviceLink.name}\n${nhsAdviceLink.url}`;
    }

    const {
        smsCharacterCount: characterCount,
        fragmentCount,
        unicode,
    } = calculateSMSFragments(formatted);

    return { characterCount, fragmentCount, isUnicode: unicode };
};
