import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    EditQuestionnaireRequest,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { editQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";

import { GET_QUESTIONNAIRE_CACHE_KEY } from "../queries/useGetQuestionnaireQuery";
import { QUESTIONNAIRE_SUMMARIES_CACHE_KEY } from "../queries/useQuestionnaireSummariesQuery";

export const useEditQuestionnaireMutation = createMutationHook<
    EditQuestionnaireRequest,
    QuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["EditQuestionnaire"],
    mutationFn: async (variables) => {
        const response = await editQuestionnaire(
            variables.organisationId.toString(),
            variables,
        );
        return returnDataOrThrow(response);
    },
    onSuccess: (data, { organisationId, questionnaireId }) => {
        queryClient.setQueryData<QuestionnaireResponse>(
            [
                GET_QUESTIONNAIRE_CACHE_KEY,
                {
                    workspaceId: organisationId,
                    questionnaireId: questionnaireId.toString(),
                },
            ],
            data,
        );
        queryClient.refetchQueries({
            queryKey: [
                QUESTIONNAIRE_SUMMARIES_CACHE_KEY,
                { workspaceId: organisationId },
            ],
        });
    },
}));
