import { StackPanel, Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { CONVERSATION_GROUP_BREAKPOINT } from "./ConversationGroup.constants";

const ITEM_BACKGROUND_COLOR = Tokens.COLOURS.greyscale.white;

export const StyledConversationList = styled(StackPanel)`
    display: block;

    // Go to table layout on larger screens
    @media all and (min-width: ${CONVERSATION_GROUP_BREAKPOINT}) {
        display: table;
        border-collapse: separate;
        border-spacing: 0 ${Tokens.SIZES[0.25]};
    }
`;

/**
 * Shared styles between the loading skeleton
 * and the loaded conversation list item
 *
 * on certain screen sizes width and radius styles will be overritten
 */
const conversationListItemBorderStyles = css`
    border-radius: ${Tokens.BORDERS.radius};
    border-color: ${Tokens.COLOURS.greyscale.white};
    border-width: 1px 1px ${Tokens.SIZES[0.25]} 1px;
    border-style: solid;
`;

const StyledUnreadIndicatorContainer = styled.div``;

const StyledPatientContainer = styled.div``;

const StyledItemIndicatorContainer = styled.div``;

const StyledSummaryContainer = styled.div`
    // Let overflowing text get cut
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledDateContainer = styled.div`
    text-align: right;
`;

/** Way to select all the children of the link */
const AllLinkItemsSelector = `
    ${StyledUnreadIndicatorContainer},
    ${StyledPatientContainer},
    ${StyledItemIndicatorContainer},
    ${StyledSummaryContainer},
    ${StyledDateContainer}`;

/**
 * Layout styling of the link and its children
 * to be applied from tiny screens to extra large
 */
const conversationLinkLayoutStyling = css`
    display: grid;

    // translates to 12px 1fr 100px
    grid-template-columns: 0.75rem 1fr 6.25rem;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: ${Tokens.SIZES[1]};
    align-items: center;

    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[0.5]};
    padding-right: ${Tokens.SIZES[1.5]};

    ${conversationListItemBorderStyles}

    ${StyledUnreadIndicatorContainer} {
        padding: 0;
        grid-column: 1;
        grid-row: 2;
    }

    ${StyledPatientContainer} {
        grid-column: 2 / -1;
        grid-row: 1;
    }

    ${StyledItemIndicatorContainer} {
        grid-column: 2 / -1;
        grid-row: 2;
    }

    ${StyledSummaryContainer} {
        grid-column: 2 / 3;
        grid-row: 3;
    }

    ${StyledDateContainer} {
        grid-column: 3;
        grid-row: 3;
    }

    @media all and (min-width: ${CONVERSATION_GROUP_BREAKPOINT}) {
        display: table-row;

        // Because it's displaying like a table-row
        // the hover will need to be applied to children
        &:hover {
            ${AllLinkItemsSelector} {
                border-color: ${Tokens.COLOURS.primary.blue[75]};
            }
        }

        /* All children styles */

        ${AllLinkItemsSelector} {
            display: table-cell;
            vertical-align: middle;

            // General padding for all cells because the table row cannot have padding.
            // Will be ovewritten by the unread badge and last date components
            padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[0.5]};

            ${conversationListItemBorderStyles}

            // The side border width & radius are defined by the 
            // Unread indicator and the last date item
            border-width: 1px 0 ${Tokens.SIZES[0.25]} 0;
            border-radius: 0;

            // Give 0% width so the cells fit the content
            width: 0%;
        }

        /* Single child overrides */

        ${StyledUnreadIndicatorContainer} {
            padding: 0 ${Tokens.SIZES[0.5]};

            border-radius: ${Tokens.BORDERS.radius} 0 0 ${Tokens.BORDERS.radius};
            border-left-width: 1px;
        }

        ${StyledPatientContainer},
        ${StyledItemIndicatorContainer} {
            border-radius: 0;
        }

        ${StyledSummaryContainer} {
            border-radius: 0;

            // Give this 100% so it can take the space leftover (to be used with min-width)
            width: 100%;
            max-width: 1px;
        }

        ${StyledDateContainer} {
            padding-right: ${Tokens.SIZES[1.5]};
            border-radius: 0 ${Tokens.BORDERS.radius} ${Tokens.BORDERS.radius} 0;
            border-right-width: 1px;
        }
    }
`;

const StyledConversationLink = styled(Link)`
    position: relative;

    // All link cells should not have wrapping text
    white-space: nowrap;

    background-color: ${ITEM_BACKGROUND_COLOR};
    color: ${Tokens.COLOURS.greyscale.zinc};

    // Grid layout styles for mobile and above
    ${conversationLinkLayoutStyling}

    &:hover {
        text-decoration: none;

        color: ${Tokens.COLOURS.greyscale.zinc};
        border-color: ${Tokens.COLOURS.primary.blue[75]};
    }

    // Ensures box shadow is fully visible and not hiding behind next item
    &:focus {
        z-index: 1;
    }
`;

/**
 * When list item is loading, a few of these blocks are shown
 */
export const StyledLoadingSkeleton = styled.div`
    padding: ${Tokens.SIZES[1.5]};

    ${conversationListItemBorderStyles}
    background-color: ${ITEM_BACKGROUND_COLOR};
`;

export const StyledConversationSummaryLayout = {
    Link: StyledConversationLink,

    UnreadIndicator: StyledUnreadIndicatorContainer,
    Patient: StyledPatientContainer,
    ItemIndicator: StyledItemIndicatorContainer,
    Summary: StyledSummaryContainer,
    Date: StyledDateContainer,
};
