import * as UI from "@accurx/design";
import styled, { css } from "styled-components";

import {
    CONVERSATION_ITEM_MAX_WIDTH,
    CONVERSATION_ITEM_WIDTH,
} from "../ConversationView/components/ConversationThread/ConversationThread.styles";

const { SIZES, COLOURS, BORDERS } = UI.Tokens;
export type Alignment = "Left" | "Right" | "Center" | "FullWidth";

export const ConversationThreadListItem = styled(UI.Thread.Item)<{
    $align: Alignment;
}>`
    flex: 1;
    margin-bottom: ${UI.Tokens.SIZES[2]};
    position: relative;

    // hack that prevents content from extending it's parent width
    min-width: 0;

    // combination of 'overflow-wrap: anywhere' and 'word-break: normal' gives the same effect as
    // as deprecated 'word-break: break-word'
    // https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#break-word
    overflow-wrap: anywhere;
    word-break: normal;

    ${({ $align }) => {
        if ($align === "FullWidth") {
            return css`
                max-width: initial;
                width: 100%;
            `;
        }

        return css`
            max-width: ${CONVERSATION_ITEM_MAX_WIDTH};
            width: ${CONVERSATION_ITEM_WIDTH};
        `;
    }}

    align-self: ${({ $align }) => {
        switch ($align) {
            case "Left":
                return "flex-start";

            case "Right":
                return "flex-end";

            case "Center":
            case "FullWidth":
                return "center";
        }
    }}
`;

export const ConversationItemHeader = styled.div`
    display: flex;
    padding: ${SIZES[1]} ${SIZES[1.5]};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${COLOURS.greyscale.silver};
    gap: ${SIZES[1]};
`;

export const ConversationItemContent = styled.div`
    padding: ${SIZES[1.5]};

    // Stop paragraph elements from adding stray whitespace at the bottom of the
    // message content.
    p:last-child {
        margin-bottom: 0px;
    }
`;

export const ConversationItemFooter = styled.div`
    display: flex;
    padding: ${SIZES[1]} ${SIZES[1.5]};
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    flex-wrap: wrap;
    gap: ${SIZES[0.5]};
`;

export const ConversationItemFooterInfo = styled(UI.Flex)`
    align-items: center;
    justify-content: flex-end;
    flex: 1 0;
`;

export const ScheduledMessageFeedbackContainer = styled.div`
    padding: ${SIZES[1.5]};
`;

type ContainerColor = "red" | "orange" | "yellow" | "blue" | "white";

const containerColors: Record<
    ContainerColor,
    { border: string; headerBackground: string }
> = {
    white: {
        border: COLOURS.greyscale.silver,
        headerBackground: COLOURS.greyscale.white,
    },
    red: {
        border: COLOURS.primary.red[50],
        headerBackground: COLOURS.primary.red[10],
    },
    orange: {
        border: COLOURS.primary.orange[50],
        headerBackground: COLOURS.primary.orange[10],
    },
    yellow: {
        border: COLOURS.primary.yellow[50],
        headerBackground: COLOURS.primary.yellow[10],
    },
    blue: {
        border: COLOURS.primary.blue[50],
        headerBackground: COLOURS.primary.blue[10],
    },
};

export const ConversationItemContainer = styled.div<{
    $color: ContainerColor;
}>`
    background-color: ${COLOURS.greyscale.white};
    border-radius: ${BORDERS.radius};
    border: ${BORDERS.normal.silver};
    padding: 0;
    overflow: hidden;
    font-size: 0.875rem;
    color: ${COLOURS.greyscale.zinc};

    ${(props) => {
        const { border, headerBackground } = containerColors[props.$color];

        return css`
            border-color: ${border};

            ${ConversationItemHeader} {
                border-color: ${border};
                background-color: ${headerBackground};
            }
        `;
    }}
`;

export const ConversationItemBadgeContainer = styled.div`
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
