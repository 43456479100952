import { Feedback } from "@accurx/design";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { CoreActions } from "shared/constants/actionTypes";

import {
    OrgUserApprovalStatus,
    OrgUserApprovalStatusLabel,
    OrgUserRole,
    OrgUserRoleLabel,
} from "./PracticeUsers.types";

const ENDPOINTS = {
    organisationSetup: "/api/portal/OrganisationSetup/:practiceId",
    organisationSetupUpdateRole:
        "/api/portal/OrganisationSetup/:practiceId/UpdateRole",
    organisationSetupUpdateStatus:
        "/api/portal/OrganisationSetup/:practiceId/UpdateStatus",
    organisationSetupAddVaccine:
        "/api/portal/OrganisationSetup/:practiceId/AddVaccineUser",
} as const;

export const getAllData = async (
    practiceId: string,
    dispatch: Dispatch,
): Promise<undefined> => {
    const { success, result } = await httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.organisationSetup,
            params: { practiceId },
        }),
    );
    if (success && result) {
        dispatch({
            type: CoreActions.LOAD_PRACTICE_SETUP_SUCCESS,
            approvedUsers: result.approvedUsers,
            unapprovedUsers: result.unapprovedUsers,
            archivedUsers: result.archivedUsers,
            hasApprovalRights: result.hasApprovalRights,
        });
        return;
    }
    dispatch({ type: CoreActions.LOAD_PRACTICE_SETUP_FAIL });
};

export const loadInitialData =
    (practiceId: string) =>
    (dispatch: Dispatch): void => {
        dispatch({ type: CoreActions.LOAD_PRACTICE_SETUP });
        getAllData(practiceId, dispatch);
    };

export const userChangeRole =
    (
        practiceId: string,
        userId: number,
        role: OrgUserRole,
        name: string,
        onSuccessRefetch?: () => void,
    ) =>
    async (dispatch: Dispatch): Promise<undefined> => {
        const { success } = await httpClient.putJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.organisationSetupUpdateRole,
                params: { practiceId },
            }),
            { userId, role },
        );
        if (success) {
            // TODO: Temporary whilst migrating from Redux to React Query
            await (onSuccessRefetch
                ? onSuccessRefetch()
                : getAllData(practiceId, dispatch));
            toast(
                Feedback({
                    colour: "success",
                    title:
                        role === OrgUserRole.Admin
                            ? `${name} is now an admin`
                            : `${name} is now a ${OrgUserRoleLabel(
                                  role,
                              ).toLowerCase()}`,
                }),
            );
            return;
        }
        toast(
            Feedback({
                colour: "error",
                title: "Could not update role",
            }),
        );
    };

export const userChangeStatus =
    (
        practiceId: string,
        userId: number,
        status: OrgUserApprovalStatus,
        name: string,
        onSuccessRefetch?: () => void,
    ) =>
    async (dispatch: Dispatch): Promise<undefined> => {
        const { success } = await httpClient.putJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.organisationSetupUpdateStatus,
                params: { practiceId },
            }),
            { userId, status },
        );
        if (success) {
            // TODO: Temporary whilst migrating from Redux to React Query
            await (onSuccessRefetch
                ? onSuccessRefetch()
                : getAllData(practiceId, dispatch));
            toast(
                Feedback({
                    colour: "success",
                    title: `${name} is now ${OrgUserApprovalStatusLabel(
                        status,
                    ).toLowerCase()}`,
                }),
            );
            return;
        }
        toast(
            Feedback({
                colour: "error",
                title: "Could not update status",
            }),
        );
    };

export const addVaccineUser =
    (practiceId: string, emailAddress: string) =>
    async (dispatch: Dispatch): Promise<undefined> => {
        const { success } = await httpClient.putJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.organisationSetupAddVaccine,
                params: { practiceId },
            }),
            { emailAddress },
        );
        if (success) {
            await getAllData(practiceId, dispatch);
            toast(
                Feedback({
                    colour: "success",
                    title: `${emailAddress} is now part of your organisation`,
                }),
            );
            return;
        }
        toast(
            Feedback({
                colour: "error",
                title: "Could not add user",
            }),
        );
    };
