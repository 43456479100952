import { useEffect, useState } from "react";

import * as UI from "@accurx/design";
import { useMedicalRecordConnection } from "@accurx/native";
import { ChangePatientModal } from "domains/compose-toolbar/pages/compose/components/ChangePatientModal/ChangePatientModal";
import { PatientHeader } from "domains/compose-toolbar/pages/compose/components/PatientHeader/PatientHeader";
import { mapPatientDemographicsToComposePatient } from "domains/compose-toolbar/utils/mapPatientDemographicsToComposePatient";
import { mapPatientDemographicsContactToContactDetails } from "domains/compose-toolbar/utils/mapPatientDemographicsToContactDetails";

import { Page } from "../components/Page";
import { ComposeNewMessage } from "./ComposeNewMessage";
import { useComposePatientDemographics } from "./hooks/useComposePatientDemographics";

export const ComposePage = () => {
    const medicalRecordConnection = useMedicalRecordConnection();
    const {
        composePatientDemographics,
        currentEmrPatientDemographics,
        composePatientSameAsEmr,
        currentEmrPatientQueryStatus,
        setComposePatientDemographics,
    } = useComposePatientDemographics();

    const [showChangePatientModal, setShowChangePatientModal] = useState(false);

    useEffect(() => {
        /* Only show the modal if there's a difference between
         * the currently selected patient and the EMR patient
         * is not null.
         */
        if (
            !showChangePatientModal &&
            !composePatientSameAsEmr &&
            currentEmrPatientDemographics
        ) {
            setShowChangePatientModal(true);
        }
        /* We only want to show the dialogue if the page patient has changed,
         * running the callback when either of the other dependancies changes
         * can cause either an infinate re-render or the modal to be displayed
         * incorrectly
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmrPatientDemographics]);

    if (composePatientDemographics) {
        return (
            <Page>
                <ChangePatientModal
                    isOpen={showChangePatientModal}
                    onClose={() => setShowChangePatientModal(false)}
                    onConfirm={() => {
                        if (currentEmrPatientDemographics) {
                            setComposePatientDemographics(
                                currentEmrPatientDemographics,
                            );
                        }
                        setShowChangePatientModal(false);
                    }}
                    emrName={
                        medicalRecordConnection.system ?? "your medical system"
                    }
                />
                <PatientHeader patient={composePatientDemographics} />
                {medicalRecordConnection.status === "Disconnected" ? (
                    <UI.Feedback colour="warning">
                        You are not longer connected to the EMR.
                    </UI.Feedback>
                ) : null}
                {medicalRecordConnection.status === "Connected" &&
                currentEmrPatientQueryStatus === "error" ? (
                    <UI.Feedback colour="warning">
                        There was a problem fetching the patient form the EMR,
                        the current patient may not match the one selected in
                        the EMR.
                    </UI.Feedback>
                ) : null}
                <UI.Text as="h1" variant="subtitle" skinny>
                    Compose a new message
                </UI.Text>
                <ComposeNewMessage
                    patient={mapPatientDemographicsToComposePatient(
                        composePatientDemographics,
                    )}
                    contactDetails={mapPatientDemographicsContactToContactDetails(
                        composePatientDemographics.contact,
                    )}
                />
            </Page>
        );
    }

    /**
     * This is the initial state, before the patient has
     * been set from the emr and the current emr patient
     * is still loading
     */
    if (
        currentEmrPatientQueryStatus === "loading" &&
        medicalRecordConnection.status !== "Disconnected"
    ) {
        return <UI.Ds.Spinner />;
    }

    /**
     * If we do not have a current EMR patient _and_
     * there is no EMR connection, display an error
     * and prompt the user to connect to the EMR.
     */
    if (medicalRecordConnection.status === "Disconnected") {
        return (
            <UI.Feedback colour="error">
                Not connected, connect to the EMR to continue.
            </UI.Feedback>
        );
    }

    /**
     * This is the initial state, before the patient has
     * been set from the emr and there was en error
     * fetching the patient
     */
    if (currentEmrPatientQueryStatus === "error") {
        return (
            <UI.Feedback colour="error">
                There was an error fetching the current patient from the EMR.
            </UI.Feedback>
        );
    }

    return (
        <UI.Feedback colour="error">
            No patient selected in EMR, search for a patient will appear here.
        </UI.Feedback>
    );
};
