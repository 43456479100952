import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPreviewLink = styled.a.attrs({
    target: "_blank",
    rel: "noreferrer noopener",
})`
    // We're extending a standard <a> element here with the link styles instead
    // of directly using <UI.Link> because <UI.Link> does not support calling
    // e.preventDefault() inside the onClick handler. We need to do this in the
    // case that the fail to fetch the preview link.
    ${UI.LinkStyles}

    font-size: 0.875rem;

    // Also unfortunately the <UI.Icon> component does not support being set to
    // the correct size for 14px text. It's either too big or too small and it
    // cannot be directly extended with styled(UI.Icon). So we have to manually
    // override its size here.
    svg {
        width: 20px;
        height: 20px;
    }
`;

// The quickview panel does not have much horizontal space and the link text
// changes depending on wether you're web or desktop embedded. To prevent weird
// whitespace breakages in all cases we can use this nowrap span to be very
// specific about which parts of the link we don't want to break onto a new
// line.
export const NoWrap = styled.span`
    white-space: nowrap;
`;

export const StyledPreviewLinkLoaderContainer = styled.div`
    padding: ${UI.Tokens.SIZES[0.5]} 0;
`;
