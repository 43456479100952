import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { createAccumailTemplate } from "api/Accumail/AccumailTemplatesApi";
import { AccumailTemplate } from "app/practices/accumail/templates.types";

import { ACCUMAIL_TEMPLATES_CACHE_KEY } from "../queries/useAccumailTemplatesQuery";

type CreateAccumailTemplateMutationVariables = {
    workspaceId: number;
    data: Omit<AccumailTemplate, "id">;
};

export const useCreateAccumailTemplateMutation = createMutationHook<
    CreateAccumailTemplateMutationVariables,
    AccumailTemplate
>(({ queryClient }) => ({
    mutationKey: ["CreateAccumailTemplate"],
    mutationFn: async ({ workspaceId, data }) => {
        const response = await createAccumailTemplate(workspaceId, data);
        return returnDataOrThrow(response);
    },
    onSuccess: (template, { workspaceId }) => {
        const queryKey = [
            ACCUMAIL_TEMPLATES_CACHE_KEY,
            { workspaceId, templateType: template.templateType },
        ];

        queryClient.setQueryData<AccumailTemplate[]>(queryKey, (old) =>
            old === undefined ? [template] : [...old, template],
        );
    },
}));
