import React from "react";

import { Ds, Text } from "@accurx/design";
import { generatePath, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useFeatureFlag } from "reduxQuarantine/useFeatureFlag";

import { useModules } from "app/organisations/hooks";
import { ROUTES_ORGS } from "shared/Routes";

import { SectionCardLink } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCTAStackPanel,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledList,
} from "../../styles/Cards.styles";

const ORIGIN_PROPS = {
    navigationOrigin: "Web",
    pageOrigin: "OrganisationHomepage",
};

export const ReportingProductCard = (): JSX.Element => {
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();
    const modules = useModules();

    const isNewReportingEnabled = useFeatureFlag("EmbeddedPowerBiPOC");

    const generateSectionPath = (path: string) => {
        return {
            pathname: generatePath(path, { orgId }),
            state: ORIGIN_PROPS,
        };
    };

    const handleViewReportsClick = () => {
        history.push(
            generatePath(ROUTES_ORGS.reporting, { orgId }),
            ORIGIN_PROPS,
        );
    };

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <Ds.Flex gap="1.5" alignItems="center">
                    <Ds.Icon name="Chart" />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.REPORTING}
                    </Text>
                </Ds.Flex>
                {isNewReportingEnabled && (
                    <StyledCTAStackPanel
                        gutter={2}
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        horizontalContentAlignment="left"
                    >
                        <StyledCTAButton
                            theme="secondary"
                            text="View reports"
                            icon={{ name: "Chart", colour: "blue" }}
                            onClick={handleViewReportsClick}
                        />
                    </StyledCTAStackPanel>
                )}
            </StyledCardHeading>

            <StyledCardContent>
                {isNewReportingEnabled ? (
                    <StyledList>
                        {modules?.patientTriage && (
                            <li>
                                <SectionCardLink
                                    section={SECTIONS.REPORTING}
                                    text="Patient triage"
                                    path={generateSectionPath(
                                        ROUTES_ORGS.reportPatientTriage,
                                    )}
                                />
                            </li>
                        )}
                        <li>
                            <SectionCardLink
                                section={SECTIONS.REPORTING}
                                text="Questionnaire and self-book response rates"
                                path={generateSectionPath(
                                    ROUTES_ORGS.reportResponseRates,
                                )}
                            />
                        </li>
                        <li>
                            <SectionCardLink
                                section={SECTIONS.REPORTING}
                                text="Fragments"
                                path={generateSectionPath(
                                    ROUTES_ORGS.reportFragments,
                                )}
                            />
                        </li>
                    </StyledList>
                ) : (
                    <StyledList>
                        <li>
                            <SectionCardLink
                                section={SECTIONS.REPORTING}
                                text="Questionnaire response rates"
                                path={generateSectionPath(
                                    "/organisations/:orgId/reporting/questionnaire",
                                )}
                            />
                        </li>
                        <li>
                            <SectionCardLink
                                section={SECTIONS.REPORTING}
                                text="Self-Book response rates"
                                path={generateSectionPath(
                                    "/organisations/:orgId/reporting/booking",
                                )}
                            />
                        </li>
                    </StyledList>
                )}
            </StyledCardContent>
        </StyledCard>
    );
};
