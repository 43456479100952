import { useEffect, useState } from "react";

import { Button, Card, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { RecordViewRequest, RecordViewRequestKeys } from "./RequestsList.types";

export type TeamUsageTableProps = {
    requests: RecordViewRequest[];
};

const TABLE_HEADER: {
    key: RecordViewRequestKeys;
    title: string;
}[] = [
    {
        key: "nhsNumber",
        title: "Patient NHS number",
    },
    {
        key: "requestedTime",
        title: "Requested",
    },
    {
        key: "status",
        title: "Status",
    },
    {
        key: "hasPatientAuthorised",
        title: "Has Patient Authorised",
    },
    {
        key: "requesterEmail",
        title: "Requester email",
    },
    {
        key: "requesterOrg",
        title: "Requester organisation",
    },
];

const sortData = (
    data: RecordViewRequest[],
    sortKey: RecordViewRequestKeys,
    sortDirectionAsc: boolean,
): RecordViewRequest[] => {
    data.sort((a, b) => {
        if (sortKey === "requestedTime") {
            return DateHelpers.mostRecentSorter(a[sortKey], b[sortKey]);
        }
        if (sortKey === "hasPatientAuthorised") {
            return a[sortKey] ? 1 : -1;
        }
        return a[sortKey].localeCompare(b[sortKey]);
    });
    if (!sortDirectionAsc) {
        data.reverse();
    }
    return data;
};

export const RequestsListTable = ({ requests }: TeamUsageTableProps) => {
    const [sortKey, setSortKey] = useState<RecordViewRequestKeys>(
        TABLE_HEADER[0].key,
    );
    const [sortDirectionAsc, setSortDirectionAsc] = useState(true);
    const [sortedData, setSortedData] = useState(
        sortData(requests, sortKey, sortDirectionAsc),
    );

    useEffect(() => {
        // Sort table on click
        setSortedData(sortData(requests, sortKey, sortDirectionAsc));
    }, [requests, sortKey, sortDirectionAsc]);

    const handleSort = (selectedKey: RecordViewRequestKeys): void => {
        if (sortKey === selectedKey) {
            // Changing sorting order
            setSortDirectionAsc((prev) => !prev);
            return;
        }
        // Changing sorting column
        setSortKey(selectedKey);
        setSortDirectionAsc(true);
    };

    return (
        <Card
            spacing={0}
            isElevated={false}
            props={{ className: "table-responsive" }}
        >
            <table className="table table-bordered table-sm mb-0">
                <thead>
                    <tr>
                        {TABLE_HEADER.map(({ key, title }) => (
                            <Text
                                key={title}
                                as="th"
                                variant="label"
                                props={{
                                    className: `align-middle ${
                                        key === sortKey && "bg-blue-25"
                                    }`,
                                }}
                            >
                                <Button
                                    className="p-0 text-left align-items-center"
                                    theme="transparent"
                                    text={title}
                                    icon={{
                                        name: "Order",
                                        colour: "blue",
                                        placement: "end",
                                        rotation:
                                            key === sortKey && sortDirectionAsc
                                                ? "up"
                                                : "down",
                                    }}
                                    onClick={(): void => handleSort(key)}
                                />
                            </Text>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((user, index) => (
                        <tr key={index}>
                            <Text as="td" variant="label">
                                {user.nhsNumber}
                            </Text>
                            <Text as="td">
                                {DateHelpers.formatDate(
                                    user.requestedTime,
                                    DateFormatOptions.HUMAN_READABLE_DATE_TIME,
                                )}
                            </Text>
                            <Text as="td">{user.status}</Text>
                            <Text as="td">
                                {user.hasPatientAuthorised ? "Yes" : "No"}
                            </Text>
                            <Text as="td">{user.requesterEmail}</Text>
                            <Text as="td">{user.requesterOrg}</Text>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};
