import React, { Component, MouseEvent } from "react";

import { Button, Card, Ds, Feedback, Icon, Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    EmailAddressHelper,
    MobileNumberHelper,
} from "@accurx/shared";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import PatientInfoSubMenu from "app/navbar/PatientInfoSubMenuComponent";
import { TourTestPatient, getTourMode } from "app/onboarding/Onboarding.helper";
import { AnalyticsState } from "app/sessionAnalytics/sessionAnalyticsReducer";
import { SlideOut } from "app/sharedComponents/SlideOut";
import { PatientHelper } from "shared/PatientHelper";
import { ROUTES } from "shared/Routes";

import type { AccountState } from "../account/AccountState.types";
import * as SearchForPatientStore from "../searchForPatient/SearchForPatientReducer";
import * as SelectProductActions from "../selectProduct/SelectProductActions";
import type { SelectProductState } from "../selectProduct/SelectProductState.types";
import * as MessagePatientStore from "../sendMessage/SendMessageReducer";
import { MessageType } from "../sendMessage/SendMessageReducer";
import CopyTextButton from "../sharedComponents/copyText/CopyTextButtonComponent";
import { generateMessageCopy } from "../sharedComponents/messagePreview/MessagePreviewComponent";
import * as VideoConfirmationLobbyActions from "./VideoLobbyActions";
import * as VideoLobbyStore from "./VideoLobbyReducer";

const CopyButtonAnalyticsContext =
    FlemingAnalyticsTracker.CopyButtonAnalyticsContext;

interface VideoLobbyState {
    isPollingPaused: boolean;
    isRecordViewSlideOutOpen: boolean;
}

export type VideoLobbyProps = VideoLobbyStore.VideoLobbyState &
    SearchForPatientStore.SearchPatientState &
    MessagePatientStore.MessagePatientState &
    SelectProductState &
    AccountState & {
        analytics: AnalyticsState;
    } & typeof VideoConfirmationLobbyActions.actionCreators &
    typeof SelectProductActions.actionCreators &
    RouteComponentProps<Record<string, string | undefined>>;

class VideoLobby extends Component<VideoLobbyProps, VideoLobbyState> {
    constructor(props: VideoLobbyProps) {
        super(props);
        this.state = {
            isPollingPaused: false,
            isRecordViewSlideOutOpen: false,
        };
    }

    timerRef = 0;

    componentDidMount(): void {
        if (!this.props.hasEverCheckedProgress) {
            this.startStatusPolling();
        }
    }

    componentWillUnmount(): void {
        if (this.timerRef) {
            window.clearTimeout(this.timerRef);
        }
        this.resetProduct();
    }

    componentDidUpdate(): void {
        if (this.props.hasEverCheckedProgress && this.props.settingUpDevice) {
            this.props.cancelCheckPatientProgress(this.getConsultId);
        }
    }

    //#region Rendering

    handleGoBack = (): void => {
        this.props.history.goBack();
    };

    public render() {
        if (this.getPatient === null) {
            return <Redirect to="/" />;
        }

        return (
            <>
                <NavSubMenuComponent backCallback={this.handleGoBack}>
                    <PatientInfoSubMenu patient={this.getPatient} />
                </NavSubMenuComponent>
                <Text as="h2" variant="subtitle" className="mb-2">
                    Video Consult invitation sent
                </Text>
                {this.renderTourFeedback()}
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        {this.props.messageInfoSent?.emailBody &&
                            this.props.lastSendMessageResponse?.result
                                ?.messageText && (
                                <CopyTextButton
                                    text={generateMessageCopy({
                                        sentTo:
                                            PatientHelper.getPatient(
                                                this.props.lastResponse,
                                            )?.displayName || "Unknown",
                                        mobileNumber:
                                            MobileNumberHelper.obfuscate(
                                                this.props.messageInfoSent
                                                    .mobileNumber,
                                            ) || undefined,
                                        email: this.props.messageInfoSent
                                            .emailAddress
                                            ? EmailAddressHelper.obfuscateDefined(
                                                  this.props.messageInfoSent
                                                      .emailAddress,
                                              )
                                            : undefined,
                                        sentBy:
                                            this.props.user?.fullName ||
                                            undefined,
                                        dateTime: DateHelpers.formatTime(
                                            DateHelpers.getCurrentTimeStamp(),
                                            DateFormatOptions.TIME_DATE_SHORT,
                                        ),
                                        messageText:
                                            this.props.lastSendMessageResponse
                                                .result.messageText,
                                    })}
                                    analyticsContext={
                                        CopyButtonAnalyticsContext.videoConsult
                                    }
                                />
                            )}
                        {this.renderRecordViewCard()}
                        {this.renderGoBackCard()}
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        {this.renderPatientProgress()}
                    </div>
                </div>

                <SlideOut
                    showTab={true}
                    tabText="Record view"
                    isOpen={this.state.isRecordViewSlideOutOpen}
                    toggleOpen={() =>
                        this.setState((state) => ({
                            isRecordViewSlideOutOpen:
                                !state.isRecordViewSlideOutOpen,
                        }))
                    }
                >
                    {/* disabling this rule as i don't know enough about this code to fix it */}
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        src={
                            ROUTES.record_view_request_medical_record +
                            "?embed=true"
                        }
                    ></iframe>
                </SlideOut>
            </>
        );
    }

    private renderPatientProgress() {
        const renderReceivedMessage = this.getMessageType !== MessageType.Email;

        return (
            <Card
                spacing={3}
                header={
                    <Text variant="subtitle" props={{ className: "my-0" }}>
                        Patient's progress
                    </Text>
                }
                footer={this.renderPatientProgressFooter()}
            >
                <div>
                    {this.props.cannotGetProgress ? (
                        <div className="row">
                            <label className="col-lg-12 mb-1 text-danger">
                                Sorry something went wrong and we couldn't get
                                the patient's progress
                            </label>
                        </div>
                    ) : (
                        <div>
                            {this.props.sentMessage &&
                            this.confirmSameConsultation() ? (
                                <div className="row">
                                    <label className="col-lg-12 mb-1">
                                        ✔ Message sent
                                    </label>
                                    <p className="col-lg-12 time-stamp">
                                        {DateHelpers.formatDate(
                                            this.props.sentMessageAt,
                                            DateFormatOptions.DATE_TIME_WITH_SECONDS,
                                        )}
                                    </p>
                                </div>
                            ) : (
                                !this.state.isPollingPaused && (
                                    <div className="row">
                                        <label
                                            className={
                                                "col-lg-12 loading-label"
                                            }
                                        >
                                            ... Waiting for message to send
                                        </label>
                                    </div>
                                )
                            )}
                            {renderReceivedMessage &&
                                (this.props.receivedMessage &&
                                this.confirmSameConsultation() ? (
                                    <div className="row">
                                        <label className="col-lg-12 mb-1">
                                            ✔ Message received
                                        </label>
                                        <p className="col-lg-12 time-stamp">
                                            {DateHelpers.formatDate(
                                                this.props.receivedMessageAt,
                                                DateFormatOptions.DATE_TIME_WITH_SECONDS,
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    !this.state.isPollingPaused && (
                                        <div className="row">
                                            {/* If previous property is true this must now be the loading state */}
                                            <label
                                                className={
                                                    this.props.sentMessage
                                                        ? "col-lg-12 loading-label"
                                                        : "col-lg-12 not-loading-label"
                                                }
                                            >
                                                ... Waiting for patient to
                                                receive message
                                            </label>
                                        </div>
                                    )
                                ))}
                            {this.props.linkClicked &&
                            this.confirmSameConsultation() ? (
                                <div className="row">
                                    <label className="col-lg-12 mb-1">
                                        ✔ Opened video link
                                    </label>
                                    <p className="col-lg-12 time-stamp">
                                        {DateHelpers.formatDate(
                                            this.props.linkClickedAt,
                                            DateFormatOptions.DATE_TIME_WITH_SECONDS,
                                        )}
                                    </p>
                                </div>
                            ) : (
                                !this.state.isPollingPaused && (
                                    <div className="row">
                                        {/* If previous property (which is "sent" for email, and otherwise "received") is true this must now be the loading state */}
                                        <label
                                            className={
                                                this.props.receivedMessage ||
                                                (!renderReceivedMessage &&
                                                    this.props.sentMessage)
                                                    ? "col-lg-12 loading-label"
                                                    : "col-lg-12 not-loading-label"
                                            }
                                        >
                                            ... Waiting for patient to open
                                            video link
                                        </label>
                                    </div>
                                )
                            )}
                            {this.props.settingUpDevice &&
                            this.confirmSameConsultation() ? (
                                <div className="row">
                                    <label className="col-lg-12 mb-1">
                                        ✔ Setting up device
                                    </label>
                                    <p className="col-lg-12 time-stamp">
                                        {DateHelpers.formatDate(
                                            this.props.settingUpDeviceAt,
                                            DateFormatOptions.DATE_TIME_WITH_SECONDS,
                                        )}
                                    </p>
                                </div>
                            ) : (
                                !this.state.isPollingPaused && (
                                    <div className="row">
                                        {/* If previous property is true this must now be the loading state */}
                                        <label
                                            className={
                                                this.props.linkClicked
                                                    ? "col-lg-12 loading-label"
                                                    : "col-lg-12 not-loading-label"
                                            }
                                        >
                                            ... Waiting for patient to set up
                                            device
                                        </label>
                                    </div>
                                )
                            )}
                            {this.state.isPollingPaused && (
                                <>
                                    <label className="not-loading-label">
                                        No further updates to report
                                    </label>
                                    <Button
                                        theme="secondary"
                                        text="Check for updates"
                                        onClick={this.startStatusPolling}
                                        className="mt-1"
                                    />
                                </>
                            )}
                        </div>
                    )}
                    <div className="mt-2">
                        <Feedback title="On a phone call?" colour="warning">
                            <Text skinny>
                                Hang up so that Video Consult can access your
                                microphone
                            </Text>
                        </Feedback>
                    </div>
                </div>
            </Card>
        );
    }

    private renderPatientProgressFooter(): JSX.Element {
        return (
            <Button
                text="Open Video Consult"
                icon={{ name: "Video", style: "Line" }}
                onClick={this.openUrl}
                className="mx-auto"
            />
        );
    }

    private renderTourFeedback(): JSX.Element | null {
        const accuRxUserId = this.props.user?.accuRxUserId || "";
        const tourMode = getTourMode(accuRxUserId);
        const isDemoPatient = PatientHelper.isDemoPatient(
            this.getPatient?.nhsNumber || "",
        );
        const isTourVisible =
            isDemoPatient &&
            (tourMode === TourTestPatient.Video ||
                tourMode === TourTestPatient.VideoEnded);
        if (!isTourVisible) {
            return null;
        }
        const deviceStr =
            this.getMessageType === MessageType.Email ? "email" : "phone";
        return (
            <Feedback
                colour="learning"
                title="Congratulations! You've sent a Video Consult invitation"
                props={{ className: "mb-4" }}
            >
                <Text
                    skinny
                >{`Check the ${deviceStr} you used to see how this message appears to patients`}</Text>
            </Feedback>
        );
    }

    private renderRecordViewCard(): JSX.Element {
        return (
            <Card spacing={2} props={{ className: "mb-4" }}>
                <div className="d-block d-sm-flex align-items-center justify-content-between">
                    <Text
                        variant="label"
                        props={{
                            className:
                                "d-flex align-items-center my-0 mr-0 mr-sm-4",
                        }}
                        colour="night"
                    >
                        <span className="d-inline-block mr-2">
                            <Icon name={"Record"} size={3} colour="night" />
                        </span>
                        <span className="d-flex align-items-center">
                            <Ds.Badge
                                radius="round"
                                color="purple"
                                className="mr-2"
                            >
                                New
                            </Ds.Badge>
                            {"Request a summary of the patient's GP record"}
                        </span>
                    </Text>
                    <div>
                        <Button
                            theme="secondary"
                            icon={{ name: "OpenWindow" }}
                            onClick={this.handleRecordViewClick}
                            className="text-nowrap mt-2 mt-sm-0"
                            text={"Request record"}
                        />
                    </div>
                </div>
            </Card>
        );
    }

    private renderGoBackCard(): JSX.Element {
        const buttonCopy =
            this.props.searchPatientOrigin === ROUTES.patient_lists
                ? "Go to patient list"
                : "Search for a new patient";
        const messageCopy =
            this.props.searchPatientOrigin === ROUTES.patient_lists
                ? "See a patient's progress from the patient list"
                : "Want to contact another patient?";
        const icon =
            this.props.searchPatientOrigin === ROUTES.patient_lists
                ? "Success"
                : "Search";
        const iconStyle =
            this.props.searchPatientOrigin === ROUTES.patient_lists
                ? "Fill"
                : "Line";
        return (
            <Card spacing={2} props={{ className: "mb-4" }}>
                <div className="d-block d-sm-flex align-items-center justify-content-between">
                    <Text
                        variant="label"
                        props={{
                            className:
                                "d-flex align-items-center my-0 mr-0 mr-sm-4",
                        }}
                    >
                        <span className="d-inline-block mr-2">
                            <Icon
                                name={icon}
                                size={3}
                                colour="night"
                                style={iconStyle}
                            />
                        </span>
                        {messageCopy}
                    </Text>
                    <Button
                        theme="secondary"
                        onClick={this.handleMessageNewPatientClick}
                        className="text-nowrap mt-2 mt-sm-0"
                        text={buttonCopy}
                    />
                </div>
            </Card>
        );
    }

    // We've done basic handling of multiple consultations being displayed but would need to do more work here to properly support.
    private confirmSameConsultation() {
        return this.props.consultationId === this.getConsultId;
    }

    private handleRecordViewClick = (): void => {
        this.setState({
            isRecordViewSlideOutOpen: true,
        });

        const recordViewAnalytics = {
            ...AnalyticsMapper.getRecordViewBaseAnalyticsProps(this.props, {
                ...this.props,
                externalReferrer: this.props.analytics?.externalReferrer || "",
            }),
            recordViewOrigin:
                FlemingAnalyticsTracker.MedicalRecordViewOrigin
                    .VideoConsultConfirmation,
        };

        FlemingAnalyticsTracker.trackRecordViewRequestRecord(
            recordViewAnalytics,
        );
    };

    private handleMessageNewPatientClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        this.resetProduct();
        this.props.history.push(this.props.searchPatientOrigin);
    };

    private startStatusPolling = (): void => {
        this.setState({ isPollingPaused: false });

        this.props.checkPatientProgress(this.getConsultId);

        // Cancel polling after 10 min in case the page is left open
        if (this.timerRef) {
            window.clearTimeout(this.timerRef);
        }
        this.timerRef = window.setTimeout(() => {
            this.props.cancelCheckPatientProgress(this.getConsultId);
            this.setState({ isPollingPaused: true });
        }, 600000);
    };

    private resetProduct = (): void => {
        this.props.resetSubscriptions();
        this.props.resetProductType();
    };

    private openUrl = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        window.open(this.getConsultUrl);
    };

    //#region Selectors
    private get getPatient() {
        return PatientHelper.getPatient(this.props.lastResponse);
    }

    private get getMessageType() {
        return this.props.messageInfoSent &&
            this.props.messageInfoSent.mobileNumber === null
            ? MessageType.Email
            : MessageType.Sms;
    }

    private get getConsultUrl(): string {
        return (
            (this.props.lastSendMessageResponse &&
                this.props.lastSendMessageResponse.result &&
                this.props.lastSendMessageResponse.result.videoConsultUrl) ||
            "#"
        );
    }

    private get getConsultId() {
        return (
            (this.props.lastSendMessageResponse &&
                this.props.lastSendMessageResponse.result &&
                this.props.lastSendMessageResponse.result.videoConsultId) ||
            null
        );
    }
    // endregion
}

export default VideoLobby;
