import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledSkipToContentLink = styled.a`
    background-color: ${Tokens.COLOURS.greyscale.white};
    color: ${Tokens.COLOURS.greyscale.metal};
    right: 2%;
    padding: ${Tokens.SIZES[1.5]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    border-radius: ${Tokens.SIZES[0.5]};
    position: absolute;
    transform: translateY(-100%);
    z-index: 999;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        transform: translateY(0%);
    }
`;

export { StyledSkipToContentLink };
