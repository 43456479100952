import { Text } from "@accurx/design";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import { generatePath } from "react-router";

import { ReactRouterLink } from "app/sharedComponents/reactRouterLink/ReactRouterLink";

import {
    WORKSPACE_DESCRIPTION_CHAR_LIMIT,
    WORKSPACE_DESCRIPTION_MIN_CHAR,
    WORKSPACE_NAME_CHAR_LIMIT,
    WORKSPACE_NAME_MIN_CHAR,
} from "./constants";

export type NameError = {
    summaryMessage: string;
    detailMessage: JSX.Element | string;
};

export const getGenericError = () => {
    return {
        detailMessage: "",
        summaryMessage: "Something went wrong. Please contact support.",
    };
};

export const getDuplicateWorkspaceError = (workspaceId: number): NameError => {
    return {
        detailMessage: (
            <Text>
                Workspace name is the same as an existing one.{" "}
                <ReactRouterLink
                    to={generatePath(
                        WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin,
                        {
                            workspaceId,
                        },
                    )}
                >
                    Join the existing workspace
                </ReactRouterLink>{" "}
                or enter a different name.
            </Text>
        ),
        summaryMessage:
            "Workspace name is the same as an existing one. Join the existing workspace or enter a different name.",
    };
};

export const WORKSPACE_NAME_CHAR_LIMIT_ERROR_TEXT = `Workspace names must be ${WORKSPACE_NAME_CHAR_LIMIT} characters or less`;
export const DESCRIPTION_CHAR_LIMIT_ERROR = `Workspace descriptions must be ${WORKSPACE_DESCRIPTION_CHAR_LIMIT} characters or less`;
export const WORKSPACE_NAME_MIN_CHAR_ERROR_TEXT = `Workspace names must be at least ${WORKSPACE_NAME_MIN_CHAR} characters`;
export const DESCRIPTION_MIN_CHAR_ERROR = `Workspace descriptions must be at least ${WORKSPACE_DESCRIPTION_MIN_CHAR} characters`;
export const NAME_ABUSIVE_LANGUAGE_ERROR = `Workspace names must not contain abusive language`;
export const DESCRIPTION_ABUSIVE_LANGUAGE_ERROR = `Workspace descriptions must not contain abusive language`;
export const WORKSPACE_SPECIALTY_ERROR = "Select a specialty";

export const WORKSPACE_NAME_CHAR_LIMIT_ERROR: NameError = {
    summaryMessage: WORKSPACE_NAME_CHAR_LIMIT_ERROR_TEXT,
    detailMessage: WORKSPACE_NAME_CHAR_LIMIT_ERROR_TEXT,
};

export const WORKSPACE_NAME_MIN_CHAR_ERROR: NameError = {
    summaryMessage: WORKSPACE_NAME_MIN_CHAR_ERROR_TEXT,
    detailMessage: WORKSPACE_NAME_MIN_CHAR_ERROR_TEXT,
};
