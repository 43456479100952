import { Tabs, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Redirect } from "react-router";

import {
    Breadcrumb,
    Breadcrumbs,
    Container,
    Header,
    Spinner,
} from "app/organisations/shared";
import { VaccineApprovalRequests } from "app/practices/vaccine/approvalRequests/ApprovalRequests";
import { VaccineCapacityDashboard } from "app/practices/vaccine/capacityDashboard/CapacityDashboard";
import { VaccineSiteSetup } from "app/practices/vaccine/siteSetup/SiteSetup";
import { VaccineAllPatientsInvitedContainer as VaccineAllPatientsInvited } from "app/practices/vaccine/vaccineAllPatientsInvited/VaccineAllPatientsInvited.container";
import { VaccineRecordingAllSessions } from "app/practices/vaccine/vaccineRecording/vaccineAllSessions/VaccineRecordingAllSessions.component";
import { LoadingStatus } from "shared/LoadingStatus";
import { ROUTES_ORGS } from "shared/Routes";

import { useModules, usePractices } from "../hooks";
import { TABS } from "./AccubookPage.constants";
import { useAccubookPage } from "./AccubookPage.hooks";
import { MainPanel } from "./AccubookPage.styles";

// TODO remove non-lazy import after code-splitting rollout
// import {
//     VaccineApprovalRequests,
//     VaccineSiteSetup,
//     VaccineAllPatientsInvited,
//     VaccineRecordingAllSessions,
//     VaccineCapacityDashboard,
// } from "app/practices/vaccine";

export const AccubookPage = () => {
    useAccurxWebTitle("AccuBook");
    const practices = usePractices();
    const { tab, onTabChange, redirect } = useAccubookPage();

    const modules = useModules();

    const isAccubookOrg = modules?.accubook.organisation;
    const isDeliverySite = modules?.accubook.deliverySite;
    const showVaccineLinks = modules?.accubook.vaccineLinks;

    if (!tab) return <Redirect to={redirect} />;
    if (practices.loadingStatus !== LoadingStatus.Loaded) return <Spinner />;

    const filteredTabs = TABS.filter(({ tabId }) => {
        switch (tabId) {
            case "manage-patients":
                return showVaccineLinks && isAccubookOrg;
            case "manage-clinics":
                return isDeliverySite;
            case "booked-patients":
                return showVaccineLinks && (isDeliverySite || isAccubookOrg);
            case "delivery-sites":
                return isAccubookOrg;
            case "dashboard":
                return isAccubookOrg;
            default:
                return false;
        }
    });

    if (!modules) return null;

    return (
        <Tabs selected={tab.tabId} onTabChange={onTabChange}>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb
                        text="AccuBook"
                        path={ROUTES_ORGS.accubook}
                        params={{ tab: tab?.tabId }}
                    />
                    <Breadcrumb
                        text={tab.text}
                        path={ROUTES_ORGS.accubook}
                        params={{ tab: tab?.tabId }}
                    />
                </Breadcrumbs>

                <Text variant="title" as="h1" skinny>
                    AccuBook
                </Text>

                <Header.TabList tabs={filteredTabs} />
            </Header>
            <Container>
                <MainPanel>
                    {showVaccineLinks && isAccubookOrg && (
                        <Tabs.Panel tabId="manage-patients">
                            <VaccineAllPatientsInvited noHeader />
                        </Tabs.Panel>
                    )}
                    {isDeliverySite && (
                        <Tabs.Panel tabId="manage-clinics">
                            <VaccineSiteSetup noHeader />
                        </Tabs.Panel>
                    )}
                    {showVaccineLinks && (isDeliverySite || isAccubookOrg) && (
                        <Tabs.Panel tabId="booked-patients">
                            <VaccineRecordingAllSessions />
                        </Tabs.Panel>
                    )}
                    {isAccubookOrg && (
                        <>
                            <Tabs.Panel tabId="delivery-sites">
                                <VaccineApprovalRequests noHeader />
                            </Tabs.Panel>
                            <Tabs.Panel tabId="dashboard">
                                <VaccineCapacityDashboard noHeader />
                            </Tabs.Panel>
                        </>
                    )}
                </MainPanel>
            </Container>
        </Tabs>
    );
};

export default AccubookPage;
