import { BatchPatientIdentifier } from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";

import { IdentifiersMap } from "../constants";
import {
    ErrorRowsFormatter,
    ErrorValuesFormatter,
    InvalidUploadPatientFileResponse,
    ValidationError,
} from "../types";

const pluralRules = new Intl.PluralRules("en-GB");

const mapPlural: Record<Intl.LDMLPluralRule, string> = {
    zero: "s",
    one: "",
    two: "s",
    few: "s",
    many: "s",
    other: "s",
};

const joinValues = (values: string[] | number[]) => {
    return values.length > 1
        ? `${values.slice(0, -1).join(", ")} and ${
              values.at(-1) as string | number
          }`
        : values.join(", ");
};

const ParsingErrorsMap: Record<string, ErrorValuesFormatter> = {
    MissingColumns: (values) => {
        const rule = pluralRules.select(values.length);

        return {
            mainMessage: `Missing ${values.length} required column${mapPlural[rule]}:`,
            details: `${joinValues(values)}.`,
        };
    },
    DuplicateColumns: (values) => {
        const rule = pluralRules.select(values.length);

        return {
            mainMessage: `Found duplicate column${mapPlural[rule]}:`,
            details: `${joinValues(values)}.`,
        };
    },
    UnknownDateFormat: () => ({
        mainMessage: `Unknown date format:`,
        details: `make sure dates in the file are in the format D-MMM-YYYY (e.g. 9-May-2024).`,
    }),
    ExceedsMaxNumberOfPatients: () => ({
        mainMessage: `Too many patients:`,
        details: `you can send a message to a maximum of 25,000 patients in one batch.`,
    }),
    NoPatientsFound: () => ({
        mainMessage: `No patients found:`,
        details: `please upload a file with the list of patients.`,
    }),
    Other: () => ({
        mainMessage: `Unexpected file processing error:`,
        details: `please try again.`,
    }),
} as const;

const DataErrorsMap: Record<string, ErrorRowsFormatter> = {
    MissingDateOfBirth: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Missing date of birth on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    CannotReadDateOfBirth: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Invalid date of birth on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    DateOfBirthInFuture: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Date of birth in the future on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    ContainsDeceasedPatient: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Deceased patient contact details found on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    MissingPatientIdentifier: (rowNumbers, options) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Missing ${IdentifiersMap[options.identifier]} on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    InvalidPatientIdentifier: (rowNumbers, options) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Invalid ${IdentifiersMap[options.identifier]} on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    InvalidEmailAddress: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Invalid email on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    ContactNumberTooLong: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Invalid phone number on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    DuplicatePatients: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Duplicate patient${mapPlural[rule]} found on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
    MultipleContactNumbersForPatient: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Conflicting phone number for existing patient${
                mapPlural[rule]
            } on ${rowNumbers.length > 1 ? rowNumbers.length : ""} row${
                mapPlural[rule]
            }:`,
            details: joinValues(rowNumbers),
        };
    },
    MultipleEmailAddressesForPatient: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Conflicting email address for existing patient${
                mapPlural[rule]
            } on ${rowNumbers.length > 1 ? rowNumbers.length : ""} row${
                mapPlural[rule]
            }:`,
            details: joinValues(rowNumbers),
        };
    },
    CannotParseRow: (rowNumbers) => {
        const rule = pluralRules.select(rowNumbers.length);
        return {
            mainMessage: `Unexpected processing error on ${
                rowNumbers.length > 1 ? rowNumbers.length : ""
            } row${mapPlural[rule]}:`,
            details: joinValues(rowNumbers),
        };
    },
} as const;

export const mapFileProcessingErrors = (
    data: InvalidUploadPatientFileResponse,
    identifier: BatchPatientIdentifier,
) => {
    const allErrors: ValidationError[] = [];

    data.validationErrors.parsingErrors.forEach((error) => {
        if (error.type in ParsingErrorsMap) {
            allErrors.push(
                ParsingErrorsMap[error.type](error.values || [], {
                    identifier,
                }),
            );
        } else {
            allErrors.push(
                ParsingErrorsMap.Other(error.values || [], { identifier }),
            );
            Log.error(
                "Unknown parsing error returned after file upload on batch flow",
                {
                    tags: {
                        errorType: error.type,
                    },
                },
            );
        }
    });

    data.validationErrors.dataErrors.forEach((error) => {
        if (error.type in DataErrorsMap) {
            allErrors.push(
                DataErrorsMap[error.type](error.rowNumbers, { identifier }),
            );
        } else {
            allErrors.push(
                DataErrorsMap.CannotParseRow(error.rowNumbers, { identifier }),
            );
            Log.error(
                "Unknown data error returned after file upload on batch flow",
                {
                    tags: {
                        errorType: error.type,
                    },
                },
            );
        }
    });

    return allErrors;
};
