import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { shardedMap } from "domains/concierge/internal/util/shardedMap";
import { Conversation } from "domains/concierge/types";
import { Draft } from "immer";

/**
 * Warning - this mutates the state argument. Only use it with immer
 */
export const setConversation = (
    state: Draft<Pick<ConversationsState, "items">>,
    conversation: Conversation,
) => {
    shardedMap.setValue(state.items, conversation.id, conversation);
};
