import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledInfoBoxLink = styled(UI.Link)`
    padding: 0;

    // In UI.Link we force the container to span the full width of
    // the content, but this means that reply will overflow if window
    // is smaller that the link text.
    // Do not force the width of the link to be the max content
    // otherwise it doesn't go on multiple lines
    width: initial;

    font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};

    // If there is an icon, make it 20px
    svg {
        width: 20px;
        height: 20px;
    }
`;
