import { useState } from "react";

import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { QuickViewPortal, useQuickViewContext } from "@accurx/quick-view";
import { useNHSAdviceLinks } from "domains/compose/hooks/useNHSAdviceLinksQuery";

import { LiveSearchInput } from "../LiveSearchInput/LiveSearchInput";
import {
    StyledPaddedBody,
    StyledTabContainer,
    StyledTemplateSearchContainer,
    StyledTemplatesScroller,
} from "../MessageTemplates/components/MessageTemplateSelector/MessageTemplateSelector.styles";
import {
    StyledButton,
    StyledListItem,
    StyledTitleText,
} from "../MessageTemplates/components/MessageTemplateSelector/components/ListItem.styles";
import { StyledTemplateListSection } from "../MessageTemplates/components/MessageTemplateSelector/components/TemplateListSection.styles";
import { StyledList } from "../MessageTemplates/components/MessageTemplateSelector/components/TemplatePreview.styles";
import { RefreshableErrorState } from "../RefreshableErrorState/RefreshableErrorState";
import { NHSAdviceSelectorProps } from "./NHSAdviceSelector.types";
import { StyledLink } from "./NhsAdviceSelector.styles";
import { useNHSAdviceLinksFilter } from "./useNHSAdviceLinksFilter";

export const NHSAdviceSelector = ({
    onSelectNHSAdvice,
}: NHSAdviceSelectorProps) => {
    const { onClose } = useQuickViewContext();

    const { data, status, refetch } = useNHSAdviceLinks();

    const [searchTerm, setSearchTerm] = useState("");

    const { groupedNhsAdviceLinks, count } = useNHSAdviceLinksFilter({
        links: data ?? [],
        searchTerm,
    });

    return (
        <>
            <QuickViewPortal.Header>
                <Pill.SecondaryButton dimension="small" onClick={onClose}>
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>
            </QuickViewPortal.Header>
            <QuickViewPortal.Body>
                <StyledTabContainer>
                    <StyledTemplateSearchContainer>
                        <UI.Text as="h2" variant="label" skinny>
                            NHS.UK health advice A to Z
                        </UI.Text>
                        <LiveSearchInput
                            placeholder={"Search for advice"}
                            aria-label={"Search for NHS advice"}
                            value={searchTerm}
                            disabled={status === "loading"}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                        />
                    </StyledTemplateSearchContainer>
                    {status === "error" && (
                        <RefreshableErrorState
                            text="Sorry, something went wrong loading NHS advice. Click
                        below to try again."
                            onClickRefresh={() => void refetch()}
                        />
                    )}
                    {status === "loading" && (
                        <StyledPaddedBody>
                            <UI.Flex justifyContent="center">
                                <UI.Ds.Spinner />
                            </UI.Flex>
                        </StyledPaddedBody>
                    )}
                    {status === "success" && (
                        <StyledTemplatesScroller>
                            {count > 0 ? (
                                <StyledList aria-label="NHS advice">
                                    {Object.keys(groupedNhsAdviceLinks).map(
                                        (key, index) => {
                                            const links =
                                                groupedNhsAdviceLinks[key];

                                            return (
                                                <li key={`${key}-${index}`}>
                                                    <StyledTemplateListSection.Heading>
                                                        <UI.VisuallyHidden>
                                                            <UI.Text
                                                                as="label"
                                                                props={{
                                                                    id: `${key}-nhs-advice`,
                                                                }}
                                                                skinny
                                                            >
                                                                NHS advice
                                                                beginning with{" "}
                                                                {key === "#"
                                                                    ? "a numerical value"
                                                                    : `the letter ${key}`}
                                                            </UI.Text>
                                                        </UI.VisuallyHidden>
                                                        <UI.Text
                                                            variant="note"
                                                            colour={"zinc"}
                                                            skinny
                                                            as="h3"
                                                        >
                                                            {key}
                                                        </UI.Text>
                                                    </StyledTemplateListSection.Heading>
                                                    <StyledTemplateListSection.List
                                                        aria-labelledby={`${key}-nhs-advice`}
                                                    >
                                                        {links.map((link) => {
                                                            return (
                                                                <StyledListItem
                                                                    key={`${link.name}-${link.url}`}
                                                                >
                                                                    <StyledButton
                                                                        onClick={() =>
                                                                            onSelectNHSAdvice(
                                                                                link,
                                                                            )
                                                                        }
                                                                    >
                                                                        <StyledTitleText
                                                                            variant="preview"
                                                                            skinny
                                                                        >
                                                                            {
                                                                                link.name
                                                                            }
                                                                        </StyledTitleText>
                                                                    </StyledButton>
                                                                    <StyledLink
                                                                        href={`https://${link.url}`}
                                                                        openInNewTab
                                                                    >
                                                                        Preview
                                                                        <UI.Link.Icon />
                                                                    </StyledLink>
                                                                </StyledListItem>
                                                            );
                                                        })}
                                                    </StyledTemplateListSection.List>
                                                </li>
                                            );
                                        },
                                    )}
                                </StyledList>
                            ) : (
                                // aria-live=assertive is recommended here so screen reader users
                                // do not continue typing when we've found no results.
                                <StyledPaddedBody aria-live="assertive">
                                    <UI.Text skinny variant="body">
                                        {searchTerm === ""
                                            ? "No NHS advice links found."
                                            : `No results found for '${searchTerm}'.`}
                                    </UI.Text>
                                </StyledPaddedBody>
                            )}
                        </StyledTemplatesScroller>
                    )}
                </StyledTabContainer>
            </QuickViewPortal.Body>
        </>
    );
};
