import { generatePath } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { templateTypeToCreateParam } from "./manageTemplates/ManageAccumailTemplates.tabs";
import { CreatableTemplate } from "./templates.types";

export const createNewTemplateLink = (type: CreatableTemplate, orgId: number) =>
    generatePath(ROUTES_ORGS.accumailManageTemplatesCreate, {
        orgId,
        type: templateTypeToCreateParam(type),
    });
