/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Button, Icon, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { NeedAssistance } from "../shared/NeedAssistance";

export type TwoFactorConfirmSkipProps = {
    isOpen: boolean;
    handleConfirmSkip: () => void;
    handleCancelSkip: () => void;
};

export const TwoFactorConfirmSkipModal = ({
    isOpen,
    handleConfirmSkip,
    handleCancelSkip,
}: TwoFactorConfirmSkipProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const confirmSkipTwoFactor = (): void => {
        FlemingAnalyticsTracker.trackConfirmSkipTwoFactor(
            analyticsLoggedInProps,
        );
        handleConfirmSkip();
    };

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader tag="div">
                <Text
                    variant="subtitle"
                    as="h1"
                    skinny
                    props={{ className: "d-flex align-items-center" }}
                >
                    <Icon
                        theme="Fill"
                        name="Warning"
                        halo={{ colour: "red" }}
                        size={4}
                        props={{ className: "mr-1" }}
                    />
                    <span>Are you sure you want to skip?</span>
                </Text>
            </ModalHeader>
            <ModalBody>
                <Text skinny>
                    By skipping, you <strong>won't</strong> be able to use all
                    Accurx features.
                </Text>
                <Text className="mb-3">For example: Record View.</Text>
                <NeedAssistance onCallback={handleCancelSkip} />
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button onClick={handleCancelSkip} text="Back" />
                <Button
                    theme="secondary"
                    onClick={confirmSkipTwoFactor}
                    text="Skip security"
                />
            </ModalFooter>
        </Modal>
    );
};
