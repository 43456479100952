import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { PatientThreadItemUpdate } from "shared/concierge/conversations/tickets/types/dto.types";
import { isInstance } from "shared/concierge/conversations/utils";
import { HubClient, SocketEvents } from "shared/hubClient/HubClient";

import {
    mapTicketItemUpdateToUserAndTeamSummaries,
    mapUserGroupUpdateToTeamMembershipChanges,
} from "./mappers/SignalREventMapper";
import {
    TeamUpdate,
    UsersAndTeamsSummaries,
} from "./types/usersAndTeams.types";

export const subscribeToUserTeamChangesFromTicketUpdates = (
    hubClient: HubClient,
    workspaceId: number,
): Observable<UsersAndTeamsSummaries> => {
    return hubClient
        .getSubscription(SocketEvents.OnPatientThreadItemChanged)
        .pipe(
            filter((event) => filterByWorkspaceId(event.payload, workspaceId)),
            map((event) =>
                mapTicketItemUpdateToUserAndTeamSummaries(event.payload),
            ),
            filter(isInstance),
        );
};

export const subscribeToTeamUpdatesFromTicketUpdates = (
    hubClient: HubClient,
    workspaceId: number,
    currentUserId: string,
): Observable<TeamUpdate> => {
    return hubClient.getSubscription(SocketEvents.OnUserGroupChanged).pipe(
        filter((event) => event.payload.organisationId === workspaceId),
        map((event) =>
            mapUserGroupUpdateToTeamMembershipChanges(
                event.payload,
                currentUserId,
            ),
        ),
        filter(isInstance),
    );
};

const filterByWorkspaceId = (
    patientThreadItemUpdate: PatientThreadItemUpdate,
    workspaceId: number,
): boolean => {
    return patientThreadItemUpdate.organisationId === workspaceId;
};
