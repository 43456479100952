import { FeatureName } from "@accurx/auth";
import {
    JoinWorkspacePage,
    WORKSPACE_MANAGEMENT_ROUTES,
} from "@accurx/workspace-management";
import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
import { CreateWorkspacePage } from "app/workspace/pages/CreateWorkspacePage/CreateWorkspacePage";
import { SelectOrganisationPage } from "app/workspace/pages/SelectOrganisation/SelectOrganisationPage";

import NotFound from "../../../NotFound";
import { GuardedRoute } from "../GuardedRoute";

const FlexibleWorkspaceRoute = ({
    path,
    children,
}: {
    path: string;
    children: JSX.Element;
}) => (
    <GuardedRoute
        path={path}
        exact
        requires={{
            authenticationStatus: "LoggedInWith2FA",
            onboarded: true,
        }}
    >
        <FeatureGuard all={[FeatureName.FlexibleWorkspace]}>
            {children}
        </FeatureGuard>
    </GuardedRoute>
);

export const WorkspaceOnboardingRoutesSection = () => {
    return (
        <Switch>
            <GuardedRoute
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
                path={WORKSPACE_MANAGEMENT_ROUTES.selectOrganisation}
                exact
            >
                <SelectOrganisationPage />
            </GuardedRoute>
            <FlexibleWorkspaceRoute
                path={WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin}
            >
                <JoinWorkspacePage />
            </FlexibleWorkspaceRoute>
            <FlexibleWorkspaceRoute
                path={WORKSPACE_MANAGEMENT_ROUTES.workspaceCreate}
            >
                <CreateWorkspacePage />
            </FlexibleWorkspaceRoute>
            <SimpleRoute path="*">
                <NotFound />
            </SimpleRoute>
        </Switch>
    );
};
