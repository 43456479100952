import * as UI from "@accurx/design";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import styled from "styled-components";

export const StyledTabsList = styled(NavigationMenu.List)`
    list-style-type: none;
    padding: 0 ${UI.Tokens.SIZES[0.5]};
    margin: 0;
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
`;
