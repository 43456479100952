import { useState } from "react";

import { AppointmentTypeFilter } from "@accurx/api/appointment";
import { useErrorSummary } from "@accurx/design";
import { z } from "zod";

import { MAX_SMS_CHAR_LIMIT } from "../constants";

export const reduceReminderMessage = `Reduce the message below ${
    MAX_SMS_CHAR_LIMIT + 1
} characters`;
export const reducePostAppointmentMessage = `Reduce the post-appointment message below ${
    MAX_SMS_CHAR_LIMIT + 1
} characters`;

const validationRules = {
    siteNames: z
        .array(z.string())
        .nonempty({ message: "Select at least one site location" }),
    slotTypes: z
        .array(z.object({ value: z.string() }))
        .nonempty({ message: "Select at least one slot type" }),
    selectedClinics: z
        .array(z.object({ value: z.string() }))
        .nonempty({ message: "Select at least one clinic" }),
    selectedAppointmentType: z.nativeEnum(AppointmentTypeFilter, {
        errorMap: () => ({ message: "Select an appointment type" }),
    }),
    reminderMessageCharacterCount: z.number().max(MAX_SMS_CHAR_LIMIT, {
        message: reduceReminderMessage,
    }),
    postAppointmentMessageBody: z.string().min(1, {
        message:
            "Write the message you want to send to patients after their appointment",
    }),
    postAppointmentMessageCharacterCount: z.number().max(MAX_SMS_CHAR_LIMIT, {
        message: reducePostAppointmentMessage,
    }),
};

export const commonFieldsSchema = z
    .object({
        reminderMessageCharacterCount:
            validationRules.reminderMessageCharacterCount,
    })
    .and(
        z.discriminatedUnion("postAppointmentMessageEnabled", [
            z.object({
                postAppointmentMessageEnabled: z.literal(false),
                postAppointmentMessageCharacterCount: z.number(),
                postAppointmentMessageBody: z.string(),
            }),
            z.object({
                postAppointmentMessageEnabled: z.literal(true),
                postAppointmentMessageCharacterCount:
                    validationRules.postAppointmentMessageCharacterCount,
                postAppointmentMessageBody:
                    validationRules.postAppointmentMessageBody,
            }),
        ]),
    );

export const clinicsSchema = commonFieldsSchema.and(
    z.object({
        selectedSlotTypes: validationRules.selectedClinics,
        selectedAppointmentType: validationRules.selectedAppointmentType,
    }),
);

export const siteNamesAndSlotTypesSchema = commonFieldsSchema.and(
    z.object({
        selectedSiteNames: validationRules.siteNames,
        selectedSlotTypes: validationRules.slotTypes,
    }),
);

type ValidationSchema =
    | typeof commonFieldsSchema
    | typeof clinicsSchema
    | typeof siteNamesAndSlotTypesSchema;

const initialFormSchema = z.object({
    selectedSiteNames: z.array(z.string()),
    selectedSlotTypes: z
        .array(z.object({ label: z.string(), value: z.string() }))
        .nonempty(),
    selectedAppointmentType: z.enum(["FaceToFace", "Telephone"]),
    templateType: z.enum(["FaceToFace", "Telephone"]),
    customMessage: z.string(),
    oneWeekEnabled: z.boolean(),
    threeDaysEnabled: z.boolean(),
    postAppointmentMessageEnabled: z.boolean(),
    postAppointmentMessageBody: z.string(),
});

export const validateInitialFormData = (formValues: unknown) => {
    const parseResult = initialFormSchema.safeParse(formValues);

    if (parseResult.success) {
        return { success: true };
    }

    const flattenedErrors = parseResult.error.flatten();
    const errors: Record<string, string> = {};
    Object.entries(flattenedErrors.fieldErrors).forEach(([key, value]) => {
        errors[key] = value.join(", ");
    });

    if (flattenedErrors.formErrors.length) {
        errors.formErrors = flattenedErrors.formErrors.join(", ");
    }

    return { success: false, errors };
};

const partialSchema = z.object(validationRules).partial();

type RawFormData = {
    selectedSiteNames: string[];
    selectedSlotTypes: Array<{ value: string }> | string[];
    selectedAppointmentType: string;
    reminderMessageCharacterCount: number;
    postAppointmentMessageEnabled: boolean;
    postAppointmentMessageBody: string;
    postAppointmentMessageCharacterCount: number;
};

type ValidationErrors = Partial<Record<keyof RawFormData, string[]>>;

type FieldsWithVisibleError = Exclude<
    keyof ValidationErrors,
    "postAppointmentMessageEnabled"
>;

const formFieldIds: Record<FieldsWithVisibleError, string> = {
    selectedSiteNames: "site_names",
    selectedSlotTypes: "slot_types",
    selectedAppointmentType: "appointment-type-filter",
    reminderMessageCharacterCount: "message-body",
    postAppointmentMessageBody: "post-appointment-message",
    postAppointmentMessageCharacterCount: "post-appointment-message",
};

export const isValid = (schema: ValidationSchema, rawFormData: RawFormData) => {
    const parseResult = schema.safeParse(rawFormData);

    if (parseResult.success) {
        return {
            success: true,
        };
    }

    return {
        success: false,
        error: parseResult.error.flatten().fieldErrors,
    };
};

const getSchemaForAvailableFilters = (
    availableFilters: "site-name-slot-type" | "clinic-appointment-type",
): ValidationSchema => {
    switch (availableFilters) {
        case "site-name-slot-type":
            return siteNamesAndSlotTypesSchema;
        case "clinic-appointment-type":
            return clinicsSchema;
    }
};

export const useFormValidation = (
    availableFilters: "site-name-slot-type" | "clinic-appointment-type",
) => {
    const { clearAllErrors, addMultipleErrorsByKey } = useErrorSummary();
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
        {},
    );

    const addErrorsToSummary = (errors: ValidationErrors) => {
        (Object.keys(errors) as FieldsWithVisibleError[]).forEach(
            (fieldName) => {
                if (errors[fieldName]?.length) {
                    errors[fieldName]?.forEach((errorMessage) => {
                        addMultipleErrorsByKey({
                            [fieldName]: {
                                id: formFieldIds[fieldName],
                                body: errorMessage,
                            },
                        });
                    });
                }
            },
        );
    };

    return {
        hasValidationErrors,
        validationErrors,
        validate: (rawFormData: RawFormData) => {
            clearAllErrors();
            setHasValidationErrors(false);
            setValidationErrors({});

            const validationResult = isValid(
                getSchemaForAvailableFilters(availableFilters),
                rawFormData,
            );
            if (validationResult.error) {
                addErrorsToSummary(validationResult.error);
                setHasValidationErrors(true);
                setValidationErrors(validationResult.error);
            }

            return validationResult;
        },
        /**
         * Validate a subset of form fields.
         * Useful for onChange validation.
         * */
        validatePartial: (partialFormValues: Partial<RawFormData>) => {
            const validationResult = partialSchema.safeParse(partialFormValues);

            setValidationErrors((prevErrors) => {
                const nextErrors = { ...prevErrors };
                // Clear existing errors for validated fields
                (
                    Object.keys(partialFormValues) as Array<keyof RawFormData>
                ).forEach((fieldName) => {
                    nextErrors[fieldName] = undefined;
                });

                if (validationResult.success) {
                    return nextErrors;
                }

                // Add new errors from partial validation
                return {
                    ...nextErrors,
                    ...validationResult.error.flatten().fieldErrors,
                };
            });
        },
    };
};
