import { generatePath, useLocation } from "react-router";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { ROUTES_ORGS } from "shared/Routes";

import { Tab, TabLookup } from "../../SettingsPage.constants";
import { StyledItem, StyledLabel, StyledLink } from "./SidebarLink.styles";

export type SidebarLinkProps = {
    tab: Tab;
    close: () => void;
};

export const SidebarLink = ({ tab, close }: SidebarLinkProps) => {
    const { text } = TabLookup[tab];
    const org = useCurrentOrganisation();
    const url = generatePath(ROUTES_ORGS.settings, { orgId: org?.orgId, tab });
    const location = useLocation();
    const isActive = location.pathname.endsWith(tab.toString());

    return isActive ? (
        <StyledItem role="presentation">
            <StyledLabel role="tab">{text}</StyledLabel>
        </StyledItem>
    ) : (
        <StyledItem role="presentation">
            <StyledLink to={url} onClick={close} role="tab">
                {text}
            </StyledLink>
        </StyledItem>
    );
};
