import {
    DateFormatOptions,
    DateHelpers,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

const useNextWorkingDayForDate = (
    {
        workspaceId,
        date,
    }: {
        workspaceId: number;
        date: string;
    },
    options?: UseQueryOptions<string, Error>,
) => {
    return useQuery({
        queryKey: ["NextWorkingDay", { workspaceId, date }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/NextWorkingDay/${workspaceId}/getnextworkingday/${date}`,
                }),
            );

            return returnDataOrThrow<string>(response);
        },
        ...options,
    });
};

type UseNextWorkingDay = { workspaceId: number; enabled: boolean };

export const useNextWorkingDay = ({
    workspaceId,
    enabled,
}: UseNextWorkingDay) => {
    const today = new Date().toISOString();
    const date = DateHelpers.formatDate(
        today,
        DateFormatOptions.DATE_SHORT_NO_SPACES_REVERSE,
    );

    return useNextWorkingDayForDate({ workspaceId, date }, { enabled });
};
