import React from "react";

import { Card, Checkbox, Icon } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { PatientListAppointmentDay } from "api/FlemingDtos";
import {
    StyledButtonsDiv,
    StyledFlexDiv,
    StyledListCountText,
    StyledListNameText,
    StyledOuterDiv,
    StyledSummaryDetailsDiv,
    StyledSummaryDiv,
} from "app/patientLists/PatientListSummary.styles";

type PatientListDayCardProps = {
    patientListName: string;
    appointmentDay: PatientListAppointmentDay;
    isSelected: boolean;
    onDaySelectChanged: (
        appointmentDay: PatientListAppointmentDay,
        checked: boolean,
    ) => void;
};
export const PatientListDayCard = ({
    patientListName,
    appointmentDay,
    isSelected,
    onDaySelectChanged,
}: PatientListDayCardProps) => {
    const formattedAppointmentDay =
        appointmentDay.date === ""
            ? "No date specified"
            : DateHelpers.formatDate(
                  appointmentDay.date,
                  DateFormatOptions.DATE_SHORT_WITH_SPACES,
              );

    return (
        <Card spacing={2}>
            <StyledOuterDiv data-testid="patient-list-day-card">
                <StyledFlexDiv>
                    <Icon
                        name="List"
                        halo={{ colour: "blue", luminosity: "high" }}
                        size={6}
                    />
                    <StyledSummaryDiv>
                        <StyledListNameText variant="label">
                            {`${patientListName} (${formattedAppointmentDay})`}
                        </StyledListNameText>
                        <StyledSummaryDetailsDiv>
                            <StyledListCountText variant="preview">
                                {appointmentDay.appointments.length} patient{" "}
                                {appointmentDay.appointments.length === 1
                                    ? "entry"
                                    : "entries"}
                            </StyledListCountText>
                        </StyledSummaryDetailsDiv>
                    </StyledSummaryDiv>
                </StyledFlexDiv>
                <StyledButtonsDiv>
                    <Checkbox
                        id={`date-select-${appointmentDay.date}`}
                        text="Select"
                        theme="bordered"
                        checked={isSelected}
                        onCheckChange={(checked) =>
                            onDaySelectChanged(appointmentDay, checked)
                        }
                    />
                </StyledButtonsDiv>
            </StyledOuterDiv>
        </Card>
    );
};
