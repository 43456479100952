import * as UI from "@accurx/design";
import styled from "styled-components";

export const NoWrapCheckboxLabel = styled(UI.Text).attrs({
    skinny: true,
    variant: "preview",
})`
    text-wrap: nowrap;
    color: inherit;
`;
