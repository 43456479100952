import {
    LogInMutationVariables,
    useLogInMutation,
} from "./hooks/mutations/useLogInMutation";
import {
    LogOutMutationVariables,
    useLogOutMutation,
} from "./hooks/mutations/useLogOutMutation";
import { useCurrentUserQuery } from "./hooks/queries/useCurrentUserQuery";
import { LoggedInOrOutUser, User } from "./types/User";

type LogInFn = (
    args: LogInMutationVariables,
    options?: { onSuccess?: (data: User) => void },
) => void;
type LogoutFn = (args: LogOutMutationVariables) => void;
/**
 * Provides a logged in or out user
 * and a logIn and logOut function
 *
 * Use:
 * - when logging the user in and out
 * - when the component could expect a logged out
 *   user (better to use useCurrentUser otherwise)
 */
export const useAuth = (): {
    user: LoggedInOrOutUser;

    logIn: LogInFn;
    logOut: (args: LogOutMutationVariables) => void;

    isLoading: boolean;
    error?: string;
} => {
    const { data: user } = useCurrentUserQuery({
        enabled: false,
    });
    const logInMutation = useLogInMutation();
    const logOutMutation = useLogOutMutation();

    if (!user) {
        throw new Error("[useAuth] No current user, but there should be");
    }

    const logIn: LogInFn = (vars, options) => {
        logInMutation.mutate(vars, {
            onSuccess: (data) => {
                options?.onSuccess && options.onSuccess(data);
            },
        });
    };

    const logOut: LogoutFn = (vars) => {
        logOutMutation.mutate(vars);
    };

    return {
        user,
        logIn,
        logOut,
        isLoading: logInMutation.isLoading || logOutMutation.isLoading || false,
        error: logInMutation.error?.message || logOutMutation.error?.message,
    };
};
