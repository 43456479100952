import { DateHelpers } from "@accurx/shared";

export enum SearchFormErrors {
    NoMatchFound = "Sorry, we haven't found a patient matching these details. Please try again.",
    Other = "Sorry, something went wrong whilst searching for this patient. Please try again.",
    NhsNumberNotVerified = "Sorry, the NHS number of this patient has not been verified.",
    MultipleMatches = "Sorry, we encountered multiple matches while searching for this patient.",
}

export const formFieldIds = {
    nhsNumber: "input-nhsnumber",
    dobDay: "dob-dd",
    dobMonth: "dob-mm",
    dobYear: "dob-yyyy",
} as const;

export const demoPatientData = {
    firstName: "Frodo",
    lastName: "Baggins",
    postcode: "B4G 3ND",
    gender: "Male",
    nhsNumber: "7777777777",
    // We subtract 14700 (40 and a bit years) days to the current date to match the server fake patient response
    dobDate: DateHelpers.getAndFormatDateForDaysBeforeToday(
        14700,
        "DD/MM/YYYY",
    ),
    dobDay: DateHelpers.getAndFormatDateForDaysBeforeToday(14700, "DD"),
    dobMonth: DateHelpers.getAndFormatDateForDaysBeforeToday(14700, "MM"),
    dobYear: DateHelpers.getAndFormatDateForDaysBeforeToday(14700, "YYYY"),
};

export const searchForPatientFormIds = {
    defaultForm: "default-form",
    // Used when adding a patient to a patient list
    patientList: "patient-list",
    // Used for matching a patient to a PIFU response
    pifuPatientMatching: "pifu-patient-matching",
    // Used under the Search by NHS number tab on the 'Search for patients' page
    allPatients: "all-patients-form",
    // Used under the Search by name tab on the 'Search for patients' page
    allPatientsByName: "all-patients-by-name-form",
} as const;

export type SearchForPatientFormIds =
    (typeof searchForPatientFormIds)[keyof typeof searchForPatientFormIds];
