import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { PatientExternalId } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkTicketAsUnreadOptions = {
    ticketIdentity: TicketIdentity;
    workspaceId: number;
    patientExternalIds?: PatientExternalId[];
};

export const markTicketAsUnread: ConciergeFetcher<
    MarkTicketAsUnreadOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const patientExternalIds = arg.patientExternalIds
        ? mapPatientSummaryExternalIdsToTicketPatientExternalIds(
              arg.patientExternalIds,
          )
        : undefined;

    const response = await TicketApiClient.markTicketUnread({
        organisationId: arg.workspaceId,
        ticketIdentity: arg.ticketIdentity,
        patientExternalIdentity: patientExternalIds
            ? {
                  patientExternalIds: patientExternalIds,
              }
            : undefined,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
