import { Ds } from "@accurx/design";

const BADGES_COLOURS_LABELS: Record<
    string,
    {
        colour: Ds.BadgeProps["color"];
        label:
            | string
            | {
                  verbose: string;
                  compact: string;
              };
    }
> = {
    Low: {
        colour: "green",
        label: {
            verbose: "Low complexity",
            compact: "Low",
        },
    },
    MediumHigh: {
        colour: "red",
        label: {
            verbose: "Medium/High complexity",
            compact: "Medium/High",
        },
    },
    Calculating: {
        colour: "greyscale",
        label: {
            verbose: "Calculating complexity",
            compact: "Calculating",
        },
    },
} as const;

/**
 *
 * @param complexity
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'complexity' string and returns the colour of the badge for the validation card.
 */
export const getComplexityBadgeColour = (
    complexity: string,
): Ds.BadgeProps["color"] => {
    return BADGES_COLOURS_LABELS[complexity]?.colour ?? "greyscale";
};

/**
 *
 * @param complexity
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'complexity' string and returns the string to display in the UI.
 */
export const getComplexityBadgeText = ({
    complexity,
    verbose,
}: {
    complexity: string;
    /**
     * Whether it should return the text in its "verbose" form.
     *
     * i.e.
     *
     * verbose === false -> Medium/High
     * verbose === true -> Medium/High complexity
     */
    verbose: boolean;
}): string => {
    if (BADGES_COLOURS_LABELS[complexity]) {
        const label = BADGES_COLOURS_LABELS[complexity]?.label;
        if (typeof label === "string") {
            // No difference between verbose & compact
            return label;
        }
        return verbose ? label.verbose : label.compact;
    }
    return complexity;
};
