import { useEffect } from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { useHistory } from "react-router";

import { trackManageTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useDeleteAccumailTemplateMutation } from "app/hooks/mutations";
import { useAccumailTemplatesQuery } from "app/hooks/queries";
import { Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useCurrentOrgId, useCurrentOrgName } from "store/hooks";

import { createNewTemplateLink } from "../../manageTemplatesHelpers";
import { AccumailTemplateList } from "../../templateList/AccumailTemplateList";
import { IntroductionContainer } from "../ManageAccumailTemplates.styles";

export const ManageUserTemplates = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const orgName = useCurrentOrgName();
    const { isLoading, isError, data } = useAccumailTemplatesQuery({
        workspaceId,
        templateType: "UserTemplate",
    });

    const history = useHistory();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    useEffect(() => {
        trackManageTemplatePageView({
            ...flemingLoggedInProperties,
            countTemplateCategories: 0, // Accumail templates don't have categories
            currentTab: "UserTemplates",
            productOrigin: "ClinicianMessage",
        });
    }, [flemingLoggedInProperties]);

    const { mutate: deleteTemplate, isError: deleteError } =
        useDeleteAccumailTemplateMutation();

    return (
        <>
            <IntroductionContainer>
                <Text variant="subtitle" as="h2">
                    My Templates
                </Text>
                <Text>
                    Here you can create your own templates that will only be
                    shown to you. When creating a new template, the message will
                    have a greeting (e.g. Dear Miss Flynn), patient details, and
                    a signature (e.g. {orgName}).
                </Text>
                <Button
                    text="Create New Template"
                    onClick={() =>
                        history.push(
                            createNewTemplateLink("UserTemplate", workspaceId),
                        )
                    }
                />
            </IntroductionContainer>
            {isLoading && <Spinner />}
            {(isError || deleteError) && (
                <Feedback
                    title={
                        deleteError
                            ? "There was a problem deleting the template"
                            : "Unable to fetch templates"
                    }
                    colour="error"
                >
                    Please refresh the page to try again.
                </Feedback>
            )}
            {!isLoading && !(isError || deleteError) && (
                <AccumailTemplateList
                    tableTitle="My Templates"
                    data={data}
                    hasActions={true}
                    onDelete={(templateId) => {
                        deleteTemplate({
                            workspaceId,
                            templateId,
                            templateType: "UserTemplate",
                        });
                    }}
                />
            )}
        </>
    );
};
