import { RemirrorJSON } from "@remirror/core";
import {
    EditorComponent,
    OnChangeJSON,
    Remirror,
    useRemirror,
} from "@remirror/react";
import { MentionAtomExtension } from "remirror/extensions";

import { StyledEditor } from "./AccumailTemplateEditor.styles";
import { mentionAtomName } from "./helpers/constants";
import { EditorMenu } from "./menu/Menu";

type AccumailTemplateEditorProps = {
    onChange: (content: RemirrorJSON) => void;
    initialContent?: RemirrorJSON;
    hasErrors?: boolean;
};

export const AccumailTemplateEditor = ({
    onChange,
    initialContent,
    hasErrors,
}: AccumailTemplateEditorProps) => {
    const { manager, state } = useRemirror({
        extensions: () => [
            new MentionAtomExtension({
                matchers: [{ name: mentionAtomName, char: "%" }],
            }),
        ],
        content: initialContent,
        selection: "start",
        stringHandler: "html",
    });

    return (
        <StyledEditor hasErrors={hasErrors}>
            <Remirror manager={manager} initialContent={state}>
                <OnChangeJSON onChange={onChange} />
                <EditorMenu />
                <EditorComponent />
            </Remirror>
        </StyledEditor>
    );
};
