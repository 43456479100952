import { useMemo } from "react";

import { ConversationItem } from "@accurx/concierge/types";
import { format } from "date-fns";
import styled from "styled-components";

type CreatedAtTimeProps = {
    time: ConversationItem["createdAt"];
};

const StyledContainer = styled.span`
    white-space: nowrap;
`;

export const CreatedAtTime = ({ time }: CreatedAtTimeProps) => {
    const createdTime = useMemo(() => {
        try {
            return format(new Date(time), "h:mmaaa");
        } catch (e) {
            return null;
        }
    }, [time]);

    return createdTime ? (
        <StyledContainer>{createdTime}</StyledContainer>
    ) : null;
};
