import { FeatureName, useFeatureFlag } from "@accurx/auth";
import { Tokens } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Redirect, Switch, generatePath } from "react-router";
import styled from "styled-components";

import { FeatureGuard } from "app/FeatureGuard";
import { NewInboxBanner } from "app/clinicianConversations/components/NewInboxBanner";
import { ClinicianConversation } from "app/clinicianconversation/ClinicianConversation";
import { ClinicianConversationProvider } from "app/clinicianconversation/providers";
import { GuardedRoute } from "app/guardedRoutes/GuardedRoute";
import { Container } from "app/layout/Container";
import { ROUTES, ROUTES_PRIMARY } from "shared/Routes";

import { ClinicianConversationsPage } from "./pages/ClinicianConversationsPage";
import { MessageGpPatientSearchPage } from "./pages/messageGpPatientSearchPage/MessageGpPatientSearchPage";

type WorkspaceConversationsSectionProps = {
    workspaceId: number;
};

const BannerContainer = styled.div`
    padding: ${Tokens.SIZES[1.5]} ${Tokens.SIZES[1.5]} 0;
`;

/**
 * This component acts as a controller for all
 * routes nested under "/w/:workspaceId/clinician-conversations"
 */
export const ClinicianConversationsSection = ({
    workspaceId,
}: WorkspaceConversationsSectionProps): JSX.Element => {
    useAccurxWebTitle("My clinician conversations");

    const hasNewInbox = useFeatureFlag("UnifiedInboxAccumailSandboxBuild");

    return (
        <Switch>
            <GuardedRoute
                key={ROUTES.workspaceClinicianConversationsView}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={ROUTES.workspaceClinicianConversationsView}
            >
                <FeatureGuard all={[FeatureName.AccumailSandboxWebInbox]}>
                    <ClinicianConversationsPage />
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                key={ROUTES.workspaceClinicianConversationSearchPatient}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={ROUTES.workspaceClinicianConversationSearchPatient}
            >
                <FeatureGuard
                    all={[
                        FeatureName.AccumailSandboxWebInbox,
                        FeatureName.CaregiverInitiated,
                    ]}
                >
                    <MessageGpPatientSearchPage />
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                key={ROUTES.workspaceClinicianConversationCompose}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={ROUTES.workspaceClinicianConversationCompose}
            >
                <FeatureGuard
                    all={[
                        FeatureName.AccumailSandboxWebInbox,
                        FeatureName.CaregiverInitiated,
                    ]}
                >
                    <Container>
                        <ClinicianConversationProvider.FromNewConversation>
                            <ClinicianConversation />
                        </ClinicianConversationProvider.FromNewConversation>
                    </Container>
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                key={ROUTES.workspaceClinicianConversationComposeTest}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={ROUTES.workspaceClinicianConversationComposeTest}
            >
                <FeatureGuard
                    all={[
                        FeatureName.AccumailSandboxWebInbox,
                        FeatureName.CaregiverInitiated,
                    ]}
                >
                    <Container>
                        <ClinicianConversationProvider.FromTestConversation>
                            <ClinicianConversation />
                        </ClinicianConversationProvider.FromTestConversation>
                    </Container>
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={[ROUTES.view_clinician_conversation]}
            >
                <>
                    {hasNewInbox && (
                        <BannerContainer>
                            <NewInboxBanner />
                        </BannerContainer>
                    )}
                    <Container>
                        <ClinicianConversationProvider.FromWorkspaceConversationId>
                            <ClinicianConversation />
                        </ClinicianConversationProvider.FromWorkspaceConversationId>
                    </Container>
                </>
            </GuardedRoute>
            <GuardedRoute
                key={ROUTES_PRIMARY.workspaceClinicianConversations}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
                path={ROUTES_PRIMARY.workspaceClinicianConversations}
            >
                <Redirect
                    to={generatePath(
                        ROUTES.workspaceClinicianConversationsView,
                        { workspaceId, view: "me" },
                    )}
                />
            </GuardedRoute>
        </Switch>
    );
};
