import { ReactNode } from "react";

import { StyledOuterTextArea, StyledTextArea } from "./TextAreaWrapper.styles";

export const TextAreaWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <StyledOuterTextArea>
            <StyledTextArea>{children}</StyledTextArea>
        </StyledOuterTextArea>
    );
};
