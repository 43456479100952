import { useEffect, useState } from "react";

import { FragmentUsageDaily } from "@accurx/api/patient-messaging";
import {
    Card,
    Flex,
    Grid,
    Item,
    Pagination,
    SelectItem,
    Spinner,
    Table,
    Text,
    usePagination,
    useTable,
} from "@accurx/design";

import {
    ALL_PRODUCTS,
    TRENDS_TABLE_PAGINATION_PAGE_SIZES,
} from "./FragmentsDashboard.const";
import { StyledSingleSelect } from "./FragmentsDashboard.styles";

type TrendsTableProps = {
    data: FragmentUsageDaily[];
    isLoading: boolean;
    productsList: string[];
};
export const TrendsTable = ({
    data,
    isLoading,
    productsList,
}: TrendsTableProps) => {
    const [productFilterValue, setProductFilterValue] = useState(ALL_PRODUCTS);

    const fragmentTrendsTable = useTable({
        data: data,
        columnDefs: [
            {
                header: "Date",
                id: "date",
            },
            {
                header: "SMS Sent",
                id: "sms-sent",
            },
            {
                header: "Fragments",
                id: "fragments",
            },
        ],
    });

    // On initial load, filter table (currently not supported by the useTable hook)
    useEffect(() => {
        fragmentTrendsTable.actions.filter(
            (data) => data.product === ALL_PRODUCTS,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // no depencies since adding fragmentTrendsTable.actions causes infinite loop

    const handleFilterByProduct = (selectedOption: string): void => {
        fragmentTrendsTable.actions.filter(
            (data) => data.product === selectedOption,
        );
        setProductFilterValue(selectedOption);
        goToPage(1);
    };

    const {
        currentPage,
        totalPages,
        currentPageSize,
        onNext,
        onPrevious,
        onPageSizeChange,
        paginate,
        goToPage,
    } = usePagination({
        defaultPage: 1,
        defaultPageSize: 10,
        total: fragmentTrendsTable.rows.length,
        isCycleEnabled: true,
    });

    const filteredPageSizes = TRENDS_TABLE_PAGINATION_PAGE_SIZES.filter(
        (pageSize) => pageSize < fragmentTrendsTable.rows.length,
    );

    return (
        <Card
            header={
                <Text
                    variant="subtitle"
                    skinny
                    props={{ id: "heading-table-2" }}
                    as="h2"
                >
                    Fragment trends over time
                </Text>
            }
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <Grid gap="2">
                    <Grid
                        columns={{
                            sm: "1fr",
                            md: "auto 1fr 1fr",
                        }}
                        gap="2"
                    >
                        <Item>
                            <Text
                                variant="label"
                                as="label"
                                props={{ htmlFor: "select-filter-by-product" }}
                            >
                                Filter by Product
                            </Text>
                        </Item>
                        <Item>
                            <StyledSingleSelect
                                id="select-filter-by-product"
                                placeholder="Filter by product"
                                value={productFilterValue}
                                onValueChange={handleFilterByProduct}
                                dimension="medium"
                            >
                                {productsList.map((product) => (
                                    <SelectItem key={product} value={product}>
                                        {product}
                                    </SelectItem>
                                ))}
                            </StyledSingleSelect>
                        </Item>
                    </Grid>
                    <Table aria-describedby="heading-table-2">
                        <Table.ColumnGroup>
                            <Table.Column width="30%" />
                            <Table.Column width="20%" />
                            <Table.Column width="20%" />
                        </Table.ColumnGroup>
                        <Table.Head>
                            <Table.Row>
                                {fragmentTrendsTable.columns.map((column) => (
                                    <Table.Header
                                        scope="col"
                                        colSpan={1}
                                        key={column.id}
                                    >
                                        {column.header}
                                    </Table.Header>
                                ))}
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {paginate(fragmentTrendsTable.rows).map(
                                (row, index) => (
                                    <Table.Row
                                        key={`${row.date}-${row.product}-${index}`}
                                    >
                                        <Table.Header scope="row">
                                            {row.date}
                                        </Table.Header>
                                        <Table.DataCell colSpan={1}>
                                            {row.numberOfMessages}
                                        </Table.DataCell>
                                        <Table.DataCell colSpan={1}>
                                            {row.numberOfFragments}
                                        </Table.DataCell>
                                    </Table.Row>
                                ),
                            )}
                        </Table.Body>
                    </Table>
                    <Flex justifyContent="space-between">
                        <Text skinny as="div">
                            Showing
                            <Pagination.SizeSelect
                                id="pagination-size-select"
                                onPageSizeChange={onPageSizeChange}
                                pageSize={currentPageSize}
                            >
                                {filteredPageSizes.map((pageSize) => (
                                    <Pagination.SizeOption
                                        value={pageSize}
                                        key={`${pageSize}`}
                                    />
                                ))}
                                <Pagination.SizeOption
                                    value={fragmentTrendsTable.rows.length}
                                >
                                    All
                                </Pagination.SizeOption>
                            </Pagination.SizeSelect>
                            {`of total ${fragmentTrendsTable.rows.length} row${
                                fragmentTrendsTable.rows.length === 1 ? "" : "s"
                            }`}
                        </Text>
                        <Pagination>
                            <Pagination.Navigation
                                currentPage={currentPage}
                                totalPages={totalPages}
                            >
                                {currentPage > 1 && (
                                    <Pagination.PreviousButton
                                        onPrevious={onPrevious}
                                    />
                                )}

                                {currentPage < totalPages && (
                                    <Pagination.NextButton onNext={onNext} />
                                )}
                            </Pagination.Navigation>
                        </Pagination>
                    </Flex>
                </Grid>
            )}
        </Card>
    );
};
