/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Button, Icon, Spinner, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useDispatch } from "react-redux";

import { NimsData, NimsVaccineProductType } from "../models/VaccineSharedDTO";
import { refreshNimsData } from "./VaccineAllPatientsInvited.actions";

export interface VaccineAllPatientsInvitedDetailsVaccinationDetailsProps {
    selectedPractice: string;
    inviteId: string;
    inviteBatchId: string;
    notInNetwork: boolean;
    patientDetailsLoading: boolean;
    patientDetailsError: string;
    nimsData: NimsData | null;
    nimsDataLoading: boolean;
    nimsDataError: string;
}

export const VaccineAllPatientsInvitedDetailsVaccinationDetails = ({
    selectedPractice,
    inviteId,
    inviteBatchId,
    notInNetwork,
    patientDetailsLoading,
    patientDetailsError,
    nimsData,
    nimsDataLoading,
    nimsDataError,
}: VaccineAllPatientsInvitedDetailsVaccinationDetailsProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleRefreshNims = (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();
        dispatch(
            refreshNimsData({
                organisationId: selectedPractice,
                inviteBatchId,
                inviteId,
            }),
        );
    };

    const renderVaccineTypeLine = (product: NimsVaccineProductType | null) =>
        `Vaccine type: ${product ?? "Not specified"}`;

    const renderPrimaryText = (nimsData: NimsData): JSX.Element | null => {
        const singleDoseDate = nimsData.isSingleDoseVaccine
            ? nimsData.covidFirstDoseDate ?? nimsData.covidSecondDoseDate // N.B. Expect it on the first date field for single dose
            : null;

        if (singleDoseDate) {
            return (
                <Text variant="body">
                    {renderVaccineTypeLine(nimsData.vaccineProduct)}
                    <br />
                    Vaccination received on:{" "}
                    {DateHelpers.formatDate(
                        singleDoseDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                </Text>
            );
        }

        if (nimsData.covidFirstDoseDate && nimsData.covidSecondDoseDate) {
            return (
                <Text variant="body">
                    {renderVaccineTypeLine(nimsData.vaccineProduct)}
                    <br />
                    1st vaccination received:{" "}
                    {DateHelpers.formatDate(
                        nimsData.covidFirstDoseDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                    <br />
                    2nd vaccination received:{" "}
                    {DateHelpers.formatDate(
                        nimsData.covidSecondDoseDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                </Text>
            );
        }

        if (nimsData.covidSecondDoseDate) {
            return (
                <Text variant="body">
                    {renderVaccineTypeLine(nimsData.vaccineProduct)}
                    <br />
                    No 1st vaccination data returned{" "}
                    <Icon
                        name="Warning"
                        theme="Fill"
                        size={3}
                        colour="orange"
                    />
                    <br />
                    2nd vaccination received:{" "}
                    {DateHelpers.formatDate(
                        nimsData.covidSecondDoseDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                    <br />
                </Text>
            );
        }

        if (nimsData.covidFirstDoseDate) {
            return (
                <Text variant="body">
                    {renderVaccineTypeLine(nimsData.vaccineProduct)}
                    <br />
                    1st vaccination received:{" "}
                    {DateHelpers.formatDate(
                        nimsData.covidFirstDoseDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                </Text>
            );
        }

        return (
            <Text variant="body">
                Patient doesn't have any recorded vaccinations
            </Text>
        );
    };

    const renderBoosterText = (nimsData: NimsData): JSX.Element | null => {
        if (nimsData.covidLatestBoosterDate) {
            return (
                <Text variant="body">
                    {renderVaccineTypeLine(nimsData.covidLatestBoosterProduct)}
                    <br />
                    Booster vaccination received:{" "}
                    {DateHelpers.formatDate(
                        nimsData.covidLatestBoosterDate,
                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                    )}
                    <br />
                </Text>
            );
        }

        return (
            <Text variant="body">
                Patient doesn't have any recorded vaccinations
            </Text>
        );
    };

    const renderNimsData = (): JSX.Element => {
        if (notInNetwork) {
            return (
                <Text variant="body">
                    Vaccination data not visible to you, as this patient has
                    been invited by an organisation outside of your network.
                    Details below.
                </Text>
            );
        }

        if (!nimsData?.lastSuccessfulFetchTime) {
            return (
                <Text variant="body">
                    Vaccination data not yet available
                    <br />
                    No data returned for this patient. Please check that their
                    NHS number is correct.
                </Text>
            );
        }

        return (
            <>
                <Text variant="body">
                    Vaccination data last checked:{" "}
                    {DateHelpers.formatDate(
                        nimsData.lastSuccessfulFetchTime,
                        DateFormatOptions.TIME_DATE_LONG_WITH_AT_DAY,
                    )}
                </Text>
                <Text variant="body">
                    <b>Primary Course</b>
                </Text>
                {renderPrimaryText(nimsData)}
                <Text variant="body">
                    <b>Boosters</b>
                </Text>
                {renderBoosterText(nimsData)}
            </>
        );
    };

    const nimsFetchedInLast12Hours: boolean =
        nimsData && nimsData.lastSuccessfulFetchTime
            ? DateHelpers.isInLastXHours(nimsData.lastSuccessfulFetchTime, 12)
            : false;

    return (
        <>
            <div className="ml-2 w-100" data-testid="vaccination-details">
                <div className="d-flex justify-content-between align-items-center mt-2">
                    {!notInNetwork && (
                        <Button
                            text="Refresh Vaccination Data"
                            onClick={handleRefreshNims}
                            theme="secondary"
                            dimension="small"
                            disabled={
                                patientDetailsLoading ||
                                nimsDataLoading ||
                                nimsFetchedInLast12Hours
                            }
                        />
                    )}
                </div>
                {nimsDataError && (
                    <Text colour="red" skinny>
                        {nimsDataError}
                    </Text>
                )}
                {(patientDetailsLoading || nimsDataLoading) && <Spinner />}
                {!patientDetailsLoading &&
                    !patientDetailsError &&
                    renderNimsData()}
            </div>
        </>
    );
};
