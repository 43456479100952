import { z } from "zod";

import { PatientExternalIdentityDtoSchema } from "./PatientExternalIdentityDtoSchema";

export const PatientDemographicsSchema = z.object({
    dateOfBirth: z.string().nullable(),
    gender: z.enum(["Unknown", "Male", "Female"]),
    contact: z.object({
        email: z.string().nullable(),
        mobileTelephone: z.string().nullable(),
        homeTelephone: z.string().nullable(),
        workTelephone: z.string().nullable(),
        address: z.string().nullable(),
    }),
    patientExternalIdentityDto: PatientExternalIdentityDtoSchema,
    patientName: z.object({
        usual: z.object({
            prefix: z.string().array(),
            given: z.string().array(),
            family: z.string().array(),
        }),
        nickname: z.object({
            prefix: z.string().array(),
            given: z.string().array(),
            family: z.string().array(),
        }),
    }),
});

export type PatientDemographics = z.infer<typeof PatientDemographicsSchema>;
