import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSavedToRecordSuccess = styled(UI.Feedback).attrs({
    colour: "success",
    iconName: "Success",
    title: "Saved to record",
})`
    padding: ${UI.Tokens.SIZES[0.5]};
`;
