import { Feature } from "../types/Feature";
import { Organisation } from "../types/Organisation";

export const isFeatureEnabledForWorkspace = (
    feature: Feature,
    workspace: Organisation,
): boolean => {
    return !!workspace.settings.features.find((f) => f.name === feature)
        ?.isEnabled;
};
