import { useEffect, useState } from "react";

import {
    ConversationManager,
    ConversationUserActions,
} from "shared/concierge/conversations/types/component.types";
import { ConversationDisplaySummary } from "shared/concierge/conversations/types/conversation.types";
import {
    ConversationGroupPaginationOptions,
    ConversationGroupRuleset,
} from "shared/concierge/conversations/types/conversationGroup.types";
import {
    ErrorStatus,
    LoadingStatus,
    PaginatedSubscription,
    Pagination,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";

export type UseConversationGroupResult = SubscriptionResult<
    ConversationDisplaySummary[]
> & {
    isRulesetValid: boolean;
    actions: ConversationUserActions;
    paginationActions?: Pagination;
    retryFetch?: () => Promise<void>;
};

/**
 * Returns conversation group data for the given ruleset, processes the initial data and responds to update events.
 */
export const useConversationGroup = (
    manager: ConversationManager,
    ruleset: ConversationGroupRuleset | null,
    options: ConversationGroupPaginationOptions,
): UseConversationGroupResult => {
    const isRulesetValid = ruleset !== null;

    const [state, setState] = useState<
        SubscriptionResult<ConversationDisplaySummary[]>
    >(isRulesetValid ? initialLoadingState : initialMissingRulesetState);
    const [groupSubscription, setGroupSubscription] = useState<
        PaginatedSubscription<ConversationDisplaySummary> | undefined
    >();

    useEffect(() => {
        if (ruleset) {
            const sub = manager.getConversationGroup(ruleset, options);

            const result = sub.feed.subscribe(setState);
            setGroupSubscription(sub);

            return () => {
                result.unsubscribe();
            };
        }
    }, [manager, ruleset, options]);

    return {
        ...state,
        actions: manager.actions,
        isRulesetValid: ruleset !== null,
        paginationActions: groupSubscription?.pagination,
        retryFetch: groupSubscription?.retryFetch,
    };
};

const initialLoadingState: LoadingStatus = {
    status: "LOADING",
    data: null,
    errorMessage: null,
};

const initialMissingRulesetState: ErrorStatus = {
    status: "ERROR",
    data: null,
    errorMessage: "Could not load the conversations you're looking for.",
};
