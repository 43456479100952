import { Tokens } from "@accurx/design";
import styled from "styled-components";

import FileUpload from "app/fileUpload/FileUploadComponent";

const StyledAddResourceButton = styled(FileUpload)`
    display: inline-block;
`;

const StyledNoResourcesWrapper = styled.div`
    text-align: center;
`;

const StyledLabelWrapper = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1]};
    justify-content: space-between;
    align-items: center;

    flex-wrap: wrap;

    margin-bottom: ${Tokens.SIZES[1.5]};
`;

export {
    StyledNoResourcesWrapper,
    StyledLabelWrapper,
    StyledAddResourceButton,
};
