import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const StyledSearchContainer = styled.div`
    margin: ${Tokens.SIZES[2]} 0;
`;

export const StyledLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${Tokens.SIZES[1.5]};

    margin-bottom: ${Tokens.SIZES[2]};
    margin-top: 12px;
`;

export const StyledContainer = styled.div`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};

    background-color: ${Tokens.COLOURS.greyscale.white};

    flex: 1;
`;

export const StyledSectionHeading = styled(Text)`
    margin: 0 0 ${Tokens.SIZES[1]} 0;
`;

export const StyledFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${Tokens.SIZES[1]};
`;
