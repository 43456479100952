import React from "react";

import { Thread } from "@accurx/design";

import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import {
    ConversationItem,
    PatientMatchState,
} from "shared/concierge/conversations/types/item.types";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";

import { useFirstUnreadItemId } from "../Conversation.hooks";
import { PatientType } from "../Conversation.types";
import { groupConversationItemsByDate } from "./ConversationThread.utils";
import { EndOfConversationItem, NewConversationItem } from "./Items";
import { ConversationThreadItem } from "./Items/ConversationThreadItem";

export type ConversationThreadProps = {
    conversationItems: ConversationItem[];
    isEndOfConversation: boolean;
    patient: PatientType;
    markItemAsRead: (itemId: string) => void;
    matchType: PatientMatchState | null;
    currentUserId: string;
};

const ConversationThreadComponent = ({
    conversationItems = [],
    isEndOfConversation,
    patient,
    markItemAsRead,
    matchType,
    currentUserId,
}: ConversationThreadProps) => {
    const organisationId = useWorkspaceId();

    const patientFullName = formatPatientDisplayName({
        firstName: patient?.firstName || null,
        familyName: patient?.familyName || null,
    });

    const firstUnreadItemId = useFirstUnreadItemId({
        currentUserId: currentUserId,
        items: conversationItems,
    });

    const hasNotMatchedPatient =
        matchType === "NoMatch" || matchType === "Suggested";

    const conversationItemsByDate =
        groupConversationItemsByDate(conversationItems);
    const conversationItemsByDateAsArray = Array.from(conversationItemsByDate);

    return (
        <Thread>
            <Thread.Body>
                {conversationItemsByDateAsArray.length === 0 ? (
                    <NewConversationItem patientName={patientFullName} />
                ) : (
                    conversationItemsByDateAsArray.map(([date, items]) => {
                        return (
                            <Thread.List
                                key={date}
                                header={date}
                                aria-label={`Messages from ${date}`}
                            >
                                {items.map((item) => {
                                    return (
                                        <Thread.Item key={item.id}>
                                            {item.id === firstUnreadItemId && (
                                                <React.Fragment
                                                    key={`new-messages-badge-${item.id}`}
                                                >
                                                    <Thread.ScrollAnchor />
                                                    <Thread.UnreadBadge text="New messages" />
                                                </React.Fragment>
                                            )}
                                            <ConversationThreadItem
                                                item={item}
                                                allItems={conversationItems}
                                                patientId={patient?.patientId}
                                                organisationId={organisationId}
                                                patientFullName={
                                                    patientFullName
                                                }
                                                markItemAsRead={markItemAsRead}
                                                currentUserId={currentUserId}
                                                matchType={matchType}
                                            />
                                        </Thread.Item>
                                    );
                                })}
                            </Thread.List>
                        );
                    })
                )}
                {isEndOfConversation && (
                    <EndOfConversationItem
                        hasNotMatchedPatient={hasNotMatchedPatient}
                        patientName={patientFullName}
                        key={"end-of-conversation-item"}
                    />
                )}
                {!firstUnreadItemId && <Thread.ScrollAnchor />}
            </Thread.Body>
        </Thread>
    );
};

export const ConversationThread = React.memo(ConversationThreadComponent);
