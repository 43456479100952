import {
    MessageTemplate,
    QuestionnaireTemplate,
} from "@accurx/message-templates";
import { TemplateItem } from "domains/compose/types";

export const combineMessageAndQuestionnaireTemplates = ({
    messageTemplates = [],
    questionnaireTemplates = [],
}: {
    messageTemplates?: MessageTemplate[];
    questionnaireTemplates?: QuestionnaireTemplate[];
}): TemplateItem[] => {
    return [
        ...questionnaireTemplates.map<TemplateItem>((template) => ({
            type: "QuestionnaireTemplate",
            value: template,
        })),
        ...messageTemplates.map<TemplateItem>((template) => ({
            type: "MessageTemplate",
            value: template,
        })),
    ];
};
