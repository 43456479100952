import { IWrappedResult } from "@accurx/shared";
import {
    UseMutationOptions,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";

import {
    CombinedMutationContext,
    MutationHook,
    MutationHookDef,
    PassthroughUseMutationOptions,
    PassthroughUseQueryOptions,
    QueryHook,
    QueryHookDef,
    QueryKey,
} from "./QueryClient.types";

/**
 * Out HTTP Client always returns a successful data payload - even when
 * the HTTP request failed.
 *
 * This utility reverses that process and rethrows any error so that
 * React Query can catch errors and handle them.
 *
 * @param response the wrapped response that HttpClient provides
 * @returns the successful data inside the wrapped response
 */
export const returnDataOrThrow = <T>(response: IWrappedResult<T>): T => {
    if (response.success === true && response.result !== null) {
        return response.result;
    }

    throw new Error(response.error ?? "Failed to fetch");
};

/**
 * createQueryHook wraps up useQuery and allows you to create query hooks
 * without doing so much manual typing.
 *
 * @example
 * // Hook creation
 * const useUserById = createQueryHook<
 *   number, // the hook will take a number as an argument
 *   User, // the API will return a User object
 * >((userId) => {
 *   queryKey: ["user", userId],
 *   queryFn: () => UserAPI.fetchUser(userId)
 * });
 *
 * // Hook usage (simple)
 * const userQuery = useUserById(44);
 *
 * // Hook usage with extra options
 * const usernameQuery = useUserById<UserName>(44, {
 *   select: (data) => data.name,
 *   refetchInterval: 30000
 * });
 */
export function createQueryHook<TArg, TData>(
    def: QueryHookDef<TArg, TData>,
): QueryHook<TArg, TData> {
    return <TResult>(
        arg: TArg,
        options?: PassthroughUseQueryOptions<TData, TResult>,
    ) => {
        const preBuiltOptions = def<TResult>(arg);

        return useQuery<TData, Error, TResult, QueryKey>({
            ...options,
            ...preBuiltOptions,
        });
    };
}

export function createMutationHook<
    TVariables,
    TData = unknown,
    TBaseContext = unknown,
>(
    def: MutationHookDef<TVariables, TData, TBaseContext>,
): MutationHook<TVariables, TData, TBaseContext> {
    return <TContext>(
        options?: PassthroughUseMutationOptions<
            TData,
            TVariables,
            TBaseContext,
            TContext
        >,
    ) => {
        const queryClient = useQueryClient();
        const preBuiltOptions = def({ queryClient });

        const onMutate: UseMutationOptions<
            TData,
            Error,
            TVariables,
            CombinedMutationContext<TBaseContext, TContext>
        >["onMutate"] = async (variables) => {
            let baseContext: TBaseContext | undefined;
            let context: TContext | undefined;

            if (preBuiltOptions.onMutate) {
                baseContext = await preBuiltOptions.onMutate(variables);
            }

            if (options?.onMutate) {
                context = await options.onMutate(variables);
            }

            return { baseContext, context };
        };

        const onSuccess: UseMutationOptions<
            TData,
            Error,
            TVariables,
            CombinedMutationContext<TBaseContext, TContext>
        >["onSuccess"] = (data, variables, context) => {
            preBuiltOptions.onSuccess &&
                preBuiltOptions.onSuccess(
                    data,
                    variables,
                    context?.baseContext,
                );
            options?.onSuccess && options.onSuccess(data, variables, context);
        };

        const onError: UseMutationOptions<
            TData,
            Error,
            TVariables,
            CombinedMutationContext<TBaseContext, TContext>
        >["onError"] = (error, variables, context) => {
            preBuiltOptions.onError &&
                preBuiltOptions.onError(error, variables, context?.baseContext);
            options?.onError && options.onError(error, variables, context);
        };

        const onSettled: UseMutationOptions<
            TData,
            Error,
            TVariables,
            CombinedMutationContext<TBaseContext, TContext>
        >["onSettled"] = (data, error, variables, context) => {
            preBuiltOptions.onSettled &&
                preBuiltOptions.onSettled(
                    data,
                    error,
                    variables,
                    context?.baseContext,
                );
            options?.onSettled &&
                options.onSettled(data, error, variables, context);
        };

        return useMutation<
            TData,
            Error,
            TVariables,
            CombinedMutationContext<TBaseContext, TContext>
        >({
            ...preBuiltOptions,
            ...options,
            onMutate,
            onSuccess,
            onError,
            onSettled,
        });
    };
}
