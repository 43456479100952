import { FormEvent, useReducer, useState } from "react";

import {
    RegisterAccountProductType,
    RegisterRequest,
} from "@accurx/api/account";
import { Feedback, Flex, FormFieldWrapper, Input, Text } from "@accurx/design";

import { postRegister } from "api/AccountApi";
import { StyledFullWidthButton } from "app/account/SharedStyledComponents.styles";
import { useGetUtmParams } from "app/account/hooks";
import { TermsAndConditionsCheckbox } from "app/sharedComponents/termsAndConditionsCheckbox/TermsAndConditionsCheckbox";
import {
    getConfirmPasswordError,
    getEmailError,
    getFullNameError,
    getPasswordError,
    getTermsAndConditionsError,
} from "shared/Form.helper";

import { getRegisterError } from "../Register.helper";

const getFormFieldErrors = (errorMessage?: string) =>
    errorMessage ? [errorMessage] : [];

type StringFormFields = "fullName" | "email" | "password" | "confirmPassword";
type CheckedFormFields = "termsAndConditions";
type AllFormFields = StringFormFields | CheckedFormFields;

type FormFieldData = Record<StringFormFields, string> &
    Record<CheckedFormFields, boolean>;
type RegisterFormStateType = FormFieldData & {
    formErrors: Partial<Record<AllFormFields, string>>;
};
const initialFormValues: RegisterFormStateType = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAndConditions: false,
    formErrors: {},
};

type SetFieldAction = {
    type: "SET_FIELD";
    payload: { field: StringFormFields; value: string };
};

type SetCheckedFieldAction = {
    type: "SET_CHECKED_FIELD";
    payload: { field: CheckedFormFields; value: boolean };
};

type SetFieldErrorAction = {
    type: "SET_FIELD_ERROR";
    payload: { field: AllFormFields; value: string };
};

type FormActions = SetFieldAction | SetCheckedFieldAction | SetFieldErrorAction;

const registerFormReducer = (
    state: RegisterFormStateType,
    action: FormActions,
) => {
    switch (action.type) {
        case "SET_FIELD":
            // Clear error after setting
            const errors = { ...state.formErrors };
            errors[action.payload.field] = "";

            return {
                ...state,
                [action.payload.field]: action.payload.value,
                formErrors: errors,
            };
        case "SET_CHECKED_FIELD":
            const { field, value } = action.payload;
            const errorsCopy = { ...state.formErrors };
            errorsCopy[field] = "";
            return {
                ...state,
                [field]: value,
                formErrors: errorsCopy,
            };
        case "SET_FIELD_ERROR":
            return {
                ...state,
                formErrors: {
                    ...state.formErrors,
                    [action.payload.field]: action.payload.value,
                },
            };
        default:
            return state;
    }
};

type RegisterFormProps = {
    /** Required when submitting form as part of the payload */
    product: RegisterAccountProductType;
    /** What to do after form has submitted successfully */
    onRegisterSuccess: (registeredEmail: string) => void;
    /** What to do after form has failed due to active directory email */
    onActiveDirectoryError?: (orgExternalId: string) => void;
};

export const RegisterForm = ({
    product,
    onRegisterSuccess,
    onActiveDirectoryError,
}: RegisterFormProps) => {
    const utmParams = useGetUtmParams();
    const [form, dispatch] = useReducer(registerFormReducer, initialFormValues);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [formServerError, setFormServerError] = useState("");

    /** Handles changing field value */
    const setField = (field: StringFormFields, value: string) => {
        dispatch({
            type: "SET_FIELD",
            payload: { field, value },
        });

        if (formServerError) {
            setFormServerError("");
        }
    };
    /** Handles changing the checked fields */
    const setCheckedField = (field: CheckedFormFields, value: boolean) => {
        dispatch({
            type: "SET_CHECKED_FIELD",
            payload: { field, value: !!value },
        });
        if (formServerError) {
            setFormServerError("");
        }
    };

    /* Validation */
    const isFieldValid = (
        field: AllFormFields,
        getFieldError: () => string,
    ) => {
        const error = getFieldError();
        if (error) {
            dispatch({
                type: "SET_FIELD_ERROR",
                payload: { field: field, value: error },
            });
            return false;
        }

        return true;
    };

    const isTermsAndConditionsValid = (): boolean =>
        isFieldValid("termsAndConditions", () =>
            getTermsAndConditionsError(form.termsAndConditions),
        );
    const isFullNameValid = (): boolean =>
        isFieldValid("fullName", () => getFullNameError(form.fullName));

    const isEmailValid = (): boolean =>
        isFieldValid("email", () => getEmailError(form.email));

    const isPasswordValid = (): boolean =>
        isFieldValid("password", () => getPasswordError(form.password));

    const isConfirmPasswordValid = (): boolean =>
        isFieldValid("confirmPassword", () =>
            getConfirmPasswordError({
                password: form.password,
                confirmPassword: form.confirmPassword,
            }),
        );

    const validateForm = (): boolean => {
        const fullNameValid = isFullNameValid();
        const emailValid = isEmailValid();
        const passwordValid = isPasswordValid();
        const confirmPasswordValid = isConfirmPasswordValid();
        const termsAndConditionsValid = isTermsAndConditionsValid();
        return (
            fullNameValid &&
            emailValid &&
            passwordValid &&
            confirmPasswordValid &&
            termsAndConditionsValid
        );
    };

    const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsFormSubmitting(true);

        const params: RegisterRequest = {
            name: form.fullName,
            email: form.email,
            confirmEmail: form.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
            hasAcceptedTermsService: form.termsAndConditions === true,
            referralSource: undefined,
            product,
        };
        if (utmParams) {
            params.utmParameters = utmParams;
        }

        const res = await postRegister(params);
        setIsFormSubmitting(false);

        if (res.result?.emailSent) {
            // Submission successful
            onRegisterSuccess(form.email);
        } else if (
            res.result?.organisationExternalId &&
            onActiveDirectoryError
        ) {
            onActiveDirectoryError(res.result.organisationExternalId);
        } else {
            // Submission failed
            const registerError = getRegisterError(res.result?.failureType);

            // Show a generic error message if:
            // - There was a non-200 API response
            // - No verificaiton email was sent and no failure information was returned
            if (!res.success || !registerError) {
                setFormServerError(
                    res.error ||
                        "Something went wrong creating your account. Please try again.",
                );
                return;
            }

            setFormServerError(registerError);
        }
    };

    return (
        <form onSubmit={handleRegister}>
            <Flex gap="2" flexDirection="column">
                <FormFieldWrapper
                    label="Full name"
                    labelProps={{
                        htmlFor: "name",
                        skinny: true,
                    }}
                    errors={getFormFieldErrors(form.formErrors.fullName)}
                >
                    <Input
                        id="name"
                        value={form.fullName}
                        type="text"
                        onChange={(e): void =>
                            setField("fullName", e.target.value)
                        }
                        onBlur={isFullNameValid}
                        autoComplete="name"
                        hasErrors={!!form.formErrors.fullName}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="NHS email"
                    labelProps={{
                        htmlFor: "email",
                        skinny: true,
                    }}
                    errors={getFormFieldErrors(form.formErrors.email)}
                >
                    <Input
                        id="email"
                        value={form.email}
                        type="email"
                        onChange={(e): void =>
                            setField("email", e.target.value)
                        }
                        onBlur={isEmailValid}
                        autoComplete="email"
                        hasErrors={!!form.formErrors.email}
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="Password"
                    labelProps={{
                        htmlFor: "password",
                        skinny: true,
                    }}
                    errors={getFormFieldErrors(form.formErrors.password)}
                >
                    <Input
                        id="password"
                        value={form.password}
                        type="password"
                        onChange={(e): void =>
                            setField("password", e.target.value)
                        }
                        onBlur={isPasswordValid}
                        autoComplete="new-password"
                        hasErrors={!!form.formErrors.password}
                        aria-describedby="password-rules"
                    />
                </FormFieldWrapper>
                <FormFieldWrapper
                    label="Confirm password"
                    labelProps={{
                        htmlFor: "confirmPassword",
                        skinny: true,
                    }}
                    errors={getFormFieldErrors(form.formErrors.confirmPassword)}
                >
                    <Input
                        id="confirmPassword"
                        value={form.confirmPassword}
                        type="password"
                        onChange={(e): void =>
                            setField("confirmPassword", e.target.value)
                        }
                        onBlur={isConfirmPasswordValid}
                        autoComplete="new-password"
                        hasErrors={!!form.formErrors.confirmPassword}
                    />
                </FormFieldWrapper>
                <Text skinny props={{ id: "password-rules" }}>
                    Passwords must be at least 8 characters long and include a
                    number, lower and upper case letters.
                </Text>

                <FormFieldWrapper
                    errors={getFormFieldErrors(
                        form.formErrors.termsAndConditions,
                    )}
                >
                    <TermsAndConditionsCheckbox
                        checked={form.termsAndConditions}
                        onCheckChange={(checked) =>
                            setCheckedField(
                                "termsAndConditions",
                                checked === true,
                            )
                        }
                    />
                </FormFieldWrapper>
                {!!formServerError && (
                    <Feedback colour="error" title={formServerError} />
                )}
                <StyledFullWidthButton
                    type="submit"
                    text="Create an account"
                    disabled={isFormSubmitting}
                />
            </Flex>
        </form>
    );
};
