import React, { useState } from "react";

import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItem,
    PopoverTrigger,
} from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { generatePath, useHistory } from "react-router";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { TabLookup } from "app/organisations/settings/SettingsPage.constants";
import { ROUTES_ORGS } from "shared/Routes";
import { getInitials } from "shared/formatters/UserName";
import { useIsMobile, useIsSmallMobile } from "shared/useIsMobile";
import { useAppSelector } from "store/hooks";

import { NavButton, NavButtonIcon, NavMenuDivider } from "../../Navbar.styles";
import { useLogoutChain } from "./AccountDropdown.hooks";

export const AccountDropdown = (): JSX.Element => {
    const user = useAppSelector(({ account }) => account.user);
    const [open, setOpen] = useState(false);
    const isSmallMobile = useIsSmallMobile();
    const isMobile = useIsMobile();
    const history = useHistory();
    const logoOut = useLogoutChain();
    const org = useCurrentOrganisation();

    const navigateToSettings = () => {
        history.push(
            generatePath(ROUTES_ORGS.settings, {
                orgId: org?.orgId,
                tab: TabLookup.profile.tabId,
            }),
        );
        setOpen(false);
    };

    const openHelp = () => {
        window.open(SupportUrls.Home, "_blank");
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <NavButton aria-label={`${user?.fullName ?? ""} account menu`}>
                    {isMobile ? getInitials(user?.fullName) : user?.fullName}
                    {!isSmallMobile && (
                        <NavButtonIcon>
                            <Icon
                                colour="white"
                                name="Arrow"
                                rotation="down"
                                size={3}
                            />
                        </NavButtonIcon>
                    )}
                </NavButton>
            </PopoverTrigger>
            <PopoverContent align="end">
                {user && (
                    <PopoverItem>
                        <Icon name="Person" size={3} /> {user.fullName}
                    </PopoverItem>
                )}
                <NavMenuDivider />
                <PopoverItem onClick={navigateToSettings}>Settings</PopoverItem>
                <PopoverItem onClick={openHelp}>
                    Help{" "}
                    <Icon
                        name="OpenWindow"
                        size={3}
                        title="Opens in new window"
                    />
                </PopoverItem>
                <PopoverItem onClick={logoOut}>Log out</PopoverItem>
            </PopoverContent>
        </Popover>
    );
};
