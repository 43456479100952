import { useCallback } from "react";

import { FeatureName, useCurrentWorkspace, useFeatureFlag } from "@accurx/auth";
import { generatePath, useHistory } from "react-router";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES } from "shared/Routes";

export const useNewConversationNavigation = () => {
    const history = useHistory();
    const { orgId: workspaceId } = useCurrentWorkspace();
    const canCreateNewConversation = useFeatureFlag(
        FeatureName.CaregiverInitiated,
    );
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const trackNewMessageButtonClick = useCallback(
        ({ fromEmptyConversations }: { fromEmptyConversations: boolean }) =>
            FlemingAnalyticsTracker.trackClinicianConversationsNewMessageButtonClick(
                { ...analyticsLoggedInProps, fromEmptyConversations },
            ),
        [analyticsLoggedInProps],
    );

    return {
        canCreateNewConversation,
        navigateToNewConversation: ({
            fromEmptyConversations,
        }: {
            fromEmptyConversations: boolean;
        }) => {
            history.push(
                generatePath(
                    ROUTES.workspaceClinicianConversationSearchPatient,
                    { workspaceId },
                ),
                {
                    backTo: history.location.pathname,
                },
            );
            trackNewMessageButtonClick({ fromEmptyConversations });
        },
    };
};
