import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

const StyledFooter = styled(ModalFooter)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

type DeleteReminderModalProps = {
    isOpen: boolean;
    areButtonsDisabled: boolean;
    onCancel(): void;
    onDelete(): void;
};

export function DeleteReminderModal({
    isOpen,
    onCancel,
    onDelete,
    areButtonsDisabled,
}: DeleteReminderModalProps) {
    return (
        <Modal isOpen={isOpen} backdrop={true} fade={false} centered>
            <ModalHeader>
                <Text skinny variant="subtitle">
                    Are you sure you want to delete this appointment reminder?
                </Text>
            </ModalHeader>
            <ModalBody className="bg-light">
                <Text skinny props={{ style: { wordWrap: "break-word" } }}>
                    Deleting this reminder means that any patients who were
                    going to receive this message in the next 24 hours before
                    their scheduled appointment will no longer receive it.
                </Text>
            </ModalBody>
            <StyledFooter>
                <Button
                    text="Cancel"
                    theme="secondary"
                    onClick={onCancel}
                    disabled={areButtonsDisabled}
                />
                <Button
                    text="Delete this reminder"
                    theme="danger"
                    onClick={onDelete}
                    disabled={areButtonsDisabled}
                />
            </StyledFooter>
        </Modal>
    );
}
