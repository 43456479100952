import React, { MouseEvent } from "react";

import { Feedback, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import {
    PatientListAppointment,
    PatientListPatientDetails,
} from "api/FlemingDtos";

import { getPatientFromPatientId } from "../PatientList.helper";

type PatientListBulkUploadDuplicatesProps = {
    currentListPatientDuplicates: PatientListAppointment[];
    currentListPatientDetails: PatientListPatientDetails;
    onRemoveFromDuplicates: (
        e: MouseEvent,
        appointment: PatientListAppointment,
    ) => void;
};

export const PatientListBulkUploadDuplicates = ({
    currentListPatientDuplicates,
    currentListPatientDetails,
    onRemoveFromDuplicates,
}: PatientListBulkUploadDuplicatesProps): JSX.Element => (
    <ul className="list-unstyled mb-3">
        {currentListPatientDuplicates.map((apt) => {
            const patient = getPatientFromPatientId(
                currentListPatientDetails,
                apt.patientId,
            );

            if (!patient) return null;

            const addedDate = DateHelpers.formatDate(
                apt.dateAdded,
                DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
            );
            return (
                <li key={apt.id} className="mt-3">
                    <Feedback
                        colour="warning"
                        title={`${patient.displayName} is already in your list`}
                    >
                        <Text as="span">
                            {`This patient was added on ${addedDate}. `}
                            <Text
                                variant="link"
                                as="a"
                                colour="zinc"
                                props={{
                                    href: "#",
                                    onClick: (e: MouseEvent): void =>
                                        onRemoveFromDuplicates(e, apt),
                                }}
                            >
                                Ok, got it
                            </Text>
                        </Text>
                    </Feedback>
                </li>
            );
        })}
    </ul>
);
