import React, { useState } from "react";

import { Button, Text } from "@accurx/design";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

import { usePracticeUsersContext } from "./PracticeUsers.context";
import {
    OrgUserApprovalStatus,
    OrgUserApprovalStatusConsequence,
    OrgUserRole,
    OrgUserRoleLabel,
    UserTransitions,
    UserType,
} from "./PracticeUsers.types";

const getTransitions = (
    user: UserType,
    changeRole: (userId: number, role: OrgUserRole, name: string) => void,
    changeStatus: (
        userId: number,
        status: OrgUserApprovalStatus,
        name: string,
    ) => void,
): UserTransitions[] => {
    const roleActions: UserTransitions[] =
        user.userRoleState?.allowedChanges.map((role: OrgUserRole) => {
            return {
                title: `Make ${OrgUserRoleLabel(role)}`,
                role,
                user,
                action: () =>
                    changeRole(user.userId, role, user.name || user.email),
            };
        }) || [];
    const stateActions: UserTransitions[] =
        user.userStatusState?.allowedChanges.map(
            (status: OrgUserApprovalStatus) => {
                return {
                    title: OrgUserApprovalStatusConsequence(
                        status,
                        user.userStatusState?.currentValue,
                    ),
                    status,
                    user,
                    action: () =>
                        changeStatus(
                            user.userId,
                            status,
                            user.name || user.email,
                        ),
                };
            },
        ) || [];
    return [...roleActions, ...stateActions];
};

type UserTypeProps = {
    user: UserType;
};

export const UserRowActions = ({ user }: UserTypeProps): JSX.Element | null => {
    const {
        changeRole,
        changeStatus,
        hasApprovalRights,
        showModalNotification,
    } = usePracticeUsersContext();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const transitions = getTransitions(user, changeRole, changeStatus);

    if (hasApprovalRights === false || transitions.length === 0) {
        return null;
    }

    return (
        <Dropdown
            isOpen={isDropdownOpen}
            toggle={() => setIsDropdownOpen((prevState) => !prevState)}
        >
            <DropdownToggle tag="div" aria-expanded={isDropdownOpen}>
                <Button
                    theme="secondary"
                    icon={{
                        name: "More",
                        style: "Fill",
                        title: "Toggle dropdown",
                        id: "toggle-dropdown-btn",
                    }}
                />
            </DropdownToggle>
            <DropdownMenu right={true}>
                {transitions.map((item: UserTransitions) => (
                    <DropdownItem
                        key={item.title}
                        tag="div"
                        className="d-flex p-1 cursor-pointer"
                        onClick={() => showModalNotification(item)}
                    >
                        <Text variant="label" as="span" colour="red" skinny>
                            {item.title}
                        </Text>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
