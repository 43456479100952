/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Icon } from "@accurx/design";

import { PatientMatchState } from "shared/concierge/conversations/types/item.types";

import {
    StyledButtonContainer,
    StyledContainer,
    StyledIconBlock,
    StyledSearchButton,
    StyledSearchInfo,
    StyledText,
} from "./PatientMatchHeader.styles";

export const SEARCH_TEXT_OPEN_NO_MATCH =
    "Search for the correct patient by NHS number and DoB to respond to this message.";

export const SEARCH_TEXT_OPEN_SUGGESTED =
    "Confirm patient’s identity to respond to this message.";

export const SEARCH_TEXT_DONE =
    "Re-open the conversation to search for and/or match the patient.";

const MATCH_TEXT = {
    NoMatch: SEARCH_TEXT_OPEN_NO_MATCH,
    Suggested: SEARCH_TEXT_OPEN_SUGGESTED,
    Done: SEARCH_TEXT_DONE,
};

export type PatientMatchHeaderProps = {
    onConfirmPatientIdentity: () => void;
    onSearchForPatient: () => void;
    conversationIsDone: boolean;
    matchType: PatientMatchState | null;
    showActionButtons: boolean;
};

export const PatientMatchHeader = ({
    onConfirmPatientIdentity,
    onSearchForPatient,
    conversationIsDone,
    matchType,
    showActionButtons,
}: PatientMatchHeaderProps) => {
    const getPatientMatchText = (): string => {
        if (conversationIsDone) {
            return MATCH_TEXT.Done;
        }
        return matchType === "NoMatch"
            ? MATCH_TEXT.NoMatch
            : MATCH_TEXT.Suggested;
    };
    return (
        <StyledContainer>
            <StyledSearchInfo>
                <StyledIconBlock>
                    <Icon
                        name="Warning"
                        colour="orange"
                        size={3}
                        theme="Fill"
                    />
                </StyledIconBlock>
                <StyledText variant="label" skinny>
                    {getPatientMatchText()}
                </StyledText>
            </StyledSearchInfo>
            {!conversationIsDone &&
                matchType === "NoMatch" &&
                showActionButtons && (
                    <StyledSearchButton
                        onClick={onSearchForPatient}
                        text="Search for patient"
                        theme="primary"
                    />
                )}
            {!conversationIsDone &&
                matchType === "Suggested" &&
                showActionButtons && (
                    <StyledButtonContainer>
                        <StyledSearchButton
                            onClick={onConfirmPatientIdentity}
                            text="Confirm patient identity"
                            theme="primary"
                        />
                        <StyledSearchButton
                            onClick={onSearchForPatient}
                            text="Match to a different patient"
                            theme="secondary"
                        />
                    </StyledButtonContainer>
                )}
        </StyledContainer>
    );
};
