import { Option } from "@accurx/design";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";

export function getLocationFilterOptions(
    clinics: IntegratedClinic[],
): Option[] {
    const locations = new Set<string>();

    clinics.forEach(({ location }) => {
        if (location) {
            locations.add(location);
        }
    });

    const sortedValues = [...locations].sort();
    const options = sortedValues.map((value) => ({ value, label: value }));

    return options;
}
