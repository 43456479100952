import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

export type LogOutMutationVariables = {
    /**
     * What we want to do before refreshing the session
     *
     * This would be contextual to where the logout
     * function is being called
     */
    cleanUpFn: () => void;
    redirectTo?: string;
};

export const useLogOutMutation = (
    options: Omit<
        UseMutationOptions<void, Error, LogOutMutationVariables>,
        "onSuccess"
    > = {},
) => {
    return useMutation({
        mutationKey: ["logout"],
        ...options,
        mutationFn: async ({ cleanUpFn, redirectTo = "/login" }) => {
            const response = await httpClient.postSafeAsync(
                getApiEndpoint({ path: "/api/Account/Logout" }),
            );

            if (response.success) {
                cleanUpFn();
                window.location.href = redirectTo;
            } else {
                throw new Error(
                    "Oops! We're sorry, but we couldn't log you out.",
                );
            }
        },
    });
};
