import React from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

import { Button, ButtonProps } from "../Button/Button";

const { COLOURS, SIZES } = Tokens;

export const StyledSecondaryButton = styled(Button)`
    span {
        color: ${COLOURS.greyscale.zinc};
    }
    background-color: ${COLOURS.greyscale.white};
    padding: ${SIZES[1]};
    line-height: 24px;
    height: ${(props) => (props.dimension === "small" ? "32px" : "40px")};
`;

export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, ...otherProps }, ref) => {
        return (
            <StyledSecondaryButton ref={ref} {...otherProps}>
                {children}
            </StyledSecondaryButton>
        );
    },
);
