import { useCurrentWorkspace } from "@accurx/auth";
import { AppSettings } from "@accurx/shared";

export const usePatientTriageUrl = () => {
    const workspaceNationalCode = useCurrentWorkspace().nationalCode;

    if (!workspaceNationalCode) {
        return null;
    }

    if (
        AppSettings.accurxEnvironment === "local" ||
        AppSettings.accurxEnvironment === "development"
    ) {
        return `https://dev.accurx.nhs.uk/p/${workspaceNationalCode}`;
    }

    if (AppSettings.accurxEnvironment === "staging") {
        return `https://demo.accurx.nhs.uk/p/${workspaceNationalCode}`;
    }

    return `https://accurx.nhs.uk/p/${workspaceNationalCode}`;
};
