import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export type MutateAcknowledgeMedicalRecordUserChangePayload = {
    logout: boolean;
};

export const mutateAcknowledgeMedicalRecordUserChange = createEndpoint<
    unknown,
    MutateAcknowledgeMedicalRecordUserChangePayload
>("MutateAcknowledgeMedicalRecordUserChange", async (context, payload) => {
    await NativeApi.request({
        transport: context.transport,
        request: {
            type: "MutateAcknowledgeMedicalRecordUserChange",
            workspaceId: context.workspaceId,
            payload: payload,
        },
    });
});
