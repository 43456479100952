import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Checkbox } from "@accurx/inbox-design-library";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";

import { SAVE_TO_RECORD_CHECKBOX_ID } from "./SaveToRecordCheckbox.constants";

export const SaveToRecordCheckbox = () => {
    const { state, dispatch } = useCompose();

    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const saveToRecordCheckboxOnChange = () => {
        track("PatientMessageSaveToRecord Option Select", {
            ...nativeTrackingFields,
            oldValue: state.isSaveToRecordEnabled,
            newValue: !state.isSaveToRecordEnabled,
        });
        dispatch({
            type: "UPDATE_IS_SAVE_TO_RECORD_ENABLED",
            payload: {
                isSaveToRecordEnabled: !state.isSaveToRecordEnabled,
            },
        });
    };

    return (
        <Checkbox
            id={SAVE_TO_RECORD_CHECKBOX_ID}
            checked={state.isSaveToRecordEnabled}
            onCheckChange={saveToRecordCheckboxOnChange}
            text="Save message to record"
        >
            <UI.Text skinny as="span" variant="preview">
                Save
            </UI.Text>
        </Checkbox>
    );
};
