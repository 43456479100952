import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import {
    SelectedItem,
    trackOnContactDropdownOpen,
    trackOnContactDropdownOptionClick,
} from "../patientListContactDropdownEvents";

export function useTrackOnContactDropdownOpen() {
    const loggedInProps = useFlemingLoggedInAnalytics();

    return () => {
        trackOnContactDropdownOpen({
            ...loggedInProps,
            productOrigin: "Clinic",
        });
    };
}

export function useTrackOnContactDropdownOptionClick() {
    const loggedInProps = useFlemingLoggedInAnalytics();

    return ({ selectedItem }: { selectedItem: SelectedItem }) => {
        trackOnContactDropdownOptionClick({
            ...loggedInProps,
            productOrigin: "Clinic",
            selectedItem,
        });
    };
}
