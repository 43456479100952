import { Tokens } from "@accurx/design";
import { applyFocusStyle } from "domains/navigation/components/Sidenav/Sidenav.styles";
import { Level2AccordionHeaderHeight } from "domains/navigation/styles/accordionHeight";
import styled, { css } from "styled-components";

export const StyledContainer = styled.div<{
    $isActive: boolean;
}>`
    display: flex;
    flex-direction: row-reverse;
    border-radius: 8px;
    border: 1px solid transparent;

    // For the accordion behaviour we need to set a specific height for the
    // accordion header elements. It's very important that this header item fits
    // exactly within the fixed level 2 accordion header height. We take 8px
    // from that value to account for the 4px focus ring above and below.
    height: calc(${Level2AccordionHeaderHeight} - 8px);
    max-height: calc(${Level2AccordionHeaderHeight} - 8px);

    &:has(a:focus) {
        ${applyFocusStyle};
    }

    &:hover {
        background-color: ${Tokens.COLOURS.primary.green[130]};
        border: 1px solid ${Tokens.COLOURS.primary.green[100]};
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            border: 1px solid ${Tokens.COLOURS.primary.green[50]} !important;
            background: ${Tokens.COLOURS.primary.green[100]} !important;
        `}
`;

export const StyledLink = styled.a`
    flex: 1;
    font-size: 1rem;
    text-decoration: none !important;
    color: ${Tokens.COLOURS.greyscale.white} !important;

    border-top-right-radius: 7px; // 1px less to account for border
    border-bottom-right-radius: 7px;
    display: flex;
    gap: ${Tokens.SIZES[1]};
    padding: 0 ${Tokens.SIZES[1]} 0 ${Tokens.SIZES[0.5]};
    align-items: center;

    &:not([tabindex="-1"]):focus:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const StyledToggleButton = styled.button`
    appearance: none;
    padding: 8px 4px;
    margin: 0;
    background: none;
    border: none;
    border-top-left-radius: 7px; // 1px less to account for border
    border-bottom-left-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${Tokens.COLOURS.primary.green[75]};
    }

    &:not([tabindex="-1"]):focus:focus {
        ${applyFocusStyle};
    }
`;
