import { ReactNode } from "react";

import { Icon, StackPanel, Text } from "@accurx/design";
import { IconProps } from "@accurx/design/dist/components/Icon/Icon";

import { OverflowStackPanel } from "./conversationSidePanelSection.styles";

type ClinicianConversationSidePanelSectionProps = {
    headerText: string;
    headerIconProps: IconProps;
    hideOverflow?: boolean;
    children: ReactNode;
};

export const ClinicianConversationSidePanelSection = ({
    children,
    headerText,
    hideOverflow,
    headerIconProps,
}: ClinicianConversationSidePanelSectionProps): JSX.Element => {
    return (
        <OverflowStackPanel gutter={1} $hideOverflow={hideOverflow}>
            <StackPanel
                verticalContentAlignment="center"
                orientation="horizontal"
                gutter={1}
            >
                <Icon
                    {...headerIconProps}
                    size={4}
                    halo={{
                        colour: "greyscale",
                        luminosity: "low",
                    }}
                />
                <Text skinny as="h2" variant="label">
                    {headerText}
                </Text>
            </StackPanel>
            {children}
        </OverflowStackPanel>
    );
};
