import { Fragment } from "react";

import {
    Option,
    QuestionTemplateType,
    QuestionType,
} from "@accurx/api/florey-builder";
import { Ds, Flex, Icon, Text } from "@accurx/design";
import ReactMarkdown from "react-markdown";

import { useQuestionFeatures } from "../FloreyBuilder.hooks";
import { floreyPlainTextToMarkdownLink } from "../FloreyBuilder.utils";
import { ClientSideQuestion } from "../types/context.types";
import { StyledGreyIndent, StyledMarkdown } from "./PageContainer.styles";
import {
    CheckboxSVG,
    RadioButtonSVG,
    StyledBulletIconPreview,
    StyledBulletText,
    StyledBulletWrapper,
} from "./Question.styles";
import {
    FreeTextInformation,
    MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS,
    MultipleChoiceManyAnswersRecommendedLimit,
} from "./QuestionFeedbacks";
import { QuestionImagePreview } from "./QuestionImageUploader/QuestionImageUploader";

const QuestionPreviewMultipleChoice = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => {
    const { isQuestionImageEnabled, isRecordTextEnabled } =
        useQuestionFeatures();
    return (
        <Fragment key={keyId}>
            {question.questionTitle && (
                <Text variant="label" children={question.questionTitle} />
            )}
            {question.helperText && <Text children={question.helperText} />}
            {isRecordTextEnabled && (
                <Text>
                    <Text as="span" variant="label">
                        Display in inbox as
                    </Text>
                    : {question.recordText ?? question.questionTitle}
                </Text>
            )}
            {isQuestionImageEnabled && (
                <QuestionImagePreview question={question} />
            )}
            {question.allowMultipleAnswers && (
                <Text>Choose all that apply (you can select none)</Text>
            )}
            {question.options?.map((option: Option, index) => (
                <StyledBulletWrapper key={index}>
                    <StyledBulletIconPreview>
                        {question.allowMultipleAnswers ? (
                            <CheckboxSVG />
                        ) : (
                            <RadioButtonSVG />
                        )}
                    </StyledBulletIconPreview>
                    <StyledBulletText>
                        {option.text && <Text children={option.text} skinny />}
                        {option.answerCode && (
                            <Ds.Badge color="greyscale">
                                <Icon name="Tag" />
                                {`SNOMED Code: ${option.answerCode.term} - ${option.answerCode.conceptId}`}
                            </Ds.Badge>
                        )}
                    </StyledBulletText>
                </StyledBulletWrapper>
            ))}
            {question?.options &&
                question.options.length >
                    MULTIPLE_CHOICE_MANY_ANSWERS_MAX_RECOMMENDED_OPTIONS &&
                question.allowMultipleAnswers && (
                    <MultipleChoiceManyAnswersRecommendedLimit />
                )}
        </Fragment>
    );
};

const answerHelperText = (question: ClientSideQuestion): string =>
    `Patients will be able to answer with ${
        question.questionTemplateType === QuestionTemplateType.Height
            ? "their height"
            : question.questionTemplateType === QuestionTemplateType.Weight
            ? "their weight"
            : "a number"
    } in ${question.snomedCodeUnit} (min ${
        question.numberedQuestionData?.minimumValue
    }${question.snomedCodeUnit} and max ${
        question.numberedQuestionData?.maximumValue
    }${question.snomedCodeUnit})`;

const QuestionPreviewMeasurement = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => (
    <Fragment key={keyId}>
        {question.questionTitle && (
            <Text variant="label" children={question.questionTitle} />
        )}
        {question.helperText && (
            <StyledMarkdown
                children={floreyPlainTextToMarkdownLink(question.helperText)}
            />
        )}
        <StyledGreyIndent>
            <Text children={answerHelperText(question)} />
            <Ds.Badge color="greyscale">
                <Icon name="Tag" />
                {`SNOMED Code: ${question.snomedCode?.term} - ${question.snomedCode?.conceptId}`}
            </Ds.Badge>
        </StyledGreyIndent>
    </Fragment>
);

const QuestionPreviewText = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => {
    const { isQuestionImageEnabled, isRecordTextEnabled } =
        useQuestionFeatures();
    return (
        <Fragment key={keyId}>
            {question.questionTitle && (
                <Text variant="label" children={question.questionTitle} />
            )}
            {question.helperText && (
                <ReactMarkdown children={question.helperText} />
            )}
            {isRecordTextEnabled && (
                <Text>
                    <Text as="span" variant="label">
                        Display in inbox as
                    </Text>
                    : {question.recordText ?? question.questionTitle}
                </Text>
            )}
            {isQuestionImageEnabled && (
                <QuestionImagePreview question={question} />
            )}
            <StyledGreyIndent>
                <Text
                    children={
                        "Patients will be able to provide an answer up to 600 characters long."
                    }
                />
            </StyledGreyIndent>
            <FreeTextInformation />
        </Fragment>
    );
};

const QuestionPreviewInformation = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => {
    const { isQuestionImageEnabled } = useQuestionFeatures();
    return (
        <Fragment key={keyId}>
            {question.questionTitle && (
                <Text variant="label" children={question.questionTitle} />
            )}
            {question.helperText && (
                <ReactMarkdown children={question.helperText} />
            )}
            {isQuestionImageEnabled && (
                <QuestionImagePreview question={question} />
            )}
        </Fragment>
    );
};

const QuestionPreviewBranch = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => {
    const questionText =
        question.branchData?.condition.conditionQuestion.questionText;
    const answerText =
        question.branchData?.condition.conditionQuestion.answerText;
    return (
        <Fragment key={keyId}>
            {questionText && (
                <Flex gap={"1"}>
                    <Text variant="label" as="span">
                        Question:
                    </Text>
                    <Text>{questionText}</Text>
                </Flex>
            )}
            {answerText && (
                <Flex gap={"1"}>
                    <Text variant="label" as="span">
                        Matching answer:
                    </Text>
                    <Text>{answerText}</Text>
                </Flex>
            )}
        </Fragment>
    );
};

const QuestionPreviewPhotoUpload = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => (
    <Fragment key={keyId}>
        {question.questionTitle && (
            <Text variant="label" children={question.questionTitle} />
        )}
        {question.helperText && (
            <StyledMarkdown
                children={floreyPlainTextToMarkdownLink(question.helperText)}
            />
        )}
        <StyledGreyIndent>
            <Text>Patients will be able to upload up to 5 images</Text>
        </StyledGreyIndent>
    </Fragment>
);

const QuestionPreview = ({
    question,
    keyId,
}: {
    question: ClientSideQuestion;
    keyId: string;
}): JSX.Element => {
    switch (question.questionType) {
        case QuestionType.Text:
            return <QuestionPreviewText question={question} keyId={keyId} />;
        case QuestionType.Information:
            return (
                <QuestionPreviewInformation question={question} keyId={keyId} />
            );
        case QuestionType.MultipleChoice:
            return (
                <QuestionPreviewMultipleChoice
                    question={question}
                    keyId={keyId}
                />
            );
        case QuestionType.Numbered:
            return (
                <QuestionPreviewMeasurement question={question} keyId={keyId} />
            );
        // Branching card does not have a preview version
        case QuestionType.Branching:
            return <QuestionPreviewBranch question={question} keyId={keyId} />;
        case QuestionType.ImageAttachment:
            return (
                <QuestionPreviewPhotoUpload question={question} keyId={keyId} />
            );
        default:
            return <></>;
    }
};

export default QuestionPreview;
