import React, { FormEvent } from "react";

import { Button, Card, Flex, Text } from "@accurx/design";

import { TwoFactorContext } from "../TwoFactor";
import {
    TwoFactorContextProps,
    TwoFactorMethod,
    TwoFactorRequestStepProps,
    TwoFactorStepProps,
} from "../TwoFactorModels";

export type TwoFactorSuccessProps = Pick<
    TwoFactorRequestStepProps,
    "twoFactorMethod"
> &
    TwoFactorStepProps &
    TwoFactorContextProps;

const getSuccessButtonText = (context: TwoFactorContext): string => {
    switch (context) {
        case TwoFactorContext.PatientList:
            return "Create a patient list";
        case TwoFactorContext.RecordView:
            return "Start using Record View";
        default:
            return "Continue";
    }
};

export const TwoFactorSuccess = ({
    nextStep,
    twoFactorMethod: { chosenMethod, obfuscatedMethod },
    context,
}: TwoFactorSuccessProps): JSX.Element => {
    const handleCreateList = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        nextStep && nextStep();
    };

    const useMobile = chosenMethod === TwoFactorMethod.Mobile;

    return (
        // Form is here to handle the "enter" key
        <form onSubmit={handleCreateList}>
            <Card spacing={3}>
                <Flex flexDirection="column" gap="5">
                    <Text variant="body">
                        Your {useMobile ? "phone number" : "email address"}:{" "}
                        <strong>{obfuscatedMethod}</strong> is now set-up to
                        receive verification codes. You can update your security
                        preferences at any time by contacting support.
                    </Text>
                    <Flex justifyContent="flex-end">
                        <Button
                            text={getSuccessButtonText(context)}
                            type="submit"
                        />
                    </Flex>
                </Flex>
            </Card>
        </form>
    );
};
