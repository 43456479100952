import { Flex } from "@accurx/design";

import { SkeletonBlock } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import { StyledCell } from "../components/ConversationSummaries.styles";
import { LoadingConversationSummary } from "../components/LoadingConversationSummary";
import { ClinicianConversationsPageLayout } from "./ClinicianConversationsPageLayout";

export const LoadingClinicianConversationPage = ({
    pageSize,
    headerText,
}: {
    pageSize: number;
    headerText: string;
}) => (
    <ClinicianConversationsPageLayout
        headerText={headerText}
        pagination={
            <Flex justifyContent="flex-end">
                <SkeletonBlock height="41px" width="106px" />
            </Flex>
        }
    >
        <StyledCell>
            <Flex flexDirection="column">
                {[...Array(pageSize).keys()].map(() => (
                    <LoadingConversationSummary />
                ))}
            </Flex>
        </StyledCell>
    </ClinicianConversationsPageLayout>
);
