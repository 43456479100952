import { Log } from "@accurx/shared";

type ClinicianMessagingIdentity = {
    id: string;
};

const regex = /^cm-(\d+)-(\w+)$/;

/**
 * Provides the original server identity for a mapped conversation
 */
export const mapConversationIdToClinicianMessagingIdentity = (
    conversationId: string,
    suppressLogs?: boolean,
): ClinicianMessagingIdentity | undefined => {
    const match = conversationId?.match(regex);
    if (match) {
        return {
            id: match[2],
        };
    }
    if (!suppressLogs) {
        Log.error("Unable to parse unique conversation ID", {
            tags: { product: "Inbox", conversationId },
        });
    }
    return undefined;
};
