import NotFound from "NotFound";
import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { CookiesAccount } from "app/account/UserAccountSetting/CookiesAccount";
import { MessageSignature } from "app/account/UserAccountSetting/MessageSignature";
import { Security } from "app/account/UserAccountSetting/Security";
import { TermsAndConditionsPage } from "app/account/UserAccountSetting/TermsAndConditionsPage";
import { UserProfile } from "app/account/UserAccountSetting/UserProfile";
import { WorkspaceAccessPage } from "app/account/UserAccountSetting/WorkspaceAccessPage/WorkspaceAccessPage";
import { ROUTES_BASE } from "shared/Routes";

export const ACCOUNT_SETTINGS_ROUTES = [
    ROUTES_BASE.accountUserNameAndEmail,
    ROUTES_BASE.accountUserWorkspaceAccess,
    ROUTES_BASE.accountUserManageSignature,
    ROUTES_BASE.accountUserCookies,
    ROUTES_BASE.accountUserSecurity,
    ROUTES_BASE.accountUserTermsAndConditions,
];

export const AccountSettingsRoutesSection = () => {
    return (
        <Switch>
            <SimpleRoute path={ROUTES_BASE.accountUserNameAndEmail} exact>
                <UserProfile />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_BASE.accountUserWorkspaceAccess} exact>
                <WorkspaceAccessPage />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_BASE.accountUserManageSignature} exact>
                <MessageSignature />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_BASE.accountUserCookies} exact>
                <CookiesAccount />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_BASE.accountUserSecurity} exact>
                <Security />
            </SimpleRoute>
            <SimpleRoute path={ROUTES_BASE.accountUserTermsAndConditions} exact>
                <TermsAndConditionsPage />
            </SimpleRoute>
            <SimpleRoute path="*">
                <NotFound />
            </SimpleRoute>
        </Switch>
    );
};
