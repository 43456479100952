/**
 * Initialises the message with greeting
 * & body separated by a new line
 */
export const composeInitialMessageBody = ({
    messageBody,
    messageGreeting,
}: {
    messageBody: string;
    messageGreeting: string;
}) => {
    if (messageGreeting !== "") {
        return `${messageGreeting}\n${messageBody}`;
    }
    return messageBody;
};
