/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import {
    BasePatientThreadItem,
    PatientThreadItemTransport,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { UnknownConversationItem } from "shared/concierge/conversations/types/item.types";

import { mapBaseConversationItem } from "./conversationItem.helpers";

function hasEnoughForUnknownItem(o: any): o is BasePatientThreadItem {
    if (o === null || typeof o !== "object") {
        return false;
    }

    return typeof o.id === "string" && typeof o.createdAt === "string";
}

export function tryFindUnknownPatientThreadItem(
    ticketItem: PatientThreadItemTransport,
): BasePatientThreadItem | undefined {
    if (typeof ticketItem.type !== "number") {
        return undefined;
    }

    const potentialItems = Object.values(ticketItem).filter(
        (x): x is BasePatientThreadItem => hasEnoughForUnknownItem(x),
    );
    return potentialItems.length === 1 ? potentialItems[0] : undefined;
}

export function mapUnimplementedConversationItem(
    ticketItem: BasePatientThreadItem,
): UnknownConversationItem | undefined {
    return hasEnoughForUnknownItem(ticketItem)
        ? {
              ...mapBaseConversationItem(ticketItem),
              contentType: "Unknown",
              createdBy: { type: "System" },
          }
        : undefined;
}
