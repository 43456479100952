import * as UI from "@accurx/design";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
`;

export const StyledSpinner = styled.div`
    background: #0b8888;
    padding: ${UI.Tokens.SIZES[1]};
    display: inline-flex;
    border-radius: 100%;
    svg {
        animation: 900ms ease-in-out 300ms infinite forwards ${spin};
    }
`;
