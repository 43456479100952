import { DateHelpers, Log } from "@accurx/shared";

import { getIndividualClinicPath } from "app/patientLists/integratedClinics/routes";

import { getLocationWithPlaceholder } from "../../../../shared/location";

export function getClinicPath({
    workspaceId,
    clinicCode,
    clinicDate,
    location,
}: {
    workspaceId: string;
    clinicCode?: string | null;
    clinicDate?: string | null;
    location?: string | null;
}) {
    if (clinicCode && clinicDate) {
        const date = DateHelpers.getDateParts(clinicDate);

        if (!date) {
            Log.error(`Unable to parse clinic date from "${clinicDate}"`);
            return;
        }

        const { d, m, y } = date;
        const day = d.toString().padStart(2, "0");
        const month = m.toString().padStart(2, "0");
        const clinicDay = `${y}${month}${day}`;

        return getIndividualClinicPath({
            workspaceId,
            clinicCode,
            clinicDay,
            location: getLocationWithPlaceholder(location),
        });
    }

    Log.error(
        `Missing clinic code and/or clinic date, received clinicCode: "${clinicCode}", clinicDate: "${clinicDate}"`,
    );
}
