import React from "react";

import { ButtonLink, Feedback, Flex } from "@accurx/design";
import styled from "styled-components";

import { LogInOptionsButton } from "./LogIn/TrustsLogin/Login.styles";

export const StyledButtonLink = styled(ButtonLink)`
    width: 100%;

    & > span {
        display: block;
        text-align: center;
    }
`;

// Currently hardcoded for UHL since they're the only AD user
export const ActiveDirectoryRedirectForm = ({
    externalOrgId,
    onBack,
    isRegisterForm = false,
}: {
    externalOrgId: string;
    onBack: () => void;
    isRegisterForm?: boolean;
}) => {
    return (
        <Flex flexDirection="column" gap="2">
            <LogInOptionsButton
                theme="secondary"
                icon={{ name: "ArrowTail", rotation: "left" }}
                text="Back"
                onClick={onBack}
            />
            {isRegisterForm ? (
                <Feedback colour="error">
                    You can already use this email address to access Accurx
                    without creating an account. You now need to log in with
                    UHL’s authentication process below. Contact your IT support
                    for further help.
                </Feedback>
            ) : (
                <Feedback colour="information">
                    To log in with this email address, you now need to use UHL’s
                    authentication process. You can access it below.
                </Feedback>
            )}
            <StyledButtonLink
                text="Log in with UHL email (uhl-tr.nhs.uk)"
                href={`/oauth/${externalOrgId}/authenticate?returnUrl=/postlogin`}
            />
        </Flex>
    );
};
