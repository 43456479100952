import { Feature, isFeatureEnabledForWorkspace, useAuth } from "@accurx/auth";

import { useSelectedOrganisation } from "./SelectedOrganisationProvider";

/** Returns true if the provided feature is enabled for the current workspace, otherwise returns false. Uses the useFeatureFlag hook from @accurx/auth, scoped to the current selectedOrgId. This is necessary while not all of fleming client pages live on routes with a workspaceId.
 */
export const useFeatureFlag = (feature: Feature) => {
    const { selectedOrgId } = useSelectedOrganisation();
    const { user } = useAuth();

    if (!user.isLoggedIn) {
        return false;
    }

    const workspace = user.organisations.find(
        (org) => org.orgId === selectedOrgId,
    );

    if (!workspace) {
        return false;
    }

    return isFeatureEnabledForWorkspace(feature, workspace);
};
