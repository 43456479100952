import { MessageTemplate } from "../api/FlemingDtos";
import { syncedDefaultText } from "./MessageHelper";

export class MessageTemplateTokens {
    static FieldToken = "*****";
}

export type MessageTemplateOption = MessageTemplate & {
    group: string | null;
    isPresetTemplate: boolean;
};

export type PresetTemplate = {
    title: string;
    body: string;
    isPatientResponseRequired?: boolean;
};

interface ScheduledVideoConsultTemplateData {
    practiceName: string;
}

export interface NonEditableContentOptions {
    organisationName: string;
    isVideoConsult: boolean;
    patientResponseEnabled: boolean;
    numberOfDocumentsAttached: number;
    videoConsultDate?: string;
    videoConsultTime?: string;
}

export interface PresetTemplateSection {
    sectionHeading: string;
    templateList: PresetTemplate[];
}

export const PREFILLED_VIDEO_CONSULT_SECTION = "Accurx template";
export const NO_TEMPLATE_SELECTED_DROPDOWN_TITLE = "Select a template";

export class Templates {
    static noTemplateSelected: MessageTemplateOption = {
        id: null,
        title: NO_TEMPLATE_SELECTED_DROPDOWN_TITLE,
        body: "",
        type: null,
        isDefault: null,
        owner: null,
        isPresetTemplate: false,
        group: null,
    };

    static presetVCTemplate: PresetTemplate = {
        title: "Accurx template",
        body: "I'm ready to start our Video Consult. Click the link below to join and wait for me to connect.",
    };

    /** Wrapper around presetVCTemplate data that handles the scheduled appointment use-case
     *  @param isScheduledVideoConsult The message being sent is for a scheduled appointment
     *  @param data Additional data to be included in the message
     */
    static getPresetVCTemplate(
        isScheduledVideoConsult = false,
        data?: ScheduledVideoConsultTemplateData,
    ): PresetTemplate {
        const template = Object.assign({}, Templates.presetVCTemplate);

        if (isScheduledVideoConsult) {
            template.body =
                "I'd like to invite you to a Video Consult appointment";

            if (data !== undefined) {
                template.body += ` with ${data.practiceName}`;
            }

            template.body +=
                ". The time and date of the appointment is at the end of this message.";
        }

        return template;
    }

    static VCTemplates: PresetTemplateSection[] = [
        {
            sectionHeading: PREFILLED_VIDEO_CONSULT_SECTION,
            templateList: [Templates.presetVCTemplate],
        },
    ];

    /** Generates the non-editable part of a message given a set of options.
     *  @param options The parameters that determine what non-editable text will be added to the message
     */
    static getNonEditableContent(options: NonEditableContentOptions): string {
        const LINK_PLACEHOLDER = "[link will appear here]";
        let content = "";

        if (options.isVideoConsult) {
            if (
                options.videoConsultDate !== undefined &&
                options.videoConsultTime !== undefined
            ) {
                content += `Date: ${options.videoConsultDate}
Time: ${options.videoConsultTime}
You will receive a link to the Video Consult approximately ${ROOM_LINKS_SENT_BEFORE_APPT_HOURS} hours before the appointment.\n`;
            } else {
                content += `To join, please follow this link: ${LINK_PLACEHOLDER}\n`;
            }
        }

        const getResponseAndDocumentsLinksContent = (
            options: NonEditableContentOptions,
            link_placeholder: string,
        ) => {
            if (
                options.patientResponseEnabled &&
                options.numberOfDocumentsAttached > 0
            ) {
                return `${syncedDefaultText.singleLinkToPortal} ${link_placeholder}\n`;
            }
            let content = "";
            if (options.patientResponseEnabled) {
                content += `${syncedDefaultText.patientResponseText} ${link_placeholder}\n`;
            }
            if (options.numberOfDocumentsAttached > 0) {
                content += `${syncedDefaultText.pleaseViewYourAttachmentHere} ${link_placeholder}\n`;
            }
            return content;
        };

        content += getResponseAndDocumentsLinksContent(
            options,
            LINK_PLACEHOLDER,
        );

        content += options.organisationName;

        return content;
    }
}

export interface Template {
    Title: string;
    Body: string;
}

export const ROOM_LINKS_SENT_BEFORE_APPT_HOURS = "18";
