import FlemingApi from "api/FlemingApiClient";
import {
    IFileUploadRequest,
    IFileUploadResponse,
    ITemplateAttachmentUploadRequest,
} from "api/FlemingDtos";

import { StoredFileWithId } from "./FileUploadReducer";

// ACTION TYPES

export const FILE_UPLOAD_STARTED = "FILE_UPLOAD_STARTED";
export const FILE_UPLOAD_FINISHED = "FILE_UPLOAD_FINISHED";
export const FILE_DATA_RECEIVED = "FILE_DATA_RECEIVED";
export const FILE_REMOVED = "FILE_REMOVED";
export const RESET_FILE_UPLOAD = "RESET_FILE_UPLOAD";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export type FileUploadStartedAction = {
    type: typeof FILE_UPLOAD_STARTED;
};

export type FileUploadFinishedAction = {
    type: typeof FILE_UPLOAD_FINISHED;
    file: StoredFileWithId | null;
    error: IFileUploadResponse["error"];
};

export type FileDataReceivedAction = {
    type: typeof FILE_DATA_RECEIVED;
    files: StoredFileWithId[];
};

export type FileRemovedAction = {
    type: typeof FILE_REMOVED;
    fileId: string;
};

type ResetFileUploadAction = {
    type: typeof RESET_FILE_UPLOAD;
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | FileUploadStartedAction
    | FileUploadFinishedAction
    | FileDataReceivedAction
    | FileRemovedAction
    | ResetFileUploadAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    uploadSmsDocument:
        (request: IFileUploadRequest): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: FILE_UPLOAD_STARTED,
            });

            const documentUploadResponse = await FlemingApi.documentUpload({
                file: request.file,
                organisationId: request.organisationId,
            });

            dispatch({
                type: FILE_UPLOAD_FINISHED,
                file:
                    documentUploadResponse.success &&
                    documentUploadResponse.result !== null
                        ? {
                              id: documentUploadResponse.result.id,
                              file: {
                                  name: request.file.name,
                                  size: request.file.size,
                                  previewUrl: window.URL.createObjectURL(
                                      request.file,
                                  ),
                              },
                          }
                        : null,
                error: documentUploadResponse.error,
            });
        },

    uploadTemplateAttachment:
        (
            request: ITemplateAttachmentUploadRequest,
        ): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: FILE_UPLOAD_STARTED,
            });

            const documentUploadResponse =
                await FlemingApi.uploadTemplateAttachment({
                    file: request.file,
                    organisationId: request.organisationId,
                });

            dispatch({
                type: FILE_UPLOAD_FINISHED,
                file:
                    documentUploadResponse.success &&
                    documentUploadResponse.result !== null
                        ? {
                              id: documentUploadResponse.result.id,
                              file: {
                                  name: request.file.name,
                                  size: request.file.size,
                                  previewUrl: window.URL.createObjectURL(
                                      request.file,
                                  ),
                              },
                          }
                        : null,
                error: documentUploadResponse.error,
            });
        },

    uploadEmailAttachment:
        (request: IFileUploadRequest): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: FILE_UPLOAD_STARTED,
            });

            const uploadEmailAttachmentResponse =
                await FlemingApi.uploadEmailAttachment({
                    file: request.file,
                    organisationId: request.organisationId,
                });

            dispatch({
                type: FILE_UPLOAD_FINISHED,
                file:
                    uploadEmailAttachmentResponse.success &&
                    uploadEmailAttachmentResponse.result !== null
                        ? {
                              id: uploadEmailAttachmentResponse.result
                                  .externalEmailAttachmentId,
                              file: {
                                  name: request.file.name,
                                  size: request.file.size,
                                  previewUrl: window.URL.createObjectURL(
                                      request.file,
                                  ),
                              },
                          }
                        : null,
                error: uploadEmailAttachmentResponse.error,
            });
        },

    removeFile: (fileId: string) => ({
        type: FILE_REMOVED,
        fileId: fileId,
    }),

    resetFileUpload: () => ({
        type: RESET_FILE_UPLOAD,
    }),
};
