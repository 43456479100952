import { mapConversationServerIdToConversationId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationServerIdToConversationId";
import { mapConversationIdToClinicianMessagingIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToClinicianMessagingIdentity";
import { mapConversationIdToTicketIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToTicketIdentity";
import { mapTicketIdentityToConversationId } from "domains/concierge/internal/api/ticket/mappers/mapTicketIdentityToConversationId";

export { ConciergeProvider } from "./internal/provider";
export { useWorkspaceUnreadCountsQuery } from "./hooks/queries/useWorkspaceUnreadCountsQuery";

// We know this isn't great, but there are places outside of concierge where we have
// to map conversation IDs to server identities (and vice versa)
export const conversationIdMapper = {
    ticket: {
        fromSource: mapTicketIdentityToConversationId,
        toSource: mapConversationIdToTicketIdentity,
    },
    clinicianMessaging: {
        fromSource: mapConversationServerIdToConversationId,
        toSource: mapConversationIdToClinicianMessagingIdentity,
    },
};
