import { TicketIdentityType } from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { TicketIdentityType as PatientMessagingTicketIdentityType } from "domains/concierge/internal/api/patientMessaging/types/dto.types";

type RequiredTicketIdentity = {
    type: TicketIdentityType | PatientMessagingTicketIdentityType;
    id: string;
};

const regex = /^t-(\d+)-(\w+)$/;

/**
 * Provides the original server identity for a mapped conversation,
 * used for assign etc.
 */
export const mapConversationIdToTicketIdentity = (
    conversationId: string,
    suppressLogs?: boolean,
): RequiredTicketIdentity | undefined => {
    const match = conversationId?.match(regex);
    if (match) {
        return {
            type: parseInt(match[1], 10),
            id: match[2],
        };
    }
    if (!suppressLogs) {
        Log.error("Unable to parse unique conversation ID", {
            tags: { product: "Inbox", conversationId },
        });
    }
    return undefined;
};
