import { useCallback, useState } from "react";

import { shallowEqual } from "react-redux";
import { useLocation } from "react-router";

import { useSafeAsync } from "api/Api.utils";
import {
    ClinicianConversationApi,
    ClinicianConversationSummary,
} from "api/ClinicianConversationApi";
import { FlemingAnalyticsTracker } from "app/analytics";
import { LocationState } from "app/clinicianconversation/hooks/useClinicianConversation";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { PatientHelper } from "shared/PatientHelper";
import { findBaseRoute } from "shared/RoutesHelper";
import { useAppSelector, useCurrentOrgId } from "store/hooks";

import { useMessagePracticeAvailabilityWithPatientData } from "../useMessagePracticeAvailabilityWithPatientData";

const filterConversations = (
    conversations: ClinicianConversationSummary[],
): ClinicianConversationSummary[] =>
    conversations.sort(
        (summary, otherSummary) =>
            new Date(otherSummary.lastMessage.dateSent).getTime() -
            new Date(summary.lastMessage.dateSent).getTime(),
    );

export const useRecentConversations = (): {
    summaries: ClinicianConversationSummary[] | null;
    hasError: boolean;
    canMessageGp: boolean;
    patientFirstName?: string;
    nextLocationState: LocationState;
} => {
    const { canMessageGp, patient } =
        useMessagePracticeAvailabilityWithPatientData();
    const patientToken = useAppSelector(
        ({ searchForPatient }) =>
            PatientHelper.getPatientToken(searchForPatient.lastResponse),
        shallowEqual,
    );
    const organisationId = useCurrentOrgId();
    const loggedInCustomProperties = useFlemingLoggedInAnalytics();

    const [summaries, setSummaries] = useState<
        ClinicianConversationSummary[] | null
    >(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const safeAsync = useSafeAsync();
    const location = useLocation();
    const nextLocationState: LocationState = {
        appOrigin: "RecentMessages",
        patientOrigin: findBaseRoute(location.pathname),
    };

    const getConversations = useCallback(async () => {
        if (canMessageGp && organisationId && patientToken) {
            const { result: conversations, success } = await safeAsync(
                ClinicianConversationApi.getRecentConversations(
                    organisationId,
                    patientToken,
                ),
            );
            if (success && conversations) {
                setHasError(false);
                const filteredConversations =
                    filterConversations(conversations);
                setSummaries(filteredConversations);
                FlemingAnalyticsTracker.trackClinicianConversationPreviousMessageComponentLoad(
                    {
                        hasError: false,
                        countConversation: filteredConversations.length,
                        ...loggedInCustomProperties,
                    },
                );
            } else {
                setHasError(true);
                setSummaries(null);
                FlemingAnalyticsTracker.trackClinicianConversationPreviousMessageComponentLoad(
                    { hasError: true, ...loggedInCustomProperties },
                );
            }
        }
    }, [
        canMessageGp,
        loggedInCustomProperties,
        organisationId,
        patientToken,
        safeAsync,
    ]);

    if (summaries === null && hasError === false) {
        getConversations();
    }

    return {
        summaries,
        hasError,
        canMessageGp: canMessageGp,
        patientFirstName: patient?.firstName ?? undefined,
        nextLocationState,
    };
};
