import { Option } from "@accurx/design";
import sortBy from "lodash/sortBy";

import { SnomedCode } from "../SnomedCode.types";

export const EMPTY_OPTION = {
    label: "No SNOMED code",
    value: "RESET",
    shouldHideValue: true,
};

export const toOption = (snomedCode: SnomedCode): Option => ({
    label: snomedCode.term,
    value: snomedCode.conceptId,
});

export const toOptions = (snomedCodes: SnomedCode[]): Option[] => [
    EMPTY_OPTION,
    ...sortBy(snomedCodes, "term").map(toOption),
];

export const findCode = (
    snomedCodes: SnomedCode[],
    option: Option,
): Optional<SnomedCode> => {
    return snomedCodes.find((code) => code.conceptId === option.value);
};
