import { Text, Tokens, Types } from "@accurx/design";
import styled from "styled-components";

const OuterWrapper = styled.section`
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
`;

const List = styled.ul<{
    gap: `${Types.SizeVariants}`;
}>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => Tokens.SIZES[gap]};
    list-style-type: none;
    padding: 0;
`;

const QuestionnaireNames = styled(Text).attrs(() => ({
    skinny: true,
}))`
    word-break: break-word;
`;

export const StyledQuestionnaires = {
    OuterWrapper,
    List,
    QuestionnaireNames,
};
