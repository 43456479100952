import { Ds } from "@accurx/design";

const BADGES_COLOURS_LABELS: Record<
    string,
    {
        colour: Ds.BadgeProps["color"];
        label:
            | string
            | {
                  verbose: string;
                  compact: string;
              };
    }
> = {
    Pending: {
        colour: "yellow",
        label: {
            verbose: "Pending validation",
            compact: "Pending",
        },
    },
    Expired: {
        colour: "greyscale",
        label: "Expired link",
    },
    FailedToDeliver: {
        colour: "greyscale",
        label: "Failed to deliver",
    },
    Incomplete: {
        colour: "greyscale",
        label: "Incomplete",
    },
} as const;

/**
 *
 * @param status
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'status' string and returns the colour of the badge for the validation card.
 */
export const getStatusBadgeColour = (
    status: string,
): Ds.BadgeProps["color"] => {
    return BADGES_COLOURS_LABELS[status]?.colour ?? "greyscale";
};

/**
 *
 * @param status
 * @returns Specific to the perioperative care questionnaire used in the pilot with GSTT (Guy's and St Thomas' Trust).
 *  Accepts a 'status' string and returns the string to display in the UI.
 */
export const getStatusBadgeText = ({
    status,
    verbose,
}: {
    status: string;
    /**
     * Whether it should return the text in its "verbose" form.
     *
     * i.e.
     *
     * verbose === false -> Pending
     * verbose === true -> Pending validation
     */
    verbose: boolean;
}): string => {
    if (BADGES_COLOURS_LABELS[status]) {
        const label = BADGES_COLOURS_LABELS[status]?.label;
        if (typeof label === "string") {
            // No difference between verbose & compact
            return label;
        }
        return verbose ? label.verbose : label.compact;
    }
    return status;
};
