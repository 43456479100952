import React from "react";

import { Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { generatePath, useParams } from "react-router";

import { RouterLink } from "app/organisations/shared/RouterLink";
import { ROUTES_CHAIN } from "shared/Routes";

export const PatientTriageTab = (): JSX.Element => {
    useAccurxWebTitle("Manage patient triage settings");

    const { orgId } = useParams<{ orgId: string }>();
    return (
        <>
            <Text>
                Patient Triage settings are currently managed from the{" "}
                <RouterLink
                    to={generatePath(
                        ROUTES_CHAIN.practicesPatientTriageSettings,
                        { orgId },
                    )}
                >
                    Patient Triage page
                </RouterLink>
                .
            </Text>
        </>
    );
};
