import {
    isConversationItemUnreadForTeam,
    isConversationItemUnreadForUser,
} from "domains/concierge/internal/util/isConversationItemUnread";
import { Conversation } from "domains/concierge/types";

export const partitionItemIdsByReadState = (
    conversation: Conversation,
    userId: string,
): { read: string[]; unread: string[] } => {
    const read: string[] = [];
    const unread: string[] = [];

    if (
        conversation.assignee.type === "User" &&
        conversation.assignee.id === userId
    ) {
        for (const item of conversation.items) {
            if (isConversationItemUnreadForUser(item, userId)) {
                unread.push(item.id);
            } else {
                read.push(item.id);
            }
        }
    } else if (conversation.assignee.type === "Team") {
        for (const item of conversation.items) {
            if (
                isConversationItemUnreadForTeam(
                    item,
                    userId,
                    conversation.assignee.id,
                )
            ) {
                unread.push(item.id);
            } else {
                read.push(item.id);
            }
        }
    } else {
        for (const item of conversation.items) {
            read.push(item.id);
        }
    }

    return { read, unread };
};
