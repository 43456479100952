import { LoginRequest, RegisterAccountProductType } from "@accurx/api/account";
import {
    SearchForPatientByDemographicsRequest,
    SearchForPatientByNhsNumberRequest,
    SearchForPatientResponse,
    SearchedResult,
} from "@accurx/api/portal";
import {
    IWrappedResult,
    JsonResult,
    Log,
    MobileNumberHelper,
    WrappedResult,
    getApiEndpoint,
    httpClient,
} from "@accurx/shared";
import { Observable } from "rxjs";

import { PatientHelper } from "shared/PatientHelper";

import * as dto from "./FlemingDtos";

const ENDPOINTS = {
    portal: {
        searchPatient: "/api/portal/SearchForPatient",
        searchPatientByDemographics:
            "/api/portal/SearchForPatient/ByDemographics",
        searchPatientReplay: "/api/portal/SearchForPatient/replay",
        redeemToken: "/api/portal/viewPatient/redeemToken",
    },
    patientMessaging: {
        recentPatientSearch: "/api/patientmessaging/latestpatientsearch",
        queryRecentPatientSearch:
            "/api/patientmessaging/latestpatientsearch/query",
        messagePatientWithId: "/api/patientmessaging/messagepatientwithid",
        messagePatientWithToken:
            "/api/patientmessaging/messagepatientwithtoken",
        patientAttachments: "/api/patientmessaging/PatientAttachment/All",
        patientAttachment: "/api/patientmessaging/PatientAttachment",
        deliveryReceipts: "/api/patientmessaging/DeliveryReceipts",
        patientVideoConsultations: "/api/patientmessaging/videoprogress",
        patientVideoConsultationStatus:
            "/api/patientmessaging/videoprogress/:videoConsultationId",
        documentUpload: "/api/patientmessaging/documentupload",
        templateAttachmentUpload:
            "/api/patientmessaging/template/message/:organisationId/attachment",
        patientLists: {
            summary: "/api/patientmessaging/patientlistsummary",
            get: "/api/patientmessaging/patientlist/getlist",
            upsert: "/api/patientmessaging/patientlist/addupdatelist",
            delete: "/api/patientmessaging/patientlist/deleteList",
            share: "/api/patientmessaging/patientlist/sharelist",
            shareWithWorkspace:
                "/api/patientmessaging/patientlist/sharewithworkspace",
            addPatientToList: "/api/patientmessaging/patientlist/addpatient",
            bulkAddPatientToListCheck:
                "/api/patientmessaging/patientlist/checkbulkaddpatient",
            bulkAddPatientToListConfirm:
                "/api/patientmessaging/patientlist/confirmbulkaddpatient",
            removePatientsFromList:
                "/api/patientmessaging/patientlist/removepatients",
            scheduledVideoConsults:
                "/api/patientmessaging/patientlist/scheduledvideoconsults",
        },
    },
    clinicianMessaging: {
        messagePractice: "/api/clinicianmessaging/MessagePractice",
        practiceInformation:
            "/api/clinicianmessaging/PracticeInformation/practices/:practiceCodeToQuery/activity",
        practiceWorkspaceInformation:
            "/api/clinicianmessaging/PracticeInformation/workspaces/:workspaceIdToQuery/activity",
        attachment: "/api/clinicianmessaging/attachments",
    },
    account: {
        login: "/api/account/login",
        webLogin: "/api/account/weblogin",
        logout: "/api/Account/Logout",
        loginStatus: "/api/Account/IsLoggedIn",
        allowedOrganisations: "/api/account/AllowedOrganisations",
        organisationRegistration: "/api/account/OrganisationRegistration",
        organisationRequest: "/api/account/OrganisationRequest",
        twoFactor: {
            method: "/api/account/twofactor",
            send: "/api/account/twofactor/send",
            verify: "/api/account/twofactor/verify",
            registerPhoneNumber: "/api/account/twofactor/registerphonenumber",
            registerSecondaryEmail:
                "/api/account/twofactor/registersecondaryemail",
        },
        userProfile: "/api/account/userprofile",
        healthcareProfile: "/api/account/userprofile/HealthcareProfileOptions",
    },
} as const;

/**
 * At the moment, does not error if the JSON received is correct JSON but not the right shape.
 * This could be achieved by writing type guards for each DTO but they would need to be maintained.
 * See https://stackoverflow.com/a/43895205 for context, TDLR: there's no casting in javascript, so you cannot throw if "casting fails".
 */
class FlemingApiClient {
    static get unauthorizedObservable(): Observable<Record<string, unknown>> {
        return httpClient.unauthorizedObservable;
    }

    static async searchForPatientByNhsNumber(
        orgId: number | null,
        nhsNumber: string,
        dateOfBirthYear: number,
        dateOfBirthMonth: number,
        dateOfBirthDay: number,
        isUserAction?: boolean,
    ): Promise<SearchForPatientResponse> {
        const requestDto: SearchForPatientByNhsNumberRequest = {
            nhsNumber: nhsNumber,
            dateOfBirthYear: dateOfBirthYear,
            dateOfBirthMonth: dateOfBirthMonth,
            dateOfBirthDay: dateOfBirthDay,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            organisationId: orgId!,
            isTestPatient: PatientHelper.isDemoPatient(nhsNumber),
            // sets `isUserAction` to true by default, so that by default we assume the user triggered the search
            // If `false`, the BE will not track the search, and will not mark the user as onboarded
            isUserAction: isUserAction === undefined ? true : isUserAction,
        };
        const response: IWrappedResult<SearchForPatientResponse> =
            await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: ENDPOINTS.portal.searchPatient,
                }),
                requestDto,
            );

        const mobileNumber =
            response?.result?.searchedResult?.patient?.mobileNumber;

        if (response.success === false) {
            return {
                searched: false,
                searchedNhsNumber: nhsNumber,
            };
        } else if (
            !!mobileNumber &&
            mobileNumber !== MobileNumberHelper.unknownNumber &&
            !mobileNumber.startsWith("*")
        ) {
            Log.error(
                "PDS search returned a patient with a mobile number that does not appear to be obfuscated",
            );
        }
        return response.result as SearchForPatientResponse;
    }

    static async searchForPatientByDemographics(
        request: SearchForPatientByDemographicsRequest,
    ): Promise<IWrappedResult<SearchForPatientResponse>> {
        const response: IWrappedResult<SearchForPatientResponse> =
            await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: ENDPOINTS.portal.searchPatientByDemographics,
                }),
                request,
            );

        const mobileNumber =
            response?.result?.searchedResult?.patient?.mobileNumber;

        if (
            !!mobileNumber &&
            mobileNumber !== MobileNumberHelper.unknownNumber &&
            !mobileNumber.startsWith("*")
        ) {
            Log.error(
                "PDS search returned a patient with a mobile number that does not appear to be obfuscated",
            );
        }
        return response;
    }

    static async getPatient(
        organisationId: number,
        patientToken: string,
    ): Promise<SearchedResult | null> {
        const { success, result } =
            await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: ENDPOINTS.portal.searchPatientReplay,
                }),
                { organisationId, patientToken },
            );

        if (success && result.isValid && result.searchedResult.matchFound) {
            return result.searchedResult;
        }

        return null;
    }

    /**
     * Backend will send up to 8 searches only
     */
    static getRecentPatientSearches({
        organisationId,
    }: dto.GetRecentSearchesRequest): Promise<
        IWrappedResult<dto.GetRecentSearchesResponse>
    > {
        return httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.recentPatientSearch,
                query: `?organisationId=${organisationId}`,
            }),
        );
    }

    /**
     * Backend will send up to 8 searches only
     */
    static queryRecentPatientSearches({
        organisationId,
        queryText,
    }: {
        organisationId: number | null;
        queryText: string;
    }): Promise<IWrappedResult<dto.GetRecentSearchesResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.queryRecentPatientSearch,
            }),
            {
                organisationId,
                search: queryText,
            },
        );
    }

    static savePatientToRecentSearches(
        request: dto.SaveRecentSearchRequest,
    ): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.recentPatientSearch,
            }),
            request,
        );
    }

    /**
     * Send a message as part of an existing conversation
     * or create a new conversation
     * when we have a patient token as a result of a patient search.
     *
     * User must have previously searched for the patient in order to be able to use this endpoint
     *
     * i.e. we use this endpoint when we message a patient from the patient
     * conversations, after having searched for a patient
     */
    static messagePatientWithToken(
        request: dto.MessagePatientWithTokenRequest,
    ): Promise<IWrappedResult<dto.IMessagePatientResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.messagePatientWithToken,
            }),
            request,
        );
    }

    /**
     * Send a message as part of an existing conversation
     * or create a new conversation
     * when we don't have a patient token as a result of a patient search.
     *
     * User must be 2FAed in, in order to be able to successfully use this endpoint
     *
     * i.e. we use this endpoint when we message a patient from the shared inbox
     * (where patient search doesn't happen)
     */
    static messagePatientWithExternalIdentity(
        request: dto.MessagePatientWithTwoFactorRequest,
    ): Promise<IWrappedResult<dto.IMessagePatientResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.messagePatientWithId,
            }),
            request,
        );
    }

    static async messagePractice(
        messageRequestDto: dto.IMessagePracticeRequest,
    ): Promise<IWrappedResult<dto.IMessagePracticeResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianMessaging.messagePractice,
            }),
            messageRequestDto,
        );
    }

    static async getWorkspaceActivityByPracticeCode(
        practiceCodeToQuery: string,
        organisationId: number,
    ): Promise<IWrappedResult<dto.IPracticeActivityResponse>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianMessaging.practiceInformation,
                params: { practiceCodeToQuery },
                query: `?organisationId=${organisationId}`,
            }),
        );
    }

    static async getWorkspaceActivityByWorkspaceId(
        workspaceIdToQuery: number,
        organisationId: number,
    ): Promise<IWrappedResult<dto.IPracticeActivityResponse>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianMessaging.practiceWorkspaceInformation,
                params: { workspaceIdToQuery: workspaceIdToQuery.toString() },
                query: `?organisationId=${organisationId}`,
            }),
        );
    }

    static async login(
        email: string,
        password: string,
        isLoginOrg: boolean,
    ): Promise<IWrappedResult<dto.IUserResponse>> {
        const requestDto: LoginRequest = {
            userName: email,
            password: password,
            product: isLoginOrg
                ? RegisterAccountProductType.Chain
                : RegisterAccountProductType.Fleming,
        };

        // "weblogin" send error emails, "login" have some extra analytics & logs
        const endpoint = isLoginOrg
            ? ENDPOINTS.account.webLogin
            : ENDPOINTS.account.login;

        const response = await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({ path: endpoint }),
            requestDto,
            false,
        );
        if (response.success === false) {
            const errorMessage =
                response.statusCode === 401
                    ? // Majority of the time 401 will be due to invalid email or password. Can't get a more specific error
                      // from the server due to security issues with leaking account information.
                      "Invalid email or password. Please try again."
                    : response.error;
            return WrappedResult.Fail<dto.IUserResponse>(errorMessage);
        }
        return WrappedResult.Success(response.result as dto.IUserResponse);
    }

    static logout(): Promise<{ success: boolean }> {
        return httpClient.postSafeAsync(
            getApiEndpoint({ path: ENDPOINTS.account.logout }),
        );
    }

    static async isLoggedIn(): Promise<IWrappedResult<dto.IUserResponse>> {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({ path: ENDPOINTS.account.loginStatus }),
        );
        if (response.success === false) {
            return WrappedResult.Fail<dto.IUserResponse>(response.error);
        }
        return WrappedResult.Success(response.result as dto.IUserResponse);
    }

    static async getOrganisations(): Promise<
        dto.IAllowedOrganisationResponse[]
    > {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({ path: ENDPOINTS.account.allowedOrganisations }),
        );
        if (response.success === false) {
            return [];
        }
        return response.result;
    }

    static async setupOrganisation(
        organisationRequestDto: dto.ISetupOrganisationRequest,
    ): Promise<dto.ISetupOrganisationResponse> {
        const response = await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.organisationRegistration,
            }),
            organisationRequestDto,
        );
        if (response.success === false) {
            return {
                success: response.success,
                organisations: [],
                organisationId: null,
            };
        }
        return response.result as dto.ISetupOrganisationResponse;
    }

    static async submitJoinOrganisationRequest(
        organisationRequestDto: dto.IJoinOrganisationFormRequest,
    ): Promise<boolean> {
        const response = await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({ path: ENDPOINTS.account.organisationRequest }),
            organisationRequestDto,
        );
        return response.success;
    }

    static async getPatientAttachments({
        organisationId,
        patientToken,
    }: dto.GetPatientAttachmentsRequest): Promise<
        IWrappedResult<dto.GetPatientAttachmentsResponse>
    > {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientAttachments,
                query: `?organisationId=${organisationId}&patientToken=${patientToken}`,
            }),
        );
    }

    static async deletePatientAttachments({
        organisationId,
        patientToken,
        documentUrlId,
    }: dto.DeletePatientAttachmentsRequest): Promise<{ success: boolean }> {
        return await httpClient.deleteJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientAttachment,
            }),
            { organisationId, patientToken, documentUrlId },
        );
    }

    static async getDeliveryReceipts({
        organisationId,
    }: dto.IDeliveryReceiptRequest): Promise<dto.IDeliveryReceiptResponse> {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.deliveryReceipts,
                query: `?organisationId=${organisationId}`,
            }),
        );
        if (response.success === false) {
            return {
                success: response.success,
                deliveryReceipts: [],
                organisationId: null,
            } as dto.IDeliveryReceiptResponse;
        }
        return {
            success: response.success,
            deliveryReceipts: response.result
                .receipts as dto.IDeliveryReceipt[],
            organisationId: response.result.organisationId,
        } as dto.IDeliveryReceiptResponse;
    }

    static async checkPatientProgress(
        videoConsultationId: string,
    ): Promise<dto.PatientVideoProgressResponse | null> {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientVideoConsultationStatus,
                params: { videoConsultationId },
            }),
        );
        if (response.success === false || response.result === null) {
            return null;
        }
        return {
            consultationId: response.result.consultationId,
            patientMessageSent: response.result.patientMessageSent,
            patientMessageDelivered: response.result.patientMessageDelivered,
            patientClickedLink: response.result.patientClickedLink,
            patientSettingUpDevice: response.result.patientSettingUpDevice,
            patientLastSeenInCall: response.result.patientLastSeenInCall,
        } as dto.PatientVideoProgressResponse;
    }

    static checkPatientsVideoProgress(
        request: dto.PatientsVideoProgressRequest,
    ): Promise<IWrappedResult<dto.PatientsVideoProgressResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientVideoConsultations,
            }),
            request,
        );
    }

    static postTwoFactorRegisterMobileNumber(
        request: dto.TwoFactorRegisterRequest,
    ): Promise<IWrappedResult<dto.TwoFactorNumberResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.twoFactor.registerPhoneNumber,
            }),
            request,
        );
    }

    static postTwoFactorRegisterSecondaryEmail(
        request: dto.TwoFactorRegisterEmailRequest,
    ): Promise<IWrappedResult<dto.TwoFactorNumberResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.twoFactor.registerSecondaryEmail,
            }),
            request,
        );
    }

    static getTwoFactorMethod(): Promise<JsonResult> {
        return httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.twoFactor.method,
            }),
        );
    }

    static postTwoFactorSend(): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.twoFactor.send,
            }),
            {},
        );
    }

    static postTwoFactorVerify(
        request: dto.TwoFactorVerifyRequest,
    ): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.twoFactor.verify,
            }),
            request,
        );
    }

    static async uploadTemplateAttachment({
        file,
        organisationId,
    }: dto.ITemplateAttachmentUploadRequest): Promise<
        IWrappedResult<dto.IDocumentUploadResponse>
    > {
        const formData = new FormData();
        formData.append("formFile", file);
        const response = await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.templateAttachmentUpload,
                params: { organisationId },
            }),
            formData,
        );
        if (response.success === false) {
            return WrappedResult.Fail<dto.IDocumentUploadResponse>(
                response.error,
            );
        }
        return WrappedResult.Success(
            response.result as dto.IDocumentUploadResponse,
        );
    }

    static async documentUpload({
        file,
        organisationId,
    }: dto.IDocumentUploadRequest): Promise<
        IWrappedResult<dto.IDocumentUploadResponse>
    > {
        const formData = new FormData();
        formData.append("formFile", file);
        const response = await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.documentUpload,
                query: `?organisationId=${organisationId}`,
            }),
            formData,
        );
        if (response.success === false) {
            return WrappedResult.Fail<dto.IDocumentUploadResponse>(
                response.error,
            );
        }
        return WrappedResult.Success(
            response.result as dto.IDocumentUploadResponse,
        );
    }

    static async uploadEmailAttachment({
        file,
        organisationId,
    }: dto.IExternalEmailUploadAttachmentRequest): Promise<
        IWrappedResult<dto.IExternalEmailUploadAttachmentResponse>
    > {
        const formData = new FormData();
        formData.append("formFile", file);
        const response = await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.clinicianMessaging.attachment,
                query: `?organisationId=${organisationId}&source=LocalFile`,
            }),
            formData,
        );
        if (response.success === false) {
            return WrappedResult.Fail<dto.IExternalEmailUploadAttachmentResponse>(
                response.error,
            );
        }
        return WrappedResult.Success(
            response.result as dto.IExternalEmailUploadAttachmentResponse,
        );
    }

    static getAllUserPatientListSummaries({
        organisationId,
    }: dto.AllPatientListSummariesRequest): Promise<
        IWrappedResult<dto.PatientListSummary[]>
    > {
        return httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.summary,
                query: `?organisationId=${organisationId}`,
            }),
        );
    }

    static createOrEditPatientList(
        request: dto.CreateOrEditPatientListRequest,
    ): Promise<IWrappedResult<dto.PatientList>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.upsert,
            }),
            request,
        );
    }

    static getUserPatientList({
        organisationId,
        patientListId,
    }: dto.PatientListRequest): Promise<IWrappedResult<dto.PatientList>> {
        return httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.get,
                query: `?organisationId=${organisationId}&listId=${patientListId}`,
            }),
        );
    }

    static deletePatientList(
        request: dto.DeletePatientListRequest,
    ): Promise<IWrappedResult<dto.PatientList>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.delete,
            }),
            request,
        );
    }

    static sharePatientList(
        request: dto.SharePatientListRequest,
    ): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.share,
            }),
            request,
        );
    }

    static sharePatientListWithWorkspace(
        request: dto.PatientListRequest,
    ): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists
                    .shareWithWorkspace,
            }),
            request,
        );
    }

    static addPatientToList(
        request: dto.PatientListAddPatientRequest,
    ): Promise<IWrappedResult<dto.PatientListAddPatientResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists.addPatientToList,
            }),
            request,
        );
    }

    static bulkAddPatientsToList({
        organisationId,
        patientListId,
        file,
    }: dto.PatientListBulkAddPatientRequest): Promise<
        IWrappedResult<dto.PatientListBulkAddPatientResponse>
    > {
        const formData = new FormData();
        formData.append("formFile", file);
        return httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists
                    .bulkAddPatientToListCheck,
                query: `?organisationId=${organisationId}&listId=${patientListId}`,
            }),
            formData,
        );
    }

    static confirmBulkAddPatientsToList(
        request: dto.PatientListConfirmBulkAddPatientRequest,
    ): Promise<IWrappedResult<dto.PatientListConfirmBulkAddPatientResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists
                    .bulkAddPatientToListConfirm,
            }),
            request,
        );
    }

    static removePatientEntriesFromList(
        request: dto.PatientListRemovePatientEntriesApiRequest,
    ): Promise<IWrappedResult<boolean>> {
        return httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists
                    .removePatientsFromList,
            }),
            request,
        );
    }

    static checkVideoConsultCancellations(
        request: dto.GetScheduledVideoConsultsRequest,
    ): Promise<IWrappedResult<dto.GetScheduledVideoConsultsResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patientMessaging.patientLists
                    .scheduledVideoConsults,
            }),
            request,
        );
    }

    static postPatientToken(
        request: dto.PostPatientTokenRequest,
    ): Promise<IWrappedResult<dto.PostPatientTokenResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.portal.redeemToken,
            }),
            request,
        );
    }

    static getHealthcareProfileOptions(): Promise<
        IWrappedResult<dto.GetHealthcareProfileOptionsResponse>
    > {
        return httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.healthcareProfile,
            }),
        );
    }

    static postUserProfile(
        request: dto.PostUserProfileRequest,
    ): Promise<IWrappedResult<dto.PostUserProfileResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.account.userProfile,
            }),
            request,
        );
    }

    static requestQuestionnairePreview(
        request: dto.QuestionnairePreviewRequest,
    ): Promise<IWrappedResult<dto.QuestionnairePreviewResponse>> {
        return httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: "/api/patientmessaging/previewfloreyenrolment",
            }),
            {
                organisationId: request.workspaceId,
                conditionId: request.questionnaireId,
            },
        );
    }
}

export default FlemingApiClient;
