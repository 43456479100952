import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { MAX_MESSAGE_LENGTH } from "domains/compose/constants";
import { useCompose } from "domains/compose/context";
import { useCharacterCount } from "domains/compose/hooks/useCharacterCount";

import { CharacterCount } from "../../../Compose/components/CharacterCount/CharacterCount";
import { FragmentCount } from "../../../Compose/components/FragmentCount/FragmentCount";

export const MessageFooter = ({ children }: { children?: JSX.Element }) => {
    const { characterCount, fragmentCount } = useCharacterCount();
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );
    const { state } = useCompose();

    return (
        <UI.Flex gap="1" justifyContent="space-between">
            {children}
            {isMessageComponentV1_1Enabled &&
                state.contactDetails.method !== "Email" && (
                    <UI.Flex
                        gap="0.5"
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <CharacterCount
                            charactersUsed={characterCount}
                            maxCharacters={MAX_MESSAGE_LENGTH}
                        />
                        <FragmentCount count={fragmentCount} />
                    </UI.Flex>
                )}
        </UI.Flex>
    );
};
