import { Log } from "@accurx/shared";
import noop from "lodash/noop";
import { Unsubscribable, from } from "rxjs";
import { distinctUntilKeyChanged, filter, first } from "rxjs/operators";

import { ConversationActions } from "shared/concierge/conversations/types/component.types";
import { ConversationUpdate } from "shared/concierge/conversations/types/conversation.types";
import { ConnectionStateNew } from "shared/hubClient/ConnectionState";
import { BehaviorSubscribable } from "shared/types/rxjs.types";

type ConstructorArgs = {
    /**
     * Refresh rate defines, in milliseconds, how often we should poll for
     * unread items when the SignalR connection drops.
     */
    refreshRate: number;

    /**
     * A feed of updates to the SignalR connection.
     */
    connectionStateFeed: BehaviorSubscribable<ConnectionStateNew>;

    onFetchSuccess?: (updates: ConversationUpdate[]) => void;

    conversationActions: ConversationActions;
};

/**
 * UnreadItemsFetcher - We display unread counts for all conversation groups in
 * the Inbox. To calculate unread counts we fetch all the unread items in a
 * workspace when the Inbox first loads and rely on SignalR updates to send
 * through new unread items in the background. If the SignalR connection drops
 * at any point however, we revert to polling for all unread items at a regular
 * interval.
 *
 * Behaviour:
 * - Waits until we know if SignalR is either connected or disconnected then
 *   fetch all unread items.
 * - Sets up an interval that ticks every x milliseconds.
 * - If SignalR is disconnected at any point in a refresh interval poll for all
 *   unread items on the next tick.
 * - If the SignalR connection stays connected never poll.
 */
export class UnreadItemsFetcher {
    private refreshRate: number;
    private polling = false;
    private connected = false;
    private hasStarted = false;
    private intervalHandle: ReturnType<typeof setInterval> | null = null;
    private connectionStateFeed: BehaviorSubscribable<ConnectionStateNew>;
    private conversationActions: ConversationActions;
    private onFetchSuccess: (updates: ConversationUpdate[]) => void;
    private readonly subscriptionHandles: Unsubscribable[] = [];

    constructor(args: ConstructorArgs) {
        this.refreshRate = args.refreshRate;
        this.connectionStateFeed = args.connectionStateFeed;
        this.conversationActions = args.conversationActions;
        this.onFetchSuccess = args.onFetchSuccess ? args.onFetchSuccess : noop;
    }

    public start(): void {
        // If start has already been called make sure that we only ever respond
        // to the first call
        if (this.hasStarted) return;
        this.hasStarted = true;

        // Subscribe to connection status changes and synchronise internal state
        // so that we can keep track of wether we should be polling on the next
        // refresh interval.
        const allConnectionEvents = from(this.connectionStateFeed).pipe(
            distinctUntilKeyChanged("state"),
            filter((event) => event.state !== "Initialising"),
        );
        const allEventsHandle = allConnectionEvents.subscribe((event) =>
            this.syncUnreadItemsPollingState(event),
        );

        // Subscribe to the first connection event and:
        // 1. Initially fetch all unread items
        // 2. Setup the refresh interval
        // 3. Enable polling for unread items if SignalR is disconnected
        const firstEvent = allConnectionEvents.pipe(first());
        const firstEventHandle = firstEvent.subscribe(async () => {
            try {
                await this.fetch();
            } catch (e) {
                this.polling = true;
                Log.info("UnreadItemsPolling Initial fetch failed", {
                    originalException: e,
                });
            }

            this.setupUnreadItemsRefreshInterval();
        });

        this.subscriptionHandles.push(allEventsHandle, firstEventHandle);
    }

    public teardown(): void {
        if (this.intervalHandle) {
            clearInterval(this.intervalHandle);
        }
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
    }

    private syncUnreadItemsPollingState({ state }: ConnectionStateNew) {
        Log.debug(`UnreadItemsPolling connection state: ${state}`);

        this.connected = state === "Connected";

        // Enable polling if we just entered a disconnected state
        if (state !== "Connected") {
            Log.debug(`UnreadItemsPolling interval: enabling polling`);
            this.polling = true;
        }
    }

    private setupUnreadItemsRefreshInterval() {
        this.intervalHandle = setInterval(
            () => this.onRefreshIntervalTick(),
            this.refreshRate,
        );
    }

    private async onRefreshIntervalTick() {
        Log.debug(`UnreadItemsPolling interval tick`, {
            tags: {
                polling: this.polling,
                connected: this.connected,
                refreshRate: this.refreshRate,
            },
        });

        // If polling is not enabled then we know that SignalR connection hasn't
        // dropped since the last refresh interval and there's nothing to do.
        if (!this.polling) {
            return;
        }

        // Cache the connected status before we fetch unread items. We want to
        // know if we're connected before we fetch because there is an edge case
        // where SignalR reconnects while we're fetching. In that case we may
        // have missed some SignalR updates while fetching and should not switch
        // off polling.
        const wasConnectedBeforeFetching = this.connected;

        // Try to fetch all unread items:
        // - If the call is successful and we are now back in a connected state then disable polling.
        // - If the call is successful and we're still disconnected then keep polling enabled.
        // - If the call was unsuccessful then keep polling enabled
        try {
            await this.fetch();

            Log.debug(
                `UnreadItemsPolling interval: successfully fetched unread items`,
            );

            // If we are now back in a connected state then we can switch off polling.
            if (wasConnectedBeforeFetching) {
                Log.debug(`UnreadItemsPolling interval: disabling polling`);
                this.polling = false;
            }
        } catch (e) {
            Log.info("UnreadItemsPolling refresh fetch failed", {
                originalException: e,
            });
        }
    }

    private async fetch() {
        const updates = await this.conversationActions.getAllUnreadItems();
        this.onFetchSuccess(updates);
    }
}

export const createUnreadItemsFetcher = (args: ConstructorArgs) => {
    return new UnreadItemsFetcher(args);
};
