import { useEffect, useState } from "react";

import { useParams } from "react-router";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useClinicianConversationSummariesQuery } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { ConversationSummaryProps, View } from "../types";
import { mapPatient } from "./helpers/mapPatient";
import { useClinicianConversationsAnalytics } from "./useClinicianConversationsAnalytics";

type UseClinicianConversationsProps = {
    view: View;
};

type UseClinicianConversationsResult =
    | {
          page: number;
          setPage: (page: number) => void;
          pageSize: number;
          conversationSummaries: ConversationSummaryProps[];
          state: "success";
      }
    | {
          page?: undefined;
          setPage?: undefined;
          pageSize: number;
          conversationSummaries?: undefined;
          state: "loading" | "error";
      };

export const useClinicianConversations: (
    props: UseClinicianConversationsProps,
) => UseClinicianConversationsResult = ({ view }) => {
    const loggedInProps = useFlemingLoggedInAnalytics();
    const {
        trackIntercomConversationsViewed,
        trackIntercomConversationClicked,
    } = useClinicianConversationsAnalytics();

    const pageSize = 15;
    const workspaceId = parseInt(
        useParams<{ workspaceId: string }>().workspaceId,
    );
    const [page, setPage] = useState(1);

    useEffect(() => {
        setPage(1);
    }, [view, setPage]);

    const { isSuccess, isLoading, data } =
        useClinicianConversationSummariesQuery({
            workspaceId,
            assignedToMeOnly: view === "me",
            pageNumber: page,
            pageSize: pageSize + 1,
        });

    if (isLoading) {
        return { state: "loading", pageSize };
    }
    if (!isSuccess) {
        return { state: "error", pageSize };
    }

    const folderName = view === "me" ? "MyConversations" : "All";

    FlemingAnalyticsTracker.trackClinicianConversationsPageView({
        ...loggedInProps,
        folderName: folderName,
        pageNumber: page,
        countConversations: data.length,
    });

    if (data.length > 1) {
        trackIntercomConversationsViewed();
    }

    const conversationSummaries = data
        .map((conversationSummary) => ({
            onClickSummary: () => {
                FlemingAnalyticsTracker.trackClinicianConversationSummaryClick({
                    ...loggedInProps,
                    folderName: folderName,
                    clinicianWorkspaceConversationId: conversationSummary.id,
                });
                trackIntercomConversationClicked();
            },
            ...mapPatient(conversationSummary.patient),
            caseReference: conversationSummary.caseReference,
            workspaceName: "GP Practice",
            senderName: conversationSummary.from,
            messageText: conversationSummary.preview,
            messageDate: conversationSummary.lastUpdated,
            workspaceId,
            workspaceConversationId: conversationSummary.id,
            assigneeName: conversationSummary.assignee?.name,
        }))
        .slice(0, pageSize);

    return { page, setPage, conversationSummaries, state: "success", pageSize };
};
