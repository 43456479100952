import * as UI from "@accurx/design";
import { useInboxParams } from "domains/inbox/hooks/util";
import { i } from "images";

import { StyledContainer } from "./EmptyState.styles";

export type EmptyStateProps = { fadeIn?: boolean };

export const EmptyState = ({ fadeIn = false }: EmptyStateProps) => {
    const { status } = useInboxParams(["status"]);

    const text =
        status === "Open"
            ? "There are no open conversations in this folder"
            : "There are no conversations in this folder";

    return (
        <StyledContainer
            flexDirection="column"
            gap="1.5"
            alignItems="center"
            aria-label={text}
            $fadeIn={fadeIn}
        >
            <img
                src={i("illustrations/empty-inbox-80px@2x.png")}
                srcSet={`${i("illustrations/empty-inbox-80px.png")}, ${i(
                    "illustrations/empty-inbox-80px@2x.png",
                )} 2x, ${i("illustrations/empty-inbox-80px@3x.png")} 3x`}
                alt="Empty inbox"
                height="80"
            />
            <UI.Text colour="metal">{text}</UI.Text>
        </StyledContainer>
    );
};
