import React from "react";

import { Link, Text } from "@accurx/design";
import { Link as RouterLink } from "react-router-dom";

import { StackPanel } from "app/sharedComponents/StackPanel";
import { ROUTES } from "shared/Routes";

export function DeletedPage(): JSX.Element {
    return (
        <StackPanel className="text-center" gutter={2}>
            <Text as="h1" variant="subtitle" skinny className="pt-4">
                This page no longer exists.
            </Text>
            <div>
                <Text>
                    Please head back to the{" "}
                    <RouterLink to={ROUTES.home}>homepage</RouterLink> and start
                    again.
                </Text>
                <Text>
                    If you encounter any issues, chat to us through{" "}
                    <Link
                        href="https://www.accurx.com/contact-us"
                        openInNewTab={true}
                        text="live chat"
                    />{" "}
                    or email us on{" "}
                    <Link
                        href="mailto:support@accurx.com"
                        text="support@accurx.com"
                    />
                    .
                </Text>
            </div>
        </StackPanel>
    );
}
