import { Log } from "@accurx/shared";
import { PayloadAction } from "@reduxjs/toolkit";
import { ConversationGroupFilters } from "domains/concierge/internal/types/ConversationGroupFilters";
import { ConversationGroupSortOptions } from "domains/concierge/internal/types/ConversationGroupSortOptions";
import { ConversationsState } from "domains/concierge/internal/types/ConversationsState";
import { conversationGroupId } from "domains/concierge/internal/util/conversationGroupId";

export const unsubscribeFromGroup = (
    state: ConversationsState,
    action: PayloadAction<{
        filters: ConversationGroupFilters;
        sortOptions: ConversationGroupSortOptions;
    }>,
) => {
    const { filters, sortOptions } = action.payload;
    const id = conversationGroupId(filters, sortOptions);
    const group = state.groups.items[id];
    const loggingInfo = group
        ? {
              product: "Inbox",
              groupName: group.loggingInfo.name,
              ...group.loggingInfo.tags,
          }
        : { product: "Inbox" };

    let subscriberCount = state.groups.subscriberCounts[id] ?? 0;

    // If the group doesn't exist or has no subscribers then something has gone wrong. Log, reset and bail.
    if (!group || subscriberCount <= 0) {
        Log.error(
            "Tried to unsubscribe from a conversation group with zero active subscribers",
            { tags: loggingInfo },
        );
        delete state.groups.subscriberCounts[id];
        return state;
    }

    if (subscriberCount > 1) {
        Log.error(
            "Found a conversation group with more than one active subscribers. This could indicate a memory leak.",
            { tags: loggingInfo },
        );
    }

    const cacheEntry = state.groups.cache[id];

    if (!cacheEntry) {
        // If the group is cacheless delete it
        delete state.groups.items[id];
        delete state.groups.subscriberCounts[id];

        return state;
    }

    // Decrement the subscriber count
    subscriberCount--;

    // Add an "unusedSince" stamp so that the group will get
    // garbage collected if it doesn't get used again soon.
    if (subscriberCount <= 0 && cacheEntry.unusedSince === null) {
        cacheEntry.unusedSince = new Date().toISOString();
    }

    // Don't bother keeping a subscriber count if it's zero
    if (subscriberCount <= 0) {
        delete state.groups.subscriberCounts[id];
    } else {
        state.groups.subscriberCounts[id] = subscriberCount;
    }

    return state;
};
