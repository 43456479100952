import { Link, Text } from "@accurx/design";

import { StyledContainer } from "app/layout/Container";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";

export const AllReportsPage = () => {
    return (
        <StyledContainer>
            <PageHeader title="Reporting" type={PageHeaderType.ListPage} />

            <Text skinny>
                We are taking a new approach to reporting, please let us know
                what you think by providing feedback{" "}
                <Link href="https://forms.gle/h2P81jGx9Xp4GFtq6" openInNewTab>
                    here
                    <Link.Icon />
                </Link>
            </Text>
        </StyledContainer>
    );
};
