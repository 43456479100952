import React, { useEffect } from "react";

import { Feedback, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Redirect } from "react-router";

import { useAnalytics } from "app/analytics/hooks";
import {
    useCurrentOrganisation,
    useCurrentUser,
    useModules,
    usePractices,
} from "app/organisations/hooks";
import { Container } from "app/organisations/shared/Container";
import { ROUTES_CHAIN } from "shared/Routes";

import {
    StyledContent,
    StyledHeader,
    StyledOrganisationGrid,
    StyledOrganisationProducts,
    StyledUnapprovedFeedback,
} from "./HomePage.styles";
import {
    AccuBookProductCard,
    AppointmentRemindersMarketingCard,
    AppointmentRemindersProductCard,
    FloreyPlusMarketingCard,
    MessagingProductCard,
    OrganisationCard,
    PatientTriageMarketingCard,
    PatientTriageProductCard,
} from "./components";
import { ReportingProductCard } from "./components/ReportingProductCard/ReportingProductCard";

export const HomePage = (): JSX.Element => {
    useAccurxWebTitle("Manage organisation");

    const org = useCurrentOrganisation();
    const practices = usePractices();
    const user = useCurrentUser();
    const modules = useModules();

    const { track } = useAnalytics();

    useEffect(() => {
        track("HomepagePageView", {
            withPatientTriage: !!modules?.patientTriage,
            withAppointmentReminders: !!modules?.batchAndAppointmentReminders,
            withCustomFloreys: !!modules?.floreyPlus,
        });
    }, [track, modules, user?.isApproved]);

    if (!org) return <Redirect to={ROUTES_CHAIN.practices} />;

    // if the practices endpoint hasn't finished yet, wait till it has
    if (!practices.selectedPractice) return <></>;

    return (
        <>
            <StyledHeader>
                <Container>
                    <Text variant="subtitle" as="h1" skinny colour="white">
                        {org.organisationName}
                    </Text>
                </Container>
            </StyledHeader>
            <StyledContent>
                <Container>
                    <StyledOrganisationGrid>
                        {user?.isApproved && (
                            <div>
                                <OrganisationCard />
                            </div>
                        )}
                        <StyledOrganisationProducts>
                            {!user?.isApproved && (
                                <StyledUnapprovedFeedback>
                                    <Feedback
                                        colour="warning"
                                        title="You are currently an unapproved user"
                                    >
                                        <Text>
                                            This means you won't have access to
                                            certain Accurx features. Please ask
                                            an Admin User to approve you.
                                        </Text>
                                    </Feedback>
                                </StyledUnapprovedFeedback>
                            )}
                            <MessagingProductCard />
                            {modules?.patientTriage && user?.isApproved && (
                                <PatientTriageProductCard />
                            )}
                            {modules?.batchAndAppointmentReminders &&
                                user?.isApproved && (
                                    <AppointmentRemindersProductCard />
                                )}

                            {user?.isApproved &&
                                (modules?.accubook.organisation ||
                                    modules?.accubook.deliverySite) && (
                                    <AccuBookProductCard />
                                )}
                            {modules?.reporting && user?.isApproved && (
                                <ReportingProductCard />
                            )}

                            {!modules?.floreyPlus && (
                                <FloreyPlusMarketingCard />
                            )}
                            {!modules?.patientTriage && (
                                <PatientTriageMarketingCard />
                            )}
                            {!modules?.batchAndAppointmentReminders && (
                                <AppointmentRemindersMarketingCard />
                            )}
                        </StyledOrganisationProducts>
                    </StyledOrganisationGrid>
                </Container>
            </StyledContent>
        </>
    );
};

export default HomePage;
