import { ReactNode } from "react";

import * as UI from "@accurx/design";

export const InfoBoxText = ({
    colour,
    children,
}: {
    colour: "night" | "metal";
    children: ReactNode;
}) => {
    return (
        <UI.Text skinny colour={colour} variant="preview">
            {children}
        </UI.Text>
    );
};
