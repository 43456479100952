import { createSlice } from "@reduxjs/toolkit";
import { UsersState } from "domains/concierge/internal/types/UsersState";

import * as extraReducers from "./extraReducers";

export const initialState: UsersState = {
    items: {},
};

export const users = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers,
});
