import { PatientNoteTagsWithTokenRequest } from "@accurx/api/ticket";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import {
    PatientNoteTags,
    PatientThreadTicketCommandResult,
    UnreadTickets,
} from "./FlemingDtos";

const ENDPOINTS = {
    notificationSummary: "/api/conversation/web/notificationSummary",
    markNoteRead: "/api/conversation/web/marknoteread",
    markNoteReadWithToken: "/api/conversation/web/marknotereadwithtoken",
    markNoteReadUnread: "/api/conversation/web/marknoteunread",
} as const;

export const getUnreadTickets = (
    organisationId: number,
): Promise<IWrappedResult<UnreadTickets>> => {
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/conversation/web/unreadtickets",
            query: `?organisationId=${organisationId}`,
        }),
    );
};
/**
 * Marks the notes provided in the request as read, if not already
 * done so, and returns the updated items.
 * Expects patient identity to be supplied, low trust sessions
 * (without 2fa) will be blocked from making this call and
 * must use the token variant instead after a patient search.
 */
export const postMarkNoteRead = (
    request: PatientNoteTags,
): Promise<IWrappedResult<PatientThreadTicketCommandResult>> => {
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteRead,
        }),
        request,
    );
};

/**
 * Marks the notes provided in the request as read, if not already
 * done so, and returns the updated items.
 * The patient token is supplied from an earlier patient
 * search within the session.
 */
export const postMarkNoteReadWithToken = (
    // need to change the request object
    request: PatientNoteTagsWithTokenRequest,
): Promise<IWrappedResult<PatientThreadTicketCommandResult>> => {
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteReadWithToken,
        }),
        request,
    );
};

/**
 * Marks the notes provided in the request as read, if not already
 * done so, and returns the updated items.
 * Expects patient identity to be supplied, low trust sessions
 * (without 2fa) will be blocked from making this call and
 * must use the token variant instead after a patient search.
 */
export const postMarkNoteUnread = (
    request: PatientNoteTags,
): Promise<IWrappedResult<PatientThreadTicketCommandResult>> => {
    return httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteReadUnread,
        }),
        request,
    );
};
