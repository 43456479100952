/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import {
    Button,
    Card,
    FormFieldFeedback,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    Tokens,
} from "@accurx/design";

import { Settings, switchOptionNames } from "../../constants";
import { PatientTriageStatus } from "../../types";
import { StyledLeftSideBorderedBlock } from "./LeftSideBorderedBlock.styles";
import { StyledReference } from "./Reference.styles";
import { StyledSubtitle } from "./Subtitle.styles";

export type RepeatPrescriptionsServiceFormPropsType = {
    status: PatientTriageStatus;
    triageSettingsUpdatingStatus: any;
    isPrescriptionEditing: boolean;
    handleUpdatePrescriptionSettings: (
        event: React.FormEvent<HTMLFormElement>,
    ) => void;
    handleRepeatPrescriptionEdit: () => void;
    handleRepeatPrescriptionCancel: () => void;
    handleSwitchOptions: (
        event: React.ChangeEvent<HTMLInputElement>,
        name: switchOptionNames,
    ) => void;
    urlError: boolean;
    isUrlEmpty?: boolean;
    isTitleEmpty?: boolean;
    handleUpdateString: (event: any, name: string) => void;
    isPrescriptionEnabled: boolean;
    isPrescriptionRedirectEnabled: boolean;
    isPrescriptionFormEnabled: boolean;
    prescriptionRedirectTitle: string;
    prescriptionRedirectUrl: string;
};

const ERROR_EMPTY_TITLE = "You need to provide a service name.";
const ERROR_EMPTY_URL = "You need to provide a link to the service.";
const ERROR_WRONG_URL = "You need to provide a valid url.";

export const RepeatPrescriptionsServiceForm = ({
    status,
    triageSettingsUpdatingStatus,
    isPrescriptionEditing,
    handleUpdatePrescriptionSettings,
    handleRepeatPrescriptionEdit,
    handleRepeatPrescriptionCancel,
    handleSwitchOptions,
    urlError,
    isUrlEmpty,
    isTitleEmpty,
    handleUpdateString,
    isPrescriptionEnabled,
    isPrescriptionRedirectEnabled,
    prescriptionRedirectTitle,
    prescriptionRedirectUrl,
    isPrescriptionFormEnabled,
}: RepeatPrescriptionsServiceFormPropsType): JSX.Element => {
    const isDisabled =
        status.isAdmin === false || triageSettingsUpdatingStatus.updating;

    const urlErrorText = urlError ? ERROR_WRONG_URL : ERROR_EMPTY_URL;

    const switchStateIndicator = (isTrue?: boolean) => {
        return (
            <Text>
                {isTrue ? (
                    <Icon name="Tick" size={3} colour="green" />
                ) : (
                    <Icon name="Cross" size={3} colour="red" />
                )}
                {isTrue ? "On" : "Off"}
            </Text>
        );
    };

    return (
        <section>
            <Text as="h2" variant="subtitle">
                Repeat prescriptions
            </Text>
            <form onSubmit={(e): void => handleUpdatePrescriptionSettings(e)}>
                <Card
                    props={{ className: "mb-3" }}
                    header={
                        <Text as="h3" skinny variant="subtitle">
                            Settings
                        </Text>
                    }
                    footer={
                        <div className="d-flex justify-content-end">
                            {!isPrescriptionEditing && (
                                <Button
                                    disabled={isDisabled}
                                    dimension="medium"
                                    theme="primary"
                                    icon={{ name: "Pencil" }}
                                    onClick={handleRepeatPrescriptionEdit}
                                    data-testid="repeatPrescriptionsServiceEdit"
                                    text="Edit"
                                    type="button"
                                />
                            )}

                            {isPrescriptionEditing && (
                                <>
                                    <Button
                                        disabled={isDisabled}
                                        type="submit"
                                        text="Save changes"
                                        data-testid="repeatPrescriptionsServiceSave"
                                    />
                                    <Button
                                        className="ml-3"
                                        dimension="medium"
                                        theme="transparent"
                                        onClick={handleRepeatPrescriptionCancel}
                                        data-testid="repeatPrescriptionsServiceCancel"
                                        text="Cancel"
                                        type="button"
                                    />
                                </>
                            )}
                        </div>
                    }
                >
                    <Text>
                        Allows patients to request repeat prescriptions from
                        your practice or an existing prescriptions service.
                    </Text>
                    <div className="mt-3">
                        <Text variant="label">
                            Show repeat prescription option to patients
                        </Text>

                        {isPrescriptionEditing && (
                            <Switch
                                defaultChecked={isPrescriptionEnabled}
                                labelText={isPrescriptionEnabled ? "Yes" : "No"}
                                onChange={(e): void => {
                                    handleSwitchOptions(
                                        e,
                                        switchOptionNames.switchRepeatPrescription,
                                    );
                                }}
                                testId="prescriptionsSwitch"
                            />
                        )}

                        {!isPrescriptionEditing &&
                            switchStateIndicator(isPrescriptionEnabled)}
                    </div>

                    {isPrescriptionEnabled && (
                        <div className="mt-3">
                            <Text variant="label">
                                Redirect to an existing service
                            </Text>

                            {isPrescriptionEditing && (
                                <Switch
                                    testId="redirectSwitch"
                                    defaultChecked={
                                        isPrescriptionRedirectEnabled
                                    }
                                    labelText={
                                        isPrescriptionRedirectEnabled
                                            ? "Yes"
                                            : "No"
                                    }
                                    onChange={(e): void => {
                                        handleSwitchOptions(
                                            e,
                                            switchOptionNames.switchPrescriptionRedirect,
                                        );
                                    }}
                                />
                            )}
                            {!isPrescriptionEditing &&
                                switchStateIndicator(
                                    isPrescriptionRedirectEnabled,
                                )}
                        </div>
                    )}

                    {isPrescriptionEnabled && isPrescriptionRedirectEnabled && (
                        <StyledLeftSideBorderedBlock className="mt-3 p-3">
                            <StyledSubtitle className="mb-4">
                                Repeat prescriptions redirect
                            </StyledSubtitle>

                            <Text variant="label" className="mb-3">
                                How it appears for patients:
                            </Text>

                            <StyledLeftSideBorderedBlock
                                className="mb-4"
                                background={Tokens.COLOURS.greyscale.cloud}
                                data-testid="messageTemplate"
                            >
                                <Text variant="label">
                                    Did you know your practice has a repeat
                                    prescriptions service?
                                </Text>
                                <Text>
                                    If you are registered with{" "}
                                    {prescriptionRedirectTitle}{" "}
                                    <StyledReference>[1]</StyledReference>, or
                                    would like to be, please{" "}
                                    <Link
                                        href={prescriptionRedirectUrl}
                                        text="request a repeat prescription here"
                                    />{" "}
                                    <StyledReference>[2]</StyledReference>.
                                    {isPrescriptionFormEnabled && (
                                        <>
                                            Otherwise you can give details of
                                            your repeat prescription request
                                            below.
                                        </>
                                    )}
                                </Text>
                            </StyledLeftSideBorderedBlock>

                            <div className="mb-4">
                                <div>
                                    <Text
                                        variant="label"
                                        as="label"
                                        props={{
                                            htmlFor:
                                                "prescriptionRedirectTitle",
                                        }}
                                    >
                                        Service name
                                    </Text>{" "}
                                    <StyledReference>[1]</StyledReference>
                                </div>
                                {!isPrescriptionEditing && (
                                    <Text>{prescriptionRedirectTitle}</Text>
                                )}

                                {isPrescriptionEditing && (
                                    <>
                                        <Text>
                                            This will be shown to patients when
                                            they select the option.
                                        </Text>
                                        <Input
                                            disabled={isDisabled}
                                            name="prescriptionRedirectTitle"
                                            id="prescriptionRedirectTitle"
                                            defaultValue={
                                                prescriptionRedirectTitle
                                            }
                                            type="text"
                                            onChange={(e): void => {
                                                handleUpdateString(
                                                    e,
                                                    Settings.PRESCRIPTION_TITLE,
                                                );
                                            }}
                                            hasErrors={isTitleEmpty}
                                        />
                                        {isTitleEmpty && (
                                            <FormFieldFeedback
                                                className="mt-1"
                                                text={ERROR_EMPTY_TITLE}
                                                variant="error"
                                            />
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="mb-4">
                                <div>
                                    <Text
                                        variant="label"
                                        as="label"
                                        props={{
                                            htmlFor: "prescriptionRedirectUrl",
                                        }}
                                    >
                                        Service link
                                    </Text>{" "}
                                    <StyledReference>[2]</StyledReference>
                                </div>
                                {!isPrescriptionEditing && (
                                    <Link
                                        href={prescriptionRedirectUrl}
                                        text={prescriptionRedirectUrl}
                                    />
                                )}

                                {isPrescriptionEditing && (
                                    <>
                                        <Text>
                                            The is where patients will be
                                            redirect to. Make sure to include
                                            the http:// or https:// at the start
                                            of your link.
                                        </Text>

                                        <Input
                                            disabled={isDisabled}
                                            name="prescriptionRedirectUrl"
                                            id="prescriptionRedirectUrl"
                                            defaultValue={
                                                prescriptionRedirectUrl
                                            }
                                            type="text"
                                            onChange={(e): void => {
                                                handleUpdateString(
                                                    e,
                                                    Settings.PRESCRIPTION_LINK,
                                                );
                                            }}
                                            hasErrors={urlError || isUrlEmpty}
                                        />

                                        {(urlError || isUrlEmpty) && (
                                            <FormFieldFeedback
                                                className="mt-1"
                                                text={urlErrorText}
                                                variant="error"
                                            />
                                        )}
                                    </>
                                )}
                            </div>

                            <div>
                                <Text variant="label">
                                    Allow patient to submit a request using
                                    Accurx
                                </Text>
                                {!isPrescriptionEditing &&
                                    switchStateIndicator(
                                        isPrescriptionFormEnabled,
                                    )}

                                {isPrescriptionEditing && (
                                    <Switch
                                        defaultChecked={
                                            isPrescriptionFormEnabled
                                        }
                                        labelText={
                                            isPrescriptionFormEnabled
                                                ? "Yes"
                                                : "No"
                                        }
                                        onChange={(e): void => {
                                            handleSwitchOptions(
                                                e,
                                                switchOptionNames.switchPrescriptionForm,
                                            );
                                        }}
                                        testId="formSwitch"
                                    />
                                )}
                            </div>
                        </StyledLeftSideBorderedBlock>
                    )}
                </Card>
            </form>
        </section>
    );
};
