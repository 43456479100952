import { PracticeActivityResponse } from "@accurx/api/clinician-messaging";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

type WorkspaceActivityArgs = {
    currentWorkspaceId: number;
    workspaceIdToQuery?: number;
};

export const useWorkspaceActivityQuery = ({
    currentWorkspaceId,
    workspaceIdToQuery,
}: WorkspaceActivityArgs) => {
    return useQuery(["WorkspaceActivity", { workspaceIdToQuery }], async () => {
        if (!workspaceIdToQuery) return null;
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: "/api/clinicianmessaging/PracticeInformation/workspaces/:workspaceId/activity",
                query: `?organisationId=${currentWorkspaceId}`,
                params: { workspaceId: `${workspaceIdToQuery}` },
            }),
        );
        if (response.success === true && response.result !== null) {
            return response.result as PracticeActivityResponse;
        }

        throw new Error(response.error ?? "Failed to fetch");
    });
};
