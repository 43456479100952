import { useQuery } from "@tanstack/react-query";
import { useWithCareProvidersWorkspaceUnreadCount } from "domains/concierge/hooks/data/useWithCareProvidersWorkspaceUnreadCount";
import { useWithPatientsWorkspaceUnreadCount } from "domains/concierge/hooks/data/useWithPatientsWorkspaceUnreadCount";
import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";

/**
 * Query for the total count of unread conversations for
 * the current user in the current workspace.
 *
 * This count can be fetched from one of two places, depending on
 * whether the user is trusted/2FA'd or not and whether the `/initialunreaditems`
 * endpoint has returned.
 *
 * When the Concierge layer first loads, this query
 * fetches the unread counts from the `/unreadtickets`
 * endpoint regardless of whether the device is untrusted or trusted.
 * We do this because in many cases this endpoint, which returns only
 * IDs rather than full items + ticket summaries, can be significantly
 * faster than `/initialunreaditems`. As such it gives trusted clients
 * a better first load experience, in addition to giving untrusted
 * clients their unread counts without requiring 2FA.
 *
 * After the `/initialunreaditems` endpoint has completed, the
 * behaviour diverges for trusted and non-trusted devices.
 *
 * For trusted devices, we can rely on `/initialunreaditems` having
 * populated the state, and as such the unread counts in the state
 * should be correct. So from this point on, we instead return the
 * unread counts from the Redux state.
 *
 * For non-trusted devices, we continue to poll the `/unreadtickets` endpoint
 * every 15 minutes.
 */
export const useWorkspaceUnreadCountsQuery = ({
    hasClinicianMessagingInbox,
}: {
    hasClinicianMessagingInbox: boolean;
}): {
    unreadCount: number;
} => {
    const { workspaceId, isTrustedDevice } = useConciergeMeta();

    const withPatientsWorkspaceUnreadCount =
        useWithPatientsWorkspaceUnreadCount();
    const withCareProvidersWorkspaceUnreadCount =
        useWithCareProvidersWorkspaceUnreadCount();

    // Trusted devices should be able to rely on the concierge layer for unread
    // counts. Only fetch unreads from the API on non-trusted devices OR when
    // the Concierge layer is still loading OR when the concierge has failed to
    // hydrate unread counts.
    const fallBackToPollingTicket =
        !isTrustedDevice ||
        withPatientsWorkspaceUnreadCount.status !== "success";

    const query = useQuery({
        queryKey: ["WorkspaceUnreadCounts", { workspaceId }],
        queryFn: async () => api.getUnreadTickets(workspaceId),
        enabled: fallBackToPollingTicket,
        refetchInterval: 1000 * 60 * 15,
    });

    const fallbackPatientUnreadCount =
        query.data?.currentUserAndGroupsUnreadTicketCount ?? 0;
    const conciergePatientUnreadCount =
        withPatientsWorkspaceUnreadCount.unreadCount;
    const conciergeCareProvidersUnreadCount =
        withCareProvidersWorkspaceUnreadCount.unreadCount;

    if (fallBackToPollingTicket) {
        // With clinician messaging inbox wait for both the ticket and clinician
        // messaging unread counts to load. Otherwise only wait for the ticket
        // unread counts.
        const isReady = hasClinicianMessagingInbox
            ? withCareProvidersWorkspaceUnreadCount.status === "success" &&
              query.status === "success"
            : query.status === "success";

        // With clinician messaging inbox show the sum of ticket and clinician
        // messaging unread counts. Otherwise only show the ticket unread count.
        const unreadCount = hasClinicianMessagingInbox
            ? conciergeCareProvidersUnreadCount + fallbackPatientUnreadCount
            : fallbackPatientUnreadCount;

        return { unreadCount: isReady ? unreadCount : 0 };
    }

    // With clinician messaging inbox wait for both the ticket and clinician
    // messaging unread counts to load. Otherwise only wait for the ticket
    // unread counts.
    const isReady = hasClinicianMessagingInbox
        ? withCareProvidersWorkspaceUnreadCount.status === "success" &&
          withPatientsWorkspaceUnreadCount.status === "success"
        : withPatientsWorkspaceUnreadCount.status === "success";

    // With clinician messaging inbox show the sum of ticket and clinician
    // messaging unread counts. Otherwise only show the ticket unread count.
    const totalConciergeUnreadCount = hasClinicianMessagingInbox
        ? conciergePatientUnreadCount + conciergeCareProvidersUnreadCount
        : conciergePatientUnreadCount;

    return {
        unreadCount: isReady ? totalConciergeUnreadCount : 0,
    };
};
