import React from "react";

import { PatientDetails } from "api/FlemingDtos";

import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

type PatientDetailsData =
    | "name"
    | "mobileTelephone"
    | "homeTelephone"
    | "workTelephone"
    | "address";

export type PatientDetailsCardProps = Pick<PatientDetails, PatientDetailsData>;

export const PatientDetailsFull = ({
    name,
    mobileTelephone,
    homeTelephone,
    workTelephone,
    address,
}: PatientDetailsCardProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title="Patient contact details">
            <CardRowStack>
                <CardRow
                    type={CardRowType.Default}
                    label="Name"
                    title={name}
                    emphasise
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Mobile"
                    title={mobileTelephone}
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Home"
                    title={homeTelephone}
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Work"
                    title={workTelephone}
                />
                <CardRow
                    type={CardRowType.Default}
                    label="Address"
                    title={address}
                />
            </CardRowStack>
        </MedicalRecordPanelCard>
    );
};
