import { Patient } from "@accurx/api/portal";
import { Feedback, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { UncontrolledTooltip } from "reactstrap";

import { PatientListAppointment } from "api/FlemingDtos";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { ROOM_LINKS_SENT_BEFORE_APPT_HOURS } from "shared/Templates";
import { useAppSelector } from "store/hooks";

import PatientEntryVideoStatus from "../../patientLists/PatientEntryVideoStatusComponent";
import { generateGreeting } from "./SendMessageFormComponent";

export interface FeedbackMessagesComponentProps {
    isVideoConsult: boolean;
    appointment?: PatientListAppointment;
}

type AppointmentReminderMessageProps = {
    organisationName: string;
    patient: Patient | null;
    appointmentDate: string;
    appointmentTime: string;
};

const AppointmentReminderMessage = ({
    organisationName,
    patient,
    appointmentDate,
    appointmentTime,
}: AppointmentReminderMessageProps) => (
    <Text as="span">
        As well as the below message, they will receive a reminder and link to
        join {ROOM_LINKS_SENT_BEFORE_APPT_HOURS} hours before the call
        <br />
        <Text
            as="span"
            colour="zinc"
            variant="link"
            props={{ id: "ReminderMessage" }}
        >
            View appointment reminder message
        </Text>
        <UncontrolledTooltip target="ReminderMessage" placement="bottom">
            <Text colour="white" variant="preview">
                {generateGreeting(patient)}
            </Text>
            <Text colour="white" variant="preview">
                Here's a link to your Video Consult appointment with
                {organisationName} on {appointmentDate} at {appointmentTime}.
            </Text>
            <Text colour="white" variant="preview">
                To join, please follow this link: [link will appear here]
            </Text>
            <Text colour="white" variant="preview">
                {organisationName}
            </Text>
        </UncontrolledTooltip>
    </Text>
);

const isPatientAlreadyInvitedToVideoConsult = (
    appointment: PatientListAppointment | undefined,
    isInvitingToVideoConsult: boolean,
): appointment is PatientListAppointment =>
    isInvitingToVideoConsult &&
    appointment !== undefined &&
    (appointment.videoConsultId !== null ||
        appointment.hasSentVideoConsultInvite);

const hasAppointmentBeenScheduled = (
    startDateTime: string | null,
): startDateTime is string => startDateTime !== null;

const isPatientBeingInvitedToVideoConsult = (
    appointment: PatientListAppointment | undefined,
    isInvitingToVideoConsult: boolean,
): appointment is PatientListAppointment =>
    isInvitingToVideoConsult &&
    appointment !== undefined &&
    hasAppointmentBeenScheduled(appointment.dateTimeStart);

/*
 * Component used in the Message Patient & Video Consult invite flows
 * to inform the user about certain information relevant to the patient being invited
 * i.e. is it a dummy patient or whether the patient has already been invited to a video consult
 * */
const FeedbackMessages = ({
    isVideoConsult,
    appointment,
}: FeedbackMessagesComponentProps): JSX.Element => {
    const orgName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    const patient = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatient(searchForPatient.lastResponse),
    );

    return (
        <>
            {isPatientAlreadyInvitedToVideoConsult(
                appointment,
                isVideoConsult,
            ) && (
                <Feedback
                    title="This patient is already invited to a Video Consult"
                    colour="information"
                    props={{ className: "mb-3" }}
                >
                    <PatientEntryVideoStatus
                        videoConsultId={appointment.videoConsultId}
                        videoConsultUrl={appointment.videoConsultUrl}
                        hasSentVideoConsultInvite={
                            appointment.hasSentVideoConsultInvite
                        }
                    />
                </Feedback>
            )}

            {isPatientBeingInvitedToVideoConsult(appointment, isVideoConsult) &&
                hasAppointmentBeenScheduled(appointment.dateTimeStart) && (
                    <Feedback
                        title={`Video Consult for ${DateHelpers.formatTime(
                            appointment.dateTimeStart,
                            DateFormatOptions.TIME,
                        )} on ${DateHelpers.formatDate(
                            appointment.dateTimeStart,
                            DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                        )}`}
                        colour="information"
                        props={{ className: "mb-3" }}
                    >
                        <AppointmentReminderMessage
                            organisationName={orgName}
                            patient={patient}
                            appointmentDate={DateHelpers.formatDate(
                                appointment.dateTimeStart,
                                DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                            )}
                            appointmentTime={DateHelpers.formatTime(
                                appointment.dateTimeStart,
                                DateFormatOptions.TIME,
                            )}
                        />
                    </Feedback>
                )}
        </>
    );
};

export default FeedbackMessages;
