import { useCallback, useEffect, useMemo, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { FeatureName } from "@accurx/auth";
import {
    BATCH_OVERVIEW_PAGE_SIZE,
    BatchMessageSummary,
    useSentBatchMessages,
} from "@accurx/batch-messaging";
import { Ds, Feedback, Flex, Pagination } from "@accurx/design";

import { useIsFeatureEnabled } from "store/hooks";

import {
    BatchMessageTableWrapper,
    PaginationWrapper,
} from "./BatchMessageStatuses.styles";
import { BatchMessageTable } from "./BatchMessageTable";

type BatchMessageStatusesProps = {
    workspaceId: string;

    // This flag is needed for getting new data after new batch is sent
    // because we redirect to the Overview page before the send request is complete
    // GP flow only
    confirmMessageSuccess?: boolean;
    pageOrigin: "AccurxWeb" | "Practices";
};

export const BatchMessageStatuses = ({
    workspaceId,
    confirmMessageSuccess,
    pageOrigin,
}: BatchMessageStatusesProps) => {
    // Feature flags
    const canUseEmisGPSoCServer = useIsFeatureEnabled(
        FeatureName.EmisGPSoCServer,
    );
    const waitingListValidationEnabled = useIsFeatureEnabled(
        FeatureName.WebWaitingListValidation,
    );

    const track = useAnalytics({ workspaceId: parseInt(workspaceId) });

    const [pageTokens, setPageTokens] = useState<Array<string | null>>([""]);
    const [currentPageToken, setCurrentPageToken] = useState("");

    const { data, refetch, isFetching, isError } = useSentBatchMessages({
        workspaceId,
        continuationToken: currentPageToken,
    });

    const refetchSentBatchMessages = useCallback(
        (withReset = true) => {
            if (withReset && currentPageToken !== "") {
                setPageTokens([""]);
                setCurrentPageToken("");
            } else {
                refetch();
            }
        },
        [refetch, currentPageToken],
    );

    useEffect(() => {
        if (confirmMessageSuccess) {
            refetchSentBatchMessages();
        }
    }, [confirmMessageSuccess, refetchSentBatchMessages]);

    useEffect(() => {
        if (
            !isFetching &&
            data &&
            !pageTokens.includes(data.continuationToken || null)
        ) {
            setPageTokens((prevPageTokens) => [
                ...prevPageTokens,
                data.continuationToken || null,
            ]);
        }
    }, [isFetching, data, pageTokens]);

    const getNextPage = () => {
        if (data?.continuationToken) {
            track("BatchOverviewPageChange Button Click", {
                pageSize: BATCH_OVERVIEW_PAGE_SIZE,
                pageOrigin,
                currentPageNumber: pageTokens.length - 1,
                newPageNumber: pageTokens.length,
            });
            setCurrentPageToken(data.continuationToken);
        }
    };
    const getPreviousPage = () => {
        track("BatchOverviewPageChange Button Click", {
            pageSize: BATCH_OVERVIEW_PAGE_SIZE,
            pageOrigin,
            currentPageNumber: pageTokens.length - 1,
            newPageNumber: pageTokens.length - 2,
        });
        setCurrentPageToken(pageTokens[pageTokens.length - 3] as string);
        setPageTokens(pageTokens.slice(0, pageTokens.length - 1));
    };

    const [summaries, messageWaitingToStartWriting] = useMemo(() => {
        let calculatedSummaries: BatchMessageSummary[] = [];
        let calculatedFlag = false;

        if (data?.summaries && data.summaries.length > 0) {
            calculatedSummaries = data.summaries?.filter(
                (summary) =>
                    !(
                        summary.status === "FetchingDemographics" ||
                        summary.status === "DemographicsPopulated"
                    ),
            );
            calculatedFlag = calculatedSummaries.some(
                (summary) =>
                    summary.status === "Dispatched" &&
                    summary.totalWriteSuccess === 0 &&
                    summary.totalSent > 0,
            );
        }

        return [calculatedSummaries, calculatedFlag];
    }, [data]);

    if (isFetching) {
        return (
            <Flex justifyContent="center">
                <Ds.Spinner />
            </Flex>
        );
    }
    if (isError) {
        return (
            <Feedback
                colour="error"
                title="Something went wrong loading your messages"
            />
        );
    }

    return (
        <BatchMessageTableWrapper>
            <BatchMessageTable
                practiceId={workspaceId}
                refetch={refetchSentBatchMessages}
                summaries={summaries}
                showSavingToRecordWarning={
                    canUseEmisGPSoCServer === false &&
                    messageWaitingToStartWriting === true &&
                    waitingListValidationEnabled === false
                }
            />
            {pageTokens.length > 1 && (
                <PaginationWrapper>
                    <Pagination>
                        <Pagination.Navigation
                            currentPage={pageTokens.length - 1}
                        >
                            {pageTokens.length > 2 && (
                                <Pagination.PreviousButton
                                    onPrevious={getPreviousPage}
                                />
                            )}
                            {pageTokens[pageTokens.length - 1] && (
                                <Pagination.NextButton onNext={getNextPage} />
                            )}
                        </Pagination.Navigation>
                    </Pagination>
                </PaginationWrapper>
            )}
        </BatchMessageTableWrapper>
    );
};
