import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

export const StyledPillText = styled(Pill.Text)`
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;

    em {
        font-weight: bold;
        font-style: normal;
    }

    // To guard against really long assignee names we set a max width and make
    // it slightly larger on larger screens
    max-width: 300px;
    @media (max-width: 1380px) {
        max-width: 200px;
    }
`;

export const StyledAssigneeLabel = styled.span`
    @media (max-width: 1230px) {
        display: none;
    }
`;
