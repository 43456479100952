import { useMemo } from "react";

import { mapConversationIdToConversationSource } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToConversationSource";
import { Conversation } from "domains/concierge/types";

export const useConversationSource = ({
    conversationId,
}: {
    conversationId: Conversation["id"];
}) => {
    return useMemo(
        () => mapConversationIdToConversationSource(conversationId),
        [conversationId],
    );
};
