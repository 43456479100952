import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { UploadPatientFileResponse } from "../types";

export interface PatientsFileUploadMutationVariables {
    file: File;
    workspaceId: number;
    isEmailEnabled: boolean;
}

export const usePatientsFileUploadMutation = (
    options: UseMutationOptions<
        UploadPatientFileResponse,
        Error,
        PatientsFileUploadMutationVariables
    > = {},
) =>
    useMutation({
        mutationKey: ["patients-file-upload"],
        ...options,
        mutationFn: async ({ file, workspaceId, isEmailEnabled }) => {
            const formData = new FormData();
            formData.append("formFile", file);
            formData.append("batchEmailFlow", `${isEmailEnabled}`);

            const response: IWrappedResult<UploadPatientFileResponse> =
                await httpClient.postFileSafeAsync(
                    getApiEndpoint({
                        path: "patientMessaging/batchMessaging/:workspaceId/UploadPatientFile",
                        params: { workspaceId: workspaceId.toString() },
                    }),
                    formData,
                );

            if (response.success && response.result !== null) {
                return response.result;
            }
            throw new Error(response.error ?? "Failed to upload file");
        },
    });
