/**
 * Parses a workspaceId or organisation ID from a string to a number
 * This conversion is needed when parsing a workspace ID or organisation ID from a URL
 * Return null if id wasn't provided, or parsing resulted with NaN
 */
export const parseUrlId = (id?: string) => {
    if (id) {
        const parsedId = parseInt(id, 10);

        if (!Number.isNaN(parsedId)) {
            return parsedId;
        }
    }

    return null;
};
