import * as React from "react";

import { Text } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";

import { SsoErrorCard } from "./SsoErrorCard";

export const SsoErrorUnableToLookupOds = () => (
    <SsoErrorCard
        header="We couldn't match your email to a current NHS organisation"
        body={
            <>
                <Text>
                    We are sorry something went wrong. We couldn't look up your
                    NHS organisation.
                </Text>

                <Text>
                    So we can finish getting you set up please email us{" "}
                    <a href="mailto:support@accurx.com">here</a> with your role,
                    organisation, and organisation code.
                </Text>

                <Text>
                    If you would like to learn more about Accurx Web whilst we
                    handle your request{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={SharedUrls.FlemingWebPage}
                    >
                        please visit our website.
                    </a>
                </Text>

                <Text>Thanks, The Accurx team</Text>
            </>
        }
    />
);
