import { Fragment, ReactNode, useState } from "react";

import { Button, Text } from "@accurx/design";

import { MISSING_DATA_TEXT } from "../RecordView.constant";
import { CardSpacer } from "./CardSpacer";

export enum CardRowType {
    Default = "default",
    DefaultWithComments = "defaultWithComments",
    Investigation = "investigation",
}

type BaseRow = {
    label: string | null;
    /** [optional] unique test id to find the row by when unit testing */
    "data-testid"?: string;
};

type DefaultRow = BaseRow & {
    type: CardRowType.Default;
    title: string | null;
    emphasise?: boolean;
};

type DefaultWithCommentsRow = BaseRow & {
    type: CardRowType.DefaultWithComments;
    title: string | null;
    comments: string | string[];
    /** [optional] defaults to false. If set to true, comments will appear as preview typography variant */
    captionComments?: boolean;
};

type SubInvestigation = Pick<InvestigationRow, "label" | "title" | "comments">;
type InvestigationRow = BaseRow & {
    type: CardRowType.Investigation;
    title: string | null;
    comments: string | null;
    value: string | null;
    subItems: SubInvestigation[];
};

export type CardRowProps =
    | DefaultRow
    | DefaultWithCommentsRow
    | InvestigationRow;

const hasValidMeasurements = (value: string | null): value is string =>
    value !== null;

type NonEmptyArray<T> = [T, ...T[]];
const hasSubInvestigations = (
    value: SubInvestigation[],
): value is NonEmptyArray<SubInvestigation> =>
    Array.isArray(value) && value.length > 0;

export const CardRow = (props: CardRowProps): JSX.Element => {
    const renderRow = (): JSX.Element => {
        switch (props.type) {
            case CardRowType.Default:
                return <CardRowDefault {...props} />;

            case CardRowType.DefaultWithComments:
                return <CardRowWithComments {...props} />;

            case CardRowType.Investigation:
                return <CardRowInvestigation {...props} />;
        }
    };

    return (
        <div className="row mb-0 text-break" data-testid={props["data-testid"]}>
            {renderRow()}
        </div>
    );
};

const LeftColumn = ({ children }: { children: ReactNode }) => {
    return <div className="mb-0 col-auto flex-grow-1">{children}</div>;
};

const RightColumn = ({ children }: { children: ReactNode }) => {
    return <div className="mb-0 col-sm-12 col-md-9">{children}</div>;
};

const CardRowDefault = ({
    title,
    label,
    emphasise,
}: DefaultRow): JSX.Element => {
    return (
        <>
            <LeftColumn>
                <Text colour="metal" skinny>
                    {label}
                </Text>
            </LeftColumn>
            <RightColumn>
                <Text
                    skinny
                    variant={emphasise ? "label" : "body"}
                    colour={title ? "night" : "metal"}
                >
                    {title || MISSING_DATA_TEXT}
                </Text>
            </RightColumn>
        </>
    );
};

const CardRowWithComments = ({
    title,
    label,
    ...props
}: DefaultWithCommentsRow): JSX.Element => {
    const comments =
        typeof props.comments === "string" ? [props.comments] : props.comments;

    return (
        <>
            <LeftColumn>
                <Text colour="metal" skinny>
                    {label}
                </Text>
            </LeftColumn>
            <RightColumn>
                <Text
                    skinny
                    variant="label"
                    colour={title ? "night" : "metal"}
                    props={{ className: "mb-1" }}
                >
                    {title || MISSING_DATA_TEXT}
                </Text>
                {comments.map((comment, i) => (
                    <Text
                        key={i}
                        colour="night"
                        props={{
                            className: i < comments.length - 1 ? "mb-1" : "",
                        }}
                        skinny
                        variant={props.captionComments ? "preview" : "body"}
                    >
                        {comment}
                    </Text>
                ))}
            </RightColumn>
        </>
    );
};

const CardRowInvestigation = ({
    comments,
    title,
    label,
    subItems,
    value,
}: InvestigationRow): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState(
        hasSubInvestigations(subItems),
    );

    const toggleCollapse = () => {
        setIsCollapsed((prevState) => !prevState);
    };

    const hasComments = !!comments;
    const isEmptyTopRow =
        !title && !hasComments && !hasValidMeasurements(value);
    const isEmptyTopRowWithSubItems =
        isEmptyTopRow && hasSubInvestigations(subItems);

    const collapsibleProps = hasSubInvestigations(subItems)
        ? { onClick: toggleCollapse, style: { cursor: "pointer" } }
        : {};

    return (
        <div className="col">
            <div className="row" {...collapsibleProps}>
                <LeftColumn>
                    {label && (
                        <Text colour="metal" skinny>
                            {label}
                        </Text>
                    )}
                </LeftColumn>
                <RightColumn>
                    <div className="row">
                        <div className="mb-0 col-md-7 col-sm-12">
                            {(title || isEmptyTopRowWithSubItems) && (
                                <Text
                                    skinny
                                    colour="night"
                                    props={{
                                        className: hasComments ? " mb-1" : "",
                                    }}
                                >
                                    {title
                                        ? title
                                        : "Expand to view investigations"}
                                </Text>
                            )}
                            {hasComments && (
                                <Text
                                    colour="night"
                                    variant="preview"
                                    skinny
                                    props={{
                                        className: !title ? "mt-1" : "",
                                    }}
                                >
                                    {comments}
                                </Text>
                            )}
                        </div>
                        <div className="mb-0 col-md-5 col-sm-12">
                            <div className="d-flex justify-content-end">
                                {hasValidMeasurements(value) && (
                                    <Text
                                        skinny
                                        colour="night"
                                        props={{
                                            className: "text-md-right",
                                        }}
                                    >
                                        {value}
                                    </Text>
                                )}
                                {hasSubInvestigations(subItems) && (
                                    <div>
                                        <Button
                                            aria-label="Expand sub items"
                                            icon={{
                                                name: "Arrow",
                                                rotation: isCollapsed
                                                    ? "down"
                                                    : "up",
                                                colour: "night",
                                                title: "Expand sub items",
                                                id: "expand-sub-items-btn",
                                            }}
                                            theme="transparent"
                                            dimension="large"
                                            className="ml-2"
                                            style={{
                                                marginTop:
                                                    -5 /* So we can keep icon aligned with first line of text */,
                                                padding: 0 /* Remove padding to fit with designs */,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {hasSubInvestigations(subItems) && !isCollapsed && (
                        <div className="row">
                            <div className="col-sm-12">
                                <CardSpacer spacing="tiny" />
                                <CardSpacer spacing="tiny" />
                            </div>
                            {subItems.map((subItem, i) => {
                                const hasComments = !!subItem.comments;
                                const columnClassName =
                                    i === subItems.length - 1 ? "mb-0" : "mb-1";
                                return (
                                    <Fragment key={`${i}-${subItem.label}`}>
                                        <div
                                            className={`${columnClassName} col-md-7 col-sm-12`}
                                        >
                                            {subItem.label && (
                                                <Text
                                                    skinny
                                                    colour="night"
                                                    props={{
                                                        className: hasComments
                                                            ? " mb-1"
                                                            : "",
                                                    }}
                                                >
                                                    {subItem.label}
                                                </Text>
                                            )}
                                            {hasComments && (
                                                <Text
                                                    colour="night"
                                                    variant="preview"
                                                    skinny
                                                    props={{
                                                        className:
                                                            !subItem.label
                                                                ? "mt-1"
                                                                : "",
                                                        style: {
                                                            whiteSpace:
                                                                "pre-line",
                                                        },
                                                    }}
                                                >
                                                    {subItem.comments}
                                                </Text>
                                            )}
                                        </div>
                                        <div
                                            className={`${columnClassName} col-md-5 col-sm-12`}
                                        >
                                            {hasValidMeasurements(
                                                subItem.title,
                                            ) && (
                                                <Text
                                                    skinny
                                                    colour="night"
                                                    props={{
                                                        className:
                                                            "text-md-right",
                                                    }}
                                                >
                                                    {subItem.title}
                                                </Text>
                                            )}
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    )}
                </RightColumn>
            </div>
        </div>
    );
};
