import { useCallback, useEffect, useRef } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";
import { useTransport } from "@accurx/transport";

import { ConnectedStatusStatus } from "shared/hubClient/payload.types";

export const ACTIVITY_INTERVAL = 1000 * 60 * 10; // 10 minutes

export const useSendUserConnectedStatus = (): void => {
    const { orgId } = useCurrentWorkspace();
    const { transport, connectionState } = useTransport();
    const { embedMode } = getEmbedMode();
    const lastActiveTimestamp = useRef<number>(Date.now());

    // Send a user connected status update with a ConnectedStatusStatus value
    // to signal whether the user is active or idle
    // https://www.notion.so/accurx/Online-Offline-status-on-desktop-8384e155bf7943fc829703e62d1e42de
    const sendUserStatusUpdate = useCallback(
        (status: ConnectedStatusStatus): void => {
            if (connectionState !== "Connected") return;

            void transport?.send({
                methodName: "ConnectedStatus",
                payload: {
                    organisationId: orgId,
                    machineIdentifier: null,
                    status,
                },
            });
        },
        [connectionState, transport, orgId],
    );

    // Send an update whether the user is active based on whether
    // the page is visible or their last active timestamp is within the
    // time threshold for being considered active
    const handleUserActivity = useCallback((): void => {
        const isUserActive =
            document.visibilityState === "visible" ||
            Date.now() - lastActiveTimestamp.current < ACTIVITY_INTERVAL;

        sendUserStatusUpdate(
            isUserActive
                ? ConnectedStatusStatus.StatusUpdateActive
                : ConnectedStatusStatus.StatusUpdateIdle,
        );
    }, [sendUserStatusUpdate]);

    useEffect(() => {
        if (embedMode === "Desktop" || connectionState !== "Connected") {
            return;
        }

        // Send an update the user is active and update the last active timestamp
        // when the visibility changes to 'visible'
        const handleVisibilityChange = (): void => {
            if (document.visibilityState !== "visible") return;

            sendUserStatusUpdate(ConnectedStatusStatus.StatusUpdateActive);
            lastActiveTimestamp.current = Date.now();
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Update whether the user has been active or idle at regular intervals
        const userActivityInterval = setInterval(() => {
            handleUserActivity();
        }, ACTIVITY_INTERVAL);

        return () => {
            clearInterval(userActivityInterval);
            document.removeEventListener(
                "visibilitychange",
                handleVisibilityChange,
            );
        };
    }, [embedMode, sendUserStatusUpdate, handleUserActivity, connectionState]);
};
