import { useCurrentWorkspace } from "@accurx/auth";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    SearchForPatientSuccess,
    SearchForPatientSuccessSchema,
} from "domains/inbox/schemas/SearchForPatientSchema";
import { isTestPatient } from "domains/inbox/util";

type MutationVariables = {
    nhsNumber: string;
    dateOfBirth: { day: string; month: string; year: string };
    isUserAction?: boolean;
};

export const useSearchForPatientMutation = (
    options: UseMutationOptions<
        SearchForPatientSuccess,
        Error,
        MutationVariables
    >,
) => {
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(
        ["searchForPatientByPDS"],
        async (variables: MutationVariables) => {
            const { day, month, year } = variables.dateOfBirth;

            const request = {
                nhsNumber: variables.nhsNumber,
                dateOfBirthYear: year,
                dateOfBirthMonth: month,
                dateOfBirthDay: day,
                organisationId: workspaceId,
                isTestPatient: isTestPatient(variables.nhsNumber),
                isUserAction: variables.isUserAction,
            };

            const response = await httpClient.postJsonReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/portal/SearchForPatient",
                }),
                request,
            );

            const responseParse = SearchForPatientSuccessSchema.safeParse(
                response.result,
            );

            if (responseParse.success) {
                return responseParse.data;
            }

            throw new Error(
                "Search for patient via /api/portal/SearchForPatient failed",
            );
        },
        options,
    );
};
