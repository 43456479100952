import React from "react";

import { Feedback, Text } from "@accurx/design";

import { MedicalRecordResult } from "api/FlemingDtos";

import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type InvestigationsProps = Pick<MedicalRecordResult, "investigations">;

export const Investigations = ({
    investigations,
}: InvestigationsProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title="Investigations (Last 2 years)">
            {investigations === null && (
                <Feedback
                    title="Omitted Investigations"
                    content="Investigations are currently unavailable for practices that use SystmOne as a clinical system. We're working with SystmOne to make these available soon."
                    colour="warning"
                />
            )}
            {investigations !== null &&
                (investigations.length > 0 ? (
                    <CardRowStack>
                        {investigations.map((investigation, i) => {
                            const subInvestigations =
                                investigation.subInvestigations !== null
                                    ? investigation.subInvestigations.map(
                                          (subInvestigation) => ({
                                              label: subInvestigation.name,
                                              title: subInvestigation.stringValue,
                                              comments:
                                                  subInvestigation.comments,
                                          }),
                                      )
                                    : [];

                            return (
                                <CardRow
                                    type={CardRowType.Investigation}
                                    key={`${i}-${investigation.date}-${investigation.name}`}
                                    label={formatMedicalRecordDate(
                                        investigation.date,
                                    )}
                                    title={investigation.name || null}
                                    value={investigation.stringValue || null}
                                    subItems={subInvestigations}
                                    comments={investigation.comments}
                                />
                            );
                        })}
                    </CardRowStack>
                ) : (
                    <Text skinny>
                        No recorded investigations (last 2 years)
                    </Text>
                ))}
        </MedicalRecordPanelCard>
    );
};
