import { useQuery } from "@tanstack/react-query";

import { useOptionalUsersAndTeamsManager } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";
import { UsersAndTeamsWithMembershipSummaries } from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

/*
 * Provides wrapper for users and teams useQuery so that default handling can happen in one place
 */
export const useQueryWrapper = <T>(
    mapper: (data: UsersAndTeamsWithMembershipSummaries) => T | undefined,
) => {
    const manager = useOptionalUsersAndTeamsManager();

    const emptyState: UsersAndTeamsWithMembershipSummaries = {
        users: [],
        teams: [],
        currentUserTeamIds: [],
    };

    return useQuery<
        UsersAndTeamsWithMembershipSummaries,
        unknown,
        T | undefined
    >(
        manager ? manager.cacheKey : ["INVALID"],
        () =>
            manager
                ? manager.fetchUserTeamSummaries()
                : Promise.resolve(emptyState),
        {
            enabled: !!manager,
            select: mapper,
        },
    );
};
