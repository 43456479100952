import { Log } from "@accurx/shared";

import { IUserResponse } from "api/FlemingDtos";

class UserSettingsService {
    static getIsFirstTimeUser(user: IUserResponse) {
        // If the database says they are not a first time user we trust
        // it. Otherwise we fall back to browser storage
        // Logic to fall back to browser storage can be removed after
        // 1 Jul 2022, as we have then given enough time to trust the
        // database, and we are okay with treating users who haven't used
        // the product for 2 months as first time user
        if (user.onboarding?.isFirstTimeUser === false) {
            return false;
        }
        return (
            this.get(user.accuRxUserId, "isFirstTimeUser", "true") !== "false"
        );
    }

    static getIsNavigationMenuCollapsed(userId: IUserResponse["accuRxUserId"]) {
        const value = this.get(
            userId,
            "__accurx_navigation_collapsed__",
            "false",
        );

        if (!value) {
            return false;
        }

        return value !== "false";
    }

    static setIsNavigationMenuCollapsed(
        userId: IUserResponse["accuRxUserId"],
        value: boolean,
    ) {
        this.set(userId, "__accurx_navigation_collapsed__", value.toString());
    }

    static getIsMyConversationsSectionCollapsed = (userId: string) =>
        this.get(userId, "__accurx_my_conversations_collapsed__", "false") ===
        "true";

    static setIsMyConversationsSectionCollapsed(
        userId: string,
        value: boolean,
    ) {
        this.set(
            userId,
            "__accurx_my_conversations_collapsed__",
            value.toString(),
        );
    }

    static getIsTeamsColleaguesConversationsSectionCollapsed = (
        userId: string,
    ) =>
        this.get(
            userId,
            "__accurx_teams_colleagues_conversations_collapsed__",
            "false",
        ) === "true";

    static setIsTeamsColleaguesConversationsSectionCollapsed(
        userId: string,
        value: boolean,
    ) {
        this.set(
            userId,
            "__accurx_teams_colleagues_conversations_collapsed__",
            value.toString(),
        );
    }

    private static get(
        userId: string,
        keyName: string,
        failValue: string | null,
    ) {
        try {
            // In some browsers, if local storage has been disabled (for example using browser settings), then localStorage will be defined (vs window.localStorage === undefined) but set to null.
            // See https://mathiasbynens.be/notes/localstorage-pattern and https://gist.github.com/paulirish/5558557.
            if (window.localStorage === null) {
                Log.info(
                    "Could not get item from local storage. Local storage disabled. Returning failValue.",
                );
                return failValue;
            }
            return window.localStorage.getItem(
                this.buildKeyName(userId, keyName),
            );
        } catch (e) {
            if (this.isExpectedError(e)) {
                Log.info(
                    "Could not get item from local storage. Expected Error. Returning failValue.",
                    e,
                );
                return failValue;
            }
            Log.error(
                "Could not get item from local storage. Unexpected Error. Returning failValue.",
                e,
            );
            return failValue;
        }
    }

    private static set(userId: string, keyName: string, value: string) {
        try {
            if (window.localStorage === null) {
                Log.info(
                    "Could not set item in local storage. Local storage disabled.",
                );
            }
            return window.localStorage.setItem(
                this.buildKeyName(userId, keyName),
                value,
            );
        } catch (e) {
            if (this.isExpectedError(e)) {
                Log.info("Could not set item in local storage.", e);
                return;
            }
            Log.error("Could not set item in local storage.", e);
        }
    }

    private static buildKeyName(userId: string, keyName: string) {
        return `${keyName}-${userId}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static isExpectedError(e: any) {
        // We've found that even though Local Storage should be a supported API in IE11,
        // some users are getting a "An internal error occurred in the Microsoft Internet extensions".
        if (
            e.message ===
            "An internal error occurred in the Microsoft Internet extensions"
        ) {
            return true;
        }
        // SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
        // Exclusively seen on Chrome Mobile.
        // This exception is thrown when the "Block third-party cookies and site data" checkbox is set in Content Settings.
        // See https://stackoverflow.com/questions/30481516/iframe-in-chrome-error-failed-to-read-localstorage-from-window-access-deni.
        if (
            e.name === "SecurityError" &&
            e.message ===
                "Failed to read the 'localStorage' property from 'Window': Access is denied for this document."
        ) {
            Log.info(
                "Chrome settings are preventing us from using localStorage.",
                e,
            );
            return true;
        }
        // SecurityError: The operation is insecure.
        // In Firefox, a user can disable cookies/localStorage, triggering this error.
        // E.g. using the "Exceptions - Cookies and Site Data" dialog.
        if (
            e.name === "SecurityError" &&
            e.message === "The operation is insecure."
        ) {
            Log.info(
                "Firefox settings are preventing us from using localStorage.",
                e,
            );
            return true;
        }
        // Opera Mini does not support local storage (0.5% of users, https://caniuse.com/#search=localstorage).
        if (
            e.name === "TypeError" &&
            e.message === "Cannot convert 'window.localStorage' to object"
        ) {
            Log.info("Opera Mini does not support local storage.", e);
            return true;
        }
        // Some versions of IE11 do not support localStorage, therefore when we try to set and get whether a user has accepted the use policy we get an access denied error
        // In some cases the error message contains new line characters, therefore we cannot check for deep equality.
        if (
            e.name === "Error" &&
            typeof e.message === "string" &&
            e.message?.indexOf("Access is denied") >= 0
        ) {
            Log.info("IE settings preventing us from using local storage", e);
            return true;
        }
        return false;
    }
}

export default UserSettingsService;
