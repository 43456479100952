import { getApiEndpoint, httpClient } from "@accurx/shared";
import isNil from "lodash/isNil";

import {
    PatientThreadFolderTicketView,
    PatientThreadTicket,
    PatientThreadTicketCommandResult,
    PatientThreadTicketFolder,
    TicketIdentity,
} from "./types/dto.types";

const ENDPOINTS = {
    patientFolderTicketView:
        "api/conversation/web/patientfolderticketviewwithtoken",
    markNoteRead: "/api/conversation/web/marknotereadwithtoken",
    ticket: "api/conversation/web/ticketwithtoken",
} as const;

/**
 * Returns a page of ticket highlights corresponding to the folder
 * details as supplied in the request body, for example the user
 * inbox or all unassigned patient triage messages.
 */
export async function fetchPatientFolderViewWithToken(
    workspaceId: number,
    folder: PatientThreadTicketFolder,
    patientToken: string,
    continuationToken?: string,
): Promise<PatientThreadFolderTicketView> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.patientFolderTicketView,
        }),
        {
            organisationId: workspaceId,
            folder,
            patientToken,
            continuationToken,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `failed to fetch patient folder view for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}

/**
 * Marks a note as read by the current user.
 */
export const markNoteRead = async (
    workspaceId: number,
    patientToken: string,
    patientThreadItemIds: string[],
): Promise<PatientThreadTicketCommandResult> => {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.markNoteRead,
        }),
        { organisationId: workspaceId, patientToken, patientThreadItemIds },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `Failed to mark note as read, with note ID ${patientThreadItemIds}`,
        );
    }
    return response.result;
};

/**
 * Returns a ticket with all history of items contained within it.
 */
export async function fetchTicket(
    workspaceId: number,
    ticketIdentity: TicketIdentity,
    patientToken: string,
): Promise<PatientThreadTicket> {
    const response = await httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.ticket,
        }),
        {
            organisationId: workspaceId,
            ticketIdentity: ticketIdentity,
            patientToken: patientToken,
        },
    );

    if (!response.success || isNil(response.result)) {
        throw new Error(
            response.error ||
                `Failed to fetch conversation with patient token for user in workspace ${workspaceId}`,
        );
    }

    return response.result;
}
