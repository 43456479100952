/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Feedback, Text } from "@accurx/design";
import { shallowEqual } from "react-redux";
import { Dropdown, DropdownMenu } from "reactstrap";

import {
    MessageTemplate,
    MessageTemplateOwner,
} from "app/batchMessage/gp/BatchMessage.types";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

import { emptyTemplate } from "./BatchMessageCompose.constants";
import {
    BatchMessageDropDownItem,
    BatchMessageDropDownToggle,
} from "./BatchMessageCompose.styles";
import { TemplatesByHeader } from "./BatchMessageCompose.types";
import { ComposeTemplateDropdownItem } from "./ComposeTemplateDropdownItem";

export type ComposeTemplateDropdownProps = {
    selectTemplate: (
        template: MessageTemplate,
        templateOwner: MessageTemplateOwner,
    ) => void;
    selectedTemplateCanReply: boolean;
    selectedTemplateDisplay: string;
    isSelfBook?: boolean;
};

// Create groups of template with title for display in a dropdown
const formTemplateGroups = (
    templates: MessageTemplate[],
): TemplatesByHeader => {
    const templatesByHeader: TemplatesByHeader = {};
    templates.forEach((messageTemplate) => {
        if (messageTemplate.path.folders.length === 0) {
            if (templatesByHeader[""]) {
                templatesByHeader[""] = [
                    ...templatesByHeader[""],
                    messageTemplate,
                ];
            } else {
                templatesByHeader[""] = [messageTemplate];
            }
        } else {
            const header = messageTemplate.path.folders
                .map((x: any) => (x.name ? x.name.trim() : ""))
                .join(" > ");
            if (templatesByHeader[header]) {
                templatesByHeader[header] = [
                    ...templatesByHeader[header],
                    messageTemplate,
                ];
            } else {
                templatesByHeader[header] = [messageTemplate];
            }
        }
    });
    return templatesByHeader;
};

export const ComposeTemplateDropdown = ({
    selectTemplate,
    selectedTemplateCanReply,
    selectedTemplateDisplay,
    isSelfBook = false,
}: ComposeTemplateDropdownProps): JSX.Element => {
    // Store
    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const accurxTemplatesList = useAppSelector(
        ({ batchMessage }) => batchMessage.accurxTemplatesList,
        shallowEqual,
    );
    // This list of accurx templates is sorted with selfbook templates at the top
    const accurxSelfBookTemplatesList = useAppSelector(
        ({ batchMessage }) => batchMessage.accurxSelfBookTemplatesList,
        shallowEqual,
    );

    const orgTemplatesList = useAppSelector(
        ({ batchMessage }) => batchMessage.orgTemplatesList,
        shallowEqual,
    );

    const userTemplatesList = useAppSelector(
        ({ batchMessage }) => batchMessage.userTemplatesList,
        shallowEqual,
    );

    // State
    const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);

    const handleToggle = () => {
        setShowTemplateDropdown((prevState) => !prevState);
    };

    const accurxTemplatesByHeader = formTemplateGroups(accurxTemplatesList);
    const orgTemplatesByHeader = formTemplateGroups(orgTemplatesList);
    const userTemplatesByHeader = formTemplateGroups(userTemplatesList);
    const selfBookTemplatesByHeader = formTemplateGroups(
        accurxSelfBookTemplatesList,
    );

    return (
        <div className="w-100 mb-4" data-testid="templateDropdown">
            <div>
                <Text
                    as="label"
                    skinny
                    props={{ htmlFor: "template-dropdown" }}
                >
                    <Text variant="label" as="span">
                        Templates{" "}
                    </Text>
                    (optional)
                </Text>
                <Dropdown
                    isOpen={showTemplateDropdown}
                    toggle={handleToggle}
                    className="batch-dropdown"
                >
                    <BatchMessageDropDownToggle
                        id="template-dropdown"
                        color="light"
                    >
                        <Text skinny>{selectedTemplateDisplay}</Text>
                        <span className="caret" />
                    </BatchMessageDropDownToggle>
                    <DropdownMenu
                        className="w-100"
                        style={{ overflowY: "auto", height: "20rem" }}
                    >
                        <BatchMessageDropDownItem
                            style={{ padding: "0.25rem 1rem" }}
                            onClick={() => selectTemplate(emptyTemplate, "")}
                        >
                            <Text skinny>No template</Text>
                        </BatchMessageDropDownItem>

                        {isSelfBook && (
                            <ComposeTemplateDropdownItem
                                templateGroup={accurxSelfBookTemplatesList}
                                templateOwner={MessageTemplateOwner.AccuRx}
                                templateHeader={selfBookTemplatesByHeader}
                                templateTitle="Accurx templates"
                                selectTemplate={selectTemplate}
                                isSelfBook={isSelfBook}
                            />
                        )}

                        <ComposeTemplateDropdownItem
                            templateGroup={userTemplatesList}
                            templateOwner={MessageTemplateOwner.User}
                            templateHeader={userTemplatesByHeader}
                            templateTitle="My templates"
                            selectTemplate={selectTemplate}
                            isSelfBook={isSelfBook}
                        />

                        <ComposeTemplateDropdownItem
                            templateGroup={orgTemplatesList}
                            templateOwner={MessageTemplateOwner.Org}
                            templateHeader={orgTemplatesByHeader}
                            templateTitle={`${
                                practiceName || "Practice"
                            } templates`}
                            selectTemplate={selectTemplate}
                            isSelfBook={isSelfBook}
                        />

                        {!isSelfBook && (
                            <ComposeTemplateDropdownItem
                                templateGroup={accurxTemplatesList}
                                templateOwner={MessageTemplateOwner.AccuRx}
                                templateHeader={accurxTemplatesByHeader}
                                templateTitle="Accurx templates"
                                selectTemplate={selectTemplate}
                                isSelfBook={isSelfBook}
                            />
                        )}
                    </DropdownMenu>
                </Dropdown>
            </div>
            {selectedTemplateCanReply && (
                <Feedback
                    props={{ className: "w-100 mt-2" }}
                    colour="warning"
                    title="This template would normally allow a patient to respond. Single patient responses are currently not available in batch messaging."
                />
            )}
        </div>
    );
};
