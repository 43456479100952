import { NhsPharmaciesResponse } from "@accurx/api/account";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

const ENDPOINTS = {
    pharmacies: "/api/account/nhspharmacies",
};

export const searchPharmacies = ({
    searchTerm,
    limit,
    page,
}: {
    searchTerm: string;
    limit: number;
    page: number;
}): Promise<IWrappedResult<NhsPharmaciesResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.pharmacies,
            query: `?searchTerm=${searchTerm}&limit=${limit}&page=${page}`,
        }),
    );
