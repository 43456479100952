import { useRouteMatch } from "react-router";

import { ROUTES_WORKSPACE } from "shared/Routes";

/**
 * Returns a conversation identity calculated from the current URL.
 *
 * Examples:
 *   /conversations/users/me/conversation/t:1-id:2
 *   /conversations/conversation/t:1-id:2
 *
 * Returning null means that the URL does not represent
 * a valid conversation page
 */
export const useConversationId = (): string | null => {
    const pathMatch = useRouteMatch<{ conversationId: string }>(
        ROUTES_WORKSPACE.conversation,
    );

    return pathMatch?.params?.conversationId || null;
};
