import { useFeatureFlag } from "@accurx/auth";
import { useTeam } from "@accurx/concierge/hooks/data/useTeam";
import { useToAssignTriageConversationGroupQuery } from "@accurx/concierge/hooks/queries/useToAssignTriageConversationGroupQuery";
import { getMostRecentItem } from "@accurx/concierge/util/getMostRecentItem";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    ConversationFeed,
    ConversationFilter,
    ConversationListHeader,
    ConversationTabItem,
    ConversationTabs,
} from "domains/inbox/components/ConversationList/components";
import { ConversationPreview } from "domains/inbox/components/ConversationPreview/ConversationPreview";
import { Dropdown } from "domains/inbox/components/Dropdown/Dropdown";
import { DropdownItem } from "domains/inbox/components/Dropdown/DropdownItem";
import { NotificationsDropdown } from "domains/inbox/components/NotificationsDropdown/NotificationsDropdown";
import {
    useInboxLink,
    useInboxParams,
    useInboxTitle,
} from "domains/inbox/hooks/util";
import { useConversationFilterAnalytics } from "domains/inbox/hooks/util/useConversationFilterAnalytics";
import { useConversationListAnalytics } from "domains/inbox/hooks/util/useConversationListAnalytics";
import { userflowIds } from "domains/inbox/util";
import upperFirst from "lodash/upperFirst";

import {
    StyledAccessibleHeader,
    StyledConversationFeedContainer,
    StyledConversationList,
} from "../styles";
import { formatTriageType } from "./formatTriageType";
import { TriageType } from "./types";

export type ToAssignTriageProps = {
    triageType: TriageType;
};

/**
 * Notes on existing desktop behaviour:
 *
 * Currently the top level Unassigned/ToAssign view is equivalent to all open conversations,
 * assigned to the hard-coded "To Assign" team which acts as a catch-all, rather
 * than have a true lack of assignee. It only differs from the team based view in
 * that we don't show a done variation.
 *
 * This view has an additional filter so that it only shows these conversations
 * if they originated with a Patient Triage request of the expected type. In the
 * desktop this is currently only seen if the feature is enabled.
 */
export const UnassignedTriage = ({ triageType }: ToAssignTriageProps) => {
    const { teamId, status } = useInboxParams(["teamId", "status"]);
    const link = useInboxLink();
    const team = useTeam({ teamId });
    const groupQuery = useToAssignTriageConversationGroupQuery({
        status,
        teamId,
        triageType,
    });
    const { trackTabClick } = useConversationListAnalytics();

    const { trackFilterButtonClick, trackFilterMenuItemClick } =
        useConversationFilterAnalytics();

    const formattedTriageType = formatTriageType(triageType);

    useInboxTitle(
        `Unassigned ${formattedTriageType} ${status.toLowerCase()} conversations`,
    );

    const isSelfReferralEnabled = useFeatureFlag("PatientInitiatedSussexMsk");

    return team ? (
        <>
            <StyledConversationList rows="auto 1fr">
                <UI.Cell>
                    <ConversationListHeader
                        heading={
                            <>
                                Unassigned
                                <StyledAccessibleHeader>
                                    showing {status.toLowerCase()} messages with{" "}
                                    {formattedTriageType} filter applied
                                </StyledAccessibleHeader>
                            </>
                        }
                        notifications={
                            <NotificationsDropdown
                                team={team}
                                subTitle={formattedTriageType}
                            />
                        }
                    />
                    <ConversationTabs>
                        <ConversationTabItem
                            active={status === "Open"}
                            link={link.update({
                                status: "Open",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.openTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Done",
                                    newTab: "Open",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Open
                        </ConversationTabItem>
                        <ConversationTabItem
                            active={status === "Done"}
                            link={link.update({
                                status: "Done",
                                conversationId: null,
                            })}
                            userflowId={userflowIds.conversationList.doneTab}
                            onClick={() =>
                                trackTabClick({
                                    currentTab: "Open",
                                    newTab: "Done",
                                    conversationParticipant: "WithPatient",
                                })
                            }
                        >
                            Done
                        </ConversationTabItem>
                    </ConversationTabs>
                </UI.Cell>

                <StyledConversationFeedContainer>
                    <ConversationFilter>
                        <Dropdown
                            icon={<Pill.Icon name="Tag" colour="blue" />}
                            dropDownDisplayName={upperFirst(
                                formattedTriageType,
                            )}
                            contentHeading="Filter"
                            onClick={(_, isOpen) => {
                                if (!isOpen) {
                                    trackFilterButtonClick({
                                        filterName: triageType,
                                        appOrigin: "ConversationList",
                                    });
                                }
                            }}
                        >
                            <DropdownItem.ListItem>
                                <DropdownItem.Link
                                    link={link.to("Unassigned", {
                                        teamId,
                                    })}
                                    onClick={() =>
                                        trackFilterMenuItemClick({
                                            filterName: "Show all",
                                            appOrigin: "ConversationList",
                                        })
                                    }
                                >
                                    <DropdownItem.Icon name="Tag" />
                                    <DropdownItem.Text>
                                        Show all
                                    </DropdownItem.Text>
                                </DropdownItem.Link>
                            </DropdownItem.ListItem>

                            <DropdownItem.ListItem>
                                <DropdownItem.Divider fullWidth />
                            </DropdownItem.ListItem>

                            <DropdownItem.ListItem $mb={1}>
                                <DropdownItem.Link
                                    link={link.to("UnassignedAdmin", {
                                        teamId,
                                    })}
                                    selected={triageType === "Admin"}
                                    onClick={() =>
                                        trackFilterMenuItemClick({
                                            filterName: "Admin",
                                            appOrigin: "ConversationList",
                                        })
                                    }
                                >
                                    <DropdownItem.Icon name="Tag" />
                                    <DropdownItem.Text>Admin</DropdownItem.Text>
                                </DropdownItem.Link>
                            </DropdownItem.ListItem>

                            <DropdownItem.ListItem
                                $mb={isSelfReferralEnabled ? 1 : undefined}
                            >
                                <DropdownItem.Link
                                    link={link.to("UnassignedMedical", {
                                        teamId,
                                        status,
                                    })}
                                    selected={triageType === "Medical"}
                                    onClick={() =>
                                        trackFilterMenuItemClick({
                                            filterName: "Medical",
                                            appOrigin: "ConversationList",
                                        })
                                    }
                                >
                                    <DropdownItem.Icon name="Tag" />
                                    <DropdownItem.Text>
                                        Medical
                                    </DropdownItem.Text>
                                </DropdownItem.Link>
                            </DropdownItem.ListItem>

                            {isSelfReferralEnabled && (
                                <DropdownItem.ListItem>
                                    <DropdownItem.Link
                                        link={link.to(
                                            "UnassignedSelfReferral",
                                            {
                                                teamId,
                                                status,
                                            },
                                        )}
                                        selected={triageType === "SelfReferral"}
                                        onClick={() =>
                                            trackFilterMenuItemClick({
                                                filterName: "Self referral",
                                                appOrigin: "ConversationList",
                                            })
                                        }
                                    >
                                        <DropdownItem.Icon name="Tag" />
                                        <DropdownItem.Text>
                                            Self referral
                                        </DropdownItem.Text>
                                    </DropdownItem.Link>
                                </DropdownItem.ListItem>
                            )}
                        </Dropdown>
                    </ConversationFilter>

                    <ConversationFeed
                        key={groupQuery.data?.id}
                        isLoading={groupQuery.status === "loading"}
                        isError={groupQuery.status === "error"}
                        refetch={groupQuery.refetch}
                        fetchMore={groupQuery.fetchMore}
                        isFetchingMore={groupQuery.isFetchingMore}
                        isFullyLoaded={groupQuery.data?.isFullyLoaded}
                        members={groupQuery.data?.members}
                        filters={groupQuery.data?.filters}
                        renderItem={(conversation, state) => (
                            <ConversationPreview
                                conversation={conversation}
                                item={getMostRecentItem(conversation)}
                                state={state}
                            />
                        )}
                    />
                </StyledConversationFeedContainer>
            </StyledConversationList>
        </>
    ) : null;
};
