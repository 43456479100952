import { ROUTES } from "shared/Routes";
import patientTokenService from "shared/storage/PatientTokenService";

export const cleanUpSession = () => {
    // Shut intercom down
    window.Intercom && window.Intercom("shutdown");

    // clear patient from storage as we're about to log out, and we may have data
    // in the browsers sessionStorage
    if (patientTokenService !== null) {
        patientTokenService.clearCurrentPatientToken();
    }
};

// At the moment we still have two login page (one for Chain one for Fleming)
// We want to redirect the user to the chain login in this case
export const getStartupUrl = (isChain = false) => {
    return isChain ? ROUTES.loginOrganisations : ROUTES.home;
};

export function restartApp(isChain = false): void {
    cleanUpSession();

    // We trigger a hard reload of the application, similar to Chain Desktop.
    // This takes us out of the abstraction of React/Redux into the underlying browser APIs.
    // A fresh GET / request will be triggered ensuring no in memory state, and any
    // persisted data outside of the in-memory Redux store has already been cleared (above).
    // Doing this ensures no state is carried forwards from the logging out user
    // to the next logged in user.
    window.location.href = getStartupUrl(isChain);
}

// Reloading the app, as opposed to restarting it, is useful in cases where we've hit an edge case but want to stay
// on the current URL. For example when embedded in WebView2 it is theoretically impossible for the user to get a 401
// as cookies are provided by the native app. However, in case this does happen, simply refreshing the page will reload
// the cookies.
export function reloadApp(): void {
    cleanUpSession();

    // We trigger a hard reload of the application, similar to Chain Desktop.
    // This takes us out of the abstraction of React/Redux into the underlying browser APIs.
    // A fresh GET / request will be triggered ensuring no in memory state, and any
    // persisted data outside of the in-memory Redux store has already been cleared (above).
    // Doing this ensures no state is carried forwards from the logging out user
    // to the next logged in user.
    window.location.reload();
}
