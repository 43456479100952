import { Card, Flex, Text } from "@accurx/design";

import {
    StyledCardContainer,
    StyledChildrenContentFlex,
} from "./WorkspaceCard.styles";

interface WorkspaceCardProps {
    workspaceName: string;
    defaultWorkspaceName?: string;
    children?: React.ReactNode;
}

export const WorkspaceCard = ({
    workspaceName,
    defaultWorkspaceName,
    children,
}: WorkspaceCardProps) => {
    return (
        <Card spacing={1.5}>
            <StyledCardContainer>
                <Flex flexDirection="column">
                    <Text variant="label" skinny>
                        {workspaceName}
                    </Text>
                    {defaultWorkspaceName && (
                        <Text variant="preview" skinny>
                            {defaultWorkspaceName}
                        </Text>
                    )}
                </Flex>
                <StyledChildrenContentFlex>
                    {children}
                </StyledChildrenContentFlex>
            </StyledCardContainer>
        </Card>
    );
};
