import * as UI from "@accurx/design";
import styled from "styled-components";

export const StylesNotesIconWrapper = styled.div`
    svg {
        width: 20px;
        height: 20px;
        margin-right: ${UI.Tokens.SIZES[0.5]};
    }
`;
