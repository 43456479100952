import { PatientExternalIdSchema } from "@accurx/concierge/schemas/PatientExternalIdSchema";
import { z } from "zod";

export const PatientExternalIdentityDtoSchema = z.object({
    patientExternalIds: z.array(PatientExternalIdSchema),
});

export type PatientExternalIdentityDto = z.infer<
    typeof PatientExternalIdentityDtoSchema
>;
