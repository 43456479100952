import React from "react";

import { Button, Feedback, Link, Text, Thread } from "@accurx/design";
import { toast } from "react-toastify";

import { VideoConsultLinkItem } from "shared/concierge/conversations/types/item.types";

import { BaseConversationThreadItem } from "../threadItem.types";
import { StyledButtonsWrapper } from "./VideoConsultThreadItem.styles";

export type VideoConsultLinkThreadItemProps =
    BaseConversationThreadItem<VideoConsultLinkItem>;

export const VideoConsultLinkThreadItem = ({
    item,
    handleOnItemRead,
}: VideoConsultLinkThreadItemProps): JSX.Element => {
    const copyHandler = () => {
        try {
            navigator.clipboard.writeText(item.clinicianUrl);

            toast(
                Feedback({
                    colour: "success",
                    title: "Video consultation link copied",
                    content: "Start using video consultation",
                }),
            );
        } catch {
            toast(
                Feedback({
                    colour: "error",
                    title: "Unable to copy video consultation link",
                    content: "Please try clicking the link instead",
                }),
            );
        }
    };

    return (
        <Thread.Message
            inboxType="information"
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <Text as="h5" variant="label">
                Ready to join the video consultation?
            </Text>
            <Text variant="body">
                If you're joining from your smartphone, click the link in the
                SMS message that we've sent you.
            </Text>
            <StyledButtonsWrapper>
                <Button
                    dimension="medium"
                    text="Copy link"
                    theme="secondary"
                    onClick={copyHandler}
                />
                <Link
                    href={item.clinicianUrl}
                    openInNewTab
                    text="Join video call"
                />
            </StyledButtonsWrapper>
            <Text variant="note">Having trouble connecting?</Text>
            <Text variant="preview">
                You need a webcam, microphone and you should use Google Chrome,
                Mozilla Firefox or Microsoft Edge. If you are on a phone call
                hang up so video consult can access your microphone.
            </Text>
        </Thread.Message>
    );
};

export const videoConsultLinkThreadItemSummaryText = (
    item: VideoConsultLinkThreadItemProps["item"],
): string => `Video consult link provided: ${item.clinicianUrl}`;
