import { useDispatch } from "react-redux";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import {
    PostUserProfileOrganisationResponse,
    PostUserProfileRequest,
} from "api/FlemingDtos";
import {
    POST_USER_PROFILE_SUCCESS,
    SETUP_ORG_SUCCESS,
} from "app/account/AccountActions";
import { useUpdateUserHealthcareProfileMutation } from "app/hooks/mutations";

interface MutationOptions {
    onSuccess?: (data: PostUserProfileOrganisationResponse) => void;
    onError?: (e: Error) => void;
}

/**
 * Wrapper for user profile mutation that should be used specifically for
 * joining an organisation. It abstracts operations to keep the Redux store and
 * SelectOrganisationProvider in sync. Once account state is fully migrated,
 * this wrapper will become redundant.
 */
export const useJoinOrganisation = () => {
    const dispatch = useDispatch();
    const { setSelectedOrgId } = useSelectedOrganisation();
    const { mutate, isLoading } = useUpdateUserHealthcareProfileMutation();

    const handleMutate = (
        request: PostUserProfileRequest,
        { onSuccess, onError }: MutationOptions = {},
    ) => {
        mutate(request, {
            onSuccess: ({ organisationResponse }, variables) => {
                const { organisationId, organisations } = organisationResponse;

                dispatch({
                    type: SETUP_ORG_SUCCESS,
                    selectedOrganisationId: organisationId,
                    organisations,
                });

                dispatch({
                    type: POST_USER_PROFILE_SUCCESS,
                    hasAcceptedTermsService: variables.hasAcceptedTermsService,
                });

                setSelectedOrgId(organisationId);

                onSuccess && onSuccess(organisationResponse);
            },
            onError,
        });
    };

    return { isLoading, mutate: handleMutate };
};
