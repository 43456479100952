/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { createContext, useContext } from "react";

import {
    OrgUserApprovalStatus,
    OrgUserRole,
    UserTransitions,
} from "./PracticeUsers.types";

type PracticeUsersContext = {
    changeRole: (userId: number, role: OrgUserRole, name: string) => void;
    changeStatus: (
        userId: number,
        status: OrgUserApprovalStatus,
        name: string,
    ) => void;
    hasApprovalRights: boolean;
    showModalNotification: (userInput: UserTransitions) => void;
};

const PracticeUsersContext = createContext<PracticeUsersContext | undefined>(
    undefined,
);
PracticeUsersContext.displayName = "PracticeUsersContext";

export const PracticeUsersProvider = PracticeUsersContext.Provider;

export const usePracticeUsersContext = (): PracticeUsersContext => {
    const context = useContext(PracticeUsersContext);
    if (context === undefined) {
        throw new Error(
            "usePracticeUsersContext must be used within a PracticeUsersProvider",
        );
    }
    return context;
};
