import { Log } from "@accurx/shared";
import { InboxParams, ROUTES_INBOX } from "domains/inbox/routes";
import { generateLocationObject } from "domains/inbox/routes/util";
import { PatientDemographics } from "domains/inbox/schemas/PatientDemographicsSchema";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import omitBy from "lodash/omitBy";
import { useLocation, useParams, useRouteMatch } from "react-router";

import { useSearchParams } from "./useSearchParams";

/**
 * Given a path segment, this type will resolve to the
 * name of the param that segment binds, if any.
 * Optional path params, ending in ?, are supported.
 *
 * So:
 * ParamName<"foo"> => never
 * ParamName<":foo"> => "foo"
 * ParamName<":foo?"> => "foo"
 */
type ParamName<PathSegment> = PathSegment extends `:${infer Param}?`
    ? Param
    : PathSegment extends `:${infer Param}`
    ? Param
    : never;

/**
 * Splits a string literal path into segments
 * separated by a forward slash, and extract
 * the path params using the `ParamName` type.
 *
 * So:
 * PathParams<"/foo/bar/:baz/:quux?"> => "baz" | "quux"
 * PathParams<"/foo/bar/baz/quux"> => never
 */
export type PathParams<Path> = Path extends `${infer First}/${infer Second}`
    ? ParamName<First> | PathParams<Second>
    : ParamName<Path>;

/**
 * Given a param name, looks it up in the InboxParams type
 * to see if we have specified a type for it. If we have,
 * it resolves to that type. If we haven't, it resolves
 * to the standard union type for path params supported
 * by react-routers's `generatePath`.
 * We support this default to allow these helpers to be
 * used in cases where we have one-off params that we don't
 * want to include in the globally used InboxParams type.
 *
 * So:
 * InboxParamType<"status"> => ConversationStatus
 * InboxParamType<"foo"> => string | number | boolean | undefined;
 */
type InboxParamType<Param> = Param extends keyof InboxParams
    ? InboxParams[Param]
    : string | number | boolean | undefined;

/**
 * Figures out which path params are required - i.e. do not end in a `?`.
 * Doing this is a pain in Typescript.
 * This solution adapted from this Github comment:
 * https://github.com/microsoft/TypeScript/issues/36126#issuecomment-731915586
 *
 * We make a mapped type of param name => either `never`, if the param appears
 * in the path followed by a question mark, or the param name if it does not,
 * and then extract the non-never values from that mapped type.
 *
 * So:
 *
 * RequiredInboxRouteParams<"/foo/:bar/:baz/:quux?"> => "bar" | "baz"
 */
type RequiredInboxRouteParams<Path> = {
    [Param in PathParams<Path>]: Path extends `${string}:${Param}?${string}`
        ? never
        : Param;
}[PathParams<Path>];

/**
 * The inverse of RequiredInboxRouteParams above:
 *
 * OptionalInboxRouteParams<"/foo/:bar/:baz/:quux?/:zyzzy?"> => "quux" | "zyzzy"
 */
type OptionalInboxRouteParams<Path extends string> = {
    [Param in PathParams<Path>]: Path extends `${string}:${Param}?${string}`
        ? Param
        : never;
}[PathParams<Path>];

/**
 * Given a path, constructs a mapped type which contains
 * both its optional and required params, with their types
 * extracted from the InboxParams type if present.
 * So:
 *
 * InboxRouteParams<"/inbox/w/:workspaceId/my/s/:status/c/:conversationId?"> =>
 * { workspaceId: number, status: ConversationStatus, conversationId?: string | null }
 */
export type InboxRouteParams<Path extends string> = {
    [Param in RequiredInboxRouteParams<Path>]: InboxParamType<Param>;
} & {
    [Param in OptionalInboxRouteParams<Path>]?: InboxParamType<Param> | null;
} & Partial<InboxParams>;

export type InboxLocationState = {
    patient?: PatientDemographics;
};

export type LocationObject = {
    pathname: string;
    state: InboxLocationState;
    search?: string;
};

/**
 * A hook that returns two helper functions for creating
 * links.
 *
 * The first, `link.to`, accepts the key of an inbox route
 * (defined in ROUTES_INBOX) and enforces that we pass all
 * the required params to it, to give us some protection against
 * accidentally missing some. If we add new params to a path
 * and forget to update everywhere that links to it, this
 * will cause typescript to complain - which is what we want.
 * Any extra params passed to `link.to` will be appended to the url
 * as query params - this is used for things like filtering by status.
 *
 * The second, `link.update`, is for when we want to keep the path the same
 * but update only one or more params. For e.g. when clicking a filter
 * on a conversation list, or clicking a conversation preview
 * to select that conversation.
 */
export const useInboxLink = () => {
    const location = useLocation<InboxLocationState>();
    const match = useRouteMatch({
        path: Object.values(ROUTES_INBOX),
        exact: true,
    });
    const pathParams = useParams<{ workspaceId: string }>();
    const searchParams = useSearchParams();

    const { state = {} } = location;

    const to = <
        K extends keyof typeof ROUTES_INBOX,
        Path extends (typeof ROUTES_INBOX)[K],
    >(
        path: K,
        params?: Omit<InboxRouteParams<Path>, "workspaceId"> & {
            source?: string;
        },
        locationState: Partial<InboxLocationState> = {},
    ): LocationObject => {
        const { pathname, search } = generateLocationObject(
            ROUTES_INBOX[path],
            {
                // we provide the workspaceId by default as it shouldn't change
                // except via the workspace switcher, so we don't want to
                // have to always pass it in
                workspaceId: pathParams.workspaceId,
                ...mapValues(omitBy(params, isNil), encodeURIComponent),
            },
        );

        return {
            pathname,
            search,
            state: {
                ...state,
                ...locationState,
            },
        };
    };

    const update = (
        updatedParams: Partial<Nullable<InboxParams>> &
            Record<string, string | number | boolean | null>,
        locationState: Partial<InboxLocationState> = {},
    ): LocationObject => {
        if (!match) {
            Log.error(`Couldn't match current location: ${location.pathname}.`);
        }

        const params = omitBy(
            {
                ...pathParams,
                ...match?.params,
                ...searchParams,
                ...updatedParams,
            },
            isNil,
        );

        const { search, pathname } = generateLocationObject(
            match?.path ?? ROUTES_INBOX.MyInbox,
            mapValues(params, encodeURIComponent),
        );

        return {
            search,
            pathname,
            state: {
                ...state,
                ...locationState,
            },
        };
    };

    return { to, update };
};
