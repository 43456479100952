import React, { ReactNode } from "react";

import * as UI from "@accurx/design";
import { i } from "images";

import { StyledContainer } from "./GenericErrorState.styles";

export const GenericErrorState = ({ children }: { children: ReactNode }) => {
    return (
        <StyledContainer
            flexDirection="column"
            gap="1.5"
            alignItems="center"
            justifyContent="center"
        >
            <img
                src={i("illustrations/error-icon-80px@2x.png")}
                srcSet={`${i("illustrations/error-icon-80px.png")}, ${i(
                    "illustrations/error-icon-80px@2x.png",
                )} 2x, ${i("illustrations/error-icon-80px@3x.png")} 3x`}
                alt="Conversation loading error"
                height="80"
            />

            {children ? (
                <>{children}</>
            ) : (
                <UI.Text skinny>
                    Sorry, something went wrong.
                    <br />
                    Refresh the page to try again.
                </UI.Text>
            )}
        </StyledContainer>
    );
};
