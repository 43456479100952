import { SettingsTabs } from "./Settings.types";

/**
 * Decide what the default tab should be from a string.
 * If the tabname is not a valid tab, default to Profile tab
 */
export const getDefaultTab = (tabname: string): SettingsTabs => {
    const tabLowercase = tabname.toLowerCase() as SettingsTabs;
    return Object.values(SettingsTabs).includes(tabLowercase)
        ? tabLowercase
        : SettingsTabs.Profile;
};
