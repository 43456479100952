import { useCallback, useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useTransport } from "@accurx/transport";

export const useSendUserOnline = (): void => {
    const { orgId } = useCurrentWorkspace();
    const { transport, connectionState } = useTransport();

    /**
     * For other clients to know its own online status,
     * it needs to send this event to the server when it first connects.
     *
     * The desktop toolbar already sends this event once its loaded but
     * the responses from other users will be sent back to the toolbar
     * instead of this client. This means that when embeded in Webview,
     * we technically need to send this event twice (which is OK).
     */
    const sendUserIsOnline = useCallback(() => {
        void transport?.send({
            methodName: "OnConnectedInfo",
            payload: {
                organisationId: orgId,
                machineIdentifier: null,
            },
        });
    }, [transport, orgId]);

    useEffect(() => {
        // Call whenever Signlar connects/reconnects
        if (connectionState === "Connected") {
            sendUserIsOnline();
        }
    }, [sendUserIsOnline, connectionState]);
};
