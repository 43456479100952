import { storageAvailable } from "./featureDetection";

export const CURRENT_PATIENT_TOKEN_KEY = "__accurx_current_patient_token__";
export type PatientKey = typeof CURRENT_PATIENT_TOKEN_KEY;

// We can't set anything in the session storage to null so we use this when we want to wipe out the token
export const NULL_TOKEN = "NULL_TOKEN";

interface IPatientTokenService {
    getCurrentPatientToken(): string | null;
    setCurrentPatientToken(patientToken: string | null): void;
    clearCurrentPatientToken(): void;
}

class PatientTokenService implements IPatientTokenService {
    getCurrentPatientToken(): string | null {
        const storedToken = window.sessionStorage.getItem(
            CURRENT_PATIENT_TOKEN_KEY,
        );

        if (storedToken === NULL_TOKEN) {
            return null;
        }
        return storedToken;
    }

    setCurrentPatientToken(patientToken: string | null): void {
        window.sessionStorage.setItem(
            CURRENT_PATIENT_TOKEN_KEY,
            patientToken ?? NULL_TOKEN,
        );
    }

    clearCurrentPatientToken(): void {
        window.sessionStorage.removeItem(CURRENT_PATIENT_TOKEN_KEY);
    }
}

let patientsService: IPatientTokenService | null;

if (storageAvailable("sessionStorage")) {
    patientsService = new PatientTokenService();
} else {
    patientsService = null;
}

export default patientsService;
