import type { AnyAction } from "redux";

import {
    LoadingStatus,
    UpdatingStatus,
    UpdatingStatusType,
} from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

import { DashboardData } from "./types";

export type PatientTriageState = {
    updatingStatus: UpdatingStatus;
    currentUserAnalyticsId: string;
    triageSettingsUpdatingStatus: UpdatingStatusType;
    loadingStatus: LoadingStatus;
    dashboardLoadingStatus: LoadingStatus;
    status: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    dashboardData: DashboardData[];
};

const emptyUser = {
    userId: "",
    email: "",
    name: "",
    enabled: false,
};

export const initialState: PatientTriageState = {
    updatingStatus: UpdatingStatus.Initial,
    currentUserAnalyticsId: "00000000-0000-0000-0000-000000000000",
    triageSettingsUpdatingStatus: UpdatingStatus.Initial,
    loadingStatus: LoadingStatus.Initial,
    dashboardLoadingStatus: LoadingStatus.Initial,
    status: {
        isAdmin: false,
        enabled: false,
        url: "",
        users: [emptyUser],
        responseTimeWorkingDays: 2,
        prescriptionRedirectEnabled: false,
        prescriptionRedirectUrl: null,
        prescriptionRedirectTitle: null,
        disableUntilDate: null,
        welcomePageMessage: null,
        submissionPageMessage: null,
        submissionPageSms: null,
        outOfHoursPageMessage: null,
        allowAdminRequests: false,
        outOfHoursSettings: {
            outOfHoursAllowAdminRequests: null,
            outOfHoursDisableFromHour: null,
            outOfHoursDisableUntilHour: null,
            outOfHoursSuspendPatientTriage: false,
        },
    },
    dashboardData: [],
};

export const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case CoreActions.LOAD_PATIENT_TRIAGE_STATUS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loading,
            };
        case CoreActions.LOAD_PATIENT_TRIAGE_STATUS_FAIL:
            return {
                ...state,
                loadingStatus: LoadingStatus.Failed,
            };
        case CoreActions.LOAD_PATIENT_TRIAGE_STATUS_SUCCESS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                status: action.status,
            };
        case CoreActions.ENABLE_PATIENT_TRIAGE:
            return {
                ...state,
                updatingStatus: UpdatingStatus.Loading,
            };
        case CoreActions.ENABLE_PATIENT_TRIAGE_FAIL:
            return {
                ...state,
                updatingStatus: UpdatingStatus.Failed,
            };
        case CoreActions.ENABLE_PATIENT_TRIAGE_SUCCESS:
            return {
                ...state,
                updatingStatus: UpdatingStatus.Loaded,
                status: action.status,
            };
        case CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Loading,
            };
        case CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS_FAIL:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Failed,
            };
        case CoreActions.UPDATE_PATIENT_TRIAGE_SETTINGS_SUCCESS:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Loaded,
                status: action.status,
            };
        case CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Loading,
            };
        case CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_SUCCESS:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Loaded,
                status: action.status,
            };
        case CoreActions.PATIENT_TRIAGE_SET_OUT_OF_HOURS_FAIL:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Failed,
            };
        case CoreActions.RESET_UPDATING_STATUS:
            return {
                ...state,
                triageSettingsUpdatingStatus: UpdatingStatus.Initial,
            };
        case CoreActions.LOAD_PATIENT_TRIAGE_DASHBOARD:
            return {
                ...state,
                dashboardLoadingStatus: LoadingStatus.Loading,
            };
        case CoreActions.LOAD_PATIENT_TRIAGE_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardLoadingStatus: LoadingStatus.Loaded,
                dashboardData: action.status,
            };
        case CoreActions.LOAD_PATIENT_TRIAGE_DASHBOARD_FAIL:
            return {
                ...state,
                dashboardLoadingStatus: LoadingStatus.Failed,
            };
        default:
            return state;
    }
};
