import {
    QueryClient,
    QueryObserver,
    QueryObserverOptions,
    QueryObserverResult,
} from "@tanstack/react-query";
import { Observable, fromEventPattern } from "rxjs";

/**
 * This helper utility returns an RxJS observable that subscribes to React Query
 * and emits an event whenever the cache changes for a given query.
 *
 * @example
 * ```
 * const observable = fromReactQuery(
 *     {
 *         queryKey: ["user", userId],
 *         queryFn: () => fetchUserById(userId),
 *     },
 * );
 *
 * observable.subscribe((data) => handleCacheUpdate(data));
 * ```
 *
 * @param queryClient - A React Query Client
 * @param options - Options to pass to the QueryObserver. Should likely contain
 * a cache key and a query function.
 *
 * @returns An RxJS observable that emits whenever the cached data changes
 */
export const fromReactQuery = <T>(
    queryClient: QueryClient,
    options: QueryObserverOptions<T>,
): Observable<QueryObserverResult<T>> => {
    const queryObserver = new QueryObserver<T>(queryClient, options);

    const callback = fromEventPattern<QueryObserverResult<T>>(
        queryObserver.subscribe,
        (_, unsubscribeCb) => unsubscribeCb(),
    );

    return callback;
};
