import React, { useEffect, useState } from "react";

import { Feedback, Link, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { SupportUrls } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";

import { useSafeAsync } from "api/Api.utils";
import { RecordViewRequestResult } from "api/FlemingDtos";
import { getAllRecordRequests } from "api/RecordViewApi";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { useAppSelector } from "store/hooks";

import { PageHeader, PageHeaderType } from "../layout/PageHeader";
import { actionCreators } from "./RecordViewActions";
import { CardSpacer } from "./medicalRecord/CardSpacer";
import { Legend } from "./medicalRecords/Legend";
import { MedicalRecordsTable } from "./medicalRecords/MedicalRecordsTable";

enum LoadStatus {
    Loading = "loading",
    Loaded = "loaded",
    Error = "error",
}

const MedicalRecords = (): JSX.Element => {
    useAccurxWebTitle("View medical record requests");

    const dispatch = useDispatch();
    const safeAsync = useSafeAsync();

    const orgId = useAppSelector((state) => state.account.selectedOrganisation);

    const [status, setStatus] = useState(LoadStatus.Loading);
    const [recordViewRequests, setRecordViewRequests] = useState<
        RecordViewRequestResult[] | null
    >(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        dispatch(
            actionCreators.setViewMedicalRecordOrigin(
                FlemingAnalyticsTracker.MedicalRecordViewOrigin
                    .MedicalRecordsTable,
            ),
        );
        dispatch(actionCreators.resetRecordView());
    }, [dispatch, orgId]);

    useEffect(() => {
        if (orgId) {
            const fetchRecords = async () => {
                setStatus(LoadStatus.Loading);
                setErrorMessage("");

                const { success, result, error } = await safeAsync(
                    getAllRecordRequests({
                        OrganisationId: orgId,
                    }),
                );

                if (success && result !== null) {
                    setStatus(LoadStatus.Loaded);
                    setRecordViewRequests(result.recordAccessRequests);
                } else {
                    setStatus(LoadStatus.Error);
                    error !== null && setErrorMessage(error);
                }
            };

            fetchRecords();
        } else {
            setStatus(LoadStatus.Error);
            setErrorMessage(
                "Please select an organisation before trying to load the records",
            );
        }
    }, [safeAsync, orgId]);

    const availableRecordsCount = recordViewRequests?.filter(
        (recordRequest) => recordRequest?.status === "RecordAvailable",
    ).length;

    const analyticsProps = useAppSelector(
        ({ account, selectProduct, sessionAnalytics: analytics }) => {
            const baseProps = AnalyticsMapper.getRecordViewBaseAnalyticsProps(
                account,
                analytics,
            );
            return baseProps
                ? {
                      ...baseProps,
                      searchPatientOrigin: selectProduct.searchPatientOrigin,
                  }
                : undefined;
        },
        shallowEqual,
    );

    useEffect(() => {
        analyticsProps &&
            availableRecordsCount !== undefined &&
            FlemingAnalyticsTracker.trackMedicalRecordsPageView({
                ...analyticsProps,
                recordsAvailable: availableRecordsCount,
            });
    }, [analyticsProps, availableRecordsCount]);

    const renderMedicalRecordsState = (): JSX.Element => {
        switch (status) {
            case LoadStatus.Loading:
                return <Spinner />;

            case LoadStatus.Loaded:
                if (
                    recordViewRequests !== null &&
                    recordViewRequests.length > 0
                ) {
                    return (
                        <>
                            <Legend />
                            <CardSpacer spacing="small" />
                            <MedicalRecordsTable records={recordViewRequests} />
                        </>
                    );
                }
                return (
                    <Text colour="night">
                        Once you've made medical record requests they will
                        appear here.{" "}
                        <Link
                            href={SupportUrls.RecordViewGuide}
                            openInNewTab={true}
                        >
                            Learn how to request a record here
                            <Link.Icon />
                        </Link>
                    </Text>
                );

            case LoadStatus.Error:
                return (
                    <Feedback
                        colour="error"
                        title="Something went wrong"
                        content={
                            errorMessage ||
                            "Couldn't load the list of records, please refresh to try again"
                        }
                    />
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <PageHeader
                type={PageHeaderType.ListPage}
                title="Medical records"
            />
            <div className="bottom-spacing">{renderMedicalRecordsState()}</div>
        </>
    );
};

export default MedicalRecords;
