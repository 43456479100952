import { Log } from "@accurx/shared";
import { OptionalHubClient } from "domains/transport/hooks/useOptionalHubClient";
import { Transport } from "domains/transport/types";
import { filter } from "rxjs/operators";

// A hook to encapsulate the logic for sending and subscribing to SignalR events
// via hubClient. The hook is composed of being able to send SignalR events and
// setting up and subscribing events, which is abstracted under a single subscribe function
export const useHubClientTransport = (
    hubClient: OptionalHubClient,
): Transport => {
    return {
        send: ({ methodName, payload }) => {
            if (!hubClient) {
                Log.error(
                    `HubClientTransport: Tried to send an event with no HubClient`,
                    {
                        tags: {
                            methodName,
                        },
                    },
                );
                throw new Error(
                    `No HubClient provided - Unable to send ${methodName} event`,
                );
            }

            void hubClient.send(methodName, payload);
        },
        subscribe: ({ methodName, onEvent, eventFilter = () => true }) => {
            if (!hubClient) {
                Log.error(
                    `HubClientTransport: Tried to subscribe to an event with no HubClient`,
                    {
                        tags: {
                            methodName,
                        },
                    },
                );
                throw new Error(
                    `No HubClient provided - Unable to subscribe to ${methodName} event`,
                );
            }

            const subscription = hubClient
                .getSubscription(methodName)
                .pipe(filter(eventFilter))
                .subscribe(onEvent);
            return { unsubscribe: () => subscription.unsubscribe() };
        },
    };
};
