import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledCardContent = styled.div`
    // Can't use the StackPanel here since it isn't responsive at smaller breakpoints.
    // Have added the flex-wrap attribute to ensure content always fits within card.

    display: flex;
    gap: ${Tokens.SIZES[1.5]};
    justify-content: space-between;
    align-items: center;

    flex-wrap: wrap;
`;

const StyledDownloadLink = styled.a`
    &:hover,
    &:focus {
        text-decoration: none;
    }
`;

export { StyledCardContent, StyledDownloadLink };
