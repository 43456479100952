import React from "react";

import { Text } from "@accurx/design";

import { MedicalRecordResult } from "api/FlemingDtos";

import { MISSING_DATA_TEXT } from "../RecordView.constant";
import { formatMedicalRecordDate } from "../RecordView.helper";
import { CardRow, CardRowType } from "./CardRow";
import { CardRowStack } from "./CardRowStack";
import { MedicalRecordPanelCard } from "./MedicalRecordPanelCard";

export type HealthStatusProps = Pick<MedicalRecordResult, "healthStatus">;

export const HealthStatus = ({
    healthStatus,
}: HealthStatusProps): JSX.Element => {
    return (
        <MedicalRecordPanelCard title="Health Status">
            {healthStatus.length > 0 ? (
                <CardRowStack>
                    {healthStatus.map((status, i) => (
                        <CardRow
                            type={CardRowType.Default}
                            key={i.toString()}
                            label={formatMedicalRecordDate(status.lastUpdated)}
                            title={
                                status.name || status.value
                                    ? [status.name, ": ", status.value].join("")
                                    : MISSING_DATA_TEXT
                            }
                        />
                    ))}
                </CardRowStack>
            ) : (
                <Text skinny>None recorded</Text>
            )}
        </MedicalRecordPanelCard>
    );
};
