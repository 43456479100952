import { ChangeEvent } from "react";

import { Card, FormFieldWrapper, Link, Switch, Text } from "@accurx/design";

import { PostAppointmentData } from "../types";
import { reducePostAppointmentMessage } from "../utils/reminderFormValidation";
import { StyledMessageComposeDefault } from "./AppointmentReminder.styles";
import { CharLimitErrorMessage } from "./CharLimitErrorMessage";
import { TextArea } from "./TextArea.styles";

export interface PostAppointmentProps {
    formData: PostAppointmentData;
    onToggle: (event: ChangeEvent<HTMLInputElement>) => void;
    onMessageInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    messageParts: {
        greeting: string;
        templateText: string;
        changeLinkText: string;
        messageSignoff: string;
    };
    charAndFragmentInfo: string;

    validationErrors?: string[];
    spacing?: 2 | 3;
}
export const PostAppointment = ({
    formData,
    onToggle,
    onMessageInput,
    messageParts,
    charAndFragmentInfo,
    validationErrors,
    spacing,
}: PostAppointmentProps) => {
    const { postAppointmentMessageEnabled, postAppointmentMessageBody } =
        formData;

    const textareaId = "post-appointment-message";

    return (
        <Card
            spacing={spacing || 2}
            header={
                <Text skinny variant="subtitle" as="h2">
                    Post-appointment message
                </Text>
            }
        >
            <>
                <Text skinny>
                    This will send an automated message to patient 24 hours
                    after their appointment, for example, Friends and Family
                    Test (FFT) invitations.
                </Text>
                <Text className="text-block-margin">
                    Learn{" "}
                    <Link
                        href="http://support.accurx.com/en/articles/6259747"
                        openInNewTab
                    >
                        <Link.Text text="how to use Post-appointment message" />
                        <Link.Icon />
                    </Link>
                </Text>

                <FormFieldWrapper
                    id="post-appointment-switch-label"
                    label="Send post appointment message"
                >
                    <Switch
                        id="postAppointmentSwitch"
                        ariaLabelledby="post-appointment-switch-label"
                        testId="post-appointment-switch"
                        controlled
                        checked={postAppointmentMessageEnabled}
                        onChange={onToggle}
                        labelText={postAppointmentMessageEnabled ? "On" : "Off"}
                    />
                </FormFieldWrapper>

                {postAppointmentMessageEnabled && (
                    <div>
                        <FormFieldWrapper
                            label="Message"
                            labelProps={{ htmlFor: textareaId }}
                            errors={validationErrors?.map((error) => {
                                if (error === reducePostAppointmentMessage) {
                                    return <CharLimitErrorMessage />;
                                }

                                return error;
                            })}
                            metaInfo={charAndFragmentInfo}
                        >
                            <StyledMessageComposeDefault>
                                <Text skinny variant="body">
                                    {messageParts.greeting}
                                </Text>
                                <TextArea
                                    id={textareaId}
                                    value={postAppointmentMessageBody}
                                    onChange={onMessageInput}
                                    data-testid="post-message-body"
                                    rows={4}
                                    $hasErrors={!!validationErrors?.length}
                                />
                                <Text skinny variant="body">
                                    {messageParts.messageSignoff}
                                </Text>
                            </StyledMessageComposeDefault>
                        </FormFieldWrapper>
                    </div>
                )}
            </>
        </Card>
    );
};
