import { Button, Tokens } from "@accurx/design";
import { useHistory } from "react-router";
import styled from "styled-components";

import { trackClinicBackButtonClick } from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

const StyledWrapper = styled("nav")`
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1.5]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
`;

type TopNavigationProps = {
    previousPageUrl: string;
};

export const TopNavigation = ({ previousPageUrl }: TopNavigationProps) => {
    const history = useHistory();
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();

    const navigateBack = () => {
        trackClinicBackButtonClick({
            ...flemingLoggedInProperties,
            origin: history.location.pathname,
        });
        history.push(previousPageUrl);
    };

    return (
        <StyledWrapper>
            <Button
                text="Back"
                theme="transparent"
                dimension="medium"
                icon={{
                    name: "ArrowTail",
                    rotation: "left",
                    placement: "start",
                }}
                onClick={navigateBack}
            />
        </StyledWrapper>
    );
};
