import React from "react";

import {
    Input,
    SelectItem,
    SingleSelect,
    Switch,
    Text,
    VisuallyHidden,
} from "@accurx/design";

import { WorkingHours } from "app/practices/patientTriage/types";

import {
    StyledTableDataCell,
    StyledTableRow,
} from "../WorkingHoursTable.styles";
import {
    StyledInlineContainer,
    StyledInputWrapper,
    StyledText,
    StyledTimeInputWrapper,
} from "./WorkingHoursRow.styles";
import {
    getCurrentDay,
    getCurrentDaySlug,
    getDefaultClosingTime,
    getValidClosingTimes,
    times,
} from "./WorkingHoursRow.utils";

type WorkingHoursRowProps = {
    workDay: WorkingHours;
    updateWorkDay: (updatedWorkDay: WorkingHours) => void;
};

const DEFAULT_OPENING_TIME = "09:00";
const DEFAULT_CLOSING_TIME = "17:00";

export const WorkingHoursRow = ({
    workDay,
    updateWorkDay,
}: WorkingHoursRowProps): JSX.Element => {
    const hasLimit = (workDay?.limit ?? 0) > 0 || !!workDay.limit;
    const isOpenToday = !!workDay.from && !!workDay.to;

    const handleIsOpenToday = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        if (checked) {
            updateWorkDay({
                ...workDay,
                from: DEFAULT_OPENING_TIME,
                to: DEFAULT_CLOSING_TIME,
                limit: null,
            });
        } else {
            updateWorkDay({ ...workDay, from: null, to: null, limit: null });
        }
    };

    const handleFrom = (value: string) => {
        const to = getDefaultClosingTime({
            closingTime: workDay.to,
            validClosingTimes: getValidClosingTimes(value),
            preferredDefaultClosingTime: DEFAULT_CLOSING_TIME,
        });
        updateWorkDay({ ...workDay, from: value, to });
    };

    const handleTo = (value: string) => {
        updateWorkDay({ ...workDay, to: value });
    };

    const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (value > 9999) return;
        updateWorkDay({ ...workDay, limit: (value || 1) ?? null });
    };

    const toggleLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
            updateWorkDay({ ...workDay, limit: 500 });
        } else {
            updateWorkDay({ ...workDay, limit: null });
        }
    };

    const currentDay = getCurrentDay(workDay.day);
    const currentDaySlug = getCurrentDaySlug(workDay.day);

    const closingTimes = getValidClosingTimes(
        workDay.from ?? DEFAULT_OPENING_TIME,
    );
    const defaultClosingTime = getDefaultClosingTime({
        closingTime: workDay.to,
        validClosingTimes: closingTimes,
        preferredDefaultClosingTime: DEFAULT_CLOSING_TIME,
    });

    return (
        <StyledTableRow>
            <StyledTableDataCell>{currentDay}</StyledTableDataCell>
            <StyledTableDataCell>
                <Switch
                    labelText={
                        <VisuallyHidden as="span">
                            Is open on {currentDay}
                        </VisuallyHidden>
                    }
                    labelVariant="body"
                    size="small"
                    defaultChecked={isOpenToday}
                    onChange={handleIsOpenToday}
                />
            </StyledTableDataCell>
            <StyledTableDataCell>
                {isOpenToday ? (
                    <StyledInlineContainer>
                        <StyledTimeInputWrapper>
                            <VisuallyHidden
                                as="label"
                                htmlFor={`from-${currentDaySlug}`}
                            >
                                {currentDay} opening time
                            </VisuallyHidden>
                            <SingleSelect
                                dimension="medium"
                                id={`from-${currentDaySlug}`}
                                defaultValue={
                                    workDay.from ?? DEFAULT_OPENING_TIME
                                }
                                placeholder="From"
                                onValueChange={handleFrom}
                            >
                                {times.map((time) => (
                                    <SelectItem key={time} value={time}>
                                        {time}
                                    </SelectItem>
                                ))}
                            </SingleSelect>
                        </StyledTimeInputWrapper>
                        To
                        <StyledTimeInputWrapper>
                            <VisuallyHidden
                                as="label"
                                htmlFor={`to-${currentDaySlug}`}
                            >
                                {currentDay} closing time
                            </VisuallyHidden>
                            <SingleSelect
                                key={`${currentDaySlug}-${workDay.from}`}
                                dimension="medium"
                                id={`to-${currentDaySlug}`}
                                defaultValue={defaultClosingTime}
                                placeholder="To"
                                onValueChange={handleTo}
                            >
                                {closingTimes.map((time) => (
                                    <SelectItem key={time} value={time}>
                                        {time}
                                    </SelectItem>
                                ))}
                            </SingleSelect>
                        </StyledTimeInputWrapper>
                    </StyledInlineContainer>
                ) : (
                    <StyledText skinny colour="red">
                        Closed
                    </StyledText>
                )}
            </StyledTableDataCell>
            <StyledTableDataCell>
                {isOpenToday ? (
                    <Switch
                        labelText={
                            <VisuallyHidden as="span">
                                Has limit on {currentDay}
                            </VisuallyHidden>
                        }
                        labelVariant="body"
                        size="small"
                        disabled={!isOpenToday}
                        defaultChecked={isOpenToday && hasLimit}
                        onChange={toggleLimit}
                    />
                ) : (
                    <StyledText skinny>N/A</StyledText>
                )}
            </StyledTableDataCell>
            <StyledTableDataCell>
                {isOpenToday ? (
                    <>
                        {hasLimit ? (
                            <StyledInputWrapper>
                                <VisuallyHidden
                                    as="label"
                                    htmlFor={`limit-${currentDaySlug}`}
                                >
                                    {currentDay} limit
                                </VisuallyHidden>
                                <Input
                                    id={`limit-${currentDaySlug}`}
                                    type="number"
                                    min={1}
                                    max={9999}
                                    inputMode="numeric"
                                    value={workDay.limit ?? ""}
                                    onChange={handleLimitChange}
                                    placeholder="Enter limit"
                                />
                            </StyledInputWrapper>
                        ) : (
                            <StyledText skinny colour="green">
                                No limit
                            </StyledText>
                        )}
                    </>
                ) : (
                    <StyledText skinny>N/A</StyledText>
                )}
            </StyledTableDataCell>
        </StyledTableRow>
    );
};

export const StaticWorkingHoursRow = ({
    workDay,
}: Omit<WorkingHoursRowProps, "updateWorkDay">): JSX.Element => {
    const isOpenToday = !!workDay.from && !!workDay.to;
    const hasLimit = !!workDay.limit;

    return (
        <StyledTableRow>
            <StyledTableDataCell>
                {getCurrentDay(workDay.day)}
            </StyledTableDataCell>
            <StyledTableDataCell>
                {isOpenToday ? (
                    <StyledInlineContainer>
                        <StyledTimeInputWrapper>
                            <Text skinny>{workDay.from}</Text>
                        </StyledTimeInputWrapper>
                        To
                        <StyledTimeInputWrapper>
                            <Text skinny>{workDay.to}</Text>
                        </StyledTimeInputWrapper>
                    </StyledInlineContainer>
                ) : (
                    <StyledText skinny colour="red">
                        Closed
                    </StyledText>
                )}
            </StyledTableDataCell>
            <StyledTableDataCell>
                {isOpenToday ? (
                    <>
                        {hasLimit ? (
                            <Text skinny>{workDay.limit}</Text>
                        ) : (
                            <StyledText skinny colour="green">
                                No limit
                            </StyledText>
                        )}
                    </>
                ) : (
                    <StyledText skinny>N/A</StyledText>
                )}
            </StyledTableDataCell>
        </StyledTableRow>
    );
};
