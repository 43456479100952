import { FeatureName } from "@accurx/auth";
import { TabProps } from "@accurx/design";

import { useIsFeatureEnabled } from "store/hooks";

import { TAB_PAGES, TabPageType } from "./PatientProfile.types";

/**
 * @returns a list of available tabs based on feature availability
 */
export const usePatientProfileTabs = (): TabProps<TabPageType>[] => {
    const collaborativeWebInboxEnabled = useIsFeatureEnabled(
        FeatureName.CollaborativeWebInbox,
    );
    const wayFinderFeaturesEnabled = useIsFeatureEnabled(FeatureName.Wayfinder);

    const tabs: TabProps<TabPageType>[] = [];

    tabs.push({ tabId: TAB_PAGES.overview, text: "Overview" });

    if (collaborativeWebInboxEnabled) {
        tabs.push({
            tabId: TAB_PAGES.allConversations,
            text: "All conversations",
        });
    }

    tabs.push({
        tabId: TAB_PAGES.myConversations,
        text: "My conversations",
    });

    if (wayFinderFeaturesEnabled) {
        tabs.push({ tabId: TAB_PAGES.documents, text: "Documents" });
    }

    return tabs;
};
