import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { HasErrorProps } from "./FlemingAnalyticsConstants";

/** The unique identifier for a florey questionnaire */
type ConditionId = { conditionId: string };

/** The possible CTAs for a questionnaire response */
type ContactOptionSelected = {
    optionSelected: "ViewFullConversation" | "RequestGpRecord" | "ViewProfile";
};

/** The unique identifier for an instance of a florey questionnaire sent to a patient */
type FloreyEnrolmentId = {
    floreyEnrolmentId: number;
};

export type Filter =
    | "DateStart"
    | "DateEnd"
    | "AnsweredStart"
    | "AnsweredEnd"
    | "Status"
    | "Complexity";

type FilterName = {
    filterName: Array<Filter>;
};

type HasAdditionalNotes = {
    hasAdditionalNotes: boolean;
};

type QuestionnaireResponseCount = {
    questionnaireResponseCount: number;
};

export type SortOptions = {
    sortField: "dateSent" | "patientName" | "status" | "complexity" | "unknown";
    sortDirection: "ascending" | "descending" | "unknown";
};

export const mapDisplaySortFieldToAnalyticsSortField = (
    displaySortField: string,
): SortOptions["sortField"] => {
    if (displaySortField === "patient") {
        return "patientName";
    }
    if (displaySortField === "status") {
        return "status";
    }
    if (displaySortField === "complexity") {
        return "complexity";
    }
    if (displaySortField === "sentAt") {
        return "dateSent";
    }

    return "unknown";
};

export const mapDisplaySortDirectionToAnalyticsSortDirection = (
    displaySortDirection: string,
): SortOptions["sortDirection"] => {
    if (displaySortDirection === "asc") {
        return "ascending";
    }
    if (displaySortDirection === "desc") {
        return "descending";
    }

    return "unknown";
};

/**
 * Triggered when a user lands on the questionnaire responses page
 *
 * Event type: Page View
 * */
export const trackQuestionnaireResponsesPageView = (
    props: LoggedInCustomProperties & ConditionId & QuestionnaireResponseCount,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponses",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

/**
 * Triggered when a user clicks on one of the contact options on a questionnaire response entry
 *
 * Event type: MenuItem Click
 * */
export const trackQuestionnaireResponsesContactOptionClick = (
    props: LoggedInCustomProperties & ConditionId & ContactOptionSelected,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponsesContact",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Triggered when a user clicks on one of the column headings to sort the questionnaire responses
 *
 * Event type: Button Click
 * */
export const trackQuestionnaireResponsesColumnSortClick = (
    props: LoggedInCustomProperties & ConditionId & SortOptions,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponsesTableSortColumn",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Triggered when a user clicks on a questionnaire response entry to view the full response
 *
 * Event type: Button Click
 * */
export const trackQuestionnaireResponsesViewResponse = (
    props: LoggedInCustomProperties & ConditionId & FloreyEnrolmentId,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponsesTableViewResponse",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Triggered when a user clicks to apply selected filters
 *
 * Event type: Button Click
 * */
export const trackQuestionnaireResponsesFilterApplyClick = (
    props: LoggedInCustomProperties & ConditionId & FilterName,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponsesApplyFilters",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * Triggered when the response to a clinician validation is received
 *
 * Event type: Button Response
 * */
export const trackQuestionnaireResponsesClinicianValidation = (
    props: LoggedInCustomProperties &
        ConditionId &
        FloreyEnrolmentId &
        HasAdditionalNotes &
        HasErrorProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "QuestionnaireResponsesClinicianValidation",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: fullProps,
    });
};
