import {
    Children,
    ReactNode,
    isValidElement,
    useEffect,
    useState,
} from "react";

import { Button, Card, Ds, Text } from "@accurx/design";

import { CardSpacer } from "./CardSpacer";
import { useMedicalRecordColumns } from "./MedicalRecordColumnContext";
import { MedicationsList } from "./Medications";
import { ProblemList } from "./Problems";

export type AllergiesBadge = {
    text: string;
    color: Ds.BadgeProps["color"];
};

export type MedicalRecordPanelCardProps = {
    children: ReactNode;
    title: string;
    count?: number;
    nested?: boolean;
    badge?: AllergiesBadge;
    headerButton?: { text: string; onClick: () => void };
    /** [optional] If true, the card will render as collapsed initially */
    collapsible?: boolean;
};

export const MedicalRecordPanelCard = ({
    title,
    count,
    nested,
    children,
    badge,
    headerButton,
    collapsible,
}: MedicalRecordPanelCardProps): JSX.Element => {
    const { column } = useMedicalRecordColumns();
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Set default collapsed state to true if the card is indeed collapsible
    useEffect(() => {
        if (collapsible) {
            setIsCollapsed(true);
        }
    }, [collapsible]);

    const toggleExpanded = () => {
        setIsCollapsed((prevState) => !prevState);
    };

    // Check if we should have the title inside a header or not
    let titleInHeader = true;
    Children.forEach(children, (child) => {
        if (
            isValidElement(child) &&
            (child.type === ProblemList || child.type === MedicationsList)
        ) {
            titleInHeader = false;
        }
    });

    const badgeElement =
        badge !== undefined && titleInHeader ? (
            <Ds.Badge color={badge.color}>{badge.text}</Ds.Badge>
        ) : null;
    const buttonElement =
        headerButton !== undefined && titleInHeader ? (
            <Button
                theme="secondary"
                text={headerButton.text}
                onClick={headerButton.onClick}
            />
        ) : null;

    let titleElement = nested ? (
        <Text variant="label" as="h5" skinny>
            {title}
            {count !== undefined && (
                <Text variant="body" as="span">
                    {" "}
                    · {count}
                </Text>
            )}
        </Text>
    ) : (
        <Text variant="subtitle" as="h3" skinny>
            {title}
            {count !== undefined && (
                <Text variant="subtitle" as="span">
                    {" "}
                    · {count}
                </Text>
            )}
        </Text>
    );

    titleElement =
        badgeElement !== null || buttonElement !== null ? (
            <div
                className={`d-flex flex-wrap ${
                    buttonElement ? "justify-content-between " : ""
                }align-items-center`}
            >
                <span className="mr-2">{titleElement}</span>
                {buttonElement}
                {/* Button element takes precedence over a badge element */}
                {!buttonElement && badgeElement}
            </div>
        ) : (
            titleElement
        );

    titleElement = collapsible ? (
        <div
            className="d-flex justify-content-between align-items-center"
            onClick={toggleExpanded}
            style={{ cursor: "pointer" }}
        >
            {titleElement}
            <Button
                aria-label="Expand"
                icon={{
                    name: "Arrow",
                    rotation: isCollapsed ? "down" : "up",
                    colour: "night",
                    title: "Toggle",
                    id: "toggle-btn",
                }}
                theme="transparent"
                dimension="large"
            />
        </div>
    ) : (
        titleElement
    );

    const spacing = column === "right" ? 2 : 3;

    return (
        <Card
            spacing={spacing}
            isElevated={!nested}
            header={titleInHeader ? titleElement : undefined}
            spacingBody={isCollapsed && titleInHeader ? 0 : spacing}
        >
            {!titleInHeader && (
                <>
                    {titleElement}
                    {!isCollapsed && <CardSpacer spacing="small" />}
                </>
            )}
            {!isCollapsed && children}
        </Card>
    );
};
