import { Button } from "@accurx/design";

import { useMobileLayout } from "../Mobile/MobileLayoutContext";
import { HelpPopover } from "../PrimaryNavigation/HelpPopover/HelpPopover";
import { StyledMobileHeader } from "./MobileHeader.styles";

export const MobileHeader = () => {
    const { modalNav } = useMobileLayout();

    return (
        <StyledMobileHeader>
            <Button
                theme="transparent"
                dimension="medium"
                icon={{ name: "Menu", title: "Menu" }}
                onClick={modalNav.toggle}
            />
            <HelpPopover>
                <Button
                    theme="transparent"
                    dimension="medium"
                    icon={{ name: "Question", title: "Help" }}
                />
            </HelpPopover>
        </StyledMobileHeader>
    );
};
