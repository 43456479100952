import React, { useState } from "react";

import { Button } from "@accurx/design";
import { QuickViewPortal } from "@accurx/quick-view";

import {
    MessageTemplate,
    MessageTemplateOwner,
    MessageTemplateType,
} from "api/FlemingDtos";
import { FlemingAnalyticsTracker } from "app/analytics";
import { CreateMessageTemplate } from "app/messageTemplates/CreateMessageTemplate";
import { getNewMessageTemplate } from "app/messageTemplates/MessageTemplatesHelper";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { generateSignature } from "shared/MessageHelper";
import { useAppSelector, useCurrentOrgName } from "store/hooks";

import { generateMessage } from "../Conversation/Conversation.helpers";
import { StyledFooterWrapper } from "./MessageTemplate.styles";
import { MessageTemplates } from "./MessageTemplates";
import { Template } from "./MessageTemplates.types";
import { TemplatePreview } from "./components/TemplatePreview";

type CurrentView =
    | { view: "templateList" }
    | { view: "createTemplateForm" }
    | { view: "previewTemplate"; template: Template };

type MessageTemplatesQuickViewProps = {
    onClose: () => void;
    patientGreeting?: string;
    workspaceId: number;
    onClickUseTemplate: (template: Template) => void;
    onSuccessCreateMessageTemplate: (template: MessageTemplate) => void;
    showCreateTemplateForm?: boolean;
};

export const MessageTemplatesQuickView = ({
    onClose,
    patientGreeting,
    workspaceId,
    onClickUseTemplate,
    onSuccessCreateMessageTemplate,
    showCreateTemplateForm,
}: MessageTemplatesQuickViewProps) => {
    const [currentView, setCurrentView] = useState<CurrentView>(
        showCreateTemplateForm
            ? { view: "createTemplateForm" }
            : {
                  view: "templateList",
              },
    );

    const loggedInProps = useFlemingLoggedInAnalytics();

    const workspaceName = useCurrentOrgName();

    const messageSignature = useAppSelector((state) =>
        generateSignature(state.account.user),
    );

    const baseNewTemplate = getNewMessageTemplate({
        owner: MessageTemplateOwner.User,
        type: MessageTemplateType.Sms,
    });

    const handleOnClickPreviewTemplate = (template: Template) => {
        setCurrentView({ view: "previewTemplate", template });

        FlemingAnalyticsTracker.trackMessageTemplatePreviewButtonClick({
            ...loggedInProps,
            hasError: false,
            searchString: "None",
            templateGroup: template.group ?? null,
            templateName: template.title,
            templateType: "sms",
        });
    };

    const handleOnUseTemplateClick = (
        template: Template,
        fromQuickView = false,
    ) => {
        FlemingAnalyticsTracker.trackMessageTemplateUseButtonClick({
            ...loggedInProps,
            hasError: false,
            searchString: "None",
            templateGroup: template.group ?? null,
            templateName: template.title,
            fromQuickView: fromQuickView,
            pageOrigin: "WebInboxMessageTemplatePage",
            templateType: "sms",
            countAttachment: template.attachedDocuments?.length ?? 0,
        });
        onClickUseTemplate(template);
    };

    switch (currentView.view) {
        case "templateList": {
            return (
                <QuickViewPortal isOpen onClose={onClose}>
                    <QuickViewPortal.Header>
                        <Button
                            onClick={onClose}
                            text="Close"
                            icon={{
                                name: "Cross",
                                colour: "metal",
                                style: "Line",
                            }}
                            theme="transparent"
                        />
                    </QuickViewPortal.Header>
                    <QuickViewPortal.Body>
                        <MessageTemplates
                            onClickCreateTemplate={() =>
                                setCurrentView({ view: "createTemplateForm" })
                            }
                            onClickUseTemplate={handleOnUseTemplateClick}
                            workspaceId={workspaceId}
                            handleOnClickPreviewTemplate={
                                handleOnClickPreviewTemplate
                            }
                        />
                    </QuickViewPortal.Body>
                </QuickViewPortal>
            );
        }
        case "createTemplateForm": {
            return (
                <QuickViewPortal isOpen onClose={onClose}>
                    <QuickViewPortal.Header>
                        <Button
                            onClick={onClose}
                            text="Close"
                            icon={{
                                name: "Cross",
                                colour: "metal",
                                style: "Line",
                            }}
                            theme="transparent"
                        />
                        <Button
                            onClick={() =>
                                setCurrentView({ view: "templateList" })
                            }
                            text="Back"
                            icon={{
                                name: "ArrowTail",
                                colour: "metal",
                                style: "Line",
                                rotation: "left",
                            }}
                            theme="transparent"
                        />
                    </QuickViewPortal.Header>
                    <QuickViewPortal.Body>
                        <CreateMessageTemplate
                            template={baseNewTemplate}
                            onSuccess={(template: MessageTemplate) => {
                                onSuccessCreateMessageTemplate(template);
                            }}
                            analyticsProps={{
                                pageOrigin: "WebInboxCreateTemplatePage",
                            }}
                        />
                    </QuickViewPortal.Body>
                </QuickViewPortal>
            );
        }
        case "previewTemplate": {
            const { template } = currentView;

            return (
                <QuickViewPortal isOpen onClose={onClose}>
                    <QuickViewPortal.Header>
                        <Button
                            onClick={onClose}
                            text="Close"
                            icon={{
                                name: "Cross",
                                colour: "metal",
                                style: "Line",
                            }}
                            theme="transparent"
                        />
                        <Button
                            onClick={() => {
                                setCurrentView({ view: "templateList" });
                            }}
                            text="Back"
                            icon={{
                                name: "ArrowTail",
                                colour: "metal",
                                style: "Line",
                                rotation: "left",
                            }}
                            theme="transparent"
                        />
                    </QuickViewPortal.Header>
                    <QuickViewPortal.Body>
                        <TemplatePreview
                            title={template.title}
                            body={generateMessage({
                                greeting: patientGreeting,
                                body: template.body,
                                signature: messageSignature,
                            })}
                            practiceName={workspaceName}
                            attachedDocuments={template.attachedDocuments}
                        />
                    </QuickViewPortal.Body>
                    <QuickViewPortal.Footer>
                        {template && (
                            <StyledFooterWrapper>
                                <Button
                                    onClick={() => {
                                        handleOnUseTemplateClick(
                                            template,
                                            true,
                                        );
                                    }}
                                    text="Use template"
                                    icon={{
                                        name: "Success",
                                        colour: "white",
                                        style: "Fill",
                                    }}
                                    theme="primary"
                                />
                            </StyledFooterWrapper>
                        )}
                    </QuickViewPortal.Footer>
                </QuickViewPortal>
            );
        }
    }
};
