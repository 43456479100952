import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import {
    AccumailTemplate,
    AccumailTemplateType,
    AccumailTemplatesResponse,
} from "app/practices/accumail/templates.types";

const ENDPOINTS = {
    templates: "/api/clinicianmessaging/workspaces/:workspaceId/templates",
    template:
        "/api/clinicianmessaging/workspaces/:workspaceId/templates/:templateId",
} as const;

export const getAccumailTemplates = (
    workspaceId: number,
    templateType: AccumailTemplateType,
): Promise<IWrappedResult<AccumailTemplatesResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.templates,
            params: { workspaceId: workspaceId.toString() },
            query: `?templateType=${templateType}`,
        }),
    );

export const getAccumailTemplate = (
    workspaceId: number,
    templateId: string,
): Promise<IWrappedResult<AccumailTemplate>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.template,
            params: { workspaceId: workspaceId.toString(), templateId },
        }),
    );

export const createAccumailTemplate = (
    workspaceId: number,
    template: Omit<AccumailTemplate, "id">,
): Promise<IWrappedResult<AccumailTemplate>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.templates,
            params: { workspaceId: workspaceId.toString() },
        }),
        template,
    );

export const editAccumailTemplate = (
    workspaceId: number,
    templateId: string,
    template: Pick<AccumailTemplate, "title" | "content" | "snomedCodes">,
): Promise<IWrappedResult<AccumailTemplate>> =>
    httpClient.putJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.template,
            params: { workspaceId: workspaceId.toString(), templateId },
        }),
        template,
    );

export const deleteAccumailTemplate = (
    workspaceId: number,
    templateId: string,
): Promise<IWrappedResult<null>> =>
    httpClient.deleteReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.template,
            params: { workspaceId: workspaceId.toString(), templateId },
        }),
    );
