import * as UI from "@accurx/design";

import { StyledNoMobileVerificationContainer } from "./NoMobileVerificationWarning.styles";

export const NoMobileVerificationWarning = () => {
    return (
        <StyledNoMobileVerificationContainer>
            <UI.Feedback
                colour="warning"
                iconName="Warning"
                title="Patient was unable to complete mobile phone verification"
            >
                <UI.Text skinny>
                    If you have reason to believe this is not the correct
                    patient, please contact them to confirm their identity.{" "}
                    <UI.Link
                        href="https://support.accurx.com/en/articles/4247693-patient-triage-how-does-patient-identification-work"
                        openInNewTab
                    >
                        Learn about how we verify patient identity
                        <UI.Link.Icon />
                    </UI.Link>
                </UI.Text>
            </UI.Feedback>
        </StyledNoMobileVerificationContainer>
    );
};
