import * as UI from "@accurx/design";
import styled from "styled-components";

/*
    Temporary UI to go back to composing a message
*/
export const StyledBackButtonWrapper = styled.div`
    padding: ${UI.Tokens.SIZES[1]};
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
`;
export const StyledDivider = styled.hr`
    margin-top: 0;
    margin-bottom: 0;
`;
