import React from "react";

import { GPRequestResult, Patient } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { DateHelpers, SupportUrls } from "@accurx/shared";

import { PatientListAppointment } from "api/FlemingDtos";
import { VideoCompatibilityState } from "app/selectProduct/VideoCompatibilityReducer";
import { PatientHelper } from "shared/PatientHelper";

import { DisabledInfo } from "./FeatureButton";

export const isMessagePatientDisabled = (
    patient: Patient | null,
): DisabledInfo | null => {
    if (!patient) {
        return null;
    }

    if (patient?.deceased) {
        return {
            title: defaultTitle,
            description: "This feature is unavailable for deceased patients",
        };
    }
    return null;
};

export const isVideoConsultDisabled = (
    patient: Patient | null,
    appointment: PatientListAppointment | null,
    videoCompatibility: VideoCompatibilityState,
): DisabledInfo | null => {
    if (!patient) {
        return null;
    }

    if (patient?.deceased) {
        return {
            title: defaultTitle,
            description: "This feature is unavailable for deceased patients",
        };
    }

    // Video compatibility checks
    if (
        videoCompatibility.hasChecked === false ||
        videoCompatibility.isChecking
    ) {
        return {
            title: "Detecting video compatibility",
            description: null,
        };
    }
    if (videoCompatibility.browserCheck.ok === false) {
        return {
            title: "Your browser is not supported",
            description:
                videoCompatibility.browserCheck.solution ??
                "Try on another device",
        };
    }

    if (videoCompatibility.networkCheck.ok === false) {
        return {
            title: defaultTitle,
            description: (
                <>
                    Your network firewall appears to be blocking our
                    connections.
                    <br />
                    <a
                        href={
                            SupportUrls.ISeeNetworkFirewallBlockingConnections
                        }
                    >
                        See this article for more details
                    </a>
                </>
            ),
        };
    }

    if (appointment?.hasSentVideoConsultInvite === true) {
        return {
            title: "This patient has a video consult scheduled for this appointment",
            description: (
                <>
                    <b>To send an invite now:</b>
                    <ul>
                        <li>
                            Search for the patient using their NHS No and D.O.B
                            from the homepage.
                        </li>
                        <li>
                            Add the patient to your 'anytime list' to send a new
                            invite
                        </li>
                    </ul>
                </>
            ),
        };
    }

    const isVideoConsultBeforeToday = (
        appointment: PatientListAppointment | null,
    ) =>
        appointment &&
        appointment.dateTimeStart !== null &&
        DateHelpers.isBeforeToday(appointment.dateTimeStart);

    if (isVideoConsultBeforeToday(appointment)) {
        return {
            title: "This video consult has been scheduled in the past",
            description: (
                <>
                    <b>To send an invite now:</b>
                    <ul>
                        <li>
                            Search for the patient using their NHS No and D.O.B
                            from the homepage.
                        </li>
                        <li>
                            Add the patient to your 'anytime list' to send a new
                            invite
                        </li>
                    </ul>
                </>
            ),
        };
    }
    return null;
};

export const isRecordViewDisabled = (
    patient: Patient | null,
): DisabledInfo | null => {
    if (!patient) {
        return null;
    }

    if (patient?.recordViewSupported === GPRequestResult.Success) {
        return null;
    }
    const recordViewUnavailableTitle = "Request GP record unavailable";
    const patientDisplayName = getPatientDisplayName(patient);
    switch (patient?.recordViewSupported) {
        case GPRequestResult.FailureNoValidMobile:
            return {
                title: recordViewUnavailableTitle,
                description: `${patientDisplayName} does not have a mobile phone number in PDS. Please ask ${patientDisplayName} to update their details with the GP practice in order to use Record View`,
            };
        case GPRequestResult.FailureNoActiveUsers:
        case GPRequestResult.FailureNoRecentConnection:
            return {
                title: recordViewUnavailableTitle,
                description: `${patientDisplayName}'s GP practice is not actively using Accurx`,
            };
        case GPRequestResult.FailureGpNotOptedIn:
            return {
                title: recordViewUnavailableTitle,
                description: `${patientDisplayName}'s GP practice hasn't yet switched on this feature`,
            };
        case GPRequestResult.FailureUnsupportedSystem:
            return {
                title: recordViewUnavailableTitle,
                description: `${patientDisplayName}'s GP practice uses a clinical system that is not yet supported`,
            };
        case GPRequestResult.FailureNoOrganisation:
            return {
                title: recordViewUnavailableTitle,
                description: `${patientDisplayName}'s GP practice does not use Accurx`,
            };
        default:
            return {
                title: recordViewUnavailableTitle,
                description: `This feature is not available${
                    patient !== null ? " for " + patient.firstName : ""
                }`,
            };
    }
};

export const isMessageGpDisabled = (
    patient: Patient | null,
): DisabledInfo | null => {
    if (!patient) {
        return null;
    }

    const isAccuMailEnabledForPatientPractice = PatientHelper.isFeatureEnabled(
        patient,
        FeatureName.CaregiverInitiated,
    );
    if (!isAccuMailEnabledForPatientPractice) {
        return {
            title: "Message GP practice unavailable",
            description: `${getPatientDisplayName(
                patient,
            )}'s GP practice does not use Accurx`,
        };
    }
    return null;
};

const getPatientDisplayName = (patient: Patient | null): string => {
    return patient?.firstName || "This patient";
};
const defaultTitle = "Feature unavailable";

export const getRequestRemainingHoursTextOnly = (
    accessRemainingHours: number,
): string => {
    return accessRemainingHours > 0
        ? `${accessRemainingHours} hr${accessRemainingHours === 1 ? "" : "s"} `
        : "under 1 hour";
};
