//This is not fully strict and acts as a hint only due to
//IE11 polyfill not working as other browsers do i.e. catching <script tags
//Is checked more thoroughly on the server
export const isURLValid = (str: string): boolean => {
    try {
        new URL(str);
        return true;
    } catch {
        return false;
    }
};
