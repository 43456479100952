import React from "react";

import { Link } from "@accurx/design";
import ReactLinkify from "react-linkify";
import styled from "styled-components";

/**
 * A wrapper around the ReactLinkify component, ensuring we always render
 * the Link component from the design system.
 * Defaults to opening links in new tab.
 */
type LinkifyProps = { children: React.ReactNode };
const Linkify = ({ children }: LinkifyProps): JSX.Element => (
    <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
            <AutoWidthLink
                href={decoratedHref}
                text={decoratedText}
                key={key}
                openInNewTab={true}
            />
        )}
    >
        {children}
    </ReactLinkify>
);

const AutoWidthLink = styled(Link)`
    width: auto;
`;

export default Linkify;
