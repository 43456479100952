import { Log } from "@accurx/shared";
import { mapConversationIdToConversationSource } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToConversationSource";
import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type Variables = {
    itemId: string;
    conversationId: string;
    attachmentId: string;
    reasonForDeletion: string;
    patientId?: string;
};

export const useDeleteAttachmentMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "deleteAttachmant",
        async (variables: Variables) => {
            const conversationSource = mapConversationIdToConversationSource(
                variables.conversationId,
            );

            if (conversationSource?.system !== "Ticket") {
                throw new Error(
                    "Deleting Attachment is only supported by Ticket conversations",
                );
            }

            // The delete attachment endpoint does not return anything so we do
            // not get back a conversation update. So that we can show the
            // updated item with the attachment deleted we need to refetch the
            // full conversation after deleting the attachment.
            //
            // NOTE: This is not the desired behaviour for mutations and should
            // be avoided where possible. Other endpoints return an update
            // object directly so we can immedietly display the update without
            // needing to refetch the ticket.
            await api.deleteAttachment({
                workspaceId,
                itemId: variables.itemId,
                attachmentId: variables.attachmentId,
                patientId: variables.patientId,
                reasonForDeletion: variables.reasonForDeletion,
            });

            try {
                const { updates } = await api.getConversation({
                    workspaceId,
                    ticketIdentity: conversationSource.ticketIdentity,
                });

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:deleteAttachment",
                        ...updates,
                    }),
                );
            } catch (err) {
                Log.error(
                    "An attachment was deleted but we weren't able to refresh the conversation",
                    {
                        tags: {
                            product: "Inbox",
                            conversationId: variables.conversationId,
                            itemId: variables.itemId,
                            attachmentId: variables.attachmentId,
                            originalError:
                                err instanceof Error
                                    ? err.message
                                    : (err as string),
                        },
                    },
                );
            }
        },
        options,
    );
};
