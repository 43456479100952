import * as TicketApiClient from "domains/concierge/internal/api/ticket/TicketApiClient";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type Variables = {
    itemServerId: string;
};

export const useSaveFloreyPdfToRecordMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "SaveFloreyToPdf",
        ({ itemServerId }: Variables) =>
            TicketApiClient.saveFloreyPdfToRecord({
                organisationId: workspaceId,
                floreyNoteId: itemServerId,
            }),
        options,
    );
};
