/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, {
    ChangeEvent,
    Fragment,
    MouseEvent,
    useEffect,
    useState,
} from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Card, Ds, Feedback, Icon, Input, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { useDispatch, useSelector } from "react-redux";

import { PracticeDetails } from "app/practices/Practices.types";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { getEnumKeyByEnumValue, getEnumKeys } from "shared/Typescript.helper";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import {
    deleteSession,
    getSiteSetupSessions,
    postSiteSetupSession,
    postSiteSetupSessionPublish,
    postSiteSetupSessionUnpublish,
} from "./SiteSetup.actions";
import { initialState as SiteSetupInitalState } from "./SiteSetup.reducer";
import {
    VaccineSiteSession,
    VaccineSiteSessionDetails,
    VaccineSiteSessionDetailsType,
    VaccineSiteSessionDoseRestriction,
    VaccineSiteSessionDoseRestrictionStrings,
    VaccineSiteSessionRecallType,
} from "./SiteSetup.types";
import { VaccineSiteSetupPublishModal } from "./SiteSetupPublishModal";

const HEADERS_STRING = [
    "Date",
    "Session start time",
    "Session end time",
    "Patients per hour",
    "Capacity",
    "Vaccine",
    "Dose",
    "Hidden clinic",
    "Set live",
    "",
];

export const DATE_FORMATS = [
    "DD/MM/YYYY",
    "DD/MM/YY",
    "D/M/YYYY",
    "D/M/YY",
    "DD-MM-YYYY",
    "DD-MM-YY",
    "D-M-YYYY",
    "D-M-YY",
];

const DATE_TIME_FORMATS = [
    "DD/MM/YYYY HH:mm",
    "DD/MM/YY HH:mm",
    "D/M/YYYY HH:mm",
    "D/M/YY HH:mm",
    "DD-MM-YYYY HH:mm",
    "DD-MM-YY HH:mm",
    "D-M-YYYY HH:mm",
    "D-M-YY HH:mm",
    "DD/MM/YYYY HHmm",
    "DD/MM/YY HHmm",
    "D/M/YYYY HHmm",
    "D/M/YY HHmm",
    "DD-MM-YYYY HHmm",
    "DD-MM-YY HHmm",
    "D-M-YYYY HHmm",
    "D-M-YY HHmm",
];

interface UpdatePatientPerHourState {
    sessionId: string;
    patientsPerHour: number;
}

interface UpdateAdminOnly {
    sessionId: string;
    adminOnly: boolean;
}

interface SessionOptions {
    display: string;
    type: number;
    enabled: boolean;
}

function CreateSessionOptions(
    display: string,
    type: number,
    enabled: boolean,
): SessionOptions {
    return {
        display,
        type,
        enabled,
    };
}

const calculateCapacity = (
    startTime: string,
    endTime: string,
    patientsPerHour: number,
): number => {
    const timeDiff = DateHelpers.getTimeDiffInMinutes(startTime, endTime);
    const patientPerMin = patientsPerHour / 60;
    return Math.ceil(timeDiff * patientPerMin);
};

let confirmSessionId = "";
let isPublishing = false;

const initialState = (siteSetupLocation: string): VaccineSiteSessionDetails => {
    return {
        id: "",
        date: "",
        startTime: "",
        endTime: "",
        patientsPerHour: 0,
        type: 0,
        capacity: 0,
        locationText: siteSetupLocation,
        isPopulated: false,
        isPublished: false,
        totalBooked: 0,
        reservedForClinicianBooking: false,
        vaccineDoseRestriction: VaccineSiteSessionDoseRestriction.None,
    };
};

const getDoseDisplayString = (
    dose: VaccineSiteSessionDoseRestrictionStrings,
): string => {
    switch (dose) {
        case VaccineSiteSessionDoseRestriction.FirstOnly:
            return "First Only";
        case VaccineSiteSessionDoseRestriction.SecondOnly:
            return "Second Only";
        case VaccineSiteSessionDoseRestriction.Both:
            return "Primary";
        case VaccineSiteSessionDoseRestriction.All:
            return "Any";
        case VaccineSiteSessionDoseRestriction.None:
            return "-";
        default:
            return dose;
    }
};

interface VaccineSiteSetupTableProps {
    initialShowPastSessions: boolean;
}

const VaccineSiteSetupTable = (
    props: VaccineSiteSetupTableProps,
): JSX.Element => {
    const dispatch = useDispatch();

    const {
        siteSetupId,
        siteSetupLocation,
        siteSetupSessions,
        siteSetupSessionsError,
        siteSetupSessionError,
        siteSetupSessionDeleting,
        siteSetupSessionDeletingError,
    } = useSelector(
        ({ vaccineSiteSetup }: ApplicationState) =>
            vaccineSiteSetup || SiteSetupInitalState,
    );
    const siteSetupSessionLoading = useAppSelector(
        ({ vaccineSiteSetup }) => vaccineSiteSetup.siteSetupSessionLoading,
    );

    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );

    const practiceFeatures = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.features ?? [],
    );

    const featureFlagPaediatric: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePaediatric,
    );

    const featureFlagSanofi: boolean = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.VaccinePracticeSanofi,
    );

    const isAutumn2023Enabled = useIsFeatureEnabled(
        FeatureName.VaccinePracticeAutumn2023BoosterTab,
    );

    const VACCINE_TYPE_LIST: SessionOptions[] = [
        CreateSessionOptions("-", VaccineSiteSessionRecallType.None, true),
        CreateSessionOptions(
            "Pfizer",
            VaccineSiteSessionRecallType.CovidPfizer,
            true,
        ),
        CreateSessionOptions(
            "AstraZeneca",
            VaccineSiteSessionRecallType.CovidAstraZeneca,
            true,
        ),
        CreateSessionOptions(
            "Moderna",
            VaccineSiteSessionRecallType.CovidModerna,
            true,
        ),
        CreateSessionOptions(
            "Janssen",
            VaccineSiteSessionRecallType.CovidJanssen,
            true,
        ),
        CreateSessionOptions(
            "Paeds5-11Pfizer",
            VaccineSiteSessionRecallType.CovidPfizerPaediatric,
            featureFlagPaediatric,
        ),
        CreateSessionOptions(
            "Sanofi",
            VaccineSiteSessionRecallType.CovidSanofi,
            featureFlagSanofi,
        ),
    ];

    const [showPastSessions, setShowPastSessions] = useState(
        props.initialShowPastSessions,
    );
    const [currentRow, setCurrentRow] = useState(
        initialState(siteSetupLocation),
    );
    const [editingRow, setEditingRow] = useState(
        initialState(siteSetupLocation),
    );
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [validationError, setValidationError] = useState({
        id: "",
        message: "",
        inputField: "",
        typeFeedback: "",
    });
    const [updatedPatientsPerHour, setUpdatedPatientsPerHour] =
        useState<UpdatePatientPerHourState>({
            sessionId: "",
            patientsPerHour: 0,
        });
    const [updatedAdminOnly, setUpdatedAdminOnly] = useState<UpdateAdminOnly>({
        sessionId: "",
        adminOnly: false,
    });

    const [availableDoses, setAvailableDoses] = useState(
        getEnumKeys(VaccineSiteSessionDoseRestriction),
    );
    const [doseSelectDisable, setDoseSelectDisable] = useState(false);

    // Load Clinic Times data
    useEffect(() => {
        dispatch(
            getSiteSetupSessions({
                organisationId: selectedPractice,
                siteSetupId,
            }),
        );
    }, [dispatch, selectedPractice, siteSetupId, siteSetupSessionDeleting]);

    // Close modal only once publish/unpublish action is finished
    useEffect(() => {
        if (showPublishModal && !siteSetupSessionLoading) {
            onToggleModal();
        }
    }, [siteSetupSessionLoading]);

    const handleUpdateSession = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName: VaccineSiteSessionDetailsType,
    ): void => {
        const value = e.target.value;
        e.preventDefault();
        setEditingRow((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleUpdateAdminOnly = (
        isChecked: boolean,
        sessionId: string,
    ): void => {
        setUpdatedAdminOnly({
            sessionId,
            adminOnly: isChecked,
        });
        setEditingRow({
            ...editingRow,
            reservedForClinicianBooking: isChecked,
        });
    };

    const handleInputChange = (
        newPair: Partial<VaccineSiteSessionDetails>,
    ): void => {
        setCurrentRow((prev) => ({
            ...prev,
            ...newPair,
        }));
    };

    const handleVaccineInputChange = (
        newPair: Partial<VaccineSiteSessionDetails>,
    ): void => {
        // When type is Janssen, should disable dose restriction choice and set to BOTH value.
        if (newPair?.type === VaccineSiteSessionRecallType.CovidJanssen) {
            setAvailableDoses([VaccineSiteSessionDoseRestriction.Both]);
            setDoseSelectDisable(true);
            setCurrentRow((prev) => ({
                ...prev,
                ...newPair,
                vaccineDoseRestriction: VaccineSiteSessionDoseRestriction.Both,
            }));
        } else if (
            newPair?.type === VaccineSiteSessionRecallType.CovidPfizerPaediatric
        ) {
            if (isAutumn2023Enabled) {
                setAvailableDoses([
                    VaccineSiteSessionDoseRestriction.None,
                    VaccineSiteSessionDoseRestriction.Both,
                    VaccineSiteSessionDoseRestriction.FirstOnly,
                    VaccineSiteSessionDoseRestriction.SecondOnly,
                    VaccineSiteSessionDoseRestriction.Booster,
                ]);
            } else {
                setAvailableDoses([
                    VaccineSiteSessionDoseRestriction.None,
                    VaccineSiteSessionDoseRestriction.Both,
                    VaccineSiteSessionDoseRestriction.FirstOnly,
                    VaccineSiteSessionDoseRestriction.SecondOnly,
                ]);
            }
            setDoseSelectDisable(false);
            setCurrentRow((prev) => ({
                ...prev,
                ...newPair,
            }));
        } else if (newPair?.type === VaccineSiteSessionRecallType.CovidSanofi) {
            setAvailableDoses([VaccineSiteSessionDoseRestriction.Booster]);
            setDoseSelectDisable(true);
            setCurrentRow((prev) => ({
                ...prev,
                ...newPair,
                vaccineDoseRestriction:
                    VaccineSiteSessionDoseRestriction.Booster,
            }));
        } else {
            setAvailableDoses(getEnumKeys(VaccineSiteSessionDoseRestriction));
            setDoseSelectDisable(false);
            setCurrentRow((prev) => ({
                ...prev,
                ...newPair,
            }));
        }
    };

    const onToggleModal = (isPublished?: boolean, sessionId?: string): void => {
        confirmSessionId = sessionId || "";
        isPublishing = !isPublished;
        setShowPublishModal((prevState) => !prevState);
    };

    const handlePublish = (): void => {
        if (!confirmSessionId) {
            return;
        }
        const params = {
            organisationId: selectedPractice,
            siteSetupId,
            sessionId: confirmSessionId,
        };
        dispatch(
            isPublishing
                ? postSiteSetupSessionPublish(params)
                : postSiteSetupSessionUnpublish(params),
        );
    };

    const handleDelete = (sessionId: string): void => {
        const params = {
            organisationId: selectedPractice,
            siteSetupId,
            sessionId,
        };
        dispatch(deleteSession(params));
    };

    const handleEditRow = (sessionDetails: VaccineSiteSessionDetails): void => {
        if (editingRow?.id) {
            setValidationError({
                id: editingRow.id,
                message: "You have unsaved changes",
                inputField: "",
                typeFeedback: "Warning",
            });
            return;
        }

        setEditingRow({
            ...sessionDetails,
        });
    };

    const cancelEditingRow = (): void => {
        setEditingRow(initialState(siteSetupLocation));
    };

    const isValidRow = (
        rowData: VaccineSiteSessionDetails,
        isEditingSession: boolean,
    ): boolean => {
        const validationErr = {
            id: rowData.id || "",
            message: "",
            inputField: "",
            typeFeedback: "Error",
        };

        // Date validation
        if (
            !rowData.date ||
            !DateHelpers.checkDateFormats(rowData.date, DATE_FORMATS)
        ) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }date`;
            validationErr.message =
                "Invalid date, please enter in one of the following formats: DD/MM/YYYY, DD/MM/YY, D/M/YYYY, D/M/YY, DD-MM-YYYY, DD-MM-YY, D-M-YYYY, D-M-YY";
            setValidationError(validationErr);
            return false;
        }

        // Start time validation
        const startTime = DateHelpers.getDateISOStringFromAnotherStringFormat(
            `${rowData.date} ${rowData.startTime}`,
            DATE_TIME_FORMATS,
        );
        if (
            !rowData.startTime ||
            startTime === "" ||
            !DateHelpers.timeDivisibleBy10(startTime)
        ) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }startTime`;
            if (
                rowData.startTime &&
                startTime !== "" &&
                !DateHelpers.timeDivisibleBy10(startTime)
            ) {
                validationErr.message =
                    "Session start time must be divisible by 10 minutes e.g. 08:20";
            } else {
                validationErr.message =
                    "Invalid session start time, please enter in the following format: HH:MM";
            }
            setValidationError(validationErr);
            return false;
        }

        // End time validation
        const endTime = DateHelpers.getDateISOStringFromAnotherStringFormat(
            `${rowData.date} ${rowData.endTime}`,
            DATE_TIME_FORMATS,
        );
        if (
            !rowData.endTime ||
            endTime === "" ||
            !DateHelpers.timeDivisibleBy10(endTime)
        ) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }endTime`;
            if (
                rowData.endTime &&
                endTime !== "" &&
                !DateHelpers.timeDivisibleBy10(endTime)
            ) {
                validationErr.message =
                    "Session end time must be divisible by 10 minutes e.g. 08:20";
            } else {
                validationErr.message =
                    "Invalid session end time, please enter in the following format: HH:MM";
            }
            setValidationError(validationErr);
            return false;
        }

        // Patient per hour validation
        if (!rowData.patientsPerHour || rowData.patientsPerHour <= 0) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }patientsPerHour`;
            validationErr.message =
                "Invalid number of patients per hour - please enter a whole number";
            setValidationError(validationErr);
            return false;
        }

        // Vaccine type validation
        const vaccineType =
            typeof rowData.type !== "number"
                ? parseInt(rowData.type, 10)
                : rowData.type;

        if (!vaccineType || !(vaccineType in VaccineSiteSessionRecallType)) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }type`;
            validationErr.message =
                "Invalid vaccine entered - please choose one from the dropdown";
            setValidationError(validationErr);
            return false;
        }

        // Dose restriction validation
        const doseRestriction = getEnumKeys(
            VaccineSiteSessionDoseRestriction,
        ).find((x) => x === rowData.vaccineDoseRestriction);
        if (
            !doseRestriction ||
            doseRestriction === VaccineSiteSessionDoseRestriction.None
        ) {
            validationErr.inputField = `${
                !isEditingSession ? "newSession-" : ""
            }doseRestriction`;
            validationErr.message =
                "Invalid vaccine dose entered - please choose one from the dropdown";
            setValidationError(validationErr);
            return false;
        }

        return true;
    };

    const handleUpdatePatientsPerHour = (
        e: ChangeEvent<HTMLInputElement>,
        sessionId: string,
    ): void => {
        setUpdatedPatientsPerHour({
            sessionId,
            patientsPerHour: e.target.value ? parseInt(e.target.value, 10) : 0,
        });
    };

    const handleSubmitRow = (
        e:
            | ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | MouseEvent<HTMLButtonElement>,
        sessionData?: VaccineSiteSessionDetails,
    ): void => {
        const rowData = sessionData || currentRow;

        setValidationError({
            id: "",
            message: "",
            inputField: "",
            typeFeedback: "",
        });
        if (!isValidRow(rowData, Boolean(sessionData))) {
            return;
        }

        const keepTzOffset = true; // do not convert times to UTC, timezone of a site is relevant
        const startTime = DateHelpers.getDateISOStringFromAnotherStringFormat(
            `${rowData.date} ${rowData.startTime}`,
            DATE_TIME_FORMATS,
            keepTzOffset,
        );
        const endTime = DateHelpers.getDateISOStringFromAnotherStringFormat(
            `${rowData.date} ${rowData.endTime}`,
            DATE_TIME_FORMATS,
            keepTzOffset,
        );
        const request = {
            startTime,
            endTime,
            patientsPerHour:
                typeof rowData.patientsPerHour !== "number"
                    ? parseInt(rowData.patientsPerHour, 10)
                    : rowData.patientsPerHour,
            type:
                typeof rowData.type !== "number"
                    ? parseInt(rowData.type, 10)
                    : rowData.type,
            reservedForClinicianBooking: rowData.reservedForClinicianBooking,
            vaccineDoseRestriction: rowData.vaccineDoseRestriction,
        };
        const urlParams = {
            organisationId: selectedPractice,
            siteSetupId,
            sessionId: rowData.id,
        };

        const successCallback = (): void => {
            setCurrentRow(initialState(siteSetupLocation));
            setEditingRow(initialState(siteSetupLocation));
        };

        dispatch(postSiteSetupSession(urlParams, request, successCallback));
    };

    const header = HEADERS_STRING.map((title) => (
        <th key={title} className="align-middle border-top-0">
            <Text variant="label">{title}</Text>
        </th>
    ));

    const getInputWrapperClass = (
        fieldName: string,
        sessionId = "",
    ): string => {
        if (
            validationError?.inputField === fieldName &&
            validationError?.id === sessionId
        ) {
            return "has-error";
        }
        return "";
    };

    const savedRows = siteSetupSessions?.map((session: VaccineSiteSession) => {
        if (
            !showPastSessions &&
            DateHelpers.isBefore(
                session.endTime,
                DateHelpers.getCurrentTimeStamp(),
            )
        ) {
            return null;
        }

        const capacity =
            updatedPatientsPerHour?.sessionId === session.id
                ? updatedPatientsPerHour.patientsPerHour
                : session.patientsPerHour;

        const isAdminOnly =
            updatedAdminOnly?.sessionId === session.id
                ? updatedAdminOnly.adminOnly
                : session.reservedForClinicianBooking;

        const sessionDetails: VaccineSiteSessionDetails = {
            id: session.id,
            date: DateHelpers.formatDate(session.startTime, "DD/MM/YYYY"),
            startTime: DateHelpers.formatDate(session.startTime, "HH:mm"),
            endTime: DateHelpers.formatDate(session.endTime, "HH:mm"),
            patientsPerHour: session.patientsPerHour,
            capacity: calculateCapacity(
                session.startTime,
                session.endTime,
                capacity,
            ),
            type: session.type,
            locationText: session.locationText,
            isPopulated: session.isPopulated,
            isPublished: session.isPublished,
            totalBooked: session.totalBooked,
            reservedForClinicianBooking: isAdminOnly,
            vaccineDoseRestriction: session.vaccineDoseRestriction,
        };

        const isEditingRow = editingRow.id === sessionDetails.id;
        return (
            <Fragment key={`${sessionDetails.id}${isEditingRow}`}>
                <tr>
                    <td
                        className={getInputWrapperClass(
                            "date",
                            sessionDetails.id,
                        )}
                    >
                        <Input
                            key={sessionDetails.date}
                            type="text"
                            defaultValue={sessionDetails.date}
                            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
                                handleUpdateSession(e, "date")
                            }
                            disabled={
                                sessionDetails.isPopulated || !isEditingRow
                            }
                        />
                    </td>
                    <td
                        className={getInputWrapperClass(
                            "startTime",
                            sessionDetails.id,
                        )}
                    >
                        <Input
                            key={sessionDetails.startTime}
                            type="text"
                            defaultValue={sessionDetails.startTime}
                            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
                                handleUpdateSession(e, "startTime")
                            }
                            disabled={
                                sessionDetails.isPublished || !isEditingRow
                            }
                        />
                    </td>
                    <td
                        className={getInputWrapperClass(
                            "endTime",
                            sessionDetails.id,
                        )}
                    >
                        <Input
                            key={sessionDetails.endTime}
                            type="text"
                            defaultValue={sessionDetails.endTime}
                            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
                                handleUpdateSession(e, "endTime")
                            }
                            disabled={
                                sessionDetails.isPublished || !isEditingRow
                            }
                        />
                    </td>
                    <td
                        className={getInputWrapperClass(
                            "patientsPerHour",
                            sessionDetails.id,
                        )}
                    >
                        <Input
                            key={sessionDetails.patientsPerHour}
                            type="number"
                            defaultValue={sessionDetails.patientsPerHour}
                            onChange={(e): void =>
                                handleUpdatePatientsPerHour(
                                    e,
                                    sessionDetails.id,
                                )
                            }
                            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
                                handleUpdateSession(e, "patientsPerHour")
                            }
                            disabled={
                                sessionDetails.isPublished || !isEditingRow
                            }
                            data-testid={`input-savedsession-patientsPerHour-${sessionDetails.id}`}
                        />
                    </td>
                    <td>
                        <Input
                            type="number"
                            value={sessionDetails.capacity}
                            disabled
                            data-testid={`input-savedsession-capacity-${sessionDetails.id}`}
                        />
                    </td>
                    <td
                        className={getInputWrapperClass(
                            "type",
                            sessionDetails.id,
                        )}
                    >
                        <select
                            key={sessionDetails.type}
                            className="form-control"
                            defaultValue={sessionDetails.type}
                            onBlur={(e: ChangeEvent<HTMLSelectElement>): void =>
                                handleUpdateSession(e, "type")
                            }
                            disabled={
                                sessionDetails.isPublished || !isEditingRow
                            }
                        >
                            {VACCINE_TYPE_LIST.filter((vac) => vac.enabled).map(
                                (vac) => (
                                    <option key={vac.type} value={vac.type}>
                                        {vac.display}
                                    </option>
                                ),
                            )}
                        </select>
                    </td>
                    <td
                        className={getInputWrapperClass(
                            "type",
                            sessionDetails.id,
                        )}
                    >
                        <select
                            key={sessionDetails.vaccineDoseRestriction}
                            className="form-control"
                            defaultValue={sessionDetails.vaccineDoseRestriction}
                            onBlur={(e: ChangeEvent<HTMLSelectElement>): void =>
                                handleUpdateSession(e, "vaccineDoseRestriction")
                            }
                            disabled={
                                sessionDetails.isPublished || !isEditingRow
                            }
                            data-testid={`dose-restriction-${sessionDetails.id}`}
                        >
                            {getEnumKeys(VaccineSiteSessionDoseRestriction).map(
                                (vac) => (
                                    <option key={vac} value={vac}>
                                        {getDoseDisplayString(vac)}
                                    </option>
                                ),
                            )}
                        </select>
                    </td>
                    <td className="text-center">
                        {isEditingRow ? (
                            <input
                                key={`${sessionDetails.reservedForClinicianBooking}`}
                                type="checkbox"
                                defaultChecked={
                                    sessionDetails.reservedForClinicianBooking
                                }
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ): void =>
                                    handleUpdateAdminOnly(
                                        e.target.checked,
                                        sessionDetails.id,
                                    )
                                }
                                data-testid={`admin-only-${sessionDetails.id}`}
                                disabled={
                                    sessionDetails.isPublished || !isEditingRow
                                }
                            />
                        ) : (
                            sessionDetails.reservedForClinicianBooking && (
                                <Text
                                    props={{
                                        "data-testid": `admin-only-disabled-check-${sessionDetails.id}`,
                                    }}
                                >
                                    &#10003;
                                </Text>
                            )
                        )}
                    </td>
                    <td>
                        {!isEditingRow && (
                            <Ds.Switch
                                checked={sessionDetails.isPublished}
                                data-testId={`publish-${sessionDetails.id}`}
                                onClick={(): void =>
                                    onToggleModal(
                                        sessionDetails.isPublished,
                                        sessionDetails.id,
                                    )
                                }
                            >
                                <Ds.Switch.Toggle />
                            </Ds.Switch>
                        )}
                    </td>
                    <td>
                        <div className="d-flex justify-content-end align-items-center">
                            {!isEditingRow ? (
                                <>
                                    <Button
                                        theme="secondary"
                                        className="mr-1"
                                        icon={{
                                            name: "Bin",
                                            colour: "red",
                                            title: `Delete ${sessionDetails.id}`,
                                            id: `delete-${sessionDetails.id}`,
                                        }}
                                        data-testid={`delete-${sessionDetails.id}`}
                                        onClick={(): void =>
                                            handleDelete(sessionDetails.id)
                                        }
                                        disabled={
                                            session.isPublished ||
                                            siteSetupSessionDeleting
                                        }
                                    />
                                    <Button
                                        theme="secondary"
                                        icon={{
                                            name: "Pencil",
                                            colour: "blue",
                                            title: `Edit ${sessionDetails.id}`,
                                            id: `edit-${sessionDetails.id}`,
                                        }}
                                        data-testid={`edit-${sessionDetails.id}`}
                                        onClick={(): void =>
                                            handleEditRow(sessionDetails)
                                        }
                                        disabled={sessionDetails.isPublished}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        theme="secondary"
                                        type="button"
                                        className="m-1"
                                        data-testid={`cancel-${sessionDetails.id}`}
                                        onClick={cancelEditingRow}
                                        text={"Cancel"}
                                        dimension="small"
                                    />
                                    <Button
                                        theme="primary"
                                        type="button"
                                        data-testid={`save-${sessionDetails.id}`}
                                        onClick={(e): void =>
                                            handleSubmitRow(e, editingRow)
                                        }
                                        text={"Save"}
                                        dimension="small"
                                    />
                                </>
                            )}
                        </div>
                    </td>
                </tr>
                {(validationError?.id === sessionDetails.id ||
                    siteSetupSessionError?.id === sessionDetails.id ||
                    siteSetupSessionDeletingError?.id ===
                        sessionDetails.id) && (
                    <tr>
                        <td
                            colSpan={8}
                            style={{ borderTop: "none", paddingTop: 0 }}
                        >
                            <div className="d-flex">
                                <Icon
                                    colour={
                                        validationError?.typeFeedback ===
                                        "Warning"
                                            ? "orange"
                                            : "red"
                                    }
                                    name={
                                        validationError?.typeFeedback ===
                                        "Warning"
                                            ? "Warning"
                                            : "Error"
                                    }
                                    size={3}
                                    theme="Fill"
                                />
                                <Text skinny>
                                    {validationError.message ||
                                        siteSetupSessionError.message ||
                                        siteSetupSessionDeletingError.message}
                                </Text>
                            </div>
                        </td>
                    </tr>
                )}
            </Fragment>
        );
    });

    const renderNewRow = (): JSX.Element => {
        let capacity: number | string = "";
        if (
            currentRow.date &&
            currentRow.startTime &&
            currentRow.endTime &&
            currentRow.patientsPerHour
        ) {
            const startTime =
                DateHelpers.getDateISOStringFromAnotherStringFormat(
                    `${currentRow.date} ${currentRow.startTime}`,
                    DATE_TIME_FORMATS,
                );
            const endTime = DateHelpers.getDateISOStringFromAnotherStringFormat(
                `${currentRow.date} ${currentRow.endTime}`,
                DATE_TIME_FORMATS,
            );
            capacity = calculateCapacity(
                startTime,
                endTime,
                currentRow.patientsPerHour,
            );
        }
        return (
            <>
                <tr>
                    <td
                        className={`${getInputWrapperClass("newSession-date")}`}
                        style={{ minWidth: "9rem" }}
                    >
                        <Input
                            type="text"
                            onChange={(e): void =>
                                handleInputChange({ date: e.target.value })
                            }
                            value={currentRow.date}
                            placeholder="DD/MM/YYYY"
                        />
                    </td>
                    <td
                        className={`${getInputWrapperClass(
                            "newSession-startTime",
                        )}`}
                        style={{ minWidth: "6.5rem" }}
                    >
                        <Input
                            type="text"
                            onChange={(e): void =>
                                handleInputChange({ startTime: e.target.value })
                            }
                            value={currentRow.startTime}
                            placeholder="ie: 14:10"
                            data-testid="input-newsession-startTime"
                        />
                    </td>
                    <td
                        className={`${getInputWrapperClass(
                            "newSession-endTime",
                        )}`}
                        style={{ minWidth: "6.5rem" }}
                    >
                        <Input
                            type="text"
                            onChange={(e): void =>
                                handleInputChange({ endTime: e.target.value })
                            }
                            value={currentRow.endTime}
                            placeholder="ie: 14:10"
                            data-testid="input-newsession-endTime"
                        />
                    </td>
                    <td
                        className={`${getInputWrapperClass(
                            "newSession-patientsPerHour",
                        )}`}
                        style={{ minWidth: "6rem" }}
                    >
                        <Input
                            type="number"
                            onChange={(e): void =>
                                handleInputChange({
                                    patientsPerHour: Number(e.target.value),
                                })
                            }
                            value={currentRow.patientsPerHour || ""}
                            placeholder="-"
                            data-testid="input-newsession-patientsPerHour"
                        />
                    </td>
                    <td style={{ minWidth: "6rem" }}>
                        <Input
                            type="number"
                            value={capacity}
                            placeholder="-"
                            data-testid="input-newsession-capacity"
                            disabled
                        />
                    </td>
                    <td
                        className={`${getInputWrapperClass("newSession-type")}`}
                        style={{ minWidth: "6.5rem" }}
                    >
                        <select
                            className="form-control"
                            value={currentRow.type}
                            onChange={(e): void =>
                                handleVaccineInputChange({
                                    type: Number(e.target.value),
                                })
                            }
                            data-testid="input-newsession-type"
                        >
                            {VACCINE_TYPE_LIST.filter((vac) => vac.enabled).map(
                                (vac) => (
                                    <option key={vac.type} value={vac.type}>
                                        {vac.display}
                                    </option>
                                ),
                            )}
                        </select>
                    </td>
                    <td
                        className={`${getInputWrapperClass(
                            "newSession-doseRestriction",
                        )}`}
                        style={{ minWidth: "6.5rem" }}
                    >
                        <select
                            className="form-control"
                            placeholder="-"
                            value={currentRow.vaccineDoseRestriction}
                            onChange={(e): void =>
                                handleInputChange({
                                    vaccineDoseRestriction:
                                        VaccineSiteSessionDoseRestriction[
                                            getEnumKeyByEnumValue(
                                                VaccineSiteSessionDoseRestriction,
                                                e.target.value,
                                            ) ??
                                                VaccineSiteSessionDoseRestriction.None
                                        ],
                                })
                            }
                            data-testid="input-newsession-doseRestriction"
                            disabled={doseSelectDisable}
                        >
                            {availableDoses.map((vac, index) => (
                                <option key={vac} value={vac}>
                                    {getDoseDisplayString(
                                        VaccineSiteSessionDoseRestriction[vac],
                                    )}
                                </option>
                            ))}
                        </select>
                    </td>
                    <td className="text-center">
                        <input
                            type="checkbox"
                            onChange={(e): void =>
                                handleInputChange({
                                    reservedForClinicianBooking:
                                        e.target.checked,
                                })
                            }
                            checked={currentRow.reservedForClinicianBooking}
                            disabled={false}
                            data-testid={`admin-only-${currentRow.id}`}
                        />
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                        <Button
                            theme="secondary"
                            type="button"
                            onClick={handleSubmitRow}
                            text={"Save"}
                            dimension="small"
                        />
                    </td>
                    <td />
                    {/* Extends horizontal divider */}
                </tr>
                {Boolean(
                    (validationError?.id === "" && validationError.message) ||
                        (siteSetupSessionError?.id === "" &&
                            siteSetupSessionError?.message),
                ) && (
                    <tr>
                        <td
                            colSpan={8}
                            style={{ borderTop: "none", paddingTop: 0 }}
                        >
                            <div className="d-flex">
                                <Icon
                                    colour="red"
                                    name="Error"
                                    size={3}
                                    theme="Fill"
                                />
                                <Text skinny>
                                    {validationError.message ||
                                        siteSetupSessionError.message}
                                </Text>
                            </div>
                        </td>
                    </tr>
                )}
            </>
        );
    };

    return (
        <div>
            <Text as="h2" variant="subtitle">
                Set up clinic times
            </Text>
            <Text>{siteSetupLocation}</Text>
            {siteSetupSessionsError && (
                <Feedback
                    colour="error"
                    title={siteSetupSessionsError}
                    content=""
                />
            )}
            {!siteSetupSessionsError && (
                <>
                    <div className="d-flex align-items-center m-2">
                        <input
                            type="checkbox"
                            id="showPastSessionsCheckbox"
                            data-testid="showPastSessionsCheckbox"
                            onChange={(): void =>
                                setShowPastSessions((prevState) => !prevState)
                            }
                            checked={showPastSessions}
                        />
                        <Text
                            as="label"
                            props={{
                                htmlFor: "showPastSessionsCheckbox",
                                className: "ml-2",
                            }}
                            skinny
                        >
                            Show past clinics
                        </Text>
                    </div>
                    <Card
                        spacing={1.5}
                        props={{ className: "table-responsive" }}
                    >
                        <table className="table table-sm mb-0">
                            <thead>
                                <tr>{header}</tr>
                            </thead>
                            <tbody>
                                {savedRows}
                                {renderNewRow()}
                            </tbody>
                        </table>
                    </Card>
                    <VaccineSiteSetupPublishModal
                        isPublishing={isPublishing}
                        showPublishModal={showPublishModal}
                        onToggleModal={(): void => onToggleModal()}
                        onPublish={handlePublish}
                        siteSetupSessionLoading={siteSetupSessionLoading}
                    />
                </>
            )}
        </div>
    );
};

export default VaccineSiteSetupTable;
