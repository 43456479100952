import React from "react";

import { StyledSvgIconWrapper } from "../Menu.styles";

export const NumberedListIcon = () => {
    return (
        <StyledSvgIconWrapper
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
            />
        </StyledSvgIconWrapper>
    );
};
