import { FeatureName } from "@accurx/auth";

import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";
import { PatientHelper } from "shared/PatientHelper";
import { useIsFeatureEnabled } from "store/hooks";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

export const MessageGpPracticeOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const saveToStore = useSavePatientSearchToStore();
    const isFeatureEnabledForOrganisation = useIsFeatureEnabled(
        FeatureName.CaregiverInitiated,
    );

    if (!isFeatureEnabledForOrganisation) {
        return null;
    }

    const isFeatureEnabledForPatientPractice = PatientHelper.isFeatureEnabled(
        searchResult.searchedResult.patient,
        FeatureName.CaregiverInitiated,
    );

    return (
        <ContactOption
            disabled={!isFeatureEnabledForPatientPractice}
            iconName="Mention"
            text="Message GP practice"
            onClick={() => {
                saveToStore(searchResult);
                onClick();
            }}
        />
    );
};
