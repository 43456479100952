interface KeyValuePairString {
    [index: string]: string;
}

/**
 * For a string enum, passing an enumObject and a enumValue, will return the key
 * eg:
 * export enum MyStringEnum {
 *   KEYA = "value A",
 *   KEYB = "value B",
 * }
 * getEnumKeyByEnumValue(MyStringEnum, "value B") will return KEYB
 *
 * @param enumObject string enum where we search for a value
 * @param enumValue string to search in the enum
 * @return the key matching enumValue or null
 */
export function getEnumKeyByEnumValue<T extends KeyValuePairString>(
    enumObject: T,
    enumValue: string,
): keyof T | null {
    return (
        Object.keys(enumObject).find((x) => enumObject[x] === enumValue) || null
    );
}

/**
 * For a string enum, passing an enumObject will return an array of keys
 * eg:
 * export enum MyEnum {
 *   KEYA,
 *   KEYB,
 * }
 * getEnumValues(MyEnum) will return [0, 1]
 *
 * @param enumObject enum we want to extract the keys
 * @return array of possible keys
 */
export function getEnumKeys<
    T extends KeyValuePairString,
    K extends keyof T = keyof T,
>(enumObject: T): K[] {
    return Object.keys(enumObject).filter((k) => Number.isNaN(+k)) as K[];
}
