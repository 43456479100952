import { useCurrentWorkspace } from "@accurx/auth";
import { Ds } from "@accurx/design";
import {
    Accordion,
    FocusRingSpacing,
    NavLinkWithToggle,
} from "@accurx/navigation";
import { Link, generatePath } from "react-router-dom";

import { ROUTES_WORKSPACE } from "shared/Routes";

import { SecondaryNavLink } from "../SecondaryNavLink";

type ReportingLinksProps = {
    isOpen?: boolean;
};

export const ReportingLinks = ({ isOpen = false }: ReportingLinksProps) => {
    const { orgId: workspaceId } = useCurrentWorkspace();

    return (
        <Accordion.Item.Level2 value="Reporting">
            <Accordion.Header>
                <FocusRingSpacing>
                    <NavLinkWithToggle>
                        <NavLinkWithToggle.Link asChild>
                            <Link
                                to={generatePath(ROUTES_WORKSPACE.reporting, {
                                    workspaceId,
                                })}
                            >
                                <Ds.Flex gap="1" alignItems="center">
                                    <Ds.Icon
                                        name="Chart"
                                        color="white"
                                        size="small"
                                    />
                                    Reporting
                                </Ds.Flex>
                            </Link>
                        </NavLinkWithToggle.Link>
                        <Accordion.Trigger asChild>
                            <NavLinkWithToggle.Toggle aria-label="Open reporting">
                                <Ds.Icon
                                    name="Arrow"
                                    color="white"
                                    size="xsmall"
                                    rotate={isOpen ? "0" : "90"}
                                />
                            </NavLinkWithToggle.Toggle>
                        </Accordion.Trigger>
                    </NavLinkWithToggle>
                </FocusRingSpacing>
            </Accordion.Header>
            <Accordion.Content>
                <Accordion.Indented>
                    <SecondaryNavLink
                        to={generatePath(
                            ROUTES_WORKSPACE.reportingPatientTriage,
                            { workspaceId },
                        )}
                    >
                        Patient triage
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={generatePath(
                            ROUTES_WORKSPACE.reportingResponseRates,
                            { workspaceId },
                        )}
                    >
                        Response rates
                    </SecondaryNavLink>
                    <SecondaryNavLink
                        to={generatePath(ROUTES_WORKSPACE.reportingFragments, {
                            workspaceId,
                        })}
                    >
                        Fragments
                    </SecondaryNavLink>
                </Accordion.Indented>
            </Accordion.Content>
        </Accordion.Item.Level2>
    );
};
