import { TabProps } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { TabLookup } from "./AppointmentRemindersPage.constants";

export type UseAppointmentRemindersPage = () => {
    tab?: TabProps;
    onTabChange: (tab: string) => void;
    redirect: string;
};

export const useAppointmentRemindersPage: UseAppointmentRemindersPage = () => {
    const history = useHistory();
    const { orgId, tab: tabId } = useParams<{ orgId: string; tab: string }>();

    const tab = TabLookup[tabId || "manage"];

    const onTabChange = (tabId: string) => {
        history.push(
            generatePath(ROUTES_ORGS.appointmentReminders, {
                orgId,
                tab: tabId,
            }),
        );
    };
    // generate a redirect url in case the route
    // we're on points to an invalid tab
    const redirect = generatePath(ROUTES_ORGS.appointmentReminders, {
        orgId,
    });

    return { tab, onTabChange, redirect };
};
