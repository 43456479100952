import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useMatchTicketToPatientMutation } from "@accurx/concierge/hooks/mutations/useMatchTicketToPatientMutation";
import { PatientExternalId } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";
import { getPatientMatchState } from "domains/inbox/util/getPatientMatchState";
import { toast } from "react-toastify";

import { ComposeNote } from "../ComposeNote/ComposeNote";
import { ReplyProps } from "./Reply.types";
import {
    StyledSearchForPatientFeedback,
    StyledSearchForPatientFeedbackContainer,
} from "./ReplyContents.styles";
import { ReplyTabs } from "./ReplyTabs";

type PatientNotFoundReplyProps = Pick<
    ReplyProps,
    "conversation" | "setSentNoteStatus"
> & {
    selectedMessageAction: "note" | "reply";
    onClickSearchForPatient: () => void;
    /**
     * Callback for when we want to completely close (not just minimise)
     * Reply component
     */
    close: () => void;
};

export const PatientNotFoundReply = ({
    setSentNoteStatus,
    conversation,
    selectedMessageAction,
    onClickSearchForPatient,
    close,
}: PatientNotFoundReplyProps) => {
    const track = useAnalytics();

    const matchTicketToPatientMutation = useMatchTicketToPatientMutation();

    const patientMatchState = getPatientMatchState(conversation);

    const [noteText, setNoteText] = useState<string>("");

    const trackingFields = useConversationAnalyticsFields({
        conversation,
        appOrigin: "MessageActions",
    });

    const handleMatchTicketToPatient = (
        patientExternalIds: PatientExternalId[],
    ) => {
        matchTicketToPatientMutation.mutate(
            {
                conversation,
                patientExternalIds,
            },
            {
                onError: () =>
                    toast(
                        <UI.Feedback
                            title="Failed to match conversation to patient, please try again"
                            colour="error"
                        />,
                    ),
            },
        );
    };

    const isComposingNote = noteText.length > 0;

    return (
        <ReplyTabs
            selectedMessageAction={selectedMessageAction}
            isComposing={false}
            isComposingNote={isComposingNote}
            conversation={conversation}
        >
            <ReplyTabs.ReplyPanel>
                <StyledSearchForPatientFeedbackContainer>
                    <StyledSearchForPatientFeedback
                        justifyContent="space-between"
                        flexWrap="wrap"
                        alignItems="center"
                        gap="1"
                    >
                        <UI.Flex alignItems="center" gap="1">
                            <UI.Icon
                                colour="orange"
                                theme="Fill"
                                name="Warning"
                                size={3}
                            />
                            <UI.Text skinny variant="note">
                                {patientMatchState?.type === "SuggestedMatch"
                                    ? "Confirm patient to reply"
                                    : "Search and match patient to reply"}
                            </UI.Text>
                        </UI.Flex>
                        {patientMatchState?.type === "SuggestedMatch" ? (
                            <UI.Flex gap="1">
                                <Pill.PrimaryButton
                                    dimension="small"
                                    aria-label="Confirm patient and match to conversation"
                                    isLoading={
                                        matchTicketToPatientMutation.isLoading
                                    }
                                    onClick={() => {
                                        handleMatchTicketToPatient(
                                            patientMatchState.suggestedMatch
                                                .externalIds,
                                        );
                                    }}
                                >
                                    <Pill.Text>Confirm patient</Pill.Text>
                                </Pill.PrimaryButton>
                                <WithTooltip
                                    content={
                                        <UI.Text
                                            skinny
                                            variant="note"
                                            colour="white"
                                        >
                                            Match to another patient
                                        </UI.Text>
                                    }
                                >
                                    <Pill.SecondaryButton
                                        dimension="small"
                                        aria-label="Match to another patient"
                                        onClick={() => {
                                            track(
                                                "ConversationSuggestedPatientChangeSelect Button Click",
                                                trackingFields,
                                            );
                                            onClickSearchForPatient();
                                        }}
                                    >
                                        <Pill.Text>Change</Pill.Text>
                                    </Pill.SecondaryButton>
                                </WithTooltip>
                            </UI.Flex>
                        ) : (
                            <Pill.PrimaryButton
                                dimension="small"
                                onClick={() => {
                                    track(
                                        "ConversationPatientMatchSelect Button Click",
                                        trackingFields,
                                    );
                                    onClickSearchForPatient();
                                }}
                            >
                                <Pill.Text>Search for patient</Pill.Text>
                            </Pill.PrimaryButton>
                        )}
                    </StyledSearchForPatientFeedback>
                </StyledSearchForPatientFeedbackContainer>
            </ReplyTabs.ReplyPanel>
            <ReplyTabs.NotesPanel>
                <ComposeNote
                    noteText={noteText}
                    setNoteText={setNoteText}
                    conversation={conversation}
                    closeNotePanel={close}
                    setSentNoteStatus={setSentNoteStatus}
                    isHeightRestricted
                />
            </ReplyTabs.NotesPanel>
        </ReplyTabs>
    );
};
