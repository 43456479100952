import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledSkeleton = styled.div<{
    $width?: string;
    $height?: string;
    $backgroundColor: string;
    $shimmerRGBColor: [number, number, number];
}>`
    border-radius: ${UI.Tokens.SIZES[0.5]};
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
    position: relative;
    overflow: hidden;
    background: ${(props) => props.$backgroundColor};

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);

        background: ${({ $shimmerRGBColor }) => {
            const rgbColor = $shimmerRGBColor.join(", ");
            return `linear-gradient(
                80deg,
                rgba(${rgbColor}, 0) 0%,
                rgba(${rgbColor}, 0.05) 50%,
                rgba(${rgbColor}, 0) 100%
            )
                `;
        }};

        animation: shimmer 1.2s infinite linear;
        content: "";

        @keyframes shimmer {
            100% {
                transform: translateX(200%);
            }
        }
    }
`;
