/*
 * We don't currently have automated tests of our JavaScript. You can ensure the links are live by
 * adding to SupportUrls.cs and they'll get checked automated by C# tests.
 */

/* NB. This file is now a legacy File. Please add any future SupportUrls to this file "src\Web\accuRx.Web.Client.Shared\src\SupportUrls.ts " */
export enum SupportUrls {
    SetupNewUser = "https://support.accurx.com/en/articles/2743730-how-to-add-a-new-user",
    DeliveryStateMeaning = "https://support.accurx.com/en/articles/2781278-what-do-sent-delivered-and-failed-mean-in-delivery-receipts",
    ApproveUser = "https://support.accurx.com/en/articles/2290931-approving-new-users-in-your-practice",
    ArchiveUser = "https://support.accurx.com/en/articles/2949637-archiving-old-user-accounts",
    MakeUserAdmin = "https://support.accurx.com/en/articles/6868339-manage-practice-how-to-make-other-users-admins",
    UsersNotAppearingToApprove = "https://support.accurx.com/en/articles/3684792-a-new-user-hasn-t-appeared-in-my-list-of-people-to-approve",
    AlreadyInvitedRecall = "https://support.accurx.com/en/articles/4837106-accubook-what-does-already-uploaded-mean-and-what-should-i-do",
    RejectedIneligible = "https://support.accurx.com/en/articles/5634302-accubook-what-does-not-eligible-mean-and-what-should-i-do",
    NotInNetworkRecall = "https://support.accurx.com/en/articles/5135029-accubook-what-does-not-in-network-mean-and-what-i-should-do",
    SelfBookHelp = "https://support.accurx.com/en/articles/5728510-self-book-what-is-it-and-how-does-it-work",
    TwoFactorSecurity = "https://support.accurx.com/en/articles/5665430-accurx-web-setting-up-two-factor-authentication",
}
