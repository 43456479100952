import { SnomedCode, TemplateItem } from "domains/compose/types";

export const getTemplateSnomedCodes = (
    template: TemplateItem,
): SnomedCode[] => {
    if (template.type === "MessageTemplate") {
        return template.value.snomedCodes ?? [];
    }

    if (template.value.medicalCodeIdentity?.code) {
        return [{ id: template.value.medicalCodeIdentity.code }];
    }

    return [];
};
