import { useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Button, Feedback, Icon, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { SupportUrls } from "@accurx/shared";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    resetConfirmMessageSuccess,
    resetIsBatchScheduled,
    resetState,
} from "app/batchMessage/gp/BatchMessage.actions";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import { BatchMessageStatuses } from "app/batchMessage/gp/components/overview/BatchMessageStatuses";
import { StatusesLabelsContext } from "app/batchMessage/gp/components/overview/BatchMessageStatusesContext";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_CHAIN } from "shared/Routes";
import {
    useAppSelector,
    useCurrentUserId,
    useIsFeatureEnabled,
} from "store/hooks";

import {
    getIamNotInterestedCount,
    getShowMeHowItWorksCount,
} from "../BatchMessage.utils";
import { BatchMessageSelfBookPromoModal } from "../components/overview/BatchMessageSelfBookPromoModal";

type BatchMessageOverviewProps = {
    noHeader?: boolean;
};

const statusLabels = [
    "Scheduled",
    "Sending",
    "Done",
    "Cancelled",
    "Saved",
    "Saving",
];

export const BatchMessageOverview = ({
    noHeader = false,
}: BatchMessageOverviewProps): JSX.Element => {
    useAccurxWebTitle("View batch messages");

    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const userId = useCurrentUserId();

    // Feature flags
    const batchSelfBookEnabled = useIsFeatureEnabled(FeatureName.BatchSelfBook);
    const batchEnabled = useIsFeatureEnabled(FeatureName.BatchMessagingAlpha);
    const batchFloreyEnabled = useIsFeatureEnabled(
        FeatureName.BatchMessagingFlorey,
    );
    const batchGskFloreyEnabled = useIsFeatureEnabled(
        FeatureName.BatchGskFlorey,
    );

    const SelfBookExperimentTargetedPromotionToPracticeMangers =
        useIsFeatureEnabled(
            FeatureName.SelfBookExperimentTargetedPromotionToPracticeMangers,
        );
    const onlyBatchGskFloreyEnabled =
        batchGskFloreyEnabled &&
        batchFloreyEnabled === false &&
        batchEnabled === false;

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const confirmMessageSuccess = useAppSelector(
        ({ batchMessage }) => batchMessage.confirmMessageSuccess,
    );
    const isBatchScheduled = useAppSelector(
        ({ batchMessage }) => batchMessage.isBatchScheduled,
    );

    // States
    const [showSelfBookModal, setShowSelfBookModal] = useState<boolean>(false);

    useEffect(() => {
        if (confirmMessageSuccess) {
            toast(
                Feedback({
                    colour: "success",
                    title: isBatchScheduled ? "Message saved" : "Message sent",
                }),
            );
            setShowSelfBookModal(true);
            dispatch(resetConfirmMessageSuccess());
            dispatch(resetIsBatchScheduled());
        }
    }, [dispatch, confirmMessageSuccess, isBatchScheduled]);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchOverviewPageView(
            analyticsLoggedInProps,
        );
    }, [analyticsLoggedInProps]);

    const trackClickInfo = (): void => {
        // old app insight
        ChainAnalyticsTracker.trackBatchClickedAccuRxPlusInfo({
            ...analyticsLoggedInProps,
            fromPage: "Start",
        });

        ChainAnalyticsTracker.trackBatchResourceClick({
            ...analyticsLoggedInProps,
            batchResourceName: SupportUrls.BatchAccessGuide,
            origin: history.location.pathname,
        });

        window.open(SupportUrls.BatchAccessGuide, "_blank");
    };

    const sendBatchMessageHandler = () => {
        dispatch(resetState());
        ChainAnalyticsTracker.trackBatchStartClick({
            ...analyticsLoggedInProps,
            isGskOnly: onlyBatchGskFloreyEnabled,
        });
        history.push(
            ROUTES_CHAIN.practicesBatchMessagingChoose.replace(
                ":orgId",
                practiceId,
            ),
        );
    };

    const renderSelfBookPromoModal = () => {
        return (
            <BatchMessageSelfBookPromoModal
                showModal={showModal()}
                onButtonClick={() => setShowSelfBookModal(false)}
            />
        );
    };

    const showModal = (): boolean => {
        if (userId) {
            return (
                SelfBookExperimentTargetedPromotionToPracticeMangers &&
                showSelfBookModal &&
                Number.parseInt(getShowMeHowItWorksCount(userId), 10) < 1 &&
                Number.parseInt(getIamNotInterestedCount(userId), 10) < 3
            );
        }

        return false;
    };

    return (
        <StyledLayoutWithFooter className="d-flex flex-column">
            {renderSelfBookPromoModal()}
            {!noHeader && (
                <NavSubMenuComponent>
                    <>
                        <Breadcrumb title="Batch Messaging" wrapper={false} />
                        <div className="w-75 mt-4">
                            <Text as="h1" variant="title">
                                Batch Messaging
                            </Text>
                            {onlyBatchGskFloreyEnabled ? (
                                <Text>
                                    <b>
                                        Only the COPD Florey questionnaire
                                        {batchSelfBookEnabled
                                            ? " or Self-Book are "
                                            : " is "}
                                        available for your practice.{" "}
                                    </b>
                                    Get Accurx Plus for more types of Florey
                                    questionnaires and SMS.
                                </Text>
                            ) : (
                                <Text>
                                    Send a message
                                    {batchSelfBookEnabled
                                        ? ", Self-Book "
                                        : " "}
                                    or a Florey questionnaire to a group of
                                    patients. Read more about{" "}
                                    <Text
                                        as="a"
                                        variant="link"
                                        props={{
                                            href: SupportUrls.BatchHowTo,
                                            rel: "noopener noreferrer",
                                            target: "_blank",
                                            onClick: () => {
                                                ChainAnalyticsTracker.trackBatchResourceClick(
                                                    {
                                                        ...analyticsLoggedInProps,
                                                        origin: history.location
                                                            .pathname,
                                                        batchResourceName:
                                                            SupportUrls.BatchHowTo,
                                                    },
                                                );
                                            },
                                        }}
                                    >
                                        how to use Accurx Batch Messaging
                                        <Icon
                                            name="OpenWindow"
                                            size={3}
                                            colour={"blue"}
                                        />
                                    </Text>
                                    .
                                </Text>
                            )}
                        </div>
                        <div className="d-flex mt-4">
                            <Button
                                type="button"
                                text={"Send a batch message"}
                                icon={{
                                    name: "Arrow",
                                    colour: "white",
                                    placement: "end",
                                    rotation: "right",
                                }}
                                onClick={sendBatchMessageHandler}
                            />
                            {onlyBatchGskFloreyEnabled && (
                                <div className="ml-3">
                                    <Button
                                        onClick={trackClickInfo}
                                        theme="secondary"
                                        text="Learn about Accurx Plus"
                                        icon={{
                                            name: "OpenWindow",
                                            placement: "end",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                </NavSubMenuComponent>
            )}
            {!noHeader && (
                <Text as="h3" variant="subtitle" skinny>
                    Message status
                </Text>
            )}
            <StatusesLabelsContext.Provider value={statusLabels}>
                <BatchMessageStatuses
                    workspaceId={practiceId}
                    confirmMessageSuccess={confirmMessageSuccess}
                    pageOrigin="Practices"
                />
            </StatusesLabelsContext.Provider>
        </StyledLayoutWithFooter>
    );
};

export default BatchMessageOverview;
