import { getHours } from "date-fns";

import { TIME_OPTIONS } from "../components/Compose/components/ScheduleSend/constants/constants";

export const useAvailableHourOptions = ({ isToday }: { isToday: boolean }) => {
    const currentHour = getHours(new Date());

    if (isToday) {
        return TIME_OPTIONS.filter((item) => +item.value > currentHour);
    }

    return TIME_OPTIONS;
};
