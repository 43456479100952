import {
    StyledOneLineBadge,
    StyledTruncatedText,
} from "./ContactDetailLabel.styles";
import { StyledCustomRadioLabel } from "./CustomRadio.styles";

export const ContactDetailLabel = ({
    text,
    badgeProps,
}: {
    text: string;
    badgeProps: {
        text?: string;
        selected: boolean;
    };
}) => {
    return (
        <StyledCustomRadioLabel
            gap={"1"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <StyledTruncatedText variant="preview" skinny>
                {text}
            </StyledTruncatedText>
            {badgeProps.text && (
                <StyledOneLineBadge
                    color={badgeProps.selected ? "blue" : "greyscale"}
                    radius="round"
                    size="small"
                >
                    {badgeProps.text}
                </StyledOneLineBadge>
            )}
        </StyledCustomRadioLabel>
    );
};
