import { HTMLAttributes } from "react";

import * as UI from "@accurx/design";
import { SizeVariants } from "@accurx/design/dist/styles/types";
import styled from "styled-components";

const List = styled.ul<{
    $gap: `${SizeVariants}`;
}>`
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: ${(props) => `${UI.Tokens.SIZES[props.$gap]}`};
`;

type FlexListProps = HTMLAttributes<HTMLUListElement> & {
    gap?: `${SizeVariants}`;
};

export const FlexList = ({ gap, ...props }: FlexListProps) => {
    return <List $gap={gap ?? "1"} {...props}></List>;
};
