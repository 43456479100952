import { useEffect } from "react";

import { Feedback } from "@accurx/design";
import { Redirect } from "react-router";

import { trackEditTemplatePageView } from "app/analytics/FlemingAnalytics";
import { useEditAccumailTemplateMutation } from "app/hooks/mutations";
import { useAccumailTemplateQuery } from "app/hooks/queries";
import { Container, Spinner } from "app/organisations/shared";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { SnomedCode } from "app/sharedComponents/SnomedPicker";
import { useCurrentOrgId, useCurrentOrgName } from "store/hooks";

import { textToJson } from "../accumailTemplateEditor";
import { EditTemplatePageHeader } from "./components/EditTemplatePageHeader";
import { TemplateForm } from "./form/TemplateForm";
import { useTemplateFormState } from "./form/useTemplateFormState";

type EditAccumailTemplateProps = {
    templateId: string;
};

export const EditAccumailTemplate = ({
    templateId,
}: EditAccumailTemplateProps) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const workspaceName = useCurrentOrgName();
    const { data, isLoading, isError } = useAccumailTemplateQuery({
        workspaceId,
        templateId: templateId,
    });
    const {
        mutate,
        isSuccess,
        isError: isMutationError,
        isLoading: isMutating,
    } = useEditAccumailTemplateMutation();

    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const templateLevel =
        data?.templateType === "UserTemplate" ? "User" : "Organisation";

    useEffect(() => {
        if (isLoading || isError) {
            return;
        }
        trackEditTemplatePageView({
            ...flemingLoggedInProperties,
            templateLevel,
            productOrigin: "ClinicianMessage",
            templateId: data.id,
        });
    }, [flemingLoggedInProperties, data, isLoading, isError, templateLevel]);

    const doEdit: (
        text: string,
        title: string,
        snomedCodes?: SnomedCode[],
    ) => void = (text, title, snomedCodes) =>
        mutate({
            workspaceId,
            data: { content: text, title, snomedCodes },
            templateId: templateId,
        });

    const templateFormState = useTemplateFormState({
        doSave: doEdit,
        type: data?.templateType,
        saveMode: "edit",
    });
    const {
        title,
        setTitle,
        editorContent,
        setEditorContent,
        manageTemplatePage,
        setSnomedCodes,
        snomedCodes,
    } = templateFormState;

    if (isSuccess && manageTemplatePage) {
        return <Redirect to={manageTemplatePage} />;
    }

    if (isLoading || isMutating || isError) {
        return (
            <Container>
                {isLoading || isMutating ? (
                    <Spinner />
                ) : (
                    <Feedback title="Unable to fetch template" colour="error">
                        Please refresh the page to try again.
                    </Feedback>
                )}
            </Container>
        );
    }

    const initialContent = textToJson(data.content);
    if (editorContent === undefined) {
        setEditorContent(initialContent);
    }
    if (title === undefined) {
        setTitle(data.title);
    }
    if (snomedCodes === undefined) {
        setSnomedCodes(data.snomedCodes ?? []);
    }

    const subtitle = `These changes will update ${
        data.templateType === "UserTemplate"
            ? "your template"
            : "the template for everyone in " + workspaceName
    }`;

    return (
        <TemplateForm
            header={
                <EditTemplatePageHeader
                    title={`Edit template: "${data.title}"`}
                    subtitle={subtitle}
                />
            }
            saveButtonText="Update template"
            initialContent={editorContent ?? initialContent}
            isMutationError={isMutationError}
            {...templateFormState}
        />
    );
};
