import React from "react";

import { Button, Text } from "@accurx/design";
import { i } from "images";

const refresh = () => {
    window.location.reload();
};

export const GeneralError = (): JSX.Element => (
    <div className="text-center">
        <Text as="h1" variant="title" props={{ className: "mb-4" }}>
            Oops! Something is not quite right
        </Text>

        <img
            className="mb-5"
            src={i("errors/error.svg")}
            alt="confused robot"
        />

        <Text skinny>
            Looks like something went wrong. Our engineering team has been
            notified.
        </Text>
        <Text>Try refreshing the page.</Text>

        <Button
            className="mx-auto mb-5"
            theme="primary"
            text="Refresh"
            onClick={refresh}
        />

        <Text>If that doesn't work please get in touch.</Text>
        <Text>
            Chat to us through{" "}
            <a
                href="https://www.accurx.com/contact-us"
                target="_blank"
                rel="noopener noreferrer"
            >
                live chat
            </a>{" "}
            or email us on{" "}
            <a href="mailto:support@accurx.com">support@accurx.com</a>
        </Text>
    </div>
);
