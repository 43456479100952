import { useAssignee } from "@accurx/concierge/hooks/data/useAssignee";
import { useUser } from "@accurx/concierge/hooks/data/useUser";
import {
    ItemCreatedByUser,
    StateChangeItem,
} from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";
import {
    formatAssigneeDisplayName,
    formatUserDisplayName,
} from "domains/inbox/util/format/names";

export type StateChangeAssignmentProps = {
    conversationItem: StateChangeItem;
};

export const StateChangeAssignment = ({
    conversationItem,
}: StateChangeAssignmentProps) => {
    const senderId = (conversationItem.createdBy as ItemCreatedByUser).id;

    const sender = useUser({ userId: senderId });
    const assignee = useAssignee(
        conversationItem.assignedTo ?? { type: "None" },
    );

    return (
        <StyledGrid>
            <UI.Cell>
                <UI.Text as="span" variant="note">
                    <UI.VisuallyHidden as="span">
                        Responded by
                    </UI.VisuallyHidden>
                    {formatUserDisplayName({ user: sender })}:
                </UI.Text>
            </UI.Cell>
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    Assigned this to {formatAssigneeDisplayName(assignee)}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
