import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const RadioTextWrapper = styled.div`
    white-space: nowrap;
    margin: ${Tokens.SIZES[0.25]} 0;
`;

export const SubTitlePositioning = styled.div`
    margin-left: ${Tokens.SIZES[0.5]};
    margin-top: ${Tokens.SIZES[0.25]};
`;
