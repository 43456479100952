import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";
import { conversationOrdering } from "domains/concierge/internal/util/conversationOrdering";
import { Conversation } from "domains/concierge/types";
import orderBy from "lodash/orderBy";

/**
 * When the conversation at the very boundary of the group receives an update we
 * need to know if it's still a part of the dense set or not. To do this we can
 * sort the updated and existing version based on the group sorting rules. If
 * the update is sorted earlier or exactly equal to the existing version in the
 * sort order then we know it's part of the dense set. If the update is sorted
 * after the existing version then we can no longer guarentee that it's part of
 * the dense set.
 */
export const isUpdateBelowCeiling = (
    update: Conversation,
    current: Conversation,
    sortOptions: ConversationGroup["sortOptions"],
): boolean => {
    const [comparables, orders] = conversationOrdering(sortOptions);

    const currentComparables = comparables.map((f) => f(current));
    const updateComparables = comparables.map((f) => f(update));

    // Lodash's sort is a stable sort and maintains the order of exactly equal
    // entities. So place the update first and then we know that, if the update
    // is still first after sorting then it must be before or exactly equal to
    // the current.
    const sorted = orderBy(
        [updateComparables, currentComparables],
        comparables.map((_, index) => index),
        orders,
    );

    return sorted[0] === updateComparables;
};
