import * as UI from "@accurx/design";

import { StyledDisconnectedState } from "../CurrentPatient.styles";

export const CurrentPatientDisconnected = () => {
    return (
        <StyledDisconnectedState>
            <UI.Icon
                name="Failed"
                size={3}
                theme="Line"
                colour="currentColor"
            />
            We were unable to connect to the medical system
        </StyledDisconnectedState>
    );
};
