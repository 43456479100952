import { Link, LinkProps, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { mobileNavItemStyles } from "./MobileNavItem.styles";

export const MobileNavButton = styled.button`
    ${mobileNavItemStyles};
    background: none;
`;

const StyledMobileNavLink = styled(Link)`
    ${mobileNavItemStyles}
`;

export const MobileNavLink = ({
    activePaths,
    to,
    children,
    ...props
}: { activePaths: string[] } & LinkProps) => {
    const routeMatch = useRouteMatch(activePaths);
    const isActiveRoute = Boolean(routeMatch);

    return (
        <StyledMobileNavLink to={to} data-active={isActiveRoute} {...props}>
            {children}
        </StyledMobileNavLink>
    );
};
