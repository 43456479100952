import { EmailAddressHelper } from "@accurx/shared";

const getFullNameError = (fullName: string): string => {
    if (!fullName) {
        return "The full name field is required.";
    } else if (fullName.length < 2) {
        return "Please enter your full name (at least 2 characters long)";
    }
    return "";
};

/**
 * Check the validity of a email
 * @param email value to check
 * @returns [string] relevant error or empty string
 */
const getEmailError = (email: string): string => {
    if (!email) {
        return "The email field is required.";
    }

    if (!EmailAddressHelper.isValidEmailAddress(email)) {
        return "Please enter a valid email address.";
    }

    return "";
};

/**
 * Check the validity of a password
 * - 8 to 100 characters
 * - at least 1 number
 * - at least one upper/lower case
 * @param password value to check
 * @returns [string] relevant error or empty string
 */
const getPasswordError = (password: string): string => {
    if (!password) {
        return "The password field is required.";
    }

    if (password.length < 8 || password.length > 100) {
        return "The password must contain at least 8 and at max 100 characters long.";
    }

    if (!/[0-9]/.test(password)) {
        return "The password must contain at least one number.";
    }

    const lowercase = password.toLowerCase();
    if (lowercase === password) {
        return "The password must contain at least one upper case letter.";
    }

    const uppercase = password.toUpperCase();
    if (uppercase === password) {
        return "The password must contain at least one lower case letter.";
    }

    return "";
};

const getConfirmPasswordError = ({
    password,
    confirmPassword,
}: {
    password: string;
    confirmPassword: string;
}): string => {
    if (!confirmPassword) {
        return "Please confirm your password";
    }

    if (confirmPassword !== password) {
        return "Sorry, your passwords don't match";
    }
    return "";
};

/**
 * Check if Terms and Conditions has been checked
 * @param termsAndConditions value to check
 * @returns [string] relevant error or empty string
 */
const getTermsAndConditionsError = (termsAndConditions: boolean): string => {
    if (termsAndConditions !== true) {
        return "Please confirm you have read and accepted the Terms and Conditions.";
    }
    return "";
};

export {
    getFullNameError,
    getEmailError,
    getPasswordError,
    getConfirmPasswordError,
    getTermsAndConditionsError,
};
