import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledPatientMatchSpacer = styled.div`
    padding: ${UI.Tokens.SIZES[2]};
`;

export const StyledTabButtonContainer = styled.div`
    padding-left: ${UI.Tokens.SIZES[1]};
`;

export const StyledFormsDescriptionText = styled(UI.Text).attrs({
    skinny: true,
    variant: "preview",
})`
    margin-bottom: ${UI.Tokens.SIZES[3]};
`;

export const StyledDivider = styled.hr`
    margin: 0;
`;
