export const LINKS = {
    UPGRADE:
        "https://support.accurx.com/en/articles/4966664-what-do-the-restriction-notifications-mean-and-how-to-upgrade",
    FLOREY_PLUS:
        "https://support.accurx.com/en/articles/4970899-florey-plus-resource-centre",
    BATCH_AND_APPOINTMENT_REMINDERS:
        "https://support.accurx.com/en/articles/4970919-batch-messaging-appointment-reminders-resource-centre",
    VIDEO_CONSULTATIONS:
        "https://support.accurx.com/en/articles/4971029-video-resource-centre",
    PATIENT_TRIAGE:
        "https://support.accurx.com/en/articles/4970829-patient-triage-resource-centre",
};
