import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { i } from "images";

import { StyledWrapper } from "./RefreshableErrorState.styles";

type RefreshableErrorStateProps = {
    text: string;
    onClickRefresh: () => void;
};

export const RefreshableErrorState = ({
    text,
    onClickRefresh,
}: RefreshableErrorStateProps) => {
    return (
        <StyledWrapper>
            <img
                src={i("illustrations/error-icon-80px@2x.png")}
                srcSet={`${i("illustrations/error-icon-80px.png")}, ${i(
                    "illustrations/error-icon-80px@2x.png",
                )} 2x, ${i("illustrations/error-icon-80px@3x.png")} 3x`}
                alt="Conversation feed loading error"
                height="80"
            />
            <UI.Text colour="metal" skinny>
                {text}
            </UI.Text>
            <Pill.SecondaryButton onClick={onClickRefresh}>
                <Pill.Icon name="Refresh" colour="blue" size={3} />
                <Pill.Text>Refresh</Pill.Text>
            </Pill.SecondaryButton>
        </StyledWrapper>
    );
};
