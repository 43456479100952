import { useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { User } from "@accurx/auth";
import { Flex, Text } from "@accurx/design";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

import { formatSearch } from "app/account/Account.helper";
import { StyledFullWidthButton } from "app/account/SharedStyledComponents.styles";
import { LoginPageLayout } from "app/layout/LoginPageLayout/LoginPageLayout";
import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { ActiveDirectoryRedirectForm } from "../../ActiveDirectoryRedirectForm";
import {
    LOGIN_LAYOUT_CONSTANTS,
    SHOW_LOGIN_FORM_SEARCH_PARAM,
} from "../LogIn.constants";
import { LoginForm } from "../LoginForm";
import { InnerStackPanel, OuterStackPanel } from "../LoginStackPanel";
import { StyledStackPanel } from "../OrganisationsLogin/OrgLogin.styles";
import { FullWidthFlex, LogInOptionsButton } from "./Login.styles";
import { NhsMailLoginButtonLink } from "./NhsMailLogin";

export const Login = () => {
    const history = useHistory();
    const location = useLocation<{ email?: string }>();

    // Set default product in case none was present
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("product", `${RegisterAccountProductType.Fleming}`);

    const [externalOrgId, setExternalOrgId] = useState("");

    const showActiveDirectoryRedirect = Boolean(externalOrgId);
    const showLoginFormSearchParam = searchParams.get(
        SHOW_LOGIN_FORM_SEARCH_PARAM,
    );
    const showLoginForm = showLoginFormSearchParam === "true";
    const defaultEmail = location.state?.email;

    const searchPreLogin = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: [SHOW_LOGIN_FORM_SEARCH_PARAM],
    });

    const searchPostLogin = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: [SHOW_LOGIN_FORM_SEARCH_PARAM, "product"],
    });

    const isLoggedIn = useAppSelector(({ account }) => account.isLoggedIn);

    if (isLoggedIn) {
        return (
            <Redirect
                to={{
                    ...location,
                    pathname: ROUTES.postlogin,
                    search: searchPostLogin,
                }}
            />
        );
    }

    const onLoginSuccess = ({ organisationExternalId }: User) => {
        if (organisationExternalId) {
            setExternalOrgId(organisationExternalId);
        } else {
            window.location.href = `${ROUTES.postlogin}${searchPostLogin}`;
        }
    };

    return (
        <LoginPageLayout {...LOGIN_LAYOUT_CONSTANTS.defaultTrust}>
            <OuterStackPanel>
                <StyledStackPanel
                    orientation="vertical"
                    gutter={3}
                    horizontalContentAlignment="center"
                >
                    <Text variant="title" skinny as="h1">
                        Welcome
                    </Text>
                    <InnerStackPanel horizontalContentAlignment="stretch">
                        {showActiveDirectoryRedirect ? (
                            <ActiveDirectoryRedirectForm
                                externalOrgId={externalOrgId}
                                onBack={() => setExternalOrgId("")}
                            />
                        ) : showLoginForm ? (
                            <Flex flexDirection="column" gap="2">
                                <LogInOptionsButton
                                    theme="secondary"
                                    icon={{
                                        name: "ArrowTail",
                                        rotation: "left",
                                    }}
                                    text="Log in options"
                                    onClick={() => {
                                        history.push(
                                            `${ROUTES.login}${searchPreLogin}`,
                                        );
                                    }}
                                />
                                <LoginForm
                                    product={RegisterAccountProductType.Fleming}
                                    defaultEmail={defaultEmail}
                                    onLoginSuccess={onLoginSuccess}
                                />
                            </Flex>
                        ) : (
                            <Flex
                                flexDirection="column"
                                gap="1"
                                alignItems="center"
                            >
                                <FullWidthFlex alignItems="center">
                                    <NhsMailLoginButtonLink
                                        nextRouteAfterLogin={{
                                            // Always redirect to /postlogin. 'next' search param is passed along and handled.
                                            route: ROUTES.postlogin,
                                            search: searchPostLogin,
                                        }}
                                    />
                                    <Text skinny>
                                        Log in or sign up instantly with your
                                        nhs.net account
                                    </Text>
                                </FullWidthFlex>
                                <Text skinny variant="label">
                                    or
                                </Text>
                                <StyledFullWidthButton
                                    theme="secondary"
                                    text="Log in with email (nhs.uk)"
                                    onClick={() => {
                                        history.push(
                                            `${ROUTES.login}${searchPreLogin}&${SHOW_LOGIN_FORM_SEARCH_PARAM}=true`,
                                        );
                                    }}
                                />
                            </Flex>
                        )}
                    </InnerStackPanel>
                    <Text variant="label" skinny>
                        New to Accurx?{" "}
                        <Link to={`${ROUTES.register}${searchPreLogin}`}>
                            Create an account
                        </Link>
                    </Text>
                </StyledStackPanel>
            </OuterStackPanel>
        </LoginPageLayout>
    );
};
