import * as UI from "@accurx/design";
import styled from "styled-components";

const { COLOURS, SIZES, BORDERS } = UI.Tokens;

export const PopoverContent = styled(UI.PopoverContent)<{
    // TODO: after MessageComponentV1_1 is deprecated,
    // this prop can be hardcoded rather than dynamic
    $minWidth: number;
}>`
    padding: 0;
    min-width: ${({ $minWidth }) => $minWidth}px;
`;

export const Header = styled.div`
    background-color: ${COLOURS.greyscale.cloud};
    border-bottom: ${BORDERS.normal.silver};
    padding: ${SIZES[1]} ${SIZES[1.5]};
`;

export const AttachOptionsContainer = styled.div`
    padding: ${SIZES[1]};
`;

export const StyledAttachSelector = {
    PopoverContent,
    Header,
    AttachOptionsContainer,
};
