import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { placeholderOptions } from "app/practices/accumail/accumailTemplateEditor/helpers/constants";

import { ProductOrigin } from ".";

type PlaceholderOption = (typeof placeholderOptions)[number]["label"];
type PatientDetail = { patientDetail: PlaceholderOption };

export const trackClickAddPatientDetailButtonClick = (
    props: ProductOrigin,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateCreatePatientDetail",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackClickAddPatientDetailMenuItemClick = (
    props: ProductOrigin & PatientDetail,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplatePatientDetailAdd",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};
