/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { useEffect } from "react";

import { EventCallback, Events } from "./HubClient";
import { useHubClient } from "./useHubClient";

export { SocketEvents } from "./HubClient";

export function useWebsocketEvent<T extends Events>(
    eventName: T,
    callback: EventCallback<T>,
    dependencies: ReadonlyArray<any>,
) {
    const hubClient = useHubClient();

    return useEffect(() => {
        const subscription = hubClient.getSubscription(eventName);
        // .subscribe creates a new observable with the original as the source
        const localSubscription = subscription?.subscribe(
            callback as EventCallback<Events>,
        );

        return () => {
            if (localSubscription !== undefined) {
                // Don't unsubscribe or complete the subscription const as this will
                // stop the subscription within the HubClient and prevent further
                // subscriptions on dependencies changing
                localSubscription.unsubscribe();
            }
        };
    }, [...dependencies, hubClient]);
}
