import { Feedback, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledBadgePositioner = styled.span`
    margin-left: ${Tokens.SIZES[0.5]};
    margin-top: -4px;
`;

export const StyledFeedback = styled(Feedback)`
    padding-top: ${Tokens.SIZES[1]};
    padding-bottom: ${Tokens.SIZES[1]};

    > div:nth-child(2) {
        margin-top: ${Tokens.SIZES[0.25]};
    }
`;

export const FeedbackSpacingWrapper = styled.div`
    margin-top: ${Tokens.SIZES[1]};
`;
