import { useContext } from "react";

import { ClinicianConversationContext } from "../providers";

export const useIsMessageGpTestFlow = () => {
    const context = useContext(ClinicianConversationContext);

    if (context === null) {
        throw new Error(
            "useClinicianConversation must be used inside an appropriate conversation provider",
        );
    }

    return context.isTestFlow ?? false;
};
