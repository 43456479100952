import { Option } from "@accurx/design";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";

type ClinicCode = NonNullable<IntegratedClinic["clinicCode"]>;

export function getClinicCodeFilterOptions(
    clinics: IntegratedClinic[],
): Option[] {
    const values = clinics.map((clinic) => clinic.clinicCode);
    const dedupedValues = new Set(values);
    const definedValues = [...dedupedValues].filter(
        (value): value is ClinicCode => !!value,
    );
    const sortedValues = definedValues.sort();
    const options = sortedValues.map((value) => ({ value, label: value }));

    return options;
}
