import {
    ConditionQuestion,
    QuestionType,
    SnomedCode,
} from "@accurx/api/florey-builder";
import { Option } from "@accurx/design";
import { Log } from "@accurx/shared";

import { ClientSideQuestion } from "./types/context.types";

export const SNOMED_VALUE_DESCRIPTION = "Concept ID";

const FLOREY_BUILDER_ACCEPTABLE_USE_POLICY_KEY =
    "accurx-florey-builder-acceptable-use-policy";

export const NO_SNOMED_CODE_OPTION = {
    label: "No SNOMED code",
    value: "0",
    shouldHideValue: true,
};

export const mapQuestionToOption = ({
    question,
    questionId,
}: {
    question: string;
    questionId: string;
}): Option => {
    return { label: question, value: questionId };
};

export const mapToOption = (value: string): Option => {
    return { label: value, value };
};

export const mapSelectedQuestion = (
    conditionQuestion: ConditionQuestion | undefined,
): Option | undefined => {
    if (!conditionQuestion) {
        return undefined;
    }
    return {
        label: conditionQuestion.questionText,
        value: conditionQuestion.questionId.toString(),
    };
};

export const mapSelectedAnswer = (
    conditionQuestion: ConditionQuestion | undefined,
): Option | undefined => {
    if (!conditionQuestion) {
        return undefined;
    }
    return {
        label: conditionQuestion.answerText,
        value: conditionQuestion.questionId.toString(),
    };
};

/**
 * mapQuestionsToList - from a list of all questions
 * filter out those which are multiple choice questions - radio or checkbox
 * return in a format suitable for the select dropdown
 */
export const mapQuestionsToList = (
    questions: ClientSideQuestion[],
): Option[] => {
    return questions
        .filter(
            (question: ClientSideQuestion) =>
                question.questionType === QuestionType.MultipleChoice,
        )
        .map((question: ClientSideQuestion) => ({
            label: question.questionTitle ?? "",
            value: question.id?.toString() ?? "",
        }));
};
/**
 * getAnswersList - from a list of all questions
 * get a list of answers from the selected question
 */
export const getAnswersList = ({
    questions,
    selectedConditionQuestion,
}: {
    questions: ClientSideQuestion[];
    selectedConditionQuestion: ConditionQuestion | undefined;
}): Option[] => {
    if (
        !selectedConditionQuestion ||
        selectedConditionQuestion.questionId === 0
    ) {
        return [];
    }
    const chosenQuestion = questions.filter(
        (question) => question.id === selectedConditionQuestion.questionId,
    )[0];
    const listOfOptions = chosenQuestion?.options?.map(({ text }) => ({
        label: text, // answerText
        value: chosenQuestion.id?.toString() ?? "", // questionId
    }));
    return listOfOptions ?? [];
};

// Using enum instead of boolean so we have a strict record of different string values set for this item in storage
export enum AcceptableUsePolicyValues {
    True = "True",
    False = "False",
}

export const getUserAcceptableUsePolicyValue = (userId: string): string =>
    window.localStorage.getItem(
        `${FLOREY_BUILDER_ACCEPTABLE_USE_POLICY_KEY}-${userId}`,
    ) ?? "";

export const setUserAcceptableUsePolicyValue = (
    userId: string,
    value: AcceptableUsePolicyValues,
): void => {
    window.localStorage.setItem(
        `${FLOREY_BUILDER_ACCEPTABLE_USE_POLICY_KEY}-${userId}`,
        value,
    );
};

export const hasAcceptedFloreyBuilderUsePolicy = (userId: string): boolean =>
    getUserAcceptableUsePolicyValue(userId) === AcceptableUsePolicyValues.True;

export const mapSnomedCodeToConcept = (
    code: Optional<SnomedCode>,
): string | undefined => (code?.conceptId !== "0" ? code?.conceptId : ""); //empty conceptID is mapped to null in the backend

export const withSnomedCode = (code: SnomedCode | null | undefined): boolean =>
    !(code === undefined || code === null || code?.conceptId === "0");

export const floreyPlainTextToMarkdownLink = (text: string): string => {
    //This formats link from text based on the logic in accuRx.Florey.Client/ClientApp/src/survey/components/serverHyperlinkTextRenderer.js.
    //This is currently specific to helperText we expect to receive from templated height and weight text but in future should be shared logic for florey and fleming
    //The actual logic in florey-client relies on "react-process-string" which does not have available typescript types, so logic is reworked
    const urlElements = getUrlElements(text);
    if (urlElements === null || urlElements.length === 0) {
        return text;
    }
    if (urlElements.length > 1) {
        Log.warn("Expected only one url in helperText");
    }
    const urlElement = urlElements[0];
    const url = processUrl(urlElement);
    let displayText = processDisplayText(urlElement, url);
    if (displayText === null || displayText.trim() === "") {
        displayText = url;
    }
    const { postUrlText, preUrlText } = getPreAndPostUrlText(text, urlElement);
    return `${preUrlText}[${displayText}](${url})${postUrlText}`;
};

const getUrlElements = (text: string) => {
    return text.match(
        // disabling rather than changing so as to not risk breaking things
        // eslint-disable-next-line no-useless-escape
        /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)([a-z]*( [a-z]*)*)?/gim,
    );
};

const getPreAndPostUrlText = (
    text: string,
    url: string,
): { preUrlText: string; postUrlText: string } => {
    const preAndPostText = text.split(url);
    return { preUrlText: preAndPostText[0], postUrlText: preAndPostText[1] };
};

const processUrl = (urlSegment: string) => {
    const splits = urlSegment.split(" ");
    return splits[0];
};

const processDisplayText = (urlSegment: string, url: string) => {
    const splits = urlSegment.split(url);
    return splits[1].trim();
};
