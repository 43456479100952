import React from "react";

import { Feedback, Spinner } from "@accurx/design";
import { Log } from "@accurx/shared";

import { useConversationManager } from "shared/concierge/conversations/context/ConversationManagerContext";
import {
    useConversation,
    useConversationId,
    useCurrentUserId,
} from "shared/concierge/conversations/hooks";
import { AssigneeSummary } from "shared/concierge/conversations/types/assignee.types";
import { usePatient } from "shared/concierge/patients/hooks/usePatient";
import { PatientExternalIdentity } from "shared/concierge/patients/types/patient.types";

import { Conversation } from "../components/Conversation/Conversation";
import { mapPatientThreadSmsOrEmailToConversationItems } from "../components/Conversation/Conversation.helpers";
import { OnSendMessageSuccessType } from "../components/Conversation/Conversation.types";

export const ConversationPage = (): JSX.Element | null => {
    const manager = useConversationManager();
    const conversationId = useConversationId();
    const {
        data: conversation,
        status: conversationStatus,
        errorMessage,
        actions,
    } = useConversation(manager, conversationId);
    const currentUserId = useCurrentUserId();

    const { data: patient, status: patientStatus } = usePatient(
        conversation?.regardingPatientId,
    );

    const patientData = {
        data: patient,
        isLoading: patientStatus === "LOADING",
    };

    /**
     * This page component should only ever get rendered on a URL that
     * links to a valid conversation so if we have no conversationId
     * something has gone wrong. In that case we'll log an error and display
     * nothing.
     */
    if (conversationId === null) {
        Log.error("A conversation page was displayed at an invalid URL");
        return null;
    }

    if (conversationStatus === "LOADING") {
        return <Spinner />;
    }

    if (conversationStatus === "ERROR") {
        return (
            <Feedback
                title="An error occurred while trying to load the conversation"
                colour="error"
            >
                {errorMessage}
            </Feedback>
        );
    }

    const onSendMessageSuccess: OnSendMessageSuccessType = (messages) => {
        const conversationItems =
            mapPatientThreadSmsOrEmailToConversationItems(messages);

        actions.addItemsToConversation(conversation, conversationItems);
    };

    const markAsDone = async () => {
        await actions.markAsDone(conversation);
    };

    const markAsOpen = async () => {
        await actions.markAsOpen(conversation);
    };

    const markItemAsRead = async (
        itemId: string,
        patientExternalId: PatientExternalIdentity | null,
    ) => {
        await actions.markItemAsRead(itemId, patientExternalId);
    };

    const assign = async (assignee: AssigneeSummary) => {
        await actions.assign(conversation, assignee);
    };

    const matchPatientToConversation = async (patientToken: string) => {
        try {
            await actions.matchPatientToConversation(
                conversation,
                patientToken,
            );
            return true;
        } catch (error) {
            Log.error(
                `Match patient to conversation action failed.${
                    error && ` ${error}`
                }`,
            );
            return false;
        }
    };

    return (
        <Conversation
            conversation={conversation}
            patient={patientData}
            conversationActions={{
                markAsDone,
                markAsOpen,
                markItemAsRead,
                onSendMessageSuccess,
                assign,
                matchPatientToConversation,
            }}
            currentUserId={currentUserId}
        />
    );
};
