import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled(UI.Flex).attrs({
    gap: "0.5",
    flexDirection: "column",
})`
    border-top: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    padding-top: ${UI.Tokens.SIZES[0.5]};
`;
