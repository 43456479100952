import { createContext } from "react";

import noop from "lodash/noop";

type Value<T> = {
    value: T;
    setValue: (v: T) => void;
};

type InboxContextValue = {
    feedCount: Value<number>;
};

const initialState: InboxContextValue = {
    feedCount: { value: 0, setValue: noop },
};

export const InboxContext = createContext<InboxContextValue>(initialState);
