/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Button, Card, Icon, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useHistory } from "react-router-dom";

import { RecordViewRequestResult } from "api/FlemingDtos";
import { ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";

import { getRequestRemainingHoursText } from "../RecordView.helper";
import { CardSpacer } from "../medicalRecord/CardSpacer";

type LatestPatientRecordRequestProps = {
    latestRecord: RecordViewRequestResult | null;
};

export const LatestPatientRecordRequest = ({
    latestRecord,
}: LatestPatientRecordRequestProps): JSX.Element | null => {
    const history = useHistory();

    const goToRecord = (): void => {
        if (latestRecord !== null) {
            history.replace(
                `${findBaseRoute(history.location.pathname)}${
                    ROUTES_EXTENSION.remoteRecordViewMedicalRecord
                }?request=${latestRecord.requestId}`,
            );
        }
    };

    const displayDate =
        latestRecord && latestRecord.requestedTime !== null
            ? DateHelpers.formatDate(
                  latestRecord.requestedTime,
                  DateFormatOptions.DATE_DROPDOWN_OPTION_FORMAT,
              )
            : "";

    const timeRemaining = latestRecord
        ? getRequestRemainingHoursText(latestRecord.accessRemainingHours)
        : "";

    return (
        <>
            <Card
                header={
                    <div className="d-flex align-items-center justify-content-between">
                        <Text variant="subtitle" as="h2" skinny>
                            <Icon
                                name="Record"
                                style="Line"
                                props={{
                                    className: "align-bottom mr-2",
                                }}
                                halo={{ colour: "green" }}
                            />
                            Record available
                        </Text>
                        <Button text="View record" onClick={goToRecord} />
                    </div>
                }
            >
                <Text skinny>
                    Requested: {displayDate} ({timeRemaining})
                </Text>
                <Text skinny>
                    For a more up-to-date record, start the request process
                    again below.
                </Text>
            </Card>
            <CardSpacer spacing="medium" />
        </>
    );
};
