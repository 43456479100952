import * as UI from "@accurx/design";

import { BodyInfoBox } from "../BodyInfoBox";

export const QuestionnaireInfoBox = () => {
    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header gap="1" fullBorder>
                <UI.Flex gap={"1"}>
                    <BodyInfoBox.Icon name="Questionnaire" />
                    <BodyInfoBox.Text colour="night">
                        {
                            "Please complete this questionnaire: [link will appear here]"
                        }
                    </BodyInfoBox.Text>
                </UI.Flex>
            </BodyInfoBox.Header>
        </BodyInfoBox.Wrapper>
    );
};
