import { MemberWorkspace } from "@accurx/api/clinician-messaging";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { Participant } from "domains/concierge/schemas/ParticipantSchema";

import { mapUserMemberToConversationParticipant } from "./mapIndividualParticipantToConversationParticipant";

export const mapMemberWorkspaceToConversationParticipant = (
    participantDto: MemberWorkspace,
): Participant | undefined => {
    return {
        type: "Workspace",
        workspaceId: participantDto.workspaceId,
        displayName: participantDto.workspaceName,
        individualParticipants: participantDto.participants
            .map(mapUserMemberToConversationParticipant)
            .filter(isInstance),
    };
};
