import { Badge, Input, Text, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledCurrentAssigneeContainer = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;

    border-radius: ${Tokens.SIZES[1]};

    &:hover {
        > * {
            border-color: ${Tokens.COLOURS.primary.blue[100]};
            text-decoration: none;
            background: ${Tokens.COLOURS.primary.blue[5]};
            transition: background-color 0.2s ease-in;
        }
    }
`;

export const StyledText = styled(Text)`
    text-overflow: ellipsis;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
`;

export const StyledDetailsSection = styled.div<{ isDisabled: boolean }>`
    display: flex;
    align-items: center;
    border: ${Tokens.BORDERS.normal.silver};
    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]} ${Tokens.SIZES[0.5]}
        ${Tokens.SIZES[0.5]};
    max-width: 240px;
    column-gap: ${Tokens.SIZES[1]};
    align-self: stretch;
    background-color: ${Tokens.COLOURS.greyscale.white};
    ${({ isDisabled }) =>
        isDisabled
            ? css`
                  border-radius: ${Tokens.SIZES[1]};
              `
            : css`
                  border-right: 0;
                  border-radius: ${Tokens.SIZES[1]} 0px 0px ${Tokens.SIZES[1]};
              `};
`;

export const StyledIconSection = styled.div<{ isOpen: boolean }>`
    background: ${({ isOpen }) =>
        isOpen
            ? Tokens.COLOURS.greyscale.silver
            : Tokens.COLOURS.greyscale.white};
    border: 1px solid
        ${({ isOpen }) =>
            isOpen
                ? Tokens.COLOURS.primary.blue["100"]
                : Tokens.COLOURS.greyscale.silver};
    border-radius: 0px ${Tokens.SIZES[1]} ${Tokens.SIZES[1]} 0px;
    padding: ${Tokens.SIZES[1]};
`;

export const StyledContainer = styled.ul`
    overflow-y: auto;
    max-height: 320px;
    margin: 0;
    padding: 0;
`;

export const StyledBadge = styled(Badge)`
    margin-left: auto;
`;

export const StyledSearchInput = styled(Input)`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledPopoverContentWrapper = styled.div`
    width: 344px;
    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        width: 302px;
    }
`;

export const StyledTeamIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${Tokens.SIZES[0.25]};
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    background: ${Tokens.COLOURS.greyscale.dishwater};
    border-radius: ${Tokens.BORDERS.radius};
`;
