const splitUserName = (userName: string): string[] => {
    return userName
        .split(" ")
        .map((namePart) => {
            // Return empty string if name only contains special characters
            if (namePart.match(/[0-9a-z]/i) === null) {
                return "";
            }
            const matches = namePart.match(/(\w|-|')+/g);
            return matches === null ? "" : matches.join("-");
        })
        .filter(Boolean);
};

export const getInitials = (fullName?: string): string => {
    const nameParts = fullName
        ? splitUserName(fullName).map((name) => name.charAt(0).toUpperCase())
        : [];

    if (nameParts.length === 0) {
        return "";
    }

    return `${nameParts[0]}${
        nameParts.length > 1 ? nameParts[nameParts.length - 1] : ""
    }`;
};
