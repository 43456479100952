import { useFeatureFlag } from "@accurx/auth";
import { getEmbedMode } from "@accurx/native";

export const useHasClinicianMessagingInbox = (): boolean => {
    // Only web users will have access
    const { embedMode } = getEmbedMode();

    // It's also currently being built and so is behind a feature flag
    const hasBuildFlag = useFeatureFlag("UnifiedInboxAccumailSandboxBuild");

    return hasBuildFlag && embedMode === "Web";
};
