import { useMemo } from "react";

import { Conversation, ConversationItem } from "@accurx/concierge/types";
import { conversationHasAttachments } from "@accurx/concierge/util/conversationHasAttachments";
import * as UI from "@accurx/design";
import { useCanMarkConversationUnread } from "domains/inbox/hooks/util";
import { ItemStateWithReason } from "domains/inbox/hooks/util/useUpdatingFeed";

import { PreviewSummary } from "../PreviewSummary/PreviewSummary";
import {
    EXTENDED_CONVERSATION_PREVIEW_HEIGHT,
    StyledBadgesGroup,
    StyledFooter,
    StyledHeader,
} from "./ConversationPreview.styles";
import {
    AssigneeBadge,
    ConversationPreviewActions,
    ConversationPreviewLayout,
    ConversationPreviewPatientName,
    LastUpdateTime,
    StatusBadge,
} from "./components";
import { CurrentlyViewingBadge } from "./components/badges/CurrentlyViewingBadge/CurrentlyViewingBadge";
import { UnreadBadge } from "./components/badges/UnreadBadge/UnreadBadge";

export type ConversationPreviewProps = {
    conversation: Conversation;
    state: ItemStateWithReason;
    item: ConversationItem;
    lastUpdateTime?: string;
    displayAssigneeBadge?: boolean;
    hideConversationActions?: boolean;
};

export const ClinicianConversationPreview = ({
    conversation,
    item,
    state,
    displayAssigneeBadge,
}: {
    conversation: Conversation;
    item: ConversationItem;
    state: ItemStateWithReason;
    displayAssigneeBadge?: boolean;
}) => {
    const hasAttachments = useMemo(
        () => conversationHasAttachments(conversation),
        [conversation],
    );
    return (
        <ConversationPreviewLayout
            conversation={conversation}
            item={item}
            state={state}
            height={EXTENDED_CONVERSATION_PREVIEW_HEIGHT}
        >
            <StyledHeader
                justifyContent="space-between"
                alignItems="center"
                gap="1"
            >
                <UI.Item>
                    <UI.Text variant="note" props={{ className: "text-item" }}>
                        With: GP practice
                    </UI.Text>
                </UI.Item>
                <UI.Item>
                    <LastUpdateTime
                        time={conversation.lastUpdated}
                        textProps={{ className: "text-item" }}
                    />
                </UI.Item>
            </StyledHeader>
            <UI.Flex gap="0.25">
                <UI.Text
                    skinny
                    variant="note"
                    props={{ className: "text-item" }}
                >
                    About:{" "}
                </UI.Text>
                <ConversationPreviewPatientName conversation={conversation} />
            </UI.Flex>

            <UI.Flex justifyContent="space-between" gap="0.25">
                <UI.Item flex="1" className="preview-summary">
                    <PreviewSummary conversationItem={item} />
                </UI.Item>
                {hasAttachments && (
                    <UI.Item>
                        <UI.Icon name="Paperclip" colour="stone" size={3} />
                    </UI.Item>
                )}
                <UI.Flex alignItems="center">
                    <UnreadBadge conversation={conversation} />
                </UI.Flex>
            </UI.Flex>
            <StyledFooter
                justifyContent="space-between"
                alignItems="center"
                gap="0.5"
            >
                <StyledBadgesGroup gap="1">
                    <CurrentlyViewingBadge conversation={conversation} />
                    {displayAssigneeBadge && (
                        <AssigneeBadge assignee={conversation.assignee} />
                    )}
                    <StatusBadge
                        status={conversation.status}
                        conversationItem={item}
                    />
                </StyledBadgesGroup>
                <ConversationPreviewActions.Provider
                    conversation={conversation}
                    state={state}
                    tooltipText={
                        conversation.status === "Done"
                            ? "Mark not done"
                            : "Mark done"
                    }
                >
                    <ConversationPreviewActions.ToggleStatus />
                </ConversationPreviewActions.Provider>
            </StyledFooter>
        </ConversationPreviewLayout>
    );
};

export const ConversationPreview = ({
    conversation,
    state,
    item,
    lastUpdateTime,
    displayAssigneeBadge = false,
    hideConversationActions = false,
}: ConversationPreviewProps) => {
    const hasAttachments = useMemo(
        () => conversationHasAttachments(conversation),
        [conversation],
    );
    const showMarkUnread = useCanMarkConversationUnread(conversation);
    const actionTooltipText = (() => {
        if (conversation.status === "Done") return "Mark not done";
        return showMarkUnread
            ? "Mark done, urgent or unread"
            : "Mark done or urgent";
    })();
    return (
        <ConversationPreviewLayout
            conversation={conversation}
            item={item}
            state={state}
        >
            {/*
                Example of how a screen reader will announce a conversation preview:
                Urgent conversation with 3 unread items, has attachment, assigned to Dr Ray, marked as done, patient Smith, John  34, male, updated at Friday 1 September, responded by Accurx: Message summary: Patient did not respond
            */}
            <UI.VisuallyHidden as="span">
                {conversation.isUrgent ? `Urgent conversation` : `Conversation`}
                <UnreadBadge conversation={conversation} />
                {hasAttachments && <span>has attachment, </span>}
                {displayAssigneeBadge && (
                    <AssigneeBadge assignee={conversation.assignee} />
                )}
                <StatusBadge
                    status={conversation.status}
                    conversationItem={item}
                />
            </UI.VisuallyHidden>

            <StyledHeader
                justifyContent="space-between"
                alignItems="center"
                gap="1"
            >
                <UI.Item>
                    <ConversationPreviewPatientName
                        conversation={conversation}
                    />
                </UI.Item>
                <UI.Item>
                    <LastUpdateTime
                        time={lastUpdateTime ?? conversation.lastUpdated}
                        textProps={{ className: "text-item" }}
                    />
                </UI.Item>
            </StyledHeader>
            <UI.Flex justifyContent="space-between" gap="0.25">
                {conversation.isUrgent && (
                    <UI.Item>
                        <UI.Icon
                            name="Flag"
                            colour="red"
                            theme="Fill"
                            size={3}
                        />
                    </UI.Item>
                )}
                <UI.Item flex="1" className="preview-summary">
                    <PreviewSummary conversationItem={item} />
                </UI.Item>
                {hasAttachments && (
                    <UI.Item>
                        <UI.Icon name="Paperclip" colour="stone" size={3} />
                    </UI.Item>
                )}
                <UI.Flex alignItems="center">
                    <UnreadBadge conversation={conversation} />
                </UI.Flex>
            </UI.Flex>
            <StyledFooter
                justifyContent="space-between"
                alignItems="center"
                gap="0.5"
            >
                <StyledBadgesGroup gap="1">
                    <CurrentlyViewingBadge conversation={conversation} />
                    {displayAssigneeBadge && (
                        <AssigneeBadge assignee={conversation.assignee} />
                    )}

                    <StatusBadge
                        status={conversation.status}
                        conversationItem={item}
                    />
                </StyledBadgesGroup>

                {!hideConversationActions && conversation.status !== "None" && (
                    <ConversationPreviewActions.Provider
                        conversation={conversation}
                        state={state}
                        tooltipText={actionTooltipText}
                    >
                        <ConversationPreviewActions.ToggleStatus />
                        <ConversationPreviewActions.ToggleUrgency />
                        <ConversationPreviewActions.MarkAsUnread />
                    </ConversationPreviewActions.Provider>
                )}
            </StyledFooter>
        </ConversationPreviewLayout>
    );
};
