import { Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

export const MentionAtomStyles = css`
    background: ${Tokens.COLOURS.greyscale.silver};

    border-radius: ${Tokens.BORDERS.radius};
    padding: 0.2rem 0.5rem;
    white-space: nowrap;

    ${Tokens.TYPOGRAPHY.body.base}
    ${Tokens.TYPOGRAPHY.body.mobile}
    font-size: 0.8rem;
    color: ${Tokens.COLOURS.greyscale.night};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        ${Tokens.TYPOGRAPHY.body.desktop}
        font-size: 0.8rem;
    }
`;

export const StyledEditor = styled.div<{ hasErrors?: boolean }>`
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES["1"]};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    margin-top: ${Tokens.SIZES["1"]};

    & p {
        ${Tokens.TYPOGRAPHY.body.base}
        ${Tokens.TYPOGRAPHY.body.mobile}

        @media (min-width: ${Tokens.BREAKPOINTS.md}) {
            ${Tokens.TYPOGRAPHY.body.desktop}
        }
    }

    .remirror-mention-atom {
        ${MentionAtomStyles}
    }

    & .remirror-editor-wrapper {
        position: relative;
        width: 100%;

        /* Border alternative */
        &::before {
            content: " ";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            padding: ${Tokens.SIZES["1"]} ${Tokens.SIZES["1.5"]};
            border-radius: ${Tokens.BORDERS.radius};
            border: ${({ hasErrors }) =>
                hasErrors
                    ? Tokens.BORDERS.normal.red
                    : Tokens.BORDERS.normal.silver};
            box-shadow: ${({ hasErrors }) =>
                hasErrors
                    ? Tokens.SHADOWS.lowered.shallow.red
                    : Tokens.SHADOWS.lowered.shallow.silver};
            transition: all 0.2s ease;
        }

        &:focus-within,
        &:hover {
            &::before {
                box-shadow: ${({ hasErrors }) =>
                    hasErrors
                        ? Tokens.BORDERS.normal.red
                        : Tokens.SHADOWS.lowered.shallow.metal};
                border: ${({ hasErrors }) =>
                    hasErrors
                        ? Tokens.SHADOWS.lowered.shallow.red
                        : Tokens.BORDERS.normal.metal};
            }
        }
    }

    & .remirror-editor {
        border-radius: ${Tokens.BORDERS.radius};
        padding: ${Tokens.SIZES["1"]} ${Tokens.SIZES["1.5"]};
        pointer-events: auto;
        position: relative;
        height: 200px;
        overflow-y: auto;
    }
`;
