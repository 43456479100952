import React from "react";

import { Button } from "@accurx/design";

import { WeekView } from "./CapacityDashboard.type";
import { Wrapper } from "./CapacityFilter.styles";

type CapacityFilterProps = {
    weekViewSelected: WeekView;
    setWeekViewSelected: (view: WeekView) => void;
};

const FILTER_VALUES = [
    {
        type: WeekView.upTo3Weeks,
        text: "0 - 3 weeks",
    },
    {
        type: WeekView.pastSlots,
        text: "Past slots",
    },
    {
        type: WeekView.moreThan3Weeks,
        text: "> 3 weeks",
    },
];

export const CapacityFilter = ({
    weekViewSelected,
    setWeekViewSelected,
}: CapacityFilterProps): JSX.Element => (
    <Wrapper>
        <div className="d-flex">
            {FILTER_VALUES.map((filter) => (
                <Button
                    key={filter.type}
                    type="button"
                    theme={
                        weekViewSelected === filter.type
                            ? "primary"
                            : "transparent"
                    }
                    text={filter.text}
                    className="m-1"
                    onClick={() => {
                        setWeekViewSelected(filter.type);
                    }}
                />
            ))}
        </div>
    </Wrapper>
);
