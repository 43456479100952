import {
    Feedback,
    Flex,
    FormFieldWrapper,
    Link,
    Option,
    SearchSelect,
    Spinner,
    Text,
} from "@accurx/design";
import { FormFieldWrapperProps } from "@accurx/design/dist/components/Forms/FormFieldWrapper/FormFieldWrapper";
import {
    SearchSelectMode,
    SearchSelectProps,
} from "@accurx/design/dist/components/Forms/Select/models";

import { SnomedSummaryCard } from "app/sharedComponents/SnomedPicker";

import {
    NO_SNOMED_CODE_OPTION,
    SNOMED_VALUE_DESCRIPTION,
} from "../../FloreyBuilder.utils";

type SnomedSelectFormFieldProps = {
    isLoading?: boolean;
    selectedOption?: Option;
    options: Option[];
    infoText?: string;
    onChange: (option: Option) => void;
    formFieldProps?: Partial<FormFieldWrapperProps>;
    selectProps?: Partial<SearchSelectProps>;
};

export const SnomedSelectFormField = ({
    isLoading,
    selectedOption = NO_SNOMED_CODE_OPTION,
    options,
    infoText,
    onChange,
    formFieldProps,
    selectProps,
}: SnomedSelectFormFieldProps) => {
    const shouldShowSummary =
        selectedOption.value !== NO_SNOMED_CODE_OPTION.value;
    const subLabel =
        isLoading || shouldShowSummary
            ? undefined
            : "Type the description or concept ID to search.";

    return (
        <Flex flexDirection="column" gap={shouldShowSummary ? "2" : "1"}>
            <FormFieldWrapper
                label="SNOMED code (optional)"
                subLabel={subLabel}
                {...formFieldProps}
            >
                {isLoading && (
                    <span id={"snomed-search"}>
                        <Spinner />
                    </span>
                )}
                {!isLoading && (
                    <>
                        {shouldShowSummary ? (
                            <SnomedSummaryCard
                                onRemove={() => {
                                    onChange(NO_SNOMED_CODE_OPTION);
                                }}
                                value={{
                                    term: selectedOption.label as string,
                                    conceptId: selectedOption.value,
                                }}
                            />
                        ) : (
                            <>
                                <SearchSelect
                                    zIndex={1}
                                    id={"snomed-search"}
                                    data-testid="snomed-code"
                                    options={options}
                                    onChangeHandler={(selected): void => {
                                        onChange(selected as Option);
                                    }}
                                    initSelectedValues={selectedOption}
                                    searchMode={SearchSelectMode.IncludeValue}
                                    shouldDisplayValues={true}
                                    valueDescription={SNOMED_VALUE_DESCRIPTION}
                                    {...selectProps}
                                />
                                <Text>
                                    If you can't find the code you're looking
                                    for,{" "}
                                    <Link
                                        text="let us know"
                                        openInNewTab
                                        href="https://forms.gle/G2YQVPpgSQHKpTCJ7"
                                    />
                                    .
                                </Text>
                            </>
                        )}
                    </>
                )}
            </FormFieldWrapper>
            {!!infoText && <Feedback colour="secondary" title={infoText} />}
        </Flex>
    );
};
