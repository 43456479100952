import {
    Conversation,
    ConversationItem,
    LabelTagItem,
} from "@accurx/concierge/types";
import findLast from "lodash/findLast";

const isLabelTagItem = (item: ConversationItem): item is LabelTagItem => {
    return item.contentType === "LabelTag";
};

export const getLastConversationLabelTagItem = (
    conversation: Conversation,
): LabelTagItem | null => {
    return findLast(conversation.items, isLabelTagItem) ?? null;
};
