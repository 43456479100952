import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledScheduledSendInfo = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1",
})`
    background: ${UI.Tokens.COLOURS.greyscale.cloud};

    padding: ${UI.Tokens.SIZES[1]};

    border-radius: ${UI.Tokens.SIZES[0.5]};
`;
