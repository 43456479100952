import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

const leaveWorkspace = ({
    workspaceId,
}: {
    workspaceId: string;
}): Promise<IWrappedResult<undefined>> =>
    httpClient.putJsonReturnSafeAsync(
        getApiEndpoint({
            path: "/api/portal/OrganisationSetup/:workspaceId/Leave",
            params: { workspaceId },
        }),
        {},
    );

export const useLeaveWorkspaceMutation = (
    options: UseMutationOptions<void, Error, { workspaceId: number }> = {},
) =>
    useMutation({
        mutationKey: ["LeaveWorkspace"],
        ...options,
        mutationFn: async ({ workspaceId }) => {
            const response = await leaveWorkspace({
                workspaceId: String(workspaceId),
            });
            if (!response.success) {
                throw new Error(response.error ?? "Failed to leave workspace");
            }
        },
    });
