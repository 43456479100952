import {
    VaccineDoseType,
    VaccineTypeFilters,
} from "./models/VaccineDeliveryDTO";
import { NimsVaccineProductType } from "./models/VaccineSharedDTO";

export const generateFiltersString = (
    vaccineTypeFilters: VaccineTypeFilters,
): string => {
    const filtersArray = generateFiltersArray(vaccineTypeFilters);
    return filtersArray.join(", ");
};

export const generateFiltersArray = (
    vaccineTypeFilters: VaccineTypeFilters,
): string[] => {
    const filtersArray = [];
    if (vaccineTypeFilters.astrazeneca) {
        filtersArray.push(NimsVaccineProductType.Az);
    }
    if (vaccineTypeFilters.janssen) {
        filtersArray.push(NimsVaccineProductType.Janssen);
    }
    if (vaccineTypeFilters.moderna) {
        filtersArray.push(NimsVaccineProductType.Moderna);
    }
    if (vaccineTypeFilters.pfizer) {
        filtersArray.push(NimsVaccineProductType.Pfizer);
    }
    return filtersArray;
};

// NHS related Regex
const REGEX_SPLIT_NHS = /\b(\d{3})([- ])(\d{3})\2(\d{4})\b/g; // standalone 000-000-0000 or 000 000 0000

/**
 * Remove spaces and dashes if we reconize an NHS number pattern or leave the string as it is
 * ie: "Nhs: 000-000-0000" => "Nhs: 0000000000"
 * @param pastedText
 * @returns
 */
export const sanitiseNhsNumber = (pastedText: string): string =>
    pastedText.trim().replace(REGEX_SPLIT_NHS, "$1$3$4");

export const getNbsBookingDoseText = (doseType: VaccineDoseType): string => {
    switch (doseType) {
        case "First":
            return "1st dose booked";
        case "Second":
            return "2nd dose booked";
        case "Booster":
            return "booster booked";
        default:
            return "appointment booked";
    }
};

export function getVaccinesSessionCsvDownloadUrl(
    practiceId: string,
    sessionId: string,
): string {
    return `/api/recall/practices/${practiceId}/recallsession/${sessionId}/csv`;
}

export function getVaccineInviteListCsvDownloadUrl(practiceId: string): string {
    return `/api/recall/practices/${practiceId}/recallinvites/globallist/csv`;
}
