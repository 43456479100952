import { Icon } from "@accurx/design";

import { AvatarContainer } from "./ConversationSummaryAvatar.styles";

export const ConversationSummaryAvatar = () => {
    return (
        <AvatarContainer alignItems="center" justifyContent="center">
            <Icon name="Carehome" theme="Line" size={2} />
        </AvatarContainer>
    );
};
