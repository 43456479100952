import React, { ReactNode } from "react";

import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledHeader = styled.header`
    padding: ${Tokens.SIZES[2]};
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
`;

export const Header = ({ children }: { children?: ReactNode }) => {
    return <StyledHeader>{children}</StyledHeader>;
};
