import { Complexity, FloreyScoreStatus } from "@accurx/api/ticket";

export const FILTER_FORM_ID = "questionnaires-table-filters";
export const FILTER_FORM_LABEL_ID = "questionnaires-table-filters-label";

export const FLOREY_STATUS_LABELS = {
    [FloreyScoreStatus.Expired]: "Expired",
    [FloreyScoreStatus.FailedToDeliver]: "Failed to deliver",
    [FloreyScoreStatus.Incomplete]: "Incomplete",
    [FloreyScoreStatus.Pending]: "Pending validation",
    [FloreyScoreStatus.Validated]: "Validated",
};

export const COMPLEXITY_LABELS = {
    [Complexity.Low]: "Low",
    [Complexity.MediumHigh]: "Medium/High",
    [Complexity.Calculating]: "Calculating",
    [Complexity.NotApplicable]: "N/A",
};
