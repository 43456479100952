import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { FlemingLoggedInCustomProperties } from "./FlemingAnalyticsConstants";

type ContactDropdownOpenAnalytics = FlemingLoggedInCustomProperties & {
    productOrigin: "PatientList" | "RecentSearch" | "Clinic";
    messagePracticeStatus?: string;
};

export function trackOnContactDropdownOpen(
    props: ContactDropdownOpenAnalytics,
): void {
    GenericTrackEvent({
        object: "PatientListContact",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            ...props,
            eventVersion: 2,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
        },
    });
}

export type SelectedItem =
    | "PatientMessage"
    | "ClinicianMessage"
    | "RecordView"
    | "VideoInvite"
    | "PatientProfile";

type ContactDropdownOptionAnalytics = FlemingLoggedInCustomProperties & {
    productOrigin: "PatientList" | "RecentSearch" | "Clinic";
    selectedItem: SelectedItem;
};

export function trackOnContactDropdownOptionClick(
    props: ContactDropdownOptionAnalytics,
): void {
    GenericTrackEvent({
        object: "PatientListContact",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: {
            ...props,
            eventVersion: 2,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
        },
    });
}
