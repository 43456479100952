import React, { useState } from "react";

import { Feedback, TabProps, Tabs, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { Log } from "@accurx/shared";
import keyBy from "lodash/keyBy";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { trackChangeTab } from "app/analytics/FlemingAnalytics";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_CHAIN } from "shared/Routes";
import {
    useCurrentOrgId,
    useCurrentOrgName,
    useIsUserApproved,
} from "store/hooks";

import { AccurxTemplatesPanel } from "./AccurxTemplatesPanel";
import {
    FEEDBACK_UNAPPROVED_USER_BODY_TEXT,
    FEEDBACK_UNAPPROVED_USER_TITLE_TEXT,
} from "./ManageTemplates.utils";
import { OrganisationFloreysPanel } from "./OrganisationFloreysPanel";
import { OrganisationTemplatesPanel } from "./OrganisationTemplatesPanel";
import { UserTemplatesPanel } from "./UserTemplatesPanel";

//These tabIds should be kept in sync with BuildManageTemplatesRoute and BuildManageAccuRxTemplatesRoute in AccuRxDotCom.cs in Shared
export const TabIds = {
    UserTemplates: "UserTemplates",
    OrganisationTemplates: "OrganisationTemplates",
    AccurxTemplates: "AccurxTemplates",
    OrganisationFloreys: "OrganisationFloreys",
} as const;

export type TabId = (typeof TabIds)[keyof typeof TabIds];

const ManageTemplatesTabPanel = ({ tabId }: { tabId: string }): JSX.Element => {
    useAccurxWebTitle("Manage message templates");
    const orgId = useCurrentOrgId();
    const orgName = useCurrentOrgName();

    if (orgId === null) {
        return (
            <Feedback
                colour="error"
                title="There was a problem loading this page"
                children="Please refresh the page."
            />
        );
    }
    switch (tabId) {
        case TabIds.UserTemplates:
            return (
                <UserTemplatesPanel
                    orgId={orgId?.toString() || ""}
                    orgName={orgName}
                />
            );
        case TabIds.OrganisationTemplates:
            return (
                <OrganisationTemplatesPanel
                    orgId={orgId?.toString() || ""}
                    orgName={orgName}
                />
            );
        case TabIds.AccurxTemplates:
            return <AccurxTemplatesPanel orgId={orgId?.toString() || ""} />;
        case TabIds.OrganisationFloreys:
            return <OrganisationFloreysPanel orgId={orgId?.toString() || ""} />;
        default:
            return <></>;
    }
};

export const ManageTemplatesOverview = (): JSX.Element => {
    const flemingLoggedInProperties = useFlemingLoggedInAnalytics();
    const orgName = useCurrentOrgName();
    const history = useHistory();
    const isUserApproved: boolean = useIsUserApproved();
    const searchParams = new URLSearchParams(history.location.search);
    const tab = searchParams.get("tab");
    const params = useParams<{ orgId: string }>();

    const tabs: TabProps<TabId>[] = [
        { tabId: TabIds.UserTemplates, text: "My Templates" },
        { tabId: TabIds.OrganisationTemplates, text: `${orgName} Templates` },
        { tabId: TabIds.AccurxTemplates, text: "Accurx Templates" },
        { tabId: TabIds.OrganisationFloreys, text: `${orgName} Floreys` },
    ];
    const TabLookup = keyBy(tabs, "tabId");
    const initialTab =
        TabLookup[tab || TabIds.UserTemplates] ||
        TabLookup[TabIds.UserTemplates];
    const [currentTabId, setCurrentTabId] = useState<TabId>(initialTab.tabId);
    const handleTabChange = (tabId: TabId) => {
        switch (tabId.toLowerCase()) {
            case TabIds.UserTemplates.toLowerCase():
            case TabIds.OrganisationTemplates.toLowerCase():
            case TabIds.AccurxTemplates.toLowerCase():
            case TabIds.OrganisationFloreys.toLowerCase():
                history.replace(
                    generatePath(
                        `${ROUTES_CHAIN.practicesManageTemplates}?tab=${tabId}`,
                        params,
                    ),
                );
                trackChangeTab({
                    ...flemingLoggedInProperties,
                    currentTab: currentTabId,
                    newTab: tabId,
                });
                return setCurrentTabId(tabId);
            default:
                Log.error(
                    `[ManageTemplatesOverview] - tab id ${tabId} is not on list`,
                );
                return setCurrentTabId(TabIds.UserTemplates);
        }
    };

    return (
        <div>
            <Breadcrumb title="Manage templates" />
            <Text skinny variant="title" as="h1">
                Manage templates
            </Text>
            {!isUserApproved && (
                <Feedback
                    colour="warning"
                    title={FEEDBACK_UNAPPROVED_USER_TITLE_TEXT}
                >
                    {FEEDBACK_UNAPPROVED_USER_BODY_TEXT}
                </Feedback>
            )}
            <Tabs
                selected={currentTabId}
                onTabChange={(tab) => handleTabChange(tab as TabId)}
            >
                <Tabs.TabList tabs={tabs} />
                {tabs.map(({ tabId }) => (
                    <Tabs.Panel key={tabId} tabId={tabId}>
                        <ManageTemplatesTabPanel tabId={tabId} />
                    </Tabs.Panel>
                ))}
            </Tabs>
        </div>
    );
};
