import { StyledLink, Tokens } from "@accurx/design";
import styled from "styled-components";

/** Just a simple unstyled link */
export const StyledBaseComponentLink = styled.a``;

export const StyledNhsButtonLink = styled.a`
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: ${Tokens.BORDERS.radius};
    color: ${Tokens.COLOURS.greyscale.white};
    background-color: ${Tokens.COLOURS.primary.blue[100]};
    box-shadow: rgb(20, 71, 121) 0px -4px 0px inset;
    display: block;
    width: 100%;
    text-align: center;
    &:hover {
        text-decoration: none;
        background-color: ${Tokens.COLOURS.primary.blue[130]};
    }
    cursor: pointer;
`;

export const StyledLinkLight = styled(StyledLink).attrs({
    $isDarkTheme: false,
})``;
