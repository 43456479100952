import * as UI from "@accurx/design";
import styled, { css } from "styled-components";

export const StyledContainer = styled.div`
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    padding: 7px;

    display: flex;
    flex-direction: row;

    // Depending on the features the user has and the type of conversation the
    // conversation actions may differ. In all cases though we want a single
    // action aligned to the left and the rest aligned to the right. We can
    // achieve this by giving the first child flex:1 so that it fills up the
    // available space.
    & > :first-child {
        flex: 1;
    }
`;

export const StyledInnerContainer = styled(UI.Item)`
    min-width: 0;
    margin-right: 28px;

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.md}) {
        margin-right: 0;
    }
`;

export const Item = styled.div<{ $collapsible?: boolean }>`
    padding: 5px;
    position: relative;

    ${({ $collapsible }) =>
        $collapsible
            ? css`
                  overflow: hidden;
              `
            : null}
`;
