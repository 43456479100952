import { useEffect } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import { useBackgroundUpdatePatientDemographicsMutation } from "@accurx/concierge/hooks/mutations/useBackgroundUpdatePatientDemographicsMutation";
import {
    NativeApi,
    NativeFetchUnexpectedError,
    useNativeTransport,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import {
    convertNativeErrorMessageToError,
    sanitizeAndLogError,
} from "domains/inbox/native/errors";
import { MaterialisedPatientDemographicsSchema } from "domains/inbox/schemas/MaterialisedPatientDemographics";

// Here subscription to the patient demographics updates (for any patient) is added;
// a Subscription event contains patientDemographics and a serverPatientId (internal patient id);
// After update is received a mutation that updates patient summary inside a concierge layer is called.
export const useNativePatientDemographicsBackgroundUpdates = () => {
    const transport = useNativeTransport();
    const workspace = useCurrentWorkspace();
    const workspaceId = workspace.orgId;

    const { mutate: updatePatientDemographics } =
        useBackgroundUpdatePatientDemographicsMutation();

    useEffect(() => {
        if (!transport) return;

        const unsubscribe = NativeApi.subscribe({
            transport,
            request: { type: "SubscribePatientDemographics", workspaceId },
            onEvent: (event) => {
                if (!event.success) {
                    sanitizeAndLogError(
                        convertNativeErrorMessageToError(event.error),
                        {
                            domain: "Native API Subscription",
                            subscription: "SubscribePatientDemographics",
                        },
                    );
                    return;
                }

                const parseResult =
                    MaterialisedPatientDemographicsSchema.safeParse(event.data);

                if (!parseResult.success) {
                    Log.error(
                        new NativeFetchUnexpectedError(
                            "ClientZodParseFailure",
                            `Invalid payload for ${event.type}`,
                            parseResult.error,
                        ),
                    );
                    return;
                }

                const { patientDemographics, serverPatientId } =
                    parseResult.data;

                // having patientDemographics and patient's internal ID, we call a concierge layer mutation
                updatePatientDemographics({
                    patientDemographics,
                    patientInternalId: serverPatientId,
                });
            },
        });

        return () => unsubscribe();
    }, [transport, workspaceId, updatePatientDemographics]);
};
