import { useUnreadCountSelector } from "domains/concierge/internal/hooks/useUnreadCountSelector";

export const useTeamUnreadCount = ({ teamId }: { teamId: string }): number => {
    return useUnreadCountSelector((state) => {
        const team = state.teams.items[teamId];

        return team?.isMember
            ? state.conversations.unreadCounts.ticket.teams[teamId] ?? 0
            : 0;
    });
};
