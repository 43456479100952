import { useEffect, useState } from "react";

import { conversationIdMapper } from "@accurx/concierge";
import { Log } from "@accurx/shared";
import { useTransport } from "@accurx/transport";
import uniq from "lodash/uniq";
import { v4 as uuid } from "uuid";

import { SocketEvents } from "shared/hubClient/HubClient";
import {
    NoteTypingReceive,
    TypingSendThrottleMs,
} from "shared/hubClient/payload.types";

type UserDisplayName = string;
type UserIsTypingEventId = string;

const mapUserIsTypingTicketIdToConversationId = (
    payload: NoteTypingReceive,
) => {
    if (!payload.ticketIdentity?.type || !payload.ticketIdentity.id) {
        Log.error(
            "Received an event for OnPatientThreadNoteTyping signalR without a valid ticketIdentity - ignoring the event",
            {
                tags: {
                    product: "Inbox",
                    itemType: payload.ticketIdentity?.type,
                    itemId: payload.ticketIdentity?.id,
                },
            },
        );

        return;
    }
    const processedTicketIdentity = {
        type: payload.ticketIdentity.type,
        id: payload.ticketIdentity.id,
    };
    return conversationIdMapper.ticket.fromSource(processedTicketIdentity);
};

export const useCurrentlyTypingUsers = (conversationId: string) => {
    const { transport } = useTransport();

    const [conversationUsersTyping, setConversationUsersTyping] = useState<
        string[]
    >([]);

    useEffect(() => {
        const userIsTypingEventTracker: Record<
            UserIsTypingEventId,
            UserDisplayName
        > = {};
        const eventTrackerClearingTimeouts: Array<
            ReturnType<typeof setTimeout>
        > = [];

        const subscription = transport?.subscribe({
            methodName: SocketEvents.OnPatientThreadNoteTyping,
            onEvent: (update) => {
                const notificationConversationId =
                    mapUserIsTypingTicketIdToConversationId(update.payload);

                if (notificationConversationId === conversationId) {
                    const userIsTypingEventId = uuid();
                    const userDisplayName =
                        update.payload.sender?.name ??
                        update.payload.sender?.email ??
                        "A user";
                    // Keep track every time we receive an event for a user typing on the conversation
                    userIsTypingEventTracker[userIsTypingEventId] =
                        userDisplayName;
                    // Set the list of unique users typing to display in UI
                    setConversationUsersTyping(
                        uniq(Object.values(userIsTypingEventTracker)),
                    );
                    // After 2.5s that the event comes in, make sure to clear
                    // it from the event tracker
                    eventTrackerClearingTimeouts.push(
                        setTimeout(() => {
                            delete userIsTypingEventTracker[
                                userIsTypingEventId
                            ];
                            const newUsersTypingArr = uniq(
                                Object.values(userIsTypingEventTracker),
                            );
                            setConversationUsersTyping(newUsersTypingArr);
                        }, TypingSendThrottleMs + 500),
                    );
                }
            },
        });

        // Clean up outstanding timeouts and subscription
        // when hook unmounts
        return () => {
            subscription?.unsubscribe();
            eventTrackerClearingTimeouts.forEach((timeout) =>
                clearTimeout(timeout),
            );
        };
    }, [transport, conversationId]);

    return conversationUsersTyping;
};
