import { Pill } from "@accurx/inbox-design-library";

type LoadMoreButtonProps = { onClick?: () => void; isLoading: boolean };

export const LoadMoreButton = ({ onClick, isLoading }: LoadMoreButtonProps) => {
    return (
        <Pill.Button radius="full" onClick={onClick} disabled={isLoading}>
            <Pill.Icon name="ArrowTail" colour="blue" isLoading={isLoading} />
            <Pill.Text>Load more conversations</Pill.Text>
        </Pill.Button>
    );
};
