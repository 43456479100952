/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import {
    SearchForPatientByDemographicsRequest,
    SearchForPatientByNhsNumberRequest,
    SearchForPatientResponse,
} from "@accurx/api/portal";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import {
    VaccineAddToNetworkRequest,
    VaccineInviteOperationResult,
    VaccinePatientAddToNetworkResponse,
    VaccinePatientInviteDetailsResponse,
    VaccinePatientInvitePostNotesResponse,
    VaccinePatientNotesRequest,
    VaccinePatientRefreshNimsResponse,
} from "app/practices/vaccine/models/VaccineAllPatientsInvitedDTO";
import { VaccineGetAppointmentsResponse } from "app/practices/vaccine/models/VaccineCancelDTO";
import { VaccineCapacityTypes } from "app/practices/vaccine/models/VaccineCapacityDashboardDTO";
import {
    PatientInviteSearchFilters,
    RecallCapacityRequest,
    RecallCapacityResponse,
} from "app/practices/vaccine/models/VaccineDeliveryDTO";
import {
    RecallBookingLink,
    RecallBoosterSmsRequest,
    RecallSecondInviteResponse,
    RecallSmsRequest,
} from "app/practices/vaccine/models/VaccineRecordingBatchesDTO";
import {
    RecallVaccineUrlParamsBase,
    SessionSummary,
    UpdateArrivalParams,
    VaccineCancelRequest,
    VaccineSessionRequest,
    VaccineSessionResponse,
} from "app/practices/vaccine/models/VaccineRecordingDTO";

const ENDPOINTS = {
    recallSession: "/api/recall/practices/:orgId/recallsession",
    recallSessionId: "/api/recall/practices/:orgId/recallsession/:sessionId",
    recallInvitesCancel:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/cancel/:inviteId",
    recallUpdateArrival:
        "/api/recall/practices/:orgId/recallslotbooking/updatearrival",
    recallSearchForPatient:
        "/api/recall/practices/:orgId/recallinvites/SearchForPatient",
    recallCanSendReminder:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/cansendinvitereminder/:inviteId",
    recallSendCustomisedInvite:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/sendcustomisedinvitereminder/:inviteId",
    recallBookingLink:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/bookinglink/:inviteId",
    recallUpdateToSecond:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/updatetosecond/:inviteId",
    recallInviteToBooster:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/inviteToBooster/:inviteId",
    recallBookings:
        "/api/recall/practices/:orgId/recallinvites/:batchId/Invites/:inviteId/Bookings",
    recallCheckCapacity:
        "/api/recall/practices/:orgId/recallinvites/CheckSendMessageCapacity",
    recallUpcomingCapacities:
        "/api/recall/practices/:orgId/RecallVaccineCapacity/UpcomingCapacitiesByType",
    recallInvitesId:
        "/api/recall/practices/:orgId/recallinvites/:vaccineDeliveryId",
    recallInvitesStatus:
        "/api/recall/practices/:orgId/recallinvites/:vaccineDeliveryId/status",
    recallInvitesIdCancel:
        "/api/recall/practices/:orgId/recallinvites/:vaccineDeliveryId/Cancel/:inviteId",
    recallInvitesReset:
        "/api/recall/practices/:orgId/recallinvites/:vaccineDeliveryId/Reset/:inviteId",
    recallInvitesUploadSingle:
        "/api/recall/practices/:orgId/recallinvites/UploadSingle",
    recallInvitesSend:
        "/api/recall/practices/:orgId/recallinvites/:vaccineDeliveryId/send",
    recallInvitesUpload: "/api/recall/practices/:orgId/recallinvites/upload",
    recallInvitesGlobalList:
        "/api/recall/practices/:orgId/recallinvites/GlobalList",
    recallInvitesGlobalListId:
        "/api/recall/practices/:orgId/recallinvites/GlobalList/:inviteId",
    recallInvitesAddNote:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/AddNote/:inviteId",
    recallInvitesRefreshNims:
        "/api/recall/practices/:orgId/recallinvites/:inviteBatchId/RefreshNims/:inviteId",
    recallInvitesAddToNetwork:
        "/api/recall/practices/:orgId/RecallInvites/AddToNetwork",
} as const;

export interface RecallBatchInviteRef {
    id: string;
}

class ChainApiClient {
    static async getVaccineSessions({
        orgId,
    }: RecallVaccineUrlParamsBase): Promise<IWrappedResult<SessionSummary[]>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallSession,
                params: { orgId },
            }),
        );
    }

    static async getVaccineSession({
        orgId,
        sessionId,
    }: VaccineSessionRequest): Promise<IWrappedResult<VaccineSessionResponse>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallSessionId,
                params: { orgId, sessionId },
            }),
        );
    }

    static async cancelBooking(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
        request: VaccineCancelRequest,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesCancel,
                params: { orgId, inviteBatchId, inviteId },
            }),
            request,
        );
    }

    static async updateArrival({
        orgId,
        request,
    }: UpdateArrivalParams): Promise<IWrappedResult<undefined>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallUpdateArrival,
                params: { orgId },
            }),
            request,
        );
    }

    static async recallSearchForPatient(
        orgId: string,
        searchForPatientRequest:
            | SearchForPatientByNhsNumberRequest
            | SearchForPatientByDemographicsRequest,
    ): Promise<{ success: boolean; result: SearchForPatientResponse }> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallSearchForPatient,
                params: { orgId },
            }),
            searchForPatientRequest,
        );
    }

    static async checkCanSendInviteReminder(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
    ): Promise<IWrappedResult<VaccineInviteOperationResult>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallCanSendReminder,
                params: { orgId, inviteBatchId, inviteId },
            }),
        );
    }

    static async sendInviteReminder(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
        request: RecallSmsRequest,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallSendCustomisedInvite,
                params: { orgId, inviteBatchId, inviteId },
            }),
            request,
        );
    }

    static async getClinicianBookingLink(
        orgId: string,
        inviteId: string,
        isDeclineFlow: boolean,
        inviteBatchId?: string,
    ): Promise<IWrappedResult<RecallBookingLink>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallBookingLink,
                params: {
                    orgId,
                    inviteBatchId: inviteBatchId?.toString() ?? "",
                    inviteId,
                },
            }),
            { isDeclineFlow },
        );
    }

    static async sendInviteToSecondBooking(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
        request: RecallSmsRequest,
    ): Promise<IWrappedResult<RecallSecondInviteResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallUpdateToSecond,
                params: { orgId, inviteBatchId, inviteId },
            }),
            request,
        );
    }

    static async sendInviteToBoosterBooking(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
        request: RecallBoosterSmsRequest,
    ): Promise<IWrappedResult<RecallSecondInviteResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInviteToBooster,
                params: { orgId, inviteBatchId, inviteId },
            }),
            request,
        );
    }

    static async getVaccineAppointment(
        orgId: string,
        batchId: string,
        inviteId: string,
    ): Promise<IWrappedResult<VaccineGetAppointmentsResponse>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallBookings,
                params: { orgId, batchId, inviteId },
            }),
        );
    }

    static async checkCapacity(
        orgId: string,
        request: RecallCapacityRequest,
    ): Promise<IWrappedResult<RecallCapacityResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallCheckCapacity,
                params: { orgId },
            }),
            request,
        );
    }

    static async getVaccineCapacityTypes(
        orgId: string,
    ): Promise<IWrappedResult<VaccineCapacityTypes>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallUpcomingCapacities,
                params: { orgId },
            }),
        );
    }

    static async getVaccineDeliveryDetails(
        orgId: string,
        vaccineDeliveryId: string,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesId,
                params: { orgId, vaccineDeliveryId },
            }),
        );
    }

    static async getVaccineUploadStatus(
        orgId: string,
        vaccineDeliveryId: string,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesStatus,
                params: { orgId, vaccineDeliveryId },
            }),
        );
    }

    static async postVaccineCancelBooking(
        orgId: string,
        vaccineDeliveryId: string,
        inviteId: string,
        request: any,
    ): Promise<IWrappedResult<undefined>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesIdCancel,
                params: { orgId, vaccineDeliveryId, inviteId },
            }),
            request,
        );
    }

    static async postVaccineResetBooking(
        orgId: string,
        vaccineDeliveryId: string,
        inviteId: string,
    ): Promise<IWrappedResult<undefined>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesReset,
                params: { orgId, vaccineDeliveryId, inviteId },
            }),
            {},
        );
    }

    static async postInviteSinglePatient(
        orgId: string,
        request: any,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesUploadSingle,
                params: { orgId },
            }),
            request,
        );
    }

    static async postSendMessage(
        orgId: string,
        vaccineDeliveryId: string,
        request: any,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesSend,
                params: { orgId, vaccineDeliveryId },
            }),
            request,
        );
    }

    static async postVaccineUploadFile(
        orgId: string,
        courseParam: string,
        formData: any,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesUpload,
                params: { orgId },
                query: `?course=${courseParam}`,
            }),
            formData,
        );
    }

    // region ALL INVITES PAGE
    static async getVaccineAllPatientsInvited(
        orgId: string,
        request: PatientInviteSearchFilters,
    ): Promise<IWrappedResult<any>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesGlobalList,
                params: { orgId },
            }),
            request,
        );
    }

    static async getPatientDetails(
        orgId: string,
        inviteId: string,
    ): Promise<IWrappedResult<VaccinePatientInviteDetailsResponse>> {
        return await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesGlobalListId,
                params: { orgId, inviteId },
            }),
        );
    }

    static async postVaccinePatientNotes(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
        request: VaccinePatientNotesRequest,
    ): Promise<IWrappedResult<VaccinePatientInvitePostNotesResponse>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesAddNote,
                params: { orgId, inviteBatchId, inviteId },
            }),
            request,
        );
    }

    static async postRefreshNimsData(
        orgId: string,
        inviteBatchId: string,
        inviteId: string,
    ): Promise<IWrappedResult<VaccinePatientRefreshNimsResponse>> {
        return await httpClient.postJsonReturnSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesRefreshNims,
                params: { orgId, inviteBatchId, inviteId },
            }),
            {},
        );
    }

    static async addPatientToNetwork(
        orgId: string,
        request: VaccineAddToNetworkRequest,
    ): Promise<IWrappedResult<VaccinePatientAddToNetworkResponse>> {
        return await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.recallInvitesAddToNetwork,
                params: { orgId },
            }),
            request,
        );
    }

    // endregion ALL INVITES PAGE
}

export default ChainApiClient;
