import { Log } from "@accurx/shared";
import isNil from "lodash/isNil";

import {
    PatientThreadSummaryDetails,
    UserGroupType,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    ConversationsTeamType,
    InitialSummary,
} from "shared/concierge/conversations/types/initialSummary.types";

/**
 * Maps the key entity information used for:
 * (a) what entities to be shown within the sidebar
 * (b) the set of valid assignees for tickets
 *
 * This is deliberately simplified vs how GP Desktop app currently does things as
 * the granularity of the specific folder views is not needed in Web due to the
 * client UI code wanting to be more explicit, but for now keeps control at the
 * server side.
 *
 * See the `InitialSummary` type for more details.
 *
 * @param summary the ticket API response containing summary information for the Inbox
 */
export const mapInitialSummary = (
    summary: PatientThreadSummaryDetails,
): InitialSummary => {
    const result: InitialSummary = {
        displayedColleagues: [],
        displayedTeams: [],
        activeAssignees: [],
    };

    const mappingErrors = new Array<string>();
    const referencedUserIds = new Set<string>();
    const referencedTeamIds = new Set<string>();
    const currentUserId = summary.currentUser?.accuRxId;
    summary.folders?.forEach((f) => {
        if (f.userId) {
            referencedUserIds.add(f.userId);
        }
        if (f.userGroupId) {
            referencedTeamIds.add(f.userGroupId);
        }
    });

    summary.activeUsers?.forEach((u, ix) => {
        if (isNil(u.accuRxId)) {
            mappingErrors.push(`User index ${ix}`);
            return;
        }

        result.activeAssignees.push({ type: "User", id: u.accuRxId });
        if (u.accuRxId !== currentUserId && referencedUserIds.has(u.accuRxId)) {
            result.displayedColleagues.push({ type: "User", id: u.accuRxId });
        }
    });

    summary.activeUserGroups?.forEach((ug, ix) => {
        if (isNil(ug.id) || isNil(ug.groupType)) {
            mappingErrors.push(`UserGroup index ${ix}`);
            return;
        }

        result.activeAssignees.push({ type: "Team", id: ug.id });
        if (referencedTeamIds.has(ug.id)) {
            result.displayedTeams.push({
                type: "Team",
                id: ug.id,
                teamType: mapUserGroupType(ug.groupType),
            });
        }
    });

    if (!currentUserId) {
        Log.warn(
            "Current user ID missing, will not be excluded from colleagues",
            {
                tags: {
                    product: "Foundational Inbox",
                    domain: "Initial summary mapping",
                },
            },
        );
    }

    if (
        result.displayedTeams.filter((t) => t.teamType === "ToAssign").length >
        1
    ) {
        Log.warn("Multiple active ToAssign teams provided", {
            tags: {
                product: "Foundational Inbox",
                domain: "Initial summary mapping",
            },
        });
    }

    if (mappingErrors.length > 0) {
        Log.warn("Missing information during summary mapping", {
            tags: {
                product: "Foundational Inbox",
                domain: "Initial summary mapping",
                count: mappingErrors.length,
                details: mappingErrors.join(", "),
            },
        });
    }

    return result;
};

// In the context of the inbox layout, we don't need to be more granular than this
const mapUserGroupType = (type: UserGroupType): ConversationsTeamType => {
    switch (type) {
        case UserGroupType.Florey:
            return "Florey";
        case UserGroupType.ToAssign:
            return "ToAssign";
        case UserGroupType.PracticeGroup:
            return "Hub";
        // If we introduce new team types, show them in the general section by default
        default:
            return "Default";
    }
};
