import { PatientThreadFilteredTicketView } from "@accurx/api/ticket";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { isInstance } from "domains/concierge/internal/util/isInstance";

import { mapPatientThreadUserToUserSummary } from "./mapPatientThreadUserToUserSummary";
import { mapTicketToConversation } from "./mapTicketToConversation";

export const mapPatientThreadFilteredTicketViewToConciergeUpdate = (
    dto: PatientThreadFilteredTicketView,
): ConciergeUpdates => {
    const conversations = (dto.tickets ?? [])
        .map(mapTicketToConversation)
        .filter(isInstance);

    const patients = (dto.referencedPatients ?? [])
        .map(mapPatientThreadPatientDetailsToPatientSummary)
        .filter(isInstance);

    const users = (dto.users ?? [])
        .map(mapPatientThreadUserToUserSummary)
        .filter(isInstance);

    const teams = (dto.userGroups ?? [])
        .map(mapPatientThreadUserGroupToTeamSummary)
        .filter(isInstance);

    return {
        conversations: conversations,
        patients,
        users,
        teams,
    };
};
