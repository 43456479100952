import React from "react";

import { Feedback, Text } from "@accurx/design";

export const ExcludedCodesWarning = (): JSX.Element => {
    return (
        <Feedback
            colour="information"
            content={
                <>
                    We do not return clinical codes related to sexually
                    transmitted infections, in-vitro fertilisation, gender
                    re-assignment and terminations of pregnancy in the medical
                    record.{" "}
                    <Text
                        as="a"
                        variant="link"
                        props={{
                            target: "_blank",
                            rel: "noopener noferrer",
                            href: "https://www.notion.so/accurx/Clinical-Code-Exclusion-List-48dccfe16ab545098dbb56f8a92b6bdc",
                        }}
                    >
                        See here for the full list of excluded codes
                    </Text>
                </>
            }
        />
    );
};
