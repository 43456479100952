import { Table, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTable = styled(Table)`
    table-layout: fixed;
`;

export const StyledTableHead = styled(Table.Head)`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
`;

export const StyledTruncatedTableData = styled.div`
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

export const StyledWrappedTableDataCell = styled(Table.DataCell)`
    overflow-wrap: break-word;
`;

export const ContactDetails = styled.span`
    max-width: 100%;
    overflow-wrap: break-word;
`;
