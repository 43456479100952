import { Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    MobileNumberHelper,
} from "@accurx/shared";

import { FlemingAnalyticsTracker } from "app/analytics";
import { WebPatient } from "app/patients/Patient.types";
import { useAppSelector } from "store/hooks";

import ConfirmMessageLayout from "../../sharedComponents/confirmMessageLayout/ConfirmMessageLayoutComponent";
import MessagePreview from "../../sharedComponents/messagePreview/MessagePreviewComponent";

const CopyButtonAnalyticsContext =
    FlemingAnalyticsTracker.CopyButtonAnalyticsContext;

export interface ConfirmMessageProps {
    patient: WebPatient;
    resetProductChoice: () => void;
}

const ConfirmMessage = ({
    patient,
    resetProductChoice,
}: ConfirmMessageProps) => {
    const messageInfoSent = useAppSelector(
        ({ messagePatient }) => messagePatient.messageInfoSent,
    );
    const lastSendMessageResponse = useAppSelector(
        ({ messagePatient }) => messagePatient.lastSendMessageResponse,
    );
    const userEmailString = useAppSelector(({ account }) =>
        account.user?.email ? ` (${account.user?.email})` : "",
    );
    const userName = useAppSelector(({ account }) => account.user?.fullName);

    return (
        <>
            <ConfirmMessageLayout
                title={`Your message to ${patient.firstName} has been successfully sent!`}
                handleGoBack={resetProductChoice}
            >
                <Text>{`Replies to this message will arrive in your email inbox${userEmailString}.`}</Text>
                {/* We render ConfirmMessage only if messageText is not null, so when this component renders, lastSendMessageResponse.result.messageText will be truthy */}
                {lastSendMessageResponse?.result?.messageText && (
                    <MessagePreview
                        messageCopy={{
                            sentTo: patient.displayName || "Unknown",
                            mobileNumber:
                                MobileNumberHelper.obfuscate(
                                    messageInfoSent.mobileNumber,
                                ) || undefined, // Message Patient only allows sms sending
                            messageText:
                                lastSendMessageResponse.result.messageText,
                            sentBy: userName,
                            dateTime: DateHelpers.formatTime(
                                DateHelpers.getCurrentTimeStamp(),
                                DateFormatOptions.TIME_DATE_SHORT,
                            ),
                        }}
                        copyButtonAnalyticsContext={
                            CopyButtonAnalyticsContext.sms
                        }
                    />
                )}
            </ConfirmMessageLayout>
        </>
    );
};

export default ConfirmMessage;
