import { Card, Feedback, StackPanel, Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { generatePath } from "react-router-dom";

import { ClinicianConversationSummary } from "api/ClinicianConversationApi";
import { LocationState } from "app/clinicianconversation/hooks/useClinicianConversation";
import { SkeletonLabel } from "app/sharedComponents/loadingSkeleton/SkeletonText";
import { ROUTES } from "shared/Routes";

import {
    StyledConversationList,
    StyledLink,
    StyledLinkPlaceholder,
    StyledMainMessage,
    StyledMainMessagePlaceholder,
} from "./PreviousClinicianConversationsCard.styles";
import { useRecentConversations } from "./useRecentConversations";

const formatItemDate = (isoDate: string): string =>
    DateHelpers.formatDate(
        isoDate,
        DateHelpers.isBeforeToday(isoDate) ? "DD MMM YYYY" : "hh:mm A",
    );

const ConversationRow = ({
    urlId,
    lastMessage: { body, senderDisplayName, dateSent },

    nextLocationState,
}: {
    urlId: string;
    lastMessage: ClinicianConversationSummary["lastMessage"];
    nextLocationState: LocationState;
}) => (
    <li>
        <StyledLink
            to={{
                pathname: generatePath(ROUTES.reply_from_web, {
                    urlId,
                }),
                state: nextLocationState,
            }}
        >
            <StyledMainMessage skinny>
                {senderDisplayName}: {body}
            </StyledMainMessage>
            <Text skinny>{formatItemDate(dateSent)}</Text>
        </StyledLink>
    </li>
);

const ConversationRowSkeleton = () => (
    <li data-testid="conversation-row-skeleton">
        <StyledLinkPlaceholder>
            <StyledMainMessagePlaceholder charCount={1} />
            <SkeletonLabel charCount={10} />
        </StyledLinkPlaceholder>
    </li>
);

export const PreviousClinicianConversationsCard = () => {
    const {
        canMessageGp,
        patientFirstName,
        summaries,
        hasError,
        nextLocationState,
    } = useRecentConversations();

    if (!canMessageGp) {
        return null;
    }

    const componentLoading = summaries === null && !hasError;
    const componentLoadedSucessfully = summaries !== null && !hasError;

    return (
        <Card isElevated spacing={2} variant="regular">
            <StackPanel gutter={1.5}>
                <Text variant="label" skinny>
                    {"Recent messages with " +
                        (patientFirstName
                            ? patientFirstName + "'s GP practice"
                            : "GP practice")}
                </Text>
                {componentLoading && (
                    <StyledConversationList role="presentation">
                        <ConversationRowSkeleton />
                    </StyledConversationList>
                )}
                {componentLoadedSucessfully &&
                    (summaries.length > 0 ? (
                        <StyledConversationList>
                            {summaries.map(({ urlId, lastMessage }) => (
                                <ConversationRow
                                    key={urlId}
                                    urlId={urlId}
                                    lastMessage={lastMessage}
                                    nextLocationState={nextLocationState}
                                />
                            ))}
                        </StyledConversationList>
                    ) : (
                        <Text variant="body" skinny>
                            No recent messages
                        </Text>
                    ))}
                {hasError && (
                    <Feedback
                        colour="error"
                        title="Unable to get conversations right now"
                    />
                )}
            </StackPanel>
        </Card>
    );
};
