import { LinkStyles } from "@accurx/design";
import { Link as ReactRouterDomLink } from "react-router-dom";
import styled from "styled-components";

/**
 * ReactRouterLink is the Link component from React Router wrapped in custom styling that matches our Link component
 * $isDarkTheme is a transient prop used to style the link accessibly depending on whether your link is on a light or a dark background
 * Use $isDarkTheme = true if your link will be displayed on a dark background (this will turn the text white and add appropriate focus/hover styles)
 * Also accepts any props accepted by react router's Link component
 */
export const ReactRouterLink = styled(ReactRouterDomLink)<{
    $isDarkTheme?: boolean;
}>`
    ${LinkStyles}
`;
