import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledNav = styled.nav`
    margin-bottom: ${Tokens.SIZES["4"]};
`;

export const StyledList = styled.ol`
    list-style: none;
    display: flex;
    padding: 0;
`;

export const StyledListItem = styled.li`
    &:not(:last-child):after {
        content: "/ ";
        margin-right: ${Tokens.SIZES["1"]};
        margin-left: ${Tokens.SIZES["1"]};
        color: ${Tokens.COLOURS.greyscale.stone};
    }
`;
