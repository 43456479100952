import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction } from "react";

import { Button, Feedback, Spinner, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useDispatch } from "react-redux";

import {
    InviteNote,
    InviteNoteType,
    VaccinePatientInvite,
} from "../models/VaccineAllPatientsInvitedDTO";
import { postPatientNotes } from "./VaccineAllPatientsInvited.actions";

export interface VaccineAllPatientsInvitedDetailsUserNotesProps {
    selectedPractice: string;
    inviteId: string;
    inviteBatchId: string;
    patientDetails: VaccinePatientInvite[];
    patientDetailsLoading: boolean;
    patientDetailsError: string;
    newNote: string;
    setNewNote: Dispatch<SetStateAction<string>>;
    patientNoteSaving: boolean;
    patientNoteError: string;
}

// This component renders notes on an invite that were manually added by a user
export const VaccineAllPatientsInvitedDetailsUserNotes = ({
    selectedPractice,
    inviteId,
    inviteBatchId,
    patientDetails,
    patientDetailsLoading,
    patientDetailsError,
    newNote,
    setNewNote,
    patientNoteSaving,
    patientNoteError,
}: VaccineAllPatientsInvitedDetailsUserNotesProps): JSX.Element => {
    const dispatch = useDispatch();

    const handleNewNoteChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        setNewNote(e.target.value);
    };

    const handleSaveNote = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        dispatch(
            postPatientNotes(
                {
                    organisationId: selectedPractice,
                    inviteBatchId,
                    inviteId,
                },
                { details: newNote, summary: undefined },
            ),
        );
    };

    // Flatten all invites to get notes from every invite, filter down to only user-added notes, then sort chronologically
    const allNotes: InviteNote[] = patientDetails
        .reduce(
            (acc: InviteNote[], curr: VaccinePatientInvite) =>
                acc.concat(curr.notes ?? []),
            [],
        )
        .filter((x: InviteNote) => x.type === InviteNoteType.UserNote)
        .sort((a: InviteNote, b: InviteNote) =>
            DateHelpers.isBefore(a.createdAt, b.createdAt) ? 1 : -1,
        );

    return (
        <>
            <div className="ml-2 w-100" data-testid="patient-notes">
                {patientDetailsLoading && <Spinner />}
                {!patientDetailsLoading &&
                    !patientDetailsError &&
                    !allNotes?.length && (
                        <Text variant="body">
                            No notes have been saved yet for this patient.
                        </Text>
                    )}
                {!patientDetailsLoading &&
                    !patientDetailsError &&
                    allNotes?.map((note: InviteNote, index: number) => {
                        if (!note) return null;
                        return (
                            <div key={index} className="mt-3">
                                <Text variant="note">
                                    {DateHelpers.formatDate(
                                        note.createdAt,
                                        DateFormatOptions.DATE_SHORT_WITH_SPACES,
                                    )}
                                    {note?.createdByUser && (
                                        <span>
                                            {" "}
                                            by {note.createdByUser.fullName}
                                        </span>
                                    )}
                                    {note?.createdByOrg && (
                                        <span>
                                            {" "}
                                            from{" "}
                                            {note.createdByOrg.nationalCode}
                                        </span>
                                    )}
                                </Text>
                                <Text
                                    variant="body"
                                    props={{ className: "ml-2" }}
                                >
                                    {note.details}
                                </Text>
                            </div>
                        );
                    })}
                <form onSubmit={handleSaveNote}>
                    {patientNoteError && (
                        <Feedback
                            colour="error"
                            title={patientNoteError}
                            content=""
                        />
                    )}
                    <textarea
                        id="new-note"
                        className="form-control"
                        style={{ resize: "vertical" }}
                        onChange={handleNewNoteChange}
                        value={newNote}
                        placeholder="Add a new note here"
                    />
                    <Button
                        theme="secondary"
                        type="submit"
                        className="mt-1"
                        text="Save Note"
                        disabled={
                            newNote.length < 5 ||
                            newNote.length > 200 ||
                            patientNoteSaving
                        }
                    />
                </form>
            </div>
        </>
    );
};
