import { createContext, useContext } from "react";

import { AnyAction } from "@reduxjs/toolkit";
import {
    ReactReduxContextValue,
    createDispatchHook,
    createSelectorHook,
} from "react-redux";

import { ConciergeMeta } from "./types/ConciergeMeta";
import { ConciergeState } from "./types/ConciergeState";

/**
 * A custom context for our redux store, to keep it separate from the main app's store.
 * We set its default value to null despite the type issue as it will be overridden by the Provider;
 * no default value is needed.
 */
export const ConciergeContext = createContext<
    ReactReduxContextValue<ConciergeState, AnyAction>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
>(null as any);

export const useConciergeDispatch = createDispatchHook(ConciergeContext);

export const useConciergeSelector = createSelectorHook(ConciergeContext);

export const useConciergeGetState = () => {
    const context = useContext(ConciergeContext);
    return () => context.store.getState();
};

/**
 * A custom context to keep metadata - the current workspace and userId.
 * This is provided to reducers as a meta field on the action. We also
 * need it elsewhere in the concierge layer, which is what this context is
 * for. Due to the way redux toolkit works it's easier to store it in its
 * own context rather than inject it into the redux store. A bit awkward,
 * but doesn't seem too bad.
 */
const ConciergeMetaContext = createContext<ConciergeMeta>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
    null as any,
);

export const ConciergeMetaProvider = ConciergeMetaContext.Provider;

export const useConciergeMeta = () => useContext(ConciergeMetaContext);
