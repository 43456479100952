/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
} from "@accurx/shared";

const trackManageOrgEvent = <T extends Record<string, any>>(
    obj: string,
    objType: EventObjectType,
    action: EventAction,
    props: T,
) => {
    GenericTrackEvent({
        object: obj,
        objectType: objType,
        action,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

export type HomepagePageViewProps = LoggedInCustomProperties & {
    withPatientTriage: boolean;
    withCustomFloreys: boolean;
    withAppointmentReminders: boolean;
};
export const trackHomepagePageView = (props: HomepagePageViewProps) => {
    trackManageOrgEvent(
        "Homepage",
        EventObjectType.Page,
        EventAction.View,
        props,
    );
};

export type HomepagePatientTriageSettingButtonClickProps =
    LoggedInCustomProperties;
export const trackHomepagePatientTriageSettingButtonClick = (
    props: HomepagePatientTriageSettingButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepagePatientTriageSetting",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageOrganisationSettingButtonClickProps =
    LoggedInCustomProperties & { homepageSection: string };
export const trackHomepageOrganisationSettingButtonClick = (
    props: HomepageOrganisationSettingButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepageOrganisationSetting",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageAppointmentReminderCreateStartButtonClickProps =
    LoggedInCustomProperties & { pageOrigin: string };
export const trackHomepageAppointmentReminderCreateStartButtonClick = (
    props: HomepageAppointmentReminderCreateStartButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepageAppointmentReminderCreateStart",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type AppointmentReminderCreateConfirmButtonClickProps =
    LoggedInCustomProperties & { pageOrigin: string };
export const trackAppointmentReminderCreateConfirmButtonClick = (
    props: AppointmentReminderCreateConfirmButtonClickProps,
) => {
    trackManageOrgEvent(
        "AppointmentReminderCreateConfirm",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageBatchStartButtonClickProps = LoggedInCustomProperties;
export const trackHomepageBatchStartButtonClick = (
    props: HomepageBatchStartButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepageBatchStart",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageVaccineStartButtonClickProps = LoggedInCustomProperties;
export const trackHomepageVaccineStartButtonClick = (
    props: HomepageVaccineStartButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepageVaccineStart",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageItemListItemClickProps = LoggedInCustomProperties & {
    homepageSection: string;
    homepageItem: string;
};
export const trackHomepageItemListItemClick = (
    props: HomepageItemListItemClickProps,
) => {
    trackManageOrgEvent(
        "HomepageItem",
        EventObjectType.ListItem,
        EventAction.Click,
        props,
    );
};

export type HomepageDiscoverButtonClickProps = LoggedInCustomProperties & {
    homepageSection: string;
    homepageItem: string;
};
export const trackHomepageDiscoverButtonClick = (
    props: HomepageDiscoverButtonClickProps,
) => {
    trackManageOrgEvent(
        "HomepageDiscover",
        EventObjectType.Button,
        EventAction.Click,
        props,
    );
};

export type HomepageDiscoverResourceLinkClickProps =
    LoggedInCustomProperties & {
        homepageSection: string;
        homepageItem: string;
    };
export const trackHomepageDiscoverResourceLinkClick = (
    props: HomepageDiscoverResourceLinkClickProps,
) => {
    trackManageOrgEvent(
        "HomepageDiscoverResource",
        EventObjectType.Link,
        EventAction.Click,
        props,
    );
};
