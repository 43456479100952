import { FloreyPatientAttachment, FloreyResponse } from "@accurx/api/ticket";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import {
    Attachment,
    PatientResponse,
    PatientResponseSection,
} from "domains/concierge/types";

function mapAttachment(
    attachment: FloreyPatientAttachment,
): Attachment | undefined {
    if (!attachment.attachmentId) {
        return;
    }

    return {
        id: attachment.attachmentId,
        event: attachment.event
            ? {
                  eventTime: attachment.event.eventTime,
                  userId: attachment.event.userId ?? null,
                  isSoftDelete: !!attachment.event.isSoftDelete,
                  description: attachment.event.description ?? null,
              }
            : undefined,
    };
}

function mapPatientResponse(
    response: FloreyResponse,
): PatientResponse | undefined {
    const attachments =
        response.answer?.floreyPatientAttachments
            ?.map(mapAttachment)
            .filter(isInstance) ?? [];

    // A valid respose must contain at least a question or attachments or an answer
    if (
        !response.question &&
        !response.answer?.displayAnswer &&
        attachments.length === 0
    ) {
        return;
    }

    return {
        // as per DTO the default is bold, expect it to be explicitly false
        displayQuestionBold: response.displayQuestionBold ?? true,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        question: response.question || null,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        answer: response.answer?.displayAnswer || null,
        attachments,
    };
}

type PatientResponseSectionResult = {
    sections: PatientResponseSection[];
    failedToMapCount: number;
};

export function mapPatientResponseSections(
    responses: FloreyResponse[],
): PatientResponseSectionResult {
    let failedToMapCount = 0;
    const sections: PatientResponseSection[] = [];

    responses.forEach((resp) => {
        const displaySection = resp.displaySection ?? 0;
        if (sections[displaySection] === undefined) {
            sections[displaySection] = { responses: [] };
        }
        const mapped = mapPatientResponse(resp);
        if (!mapped) {
            failedToMapCount++;
        } else {
            sections[displaySection].responses.push(mapped);
        }
    });

    // There could be gaps in source data
    // (i.e. displaySection 0, 1, 4, etc)
    // so drop any empty sections
    return {
        sections: sections.filter((grp) => grp !== undefined),
        failedToMapCount,
    };
}
