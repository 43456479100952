import { useState } from "react";

import { Embed, Report, models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useLocation, useParams } from "react-router";

import { useGetPowerBiReportQuery } from "app/hooks/queries/useGetPowerBIReportQuery";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { StyledContainer, StyledError } from "./PowerBIReporting.styles";
import { TabLookup, trackPowerBIReportPageView } from "./PowerBIReportingUtils";

export const PowerBIReporting = (): JSX.Element => {
    const location = useLocation<{ navigationOrigin?: string }>();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // PowerBI Report object
    const [, setReport] = useState<Report>();

    const params = useParams<{ orgId: string; tab: string }>();

    const powerBIReport = useGetPowerBiReportQuery({
        organisationId: params.orgId,
    });

    //getting navigationOrigin and currentTab for analytics
    const urlState = location?.state;
    const navigationOrigin = urlState?.navigationOrigin;
    const currentTab = TabLookup[params?.tab || "questionnaire"];

    if (powerBIReport?.status === "error")
        return (
            <StyledError>
                Oops! We're sorry something went wrong. Please refresh the page,
                if the issue persists contact our support team.
            </StyledError>
        );

    // see https://github.com/microsoft/powerbi-client-react/blob/master/React/demo/src/DemoApp.tsx
    const reportConfig = {
        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: powerBIReport?.data?.id,
        embedUrl: powerBIReport?.data?.embedUrl,
        accessToken: powerBIReport?.data?.token,
        tokenType: models.TokenType.Embed,
        viewMode: models.ViewMode.View,
        pageName: powerBIReport?.data?.pages[currentTab.text],
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToPage,
            },
            panes: {
                filters: {
                    expanded: false,
                    visible: false,
                },
                pageNavigation: {
                    visible: false,
                },
            },
            background: models.BackgroundType.Transparent,
        },
    };

    return (
        <StyledContainer>
            <PowerBIEmbed
                embedConfig={reportConfig}
                eventHandlers={
                    new Map([
                        [
                            "rendered",
                            function () {
                                trackPowerBIReportPageView({
                                    ...analyticsLoggedInProps,
                                    currentTab: currentTab.text,
                                    navigationOrigin: navigationOrigin ?? "App",
                                });
                            },
                        ],
                    ])
                }
                cssClassName="powerBiEmbeddedContainer"
                getEmbeddedComponent={(embeddedObject: Embed) => {
                    setReport(embeddedObject as Report);
                }}
            />
        </StyledContainer>
    );
};
