import React, { useEffect, useRef } from "react";

import { LoggedInOrOutUser, useAuth } from "@accurx/auth";
import { Ds } from "@accurx/design";
import { useDispatch } from "react-redux";

import patientTokenService from "shared/storage/PatientTokenService";
import { useAppSelector } from "store/hooks";

import { StyledSpinnerWrapper } from "./SpinnerWrapper.styles";
import {
    ACCOUNT_LOGGED_IN,
    ACCOUNT_LOGGED_OUT,
} from "./account/AccountActions";

/** This is a temporary higher order component that replicates the logic in the checkIfLoggedIn
 * account action. This is done so we can use the api/account/isloggedin response from the
 * AuthProvider and migrate the checkIfLoggedIn action away from Redux more easily.
 */
export function withLoginCheck(
    Component: React.ComponentType<React.PropsWithChildren<unknown>>,
) {
    return function WrapperComponent() {
        const dispatch = useDispatch();
        const reduxIsLoggedIn = useAppSelector(
            ({ account }) => account.isLoggedIn,
        );
        const { user } = useAuth();
        const currentUserRef = useRef<LoggedInOrOutUser>(user);

        useEffect(() => {
            if (currentUserRef.current.isLoggedIn) {
                dispatch({
                    type: ACCOUNT_LOGGED_IN,
                    user: currentUserRef.current,
                    is2FAed: currentUserRef.current.is2FAed,
                });
            } else {
                if (patientTokenService !== null) {
                    patientTokenService.clearCurrentPatientToken();
                }

                dispatch({
                    type: ACCOUNT_LOGGED_OUT,
                });
            }
        }, [dispatch]);

        // Wait to render the component until we have a known login status in redux
        if (reduxIsLoggedIn === null) {
            return (
                <StyledSpinnerWrapper>
                    <Ds.Spinner />
                </StyledSpinnerWrapper>
            );
        }

        return <Component />;
    };
}
