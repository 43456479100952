import { Tooltip, TooltipProps } from "reactstrap";
import styled from "styled-components";

export const StyledTooltip = styled(Tooltip)<
    TooltipProps & { trigger?: string }
>`
    & .tooltip-inner {
        background-color: white;
        padding: 16px;
        box-shadow: 0px 4px 16px rgba(68, 72, 126, 0.32);
        text-align: left;
    }

    & .tooltip.show {
        opacity: 1 !important;
    }
`;

export const LinkButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: default;
    outline: none;
`;
