import * as UI from "@accurx/design";
import styled from "styled-components";

const { BORDERS, COLOURS, SIZES } = UI.Tokens;

export const StyledSnomedCodeInfoList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${SIZES[1]};
    border-radius: ${SIZES[0.5]};
    padding: ${SIZES[1]} ${SIZES[1.5]};
    margin: 0;

    background: ${COLOURS.primary.blue[10]};
    border: ${BORDERS.soft.blue};
`;

export const StyledSnomedCodeInfoListItem = styled.li`
    display: flex;
    align-items: center;
    gap: ${SIZES[1]};

    svg {
        min-width: 20px;
        height: 20px;
    }
`;
