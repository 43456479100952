import { Icon, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

const OptionText = styled(Text).attrs({
    skinny: true,
    variant: "label",
})<{ $disabled?: boolean }>`
    white-space: nowrap;
    display: block;

    // Override reboot css font styling
    ${Tokens.TYPOGRAPHY.label.base}

    ${({ $disabled = false }) =>
        $disabled && `color: ${Tokens.COLOURS.greyscale.stone}`}
`;

const OptionIcon = styled(Icon).attrs({
    size: 3,
})`
    flex-shrink: 0;
`;

const UnavailableMessage = styled(Text).attrs({
    skinny: true,
    as: "span",
})`
    // Override PopoverContent styling
    margin-left: 0;

    // Override reboot css font styling
    ${Tokens.TYPOGRAPHY.body.base}
`;

export const StyledContactOption = {
    OptionText,
    OptionIcon,
    UnavailableMessage,
};
