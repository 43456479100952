import React from "react";

import { Avatar, Card, Ds, StackPanel, Text } from "@accurx/design";

import { OutstandingInvite } from "api/WorkspaceDtos";

import {
    StyledUserDetails,
    StyledUserManagementCardContainer,
    StyledUserManagementCardContent,
    StyledUserNameAndEmail,
} from "./UserManagementPage.styles";

type InvitesManagementCardProps = {
    outstandingInvite: OutstandingInvite;
};

export const OutstandingInviteListCard = ({
    outstandingInvite,
}: InvitesManagementCardProps): JSX.Element => {
    return (
        <StyledUserManagementCardContainer>
            <Card spacing={2}>
                <StackPanel
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    gutter={3}
                >
                    <Avatar colour="grey" size="extraLarge" />
                    <StyledUserManagementCardContent>
                        <StyledUserDetails>
                            <StyledUserNameAndEmail>
                                <Text variant="label" skinny>
                                    {outstandingInvite.email}
                                </Text>
                            </StyledUserNameAndEmail>
                        </StyledUserDetails>
                        <Ds.Badge color="greyscale">Not yet accepted</Ds.Badge>
                    </StyledUserManagementCardContent>
                </StackPanel>
            </Card>
        </StyledUserManagementCardContainer>
    );
};
