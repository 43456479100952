import { GlobalConversationManagerProvider } from "shared/concierge/conversations/context/GlobalConversationManagerContext";
import { GlobalPatientManagerProvider } from "shared/concierge/patients/context/GlobalPatientManagerContext";
import { GlobalUsersAndTeamsManagerProvider } from "shared/concierge/usersAndTeams/context/GlobalUsersAndTeamsManagerContext";

import { SelectedGlobalOrganisationConversationManagerProvider } from "./workspaceConversations/context/SelectedGlobalOrganisationConversationManagerContext";

type TrustWebInboxProvidersProps = {
    is2FAed: boolean;
    workspaceId: number | null;
    currentUserId: string | undefined;
};

/* Root level providers for our app
 */
export const TrustWebInboxProviders = ({
    is2FAed,
    workspaceId,
    currentUserId,
    children,
}: React.PropsWithChildren<TrustWebInboxProvidersProps>) => {
    return (
        <GlobalUsersAndTeamsManagerProvider currentUserId={currentUserId}>
            <GlobalPatientManagerProvider currentUserId={currentUserId}>
                <GlobalConversationManagerProvider
                    currentUserId={currentUserId}
                >
                    <SelectedGlobalOrganisationConversationManagerProvider
                        is2FAed={is2FAed}
                        workspaceId={workspaceId}
                    >
                        {children}
                    </SelectedGlobalOrganisationConversationManagerProvider>
                </GlobalConversationManagerProvider>
            </GlobalPatientManagerProvider>
        </GlobalUsersAndTeamsManagerProvider>
    );
};
