import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    margin: 0 auto;
    max-width: ${Tokens.BREAKPOINTS.xl};
    padding: 0 ${Tokens.SIZES[3]};
    div.powerBiEmbeddedContainer {
        height: 100vh;
    }
`;

export const StyledText = styled(Text)`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledError = styled(Text)`
    color: red;
    margin: ${Tokens.SIZES[1]} auto;
    max-width: ${Tokens.BREAKPOINTS.xl};
    padding: 0 ${Tokens.SIZES[3]};
`;
