import { PatientThreadItemUpdate } from "@accurx/api/ticket";
import { Conversation } from "domains/concierge/types";

import { mapTicketToConversation } from "./mapTicketToConversation";

/**
 * Maps from an external ticket item update DTO to an internal
 * domain view "Conversation". the conversation returned is the
 * conversation result after the update has been applied.
 *
 * Note: This function will update the data in the entity store
 *
 * @param update
 * @returns A conversation
 */
export function mapTicketItemUpdateToConversation(
    update: PatientThreadItemUpdate,
): Conversation | undefined {
    return mapTicketToConversation({
        ticketSummary: update.ticketSummary,
        patient: update.patient,
        isFullyLoaded: false,
        items: [update],
    });
}
