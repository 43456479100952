import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { TabId } from "app/practices/manageTemplates";

import { ProductOrigin } from ".";
import { FlemingLoggedInCustomProperties } from "../FlemingAnalyticsConstants";

type CountTemplateCategories = {
    countTemplateCategories: number;
};
type CurrentTab = {
    currentTab: TabId;
};

type NewTab = {
    newTab: string;
};

type TemplateLevel =
    | {
          templateLevel: string;
          productOrigin: "PatientMessage";
      }
    | {
          templateLevel: "User" | "Organisation";
          productOrigin: "ClinicianMessage";
      };

type TemplateId = {
    templateId: string;
};

type ChangeAvailability = {
    availabilityType: string;
    oldValue: boolean;
    newValue: boolean;
};

type CurrentTable = {
    currentTable: string;
};
type FilterName = {
    filterName: string;
};
type OptionSelected = {
    optionSelected: string;
};

export type ManageTemplatePageViewProps = FlemingLoggedInCustomProperties &
    CountTemplateCategories &
    CurrentTab &
    ProductOrigin;
export type TemplateCreateProps = FlemingLoggedInCustomProperties &
    TemplateLevel &
    ProductOrigin;
export type TemplateCopyProps = FlemingLoggedInCustomProperties &
    TemplateId &
    ProductOrigin;
export type TemplateActionProps = TemplateCreateProps &
    TemplateId &
    ProductOrigin;
export type ChangeAvailabilityProps = Omit<
    TemplateActionProps,
    "productOrigin"
> &
    ChangeAvailability;
export type ChangeTabProps = FlemingLoggedInCustomProperties &
    NewTab &
    CurrentTab;
export type FilterProps = FlemingLoggedInCustomProperties & CurrentTable;
export type FilterSelectProps = FilterProps & FilterName & OptionSelected;
export type FilterClickProps = FilterProps & FilterName;

export const trackManageTemplatePageView = (
    props: ManageTemplatePageViewProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateManage",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackCreateTemplatePageView = (
    props: TemplateCreateProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateCreate",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackEditTemplatePageView = (props: TemplateActionProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateEdit",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackDeleteTemplatePageView = (
    props: TemplateActionProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateDelete",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackCopyToOrgTemplatePageView = (
    props: TemplateCopyProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 3,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateCopy",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: fullProps,
    });
};

export const trackChangeAvailability = (
    props: ChangeAvailabilityProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateAvailability",
        objectType: EventObjectType.Option,
        action: EventAction.Select,
        properties: fullProps,
    });
};

export const trackChangeTab = (props: ChangeTabProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 2,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateManage",
        objectType: EventObjectType.Tab,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackCategoriesFilterClear = (props: FilterProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateManageFilterClear",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackCategoriesFilterSelect = (props: FilterSelectProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateManageFilter",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackCategoriesFilterClick = (props: FilterClickProps): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "TemplateManageFilter",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};
