import { PatientInfo } from "@accurx/api/clinician-messaging";
import { mapDateOfBirthToPatientSummaryAgeParts } from "domains/concierge/internal/api/shared/mappers/patients/mapDateOfBirthToPatientSummaryAgeParts";
import { PatientSummary } from "domains/concierge/types";

export const mapPatientInfoToPatientSummary = (
    dto: PatientInfo,
): PatientSummary => ({
    ...mapDateOfBirthToPatientSummaryAgeParts(dto.dateOfBirth),
    prefixName: dto.prefixName ?? null,
    familyName: dto.lastName ?? null,
    firstName: dto.firstName ?? null,
    nickname: null,
    gender: dto.gender ?? "Unknown",
    patientId: dto.patientId,
    externalIds: dto.nhsNumber
        ? [{ type: "NhsNumber", value: dto.nhsNumber }]
        : [],
    /* We don't need the demographics' last refresh date for now, this is only 
       used in desktop, where this PatientInfo is not currently used */
    demographicsLastRefreshed: null,
});
