import { ButtonLink, Spinner, Text } from "@accurx/design";
import { useParams } from "react-router";

import { useGetQuestionnaireQuery } from "app/hooks/queries";

export const FloreyBuilderExportAsJson = () => {
    const { orgId } = useParams<{ orgId: string }>();
    const { questionnaireId } = useParams<{ questionnaireId: string }>();

    const { data, status } = useGetQuestionnaireQuery({
        workspaceId: parseInt(orgId, 10),
        questionnaireId,
    });

    const blob = new Blob([JSON.stringify(data)], { type: "text/json" });
    const downloadUrl = window.URL.createObjectURL(blob);

    return (
        <>
            {status === "loading" && <Spinner />}
            {status === "success" && (
                <ButtonLink
                    href={downloadUrl}
                    download={`${data.name}.json`}
                    text="Click to download"
                />
            )}
            {status === "error" && <Text>Failed to load questionnaire</Text>}
        </>
    );
};
