import * as UI from "@accurx/design";

import { BodyInfoBox } from "../BodyInfoBox";
import { PATIENT_RESPONSE_TEXT } from "./PatientResponseEnabledInfo.constants";

export const PatientResponseEnabledInfo = () => {
    return (
        <BodyInfoBox.Wrapper>
            <BodyInfoBox.Header gap="1">
                <UI.Flex gap={"1"}>
                    <BodyInfoBox.Icon name="Reply" />
                    <BodyInfoBox.Text colour="night">
                        {`${PATIENT_RESPONSE_TEXT}[link will appear here]`}
                    </BodyInfoBox.Text>
                </UI.Flex>
            </BodyInfoBox.Header>
            <BodyInfoBox.Footer>
                <BodyInfoBox.Text colour="metal">
                    This link will be available for 7 days from the day it's
                    sent.
                </BodyInfoBox.Text>
            </BodyInfoBox.Footer>
        </BodyInfoBox.Wrapper>
    );
};
