import { useCallback } from "react";

import { Option } from "@accurx/design";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import {
    transformToClinicianOptions,
    transformToOrganisationOptions,
    transformToSlotTypesOptions,
} from "../utils/SelfbookFormComponentUtils";
import {
    ClinicianValue,
    CliniciansForSlotType,
    KeyValueClinician,
    KeyValueDTO,
    OrganisationOption,
} from "../utils/types";

export const GET_SELFBOOK_AVAILABILITY_KEY = "SelfbookAvailability";
export const GET_APPOINTMENT_AVAILABILITY_KEY = "AppointmentAvailability";

export type ClinicianDTO = {
    telephone: KeyValueClinician;
    faceToFace: KeyValueClinician;
};

export interface SelfbookAvailabilityResponse {
    weeksAvailable: number;
    defaultOrgSlotTypesAvailability: {
        telephone: KeyValueDTO;
        faceToFace: KeyValueDTO;
    };
    crossOrgAvailableOdsAndNames: KeyValueDTO;
    slotTypesWithAvailabilityCount: KeyValueDTO;
    defaultOrgCliniciansAvailability?: ClinicianDTO;
}

export type SlotTypeAvailabilityDto = {
    clinicianGroups?: ClinicianValue[];
    appointmentCount: number;
    slotTypeName: string;
};

export interface AppointmentAvailabilityResponse {
    appointmentCount: number;
    slotTypeAvailabilities: SlotTypeAvailabilityDto[];
    weeksAvailable: number;
    crossOrgAvailableOdsAndNames: KeyValueDTO;
}

export interface SelfbookAvailabilityParsedResponse {
    slotTypeOptions: Option[];
    organisationOptions: OrganisationOption[];
    clinicianListForSlotTypes: CliniciansForSlotType[];
    weeksAvailability: number;
}

export interface AppointmentAvailabilityParsedResponse {
    slotTypeOptions: Option[];
    organisationOptions: OrganisationOption[];
    clinicianListForSlotTypes: CliniciansForSlotType[];
    weeksAvailability: number;
}

const appointmentAvailabilityDataTransformer = (
    orgId: number,
    organisationName: string,
    response: AppointmentAvailabilityResponse,
): AppointmentAvailabilityParsedResponse => {
    const slotTypes: { [key: string]: string } = {};

    response.slotTypeAvailabilities.forEach((item) => {
        slotTypes[item.slotTypeName] = `${item.appointmentCount}`;
    });

    const slotTypeOptions: Option[] = transformToSlotTypesOptions(slotTypes);

    const organisationOptions: OrganisationOption[] =
        transformToOrganisationOptions(
            orgId,
            organisationName,
            response.crossOrgAvailableOdsAndNames,
        );

    const clinicianListForSlotTypes: CliniciansForSlotType[] =
        transformToClinicianOptions(response.slotTypeAvailabilities);

    const weeksAvailability = response.weeksAvailable;

    return {
        slotTypeOptions,
        organisationOptions,
        clinicianListForSlotTypes,
        weeksAvailability,
    };
};

export const useAppointmentAvailabilityQuery = (
    orgId: number,
    organisationName: string,
): UseQueryResult<AppointmentAvailabilityParsedResponse, Error> =>
    useQuery({
        queryKey: [GET_APPOINTMENT_AVAILABILITY_KEY, orgId],
        queryFn: async () =>
            await httpClient
                .getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "/api/appointments/SelfBook/AppointmentAvailability",
                        query: `?organisationId=${orgId}`,
                    }),
                )
                .then(
                    (response) =>
                        returnDataOrThrow(
                            response,
                        ) as AppointmentAvailabilityResponse,
                ),
        refetchOnMount: true,
        // memoise to not run on every render
        select: useCallback(
            (data: AppointmentAvailabilityResponse) =>
                appointmentAvailabilityDataTransformer(
                    orgId,
                    organisationName,
                    data,
                ),
            [orgId, organisationName],
        ),
    });
