import { Ds } from "@accurx/design";
import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledBadge = styled(Ds.Badge)`
    min-width: 45px;
`;

export const StyledTooltip = styled.div`
    .currently-viewing-tooltip {
        max-height: 65px;
        width: 100%;
        overflow: hidden;
    }

    [data-radix-popper-content-wrapper] {
        // This is set by default as an inline style to be 'max-content'. This is
        // problematic for when we need text to wrap based on the parent width, rather
        // then a hard-coded max-width value.
        min-width: unset !important;
        max-width: calc(100% - ${UI.Tokens.SIZES[6]});
    }
`;

export const StyledTooltipText = styled(UI.Text)`
    text-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
