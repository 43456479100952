import { mergeConversationItems } from "domains/concierge/internal/util/mergeConversationItems";
import { mergeConversationParticipants } from "domains/concierge/internal/util/mergeConversationParticipants";
import { Conversation } from "domains/concierge/schemas/ConversationSchema";

/**
 * Merges together the existing version of a conversation (if we have one) with
 * an update of that same conversation. There are a few important things to
 * note:
 *
 * Merging conversation items
 * --------------------------
 * Even if we have a conversation in state we don't always have its complete
 * list of items. When you fetch conversations as part of a group, for example,
 * each conversation received only contains the item that we need to display as
 * a preview in the list. Equally, a SignalR update to the read state of an item
 * will only contain the individual item that changed.
 *
 * To deal with the fact that both our existing conversation and the update may
 * have an incomplete list of items, we always merge together the items from
 * both. Then, as the existing conversation and the update may both contain the
 * same item, deduplicate them by ID.
 *
 *
 * Stale updates
 * -------------
 * If the update is stale that means it's older than the current version of the
 * conversation we already have. This is rare but can happen if we receive
 * out-of-order updates.
 *
 * If we have a stale update, we keep the existing version of the conversation
 * in tact. However we still may need to merge in any previously unseen
 * conversation items. When deduplicating the conversation items, we normally
 * keep the version of the item that appears in the update. But when the update
 * is stale, we keep the version of the item that we already have.
 *
 * The reason this is important is that the "readBy" state of an item may have
 * changed. As an example: the user has just read a conversation item and the
 * item is marked as read in our state. Then we receive a stale update from
 * before the item was read. We don't want to merge in the unread version of the
 * item as that would show the conversation as being unread when it isn't.
 *
 *
 * isFullyLoaded
 * -------------
 * The isFullyLoaded flag on a conversation represents if we have all of its
 * items in state. When we want to view a full conversation and it's fully
 * loaded we know we don't need to fetch the full conversation again.
 *
 * If the update contains a fully loaded conversation then we know we now have a
 * fully loaded conversation in state so isFullyLoaded is true.
 *
 * If the existing conversation is already fully loaded and we receive an
 * update, even if the update does not contain the full conversation, we know
 * that we have the full conversation in state and so isFullyLoaded stays true.
 *
 * The only way isFullyLoaded can be false after merging is if we don't have a
 * full conversation in state, and the update is also not a full conversation.
 *
 * Note that, when SignalR disconnects, we switch this flag to false for all
 * conversations to force a refresh next time we want to display the full
 * conversation. Even if we previously had a full conversation, when SignalR
 * disconnects, we know that we may have missed some updates and therefor want
 * to make sure we refetch.
 *
 */
export const mergeConversation = (
    existing: Conversation | undefined,
    update: Conversation,
): Conversation => {
    // Check if the update is stale
    const isUpdateStale =
        !!existing && existing.latestToken > update.latestToken;

    // Keep the most up-to-date version of the conversation
    const conversation = isUpdateStale ? existing : update;

    // Merge conversation items together but make sure that, for any duplicates,
    // we keep the item that belongs to the most up-to-date version of the
    // conversation.
    const items = isUpdateStale
        ? mergeConversationItems(update.items, existing.items)
        : mergeConversationItems(existing?.items, update.items);

    // Merge conversation participants together since we can receive updates
    // that have an empty `participants` field i.e from the summaries of
    // clinician conversations, the participants aren't received.
    // This makes sure that we do not lose the information on that even if an
    // update without participants comes after an update without participants.
    //  Similar to items above, make sure that, for any duplicates,
    // we keep the participant that belongs to the most up-to-date version of the
    // conversation.
    const participants = isUpdateStale
        ? mergeConversationParticipants(
              update.participants,
              existing.participants,
          )
        : mergeConversationParticipants(
              existing?.participants,
              update.participants,
          );

    // Set isFullyLoaded to true if, between the existing conversation and the
    // update, we have a full list of conversation items.
    const isFullyLoaded = !!existing?.isFullyLoaded || update.isFullyLoaded;

    return {
        ...conversation,
        isFullyLoaded,
        items,
        participants,
    };
};
