import { OrganisationSetupIndexViewModel } from "@accurx/api/portal";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const WORKSPACE_MANAGEMENT_USERS_CACHE_KEY = "WorkspaceManagementUsers";

const fetchWorkspaceData = (
    workspaceId: string,
): Promise<IWrappedResult<OrganisationSetupIndexViewModel>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/OrganisationSetup/:workspaceId",
            params: { workspaceId },
        }),
    );

export const useWorkspaceUsersQuery = (
    { workspaceId }: { workspaceId: number },
    options: UseQueryOptions<OrganisationSetupIndexViewModel, Error> = {},
) =>
    useQuery<
        OrganisationSetupIndexViewModel,
        Error,
        OrganisationSetupIndexViewModel
    >(
        [WORKSPACE_MANAGEMENT_USERS_CACHE_KEY, { workspaceId }],
        async () => {
            const response = await fetchWorkspaceData(workspaceId.toString());
            return returnDataOrThrow(response);
        },
        options,
    );
