import {
    ConversationFilter,
    ConversationGroupFilters,
    ConversationItemfilter,
} from "domains/concierge/internal/types/ConversationGroupFilters";
import { Conversation, ConversationItem } from "domains/concierge/types";
import every from "lodash/every";
import isArray from "lodash/isArray";
import some from "lodash/some";

const itemSatisfiesFilter = (
    item: ConversationItem,
    filter: ConversationItemfilter,
): boolean => {
    switch (filter.type) {
        case "Sender":
            return (
                item.createdBy.type === "User" &&
                item.createdBy.id === filter.value.id
            );

        case "DeliveryStatus":
            return (
                "deliveryStatus" in item &&
                !!filter.value.includes(item.deliveryStatus)
            );

        case "ItemType":
            return !!filter.value.find((x) => x === item.contentType);

        case "PatientTriageRequestType":
            return (
                item.contentType === "PatientTriageRequestNote" &&
                item.requestType === filter.value
            );
    }
};

const conversationSatisfiesFilter = (
    conversation: Conversation,
    filter: ConversationFilter,
): boolean => {
    switch (filter.type) {
        case "Source":
            return filter.value === conversation.source.system;
        case "Status":
            if (isArray(filter.value)) {
                return filter.value.includes(conversation.status);
            } else {
                return filter.value === conversation.status;
            }

        case "AssignedTo":
            if (
                conversation.assignee.type === "User" &&
                filter.value.type === "User"
            ) {
                return (
                    filter.value.id === null ||
                    conversation.assignee.id === filter.value.id
                );
            } else if (
                conversation.assignee.type === "Team" &&
                filter.value.type === "Team"
            ) {
                return (
                    filter.value.id === null ||
                    conversation.assignee.id === filter.value.id
                );
            } else {
                return false;
            }

        case "StartsWithItem": {
            const item = conversation.items[0];
            if (!item) return false;

            return every(filter.value.filters, (_filter) =>
                itemSatisfiesFilter(item, _filter),
            );
        }

        case "ContainsItem":
            return some(conversation.items, (item) =>
                every(filter.value.filters, (_filter) =>
                    itemSatisfiesFilter(item, _filter),
                ),
            );
        case "Patient":
            return (
                conversation.regardingPatientId ===
                filter.value.patientInternalId
            );
    }
};

export const isConversationInGroup = (
    conversation: Conversation,
    filters: ConversationGroupFilters,
): boolean => {
    return every(filters, (filter) =>
        conversationSatisfiesFilter(conversation, filter),
    );
};
