import React, {
    ButtonHTMLAttributes,
    ReactNode,
    createRef,
    useCallback,
    useState,
} from "react";

import { Button, ButtonProps, Ds, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared/dist";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

import { IFileUploadRequest } from "api/FlemingDtos";
import { useAppSelector } from "store/hooks";

import { FileUploadRow } from "./FileUploadRow";

export type FileUploadOwnProps = {
    maxFiles: number;
    maxSize: number;
    showNewBadge?: boolean;
    accept: string;
    noDrag: boolean;
    organisationId: number | null;
    onUploadFile: (request: IFileUploadRequest) => void;
    onDialogOpen?: () => void;
    onFileRemove?: (fileId: string) => void;
    uploadButtonProps?: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;
    displayUploadedFiles?: boolean;
    displayErrorsAsPopover?: boolean;
    className?: string;
    isLoading?: boolean;
};
type FileRejection = {
    errors: [{ message: string; code: string }];
    file: File;
};

const FileUpload = ({
    maxFiles,
    maxSize,
    showNewBadge,
    accept,
    noDrag,
    organisationId,
    onUploadFile,
    onDialogOpen,
    onFileRemove,
    uploadButtonProps,
    displayUploadedFiles = true,
    displayErrorsAsPopover = false,
    className,
    isLoading,
}: FileUploadOwnProps): JSX.Element => {
    const files = useAppSelector(({ fileUpload }) => fileUpload.files);
    const storeErrors = useAppSelector(({ fileUpload }) => fileUpload.errors);
    const isUploading = useAppSelector(
        ({ fileUpload }) => fileUpload.isUploading,
    );

    const [errors, setErrors] = useState<string[] | ReactNode[]>([]);

    const onDrop = useCallback(
        (acceptedFiles, fileRejections: FileRejection[]) => {
            // Reset errors - use a set to keep them unique
            const localErrors = [];
            if (acceptedFiles.length > 0) {
                // If we have any accepted files, we process them
                // Check we haven't reached the maximum file allowance

                const allFiles = files.concat(acceptedFiles);
                if (allFiles.length > maxFiles) {
                    const fileDesc = maxFiles === 1 ? "file" : "files";
                    localErrors.push(
                        `You cannot upload more than ${maxFiles} ${fileDesc}`,
                    );
                } else {
                    acceptedFiles.forEach((file: File) =>
                        onUploadFile({ file, organisationId }),
                    );
                }
            }

            if (fileRejections.length > 0) {
                fileRejections.map((x: FileRejection) =>
                    x.errors.forEach((e: { message: string; code: string }) => {
                        if (e.code === "file-too-large") {
                            localErrors.push(
                                <>
                                    The maximum attachment size allowed is{" "}
                                    {maxSize}MB. Our support article{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        href={SupportUrls.ReduceAttachmentSize}
                                    >
                                        here
                                    </a>{" "}
                                    can help with re-sizing a file.
                                </>,
                            );
                        } else if (e.code === "file-invalid-type") {
                            localErrors.push(
                                `You can only attach these types of files: ${accept}`,
                            );
                        } else {
                            localErrors.push(e.message);
                        }
                    }),
                );
            }

            setErrors(localErrors);
        },
        [files, accept, maxSize, maxFiles, onUploadFile, organisationId],
    );

    const dropzoneRef = createRef<DropzoneRef>();
    const openDialog = (): void => {
        // Note that the ref is set async, so it might be null at some point
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
            onDialogOpen && onDialogOpen();
        }
    };

    const removeFile = useCallback(
        (fileId: string) => {
            onFileRemove && onFileRemove(fileId);
        },
        [onFileRemove],
    );

    const fileDisplay =
        files.length > 0 ? (
            <div className="pt-1">
                <div>
                    {files.map((fileWithId) => (
                        <FileUploadRow
                            uploadedFile={fileWithId}
                            removeFile={removeFile}
                            key={fileWithId.id}
                        />
                    ))}
                </div>
            </div>
        ) : null;

    const errorDisplay = (
        allErrors: string[] | ReactNode[],
    ): JSX.Element | null => {
        const definedErrors = allErrors.filter((e) => e !== undefined);
        if (definedErrors.length > 0) {
            return (
                <>
                    {definedErrors.map((e, index) => (
                        <li key={index} className="text-danger">
                            {e}
                        </li>
                    ))}
                </>
            );
        }
        return null;
    };

    const renderErrorsLayout = (): JSX.Element | null => {
        if (!!errors?.length || !!storeErrors?.length) {
            const allErrors = [...(errors || []), ...(storeErrors || [])];

            if (displayErrorsAsPopover) {
                return (
                    <Popover target="ErrorPopover" isOpen={!!allErrors.length}>
                        <PopoverHeader tag="div">
                            <Text variant="label" props={{ className: "mt-0" }}>
                                File not uploaded
                            </Text>
                        </PopoverHeader>
                        <PopoverBody>
                            <ul className="pl-3 mb-0">
                                {errorDisplay(allErrors)}
                            </ul>
                        </PopoverBody>
                    </Popover>
                );
            }

            return (
                <div className="p-1 m-1">
                    <ul>{errorDisplay(allErrors)}</ul>
                </div>
            );
        }

        return null;
    };

    return (
        <Dropzone
            onDrop={onDrop}
            maxSize={maxSize * 1024 * 1024}
            multiple={maxFiles > 1}
            accept={accept}
            noClick={noDrag}
            noDrag={noDrag}
            ref={dropzoneRef}
            noKeyboard={noDrag}
        >
            {({ getRootProps, getInputProps }) => (
                <div className={className}>
                    <div {...getRootProps()}>
                        <label htmlFor="inputFile" className="sr-only">
                            Input file
                        </label>
                        <input id="inputFile" {...getInputProps()} />
                        {files.length < maxFiles && (
                            <div
                                className="d-sm-flex align-items-center"
                                id="ErrorPopover"
                            >
                                {!uploadButtonProps && (
                                    <>
                                        <Button
                                            text={
                                                isUploading || isLoading
                                                    ? "Please wait..."
                                                    : "Attach file"
                                            }
                                            type="button"
                                            onClick={openDialog}
                                            disabled={isUploading || isLoading}
                                            theme="secondary"
                                            icon={{ name: "Paperclip" }}
                                            data-testid="attachfile"
                                        />
                                        {showNewBadge && (
                                            <Ds.Badge className="ml-2">
                                                NEW
                                            </Ds.Badge>
                                        )}
                                    </>
                                )}
                                {uploadButtonProps && (
                                    <Button
                                        {...uploadButtonProps}
                                        type="button"
                                        onClick={openDialog}
                                    />
                                )}
                                {!noDrag && (
                                    <span className="d-block mt-2 mt-sm-0 ml-sm-2">
                                        or drag and drop files here
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {renderErrorsLayout()}
                    {displayUploadedFiles && files?.length > 0 && (
                        <div>{fileDisplay}</div>
                    )}
                </div>
            )}
        </Dropzone>
    );
};

export default FileUpload;
