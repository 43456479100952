import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    FLOREY_RESPONSE_SCORE_SUMMARIES_CACHE_KEY,
    FloreyResponseScoreSummariesQueryVariables,
} from "app/hooks/queries/useFloreyResponseScoreSummariesQuery";
import { postFloreyScoreResponse } from "shared/concierge/conversations/tickets/TicketApiClient";
import {
    FloreyScoreResponse,
    FloreyScoresSummaryResponse,
} from "shared/concierge/conversations/tickets/types/dto.types";

import { FLOREY_SCORE_RESPONSE_CACHE_KEY } from "./useGetFloreyScoreResponseQuery";

type ParamsForCacheUpdates = Pick<
    FloreyResponseScoreSummariesQueryVariables,
    "questionnaireId" | "filters"
>;

type SubmitClinicianStatusParams = {
    floreyEnrolmentId: number;
    score: string;
    additionalInformation?: string;
    workspaceId: string;
} & ParamsForCacheUpdates;

export const useSubmitClinicianStatusMutation = createMutationHook<
    SubmitClinicianStatusParams,
    FloreyScoreResponse
>(({ queryClient }) => ({
    mutationKey: ["submit-florey-score"],
    mutationFn: async ({
        floreyEnrolmentId,
        score,
        additionalInformation,
        workspaceId,
    }) => {
        const response = await postFloreyScoreResponse({
            workspaceId,
            floreyEnrolmentId,
            score,
            additionalInformation,
        });

        return returnDataOrThrow(response);
    },
    onSuccess: (data, variables) => {
        // Update the cache of the single florey data
        queryClient.setQueryData<FloreyScoreResponse>(
            [
                FLOREY_SCORE_RESPONSE_CACHE_KEY,
                {
                    workspaceId: variables.workspaceId,
                    floreyEnrolmentId: variables.floreyEnrolmentId,
                },
            ],
            // We receive the entire response and score back, so replace everything with the updated data.
            () => data,
        );

        // Update the cache of the summary florey data
        queryClient.setQueryData(
            [
                FLOREY_RESPONSE_SCORE_SUMMARIES_CACHE_KEY,
                {
                    workspaceId: variables.workspaceId,
                    questionnaireId: variables.questionnaireId,
                    ...variables.filters,
                },
            ],
            (summariesCache: FloreyScoresSummaryResponse | undefined) => {
                if (summariesCache) {
                    return {
                        ...summariesCache,
                        floreyScoreSummaries:
                            summariesCache.floreyScoreSummaries.map((summary) =>
                                summary.floreyEnrolmentId ===
                                variables.floreyEnrolmentId
                                    ? data
                                    : summary,
                            ),
                    };
                }

                return summariesCache;
            },
        );
    },
}));
