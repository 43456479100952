import { ReactNode } from "react";

import { useBrowserEnvironment } from "domains/native/hooks/useBrowserEnvironment";
import { WebView2Transport } from "domains/native/transports/WebView2Transport/WebView2Transport";

import { NativeTransportContext } from "./context";

export const NativeTransportProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const env = useBrowserEnvironment();
    const transport = env === "WebView" ? WebView2Transport : null;

    return (
        <NativeTransportContext.Provider value={transport}>
            {children}
        </NativeTransportContext.Provider>
    );
};
