import { Table, Tokens } from "@accurx/design";
import styled, { css } from "styled-components";

const { SIZES } = Tokens;

export const StyledContainer = styled.div`
    margin-top: ${SIZES[2]};
    margin-bottom: ${SIZES[4]};
`;

export const StyledTableWrapper = styled.div`
    overflow-x: auto;
    margin-top: ${SIZES[3]};
    margin-bottom: ${SIZES[3]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        margin-left: -${SIZES[3]};
        margin-right: -${SIZES[3]};
    }
`;

const SharedCellStyles = css`
    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
`;

export const StyledTableHeader = styled(Table.Header)`
    ${SharedCellStyles}
    position: relative;
`;

export const StyledTableBody = styled(Table.Body)`
    position: relative;
`;

export const StyledTableDataCell = styled(Table.DataCell)`
    ${SharedCellStyles}
`;

export const StyledEmptyTableDataCell = styled(Table.DataCell)<{
    $padding: number;
}>`
    ${SharedCellStyles}
    padding: calc(${({ $padding }) => $padding} * ${SIZES[3]});

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        display: none;
    }
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${SIZES[1]};
    flex-wrap: wrap;
`;

export const NoReceptionDataWrapper = styled.div`
    text-align: center;
    position: absolute;
    // Specific values used to absolutely position the edges of the
    // modal against the edges of the rendered graph (excluding legends and axes).
    top: 0;
    left: 40%;
    width: 60%;
    height: 100%;
    // Using custom hex here as design library sets alpha values to 0
    // where I need an alpha value of 20 here.
    background-color: #191c4d20;

    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        display: none;
    }
`;

export const NoReceptionDataCard = styled.div`
    width: 60%;
    margin: 0 auto;
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]};
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    top: 50%;
    transform: translateY(-50%);
`;
