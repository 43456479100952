import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    height: 100%;
    overflow: auto;
`;

export const StyledHeader = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    padding: ${Tokens.SIZES[2]};
`;

export const StyledBody = styled.div`
    border-top: ${Tokens.BORDERS.normal.silver};
    padding: ${Tokens.SIZES[2]};
`;

export const StyledMember = styled(Flex)`
    align-items: center;
    background-color: ${Tokens.COLOURS.greyscale.white};
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    gap: ${Tokens.SIZES[2]};
    padding: ${Tokens.SIZES[1.5]};
`;

export const StyledLegend = styled.legend`
    margin-bottom: ${Tokens.SIZES[2]};
`;
