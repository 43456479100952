import { Text } from "@accurx/design";
import styled from "styled-components";

export const StyledPatientPillContainer = styled.div`
    display: inline-block;
    margin-right: 8px;
`;

export const StyledPatientPillInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledPatientPillAvatarContainer = styled.div`
    margin-right: 4px;
`;

export const StyledText = styled(Text)`
    margin: 0;
`;
