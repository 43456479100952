/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { UpdatingStatus } from "../../../shared/LoadingStatus";
import { Actions } from "../../batchMessage/gp/BatchMessage.actions";
import { Actions as PracticesAction } from "../Practices.actions";
import { ListBeastSeriesDto } from "./Beast.types";

export type BeastReducerState = {
    fetchingBeastSeriesStatus: UpdatingStatus;
    beastSeries: ListBeastSeriesDto | null;
};

export const initialState: BeastReducerState = {
    fetchingBeastSeriesStatus: UpdatingStatus.Initial,
    beastSeries: null,
};

export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.FETCH_BEAST_SERIES:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_BEAST_SERIES_FAILURE:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_BEAST_SERIES_SUCCESS:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Loaded,
                beastSeries: action.response,
            };
        case PracticesAction.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        default:
            return state;
    }
};
