import React, { ReactNode } from "react";

import { Icon } from "@accurx/design";
import * as Collapsible from "@radix-ui/react-collapsible";

import {
    StyledCollapsibleContent,
    StyledCollapsibleMenuButton,
    StyledCollapsibleTitle,
} from "./CollapsibleMenu.styles";

type RenderBadgeCollapsibleMenuArgs = {
    open: boolean;
};

type BaseCollapsibleMenuProps = {
    /** Whether the menu is open or closed */
    open: boolean;
    /** Handler to change the status of the menu */
    onOpenChange: (open: boolean) => void;

    /** Unique id of the menu */
    id: string;

    /** Title of the collapsible menu - it will be used as the trigger text */
    title: string;
    /**
     * Ideally <Ds.Badge /> or <Icon /> component
     * that will display next to the title.
     */
    renderBadge?: (args: RenderBadgeCollapsibleMenuArgs) => JSX.Element;

    children: ReactNode;
};

export type CollapsibleMenuProps = BaseCollapsibleMenuProps;

export const CollapsibleMenu = ({
    open,
    onOpenChange,
    title,
    renderBadge,
    children,
    id,
}: CollapsibleMenuProps): JSX.Element => {
    return (
        <Collapsible.Root open={open} onOpenChange={onOpenChange}>
            <Collapsible.Trigger asChild>
                <StyledCollapsibleMenuButton>
                    <Icon
                        name="Arrow"
                        size={3}
                        rotation={open ? "down" : "right"}
                    />
                    <StyledCollapsibleTitle
                        forwardedAs="h3"
                        skinny
                        props={{ id }}
                    >
                        {title}
                    </StyledCollapsibleTitle>
                    {renderBadge && renderBadge({ open })}
                </StyledCollapsibleMenuButton>
            </Collapsible.Trigger>
            <Collapsible.Content>
                <StyledCollapsibleContent>{children}</StyledCollapsibleContent>
            </Collapsible.Content>
        </Collapsible.Root>
    );
};
