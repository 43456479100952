import { ReactNode, useState } from "react";

import { useParams } from "react-router";

import { ClinicianConversationApi } from "api/ClinicianConversationApi";
import { useClinicianConversationByWorkspaceConversationIdQuery } from "app/hooks/queries";
import { useCurrentUserEmailAddress } from "store/hooks";

import { ClinicianConversationContext } from ".";
import {
    buildSubjectFromPatient,
    getPatientDetailsWithDisplayNames,
} from "../clinicianConversation.helper";
import { getParticipantsFromConversation } from "../participantsPanel/ParticipantsPanel.mappers";
import { ClinicianConversationContextType } from "./types";

export const FromWorkspaceConversationId = ({
    children,
}: {
    children: ReactNode;
}) => {
    const params = useParams<{
        workspaceConversationId: string;
        workspaceId: string;
    }>();

    const workspaceConversationId = parseInt(params.workspaceConversationId);
    const workspaceId = parseInt(params.workspaceId);
    const userEmailAddress = useCurrentUserEmailAddress();
    const [queryEnabled, setQueryEnabled] = useState(true);

    const {
        data: conversation,
        isLoading,
        isError,
    } = useClinicianConversationByWorkspaceConversationIdQuery(
        {
            workspaceConversationId: params.workspaceConversationId,
            workspaceId: params.workspaceId,
        },
        { refetchInterval: 10000, enabled: queryEnabled },
    );

    if (Number.isNaN(workspaceConversationId) || Number.isNaN(workspaceId)) {
        throw new Error(
            "WorkspaceConversationId or WorkspaceId is not a number",
        );
    }

    if (!userEmailAddress) {
        throw new Error(
            "Cannot use ClinicianConversationProvider.FromWorkspaceConversationId without having an userEmailAddress in the state",
        );
    }

    if (isError && queryEnabled) {
        // Prevent polling in error state
        setQueryEnabled(false);
    }

    const getContext: () => ClinicianConversationContextType = () => {
        if (isLoading) {
            return {
                state: "loading",
                urlId: null,
                userEmailAddress,
                isNewConversation: false,
            };
        }

        if (isError) {
            return {
                state: "error",
                urlId: null,
                error: "Failed to fetch conversation via unique link",
                userEmailAddress,
                isNewConversation: false,
            };
        }

        const patientDetailsWithDisplayNames = conversation.patient
            ? getPatientDetailsWithDisplayNames(conversation.patient)
            : null;

        return {
            state: "fetched",
            error: null,
            messages: conversation.messages,
            conversationId: conversation.id,
            patient: patientDetailsWithDisplayNames,
            sendMessage: async ({ messageBody, attachedFiles, metadata }) =>
                await ClinicianConversationApi.sendMessage({
                    organisationId: workspaceId,
                    conversationId: conversation.id,
                    textContent: messageBody,
                    externalEmailAttachmentIds: attachedFiles.map(
                        ({ id }) => id,
                    ),
                    metadata: {
                        ...metadata,
                        templateLevel: metadata.isPresetTemplate
                            ? "Accurx"
                            : null,
                    },
                }),
            getAttachmentUrl: (attachmentId, attachmentName) => {
                setQueryEnabled(false);
                const result =
                    ClinicianConversationApi.getDownloadUrlByWorkspaceConversationId(
                        {
                            workspaceConversationId: workspaceConversationId,
                            workspaceId: workspaceId,
                            attachmentId,
                            attachmentName,
                        },
                    );
                setQueryEnabled(true);
                return result;
            },
            onSendFn: () => {
                // noop
            },
            urlId: null,
            participants: getParticipantsFromConversation({
                individualParticipants: conversation.individualParticipants,
                participantWorkspaces: conversation.participantWorkspaces,
                userEmailAddress,
            }),
            userEmailAddress,
            subject: patientDetailsWithDisplayNames
                ? buildSubjectFromPatient(patientDetailsWithDisplayNames)
                : conversation.subject,
            workspaceId,
            hasPolled: conversation.hasPolled,
            isNewConversation: false,
        };
    };

    return (
        <ClinicianConversationContext.Provider value={getContext()}>
            {children}
        </ClinicianConversationContext.Provider>
    );
};
