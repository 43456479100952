import { Dispatch } from "redux";

import { getDeliveryReceipts } from "api/PracticesApi";
import { CoreActions } from "shared/constants/actionTypes";

export function getInitialData(practiceId: number) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.LOAD_DELIVERY_RECEIPTS });

        const res = await getDeliveryReceipts(practiceId);
        if (res.success && res.result) {
            const data = res.result;
            dispatch({
                type: CoreActions.LOAD_DELIVERY_RECEIPTS_SUCCESS,
                receipts: data.receiptList,
                receiptHeaderType: data.headerType,
                isRestrictedToOwnReceipts: data.isRestrictedToOwnReceipts,
            });
            return;
        }

        dispatch({ type: CoreActions.LOAD_DELIVERY_RECEIPTS_FAIL });
    };
}
