import { Card, Text, Tokens } from "@accurx/design";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledCard = styled(Card)`
    > div {
        padding: ${Tokens.SIZES[1.5]};
        padding-left: ${Tokens.SIZES[2]};
        margin-left: ${Tokens.SIZES[2]};
    }
`;

export const StyledPracticeCard = styled(Card)`
    > div {
        padding: ${Tokens.SIZES[0.5]};
    }
`;

export const StyledMessageText = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: anywhere;
    min-width: 0;
    overflow: hidden;
`;

export const StyledDateText = styled(Text)`
    white-space: nowrap;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    border: 1px solid ${Tokens.COLOURS.greyscale.dishwater};
    margin-top: -2px;

    &:focus,
    &:hover {
        z-index: 1;
    }

    &:hover {
        border: 1px solid ${Tokens.COLOURS.primary.blue[75]};
        border-radius: ${Tokens.SIZES[0.5]};
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const StyledSpan = styled.span`
    font-weight: 700;
`;
