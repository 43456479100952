import { FeatureName } from "domains/auth/types/FeatureName";
import { Organisation } from "domains/auth/types/Organisation";

import { hasFeature } from "./hasFeature";
import { RequiredFlags } from "./types";

type FeatureFlagsCheckArgs = {
    flags: RequiredFlags;
    workspace: Organisation;
};

export const hasRequiredFeatureFlags = ({
    workspace,
    flags,
}: FeatureFlagsCheckArgs):
    | { isAllowed: true }
    | { isAllowed: false; missingFeatureFlags: FeatureName[] } => {
    const flagRule = flags.all ? "all" : "any";
    const flagList = flags.all || flags.any;

    const missingFeatureFlags = flagList.filter(
        (flag) => hasFeature({ feature: flag, workspace }) === false,
    );

    // If rule is "all", we don't allow a user who has even 1 feature flags disabled/missing
    if (flagRule === "all" && missingFeatureFlags.length > 0) {
        return { isAllowed: false, missingFeatureFlags };
    }
    // If rule is "any", we don't allow a user who has all feature flags disabled/missing
    if (flagRule === "any" && missingFeatureFlags.length === flagList.length) {
        return { isAllowed: false, missingFeatureFlags };
    }
    return { isAllowed: true };
};
