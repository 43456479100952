import { Button, FormFieldWrapper, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    flex: 1;
`;

const StyledFormWrapper = styled.div<{ hasColumnLayout: boolean }>`
    display: flex;
    flex-direction: column;

    gap: ${Tokens.SIZES[1.5]};
    width: 100%;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        flex-direction: ${({ hasColumnLayout }) =>
            hasColumnLayout ? "column" : "row"};
        align-items: end;
    }
`;

const StyledSubmitButton = styled(Button)`
    align-self: end;
    margin-left: auto;
`;

export { StyledSubmitButton, StyledFormFieldWrapper, StyledFormWrapper };
