import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { IsCollapsed } from "app/layout/navigationMenu/NavigationMenu.styles";

export const StyledMobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const StyledFullAccurxLogo = styled.img<{ isCollapsed?: IsCollapsed }>`
    ${({ isCollapsed }: { isCollapsed?: boolean }) =>
        isCollapsed ? `${exitTransition}` : `${entranceTransition}`}
    min-height: 40px;
    position: absolute; // overlap with small logo for the animation
`;

export const StyledSmallAccurxLogo = styled.img<{ isCollapsed?: IsCollapsed }>`
    ${({ isCollapsed }: { isCollapsed?: boolean }) =>
        isCollapsed ? `${exitTransition}` : `${entranceTransition}`}
    min-height: 40px;
`;

const entranceTransition = css`
    max-width: 120px;
    opacity: 1;

    transition: max-width 0.3s ease-out, opacity 0.1s ease-out;
`;

const exitTransition = css`
    max-width: 0;
    opacity: 0;

    transition: max-width 0.3s ease-in, opacity 0.1s ease-in;
`;

export const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    appearance: none;

    &:focus {
        // This is needed to remove the outline shown when a link is in focus
        // We don't want this outline as we are applying our own focus styles.
        box-shadow: none !important;
    }
    max-width: 34px;
`;
