import { BankHolidaysResponse } from "@accurx/api/patient-messaging";
import {
    Log,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

export const useBankHolidays = (
    {
        workspaceId,
    }: {
        workspaceId: number;
    },
    options?: UseQueryOptions<string[], Error>,
) => {
    return useQuery({
        queryKey: ["BankHolidays", { workspaceId }],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: `/api/patientmessaging/BankHoliday/${workspaceId}`,
                }),
            );

            const { bankHolidays } =
                returnDataOrThrow<BankHolidaysResponse>(response);

            if (!bankHolidays) {
                const message =
                    "Api response doesn't contain BankHolidays array";
                Log.error(message);
                throw new Error(message);
            }

            return bankHolidays;
        },
        ...options,
    });
};
