import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledCheckbox = styled(UI.Checkbox)`
    min-height: ${UI.Tokens.SIZES[4]};
    padding: ${UI.Tokens.SIZES[0.5]} ${UI.Tokens.SIZES[1]};

    label[for="${({ id }) => id}"] {
        // removes unneeded padding from checkbox label
        padding: 0;

        > button > span {
            line-height: 1;
        }
    }
`;
