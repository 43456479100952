import { Tokens } from "@accurx/design";
import styled from "styled-components";

/*
    StackPanel component
    ====================

    The intention of this component is to make common layouts easy to achieve.
    See https://github.com/accuRx/rosemary/pull/8886 for extra context.

    The StackPanel is a container based on css flexbox with the added ability to specify
    the margins (gutter) between the child elements. Using this, we should be able to avoid adding spacer components
    and better follow the rule:
    "An element should never define its own margin, it can only be given a margin by a parent. And the inverse for padding"

    This is a very early experiment. Feel free to add/change things if you think they'll be useful
    e.g. a way to specify padding, or set the height or width to 100% etc. If this component proves
    useful we'll add it to the design library, otherwise remove in favour of something better.

    Known (current) limitations:
        - Wrapping - flex-wrap isn't implemented here
        - Responsive - this isn't breakpoint aware so switching from vertical to horizontal will need to be handled
                       by the consumer for now

    Examples:

        - List of items with space between them. "vertical" orientation is the default so could be omitted here
        <StackPanel orientation="vertical" gutter={2}>
            {items.map(item => <div>{item.text}</div>)}
        </StackPanel>

        - Centered content. Perhaps an added height prop could help here (height="100%" etc)
        <StackPanel orientation="horizontal"  horizontalContentAlignment="center" verticalContentAlignment="center">
            <div>I'm in the middle!</div>
        </StackPanel>

        - Space buttons as far apart as possible with minimum gap of 32px (gutter 4)
        <StackPanel orientation="horizontal"  horizontalContentAlignment="space-between" gutter={4}>
            <button>Back</button>
            <button>Confirm</button>
        </StackPanel>
*/

/** Converts directions to flex properties */
const AlignmentMap = {
    top: "flex-start",
    bottom: "flex-end",
    left: "flex-start",
    right: "flex-end",
    center: "center",
    stretch: "stretch",
    baseline: "baseline",
    "space-between": "space-between",
    "space-around": "space-around",
} as const;

type VerticalStackPanelProps = {
    /** [Optional] default is vertical */
    orientation?: "vertical";
    /** [Optional] Vertical alignment of child elements. Default is top */
    verticalContentAlignment?: Extract<
        keyof typeof AlignmentMap,
        "top" | "bottom" | "center" | "space-between" | "space-around"
    >;
    /** [Optional] Horizontal alignment of child elements. Default is stretch */
    horizontalContentAlignment?: Extract<
        keyof typeof AlignmentMap,
        "left" | "right" | "center" | "stretch"
    >;
    /** [Optional] The minimum vertical spacing (Tokens.SIZES) between child elements */
    gutter?: keyof typeof Tokens.SIZES;
};

type HorizontalStackPanelProps = {
    /** [Optional] default is vertical */
    orientation?: "horizontal";
    /** [Optional] Horizontal alignment of child elements. Default is left */
    horizontalContentAlignment?: Extract<
        keyof typeof AlignmentMap,
        "left" | "right" | "center" | "space-between" | "space-around"
    >;
    /** [Optional] Vertical alignment of child elements. Default is stretch */
    verticalContentAlignment?: Extract<
        keyof typeof AlignmentMap,
        "top" | "bottom" | "center" | "stretch" | "baseline"
    >;
    /** [Optional] The minimum horizontal spacing (Tokens.SIZES) between child elements */
    gutter?: keyof typeof Tokens.SIZES;
};

export type StackPanelProps =
    | VerticalStackPanelProps
    | HorizontalStackPanelProps;

export const StackPanel = styled.div<StackPanelProps>`
    display: flex;

    ${(props) => {
        if (props.orientation === "horizontal") {
            return `
                flex-direction: row;
                justify-content: ${
                    props.horizontalContentAlignment
                        ? AlignmentMap[props.horizontalContentAlignment]
                        : "flex-start"
                };
                align-items: ${
                    props.verticalContentAlignment
                        ? AlignmentMap[props.verticalContentAlignment]
                        : "stretch"
                };
            `;
        } else {
            return `
                flex-direction: column;
                justify-content: ${
                    props.verticalContentAlignment
                        ? AlignmentMap[props.verticalContentAlignment]
                        : "flex-start"
                };
                align-items: ${
                    props.horizontalContentAlignment
                        ? AlignmentMap[props.horizontalContentAlignment]
                        : "stretch"
                };
            `;
        }
    }}

    ${(props) => {
        if (props.gutter && props.gutter > 0) {
            const marginSide =
                props.orientation === "horizontal"
                    ? "margin-left"
                    : "margin-top";
            return `
                > * + * {
                    ${marginSide}: ${Tokens.SIZES[props.gutter]};
                }
            `;
        }
    }}
`;
