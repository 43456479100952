import { useCallback } from "react";

import { useAuth } from "@accurx/auth";
import { cleanUpSession } from "appLifecycle";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES } from "shared/Routes";

export const useLogoutChain = () => {
    const analyticsProps = useFlemingLoggedInAnalytics();
    const { logOut } = useAuth();

    return useCallback(() => {
        FlemingAnalyticsTracker.trackLogoutButtonClick(analyticsProps);
        logOut({
            cleanUpFn: cleanUpSession,
            redirectTo: ROUTES.loginOrganisations,
        });
    }, [analyticsProps, logOut]);
};
