import { TabProps } from "@accurx/design";
import { generatePath, useParams } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { TabLookup } from "./AllMessagesPage.constants";

export type UseAllSentMessagesPage = () => {
    tab?: TabProps;
    redirect: string;
};

export const useAllMessagesPage: UseAllSentMessagesPage = () => {
    const { orgId, tab: tabId } = useParams<{ orgId: string; tab?: string }>();

    const tab = TabLookup[tabId || "delivery-receipts"];

    // generate a redirect url in case the route
    // we're on points to an invalid tab
    const redirect = generatePath(ROUTES_ORGS.allMessages, {
        orgId,
    });

    return { tab, redirect };
};
