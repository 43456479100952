import React, { useEffect } from "react";

import { Feedback, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useDispatch } from "react-redux";

import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

import { cancelMessage, getIntialData } from "./DelayedMessages.actions";
import { DelayedMessagesTable } from "./DelayedMessagesTable";

type DelayedMessagesProps = {
    noHeader?: boolean;
};

export const DelayedMessages = ({
    noHeader = false,
}: DelayedMessagesProps): JSX.Element => {
    useAccurxWebTitle("View scheduled messages");

    const dispatch = useDispatch();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const loadingStatus = useAppSelector(
        ({ delayedMessages }) => delayedMessages.loadingStatus,
    );
    const messages = useAppSelector(
        ({ delayedMessages }) => delayedMessages.messages,
    );

    useEffect(() => {
        if (practiceId) {
            dispatch(getIntialData(practiceId));
        }
    }, [dispatch, practiceId]);

    switch (loadingStatus) {
        case LoadingStatus.Initial:
        case LoadingStatus.Loading:
            return <Spinner />;
        case LoadingStatus.Failed:
            return (
                <Feedback
                    title={"Something went wrong. Please refresh the page."}
                    colour="error"
                />
            );
        case LoadingStatus.Loaded:
        default:
            return (
                <div className="mb-4">
                    {!noHeader && (
                        <>
                            <Breadcrumb title="Delayed Messages" />
                            <Text variant="title" as="h1">
                                Delayed Messages
                            </Text>
                        </>
                    )}
                    <Text>
                        These are messages that have been sent outside of
                        working hours or as part of a pathway. They will be sent
                        in the future, and can be cancelled from this page.
                    </Text>
                    <div className="mb-4">
                        <Text skinny>
                            <b>Queued:</b> Delayed message has been scheduled
                            for delivery in the future.
                        </Text>
                        <Text skinny>
                            <b>Cancelled:</b> Delayed message has been cancelled
                            before being sent.
                        </Text>
                    </div>
                    {messages.length > 0 && (
                        <DelayedMessagesTable
                            messages={messages}
                            onCancelMessage={(messageId: string) =>
                                cancelMessage(practiceId, messageId, dispatch)
                            }
                        />
                    )}
                </div>
            );
    }
};
