import { PatientDetails } from "./PatientParser";

/**
 * Adds patients to an existing list, ignoring any patients with the same NHS number
 *
 * @param existingPatientList Existing list of patients. Assumes there are no duplicates
 * @param newPatients Patients to be added, can contain duplicates
 * @return Array of unique patients
 */
export default function dedupePatientsByNhsNumber(
    existingPatientList: PatientDetails[],
    newPatients: PatientDetails[],
) {
    const dedupedPatients = [...existingPatientList];

    newPatients.forEach((patient) => {
        const isInArray = dedupedPatients.find(
            (x) => x.nhsNumber === patient.nhsNumber,
        );

        if (!isInArray) {
            dedupedPatients.push(patient);
        }
    });

    return dedupedPatients;
}
