import React, { useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { Feedback, Flex, Text } from "@accurx/design";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { formatSearch } from "app/account/Account.helper";
import { ROUTES } from "shared/Routes";

import { ActiveDirectoryRedirectForm } from "../ActiveDirectoryRedirectForm";
import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { LoginWithNhsMailLink } from "../LogIn/TrustsLogin/NhsMailLogin";
import { useGetProductTypeFromUrl } from "../LogIn/hooks";
import { RegisterForm } from "./components/RegisterForm";

export const Register = (): JSX.Element => {
    const history = useHistory();
    const product = useGetProductTypeFromUrl();

    const [externalOrgId, setExternalOrgId] = useState("");

    const showActiveDirectoryRedirectForm = Boolean(externalOrgId);
    const searchPreLogin = formatSearch({ search: history.location.search });
    const searchPostLogin = formatSearch({
        search: history.location.search,
        paramsToRemove: ["product"],
    });

    return (
        <OuterStackPanel>
            <Flex flexDirection="column" alignItems="center" gap="3">
                <Text as="h1" variant="title" skinny>
                    Create account
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    {showActiveDirectoryRedirectForm ? (
                        <ActiveDirectoryRedirectForm
                            externalOrgId={externalOrgId}
                            onBack={() => setExternalOrgId("")}
                            isRegisterForm
                        />
                    ) : (
                        <Flex gap="2" flexDirection="column">
                            {product === RegisterAccountProductType.Fleming && (
                                <Feedback
                                    colour="information"
                                    title="Have an nhs.net email? You can log in to Accurx without creating an account."
                                >
                                    <LoginWithNhsMailLink
                                        nextRouteAfterLogin={{
                                            route: ROUTES.postlogin,
                                            search: searchPostLogin,
                                        }}
                                    />
                                </Feedback>
                            )}
                            <RegisterForm
                                product={product}
                                onRegisterSuccess={(email: string) =>
                                    history.push(
                                        `${ROUTES.checkYourInbox}${searchPreLogin}`,
                                        { email },
                                    )
                                }
                                onActiveDirectoryError={(externalOrgId) =>
                                    setExternalOrgId(externalOrgId)
                                }
                            />
                        </Flex>
                    )}
                </InnerStackPanel>
                <Text variant="label" skinny>
                    Already have an account?{" "}
                    <Link
                        to={
                            product === RegisterAccountProductType.Fleming
                                ? `${ROUTES.login}${searchPreLogin}`
                                : `${ROUTES.loginOrganisations}${searchPreLogin}`
                        }
                    >
                        Log in
                    </Link>
                </Text>
            </Flex>
        </OuterStackPanel>
    );
};
