import { useCurrentWorkspace } from "@accurx/auth";
import {
    NativeFetchError,
    NativeTransportError,
    useNativeTransport,
} from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import {
    MutateSupportLinkClickPayload,
    mutateSupportLinkClick,
} from "../api/mutateSupportLinkClick";

export const useSupportLinkClickMutation = (
    options?: UseMutationOptions<
        void,
        NativeFetchError | NativeTransportError,
        MutateSupportLinkClickPayload
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation(async (variables) => {
        if (!transport) {
            throw new NativeTransportError(
                "SupportLinkClickMutation: Called with no native transport",
            );
        }

        await mutateSupportLinkClick(
            {
                transport,
                workspaceId,
            },
            variables,
        );
    }, options);
};
