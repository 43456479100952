import { UseQueryResult } from "@tanstack/react-query";

import {
    TeamSummary,
    UserSummary,
} from "shared/concierge/usersAndTeams/types/usersAndTeams.types";

import { useQueryWrapper } from "./useQueryWrapper";

/**
 * Returns an array of *all* users from the user manager
 */
export const useAllUsersQuery = (): UseQueryResult<
    UserSummary[] | undefined
> => {
    return useQueryWrapper((data) => data.users);
};

/**
 * Given a user id returns a single user from the user manager
 */
export const useSingleUserQuery = (
    userId: string | undefined,
): UseQueryResult<UserSummary | undefined> => {
    return useQueryWrapper((data) => data.users.find((x) => x.id === userId));
};

/**
 * Returns an array of *all* teams from the user manager
 */
export const useAllTeamsQuery = (): UseQueryResult<
    TeamSummary[] | undefined
> => {
    return useQueryWrapper((data) => data.teams);
};

/**
 * Given a user id returns a single user from the user manager
 */
export const useSingleTeamQuery = (
    teamId: string | undefined,
): UseQueryResult<TeamSummary | undefined> => {
    return useQueryWrapper((data) => data.teams.find((x) => x.id === teamId));
};

/**
 * Returns an array of *all* teams from the user manager
 */
export const useAllUsersAndTeamsQuery = () => {
    return useQueryWrapper((data) => data);
};

/**
 * Returns the ids of teams the current user is in
 */
export const useCurrentUserTeamIdsQuery = () => {
    return useQueryWrapper((data) => data.currentUserTeamIds);
};

/**
 * For cases where you want to request a user or a team interchangeably
 * this hook lets you request one or the other.
 */
export const useSingleUserOrTeamQuery = (
    request?: UserOrTeamRequest,
): UseQueryResult<UserSummary | TeamSummary | undefined> => {
    return useQueryWrapper((data) => {
        if (request?.type === "User") {
            return data.users.find((x) => x.id === request.id);
        }

        if (request?.type === "Team") {
            return data.teams.find((x) => x.id === request.id);
        }

        return undefined;
    });
};

type UserRequest = {
    type: "User";
    id: string;
};
type TeamRequest = {
    type: "Team";
    id: string;
};

export type UserOrTeamRequest = UserRequest | TeamRequest;

/**
 * Given the current user id, it returns an object with all colleagues and teams
 * excluding the current user.
 */
export const useAllColleaguesQuery = (
    currentUserId: string,
): UseQueryResult<
    | {
          users: UserSummary[];
          teams: TeamSummary[];
      }
    | undefined
> => {
    return useQueryWrapper((data) => {
        return {
            teams: data.teams,
            users: data.users.filter((user) => user.id !== currentUserId),
        };
    });
};
