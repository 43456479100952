import { Action, Reducer } from "redux";

import { RESET_PRODUCT } from "../selectProduct/SelectProductActions";
import { KnownAction } from "./VideoLobbyActions";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface VideoLobbyState {
    hasEverCheckedProgress: boolean;
    isCheckingProgress: boolean;
    sentMessage: boolean;
    sentMessageAt: string;
    receivedMessage: boolean;
    receivedMessageAt: string;
    linkClicked: boolean;
    linkClickedAt: string;
    settingUpDevice: boolean;
    settingUpDeviceAt: string;
    consultationId: string;
    cannotGetProgress: boolean;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: VideoLobbyState = {
    hasEverCheckedProgress: false,
    isCheckingProgress: false,
    sentMessage: false,
    sentMessageAt: "",
    receivedMessage: false,
    receivedMessageAt: "",
    linkClicked: false,
    linkClickedAt: "",
    settingUpDevice: false,
    settingUpDeviceAt: "",
    consultationId: "",
    cannotGetProgress: false,
};

// We currently assume only one consultation occuring at once in lifetime of application.

export const reducer: Reducer<VideoLobbyState> = (
    state = initialState,
    incomingAction: Action,
): VideoLobbyState => {
    //If we re-select product then clear previous state
    if (incomingAction.type === RESET_PRODUCT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "CHECK_PROGRESS_STARTED":
            return {
                ...state,
                hasEverCheckedProgress: true,
                isCheckingProgress: true,
            };
        case "CHECK_PROGRESS_RESPONSE":
            if (action.response === null) {
                return {
                    ...state,
                    cannotGetProgress: true,
                };
            }
            return {
                ...state,
                // Message Sent
                sentMessage: action.response.patientMessageSent !== null,
                sentMessageAt:
                    action.response.patientMessageSent !== null
                        ? action.response.patientMessageSent
                        : "",
                // Message Delivered
                receivedMessage:
                    action.response.patientMessageDelivered !== null,
                receivedMessageAt:
                    action.response.patientMessageDelivered !== null
                        ? action.response.patientMessageDelivered
                        : "",
                // Link Clicked
                linkClicked: action.response.patientClickedLink !== null,
                linkClickedAt:
                    action.response.patientClickedLink !== null
                        ? action.response.patientClickedLink
                        : "",
                // Setting Up Device
                settingUpDevice:
                    action.response.patientSettingUpDevice !== null,
                settingUpDeviceAt:
                    action.response.patientSettingUpDevice !== null
                        ? action.response.patientSettingUpDevice
                        : "",
                consultationId: action.consultationId,
                cannotGetProgress: false,
            };
        case "CHECK_PROGRESS_FINISHED":
            return {
                ...state,
                isCheckingProgress: false,
            };
        case "RESET_SUBSCRIPTIONS":
        default:
            return initialState;
    }
};
