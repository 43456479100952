import { IWrappedResult } from "@accurx/shared/dist";
import { Action, Reducer } from "redux";

import {
    IMessagePatientResponse,
    MessagePatientAllEndpointsRequest,
} from "api/FlemingDtos";
import { StoredFileWithId } from "app/fileUpload/FileUploadReducer";

import {
    FILE_REMOVED,
    FILE_UPLOAD_FINISHED,
} from "../fileUpload/FileUploadActions";
import { RESET_PRODUCT } from "../selectProduct/SelectProductActions";
import {
    CHANGE_MESSAGE_TYPE,
    KnownAction,
    SEND_MESSAGE_FINISHED,
    SEND_MESSAGE_STARTED,
} from "./SendMessageActions";

export enum MessageType {
    Sms,
    Email,
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessagePatientState {
    isMessageSending: boolean;
    messageInfoSent: MessagePatientAllEndpointsRequest;
    lastSendMessageResponse: IWrappedResult<IMessagePatientResponse> | null;
    lastFileUploaded: StoredFileWithId | null;
    lastFileRemoved: string | null;
    messageType: MessageType;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialMessagePatientRequest: MessagePatientAllEndpointsRequest = {
    organisationId: null,
    patientToken: "",
    mobileNumber: "null",
    useMobileNumberFromPDS: false,
    messageBody: "null",
    nhsNumber: "null",
    emailSubjectLine: "null",
    emailBody: "null",
    isVideoConsult: false,
    attachedDocumentIds: new Array<string>(),
    emailAddress: "null",
    enablePatientResponse: false,
    patientListId: null,
    patientListEntryId: null,
    videoConsultTime: null,
    ticketIdentity: null,
    patientExternalIdentity: {
        patientExternalIds: [],
    },
};

export const initialState: MessagePatientState = {
    messageInfoSent: initialMessagePatientRequest,
    lastSendMessageResponse: null,
    isMessageSending: false,
    lastFileUploaded: null,
    lastFileRemoved: null,
    messageType: MessageType.Sms,
};

export const reducer: Reducer<MessagePatientState> = (
    state = initialState,
    incomingAction: Action,
): MessagePatientState => {
    if (incomingAction.type === RESET_PRODUCT) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SEND_MESSAGE_STARTED:
            return {
                ...state,
                isMessageSending: true,
                lastSendMessageResponse: null, //Reset if not already
                messageInfoSent: action.requestData,
            } as MessagePatientState;

        case SEND_MESSAGE_FINISHED:
            return {
                ...state,
                isMessageSending: false,
                lastSendMessageResponse: action.response,
            };

        case FILE_UPLOAD_FINISHED:
            return {
                ...state,
                lastFileUploaded: action.file,
            };

        case FILE_REMOVED:
            return {
                ...state,
                lastFileRemoved: action.fileId,
            };

        case CHANGE_MESSAGE_TYPE:
            return {
                ...state,
                messageType: action.messageType,
            };
    }

    return state;
};
