import { Button, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledFormWrapper = styled.div`
    display: block;
    align-items: start;
    margin-bottom: ${Tokens.SIZES[1.5]};
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: flex;
    }
`;

const StyledFormFieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1.5]};

    width: 100%;
    padding-bottom: ${Tokens.SIZES[1.5]};

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: grid;
        grid-template-columns: repeat(4, 1fr) fit-content(100%);
        column-gap: ${Tokens.SIZES[1.5]};
        row-gap: ${Tokens.SIZES[1]};

        padding-bottom: 0;
    }
`;

const StyledFirstName = styled.div`
    grid-column: 1/3;
    grid-row: 1;
`;

const StyledLastName = styled.div`
    grid-column: 3/5;
    grid-row: 1;
`;

const StyledDateOfBirth = styled.div`
    grid-column: 1/3;
    grid-row: 2;
`;

const StyledGenderSelect = styled.div`
    grid-column: 3;
    grid-row: 2;
`;

const StyledPostcodeInput = styled.div`
    grid-column: 4;
    grid-row: 2;
`;

const StyledSubmitButton = styled(Button)`
    align-self: flex-end;

    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        align-self: flex-start;
        margin-top: ${Tokens.SIZES[3]};
    }

    grid-column: 5;
    grid-row: 2;
`;

export {
    StyledFormWrapper,
    StyledFormFieldsWrapper,
    StyledFirstName,
    StyledLastName,
    StyledDateOfBirth,
    StyledSubmitButton,
    StyledGenderSelect,
    StyledPostcodeInput,
};
