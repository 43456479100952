import { NativeApi } from "domains/native/api/NativeApi";
import { createEndpoint } from "domains/native/api/api.utils";

export const mutatePersistAttachmentUsage = createEndpoint<void, void>(
    "MutatePersistAttachmentUsage",
    async (context) => {
        await NativeApi.request({
            transport: context.transport,
            request: {
                type: "MutatePersistAttachmentUsage",
                workspaceId: context.workspaceId,
            },
        });
    },
);
