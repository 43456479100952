import { useEffect, useState } from "react";

import { useSelectedGlobalOrganisationConversationManager } from "app/workspaceConversations/context/SelectedGlobalOrganisationConversationManagerContext";

export type WorkspaceUnreadCounts = {
    /** The count of unread conversations to display as notifiable */
    unreadCount: number;
};

/**
 * This hook provides overall unread notification counts for a given workspace,
 * as there are a few alternate UI nav components which pull this data.
 *
 * Note that at the top navigation levels it's possible to not currently belong to
 * a workspace, not be logged in, or not have access to this data.
 */
export const useCurrentWorkspaceUnreadCounts = (): WorkspaceUnreadCounts => {
    const workspaceUnreadCounts = useWorkspaceUnreadCount();

    return {
        unreadCount: workspaceUnreadCounts,
    };
};

export const useWorkspaceUnreadCount = (): number => {
    const [summary, setSummary] = useState<number>(0);

    const conversationManager =
        useSelectedGlobalOrganisationConversationManager();

    useEffect(() => {
        if (!conversationManager) {
            // no data yet
            setSummary(0);
            return;
        }

        const subscription = conversationManager
            .getConversationGroupSummary({ rules: [] })
            .feed.subscribe((state) =>
                setSummary(state.data?.unreadCount ?? 0),
            );

        return () => {
            subscription.unsubscribe();
        };
    }, [conversationManager]);

    return summary;
};
