import * as React from "react";

import { ErrorSummaryProvider } from "@accurx/design";

import {
    FloreyBuilderReducer,
    initialState as initialStateFromReducer,
} from "./FloreyBuilder.reducer";
import { ContextProps, FloreyBuilderProviderProps } from "./types";

export const FloreyBuilderStateContext = React.createContext<
    ContextProps | undefined
>(undefined);

function FloreyBuilderProvider({
    children,
    initialState = initialStateFromReducer,
}: /**
 * This prop allows us to override the state being passed to the FloreyBuilderProvider
 * e.g. for testing purposes
 */
FloreyBuilderProviderProps): JSX.Element {
    const [state, dispatch] = React.useReducer(
        FloreyBuilderReducer,
        initialState,
    );
    const value = { state, dispatch };
    return (
        <FloreyBuilderStateContext.Provider value={value}>
            <ErrorSummaryProvider>{children}</ErrorSummaryProvider>
        </FloreyBuilderStateContext.Provider>
    );
}

function useFloreyBuilder(): ContextProps {
    const context = React.useContext(FloreyBuilderStateContext);
    if (context === undefined) {
        throw new Error(
            "useFloreyBuilder must be used within a FloreyBuilderProvider",
        );
    }
    return context;
}

export { FloreyBuilderProvider, useFloreyBuilder };
