import { Ds, Flex, Icon, Item, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { formatUpdatedDateTime } from "../../../shared/formatUpdatedDateTime";
import { Header } from "./Header.styles";

type IntegratedClinicHeaderProps = {
    name?: string | null;
    clinicCode?: string | null;
    clinicDate?: string | null;
    lastUpdated?: string | null;
    location?: string | null;
    responsibleClinicianName?: string | null;
};

export default function IntegratedClinicHeader({
    name,
    clinicCode,
    clinicDate,
    lastUpdated,
    location,
    responsibleClinicianName,
}: IntegratedClinicHeaderProps) {
    const formattedDate = clinicDate
        ? DateHelpers.formatDate(
              new Date(clinicDate).toISOString(),
              DateFormatOptions.DATE_SHORT_WITH_SPACES,
          )
        : "Unknown date";
    const humanReadableLastUpdated = formatUpdatedDateTime(lastUpdated);

    return (
        <Header>
            <Flex gap="1.5" justifyContent="space-between" flexWrap="wrap">
                <Item>
                    <Text as="h1" variant="subtitle" skinny>{`${
                        name ? name : "Unknown clinic"
                    } · ${formattedDate}`}</Text>
                    <Flex gap="0.25 1.5" flexWrap="wrap">
                        <Text as="span" skinny>
                            {responsibleClinicianName}
                        </Text>
                        <Text as="span" skinny>
                            {clinicCode}
                        </Text>
                        <Text as="span" skinny>
                            {location}
                        </Text>
                    </Flex>
                </Item>
                <Flex gap="1.5" flexWrap="wrap-reverse" alignItems="center">
                    <Flex alignItems="center">
                        <Icon name="Clock" size={3} />
                        <Text variant="note" as="span" skinny>
                            {`Updated · ${humanReadableLastUpdated}`}
                        </Text>
                    </Flex>

                    <Ds.Badge color="blue">Integrated with HISS</Ds.Badge>
                </Flex>
            </Flex>
        </Header>
    );
}
