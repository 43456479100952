import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

export const PatientProfileOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const saveToStore = useSavePatientSearchToStore();

    return (
        <ContactOption
            // Never disabled because every patient has a profile...
            disabled={false}
            iconName="Person"
            text="View profile"
            onClick={() => {
                saveToStore(searchResult);
                onClick();
            }}
        />
    );
};
