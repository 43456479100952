import { SortDirection } from "@accurx/design";

import { SortOptions } from "./types";

export const COLUMN_SORT_DIRECTIONS: Record<
    string,
    {
        asc: SortDirection[];
        desc: SortDirection[];
        initial: SortDirection;
    }
> = {
    sentAt: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "asc",
    },
    patient: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "asc",
    },
    status: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "asc",
    },
    complexity: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "asc",
    },
    answeredAt: {
        asc: ["asc"],
        desc: ["desc"],
        initial: "desc",
    },
};

export const INITIAL_TABLE_SORT_OPTIONS: SortOptions = {
    sortColumnId: "sentAt",
    sortDirection: COLUMN_SORT_DIRECTIONS.sentAt.initial,
} as const;
