import { useEffect, useState } from "react";

import { useSelectedPatientToken } from "app/patients/hooks";
import { useWorkspaceId } from "shared/concierge/conversations/hooks";
import { SearchedPatientConversationGroupActionScope } from "shared/concierge/conversations/tickets/SearchedPatientConversationActions";
import { ConversationDisplaySummary } from "shared/concierge/conversations/types/conversation.types";
import { usePatientManager } from "shared/concierge/patients/hooks";
import {
    PaginatedSubscription,
    Pagination,
    SubscriptionResult,
} from "shared/concierge/types/subscription.types";
import { useUsersAndTeamsManager } from "shared/concierge/usersAndTeams/context/UsersAndTeamsContext";
import { useCurrentUserId } from "store/hooks";

import { setupSearchedPatientConversationGroupSubscription } from "./patientConversationGroupSubscription";

/**
 * Instantiates a new conversation group subscription and returns its feed's results
 */
export const useSearchedPatientConversationGroup = (
    conversationGroupType: SearchedPatientConversationGroupActionScope["type"],
): SubscriptionResult<ConversationDisplaySummary[]> & {
    paginationActions?: Pagination;
    retryFetch?: () => Promise<void>;
} => {
    const patientManager = usePatientManager();
    const usersAndTeamsManager = useUsersAndTeamsManager();

    const currentUserId = useCurrentUserId();
    const currentWorkspaceId = useWorkspaceId();
    const currentPatientToken = useSelectedPatientToken();

    const [state, setState] = useState<
        SubscriptionResult<ConversationDisplaySummary[]>
    >({
        status: "LOADING",
        data: null,
        errorMessage: null,
    });
    const [groupSubscription, setGroupSubscription] = useState<
        PaginatedSubscription<ConversationDisplaySummary> | undefined
    >();

    useEffect(() => {
        if (
            !Number.isSafeInteger(currentWorkspaceId) ||
            !currentPatientToken ||
            !currentUserId ||
            !conversationGroupType
        ) {
            return;
        }

        const { subscription, actionFeeds } =
            setupSearchedPatientConversationGroupSubscription({
                currentPatientToken,
                currentUserId,
                currentWorkspaceId,
                conversationGroupType,
            });

        subscription.start();

        setGroupSubscription(subscription);

        const feed = subscription.feed.subscribe((update) => {
            setState(update);
        });

        const patientsSubscriptionHandle = patientManager.connectFeed(
            actionFeeds.patients,
        );
        const usersAndTeamsSubscriptionHandle =
            usersAndTeamsManager.connectFeed(actionFeeds.usersAndTeams);

        return () => {
            feed.unsubscribe();
            subscription.teardown();
            patientsSubscriptionHandle.unsubscribe();
            usersAndTeamsSubscriptionHandle.unsubscribe();
        };
    }, [
        currentPatientToken,
        currentUserId,
        currentWorkspaceId,
        conversationGroupType,
        usersAndTeamsManager,
        patientManager,
    ]);

    return {
        ...state,
        paginationActions: groupSubscription?.pagination,
        retryFetch: groupSubscription?.retryFetch,
    };
};
