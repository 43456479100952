import React, { PropsWithChildren, ReactElement } from "react";

import { Card, StackPanel, Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";

import { PatientInfo } from "api/ClinicianConversationApi";

type DetailFieldProps = {
    label: string;
    value: string;
};

export type PatientDetailsCardPatient = Omit<PatientInfo, "patientId">;

type PatientDetailsCardProps = {
    patient?: PatientDetailsCardPatient;
    noHeader?: boolean;
};

export const getDisplayName = (
    patientInfo: PatientDetailsCardPatient,
): string => {
    if (patientInfo.firstName && patientInfo.lastName) {
        return `${patientInfo.lastName.toUpperCase()}, ${
            patientInfo.firstName
        }`;
    } else if (patientInfo.lastName) {
        return patientInfo.lastName;
    } else if (patientInfo.firstName) {
        return patientInfo.firstName;
    }
    return "Unknown";
};

const DetailField = ({ label, value }: DetailFieldProps) => {
    return (
        <Text as="p" skinny>
            {label}: {value}
        </Text>
    );
};

export const PatientDetailsCard = ({
    patient,
    noHeader,
    children, // children could be additional information or a link to patient profile, for example
}: PropsWithChildren<PatientDetailsCardProps>): ReactElement => {
    const nhsNumber = patient?.nhsNumber;
    const dateOfBirth = patient?.dateOfBirth;

    return (
        <Card
            spacing={2}
            header={
                noHeader ? undefined : (
                    <Text variant="label" as="h2">
                        Patient details
                    </Text>
                )
            }
        >
            {patient ? (
                <StackPanel gutter={1}>
                    <Text
                        as={noHeader ? "h2" : "h3"}
                        variant={noHeader ? "label" : "subtitle"}
                        skinny
                        className="text-wrap text-truncate"
                    >
                        {getDisplayName(patient)}
                    </Text>
                    <div>
                        <DetailField
                            label="NHS"
                            value={
                                nhsNumber
                                    ? NhsNumberHelpers.formatNhsNumber(
                                          nhsNumber,
                                      )
                                    : "Unknown"
                            }
                        />
                        <DetailField
                            label="Born"
                            value={
                                dateOfBirth
                                    ? DateHelpers.formatDate(
                                          dateOfBirth,
                                          DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                                      )
                                    : "Unknown"
                            }
                        />
                        <DetailField
                            label="Gender"
                            value={patient.gender ?? "Unknown"}
                        />

                        {children}
                    </div>
                </StackPanel>
            ) : (
                <Text as="p" skinny>
                    Unknown patient details
                </Text>
            )}
        </Card>
    );
};
