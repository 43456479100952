/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import { VaccineApprovalDetails } from "app/practices/Practices.types";
import { VaccinePracticeLinks } from "app/practices/vaccine/approvalRequests/ApprovalRequests.types";
import { VaccineDeliverySites } from "app/practices/vaccine/capacityDashboard/CapacityDashboard.type";

const ENDPOINTS = {
    vaccineApproval: "/api/recall/VaccineApprovalDetails",
    vaccineCapacity: "/api/recall/Practices/:practiceId/RecallVaccineCapacity",
    vaccineSiteLinks:
        "/api/recall/Practices/:practiceId/RecallVaccineSiteLinks",
    vaccineSiteUpdateApproval:
        "/api/recall/Practices/:practiceId/RecallVaccineSiteLinks/UpdateApproval",
    vaccineSiteLeaveDelivery:
        "/api/recall/Practices/:practiceId/RecallVaccineSiteLinks/LeaveDeliverySite",
} as const;

export const getVaccineDetails = (): Promise<
    IWrappedResult<VaccineApprovalDetails[]>
> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.vaccineApproval,
        }),
    );

export const getVaccineCapacity = (
    practiceId: string,
): Promise<IWrappedResult<VaccineDeliverySites>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.vaccineCapacity,
            params: { practiceId },
        }),
    );

export const getVaccineSiteLinks = (
    practiceId: string,
): Promise<IWrappedResult<VaccinePracticeLinks>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.vaccineSiteLinks,
            params: { practiceId },
        }),
    );

export const postVaccineSiteLinksApproval = (
    practiceId: string,
    request: any,
): Promise<IWrappedResult<VaccinePracticeLinks>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.vaccineSiteUpdateApproval,
            params: { practiceId },
        }),
        request,
    );

export const postVaccineSiteLinksLeave = (
    practiceId: string,
    request: any,
): Promise<IWrappedResult<VaccinePracticeLinks>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.vaccineSiteLeaveDelivery,
            params: { practiceId },
        }),
        request,
    );
