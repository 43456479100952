import React, { ComponentProps, forwardRef } from "react";

import * as UI from "@accurx/design";
import { Link, LinkProps } from "domains/inbox/components/Link/Link";
import styled, { css } from "styled-components";

// Nab a few useful bits from the UI library
const { COLOURS, SIZES, BORDERS } = UI.Tokens;
type SizeVariants = ComponentProps<typeof UI.Icon>["size"];

/**
 * <DropdownItem.ListItem />
 * A list item that wraps and positions individual dropdown items.
 */
const DropdownListItem = styled.li<{ $mb?: SizeVariants }>`
    display: block;

    padding: 0;
    margin: 0;
    margin-bottom: ${({ $mb }) => ($mb ? SIZES[$mb] : 0)};
`;

/**
 * <DropdownItem.TitleBar />
 * A header element that acts as a label for the dropdown options.
 */
const DropdownItemTitleBar = styled.div<{ $mb?: SizeVariants }>`
    background: ${COLOURS.greyscale.cloud};
    padding: ${SIZES[1]} ${SIZES[1.5]};
    border-bottom: 1px solid ${COLOURS.greyscale.silver};
    margin: -${SIZES[1]} -${SIZES[1]} 0;
    margin-bottom: ${({ $mb }) => ($mb ? SIZES[$mb] : 0)};
`;

/**
 * <DropdownItem.Divider />
 * A divider to separate groups of dropdown items. Can be full-width.
 */
const StyledDropdownItemDivider = styled.hr<{ $fullWidth?: boolean }>`
    border-top: 1px solid ${COLOURS.greyscale.silver};
    margin: ${({ $fullWidth }) =>
        $fullWidth ? `${SIZES[1]} -${SIZES[1]}` : `${SIZES[1]} 0`};
`;
type DropdownItemDividerProps = ComponentProps<
    typeof StyledDropdownItemDivider
> & {
    fullWidth?: boolean;
};
const DropdownItemDivider = forwardRef<HTMLHRElement, DropdownItemDividerProps>(
    ({ fullWidth, ...props }, ref) => {
        return (
            <StyledDropdownItemDivider
                ref={ref}
                {...props}
                $fullWidth={fullWidth}
            />
        );
    },
);

/**
 * <DropdownItem.Icon />
 * An icon for inside dropdown items.
 */
const StyledDropdownItemIconContainer = styled.span`
    margin-right: ${SIZES[0.5]};
`;
const DropdownItemIcon = (props: ComponentProps<typeof UI.Icon>) => {
    return (
        <StyledDropdownItemIconContainer>
            <UI.Icon colour="currentColor" size={3} {...props} />
        </StyledDropdownItemIconContainer>
    );
};

/**
 * <DropdownItem.Text />
 * The text for inside dropdown items.
 */
const DropdownItemText = styled(UI.Text).attrs({ skinny: true })``;

/**
 * <DropdownItem.Button />
 * An interactive dropdown item as a button element.
 */
const StyledDropdownItemButton = styled.button<{ $selected: boolean }>`
    appearance: none;
    background: white;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    align-items: flex-start;
    padding: ${SIZES[1]} ${SIZES[1.5]};
    border-radius: ${BORDERS.radius};
    color: ${COLOURS.greyscale.zinc};

    &:focus,
    &:active {
        outline: none;
        box-shadow: 0 0 0 2px ${COLOURS.greyscale.white},
            0 0 0 4px ${COLOURS.primary.blue[100]};
        position: relative;
        z-index: 1;
    }

    ${({ $selected }) => {
        if ($selected) {
            return css`
                background: ${COLOURS.primary.blue["100"]};
                color: ${COLOURS.greyscale.white};

                ${DropdownItemText} {
                    color: ${COLOURS.greyscale.white};
                }
            `;
        } else {
            return css`
                &:hover {
                    text-decoration: none;
                    background: ${COLOURS.primary.blue[10]};
                }

                &:focus,
                &:active {
                    background: ${COLOURS.primary.blue[10]};
                }
            `;
        }
    }}
`;
type DropdownItemButtonProps = ComponentProps<
    typeof StyledDropdownItemButton
> & {
    selected?: boolean;
};
const DropdownItemButton = forwardRef<
    HTMLButtonElement,
    DropdownItemButtonProps
>(({ children, selected, ...buttonProps }, ref) => {
    return (
        <StyledDropdownItemButton
            {...buttonProps}
            ref={ref}
            $selected={!!selected}
        >
            {children}
        </StyledDropdownItemButton>
    );
});

/**
 * <DropdownItem.Link />
 * An interactive dropdown item as a Link element.
 * The underlying implementation includes a button element so that the
 * item gets focused once the dropdown is opened.
 */
const StyledDropdownItemLink = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`;
type DropdownItemLinkProps = {
    selected?: boolean;
    link: LinkProps["to"];
    onClick?: (e: React.MouseEvent) => void;
    children: React.ReactNode;
};
const DropdownItemLink = ({
    children,
    selected,
    link,
    onClick,
}: DropdownItemLinkProps) => {
    return (
        <StyledDropdownItemLink
            to={link}
            aria-current={selected ? "page" : false}
            onClick={onClick}
            tabIndex={-1} // lets focus move straight to the button
        >
            <StyledDropdownItemButton $selected={!!selected}>
                {children}
            </StyledDropdownItemButton>
        </StyledDropdownItemLink>
    );
};

export const DropdownItem = {
    ListItem: DropdownListItem,
    TitleBar: DropdownItemTitleBar,
    Divider: DropdownItemDivider,
    Icon: DropdownItemIcon,
    Text: DropdownItemText,
    Button: DropdownItemButton,
    Link: DropdownItemLink,
};
