import React from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { i } from "images";
import { useLocation } from "react-router-dom";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { StyledCheckYourInbox } from "./CheckYourInbox.styles";

export const CheckYourInbox = (): JSX.Element => {
    const location = useLocation<{ email?: string }>();
    const params = new URLSearchParams(location.search);
    // Ideally we don't have email in the URL, so we should try and use
    // location state to pass the email instead, fallback to URL to ensure
    // we don't lose existing functionality
    const email = location.state?.email ?? params.get("email");
    const sentEmail = email
        ? ` the email we sent to ${email}`
        : " the email we've sent you";

    return (
        <OuterStackPanel>
            <StyledCheckYourInbox.Img
                src={i("send-illustration.svg")}
                alt="Check your inbox send illustration"
            />
            <Text variant="title" as="h1" skinny>
                Check your inbox
            </Text>
            <InnerStackPanel horizontalContentAlignment="left">
                <Text skinny>
                    Please follow the instructions in{" "}
                    <strong>{sentEmail}</strong>
                </Text>

                <Feedback
                    colour="secondary"
                    title="Can't find an email from Accurx?"
                >
                    <Text skinny variant="preview">
                        They can sometimes end up in your Spam or Junk folder,
                        so you may find it hiding in there. If you're unable to
                        locate it, please{" "}
                        <Button
                            text="contact support"
                            as="span"
                            dimension="small"
                            theme="secondary"
                            onClick={() =>
                                window.Intercom && window.Intercom("show")
                            }
                        ></Button>
                    </Text>
                </Feedback>
            </InnerStackPanel>
        </OuterStackPanel>
    );
};
