import { StackPanel, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

const MAX_SIDEBAR_WIDTH = 480;
const SIDEBAR_PADDING = 100;
const MAX_CONTENT_WIDTH = 280;

export const StyledSidebar = styled(StackPanel)`
    width: 100vw;

    max-width: 100vw;
    min-width: 30%;
    padding: ${Tokens.SIZES[3]} ${Tokens.SIZES[3]} ${Tokens.SIZES[2]};
    background-color: ${Tokens.COLOURS.primary.green[100]};
    overflow-y: auto;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[8]} ${Tokens.SIZES[6]};
        max-width: ${MAX_SIDEBAR_WIDTH}px;
        min-height: 100vh;
        overflow-y: unset;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        padding: ${Tokens.SIZES[8]};
    }
`;

export const StyledImg = styled.img`
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledBranding = styled.div`
    margin-bottom: ${Tokens.SIZES[2]};

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        margin-bottom: ${SIDEBAR_PADDING}px;
    }
`;

export const StyledList = styled.ul`
    padding-left: 0;
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledHeading = styled(Text)`
    padding-bottom: ${Tokens.SIZES[1]};
`;

export const StyledListItem = styled.li`
    list-style: none;
    padding-bottom: ${Tokens.SIZES[2]};
`;

export const StyledBorderedBox = styled(StackPanel)`
    padding: ${Tokens.SIZES[2]};
    border: 1px solid ${Tokens.COLOURS.primary.green[130]};
    border-radius: 9px;
    max-width: max-content;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        max-width: ${MAX_CONTENT_WIDTH}px;
    }
`;

export const StyledIconBox = styled(StackPanel)`
    background-color: ${Tokens.COLOURS.primary.green[75]};
    border-radius: ${Tokens.SIZES[1]};
    height: ${Tokens.SIZES[4]};
    width: ${Tokens.SIZES[4]};
    flex-shrink: 0;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    min-height: 100vh;

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        flex-wrap: nowrap;
        flex-direction: row;
    }
`;
