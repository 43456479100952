import { BatchPatientIdentifier } from "@accurx/api/patient-messaging";
import { Feedback, Text, Tokens } from "@accurx/design";
import { UploadPatientFileResponse } from "domains/batch-messaging/types";
import { mapFileProcessingErrors } from "domains/batch-messaging/utils/mapFileProcessingErrors";
import styled from "styled-components";

const ErrorList = styled.ul`
    margin: 0;
    padding-left: ${Tokens.SIZES[3]};
`;

interface FileProcessingErrorsProps {
    uploadData: UploadPatientFileResponse;
    identifier: BatchPatientIdentifier;
}

export const FileProcessingErrors = ({
    uploadData,
    identifier,
}: FileProcessingErrorsProps) => {
    if (uploadData.isUploadSuccess) return null;

    return (
        <Feedback
            colour="error"
            title="Your file has missing or invalid information"
        >
            <ErrorList>
                {mapFileProcessingErrors(uploadData, identifier).map(
                    (error, index) => (
                        <li key={`processing-error-${index}`}>
                            <Text as="span" skinny variant="label">
                                {error.mainMessage}
                            </Text>{" "}
                            <Text as="span" skinny>
                                {error.details}
                            </Text>
                        </li>
                    ),
                )}
            </ErrorList>
        </Feedback>
    );
};
