import {
    AnalyticsProperties,
    AnalyticsTracker,
    EventClientPrefixes,
} from "@accurx/shared";

export const TrackAppointmentReminderEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.APPOINTMENT_REMINDER,
        eventName,
        props,
    );
};

export const TrackManagePracticeEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.MANAGE_PRACTICE,
        eventName,
        props,
    );
};

export const TrackBatchEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.BATCH,
        eventName,
        props,
    );
};

export const TrackVaccinesDeliveryEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.VACCINES,
        eventName,
        props,
    );
};

export const TrackRecordViewOptInEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.RECORD_VIEW_OPT_IN,
        eventName,
        props,
    );
};

export const TrackInhalerRemindersEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    AnalyticsTracker.LegacyTrackEvent(
        EventClientPrefixes.INHALER_REMINDERS,
        eventName,
        props,
    );
};
