import React from "react";

import { Text } from "@accurx/design";

type CharacterCountProps = {
    currentCount: number;
    maxCount: number;
};

export const CharacterCount = ({
    currentCount,
    maxCount,
}: CharacterCountProps) => {
    return (
        <Text
            skinny
            variant="note"
            colour="zinc"
            props={{ "aria-label": "Character count" }}
        >
            {maxCount - currentCount}/{maxCount}
        </Text>
    );
};
