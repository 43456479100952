import { ReactNode } from "react";

import * as UI from "@accurx/design";

import { StyledUnstyledList } from "./AttachmentFailedMessage.styles";

const MessageItem = ({
    children,
    as,
}: {
    children: ReactNode;
    as?: string;
}) => {
    return (
        <UI.Text skinny colour="red" variant="preview" as={as}>
            {children}
        </UI.Text>
    );
};

export const AttachmentFailedMessage = ({ errors }: { errors: string[] }) => {
    if (errors.length === 0) {
        return null;
    }

    if (errors.length > 1) {
        return (
            <StyledUnstyledList>
                {errors.map((error) => (
                    <MessageItem key={error} as="li">
                        {error}
                    </MessageItem>
                ))}
            </StyledUnstyledList>
        );
    }

    return <MessageItem>{errors[0]}</MessageItem>;
};
