import { ContactDetail } from "@accurx/compose";
import { PatientDemographics } from "@accurx/native";
import { MobileNumberHelper } from "@accurx/shared";

export const mapPatientDemographicsContactToContactDetails = ({
    mobileTelephone,
    email,
}: PatientDemographics["contact"]): ContactDetail[] => {
    const contactDetails: ContactDetail[] = [];

    const isValidMobileNumber = MobileNumberHelper.isValidMobileNumber(
        mobileTelephone ?? undefined,
    );

    if (mobileTelephone && isValidMobileNumber) {
        contactDetails.push({
            method: "Mobile",
            value: mobileTelephone,
            origin: "EMR",
        });
    }

    if (email) {
        contactDetails.push({
            method: "Email",
            value: email,
            origin: "EMR",
        });
    }

    return contactDetails;
};
