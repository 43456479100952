import React, { useEffect, useState } from "react";

import {
    Button,
    Compose,
    Feedback,
    FileUploadResponse,
    Flex,
    FormFieldFeedback,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { useSafeAsync } from "api/Api.utils";
import FlemingApiClient from "api/FlemingApiClient";
import { MessageTemplate, TemplateAttachment } from "api/FlemingDtos";
import { createPreviewUrl } from "app/messageTemplates/MessageTemplatesHelper";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "app/questionnaireScoreboard/constants";
import { MessageTemplateTokens } from "shared/Templates";
import { useSingleTeamQuery } from "shared/concierge/usersAndTeams/hooks";
import { useAppSelector, useCurrentOrgName } from "store/hooks";

import { useMessageTemplates } from "../../MessageTemplates/MessageTemplates.hooks";
import {
    ObjectUrl,
    Template,
} from "../../MessageTemplates/MessageTemplates.types";
import { MessageTemplatesQuickView } from "../../MessageTemplates/MessageTemplatesQuickView";
import { AttachFileRow } from "../AttachFileRow/AttachFileRow";
import { TEMPLATE_PARAM_KEY, VIEW_PARAM_KEY } from "../Conversation.constants";
import { generateMessage, getMessageSize } from "../Conversation.helpers";
import { PageViewTypes } from "../Conversation.types";
import { EnablePatientResponseToggle } from "../EnablePatientResponseToggle/EnablePatientResponseToggle";
import { AttachFileButton } from "./AttachFileButton";
import { CharacterCount } from "./CharacterCount";
import {
    FILE_UPLOAD_ALLOWED_EXTENSIONS,
    FILE_UPLOAD_MAX_FILES,
    FILE_UPLOAD_MAX_SIZE_BYTES,
    FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE,
    MAX_CHAR_COUNT,
} from "./ComposeArea.constants";
import {
    StyledCompose,
    StyledComposeContainer,
    StyledPatientResponseAndSendButton,
    StyledReponseCopyContainer,
    StyledTemplatesAndQuestionnaireButton,
    StyledWriteAMessageButton,
} from "./ComposeArea.styles";
import { AttachmentDisplay, ComposeAreaProps } from "./ComposeArea.types";
import { Textarea } from "./Textarea";

export const ComposeArea = ({
    workspaceId,
    messageParts,
    handleSendMessage,
    isLoading,
    isOpen,
    setIsOpen,
    canTogglePatientResponse,
    scheduleButton,
    sendButtonText,
    ...props
}: ComposeAreaProps) => {
    const [showMessageTemplates, setShowMessageTemplates] = useState(false);
    const [showCreateTemplateForm, setShowCreateTemplateForm] = useState(false);
    const { body, greeting, signature, header } = messageParts;
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const viewParam = params.get(VIEW_PARAM_KEY);
    const presetTemplateSearchParam = params.get(TEMPLATE_PARAM_KEY);
    const [enablePatientResponse, setEnablePatientResponse] = useState(false);

    const [attachments, setAttachments] = useState<AttachmentDisplay[]>([]);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
        null,
    );
    const safeAsync = useSafeAsync();
    const userEmail = useAppSelector(({ account }) => account.user?.email);
    const emptyMessage = generateMessage({
        greeting,
        signature,
    });
    const [message, setMessage] = useState(
        generateMessage({
            greeting,
            body,
            signature,
        }),
    );
    const [sendMessageError, setSendMessageError] = useState("");
    const [isSending, setIsSending] = useState(false);

    const { presetTemplates, isPresetTemplatesLoading } =
        useMessageTemplates(workspaceId);

    // After a questionnaire gets selected, reset the other bits of state
    useEffect(() => {
        if (props.canUseQuestionnaires) {
            if (props.selectedQuestionnaire) {
                setSelectedTemplate(null);
                setEnablePatientResponse(false);
                setAttachments([]);
            }
        }
    }, [props.canUseQuestionnaires, props.selectedQuestionnaire]);

    // The parent wants us to override the textarea content
    useEffect(() => {
        setMessage(
            generateMessage({
                greeting: messageParts.greeting,
                body: messageParts.body,
                signature: messageParts.signature,
            }),
        );
    }, [messageParts]);

    useEffect(() => {
        if (isPresetTemplatesLoading || presetTemplateSearchParam === null)
            return;

        const foundTemplate = presetTemplates.find(
            ({ title }) => title === presetTemplateSearchParam,
        );

        if (foundTemplate) {
            setMessage(
                generateMessage({
                    greeting,
                    body: foundTemplate.body,
                    signature,
                }),
            );
            setSelectedTemplate({
                ...foundTemplate,
                isPreset: true,
            });
        } else {
            Log.error(
                "Trying to open conversation with a preset-template search param, but template not found",
                {
                    tags: { presetTemplate: presetTemplateSearchParam },
                },
            );
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [presetTemplateSearchParam, presetTemplates, isPresetTemplatesLoading]);

    const floreyResponseTeamQuery = useSingleTeamQuery(
        props.selectedQuestionnaire?.defaultAssigneeTeamId.toString(),
    );

    const workspaceName = useCurrentOrgName();

    const mapTemplateAttachmentToAttachmentDisplay = (
        templateAttachment: TemplateAttachment,
        templateId?: number | null,
    ): AttachmentDisplay => ({
        name: templateAttachment.fileName,
        size: templateAttachment.fileSize,
        id: templateAttachment.documentId,
        previewUrl: createPreviewUrl({
            orgId: workspaceId,
            documentId: templateAttachment.documentId,
            templateId: templateId || null,
        }),
        fromTemplate: true,
    });

    const replaceAttachmentsWithTemplateAttachments = (
        template: Template | MessageTemplate,
    ) => {
        setAttachments(
            (template.attachedDocuments || []).map((attachment) => {
                return mapTemplateAttachmentToAttachmentDisplay(
                    attachment,
                    template.id,
                );
            }),
        );
    };

    const onChangeEnablePatientResponse = () => {
        const newValue = !enablePatientResponse;
        setEnablePatientResponse(newValue);
    };

    const textareaOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setMessage(e.currentTarget.value);
        setSendMessageError("");
    };

    const uploadFileFunction = async (
        file: File,
    ): Promise<FileUploadResponse> => {
        setIsUploadingFile(true);
        const response = await FlemingApiClient.documentUpload({
            file,
            organisationId: workspaceId,
        });

        let result: FileUploadResponse;
        if (response.success === false || response.result === null) {
            Log.warn("Unable to upload user file in patient conversation.", {
                tags: { "api.response.error": response.error ?? "unknown" },
            });

            toast(
                <Feedback title="Unable to upload file" colour="error">
                    <Text>Please try again</Text>
                </Feedback>,
            );

            result = {
                success: false,
                attachmentId: "",
            };
        } else {
            props.onAttachFileClick && props.onAttachFileClick();

            result = {
                success: true,
                attachmentId: response.result.id,
            };
        }

        setIsUploadingFile(false);

        return result;
    };

    const onSuccessCreateMessageTemplate = (template: MessageTemplate) => {
        setMessage(
            generateMessage({
                greeting,
                body: template.body,
                signature,
            }),
        );
        replaceAttachmentsWithTemplateAttachments(template);
        props.canUseQuestionnaires && props.unSelectQuestionnaire();

        setSelectedTemplate({
            id: template.id || undefined,
            body: template.body,
            title: template.title,
            isPreset: false,
            attachedDocuments: template.attachedDocuments || [],
        });

        setShowMessageTemplates(false);
    };

    const goToCreateMessageTemplate = () => {
        setShowMessageTemplates(true);
        setShowCreateTemplateForm(true);
    };

    const onClickUseTemplate = (template: Template) => {
        props.canUseQuestionnaires && props.unSelectQuestionnaire();
        setMessage(
            generateMessage({
                greeting,
                body: template.body,
                signature,
            }),
        );

        if (
            template.id &&
            template.attachedDocuments &&
            template.attachedDocuments.length > 0
        ) {
            replaceAttachmentsWithTemplateAttachments(template);
        } else {
            setAttachments([]);
        }
        setSelectedTemplate(template);
        setShowMessageTemplates(false);
    };

    const goToMessageTemplates = () => {
        setShowMessageTemplates(true);
    };

    useEffect(() => {
        switch (viewParam) {
            case PageViewTypes.MessageTemplates: {
                return goToMessageTemplates();
            }
            case PageViewTypes.CreateTemplate: {
                return goToCreateMessageTemplate();
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const { isSizeValid, messageSize } = getMessageSize({
        message,
        header,
        workspaceName,
        isPatientResponseEnabled: enablePatientResponse,
        numberOfAttachments: attachments.length,
        includesQuestionnaire: !!props.selectedQuestionnaire,
    });

    useEffect(() => {
        if (!isSizeValid) {
            setSendMessageError(
                `Sorry, your message must be equal to or less than ${MAX_CHAR_COUNT} characters (currently ${messageSize})`,
            );
        } else {
            setSendMessageError("");
        }
    }, [isSizeValid, messageSize]);

    const getTextareaFooterValue = () => {
        if (!!props.selectedQuestionnaire) {
            return `[Questionnaire link will auto-generate in the message]\n${workspaceName}`;
        }

        if (enablePatientResponse && attachments.length > 0) {
            return `TO RESPOND or VIEW ATTACHMENTS, please follow this link: [link will appear here]\n${workspaceName}`;
        }

        if (enablePatientResponse) {
            return `TO RESPOND, please follow this link: [link will appear here]\n${workspaceName}`;
        }

        if (attachments.length > 0) {
            return `To view your attachment, please follow this link: [link will appear here]\n${workspaceName}`;
        }

        return workspaceName;
    };

    const onFileUploadSuccess = ({
        file,
        attachmentId,
    }: {
        file: File;
        attachmentId: string;
    }) => {
        setAttachments([
            ...attachments,
            {
                name: file.name,
                size: file.size,
                id: attachmentId,
                previewUrl: window.URL.createObjectURL(file) as ObjectUrl,
                fromTemplate: false,
            },
        ]);
    };

    const handleRemoveAttachment = (attachmentId: string) => {
        const foundAttachment = attachments.find(
            (file) => file.id === attachmentId,
        );

        if (foundAttachment?.fromTemplate && selectedTemplate) {
            setSelectedTemplate({
                ...selectedTemplate,
                attachedDocuments: [],
            });
        }
        setAttachments(attachments.filter((file) => file.id !== attachmentId));
    };

    const getAttachFileInfoText = () => {
        if (attachments.length === 0) {
            return `Max size ${FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE}`;
        }

        if (attachments.length === FILE_UPLOAD_MAX_FILES) {
            return "You have 0 file(s) left";
        }
    };

    const getIsValidMessage = () => {
        const isMessageEmpty = message === emptyMessage;
        const hasFieldToken = message.includes(
            MessageTemplateTokens.FieldToken,
        );
        if (isMessageEmpty || hasFieldToken) {
            setSendMessageError("Please complete message text");

            return false;
        }

        return true;
    };

    const onSend = async () => {
        const isValidMessage = getIsValidMessage();

        setIsSending(true);

        let success: boolean;

        if (!isValidMessage) {
            // We do this so the parent component is able to continue with any validation it might do
            await safeAsync(handleSendMessage({ isValidMessage: false }));
            success = false;
        } else {
            const result = await safeAsync(
                handleSendMessage({
                    message,
                    isValidMessage,
                    uploadedFileIds: attachments
                        .filter((attachment) => !attachment.fromTemplate)
                        .map(({ id }) => id),
                    template: selectedTemplate,
                    questionnaire: props.canUseQuestionnaires
                        ? props.selectedQuestionnaire
                        : null,
                    enablePatientResponse,
                    messageLength: messageSize,
                }),
            );

            success = result.success;
        }

        if (success) {
            setAttachments([]);
            setSelectedTemplate(null);
            props.canUseQuestionnaires && props.unSelectQuestionnaire();
            setMessage(emptyMessage);
        }

        setIsSending(false);
    };

    const messageBody = message
        .replace(signature, "")
        .replace(greeting ?? "", "")
        .trim();

    /* We only want to display message templates when the compose area is open. It should close when
the compose area collapses. */
    const shouldShowMessageTemplatesQuickView = isOpen && showMessageTemplates;

    return (
        <StyledComposeContainer data-testid="compose-container">
            {!isOpen && setIsOpen ? (
                <StyledWriteAMessageButton
                    theme="primary"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                    text={
                        messageBody ? "Continue writing..." : "Write a message"
                    }
                    icon={{ name: "Pencil" }}
                    dimension="large"
                    type="button"
                />
            ) : (
                <>
                    <Flex
                        gap="1.5"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <Text skinny variant="label">
                            Message:
                        </Text>
                        <Flex gap="1" flexWrap={"wrap"}>
                            <StyledTemplatesAndQuestionnaireButton
                                theme="secondary"
                                dimension="medium"
                                text="See template options"
                                icon={{ name: "Document", colour: "blue" }}
                                onClick={goToMessageTemplates}
                                data-userflow-id="see-template-options-button"
                            />
                            {props.canUseQuestionnaires &&
                                props.questionnaireButton}
                        </Flex>
                    </Flex>
                    <StyledCompose
                        canCompose={!isSending}
                        canSend={
                            !isLoading && !sendMessageError && !isUploadingFile
                        }
                        isSending={isSending}
                    >
                        <Textarea
                            onChange={textareaOnChange}
                            messageParts={{
                                header,
                                body: message,
                                footer: getTextareaFooterValue(),
                            }}
                            disabled={isSending}
                            data-userflow-id="message-patient-textbox"
                        />
                        <Flex
                            justifyContent="space-between"
                            flexDirection="row-reverse"
                        >
                            {isSizeValid && (
                                <CharacterCount
                                    currentCount={messageSize}
                                    maxCount={MAX_CHAR_COUNT}
                                />
                            )}
                            {sendMessageError && (
                                <FormFieldFeedback
                                    variant="error"
                                    text={sendMessageError}
                                />
                            )}
                        </Flex>
                        {attachments.map((attachment, index) => (
                            <AttachFileRow
                                key={`${attachment.id}-${index}`}
                                attachmentId={attachment.id}
                                fileName={attachment.name}
                                fileSize={attachment.size}
                                previewUrl={attachment.previewUrl}
                                handleRemoveFile={handleRemoveAttachment}
                                isControllable
                                isFromTemplate={attachment.fromTemplate}
                            />
                        ))}
                        <StyledReponseCopyContainer>
                            {!!props.selectedQuestionnaire && (
                                <>
                                    {props.displayQuestionnaireResponseBanner &&
                                        floreyResponseTeamQuery.data && (
                                            <Feedback
                                                colour="information"
                                                iconName="Assigned"
                                                title={`Response assigned to '${floreyResponseTeamQuery.data.displayName}'`}
                                            />
                                        )}
                                    {props.selectedQuestionnaire.id ===
                                        PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID && (
                                        <Feedback
                                            colour="information"
                                            iconName="Clock"
                                            title="Patients have 7 days to reply. They will be sent reminders on day 3 and 6"
                                        />
                                    )}
                                </>
                            )}
                            <Flex
                                flexWrap="wrap"
                                justifyContent="space-between"
                                gap="1.5"
                            >
                                {props.canUseAttachFiles && (
                                    <Flex gap="1" alignItems="center">
                                        <AttachFileButton
                                            infoText={getAttachFileInfoText()}
                                            disabled={attachments.length > 0}
                                            allowedFileExtensions={
                                                FILE_UPLOAD_ALLOWED_EXTENSIONS
                                            }
                                            maxAllowedFileSize={{
                                                size: FILE_UPLOAD_MAX_SIZE_BYTES,
                                                humanReadable:
                                                    FILE_UPLOAD_MAX_SIZE_HUMAN_READABLE,
                                            }}
                                            uploadFileFunction={
                                                uploadFileFunction
                                            }
                                            onFileUploadSuccess={
                                                onFileUploadSuccess
                                            }
                                        />
                                    </Flex>
                                )}
                                <StyledPatientResponseAndSendButton>
                                    {canTogglePatientResponse && (
                                        <EnablePatientResponseToggle
                                            enabled={enablePatientResponse}
                                            onChange={
                                                onChangeEnablePatientResponse
                                            }
                                            userEmail={userEmail}
                                        />
                                    )}
                                    <Flex gap="1.5">
                                        {scheduleButton}
                                        <Compose.SendButton
                                            onSend={onSend}
                                            customButton={
                                                <Button
                                                    icon={{ name: "Send" }}
                                                    text={
                                                        sendButtonText ?? "Send"
                                                    }
                                                    data-userflow-id="batch-message-trust-send-button"
                                                />
                                            }
                                        />
                                    </Flex>
                                </StyledPatientResponseAndSendButton>
                            </Flex>
                        </StyledReponseCopyContainer>
                    </StyledCompose>
                </>
            )}
            {shouldShowMessageTemplatesQuickView && (
                <MessageTemplatesQuickView
                    onClose={() => setShowMessageTemplates(false)}
                    workspaceId={workspaceId}
                    onClickUseTemplate={onClickUseTemplate}
                    onSuccessCreateMessageTemplate={
                        onSuccessCreateMessageTemplate
                    }
                    patientGreeting={greeting}
                    showCreateTemplateForm={showCreateTemplateForm}
                />
            )}
        </StyledComposeContainer>
    );
};
