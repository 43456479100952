import { Log } from "@accurx/shared";
import { api } from "domains/concierge/internal/api/patientMessaging";
import { useConciergeDispatch } from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { MessagePatientProps } from "domains/concierge/types/messagePatient.types";
import {
    UpdatedConversation,
    getConversationForSaveToRecord,
} from "domains/concierge/util/getConversationForSaveToRecord";

export type SendMessageVariables = MessagePatientProps & {
    patientToken?: string | null;
    useMobileNumberFromPds: boolean;
    shouldSaveToRecord: boolean;
};

/** Use patient messaging mutation */
export const useSendMessageMutation = (
    options?: UseMutationOptions<
        SendMessageVariables,
        UpdatedConversation,
        Error
    >,
) => {
    const dispatch = useConciergeDispatch();

    return useMutation<SendMessageVariables, UpdatedConversation, Error>(
        "sendMessage",
        async (variables) => {
            const {
                patientToken,
                useMobileNumberFromPds,
                shouldSaveToRecord,
                ...params
            } = variables;
            /**
             * api.messagePatientWithToken is called when a PDS search has occured and a patient token is return with the search data. We use that token to authenicate against and post the message
             */
            if (patientToken) {
                const { updates } = await api.messagePatientWithToken({
                    ...params,
                    patientToken,
                    useMobileNumberFromPds,
                });

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:SendMessage:WithToken",
                        ...updates,
                    }),
                );

                return getConversationForSaveToRecord(updates.conversations);
            }

            /**
             * api.messagePatientWithExternalIdentity is called when no patient token is present and the external identity is used instead to authenicate against
             */
            if (params.patient.externalIds.length > 0) {
                const { updates } =
                    await api.messagePatientWithExternalIdentity(params);

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:SendMessage:WithId",
                        ...updates,
                    }),
                );

                return getConversationForSaveToRecord(updates.conversations);
            }

            Log.error(
                "Could not send message for unknown reason! Patient doesn't have a token or nhsnumber",
                { tags: { product: "Inbox" } },
            );
            return Promise.reject({ message: "Unknown error" });
        },
        {
            ...options,
            // Figured it better to allow the user to handle the connection errors themselves. React query default to online meaning it waits for a connection to come back before attempting to send the message. I think it's safer for the user to know the message hasn't been sent so they can check their PCs network connection rather then thinking it has been sent.
            networkMode: "always",
        },
    );
};
