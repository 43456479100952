import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { AutoFocusHeading } from "domains/inbox/components/AutoFocusHeading/AutoFocusHeading";
import { useLayout } from "domains/inbox/components/Layout/context";
import { useViewportSize } from "domains/inbox/hooks/util";
import { HelpPopover } from "layouts/UnifiedLayout/PrimaryNavigation/HelpPopover/HelpPopover";

import {
    StyledContainer,
    StyledHeadingWrap,
    StyledTitledContainer,
    StyledTitledContainerInner,
    StyledTitledContainerInnerCentered,
} from "./ConversationListHeader.styles";

export type ConversationListHeaderProps = {
    heading: JSX.Element;
    notifications?: JSX.Element;
    paddingBottom?: number;
};

export const ConversationListHeader = ({
    heading,
    notifications,
    paddingBottom = 0,
}: ConversationListHeaderProps) => {
    const size = useViewportSize();
    const shouldShowMenuButton = size !== "lg" && size !== "xl";
    const { leftPane } = useLayout();

    const isUnifiedNavigationEnabled = useFeatureFlag("UnifiedNavigation");

    if (isUnifiedNavigationEnabled) {
        return (
            <UnifiedHeader
                heading={heading}
                notifications={notifications}
                paddingBottom={paddingBottom}
            />
        );
    }

    return (
        <StyledContainer pb={paddingBottom}>
            <UI.Flex justifyContent="flex-start" alignItems="center">
                {shouldShowMenuButton && (
                    <UI.Item>
                        <nav>
                            <UI.Button
                                theme="transparent"
                                dimension="large"
                                icon={{
                                    name: "Menu",
                                    title: "Open Sidebar navigation",
                                    id: "menu-nav",
                                }}
                                onClick={leftPane.toggle}
                            />
                        </nav>
                    </UI.Item>
                )}
                <StyledTitledContainer>
                    <StyledTitledContainerInner>
                        <StyledHeadingWrap>
                            <AutoFocusHeading as="h1" variant="subtitle" skinny>
                                {heading}
                            </AutoFocusHeading>
                        </StyledHeadingWrap>
                        {notifications && <UI.Item>{notifications}</UI.Item>}
                    </StyledTitledContainerInner>
                </StyledTitledContainer>
            </UI.Flex>
        </StyledContainer>
    );
};

/**
 * For experimental purposes - should be kept in sync with the above
 */
export const UnifiedHeader = ({
    heading,
    notifications,
    paddingBottom = 0,
}: ConversationListHeaderProps) => {
    const size = useViewportSize();
    const shouldShowMenuButton = size !== "lg" && size !== "xl";
    const { leftPane } = useLayout();

    return (
        <StyledContainer pt={1} pb={paddingBottom}>
            <UI.Flex justifyContent="flex-start" alignItems="center">
                {shouldShowMenuButton && (
                    <UI.Item>
                        <nav>
                            <UI.Button
                                theme="transparent"
                                icon={{
                                    name: "Menu",
                                    title: "Open Sidebar navigation",
                                    id: "menu-nav",
                                }}
                                onClick={leftPane.toggle}
                            />
                        </nav>
                    </UI.Item>
                )}
                <StyledTitledContainer>
                    <StyledTitledContainerInnerCentered>
                        <StyledHeadingWrap>
                            <AutoFocusHeading as="h1" variant="subtitle" skinny>
                                {heading}
                            </AutoFocusHeading>
                        </StyledHeadingWrap>
                        {notifications && <UI.Item>{notifications}</UI.Item>}
                    </StyledTitledContainerInnerCentered>
                </StyledTitledContainer>
                {["xs", "sm"].includes(size) && (
                    <HelpPopover>
                        <UI.Button
                            theme="transparent"
                            icon={{ name: "Question", title: "Help" }}
                        />
                    </HelpPopover>
                )}
            </UI.Flex>
        </StyledContainer>
    );
};
