import { PatientThreadFloreyResponseNote } from "shared/concierge/conversations/tickets/types/dto.types";
import {
    FloreyResponseConversationItem,
    PatientSingleResponseConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    mapBaseConversationItem,
    mapPatientResponseSections,
} from "./conversationItem.helpers";

/**
 * Maps ticket Florey response to conversation Florey responses
 *
 * This can be both patient single response or Florey responses
 *
 * @param ticketFloreyItem Florey response as it's sent from the API
 * @returns mapped Florey response conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapFloreyResponseItem(
    ticketFloreyItem: PatientThreadFloreyResponseNote,
):
    | FloreyResponseConversationItem
    | PatientSingleResponseConversationItem
    | undefined {
    if (!ticketFloreyItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketFloreyItem.message.responses) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    // If this is empty it means that the patient didn't respond in time
    const responses = ticketFloreyItem.message.responses;
    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const isPatientSingleResponse =
        ticketFloreyItem.message.isPatientSingleResponse;

    const baseConversationItem = mapBaseConversationItem(ticketFloreyItem);

    if (isPatientSingleResponse) {
        return {
            ...baseConversationItem,
            contentType: "PatientSingleResponse",
            sections: result.sections,
            createdBy: { type: "Patient" },
        };
    }

    return {
        ...baseConversationItem,
        contentType: "FloreyResponseNote",
        createdBy: { type: "Patient" },
        floreyName: ticketFloreyItem.message.floreyName || null,
        patientThreadMarkdown: ticketFloreyItem.message.patientThreadMarkdown,
        sections: result.sections,
    };
}
