import React, { ChangeEvent, FormEvent, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Link,
    RadioGroup,
    Text,
} from "@accurx/design";
import { EmailAddressHelper, MobileNumberHelper } from "@accurx/shared";

import { StyledFooterLink } from "../TwoFactor.styles";
import { TwoFactorMethod } from "../TwoFactorModels";
import { BackButton } from "../shared/FooterButtons";
import { StyledForm } from "./TwoFactorChooseMethod.styles";

export type TwoFactorChooseMethodProps = {
    prevStep: (() => void) | null;
    nextStep: ((inputValue: string) => void) | null;
    currentChosenMethod: TwoFactorMethod;
    handleChangeMethod: (value: TwoFactorMethod) => void;
    error: string;
    requestLoading: boolean;
    /** The email that the current user is logged in as */
    currentUserEmail: string;
};

export const TwoFactorChooseMethod = ({
    prevStep,
    nextStep,
    currentChosenMethod,
    handleChangeMethod,
    error,
    requestLoading,
    currentUserEmail,
}: TwoFactorChooseMethodProps): JSX.Element => {
    const [isEdit, setIsEdit] = useState(false);
    const [mobileNumberInputValue, setMobileNumberInputValue] = useState("");
    const [mobileNumberInputError, setMobileNumberInputError] = useState("");

    const [emailInputValue, setEmailInputValue] = useState("");
    const [emailInputError, setEmailInputError] = useState("");

    const handleMobileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setMobileNumberInputValue(e.target.value);
        setMobileNumberInputError("");
        setIsEdit(true);
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmailInputValue(e.target.value);
        setEmailInputError("");
        setIsEdit(true);
    };

    const validateMobileNumber = (): string => {
        const mobileNumberToUse = mobileNumberInputValue.replace(/\s/g, "");
        const isMobileNumberValid = MobileNumberHelper.validatePhoneNumber(
            mobileNumberToUse,
            true,
        );

        if (!isMobileNumberValid) {
            setMobileNumberInputError("Please enter a valid UK mobile number");
            return "";
        }
        return mobileNumberToUse;
    };

    const validateEmail = (): string => {
        const emailToUse = emailInputValue.replace(/\s/g, "");
        const isValid = EmailAddressHelper.isValidEmailAddress(emailToUse);

        if (!isValid) {
            setEmailInputError("Please enter a valid email address");
            return "";
        }

        if (emailToUse === currentUserEmail) {
            setEmailInputError(
                "This email address is the same as your account email, please use a different one",
            );
            return "";
        }

        return emailToUse;
    };

    const handleRegister = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (currentChosenMethod === TwoFactorMethod.Mobile) {
            const mobileNumber = validateMobileNumber();
            if (mobileNumber) {
                setIsEdit(false);
                nextStep && nextStep(mobileNumber);
            }
        } else {
            const email = validateEmail();
            if (email) {
                setIsEdit(false);
                nextStep && nextStep(email);
            }
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleChangeMethod(e.target.value as TwoFactorMethod);
        setIsEdit(false);
    };

    const handleGoBack = (): void => {
        handleChangeMethod(TwoFactorMethod.Mobile); // By default always land on Mobile
        prevStep && prevStep();
    };

    const renderInput = (): JSX.Element => {
        if (currentChosenMethod === TwoFactorMethod.Mobile) {
            const displayInputError = mobileNumberInputError !== "";
            return (
                <>
                    <FormFieldWrapper
                        label="Mobile phone number"
                        labelProps={{
                            htmlFor: "mobileNumber",
                        }}
                        errors={
                            displayInputError
                                ? [mobileNumberInputError]
                                : undefined
                        }
                    >
                        <Input
                            id="mobileNumber"
                            type="tel"
                            className="form-control"
                            onChange={handleMobileChange}
                            value={mobileNumberInputValue}
                            placeholder="Enter your number"
                            readOnly={requestLoading}
                            hasErrors={displayInputError}
                        />
                    </FormFieldWrapper>
                    <Feedback
                        colour="information"
                        title="This must be a phone you can receive SMS messages to whilst using Accurx"
                    />
                </>
            );
        }

        const displayInputError = emailInputError !== "";
        return (
            <>
                <FormFieldWrapper
                    label="Email address"
                    labelProps={{
                        htmlFor: "emailaddress",
                    }}
                    errors={displayInputError ? [emailInputError] : undefined}
                >
                    <Input
                        id="emailaddress"
                        type="email"
                        className="form-control"
                        onChange={handleEmailChange}
                        value={emailInputValue}
                        placeholder="Enter email address"
                        readOnly={requestLoading}
                        hasErrors={displayInputError}
                    />
                </FormFieldWrapper>
                <Feedback
                    colour="information"
                    title="This address must be different to the one you use for your account"
                />
            </>
        );
    };

    // Only display the error if there is one and we are not editing the form
    const displayApiError = error !== "" && !isEdit;

    return (
        // Form is here to handle the "enter" key
        <StyledForm onSubmit={handleRegister} noValidate>
            <Card spacing={3}>
                <Flex flexDirection="column" gap="5">
                    <Flex flexDirection="column" gap="3">
                        <RadioGroup
                            formFieldWrapperProps={{
                                label: "Choose method for two factor authentication",
                                labelProps: { className: "sr-only" },
                            }}
                            onChange={handleChange}
                            checkedValue={currentChosenMethod}
                            theme="bordered"
                            columns={2}
                            name="choose-method"
                            radioInputs={[
                                {
                                    value: TwoFactorMethod.Mobile,
                                    id: TwoFactorMethod.Mobile,
                                    label: "Mobile phone",
                                },
                                {
                                    value: TwoFactorMethod.Email,
                                    id: TwoFactorMethod.Email,
                                    label: "Email address",
                                },
                            ]}
                        >
                            <Text variant="label" as="h2" skinny>
                                Choose where to receive your verification code
                            </Text>
                        </RadioGroup>
                        {renderInput()}
                        {displayApiError && (
                            <Feedback colour="error" title="An error occured">
                                <Text skinny>{error}</Text>
                            </Feedback>
                        )}
                    </Flex>
                    <Flex
                        justifyContent={prevStep ? "space-between" : "flex-end"}
                    >
                        {prevStep && <BackButton onClick={handleGoBack} />}
                        <Button
                            text="Send code"
                            type="submit"
                            disabled={requestLoading}
                        />
                    </Flex>
                </Flex>
            </Card>
            <StyledFooterLink>
                <Link href="https://www.accurx.com/security" openInNewTab>
                    Learn more about account security
                    <Link.Icon />
                </Link>
            </StyledFooterLink>
        </StyledForm>
    );
};
