export type ComposeNoteErrors = "NOTE_TOO_LONG" | "NOTE_EMPTY";

export const composeNoteValidation = (
    noteText: string,
): null | ComposeNoteErrors => {
    if (noteText.trim().length === 0) {
        return "NOTE_EMPTY";
    }

    if (noteText.length > 4000) {
        return "NOTE_TOO_LONG";
    }

    return null;
};
