import { UserSettings } from "app/organisations/shared/types";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { useAppSelector } from "store/hooks";

export const useCurrentUser = (): Optional<UserSettings> => {
    const user = useAppSelector(({ account }) => account);
    const currentOrg = OrganisationHelper.getOrganisation(user);

    return {
        settings: useAppSelector(({ account }) => account.user),
        isAdmin: OrganisationHelper.getIsAdminOrgUser(currentOrg),
        isApproved: OrganisationHelper.getIsApprovedOrgUser(user),
    };
};
