import { createContext } from "react";

type OnlineUsersContextValue = {
    usersOnline: Set<string>;
};

const initialState: OnlineUsersContextValue = {
    usersOnline: new Set(),
};

export const OnlineUsersContext =
    createContext<OnlineUsersContextValue>(initialState);
