type PatientName = {
    firstName: string | null;
    familyName: string | null;
};

/**
 * Used to format patient name when generating confirmation of SMS delivery emails.
 */
export const formatPatientDisplayName = ({
    firstName,
    familyName,
}: PatientName): string | null => {
    if (familyName && firstName) {
        return `${familyName.toUpperCase()}, ${firstName}`;
    }
    if (familyName) {
        return familyName;
    }

    return firstName ?? null;
};
