export enum switchOptionNames {
    switchRepeatPrescription,
    switchPrescriptionRedirect,
    switchPrescriptionForm,
    switchDisablePractice,
    switchOutOfHours,
    switchAllowAdminRequests,
}

export const outOfHoursDefaults: {
    [key: string]: {
        startHour: number;
        endHour: number;
        meridiem: "am" | "pm";
        default: number;
    };
} = {
    from: {
        startHour: 3,
        endHour: 12,
        meridiem: "pm",
        default: 17,
    },
    to: {
        startHour: 5,
        endHour: 10,
        meridiem: "am",
        default: 8,
    },
};

export const patientTriageUrls = {
    patientTriage: "patient-triage",
    patientTriageSetUp: "patient-triage-setup",
    patientTriageSettings: "patient-triage-settings",
    patientTriageUsage: "patient-triage-usage",
    patientTriageDashboard: "patient-triage-dashboards",
};

export const EDITOR_COUNT_EXTENSION_MAXIMUM = 9999;
