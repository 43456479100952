export const TAB_PAGES = {
    overview: "overview",
    allConversations: "all-conversations",
    myConversations: "my-conversations",
    documents: "documents",
} as const;

export type PatientConversationsTabPageType =
    | typeof TAB_PAGES.allConversations
    | typeof TAB_PAGES.myConversations;

export type TabPageType = (typeof TAB_PAGES)[keyof typeof TAB_PAGES];
