import { ChangeEvent, MouseEvent, useRef } from "react";

import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { DropdownItem } from "domains/compose/components/DropdownItem";

import { StyledInvisibleFileInput } from "./AttachFileFromDesktopButton.styles";

export const AttachFileFromDesktopButton = ({
    isDisabled,
    onChange,
    onClick,
    accepts,
}: {
    /** Whether the user is able to attach a file to compose */
    isDisabled: boolean;
    /** What to do when user selects a file or cancels */
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /**
     * What to do right after the user clicks on the button
     */
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
    /** List of file extensions that are accepted by the system
     *
     * Should be a comma separated string of file extensions
     * i.e. ".png,.jpg"
     */
    accepts: string;
}) => {
    const isMessageComponentV1_1Enabled = useFeatureFlag(
        "MessageComponentV1_1",
    );

    const fileInputRef = useRef<HTMLInputElement>(null);
    return (
        <UI.Flex flexDirection="column" gap="1">
            <UI.Flex alignItems="center" gap="1">
                <DropdownItem.Button
                    aria-label={
                        isMessageComponentV1_1Enabled
                            ? "Upload desktop file"
                            : "Upload file from desktop"
                    }
                    text={
                        isMessageComponentV1_1Enabled
                            ? "Desktop file"
                            : "File from desktop"
                    }
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isDisabled}
                    theme={"transparent"}
                    icon={{
                        name: isMessageComponentV1_1Enabled
                            ? "Paperclip"
                            : "Upload",
                        colour: isDisabled ? "stone" : "zinc",
                    }}
                />
                <StyledInvisibleFileInput
                    // Only upload one file at a time
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    accept={accepts}
                    id="fileUpload"
                    data-testid="fileUpload"
                    disabled={isDisabled}
                    onChange={onChange}
                    onClick={(event: MouseEvent<HTMLInputElement>) => {
                        // Need to reset this on click in case we want to upload the same file twice
                        event.currentTarget.value = "";
                        onClick?.(event);
                    }}
                />
            </UI.Flex>
        </UI.Flex>
    );
};
