import { Complexity, FloreyScoreStatus } from "@accurx/api/ticket";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { z } from "zod";

import { createUniqueConversationId } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import {
    IdType,
    PatientNameUse,
    TicketIdentityType,
} from "shared/concierge/conversations/tickets/types/dto.types";

import { mapTicketPatientToPatientSummaryNoPatientIdRequired } from "./mappers";

const PatientNameSchema = z.object({
    use: z.nativeEnum(PatientNameUse),
    prefix: z.array(z.string()).optional(),
    given: z.array(z.string()).optional(),
    family: z.array(z.string()).optional(),
});

const PatientExternalIdSchema = z.object({
    type: z.nativeEnum(IdType),
    value: z.string(),
});

const PatientThreadPatientDetailsSchema = z
    .object({
        names: z.array(PatientNameSchema).optional(),
        gender: z.string().optional(),
        dateOfBirth: z.string().optional(),
        demographicsLastRefreshed: z.string().optional(),
        accuRxId: z.string().optional(),
        patientExternalIdentity: z
            .object({
                patientExternalIds: z.array(PatientExternalIdSchema).optional(),
            })
            .optional(),
    })
    .transform((dto) =>
        mapTicketPatientToPatientSummaryNoPatientIdRequired(dto),
    );

export const FloreyScoreSummarySchema = z
    .object({
        floreyEnrolmentId: z.number(),
        sentAt: z.string(),
        patient: PatientThreadPatientDetailsSchema,
        isValidated: z.boolean(),
        ticketIdentity: z.object({
            type: z.nativeEnum(TicketIdentityType),
            id: z.string(),
        }),
        status: z.string(),
        isQuestionnaireSubmitted: z.boolean(),
        complexity: z.string(),
        answeredAt: z.string().nullable(),
    })
    .transform((dto) => ({
        floreyEnrolmentId: dto.floreyEnrolmentId,
        sentAt: dto.sentAt,
        patient: dto.patient,
        validated: dto.isValidated,
        conversationId: createUniqueConversationId(dto.ticketIdentity),
        isQuestionnaireSubmitted: dto.isQuestionnaireSubmitted,
        status: dto.status,
        complexity: dto.complexity,
        answeredAt: dto.answeredAt,
    }));

export type FloreyScoreSummary = z.infer<typeof FloreyScoreSummarySchema>;

export const FiltersSchema = z
    .object({
        dateStart: z.string().optional(),
        dateEnd: z.string().optional(),
        status: z.nativeEnum(FloreyScoreStatus).array(),
        complexity: z.nativeEnum(Complexity).array(),
    })
    .refine(
        (filters) => {
            if (!filters.dateStart || !filters.dateEnd) return true;

            return filters.dateStart <= filters.dateEnd;
        },
        {
            message: "Date from should be before Date to",
            path: ["dateStart"],
        },
    )
    .refine(
        (filters) => {
            if (filters.dateStart === undefined) return true;

            return (
                filters.dateStart <=
                DateHelpers.formatDate(
                    new Date().toISOString(),
                    DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
                )
            );
        },
        {
            message: "Date from should be in the past",
            path: ["dateStart"],
        },
    )
    .refine(
        (filters) => {
            if (filters.dateEnd === undefined) return true;

            return (
                filters.dateEnd <=
                DateHelpers.formatDate(
                    new Date().toISOString(),
                    DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
                )
            );
        },
        {
            message: "Date to should be in the past",
            path: ["dateEnd"],
        },
    );
