import { MouseEventHandler, useEffect, useState } from "react";

import * as UI from "@accurx/design";
import { Accordion, FocusRingSpacing } from "@accurx/navigation";
import { ExpandingScrollableArea } from "domains/inbox/components/Sidebar/components/ExpandingScrollableArea/ExpandingScrollableArea";
import { SearchButton } from "domains/inbox/components/Sidebar/components/SearchableAccordionSection/SearchButton";
import { SearchInput } from "domains/inbox/components/Sidebar/components/SearchableAccordionSection/SearchInput";

type SearchableAccordionSectionProps = {
    renderHeader: () => JSX.Element;
    renderContent: (arg: { searchTerm: string }) => JSX.Element;
    isOpen?: boolean;
    onSearchButtonclick?: MouseEventHandler<HTMLButtonElement>;
};

export const SearchableAccordionSection = (
    props: SearchableAccordionSectionProps,
) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    useEffect(() => {
        if (!props.isOpen) {
            setIsSearchOpen(false);
            setSearchTerm("");
        }
    }, [props.isOpen]);

    return (
        <>
            <Accordion.Header>
                <UI.Flex>
                    <UI.Item flex="1">{props.renderHeader()}</UI.Item>

                    {!!props.isOpen && (
                        <UI.Item>
                            <FocusRingSpacing>
                                {isSearchOpen ? (
                                    <SearchButton
                                        aria-label="Close searchbox"
                                        onClick={() => {
                                            setIsSearchOpen(false);
                                            setSearchTerm("");
                                        }}
                                    >
                                        <UI.Icon
                                            name="Cross"
                                            colour="white"
                                            size={3}
                                        />
                                    </SearchButton>
                                ) : (
                                    <SearchButton
                                        aria-label="Open searchbox"
                                        onClick={(event) => {
                                            props.onSearchButtonclick?.(event);
                                            setIsSearchOpen(true);
                                        }}
                                    >
                                        <UI.Icon
                                            name="Search"
                                            colour="white"
                                            size={3}
                                        />
                                    </SearchButton>
                                )}
                            </FocusRingSpacing>
                        </UI.Item>
                    )}
                </UI.Flex>
            </Accordion.Header>
            <Accordion.Content>
                <ExpandingScrollableArea>
                    <Accordion.Indented>
                        {isSearchOpen && (
                            <FocusRingSpacing>
                                <SearchInput
                                    type="search"
                                    value={searchTerm}
                                    onChange={(value) => setSearchTerm(value)}
                                />
                            </FocusRingSpacing>
                        )}
                        {props.renderContent({ searchTerm })}
                    </Accordion.Indented>
                </ExpandingScrollableArea>
            </Accordion.Content>
        </>
    );
};
