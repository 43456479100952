import React from "react";

import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface VaccineApprovalModalProps {
    isApproving: boolean;
    showApprovalModal: boolean;
    onToggleModal: () => void;
    onApprove: () => void;
}

export const VaccineApprovalModal = ({
    isApproving,
    showApprovalModal,
    onToggleModal,
    onApprove,
}: VaccineApprovalModalProps): JSX.Element => (
    <Modal
        isOpen={showApprovalModal}
        toggle={onToggleModal}
        fade={false}
        backdropClassName="bg-zinc"
    >
        <ModalHeader tag="div">
            <Text variant="subtitle" as="h1" skinny>
                {isApproving ? "Join delivery site" : "Decline invite"}
            </Text>
        </ModalHeader>
        <ModalBody className={"bg-cloud"}>
            {isApproving ? (
                <>
                    <Text>
                        By joining this delivery site, any patients you invite
                        for vaccinations will be able to book appointments here.
                    </Text>
                    <Text>
                        Staff at the delivery site and other linked practices
                        will be able to see information about the patients you
                        upload (name, DoB, NHS number and phone number).
                    </Text>
                </>
            ) : (
                <>
                    <Text>
                        By declining this request, your patients will not be
                        able to book into any appointments at this site.
                    </Text>
                </>
            )}
            <Text>Do you wish to continue?</Text>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
            <Button
                text="Cancel"
                theme="secondary"
                onClick={onToggleModal}
                className="mx-1"
            />
            <Button
                text={isApproving ? "Join" : "Decline"}
                onClick={onApprove}
            />
        </ModalFooter>
    </Modal>
);
