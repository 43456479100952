import {
    AllWorkspaceInvitesResponse,
    WorkspaceInviteResponse,
} from "@accurx/api/portal";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

export const WORKSPACE_MANAGEMENT_INVITES_CACHE_KEY =
    "WorkspaceManagementInvites";

export const fetchWorkspaceInvites = (
    workspaceId: string,
): Promise<IWrappedResult<AllWorkspaceInvitesResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: "/api/portal/workspaces/:workspaceId/invites",
            params: { workspaceId },
        }),
    );

export const useWorkspaceInvitesQuery = ({
    workspaceId,
}: {
    workspaceId: number;
}) =>
    useQuery<WorkspaceInviteResponse[], Error, WorkspaceInviteResponse[]>(
        [WORKSPACE_MANAGEMENT_INVITES_CACHE_KEY, { workspaceId }],
        async () => {
            const response = await fetchWorkspaceInvites(
                workspaceId.toString(),
            );
            if (!response.result) {
                throw new Error(
                    response.error ?? "Failed to fetch workspace invites",
                );
            }
            return response.result.invites;
        },
        { refetchOnMount: true },
    );
