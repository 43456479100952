import { Accordion, Button, StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledSidebar = styled.aside<{ isOpen: boolean }>`
    flex-shrink: 0;
    position: absolute;
    width: ${Tokens.BASE.grid * 30}px;
    left: -${Tokens.BASE.grid * 30}px;
    top: 0;
    bottom: 0;
    transition: left 0.2s ease-in-out;
    border-right: 1px solid ${Tokens.COLOURS.greyscale.dishwater};
    padding: ${Tokens.SIZES[2]} ${Tokens.SIZES[3]} 0 0;
    margin-left: -${Tokens.SIZES[3]};
    margin-right: ${Tokens.SIZES[6]};
    z-index: 4;

    ${(props) =>
        props.isOpen
            ? `
            left: 0;
            right: auto;
            box-shadow: 0px 4px 16px 0px ${Tokens.COLOURS.greyscale.metal};
            clip-path: inset(0px -16px 0px -16px);
      `
            : ""}

    background-color: ${Tokens.COLOURS.greyscale.white};
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        box-shadow: none;
        background-color: ${Tokens.COLOURS.greyscale.cloud};
        border-right: none;
        padding: ${Tokens.SIZES[5]} 0 0 ${Tokens.SIZES[2]};
        margin-left: -${Tokens.SIZES[5]};
        left: auto;
        right: auto;
        bottom: auto;
        position: relative;
    }
`;

export const StyledAccordion = styled(Accordion)`
    width: 100%;
    background-color: transparent;
    border: none;
    margin: 0 ${Tokens.SIZES[2]} ${Tokens.SIZES[1]} ${Tokens.SIZES[1]};
    & button {
        border: 1px solid transparent;
        padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[1.5]};
    }
    & section {
        padding: 0;
    }
`;

export const StyledSidebarToggle = styled(Button)`
    color: ${Tokens.COLOURS.primary.blue["100"]};
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: none;
    }
    margin: 0 ${Tokens.SIZES[1]} ${Tokens.SIZES[2]} ${Tokens.SIZES[1]};
`;

export const StyledStackPanel = styled(StackPanel)`
    list-style: none;
    padding: 0;
`;
