import { Tokens } from "@accurx/design";
import { Dictionary } from "highcharts";
import groupBy from "lodash/groupBy";
import moment from "moment";

import { SeriesColumn } from "app/sharedComponents/graph/Graph.types";

import { RequestType } from "../shared/shared.constants";
import {
    getStartAndEndOfCurrentWeek,
    getStartAndEndOfPreviousWeek,
} from "../shared/shared.utils";
import { RequestWithOutcome } from "./RequestsByOutcome.types";

const groupByOutcome = (requests: Array<RequestWithOutcome>) =>
    groupBy(requests, (request) => request.outcome);

/**
 * Take in an array of the requests for a week
 * count the number of requests for each outcome and
 * return an array with the number of requests
 * for each outcome.
 * @param lastWeekData
 * @param filterRequestType
 * @param filterRequestSource
 * @returns number[]
 */
const aggregateToOutcome = (
    lastWeekData: Dictionary<RequestWithOutcome[]>,
    outcomes: string[],
    filterRequestType: string,
): Array<number> => {
    return outcomes.map((outcome) => {
        if (lastWeekData[outcome]) {
            return lastWeekData[outcome].filter(
                (request) =>
                    filterRequestType === RequestType.MedicalAndAdmin ||
                    request.type === filterRequestType,
            ).length;
        } else {
            return 0;
        }
    });
};

/**
 * This function checks the dashboard data. Splits the data by week number objects
 * returning an array of two obejcts, one for the last week and the second for the current week
 * It also returns a boolean to show if the data sets are all zero values
 */
export const aggregateDataForRequestsByOutcomeGraph = (
    triageRequests: RequestWithOutcome[],
    outcomeLabels: string[],
    filterRequestType: string,
): {
    aggregatedData: SeriesColumn[];
    isEmptyDataSet: boolean;
} => {
    const aggregatedData: SeriesColumn[] = [];

    const [previousWeekStart, previousWeekEnd] = getStartAndEndOfPreviousWeek();
    const [currentWeekStart, currentWeekEnd] = getStartAndEndOfCurrentWeek();

    const lastWeekRequests = triageRequests.filter((request) =>
        moment(request.date).isBetween(
            previousWeekStart,
            previousWeekEnd,
            "days",
            "[]",
        ),
    );
    const currentWeekRequests = triageRequests.filter((request) =>
        moment(request.date).isBetween(
            currentWeekStart,
            currentWeekEnd,
            "days",
            "[]",
        ),
    );

    const lastWeekData = groupByOutcome(lastWeekRequests);
    const lastWeekOutcomeDataArray = aggregateToOutcome(
        lastWeekData,
        outcomeLabels,
        filterRequestType,
    );
    aggregatedData.push({
        name: "Last week",
        pointWidth: 19,
        borderWidth: 2,
        color: Tokens.COLOURS.primary.blue[10],
        borderColor: Tokens.COLOURS.primary.blue[75],
        data: lastWeekOutcomeDataArray,
    });

    const currentWeekData = groupByOutcome(currentWeekRequests);
    const currentWeekOutcomeDataArray = aggregateToOutcome(
        currentWeekData,
        outcomeLabels,
        filterRequestType,
    );
    aggregatedData.push({
        name: "Current week",
        pointWidth: 19,
        borderWidth: 2,
        color: Tokens.COLOURS.primary.blue[75],
        borderColor: Tokens.COLOURS.primary.blue[75],
        data: currentWeekOutcomeDataArray,
    });

    const isEmptyDataSet =
        Math.max(...currentWeekOutcomeDataArray, 0) === 0 &&
        Math.max(...lastWeekOutcomeDataArray, 0) === 0;

    return {
        aggregatedData,
        isEmptyDataSet,
    };
};
