import { Conversation, ConversationItem } from "domains/concierge/types";
import findLast from "lodash/findLast";

export const getMostRecentItemOfType = <
    T extends ConversationItem["contentType"],
>(
    conversation: Conversation,
    type: T,
): Extract<ConversationItem, { contentType: T }> | undefined => {
    const match = findLast(
        conversation.items,
        (item): item is Extract<ConversationItem, { contentType: T }> =>
            item.contentType === type,
    );

    return match;
};
