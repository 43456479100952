import * as React from "react";

import { FeatureName } from "@accurx/auth";
import { Feedback, Option, Tabs, Text } from "@accurx/design";
import { toast } from "react-toastify";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics/ManagePracticeEvents";
import { LoadingWrapper } from "app/sharedComponents/LoadingWrapper";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import {
    LoadingStatus,
    UpdatingStatus,
    UpdatingStatusType,
} from "shared/LoadingStatus";

import { NavSubMenuComponent } from "../../navbar/NavSubMenuComponent";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import { DisableUntilRequest } from "./PatientTriage.actions";
import {
    Dashboard,
    SetupPatientTriage,
    WhatIsPatientTriage,
} from "./components";
import { PTMessage } from "./components/PTMessage";
import { PatientSelfReferral } from "./components/SettingCards/PatientSelfReferral/PatientSelfReferral";
import { WorkingHours } from "./components/SettingCards/WorkingHours/WorkingHours";
import { defaultWorkingHours } from "./components/SettingCards/WorkingHours/__data__/defaults";
import { ChangeOohPageForm } from "./components/changeOohPageForm";
import { ChangeSubmissionPageForm } from "./components/changeSubmissionPageForm";
import { ChangeSubmissionSmsForm } from "./components/changeSubmissionSmsForm";
import { ChangeWelcomePageForm } from "./components/changeWelcomePageForm";
import { DisableUntilSection } from "./components/disableUntilSection";
import { RepeatPrescriptionsServiceForm } from "./components/repeatPrescriptionServiceForm";
import { WorkingHoursSuspensionMessageForm } from "./components/workingHoursLimitMessageForm";
import { Settings, outOfHoursDefaults, switchOptionNames } from "./constants";
import { patientTriageUrls } from "./constants/PracticePatientTriage";
import {
    PatientTriageProps,
    PatientTriageState,
    TabIds,
    WorkingHoursSettings,
    WorkingHoursSuspensionMessageSettings,
} from "./types";
import { isURLValid } from "./utils";

export class PatientTriage extends React.Component<
    PatientTriageProps,
    PatientTriageState
> {
    constructor(props: PatientTriageProps) {
        super(props);

        this.state = {
            mostRecentlyClickedEdit: "",
            hasClickedEditWelcomePage: false,
            hasClickedEditSubmissionPage: false,
            hasClickedEditSubmissionSms: false,
            hasClickedEditOohPage: false,
            hasClickEditScheduleOoh: false,
            hasClickEditPTPage: false,
            welcomePageMessage: null,
            welcomePageMessageEditing: null,
            submissionPageMessage: null,
            submissionPageMessageEditing: null,
            submissionPageSms: null,
            submissionPageSmsEditing: null,
            outOfHoursPageMessage: null,
            outOfHoursPageMessageEditing: null,

            ptPageMessage: {
                disabledMessageHeading: null,
                disabledMessageBody: null,
            },
            ptPageMessageEdit: {
                disabledMessageHeading: null,
                disabledMessageBody: null,
            },

            // Suspend Patient Triage properties
            suspendPTUntil: this.props.status.disableUntilDate ?? null,
            suspendPTDisableSwitch: false,
            suspendPTAdminRequests: false,
            suspendPTDisableReason: "",
            suspendPTDisableComment: "",
            suspendPTEditing: false,
            suspendPTHasUpdated: false,
            // This object saves the original or updated state (after an api request) of the suspend PT settings, when a user makes changes to the suspended PT settings and then cancels, we copy this data back to the original properties.
            suspendPTPrevState: {
                suspendPTUntil: null,
                suspendPTAdminRequests: null,
                suspendPTDisableSwitch: null,
                suspendPTDisableReason: "",
                suspendPTDisableComment: "",
            },

            // Out of hours properties
            outOfHoursEnabled: null,
            outOfHoursSettings: {
                outOfHoursDisableFromHour: null,
                outOfHoursDisableToHour: null,
                outOfHoursSuspendPatientTriage: false,
                outOfHoursAllowAdminRequests: false,
            },
            outOfHoursPrevState: {
                outOfHoursEnabled: null,
                outOfHoursSettings: {
                    outOfHoursDisableFromHour: null,
                    outOfHoursDisableToHour: null,
                    outOfHoursSuspendPatientTriage: false,
                    outOfHoursAllowAdminRequests: false,
                },
            },
            hasUpdatedWelcomePageMessage: false,
            hasUpdatedSubmissionPageMessage: false,
            hasUpdatedSubmissionSmsMessage: false,
            hasUpdatedPTPageMessage: false,
            hasUpdatedOohPageMessage: false,
            hasUpdatedDisableUntilSettings: false,
            hasUpdatedOohSettings: false,
            pulledInitialPrescriptionValue: false,
            pulledInitialDisableUntilValue: false,
            pulledInitialOutOfHoursValue: false,
            pulledInitialWelcomePageMessage: false,
            pulledInitialSubmissionPageMessage: false,
            pulledInitialSubmissionSmsMessage: false,
            pulledInitialOohMessage: false,
            pulledInitialOohPageValue: false,
            sentLoadedAnalytics: null,
            hasUpdatedResponseTimeSettings: null,
            flagAllowAdminRequests: false,
            flagIsSet: false,
            flagPatientTriageDashboardsEnabled: false,

            // Repeat Prescription properties
            urlError: false,
            isTitleEmpty: false,
            isUrlEmpty: false,

            isPrescriptionEditing: false,
            isPrescriptionEnabled: false,
            isPrescriptionRedirectEnabled: false,
            isPrescriptionFormEnabled: false,
            prescriptionRedirectTitle: "",
            prescriptionRedirectUrl: "",
            prescriptionPrevState: {
                isPrescriptionEnabled: false,
                isPrescriptionRedirectEnabled: false,
                isPrescriptionFormEnabled: false,
                prescriptionRedirectTitle: "",
                prescriptionRedirectUrl: "",
            },
        };
    }

    componentWillUpdate(): void {
        document.title = "Manage patient triage | Accurx";
    }

    componentDidMount(): void {
        this.setState({
            sentLoadedAnalytics: false,
        });
    }

    componentDidUpdate(): void {
        // Loading status data
        const { loadingStatus, practiceId, actions, features, status } =
            this.props;
        if (loadingStatus === LoadingStatus.Initial && practiceId) {
            actions.getPatientTriageStatus(practiceId);
        }

        if (
            status.enabled &&
            status.prescriptionSettings &&
            !this.state.pulledInitialPrescriptionValue
        ) {
            const {
                isEnabled,
                isRedirectEnabled,
                redirectSettings: { isPrescriptionFormEnabled, title, url },
            } = status.prescriptionSettings;

            this.setState({
                isPrescriptionEnabled: isEnabled,
                isPrescriptionRedirectEnabled: isRedirectEnabled,
                isPrescriptionFormEnabled: isPrescriptionFormEnabled,
                prescriptionRedirectTitle: title,
                prescriptionRedirectUrl: url,
                pulledInitialPrescriptionValue: true,
                prescriptionPrevState: {
                    isPrescriptionEnabled: isEnabled,
                    isPrescriptionRedirectEnabled: isRedirectEnabled,
                    isPrescriptionFormEnabled: isPrescriptionFormEnabled,
                    prescriptionRedirectTitle: title,
                    prescriptionRedirectUrl: url,
                },
            });
        }

        // Setting up feature flag
        if (!this.state.flagIsSet && features?.length) {
            this.setState({
                flagAllowAdminRequests: IsFeatureEnabled(
                    features,
                    FeatureName.PatientTriageAllowAdminRequests,
                ),
                flagPatientTriageDashboardsEnabled: IsFeatureEnabled(
                    features,
                    FeatureName.PatientTriageDashboards,
                ),
                flagIsSet: true,
            });
        }

        // Sending analytics
        if (this.state.sentLoadedAnalytics === false) {
            const { status, accountState, analyticsState } = this.props;
            if (loadingStatus === LoadingStatus.Loaded) {
                const analyticsProps: ChainAnalyticsTracker.PatientTriagePageLoadedProps =
                    {
                        ...AnalyticsMapper.getAnalyticsLoggedInProps(
                            accountState,
                            analyticsState,
                        ),
                        isEnabled: status.enabled.toString(),
                    };
                ChainAnalyticsTracker.trackPatientTriagePageLoaded(
                    analyticsProps,
                );
                this.setState({
                    sentLoadedAnalytics: true,
                });
            }
        }
        // Out of hours props initialise with data returned from the status api
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialOutOfHoursValue
        ) {
            if (this.props.status.outOfHoursSettings) {
                this.setState({
                    outOfHoursEnabled: true,
                    outOfHoursSettings: this.props.status.outOfHoursSettings,
                });
            } else {
                this.setState({
                    outOfHoursEnabled: false,
                });
            }
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialDisableUntilValue
        ) {
            const {
                disableUntilDate,
                allowAdminRequests,
                disabledMessageHeading,
                disabledMessageBody,
            } = this.props.status;
            // status.enabled implies the API call is finished
            const disableUntil = disableUntilDate ?? null;

            this.setState({
                pulledInitialDisableUntilValue: true,
                suspendPTUntil: disableUntil,
                suspendPTDisableSwitch: !!disableUntil,
                suspendPTAdminRequests: allowAdminRequests,
                suspendPTPrevState: {
                    ...this.state.suspendPTPrevState,
                    suspendPTUntil: disableUntil,
                    suspendPTDisableSwitch: !!disableUntil,
                    suspendPTAdminRequests: allowAdminRequests,
                },
                ptPageMessage: {
                    disabledMessageHeading,
                    disabledMessageBody,
                },
            });
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialOutOfHoursValue
        ) {
            this.setState({
                pulledInitialOutOfHoursValue: true,
                outOfHoursPageMessage: this.props.status.outOfHoursPageMessage,
            });
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialWelcomePageMessage
        ) {
            this.setState({
                pulledInitialWelcomePageMessage: true,
                welcomePageMessage: this.props.status.welcomePageMessage,
            });
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialSubmissionPageMessage
        ) {
            this.setState({
                pulledInitialSubmissionPageMessage: true,
                submissionPageMessage: this.props.status.submissionPageMessage,
            });
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialSubmissionSmsMessage
        ) {
            this.setState({
                pulledInitialSubmissionSmsMessage: true,
                submissionPageSms: this.props.status.submissionPageSms,
            });
        }
        if (
            this.props.status.enabled &&
            !this.state.pulledInitialOohPageValue
        ) {
            this.setState({
                pulledInitialOohPageValue: true,
                outOfHoursPageMessage: this.props.status.outOfHoursPageMessage,
            });
        }

        if (
            this.props.triageSettingsUpdatingStatus !== UpdatingStatus.Initial
        ) {
            this.renderActionConfirmation(
                this.props.triageSettingsUpdatingStatus,
            );
            this.props.actions.resetUpdateStatus();
        }
    }

    handleSetPage(optionSelected: TabIds, url: string): void {
        if (this.props.deepLinkActivePage !== optionSelected) {
            const { accountState, analyticsState } = this.props;
            const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementItemProps =
                {
                    ...AnalyticsMapper.getAnalyticsLoggedInProps(
                        accountState,
                        analyticsState,
                    ),
                    optionSelected:
                        AnalyticsMapper.mapToPascalCase(optionSelected),
                };

            ChainAnalyticsTracker.trackPatientTriageManagementMenuItemClicked(
                analyticsProps,
            );
        }
        this.props.history.push(url);
    }

    handleSwitchOptions(
        event: React.ChangeEvent<HTMLInputElement>,
        name: switchOptionNames,
    ): void {
        const isChecked = event.target.checked;

        switch (name) {
            case switchOptionNames.switchRepeatPrescription:
                this.setState({
                    isPrescriptionEnabled: isChecked,
                });
                if (!isChecked) {
                    this.setState({
                        isPrescriptionRedirectEnabled: false,
                    });
                }
                break;

            case switchOptionNames.switchPrescriptionRedirect:
                this.setState({
                    isPrescriptionRedirectEnabled: isChecked,
                });
                break;

            case switchOptionNames.switchPrescriptionForm:
                this.setState({
                    isPrescriptionFormEnabled: isChecked,
                });
                break;

            case switchOptionNames.switchDisablePractice:
                this.setState({
                    suspendPTDisableSwitch: isChecked,
                    hasUpdatedDisableUntilSettings: false,
                    suspendPTAdminRequests: false,
                    suspendPTDisableReason: "",
                    suspendPTHasUpdated:
                        this.state.suspendPTPrevState.suspendPTDisableSwitch !==
                        isChecked,
                });
                break;

            case switchOptionNames.switchAllowAdminRequests:
                this.setState({
                    suspendPTAdminRequests: isChecked,
                    suspendPTHasUpdated:
                        this.state.suspendPTPrevState.suspendPTAdminRequests !==
                        isChecked,
                });
                break;

            case switchOptionNames.switchOutOfHours:
                this.setState({
                    outOfHoursEnabled: isChecked,
                    hasUpdatedOohSettings:
                        this.state.outOfHoursPrevState.outOfHoursEnabled !==
                        isChecked,
                });
        }
    }

    handleUpdateOnOutOfHours(): void {
        const { practiceId, actions, accountState, analyticsState } =
            this.props;
        const OutOfHoursSettings = this.state.outOfHoursSettings;
        let request;
        if (this.state.outOfHoursEnabled) {
            request = {
                OutOfHoursSettings: {
                    OutOfHoursDisableFromHour:
                        OutOfHoursSettings.outOfHoursDisableFromHour
                            ? OutOfHoursSettings.outOfHoursDisableFromHour
                            : outOfHoursDefaults.from.default,
                    OutOfHoursDisableToHour:
                        OutOfHoursSettings.outOfHoursDisableToHour
                            ? OutOfHoursSettings.outOfHoursDisableToHour
                            : outOfHoursDefaults.to.default,
                    OutOfHoursSuspendPatientTriage:
                        OutOfHoursSettings.outOfHoursSuspendPatientTriage,
                    OutOfHoursAllowAdminRequests:
                        OutOfHoursSettings.outOfHoursSuspendPatientTriage
                            ? OutOfHoursSettings.outOfHoursAllowAdminRequests
                            : false,
                },
            };
        } else {
            request = {
                OutOfHoursSettings: null,
            };
        }
        actions.setScheduledOutOfHoursFeature(practiceId, request);

        this.setState({
            outOfHoursPrevState: {
                outOfHoursEnabled: this.state.outOfHoursEnabled,
                outOfHoursSettings: this.state.outOfHoursSettings,
            },
            hasUpdatedOohSettings: false,
            hasClickEditScheduleOoh: false,
        });

        const isEnabled = !!this.state.outOfHoursEnabled;
        const genericProps: ChainAnalyticsTracker.PatientTriageManagementSettingSaveOutOfHoursProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                isEnabled: isEnabled,
                fromHour: isEnabled
                    ? OutOfHoursSettings.outOfHoursDisableFromHour ??
                      outOfHoursDefaults.from.default
                    : null,
                toHour: isEnabled
                    ? OutOfHoursSettings.outOfHoursDisableToHour ??
                      outOfHoursDefaults.to.default
                    : null,
                isSuspendAccessEnabled: isEnabled
                    ? OutOfHoursSettings.outOfHoursSuspendPatientTriage
                    : null,
                isSuspendMedicalRequestOnlyEnabled: isEnabled
                    ? OutOfHoursSettings.outOfHoursAllowAdminRequests ?? false
                    : null,
                objectName: PatientTriageSettingObjectName.SAVE,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHour(
            genericProps,
        );

        const analyticsProps: ChainAnalyticsTracker.PatientTriageOutOfHoursToggleProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                enabled: Boolean(this.state.outOfHoursEnabled).toString(),
            };
        ChainAnalyticsTracker.trackPatientTriageToggledOutOfHoursPage(
            analyticsProps,
        );
    }

    //TODO: Fix type errors. Potential to use switch statement to avoid repetition
    handleUpdateString(
        event:
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLInputElement>,
        name: string,
    ): void {
        event.preventDefault();

        if (name === Settings.PRESCRIPTION_LINK) {
            this.setState({
                prescriptionRedirectUrl: event.target.value,
                urlError: false,
                isUrlEmpty: false,
            });
        }
        if (name === Settings.PRESCRIPTION_TITLE) {
            this.setState({
                prescriptionRedirectTitle: event.target.value,
                isTitleEmpty: false,
            });
        }

        if (name === Settings.TEXT_COMMENT) {
            this.setState({
                suspendPTDisableComment: event.target.value,
                hasUpdatedDisableUntilSettings: false,
            });
        }
        if (name === Settings.TEXT_WELCOME_PAGE) {
            this.setState({
                welcomePageMessage: event.target.value,
                hasUpdatedWelcomePageMessage: false,
            });
        }
        if (name === Settings.TEXT_SUBMISSION_PAGE) {
            this.setState({
                submissionPageMessage: event.target.value,
                hasUpdatedSubmissionPageMessage: false,
            });
        }
        if (name === Settings.TEXT_SUBMISSION_SMS) {
            this.setState({
                submissionPageSms: event.target.value,
                hasUpdatedSubmissionSmsMessage: false,
            });
        }
        if (name === Settings.TEXT_OOH_PAGE) {
            this.setState({
                outOfHoursPageMessage: event.target.value,
                hasUpdatedOohPageMessage: false,
            });
        }
        if (name === Settings.TEXT_PT_HEADING) {
            this.setState({
                ptPageMessage: {
                    ...this.state.ptPageMessage,
                    disabledMessageHeading: event.target.value,
                },
                hasUpdatedPTPageMessage: false,
            });
        }
        if (name === Settings.TEXT_PT_BODY) {
            this.setState({
                ptPageMessage: {
                    ...this.state.ptPageMessage,
                    disabledMessageBody: event.target.value,
                },
                hasUpdatedPTPageMessage: false,
            });
        }
    }

    // used for displaying status underneath each setting when changed, clear when updating something else
    clearTrackingOfUpdatedSettings(): void {
        this.setState({
            hasUpdatedWelcomePageMessage: false,
            hasUpdatedSubmissionPageMessage: false,
            hasUpdatedSubmissionSmsMessage: false,
            hasUpdatedOohPageMessage: false,
            hasUpdatedDisableUntilSettings: false,
        });
    }

    // Fires when a user saves the changes to some updated copy, or when they want to reset the copy to default
    // If the reset flag is true then we pass null to the server
    handleUpdateCustomCopy(
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.FormEvent<HTMLFormElement>,
        name: string,
        resetToDefault: boolean | null,
    ): void {
        event.preventDefault();
        const { practiceId, actions, accountState, analyticsState } =
            this.props;
        this.clearTrackingOfUpdatedSettings();

        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: resetToDefault
                    ? PatientTriageSettingObjectName.RESET
                    : PatientTriageSettingObjectName.SAVE,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };

        if (name === Settings.TEXT_WELCOME_PAGE) {
            const request = {
                welcomePageMessage: resetToDefault
                    ? null
                    : this.state.welcomePageMessage,
            };
            actions.updateWelcomePage(practiceId, request);
            this.setState({
                welcomePageMessage: resetToDefault
                    ? this.getDefaultWelcomeText()
                    : this.state.welcomePageMessage,
                hasUpdatedWelcomePageMessage: true,
                hasClickedEditWelcomePage: false,
            });

            ChainAnalyticsTracker.trackPatientTriageManagementSettingWelcomeNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_SUBMISSION_PAGE) {
            const request = {
                submissionPageMessage: resetToDefault
                    ? null
                    : this.state.submissionPageMessage,
            };
            actions.updateSubmissionPage(practiceId, request);
            this.setState({
                submissionPageMessage: resetToDefault
                    ? this.getDefaultSubmissionMessage()
                    : this.state.submissionPageMessage,
                hasUpdatedSubmissionPageMessage: true,
                hasClickedEditSubmissionPage: false,
            });

            ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_SUBMISSION_SMS) {
            const request = {
                submissionPageSms: resetToDefault
                    ? null
                    : this.state.submissionPageSms,
            };
            actions.updateSubmissionSms(practiceId, request);
            this.setState({
                submissionPageSms: resetToDefault
                    ? this.getDefaultSubmissionPageSms()
                    : this.state.submissionPageSms,
                hasUpdatedSubmissionSmsMessage: true,
                hasClickedEditSubmissionSms: false,
            });

            ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationMessage(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_OOH_PAGE) {
            const request = {
                outOfHoursPageMessage: resetToDefault
                    ? null
                    : this.state.outOfHoursPageMessage,
            };
            actions.updateOutOfHoursMessage(practiceId, request);
            this.setState({
                outOfHoursPageMessage: resetToDefault
                    ? null
                    : this.state.outOfHoursPageMessage,
                hasUpdatedOohPageMessage: true,
                hasClickedEditOohPage: false,
            });

            ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHourNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_PT_PAGE) {
            const request = {
                disabledMessageHeading: resetToDefault
                    ? null
                    : this.state.ptPageMessage.disabledMessageHeading,
                disabledMessageBody: resetToDefault
                    ? null
                    : this.state.ptPageMessage.disabledMessageBody,
            };
            actions.updatePTMessage(practiceId, request);
            this.setState({
                ptPageMessage: resetToDefault
                    ? {
                          disabledMessageBody: null,
                          disabledMessageHeading: null,
                      }
                    : { ...this.state.ptPageMessage },
                hasUpdatedPTPageMessage: true,
                hasClickEditPTPage: false,
            });

            ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspendNote(
                analyticsProps,
            );
        }
    }

    handleUpdatePrescriptionSettings(
        event: React.FormEvent<HTMLFormElement>,
    ): void {
        event.preventDefault();

        const {
            isPrescriptionEnabled,
            isPrescriptionRedirectEnabled,
            isPrescriptionFormEnabled,
            prescriptionRedirectTitle,
            prescriptionRedirectUrl,
        } = this.state;

        const { practiceId, actions, accountState, analyticsState } =
            this.props;

        this.clearTrackingOfUpdatedSettings();
        this.setState({ urlError: false });

        if (isPrescriptionRedirectEnabled && !prescriptionRedirectUrl) {
            this.setState({
                isUrlEmpty: true,
            });
            return;
        }

        if (isPrescriptionRedirectEnabled && !prescriptionRedirectTitle) {
            this.setState({
                isTitleEmpty: true,
            });
            return;
        }

        if (
            prescriptionRedirectUrl !== "" &&
            prescriptionRedirectUrl !== null
        ) {
            if (!isURLValid(prescriptionRedirectUrl)) {
                this.setState({
                    urlError: true,
                });
                return;
            }
        }

        const request = {
            isEnabled: isPrescriptionEnabled,
            isRedirectEnabled: isPrescriptionRedirectEnabled,
            redirectSettings:
                isPrescriptionEnabled && isPrescriptionRedirectEnabled
                    ? {
                          isPrescriptionFormEnabled: isPrescriptionFormEnabled,
                          title: prescriptionRedirectTitle,
                          url: prescriptionRedirectUrl,
                      }
                    : null,
        };

        actions.updatePrescriptionSettings(practiceId, request);

        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingSavePrescriptionProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                isRepeatPrescriptionEnabled: isPrescriptionEnabled,
                isRedirectEnabled: isPrescriptionRedirectEnabled,
                isPrescriptionFormEnabled: isPrescriptionFormEnabled,
                objectName: PatientTriageSettingObjectName.SAVE,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };

        ChainAnalyticsTracker.trackPatientTriageManagementSettingPrescription(
            analyticsProps,
        );

        // If updating enabled state and currently false
        if (isPrescriptionRedirectEnabled) {
            const analyticsProps: ChainAnalyticsTracker.PatientTriageRedirectEnabledProps =
                {
                    ...AnalyticsMapper.getAnalyticsLoggedInProps(
                        accountState,
                        analyticsState,
                    ),
                    redirectUrl: prescriptionRedirectUrl || "",
                };
            ChainAnalyticsTracker.trackPatientTriagePrescriptionRedirectEnabled(
                analyticsProps,
            );
        }

        this.setState({
            isPrescriptionEditing: false,
            prescriptionPrevState: {
                isPrescriptionEnabled,
                isPrescriptionRedirectEnabled,
                isPrescriptionFormEnabled,
                prescriptionRedirectTitle,
                prescriptionRedirectUrl,
            },
        });
    }

    handleUpdateDisableUntilSettings(request: DisableUntilRequest): void {
        const { practiceId, actions } = this.props;

        actions.updateDisableUntilSettings(practiceId, request);
    }

    handleSuspendPTEdit(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.EDIT,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspend(
            analyticsProps,
        );

        this.setState({
            suspendPTEditing: true,
            suspendPTHasUpdated: false,
        });
    }

    handleSuspendPTCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspend(
            analyticsProps,
        );

        const { suspendPTPrevState } = this.state;
        this.setState({
            suspendPTUntil: suspendPTPrevState.suspendPTUntil,
            suspendPTDisableSwitch:
                suspendPTPrevState.suspendPTDisableSwitch as boolean,
            suspendPTDisableReason: suspendPTPrevState.suspendPTDisableReason,
            suspendPTDisableComment: suspendPTPrevState.suspendPTDisableComment,
            suspendPTAdminRequests:
                suspendPTPrevState.suspendPTAdminRequests as boolean,
            suspendPTEditing: false,
            suspendPTHasUpdated: false,
        });
    }

    renderActionConfirmation(
        triageSettingsUpdatingStatus: UpdatingStatusType,
    ): void {
        if (triageSettingsUpdatingStatus.hasUpdatingFailed) {
            toast(
                <Feedback
                    colour="error"
                    title="Sorry something went wrong, please reload and try again!"
                />,
            );
            return;
        }

        if (triageSettingsUpdatingStatus.hasUpdated) {
            toast(<Feedback colour="success" title="Updated!" />);
        }
    }

    handlePTMessageCancel(): void {
        if (this.state.hasUpdatedPTPageMessage) {
            this.setState({
                hasUpdatedPTPageMessage: false,
            });
        } else {
            this.setState({
                ptPageMessage: { ...this.state.ptPageMessageEdit },
                hasUpdatedPTPageMessage: false,
                hasClickEditPTPage: false,
            });
        }
    }

    //Opens the modal and sets which part of triage a user is trying to customise
    hasClickedEditButton(
        event: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ): void {
        event.preventDefault();
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.EDIT,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        if (name === Settings.TEXT_WELCOME_PAGE) {
            this.setState({
                mostRecentlyClickedEdit: Settings.TEXT_WELCOME_PAGE,
                welcomePageMessageEditing:
                    this.state.welcomePageMessage ??
                    this.getDefaultWelcomeText(),
                hasClickedEditWelcomePage: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingWelcomeNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_SUBMISSION_PAGE) {
            this.setState({
                mostRecentlyClickedEdit: Settings.TEXT_SUBMISSION_PAGE,
                submissionPageMessageEditing:
                    this.state.submissionPageMessage ??
                    this.getDefaultSubmissionMessage(),
                hasClickedEditSubmissionPage: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_SUBMISSION_SMS) {
            this.setState({
                mostRecentlyClickedEdit: Settings.TEXT_SUBMISSION_SMS,
                submissionPageSmsEditing:
                    this.state.submissionPageSms ??
                    this.getDefaultSubmissionPageSms(),
                hasClickedEditSubmissionSms: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationMessage(
                analyticsProps,
            );
        }
        if (name === Settings.OOH_SCHEDULE) {
            this.setState({
                hasClickEditScheduleOoh: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHour(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_OOH_PAGE) {
            this.setState({
                mostRecentlyClickedEdit: Settings.TEXT_OOH_PAGE,
                outOfHoursPageMessageEditing:
                    this.state.outOfHoursPageMessage ??
                    this.getDefaultOohText(),
                hasClickedEditOohPage: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHourNote(
                analyticsProps,
            );
        }
        if (name === Settings.TEXT_PT_PAGE) {
            this.setState({
                mostRecentlyClickedEdit: Settings.TEXT_PT_PAGE,
                ptPageMessageEdit: {
                    disabledMessageBody:
                        this.state.ptPageMessage.disabledMessageBody ??
                        this.getPTBodyDefaultText(),
                    disabledMessageHeading:
                        this.state.ptPageMessage.disabledMessageHeading ??
                        this.getPTHeadingDefaultText(),
                },
                hasClickEditPTPage: true,
            });
            ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspendNote(
                analyticsProps,
            );
        }
    }

    //Default text for overridable parts of triage form
    getDefaultWelcomeText = (): string =>
        `This will be read by a member of the team within ${this.props.status.responseTimeWorkingDays} working days`;
    getDefaultSubmissionMessage = (): string =>
        `The practice will review your request within ${this.props.status.responseTimeWorkingDays} working days`;
    getDefaultSubmissionPageSms = (): string =>
        `Your request will be reviewed within ${this.props.status.responseTimeWorkingDays} working days. If you need more urgent help, please call your GP or 111 if your practice is closed. In an emergency call 999.`;
    getDefaultOohText = (): string =>
        "Because of the time of the day, your practice will not be able to process urgent requests. Please select one of the available options below.";
    getPTHeadingDefaultText = (): string =>
        "We have temporarily paused access to online enquiries. Please select one of the available options.";
    getPTBodyDefaultText = (): string =>
        "If your request can't wait until then, please call your practice.";

    handleWelcomeMessageCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingWelcomeNote(
            analyticsProps,
        );

        if (this.state.hasUpdatedWelcomePageMessage) {
            this.setState({
                hasClickedEditWelcomePage: false,
            });
        } else {
            this.setState({
                welcomePageMessage: this.state.welcomePageMessageEditing,
                hasUpdatedWelcomePageMessage: false,
                hasClickedEditWelcomePage: false,
            });
        }
    }

    handleAccordionClick(
        e: React.MouseEvent<HTMLButtonElement>,
        name: string,
    ): void {
        e.preventDefault();
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: "SeeWhereThisIsOnThePatientForm",
                objectType: PatientTriageSettingObjectType.TAB,
            };
        switch (name) {
            case Settings.TEXT_PT_PAGE:
                ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspendNote(
                    analyticsProps,
                );
                break;
            case Settings.TEXT_WELCOME_PAGE:
                ChainAnalyticsTracker.trackPatientTriageManagementSettingWelcomeNote(
                    analyticsProps,
                );
                break;
            case Settings.TEXT_SUBMISSION_PAGE:
                ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationNote(
                    analyticsProps,
                );
                break;
            case Settings.TEXT_OOH_PAGE:
                ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHourNote(
                    analyticsProps,
                );
                break;
        }
    }

    handleSubmissionConfirmationMessageCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationNote(
            analyticsProps,
        );

        if (this.state.hasUpdatedWelcomePageMessage) {
            this.setState({
                hasClickedEditSubmissionPage: false,
            });
        } else {
            this.setState({
                submissionPageMessage: this.state.submissionPageMessageEditing,
                hasUpdatedSubmissionPageMessage: false,
                hasClickedEditSubmissionPage: false,
            });
        }
    }

    handleSubmissionSmsMessageCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingConfirmationMessage(
            analyticsProps,
        );

        if (this.state.hasUpdatedWelcomePageMessage) {
            this.setState({
                hasClickedEditSubmissionSms: false,
            });
        } else {
            this.setState({
                submissionPageSms: this.state.submissionPageSmsEditing,
                hasUpdatedSubmissionSmsMessage: false,
                hasClickedEditSubmissionSms: false,
            });
        }
    }

    handleOohPageMessageCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHourNote(
            analyticsProps,
        );

        if (this.state.hasUpdatedOohPageMessage) {
            this.setState({
                hasUpdatedOohPageMessage: false,
            });
        } else {
            this.setState({
                outOfHoursPageMessage: this.state.welcomePageMessageEditing,
                hasUpdatedOohPageMessage: false,
                hasClickedEditOohPage: false,
            });
        }
    }

    handleRepeatPrescriptionEdit(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.EDIT,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingPrescription(
            analyticsProps,
        );

        this.setState({
            isPrescriptionEditing: true,
        });
    }

    handleRepeatPrescriptionCancel(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingPrescription(
            analyticsProps,
        );

        const {
            isPrescriptionEnabled,
            isPrescriptionFormEnabled,
            isPrescriptionRedirectEnabled,
            prescriptionRedirectTitle,
            prescriptionRedirectUrl,
        } = this.state.prescriptionPrevState;

        this.setState({
            isPrescriptionEnabled,
            isPrescriptionFormEnabled,
            isPrescriptionRedirectEnabled,
            prescriptionRedirectTitle,
            prescriptionRedirectUrl,
            isPrescriptionEditing: false,
        });
    }

    fromOohHandler(e: Option | Option[]): void {
        const hour = e as Option;
        this.setState({
            outOfHoursSettings: {
                ...this.state.outOfHoursSettings,
                outOfHoursDisableFromHour: parseInt(hour.value),
            },
        });
    }

    toTheNextDayOohHandler(e: Option | Option[]): void {
        const hour = e as Option;
        this.setState({
            outOfHoursSettings: {
                ...this.state.outOfHoursSettings,
                outOfHoursDisableToHour: parseInt(hour.value),
            },
        });
    }

    outOfHoursSuspendPatientTriageHandler(): void {
        this.setState({
            outOfHoursSettings: {
                ...this.state.outOfHoursSettings,
                outOfHoursSuspendPatientTriage:
                    !this.state.outOfHoursSettings
                        .outOfHoursSuspendPatientTriage,
            },
        });
    }

    outOfHoursSuspendPatientTriageAllowAdminRequestHandler(): void {
        this.setState({
            outOfHoursSettings: {
                ...this.state.outOfHoursSettings,
                outOfHoursAllowAdminRequests:
                    !this.state.outOfHoursSettings.outOfHoursAllowAdminRequests,
            },
        });
    }

    outOfHoursCancelSuspendPatientTriageHandler(): void {
        const { accountState, analyticsState } = this.props;
        const analyticsProps: ChainAnalyticsTracker.PatientTriageManagementSettingProps =
            {
                ...AnalyticsMapper.getAnalyticsLoggedInProps(
                    accountState,
                    analyticsState,
                ),
                objectName: PatientTriageSettingObjectName.CANCEL,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingOutOfHour(
            analyticsProps,
        );

        const prevState = this.state.outOfHoursPrevState;
        this.setState({
            outOfHoursEnabled: prevState.outOfHoursEnabled,
            outOfHoursSettings: prevState.outOfHoursSettings as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            hasClickEditScheduleOoh: false,
        });
    }

    onTabChange = (tabId: string): void => {
        switch (tabId) {
            case TabIds.WhatIsPatientTriage:
                this.handleSetPage(
                    TabIds.WhatIsPatientTriage,
                    patientTriageUrls.patientTriage,
                );
                return;
            case TabIds.SetUpPatientTriage:
                this.handleSetPage(
                    TabIds.SetUpPatientTriage,
                    patientTriageUrls.patientTriageSetUp,
                );
                return;
            case TabIds.Settings:
                this.handleSetPage(
                    TabIds.Settings,
                    patientTriageUrls.patientTriageSettings,
                );
                return;
            case TabIds.Dashboard:
                this.handleSetPage(
                    TabIds.Dashboard,
                    patientTriageUrls.patientTriageDashboard,
                );
                return;
            default:
                console.error(`tabId ${tabId} not recognised`);
                return;
        }
    };

    renderTabPanelContents = (tabId: TabIds): JSX.Element => {
        const {
            practiceId,
            status,
            triageSettingsUpdatingStatus,
            practiceName,
        } = this.props;

        const {
            suspendPTUntil,
            hasClickEditPTPage,
            outOfHoursPageMessage,
            hasClickedEditOohPage,
            hasClickedEditWelcomePage,
            welcomePageMessage,
            hasClickedEditSubmissionPage,
            hasClickedEditSubmissionSms,
            prescriptionRedirectTitle,
            prescriptionRedirectUrl,
            isPrescriptionEnabled,
            isPrescriptionFormEnabled,
            isPrescriptionRedirectEnabled,
            isPrescriptionEditing,
            urlError,
            isTitleEmpty,
            isUrlEmpty,
        } = this.state;

        const submissionPageMessage =
            this.state.submissionPageMessage ??
            status.submissionPageMessage ??
            this.getDefaultSubmissionMessage();

        const headingText =
            this.state.ptPageMessage.disabledMessageHeading ??
            status.disabledMessageHeading ??
            this.getPTHeadingDefaultText();

        const bodyText =
            this.state.ptPageMessage.disabledMessageBody ??
            status.disabledMessageBody ??
            this.getPTBodyDefaultText();

        const submissionPageSms =
            this.state.submissionPageSms ??
            status.submissionPageSms ??
            this.getDefaultSubmissionPageSms();

        const workingHoursSettings = status.workingHoursSettings
            ? status.workingHoursSettings
            : defaultWorkingHours.workingHoursSettings;

        return (
            <>
                {tabId === TabIds.WhatIsPatientTriage && (
                    <WhatIsPatientTriage practiceLink={status.url} />
                )}
                {tabId === TabIds.SetUpPatientTriage && (
                    <SetupPatientTriage practiceLink={status.url} />
                )}
                {tabId === TabIds.Settings && (
                    <div className="mb-4 px-4">
                        {status.enabled ? (
                            <>
                                <WorkingHours
                                    handleSubmit={this.handleWorkingHours}
                                    isEditable={status.isAdmin}
                                    {...workingHoursSettings}
                                />
                                <section>
                                    <Text as="h2" variant="subtitle">
                                        Customise messaging
                                    </Text>
                                    <ChangeOohPageForm
                                        status={status}
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        defaultOohText={this.getDefaultOohText()}
                                        outOfHoursPageMessage={
                                            outOfHoursPageMessage
                                        }
                                        hasClickedEditOohPage={
                                            hasClickedEditOohPage
                                        }
                                        handleOohPageMessageCancel={this.handleOohPageMessageCancel.bind(
                                            this,
                                        )}
                                        hasClickedEditButton={this.hasClickedEditButton.bind(
                                            this,
                                        )}
                                        handleAccordionClick={this.handleAccordionClick.bind(
                                            this,
                                        )}
                                        handleUpdateCustomCopy={this.handleUpdateCustomCopy.bind(
                                            this,
                                        )}
                                        handleUpdateString={this.handleUpdateString.bind(
                                            this,
                                        )}
                                    />
                                    <WorkingHoursSuspensionMessageForm
                                        status={status}
                                        isEditable={status.isAdmin}
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        workingHoursSuspensionPageMessage={
                                            workingHoursSettings.suspensionMessageHeader ??
                                            null
                                        }
                                        handleSubmit={
                                            this
                                                .handleWorkingHoursSuspensionMessage
                                        }
                                    />
                                </section>
                                <br />
                                <section>
                                    <Text as="h2" variant="subtitle">
                                        Customise core messaging
                                    </Text>
                                    <ChangeWelcomePageForm
                                        status={status}
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        hasClickedEditWelcomePage={
                                            hasClickedEditWelcomePage
                                        }
                                        welcomePageMessage={welcomePageMessage}
                                        defaultWelcomeText={this.getDefaultWelcomeText()}
                                        handleUpdateCustomCopy={this.handleUpdateCustomCopy.bind(
                                            this,
                                        )}
                                        handleWelcomeMessageCancel={this.handleWelcomeMessageCancel.bind(
                                            this,
                                        )}
                                        handleUpdateString={this.handleUpdateString.bind(
                                            this,
                                        )}
                                        hasClickedEditButton={this.hasClickedEditButton.bind(
                                            this,
                                        )}
                                        handleAccordionClick={this.handleAccordionClick.bind(
                                            this,
                                        )}
                                    />
                                    <ChangeSubmissionPageForm
                                        status={status}
                                        submissionPageMessage={
                                            submissionPageMessage
                                        }
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        hasClickedEditSubmissionPage={
                                            hasClickedEditSubmissionPage
                                        }
                                        handleSubmissionConfirmationMessageCancel={this.handleSubmissionConfirmationMessageCancel.bind(
                                            this,
                                        )}
                                        hasClickedEditButton={this.hasClickedEditButton.bind(
                                            this,
                                        )}
                                        handleUpdateCustomCopy={this.handleUpdateCustomCopy.bind(
                                            this,
                                        )}
                                        handleUpdateString={this.handleUpdateString.bind(
                                            this,
                                        )}
                                        handleAccordionClick={this.handleAccordionClick.bind(
                                            this,
                                        )}
                                    />
                                    <ChangeSubmissionSmsForm
                                        submissionPageSms={submissionPageSms}
                                        status={status}
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        hasClickedEditSubmissionSms={
                                            hasClickedEditSubmissionSms
                                        }
                                        practiceName={practiceName}
                                        hasClickedEditButton={this.hasClickedEditButton.bind(
                                            this,
                                        )}
                                        handleUpdateCustomCopy={this.handleUpdateCustomCopy.bind(
                                            this,
                                        )}
                                        handleSubmissionSmsMessageCancel={this.handleSubmissionSmsMessageCancel.bind(
                                            this,
                                        )}
                                        handleUpdateString={this.handleUpdateString.bind(
                                            this,
                                        )}
                                    />
                                </section>
                                <br />
                                <RepeatPrescriptionsServiceForm
                                    status={status}
                                    triageSettingsUpdatingStatus={
                                        triageSettingsUpdatingStatus
                                    }
                                    prescriptionRedirectTitle={
                                        prescriptionRedirectTitle
                                    }
                                    isPrescriptionRedirectEnabled={
                                        isPrescriptionRedirectEnabled
                                    }
                                    prescriptionRedirectUrl={
                                        prescriptionRedirectUrl
                                    }
                                    isPrescriptionFormEnabled={
                                        isPrescriptionFormEnabled
                                    }
                                    isPrescriptionEnabled={
                                        isPrescriptionEnabled
                                    }
                                    urlError={urlError}
                                    isTitleEmpty={isTitleEmpty}
                                    isUrlEmpty={isUrlEmpty}
                                    isPrescriptionEditing={
                                        isPrescriptionEditing
                                    }
                                    handleUpdatePrescriptionSettings={this.handleUpdatePrescriptionSettings.bind(
                                        this,
                                    )}
                                    handleRepeatPrescriptionEdit={this.handleRepeatPrescriptionEdit.bind(
                                        this,
                                    )}
                                    handleRepeatPrescriptionCancel={this.handleRepeatPrescriptionCancel.bind(
                                        this,
                                    )}
                                    handleSwitchOptions={this.handleSwitchOptions.bind(
                                        this,
                                    )}
                                    handleUpdateString={this.handleUpdateString.bind(
                                        this,
                                    )}
                                />
                                <br />
                                <PatientSelfReferral
                                    isEditable={status.isAdmin}
                                    isEnabled={
                                        status.patientSelfReferralSettings
                                            .patientSelfReferralEnabled
                                    }
                                    message={
                                        status.patientSelfReferralSettings
                                            .patientSelfReferralMessage ?? ""
                                    }
                                    onSubmit={this.handlePatientSelfReferral}
                                />
                                <br />
                                <section>
                                    <Text as="h2" variant="subtitle">
                                        Suspend Patient Triage
                                    </Text>
                                    <DisableUntilSection
                                        isAdmin={status.isAdmin}
                                        isSuspended={!!status.disableUntilDate}
                                        suspendUntil={status.disableUntilDate}
                                        allowAdminRequests={
                                            status.allowAdminRequests
                                        }
                                        handleUpdateDisableUntilSettings={this.handleUpdateDisableUntilSettings.bind(
                                            this,
                                        )}
                                    />
                                    <PTMessage
                                        status={status}
                                        headingText={headingText}
                                        bodyText={bodyText}
                                        triageSettingsUpdatingStatus={
                                            triageSettingsUpdatingStatus
                                        }
                                        suspendPTUntil={suspendPTUntil}
                                        hasClickEditPTPage={hasClickEditPTPage}
                                        hasClickedEditButton={this.hasClickedEditButton.bind(
                                            this,
                                        )}
                                        handleUpdateCustomCopy={this.handleUpdateCustomCopy.bind(
                                            this,
                                        )}
                                        handlePTMessageCancel={this.handlePTMessageCancel.bind(
                                            this,
                                        )}
                                        handleAccordionClick={this.handleAccordionClick.bind(
                                            this,
                                        )}
                                        handleUpdateString={this.handleUpdateString.bind(
                                            this,
                                        )}
                                    />
                                </section>
                            </>
                        ) : (
                            <Text>
                                Please head over to{" "}
                                <a
                                    href={`/practices/${practiceId}/patient-triage`}
                                >
                                    Getting Started
                                </a>{" "}
                                to get Patient Triage set up
                            </Text>
                        )}
                    </div>
                )}
                {tabId === TabIds.Dashboard && <Dashboard />}
            </>
        );
    };

    handlePatientSelfReferral = ({
        isEnabled,
        message,
    }: {
        isEnabled: boolean;
        message: string;
    }): void => {
        const { practiceId, actions } = this.props;
        actions.updatePatientSelfReferral(practiceId, {
            patientSelfReferralEnabled: isEnabled,
            patientSelfReferralMessage: message,
        });
    };

    handleWorkingHours = ({
        suspendRequestsOutOfHours,
        allowAdminOutOfHours,
        excludeAdminFromLimit,
        workingHours,
        bankHolidayWorkingHours,
    }: WorkingHoursSettings): void => {
        const { practiceId, actions } = this.props;
        actions.updateWorkingHours(practiceId, {
            suspendRequestsOutOfHours,
            allowAdminOutOfHours,
            excludeAdminFromLimit,
            workingHours,
            bankHolidayWorkingHours,
        });
    };

    handleWorkingHoursSuspensionMessage = ({
        messageHeader,
    }: WorkingHoursSuspensionMessageSettings): void => {
        const { practiceId, actions } = this.props;
        actions.updateWorkingHoursSuspensionMessage(practiceId, {
            messageHeader: messageHeader,
        });
    };

    render(): JSX.Element {
        const { loadingStatus } = this.props;

        const tabs = Object.values(TabIds).map((value) => {
            return { tabId: value, text: value };
        });

        const selectedTab =
            this.props.deepLinkActivePage ?? TabIds.WhatIsPatientTriage;

        return (
            <LoadingWrapper loadingStatus={loadingStatus}>
                <Tabs selected={selectedTab} onTabChange={this.onTabChange}>
                    <NavSubMenuComponent contentExtendsToBottomEdge={true}>
                        <Breadcrumb title="Patient Triage" wrapper={false} />
                        <Text variant="title" as="h1" skinny className="mt-2">
                            Patient Triage
                        </Text>
                        <Tabs.TabList tabs={tabs} />
                    </NavSubMenuComponent>
                    {tabs.map(({ tabId }) => (
                        <Tabs.Panel key={tabId} tabId={tabId}>
                            {this.renderTabPanelContents(tabId)}
                        </Tabs.Panel>
                    ))}
                </Tabs>
            </LoadingWrapper>
        );
    }
}
