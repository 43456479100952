import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import {
    Button,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Link,
    Text,
} from "@accurx/design";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
    getResetValidateTokens,
    postResetPassword,
    postResetResendEmail,
} from "api/AccountApi";
import { formatSearch } from "app/account/Account.helper";
import { StyledFullWidthButton } from "app/account/SharedStyledComponents.styles";
import { getPasswordError } from "shared/Form.helper";
import { ROUTES } from "shared/Routes";

import { InnerStackPanel, OuterStackPanel } from "../LogIn/LoginStackPanel";
import { useGetProductTypeFromUrl } from "../LogIn/hooks";
import { FeedbackMsg } from "./ForgotPassword.types";

export type ResetPasswordRequestDto = {
    userId: string;
    code: string;
    password: string;
    confirmPassword: string;
    product: RegisterAccountProductType;
};

export const ResetPassword = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const product = useGetProductTypeFromUrl();
    const userId = params.get("userId");
    const code = params.get("code");
    const searchPreLogin = formatSearch({
        search: history.location.search,
        paramsToRemove: ["userId", "code"],
    });

    const [passwordNew, setPasswordNew] = useState("");
    const [passwordNewError, setPasswordNewError] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordConfirmError, setPasswordConfirmError] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });
    const [isWaiting, setIsWaiting] = useState(false);

    const [isInvalidToken, setIsInvalidToken] = useState(false);

    useEffect(() => {
        const validateResetTokens = async () => {
            if (!userId || !code) {
                return;
            }

            const res = await getResetValidateTokens(userId, code);
            if (!res.success || !res.result || !res.result.userIdValid) {
                // Redirect users that don't have a valid userId
                history.push(`${ROUTES.forgotPassword}${searchPreLogin}`);
                return;
            }

            if (!res.result.codeValid) {
                setIsInvalidToken(true);
            }
        };
        validateResetTokens();
    }, [history, userId, code, searchPreLogin]);

    // Redirect users that are missing query params
    if (!userId || !code) {
        return <Redirect to={`${ROUTES.forgotPassword}${searchPreLogin}`} />;
    }

    const handleResendEmail = async () => {
        setIsWaiting(true);
        const res = await postResetResendEmail(userId, product);
        setIsWaiting(false);

        if (!res.success) {
            setFeedbackMsg({
                colour: "error",
                msg: "Something went wrong. Please try again.",
            });
            return;
        }
        history.push(`${ROUTES.checkYourInbox}${searchPreLogin}`);
    };

    const validateNewPassword = (): string => {
        const passwordError = getPasswordError(passwordNew);
        setPasswordNewError(passwordError);
        return passwordError;
    };

    const validateConfirmPassword = (): string => {
        const confirmError =
            passwordNew !== passwordConfirm
                ? "The password and confirmation password do not match"
                : "";
        setPasswordConfirmError(confirmError);
        return confirmError;
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordNew(e.target.value);
    };

    const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(e.target.value);
    };

    const handleSubmit = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        if (!passwordNew) {
            setPasswordNewError("The new password field is required");
            return;
        }

        if (!passwordConfirm) {
            setPasswordConfirmError(
                "The password new confirm field is required",
            );
            return;
        }

        // Validate field in case user directly click on reset before onBlur happenned
        if (validateNewPassword() || validateConfirmPassword()) {
            return;
        }

        setIsWaiting(true);
        const res = await postResetPassword({
            userId,
            code,
            password: passwordNew,
            confirmPassword: passwordConfirm,
            product,
        });
        setIsWaiting(false);

        if (!res.success) {
            setFeedbackMsg({
                colour: "error",
                msg: "Something went wrong. Please try again.",
            });
            return;
        }

        history.push(`${ROUTES.resetPasswordConfirm}${searchPreLogin}`);
    };

    return (
        <OuterStackPanel>
            <Flex flexDirection="column" alignItems="center" gap="3">
                <Text variant="title" as="h1" skinny>
                    Reset password
                </Text>
                <InnerStackPanel horizontalContentAlignment="stretch">
                    {isInvalidToken ? (
                        <>
                            <Text skinny>
                                Your password reset link is now expired.
                            </Text>
                            <StyledFullWidthButton
                                type="button"
                                onClick={handleResendEmail}
                                text={
                                    isWaiting
                                        ? "Sending a new link..."
                                        : "Send a new link via email"
                                }
                                disabled={isWaiting}
                            />
                        </>
                    ) : (
                        <>
                            <Text skinny>
                                To keep your account secure use a{" "}
                                <Link
                                    href="https://www.ncsc.gov.uk/cyberaware/home"
                                    theme="light"
                                    openInNewTab
                                >
                                    <Link.Text text="hard to guess password" />
                                    <Link.Icon />
                                </Link>
                                , e.g. two random words and a number.
                            </Text>
                            <Text skinny>
                                Passwords must be at least 8 characters long and
                                include a number, lower and upper case letters.
                            </Text>
                            <form noValidate onSubmit={handleSubmit}>
                                <Flex flexDirection="column" gap="2">
                                    <FormFieldWrapper
                                        label="New password"
                                        labelProps={{ htmlFor: "password-new" }}
                                        errors={[passwordNewError]}
                                    >
                                        <Input
                                            id="password-new"
                                            value={passwordNew}
                                            type="password"
                                            placeholder={"Password"}
                                            autoComplete="new-password"
                                            onChange={handlePasswordChange}
                                            onBlur={validateNewPassword}
                                            hasErrors={!!passwordNewError}
                                            autoFocus
                                        />
                                    </FormFieldWrapper>
                                    <FormFieldWrapper
                                        label="Confirm new password"
                                        labelProps={{
                                            htmlFor: "password-confirm",
                                        }}
                                        errors={[passwordConfirmError]}
                                    >
                                        <Input
                                            id="password-confirm"
                                            value={passwordConfirm}
                                            type="password"
                                            placeholder={"Confirm new password"}
                                            autoComplete="new-password"
                                            onChange={
                                                handlePasswordConfirmChange
                                            }
                                            onBlur={validateConfirmPassword}
                                            hasErrors={!!passwordConfirmError}
                                        />
                                    </FormFieldWrapper>
                                    {feedbackMsg.colour && feedbackMsg.msg && (
                                        <Feedback
                                            title={feedbackMsg.msg}
                                            colour={feedbackMsg.colour}
                                            props={{ className: "mb-3" }}
                                        />
                                    )}
                                    <StyledResetPasswordButton
                                        type="submit"
                                        text={
                                            isWaiting
                                                ? "Resetting password..."
                                                : "Reset password"
                                        }
                                        disabled={isWaiting}
                                    />
                                </Flex>
                            </form>
                        </>
                    )}
                </InnerStackPanel>
            </Flex>
        </OuterStackPanel>
    );
};

const StyledResetPasswordButton = styled(Button)`
    align-self: flex-start;
`;
