import {
    FlemingAnalyticsPatientListsEnabled,
    FlemingLoggedInCustomProperties,
    ProductSelection,
} from "../FlemingAnalyticsConstants";
import { TrackFlemingEvent } from "../FlemingEventTracker";

export type FlemingAnalyticsTemplateType = "sms" | "video-consult" | null;
export type FlemingAnalyticsTemplateLocationType =
    | "product-page"
    | "product-modal"
    | "templates-page"
    | null;
export type FlemingAnalyticsTemplateOwnerType = "user" | "organisation";

/**
 * - LocationOfTemplate: Which part of the website did the message template action come from {@link FlemingAnalyticsTemplateLocationType}
 * - isFeaturePatientListEnabled: Whether user has Patient List feature enabled
 * - templateType: Whether the template is for Patient message or Video consult {@link FlemingAnalyticsTemplateType}
 * - Product: Which product was selected when completing the action {@link FlemingAnalyticsProductType}
 * - isDefault: Whether the template used is marked as default
 * */
type MessageTemplateAnalyticsProps = {
    LocationOfTemplate: FlemingAnalyticsTemplateLocationType;
    templateType: FlemingAnalyticsTemplateType;
    isDefault: boolean | null;
    owner: FlemingAnalyticsTemplateOwnerType | null;
} & ProductSelection &
    FlemingAnalyticsPatientListsEnabled;

export type MessageTemplateAnalyticsPropsType = MessageTemplateAnalyticsProps &
    FlemingLoggedInCustomProperties;

export type MessageTemplateAnalyticsPropsWithCurrentTemplateType =
    MessageTemplateAnalyticsPropsType & { currentTemplate: string };

export type TemplateSelectedType = MessageTemplateAnalyticsPropsType & {
    templateSelected: string;
};

/**
 * User created a custom template
 *
 * Event type: API response
 * */
export const trackMessageTemplateCreateSuccess = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Created Custom Template", props);
};

/**
 * User tried to create a custom template but got error from the server
 *
 * Event type: API response
 * */
export const trackMessageTemplateCreateFailure = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Failed To Create Custom Template", props);
};

/**
 * User edited a custom template
 *
 * Event type: API response
 * */
export const trackMessageTemplateEditSuccess = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Edited Custom Template", props);
};

/**
 * User tried to edit a custom template but got error from the server
 *
 * Event type: API response
 * */
export const trackMessageTemplateEditFailure = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Failed To Edit Custom Template", props);
};

/**
 * User deleted a custom template
 *
 * Event type: API response
 * */
export const trackMessageTemplateDeleteSuccess = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Deleted Custom Template", props);
};

/**
 * User tried to delete a custom template but got error from the server
 *
 * Event type: API response
 * */
export const trackMessageTemplateDeleteFailure = (
    props: MessageTemplateAnalyticsPropsType,
): void => {
    TrackFlemingEvent("Failed To Delete Custom Template", props);
};

/**
 * Message template list opened or closed
 *
 * Event type: Dropdown opened/closed
 * */
export const trackSelectATemplateClicked = (
    props: MessageTemplateAnalyticsPropsWithCurrentTemplateType,
): void => {
    TrackFlemingEvent("Clicked 'Select a Template'", props);
};

/**
 * Button to create a new message template clicked
 *
 * Event type: Button click
 * */
export const trackNewTemplateClicked = (
    props: MessageTemplateAnalyticsPropsWithCurrentTemplateType,
): void => {
    TrackFlemingEvent("Clicked 'New Template'", props);
};

/**
 * Message template selected from dropdown
 *
 * Event type: Dropdown item selected
 * */
export const trackTemplateSelected = (props: TemplateSelectedType): void => {
    TrackFlemingEvent("Selected a Template", props);
};

export type MessageTemplatesTabSelectedAnalyticsProps =
    FlemingLoggedInCustomProperties & {
        tab: FlemingAnalyticsTemplateOwnerType;
    };

/**
 * User switch from one tab to another
 * from message templates page
 *
 * Event type: Tab selected
 * */
export const trackTemplatesTabSelected = (
    props: MessageTemplatesTabSelectedAnalyticsProps,
): void => {
    TrackFlemingEvent("Switched Message Template Tab", props);
};
