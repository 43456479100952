import { useEffect, useMemo, useRef, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import {
    useMedicalRecordConnection,
    useNativeTrackingFields,
} from "@accurx/native";
import { QuickViewPortal } from "@accurx/quick-view";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useCompose } from "domains/compose/context";
import { PatientExternalId, TemplateItem } from "domains/compose/types";
import { getTemplateType } from "domains/compose/utils/getTemplateType";
import debounce from "lodash/debounce";

import { LiveSearchInput } from "../../../LiveSearchInput/LiveSearchInput";
import { RefreshableErrorState } from "../../../RefreshableErrorState/RefreshableErrorState";
import { SelectedTemplate } from "../../../SelectedTemplate/SelectedTemplate";
import {
    StyledFilterContainer,
    StyledPaddedBody,
    StyledTabContainer,
    StyledTemplateSearchContainer,
    StyledTemplatesScroller,
} from "./MessageTemplateSelector.styles";
import { QuestionnaireTemplatePreview } from "./components/QuestionnaireTemplatePreview";
import {
    TemplateFilterSelector,
    TemplatesFilter,
} from "./components/TemplateFilterSelector";
import { TemplateList } from "./components/TemplateList";
import { TemplatePreview } from "./components/TemplatePreview";
import { useMessageTemplatesFilter } from "./useMessageTemplatesFilter";

export type MessageTemplateSelectorProps = {
    onClickTemplate: (value: TemplateItem) => void;
    onClose: () => void;
    patientExternalIds: PatientExternalId[];
    manageTemplatesLink?: string;
};

export type MessageTemplateDataProps =
    | {
          templates: TemplateItem[];
          status: "loaded";
          refetch?: undefined;
      }
    | {
          status: "loading";
          templates?: undefined;
          refetch?: undefined;
      }
    | { status: "failed"; templates?: undefined; refetch: () => void };

const getTrackingFilterName = (filter: TemplatesFilter) => {
    switch (filter) {
        case "All":
            return "Filter";
        case "Questionnaires":
            return "Questionnaire";
        case "Templates":
            return "Template";
    }
};

export const MessageTemplateSelector = ({
    onClickTemplate,
    onClose,
    patientExternalIds,
    status,
    templates,
    refetch,
    manageTemplatesLink,
}: MessageTemplateSelectorProps & MessageTemplateDataProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const connection = useMedicalRecordConnection();
    const canSaveToRecord =
        connection.status === "Connected" &&
        connection.capabilities.saveToRecord;

    const scrollerRef = useRef<HTMLElement>(null);
    const [filter, setFilter] = useState<TemplatesFilter>("All");

    const [selectedTemplate, setSelectedTemplate] =
        useState<TemplateItem | null>(null);
    const [
        isLoadingQuestionnaireEnrolment,
        setIsLoadingQuestionnaireEnrolment,
    ] = useState(false);

    const [templatePreview, setTemplatePreview] = useState<TemplateItem | null>(
        null,
    );
    const [searchTerm, setSearchTerm] = useState("");

    const { state } = useCompose();
    const buttonVerb = useFeatureFlag("MessageComponentV1_1")
        ? "Use"
        : "Select";

    const hasQuestionnaireTemplates = templates
        ? templates.some(({ type }) => type === "QuestionnaireTemplate")
        : false;

    const filteredTemplates = useMemo(
        () =>
            (templates ?? []).filter(
                ({ type }) =>
                    filter === "All" ||
                    (filter === "Questionnaires" &&
                        type === "QuestionnaireTemplate") ||
                    (filter === "Templates" && type === "MessageTemplate"),
            ),
        [templates, filter],
    );

    const { templateGroups, count: templatesCount } = useMessageTemplatesFilter(
        {
            templates: filteredTemplates,
            searchTerm,
        },
    );

    const trackTemplateSearchDebounced = useMemo(
        () =>
            debounce((searchTerm: string) => {
                track("TemplateBrowseSearch TextBox Input", {
                    ...nativeTrackingFields,
                    searchString: searchTerm,
                    searchStringLength: searchTerm.length,
                    conversationParticipant: state.conversationParticipant,
                    eventVersion: 2,
                });
            }, 1000),
        [nativeTrackingFields, state.conversationParticipant, track],
    );

    const onSelectTemplate = (value: TemplateItem) => {
        setSelectedTemplate(value);

        track("TemplateSelect Button Click", {
            ...nativeTrackingFields,
            templateId: value.value.id,
            templateName: value.value.title,
            templateGroup: value.value.heading,
            templateType: getTemplateType(value),
            conversationParticipant: state.conversationParticipant,
            eventVersion: 3,
        });
    };

    const onClickPreview = (value: TemplateItem) => {
        setTemplatePreview(value);
        track("TemplatePreview Button Click", {
            ...nativeTrackingFields,
            templateId: value.value.id,
            templateName: value.value.title,
            templateGroup: value.value.heading,
            templateType: getTemplateType(value),
            conversationParticipant: state.conversationParticipant,
            eventVersion: 4,
        });
    };

    const onFilterChange = (value: TemplatesFilter) => {
        track("ConversationFilter MenuItem Click", {
            accessType: nativeTrackingFields.accessType,
            appOrigin: "TemplateList",
            filterName: getTrackingFilterName(value),
            eventVersion: 2,
        });
        setFilter(value);
    };

    const onFilterClick = () => {
        track("ConversationFilter Button Click", {
            accessType: nativeTrackingFields.accessType,
            appOrigin: "TemplateList",
            filterName: getTrackingFilterName(filter),
            eventVersion: 2,
        });
    };

    // When you search or filter the list we scroll it to the top
    useEffect(() => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTop = 0;
        }
    }, [searchTerm, filter]);

    // When questionnaires are supported we want to wait for all templates to
    // have been fetched
    const isFetchingTemplates = status === "loading";

    const resetSelectedTemplate = () => {
        setSelectedTemplate(null);
    };

    const heading = hasQuestionnaireTemplates
        ? "Select template or questionnaire"
        : "Use a template";

    return (
        <>
            <QuickViewPortal.Header>
                <Pill.SecondaryButton
                    dimension="small"
                    onClick={() => {
                        onClose();
                        track("TemplateBrowseClose Button Click", {
                            ...nativeTrackingFields,
                            conversationParticipant:
                                state.conversationParticipant,
                            eventVersion: 2,
                        });
                    }}
                >
                    <Pill.Icon name="Cross" colour="blue" size={3} />
                    <Pill.Text>Close</Pill.Text>
                </Pill.SecondaryButton>{" "}
                {templatePreview && (
                    <Pill.SecondaryButton
                        dimension="small"
                        onClick={() => {
                            track("TemplatePreviewBack Button Click", {
                                ...nativeTrackingFields,
                                templateId: templatePreview.value.id,
                                templateName: templatePreview.value.title,
                                templateGroup: templatePreview.value.heading,
                                conversationParticipant:
                                    state.conversationParticipant,
                                eventVersion: 2,
                            });
                            setTemplatePreview(null);
                        }}
                    >
                        <Pill.Icon
                            name="ArrowTail"
                            colour="blue"
                            size={3}
                            rotation="left"
                        />
                        <Pill.Text>Back</Pill.Text>
                    </Pill.SecondaryButton>
                )}
            </QuickViewPortal.Header>
            <QuickViewPortal.Body>
                {status === "failed" ? (
                    <RefreshableErrorState
                        text="Sorry, something went wrong loading message templates. Click
                    below to try again."
                        onClickRefresh={() => {
                            refetch();
                        }}
                    />
                ) : templatePreview?.type === "MessageTemplate" ? (
                    <TemplatePreview
                        title={templatePreview.value.title}
                        body={templatePreview.value.body}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                        snomedCodes={
                            canSaveToRecord
                                ? templatePreview.value.snomedCodes
                                : []
                        }
                        attachments={templatePreview.value.attachments}
                        allowReplyByDefault={
                            templatePreview.value.allowReplyByDefault
                        }
                    />
                ) : templatePreview?.type === "QuestionnaireTemplate" ? (
                    <QuestionnaireTemplatePreview
                        template={templatePreview.value}
                        greeting={state.originalGreeting}
                        signature={state.messageSignature}
                    />
                ) : (
                    <StyledTabContainer>
                        <StyledTemplateSearchContainer>
                            {isFetchingTemplates ? (
                                <SkeletonLoader height="24px" />
                            ) : (
                                <UI.Text as="h2" variant="label" skinny>
                                    {heading}
                                </UI.Text>
                            )}
                            <LiveSearchInput
                                placeholder={"Search"}
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    trackTemplateSearchDebounced(
                                        e.target.value,
                                    );
                                }}
                                aria-label={
                                    hasQuestionnaireTemplates
                                        ? "Search for template or questionnaire"
                                        : "Search for template"
                                }
                                disabled={isFetchingTemplates}
                            />
                        </StyledTemplateSearchContainer>
                        {hasQuestionnaireTemplates && (
                            <StyledFilterContainer>
                                <TemplateFilterSelector
                                    value={filter}
                                    onChange={onFilterChange}
                                    onClick={onFilterClick}
                                />
                            </StyledFilterContainer>
                        )}
                        <StyledTemplatesScroller ref={scrollerRef}>
                            {isFetchingTemplates ? (
                                <StyledPaddedBody>
                                    <UI.Spinner />
                                </StyledPaddedBody>
                            ) : templatesCount > 0 ? (
                                <TemplateList
                                    templates={templateGroups}
                                    onClickTemplate={onSelectTemplate}
                                    onClickPreview={onClickPreview}
                                    loadingTemplate={
                                        selectedTemplate &&
                                        isLoadingQuestionnaireEnrolment
                                            ? selectedTemplate
                                            : undefined
                                    }
                                />
                            ) : (
                                // aria-live=assertive is recommended here so screen reader users
                                // do not continue typing when we've found no results.
                                <StyledPaddedBody aria-live="assertive">
                                    <UI.Text skinny variant="body">
                                        {searchTerm === ""
                                            ? "No templates found."
                                            : `No results found for '${searchTerm}'.`}
                                    </UI.Text>
                                </StyledPaddedBody>
                            )}
                        </StyledTemplatesScroller>
                    </StyledTabContainer>
                )}
            </QuickViewPortal.Body>
            {(templatePreview || manageTemplatesLink) && (
                <QuickViewPortal.Footer>
                    {templatePreview && (
                        <UI.Flex justifyContent={"flex-end"}>
                            <Pill.PrimaryButton
                                dimension="small"
                                onClick={() =>
                                    onSelectTemplate(templatePreview)
                                }
                                aria-label={`${buttonVerb} ${templatePreview.value.title}`}
                                isLoading={isLoadingQuestionnaireEnrolment}
                            >
                                <Pill.Text>
                                    {templatePreview.type ===
                                    "QuestionnaireTemplate"
                                        ? `${buttonVerb} questionnaire`
                                        : `${buttonVerb} template`}
                                </Pill.Text>
                            </Pill.PrimaryButton>
                        </UI.Flex>
                    )}
                    {!templatePreview && manageTemplatesLink && (
                        <UI.Link
                            href={manageTemplatesLink}
                            openInNewTab
                            onClick={() => {
                                track("TemplateBrowseManage Link Click", {
                                    ...nativeTrackingFields,
                                    conversationParticipant:
                                        state.conversationParticipant,
                                    eventVersion: 2,
                                });
                            }}
                        >
                            Create and manage templates
                            <UI.Link.Icon />
                        </UI.Link>
                    )}
                </QuickViewPortal.Footer>
            )}

            {selectedTemplate && (
                <SelectedTemplate
                    onClickTemplate={onClickTemplate}
                    resetSelectedTemplate={resetSelectedTemplate}
                    template={selectedTemplate}
                    patientExternalIds={patientExternalIds}
                    onIsLoading={setIsLoadingQuestionnaireEnrolment}
                />
            )}
        </>
    );
};
