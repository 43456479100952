import { NativeFetchError } from "domains/native/errors";
import { MedicalRecordConnectionDto } from "domains/native/schemas/MedicalRecordConnectionDtoSchema";
import { useNativeSubscription } from "domains/native/subscriptions/context";

import { QueryHook } from "../../inbox/hooks/queries/types";

export type UseMedicalRecordConnectionQuery = QueryHook<
    undefined,
    MedicalRecordConnectionDto,
    NativeFetchError
>;

export const useMedicalRecordConnectionQuery: UseMedicalRecordConnectionQuery =
    () => {
        return useNativeSubscription("SubscribeMedicalRecordConnection");
    };
