import React from "react";

import { FeatureName } from "@accurx/auth";
import { Ds, Text } from "@accurx/design";

import { FeatureFlag } from "app/practices/Practices.types";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionSelfBookProps = {
    practiceFeatures: FeatureFlag[];
};

export const SectionSelfBook = ({
    practiceFeatures,
}: SectionSelfBookProps): JSX.Element | null => {
    const isSelfBookEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.PatientTriageSolicitedAppointmentBooking,
    );
    const isTriageInboxEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.AccurxPlusPatientTriage,
    );

    if (!isSelfBookEnabled || !isTriageInboxEnabled) {
        return null;
    }

    return (
        <>
            <div className={"d-flex align-items-center"}>
                <Text as="h2" variant="subtitle">
                    Self-Book for Patient Triage
                </Text>
                <Ds.Badge color="purple" radius="round" className="ml-2">
                    Pilot feature
                </Ds.Badge>
            </div>
            <div className="mb-4">
                <PracticeLink
                    url="self-book"
                    title="Message Status"
                    section={PracticeLinkSection.SELF_BOOK}
                    description="See the status and details of all sent Self-Book messages"
                />
            </div>
        </>
    );
};
