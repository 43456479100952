import { useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Button,
    Ds,
    Feedback,
    Icon,
    Link,
    StackPanel,
    Text,
} from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { i } from "images";
import { generatePath, useHistory, useParams } from "react-router";

import { getQuestionnaireCount } from "api/FloreyBuilder/FloreyBuilderApi";
import { ChainAnalyticsTracker } from "app/analytics";
import { useAnalytics } from "app/analytics/hooks";
import { useCurrentUser, useModules } from "app/organisations/hooks";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_CHAIN, ROUTES_ORGS } from "shared/Routes";
import { useIsFeatureEnabled } from "store/hooks";

import { SectionCardLink, SectionCardLinkDisabled } from "../";
import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCTAButton,
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
    StyledFeedbackContainer,
    StyledList,
    StyledMarketingCardImage,
    StyledMarketingCardStackPanel,
    StyledProductCardSummary,
    StyledProductMarketingContent,
} from "../../styles/Cards.styles";

export const MessagingProductCard = (): JSX.Element => {
    const { track } = useAnalytics();
    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();
    const userSettings = useCurrentUser();
    const modules = useModules();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const SelfBookExperimentTargetedPromotionToPracticeMangers =
        useIsFeatureEnabled(
            FeatureName.SelfBookExperimentTargetedPromotionToPracticeMangers,
        );

    const [isNewToFloreyBuilder, setIsNewToFloreyBuilder] = useState(false);

    const newBatchMessageClickHandler = () => {
        track("HomepageBatchStartButtonClick", {});
        history.push(
            generatePath(ROUTES_CHAIN.practicesBatchMessagingChoose, {
                orgId,
            }),
            {
                from: history.location.pathname,
            },
        );
    };

    const customFloreyClickHandler = () => {
        track("HomepageDiscoverButtonClick", {
            homepageSection: SECTIONS.MESSAGING,
            homepageItem: "Custom Floreys",
        });

        history.push(
            generatePath(ROUTES_CHAIN.practicesFloreyBuilderBase, {
                orgId,
            }),
        );
    };

    useEffect(() => {
        if (!orgId) return;
        const getFloreyBuilderStatus = async () => {
            const response = await getQuestionnaireCount(orgId.toString());

            if (response.success && response?.result?.count === 0) {
                setIsNewToFloreyBuilder(true);
            }
        };

        if (modules?.floreyBuilder) {
            getFloreyBuilderStatus();
        }
    }, [orgId, modules?.floreyBuilder]);

    return (
        <StyledCard>
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="MessageWrite"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        Patient Messaging
                    </Text>
                </StackPanel>
                {userSettings?.isApproved && (
                    <StyledCTAButton
                        theme="secondary"
                        text="New batch message"
                        icon={{
                            name: "Messages",
                            colour: "blue",
                        }}
                        onClick={newBatchMessageClickHandler}
                    />
                )}
            </StyledCardHeading>
            {SelfBookExperimentTargetedPromotionToPracticeMangers && (
                <StyledFeedbackContainer>
                    <Feedback
                        colour="learning"
                        title="Invite patients to book into an appointment slot specified by you"
                    >
                        {
                            <>
                                <Text skinny>
                                    Need to run annual reviews, blood tests or
                                    cervical screenings? Avoid unnecessary admin
                                    by selecting what, and when, a patient can
                                    choose to book via a Self-Book link.
                                </Text>
                                <Link
                                    onClick={() => {
                                        ChainAnalyticsTracker.trackMessagingSelfBookViewDemoClick(
                                            {
                                                ...analyticsLoggedInProps,
                                                homepageItem: "BookingDemo",
                                            },
                                        );
                                    }}
                                    href={SupportUrls.SelfBookOverviewHowToSend}
                                    openInNewTab
                                >
                                    View demo
                                    <Link.Icon iconName="OpenWindow" />
                                </Link>
                            </>
                        }
                    </Feedback>
                </StyledFeedbackContainer>
            )}
            <StyledCardContent>
                <StyledList>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.MESSAGING}
                            text="All messages"
                            path={generatePath(ROUTES_ORGS.allMessages, {
                                orgId,
                            })}
                        />
                    </li>
                    <li>
                        {userSettings?.isApproved ? (
                            <SectionCardLink
                                section={SECTIONS.MESSAGING}
                                text="View batch messages"
                                path={generatePath(ROUTES_ORGS.allMessages, {
                                    orgId,
                                    tab: "batch-messages",
                                })}
                            />
                        ) : (
                            <SectionCardLinkDisabled text="Batch messages" />
                        )}
                    </li>
                    <li>
                        <SectionCardLink
                            section={SECTIONS.MESSAGING}
                            text="Templates & Florey questionnaires"
                            path={generatePath(
                                ROUTES_CHAIN.practicesManageTemplates,
                                { orgId },
                            )}
                            newBadge={true}
                        />
                    </li>
                </StyledList>
            </StyledCardContent>

            {userSettings?.isApproved && isNewToFloreyBuilder && (
                <StyledProductMarketingContent>
                    <StyledCardHeading
                        as="header"
                        orientation="horizontal"
                        horizontalContentAlignment="space-between"
                        gutter={4}
                    >
                        <StyledMarketingCardStackPanel
                            gutter={1.5}
                            orientation="horizontal"
                            verticalContentAlignment="center"
                            horizontalContentAlignment="left"
                        >
                            <Text as="h3" skinny variant="label">
                                Custom Floreys
                            </Text>
                            <Ds.Badge color="purple" radius="round">
                                New
                            </Ds.Badge>
                        </StyledMarketingCardStackPanel>
                    </StyledCardHeading>
                    <StyledCardContent>
                        <StackPanel
                            gutter={1.5}
                            orientation="horizontal"
                            verticalContentAlignment="center"
                            horizontalContentAlignment="space-between"
                        >
                            <StyledProductCardSummary>
                                <Text>
                                    Reduce admin burden or manage long-term
                                    conditions with easy to create patient
                                    questionnaires.
                                </Text>
                                <Button
                                    text="Try it now"
                                    theme="secondary"
                                    icon={{
                                        name: "OpenWindow",
                                        colour: "blue",
                                    }}
                                    onClick={customFloreyClickHandler}
                                />
                            </StyledProductCardSummary>
                            <StyledMarketingCardImage
                                src={i(
                                    "organisations/illustration-custom-floreys-2.svg",
                                )}
                                alt="Custom florey illstration"
                                aria-hidden="true"
                            />
                        </StackPanel>
                    </StyledCardContent>
                </StyledProductMarketingContent>
            )}
        </StyledCard>
    );
};
