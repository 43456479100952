import {
    Condition,
    ConditionOperator,
    ConditionQuestion,
    Option,
    QuestionType,
    SnomedCode,
    UserGroup,
    UserGroupCategory,
} from "@accurx/api/florey-builder";
import { v4 as uuid } from "uuid";

import { ViewStatus } from "app/sharedComponents/saveableCard/SaveableCard";

import { NO_SNOMED_CODE_OPTION } from "./FloreyBuilder.utils";
import { generateCardKey } from "./components/Question/Question.helpers";
import { Actions } from "./constants";
import { Action, State } from "./types";
import {
    ClientSideQuestion,
    ClientSideQuestionsData,
    SelectedQuestionnaire,
} from "./types/context.types";
import {
    AdditionalCardTypes,
    BranchPosition,
    CardId,
    CardStatus,
    CardType,
    CardsByKey,
    QuestionLevel,
    QuestionnaireResponseExtended,
} from "./types/data.types";

export const mapSnomedListToSelectOptions = (
    snomedOptions: SnomedCode[],
): { label: string; value: string }[] =>
    snomedOptions
        .filter((code) => code.conceptId && code.term)
        .map((snomedOption) => ({
            label: snomedOption.term,
            value: snomedOption.conceptId,
        }));

export const mapUserGroupsToOptions = (
    userGroups: UserGroup[],
): { label: string; value: string; grouping: string }[] =>
    userGroups
        .filter((group) => group.displayName && group.id)
        .map((userGroup) => ({
            label: userGroup.displayName,
            value: userGroup.id.toString(),
            grouping: UserGroupCategory[userGroup.category] ?? "",
        }));

export function replaceItemInArray<T>(
    array: Array<T>,
    item: T,
    index: number,
): Array<T> {
    return [
        ...array.slice(0, index),
        item,
        ...array.slice(index + 1, array.length),
    ];
}
export function removeItemInArray<T>(array: Array<T>, index: number): Array<T> {
    return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
}

export function removeItemInArrayByKey<
    K extends string,
    T extends Record<K, unknown>,
>(array: Array<T>, key: K, value: string): Array<T> {
    const idx = array.findIndex((item) => item[key] === value);
    return removeItemInArray(array, idx);
}

type GetBranch = { branchPosition: BranchPosition; state: State };
const getBranch = ({
    branchPosition,
    state,
}: GetBranch): ClientSideQuestion => {
    const questions = state.selectedQuestionnaire?.questions ?? [];
    return questions[branchPosition];
};
type UpdateBranch = {
    branchPosition: BranchPosition;
    state: State;
    updatedBranch: ClientSideQuestion;
};
const updateBranch = ({
    branchPosition,
    state,
    updatedBranch,
}: UpdateBranch): State => {
    const questions = state.selectedQuestionnaire?.questions ?? [];
    const updatedQuestions = replaceItemInArray(
        questions,
        updatedBranch,
        branchPosition,
    );
    return {
        ...state,
        selectedQuestionnaire: {
            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
            questions: updatedQuestions,
        },
    };
};

export function getOptionsForQuestion(
    state: State,
    questionIndex: number,
): Option[] {
    const questions = state.selectedQuestionnaire?.questions ?? [];
    return questions[questionIndex].options ?? [];
}

export const getInitialOptionState = (questionType: QuestionType): Option => {
    switch (questionType) {
        case QuestionType.MultipleChoice:
        default:
            return {
                text: "",
                answerCode: undefined,
                clientId: uuid(),
                // generate a unique identifier for each new option that will be saved in state and value used for 'key' in loops of options
                // the term "clientId" is used instead of "uniqueId" only because this is also sent and retrieved to the server and is the key name there.
            };
    }
};

const getInitialBranchCondition = (): Condition => ({
    conditionOperator: ConditionOperator.IsEqual,
    conditionQuestion: {
        answerText: "",
        questionId: 0,
        questionText: "",
    },
});
const getInitialQuestionsData = ({
    cardsByKey = {},
    isRemovingQuestion = false,
    showAddQuestionButton = false,
}: {
    cardsByKey?: CardsByKey;
    isRemovingQuestion?: boolean;
    showAddQuestionButton?: boolean;
}): ClientSideQuestionsData => ({
    cardsByKey,
    isRemovingQuestion,
    showAddQuestionButton,
});

export const getInitialQuestionState = (
    questionType: QuestionType,
): ClientSideQuestion => {
    switch (questionType) {
        case QuestionType.Branching:
            return {
                questionTitle: `Branch-${uuid()}`,
                questionType,
                helperText: "",
                questionRowVersion: "",
                viewStatus: ViewStatus.Edit,
                branchData: {
                    condition: getInitialBranchCondition(),
                    branchQuestions: [],
                },
                questionsData: getInitialQuestionsData({}),
                clientId: uuid(),
            };
        case QuestionType.Text:
            return {
                questionTitle: "",
                questionType,
                helperText: "",
                questionRowVersion: "",
                viewStatus: ViewStatus.Edit,
                clientId: uuid(),
            };
        case QuestionType.Information:
            return {
                questionTitle: "",
                questionType,
                helperText: "",
                questionRowVersion: "",
                viewStatus: ViewStatus.Edit,
                clientId: uuid(),
            };
        case QuestionType.MultipleChoice:
        default:
            return {
                questionTitle: "",
                questionType,
                helperText: "",
                options: [getInitialOptionState(questionType)],
                questionRowVersion: "",
                viewStatus: ViewStatus.Edit,
                clientId: uuid(),
            };
    }
};
const initialCardState = (cardType: CardType): CardStatus => {
    return {
        cardType: cardType,
        isSaving: false,
    };
};
const initialCardsByKeyState: CardsByKey = {
    questionnaireName: initialCardState(AdditionalCardTypes.QuestionnaireName),
};
export const initialState: State = {
    selectedQuestionnaire: undefined,
    cardsByKey: initialCardsByKeyState,
    isRemovingQuestion: false,
    isSavingPage: false,
    nameViewStatus: ViewStatus.Initial,
    showAddQuestionButton: false,
    snomedCodes: [],
    userGroups: [],
};

const populateCardsByKey = (questions: ClientSideQuestion[]): CardsByKey => {
    const cardsByKey: CardsByKey = initialCardsByKeyState;

    questions.forEach((question) => {
        cardsByKey[generateCardKey(question.clientId)] = initialCardState(
            question.questionType,
        );
    });
    return cardsByKey;
};

const getQuestions = (questions: ClientSideQuestion[], clientId: string) =>
    questions
        .map((question, index) => ({ question, index }))
        .filter((x) => x.question.clientId === clientId);

const getQuestionToUpdate = (
    questions: ClientSideQuestion[],
    clientId: string,
): { question: ClientSideQuestion; index: number } | undefined => {
    const updates = getQuestions(questions, clientId);
    if (updates.length !== 1) {
        return undefined;
    } //guarding against creating empty question with just a status
    return updates[0];
};

export function FloreyBuilderReducer(state: State, action: Action): State {
    switch (action.type) {
        case Actions.RESET_STATE: {
            return {
                ...initialState,
            };
        }
        case Actions.SET_QUESTIONNAIRE: {
            const selectedQuestionnaire =
                action.payload as SelectedQuestionnaire;
            const questions: ClientSideQuestion[] =
                selectedQuestionnaire.questions?.map((question) => {
                    if (question.questionType !== QuestionType.Branching) {
                        return question;
                    } else {
                        return {
                            ...question,
                            questionsData: getInitialQuestionsData({
                                cardsByKey: populateCardsByKey(
                                    question.branchData?.branchQuestions ?? [],
                                ),
                                showAddQuestionButton: true,
                            }),
                        };
                    }
                }) ?? [];

            return {
                ...state,
                selectedQuestionnaire: {
                    ...selectedQuestionnaire,
                    questions: questions,
                } as SelectedQuestionnaire,
                cardsByKey: populateCardsByKey(questions),
                showAddQuestionButton: true,
            };
        }
        case Actions.SET_QUESTIONS: {
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    questions: action.payload as ClientSideQuestion[],
                },
            };
        }
        case Actions.SET_ENROLMENT_MESSAGE: {
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    enrolmentMessage: action.payload as string,
                },
            };
        }
        case Actions.SET_ENROLMENT_CODE: {
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    enrolmentSnomedCode: action.payload as SnomedCode,
                },
            };
        }
        case Actions.SET_COMPLETION_TEXT: {
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    completionText: action.payload as string,
                },
            };
        }
        case Actions.SET_COMPLETION_CODE: {
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    completionSnomedCode: action.payload as SnomedCode,
                },
            };
        }
        case Actions.SET_CODES: {
            return {
                ...state,
                snomedCodes: [
                    NO_SNOMED_CODE_OPTION,
                    ...mapSnomedListToSelectOptions(
                        (action.payload as { codes: SnomedCode[] }).codes,
                    ),
                ],
            };
        }
        case Actions.UPDATE_CARD_IS_SAVING: {
            const { isSaving, cardKey, questionLevel, branchPosition } =
                action.payload as {
                    isSaving: boolean;
                    cardKey: string;
                    questionLevel: QuestionLevel;
                    branchPosition: BranchPosition;
                };

            let updatedCardsByKey: CardsByKey = {};
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    if (!branch.questionsData?.cardsByKey[cardKey]) {
                        return state;
                    }
                    updatedCardsByKey = { ...branch.questionsData?.cardsByKey };
                    // Update card with key `${cardKey}`
                    updatedCardsByKey[`${cardKey}`] = {
                        ...state.cardsByKey[cardKey],
                        isSaving,
                    };
                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKey,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });

                case "Questionnaire":
                default:
                    if (!state.cardsByKey[cardKey]) {
                        return state;
                    }
                    updatedCardsByKey = { ...state.cardsByKey };

                    // Update card with key `${cardKey}`
                    updatedCardsByKey[`${cardKey}`] = {
                        ...state.cardsByKey[cardKey],
                        isSaving,
                    };
                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                    };
            }
        }
        case Actions.UPDATE_QUESTION_IS_REMOVING: {
            const { isRemovingQuestion, questionLevel, branchPosition } =
                action.payload as {
                    isRemovingQuestion: boolean;
                    questionLevel: QuestionLevel;
                    branchPosition: BranchPosition;
                };
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: branch.questionsData?.cardsByKey ?? {},
                            showAddQuestionButton:
                                branch.questionsData?.showAddQuestionButton ??
                                false,
                            isRemovingQuestion,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });

                case "Questionnaire":
                default:
                    return {
                        ...state,
                        isRemovingQuestion,
                    };
            }
        }
        case Actions.UPDATE_PAGE_IS_SAVING: {
            const { isSavingPage } = action.payload as {
                isSavingPage: boolean;
            };
            return {
                ...state,
                isSavingPage,
            };
        }
        case Actions.UPDATE_QUESTIONNAIRE_NAME: {
            const { questionnaireName } = action.payload as {
                questionnaireName: string;
            };
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                    name: questionnaireName,
                },
            };
        }

        case Actions.UPDATE_QUESTIONNAIRE_NAME_VIEW_STATUS: {
            const { viewStatus } = action.payload as { viewStatus: ViewStatus };
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as SelectedQuestionnaire),
                },
                nameViewStatus: viewStatus,
            };
        }
        case Actions.ADD_QUESTION_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };

            let updatedCardsByKey: CardsByKey = {};
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    updatedCardsByKey = { ...branch.questionsData?.cardsByKey };
                    updatedCardsByKey[CardId.questionTypeSelector] =
                        initialCardState(
                            AdditionalCardTypes.QuestionTypeSelector,
                        );

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKey,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    updatedCardsByKey = { ...state.cardsByKey };
                    updatedCardsByKey[CardId.questionTypeSelector] =
                        initialCardState(
                            AdditionalCardTypes.QuestionTypeSelector,
                        );

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: false,
                    };
            }
        }
        case Actions.REMOVE_QUESTION_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    // Remove QuestionTypeSelector card from branch.cardsByKey
                    // But since this is optional at this level typescript won't let me remove it here.
                    const {
                        [CardId.questionTypeSelector]: removedKeyFromBranch,
                        ...updatedCardsByKeyFromBranch
                    } = branch.questionsData?.cardsByKey ?? {};

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKeyFromBranch,
                            showAddQuestionButton: true,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    // Remove QuestionTypeSelector card from state.cardsByKey
                    const {
                        [CardId.questionTypeSelector]: removedKey,
                        ...updatedCardsByKey
                    } = state.cardsByKey;

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: true,
                    };
            }
        }
        case Actions.ADD_MEASUREMENT_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            let updatedCardsByKey;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    updatedCardsByKey = { ...branch.questionsData?.cardsByKey };
                    updatedCardsByKey[CardId.measurementTypeSelector] =
                        initialCardState(
                            AdditionalCardTypes.MeasurementTypeSelector,
                        );

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKey,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    updatedCardsByKey = { ...state.cardsByKey };
                    updatedCardsByKey[CardId.measurementTypeSelector] =
                        initialCardState(
                            AdditionalCardTypes.MeasurementTypeSelector,
                        );

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: false,
                    };
            }
        }
        case Actions.REMOVE_MEASUREMENT_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    // Remove measurementTypeSelector card from branch.cardsByKey
                    const {
                        [CardId.measurementTypeSelector]: removedKeyFromBranch,
                        ...updatedCardsByKeyFromBranch
                    } = branch.questionsData?.cardsByKey ?? {};

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKeyFromBranch,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    // Remove MeasurementTypeSelector card from state.cardsByKey
                    const {
                        [CardId.measurementTypeSelector]: removedKey,
                        ...updatedCardsByKey
                    } = state.cardsByKey;

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: true,
                    };
            }
        }
        case Actions.ADD_PHOTO_UPLOAD_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            let updatedCardsByKey;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    updatedCardsByKey = { ...branch.questionsData?.cardsByKey };
                    updatedCardsByKey[CardId.photoUploadSelector] =
                        initialCardState(
                            AdditionalCardTypes.PhotoUploadSelector,
                        );

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKey,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    updatedCardsByKey = { ...state.cardsByKey };
                    updatedCardsByKey[CardId.photoUploadSelector] =
                        initialCardState(
                            AdditionalCardTypes.PhotoUploadSelector,
                        );

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: false,
                    };
            }
        }
        case Actions.REMOVE_PHOTO_UPLOAD_SELECTOR: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    // Remove photoUploadSelector card from branch.cardsByKey
                    const {
                        [CardId.photoUploadSelector]: removedKeyFromBranch,
                        ...updatedCardsByKeyFromBranch
                    } = branch.questionsData?.cardsByKey ?? {};

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        questionsData: {
                            cardsByKey: updatedCardsByKeyFromBranch,
                            showAddQuestionButton: false,
                            isRemovingQuestion: false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    // Remove photoUploadSelector card from state.cardsByKey
                    const {
                        [CardId.photoUploadSelector]: removedKey,
                        ...updatedCardsByKey
                    } = state.cardsByKey;

                    return {
                        ...state,
                        cardsByKey: updatedCardsByKey,
                        showAddQuestionButton: true,
                    };
            }
        }
        case Actions.ADD_QUESTION: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionType, questionLevel, branchPosition } =
                action.payload as {
                    questionType: QuestionType;
                    questionLevel: QuestionLevel;
                    branchPosition: BranchPosition;
                };
            const newQuestion: ClientSideQuestion =
                getInitialQuestionState(questionType);
            let cardsByKey: CardsByKey = {};
            switch (questionLevel) {
                case "Branch":
                    const branch: ClientSideQuestion = getBranch({
                        branchPosition,
                        state,
                    });
                    const branchQuestions: ClientSideQuestion[] =
                        branch.branchData?.branchQuestions ?? [];
                    cardsByKey = { ...branch.questionsData?.cardsByKey };
                    cardsByKey[generateCardKey(newQuestion.clientId)] =
                        initialCardState(questionType);

                    const condition: Condition =
                        branch.branchData?.condition ??
                        getInitialBranchCondition();

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: [...branchQuestions, newQuestion],
                            condition,
                        },
                        questionsData: {
                            ...branch.questionsData,
                            cardsByKey,
                            showAddQuestionButton: false,
                            isRemovingQuestion:
                                branch.questionsData?.isRemovingQuestion ??
                                false,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    const questions =
                        state.selectedQuestionnaire?.questions || [];
                    cardsByKey = { ...state.cardsByKey };
                    cardsByKey[generateCardKey(newQuestion.clientId)] =
                        initialCardState(questionType);

                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: [...questions, newQuestion],
                        },
                        cardsByKey,
                        showAddQuestionButton: false,
                    };
            }
        }
        case Actions.REMOVE_LAST_QUESTION: {
            // TODO: COCO-453 Update reducer to have typed actions
            const { questionLevel, branchPosition } = action.payload as {
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    const branchQuestions =
                        branch.branchData?.branchQuestions ?? [];
                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            ...branch.branchData,
                            condition: {
                                ...(branch.branchData?.condition ??
                                    getInitialBranchCondition()),
                            },
                            branchQuestions,
                        },
                        questionsData: {
                            ...branch.questionsData,
                            cardsByKey: branch.questionsData?.cardsByKey ?? {},
                            isRemovingQuestion:
                                branch.questionsData?.isRemovingQuestion ??
                                false,
                            showAddQuestionButton: true,
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    const questions =
                        state.selectedQuestionnaire?.questions || [];
                    const updatedQuestions = [
                        ...questions.slice(0, questions.length - 1),
                    ];
                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                        showAddQuestionButton: true,
                    };
            }
        }
        case Actions.UPDATE_QUESTION_FIELD: {
            const {
                questionIndex,
                fieldKey,
                fieldValue,
                questionLevel,
                branchPosition,
            } = action.payload as {
                questionIndex: number;
                fieldKey: string;
                fieldValue: string;
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            let questions;
            let updatedQuestion;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    questions = branch.branchData?.branchQuestions || [];
                    updatedQuestion = {
                        ...questions[questionIndex],
                        [fieldKey]: fieldValue, // update questionTitle/helperText
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };
                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];
                    updatedQuestion = {
                        ...questions[questionIndex],
                        [fieldKey]: fieldValue, // update questionTitle/helperText
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };

                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                        },
                    };
            }
        }
        case Actions.UPDATE_QUESTION_BRANCH_CONDITION_QUESTION: {
            const { clientId, fieldKey, fieldValue } = action.payload as {
                clientId: string;
                fieldKey: string;
                fieldValue: ConditionQuestion | undefined;
            };
            const questions = state.selectedQuestionnaire?.questions || [];
            const questionToUpdate = getQuestionToUpdate(questions, clientId);
            if (questionToUpdate === undefined) {
                return state;
            }
            const updatedQuestion: ClientSideQuestion = {
                ...questionToUpdate.question,
                branchData: {
                    ...questionToUpdate.question
                        .branchData /* TODO: make spreading branchData not require handling all childrens' undefined fields */,
                    branchQuestions:
                        questionToUpdate.question.branchData?.branchQuestions ??
                        [],
                    condition: {
                        ...(questionToUpdate.question.branchData?.condition ??
                            getInitialBranchCondition()),
                        [fieldKey]: fieldValue,
                    },
                },
                viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
            };
            return {
                ...state,
                selectedQuestionnaire: {
                    ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                    questions: replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionToUpdate.index,
                    ),
                },
            };
        }
        case Actions.UPDATE_QUESTION_VIEW_STATUS: {
            const { clientId, viewStatus, branchPosition, questionLevel } =
                action.payload as {
                    clientId: string;
                    viewStatus: ViewStatus;
                    questionLevel: QuestionLevel;
                    branchPosition: BranchPosition;
                };

            let questions;
            let updatedQuestion;
            let questionToUpdate;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    questions = branch.branchData?.branchQuestions || [];

                    questionToUpdate = getQuestionToUpdate(questions, clientId);

                    if (questionToUpdate === undefined) {
                        return state;
                    }

                    updatedQuestion = {
                        ...questionToUpdate.question,
                        viewStatus: viewStatus,
                    };

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionToUpdate.index,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };

                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });

                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];

                    questionToUpdate = getQuestionToUpdate(questions, clientId);

                    if (questionToUpdate === undefined) {
                        return state;
                    }
                    updatedQuestion = {
                        ...questionToUpdate.question,
                        viewStatus: viewStatus,
                    };

                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionToUpdate.index,
                            ),
                        },
                    };
            }
        }
        case Actions.ADD_QUESTION_MULTIPLE_CHOICE_OPTION: {
            const { questionIndex, questionLevel, branchPosition } =
                action.payload as {
                    questionIndex: number;
                    questionLevel: QuestionLevel;
                    branchPosition: BranchPosition;
                };
            let questions;
            let options;
            let updatedQuestion;
            let updatedQuestions;
            const newOption = getInitialOptionState(
                QuestionType.MultipleChoice,
            );
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    questions = branch.branchData?.branchQuestions || [];
                    options = questions[questionIndex].options;
                    if (!options) {
                        return state;
                    } // unlikely case when options are undefined

                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: [
                            ...options,
                            newOption, // add new option to end of array
                        ],
                    };

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };

                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    } // unlikely case when options are undefined

                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: [
                            ...options,
                            newOption, // add new option to end of array
                        ],
                    };

                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );

                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                    };
            }
        }
        case Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_FIELD: {
            const {
                fieldKey,
                fieldValue,
                questionIndex,
                optionIndex,
                questionLevel,
                branchPosition,
            } = action.payload as {
                fieldKey: string;
                fieldValue: string;
                questionIndex: number;
                optionIndex: number;
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            let questions;
            let options;
            let updatedOption;
            let updatedQuestion;
            let updatedQuestions;

            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });

                    questions = branch.branchData?.branchQuestions || [];
                    options = questions[questionIndex].options;
                    if (!options) {
                        return state;
                    } // unlikely case when options are undefined

                    updatedOption = {
                        ...options[optionIndex],
                        [fieldKey]: fieldValue, // can add text value
                    };
                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: replaceItemInArray(
                            options,
                            updatedOption,
                            optionIndex,
                        ),
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };

                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });

                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    } // unlikely case when options are undefined

                    updatedOption = {
                        ...options[optionIndex],
                        [fieldKey]: fieldValue, // can add text value
                    };
                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: replaceItemInArray(
                            options,
                            updatedOption,
                            optionIndex,
                        ),
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };

                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );
                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                    };
            }
        }
        case Actions.REMOVE_QUESTION_MULTIPLE_CHOICE_OPTION: {
            const {
                questionIndex,
                selectedOptionClientId,
                questionLevel,
                branchPosition,
            } = action.payload as {
                questionIndex: number;
                selectedOptionClientId: string;
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };
            let questions;
            let options;
            let updatedOptions;
            let updatedQuestion;
            let updatedQuestions;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    questions = branch.branchData?.branchQuestions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    }
                    updatedOptions = removeItemInArrayByKey(
                        options,
                        "clientId",
                        selectedOptionClientId,
                    );

                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: updatedOptions,
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };
                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    }
                    updatedOptions = removeItemInArrayByKey(
                        options,
                        "clientId",
                        selectedOptionClientId,
                    );
                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: updatedOptions,
                    };
                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );
                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                    };
            }
        }
        case Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_OPTION_CODE: {
            const {
                questionIndex,
                optionIndex,
                answerCode,
                questionLevel,
                branchPosition,
            } = action.payload as {
                questionIndex: number;
                optionIndex: number;
                answerCode: SnomedCode | undefined;
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };

            let questions;
            let updatedQuestion;
            let updatedQuestions;
            let updatedOptions;
            let updatedOption;
            let options;
            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    questions = branch.branchData?.branchQuestions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    } // handle case where options are undefined

                    updatedOption = {
                        ...options[optionIndex],
                        answerCode, // add answerCode value
                    };
                    updatedOptions = replaceItemInArray(
                        options,
                        updatedOption,
                        optionIndex,
                    );
                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: updatedOptions,
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };
                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];
                    options = questions[questionIndex].options;

                    if (!options) {
                        return state;
                    } // handle case where options are undefined

                    updatedOption = {
                        ...options[optionIndex],
                        answerCode, // add answerCode value
                    };
                    updatedOptions = replaceItemInArray(
                        options,
                        updatedOption,
                        optionIndex,
                    );
                    updatedQuestion = {
                        ...questions[questionIndex],
                        options: updatedOptions,
                        viewStatus: ViewStatus.Edit, // to ensure question card will remain in Edit ViewState
                    };
                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );
                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                    };
            }
        }

        case Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_NUM_OF_ANSWERS: {
            const {
                questionIndex,
                allowMultipleAnswers,
                questionLevel,
                branchPosition,
            } = action.payload as {
                questionIndex: number;
                allowMultipleAnswers: boolean;
                questionLevel: QuestionLevel;
                branchPosition: BranchPosition;
            };

            let questions;
            let updatedQuestion;
            let updatedQuestions;

            switch (questionLevel) {
                case "Branch":
                    const branch = getBranch({ branchPosition, state });
                    questions = branch.branchData?.branchQuestions || [];
                    updatedQuestion = {
                        ...questions[questionIndex],
                        allowMultipleAnswers, // add allowMultipleAnswers value
                    };

                    const updatedBranch: ClientSideQuestion = {
                        ...branch,
                        branchData: {
                            branchQuestions: replaceItemInArray(
                                questions,
                                updatedQuestion,
                                questionIndex,
                            ),
                            condition:
                                branch.branchData?.condition ??
                                getInitialBranchCondition(),
                        },
                    };
                    return updateBranch({
                        branchPosition,
                        state,
                        updatedBranch,
                    });
                case "Questionnaire":
                default:
                    questions = state.selectedQuestionnaire?.questions || [];

                    updatedQuestion = {
                        ...questions[questionIndex],
                        allowMultipleAnswers, // add allowMultipleAnswers value
                    };

                    updatedQuestions = replaceItemInArray(
                        questions,
                        updatedQuestion,
                        questionIndex,
                    );
                    return {
                        ...state,
                        selectedQuestionnaire: {
                            ...(state.selectedQuestionnaire as QuestionnaireResponseExtended),
                            questions: updatedQuestions,
                        },
                    };
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
