import * as React from "react";

import { Button } from "@accurx/design";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

export type DownloadReportButtonProps = {
    practiceId: string;
};

export const DownloadReportButton = ({
    practiceId,
}: DownloadReportButtonProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const trackUsageDownloaded = () => {
        ChainAnalyticsTracker.trackPatientTriageUsageDownloaded({
            ...analyticsLoggedInProps,
            objectName: PatientTriageSettingObjectName.DOWNLOAD,
            objectType: PatientTriageSettingObjectType.BUTTON,
        });
        window.location.href = `/api/conversation/statistics/${practiceId}/patientInitiated/report`;
    };

    return (
        <Button
            className="mb-2"
            theme="secondary"
            icon={{ name: "Save" }}
            onClick={trackUsageDownloaded}
            text="Download report"
        />
    );
};
