import React from "react";

import { Flex, Text } from "@accurx/design";

import { StyledValidationCard } from "./ValidationCard.styles";

export type ValidationCardProps = {
    heading: string;
    validatedAt: string;
    complexityBadge: JSX.Element;
    additionalInfo?: Optional<string>;
};

export const ValidationCard = ({
    heading,
    validatedAt,
    complexityBadge,
    additionalInfo,
}: ValidationCardProps) => {
    return (
        <StyledValidationCard>
            <Flex flexDirection="column" gap="1.5">
                <Text skinny variant="label">
                    {heading}
                </Text>
                <Flex flexDirection="column" gap="0.5" alignItems="start">
                    {complexityBadge}
                    {additionalInfo && <Text skinny>{additionalInfo}</Text>}
                </Flex>
                <Flex justifyContent="space-between">
                    <Text skinny variant="preview" colour="metal">
                        Only visible for people in your org
                    </Text>
                    <Text skinny variant="preview" colour="metal">
                        {validatedAt}
                    </Text>
                </Flex>
            </Flex>
        </StyledValidationCard>
    );
};
