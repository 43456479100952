import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledUl = styled.ul`
    margin: 0;
    padding: 0 0 0 ${Tokens.SIZES[2]};
`;

export const StyledLi = styled.li``;

export const StyledFlexDivNoWrap = styled.div`
    display: flex;
    white-space: nowrap;
`;
export const StyledFlexChildInitialInline = styled.span`
    width: 100%;
    margin-right: ${Tokens.SIZES[2]};
`;

export const StyledCardLineBreak = styled.hr`
    border: none;
    border-top: solid 1px ${Tokens.COLOURS.greyscale.silver};
    margin: ${Tokens.SIZES[4]} 0 ${Tokens.SIZES[2]} 0;
`;

export const StyledStackPanelListWrapper = styled(StackPanel)`
    padding: 0;
    margin: 0;
    list-style-type: none;
`;
