import { useState } from "react";

import { useCurrentWorkspace } from "@accurx/auth";
import {
    Compose,
    ComposePatient,
    ComposeProvider,
    ContactDetail,
    OnMessageSendFn,
} from "@accurx/compose";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { SaveToRecordAction } from "@accurx/inbox";
import {
    ContentTypesWithSaveToRecordSupport,
    useMedicalRecordConnection,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useSendMessageMutation } from "./hooks/useSendMessageMutation";

type ComposeNewMessageProps = {
    patient: ComposePatient;
    contactDetails: ContactDetail[];
};

export const ComposeNewMessage = ({
    patient,
    contactDetails,
}: ComposeNewMessageProps) => {
    const sendMessageMutation = useSendMessageMutation();
    const history = useHistory();
    const workspace = useCurrentWorkspace();
    const connection = useMedicalRecordConnection();
    const [saveToRecordData, setSaveToRecordData] = useState<{
        patientId: string;
        conversationSource: Conversation["source"];
        itemServerId: string;
        contentType: Extract<
            ContentTypesWithSaveToRecordSupport,
            "PatientSms" | "PatientEmail"
        >;
        snomedCodes: string[];
    } | null>(null);

    const handleMessageSend: OnMessageSendFn = (args) => {
        // We only save to record if we're connected to EMR
        // and it allows to save to record
        // and compose is set to save to record
        const shouldSaveToRecord =
            connection.status === "Connected" &&
            connection.capabilities.saveToRecord &&
            args.isSaveToRecordEnabled;

        sendMessageMutation.mutate(
            {
                patientExternalIds: patient.externalIds,
                attachments: args.attachments,
                nhsAdviceLink: args.nhsAdviceLink,
                contactDetails: args.contactDetails,
                isPatientResponseEnabled: args.isPatientResponseEnabled,
                messageBody: args.messageBody,
                messageSignature: args.messageSignature,
                sendAt: args.sendAt,
                template: args.template,
                selfBookLink: args.selfBookLink,
                shouldSaveToRecord,
            },
            {
                onSuccess: (res, variables) => {
                    if (!res) {
                        Log.error(
                            "No message returned, unable to redirect user to conversation.",
                            { tags: { product: "Compose" } },
                        );
                        return;
                    }

                    if (
                        variables.shouldSaveToRecord &&
                        res.patientId &&
                        res.ticketIdentity.id
                    ) {
                        const snomedCodes = args.template?.value.snomedCodes
                            ? args.template.value.snomedCodes.map(
                                  ({ id }) => id,
                              )
                            : [];

                        setSaveToRecordData({
                            conversationSource: {
                                system: "Ticket",
                                ticketIdentity: {
                                    type: res.ticketIdentity.type,
                                    id: res.ticketIdentity.id,
                                },
                            },
                            patientId: res.patientId,
                            itemServerId: res.ticketIdentity.id,
                            contentType: res.contentType,
                            snomedCodes,
                        });
                    }

                    history.push(
                        `/compose/w/${workspace.orgId}/conversation/${res.conversationId}`,
                    );
                },
                onError: () => {
                    toast(
                        <UI.Feedback
                            title="Something went wrong while trying to send your message"
                            colour="error"
                        />,
                    );
                },
            },
        );
    };

    const patientKey = patient.externalIds
        .map(({ type, value }) => `${type}${value}`)
        .join("-");

    return (
        <>
            <ComposeProvider
                /*
                When the patient changes, we need to ensure that the compose provider
                and it's children are re-rendered, so that the patients contact details
                and the body of the message are reset.
                */
                key={patientKey}
                settings={{
                    greeting: "Hi there",
                    editableBody: "",
                    editableSignature: "Bye bye",
                    maxAttachmentCount: 1,
                    canUseQuestionnaires: true,
                    contactDetails:
                        contactDetails.find((c) => c.method === "Mobile") ??
                        contactDetails[0],
                    conversationParticipant: "WithPatient",
                }}
            >
                <Compose
                    patient={patient}
                    contactDetails={contactDetails}
                    patientMatchesCurrentMedicalRecordPatient={true}
                    onMessageSend={handleMessageSend}
                    isMessageSending={sendMessageMutation.isLoading}
                    conversationId={null}
                />
            </ComposeProvider>
            {saveToRecordData !== null && (
                <SaveToRecordAction
                    patientId={saveToRecordData.patientId}
                    itemServerId={saveToRecordData.itemServerId}
                    patientExternalIds={patient.externalIds}
                    contentType={saveToRecordData.contentType}
                    conversationSource={saveToRecordData.conversationSource}
                    snomedCodeIds={saveToRecordData.snomedCodes}
                />
            )}
        </>
    );
};
