import { Checkbox } from "@accurx/inbox-design-library";
import { userflowIds } from "domains/compose/userflow";

import { NoWrapCheckboxLabel } from "./AllowReplyCheckbox.styles";
import { PATIENT_RESPONSE_CHECKBOX_ID } from "./PatientResponseCheckbox.constants";

type AllowReplyCheckboxProps = {
    onChange: () => void;
    checked: boolean;
    disabled: boolean;
};

export const AllowReplyCheckbox = ({
    onChange,
    checked,
    disabled,
}: AllowReplyCheckboxProps) => {
    return (
        <Checkbox
            id={PATIENT_RESPONSE_CHECKBOX_ID}
            checked={checked}
            onCheckChange={onChange}
            text="Allow reply"
            data-userflow-id={userflowIds.patientResponseCheckbox}
            disabled={disabled}
        >
            <NoWrapCheckboxLabel forwardedAs="span">
                Allow reply
            </NoWrapCheckboxLabel>
        </Checkbox>
    );
};
