import { Tokens } from "@accurx/design";

export const BASE_AXIS_CONFIG = {
    title: {
        enabled: false,
    },
    labels: {
        style: {
            fontWeight: "bolder",
            fontSize: "14",
            color: Tokens.COLOURS.greyscale.metal,
        },
    },
} as const;

export const BASE_OPTIONS = {
    credits: {
        enabled: false,
    },
    tooltip: {
        outside: false,
        useHTML: false,
    },
    title: {
        text: undefined,
    },
} as const;
