/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import {
    Button,
    Card,
    ErrorSummary,
    ErrorSummaryProvider,
    Feedback,
    FormFieldWrapper,
    SelectItem,
    SingleSelect,
    Switch,
    Text,
    useErrorSummary,
} from "@accurx/design";

import { AnalyticsMapper, ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import {
    formatDateAsFriendlyDate,
    formatDateTimeAsISO,
    getDateTimeFromISOString,
    getSuspendDays,
    isValidSuspendTime,
    timeIn30MinuteIncrements,
} from "../../utils";

export type DisableUntilSectionPropsType = {
    isAdmin: boolean;
    suspendUntil: string | null;
    isSuspended: boolean;
    allowAdminRequests: boolean;
    handleUpdateDisableUntilSettings: (request: {
        disableUntil: string | null;
        reason: string;
        comment: string;
        allowAdminRequests: boolean;
    }) => void;
};

export const DisableUntilSection = (props: DisableUntilSectionPropsType) => {
    return (
        <ErrorSummaryProvider>
            <DisableUntilForm {...props} />
        </ErrorSummaryProvider>
    );
};

const DisableUntilForm = ({
    isAdmin,
    isSuspended: initialIsSuspended,
    suspendUntil: initialSuspendUntil,
    allowAdminRequests: initialAllowAdminRequests,
    handleUpdateDisableUntilSettings,
}: DisableUntilSectionPropsType): JSX.Element | null => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [isEditing, setIsEditing] = useState(false);

    const [initialSuspendUntilDate, initialSuspendUntilTime] =
        getDateTimeFromISOString(initialSuspendUntil);
    const [disableUntilDate, setDisableUntilDate] = useState(
        initialSuspendUntilDate,
    );
    const [disableUntilTime, setDisableUntilTime] = useState(
        initialSuspendUntilTime,
    );
    const [suspendComment, setSuspendComment] = useState("");
    const [suspendReason, setSuspendReason] = useState("");
    const [isSuspended, setIsSuspended] = useState(initialIsSuspended);
    const [allowAdminRequests, setAllowAdminRequests] = useState(
        initialAllowAdminRequests,
    );

    const isDisabled = isAdmin === false;

    const { clearAllErrors, addError, errors } = useErrorSummary();

    const suspendDays = getSuspendDays();

    const validateDisableUntilSection = () => {
        clearAllErrors();
        let hasError = false;

        if (isSuspended && !disableUntilDate) {
            addError({
                id: "disableDate",
                body: "Please select a resume date",
            });
            hasError = true;
        }

        if (isSuspended && !disableUntilTime) {
            addError({
                id: "disableTime",
                body: "Please select a resume time",
            });
            hasError = true;
        } else if (
            isSuspended &&
            !isValidSuspendTime(
                formatDateTimeAsISO(disableUntilDate, disableUntilTime),
            )
        ) {
            addError({
                id: "disableTime",
                body: "Please select a resume time that is not in the past",
            });
            hasError = true;
        }

        if (isSuspended && !suspendReason) {
            addError({
                id: "disableReason",
                body: "Please select a reason for suspending patient triage",
            });
            hasError = true;
        }

        return hasError;
    };

    const handleSubmit = () => {
        const hasErrors = validateDisableUntilSection();

        if (hasErrors) return;

        const genericProps: ChainAnalyticsTracker.PatientTriageManagementSettingSaveSuspendProps =
            {
                ...analyticsLoggedInProps,
                isEnabled: isSuspended,
                resumeDate:
                    disableUntilDate && disableUntilTime
                        ? formatDateTimeAsISO(
                              disableUntilDate,
                              disableUntilTime,
                          )
                        : null,
                isSuspendMedicalRequestOnlyEnabled: isSuspended
                    ? allowAdminRequests
                    : null,
                reason: !!suspendReason
                    ? AnalyticsMapper.mapToPascalCase(suspendReason)
                    : null,
                objectName: PatientTriageSettingObjectName.SAVE,
                objectType: PatientTriageSettingObjectType.BUTTON,
            };
        ChainAnalyticsTracker.trackPatientTriageManagementSettingSuspend(
            genericProps,
        );

        const analyticsProps: ChainAnalyticsTracker.PatientTriageDisableProps =
            {
                ...analyticsLoggedInProps,
                reason: suspendReason,
                comment: suspendComment,
                allowAdminRequests: allowAdminRequests,
                disableUntil:
                    disableUntilDate && disableUntilTime
                        ? formatDateTimeAsISO(
                              disableUntilDate,
                              disableUntilTime,
                          )
                        : null,
            } as any;
        ChainAnalyticsTracker.trackPatientTriageTemporarilyDisabled(
            analyticsProps,
        );

        if (!isSuspended) {
            handleUpdateDisableUntilSettings({
                disableUntil: null,
                reason: "",
                comment: "",
                allowAdminRequests: false,
            });
        } else {
            handleUpdateDisableUntilSettings({
                disableUntil:
                    disableUntilDate && disableUntilTime
                        ? formatDateTimeAsISO(
                              disableUntilDate,
                              disableUntilTime,
                          )
                        : null,
                reason: suspendReason,
                comment: suspendComment,
                allowAdminRequests,
            });
        }

        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        clearAllErrors();

        setDisableUntilDate(initialSuspendUntilDate);
        setDisableUntilTime(initialSuspendUntilTime);
        setSuspendComment("");
        setSuspendReason("");
        setIsSuspended(initialIsSuspended);
        setAllowAdminRequests(initialAllowAdminRequests);
    };

    return (
        <Card
            props={{ className: "mb-3" }}
            header={
                <Text as="h3" skinny variant="subtitle">
                    Temporarily suspend Patient Triage access
                </Text>
            }
            footer={
                <div className="d-flex justify-content-end">
                    {!isDisabled && isEditing && (
                        <>
                            <Button
                                theme="primary"
                                data-testid="disableSubmit"
                                type="button"
                                onClick={handleSubmit}
                                disabled={isDisabled}
                                text="Save changes"
                            />
                            <Button
                                className="ml-3"
                                dimension="medium"
                                theme="transparent"
                                onClick={handleCancel}
                                data-testid="suspendPTServiceCancel"
                                text="Cancel"
                                type="button"
                            />
                        </>
                    )}

                    {!isEditing && (
                        <Button
                            disabled={isDisabled}
                            dimension="medium"
                            theme="primary"
                            icon={{ name: "Pencil" }}
                            onClick={() => setIsEditing(true)}
                            data-testid="suspendPTServiceEdit"
                            text="Edit"
                            type="button"
                        />
                    )}
                </div>
            }
        >
            <div className="mb-3">
                <Feedback
                    colour="secondary"
                    content="This change will temporarily override the out of hours settings during the suspension."
                />
            </div>

            <Text>
                Turning on this option will temporarily suspend patient access
                for up to 4 days. You can also choose to suspend the medical
                requests access only.
            </Text>

            <Text variant="label">Suspend Patient Triage access</Text>

            {isEditing && (
                <>
                    <ErrorSummary isShown={true} />
                    <div className="d-flex mt-2">
                        <Switch
                            testId="disableSwitch"
                            onChange={(e): void => {
                                setIsSuspended(e.target.checked);
                            }}
                            disabled={isDisabled}
                            defaultChecked={isSuspended}
                            id="disableUntilSwitch"
                        />
                        <Text skinny>{isSuspended ? "On" : "Off"}</Text>
                    </div>
                </>
            )}

            {!isEditing && (
                <Text skinny props={{ "data-testid": "SuspendPT" }}>
                    {isSuspended ? "On" : "Off"}
                </Text>
            )}

            {isSuspended && !isEditing && (
                <div className="mt-3">
                    <div>
                        <Text variant="label">
                            Suspend medical request access only
                        </Text>
                        <Text
                            skinny
                            props={{
                                "data-testid": "SuspendPTAdminRequests",
                            }}
                        >
                            {allowAdminRequests ? "Yes" : "No"}
                        </Text>
                    </div>

                    <div>
                        <Text variant="label">Resume date</Text>
                        <Text
                            props={{
                                "data-testid": "SuspendPTDate",
                            }}
                        >
                            {disableUntilDate
                                ? formatDateAsFriendlyDate(disableUntilDate)
                                : "-"}
                        </Text>
                    </div>
                    <div>
                        <Text variant="label">Resume time</Text>
                        <Text
                            props={{
                                "data-testid": "SuspendPTTime",
                            }}
                        >
                            {disableUntilTime ? disableUntilTime : "-"}
                        </Text>
                    </div>
                </div>
            )}

            {isSuspended && isEditing && (
                <div>
                    <div className="my-3">
                        <input
                            type="checkbox"
                            data-testid="allowAdminRequestsSwitch"
                            onChange={(e): void => {
                                setAllowAdminRequests(e.target.checked);
                            }}
                            defaultChecked={allowAdminRequests}
                            id="allowAdminRequestsSwitch"
                        />
                        <label
                            htmlFor="allowAdminRequestsSwitch"
                            className="ml-2"
                        >
                            Suspend medical request access only
                        </label>
                    </div>

                    <FormFieldWrapper
                        as="div"
                        label="Resume date"
                        subLabel="No longer than 4 days from now"
                        errors={[
                            errors.find((error) => error.id === "disableDate")
                                ?.body as string,
                        ]}
                    >
                        <SingleSelect
                            id="disableDate"
                            onValueChange={(value): void => {
                                setDisableUntilDate(value);
                            }}
                            defaultValue={disableUntilDate ?? ""}
                        >
                            {suspendDays.map((day) => (
                                <SelectItem
                                    value={
                                        getDateTimeFromISOString(
                                            day,
                                        )[0] as string
                                    }
                                >
                                    {formatDateAsFriendlyDate(day)}
                                </SelectItem>
                            ))}
                        </SingleSelect>
                    </FormFieldWrapper>

                    <FormFieldWrapper
                        as="div"
                        label="Resume time"
                        errors={[
                            errors.find((error) => error.id === "disableTime")
                                ?.body as string,
                        ]}
                    >
                        <SingleSelect
                            id="disableTime"
                            onValueChange={(value): void => {
                                setDisableUntilTime(value);
                            }}
                            defaultValue={disableUntilTime ?? ""}
                        >
                            {timeIn30MinuteIncrements.map((time) => (
                                <SelectItem value={time}>{time}</SelectItem>
                            ))}
                        </SingleSelect>
                    </FormFieldWrapper>

                    <FormFieldWrapper
                        as="div"
                        label="Reason for suspending"
                        errors={[
                            errors.find((error) => error.id === "disableReason")
                                ?.body as string,
                        ]}
                    >
                        <SingleSelect
                            id="disableReason"
                            onValueChange={(value): void => {
                                setSuspendReason(value);
                            }}
                            defaultValue={suspendReason}
                        >
                            <SelectItem value="Sick doctor">
                                Sick doctor
                            </SelectItem>
                            <SelectItem value="Infrastructure issue">
                                Infrastructure issue
                            </SelectItem>
                            <SelectItem value="Overwhelm">Overwhelm</SelectItem>
                            <SelectItem value="Other">Other</SelectItem>
                        </SingleSelect>
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        as="div"
                        label={
                            <>
                                <b>Comment</b> (optional)
                            </>
                        }
                        labelProps={{
                            htmlFor: "disableComment",
                        }}
                    >
                        <textarea
                            className="form-control"
                            rows={3}
                            cols={80}
                            data-testid="disableCommentTextArea"
                            name="disableComment"
                            id="disableComment"
                            disabled={isDisabled}
                            onChange={(e): void => {
                                setSuspendComment(e.target.value);
                            }}
                            value={suspendComment}
                        />
                    </FormFieldWrapper>
                </div>
            )}
        </Card>
    );
};
