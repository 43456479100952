import { useCallback } from "react";

import { api } from "domains/concierge/internal/api/ticket";
import { useConciergeMeta } from "domains/concierge/internal/context";
import {
    ConversationGroupFetcher,
    useConversationGroupQuery,
} from "domains/concierge/internal/hooks/useConversationGroupQuery";
import { ConversationGroupHook } from "domains/concierge/internal/types/ConversationGroupHook";
import { Conversation } from "domains/concierge/types";

export const usePatientConversationGroupQuery: ConversationGroupHook<{
    status: "Open" | "Done";
    patientInternalId?: string;
}> = ({ patientInternalId = "", status }) => {
    const { workspaceId } = useConciergeMeta();

    const fetcher = useCallback<ConversationGroupFetcher>(
        async (continuationToken) => {
            const { updates, response } = await api.getFilteredTicketView({
                workspaceId: workspaceId,
                patientId: patientInternalId,
                isDone: status === "Done",
                ordering: {
                    urgentFirst: status === "Open",
                },
                continuationToken,
            });

            return {
                continuationToken: response.continuationToken ?? undefined,
                updates,
            };
        },
        [workspaceId, patientInternalId, status],
    );

    /**
     * Definition of a Pseudo ticket:
     * A Pseudo ticket is defined as a conversation that only includes 1 sent message.
     *
     * In other folders, we typically display pseudo-tickets in the ‘sent’ folder view.
     * However, since the patient view lacks a ‘sent’ view, we display pseudo-tickets in the ‘done’ view.
     */
    const filterDoneAndPseudoTickets: Conversation["status"][] = [
        "Done",
        "None",
    ];

    return useConversationGroupQuery(
        {
            filters: [
                { type: "Source", value: "Ticket" },
                {
                    type: "Patient",
                    value: {
                        // we will not subscribe to a group if it's disabled (that's disbaled when there is no accurxId)
                        // so it's ok to pass possible undefined value here
                        patientInternalId,
                    },
                },
                {
                    type: "Status",
                    value:
                        status === "Open" ? "Open" : filterDoneAndPseudoTickets,
                },
            ],
            sortOptions:
                status === "Open"
                    ? [
                          { key: "IsUrgent", direction: "desc" },
                          { key: "LastUpdated", direction: "desc" },
                          { key: "Id", direction: "desc" },
                      ]
                    : [
                          { key: "LastUpdated", direction: "desc" },
                          { key: "Id", direction: "desc" },
                      ],
            loggingInfo: {
                name: "Patient conversations",
                tags: {
                    statusFilter: status,
                },
            },
            fetcher,
        },
        { enabled: !!patientInternalId },
    );
};
