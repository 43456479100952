import {
    StyledBarContainer,
    StyledIconButton,
} from "./ExpandMinimiseBar.styles";

export const ExpandMinimiseBar = ({
    onExpandClick,
    isHeightRestricted,
    onMinimiseClick,
}: {
    onExpandClick?: () => void;
    onMinimiseClick?: () => void;
    isHeightRestricted: boolean;
}) => (
    <StyledBarContainer justifyContent="flex-end" alignItems="center" gap="1">
        {onExpandClick && (
            <StyledIconButton
                aria-label={isHeightRestricted ? "Expand" : "Collapse"}
                icon={{
                    name: isHeightRestricted ? "Expand" : "Collapse",
                    title: isHeightRestricted ? "Expand" : "Collapse",
                }}
                onClick={onExpandClick}
            />
        )}
        {onMinimiseClick && (
            <StyledIconButton
                aria-label="Minimise"
                icon={{ name: "Minimise", title: "Minimise" }}
                onClick={onMinimiseClick}
            />
        )}
    </StyledBarContainer>
);
