/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { NhsNumberHelpers } from "@accurx/shared";

/* eslint-disable import/no-duplicates */
import differenceInYears from "date-fns/differenceInYears";
import isAfter from "date-fns/isAfter";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

/* eslint-enable import/no-duplicates */

export const errorStrings = {
    noInputDetected:
        "You must input an NHS number and Date of birth for each patient you want to add on a separate line",
    multipleNhsNumbers: "Multiple NHS numbers detected",
    invalidNhsNumber: "Couldn't detect a valid NHS number",
    futureDateOfBirth:
        "Couldn't detect a valid Date of birth. Patient is born in the future",
    patientTooOld: "Couldn't detect a valid Date of birth. Patient is too old",
    invalidDateOfBirth: "Couldn't detect a valid Date of birth",
} as const;

export type ParsePatientsResult =
    | ParsePatientsResultSuccess
    | ParsePatientsResultError;

type ParsePatientsResultSuccess = {
    success: true;
    patients: PatientDetails[];
};

type ParsePatientsResultError = {
    patients?: undefined;
    success: false;
} & ErrorDetails;

type ErrorDetails =
    | {
          errorReason: "ParseError";
          errorsByLine: ParsingErrorsByLine;
      }
    | {
          errorReason: "NoInput";
      };

export type PatientDetails = {
    nhsNumber: string;
    dateOfBirth: Date;
};

export type ParsingErrorsByLine = Record<
    Exclude<ParseError, "noInputDetected">,
    number[]
>;

type ParseError = keyof typeof errorStrings;

const dateOfBirthFormats = [
    "d M yyyy",
    "d.M.yyyy",
    "d-M-yyyy",
    "d/M/yyyy",
    "d-MMM-yyyy",
    "d-MMMM-yyyy",
    "d MMM yyyy",
    "d MMMM yyyy",
    "yyyy-M-d",
    "yyyy-MMM-d",
    "yyyy-MMMM-d",
    "yyyy/M/d",
];

/**
 * Parses a block of text, to find date of birth and nhs numbers.
 * Assume each patient is on a new line, and there must be a DOB and NHS number to succeed.
 * For now, we are not supporting multiple patients on one line and error if this occurs.
 */
export const parsePatients = (text: string): ParsePatientsResult => {
    const lines = splitIntoLines(text);

    if (lines.length === 0) {
        return {
            success: false,
            errorReason: "NoInput",
        };
    }

    const errorsByLine: ParsingErrorsByLine = {
        multipleNhsNumbers: [],
        invalidNhsNumber: [],
        futureDateOfBirth: [],
        patientTooOld: [],
        invalidDateOfBirth: [],
    };

    const patientParseResults = lines.map((patient, index) => {
        const lineNumber = index + 1;
        const nhsNumbers = parseNhsNumbers(patient);

        let nhsNumber;
        let dateOfBirth;

        if (nhsNumbers.length > 1) {
            errorsByLine.multipleNhsNumbers.push(lineNumber);
        } else if (nhsNumbers.length === 0) {
            errorsByLine.invalidNhsNumber.push(lineNumber);
        } else {
            nhsNumber = nhsNumbers[0];
        }

        const dates = parseDates(patient);
        const sortedDates = dates.sort((a, b) => +b - +a);
        // If there are multiple dates, assume the oldest date is the date of birth
        const parsedDateOfBirth = sortedDates.pop();

        if (!parsedDateOfBirth) {
            errorsByLine.invalidDateOfBirth.push(lineNumber);
        } else {
            const hasRealisticDOB =
                patientHasRealisticDateOfBirth(parsedDateOfBirth);

            if (hasRealisticDOB.success) {
                dateOfBirth = parsedDateOfBirth;
            } else {
                switch (hasRealisticDOB.error) {
                    case "Not born yet":
                        errorsByLine.futureDateOfBirth.push(lineNumber);
                        break;

                    case "Too old":
                        errorsByLine.patientTooOld.push(lineNumber);
                        break;

                    default:
                        errorsByLine.invalidDateOfBirth.push(lineNumber);
                        break;
                }
            }
        }

        return nhsNumber && dateOfBirth ? { nhsNumber, dateOfBirth } : {};
    });

    const patients = patientParseResults.filter(
        (p): p is PatientDetails =>
            p.nhsNumber !== undefined && p.dateOfBirth !== undefined,
    );

    if (patients.length !== patientParseResults.length) {
        return {
            success: false,
            errorsByLine,
            errorReason: "ParseError",
        };
    }

    return {
        success: true,
        patients,
    };
};

export const createFormattedListOfErrors = (
    errorsByLine: Record<Exclude<ParseError, "noInputDetected">, number[]>,
) => {
    const errors: string[] = [];

    Object.entries(errorsByLine).map(([errorName, lineNumbers]) => {
        if (lineNumbers.length > 0) {
            const pluralizedLine = lineNumbers.length > 1 ? "lines" : "line";
            const errorMessage = `${
                errorStrings[errorName as ParseError]
            } on ${pluralizedLine} ${lineNumbers.join(", ")}.`;

            errors.push(errorMessage);
        }
    });

    return errors;
};

const splitIntoLines = (text: string) => {
    // Can have new lines at the end, remove these
    const trimmedText = text.trimEnd();
    if (trimmedText === "") {
        return [];
    }
    const newLinePattern = /\r?\n/;
    return trimmedText.split(newLinePattern);
};

type RealisticDateOfBirthSuccess = { success: true };
type RealisticDateOfBirthFailure = {
    success: false;
    error: "Too old" | "Not born yet";
};

const patientHasRealisticDateOfBirth = (
    dateOfBirth: Date,
): RealisticDateOfBirthSuccess | RealisticDateOfBirthFailure => {
    const MAX_AGE = 120;
    const today = new Date();

    if (isAfter(dateOfBirth, today)) {
        return {
            success: false,
            error: "Not born yet",
        };
    }

    if (differenceInYears(today, dateOfBirth) >= MAX_AGE) {
        return {
            success: false,
            error: "Too old",
        };
    }

    return {
        success: true,
    };
};

// match any 10 digit number allowing special characters (even if they are in the wrong position)
const nhsNumberMatcher = /\b\d{3}[. -]?\d{3}[. -]?\d{4}\b/g;
const parseNhsNumbers = (text: string): string[] =>
    text
        .match(nhsNumberMatcher)
        // Strip any non-numeric characters
        ?.map((match) => match.replace(/[^0-9]/g, ""))
        ?.filter(
            (match) => NhsNumberHelpers.validateNhsNumber(match).success,
        ) ?? [];

const convertDatePatternToRegex = (dateParserPattern: string): string => {
    const regexPattern = dateParserPattern
        // Escape special characters e.g. ',','-'
        .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        // Replace date pattern matches
        .replace("d", "[0-9]{1,2}")
        .replace(/MMM|MMMM/, "[a-z,A-Z]{3,}")
        .replace("M", "[0-9]{1,2}")
        .replace("yyyy", "[0-9]{4}");

    // Add boundaries to ensure we don't match partial e.g. missing the start 1[2/23/1995]
    return `\\b${regexPattern}\\b`;
};

const dateMatcher = new RegExp(
    dateOfBirthFormats.map(convertDatePatternToRegex).join("|"),
    "g",
);

const parseDates = (text: string): Date[] =>
    (text
        .match(dateMatcher)
        ?.map(parseDate)
        ?.filter((date) => date != null) as Date[]) ?? [];

const parseDate = (text: string): Date | null => {
    for (const format of dateOfBirthFormats) {
        const date = parse(text, format, new Date());
        if (isValid(date)) {
            return date;
        }
    }
    return null;
};
