import * as UI from "@accurx/design";
import { css } from "styled-components";

/**
 * Tabs in the inbox can be
 * - navigational, where they take form of links
 * - non-navigational, where they take form of buttons
 *
 * These styles can be applied to either
 * for consistent look and feel
 */

export const tabItemBaseStyles = css`
    position: initial;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.62rem ${UI.Tokens.SIZES[0.5]};
    margin: ${UI.Tokens.SIZES[0.5]};

    min-height: 40px;
    min-width: 49px;

    border: none;
    border-radius: ${UI.Tokens.BORDERS.radius};

    color: ${UI.Tokens.COLOURS.greyscale.metal};
    // Typography - if needed set children to inherit these styles
    font-size: ${UI.Tokens.TYPOGRAPHY.preview.desktop.fontSize};
    line-height: ${UI.Tokens.TYPOGRAPHY.preview.desktop.lineHeight};

    // Make icons smaller
    svg {
        width: 20px;
        height: 20px;
    }

    // In case there's an icon, override the color
    svg path {
        fill: ${UI.Tokens.COLOURS.greyscale.metal};
    }
    text-align: center;
`;

export const tabItemHoverStyles = css`
    color: ${UI.Tokens.COLOURS.primary.blue[100]};
    svg path {
        fill: ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    background-color: ${UI.Tokens.COLOURS.primary.blue[10]};
`;

export const tabItemFocusStyles = css`
    background-color: ${UI.Tokens.COLOURS.primary.blue[10]};

    box-shadow: 0px 0px 0px 3px ${UI.Tokens.COLOURS.primary.blue[50]};
    outline: none;
`;

export const tabItemActiveStyles = css`
    color: ${UI.Tokens.COLOURS.primary.blue[100]};
    svg path {
        fill: ${UI.Tokens.COLOURS.primary.blue[100]};
    }

    // Selected item border:
    // Needs to be absolutely
    // positioned relative to the tab list item <li>
    &:before {
        background-color: ${UI.Tokens.COLOURS.primary.blue[100]};

        content: "";
        height: calc(${UI.Tokens.SIZES[0.5]} / 2);
        display: block;
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
    }
`;
