export enum BatchType {
    COPD = "COPD questionnaire (sponsored by GSK)",
    FLOREY = "Florey",
    INHALER = "Inhaler adherence SMS series",
    SELFBOOK = "Self-Book",
    MESSAGE = "Message",
    NONE = "None",
}

export enum statusLabel {
    Scheduled = "Scheduled",
    Sending = "Sending",
    Saving = "Saving",
    Done = "Done",
    Saved = "Saved",
    Cancelled = "Cancelled",
}

export interface BatchMessageCollection {
    summaries: BatchMessageSummary[];
}

export interface BatchMessageSummary {
    id: string;
    name: string;
    batchStatus: statusLabel;
    batchType: string;
    bookedCount: number;
    isCancellable: boolean;
    sender: BatchMessageUser;
    messageTemplate: string;
    status: string;
    sendAt: string;
    sendTime: string;
    totalItems: number;
    totalNonTextable: number;
    totalSent: number;
    totalDelivered: number;
    totalFailed: number;
    totalWriteSuccess: number;
    totalWriteFailure: number;
    type: string;
}

export interface BatchMessageModalDetails {
    id: string;
    batchStatus: statusLabel;
    batchType: string;
    bookedCount: number;
    sender: BatchMessageUser;
    messageTemplate: string;
    savingStatus: statusLabel;
    sendAt: string;
    totalPatients: number;
    nonTextablePatients: number;
    failedToSave: number;
    failedToDeliver: number;
    delivered: number;
    staggerOverDays: number | null;
    createdTime: string;
    type: string;
    isFloreyCsvDownloadSupported: boolean;
    slotType: string | null;
    declineSettings: BatchDeclineSettings | null;
    saveMessageToRecord: boolean;
    totalItems: number;
    totalProcessed: number;
    totalMessages: number;
}

export interface BatchMessageUser {
    id: string;
    fullName: string;
    email: string;
}

export interface BatchSnomedCode {
    term: string;
    conceptId: string;
}

// Structure for the Florey Snomed Code Endpoint
export interface BatchFloreySnomedCodes {
    codes: BatchSnomedCode[];
}

export interface FloreyTemplate {
    title: string;
    id: string;
    medicalCodeIdentity: {
        debugDisplay: string;
        code: string;
        codeScheme: number;
    };
    templateBody: string;
    canSchedule: boolean;
    availability: number;
    type: number;
    availableInBatchForFree: boolean;
    canAutoSaveToRecord: boolean;
    defaultUserGroupId: string;
}

export interface FloreyListGroup {
    messageTemplates: FloreyTemplate[];
    heading: string;
}

export interface FloreysList {
    groups: FloreyListGroup[];
}

export interface TemplatePath {
    folders: TemplateFolders[];
}

export interface TemplateFolders {
    name: string;
}

export interface TemplateAttachment {
    id: number;
    fileSize: number;
    fileName: string;
}

export interface MessageTemplate {
    title: string;
    body: string;
    id: string;
    path: TemplatePath;
    snomedCodes: BatchSnomedCode[];
    canSchedule: boolean;
    allowReplyByDefault: boolean;
    shouldSaveBatchToRecord: boolean;
    attachments?: TemplateAttachment[];
}

export const MaxSingleSMSSize = 160;
export const MaxBatchSMSSize = 612;
export const MaxBatchSelfbookSMSSize = 306;

export const BatchStatus = {
    DemographicsPopulated: "DemographicsPopulated",
    FetchingDemographics: "FetchingDemographics",
};

export class MessageTemplateOwner {
    static User = "User";
    static Org = "Org";
    static AccuRx = "Accurx";
}

export enum FloreyOwner {
    Org = "Org",
    Accurx = "Accurx",
}

interface SendMessageBaseParameters {
    messageTemplate: string;
    documentId?: string | null;
}

export type BatchDeclineSettings = {
    confirmationText: string;
    snomedConceptId: string;
};

export interface SendMessageParameters extends SendMessageBaseParameters {
    practiceId: string;
    batchId: string;
    snomedCode: string | null;
    floreyId?: string | null;
    canSaveToRecord?: boolean;
    assignedUserGroupId?: string | null;
    sendAt?: SendAt | null;
    staggerOverDays?: number | null;
    declineSettings?: BatchDeclineSettings;
    slotType?: string | null;
    selfBookTimeoutDays?: number | null;
    shouldSaveBatchToRecord?: boolean;
    messageTemplateAttachments?: {
        templateId: number;
        attachmentIds: number[];
    };
}

export interface SendBatchMessageRequest extends SendMessageBaseParameters {
    id: string;
    snomedConceptId: string | null;
    floreyConditionId?: string | null;
    autoSaveToRecord?: boolean;
    sendAt?: SendAt | null;
    staggerOverDays?: number | null;
    slotType?: string | null;
    selfBookTimeoutDays?: number | null;
    assignedUserGroupId?: string | null;
    declineSettings?: BatchDeclineSettings;
    saveMessageToRecord?: boolean | null;
    messageTemplateAttachments?: {
        templateId: number;
        attachmentIds: number[];
    };
}

export interface PatientToUpload {
    nhsNumber: string;
    dateOfBirth: string;
}

export interface UploadPatientsRequest {
    batchMessageItems: PatientToUpload[];
}

export interface BatchMessageId {
    id: string;
}

export type CreateBeastInvitesParameters = {
    batchMessageId: string;
    beastSeriesId: string;
    practiceId: string;
};

export type CreateBeastInvitesRequest = {
    batchMessageId: string;
    beastSeriesId: string;
};

export const getBatchMessageResponsesCsvDownloadUrl = (
    practiceId: string,
    batchId: string,
): string =>
    `/api/patientMessaging/BatchMessaging/${practiceId}/${batchId}/responsescsv`;

export type SendAt = {
    day: string;
    month: string;
    year: string;
    hour: string;
};

export interface BeastSeriesDto {
    id: string;
    name: string;
    inviteSmsBody: string;
}

export interface ListBeastSeriesDto {
    series: BeastSeriesDto[];
}

export type BatchSlotType = {
    type: string;
    availability: number;
};

export type BatchSlotTypesDto = {
    slotTypesWithAvailabilityCount: {
        [key: string]: number;
    };
    weeksAvailable: number;
};
