import { AppointmentRemindersRoutes } from "@accurx/appointment-reminders";
import { FeatureName, WorkspaceFeatureGuard } from "@accurx/auth";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import { Switch } from "react-router-dom";

import { SimpleRoute } from "app/AppRoute";
import { FeatureGuard } from "app/FeatureGuard";
import DeliveryReceipts from "app/account/DeliveryReceipts/DeliveryReceipts";
import { OrganisationResources } from "app/account/OrganisationResources/OrganisationResources";
import { Container } from "app/layout/Container";
import { MessageTemplatesPage } from "app/messageTemplates/MessageTemplatesPage";
import MedicalRecord from "app/recordView/MedicalRecordComponent";
import MedicalRecords from "app/recordView/MedicalRecordsComponent";
import { RequestMedicalRecord } from "app/recordView/RequestMedicalRecord";
import { ReportingRoutes } from "app/reporting/ReportingRoutes";
import { EditWorkspacePage } from "app/workspace/pages/EditWorkspace/EditWorkspacePage";
import { GeneralWorkspaceSettings } from "app/workspaceSettings/pages/GeneralWorkspaceSettings/GeneralWorkspaceSettings";
import { InviteUserPage } from "app/workspaceSettings/pages/UserManagement/InviteUserPage";
import { UserManagementPage } from "app/workspaceSettings/pages/UserManagement/UserManagementPage";
import { ROUTES_EXTENSION, ROUTES_WORKSPACE } from "shared/Routes";

import NotFound from "../../../NotFound";
import { GuardedRoute } from "../GuardedRoute";

export const WorkspaceSettingsRoutesSection = () => {
    return (
        <Switch>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceGeneralSettings} exact>
                <GeneralWorkspaceSettings />
            </SimpleRoute>
            <GuardedRoute
                path={ROUTES_WORKSPACE.workspaceEdit}
                exact
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <FeatureGuard all={[FeatureName.FlexibleWorkspace]}>
                    <EditWorkspacePage />
                </FeatureGuard>
            </GuardedRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceUserManagement} exact>
                <WorkspaceFeatureGuard
                    all={[FeatureName.WorkspaceUserManagement]}
                >
                    <UserManagementPage />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute path={WORKSPACE_MANAGEMENT_ROUTES.inviteUsers}>
                <WorkspaceFeatureGuard
                    all={[FeatureName.WorkspaceUserManagement]}
                >
                    <InviteUserPage />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute
                path={ROUTES_WORKSPACE.workspaceDeliveryReceipts}
                exact
            >
                <Container>
                    <DeliveryReceipts />
                </Container>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceSharedTemplates} exact>
                <Container>
                    <MessageTemplatesPage filter="Organisation" />
                </Container>
            </SimpleRoute>
            <SimpleRoute
                path={ROUTES_WORKSPACE.workspacePersonalTemplates}
                exact
            >
                <Container>
                    <MessageTemplatesPage filter="User" />
                </Container>
            </SimpleRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.workspaceResources} exact>
                <FeatureGuard all={[FeatureName.Wayfinder]}>
                    <Container>
                        <OrganisationResources />
                    </Container>
                </FeatureGuard>
            </SimpleRoute>
            <GuardedRoute
                path={ROUTES_WORKSPACE.workspaceMedicalRecords}
                exact
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                    <Container>
                        <MedicalRecords />
                    </Container>
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                path={`${ROUTES_WORKSPACE.workspaceMedicalRecords}${ROUTES_EXTENSION.remoteRecordView}`}
                exact
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                    <Container>
                        <RequestMedicalRecord />
                    </Container>
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                path={ROUTES_WORKSPACE.workspaceAppointmentReminders}
                requires={{
                    authenticationStatus: "LoggedIn",
                    onboarded: true,
                }}
            >
                <FeatureGuard
                    all={[
                        FeatureName.AppointmentRemindersAlpha,
                        FeatureName.AppointmentRemindersUhl,
                        FeatureName.IntegratedClinicLists,
                    ]}
                >
                    <AppointmentRemindersRoutes />
                </FeatureGuard>
            </GuardedRoute>
            <GuardedRoute
                path={`${ROUTES_WORKSPACE.workspaceMedicalRecords}${ROUTES_EXTENSION.remoteRecordViewMedicalRecord}`}
                exact
                requires={{
                    authenticationStatus: "LoggedInWith2FA",
                    onboarded: true,
                }}
            >
                <FeatureGuard all={[FeatureName.RecordAccessBeta]}>
                    <Container>
                        <MedicalRecord />
                    </Container>
                </FeatureGuard>
            </GuardedRoute>
            <SimpleRoute path={ROUTES_WORKSPACE.reporting}>
                <WorkspaceFeatureGuard all={[FeatureName.EmbeddedPowerBiPOC]}>
                    <ReportingRoutes />
                </WorkspaceFeatureGuard>
            </SimpleRoute>
            <SimpleRoute path="*">
                <NotFound />
            </SimpleRoute>
        </Switch>
    );
};
