import { useCallback, useRef } from "react";

export const useScrollIntoView = () => {
    const ref = useRef<HTMLDivElement>(null);

    // Recreating this function on every render can cause excessive scrolling.
    // Storing as a callback prevents this.
    const scrollIntoView = useCallback(() => {
        if (ref.current) {
            /** block: "nearest" ensures the containing element is only scrolled if needed. See: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#block
             */
            ref.current.scrollIntoView({ block: "nearest" });
        }
    }, []);

    return { ref, scrollIntoView };
};
