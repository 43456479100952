/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
/**
 * Debug utility for WebInbox
 * These are not recommended for use elsewhere, they are not intended to be
 * generic, though feel free to take inspiration if it helps.
 */

type AnyFunction = {
    (..._: any[]): any;
    off?: () => void;
    target?: () => void;
};
type AnyModule = Record<string, AnyFunction>;

// prefix for all log lines produced by this module
const logPrefix = "%cWebInbox%c -";
const logPrefixColours = [
    "background: seagreen; color: white; padding:2px; border-radius: 2px;",
    "",
];

/**
 * Log an arbitrary value or values.
 * @param { string } label
 * An informative label that identifies what the values relate to
 * @param { any[] } args
 * The values you want to log
 */
export const log = (label: string, ...args: any[]) => {
    console.groupCollapsed(`${logPrefix} ${label}`, ...logPrefixColours);
    console.log(...args);
    console.groupEnd();
};

/**
 * Wrap a function so that every call to it produces a log output. The function
 * name will be logged, the arguments it is called with will be captured and
 * logged, and the return value if there is one.
 * @param { AnyFunction } target
 * The function you want to start observing
 */
export const traceCallsTo = function (target: AnyFunction) {
    return traceFunctionCall(target, `${target.name}()`);
};

/**
 * Wrap a module so that every call to a method on it produces log output.
 * The method name will be logged, the arguments it is called with will be
 * captured and logged, and the return value if there is one.
 * @param { string } name
 * The name of the containing object/module (i.e. the namespace of the methods)
 * @param { AnyModule } target
 * The module/object you want to trace
 */
export const traceMethodCallsOn = function (name: string, module: AnyModule) {
    Object.keys(module).forEach((key) => {
        if (typeof module[key] === "function") {
            const revocableProxy = traceFunctionCall(
                module[key],
                `${name}.${key}()`,
            );

            // move original method to .target
            module[key].target = module[key];
            // replace original method with proxy trap
            module[key] = revocableProxy.proxy;
            // attach the proxies revoke method to .off
            module[key].off = revocableProxy.revoke;
        }
    });
};

export const disableTracing = function (module: AnyModule) {
    function noop() {
        void 0;
    }

    Object.keys(module).forEach((key) => {
        if (module[key] !== undefined && "target" in module[key]) {
            // replace the original method if it was moved to .target
            module[key] = module[key].target ?? module[key];
            // delete the target property
            delete module[key].target;
        }

        if (module[key] !== undefined && "off" in module[key]) {
            // grab the revoke method or fallback to noop if this isn't a proxy
            const revoke = module[key].off ?? noop;

            // invoke proxy.revoke() (or noop if this was never a proxy)
            revoke();
        }
    });
};

// private

const traceFunctionCall = (fn: AnyFunction, fnName: string) => {
    return Proxy.revocable(fn, {
        apply(target, thisArg, args) {
            const result = target.apply(thisArg, args);

            console.groupCollapsed(
                `${logPrefix} calling ${fnName}`,
                ...logPrefixColours,
            );

            if (args.length > 0) {
                console.log("with args:", ...args);
            } else {
                console.log(
                    `with args: %cNone%c`,
                    "font-style: italic; color: grey;",
                    "",
                );
            }

            if (result !== undefined) {
                console.log("returned:", result);
            }

            console.groupEnd();

            return result;
        },
    });
};
