import { useAnalytics } from "@accurx/analytics";
import { useFeatureFlag } from "@accurx/auth";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { useNativeTrackingFields } from "@accurx/native";
import { WithTooltip } from "domains/inbox/components/Tooltip/WithTooltip";
import { useCurrentlyViewingUsers } from "domains/inbox/hooks/useCurrentlyViewingUsers";

import {
    StyledBadge,
    StyledTooltip,
    StyledTooltipText,
} from "./CurrentlyViewingBadge.styles";

export type CurrentlyViewingBadgeProps = {
    conversation: Conversation;
};

export const CurrentlyViewingBadge = ({
    conversation,
}: CurrentlyViewingBadgeProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const isCurrentlyViewingEnabled = useFeatureFlag(
        "UnifiedInboxCurrentlyViewing",
    );
    const activeUsers = useCurrentlyViewingUsers(conversation.id);

    if (!isCurrentlyViewingEnabled) return null;
    if (activeUsers.length === 0) return null;

    return (
        <>
            <UI.VisuallyHidden as="span">
                {`${activeUsers.length} colleague${
                    activeUsers.length > 1 ? "s are" : " is"
                } viewing this conversation`}
            </UI.VisuallyHidden>

            <StyledTooltip>
                <WithTooltip
                    isEnabled={activeUsers.length > 0}
                    content={
                        <StyledTooltipText skinny variant="note" colour="white">
                            Colleague{activeUsers.length > 1 && "s"} viewing:{" "}
                            {activeUsers.length <= 2 ? (
                                activeUsers.join(", ")
                            ) : (
                                <>
                                    {activeUsers[0]} and{" "}
                                    {activeUsers.length - 1} others
                                </>
                            )}
                        </StyledTooltipText>
                    }
                    props={{
                        content: {
                            align: "start",
                            side: "bottom",
                            className: "currently-viewing-tooltip",
                        },
                        root: {
                            onOpenChange: (open) => {
                                if (!open) return;

                                track(
                                    "ConversationColleagueViewing ListItem Hover",
                                    {
                                        accessType:
                                            nativeTrackingFields.accessType,
                                        pageOrigin: "ConversationListPreview",
                                        countColleague: activeUsers.length,
                                    },
                                );
                            },
                        },
                    }}
                >
                    <StyledBadge
                        color="greyscale"
                        radius="round"
                        className="conversation-preview-badge"
                        aria-hidden="true"
                    >
                        <UI.Icon name="Person" theme="Fill" size={2} />
                        <span data-testid="currently-viewing-persons">
                            {activeUsers.length}
                        </span>
                    </StyledBadge>
                </WithTooltip>
            </StyledTooltip>
        </>
    );
};
