import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { TagsResponse } from "@accurx/api/florey-builder";

import { getContentTags } from "api/FloreyBuilder/FloreyBuilderApi";

export const GET_CONTENT_TAGS_CACHE_KEY = "GetContentTags";

export const useGetContentTagsQuery = createQueryHook<void, TagsResponse>(
    () => ({
        queryKey: [GET_CONTENT_TAGS_CACHE_KEY, {}],
        queryFn: async () => {
            const response = await getContentTags();
            return returnDataOrThrow(response);
        },
    }),
);
