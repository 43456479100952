import { PatientThreadItemUpdate } from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { mapPatientThreadUserGroupToTeamSummary } from "domains/concierge/internal/api/shared/mappers/mapPatientThreadUserGroupToTeamSummary";
import { mapPatientThreadPatientDetailsToPatientSummary } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientThreadPatientDetailsToPatientSummary";
import { Subscription } from "domains/concierge/internal/hooks/useSignalRSubscription";
import { actions } from "domains/concierge/internal/store";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import isNil from "lodash/isNil";

import { Event, SocketEvents } from "shared/hubClient/HubClient";

import { mapPatientThreadUserToUserSummary } from "../mappers/mapPatientThreadUserToUserSummary";
import { mapTicketItemUpdateToConversation } from "../mappers/mapTicketItemUpdateToConversation";

export const isForWorkspace =
    (workspaceId: number) => (event: Event<"OnPatientThreadItemChanged">) => {
        const payload = event?.payload as PatientThreadItemUpdate | undefined;
        if (isNil(payload)) {
            Log.error(
                "Unable to process undefined/null event from SignalR for 'OnPatientThreadItemChanged'",
                { tags: { product: "Inbox" } },
            );
            return false;
        }
        return payload?.organisationId === workspaceId;
    };

export const mapToConciergeUpdates = (
    event: Event<"OnPatientThreadItemChanged">,
): ConciergeUpdates => {
    const conversation = mapTicketItemUpdateToConversation(event.payload);
    const conversations = conversation ? [conversation] : [];

    const patients =
        event.payload.referencedPatients
            ?.map(mapPatientThreadPatientDetailsToPatientSummary)
            .filter(isInstance) ?? [];

    const users =
        event.payload.users
            ?.map(mapPatientThreadUserToUserSummary)
            .filter(isInstance) ?? [];

    const teams =
        event.payload.userGroups
            ?.map(mapPatientThreadUserGroupToTeamSummary)
            .filter(isInstance) ?? [];

    return {
        conversations,
        patients,
        users,
        teams,
    };
};

export const OnPatientThreadItemChanged: Subscription = ({
    transport,
    dispatch,
    workspaceId,
}) => {
    const subscription = transport.subscribe({
        methodName: SocketEvents.OnPatientThreadItemChanged,
        eventFilter: (event) => isForWorkspace(workspaceId)(event),
        onEvent: (event) => {
            if (!event) return;
            const payload = mapToConciergeUpdates(event);
            dispatch(
                actions.processUpdates({
                    source: "SignalR:OnPatientThreadItemChanged",
                    ...payload,
                }),
            );
        },
    });

    return subscription.unsubscribe.bind(subscription);
};
