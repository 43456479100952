import React, { ComponentProps } from "react";

import { ActionCard, Text, Thread } from "@accurx/design";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import {
    PatientMatchState,
    PatientTriageRequestConversationItem,
} from "shared/concierge/conversations/types/item.types";

import { getPatientNotFoundBadgeInfo } from "../../../Conversation.helpers";
import { renderPatientResponseSections } from "../../ConversationThread.helper";
import { BaseConversationThreadItem } from "../threadItem.types";
import { StyledActionCardWrapper } from "./PatientTriageRequestNoteThreadItem.styles";

type CardHeaderBadgeProps = Pick<
    ComponentProps<typeof ActionCard.Header>,
    "badgeColour" | "badgeText"
>;

type PatientTriageRequestNoteThreadItemProps =
    BaseConversationThreadItem<PatientTriageRequestConversationItem> & {
        organisationId: number;
        patientId: string | null | undefined;
        matchType: PatientMatchState | null;
    };

const NO_TOPIC_TEXT = "Unknown";

const getTopic = (item: PatientTriageRequestConversationItem): string =>
    item.requestTitle || NO_TOPIC_TEXT;

export const PatientTriageRequestNoteThreadItem = ({
    organisationId,
    patientId,
    item,
    handleOnItemRead,
    createdByDisplayName,
    matchType,
}: PatientTriageRequestNoteThreadItemProps): JSX.Element => {
    const renderRequestSections = (): JSX.Element[] => {
        return renderPatientResponseSections({
            item,
            organisationId,
            patientId,
            hideAttachmentsLabel: true,
            noResponseSection: "No information supplied",
        });
    };

    const badgeInfo = getPatientNotFoundBadgeInfo(matchType);

    const badgeProps: CardHeaderBadgeProps = badgeInfo.showBadge
        ? {
              badgeColour: "yellow",
              badgeText: badgeInfo.badgeText,
          }
        : {};

    return (
        <Thread.Message
            inboxType="received"
            dateCreated={formatConversationItemDateCreatedShort(item.createdAt)}
            senderFullName={{
                name: createdByDisplayName
                    ? {
                          fullName: createdByDisplayName,
                          shouldDisplayFullName: true,
                      }
                    : null,
            }}
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            <StyledActionCardWrapper>
                <ActionCard>
                    <ActionCard.Header
                        iconName="Message"
                        contentType="Patient follow up"
                        {...badgeProps}
                    />
                    <ActionCard.Body
                        colour={badgeInfo.showBadge ? "yellow" : "blue"}
                    >
                        <Text as="span" variant={"label"}>
                            Request type
                        </Text>
                        <Text>{getTopic(item)}</Text>
                        {renderRequestSections()}
                    </ActionCard.Body>
                </ActionCard>
            </StyledActionCardWrapper>
        </Thread.Message>
    );
};

export const patientTriageRequestNoteThreadItemSummaryText = (
    item: PatientTriageRequestNoteThreadItemProps["item"],
): string => `Request for ${getTopic(item)}`;
