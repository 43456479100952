import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { ClinicianMessageItem } from "@accurx/concierge/schemas/ConversationItemSchema";
import * as UI from "@accurx/design";
import {
    StyledCellActionSummary,
    StyledGrid,
} from "domains/inbox/components/PreviewSummary/PreviewSummary.styles";

export type ClinicianMessageProps = {
    conversationItem: ClinicianMessageItem;
};

const Sender = ({ fromText }: { fromText: string }): JSX.Element => (
    <UI.Cell>
        <UI.Text as="span" variant="note">
            <UI.VisuallyHidden as="span">Message from</UI.VisuallyHidden>
            <>{fromText}:</>
        </UI.Text>
    </UI.Cell>
);

const UserSender = ({ userId }: { userId: string }) => {
    const participant = useUser({ userId });

    if (participant) {
        return (
            <Sender
                fromText={
                    participant.isCurrentUser ? "You" : participant.displayName
                }
            />
        );
    }

    return null;
};

export const ClinicianMessage = ({
    conversationItem,
}: ClinicianMessageProps) => {
    const getSenderName = () => {
        const { createdBy } = conversationItem;
        switch (createdBy.type) {
            case "WorkspaceUser":
                return <UserSender userId={createdBy.userId} />;
            case "Contact":
                return <Sender fromText={createdBy.displayName} />;
        }
    };

    return (
        <StyledGrid>
            {getSenderName()}
            <StyledCellActionSummary>
                <UI.Text as="span" variant="preview">
                    <UI.VisuallyHidden as="span">
                        Message summary:
                    </UI.VisuallyHidden>
                    {conversationItem.body}
                </UI.Text>
            </StyledCellActionSummary>
        </StyledGrid>
    );
};
