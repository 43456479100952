import { ExternalEmailUploadAttachmentResponse } from "@accurx/api/clinician-messaging";
import {
    IWrappedResult,
    getApiEndpoint,
    httpClient,
    returnDataOrThrow,
} from "@accurx/shared";
import { useMutation } from "@tanstack/react-query";

import { AttachmentSource } from "../reducer.types";

type UploadFileMutationResponse = {
    id: string;
    previewUrl: string;
    source: AttachmentSource;
};

type TicketAttachmentUploadResponse = {
    id: string;
    urlId: string;
    link: string;
};

const uploadPatientMessagingAttachment = async ({
    file,
    workspaceId,
}: UploadFileMutationVariables) => {
    const formData = new FormData();
    formData.append("formFile", file);

    const res: IWrappedResult<TicketAttachmentUploadResponse> =
        await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: "/api/patientmessaging/documentupload",
                query: `?organisationId=${workspaceId}`,
            }),
            formData,
        );
    const response = returnDataOrThrow(res);

    return {
        source: "PatientMessaging",
        id: response.id,
        previewUrl: `/api/patientmessaging/DocumentUpload/previewdocument?documentUrlId=${response.urlId}&organisationId=${workspaceId}`,
    } as UploadFileMutationResponse;
};

const uploadClinicianMessagingAttachment = async ({
    file,
    workspaceId,
}: UploadFileMutationVariables) => {
    const getId = ({
        externalEmailAttachmentId,
    }: ExternalEmailUploadAttachmentResponse) => {
        if (!externalEmailAttachmentId) {
            throw new Error(
                "Attachment id on ExternalEmailUploadAttachmentResponse was unexpectedly null or undefined",
            );
        }
        return externalEmailAttachmentId;
    };

    const formData = new FormData();
    formData.append("formFile", file);

    const res: IWrappedResult<ExternalEmailUploadAttachmentResponse> =
        await httpClient.postFileSafeAsync(
            getApiEndpoint({
                path: "/api/clinicianmessaging/attachments",
                query: `?organisationId=${workspaceId}&source=LocalFile`,
            }),
            formData,
        );
    const response = returnDataOrThrow(res);

    return {
        source: "ClinicianMessaging",
        id: getId(response),
        previewUrl: URL.createObjectURL(file),
    } as UploadFileMutationResponse;
};

type UploadFileMutationVariables = {
    file: File;
    workspaceId: string;
};

/**
 * Allows users to upload local attachments
 */
export const useUploadFileMutation = (source: AttachmentSource) => {
    const uploadAttachment = (variables: UploadFileMutationVariables) => {
        switch (source) {
            case "PatientMessaging":
                return uploadPatientMessagingAttachment(variables);
            case "ClinicianMessaging":
                return uploadClinicianMessagingAttachment(variables);
        }
    };

    return useMutation({
        mutationKey: ["uploadFile"],
        mutationFn: uploadAttachment,
    });
};
