import { ReactNode } from "react";

import { Navbar } from "../Navbar";

export const PracticesLayout = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <Navbar />
            {children}
        </>
    );
};
