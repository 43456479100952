import { ReactNode } from "react";

import { Spinner } from "@accurx/design";
import { useQueryClient } from "@tanstack/react-query";
import { SelectedOrganisationProvider } from "reduxQuarantine/SelectedOrganisationProvider";

import { StyledSpinnerWrapper } from "./SpinnerWrapper.styles";
import {
    AUTH_API_QUERY_KEY,
    useCurrentUserQuery,
} from "./hooks/queries/useCurrentUserQuery";
import { identify } from "./identify";
import { LoggedOutUser } from "./types/User";

const loggedOutUser: LoggedOutUser = {
    isLoggedIn: false,
    isTrustedDevice: false,
    isSecureEmail: false,
};

export const AuthProvider = ({
    children,
    isDesktopUser = false,
}: {
    children: ReactNode;
    isDesktopUser?: boolean;
}) => {
    const queryClient = useQueryClient();

    const { status } = useCurrentUserQuery({
        enabled: true,
        onSuccess: (user) => {
            if (user.isLoggedIn) {
                identify({ user, isDesktopUser });
            }
        },
    });

    if (status === "loading") {
        return (
            <StyledSpinnerWrapper>
                <Spinner />
            </StyledSpinnerWrapper>
        );
    }

    if (status === "error") {
        queryClient.setQueryData([AUTH_API_QUERY_KEY], loggedOutUser);
    }

    return (
        <SelectedOrganisationProvider>{children}</SelectedOrganisationProvider>
    );
};
