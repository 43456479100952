import {
    PatientThreadEmail,
    PatientThreadNhsAppMessage,
    PatientThreadSMS,
} from "@accurx/api/ticket";
import { mapMedicalRecordWriteState } from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/mapMedicalRecordWriteState";
import {
    ConversationItem,
    DeliveryStatusSchema,
    NhsAppMessageItem,
} from "domains/concierge/schemas/ConversationItemSchema";
import {
    DeliveryStatus,
    PatientEmailItem,
    PatientSmsItem,
} from "domains/concierge/types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import { mapBaseConversationItem } from "./mapBaseConversationItem";
import { mapDeliveryScheduledAt } from "./mapDeliveryScheduledAt";

/**
 * Maps ticket SMS to conversation SMS
 *
 * @param ticketItemSMS SMS as it's sent from the API
 * @returns mapped SMS conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientSmsItem(
    ticketItemSMS: PatientThreadSMS,
): PatientSmsItem | undefined {
    if (!ticketItemSMS.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItemSMS.message.accuRxMessageId) {
        throw new Error(
            "Property accuRxMessageId not present in ticket item, but it is required",
        );
    }
    if (!ticketItemSMS.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }
    if (!ticketItemSMS.message.sentByUserId) {
        throw new Error(
            "Property sentByUserId not present in PatientSMS item, but it is required",
        );
    }

    const deliveryStatus = mapDeliveryStatus(
        ticketItemSMS.message.deliveryStatus,
        "PatientSms",
    );

    return {
        ...mapBaseConversationItem(ticketItemSMS),
        messageId: ticketItemSMS.message.accuRxMessageId,
        body: ticketItemSMS.message.messageBody,
        createdBy: { type: "User", id: ticketItemSMS.message.sentByUserId },
        // It would be unexpected if mobileNumber wasn't there, but absence of it acceptable
        mobileNumber: ticketItemSMS.message.mobileNumber ?? "",
        contentType: "PatientSms",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItemSMS.message.deliveryScheduledAt,
        ),
        deliveryStatusUpdatedAt: ticketItemSMS.message.deliveryStatusUpdatedAt,
        saveToRecordState: mapMedicalRecordWriteState(
            ticketItemSMS.message.medicalRecordWriteState,
        ),
    };
}

/**
 * Maps ticket email to conversation email
 *
 * @param ticketItemEmail email as it's sent from the API
 * @returns mapped email conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientEmailItem(
    ticketItemEmail: PatientThreadEmail,
): PatientEmailItem | undefined {
    if (!ticketItemEmail.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItemEmail.message.accuRxMessageId) {
        throw new Error(
            "Property accuRxMessageId not present in ticket item, but it is required",
        );
    }
    if (!ticketItemEmail.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }
    if (!ticketItemEmail.message.sentByUserId) {
        throw new Error(
            "Property sentByUserId not present in PatientEmail item, but it is required",
        );
    }

    const deliveryStatus = mapDeliveryStatus(
        ticketItemEmail.message.deliveryStatus,
        "PatientEmail",
    );

    return {
        ...mapBaseConversationItem(ticketItemEmail),
        messageId: ticketItemEmail.message.accuRxMessageId,
        body: ticketItemEmail.message.messageBody,
        createdBy: { type: "User", id: ticketItemEmail.message.sentByUserId },
        // It would be unexpected if emailAddress wasn't there, but absence of it acceptable
        emailAddress: ticketItemEmail.message.emailAddress ?? "",
        contentType: "PatientEmail",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItemEmail.message.deliveryScheduledAt,
        ),
        saveToRecordState: mapMedicalRecordWriteState(
            ticketItemEmail.message.medicalRecordWriteState,
        ),
        deliveryStatusUpdatedAt:
            ticketItemEmail.message.deliveryStatusUpdatedAt,
    };
}

/**
 * Maps ticket NHS app message to conversation NHS app message
 *
 * @param ticketItem message as it's sent from the API
 * @returns mapped NHS app message conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapNhsAppMessageItem(
    ticketItem: PatientThreadNhsAppMessage,
): NhsAppMessageItem | undefined {
    if (!ticketItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketItem.message.accuRxMessageId) {
        throw new Error(
            "Property accuRxMessageId not present in ticket item, but it is required",
        );
    }
    if (!ticketItem.message.messageBody) {
        throw new Error(
            "Property messageBody not present in ticket item, but it is required",
        );
    }
    if (!ticketItem.message.sentByUserId) {
        throw new Error(
            "Property sentByUserId not present in NhsAppMessage item, but it is required",
        );
    }

    const deliveryStatus = mapDeliveryStatus(
        ticketItem.message.deliveryStatus,
        "NhsAppMessage",
    );

    return {
        ...mapBaseConversationItem(ticketItem),
        messageId: ticketItem.message.accuRxMessageId,
        body: ticketItem.message.messageBody,
        createdBy: { type: "User", id: ticketItem.message.sentByUserId },
        contentType: "NhsAppMessage",
        deliveryStatus: deliveryStatus,
        deliveryScheduledAt: mapDeliveryScheduledAt(
            ticketItem.message.deliveryScheduledAt,
        ),
        saveToRecordState: mapMedicalRecordWriteState(
            ticketItem.message.medicalRecordWriteState,
        ),
        deliveryStatusUpdatedAt: ticketItem.message.deliveryStatusUpdatedAt,
    };
}

const mapDeliveryStatus = (
    value: string | undefined | null,
    contentType: ConversationItem["contentType"],
): DeliveryStatus => {
    // extra check BE value is null or undefined.
    if (!value) {
        throw new Error(
            `Property deliveryStatus  null or undefined in ticket item, but it is required for contentType - ${contentType}`,
        );
    }

    const parseResult = DeliveryStatusSchema.safeParse(value);

    // check if the value is present in mapping type
    if (!parseResult.success) {
        throw new Error(
            `Property deliveryStatus value in ticket item is not present in the fe mapping types. contentType - ${contentType}`,
        );
    }

    return parseResult.data;
};
