import { useEffect, useMemo, useState } from "react";

export const useMediaQuery = (mediaQueryString: string): boolean => {
    const queryString = mediaQueryString.replace("@media ", "").trim();
    const query = useMemo(() => window.matchMedia(queryString), [queryString]);
    const [matches, setMatches] = useState(query.matches);

    useEffect(() => {
        const handleChange = (e: MediaQueryListEvent) => setMatches(e.matches);

        query.addEventListener("change", handleChange);

        return () => query.removeEventListener("change", handleChange);
    }, [query]);

    return matches;
};
