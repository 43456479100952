import {
    NoteUserGroupState,
    NoteUserState,
    PatientThreadItemTransport,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { BaseItem, ConversationItem } from "domains/concierge/types";

import { mapTicketItemIdentityToConversationItemId } from "../mapTicketItemIdentityToConversationItemId";

type PatientThreadItemTransportContentKeys = keyof Omit<
    PatientThreadItemTransport,
    // outgoingEmail and incomingEmail are unused types
    "type" | "outgoingEmail" | "incomingEmail"
>;

type PatientThreadTicketItem = Exclude<
    PatientThreadItemTransport[PatientThreadItemTransportContentKeys],
    null | undefined
>;

export function mapBaseConversationItem(
    ticketItem: PatientThreadTicketItem,
): BaseItem {
    const serverId = ticketItem.id || ""; // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
    const itemId = mapTicketItemIdentityToConversationItemId({
        type: ticketItem.type,
        id: serverId,
    });

    const readDataResult =
        !!ticketItem?.message && "userStates" in ticketItem.message
            ? mapUserReadData(
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  ticketItem.message.userStates || [],
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  ticketItem.message.userGroupStates || [],
              )
            : undefined;

    if (readDataResult && readDataResult.failedToMapCount > 0) {
        Log.error(
            "Could not map all read states for ticket item - missing or inconsistent data",
            {
                tags: {
                    product: "Inbox",
                    itemId: ticketItem.id,
                    itemType: ticketItem.type,
                },
            },
        );
    }

    return {
        id: itemId,
        serverId: serverId,
        createdAt: ticketItem.createdAt,
        readDataByUserId: readDataResult?.readDataByUserId ?? {},
    };
}

type UserReadDatesResult = {
    readDataByUserId: ConversationItem["readDataByUserId"];
    failedToMapCount: number;
};

function mapUserReadData(
    userStates: NoteUserState[],
    userGroupStates: NoteUserGroupState[],
): UserReadDatesResult {
    const userIdsToGroups = new Map<string, Record<string, true>>();
    const dict: ConversationItem["readDataByUserId"] = {};
    let failedToMapCount = 0;

    userGroupStates.forEach((groupState) => {
        if (groupState.isArchived) {
            return;
        }

        if (!groupState.userGroupId || !groupState.userIdsInGroupAtNoteCreate) {
            failedToMapCount++;
            return;
        }

        const userGroupId = groupState.userGroupId;
        groupState.userIdsInGroupAtNoteCreate.forEach((userId) => {
            const existing = userIdsToGroups.get(userId);
            if (existing) {
                existing[userGroupId] = true;
            } else {
                userIdsToGroups.set(userId, { [userGroupId]: true });
            }
        });
    });

    userStates.forEach((userState) => {
        if (userState.isArchived) {
            return;
        }
        if (!userState.userId) {
            failedToMapCount++;
            return;
        }

        if (!!userState.readAt !== !!userState.hasRead) {
            failedToMapCount++;
            return;
        }

        // FOU-212: We need an explicit flag from the server to help differentiate when a user
        // is included both directly as an individual and indirectly via team membership. Until
        // then, a user that is included via both paths will be calculated via team logic alone.
        // The desktop client partly masks this by looking for tags in UserNote content, but
        // this isn't complete and we should fix both at the same time.
        const shouldIgnoreTeamMembership = false;

        dict[userState.userId] = {
            readAt: userState.readAt ?? null,
            byTeamId: shouldIgnoreTeamMembership
                ? null
                : userIdsToGroups.get(userState.userId) ?? null,
        };
    });

    return {
        readDataByUserId: dict,
        failedToMapCount,
    };
}
