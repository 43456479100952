import { InvitedByPractice } from "./VaccineDeliveryDTO";

export class VaccineProductType {
    static Pfizer = "PFIZER";
    static AstraZeneca = "ASTRAZENECA";
    static Moderna = "MODERNA";
}
export class VaccineProductTypeLabel {
    static Pfizer = "Pfizer";
    static PfizerPaediatric = "Paeds5-11Pfizer";
    static AstraZeneca = "AstraZeneca";
    static Moderna = "Moderna";
    static Janssen = "Janssen";
    static Sanofi = "Sanofi";
}

export interface PatientDetails {
    nhsNumber: string;
    displayName: string;
    gender: string;
    dateOfBirthDisplay: string;
    ageDisplay: string;
    contactNumber: string | null;
    patientId: number | null;
}

export interface BookedPatient {
    sessionId: number;
    recallSlotBookingId: number;
    patient: PatientDetails;
    invitedBy: InvitedByPractice | null;
    registeredOrganisation: InvitedByPractice | null;
    hasArrived: boolean;
    isTreated: boolean;
    isPartiallyTreated: boolean;
    // For users without accuTrack enabled, this value will always be null
    recordingVaccineProduct: string | null;
    sessionVaccineProduct: string | null;
    recordingId: number | null;
    vaccineDose: string | null;
    vaccineDoseGiven: string | null;
}

export interface Slot {
    startTime: string;
    endTime: string;
    bookedPatients: BookedPatient[];
}

// API requests and responses

export interface SessionSummary {
    id: string;
    deliverySite: string;
    sessionId: number; // need to migrate to opaque string id
    startTime: string;
    endTime: string;
    totalCapacity: number;
    totalBooked: number;
    totalArrived: number;
    totalBookedSecond: number;
    isPublished: boolean;
    type: string;
    doseType: string;
}

export interface VaccineSessionRequest {
    orgId: string;
    sessionId: string;
}

export interface VaccineSessionResponse {
    sessionId: number;
    isPublished: boolean;
    patientCount: number;
    sessionDay: string;
    slots: Slot[];
}

export interface VaccineCancelRequest {
    patientCanRebook: boolean;
    cancelSecondBooking: boolean;
    customSmsSignature: string | null;
}

export interface UpdateArrivalRequest {
    recallSlotBookingId: number;
    hasArrived: boolean;
}

export interface UpdateArrivalParams extends RecallVaccineUrlParamsBase {
    request: UpdateArrivalRequest;
}

export interface RecallVaccineUrlParamsBase {
    orgId: string;
}
