import { Conversation, ConversationItem } from "domains/concierge/types";
import findLast from "lodash/findLast";
import last from "lodash/last";

export const getMostRecentItem = (
    conversation: Conversation,
): ConversationItem => {
    const nonStateChangeItem = findLast(
        conversation.items,
        (item) => item.contentType !== "StateChange",
    );

    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return nonStateChangeItem ?? last(conversation.items)!;
};
