import { Card, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { Table } from "reactstrap";

import { VaccineDeliveryItem } from "../models/VaccineDeliveryDTO";
import { renderJabState } from "./VaccineDeliveryUploadAndComposeHelpers";

// N.B. Why do we pass showNims in here rather than calculate it in the method using the filteredList?
// We may have a page with multiple tables rendered and we want to consistently show that NIMS column across that
// page or not, so we calculate showNims in the parent component and pass it in here
export interface VaccineDeliveryComposePatientTableProps {
    filteredList: VaccineDeliveryItem[];
    testIdPrefix: string;
    showNims: boolean;
}

export const VaccineDeliveryComposePatientTable = ({
    filteredList,
    testIdPrefix,
    showNims,
}: VaccineDeliveryComposePatientTableProps) => {
    return (
        <>
            <Card spacing={0} props={{ style: { overflowX: "scroll" } }}>
                <Table striped bordered hover className="mb-0 text-center">
                    <thead>
                        <tr>
                            <th className="text-center">NHS number</th>
                            <th className="text-center">Name of patient</th>
                            <th className="text-center">DOB</th>
                            <th className="text-center">Contact number</th>
                            {showNims && (
                                <th className="text-center">
                                    National vaccination data
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {!!filteredList.length &&
                            filteredList.map((x) => (
                                <tr
                                    key={
                                        x.patientExternalIdentity
                                            .patientExternalIds[0].value +
                                        " key"
                                    }
                                >
                                    <td>
                                        <Text
                                            props={{
                                                "data-testid": `${testIdPrefix}-nhsColumn`,
                                            }}
                                        >
                                            {
                                                x.patientExternalIdentity
                                                    .patientExternalIds[0].value
                                            }
                                        </Text>
                                    </td>
                                    <td>
                                        <Text
                                            props={{
                                                "data-testid": `${testIdPrefix}-nameColumn`,
                                            }}
                                        >
                                            {x.patientName}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text
                                            props={{
                                                "data-testid": `${testIdPrefix}-dobColumn`,
                                            }}
                                        >
                                            {DateHelpers.formatDate(
                                                x.dateOfBirth,
                                                DateFormatOptions.DATE_SHORT_WITH_SLASH,
                                            )}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text
                                            props={{
                                                "data-testid": `${testIdPrefix}-phoneColumn`,
                                            }}
                                        >
                                            {x.contactNumber}
                                        </Text>
                                    </td>
                                    {showNims && (
                                        <td>
                                            <Text
                                                props={{
                                                    "data-testid": `${testIdPrefix}-jabState`,
                                                }}
                                            >
                                                {renderJabState(x.jabState)}
                                            </Text>
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Card>
        </>
    );
};
