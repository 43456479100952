import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledThumbnailIcon = styled(Flex).attrs({
    alignItems: "center",
})`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    height: ${Tokens.SIZES[6]};
    width: ${Tokens.SIZES[6]};
    padding-left: ${Tokens.SIZES[0.5]};
`;
