import { ComponentPropsWithoutRef, ReactNode } from "react";

import { Slot } from "@radix-ui/react-slot";

import {
    StyledContainer,
    StyledLink,
    StyledToggleButton,
} from "./NavLinkWithToggle.styles";

type NavLinkContainerProps = {
    isActive?: boolean;
    children: ReactNode;
} & ComponentPropsWithoutRef<"div">;

const Container = ({ isActive, children, ...props }: NavLinkContainerProps) => (
    <StyledContainer $isActive={Boolean(isActive)} {...props}>
        {children}
    </StyledContainer>
);

type LinkProps = {
    asChild?: boolean;
    isActive?: boolean;
    children: ReactNode;
} & ComponentPropsWithoutRef<"a">;

const Link = ({
    asChild = false,
    isActive = false,
    children,
    ...props
}: LinkProps) => (
    <StyledLink
        as={asChild ? Slot : "a"}
        aria-current={isActive ? "page" : undefined}
        {...props}
    >
        {children}
    </StyledLink>
);
Link.displayName = "NavLinkWithToggle.Link";

const Toggle = StyledToggleButton;
Toggle.displayName = "NavLinkWithToggle.Toggle";

export const NavLinkWithToggle = Object.assign(Container, {
    Link,
    Toggle,
});
