import { Option } from "@accurx/design";

export const getHourDisplayValue = (hour: number): string => {
    if (hour === 0 || hour === 12) {
        return `12${hour === 0 ? "am" : "pm"}`;
    }
    return hour <= 11 ? `${hour}am` : `${hour - 12}pm`;
};

const EARLIEST_HOUR = 6;
const LATEST_HOUR = 21;

export const getWorkingHoursOptions = (startHour?: number): Option[] => {
    const options: Option[] = [];

    for (let i = EARLIEST_HOUR; i <= LATEST_HOUR; i++) {
        options.push({
            label: getHourDisplayValue(i),
            value: `${i}`,
            disabled: startHour && startHour >= i ? true : false,
        });
    }

    return options;
};
