import { SearchForPatientResponse } from "@accurx/api/portal";
import { FeatureName } from "@accurx/auth";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { MedicalRecordResult, Medication, Problem } from "api/FlemingDtos";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";

import type { AccountState } from "../account/AccountState.types";
import { MISSING_LABEL_DATE_TEXT } from "./RecordView.constant";

const isPatientGpSameAsUserOrg = (
    account: AccountState,
    patientSearchResult: SearchForPatientResponse | null,
): boolean => {
    const userOrg = OrganisationHelper.getOrganisation(account);
    const patient = PatientHelper.getPatient(patientSearchResult);
    return (
        !!patient?.practiceCode &&
        !!userOrg?.nationalCode &&
        patient.practiceCode === userOrg.nationalCode
    );
};

/**
 * FOR INTERNAL CLINICAL TESTING USE ONLY
 *
 * @param account
 * @param patientSearchResult
 * @returns true if a user searches for a patient who's GP is the same as the user's practice,
 * and they are part of the internal testing clinical team,
 * otherwise returns false
 */
export const canUseNoAuthRecordViewInternal = (
    account: AccountState,
    patientSearchResult: SearchForPatientResponse | null,
): boolean => {
    const userOrg = OrganisationHelper.getOrganisation(account);
    const isNoAuthRecordViewEnabled = userOrg
        ? OrganisationHelper.isFeatureEnabled(
              userOrg,
              FeatureName.RecordAccessNoPatient2FA,
          )
        : false;
    return (
        isNoAuthRecordViewEnabled &&
        isPatientGpSameAsUserOrg(account, patientSearchResult)
    );
};

/**
 * @param account
 * @param patientSearchResult
 * @returns true if a user searches for a patient who's GP is the same as the user's practice,
 * and they are not part of the internal testing clinical team,
 * and they are an approved user for the currently selected organisation
 * otherwise returns false
 */
export const canUseNoAuthRecordViewGp = (
    account: AccountState,
    patientSearchResult: SearchForPatientResponse | null,
): boolean => {
    return (
        isPatientGpSameAsUserOrg(account, patientSearchResult) &&
        !canUseNoAuthRecordViewInternal(account, patientSearchResult) &&
        account !== undefined &&
        OrganisationHelper.getIsApprovedOrgUser(account)
    );
};

/**
 * Date formatter for Medical Record component
 * */
export const formatMedicalRecordDate = (
    date: string | null,
    shouldUsePlaceholder = true,
): string | null => {
    if (date === null) {
        if (shouldUsePlaceholder) {
            return MISSING_LABEL_DATE_TEXT;
        }
        return null;
    }
    return DateHelpers.formatDate(
        date,
        DateFormatOptions.NHS_MANUAL_DATE_ABBREVIATED_FORMAT,
    );
};

/**
 * Most of our medical record details can be a multitude of things which we cannot control
 * this function helps us to define whether the medical record item has a value to be displayed
 * */
export const hasValueToDisplay = (
    target:
        | string
        | number
        | Array<unknown>
        | Record<string, unknown>
        | null
        | undefined,
): boolean => {
    if (target === null) return false;
    if (target === undefined) return false;
    if (typeof target === "string" && target === "") return false;
    if (Array.isArray(target) && target.length === 0) return false;
    if (typeof target === "object" && Object.keys(target).length === 0)
        return false;

    return true;
};

/**
 * Filters out items of the medical record which are empty
 * */
export const getRecordWithoutEmptyInfo = (
    record: MedicalRecordResult,
): MedicalRecordResult => {
    const removeEmptyProblems = (problems: Problem[]): Problem[] => {
        return problems.filter((problem) => {
            const isEmpty =
                !hasValueToDisplay(problem.date) &&
                !hasValueToDisplay(problem.severity) &&
                !hasValueToDisplay(problem.description);

            return !isEmpty;
        });
    };
    const removeEmptyMedications = (
        medications: Medication[],
    ): Medication[] => {
        return medications.filter((medication) => {
            // Not adding the medication type to check for emptiness as it is not something
            // we are currently displaying, just a way for us to group them
            const isEmpty =
                !hasValueToDisplay(medication.name) &&
                !hasValueToDisplay(medication.date) &&
                !hasValueToDisplay(medication.dosage) &&
                !hasValueToDisplay(medication.quantity) &&
                !hasValueToDisplay(medication.durationDays) &&
                !hasValueToDisplay(medication.lastIssueDate) &&
                !hasValueToDisplay(medication.expiryDate) &&
                !hasValueToDisplay(medication.issueNumber);

            return !isEmpty;
        });
    };

    return {
        patient: record.patient, // we don't care if patient contains no data - we just show everything as not provided
        gp: record.gp, // we don't care if gp contains no data - we just show everything as not provided
        activeProblems: removeEmptyProblems(record.activeProblems),
        pastProblems: removeEmptyProblems(record.pastProblems),
        healthAdminProblems: removeEmptyProblems(record.healthAdminProblems),
        otherProblems: removeEmptyProblems(record.otherProblems),
        potentialProblems: removeEmptyProblems(record.potentialProblems),
        currentMedications: removeEmptyMedications(record.currentMedications),
        pastMedications: removeEmptyMedications(record.pastMedications),
        investigations:
            record.investigations !== null
                ? record.investigations.filter((investigation) => {
                      const isEmpty =
                          !hasValueToDisplay(investigation.subInvestigations) &&
                          !hasValueToDisplay(investigation.date) &&
                          !hasValueToDisplay(investigation.name) &&
                          !hasValueToDisplay(investigation.stringValue) &&
                          !hasValueToDisplay(investigation.comments);

                      return !isEmpty;
                  })
                : null,
        allergies: record.allergies.filter((allergy) => {
            const isEmpty =
                !hasValueToDisplay(allergy.date) &&
                !hasValueToDisplay(allergy.freeText) &&
                !hasValueToDisplay(allergy.description);
            return !isEmpty;
        }),
        immunisations: record.immunisations.filter((immunisation) => {
            const isEmpty =
                !hasValueToDisplay(immunisation.date) &&
                !hasValueToDisplay(immunisation.name);

            return !isEmpty;
        }),
        healthStatus: record.healthStatus.filter((status) => {
            const isEmpty =
                !hasValueToDisplay(status.lastUpdated) &&
                !hasValueToDisplay(status.value) &&
                !hasValueToDisplay(status.name);
            return !isEmpty;
        }),
        gpConsultations:
            record.gpConsultations?.filter((consultation) => {
                const isEmpty =
                    !hasValueToDisplay(consultation.date) &&
                    !hasValueToDisplay(consultation.clinicianName) &&
                    !hasValueToDisplay(consultation.consultationItems);
                return !isEmpty;
            }) ?? null,
    };
};

export const getRequestRemainingHoursText = (
    accessRemainingHours: number,
): string => {
    return accessRemainingHours > 0
        ? `${accessRemainingHours} hour${
              accessRemainingHours === 1 ? "" : "s"
          } remaining`
        : "Less than 1 hour remaining";
};
