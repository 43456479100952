import { PageLoadAnalyticsPropsType, trackPageLoadBase } from "@accurx/shared";

import { TrackFlemingEvent } from "./FlemingEventTracker";

/**
 * Page loaded in Fleming
 *
 * Event type: Page load
 * */
export const trackPageLoadFleming = (
    props: PageLoadAnalyticsPropsType,
): void => {
    trackPageLoadBase(TrackFlemingEvent, props);
};
