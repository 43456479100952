import { Card, Flex, Text } from "@accurx/design";
import styled from "styled-components";

const CardOuter = styled(Card).attrs({
    spacing: 2,
})``;

const CardInner = styled(Flex).attrs({
    gap: "2",
    flexDirection: { sm: "column", md: "row" },
    justifyContent: "space-between",
    alignItems: { sm: "flex-start", md: "center" },
})``;

const CardTextWrapper = styled(Flex).attrs({
    gap: "0.5",
    flexDirection: "column",
})``;

const CardTitle = styled(Text).attrs({
    variant: "label",
    skinny: true,
})``;

const CardBody = styled(Text).attrs({
    variant: "body",
    skinny: true,
})``;

export const AddPatientsCard = {
    CardOuter: CardOuter,
    CardInner: CardInner,
    TextWrapper: CardTextWrapper,
    Title: CardTitle,
    Body: CardBody,
};
