import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledWrapper = styled(UI.Flex).attrs({
    flexDirection: "column",
    alignItems: "center",
    gap: "1.5",
})`
    padding: ${UI.Tokens.SIZES[2]};
`;
