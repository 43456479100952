/**
 * A set of all supported query/path params for filtering the inbox,
 * used by route and link helpers such as useInboxLink and useInboxParams.
 */
export type InboxParams = {
    workspaceId: number;
    teamId: string;
    colleagueId: string;
    conversationId: string;
    status: "Open" | "Done";
};

/**
 * A set of default query/path params for filtering the inbox,
 * used when a value is not specified.
 */
export const DEFAULT_PARAMS: Partial<InboxParams> = {
    status: "Open",
};
