import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledIconButton = styled(UI.Button).attrs({
    theme: "secondary",
})`
    // account for border width
    padding: 5px 7px;
    height: ${UI.Tokens.SIZES[4]};

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const StyledBarContainer = styled(UI.Flex)`
    margin-bottom: ${UI.Tokens.SIZES[1.5]};
`;
