import { useCallback } from "react";

import { Log } from "@accurx/shared";
import { MutationFunction } from "@tanstack/query-core";
import {
    UseMutationOptions as BaseUseMutationOptions,
    /* this eslint exclusion is needed because this is where we
     * define our alternative implementation of useMutation,
     * which we enforce the use of across the rest of the concierge layer
     */
    // eslint-disable-next-line no-restricted-imports -- PERMANENT
    useMutation as useReactQueryMutation,
} from "@tanstack/react-query";

import { useConciergeMeta } from "../context";
import { afterFrame } from "./afterFrame";
import { performance } from "./perf";

export type UseMutationOptions<
    TVariables = void,
    TData = unknown,
    TError extends Error = Error,
    TContext = unknown,
> = Omit<
    BaseUseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
>;

/*
 * Hook that wraps React Query's useMutation hook,
 * enforces giving the mutation a name,
 * and logs the time it takes to perform the mutation + rerender
 * (using the `afterFrame` utility to queue the log after the next
 * browser paint).
 */
export const useMutation = <
    TVariables = void,
    TData = unknown,
    TError extends Error = Error,
    TContext = unknown,
>(
    name: string,
    mutationFn: MutationFunction<TData, TVariables>,
    options: UseMutationOptions<TVariables, TData, TError, TContext> = {},
) => {
    const { userId, workspaceId } = useConciergeMeta();

    // it's a bit annoying that we destructure this here
    // and then have to create a bunch of awkward
    // `instrumentedOnX` functions that wrap them. we do so
    // so that we can pass the individual functions to the
    // dependency arrays of the useCallbacks, rather than the
    // options object as a whole, which would not have a stable
    // object identity and so would cause a lot of unneccessary
    // rerenders
    const { onMutate, onSuccess, onError } = options;

    const instrumentedOnMutate = useCallback(
        async (variables: TVariables): Promise<TContext | undefined> => {
            performance.mark(`concierge-mutation-start-${name}`);
            return await onMutate?.(variables);
        },
        [name, onMutate],
    );

    const instrumentedOnSuccess = useCallback(
        (data: TData, variables: TVariables, context?: TContext) => {
            afterFrame(() => {
                performance.measure(
                    `concierge-mutation-success-${name}`,
                    `concierge-mutation-start-${name}`,
                );

                const measures = performance.getEntriesByName(
                    `concierge-mutation-success-${name}`,
                );

                const measure = measures[measures.length - 1];

                Log.info(`Concierge layer mutation succeeded: ${name}`, {
                    tags: {
                        product: "Inbox",
                        mutation: name,
                        start: new Date(
                            performance.timeOrigin + measure.startTime,
                        ).toISOString(),
                        durationMillis: measure.duration,
                        userId,
                        workspaceId,
                    },
                });
            });
            return onSuccess?.(data, variables, context);
        },
        [name, userId, workspaceId, onSuccess],
    );

    const instrumentedOnError = useCallback(
        (error: TError, variables: TVariables, context?: TContext) => {
            afterFrame(() => {
                performance.measure(
                    `concierge-mutation-failure-${name}`,
                    `concierge-mutation-start-${name}`,
                );

                const measures = performance.getEntriesByName(
                    `concierge-mutation-failure-${name}`,
                );

                const measure = measures[measures.length - 1];

                Log.error(`Concierge layer mutation failed: ${name}`, {
                    tags: {
                        product: "Inbox",
                        mutation: name,
                        error: error?.message,
                        start: new Date(
                            performance.timeOrigin + measure.startTime,
                        ).toISOString(),
                        durationMillis: measure.duration,
                        userId,
                        workspaceId,
                    },
                });
            });
            return onError?.(error, variables, context);
        },
        [name, userId, workspaceId, onError],
    );

    return useReactQueryMutation<TData, TError, TVariables, TContext>({
        ...options,
        mutationFn,
        onMutate: instrumentedOnMutate,
        onSuccess: instrumentedOnSuccess,
        onError: instrumentedOnError,
    });
};
