import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    OrganisationResource,
    uploadOrganisationResource,
} from "api/OrganisationResourceApi";
import { WORKSPACE_RESOURCES_CACHE_KEY } from "app/hooks/queries/useWorkspaceResourcesQuery";

type CreateWorkspaceResourceVariables = {
    file: File;
    title: string;
    type: string | null;
    workspaceId: number | null;
};

export const useCreateWorkspaceResourceMutation = createMutationHook<
    CreateWorkspaceResourceVariables,
    OrganisationResource
>(({ queryClient }) => ({
    mutationKey: ["CreateWorkspaceResource"],
    mutationFn: async ({ file, title, type, workspaceId }) => {
        if (workspaceId === null) {
            throw new Error(
                "workspace ID not supplied to CreateWorkspaceResource mutation",
            );
        }

        if (type === null) {
            throw new Error("File type not supported");
        }

        const response = await uploadOrganisationResource({
            file,
            organisationId: workspaceId,
            title,
            type,
        });

        return returnDataOrThrow(response);
    },
    onSuccess: (data, variables) => {
        queryClient.setQueryData<OrganisationResource[]>(
            [
                WORKSPACE_RESOURCES_CACHE_KEY,
                { workspaceId: variables.workspaceId },
            ],
            (cache) => [data, ...(cache || [])],
        );
    },
}));
