import { Log } from "@accurx/shared";
import { mapConversationIdToConversationSource } from "domains/concierge/internal/api/shared/mappers/mapConversationIdToConversationSource";
import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";

type Variables = {
    messageId: string;
    conversationId: string;
};

export const useCancelScheduledMessageMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "cancelScheduledMessage",
        async ({ messageId, conversationId }: Variables) => {
            const conversationSource =
                mapConversationIdToConversationSource(conversationId);

            if (conversationSource?.system !== "Ticket") {
                throw new Error(
                    "Cancelling scheduled messages is only supported by Ticket conversations",
                );
            }

            // The cancel scheduled message endpoint does not return anything so we do
            // not get back a conversation update. So that we can show the
            // updated item with the cancellation we need to refetch the
            // full conversation after cancelling.
            //
            // NOTE: This is not the desired behaviour for mutations and should
            // be avoided where possible. Other endpoints return an update
            // object directly so we can immedietly display the update without
            // needing to refetch the ticket.
            await api.cancelScheduledMessage({
                workspaceId,
                messageId,
            });

            try {
                const { updates } = await api.getConversation({
                    workspaceId,
                    ticketIdentity: conversationSource.ticketIdentity,
                });

                dispatch(
                    actions.processUpdates({
                        source: "Mutation:cancelScheduledMessage",
                        ...updates,
                    }),
                );
            } catch (err) {
                Log.error(
                    "A message was cancelled but we weren't able to refresh the conversation",
                    {
                        tags: {
                            product: "Inbox",
                            conversationId,
                            messageId,
                            originalError:
                                err instanceof Error
                                    ? err.message
                                    : (err as string),
                        },
                    },
                );
            }
        },
        options,
    );
};
