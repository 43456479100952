import isNil from "lodash/isNil";

import {
    mapTicketDateOfBirthInfoToPatientSummaryAgeParts,
    mapTicketPatientExternalIdsToPatientSummaryExternalIds,
    mapTicketPatientGenderToPatientSummaryGender,
    mapTicketPatientNamesToPatientSummaryParts,
} from "shared/concierge/conversations/tickets/mappers/PatientMapper";
import {
    MatchState,
    PatientThreadSuggestedPatientDetails,
    PatientThreadTriageProxyInformation,
    PatientThreadTriageRequestNote,
    RequestReceptionData,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    PatientMatchState,
    PatientTriageProxyInformation,
    PatientTriageReceptionInformation,
    PatientTriageRequestConversationItem,
    PatientTriageSuggestedPatient,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    mapBaseConversationItem,
    mapPatientResponseSections,
} from "./conversationItem.helpers";

/**
 * Maps ticket Patient Triage incoming request to
 * conversation Patient Triage incoming request
 *
 * @param ticketPatientTriageItem Patient Triage incoming request
 * as it's sent from the API
 * @returns mapped Patient Triage incoming request conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapPatientTriageItem(
    ticketPatientTriageItem: PatientThreadTriageRequestNote,
): PatientTriageRequestConversationItem | undefined {
    if (!ticketPatientTriageItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }

    // Do not accept a request that has no responses
    if (
        isNil(ticketPatientTriageItem.message.responses) ||
        ticketPatientTriageItem.message.responses.length === 0
    ) {
        throw new Error(
            "Property responses invalid in ticket item, but it is required",
        );
    }

    const responses = ticketPatientTriageItem.message.responses;

    const result = mapPatientResponseSections(responses);
    if (result.failedToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more responses have failed to be mapped",
        );
    }

    const baseConversationItem = mapBaseConversationItem(
        ticketPatientTriageItem,
    );

    const matchStatus = mapMatchState(
        ticketPatientTriageItem.message.initialRequestMatchState,
    );
    if (!matchStatus) {
        throw new Error("Cannot map ticket item as match status is unknown");
    }

    const suggestedMatch = mapSuggestedMatchInfo(
        ticketPatientTriageItem.message.suggestedMatch,
    );
    if (matchStatus === "Suggested" && !suggestedMatch) {
        throw new Error(
            "Cannot map ticket item as match status is Suggested, and there is no suggestedMatch",
        );
    }

    const patientProxy = mapProxyInformation(
        ticketPatientTriageItem.message.patientTriageProxyInformation,
    );
    const reception = mapReceptionInformation(
        ticketPatientTriageItem.message.requestReceptionData,
    );

    // expecting it to be set, but ok if not
    const title = ticketPatientTriageItem.message.patientRequestType || null;

    return {
        ...baseConversationItem,
        contentType: "PatientTriageRequestNote",
        createdBy: { type: "Patient" },
        sections: result.sections,
        requestTitle: title,
        // 'Admin' prefix check matches current server side filter
        requestType: title?.startsWith("Admin") ? "Admin" : "Medical",
        initialRequestMatchState: matchStatus,
        suggestedMatch,
        patientProxy,
        reception,
    };
}

function mapProxyInformation(
    proxyInfo?: PatientThreadTriageProxyInformation | null,
): PatientTriageProxyInformation | null {
    if (!proxyInfo) {
        return null;
    }

    return {
        firstName: proxyInfo.proxyFirstName || null,
        lastName: proxyInfo.proxyLastName || null,
        phoneNumber: proxyInfo.proxyPhoneNumber || null,
        patientPreferredPhoneNumber:
            proxyInfo.patientPreferredPhoneNumber || null,
        relationshipToPatient: proxyInfo.proxyRelationshipToPatient || null,
    };
}

function mapReceptionInformation(
    receptionInfo?: RequestReceptionData | null,
): PatientTriageReceptionInformation | null {
    if (!receptionInfo) return null;
    return { userId: receptionInfo.userId ?? null };
}

function mapSuggestedMatchInfo(
    suggestedMatch?: PatientThreadSuggestedPatientDetails | null,
): PatientTriageSuggestedPatient | null {
    if (!suggestedMatch) {
        return null;
    }

    return {
        ...mapTicketPatientNamesToPatientSummaryParts(suggestedMatch.names),
        ...mapTicketDateOfBirthInfoToPatientSummaryAgeParts(
            suggestedMatch.dateOfBirth,
        ),
        gender: mapTicketPatientGenderToPatientSummaryGender(
            suggestedMatch.gender,
        ),
        externalIds: mapTicketPatientExternalIdsToPatientSummaryExternalIds(
            suggestedMatch.patientExternalIdentity?.patientExternalIds,
        ),
        postCode: suggestedMatch?.postCode ?? null,
    };
}

function mapMatchState(matchState: MatchState): PatientMatchState | undefined {
    switch (matchState) {
        case MatchState.NoMatch:
            return "NoMatch";
        case MatchState.Suggested:
            return "Suggested";
        case MatchState.Verified:
            return "Verified";

        case MatchState.None:
        default:
            return;
    }
}
