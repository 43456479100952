import { useMemo } from "react";

import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { PatientSmsItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import {
    ConversationItemBadgeContainer,
    ConversationItemContainer,
    ConversationItemContent,
    ConversationItemFooter,
    ConversationItemFooterInfo,
    ConversationItemHeader,
    ConversationThreadListItem,
    ScheduledMessageFeedbackContainer,
} from "domains/inbox/components/ConversationItem/ConversationItem.styles";
import { FreeText } from "domains/inbox/components/ConversationItem/components/FreeText/FreeText";
import { MessageNameTag } from "domains/inbox/components/ConversationItem/components/MessageNameTag/MessageNameTag";
import { SaveItemAction } from "domains/inbox/components/ConversationItem/components/SaveItemAction/SaveItemAction";
import { ScheduledMessageFeedback } from "domains/inbox/components/ConversationItem/components/ScheduledMessageFeedback/ScheduledMessageFeedback";
import { SentToDetail } from "domains/inbox/components/ConversationItem/components/SentToDetail/SentToDetail";
import { getVideoConsultLinkNote } from "domains/inbox/components/ConversationItem/conversationItems.utils";
import { VideoConsultLinkNote } from "domains/inbox/components/ConversationItem/itemTypes/VideoConsultLinkNote/VideoConsultLinkNote";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { useGetClinicianToPatientMessageInfo } from "domains/inbox/components/ConversationItem/useCopyInfo";
import { getItemAlignment } from "domains/inbox/components/ConversationView/components/ConversationThread/conversationThread.utils";
import { userflowIds } from "domains/inbox/util";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

import { DeliveryDetailsWithTooltip } from "../../components/DeliveryDetails/DeliveryDetails";

export const PatientSmsMessage = ({
    item,
    conversation,
}: ConversationItemComponent<PatientSmsItem>) => {
    const sender = useUser({ userId: item.createdBy.id });

    const senderName = formatUserDisplayName({
        user: sender,
        considerCurrentUser: false,
    });

    const videoConsultLink = useMemo(
        () => getVideoConsultLinkNote(conversation.items, item.id),
        [conversation.items, item.id],
    );

    const copyInfo = useGetClinicianToPatientMessageInfo({
        patientId: conversation.regardingPatientId,
        contactMethod: item.mobileNumber,
        userId: item.createdBy.id,
        dateCreatedAt: item.createdAt,
        messageBody: item.body,
    });

    return (
        <ConversationThreadListItem
            $align={getItemAlignment({
                item,
            })}
        >
            <ConversationItemContainer
                data-testid={`conversation-item-${item.id}`}
                $color={item.deliveryStatus === "Failed" ? "red" : "white"}
            >
                <ConversationItemHeader>
                    <MessageNameTag
                        avatar={
                            <UI.Avatar
                                name={senderName}
                                size="small"
                                colour="grey"
                            />
                        }
                        name={senderName}
                    />
                    <ConversationItemBadgeContainer
                        data-userflow-id={
                            userflowIds.conversationView.messageHeaderLabel
                        }
                    >
                        {item.deliveryStatus === "Failed" ? (
                            <UI.Ds.Badge color="red" size="small">
                                Failed
                            </UI.Ds.Badge>
                        ) : (
                            <UI.Ds.Badge color="greyscale" size="small">
                                Staff
                            </UI.Ds.Badge>
                        )}
                    </ConversationItemBadgeContainer>
                </ConversationItemHeader>
                <ConversationItemContent>
                    <UI.Flex flexDirection="column" gap="3">
                        <UI.Item>
                            <FreeText>{item.body}</FreeText>
                            {videoConsultLink && (
                                <VideoConsultLinkNote
                                    item={videoConsultLink}
                                    conversation={conversation}
                                />
                            )}
                        </UI.Item>
                        <UI.Flex flexDirection="column" gap="1">
                            <SaveItemAction
                                conversation={conversation}
                                item={item}
                                getTextToCopy={copyInfo}
                            />
                        </UI.Flex>
                    </UI.Flex>
                </ConversationItemContent>
                <ConversationItemFooter>
                    <SentToDetail>{item.mobileNumber}</SentToDetail>
                    <ConversationItemFooterInfo>
                        <DeliveryDetailsWithTooltip
                            createdAt={item.createdAt}
                            deliveryStatusUpdatedAt={
                                item.deliveryStatusUpdatedAt
                            }
                            deliveryStatus={item.deliveryStatus}
                        />
                    </ConversationItemFooterInfo>
                </ConversationItemFooter>
                {item.deliveryScheduledAt && (
                    <ScheduledMessageFeedbackContainer>
                        <ScheduledMessageFeedback
                            deliveryScheduledAt={item.deliveryScheduledAt}
                            messageId={item.messageId}
                            conversationId={conversation.id}
                        />
                    </ScheduledMessageFeedbackContainer>
                )}
            </ConversationItemContainer>
        </ConversationThreadListItem>
    );
};
