import { Children, ReactNode, isValidElement } from "react";

import { Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { COMPLEXITY_LABELS, FLOREY_STATUS_LABELS } from "./constants";
import { FilterOptions } from "./types";

const formatDates = (filters: FilterOptions) => {
    if (!filters.dateStart && !filters.dateEnd) return "Sent - All time;";

    const dateType = filters.dateType === "date-sent" ? "Sent" : "Answered";

    if (!filters.dateStart)
        return `${dateType} - to ${DateHelpers.formatDate(
            filters.dateEnd,
            DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
        )};`;

    if (!filters.dateEnd)
        return `${dateType} - from ${DateHelpers.formatDate(
            filters.dateStart,
            DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
        )};`;

    return `${dateType} - from ${DateHelpers.formatDate(
        filters.dateStart,
        DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
    )} to ${DateHelpers.formatDate(
        filters.dateEnd,
        DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
    )};`;
};

const JoinedWhitespaceSeparatedText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <>
            {Children.map(children, (child) => {
                if (!isValidElement(child)) {
                    return null;
                }

                return <> {child}</>;
            })}
        </>
    );
};

type CurrentFiltersTextProps = {
    currentFilters: FilterOptions;
};

export const CurrentFiltersText = ({
    currentFilters,
}: CurrentFiltersTextProps) => {
    return (
        <Text skinny>
            <Text as="span" variant="label">
                Show:
            </Text>
            <JoinedWhitespaceSeparatedText>
                <Text as="span">{formatDates(currentFilters)}</Text>
                {currentFilters.status.length && (
                    <Text as="span">
                        Status -{" "}
                        {currentFilters.status
                            .map(
                                (statusValue) =>
                                    FLOREY_STATUS_LABELS[statusValue],
                            )
                            .join(", ")}
                        ;
                    </Text>
                )}
                {currentFilters.complexity.length && (
                    <Text as="span">
                        Complexity -{" "}
                        {currentFilters.complexity
                            .map(
                                (complexityValue) =>
                                    COMPLEXITY_LABELS[complexityValue],
                            )
                            .join(", ")}
                        ;
                    </Text>
                )}
            </JoinedWhitespaceSeparatedText>
        </Text>
    );
};
