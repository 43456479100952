import { useState } from "react";

import { Button, Card, Text, Tokens } from "@accurx/design";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { ROUTES_CHAIN } from "shared/Routes";

import { SettingsTabs } from "./Settings.types";
import { getDefaultTab } from "./Settings.utils";
import { SettingsCookies } from "./SettingsCookies";
import { SettingsPassword } from "./SettingsPassword";
import { SettingsProfile } from "./SettingsProfile";
import { SettingsSecurity } from "./SettingsSecurity";
import { SettingsSignature } from "./SettingsSignature";
import { SettingsTermsAndConditions } from "./SettingsTermsAndConditions";

const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: ${Tokens.SIZES[1]};
    text-align: left;
`;

export const SettingsMain = () => {
    const history = useHistory();
    const { tab }: { tab?: string } = useParams();
    const [currentTab, setCurrentTab] = useState(getDefaultTab(tab || ""));

    const handleTabClick = (nextTab: SettingsTabs): void => {
        history.push(
            ROUTES_CHAIN.practicesSettings.replace(
                ":tab",
                nextTab.toLowerCase(),
            ),
        );
        setCurrentTab(nextTab);
    };

    const renderNavigation = (): JSX.Element => (
        <>
            <StyledButton
                theme={
                    currentTab === SettingsTabs.Profile
                        ? "primary"
                        : "transparent"
                }
                onClick={(): void => handleTabClick(SettingsTabs.Profile)}
                text="Profile"
            />
            <StyledButton
                theme={
                    currentTab === SettingsTabs.Password
                        ? "primary"
                        : "transparent"
                }
                onClick={(): void => handleTabClick(SettingsTabs.Password)}
                text="Password"
            />
            <StyledButton
                theme={
                    currentTab === SettingsTabs.Signature
                        ? "primary"
                        : "transparent"
                }
                onClick={(): void => handleTabClick(SettingsTabs.Signature)}
                text="Message Signature"
            />
            <StyledButton
                theme={
                    currentTab === SettingsTabs.Cookies
                        ? "primary"
                        : "transparent"
                }
                onClick={(): void => handleTabClick(SettingsTabs.Cookies)}
                text="Cookies"
            />
            <StyledButton
                theme={
                    currentTab === SettingsTabs.Security
                        ? "primary"
                        : "transparent"
                }
                onClick={(): void => handleTabClick(SettingsTabs.Security)}
                text="Security"
            />
            <Button
                theme={
                    currentTab === SettingsTabs.TermsAndConditions
                        ? "primary"
                        : "transparent"
                }
                className="w-100 text-left"
                onClick={(): void =>
                    handleTabClick(SettingsTabs.TermsAndConditions)
                }
                text="Terms and Conditions"
            />
        </>
    );

    const renderSection = (): JSX.Element => {
        switch (currentTab) {
            case SettingsTabs.Password:
                return <SettingsPassword />;
            case SettingsTabs.Signature:
                return <SettingsSignature />;
            case SettingsTabs.Cookies:
                return <SettingsCookies />;
            case SettingsTabs.Security:
                return <SettingsSecurity isChain={true} />;
            case SettingsTabs.TermsAndConditions:
                return <SettingsTermsAndConditions />;
            case SettingsTabs.Profile:
            default:
                return <SettingsProfile />;
        }
    };

    return (
        <div className="row">
            <div className="col-12 mb-4">
                <Text variant="title" as="h1" skinny>
                    Manage your account
                </Text>
            </div>
            <div className="col-12 col-sm-5 col-lg-3 mb-4">
                <Card spacing={1.5}>{renderNavigation()}</Card>
            </div>
            <div className="col-12 col-sm-7 col-lg-9 mb-4">
                {renderSection()}
            </div>
        </div>
    );
};
