import {
    AssignedEventItem,
    Assignee,
    ContactMember,
    ConversationItem as ConversationItemDto,
    IncomingMessageItem,
    Member,
    OutgoingMessageItem,
    ReadState,
    UserMember,
} from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import { mapAssignee } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapAssignee";
import { mapAttachment } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapAttachment";
import { mapConversationItemIdentityToConversationItemId } from "domains/concierge/internal/api/clinicianMessaging/mappers/mapConversationItemIdentityToConversationItemId";
import {
    ClinicianMessageItem,
    StateChangeItem,
} from "domains/concierge/schemas/ConversationItemSchema";
import { ConversationItem } from "domains/concierge/types";

const mapCreatedBy = (dto: Member): ClinicianMessageItem["createdBy"] => {
    switch (dto.discriminator) {
        case "user":
            return mapCreatedByUser(dto);
        case "contact":
            return mapCreatedByContact(dto);
        default:
            throw new Error("Message created by was an invalid member type");
    }
};

const mapCreatedByUser = (
    dto: UserMember,
): ClinicianMessageItem["createdBy"] => {
    if (!dto.userId) {
        throw new Error("Created by user with missing user ID");
    }
    if (!dto.workspaceId) {
        throw new Error("Created by user with missing workspace ID");
    }

    return {
        type: "WorkspaceUser",
        userId: dto.userId,
        workspaceId: dto.workspaceId,
    };
};

const mapCreatedByContact = (
    dto: ContactMember,
): ClinicianMessageItem["createdBy"] => {
    if (!dto.emailAddress) {
        throw new Error("Created by contact with missing email address");
    }
    return {
        type: "Contact",
        displayName: dto.displayName || dto.emailAddress || "Unknown contact",
        emailAddress: dto.emailAddress,
    };
};

const mapReadStatus = (
    dto: IncomingMessageItem,
): ClinicianMessageItem["readStatus"] => {
    if (dto.dateReadByGp) {
        return "Read";
    } else {
        return "Delivered";
    }
};

const mapUserReadData = (
    readBy: ReadState[],
): ConversationItem["readDataByUserId"] => {
    const result: ConversationItem["readDataByUserId"] = {};

    for (const entry of readBy) {
        result[entry.userId] = {
            readAt: entry.readAt ?? null,
        };
    }

    return result;
};

const mapIncomingMessage = (dto: IncomingMessageItem): ClinicianMessageItem => {
    const id = mapConversationItemIdentityToConversationItemId({
        type: dto.type,
        id: dto.id,
    });
    const createdBy = mapCreatedBy(dto.createdBy);

    return {
        id,
        serverId: dto.id,
        contentType: "ClinicianMessage",
        body: dto.text,
        trimmedContent: dto.trimmedContent ?? undefined,
        readStatus: mapReadStatus(dto),
        createdBy,
        readDataByUserId: mapUserReadData(dto.readBy),
        attachments: dto.attachments.map(mapAttachment),
        createdAt: dto.dateSent,
    };
};

const mapOutgoingMessage = (dto: OutgoingMessageItem): ClinicianMessageItem => {
    const id = mapConversationItemIdentityToConversationItemId({
        type: dto.type,
        id: dto.id,
    });
    const createdBy = mapCreatedBy(dto.createdBy);

    return {
        id,
        serverId: dto.id,
        contentType: "ClinicianMessage",
        body: dto.text,
        trimmedContent: dto.trimmedContent ?? undefined,
        readStatus: "None",
        createdBy,
        readDataByUserId: mapUserReadData(dto.readBy),
        attachments: dto.attachments.map(mapAttachment),
        createdAt: dto.dateSent,
    };
};

export const mapStateChangeAssignee = (
    dto: Assignee | null | undefined,
): StateChangeItem["assignedTo"] => {
    if (!dto) return undefined;

    const assignee = mapAssignee(dto);

    switch (assignee.type) {
        case "User":
        case "Team":
            return assignee;
        default:
            return undefined;
    }
};

const mapAssignEvent = (dto: AssignedEventItem): StateChangeItem => {
    const id = mapConversationItemIdentityToConversationItemId({
        type: dto.type,
        id: dto.id,
    });

    let createdBy: StateChangeItem["createdBy"];

    switch (dto.createdBy.discriminator) {
        case "user":
            createdBy = { type: "User", id: dto.createdBy.userId };
            break;
        case "system":
        case "contact":
            Log.warn(
                "Received an AssignedEvent with a createdBy that isn't a user",
                {
                    tags: {
                        product: "Inbox",
                        createdByType: dto.createdBy.discriminator,
                        eventId: dto.id,
                    },
                },
            );
            createdBy = { type: "System" };
    }

    const assignedTo = mapStateChangeAssignee(dto.assignee);

    if (!assignedTo) {
        Log.warn("Invalid assignee in AssignedEvent", {
            tags: {
                product: "Inbox",
                eventId: dto.id,
            },
        });
    }

    return {
        id,
        serverId: dto.id,
        contentType: "StateChange",
        changeType: "Assignment",
        createdBy,
        createdAt: dto.dateSent,
        readDataByUserId: mapUserReadData(dto.readBy),
        assignedTo,
        note: dto.text,
    };
};

const safeMapItem = <T extends ConversationItemDto>(
    mapper: (dto: T) => ConversationItem,
    dto: T,
): ConversationItem | undefined => {
    try {
        return mapper(dto);
    } catch (e) {
        const errorMessage =
            e instanceof Error
                ? e.message
                : "Failed to map Clinician Message item";

        Log.error(errorMessage, {
            tags: {
                product: "Inbox",
                itemType: dto.type,
                itemId: dto.id,
            },
        });
    }
};

export const mapClinicianMessageItemToConversationItem = (
    dto: ConversationItemDto,
): ConversationItem | undefined => {
    switch (dto.discriminator) {
        case "incomingMessage":
            return safeMapItem(mapIncomingMessage, dto);
        case "outgoingMessage":
            return safeMapItem(mapOutgoingMessage, dto);
        case "assignedEvent":
            return safeMapItem(mapAssignEvent, dto);
        default:
            return safeMapItem(() => {
                throw new Error("Unsupported item type");
            }, dto);
    }
};
