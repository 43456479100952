import React, { ChangeEvent, ClipboardEvent } from "react";

import { Feedback, Flex, Icon, Input, Spinner, Text } from "@accurx/design";
import { useDispatch } from "react-redux";

import { useAppSelector } from "store/hooks";

import { actionCreators as sendMessageActionCreators } from "../SendMessageActions";
import { MessageType } from "../SendMessageReducer";
import {
    StyledSwitchGroup,
    StyledSwitchLabelLeft,
    StyledSwitchLabelRight,
} from "./MobileEmailInputComponents.styles";
import { LoadingStatus } from "./types";

export interface InputProps {
    value: string;
    error: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handlePaste?: (e: ClipboardEvent) => void;
    handleBlur?: () => void;
    allowToggle: boolean;
    isDummyPatient?: boolean;
    loadingStatus?: LoadingStatus;
}

const MobileEmailToggle = (): JSX.Element | null => {
    const dispatch = useDispatch();
    const messageType = useAppSelector(
        ({ messagePatient }) => messagePatient.messageType,
    );
    const isSending = useAppSelector(
        ({ messagePatient }) => messagePatient.isMessageSending,
    );

    const handleChange = (type: MessageType): void => {
        dispatch(sendMessageActionCreators.changeMessageType(type));
    };

    return (
        <StyledSwitchGroup data-testid="email-sms-toggle">
            <StyledSwitchLabelLeft active={messageType === MessageType.Sms}>
                <input
                    onChange={(): void => handleChange(MessageType.Sms)}
                    type="radio"
                    name="sms_or_email"
                    disabled={isSending}
                    id="sms"
                    value="sms"
                    data-testid="sms-toggle-select"
                    checked={messageType === MessageType.Sms}
                />
                Phone
            </StyledSwitchLabelLeft>
            <StyledSwitchLabelRight active={messageType === MessageType.Email}>
                <input
                    onChange={(): void => handleChange(MessageType.Email)}
                    type="radio"
                    name="sms_or_email"
                    disabled={isSending}
                    id="email"
                    value="email"
                    data-testid="email-toggle-select"
                    checked={messageType === MessageType.Email}
                />
                Email
            </StyledSwitchLabelRight>
        </StyledSwitchGroup>
    );
};

export const MobileInput = ({
    value,
    error,
    handleChange,
    handleBlur,
    allowToggle,
    isDummyPatient,
    loadingStatus,
}: InputProps) => {
    const isSending = useAppSelector(
        ({ messagePatient }) => messagePatient.isMessageSending,
    );

    const renderMobileInput = () => {
        if (loadingStatus === LoadingStatus.Loading) {
            return <Spinner placement="inline" dimension="small" />;
        } else {
            //We might get an error when loading the mobile number but have the mobile value anyway from a previous search.
            //If that's the case we should NOT show the error message.
            if (!value && loadingStatus === LoadingStatus.Errored) {
                error = "Could not load Patient's mobile number.";
            }
            //Currently, we should show the mobile input regardless whether it failed to load it.
            //We want to give the option for the user to manually fill it.
            return (
                <Flex alignItems="start">
                    <Input
                        required
                        type="text"
                        inputMode="tel"
                        value={value}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        data-testid="sms-input"
                        placeholder="Enter patient's mobile"
                        readOnly={isSending}
                    />
                    {allowToggle && <MobileEmailToggle />}
                </Flex>
            );
        }
    };

    return (
        <div className="form-group">
            <Text
                variant="label"
                as="label"
                props={{ className: "col-form-label" }}
            >
                Mobile number
            </Text>
            {renderMobileInput()}
            {!!error && (
                <div className="d-flex align-items-center mt-2">
                    <div style={{ lineHeight: "normal" }}>
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                    </div>
                    <div>
                        <Text props={{ className: "m-0" }}>{error}</Text>
                    </div>
                </div>
            )}
            {isDummyPatient && (
                <Feedback
                    colour="learning"
                    title={`Use your number to see how messages appear to patients`}
                    props={{ className: "mt-2" }}
                >
                    <Text skinny>
                        (When messaging a real patient we'll supply their number
                        from the NHS Spine)
                    </Text>
                </Feedback>
            )}
        </div>
    );
};

export const EmailInput = ({
    value,
    error,
    handleChange,
    handlePaste,
    handleBlur,
    allowToggle,
    isDummyPatient,
}: InputProps) => {
    const isSending = useAppSelector(
        ({ messagePatient }) => messagePatient.isMessageSending,
    );

    return (
        <div className="form-group col-cm-12 font-weight-bold">
            <Text
                variant="label"
                as="label"
                props={{ className: "col-form-label" }}
            >
                Email address
            </Text>
            <Flex alignItems="start">
                <Input
                    required
                    type="text"
                    inputMode="email"
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={handlePaste}
                    data-testid="email-input"
                    placeholder="Enter patient's email"
                    readOnly={isSending}
                />
                {allowToggle && <MobileEmailToggle />}
            </Flex>
            {!!error && (
                <div className="d-flex align-items-center mt-2">
                    <div style={{ lineHeight: "normal" }}>
                        <Icon colour="red" name="Error" size={3} theme="Fill" />
                    </div>
                    <div>
                        <Text props={{ className: "m-0" }}>{error}</Text>
                    </div>
                </div>
            )}
            {isDummyPatient && (
                <Feedback
                    colour="learning"
                    title={`Use your email to see how messages appear to patients`}
                    props={{ className: "mt-2" }}
                />
            )}
        </div>
    );
};
