import React from "react";

import * as UI from "@accurx/design";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";

import { StyledTabsList } from "./LinkTabsList.styles";

export type LinkTabsListProps = {
    ariaLabel: string;
};

export const LinkTabsList = ({
    ariaLabel,
    children,
}: React.PropsWithChildren<LinkTabsListProps>) => {
    return (
        <NavigationMenu.Root>
            <StyledTabsList aria-label={ariaLabel}>
                <UI.Flex>{children}</UI.Flex>
            </StyledTabsList>
        </NavigationMenu.Root>
    );
};
