import { ComposePatient } from "@accurx/compose";
import { mapPatientDemographicsNamesToPatientSummaryParts } from "@accurx/concierge/util/mapPatientDemographicsToPatientSummary";
import { PatientDemographics } from "@accurx/native";

export const mapPatientDemographicsToComposePatient = (
    patient: PatientDemographics,
): ComposePatient => {
    const names = mapPatientDemographicsNamesToPatientSummaryParts(
        patient.patientName,
    );

    return {
        ...names,
        externalIds: patient.patientExternalIdentityDto.patientExternalIds,
    };
};
