/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import noop from "lodash/noop";
import { Unsubscribable, from } from "rxjs";
import { distinctUntilKeyChanged, filter, first } from "rxjs/operators";

import { ConnectionStateNew } from "shared/hubClient/ConnectionState";
import { BehaviorSubscribable } from "shared/types/rxjs.types";

export interface DataFetcher {
    start: () => Promise<void>;
    teardown: () => void;
}

type EventMeta = {
    isInitialFetch: boolean;
    polling: boolean;
    connected: boolean;
    refreshRate?: number;
};

type OfflinePolling = {
    /**
     * A feed of updates to the SignalR connection.
     */
    connectionStateFeed: BehaviorSubscribable<ConnectionStateNew>;

    /**
     * Refresh rate defines, in milliseconds, how often we should poll for data
     * when the SignalR connection drops.
     */
    refreshRate: number;

    onRefreshInterval?: (details: Omit<EventMeta, "isInitialFetch">) => void;

    onPollingStateChange?: (details: Omit<EventMeta, "isInitialFetch">) => void;
};

type DataFetcherArgs = {
    onFetchStart?: (meta: EventMeta) => void;

    onFetchSuccess?: (meta: EventMeta) => void;

    onFetchError?: (error: Error, meta: EventMeta) => void;

    fetchFn: (meta: EventMeta) => Promise<void>;
};

/**
 * PollingDataFetcher - Makes an initial fetch when first started and then
 * monitors the SignalR connection status. If SignalR disconnects it starts
 * polling at a given refresh interval until SignalR reconnects.
 *
 * Behaviour:
 * - Waits until we know if SignalR is either connected or disconnected then
 *   fetch data.
 * - Sets up an interval that ticks every x milliseconds.
 * - If SignalR is disconnected at any point in a refresh interval poll for data
 *   on the next tick.
 * - If the SignalR connection stays connected never poll.
 */
export class PollingDataFetcher implements DataFetcher {
    private refreshRate: number;
    private polling = false;
    private connected = false;
    private isLive = false;
    private intervalHandle: ReturnType<typeof setTimeout> | null = null;
    private connectionStateFeed: BehaviorSubscribable<ConnectionStateNew>;
    private fetchFn: (meta: EventMeta) => Promise<void>;
    private onFetchStart: (meta: EventMeta) => void;
    private onFetchSuccess: (meta: EventMeta) => void;
    private onFetchError: (error: Error, meta: EventMeta) => void;
    private onRefreshInterval: (
        details: Omit<EventMeta, "isInitialFetch">,
    ) => void;
    private onPollingStateChange: (
        details: Omit<EventMeta, "isInitialFetch">,
    ) => void;
    private readonly subscriptionHandles: Unsubscribable[] = [];

    constructor(args: OfflinePolling & DataFetcherArgs) {
        this.refreshRate = args.refreshRate;
        this.connectionStateFeed = args.connectionStateFeed;
        this.fetchFn = args.fetchFn;
        this.onFetchStart = args.onFetchStart ?? noop;
        this.onFetchSuccess = args.onFetchSuccess ?? noop;
        this.onFetchError = args.onFetchError ?? noop;
        this.onRefreshInterval = args.onRefreshInterval ?? noop;
        this.onPollingStateChange = args.onPollingStateChange ?? noop;
    }

    public start(): Promise<void> {
        return new Promise((resolve, reject) => {
            // If start has already been called make sure that we only ever respond
            // to the first call
            if (this.isLive) return resolve();
            this.isLive = true;

            // Subscribe to connection status changes and synchronise internal state
            // so that we can keep track of wether we should be polling on the next
            // refresh interval.
            const allConnectionEvents = from(this.connectionStateFeed).pipe(
                distinctUntilKeyChanged("state"),
                filter((event) => event.state !== "Initialising"),
            );
            const allEventsHandle = allConnectionEvents.subscribe((event) =>
                this.syncPollingState(event),
            );

            // Subscribe to the first connection event and:
            // 1. Initially fetch data
            // 2. Setup the refresh interval
            // 3. Enable polling if SignalR is disconnected
            const firstEvent = allConnectionEvents.pipe(first());
            const firstEventHandle = firstEvent.subscribe(async () => {
                const meta: EventMeta = {
                    isInitialFetch: true,
                    polling: this.polling,
                    connected: this.connected,
                    refreshRate: this.refreshRate,
                };
                try {
                    this.onFetchStart(meta);
                    await this.fetchFn(meta);
                    this.onFetchSuccess(meta);
                    resolve();
                } catch (e) {
                    this.polling = true;
                    this.onFetchError(e, meta);
                    reject(e);
                }

                this.setupNextRefreshTick();
            });

            this.subscriptionHandles.push(allEventsHandle, firstEventHandle);
        });
    }

    public teardown(): void {
        this.isLive = false;
        if (this.intervalHandle) {
            clearTimeout(this.intervalHandle);
        }
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
    }

    private syncPollingState({ state }: ConnectionStateNew) {
        this.connected = state === "Connected";

        // Enable polling if we just entered a disconnected state
        if (state !== "Connected") {
            this.polling = true;
            this.onPollingStateChange({
                polling: this.polling,
                connected: this.connected,
                refreshRate: this.refreshRate,
            });
        }
    }

    private setupNextRefreshTick() {
        if (!this.isLive) {
            return;
        }

        this.intervalHandle = setTimeout(
            () => this.onRefreshTick(),
            this.refreshRate,
        );
    }

    private async onRefreshTick() {
        this.onRefreshInterval({
            polling: this.polling,
            connected: this.connected,
            refreshRate: this.refreshRate,
        });

        // If polling is not enabled then we know that SignalR connection hasn't
        // dropped since the last refresh interval and there's nothing to do.
        if (!this.polling) {
            this.setupNextRefreshTick();
            return;
        }

        // Cache the connected status before we fetch data. We want to know if
        // we're connected before we fetch because there is an edge case where
        // SignalR reconnects while we're fetching. In that case we may have
        // missed some SignalR updates while fetching and should not switch off
        // polling.
        const wasConnectedBeforeFetching = this.connected;

        const meta: EventMeta = {
            isInitialFetch: false,
            polling: this.polling,
            connected: this.connected,
            refreshRate: this.refreshRate,
        };
        // Try to fetch data:
        // - If the call is successful and we are now back in a connected state then disable polling.
        // - If the call is successful and we're still disconnected then keep polling enabled.
        // - If the call was unsuccessful then keep polling enabled
        try {
            this.onFetchStart(meta);
            await this.fetchFn(meta);
            this.onFetchSuccess(meta);

            // If we are now back in a connected state then we can switch off polling.
            if (wasConnectedBeforeFetching) {
                this.polling = false;
                this.onPollingStateChange({
                    polling: this.polling,
                    connected: this.connected,
                    refreshRate: this.refreshRate,
                });
            }
        } catch (e) {
            this.onFetchError(e, meta);
        }

        this.setupNextRefreshTick();
    }
}

export class NonPollingDataFetcher implements DataFetcher {
    private fetchFn: (meta: EventMeta) => Promise<void>;
    private onFetchStart: (meta: EventMeta) => void;
    private onFetchSuccess: (meta: EventMeta) => void;
    private onFetchError: (error: Error, meta: EventMeta) => void;

    constructor(args: DataFetcherArgs) {
        this.fetchFn = args.fetchFn;
        this.onFetchStart = args.onFetchStart ?? noop;
        this.onFetchSuccess = args.onFetchSuccess ?? noop;
        this.onFetchError = args.onFetchError ?? noop;
    }

    public async start(): Promise<void> {
        const meta: EventMeta = {
            isInitialFetch: true,
            polling: false,
            connected: false,
        };
        try {
            this.onFetchStart(meta);
            await this.fetchFn(meta);
            this.onFetchSuccess(meta);
        } catch (e) {
            this.onFetchError(e, meta);
            throw e;
        }
    }

    public teardown(): void {}
}

export const createDataFetcher = (
    args: DataFetcherArgs & { offlinePolling?: OfflinePolling },
): DataFetcher => {
    const { offlinePolling, ...dataFetcherArgs } = args;
    return offlinePolling
        ? new PollingDataFetcher({
              ...offlinePolling,
              ...dataFetcherArgs,
          })
        : new NonPollingDataFetcher(dataFetcherArgs);
};
