import { RegisterAccountProductType } from "@accurx/api/account";

/**
 * Convert query params to a consistent value.
 * Desktop vs Web are setting different values, but api always expect number
 * @param productSearchParam value read in the queryParams "product"
 * @returns Number - 1 (default) or 2
 */
export const mapProductSearchParamToProductType = (
    productSearchParam: string | null,
): RegisterAccountProductType => {
    switch (productSearchParam) {
        case "2":
        case "Fleming":
            return RegisterAccountProductType.Fleming;

        case "1":
        case "Chain":
        default:
            return RegisterAccountProductType.Chain;
    }
};

/**
 * Create a formated query params search string for Pre/Post links
 * search queryParams could contain some of those values:
 * "next": redirect to a specific location post login
 * "referrer", "dest": Record view redirection
 * "externalReferrer": tracking analytics (ie: EPRQuickLaunch, ClinicianConversation)
 * "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content": Marketing tracking
 *
 * @param search - queryParams search (ie: window.location.search)
 * @param paramsToRemove - array of queryParams to remove
 * @returns String ie: "?product=2&utm_source=email"
 */
export const formatSearch = ({
    search,
    paramsToRemove,
}: {
    search: string;
    paramsToRemove?: string[];
}) => {
    const params = new URLSearchParams(search);

    // Format "product" from "?product=Fleming" to "?product=2"
    const productSearchParam = params.get("product");
    const productType = mapProductSearchParamToProductType(productSearchParam);
    params.set("product", `${productType}`);

    // Removing unwanted keys
    if (paramsToRemove) {
        paramsToRemove.forEach((param) => {
            params.delete(param);
        });
    }

    const paramsString = params.toString();
    return paramsString ? `?${paramsString}` : "";
};
