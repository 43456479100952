import isNil from "lodash/isNil";

import {
    NoteSegments,
    NoteTagSegment,
    NoteTextSegment,
    NoteUserGroupTagSegment,
    PatientThreadNote,
    SegmentType,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    NoteConversationItem,
    NoteSegment,
} from "shared/concierge/conversations/types/item.types";

import { NO_ITEM_CONTENT_ERROR } from "./conversationItem.constants";
import {
    mapBaseConversationItem,
    mapCreatedByOptionalUser,
} from "./conversationItem.helpers";

/**
 * Maps ticket user note to conversation user note
 *
 * @param ticketNoteItem as it's sent from the API
 * @returns mapped conversation item
 * @throws {Error} if the necessary props are not present
 */
export function mapUserNoteItem(
    ticketNoteItem: PatientThreadNote,
): NoteConversationItem | undefined {
    if (!ticketNoteItem.message) {
        throw new Error(NO_ITEM_CONTENT_ERROR);
    }
    if (!ticketNoteItem.message.segments) {
        throw new Error(
            "Property segments invalid in ticket item, but it is required",
        );
    }

    const baseConversationItem = mapBaseConversationItem(ticketNoteItem);

    const result = mapNoteSegments(ticketNoteItem.message.segments);
    if (!result || result.failToMapCount > 0) {
        throw new Error(
            "Cannot map ticket item as one or more note segments failed to be mapped",
        );
    }

    return {
        ...baseConversationItem,
        segments: result.segments,
        // Ok if createdByUserId is not there because it could be a system created note
        createdBy: mapCreatedByOptionalUser(
            ticketNoteItem.message.createdByUserId,
        ),
        contentType: "Note",
    };
}

type NoteSegmentsResult = {
    segments: NoteSegment[];
    failToMapCount: number;
};

function mapNoteSegments(
    segments: NoteSegments,
): NoteSegmentsResult | undefined {
    const mergedArrays = [
        ...(segments.texts || []),
        ...(segments.tags || []),
        ...(segments.userGroupTags || []),
    ];

    if (mergedArrays.length === 0) {
        return;
    }

    let failToMapCount = 0;
    const result = mergedArrays
        // API DTO says order is always there and defined,
        // but still handling the remote possibility that order might not be defined
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .reduce<NoteSegment[]>((previousValue, currentValue) => {
            const mapped = mapNoteSegment(currentValue);
            if (!mapped) {
                failToMapCount++;
            }
            return mapped ? [...previousValue, mapped] : previousValue;
        }, []);

    return {
        segments: result,
        failToMapCount,
    };
}

function mapNoteSegment(
    segment: PossibleApiNoteSegments,
): NoteSegment | undefined {
    if (isTextSegment(segment) && !isNil(segment.text)) {
        return {
            type: "Text",
            text: segment.text,
        };
    }
    if (isUserTagSegment(segment) && segment.userId) {
        return {
            type: "UserTag",
            userId: segment.userId,
        };
    }
    if (isUserGroupTagSegment(segment) && segment.userGroupId) {
        return {
            type: "TeamTag",
            teamId: segment.userGroupId,
        };
    }

    return undefined;
}

type PossibleApiNoteSegments =
    | NoteTextSegment
    | NoteUserGroupTagSegment
    | NoteTagSegment;

function isTextSegment(
    segment: PossibleApiNoteSegments,
): segment is NoteTextSegment {
    return segment.type === SegmentType.Text && "text" in segment;
}

function isUserTagSegment(
    segment: PossibleApiNoteSegments,
): segment is NoteTagSegment {
    return segment.type === SegmentType.Tag && "userId" in segment;
}

function isUserGroupTagSegment(
    segment: PossibleApiNoteSegments,
): segment is NoteUserGroupTagSegment {
    return (
        segment.type === SegmentType.UserGroupTag && "userGroupId" in segment
    );
}
