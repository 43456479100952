import { getApiEndpoint, httpClient } from "@accurx/shared";
import { useQuery } from "@tanstack/react-query";

import { ApiQueryKey } from "../../api";
import { LoggedInOrOutUser } from "../../types/User";

export const AUTH_API_QUERY_KEY = { path: "/api/Account/IsLoggedIn" } as const;

export const useCurrentUserQuery = ({
    enabled,
    onSuccess,
}: {
    /** Whether the query should be done at component mounting */
    enabled: boolean;
    /** What to do when API call resolves
     * Different from react query onSuccess, as it's called only if the
     * component using the hook passes it through
     */
    onSuccess?: (user: LoggedInOrOutUser) => void;
}) => {
    return useQuery<LoggedInOrOutUser, Error, LoggedInOrOutUser, ApiQueryKey>({
        queryKey: [AUTH_API_QUERY_KEY],
        queryFn: async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint(AUTH_API_QUERY_KEY),
            );
            if (response.success && response.result !== null) {
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                onSuccess?.(response.result);
                // FIXME
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return response.result;
            }
            throw new Error(response.error ?? "Request failed");
        },
        enabled,
    });
};
