import { ComponentProps } from "react";

import { Tooltip } from "@accurx/inbox-design-library";

type TooltipProps = {
    root: Omit<ComponentProps<typeof Tooltip.Root>, "children">;
    trigger: ComponentProps<typeof Tooltip.Trigger>;
    // Omitting children because in WithTooltip component it is called `content`
    content: Omit<ComponentProps<typeof Tooltip.Content>, "children">;
};

type WithTooltipProps = {
    isEnabled?: boolean;
    content: React.ReactNode;
    props?: Partial<TooltipProps>;
    children: React.ReactNode;
};

export const WithTooltip = ({
    children,
    content,
    props,
    isEnabled = true,
}: WithTooltipProps) => {
    return (
        <Tooltip.Root {...props?.root}>
            <Tooltip.Trigger {...props?.trigger}>{children}</Tooltip.Trigger>
            {isEnabled && (
                <Tooltip.Content {...props?.content}>{content}</Tooltip.Content>
            )}
        </Tooltip.Root>
    );
};
