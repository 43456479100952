import styled from "styled-components";

export const StyledSelectorContainer = styled.div`
    width: 200px;
    margin-top: 0.5rem;
`;

export const StyledPaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding-bottom: 3rem;
`;
