import { NativeApi } from "domains/native/api/NativeApi";
import {
    Action,
    NativeTransport,
    SubscriptionEvent,
    Unsubscribe,
} from "domains/native/types";

import * as reducer from "./reducers";
import { store } from "./store";

export type NativeSubscription = keyof typeof reducer;

export const mapSubscriptionEventToReduxAction = (
    event: SubscriptionEvent,
): Action => {
    return {
        type: event.type,
        payload: event,
    };
};

export const startNativeSubscription = ({
    transport,
    workspaceId,
    subscription,
}: {
    transport: NativeTransport;
    workspaceId: number;
    subscription: NativeSubscription;
}): Unsubscribe => {
    return NativeApi.subscribe({
        transport,
        request: { type: subscription, workspaceId },
        onEvent: (event) => {
            store.dispatch(mapSubscriptionEventToReduxAction(event));
        },
    });
};
