import {
    NativeFetchError,
    NativeFetchExpectedError,
    NativeTransportError,
} from "@accurx/native";
import { SUPPORTED_FILE_EXTENSIONS } from "domains/compose/components/Compose/components/Attach/upload.constants";

export const getNativeUploadErrorMessage = (
    error: NativeFetchError | NativeTransportError,
) => {
    if (error instanceof NativeFetchExpectedError) {
        switch (error.reason) {
            case "FileTooBig":
                return "The selected file attachment is too big to upload";

            case "FileTypeInvalid": {
                const validExtensionsList =
                    SUPPORTED_FILE_EXTENSIONS.join(", ");

                return `The selected file attachment has an unsupported file type. files can only be one of the supported extensions (${validExtensionsList})`;
            }

            case "MedicalRecordDisconnected":
                return "Couldn't upload attachment as the medical recordis disconnected";

            default:
                return "Failed to upload attachment";
        }
    }

    return "Failed to upload attachment";
};
