import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";

import {
    StyledContainer,
    StyledText,
} from "./ResponseToCurrentAssigneeLabel.styles";

type ResponseToCurrentAssigneeLabelProps = {
    assigneeLabel: string;
};

export const ResponseToCurrentAssigneeLabel = ({
    assigneeLabel,
}: ResponseToCurrentAssigneeLabelProps) => {
    return (
        <StyledContainer>
            <Pill.Base
                radius="full"
                color={UI.Tokens.COLOURS.greyscale.cloud}
                border={false}
            >
                <Pill.Icon name="Assign" theme="Fill" colour="zinc" />
                <StyledText>
                    Response to: <b>{assigneeLabel}</b>
                </StyledText>
            </Pill.Base>
        </StyledContainer>
    );
};
