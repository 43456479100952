import * as UI from "@accurx/design";

export const ErrorFeedback = ({
    id,
    errorMessage,
}: {
    id: string;
    errorMessage: string;
}) => {
    return (
        <UI.FormFieldFeedback
            id={id}
            variant="error"
            text={
                <UI.Text variant="preview" skinny as="span">
                    {errorMessage}
                </UI.Text>
            }
        />
    );
};
