import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { Organisation, useCurrentUser } from "@accurx/auth";
import { useQueryClient } from "@tanstack/react-query";

import { IOrganisation } from "api/FlemingDtos";
import { acceptWorkspaceInvitation } from "api/WorkspaceApi";
import { WorkspaceInvite } from "api/WorkspaceDtos";

import { WORKSPACE_INVITES_QUERY_KEY } from "../queries/useGetWorkspaceInvitesQuery";

// remove invite from the cache for the user's list of invites
export const useRemoveCurrentInvite = () => {
    const queryClient = useQueryClient();

    const removeCurrentInvite = (workspaceId: number) =>
        queryClient.setQueryData(
            [WORKSPACE_INVITES_QUERY_KEY, {}],
            (cachedInvites: WorkspaceInvite[] = []) =>
                cachedInvites.filter(
                    ({ workspace }) => workspace.id !== workspaceId,
                ),
        );

    return removeCurrentInvite;
};

export const useAcceptWorkspaceInvitationMutation = createMutationHook<
    {
        workspaceId: number;
    },
    IOrganisation
>(() => {
    const { user, update } = useCurrentUser();

    return {
        mutationKey: ["AcceptWorkspaceInvitation"],
        mutationFn: async ({ workspaceId }) => {
            const response = await acceptWorkspaceInvitation(workspaceId);
            return returnDataOrThrow(response);
        },
        onSuccess: (data) => {
            const isAlreadyInOrg = user.organisations.some(
                (org) => org.orgId === data.orgId,
            );

            const organisations = isAlreadyInOrg
                ? user.organisations
                : [...user.organisations, data as Organisation];

            update({ ...user, organisations });
        },
    };
});
