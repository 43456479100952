import React from "react";

import {
    Checkbox,
    Text,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@accurx/design";

import { useAppSelector } from "store/hooks";

import {
    StyledEnablePatientResponse,
    StyledTooltipContentWrapper,
} from "./EnablePatientResponseToggle.styles";

type EnablePatientResponseToggleProps = {
    enabled: boolean;
    onChange: () => void;
    userEmail: string | undefined;
};

export const EnablePatientResponseToggle = ({
    enabled,
    onChange,
    userEmail,
}: EnablePatientResponseToggleProps) => {
    const isSecureEmail = useAppSelector(
        ({ account }) => account.user?.isSecureEmail || false,
    );

    const tooltipContent = isSecureEmail
        ? `Allow a patient to reply once with text and up to 5 photos. The patient's response will come back to ${
              userEmail || "your email."
          }`
        : `This feature is unavailable as you have logged in with an insecure email address. Please log in with
            either nhs.net or a secure (DCB1596) email address.`;

    return (
        <StyledEnablePatientResponse>
            <Checkbox
                id="chkAllowPatientResponse"
                text={
                    isSecureEmail
                        ? `Allow patient response`
                        : `Patient response not available`
                }
                checked={enabled}
                onCheckChange={onChange}
                disabled={!isSecureEmail}
            />
            &nbsp;&middot;&nbsp;&nbsp;
            <Tooltip>
                <TooltipTrigger>
                    <Text as="u" colour="zinc">
                        Learn more
                    </Text>
                </TooltipTrigger>
                <TooltipContent>
                    <StyledTooltipContentWrapper>
                        <Text skinny>{tooltipContent}</Text>
                    </StyledTooltipContentWrapper>
                </TooltipContent>
            </Tooltip>
        </StyledEnablePatientResponse>
    );
};
