import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { Checkbox, Pill } from "@accurx/inbox-design-library";
import {
    PatientDemographics,
    useMedicalRecordConnection,
    useNativeTrackingFields,
} from "@accurx/native";
import { PatientSearchResult } from "domains/inbox/components/PatientSearch/types";

import { PatientCard } from "../PatientCard";
import { StyledPanel } from "../PatientSearchPanel.styles";
import { PatientSearchConfirmRadioButton } from "../forms/PatientSearchConfirmRadioButton";

const NO_PATIENT_SELECTED_ERROR_ID = "no-patient-selected" as const;

export type PatientSearchResultsForRadio = (PatientSearchResult & {
    demographics: PatientDemographics;
    uniqueId: string;
})[];

export const PatientSearchConfirmForm = ({
    results,
    onCancel,
    onSelectPatient,
}: {
    results: PatientSearchResultsForRadio;
    onCancel: () => void;
    onSelectPatient: (
        patient: PatientDemographics,
        shouldOpenInSystem: boolean,
    ) => void;
}) => {
    const track = useAnalytics();
    const { accessType, medicalRecordSystem } = useNativeTrackingFields();
    const medicalRecordConnection = useMedicalRecordConnection();
    const [showNoPatientSelectedError, setShowNoPatientSelectedError] =
        useState(false);
    const [selectedPatient, setSelectedPatient] = useState(
        // autoselect if there is only one option
        results.length === 1 ? results[0] : null,
    );

    const [shouldOpenInSystem, setShouldOpenInSystem] = useState(false);

    const trackSearchConfirmClickEvent = ({
        hasError,
    }: {
        hasError: boolean;
    }) => {
        track("PatientCardSearchConfirm Button Click", {
            accessType,
            medicalRecordSystem,
            hasError,
            openInSystem: shouldOpenInSystem,
        });
    };

    return (
        <>
            <StyledPanel.Body>
                <UI.Flex flexDirection={"column"} gap="1">
                    {showNoPatientSelectedError && (
                        <UI.Feedback
                            props={{
                                id: NO_PATIENT_SELECTED_ERROR_ID,
                                "aria-live": "polite",
                            }}
                            colour="error"
                            title="Please select a patient"
                        />
                    )}
                    <form
                        aria-label="Select a patient"
                        id="select-searched-patient"
                    >
                        <fieldset
                            aria-describedby={
                                showNoPatientSelectedError
                                    ? NO_PATIENT_SELECTED_ERROR_ID
                                    : undefined
                            }
                        >
                            <UI.VisuallyHidden as="legend">
                                <UI.Text skinny variant="label" colour="night">
                                    Select a patient
                                </UI.Text>
                            </UI.VisuallyHidden>
                            <UI.Flex flexDirection={"column"} gap="1">
                                {results.map(({ uniqueId, ...patient }) => (
                                    <PatientSearchConfirmRadioButton
                                        key={`${uniqueId}`}
                                        value={uniqueId}
                                        label={<PatientCard {...patient} />}
                                        checkedValue={
                                            selectedPatient?.uniqueId ?? ""
                                        }
                                        onChange={() =>
                                            setSelectedPatient({
                                                ...patient,
                                                uniqueId,
                                            })
                                        }
                                    />
                                ))}
                            </UI.Flex>
                        </fieldset>
                    </form>
                </UI.Flex>
            </StyledPanel.Body>
            <StyledPanel.Footer>
                <Pill.SecondaryButton dimension="small" onClick={onCancel}>
                    <Pill.Icon
                        name="Arrow"
                        colour="blue"
                        size={3}
                        rotation="left"
                    />
                    <Pill.Text>Back</Pill.Text>
                </Pill.SecondaryButton>

                <UI.Flex gap="1">
                    {!!medicalRecordConnection.capabilities?.openInEMR && (
                        <Checkbox
                            id="open-in-system"
                            text={`Open patient in ${medicalRecordConnection.system}`}
                            checked={shouldOpenInSystem}
                            onCheckChange={(checked) =>
                                setShouldOpenInSystem(checked)
                            }
                        >
                            <UI.Text skinny variant="preview">
                                Open patient in {medicalRecordConnection.system}
                            </UI.Text>
                        </Checkbox>
                    )}
                    <Pill.PrimaryButton
                        type="submit"
                        form="select-patient-form"
                        dimension="small"
                        isLoading={false}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!selectedPatient) {
                                setShowNoPatientSelectedError(true);
                                trackSearchConfirmClickEvent({
                                    hasError: true,
                                });
                                return;
                            }

                            trackSearchConfirmClickEvent({ hasError: false });

                            onSelectPatient(
                                selectedPatient.demographics,
                                shouldOpenInSystem,
                            );
                        }}
                    >
                        <Pill.Text>Select patient</Pill.Text>
                    </Pill.PrimaryButton>
                </UI.Flex>
            </StyledPanel.Footer>
        </>
    );
};
