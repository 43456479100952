import * as UI from "@accurx/design";

import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { StyledOverlay } from "./LoadingOverlay.styles";

export const LoadingOverlay = () => {
    return (
        <StyledOverlay>
            <LoadingSpinner />
            <UI.Text variant="label">Loading workspace...</UI.Text>
        </StyledOverlay>
    );
};
