import { Ds } from "@accurx/design";

import { FloreyScoreSummary } from "app/questionnaireScoreboard/schemas";

import {
    getValidatedComplexityBadgeColour,
    getValidatedComplexityBadgeText,
} from "../validatedComplexityBadge.utils";

type ValidatedComplexityBadgeProps = Pick<FloreyScoreSummary, "complexity">;

/**
 * Renders a badge for the calculated complexity of a questionnaire response.
 */
export const ValidatedComplexityBadge = ({
    complexity,
}: ValidatedComplexityBadgeProps) => {
    return (
        <Ds.Badge color={getValidatedComplexityBadgeColour(complexity)}>
            {getValidatedComplexityBadgeText(complexity)}
        </Ds.Badge>
    );
};
