import { LoggedInCustomProperties } from "@accurx/shared";

import { TrackInhalerRemindersEvent } from "./ChainAnalyticsTracker";

/**
 * User clicks on "Inhaler Reminders"
 *
 * Event type: Button click
 * */
export const trackInhalerRemindersManagePracticeLinkClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackInhalerRemindersEvent("Manage Practice Page Link", props);
};

/**
 * User clicks to register interest for "Inhaler Reminders"
 *
 * Event type: Button click
 * */
export const trackInhalerRemindersRegisterInterestClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackInhalerRemindersEvent("Register Interest", props);
};
