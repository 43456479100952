import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const OrderedList = styled.ol`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[1]};
    list-style: none;
    padding: 0;
    margin: 0;
`;
