import {
    PatientThreadInitialUnreadItems,
    PatientThreadTicketDetails,
} from "@accurx/api/ticket";
import { Log } from "@accurx/shared";
import { isInstance } from "domains/concierge/internal/util/isInstance";
import { Conversation } from "domains/concierge/types";
import values from "lodash/values";

import { mapTicketIdentityToConversationId } from "./mapTicketIdentityToConversationId";
import { mapTicketToConversation } from "./mapTicketToConversation";

export const mapTicketUnreadItemUpdates = (
    result: PatientThreadInitialUnreadItems,
): Conversation[] => {
    const tickets: Record<string, PatientThreadTicketDetails> = {};

    for (const item of result.unreadItems ?? []) {
        if (!item.ticketSummary?.identity?.id) {
            Log.error("Missing conversation identity for item", {
                tags: { product: "Inbox" },
            });
            continue;
        }
        const conversationId = mapTicketIdentityToConversationId(
            item.ticketSummary.identity,
        );

        if (tickets[conversationId]) {
            tickets[conversationId].items = tickets[conversationId].items ?? [];
            tickets[conversationId].items?.push(item);
        } else {
            tickets[conversationId] = {
                ticketSummary: item.ticketSummary ?? null,
                isFullyLoaded: false,
                items: [item],
            };
        }
    }
    return values(tickets).map(mapTicketToConversation).filter(isInstance);
};
