import { Button, PopoverItem, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledButton = styled(Button)`
    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        flex-basis: 100%;
    }
    white-space: nowrap;
    display: flex;
    justify-content: center;
`;

const StyledUnavailablePopoverItem = styled(PopoverItem)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${Tokens.COLOURS.primary.yellow[10]};
    border: 1px solid ${Tokens.COLOURS.primary.yellow[25]};
    border-radius: ${Tokens.SIZES[0.5]};
    max-width: 100vw;
`;

export { StyledButton, StyledUnavailablePopoverItem };
