import { Button, Flex, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const Item = styled(Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2",
    flexWrap: "wrap",
})`
    padding: ${Tokens.SIZES[1.5]};
`;

export const Group = styled(Flex).attrs({
    flexDirection: "column",
})`
    border: ${Tokens.BORDERS.normal.silver};
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.greyscale.white};

    ${Item} {
        &:not(:last-child) {
            border-bottom: ${Tokens.BORDERS.normal.silver};
        }
    }
`;

// Cannot use Flex component as it doesn't support passing of
// extra props and attributes, such as data-testid
export const ItemHeader = styled.h3`
    display: flex;
    align-items: center;
    gap: ${Tokens.SIZES[1]};

    @media screen and (min-width: ${Tokens.BREAKPOINTS.md}) {
        gap: ${Tokens.SIZES[1.5]};
    }
`;

const ItemHeaderWrapper = styled(Flex).attrs(() => ({
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "1",
}))`
    flex-grow: 1;
`;

export const ItemHeaderText = styled(Text).attrs({
    skinny: true,
    variant: "label",
    forwardedAs: "span",
})``;

export const ItemHeaderButton = styled(Button)`
    white-space: nowrap;
`;

export const ItemContent = styled(Flex).attrs(() => ({
    flexDirection: "column",
    gap: "2",
}))`
    padding: ${Tokens.SIZES[1.5]};
    border-bottom: ${Tokens.BORDERS.normal.silver};
`;

export const StyledReviewUploadedPatients = {
    Group,
    Item,
    ItemHeader,
    ItemHeaderText,
    ItemHeaderButton,
    ItemHeaderWrapper,
    ItemContent,
};
