import {
    Conversation,
    IndividualContactParticipant,
    Participant,
    WorkspaceParticipant,
} from "@accurx/concierge/types";

export const getMainParticipant = (
    { participants }: Pick<Conversation, "participants">,
    currentWorkspaceId: number,
): Participant | null => {
    const workspaceContact = participants.find(
        (participant: Participant): participant is WorkspaceParticipant =>
            participant.type === "Workspace" &&
            participant.workspaceId !== currentWorkspaceId,
    );
    const firstIndividualContact = participants.find(
        (
            participant: Participant,
        ): participant is IndividualContactParticipant =>
            participant.type === "Contact",
    );

    return workspaceContact ?? firstIndividualContact ?? null;
};
