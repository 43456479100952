import React, { useState } from "react";

import {
    Button,
    Card,
    ErrorSummaryProvider,
    Text,
    Tokens,
} from "@accurx/design";
import styled from "styled-components";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    PatientTriageSettingObjectName,
    PatientTriageSettingObjectType,
} from "app/analytics/ChainAnalytics";
import { WorkingHours as IWorkingHours } from "app/practices/patientTriage/types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { WorkingHoursProps } from "./WorkingHours.types";
import {
    StaticWorkingHoursTable,
    WorkingHoursTable,
} from "./components/WorkingHoursTable";
import {
    StaticWorkingHoursTopLevelSettings,
    WorkingHoursTopLevelSettings,
} from "./components/WorkingHoursTopLevelSettings";

const StyledFooterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${Tokens.SIZES["2"]};
`;

export const WorkingHours = ({
    isEditable,
    suspendRequestsOutOfHours,
    allowAdminOutOfHours,
    excludeAdminFromLimit,
    handleSubmit,
    workingHours,
    bankHolidayWorkingHours,
}: WorkingHoursProps): JSX.Element | null => {
    const [isEditing, setIsEditing] = useState(false);
    const [
        updatedSuspendRequestsOutOfHours,
        setUpdatedSuspendRequestsOutOfHours,
    ] = useState(suspendRequestsOutOfHours);
    const [updatedAllowAdminOutOfHours, setUpdatedAllowAdminOutOfHours] =
        useState(allowAdminOutOfHours);
    const [updatedExcludeAdminFromLimit, setUpdatedExcludeAdminFromLimit] =
        useState(excludeAdminFromLimit);
    const [updatedWorkingHours, setUpdatedWorkingHours] =
        useState(workingHours);
    const [updatedBankHolidayWorkingHours, setUpdatedBankHolidayWorkingHours] =
        useState(bankHolidayWorkingHours);

    const loggedInAnalyticsProps = useFlemingLoggedInAnalytics();

    const baseAnalyticsProps = {
        ...loggedInAnalyticsProps,
        objectType: PatientTriageSettingObjectType.BUTTON,
    };

    const handleUpdateWorkDay = (updatedWorkDay: IWorkingHours) => {
        setUpdatedWorkingHours((workingHours) => {
            const updatedWorkingHours = workingHours.map((workDay) => {
                if (workDay.day === updatedWorkDay.day) {
                    return updatedWorkDay;
                }
                return workDay;
            });
            return updatedWorkingHours;
        });
    };

    const onSubmit = () => {
        ChainAnalyticsTracker.trackPatientTriageManagementSettingWorkingHours({
            ...baseAnalyticsProps,
            objectName: PatientTriageSettingObjectName.SAVE,
        });

        handleSubmit({
            suspendRequestsOutOfHours: updatedSuspendRequestsOutOfHours,
            allowAdminOutOfHours: updatedAllowAdminOutOfHours,
            excludeAdminFromLimit: updatedExcludeAdminFromLimit,
            workingHours: updatedWorkingHours,
            bankHolidayWorkingHours: updatedBankHolidayWorkingHours,
        });
        setIsEditing(false);
    };

    const handleEdit = () => {
        ChainAnalyticsTracker.trackPatientTriageManagementSettingWorkingHours({
            ...baseAnalyticsProps,
            objectName: PatientTriageSettingObjectName.EDIT,
        });
        setIsEditing(true);
    };

    const handleCancel = () => {
        ChainAnalyticsTracker.trackPatientTriageManagementSettingWorkingHours({
            ...baseAnalyticsProps,
            objectName: PatientTriageSettingObjectName.CANCEL,
        });
        setIsEditing(false);
        // Reset state values to props values
        setUpdatedSuspendRequestsOutOfHours(suspendRequestsOutOfHours);
        setUpdatedAllowAdminOutOfHours(allowAdminOutOfHours);
        setUpdatedExcludeAdminFromLimit(excludeAdminFromLimit);
        setUpdatedWorkingHours(workingHours);
        setUpdatedBankHolidayWorkingHours(bankHolidayWorkingHours);
    };

    return (
        <ErrorSummaryProvider>
            <section>
                <Text as="h2" variant="subtitle">
                    Opening times & daily limits
                </Text>
                <Card
                    props={{ className: "mb-3" }}
                    header={
                        <Text as="h3" skinny variant="subtitle">
                            Settings
                        </Text>
                    }
                    footer={
                        <StyledFooterWrapper>
                            {
                                <>
                                    {isEditing ? (
                                        <>
                                            <Button
                                                theme="primary"
                                                onClick={onSubmit}
                                                type="button"
                                                text="Save changes"
                                            />
                                            <Button
                                                dimension="medium"
                                                theme="transparent"
                                                onClick={handleCancel}
                                                text="Cancel"
                                                type="button"
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            disabled={!isEditable}
                                            dimension="medium"
                                            theme="primary"
                                            icon={{ name: "Pencil" }}
                                            onClick={handleEdit}
                                            text="Edit"
                                            type="button"
                                            data-testid="workingHoursButtonEdit"
                                        />
                                    )}
                                </>
                            }
                        </StyledFooterWrapper>
                    }
                >
                    <div>
                        <Text>
                            Set your opening hours, daily limits, and what your
                            patients can do and see when your practice is out of
                            hours or at its limit.
                        </Text>

                        {isEditing ? (
                            <>
                                <WorkingHoursTopLevelSettings
                                    updateSuspendRequestsOutOfHours={
                                        setUpdatedSuspendRequestsOutOfHours
                                    }
                                    updateAllowAdminOutOfHours={
                                        setUpdatedAllowAdminOutOfHours
                                    }
                                    updateExcludeAdminFromLimit={
                                        setUpdatedExcludeAdminFromLimit
                                    }
                                    suspendRequestsOutOfHours={
                                        updatedSuspendRequestsOutOfHours
                                    }
                                    allowAdminOutOfHours={
                                        updatedAllowAdminOutOfHours
                                    }
                                    excludeAdminFromLimit={
                                        updatedExcludeAdminFromLimit
                                    }
                                />
                                <WorkingHoursTable
                                    workingHours={updatedWorkingHours}
                                    bankHolidayWorkingHours={
                                        updatedBankHolidayWorkingHours
                                    }
                                    updateWorkDay={handleUpdateWorkDay}
                                    updatebankHolidayWorkingHours={
                                        setUpdatedBankHolidayWorkingHours
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <StaticWorkingHoursTopLevelSettings
                                    suspendRequestsOutOfHours={
                                        suspendRequestsOutOfHours
                                    }
                                    allowAdminOutOfHours={allowAdminOutOfHours}
                                    excludeAdminFromLimit={
                                        excludeAdminFromLimit
                                    }
                                />
                                <StaticWorkingHoursTable
                                    workingHours={workingHours}
                                    bankHolidayWorkingHours={
                                        bankHolidayWorkingHours
                                    }
                                />
                            </>
                        )}
                    </div>
                </Card>
            </section>
        </ErrorSummaryProvider>
    );
};
