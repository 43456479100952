import { EnhancedStore, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { reducers, storeInitialState } from "./";
import {
    AllowedActionFields,
    configureScopeWithState,
    createActionTransformer,
    stateTransformer,
} from "./sentryEnhancers";

type ApplicationState = typeof storeInitialState;

/**
 * The list of actions that we want to send to Sentry in full. Note, any
 * strings in the actions (other than the type) will be masked. To send a
 * string unmasked (i.e. it's actual value), the field name needs to be listed
 * in the array.
 *
 * IMPORTANT - any field that might contain patient personally identifiable
 * information (PII) should NOT be listed. If you are unsure if the data is
 * PII, ask others before adding it here.
 */
const allowedActionFields: AllowedActionFields = {
    ACCOUNT_LOGGED_IN: ["accuRxUserId", "organisationName"],
    SET_PATIENT_SEARCH_ORIGIN: ["route"],
};

export let store: EnhancedStore<ApplicationState>;

export const appConfigureStore = (): EnhancedStore<ApplicationState> => {
    store = configureStore({
        reducer: reducers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        devTools: process.env.NODE_ENV !== "production",
        preloadedState: storeInitialState,
        enhancers: [
            Sentry.createReduxEnhancer({
                actionTransformer: createActionTransformer(allowedActionFields),
                stateTransformer,
                configureScopeWithState,
            }),
        ],
    });

    return store;
};
