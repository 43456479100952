import { createSelector } from "@reduxjs/toolkit";
import { useConciergeSelector } from "domains/concierge/internal/context";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";

export const useConversationGroup = ({
    id,
}: {
    id: ConversationGroup["id"];
}): ConversationGroup | null => {
    return useConciergeSelector(
        createSelector(
            (state: ConciergeState) => state.conversations.groups.items[id],
            (group) => {
                if (!group) return null;
                if (group.isFullyLoaded) return group;
                return {
                    ...group,
                    members: group.members.slice(0, group.ceiling),
                };
            },
        ),
    );
};
