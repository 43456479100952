import { useConciergeSelector } from "domains/concierge/internal/context";
import { TeamSummary, UserSummary } from "domains/concierge/types";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import values from "lodash/values";

export const useAllAssignees = (): {
    teams: TeamSummary[];
    users: UserSummary[];
} => {
    return useConciergeSelector((state) => ({
        teams: orderBy(
            filter(values(state.teams.items), "canBeAssignedTo"),
            [(t) => t.displayName.toLowerCase()],
            ["asc"],
        ),
        users: orderBy(
            filter(values(state.users.items), "canBeAssignedTo"),
            [(t) => t.displayName.toLowerCase()],
            ["asc"],
        ),
    }));
};
