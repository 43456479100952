import {
    FeatureName,
    WorkspaceFeatureGuard,
    useCurrentUser,
} from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import NotFound from "NotFound";
import { Route, Switch, useParams } from "react-router";

import { Inbox } from "./Inbox";
import { Reload } from "./components";
import { InboxContextProvider } from "./components/InboxContext/InboxContextProvider";
import { OnlineUsersContextProvider } from "./components/OnlineUsersContext/OnlineUsersContextProvider";
import { NativeProvider } from "./native/provider";

type InboxNavigationComponent = {
    navigationMenuComponent?: React.ReactNode;
};

const InboxWithProviders = ({
    navigationMenuComponent,
}: InboxNavigationComponent) => {
    return (
        <NativeProvider>
            <OnlineUsersContextProvider>
                <InboxContextProvider>
                    <Inbox navigationMenuComponent={navigationMenuComponent} />
                </InboxContextProvider>
            </OnlineUsersContextProvider>
        </NativeProvider>
    );
};

/**
 * Renders simple unified inbox routes
 * without any guarding
 */
const SimpleInbox = ({ navigationMenuComponent }: InboxNavigationComponent) => {
    return (
        <InboxWithProviders navigationMenuComponent={navigationMenuComponent} />
    );
};

/**
 * Inbox routes guarded behind
 * workspace not being found or
 * feature flag missing
 */
const GuardedInbox = ({
    navigationMenuComponent,
}: InboxNavigationComponent) => {
    const { user } = useCurrentUser();
    const { workspaceId: searchParam } = useParams<{
        workspaceId?: string;
    }>();

    const workspaceId = searchParam ? parseInt(searchParam, 10) : null;

    const workspace =
        user.organisations.find((org) => org.orgId === workspaceId) ?? null;

    if (workspace === null) {
        return <NotFound />;
    }

    return (
        <WorkspaceFeatureGuard
            all={[FeatureName.AccurxWebEmbeddedUnifiedInbox]}
        >
            <InboxWithProviders
                navigationMenuComponent={navigationMenuComponent}
            />
        </WorkspaceFeatureGuard>
    );
};

export const App = ({ navigationMenuComponent }: InboxNavigationComponent) => {
    const isWebView = useBrowserEnvironment() === "WebView";

    return (
        <Switch>
            <Route path="/inbox/w/:workspaceId">
                {isWebView ? (
                    <SimpleInbox
                        navigationMenuComponent={navigationMenuComponent}
                    />
                ) : (
                    <GuardedInbox
                        navigationMenuComponent={navigationMenuComponent}
                    />
                )}
            </Route>
            <Route path="/inbox">
                <NotFound />
            </Route>
            <Route path="*" component={Reload} />
        </Switch>
    );
};
