import { Button } from "@accurx/design";
import styled from "styled-components";

const StyledButton = styled(Button)`
    display: block;
    width: 100%;
`;

const StyledImg = styled.img`
    width: 5rem;
    height: auto;
`;

export const StyledConfirmEmailVerify = {
    Button: StyledButton,
    Img: StyledImg,
};
