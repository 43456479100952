import { StyledSpinner } from "./LoadingSpinner.styles";
import { ReactComponent as SVG } from "./LoadingSpinner.svg";

export const LoadingSpinner = () => {
    return (
        <StyledSpinner>
            <SVG />
        </StyledSpinner>
    );
};
