import { ActionCard, ButtonLink, Thread } from "@accurx/design";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { getFloreyResponsePDFDownloadUrl } from "shared/concierge/conversations/tickets/TicketApiClient";
import { parseUniqueNoteId } from "shared/concierge/conversations/tickets/mappers/ConversationMapper";
import { FloreyResponseConversationItem } from "shared/concierge/conversations/types/item.types";

import { renderPatientResponseSections } from "../../ConversationThread.helper";
import { BaseConversationThreadItem } from "../threadItem.types";
import { StyledActionCardWrapper } from "./FloreyResponseNoteThreadItem.styles";

export type FloreyResponseNoteThreadItemProps =
    BaseConversationThreadItem<FloreyResponseConversationItem> & {
        organisationId: number;
        patientId: string | undefined | null;
    };

const NO_RESPONSE_TEXT = "No response from patient";

export const StyledMarkdown = styled(ReactMarkdown)`
    white-space: normal;
    p:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;

export const FloreyResponseNoteThreadItem = ({
    item,
    organisationId,
    patientId,
    createdByDisplayName,
    handleOnItemRead,
}: FloreyResponseNoteThreadItemProps) => {
    const renderRequestSections = (): JSX.Element[] => {
        return renderPatientResponseSections({
            item,
            organisationId,
            patientId,
        });
    };

    const floreyResponseId = parseUniqueNoteId(item.id);

    return (
        <Thread.Message
            inboxType="received"
            dateCreated={formatConversationItemDateCreatedShort(item.createdAt)}
            senderFullName={{
                name: createdByDisplayName
                    ? {
                          fullName: createdByDisplayName,
                          shouldDisplayFullName: true,
                      }
                    : null,
            }}
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
            actionButtons={
                floreyResponseId
                    ? [
                          {
                              id: "download-florey-as-pdf",
                              customElement: (
                                  <ButtonLink
                                      href={getFloreyResponsePDFDownloadUrl(
                                          organisationId,
                                          floreyResponseId,
                                      )}
                                      icon={{ name: "Save" }}
                                      theme="secondary"
                                      dimension="medium"
                                      text="Download as PDF"
                                      download={`${item.floreyName} ${floreyResponseId}`}
                                  />
                              ),
                          },
                      ]
                    : undefined
            }
        >
            <StyledActionCardWrapper>
                <ActionCard>
                    <ActionCard.Header
                        iconName="Message"
                        contentType={item.floreyName || "Florey response"}
                    />
                    <ActionCard.Body colour={"blue"}>
                        {item.patientThreadMarkdown ? (
                            <StyledMarkdown>
                                {item.patientThreadMarkdown}
                            </StyledMarkdown>
                        ) : (
                            renderRequestSections()
                        )}
                    </ActionCard.Body>
                </ActionCard>
            </StyledActionCardWrapper>
        </Thread.Message>
    );
};

export const floreyResponseThreadItemSummaryText = (
    item: FloreyResponseConversationItem,
): string => {
    // concat response answer text, deliberately fall back on empty string response
    return (
        item.sections
            .map((s) =>
                s.responses
                    .map((r) => r.answer?.replace(/\s+/, " "))
                    .join(", "),
            )
            .join(", ") || NO_RESPONSE_TEXT
    );
};
