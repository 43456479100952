import React, { useEffect, useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import * as UI from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { ChooseOrganisation } from "domains/self-book/components/ChooseOrganisation/ChooseOrganisation";
import { useSlotTypeForTargetOrganisationQuery } from "domains/self-book/queries/useSlotTypeForTargetOrganisation";

import {
    AppOrigin,
    useSelfbookConfigurationTrackingFields,
} from "../hooks/useSelfbookAnalytics";
import { useAppointmentAvailabilityQuery } from "../queries/useAppointmentAvailability";
import {
    getClinicianOptions,
    getSelfbookConfigObject,
    isCrossOrgLoading,
    shouldShowChooseClinicianError,
    shouldShowSlotTypeError,
} from "../utils/SelfbookFormComponentUtils";
import {
    AppointmentTypeValue,
    ChooseClinicianValue,
    ClinicianOption,
    OrganisationOption,
    SelfBookFormData,
    SelfbookConfigurationPayload,
} from "../utils/types";
import { AppointmentType } from "./AppointmentType/AppointmentType";
import { ChooseClinician } from "./ChooseClinician/ChooseClinician";
import {
    SpinnerPositioning,
    StyledButton,
    StyledFooter,
    StyledInnerWrapper,
    StyledLinkedText,
    StyledMiddleWrapper,
    StyledOuterWrapper,
    StyledSelfbookForm,
    TelephoneDetailsWrapper,
} from "./SelfbookConfigurationForm.styles";
import { SlotType } from "./SlotType/SlotType";
import { TelephoneBookingDetails } from "./TelephoneBookingDetails/TelephoneBookingDetails";

type SelfbookFormProps = {
    orgId: number;
    organisationName: string;
    showCrossOrg: boolean;
    appOrigin: AppOrigin;
    header?: JSX.Element;
    isTelephoneFlow?: boolean;
    marginBottom?: number | string;
    onSupportLinkClick?: (supportLinkUri: string) => void;
    onSelfbookConfigComplete: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
    onSelfbookConfigChange?: (
        selfbookConfigData: SelfbookConfigurationPayload,
    ) => void;
};

export const SelfbookConfigurationForm = ({
    orgId,
    organisationName,
    showCrossOrg,
    appOrigin,
    header,
    isTelephoneFlow,
    marginBottom,
    onSupportLinkClick,
    onSelfbookConfigComplete,
    onSelfbookConfigChange,
}: SelfbookFormProps) => {
    const track = useAnalytics();
    const SelfbookConfigurationTrackingFields =
        useSelfbookConfigurationTrackingFields(appOrigin); // needs to be updated based on from where the form is called

    const [selfBookData, setSelfbookData] = useState<SelfBookFormData>({
        appointmentType: "FaceToFace",
        clinicians: { clinicians: [], clinicianType: "AnyClinician" },
        organisation: {
            label: `${organisationName} (Default)`,
            value: `${orgId}`,
            isDefault: true,
        },
    });
    const [hasButtonClicked, setHasButtonClicked] = useState<boolean>(false);

    const {
        data: appointmentAvailabilityData,
        status: appointmentAvailabilityStatus,
        error: appointmentAvailabilityError,
    } = useAppointmentAvailabilityQuery(orgId, organisationName);
    const {
        data: slotTypeOptionsForTargetOrganisation,
        status: crossOrgStatus,
    } = useSlotTypeForTargetOrganisationQuery(
        orgId,
        selfBookData.organisation.isDefault,
        selfBookData.organisation.value,
        selfBookData.appointmentType,
    );

    // Effect triggered on status changes and data changes
    useEffect(() => {
        const selfbookConfigObject: SelfbookConfigurationPayload =
            getSelfbookConfigObject(
                selfBookData,
                appointmentAvailabilityStatus,
                crossOrgStatus,
                showCrossOrg,
            );

        const appointmentLoading = appointmentAvailabilityStatus === "loading";
        const crossOrgLoading = isCrossOrgLoading(
            crossOrgStatus,
            selfBookData.organisation.isDefault,
        );
        const pageLoading = appointmentLoading || crossOrgLoading;
        if (!pageLoading) {
            onSelfbookConfigChange &&
                onSelfbookConfigChange(selfbookConfigObject);
        }
    }, [
        selfBookData,
        appointmentAvailabilityStatus,
        crossOrgStatus,
        showCrossOrg,
        onSelfbookConfigChange,
    ]);

    const crossOrgLoading = isCrossOrgLoading(
        crossOrgStatus,
        selfBookData.organisation.isDefault,
    );
    if (appointmentAvailabilityStatus === "loading" || crossOrgLoading) {
        return (
            <SpinnerPositioning>
                <UI.Spinner />
            </SpinnerPositioning>
        );
    }

    if (appointmentAvailabilityStatus === "error") {
        return (
            <UI.Feedback
                title="An error occurred while trying to get self book availability"
                colour="error"
            >
                {appointmentAvailabilityError.message}
            </UI.Feedback>
        );
    }

    const {
        slotTypeOptions: defaultOrgSlotTypeOptions,
        clinicianListForSlotTypes,
        weeksAvailability,
    } = appointmentAvailabilityData;

    const slotTypeOptions = selfBookData.organisation.isDefault
        ? defaultOrgSlotTypeOptions
        : slotTypeOptionsForTargetOrganisation ?? [];

    const appointmentTypeErrors =
        crossOrgStatus === "error"
            ? [
                  "Appointment type is not available. Choose another option or select a different practice.",
              ]
            : undefined;

    const onAppointmentTypeChanged = (selectedType: AppointmentTypeValue) => {
        track("BookingAppointmentType Option Select", {
            ...SelfbookConfigurationTrackingFields,
            appointmentType: selectedType,
        });
        setHasButtonClicked(false);
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            appointmentType: selectedType,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
        };
        setSelfbookData(updatedSelfbookData);
    };
    const onSlotTypeChanged = (selected: UI.Option) => {
        const slotTypeError = shouldShowSlotTypeError(selected, false);

        track("BookingSlotType MenuItem Click", {
            ...SelfbookConfigurationTrackingFields,
            slotName: selected.label ?? selected.value,
            errorReason: slotTypeError,
            hasError: !!slotTypeError,
        });

        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            slotType: selected,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
        };

        setSelfbookData(updatedSelfbookData);
    };

    const onSelfbookFormConfigButtonClick = () => {
        // to trigger validation on button click
        setHasButtonClicked(true);
        const selfbookConfigObject = getSelfbookConfigObject(
            selfBookData,
            appointmentAvailabilityStatus,
            crossOrgStatus,
            showCrossOrg,
        );

        selfbookConfigObject.validationSuccess &&
            onSelfbookConfigComplete(selfbookConfigObject);
    };

    const onOrganisationChange = (selected: OrganisationOption) => {
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            organisation: selected,
            slotType: undefined,
            clinicians: {
                clinicianType: "AnyClinician",
                clinicians: [],
            },
        };
        setHasButtonClicked(false);
        setSelfbookData(updatedSelfbookData);
    };

    const onClinicianChange = (
        selected: ClinicianOption[] | undefined,
        chooseClinicianType: ChooseClinicianValue,
    ) => {
        const clinicianObject = {
            clinicians: selected ?? [],
            clinicianType: chooseClinicianType,
        };
        const updatedSelfbookData: SelfBookFormData = {
            ...selfBookData,
            clinicians: clinicianObject,
        };
        setHasButtonClicked(false);
        setSelfbookData(updatedSelfbookData);
    };

    const renderConfigForm = (): JSX.Element => {
        return (
            <>
                {showCrossOrg && (
                    <ChooseOrganisation
                        onChange={(selected) => {
                            onOrganisationChange(selected);
                        }}
                        options={
                            appointmentAvailabilityData.organisationOptions
                        }
                        initialOption={selfBookData.organisation}
                        errors={undefined}
                    />
                )}
                <AppointmentType
                    selectedAppointmentType={selfBookData.appointmentType}
                    onChange={(event) =>
                        onAppointmentTypeChanged(
                            event.target.value as AppointmentTypeValue,
                        )
                    }
                    errors={appointmentTypeErrors}
                />

                <SlotType
                    onChange={(selected) => onSlotTypeChanged(selected)}
                    initialOption={selfBookData.slotType}
                    options={slotTypeOptions}
                    weeksAvailable={weeksAvailability}
                    errors={shouldShowSlotTypeError(
                        selfBookData.slotType,
                        hasButtonClicked,
                    )}
                />
                {selfBookData.organisation.isDefault === true && (
                    <ChooseClinician
                        onChange={(selected, chooseClinicianType) =>
                            onClinicianChange(selected, chooseClinicianType)
                        }
                        initialOption={selfBookData.clinicians}
                        options={getClinicianOptions(
                            clinicianListForSlotTypes,
                            selfBookData.slotType,
                        )}
                        errors={shouldShowChooseClinicianError(
                            selfBookData.clinicians,
                            hasButtonClicked,
                        )}
                        isSlotTypeAvailabilityError={
                            selfBookData.slotType &&
                            selfBookData.slotType.value === "0"
                        }
                    />
                )}
            </>
        );
    };

    return (
        <StyledOuterWrapper>
            <StyledMiddleWrapper>
                <StyledInnerWrapper>
                    <StyledSelfbookForm>
                        {header}
                        {isTelephoneFlow ? (
                            <TelephoneDetailsWrapper>
                                <TelephoneBookingDetails />
                            </TelephoneDetailsWrapper>
                        ) : (
                            renderConfigForm()
                        )}
                        <UI.Link
                            href={SupportUrls.BatchSelfBookProblem}
                            openInNewTab
                            onClick={() => {
                                track("BookingResource Link Click", {
                                    ...SelfbookConfigurationTrackingFields,
                                    resourceLink:
                                        SupportUrls.BatchSelfBookProblem,
                                });
                                onSupportLinkClick &&
                                    onSupportLinkClick(
                                        SupportUrls.BatchSelfBookProblem,
                                    );
                            }}
                        >
                            <StyledLinkedText>
                                <UI.Link.Text text="Having problems?" />
                            </StyledLinkedText>
                            <UI.Link.Icon />
                        </UI.Link>
                    </StyledSelfbookForm>
                    <StyledFooter>
                        <StyledButton
                            theme="primary"
                            dimension="medium"
                            text={
                                selfBookData.appointmentType === "FaceToFace" ||
                                isTelephoneFlow
                                    ? "Add Self-Book invite"
                                    : "Save and next"
                            }
                            onClick={() => onSelfbookFormConfigButtonClick()}
                            marginBottom={marginBottom}
                        />
                    </StyledFooter>
                </StyledInnerWrapper>
            </StyledMiddleWrapper>
        </StyledOuterWrapper>
    );
};
