import { StyledFooterLink } from "../TwoFactor.styles";
import { TwoFactorVerifySubmitStepProps } from "../TwoFactorModels";
import { ConfirmCodeCard } from "../shared/ConfirmCodeCard";
import { BackButton } from "../shared/FooterButtons";
import { NeedAssistance } from "../shared/NeedAssistance";

export type TwoFactorConfirmationCodeProps = {
    error: string;
    requestLoading: boolean;
} & TwoFactorVerifySubmitStepProps;

export const TwoFactorConfirmationCode = ({
    prevStep,
    nextStep,
    requestLoading,
    error,
}: TwoFactorConfirmationCodeProps): JSX.Element => {
    const handleSendConfirmation = (
        accessCode: string,
        remember: boolean,
    ): void => {
        nextStep && nextStep(accessCode, remember);
    };

    const handleGoBack = (): void => {
        prevStep && prevStep();
    };

    return (
        <>
            <ConfirmCodeCard
                onSubmit={handleSendConfirmation}
                isLoading={requestLoading}
                error={error}
                text={{ submitButtonText: "Confirm" }}
                showRememberMe
                cancelButton={<BackButton onClick={handleGoBack} />}
            />
            <StyledFooterLink>
                <NeedAssistance />
            </StyledFooterLink>
        </>
    );
};
