import { useAuth } from "@accurx/auth";

import {
    isPharmacy,
    isUserCreatedWorkspace,
} from "../../util/workspaceHelpers";

/**
 * This hook determines whether to show certain UI elements that reflect the
 * user's pending approval status within the current workspace. Currently, we
 * only want to show this to limited markets.
 */
export const usePendingApproval = (
    workspaceId?: number | null,
): { isPendingApproval: boolean } => {
    const { user } = useAuth();

    if (!user.isLoggedIn) {
        return { isPendingApproval: false };
    }

    const currentWorkspace = user.organisations.find(
        (o) => o.orgId === workspaceId,
    );

    if (!currentWorkspace) {
        return { isPendingApproval: false };
    }

    // Only show pending features in pharmacy and user-created trust workspaces
    // because some GPs might not have CollaborativeInbox or UserManagement yet.
    return {
        isPendingApproval:
            !currentWorkspace.settings.isApprovedUser &&
            (isPharmacy(currentWorkspace) ||
                isUserCreatedWorkspace(currentWorkspace)),
    };
};
