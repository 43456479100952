import { isConversationItemUnreadForUser } from "domains/concierge/internal/util/isConversationItemUnread";
import { Conversation, ConversationItem } from "domains/concierge/types";

export const getAllUnreadConversationItems = (
    conversation: Conversation,
    userId: string,
): ConversationItem[] => {
    return conversation.items.filter((item) =>
        isConversationItemUnreadForUser(item, userId),
    );
};
