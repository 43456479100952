import { Grid, Tokens } from "@accurx/design";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const PageContainerForStickyFooter = styled.div`
    min-height: 100%; // sticky footers means content overlap
    padding-bottom: 23rem;
`;

export const StyledIndent = styled.div`
    border-left: ${Tokens.SIZES[0.5]} solid
        ${Tokens.COLOURS.primary.green["130"]};
    padding-left: ${Tokens.SIZES[2]};
`;

export const StyledGreyIndent = styled.div`
    border-left: ${Tokens.SIZES[0.5]} solid ${Tokens.COLOURS.greyscale.silver};
    padding-left: ${Tokens.SIZES[2]};
    margin: ${Tokens.SIZES[1.5]} 0;
`;

export const StyledMarkdown = styled(ReactMarkdown)`
    margin-top: ${Tokens.SIZES[0.5]};
    margin-bottom: ${Tokens.SIZES[1.5]};
`;

export const StyledGreyBoxWithIndent = styled.div`
    border-left: ${Tokens.SIZES[0.5]} solid ${Tokens.COLOURS.greyscale.silver};
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[2]};
`;

export const StyledGreyBox = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledSvgWrapper = styled.div`
    & svg {
        max-width: 100%;
    }
`;

export const StyledSpaced = styled.div`
    padding-top: ${Tokens.SIZES[1]};
`;

export const StyledQuestionIndent = styled(Grid)`
    padding-left: ${Tokens.SIZES[6]};
`;
