/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, {
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    useEffect,
    useState,
} from "react";

import {
    SearchForPatientByDemographicsRequest,
    SearchForPatientByNhsNumberRequest,
    SearchForPatientResponse,
} from "@accurx/api/portal";
import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { DateHelpers, NhsNumberHelpers } from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { sendInviteForSinglePatient } from "../../vaccine/vaccineInvitesOldPage/vaccineDelivery.actions";
import { getVaccineCourseSearchTerm } from "../utils";

const GENDER_OPTIONS = ["Female", "Male", "Not Known", "Not Specified"];

export const VaccineDeliverySinglePatient = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const { sendingStatus, vaccineCourse } = useAppSelector(
        ({ vaccineDelivery }) => ({
            sendingStatus: vaccineDelivery.sendingStatus,
            vaccineCourse: vaccineDelivery.vaccineCourse,
        }),
        shallowEqual,
    );

    // NHS input group state
    const [nhsNumber, setNhsNumber] = useState("");
    const [nhsDobDay, setNhsDobDay] = useState("");
    const [nhsDobMonth, setNhsDobMonth] = useState("");
    const [nhsDobYear, setNhsDobYear] = useState("");
    const [nhsIsSearching, setNhsIsSearching] = useState(false);

    // Nhs input group Field validation
    const [nhsNumberValid, setNhsNumberValid] = useState(true);
    const [nhsNumberErrorMessage, setNhsNumberErrorMessage] = useState("");
    const [nhsDobValid, setNhsDobValid] = useState(true);
    const [nhsDobErrorMessage, setNhsDobErrorMessage] = useState("");

    // Demographics input group state
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [postcode, setPostcode] = useState("");
    const [gender, setGender] = useState("-");
    const [demoDobDay, setDemoDobDay] = useState("");
    const [demoDobMonth, setDemoDobMonth] = useState("");
    const [demoDobYear, setDemoDobYear] = useState("");
    const [demoIsSearching, setDemoIsSearching] = useState(false);

    // Nhs input group Field validation
    const [firstNameValid, setFirstNameValid] = useState<boolean>(true);
    const [firstNameErrorMessage, setFirstNameErrorMessage] =
        useState<string>("");
    const [lastNameValid, setLastNameValid] = useState<boolean>(true);
    const [lastNameErrorMessage, setLastNameErrorMessage] =
        useState<string>("");
    const [postcodeValid, setPostcodeValid] = useState<boolean>(true);
    const [postcodeErrorMessage, setPostcodeErrorMessage] =
        useState<string>("");
    const [genderValid, setGenderValid] = useState<boolean>(true);
    const [genderErrorMessage, setGenderErrorMessage] = useState<string>("");
    const [demoDobValid, setDemoDobValid] = useState<boolean>(true);
    const [demoDobErrorMessage, setDemoDobErrorMessage] = useState<string>("");

    // Form state
    const [isNhsSearch, setIsNhsSearch] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [matchResult, setMatchResult] =
        useState<SearchForPatientResponse | null>(null);
    const [shouldSendSms, setShouldSendSms] = useState(true);

    const [creatingInvite, setCreatingInvite] = useState(false);
    const [creatingInviteError, setCreatingInviteError] = useState("");

    useEffect(() => {
        if (sendingStatus === UpdatingStatus.Loaded) {
            setCreatingInvite(false);
            history.push(
                ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                    ":orgId",
                    practiceId,
                ),
            );
        }
        if (sendingStatus === UpdatingStatus.Failed) {
            setCreatingInvite(false);
            setCreatingInviteError(
                "Something went wrong trying to create an invite for this patient. Please try again",
            );
        }
    }, [sendingStatus]);

    const getError = (isNhsGroup: boolean): string => {
        if (!matchResult) return "";

        const hasSearched =
            (isNhsGroup && !nhsIsSearching && nhsNumber) ||
            (!isNhsGroup && !demoIsSearching && firstName);
        const hasSearchFinished = hasSubmitted && hasSearched;
        if (!hasSearchFinished) return "";

        const isSearchedPatientNoMatch =
            matchResult?.searched &&
            matchResult.searchedResult?.matchFound === false;
        if (isSearchedPatientNoMatch) {
            return "Sorry, we haven't found a patient matching these details.";
        }

        // Check other server errors other than no match
        if (matchResult?.searchedResult === null) {
            return "Sorry, something went wrong whilst searching for this patient.";
        }

        if (matchResult.searchedResult?.patient?.deceased) {
            return "Sorry, this patient is deceased.";
        }
        return "";
    };

    const handleNhsNumberChange = (e: ChangeEvent<HTMLInputElement>): void =>
        sanitiseInputStringAndSetState(e.target.value);

    const handleNhsNumberPaste = (e: ClipboardEvent): void => {
        e.preventDefault();
        const text = e.clipboardData?.getData("Text");
        sanitiseInputStringAndSetState(text);
    };

    const sanitiseInputStringAndSetState = (inputString: string): void => {
        const cleanedString = inputString.replace(/[^0-9]/g, "");
        setNhsNumber(cleanedString);
        setNhsNumberErrorMessage("");
        setHasSubmitted(false);
    };

    const handleDobDDChange = (isNhsGroup: boolean) =>
        handleDobChange(isNhsGroup, 2, setNhsDobDay, setDemoDobDay);
    const handleDobMMChange = (isNhsGroup: boolean) =>
        handleDobChange(isNhsGroup, 2, setNhsDobMonth, setDemoDobMonth);
    const handleDobYYChange = (isNhsGroup: boolean) =>
        handleDobChange(isNhsGroup, 4, setNhsDobYear, setDemoDobYear);
    const handleDobChange =
        (
            isNhsGroup: boolean,
            maxLength: number,
            setNhsInput: (input: string) => void,
            setDemoInput: (input: string) => void,
        ) =>
        (e: ChangeEvent<HTMLInputElement>): void => {
            if (!canSetDate(e.target.value, maxLength)) return;
            if (isNhsGroup) {
                setNhsInput(e.target.value);
                setNhsDobErrorMessage("");
            } else {
                setDemoInput(e.target.value);
                setDemoDobErrorMessage("");
            }
            setHasSubmitted(false);
        };

    // Since the date inputs are of type number, we must check the max length from that handleChange functions and simulate maxlength behaviour of input type text
    const canSetDate = (value: string, maxLength: number): boolean => {
        return value.length <= maxLength;
    };

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFirstName(e.target.value);
        setFirstNameErrorMessage("");
        setHasSubmitted(false);
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setLastName(e.target.value);
        setLastNameErrorMessage("");
        setHasSubmitted(false);
    };

    const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setPostcode(e.target.value);
        setPostcodeErrorMessage("");
        setHasSubmitted(false);
    };

    const handleGenderChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        setGender(e.target.value);
        setGenderErrorMessage("");
        setHasSubmitted(false);
    };

    const renderNhsNumberFormGroup = (): JSX.Element => {
        return (
            <>
                <Text
                    variant="label"
                    as="label"
                    props={{ htmlFor: "nhs-number" }}
                >
                    NHS number
                </Text>
                <input
                    id="nhs-number"
                    className="form-control"
                    placeholder="xxx-xxx-xxxx"
                    value={nhsNumber}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    minLength={10}
                    maxLength={10}
                    onChange={handleNhsNumberChange}
                    onPaste={handleNhsNumberPaste}
                    disabled={nhsIsSearching || isDisabled}
                />
                {!nhsNumberValid && renderFormErrors(nhsNumberErrorMessage)}
            </>
        );
    };

    const renderNameFormGroup = (): JSX.Element => {
        return (
            <>
                <div className="col-sm-6">
                    <Text
                        variant="label"
                        as="label"
                        props={{ htmlFor: "first-name" }}
                    >
                        First name
                    </Text>
                    <input
                        id="first-name"
                        className="form-control"
                        value={firstName}
                        minLength={1}
                        type="text"
                        inputMode="text"
                        onChange={handleFirstNameChange}
                        disabled={demoIsSearching || isDisabled}
                    />
                    {!firstNameValid && renderFormErrors(firstNameErrorMessage)}
                </div>
                <div className="col-sm-6">
                    <Text
                        variant="label"
                        as="label"
                        props={{ htmlFor: "last-name" }}
                    >
                        Last name
                    </Text>
                    <input
                        id="last-name"
                        className="form-control"
                        value={lastName}
                        minLength={1}
                        type="text"
                        inputMode="text"
                        onChange={handleLastNameChange}
                        disabled={demoIsSearching || isDisabled}
                    />
                    {!lastNameValid && renderFormErrors(lastNameErrorMessage)}
                </div>
            </>
        );
    };

    const renderGenderFormGroup = (): JSX.Element => {
        return (
            <>
                <Text variant="label" as="label" props={{ htmlFor: "gender" }}>
                    Gender
                </Text>
                <select
                    id="gender"
                    className="form-control"
                    value={gender}
                    onChange={handleGenderChange}
                    disabled={demoIsSearching || isDisabled}
                >
                    <option key="-1" value="-">
                        Please select
                    </option>
                    {GENDER_OPTIONS.map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
                {!genderValid && renderFormErrors(genderErrorMessage)}
            </>
        );
    };

    const renderPostcodeFormGroup = (): JSX.Element => {
        return (
            <>
                <Text
                    variant="label"
                    as="label"
                    props={{ htmlFor: "postcode" }}
                >
                    Postcode
                </Text>
                <input
                    id="postcode"
                    className="form-control"
                    value={postcode}
                    minLength={1}
                    type="text"
                    inputMode="text"
                    onChange={handlePostcodeChange}
                    disabled={demoIsSearching || isDisabled}
                />
                {!postcodeValid && renderFormErrors(postcodeErrorMessage)}
            </>
        );
    };

    const renderDateInput = (
        value: string,
        placeholder: string,
        min: number,
        max: number,
        handleChange: (e: ChangeEvent<HTMLInputElement>) => void,
    ): JSX.Element => {
        return (
            <input
                className="form-control"
                type="number"
                pattern="[0-9]*"
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                min={min}
                max={max}
                disabled={nhsIsSearching || isDisabled}
                required
            />
        );
    };

    const renderDOBFormGroup = (isNhsGroup: boolean): JSX.Element => {
        return (
            <>
                <Text variant="label" as="label">
                    Date of birth
                </Text>
                {/* Why don't we use a date input? Poor support for type="date" in Safari. See https://caniuse.com/#feat=input-datetime. */}
                {/* Unlike the above input we can use input type number here as these are incremental number fields, this allows us to use the min/max form validation */}
                <div className="d-flex">
                    {renderDateInput(
                        isNhsGroup ? nhsDobDay : demoDobDay,
                        "DD",
                        1,
                        31,
                        handleDobDDChange(isNhsGroup),
                    )}
                    {renderDateInput(
                        isNhsGroup ? nhsDobMonth : demoDobMonth,
                        "MM",
                        1,
                        12,
                        handleDobMMChange(isNhsGroup),
                    )}
                    {renderDateInput(
                        isNhsGroup ? nhsDobYear : demoDobYear,
                        "YYYY",
                        1900,
                        new Date().getFullYear(),
                        handleDobYYChange(isNhsGroup),
                    )}
                </div>
                {isNhsGroup &&
                    !nhsDobValid &&
                    renderFormErrors(nhsDobErrorMessage)}
                {!isNhsGroup &&
                    !demoDobValid &&
                    renderFormErrors(demoDobErrorMessage)}
            </>
        );
    };

    const renderSearchResult = (): JSX.Element => {
        return (
            <>
                {hasSubmitted &&
                    matchResult?.searchedResult?.patient &&
                    matchResult?.searchedResult?.patient?.deceased ===
                        false && (
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1">
                                <Card>
                                    <div className="d-flex">
                                        <span className="mr-4">
                                            <Text variant="label" as="span">
                                                {
                                                    matchResult.searchedResult
                                                        ?.patient?.displayName
                                                }
                                            </Text>
                                            <div>
                                                <Text
                                                    variant="body"
                                                    as="span"
                                                    props={{
                                                        style: {
                                                            whiteSpace:
                                                                "nowrap",
                                                            margin: "0",
                                                            marginRight: "1rem",
                                                        },
                                                        "data-testid":
                                                            "nhsColumn",
                                                    }}
                                                >
                                                    NHS:{" "}
                                                    {formatNhsNumber(
                                                        matchResult
                                                            .searchedResult
                                                            ?.patient
                                                            ?.nhsNumber as string,
                                                    )}
                                                </Text>
                                                <Text
                                                    variant="body"
                                                    as="span"
                                                    props={{
                                                        style: {
                                                            whiteSpace:
                                                                "nowrap",
                                                            margin: "0",
                                                            marginRight: "1rem",
                                                        },
                                                        "data-testid":
                                                            "dobColumn",
                                                    }}
                                                >
                                                    Born:{" "}
                                                    {
                                                        matchResult
                                                            .searchedResult
                                                            ?.patient
                                                            ?.dateOfBirth
                                                    }
                                                </Text>
                                                <Text
                                                    variant="body"
                                                    as="span"
                                                    props={{
                                                        style: {
                                                            whiteSpace:
                                                                "nowrap",
                                                            margin: "0",
                                                            marginRight: "1rem",
                                                        },
                                                        "data-testid":
                                                            "phoneColumn",
                                                    }}
                                                >
                                                    {
                                                        matchResult
                                                            .searchedResult
                                                            ?.patient
                                                            ?.mobileNumber
                                                    }
                                                </Text>
                                            </div>
                                        </span>
                                    </div>
                                </Card>
                                {matchResult?.searchedResult?.patient
                                    ?.mobileNumber && (
                                    <span className="d-flex align-items-center mt-1">
                                        <input
                                            id="send-text-on-invite"
                                            type="checkbox"
                                            defaultChecked={shouldSendSms}
                                            onChange={(e): void =>
                                                setShouldSendSms(
                                                    e.target.checked,
                                                )
                                            }
                                            style={{ margin: "0 0.5rem" }}
                                        />
                                        <Text
                                            as="label"
                                            props={{
                                                htmlFor: "send-text-on-invite",
                                            }}
                                            skinny
                                        >
                                            Send mobile invite
                                        </Text>
                                    </span>
                                )}
                                {creatingInviteError && (
                                    <Feedback
                                        props={{ className: "mt-1" }}
                                        colour="error"
                                        title={creatingInviteError}
                                        content=""
                                    />
                                )}
                                <div className="d-flex justify-content-end mt-2">
                                    <Button
                                        text="Book now"
                                        onClick={addSinglePatient}
                                        type="submit"
                                        disabled={creatingInvite}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
            </>
        );
    };

    const renderFormErrors = (
        errors: string,
        addSpacing = true,
    ): JSX.Element | null => {
        if (!errors) return null;
        return (
            <div
                className={`d-flex align-items-center${
                    addSpacing ? " mt-1" : ""
                }`}
            >
                <div style={{ lineHeight: "normal" }}>
                    <Icon colour="red" name="Error" size={3} theme="Fill" />
                </div>
                <Text skinny>{errors}</Text>
            </div>
        );
    };

    const handleValidateAndSearchByNhs = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();

        setMatchResult(null);
        setIsNhsSearch(true);
        setIsDisabled(true);
        setNhsIsSearching(true);

        const day = parseInt(nhsDobDay, 10);
        const month = parseInt(nhsDobMonth, 10);
        const year = parseInt(nhsDobYear, 10);

        const dob = DateHelpers.getDateISOStringFromDayMonthYear(
            day,
            month,
            year,
        );

        let isValid = true;
        if (DateHelpers.isAfterToday(dob)) {
            setNhsDobErrorMessage("A date of birth cannot be in the future");
            setNhsDobValid(false);
            isValid = false;
        }

        if (isDobInvalid(day, month, year)) {
            setNhsDobErrorMessage(
                "Please use a valid date, month, and year combination",
            );
            setNhsDobValid(false);
            isValid = false;
        }
        const nhsNumberValidateResult =
            NhsNumberHelpers.validateNhsNumber(nhsNumber);
        if (!nhsNumberValidateResult.success) {
            setNhsNumberErrorMessage(
                nhsNumberValidateResult.error ||
                    "Please enter a valid NHS number",
            );
            setNhsNumberValid(false);
            isValid = false;
        }

        if (!isValid) {
            setIsDisabled(false);
            setNhsIsSearching(false);
            return;
        }

        const request: SearchForPatientByNhsNumberRequest = {
            nhsNumber,
            dateOfBirthYear: parseInt(nhsDobYear, 10),
            dateOfBirthMonth: parseInt(nhsDobMonth, 10),
            dateOfBirthDay: parseInt(nhsDobDay, 10),
            organisationId: parseInt(practiceId, 10),
        };

        const result = await ChainApiClient.recallSearchForPatient(
            practiceId,
            request,
        );
        setNhsIsSearching(false);
        setIsDisabled(false);
        setHasSubmitted(true);

        if (result?.success === true) {
            const mobileNumber =
                result?.result?.searchedResult?.patient?.mobileNumber;
            setShouldSendSms(
                mobileNumber !== undefined && mobileNumber !== null,
            );
            setMatchResult(result.result);
        } else {
            setMatchResult({
                searched: false,
                searchedNhsNumber: "",
                // this looks v weird, but doing it for safety
                // as part of a large scale change to use generated types.
                // bc the generated type doesn't match what we actually treat it
                // as in code, i could either cast it as i do here, or change
                // the code to return `undefined` instead. because the latter
                // could change the _behaviour_ of the code, i've opted for
                // the former, as this change is going to include far more
                // file changes than i could reasonably test. keeping the changes
                // limited to types only mean the behaviour of running code
                // will stay the same
                searchedResult: null as unknown as undefined,
            });
        }
        const analyticsProps: ChainAnalyticsTracker.VaccineIndividualPatientLookupProps =
            {
                ...analyticsLoggedInProps,
                lookupMethod: "NHS number",
                foundPatient: !!result?.result?.searchedResult?.patient,
            };
        ChainAnalyticsTracker.trackVaccineIndividualPatientLookup(
            analyticsProps,
        );
    };

    const isDobInvalid = (day: number, month: number, year: number) =>
        year < 1900 || month < 1 || month > 12 || day < 1 || day > 31;

    const handleValidateAndSearchByDemographics = async (
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();

        setMatchResult(null);
        setIsNhsSearch(false);
        setIsDisabled(true);
        setDemoIsSearching(true);

        const day = parseInt(demoDobDay, 10);
        const month = parseInt(demoDobMonth, 10);
        const year = parseInt(demoDobYear, 10);

        const dob = DateHelpers.getDateISOStringFromDayMonthYear(
            day,
            month,
            year,
        );

        let isValid = true;
        if (DateHelpers.isAfterToday(dob)) {
            setDemoDobErrorMessage("A date of birth cannot be in the future");
            setDemoDobValid(false);
            isValid = false;
        }
        if (isDobInvalid(day, month, year)) {
            setDemoDobErrorMessage(
                "Please use a valid date, month, and year combination",
            );
            setDemoDobValid(false);
            isValid = false;
        }
        if (firstName.length < 1) {
            setFirstNameErrorMessage("Please enter a first name");
            setFirstNameValid(false);
            isValid = false;
        }
        if (lastName.length < 1) {
            setLastNameErrorMessage("Please enter a last name");
            setLastNameValid(false);
            isValid = false;
        }
        if (GENDER_OPTIONS.indexOf(gender) === -1) {
            setGenderErrorMessage("Please select a gender");
            setGenderValid(false);
            isValid = false;
        }
        if (postcode.length < 1) {
            setPostcodeErrorMessage("Please enter a postcode");
            setPostcodeValid(false);
            isValid = false;
        }

        if (!isValid) {
            setIsDisabled(false);
            setDemoIsSearching(false);
            return;
        }

        const request: SearchForPatientByDemographicsRequest = {
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            postcode: postcode,
            dateOfBirthYear: parseInt(demoDobYear, 10),
            dateOfBirthMonth: parseInt(demoDobMonth, 10),
            dateOfBirthDay: parseInt(demoDobDay, 10),
            organisationId: parseInt(practiceId, 10),
        };

        const result = await ChainApiClient.recallSearchForPatient(
            practiceId,
            request,
        );
        setDemoIsSearching(false);
        setIsDisabled(false);
        setHasSubmitted(true);

        if (result?.success === true) {
            const mobileNumber =
                result?.result?.searchedResult?.patient?.mobileNumber;
            setShouldSendSms(
                mobileNumber !== undefined && mobileNumber !== null,
            );
            setMatchResult(result.result);
        } else {
            setMatchResult({
                searched: false,
                searchedNhsNumber: "",
                // this looks v weird, but doing it for safety
                // as part of a large scale change to use generated types.
                // bc the generated type doesn't match what we actually treat it
                // as in code, i could either cast it as i do here, or change
                // the code to return `undefined` instead. because the latter
                // could change the _behaviour_ of the code, i've opted for
                // the former, as this change is going to include far more
                // file changes than i could reasonably test. keeping the changes
                // limited to types only mean the behaviour of running code
                // will stay the same
                searchedResult: null as unknown as undefined,
            });
        }
        const analyticsProps: ChainAnalyticsTracker.VaccineIndividualPatientLookupProps =
            {
                ...analyticsLoggedInProps,
                lookupMethod: "demographics",
                foundPatient: !!result?.result?.searchedResult?.patient,
            };
        ChainAnalyticsTracker.trackVaccineIndividualPatientLookup(
            analyticsProps,
        );
    };

    const addSinglePatient = (): void => {
        setCreatingInvite(true);
        setCreatingInviteError("");
        const analyticsProps: ChainAnalyticsTracker.VaccineSentMessageProps = {
            ...analyticsLoggedInProps,
            hasEditedMessage: false.toString(),
            isIndividualInvite: true,
        };
        ChainAnalyticsTracker.trackVaccineSentMessage(analyticsProps);
        dispatch(
            sendInviteForSinglePatient(
                practiceId,
                matchResult!.searchedResult!.patientToken!,
                shouldSendSms,
                getVaccineCourseSearchTerm(vaccineCourse),
            ),
        );
    };

    return (
        <div>
            <div className="row mb-3">
                <div className="col-12 text-center">
                    <Text as="h1" variant="title">
                        Add individual patient
                    </Text>
                </div>
                <div className="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 text-center">
                    <Text>
                        Find and add a patient to send them an SMS invitation or
                        manually book their vaccinations.
                    </Text>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-sm-10 offset-sm-1">
                    <Text variant="subtitle">
                        Either search for a patient based on NHS number and date
                        of birth:
                    </Text>
                    <form
                        onSubmit={handleValidateAndSearchByNhs}
                        data-testid="searchForPatientByNhsNumberForm"
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                {renderNhsNumberFormGroup()}
                            </div>
                            <div className="col-sm-6">
                                {renderDOBFormGroup(true)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {isNhsSearch &&
                                    renderFormErrors(getError(true), false)}
                            </div>
                            <div className="col-12 d-flex justify-content-end mt-2">
                                <Button
                                    text={
                                        nhsIsSearching
                                            ? "Searching..."
                                            : "Find and add"
                                    }
                                    type="submit"
                                    disabled={nhsIsSearching || isDisabled}
                                    icon={{ name: "Search" }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {isNhsSearch && renderSearchResult()}
            <div className="row mb-3">
                <div className="col-12 col-sm-10 offset-sm-1">
                    <Text variant="subtitle">
                        Or based on patient demographics:
                    </Text>
                    <form
                        onSubmit={handleValidateAndSearchByDemographics}
                        data-testid="searchForPatientByDemographicsForm"
                    >
                        <div className="row">{renderNameFormGroup()}</div>
                        <div className="row">
                            <div className="col-sm-6">
                                {renderDOBFormGroup(false)}
                            </div>
                            <div className="col-sm-3">
                                {renderGenderFormGroup()}
                            </div>
                            <div className="col-sm-3">
                                {renderPostcodeFormGroup()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {!isNhsSearch &&
                                    renderFormErrors(getError(false), false)}
                            </div>
                            <div className="col-12 d-flex justify-content-end mt-2">
                                <Button
                                    text={
                                        demoIsSearching
                                            ? "Searching..."
                                            : "Find and add"
                                    }
                                    type="submit"
                                    disabled={demoIsSearching || isDisabled}
                                    icon={{ name: "Search" }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {!isNhsSearch && renderSearchResult()}
        </div>
    );
};

export default VaccineDeliverySinglePatient;
