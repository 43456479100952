import * as UI from "@accurx/design";
import styled from "styled-components";

const { COLOURS } = UI.Tokens;

export const StyledPopoverContent = styled(UI.PopoverContent)`
    &[data-state="open"] {
        border: 1px solid ${COLOURS.greyscale.silver};
    }
`;
