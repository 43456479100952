import { FormEvent, useEffect, useState } from "react";

import {
    Button,
    Card,
    Feedback,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { useDispatch } from "react-redux";

import { getUserSignature } from "api/SettingsApi";
import { actionCreators } from "app/account/AccountActions";
import { useUpdateMessageSignatureMutation } from "app/hooks/mutations";

import { FeedbackMsg } from "./Settings.types";

type SettingsSignatureProps = {
    hideHeader?: boolean;
};

export const SettingsSignature = ({ hideHeader }: SettingsSignatureProps) => {
    useAccurxWebTitle("Manage message signature");

    const updateMessageSignatureMutation = useUpdateMessageSignatureMutation();
    const dispatch = useDispatch();
    const [signature, setSignature] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState<FeedbackMsg>({
        colour: null,
        msg: "",
    });

    useEffect(() => {
        (async function loadData(): Promise<void> {
            const res = await getUserSignature();
            if (!res.success || !res.result) {
                setFeedbackMsg({
                    colour: "error",
                    msg:
                        res.error ||
                        "Something went wrong fetching your message signature. Please refresh the page.",
                });
                return;
            }
            setSignature(res.result.signature || "");
        })();
    }, []);

    const handleSave = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setFeedbackMsg({
            colour: null,
            msg: "",
        });

        if (signature?.length > 40) {
            setFeedbackMsg({
                colour: "error",
                msg: "The signature must be under 40 characters long.",
            });
            return;
        }

        updateMessageSignatureMutation.mutate(
            { signature },
            {
                onSuccess: (result) => {
                    setFeedbackMsg({
                        colour: "success",
                        msg: "Your signature has been set.",
                    });

                    dispatch(
                        actionCreators.updateMessageSignature(
                            result?.signature || "",
                        ),
                    );
                },
                onError: () => {
                    setFeedbackMsg({
                        colour: "error",
                        msg: "Something went wrong saving your signature. Please try again.",
                    });
                },
            },
        );
    };

    return (
        <Card
            header={
                !hideHeader ? (
                    <Text variant="subtitle" as="h2" skinny>
                        Change your message signature
                    </Text>
                ) : undefined
            }
        >
            <form onSubmit={handleSave}>
                <Text>
                    This is the signature automatically added to your message
                    when you use a template. Your organisation name will be
                    added after this. For example, "Regards, Dr Joe Bloggs" or
                    "Thanks, The Reception Team". There is a limit of 40
                    characters.
                </Text>
                <FormFieldWrapper
                    label="Signature"
                    labelProps={{ htmlFor: "user-signature" }}
                    className="mb-3"
                >
                    <Input
                        id="user-signature"
                        value={signature}
                        type="text"
                        onChange={(e): void => setSignature(e.target.value)}
                        maxLength={40}
                    />
                </FormFieldWrapper>
                {feedbackMsg.colour && feedbackMsg.msg && (
                    <Feedback
                        title={feedbackMsg.msg}
                        colour={feedbackMsg.colour}
                        props={{ className: "mb-3" }}
                    />
                )}
                <Button type="submit" text="Set signature" />
            </form>
        </Card>
    );
};
