import { EditableMessage } from "./components/EditableMessage/EditableMessage";
import { MessageFooter } from "./components/MessageFooter/MessageFooter";
import { TextAreaWrapper } from "./components/TextAreaWrapper/TextAreaWrapper";
import { UneditableText } from "./components/UneditableText/UneditableText";

const TextArea = {
    Container: TextAreaWrapper,
    Footer: MessageFooter,
    EditableMessage: EditableMessage,
    UneditableText: UneditableText,
};

export { TextArea };
