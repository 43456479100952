import { calculateFragments } from "@accurx/shared";

import { MAX_SMS_CHAR_LIMIT } from "../constants";

/**
 * !! Caution: This does not accurately determine the number of characters
 * as it does not take in to account special characters
 */
export function getCharacterCount(message: string) {
    return message.length;
}

/**
 * !! Caution: This does not accurately determine the number of characters
 * as it does not take in to account special characters
 * @example 276 / 306 (2 fragments)
 * @returns Formatted string of character and fragment count
 */
export function getCharacterAndFragmentCountInfo(message: string) {
    const characterCount = getCharacterCount(message);
    const fragmentCount = calculateFragments(message);
    const fragments = `${fragmentCount} ${
        fragmentCount === 1 ? "fragment" : "fragments"
    }`;

    return `${characterCount} / ${MAX_SMS_CHAR_LIMIT}\n(${fragments})`;
}
