import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

export interface PowerBIReportResponse {
    id: string;
    embedUrl: string;
    token: string;
    pages: { [key: string]: string };
}

export const getPowerBIReport = ({
    organisationId,
    reportName,
}: {
    organisationId: string;
    reportName?: string;
}): Promise<IWrappedResult<PowerBIReportResponse>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: reportName
                ? `/api/portal/PowerBIReport/${organisationId}/PowerBIReport/${reportName}`
                : `/api/portal/PowerBIReport/${organisationId}/PowerBIReport`,
        }),
    );
