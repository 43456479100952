import { useState } from "react";

import { FeatureName } from "@accurx/auth";
import { Feedback, Text } from "@accurx/design";
import { Log, SupportUrls } from "@accurx/shared";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { UncontrolledTooltip } from "reactstrap";
import { useMeaningfulActionAnalyticsProps } from "reduxQuarantine/useMeaningfulActionAnalyticsProps";

import FlemingApi from "api/FlemingApiClient";
import {
    IFileUploadRequest,
    PatientListBulkAddPatientResponse,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import FileUpload from "../../fileUpload/FileUploadComponent";
import { actionCreators as patientListsActionCreators } from "../PatientListsActions";
import { ConfirmBulkAddPatientsModalComponent } from "./ConfirmBulkAddPatientsModalComponent";
import { getPatientListIsBulkAdding } from "./PatientListBulkUpload.helper";
import { UploadFailureInfoModal } from "./UploadFailureInfoModal";

type BulkUploadFirstStepInfo = Pick<
    PatientListBulkAddPatientResponse,
    "fileId" | "replacedDates" | "scheduledVideoConsults" | "versionToken"
>;

/**
 * Bulk upload modal should only open
 * if there is a response
 * and it contains at least on date to replace
 * */
const datesWillBeReplaced = (
    firstStepInfo: BulkUploadFirstStepInfo | null,
): boolean => {
    return firstStepInfo !== null && firstStepInfo.replacedDates.length > 0;
};

const BulkUploadPatients = () => {
    const dispatch = useDispatch();

    const isExpandedPatientListEnabled = useIsFeatureEnabled(
        FeatureName.ExpandedPatientList,
    );
    const maxNumberOfPatients = isExpandedPatientListEnabled ? "1000" : "50";
    const isInPatientListContext = useAppSelector(
        ({ patientLists }) =>
            // If a new list is being created the currentList will be null but we still want to showcase the button
            // in case at the time of creating a new list the add patient form is being displayed
            patientLists.currentList !== null ||
            patientLists.createOrEditListInitialising,
    );
    const patientListId = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.patientListId || null,
    );
    const currentListPatientsBulkAdding = useAppSelector(({ patientLists }) =>
        getPatientListIsBulkAdding(patientLists),
    );

    const currentListPatientAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAdding,
    );

    const currentListPatientRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientRemoving,
    );
    const currentListPatientsBatchRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBatchRemoving,
    );
    const deletingListLoading = useAppSelector(
        ({ patientLists }) => patientLists.deletingListLoading,
    );

    // Total dated days excluding Anytime group
    const currentListDatesCount = useAppSelector(({ patientLists }) => {
        if (patientLists.currentList) {
            const datedDays = patientLists.currentList.appointmentDays.filter(
                (day) => day.date !== "",
            );
            return datedDays.length;
        }
        return 0;
    });
    const isSearching = useAppSelector(
        ({ searchForPatient }) => searchForPatient.isSearching,
    );
    const orgId = useAppSelector(({ account }) => account.selectedOrganisation);

    const analyticsProps = useAppSelector((state) =>
        patientListId !== null
            ? AnalyticsMapper.getPatientListAnalyticsProps(state, patientListId)
            : undefined,
    );

    const meaningfulActionProps = useMeaningfulActionAnalyticsProps();

    const [uploadFirstStepInfo, setUploadFirstStepInfo] =
        useState<BulkUploadFirstStepInfo | null>(null);
    const [requestDurationCheckMs, setRequestDurationCheckMs] =
        useState<number>(0);
    const [hasCsvUploadFailed, setHasCsvUploadFailed] = useState(false);
    const [csvUploadErrorMessage, setCsvUploadErrorMessage] = useState<
        string | null
    >(null);

    if (!orgId || !isInPatientListContext) return null;

    const handleUploadDialogOpen = (): void => {
        analyticsProps &&
            FlemingAnalyticsTracker.trackPatientListUploadCsvButtonClicked(
                analyticsProps,
            );
    };

    const handleFileUpload = async (
        fileUploadRequest: IFileUploadRequest,
    ): Promise<void> => {
        if (patientListId) {
            // Start loading
            dispatch(
                patientListsActionCreators.setBulkAddPatientsToListLoadingState(
                    true,
                ),
            );

            // Upload&store file in server & get dates replaced and scheduled
            // VCs that are going to be cancelled
            const requestStartTime = new Date().getTime();
            const { success, error, result } =
                await FlemingApi.bulkAddPatientsToList({
                    ...fileUploadRequest,
                    patientListId,
                });
            const requestDurationCheck =
                new Date().getTime() - requestStartTime;
            setRequestDurationCheckMs(requestDurationCheck);

            // Finish loading
            dispatch(
                patientListsActionCreators.setBulkAddPatientsToListLoadingState(
                    false,
                ),
            );

            if (success === true && result !== null) {
                if (datesWillBeReplaced(result)) {
                    setUploadFirstStepInfo(result);
                } else {
                    handleBulkUploadConfirm(
                        result.fileId,
                        result.versionToken,
                        requestDurationCheck,
                    );
                }

                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListStartBulkAddPatientSuccess(
                        {
                            ...analyticsProps,
                            requestDurationCheckMs: requestDurationCheck,
                            requestDurationConfirmMs: 0,
                            fileId: result.fileId,
                        },
                    );
            } else {
                handleBulkUploadFailure(error);

                analyticsProps &&
                    FlemingAnalyticsTracker.trackPatientListStartBulkAddPatientFailure(
                        {
                            ...analyticsProps,
                            requestDurationCheckMs: requestDurationCheck,
                            requestDurationConfirmMs: 0,
                            fileId: result !== null ? result.fileId : "Unknown",
                            failureReason:
                                error || "Unknown reason for failure",
                        },
                    );
            }
        }
    };

    const handleBulkUploadConfirm = (
        fileId: string,
        token: string,
        requestDurationCheckMs: number,
    ): void => {
        analyticsProps &&
            FlemingAnalyticsTracker.trackPatientListItemBulkAddButtonClick({
                ...analyticsProps,
                ...meaningfulActionProps,
            });

        if (!patientListId) {
            Log.error(
                "Attempted to send confirm bulk add request but required info is missing",
                {
                    tags: {
                        logger: "Patient List - Bulk Upload Patients",
                        patientListId,
                    },
                },
            );
            return;
        }

        dispatch(
            patientListsActionCreators.confirmBulkAddPatientsToList(
                {
                    organisationId: orgId,
                    patientListId,
                    fileId,
                    versionToken: token,
                },
                requestDurationCheckMs,
                confirmBulkAddPatientSuccessCallback,
                handleBulkUploadFailure,
            ),
        );

        // Close the modal
        handleBulkUploadFinish();
    };

    const confirmBulkAddPatientSuccessCallback = (): void => {
        if (!patientListId) return;
        dispatch(
            patientListsActionCreators.getUserPatientList({
                organisationId: orgId,
                patientListId,
            }),
        );
    };

    const handleBulkUploadFailure = (error: string | null): void => {
        // If there's a known error change the state so that modal renders
        if (error !== null) {
            setHasCsvUploadFailed(true);
            setCsvUploadErrorMessage(error);
            // Otherwise just show a generic feedback failure modal
        } else {
            toast(
                Feedback({
                    colour: "error",
                    title: "Couldn't upload",
                    content:
                        "There was an error uploading the list of patients",
                }),
            );
        }
    };

    const handleBulkUploadFailureModalClose = (): void => {
        setHasCsvUploadFailed(false);
        setCsvUploadErrorMessage(null);
    };

    const handleBulkUploadFinish = (): void => {
        setUploadFirstStepInfo(null);
    };

    return (
        <div className="d-md-flex justify-content-between align-items-center">
            <div className="mr-3 mb-3 mb-md-0">
                <Text variant="label" props={{ className: "my-0" }}>
                    Add multiple patients
                </Text>
                <Text
                    variant="body"
                    props={{
                        className: "flex-wrap pl-3 mb-0 ml-0",
                    }}
                    as={"ul"}
                >
                    <li>
                        Upload a spreadsheet (CSV file) containing patients from
                        your patient administration system (PAS) ·
                        <span id="CSVInfoText" className={"mx-1"}>
                            <Text as="span" variant="link" colour="zinc">
                                Learn more
                            </Text>
                        </span>
                    </li>
                    <li>
                        Include dates and times in the spreadsheet in order to
                        create scheduled appointments for each patient ·
                        <span id="ScheduledCSVInfoText" className={"mx-1"}>
                            <Text as="span" variant="link" colour="zinc">
                                Learn more
                            </Text>
                        </span>
                    </li>
                </Text>
                <UncontrolledTooltip
                    placement="top"
                    target="CSVInfoText"
                    autohide={false}
                    delay={{ show: 0, hide: 50 }}
                    className="selectable-tooltip"
                    innerClassName="p-3"
                >
                    <p>
                        Your CSV file should contain a column named 'NHS number'
                        and a column named 'Date of birth'.
                    </p>
                    <br />
                    <p>
                        {`Files can have a maximum of ${maxNumberOfPatients} patients.`}
                    </p>
                    <br />
                    <p className="mb-0">
                        <a
                            href={SupportUrls.PatientListGuide}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "white" }}
                            className="underlined"
                        >
                            Learn even more
                        </a>
                    </p>
                </UncontrolledTooltip>
                <UncontrolledTooltip
                    placement="top"
                    target="ScheduledCSVInfoText"
                    autohide={false}
                    delay={{ show: 0, hide: 50 }}
                    className="selectable-tooltip"
                    innerClassName="p-3"
                >
                    <p>
                        Your CSV file should contain a column named 'NHS
                        number', 'Date of birth', 'Appointment date and time'.
                    </p>
                    <br />
                    <p>
                        {`Files can have a maximum of ${maxNumberOfPatients} patients.`}
                    </p>
                    <br />
                    <p className="mb-0">
                        <a
                            href={
                                SupportUrls.PatientListBulkAddPatientsWithAppointments
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "white" }}
                            className="underlined"
                        >
                            Learn even more
                        </a>
                    </p>
                </UncontrolledTooltip>
            </div>
            <div>
                <FileUpload
                    maxFiles={1}
                    maxSize={3}
                    accept=".csv"
                    noDrag={true}
                    organisationId={orgId}
                    onUploadFile={handleFileUpload}
                    onDialogOpen={handleUploadDialogOpen}
                    uploadButtonProps={{
                        icon: {
                            name: "Upload",
                            colour: "blue",
                        },
                        text: currentListPatientsBulkAdding
                            ? "Uploading..."
                            : "Upload CSV file",
                        disabled:
                            currentListPatientsBulkAdding ||
                            currentListPatientAdding ||
                            isSearching ||
                            currentListPatientRemoving ||
                            currentListPatientsBatchRemoving ||
                            deletingListLoading,
                        theme: "secondary",
                        className: "text-nowrap",
                    }}
                    displayUploadedFiles={false}
                    displayErrorsAsPopover={true}
                />
            </div>
            {uploadFirstStepInfo &&
                datesWillBeReplaced(uploadFirstStepInfo) && (
                    <ConfirmBulkAddPatientsModalComponent
                        appointmentsWithScheduledVideoConsults={
                            uploadFirstStepInfo.scheduledVideoConsults
                        }
                        affectedDates={uploadFirstStepInfo.replacedDates}
                        totalDatesCount={currentListDatesCount}
                        handleConfirm={(): void =>
                            handleBulkUploadConfirm(
                                uploadFirstStepInfo.fileId,
                                uploadFirstStepInfo.versionToken,
                                requestDurationCheckMs,
                            )
                        }
                        handleCancel={handleBulkUploadFinish}
                    />
                )}
            {hasCsvUploadFailed && csvUploadErrorMessage !== null && (
                <UploadFailureInfoModal
                    handleClose={handleBulkUploadFailureModalClose}
                    errorMessage={csvUploadErrorMessage}
                />
            )}
        </div>
    );
};

export default BulkUploadPatients;
