import { FeatureName, Organisation } from "@accurx/auth";
import { IFeature } from "@accurx/shared";

import { IOrganisation } from "api/FlemingDtos";
import type { AccountState } from "app/account/AccountState.types";

export class OrganisationHelper {
    static getOrganisation(accountState: AccountState): IOrganisation | null {
        if (accountState.user && accountState.selectedOrganisation) {
            const foundOrg = accountState.user.organisations.find(
                (x: IOrganisation) =>
                    x.orgId === accountState.selectedOrganisation,
            );
            return foundOrg ? foundOrg : null;
        }
        return null;
    }
    /**
     * @deprecated Use useFeatureIsEnabled hook instead
     */
    static isFeatureEnabled(
        organisation: IOrganisation | null,
        featureName: FeatureName,
    ): boolean {
        if (organisation && organisation.settings) {
            const foundFeature = organisation.settings.features.find(
                (x: IFeature) => x.name === featureName,
            );
            return (foundFeature && foundFeature.isEnabled) === true;
        }
        return false;
    }

    static getOrganisationName = (account: AccountState): string => {
        const noOrg = "Unknown";
        const org = OrganisationHelper.getOrganisation(account);
        if (org) {
            return org.organisationName;
        }
        return noOrg;
    };

    // The following is needed when we want organisation name for a different org to the one logged in.
    static getOrganisationNameForId(
        organisations: Organisation[] | undefined,
        orgId: string | undefined,
    ): string {
        const noOrg = "Unknown";
        if (organisations && orgId) {
            const orgIdNum = parseInt(orgId);
            const foundOrg = organisations.find(
                (x: Organisation) => x.orgId === orgIdNum,
            );
            return foundOrg?.organisationName ?? noOrg;
        }
        return noOrg;
    }

    static getOrganisationId = (account: AccountState): number | null => {
        return OrganisationHelper.getOrganisation(account)?.orgId || null;
    };

    static getIsApprovedOrgUser = (account: AccountState): boolean => {
        const selectedOrg = OrganisationHelper.getOrganisation(account);
        if (selectedOrg) {
            return selectedOrg.settings.isApprovedUser;
        }
        return false;
    };

    static getIsAdminOrgUser = (
        organisation: IOrganisation | null,
    ): boolean => {
        if (organisation) {
            return organisation.settings.isAdminUser;
        }
        return false;
    };

    static isNhsPharmacy = (
        workspace: Organisation | IOrganisation,
    ): boolean => {
        return workspace.nationalCode === "X31";
    };

    /**
     * Returns true for standard pharmacy organisations and does not include NHSPHARMACY
     */
    static isPharmacy = (workspace: Organisation | IOrganisation): boolean => {
        return workspace.roleCode === "RO182";
    };
}
