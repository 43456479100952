import React, { ReactNode, useEffect, useMemo, useRef } from "react";

import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";

import { useQuickViewContext } from "../../context";
import {
    StyledQuickViewColumn,
    StyledQuickViewContainer,
} from "./QuickViewPortal.styles";
import { Body } from "./components/Body/Body";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";

export const QUICK_VIEW_CONTAINER_ID = "quick-view-container";

type QuickViewProps = {
    children: ReactNode;
    onClose: () => void;
    isOpen: boolean;
    /**
     * [Optional] Give the quickview portal an accessible name
     * if none is given, "quick view content" will be used
     */
    label?: string;
    /** An optional ref to an element to return focus to when quick view closes.
     * If one is not provided, we return focus to the last active element when quick
     * view was opened, if it is still in the DOM.
     */
    returnFocusRef?: React.RefObject<HTMLElement>;
};

export const QuickViewPortal = ({
    children,
    onClose,
    isOpen,
    returnFocusRef,
    label = "quick view content",
}: QuickViewProps) => {
    const { registerQuickView, unregisterQuickView } = useQuickViewContext();

    // Store a ref of the latest onClose callback so that consumers don't have
    // to care about memoizing it with `useCallback()` and we always have access
    // to the most recent version.
    const onCloseRef = useRef(onClose);
    onCloseRef.current = onClose;

    // Generate a unique ID and persist
    const id = useMemo(() => `quick-view-${uuidv4()}`, []);

    // Register quick view instance when opened
    useEffect(() => {
        if (isOpen) {
            registerQuickView({ id, onCloseRef });
        }

        // Unregister on unmount
        return () => {
            unregisterQuickView(id, returnFocusRef);
        };
    }, [isOpen, id, registerQuickView, unregisterQuickView, returnFocusRef]);

    const quickViewRoot = document.getElementById(QUICK_VIEW_CONTAINER_ID);

    if (!quickViewRoot || !isOpen) {
        return null;
    }

    return createPortal(
        <StyledQuickViewColumn
            id={id}
            data-testid="quick-view"
            data-quickview-id={id}
            aria-label={label}
            tabIndex={-1}
        >
            <StyledQuickViewContainer>{children}</StyledQuickViewContainer>
        </StyledQuickViewColumn>,
        quickViewRoot,
    );
};

QuickViewPortal.Header = Header;
QuickViewPortal.Body = Body;
QuickViewPortal.Footer = Footer;
