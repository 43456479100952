import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { FeatureName } from "@accurx/auth";
import {
    Button,
    Feedback,
    FormFieldWrapper,
    Input,
    Text,
} from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";

import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { LoadingWrapper } from "app/sharedComponents/LoadingWrapper";
import { isAllowedToRegisterForChain } from "shared/LoginHelper";
import { SupportUrls } from "shared/constants/supportUrls";
import {
    SocketEvents,
    useWebsocketEvent,
} from "shared/hubClient/useWebsocketEvent";
import { useAppSelector } from "store/hooks";

import { OrganisationHelper } from "../../../shared/OrganisationHelper";
import { PracticeDetails } from "../Practices.types";
import {
    addVaccineUser,
    getAllData,
    loadInitialData,
    userChangeRole,
    userChangeStatus,
} from "./PracticeUsers.actions";
import { PracticeUsersProvider } from "./PracticeUsers.context";
import {
    OrgUserApprovalStatus,
    OrgUserRole,
    UserTransitions,
} from "./PracticeUsers.types";
import { UserConfirmModal } from "./UserConfirmModal";
import { UsersTable } from "./UsersTable";

export const PracticeUsers = ({
    noHeader,
}: { noHeader?: boolean } = {}): JSX.Element => {
    useAccurxWebTitle("Manage organisation users");

    const dispatch = useDispatch();

    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const showVaccineLinks = useAppSelector(
        ({ practices }) =>
            practices.items.find(
                (x: PracticeDetails) =>
                    x.id.toString() === practices.selectedPractice,
            )?.showVaccineLinks,
    );

    const organisation = useAppSelector((state) =>
        OrganisationHelper.getOrganisation(state.account),
    );
    const isPracticeGroupsEnabled = OrganisationHelper.isFeatureEnabled(
        organisation,
        FeatureName.PracticeGroups,
    );

    const approvedUsers = useAppSelector(
        ({ practiceUsers }) => practiceUsers.approvedUsers,
        shallowEqual,
    );
    const archivedUsers = useAppSelector(
        ({ practiceUsers }) => practiceUsers.archivedUsers,
        shallowEqual,
    );
    const hasApprovalRights = useAppSelector(
        ({ practiceUsers }) => practiceUsers.hasApprovalRights,
    );
    const loadingStatus = useAppSelector(
        ({ practiceUsers }) => practiceUsers.loadingStatus,
        shallowEqual,
    );
    const unapprovedUsers = useAppSelector(
        ({ practiceUsers }) => practiceUsers.unapprovedUsers,
        shallowEqual,
    );

    const [showArchivedUsers, setShowArchiedUsers] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [currentUser, setCurrentUser] = useState<UserTransitions | null>(
        null,
    );
    const [emailAddress, setEmailAddress] = useState("");
    const [emailValidationError, setEmailValidationError] = useState("");

    useEffect(() => {
        if (practiceId) {
            dispatch(loadInitialData(practiceId));
        }
    }, [dispatch, practiceId]);

    // Subscribe to changing user status socket events
    const refreshUserList = () => {
        getAllData(practiceId, dispatch);
    };
    useWebsocketEvent(SocketEvents.UserAdded, refreshUserList, [
        refreshUserList,
    ]);
    useWebsocketEvent(SocketEvents.UserApprovalChanged, refreshUserList, [
        refreshUserList,
    ]);
    useWebsocketEvent(SocketEvents.UserRoleChanged, refreshUserList, [
        refreshUserList,
    ]);

    const handleToggleArchivedUsers = () => {
        setShowArchiedUsers((prev) => !prev);
    };

    const showModalNotification = (userInput: UserTransitions) => {
        setShowNotificationModal(true);
        setCurrentUser(userInput);
    };

    const toggleModalNotification = () => {
        setShowNotificationModal((prev) => !prev);
    };

    const handleUpdateEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(e.target.value);
    };

    const handleTryCreateUser = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isEmailAllowed = isAllowedToRegisterForChain(emailAddress);

        if (!isEmailAllowed) {
            setEmailValidationError("A valid NHS email address is required.");

            return;
        }
        setEmailValidationError("");
        dispatch(addVaccineUser(practiceId, emailAddress));
        setEmailAddress("");
    };

    const changeRole = (
        userId: number,
        role: OrgUserRole,
        name: string,
    ): void => {
        dispatch(userChangeRole(practiceId, userId, role, name));
    };

    const changeStatus = (
        userId: number,
        status: OrgUserApprovalStatus,
        name: string,
    ): void => {
        dispatch(userChangeStatus(practiceId, userId, status, name));
    };

    return (
        <LoadingWrapper loadingStatus={loadingStatus}>
            <div className="mb-4">
                {/*
                    This page is also rendered inside the new manage organisation
                    layout, which has its own header and breadcrumbs, so we don't
                    want to include them here.
                */}
                {!noHeader && (
                    <>
                        <Breadcrumb title="Manage Users" />
                        <Text variant="title" as="h1">
                            Manage Users
                        </Text>
                    </>
                )}
                <Text>
                    This page allows you to see all the users in your
                    organisation.
                </Text>
                <Text skinny>
                    <b>Admins</b> can{" "}
                    <a
                        href={SupportUrls.ApproveUser}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        approve
                    </a>{" "}
                    new users and{" "}
                    <a
                        href={SupportUrls.ArchiveUser}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        archive
                    </a>{" "}
                    those who have left. They can also{" "}
                    <a
                        href={SupportUrls.MakeUserAdmin}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        make someone admin
                    </a>
                    .
                </Text>
                <Text className="mb-4">
                    <b>Members</b> can use Accurx Desktop, but will not be able
                    to manage users.
                </Text>
                {isPracticeGroupsEnabled && (
                    <Text className="mb-4">
                        <b>Visitors</b> have reduced access to Accurx Desktop,
                        they are only able to interact with conversations that
                        are assigned to themselves or to shared teams.
                    </Text>
                )}
                <Text skinny>
                    Want to add a new user? Follow{" "}
                    <a
                        href={SupportUrls.SetupNewUser}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        these steps
                    </a>
                    .
                </Text>
                <Text className="mb-4">
                    Can't see a user to approve?{" "}
                    <a
                        href={SupportUrls.UsersNotAppearingToApprove}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        See here for how to get users connected
                    </a>
                    .
                </Text>
                {hasApprovalRights && (
                    <Feedback
                        props={{ className: "mb-4" }}
                        content="Please review the admin status of your users and adjust
                        as necessary using the 3 dots next to a user's name. We
                        recommend having at least 2 admins who can approve new
                        users."
                    />
                )}
                {hasApprovalRights && showVaccineLinks && (
                    <>
                        <Text variant="subtitle" as="h2">
                            Add a user
                        </Text>
                        <form
                            onSubmit={handleTryCreateUser}
                            data-testid="add-user-form"
                        >
                            <div className="row mb-5">
                                <div className="w-50 ml-3">
                                    <FormFieldWrapper
                                        errors={[emailValidationError]}
                                    >
                                        <Input
                                            id="vaccine-user-add"
                                            type="email"
                                            placeholder="joebloggs@nhs.net"
                                            onChange={handleUpdateEmail}
                                            value={emailAddress}
                                            autoComplete="off"
                                        />
                                    </FormFieldWrapper>
                                </div>
                                <div className="w-25">
                                    <Button
                                        type="submit"
                                        text="Add"
                                        className="ml-2"
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                )}
                <PracticeUsersProvider
                    value={{
                        hasApprovalRights,
                        changeRole,
                        changeStatus,
                        showModalNotification,
                    }}
                >
                    {!!unapprovedUsers.length && (
                        <UsersTable
                            tableTitle="Unapproved Users"
                            introduction="These are new users who have not yet been approved as part of your organisation. They can send SMS messages, but not access any more functionality."
                            users={unapprovedUsers}
                        />
                    )}
                    <UsersTable
                        tableTitle="Approved Users"
                        introduction="These are users approved by an Admin to use Accurx Desktop in your organisation."
                        users={approvedUsers}
                    />
                    {hasApprovalRights &&
                        (showArchivedUsers ? (
                            <>
                                <Button
                                    type="button"
                                    theme="secondary"
                                    onClick={handleToggleArchivedUsers}
                                    text="Hide Archived Users"
                                    className="mb-4 m-auto"
                                />
                                <UsersTable
                                    tableTitle="Archived Users"
                                    introduction="These are users that have been archived by an Admin e.g. because they have left the organisation."
                                    users={archivedUsers}
                                />
                            </>
                        ) : (
                            <Button
                                type="button"
                                theme="secondary"
                                onClick={handleToggleArchivedUsers}
                                text="Show Archived Users"
                                className="m-auto"
                            />
                        ))}
                </PracticeUsersProvider>
            </div>
            {showNotificationModal && currentUser && (
                <UserConfirmModal
                    isOpen={showNotificationModal}
                    currentUser={currentUser}
                    onToggleModal={toggleModalNotification}
                />
            )}
        </LoadingWrapper>
    );
};
