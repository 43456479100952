import { TabProps } from "@accurx/design";
import { generatePath, useHistory, useParams } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";
import { useCurrentOrgName } from "store/hooks";

import { TabId, getTabLookup, getTabs } from "./ManageAccumailTemplates.tabs";

export type UseManageAccumailTemplatesPage = () => {
    tab?: TabProps;
    onTabChange: (tab: string) => void;
    redirect: string;
    orgName: string;
};

export const useManageAccumailTemplatesPage: UseManageAccumailTemplatesPage =
    () => {
        const orgName = useCurrentOrgName();
        const history = useHistory();
        const { tab: tabId, orgId } = useParams<{
            tab: TabId;
            orgId: string;
        }>();

        const tab = getTabLookup(orgName)[tabId || getTabs(orgName)[0].tabId];

        const onTabChange = (tabId: string) => {
            history.push(
                generatePath(ROUTES_ORGS.accumailManageTemplates, {
                    orgId,
                    tab: tabId,
                }),
            );
        };
        // generate a redirect url in case the route contains an invalid tab
        const redirect = generatePath(ROUTES_ORGS.accumailManageTemplates, {
            orgId,
        });

        return { tab, onTabChange, redirect, orgName };
    };
