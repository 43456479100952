import { useCallback, useEffect, useState } from "react";

import { useFeatureFlag } from "@accurx/auth";
import { conversationIdMapper } from "@accurx/concierge";
import { useTransport } from "@accurx/transport";

import { SocketEvents } from "shared/hubClient/HubClient";
import {
    ThreadActiveInternalMs,
    ThreadActiveReceive,
} from "shared/hubClient/payload.types";

type User = {
    name: string;
    lastActiveTime: number;
};

type UserId = string;
type CurrentlyViewingUserMap = Map<UserId, User>;

const mapUserIsViewingTicketIdToConversationId = (
    payload: ThreadActiveReceive,
) => {
    if (!payload.ticketIdentity?.type || !payload.ticketIdentity.id) return;

    const processedTicketIdentity = {
        type: payload.ticketIdentity.type,
        id: payload.ticketIdentity.id,
    };
    return conversationIdMapper.ticket.fromSource(processedTicketIdentity);
};

export const useCurrentlyViewingUsers = (conversationId: string) => {
    const isCurrentlyViewingEnabled = useFeatureFlag(
        "UnifiedInboxCurrentlyViewing",
    );

    const { transport } = useTransport();

    const [currentlyViewingUserMap, setCurrentlyViewingUserMap] =
        useState<CurrentlyViewingUserMap>(new Map());

    /**
     *
     * @description Store the viewers name along with the current timestamp this represents their last active time
     */
    const addViewer = (key: string, value: string): void => {
        const currentViewingUser: User = {
            name: value,
            lastActiveTime: Date.now(), // Get the current timestamp in milliseconds
        };
        setCurrentlyViewingUserMap(
            (prevMap) => new Map(prevMap.set(key, currentViewingUser)),
        );
    };

    /**
     * @description Update a viewers last active time
     */
    const updateViewerLastActiveTime = useCallback((key: string) => {
        try {
            setCurrentlyViewingUserMap((prevMap) => {
                const newCurrentlyViewingMap = new Map(prevMap);
                const existingViewer = newCurrentlyViewingMap.get(key);

                if (!existingViewer) return newCurrentlyViewingMap;

                existingViewer.lastActiveTime = Date.now();
                newCurrentlyViewingMap.set(key, existingViewer);
                return newCurrentlyViewingMap;
            });
        } catch (error) {
            if (error instanceof Error) {
                console.warn(
                    "Viewer no longer part of currently viewing Map",
                    error.message,
                );
            }
            throw error;
        }
    }, []);

    const removeViewer = (key: string): void => {
        setCurrentlyViewingUserMap((prevMap) => {
            const newCurrentlyViewingMap = new Map(prevMap);
            newCurrentlyViewingMap.delete(key);
            return newCurrentlyViewingMap;
        });
    };

    useEffect(() => {
        if (!isCurrentlyViewingEnabled || !transport) return;

        const subscription = transport.subscribe({
            methodName: SocketEvents.OnThreadActive,
            onEvent: (update) => {
                const subscriptionResponse: ThreadActiveReceive =
                    update.payload;

                const notificationConversationId =
                    mapUserIsViewingTicketIdToConversationId(
                        subscriptionResponse,
                    );
                // If a viewer leaves a conversation there ticketID will return as null, if null we remove them from the currently viewing map
                if (
                    !notificationConversationId &&
                    subscriptionResponse.sender.accuRxId
                ) {
                    removeViewer(subscriptionResponse.sender.accuRxId);
                }
                /**
                 * When the passed in conversationId and the viewers ticket Id match we know that the viewer is looking at this conversation
                 * If it's a new user we add the sender details to teh currently viewing Map other we update their viewership details
                 */
                if (notificationConversationId === conversationId) {
                    const userDisplayName = subscriptionResponse.sender.name;
                    if (
                        currentlyViewingUserMap.has(
                            subscriptionResponse.sender.accuRxId,
                        )
                    ) {
                        updateViewerLastActiveTime(
                            subscriptionResponse.sender.accuRxId,
                        );
                    } else {
                        addViewer(
                            subscriptionResponse.sender.accuRxId,
                            userDisplayName,
                        );
                    }
                }
            },
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [
        transport,
        conversationId,
        currentlyViewingUserMap,
        updateViewerLastActiveTime,
        isCurrentlyViewingEnabled,
    ]);

    /**
     * This interval removes users who have been inactive for over 30 secs
     */
    useEffect(() => {
        if (!isCurrentlyViewingEnabled) return;

        if (currentlyViewingUserMap.size === 0) return;

        const removeInactiveUsers = setInterval(() => {
            // Copy map and iterate over copied version to guard against a user who stops viewing a conversation mid iteration.
            const duplicateCurrentlyViewingUserMap = new Map(
                currentlyViewingUserMap,
            );
            const currentTime = Date.now();

            duplicateCurrentlyViewingUserMap.forEach(
                ({ lastActiveTime }, key) => {
                    try {
                        // Check user is still in the map and they have been inactive for more then 30secs, if so remove them from the Currently Viewing User map.

                        if (
                            currentTime - lastActiveTime > 30000 &&
                            currentlyViewingUserMap.has(key)
                        ) {
                            removeViewer(key);
                        }
                    } catch (error) {
                        if (error instanceof Error) {
                            // This will fire when a user who originally was the map but since it got to executing the if statement they've left.
                            console.warn(
                                "Viewer was not in the Map",
                                error.message,
                            );
                        }
                        throw Error;
                    }
                },
            );
        }, ThreadActiveInternalMs);

        return () => {
            clearInterval(removeInactiveUsers);
        };
    }, [isCurrentlyViewingEnabled, currentlyViewingUserMap]);

    return Array.from(currentlyViewingUserMap.values()).map(({ name }) => name);
};
