import React, { useEffect, useRef, useState } from "react";

import { QuestionnaireSummary, StatusQuery } from "@accurx/api/florey-builder";
import { FeatureName } from "@accurx/auth";
import {
    Button,
    Card,
    Ds,
    Feedback,
    Spinner,
    StackPanel,
    Table,
    Text,
    Tokens,
} from "@accurx/design";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useQuestionnaireSummariesQuery } from "app/hooks/queries";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useIsFeatureEnabled } from "store/hooks";

import { useFloreyBuilder } from "../FloreyBuilder.context";
import { Actions } from "../constants";
import { routeNameAndQuestionsBase } from "../constants/paths";
import { CustomFloreySummary } from "./CustomFloreySummary";

const StyledTextAndBadgeLayout = styled.span`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const StyledCardTitle = styled.div`
    margin-bottom: ${Tokens.SIZES[0.5]};
`;

const StyledTableIntro = styled.div`
    padding-left: ${Tokens.SIZES[2]};
    padding-right: ${Tokens.SIZES[2]};
`;

const StyledHeadingButton = styled(Button)`
    max-width: fit-content;
`;

const StyledTableWrapper = styled.div`
    overflow-x: auto;
`;

const StyledCentredDiv = styled.div`
    margin: ${Tokens.SIZES[2]};
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const FloreyBuilderHome = (): JSX.Element => {
    const { dispatch } = useFloreyBuilder();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    // Feature flags
    const isSharingEnabled = useIsFeatureEnabled(FeatureName.FloreyBuilder);
    const isGlobalCustomFloreyEnabled = useIsFeatureEnabled(
        FeatureName.CreateGlobalCustomFloreys,
    );

    const history = useHistory();
    const { orgId } = useParams<{ orgId: string }>();

    const { data, status: pageStatus } = useQuestionnaireSummariesQuery({
        workspaceId: parseInt(orgId, 10),
    });

    const [
        currentSharingQuestionnaireError,
        setCurrentSharingQuestionnaireError,
    ] = useState<ErrorDetails | null>(null);
    const errorFeedbackRef = useRef<HTMLAnchorElement>(null);

    type ErrorDetails = {
        QuestionnaireName: string;
        QuestionnaireId: number | null;
    };

    // on load, run analytics
    // but also run the context/shared state refresh
    // which prevents any stale state.
    // Look to clean this up as we move FloreyBuilder
    // away from Redux
    useEffect(() => {
        dispatch({
            type: Actions.RESET_STATE,
            payload: {},
        });
        FlemingAnalyticsTracker.trackQuestionnaireOverview(
            analyticsLoggedInProps,
        );
    }, [analyticsLoggedInProps, dispatch]);

    const handleContinue = (): void => {
        FlemingAnalyticsTracker.trackQuestionnaireCreateStart(
            analyticsLoggedInProps,
        );
        history.push(routeNameAndQuestionsBase(orgId));
    };

    const scrollToAndFocusElement = (element: HTMLElement) => {
        element.scrollIntoView({ behavior: "smooth" });
        element.focus();
    };

    const anyShareError = currentSharingQuestionnaireError !== null;

    const updateAndDisplaySharingError = (
        questionnaireSummary: QuestionnaireSummary | undefined,
    ) => {
        setCurrentSharingQuestionnaireError({
            QuestionnaireName: questionnaireSummary?.name ?? "",
            QuestionnaireId: questionnaireSummary?.id ?? null,
        } as ErrorDetails);

        if (!errorFeedbackRef || !errorFeedbackRef.current) return;
        scrollToAndFocusElement(errorFeedbackRef.current);
        return;
    };

    function sortDatesAscending(
        a: QuestionnaireSummary,
        b: QuestionnaireSummary,
    ) {
        if (a.lastUpdatedAt === undefined) {
            return 1;
        }
        if (b.lastUpdatedAt === undefined) {
            return -1;
        }

        if (b.lastUpdatedAt > a.lastUpdatedAt) {
            return 1;
        }
        return -1;
    }

    return (
        <>
            <Breadcrumb title="Custom Florey Questionnaires" />
            <Text variant="title" as="h1">
                Custom Florey Questionnaires
            </Text>
            <Text>
                Florey questionnaires created by members of your organisation
                that can be sent via Batch Messaging and individual text message
                on Accurx Desktop.
            </Text>
            <Text>
                <b>Tip:</b> Use the keyboard shortcut CTRL-F to search for a
                specific Florey
            </Text>
            <StackPanel gutter={4} orientation={"vertical"}>
                <StyledHeadingButton
                    type="button"
                    className="m-1"
                    onClick={handleContinue}
                    text={"Create Florey Questionnaire"}
                    icon={{
                        name: "Arrow",
                        rotation: "right",
                        placement: "end",
                    }}
                    dimension={"medium"}
                />
                {isSharingEnabled && (
                    <Feedback
                        colour={"learning"}
                        title={"Share published Custom Florey questionnaire"}
                    >
                        Now you can share your questionnaires to other health
                        professionals or organisations linked to your Accurx
                        account.
                    </Feedback>
                )}
                <Card spacing={0}>
                    <StyledTableIntro>
                        <StyledCardTitle>
                            <Text variant={"subtitle"} as="h2">
                                Drafted Floreys
                            </Text>
                        </StyledCardTitle>
                        <StyledTextAndBadgeLayout>
                            <Text>
                                When a Florey Questionnaire is{" "}
                                <Ds.Badge color="green">Ready to test</Ds.Badge>{" "}
                                try sending it to a test patient in Accurx
                                Desktop to see how it will work for patients.
                            </Text>
                        </StyledTextAndBadgeLayout>
                    </StyledTableIntro>
                    <StyledTableWrapper>
                        <Table>
                            <Table.ColumnGroup>
                                <Table.Column
                                    data-column-name="Last edit time"
                                    width="10%"
                                />
                                <Table.Column
                                    data-column-name="Questionnaire name"
                                    width="30%"
                                />
                                <Table.Column
                                    data-column-name="Assignment"
                                    width="15%"
                                />
                                <Table.Column
                                    data-column-name="Edited by"
                                    width="15%"
                                />
                                <Table.Column
                                    data-column-name="Status"
                                    width="15%"
                                />
                                <Table.Column
                                    data-column-name="Action"
                                    width="15%"
                                />
                            </Table.ColumnGroup>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Last edit time
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Questionnaire name
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Assignment
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>Edited by</Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>Status</Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>Action</Text>
                                    </Table.Header>
                                </Table.Row>
                            </Table.Head>
                            {pageStatus === "success" && (
                                <Table.Body>
                                    {data.summaries
                                        ?.filter(
                                            (x) =>
                                                x.status ===
                                                    StatusQuery.Draft ||
                                                x.status ===
                                                    StatusQuery.Reviewing,
                                        )
                                        ?.sort(sortDatesAscending)
                                        .map((summary) => {
                                            return (
                                                <CustomFloreySummary
                                                    isSharingEnabled={
                                                        isSharingEnabled
                                                    }
                                                    isGlobalCustomFloreyEnabled={
                                                        isGlobalCustomFloreyEnabled
                                                    }
                                                    key={summary.id}
                                                    summary={summary}
                                                />
                                            );
                                        })}
                                </Table.Body>
                            )}
                        </Table>
                    </StyledTableWrapper>
                    {/* The following states need to be outside of the table above */}
                    {pageStatus === "loading" && (
                        <StyledCentredDiv>
                            <Spinner />
                        </StyledCentredDiv>
                    )}
                    {pageStatus === "error" && (
                        <StyledCentredDiv>
                            <Feedback
                                title="There was a problem showing the table content"
                                colour="error"
                            >
                                Refresh the page to try again
                            </Feedback>
                        </StyledCentredDiv>
                    )}
                </Card>

                {anyShareError && (
                    <Feedback
                        title={`An error has occured. You are unable to share the "${currentSharingQuestionnaireError?.QuestionnaireName}" questionnaire`}
                        colour="error"
                        props={{
                            tabIndex: "-1",
                            ref: errorFeedbackRef,
                        }}
                    >
                        Refresh the page to try again. If the error persists,
                        please contact our customer support team.
                    </Feedback>
                )}

                <Card spacing={0}>
                    <StyledTableIntro>
                        <StyledCardTitle>
                            <Text variant={"subtitle"} as="h2">
                                Published Floreys
                            </Text>
                        </StyledCardTitle>
                    </StyledTableIntro>
                    <StyledTableWrapper>
                        <Table>
                            <Table.ColumnGroup>
                                <Table.Column
                                    data-column-name="Publish time"
                                    width="10%"
                                />
                                <Table.Column
                                    data-column-name="Questionnaire name"
                                    width="45%"
                                />
                                <Table.Column
                                    data-column-name="Assignment"
                                    width="15%"
                                />
                                <Table.Column
                                    data-column-name="Published by"
                                    width="15%"
                                />
                                <Table.Column
                                    data-column-name="Action"
                                    width="15%"
                                />
                            </Table.ColumnGroup>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Publish time
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Questionnaire name
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Assignment
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>
                                            Published by
                                        </Text>
                                    </Table.Header>
                                    <Table.Header>
                                        <Text variant={"label"}>Action</Text>
                                    </Table.Header>
                                </Table.Row>
                            </Table.Head>
                            {pageStatus === "success" && (
                                <Table.Body>
                                    {data.summaries
                                        ?.filter(
                                            (x) =>
                                                x.status ===
                                                StatusQuery.Published,
                                        )
                                        ?.sort(sortDatesAscending)
                                        .map((summary) => {
                                            return (
                                                <CustomFloreySummary
                                                    isSharingEnabled={
                                                        isSharingEnabled
                                                    }
                                                    isGlobalCustomFloreyEnabled={
                                                        isGlobalCustomFloreyEnabled
                                                    }
                                                    onShareError={
                                                        updateAndDisplaySharingError
                                                    }
                                                    key={summary.id}
                                                    summary={summary}
                                                />
                                            );
                                        })}
                                </Table.Body>
                            )}
                        </Table>
                    </StyledTableWrapper>
                    {/* The following states need to be outside of the table above */}
                    {pageStatus === "loading" && (
                        <StyledCentredDiv>
                            <Spinner />
                        </StyledCentredDiv>
                    )}
                    {pageStatus === "error" && (
                        <StyledCentredDiv>
                            <Feedback
                                title="There was a problem showing the table content"
                                colour="error"
                            >
                                Refresh the page to try again
                            </Feedback>
                        </StyledCentredDiv>
                    )}
                </Card>
            </StackPanel>
        </>
    );
};
