import * as React from "react";

import { Card, Spinner } from "@accurx/design";

import { trackPatientTriageDashboardFilterByRequestMenuItemClick } from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { Graph } from "app/sharedComponents/graph/Graph";
import { Barchart } from "app/sharedComponents/graph/Graph.barchart.types";
import { GraphType } from "app/sharedComponents/graph/Graph.types";

import { DashboardData } from "../../../types";
import { xAxisCategories } from "../Dashboards.constants";
import { FortnightlyBarGraphLegend } from "../shared/FortnightlyBarGraphLegend/FortnightlyBarGraphLegend";
import { DashboardCardHeader } from "../shared/Header";
import { NoReceptionDataPrompt } from "../shared/NoReceptionDataPrompt/NoReceptionDataPrompt";
import { RequestSource, RequestType } from "../shared/shared.constants";
import { IRequestSource, IRequestType } from "../shared/shared.types";
import {
    NoReceptionDataCard,
    NoReceptionDataWrapper,
} from "./RequestsByDay.styles";
import { aggregateDataForRequestsByDayGraph } from "./RequestsByDay.utils";

export type BarchartProps = {
    isLoading: boolean;
    dashboardData: DashboardData[];
};

export const RequestsByDayGraph = ({
    isLoading,
    dashboardData,
}: BarchartProps): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const [filterRequestType, setFilterRequestType] =
        React.useState<IRequestType>(RequestType.MedicalAndAdmin);
    const [filterRequestSource, setFilterRequestSource] =
        React.useState<IRequestSource>(RequestSource.PatientsAndReception);

    const { aggregatedData: seriesObject, isEmptyDataSet } =
        aggregateDataForRequestsByDayGraph(
            dashboardData,
            filterRequestType,
            filterRequestSource,
        );

    const handleFilterByRequestType = (requestType: IRequestType): void => {
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            dashboardName: "Patient Triage Dashboard",
            sectionName: "Requests by day",
            filterName: "Filter by Request Type",
            activeFilterList: [
                `Request Type - ${requestType}`,
                `Request Source - ${filterRequestSource}`,
            ],
            optionSelected: requestType,
        });
        setFilterRequestType(requestType);
    };

    const handleFilterByRequestSource = (requestSource: IRequestSource) => {
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            dashboardName: "Patient Triage Dashboard",
            sectionName: "Requests by day",
            filterName: "Filter by Request Source",
            activeFilterList: [
                `Request Source - ${requestSource}`,
                `Request Type - ${filterRequestType}`,
            ],
            optionSelected: requestSource,
        });
        setFilterRequestSource(requestSource);
    };

    const options: Barchart = {
        plotOptions: {
            series: {
                borderRadius: 3,
                pointPadding: 0,
                groupPadding: 0.1,
            },
        },
        yAxis: {
            allowDecimals: false,
        },
        legend: {
            enabled: false,
        },
    };

    return (
        <>
            <Card
                spacing={3}
                header={
                    <DashboardCardHeader
                        cardTitle={"Requests by day"}
                        cardSubTitle={"Last and current week"}
                        cardTooltip={
                            "The number of Patient Triage requests by day in the previous and current week"
                        }
                        filter={{
                            requestTypeFilterId: "filter-day-by-request-type",
                            requestSourceFilterId:
                                "filter-day-by-request-source",
                            onRequestTypeFilterSelect:
                                handleFilterByRequestType,
                            onRequestSourceFilterSelect:
                                handleFilterByRequestSource,
                        }}
                    />
                }
            >
                {isLoading && <Spinner />}
                {!isLoading && (
                    <div style={{ position: "relative" }}>
                        <Graph
                            graphType={GraphType.Column}
                            xAxisCategories={xAxisCategories}
                            chartOptions={options}
                            customLegend={<FortnightlyBarGraphLegend />}
                            series={[
                                ...seriesObject,
                                {
                                    /**
                                     * Force graph to maintain a non-zero
                                     * series even with no data
                                     * https://www.highcharts.com/forum/viewtopic.php?t=45068
                                     */
                                    color: "transparent",
                                    enableMouseTracking: false,
                                    showInLegend: false,
                                    opacity: 0,
                                    data: [1.2],
                                },
                            ]}
                        />
                        {filterRequestSource === RequestSource.Reception &&
                            isEmptyDataSet && (
                                <NoReceptionDataWrapper>
                                    <NoReceptionDataCard>
                                        <NoReceptionDataPrompt
                                            sectionName="Requests by day"
                                            requestSource={filterRequestSource}
                                            requestType={filterRequestType}
                                        />
                                    </NoReceptionDataCard>
                                </NoReceptionDataWrapper>
                            )}
                    </div>
                )}
            </Card>
        </>
    );
};
