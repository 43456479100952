import { StyledBookingInviteFeedback } from "./BookingLinkWarning.styles";

export const BookingLinkWarning = ({
    text,
    id,
}: {
    text: string;
    id: string;
}) => (
    <StyledBookingInviteFeedback
        colour="warning"
        title={text}
        props={{
            id,
        }}
    />
);
