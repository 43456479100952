import { SendOptions } from "./scheduleBatch.constants";

export const ScheduleSendFormValidation = (values: {
    errors: string[];
    sendOption: SendOptions;
    selectedDate: string;
    selectedTime: string;
    selectedDateFallBackError: boolean;
}): boolean => {
    if (values.errors.length !== 0) {
        return true;
    }

    if (values.selectedDateFallBackError) {
        return true;
    }

    if (
        values.sendOption === SendOptions.SEND_LATER &&
        (!values.selectedDate || !values.selectedTime)
    ) {
        return true;
    }

    return false;
};

export const StaggeredDaysValidation = (values: {
    staggeredDays: number;
    patients: number;
}): string[] => {
    if (!values.staggeredDays) {
        return [`Please enter a number between 2 and ${values.patients}`];
    }

    if (values.staggeredDays < 2) {
        return [
            "Increase the number of days, the number of working days must be at least 2",
        ];
    }

    if (values.staggeredDays > 365 || values.patients < values.staggeredDays) {
        return [
            "Reduce the number of days, the patient number must be at least 1 a day",
        ];
    }

    return [];
};
