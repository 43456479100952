import { useState } from "react";

import { Button, Ds, Flex, Icon, Input, Table, Text } from "@accurx/design";
import { generatePath, useHistory } from "react-router";

import { AccumailTemplate } from "app/practices/accumail/templates.types";
import { ROUTES_ORGS } from "shared/Routes";
import { useCurrentOrgId } from "store/hooks";

import { textToJson } from "../accumailTemplateEditor";
import { AccumailTemplateViewer } from "../accumailTemplateEditor/AccumailTemplateViewer";
import { DeleteTemplateModal } from "../deleteTemplate/DeleteTemplateModal";
import {
    CentredText,
    StyledHeaderStackPanel,
    StyledTable,
    StyledTableBody,
} from "./AccumailTemplateList.styles";

export type AccumailTemplateListProps = {
    tableTitle: string;
    data: AccumailTemplate[];
} & (
    | {
          hasActions: true;
          onDelete: (templateId: string) => void;
      }
    | {
          hasActions: false;
      }
);

export const AccumailTemplateList = (props: AccumailTemplateListProps) => {
    const { tableTitle, data, hasActions } = props;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const orgId = useCurrentOrgId()!;
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [templateToDelete, setTemplateToDelete] =
        useState<AccumailTemplate | null>(null);

    const bySearchTerm = ({ title, content }: AccumailTemplate) =>
        searchTerm.trim() !== ""
            ? title.toUpperCase().includes(searchTerm.toUpperCase()) ||
              content.toUpperCase().includes(searchTerm.toUpperCase())
            : true;

    const editTemplateLink = (templateId: string) =>
        generatePath(ROUTES_ORGS.accumailManageTemplatesEdit, {
            orgId,
            templateId,
        });

    const copyTemplateLink = (templateId: string) =>
        generatePath(ROUTES_ORGS.accumailManageTemplatesCopy, {
            orgId,
            templateId,
        });

    const filteredTemplates = data.filter(bySearchTerm);

    return (
        <>
            <StyledTable>
                <Table.ColumnGroup>
                    <Table.Column width="25%" />
                    <Table.Column width="50%" />
                    <Table.Column width="25%" />
                </Table.ColumnGroup>
                <Table.Head>
                    <Table.Row>
                        <Table.Header colSpan={3} scope="col">
                            <StyledHeaderStackPanel
                                horizontalContentAlignment="space-between"
                                verticalContentAlignment="center"
                                orientation="horizontal"
                            >
                                <Text variant="subtitle" skinny>
                                    {tableTitle}
                                </Text>
                                {data.length > 0 && (
                                    <Input
                                        type="text"
                                        isSearchInput
                                        onChange={({ target }) =>
                                            setSearchTerm(target.value)
                                        }
                                        placeholder="Search by title or content"
                                    />
                                )}
                            </StyledHeaderStackPanel>
                        </Table.Header>
                    </Table.Row>
                    <Table.Row>
                        <Table.Header scope="col" colour="greyscale">
                            Template name
                        </Table.Header>
                        <Table.Header
                            scope="col"
                            colSpan={hasActions ? 1 : 2}
                            colour="greyscale"
                        >
                            Details
                        </Table.Header>
                        {hasActions && (
                            <Table.Header scope="col" colour="greyscale">
                                Actions
                            </Table.Header>
                        )}
                    </Table.Row>
                </Table.Head>
                <StyledTableBody>
                    {filteredTemplates.map((row) => (
                        <Table.Row key={row.id}>
                            <Table.Header scope="row">{row.title}</Table.Header>
                            <Table.DataCell colSpan={hasActions ? 1 : 2}>
                                <AccumailTemplateViewer
                                    content={textToJson(row.content)}
                                />
                                {row.snomedCodes !== undefined &&
                                    row.snomedCodes.length > 0 && (
                                        <Flex
                                            gap="1.5"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                        >
                                            {row.snomedCodes.map(
                                                ({ term, conceptId }) => (
                                                    <Ds.Badge color="greyscale">
                                                        <Icon name="Tag" />
                                                        {`SNOMED code: ${term} - ${conceptId}`}
                                                    </Ds.Badge>
                                                ),
                                            )}
                                        </Flex>
                                    )}
                            </Table.DataCell>
                            {hasActions && (
                                <Table.DataCell>
                                    <Flex
                                        gap="1.5"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                    >
                                        {row.templateType ===
                                            "UserTemplate" && (
                                            <Button
                                                theme="primary"
                                                icon={{
                                                    name: "Copy",
                                                    colour: "blue",
                                                }}
                                                onClick={() =>
                                                    history.push(
                                                        copyTemplateLink(
                                                            row.id,
                                                        ),
                                                    )
                                                }
                                                text="Copy to Organisation"
                                            />
                                        )}
                                        <Button
                                            theme="secondary"
                                            icon={{
                                                name: "Pencil",
                                                colour: "blue",
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    editTemplateLink(row.id),
                                                )
                                            }
                                            text="Edit"
                                        />
                                        <Button
                                            theme="secondary"
                                            icon={{
                                                name: "Bin",
                                                colour: "blue",
                                            }}
                                            onClick={() =>
                                                setTemplateToDelete(row)
                                            }
                                            text="Delete"
                                        />
                                    </Flex>
                                </Table.DataCell>
                            )}
                        </Table.Row>
                    ))}
                </StyledTableBody>
                <Table.Foot>
                    <Table.Row>
                        <Table.DataCell colSpan={3}>
                            {data.length === 0 ? (
                                <Text>No templates created</Text>
                            ) : (
                                <CentredText>
                                    Showing {filteredTemplates.length}
                                </CentredText>
                            )}
                        </Table.DataCell>
                    </Table.Row>
                </Table.Foot>
            </StyledTable>
            {templateToDelete !== null && hasActions && (
                <DeleteTemplateModal
                    templateToDelete={templateToDelete}
                    onDelete={(id) => {
                        props.onDelete(id);
                        setTemplateToDelete(null);
                    }}
                    onCancel={() => setTemplateToDelete(null)}
                />
            )}
        </>
    );
};
