import { ReactNode } from "react";

import { Button, Flex } from "@accurx/design";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { PageHeader, PageHeaderType } from "app/layout/PageHeader";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { BatchMessageSupportLink } from "./BatchMessageSupportLink";
import {
    WebBatchControlsWrapper,
    WebBatchPageContent,
    WebBatchPageFooterNav,
    WebBatchPageHeaderNav,
    WebBatchPageLayout,
    WebBatchWidthWrapper,
} from "./BatchMessageWeb.styles";

export type BetchMessagePageLayoutProps = {
    children: ReactNode;
    pageTitle: string;
    showControls?: boolean;
    continueDisabled?: boolean;
    onContinue?: () => void;
    continueTitle?: string;
    onBack?: () => void;
};

export const BatchMessagePageLayout = ({
    children,
    pageTitle,
    showControls,
    continueDisabled,
    onContinue,
    continueTitle,
    onBack,
}: BetchMessagePageLayoutProps) => {
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleBackClick = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: true,
        });

        (onBack ?? history.goBack)();
    };

    return (
        <WebBatchPageLayout>
            <WebBatchPageHeaderNav>
                <Button
                    onClick={handleBackClick}
                    text="Back"
                    icon={{
                        name: "ArrowTail",
                        rotation: "left",
                        placement: "start",
                    }}
                    theme="transparent"
                    data-userflow-id="nav-back"
                />
            </WebBatchPageHeaderNav>

            <WebBatchPageContent>
                <Flex justifyContent="space-between" alignItems="center">
                    <PageHeader
                        type={PageHeaderType.ListPage}
                        title={pageTitle}
                    />

                    <BatchMessageSupportLink />
                </Flex>

                <WebBatchWidthWrapper>{children}</WebBatchWidthWrapper>
            </WebBatchPageContent>

            {showControls && (
                <WebBatchPageFooterNav>
                    <WebBatchControlsWrapper>
                        <Button
                            disabled={continueDisabled}
                            onClick={onContinue}
                            text={continueTitle || "Continue"}
                            icon={{
                                name: "ArrowTail",
                                rotation: "right",
                                placement: "end",
                            }}
                        />
                    </WebBatchControlsWrapper>
                </WebBatchPageFooterNav>
            )}
        </WebBatchPageLayout>
    );
};
