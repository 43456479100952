import { getDateOfBirthInputError } from "@accurx/design";
import { NhsNumberHelpers } from "@accurx/shared";

import { sanitiseNhsNumber } from "./sanitiseNhsNumber";
import {
    FormErrorsByDemographics,
    FormErrorsByNhsNumber,
    FormStateByDemographics,
    FormStateByNhsNumber,
} from "./types";

export const validateByNhsNumberForm = (
    formValues: FormStateByNhsNumber,
): { isValid: boolean; errors: FormErrorsByNhsNumber } => {
    const dobError = getDateOfBirthInputError({
        day: formValues.dateOfBirth.day,
        month: formValues.dateOfBirth.month,
        year: formValues.dateOfBirth.year,
    });

    const nhsNumberError = NhsNumberHelpers.validateNhsNumber(
        sanitiseNhsNumber(formValues.nhsNumber),
    );

    return {
        isValid: !dobError && nhsNumberError.success,
        errors: {
            dateOfBirth: dobError,
            nhsNumber: nhsNumberError.error,
        },
    };
};

export const validateByDemographicsForm = (
    formValues: FormStateByDemographics,
): { isValid: boolean; errors: FormErrorsByDemographics } => {
    const errors: FormErrorsByDemographics = {
        dateOfBirth: getDateOfBirthInputError(formValues.dateOfBirth),
        firstName: !formValues.firstName ? "Enter a first name" : null,
        lastName: !formValues.lastName ? "Enter a last name" : null,
        postcode: !formValues.postcode ? "Enter a postcode" : null,
        gender: !formValues.gender ? "Select an option" : null,
    };

    const isValid = Object.values(errors).every((v) => v === null);

    return {
        isValid,
        errors,
    };
};
