import * as UI from "@accurx/design";
import { Link, useLocation } from "react-router-dom";

export const ReplyFailedFeedback = () => {
    const location = useLocation();
    const currentURL = location.pathname + location.search;
    return (
        <UI.Feedback
            title={
                <UI.Flex justifyContent="space-between" alignItems="flex-start">
                    <UI.Text variant="label">
                        An error occurred when trying to send your message.
                    </UI.Text>
                    <UI.Button
                        theme="transparent"
                        icon={{
                            name: "Cross",
                            title: "Close alert",
                        }}
                        aria-label="Close alert"
                    />
                </UI.Flex>
            }
            colour="error"
        >
            <UI.Flex flexDirection="column">
                <UI.Text skinny>
                    Go back to your message and try sending it again. If this
                    keeps happening, please get in touch with our support team.
                </UI.Text>
                <Link to={currentURL} style={{ width: "fit-content" }}>
                    <UI.Text skinny colour="blue" as="span">
                        Go to message
                    </UI.Text>
                </Link>
            </UI.Flex>
        </UI.Feedback>
    );
};
