import React from "react";

import { Ds } from "@accurx/design";

import {
    RecordViewRequestResult,
    RecordViewResultStatus,
} from "api/FlemingDtos";

const Badges = {
    [RecordViewResultStatus.RecordAvailable]: (
        <Ds.Badge color="green" className="text-nowrap">
            Available
        </Ds.Badge>
    ),
    [RecordViewResultStatus.Expired]: (
        <Ds.Badge color="greyscale" className="text-nowrap">
            Expired
        </Ds.Badge>
    ),
    [RecordViewResultStatus.TwofaCodeRequired]: (
        <Ds.Badge color="yellow" className="text-nowrap">
            Authorisation pending
        </Ds.Badge>
    ),
    [RecordViewResultStatus.AuthorisationFailed]: (
        <Ds.Badge color="red" className="text-nowrap">
            Authorisation failed
        </Ds.Badge>
    ),
    [RecordViewResultStatus.Requesting]: (
        <Ds.Badge color="yellow" className="text-nowrap">
            Display pending
        </Ds.Badge>
    ),
    [RecordViewResultStatus.RequestFailed]: (
        <Ds.Badge color="red" className="text-nowrap">
            Display failed
        </Ds.Badge>
    ),
};

/**
 * Returns a badge if the status matches or nothing otherwise
 */
export const RecordStatusBadge = ({
    status,
}: Pick<RecordViewRequestResult, "status">): JSX.Element => {
    return Badges[status] ?? <></>;
};
