import { Badge } from "@accurx/design";
import styled from "styled-components";

export const StyledBadge = styled(Badge).attrs(() => ({
    colour: "blue",
    theme: "solid",
    luminosity: "low",
    radius: "full",
}))`
    display: block;
    padding: 6px; // This is non-standard as it makes the circle 12px which is standard
`;
