import { useCallback } from "react";

import { VideoConsultLinkItem } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { ConversationItemComponent } from "domains/inbox/components/ConversationItem/types";
import { copyToClipboard } from "domains/inbox/util/copyToClipboard";
import { toast } from "react-toastify";

import {
    StyledActionsContainer,
    StyledSubheader,
    StyledVideoConsultLinkNoteContainer,
} from "./VideoConsultLinkNote.styles";

export const VideoConsultLinkNote = ({
    item,
}: ConversationItemComponent<VideoConsultLinkItem>) => {
    const copyLinkToClipboard = useCallback(async () => {
        const { success } = await copyToClipboard(item.clinicianUrl);
        if (success) {
            toast(
                <UI.Feedback
                    title="Link copied to clipboard"
                    colour="success"
                />,
            );
        } else {
            toast(
                <UI.Feedback
                    title="Couldn't copy link to clipboard"
                    colour="error"
                />,
            );
        }
    }, [item.clinicianUrl]);

    return (
        <StyledVideoConsultLinkNoteContainer>
            <StyledSubheader>
                <b>Ready to join the video consultation?</b>
            </StyledSubheader>
            <p>
                If you're joining from your smartphone, click the link in the
                SMS message that we've sent you.
            </p>
            <StyledActionsContainer>
                <UI.Button
                    onClick={() => {
                        void (async () => {
                            await copyLinkToClipboard();
                        })();
                    }}
                    theme="secondary"
                    text="Copy link"
                    icon={{ name: "Copy" }}
                ></UI.Button>
                <UI.Link href={item.clinicianUrl} openInNewTab>
                    <UI.Link.Text text="Join video call" />
                    <span aria-hidden="true">
                        <UI.Link.Icon />
                    </span>
                </UI.Link>
            </StyledActionsContainer>
            <StyledSubheader>
                <b>Having trouble connecting?</b>
            </StyledSubheader>
            <p>
                You need a webcam, microphone and you should use Google Chrome,
                Mozilla Firefox or Microsoft Edge. If you are on a phone call,
                hang up so video consult can access your microphone.
            </p>
        </StyledVideoConsultLinkNoteContainer>
    );
};
