import React from "react";

import * as UI from "@accurx/design";
import { useCanMarkConversationUnread } from "domains/inbox/hooks/util";

import { useConversationPreviewActionsContext } from "./PreviewActions";

export const MarkAsUnread = () => {
    const { analytics, conversation, markAsUnread, onActionCompleted } =
        useConversationPreviewActionsContext();
    const showMarkUnread = useCanMarkConversationUnread(conversation);

    const handleMarkAsUnread = () => {
        analytics.trackConversationActionButtonClick("Unread");
        markAsUnread();
    };

    const handleOnClickUnread = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        handleMarkAsUnread();
        onActionCompleted();
    };

    if (!showMarkUnread) return null;
    return (
        <UI.PopoverItemButton
            onClick={handleOnClickUnread}
            aria-label={"Mark conversation as unread"}
        >
            <UI.Icon name={"Unread"} theme="Line" size={3} />
            <UI.Text as="span" skinny colour="zinc" variant="note">
                Mark unread
            </UI.Text>
        </UI.PopoverItemButton>
    );
};
