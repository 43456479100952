import * as UI from "@accurx/design";

import {
    ErrorPositioningWrapper,
    SpacingWrapper,
    StyledSearchSelect,
} from "../SelfbookConfigurationForm.styles";

type SlotTypeProps = {
    options: UI.Option[];
    initialOption?: UI.Option;
    weeksAvailable: number;
    onChange(selected: UI.Option): void;
    errors: string[] | undefined;
};

export const SlotType = ({
    options,
    initialOption,
    weeksAvailable,
    onChange,
    errors,
}: SlotTypeProps) => {
    const label = "Slot type";
    const id = "slot-type-filter";
    return (
        <SpacingWrapper>
            <StyledSearchSelect>
                <UI.FormFieldWrapper
                    label={
                        <UI.Text variant="note" skinny>
                            {label}
                        </UI.Text>
                    }
                    labelProps={{ htmlFor: id }}
                    subLabel={
                        <UI.Text variant="preview">{`Patients can book any available appointment within the next ${weeksAvailable} weeks.`}</UI.Text>
                    }
                    errors={errors?.map((item) => {
                        return (
                            <ErrorPositioningWrapper>
                                <UI.Text variant="preview">{item}</UI.Text>
                            </ErrorPositioningWrapper>
                        );
                    })}
                >
                    <UI.SearchSelect
                        dimension="medium"
                        id={id}
                        label={label}
                        key={`${id}-${
                            initialOption ? initialOption.value : "none"
                        }`}
                        options={options}
                        // Types here are inaccurate, returns Option for single select
                        onChangeHandler={(selected) =>
                            onChange(selected as UI.Option)
                        }
                        initSelectedValues={initialOption}
                        error={errors && errors.length > 0}
                        zIndex={2}
                    />
                </UI.FormFieldWrapper>
            </StyledSearchSelect>
        </SpacingWrapper>
    );
};
