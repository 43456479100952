/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { getTeamNotifications, postTeamNotifications } from "api/PracticesApi";
import { CoreActions } from "shared/constants/actionTypes";

export function loadTeams(practiceId: string) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: CoreActions.LOAD_PRACTICE_TEAMS });

        const res = await getTeamNotifications(practiceId);
        if (res.success && res.result) {
            dispatch({
                type: CoreActions.LOAD_PRACTICE_TEAMS_SUCCESS,
                response: res.result,
            });
            return;
        }

        dispatch({ type: CoreActions.LOAD_PRACTICE_TEAMS_FAIL });
    };
}

export function updateTeamMembership(
    practiceId: string,
    user: any,
    team: any,
    isMember: boolean,
) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CoreActions.UPDATE_TEAM_MEMBERSHIP,
            user: user.id,
            team: team.id,
        });

        const updatedMembership = {
            userId: user.id,
            teamId: team.id,
            isMember,
        };

        const res = await postTeamNotifications(practiceId, updatedMembership);
        if (res.success) {
            dispatch({
                type: CoreActions.UPDATE_TEAM_MEMBERSHIP_SUCCESS,
                userId: user.id,
                teamId: team.id,
                isMember,
            });
            return;
        }

        dispatch({
            type: CoreActions.UPDATE_TEAM_MEMBERSHIP_FAIL,
            userId: user.id,
            teamId: team.id,
        });
        toast.error(`Could not add ${user.name} to ${team.name}`);
    };
}
