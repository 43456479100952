import { Tokens } from "@accurx/design";
import styled from "styled-components";

export type TextWithHaloColours = "red" | "green" | "silver";

export const StyledTextWithHalo = styled.div<{
    $colour: TextWithHaloColours;
}>`
    background-color: ${({ $colour }) => {
        switch ($colour) {
            case "red":
                return Tokens.COLOURS.primary.red[25];
            case "green":
                return Tokens.COLOURS.primary.green[25];
            case "silver":
            default:
                return Tokens.COLOURS.greyscale.silver;
        }
    }};

    padding: ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1]};
    border-radius: 99px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: 2.5rem;
`;
