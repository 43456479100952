import { Flex, Tokens } from "@accurx/design";
import * as Toolbar from "@radix-ui/react-toolbar";
import styled from "styled-components";

const { COLOURS, BORDERS, SIZES } = Tokens;

export const ToolbarContainer = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -20%);
    z-index: 1000;
`;

export const ToolbarRoot = styled(Toolbar.Root)`
    min-width: max-content;
    width: 100%;

    background: ${COLOURS.primary.green[75]};
    box-shadow: 0 2px ${SIZES[1]} ${COLOURS.greyscale.stone};

    border-radius: ${BORDERS.radius};
    padding: ${SIZES[1]};

    display: flex;
`;

export const ButtonGroup = styled(Flex)`
    justify-content: flex-end;
    flex: 1;
    gap: ${Tokens.SIZES[2]};
`;
