import { getNextUrlOrDefault } from "@accurx/navigation";
import { SetUserTraits } from "@accurx/shared/dist/Analytics/RudderStackAnalyticsTracker";
import { shallowEqual } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { ROUTES } from "shared/Routes";
import { NEXT_URL_QUERY_PARAM, nextUrlMatchesRoute } from "shared/RoutesHelper";
import UserSettingsService from "shared/storage/UserSettingsService";
import { useAppSelector } from "store/hooks";

export const IS_NEW_USER_PARAM = "isNewUser";

/**
 * When a new user is created, we want to track what prompted them to visit
 * Accurx web for the first time. When a new user is created as part of a
 * NHSMail SSO, the `isNewUser` parameter is added to the url by the server
 * with a value of `true`. If this parameter is present, the `next` url will be
 * examined if it exists. If a reasonable source for the user can be established
 * from the `next` url, a user trait (`source`) will be added to the user in
 * MixPanel.
 * The parameter will be removed in the returned value.
 * @param search The search part of the url
 * @returns an object containing the search part of the url after the `isNewUser`
 * param is removed (if it exists)
 */
const trackNewUserSource = (search: string): { search: string } => {
    const setSource = (source: "ClinicianConversation" | "EPRQuickLaunch") => {
        SetUserTraits({ source });
    };

    const params = new URLSearchParams(search);

    if (params.get(IS_NEW_USER_PARAM) === "true") {
        params.delete(IS_NEW_USER_PARAM);

        if (nextUrlMatchesRoute(search, ROUTES.reply_from_web)) {
            setSource("ClinicianConversation");
            return { search: params.toString() };
        }

        const next = params.get(NEXT_URL_QUERY_PARAM);
        if (next) {
            if (next.includes("EPRQuickLaunch")) {
                setSource("EPRQuickLaunch");
            }
        }
    }

    return { search: params.toString() };
};

/**
 * Landing page for Accurx Web which simply redirects the user to where they should
 * go next
 */
export const PostLoginRedirect = (): JSX.Element => {
    const location = useLocation();
    const accountState = useAppSelector((state) => state.account, shallowEqual);

    // If there is a ReturnUrl param then we have come from a server rendered page
    // e.g. PatientUploadController, which required login. Therefore we need to
    // redirect back to that server rendered page after login.
    const params = new URLSearchParams(location.search);
    const serverReturnUrl = params.get("ReturnUrl");
    if (serverReturnUrl) {
        window.location.replace(serverReturnUrl);
        return <></>;
    }

    const nextRouteAfterLogin = (): {
        pathname: string;
        search: string;
        state: { from: string };
    } => {
        const isFirstTimeUser =
            accountState.user &&
            UserSettingsService.getIsFirstTimeUser(accountState.user);
        // For first time users, we don't want to hit them with 2FA before
        // they've see the product, for other users go to the 2FA page
        const defaultDestination = isFirstTimeUser
            ? ROUTES.home
            : ROUTES.two_factor_auth;

        // Track the user's source while we still have the next parameter
        const { search: nextSearch } = trackNewUserSource(location.search);

        // However if there is a "next" parameter in the URL that is the location we want
        const { pathname, search: finalSearch } = getNextUrlOrDefault(
            nextSearch,
            defaultDestination,
        );

        return {
            pathname,
            search: finalSearch,
            state: { from: location.pathname },
        };
    };

    return <Redirect to={nextRouteAfterLogin()} />;
};
