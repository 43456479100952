import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";
import { useMatchTicketToPatientMutation } from "@accurx/concierge/hooks/mutations/useMatchTicketToPatientMutation";
import { Conversation } from "@accurx/concierge/types";
import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { QuickViewPortal } from "@accurx/quick-view";
import { PatientCard } from "domains/inbox/components/PatientSearch/components/PatientCard";
import { PatientSearchConfirmRadioButton } from "domains/inbox/components/PatientSearch/components/forms/PatientSearchConfirmRadioButton";
import { PatientSearchResult } from "domains/inbox/components/PatientSearch/types";
import { useConversationAnalyticsFields } from "domains/inbox/hooks/util/useConversationAnalyticsFields";

import { StyledDivider, StyledPatientMatchSpacer } from "./PatientMatch.styles";

export type PatientMatchResultForRadio = PatientSearchResult & {
    /** A unique value, can be anything we can uniquely select a patient with */
    uniqueId: string;
};

const ERROR_DESCRIBED_BY_IDS = {
    api: "server-error",
    noPatientSelected: "no-patient-selected",
} as const;

export const PatientMatchResultsForm = ({
    results,
    onMatchConfirmSuccess,
    conversation,
}: {
    results: PatientMatchResultForRadio[];
    onMatchConfirmSuccess: () => void;
    conversation: Conversation;
}) => {
    const matchTicketToPatientMutation = useMatchTicketToPatientMutation();
    const [showNoPatientSelectedError, setShowNoPatientSelectedError] =
        useState(false);
    const [selectedPatientId, setSelectedPatientId] = useState(
        results.length === 1 ? results[0].uniqueId : "",
    );

    const track = useAnalytics();
    const trackingFields = useConversationAnalyticsFields({
        conversation,
    });

    return (
        <>
            <QuickViewPortal.Body>
                <StyledPatientMatchSpacer>
                    <UI.Flex flexDirection="column" gap="1">
                        <UI.Text as="h2" variant="label" skinny>
                            Confirm patient match
                        </UI.Text>
                        <UI.Text skinny>
                            Please double check this is the patient you searched
                            to match this conversation.
                        </UI.Text>
                    </UI.Flex>
                </StyledPatientMatchSpacer>
                <StyledDivider />
                <StyledPatientMatchSpacer>
                    <UI.Flex flexDirection={"column"} gap="1">
                        {matchTicketToPatientMutation.status === "error" && (
                            <UI.Feedback
                                props={{
                                    id: ERROR_DESCRIBED_BY_IDS.api,
                                    "aria-live": "polite",
                                }}
                                colour="error"
                                title="Sorry, something went wrong. Please try again."
                            />
                        )}
                        {showNoPatientSelectedError && (
                            <UI.Feedback
                                props={{
                                    id: ERROR_DESCRIBED_BY_IDS.noPatientSelected,
                                    "aria-live": "polite",
                                }}
                                colour="error"
                                title="Please select a patient"
                            />
                        )}
                        <form
                            aria-label="Select a patient to match to the conversation"
                            id="select-patient-form"
                            aria-describedby={
                                matchTicketToPatientMutation.isError
                                    ? ERROR_DESCRIBED_BY_IDS.api
                                    : undefined
                            }
                        >
                            <fieldset
                                aria-describedby={
                                    showNoPatientSelectedError
                                        ? ERROR_DESCRIBED_BY_IDS.noPatientSelected
                                        : undefined
                                }
                            >
                                <UI.VisuallyHidden as="legend">
                                    <UI.Text
                                        skinny
                                        variant="label"
                                        colour="night"
                                    >
                                        Select patient to match to conversation
                                    </UI.Text>
                                </UI.VisuallyHidden>
                                <UI.Flex flexDirection={"column"} gap="1">
                                    {results.map(({ uniqueId, ...patient }) => (
                                        <PatientSearchConfirmRadioButton
                                            key={`${uniqueId}`}
                                            value={uniqueId}
                                            label={<PatientCard {...patient} />}
                                            checkedValue={selectedPatientId}
                                            onChange={() =>
                                                setSelectedPatientId(uniqueId)
                                            }
                                        />
                                    ))}
                                </UI.Flex>
                            </fieldset>
                        </form>
                    </UI.Flex>
                </StyledPatientMatchSpacer>
            </QuickViewPortal.Body>
            <QuickViewPortal.Footer>
                <UI.Flex justifyContent="flex-end">
                    <Pill.PrimaryButton
                        type="submit"
                        form="select-patient-form"
                        dimension="small"
                        isLoading={matchTicketToPatientMutation.isLoading}
                        onClick={(e) => {
                            track(
                                "ConversationPatientMatchConfirm Button Click",
                                trackingFields,
                            );
                            e.preventDefault();
                            if (!selectedPatientId) {
                                setShowNoPatientSelectedError(true);
                                return;
                            }
                            const foundPatient = results.find(
                                (p) => p.uniqueId === selectedPatientId,
                            );

                            matchTicketToPatientMutation.mutate(
                                {
                                    patientExternalIds:
                                        foundPatient?.externalIds,
                                    patientToken: foundPatient?.patientToken,
                                    conversation,
                                },
                                {
                                    onSuccess: () => {
                                        onMatchConfirmSuccess();
                                    },
                                    onSettled: (_data, error) =>
                                        track(
                                            "ConversationPatientMatchConfirm Button Response",
                                            {
                                                ...trackingFields,
                                                hasError: !!error,
                                            },
                                        ),
                                },
                            );
                        }}
                    >
                        <Pill.Icon size={3} name="Done" colour="white" />
                        <Pill.Text>Confirm</Pill.Text>
                    </Pill.PrimaryButton>
                </UI.Flex>
            </QuickViewPortal.Footer>
        </>
    );
};
