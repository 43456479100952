import { Dispatch, SetStateAction } from "react";

import { Checkbox } from "@accurx/design";

import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { isContactable } from "app/patientLists/integratedClinics/shared/isContactable";

import AppointmentCard from "../../../shared/components/AppointmentCard";
import { SelectedAppointments } from "../SelectClinicPatients";

type SelectableAppointmentCardProps = {
    appointment: ClinicAppointment;
    isSelected: boolean;
    setSelected: Dispatch<SetStateAction<SelectedAppointments>>;
};

export default function SelectableAppointmentCard({
    appointment,
    isSelected,
    setSelected,
}: SelectableAppointmentCardProps) {
    const { id } = appointment;
    const checkbox = (
        <Checkbox
            id={id.toString()}
            text="Select patient"
            theme="bordered"
            disabled={!isContactable(appointment)}
            checked={isSelected}
            onCheckChange={(isChecked) => {
                setSelected((previousMap) => {
                    if (isChecked) {
                        previousMap.set(id, appointment);
                    } else {
                        previousMap.delete(id);
                    }

                    return new Map(previousMap);
                });
            }}
        />
    );

    return (
        <AppointmentCard actionButton={checkbox} appointment={appointment} />
    );
}
