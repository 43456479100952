import { useAnalytics as hook } from "@accurx/analytics";

import { useSelectedOrganisation } from "./SelectedOrganisationProvider";

/** Returns a track function form the AnalyticsProvider, scoped to the current selectedOrgId.
 * This is necessary while not all of fleming client pages live on routes with a workspaceId.
 */
export const useAnalytics = () => {
    const { selectedOrgId } = useSelectedOrganisation();

    const track = hook({ workspaceId: selectedOrgId });

    return track;
};
