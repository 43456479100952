export type EmbedMode = "Web" | "Desktop";

/**
 * "Embed mode" refers to wether you're accessing the app directly from a
 * browser "Web" or via the Accurx desktop toolbar "Desktop". In realterms this
 * is decided based on wether the browser is WebView2 or not.
 *
 * In general the Unified Inbox should be agnostic to wether you're web or
 * desktop embedded. In most cases we should only care about wether you have a
 * connection to the medical record or not. Before using this, consider using
 * `useMedicalRecordConnection()` instead.
 */
export const getEmbedMode = (): { embedMode: EmbedMode } => {
    if (window.chrome?.webview) {
        return { embedMode: "Desktop" };
    }

    return { embedMode: "Web" };
};
