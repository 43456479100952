import { MeaningfulActionProps } from "@accurx/analytics";
import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    FlemingLoggedInCustomProperties,
    IsTestPatientProps,
} from "./FlemingAnalyticsConstants";
import { reduceToFlemingLoggedInCustomProperties } from "./FlemingEventTracker";
import { ConversationGroup } from "./SharedInboxEvents.constants";

type HasError = { hasError: boolean };

export type PatientConversationInitiatedByType =
    | "ClinicianInitiated"
    | "PatientInitiated"
    | "Unknown";

/** List of shared props which can be picked for the several events */
export type PatientConversationSharedProps = {
    /** The unique ID of the conversation */
    conversationId: string;
    /** Whether user has PIFU feature flag enabled */
    isPatientInitiatedFollowUpUser: boolean;
    /** Whether the conversation was initiated by the clinician or the patient */
    conversationInitiatedBy: PatientConversationInitiatedByType;
    /** Number of messages that make up a conversation */
    conversationMessageCount: number;
    /** Start time of the first message in a conversation */
    conversationStartTimestampUTC: string;
};

export type PatientConversationListItemClickProps =
    FlemingLoggedInCustomProperties & {
        isConversationUnread: boolean;
        conversationDisplayStatus: string;
        conversationGroup: keyof typeof ConversationGroup | "Other";
        conversationId: string;
    };

/**
 * Conversation in foundational inbox v2 is clicked
 */
export const trackPatientConversationListitemClicked = (
    props: PatientConversationListItemClickProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationSummary",
        objectType: EventObjectType.ListItem,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...reduceToFlemingLoggedInCustomProperties(props),
            isConversationUnread: props.isConversationUnread,
            conversationDisplayStatus: props.conversationDisplayStatus,
            conversationGroup: props.conversationGroup,
            conversationId: props.conversationId,
        },
    });
};

/** The match status of a conversation in relation to whether the conversation has been linked to a patient
 * Relevant when it comes to patient initiated requests
 */
export type PatientConversationMatchStatus =
    | "Matched"
    | "Suggested"
    | "Unmatched"
    | "Unknown";

export type PatientConversationPageLoadProps = FlemingLoggedInCustomProperties &
    IsTestPatientProps &
    Pick<
        PatientConversationSharedProps,
        | "conversationId"
        | "conversationInitiatedBy"
        | "isPatientInitiatedFollowUpUser"
        | "conversationMessageCount"
    > &
    AssignmentProps & {
        isDone: boolean;
        matchStatus: PatientConversationMatchStatus;
    };

export const trackPatientConversationPageLoad = (
    props: PatientConversationPageLoadProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversation",
        objectType: EventObjectType.Page,
        action: EventAction.Load,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

export type AssigneeType = "Team" | "UserSelf" | "UserOther" | "None";
type AssignmentProps = { assigneeType: AssigneeType };

type PatientConversationMarkAsDoneProps = FlemingLoggedInCustomProperties &
    HasError &
    MeaningfulActionProps &
    AssignmentProps &
    Pick<
        PatientConversationSharedProps,
        | "conversationId"
        | "conversationInitiatedBy"
        | "conversationMessageCount"
        | "conversationStartTimestampUTC"
    >;

/**
 * Mark as done button is clicked
 */
export const trackPatientConversationMarkAsDoneButtonClick = (
    props: PatientConversationMarkAsDoneProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationDone",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Mark as done response
 */
export const trackPatientConversationMarkAsDoneButtonResponse = (
    props: PatientConversationMarkAsDoneProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationDone",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

type PatientConversationReOpenProps = FlemingLoggedInCustomProperties &
    HasError &
    MeaningfulActionProps &
    AssignmentProps &
    Pick<
        PatientConversationSharedProps,
        "conversationId" | "conversationInitiatedBy"
    >;

/**
 * Re-open button is clicked
 */
export const trackPatientConversationReOpenButtonClick = (
    props: PatientConversationReOpenProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationReOpen",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Re-open response
 */
export const trackPatientConversationReOpenButtonResponse = (
    props: PatientConversationReOpenProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationReOpen",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

type PatientConversationAssignProps = FlemingLoggedInCustomProperties &
    HasError &
    Pick<PatientConversationSharedProps, "conversationId">;

/**
 * Assignment pill is clicked
 */
export const trackPatientConversationAssignButtonClick = (
    props: PatientConversationAssignProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationAssign",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

type PatientConversationAssignSelectMenuItemProps =
    FlemingLoggedInCustomProperties &
        HasError &
        MeaningfulActionProps &
        AssignmentProps & {
            hasSearched: boolean;
        } & Pick<PatientConversationSharedProps, "conversationId">; // Should we create a new props for this ?? Are we reusing it anywhere?

/**
 * Assignment button is clicked
 */
export const trackPatientConversationAssignSelectMenuItemClick = (
    props: PatientConversationAssignSelectMenuItemProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationAssignSelect",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};

/**
 * Assignment button response
 */
export const trackPatientConversationAssignSelectMenuItemResponse = (
    props: PatientConversationAssignSelectMenuItemProps,
): void => {
    GenericTrackEvent({
        object: "PatientConversationAssignSelect",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Response,
        properties: {
            eventVersion: 1,
            eventUserType: EventUserType.HealthcareProfessional,
            userIsLoggedIn: true,
            ...props,
        },
    });
};
