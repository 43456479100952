import React from "react";

import { StackPanel } from "@accurx/design";

import { Template } from "./ManageTemplates.types";
import { SendTypeSwitches } from "./SendTypeSwitches";

export const AvailabilityCellContent = ({
    orgId,
    template,
}: {
    orgId: string;
    template: Template;
}) => {
    return (
        <StackPanel
            orientation="vertical"
            horizontalContentAlignment="left"
            gutter={1}
        >
            <SendTypeSwitches orgId={orgId} template={template} />
        </StackPanel>
    );
};
