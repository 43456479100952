/* eslint-disable */
/* tslint:disable */
/*
 * -------------------- /!\ WARNING /!\ --------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API  ##
 * ## DO NOT EDIT MANUALLY                                ##
 * ## YOUR CHANGES WILL BE OVERWRITTEN                    ##
 * -------------------- /!\ WARNING /!\ --------------------
 */

export interface FloreyScoreResponse {
    /** @format int32 */
    floreyEnrolmentId: number;
    /** @format date-time */
    sentAt: string;
    patient: PatientThreadPatientDetails;
    status: FloreyScoreStatus;
    complexity: Complexity;
    isValidated: boolean;
    isQuestionnaireSubmitted: boolean;
    ticketIdentity: TicketIdentity;
    responses: FloreyResponse[];
    floreyName: string;
    unvalidatedScore: Complexity;
    /** @format date-time */
    answeredAt: string;
    validations: FloreyScoreValidation[];
}

export type PatientThreadPatientDetails = PatientThreadPatientId & {
    names?: PatientName[] | null;
    gender?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    /** @format date-time */
    demographicsLastRefreshed?: string | null;
};

export interface PatientName {
    use: PatientNameUse;
    prefix?: string[] | null;
    given?: string[] | null;
    family?: string[] | null;
}

export enum PatientNameUse {
    None = 0,
    Usual = 1,
    Official = 2,
    Temp = 3,
    Nickname = 4,
    Anonymous = 5,
    Old = 6,
    Maiden = 7,
}

export interface PatientThreadPatientId {
    accuRxId?: string | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
}

export interface PatientExternalIdentityDto {
    patientExternalIds?: PatientExternalIdDto[] | null;
}

export interface PatientExternalIdDto {
    type: IdType;
    /** @minLength 1 */
    value: string;
}

export enum IdType {
    None = 0,
    NhsNumber = 1,
    Emis = 2,
    Dummy = 3,
    SystmOne = 4,
    Vision = 5,
    Hospital = 6,
}

export enum FloreyScoreStatus {
    Incomplete = "Incomplete",
    Expired = "Expired",
    FailedToDeliver = "FailedToDeliver",
    Pending = "Pending",
    Validated = "Validated",
}

export enum Complexity {
    Low = "Low",
    MediumHigh = "MediumHigh",
    Calculating = "Calculating",
    NotApplicable = "NotApplicable",
}

export interface TicketIdentity {
    type: TicketIdentityType;
    id?: string | null;
}

export enum TicketIdentityType {
    None = 0,
    SMS = 1,
    Note = 2,
    HealthcareEmail = 3,
    PatientEmail = 4,
    NhsAppMessage = 5,
}

export interface FloreyResponse {
    /** @format int32 */
    displaySection?: number | null;
    displayQuestionBold?: boolean | null;
    question?: string | null;
    answer?: FloreyAnswer | null;
}

export interface FloreyAnswer {
    displayAnswer?: string | null;
    floreyPatientAttachments?: FloreyPatientAttachment[] | null;
    floreyWriteToRecordItems?: FloreyWriteToRecordData[] | null;
}

export interface FloreyPatientAttachment {
    attachmentId?: string | null;
    event?: AttachmentEvent | null;
}

export interface AttachmentEvent {
    /** @format date-time */
    eventTime: string;
    userId?: string | null;
    isSoftDelete?: boolean | null;
    description?: string | null;
}

export interface FloreyWriteToRecordData {
    text?: string | null;
    code?: SnomedCode | null;
    numericDoubleItem?: NumericDoubleItem | null;
    writeToRecordType: FloreyResponseCodeWriteToRecordType;
}

export interface SnomedCode {
    conceptId?: string | null;
}

export interface NumericDoubleItem {
    /** @format double */
    value: number;
    unit?: string | null;
}

export enum FloreyResponseCodeWriteToRecordType {
    None = 0,
    Problem = 1,
    History = 2,
    Examination = 3,
    Comment = 4,
}

export interface FloreyScoreValidation {
    /** @format int32 */
    userId: number;
    userDisplayName: string;
    /** @format date-time */
    validatedAt: string;
    score: Score;
    additionalInformation?: string | null;
}

export enum Score {
    Low = "Low",
    MediumHigh = "MediumHigh",
    Unvalidated = "Unvalidated",
}

export interface FloreyScoresSummaryResponse {
    floreyScoreSummaries: FloreyScoreSummaryResponse[];
    floreyName: string;
}

export interface FloreyScoreSummaryResponse {
    /** @format int32 */
    floreyEnrolmentId: number;
    /** @format date-time */
    sentAt: string;
    patient: PatientThreadPatientDetails;
    status: FloreyScoreStatus;
    complexity: Complexity;
    isValidated: boolean;
    isQuestionnaireSubmitted: boolean;
    ticketIdentity: TicketIdentity;
    /** @format date-time */
    answeredAt?: string | null;
}

export interface FloreyScoreValidationRequest {
    /** @format int32 */
    floreyEnrolmentId: number;
    score: Score;
    additionalInformation?: string | null;
    /** @format int32 */
    organisationId: number;
}

export interface PatientThreadTicketCommandResult {
    isSuccess?: boolean | null;
    /** Item Updates from command. Usually 1 from successful command, but could be many in future changes, or could be 0 for a repeated idempotent command.  */
    updates?: PatientThreadItemUpdate[] | null;
}

/** Ensure we always inline referenced patient and user entities at the top level when sending explicit updates  */
export type PatientThreadItemUpdate = PatientThreadItemTransportPush & {
    /**
     * The scope of the update, pulled up from being embedded in the original item transport for more weakly
     * typed clients that want to check for routing ahead of mapping.
     * Nullable for backwards compatibility, expected to always be set as of Nov '22.
     * @format int32
     */
    organisationId?: number | null;
    patient?: PatientThreadPatientId | null;
    referencedPatients?: PatientThreadPatientDetails[] | null;
    users?: PatientThreadUser[] | null;
    userGroups?: PatientThreadUserGroup[] | null;
};

export interface PatientThreadUser {
    accuRxId?: string | null;
    email?: string | null;
    name?: string | null;
}

export interface PatientThreadUserGroup {
    id?: string | null;
    name?: string | null;
    groupType?: UserGroupType | null;
}

export enum UserGroupType {
    None = 0,
    Team = 1,
    FailedDeliveryReceipts = 2,
    Florey = 3,
    PatientSingleResponse = 4,
    ToAssign = 5,
    DefaultRoles = 6,
    PracticeGroup = 7,
}

export type PatientThreadItemTransportPush = PatientThreadItemTransport & {
    /**
     * Essential so Client can say for any given Item push whether that item should appear in a View they are rendering, and to ensure they can render the relevant information.
     * (without needing to either fetch the whole Ticket or the whole View again.)
     */
    ticketSummary?: TicketSummary | null;
};

export interface TicketSummary {
    identity?: TicketIdentity | null;
    /** The assignee of the ticket, currently null for single patient SMS/email messages. */
    assignee?: PatientThreadAssignee | null;
    /** Set for real tickets, currently null for single patient SMS/email/nhsApp messages. */
    isDone?: boolean | null;
    /**
     * Ticket urgency, some views sort urgent tickets at the top. Always null for single patient
     * SMS/email messages. Always null for tickets marked as Done, as no longer relevant.
     */
    isUrgent?: boolean | null;
    /** @format date-time */
    lastUpdated?: string | null;
    latestToken?: string | null;
    /**
     * The system ID for the patient. Should not be used alone, but for cross referencing the full
     * identity data in referenced patients.
     */
    patientId?: string | null;
}

export interface PatientThreadAssignee {
    type: AssigneeType;
    userId?: string | null;
    userGroupId?: string | null;
}

export enum AssigneeType {
    None = 0,
    User = 1,
    UserGroup = 2,
}

export interface PatientThreadItemTransport {
    /** Dictates which of the properties will be set (all others null)  */
    type: PatientThreadContentType;
    note?: PatientThreadNote | null;
    sms?: PatientThreadSMS | null;
    patientEmail?: PatientThreadEmail | null;
    nhsAppMessage?: PatientThreadNhsAppMessage | null;
    /** Includes FailedDeliveryReceipts and Video Consultations  */
    smsLinkNote?: PatientThreadSmsLinkNote | null;
    /** Includes FailedDeliveryReceipts and Video Consultations  */
    patientEmailLinkNote?: PatientThreadEmailLinkNote | null;
    /** Includes FailedDeliveryReceipts and Video Consultations  */
    nhsAppMessageLinkNote?: PatientThreadNhsAppMessageLinkNote | null;
    floreyResponseNote?: PatientThreadFloreyResponseNote | null;
    outgoingEmail?: ExternalEmailOutgoingMessage | null;
    incomingEmail?: ExternalEmailIncomingMessage | null;
    ticketStateChangeNote?: PatientThreadTicketStateChangeNote | null;
    patientThreadTriageRequestNote?: PatientThreadTriageRequestNote | null;
    genericNote?: PatientThreadGenericNote | null;
    patientThreadPatientAppointmentRequestNote?: PatientThreadPatientAppointmentRequestNote | null;
    conversationLabel?: PatientThreadLabel | null;
}

export enum PatientThreadContentType {
    None = 0,
    Note = 1,
    SMS = 2,
    SmsLinkNote = 3,
    FloreyResponseNote = 4,
    OutgoingEmail = 5,
    IncomingEmail = 6,
    StateChange = 7,
    PatientEmail = 8,
    PatientEmailLinkNote = 9,
    PatientTriageRequestNote = 10,
    GenericNote = 11,
    PatientAppointmentRequestNote = 12,
    NhsAppMessageLinkNote = 13,
    NhsAppMessage = 14,
    LabelTag = 15,
}

export type PatientThreadNote = PatientThreadItemOfPatientThreadContentNote &
    object;

export type PatientThreadItemOfPatientThreadContentNote = PatientThreadItem & {
    message?: PatientThreadContentNote | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentNote = PatientThreadContent & {
    type: PatientThreadContentType;
    createdByUserId?: string | null;
    segments?: NoteSegments | null;
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
};

export interface NoteSegments {
    texts?: NoteTextSegment[] | null;
    tags?: NoteTagSegment[] | null;
    userGroupTags?: NoteUserGroupTagSegment[] | null;
}

export type NoteTextSegment = NoteSegment & {
    type: SegmentType;
    text?: string | null;
};

export enum SegmentType {
    None = 0,
    Text = 1,
    Tag = 2,
    UserGroupTag = 3,
}

export interface NoteSegment {
    type: SegmentType;
    /** @format int32 */
    order: number;
}

export type NoteTagSegment = NoteSegment & {
    type: SegmentType;
    userId?: string | null;
};

export type NoteUserGroupTagSegment = NoteSegment & {
    type: SegmentType;
    userGroupId?: string | null;
};

export interface NoteUserState {
    userId?: string | null;
    hasRead: boolean;
    /** @format date-time */
    readAt?: string | null;
    isArchived: boolean;
    /** @format date-time */
    archivedAt?: string | null;
}

export interface NoteUserGroupState {
    userGroupId?: string | null;
    isArchived: boolean;
    /** @format date-time */
    archivedAt?: string | null;
    userIdsInGroupAtNoteCreate?: string[] | null;
}

export interface PatientThreadContent {
    type: PatientThreadContentType;
}

export interface PatientThreadItem {
    id?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    patientAccuRxId?: string | null;
    /** @format date-time */
    createdAt: string;
    type: PatientThreadContentType;
}

export type PatientThreadSMS = PatientThreadItemOfPatientThreadContentSMS &
    object;

export type PatientThreadItemOfPatientThreadContentSMS = PatientThreadItem & {
    message?: PatientThreadContentSMS | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentSMS = PatientThreadContentMessage & {
    type: PatientThreadContentType;
    mobileNumber?: string | null;
};

export type PatientThreadContentMessage = PatientThreadContent & {
    /** Null for Decision Support and Florey messages.  */
    sentByUserId?: string | null;
    messageBody?: string | null;
    deliveryStatus?: string | null;
    /** @format date-time */
    deliveryStatusUpdatedAt?: string | null;
    /**
     * Null if message not scheduled or is after message has been sent
     * @format date-time
     */
    deliveryScheduledAt?: string | null;
    /** This is the "external" SMS/Email id. Mapped from "IBasicPatientSms.AccuRxMessageId"  */
    accuRxMessageId?: string | null;
    /** Whether the patient thread content (email/SMS) has been written to the medical record.  */
    medicalRecordWriteState: MedicalRecordWriteStatus;
};

export enum MedicalRecordWriteStatus {
    Unknown = 0,
    Success = 1,
    Failed = 2,
}

export type PatientThreadEmail = PatientThreadItemOfPatientThreadContentEmail &
    object;

export type PatientThreadItemOfPatientThreadContentEmail = PatientThreadItem & {
    message?: PatientThreadContentEmail | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentEmail = PatientThreadContentMessage & {
    type: PatientThreadContentType;
    emailAddress?: string | null;
};

export type PatientThreadNhsAppMessage =
    PatientThreadItemOfPatientThreadContentNhsAppMessage & object;

export type PatientThreadItemOfPatientThreadContentNhsAppMessage =
    PatientThreadItem & {
        message?: PatientThreadContentNhsAppMessage | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentNhsAppMessage = PatientThreadContentMessage & {
    type: PatientThreadContentType;
};

export type PatientThreadSmsLinkNote =
    PatientThreadItemOfPatientThreadContentSmsLinkNote & object;

export type PatientThreadItemOfPatientThreadContentSmsLinkNote =
    PatientThreadItem & {
        message?: PatientThreadContentSmsLinkNote | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentSmsLinkNote = PatientThreadContentLinkNote & {
    type: PatientThreadContentType;
    /** Relates to the Id of a PatientThreadSMS.  */
    linkedSmsId?: string | null;
};

export type PatientThreadContentLinkNote = PatientThreadContent & {
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
    linkType: NoteLinkType;
    /**
     * Specific to LinkType of VideoConsult
     * @format uri
     */
    clinicianUrl?: string | null;
};

export enum NoteLinkType {
    None = 0,
    FailedDeliveryReceipt = 1,
    VideoConsult = 2,
}

export type PatientThreadEmailLinkNote =
    PatientThreadItemOfPatientThreadContentEmailLinkNote & object;

export type PatientThreadItemOfPatientThreadContentEmailLinkNote =
    PatientThreadItem & {
        message?: PatientThreadContentEmailLinkNote | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentEmailLinkNote = PatientThreadContentLinkNote & {
    type: PatientThreadContentType;
    /** Relates to the Id of a PatientThreadEmail.  */
    linkedEmailId?: string | null;
};

export type PatientThreadNhsAppMessageLinkNote =
    PatientThreadItemOfPatientThreadContentNhsAppMessageLinkNote & object;

export type PatientThreadItemOfPatientThreadContentNhsAppMessageLinkNote =
    PatientThreadItem & {
        message?: PatientThreadContentNhsAppMessageLinkNote | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentNhsAppMessageLinkNote =
    PatientThreadContentLinkNote & {
        type: PatientThreadContentType;
        /** Relates to the Id of a PatientThreadNhsAppMessage.  */
        linkedNhsAppMessageId?: string | null;
    };

/** Base id refers to the note id (NOT the Florey Enrolment id)  */
export type PatientThreadFloreyResponseNote =
    PatientThreadItemOfPatientThreadFloreyResponses & object;

export type PatientThreadItemOfPatientThreadFloreyResponses =
    PatientThreadItem & {
        message?: PatientThreadFloreyResponses | null;
        type: PatientThreadContentType;
    };

export type PatientThreadFloreyResponses = PatientThreadContent & {
    type: PatientThreadContentType;
    linkedSmsId?: string | null;
    linkedEmailId?: string | null;
    linkedNhsAppMessageId?: string | null;
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
    floreyName?: string | null;
    floreyUserGroupId?: string | null;
    isPatientSingleResponse: boolean;
    codes?: PatientThreadFloreyCode[] | null;
    responses?: FloreyResponse[] | null;
    writeToRecordState?: PatientThreadWriteToRecordState | null;
    reference?: FloreyReference | null;
    patientThreadMarkdown?: string | null;
};

export type PatientThreadFloreyCode = SnomedCode & {
    writeToRecordType: FloreyResponseCodeWriteToRecordType;
};

export interface PatientThreadWriteToRecordState {
    writeToRecordAttemptEvents?:
        | PatientThreadWriteToRecordAttemptEvent[]
        | null;
}

export type PatientThreadWriteToRecordAttemptEvent =
    PatientThreadWriteToRecordEvent & {
        writeSuccess: boolean;
    };

export interface PatientThreadWriteToRecordEvent {
    /** @format date-time */
    writeTime: string;
    userId?: string | null;
    patientAccuRxId?: string | null;
}

export interface FloreyReference {
    text?: string | null;
    link?: string | null;
}

export type ExternalEmailOutgoingMessage = ExternalEmailMessage & {
    type: ExternalEmailMessageType;
    sender?: PatientThreadUser | null;
    recipient?: ExternalEmailContact | null;
    /** @format date-time */
    sentTime?: string | null;
};

export enum ExternalEmailMessageType {
    None = 0,
    Outgoing = 1,
    Incoming = 2,
}

export interface ExternalEmailContact {
    id?: string | null;
    displayName?: string | null;
    email?: string | null;
    organisationName?: string | null;
    /** @deprecated */
    isHidden?: boolean | null;
    /** @deprecated */
    serviceName?: string | null;
    service?: ExternalEmailContactService | null;
    /** @deprecated */
    institutionName?: string | null;
    institution?: ExternalEmailContactInstitution | null;
    notes?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
    rowVersionBase64?: string | null;
    organisationContactDataRowVersionBase64?: string | null;
}

export interface ExternalEmailContactService {
    /** @format int32 */
    id: number;
    name?: string | null;
}

export interface ExternalEmailContactInstitution {
    /** @format int32 */
    id: number;
    name?: string | null;
}

export interface ExternalEmailMessage {
    id?: string | null;
    emailConversationId?: string | null;
    /** @format int32 */
    organisationId?: number | null;
    caseReference?: string | null;
    patientAccuRxId?: string | null;
    ticketIdentity?: TicketIdentity | null;
    type: ExternalEmailMessageType;
    bodyTextContent?: string | null;
    messageBody?: MessageBody | null;
    isTruncated?: boolean | null;
    writeToRecordState?: ExternalEmailWriteToRecordState | null;
    attachments?: ExternalEmailAttachment[] | null;
    otherRecipients?: ExternalEmailContact[] | null;
    userStates?: ExternalEmailUserState[] | null;
    archiveState?: ExternalEmailArchiveState | null;
}

export interface MessageBody {
    messagePrefix?: string | null;
    mainMessage?: string | null;
    previousMessages?: string | null;
}

export interface ExternalEmailWriteToRecordState {
    hasWrittenToRecord?: boolean | null;
    writtenByUserId?: string | null;
    writtenByUserName?: string | null;
    /** @format date-time */
    writeTime?: string | null;
}

export interface ExternalEmailAttachment {
    id?: string | null;
    displayName?: string | null;
}

export interface ExternalEmailUserState {
    userId?: string | null;
    hasRead: boolean;
    /** @format date-time */
    readAt?: string | null;
}

export interface ExternalEmailArchiveState {
    isArchived: boolean;
}

export type ExternalEmailIncomingMessage = ExternalEmailMessage & {
    type: ExternalEmailMessageType;
    sender?: ExternalEmailContact | null;
    recipient?: PatientThreadUser | null;
    /** @format date-time */
    receivedTime?: string | null;
    /** @format date-time */
    dateReadByGp?: string | null;
};

export type PatientThreadTicketStateChangeNote =
    PatientThreadItemOfPatientThreadContentTicketStateChangeNote & object;

export type PatientThreadItemOfPatientThreadContentTicketStateChangeNote =
    PatientThreadItem & {
        message?: PatientThreadContentTicketStateChangeNote | null;
        type: PatientThreadContentType;
    };

export type PatientThreadContentTicketStateChangeNote = PatientThreadContent & {
    type: PatientThreadContentType;
    state: TicketState;
    /** Represents the User who performed this action  */
    createdByUserId?: string | null;
    /** Null if Ticket is not not currently assigned.  */
    assignee?: PatientThreadAssignee | null;
    isLatestAssignee: boolean;
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
};

export enum TicketState {
    None = 0,
    Assignment = 1,
    Done = 2,
    ReOpened = 3,
    PatientMatch = 4,
    Urgent = 5,
    NonUrgent = 6,
    UnmatchPatient = 7,
}

/** Base id refers to the note id (NOT the patient triage request id)  */
export type PatientThreadTriageRequestNote =
    PatientThreadItemOfPatientThreadTriageRequest & object;

export type PatientThreadItemOfPatientThreadTriageRequest =
    PatientThreadItem & {
        message?: PatientThreadTriageRequest | null;
        type: PatientThreadContentType;
    };

export type PatientThreadTriageRequest = PatientThreadContent & {
    type: PatientThreadContentType;
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
    /** The type of the patient request  */
    patientRequestType?: string | null;
    /** Language in which the form has been translated to.  */
    translationLanguage?: string | null;
    initialRequestMatchState: MatchState;
    suggestedMatch?: PatientThreadSuggestedPatientDetails | null;
    responses?: FloreyResponse[] | null;
    writeToRecordState?: PatientThreadWriteToRecordState | null;
    patientTriageProxyInformation?: PatientThreadTriageProxyInformation | null;
    requestReceptionData?: RequestReceptionData | null;
};

export enum MatchState {
    None = 0,
    NoMatch = 1,
    Verified = 2,
    Suggested = 3,
}

export interface PatientThreadSuggestedPatientDetails {
    names?: PatientName[] | null;
    gender?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
    postCode?: string | null;
}

export interface PatientThreadTriageProxyInformation {
    proxyFirstName?: string | null;
    proxyLastName?: string | null;
    proxyPhoneNumber?: string | null;
    proxyRelationshipToPatient?: string | null;
    patientPreferredPhoneNumber?: string | null;
}

export interface RequestReceptionData {
    userId?: string | null;
}

export type PatientThreadGenericNote =
    PatientThreadItemOfPatientThreadGenericNoteContent & object;

export type PatientThreadItemOfPatientThreadGenericNoteContent =
    PatientThreadItem & {
        message?: PatientThreadGenericNoteContent | null;
        type: PatientThreadContentType;
    };

export type PatientThreadGenericNoteContent = PatientThreadContent & {
    markdown?: string | null;
    theme?: GenericNoteTheme | null;
    displaySender?: string | null;
    type: PatientThreadContentType;
};

export enum GenericNoteTheme {
    Information = 0,
    Warning = 1,
    Alarm = 2,
}

export type PatientThreadPatientAppointmentRequestNote =
    PatientThreadItemOfPatientThreadPatientAppointmentRequest & object;

export type PatientThreadItemOfPatientThreadPatientAppointmentRequest =
    PatientThreadItem & {
        message?: PatientThreadPatientAppointmentRequest | null;
        type: PatientThreadContentType;
    };

export type PatientThreadPatientAppointmentRequest = PatientThreadContent & {
    type: PatientThreadContentType;
    userStates?: NoteUserState[] | null;
    userGroupStates?: NoteUserGroupState[] | null;
    patientRequestType: PatientAppointmentRequestType;
    responses?: FloreyResponse[] | null;
    writeToRecordState?: PatientThreadWriteToRecordState | null;
};

export enum PatientAppointmentRequestType {
    None = 0,
    Reschedule = 1,
    Cancel = 2,
    GeneralEnquiry = 3,
}

export type PatientThreadLabel = PatientThreadItemOfPatientThreadContentLabel &
    object;

export type PatientThreadItemOfPatientThreadContentLabel = PatientThreadItem & {
    message?: PatientThreadContentLabel | null;
    type: PatientThreadContentType;
};

export type PatientThreadContentLabel = PatientThreadContent & {
    labelText?: string | null;
    createdByUserId?: string | null;
    type: PatientThreadContentType;
    writeToRecordState?: PatientThreadWriteToRecordState | null;
};

export type PatientTicketMarkDone = PatientTicketChangeStateBase & object;

export interface PatientTicketChangeStateBase {
    /** @format int32 */
    organisationId?: number | null;
    ticketIdentity?: TicketIdentity | null;
    latestToken?: string | null;
}

export type PatientTicketReOpenTicket = PatientTicketChangeStateBase & object;

export type PatientTicketMarkUrgent = PatientTicketChangeStateBase & object;

export type PatientTicketMarkNonUrgent = PatientTicketChangeStateBase & object;

export interface PatientThreadSummaryDetails {
    /** @format int32 */
    organisationId?: number | null;
    currentUser?: PatientThreadUser | null;
    currentOrganisationUserApprovalStatus?: OrganisationUserApprovalStatus | null;
    /** Approved users in the organisation (excludes archived).  */
    activeUsers?: PatientThreadUser[] | null;
    /** All Active UserGroups in the Organisation (i.e. those which have not been marked "Archived")  */
    activeUserGroups?: PatientThreadUserGroupUserMembership[] | null;
    /** Folders representing: their own and ALL Teams in the Practice  */
    folders?: PatientThreadTicketFolder[] | null;
    conversationOutcomeLabels?: Label[] | null;
}

export enum OrganisationUserApprovalStatus {
    None = 0,
    Unapproved = 1,
    Approved = 2,
    Archived = 3,
}

export type PatientThreadUserGroupUserMembership = PatientThreadUserGroup & {
    currentUserIsMember?: boolean | null;
    userHasConfirmedMembership?: boolean | null;
};

export interface PatientThreadTicketFolder {
    /** Serialised as integers must extend only */
    viewType: View;
    /** Required when ViewType is OpenTickets or Mentions  */
    userId?: string | null;
    /** Required when ViewType is OpenTickets and (Failed or PatientSingleResponse) - where the Id refers to the Failed Group  */
    userGroupId?: string | null;
}

/** Serialised as integers must extend only */
export enum View {
    None = 0,
    OpenTickets = 1,
    Mentions = 2,
    PracticeInbox = 3,
    Sent = 4,
    Failed = 5,
    Done = 6,
    Scheduled = 7,
    UserInbox = 8,
    UsersTeams = 9,
    OtherTeams = 10,
    PatientSingleResponse = 11,
    ToAssign = 12,
    UserSent = 13,
    UserFailed = 14,
    UserDone = 15,
    UserScheduled = 16,
    PatientTriage = 17,
    ToAssignTriageAdmin = 18,
    ToAssignTriageMedical = 19,
    PracticeInboxAndSent = 20,
    UserInboxMentionsAndSent = 21,
    PatientAppointmentRequest = 22,
    PracticeGroupTeams = 23,
    ToAssignTriageSelfReferral = 24,
}

export interface Label {
    code: string;
    name: string;
    /** Type stored in db as enum. Safe to rename. NOT SAFE to reorder.  */
    labelType: LabelType;
}

/** Type stored in db as enum. Safe to rename. NOT SAFE to reorder.  */
export enum LabelType {
    None = 0,
    Outcome = 1,
}

export interface PatientUploadSoftDelete {
    /** @format int32 */
    organisationId: number;
    /** @minLength 1 */
    noteId: string;
    /** @minLength 1 */
    attachmentId: string;
    patientId?: string | null;
    reasonForDeletion?: string | null;
}

export interface AssignmentStatisticsResponse {
    /** Array containing assignment statistics per organisation user/team */
    assigneeData: AssigneeData[];
}

export interface AssigneeData {
    /**
     * Id of user or userGroup.
     * Used to distinguish users or groups with same name.
     * @format int32
     */
    id: number;
    /** Full name of user or Display name of team/shared team */
    name: string;
    /** Type of user: User, Team, SharedTeam */
    type: AssigneeType2;
    /** User/Team assignment statistics */
    assignments: AssignmentData;
}

export enum AssigneeType2 {
    User = 0,
    Team = 1,
    SharedTeam = 2,
}

export interface AssignmentData {
    /** Statistics for currently assigned tickets */
    totalCurrent: CurrentAssignments;
    /** Assignment statistics for current day */
    totalToday: TodayAssignments;
}

export interface CurrentAssignments {
    assigned: AssignedData;
}

export type AssignedData = AssignmentDataBase & {
    /** Subset of total assignments which are marked as urgent */
    urgentData: UrgentData;
};

export type UrgentData = AssignmentDataBase & object;

export interface AssignmentDataBase {
    /**
     * Number of assigned Medical tickets
     * @format int32
     */
    medical: number;
    medicalCount: PatientTriageFlowTypeCount;
    /**
     * Number of assigned Admin tickets
     * @format int32
     */
    admin: number;
    adminCount: PatientTriageFlowTypeCount;
}

export interface PatientTriageFlowTypeCount {
    /** @format int32 */
    patientFlow: number;
    /** @format int32 */
    receptionFlow: number;
}

export interface TodayAssignments {
    /** Currently assigned statistics */
    assigned: AssignedData;
    /** Statistics for assignments marked as done */
    done: DoneData;
}

export type DoneData = AssignmentDataBase & object;

export interface OutcomeStatisticsResponse {
    /** Collection of all outcome labels */
    labels?: Label[] | null;
    /** Outcomes selected for a conversation/ticket. */
    outcomes?: OutcomeData[] | null;
}

export interface OutcomeData {
    /** @format date-time */
    date: string;
    outcome?: string | null;
    type?: string | null;
}

export interface PatientTicketCreate {
    /** @format int32 */
    organisationId?: number | null;
    patientId?: PatientExternalIdentityDto | null;
    segments?: NoteSegments | null;
    assignee?: PatientThreadAssignee | null;
}

export interface PatientTicketNoteCreate {
    /** @format int32 */
    organisationId?: number | null;
    patientId?: PatientExternalIdentityDto | null;
    ticketIdentity?: TicketIdentity | null;
    segments?: NoteSegments | null;
}

export interface UpdatePatientsRequest {
    /** @format int32 */
    organisationId?: number | null;
    patients?: UpdatePatientsPatient[] | null;
}

export interface UpdatePatientsPatient {
    patientExternalIdentity?: PatientExternalIdentityDto | null;
    names?: PatientName[] | null;
    gender?: string | null;
    /** @format date-time */
    dateOfBirth?: string | null;
}

export interface UpdatePatientIdentityResponse {
    patientId?: PatientThreadPatientId | null;
}

export interface UpdatePatientDetailsRequest {
    /** @format int32 */
    organisationId?: number | null;
    patient?: UpdatePatientsPatient | null;
}

export interface UpdatePatientIdentityRequest {
    /** @format int32 */
    organisationId?: number | null;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
}

/**
 * Represents update to Mark a note with id PatientThreadItemId as written to a 3rd party medical record
 * of patient PatientExternalIdentity in organisation OrganisationId
 */
export interface WriteToRecordUpdate {
    /** @format int32 */
    organisationId: number;
    patientExternalIdentity: PatientExternalIdentityDto;
    /** @minLength 1 */
    patientThreadItemId: string;
    /**
     * Nullable for retro-compatibility, if not explicit set to False we can assume as a success attempt
     * Last client to use this was 3.120, when ChainMinimumVersion is create than this we can require this property
     */
    writeToRecordSuccess?: boolean | null;
}

export interface PatientThreadUserGroupMembershipUpdate {
    /** @format int32 */
    organisationId?: number | null;
    userGroupId?: string | null;
    updatedMembership?: boolean | null;
}

export interface PatientThreadFolderTicketView {
    folder?: PatientThreadTicketFolder | null;
    /** These tickets are not "fully loaded" but have the parent Note (AND any unread notes)  */
    tickets?: PatientThreadTicketDetails[] | null;
    referencedPatients?: PatientThreadPatientDetails[] | null;
    users?: PatientThreadUser[] | null;
    userGroups?: PatientThreadUserGroup[] | null;
    /**
     * Supports pagination:
     * 1) If null - all tickets present! No need to request any more.
     * 2) If set - NOT ALL TICKETs present, this is an opaque continuation token which is a string indicating the LatestUpdate time for the earliest ticket returned.
     * Client can request more with ContinuationToken in another TicketFolderViewRequest.
     */
    continuationToken?: string | null;
}

export interface PatientThreadTicketDetails {
    ticketSummary?: TicketSummary | null;
    patient?: PatientThreadPatientDetails | null;
    isFullyLoaded?: boolean | null;
    items?: PatientThreadItemTransport[] | null;
}

export interface GetTicketFolderViewRequest {
    /** @format int32 */
    organisationId?: number | null;
    folder?: PatientThreadTicketFolder | null;
    continuationToken?: string | null;
}

export type PatientThreadAssignTicket = PatientTicketChangeStateBase & {
    assignee?: PatientThreadAssignee | null;
};

export type PatientThreadMatchTicketWithExternalIdentity =
    PatientThreadMatchTicket & {
        patientExternalIdentity?: PatientExternalIdentityDto | null;
    };

export interface PatientThreadMatchTicket {
    /** @format int32 */
    organisationId?: number | null;
    ticketIdentity?: TicketIdentity | null;
    latestToken?: string | null;
}

export type PatientThreadUnmatchTicket = PatientTicketChangeStateBase & object;

export type AddLabelRequest = PatientTicketChangeStateBase & {
    labelCode?: string | null;
    otherDescription?: string | null;
};

export interface GenericNoteRequest {
    /** @format int32 */
    organisationId?: number | null;
    /** @format int32 */
    patientId?: number | null;
    /** @format int32 */
    communicationId?: number | null;
    accurxMessageId?: string | null;
    communicationType?: UserToPatientCommunicationType | null;
    remoteItemId?: string | null;
    context?: string | null;
    consultationDetails?: string | null;
    markdown?: string | null;
    displaySender?: string | null;
    theme?: GenericNoteTheme2 | null;
}

export enum UserToPatientCommunicationType {
    Sms = 0,
    Email = 1,
    NhsAppMessage = 2,
}

export enum GenericNoteTheme2 {
    Information = 0,
    Warning = 1,
    Alarm = 2,
}

export interface PatientThreadPatientTickets {
    isEmpty: boolean;
    patient?: PatientThreadPatientDetails | null;
    tickets?: PatientThreadTicketDetails[] | null;
    referencedPatients?: PatientThreadPatientDetails[] | null;
    users?: PatientThreadUser[] | null;
    userGroups?: PatientThreadUserGroup[] | null;
}

export interface GetTicketsForPatientWithTokenRequest {
    /** @format int32 */
    organisationId: number;
    /**
     * Accessed by reflection in "PatientActionFilter" (to set Patient context)
     * @minLength 1
     */
    patientToken: string;
}

export interface PatientNoteTagsWithTokenRequest {
    /** @format int32 */
    organisationId: number;
    /**
     * Accessed by reflection in "PatientActionFilter" (to set Patient context)
     * @minLength 1
     */
    patientToken: string;
    patientThreadItemIds?: string[] | null;
}

export type PatientThreadTicket = PatientThreadTicketDetails & {
    referencedPatients?: PatientThreadPatientDetails[] | null;
    users?: PatientThreadUser[] | null;
    userGroups?: PatientThreadUserGroup[] | null;
};

export interface GetTicketWithPatientTokenRequest {
    /** @format int32 */
    organisationId?: number | null;
    ticketIdentity: TicketIdentity;
    /**
     * Accessed by reflection in "PatientActionFilter" (to set Patient context)
     * @minLength 1
     */
    patientToken: string;
}

export type GetPatientFolderTicketViewWithTokenRequest =
    GetTicketFolderViewRequest & {
        /**
         * Accessed by reflection in "PatientActionFilter" (to set Patient context)
         * @minLength 1
         */
        patientToken: string;
    };

export type PatientThreadMatchTicketWithToken = PatientThreadMatchTicket & {
    /**
     * Accessed by reflection in "PatientActionFilter" (to set Patient context)
     * @minLength 1
     */
    patientToken: string;
};

/**
 * Superset of PatientThreadSummaryDetails and PatientThreadInitialUnreadItems.
 * Used by Older Clients when single call to GetSummary.
 */
export interface PatientThreadTicketSummary {
    /** @format int32 */
    organisationId?: number | null;
    currentUser?: PatientThreadUser | null;
    currentOrganisationUserApprovalStatus?: OrganisationUserApprovalStatus | null;
    /** Approved users in the organisation (excludes archived).  */
    activeUsers?: PatientThreadUser[] | null;
    /** All UserGroups in the Organisation  */
    allOrganisationUserGroups?: PatientThreadUserGroupUserMembership[] | null;
    /** Folders representing: their own and ALL Teams in the Practice  */
    folders?: PatientThreadTicketFolder[] | null;
    unreadItems?: PatientThreadItemTransportPush[] | null;
    /** Users appearing in the unread notes. May include archived but is not all approved users. */
    referencedNoteUsers?: PatientThreadUser[] | null;
    referencedNotePatients?: PatientThreadPatientId[] | null;
    referencedNoteUserGroups?: PatientThreadUserGroup[] | null;
}

export interface PatientThreadInitialUnreadItems {
    unreadItems?: PatientThreadItemTransportPush[] | null;
    /** Identity information for all patients referenced by notes and tickets.  */
    referencedNotePatients?: PatientThreadPatientId[] | null;
    /** Users appearing in the unread notes. May include archived but is not all approved users. */
    referencedNoteUsers?: PatientThreadUser[] | null;
    referencedNoteUserGroups?: PatientThreadUserGroup[] | null;
}

/** Note: This DTO is used by an endpoint that does not require 2FA, if adding fields to this, consider if it is appropriate to return to non-2FAed users. */
export interface UnreadTickets {
    /**
     * This is used as the total unread count by the Accurx Web inbox, and is all tickets where the current
     * user has an unread note
     * @format int32
     */
    totalUnreadTicketCount: number;
    /**
     * This is used as the total unread count by the unified inbox, and is all tickets assigned to the current
     * user and any user group, where the current user has an unread note. I.e. it excludes tickets assigned to
     * other users
     * @format int32
     */
    currentUserAndGroupsUnreadTicketCount: number;
    unreadSummaries: UnreadTicketSummary[];
}

export interface UnreadTicketSummary {
    identity: TicketIdentity;
    patientId?: string | null;
    assignee: PatientThreadAssignee;
    latestToken: string;
    items: UnreadSummaryItemIdentity[];
}

export interface UnreadSummaryItemIdentity {
    id: string;
    type: PatientThreadContentType;
}

export interface GetTicketRequest {
    /** @format int32 */
    organisationId?: number | null;
    patient?: PatientExternalIdentityDto | null;
    ticketIdentity: TicketIdentity;
}

export interface GetTicketsForPatientRequest {
    /** @format int32 */
    organisationId?: number | null;
    patient?: PatientExternalIdentityDto | null;
}

export interface PatientNoteTags {
    patientExternalIdentity?: PatientExternalIdentityDto | null;
    /** @format int32 */
    organisationId?: number | null;
    patientThreadItemIds?: string[] | null;
}

export interface WriteCommunicationToRecordUpdate {
    /** @format int32 */
    organisationId: number;
    patientExternalIdentity: PatientExternalIdentityDto;
    /** These should be the value of Id for PatientThreadSMS or PatientThreadEmail. */
    communicationItemServerIds: string[];
    writeToRecordSuccess: boolean;
    communicationType: CommunicationMethod;
}

export enum CommunicationMethod {
    SMS = 0,
    Email = 1,
    NhsAppMessage = 2,
}

export interface PatientThreadDetails {
    referencedPatients: PatientThreadPatientDetails[];
}

export interface PatientsDetailsByExternalIdsRequest {
    /** @format int32 */
    organisationId?: number | null;
    patientsToLookup: PatientExternalIdentityDto[];
}

export interface PatientThreadFilteredTicketView {
    /**
     * These tickets are not "fully loaded" but have the "highlights" needed to render a useful summary in
     * current clients, which today is the identity item, the most recent item and any considered key for the
     * client (which may be context dependant).
     */
    tickets?: PatientThreadTicketDetails[] | null;
    /**
     * This holds identity and display details that we have for all referenced patients in the result.
     *
     * While uncommon, since an item could have previously been interacted with when it was matched to
     * a different patient, we can have details (e.g. write to record) which reference extra patients other
     * than the current patient match for containing tickets.
     */
    referencedPatients?: PatientThreadPatientDetails[] | null;
    /** This is the list of all referenced users in the result.  */
    users?: PatientThreadUser[] | null;
    /** This is the list of all referenced user groups in the result.  */
    userGroups?: PatientThreadUserGroup[] | null;
    /**
     * 1) If null - reached the end, no need to request any more.
     * 2) If set - NOT ALL TICKETs present, this is an opaque continuation token - the client can request more
     *    results for the same rules with ContinuationToken in another request until results are exhausted.
     */
    continuationToken?: string | null;
}

export interface GetFilteredTicketViewRequest {
    /** @format int32 */
    organisationId: number;
    /** If set, filter on assignee for tickets  */
    assignee?: PatientThreadAssignee | null;
    /** If set, filter on done status for tickets  */
    isDone?: boolean | null;
    /** If set, filter on patient id for tickets  */
    patientId?: PatientInternalIdentity | null;
    includePseudoTickets?: boolean | null;
    ordering: PatientThreadTicketOrdering;
    continuationToken?: string | null;
    /** If set, filter on the type of message that started the conversation  */
    startsWithItem?: StartsWithType | null;
}

export interface PatientInternalIdentity {
    /** @format int32 */
    id: number;
}

export interface PatientThreadTicketOrdering {
    urgentFirst: boolean;
}

/** Serialised as integers must extend only  */
export enum StartsWithType {
    None = 0,
    PatientTriageRequestAdmin = 1,
    PatientTriageRequestMedical = 2,
    PatientTriageRequestSelfReferral = 3,
}

export type GetPatientFolderTicketViewRequest = GetTicketFolderViewRequest & {
    patientExternalIdentity: PatientExternalIdentityDto;
};

export interface MarkTicketUnreadRequest {
    /** @format int32 */
    organisationId?: number | null;
    ticketIdentity: TicketIdentity;
    patientExternalIdentity?: PatientExternalIdentityDto | null;
}

export type AppointmentRequestsStatusResponse =
    AppointmentRequestStatusResponse & {
        patientAppointmentRequests: AppointmentRequestStatus[];
    };

export type AppointmentRequestStatus = AppointmentRequestStatusResponse & {
    /** @format int32 */
    patientAppointmentRequestId: number;
};

export interface AppointmentRequestStatusResponse {
    cancelRequestInProgress: boolean;
    rescheduleRequestInProgress: boolean;
    generalRequestInProgress: boolean;
}

export interface AppointmentRequestsStatusRequest {
    patientAppointmentRequestIds: number[];
    /** @format int32 */
    workspaceId: number;
}

export interface PatientTicketViewResponse {
    workspaceName?: string | null;
    ticket?: PatientThreadTicket | null;
}

export interface GetTicketContainingItemForPatientRequest {
    /** @format int32 */
    workspaceId: number;
    /** @format int32 */
    patientId: number;
    patientConversationSourceItem: PatientConversationSourceItem;
}

export interface PatientConversationSourceItem {
    type: PatientConversationSourceType;
    id?: string | null;
}

export enum PatientConversationSourceType {
    None = 0,
    PatientSms = 1,
    Note = 2,
    PatientEmail = 3,
    NhsAppMessage = 4,
}

export interface PatientNoteResponse {
    /** @format int32 */
    workspaceId: number;
    /** @format int32 */
    patientId: number;
    /** @format int32 */
    noteId: number;
}

export interface PatientConversationList {
    items: PatientConversation[];
}

export interface PatientConversation {
    identity: TicketIdentity;
    patientTicketWorkspace: PatientTicketWorkspaceDetails;
    messages: PatientConversationMessage[];
}

export interface PatientTicketWorkspaceDetails {
    /** @minLength 1 */
    name: string;
    /** @format int32 */
    id: number;
}

export interface PatientConversationMessage {
    /** Dictates which of the properties will be set (all others null)  */
    type: PatientThreadContentType;
    sms?: PatientConversationSms | null;
    email?: PatientConversationEmail | null;
    nhsAppMessage?: PatientConversationNhsAppMessage | null;
    floreyQuestionnaireResponse?: PatientConversationFloreyResponse | null;
    patientInitiatedRequest?: PatientConversationTriageRequest | null;
    patientAppointmentRequest?: PatientConversationAppointmentRequest | null;
}

export type PatientConversationSms = BaseMessageToPatient & object;

export type BaseMessageToPatient = BasePatientConversationMessage & {
    sentByUserId?: string | null;
    messageBody: string;
};

export interface BasePatientConversationMessage {
    /** @format date-time */
    createdAt: string;
}

export type PatientConversationEmail = BaseMessageToPatient & object;

export type PatientConversationNhsAppMessage = BaseMessageToPatient & object;

export type PatientConversationFloreyResponse =
    BasePatientConversationMessage & {
        /** Empty or null for timed out florey with no response. */
        responses?: FloreyResponse2[] | null;
    };

export interface FloreyResponse2 {
    question?: string | null;
    answer: string;
}

export type PatientConversationTriageRequest = BasePatientConversationMessage &
    object;

export type PatientConversationAppointmentRequest =
    BasePatientConversationMessage & object;
