import {
    FirstVaccinationFilterChoice,
    SecondVaccinationFilterChoice,
} from "../models/VaccineDeliveryDTO";

const FILTER_INVITEID_REGEX = /^inviteId:\d+\s*/i;

export enum FirstVaccinationWeekFilters {
    TwelvePlusWeeks,
    ElevenWeeks,
    TenWeeks,
    NineWeeks,
    EightWeeks,
    EightPlusWeeks,
    SevenWeeks,
    SixWeeks,
    FiveWeeks,
    FourWeeks,
    LessThanFourWeeks,
    ThreeWeeks,
    TwoWeeks,
    OneWeek,
    LessThanOneWeek,
}

export const twelvePlusWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.TwelvePlusWeeks,
    displayText: "12 + weeks",
    minDays: 7 * 12,
    maxDays: undefined,
};

export const elevenWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.ElevenWeeks,
    displayText: "11 weeks",
    minDays: 7 * 11,
    maxDays: 7 * 12 - 1,
};

export const tenWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.TenWeeks,
    displayText: "10 weeks",
    minDays: 7 * 10,
    maxDays: 7 * 11 - 1,
};

export const nineWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.NineWeeks,
    displayText: "9 weeks",
    minDays: 7 * 9,
    maxDays: 7 * 10 - 1,
};

export const eightPlusWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.EightPlusWeeks,
    displayText: "8 + weeks",
    minDays: 7 * 8,
    maxDays: undefined,
};

export const eightWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.EightWeeks,
    displayText: "8 weeks",
    minDays: 7 * 8,
    maxDays: 7 * 9 - 1,
};

export const sevenWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.SevenWeeks,
    displayText: "7 weeks",
    minDays: 7 * 7,
    maxDays: 7 * 8 - 1,
};

export const sixWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.SixWeeks,
    displayText: "6 weeks",
    minDays: 7 * 6,
    maxDays: 7 * 7 - 1,
};

export const fiveWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.FiveWeeks,
    displayText: "5 weeks",
    minDays: 7 * 5,
    maxDays: 7 * 6 - 1,
};

export const fourWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.FourWeeks,
    displayText: "4 weeks",
    minDays: 7 * 4,
    maxDays: 7 * 5 - 1,
};

export const lessThanFourWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.LessThanFourWeeks,
    displayText: "< 4 weeks",
    minDays: undefined,
    maxDays: 7 * 4 - 1,
};

export const threeWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.ThreeWeeks,
    displayText: "3 weeks",
    minDays: 7 * 3,
    maxDays: 7 * 4 - 1,
};

export const twoWeeks: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.TwoWeeks,
    displayText: "2 weeks",
    minDays: 7 * 2,
    maxDays: 7 * 3 - 1,
};

export const oneWeek: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.OneWeek,
    displayText: "1 week",
    minDays: 7,
    maxDays: 7 * 2 - 1,
};

export const lessThanOneWeek: FirstVaccinationFilterChoice = {
    id: FirstVaccinationWeekFilters.LessThanOneWeek,
    displayText: "< 1 week",
    minDays: undefined,
    maxDays: 6,
};

export const firstVaccinationWeeksFilterChoices: FirstVaccinationFilterChoice[] =
    [
        eightPlusWeeks,
        sevenWeeks,
        sixWeeks,
        fiveWeeks,
        fourWeeks,
        lessThanFourWeeks,
    ];

export interface AugmentedFilterChoice extends SecondVaccinationFilterChoice {
    addendumText: string | undefined;
}

const secondVaccinationFilterChoice = (
    minWeeks: number | null,
    maxWeeks: number | null,
): AugmentedFilterChoice => {
    const displayText = minWeeks
        ? maxWeeks
            ? `${minWeeks} - ${maxWeeks} weeks`
            : `${minWeeks} + weeks`
        : maxWeeks
        ? `< ${maxWeeks} weeks`
        : `No previous vaccination recorded`;

    return {
        key: `${minWeeks}-${maxWeeks}`,
        displayText: displayText,
        minDaysInclusive: minWeeks ? minWeeks * 7 : undefined,
        maxDaysExclusive: maxWeeks ? maxWeeks * 7 : undefined,
        addendumText:
            maxWeeks === null
                ? ", or no previous vaccinations recorded"
                : undefined,
    };
};

export const twentySixPlusWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(26, null);

export const twentyFourToTwentySixWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(24, 26);

export const thirteenToTwentySixWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(13, 26);

export const sixteenToTwentyFourWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(16, 24);

export const thirteenPlusWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(13, null);

export const upToThirteenWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(null, 13);

export const noVaccinesRecorded: AugmentedFilterChoice =
    secondVaccinationFilterChoice(null, null);

export const twelveToThirteenWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(12, 13);

export const tenToTwelveWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(10, 12);

export const eightToSixteenWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(8, 16);

export const eightToTenWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(8, 10);

export const upToEightWeeks: AugmentedFilterChoice =
    secondVaccinationFilterChoice(null, 8);

export const timeSincePrimaryVaccinationWeekChoicesFull: AugmentedFilterChoice[] =
    [
        twentySixPlusWeeks,
        twentyFourToTwentySixWeeks,
        sixteenToTwentyFourWeeks,
        eightToSixteenWeeks,
        upToEightWeeks,
    ];

export const timeSincePrimaryVaccinationWeekChoicesReduced: AugmentedFilterChoice[] =
    [
        thirteenPlusWeeks,
        twelveToThirteenWeeks,
        tenToTwelveWeeks,
        eightToTenWeeks,
        upToEightWeeks,
    ];

export const timeSinceLastVaccination: AugmentedFilterChoice[] = [
    twentySixPlusWeeks,
    thirteenToTwentySixWeeks,
    upToThirteenWeeks,
];

export const timeSinceLastAndNoneVaccination: AugmentedFilterChoice[] = [
    twentySixPlusWeeks,
    thirteenToTwentySixWeeks,
    upToThirteenWeeks,
    noVaccinesRecorded,
];

/**
 * Check if a searchTerm contains and inviteId
 * @param searchTerm string where we are looking for the inviteId
 */
export const isFilteredByInviteId = (
    searchTerm: string,
): RegExpMatchArray | null => {
    if (!searchTerm) {
        return null;
    }
    return searchTerm.match(FILTER_INVITEID_REGEX);
};
