import { Organisation } from "@accurx/auth";
import sortBy from "lodash/sortBy";

/**
 * Sorts an array of workspaces based on organisationNodeName and organisationName.
 */

export const sortWorkspaces = (workspaces: Organisation[]): Organisation[] => {
    return sortBy(
        workspaces,
        ({ organisationNodeName }) => organisationNodeName.toLowerCase(),
        ({ organisationName }) => organisationName.toLowerCase(),
    );
};
