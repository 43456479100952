import { Feedback, Link, Spinner, Text } from "@accurx/design";

import { ChainAnalyticsTracker } from "app/analytics";
import { PatientListImportCard } from "app/batchMessage/trust/pages/AddPatientsFromPatientList/components/PatientListImportCard";
import { usePatientListSummariesQuery } from "app/hooks/queries/usePatientListSummariesQuery";
import { Subheader } from "app/patientLists/Subheader";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { ROUTES_BASE } from "shared/Routes";

import { PatientListSummary } from "../../../../../../api/FlemingDtos";
import { useFlemingLoggedInAnalytics } from "../../../../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { BatchMessagePageLayout } from "../../../components/BatchMessagePageLayout";

type ChoosePatientListProps = {
    workspaceId: number | null;
    onChoosePatientList: (patientListId: number) => void;
};

export const ChoosePatientList = ({
    workspaceId,
    onChoosePatientList,
}: ChoosePatientListProps) => {
    const patientListSummariesQuery = usePatientListSummariesQuery({
        workspaceId,
    });

    const loggedInAnalyticsProps = useFlemingLoggedInAnalytics();
    const handleChoosePatientList = (patientList: PatientListSummary) => {
        onChoosePatientList(patientList.patientListId);
        ChainAnalyticsTracker.trackBatchPatientListImportButtonClick({
            ...loggedInAnalyticsProps,
            patientListType: patientList.listType,
            countPatient: patientList.patientCount,
        });
    };

    return (
        <BatchMessagePageLayout pageTitle="Import patient details from a list">
            {patientListSummariesQuery.status === "loading" && <Spinner />}
            {patientListSummariesQuery.status === "error" && (
                <Feedback
                    colour="error"
                    title="There was an error loading your patient lists"
                >
                    <Text skinny>Please refresh the page and try again</Text>
                </Feedback>
            )}
            {patientListSummariesQuery.status === "success" && (
                <>
                    <Text>
                        Select the patient list you want to import patient
                        details from
                    </Text>
                    <Subheader
                        icon="List"
                        text={`Lists you're involved in · ${patientListSummariesQuery.data.length}`}
                    />
                    {patientListSummariesQuery.data.length > 0 ? (
                        <StackPanel gutter={1}>
                            {patientListSummariesQuery.data.map((summary) => (
                                <PatientListImportCard
                                    summary={summary}
                                    onChoosePatientList={
                                        handleChoosePatientList
                                    }
                                    key={summary.patientListId}
                                />
                            ))}
                        </StackPanel>
                    ) : (
                        <Feedback colour="information" iconName="None">
                            <Text>
                                You’re not involved in any patient lists yet.
                                You can create one from the patient lists page.
                            </Text>
                            <Link
                                href={ROUTES_BASE.patientsListsAll}
                                text="Go to the patient lists page"
                                openInNewTab
                            />
                        </Feedback>
                    )}
                </>
            )}
        </BatchMessagePageLayout>
    );
};
