import { Ds, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledText = styled(Text)`
    margin: ${Tokens.SIZES[0.5]};
`;

const StyledInitials = styled(StyledText)`
    height: 1.5rem;
    width: 1.5rem;
`;

const StyledDivider = styled.hr`
    margin: 8px 0;
`;

const StyledBadge = styled(Ds.Badge)`
    position: absolute;
    top: -8px;
    right: -4px;
`;

const StyledArchivedBadge = styled(Ds.Badge).attrs({
    radius: "square",
    color: "greyscale",
})`
    margin-left: ${Tokens.SIZES[0.5]};
`;

export {
    StyledText,
    StyledInitials,
    StyledDivider,
    StyledBadge,
    StyledArchivedBadge,
};
