import { Option } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import isValid from "date-fns/isValid";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";

export function getDateFilterOptions(clinics: IntegratedClinic[]): Option[] {
    const dates = new Set<string>();

    clinics.forEach(({ clinicDate }) => {
        if (clinicDate && isValid(new Date(clinicDate))) {
            dates.add(clinicDate);
        }
    });

    const sortedValues = [...dates].sort(DateHelpers.mostRecentSorter);
    const options = sortedValues.map((value) => ({
        value,
        label: DateHelpers.formatDate(
            value,
            DateFormatOptions.NHS_DATE_LONG_WITH_DAY,
        ),
    }));

    return options;
}
