import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledColumn = styled(UI.Flex)`
    min-width: 0;
    flex-direction: column;
`;

export const StyledRow = styled(UI.Flex)`
    min-width: 0;
    gap: ${UI.Tokens.SIZES[1]};
`;
