import React from "react";

import { Text } from "@accurx/design";

type AccessAwareMessageProps = {
    canAccess: boolean;
    moduleName?: string;
};

export const AccessAwareMessage = ({
    moduleName,
    canAccess,
}: AccessAwareMessageProps) => (
    <>
        {canAccess && moduleName && (
            <Text>
                You have access to our {moduleName}. See your contract details
                for more information.
            </Text>
        )}
        {!canAccess && <Text>You have not yet purchased this module.</Text>}
    </>
);
