import React from "react";

import { Button } from "@accurx/design";

type MuteButtonProps = {
    disabled?: boolean;
    isMuted: boolean;
    onClick: () => void;
};

export const MuteButton = (props: MuteButtonProps): JSX.Element => {
    return (
        <Button
            text={props.isMuted ? "Unmute" : "Mute"}
            type="button"
            theme="secondary"
            onClick={props.onClick}
            disabled={props.disabled}
            icon={{
                name: "Bell",
                style: props.isMuted ? "Fill" : "Line",
            }}
        />
    );
};
