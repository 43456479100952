import * as UI from "@accurx/design";
import { Colours } from "@accurx/navigation";
import styled from "styled-components";

export const StyledLeftPaneContainer = styled.div`
    background: ${Colours.midnightGreen};
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
`;

export const StyledCloseButtonWrapper = styled(UI.Flex)`
    position: absolute;
    right: -${UI.Tokens.SIZES[7]};
    top: ${UI.Tokens.SIZES[2]};
`;
