import { SearchForPatientResponseSuccess } from "app/hooks/mutations/useSearchForPatientByNhsNumber";

import { ContactOption } from "./ContactOption";
import useSavePatientSearchToStore from "./hooks/useSavePatientSearchToStore";

export const ViewConversationOption = ({
    onClick,
    searchResult,
}: {
    onClick: () => void;
    searchResult: SearchForPatientResponseSuccess;
}) => {
    const saveToStore = useSavePatientSearchToStore();

    return (
        <ContactOption
            // Never disabled because if there is a conversation to go to,
            // the user would never be unable to visit it
            disabled={false}
            iconName="Message"
            text="View full conversation"
            onClick={() => {
                saveToStore(searchResult);
                onClick();
            }}
        />
    );
};
