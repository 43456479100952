import React, { useRef, useState } from "react";

import { Ds, IconNameVariants, Text } from "@accurx/design";
import { Rotate } from "@accurx/design/dist/ds/shared/Props";

import { useAnalytics } from "app/analytics/hooks";

import { StyledLink } from "./SectionCardLink.styles";

export type SectionCardLinkProps = {
    iconName?: IconNameVariants;
    rotate?: Rotate;
    section: string;
    text: string;
    path: string | object;
    openNewWindow?: boolean;
    newBadge?: boolean;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const SectionCardLink = ({
    iconName = "ArrowTail",
    rotate = "90",
    section,
    text,
    path,
    newBadge = false,
    onClick,
}: SectionCardLinkProps): JSX.Element => {
    const { track } = useAnalytics();
    const linkRef = useRef(null);

    const [hoverState, setHoverState] = useState(false);

    const mouseEnterHandler = () => setHoverState(true);
    const mouseLeaveHandler = () => setHoverState(false);

    return (
        <StyledLink
            to={path}
            ref={linkRef}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            onClick={(e) => {
                track("HomepageItemListItemClick", {
                    homepageSection: section,
                    homepageItem: text,
                });
                onClick && onClick(e);
            }}
        >
            <Ds.Halo color="blue" luminosity={hoverState ? "low" : "high"}>
                <Ds.Icon name={iconName} size="xsmall" rotate={rotate} />
            </Ds.Halo>
            <Text as="span" skinny>
                {text}
            </Text>
            {newBadge && (
                <Ds.Badge className="new-badge" color="purple" radius="round">
                    New
                </Ds.Badge>
            )}
        </StyledLink>
    );
};
