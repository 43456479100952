export enum BookingStatus {
    Awaiting = "Awaiting",
    Booked = "Booked",
    Failed_To_Book = "Failed to book",
    Did_Not_Book = "Did not book",
}

export enum DeliveryStatus {
    Queue = "Queue",
    Sent = "Sent",
    Delivered = "Delivered",
    Failed = "Failed",
}

export type SelfBookAuditItem = {
    nhsNumber: string;
    patientName: string;
    bookingStatus: BookingStatus;
    sentTime: string; // 2011-10-05T14:48:00.000Z
    deliveryStatus?: DeliveryStatus | null;
    senderName: string;
};

export type SelfBookAuditDto = {
    auditList: SelfBookAuditItem[];
};
