import { sanitizeAndLogError } from "domains/native/errors";
import { NativeTransport } from "domains/native/types";

export type EndpointContext = {
    transport: NativeTransport;
    workspaceId: number;
};

export type Endpoint<TData, TPayload> = (
    context: EndpointContext,
    payload: TPayload,
) => Promise<TData>;

/**
 * Acts as a factory for creating new Native API endpoints. It provides:
 * - Enforcing consistent structure for endpoints
 * - Out-of-the-box typing
 * - Error logging
 *
 * @param name - Will be used when logging errors thrown inside the request
 * @param endpoint - The Endpoint function
 * @returns An endpoint function wrapped with error logging
 */
export const createEndpoint = <TData, TPayload = undefined>(
    name: string,
    endpoint: Endpoint<TData, TPayload>,
): Endpoint<TData, TPayload> => {
    return ((context: EndpointContext, payload: TPayload) => {
        return endpoint(context, payload).catch((err) => {
            const error = sanitizeAndLogError(err, {
                domain: "Native API Request",
                endpoint: name,
            });

            throw error;
        });
    }) as Endpoint<TData, TPayload>;
};
