import { ContactMember, UserMember } from "@accurx/api/clinician-messaging";
import { Log } from "@accurx/shared";
import {
    IndividualContactParticipant,
    IndividualUserParticipant,
} from "domains/concierge/schemas/ParticipantSchema";

export const mapContactMemberToConversationParticipant = (
    contactMemberDto: ContactMember,
): IndividualContactParticipant | undefined => {
    if (!contactMemberDto.emailAddress) {
        Log.warn("Contact has no email address", {
            tags: {
                product: "Inbox",
                memberType: contactMemberDto.discriminator,
                memberId: contactMemberDto.contactId,
            },
        });
        return undefined;
    }
    return {
        contactId: contactMemberDto.contactId,
        type: "Contact",
        emailAddress: contactMemberDto.emailAddress,
        displayName:
            contactMemberDto.displayName ?? contactMemberDto.emailAddress,
    };
};

export const mapUserMemberToConversationParticipant = (
    userMemberDto: UserMember,
): IndividualUserParticipant => ({
    type: "User",
    userId: userMemberDto.userId ?? "",
});
