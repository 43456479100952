import {
    NativeFetchError,
    NativeFetchExpectedError,
    NativeFetchUnexpectedError,
    NativeToWebError,
} from "@accurx/native";
import { Log } from "@accurx/shared";
import { Primitive } from "@sentry/types";
import { ZodError } from "zod";

const expectedErrorReasons = ["RequestFailed", "SubscriptionEnded"] as const;

export type ExpectedErrorReason = (typeof expectedErrorReasons)[number];

export const isExpectedError = (
    reason: string,
): reason is ExpectedErrorReason => {
    return expectedErrorReasons.includes(reason as ExpectedErrorReason);
};

export const sanitizeAndLogError = (
    error: unknown,
    tags: Record<string, Primitive>,
): NativeFetchError => {
    // Expected errors do not need to be logged
    if (error instanceof NativeFetchExpectedError) {
        return error;
    }

    // Unexpected errors should be logged and may possibly have an underlying
    // cause error
    if (error instanceof NativeFetchUnexpectedError) {
        Log.error(error.cause ?? error, {
            tags: tags,
        });
        return error;
    }

    if (error instanceof ZodError) {
        Log.error(error, {
            tags: tags,
        });
        return new NativeFetchUnexpectedError(
            "ClientZodParseFailure",
            null,
            error,
        );
    }

    // We should never get here but if, for some reason, we receive an error
    // that isn't a NativeFetchError then convert it to one
    if (error instanceof Error) {
        Log.error(error, {
            tags: tags,
        });
        return new NativeFetchUnexpectedError("ClientUncaughtError");
    }

    // you can never be too careful with errors. If something that isn't even an
    // Error instance is thrown we've gone very wrong but again, lets convert it
    // to a NativeFetchError
    const err = new NativeFetchUnexpectedError("ClientUncaughtError");
    Log.error(err, {
        tags: tags,
    });
    return err;
};

export const convertNativeErrorMessageToError = (
    error: NativeToWebError,
): NativeFetchError => {
    return isExpectedError(error.type)
        ? new NativeFetchExpectedError(error.type, error.message)
        : new NativeFetchUnexpectedError(error.type, error.message);
};
