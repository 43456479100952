import React from "react";

import { Button, Text } from "@accurx/design";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import {
    OrgUserApprovalStatus,
    OrgUserApprovalStatusConsequence,
    OrgUserRoleConsequence,
    UserTransitions,
} from "./PracticeUsers.types";

type UserConfirmModalProps = {
    currentUser: UserTransitions;
    isOpen: boolean;
    onToggleModal: () => void;
};

// Returns a modal notification dialog box when changing user state or role
export const UserConfirmModal = ({
    currentUser,
    isOpen,
    onToggleModal,
}: UserConfirmModalProps): JSX.Element | null => {
    let message = "";

    const userName = currentUser.user.name || "this user";

    if (currentUser.status !== undefined) {
        message = OrgUserApprovalStatusConsequence(
            currentUser.status,
            currentUser.user.userStatusState?.currentValue,
            userName,
        );
    }
    if (currentUser.role !== undefined) {
        message = OrgUserRoleConsequence(currentUser.role, userName);
    }

    // If user status is unapproved (0) and changing state to approved do NOT show modal
    if (
        currentUser.user.userStatusState?.currentValue ===
            OrgUserApprovalStatus.Unapproved &&
        currentUser.status === OrgUserApprovalStatus.Approved
    ) {
        currentUser.action();
        onToggleModal();
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
        >
            <ModalHeader tag="div">
                <Text variant="subtitle" as="h1" skinny>
                    {currentUser.title}
                </Text>
            </ModalHeader>
            <ModalBody>
                <Text variant="label">Are you sure?</Text>
                <Text>{message}</Text>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button
                    text="Cancel"
                    theme="secondary"
                    onClick={onToggleModal}
                    className="mx-1"
                />
                <Button
                    text={currentUser.title}
                    onClick={(e): void => {
                        e.preventDefault();
                        currentUser.action();
                        onToggleModal();
                    }}
                />
            </ModalFooter>
        </Modal>
    );
};
