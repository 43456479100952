import {
    tabItemActiveStyles,
    tabItemBaseStyles,
    tabItemFocusStyles,
    tabItemHoverStyles,
} from "domains/inbox/components/Tabs/tabs.styles";
import styled from "styled-components";

export const StyledButtonTabWrapper = styled.div`
    // Override styles of the tab items
    li[role="presentation"] {
        padding: 0;
        border-bottom: none;
        position: relative;

        button {
            ${tabItemBaseStyles}
            span {
                font-size: inherit;
                line-height: inherit;
            }

            &:focus {
                ${tabItemFocusStyles}
            }
            &:hover {
                ${tabItemHoverStyles}
            }

            // When tab is active
            &[tabindex="0"] {
                ${tabItemActiveStyles}
            }
        }
    }
`;
