import { Button, Flex, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { i } from "images";

import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ModalContent } from "app/sharedComponents/ModalContent/ModalContent";
import { useCurrentUserId } from "store/hooks";

import {
    getIamNotInterestedCount,
    getShowMeHowItWorksCount,
    updateIamNotInterestedCount,
    updateShowMeHowItWorksCount,
} from "../../BatchMessage.utils";
import {
    StyledImage,
    StyledModal,
} from "./BatchMessageSelfBookPromoModal.styles";

export type BatchMessageSelfBookPromoModalProps = {
    showModal: boolean;
    onButtonClick: () => void;
};

export const BatchMessageSelfBookPromoModal = ({
    showModal,
    onButtonClick,
}: BatchMessageSelfBookPromoModalProps) => {
    const userId = useCurrentUserId();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const trackButtonClickEvent = (buttonType: string, clickCount: number) => {
        ChainAnalyticsTracker.trackBatchClickedSelfBookModalCTAButton({
            ...analyticsLoggedInProps,
            objectName: buttonType,
            countClick: clickCount,
        });
    };

    const onIamNotInterested = () => {
        if (userId) {
            const updatedCount =
                Number.parseInt(getIamNotInterestedCount(userId), 10) + 1;
            updateIamNotInterestedCount(userId, updatedCount.toString());

            trackButtonClickEvent("IamNotInterested", updatedCount);
        }
        onButtonClick();
    };

    const onShowMeHowItWorks = () => {
        if (userId) {
            const updatedCount =
                Number.parseInt(getShowMeHowItWorksCount(userId), 10) + 1;
            updateShowMeHowItWorksCount(userId, updatedCount.toString());

            trackButtonClickEvent("ShowMeHowItWorks", updatedCount);

            window.open(SupportUrls.SelfBookOverviewHowToSend, "_blank");
        }
        onButtonClick();
    };

    return (
        <StyledModal isOpen={showModal}>
            <ModalContent.Layout>
                <ModalContent.Header>
                    Did you know that you can use Self-Book in individual
                    messages to patient?
                </ModalContent.Header>
                <ModalContent.Body>
                    <Text skinny>
                        When composing a message to an individual patient,
                        simply click on the booking link button and choose an
                        appointment slot, whether that's for a blood test,
                        annual review, smear test or anything else!
                    </Text>
                    <StyledImage>
                        <img
                            src={i(
                                "batchMessaging/self-book-promotion-in-batch.png",
                            )}
                            alt="Self Book promotion in batch messaging"
                        />
                    </StyledImage>
                </ModalContent.Body>
                <ModalContent.Footer>
                    <Flex justifyContent="space-between">
                        <Button
                            onClick={onIamNotInterested}
                            theme="secondary"
                            text="I'm not interested"
                        ></Button>
                        <Button
                            theme="primary"
                            text="Show me how it works"
                            onClick={onShowMeHowItWorks}
                        />
                    </Flex>
                </ModalContent.Footer>
            </ModalContent.Layout>
        </StyledModal>
    );
};
