import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { PatientToUpload } from "app/batchMessage/gp/BatchMessage.types";

export const mapAppointmentsToBatchPatients = (
    appointments: ClinicAppointment[],
): PatientToUpload[] => {
    const uniquePatientsMap = new Map<string, PatientToUpload>();

    appointments.forEach(({ nhsNumber, dateOfBirth }) => {
        if (nhsNumber && dateOfBirth && !uniquePatientsMap.has(nhsNumber)) {
            uniquePatientsMap.set(nhsNumber, {
                nhsNumber,
                dateOfBirth,
            });
        }
    });

    return Array.from(uniquePatientsMap.values());
};
