import { usePatient } from "domains/concierge/hooks/data/usePatient";
import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation, PatientSummary } from "domains/concierge/types";

type HookVariables = {
    patientId: PatientSummary["patientId"] | null | undefined;
};

type MutationVariables = {
    conversation: Pick<Conversation, "source">;
};

export const useMarkAsUnreadMutation = (
    { patientId }: HookVariables,
    options?: UseMutationOptions<MutationVariables, ConciergeUpdates>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();
    const patient = usePatient({ patientId });

    return useMutation<MutationVariables, ConciergeUpdates>(
        "MarkAsUnread",
        async ({ conversation }) => {
            if (conversation.source.system !== "Ticket") {
                throw new Error(
                    `MarkAsUnreadMutation does not support ${conversation.source.system} conversations`,
                );
            }

            const { updates } = await api.markTicketAsUnread({
                workspaceId,
                ticketIdentity: conversation.source.ticketIdentity,
                patientExternalIds: patient?.externalIds,
            });

            return updates;
        },
        {
            ...options,
            onSuccess: (updates, _variables, _context) => {
                // We only process updates and call the onSuccess if
                // any updates were returned. Otherwise, marking as unread
                // had no effect and we do nothing.

                if (
                    updates.conversations.length > 0 ||
                    updates.patients.length > 0 ||
                    updates.teams.length > 0 ||
                    updates.users.length > 0
                ) {
                    dispatch(
                        actions.processUpdates({
                            source: "Mutation:MarkAsUnread",
                            ...updates,
                        }),
                    );

                    options?.onSuccess?.(updates, _variables, _context);
                }
            },
        },
    );
};
