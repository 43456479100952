import { PatientThreadTicketCommandResult } from "@accurx/api/ticket";
import { mapConversationItemIdToTicketItemIdentity } from "domains/concierge/internal/api/shared/mappers/mapConversationItemIdToTicketItemIdentity";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { ConversationItem, PatientExternalId } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkNotesAsReadOptions = {
    itemIds: ConversationItem["id"][];
    workspaceId: number;
    patientExternalIds?: PatientExternalId[];
};

export const markNotesAsRead: ConciergeFetcher<
    MarkNotesAsReadOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const noteIds: string[] = [];

    arg.itemIds.forEach((itemId) => {
        const identity = mapConversationItemIdToTicketItemIdentity(itemId);
        if (identity) {
            noteIds.push(identity.id);
        }
    });

    const patientExternalIds = arg.patientExternalIds
        ? mapPatientSummaryExternalIdsToTicketPatientExternalIds(
              arg.patientExternalIds,
          )
        : undefined;

    const response = await TicketApiClient.markNoteRead({
        organisationId: arg.workspaceId,
        patientThreadItemIds: noteIds,
        patientExternalIdentity: patientExternalIds
            ? {
                  patientExternalIds: patientExternalIds,
              }
            : undefined,
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
