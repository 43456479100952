/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { ElementType, MouseEvent, ReactNode } from "react";

import { Flex, Text, Tokens } from "@accurx/design";
import { IframeDetector } from "@accurx/shared";

import { ROUTES } from "shared/Routes";

import { useSsoPopup } from "../hooks";
import {
    StyledBaseComponentLink,
    StyledLinkLight,
    StyledNhsButtonLink,
} from "./NhsMailLogin.styles";

type NextRouteAfterLoginType = { route: string; search: string };

/**
 * Holds the functionality of logging in with NHS mail.
 * Styling is implemented by using the component prop.
 *
 * DO NOT USE DIRECTLY - use `LoginWithNhsMailLink` or `NhsMailLoginButtonLink`
 * depending on whether you the link to look like an Accurx link or button
 */
const NhsMailLogin = ({
    nextRouteAfterLogin,
    children,
    component,
}: {
    /** Whether we want the user to land on a specific page after they've logged in via NHSMail */
    nextRouteAfterLogin: NextRouteAfterLoginType;
    /** The content of the link */
    children: ReactNode;
    /** Pass a styled component to customise simple <a> */
    component?: ElementType;
}) => {
    const openSsoPopup = useSsoPopup({
        nextRouteAfterLogin: nextRouteAfterLogin,
        onLoading: () => {},
    });

    // To avoid having a half double encoded query, we need to decode then encode URI
    const openIdUrl = `/openidconnect/authenticate?returnUrl=${
        nextRouteAfterLogin.route
    }${encodeURIComponent(decodeURIComponent(nextRouteAfterLogin.search))}`;

    return (
        <StyledBaseComponentLink
            as={component}
            href={openIdUrl}
            onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                if (IframeDetector.InIframe()) {
                    openSsoPopup();
                    e.preventDefault();
                }
            }}
        >
            {children}
        </StyledBaseComponentLink>
    );
};

/**
 * Adds Accurx link styles to NhsMailLogin functionality
 */
export const LoginWithNhsMailLink = ({
    nextRouteAfterLogin = { route: ROUTES.postlogin, search: "" },
}: {
    nextRouteAfterLogin?: NextRouteAfterLoginType;
}) => {
    return (
        <NhsMailLogin
            component={StyledLinkLight}
            nextRouteAfterLogin={nextRouteAfterLogin}
        >
            Log in via nhs.net
        </NhsMailLogin>
    );
};

/**
 * Adds Accurx button styles to NhsMailLogin functionality
 */
export const NhsMailLoginButtonLink = ({
    nextRouteAfterLogin,
}: {
    nextRouteAfterLogin: NextRouteAfterLoginType;
}) => {
    return (
        <NhsMailLogin
            nextRouteAfterLogin={nextRouteAfterLogin}
            component={StyledNhsButtonLink}
        >
            <Flex
                gap="0.5"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
            >
                <Text
                    variant="label"
                    as="span"
                    skinny
                    props={{ style: { color: Tokens.COLOURS.greyscale.white } }}
                >
                    Continue with NHSmail (nhs.net)
                </Text>
            </Flex>
        </NhsMailLogin>
    );
};

export const ForTestingOnlyNhsMailLogin = NhsMailLogin;
