import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledCard = styled(UI.Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
})`
    padding: ${UI.Tokens.SIZES[1.5]};
    border-radius: ${UI.Tokens.BORDERS.radius};
    border: solid 1px ${UI.Tokens.COLOURS.greyscale.silver};
`;
