import { SortDirection } from "@accurx/design";

import { COLUMN_SORT_DIRECTION } from "../shared/shared.utils";

const COLUMN_DESCRIPTIONS: Record<string, string> = {
    name: "This column holds the name of the member of staff or team",
    totalCurrentAssigned:
        "This column holds the number of all open tickets currently assigned. If there are urgent requests within this amount, these are highlighted with a flag.",
    totalTodayAssigned:
        "This column holds the number of all open tickets assigned today. If there are urgent requests within this amount, these are highlighted with a flag.",
    totalTodayDone:
        "This column holds the number of tickets marked as done today while assigned.",
};

export const isColumnSortReversed = (
    columnName: string,
    direction: SortDirection,
): boolean => {
    const reverseSortDirection = COLUMN_SORT_DIRECTION[columnName].reverse;
    if (!reverseSortDirection) return false;
    return reverseSortDirection[1] === direction;
};

const COLUMN_FULL_NAMES: Record<string, string> = {
    name: "Owner",
    totalCurrentAssigned: "Total Current Assigned",
    totalTodayAssigned: "Total Assigned Today",
    totalTodayDone: "Total Done Today",
};

export const getColumnFullName = (key: string): string =>
    COLUMN_FULL_NAMES[key];

export const getColumnDescription = (key: string): string =>
    COLUMN_DESCRIPTIONS[key];
