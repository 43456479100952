import { FormEvent, useEffect, useState } from "react";

import {
    Button,
    Feedback,
    Flex,
    FormFieldWrapper,
    Input,
    Link,
    Text,
} from "@accurx/design";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FlemingAnalyticsTracker } from "app/analytics";
import { useSubmitJoinOrganisationRequestMutation } from "app/hooks/mutations";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { useIsClinicianConversationOnboarding } from "../hooks";
import { OnboardingCard } from "../layout-components";

const ERROR_COPY = "This field is required.";

export const AddOrganisationForm = ({
    onAddSuccess,
}: {
    onAddSuccess: () => void;
}): JSX.Element => {
    const history = useHistory<{ showBackButton?: boolean }>();
    const params = new URLSearchParams(history.location.search);
    const isBlurryChatLayout = useIsClinicianConversationOnboarding();

    const showBackButton = history.location.state?.showBackButton ?? false;

    // SSO can redirect here with a suggested ods code
    // used to provide extra info in relevant Slack channel to support team
    const ssoOdsCode = params.get("odsCode") || undefined;

    const submitJoinOrganisationRequestMutation =
        useSubmitJoinOrganisationRequestMutation();

    const [organisationName, setOrganisationName] = useState("");
    const [organisationPostCode, setOrganisationPostCode] = useState("");
    const [organisationType, setOrganisationType] = useState("");
    const [reasonForAccess, setReasonForAccess] = useState("");

    const [submitClicked, setSubmitClicked] = useState(false);

    const loggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        FlemingAnalyticsTracker.trackOrganisationSetupPageView(loggedInProps);
    }, [loggedInProps]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setSubmitClicked(true);

        if (
            !organisationName ||
            !organisationPostCode ||
            !organisationType ||
            !reasonForAccess
        ) {
            return;
        }

        FlemingAnalyticsTracker.trackOrganisationSetUpButtonClick(
            loggedInProps,
        );

        const formData = {
            organisationName,
            organisationPostCode,
            organisationType,
            reasonForAccess,
            ssoOdsCode,
        };

        submitJoinOrganisationRequestMutation.mutate(formData, {
            onSuccess: onAddSuccess,
            onError: () => {
                toast(
                    Feedback({
                        colour: "error",
                        title: "Failed to submit",
                        content: "Please refresh page and try again",
                    }),
                );
            },
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <OnboardingCard
                header={
                    isBlurryChatLayout ? "Add your organisation" : undefined
                }
            >
                <Feedback
                    iconName="None"
                    colour="information"
                    props={{ className: "mb-3" }}
                >
                    <Text skinny>
                        Your organisation isn’t on Accurx yet. Once you submit
                        the details of your organisation, we will review the
                        request and update your account accordingly.
                    </Text>
                </Feedback>
                <Flex gap="1" flexDirection="column">
                    <Text>
                        Check your organisation details with the{" "}
                        <Link
                            href="https://odsportal.digital.nhs.uk/Organisation/Search"
                            theme="light"
                            openInNewTab
                        >
                            NHS Organisation/Practitioner Search
                            <Link.Icon />
                        </Link>
                    </Text>
                    <FormFieldWrapper
                        label="Organisation name"
                        labelProps={{ htmlFor: "organisation-name" }}
                        className="mb-3"
                        errors={[
                            submitClicked && !organisationName
                                ? ERROR_COPY
                                : null,
                        ]}
                    >
                        <Input
                            id="organisation-name"
                            value={organisationName}
                            type="text"
                            onChange={(e): void =>
                                setOrganisationName(e.target.value)
                            }
                            hasErrors={submitClicked && !organisationName}
                            placeholder="e.g. Village Surgery"
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Organisation postcode"
                        labelProps={{ htmlFor: "organisation-postcode" }}
                        className="mb-3"
                        errors={[
                            submitClicked && !organisationPostCode
                                ? ERROR_COPY
                                : null,
                        ]}
                    >
                        <Input
                            id="organisation-postcode"
                            value={organisationPostCode}
                            type="text"
                            onChange={(e): void =>
                                setOrganisationPostCode(e.target.value)
                            }
                            hasErrors={submitClicked && !organisationPostCode}
                            placeholder="e.g. N1 5AA"
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Organisation type"
                        labelProps={{ htmlFor: "organisation-type" }}
                        className="mb-3"
                        errors={[
                            submitClicked && !organisationType
                                ? ERROR_COPY
                                : null,
                        ]}
                    >
                        <Input
                            id="organisation-type"
                            value={organisationType}
                            type="text"
                            onChange={(e): void =>
                                setOrganisationType(e.target.value)
                            }
                            hasErrors={submitClicked && !organisationType}
                            placeholder="e.g. NHS Trust"
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper
                        label="Reason for access"
                        labelProps={{ htmlFor: "organisation-reason" }}
                        className="mb-3"
                        errors={[
                            submitClicked && !reasonForAccess
                                ? ERROR_COPY
                                : null,
                        ]}
                    >
                        <Input
                            id="organisation-reason"
                            value={reasonForAccess}
                            type="text"
                            onChange={(e): void =>
                                setReasonForAccess(e.target.value)
                            }
                            hasErrors={submitClicked && !reasonForAccess}
                            placeholder="e.g. To send messages to patients"
                        />
                    </FormFieldWrapper>
                </Flex>
                <Flex justifyContent="flex-end" gap="2">
                    {showBackButton && (
                        <Button
                            type="button"
                            theme="secondary"
                            onClick={history.goBack}
                            text="Back"
                        />
                    )}
                    <Button
                        type="submit"
                        text="Add"
                        disabled={
                            submitJoinOrganisationRequestMutation.status ===
                            "loading"
                        }
                    />
                </Flex>
            </OnboardingCard>
        </form>
    );
};
