import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import { Attachment as AttachmentType } from "domains/compose/reducer.types";

import { AttachmentSize } from "../../Attach/components/AttachmentSize";
import { BodyInfoBox } from "../../BodyInfoBox";
import { StyledActionStack } from "../ActionStack.styles";

export const Attachment = ({
    attachment,
    onRemoveClick,
}: {
    attachment: AttachmentType;
    onRemoveClick?: (attachment: AttachmentType) => void;
}) => {
    switch (attachment.origin) {
        case "TempUpload":
            return (
                <StyledActionStack.Row alignItems={"center"} gap="1">
                    <UI.Ds.Spinner aria-hidden="true" size="small" />
                    <BodyInfoBox.Text colour="night">
                        {attachment.name} uploading
                    </BodyInfoBox.Text>
                </StyledActionStack.Row>
            );
        case "Upload":
        case "Template":
            return (
                <StyledActionStack.Row
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap="1"
                >
                    <UI.Flex gap="1" alignItems="center">
                        <BodyInfoBox.Icon name="Paperclip" />
                        {attachment.previewUrl ? (
                            <BodyInfoBox.Link
                                href={attachment.previewUrl}
                                openInNewTab
                            >
                                <UI.Link.Text text={attachment.name} />
                                <UI.Link.Icon />
                            </BodyInfoBox.Link>
                        ) : (
                            <BodyInfoBox.Text colour="night">
                                {attachment.name}
                            </BodyInfoBox.Text>
                        )}
                    </UI.Flex>
                    <UI.Flex gap="1" alignItems="center">
                        {attachment.size && (
                            <AttachmentSize size={attachment.size} />
                        )}
                        {onRemoveClick !== undefined && (
                            <StyledActionStack.SquareIconButton
                                onClick={() => onRemoveClick(attachment)}
                                title={`Remove ${attachment.name}`}
                            >
                                <Pill.Icon name="Cross" colour="red" size={3} />
                            </StyledActionStack.SquareIconButton>
                        )}
                    </UI.Flex>
                </StyledActionStack.Row>
            );

        default: {
            // Make sure build breaks if we don't render one of the attachments origins
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = attachment.origin;
            return null;
        }
    }
};
