import { Log } from "@accurx/shared";

import {
    mapTicketDateOfBirthInfoToPatientSummaryAgeParts,
    mapTicketPatientExternalIdsToPatientSummaryExternalIds,
    mapTicketPatientGenderToPatientSummaryGender,
    mapTicketPatientNamesToPatientSummaryParts,
} from "shared/concierge/conversations/tickets/mappers/PatientMapper";
import {
    FloreyScoreSummaryResponse,
    FloreyScoresSummaryResponse,
    PatientThreadPatientDetails,
} from "shared/concierge/conversations/tickets/types/dto.types";
import { PatientSummary } from "shared/concierge/patients/types/patient.types";

import { FilterOptions } from "./components/responsesDashboard/types";
import { FloreyScoreSummary, FloreyScoreSummarySchema } from "./schemas";

/**
 * Similar to mapping function in PatientMapper.ts,
 * but does not require the patient to have a unique accurx id.
 * This function will still map the id to an empty string if null.
 */
export const mapTicketPatientToPatientSummaryNoPatientIdRequired = (
    dto: PatientThreadPatientDetails,
): PatientSummary => {
    return {
        patientId: dto.accuRxId || "",
        ...mapTicketDateOfBirthInfoToPatientSummaryAgeParts(dto.dateOfBirth),
        externalIds: mapTicketPatientExternalIdsToPatientSummaryExternalIds(
            dto.patientExternalIdentity?.patientExternalIds,
        ),
        ...mapTicketPatientNamesToPatientSummaryParts(dto.names),
        gender: mapTicketPatientGenderToPatientSummaryGender(dto.gender),
    };
};

const mapFloreyScoreSummaryResponseToDisplayData = (
    scoreSummary: FloreyScoreSummaryResponse,
) => {
    const validatedAndMapped = FloreyScoreSummarySchema.safeParse(scoreSummary);

    if (validatedAndMapped.success) {
        return validatedAndMapped.data;
    }

    Log.error(
        "Cannot map score summary response as the data received from the API is invalid",
        {
            tags: {
                floreyEnrolmentId: scoreSummary.floreyEnrolmentId,
            },
            context: [
                {
                    name: "Validation errors:",
                    data: validatedAndMapped.error.format(),
                },
            ],
        },
    );
    return null;
};

const mapFloreyScoreSummariesToDisplayData = (
    summaries: FloreyScoresSummaryResponse["floreyScoreSummaries"],
) => {
    return (summaries || []).reduce((prev, next) => {
        const mapped = mapFloreyScoreSummaryResponseToDisplayData(next);
        if (mapped) {
            return [...prev, mapped];
        }
        return prev;
    }, [] as FloreyScoreSummary[]);
};

export const mapFloreyScoreSummariesToDisplay = (
    data: FloreyScoresSummaryResponse,
) => ({
    questionnaireName: data.floreyName,
    summaries: mapFloreyScoreSummariesToDisplayData(data.floreyScoreSummaries),
});

export const mapUiFiltersToApiFilters = (filters: FilterOptions) => {
    const dateStartFormatted = filters.dateStart
        ? new Date(filters.dateStart).toISOString()
        : undefined;
    // Because we include time for API request at this stage and it is midnight,
    // to make sure that end date is included at this stage we add one day to it
    const dateEndFormatted = filters.dateEnd
        ? new Date(
              new Date(filters.dateEnd).getTime() + 24 * 60 * 60 * 1000,
          ).toISOString()
        : undefined;

    return {
        dateStart:
            filters.dateType === "date-sent" ? dateStartFormatted : undefined,
        dateEnd:
            filters.dateType === "date-sent" ? dateEndFormatted : undefined,
        answeredAtStart:
            filters.dateType === "date-answered"
                ? dateStartFormatted
                : undefined,
        answeredAtEnd:
            filters.dateType === "date-answered" ? dateEndFormatted : undefined,
        status: filters.status,
        complexity: filters.complexity,
    };
};
