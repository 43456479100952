/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { IUserResponse } from "api/FlemingDtos";

import * as FileUploadActions from "../fileUpload/FileUploadActions";
import * as SelectProductActions from "../selectProduct/SelectProductActions";
import * as MessagePracticeActions from "./MessagePracticeActions";
import MessagePractice from "./MessagePracticeComponent";

// Selects which state properties are merged into the component's props
function mapStateToProps(
    state: ApplicationState,
    ownProps: { user: IUserResponse },
) {
    return {
        ...state.selectProduct,
        ...state.searchForPatient,
        ...state.messagePractice,
        ...state.fileUpload,
        account: state.account,
        analytics: state.sessionAnalytics,
        ...ownProps,
    };
}

// Why withRouter? See https://reacttraining.com/react-router/web/guides/redux-integration/blocked-updates. We're using Route children rather than component=.
export default withRouter(
    connect(
        mapStateToProps,
        {
            ...MessagePracticeActions.actionCreators,
            ...FileUploadActions.actionCreators,
            ...SelectProductActions.actionCreators,
        }, // Selects which action creators are merged into the component's props
    )(MessagePractice as any) as any,
) as any;
// Need the final as any in order to pass a prop to MessagePractice in the App.tsx Component
