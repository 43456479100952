import { ReactNode, useEffect, useRef, useState } from "react";

import { Icon, Text, Tokens } from "@accurx/design";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

export const SLIDEOUT_PORTAL_ID = "app-slideout-root";

/**
 * <SlideOut />
 * ============
 * A controlled `SlideOut` component for placing to the left or right of any view,
 * into which you can put any content you want/need. It's open state is controlled
 * by an isOpen prop, and a toggleOpen callback. It renders into a portal with id
 * SLIDEOUT_PORTAL_ID.
 *
 * If the option to showTab=true, tabText is required. The tab will only become
 * visible in the viewport _after_ the first time the SlideOut has opened.
 *
 * Example usage
 * -------------
 *
 * default/minimal - right placement, no toggle tab
 *
 *      <SlideOut
 *          isOpen={this.state.isSlideOutOpen}
 *          toggleOpen={() =>
 *              this.setState((state) => ({
 *                  isSlideOutOpen: !state.isSlideOutOpen,
 *              }))
 *          }
 *      >
 *          <h1>Hello</h1>
 *      </SlideOut>
 *
 * left placement, no toggle tab
 *
 *      <SlideOut
 *          placement="left"
 *          isOpen={this.state.isSlideOutOpen}
 *          toggleOpen={() =>
 *              this.setState((state) => ({
 *                  isSlideOutOpen: !state.isSlideOutOpen,
 *              }))
 *          }
 *      >
 *          <h1>Hello</h1>
 *      </SlideOut>
 *
 * left placement, with toggle tab
 *
 *      <SlideOut
 *          placement="left"
 *          showTab={true}
 *          tabText="My Slide Out"
 *          isOpen={this.state.isSlideOutOpen}
 *          toggleOpen={() =>
 *              this.setState((state) => ({
 *                  isSlideOutOpen: !state.isSlideOutOpen,
 *              }))
 *          }
 *      >
 *          <h1>Hello</h1>
 *      </SlideOut>
 */

export const SlideOut = (props: SlideOutProps) => {
    // 1-time flag to denote the *first* time the slideout is shown. once it
    // has been shown once, it is active thereafter
    const [isSlideOutActive, setIsSlideOutActive] = useState<boolean>(
        props.isOpen === true,
    );

    const el = useRef(document.createElement("div"));

    // insert the div we're rendering into, into the portal root
    useEffect(() => {
        const current = el.current;

        const slideOutRoot = document.querySelector<HTMLDivElement>(
            `#${SLIDEOUT_PORTAL_ID}`,
        );

        if (slideOutRoot === null) return;

        slideOutRoot.appendChild(current);
        return (): void => void slideOutRoot.removeChild(current);
    }, []);

    // set SlideOut to be activate on first open
    useEffect(() => {
        if (!isSlideOutActive && props.isOpen) {
            setIsSlideOutActive(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    // disable scrolling on body when SlideOut is open
    useEffect(() => {
        const body = document.querySelector<HTMLBodyElement>("body");

        if (body) {
            body.style.overflow = props.isOpen ? "hidden" : "auto";
        }

        return (): void => {
            if (body) {
                body.style.overflow = "auto";
            }
        };
    }, [props.isOpen]);

    const placement = props.placement ?? "right";
    const toggleArrowRotation = placement === "left" ? "right" : "left";

    // optional tab that can be used to toggle the SlideOut
    const toggleTab =
        props.showTab === true ? (
            <SlideOutToggle placement={placement} onClick={props.toggleOpen}>
                <Text variant="label" skinny>
                    {props.tabText}
                </Text>
                <IconWrapper isOpen={props.isOpen}>
                    <Icon
                        name="Arrow"
                        theme="Line"
                        size={3}
                        rotation={toggleArrowRotation}
                    />
                    <Icon
                        name="Arrow"
                        theme="Line"
                        size={3}
                        rotation={toggleArrowRotation}
                    />
                </IconWrapper>
            </SlideOutToggle>
        ) : null;

    const slideOutContent = (
        <>
            <SlideOutOverlay isOpen={props.isOpen} onClick={props.toggleOpen} />
            <SlideOutContent
                id="app-slideout-content"
                data-testid="app-slideout-content"
                isActive={isSlideOutActive}
                isOpen={props.isOpen}
                placement={placement}
                useFixedWidth={props.useFixedWidth}
            >
                {toggleTab}
                {props.children}
            </SlideOutContent>
        </>
    );

    return createPortal(slideOutContent, el.current);
};

type WithToggleTab = {
    showTab: true;
    tabText: string;
};

type OptionalToggleTab = WithToggleTab | { showTab: false };
type Placement = "left" | "right";

type SlideOutProps = {
    children?: ReactNode;
    placement?: Placement;
    isOpen: boolean;
    toggleOpen: () => void;
    useFixedWidth?: boolean;
} & OptionalToggleTab;

type SlideOutPlacement = Pick<SlideOutProps, "placement">;
type SlideOutOpenState = Pick<SlideOutProps, "isOpen">;

const SlideOutOverlay = styled.div<SlideOutOpenState>`
    pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
    opacity: ${(props) => (props.isOpen ? 0.5 : 0)};
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #333;
    will-change: opacity;
    transition: opacity 0.3s ease-out;
`;

// an arbitrary distance away from the edge of the viewport that the SlideOut
// initially starts off at. This ensures that the toggle tab is not visible on
// first render, and is only visible after the first time the SlideOut has opened
const slideOutOffScreenDistance = "240px";

const SlideOutContent = styled.div<
    SlideOutOpenState &
        SlideOutPlacement & { isActive: boolean; useFixedWidth?: boolean }
>`
    position: fixed;
    z-index: 999;
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: ${(props) =>
        props.isOpen ? "0px 0px 24px 4px rgba(0, 0, 0, 0.3)" : "none"};
    will-change: transform;
    transition: transform 0.3s;
    transition-timing-function: ${(props) =>
        props.isOpen ? "ease-out" : "ease-in"};
    ${(props) =>
        props.placement === "right" &&
        `
        width: 30%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        transform: translateX(calc(100% + ${slideOutOffScreenDistance}));
        transform: ${
            props.isOpen
                ? "translateX(0)"
                : `translateX(calc(100% + ${
                      props.isActive ? "0px" : slideOutOffScreenDistance
                  }))`
        };
        ${
            !props.useFixedWidth &&
            `        @media only screen and (max-width: 767px) {
            width: 80%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1200px) {
            width: 50%;
        }
        @media only screen and (min-width: 1201px) {
            width: 30%;
        }`
        }

    `}
    ${(props) =>
        props.placement === "left" &&
        `
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        transform: translateX(calc(-100% - ${slideOutOffScreenDistance}));
        transform: ${
            props.isOpen
                ? "translateX(0)"
                : `translateX(calc(-100% - ${
                      props.isActive ? "0px" : slideOutOffScreenDistance
                  }))`
        };
        ${
            !props.useFixedWidth &&
            `        @media only screen and (max-width: 767px) {
            width: 80%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1200px) {
            width: 50%;
        }
        @media only screen and (min-width: 1201px) {
            width: 30%;
        }`
        }
    `}
`;

const toggleTabHeight = Tokens.SIZES["4"];
const boxShadowRight = "0px -8px 16px 0px rgba(68, 72, 126, 0.32)";
const boxShadowLeft = "0px 8px 16px 0px rgba(68, 72, 126, 0.32)";

const SlideOutToggle = styled.button<SlideOutPlacement>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 50%;
    left: 0;
    min-width: 120px;
    height: ${toggleTabHeight};
    padding: 0 ${Tokens.SIZES["1"]};
    background-color: #fff;
    color: ${Tokens.COLOURS.greyscale.zinc};
    border: none;
    word-break: keep-all;
    transform-origin: top left;
    ${(props) =>
        props.placement === "right" &&
        css`
            box-shadow: ${boxShadowRight};
            border-radius: 8px 8px 0 0;
            transform: translateX(-${toggleTabHeight}) rotate(-90deg);
        `}
    ${(props) =>
        props.placement === "left" &&
        css`
            box-shadow: ${boxShadowLeft};
            border-radius: 0 0 8px 8px;
            transform: translateX(100%) rotate(-90deg);
        `}

    /*
        overrides the global focus styling on buttons, from GlobalStyle in
        accurx-design. the reason this needs overriding, is because this button
        already has a box-shadow, and the global style was removing it to add
        its own, for focus highlighting. this override moves focus indication to
        the outline property, and retains the box-shadow.
    */
    &:not([disabled]):not([tabindex='-1']):focus {
        outline: 4px solid ${Tokens.COLOURS.primary.blue[25]};

        ${(props) =>
            props.placement === "right" &&
            css`
                box-shadow: ${boxShadowRight};
            `}

        ${(props) =>
            props.placement === "left" &&
            css`
                box-shadow: ${boxShadowLeft};
            `}
    }

    &:hover {
        pointer: cursor;
    }

    & > * {
        flex: 1 0 auto;
    }

    & > * + * {
        margin-left: ${Tokens.SIZES["1"]};
    }
`;

const IconWrapper = styled.span<SlideOutOpenState>`
    display: inline-flex;
    position: relative;
    width: 1.5rem;
    height: 100%;
    transition: transform 0.6s ease-in-out;
    transform: ${(props) =>
        props.isOpen ? "rotate(-90deg)" : "rotate(90deg)"};

    & > * {
        position: absolute;
        top: 0;
        height: 100%;

        &:first-child {
            left: -3px;
        }
        &:last-child {
            left: 3px;
        }
    }
`;
