import { IWrappedResult } from "@accurx/shared";

import FlemingApi from "api/FlemingApiClient";
import {
    IMessagePracticeRequest,
    IMessagePracticeResponse,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { ClinicianMessageSend } from "app/analytics/FlemingAnalytics";
import { SentPracticeMessageAnalyticsProps } from "app/analytics/FlemingAnalytics/SendMessageEvents";

import {
    FileRemovedAction,
    FileUploadFinishedAction,
    FileUploadStartedAction,
} from "../fileUpload/FileUploadActions";

// Interface used to group information used by analytics on sending a message
export type SendPracticeAnalyticsArgs = Pick<
    FlemingAnalyticsTracker.SentPracticeMessageAnalyticsProps,
    "TemplateUsed" | "template" | "fileTypes" | "enteredWithUseCase"
>;

// ACTION TYPES

export const MESSAGE_PRACTICE_STARTED = "MESSAGE_PRACTICE_STARTED";
export const MESSAGE_PRACTICE_FINISHED = "MESSAGE_PRACTICE_FINISHED";
export const MESSAGE_PRACTICE_RESET_PRODUCT = "MESSAGE_PRACTICE_RESET_PRODUCT";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface MessagePracticeStartedAction {
    type: typeof MESSAGE_PRACTICE_STARTED;
    requestData: IMessagePracticeRequest;
}

interface MessagePracticeFinishedAction {
    type: typeof MESSAGE_PRACTICE_FINISHED;
    response: IWrappedResult<IMessagePracticeResponse>;
}

interface MessagePracticeResetProductAction {
    type: typeof MESSAGE_PRACTICE_RESET_PRODUCT;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | MessagePracticeStartedAction
    | MessagePracticeFinishedAction
    | MessagePracticeResetProductAction
    | FileUploadStartedAction
    | FileUploadFinishedAction
    | FileRemovedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    sendPracticeMessage:
        (
            messageRequest: IMessagePracticeRequest,
            analyticsProps: ClinicianMessageSend,
            analyticsLegacyArgs: SendPracticeAnalyticsArgs,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            dispatch({
                type: MESSAGE_PRACTICE_STARTED,
                requestData: messageRequest,
            });

            // Gather props for analytics
            const analyticsLegacyProps = getAnalyticsProps(
                getState,
                messageRequest,
                analyticsLegacyArgs,
            );

            const messagePracticeResponse = await FlemingApi.messagePractice(
                messageRequest,
            );

            FlemingAnalyticsTracker.trackMessagePracticeSendButtonClick({
                ...analyticsProps,
                hasError: !messagePracticeResponse.success,
                origin: getState().selectProduct.searchPatientOrigin,
            });
            if (messagePracticeResponse.success) {
                FlemingAnalyticsTracker.trackSentPracticeMessageSuccess(
                    analyticsLegacyProps,
                );
                window.Intercom(
                    "trackEvent",
                    "message-practice-initial-message-sent",
                );
            } else {
                FlemingAnalyticsTracker.trackSentPracticeMessageFailure(
                    analyticsLegacyProps,
                );
            }

            dispatch({
                type: MESSAGE_PRACTICE_FINISHED,
                response: messagePracticeResponse,
            });
        },

    resetProductChoice: () => ({
        type: MESSAGE_PRACTICE_RESET_PRODUCT,
    }),
};

const getAnalyticsProps = (
    getState: () => ApplicationState,
    messageRequest: IMessagePracticeRequest,
    analyticsArgs: SendPracticeAnalyticsArgs,
): SentPracticeMessageAnalyticsProps => {
    const state = getState();
    return {
        ...AnalyticsMapper.getAnalyticsLoggedInProps(
            state.account,
            state.sessionAnalytics,
        ),
        AttachmentUsed: messageRequest.externalEmailAttachmentIds.length > 0,
        TemplateUsed: analyticsArgs.TemplateUsed,
        isFeaturePatientListEnabled: true,
        searchPatientOrigin: state.selectProduct.searchPatientOrigin,
        template: analyticsArgs.template,
        attachments: messageRequest.externalEmailAttachmentIds.length,
        fileTypes: analyticsArgs.fileTypes,
        practiceCodeSentTo: messageRequest.practiceCode,
        enteredWithUseCase: analyticsArgs.enteredWithUseCase,
    };
};
