import styled from "styled-components";

export const WhatIsPatientTriageContainer = styled.div`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`;

export const WhatIsPatientTriageTableContainer = styled.div`
    margin-top: 5rem;
`;
