import { useCurrentWorkspace } from "@accurx/auth";
import { useMutation } from "@tanstack/react-query";

import { mutateSignalRClientOn } from "../api/endpoints/mutateSignalRClientOn";
import { NativeTransportError } from "../errors";
import { useNativeTransport } from "./useNativeTransport";

// A hook to enable setting up a SignalR subscription via the native bridge
// This is a pre-requisite for subscribing to a SignalR method via the native bridge
export const useSignalRClientOn = () => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation({
        mutationKey: ["MutateSignalRClientOn"],
        mutationFn: async ({ methodName }: { methodName: string }) => {
            if (!transport) {
                throw new NativeTransportError(
                    "useSignalRClientOn: No native transport",
                );
            }

            return await mutateSignalRClientOn(
                {
                    transport,
                    workspaceId,
                },
                { methodName },
            );
        },
    });
};
