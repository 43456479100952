const NEXT_URL_QUERY_PARAM = "next";

/*
 * If there is a "next" parameter in the URL then that URL is returned, with any
 * additional query string parameters in the original URL preserved and added to the URL
 * of the next URL
 * If there is a no "next" parameter then this returns the defaultPathName as the path
 * with the original query string parameters in the new URL.
 * Note: The parameter keepExistingSearchParams keeps any existing parameters in the URL
 * on the next URL, and removes them if not. The flag is here to ensure the two functions
 * this replaces both work in the same way as previously, but doesn't attempt to speak
 * for why we might sometimes want to do this and sometimes not. We should probably tidy
 * this to consistent behaviour at some point.
 */
export const getNextUrlOrDefault = (
    search: string,
    defaultPathName: string,
    keepExistingSearchParams = true,
): { pathname: string; search: string } => {
    const params = new URLSearchParams(search);
    // Extract the next url param and remove it from the original list
    const next = params.get(NEXT_URL_QUERY_PARAM);
    params.delete(NEXT_URL_QUERY_PARAM);

    if (next) {
        const nextRoute = splitRoute(next);
        if (keepExistingSearchParams) {
            // Combine the params from the next URL and any original params
            const combinedSearch = nextRoute.search
                ? nextRoute.search +
                  (params.toString() ? "&" + params.toString() : "")
                : searchFromParams(params.toString());
            return { pathname: nextRoute.pathname, search: combinedSearch };
        }
        return nextRoute;
    } else {
        return {
            pathname: defaultPathName,
            search: searchFromParams(params.toString()),
        }; // Default to home page
    }
};

export const splitRoute = (
    route: string,
): { pathname: string; search: string } => {
    const onlyFirstQuestionMarkRegex = /\?(.+)/;
    const [pathname, params] = route.split(onlyFirstQuestionMarkRegex);
    return { pathname, search: searchFromParams(params) };
};

// Adds in a ? at the beginning of a string of parameters, if any exist
const searchFromParams = (params: string | undefined): string =>
    params ? `?${params}` : "";
