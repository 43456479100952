import * as React from "react";

import { Text } from "@accurx/design";
import { SharedUrls } from "@accurx/shared";

import { SsoErrorCard } from "./SsoErrorCard";

export const SsoErrorNoOdsCode = () => (
    <SsoErrorCard
        header="We couldn't match your email to a current NHS organisation"
        body={
            <>
                <Text>
                    We integrate with the NHS personal demographic service which
                    matches demographic details of patients of health and care
                    services in England & Wales. As such, we are currently only
                    providing access to Accurx Web for organisations based in
                    England & Wales.
                </Text>

                <Text>
                    If you think that you should have access to Accurx Web,
                    would you be able to find your organisation's ODS code from{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={SharedUrls.ODSCodeWebPage}
                    >
                        {SharedUrls.ODSCodeWebPage}
                    </a>
                </Text>

                <Text>
                    Once you've done this, please email us{" "}
                    <a href="mailto:support.fleming@accurx.com">here</a> with
                    your role, ODS code, and a sentence on how you'll be using
                    Accurx for direct patient care. We aim to process all
                    requests by the end of the next working day.
                </Text>

                <Text>
                    If you would like to learn more about Accurx Web while we
                    handle your request, please{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={SharedUrls.FlemingWebPage}
                    >
                        visit our website.
                    </a>
                </Text>

                <Text>Thanks, The Accurx team</Text>
            </>
        }
    />
);
