import { Button, Ds, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFlex = styled(Ds.Flex)`
    position: relative;
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const MainPanel = styled.div`
    flex-grow: 1;
    min-height: calc(100vh - ${Tokens.BASE.grid * 32}px);
    overflow: hidden;
    h2 {
        margin-bottom: ${Tokens.SIZES[2]};
    }

    padding: ${Tokens.SIZES[5]} 0;
`;

export const StyledSidebarToggle = styled(Button)`
    color: ${Tokens.COLOURS.primary.blue["100"]};
    margin-left: -${Tokens.SIZES[1.5]};
    position: absolute;
    top: ${Tokens.SIZES[1.5]};
    left: 0;
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        display: none;
    }
`;
