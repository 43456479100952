import React from "react";

import { Flex, Pagination } from "@accurx/design";

export const PaginationControls = ({
    currentPage,
    totalPages,
    onPrevious,
    onNext,
}: {
    currentPage: number;
    totalPages: number;
    onPrevious: () => void;
    onNext: () => void;
}) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return (
        <Flex justifyContent="center">
            <Pagination>
                <Pagination.Navigation
                    currentPage={currentPage}
                    totalPages={totalPages}
                >
                    {!isFirstPage && (
                        <Pagination.PreviousButton onPrevious={onPrevious} />
                    )}
                    {!isLastPage && <Pagination.NextButton onNext={onNext} />}
                </Pagination.Navigation>
            </Pagination>
        </Flex>
    );
};
