import { useEffect } from "react";

import { Link, Text, Tokens } from "@accurx/design";
import { LinkIcon } from "@accurx/design/dist/components/Link/LinkIcon";
import { LinkText } from "@accurx/design/dist/components/Link/LinkText";
import styled from "styled-components";

import {
    trackPatientTriageDashboardEmptyComponentLoad,
    trackPatientTriageDashboardResourceClick,
} from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { IRequestSource, IRequestType } from "../shared.types";

const StyledText = styled(Text)`
    margin: ${Tokens.SIZES[1]} 0 ${Tokens.SIZES[2]} 0;
`;

export const NoReceptionDataPrompt = ({
    sectionName,
    requestSource,
    requestType,
}: {
    sectionName: string;
    requestSource: IRequestSource;
    requestType: IRequestType;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    useEffect(() => {
        trackPatientTriageDashboardEmptyComponentLoad({
            ...analyticsLoggedInProps,
            sectionName,
            dashboardName: "Patient Triage Dashboard",
            activeFilterList: [
                `Request Source - ${requestSource}`,
                `Request Type - ${requestType}`,
            ],
        });
        // only run this on component load.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLinkClick = () => {
        trackPatientTriageDashboardResourceClick({
            ...analyticsLoggedInProps,
            sectionName,
            dashboardName: "Patient Triage Dashboard",
            resourceTitle: "Patient Triage: Reception Flow",
            resourceLink:
                "https://support.accurx.com/en/articles/6259762-patient-triage-reception-flow",
        });
    };

    return (
        <>
            <Text skinny variant="subtitle" as="h3">
                No reception data to show
            </Text>
            <StyledText variant="body">
                Submit a request on behalf of a patient using Reception Flow to
                see data here
            </StyledText>
            <Link
                onClick={onLinkClick}
                openInNewTab
                href="https://support.accurx.com/en/articles/6259762-patient-triage-reception-flow"
            >
                <LinkText text="Learn more about Reception Flow" />
                <LinkIcon />
            </Link>
        </>
    );
};
