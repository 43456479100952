import React from "react";

import { Thread } from "@accurx/design";

import { formatConversationItemDateCreatedShort } from "app/workspaceConversations/utils/conversation.utils";
import { PatientSingleResponseConversationItem } from "shared/concierge/conversations/types/item.types";

import { renderPatientResponseSections } from "../../ConversationThread.helper";
import { BaseConversationThreadItem } from "../threadItem.types";

export type PatientSingleResponseNoteThreadItemProps =
    BaseConversationThreadItem<PatientSingleResponseConversationItem> & {
        organisationId: number;
        patientId: string | undefined | null;
    };

const NO_RESPONSE_TEXT = "No response from patient";

export const PatientSingleResponseThreadItem = ({
    item,
    organisationId,
    patientId,
    createdByDisplayName,
    handleOnItemRead,
}: PatientSingleResponseNoteThreadItemProps) => {
    const renderRequestSections = (): JSX.Element[] => {
        return renderPatientResponseSections({
            item,
            organisationId,
            patientId,
        });
    };

    return (
        <Thread.Message
            inboxType="received"
            dateCreated={formatConversationItemDateCreatedShort(item.createdAt)}
            senderFullName={{
                name: createdByDisplayName
                    ? {
                          fullName: createdByDisplayName,
                          shouldDisplayFullName: true,
                      }
                    : null,
            }}
            readActionProps={{ id: item.id, onMessageSeen: handleOnItemRead }}
        >
            {renderRequestSections()}
        </Thread.Message>
    );
};

export const patientSingleResponseThreadItemSummaryText = (
    item: PatientSingleResponseConversationItem,
): string => {
    // concat response answer text, deliberately fall back on empty string response
    return (
        item.sections
            .map((s) =>
                s.responses
                    .map((r) => r.answer?.replace(/\s+/, " "))
                    .join(", "),
            )
            .join(", ") || NO_RESPONSE_TEXT
    );
};
