import {
    useAnalytics,
    useMeaningfulActionAnalyticsProps,
} from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";

type BaseTrackingFields = {
    conversationId: string;
    messageType: string;
    hasError: boolean;
    isTestPatient: boolean;
    isMobileNumberOverridden: boolean;
    messageLength: number;
    countFragment: number;
    withUnicodeCharacter: boolean;
    withAttachment: boolean;
    countAttachmentFromTemplate: number;
    withPatientResponse: boolean;
    withSchedule: boolean;
    withNHSAdviceLink: boolean;
    enabledSaveToMedicalRecord: boolean;
};

export type TemplateTrackingFields =
    | {
          withTemplate: true;
          templateName: string;
          templateGroup?: string;
          isPresetTemplate: boolean;
      }
    | { withTemplate: false };

export type QuestionnaireTrackingFields =
    | {
          withFlorey: true;
          floreyName: string;
          isPresetTemplate: boolean;
      }
    | { withFlorey: false };

export const useReplyAnalytics = () => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { isMultiFactorAuthActive, isMultiFactorAuthEnabled } =
        useMeaningfulActionAnalyticsProps();

    const trackReplyResponse = (
        args: BaseTrackingFields &
            TemplateTrackingFields &
            QuestionnaireTrackingFields,
    ) => {
        track("PatientMessageSend Button Response", {
            ...nativeTrackingFields,
            isMultiFactorAuthActive,
            isMultiFactorAuthEnabled,
            isReply: true,
            origin: "/inbox",
            eventVersion: 8,
            ...args,
        });
    };

    return trackReplyResponse;
};
