import { ClinicAppointment } from "api/Appointment/IntegratedClinics";
import { isContactable } from "app/patientLists/integratedClinics/shared/isContactable";

import { SelectedAppointments } from "../SelectClinicPatients";

export function isEverySelectableAppointmentSelected(
    appointments: ClinicAppointment[],
    selectedAppointments: SelectedAppointments,
) {
    return appointments.every((appointment) => {
        if (isContactable(appointment)) {
            return selectedAppointments.has(appointment.id);
        }

        return true;
    });
}
