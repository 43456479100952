import React, { useEffect, useRef, useState } from "react";

import { Text } from "@accurx/design";
import {
    DateFormatOptions,
    DateHelpers,
    NhsNumberHelpers,
} from "@accurx/shared";
import styled from "styled-components";

import { WebPatient } from "app/patients/Patient.types";
import { sentenceCase } from "shared/formatters/String";
import { useMountedState } from "shared/useMountedState";

import { MISSING_DATA_TEXT } from "../recordView/RecordView.constant";
import {
    SkeletonPreview,
    SkeletonSubtitle,
} from "../sharedComponents/loadingSkeleton/SkeletonText";
import {
    StyleHeaderLeft,
    StyledHeaderRight,
    StyledWrapper,
} from "./PatientInfoSubMenuComponent.styles";

export type PatientInfoSubMenuProps = {
    patient: Pick<
        WebPatient,
        "dateOfBirth" | "displayName" | "nhsNumber" | "gender"
    > | null;
    children?: React.ReactNode;
};

// Some pages (patientConversationSingle/Page, ClinicianConversation)
// need to have a dynamic height so we need these values.
// This is temporary and will be refactored in the navigation revamp next cycle.
export const PATIENT_INFO_HEIGHT = "185px";
export const PATIENT_INFO_HEIGHT_SM = "209px";
export const PATIENT_INFO_HEIGHT_MD = "169px";

const PatientInfoText = styled(Text)`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    word-wrap: nowrap;
    margin-right: 1rem;
`;

/* A generic header for the navigation, which displays selected patient info */
const PatientInfoSubMenu = ({
    patient,
    children,
}: PatientInfoSubMenuProps): JSX.Element => {
    const isMounted = useMountedState();
    const [isPatientLoading, setIsPatientLoading] = useState(patient === null);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
        if (isMounted() && isPatientLoading && timerRef.current === null) {
            // whilst the data will most likely load quicker, switching from skeleton to
            // real text too quickly is somewhat jarring, so a little extra buffer on it
            // is added. ideally, we'd have a timer on request so that we *only* pad
            // the transition time if it's less than a certain amount.
            timerRef.current = window.setTimeout(
                () => setIsPatientLoading(false),
                1000,
            );

            return () => {
                if (timerRef.current !== null)
                    window.clearTimeout(timerRef.current);
            };
        }
    }, [isMounted, isPatientLoading, timerRef]);

    return (
        <StyledWrapper>
            <StyleHeaderLeft>
                <>
                    {/* patient name subtitle */}
                    <PatientInfoText variant="subtitle" colour="night" skinny>
                        {isPatientLoading ? (
                            <SkeletonSubtitle charCount={20} />
                        ) : (
                            patient?.displayName || "Unknown name"
                        )}
                    </PatientInfoText>

                    {/* patient demographics info */}
                    <div className="d-flex flex-wrap align-items-center">
                        <PatientInfoText variant="preview" colour="night">
                            NHS: &nbsp;
                            {isPatientLoading ? (
                                <SkeletonPreview charCount={12} />
                            ) : patient?.nhsNumber ? (
                                NhsNumberHelpers.formatNhsNumber(
                                    patient.nhsNumber,
                                )
                            ) : (
                                MISSING_DATA_TEXT
                            )}
                        </PatientInfoText>

                        <PatientInfoText variant="preview" colour="night">
                            Born: &nbsp;
                            {isPatientLoading ? (
                                <SkeletonPreview charCount={12} />
                            ) : patient?.dateOfBirth ? (
                                DateHelpers.formatDate(
                                    patient.dateOfBirth,
                                    DateFormatOptions.NHS_MANUAL_DATE_FORMAT,
                                )
                            ) : (
                                MISSING_DATA_TEXT
                            )}
                        </PatientInfoText>
                        <PatientInfoText variant="preview" colour="night">
                            Gender: &nbsp;
                            {isPatientLoading ? (
                                <SkeletonPreview charCount={6} />
                            ) : patient?.gender ? (
                                sentenceCase(patient.gender)
                            ) : (
                                MISSING_DATA_TEXT
                            )}
                        </PatientInfoText>
                    </div>
                </>
            </StyleHeaderLeft>
            {!!children && <StyledHeaderRight>{children}</StyledHeaderRight>}
        </StyledWrapper>
    );
};

export default PatientInfoSubMenu;
