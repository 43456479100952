import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Card, Text, Tokens } from "@accurx/design";
import { calculateFragments } from "@accurx/shared";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    fetchFloreysList,
    fetchSnomedCodes,
    updateMessage,
    updateSelectedBatchOptionToSaveToRecord,
    updateSelectedFloreyId,
    updateSelectedFloreyName,
    updateSelectedFloreySnomed,
} from "app/batchMessage/gp/BatchMessage.actions";
import {
    StyledLayoutWithFooter,
    StyledTextareaAutosize,
} from "app/batchMessage/gp/BatchMessage.styles";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import {
    BatchRoute,
    findBatchRoute,
} from "app/batchMessage/gp/BatchMessage.utils";
import { BatchMessageHeader } from "app/batchMessage/gp/components/BatchMessageHeader";
import { ComposeMsgInfo } from "app/batchMessage/gp/components/ComposeMsgInfo";
import {
    ComposeOptionToSaveToRecord,
    useSetSaveBatchToRecord,
} from "app/batchMessage/gp/components/ComposeOptionToSaveToRecord";
import {
    FOOTER_FLOREY,
    PLACEHOLDER_LINK_AUTO,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.constants";
import { MessageErrorType } from "app/batchMessage/gp/components/compose/BatchMessageCompose.types";
import {
    calculateMessageLength,
    findFloreyCopd,
    isValidMessage,
} from "app/batchMessage/gp/components/compose/BatchMessageCompose.utils";
import { SnomedCodeFeedback } from "app/batchMessage/gp/components/snomedCodes/SnomedCodeFeedback";
import { Breadcrumb } from "app/practices/breadcrumb/Breadcrumb";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

import { MessageComposeWrapper } from "./components/MessageComposeWrapper";

export const ComposeCopd = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(
        ({ practices }: ApplicationState) =>
            practices?.items.find(
                (x) => x.id.toString() === practices?.selectedPractice,
            )?.name ?? "",
    );
    const initialSelectedFloreyName = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyName,
    );
    const initialSelectedFloreyId = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreyId,
    );
    const floreysList = useAppSelector(
        ({ batchMessage }) => batchMessage.floreysList,
        shallowEqual,
    );
    const gettingFloreysListStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingFloreysListStatus,
        shallowEqual,
    );
    const maximumPatientGreetingLength = useAppSelector(
        ({ batchMessage }) =>
            batchMessage.batchMessageDetails.maximumPatientGreetingLength,
    );
    const patientMessage = useAppSelector(
        ({ batchMessage }) => batchMessage.patientMessage,
    );
    const selectedFloreySnomedCode = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedFloreySnomedCode,
    );

    const gettingSnomedCodesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.gettingSnomedCodesStatus,
        shallowEqual,
    );
    const snomedCodes = useAppSelector(
        ({ batchMessage }) => batchMessage.snomedCodes,
        shallowEqual,
    );

    const checkMessageTextAndSetState = useCallback(
        (textToCheck: string) => {
            const totalCharacterCount = calculateMessageLength(
                BatchType.FLOREY,
                maximumPatientGreetingLength,
                textToCheck.length,
                practiceName.length,
            );
            setCharacterCount(totalCharacterCount);

            const validation = isValidMessage(textToCheck, totalCharacterCount);
            setValidMessageBody(validation.validMessageBody);
            setMessageErrorMessage(validation.messageErrorMessage);
            setMessageErrorType(validation.messageErrorType);
        },
        [maximumPatientGreetingLength, practiceName.length],
    );

    const checkAndSetMessageText = (messageText: string) => {
        checkMessageTextAndSetState(messageText);
        setMessageBody(messageText);
    };

    // States: to be saved to redux
    const [messageBody, setMessageBody] = useState(patientMessage);
    const [selectedSnomedCode, setSelectedSnomedCode] = useState(
        selectedFloreySnomedCode,
    );
    const [selectedFloreyId, setSelectedFloreyId] = useState(
        initialSelectedFloreyId,
    );
    const [selectedFloreyName, setSelectedFloreyName] = useState(
        initialSelectedFloreyName,
    );

    // States: local only
    const [validMessageBody, setValidMessageBody] = useState(false);
    const [messageErrorMessage, setMessageErrorMessage] = useState("");
    const [messageErrorType, setMessageErrorType] = useState(
        MessageErrorType.None,
    );
    const [characterCount, setCharacterCount] = useState(0);

    // Load all florey for the dropdown
    useEffect(() => {
        if (gettingFloreysListStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(fetchFloreysList(practiceId));
        }
    }, [dispatch, practiceId, gettingFloreysListStatus]);

    // Automatically select COPD for the dropdown
    useEffect(() => {
        if (gettingFloreysListStatus === UpdatingStatus.Loaded) {
            const selectedFloreyCopd = findFloreyCopd(floreysList);
            if (selectedFloreyCopd !== null) {
                setSelectedFloreyId(selectedFloreyCopd.id);
                setSelectedFloreyName(selectedFloreyCopd.title);
                setSelectedSnomedCode(
                    selectedFloreyCopd.medicalCodeIdentity.code,
                );
                if (!messageBody) {
                    // Keep edited message if it exist
                    setMessageBody(selectedFloreyCopd.templateBody);
                }
            }
        }
    }, [floreysList, gettingFloreysListStatus, messageBody]);

    useEffect(() => {
        checkMessageTextAndSetState(messageBody);
    }, [checkMessageTextAndSetState, messageBody]);

    // Load all snomeds for the Feedback
    useEffect(() => {
        if (gettingSnomedCodesStatus === UpdatingStatus.Initial && practiceId) {
            dispatch(fetchSnomedCodes(practiceId));
        }
    }, [dispatch, practiceId, gettingSnomedCodesStatus]);

    useEffect(() => {
        ChainAnalyticsTracker.trackBatchPatientMessageReviewPageView({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
        });
    }, [analyticsLoggedInProps, history.location.pathname]);

    const updateMessageText = (e: ChangeEvent<HTMLTextAreaElement>) => {
        checkAndSetMessageText(e.target.value);
    };

    const { shouldSaveBatchToRecord, setShouldSaveBatchToRecord } =
        useSetSaveBatchToRecord();

    const handleBack = () => {
        ChainAnalyticsTracker.trackBatchBackClick({
            ...analyticsLoggedInProps,
            origin: history.location.pathname,
            isTrustFlow: false,
        });
    };

    const handleSave = () => {
        // TODO: This needs to be refactored into one action
        dispatch(updateMessage(messageBody));
        dispatch(updateSelectedFloreySnomed(selectedSnomedCode));
        dispatch(updateSelectedFloreyId(selectedFloreyId));
        dispatch(updateSelectedFloreyName(selectedFloreyName));
        dispatch(
            updateSelectedBatchOptionToSaveToRecord(shouldSaveBatchToRecord),
        );
    };

    /**
     * calculate fragments by passing in the body of the
     * batch message as well as padding it to length we assume it is
     * accounting for things like practice name and message footer
     */
    const fragmentCount = calculateFragments(
        `${messageBody}${"a".repeat(
            Math.abs(characterCount - messageBody.length),
        )}`,
    );

    const routes = findBatchRoute(practiceId, BatchRoute.COMPOSE);
    return (
        <StyledLayoutWithFooter>
            <Breadcrumb title="Batch Messaging COPD" />
            <div className="row mb-4">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <BatchMessageHeader
                        title={"Write a COPD Questionnaire invite"}
                        stepNumber={1}
                    />
                </div>
                <div className="col-12 col-lg-6 offset-lg-3">
                    <Card isElevated={false} spacing={1.5}>
                        <Text
                            as="label"
                            variant="label"
                            props={{ htmlFor: "message-body" }}
                        >
                            Message
                        </Text>
                        <Text>
                            This message will be sent to multiple patients, so
                            please do not include information relating to
                            individual patients.
                        </Text>
                        <MessageComposeWrapper>
                            <StyledTextareaAutosize
                                id="message-body"
                                autoFocus
                                value={messageBody}
                                onChange={updateMessageText}
                                placeholder={"Please enter a message"}
                                data-testid="message-body"
                                style={{
                                    outline: "none",
                                    color: Tokens.COLOURS.greyscale.zinc,
                                    marginTop: `${Tokens.SIZES[1]}`,
                                    marginBottom: `${Tokens.SIZES[1]}`,
                                }}
                                disabled={false}
                            />
                            <Text data-testid="message-footer">
                                {FOOTER_FLOREY + PLACEHOLDER_LINK_AUTO}
                            </Text>
                        </MessageComposeWrapper>
                        <ComposeMsgInfo
                            characterCount={characterCount}
                            fragmentCount={fragmentCount}
                            messageErrorMessage={messageErrorMessage}
                            messageErrorType={messageErrorType}
                        />
                        <div className="mb-4">
                            <ComposeOptionToSaveToRecord
                                shouldSaveBatchToRecord={
                                    shouldSaveBatchToRecord
                                }
                                setShouldSaveBatchToRecord={
                                    setShouldSaveBatchToRecord
                                }
                                batchType={BatchType.COPD}
                            />
                        </div>
                        {!!selectedFloreyId && (
                            <SnomedCodeFeedback
                                selectedSnomedCode={selectedSnomedCode}
                                snomedCodes={snomedCodes}
                            />
                        )}
                    </Card>
                    <StepsFooter
                        backText={"Back"}
                        backLink={routes.back}
                        backClickFunction={handleBack}
                        forwardText={"Add a patient list"}
                        forwardLink={routes.forward}
                        forwardClickFunction={handleSave}
                        disabled={!validMessageBody}
                    />
                </div>
            </div>
        </StyledLayoutWithFooter>
    );
};

export default ComposeCopd;
