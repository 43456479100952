import React from "react";

import { Button, Card, Feedback, Icon, Text } from "@accurx/design";
import { useHistory } from "react-router-dom";

import {
    TourTestPatient,
    getTourMode,
    setTourMode,
} from "app/onboarding/Onboarding.helper";
import { ROUTES_EXTENSION } from "shared/Routes";
import { findBaseRoute } from "shared/RoutesHelper";
import { useCurrentUserId } from "store/hooks";

import { EXAMPLE_REQUEST_ID } from "../RecordView.constant";
import { CardSpacer } from "../medicalRecord/CardSpacer";

export const RequestDemoPatient = (): JSX.Element => {
    const history = useHistory();
    const accuRxUserId = useCurrentUserId() || "";

    const goToRecord = (): void => {
        if (getTourMode(accuRxUserId) === TourTestPatient.RecordView) {
            setTourMode(TourTestPatient.RecordViewEnded, accuRxUserId);
        }
        history.replace(
            `${findBaseRoute(history.location.pathname)}${
                ROUTES_EXTENSION.remoteRecordViewMedicalRecord
            }?request=${EXAMPLE_REQUEST_ID}`,
        );
    };

    const ViewDemoPatientCard = (): JSX.Element => {
        const CardHeader = (
            <div className="d-flex justify-content-between align-items-center">
                <Text
                    variant="subtitle"
                    as="h1"
                    skinny
                    props={{ className: "mr-3" }}
                >
                    Record available
                </Text>
            </div>
        );

        const CardFooter = (
            <div className="d-flex justify-content-end align-items-center">
                <Button
                    text="View record"
                    onClick={goToRecord}
                    className="text-nowrap"
                />
            </div>
        );

        return (
            <Card header={CardHeader} footer={CardFooter}>
                <Feedback
                    colour="learning"
                    title="Click the “View record” button"
                    content="Patient permission is not required for test patient."
                />
            </Card>
        );
    };

    const HowToCard = (): JSX.Element => {
        const CardContent = (): JSX.Element => (
            <>
                <Text variant="label">
                    How does Record View work with other patients?
                </Text>
                <Text
                    colour="metal"
                    props={{
                        className: "mb-0",
                        "data-testid": "rv-sample-sms",
                    }}
                >
                    When viewing your patient's GP record, you will first need
                    to request permission from the patient. To learn how this
                    works, read more in the{" "}
                    <Text
                        as="a"
                        variant="link"
                        props={{
                            href: "https://support.accurx.com/en/articles/5665559-record-view-how-to-guide",
                            rel: "noopener noreferrer",
                            target: "_blank",
                        }}
                    >
                        Record View 'How-To' Guide
                        <Icon
                            props={{ className: "align-bottom" }}
                            name="OpenWindow"
                            size={3}
                            colour={"blue"}
                        />
                    </Text>
                </Text>
            </>
        );

        return (
            <Card>
                <CardContent />
            </Card>
        );
    };

    return (
        <>
            <CardSpacer spacing="medium" />
            <ViewDemoPatientCard />
            <CardSpacer spacing="medium" />
            <HowToCard />
        </>
    );
};
