import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { UnreadTickets } from "./FlemingDtos";
import * as MessagingApi from "./MessagingApi";

export const useUnreadTicketsQuery = createQueryHook<number, UnreadTickets>(
    (workspaceId) => ({
        queryKey: ["unreadtickets", { workspaceId }],
        queryFn: () =>
            MessagingApi.getUnreadTickets(workspaceId).then(returnDataOrThrow),
    }),
);
