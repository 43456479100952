import { IWrappedResult } from "@accurx/shared/dist";
import omit from "lodash/omit";

import FlemingApi from "api/FlemingApiClient";
import {
    IMessagePatientResponse,
    MessagePatientAllEndpointsRequest,
    MessagePatientWithTokenRequest,
    MessagePatientWithTwoFactorRequest,
} from "api/FlemingDtos";
import { AnalyticsMapper, FlemingAnalyticsTracker } from "app/analytics";
import { isMessageSentSuccess } from "shared/MessageHelper";

import {
    FileRemovedAction,
    FileUploadFinishedAction,
} from "../fileUpload/FileUploadActions";
import { MessageType } from "./SendMessageReducer";

// ACTION TYPES

export const SEND_MESSAGE_STARTED = "SEND_MESSAGE_STARTED";
export const SEND_MESSAGE_FINISHED = "SEND_MESSAGE_FINISHED";
export const CHANGE_MESSAGE_TYPE = "CHANGE_MESSAGE_TYPE";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface SendMessageStartedAction {
    type: typeof SEND_MESSAGE_STARTED;
    requestData: MessagePatientAllEndpointsRequest;
}

interface SendMessageFinishedAction {
    type: typeof SEND_MESSAGE_FINISHED;
    response: IWrappedResult<IMessagePatientResponse>;
}

interface ChangeMessageTypeAction {
    type: typeof CHANGE_MESSAGE_TYPE;
    messageType: MessageType;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | SendMessageStartedAction
    | SendMessageFinishedAction
    | FileUploadFinishedAction
    | FileRemovedAction
    | ChangeMessageTypeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    sendPatientMessage:
        (
            messageRequest: MessagePatientAllEndpointsRequest,
            sentMessageAnalyticsProps: FlemingAnalyticsTracker.SentPatientMessageRudderStackAnalyticsProps,
            failureCallback?: (error: string) => void,
            successCallback?: () => void,
        ): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            dispatch({
                type: SEND_MESSAGE_STARTED,
                requestData: messageRequest,
            });

            // It is possible for messageRequest.patientToken to be an empty string
            // In that case we send the message with the external id instead
            const canSendMessageWithToken = !!messageRequest.patientToken;

            let response;
            if (canSendMessageWithToken) {
                const request: MessagePatientWithTokenRequest = omit(
                    messageRequest,
                    ["patientExternalIdentity"],
                );
                response = await FlemingApi.messagePatientWithToken(request);
            } else {
                const request: MessagePatientWithTwoFactorRequest = omit(
                    messageRequest,
                    ["patientToken", "useMobileNumberFromPDS"],
                );

                response = await FlemingApi.messagePatientWithExternalIdentity(
                    request,
                );
            }

            const entryPatientNhsNumber =
                getState().searchForPatient.entryPatientNhsNumber;
            const sendingMessageToSamePatientAsInEntryLink =
                messageRequest.nhsNumber === entryPatientNhsNumber;

            // Prepare analytics
            const state = getState();
            const loggedInProps = AnalyticsMapper.getAnalyticsLoggedInProps(
                state.account,
                state.sessionAnalytics,
            );
            if (sendingMessageToSamePatientAsInEntryLink) {
                FlemingAnalyticsTracker.trackSendMessageSamePatient(
                    loggedInProps,
                );
            }

            // We want to dispatch this event before calling any callback functions as they
            // may (and do) rely on the send message event to have finished before running
            dispatch({
                type: SEND_MESSAGE_FINISHED,
                response: response,
            });

            if (
                isMessageSentSuccess(
                    response,
                    messageRequest.isVideoConsult,
                    messageRequest.videoConsultTime,
                )
            ) {
                messageRequest.isVideoConsult
                    ? FlemingAnalyticsTracker.trackSentVideoConsultInviteSuccess(
                          sentMessageAnalyticsProps,
                      )
                    : FlemingAnalyticsTracker.trackSentPatientMessageSuccess(
                          sentMessageAnalyticsProps,
                      );
                // No need to differentiate patient message vs video consult invite for Intercom
                window.Intercom("trackEvent", "message-sent");

                successCallback && successCallback();
            } else {
                messageRequest.isVideoConsult
                    ? FlemingAnalyticsTracker.trackSentVideoConsultInviteFailure(
                          sentMessageAnalyticsProps,
                      )
                    : FlemingAnalyticsTracker.trackSentPatientMessageFailure(
                          sentMessageAnalyticsProps,
                      );

                failureCallback &&
                    failureCallback(
                        response.error ||
                            "Sorry your message failed to send! Please try again",
                    );
            }
        },

    changeMessageType: (messageType: MessageType) => ({
        type: CHANGE_MESSAGE_TYPE,
        messageType,
    }),
};
