import { GPRequestResult } from "@accurx/api/portal";
import {
    AnalyticsProperties,
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    AnalyticsPatientId,
    AnalyticsSearchPatientOrigin,
    FlemingLoggedInCustomProperties,
    IsTestPatientProps,
    OriginProps,
    SharedLoggedInRudderStackAnalyticProps,
} from "./FlemingAnalyticsConstants";
import {
    TrackFlemingEvent,
    reduceToFlemingLoggedInCustomProperties,
} from "./FlemingEventTracker";
import { trackPageLoadFleming } from "./PageLoadEvents";

export type RecordViewAnalyticsProps = FlemingLoggedInCustomProperties &
    AnalyticsPatientId &
    RequestId;

export type RecordViewSelectProductAnalyticsProps = RecordViewAnalyticsProps &
    AnalyticsSearchPatientOrigin;

export type RecordViewEnabledForPatientProps = {
    isRecordViewEnabledForPatient: boolean;
};

type RecordViewRequestPageViewProps = RecordViewSelectProductAnalyticsProps &
    Referrer &
    IsTestPatientProps &
    RecordViewOrigin;

type Referrer = { referrer: string };

type RequestId = { requestId: string | null };

type RecordViewOrigin = {
    recordViewOrigin: MedicalRecordViewOrigin;
};

export enum MedicalRecordViewOrigin {
    None = "None", // this would be a page load for example
    PatientRequestFlow = "PatientRequestFlow",
    MedicalRecordsTable = "MedicalRecordsTable",
    PatientMessageSent = "PatientMessageSent",
    VideoConsultConfirmation = "VideoConsultConfirmation",
}

/**
 * Prepends `Record View - ` in front of all of the exported analytics functions
 * */
const TrackRecordViewEvent = (
    eventName: string,
    props: AnalyticsProperties,
): void => {
    TrackFlemingEvent(`Record View - ${eventName}`, props);
};

/** REQUEST FLOW */
type TwoFactorProps = { is2Faed: boolean };
type RecordViewMedicalRecordAvailableProps =
    RecordViewSelectProductAnalyticsProps &
        TwoFactorProps & {
            isTestPatient: boolean;
            gpNationalCode: string | null;
        };

type RecordViewRequestRudderStackAnalyticsProps =
    SharedLoggedInRudderStackAnalyticProps & RequestId;

const mapToRecordViewRequestRudderStackAnalyticsProps = (
    props: RecordViewSelectProductAnalyticsProps,
): RecordViewRequestRudderStackAnalyticsProps => {
    return {
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        ...reduceToFlemingLoggedInCustomProperties(props),
        requestId: props.requestId,
    };
};

/**
 * Triggered when someone lands on the record view request page
 *
 * Event type: Page View
 * */
export const trackRecordViewRequestPageView = (
    props: RecordViewRequestPageViewProps,
): void => {
    trackPageLoadFleming({
        ...props,
        page: "Record View Request",
    });
    GenericTrackEvent({
        object: "RecordRequest",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: mapToRecordViewPageViewRudderStackAnalyticsProps(props),
    });
};

/**
 * Triggered when someone lands on the request code via SMS page
 *
 * Event type: Page View
 * */
export const trackRecordRequestCodePageView = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "RecordRequestCode",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * Triggered when someone lands on the confirm SMS code page
 *
 * Event type: Page View
 * */
export const trackRecordRequestConfirmCodePageView = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "RecordRequestCodeEnter",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User searched for a patient and visited select product page,
 * and the Record View feature was available for the search patient
 * (regardless of user's feature flag status)
 *
 * Event type: Select product page load
 * */
export const trackRecordViewAvailableForPatient = (
    props: RecordViewMedicalRecordAvailableProps,
): void => {
    TrackRecordViewEvent("Product Available For Searched Patient", props);
};

type RecordViewMedicalRecordUnavailableProps =
    RecordViewSelectProductAnalyticsProps &
        RecordViewMedicalRecordAvailableProps &
        TwoFactorProps & {
            /**
             * Server provides a reason why the feature might not be
             * available for a searched patient
             */
            reason: GPRequestResult;
        };

/**
 * User searched for a patient and visited select product page,
 * and the Record View feature was NOT available for the search patient
 * (regardless of user's feature flag status)
 *
 * Event type: Select product page load
 * */
export const trackRecordViewUnavailableForPatient = (
    props: RecordViewMedicalRecordUnavailableProps,
): void => {
    TrackRecordViewEvent("Could Not Use Product For Searched Patient", props);
};

/**
 * User saw the disabled Record View card and clicked on button
 * to "Invite to Record View" button
 *
 * Event type: Button click
 */
export const trackRecordViewInviteViaAccuMail = (
    props: RecordViewMedicalRecordUnavailableProps,
): void => {
    TrackRecordViewEvent("Clicked On Invite To Record View", props);
};

/**
 * User searched for a patient and clicks to say "YES" when
 * asked if they can communicate with the patient
 *
 * Event type: Button click
 */
export const trackRecordRequestWithPatient = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent("Clicked to confirm with patient", props);
    GenericTrackEvent({
        object: "RecordRequestWithPatient",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User searched for a patient and clicks to say "NO" when
 * asked if they can communicate with the patient
 *
 * Event type: Button click
 */
export const trackRecordRequestWithoutPatient = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent("Clicked to confirm NOT with patient", props);
    GenericTrackEvent({
        object: "RecordRequestWithoutPatient",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User said "No" to being able to communicate with the patient
 * and clicks on the button to start requesting access to view the record
 *
 * Event type: Button click
 */
export const RecordRequestWithoutPatientContinue = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent(
        "Without patient and clicked to continue to request record",
        props,
    );
    GenericTrackEvent({
        object: "RecordRequestWithoutPatientContinue",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User said "No" to being able to communicate with the patient
 * and clicks on the button to go back to the Product Option page
 *
 * Event type: Button click
 */
export const RecordRequestWithoutPatientBack = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent(
        "Without patient and clicked to go back to Select Product page",
        props,
    );
    GenericTrackEvent({
        object: "RecordRequestWithoutPatientBack",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User clicked button to send SMS to patient for Record View authorisation
 *
 * Event type: Button click
 * */
export const trackRecordViewSendPatientAuth = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent("Clicked To Send Patient Consent Sms", props);
    GenericTrackEvent({
        object: "RecordRequestCodeSend",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * User entered auth code and clicked confirm
 *
 * Event type: Button click
 * */
export const trackRecordViewConfirmCode = (
    props: RecordViewSelectProductAnalyticsProps & { hasError: boolean },
): void => {
    GenericTrackEvent({
        object: "RecordRequestCodeConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: {
            ...mapToRecordViewRequestRudderStackAnalyticsProps(props),
            hasError: props.hasError,
        },
    });
};

/**
 * User entered & submitted patient auth code provided successfully
 *
 * Event type: API response
 * */
export const trackRecordViewSubmitPatientAuthCodeSuccess = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent("Submitted Patient Consent Code Successfully", props);
};

/**
 * User entered & submitted patient auth code provided, but the request failed
 *
 * Event type: API response
 * */
export const trackRecordViewSubmitPatientAuthCodeFailure = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    TrackRecordViewEvent(
        "Submitted Patient Consent Code But Request Failed",
        props,
    );
};

/** VIEW RECORD */

type RecordViewMedicalRecordAnalyticsProps = RecordViewAnalyticsProps &
    IsTestPatientProps &
    RequestId &
    RecordViewOrigin &
    Referrer;

type RecordViewRudderStackAnalyticsProps =
    RecordViewRequestRudderStackAnalyticsProps &
        IsTestPatientProps &
        OriginProps;

/**
 * User opened the medical record page
 *
 * Event type: API Response
 * */
export const trackRecordViewViewedRecordSuccess = (
    props: RecordViewMedicalRecordAnalyticsProps,
): void => {
    TrackRecordViewEvent("Viewed Record Successfully", props);
    GenericTrackEvent({
        object: "RecordView",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: mapToRecordViewPageViewRudderStackAnalyticsProps(props),
    });
};

const mapToRecordViewPageViewRudderStackAnalyticsProps = (
    props: RecordViewMedicalRecordAnalyticsProps,
): RecordViewRudderStackAnalyticsProps => {
    return {
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
        ...reduceToFlemingLoggedInCustomProperties(props),
        isTestPatient: props.isTestPatient,
        requestId: props.isTestPatient ? "Example" : props.requestId,
        origin: props.referrer ?? "Unknown",
    };
};

/**
 * User tried to load the medical record,
 * but the request failed
 *
 * Event type: API Response
 * */
export const trackRecordViewViewedRecordFailed = (
    props: RecordViewMedicalRecordAnalyticsProps,
): void => {
    TrackRecordViewEvent("Failed To View Record", props);
};

export type NavigatedToRecordViewProps = RecordViewAnalyticsProps & {
    recordViewOrigin: MedicalRecordViewOrigin;
};
/**
 * A user sent a patient a message, and upon successful send, they
 * saw and clicked the Request record button
 *
 * Event type: Button click
 * */
export const trackRecordViewRequestRecord = (
    props: NavigatedToRecordViewProps,
): void => {
    TrackRecordViewEvent("User Navigated to Request Record", props);
};

/**
 * A user sent a patient a message, and upon successful send, they
 * saw and clicked the Try with a test patient link
 *
 */
export const trackRecordViewTryWithTestPatient = (
    props: NavigatedToRecordViewProps,
): void => {
    TrackRecordViewEvent("User Navigated To Test Patient", props);
};

/**
 * A user clicks on the link to try with a test patient from the
 * select product page.
 */
export const trackRecordViewTryWithTestPatientFromProductPage = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "RecordViewWithTestPatient",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: mapToRecordViewRequestRudderStackAnalyticsProps(props),
    });
};

/**
 * A user clicks on the Medical Records tab in the navigation
 */
export const trackMedicalRecordsPageView = (
    props: RecordViewSelectProductAnalyticsProps & {
        recordsAvailable: number | undefined;
    },
): void => {
    GenericTrackEvent({
        object: "RecordManagement",
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: {
            ...mapToRecordViewRequestRudderStackAnalyticsProps(props),
            recordsAvailable: props.recordsAvailable,
        },
    });
};

/**
 * A user clicks on the Record View tile on the Select Product page
 */
export const trackRecordViewProductSelected = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "ProductOption",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps({
            ...props,
            optionSelected: "RecordView",
        }),
    });
};

/**
 * A user clicks on a button that intiates a product
 */
export const trackProductOptionSelected = (
    props: RecordViewSelectProductAnalyticsProps,
): void => {
    GenericTrackEvent({
        object: "ProductOption",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps({
            ...props,
            optionSelected: "RecordView",
        }),
    });
};
