import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { useQueryClient } from "@tanstack/react-query";

import {
    ClinicianConversationApi,
    ConversationSummary,
} from "api/ClinicianConversationApi";
import { useCurrentUserId } from "store/hooks";

export const CONVERSATION_SUMMARIES_CACHE_KEY = "ConversationSummaries";

type ClinicianConversationSummariesQueryVariables = {
    workspaceId: number;
    assignedToMeOnly: boolean;
    pageNumber: number;
    pageSize: number;
};

export const useClinicianConversationSummariesQuery = createQueryHook<
    ClinicianConversationSummariesQueryVariables,
    ConversationSummary[]
>(({ workspaceId, assignedToMeOnly, pageNumber, pageSize }) => {
    const userId = useCurrentUserId();
    const queryClient = useQueryClient();
    const getQueryKey: (page: number) => [string, Record<string, unknown>] = (
        page,
    ) => [
        CONVERSATION_SUMMARIES_CACHE_KEY,
        { workspaceId, assignedToMeOnly, pageNumber: page, pageSize },
    ];

    const getLastDateOnPreviousPage = () =>
        queryClient
            .getQueryData<ConversationSummary[]>(getQueryKey(pageNumber - 1))
            ?.at(-1)?.lastUpdated;

    return {
        cacheTime: 0,
        queryKey: getQueryKey(pageNumber),
        queryFn: async () => {
            const lastDateOnPreviousPage =
                pageNumber > 1 ? getLastDateOnPreviousPage() : undefined;
            const response = await ClinicianConversationApi.getConversations(
                workspaceId,
                pageSize,
                assignedToMeOnly ? userId : undefined,
                lastDateOnPreviousPage,
            );
            return returnDataOrThrow(response).conversations;
        },
    };
});
