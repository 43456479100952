import { useEffect, useRef, useState } from "react";

import { useBrowserEnvironment } from "@accurx/native";
import { Toggle } from "@accurx/navigation";
import { createPortal } from "react-dom";

import * as Styled from "./Drawer.styles";

type Placement = "left" | "right";

export type DrawerProps = Toggle & {
    placement?: Placement;
    children: React.ReactNode;
};

export const Drawer = (props: DrawerProps) => {
    // 1-time flag to denote the *first* time the slideout is shown. once it
    // has been shown once, it is active thereafter

    const [isDrawerActive, setIsDrawerActive] = useState<boolean>(
        props.isOpen === true,
    );

    const env = useBrowserEnvironment();

    const el = useRef(document.createElement("div"));

    // insert the div we're rendering into, into the portal root
    useEffect(() => {
        const current = el.current;

        document.body.appendChild(current);
        return (): void => void document.body.removeChild(current);
    }, []);

    // set Drawer to be activate on first open
    useEffect(() => {
        if (!isDrawerActive && props.isOpen) {
            setIsDrawerActive(true);
        }
    }, [props.isOpen, isDrawerActive]);

    // disable scrolling on body when Drawer is open
    useEffect(() => {
        const body = document.querySelector<HTMLBodyElement>("body");

        // on WebView overflow is always set to hidden to prevent bounce scroll
        if (body && env !== "WebView") {
            body.style.overflow = props.isOpen ? "hidden" : "auto";
        }

        return (): void => {
            if (body && env !== "WebView") {
                body.style.overflow = "auto";
            }
        };
    }, [props.isOpen, env]);

    const placement = props.placement ?? "right";

    const drawerContent = (
        <>
            <Styled.DrawerOverlay
                isOpen={props.isOpen}
                onClick={props.toggle}
                aria-hidden={!props.isOpen}
            />
            <Styled.DrawerContent
                aria-hidden={!props.isOpen}
                isActive={isDrawerActive}
                isOpen={props.isOpen}
                placement={placement}
            >
                {props.children}
            </Styled.DrawerContent>
        </>
    );

    return createPortal(drawerContent, el.current);
};
