import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTooltipContentWrapper = styled.div`
    padding: ${Tokens.SIZES[2]};
    max-width: 15rem;
    position: relative;
`;

export const StyledEnablePatientResponse = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    // This is to override Bootstrap reboot styles (https://getbootstrap.com/docs/4.0/content/reboot/)
    // and is temporary.
    * > button {
        line-height: 0;
    }
`;
