import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Ds, Feedback, Flex, Text } from "@accurx/design";
import { toast } from "react-toastify";

import { useSendApprovalReminderMutation } from "../../hooks/mutations";

export const PendingApprovalModal = ({
    open,
    onOpenChange,
    appOrigin,
}: Ds.ModalProps & {
    appOrigin: string;
}) => {
    const track = useAnalytics();

    const { orgId: workspaceId } = useCurrentWorkspace();

    const { mutate, isLoading } = useSendApprovalReminderMutation();

    const handleCancel = () => {
        onOpenChange(false);
    };

    const handleSendReminder = () => {
        track("PendingApprovalModalSendReminder Button Click", {
            appOrigin,
        });
        mutate(
            { workspaceId },
            {
                onSuccess: () =>
                    toast(
                        <Feedback
                            colour="success"
                            title="Approval reminder sent"
                        />,
                    ),
                onError: () =>
                    toast(
                        <Feedback
                            colour="error"
                            title="There was a network issue"
                        >
                            Refresh the page and try again
                        </Feedback>,
                    ),
                onSettled: () => onOpenChange(false),
            },
        );
    };

    return (
        <Ds.Modal open={open} onOpenChange={onOpenChange}>
            <Ds.Modal.Section>
                <Flex flexDirection="column" gap="2">
                    <Ds.Modal.Title>
                        You can access more features when your membership is
                        approved
                    </Ds.Modal.Title>
                    <Text>
                        Once approved, you can view all conversations in the
                        workspace and collaborate with colleagues.
                    </Text>
                    <Flex justifyContent="flex-end" gap="2">
                        <Button
                            theme="secondary"
                            text="Close"
                            onClick={handleCancel}
                        />
                        <Button
                            text={
                                isLoading
                                    ? "Sending reminder..."
                                    : "Send reminder to workspace admins"
                            }
                            disabled={isLoading}
                            onClick={handleSendReminder}
                        />
                    </Flex>
                </Flex>
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
