import { Log } from "@accurx/shared";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ConversationGroup } from "domains/concierge/internal/types/ConversationGroup";
import { conversationGroupId } from "domains/concierge/internal/util/conversationGroupId";

export const subscribeToGroup = (
    state: Draft<ConciergeState["conversations"]>,
    action: PayloadAction<{
        filters: ConversationGroup["filters"];
        sortOptions: ConversationGroup["sortOptions"];
        loggingInfo: ConversationGroup["loggingInfo"];
        useCache?: boolean;
    }>,
) => {
    const {
        filters,
        sortOptions,
        loggingInfo,
        useCache = true, // groups are cached by default
    } = action.payload;
    const id = conversationGroupId(filters, sortOptions);
    const connectionId =
        state.connectionState.state === "Connected"
            ? state.connectionState.connectionId
            : null;

    // Increment the group's subscriber count
    state.groups.subscriberCounts[id] =
        (state.groups.subscriberCounts[id] ?? 0) + 1;

    if (state.groups.subscriberCounts[id] > 1) {
        Log.error(
            "Found a conversation group with more than one active subscribers. This could indicate a memory leak.",
            {
                tags: {
                    product: "Inbox",
                    groupName: state.groups.items[id].loggingInfo.name,
                    ...state.groups.items[id].loggingInfo.tags,
                },
            },
        );
    }

    // If this is a new group initialise it into an empty state
    if (!state.groups.items[id]) {
        state.groups.subscriberCounts[id] = 1;
        state.groups.items[id] = {
            id,
            filters,
            sortOptions,
            loggingInfo,
            members: [],
            continuationToken: undefined,
            ceiling: 0,
            isFullyLoaded: false,
        };
    }

    // For groups that use the cache we store a cache entry
    if (useCache) {
        const cacheEntry = state.groups.cache[id];

        // If there's already a cache entry then reset the unused since date. Ff
        // the group was stale it will be refetched. So we update the connection
        // ID here to mark the group as fresh.
        if (cacheEntry) {
            cacheEntry.connectionId = connectionId;
            cacheEntry.unusedSince = null;
        } else {
            // If there is no cache entry already create one
            state.groups.cache[id] = {
                id,
                unusedSince: null,
                connectionId,
            };
        }
    }

    return state;
};
