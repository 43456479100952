import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
    LoggedInCustomProperties,
} from "@accurx/shared";

import {
    AddRudderStackAnalyticProps,
    ChainLoggedInCustomProperties,
} from "./ChainAnalyticsConstants";
import { TrackRecordViewOptInEvent } from "./ChainAnalyticsTracker";

//#region Props

export type RecordViewNoSelectedPracticeAnalyticsProps =
    ChainLoggedInCustomProperties;

export type RecordViewOptInAnalyticsProps = ChainLoggedInCustomProperties & {
    /** Whether the user has already opted into Record View */
    isOptedIn: boolean;
};

export type RecordViewOptInRudderstackAnalyticsProps =
    LoggedInCustomProperties & {
        internalReferrer: string;
    };

export type RecordViewOptInPageClicksAnalyticsProps =
    RecordViewOptInAnalyticsProps & {
        /** Which interactive element did the user click */
        itemClicked: string;
    };

/**
 *
 * Remove opted-in property for Rudderstack event
 *
 */
export const MapToRudderstackProps = (
    props: RecordViewOptInAnalyticsProps,
): RecordViewOptInRudderstackAnalyticsProps => {
    const {
        isOptedIn: _isOptedIn,
        referrer: internalReferrer,
        ...propsWithoutAppInsights
    } = props;
    return {
        internalReferrer: internalReferrer,
        ...propsWithoutAppInsights,
    };
};
//#endregion Props

//#region Events

//#region Select Practice
/**
 * User landed on Select Practice page
 *
 * Event type: Page load
 * */
export const trackRecordViewSelectPracticePageView = (
    props: RecordViewNoSelectedPracticeAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Opened Select Practice Page", props);
};

/**
 * User selected the practice
 *
 * Event type: Link click
 * */
export const trackRecordViewSelectPractice = (
    props: RecordViewOptInAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Selected Practice", props);
};
//#endregion Select Practice

//#region Opt-in
/**
 * User loaded the Record View opt-in page
 *
 * Event type: Page load
 * */
export const trackRecordViewOptInPageView = (
    props: RecordViewOptInAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Opened Page", props);
};

/**
 * User clicked on interactive item in Record View page
 * Interactive items include links, buttons and accordions on the page
 *
 * Other clicks recorded in other events should not be included
 * (i.e. opt-in button click or modal dismiss)
 *
 * Event type: Click
 * */
export const trackRecordViewInteractiveItemClicked = (
    props: RecordViewOptInPageClicksAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Clicked On Opt-in Page Interactive Item", props);
};

/**
 * User clicked to opt into Record View
 *
 * Event type: Button click
 * */
export const trackRecordViewOptInButtonClicked = (
    props: RecordViewOptInAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Clicked On Opt-in Button", props);
    GenericTrackEvent({
        object: "RecordViewOptIn",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(MapToRudderstackProps(props)),
    });
};

/**
 * User closed opt-in confirmation modal
 *
 * Event type: Button click or keyboard
 * */
export const trackRecordViewOptInConfirmationScreenClosed = (
    props: RecordViewOptInAnalyticsProps,
): void => {
    TrackRecordViewOptInEvent("Closed Opt-in Confirmation Screen", props);
};
//#endregion Opt-in

//#endregion Events
