import { generatePath, useHistory, useParams } from "react-router";

import AddPatientsViaCopyPaste from "app/batchMessage/trust/pages/AddPatientsViaCopyPaste/AddPatientsViaCopyPaste";
import { ROUTES_WORKSPACE } from "shared/Routes";

export const AddPatientsViaCopyPastePage = () => {
    const history = useHistory();
    const { workspaceId } = useParams<{ workspaceId: string }>();

    return (
        <AddPatientsViaCopyPaste
            onUploadPatientSuccess={(batchId: string) => {
                const path = generatePath(
                    ROUTES_WORKSPACE.batchMessageTrustReview,
                    {
                        workspaceId,
                        batchId,
                    },
                );
                history.push(path);
            }}
        />
    );
};
