import { Label, LabelType } from "@accurx/api/ticket";
import { useCurrentWorkspace } from "@accurx/auth";
import { getApiEndpoint, httpClient } from "@accurx/shared";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

const mapLabelType = (type: keyof typeof LabelType): LabelType => {
    switch (type) {
        case "Outcome":
            return LabelType.Outcome;

        case "None":
        default:
            return LabelType.None;
    }
};

export const useTicketLabelsQuery = ({
    labelType,
    options,
}: {
    labelType: keyof typeof LabelType;
    options?: UseQueryOptions<Label[]>;
}) => {
    const { orgId } = useCurrentWorkspace();

    return useQuery<Label[]>(
        ["TicketLabels", { labelType }],
        async () => {
            const response = await httpClient.getReturnJsonSafeAsync(
                getApiEndpoint({
                    path: "/api/conversation/labelType/:labelType/labels",
                    query: `?organisationId=${orgId}`,
                    params: { labelType: `${mapLabelType(labelType)}` },
                }),
            );

            if (!response.success) {
                throw new Error(
                    response.error ??
                        `Error while attempting to fetch ticket labels of type ${labelType}.`,
                );
            }

            return response.result as Label[];
        },
        options,
    );
};
