import { Button, Text } from "@accurx/design";
import { i } from "images";

import { StyledFlex } from "./ConversationsError.styles";

type ConversationsErrorProps = {
    tryAgain: () => void;
};

export const ConversationsError = ({ tryAgain }: ConversationsErrorProps) => (
    <StyledFlex flexDirection="column" alignItems={"center"}>
        <img
            src={i("illustrations/error-icon-80px@3x.png")}
            alt="There was a problem loading your conversations"
            height="120"
        />
        <Text>There was a problem loading your conversations.</Text>
        <Button text="Try again" theme="primary" onClick={tryAgain} />
    </StyledFlex>
);
