import { createContext } from "react";

import noop from "lodash/noop";

type PaginationContextValue = {
    fetchMore: () => void;
    isFetchingMore: boolean;
    isFullyLoaded: boolean;
};

/**
 * The conversation feed uses react-window to "Virtualize" the list of
 * conversations. The FixedSizedList requires us to statically define the
 * elements for the list container and each list item. But those elements also
 * need access to dynamic values for pagination purposes.
 *
 * Wrapping the list in a context provider and putting dynamic pagination values
 * into context allows us to access them from inside the list and list item
 * elements.
 */
export const PaginationContext = createContext<PaginationContextValue>({
    fetchMore: noop,
    isFetchingMore: false,
    isFullyLoaded: false,
});
