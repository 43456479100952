import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import {
    OrganisationResource,
    deleteOrganisationResource,
} from "api/OrganisationResourceApi";

import { WORKSPACE_RESOURCES_CACHE_KEY } from "../queries/useWorkspaceResourcesQuery";

type DeleteWorkspaceResourceVariables = {
    resourceId: OrganisationResource["id"];
    workspaceId: number | null;
};

export const useDeleteWorkspaceResourceMutation =
    createMutationHook<DeleteWorkspaceResourceVariables>(({ queryClient }) => ({
        mutationKey: ["DeleteWorkspaceResource"],
        mutationFn: async ({ resourceId, workspaceId }) => {
            if (workspaceId == null) {
                throw new Error("organisationId not supplied to mutation");
            }
            const response = await deleteOrganisationResource({
                organisationId: workspaceId,
                resourceId,
            });
            return returnDataOrThrow(response);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData<OrganisationResource[]>(
                [
                    WORKSPACE_RESOURCES_CACHE_KEY,
                    { workspaceId: variables.workspaceId },
                ],
                (cache) => {
                    return cache?.filter((r) => r.id !== variables.resourceId);
                },
            );
        },
    }));
