import React, { useState } from "react";

import {
    QueryClientProvider as BaseQueryClientProvider,
    QueryClient,
    QueryClientConfig,
} from "@tanstack/react-query";

import { queryClientOptions } from "./getOrCreateQueryClient";

type QueryClientTestProviderProps = {
    children: React.ReactNode;
};

const errorSilencer = (): void => undefined;

export const queryClientTestOptions: QueryClientConfig = {
    ...queryClientOptions,
    logger: {
        log: console.log,
        warn: console.warn,
        // N.B: Error logging needs to be surpressed inside test environment,
        // because we need to be able to test error cases without the logger
        // cluttering our test output.
        error: errorSilencer,
    },
};

/**
 * Provides an instance of React Query Client. We create a new instance
 * per test so that cached data and pending requests do not leak across
 * tests.
 */
export const QueryClientTestProvider = (
    props: QueryClientTestProviderProps,
): JSX.Element => {
    const [queryClient] = useState(
        () => new QueryClient(queryClientTestOptions),
    );

    return (
        <BaseQueryClientProvider client={queryClient}>
            {props.children}
        </BaseQueryClientProvider>
    );
};
