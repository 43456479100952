import { MeaningfulActionProps } from "@accurx/analytics";
import { PatientListType } from "@accurx/api/patient-messaging";
import {
    EventAction,
    EventObjectType,
    GenericTrackEvent,
} from "@accurx/shared";

import {
    AddError,
    AddRudderStackAnalyticProps,
    AddSuccess,
    FlemingLoggedInCustomProperties,
    IsTestPatientProps,
    OriginProps,
} from "./FlemingAnalyticsConstants";
import { MessagePracticeStatusProps } from "./MessagePracticeEvents";
import {
    trackOnContactDropdownOpen,
    trackOnContactDropdownOptionClick,
} from "./patientListContactDropdownEvents";

//#region Types that belong to legacy AppInsights events and their conversations

// old AppInsights Action Source type
export enum PatientListActionSourceLegacy {
    PatientListsCard = "Patient lists card",
    PatientListEditor = "Patient list editor",
    PatientListSummary = "Patient list summary",
    NavSubMenuContextMenu = "Patient list navigation - context menu",
    NavSubMenuInlineIcon = "Patient list navigation - inline icon",
}

// Newer Rudderstack pageOrigin type
export enum PatientListActionOrigin {
    PatientListsCard = "PatientListsCard",
    PatientListEditor = "PatientListEditor",
    PatientListSummary = "PatientListSummary",
    NavSubMenuContextMenu = "NavSubMenuContextMenu",
    NavSubMenuInlineIcon = "NavSubMenuInlineIcon",
}

type PatientListContactItemClickedProps = {
    selectedItem:
        | "PatientMessage"
        | "ClinicianMessage"
        | "RecordView"
        | "VideoInvite"
        | "PatientProfile";
} & FlemingLoggedInCustomProperties;

/* New pageOrigin prop for mixpanel */
type PatientListPageOriginProp = {
    pageOrigin: PatientListActionOrigin | null;
};

/* legacy actionSource prop for appinsights */
type PatientListActionSourceLegacyProp = {
    ActionSource: PatientListActionSourceLegacy | null;
};

export type UploadType = "Anytime" | "Dated";
export type UploadTypeLegacy = "anytime" | "dated";

//#endregion
//#region

type PatientListNewlyCreatedAnalyticsProps = {
    countUserList: number;
    isOwner: boolean;
};

type ListSizeProp = {
    listSize: number;
};

type PatientListAnalyticsProps = PatientListNewlyCreatedAnalyticsProps &
    ListSizeProp & {
        countShare: number;
    };

type PatientListBulkAddProps = {
    fileId: string;
    requestDurationConfirmMs: number;
    requestDurationCheckMs: number;
};

type PatientListBulkAddSuccessProps = PatientListBulkAddProps & {
    countTotalRow: number;
    countSuccessRow: number;
    countErrorRow: number;
};

type PatientListBulkAddFailureProps = PatientListBulkAddProps & {
    failureReason: string;
};

type PatientListContactButtonClickedProps = FlemingLoggedInCustomProperties &
    MessagePracticeStatusProps;

//#endregion Props

//#region Public types

/** Props used by most analytics events in patient lists */
export type PatientListAnalyticsType = FlemingLoggedInCustomProperties &
    PatientListAnalyticsProps;

export type SharePatientListAnalyticsType = PatientListAnalyticsType & {
    shareType: "UserShare" | "WorkspaceShare";
};

/** Props for viewing a patient list */
export type PatientListAnalyticsViewType = FlemingLoggedInCustomProperties &
    ListSizeProp;

/** Props for a newly-created patient list */
export type PatientListNewlyCreatedType = FlemingLoggedInCustomProperties &
    PatientListNewlyCreatedAnalyticsProps &
    PatientListPageOriginProp;

/** Props for a newly-created patient list with legacy AppInsights actionSource */
export type PatientListNewlyCreatedLegacyType =
    FlemingLoggedInCustomProperties &
        PatientListNewlyCreatedAnalyticsProps &
        PatientListActionSourceLegacyProp;

export type PatientListDeleteClickEventType = FlemingLoggedInCustomProperties &
    OriginProps &
    PatientListPageOriginProp;

/** Props used for patient list analytics events that can be taken from multiple locations in the website */
export type PatientListAnalyticsMultipleOriginType = PatientListAnalyticsType &
    PatientListPageOriginProp;

/** Props used for patient list analytics events that can be taken from multiple locations in the website */
export type PatientListAnalyticsMultipleOriginLegacyType =
    PatientListAnalyticsType & PatientListActionSourceLegacyProp;

/** Props for starting a patient list bulk add */
type PatientListAnalyticsStartBulkAddSuccessType = PatientListAnalyticsType &
    PatientListBulkAddProps;

/** Props for completing a patient list bulk add */
type PatientListAnalyticsStartBulkAddFailure = PatientListAnalyticsType &
    PatientListBulkAddFailureProps;

/** Analytics data for a successful CSV upload */
export type PatientListAnalyticsBulkAddSuccessType = PatientListAnalyticsType &
    PatientListBulkAddSuccessProps & { uploadType: UploadType };

/** Analytics data for a successful CSV upload (legacy AppInsights version) */
export type PatientListAnalyticsBulkAddSuccessLegacyType =
    PatientListAnalyticsType &
        PatientListBulkAddSuccessProps & { uploadType: UploadTypeLegacy };

/** Analytics data for a failed CSV upload */
export type PatientListAnalyticsBulkAddFailureType = PatientListAnalyticsType &
    PatientListBulkAddFailureProps;

/** Analytics data for a single patient */
export type PatientListSinglePatientType = PatientListAnalyticsType &
    IsTestPatientProps;

//#endregion Public types

/**
 * User viewed a patient list
 *
 * Event type: Button click
 * */
export const trackPatientListView = (
    props: PatientListAnalyticsViewType,
): void => {
    GenericTrackEvent({
        object: "PatientListView",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

//#region PATIENT(s) ACTIONS

/**
 * User clicked to add a patient to the list
 *
 * Event type: Button click
 * */
export const trackPatientListStartAdding = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PatientListItemAddStart",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicked to try to add a patient
 *
 * Event type: Button click
 * */
export const trackPatientListItemAddConfirmButtonClick = (
    props: PatientListSinglePatientType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListItemAddConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User added a patient to the list successfully using the patient search form
 *
 * Event type: API response
 * */
export const trackPatientListFindAndAddPatientSuccess = (
    props: PatientListSinglePatientType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemAddConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to add a patient to the list using the patient search form,
 * but the search failed
 *
 * Event type: API response
 * */
export const trackPatientListFindAndAddPatientFailure = (
    props: PatientListSinglePatientType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemAddConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User clicked on Upload CSV button to open the dialog for file uploading
 *
 */
export const trackPatientListUploadCsvButtonClicked = (
    props: PatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListUploadCsv",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicked on the batch add confirm button
 * Event type: Button click
 */
export const trackPatientListItemBulkAddButtonClick = (
    props: PatientListAnalyticsType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListItemBulkAdd",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully initiated action to add/update/delete multiple
 * patients to the list via CSV upload
 *
 * Event type: API response
 * */
export const trackPatientListStartBulkAddPatientSuccess = (
    props: PatientListAnalyticsStartBulkAddSuccessType,
): void => {
    GenericTrackEvent({
        object: "PatientListStartBulkAddPatientSuccess",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User unsuccessfully initiated action to add/update/delete multiple
 * patients to the list via CSV upload
 *
 * Event type: API response
 * */
export const trackPatientListStartBulkAddPatientFailure = (
    props: PatientListAnalyticsStartBulkAddFailure,
): void => {
    GenericTrackEvent({
        object: "PatientListStartBulkAddPatientFailure",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User added multiple patients to the list successfully via CSV upload
 *
 * Event type: API response
 * */
export const trackPatientListBulkAddPatientSuccess = (
    props: PatientListAnalyticsBulkAddSuccessType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemBulkAdd",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to add multiple patients to the list via CSV upload,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListBulkAddPatientFailure = (
    props: PatientListAnalyticsBulkAddFailureType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemBulkAdd",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to add a patient to the list using the patient search form,
 * but the patient was already present in the list
 *
 * Event type: Submit form attempt
 * */
export const trackPatientListDuplicatePatientFound = (
    props: FlemingLoggedInCustomProperties,
): void => {
    GenericTrackEvent({
        object: "PatientListDuplicatePatientFound",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicked to remove a patient from the list
 *
 * Event type: Button click
 * */
export const trackPatientListRemovePatientClicked = (
    props: PatientListSinglePatientType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully removed a patient from the list
 *
 * Event type: API response
 * */
export const trackPatientListRemovePatientSuccess = (
    props: PatientListSinglePatientType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to remove a patient from the list,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListRemovePatientFailure = (
    props: PatientListSinglePatientType,
): void => {
    GenericTrackEvent({
        object: "PatientListItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User clicked to bulk remove patients from the list
 *
 * Event type: Button click
 * */
export const trackPatientListBulkRemovePatientClicked = (
    props: PatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListBulkItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully removed a group of patients from the list
 *
 * Event type: API response
 * */
export const trackPatientListBatchRemovePatientSuccess = (
    props: PatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListBulkItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to remove a group of patients from the list,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListBatchRemovePatientFailure = (
    props: PatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListBulkItemRemove",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

//#endregion PATIENT(s) ACTIONS

//#region LIST SHARING

/**
 * User clicked to share a list
 *
 * Event type: Button click
 * */
export const trackPatientListShareButtonClick = (
    props: SharePatientListAnalyticsType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListShare",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully shared a list with someone
 *
 * Event type: API response
 * */
export const trackPatientListShareSuccess = (
    props: SharePatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListShare",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to share a list
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListShareFailure = (
    props: SharePatientListAnalyticsType,
): void => {
    GenericTrackEvent({
        object: "PatientListShare",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User successfully removed themselves from a list
 * that was shared with them
 *
 * Event type: API response
 * */
export const trackPatientListRemoveSelfSuccess = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListRemoveSelfSuccess",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User tried to remove themselves from a list,
 * that was shared with them
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListRemoveSelfFailure = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListRemoveSelfFailure",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddRudderStackAnalyticProps(props),
    });
};

//#endregion LIST SHARING

//#region WHOLE LIST ACTIONS

/**
 * User clicks the list creation button
 *
 * Event type: Button click
 * */
export const trackPatientListCreateButtonClick = (
    props: PatientListNewlyCreatedType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListCreate",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User clicks the button to edit a list name
 *
 * Event type: Button click
 * */
export const trackPatientListEditButtonClick = (
    props: PatientListAnalyticsType & MeaningfulActionProps,
): void => {
    GenericTrackEvent({
        object: "PatientListEdit",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully created a new list
 *
 * Event type: API response
 * */
export const trackPatientListCreateSuccess = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListCreate",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to create a new list,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListCreateFailure = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListCreate",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User successfully edited a list
 *
 * Event type: API response
 * */
export const trackPatientListEditSuccess = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListEdit",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to edit a list,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListEditFailure = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListEdit",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User clicked on the button to delete a list
 *
 * Event type: Button click
 * */
export const trackPatientListDeleteButtonClick = (
    props: PatientListDeleteClickEventType,
): void => {
    GenericTrackEvent({
        object: "PatientListDelete",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: AddRudderStackAnalyticProps(props),
    });
};

/**
 * User successfully deleted a list
 *
 * Event type: API response
 * */
export const trackPatientListDeleteSuccess = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListDelete",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddSuccess(AddRudderStackAnalyticProps(props)),
    });
};

/**
 * User tried to delete a list,
 * but the request failed
 *
 * Event type: API response
 * */
export const trackPatientListDeleteFailure = (
    props: PatientListAnalyticsMultipleOriginType,
): void => {
    GenericTrackEvent({
        object: "PatientListDelete",
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: AddError(AddRudderStackAnalyticProps(props)),
    });
};

//#endregion WHOLE LIST ACTIONS

/**
 * When the contact button/dropdown is clicked.
 *
 * Event type: Button click
 * */
export const trackPatientListContactButtonClicked = (
    props: PatientListContactButtonClickedProps,
): void => {
    trackOnContactDropdownOpen({ ...props, productOrigin: "PatientList" });
};

/**
 * When the contact dropdown item is clicked.
 *
 * Event type: MenuItem click
 * */
export const trackPatientListContactItemClicked = (
    props: PatientListContactItemClickedProps,
): void => {
    trackOnContactDropdownOptionClick({
        ...props,
        productOrigin: "PatientList",
    });
};

type PatientListSearchForPatientFailedProps = {
    patientListType?: PatientListType;
    isNhsNumberSet: boolean;
} & FlemingLoggedInCustomProperties;

/**
 * When the contact dropdown item is clicked and the search for patient fails
 *
 * Event type: MenuItem click
 * */
export const trackPatientListSearchForPatientFailed = (
    props: PatientListSearchForPatientFailedProps,
): void => {
    GenericTrackEvent({
        object: "PatientListContact",
        objectType: EventObjectType.Button,
        action: EventAction.ResponseFail,
        properties: AddRudderStackAnalyticProps(props),
    });
};
