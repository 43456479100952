import React from "react";

import * as UI from "@accurx/design";

import { StyledNameText } from "./MessageNameTag.styles";

type MessageNameTagProps = {
    avatar: React.ReactNode;
    name: string;
};

export const MessageNameTag = ({ avatar, name }: MessageNameTagProps) => {
    return (
        <UI.Flex flexDirection="row" gap="1" alignItems="center">
            {avatar}
            <StyledNameText>{name}</StyledNameText>
        </UI.Flex>
    );
};
