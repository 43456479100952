import { useState } from "react";

import { RadioGroup, Text } from "@accurx/design";

import { useFloreyBuilder } from "../FloreyBuilder.context";
import { Actions } from "../constants";
import {
    CheckedChangeProps,
    QuestionMultipleChoiceSwitchProps,
} from "../types/data.types";
import { StyledGreyIndent } from "./PageContainer.styles";

enum Values {
    One = "One",
    Many = "Many",
}

export const QuestionMultipleChoiceSwitch = ({
    allowMultipleAnswers,
    questionIndex,
    questionLevel,
    branchPosition,
}: QuestionMultipleChoiceSwitchProps): JSX.Element => {
    const { dispatch } = useFloreyBuilder();
    const [checkedValue, setCheckedValue] = useState<Values>(
        allowMultipleAnswers ? Values.Many : Values.One,
    );
    const handleCheckedChange = ({
        event,
        questionLevel,
        branchPosition,
    }: CheckedChangeProps) => {
        const value = event.target.value as Values;
        setCheckedValue(value);
        dispatch({
            type: Actions.UPDATE_QUESTION_MULTIPLE_CHOICE_NUM_OF_ANSWERS,
            payload: {
                allowMultipleAnswers: value === Values.Many,
                questionIndex,
                questionLevel,
                branchPosition,
            },
        });
    };
    return (
        <>
            <RadioGroup
                formFieldWrapperProps={{
                    label: "Patients can select:",
                }}
                name="bordered-columned-radio"
                onChange={(event) =>
                    handleCheckedChange({
                        event,
                        questionLevel,
                        branchPosition,
                    })
                }
                checkedValue={checkedValue}
                theme="bordered"
                columns={2}
                radioInputs={[
                    { id: "one", label: "One answer", value: Values.One },
                    { id: "many", label: "Many answers", value: Values.Many },
                ]}
            />
            <StyledGreyIndent>
                <Text skinny>
                    {checkedValue === Values.Many ? (
                        <>Patients can select many options</>
                    ) : (
                        <>Patients can select one option</>
                    )}
                </Text>
            </StyledGreyIndent>
        </>
    );
};
