import { Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import { usePatientTriageDashboardFortnightlyDataQuery } from "app/hooks/queries";
import { DownloadReportButton } from "app/practices/patientTriage/components";
import { RequestsByDayGraph } from "app/practices/patientTriage/components/Dashboards/RequestsByDay";

import { MainPanel } from "../../settings/SettingsPage.styles";
import { Container } from "../../shared";

const StyledDownloadSection = styled.div`
    margin-top: ${Tokens.SIZES[4]};
`;

export const RequestsByDayTab = ({ workspaceId }: { workspaceId: number }) => {
    const { isLoading, data } = usePatientTriageDashboardFortnightlyDataQuery({
        workspaceId,
    });

    return (
        <Container>
            <MainPanel>
                <RequestsByDayGraph
                    isLoading={isLoading}
                    dashboardData={data?.usage ?? []}
                />
                <StyledDownloadSection>
                    <Text>
                        Download data from the last 90 days. It may take a few
                        seconds to start downloading.
                    </Text>
                    <DownloadReportButton practiceId={String(workspaceId)} />
                </StyledDownloadSection>
            </MainPanel>
        </Container>
    );
};
