import { Attachment } from "domains/concierge/types/messagePatient.types";

export const mapAttachmentsToTemplateAttachmentIds = (
    attachments: Attachment[],
): number[] => {
    if (attachments && attachments.length === 0) {
        return [];
    }

    return attachments
        .filter((attachment) => attachment.origin === "Template")
        .map((attachment) => parseInt(attachment.id, 10));
};
