import { Action, Reducer } from "redux";

import { ROUTES } from "shared/Routes";

import { MESSAGE_PRACTICE_RESET_PRODUCT } from "../messagePractice/MessagePracticeActions";
import { ProductSelection } from "./ProductSelection";
import {
    KnownAction,
    RESET_PRODUCT,
    SELECT_PRODUCT,
    SET_PATIENT_SEARCH_ORIGIN,
} from "./SelectProductActions";
import type { SelectProductState } from "./SelectProductState.types";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const initialState: SelectProductState = {
    productSelection: ProductSelection.None,
    searchPatientOrigin: ROUTES.home,
};

export const reducer: Reducer<SelectProductState> = (
    state = initialState,
    incomingAction: Action,
): SelectProductState => {
    if (incomingAction.type === MESSAGE_PRACTICE_RESET_PRODUCT) {
        return {
            ...state,
            productSelection: ProductSelection.None,
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SELECT_PRODUCT: {
            return {
                ...state,
                productSelection: action.productSelection,
            };
        }
        //case SEARCH_FOR_PATIENT_WENT_BACK:
        case RESET_PRODUCT:
            return {
                ...state,
                productSelection: ProductSelection.None,
            };
        case SET_PATIENT_SEARCH_ORIGIN:
            return {
                ...state,
                searchPatientOrigin: action.route,
            };
        default:
            return state;
    }
};
