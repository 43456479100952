import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { editAccumailTemplate } from "api/Accumail/AccumailTemplatesApi";
import { AccumailTemplate } from "app/practices/accumail/templates.types";

import { ACCUMAIL_TEMPLATES_CACHE_KEY } from "../queries/useAccumailTemplatesQuery";

type EditAccumailTemplateMutationVariables = {
    workspaceId: number;
    templateId: string;
    data: Pick<AccumailTemplate, "title" | "content" | "snomedCodes">;
};

export const useEditAccumailTemplateMutation = createMutationHook<
    EditAccumailTemplateMutationVariables,
    AccumailTemplate
>(({ queryClient }) => ({
    mutationKey: ["EditAccumailTemplate"],
    mutationFn: async ({ workspaceId, data, templateId }) => {
        const response = await editAccumailTemplate(
            workspaceId,
            templateId,
            data,
        );
        return returnDataOrThrow(response);
    },
    onSuccess: (editedtemplate, { workspaceId }) => {
        const queryKey = [
            ACCUMAIL_TEMPLATES_CACHE_KEY,
            { workspaceId, templateType: editedtemplate.templateType },
        ];

        queryClient.setQueryData<AccumailTemplate[]>(queryKey, (old) =>
            old === undefined
                ? undefined
                : old.map((template) =>
                      template.id === editedtemplate.id &&
                      template.templateType === editedtemplate.templateType
                          ? editedtemplate
                          : template,
                  ),
        );
    },
}));
