import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { mapPatientSummaryExternalIdsToTicketPatientExternalIds } from "domains/concierge/internal/api/shared/mappers/patients/mapPatientSummaryExternalIdsToTicketPatientExternalIds";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";
import { NoteSegment, PatientExternalId } from "domains/concierge/types";

import * as TicketApiClient from "../TicketApiClient";
import { mapNoteSegmentToConversationNoteSegment } from "../mappers/mapNoteSegmentToConversartionNoteSegment";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type AddNoteOptions = {
    ticketIdentity: TicketIdentity;
    workspaceId: number;
    patientIds?: PatientExternalId[];
    note: NoteSegment;
};

export const addNote: ConciergeFetcher<
    AddNoteOptions,
    PatientThreadTicketCommandResult
> = async ({ ticketIdentity, workspaceId, patientIds, note }) => {
    const patientIdentities = patientIds
        ? {
              patientExternalIds:
                  mapPatientSummaryExternalIdsToTicketPatientExternalIds(
                      patientIds,
                  ),
          }
        : undefined;

    if (note?.type !== "Text") {
        throw new Error(
            "Only 'Text' type note segments are supported in the Unified inbox.",
        );
    }

    const noteTextSegment = mapNoteSegmentToConversationNoteSegment(note);

    const response = await TicketApiClient.addTicketNote({
        organisationId: workspaceId,
        ticketIdentity: ticketIdentity,
        patientId: patientIdentities,
        segments: {
            texts: [noteTextSegment],
        },
    });

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
