import React, { ReactNode, useState } from "react";

import { Button, Card, Icon, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { UncontrolledTooltip } from "reactstrap";
import styled from "styled-components";

export const RequestMedicalRecordWrapper = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => (
    <div className="row mb-5">
        <div className="col-sm-12 col-lg-8 m-auto">{children}</div>
    </div>
);

export const requestPermissionStepTitle = (
    <>
        <Text className="font-weight-bold" colour="zinc" skinny>
            {"Step 1 of 2"}
        </Text>
        <Text variant="subtitle" as="h2" colour="night" skinny>
            {"Send permission code"}
        </Text>
    </>
);

export const confirmCodeTitle = (
    <>
        <Text className="font-weight-bold" colour="zinc" skinny>
            {"Step 2 of 2"}
        </Text>
        <Text variant="subtitle" as="h2" colour="night" skinny>
            {"Confirm permission code"}
        </Text>
    </>
);

type PatientFirstName = { patientFirstName: string | null };

export type RequestPermissionContentProps = PatientFirstName &
    RequestPermissionSMSProps & {
        obfuscatedPatientMobileNumber: string;
    };

type RequestPermissionSMSProps = {
    greeting: string;
    userName: string;
    practiceName: string;
};

const StyledHoverableInfoIcon = styled.span`
    cursor: pointer;
`;

const RequestPermissionSMS = ({
    greeting,
    userName,
    practiceName,
}: RequestPermissionSMSProps): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const SMSCardBody = (): JSX.Element => (
        <Text
            colour="metal"
            props={{ className: "mb-0", "data-testid": "rv-sample-sms" }}
        >
            {greeting} {userName} from {practiceName} would like to view a
            summary of your GP medical record. To grant permission, give them
            this code: [code will appear here]
            <br />
            Thanks, {userName}
            <br />
            To read about what will be shared, and why you may be receiving this
            message, click here:{" "}
            <Text
                as="a"
                variant="link"
                props={{
                    href: SupportUrls.RecordViewPatients,
                    target: "_blank",
                    rel: "noopener noreferrer",
                }}
            >
                {SupportUrls.RecordViewPatients}
            </Text>
        </Text>
    );

    const SMSCardHeader = (): JSX.Element => (
        <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => setIsCollapsed((prevState) => !prevState)}
            style={{ cursor: "pointer" }}
        >
            <Text className="font-weight-bold" colour="night" skinny>
                See what the SMS message looks like
            </Text>
            <Button
                icon={{
                    name: "Arrow",
                    rotation: isCollapsed ? "down" : "up",
                    colour: "night",
                    title: "Expand",
                    id: "expand-btn",
                }}
                theme="transparent"
                title="Expand"
            />
        </div>
    );

    return (
        <Card
            spacingBody={isCollapsed ? 0 : 2}
            spacing={2}
            header={<SMSCardHeader />}
        >
            {!isCollapsed && <SMSCardBody />}
        </Card>
    );
};

export const RequestPermissionContent = ({
    greeting,
    obfuscatedPatientMobileNumber,
    userName,
    practiceName,
    patientFirstName = "the patient",
}: RequestPermissionContentProps): JSX.Element => (
    <>
        <Text colour="night" props={{ className: "mt-0" }}>
            Send a one-time permission code to {patientFirstName} in an SMS.
            <br />
            They will need to say the code back to you to confirm their
            permission.
        </Text>
        <Text
            variant="label"
            props={{ className: "d-flex align-items-center" }}
        >
            Mobile phone number{" "}
            <StyledHoverableInfoIcon id="MobilePhoneInfo">
                <Icon name="Info" theme="Line" size={3} />
            </StyledHoverableInfoIcon>
        </Text>
        <Text colour="metal">{obfuscatedPatientMobileNumber}</Text>
        <RequestPermissionSMS
            greeting={greeting}
            userName={userName}
            practiceName={practiceName}
        />
        <UncontrolledTooltip target="MobilePhoneInfo">
            This number is taken from the NHS database. If it is incorrect, the
            patient will need to let their GP know.
        </UncontrolledTooltip>
    </>
);

type RequestRecordConfirmCodeProps = PatientFirstName & {
    obfuscatedPatientMobileNumber: string;
};

export const ConfirmCodeContent = ({
    obfuscatedPatientMobileNumber,
    patientFirstName = "the patient",
}: RequestRecordConfirmCodeProps): JSX.Element => (
    <Text skinny>
        The one-time permission code has been sent to the phone number:{" "}
        {obfuscatedPatientMobileNumber}.<br /> Please ask {patientFirstName} for
        the code that was sent to them and enter it below.
    </Text>
);

export const ConfirmCodeDirectContent = (): JSX.Element => (
    <Text>
        The patient should have previously received a 6 digit code via SMS to
        their phone number. You can enter that code to view their record.
    </Text>
);

type CheckUserWithPatientCardProps = PatientFirstName & {
    onConfirm: React.MouseEventHandler<HTMLButtonElement>;
    onDecline: React.MouseEventHandler<HTMLButtonElement>;
};

export const CheckUserWithPatientCard = ({
    onConfirm,
    onDecline,
    patientFirstName = "the patient",
}: CheckUserWithPatientCardProps): JSX.Element => {
    const CardHeader = (): JSX.Element => (
        <>
            <Text className="font-weight-bold" colour="zinc" skinny>
                {"Before we start"}
            </Text>
            <Text variant="subtitle" as="h2" colour="night" skinny>
                {`Are you currently able to communicate with ${patientFirstName}?`}
            </Text>
        </>
    );

    const CardFooter = (): JSX.Element => (
        <div className="d-flex justify-content-end">
            <Button
                theme="secondary"
                text={"No"}
                type="button"
                onClick={onDecline}
            />
            <Button
                text={"Yes"}
                type="button"
                className="ml-3"
                onClick={onConfirm}
            />
        </div>
    );

    const CardContent = (): JSX.Element => (
        <>
            <Text>
                Record View works with explicit permission from the patient. You
                need to be in communication with {patientFirstName} in order to
                confirm a one-time permission code.
            </Text>
            <Text className="mb-0">This communication can be:</Text>
            <Text as="ul">
                <li>in-person</li>
                <li>over the phone</li>
                <li>via video call</li>
            </Text>
        </>
    );

    return (
        <Card header={<CardHeader />} footer={<CardFooter />}>
            <CardContent />
        </Card>
    );
};

type UnableToUseRecordViewCardProps = PatientFirstName & {
    handleGoBack: React.MouseEventHandler<HTMLButtonElement>;
    handleContinue: React.MouseEventHandler<HTMLButtonElement>;
    hideGoBackButton?: boolean;
};

export const UnableToUseRecordViewCard = ({
    handleGoBack,
    handleContinue,
    patientFirstName = "the patient",
    hideGoBackButton,
}: UnableToUseRecordViewCardProps): JSX.Element => {
    const CardHeader = (): JSX.Element => (
        <>
            <Text className="font-weight-bold" colour="zinc" skinny>
                Unable to use now
            </Text>
            <Text variant="subtitle" as="h2" colour="night" skinny>
                Not in communication with {patientFirstName}
            </Text>
        </>
    );

    const CardFooter = (): JSX.Element => (
        <div className="d-flex justify-content-end">
            <Button
                theme="secondary"
                text={"Continue to request record"}
                type="button"
                onClick={handleContinue}
            />

            {!hideGoBackButton && (
                <Button
                    text={"Go back to profile"}
                    type="button"
                    className="ml-3"
                    onClick={handleGoBack}
                />
            )}
        </div>
    );

    const CardContent = (): JSX.Element => (
        <>
            <Text>
                To view {patientFirstName}'s record, you need to be in
                communication with them to ask for a permission code.
            </Text>
            <Text className="font-weight-bold" colour="night" skinny>
                How Record View works
            </Text>
            <Text as="ol">
                <li>Send a one-time permission code to patient via SMS</li>
                <li>
                    Confirm permission code with patient (currently unable to
                    do)
                </li>
            </Text>
        </>
    );

    return (
        <Card header={<CardHeader />} footer={<CardFooter />}>
            <CardContent />
        </Card>
    );
};
