import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled(UI.Flex)`
    background-color: rgba(255, 255, 255, 0.75);
    border-bottom: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    padding: ${UI.Tokens.SIZES[1.5]};
    backdrop-filter: blur(8px);
    position: sticky;
    top: -1px;
    left: 0px;
    width: 100%;

    @media screen and (min-width: ${UI.Tokens.BREAKPOINTS.lg}) {
        top: 0px;
    }
`;
