import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledRecordViewContainer = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
`;

export const ProductInfoBox = styled.div`
    border: solid 1px ${Tokens.COLOURS.greyscale.silver};
    padding: 1rem;
`;
