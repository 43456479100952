import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTopLevelSettingsWrapper = styled.div`
    border: ${Tokens.BORDERS.normal.silver};
    padding: ${Tokens.SIZES[3]};
    display: grid;
    gap: ${Tokens.SIZES[2]};
    margin-bottom: ${Tokens.SIZES[2]};
`;
export const StyledSuspendWrapper = styled.div`
    margin-top: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledFlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledSettingsSection = styled.div`
    border-left: ${Tokens.BORDERS.normal.silver};
    border-left-width: 3px;
    padding-left: ${Tokens.SIZES[3]};
`;
