import { createMutationHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import {
    CreateQuestionnaireRequest,
    QuestionnaireResponse,
} from "@accurx/api/florey-builder";

import { createQuestionnaire } from "api/FloreyBuilder/FloreyBuilderApi";

export const useCreateQuestionnaireMutation = createMutationHook<
    CreateQuestionnaireRequest,
    QuestionnaireResponse
>(({ queryClient }) => ({
    mutationKey: ["CreateQuestionnaire"],
    mutationFn: async ({ organisationId, name }) => {
        const response = await createQuestionnaire(organisationId.toString(), {
            organisationId,
            name,
        });
        return returnDataOrThrow(response);
    },
}));
