import { useContext } from "react";

import { HubClient } from "./HubClient";
import { HubClientContext } from "./HubClientContext";

export const useHubClient = (): HubClient => {
    const hubClient = useContext(HubClientContext);
    if (!hubClient) {
        throw new Error(
            "HubClient must be used within a HubClient provider context",
        );
    }
    return hubClient;
};
