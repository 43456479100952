import { ReactNode } from "react";

import { FormFieldWrapper, StackPanel, Text } from "@accurx/design";
import { RemirrorJSON } from "remirror";

import { Container } from "app/organisations/shared";
import { SnomedCode } from "app/sharedComponents/SnomedPicker";
import { MultiSnomedPicker } from "app/sharedComponents/SnomedPicker/MultiSnomedPicker";

import { FormButtons } from "./FormButtons";
import { TemplateContent } from "./TemplateContent";
import { TemplateErrorSummary } from "./TemplateErrorSummary";
import { StyledCard } from "./TemplateForm.styles";
import { TemplateTitle } from "./TemplateTitle";
import { WhatPatientDetailInformation } from "./WhatPatientDetailInformation";

export type ValidationDetails = {
    submitted: boolean;
    invalidPlaceholder: string | null;
    submittedContentIsEmpty: boolean;
    hasErrors: boolean;
};

type TemplateFormProps = {
    save: () => void;
    goBack: () => void;
    header: ReactNode;
    isMutationError: boolean;
    initialContent: RemirrorJSON | undefined;
    setEditorContent: (content: RemirrorJSON) => void;
    title: string | undefined;
    setTitle: (title: string) => void;
    snomedCodes: SnomedCode[] | undefined;
    setSnomedCodes: (title: SnomedCode[]) => void;
    validationDetails: ValidationDetails;
    saveButtonText: string;
};

export const TemplateForm = ({
    save,
    goBack,
    header,
    isMutationError,
    initialContent,
    setEditorContent,
    title,
    setTitle,
    snomedCodes,
    setSnomedCodes,
    validationDetails,
    saveButtonText,
}: TemplateFormProps) => (
    <form
        onSubmit={(e) => {
            e.preventDefault();
            save();
        }}
    >
        <Container>
            {header}

            <TemplateErrorSummary
                validationDetails={validationDetails}
                isMutationError={isMutationError}
            />

            <StyledCard
                header={
                    <Text variant="subtitle" skinny>
                        Template details
                    </Text>
                }
            >
                <StackPanel gutter={3}>
                    <TemplateTitle
                        validationDetails={validationDetails}
                        title={title ?? ""}
                        setTitle={setTitle}
                    />
                    <TemplateContent
                        initialContent={initialContent}
                        setEditorContent={setEditorContent}
                        validationDetails={validationDetails}
                    />
                    <WhatPatientDetailInformation />
                    <FormFieldWrapper label="SNOMED code (optional)">
                        <MultiSnomedPicker
                            context="accumail"
                            disabled={false}
                            id="multisnomedcodepicker"
                            values={snomedCodes}
                            onChange={setSnomedCodes}
                            showMissingSnomedCodeInformation
                        />
                    </FormFieldWrapper>
                </StackPanel>
            </StyledCard>
        </Container>
        <FormButtons goBack={goBack} saveButtonText={saveButtonText} />
    </form>
);
