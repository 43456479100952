import { ReactNode } from "react";

import { Button, Icon, Text } from "@accurx/design";

import { ROUTES } from "shared/Routes";
import { useAppSelector } from "store/hooks";

export interface ConfirmMessageComponentProps {
    children: ReactNode;
    title: string;
    handleGoBack: () => void;
}

const ConfirmMessageLayout = ({
    title,
    handleGoBack,
    children,
}: ConfirmMessageComponentProps) => {
    const searchPatientOrigin = useAppSelector(
        ({ selectProduct }) => selectProduct.searchPatientOrigin,
    );

    return (
        <div className="row justify-content-center bottom-spacing">
            <div className="col-sm-12 col-md-10 col-lg-6">
                <div className="row justify-content-center">
                    <Icon name="Send" size={8} />
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <Text variant="subtitle" as="h1">
                            {title}
                        </Text>
                    </div>
                </div>
                <div className="row justify-content-center my-3">
                    <div className="col-sm-12">{children}</div>
                </div>
                <div className="row justify-content-center mb-4 pt-3">
                    <Button
                        text={
                            searchPatientOrigin === ROUTES.patient_lists
                                ? "Go to patient list"
                                : "Search for a new patient"
                        }
                        onClick={handleGoBack}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConfirmMessageLayout;
