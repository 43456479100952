import { Dispatch } from "redux";

import {
    getVaccineSiteLinks,
    postVaccineSiteLinksApproval,
    postVaccineSiteLinksLeave,
} from "api/VaccineApi";
import { Actions, ExtendedActions } from "app/practices/Practices.actions";

import {
    VaccineApprovalUpdate,
    VaccinePracticeLinks,
    VaccineSiteLeaveRequest,
} from "./ApprovalRequests.types";

// -----------------
// ACTION TYPES
// -----------------
export const VACCINE_SITE_LINKS = "VACCINE_SITE_LINKS";
export const VACCINE_SITE_LINKS_SUCCESS = "VACCINE_SITE_LINKS_SUCCESS";
export const VACCINE_SITE_LINKS_FAILURE = "VACCINE_SITE_LINKS_FAILURE";

export const POST_APPROVAL_REQUEST_UPDATE = "POST_APPROVAL_REQUEST_UPDATE";
export const POST_APPROVAL_REQUEST_UPDATE_SUCCESS =
    "POST_APPROVAL_REQUEST_UPDATE_SUCCESS";
export const POST_APPROVAL_REQUEST_UPDATE_FAILURE =
    "POST_APPROVAL_REQUEST_UPDATE_FAILURE";

export const POST_LEAVE_SITE_REQUEST = "POST_LEAVE_SITE_REQUEST";
export const POST_LEAVE_SITE_REQUEST_SUCCESS =
    "POST_LEAVE_SITE_REQUEST_SUCCESS";
export const POST_LEAVE_SITE_REQUEST_FAILURE =
    "POST_LEAVE_SITE_REQUEST_FAILURE";
export const UPDATE_VACCINE_SITE_LINKS = "UPDATE_VACCINE_SITE_LINKS";

// -----------------
// ACTIONS INTERFACES
// -----------------
interface GetPendingApprovalRequestsAction {
    type: typeof VACCINE_SITE_LINKS;
}
interface GetPendingApprovalRequestsSuccessAction {
    type: typeof VACCINE_SITE_LINKS_SUCCESS;
    vaccineSiteLinks: VaccinePracticeLinks;
}
interface GetPendingApprovalRequestsFailureAction {
    type: typeof VACCINE_SITE_LINKS_FAILURE;
    error: string;
}

interface PostApprovalRequestUpdateAction {
    type: typeof POST_APPROVAL_REQUEST_UPDATE;
}
interface PostApprovalRequestUpdateSuccessAction {
    type: typeof POST_APPROVAL_REQUEST_UPDATE_SUCCESS;
    vaccineSiteLinks: VaccinePracticeLinks;
}
interface PostApprovalRequestUpdateFailureAction {
    type: typeof POST_APPROVAL_REQUEST_UPDATE_FAILURE;
    error: string;
}

interface PostVaccineSiteLeaveAction {
    type: typeof POST_LEAVE_SITE_REQUEST;
}
interface PostVaccineSiteLeaveSuccessAction {
    type: typeof POST_LEAVE_SITE_REQUEST_SUCCESS;
    vaccineSiteLinks: VaccinePracticeLinks;
}
interface PostVaccineSiteLeaveFailureAction {
    type: typeof POST_LEAVE_SITE_REQUEST_FAILURE;
    error: string;
}
export interface UpdateVaccineSiteLinksAction {
    type: typeof UPDATE_VACCINE_SITE_LINKS;
    practiceId: number;
    showVaccineLinks: boolean;
    pendingVaccineApprovalRequests: boolean;
}

export type KnownAction =
    | GetPendingApprovalRequestsAction
    | GetPendingApprovalRequestsSuccessAction
    | GetPendingApprovalRequestsFailureAction
    | PostApprovalRequestUpdateAction
    | PostApprovalRequestUpdateSuccessAction
    | PostApprovalRequestUpdateFailureAction
    | PostVaccineSiteLeaveAction
    | PostVaccineSiteLeaveSuccessAction
    | PostVaccineSiteLeaveFailureAction
    | UpdateVaccineSiteLinksAction
    | ExtendedActions[Actions.SET_SELECTED_PRACTICE];

// ----------------
// ACTION CREATORS
// ----------------
export const getPendingApprovalRequests = (practiceId: string) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: VACCINE_SITE_LINKS });

        const { success, result, error } = await getVaccineSiteLinks(
            practiceId,
        );
        if (success && result) {
            updateGlobalState(dispatch, practiceId, result);
            return dispatch({
                type: VACCINE_SITE_LINKS_SUCCESS,
                vaccineSiteLinks: result,
            });
        }
        return dispatch({
            type: VACCINE_SITE_LINKS_FAILURE,
            error:
                error ||
                "Something went wrong loading your data, please refresh the page",
        });
    };
};

export const postApprovalUpdate = (
    practiceId: string,
    request: VaccineApprovalUpdate,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_APPROVAL_REQUEST_UPDATE });

        const { success, result, error } = await postVaccineSiteLinksApproval(
            practiceId,
            request,
        );
        if (success && result) {
            updateGlobalState(dispatch, practiceId, result);
            return dispatch({
                type: POST_APPROVAL_REQUEST_UPDATE_SUCCESS,
                vaccineSiteLinks: result,
            });
        }
        return dispatch({
            type: POST_APPROVAL_REQUEST_UPDATE_FAILURE,
            error: error || "Something went wrong, please try again",
        });
    };
};

export const tryLeaveDeliverySite = (
    practiceId: string,
    request: VaccineSiteLeaveRequest,
) => {
    return async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: POST_LEAVE_SITE_REQUEST });

        const { success, result, error } = await postVaccineSiteLinksLeave(
            practiceId,
            request,
        );
        if (success && result) {
            updateGlobalState(dispatch, practiceId, result);
            return dispatch({
                type: POST_LEAVE_SITE_REQUEST_SUCCESS,
                vaccineSiteLinks: result,
            });
        }
        return dispatch({
            type: POST_LEAVE_SITE_REQUEST_FAILURE,
            error: error || "Something went wrong leaving this site",
        });
    };
};

// We do this so users don't have to hard refresh to see the new links
const updateGlobalState = (
    dispatch: Dispatch<KnownAction>,
    organisationId: string,
    linkData: VaccinePracticeLinks,
) => {
    dispatch({
        type: UPDATE_VACCINE_SITE_LINKS,
        practiceId: Number(organisationId),
        showVaccineLinks: linkData.deliverySites.length !== 0,
        pendingVaccineApprovalRequests: linkData.approvalRequests.length !== 0,
    });
};
