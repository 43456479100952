import React from "react";

import { Feedback } from "@accurx/design";

type DemoPatientFeedbackProps = {
    isDemoPatient: boolean;
};

export const DemoPatientFeedback = ({
    isDemoPatient,
}: DemoPatientFeedbackProps): JSX.Element | null => {
    if (!isDemoPatient) {
        return null;
    }
    return (
        <Feedback
            content="Placeholder information in use. None of this data is linked to a real patient."
            colour="information"
            props={{ className: "mb-4" }}
        />
    );
};
