import * as UI from "@accurx/design";
import { PatientDemographics } from "@accurx/native";
import { NhsNumberHelpers } from "@accurx/shared";
import {
    calculateAge,
    formatPatientDisplayGender,
    formatPatientName,
    mapPatientsNames,
} from "domains/compose-toolbar/utils/format";
import styled from "styled-components";

const StyledWrapper = styled(UI.Flex).attrs({
    flexDirection: "column",
})`
    white-space: nowrap;
`;

interface PatientHeaderProps {
    patient: PatientDemographics;
}

export const PatientHeader = ({ patient }: PatientHeaderProps) => {
    const patientName = formatPatientName(
        mapPatientsNames(patient.patientName),
    );

    const nhsNumber =
        patient.patientExternalIdentityDto.patientExternalIds.find(
            (id) => id.type === "NhsNumber",
        )?.value;

    const formattedNhsNumber = nhsNumber
        ? NhsNumberHelpers.formatNhsNumber(nhsNumber)
        : "Unknown";

    const age = patient.dateOfBirth ? calculateAge(patient.dateOfBirth) : null;

    const gender = formatPatientDisplayGender(patient.gender);

    return (
        <StyledWrapper>
            <UI.Text variant="label">{patientName} </UI.Text>

            <UI.Flex>
                <UI.Text skinny variant="body">
                    NHS: {formattedNhsNumber}
                </UI.Text>
                {age && (
                    <UI.Text skinny variant="body">
                        &nbsp;· {age}y
                    </UI.Text>
                )}
                {gender && (
                    <UI.Text skinny variant="body">
                        &nbsp;· {gender}
                    </UI.Text>
                )}
            </UI.Flex>
        </StyledWrapper>
    );
};
