import { FeatureName } from "@accurx/auth";

export const BatchAndAppointmentRemindersFeatureFlags: FeatureName[] = [
    FeatureName.BatchMessagingAlpha,
    FeatureName.BatchMessagingFlorey,
    FeatureName.AppointmentRemindersAlpha,
];

export const FloreyPlusFeatureFlags: FeatureName[] = [
    FeatureName.FloreyAccuRxPlus,
    FeatureName.FloreyBuilder,
];

export const PatientTriageFeatureFlags: FeatureName[] = [
    FeatureName.PatientInitiatedInbox,
    FeatureName.AccurxPlusPatientTriage,
    FeatureName.PatientTriageSolicitedAppointmentBooking,
    FeatureName.PatientTriageSolicitedAppointmentBookingSendLink,
    FeatureName.PatientTriageReceptionFlow,
    FeatureName.PatientTriageDashboards,
];

export const smsPlusFeatureFlags: FeatureName[] = [
    FeatureName.UnlimitedAttachments,
    FeatureName.UnlimitedSms,
    FeatureName.UnlimitedPatientResponses,
];

export const smsLiteFeatureFlags: FeatureName[] = [
    FeatureName.UnlimitedAttachments,
    FeatureName.UnlimitedSms,
    FeatureName.UnlimitedPatientResponses,
];

export const smsNonGPDefaultFeatureFlags: FeatureName[] = [
    FeatureName.UnlimitedAttachments,
    FeatureName.UnlimitedPatientResponses,
];

export const videoFeatureFlags: FeatureName[] = [FeatureName.VideoConsult];

export const accumailFeatureFlags: FeatureName[] = [FeatureName.ExternalEmail];
