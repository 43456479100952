import React, { FormEvent } from "react";

import { ErrorSummary, Feedback, Flex, Text } from "@accurx/design";

import { StyledSpacer } from "./WorkspaceDetailsForm.styles";

export const Legend = ({ children }: { children: React.ReactNode }) => (
    <legend>
        <Text variant="subtitle" as="h1" skinny>
            {children}
        </Text>
    </legend>
);

export const FormWithErrorSummary = ({
    onSubmit,
    hasNetworkError,
    hasFormValidationErrors,
    children,
}: {
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    hasNetworkError: boolean;
    hasFormValidationErrors: boolean;
    children: React.ReactNode;
}) => {
    return (
        <form onSubmit={onSubmit}>
            <Flex
                gap={hasFormValidationErrors ? "3" : undefined}
                flexDirection="column"
            >
                {hasNetworkError && (
                    <StyledSpacer>
                        <Feedback
                            colour="error"
                            title="Something has gone wrong"
                        >
                            <Text skinny>
                                Please refresh the page and try again.
                            </Text>
                        </Feedback>
                    </StyledSpacer>
                )}

                <ErrorSummary isShown={hasFormValidationErrors} />
                <fieldset>{children}</fieldset>
            </Flex>
        </form>
    );
};
