export type LoadingStatusType = {
    hasLoaded: boolean;
    hasLoadingFailed: boolean;
};

export class LoadingStatus {
    static Initial: LoadingStatusType = {
        hasLoaded: false,
        hasLoadingFailed: false,
    };
    static Loading: LoadingStatusType = {
        hasLoaded: false,
        hasLoadingFailed: false,
    };
    static Failed: LoadingStatusType = {
        hasLoaded: false,
        hasLoadingFailed: true,
    };
    static Loaded: LoadingStatusType = {
        hasLoaded: true,
        hasLoadingFailed: false,
    };
}

export type UpdatingStatusType = {
    updating: boolean;
    hasUpdated: boolean;
    hasUpdatingFailed: boolean;
};

// DEPRECIATED: This should be merged with LoadingStatus
export class UpdatingStatus {
    static Initial: UpdatingStatusType = {
        updating: false,
        hasUpdated: true,
        hasUpdatingFailed: false,
    };
    static Loading: UpdatingStatusType = {
        updating: true,
        hasUpdated: false,
        hasUpdatingFailed: false,
    };
    static Failed: UpdatingStatusType = {
        updating: false,
        hasUpdated: false,
        hasUpdatingFailed: true,
    };
    static Loaded: UpdatingStatusType = {
        updating: false,
        hasUpdated: true,
        hasUpdatingFailed: false,
    };
}
