import { Flex, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledContainer = styled(Flex).attrs(() => ({
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}))`
    max-width: 23ch;
    margin: ${Tokens.SIZES[1]} auto 0;
    text-align: center;
`;
