import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";
import { QuestionnaireSummariesResponse } from "@accurx/api/florey-builder";

import { getQuestionnaireSummaries } from "api/FloreyBuilder/FloreyBuilderApi";

export const QUESTIONNAIRE_SUMMARIES_CACHE_KEY = "QuestionnaireSummaries";

type QuestionnaireSummariesQueryVariables = {
    workspaceId: number;
};

export const useQuestionnaireSummariesQuery = createQueryHook<
    QuestionnaireSummariesQueryVariables,
    QuestionnaireSummariesResponse // TODO: Define the return type
>(({ workspaceId }) => ({
    queryKey: [QUESTIONNAIRE_SUMMARIES_CACHE_KEY, { workspaceId }],
    queryFn: async () => {
        const response = await getQuestionnaireSummaries(
            workspaceId.toString(),
        );
        return returnDataOrThrow(response);
    },
    refetchOnMount: "always",
}));
