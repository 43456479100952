import { Tokens } from "@accurx/design";
import styled from "styled-components";

// NUM PAD
export const StyledNumPadContainer = styled.div`
    max-width: 240px;
    width: 100%;
`;

export const StyledNumpadButton = styled.button`
    appearance: none;
    border: ${Tokens.BORDERS.normal.silver};
    display: block;
    width: 100%;
    border-radius: ${Tokens.BORDERS.radius};
    background-color: ${Tokens.COLOURS.greyscale.white};
    box-shadow: ${Tokens.SHADOWS.raisedInset.medium.silver};
    color: ${Tokens.COLOURS.greyscale.zinc};
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    :hover {
        background-color: ${Tokens.COLOURS.greyscale.silver};
    }
`;

export const StyledNumpadButtonValue = styled.span`
    display: block;
    font-size: 24px;
    line-height: 1;
`;

export const StyledNumpadButtonLetters = styled.span`
    color: ${Tokens.COLOURS.greyscale.stone};
    font-size: 13px;
`;
