import React, { useEffect, useRef } from "react";

import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";
import { AnalyticsTracker } from "@accurx/shared";
import { parse, stringify } from "query-string";
import { shallowEqual, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingWrapper } from "app/sharedComponents/LoadingWrapper";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";
import { useAppSelector } from "store/hooks";

import { selectPractice } from "../Practices.actions";
import { PracticeDetails } from "../Practices.types";
import { NO_RECORD_VIEW_REFERRER } from "../recordView/RecordViewOptIn";
import { SignInToDesktop } from "./SignInToDesktop";

const FORWARD_PATH_URL_PARAM = "dest";
const DEFAULT_FORWARD_PATH = "details";

const isInRecordViewOptInContext = (destinationPath: string): boolean => {
    return destinationPath.search(/record-view-opt-in/i) !== -1;
};

type PracticesListProps = {
    practices: PracticeDetails[];
    queryString: string;
    analyticsLoggedInProps: AnalyticsTracker.LoggedInAnalyticsProps;
};

const PracticesList = ({
    practices,
    queryString,
    analyticsLoggedInProps,
}: PracticesListProps): JSX.Element => {
    const practiceForwardPath = useRef<string>(DEFAULT_FORWARD_PATH);

    if (practices.length === 0) {
        return <SignInToDesktop />;
    }

    const params = parse(queryString);
    if (FORWARD_PATH_URL_PARAM in params) {
        const destination = params[FORWARD_PATH_URL_PARAM];

        if (destination && typeof destination === "string") {
            // strip leading forward-slash
            const sanitisedDestination =
                destination[0] === "/" ? destination.substring(1) : destination;

            // send them to sub-path specified in "dest" url param instead
            practiceForwardPath.current = sanitisedDestination;
        }

        // remove "dest" from params
        delete params[FORWARD_PATH_URL_PARAM];

        // serialise params again, to be appended onto any onward link
        queryString = `?${stringify(params)}`;
    }

    // Sort practices so that non resident practices are at the bottom, otherwise preserve order
    function sortPracticeList(a: PracticeDetails, b: PracticeDetails) {
        return a.isResident === b.isResident ? 0 : a.isResident ? -1 : 1;
    }

    const links = [...practices]
        .sort((a, b) => sortPracticeList(a, b))
        .map((p: PracticeDetails) => {
            const link = `/practices/${p.id}/${practiceForwardPath.current}${queryString}`;

            const recordViewHandleSelectPractice = (): void => {
                if (isInRecordViewOptInContext(practiceForwardPath.current)) {
                    ChainAnalyticsTracker.trackRecordViewSelectPractice({
                        ...analyticsLoggedInProps,
                        // Need to override this org info here as this happens before we set the selectedPractice in the store
                        CurrentOrganisationName: p.name,
                        CurrentOrganisationId: p.id.toString(),
                        referrer:
                            (params.referrer as string) ||
                            NO_RECORD_VIEW_REFERRER,
                        isOptedIn: Boolean(
                            IsFeatureEnabled(
                                p.features,
                                FeatureName.RecordViewGpOptIn,
                            ),
                        ),
                    });
                }
            };

            return (
                <div className="list-group-item" key={p.id}>
                    <div className="d-flex align-items-center">
                        {p.isResident && (
                            <Link
                                to={link}
                                onClick={recordViewHandleSelectPractice}
                            >
                                <Text variant="label" skinny>
                                    {p.name}
                                </Text>
                            </Link>
                        )}
                        {!p.isResident && (
                            <div>
                                <Text as="span" variant="label" skinny>
                                    {`${p.name} · `}
                                </Text>
                                <Text as="span" variant="body" skinny>
                                    your access is limited at this practice
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    return (
        <>
            <NavSubMenuComponent>
                <Text variant="title" as="h1" skinny>
                    Practices
                </Text>
            </NavSubMenuComponent>
            <div className="list-group mb-5">{links}</div>
        </>
    );
};

export const PracticeList = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const loadingStatus = useAppSelector(
        ({ practices }) => practices.loadingStatus,
        shallowEqual,
    );
    const practices = useAppSelector(
        ({ practices }) => practices.items,
        shallowEqual,
    );

    useEffect(() => {
        // if we're on this page, we should unselect any current practice we have
        // due to the type in the store, weirdly, we have to set it to an empty
        // string, not null - but this will eventually go away
        dispatch(selectPractice(""));
    }, [dispatch]);

    // RecordView Track PageLoaded
    useEffect(() => {
        const search = location.search;
        const practiceForwardPath = (parse(search).dest as string) || "";

        if (isInRecordViewOptInContext(practiceForwardPath)) {
            ChainAnalyticsTracker.trackRecordViewSelectPracticePageView({
                ...analyticsLoggedInProps,
                referrer:
                    (parse(search).referrer as string) ||
                    NO_RECORD_VIEW_REFERRER,
            });
        }
    }, [location.search, analyticsLoggedInProps]);

    return (
        <LoadingWrapper loadingStatus={loadingStatus}>
            <PracticesList
                practices={practices}
                queryString={location.search}
                analyticsLoggedInProps={analyticsLoggedInProps}
            />
        </LoadingWrapper>
    );
};
