import { FormFieldWrapper, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

import {
    CharLimitErrorMessage,
    StyledMessageComposeDefault,
    TextArea,
} from "../../components";
import { reduceReminderMessage } from "../../utils/reminderFormValidation";

const FragmentsInfo = styled.span`
    display: block;
    white-space: pre;
    text-align: right;

    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        white-space: nowrap;
    }
`;

type ComposeFieldProps = {
    messageParts: {
        greeting: string;
        templateText: string;
        changeLinkText: string;
        messageSignoff: string;
    };
    charAndFragmentInfo: string;
    customMessage: string;
    onCustomMessageChange: React.ChangeEventHandler<HTMLTextAreaElement>;

    validationErrors?: string[];
};

export function ComposeField({
    messageParts,
    charAndFragmentInfo,
    customMessage,
    onCustomMessageChange,
    validationErrors,
}: ComposeFieldProps) {
    const textareaId = "message-body";

    return (
        <FormFieldWrapper
            label="Message (optional)"
            labelProps={{ htmlFor: textareaId }}
            subLabel="Add an additional message to the reminder."
            errors={validationErrors?.map((error) => {
                if (error === reduceReminderMessage) {
                    return <CharLimitErrorMessage />;
                }

                return error;
            })}
            metaInfo={<FragmentsInfo>{charAndFragmentInfo}</FragmentsInfo>}
        >
            <StyledMessageComposeDefault>
                <Text skinny variant="body">
                    {messageParts.greeting}
                    {`\n`}
                    {messageParts.templateText}
                </Text>

                <TextArea
                    id={textareaId}
                    value={customMessage}
                    onChange={onCustomMessageChange}
                    data-testid="message-body"
                    rows={4}
                    $hasErrors={!!validationErrors?.length}
                />

                <Text skinny variant="body">
                    {messageParts.changeLinkText}
                </Text>
                <Text skinny variant="body">
                    {messageParts.messageSignoff}
                </Text>
            </StyledMessageComposeDefault>
        </FormFieldWrapper>
    );
}
