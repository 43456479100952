/**
 * @param htmlElement
 * @return the distance of the top of an element from the top of the viewport
 * */
export const getDistanceFromTop = (htmlElement: Element): number => {
    // IE does not have getBoundingClientRect().y, so we use .top instead
    return htmlElement.getBoundingClientRect().top;
};

/**
 * @param message message to be copied in clipboard
 * @returns boolean
 */
export const copyToClipboard = async (message: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(message);
        return true;
    } catch {
        return false;
    }
};
