import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

type FilterOpenAnalytics = {
    filterKey: string;
    selectedFilterKeys: string[];
    countResult: number;
    countDropdownOption: number;
};

export function useTrackOnFilterOpen() {
    const loggedInProps = useFlemingLoggedInAnalytics();

    return ({
        filterKey,
        selectedFilterKeys,
        countResult,
        countDropdownOption,
    }: FilterOpenAnalytics) => {
        GenericTrackEvent({
            object: "ClinicFilter",
            objectType: EventObjectType.Button,
            action: EventAction.Click,
            properties: {
                ...loggedInProps,
                eventVersion: 1,
                eventUserType: EventUserType.HealthcareProfessional,
                userIsLoggedIn: true,

                filterName: filterKey,
                activeFilterList: selectedFilterKeys,
                countResult,
                countDropdownOption,
            },
        });
    };
}

type FilterSelectAnalytics = {
    filterKey: string;
    selectedFilterKeys: string[];
    countResult: number;
    countDropdownOption: number;
    searchStringLength: number;
};

export function useTrackOnFilterSelected() {
    const loggedInProps = useFlemingLoggedInAnalytics();

    return ({
        filterKey,
        selectedFilterKeys,
        countResult,
        countDropdownOption,
        searchStringLength,
    }: FilterSelectAnalytics) => {
        GenericTrackEvent({
            object: "ClinicFilter",
            objectType: EventObjectType.MenuItem,
            action: EventAction.Click,
            properties: {
                ...loggedInProps,
                eventVersion: 1,
                eventUserType: EventUserType.HealthcareProfessional,
                userIsLoggedIn: true,

                filterName: filterKey,
                activeFilterList: selectedFilterKeys,
                countResult,
                countDropdownOption,
                searchStringLength,
            },
        });
    };
}
