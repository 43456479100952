import React from "react";

import { useCurrentUser } from "@accurx/auth";
import { useBrowserEnvironment } from "@accurx/native";
import { Log } from "@accurx/shared";
import { LoadingOverlay } from "domains/inbox/components";
import { NativeSubscriptionsProvider } from "domains/inbox/native/subscriptions/provider";

/**
 * NativeProvider sets up the environment for making native queries and subscriptions.
 *
 * When the inbox is rendered inside a WebView2 the native transport allows us
 * to talk directly to the native app via WebView2 JS interop. If we're not
 * inside a WebView2 that means that the user has opened the inbox directly in a
 * browser. In that case we revert to the SignalR transport so messages are sent
 * via SignalR.
 */
export const NativeProvider = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    const env = useBrowserEnvironment();
    const isWebView = env === "WebView";
    const { user } = useCurrentUser();

    if (isWebView && !user.isTrustedDevice) {
        Log.error(
            "Unified Inbox: there is a problem with Desktop cookies while using a WebView",
            {
                tags: {
                    userId: user.accuRxUserId,
                },
            },
        );
        // this edge case scenario will be resolved in that task
        // https://linear.app/accurx/issue/TPANE-1064/istrusteddevice-false-in-webview-scenario
        return <LoadingOverlay />;
    }

    // actually we never go there as when it's not a WebView we go first into app/guardedRoutes/GuardedRoute.tsx
    // where a check for 2FA and Login is implemented. So, not logged /not 2FAed user never goes there.
    if (!user.isTrustedDevice) {
        window.location.href = "/login";
        return <LoadingOverlay />;
    }

    return (
        <NativeSubscriptionsProvider>{children}</NativeSubscriptionsProvider>
    );
};
