import { StackPanel, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledUnstyledList = styled(StackPanel)`
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
`;

export const StyledScrollableUnstyledList = styled(StyledUnstyledList)`
    overflow-y: auto;
    height: 100%;
    padding: 0 ${Tokens.SIZES[1.5]};
`;
