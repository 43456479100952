import { FormEvent, useState } from "react";

import {
    Button,
    Card,
    FormFieldFeedback,
    FormFieldWrapper,
    Grid,
    Input,
    Item,
} from "@accurx/design";

import { useCurrentOrgId } from "store/hooks";

import {
    DashboardApiProps,
    FormErrorsState,
    FormState,
} from "./FragmentsDashboard.types";
import * as helpers from "./FragmentsDashboard.utils";

type FragmentsDashboardFilterFormProps = {
    onSubmit: (
        e: FormEvent<HTMLFormElement>,
        apiOptions: DashboardApiProps,
    ) => void;
    serverError: string;
};

export const FragmentsDashboardFilterForm = ({
    onSubmit,
    serverError,
}: FragmentsDashboardFilterFormProps) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const workspaceId = useCurrentOrgId()!;
    const [formState, setFormState] = useState<FormState>({
        startDate: helpers.initialStartDate(),
        endDate: helpers.initialEndDate(),
    });
    const [errors, setErrors] = useState<FormErrorsState>({
        startDate: "",
        endDate: "",
        generic: "",
    });
    const onInputChange =
        (
            key: "startDate" | "endDate",
        ): React.FormEventHandler<HTMLInputElement> =>
        (e) => {
            setFormState({ ...formState, [key]: e.currentTarget.value });
        };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // clear errors
        setErrors({
            startDate: "",
            endDate: "",
            generic: "",
        });
        // clientside error validation
        const { isValid, errorMessage, errorKey } = helpers.validate(formState);
        if (!isValid) {
            setErrors((prevProps) => {
                if (errorKey) {
                    return {
                        ...prevProps,
                        [errorKey]: errorMessage,
                    };
                }
                return prevProps;
            });
            return;
        }
        onSubmit(e, {
            workspaceId: workspaceId,
            startDate: formState.startDate,
            endDate: formState.endDate,
        });
    };
    return (
        <Card>
            <form onSubmit={handleOnSubmit}>
                <Grid
                    gap="2"
                    columns={{
                        xs: "1fr",
                        sm: "2fr 2fr 1fr",
                    }}
                >
                    <Item>
                        <FormFieldWrapper
                            label="Start date"
                            labelProps={{ htmlFor: "start-date" }}
                            subLabel="Use format DD/MM/YYYY, e.g. 23/08/2023"
                            errors={[errors.startDate]}
                        >
                            <Input
                                id="start-date"
                                name="startdate"
                                value={formState.startDate}
                                onChange={onInputChange("startDate")}
                            />
                        </FormFieldWrapper>
                    </Item>
                    <Item>
                        <FormFieldWrapper
                            label="End date"
                            labelProps={{ htmlFor: "end-date" }}
                            subLabel="Use format DD/MM/YYYY, e.g. 30/08/2023"
                            errors={[errors.endDate]}
                        >
                            <Input
                                id="end-date"
                                name="enddate"
                                value={formState.endDate}
                                onChange={onInputChange("endDate")}
                            />
                        </FormFieldWrapper>
                    </Item>
                    <Item>
                        <Button text="Update data below" type="submit" />
                    </Item>
                </Grid>
                {(errors.generic || serverError) && (
                    <FormFieldFeedback
                        variant="error"
                        text={
                            errors.generic ||
                            serverError ||
                            "Something went wrong with fetching data. Refresh page to try again."
                        }
                    />
                )}
            </form>
        </Card>
    );
};
