export const CopyStatus = {
    Initial: "Initial",
    Succeeded: "Succeeded",
    NotSupported: "NotSupported",
} as const;

export const COPY_NOT_SUPPORTED_MESSAGE =
    "Copying didn't work. Please copy manually.";
export const COPY_SUCCESS_MESSAGE =
    "The link has been copied to your clipboard";
