import { PatientExternalId } from "@accurx/concierge/types";
import { useMedicalRecordConnection } from "@accurx/native";
import { NhsNumberHelpers } from "@accurx/shared";

const getValidPatientNhsNumber = (
    patientExternalIds: PatientExternalId[],
): string | undefined => {
    return patientExternalIds.find(
        (id) =>
            id.type === "NhsNumber" &&
            NhsNumberHelpers.validateNhsNumber(id.value).success === true,
    )?.value;
};

export const useShouldPrefetchPatientRecordAttachments = (
    patientExternalIds: PatientExternalId[],
) => {
    const { status, capabilities } = useMedicalRecordConnection();

    if (status !== "Connected" || !capabilities.prefetchAttachments) {
        return false;
    }

    switch (capabilities.attachDocumentsFromRecord) {
        case "Supported":
            return true;
        case "NotSupported":
            return false;
        case "SupportedWithNhsNumber":
            return !!getValidPatientNhsNumber(patientExternalIds);
    }
};
