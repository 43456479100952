import * as UI from "@accurx/design";
import { SIDENAV_WIDTH } from "@accurx/navigation";
import styled from "styled-components";

const { BREAKPOINTS } = UI.Tokens;

const INBOX_CONVERSATION_LIST_WIDTH = "280px";

export const StyledGrid = styled(UI.Grid)<{
    $rightPaneIsOpen: boolean;
    $quickViewIsOpen: boolean;
}>`
    height: 100%;
    width: 100%;
    overflow: hidden;

    grid-template-columns: ${(props) => {
        // If quick view is open, make it take up the entire width
        if (props.$quickViewIsOpen) {
            return "0 0 1fr";
        }

        // Otherwise, if the conversation view pane is open, make that take up the entire width
        return props.$rightPaneIsOpen ? "0 1fr" : "1fr";
    }};

    grid-template-areas: "sidebar conversation-list conversation-view quick-view";

    @media screen and (min-width: ${BREAKPOINTS.md}) {
        transform: none;

        position: relative;
        grid-template-columns: ${(props) =>
            props.$quickViewIsOpen
                ? `0 2fr minmax(${INBOX_CONVERSATION_LIST_WIDTH}, 1fr)`
                : `minmax(${SIDENAV_WIDTH}, 1fr) 2fr`};
    }

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
        grid-template-columns: ${(props) =>
            props.$quickViewIsOpen
                ? `${SIDENAV_WIDTH} 0 3fr minmax(${INBOX_CONVERSATION_LIST_WIDTH},1fr)`
                : `${SIDENAV_WIDTH} minmax(${INBOX_CONVERSATION_LIST_WIDTH},1fr) 3fr 0`};
    }

    @media screen and (min-width: ${BREAKPOINTS.xl}) {
        grid-template-columns: ${(props) =>
            props.$quickViewIsOpen
                ? `${SIDENAV_WIDTH} 0 2fr minmax(${INBOX_CONVERSATION_LIST_WIDTH},1fr)`
                : `${SIDENAV_WIDTH} minmax(${INBOX_CONVERSATION_LIST_WIDTH},1fr) 2fr 0`};
    }
`;

export const StyledQuickViewContainer = styled.div`
    overflow-y: auto;
    border-left: 1px solid ${UI.Tokens.COLOURS.greyscale.silver};
    height: 100%;
`;
