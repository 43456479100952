import { SubscriptionResult } from "./types/subscription.types";

export const successfulSubscriptionResult = <T>(
    data: T,
): SubscriptionResult<T> => {
    return {
        data: data,
        errorMessage: null,
        status: "SUCCESS",
    };
};

export const loadingSubscriptionResult = <T>(): SubscriptionResult<T> => {
    return {
        data: null,
        errorMessage: null,
        status: "LOADING",
    };
};

export const failedSubscriptionResult = <T>(
    errorMessage: string,
): SubscriptionResult<T> => {
    return {
        data: null,
        errorMessage: errorMessage,
        status: "ERROR",
    };
};
