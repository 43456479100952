import { useMemo, useState } from "react";

import {
    Card,
    Feedback,
    Flex,
    Spinner,
    Tabs,
    Text,
    Tokens,
} from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { differenceInDays } from "date-fns";
import partition from "lodash/partition";
import styled from "styled-components";

import { useIntegratedClinicLists } from "app/hooks/queries";
import { ClinicTabId } from "app/patientLists/integratedClinics/types";

import getFilteredClinics, { FiltersValues } from "../getFilteredClinics";
import { FilteredList } from "./FilteredList";
import Filters, { UNSELECTED_FILTER_VALUE } from "./Filters";
import { Header } from "./Header";
import { HeaderSkeleton } from "./HeaderSkeleton";

const TabHeaderWrapper = styled.div`
    padding: ${Tokens.SIZES[1]};
`;

const TabsWrapper = styled.div`
    padding: ${Tokens.SIZES[1.5]};
`;

const ListWrapper = styled(Flex).attrs({
    flexDirection: "column",
    gap: "1",
})``;

type DataStatusResolverProps = {
    workspaceId: string;
};

export const DataStatusResolver = ({
    workspaceId,
}: DataStatusResolverProps) => {
    const { status, data } = useIntegratedClinicLists({
        workspaceId,
    });
    const [currentTab, setCurrentTab] = useState<ClinicTabId>("upcoming");
    const [filtersSelected, setFiltersSelected] = useState<FiltersValues>({
        clinicCode: UNSELECTED_FILTER_VALUE,
        responsibleClinician: UNSELECTED_FILTER_VALUE,
        location: UNSELECTED_FILTER_VALUE,
        date: UNSELECTED_FILTER_VALUE,
    });

    const onTabChange = (nextTabId: string) => {
        setCurrentTab(nextTabId as ClinicTabId);
        setFiltersSelected({
            clinicCode: UNSELECTED_FILTER_VALUE,
            responsibleClinician: UNSELECTED_FILTER_VALUE,
            location: UNSELECTED_FILTER_VALUE,
            date: UNSELECTED_FILTER_VALUE,
        });
    };

    const [pastClinics, upcomingClinicsUnsorted] = useMemo(
        () =>
            partition(data?.clinics, (clinic) =>
                DateHelpers.isBeforeToday(clinic.clinicDate),
            ),
        [data?.clinics],
    );
    const upcomingClinics = useMemo(
        () =>
            upcomingClinicsUnsorted.sort((clinicA, clinicB) => {
                if (clinicA.clinicDate < clinicB.clinicDate) {
                    return -1;
                } else if (clinicA.clinicDate > clinicB.clinicDate) {
                    return 1;
                }
                return 0;
            }),
        [upcomingClinicsUnsorted],
    );

    if (status === "loading") {
        return (
            <>
                <HeaderSkeleton />
                <Spinner />
            </>
        );
    }

    if (status === "error") {
        return (
            <Feedback
                colour="error"
                title="There was an issue retrieving clinic information"
                iconName="Failed"
            >
                <Text skinny>No information found</Text>
            </Feedback>
        );
    }

    const clinicInfoOutOfdate = data.lastUpdated
        ? differenceInDays(new Date(), new Date(data.lastUpdated)) >= 1
        : true;

    const currentTabClinics =
        currentTab === "upcoming" ? upcomingClinics : pastClinics;
    const filteredClinics = getFilteredClinics(
        currentTabClinics,
        filtersSelected,
    );

    return (
        <>
            <Header
                startDate={data.startDate}
                endDate={data.endDate}
                lastUpdated={data.lastUpdated}
            />
            <Tabs selected={currentTab} onTabChange={onTabChange}>
                <ListWrapper>
                    {data.clinics.length > 0 && (
                        <Card
                            spacing={0}
                            header={
                                <TabHeaderWrapper>
                                    <Tabs.TabList
                                        tabs={
                                            [
                                                {
                                                    tabId: "past",
                                                    text: "Past clinics",
                                                },
                                                {
                                                    tabId: "upcoming",
                                                    text: "Upcoming clinics",
                                                },
                                            ] satisfies Array<{
                                                tabId: ClinicTabId;
                                                text: string;
                                            }>
                                        }
                                    />
                                </TabHeaderWrapper>
                            }
                        >
                            <TabsWrapper>
                                <Tabs.Panel tabId="past">
                                    <Filters
                                        clinics={pastClinics}
                                        filtersSelected={filtersSelected}
                                        setFiltersSelected={setFiltersSelected}
                                        numberOfResults={filteredClinics.length}
                                    />
                                </Tabs.Panel>
                                <Tabs.Panel tabId="upcoming">
                                    <Filters
                                        clinics={upcomingClinics}
                                        filtersSelected={filtersSelected}
                                        setFiltersSelected={setFiltersSelected}
                                        numberOfResults={filteredClinics.length}
                                    />
                                </Tabs.Panel>
                            </TabsWrapper>
                        </Card>
                    )}

                    {clinicInfoOutOfdate && (
                        <Feedback
                            colour="error"
                            iconName="Failed"
                            title="There was an issue retrieving clinic information"
                        >
                            <Text skinny>
                                The clinic information has not been updated in
                                over 24 hours
                            </Text>
                        </Feedback>
                    )}

                    <FilteredList
                        workspaceId={workspaceId}
                        currentTab={{
                            tabId: currentTab,
                            hasNoClinics: currentTabClinics.length === 0,
                        }}
                        noClinics={data.clinics.length === 0}
                        filteredClinics={filteredClinics}
                        startDate={data.startDate}
                        endDate={data.endDate}
                    />
                </ListWrapper>
            </Tabs>
        </>
    );
};
