import {
    MessagePatientResponse,
    PatientThreadContentType,
    PatientThreadEmail,
    PatientThreadNhsAppMessage,
    PatientThreadSMS,
} from "@accurx/api/patient-messaging";
import { Log } from "@accurx/shared";
import {
    mapNhsAppMessageItem,
    mapPatientEmailItem,
    mapPatientSmsItem,
} from "domains/concierge/internal/api/shared/mappers/conversationItemMappers/patientMessage";
import { ConciergeUpdates } from "domains/concierge/internal/types/ConciergeUpdates";
import {
    NhsAppMessageItem,
    PatientEmailItem,
    PatientSmsItem,
} from "domains/concierge/types";

const mapMessagePatientResponseItemToConversationItem = (
    item: PatientThreadEmail | PatientThreadSMS | PatientThreadNhsAppMessage,
) => {
    switch (item.type) {
        case PatientThreadContentType.SMS:
            return mapPatientSmsItem(item);

        case PatientThreadContentType.PatientEmail:
            return mapPatientEmailItem(item);

        case PatientThreadContentType.NhsAppMessage:
            return mapNhsAppMessageItem(item);
        default:
            throw new Error("Unknown item type: " + item.type.toString());
    }
};

export const mapMessagePatientResponseItemsToConciergeUpdateConversationItems =
    (
        response: MessagePatientResponse,
    ): ConciergeUpdates["conversations"][0]["items"] | null => {
        const items = [
            ...(response.messages ?? []),
            ...(response.emailMessages ?? []),
            ...(response.nhsAppMessages ?? []),
        ];

        if (items.length === 0) {
            Log.error(
                "Expected IMessageResponse to include at least one sms or email message, but got nothing instead",
                { tags: { product: "Inbox" } },
            );
            return null;
        }

        const errors: Record<string, string> = {};
        const mappedItems: (
            | PatientSmsItem
            | PatientEmailItem
            | NhsAppMessageItem
        )[] = [];
        items.forEach((item, i) => {
            try {
                const mappedItem =
                    mapMessagePatientResponseItemToConversationItem(item);
                if (mappedItem) {
                    mappedItems.push(mappedItem);
                }
            } catch (e) {
                const message = e instanceof Error ? e.message : "";
                errors[
                    `indexPos:${i}-messageId:${
                        item.message?.accuRxMessageId ?? ""
                    }-id:${item.id ?? ""}-itemType:${item.type}`
                ] = message;
            }
        });

        const totalErrors = Object.keys(errors).length;
        if (totalErrors > 0) {
            Log.error(
                "Could not map one or more of the items in the message patient response, they will not be included in the concierge updates",
                {
                    tags: { product: "Inbox" },
                    context: [
                        {
                            name: "Summary",
                            data: {
                                totalItemsReceivedFromApi: items.length,
                                totalErrors,
                                totalMapped: mappedItems.length,
                            },
                        },
                        {
                            name: "Errors breakdown",
                            data: errors,
                        },
                    ],
                },
            );
        }

        if (mappedItems.length === 0) {
            return null;
        }

        return mappedItems;
    };
