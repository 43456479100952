import { useCallback, useEffect, useState } from "react";

import {
    InboxPath,
    InboxRouteName,
    useCurrentRoute,
} from "domains/inbox/hooks/util";
import { ROUTES_INBOX } from "domains/inbox/routes";

type AccordionPath = {
    topLevel: "Patients" | "CareProviders";
    patients?: string;
};

const getAccordionPathFromRoutePath = (path: InboxPath): AccordionPath => {
    switch (path) {
        case ROUTES_INBOX.MyInbox:
        case ROUTES_INBOX.MyInboxSent:
        case ROUTES_INBOX.MyInboxScheduled:
            return { topLevel: "Patients", patients: "MyInbox" };

        case ROUTES_INBOX.Unassigned:
        case ROUTES_INBOX.UnassignedAdmin:
        case ROUTES_INBOX.UnassignedMedical:
        case ROUTES_INBOX.UnassignedSelfReferral:
            return { topLevel: "Patients", patients: "Unassigned" };

        case ROUTES_INBOX.All:
        case ROUTES_INBOX.AllSent:
        case ROUTES_INBOX.AllScheduled:
        case ROUTES_INBOX.AllPatientTriages:
        case ROUTES_INBOX.AllPatientResponses:
        case ROUTES_INBOX.AllFailed:
            return { topLevel: "Patients", patients: "AllConversations" };

        case ROUTES_INBOX.Teams:
            return { topLevel: "Patients", patients: "Teams" };

        case ROUTES_INBOX.Questionnaires:
            return { topLevel: "Patients", patients: "Questionnaires" };

        case ROUTES_INBOX.Shared:
            return { topLevel: "Patients", patients: "Shared" };

        case ROUTES_INBOX.Colleagues:
            return { topLevel: "Patients", patients: "Colleagues" };

        case ROUTES_INBOX.Patient:
            return { topLevel: "Patients", patients: "Patient" };

        case ROUTES_INBOX.CareProvidersMyInbox:
        case ROUTES_INBOX.CareProvidersAll:
            return { topLevel: "CareProviders" };
    }
};

export const useAccordionBehaviour = () => {
    const currentRoute = useCurrentRoute();

    const [path, setPath] = useState<AccordionPath>(() => {
        if (currentRoute) {
            return getAccordionPathFromRoutePath(currentRoute.path);
        } else {
            return {
                topLevel: "Patients",
                patients: "MyInbox",
            };
        }
    });

    useEffect(() => {
        if (currentRoute) {
            setPath(getAccordionPathFromRoutePath(currentRoute.path));
        }
    }, [currentRoute]);

    return { path, setPath };
};

export const useIsCurrentPath = () => {
    const currentRoute = useCurrentRoute();

    return useCallback(
        (path: InboxRouteName | InboxRouteName[]) => {
            if (typeof path === "string") {
                return !!currentRoute && path === currentRoute.name;
            } else {
                return !!currentRoute && path.includes(currentRoute.name);
            }
        },
        [currentRoute],
    );
};
