import { MultiSelectOption } from "../types";

/**
 * @description Converts a stringed arr to an object arr that the multi-select comp can understand
 */
export const formatToInputObject = (
    arr: string[],
    selectedArr: string[],
    isDisabled?: (item: string, selectedArr: string[]) => boolean,
): MultiSelectOption[] => {
    return arr.map((item, i) => {
        return {
            value: `${item.toLowerCase().replace(/ /g, "-")}-${i}`,
            label: item,
            disabled: isDisabled ? !isDisabled(item, selectedArr) : false,
        };
    });
};
