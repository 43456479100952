import * as UI from "@accurx/design";
import styled from "styled-components";

export const StyledExpandableContainer = styled(UI.Flex).attrs({ gap: "1" })<{
    $isExpanded: boolean;
    $isFloating: boolean;
}>`
    ${(props) => props.$isExpanded && "flex: 1;"}
    margin: ${({ $isFloating }) => UI.Tokens.SIZES[$isFloating ? 2 : 1]} 
    ${({ $isFloating }) => UI.Tokens.SIZES[$isFloating ? 3 : 2]};
    position: ${(props) => (props.$isFloating ? "absolute" : "inherit")};
    bottom: 0;
    left: 0;
`;
