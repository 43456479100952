import { ConversationGroupSortOptions } from "domains/concierge/internal/types/ConversationGroupSortOptions";

const toAssignTriage = ({
    status,
}: {
    status: "Open" | "Done";
}): ConversationGroupSortOptions => {
    return status === "Open"
        ? [
              { key: "IsUrgent", direction: "desc" },
              { key: "LastUpdated", direction: "desc" },
              { key: "Id", direction: "desc" },
          ]
        : [
              { key: "LastUpdated", direction: "desc" },
              { key: "Id", direction: "desc" },
          ];
};

export const sortOptions = {
    toAssignTriage,
};
