import { SupportUrls } from "@accurx/shared";

import { LoginPageLayoutProps } from "app/layout/LoginPageLayout/LoginPageLayout";

export const SHOW_LOGIN_FORM_SEARCH_PARAM = "showLoginForm";

export type LoginPageLayoutVariants =
    | "clinicianConversation"
    | "organisations"
    | "recordView"
    | "defaultTrust";

export const LOGIN_LAYOUT_CONSTANTS: {
    [key in LoginPageLayoutVariants]: LoginPageLayoutProps;
} = {
    clinicianConversation: {
        featuresList: [
            {
                listItems: [
                    {
                        heading: "Delivery and read receipts",
                        icon: { name: "TickDouble", style: "Line" },
                    },
                    {
                        heading: "Know when the practice is online",
                        icon: { name: "Team", style: "Line" },
                    },
                    {
                        heading: "A reliable and secure platform",
                        icon: { name: "LockLocked", style: "Fill" },
                    },
                ],
                heading: "Why message online?",
            },
        ],
        footer: {
            link: SupportUrls.GetStartedWithWeb,
            text: "Accurx is used by over 98% of GP Practices",
        },
    },
    organisations: {
        featuresList: [
            {
                listItems: [
                    {
                        heading: "Batch Self-Book",
                        text: "Invite patients to book face-to-face appointments in your clinical system.",
                        icon: { name: "Calendar", style: "Line" },
                    },
                    {
                        heading: "Custom Floreys",
                        badge: "New",
                        text: "Create your own questionnaires that can be sent to one or a list of patients.",
                        icon: { name: "Survey", style: "Line" },
                    },
                ],
                heading: "What's new?",
            },
        ],
    },
    recordView: {
        featuresList: [
            {
                listItems: [
                    {
                        heading:
                            "Save time fielding requests for patient records",
                        icon: { name: "Clock", style: "Line" },
                    },
                    {
                        heading:
                            "Help secondary care colleagues deliver safer care",
                        icon: { name: "Team", style: "Line" },
                    },
                ],
                heading: "Why use Record View?",
                text: "Quick and secure record sharing made easy.",
            },
        ],
        footer: {
            link: SupportUrls.RecordViewSecondaryCare,
            text: "If you work in secondary care find out how you can use Record View",
        },
    },
    defaultTrust: {
        featuresList: [
            {
                listItems: [
                    {
                        heading: "Secondary care workers",
                        icon: { name: "HealthProfessional", style: "Line" },
                    },
                    {
                        heading: "Community care workers",
                        icon: { name: "Carehome", style: "Line" },
                    },
                    {
                        heading: "Community pharmacies",
                        icon: { name: "Medication", style: "Line" },
                    },
                ],
                heading: "For who?",
            },
            {
                listItems: [
                    {
                        heading: "Free SMS messaging with patients",
                        icon: { name: "Message", style: "Line" },
                    },
                    {
                        heading: "Free access to a patient’s GP record",
                        icon: { name: "Record", style: "Line" },
                    },
                    {
                        heading: "Free video calls with patients",
                        icon: { name: "Video", style: "Line" },
                    },
                ],
                heading: "For what?",
            },
        ],
        footer: {
            link: SupportUrls.IGAndSecurity,
            text: "NHS Digital approved supplier",
        },
    },
};
