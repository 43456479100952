import React, { useEffect } from "react";

import {
    Feedback,
    FormFieldWrapper,
    Option,
    Select,
    Spinner,
    Text,
} from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { fetchBeastSeries } from "app/batchMessage/gp/BatchMessage.actions";
import { BeastSeriesDto } from "app/batchMessage/gp/BatchMessage.types";
import { Z_INDEX } from "app/batchMessage/gp/components/compose/BatchMessageCompose.styles";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { UpdatingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

type ComposeInhalerDropdownProps = {
    selectedBeastSeries: BeastSeriesDto | null;
    selectBeastSeries: (serie: BeastSeriesDto) => void;
};

export const ComposeInhalerDropdown = ({
    selectedBeastSeries,
    selectBeastSeries,
}: ComposeInhalerDropdownProps): JSX.Element => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const beastSeries = useAppSelector(
        ({ batchMessage }) => batchMessage.beastSeries,
        shallowEqual,
    );
    const fetchingBeastSeriesStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.fetchingBeastSeriesStatus,
        shallowEqual,
    );

    // Load the dropdown data
    useEffect(() => {
        if (
            fetchingBeastSeriesStatus === UpdatingStatus.Initial &&
            practiceId
        ) {
            dispatch(fetchBeastSeries(practiceId));
        }
    }, [dispatch, practiceId, fetchingBeastSeriesStatus]);

    if (!beastSeries) {
        return (
            <>
                {fetchingBeastSeriesStatus === UpdatingStatus.Loading && (
                    <Spinner />
                )}
                {fetchingBeastSeriesStatus === UpdatingStatus.Failed && (
                    <Feedback
                        colour="error"
                        title="There was a connection problem."
                        props={{ className: "mb-4" }}
                    >
                        <Text skinny>
                            Refresh the page to try again. If that still doesn't
                            work, contact support.
                        </Text>
                    </Feedback>
                )}
            </>
        );
    }

    // Create an array of options for the dropdown
    const options: Option[] = beastSeries.series.map(
        (serie: BeastSeriesDto) => ({ label: serie.name, value: serie.id }),
    );

    // Use selected value or select the first available value
    const firstSeries = beastSeries.series[0];
    const initialValue = selectedBeastSeries
        ? {
              label: selectedBeastSeries.name,
              value: selectedBeastSeries.id,
          }
        : {
              label: firstSeries.name,
              value: firstSeries.id,
          };

    // TODO: selected type should allow a single Option
    const handleOptionChange = (selected: Option[] | Option) => {
        const selectedSerie = beastSeries.series.find(
            (serie: BeastSeriesDto) => serie.id === (selected as Option).value,
        );
        if (selectedSerie) {
            selectBeastSeries(selectedSerie);
            ChainAnalyticsTracker.trackBatchComposeRctOptionClick(
                analyticsLoggedInProps,
            );
        }
    };

    return (
        <FormFieldWrapper
            className="mb-4"
            label="RCT"
            labelProps={{
                htmlFor: "inhaler-dropdown",
            }}
        >
            <Select
                id="inhaler-dropdown"
                options={options}
                onChangeHandler={handleOptionChange}
                initSelectedValues={initialValue}
                zIndex={Z_INDEX.dropdown}
            />
        </FormFieldWrapper>
    );
};
