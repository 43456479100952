import { useEffect } from "react";

import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

type PageName = "ClinicList" | "Clinic";

export function useTrackLoggedInPageView(pageName: PageName) {
    const loggedInProps = useFlemingLoggedInAnalytics();

    useEffect(() => {
        GenericTrackEvent({
            object: pageName,
            objectType: EventObjectType.Page,
            action: EventAction.View,
            properties: {
                ...loggedInProps,
                userIsLoggedIn: true,
                eventVersion: 1,
                eventUserType: EventUserType.HealthcareProfessional,
            },
        });
    }, [loggedInProps, pageName]);
}
