import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${Tokens.SIZES[1]};
    margin-bottom: ${Tokens.SIZES[1.5]};
`;
