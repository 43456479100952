import React, { useEffect } from "react";

import {
    Card,
    Feedback,
    Flex,
    Icon,
    Spinner,
    Table,
    Text,
} from "@accurx/design";
import { generatePath, useParams } from "react-router";
import { Link } from "react-router-dom";

import { ChainAnalyticsTracker } from "app/analytics";
import { BatchMessageModalDetails } from "app/batchMessage/gp/BatchMessage.types";
import { useBatchDetailsQuery } from "app/hooks/queries/useBatchDetailsQuery";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { ROUTES_WORKSPACE } from "shared/Routes";

import {
    PageWrapper,
    StyledTable,
    StyledTableHead,
} from "./ConfirmPage.styles";

// How often to poll the batch details endpoint (30 seconds)
const REFETCH_INTERVAL = 30000;

const getTotalPendingFromBatchDetails = (
    batchDetails: BatchMessageModalDetails,
) => {
    // As totalItems includes non-textable patients, we remove these
    // and processed items to calculate how many are still pending
    return (
        batchDetails.totalItems -
        batchDetails.nonTextablePatients -
        batchDetails.totalProcessed
    );
};

export const ConfirmPage = () => {
    const { batchId, workspaceId } = useParams<{
        batchId: string;
        workspaceId: string;
    }>();

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const query = useBatchDetailsQuery({
        workspaceId,
        batchId,
        // Poll the endpoint for updates while there are still unsent messages
        refetchInterval: (data?: BatchMessageModalDetails) => {
            if (!data) {
                return false;
            }

            const totalPending = getTotalPendingFromBatchDetails(data);

            if (totalPending === 0) {
                return false;
            }

            return REFETCH_INTERVAL;
        },
    });

    useEffect(() => {
        if (query.data) {
            ChainAnalyticsTracker.trackBatchConfirmPageView({
                ...analyticsLoggedInProps,
                countAccepted: query.data.totalMessages,
                isTrustFlow: true,
            });
        }
    }, [query?.data, analyticsLoggedInProps]);

    if (query.status === "error") {
        return (
            <Feedback
                title="An error occurred while trying to load batch details"
                colour="error"
            >
                {query.error.message}
            </Feedback>
        );
    }

    if (query.status === "loading") {
        return <Spinner />;
    }

    const totalPending = getTotalPendingFromBatchDetails(query.data);
    const totalProcessed = query.data.totalProcessed;

    return (
        <PageWrapper>
            <Card
                header={
                    <CardHeader
                        sentConversationsUrl={generatePath(
                            ROUTES_WORKSPACE.batchMessageTrustSentMessages,
                            {
                                workspaceId,
                            },
                        )}
                    />
                }
                footer={<CardFooter isComplete={totalPending === 0} />}
            >
                <StyledTable shouldAnimate={!query.isRefetching}>
                    <StyledTableHead>
                        <Table.Row>
                            <Table.Header scope="column">Pending</Table.Header>
                            <Table.Header scope="column">Complete</Table.Header>
                        </Table.Row>
                    </StyledTableHead>
                    <Table.Body>
                        <Table.Row>
                            <Table.DataCell>{totalPending}</Table.DataCell>
                            <Table.DataCell>{totalProcessed}</Table.DataCell>
                        </Table.Row>
                    </Table.Body>
                </StyledTable>
            </Card>
        </PageWrapper>
    );
};

const CardHeader = ({
    sentConversationsUrl,
}: {
    sentConversationsUrl: string;
}) => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    return (
        <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" gap="1">
                <Icon name="Messages" halo={{ colour: "green" }} size={6} />
                <Text variant="label" skinny>
                    Messages being sent
                </Text>
            </Flex>
            <Text skinny>
                <Link
                    to={sentConversationsUrl}
                    onClick={() => {
                        ChainAnalyticsTracker.trackBatchConfirmMessageStatusViewClick(
                            {
                                ...analyticsLoggedInProps,
                                isTrustFlow: true,
                            },
                        );
                    }}
                >
                    View sent messages
                </Link>
            </Text>
        </Flex>
    );
};

const CardFooter = ({ isComplete }: { isComplete: boolean }) => {
    return (
        <Flex gap="0.5" alignItems="center" justifyContent="flex-end">
            <Icon
                name="Clock"
                colour={isComplete ? "green" : "metal"}
                size={3}
            />
            <Text variant="body" skinny>
                {isComplete ? "Complete" : "Updates every 30 secs"}
            </Text>
        </Flex>
    );
};
