import React, { ReactNode } from "react";

import { Card, Text } from "@accurx/design";

export type CardTitleProps = {
    children: ReactNode;
    cardTitle: string;
    cardFooter: JSX.Element | string | undefined;
};

export const CardWithTitle = ({
    cardTitle,
    children,
    cardFooter,
}: CardTitleProps): JSX.Element => {
    return (
        <Card
            variant={"regular"}
            children={children}
            spacing={3}
            header={
                <Text
                    variant={"note"}
                    as={"h2"}
                    children={cardTitle.toUpperCase()}
                />
            }
            headerBackgroundColour={"dishwater"}
            footer={cardFooter}
        />
    );
};
