import { OrganisationSetupUserViewModel, UserRole } from "@accurx/api/portal";
import { User } from "@accurx/auth";
import orderBy from "lodash/orderBy";

/**
 * This method sort array in that way:
 * 1. current user goes first
 * 2. then admins in alphabetical order by name (or by email if there is no name)
 * 3. then others in alphabetical order by name (or by email if there is no name)
 */
export const sortUsers = ({
    users,
    currentUserId,
}: {
    users: OrganisationSetupUserViewModel[];
    currentUserId: User["accuRxUserId"];
}) => {
    const orderedList = orderBy(
        users,
        [
            (user) => String(user.userId) === currentUserId,
            (user) => user.userRoleState?.currentValue === UserRole.Admin,
            (user) => user.name?.toLowerCase() || user.email?.toLowerCase(),
        ],
        ["desc", "desc", "asc"],
    );
    return orderedList;
};
