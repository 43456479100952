import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledTwoThirdsContainer = styled.div`
    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        width: calc(100% * 2 / 3);
    }
`;

export { StyledTwoThirdsContainer };
