import { useConciergeSelector } from "domains/concierge/internal/context";
import { UserSummary } from "domains/concierge/types";

export const useUser = ({
    userId,
}: {
    userId: UserSummary["id"];
}): UserSummary | undefined => {
    return useConciergeSelector((state) => state.users.items[userId]);
};
