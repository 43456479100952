import React from "react";

export const PostSsoRedirect = () => {
    const message = {
        message: "SsoPopoutLoginSuccess",
    };
    if (window.opener) {
        // sends message to the primary window
        window.opener.postMessage(message, window.location.origin);
    }
    // close the popup
    window.close();

    return <></>;
};
