import { Tokens } from "@accurx/design";
import TextareaAutosize from "react-autosize-textarea";
import styled from "styled-components";

// Handles main content view with a footer
export const StyledLayoutWithFooter = styled.div`
    padding-bottom: 10%;

    @media (max-width: ${Tokens.BREAKPOINTS.md}) {
        padding-bottom: 20%;
    }
`;

export const StyledLeftBarBox = styled.div`
    border-left: ${Tokens.SIZES[0.5]} solid ${Tokens.COLOURS.primary.green[100]};
    padding-left: ${Tokens.SIZES[2]};
    margin-bottom: ${Tokens.SIZES[4]};
`;

export const StyledBatchTypeList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-top: ${Tokens.SIZES[3]};
`;

export const StyledMessageComposeWrapper = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    white-space: pre-wrap;
    border: 1px solid ${Tokens.COLOURS.greyscale.silver};
    padding: ${Tokens.SIZES[1]};
`;

export const StyledTextareaAutosize = styled(TextareaAutosize)`
    width: 100%;
    padding: ${Tokens.SIZES[1]};
    background-color: ${Tokens.COLOURS.greyscale.white};
    border-color: ${Tokens.COLOURS.greyscale.silver};
    resize: vertical;
    fontsize: ${Tokens.SIZES[1]};
`;
