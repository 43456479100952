import React, {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

import { createPatientManager } from "shared/concierge/patients/PatientManager";
import { PatientManager } from "shared/concierge/patients/types/PatientManager.types";
import {
    WorkspaceSingletonProvider,
    createWorkspaceSingletonProvider,
} from "shared/workspace/WorkspaceSingletonProvider";

const GlobalPatientManagerContext =
    createContext<WorkspaceSingletonProvider<PatientManager> | null>(null);

type ProviderProps = {
    children?: ReactNode;
    currentUserId?: string;
};

/**
 * GlobalPatientManagerProvider
 *
 * Sets up a global instance to provide PatientManager instances for
 * a user and exposes it via React Context.
 *
 * This in turn provides a factory for spinning up managers scoped
 * to a specific workspace, ensuring that only one may exist for a
 * given workspace at once.
 */
export const GlobalPatientManagerProvider = (
    props: ProviderProps,
): JSX.Element => {
    const [tracker, setTracker] =
        useState<WorkspaceSingletonProvider<PatientManager> | null>(null);

    useEffect(() => {
        const result = props.currentUserId
            ? createWorkspaceSingletonProvider<PatientManager>(
                  "PatientManager",
                  (workspaceId) => createPatientManager(workspaceId),
                  (mgr) => mgr.unsubscribe(),
              )
            : null;
        setTracker(result);
        return () => result?.destroy();
    }, [props.currentUserId]);

    return (
        <GlobalPatientManagerContext.Provider value={tracker}>
            {props.children}
        </GlobalPatientManagerContext.Provider>
    );
};

/**
 * Provides the global patient manager instance.
 */
export const useGlobalPatientManager = () =>
    useContext(GlobalPatientManagerContext);

/**
 * Export the global context provider directly so that our UI tests can easily
 * wrap a component with a fake manager.
 */
export const StaticGlobalPatientManagerProvider =
    GlobalPatientManagerContext.Provider;
