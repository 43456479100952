/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React from "react";

import { Avatar, Icon } from "@accurx/design";

import { Assignee } from "app/workspaceConversations/workspaceConversations.types";

import { getAssigneeDisplayName } from "../AssignHelper";
import {
    StyledButton,
    StyledDetailsSection,
    StyledInfo,
    StyledItemContainer,
    StyledTeamIconWrapper,
    StyledText,
} from "./AssignItem.styles";

type AssignItemProps = {
    assignee: Assignee;
    selectedItem: boolean;
    isLoggedInUser: boolean;
    handleAssigneeItemClick: (user: Assignee) => void;
};

export const AssignItem = ({
    assignee,
    selectedItem,
    isLoggedInUser,
    handleAssigneeItemClick,
}: AssignItemProps): JSX.Element => {
    const onItemClick = () => {
        handleAssigneeItemClick(assignee);
    };

    return (
        <StyledItemContainer
            selectedItem={selectedItem}
            aria-selected={selectedItem ? "true" : "false"}
        >
            {assignee.type === "User" && (
                <Avatar
                    name={getAssigneeDisplayName(assignee)}
                    size="large"
                    colour={isLoggedInUser ? "blue" : "grey"}
                />
            )}
            {assignee.type === "Team" && (
                <StyledTeamIconWrapper title={getAssigneeDisplayName(assignee)}>
                    <Icon theme="Line" name="Team" size={3} colour="night" />
                </StyledTeamIconWrapper>
            )}
            <StyledDetailsSection>
                <StyledInfo>
                    <StyledText
                        props={{ title: getAssigneeDisplayName(assignee) }}
                        variant="note"
                        selectedItem={selectedItem}
                    >
                        {getAssigneeDisplayName(assignee)}{" "}
                        {isLoggedInUser && " (You)"}
                    </StyledText>
                </StyledInfo>
                {!selectedItem && (
                    <StyledButton
                        theme="secondary"
                        text="Assign"
                        aria-label={`Assign to ${getAssigneeDisplayName(
                            assignee,
                        )}`}
                        icon={{ name: "Assign", style: "Fill", colour: "blue" }}
                        onClick={onItemClick}
                    />
                )}
            </StyledDetailsSection>
        </StyledItemContainer>
    );
};
