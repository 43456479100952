import { log } from "./Debug";

/**
 * A basic Mutex (MUTually EXclusive lock)
 * https://en.wikipedia.org/wiki/Lock_(computer_science)
 *
 * Currently only used by WebInbox, check out its usage
 * in there. Suited for purposes where you need to gate
 * doing some work whilst something else is happening.
 *
 * Usage
 * ===
 * const mutex = new Mutex()
 *
 * function someImportantLongRunningWork() {
 *     mutex.lock("someStringToIdentifyCallingCode") <- acquire the lock
 *
 *     // do some stuff
 *
 *     mutex.release("someStringToIdentifyCallingCode") <- release the lock
 * }
 *
 * // elsewhere in your code, using the *same* Mutex instance
 *
 * if (mutex.isLocked() === false) {
 *     // stuff that should only happen when
 *     // someImportantLongRunningWork() is not in progress
 * }
 */
export class Mutex {
    private _lockHolders: string[] = [];
    private _isLocked = false;

    constructor(readonly debug = false) {}

    lock(identifier: string) {
        this._lockHolders.push(identifier);
        this._isLocked = true;

        if (this.debug) {
            log("request lock acquired", this._printInternalState());
        }
    }

    release(identifier: string, debug = false) {
        const lockHolderIdx = this._lockHolders.indexOf(identifier);
        if (lockHolderIdx > -1) {
            this._lockHolders.splice(lockHolderIdx, 1);
        }

        if (this._lockHolders.length === 0) {
            this._isLocked = false;
        }

        if (this.debug) {
            log("request lock released", this._printInternalState());
        }
    }

    isLocked() {
        return this._isLocked;
    }

    private _printInternalState() {
        const state = [
            `_isLocked: ${this._isLocked}`,
            `_lockHolders: [${this._lockHolders}]`,
        ];
        return state.join("\n");
    }
}
