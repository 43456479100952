import React from "react";

import { Tokens } from "@accurx/design";
import styled, { css, keyframes } from "styled-components";

/*
    Skeleton Text components
    ========================

    The exported components are intended to allow rendering of placeholder skeleton
    UI for *text content only*, at the size/dimensions that exactly match our Text
    variants. Currently, this is achieved by manually setting dimensions (go to bottom
    of file)

    Reasons to change
    -----------------

    As support for more variants is needed, this module can expand to define and export
    them.

    Once we have well-established usage patterns, it may make sense to move these
    to the component library. In that event, we could look to tie the definition
    more tightly to the underlying styling/appearance of Text variants. That way, if
    we change font-size for one variant, the corresponding skeleton UI would adapt
    to match.

    Some potential options for achieving this (no obligation, do as you wish)

        - add this state into the underlying component (i.e. isLoading prop on Text)
        - utility higher-order component that can be used to create this behaviour ad-hoc
        - standalone components (as implemented here) for max. flexibility/simplicity

    If we end up wanting skeleton UI for other types of underlying components (e.g. image)
    then we'd need to handle those explicitly. We don't currently have any use cases, so
    this is concerned only with Text.
 */

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

/**
 * The number of characters to mimic for the loading placeholder UI.
 * e.g. if displaying skeleton for an NHS number, you may want to set
 * charCount={12} (to match 3<space>3<space>4 format)
 */
type SkeletonTextProps = { charCount: number };
type SkeletonBlockProps = {
    height: React.CSSProperties["height"];
    width: React.CSSProperties["width"];
};

const baseSkeletonCss = css`
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;

    background-color: var(--base-color);
    border-radius: ${Tokens.BORDERS.radius};
    position: relative;
    overflow: hidden;

    // z-index necessary for overflow: hidden to work correctly in Safari
    z-index: 1;

    // only enable the animation if user has not set a value for prefers-reduced-motion
    @media (prefers-reduced-motion: no-preference) {
        &::after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-repeat: no-repeat;
            background-image: linear-gradient(
                90deg,
                var(--base-color),
                var(--highlight-color),
                var(--base-color)
            );
            transform: translateX(-100%);

            display: block;
            animation-name: ${shimmer};
            animation-direction: var(--animation-direction);
            animation-duration: var(--animation-duration);
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
`;

const SkeletonText = styled.span<SkeletonTextProps>`
    ${baseSkeletonCss}
    display: inline-flex;

    /* ch units to mimic width of specified number of characters
     * see https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units#relative_length_units
     */
    width: ${(props) => props.charCount}ch;
`;

/*
 * The exported skeleton placeholders per Text variant
 *
 * these manually specify the vertical dimensions to match each Text variant
 * (for the time being). We can't use the exact same CSS properties as Text does,
 * because skeleton UI does not contain any text, so font-size and line-height
 * do nothing. Instead, use the font-size value for height, and (line-height - font-size)
 * spread evenly over top & bottom margin. seems to work well 😀
 */

export const SkeletonSubtitle = styled(SkeletonText)`
    height: ${Tokens.TYPOGRAPHY.subtitle.desktop.fontSize};
    margin: 0.5rem 0;
`;
export const SkeletonPreview = styled(SkeletonText)`
    height: ${Tokens.TYPOGRAPHY.preview.desktop.fontSize};
`;
export const SkeletonLabel = styled(SkeletonText)`
    height: ${Tokens.TYPOGRAPHY.label.desktop.fontSize};
`;
export const SkeletonNote = styled(SkeletonText)`
    height: ${Tokens.TYPOGRAPHY.note.desktop.fontSize};
`;
export const SkeletonBody = styled(SkeletonText)`
    height: ${Tokens.TYPOGRAPHY.body.desktop.fontSize};
`;

export const SkeletonBlock = styled.div<SkeletonBlockProps>`
    ${baseSkeletonCss}
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;
