import * as React from "react";

import { Text } from "@accurx/design";

import { ROUTES } from "shared/Routes";

import { SsoErrorCard } from "./SsoErrorCard";

export const SsoFailed = () => (
    <SsoErrorCard
        header="Oops! We can't authenticate you"
        body={
            <>
                <Text>
                    Try <a href={ROUTES.login}>logging in</a> using your email
                    address and password.
                </Text>

                <Text>
                    Our team is aware of issues with NHS login and we're working
                    on a fix at the moment.
                </Text>

                <Text>
                    In the meantime, we have found that clearing browser cookies
                    often helps. Here are instructions how you can do this in{" "}
                    <a
                        href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Edge
                    </a>{" "}
                    or{" "}
                    <a
                        href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Chrome
                    </a>
                    .
                </Text>

                <Text>
                    If that doesn't work, chat to us through{" "}
                    <a
                        href="https://support.accurx.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        live chat
                    </a>{" "}
                    or email us on{" "}
                    <a href="mailto:support@accurx.com">support@accurx.com</a>
                </Text>
            </>
        }
    />
);
