import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";
import { isPast } from "date-fns";
import { SkeletonLoader } from "domains/compose/ILLEGAL_IMPORTS_DO_NOT_USE";
import { useCompose } from "domains/compose/context";
import { useAppointmentsConfigurationQuery } from "domains/compose/hooks/useAppointmentConfigurationQuery";
import { usePresetDateOptions } from "domains/compose/hooks/usePresetDateOptions";

import { StyledPopoverItem } from "../../ScheduleSend.styles";
import { PresetDateType, ScheduleSendAt } from "../../types/ScheduleSend.types";
import { getSelectedDateTimeUK } from "../../utils/getSelectedDateTimeUK";

type ScheduleSendProps = {
    onConfirmScheduleSend: (sendAt: ScheduleSendAt) => void;
    onClickCustomDate: () => void;
};

export const ScheduleSendPopoverContent = ({
    onConfirmScheduleSend,
    onClickCustomDate,
}: ScheduleSendProps) => {
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();

    const { state } = useCompose();
    const { data } = useAppointmentsConfigurationQuery();

    const showCustomDateTimeOption =
        data?.selfBookSchedulingWeeksIntoFuture || !state.selfBookLink;

    const { status, options, nextWorkingDay } = usePresetDateOptions();

    const trackItemClick = ({
        scheduleType,
        scheduleTime,
    }: {
        scheduleType: "Now" | "Custom";
        scheduleTime: string | null;
    }) => {
        const scheduledTimestampUtc = scheduleTime
            ? new Date(scheduleTime).toUTCString()
            : undefined;

        track("ConversationSchedule MenuItem Click", {
            ...nativeTrackingFields,
            indexMessage: 0,
            templateName: state.template?.value.title,
            scheduleType,
            scheduledTimestampUtc,
        });
    };

    const onSelectPresetDate = (value: PresetDateType | null) => {
        const sendAtDateTime = value
            ? getSelectedDateTimeUK({ type: value, nextWorkingDay })
            : null;

        const sendAt =
            value && sendAtDateTime
                ? {
                      type: value,
                      sendAtDateTime,
                  }
                : null;

        trackItemClick({
            scheduleType: "Custom",
            scheduleTime: sendAtDateTime,
        });
        onConfirmScheduleSend(sendAt);
    };

    const hasSelectedDatePassed = state.sendAt
        ? isPast(new Date(state.sendAt.sendAtDateTime))
        : false;

    return (
        <>
            <StyledPopoverItem
                aria-label="Now"
                aria-current={!state.sendAt}
                active={!state.sendAt}
                onClick={
                    state.sendAt
                        ? () => {
                              trackItemClick({
                                  scheduleType: "Now",
                                  scheduleTime: null,
                              });
                              onSelectPresetDate(null);
                          }
                        : undefined
                }
            >
                Now
            </StyledPopoverItem>
            {status === "loading" ? (
                <StyledPopoverItem>
                    <SkeletonLoader width="120px" />
                </StyledPopoverItem>
            ) : (
                options.map((item) => {
                    const isActive =
                        !hasSelectedDatePassed &&
                        state.sendAt?.type === item.value;
                    return (
                        <StyledPopoverItem
                            key={item.value}
                            aria-label={item.text}
                            aria-current={isActive}
                            active={isActive}
                            onClick={
                                isActive
                                    ? undefined
                                    : () => onSelectPresetDate(item.value)
                            }
                        >
                            {item.text}
                        </StyledPopoverItem>
                    );
                })
            )}
            {showCustomDateTimeOption && (
                <StyledPopoverItem
                    aria-label={"Custom date and time"}
                    // We don't want to visually show a selected state but we can still derive it a11y-wise
                    aria-current={
                        !hasSelectedDatePassed &&
                        (state.sendAt?.type === "calendar" ||
                            state.sendAt?.type === "frame")
                    }
                    onClick={() => {
                        trackItemClick({
                            scheduleType: "Custom",
                            scheduleTime: null,
                        });
                        onClickCustomDate();
                    }}
                >
                    Custom date and time
                </StyledPopoverItem>
            )}
        </>
    );
};
