import { useCallback } from "react";

import { Option } from "@accurx/design";
import { getApiEndpoint, httpClient, returnDataOrThrow } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { transformToSlotTypesOptions } from "../utils/SelfbookFormComponentUtils";
import { KeyValueDTO } from "../utils/types";

export const GET_SLOT_TYPE_FOR_TARGET_ORGANISATION_KEY =
    "SlotTypeForTargetOrganisationKey";

export interface SlotTypeForTargetOrganisationResponse {
    telephone: KeyValueDTO;
    faceToFace: KeyValueDTO;
}

const transformToOptionsForAppointmentType = (
    appointmentType: string,
    data: SlotTypeForTargetOrganisationResponse,
) =>
    appointmentType === "FaceToFace"
        ? transformToSlotTypesOptions(data.faceToFace)
        : transformToSlotTypesOptions(data.telephone);

export const useSlotTypeForTargetOrganisationQuery = (
    orgId: number,
    isDefaultOrg: boolean,
    targetOdsCode: string,
    appointmentType: string,
): UseQueryResult<Option[], Error> =>
    useQuery({
        enabled: !isDefaultOrg,
        queryKey: [
            GET_SLOT_TYPE_FOR_TARGET_ORGANISATION_KEY,
            `${orgId}-${targetOdsCode}`,
        ],
        queryFn: async () =>
            await httpClient
                .getReturnJsonSafeAsync(
                    getApiEndpoint({
                        path: "/api/appointments/SelfBook/SlotTypesForTargetOrganisation",
                        query: `?organisationId=${orgId}&targetOdsCode=${targetOdsCode}`,
                    }),
                )
                .then(
                    (response) =>
                        returnDataOrThrow(
                            response,
                        ) as SlotTypeForTargetOrganisationResponse,
                ),
        refetchOnMount: true,
        // memoise to not run on every render
        select: useCallback(
            (data: SlotTypeForTargetOrganisationResponse) =>
                transformToOptionsForAppointmentType(appointmentType, data),
            [appointmentType],
        ),
    });
