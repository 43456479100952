import { ComponentProps, ReactNode } from "react";

import * as UI from "@accurx/design";

import { StyledCheckbox } from "./Checkbox.styles";

type CheckboxProps = Omit<
    ComponentProps<typeof UI.Checkbox>,
    // Omitted "ref" and "as" HTMLProps<HTMLDivElement> props
    // as they went in conflict with styled components
    "theme" | "ref" | "as"
>;

export const Checkbox = ({
    children,
    ...props
}: CheckboxProps & { children: ReactNode }) => {
    return (
        <StyledCheckbox theme="bordered" {...props}>
            {children}
        </StyledCheckbox>
    );
};
