import { ClinicianConversationWithWorkspace } from "@accurx/api/clinician-messaging";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as ClinicianMessagingApiClient from "../ClinicianMessagingApiClient";
import { mapClinicianConversationToConciergeUpdate } from "../mappers/mapClinicianConversationToConciergeUpdate";

type FetchConversationOptions = {
    serverId: string;
    workspaceId: number;
};

export const getConversation: ConciergeFetcher<
    FetchConversationOptions,
    ClinicianConversationWithWorkspace
> = async (arg) => {
    const response = await ClinicianMessagingApiClient.fetchConversation({
        workspaceId: arg.workspaceId,
        conversationId: arg.serverId,
    });

    const updates = mapClinicianConversationToConciergeUpdate(response);

    return {
        updates,
        response,
    };
};
