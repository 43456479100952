import { TriageType } from "./types";

export const formatTriageType = (triageType: TriageType) => {
    // Split the string when encountering an uppercase letter
    const triageTypeSplitByUppercase = triageType.match(
        /([A-Z][a-z]+)|([A-Z])/g,
    ) ?? [triageType];

    return triageTypeSplitByUppercase
        .map((type) => type.toLowerCase())
        .join(" ");
};
