import * as UI from "@accurx/design";
import styled from "styled-components";

import type { DrawerProps } from "./Drawer";

type DrawerPlacement = Pick<DrawerProps, "placement">;
type DrawerOpenState = Pick<DrawerProps, "isOpen">;

export const DrawerOverlay = styled.div<DrawerOpenState>`
    pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
    opacity: ${(props) => (props.isOpen ? 0.5 : 0)};
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    will-change: opacity;
    transition: opacity 0.3s ease-out;
`;

// an arbitrary distance away from the edge of the viewport that the drawer
// initially starts off at. This ensures that the toggle tab is not visible on
// first render, and is only visible after the first time the drawer has opened
const drawerOffScreenDistance = "240px";

export const DrawerContent = styled.div<
    DrawerOpenState &
        DrawerPlacement & {
            isActive: boolean;
            useFixedWidth?: boolean;
            useFullWidth?: boolean;
        }
>`
    position: fixed;
    background-color: ${UI.Tokens.COLOURS.greyscale.white};
    box-shadow: ${(props) =>
        props.isOpen ? "0px 0px 24px 4px rgba(0, 0, 0, 0.3)" : "none"};
    will-change: transform;
    transition: transform 0.6s;
    transition-timing-function: ${(props) =>
        props.isOpen ? "cubic-bezier(0.16, 1, 0.3, 1)" : ""};
    height: 100%;
    top: 0;
    bottom: 0;
    ${(props) =>
        props.placement === "right" &&
        `
        width: 100%;
        right: 0;
        transform: translateX(calc(100% + ${drawerOffScreenDistance}));
        transform: ${
            props.isOpen
                ? "translateX(0)"
                : `translateX(calc(100% + ${
                      props.isActive ? "0px" : drawerOffScreenDistance
                  }))`
        };
    `}
    ${(props) =>
        props.placement === "left" &&
        `
        width: auto;
        left: 0;
        transform: translateX(calc(-100% - ${drawerOffScreenDistance}));
        transform: ${
            props.isOpen
                ? "translateX(0)"
                : `translateX(calc(-100% - ${
                      props.isActive ? "0px" : drawerOffScreenDistance
                  }))`
        };
    `}
`;
