import React from "react";

import {
    Icon,
    Popover,
    PopoverContent,
    PopoverItemButton,
    PopoverTrigger,
    Text,
} from "@accurx/design";
import { useCommands, useCurrentSelection, useHelpers } from "@remirror/react";

import {
    trackClickAddPatientDetailButtonClick,
    trackClickAddPatientDetailMenuItemClick,
} from "app/analytics/FlemingAnalytics";

import { mentionAtomName, placeholderOptions } from "../helpers/constants";
import { RedoIcon, UndoIcon } from "../icons";
import {
    EditorButton,
    EditorPopoverTrigger,
    StyledEditorButtonGroup,
    StyledMenuSeparator,
    StyledMenuWrapper,
} from "./Menu.styles";

export const EditorMenu = () => {
    const { undo, redo, focus, createMentionAtom } = useCommands();
    const { undoDepth, redoDepth } = useHelpers();

    const handleCommandAndFocus = (command: () => void) => {
        command();
        focus();
    };

    const { $from, $to } = useCurrentSelection();
    const range = { to: $to.pos, from: $from.pos, cursor: $to.pos };
    const productOrigin = "ClinicianMessage";

    return (
        <StyledMenuWrapper>
            <StyledEditorButtonGroup>
                <EditorButton
                    type="button"
                    disabled={undoDepth() === 0}
                    onClick={() => handleCommandAndFocus(undo)}
                    title="Undo"
                >
                    <UndoIcon />
                </EditorButton>
                <EditorButton
                    type="button"
                    disabled={redoDepth() === 0}
                    onClick={() => handleCommandAndFocus(redo)}
                    title="Redo"
                >
                    <RedoIcon />
                </EditorButton>
            </StyledEditorButtonGroup>
            <StyledMenuSeparator />
            <StyledEditorButtonGroup>
                <Popover>
                    <PopoverTrigger>
                        <EditorPopoverTrigger
                            title="Add patient detail"
                            onClick={() =>
                                trackClickAddPatientDetailButtonClick({
                                    productOrigin,
                                })
                            }
                        >
                            <Icon name="Plus" size={3}></Icon>
                            <span>Add patient detail</span>
                        </EditorPopoverTrigger>
                    </PopoverTrigger>
                    <PopoverContent width="240px">
                        {placeholderOptions.map((placeholder) => (
                            <PopoverItemButton
                                key={placeholder.id}
                                onClick={() =>
                                    handleCommandAndFocus(() => {
                                        createMentionAtom(
                                            { name: mentionAtomName, range },
                                            placeholder,
                                        );
                                        trackClickAddPatientDetailMenuItemClick(
                                            {
                                                productOrigin,
                                                patientDetail:
                                                    placeholder.label,
                                            },
                                        );
                                    })
                                }
                            >
                                <Text skinny>{placeholder.label}</Text>
                            </PopoverItemButton>
                        ))}
                    </PopoverContent>
                </Popover>
            </StyledEditorButtonGroup>
        </StyledMenuWrapper>
    );
};
