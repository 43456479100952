import { PatientExternalId } from "domains/concierge/types/index";
import flatten from "lodash/flatten";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";

// this method merge passed patient external ids
// and return array of uniq pairs of external identities
export const mergeExternalIds = (
    ...patientExternalIdsArray: PatientExternalId[][]
): PatientExternalId[] => {
    const externalIds = uniqWith(flatten(patientExternalIdsArray), isEqual);

    return externalIds;
};
