/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { LoadingStatus } from "shared/LoadingStatus";
import { CoreActions } from "shared/constants/actionTypes";

import { DelayedMessage } from "./DelayedMessages.types";

export type DelayedMessagesState = {
    loadingStatus: LoadingStatus;
    messages: DelayedMessage[];
};

export const initialState: DelayedMessagesState = {
    loadingStatus: LoadingStatus.Initial,
    messages: [],
};

export const reducer = (
    state = initialState,
    action: any,
): DelayedMessagesState => {
    switch (action.type) {
        case CoreActions.LOAD_DELAYED_MESSAGES:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loading,
            };
        case CoreActions.LOAD_DELAYED_MESSAGES_FAIL:
            return {
                ...state,
                loadingStatus: LoadingStatus.Failed,
            };
        case CoreActions.LOAD_DELAYED_MESSAGES_SUCCESS:
            return {
                ...state,
                loadingStatus: LoadingStatus.Loaded,
                messages: action.messages.map((r: DelayedMessage) =>
                    createMessage(r),
                ),
            };
        default:
            return state;
    }
};

function createMessage(msg: DelayedMessage): DelayedMessage {
    return {
        messageId: msg.messageId,
        nhsNumber: msg.nhsNumber,
        senderName: msg.senderName,
        dateSent: msg.dateSent,
        status: msg.status,
    };
}
