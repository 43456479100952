import { BehaviorSubject, Observable, Unsubscribable } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import { SubscriptionResult } from "shared/concierge/types/subscription.types";

import { PatientIdentity } from "./types/PatientManager.types";
import { PatientSummary } from "./types/patient.types";

/**
 * PatientSubscription
 *
 * This class is responsible for exposing the current value of a single
 * patient. It receives a feed of all patient changes from the
 * PatientManager, checks wether the update is relevant to the
 * patient in question and emits the new patient value accordingly.
 */
class PatientSubscription {
    /**
     * The outbound feed is exposed publically and is what emits the
     * current value of the patient.
     */
    private outboundFeed: BehaviorSubject<SubscriptionResult<PatientSummary>>;

    /**
     * It's important we unsubscribe from RxJS handles when the manager
     * is torn down. We store them all here so that we can unsubscribe
     * from them later.
     */
    private readonly subscriptionHandles: Unsubscribable[] = [];

    constructor(
        private patientIdentity: PatientIdentity,
        initialValue: PatientSummary | undefined,
        patientsFeed: Observable<PatientSummary[]>,
    ) {
        this.accepts = this.accepts.bind(this);
        this.processUpdate = this.processUpdate.bind(this);

        this.outboundFeed = this.createOutboundFeed(initialValue);

        this.listenToPatientsFeed(patientsFeed);
    }

    get feed(): Observable<SubscriptionResult<PatientSummary>> {
        return this.outboundFeed;
    }

    public teardown(): void {
        this.subscriptionHandles.forEach((handle) => handle.unsubscribe());
        this.outboundFeed.unsubscribe();
    }

    private createOutboundFeed(
        initialValue: PatientSummary | undefined,
    ): BehaviorSubject<SubscriptionResult<PatientSummary>> {
        if (!initialValue) {
            return new BehaviorSubject<SubscriptionResult<PatientSummary>>({
                status: "ERROR",
                data: null,
                errorMessage: "Patient not found",
            });
        }

        return new BehaviorSubject<SubscriptionResult<PatientSummary>>({
            status: "SUCCESS",
            data: initialValue,
            errorMessage: null,
        });
    }

    private listenToPatientsFeed(
        patientsFeed: Observable<PatientSummary[]>,
    ): void {
        const handle = patientsFeed
            .pipe(
                mergeMap((patients) => patients), // split each patient in the updates list into a single event
                filter(this.accepts),
                map(this.processUpdate),
            )
            .subscribe(this.outboundFeed);

        this.subscriptionHandles.push(handle);
    }

    private processUpdate(
        patient: PatientSummary,
    ): SubscriptionResult<PatientSummary> {
        return {
            status: "SUCCESS",
            data: patient,
            errorMessage: null,
        };
    }

    /**
     * Checks that the given patient is the one we care about.
     */
    private accepts(patient: PatientSummary): boolean {
        return this.patientIdentity.id === patient.patientId;
    }
}

/**
 * Creates a new patient subscription.
 *
 * We prefer to use a factory function to create subscription instances over
 * directly using the class because it's easier to mock functions than
 * classes in tests.
 */
export const createPatientSubscription = (
    ...args: ConstructorParameters<typeof PatientSubscription>
): PatientSubscription => {
    return new PatientSubscription(...args);
};
