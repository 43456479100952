import { RefObject, useEffect, useState } from "react";

/**
 * Hook that monitors whether an element is visible on the screen.
 *
 * @param ref A ref of the element to monitor
 * @returns
 * - null if the element is mounting and its position in the layout has yet to be calculated
 * - true if the element is visible on the screen
 * - false if the element is not visible on the screen
 */
export const useIsOnScreen = (ref: RefObject<HTMLElement>): boolean | null => {
    const [isOnScreen, setIsOnScreen] = useState<boolean | null>(null);
    const [observer] = useState(() => {
        return new IntersectionObserver(([entry]) =>
            setIsOnScreen(entry.isIntersecting),
        );
    });

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => observer.disconnect();
    }, [ref, observer]);

    return isOnScreen;
};
