import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
    LoggedInCustomProperties,
    SharedRudderStackAnalyticProps,
} from "@accurx/shared";

import { TrackAppointmentReminderEvent } from "./ChainAnalyticsTracker";

//#region INTERFACES

/**
 * - numberOfSlotTypes: The number of slot types selected for the reminder.
 * - withOptionalMessageBody: If the reminder has an optional message body.
 * - postAppointmentEnabled: If reminder has post appointment enabled.
 * */
type AppointmentReminderBaseProps = {
    numberOfSlotTypes: string;
    withOptionalMessageBody: boolean;
    postAppointmentEnabled: boolean;
};

/** New event for rudderstack
 * - numberOfSlotTypes: The number of slot types selected for the reminder.
 * - withOptionalMessageBody: If the reminder has an optional message body.
 * - postAppointmentEnabled: If reminder has post appointment enabled.
 * - isWeekBeforeEnabled: If 7 days before reminder is enabled.
 * */
type AppointmentReminderLiveBaseProps = {
    countSlotType: string;
    withCustomMessageBody: boolean;
    isPostAppointmentEnabled: boolean;
    isWeekBeforeEnabled: boolean;
};

/**
 * - appointmentReminderId: The unique id for that reminder.
 * */
type ExistingAppointmentReminderBaseProps = {
    appointmentReminderId: string;
};

//#endregion INTERFACES

export type SetAppointmentReminderLiveProps = LoggedInCustomProperties &
    AppointmentReminderLiveBaseProps;

export type CreateAndAmendAppointmentReminderProps = LoggedInCustomProperties &
    AppointmentReminderBaseProps;

export type ExistingAppointmentReminderProps = LoggedInCustomProperties &
    ExistingAppointmentReminderBaseProps;

export type UpdateAppointmentReminderProps = LoggedInCustomProperties &
    AppointmentReminderBaseProps;
//#region APPOINTMENT_REMINDERS

/**
 * User clicks on "Appointment Reminders"
 *
 * Event type: Button click
 * */
export const trackAppointmentReminderClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackAppointmentReminderEvent("Clicked Appointment Reminders", props);
};

/**
 * User clicks on "Create a reminder"
 *
 * Event type: Button click
 * */
export const trackSetupAppointmentReminderClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackAppointmentReminderEvent(
        "Clicked set up a new Appointment Reminder",
        props,
    );
};

/**
 * User clicks on "Select your slot types" in the create flow
 *
 * Event type: Button click
 * */
export const trackAppointmentReminderSelectSlotTypeClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackAppointmentReminderEvent("Clicked select slot types", props);
};

/**
 * User clicks on "Cancel" in the create flow
 *
 * Event type: Button click
 * */
export const trackCancelCreateAppointmentReminderClicked = (
    props: LoggedInCustomProperties,
): void => {
    TrackAppointmentReminderEvent("Clicked cancel create reminder", props);
};

/**
 * User clicks on "Set Live" in the create flow
 *
 * Event type: Button click
 * */
export const trackAppointmentReminderCreateConfirm = (
    props: LoggedInCustomProperties & AppointmentReminderLiveBaseProps,
): void => {
    const fullProps: SharedRudderStackAnalyticProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "AppointmentReminderCreateConfirm",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: fullProps,
    });
};

/**
 * User clicks on "Set Live" in the create flow
 *
 * Event type: Button click
 * */
export const trackSetLiveAppointmentReminderClicked = (
    props: CreateAndAmendAppointmentReminderProps,
): void => {
    TrackAppointmentReminderEvent("Clicked Set live", props);
};

/**
 * User clicks "Edit" on AppointmentReminder
 *
 * Event type: Button click
 * */
export const trackEditAppointmentReminderClicked = (
    props: ExistingAppointmentReminderProps,
): void => {
    TrackAppointmentReminderEvent("Clicked edit Appointment Reminder", props);
};

/**
 * User clicks on "Cancel" in the edit flow
 *
 * Event type: Button click
 * */
export const trackCancelEditAppointmentReminderClicked = (
    props: ExistingAppointmentReminderProps,
): void => {
    TrackAppointmentReminderEvent("Clicked cancel edit reminder", props);
};

/**
 * User clicks on "Delete" in the edit flow
 *
 * Event type: Button click
 * */
export const trackDeleteAppointmentReminderClicked = (
    props: ExistingAppointmentReminderProps,
): void => {
    TrackAppointmentReminderEvent("Clicked delete reminder", props);
};

/**
 * User clicks on "Save Changes" in the update flow
 *
 * Event type: Button click
 * */
export const trackUpdateLiveAppointmentReminderClicked = (
    props: CreateAndAmendAppointmentReminderProps,
): void => {
    TrackAppointmentReminderEvent("Clicked Update live", props);
};

//#endregion APPOINTMENT_REMINDERS
