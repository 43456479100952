import * as UI from "@accurx/design";
import { Pill } from "@accurx/inbox-design-library";
import styled from "styled-components";

const StyledContainer = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})`
    background: ${UI.Tokens.COLOURS.greyscale.white};
    padding: ${UI.Tokens.SIZES[1]};

    border-radius: ${UI.Tokens.SIZES[0.5]};
    border: ${UI.Tokens.BORDERS.normal.silver};
`;

const StyledSection = styled(UI.Flex).attrs({
    flexDirection: "column",
    gap: "1",
})``;

export const StyledRow = styled(UI.Flex).attrs({
    alignItems: "center",
})`
    min-height: ${UI.Tokens.SIZES[4]};
`;

const StyledHorizontalBorder = styled.hr`
    margin: 0;
    border-color: ${UI.Tokens.COLOURS.greyscale.silver};
`;

const SquareIconButton = styled(Pill.Button)`
    padding: ${UI.Tokens.SIZES[0.5]};
`;

export const StyledActionStack = {
    Container: StyledContainer,
    Section: StyledSection,
    Row: StyledRow,
    Divider: StyledHorizontalBorder,
    SquareIconButton,
};
