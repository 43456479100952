export const CoreActions = {
    // Team Notifications actions
    LOAD_PRACTICE_TEAMS: "LOAD_PRACTICE_TEAMS",
    LOAD_PRACTICE_TEAMS_SUCCESS: "LOAD_PRACTICE_TEAMS_SUCCESS",
    LOAD_PRACTICE_TEAMS_FAIL: "LOAD_PRACTICE_TEAMS_FAIL",
    UPDATE_TEAM_MEMBERSHIP: "UPDATE_TEAM_MEMBERSHIP",
    UPDATE_TEAM_MEMBERSHIP_SUCCESS: "UPDATE_TEAM_MEMBERSHIP_SUCCESS",
    UPDATE_TEAM_MEMBERSHIP_FAIL: "UPDATE_TEAM_MEMBERSHIP_FAIL",

    // Delayed Messages
    LOAD_DELAYED_MESSAGES: "LOAD_DELAYED_MESSAGES",
    LOAD_DELAYED_MESSAGES_SUCCESS: "LOAD_DELAYED_MESSAGES_SUCCESS",
    LOAD_DELAYED_MESSAGES_FAIL: "LOAD_DELAYED_MESSAGES_FAIL",

    // Delivery receipts actions
    LOAD_DELIVERY_RECEIPTS: "LOAD_DELIVERY_RECEIPTS",
    LOAD_DELIVERY_RECEIPTS_SUCCESS: "LOAD_DELIVERY_RECEIPTS_SUCCESS",
    LOAD_DELIVERY_RECEIPTS_FAIL: "LOAD_DELIVERY_RECEIPTS_FAIL",

    // Patient Triage
    ENABLE_PATIENT_TRIAGE: "ENABLE_PATIENT_TRIAGE",
    ENABLE_PATIENT_TRIAGE_SUCCESS: "ENABLE_PATIENT_TRIAGE_SUCCESS",
    ENABLE_PATIENT_TRIAGE_FAIL: "ENABLE_PATIENT_TRIAGE_FAIL",
    LOAD_PATIENT_TRIAGE_STATUS: "LOAD_PATIENT_TRIAGE_STATUS",
    LOAD_PATIENT_TRIAGE_STATUS_SUCCESS: "LOAD_PATIENT_TRIAGE_STATUS_SUCCESS",
    LOAD_PATIENT_TRIAGE_STATUS_FAIL: "LOAD_PATIENT_TRIAGE_STATUS_FAIL",
    PATIENT_TRIAGE_SET_OUT_OF_HOURS: "PATIENT_TRIAGE_SET_OUT_OF_HOURS",
    PATIENT_TRIAGE_SET_OUT_OF_HOURS_SUCCESS:
        "PATIENT_TRIAGE_SET_OUT_OF_HOURS_SUCCESS",
    PATIENT_TRIAGE_SET_OUT_OF_HOURS_FAIL:
        "PATIENT_TRIAGE_SET_OUT_OF_HOURS_FAIL",
    UPDATE_PATIENT_TRIAGE_SETTINGS: "UPDATE_PATIENT_TRIAGE_SETTINGS",
    UPDATE_PATIENT_TRIAGE_SETTINGS_SUCCESS:
        "UPDATE_PATIENT_TRIAGE_SETTINGS_SUCCESS",
    UPDATE_PATIENT_TRIAGE_SETTINGS_FAIL: "UPDATE_PATIENT_TRIAGE_SETTINGS_FAIL",
    RESET_UPDATING_STATUS: "RESET_UPDATING_STATUS",
    LOAD_PATIENT_TRIAGE_DASHBOARD_SUCCESS:
        "LOAD_PATIENT_TRIAGE_DASHBOARD_SUCCESS",
    LOAD_PATIENT_TRIAGE_DASHBOARD: "LOAD_PATIENT_TRIAGE_DASHBOARD",
    LOAD_PATIENT_TRIAGE_DASHBOARD_FAIL: "LOAD_PATIENT_TRIAGE_DASHBOARD_FAIL",

    // Practice Users
    LOAD_PRACTICE_SETUP: "LOAD_PRACTICE_SETUP",
    LOAD_PRACTICE_SETUP_FAIL: "LOAD_PRACTICE_SETUP_FAIL",
    LOAD_PRACTICE_SETUP_SUCCESS: "LOAD_PRACTICE_SETUP_SUCCESS",

    // Vaccine accuBook
    BULK_CANCEL_INVITES: "BULK_CANCEL_INVITES",
    BULK_CANCEL_INVITES_FAILURE: "BULK_CANCEL_INVITES_FAILURE",
    BULK_CANCEL_INVITES_SUCCESS: "BULK_CANCEL_INVITES_SUCCESS",
    CANCEL_BOOKING: "CANCEL_BOOKING",
    CANCEL_BOOKING_FAILURE: "CANCEL_BOOKING_FAILURE",
    CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
    GET_VACCINE_UPLOAD_DETAILS: "GET_VACCINE_UPLOAD_DETAILS",
    GET_VACCINE_UPLOAD_DETAILS_FAILURE: "GET_VACCINE_UPLOAD_DETAILS_FAILURE",
    GET_VACCINE_UPLOAD_DETAILS_SUCCESS: "GET_VACCINE_UPLOAD_DETAILS_SUCCESS",
    GET_VACCINE_UPLOAD_DETAILS_SUCCESS_NO_BODY:
        "GET_VACCINE_UPLOAD_DETAILS_SUCCESS_NO_BODY", // set success without changing state (see reducer)
    GET_VACCINE_UPLOAD_STATUS: "GET_VACCINE_UPLOAD_STATUS",
    GET_VACCINE_UPLOAD_STATUS_FAILURE: "GET_VACCINE_UPLOAD_STATUS_FAILURE",
    GET_VACCINE_UPLOAD_STATUS_SUCCESS: "GET_VACCINE_UPLOAD_STATUS_SUCCESS",
    GET_VACCINE_ALL_PATIENTS_INVITED: "GET_VACCINE_INVITES_MASTER_LIST",
    GET_VACCINE_ALL_PATIENTS_INVITED_FAILURE:
        "GET_VACCINE_INVITES_MASTER_LIST_FAILURE",
    GET_VACCINE_ALL_PATIENTS_INVITED_SUCCESS:
        "GET_VACCINE_INVITES_MASTER_LIST_SUCCESS",
    PREPARE_NEW_VACCINE_INVITES: "PREPARE_NEW_VACCINE_INVITES",
    SEND_VACCINE_DELIVERY_MESSAGES: "SEND_VACCINE_DELIVERY_MESSAGES",
    SEND_VACCINE_DELIVERY_MESSAGES_FAILURE:
        "SEND_VACCINE_DELIVERY_MESSAGES_FAILURE",
    SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS:
        "SEND_VACCINE_DELIVERY_MESSAGES_SUCCESS",
    SEND_VACCINE_INVITES: "SEND_VACCINE_INVITES_BATCHES",
    SEND_VACCINE_INVITES_FAILURE: "SEND_VACCINE_INVITES_FAILURE",
    SEND_VACCINE_INVITES_SUCCESS: "SEND_VACCINE_INVITES_SUCCESS",
    SET_CANCEL_BOOKING_DETAILS: "SET_CANCEL_BOOKING_DETAILS",
    SET_FIRST_VACCINE_TIME_FILTER: "SET_FIRST_VACCINE_TIME_FILTER",
    SET_MY_PRACTICE_FILTER: "SET_MY_PRACTICE_FILTER",
    SET_PATIENT_INVITES_FOR_BULK_ACTION: "SET_PATIENT_INVITES_FOR_BULK_ACTION",
    SET_PATIENT_INVITES_FOR_INVITE_TO_SECOND:
        "SET_PATIENT_INVITES_FOR_INVITE_TO_SECOND",
    SET_SECOND_VACCINE_TIME_FILTER: "SET_SECOND_VACCINE_TIME_FILTER",
    SET_STATUS_FILTERS: "SET_STATUS_FILTERS",
    SET_UNCLEAR_STATUS_FILTERS: "SET_UNCLEAR_STATUS_FILTERS",
    SET_VACCINE_TYPE_FILTERS: "SET_VACCINE_TYPE_FILTERS",
    RESET_BOOKING: "RESET_BOOKING",
    RESET_BOOKING_FAILURE: "RESET_BOOKING_FAILURE",
    RESET_BOOKING_SUCCESS: "RESET_BOOKING_SUCCESS",
    RESET_BULK_CANCEL_STATUS: "RESET_BULK_CANCEL_STATUS",
    RESET_GO_TO_CANCEL_FLOW: "RESET_GO_TO_CANCEL_FLOW",
    RESET_PATIENT_INVITES_FOR_INVITE_TO_SECOND:
        "RESET_PATIENT_INVITES_FOR_INVITE_TO_SECOND",
    UPDATE_COURSE_TYPE: "UPDATE_COURSE_TYPE",
    UPDATE_MY_PRACTICE_FILTER: "UPDATE_MY_PRACTICE_FILTER",
    UPDATE_SEARCH_TERM: "UPDATE_SEARCH_TERM",
    UPLOAD_VACCINE_INVITE_LIST: "UPLOAD_VACCINE_INVITE_LIST",
    UPLOAD_VACCINE_INVITE_LIST_FAILURE: "UPLOAD_VACCINE_INVITE_LIST_FAILURE",
    UPLOAD_VACCINE_INVITE_LIST_SUCCESS: "UPLOAD_VACCINE_INVITE_LIST_SUCCESS",
};
