import {
    EventAction,
    EventObjectType,
    EventUserType,
    GenericTrackEvent,
} from "@accurx/shared";

import { FlemingLoggedInCustomProperties } from "./FlemingAnalyticsConstants";
import {
    trackOnContactDropdownOpen,
    trackOnContactDropdownOptionClick,
} from "./patientListContactDropdownEvents";

/**
 * When the contact button/dropdown is clicked.
 *
 * Event type: Button click
 * */
export const trackRecentSearchesContactButtonClicked = (
    props: FlemingLoggedInCustomProperties,
): void => {
    trackOnContactDropdownOpen({ ...props, productOrigin: "RecentSearch" });
};

type RecentSearchesContactItemClickedProps = {
    selectedItem:
        | "PatientMessage"
        | "ClinicianMessage"
        | "RecordView"
        | "VideoInvite"
        | "PatientProfile";
} & FlemingLoggedInCustomProperties;

/**
 * When the contact dropdown item is clicked.
 *
 * Event type: MenuItem click
 * */
export const trackRecentSearchesContactItemClicked = (
    props: RecentSearchesContactItemClickedProps,
): void => {
    trackOnContactDropdownOptionClick({
        ...props,
        productOrigin: "RecentSearch",
    });
};

type SearchWithinRecentSearchesProps = {
    countPatientsTotal: number;
    countPatientInFilter: number;
    searchStringLength: number;
} & FlemingLoggedInCustomProperties;

/**
 * When user types in searchbar on recent searches view and getting a response
 *
 * Event type: TextBox Input
 */
export const trackSearchWithinRecentSearches = (
    props: SearchWithinRecentSearchesProps,
) => {
    const fullProps = {
        ...props,
        eventVersion: 1,
        eventUserType: EventUserType.HealthcareProfessional,
        userIsLoggedIn: true,
    };
    GenericTrackEvent({
        object: "PatientSearchRecentFilter",
        objectType: EventObjectType.TextBox,
        action: EventAction.Input,
        properties: fullProps,
    });
};
