import { Flex, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledOuterDiv = styled(StyledFlexDiv)`
    justify-content: space-between;
    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        display: block;
    }
`;

export const StyledButtonsDiv = styled(Flex).attrs(() => ({
    alignItems: "center",
    gap: "2",
}))`
    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        margin-top: ${Tokens.SIZES[2]};
    }
`;

export const StyledSummaryDiv = styled.div`
     margin-right: ${Tokens.SIZES[1]};
     margin-left: ${Tokens.SIZES[2]};
     @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        margin-bottom: ${Tokens.SIZES[1]}
        margin-right: 0;
    }
`;

export const StyledSummaryDetailsDiv = styled.div`
    display: flex;
    @media (max-width: ${Tokens.BREAKPOINTS.sm}) {
        display: block;
    }
`;

export const StyledListNameText = styled(Text)`
    margin-bottom: 0;
    margin-top: 0;
    display: flex-block;
    align-items: center;

    :first-letter {
        text-transform: uppercase;
    }
`;

export const StyledListCountText = styled(Text)`
    margin-bottom: 0;
`;

export const StyledListDateText = styled(Text)`
    margin-bottom: 0;
    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        margin-left: ${Tokens.SIZES[4]};
    }
`;
