import React from "react";

import { Ds, Icon, Link, StackPanel, Text } from "@accurx/design";
import { SupportUrls } from "@accurx/shared";
import { i } from "images";

import { useAnalytics } from "app/analytics/hooks";
import { useCurrentUser } from "app/organisations/hooks";

import { SECTIONS } from "../../HomePage.constants";
import {
    StyledCard,
    StyledCardContent,
    StyledCardHeading,
} from "../../HomePage.styles";
import {
    StyledMarketingCardImage,
    StyledMarketingCardStackPanel,
    StyledMarketingCardSummary,
} from "../../styles/Cards.styles";

export const PatientTriageMarketingCard = (): JSX.Element => {
    const userSettings = useCurrentUser();

    const { track } = useAnalytics();

    return (
        <StyledCard cardType="marketing" data-testid="patient-triage-marketing">
            <StyledCardHeading
                as="header"
                orientation="horizontal"
                horizontalContentAlignment="space-between"
                gutter={4}
            >
                <StyledMarketingCardStackPanel
                    gutter={1.5}
                    orientation="horizontal"
                    verticalContentAlignment="center"
                    horizontalContentAlignment="left"
                >
                    <Icon
                        name="LockLocked"
                        colour="stone"
                        theme="Fill"
                        size={4}
                        props={{
                            "aria-hidden": true,
                            focusable: false,
                        }}
                    />
                    <Text as="h2" skinny variant="subtitle">
                        {SECTIONS.PATIENT_TRIAGE}
                    </Text>
                    <Ds.Badge color="yellow" radius="round">
                        {userSettings?.isApproved
                            ? "Premium feature"
                            : "Approved users only"}
                    </Ds.Badge>
                </StyledMarketingCardStackPanel>
            </StyledCardHeading>
            {userSettings?.isApproved && (
                <StyledCardContent>
                    <StackPanel
                        gutter={1.5}
                        orientation="horizontal"
                        verticalContentAlignment="center"
                        horizontalContentAlignment="space-between"
                    >
                        <StyledMarketingCardSummary>
                            <Text>
                                Give patients an easy-to-access but well managed
                                way to contact the practice.
                            </Text>
                            <Link
                                href={SupportUrls.GetStartedWithPatientTriage}
                                text="Learn about Patient Triage"
                                iconName="OpenWindow"
                                openInNewTab={true}
                                onClick={() =>
                                    track("HomepageDiscoverResourceLinkClick", {
                                        homepageSection:
                                            SECTIONS.PATIENT_TRIAGE,
                                        homepageItem:
                                            "Learn about Patient Triage",
                                    })
                                }
                            />
                        </StyledMarketingCardSummary>
                        <StyledMarketingCardImage
                            src={i(
                                "organisations/illustration-patient-triage.svg",
                            )}
                            alt="Patient Triage illstration"
                            aria-hidden="true"
                        />
                    </StackPanel>
                </StyledCardContent>
            )}
        </StyledCard>
    );
};
