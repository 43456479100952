import { useCurrentWorkspace } from "@accurx/auth";
import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "@tanstack/react-query";
import { patientRecordAttachmentsQuery } from "domains/native/api/endpoints/queryPatientRecordAttachments";
import { NativeFetchError, NativeTransportError } from "domains/native/errors";
import { useNativeTransport } from "domains/native/hooks/useNativeTransport";
import { PatientExternalId } from "domains/native/schemas/PatientExternalIdSchema";
import { PatientRecordAttachmentsDto } from "domains/native/schemas/PatientRecordAttachmentsDtoSchema";

export type UsePatientRecordAttachmentsQueryArgs = {
    patientExternalIds: PatientExternalId[];
};
export type UsePatientRecordAttachmentsQueryResult = UseQueryResult<
    PatientRecordAttachmentsDto,
    NativeFetchError
>;

export const usePatientRecordAttachmentsQuery = (
    args: UsePatientRecordAttachmentsQueryArgs,
    options?: Partial<
        UseQueryOptions<
            PatientRecordAttachmentsDto,
            NativeFetchError,
            PatientRecordAttachmentsDto,
            QueryKey
        >
    >,
): UsePatientRecordAttachmentsQueryResult => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useQuery({
        queryKey: [
            "Native:PatientRecordAttachmentsQuery",
            {
                workspaceId,
                patientExternalIds: args.patientExternalIds,
            },
        ],
        queryFn: () => {
            if (!transport) {
                throw new NativeTransportError(
                    "PatientRecordAttachmentsQuery: Called with no native transport",
                );
            }

            return patientRecordAttachmentsQuery(
                { transport, workspaceId },
                {
                    patientExternalIdentityDto: {
                        patientExternalIds: args.patientExternalIds,
                    },
                },
            );
        },
        ...options,
    });
};
