import * as UI from "@accurx/design";
import styled from "styled-components";

const { COLOURS, BORDERS } = UI.Tokens;

export const StyledTextArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StyledOuterTextArea = styled.div`
    border-radius: ${BORDERS.radius};
    border: 1px solid ${COLOURS.greyscale.white};

    // Align inner textarea: 2px padding from user agent + 1px border of this component
    margin-left: -3px;
    margin-right: -3px;

    padding: ${UI.Tokens.SIZES[0.5]};

    &:hover {
        ${UI.hoverBorderStyles}
    }
    &:focus-within {
        ${UI.focusBorderStyles}
    }
`;
