import { Dispatch } from "redux";

import ChainApiClient from "api/VaccineApiHelper";
import {
    Actions as PracticesActions,
    ExtendedActions as PracticesExtendedActions,
} from "app/practices/Practices.actions";

import {
    RecallVaccineUrlParamsBase,
    SessionSummary,
    VaccineSessionRequest,
    VaccineSessionResponse,
} from "../models/VaccineRecordingDTO";

//#region ACTION TYPES
export enum Actions {
    SET_TAB_MENU = "SET_TAB_MENU",
    GET_ALL_VACCINE_SESSIONS_STARTED = "GET_ALL_VACCINE_SESSIONS_STARTED",
    GET_ALL_VACCINE_SESSIONS_SUCCESS = "GET_ALL_VACCINE_SESSIONS_SUCCESS",
    GET_ALL_VACCINE_SESSIONS_FAILURE = "GET_ALL_VACCINE_SESSIONS_FAILURE",
    GET_VACCINE_SESSION_STARTED = "GET_VACCINE_SESSION_STARTED",
    GET_VACCINE_SESSION_SUCCESS = "GET_VACCINE_SESSION_SUCCESS",
    GET_VACCINE_SESSION_FAILURE = "GET_VACCINE_SESSION_FAILURE",
    RESET_VACCINE_SESSION = "RESET_VACCINE_SESSION",
    UPDATE_VACCINE_SESSION_ARRIVAL = "UPDATE_VACCINE_SESSION_ARRIVAL",
}

type ActionFailure = { error: string };

export type UpdateVaccineSessionArrivalActionParams = {
    hasArrived: boolean;
    recallSlotBookingId: number;
    slotStartTime?: string;
};

type DefaultActions = BaseActions<typeof Actions>;
type ExtendedActions = DecoratedActions<
    DefaultActions,
    {
        [Actions.GET_ALL_VACCINE_SESSIONS_SUCCESS]: {
            allSessions: SessionSummary[];
        };
        [Actions.GET_ALL_VACCINE_SESSIONS_FAILURE]: ActionFailure;
        [Actions.GET_VACCINE_SESSION_SUCCESS]: {
            session: VaccineSessionResponse;
        };
        [Actions.GET_VACCINE_SESSION_FAILURE]: ActionFailure;
        [Actions.UPDATE_VACCINE_SESSION_ARRIVAL]: UpdateVaccineSessionArrivalActionParams;
    }
>;

export type KnownAction =
    | ExtendedActions[keyof ExtendedActions]
    | PracticesExtendedActions[PracticesActions.SET_SELECTED_PRACTICE];

//#endregion ACTION TYPES

//#region ACTION CREATORS

export const getAllVaccineSessions =
    (params: RecallVaccineUrlParamsBase) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_ALL_VACCINE_SESSIONS_STARTED });
        const response = await ChainApiClient.getVaccineSessions(params);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.GET_ALL_VACCINE_SESSIONS_SUCCESS,
                allSessions: response.result,
            });
        }
        return dispatch({
            type: Actions.GET_ALL_VACCINE_SESSIONS_FAILURE,
            error:
                response.error ||
                "Something went wrong loading the vaccination sessions, please refresh the page",
        });
    };

export const getVaccineSession =
    (params: VaccineSessionRequest) =>
    async (dispatch: Dispatch<KnownAction>): Promise<KnownAction> => {
        dispatch({ type: Actions.GET_VACCINE_SESSION_STARTED });
        const response = await ChainApiClient.getVaccineSession(params);
        if (response.success && response.result) {
            return dispatch({
                type: Actions.GET_VACCINE_SESSION_SUCCESS,
                session: response.result,
            });
        }
        return dispatch({
            type: Actions.GET_VACCINE_SESSION_FAILURE,
            error:
                response.error ||
                "Something went wrong loading the vaccination session, please refresh the page",
        });
    };

export const resetVaccineSession = () => ({
    type: Actions.RESET_VACCINE_SESSION,
});

export const updateVaccineSessionArrival = (
    params: UpdateVaccineSessionArrivalActionParams,
) => ({ type: Actions.UPDATE_VACCINE_SESSION_ARRIVAL, ...params });

//#endregion ACTION CREATORS
