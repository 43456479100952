import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

import { useCurrentOrganisation } from "app/organisations/hooks";
import { getStringQuery } from "shared/RoutesHelper";

export const useImportToOrgPage: () => {
    returnOrg: string | null;
    shareId: string;
    orgId: string;
    orgName: string | undefined;
} = () => {
    const location = useLocation();
    const returnOrg = getStringQuery(location.search, "returnOrg");
    const { shareId, orgId } = useParams<{ shareId: string; orgId: string }>();
    const org = useCurrentOrganisation();
    const orgName = org?.organisationName;
    return {
        returnOrg,
        shareId,
        orgId,
        orgName,
    };
};
