import { UpdatePatientIdentityResponse } from "@accurx/api/ticket";
import { useCurrentWorkspace } from "@accurx/auth";
import { NativeFetchError } from "@accurx/native";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { PatientExternalIdentityDto } from "domains/inbox/schemas/PatientExternalIdentityDtoSchema";
import {
    convertEnumExternalIdsToNumberExternalIds,
    convertNumberExternalIdsToEnumExternalIds,
} from "domains/inbox/util/convertPatientExternalIds";
import isNil from "lodash/isNil";

export type UsePatientIdentityQueryResult = {
    accuRxId: string;
    externalIds: PatientExternalIdentityDto["patientExternalIds"];
};

export type UsePatientIdentityQuery = UseQueryResult<
    UsePatientIdentityQueryResult,
    NativeFetchError
>;

/**
 * Exporting the raw query function because we need to use it directly when
 * updating patient demographics.
 */
export const fetchPatientIdentity = async ({
    patientExternalIds,
    workspaceId,
}: {
    patientExternalIds: PatientExternalIdentityDto["patientExternalIds"];
    workspaceId: number;
}) => {
    if (!patientExternalIds.length) {
        throw new Error("Attempt to request patient ids for an empty patient");
    }

    const response: IWrappedResult<UpdatePatientIdentityResponse> =
        await httpClient.postJsonReturnJsonSafeAsync(
            getApiEndpoint({
                path: "/api/conversation/patient/updateidentity/",
            }),
            {
                organisationId: workspaceId,
                patientExternalIdentity: {
                    patientExternalIds:
                        convertEnumExternalIdsToNumberExternalIds(
                            patientExternalIds,
                        ),
                },
            },
        );

    if (!response.success || isNil(response.result)) {
        throw new Error("Attempt to request patient ids failed");
    }

    if (!response.result.patientId) {
        throw new Error("Patient ids response does not contain data");
    }

    const { accuRxId, patientExternalIdentity } = response.result.patientId;

    // as we receive externalIds types in a number format, we need to convert it to enums
    const externalIds = convertNumberExternalIdsToEnumExternalIds(
        patientExternalIdentity?.patientExternalIds ?? [],
    );

    return {
        accuRxId,
        externalIds,
    };
};

export const usePatientIdentityQuery = (
    patientExternalIds: PatientExternalIdentityDto["patientExternalIds"],
    enabled: boolean,
): UsePatientIdentityQuery => {
    const workspaceId = useCurrentWorkspace().orgId;

    return useQuery({
        queryKey: [
            "PatientIdentityQuery",
            {
                patientExternalIds,
            },
        ],
        queryFn: () =>
            fetchPatientIdentity({ patientExternalIds, workspaceId }),
        enabled,
        staleTime: 1000 * 60 * 5,
        // not setting this leads to queryFn to be executed when jumping directly between patients
        // (not visiting other routes between)
    });
};
