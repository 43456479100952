import { ReactNode, useState } from "react";

import { RegisterAccountProductType } from "@accurx/api/account";
import { Button, Text } from "@accurx/design";
import { getNextUrlOrDefault } from "@accurx/navigation";
import { Location } from "history";
import { i } from "images";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { ROUTES } from "shared/Routes";

import { formatSearch } from "../Account.helper";
import { StyledFullWidthButton } from "../SharedStyledComponents.styles";
import { StyledLoginFormWrapper } from "./ClinicianConversationLoginPage.styles";
import { LoginForm } from "./LoginForm";
import { InnerStackPanel, OuterStackPanel } from "./LoginStackPanel";
import { FullWidthFlex } from "./TrustsLogin/Login.styles";
import { NhsMailLoginButtonLink } from "./TrustsLogin/NhsMailLogin";

function getPracticeName(location: Location) {
    const nextUrl = getNextUrlOrDefault(location.search, "");
    const params = new URLSearchParams(nextUrl.search);
    return params.get("recipientName") ?? "the practice";
}

export const ClinicianConversationLoginPageWrapper = ({
    compensateForLayoutMargins,
    children,
}: {
    compensateForLayoutMargins?: boolean;
    children: ReactNode;
}) => (
    <OuterStackPanel
        backgroundImage={i("login-backgrounds/blurry_chat_background.svg")}
        compensateForLayoutMargins={compensateForLayoutMargins}
    >
        {children}
    </OuterStackPanel>
);

export const ClinicianConversationLoginPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showLoginForm = searchParams.get("showLoginForm") === "true";
    searchParams.set("product", `${RegisterAccountProductType.Fleming}`);
    const postLoginSearchParams = formatSearch({
        search: searchParams.toString(),
        paramsToRemove: ["product"],
    });
    const [pageStatus, setPageStatus] = useState<"sso" | "email">(
        showLoginForm ? "email" : "sso",
    );
    const onLoginSuccess = () => {
        window.location.href = `${ROUTES.postlogin}${postLoginSearchParams}`;
    };
    return (
        <ClinicianConversationLoginPageWrapper>
            <>
                <InnerStackPanel shadow horizontalContentAlignment="center">
                    {pageStatus === "sso" && (
                        <>
                            <Text variant="subtitle">
                                To reply to {getPracticeName(location)}
                            </Text>
                            <FullWidthFlex
                                flexDirection="column"
                                gap="1"
                                alignItems="center"
                            >
                                <FullWidthFlex>
                                    <NhsMailLoginButtonLink
                                        nextRouteAfterLogin={{
                                            // Always redirect to /postlogin. 'next' search param is passed along and handled.
                                            route: ROUTES.postlogin,
                                            // Encode search params, as the analytics relies on
                                            // the params being encoded in order to properly attribute sign ups.
                                            search: encodeURIComponent(
                                                postLoginSearchParams,
                                            ),
                                        }}
                                    />
                                    <Text variant="preview" skinny>
                                        Log in or sign up instantly with your
                                        nhs.net account
                                    </Text>
                                </FullWidthFlex>
                                <Text skinny variant="label">
                                    or
                                </Text>
                                <StyledFullWidthButton
                                    theme="secondary"
                                    text="Log in with email (nhs.uk)"
                                    onClick={() => setPageStatus("email")}
                                />
                            </FullWidthFlex>
                        </>
                    )}
                    {pageStatus === "email" && (
                        <FullWidthFlex gap="2">
                            <div>
                                <Button
                                    theme="secondary"
                                    icon={{
                                        name: "ArrowTail",
                                        rotation: "left",
                                    }}
                                    text="Log in options"
                                    onClick={() => setPageStatus("sso")}
                                />
                            </div>
                            <StyledLoginFormWrapper>
                                <LoginForm
                                    product={2}
                                    onLoginSuccess={onLoginSuccess}
                                />
                            </StyledLoginFormWrapper>
                        </FullWidthFlex>
                    )}
                    <Text variant="label" skinny>
                        New to Accurx?{" "}
                        <Link
                            to={`${ROUTES.register}?${searchParams.toString()}`}
                        >
                            Create an account
                        </Link>
                    </Text>
                </InnerStackPanel>
            </>
        </ClinicianConversationLoginPageWrapper>
    );
};
