import React from "react";

import { Button, Card, Text } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { DelayedMessage } from "./DelayedMessages.types";

const noContentMessage = (
    <tr>
        <td colSpan={5} align="center">
            <Text skinny>No upcoming messages</Text>
        </td>
    </tr>
);

type DelayedMessagesTableProps = {
    messages: DelayedMessage[];
    onCancelMessage: (messageId: string) => void;
};

export const DelayedMessagesTable = ({
    messages,
    onCancelMessage,
}: DelayedMessagesTableProps): JSX.Element => {
    const rows = messages.map((msg: DelayedMessage) => {
        const handleCancelMessage = () => {
            onCancelMessage(msg.messageId);
        };
        return (
            <tr key={msg.messageId}>
                <td>{msg.nhsNumber}</td>
                <td>
                    {DateHelpers.formatDate(
                        msg.dateSent,
                        DateFormatOptions.DATE_TIME_WITH_SECONDS,
                    )}
                </td>
                <td>{msg.senderName}</td>
                <td>{msg.status}</td>
                {msg.status === "Cancelled" ? (
                    <td />
                ) : (
                    <td className="py-0 align-middle">
                        <Button
                            type="button"
                            onClick={handleCancelMessage}
                            text="Cancel"
                        />
                    </td>
                )}
            </tr>
        );
    });

    return (
        <Card spacing={0}>
            <table className="table mb-0">
                <thead>
                    <tr>
                        <Text as="th" variant="label">
                            NHS number
                        </Text>
                        <Text as="th" variant="label">
                            Date sent
                        </Text>
                        <Text as="th" variant="label">
                            Sender
                        </Text>
                        <Text as="th" variant="label">
                            Status
                        </Text>
                        <Text as="th" variant="label">
                            Action
                        </Text>
                    </tr>
                </thead>
                <tbody>{rows.length === 0 ? noContentMessage : rows}</tbody>
            </table>
        </Card>
    );
};
