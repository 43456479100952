import React from "react";

import { FeatureName } from "@accurx/auth";
import { StackPanel } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";

import { useIsFeatureEnabled } from "store/hooks";

import { GpCard } from "../GpCard";
import { PreviousClinicianConversationsCard } from "../PreviousClinicianConversationsCard/PreviousClinicianConversationsCard";
import { RecordViewCard } from "../RecordViewCard";
import {
    StyledContainer,
    StyledOverviewColumnOne,
    StyledOverviewColumnTwo,
} from "./OverviewPanel.styles";

const OverviewPanel = (): JSX.Element => {
    useAccurxWebTitle("View patient profile");
    const isRecordViewFeatureEnabled = useIsFeatureEnabled(
        FeatureName.RecordAccessBeta,
    );

    return (
        <StyledContainer>
            <StyledOverviewColumnOne>
                <StackPanel gutter={2}>
                    <GpCard />
                    <PreviousClinicianConversationsCard />
                </StackPanel>
            </StyledOverviewColumnOne>
            <StyledOverviewColumnTwo>
                {isRecordViewFeatureEnabled && <RecordViewCard />}
            </StyledOverviewColumnTwo>
        </StyledContainer>
    );
};

export { OverviewPanel };
