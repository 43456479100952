import React, { MutableRefObject, ReactNode } from "react";

import { Button, Flex, FormFieldFeedback } from "@accurx/design";

import { CardWithTitle } from "../cardWithTitle/CardWithTitle";
import {
    StyledFooterActionsWrapper,
    StyledFooterGroupedActions,
} from "./SaveableCard.styles";

export type CardErrorRefType = MutableRefObject<HTMLDivElement | null>;
export type SaveableCardProps = {
    initialChildren: ReactNode;
    editChildren: ReactNode;
    previewChildren: ReactNode;
    cardTitle: string;
    additionalActions?: ReactNode /* Optional: Additional actions in the footer. Only used when needed.  */;
    handleCancelChanges: () => void;
    handleSaveChanges: () => Promise<boolean>;
    isSaving: boolean;
    handleEditChanges: () => boolean;
    viewStatus: ViewStatus;
    cardActionErrors: string[];
    cardActionErrorRef: CardErrorRefType;
    cardKey: string;
};

export enum ViewStatus {
    Initial = "Initial",
    Edit = "Edit",
    Preview = "Preview",
}

enum SaveButtonModes {
    Save = "Save",
    Saving = "Saving...",
}

export const SaveableCard = ({
    cardTitle,
    initialChildren,
    editChildren,
    previewChildren,
    additionalActions,
    handleCancelChanges,
    handleSaveChanges,
    isSaving,
    handleEditChanges,
    viewStatus,
    cardActionErrors,
    cardActionErrorRef,
    cardKey,
}: SaveableCardProps): JSX.Element => {
    const cardContent = (): ReactNode => {
        switch (viewStatus) {
            case ViewStatus.Edit:
                return editChildren;
            case ViewStatus.Preview:
                return previewChildren;
            case ViewStatus.Initial:
                return initialChildren;
        }
    };

    const saveEditCancelFooter = (): JSX.Element => {
        switch (viewStatus) {
            case ViewStatus.Initial:
                return (
                    <Flex gap={"2"} flexDirection={"column"}>
                        {cardActionErrors.length > 0 && (
                            <div ref={cardActionErrorRef} tabIndex={0}>
                                <FormFieldFeedback
                                    text={cardActionErrors[0]}
                                    variant="warning"
                                />
                            </div>
                        )}

                        <StyledFooterActionsWrapper
                            additionalActions={additionalActions}
                        >
                            {additionalActions}
                            <Button
                                text={
                                    isSaving
                                        ? SaveButtonModes.Saving
                                        : SaveButtonModes.Save
                                }
                                theme={"primary"}
                                onClick={handleSaveChanges}
                                disabled={isSaving === true}
                            />
                        </StyledFooterActionsWrapper>
                    </Flex>
                );
            case ViewStatus.Edit:
                return (
                    <Flex gap={"2"} flexDirection={"column"}>
                        {cardActionErrors.length > 0 && (
                            <div ref={cardActionErrorRef} tabIndex={0}>
                                <FormFieldFeedback
                                    text={cardActionErrors[0]}
                                    variant="error"
                                />
                            </div>
                        )}
                        <StyledFooterActionsWrapper
                            additionalActions={additionalActions}
                        >
                            {additionalActions}
                            <StyledFooterGroupedActions>
                                <Button
                                    className="mr-2"
                                    text={"Cancel"}
                                    theme={"secondary"}
                                    onClick={handleCancelChanges}
                                />
                                <Button
                                    text={
                                        isSaving
                                            ? SaveButtonModes.Saving
                                            : SaveButtonModes.Save
                                    }
                                    theme={"primary"}
                                    onClick={handleSaveChanges}
                                    disabled={isSaving === true}
                                />
                            </StyledFooterGroupedActions>
                        </StyledFooterActionsWrapper>
                    </Flex>
                );
            default:
                return (
                    <Flex gap={"2"} flexDirection={"column"}>
                        {cardActionErrors.length > 0 && (
                            <div ref={cardActionErrorRef} tabIndex={0}>
                                <FormFieldFeedback
                                    text={cardActionErrors[0]}
                                    variant="warning"
                                />
                            </div>
                        )}
                        <StyledFooterActionsWrapper
                            additionalActions={additionalActions}
                        >
                            {additionalActions}
                            <Button
                                id={`${cardKey}-edit`}
                                icon={{ id: "icon", name: "Pencil" }}
                                text={"Edit"}
                                theme={"secondary"}
                                onClick={handleEditChanges}
                            />
                        </StyledFooterActionsWrapper>
                    </Flex>
                );
        }
    };

    return (
        <CardWithTitle
            children={cardContent()}
            cardTitle={cardTitle}
            cardFooter={saveEditCancelFooter()}
        />
    );
};
