import { PatientThreadUser } from "@accurx/api/ticket";
import { UserSummary } from "domains/concierge/types";
import isNil from "lodash/isNil";

/**
 * Maps from a Ticket User to a User Summary
 * @param dto the ticket user
 * @returns mapped version to User Summary. Will return undefined if unable to map.
 */
export const mapPatientThreadUserToUserSummary = (
    dto: PatientThreadUser,
): UserSummary | undefined => {
    if (isNil(dto.accuRxId) || dto.accuRxId.length === 0) {
        return undefined;
    }
    return {
        id: dto.accuRxId,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        displayName: dto.name || dto.email || dto.accuRxId,
    };
};
