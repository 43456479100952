import {
    PatientThreadTicketCommandResult,
    TicketIdentity,
} from "@accurx/api/ticket";
import { ConciergeFetcher } from "domains/concierge/internal/types/ConciergeFetcher";

import * as TicketApiClient from "../TicketApiClient";
import { mapTicketCommandResultToConciergeUpdate } from "../mappers/mapTicketCommandResultToConciergeUpdate";

type MarkAsUrgentOptions = {
    ticketIdentity: TicketIdentity;
    latestToken: string;
    workspaceId: number;
};

export const markAsUrgent: ConciergeFetcher<
    MarkAsUrgentOptions,
    PatientThreadTicketCommandResult
> = async (arg) => {
    const response = await TicketApiClient.markTicketUrgent(
        arg.workspaceId,
        arg.ticketIdentity,
        arg.latestToken,
    );

    const updates = mapTicketCommandResultToConciergeUpdate(response);

    return { updates, response };
};
