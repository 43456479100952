export const bulletedList = (...params: string[]) => `- ${params.join("\n- ")}`;

export const placeholder = (placeholder: string) =>
    `*****${placeholder.toUpperCase()}*****`;

export const trimStart = (literal: string) =>
    literal
        .split("\n")
        .map((s) => s.trimStart())
        .join("\n");
