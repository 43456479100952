import React, { useEffect, useState } from "react";

import { Feedback, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import { toast } from "react-toastify";

import { IFileUploadRequest } from "api/FlemingDtos";
import { FileUploadRow } from "app/fileUpload/FileUploadRow";
import { useUploadSmsFileMutation } from "app/hooks/mutations";
import { MAX_FILES_SIZE_IN_MB } from "app/messageTemplates/messageTemplatesEditCreate/ManageMessageTemplateConstants";
import { FILE_UPLOAD_MAX_FILES } from "app/workspaceConversations/components/Conversation/ComposeArea/ComposeArea.constants";
import { useAppSelector } from "store/hooks";

import FileUpload from "../../fileUpload/FileUploadContainer";

export type Attachment = {
    id: string;
    file: {
        name: string;
        size: number;
        displaySize: `${string} MB`;
        previewUrl: string;
    };
};

export type FileUploadInputProps = {
    showFileUploadingMessage: boolean;
    setIsFileUploading: (nextValue: boolean) => void;
    setManuallyAttachedFileIds: (nexyValue: Array<string>) => void;
    templateAttachments: Attachment[];
};

const FileUploadInput = ({
    showFileUploadingMessage,
    setIsFileUploading,
    setManuallyAttachedFileIds,
    templateAttachments,
}: FileUploadInputProps) => {
    const [manuallyAttachedFiles, setManuallyAttachedFiles] = useState<
        Attachment[]
    >([]);
    const [filesFromTemplate, setFilesFromTemplate] =
        useState<Attachment[]>(templateAttachments);

    const totalFilesCount =
        manuallyAttachedFiles.length + filesFromTemplate.length;

    const uploadFile = useUploadSmsFileMutation({
        onSuccess: (result, variables) => {
            setManuallyAttachedFiles((previousValue) => {
                setManuallyAttachedFileIds([
                    ...previousValue.map(({ id }) => id),
                    result.id,
                ]);
                return [
                    ...previousValue,
                    {
                        id: result.id,
                        file: {
                            name: variables.file.name,
                            size: variables.file.size,
                            displaySize: `${(
                                variables.file.size /
                                (1024 * 1024)
                            ).toPrecision(2)} MB`,
                            previewUrl: window.URL.createObjectURL(
                                variables.file,
                            ),
                        },
                    },
                ];
            });
        },
        onError: (error) => {
            toast(
                <Feedback
                    colour="error"
                    title="Error during file upload, please try again"
                />,
            );
            Log.error("Error during file upload for sms attachment", {
                originalException: error,
            });
        },
    });

    useEffect(() => {
        setIsFileUploading(uploadFile.isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadFile.isLoading]);

    useEffect(() => {
        setFilesFromTemplate(templateAttachments);

        if (templateAttachments.length) {
            setManuallyAttachedFiles([]);
        }
    }, [templateAttachments]);

    const orgId = useAppSelector(({ account }) => account.selectedOrganisation);

    const handleFileUpload = (request: IFileUploadRequest): void => {
        uploadFile.mutate(request);
    };

    const removeManuallyAttachedFile = (fileId: string): void => {
        setManuallyAttachedFiles(
            manuallyAttachedFiles.filter(({ id }) => id !== fileId),
        );
    };

    const removeFileFromTemplate = (fileId: string): void => {
        setFilesFromTemplate(
            filesFromTemplate.filter(({ id }) => id !== fileId),
        );
    };

    return (
        <div className="mb-4">
            {/* Accept list, max files and max size should be kept in sync with server*/}
            {totalFilesCount < FILE_UPLOAD_MAX_FILES && (
                <>
                    <FileUpload
                        files={manuallyAttachedFiles}
                        maxFiles={FILE_UPLOAD_MAX_FILES}
                        maxSize={MAX_FILES_SIZE_IN_MB}
                        accept=".pdf, .docx, .doc, .jpeg, .jpg, .png, .tiff, .tiff2, .tif, .rtf"
                        noDrag={true}
                        organisationId={orgId}
                        onUploadFile={handleFileUpload}
                        displayUploadedFiles={false}
                        isLoading={uploadFile.isLoading}
                    />
                    <Text>
                        You can attach a file with max size{" "}
                        {MAX_FILES_SIZE_IN_MB}MB.
                    </Text>
                </>
            )}
            {filesFromTemplate.map((uploadedFile) => (
                <FileUploadRow
                    key={uploadedFile.id + "-" + uploadedFile.file.name}
                    uploadedFile={uploadedFile}
                    removeFile={removeFileFromTemplate}
                />
            ))}
            {manuallyAttachedFiles.map((uploadedFile) => (
                <FileUploadRow
                    key={uploadedFile.id + "-" + uploadedFile.file.name}
                    uploadedFile={uploadedFile}
                    removeFile={removeManuallyAttachedFile}
                />
            ))}
            {showFileUploadingMessage && (
                <Text>
                    Attachment is currently uploading, please try again in a few
                    moments...
                </Text>
            )}
        </div>
    );
};

export default FileUploadInput;
