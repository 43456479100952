/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import { useEffect, useState } from "react";

import { Card, Feedback, Flex, Spinner, Text } from "@accurx/design";
import { useParams } from "react-router";

import { trackQuestionnaireResponsesPageView } from "app/analytics/FlemingAnalytics/QuestionnaireResponsesEvents";
import { useFloreyResponseScoreSummariesQuery } from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { useQuestionnaireFilters } from "../components/filtering/hooks/useQuestionnaireFilters";
import { ResponsesDashboard } from "../components/responsesDashboard/ResponsesDashboard";
import { PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID } from "../constants";
import {
    mapFloreyScoreSummariesToDisplay,
    mapUiFiltersToApiFilters,
} from "../mappers";
import { StyledQuestionnairesSectionPageLayout } from "./PageLayout.styles";

export const QuestionnaireScoreboardPage = () => {
    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { filters, updateFilters } = useQuestionnaireFilters();
    const [questionnaireTitle, setQuestionnaireTitle] = useState("");

    const { data, status } = useFloreyResponseScoreSummariesQuery(
        {
            workspaceId,
            questionnaireId: PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
            filters: mapUiFiltersToApiFilters(filters),
        },
        {
            select: mapFloreyScoreSummariesToDisplay,
        },
    );

    const loggedInProps = useFlemingLoggedInAnalytics();

    const questionnaireResponseCount = data?.summaries.length;

    useEffect(() => {
        if (questionnaireResponseCount !== undefined) {
            trackQuestionnaireResponsesPageView({
                ...loggedInProps,
                conditionId: PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID,
                questionnaireResponseCount,
            });
        }
    }, [questionnaireResponseCount]);

    /*
     * We save the questionnaire title to the local state
     * so setting new filters doesn't hide the whole thing
     */
    useEffect(() => {
        if (data?.questionnaireName) {
            setQuestionnaireTitle(data.questionnaireName);
        }
    }, [data?.questionnaireName]);

    return (
        <StyledQuestionnairesSectionPageLayout>
            <Flex flexDirection="column" gap="2">
                <Flex gap="1.5" flexDirection="column">
                    <Text variant="subtitle" as="h1" skinny>
                        Responses from patients
                    </Text>
                    {questionnaireTitle && (
                        <Card spacing={2}>
                            <Flex gap="1" flexDirection="column">
                                <Text variant="label" as="h2" skinny>
                                    Questionnaire
                                </Text>
                                <Text skinny>{questionnaireTitle}</Text>
                            </Flex>
                        </Card>
                    )}
                </Flex>
                {status === "error" && (
                    <Feedback
                        title="Something went wrong while fetching the response summaries"
                        colour="error"
                    >
                        Please refresh the page to try again
                    </Feedback>
                )}
                {status === "loading" && <Spinner />}
                {status === "success" && (
                    <ResponsesDashboard
                        questionnaireId={
                            PERIOPERATIVE_CARE_QUESTIONNAIRE_CONDITION_ID
                        }
                        tableData={data.summaries}
                        workspaceId={workspaceId}
                        currentFilters={filters}
                        onFiltersApplied={updateFilters}
                    />
                )}
            </Flex>
        </StyledQuestionnairesSectionPageLayout>
    );
};
