import React, { useCallback } from "react";

import { Text } from "@accurx/design";
import { DateHelpers } from "@accurx/shared";
import { useLocation } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    LoadingPatientPill,
    PatientPill,
} from "app/sharedComponents/PatientPill";
import { getConversationItemSummaryTextContent } from "app/workspaceConversations/components/Conversation/ConversationThread/ConversationThread.helper";
import { ConversationDisplaySummary } from "shared/concierge/conversations/types/conversation.types";
import { usePatient } from "shared/concierge/patients/hooks/usePatient";
import { useSingleUserQuery } from "shared/concierge/usersAndTeams/hooks";
import { pascalCase } from "shared/formatters/String";
import { formatPatientDisplayName } from "shared/formatters/formatPatientDisplayName";
import { useCurrentUserId } from "store/hooks";

import { StyledConversationSummaryLayout } from "../ConversationGroupLayout.styles";
import { StyledBadge } from "./ConversationListItem.styles";
import { ItemIndicatorPill } from "./ItemIndicatorPill";
import { itemIndicatorData } from "./ItemIndicatorPill/ItemIndicatorPill";

export type ConversationListItemAnalyticsClickCallback = (
    props: Pick<
        FlemingAnalyticsTracker.PatientConversationListItemClickProps,
        "conversationId" | "isConversationUnread" | "conversationDisplayStatus"
    >,
) => void;

type ConversationListItemProps = {
    conversation: ConversationDisplaySummary;

    /**
     * As with ConversationGroupPage - it is not yet clear splitting out dedicated display components
     * for conversation summary vs item views are necessary.
     * */
    showDisplayItemDate: boolean;
    showLastItemIndicator: boolean;
    conversationListItemAnalytics: ConversationListItemAnalyticsClickCallback;
};

export const ConversationListItem = ({
    conversation,
    showDisplayItemDate,
    showLastItemIndicator,
    conversationListItemAnalytics,
}: ConversationListItemProps): JSX.Element => {
    const analyticsCurrentUserId = useCurrentUserId();

    const location = useLocation();

    const patientQuery = usePatient(conversation.regardingPatientId);
    const patientDisplayName =
        conversation.displayItem.createdBy.type === "Patient" &&
        patientQuery.status === "SUCCESS" &&
        patientQuery.data
            ? formatPatientDisplayName(patientQuery.data)
            : undefined;

    const userId =
        conversation.displayItem.createdBy.type === "User"
            ? conversation.displayItem.createdBy.id
            : undefined;
    const userQuery = useSingleUserQuery(userId);
    const userDisplayName =
        conversation.displayItem.createdBy.type === "User" &&
        userQuery.status === "success" &&
        userQuery.data
            ? userQuery.data.displayName
            : undefined;

    const hasUnread = conversation.unreadCount > 0;

    const handleConversationListItemAnalytics = useCallback(() => {
        const itemIndicatorLabel =
            itemIndicatorData(conversation.displayItem, analyticsCurrentUserId)
                ?.label || "";
        const conversationDisplayStatus = pascalCase(itemIndicatorLabel);

        conversationListItemAnalytics({
            conversationId: conversation.id,
            isConversationUnread: hasUnread,
            conversationDisplayStatus,
        });
    }, [
        conversationListItemAnalytics,
        conversation.id,
        hasUnread,
        conversation.displayItem,
        analyticsCurrentUserId,
    ]);

    return (
        <StyledConversationSummaryLayout.Link
            to={`${location.pathname}/conversation/${conversation.id}`}
            onClick={handleConversationListItemAnalytics}
        >
            <StyledConversationSummaryLayout.UnreadIndicator>
                {hasUnread && (
                    <StyledBadge aria-label="conversation has unread items" />
                )}
            </StyledConversationSummaryLayout.UnreadIndicator>
            <StyledConversationSummaryLayout.Patient>
                {patientQuery.status === "SUCCESS" && (
                    <PatientPill patient={patientQuery.data} />
                )}
                {/*If we couldn't find a patient (either they are unmatched or there was another failure) display "unknown patient" pill */}
                {patientQuery.status === "ERROR" && <PatientPill />}
                {patientQuery.status === "LOADING" && <LoadingPatientPill />}
            </StyledConversationSummaryLayout.Patient>
            <StyledConversationSummaryLayout.ItemIndicator>
                {showLastItemIndicator && conversation.displayItem && (
                    <ItemIndicatorPill
                        conversationItem={conversation.displayItem}
                        hasUnread={hasUnread}
                    />
                )}
            </StyledConversationSummaryLayout.ItemIndicator>
            <StyledConversationSummaryLayout.Summary>
                <Text variant={hasUnread ? "label" : "body"} as="span">
                    {/* Only one or none of user or patient display name will be populated */}
                    {userDisplayName ? userDisplayName + ": " : null}
                    {patientDisplayName ? patientDisplayName + ": " : null}
                    {getConversationItemSummaryTextContent(
                        conversation.displayItem,
                    )}
                </Text>
            </StyledConversationSummaryLayout.Summary>
            <StyledConversationSummaryLayout.Date>
                <Text variant={hasUnread ? "label" : "body"} as="span">
                    {DateHelpers.getLocalisedDateTimeShort(
                        showDisplayItemDate
                            ? conversation.displayItem.createdAt
                            : conversation.lastUpdated,
                    )}
                </Text>
            </StyledConversationSummaryLayout.Date>
        </StyledConversationSummaryLayout.Link>
    );
};
