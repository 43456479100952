import {
    FloreyListGroup,
    FloreyOwner,
    FloreyTemplate,
} from "app/batchMessage/gp/BatchMessage.types";

import {
    FloreyDropdownByGroupHeader,
    FloreyDropdownTemplate,
} from "../../../components/compose/BatchMessageCompose.types";

export const mapFloreysForDropdown = (
    floreyListGroups: FloreyListGroup[],
    templateOwner: FloreyOwner,
): FloreyDropdownByGroupHeader => {
    const list: Array<FloreyDropdownTemplate> = [];

    // Using floreyTemplate.type to determine category the template
    // 6 (FloreyProductType.OrganisationCustom), maps to FloreyOwner.Org
    // Any other types maps to FloreyOwner.Accurx
    const mapFloreyTemplate = (
        floreyTemplate: FloreyTemplate,
        path: string,
    ): FloreyDropdownTemplate => {
        return {
            ...floreyTemplate,
            path: path,
            source:
                floreyTemplate.type === 6 //FloreyProductType.OrganisationCustom
                    ? FloreyOwner.Org
                    : FloreyOwner.Accurx,
        };
    };

    floreyListGroups.forEach((group) => {
        group.messageTemplates.forEach((template) =>
            list.push(mapFloreyTemplate(template, group.heading)),
        );
    });
    const filteredList = list.filter((x) => x.source === templateOwner);
    const floreyDropdownByHeader: FloreyDropdownByGroupHeader = {};
    filteredList.forEach((template) => {
        if (floreyDropdownByHeader[template.path]) {
            floreyDropdownByHeader[template.path] = [
                ...floreyDropdownByHeader[template.path],
                template,
            ];
        } else {
            floreyDropdownByHeader[template.path] = [template];
        }
    });
    return floreyDropdownByHeader;
};
