import { useAnalytics } from "@accurx/analytics";
import { useNativeTrackingFields } from "@accurx/native";
import { useCompose } from "domains/compose/context";
import { Attachment } from "domains/compose/reducer.types";

import { AttachmentInProgressInfoBox } from "./AttachFeedback/AttachmentInProgressInfoBox";
import { AttachmentInfoBox } from "./AttachmentInfoBox";

export const AttachmentDisplay = ({
    attachment,
}: {
    attachment: Attachment;
}) => {
    const { dispatch } = useCompose();
    const track = useAnalytics();
    const nativeTrackingFields = useNativeTrackingFields();
    const { state } = useCompose();

    switch (attachment.origin) {
        case "TempUpload":
            return <AttachmentInProgressInfoBox names={[attachment.name]} />;
        case "Upload":
        case "Template":
            return (
                <AttachmentInfoBox
                    name={attachment.name}
                    previewUrl={attachment.previewUrl}
                    onAttachmentRemove={() => {
                        track("AttachmentRemove Button Click", {
                            ...nativeTrackingFields,
                            conversationParticipant:
                                state.conversationParticipant,
                            eventVersion: 2,
                        });

                        dispatch({
                            type: "REMOVE_ATTACHMENT",
                            payload: {
                                attachment,
                            },
                        });
                    }}
                />
            );

        default: {
            // Make sure build breaks if we don't render one of the attachments origins
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustive: never = attachment.origin;
            return null;
        }
    }
};
