import React from "react";

import { Lines } from "./Lines";
import {
    OrgUserApprovalStatusLabel,
    OrgUserRoleLabel,
    UserType,
} from "./PracticeUsers.types";
import { UserRowActions } from "./UserRowActions";

type UserRowProps = {
    user: UserType;
};

export const UserRow = ({ user }: UserRowProps): JSX.Element => {
    return (
        <tr>
            <td>
                <Lines>
                    {user.name}
                    {user.email}
                    {user.confirmedEmail === false && "( unverified )"}
                </Lines>
            </td>
            <td style={{ width: "300px" }}>
                {OrgUserRoleLabel(user.userRoleState?.currentValue)}
            </td>
            <td style={{ width: "300px" }}>
                {OrgUserApprovalStatusLabel(user.userStatusState?.currentValue)}
            </td>
            <td style={{ width: "50px" }} className="text-right">
                <UserRowActions user={user} />
            </td>
        </tr>
    );
};
