import { Log } from "@accurx/shared";
import { AnyAction } from "@reduxjs/toolkit";
import { ConciergeState } from "domains/concierge/internal/types/ConciergeState";
import { ThunkMiddleware } from "redux-thunk";

import { performance } from "../util/perf";

export const timings: ThunkMiddleware<ConciergeState, AnyAction> =
    (store) => (next) => (action) => {
        if (performance.mark === undefined) return next(action);
        performance.mark(`concierge-reducer-start-${action.type}`);
        const result = next(action);
        performance.measure(
            `concierge-reducer-success-${action.type}`,
            `concierge-reducer-start-${action.type}`,
        );

        // working round a firefox < 95 inconsistency with
        // how performance.measure works: in other browsers, it returns the
        // measure, in firefox < 95, it returns undefined.
        // based on this StackOverflow answer: https://stackoverflow.com/questions/69666408/how-to-use-performance-measure-in-firefox
        // I've added a fallback to get the measure from `performance.getEntriesByName`.
        // Am also doing what _should be_ an unnecessary null-check below just in case.
        const measures = performance.getEntriesByName(
            `concierge-reducer-success-${action.type}`,
        );
        const measure = measures[measures.length - 1];

        if (measure) {
            const conversationsCount =
                store.getState().conversations?.total ?? 0;

            Log.debug(`Concierge reducer duration: ${action.type}`, {
                tags: {
                    product: "Inbox",
                    action: action.type,
                    durationMillis: measure.duration,
                    conversationsCount,
                },
            });
        } else {
            // if we do still end up with undefined measures, we want to
            // know about it so we can investigate
            Log.error("performance.measure was undefined", {
                tags: { product: "Inbox" },
            });
        }

        return result;
    };
