import { FragmentUsageSummary } from "@accurx/api/patient-messaging";
import {
    Card,
    Grid,
    Link,
    Spinner,
    Table,
    Text,
    useTable,
} from "@accurx/design";

type SummaryTableProps = {
    data: FragmentUsageSummary[];
    isLoading: boolean;
};

export const SummaryTable = ({ data, isLoading }: SummaryTableProps) => {
    const summaryTable = useTable({
        data: data,
        columnDefs: [
            {
                header: "Product",
                id: "product",
            },
            {
                header: "SMS Sent",
                id: "sms-sent",
            },
            {
                header: "Fragments",
                id: "fragments",
            },
        ],
    });
    return (
        <Card
            header={
                <Text
                    variant="subtitle"
                    skinny
                    props={{ id: "heading-table-1" }}
                    as="h2"
                >
                    Fragments summary
                </Text>
            }
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <Grid gap="2">
                    <Link
                        href="https://support.accurx.com/en/articles/8354858-accurx-desktop-usage-dashboard-for-fragments#h_a1810d1100"
                        openInNewTab
                    >
                        Click here for a refresher on what the Accurx products
                        listed below do
                        <Link.Icon />
                    </Link>
                    <Table aria-describedby="heading-table-1">
                        <Table.ColumnGroup>
                            <Table.Column width="40%" />
                            <Table.Column width="30%" />
                            <Table.Column width="30%" />
                        </Table.ColumnGroup>
                        <Table.Head>
                            <Table.Row>
                                {summaryTable.columns.map((column) => (
                                    <Table.Header
                                        scope="col"
                                        colSpan={1}
                                        key={column.id}
                                    >
                                        {column.header}
                                    </Table.Header>
                                ))}
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {summaryTable.rows.map((row) => (
                                <Table.Row key={`${row.product}`}>
                                    <Table.Header scope="row" colSpan={1}>
                                        {row.product}
                                    </Table.Header>
                                    <Table.DataCell colSpan={1}>
                                        {row.numberOfMessages}
                                    </Table.DataCell>
                                    <Table.DataCell colSpan={1}>
                                        {row.numberOfFragments}
                                    </Table.DataCell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Grid>
            )}
        </Card>
    );
};
