import { UserGroupCategory } from "@accurx/api/florey-builder";
import {
    BatchMessageDetails,
    BatchMessageStatusResponse,
} from "@accurx/api/patient-messaging";
import { Option } from "@accurx/design";
import orderBy from "lodash/orderBy";

import { Actions as PracticesAction } from "app/practices/Practices.actions";
import {
    LoadingStatus,
    LoadingStatusType,
    UpdatingStatus,
    UpdatingStatusType,
} from "shared/LoadingStatus";

import { Actions, KnownAction } from "./BatchMessage.actions";
import {
    BatchDeclineSettings,
    BatchMessageCollection,
    BatchMessageModalDetails,
    BatchMessageSummary,
    BatchSlotType,
    BatchSnomedCode,
    BatchType,
    BeastSeriesDto,
    FloreyOwner,
    FloreyTemplate,
    FloreysList,
    ListBeastSeriesDto,
    MessageTemplate,
    MessageTemplateOwner,
    SendAt,
    statusLabel,
} from "./BatchMessage.types";
import {
    UPDATE_BATCH_DECLINE_SETTINGS,
    UpdateBatchDeclineSettingsAction,
} from "./ComposeDeclineSettings.actions";

export type BatchMessageReducerState = {
    // Local state
    //Used LoadingStatus here because on the types page, it says that UpdatingStatus is deprecated
    cancelBatchMessageStatus: LoadingStatusType;
    csvUploadStatus: LoadingStatusType;
    uploadDetailsStatus: LoadingStatusType;
    sendingStatus: LoadingStatusType;
    gettingBatchModalDetailsStatus: LoadingStatusType;
    batchUploadStatus: LoadingStatusType;
    gettingSnomedCodesStatus: UpdatingStatusType;
    gettingFloreySnomedCodesStatus: UpdatingStatusType;
    gettingUserGroupsStatus: UpdatingStatusType;
    gettingFloreysListStatus: UpdatingStatusType;
    gettingAccurxTemplatesStatus: UpdatingStatusType;
    gettingOrgTemplatesStatus: UpdatingStatusType;
    gettingUserTemplatesStatus: UpdatingStatusType;
    gettingSlotTypesStatus: LoadingStatusType;
    fileRemovingStatus: UpdatingStatusType;
    fileUploadingStatus: UpdatingStatusType;
    patientMessage: string;
    batchType: BatchType;
    selectedFloreyId: string;
    selectedFloreyName: string;
    selectedFloreyOwner: FloreyOwner | null;
    selectedFloreySnomedCode: string;
    selectedFloreySaveToRecord: boolean;
    selectedBatchOptionSaveToRecord: boolean;
    selectedTemplateName: string;
    selectedTemplateOwner: MessageTemplateOwner;
    selectedTemplateSnomedCode: string;
    selectedTemplateCanReply: boolean;
    selectedTemplateId: number;
    selectedSlotType: BatchSlotType;
    selectedAssigneeUserGroupId: string;
    selectedAssigneeUserGroupName: string;
    selectedFloreyDefaultAssigneeId: string;
    isFileAttachmentFromTemplate: boolean;
    // Server responses
    batchMessageId: string;
    batchMessageStatus: string;
    batchMessageProgressPercentage: BatchMessageStatusResponse["progressPercentage"];
    batchMessageCollection: BatchMessageCollection;
    batchMessageDetails: BatchMessageDetails;
    batchMessageSummary: BatchMessageSummary;
    batchMessageModalDetails: BatchMessageModalDetails;
    snomedCodes: Array<BatchSnomedCode>;
    floreySnomedCodes: Array<BatchSnomedCode>;
    userGroups: Array<Option>;
    slotTypes: BatchSlotType[];
    floreysList: FloreysList;
    accurxSelfBookTemplatesList: MessageTemplate[];
    accurxTemplatesList: MessageTemplate[];
    orgTemplatesList: MessageTemplate[];
    userTemplatesList: MessageTemplate[];
    uploadedFileAttachmentId: string;
    uploadedFileLink: string;
    uploadedFileName: string;
    userId: string;
    confirmMessageSuccess: boolean;
    // behaviour change responses
    fetchingBeastSeriesStatus: UpdatingStatusType;
    beastSeries: ListBeastSeriesDto | null;
    selectedBeastSeries: BeastSeriesDto | null;
    sendAt: SendAt | null;
    staggerOverDays: number | null;
    scheduleSendFormDataDefaults: {
        date: string;
        time: Option;
    } | null;
    isBatchScheduled: boolean | null;
    gettingBehaviourChangeFloreyStatus: UpdatingStatusType;
    behaviourChangeSignUpFlorey: FloreyTemplate;
    weeksAvailable: number;
    declineSettings: BatchDeclineSettings;
};

const emptyBatchMessageSummary: BatchMessageSummary = {
    id: "",
    name: "",
    batchType: "",
    bookedCount: 0,
    isCancellable: false,
    sendAt: "",
    sender: {
        id: "",
        email: "",
        fullName: "",
    },
    messageTemplate: "",
    batchStatus: statusLabel.Cancelled,
    status: "",
    sendTime: "",
    totalItems: 0,
    totalNonTextable: 0,
    totalSent: 0,
    totalDelivered: 0,
    totalFailed: 0,
    totalWriteSuccess: 0,
    totalWriteFailure: 0,
    type: "",
};

const emptyBatchMessageModalDetails: BatchMessageModalDetails = {
    id: "",
    batchStatus: statusLabel.Cancelled,
    batchType: "",
    bookedCount: 0,
    sender: {
        id: "",
        email: "",
        fullName: "",
    },
    messageTemplate: "",
    savingStatus: statusLabel.Cancelled,
    sendAt: "",
    totalPatients: 0,
    nonTextablePatients: 0,
    failedToSave: 0,
    failedToDeliver: 0,
    delivered: 0,
    staggerOverDays: 0,
    createdTime: "",
    type: "",
    isFloreyCsvDownloadSupported: false,
    slotType: "",
    declineSettings: null,
    saveMessageToRecord: true,
    totalItems: 0,
    totalProcessed: 0,
    totalMessages: 0,
};
const emptyFloreyList: FloreysList = {
    groups: [],
};

const snomedSort = (a: BatchSnomedCode, b: BatchSnomedCode) =>
    a.term > b.term ? 1 : -1;

type UserGroupRequiredFields = {
    displayName: string;
    id: number;
    category: UserGroupCategory;
};

const messageTemplateSort = (a: MessageTemplate, b: MessageTemplate) => {
    if (a.title > b.title) {
        return 1;
    }
    if (a.title < b.title) {
        return -1;
    }
    return 0;
};

export const initialState: BatchMessageReducerState = {
    cancelBatchMessageStatus: LoadingStatus.Initial,
    fileRemovingStatus: UpdatingStatus.Initial,
    fileUploadingStatus: UpdatingStatus.Initial,
    uploadedFileAttachmentId: "",
    uploadedFileLink: "",
    uploadedFileName: "",
    csvUploadStatus: LoadingStatus.Initial,
    uploadDetailsStatus: LoadingStatus.Initial,
    sendingStatus: LoadingStatus.Initial,
    batchUploadStatus: LoadingStatus.Initial,
    gettingSnomedCodesStatus: UpdatingStatus.Initial,
    gettingFloreySnomedCodesStatus: UpdatingStatus.Initial,
    gettingUserGroupsStatus: UpdatingStatus.Initial,
    gettingFloreysListStatus: UpdatingStatus.Initial,
    gettingAccurxTemplatesStatus: UpdatingStatus.Initial,
    gettingBatchModalDetailsStatus: LoadingStatus.Initial,
    gettingOrgTemplatesStatus: UpdatingStatus.Initial,
    gettingUserTemplatesStatus: UpdatingStatus.Initial,
    gettingSlotTypesStatus: LoadingStatus.Initial,
    patientMessage: "",
    batchMessageId: "",
    batchMessageStatus: "",
    batchMessageProgressPercentage: 0,
    batchMessageCollection: {
        summaries: [],
    },
    batchMessageDetails: {
        ...emptyBatchMessageSummary,
        maximumPatientGreetingLength: 0,
        items: [],
    },
    batchMessageModalDetails: emptyBatchMessageModalDetails,
    batchMessageSummary: emptyBatchMessageSummary,
    snomedCodes: [],
    floreySnomedCodes: [],
    userGroups: [],
    slotTypes: [],
    floreysList: emptyFloreyList,
    accurxTemplatesList: [],
    accurxSelfBookTemplatesList: [],
    orgTemplatesList: [],
    userTemplatesList: [],
    batchType: BatchType.NONE,
    selectedFloreyId: "",
    selectedFloreyName: "",
    selectedFloreyOwner: null,
    selectedFloreySnomedCode: "",
    selectedFloreySaveToRecord: false,
    selectedBatchOptionSaveToRecord: true,
    selectedTemplateSnomedCode: "",
    selectedTemplateName: "",
    selectedTemplateOwner: "",
    selectedTemplateId: 0,
    isFileAttachmentFromTemplate: false,
    selectedTemplateCanReply: false,
    selectedSlotType: {
        type: "",
        availability: 0,
    },
    selectedAssigneeUserGroupId: "",
    selectedAssigneeUserGroupName: "",
    selectedFloreyDefaultAssigneeId: "",
    userId: "",
    confirmMessageSuccess: false,
    fetchingBeastSeriesStatus: UpdatingStatus.Initial,
    beastSeries: null,
    selectedBeastSeries: null,
    sendAt: null,
    staggerOverDays: null,
    scheduleSendFormDataDefaults: null,
    isBatchScheduled: false,
    gettingBehaviourChangeFloreyStatus: UpdatingStatus.Initial,
    behaviourChangeSignUpFlorey: {
        title: "",
        id: "",
        medicalCodeIdentity: {
            debugDisplay: "",
            code: "",
            codeScheme: 0,
        },
        templateBody: "",
        canSchedule: false,
        availability: 0,
        type: 0,
        availableInBatchForFree: false,
        canAutoSaveToRecord: false,
        defaultUserGroupId: "",
    },
    weeksAvailable: 6,
    declineSettings: {
        confirmationText: "",
        snomedConceptId: "",
    },
};

type ValidActions = KnownAction | UpdateBatchDeclineSettingsAction;
export const reducer = (
    state = initialState,
    action: ValidActions,
): BatchMessageReducerState => {
    switch (action.type) {
        case Actions.CANCEL_BATCH_MESSAGE:
            return {
                ...state,
                cancelBatchMessageStatus: LoadingStatus.Loading,
            };
        case Actions.CANCEL_BATCH_MESSAGE_SUCCESS:
            return {
                ...state,
                cancelBatchMessageStatus: LoadingStatus.Loaded,
            };
        case Actions.CANCEL_BATCH_MESSAGE_FAILURE:
            return {
                ...state,
                cancelBatchMessageStatus: LoadingStatus.Failed,
            };
        case Actions.REMOVE_BATCH_UPLOAD_PATIENT_LIST:
            return {
                ...state,
                csvUploadStatus: LoadingStatus.Initial,
                batchUploadStatus: LoadingStatus.Initial,
                uploadDetailsStatus: LoadingStatus.Initial,
                batchMessageStatus: "",
                batchMessageId: "",
            };
        case Actions.UPLOAD_BATCH_PATIENT_LIST:
            return {
                ...state,
                csvUploadStatus: LoadingStatus.Loading,
                batchUploadStatus: LoadingStatus.Initial,
            };
        case Actions.UPLOAD_BATCH_PATIENT_LIST_SUCCESS:
            return {
                ...state,
                csvUploadStatus: LoadingStatus.Loaded,
                batchMessageId: action.response?.id || "",
            };
        case Actions.GET_BATCH_UPLOAD_STATUS:
            return {
                ...state,
                batchUploadStatus: LoadingStatus.Loading,
            };
        case Actions.GET_BATCH_UPLOAD_STATUS_FAILURE:
            return {
                ...state,
                batchUploadStatus: LoadingStatus.Failed,
            };
        case Actions.GET_BATCH_UPLOAD_STATUS_SUCCESS:
            return {
                ...state,
                batchMessageStatus: action.response.status,
                batchMessageProgressPercentage:
                    action.response.progressPercentage,
                batchUploadStatus: LoadingStatus.Loaded,
            };
        case Actions.GET_BATCH_UPLOAD_DETAILS:
            return {
                ...state,
                uploadDetailsStatus: LoadingStatus.Loading,
            };
        case Actions.GET_BATCH_UPLOAD_DETAILS_FAILURE:
            return {
                ...state,
                uploadDetailsStatus: LoadingStatus.Failed,
            };
        case Actions.GET_BATCH_UPLOAD_DETAILS_SUCCESS:
            return {
                ...state,
                uploadDetailsStatus: LoadingStatus.Loaded,
                batchMessageDetails: action.response,
                userId: action.response.sender.id,
            };
        case Actions.UPDATE_BATCH_MESSAGE_BODY:
            return {
                ...state,
                patientMessage: action.patientMessage,
            };
        case Actions.UPDATE_BATCH_TYPE:
            return {
                ...state,
                batchType: action.batchType,
            };
        case UPDATE_BATCH_DECLINE_SETTINGS:
            return {
                ...state,
                declineSettings: {
                    confirmationText: action.payload.confirmationText,
                    snomedConceptId: action.payload.snomedConceptId,
                },
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_ID:
            return {
                ...state,
                selectedFloreyId: action.floreyId,
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_NAME:
            return {
                ...state,
                selectedFloreyName: action.floreyName,
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_OWNER:
            return {
                ...state,
                selectedFloreyOwner: action.floreyOwner,
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_SNOMED:
            return {
                ...state,
                selectedFloreySnomedCode: action.snomedCode,
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_SAVE_TO_RECORD:
            return {
                ...state,
                selectedFloreySaveToRecord: action.canSaveToRecord,
            };
        case Actions.UPDATE_BATCH_SELECTED_OPTION_SAVE_TO_RECORD:
            return {
                ...state,
                selectedBatchOptionSaveToRecord: action.shouldSaveToRecord,
            };
        case Actions.UPDATE_BATCH_SELECTED_TEMPLATE_SNOMED:
            return {
                ...state,
                selectedTemplateSnomedCode: action.snomedCode,
            };
        case Actions.UPDATE_BATCH_SELECTED_TEMPLATE_NAME:
            return {
                ...state,
                selectedTemplateName: action.templateName,
            };
        case Actions.UPDATE_BATCH_SELECTED_TEMPLATE_OWNER:
            return {
                ...state,
                selectedTemplateOwner: action.templateOwner,
            };
        case Actions.UPDATE_BATCH_SELECTED_TEMPLATE_ID:
            return {
                ...state,
                selectedTemplateId: action.selectedTemplateId,
            };
        case Actions.UPDATE_BATCH_SELECTED_SELFBOOK_SLOT:
            return {
                ...state,
                selectedSlotType: action.slot,
            };
        case Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_ID:
            return {
                ...state,
                selectedAssigneeUserGroupId: action.assigneeUserGroupId,
            };
        case Actions.UPDATE_BATCH_SELECTED_ASSIGNEE_USER_GROUP_NAME:
            return {
                ...state,
                selectedAssigneeUserGroupName: action.assigneeUserGroupName,
            };
        case Actions.UPDATE_BATCH_SELECTED_FLOREY_DEFAULT_ASSIGNEE_ID:
            return {
                ...state,
                selectedFloreyDefaultAssigneeId:
                    action.selectedFloreyDefaultAssigneeId,
            };
        case Actions.SEND_BATCH_MESSAGE:
            return {
                ...state,
                sendingStatus: LoadingStatus.Loading,
            };
        case Actions.SEND_BATCH_MESSAGE_FAILURE:
            return {
                ...state,
                sendingStatus: LoadingStatus.Failed,
            };
        case Actions.SEND_BATCH_MESSAGE_SUCCESS:
            return {
                ...state,
                confirmMessageSuccess: true,
                sendingStatus: LoadingStatus.Loaded,
            };
        case Actions.FETCH_BATCH_FLOREY_LIST:
            return {
                ...state,
                gettingFloreysListStatus: UpdatingStatus.Loading,
            };
        case Actions.UPDATE_SEND_DATE_TIME:
            return {
                ...state,
                sendAt:
                    action.sendDateTime === null
                        ? null
                        : {
                              ...state.sendAt,
                              ...action.sendDateTime.sendAt,
                          },
                scheduleSendFormDataDefaults:
                    action.sendDateTime === null
                        ? null
                        : {
                              date: action.sendDateTime.formattedDate,
                              time: action.sendDateTime.formattedTime,
                          },
            };
        case Actions.UPDATE_STAGGER_OVER_DAYS:
            return {
                ...state,
                staggerOverDays: action.staggerOverDays,
            };
        case Actions.FETCH_BATCH_FLOREY_LIST_FAILURE:
            return {
                ...state,
                gettingFloreysListStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_BATCH_FLOREY_LIST_SUCCESS:
            return {
                ...state,
                floreysList: action.response,
                gettingFloreysListStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS:
            return {
                ...state,
                gettingBatchModalDetailsStatus: LoadingStatus.Loading,
            };
        case Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_FAILURE:
            return {
                ...state,
                gettingBatchModalDetailsStatus: LoadingStatus.Failed,
            };
        case Actions.FETCH_BATCH_MESSAGE_MODAL_DETAILS_SUCCESS:
            return {
                ...state,
                batchMessageModalDetails: action.response,
                gettingBatchModalDetailsStatus: LoadingStatus.Loaded,
            };
        case Actions.FETCH_ACCURX_MESSAGE_TEMPLATES:
            return {
                ...state,
                gettingAccurxTemplatesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_FAILURE:
            return {
                ...state,
                gettingAccurxTemplatesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_ACCURX_MESSAGE_TEMPLATES_SUCCESS:
            //Sorting template to put selfbook templates on top of the rest
            const accurxTemplatesWithoutSelfbook = action.response.filter(
                (template) => template.path.folders[0].name !== "Self-Book ",
            );
            const accurxTemplatesWithOnlySelfbook = action.response.filter(
                (template) => template.path.folders[0].name === "Self-Book ",
            );
            return {
                ...state,
                accurxTemplatesList: action.response.sort(messageTemplateSort),
                accurxSelfBookTemplatesList: [
                    ...accurxTemplatesWithOnlySelfbook,
                    ...accurxTemplatesWithoutSelfbook,
                ],
                gettingAccurxTemplatesStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_ORG_MESSAGE_TEMPLATES:
            return {
                ...state,
                gettingOrgTemplatesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_ORG_MESSAGE_TEMPLATES_FAILURE:
            return {
                ...state,
                gettingOrgTemplatesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_ORG_MESSAGE_TEMPLATES_SUCCESS:
            return {
                ...state,
                orgTemplatesList: action.response.sort(messageTemplateSort),
                gettingOrgTemplatesStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_USER_MESSAGE_TEMPLATES:
            return {
                ...state,
                gettingUserTemplatesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_USER_MESSAGE_TEMPLATES_FAILURE:
            return {
                ...state,
                gettingUserTemplatesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_USER_MESSAGE_TEMPLATES_SUCCESS:
            return {
                ...state,
                userTemplatesList: action.response.sort(messageTemplateSort),
                gettingUserTemplatesStatus: UpdatingStatus.Loaded,
            };
        case Actions.GET_BATCH_SLOT_TYPES_LOADING:
            return {
                ...state,
                gettingSlotTypesStatus: LoadingStatus.Loading,
            };
        case Actions.GET_BATCH_SLOT_TYPES_FAILURE:
            return {
                ...state,
                gettingSlotTypesStatus: LoadingStatus.Failed,
            };
        case Actions.GET_BATCH_SLOT_TYPES_SUCCESS:
            const sortedSlotTypes = orderBy(
                action.response?.slotFormated || [],
                (slot) => slot.type.toLocaleLowerCase(),
            );
            return {
                ...state,
                gettingSlotTypesStatus: LoadingStatus.Loaded,
                slotTypes: sortedSlotTypes,
                weeksAvailable: action.response.weeksAvailable,
            };
        case Actions.FETCH_BATCH_SNOMED_CODES:
            return {
                ...state,
                gettingSnomedCodesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_BATCH_SNOMED_CODES_FAILURE:
            return {
                ...state,
                gettingSnomedCodesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_BATCH_SNOMED_CODES_SUCCESS:
            const sortedSnomed = action.response
                ? action.response.sort(snomedSort)
                : [];
            return {
                ...state,
                snomedCodes: sortedSnomed,
                gettingSnomedCodesStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_BATCH_FLOREY_SNOMED_CODES_SUCCESS:
            const sortedFloreySnomedCodes = action.response
                ? action.response.sort(snomedSort)
                : [];
            return {
                ...state,
                floreySnomedCodes: sortedFloreySnomedCodes,
                gettingFloreySnomedCodesStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_PRACTICE_USERGROUPS_SUCCESS:
            const options = action.response
                .filter(
                    (group) =>
                        group.displayName !== undefined &&
                        group.id !== undefined,
                )
                .map((userGroup) => userGroup as UserGroupRequiredFields)
                .map((userGroup) => ({
                    label: userGroup.displayName,
                    value: userGroup.id.toString(),
                    grouping: UserGroupCategory[userGroup.category] ?? "",
                }));
            return {
                ...state,
                userGroups: options,
                gettingUserGroupsStatus: UpdatingStatus.Loaded,
            };

        case Actions.UPDATE_BATCH_SELECTED_TEMPLATE_REPLY:
            return {
                ...state,
                selectedTemplateCanReply: action.canReply,
            };
        case Actions.UPLOAD_BATCH_FILE:
            return {
                ...state,
                fileRemovingStatus: UpdatingStatus.Initial,
                fileUploadingStatus: UpdatingStatus.Loading,
            };
        case Actions.UPLOAD_BATCH_FILE_SUCCESS:
            return {
                ...state,
                uploadedFileAttachmentId: action.response.attachmentId,
                uploadedFileLink: action.response.fileLink,
                uploadedFileName: action.response.fileName,
                fileUploadingStatus: UpdatingStatus.Loaded,
            };
        case Actions.UPLOAD_BATCH_FILE_FAILURE:
            return {
                ...state,
                fileUploadingStatus: UpdatingStatus.Failed,
            };
        case Actions.BATCH_FILE_DATA_RECEIVED:
            return {
                ...state,
                uploadedFileAttachmentId: action.fileData.attachmentId,
                uploadedFileLink: action.fileData.fileLink,
                uploadedFileName: action.fileData.fileName,
                fileUploadingStatus: UpdatingStatus.Loaded,
                isFileAttachmentFromTemplate:
                    action.fileData.isFileAttachmentFromTemplate ?? false,
            };
        case Actions.REMOVE_BATCH_FILE:
            return {
                ...state,
                uploadedFileAttachmentId: "",
                uploadedFileLink: "",
                uploadedFileName: "",
                fileRemovingStatus: UpdatingStatus.Loaded,
                fileUploadingStatus: UpdatingStatus.Initial,
                isFileAttachmentFromTemplate: false,
            };
        case Actions.UPDATE_BATCH_SELECTED_BEAST_SERIES:
            return {
                ...state,
                selectedBeastSeries: action.series,
            };
        case Actions.CREATE_BEAST_INVITES:
            return {
                ...state,
                sendingStatus: LoadingStatus.Loading,
            };
        case Actions.CREATE_BEAST_INVITES_SUCCESS:
            return {
                ...state,
                confirmMessageSuccess: true,
                sendingStatus: LoadingStatus.Loaded,
            };
        case Actions.CREATE_BEAST_INVITES_FAILURE:
            return {
                ...state,
                sendingStatus: LoadingStatus.Failed,
            };
        case Actions.FETCH_SIGN_UP_FLOREY:
            return {
                ...state,
                gettingBehaviourChangeFloreyStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_SIGN_UP_FLOREY_FAILURE:
            return {
                ...state,
                gettingBehaviourChangeFloreyStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_SIGN_UP_FLOREY_SUCCESS:
            return {
                ...state,
                behaviourChangeSignUpFlorey: action.response,
                gettingBehaviourChangeFloreyStatus: UpdatingStatus.Loaded,
            };
        case Actions.FETCH_BEAST_SERIES:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Loading,
            };
        case Actions.FETCH_BEAST_SERIES_FAILURE:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Failed,
            };
        case Actions.FETCH_BEAST_SERIES_SUCCESS:
            return {
                ...state,
                fetchingBeastSeriesStatus: UpdatingStatus.Loaded,
                beastSeries: action.response,
            };
        case Actions.SEND_NEW_BATCH_MESSAGE:
            return {
                ...initialState,
                isBatchScheduled: state.sendAt ? true : false,
            };
        case Actions.RESET_IS_BATCH_SCHEDULED: {
            return {
                ...state,
                isBatchScheduled: null,
            };
        }
        case Actions.RESET_CONFIRM_MESSAGE_SUCCESS:
            return {
                ...state,
                confirmMessageSuccess: false,
            };
        case Actions.RESET_CANCEL_BATCH_MESSAGE_STATUS:
            return {
                ...state,
                cancelBatchMessageStatus: LoadingStatus.Initial,
            };
        case PracticesAction.SET_SELECTED_PRACTICE:
            // Reset data when we are changing practice
            return initialState;
        case Actions.RESET_BATCH_MESSAGE_COLLECTION:
            return {
                ...state,
                batchMessageCollection: {
                    summaries: [],
                },
            };
        default:
            return state;
    }
};
