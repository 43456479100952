import React, { useEffect } from "react";

import { Button, Feedback, Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ChainAnalyticsTracker } from "app/analytics";
import {
    cancelBatchMessage,
    resetCancelBatchStatus,
} from "app/batchMessage/gp/BatchMessage.actions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector } from "store/hooks";

export type BatchMessageCancelModalProps = {
    batchId: string;
    orgId: string;
    isOpen: boolean;
    onToggleModal: () => void;
    onCancel: () => void;
};

export const BatchMessageCancelModal = ({
    batchId,
    orgId,
    isOpen,
    onToggleModal,
    onCancel,
}: BatchMessageCancelModalProps): JSX.Element | null => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const cancelStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.cancelBatchMessageStatus,
        shallowEqual,
    );
    const cancelBatch = () => {
        ChainAnalyticsTracker.trackBatchOverviewMessageCancelClick(
            analyticsLoggedInProps,
        );
        dispatch(cancelBatchMessage(orgId, batchId));
    };

    const closeBtn = (
        <>
            <Text
                as="span"
                props={{
                    className: "sr-only",
                }}
            >
                Closing the modal
            </Text>
            <Button
                theme="transparent"
                text=""
                dimension="medium"
                icon={{
                    name: "Cross",
                    style: "Line",
                    colour: "metal",
                }}
                onClick={onToggleModal}
            />
        </>
    );

    useEffect(() => {
        if (cancelStatus === LoadingStatus.Loaded) {
            toast(
                Feedback({
                    colour: "success",
                    title: `Sending cancelled!`,
                }),
            );
            onCancel();
            onToggleModal();
            dispatch(resetCancelBatchStatus());
        } else if (cancelStatus === LoadingStatus.Failed) {
            toast(
                Feedback({
                    colour: "error",
                    title: `There was a problem cancelling this batch`,
                }),
            );
            dispatch(resetCancelBatchStatus());
        }
    }, [cancelStatus, onToggleModal, onCancel, dispatch, orgId]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggleModal}
            fade={false}
            backdropClassName="bg-zinc"
            centered
            data-testid="batch-cancel-modal"
        >
            <ModalHeader toggle={onToggleModal} close={closeBtn}>
                <Text variant="label" className="mt-2">
                    Cancelling a batch message
                </Text>
            </ModalHeader>
            <ModalBody>
                Once cancelled, any remaining patients will not receive the
                message.
            </ModalBody>
            <ModalFooter>
                <Button text="Cancel" theme="danger" onClick={cancelBatch} />
            </ModalFooter>
        </Modal>
    );
};
