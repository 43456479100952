/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import {
    Button,
    FormFieldWrapper,
    GeneratedColumn,
    Grid,
    SelectItem,
} from "@accurx/design";
import { SortDirectionConfig } from "@accurx/design/dist/components/Table/hooks/useTable.types";

import {
    trackCategoriesFilterClear,
    trackCategoriesFilterClick,
    trackCategoriesFilterSelect,
} from "../../analytics/FlemingAnalytics";
import { useFlemingLoggedInAnalytics } from "../../sessionAnalytics/useFlemingLoggedInAnalytics";
import { StyledSelect } from "./ManageTemplates.styles";
import { DisplayTemplate } from "./ManageTemplates.types";

const noCategoryString = "Templates with no category";
const allTemplatesString = "All templates";
const mapDistinctCategories = (templates: DisplayTemplate[]) => {
    const categories = templates.map((template) => {
        return template.categoryHeading === ""
            ? noCategoryString
            : template.categoryHeading;
    });
    categories.unshift(allTemplatesString);
    return Array.from(new Set(categories).values());
};

export const CategoriesFilter = ({
    templates,
    table,
    currentTable,
}: {
    templates: DisplayTemplate[];
    table: {
        rows: { categoryHeading: string }[];
        columns: GeneratedColumn[];
        metadata: {
            sortColumnId: string | undefined;
            sortDirection: SortDirectionConfig;
        };
        actions: {
            filter: (filter: (data: any) => boolean) => void;
        };
    };
    currentTable: string;
}): JSX.Element => {
    const [selected, setSelected] = useState<string>(allTemplatesString);
    const flemingLoggedInProps = useFlemingLoggedInAnalytics();
    const [open, setOpen] = useState(false);

    const distinctCategories = mapDistinctCategories(templates);
    const handleFilter = (selectedCategory: string) => {
        trackCategoriesFilterSelect({
            ...flemingLoggedInProps,
            filterName: "Category",
            currentTable,
            optionSelected: selectedCategory,
        });
        setSelected(selectedCategory);
        if (selectedCategory === allTemplatesString) {
            table.actions.filter((row) => {
                return true;
            });
        } else {
            const categoryFilter =
                selectedCategory === noCategoryString ? "" : selectedCategory;
            table.actions.filter(
                (row) => row.categoryHeading === categoryFilter,
            );
        }
    };
    const handleClearFilter = () => {
        trackCategoriesFilterClear({ ...flemingLoggedInProps, currentTable });
        table.actions.filter(() => {
            return true;
        });
        setSelected(allTemplatesString);
    };
    const handleOpen = (isOpen: boolean) => {
        if (isOpen) {
            trackCategoriesFilterClick({
                ...flemingLoggedInProps,
                filterName: "Category",
                currentTable,
            });
        }
        setOpen(isOpen);
    };
    if (templates.length === 0) {
        return <></>;
    }
    return (
        <FormFieldWrapper label="Filter by category">
            <Grid
                columns={{
                    sm: "1fr",
                    lg: "4fr 1fr",
                }}
                gap="1"
            >
                <StyledSelect
                    name={"Select category"}
                    dimension="medium"
                    placeholder={"Select category"}
                    onValueChange={handleFilter}
                    value={selected} //handling this manually so we can clear from the outside
                    onOpenChange={handleOpen}
                    open={open}
                >
                    {distinctCategories.map((category) => (
                        <SelectItem key={category} value={category}>
                            {category}
                        </SelectItem>
                    ))}
                </StyledSelect>
                <div>
                    <Button
                        icon={{
                            name: "Refresh",
                            colour: "blue",
                        }}
                        text={"Clear"}
                        onClick={handleClearFilter}
                        theme={"secondary"}
                    />
                </div>
            </Grid>
        </FormFieldWrapper>
    );
};
