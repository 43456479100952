import { Log } from "@accurx/shared";

import {
    isAssignedToRule,
    isContainsItemRule,
    isMatchItemTypeRule,
    isStartsWithItemRule,
    isStatusRule,
} from "shared/concierge/conversations/ConversationGroup.utils";
import {
    AssigneeType,
    GetFilteredTicketViewRequest,
    PatientThreadAssignee,
    PatientThreadTicketFolder,
    View,
} from "shared/concierge/conversations/tickets/types/dto.types";
import {
    ConversationAssignedToRule,
    ConversationGroupRuleset,
    ConversationGroupSortOptions,
    ConversationItemSenderRule,
    ConversationStatusRule,
} from "shared/concierge/conversations/types/conversationGroup.types";
import { ConversationItemType } from "shared/concierge/conversations/types/item.types";

const SentToPatientItemTypes: ConversationItemType[] = [
    "PatientEmail",
    "PatientSms",
    "NhsAppMessage",
];
const FailedMessageItemTypes: ConversationItemType[] = [
    "FailedDeliveryReceiptLink",
];
const PatientTriageItemTypes: ConversationItemType[] = [
    "PatientTriageRequestNote",
];
const PatientSingleResponseItemTypes: ConversationItemType[] = [
    "PatientSingleResponse",
];
const AppointmentRequestItemTypes: ConversationItemType[] = [
    "PatientAppointmentRequestNote",
];

export const mapRulesetForFilteredTicketViewRequest = (
    ruleset: ConversationGroupRuleset,
): Pick<GetFilteredTicketViewRequest, "assignee" | "isDone"> | undefined => {
    if (ruleset.rules.find(isContainsItemRule)) {
        // The API provides only latest highlights only
        return undefined;
    }

    if (ruleset.rules.find(isStartsWithItemRule)) {
        // These are all currently implicitly extra filters on OpenTickets for
        // the ToAssign team,let the original folder API pick them up.
        return undefined;
    }

    const statusRule = ruleset.rules.find(isStatusRule);
    const isDoneFilterValue = statusRule
        ? mapStatusRuleToIsDoneFilter(statusRule)
        : undefined;
    if (isDoneFilterValue === undefined) {
        // not yet supported as we would need to include pseudo tickets
        return undefined;
    }

    const assigneeRule = ruleset.rules.find(isAssignedToRule);
    return {
        isDone: isDoneFilterValue,
        assignee: assigneeRule
            ? mapAssigneeRuleToFilter(assigneeRule)
            : undefined,
    };
};

export const mapRulesetForFolderTicketViewRequest = (
    ruleset: ConversationGroupRuleset,
): PatientThreadTicketFolder | undefined => {
    /*
     * We're doing a somewhat messy mapping to the existing ticket folder API for now
     * rather than a new aligned API, but by abstracting at the Ruleset definitions we
     * can iterate on this later without impacting UI component code.
     *
     * Note that as long as we use an API that gives us a superset of results, we're ok
     * for correctness of results as the other rules are applied in memory client side -
     * though obviously this is inefficient with respect to client and server load.
     */

    for (const rule of ruleset.rules) {
        if (isContainsItemRule(rule)) {
            const senderRule = rule.value.rules.find(
                (r): r is ConversationItemSenderRule =>
                    r.type === "Sender" && r.value.type === "User",
            );
            const scheduledRule = rule.value.rules.find(
                (r): r is ConversationItemSenderRule =>
                    r.type === "DeliveryStatus" && r.value.includes("Queued"),
            );

            const sentToPatientRule = rule.value.rules.find(
                isMatchItemTypeRule(SentToPatientItemTypes),
            );

            if (sentToPatientRule && scheduledRule) {
                return senderRule
                    ? {
                          viewType: View.UserScheduled,
                          userId: senderRule.value.id,
                      }
                    : { viewType: View.Scheduled };
            }

            if (sentToPatientRule && !scheduledRule) {
                return senderRule
                    ? { viewType: View.UserSent, userId: senderRule.value.id }
                    : { viewType: View.Sent };
            }

            const failedRule = rule.value.rules.find(
                isMatchItemTypeRule(FailedMessageItemTypes),
            );
            if (failedRule) {
                return senderRule
                    ? { viewType: View.UserFailed, userId: senderRule.value.id }
                    : { viewType: View.Failed };
            }

            const patientSingleResponseRule = rule.value.rules.find(
                isMatchItemTypeRule(PatientSingleResponseItemTypes),
            );
            if (patientSingleResponseRule) {
                return senderRule
                    ? {
                          viewType: View.PatientSingleResponse,
                          userId: senderRule.value.id,
                      }
                    : { viewType: View.PatientSingleResponse };
            }

            const patientTriageRule = rule.value.rules.find(
                isMatchItemTypeRule(PatientTriageItemTypes),
            );
            if (patientTriageRule) {
                return { viewType: View.PatientTriage };
            }
        }

        // these are all currently implicitly extra filters on OpenTickets for the ToAssign team
        if (isStartsWithItemRule(rule)) {
            const assigneeRule = ruleset.rules.find(isAssignedToRule);
            const openRule = ruleset.rules.find(isStatusRule);
            if (
                assigneeRule?.value.type !== "Team" ||
                openRule?.value !== "Open"
            ) {
                continue;
            }

            const appointmentRule = rule.value.rules.find(
                isMatchItemTypeRule(AppointmentRequestItemTypes),
            );
            if (appointmentRule) {
                return {
                    viewType: View.PatientAppointmentRequest,
                    userGroupId: assigneeRule.value.id ?? undefined,
                };
            }

            const patientTriageRule = rule.value.rules.find(
                isMatchItemTypeRule(PatientTriageItemTypes),
            );
            if (patientTriageRule) {
                const typeRule = rule.value.rules.find(
                    (r) => r.type === "PatientTriageRequestType",
                );
                if (typeRule?.value === "Admin") {
                    return {
                        viewType: View.ToAssignTriageAdmin,
                        userGroupId: assigneeRule.value.id ?? undefined,
                    };
                }
                if (typeRule?.value === "Medical") {
                    return {
                        viewType: View.ToAssignTriageMedical,
                        userGroupId: assigneeRule.value.id ?? undefined,
                    };
                }
            }
        }
    }

    // no obvious API mapping
    return undefined;
};

const mapStatusRuleToIsDoneFilter = (
    rule: ConversationStatusRule,
): boolean | undefined => {
    switch (rule.value) {
        case "Done":
            return true;
        case "Open":
            return false;
        default:
            const unknownValue = rule.value as never; // force compilation failure if new value introduced
            Log.error(
                "Unsupported ConversationStatusRule for request",
                unknownValue,
            );
            return undefined;
    }
};

const mapAssigneeRuleToFilter = (
    rule: ConversationAssignedToRule,
): PatientThreadAssignee | undefined => {
    switch (rule.value.type) {
        case "User":
            return {
                type: AssigneeType.User,
                userId: rule.value.id ?? undefined,
            };
        case "Team":
            return {
                type: AssigneeType.UserGroup,
                userGroupId: rule.value.id ?? undefined,
            };
        default:
            const unknownType = rule.value.type as never; // force compilation failure if new value introduced
            Log.error(
                "Unsupported ConversationAssignedToRule for request",
                unknownType,
            );
            return undefined;
    }
};

/**
 * The original folder-based views in the ticket API have a server-defined sort order
 * that's tied to the folder type enum value.
 *
 * @param folder The ticket folder definition
 */
export const getServerSortDefinitionForFolder = (
    folder: PatientThreadTicketFolder,
): ConversationGroupSortOptions | undefined => {
    switch (folder.viewType) {
        case View.Sent:
        case View.UserSent:
            return {
                sortOrder: "NewestFirst",
                sortBy: "DisplayItemCreatedAt",
            };
        case View.Scheduled:
        case View.UserScheduled:
            return {
                sortBy: "ScheduledSendDate",
                sortOrder: "SoonestFirst",
            };
        case View.ToAssign:
        case View.ToAssignTriageAdmin:
        case View.ToAssignTriageMedical:
        case View.PatientAppointmentRequest:
        case View.PatientSingleResponse:
        case View.PatientTriage:
        case View.UserFailed:
        case View.Failed:
            return {
                sortOrder: "UrgentThenNewestFirst",
                sortBy: "ConversationLastUpdated",
            };
        default:
            // not using the rest yet - log an error on use
            Log.error(
                "Mapped to a ticket folder request with an undocumented sort order",
                { tags: { folderViewType: folder.viewType } },
            );
            return undefined;
    }
};
