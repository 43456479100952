import React, { useEffect } from "react";

import { PatientListType } from "@accurx/api/patient-messaging";
import { Feedback, Spinner, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { NavSubMenuComponent } from "app/navbar/NavSubMenuComponent";
import { BulkAddLoadingState } from "app/patientLists/PatientListsReducer";
import { actionCreators as selectProductActionCreators } from "app/selectProduct/SelectProductActions";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES, ROUTES_BASE } from "shared/Routes";
import {
    SocketEvents,
    useWebsocketEvent,
} from "shared/hubClient/useWebsocketEvent";
import { useAppSelector } from "store/hooks";

import { PatientListEditable } from "./PatientListEditable";
import { PatientListReadonly } from "./PatientListReadonly";
import { actionCreators } from "./PatientListsActions";
import { PatientListEditableSubMenu } from "./submenu/PatientListEditableSubMenu";
import { PatientListReadonlySubMenu } from "./submenu/PatientListReadonlySubMenu";

type PatientListProps = {
    urlPatientListId: string;
};

export const PatientList = ({
    urlPatientListId,
}: PatientListProps): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();

    // If organisationId is null, the GuardedRoute will automatically redirect to the homepage
    const organisationId = useAppSelector(
        (state) => state.account.selectedOrganisation,
    );
    const organisationName = useAppSelector(
        (state) =>
            OrganisationHelper.getOrganisation(state.account)?.organisationName,
    );
    const currentListId = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.patientListId,
    );
    const currentListName = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.name,
    );
    const currentListLoading = useAppSelector(
        ({ patientLists }) => patientLists.currentListLoading,
    );
    const currentListLastFailed = useAppSelector(
        ({ patientLists }) => patientLists.currentListLastFailed,
    );
    const createOrEditListInitialising = useAppSelector(
        ({ patientLists }) => patientLists.createOrEditListInitialising,
    );
    const currentListReadonly = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.isReadonly,
    );
    const currentListType = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.listType,
    );
    const currentListDays = useAppSelector(
        ({ patientLists }) => patientLists.currentList?.appointmentDays || null,
        shallowEqual,
    );
    const patientConsultationIds = useAppSelector(
        ({ patientLists }) => patientLists?.currentListConsultationIds,
        shallowEqual,
    );
    const videoError = useAppSelector(
        ({ patientLists }) => patientLists.currentListVideoProgressError,
    );
    const createOrEditListSubmitting = useAppSelector(
        ({ patientLists }) => patientLists.createOrEditListSubmitting,
    );

    const currentListPatientAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientAdding,
    );

    const currentListPatientsBulkAdding = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBulkAdding,
    );

    const currentListPatientRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientRemoving,
    );

    const currentListPatientsBatchRemoving = useAppSelector(
        ({ patientLists }) => patientLists.currentListPatientsBatchRemoving,
    );

    const deletingListLoading = useAppSelector(
        ({ patientLists }) => patientLists.deletingListLoading,
    );

    const lastDeletedListId = useAppSelector(
        ({ patientLists }) => patientLists.lastDeletedListId,
    );

    useAccurxWebTitle("View patient list");

    const shouldDisableBackButton =
        createOrEditListSubmitting ||
        currentListPatientRemoving ||
        currentListPatientsBatchRemoving ||
        currentListPatientAdding ||
        deletingListLoading ||
        currentListPatientsBulkAdding !== BulkAddLoadingState.None;

    // Load the current list
    useEffect(() => {
        const isCurrentListDaysValid = currentListDays === null;
        if (!isCurrentListDaysValid) return;

        if (currentListLoading || currentListLastFailed) return;
        if (!organisationId) return;
        if (createOrEditListInitialising) return;

        /** Transform from a string URL param to a number
         * for comparison and storage in redux
         */
        const urlPatientListIdAsNumber = parseInt(urlPatientListId);
        if (isNaN(urlPatientListIdAsNumber)) {
            history.push(ROUTES_BASE.patientsListsAll);
            return;
        }

        if (lastDeletedListId !== urlPatientListIdAsNumber) {
            dispatch(
                actionCreators.getUserPatientList({
                    organisationId,
                    patientListId: urlPatientListIdAsNumber,
                }),
            );
        }
    }, [
        history,
        dispatch,
        organisationId,
        currentListDays,
        currentListLoading,
        currentListLastFailed,
        urlPatientListId,
        createOrEditListInitialising,
        lastDeletedListId,
    ]);

    // Reset current list state on unmount
    useEffect(() => {
        // Set the origin of the patient selection to patient list route
        dispatch(
            selectProductActionCreators.setPatientSearchOrigin(
                ROUTES.patient_lists,
            ),
        );

        // Here I want to reset the product in case I selected one already and clicked on Go Back
        dispatch(selectProductActionCreators.resetProductType());

        return (): void => {
            // make sure we are not initialising edit or create on unmount
            dispatch(actionCreators.cancelCreateOrEditList());
            dispatch(actionCreators.resetCurrentList());
        };
    }, [dispatch]);

    // fetch video statuses at the start
    useEffect(() => {
        if (!patientConsultationIds?.length) {
            return;
        }

        dispatch(
            actionCreators.checkPatientsVideoProgress(patientConsultationIds),
        );
    }, [dispatch, patientConsultationIds]);

    // subscribe to video status changed socket events
    useWebsocketEvent(
        SocketEvents.OnVideoConsultationStatusChanged,
        (data) => {
            if (!patientConsultationIds?.length) {
                return;
            }

            if (currentListId === data.payload.patientListId) {
                dispatch(
                    actionCreators.checkPatientsVideoProgress(
                        patientConsultationIds,
                    ),
                );
            }
        },
        [dispatch, patientConsultationIds, currentListId],
    );

    if (
        !currentListLoading &&
        !createOrEditListInitialising &&
        currentListLastFailed
    ) {
        toast(
            <Feedback
                colour="error"
                title="Sorry, we're unable to load that list."
            />,
        );

        // Redirect to main patient lists page if failed to load list
        return <Redirect to={ROUTES_BASE.patientsListsAll} />;
    }

    return (
        <>
            {currentListName !== undefined && (
                <NavSubMenuComponent
                    backButtonDisabled={shouldDisableBackButton}
                    backCallback={history.goBack}
                >
                    {currentListReadonly ? (
                        <PatientListReadonlySubMenu />
                    ) : (
                        <PatientListEditableSubMenu
                            shouldActionButtonsBeDisabled={
                                shouldDisableBackButton
                            }
                        />
                    )}
                </NavSubMenuComponent>
            )}

            <StackPanel gutter={3}>
                {currentListLoading && <Spinner />}
                {!currentListLoading && !currentListLastFailed && (
                    <>
                        {videoError && (
                            <Feedback
                                colour="error"
                                title="Something went wrong"
                            >
                                <Text skinny>
                                    We can’t update the Video Consult status for
                                    patients right now. Refresh the page
                                </Text>
                            </Feedback>
                        )}
                        {currentListType === PatientListType.PAS && (
                            <Feedback colour="information">
                                <Text skinny>
                                    This list is being updated from your Patient
                                    Administration System (PAS) · Its contents
                                    are visible to all staff at{" "}
                                    {organisationName}
                                </Text>
                            </Feedback>
                        )}
                        {currentListReadonly ? (
                            <PatientListReadonly />
                        ) : (
                            <PatientListEditable />
                        )}
                    </>
                )}
            </StackPanel>
        </>
    );
};
