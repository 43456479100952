import { useEffect, useRef } from "react";

import * as UI from "@accurx/design";

import { AutoFocusHeadingProps } from "./AutoFocusHeading.types";

/** A heading component that receives focus on mount. Can be used
 * to manage focus after a client-side navigation.
 */
export const AutoFocusHeading = ({ as, ...rest }: AutoFocusHeadingProps) => {
    const ref = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        ref.current?.focus();
    }, []);

    return (
        <UI.Text
            as={as}
            props={{
                style: {
                    border: "none",
                    outline: "none",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
                ref,
                tabIndex: -1,
            }}
            {...rest}
        >
            {rest.children}
        </UI.Text>
    );
};
