import { Ds, Flex, Icon, Text, Tokens } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import styled from "styled-components";

import { formatUpdatedDateTime } from "../../../shared/formatUpdatedDateTime";

const StyledWrapper = styled(Flex).attrs({
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1",
})`
    margin-bottom: ${Tokens.SIZES[2]};
`;

const StyledTitlePart = styled("span")`
    font-weight: bold;
`;

type HeaderProps = {
    startDate: string;
    endDate: string;
    lastUpdated?: string | null;
};

export const Header = ({ startDate, endDate, lastUpdated }: HeaderProps) => {
    const formattedStartDate = DateHelpers.formatDate(
        startDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );
    const formattedEndDate = DateHelpers.formatDate(
        endDate,
        DateFormatOptions.DATE_SHORT_WITH_SPACES,
    );
    const formattedLastUpdated = formatUpdatedDateTime(lastUpdated);

    return (
        <StyledWrapper>
            <Flex alignItems="center" gap="1">
                <Icon
                    name="Calendar"
                    colour="blue"
                    size={4}
                    halo={{
                        colour: "blue",
                        luminosity: "high",
                    }}
                />

                <Text as="h2" skinny>
                    <StyledTitlePart>Relevant clinics</StyledTitlePart> · From{" "}
                    {formattedStartDate} - {formattedEndDate}
                </Text>
            </Flex>

            <Flex alignItems="center" gap="1.5" flexWrap="wrap">
                <Text skinny variant="label">
                    <Icon name="Clock" colour="zinc" size={3} />
                    Updated ·{" "}
                    {lastUpdated ? (
                        <time dateTime={lastUpdated}>
                            {formattedLastUpdated}
                        </time>
                    ) : (
                        formattedLastUpdated
                    )}
                </Text>

                <Ds.Badge color="blue">Integrated with HISS</Ds.Badge>
            </Flex>
        </StyledWrapper>
    );
};
