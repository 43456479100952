import {
    ConditionOperator,
    QuestionTemplateType,
    QuestionType,
    StatusQuery,
} from "@accurx/api/florey-builder";
import { z } from "zod";

export const statusQuerySchema = z.nativeEnum(StatusQuery);

export const snomedCodeSchema = z.object({
    conceptId: z.string(),
    term: z.string(),
    unit: z.string().optional().nullable(),
});

export const questionTypeSchema = z.nativeEnum(QuestionType);

export const optionSchema = z.object({
    text: z.string(),
    answerCode: snomedCodeSchema.optional().nullable(),
    /** @format guid */
    clientId: z.string(),
});

export const questionTemplateTypeSchema = z.nativeEnum(QuestionTemplateType);

export const numberedQuestionDataSchema = z.object({
    /** @format double */
    minimumValue: z.number(),
    /** @format double */
    maximumValue: z.number(),
});

export const conditionOperatorSchema = z.nativeEnum(ConditionOperator);

export const conditionQuestionSchema = z.object({
    /** @format int32 */
    questionId: z.number(),
    questionText: z.string(),
    answerText: z.string(),
});

export const conditionSchema = z.object({
    conditionOperator: conditionOperatorSchema,
    conditionQuestion: conditionQuestionSchema,
});

export const questionSchema = z.object({
    /** @format int32 */
    id: z.number(),
    questionType: questionTypeSchema,
    questionTitle: z.string().optional().nullable(),
    helperText: z.string().optional().nullable(),
    /** @format int32 */
    order: z.number().optional().nullable(),
    snomedCode: snomedCodeSchema.optional().nullable(),
    snomedCodeUnit: z.string().optional().nullable(),
    options: z.array(optionSchema).optional().nullable(),
    questionRowVersion: z.string(),
    questionTemplateType: questionTemplateTypeSchema.optional().nullable(),
    numberedQuestionData: numberedQuestionDataSchema.optional().nullable(),
    // branchData?: BranchData | null;
    allowMultipleAnswers: z.boolean().optional().nullable(),
    imageDocumentUrl: z.string().optional().nullable(),
    imageAltText: z.string().optional().nullable(),
    recordText: z.string().optional().nullable(),
});

export const branchDataSchema = z.object({
    condition: conditionSchema,
    branchQuestions: z.array(questionSchema),
});

export const branchSchema = z.object({
    id: z.number(),
    order: z.number().optional().nullable(),
    questionType: questionTypeSchema,
    branchData: branchDataSchema,
});

export const questionnaireResponseSchema = z.object({
    id: z.number(),
    graphId: z.string(),
    status: statusQuerySchema,
    name: z.string().optional().nullable(),
    enrolmentMessage: z.string().optional().nullable(),
    enrolmentSnomedCode: snomedCodeSchema.optional().nullable(),
    completionText: z.string().optional().nullable(),
    completionSnomedCode: snomedCodeSchema.optional().nullable(),
    questions: z.array(branchSchema.or(questionSchema)),
    questionnaireRowVersion: z.string(),
    /** @format int32 */
    userGroupId: z.number().optional().nullable(),
    isGloballyAccessible: z.boolean().optional().nullable(),
    /** @format int32 */
    categoryId: z.number().optional().nullable(),
    tagGroups: z.array(z.array(z.number())).optional().nullable(),
});
