import React, { useEffect, useState } from "react";

import { Feedback, Icon, Spinner, StackPanel, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

import { useSafeAsync } from "api/Api.utils";
import FlemingApiClient from "api/FlemingApiClient";
import { PatientAttachment } from "api/FlemingDtos";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { PatientHelper } from "shared/PatientHelper";
import { useAppSelector } from "store/hooks";

import { PatientAttachmentCard } from "../PatientAttachmentCard";
import { StyledFlex, StyledSectionHeader } from "./OverviewPanel.styles";

export const DocumentsPanel = (): JSX.Element => {
    useAccurxWebTitle("View documents sent to patient");

    const safeAsync = useSafeAsync();

    const [patientAttachments, setPatientAttachments] = useState<
        PatientAttachment[] | null
    >(null);

    const [isLoading, setIsLoading] = useState(true);

    const [hasError, setHasError] = useState(false);

    const organisationId = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationId(account),
    );

    const patientToken = useAppSelector(({ searchForPatient }) =>
        PatientHelper.getPatientToken(searchForPatient.lastResponse),
    );

    const organisationName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );

    useEffect(() => {
        const initLoadRequest = async (): Promise<void> => {
            if (organisationId && patientToken) {
                setIsLoading(true);
                setHasError(false);
                const { result, success } = await safeAsync(
                    FlemingApiClient.getPatientAttachments({
                        organisationId: organisationId,
                        patientToken: patientToken,
                    }),
                );
                setIsLoading(false);
                if (success && result) {
                    setPatientAttachments(
                        result.attachments.sort((a, b) =>
                            new Date(a.sentAt) > new Date(b.sentAt) ? -1 : 1,
                        ),
                    );
                    setHasError(false);
                } else {
                    setHasError(true);
                }
            }
        };

        initLoadRequest();
    }, [safeAsync, organisationId, patientToken]);

    const renderMainContent = (): JSX.Element => {
        if (isLoading) {
            return <Spinner />;
        }

        if (hasError) {
            return (
                <Feedback
                    colour="error"
                    title={`There was an error loading the attachments for ${organisationName}`}
                >
                    <Text skinny>Please refresh the page and try again</Text>
                </Feedback>
            );
        }
        return (
            <>
                <StackPanel gutter={1}>
                    <StyledFlex>
                        <Icon
                            name="Paperclip"
                            size={4}
                            halo={{ colour: "blue" }}
                        />
                        <StyledSectionHeader forwardedAs="h5" variant="label">
                            Sent attachments
                        </StyledSectionHeader>
                    </StyledFlex>
                    {patientAttachments?.length === 0 && (
                        <Feedback colour="information" iconName="None">
                            <Text skinny>
                                No attachments have been sent to this patient by{" "}
                                {organisationName}
                            </Text>
                        </Feedback>
                    )}
                    {patientAttachments?.map((attachment) => (
                        <PatientAttachmentCard
                            key={attachment.documentUrlId}
                            fileName={attachment.fileName}
                            documentUrlId={attachment.documentUrlId}
                            downloadUrl={attachment.downloadUrl}
                            description={
                                attachment.sentByCurrentUser
                                    ? `Sent by ${attachment.sentBy} (You)`
                                    : `Sent by ${attachment.sentBy}`
                            }
                            sentAt={DateHelpers.formatDate(
                                attachment.sentAt,
                                DateFormatOptions.TIME_DATE,
                            )}
                            deletedAt={attachment.deletedAt}
                        />
                    ))}
                </StackPanel>
            </>
        );
    };

    return renderMainContent();
};
