import * as React from "react";

import {
    Grid,
    Input,
    SelectItem,
    SingleSelect,
    VisuallyHidden,
} from "@accurx/design";
import debounce from "lodash/debounce";

import {
    trackPatientTriageDashboardFilterByNameInput,
    trackPatientTriageDashboardFilterByRequestButtonClick,
    trackPatientTriageDashboardFilterByRequestMenuItemClick,
} from "app/analytics/FlemingAnalytics/PatientTriageDashboardEvents";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";

import { RequestSource, RequestType } from "../../../shared/shared.constants";
import { IRequestSource, IRequestType } from "../../../shared/shared.types";
import { UserType } from "../shared/shared.constants";
import { AssignmentData } from "../shared/shared.types";

type RequestFiltersProps = {
    onFilterByText: (filterFn: (row: AssignmentData) => boolean) => void;
    requestType: IRequestType;
    requestSource: IRequestSource;
    onFilterByRequestType: (value: IRequestType) => void;
    onFilterByRequestSource: (value: IRequestSource) => void;
};

export const RequestFilters = ({
    onFilterByText,
    requestType,
    requestSource,
    onFilterByRequestType,
    onFilterByRequestSource,
}: RequestFiltersProps): JSX.Element => {
    const [isRequestTypeOpen, setIsRequestTypeOpen] = React.useState(false);
    const [isRequestSourceOpen, setIsRequestSourceOpen] = React.useState(false);
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const handleFilter = debounce((query: string) => {
        onFilterByText((row) =>
            row.name
                ? row.name.toLowerCase().includes(query.toLowerCase()) ||
                  (row.type === UserType.SharedTeam &&
                      "shared team".includes(query.toLowerCase()))
                : false,
        );
        trackPatientTriageDashboardFilterByNameInput({
            ...analyticsLoggedInProps,
            sectionName: "Requests by assignee",
            dashboardName: "Patient Triage Dashboard",
            filterName: "Filter by Name",
            inputText: query,
        });
    }, 300);

    const handleTextFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const query = event.target.value;
        handleFilter(query);
    };

    const handleRequestTypeOpenChange = (isOpen: boolean) => {
        setIsRequestTypeOpen(isOpen);
        if (isOpen) {
            trackPatientTriageDashboardFilterByRequestButtonClick({
                ...analyticsLoggedInProps,
                sectionName: "Requests by assignee",
                dashboardName: "Patient Triage Dashboard",
                filterName: "Filter by Request Type",
            });
        }
    };

    const handleRequestSourceOpenChange = (isOpen: boolean) => {
        setIsRequestSourceOpen(isOpen);
        if (isOpen) {
            trackPatientTriageDashboardFilterByRequestButtonClick({
                ...analyticsLoggedInProps,
                sectionName: "Requests by assignee",
                dashboardName: "Patient Triage Dashboard",
                filterName: "Filter by Request Source",
            });
        }
    };

    const handleRequestTypeValueChange = (value: IRequestType) => {
        onFilterByRequestType(value);
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            sectionName: "Requests by assignee",
            dashboardName: "Patient Triage Dashboard",
            filterName: "Filter by Request Type",
            activeFilterList: [
                `Request Type - ${requestType}`,
                `Request Source - ${requestSource}`,
            ],
            optionSelected: value,
        });
    };

    const handleRequestSourceValueChange = (value: IRequestSource) => {
        onFilterByRequestSource(value);
        trackPatientTriageDashboardFilterByRequestMenuItemClick({
            ...analyticsLoggedInProps,
            sectionName: "Requests by assignee",
            dashboardName: "Patient Triage Dashboard",
            filterName: "Filter by Request Source",
            activeFilterList: [
                `Request Source - ${requestSource}`,
                `Request Type - ${requestType}`,
            ],
            optionSelected: value,
        });
    };

    return (
        <Grid gap="1" columns={{ sm: "100%", lg: "50% 30% 20%" }}>
            <VisuallyHidden as="label" htmlFor="filter-by-text">
                Filter by person or team name
            </VisuallyHidden>
            <Input
                id="filter-by-text"
                placeholder="Filter by person or team name"
                onChange={handleTextFilterChange}
                isSearchInput
            />
            <div>
                <VisuallyHidden
                    as="label"
                    htmlFor="filter-assignee-by-request-source"
                >
                    Filter requests by source
                </VisuallyHidden>
                <SingleSelect
                    id="filter-assignee-by-request-source"
                    dimension="medium"
                    value={requestSource}
                    open={isRequestSourceOpen}
                    onOpenChange={handleRequestSourceOpenChange}
                    onValueChange={handleRequestSourceValueChange}
                >
                    <SelectItem value={RequestSource.PatientsAndReception}>
                        Submitted by patients and reception
                    </SelectItem>
                    <SelectItem value={RequestSource.Patients}>
                        Submitted by patients only
                    </SelectItem>
                    <SelectItem value={RequestSource.Reception}>
                        Submitted by reception only
                    </SelectItem>
                </SingleSelect>
            </div>
            <div>
                <VisuallyHidden
                    as="label"
                    htmlFor="filter-assignee-by-request-type"
                >
                    Filter requests by type
                </VisuallyHidden>
                <SingleSelect
                    id="filter-assignee-by-request-type"
                    dimension="medium"
                    value={requestType}
                    open={isRequestTypeOpen}
                    onOpenChange={handleRequestTypeOpenChange}
                    onValueChange={handleRequestTypeValueChange}
                >
                    <SelectItem value={RequestType.MedicalAndAdmin}>
                        Medical and Admin
                    </SelectItem>
                    <SelectItem value={RequestType.Medical}>
                        Medical only
                    </SelectItem>
                    <SelectItem value={RequestType.Admin}>
                        Admin only
                    </SelectItem>
                </SingleSelect>
            </div>
        </Grid>
    );
};
