import {
    CollaborativeWorkspaceUpdateRequest,
    LoginWithPasswordlessRequest,
    MagicLinkResponseV2,
    RegisterAccountProductType,
    RegisterRequest,
    RegisterResult,
    ResetPasswordResponseV2,
} from "@accurx/api/account";
import { Organisation } from "@accurx/auth";
import { IWrappedResult, getApiEndpoint, httpClient } from "@accurx/shared";

import {
    ConfirmEmailRequestDto,
    ResendEmailRequestDto,
} from "app/account/ConfirmEmailVerify.types";
import { AuthForgottenDto } from "app/account/Password/ForgotPassword.types";
import { ResetPasswordRequestDto } from "app/account/Password/ResetPassword";

const ENDPOINTS = {
    accountConfirmEmail: "/api/account/ConfirmEmail/Index",
    accountResend: "/api/account/ConfirmEmail/Resend",
    accountForgotPassword: "/api/account/ForgotPassword",
    accountRegister: "/api/account/Register",
    accountResetPassword: "/api/account/v2/ResetPassword",
    accountForgotPasswordWithUserId: "/api/account/ForgotPasswordWithUserId",
    accountMagicLink: "/api/account/MagicLink/v2/index",
    accountRequestRegisterLogin:
        "/api/account/RequestRegisterOrPasswordlessLogin",
    accountUpdateWorkspace: "api/account/workspace/:workspaceId",
} as const;

export const postConfirmEmail = (
    request: ConfirmEmailRequestDto,
): Promise<IWrappedResult<LoginWithPasswordlessRequest>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountConfirmEmail,
        }),
        request,
        // Not a logged in endpoint, we don't want to reset client if unauthorized. Error handled by verify page.
        false,
    );

export const postResendEmail = (
    request: ResendEmailRequestDto,
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountResend,
        }),
        request,
    );

export const postForgottenPassword = (
    request: AuthForgottenDto,
): Promise<IWrappedResult<boolean>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountForgotPassword,
        }),
        request,
    );

export const postRegister = (
    request: RegisterRequest,
): Promise<IWrappedResult<RegisterResult>> =>
    httpClient.postJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountRegister,
        }),
        request,
    );

export const getResetValidateTokens = (
    userId: string,
    code: string,
): Promise<IWrappedResult<ResetPasswordResponseV2>> => {
    const encodedCode = encodeURIComponent(code); // This api expect an encoded token
    return httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountResetPassword,
            query: `?userId=${userId}&code=${encodedCode}`,
        }),
    );
};

export const postResetPassword = (
    request: ResetPasswordRequestDto,
): Promise<{ success: boolean }> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountResetPassword,
        }),
        request,
    );

export const postResetResendEmail = (
    userId: string,
    product: RegisterAccountProductType,
): Promise<{ success: boolean }> =>
    httpClient.postSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountForgotPasswordWithUserId,
            query: `?userId=${userId}&product=${product}`,
        }),
    );

export const getMagicValidateTokens = (
    email: string,
    token: string,
): Promise<IWrappedResult<MagicLinkResponseV2>> =>
    httpClient.getReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountMagicLink,
            query: `?email=${email}&token=${token}`,
        }),
    );

export const postMagicResendEmail = (
    email: string,
): Promise<IWrappedResult<undefined>> =>
    httpClient.postJsonReturnSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountRequestRegisterLogin,
        }),
        { email },
    );

export const updateCollaborativeWorkspace = (
    workspaceId: string,
    request: CollaborativeWorkspaceUpdateRequest,
): Promise<IWrappedResult<Organisation>> =>
    httpClient.patchJsonReturnJsonSafeAsync(
        getApiEndpoint({
            path: ENDPOINTS.accountUpdateWorkspace,
            params: { workspaceId },
        }),
        request,
    );
