import { AccurxEnvironment } from "@accurx/shared/dist/AppSettings";

export const externalOrgIds: Record<
    string,
    Partial<Record<AccurxEnvironment, string>>
> = {
    uhl: {
        production: "aa899257-b88f-41af-b70e-452ff0d125fb",
        staging: "85100ab8-f553-45ab-883a-2cbf6b8c40d0",
    },
};
