import React from "react";

import { FeatureName } from "@accurx/auth";
import { Text } from "@accurx/design";

import { ChainAnalyticsTracker } from "app/analytics";
import { FeatureFlag } from "app/practices/Practices.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { IsFeatureEnabled } from "shared/FeatureNameHelper";

import { PracticeLink, PracticeLinkSection } from "../PracticeLink";

export type SectionDesktopProps = {
    practiceFeatures: FeatureFlag[];
    selectedPractice: string;
};

export const SectionDesktop = ({
    practiceFeatures,
    selectedPractice,
}: SectionDesktopProps): JSX.Element | null => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Feature Flags
    const isBatchSelfBookEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.BatchSelfBook,
    );
    const isInhalerReminderEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.InhalerRemindersExperimentPage,
    );
    const isRecordViewGpOptInEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.RecordViewGpOptIn,
    );
    const isBatchAlphaEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.BatchMessagingAlpha,
    );
    const isBatchFloreyEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.BatchMessagingFlorey,
    );
    const isBatchGskEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.BatchGskFlorey,
    );
    const isAppointmentRemindersEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.AppointmentRemindersAlpha,
    );
    const isInhalerRctEnabled = IsFeatureEnabled(
        practiceFeatures,
        FeatureName.BehaviourChangeInhalerRct,
    );

    // Batch messaging
    const onlyBatchGskFloreyEnabled =
        isBatchGskEnabled &&
        isBatchFloreyEnabled === false &&
        isBatchAlphaEnabled === false;
    const batchTitle = onlyBatchGskFloreyEnabled
        ? `Batch Messaging - COPD Questionnaire${
              isBatchSelfBookEnabled ? " or Self-Book" : ""
          }`
        : "Batch Messaging";
    const batchDescription = onlyBatchGskFloreyEnabled
        ? `Send the COPD Questionnaire${
              isBatchSelfBookEnabled ? " or Self-Book" : ""
          } to multiple patients at once or view the status of previously sent Batch messages`
        : "Create messages to send out to multiple patients at once, or view the status of your previously sent Batch messages";

    // Click handlers
    const handleAnalyticsInhaler = () => {
        ChainAnalyticsTracker.trackInhalerRemindersManagePracticeLinkClicked(
            analyticsLoggedInProps,
        );
    };
    const handleAnalyticsAppointmentReminder = () => {
        ChainAnalyticsTracker.trackAppointmentReminderClicked(
            analyticsLoggedInProps,
        );
    };

    return (
        <>
            <Text as="h2" variant="subtitle">
                Accurx Desktop
            </Text>
            <div className="mb-4">
                {isInhalerReminderEnabled && (
                    <PracticeLink
                        url="inhaler-reminders"
                        title="Inhaler Reminders"
                        section={PracticeLinkSection.DESKTOP}
                        description="Set up text message reminders for your asthma and COPD patients on inhalers"
                        onClickEvent={handleAnalyticsInhaler}
                    />
                )}
                <PracticeLink
                    url="delivery-receipts"
                    title="Delivery Receipts"
                    section={PracticeLinkSection.DESKTOP}
                    description="Shows the delivery status for SMS messages sent by your team"
                />
                <PracticeLink
                    url={`/practices/${selectedPractice}/manage-templates`}
                    title="Manage Templates"
                    section={PracticeLinkSection.DESKTOP}
                    description="Allows you to create and edit SMS templates and create Florey Questionnaires for yourself and your team"
                />
                <PracticeLink
                    url="delayed-messages"
                    title="Delayed Messages"
                    section={PracticeLinkSection.DESKTOP}
                    description="Cancel upcoming messages to patients"
                />
                {isRecordViewGpOptInEnabled && (
                    <PracticeLink
                        url="record-view-requests"
                        title="Record View Requests"
                        section={PracticeLinkSection.DESKTOP}
                        description="Here you can see who has requested Record View for a patient"
                    />
                )}
                {(isBatchAlphaEnabled ||
                    isBatchFloreyEnabled ||
                    isBatchGskEnabled) && (
                    <PracticeLink
                        url="batch-messaging"
                        title={batchTitle}
                        section={PracticeLinkSection.DESKTOP}
                        description={batchDescription}
                    />
                )}
                {isAppointmentRemindersEnabled && (
                    <PracticeLink
                        url="appointment-reminder"
                        title="Appointment Reminders"
                        section={PracticeLinkSection.DESKTOP}
                        description="Allows you to set up automated appointment reminders for your practice"
                        onClickEvent={handleAnalyticsAppointmentReminder}
                    />
                )}
                {isInhalerRctEnabled && (
                    <PracticeLink
                        url="rct-patients"
                        title="Inhaler Adherence Study Patients"
                        section={PracticeLinkSection.DESKTOP}
                        description="Allows you to download the list of patients enrolled in the inhaler adherence study"
                    />
                )}
            </div>
        </>
    );
};
