import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";

export const PAGE_HEADING = "Usage Dashboard";

export const TABS: TabProps[] = [
    { text: "Team usage", tabId: "team" },
    { text: "Fragments usage", tabId: "fragments" },
];

export const DEFAULT_TAB_ID = "team";

export const TabLookup = keyBy(TABS, "tabId");
export type Tab = keyof typeof TabLookup;

export const FEEDBACK_UNAPPROVED_USER_TITLE_TEXT =
    "You are currently an unapproved user.";
export const FEEDBACK_UNAPPROVED_USER_BODY_TEXT =
    "This means you will not have access to certain Accurx features, such as viewing usage of messages and fragments. Please ask your Admin to approve you.";
