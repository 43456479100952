import { useEffect } from "react";

import { BatchMessageItemSummary } from "@accurx/api/patient-messaging";
import { FeatureName } from "@accurx/auth";
import { AddPatientsViaFileUpload } from "@accurx/batch-messaging";
import { Feedback, Text } from "@accurx/design";
import { shallowEqual, useDispatch } from "react-redux";

import { ChainAnalyticsTracker } from "app/analytics";
import { Actions } from "app/batchMessage/gp/BatchMessage.actions";
import { DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT } from "app/batchMessage/gp/BatchMessage.constant";
import { StyledLayoutWithFooter } from "app/batchMessage/gp/BatchMessage.styles";
import { BatchType } from "app/batchMessage/gp/BatchMessage.types";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { LoadingStatus } from "shared/LoadingStatus";
import { useAppSelector, useIsFeatureEnabled } from "store/hooks";

import { UploadSelfbookInfo } from "./UploadSelfbookInfo";

let start: number;

type UploadInitialProps = {
    /** Used for analytics purposes */
    isTrustFlow?: boolean;
};

export const UploadInitial = ({
    isTrustFlow = false,
}: UploadInitialProps): JSX.Element => {
    const dispatch = useDispatch();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    // Store data
    const practiceId = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const csvUploadStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.csvUploadStatus,
        shallowEqual,
    );
    const batchUploadStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.batchUploadStatus,
        shallowEqual,
    );
    const uploadDetailsStatus = useAppSelector(
        ({ batchMessage }) => batchMessage.uploadDetailsStatus,
        shallowEqual,
    );
    const selectedSlotType = useAppSelector(
        ({ batchMessage }) => batchMessage.selectedSlotType,
        shallowEqual,
    );
    const patientList = useAppSelector(
        ({ batchMessage }) => batchMessage.batchMessageDetails.items,
        shallowEqual,
    );

    const {
        batchType,
        selectedTemplateSnomedCode,
        selectedTemplateName,
        declineSettings,
    } = useAppSelector(({ batchMessage }) => batchMessage, shallowEqual);

    // FIXME: This event is incorrectly being fired on page load
    // it should be fired when the Add patient list button is clicked
    useEffect(() => {
        const hasDeclineSettingMessageChanged =
            declineSettings?.confirmationText
                ? declineSettings.confirmationText !==
                  DECLINE_SETTINGS_DEFAULT_CONFIRMATION_TEXT
                : false;
        ChainAnalyticsTracker.trackBatchAddPatientList({
            ...analyticsLoggedInProps,
            batchSlotAvailable: selectedSlotType.availability,
            batchType: batchType,
            batchInviteSnomedCode: selectedTemplateSnomedCode,
            batchDeclineSnomedCode: declineSettings?.snomedConceptId,
            templateName: selectedTemplateName,
            declineSettingMessageChanged: hasDeclineSettingMessageChanged,
        });
    }, [
        analyticsLoggedInProps,
        selectedSlotType.availability,
        batchType,
        selectedTemplateSnomedCode,
        declineSettings?.snomedConceptId,
        selectedTemplateName,
        declineSettings,
    ]);

    // Track page load
    useEffect(() => {
        ChainAnalyticsTracker.trackBatchCsvPageView({
            ...analyticsLoggedInProps,
            slotName: selectedSlotType.type,
            batchSlotAvailable: selectedSlotType.availability,
            batchType: batchType,
            isTrustFlow,
        });
    }, [analyticsLoggedInProps, selectedSlotType, isTrustFlow, batchType]);

    // Track successfull/failure upload
    useEffect(() => {
        if (
            csvUploadStatus === LoadingStatus.Loaded &&
            uploadDetailsStatus === LoadingStatus.Loaded
        ) {
            const end = Date.now();
            const uploadTime = Math.floor((end - start) / 1000) + " seconds";
            const numOfTextablePatients = patientList.filter(
                (item: BatchMessageItemSummary) => item.isTextable,
            ).length;

            // Old tracking: App Insight
            ChainAnalyticsTracker.trackBatchCsvUploadSucceeded({
                ...analyticsLoggedInProps,
                patientsUploaded: numOfTextablePatients,
                timeTaken: uploadTime,
            });

            ChainAnalyticsTracker.trackBatchCsvReviewPageView({
                ...analyticsLoggedInProps,
                countTotal: patientList.length,
                countNotAccepted: patientList.filter((x) => !x.isTextable)
                    .length,
                countAccepted: numOfTextablePatients,
                uploadTime: uploadTime,
                isTrustFlow,
            });
        }
    }, [
        analyticsLoggedInProps,
        csvUploadStatus,
        uploadDetailsStatus,
        practiceId,
        patientList,
        isTrustFlow,
    ]);

    const selfBookWithNhsAppEnabled =
        useIsFeatureEnabled(FeatureName.NhsAppMessagingBatchSelfBook) &&
        batchType === BatchType.SELFBOOK;

    const messageTypeHasAdditionalContactMethods = [
        BatchType.FLOREY,
        BatchType.MESSAGE,
    ].includes(batchType);

    return (
        <StyledLayoutWithFooter>
            <div className="col-12 col-lg-6 offset-lg-3 px-0">
                {batchUploadStatus === LoadingStatus.Failed && (
                    <Feedback
                        colour="error"
                        props={{ className: "mb-3" }}
                        title="Something went wrong while uploading your patient list content"
                    >
                        <Text skinny>
                            Please check our guides below to learn how to
                            correctly export your list
                        </Text>
                    </Feedback>
                )}
                <UploadSelfbookInfo />

                <AddPatientsViaFileUpload
                    isEmailEnabled={messageTypeHasAdditionalContactMethods}
                    isNHSAppEnabled={
                        messageTypeHasAdditionalContactMethods ||
                        selfBookWithNhsAppEnabled
                    }
                    isTrustFlow={false}
                    onUploadStart={() => {
                        dispatch({ type: Actions.UPLOAD_BATCH_PATIENT_LIST });
                    }}
                    onUploadSuccess={(batchMessageId) => {
                        dispatch({
                            type: Actions.UPLOAD_BATCH_PATIENT_LIST_SUCCESS,
                            response: { id: batchMessageId },
                        });
                    }}
                />
            </div>
        </StyledLayoutWithFooter>
    );
};
