import { useCurrentWorkspace } from "@accurx/auth";
import { NativeTransportError, useNativeTransport } from "@accurx/native";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
    FormStateEMRPatientDetails,
    FormStateEmis,
} from "domains/inbox/components/PatientSearch/components/forms/types";
import { searchPatientsQuery } from "domains/inbox/native/queries";
import { PatientsSearchResult } from "domains/inbox/schemas/PatientsSearchResultSchema";

type Variables = Partial<FormStateEmis | FormStateEMRPatientDetails>;

export const useSearchForPatientInEmrMutation = (
    options?: UseMutationOptions<
        PatientsSearchResult,
        NativeTransportError,
        Variables
    >,
) => {
    const transport = useNativeTransport();
    const workspaceId = useCurrentWorkspace().orgId;

    return useMutation({
        mutationKey: ["SearchForPatientInEmr"],
        mutationFn: async (variables: Variables) => {
            if (!transport)
                throw new NativeTransportError(
                    "SearchPatientsQuery: Called with no native transport",
                );

            return searchPatientsQuery({ transport, workspaceId }, variables);
        },
        ...options,
    });
};
