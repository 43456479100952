export type AppOrigin = "Compose" | "UnifiedInbox";

export const useSelfbookConfigurationTrackingFields = (
    appOrigin: AppOrigin,
): {
    accessType: "DesktopApp" | "Browser";
    appOrigin: string;
} => {
    const isWebView = () => !!window.chrome?.webview;
    return {
        accessType: isWebView() ? "DesktopApp" : "Browser",
        appOrigin: appOrigin,
    };
};
