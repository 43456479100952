import { useCallback, useEffect, useState } from "react";

import {
    SearchForPatientByDemographicsRequest,
    SearchForPatientByNhsNumberRequest,
} from "@accurx/api/portal";
import { useDispatch } from "react-redux";

import FlemingApi from "api/FlemingApiClient";
import { FlemingAnalyticsTracker } from "app/analytics";
import { actionCreators as searchForPatientActions } from "app/searchForPatient/SearchForPatientActions";
import { SearchForPatientForm } from "app/searchForPatient/searchForPatientForm/SearchForPatientForm";
import { SearchFormErrors } from "app/searchForPatient/searchForPatientForm/SearchFormConstants";
import { actionCreators as selectProductActions } from "app/selectProduct/SelectProductActions";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { PatientHelper } from "shared/PatientHelper";

export const MessageGpPatientSearchCard = ({
    onCompleteSearch,
}: {
    onCompleteSearch: () => void;
}) => {
    const dispatch = useDispatch();

    const [searchServerError, setSearchServerError] = useState("");

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const searchForPatientByNhsNumber = useCallback(
        async (patientSearchInfo: SearchForPatientByNhsNumberRequest) => {
            dispatch(searchForPatientActions.searchForPatientStarted());

            const res = await FlemingApi.searchForPatientByNhsNumber(
                patientSearchInfo.organisationId as number,
                patientSearchInfo.nhsNumber,
                patientSearchInfo.dateOfBirthYear,
                patientSearchInfo.dateOfBirthMonth,
                patientSearchInfo.dateOfBirthDay,
            );

            // Save to recent search in the background, but don't wait for call to complete
            if (
                PatientHelper.getPatient(res) &&
                !PatientHelper.isDemoPatient(patientSearchInfo.nhsNumber)
            ) {
                FlemingApi.savePatientToRecentSearches({
                    organisationId: patientSearchInfo.organisationId as number,
                    patientToken: res.searchedResult?.patientToken || "",
                }).catch((e) => console.log(e));
            }

            dispatch(searchForPatientActions.searchForPatientFinished(res));

            if (!PatientHelper.getPatient(res)) {
                if (res.searchedResult?.matchFound === false) {
                    setSearchServerError(SearchFormErrors.NoMatchFound);
                } else {
                    setSearchServerError(SearchFormErrors.Other);
                }
            } else {
                onCompleteSearch();
            }

            FlemingAnalyticsTracker.trackSearchForPatientFormSubmit({
                ...analyticsLoggedInProps,
                patientSearchMethodType: "SearchByNHSNumber",
                hasError: !res.searched,
                patientSearchSuccess: !!res.searchedResult?.matchFound,
                isTestPatient: PatientHelper.isDemoPatient(
                    patientSearchInfo.nhsNumber,
                ),
            });
        },
        [analyticsLoggedInProps, dispatch, onCompleteSearch],
    );

    const searchForPatientByDemographics = useCallback(
        async (patientSearchInfo: SearchForPatientByDemographicsRequest) => {
            dispatch(searchForPatientActions.searchForPatientStarted());

            const { result, success } =
                await FlemingApi.searchForPatientByDemographics(
                    patientSearchInfo,
                );

            // Save to recent search in the background, but don't wait for call to complete
            if (PatientHelper.getPatient(result)) {
                FlemingApi.savePatientToRecentSearches({
                    organisationId: patientSearchInfo.organisationId as number,
                    patientToken: result?.searchedResult?.patientToken || "",
                }).catch((e) => console.log(e));
            }

            result &&
                dispatch(
                    searchForPatientActions.searchForPatientFinished(result),
                );

            if (!PatientHelper.getPatient(result)) {
                if (success && result?.searchedResult?.matchFound === false) {
                    setSearchServerError(SearchFormErrors.NoMatchFound);
                } else {
                    setSearchServerError(SearchFormErrors.Other);
                }
            } else {
                onCompleteSearch();
            }

            FlemingAnalyticsTracker.trackSearchForPatientFormSubmit({
                ...analyticsLoggedInProps,
                patientSearchMethodType: "SearchByName",
                hasError: !result?.searched,
                patientSearchSuccess: !!result?.searchedResult?.matchFound,
                // Users can't use test patient with search by name form
                isTestPatient: false,
            });
        },
        [analyticsLoggedInProps, dispatch, onCompleteSearch],
    );

    // Set the origin of the patient selection to search for patient route
    useEffect(() => {
        dispatch(
            selectProductActions.setPatientSearchOrigin(
                "ClinicianConversation",
            ),
        );
    }, [dispatch]);

    const resetServerErrors = (): void => {
        setSearchServerError("");
    };

    return (
        <SearchForPatientForm
            onSubmitNhsNumberForm={searchForPatientByNhsNumber}
            onSubmitNameForm={searchForPatientByDemographics}
            serverError={searchServerError}
            resetServerError={resetServerErrors}
            showRecentSearches={false}
            showTestPatientOption={true}
        />
    );
};
