/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useState } from "react";

import { Card, Icon, Text } from "@accurx/design";

const FEEDBACK_FORM_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLScVK3BJaArPY3Q5BfypBYXPg83QDIWn1BctRYATrFvilIezGg/viewform?embedded=true";

const ConfirmCodeProblemFeedback = (): JSX.Element => {
    const [hasClickedFeedback, setHasClickedFeedback] = useState(false);

    const handleOnClick = (): void =>
        setHasClickedFeedback(!hasClickedFeedback);

    return (
        <Card
            spacing={2}
            props={{
                onClick: handleOnClick,
                role: "button",
            }}
        >
            <div className="d-flex justify-content-between">
                <Text className={"mb-0"}>
                    Problems getting the code? Click here to let us know
                </Text>
                <Icon
                    theme="Line"
                    name="Arrow"
                    size={3}
                    rotation={hasClickedFeedback ? "up" : "down"}
                />
            </div>
            {hasClickedFeedback && (
                <iframe
                    style={{
                        width: "100%",
                        height: "60rem",
                        border: "none",
                    }}
                    title="Feedback form"
                    src={FEEDBACK_FORM_URL}
                >
                    Loading...
                </iframe>
            )}
        </Card>
    );
};

export default ConfirmCodeProblemFeedback;
