import { useCurrentUser } from "@accurx/auth";
import { Flex } from "@accurx/design";

import { StyledSectionHeading } from "../WorkspaceAccessPage.styles";
import { sortWorkspaces } from "../helpers";
import { WorkspaceMemberCard } from "./WorkspaceMemberCard/WorkspaceMemberCard";

export const JoinedWorkspaceSection = () => {
    const {
        user: { organisations: allWorkspaces },
    } = useCurrentUser();

    const sortedOrganisations = sortWorkspaces(allWorkspaces);

    return (
        <div>
            <StyledSectionHeading skinny>
                Your workspace access · {allWorkspaces.length}
            </StyledSectionHeading>
            <Flex flexDirection="column" gap="1">
                {sortedOrganisations.map((workspace) => {
                    return (
                        <WorkspaceMemberCard
                            workspace={workspace}
                            key={workspace.orgId}
                        />
                    );
                })}
            </Flex>
        </div>
    );
};
