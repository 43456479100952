import { Button, Text, Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledFlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledSubHeaderDiv = styled(StyledFlexDiv)`
    justify-content: space-between;
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledTryNowDiv = styled(StyledFlexDiv)`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledText = styled(Text)`
    margin-left: ${Tokens.SIZES[1]};
    margin-top: 0;
    margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
    margin-left: ${Tokens.SIZES[1]};
`;
