import React from "react";

import { Feedback, Text } from "@accurx/design";

import { SkeletonPreview } from "app/sharedComponents/loadingSkeleton/SkeletonText";

import {
    StyledList,
    StyledUserListContainer,
} from "./UserManagementPage.styles";

type UserListProps = {
    emptyMessage: string;
    isLoadingUsers: boolean;
    children: React.ReactNode[];
};

export const UserList = (props: UserListProps): JSX.Element => {
    return (
        <StyledUserListContainer>
            {props.isLoadingUsers ? (
                <SkeletonPreview charCount={100} />
            ) : (
                <>
                    {props.children?.length ? (
                        <StyledList>{props.children}</StyledList>
                    ) : (
                        <Feedback colour="information" iconName="None">
                            <Text skinny>{props.emptyMessage}</Text>
                        </Feedback>
                    )}
                </>
            )}
        </StyledUserListContainer>
    );
};
