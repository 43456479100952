import React, { useEffect } from "react";

import { FeatureName } from "@accurx/auth";
import { Link, Text } from "@accurx/design";
import { Log } from "@accurx/shared";
import { i } from "images";

type NotFoundProps = {
    /*
     * An object containing information about missing feature flags (used for sentry reporting)
     */
    missingFeatureFlags?: FeatureName[];
};

const formatMissingFlagsTagsForSentry = (flags?: FeatureName[]) => {
    const sentryTags: Record<string, string> = {};
    flags?.forEach((flag, index) => {
        sentryTags[`missingfeatureflag.${index}`] = flag;
    });

    return sentryTags;
};

const NotFound = ({ missingFeatureFlags }: NotFoundProps): JSX.Element => {
    useEffect(() => {
        Log.error("[Page not found]", {
            tags: formatMissingFlagsTagsForSentry(missingFeatureFlags),
        });
    }, [missingFeatureFlags]);

    return (
        <div
            className="text-center"
            style={{ marginTop: "5em", marginBottom: "5em" }}
        >
            <Text variant="title" as="h1">
                Page not found
            </Text>
            <img
                alt="404"
                src={i("errors/not-found.svg")}
                className="mt-3 mb-5"
            />
            <Text variant="body" props={{ className: "mb-4" }}>
                If you typed the web address, check it is correct.
                <br />
                If you pasted the web address, check you copied the entire
                address.
            </Text>
            <Text variant="body" props={{ className: "mb-5" }}>
                If the web address is correct or you selected a link or button,
                contact our support team to speak to someone about getting
                access.
                <br />
                You can chat with us through{" "}
                <Link
                    href="https://support.accurx.com"
                    openInNewTab={true}
                    text="live chat"
                />{" "}
                or email us on{" "}
                <Link
                    text="support@accurx.com"
                    href="mailto:support@accurx.com"
                />
            </Text>
            <Link
                href="https://www.freepik.com/free-vector/hand-drawn-404-error_1587371.htm"
                openInNewTab={true}
                text="Image designed by Freepik"
            />
        </div>
    );
};

export default NotFound;
