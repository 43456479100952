import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { generatePath } from "react-router";

/**
 * Given a path pattern and a set of params, which can be a mix of
 * path params and query string params, constructs a url by
 * figuring out which params are path params and which are query
 * params.
 */
export const generateInboxUrl = <
    T extends Record<string, string | number | boolean>,
>(
    path: string,
    params: T,
): string => {
    const pathKeys = [...path.matchAll(/:(\w+)/g)].map((match) => match[1]);
    const pathParams = pick(params, pathKeys);
    const queryParams = mapValues(omit(params, pathKeys), (v) => v.toString());

    return isEmpty(queryParams)
        ? generatePath(path, pathParams)
        : generatePath(path, pathParams) +
              "?" +
              new URLSearchParams(queryParams).toString();
};

/**
 * Given a path pattern and a set of params, which can be a mix of
 * path params and query string params, constructs an object that consists of
 * a pathname and a search keys.
 */
export const generateLocationObject = <
    T extends Record<string, string | number | boolean>,
>(
    path: string,
    params: T,
): {
    pathname: string;
    search?: string;
} => {
    const pathKeys = [...path.matchAll(/:(\w+)/g)].map((match) => match[1]);
    const pathParams = pick(params, pathKeys);
    const queryParams = mapValues(omit(params, pathKeys), (v) => v.toString());

    return {
        pathname: generatePath(path, pathParams),
        search: `?${new URLSearchParams(queryParams).toString()}`,
    };
};
