import { HTMLAttributes } from "react";

import * as UI from "@accurx/design";
import {
    Accordion,
    Level2AccordionHeaderHeight,
    applyFocusStyle,
    applyHoverStyle,
} from "@accurx/navigation";
import { UnreadCount } from "domains/inbox/components/UnreadCount/UnreadCount";
import styled from "styled-components";

const { COLOURS, SIZES } = UI.Tokens;

const ToggleButton = styled.button`
    appearance: none;
    padding: ${SIZES[1]};
    margin: 0;
    width: 100%;
    background: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    gap: ${SIZES[0.5]};
    text-align: left;

    font-size: 1rem;
    text-decoration: none !important;
    color: ${COLOURS.greyscale.white} !important;

    // For the accordion behaviour we need to set a specific height for the
    // accordion header elements. It's very important that this header item fits
    // exactly within the fixed level 2 accordion header height. We take 8px
    // from that value to account for the 4px focus ring above and below.
    height: calc(${Level2AccordionHeaderHeight} - 8px);
    max-height: calc(${Level2AccordionHeaderHeight} - 8px);

    &:focus {
        ${applyFocusStyle}
    }

    &:hover {
        ${applyHoverStyle}
    }
`;

const Text = styled(UI.Item)`
    display: flex;
    align-items: center;
    gap: ${SIZES[0.5]};
`;

type FolderToggleProps = HTMLAttributes<HTMLButtonElement> & {
    text: string;
    isOpen?: boolean;
    unreadCount?: number;
};

export const FolderToggle = ({
    text,
    isOpen,
    unreadCount,
    ...buttonProps
}: FolderToggleProps) => {
    return (
        <Accordion.Trigger asChild>
            <ToggleButton {...buttonProps}>
                <Text flex="1">
                    <span>{text}</span>
                    <UI.Icon
                        name="Arrow"
                        colour="white"
                        size={2}
                        rotation={isOpen ? "down" : "right"}
                    />
                </Text>
                <UI.Item>
                    {!!unreadCount && !isOpen && (
                        <UnreadCount count={unreadCount} />
                    )}
                </UI.Item>
            </ToggleButton>
        </Accordion.Trigger>
    );
};
