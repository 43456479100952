import { TabProps } from "@accurx/design";
import keyBy from "lodash/keyBy";
import { generatePath } from "react-router";

import { ROUTES_ORGS } from "shared/Routes";

import { AccumailTemplateType, CreatableTemplate } from "../templates.types";

export type TabId = "my-templates" | "workspace-templates" | "accurx-templates";

export const getTabs: (orgName: string) => (TabProps & { tabId: TabId })[] = (
    orgName,
) => [
    { text: "My templates", tabId: "my-templates" },
    { text: `${orgName} templates`, tabId: "workspace-templates" },
    { text: "Accurx templates", tabId: "accurx-templates" },
];

export const getTabLookup = (orgName: string) =>
    keyBy(getTabs(orgName), "tabId");

export const getManageTemplatesUrl = (
    workspaceId: number,
    templateType?: AccumailTemplateType,
) =>
    generatePath(ROUTES_ORGS.accumailManageTemplates, {
        orgId: workspaceId,
        tab:
            templateType === undefined
                ? undefined
                : templateTypeToTab(templateType),
    });

const templateTypeToTab: (templateType: AccumailTemplateType) => TabId = (
    templateType,
) => {
    switch (templateType) {
        case "UserTemplate":
            return "my-templates";
        case "GlobalTemplate":
            return "accurx-templates";
        case "WorkspaceTemplate":
            return "workspace-templates";
    }
};

export type CreateTemplatePageParam = "user" | "workspace";

export const templateTypeToCreateParam: (
    templateType: CreatableTemplate,
) => CreateTemplatePageParam = (templateType) => {
    switch (templateType) {
        case "UserTemplate":
            return "user";
        case "WorkspaceTemplate":
            return "workspace";
    }
};

export const createParamToTemplateType: (
    templateType: CreateTemplatePageParam,
) => CreatableTemplate = (templateType) => {
    switch (templateType) {
        case "user":
            return "UserTemplate";
        case "workspace":
            return "WorkspaceTemplate";
    }
};
