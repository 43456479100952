import React from "react";

import { Log } from "@accurx/shared";
import { Redirect, useHistory } from "react-router";

import { ROUTES } from "shared/Routes";
import { useCurrentOrgId } from "store/hooks";

export const PatientProfileRedirect = () => {
    const history = useHistory();

    const currentOrgId = useCurrentOrgId();

    if (!currentOrgId) {
        Log.warn("Org ID from Redux store is null. Redirecting to '/'");

        return <Redirect to={ROUTES.home} />;
    }

    return <Redirect to={`/w/${currentOrgId}${history.location.pathname}`} />;
};
