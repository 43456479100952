import { Card, Link, Text } from "@accurx/design";
import { useAccurxWebTitle } from "@accurx/navigation";
import { SharedUrls } from "@accurx/shared";

type SettingsTermsAndConditionsProps = {
    hideHeader?: boolean;
};

export const SettingsTermsAndConditions = ({
    hideHeader,
}: SettingsTermsAndConditionsProps) => {
    useAccurxWebTitle("Review terms and conditions");

    return (
        <Card
            header={
                !hideHeader ? (
                    <Text variant="subtitle" as="h2" skinny>
                        Terms and Conditions
                    </Text>
                ) : undefined
            }
        >
            <Text skinny>
                {"Your use of Accurx is subject to the "}
                <Link href={SharedUrls.TermsAndConditions} openInNewTab>
                    Terms and Conditions
                    <Link.Icon />
                </Link>
                {" on our website. Please read these carefully."}
            </Text>
        </Card>
    );
};
