import * as UI from "@accurx/design";
import { VisuallyHiddenUnreadCount } from "domains/inbox/components/VisuallyHiddenUnreadCount/VisuallyHiddenUnreadCount";
import { colors } from "domains/inbox/theme";
import styled, { keyframes } from "styled-components";

export type UnreadCountProps = { count: number };

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.span`
    display: block;
    flex: 0;
    padding: 0 ${UI.Tokens.SIZES[0.5]};
    min-width: 1.5rem;
    text-align: center;
    border-radius: 0.75rem;
    font-feature-settings: "tnum";
    color: ${colors.darkblue};
    background-color: ${colors.pink};
    animation: ${fadein} 100ms ease-in;
    span {
        font-size: 0.875rem;
        display: block;
    }
`;

export const UnreadCount = ({ count }: UnreadCountProps) => {
    return (
        <Container>
            <VisuallyHiddenUnreadCount count={count} />
            <UI.Text
                variant="label"
                skinny
                as="span"
                props={{ "aria-hidden": true }}
            >
                {count}
            </UI.Text>
        </Container>
    );
};
