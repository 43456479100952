import { ReactNode } from "react";

import { QUICK_VIEW_CONTAINER_ID } from "@accurx/quick-view";

import {
    StyledMainContentWrapper,
    StyledPageWrapper,
    StyledQuickviewWrapper,
} from "./Page.styles";

export const Page = ({
    children,
    theme = "white",
}: {
    children: ReactNode;
    theme?: "white" | "cloud";
}) => {
    return (
        <StyledPageWrapper $theme={theme}>
            <StyledMainContentWrapper>{children}</StyledMainContentWrapper>
            <StyledQuickviewWrapper id={QUICK_VIEW_CONTAINER_ID} />
        </StyledPageWrapper>
    );
};
