import { ReactNode } from "react";

import { Flex, Icon, Item, Tokens } from "@accurx/design";
import styled from "styled-components";

export const TickList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES[2]};
    padding: 0;
    margin: 0;
    list-style: none;
`;

interface TickListItemProps {
    children: ReactNode;
    haloColour?: "green" | "yellow";
}

export const TickListItem = ({
    children,
    haloColour = "green",
}: TickListItemProps) => {
    return (
        <Flex as="li" gap="2">
            <Item>
                <Icon name="Tick" halo={{ colour: haloColour }} size={3} />
            </Item>
            <Item>{children}</Item>
        </Flex>
    );
};
