import { SkeletonLoader } from "domains/inbox/components/SkeletonLoader/SkeletonLoader";

import { StyledListItem } from "./ItemAttachment.styles";

export const SkeletonAttachment = () => {
    return (
        <StyledListItem>
            <SkeletonLoader width="120px" />
            <SkeletonLoader width="98px" height="32px" />
        </StyledListItem>
    );
};
