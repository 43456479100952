import React, { useEffect } from "react";

import { Accordion, Card, Ds, Spinner, Text } from "@accurx/design";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { ROUTES_CHAIN } from "shared/Routes";

import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import {
    getVaccineDeliveryDetails,
    resetPatientInvitesForInviteToSecond,
} from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccineAllInvitesInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { VaccineDeliveryComposePatientTable } from "./VaccineDeliveryComposePatientTable";
import { getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecond } from "./VaccineDeliveryUploadAndComposeHelpers";

export const VaccineDeliveryUploadSecond = (): JSX.Element => {
    const practiceId = useSelector(
        ({ practices }: ApplicationState) => practices.selectedPractice,
    );

    const getDetailsStatus = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.getDetailsStatus ||
            VaccineAllInvitesInitialState.getDetailsStatus,
        shallowEqual,
    );
    const vaccineDeliveryId = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryId ||
            VaccineAllInvitesInitialState.vaccineDeliveryId,
        shallowEqual,
    );
    const patientList = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery?.vaccineDeliveryDetails.items ||
            VaccineAllInvitesInitialState.vaccineDeliveryDetails.items,
        shallowEqual,
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (practiceId && vaccineDeliveryId) {
            dispatch(getVaccineDeliveryDetails(practiceId, vaccineDeliveryId));
            dispatch(resetPatientInvitesForInviteToSecond());
        }
    }, [dispatch, practiceId, vaccineDeliveryId]); // only run this on the first load of the page

    const secondJabNotInvitedForSecondPatients =
        getPatientsWhoNeedSecondJabWhoHaveNotBeenInvitedForSecond(patientList);

    return (
        <div>
            <div className="text-center">
                <VaccineDeliveryHeader
                    stepNumber={5}
                    totalSteps={6}
                    title={"Review awaiting 2nd vaccination"}
                />
            </div>
            <div className="row mb-5">
                <div className="col-8 offset-md-2">
                    {getDetailsStatus === UpdatingStatus.Loading && <Spinner />}
                    {getDetailsStatus === UpdatingStatus.Loaded && (
                        <>
                            <Card
                                spacing={2}
                                header={
                                    <>
                                        <Text variant="label" as="span" skinny>
                                            {
                                                secondJabNotInvitedForSecondPatients.length
                                            }{" "}
                                            patients{" "}
                                        </Text>
                                        <Text as="span" skinny>
                                            awaiting 2nd vaccination
                                        </Text>
                                    </>
                                }
                            >
                                <>
                                    <Text>
                                        You'll be able to monitor and invite
                                        these patients later from your patient
                                        list
                                    </Text>
                                    <Accordion
                                        header={
                                            secondJabNotInvitedForSecondPatients.length +
                                            " patients"
                                        }
                                        icon={{ name: "Team", colour: "stone" }}
                                        extra={
                                            <Ds.Badge color="yellow">
                                                Will need to be invited later
                                            </Ds.Badge>
                                        }
                                        className="mt-4"
                                    >
                                        <VaccineDeliveryComposePatientTable
                                            filteredList={
                                                secondJabNotInvitedForSecondPatients
                                            }
                                            testIdPrefix="secondJab"
                                            showNims={false}
                                        />
                                    </Accordion>

                                    <Accordion
                                        header="What do I need to do next for these patients"
                                        className="mt-2"
                                    >
                                        <Text>
                                            These patients will be added to your
                                            patient list under "Awaiting Invite
                                            2nd"
                                        </Text>
                                        <Text>
                                            You will be able to monitor and
                                            invite these patients later from the
                                            patient list
                                        </Text>
                                    </Accordion>
                                </>
                            </Card>
                        </>
                    )}
                    {getDetailsStatus === UpdatingStatus.Failed && (
                        <Text variant="title" colour="red">
                            There was an error loading the upload details,
                            please click below to return to the patient list and
                            manage invite from there
                        </Text>
                    )}
                </div>
            </div>

            <StepsFooter
                forwardText={
                    getDetailsStatus === UpdatingStatus.Failed
                        ? "Back to all invites list"
                        : "Add patients to accuBook"
                }
                forwardLink={
                    getDetailsStatus === UpdatingStatus.Failed
                        ? ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                              ":orgId",
                              practiceId,
                          )
                        : ROUTES_CHAIN.practicesVaccineInvitesReviewInvites.replace(
                              ":orgId",
                              practiceId,
                          )
                }
                disabled={getDetailsStatus === UpdatingStatus.Loading}
            />
        </div>
    );
};

export default VaccineDeliveryUploadSecond;
