import { Tokens } from "@accurx/design";
import styled from "styled-components";

export const StyledTabListWrapper = styled.div`
    box-shadow: inset 0px -4px 0px 0px ${Tokens.COLOURS.greyscale.silver};
`;

export const StyledUserManagementCardContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    gap: ${Tokens.SIZES[1.5]};

    @media (min-width: ${Tokens.BREAKPOINTS.sm}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export const StyledUserListContainer = styled.div`
    margin-bottom: ${Tokens.SIZES[3]};
`;

export const StyledUserDetails = styled.div`
    display: flex;
    gap: ${Tokens.SIZES[1.5]};

    flex: 4 0 0;
`;

export const StyledUserNameAndEmail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledList = styled.ul`
    list-style: none;
    padding: 0;
`;

export const StyledUserManagementCardContainer = styled.li`
    margin-bottom: ${Tokens.SIZES[1]};
`;

export const StyledErrorCardContainer = styled.div`
    margin-bottom: ${Tokens.SIZES[2]};
`;

export const StyledSearchInputContainer = styled.div`
    width: 306px;
    margin-bottom: ${Tokens.SIZES[1.5]};
`;
