import { Ds, Flex, Text } from "@accurx/design";
import styled from "styled-components";

const StyledCenteredText = styled(Text)`
    text-align: center;
`;

type BatchUploadDemographicsProgressProps = {
    percentage: number;
};

export const BatchUploadDemographicsProgress = ({
    percentage,
}: BatchUploadDemographicsProgressProps) => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="3"
        >
            <Ds.Spinner size="large" />
            <div>
                <StyledCenteredText variant="label" skinny>
                    {percentage.toFixed()} %
                </StyledCenteredText>
                <StyledCenteredText skinny>
                    Reviewing patient information
                </StyledCenteredText>
            </div>
        </Flex>
    );
};
