import { useState } from "react";

import { usePatient } from "@accurx/concierge/hooks/data/usePatient";
import { Conversation } from "@accurx/concierge/schemas/ConversationSchema";
import { usePatientRecordAttachmentsQuery } from "@accurx/native";
import { useToggle } from "@accurx/navigation";
import { useConversationAddNoteSelectAnalytics } from "domains/inbox/hooks/util";
import { useShouldPrefetchPatientRecordAttachments } from "domains/inbox/hooks/util/useShouldPrefetchPatientRecordAttachments";

import { MessageActionsBar } from "../MessageActionsBar/MessageActionsBar";
import { PatientNotFoundReply } from "../Reply/PatientNotFoundReply";
import { Reply } from "../Reply/Reply";

type MessageActionsTypes = {
    conversation: Conversation;
    onClickSearchForPatient: () => void;
    setSentNoteStatus: (status: string | null) => void;
};

export const MessageActions = ({
    conversation,
    onClickSearchForPatient,
    setSentNoteStatus,
}: MessageActionsTypes) => {
    const addNoteSelectAnalytics =
        useConversationAddNoteSelectAnalytics(conversation);
    const { toggle: baseToggleReply, isOpen: isReplyOpen } = useToggle();

    const [hasReplyEverOpened, setHasReplyEverOpened] = useState(false);

    const patient = usePatient({
        patientId: conversation.regardingPatientId,
    });
    const [selectedMessageAction, setSelectedMessageAction] = useState<
        "reply" | "note"
    >("reply");

    // When the conversation opens we want to prefetch patient record
    // attachments if the medical record supports it because that request can
    // potentially take a looooong time so doing it early increases perceived
    // performance.
    const shouldPrefetchPatientRecordAttachments =
        useShouldPrefetchPatientRecordAttachments(patient?.externalIds ?? []);
    usePatientRecordAttachmentsQuery(
        {
            patientExternalIds: patient?.externalIds ?? [],
        },
        {
            enabled:
                !!patient &&
                hasReplyEverOpened &&
                shouldPrefetchPatientRecordAttachments,
            refetchOnMount: true,
        },
    );

    const toggleReply = () => {
        baseToggleReply();
        setHasReplyEverOpened(true);
        setSelectedMessageAction("reply");
    };

    const toggleNote = () => {
        setSelectedMessageAction("note");
        baseToggleReply();
        setHasReplyEverOpened(true);

        addNoteSelectAnalytics();
    };

    if (isReplyOpen) {
        return patient ? (
            <Reply
                patient={patient}
                conversation={conversation}
                toggleReply={() => toggleReply()}
                selectedMessageAction={selectedMessageAction}
                setSentNoteStatus={setSentNoteStatus}
            />
        ) : (
            <PatientNotFoundReply
                onClickSearchForPatient={onClickSearchForPatient}
                conversation={conversation}
                close={() => toggleNote()}
                selectedMessageAction={selectedMessageAction}
                setSentNoteStatus={setSentNoteStatus}
            />
        );
    }

    return (
        <MessageActionsBar
            isComposing={false}
            isComposingNote={false}
            toggleReply={toggleReply}
            conversation={conversation}
            toggleNote={toggleNote}
        />
    );
};
