import { api } from "domains/concierge/internal/api/ticket";
import {
    useConciergeDispatch,
    useConciergeMeta,
} from "domains/concierge/internal/context";
import { actions } from "domains/concierge/internal/store";
import {
    UseMutationOptions,
    useMutation,
} from "domains/concierge/internal/util/useMutation";
import { Conversation } from "domains/concierge/types";

type Variables = {
    conversation: Conversation;
};

export const useUnunmatchTicketFromPatientMutation = (
    options?: UseMutationOptions<Variables>,
) => {
    const dispatch = useConciergeDispatch();
    const { workspaceId } = useConciergeMeta();

    return useMutation(
        "UnunmatchTicketFromPatient",
        async ({ conversation }) => {
            if (conversation.source.system !== "Ticket") {
                throw new Error(
                    `UnmatchTicketFromPatientMutation does not support ${conversation.source.system} conversations`,
                );
            }

            const { updates } = await api.unmatchTicketFromPatient({
                workspaceId,
                latestToken: conversation.latestToken,
                ticketIdentity: conversation.source.ticketIdentity,
            });

            return dispatch(
                actions.processUpdates({
                    source: "Mutation:UnunmatchTicketFromPatient",
                    ...updates,
                }),
            );
        },
        options,
    );
};
