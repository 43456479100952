import { useEffect, useState } from "react";

import { Card, Ds, Feedback, Icon, Tabs, Text } from "@accurx/design";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    clearTourMode,
    getFeatureNameForTour,
    isTestPatientTour,
    isTourEnded,
} from "app/onboarding/Onboarding.helper";
import { CardSpacer } from "app/recordView/medicalRecord/CardSpacer";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { useAppSelector, useCurrentUserId } from "store/hooks";

import { RecentSearchesWithSearchBar } from "../recentSearches/RecentSearchesWithSearchBar";
import { TwoFactorGate } from "../recentSearches/TwoFactorGate";
import {
    SearchForPatientByNHSNumber,
    SearchFormData,
    SubmitNhsNumberSearchForm,
} from "./SearchForPatientByNHSNumber";
import {
    SearchForPatientByName,
    SubmitNameSearchForm,
} from "./SearchForPatientByName";
import {
    StyledSearchBodySpacing,
    StyledSearchHeaderDiv,
    StyledTestPatientSwitchContainer,
} from "./SearchForPatientForm.styles";
import {
    demoPatientData,
    searchForPatientFormIds,
} from "./SearchFormConstants";

export type PatientSearchMethodType = "SearchByName" | "SearchByNHSNumber";

const TabIds: {
    [key: string]: PatientSearchMethodType;
} = {
    SearchByNHSNumber: "SearchByNHSNumber",
    SearchByName: "SearchByName",
};

type TabId = PatientSearchMethodType;

export type SearchForPatientFormProps = {
    onSubmitNhsNumberForm: SubmitNhsNumberSearchForm;
    onSubmitNameForm: SubmitNameSearchForm;
    initialNhsNumber?: string;
    serverError: string;
    resetServerError: () => void;
    tourMode?: string;
    showRecentSearches?: boolean;
    showTestPatientOption?: boolean;
};

export const SearchForPatientForm = ({
    onSubmitNhsNumberForm,
    onSubmitNameForm,
    initialNhsNumber = "",
    serverError = "",
    resetServerError,
    tourMode = undefined,
    showRecentSearches = true,
    showTestPatientOption = true,
}: SearchForPatientFormProps): JSX.Element => {
    // State selectors
    const isSearching = useAppSelector(
        ({ searchForPatient, patientLists }) =>
            searchForPatient.isSearching ||
            patientLists.currentListPatientAdding,
    );

    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();

    const [initialFormData, setInitialFormData] = useState<SearchFormData>();
    // Automatically select "use Test Patient" if this is a test patient tour
    const accuRxUserId = useCurrentUserId() || "";
    const testPatientTour = isTourEnded(accuRxUserId)
        ? false
        : isTestPatientTour(tourMode || "");
    const [isTestPatientSelected, setIsTestPatientSelected] =
        useState(testPatientTour);
    const [featureTourName, setFeatureTourName] = useState(
        getFeatureNameForTour(tourMode),
    );
    const [selectedTabId, setSelectedTabId] = useState<TabId>(
        TabIds.SearchByNHSNumber,
    );

    useEffect(() => {
        if (initialNhsNumber) {
            // This is necessary since useState above can't be initialised directly via prop
            setInitialFormData({
                nhsNumber: initialNhsNumber,
            });
        }
    }, [initialNhsNumber]);

    const handleTabChange = (tabId: TabId) => {
        setSelectedTabId(tabId);
        resetServerError();

        if (tabId !== TabIds.SearchByNHSNumber) {
            setIsTestPatientSelected(false);
        }

        FlemingAnalyticsTracker.trackSearchForPatientFormTabClick({
            ...analyticsLoggedInProps,
            patientSearchMethodType: tabId,
        });
    };
    const handleTestPatientToggle = (checked: boolean): void => {
        if (serverError) {
            resetServerError();
        }

        if (checked) {
            setSelectedTabId(TabIds.SearchByNHSNumber);
            FlemingAnalyticsTracker.trackTestPatientToggle(
                analyticsLoggedInProps,
            );
        } else {
            setFeatureTourName(null);
            clearTourMode(accuRxUserId);
        }
        setIsTestPatientSelected(checked);
    };

    useEffect(() => {
        if (isTestPatientSelected) {
            setInitialFormData({
                nhsNumber: demoPatientData.nhsNumber,
                dobDay: demoPatientData.dobDay,
                dobMonth: demoPatientData.dobMonth,
                dobYear: demoPatientData.dobYear,
            });
        } else if (initialFormData?.nhsNumber === demoPatientData.nhsNumber) {
            // Clear the form if it was previously filled with test patient data
            setInitialFormData(undefined);
        }
    }, [isTestPatientSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderFeedbackIfOnTour = (): JSX.Element | null => {
        if (testPatientTour && isTestPatientSelected && featureTourName) {
            return (
                <Feedback
                    colour="learning"
                    title={`Try our "${featureTourName}" feature`}
                    props={{
                        className: "mb-4",
                        "data-testid": "search-patient-tour",
                    }}
                >
                    <Text skinny>
                        Start by clicking the <Icon name="Search" size={3} />{" "}
                        button
                    </Text>
                </Feedback>
            );
        }
        return null;
    };

    return (
        <>
            <Tabs
                selected={selectedTabId}
                onTabChange={(tabId) => handleTabChange(tabId as TabId)}
            >
                <Card
                    header={
                        <StyledSearchHeaderDiv>
                            <Tabs.TabList
                                tabs={[
                                    {
                                        tabId: TabIds.SearchByNHSNumber,
                                        text: "Search by NHS number",
                                        icon: { name: "Hashtag" },
                                    },
                                    {
                                        tabId: TabIds.SearchByName,
                                        text: "Search by name",
                                        icon: { name: "IdCard" },
                                    },
                                ]}
                            />
                            {showTestPatientOption && (
                                <StyledTestPatientSwitchContainer data-userflow-id="test-patient-toggle">
                                    <Ds.Switch
                                        onCheckedChange={
                                            handleTestPatientToggle
                                        }
                                        disabled={isSearching}
                                        checked={isTestPatientSelected}
                                    >
                                        <Ds.Switch.Label>
                                            Use with a test patient
                                        </Ds.Switch.Label>
                                        <Ds.Switch.Toggle />
                                    </Ds.Switch>
                                </StyledTestPatientSwitchContainer>
                            )}
                        </StyledSearchHeaderDiv>
                    }
                    spacing={0}
                >
                    <StyledSearchBodySpacing>
                        {renderFeedbackIfOnTour()}
                        <Tabs.Panel tabId={TabIds.SearchByNHSNumber}>
                            <SearchForPatientByNHSNumber
                                isFormDisabled={isSearching}
                                onSubmit={onSubmitNhsNumberForm}
                                serverError={serverError}
                                resetServerError={resetServerError}
                                initialFormData={initialFormData}
                                formId={searchForPatientFormIds.allPatients}
                            />
                        </Tabs.Panel>
                        <Tabs.Panel tabId={TabIds.SearchByName}>
                            <SearchForPatientByName
                                isFormDisabled={isSearching}
                                onSubmit={onSubmitNameForm}
                                serverError={serverError}
                                resetServerError={resetServerError}
                                formId={
                                    searchForPatientFormIds.allPatientsByName
                                }
                            />
                        </Tabs.Panel>
                    </StyledSearchBodySpacing>
                </Card>
            </Tabs>
            {showRecentSearches && (
                <>
                    <CardSpacer spacing="small" />
                    <TwoFactorGate>
                        <RecentSearchesWithSearchBar />
                    </TwoFactorGate>
                </>
            )}
        </>
    );
};
