import React from "react";

import * as UI from "@accurx/design";

import { useConversationPreviewActionsContext } from "./PreviewActions";

export const ToggleUrgency = () => {
    const {
        analytics,
        conversation,
        markAsUrgent,
        markAsNonUrgent,
        onActionCompleted,
    } = useConversationPreviewActionsContext();
    const isUrgent = conversation.isUrgent;

    const handleMarkAsUrgent = () => {
        analytics.trackConversationActionButtonClick("Urgent");
        markAsUrgent();
    };

    const handleMarkAsNonUrgent = () => {
        analytics.trackConversationActionButtonClick("NonUrgent");
        markAsNonUrgent();
    };

    const handleUrgencyToggle = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (isUrgent) {
            handleMarkAsNonUrgent();
        } else {
            handleMarkAsUrgent();
        }
        onActionCompleted();
    };
    if (conversation.status === "Done") return null;
    return (
        <UI.PopoverItemButton
            onClick={handleUrgencyToggle}
            aria-label={
                isUrgent
                    ? "Mark conversation as not urgent"
                    : "Mark conversation as urgent"
            }
        >
            <UI.Icon
                name={"Flag"}
                theme={isUrgent ? "Fill" : "Line"}
                size={3}
            />
            <UI.Text as="span" skinny colour="zinc" variant="note">
                {isUrgent ? "Mark not urgent" : "Mark urgent"}
            </UI.Text>
        </UI.PopoverItemButton>
    );
};
