import { Feedback, Text } from "@accurx/design";

import { ErrorPadding } from "./ClinicianConversation.styles";

export const ConversationError = ({ error }: { error: string }) => (
    <ErrorPadding>
        <Feedback colour="error" title="Something went wrong">
            <Text skinny>{error}</Text>
        </Feedback>
    </ErrorPadding>
);
