import React from "react";

import { Card, Input, Text } from "@accurx/design";

import { EditRowComputer, InstalledComputer } from "./InstalledComputers.types";

export type TeamUsageTableProps = {
    canEdit: boolean;
    computers: InstalledComputer[];
    onEditRow: (props: EditRowComputer) => void;
};

const TABLE_HEADERS: {
    id: string;
    title: string;
}[] = [
    {
        id: "name",
        title: "Computer name",
    },
    {
        id: "location",
        title: "Computer location",
    },
    {
        id: "extension",
        title: "Telephone extension",
    },
];

export const InstalledComputersTable = ({
    canEdit,
    computers,
    onEditRow,
}: TeamUsageTableProps): JSX.Element => {
    return (
        <Card
            spacing={0}
            isElevated={false}
            props={{ className: "table-responsive" }}
        >
            <table className="table table-bordered table-sm mb-0">
                <thead>
                    <tr>
                        {TABLE_HEADERS.map(({ id, title }) => (
                            <Text
                                key={id}
                                as="th"
                                variant="label"
                                props={{ id, className: "align-middle p-2" }}
                            >
                                {title}
                            </Text>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {computers.map((row, index) => (
                        <tr key={index}>
                            <Text
                                as="td"
                                props={{ className: "align-middle p-2" }}
                            >
                                {row.computerName}
                            </Text>
                            <td className="p-2">
                                <Input
                                    type="text"
                                    onChange={(e): void =>
                                        onEditRow({
                                            index,
                                            fieldKey: "computerLocation",
                                            newValue: e.target.value,
                                        })
                                    }
                                    value={row.computerLocation || ""}
                                    aria-labelledby={TABLE_HEADERS[1].id}
                                    disabled={!canEdit}
                                />
                            </td>
                            <td className="p-2">
                                <Input
                                    type="text"
                                    onChange={(e): void =>
                                        onEditRow({
                                            index,
                                            fieldKey: "telephoneExtension",
                                            newValue: e.target.value,
                                        })
                                    }
                                    value={row.telephoneExtension || ""}
                                    aria-labelledby={TABLE_HEADERS[2].id}
                                    disabled={!canEdit}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};
