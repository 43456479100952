import { useEffect } from "react";

import { useTransport } from "@accurx/transport";
import { actions } from "domains/concierge/internal/store";
import { v4 as uuidv4 } from "uuid";

import { useConciergeDispatch } from "../context";

export const useConnectionStateChangeDispatcher = () => {
    const dispatch = useConciergeDispatch();
    const { connectionState: state } = useTransport();

    useEffect(() => {
        dispatch(
            actions.connectionStateChange(
                state === "Connected"
                    ? { state: "Connected", connectionId: uuidv4() }
                    : { state },
            ),
        );
    }, [state, dispatch]);
};
