import { FeatureName } from "@accurx/auth";

import { FeatureFlag } from "../app/practices/Practices.types";

// This is depreciated: should use OrganisationHelper.isFeatureEnabled instead
// MERGE_CHAIN_FLEMING
export function IsFeatureEnabled(
    features: FeatureFlag[] | null,
    featureName: FeatureName,
): boolean {
    const feature = features?.find((x) => x.name === featureName);
    return !!feature?.isEnabled;
}

export const coreProductsDisabledForDeceasedPatients = [
    FeatureName.VideoConsultWeb,
    FeatureName.PatientThreadSMS,
];
