import { createQueryHook, returnDataOrThrow } from "@accurx/api/QueryClient";

import { getFloreyScoreResponse } from "shared/concierge/conversations/tickets/TicketApiClient";
import { FloreyScoreResponse } from "shared/concierge/conversations/tickets/types/dto.types";

export const FLOREY_SCORE_RESPONSE_CACHE_KEY = "florey-score";

type useGetFloreyScoreResponseQueryParams = {
    workspaceId: string;
    floreyEnrolmentId: number;
};

export const useGetFloreyScoreResponseQuery = createQueryHook<
    useGetFloreyScoreResponseQueryParams,
    FloreyScoreResponse
>(({ workspaceId, floreyEnrolmentId }) => ({
    queryKey: [
        FLOREY_SCORE_RESPONSE_CACHE_KEY,
        { workspaceId, floreyEnrolmentId },
    ],
    queryFn: async () => {
        const response = await getFloreyScoreResponse(
            workspaceId,
            floreyEnrolmentId,
        );
        return returnDataOrThrow(response);
    },
}));
