import { z } from "zod";

import { PatientDemographicsSchema } from "./PatientDemographicsSchema";

export const MaterialisedPatientDemographicsSchema = z.object({
    patientDemographics: PatientDemographicsSchema,
    serverPatientId: z.string().nullable(),
});

export type MaterialisedPatientDemographics = z.infer<
    typeof MaterialisedPatientDemographicsSchema
>;
