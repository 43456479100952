import { FormFieldWrapper, Grid, SearchSelect } from "@accurx/design";
import styled from "styled-components";

import { IntegratedClinic } from "api/Appointment/IntegratedClinics";
import {
    useTrackOnFilterOpen,
    useTrackOnFilterSelected,
} from "app/analytics/FlemingAnalytics/IntegratedClinicsEvents";

import { FiltersValues } from "../../getFilteredClinics";
import { getClinicCodeFilterOptions } from "./getClinicCodeFilterOptions";
import { getDateFilterOptions } from "./getDateFilterOptions";
import { getLocationFilterOptions } from "./getLocationFilterOptions";
import { getResponsibleClinicianFilterOptions } from "./getResponsibleClinicianFilterOptions";
import { getSelectedFilterKeys } from "./getSelectedFilterKeys";

export const UNSELECTED_FILTER_VALUE = "Any";

const StyledForm = styled.form`
    align-items: end;
`;

type FiltersProps = {
    clinics: IntegratedClinic[];
    filtersSelected: FiltersValues;
    setFiltersSelected: React.Dispatch<React.SetStateAction<FiltersValues>>;
    numberOfResults: number;
};

export default function Filters({
    clinics,
    filtersSelected,
    setFiltersSelected,
    numberOfResults,
}: FiltersProps) {
    const unselectedOption = {
        label: UNSELECTED_FILTER_VALUE,
        value: UNSELECTED_FILTER_VALUE,
    };

    const selectedFilterKeys = getSelectedFilterKeys(filtersSelected);
    const trackOnFilterOpen = useTrackOnFilterOpen();
    const trackOnFilterSelected = useTrackOnFilterSelected();

    const filters = [
        {
            id: "clinic-code-filter",
            label: "Clinic Code",
            filterKey: "clinicCode",
            options: getClinicCodeFilterOptions(clinics),
        },
        {
            id: "clinician-filter",
            label: "Responsible Clinician",
            filterKey: "responsibleClinician",
            options: getResponsibleClinicianFilterOptions(clinics),
        },
        {
            id: "location-filter",
            label: "Location",
            filterKey: "location",
            options: getLocationFilterOptions(clinics),
        },
        {
            id: "date-filter",
            label: "Date",
            filterKey: "date",
            options: getDateFilterOptions(clinics),
        },
    ];

    return (
        <Grid
            as={StyledForm}
            columns={{ xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
            gap="1"
        >
            {filters.map(({ id, label, filterKey, options }, index) => {
                const dropdownOptions = [unselectedOption, ...options];

                return (
                    <FormFieldWrapper
                        key={id}
                        label={label}
                        labelProps={{ htmlFor: id }}
                    >
                        <SearchSelect
                            id={id}
                            initSelectedValues={unselectedOption}
                            options={dropdownOptions}
                            onChangeHandler={(selected, context) => {
                                trackOnFilterSelected({
                                    filterKey,
                                    selectedFilterKeys,
                                    countResult: numberOfResults,
                                    countDropdownOption:
                                        context?.groupedOptions.reduce(
                                            (optionsSum, { options }) =>
                                                optionsSum + options.length,
                                            0,
                                        ) || 0,
                                    searchStringLength:
                                        context?.searchInput.length || 0,
                                });

                                const option = Array.isArray(selected)
                                    ? selected[0]
                                    : selected;
                                setFiltersSelected((previousFilters) => ({
                                    ...previousFilters,
                                    [filterKey]: option.value,
                                }));
                            }}
                            zIndex={filters.length - index}
                            expandCallback={() => {
                                trackOnFilterOpen({
                                    filterKey,
                                    selectedFilterKeys,
                                    countResult: numberOfResults,
                                    countDropdownOption: dropdownOptions.length,
                                });
                            }}
                        />
                    </FormFieldWrapper>
                );
            })}
        </Grid>
    );
}
