import React from "react";

import { Text } from "@accurx/design";

import { ROUTES } from "shared/Routes";

export const SignInToDesktop = (): JSX.Element => (
    <div className="text-center">
        <Text as="h1" variant="title" props={{ className: "mb-4" }}>
            To finish setting up Accurx Desktop...
        </Text>

        <Text variant="subtitle">
            Close this page, return to your desktop and sign in to the Accurx
            Desktop toolbar.
        </Text>

        <Text>
            Haven't installed the Accurx Desktop toolbar?{" "}
            <a href={ROUTES.downloadDesktop}>Install now</a>
        </Text>
    </div>
);
