import { useState } from "react";

import { useAnalytics } from "@accurx/analytics";

import { PendingApprovalModal } from "../PendingApprovalModal/PendingApprovalModal";
import { StyledPendingApprovalButton } from "./PendingApprovalButton.styles";

export const PendingApprovalButton = ({
    onClick,
}: {
    onClick?: () => void;
}): JSX.Element | null => {
    const track = useAnalytics();

    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
        onClick?.();
        track("PendingApproval Button Click", {});
    };

    return (
        <>
            <StyledPendingApprovalButton
                onClick={handleClick}
                text="Pending approval"
                icon={{
                    name: "LockLocked",
                    colour: "white",
                    style: "Fill",
                    placement: "end",
                }}
            />
            <PendingApprovalModal
                open={showModal}
                onOpenChange={setShowModal}
                appOrigin="PendingApprovalButton"
            />
        </>
    );
};
