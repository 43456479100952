import { Ref, forwardRef, useMemo } from "react";

import { useUser } from "@accurx/concierge/hooks/data/useUser";
import { LabelTagItem } from "@accurx/concierge/types";
import { format } from "date-fns";
import { formatUserDisplayName } from "domains/inbox/util/format/names";

type LabelTagNoteContentProps = { item: LabelTagItem };

export const LabelTagNoteContent = forwardRef(
    ({ item }: LabelTagNoteContentProps, ref: Ref<HTMLDivElement>) => {
        const senderId = item.createdBy.id;
        const sender = useUser({ userId: senderId });
        const senderName = formatUserDisplayName({ user: sender });
        const creationTime = useMemo(
            () => format(new Date(item.createdAt), "h:mmaaa"),
            [item.createdAt],
        );
        return (
            <div ref={ref}>
                <p>
                    <b>{senderName}</b> recorded the outcome to be{" "}
                    <b>{item.label}</b> at <b>{creationTime}</b>
                </p>
            </div>
        );
    },
);
