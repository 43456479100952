/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";

import { FormFieldWrapper, Input } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";

type DateInputProps = {
    handleSendDate: (date: string) => void;
    defaultDate: string;
};

export const DateInput = ({
    handleSendDate,
    defaultDate,
}: DateInputProps): JSX.Element => {
    const currentDate = useMemo(() => {
        return DateHelpers.formatDate(
            DateHelpers.getCurrentTimeStamp(),
            DateFormatOptions.DATE_SHORT_NUMERIC_WITH_HYPHENS_REVERSE,
        );
    }, []);

    const [inputDate, setInputDate] = useState(defaultDate);

    useEffect(() => {
        handleSendDate(inputDate);
    }, []);

    const dateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputDate(value);
        handleSendDate(value);
    };

    return (
        <FormFieldWrapper
            as="div"
            label="Date"
            labelProps={{
                htmlFor: "calendar-date",
                className: "sr-only",
            }}
        >
            <Input
                onChange={dateChangeHandler}
                type="date"
                id="calendar-date"
                min={currentDate}
                dimension="large"
                value={inputDate}
            />
        </FormFieldWrapper>
    );
};
