import React from "react";

import { useAnalytics } from "@accurx/analytics";
import { useCurrentWorkspace } from "@accurx/auth";
import { Button, Feedback, Text } from "@accurx/design";
import { WORKSPACE_MANAGEMENT_ROUTES } from "@accurx/workspace-management";
import { Link, generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectedOrganisation } from "reduxQuarantine/SelectedOrganisationProvider";

import { ROUTES } from "shared/Routes";

import {
    StyledCardItem,
    StyledWorkspaceDetails,
} from "../shared/Layout/Layout.styles";
import { UserflowIds } from "../shared/constants";

export type JoinedOrganisationCardProps = {
    orgId: number;
    name: string;
    hasFlexibleWorkspacesEnabled: boolean;
};

export const JoinedOrganisationCard = ({
    orgId,
    name,
    hasFlexibleWorkspacesEnabled,
}: JoinedOrganisationCardProps) => {
    const history = useHistory();
    const { setSelectedOrgId } = useSelectedOrganisation();
    const {
        orgId: currentWorkspaceId,
        defaultWorkspaceId: currentDefaultOrgId,
    } = useCurrentWorkspace();
    const track = useAnalytics();

    const isCurrentDefaultOrg = orgId === currentDefaultOrgId;

    const redirectTo = hasFlexibleWorkspacesEnabled
        ? generatePath(WORKSPACE_MANAGEMENT_ROUTES.workspaceJoin, {
              // Only switch workspace if the user's current workspace is not
              // part of the default organisation being selected
              workspaceId: isCurrentDefaultOrg ? currentWorkspaceId : orgId,
          })
        : ROUTES.home;

    const handleSelectOrganisation = (e: React.MouseEvent) => {
        e.preventDefault();

        track("AccountOrganisationSelect Button Click", {
            onboarding: false,
            pageOrigin: "OrganisationWorkspaceFlow",
            organisationActionSelected: "Select",
        });

        if (!isCurrentDefaultOrg) {
            // For the next part of the flow, we will be showing workspaces
            // within the selected organisation. Hence, we only need to switch
            // workspace if the user's current workspace is not part of the
            // default organisation being selected.
            setSelectedOrgId(orgId);

            hasFlexibleWorkspacesEnabled
                ? toast(
                      <Feedback
                          colour="information"
                          title={`Switched to My Workspace at ${name}`}
                      />,
                  )
                : toast(
                      <Feedback
                          colour="error"
                          title="This organisation does not support workspaces"
                      />,
                  );
        }

        history.push(redirectTo);
    };

    return (
        <StyledCardItem as="li">
            <Text variant="label" skinny>
                {name}
            </Text>
            <StyledWorkspaceDetails>
                <Link
                    to={redirectTo}
                    onClick={handleSelectOrganisation}
                    aria-label={`Select ${name}`}
                    data-userflow-id={
                        UserflowIds.selectOrganisation.selectButton
                    }
                >
                    <Button as="span" theme="secondary" text="Select" />
                </Link>
            </StyledWorkspaceDetails>
        </StyledCardItem>
    );
};
