import { useState } from "react";

import { PatientDemographics, useNativeSubscription } from "@accurx/native";
import isEqual from "lodash/isEqual";

type CurrentComposePatient = {
    composePatientDemographics: PatientDemographics | null;
    composePatientSameAsEmr: boolean;
    setComposePatientDemographics: (patient: PatientDemographics) => void;
} & (
    | {
          currentEmrPatientQueryStatus: "success";
          currentEmrPatientDemographics: PatientDemographics | null;
      }
    | {
          currentEmrPatientQueryStatus: "loading" | "error";
          currentEmrPatientDemographics?: null;
      }
);
export const useComposePatientDemographics = (): CurrentComposePatient => {
    const { data, status } = useNativeSubscription("SubscribeCurrentPatient");
    const { data: windowVisibility } = useNativeSubscription(
        "SubscribeWindowOpenStatus",
    );
    const currentEmrPatientDemographics = data ?? null;

    const [composePatientDemographics, setComposePatientDemographics] =
        useState<PatientDemographics | null>(currentEmrPatientDemographics);

    const composePatientSameAsEmr = isEqual(
        currentEmrPatientDemographics,
        composePatientDemographics,
    );

    if (status === "loading" || status === "error") {
        return {
            currentEmrPatientQueryStatus: status,
            composePatientDemographics,
            composePatientSameAsEmr,
            setComposePatientDemographics,
        };
    }

    /* If we don't have a composePatient set, or if the window is hidden,
     * update the current patient to match the medical record.
     */
    if (
        (composePatientDemographics === null ||
            windowVisibility?.open === false) &&
        currentEmrPatientDemographics &&
        currentEmrPatientDemographics !== composePatientDemographics
    ) {
        setComposePatientDemographics(currentEmrPatientDemographics);
    }

    return {
        currentEmrPatientQueryStatus: "success",
        currentEmrPatientDemographics,
        composePatientDemographics,
        composePatientSameAsEmr,
        setComposePatientDemographics,
    };
};
