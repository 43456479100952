import { useEffect, useState } from "react";

import { StatusChange } from "@accurx/api/florey-builder";
import { FeatureName } from "@accurx/auth";
import {
    Card,
    Flex,
    FormFieldWrapper,
    Option,
    SearchSelect,
    Spinner,
    Text,
} from "@accurx/design";
import { Log } from "@accurx/shared";
import { useHistory, useParams } from "react-router-dom";

import { FlemingAnalyticsTracker } from "app/analytics";
import {
    useEditQuestionnaireMutation,
    useQuestionnaireStatusMutation,
} from "app/hooks/mutations";
import {
    useGetContentTagsQuery,
    useGetQuestionnaireQuery,
    useGetUserGroupsQuery,
} from "app/hooks/queries";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StackPanel } from "app/sharedComponents/StackPanel";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { ReactComponent as InboxTeams } from "shared/images/InboxTeams.svg";
import { useIsFeatureEnabled } from "store/hooks";

import { mapUserGroupsToOptions } from "../FloreyBuilder.reducer";
import { ConfirmationModal } from "../components/ConfirmationModal";
import {
    connectionProblemsError,
    rowVersionError,
    savingError,
    statusChangeError,
} from "../components/ErrorToasts";
import {
    PageContainerForStickyFooter,
    StyledSvgWrapper,
} from "../components/PageContainer.styles";
import { PageTitle } from "../components/PageTitles";
import { routeConfirmationMessage, routeSummary } from "../constants/paths";
import { PageActionText } from "../types/data.types";
import { ContentTags } from "./ContentTags";
import { sortedFloreyCategories } from "./FloreyBuilderAssignAndSave.utils";

const DYNAMIC_FLOREY_USER_GROUP_ID = -1;

const DYNAMIC_FLOREY_USER_GROUP_OPTION = {
    label: "Create new group for this Florey",
    value: `${DYNAMIC_FLOREY_USER_GROUP_ID}`,
};

export const FloreyBuilderAssignAndSave = (): JSX.Element => {
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const { orgId } = useParams<{ orgId: string }>();
    const { questionnaireId } = useParams<{ questionnaireId: string }>();

    const [selectedUserGroupId, setSelectedUserGroupId] = useState<number>();
    const [selectedCategory, setSelectedCategory] = useState<number>();
    const [selectedTagGroups, setSelectedTagGroups] = useState<number[][]>();
    const [selectionErrors, setSelectionErrors] = useState<string[]>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const history = useHistory();
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false);

    const isGlobalCustomFloreyEnabled = useIsFeatureEnabled(
        FeatureName.CreateGlobalCustomFloreys,
    );

    const { data: userGroupsData, status: userGroupsStatus } =
        useGetUserGroupsQuery(
            {
                workspaceId: parseInt(orgId, 10),
            },
            {
                onError: () => connectionProblemsError(),
            },
        );

    const { data: questionnaireData, status: questionnaireStatus } =
        useGetQuestionnaireQuery(
            {
                workspaceId: parseInt(orgId, 10),
                questionnaireId,
            },
            {
                onError: () => connectionProblemsError(),
            },
        );

    const { data: contentTagsData, status: contentTagsStatus } =
        useGetContentTagsQuery(undefined, {
            onError: () => connectionProblemsError(),
        });

    const updateStatusMutation = useQuestionnaireStatusMutation({
        onError: (error, { status }) => {
            const handler =
                status === StatusChange.Review ? "handleSaveAsDraft" : "";
            Log.error(
                `[FloreyBuilder] - ${handler} - editQuestionnaireStatus did not succeed`,
                {
                    originalException: error,
                },
            );
            statusChangeError(error.message);
            setIsModalOpen(false);
            setIsPublishing(false);
            setIsSavingDraft(false);
        },
        onSuccess: (_data, { status }) => {
            status === StatusChange.Review
                ? setIsSavingDraft(false)
                : setIsPublishing(false);
            history.push(routeSummary(orgId));
        },
    });

    const editQuestionnaireMutation = useEditQuestionnaireMutation({
        onError: (error) => {
            Log.error(
                "[FloreyBuilder] - saveAndContinue - saveChanges did not succeed",
                {
                    originalException: error,
                },
            );
            savingError(error.message);
        },
        onSuccess: () => {
            setIsModalOpen(true);
        },
    });

    const selectedGroup =
        userGroupsData?.userGroups.find(
            (group) => group.id && group.id === questionnaireData?.userGroupId,
        ) ?? undefined;

    const defaultSelectedOption = selectedGroup
        ? {
              label: selectedGroup.displayName,
              value: selectedGroup.id.toString(),
          }
        : isGlobalCustomFloreyEnabled
        ? DYNAMIC_FLOREY_USER_GROUP_OPTION
        : undefined;

    const userGroupsAsOptions: Option[] = mapUserGroupsToOptions(
        userGroupsData?.userGroups ?? [],
    );

    useEffect(() => {
        FlemingAnalyticsTracker.trackQuestionnaireAssign({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
        });
    }, [analyticsLoggedInProps, questionnaireId]);

    const handleSelectedGroup = (selected: Option) => {
        const groupId = parseInt(selected.value, 10);
        FlemingAnalyticsTracker.trackQuestionnaireAssigneeSelectItem({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            userGroupType: selected.grouping ?? null,
        });
        setSelectionErrors([]);
        setSelectedUserGroupId(groupId);
    };

    const handleSelectedCategory = (selected: Option | Option[]) => {
        if (!Array.isArray(selected)) {
            setSelectedCategory(parseInt(selected.value, 10));
        }
    };

    const handleSaveAssignment = () => {
        FlemingAnalyticsTracker.trackFloreyBuilderUserGroupSave({
            ...analyticsLoggedInProps,
            userGroupId: selectedUserGroupId ?? null,
        });
        //error handling for empty dropdown
        if (!defaultSelectedOption && !selectedUserGroupId) {
            setSelectionErrors([
                "Choose a group that handles this Florey Questionnaire response",
            ]);
        } else {
            if (!questionnaireData) {
                Log.error(
                    "[FloreyBuilder] - handleSaveAssignment - questionnaireData is not defined",
                );
                return;
            }
            editQuestionnaireMutation.mutate({
                questionnaireId: parseInt(questionnaireId),
                organisationId: parseInt(orgId),
                userGroupId:
                    selectedUserGroupId ??
                    (defaultSelectedOption
                        ? parseInt(defaultSelectedOption.value)
                        : undefined),
                categoryId: selectedCategory ?? questionnaireData.categoryId,
                questionnaireRowVersion:
                    questionnaireData.questionnaireRowVersion,
                tagGroups: selectedTagGroups ?? questionnaireData.tagGroups,
            });
        }
    };

    const handleBackLink = (): void => {
        FlemingAnalyticsTracker.trackQuestionnairePageBack({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId) ?? null,
            pageOrigin: "QuestionnaireAssign",
        });
        history.push(routeConfirmationMessage(orgId, questionnaireId));
        return;
    };

    const handleSaveAsDraft = () => {
        FlemingAnalyticsTracker.trackQuestionnaireSaveForTest({
            ...analyticsLoggedInProps,
            questionnaireId: parseInt(questionnaireId, 10) ?? null,
            pageOrigin: "QuestionnaireAssign",
        });
        if (!questionnaireData) {
            rowVersionError();
            setIsModalOpen(false);
            return;
        }
        setIsSavingDraft(true);
        updateStatusMutation.mutate({
            questionnaireId: parseInt(questionnaireId, 10),
            workspaceId: parseInt(orgId),
            isGloballyAccessible: questionnaireData?.isGloballyAccessible,
            questionnaireRowVersion: questionnaireData?.questionnaireRowVersion,
            status: StatusChange.Review,
        });
    };

    const handlePublish = () => {
        FlemingAnalyticsTracker.trackQuestionnairePublishConfirm({
            ...analyticsLoggedInProps,
            questionnaireId: questionnaireData?.id ?? null,
            pageOrigin: "QuestionnaireAssign",
        });
        if (!questionnaireData) {
            rowVersionError();
            setIsModalOpen(false);
            return;
        }
        setIsPublishing(true);
        updateStatusMutation.mutate({
            questionnaireId: parseInt(questionnaireId, 10),
            workspaceId: parseInt(orgId, 10),
            isGloballyAccessible: questionnaireData?.isGloballyAccessible,
            questionnaireRowVersion: questionnaireData?.questionnaireRowVersion,
            status: StatusChange.Publish,
        });
    };

    const closeModal = (): void => {
        setIsModalOpen(false);
        // Clear modal state
        setIsSavingDraft(false);
        setIsPublishing(false);
    };

    // if a selectedCategory on clientside, use that, otherwise use from questionnaireData
    const selectedCategoryOption: Option | undefined = selectedCategory
        ? sortedFloreyCategories.find((c) => c.value === `${selectedCategory}`)
        : sortedFloreyCategories.find(
              (c) => c.value === `${questionnaireData?.categoryId}`,
          );

    return (
        <>
            <ConfirmationModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                headerText={"Save for testing or publish"}
                secondaryAction={handlePublish}
                secondaryText={isPublishing ? "Publishing..." : "Publish"}
                primaryAction={handleSaveAsDraft}
                primaryText={isSavingDraft ? "Saving..." : "Save for testing"}
                isActionInProgress={isSavingDraft || isPublishing}
            >
                <Text>
                    Once saved, your Florey Questionnaire will be visible to
                    everyone in your organisation.
                </Text>
                <ul>
                    <li>
                        <Text>
                            <Text variant="label" as="span">
                                Save for testing
                            </Text>{" "}
                            means that your Florey Questionnaire can only be
                            sent to{" "}
                            <Text variant="label" as="span">
                                test patients
                            </Text>{" "}
                            from Accurx Desktop.
                        </Text>
                    </li>
                    <li>
                        <Text>
                            <Text variant="label" as="span">
                                Publish
                            </Text>{" "}
                            means that your Florey Questionnaire can be sent to{" "}
                            <Text variant="label" as="span">
                                real patients
                            </Text>{" "}
                            from Accurx Desktop or Batch Messaging.
                        </Text>
                    </li>
                </ul>
            </ConfirmationModal>
            <PageContainerForStickyFooter>
                <StackPanel>
                    <PageTitle pageNumber="4" pageTitle="Assign to a group" />
                    <Flex flexDirection="column" gap="2">
                        <Card variant={"regular"} spacing={3}>
                            <StackPanel gutter={2}>
                                <FormFieldWrapper
                                    errors={selectionErrors}
                                    label={"Assign responses to:"}
                                    labelProps={{
                                        htmlFor: "assign-to",
                                        id: "assign-to",
                                    }}
                                    subLabel={
                                        "Assign a group that handles the responses to this Florey Questionnaire."
                                    }
                                >
                                    {userGroupsStatus === "success" &&
                                    questionnaireStatus === "success" ? (
                                        <SearchSelect
                                            id="assign-to"
                                            data-testid="assign-to"
                                            options={
                                                isGlobalCustomFloreyEnabled
                                                    ? [
                                                          DYNAMIC_FLOREY_USER_GROUP_OPTION,
                                                          ...userGroupsAsOptions,
                                                      ]
                                                    : userGroupsAsOptions
                                            }
                                            onChangeHandler={(
                                                selected: Option | Option[],
                                            ): void =>
                                                handleSelectedGroup(
                                                    selected as Option,
                                                )
                                            }
                                            expandCallback={() => {
                                                FlemingAnalyticsTracker.trackQuestionnaireAssigneeSelect(
                                                    {
                                                        ...analyticsLoggedInProps,
                                                        questionnaireId:
                                                            parseInt(
                                                                questionnaireId,
                                                                10,
                                                            ) ?? null,
                                                    },
                                                );
                                            }}
                                            initSelectedValues={
                                                defaultSelectedOption
                                            }
                                            zIndex={900}
                                        />
                                    ) : (
                                        <span id={"assign-to"}>
                                            <Spinner />
                                        </span>
                                    )}
                                </FormFieldWrapper>
                                <Text>
                                    The responses will go to the group that you
                                    choose, under the Teams or Floreys headings
                                    in the Accurx Inbox.
                                </Text>
                                <Text>
                                    Anyone who needs to see patient responses to
                                    this questionnaire will need to{" "}
                                    <a href="https://support.accurx.com/en/articles/3820467-accurx-desktop-inbox-how-do-i-get-notified-about-patient-replies-to-sms-messages-florey-responses-failed-sms-messages#h_934d903320">
                                        turn on Notifications for this group
                                    </a>
                                    .
                                </Text>
                                <StyledSvgWrapper>
                                    <InboxTeams />
                                </StyledSvgWrapper>
                                <Text>
                                    If you are sending this Florey from Batch
                                    Messaging, you will have the option to
                                    auto-save patient responses to their record.
                                </Text>
                            </StackPanel>
                        </Card>
                        {isGlobalCustomFloreyEnabled && (
                            <>
                                <Card variant={"regular"} spacing={3}>
                                    <StackPanel gutter={2}>
                                        <FormFieldWrapper
                                            errors={selectionErrors}
                                            label={"Select category:"}
                                            labelProps={{
                                                htmlFor: "select-category",
                                            }}
                                            subLabel={
                                                "Choose the category that is most relevant to this Florey. These categories are used in grouping and displaying the Floreys when composing a message."
                                            }
                                        >
                                            {questionnaireStatus ===
                                            "success" ? (
                                                <SearchSelect
                                                    id={"select-category"}
                                                    data-testid="select-category"
                                                    onChangeHandler={(
                                                        selected,
                                                    ): void =>
                                                        handleSelectedCategory(
                                                            selected,
                                                        )
                                                    }
                                                    initSelectedValues={
                                                        selectedCategoryOption
                                                    }
                                                    options={
                                                        sortedFloreyCategories
                                                    }
                                                    zIndex={910}
                                                />
                                            ) : (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </FormFieldWrapper>
                                    </StackPanel>
                                </Card>
                                <Card variant={"regular"} spacing={3}>
                                    <StackPanel gutter={2}>
                                        {contentTagsStatus === "success" &&
                                            questionnaireStatus ===
                                                "success" && (
                                                <ContentTags
                                                    tags={contentTagsData.tags}
                                                    tagGroups={
                                                        selectedTagGroups ??
                                                        questionnaireData.tagGroups ??
                                                        []
                                                    }
                                                    onChange={
                                                        setSelectedTagGroups
                                                    }
                                                />
                                            )}
                                        {(contentTagsStatus === "loading" ||
                                            questionnaireStatus ===
                                                "loading") && (
                                            <span>
                                                <Spinner />
                                            </span>
                                        )}
                                        {(contentTagsStatus === "error" ||
                                            questionnaireStatus ===
                                                "error") && (
                                            <span>
                                                <Text>
                                                    There was an error
                                                    displaying tags.
                                                </Text>
                                            </span>
                                        )}
                                    </StackPanel>
                                </Card>
                            </>
                        )}
                    </Flex>
                </StackPanel>
            </PageContainerForStickyFooter>
            <StepsFooter
                backText={PageActionText.Back}
                forwardText={
                    editQuestionnaireMutation.isLoading
                        ? PageActionText.Saving
                        : PageActionText.SaveAndDone
                }
                disabled={editQuestionnaireMutation.isLoading}
                backClickFunction={handleBackLink}
                forwardClickFunction={handleSaveAssignment}
            />
        </>
    );
};
