/* eslint-disable -- linting bankruptcy
 *
 * Linting of this file has been disabled to
 * allow us to be stricter about linting warnings.
 * See https://github.com/Accurx/rosemary/pull/21285 for details.
 *
 * If you are editing this file, remove this comment
 * and fix or individually disable any warnings.
 *
 * IFF you're fixing an incident and need to make changes to this file quickly,
 * you can commit without removing this comment by either:
 * - using 'git commit --no-verify' to skip the check
 * - individually ignoring the failures by putting '// eslint-disable-next-line' above them
 * - removing the words 'linting bankruptcy' from the top of this comment
 */
import React, { useEffect, useState } from "react";

import { Card, Feedback, Icon, Spinner, Text, Tokens } from "@accurx/design";
import { DateFormatOptions, DateHelpers, IWrappedResult } from "@accurx/shared";
import { formatNhsNumber } from "@accurx/shared/dist/NhsNumberHelper";
import { stringifyUrl } from "query-string";
import { TextareaAutosize } from "react-autosize-textarea/lib/TextareaAutosize";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ChainApiClient from "api/VaccineApiHelper";
import { ChainAnalyticsTracker } from "app/analytics";
import { useFlemingLoggedInAnalytics } from "app/sessionAnalytics/useFlemingLoggedInAnalytics";
import { StepsFooter } from "app/sharedComponents/footer/StepsFooter";
import { UpdatingStatus } from "shared/LoadingStatus";
import { OrganisationHelper } from "shared/OrganisationHelper";
import { ROUTES_CHAIN } from "shared/Routes";
import { useAppSelector } from "store/hooks";

import { getVaccineInviteListCsvDownloadUrl } from "../Vaccine.helper";
import { MaxBatchSMSSize } from "../Vaccine.type";
import {
    calculateMessageLength,
    characterCountWarning,
    characterLimitBreachedError,
    practiceSignature,
    smsCancellationCopy,
} from "../VaccineCopy";
import { InviteStatusType } from "../models/VaccineAllPatientsInvitedDTO";
import { VaccineAppointment } from "../models/VaccineCancelDTO";
import {
    VaccineInviteAction,
    VaccinePatientInvited,
} from "../models/VaccineDeliveryDTO";
import {
    AwaitingResponseFirst,
    ToManuallyBookFirst,
} from "../models/VaccineInviteStatus";
import { VaccineCancelRequest } from "../models/VaccineRecordingDTO";
import { VaccineDeliveryHeader } from "../shared/VaccineDeliveryComponents";
import { getVaccineCourseSearchTerm } from "../utils";
import {
    BulkActionContext,
    VaccineBulkActions,
} from "../vaccineBulkActions/VaccineBulkActions.component";
import {
    cancelBooking,
    resetGoToCancelFlow,
    setPatientInvites,
    setStatusFilters,
    updateMyPracticeFilter,
    updateSearchTerm,
} from "../vaccineInvitesOldPage/vaccineDelivery.actions";
import { initialState as VaccinesDeliveryInitialState } from "../vaccineInvitesOldPage/vaccineDelivery.reducer";
import { getAppointments } from "./VaccineCancel.actions";
import { initialState as VaccineCancelInitialState } from "./VaccineCancel.reducer";

export const VaccineCancel = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const analyticsLoggedInProps = useFlemingLoggedInAnalytics();
    const practices = useAppSelector(({ practices }) => practices);
    const selectedPractice = useAppSelector(
        ({ practices }) => practices.selectedPractice,
    );
    const practiceName = useAppSelector(({ account }) =>
        OrganisationHelper.getOrganisationName(account),
    );
    const {
        allInvitesFilters,
        cancelError,
        cancelPatient,
        cancelStatus,
        goToCancelFlow,
        inviteActionData,
        vaccineCourse,
    } = useSelector(
        ({ vaccineDelivery }: ApplicationState) =>
            vaccineDelivery || VaccinesDeliveryInitialState,
    );
    const {
        appointmentsLoading,
        appointmentsLoadingError,
        vaccineAppointmentsDetails,
    } = useSelector(
        ({ vaccineCancel }: ApplicationState) =>
            vaccineCancel || VaccineCancelInitialState,
    );
    const [cancelInviteId, setCancelInviteId] = useState<string>("");

    const [cancelBothAppointments, setCancelBothAppointments] =
        useState<boolean>(false);
    const [cancelSecondAppointment, setCancelSecondAppointment] =
        useState<boolean>(false);
    const [stepTwo, setStepTwo] = useState<boolean>(false);
    const [allowRebook, setAllowRebook] = useState<boolean>(false);
    const [disallowRebook, setDisallowRebook] = useState<boolean>(false);
    const [messageSignature, setMessageSignature] = useState<string>(
        practiceSignature(practiceName),
    );
    const firstAppointment = vaccineAppointmentsDetails.bookings[0];
    const secondAppointment = vaccineAppointmentsDetails.bookings[1];

    const isInBulkCancelAppointmentsMode =
        inviteActionData.action === VaccineInviteAction.CancelAppointment &&
        inviteActionData.invites.length > 0;
    const allowRebookInSmsCopy = isInBulkCancelAppointmentsMode || allowRebook;

    useEffect(() => {
        if (goToCancelFlow) {
            dispatch(resetGoToCancelFlow());
        }
    }, [dispatch, goToCancelFlow]);

    useEffect(() => {
        // No need to fetch appointments in bulk flow, will just proceed with trying to cancel whatever is there
        if (
            selectedPractice &&
            ((appointmentsLoading === UpdatingStatus.Initial &&
                !isInBulkCancelAppointmentsMode) ||
                cancelInviteId !== cancelPatient.inviteId)
        ) {
            setCancelInviteId(cancelPatient.inviteId);
            dispatch(
                getAppointments(
                    selectedPractice,
                    cancelPatient.inviteBatchId,
                    cancelPatient.inviteId,
                ),
            );
        }
    }, [
        appointmentsLoading,
        cancelInviteId,
        cancelPatient.inviteBatchId,
        cancelPatient.inviteId,
        dispatch,
        selectedPractice,
    ]);

    useEffect(() => {
        if (cancelStatus === UpdatingStatus.Loaded) {
            history.push(
                ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                    ":orgId",
                    selectedPractice,
                ),
            );
        }
    }, [cancelStatus, history, selectedPractice]);

    // region Single cancel flow

    const handleAllowRebook = (): void => {
        setAllowRebook(true);
        setDisallowRebook(false);
    };

    const handleDisallowRebook = (): void => {
        setDisallowRebook(true);
        setAllowRebook(false);
    };

    const handleCancelBooking = (): void => {
        const analyticsProps: ChainAnalyticsTracker.VaccineCancelAppointmentProps =
            {
                ...analyticsLoggedInProps,
                allowRebook: allowRebook,
            };
        ChainAnalyticsTracker.trackVaccineCancelAppointment(analyticsProps);
        dispatch(
            cancelBooking(
                selectedPractice,
                cancelPatient.inviteBatchId,
                cancelPatient.inviteId,
                allowRebook,
                cancelSecondAppointment,
                cancelPatient.sendMessages ? messageSignature : null,
            ),
        );
    };

    const handleBackToStepOne = (): void => {
        setStepTwo(false);
    };

    const updateMessageText = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        const messageText = event.target.value;
        setMessageSignature(messageText);
    };

    const smsPreview = (characterCount: number): JSX.Element => {
        const characterLimitBreached = characterCount > MaxBatchSMSSize;
        const errorColour = characterLimitBreached
            ? Tokens.COLOURS.primary.red["130"]
            : Tokens.COLOURS.greyscale.night;
        return (
            <div className="mt-4 w-100">
                <Text variant="label">Message</Text>
                <TextareaAutosize
                    className="w-100"
                    style={{
                        padding: "1rem",
                        backgroundColor: Tokens.COLOURS.greyscale.white,
                        color: Tokens.COLOURS.greyscale.stone,
                        borderStyle: "solid",
                        borderColor: Tokens.COLOURS.greyscale.silver,
                        borderWidth: "1px 1px 0px 1px",
                        resize: "none",
                        lineHeight: "2rem",
                        paddingBottom: "0",
                        display: "block",
                    }}
                    disabled
                    value={smsCancellationCopy(allowRebookInSmsCopy)}
                    data-testid="smsContent"
                />

                <TextareaAutosize
                    className="w-100"
                    style={{
                        padding: "1rem",
                        backgroundColor: Tokens.COLOURS.greyscale.white,
                        borderStyle: "solid",
                        borderColor: Tokens.COLOURS.greyscale.silver,
                        borderWidth: "0px 1px 1px 1px",
                        resize: "vertical",
                        lineHeight: "2rem",
                        paddingTop: "0",
                        outline: "none",
                    }}
                    value={messageSignature}
                    onChange={updateMessageText}
                    data-testid="signature"
                />
                <div
                    style={{
                        paddingRight: "1rem",
                        display: "flex",
                        alignSelf: "flex-end",
                    }}
                    className="mt-1"
                >
                    <Icon
                        theme="Fill"
                        name="Warning"
                        size={3}
                        colour="orange"
                    />
                    <Text
                        as="span"
                        variant="preview"
                        props={{
                            style: { color: errorColour },
                        }}
                    >
                        {characterLimitBreached
                            ? characterLimitBreachedError
                            : characterCountWarning}
                    </Text>
                    <Text
                        as="span"
                        variant="preview"
                        props={{
                            className: "ml-1",
                            "data-testid": "characterCount",
                            style: { color: errorColour },
                        }}
                    >
                        {characterCount}/{MaxBatchSMSSize}
                    </Text>
                </div>
            </div>
        );
    };

    // This section is shown when patient has both appointments booked and we are cancelling both or if patient only has a first appointment booked
    // and we are cancelling that appointment
    const renderVaccineCancelBothAppointments = (
        firstAppointment: VaccineAppointment,
        secondAppointment: VaccineAppointment | null,
    ): JSX.Element => {
        const characterCount = calculateCancelMessageLength(
            smsCancellationCopy(allowRebookInSmsCopy),
            secondAppointment ? 2 : 1,
        );
        const characterLimitBreached = characterCount > MaxBatchSMSSize;
        return (
            <>
                <Text>
                    You are about to cancel this patient's appointments.
                    {cancelPatient.sendMessages &&
                        " They will receive a text confirming this cancellation."}
                </Text>
                <div className="w-100">
                    <div className="mt-2">
                        <label
                            htmlFor="allowRebook"
                            className="my-1"
                            style={{
                                fontWeight: "normal",
                                display: "flex",
                                flexGrow: 1,
                            }}
                        >
                            <Card
                                spacing={1.5}
                                props={{ style: { flexGrow: 1 } }}
                            >
                                <>
                                    <input
                                        type="radio"
                                        id="allowRebook"
                                        value="allowRebook"
                                        name="rebook"
                                        onClick={handleAllowRebook}
                                        data-testid="allowRebook"
                                        checked={allowRebook}
                                    />
                                    {cancelPatient.sendMessages
                                        ? "Allow patient to rebook"
                                        : "Allow manual rebooking"}
                                </>
                            </Card>
                        </label>
                        <label
                            htmlFor="disallowRebook"
                            className="my-1"
                            style={{
                                fontWeight: "normal",
                                display: "flex",
                                flexGrow: 1,
                            }}
                        >
                            <Card
                                spacing={1.5}
                                props={{ style: { flexGrow: 1 } }}
                            >
                                <>
                                    <input
                                        type="radio"
                                        id="disallowRebook"
                                        value="disallowRebook"
                                        name="rebook"
                                        onClick={handleDisallowRebook}
                                        data-testid="disallowRebook"
                                        checked={disallowRebook}
                                    />
                                    {cancelPatient.sendMessages
                                        ? "Do not let patients rebook until they're sent a new invite"
                                        : "Do not allow manual rebooking until patients have been invited again"}
                                </>
                            </Card>
                        </label>
                    </div>
                </div>
                {(allowRebook || disallowRebook) &&
                    cancelPatient.sendMessages &&
                    smsPreview(characterCount)}
                <StepsFooter
                    backText="Back"
                    backClickFunction={handleBackToStepOne}
                    forwardText="Cancel appointments"
                    forwardClickFunction={handleCancelBooking}
                    disabled={
                        !(allowRebook || disallowRebook) ||
                        characterLimitBreached ||
                        cancelStatus === UpdatingStatus.Loading
                    }
                    forwardButtonIsCancel={true}
                />
            </>
        );
    };

    const renderVaccineCancelSecondAppointment = (
        firstAppointment: VaccineAppointment,
        secondAppointment: VaccineAppointment | null,
    ): JSX.Element => {
        const characterCount = calculateCancelMessageLength(
            smsCancellationCopy(allowRebookInSmsCopy),
            secondAppointment ? 2 : 1,
        );
        const characterLimitBreached = characterCount > MaxBatchSMSSize;
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Text>
                        You're about to cancel this patient's second
                        appointment.
                        {cancelPatient.sendMessages &&
                            " They will receive a text confirming this cancellation."}
                    </Text>
                </div>
                {cancelPatient.sendMessages && smsPreview(characterCount)}
                <StepsFooter
                    backText="Back"
                    backClickFunction={handleBackToStepOne}
                    forwardText="Cancel appointment"
                    forwardClickFunction={handleCancelBooking}
                    disabled={characterLimitBreached}
                    forwardButtonIsCancel={true}
                />
            </>
        );
    };

    const calculateCancelMessageLength = (
        smsContent: string,
        appointmentCount: number,
    ): number =>
        calculateMessageLength(
            smsContent + "\n" + messageSignature,
            appointmentCount,
        );

    const handleContinue = (): void => {
        setStepTwo(true);
    };

    const setBoth = (): void => {
        setCancelBothAppointments(true);
        setCancelSecondAppointment(false);
    };

    const setSecond = (): void => {
        setCancelBothAppointments(false);
        setCancelSecondAppointment(true);
        setAllowRebook(true);
    };

    const renderSingleFlow = (): JSX.Element => (
        <>
            <VaccineDeliveryHeader
                stepNumber={stepTwo ? 2 : 1}
                title={stepTwo ? stepTwoHeader : "Cancel appointments"}
            />
            {!stepTwo && (
                <Card spacing={1.5} props={{ className: "mb-2 w-100" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Text
                            variant="label"
                            props={{
                                className: "mx-3",
                                "data-testid": "nameColumn",
                            }}
                        >
                            {cancelPatient.patientName}
                        </Text>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-end",
                            }}
                        >
                            <Text
                                props={{
                                    className: "mx-2",
                                    "data-testid": "nameColumn",
                                }}
                            >
                                NHS:{" "}
                                {formatNhsNumber(
                                    cancelPatient.patientExternalIdentity
                                        .patientExternalIds[0].value,
                                )}
                            </Text>
                            <Text
                                props={{
                                    className: "mx-2",
                                    "data-testid": "nameColumn",
                                }}
                            >
                                Born:{" "}
                                {DateHelpers.formatDate(
                                    cancelPatient.dateOfBirth,
                                    DateFormatOptions.DATE_SHORT_WITH_SLASH,
                                )}
                            </Text>
                        </div>
                    </div>
                </Card>
            )}
            {appointmentsLoading === UpdatingStatus.Loading && <Spinner />}
            {appointmentsLoading === UpdatingStatus.Failed && (
                <Feedback
                    colour="error"
                    title={
                        appointmentsLoadingError ??
                        "There was a problem loading your appointment details."
                    }
                    content="Please go back and try again."
                />
            )}
            {appointmentsLoading === UpdatingStatus.Loaded &&
                !stepTwo &&
                firstAppointment &&
                renderStepOne(firstAppointment, secondAppointment)}
            {appointmentsLoading === UpdatingStatus.Loaded &&
                !stepTwo &&
                !firstAppointment && (
                    <Feedback
                        colour="error"
                        title="There was a problem loading your appointment details."
                        content="Please go back and try again."
                    />
                )}
            {stepTwo &&
                (cancelBothAppointments || !secondAppointment) &&
                renderVaccineCancelBothAppointments(
                    firstAppointment,
                    secondAppointment,
                )}
            {stepTwo &&
                cancelSecondAppointment &&
                renderVaccineCancelSecondAppointment(
                    firstAppointment,
                    secondAppointment,
                )}
            {cancelStatus === UpdatingStatus.Failed && (
                <Feedback
                    colour="error"
                    title="Something went wrong"
                    props={{
                        style: { whiteSpace: "pre-line", width: "70%" },
                        className: "m-1",
                    }}
                    content={
                        cancelError ??
                        "There was a problem cancelling your appointments. Please go back and try again."
                    }
                />
            )}
        </>
    );

    // endregion

    // region Bulk cancel flow

    // Auto-download the CSV - need this as if they cancel we lose data of who was in the clinic and can't export this again
    useEffect(() => {
        if (isInBulkCancelAppointmentsMode) {
            setTimeout(function () {
                window.location.href = getCsvDownloadUrl();
            }, 1000);
        }
    }, []);

    // For passing to the footer "Back" button
    const handleBackToAllInvites = (): void => {
        history.goBack();
    };

    const handleBulkCancelBookings = (): void => {
        setStepTwo(true);
    };

    const getCsvDownloadUrl = (): string => {
        return stringifyUrl({
            url: getVaccineInviteListCsvDownloadUrl(selectedPractice),
            query: {
                showLinkedOrgs: "true",
                searchTerm: allInvitesFilters.searchTerm, // This will be set to clinicId: XXX so export the clinic only
                course: getVaccineCourseSearchTerm(vaccineCourse),
            },
        });
    };

    const promiseForCancelAppointment = (
        patient: VaccinePatientInvited,
    ): Promise<IWrappedResult<any>> => {
        // For use in the bulk action
        const cancelSecondOnly =
            patient.currentInviteStatus === InviteStatusType.BookedBoth;
        const request: VaccineCancelRequest = {
            patientCanRebook: true, // Implicitly true as we only want to cancel the booking, not the full invite
            cancelSecondBooking: cancelSecondOnly,
            customSmsSignature: messageSignature,
        };
        return ChainApiClient.cancelBooking(
            selectedPractice,
            patient.inviteBatchId,
            patient.inviteId,
            request,
        );
    };

    // For passing into the bulk actions component
    const handleBackToInviteList = (): void => {
        dispatch(updateSearchTerm(""));
        dispatch(
            setStatusFilters([
                ToManuallyBookFirst.filterStatus,
                AwaitingResponseFirst.filterStatus,
            ]),
        );
        dispatch(updateMyPracticeFilter(true));
        dispatch(
            setPatientInvites({
                invites: [],
                action: VaccineInviteAction.None,
            }),
        );
        history.push(
            ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                ":orgId",
                selectedPractice,
            ),
        );
    };

    const renderVaccineBulkCancelAppointments = (): JSX.Element => {
        const characterCount = calculateCancelMessageLength(
            smsCancellationCopy(allowRebookInSmsCopy),
            1,
        );

        const textablePatients = inviteActionData.invites.filter(
            (invite) => invite.sendMessages,
        );
        const nonTextablePatients = inviteActionData.invites.filter(
            (invite) => !invite.sendMessages,
        );
        return (
            <>
                <Text>
                    You are about to cancel {inviteActionData.invites.length}{" "}
                    appointments. Patients with a mobile number will be sent a
                    text confirming this cancellation
                </Text>
                <div className="d-flex justify-content-between w-100">
                    <Text as="h3" variant="label">
                        Patient list
                    </Text>
                    <div className="d-flex align-items-center">
                        <Icon theme="Line" name="Save" size={3} />
                        <Text
                            as="a"
                            skinny
                            props={{
                                href: getCsvDownloadUrl(),
                                download: true,
                            }}
                        >
                            Export CSV
                        </Text>
                    </div>
                </div>
                <div className="mt-2">
                    <Text>
                        Please note that once these appointments are cancelled
                        you WILL NOT be able to recover a list of patients for
                        this specific clinic. A CSV download of these patients
                        should have started automatically, please click above to
                        download a list of these patients if not.
                    </Text>
                </div>
                <div className="w-100 mt-2">
                    <Card spacing={1.5}>
                        <div className="d-flex align-items-center">
                            <Icon theme="Line" name="Mail" />
                            <div className="ml-2">
                                <Text variant="label">
                                    {textablePatients.length} of{" "}
                                    {inviteActionData.invites.length} patients
                                </Text>
                                <Text>Will receive a cancellation SMS</Text>
                            </div>
                        </div>
                    </Card>
                    <Card spacing={1.5} props={{ className: "mt-2" }}>
                        <div className="d-flex align-items-center">
                            <Icon theme="Line" name="Phone" />
                            <div className="ml-2">
                                <Text variant="label">
                                    {nonTextablePatients.length} of{" "}
                                    {inviteActionData.invites.length} patients
                                </Text>
                                <Text>To be manually notified</Text>
                            </div>
                        </div>
                    </Card>
                </div>
                {textablePatients.length > 0 && smsPreview(characterCount)}

                <StepsFooter
                    backText="Back"
                    backClickFunction={handleBackToAllInvites}
                    forwardText="Cancel appointments"
                    forwardClickFunction={handleBulkCancelBookings}
                    disabled={false}
                    forwardButtonIsCancel={true}
                />
            </>
        );
    };

    // endregion

    const renderStepOne = (
        firstAppointment: VaccineAppointment,
        secondAppointment: VaccineAppointment | null,
    ): JSX.Element => {
        return (
            <>
                <div className="w-100">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                        className="mx-2"
                    >
                        <Text variant="label">Booking details:</Text>
                        <div style={{ display: "flex" }} className="mt-2">
                            <Icon
                                theme="Line"
                                name="Calendar"
                                size={3}
                                props={{ className: "mr-2" }}
                            />
                            <div>
                                <Text
                                    props={{
                                        style: {
                                            textDecoration:
                                                cancelBothAppointments
                                                    ? "line-through"
                                                    : "none",
                                        },
                                    }}
                                >
                                    {DateHelpers.formatDate(
                                        firstAppointment.startTime,
                                        DateFormatOptions.DATE_LONG_ORDINAL,
                                    )}
                                </Text>
                                <Text
                                    props={{
                                        style: {
                                            textDecoration:
                                                cancelBothAppointments
                                                    ? "line-through"
                                                    : "none",
                                        },
                                    }}
                                >
                                    {DateHelpers.formatTime(
                                        firstAppointment.startTime,
                                        DateFormatOptions.TIME,
                                    )}{" "}
                                    -{" "}
                                    {DateHelpers.formatTime(
                                        firstAppointment.endTime,
                                        DateFormatOptions.TIME,
                                    )}
                                </Text>
                            </div>
                        </div>
                        {secondAppointment && (
                            <div style={{ display: "flex" }}>
                                <Icon
                                    theme="Line"
                                    name="Calendar"
                                    size={3}
                                    props={{ className: "mr-2" }}
                                />
                                <div>
                                    <Text
                                        props={{
                                            style: {
                                                textDecoration:
                                                    cancelBothAppointments ||
                                                    cancelSecondAppointment
                                                        ? "line-through"
                                                        : "none",
                                            },
                                        }}
                                    >
                                        {DateHelpers.formatDate(
                                            secondAppointment.startTime,
                                            DateFormatOptions.DATE_LONG_ORDINAL,
                                        )}
                                    </Text>
                                    <Text
                                        props={{
                                            style: {
                                                textDecoration:
                                                    cancelBothAppointments ||
                                                    cancelSecondAppointment
                                                        ? "line-through"
                                                        : "none",
                                            },
                                        }}
                                    >
                                        {DateHelpers.formatTime(
                                            secondAppointment.startTime,
                                            DateFormatOptions.TIME,
                                        )}{" "}
                                        -{" "}
                                        {DateHelpers.formatTime(
                                            secondAppointment.endTime,
                                            DateFormatOptions.TIME,
                                        )}
                                    </Text>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {secondAppointment && (
                    <div className="w-100">
                        <Text variant="label" props={{ className: "mx-1" }}>
                            Which appointment would you like to cancel?
                        </Text>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "center",
                            }}
                            className="mt-2"
                        >
                            <label
                                htmlFor="cancelBoth"
                                className="mr-1"
                                style={{ fontWeight: "normal", flexGrow: 1 }}
                            >
                                <Card
                                    spacing={1.5}
                                    props={{ style: { flexGrow: 1 } }}
                                >
                                    <>
                                        <input
                                            type="radio"
                                            id="cancelBoth"
                                            value="cancelBoth"
                                            name="cancelOption"
                                            onClick={setBoth}
                                            data-testid="cancelBoth"
                                            checked={cancelBothAppointments}
                                        />
                                        Cancel both
                                    </>
                                </Card>
                            </label>
                            <label
                                htmlFor="cancelSecond"
                                className="ml-1"
                                style={{ flexGrow: 1, fontWeight: "normal" }}
                            >
                                <Card
                                    spacing={1.5}
                                    props={{ style: { flexGrow: 1 } }}
                                >
                                    <>
                                        <input
                                            type="radio"
                                            id="cancelSecond"
                                            value="cancelSecond"
                                            name="cancelOption"
                                            onClick={setSecond}
                                            data-testid="cancelSecond"
                                            checked={cancelSecondAppointment}
                                        />
                                        Cancel second
                                    </>
                                </Card>
                            </label>
                        </div>
                    </div>
                )}
                <StepsFooter
                    backText="Back"
                    backLink={ROUTES_CHAIN.practicesVaccineAllPatientsInvited.replace(
                        ":orgId",
                        selectedPractice,
                    )}
                    forwardText="Continue"
                    forwardClickFunction={handleContinue}
                    disabled={
                        !!secondAppointment &&
                        !(cancelBothAppointments || cancelSecondAppointment)
                    }
                />
            </>
        );
    };
    const stepTwoHeader = !secondAppointment
        ? "Cancel appointment"
        : cancelSecondAppointment
        ? "Cancel second appointment"
        : "Cancel both appointments";
    return (
        <div
            style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "6rem",
            }}
        >
            <div
                style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {!isInBulkCancelAppointmentsMode ? (
                    renderSingleFlow()
                ) : (
                    <>
                        <VaccineDeliveryHeader
                            stepNumber={stepTwo ? 2 : 1}
                            title={
                                stepTwo
                                    ? "Cancelled appointments"
                                    : "Cancel appointments"
                            }
                        />
                        {!stepTwo ? (
                            renderVaccineBulkCancelAppointments()
                        ) : (
                            <VaccineBulkActions
                                practices={practices}
                                practiceId={selectedPractice}
                                context={BulkActionContext.CancelAppointments}
                                invites={inviteActionData.invites}
                                processFn={promiseForCancelAppointment}
                                successListTitle={"Appointments cancelled"}
                                successListSubTitle={
                                    "We were able to cancel the patient's booking"
                                }
                                failedListTitle={"Cancellation failed"}
                                failedListSubTitle={
                                    "We were not able to cancel these bookings"
                                }
                                onBackToInviteList={handleBackToInviteList}
                                showStatusBadges={false}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default VaccineCancel;
