import { Tokens } from "@accurx/design";
import styled from "styled-components";

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${Tokens.BREAKPOINTS.xl}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StyleHeaderLeft = styled.div`
    margin-right: ${Tokens.SIZES[2]};
    flex: 1;

    @media (max-width: ${Tokens.BREAKPOINTS.xl}) {
        margin-right: 0;
        margin-bottom: ${Tokens.SIZES[2]};
    }
`;

const StyledHeaderRight = styled.div`
    flex: 0;
`;

export { StyledWrapper, StyleHeaderLeft, StyledHeaderRight };
