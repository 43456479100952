/*
 * Utility functions for working with an object that is nested two layers
 * deep, so immutable updates to the object (which have to do an O(n)
 * copy of all the keys of the object they are updating) can be done
 * in O(log n) time (where n < around 1,000,000)
 *
 * We use this when storing conversations in the concierge layer as
 * the number of conversations can get very large, and updating
 * a very large map immutably is O(n).
 *
 * The first layer of the map is keyed on the last two characters of the given key.
 * The values in this first layer are a map of key to value for all keys that
 * end with the respective two character suffix.
 *
 * See https://www.notion.so/accurx/Concierge-layer-performance-optimisations-f4bcea35e0354ef9a6f02bb73c3e45e4?pvs=4
 * for more on the motivation, reasoning, and performance testing that's behind this change.
 *
 * Mutates the object rather than working in an immutable way so it
 * can be used more performantly with immer
 */
import isEmpty from "lodash/isEmpty";
import lodashSize from "lodash/size";
import sum from "lodash/sum";
import values from "lodash/values";

export type ShardedMap<T> = Record<string, Record<string, T>>;

const parentKey = (key: string): string => key.substring(key.length - 2);

export const getValue = <T>(map: ShardedMap<T>, id: string): T => {
    const submap = map[parentKey(id)];
    return submap ? submap[id] : (undefined as T);
};

export const setValue = <T>(map: ShardedMap<T>, key: string, value: T) => {
    const pkey = parentKey(key);
    if (map[pkey] === undefined) map[pkey] = {};
    map[pkey][key] = value;
};

export const removeValue = <T>(map: ShardedMap<T>, key: string) => {
    const pkey = parentKey(key);

    if (map[pkey]) delete map[pkey][key];
    if (isEmpty(map[pkey])) delete map[pkey];
};

export const flatten = <T>(map: ShardedMap<T>): Record<string, T> => {
    const result: Record<string, T> = {};
    values(map).forEach((submap) => Object.assign(result, submap));
    return result;
};

export const create = <T>(map: Record<string, T>) => {
    const result: ShardedMap<T> = {};
    for (const k in map) {
        setValue(result, k, map[k]);
    }
    return result;
};

export const forEach = <T>(map: ShardedMap<T>, f: (value: T) => void) => {
    for (const pid in map) {
        for (const id in map[pid]) {
            f(map[pid][id]);
        }
    }
};

export const size = <T>(map: ShardedMap<T>) => {
    return sum(Object.values(map).map(lodashSize));
};

export const shardedMap = {
    getValue,
    setValue,
    removeValue,
    flatten,
    create,
    forEach,
    size,
};
