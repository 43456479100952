import { useCallback, useEffect, useRef } from "react";

import { Log } from "@accurx/shared";
import { useTransport } from "@accurx/transport";
import { useConciergeMeta } from "domains/concierge/internal/context";

import { SocketEvents } from "shared/hubClient/HubClient";
import { ReconnectPushRequest } from "shared/hubClient/payload.types";

/**
 * When SignalR reconnects, we want to register the client to receive
 * a SignalR push of all unread item IDs added in the past 12 hours
 * to calculate if there have been any updates that has been missed.
 */
export const useUnreadItemsOnReconnect = () => {
    const { workspaceId } = useConciergeMeta();
    const { transport, connectionState } = useTransport();
    const previousConnection = useRef(connectionState);

    const registerForUnreadItemsOnReconnect = useCallback((): void => {
        const reconnectPushRequest: ReconnectPushRequest = {
            workspaceId,
        };

        void transport?.send({
            methodName: SocketEvents.RegisterForReconnectPush,
            payload: reconnectPushRequest,
        });

        Log.info("SignalR reconnect: registered to receive unread items");
    }, [transport, workspaceId]);

    useEffect(() => {
        if (previousConnection.current === connectionState) return;

        if (
            previousConnection.current === "Disconnected" &&
            connectionState === "Connected"
        ) {
            registerForUnreadItemsOnReconnect();
        }

        // update previous connection ref
        previousConnection.current = connectionState;
    }, [connectionState, registerForUnreadItemsOnReconnect]);
};
