import { useConciergeSelector } from "domains/concierge/internal/context";
import { TeamSummary } from "domains/concierge/types";

export const useTeamsByType = ({
    type,
}: {
    type: TeamSummary["type"];
}): TeamSummary[] => {
    return useConciergeSelector((state) =>
        Object.values(state.teams.items).filter((team) => team.type === type),
    );
};
