import React, { useState } from "react";

import { Text } from "@accurx/design";
import { Redirect, generatePath } from "react-router";

import { useCurrentOrganisation } from "app/organisations/hooks";
import {
    Breadcrumb,
    Breadcrumbs,
    Container,
    Header,
} from "app/organisations/shared";
import { ROUTES_ORGS } from "shared/Routes";

import { Routes } from "./Routes";
import { useSettingsPage } from "./SettingsPage.hooks";
import {
    MainPanel,
    StyledFlex,
    StyledSidebarToggle,
} from "./SettingsPage.styles";
import { Sidebar } from "./components";

export const SettingsPage = (): JSX.Element => {
    const org = useCurrentOrganisation();
    const { tab } = useSettingsPage();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    if (!tab) {
        // the url contains an invalid tab
        return (
            <Redirect
                to={generatePath(ROUTES_ORGS.settings, { orgId: org?.orgId })}
            />
        );
    }

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Breadcrumb text="Settings" path={ROUTES_ORGS.settings} />
                    <Breadcrumb
                        text={tab.text}
                        path={ROUTES_ORGS.settings}
                        params={{ tab: tab.tabId }}
                    />
                </Breadcrumbs>

                <Text variant="title" as="h1" skinny>
                    Settings
                </Text>
            </Header>
            <Container>
                <StyledFlex>
                    <Sidebar
                        isOpen={isSidebarOpen}
                        close={() => setIsSidebarOpen(false)}
                    />
                    <MainPanel>
                        <StyledSidebarToggle
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            icon={{
                                colour: "blue",
                                name: "Arrow",
                                title: "Open menu",
                                id: "open-menu",
                                placement: "end",
                                rotation: "right",
                            }}
                            text="Open menu"
                            theme="transparent"
                        />
                        <Routes />
                    </MainPanel>
                </StyledFlex>
            </Container>
        </>
    );
};

export default SettingsPage;
