import { Tokens } from "@accurx/design";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

export const StyledItem = styled.li`
    display: contents;
`;

export const StyledLabel = styled.div`
    margin: ${Tokens.SIZES[0.5]} 0 ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1.5]};
    color: ${Tokens.COLOURS.greyscale.night};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    box-sizing: border-box;
    background-color: ${Tokens.COLOURS.greyscale.white};
    color: ${Tokens.COLOURS.primary.blue[100]};
    box-shadow: none !important;
    font-family: ${Tokens.BASE.font};
`;

export const StyledLink = styled(RouterLink)`
    margin: ${Tokens.SIZES[0.5]} 0 ${Tokens.SIZES[0.5]} ${Tokens.SIZES[1.5]};
    color: ${Tokens.COLOURS.primary.blue[100]};
    border-radius: ${Tokens.BORDERS.radius};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
    box-sizing: border-box;
    text-decoration: underline;
    transition: all 0.2s ease;
    font-family: ${Tokens.BASE.font};
    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        box-shadow: none;
        background-color: ${Tokens.COLOURS.greyscale.white};
    }

    &:focus,
    &:active {
        background-color: ${Tokens.COLOURS.greyscale.white};
        box-shadow: none !important;
    }

    &:focus-visible {
        box-shadow: 0px 0px 0px 3px ${Tokens.COLOURS.primary.blue[50]} !important;
    }
`;
